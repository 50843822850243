import React from 'react';
import EntityTableContainer from './EntityTableContainer';
import EntityTileViewContainer from './EntityTileViewContainer';
import { withViewMode } from '../hocs/withViewMode';

export const EntityList = ({
  data = [],
  isFetchingMainSearch,
  viewMode,
  viewName,
}) => {
  if (viewMode === 'grid') {
    return (
      <EntityTableContainer
        viewName={viewName}
        viewMode="grid"
        data={data}
        isFetchingMainSearch={isFetchingMainSearch}
      />
    );
  }

  return (
    <EntityTileViewContainer
      viewName={viewName}
      viewMode="tile"
      data={data}
      isFetchingMainSearch={isFetchingMainSearch}
    />
  );
};

export default withViewMode(EntityList);
