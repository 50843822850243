import React from 'react';
import NewConfigurationForm from '../../composites/forms/NewConfigurationForm';

const ConfigurationForm = (props) => (
  <div>
    <NewConfigurationForm {...props} />
  </div>
);

ConfigurationForm.displayName = 'ConfigurationForm';

export default ConfigurationForm;
