import React from 'react';
import PropTypes from 'prop-types';

import {
  ExplainLogSingleEventItem,
  ExplainLogSingleEventPre,
} from './ExplainLogSingleEvent.styles';

const ExplainLogSingleEvent = ({ data }) => {
  return (
    <ExplainLogSingleEventItem>
      {data.event.message && (
        <ExplainLogSingleEventPre>
          {JSON.stringify(data.event.message, null, 2)}
        </ExplainLogSingleEventPre>
      )}
    </ExplainLogSingleEventItem>
  );
};

export default ExplainLogSingleEvent;

ExplainLogSingleEvent.propTypes = {
  data: PropTypes.object,
};

ExplainLogSingleEvent.defaultProps = {
  data: {},
};
