import React from 'react';
import Switch from 'uxi/Input/Switch';
import { Integration } from 'uxi/Icons';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';

const HorizontalAppLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  & > *:nth-child(1) {
  }
  & > *:nth-child(2) {
    flex-grow: 99;
    flex-shrink: 1;
    padding: 16px;
    text-align: left;
  }
  & > *:nth-child(3) {
    padding: 16px 0;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }

  padding: 16px;
  display: flex;
  border: 1px solid #ccc;
  border-top: none;
`;

const UserProcessorConsent = ({ processor, isSingle, onChange }) => {
  const config = processor
    ? processor.config
    : {
        name: processor.Name,
      };

  return (
    <HorizontalAppLayout
      style={isSingle ? { borderTop: '1px solid #ccc' } : {}}
    >
      <div
        style={{
          minWidth: '48px',
          maxWidth: '48px',
          overflow: 'hidden',
          wordWrap: 'break-word',
        }}
      >
        {(config && config.icon && (
          <img
            style={{ width: '100%' }}
            src={config.icon}
            alt={`${processor.Name}'s logo`}
          />
        )) || <Integration />}
      </div>
      <div>
        <div style={{ fontSize: '16px', paddingBottom: '10px' }}>
          {processor.Name}
        </div>
        <div style={{ paddingBottom: '5px' }}>
          {processor.Consent && processor.Consent !== 'consent'
            ? processor.Consent
            : config.Details}
        </div>
        {processor &&
          processor.identifiers &&
          processor.identifiers.length > 0 && (
            <div style={{ marginTop: '5px' }}>
              <FormattedMessage id="module-constent-identifiersShared" />
              &nbsp;
              {processor.identifiers.map((v, index) => {
                if (processor.identifiers.length === index + 1) {
                  return `${v}.`;
                }
                return `${v}, `;
              })}
            </div>
          )}
      </div>
      <div>
        <Switch
          name={`userProcessorConsentSwitch-${processor.Name}`}
          defaultChecked={processor.hasConsent || false}
          onChange={({ checked }) => {
            if (onChange) {
              onChange({
                hasConsent: checked,
                processor,
              });
            }
          }}
        />
      </div>
    </HorizontalAppLayout>
  );
};

UserProcessorConsent.displayName = 'UserProcessorConsent';

export default UserProcessorConsent;
