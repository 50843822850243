import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pure } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import { CluedInSignInLogo } from '@cluedin/svgs';

const PageContent = styled.div`
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 280px;
  width: 100%;
  min-height: 100vh;
  padding: 60px 30px 30px 30px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 540px;
`;

const PageLogoWrapper = styled.div`
  & svg {
    height: 59px;
    width: 182px;
  }
`;

const PageForm = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const PageWrapper = styled.div`
  justify-content: center;
  display: flex;
  background: white;
  @media screen and (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

const PageExtraContent = styled.div`
  padding-bottom: 30px;
`;

const NotSignedInLayoutDividedInTwo = ({
  Page,
  PageExtra,
  Context,
  isDomain,
  url,
  ...rest
}) => {
  return (
    <PageWrapper>
      <PageContent>
        <PageLogoWrapper>
          <CluedInSignInLogo data-test="cluedin-signin-logo" />
        </PageLogoWrapper>
        <PageForm>
          <div style={{ flex: 1 }}>
            <Page isDomain={isDomain} {...rest} />
          </div>
        </PageForm>
        <PageExtraContent>
          {PageExtra && <PageExtra />}
          <div style={{ marginTop: '12px' }}>
            <a
              className="test_auth_privacyPolicy"
              target="_blank"
              href="https://www.cluedin.com/privacy"
              data-test="privacy-policy-link"
              rel="noreferrer"
            >
              <FormattedMessage
                id="module-auth-privacyPolicyLink"
                defaultMessage="Privacy Policy"
              />
            </a>
          </div>
        </PageExtraContent>
        {Context && <Context url={url} />}
      </PageContent>
    </PageWrapper>
  );
};

NotSignedInLayoutDividedInTwo.propTypes = {
  Page: PropTypes.func.isRequired,
  Context: PropTypes.func.isRequired,
};

NotSignedInLayoutDividedInTwo.displayName = 'NotSignedInLayoutDividedInTwo';

export default pure(NotSignedInLayoutDividedInTwo);
