import React from 'react';

const labelStyle = {
  fontWeight: 'bold',
};

const formDecoratorStyle = {
  marginBottom: '10px',
};

const FormDecorator = ({ label, isRequired, className, children }) => {
  let labelContent;
  let isRequiredContent;

  if (isRequired) {
    isRequiredContent = <span style={{ color: 'red' }}>&nbsp;*</span>;
  }

  if (label) {
    labelContent = (
      <label style={labelStyle}>
        {label}
        {isRequiredContent}
      </label>
    ); //eslint-disable-line
  }
  return (
    <div style={formDecoratorStyle}>
      {labelContent}
      <div className={className}>{children}</div>
    </div>
  );
};

export default FormDecorator;
