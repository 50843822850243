import { makeLazy } from '../core/components/Hocs/LazyRoute';

export const getLink = {
  home: () => '/admin/testing/entity-viewers/',
};

export default [
  {
    path: '/',
    exact: true,
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"EntityViewerTester" */
        './components/pages/EntityViewerTester'
      ),
    ),
  },
  {
    path: '/property-list',
    exact: true,
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"EntityViewerPropertyTester" */
        './components/pages/EntityViewerPropertyTester'
      ),
    ),
  },
];
