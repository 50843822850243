import React from 'react';
import PropTypes from 'prop-types';
import { getEntityTypeIcon } from '@cluedin/components';
import { Circle } from 'uxi/Icons';
import styled from 'styled-components';
import EntityIconStyle from './EntityIcon.style';

const IconWrapper = styled.div`
  .cluedIn_EntityIcon_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%';
  }
`;

const getWrapperStyles = ({ size }) => ({
  ...EntityIconStyle.wrapper,
  ...EntityIconStyle.sizes[size],
});

const getFontStyles = (size) => EntityIconStyle.fontSizes[size];

const EntityIcon = (props) => {
  const { size, icon } = props;
  /**
   * this if test sole purpose is to keep supporting fontaweosme
   * but eventually it should be removed once the transiton ic omplete
   */
  let IconMarkup = null;
  if (icon && icon.match(/^<i/)) {
    IconMarkup = (
      <IconWrapper>
        <div
          key="markup"
          style={{
            ...getFontStyles(size),
            ...EntityIconStyle.styleIconWrapper,
          }}
          dangerouslySetInnerHTML={{ __html: icon }}
        />
      </IconWrapper>
    );
  } else if (icon === '' || icon === null) {
    const iconSize = EntityIconStyle.iconSizes[props.size];

    const cleanedProps = {
      color: props.color,
      icon: props.icon,
      size: props.size,
      style: props.style,
      ...iconSize,
    };

    IconMarkup = <Circle {...cleanedProps} />;
  } else {
    const Element = getEntityTypeIcon(icon || 'Help');
    const iconSize = EntityIconStyle.iconSizes[props.size];

    const cleanedProps = {
      color: props.color,
      icon: props.icon,
      size: props.size,
      style: props.style,
      ...iconSize,
    };

    IconMarkup = <Element {...cleanedProps} />;
  }
  return (
    <div style={{ ...getWrapperStyles(props) }}>
      <div style={{ ...EntityIconStyle.icon }}>{IconMarkup}</div>
    </div>
  );
};

EntityIcon.propTypes = {
  size: PropTypes.oneOf(['M', 'S', 'L']),
  style: PropTypes.object,
  icon: PropTypes.string,
  color: PropTypes.string,
};

EntityIcon.defaultProps = {
  size: 'M',
  style: {},
  icon: 'Default',
  color: null,
};

export default EntityIcon;
