export const checkIfFetching = (props) => !!props.isFetching;

const checkIfNotFound = (props) => !props.entity && !props.isFetching;

const checkIfDone = (props) => props.done;

export const isDone =
  (options = {}) =>
  (props) =>
    options.normalizeProps
      ? checkIfDone(options.normalizeProps(props))
      : checkIfDone(props);

export const isFetching =
  (options = {}) =>
  (props) =>
    options.normalizeProps
      ? checkIfFetching(options.normalizeProps(props))
      : checkIfFetching(props);

export const isEntityNotFound =
  (options = {}) =>
  (props) =>
    options.normalizeProps
      ? checkIfNotFound(options.normalizeProps(props))
      : checkIfNotFound(props);

const checkIfAccessDenied = (props) => !!props.accessDenied;

export const isAccessDenied =
  (options = {}) =>
  (props) =>
    options.normalizeProps
      ? checkIfAccessDenied(options.normalizeProps(props))
      : checkIfAccessDenied(props);

const checkIfInvalid = (props) => !!props.invalid;

export const isInvalid =
  (options = {}) =>
  (props) =>
    options.normalizeProps
      ? checkIfInvalid(options.normalizeProps(props))
      : checkIfInvalid(props);

const checkIfNoEntities = (props) =>
  !props.entities || props.entities.length === 0;

export const ifNoEntities =
  (options = {}) =>
  (props) =>
    options.normalizeProps
      ? checkIfNoEntities(options.normalizeProps(props))
      : checkIfNoEntities(props);

export default {
  isFetching,
  isEntityNotFound,
  isAccessDenied,
  isInvalid,
  ifNoEntities,
};
