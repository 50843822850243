import { useId } from 'react';
import styled from 'styled-components';
import {
  PrimaryButton,
  Button,
  IconButton,
  ContextualMenu,
} from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { Tabularview, Tileview } from 'uxi/Icons';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Deduplication } from '@cluedin/svgs';
import { Icon } from '@fluentui/react/lib/Icon';

const GoldenRecordListFilterToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: ${({ selectionActive }) => !selectionActive && 'flex-end'};
`;

const GoldenRecordListSidesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ViewControlWrapper = styled.div`
  display: inline-flex;
  width: 64px;
  margin-right: 4px;

  button {
    min-width: 32px;
    padding: 0;
    border-color: ${({ theme }) => theme.palette.neutralQuaternaryAlt};
    box-shadow: none !important;

    i {
      margin: 0;
    }
  }

  div:first-of-type button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  div:last-of-type button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`;

const RecordListToolbar = ({
  intl,
  sortType,
  viewMode,
  noFilters,
  setViewMode,
  hasSelection,
  startMerging,
  sortingOptions,
  onCustomizeView,
  toggleSelection,
  selectionActive,
  onSortTypeChange,
  hasMinimumSelected,
}) => {
  const theme = useTheme();
  const tileModeTooltipId = useId();
  const listModeTooltipId = useId();
  const cancelSectionTooltipId = useId();
  const previewMergeTooltipId = useId();

  const TileBtn = !viewMode || viewMode === 'tile' ? PrimaryButton : Button;
  const TableBtn = viewMode === 'table' ? PrimaryButton : Button;

  const handleChangeViewMode = (mode) => {
    if (viewMode === mode) {
      return;
    }

    setViewMode(mode);
  };

  return (
    <GoldenRecordListFilterToolbarWrapper
      className="GoldenRecordListFilterToolbar"
      selectionActive={selectionActive}
    >
      {selectionActive && (
        <GoldenRecordListSidesWrapper>
          <TooltipHost
            id={cancelSectionTooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={intl.formatMessage({
              id: 'module-goldenRecordList-cancel-selection',
            })}
          >
            <IconButton
              onClick={toggleSelection}
              style={{ marginRight: '6px' }}
              iconProps={{ iconName: 'Cancel' }}
            ></IconButton>
          </TooltipHost>

          <TooltipHost
            id={previewMergeTooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={intl.formatMessage({
              id: hasMinimumSelected
                ? 'module-goldenRecordList-merge-preview'
                : 'module-goldenRecordList-merge-preview-disabled',
            })}
          >
            <IconButton onClick={startMerging} disabled={!hasMinimumSelected}>
              <Deduplication width={18} height={18} size={18} />

              <span style={{ marginLeft: '6px' }}>
                {intl.formatMessage({
                  id: 'module-goldenRecordList-start-merging',
                })}
              </span>
            </IconButton>
          </TooltipHost>
        </GoldenRecordListSidesWrapper>
      )}

      <GoldenRecordListSidesWrapper selectionActive={selectionActive}>
        <ViewControlWrapper theme={theme}>
          <TooltipHost
            id={tileModeTooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={intl.formatMessage({
              id: 'module-goldenRecordList-tile-view-mode',
            })}
          >
            <TileBtn
              disabled={selectionActive}
              startIcon={<Tileview size={14} />}
              onClick={() => {
                handleChangeViewMode('tile');
              }}
            />
          </TooltipHost>

          <TooltipHost
            id={listModeTooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={intl.formatMessage({
              id: 'module-goldenRecordList-list-view-mode',
            })}
          >
            <TableBtn
              disabled={selectionActive}
              startIcon={<Tabularview size={14} />}
              onClick={() => {
                handleChangeViewMode('table');
              }}
            />
          </TooltipHost>
        </ViewControlWrapper>

        <ContextualMenu
          styles={{ padding: '20px' }}
          buttonType="IconButton"
          onlyCustomIcon
          items={[
            hasSelection && {
              disabled: selectionActive,
              iconProps: {
                iconName: 'Add',
              },
              key: 'toggleSelection',
              onClick: toggleSelection,
              text: intl.formatMessage({
                id: 'module-goldenRecordList-select-for-merge',
              }),
            },
            hasSelection && { key: 'divider_1', itemType: 1 },
            {
              iconProps: {
                iconName: 'Settings',
              },
              key: 'tile-view',
              onClick: onCustomizeView,
              text: intl.formatMessage({
                id: 'module-goldenRecordList-customizeColumns',
              }),
            },
          ].filter(Boolean)}
          IconButtonContent={
            <Icon
              styles={{
                root: { lineHeight: 0, fontSize: 18 },
              }}
              iconName={'MoreVertical'}
            />
          }
        />
      </GoldenRecordListSidesWrapper>
    </GoldenRecordListFilterToolbarWrapper>
  );
};

export default injectIntl(RecordListToolbar);
