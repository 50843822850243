import { useLazyQuery, gql } from '@apollo/client';
import get from 'lodash/get';

const GET_MANUAL_MERGE_NODE_INFO = gql`
  query getManualMergeNodeInfo($entityId: ID!, $mergeDataPartId: ID!) {
    virtualization {
      getManualMergeNodeInfo(
        entityId: $entityId
        mergeDataPartId: $mergeDataPartId
      ) {
        kind
        name
        description
        actions {
          kind
          availability
          unavailableReason
          reasonMessage
          mergeDataPartId
          userId
          dataParts {
            dataPartId
            name
            sortDate
            provider {
              providerDefinitionId
              name
              providerId
              providerName
              providerIcon
            }
            source
          }
          actionDescriptor
          splitEntityActionRequest
        }
        userId
      }
    }
  }
`;

export const useGetManualMergeNodeInfo = () => {
  const [getManualMergeNodeInfo, { data, loading, error }] = useLazyQuery(
    GET_MANUAL_MERGE_NODE_INFO,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    },
  );

  const result = get(data, 'virtualization.getManualMergeNodeInfo');

  return [
    (entityId, mergeDataPartId) =>
      getManualMergeNodeInfo({ variables: { entityId, mergeDataPartId } }),
    {
      data: result,
      loading,
      error,
    },
  ];
};
