import { RACI_LEVELS, actionsConfiguration } from '@cluedin/components';

import routes from './routes';

export const cleanProjectRootURL = '/admin/preparation/new-clean-project';

export default {
  pillar: 'preparation',
  name: 'Clean Project',
  displayName: 'Clean',
  path: '/new-clean-project',
  claims: {
    'preparation.clean': RACI_LEVELS.INFORMED,
  },
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Datacleaning',
        name: 'Clean',
        link: cleanProjectRootURL,
        featureName: 'New Clean Project',
        claims: actionsConfiguration.preparation.clean.project.view,
      },
    ],
  },
  routes,
};
