import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';

const { engineRoom } = actionsConfiguration;

export default {
  pillar: 'engineRoom',
  name: 'pipeline',
  displayName: <FormattedMessage id="pipeline" />,
  path: '/processing',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'industry',
        name: <FormattedMessage id="pipeline" />,
        link: '/admin/engine-room/processing',
        claims: engineRoom.processing.pipeline.view,
      },
    ],
  },
  routes,
};
