import { useState } from 'react';
import store from 'store';
import { ListGhostLoading } from '@cluedin/components';
import { List as ListPattern } from '@cluedin/list-pattern';
import TileList from '../tile/TileList';
import TileGridGhostLoading from '../tile/TileGridGhostLoading';

import {
  defaultTileConfiguration,
  defaultConfiguration,
  createVocabProperty,
  createEntityProperty,
} from '../../../../helpers/columnConfiguration';

const viewModeConfig = {
  tile: defaultTileConfiguration,
  table: defaultConfiguration,
};

const columnsRestriction = {
  tile: {
    maxNumberColumns: 2,
    excludePropertyKeys: ['name'],
  },
  table: {
    maxNumberColumns: 40,
    excludePropertyKeys: ['name', 'entityType'],
  },
};

const defaultComponentGhost = {
  tile: TileGridGhostLoading,
  table: ListGhostLoading,
};

const defaultComponent = {
  tile: TileList,
  table: ListPattern,
};

const createColumnConfiguration = (
  viewMode,
  defaultVocabularyKeys = [],
  defaultEntityKeys = [],
) => {
  let initialViewMode = viewModeConfig[viewMode];

  const vocabProperties = defaultVocabularyKeys.map((v) =>
    createVocabProperty(v),
  );

  if (defaultEntityKeys.length > 0) {
    const updateInitialViewMode = initialViewMode
      .filter((eP) => eP.fixed)
      .filter(
        (c) => !defaultEntityKeys.find((dk) => dk.property === c.property),
      );
    const entityProperties = defaultEntityKeys.map((eP) =>
      createEntityProperty(eP),
    );

    initialViewMode = [...updateInitialViewMode, ...entityProperties];
  }

  return [...initialViewMode, ...vocabProperties];
};

export const useViewMode = (viewName, options = {}) => {
  const {
    initialViewMode = 'table',
    defaultVocabularyKeys = [],
    defaultEntityKeys = [],
  } = options;

  const storedViewMode = store.get(`${viewName}-viewMode`);
  const selectedViewMode = storedViewMode || initialViewMode;
  const [viewMode, setViewMode] = useState(selectedViewMode);

  let columnsConfiguration = createColumnConfiguration(
    viewMode,
    defaultVocabularyKeys,
    defaultEntityKeys,
  );

  return [
    viewMode,
    `${viewName}-${viewMode}`,
    (v) => {
      setViewMode(v);
      store.set(`${viewName}-viewMode`, v);
    },
    columnsConfiguration,
    defaultComponent[viewMode],
    defaultComponentGhost[viewMode],
    columnsRestriction[viewMode],
  ];
};

export default useViewMode;
