import React from 'react';
import { withWorkflow } from '@cluedin/form';

import DeduplicationProjectMergePanel from '../components/containers/DeduplicationProjectMergePanel/DeduplicationProjectMergePanel';

const DeduplicationProjectMergePanelWrapper =
  (Comp) =>
  ({ openWorkflow, ...props }) =>
    (
      <Comp
        openDeduplicationProjectMergePanelWorkflow={openWorkflow}
        {...props}
      />
    );

export const withDeduplicationProjectMergePanelWorkflow = (Comp) =>
  withWorkflow(
    DeduplicationProjectMergePanelWrapper(Comp),
    DeduplicationProjectMergePanel,
    {
      withCustomHeader: true,
      panelType: 'custom',
      width: 1200,
    },
  );
