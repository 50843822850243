import styled from 'styled-components';
import { Fullscreen } from 'uxi/Icons';
import { IconButton } from '@cluedin/form';

export const WidgetTitle = styled.div`
  color: #2f2f32;
  font-weight: 600;
  font-size: 16px;
  flex: 1;
`;

export const WidgetContainer = styled.div`
  border: 1px solid #ececec;
  box-sizing: border-box;
  background: white;
  border-radius: 2px;
`;

const WidgetHeaderWrapper = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid #ececec;
  display: flex;
  height: 48px;
  align-items: center;
  padding-left: 12px;
`;

const WidgetMenu = styled.div``;

export const WidgetHeader = ({ menu, title }) => {
  return (
    <WidgetHeaderWrapper>
      <WidgetTitle>{title}</WidgetTitle>
      {menu && <WidgetMenu>{menu}</WidgetMenu>}
    </WidgetHeaderWrapper>
  );
};

export const WidgetFullScreen = ({ onClick }) => {
  return (
    <>
      <IconButton onClick={onClick} style={{ marginRight: '6px' }}>
        <Fullscreen size={12} />
      </IconButton>
    </>
  );
};
