import React from 'react';
import { get } from 'lodash';
import { GqlErrorMessages } from '@cluedin/form';
import IntegrationConfigurationAuthSettingsForm from './IntegrationConfigurationAuthSettingsForm';
import { useSaveIntegrationConfigurationAuth } from '../../../hooks/useSaveIntegrationConfigurationAuth';

const IntegrationConfigurationAuthSettings = ({
  integration,
  configuration,
}) => {
  const authMethods = get(integration, 'AuthMethods', {});
  const authValues = get(configuration, 'helperConfiguration');

  const [saveAuthmethod, { loading, error }] =
    useSaveIntegrationConfigurationAuth(configuration.Id);

  return (
    <>
      {error && <GqlErrorMessages error={error} />}
      <IntegrationConfigurationAuthSettingsForm
        style={{
          background: '#fff',
          padding: '16px',
        }}
        authMethods={authMethods}
        authValues={authValues}
        saveAuthmethod={saveAuthmethod}
        loading={loading}
        integration={integration}
        configuration={configuration}
      />
    </>
  );
};

export default IntegrationConfigurationAuthSettings;
