import { connect } from 'react-redux';
import defaultViewOptions from '../../defaultViewOptions';
import { shouldChangeViewMode, shouldOpenViewOptions } from '../../actions';

const mapDispatchToProps = (dispatch, { viewName }) => ({
  changeViewMode: (viewMode) => {
    dispatch(shouldChangeViewMode(viewName, viewMode));
  },
  viewOptions: () => {
    dispatch(shouldOpenViewOptions());
  },
});

const mapStateToProps = (
  { entityMerge: { isMerging }, entityLists: { viewOptions } },
  { viewName },
) => {
  let viewConfig = viewOptions?.find((v) => v.name === viewName);

  if (isMerging || !viewConfig) {
    viewConfig = defaultViewOptions?.find((v) => v.name === viewName);
  }
  return {
    viewMode: viewConfig?.viewMode,
  };
};

export const withViewMode = (Comp) => {
  return connect(mapStateToProps, mapDispatchToProps)(Comp);
};

export default {
  withViewMode,
};
