import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

const VocabularyKeyClassification = ({ dataClassificationCode }) => {
  return (
    <>
      {dataClassificationCode}
      {!dataClassificationCode && (
        <div style={{ fontStyle: 'italic', color: '#9b9b9c' }}>
          <FormattedMessage id="module-dataCatalog-noClassificationVocabKey" />
        </div>
      )}
    </>
  );
};

export default VocabularyKeyClassification;
