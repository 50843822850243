import React from 'react';
import { FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import Flex from 'uxi/Layout/Flex';

const OrgClientIdInvalidComponent = () => {
  return (
    <Flex style={{ minHeight: '200px' }}>
      <Alert>
        <FormattedHTMLMessage id="module-public-OrgClientIdInvalidComponent" />
      </Alert>
    </Flex>
  );
};

export default OrgClientIdInvalidComponent;
