import React from 'react';
import { injectIntl } from '@cluedin/locale';
import { FormattedMessage } from '@cluedin/locale';
import { FormDecorator, TextField } from '@cluedin/form';

const FileUploadDataSourceNameField = ({
  dataSourceName,
  onDataSourceNameChange,
  errorDataSourceName,
  button,
  intl,
}) => (
  <FormDecorator
    isRequired
    label={<FormattedMessage id="data-source-name" />}
    helpText={<FormattedMessage id="data-source-add-help-text" />}
    errorText={errorDataSourceName}
    childrenWrapperStyles={{ margin: '0 0 8px 0' }}
  >
    <div className="_test_DataSourceName" style={{ display: 'flex' }}>
      <TextField
        data-test={'dataSourceName'}
        value={dataSourceName}
        placeholder={intl.formatMessage({ id: 'data-source-enter-name' })}
        onChange={onDataSourceNameChange}
        hasError={!!errorDataSourceName}
      />
      {button}
    </div>
  </FormDecorator>
);

export default injectIntl(FileUploadDataSourceNameField);
