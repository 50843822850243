import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { matchPath } from 'react-router-dom';
import store from 'store';
import IntegrationManagementContainer from '../containers/IntegrationManagementContainer';
import PageLoader from '../../../core/components/composites/PageLoader';
import { shouldFetchIntegrationById } from '../../actions';

const ConfigurationPageWrapper = ({
  children,
  selectedIntegration,
  location,
  integrationId,
}) => (
  <IntegrationManagementContainer
    integration={selectedIntegration}
    integrationId={integrationId}
    pathname={location.pathname}
  >
    {children}
  </IntegrationManagementContainer>
);

const EnhancedConfigurationPageWrapper = compose(
  lifecycle({
    componentDidMount() {
      const { shouldFetchData, integrationId } = this.props;
      if (integrationId) {
        shouldFetchData(integrationId);
      }
    },
  }),
  branch(
    (props) =>
      !props.selectedIntegration || props.isFetchingSelectedIntegration,
    renderComponent(PageLoader),
  ),
)(ConfigurationPageWrapper);

const mapToStateProps = (
  { integration: { isFetchingSelectedIntegration, selectedIntegration } },
  { location },
) => {
  const { pathname } = location;
  let match;

  if (pathname.toLowerCase().indexOf('/admin/integrations/callback') === 0) {
    match = matchPath(pathname, {
      path: '/admin/integrations/callback/:name',
    });
  } else if (pathname.toLowerCase().indexOf('/admin/integrations/new') === 0) {
    match = matchPath(pathname, {
      path: '/admin/integrations/new/:integrationId',
    });
  } else if (
    pathname.toLowerCase().indexOf('/admin/integrations/oauth') === 0
  ) {
    match = matchPath(pathname, {
      path: '/admin/integrations/oauth/:integrationId',
    });
  } else if (pathname.toLowerCase().indexOf('/admin/integrations/edit') === 0) {
    match = matchPath(pathname, {
      path: '/admin/integrations/edit/:integrationId/:configurationId',
    });
  }

  const params = match ? match.params : {};

  let guid;
  if (params && params.integrationId) {
    guid = params.integrationId;
  } else {
    const integrationName = params && params.name ? params.name : null;

    if (!integrationName) {
      console.error(
        'cannot fetch integration, we cannot find any params from the url',
      );
    } else {
      guid = store.get(integrationName);
      if (!guid) {
        console.error(
          'cannot fetch integration, the name is in the url, but the value is not in localstorage',
        );
      }
    }
  }

  return {
    integrationId: guid,
    isFetchingSelectedIntegration,
    selectedIntegration,
  };
};

const mapToDispatchProps = (dispatch) => ({
  shouldFetchData(integrationId) {
    dispatch(shouldFetchIntegrationById(integrationId));
  },
});

export default connect(
  mapToStateProps,
  mapToDispatchProps,
)(EnhancedConfigurationPageWrapper);
