import React from 'react';
import { Profile } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import TextEllipsis from 'uxi/Text/TextEllipsis';

export const UserMenuProfileSingedInAs = ({ username, theme }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        width: '40px',
        marginRight: '8px',
        height: '40px',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.themePrimary,
      }}
    >
      <Profile color="#fff" size={24} />
    </div>

    <div>
      <FormattedMessage id="module-core-userMenuSignedInAs" />
      <TextEllipsis style={{ fontWeight: 'bold', maxWidth: '200px' }}>
        {username}
      </TextEllipsis>
    </div>
  </div>
);
