"use strict";

exports.__esModule = true;
exports.toggleMark = exports.toggleBlock = exports.isMarkActive = exports.isBlockActive = void 0;
var _slate = require("slate");
var LIST_TYPES = ['numbered-list', 'bulleted-list'];
var isMarkActive = function isMarkActive(editor, format) {
  var marks = _slate.Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
exports.isMarkActive = isMarkActive;
var toggleMark = function toggleMark(editor, format) {
  var isActive = isMarkActive(editor, format);
  if (isActive) {
    _slate.Editor.removeMark(editor, format);
  } else {
    _slate.Editor.addMark(editor, format, true);
  }
};
exports.toggleMark = toggleMark;
var toggleBlock = function toggleBlock(editor, format) {
  var isActive = isBlockActive(editor, format);
  var isList = LIST_TYPES.includes(format);
  _slate.Transforms.unwrapNodes(editor, {
    match: function match(n) {
      return LIST_TYPES.includes(n.type);
    },
    split: true
  });
  _slate.Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  });
  if (!isActive && isList) {
    var block = {
      type: format,
      children: []
    };
    _slate.Transforms.wrapNodes(editor, block);
  }
};
exports.toggleBlock = toggleBlock;
var isBlockActive = function isBlockActive(editor, format) {
  var _Editor$nodes = _slate.Editor.nodes(editor, {
      match: function match(n) {
        return n.type === format;
      }
    }),
    match = _Editor$nodes[0];
  return !!match;
};
exports.isBlockActive = isBlockActive;