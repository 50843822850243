import Oidc from 'oidc-client';
import { urlBuilder, clientId, authUrl, buildUrl } from '../../config';
import { saveTokenForClientId } from '../core/helpers/storage';
import { console } from 'window-or-global';

const config = {
  authority: authUrl,
  client_id: clientId,
  redirect_uri: urlBuilder.currentClientId('ssocallback'),
  response_type: 'code',
  scope: 'openid profile ServerApiForUI offline_access',
  post_logout_redirect_uri: urlBuilder.currentClientId('logout'),
};

const createSsoConfig = (ssoConfig) => {
  const extendedConfig = {
    ...config,
  };

  if (ssoConfig.Authority) {
    extendedConfig.authority = ssoConfig.Authority;
  }

  if (ssoConfig.LoginUrl) {
    extendedConfig.redirect_uri = ssoConfig.LoginUrl;
  }

  if (ssoConfig.LogoutUrl) {
    extendedConfig.post_logout_redirect_uri = ssoConfig.LogoutUrl;
  }

  return extendedConfig;
};

export const loginWithSSO = (ssoConfig) => {
  const extendedConfig = createSsoConfig(ssoConfig);
  const client = new Oidc.OidcClient(extendedConfig);
  const challengeUrl = buildUrl(ssoConfig.Authority, ssoConfig.ChallengeUrl);

  client.createSigninRequest({ request_type: 'si:r' }).then((signinRequest) => {
    // 'url' Must be a relative uri - just remove host name and port
    var url = new URL(signinRequest.url);
    const pathName = url.pathname + url.search;

    // Add generated url to cached login url
    const ssoURL = challengeUrl + encodeURIComponent(pathName);
    // redirect user to SSO endpoint
    window.location = ssoURL;
  });
};

const fromEpochToSeconds = (epochValue) => {
  const nowInEpoc = Math.floor(new Date() / 1000);
  return Math.round(epochValue - nowInEpoc);
};

export const signInAndRedirect = (ssoConfig) => {
  const extendedConfig = createSsoConfig(ssoConfig);

  const mgr = new Oidc.UserManager({
    ...extendedConfig,
  });

  let onlyRedirectOnce = false;

  new Oidc.UserManager({
    response_mode: 'query',
  })
    .signinRedirectCallback()
    .then(() => {
      mgr
        .getUser()
        .then((token) => {
          saveTokenForClientId(
            {
              ...token,
              accessToken: token.access_token,
              refreshToken: token.refresh_token,
              expiresIn: token.expires_at
                ? fromEpochToSeconds(token.expires_at) - 180
                : 900,
            },
            clientId,
          );
          window.location = '/';
        })
        .catch((e) => {
          console.error(e);
        });
    })
    .catch(() => {
      if (!onlyRedirectOnce) {
        onlyRedirectOnce = true;
        window.location = '/signin';
      }
    });
};
