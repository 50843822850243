import React from 'react';
import styled from 'styled-components';
import { ButtonLink } from '@cluedin/atoms';

const ButtonLinkElement = styled(ButtonLink)`
  &:hover {
    color: ${({ themePrimary }) => themePrimary};
  }
`;

const PillarLink = ({ themePrimary, topic, onTopicClick }) => {
  return (
    <>
      <ButtonLinkElement
        themePrimary={themePrimary}
        text={`${topic?.pillar?.title}`}
        onClick={() => {
          onTopicClick({
            topicId: topic?.pillar?.id,
            type: 'pillar',
          });
        }}
      />
    </>
  );
};

export default PillarLink;
