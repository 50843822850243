export const sortByCount = (a, b) => {
  if (a.count < b.count) {
    return 1;
  }
  if (a.count > b.count) {
    return -1;
  }

  return 0;
};

export default {
  sortByCount,
};
