import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

type EntitySource = {
  virtualization: {
    entitySources: {
      data: {
        property: string;
      };
    };
  };
};

interface EntitySourcesData {
  data: EntitySource[];
}

interface EntitySourcesVariables {
  id: string;
}

type EntitySourceOutput = {
  property: string;
};

const GET_ENTITY_SOURCES = gql`
  query entitySources($id: ID!) {
    virtualization {
      entitySources(id: $id) {
        data {
          property
        }
      }
    }
  }
`;

export const useQueryEntitySources = (id: string) => {
  const { data, loading, error } = useQuery<
    EntitySourcesData,
    EntitySourcesVariables
  >(GET_ENTITY_SOURCES, {
    variables: { id },
  });

  const entitySources: EntitySourceOutput[] =
    get(data, 'virtualization.entitySources.data') ?? [];

  return { data: entitySources, loading, error };
};
