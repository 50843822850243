import { gql, useQuery, useLazyQuery } from '@apollo/client';
import get from 'lodash/get';

const GET_ALL_DATASETS = gql`
  query getDataStets(
    $dataSourceId: ID!
    $page: Int
    $pageSize: Int
    $annotationId: ID
  ) {
    inbound {
      dataSets(
        dataSourceId: $dataSourceId
        page: $page
        pageSize: $pageSize
        annotationId: $annotationId
      ) {
        total
        data {
          id
          isBridge
          isArchive
          archivedBy
          archivedByUser {
            id
            username
          }
          configuration
          name
          hasError
          fileSize
          currentLoadedSize
          failedLoadingTotal
          isDataSetRetryLoading
          hasValidationParsingError
          hasFailureInLoading
          fieldMappings {
            originalField
            key
          }
          author {
            id
            username
          }
          annotation {
            id
            originEntityCodeKey
            vocabulary {
              vocabularyName
              vocabularyId
              providerId
              keyPrefix
              isCluedInCore
              connector {
                about
                icon
                id
                name
              }
            }
          }
          createdAt
          updatedAt
          annotationId
          elasticTotal
          expectedTotal
          dataSource {
            id
            type
            stopped
            crashedDuringUpload
          }
          stats {
            successful
            bytes
            failed
            total
          }
        }
      }
    }
  }
`;

export const useLazyQueryDataSetsByAnnotationId = (page, pageSize = 100) => {
  const [getDataSets, { data, loading, error }] = useLazyQuery(
    GET_ALL_DATASETS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const dataSets = get(data, 'inbound.dataSets', {});

  return [
    (annotationId) => {
      getDataSets({
        variables: {
          annotationId,
          page: page - 1,
          pageSize,
        },
      });
    },
    {
      data: dataSets,
      loading,
      error,
    },
  ];
};

export const useQueryDataSets = (dataSourceId, page, pageSize) => {
  const { data, refetch, loading, error } = useQuery(GET_ALL_DATASETS, {
    variables: {
      dataSourceId,
      page: page - 1,
      pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const dataSets = get(data, 'inbound.dataSets', {});

  return [
    dataSets,
    loading,
    error,
    () => {
      refetch({
        variables: {
          dataSourceId,
          page: page - 1,
          pageSize,
        },
      });
    },
  ];
};
