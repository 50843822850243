import { FormattedMessage } from '@cluedin/locale';
import { withRouter } from 'react-router-dom';
import notFound from '../../assets/notfound.png';
import { onlyAuth } from '../../../auth/components/Hocs/onlyAuth';
import { getCurrentToken } from '../../../../config';

const styles = {
  wrapper: {
    margin: '0 16px',
  },
  hiddenStyles: {
    opacity: 0,
    pointerEvents: 'none',
  },
};

const NotFound = ({ onBack, message = '', explanation, history }) => (
  <div style={{ margin: '0 auto', textAlign: 'center' }}>
    <div style={{ marginTop: '50px' }}>
      <img alt="Not Found" src={`${notFound}`} />
    </div>
    <div style={{ padding: '16px', fontSize: '20px' }}>
      {message || <FormattedMessage id="module-error-notFound" />}
    </div>
    {explanation && <div>{explanation}</div>}
  </div>
);

const token = getCurrentToken();

export default withRouter(onlyAuth(NotFound, token));
