import { useEffect, useState } from 'react';
import { get, map, pick } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useSearch } from '@cluedin/components';

import { useEntityFilters } from './useEntityFilters';
import { useEntitySearchQuery } from './useEntitySearchQuery';
import { usePaging } from '../../core/hooks/usePaging';
import { adaptRulesToSave } from '../../rule/utils/ruleAdapters';

const getSortValue = (sortType, sortOptions) => {
  if (sortOptions && sortOptions.length > 0) {
    const hasFound = sortOptions.find((s) => s.value === sortType);

    if (hasFound) {
      return {
        sort: hasFound.sort,
        sortDirection: hasFound.sortDirection,
      };
    }
  }

  if (sortType === 'new') {
    return {
      sort: 'DATE',
      sortDirection: 'DESCENDING',
    };
  }

  if (sortType === 'old') {
    return {
      sort: 'DATE',
      sortDirection: 'ASCENDING',
    };
  }

  return {
    sort: 'RELEVANCE',
    sortDirection: 'DESCENDING',
  };
};

const createFilterGqlVariable = (filters = {}) => {
  return Object.keys(filters)
    .map((k) => {
      const filterItem = filters[k];

      if (!filterItem || !filterItem) {
        return;
      }

      return {
        aggregationName: k,
        operator: 'OR',
        values: filterItem,
      };
    })
    .filter(Boolean);
};

const createAdvancedFilter = (f) => {
  if (f.property === 'property') {
    return {
      fieldName: `properties.${f.displayName}`,
      operator: f.operator || 'AND',
      values: [f.value],
    };
  }

  return {
    fieldName: f.displayName,
    operator: 'AND',
    values: [f.value],
  };
};

export const useEntitySearch = ({
  initialVariables = {},
  defaultPageSize = 20,
  defaultSortType = 'relevance',
  defaultEntityType,
  defaultTags,
  defaultProviders,
  defaultProviderDefinitionIds,
  defaultFixedFilter: filter,
  sortOptions,
  defaultTerm,
  searchNameKey = 'searchName',
  advancedFiltersMode = false,
}) => {
  const history = useHistory();
  const location = useLocation();

  const pageSize = initialVariables.pageSize || defaultPageSize;
  const initialSortType = initialVariables.sortType || defaultSortType;

  const [cursor, currentPage, setCurrentPage] = usePaging(
    history,
    location,
    pageSize,
  );

  const [searchName, setSearchName] = useSearch(history, location, {
    searchName: searchNameKey,
  });

  const [searchNameInput, setSearchNameInput] = useState(searchName);

  const [filters, setFilters] = useEntityFilters(history, location, {
    sortType: initialSortType,
    entityType: initialVariables.entityType,
    tags_name: null,
    providers: null,
    condition: null,
    providerDefinitionIds_v2: null,
    advanced: null,
    sortBy: initialVariables.sortBy || null,
    sortDirection: initialVariables.sortDirection || null,
  });

  const sortDirections = filters.sortType
    ? getSortValue(filters.sortType, sortOptions)
    : {};

  const filtersToFormat = pick(filters, [
    'entityType',
    'providers',
    'tags_name',
    'providerDefinitionIds_v2',
  ]);

  // if Entity Type is hardcoded - we use that
  if (defaultEntityType) {
    filtersToFormat.entityType = defaultEntityType;
  }

  if (defaultProviders) {
    filtersToFormat.providers = defaultProviders;
  }

  if (defaultTags) {
    filtersToFormat.tags_name = defaultTags;
  }

  if (defaultProviderDefinitionIds) {
    filtersToFormat.providerDefinitionIds_v2 = defaultProviderDefinitionIds;
  }

  const filtersGql = createFilterGqlVariable(filtersToFormat);

  const advancedFiltersGql = map(
    get(filters, ['advanced']),
    createAdvancedFilter,
  );

  const gqlVariables = {
    query: searchName || '*',
    pageSize,
    cursor,
    ...sortDirections,
    rule: filters.condition
      ? JSON.stringify({
          ...filters.condition,
          rules: adaptRulesToSave(filters.condition?.rules || []),
        })
      : undefined,
    filters: [...filtersGql, ...advancedFiltersGql],
    filter,
  };

  if (filters.sortBy) {
    gqlVariables.sortFields = [
      {
        direction:
          filters.sortDirection === 'desc' ? 'Descending' : 'Ascending',
        field: `properties.${filters.sortBy}`,
      },
    ];

    gqlVariables.sort = 'FIELDS';
  }

  if (defaultTerm) {
    gqlVariables.terms = [{ id: defaultTerm }];
  }

  const [data, status] = useEntitySearchQuery(gqlVariables);
  const hasFilters = searchName || Object.keys(filters || {}).length > 0;

  const nextPage = (serverCursor) => {
    status?.fetchMore({
      variables: {
        ...gqlVariables,
        cursor: serverCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newData = {
          aggregations:
            fetchMoreResult.search.aggregations ?? prev.search.aggregations,
          cursor: fetchMoreResult.search.cursor ?? prev.search.cursor,
          entries: [
            ...prev.search.entries,
            ...(fetchMoreResult.search.entries ?? []),
          ],
        };

        return {
          search: newData,
        };
      },
    });
  };

  useEffect(() => {
    if (
      !status.loading &&
      !status.loadingMore &&
      (!data?.entities || data.entities.length === 0) &&
      currentPage.selectedPage > 1
    ) {
      setCurrentPage(1);
    }
  }, [data, status, currentPage]);

  return [
    data,
    status,
    {
      pageSize,
      selectedPage: currentPage.selectedPage,
      setCurrentPage,
      searchName,
      setSearchName,
      searchNameInput,
      setSearchNameInput,
      filters,
      setFilters,
      gqlVariables,
      hasFilters,
      nextPage,
    },
  ];
};

export default useEntitySearch;
