import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BreadCrumb } from './composites/BreadCrumb';

const mapStateToProps = (
  { coreModule: { currentModule, currentTitle } },
  { history },
) => ({
  currentModule,
  currentTitle,
  LinkComponent: Link,
  onNavigate: (to) => {
    history.push(to);
  },
});

export default withRouter(connect(mapStateToProps)(BreadCrumb));
