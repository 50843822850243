import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import { Aggregate } from 'uxi/Icons';

const { governance } = actionsConfiguration;

export default {
  pillar: 'governance',
  name: 'metrics',
  displayName: <FormattedMessage id="metrics" />,
  path: '/metrics',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Aggregate,
        name: <FormattedMessage id="metrics" />,
        link: '/admin/governance/pii',
        claims: governance.metrics.metric.view,
      },
    ],
  },
  routes,
};
