import React, { Fragment, useState } from 'react';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { Button } from '@cluedin/form';
import { Delete } from 'uxi/Icons';
import QueryBuilderPredicateInput from './QueryBuilderPredicateInput';

/* eslint-disable indent */
const PredicateItemWrapper = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  & > *:nth-child(1),
  & > *:nth-child(2) {
    ${({ isSynced }) =>
      isSynced
        ? 'filter: opacity(100%) grayscale(0%)'
        : 'filter: opacity(75%) grayscale(25%)'}
  }
  & > *:nth-child(3) {
    ${({ isSynced }) =>
      isSynced
        ? 'filter: opacity(100%) grayscale(0%)'
        : 'filter: opacity(100%) grayscale(65%)'}
    & ul, & ul * {
      filter: grayscale(0%);
    }
  }
`;
/* eslint-enable indent */

const PredicateItemValueWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 12px 16px;
  border: 1px solid rgb(206, 206, 206);
  color: #fff;
  background: ${({ themePrimary }) => themePrimary};
  /* font-weight: bold; */
  margin-left: 10px;
  &::before {
    content: '';
    display: block;
    height: 4px;
    width: 10px;
    background: #bdbdbd;
    position: absolute;
    left: -12px;
    top: 18px;
  }
`;

const PredicateSign = styled.div`
  position: relative;
  padding: 12px;
  margin-left: 9px;
  border: 1px solid rgb(206, 206, 206);
  background: #fff;
  &::before {
    content: '';
    display: block;
    height: 4px;
    width: 10px;
    background: #bdbdbd;
    position: absolute;
    left: -10px;
    top: 18px;
  }
`;

const PredicateItem = ({
  predicates,
  onDeletePredicate,
  onPredicateValueChange,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [value, setInputValue] = useState(predicates.value);

  return (
    <PredicateItemWrapper isSynced={predicates.value === value}>
      <PredicateItemValueWrapper themePrimary={themePrimary}>
        {predicates.schema.Key}
      </PredicateItemValueWrapper>
      <PredicateSign>
        <FormattedMessage id="module-search-equals" />
      </PredicateSign>
      <QueryBuilderPredicateInput
        isSynced={predicates.value === value}
        value={value}
        setInputValue={setInputValue}
        propertyKey={predicates.schema.Key}
        onChange={(newValue) => {
          onPredicateValueChange({
            id: predicates.id || uuid(),
            value: newValue,
            schema: predicates.schema,
          });
        }}
      />
      <div style={{ marginLeft: '12px' }}>
        <Button
          onClick={() => {
            onDeletePredicate(predicates.id);
          }}
          startIcon={<Delete size={16} />}
        />
      </div>
    </PredicateItemWrapper>
  );
};

const Separator = styled.div`
  position: relative;
  content: '';
  height: 10px;
  &::before {
    content: '';
    display: block;
    height: 52px;
    width: 4px;
    background: #bdbdbd;
    position: absolute;
    left: -1px;
    top: -22px;
  }
`;

const QueryBuilder = ({
  predicates = [],
  onDeletePredicate,
  onPredicateValueChange,
}) => {
  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      {predicates.map((p) => {
        return (
          <Fragment key={p.id}>
            <PredicateItem
              predicates={p}
              onDeletePredicate={onDeletePredicate}
              onPredicateValueChange={onPredicateValueChange}
            />
            <Separator />
          </Fragment>
        );
      })}
    </div>
  );
};

export default QueryBuilder;
