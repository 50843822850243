import React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelHeader, PanelContent } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { shouldCloseViewOptions, shouldChangeViewOption } from '../../actions';
import SchemaFieldsPicker from '../../../schema/components/containers/SchemaFieldsPicker';
import defaultViewOptions, {
  tileViewFields,
  gridViewFields,
} from '../../defaultViewOptions';
import { withViewMode } from '../hocs/withViewMode';

export const EntityListPropertiesSelector = ({
  shouldViewOptions,
  closeViewOptions,
  viewName,
  viewMode,
  config,
  changeViewOptions,
  options,
}) => {
  return (
    <Panel open={shouldViewOptions} withCustomHeader>
      <PanelHeader
        hasClose
        onClose={closeViewOptions}
        title={<FormattedMessage id="module-entityLists-choosePropsToRender" />}
      />

      <SchemaFieldsPicker
        options={options}
        fields={config?.fields}
        onChange={(updatedFields) => {
          changeViewOptions({
            viewName,
            viewMode,
            fields: updatedFields,
          });
        }}
      />
    </Panel>
  );
};

const mapStateToProps = (
  { entityLists: { shouldViewOptions, viewOptions } },
  { viewName, viewMode },
) => {
  let viewConfig = viewOptions?.find((v) => v.name === viewName);

  if (!viewConfig) {
    viewConfig = defaultViewOptions?.find((v) => v.name === viewName);
  }

  const config = viewConfig?.views?.find((v) => v.name === viewMode);
  const defaultConfig = defaultViewOptions?.find((v) => v.name === viewName);

  return {
    shouldViewOptions,
    viewOptions,
    config,
    defaultConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeViewOptions: () => {
    dispatch(shouldCloseViewOptions());
  },
  changeViewOptions: (updatedConfig) => {
    dispatch(shouldChangeViewOption(updatedConfig));
  },
});

export const withSetOpts = (Comp) => (props) => {
  let opts = {};

  if (props.viewMode === 'grid') {
    opts = {
      standardFields: gridViewFields,
    };
  } else if (props.viewMode === 'tile') {
    opts = {
      restriction: 2,
      standardFields: tileViewFields,
    };
  } else {
    opts = {
      restriction: 2,
      standardFields: tileViewFields,
    };
  }

  return <Comp {...props} options={opts} />;
};

const EntityListPropertiesSelectorContainerDecorated = withViewMode(
  withSetOpts(
    connect(mapStateToProps, mapDispatchToProps)(EntityListPropertiesSelector),
  ),
);

export default EntityListPropertiesSelectorContainerDecorated;
