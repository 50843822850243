import constants from '../constant';
import { unauthorized } from '../../../action/generic';
import { defaultErrorHandling } from '../../error/actions';

import {
  getAllUsers,
  block,
  unblock,
  isUserExisting,
  getPotentialUsers,
  getCurrentUser,
  changePassword,
  getUserAccount,
  getAllProviderForUser,
} from '../data';

const requestAllUsers = () => ({
  type: constants.REQUEST_ALL_USERS,
});

const receiveAllUsers = (users) => ({
  type: constants.RECEIVE_ALL_USERS,
  data: {
    users,
  },
});

const invalidAllUsers = (error) => ({
  type: constants.INVALID_ALL_USERS,
  error,
});

function shouldFetchAllUsers(state) {
  return !(state.user.allUsers && state.user.allUsers.length > 0);
}

export const fetchAllUsers = () => (dispatch, getState) => {
  if (shouldFetchAllUsers(getState())) {
    dispatch(requestAllUsers());

    getAllUsers()
      .then((users) => dispatch(receiveAllUsers(users)))
      .catch(unauthorized(dispatch, invalidAllUsers));
  }
};

export const resetRealTimeInviteUserInformation = (invitation) => ({
  type: constants.RESET_NEW_USER_INVITED_INFORMATION,
  data: {
    invitation,
  },
});

const requestRealTimeInviteUserInformation = (invitation) => ({
  type: constants.REQUEST_NEW_USER_INVITED_INFORMATION,
  data: {
    invitation,
  },
});

export function receiveRealTimeInvitedUserInformation(invitation) {
  return (dispatch) => {
    dispatch(requestRealTimeInviteUserInformation(invitation));
  };
}

const requestBlock = (userName) => ({
  type: constants.REQUEST_BLOCK,
  data: {
    userName,
  },
});

const receiveBlock = (userName) => ({
  type: constants.RECEIVE_BLOCK,
  data: {
    userName,
  },
});

const invalidBlock = () => ({
  type: constants.INVALID_BLOCK,
});

export const shouldBlock = (userName) => (dispatch) => {
  dispatch(requestBlock(userName));

  return block(userName)
    .then(() => dispatch(receiveBlock(userName)))
    .catch(unauthorized(dispatch, invalidBlock));
};

const requestUnBlock = (userName) => ({
  type: constants.REQUEST_UNBLOCK,
  data: {
    userName,
  },
});

const receiveUnBlock = (userName) => ({
  type: constants.RECEIVE_UNBLOCK,
  data: {
    userName,
  },
});

const invalidUnBlock = () => ({
  type: constants.INVALID_UNBLOCK,
});

export const shouldUnBlock = (userName) => (dispatch) => {
  dispatch(requestUnBlock(userName));

  return unblock(userName)
    .then(() => dispatch(receiveUnBlock(userName)))
    .catch(unauthorized(dispatch, invalidUnBlock));
};

const requestFindIfUserExist = () => ({
  type: constants.REQUEST_FIND_IF_USER_EXIST,
});

const receiveFindIfUserExist = (userName, result) => ({
  type: constants.RECEIVE_FIND_IF_USER_EXIST,
  data: {
    userName,
    isExisting: result ? result.isAvailable : true,
  },
});

const invalidFindIfUserExist = () => ({
  type: constants.INVALID_FIND_IF_USER_EXIST,
});

export const shouldFindIfUserExist = (userName) => (dispatch) => {
  dispatch(requestFindIfUserExist(userName));

  return isUserExisting(userName)
    .then((result) => dispatch(receiveFindIfUserExist(userName, result)))
    .catch(unauthorized(dispatch, invalidFindIfUserExist));
};

const requestPotentialUsers = () => ({
  type: constants.REQUEST_POTENTIAL_USERS,
});

const receivePotentialUsers = (potentialUsers) => ({
  type: constants.RECEIVE_POTENTIAL_USERS,
  data: potentialUsers,
});

const invalidPotentialUsers = () => ({
  type: constants.INVALID_POTENTIAL_USERS,
});

const fetchPotentialUsers = () => (dispatch) => {
  dispatch(requestPotentialUsers());

  return getPotentialUsers()
    .then((users) => {
      dispatch(receivePotentialUsers(users));
    })
    .catch(unauthorized(dispatch, invalidPotentialUsers));
};

const shouldFetchPotentialUsers = (state) =>
  !(state.user.potentialUsers && state.user.potentialUsers.length > 0);

export function fetchPotentialUsersIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchPotentialUsers(getState())) {
      dispatch(fetchPotentialUsers());
    }
  };
}

const requestCurrentUser = () => ({
  type: constants.REQUEST_CURRENT_USER,
});

const receiveCurrentUser = (user) => ({
  type: constants.RECEIVE_CURRENT_USER,
  data: {
    user,
  },
});

const invalidCurrentUser = () => ({
  type: constants.INVALID_CURRENT_USER,
});

const fetchCurrentUser = () => (dispatch, getState) => {
  if (getState().user.currentUser) {
    return;
  }

  if (getState().user.isFetchingCurrentUser) {
    return;
  }

  dispatch(requestCurrentUser());

  getCurrentUser()
    .then((user) => {
      if (user.entity) {
        dispatch(
          receiveCurrentUser({
            ...user,
            userProfile: null,
          }),
        );
      } else {
        dispatch(
          invalidCurrentUser({
            ...user,
            userProfile: null,
          }),
        );
      }
      /* getUserProfile(user.client.Id).then((userProfile) => {
      dispatch(receiveCurrentUser({
        ...user,
        userProfile,
      }));
    }).catch(() => {
      dispatch(receiveCurrentUser({
        ...user,
        userProfile: null,
      }));
    }); */
    })
    .catch(
      defaultErrorHandling(dispatch, null, {
        onErrorAction: invalidCurrentUser,
      }),
    );
};

export function shouldFetchCurrentUser() {
  return (dispatch) => {
    dispatch(fetchCurrentUser());
  };
}

const requestOpenInviteUserDialog = (email, name) => ({
  type: constants.REQUEST_OPEN_INVITE_USER_DIALOG,
  data: {
    email,
    name,
  },
});

export const shouldOpenInviteUserDialog =
  (email, name) => (dispatch, getState) => {
    if (!getState().user.isOpenInviteUserDialog) {
      dispatch(requestOpenInviteUserDialog(email, name));
    }
  };

const requestCloseInviteUserDialog = () => ({
  type: constants.REQUEST_CLOSE_INVITE_USER_DIALOG,
});

export const shouldCloseInviteUserDialog = () => (dispatch) => {
  dispatch(requestCloseInviteUserDialog());
};

export const shouldShowEmailIsAlreadyTaken = (email) => ({
  type: constants.RECEIVE_EMAIL_IS_ALREADY_TAKEN,
  data: {
    email,
  },
});

const requestRealTimePromoteInformation = (promoteNotification) => ({
  type: constants.RECEIVE_PROMOTE_INFORMATION,
  data: {
    promoteNotification,
  },
});

const requestResetPromoteUserInformation = (promoteUserNotifications) => ({
  type: constants.RESET_PROMOTE_INFORMATION,
  data: {
    promoteUserNotifications,
  },
});

export function resetPromoteUserInformation(promoteUserNotifications) {
  return (dispatch) => {
    dispatch(requestResetPromoteUserInformation(promoteUserNotifications));
  };
}

export function receiveRealTimePromoteInformation(promoteNotification) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetPromoteUserInformation(promoteNotification));
    }, 10000);
    dispatch(requestRealTimePromoteInformation(promoteNotification));
  };
}

const requestRealTimeDemoteInformation = (demoteNotification) => ({
  type: constants.RECEIVE_DEMOTE_INFORMATION,
  data: {
    demoteNotification,
  },
});

const requestResetDemoteUserInformation = (demoteNotification) => ({
  type: constants.RESET_DEMOTE_INFORMATION,
  data: {
    demoteNotification,
  },
});

export function resetDemoteUserInformation(demoteNotification) {
  return (dispatch) => {
    dispatch(requestResetDemoteUserInformation(demoteNotification));
  };
}

export function receiveRealTimeDemoteInformation(demoteNotification) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetDemoteUserInformation(demoteNotification));
    }, 10000);
    dispatch(requestRealTimeDemoteInformation(demoteNotification));
  };
}

const requestChangePassword = () => ({
  type: constants.REQUEST_CHANGE_PASSWORD,
});

const receiveChangePassword = () => ({
  type: constants.RECEIVE_CHANGE_PASSWORD,
});

const invalidChangePassword = () => ({
  type: constants.INVALID_CHANGE_PASSWORD,
});

const resetChangePassword = () => ({
  type: constants.RESET_CHANGE_PASSWORD,
});

export function shouldChangePassword(passwordObject) {
  return (dispatch) => {
    dispatch(requestChangePassword(passwordObject));

    return changePassword(passwordObject)
      .then(() => {
        dispatch(receiveChangePassword(passwordObject));
        setTimeout(() => {
          dispatch(resetChangePassword());
        }, 10000);
      })
      .catch(() => {
        dispatch(invalidChangePassword());
      });
  };
}

export const invalidAddWidgetConfigurationToUserProfile = () => ({
  type: constants.INVALID_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE,
});

export const addWidgetConfigurationToUserProfile = () => () => {};

const requestUserAccount = (id) => ({
  type: constants.REQUEST_USER_ACCOUNT,
  data: {
    id,
  },
});

const receiveUserAccount = (user) => ({
  type: constants.RECEIVE_USER_ACCOUNT,
  data: {
    user,
  },
});

const invalidFetchUserAccount = () => ({
  type: constants.INVALID_USER_ACCOUNT,
});

export const shouldFetchUserAccount = (id) => (dispatch) => {
  dispatch(requestUserAccount(id));

  getUserAccount(id)
    .then((user) => {
      dispatch(receiveUserAccount(user));
    })
    .catch(unauthorized(dispatch, invalidFetchUserAccount));
};

const requestAllProviderForUser = (userId) => ({
  type: constants.REQUEST_ALL_PROVIDER_FOR_USER,
  data: {
    userId,
  },
});

const receiveAllProviderForUser = (userId, providerList) => ({
  type: constants.RECEIVE_ALL_PROVIDER_FOR_USER,
  data: {
    userId,
    providerList,
  },
});

const invalidAllProviderForUser = () => ({
  type: constants.INVALID_ALL_PROVIDER_FOR_USER,
});

export const shouldFetchAllProviderForUser = (userId) => (dispatch) => {
  dispatch(requestAllProviderForUser(userId));
  return getAllProviderForUser(userId)
    .then((list) => {
      dispatch(receiveAllProviderForUser(userId, list));
    })
    .catch(unauthorized(dispatch, invalidAllProviderForUser));
};

export const shouldRemoveConfigurationFromPermission = (
  userId,
  configurationId,
) => ({
  type: constants.SHOULD_REMOVE_CONFIGURATION_FROM_PERMISSION,
  data: {
    userId,
    configurationId,
  },
});

export const shouldAddConfigurationFromPermission = (
  userId,
  configurationId,
) => ({
  type: constants.SHOULD_ADD_CONFIGURATION_FROM_PERMISSION,
  data: {
    userId,
    configurationId,
  },
});
