import { handleActions } from 'redux-actions';
import { shoudFetchPublicOrgInfoActions } from '../actions/public';
import { getDeeplyNestedProp } from '../../core/helpers/object';

export const initialState = {
  isFetching: false,
  result: null,
  invalid: false,
  isUsingSSO: false,
};

export default handleActions(
  {
    [shoudFetchPublicOrgInfoActions.request]: (state) => ({
      ...state,
      isFetching: true,
      invalid: false,
    }),
    [shoudFetchPublicOrgInfoActions.receive]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isUsingSSO: !!getDeeplyNestedProp(
        ['SingleSignOn', 'SingleSignOnProviderId'],
        payload,
        false,
      ),
      result: payload,
      invalid: false,
    }),
    [shoudFetchPublicOrgInfoActions.invalid]: (state) => ({
      ...state,
      isFetching: false,
      invalid: true,
    }),
  },
  initialState,
);
