import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from '@cluedin/theme';
import { ButtonLink } from '@cluedin/atoms';
import { ContextualMenu } from '@cluedin/form';
import {
  Access,
  Copylink,
  Merge,
  Markassensitive,
  Markasunsensitive,
  Anonymize,
  Gdpr,
  Add as AddIcon,
  Delete as DeleteIcon,
  Search,
  Iteration,
  Externallink,
} from 'uxi/Icons';
import { LayoutOutlineIcon } from '@cluedin/svgs';
import styled from 'styled-components';

import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import { copyToClipboard } from '../../../../modules/core/helpers/dom';
import { PageStructureHeader } from '../../../core/components/composites/PageStructure';
import BackLink from './mainBar/BackLink';
import EntityNameWithTag from './mainBar/EntityNameWithTag';
import ViewOriginal from './mainBar/ViewOriginal';
import EntityTypeAvatarConfigurationLinkContainer from '../../../entityTypes/components/containers/EntityTypeAvatarConfigurationLinkContainer';
import { getSensitive } from '../../../entityActions/selector';
import EntityCodePanel from '../../../entityRelationsGraph/composites/structure/EntityCodePanel';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';

import {
  EntityMainBarUl,
  EntityMainBarLi,
  EntityMainBarLiContent,
  ButtonLinkText,
} from './EntityMainBar.style';

import {
  useMarkEntityAsSensitive,
  useMarkEntityAsUnSensitive,
  useMinimizeUserData,
  useAnonymize,
  useAddFromProcessing,
  useRemoveFromProcessing,
  useExternalSearch,
  useReprocess,
} from '../../hooks/useEntityActions';

const LayoutTypeIconWrapper = styled.div`
  & path {
    fill: #000000;
  }
`;

const EntityMainBar = ({
  goBack,
  entity,
  entityId,
  onInitMerge,
  isSensitive,
  isMinimizing,
  isAnonymizing,
  isRemovingFromProcessing,
  onInitSarAndAttachIndividual,
  currentUser,
  layoutId,
  layoutName,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const [isEntityCodePanelOpen, setIsEntityCodePanelOpen] = useState(false);
  const [markEntityAsSensitive] = useMarkEntityAsSensitive(entityId);
  const [markEntityAsUnSensitive] = useMarkEntityAsUnSensitive(entityId);
  const [minimizeUserData] = useMinimizeUserData(entityId);
  const [anonymize] = useAnonymize(entityId);
  const [addFromProcessing] = useAddFromProcessing(entityId);
  const [removeFromProcessing] = useRemoveFromProcessing(entityId);
  const [externalSearch] = useExternalSearch(entityId);
  const [reProcess] = useReprocess(entityId);

  const hasDataManagementAccess = useUserHasAccess(
    actionsConfiguration.admin.datamanagement.datamanagement.edit.claims,
  );

  const hasGDPRAccess = useUserHasAccess(
    actionsConfiguration.governance.gdpr.subjectAccessRequest.view.claims,
  );
  const hasEditGDPRAccess = useUserHasAccess(
    actionsConfiguration.governance.gdpr.subjectAccessRequest.edit.claims,
  );

  const hasAnonymizedFeature = useOrganizationFeatureFlagOption('Anonymize');

  // eslint-disable-next-line no-nested-ternary
  const mergedEntityType = entity?.data?.entityType
    ? entity.data.entityType
    : '';

  const entityTitleContent = (
    <EntityMainBarLi key="name">
      <EntityMainBarLiContent data-test-id={`test_Entity_Header_${entity.id}`}>
        <EntityTypeAvatarConfigurationLinkContainer
          entityType={mergedEntityType}
        />
        <span style={{ marginLeft: '4px', marginRight: '4px' }}>/</span>
        <EntityNameWithTag entity={entity} />
      </EntityMainBarLiContent>
    </EntityMainBarLi>
  );

  const options = [
    {
      key: 'copy_link',
      text: (
        <>
          <Copylink size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-copy" />
        </>
      ),
      onClick: () => copyToClipboard(window.location.href),
    },
    { key: 'divider_1', itemType: 1 },
    {
      key: 'layout_details',
      text: (
        <div style={{ display: 'flex' }}>
          <LayoutTypeIconWrapper>
            <LayoutOutlineIcon size={12} style={{ marginRight: 5 }} />
          </LayoutTypeIconWrapper>
          <FormattedMessage id="module-entityV2-layout-type" />
          :&nbsp;
          <Link
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/settings/entity-page-layout/edit?id=${encodeURIComponent(
              layoutId,
            )}`}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              color: themePrimary,
            }}
          >
            {layoutName}
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </Link>
        </div>
      ),
      onClick: () => {
        window.open(
          `/admin/settings/entity-page-layout/edit?id=${encodeURIComponent(
            layoutId,
          )}`,
          '_blank',
          'noopener,noreferrer',
        );
      },
    },
    { key: 'divider_1', itemType: 1 },
    // {
    //   key: 'select_for_merge',
    //   text: (
    //     <>
    //       <Merge size={12} style={{ marginRight: 5 }} />
    //       <FormattedMessage id="module-entityV2-action-merge" />
    //     </>
    //   ),
    //   onClick: onInitMerge,
    // },
    hasDataManagementAccess && {
      key: 'mark_sensitive',
      text: isSensitive ? (
        <>
          <Markasunsensitive size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-unsensitive" />
        </>
      ) : (
        <>
          <Markassensitive size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-sensitive" />
        </>
      ),
      onClick: isSensitive ? markEntityAsUnSensitive : markEntityAsSensitive,
    },
    hasEditGDPRAccess && {
      key: 'minimize',
      text: isMinimizing ? (
        <>
          <Anonymize size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-unminimize" />
        </>
      ) : (
        <>
          <Anonymize size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-minimize" />
        </>
      ),
      onClick: minimizeUserData,
    },
    hasAnonymizedFeature
      ? {
          key: 'anonymize',
          text: !isAnonymizing && (
            <>
              <Anonymize size={12} style={{ marginRight: 5 }} />
              <FormattedMessage id="module-entityV2-action-anonymize" />
            </>
          ),
          onClick: anonymize,
        }
      : null,
    hasGDPRAccess && {
      key: 'init-sar-link',
      text: (
        <>
          <Gdpr size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-subject-request" />
        </>
      ),
      onClick: () => onInitSarAndAttachIndividual(currentUser?.client?.Email),
    },
    hasEditGDPRAccess && {
      key: 'remove-from-processing',
      text: isRemovingFromProcessing ? (
        <div
          style={{
            display: 'flex',
            paddingLeft: '15px',
            paddingTop: '2px',
          }}
        >
          <Loader />
        </div>
      ) : isRemovingFromProcessing ? (
        <>
          <AddIcon size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-subject-add-from-processing" />
        </>
      ) : (
        <>
          <DeleteIcon size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-subject-remove-from-processing" />
        </>
      ),
      onClick: isRemovingFromProcessing
        ? addFromProcessing
        : removeFromProcessing,
    },
    hasDataManagementAccess && {
      key: 'external-enrichment',
      text: (
        <>
          <Search size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-subject-external-enrichment" />
        </>
      ),
      onClick: externalSearch,
    },
    hasDataManagementAccess && {
      key: 'reprocess',
      text: (
        <>
          <Iteration size={12} style={{ marginRight: 5 }} />
          <FormattedMessage id="module-entityV2-action-subject-reprocess" />
        </>
      ),
      onClick: reProcess,
    },
  ].filter(Boolean);

  return (
    <PageStructureHeader
      title={
        <EntityMainBarUl>
          <BackLink onClick={goBack} />
          {entityTitleContent}
          {entity?.data?.uri && <ViewOriginal uri={entity.data.uri} />}
        </EntityMainBarUl>
      }
    >
      <EntityMainBarUl>
        <EntityMainBarLi>
          <div style={{ marginRight: 16 }}>
            <EntityCodePanel
              entity={entity}
              onClose={() => {
                setIsEntityCodePanelOpen(false);
              }}
              show={isEntityCodePanelOpen}
            />
            <ButtonLink
              text={
                <ButtonLinkText>
                  <Access size={18} />
                  <div style={{ marginLeft: '6px' }}>
                    <FormattedMessage id="module-entityV2-viewCodes" />
                  </div>
                </ButtonLinkText>
              }
              onClick={() => {
                setIsEntityCodePanelOpen(true);
              }}
            />
          </div>
        </EntityMainBarLi>

        <EntityMainBarLi>
          <div style={{ marginRight: 20 }}>
            <ContextualMenu
              text={
                <span
                  style={{
                    marginRight: 5,
                    marginLeft: 5,
                    display: 'inline-block',
                    verticalAlign: 'text-top',
                  }}
                >
                  <FormattedMessage id="module-envityV2-actions-label" />
                </span>
              }
              items={options}
            />
          </div>
        </EntityMainBarLi>
      </EntityMainBarUl>
    </PageStructureHeader>
  );
};

EntityMainBar.displayName = 'EntityMainBar';

EntityMainBar.propTypes = {
  entity: PropTypes.object,
  goBack: PropTypes.func,
  isSensitive: PropTypes.bool,
  isMinimizing: PropTypes.bool,
  isAnonymizing: PropTypes.bool,
  onInitSarAndAttachIndividual: PropTypes.func,
  currentUser: PropTypes.object,
};

const mapToStateProps = (state, ownProps) => {
  const {
    GDPR: { isRemovingFromProcessing, isMinimizingEntity, isAnonymizingEntity },
    user: { currentUser },
  } = state;
  const { entityId } = ownProps;

  const isMinimizingByEntity = isMinimizingEntity[entityId];
  const isRemovingFromProcessingByEntity = isRemovingFromProcessing[entityId];
  const isAnonymizing = isAnonymizingEntity[entityId];

  return {
    isSensitive: getSensitive(state, ownProps),
    isMinimizing: isMinimizingByEntity,
    isRemovingFromProcessing: isRemovingFromProcessingByEntity,
    isAnonymizing,
    currentUser,
  };
};

export default connect(mapToStateProps)(EntityMainBar);
