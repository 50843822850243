import React from 'react';
import { compose, lifecycle } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import { connect } from 'react-redux';
import { registerEntityWidget } from '../../registry';
import { shouldFetchAggregationSearch } from '../../../../search/actions';
import SearchFilter from './SearchMainFilter';

const FactWidget = ({
  aggregationSearchResult,
  entityId,
  isFetching,
  entityName,
}) => {
  let content;

  if (
    aggregationSearchResult &&
    aggregationSearchResult.Facets &&
    aggregationSearchResult.Facets.entityType
  ) {
    content = (
      <SearchFilter
        type="entityType"
        entityId={entityId}
        entityName={entityName}
        noHeader
        allVisible
        facets={aggregationSearchResult.Facets.entityType}
        title={<FormattedMessage id="module-entityWidget-connectionsTitle" />}
      />
    );
  } else if (!isFetching) {
    content = (
      <div>
        <FormattedMessage id="module-entityWidget-noConnections" />
      </div>
    );
  }

  return <div style={{ padding: '15px' }}>{content}</div>;
};

const EnhancedFactWidget = compose(
  lifecycle({
    componentDidMount() {
      const { entityId, search } = this.props;

      search(entityId);
    },
  }),
)(FactWidget);

const mapToStateProps = (
  { searchModule: { aggregationResult }, entityModule: { currentEntity } },
  { entityId },
) => {
  const currentResult = (aggregationResult || {})[entityId];
  const entityName =
    (currentEntity && currentEntity.name) || `${entityId.slice(0, 8)}...`;

  return {
    isFetching: (currentResult || {}).isFetching,
    aggregationSearchResult: (currentResult || {}).result,
    entityName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  search(entityId) {
    dispatch(shouldFetchAggregationSearch(entityId));
  },
});

const ConnectedFactWidget = connect(
  mapToStateProps,
  mapDispatchToProps,
)(EnhancedFactWidget);

registerEntityWidget('Fact', {
  name: 'Fact',
  displayName: 'Connections',
  description: 'Display Connections based on the current context.',
  view: ConnectedFactWidget,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
