import { registerEntityWidget } from '../../registry';
import CoreVocabularyComponent from './CoreVocabularyComponent';

registerEntityWidget('CoreVocabularyProperties', {
  name: 'CoreVocabularyProperties',
  displayName: 'Vocabulary',
  description: 'Vocabulary Properties',
  noHeader: true,
  view: CoreVocabularyComponent,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
