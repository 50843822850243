import React from 'react';
import PropTypes from 'prop-types';

import EntityActionMessageItem from '../EntityActionMessageItem/EntityActionMessageItem';

const EntityActionMessages = ({
  entityId,
  messages = [],
  refetchEntityMessages,
}) => {
  return (
    <>
      {messages &&
        (messages || []).map((message) => (
          <EntityActionMessageItem
            entityId={entityId}
            key={message?.id}
            message={message}
            refetchEntityMessages={refetchEntityMessages}
          />
        ))}
    </>
  );
};

export default EntityActionMessages;

EntityActionMessages.propTypes = {
  entityId: PropTypes.string,
  messages: PropTypes.array,
  refetchEntityMessages: PropTypes.func,
};

EntityActionMessages.defaultProps = {
  entityId: '',
  messages: [],
  refetchEntityMessages: () => {},
};
