import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PageLoader from '../../../core/components/composites/PageLoader';
import { cleanUpLocalStorateAndRedirect } from '../../actions';
import { clientId } from '../../../../config';

const Logout = ({ logout }) => {
  useEffect(() => {
    logout();
  });
  return (
    <>
      <PageLoader />
    </>
  );
};

const mapToStateProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    const { search } = window.location;
    cleanUpLocalStorateAndRedirect(clientId, search);
  },
});

export default connect(mapToStateProps, mapDispatchToProps)(Logout);
