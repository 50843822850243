import { useEffect } from 'react';

import { withEdgePropertiesPanelWorkflow } from '../../hocs/withEdgePropertiesPanelWorkflow';

const EdgePropertiesPanelContainer = ({
  selectedEdge,
  setSelectedEdge,
  openEdgePropertiesPanelWorkflow,
  isEdgePropertiesPanelOpen,
  setIsEdgePropertiesPanelOpen,
  edgePropertiesPanelData,
  edgePropertiesPanelLoading,
  edgePropertiesPanelError,
  getEdgeProperties,
  edgeByEdgeId,
  nodesAndEdges,
  edgeTypesArray,
  entityId,
  edgeDirection,
  onNodeClick,
  onGroupedNodeClick,
}) => {
  useEffect(() => {
    if (isEdgePropertiesPanelOpen) {
      openEdgePropertiesPanelWorkflow();
    }
  }, [isEdgePropertiesPanelOpen]);

  return <span />;
};

export default withEdgePropertiesPanelWorkflow(EdgePropertiesPanelContainer);
