"use strict";

exports.__esModule = true;
var _exportNames = {
  Bootstrap: true,
  createGqlClient: true
};
exports.createGqlClient = exports.Bootstrap = void 0;
var _Bootstrap = _interopRequireDefault(require("./components/Bootstrap"));
exports.Bootstrap = _Bootstrap.default;
var _UserClaims = require("./components/UserClaims");
Object.keys(_UserClaims).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UserClaims[key]) return;
  exports[key] = _UserClaims[key];
});
var _gql = _interopRequireDefault(require("./infrastructure/gql"));
exports.createGqlClient = _gql.default;
var _token = require("./infrastructure/token");
Object.keys(_token).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _token[key]) return;
  exports[key] = _token[key];
});
var _fetch = require("./infrastructure/fetch");
Object.keys(_fetch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _fetch[key]) return;
  exports[key] = _fetch[key];
});
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }