import React from 'react';
import styled from 'styled-components';
import ValueAutoCompleteFromSchema from '../../../../schema/components/containers/ValueAutoCompleteFromSchema';

const PredicateInputWrapper = styled.div`
  position: relative;
  margin-left: 9px;
  padding-top: 4px;
  &::before {
    content: '';
    display: block;
    height: 4px;
    width: 10px;
    background: #bdbdbd;
    position: absolute;
    left: -10px;
    top: 18px;
  }
`;

const QueryBuilderPredicateInput = ({
  propertyKey,
  onChange,
  value,
  setInputValue,
  isSynced,
}) => {
  return (
    <PredicateInputWrapper>
      <ValueAutoCompleteFromSchema
        isSynced={isSynced}
        value={value}
        setInputValue={setInputValue}
        propertyKey={propertyKey}
        onChange={onChange}
      />
    </PredicateInputWrapper>
  );
};

export default QueryBuilderPredicateInput;
