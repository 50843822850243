import styled from 'styled-components';

export const EntityRelationsTreeRootEdgeBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid #000;
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -4px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;
