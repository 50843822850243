import { createAction } from 'redux-actions';
import { createAsyncAction } from '../../action/helpers/actionCreator';
import { getTeamDomain, login } from './data';
import { goToLocation } from '../../action/core';
import {
  saveTokenForClientId,
  removeToken,
  removeAll,
  saveUsername,
  removeUserName,
} from '../core/helpers/storage';
import { getParameterByName } from '../core/helpers/url';
import { location } from '../../config';
import { withDefaultErrorHandlingActions } from '../error/actions';
import { validateClientId } from '../core/validation';

import { authRequest } from '../core/helpers/request';

export const updateClientIdProposalAction = createAction(
  'UPDATE_CLIENTID_PROPOSAL',
);
export const invalidClientIdProposalAction = createAction(
  'INVALID_CLIENTID_PROPOSAL',
);
export const validateClientIdProposalAction = createAction(
  'VALID_CLIENTID_PROPOSAL',
);

export const shouldUpdateClientIdProposal = (clientId) => (dispatch) => {
  const isValid = validateClientId(clientId);

  if (isValid) {
    dispatch(validateClientIdProposalAction());
  } else {
    dispatch(invalidClientIdProposalAction());
  }

  dispatch(updateClientIdProposalAction(clientId));
};

export const fetchTeamDomainActions = createAsyncAction('FETCH_TEAM_DOMAIN');
export const signInActions = createAsyncAction('FETCH_SIGNIN');

export const fetchTeamDomainCall = (clientId) => (dispatch) => {
  dispatch(fetchTeamDomainActions.request(clientId));

  return getTeamDomain(clientId).then((teamDomainResult) => {
    dispatch(fetchTeamDomainActions.receive({ clientId, teamDomainResult }));
    if (!teamDomainResult.isAvailable) {
      dispatch(goToLocation('clientIdSignIn', clientId));
    }
  });
};

export const fetchTeamDomain =
  withDefaultErrorHandlingActions(fetchTeamDomainCall);

export const redirectAfterLogin = ({ token, clientId }) => {
  saveTokenForClientId(token, clientId);

  const redirectUrl = getParameterByName('redirect');
  window.location.assign(location.goToRoot(clientId, redirectUrl));
};

export const fetchLogin =
  ({ username, password, clientId, isRememberMe }) =>
  (dispatch) => {
    dispatch(
      signInActions.request({ username, password, clientId, isRememberMe }),
    );

    return login({ username, password, clientId }).then((authInfo) => {
      if (authInfo) {
        if (isRememberMe) {
          saveUsername(username, clientId);
        } else {
          removeUserName(clientId);
        }

        saveTokenForClientId(authInfo, clientId);

        dispatch(
          signInActions.receive({ authInfo, clientId, username, password }),
        );

        redirectAfterLogin(clientId);
      }
    });
  };

export const saveUsernameInStore = ({ isRememberMe, clientId, username }) => {
  if (isRememberMe) {
    saveUsername(username, clientId);
  } else {
    removeUserName(clientId);
  }
};

export const shouldFetchLogin = withDefaultErrorHandlingActions(fetchLogin, {
  unknownError: signInActions.invalid,
});

export const authLogoutAction = createAction('APP_AUTH_LOGOUT');

export const cleanUpLocalStorateAndRedirect = (clientId, search) => {
  removeToken(clientId);
  if (search.indexOf(`redirect=`) > -1) {
    window.location = `/signin${search || ''}`;
  } else {
    window.location = `/signin?redirect=${search || ''}`;
  }
};

export const shouldLogout = (clientId, search) => {
  // authRequest('POST', 'api/account/logout')
  //   .then(() => cleanUpLocalStorateAndRedirect(clientId, search))
  //   .catch(() => cleanUpLocalStorateAndRedirect(clientId, search));
  // window.location = '/logout';
  if (search?.indexOf?.('redirect=') > -1) {
    window.location = `/logout${search || ''}`;
  } else {
    window.location = `/logout?redirect=${search || ''}`;
  }

  return authLogoutAction;
};

export const authLogoutAllAction = createAction('APP_AUTH_LOGOUT_ALL');

export const shouldLogAllOut = () => {
  removeAll();
  return authLogoutAllAction;
};
