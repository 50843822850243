import { useState, memo, useEffect } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import { Add } from 'uxi/Icons';
import { Icon } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { ButtonLink } from '@cluedin/atoms';
import { ContextualMenu } from '@cluedin/form';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { FormattedMessage } from '@cluedin/locale';

import SortableColumnList from '../SortableColumnList';
import ColumnSelectionListWrapper from './ColumnSelectionListWrapper';

const DiscreteText = styled.span`
  opacity: ${({ maxedOut }) => (maxedOut === true ? 1 : 0.5)};
`;

const ColumnSelectionList = ({
  viewMode,
  addLabel,
  columns = [],
  onDeleteColumn,
  onChangeColumns,
  onAddVocabulary,
  maxNumberColumns,
  currentNumberColumns,
  onAddEntityProperty,
}) => {
  const intl = useIntl();
  const [selectedColumns, setColumns] = useState(columns);
  // In some case we want to limit the number of property/vocabulary
  // the use should choose.
  // const hasCorrectNumber = (selectedColumns.length === maxNumberColumns);
  const maxedOut =
    maxNumberColumns !== undefined &&
    selectedColumns.length >= maxNumberColumns;

  useEffect(() => {
    setColumns(columns);
  }, [columns]);

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = selectedColumns[dragIndex];
    const updatedField = update(selectedColumns, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });

    setColumns(updatedField);
    onChangeColumns(updatedField);
  };

  return (
    <>
      <ColumnSelectionListWrapper
        numberSelected={(selectedColumns || []).length}
        maxNumberColumns={maxNumberColumns}
        header={
          <>
            <div style={{ flex: 1 }}>
              {(selectedColumns || []).length}
              &nbsp;
              <FormattedMessage id="module-goldenRecordList-selected" />
              &nbsp;
              {maxNumberColumns !== undefined ? (
                <DiscreteText maxedOut={maxedOut}>
                  <FormattedMessage
                    values={{ maxNumberColumns }}
                    id="module-goldenRecordList-onMaxColumns"
                  />
                </DiscreteText>
              ) : (
                ''
              )}
            </div>
            {maxedOut && (
              <TooltipHost
                content={
                  <FormattedMessage
                    values={{ maxNumberColumns }}
                    id={
                      viewMode === 'tile'
                        ? 'module-goldenRecordList-columnSelected-as-tile'
                        : 'module-goldenRecordList-columnSelected'
                    }
                  />
                }
                id={'can-not-add-columns-ColumnSelectionList'}
                calloutProps={{ gapSpace: 0 }}
              >
                <ButtonLink
                  disabled
                  icon={<Add />}
                  style={{ opacity: '0.5', padding: '0 12px' }}
                  text={
                    addLabel || (
                      <FormattedMessage id="module-goldenRecordList-addColumn" />
                    )
                  }
                />
              </TooltipHost>
            )}
            {!maxedOut && (
              <ContextualMenu
                onlyCustomIcon
                overrideClasses={{ span: { flexDirection: 'row-reverse' } }}
                buttonType="IconButton"
                items={[
                  {
                    key: 'en',
                    onClick: onAddEntityProperty,
                    text: intl.formatMessage({
                      id: 'module-goldenRecordList-entityProp',
                    }),
                  },
                  {
                    key: 'vc',
                    onClick: onAddVocabulary,
                    text: intl.formatMessage({
                      id: 'module-goldenRecordList-vocabulary',
                    }),
                  },
                ]}
                IconButtonContent={
                  <Icon
                    styles={{
                      root: { lineHeight: 0, fontSize: 18 },
                    }}
                    iconName={'Add'}
                  />
                }
                text={
                  addLabel || (
                    <FormattedMessage id="module-goldenRecordList-addColumn" />
                  )
                }
              />
            )}
          </>
        }
      >
        <SortableColumnList
          columns={selectedColumns}
          onMoveCard={moveCard}
          onDeleteColumn={(column) => {
            const filtered = selectedColumns.filter(
              (c) => c.property !== column.property,
            );

            const updatedFields = [...filtered];

            setColumns(updatedFields);
            onDeleteColumn(column);
          }}
        />
      </ColumnSelectionListWrapper>
    </>
  );
};

export default memo(ColumnSelectionList);
