import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OrganizationFeatureFlag } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';

const MenuSubItemLink = styled(Link)`
  padding: 14px 4px 14px 32px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  color: ${({ isActive, menuTheme }) =>
    isActive ? menuTheme?.accent : menuTheme?.color}!important;

  &:hover {
    text-decoration: none;
  }

  &:hover,
  &:hover:focus,
  &:hover:not(:focus) {
    color: ${({ menuTheme }) => menuTheme?.accent}!important;
  }
`;

export const MenuSubItem = memo(
  ({ href, isActive, displayName, menuTheme, featureName }) => {
    return featureName ? (
      <OrganizationFeatureFlag
        MainFeature={null}
        ExperimentalFeature={
          <MenuSubItemLink to={href} isActive={isActive} menuTheme={menuTheme}>
            {displayName}
          </MenuSubItemLink>
        }
        featureName={featureName}
      />
    ) : (
      <MenuSubItemLink to={href} isActive={isActive} menuTheme={menuTheme}>
        {displayName}
      </MenuSubItemLink>
    );
  },
);
