import React from 'react';
import QueuedMessage from './queued/QueuedMessage';
import AllMessageDetails from './message/AllMessageDetails';

const findAppropriateErrorMessage = (errorMessage) => {
  if (errorMessage.errorMessage) {
    return <span>{errorMessage.errorMessage}</span>;
  }

  return null;
};

const GlobalErrorMessage = ({ messages = [] }) => {
  const isEmpty = messages.length === 0;

  if (isEmpty) {
    return null;
  }

  const messagesWithDetails = messages
    .filter(
      (messageWithDetails) =>
        messageWithDetails.status ||
        messageWithDetails.url ||
        messageWithDetails.errorMessage,
    )
    .map((errorMessage) => ({
      ...errorMessage,
      message: findAppropriateErrorMessage(errorMessage),
    }));

  return (
    <QueuedMessage
      hasMultiple={messages.length > 1}
      moreDetails={
        messagesWithDetails && messagesWithDetails.length > 0 ? (
          <AllMessageDetails messages={messagesWithDetails} />
        ) : null
      }
    />
  );
};

export default GlobalErrorMessage;
