import React from 'react';
import { ProgressBar } from '@cluedin/form';
import { getScaledPercentageValue } from '../../../../core/helpers/numbers';
import { noEntity, noValue } from './CellStyles';

const ValueInRangeType = (entity) => {
  if (!entity) {
    return noEntity;
  }

  const { value } = entity;

  if (value === undefined || value === null) {
    return noValue;
  }

  return (
    <div
      style={{ boxSizing: 'border-box', width: '100%', paddingRight: '8px' }}
    >
      <ProgressBar
        label={<small>{`${getScaledPercentageValue(value * 100)}%`}</small>}
        labelPosition="top"
        width={'100%'}
        min={0}
        max={1}
        value={value}
      />
    </div>
  );
};

export default ValueInRangeType;
