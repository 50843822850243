/* eslint-disable no-underscore-dangle */
import { defaultCatch } from 'uxi-business/errorHandling';
import { url as urlHelper, urlBuilder, clientId } from '../../../config';
import { getCurrentToken } from './storage';
import fetchWithRefresh from './requestRefreshToken';

const includeTokenInResp = (resp) => {
  const response = resp;
  if (response && response instanceof Object) {
    response.__token = getCurrentToken();
  }

  return response;
};

export const jsonToFormData = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

export const toJSON = (response) =>
  response.text().then((text) => (text ? JSON.parse(text) : {}));

export const fetchApiWithFormEncoded = (method, url, data) => {
  const dataEncoded = data ? jsonToFormData(data) : '';
  const contentLenght = dataEncoded.length;

  return fetch(`${url}`, {
    method,
    mode: 'cors',
    headers: {
      Accept: '*/*',
      'Content-type': 'application/x-www-form-urlencoded',
      'Content-Length': contentLenght,
    },
    body: dataEncoded,
  }).then(toJSON);
};

const fetchApi = (method, url, data, token, headersConfig = {}) => {
  const dataEncoded = data ? JSON.stringify(data) : '';
  const contentLenght = dataEncoded.length;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Content-Length': contentLenght,
    ...headersConfig,
  };

  return fetchWithRefresh(`${url}`, {
    method,
    headers,
    body: data ? dataEncoded : null,
    redirect: 'follow',
    token,
  })
    .then(defaultCatch)
    .then(toJSON);
};

export const fetchApiFormData = (method, url, data, token) => {
  const dataEncoded = data ? jsonToFormData(data) : '';

  const bearer = token || getCurrentToken();
  const AuthorizationValue = bearer ? `Bearer ${bearer}` : null;

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/x-www-form-urlencoded',
  };

  const headers = AuthorizationValue
    ? {
        ...defaultHeaders,
        Authorization: AuthorizationValue,
      }
    : defaultHeaders;

  return fetch(`${url}`, {
    method,
    headers,
    body: dataEncoded,
  })
    .then(defaultCatch)
    .then(toJSON);
};

export const authRequest = (method, url, data, headers = {}) =>
  fetchApi(method, urlBuilder.auth(url), data, null, headers);

export const authRequestFormData = (method, url, data, headers = {}) =>
  fetchApiFormData(method, urlBuilder.auth(url), data, null, headers);

export const authWithoutCreds = (method, url, data) =>
  fetchApiWithFormEncoded(method, urlBuilder.auth(url), data);

export const publicApiRequest = (method, url, data) =>
  fetchApi(method, urlBuilder.publicApi(url), data);

export const apiRequest = (method, url, data) =>
  fetchApi(method, urlBuilder.api(url), data).then(includeTokenInResp);

export const rawRequest = (method, url) =>
  fetchApi(method, url).then(includeTokenInResp);

export const uploadRequest = (url, formData, headers = {}) => {
  const bearer = getCurrentToken();
  const AuthorizationValue = bearer ? `Bearer ${bearer}` : null;

  return fetch(`${urlHelper.uploadUrl}/${url}`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: AuthorizationValue,
      clientId,
      ...headers,
    },
  });
};

export default {
  authRequest,
  apiRequest,
  rawRequest,
  uploadRequest,
};
