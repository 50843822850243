import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import VocabSelectionStep from './VocabSelectionStep';

const CoreVocabSelection = ({ coreVocab, onSelectionChange }) => {
  return (
    <VocabSelectionStep
      title={<FormattedMessage id="module-annotation-coreVocabSelectTitle" />}
      vocabs={coreVocab}
      onSelectionChange={onSelectionChange}
      noVocabContent={
        <div>
          <FormattedMessage id="module-annotation-coreVocabSelectNoVocabPart1" />
          <br />
          <FormattedMessage id="module-annotation-coreVocabSelectNoVocabPart2" />
        </div>
      }
    />
  );
};

export default CoreVocabSelection;
