import { authRequest } from '../core/helpers/request';
import { clientId } from '../../config';

export const getSSOProviders = () =>
  authRequest('GET', 'api/account/ssoproviders');

export const getAutoFillOffice365 = (cId) =>
  authRequest('GET', `api/office365autofill?authError=none&subDomain=${cId}`);

export const getAutoFillGoogle = (cId) =>
  authRequest('GET', `api/googleautofill?authError=none&subDomain=${cId}`);

export const saveSSOConfiguration = (providerConfiguration) =>
  authRequest('POST', 'api/account/sso', providerConfiguration);

export const getCurrentSSOProvider = () =>
  authRequest('GET', `api/account/sso?subdomain=${clientId}`);
