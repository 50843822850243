import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_DATASET = gql`
  mutation createDataSource(
    $dataSourceSetId: ID
    $dataSource: InputDataSource
  ) {
    inbound {
      createDataSource(
        dataSourceSetId: $dataSourceSetId
        dataSource: $dataSource
      ) {
        id
      }
    }
  }
`;

export const useCreateDataSource = () => {
  const [createDataSource, { data, loading, error }] =
    useMutation(CREATE_DATASET);

  const onlyNeededData = data
    ? ((data || {}).inbound || {}).createDataSource || {}
    : null;

  return [
    (dataSourceConfiguration) => {
      return createDataSource({
        variables: {
          ...dataSourceConfiguration,
        },
      });
    },
    {
      data: onlyNeededData,
      loading,
      error,
    },
  ];
};

export default {
  useCreateDataSource,
};
