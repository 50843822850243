import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { Spinner, Button, PrimaryButton } from '@cluedin/form';
import { ThemedIcon } from '@cluedin/atoms';
import Alert from 'uxi/Alert';
import { Pause, Start, Access, Settings, Delete, Iteration } from 'uxi/Icons';
import { actionsConfiguration, ButtonLink } from '@cluedin/components';

const { integrations } = actionsConfiguration;
class ConfigurationOverviewActions extends Component {
  constructor() {
    super();
    this.state = {
      hideNotif: false,
    };
  }

  render() {
    const {
      configuration,
      integrationConfig,
      play,
      pause,
      approve,
      reject,
      reAuth,
      reProcess,
      viewSetting,
      viewPermissions,
      isLoadingPlay,
      isLoadingPause,
      isLoadingApprove,
      isLoadingReject,
      isOwner,
    } = this.props;

    const isActive = configuration.Active;
    const isFailingAuth = configuration.FailingAuthentication;
    const isLoading =
      isLoadingPlay || isLoadingPause || isLoadingApprove || isLoadingReject;

    // if (isUnApproved ) {
    //   return (
    //     <div style={{ padding: '8px' }}>
    //       <div>
    //         <ButtonLink
    //           isFullWidth
    //           onClick={() => {
    //             viewSetting(configuration);
    //           }}
    //           icon={<Settings />}
    //           style={{ margin: '8px' }}
    //           text={<FormattedMessage id="module-configuration-viewSettings" />}
    //           actionConfiguration={
    //             integrations.configuredIntegrations.configuredIntegration.view
    //           }
    //         />
    //       </div>
    //       <Button
    //         isFullWidth
    //         onClick={() => {
    //           approve(configuration);
    //         }}
    //         type="primary"
    //         style={{ margin: '8px' }}
    //         message={<FormattedMessage id="module-configuration-approve" />}
    //         disabled={isLoading}
    //         icon={<Start />}
    //         actionConfiguration={
    //           integrations.configuredIntegrations.configuredIntegration.create
    //         }
    //       />
    //       <Button
    //         isFullWidth
    //         type="danger"
    //         onClick={() => {
    //           reject(configuration);
    //         }}
    //         style={{ margin: '8px' }}
    //         message={<FormattedMessage id="module-configuration-reject" />}
    //         disabled={isLoading}
    //         icon={<Delete />}
    //         actionConfiguration={
    //           integrations.configuredIntegrations.configuredIntegration.delete
    //         }
    //       />

    //       {/* {setOwnerMessage} */}
    //       {isLoading && <Spinner style={{ margin: '8px' }} />}
    //     </div>
    //   );
    // }

    // if (isUnApproved ) {
    //   return (
    //     <div style={{ padding: '8px' }}>
    //       <FormattedMessage id="module-configuration-adminToApprove" />
    //     </div>
    //   );
    // }

    if (isFailingAuth) {
      return (
        <div style={{ padding: '8px' }}>
          <div>
            <ButtonLink
              isFullWidth
              onClick={() => {
                viewSetting(configuration);
              }}
              style={{ margin: '8px' }}
              icon={
                <ThemedIcon>
                  <Settings size={16} />
                </ThemedIcon>
              }
              text={<FormattedMessage id="module-configuration-viewSettings" />}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.view
              }
            />
          </div>
          <div>
            <ButtonLink
              isFullWidth
              onClick={() => {
                viewPermissions(configuration);
              }}
              style={isOwner ? { margin: '8px' } : {}}
              icon={
                <ThemedIcon>
                  <Access size={16} />
                </ThemedIcon>
              }
              text={<FormattedMessage id="module-configuration-permissions" />}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.view
              }
            />
          </div>
          {isOwner && (
            <Button
              isFullWidth
              onClick={() => {
                reAuth(configuration, integrationConfig);
              }}
              type="danger"
              disabled={isLoading}
              style={{ margin: '8px' }}
              message={<FormattedMessage id="module-configuration-reAuth" />}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.edit
              }
            />
          )}
          {/* {setOwnerMessage} */}
          {isLoading && <Spinner style={{ margin: '8px' }} />}
          {!isOwner && (
            <div style={{ marginTop: '15px' }}>
              <Alert type="danger">
                <FormattedHTMLMessage
                  id="module-configuration-html-contactForReauth"
                  values={{ username: configuration.UserName }}
                />
              </Alert>
              <p>
                <FormattedMessage id="module-configuration-reAuthExplanation" />
              </p>
            </div>
          )}
        </div>
      );
    }

    return (
      <div style={{ padding: '16px' }}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px 0',
            width: '100%',
          }}
        >
          {/* {isUnApproved && (
            <Button
              isFullWidth
              onClick={() => {
                approve(configuration);
              }}
              type="primary"
              style={{ margin: '8px' }}
              text={<FormattedMessage id="module-configuration-approve" />}
              disabled={isLoading}
              icon={<Start />}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.create
              }
            />
          )} */}

          {/*  {configuration?.Status && (
            <Button
              isFullWidth
              type="danger"
              onClick={() => {
                reject(configuration);
              }}
              style={{ margin: '8px' }}
              text={<FormattedMessage id="module-configuration-reject" />}
              disabled={isLoading}
              icon={<Delete />}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.delete
              }
            />
          )} */}
        </div>

        {isActive && configuration?.Status?.NextExecution ? (
          <PrimaryButton
            isFullWidth
            onClick={() => {
              pause(configuration);
            }}
            disabled={isLoading}
            text={
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Pause size={16} style={{ marginRight: '8px' }} />
                <FormattedMessage
                  id="ConfigurationList.StopLabel"
                  defaultMessage="Pause"
                />
              </div>
            }
            actionConfiguration={
              integrations.configuredIntegrations.configuredIntegration.edit
            }
          />
        ) : (
          <PrimaryButton
            isFullWidth
            onClick={() => {
              play(configuration);
            }}
            disabled={isLoading}
            text={
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Start size={16} style={{ marginRight: '8px' }} />
                <FormattedMessage
                  id="ConfigurationList.PlayLabel"
                  defaultMessage="Play"
                />
              </div>
            }
            actionConfiguration={
              integrations.configuredIntegrations.configuredIntegration.edit
            }
          />
        )}

        {isActive ? (
          <Button
            isFullWidth
            onClick={() => {
              reProcess(configuration.Id);
            }}
            style={{ marginTop: '8px' }}
            text={
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Iteration size={16} style={{ marginRight: '8px' }} />
                <FormattedMessage id="module-configuration-reprocess" />
              </div>
            }
            disabled={isLoading}
            actionConfiguration={
              integrations.configuredIntegrations.configuredIntegration.edit
            }
          />
        ) : null}

        {/* {setOwnerMessage} */}
        {isLoading && <Spinner style={{ margin: '8px' }} />}
      </div>
    );
  }
}
export default ConfigurationOverviewActions;
