import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { shouldFetchCurrentUser } from '../../actions';

const mapStateToProps = ({ user: { currentUser, isFetchingCurrentUser } }) => ({
  isFetchingCurrentUser,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser() {
    dispatch(shouldFetchCurrentUser());
  },
});

const mapToStatePropsWithOwner =
  (getOwner) =>
  ({ user: { currentUser } }, props) => {
    if (!getOwner) {
      return {
        isOwner: false,
      };
    }

    return {
      isOwner:
        currentUser && props && currentUser.client.Id === getOwner(props),
    };
  };

export const withOwner = (Comp, getOwner) => {
  const mapTo = mapToStatePropsWithOwner(getOwner);

  return connect(mapTo)(Comp);
};

export const withCurrentUser = (Comp) => {
  const enhanced = compose(
    lifecycle({
      componentDidMount() {
        const { fetchUser } = this.props;
        fetchUser();
      },
    }),
  );

  return connect(mapStateToProps, mapDispatchToProps)(enhanced(Comp));
};

export const withCurrentUserFromStore = (Comp) => {
  return connect(mapStateToProps)(Comp);
};

export default withCurrentUser;
