import store from 'store';
import expirePlugin from 'store/plugins/expire';
import { clientId } from '../../../config';
import { createExpiredDate } from './token';

store.addPlugin(expirePlugin);

export const removeToken = (currentClientId) => {
  const tokenParsed = store.get('token');

  if (tokenParsed) {
    const filteredToken = (tokenParsed || []).filter(
      (token) =>
        token.clientId.toLowerCase() !==
        (currentClientId && currentClientId.toLowerCase()),
    );

    store.set('token', filteredToken);
  }
};

export const removeAll = () => {
  store.set('token', []);
};

export const getClientIds = () => store.get('clientIds');

export const getFullToken = () => {
  const tokenParsed = store.get('token');

  if (!tokenParsed) {
    return null;
  }
  if (tokenParsed.length === 0) {
    return null;
  }

  if (!clientId) {
    return null;
  }

  let tokenWithClientId;
  if (clientId) {
    try {
      tokenWithClientId = tokenParsed.find((token) => {
        if (!clientId) {
          return false;
        }
        if (!token.clientId) {
          return false;
        }
        return (
          token.clientId.toLowerCase() === (clientId && clientId.toLowerCase())
        );
      });
    } catch (e) {
      return null;
    }
  }

  if (!tokenWithClientId) {
    return null;
  }

  return tokenWithClientId;
};

export const getCurrentToken = () => {
  const fullToken = getFullToken();
  return fullToken ? fullToken.token : null;
};

const saveClientId = (currentClientId) => {
  const clientIds = store.get('clientIds') || [];

  if (clientIds.indexOf(currentClientId) === -1) {
    clientIds.push(currentClientId);
  }

  store.set('clientIds', clientIds);
  store.set('currentClientId', currentClientId);
};

export const saveUsername = (username, currentClientId) => {
  const usernames = store.get('login_usernames') || [];
  const filteredUsernames = usernames.filter(
    (keyPair) => keyPair.clientId !== currentClientId,
  );

  filteredUsernames.push({
    username,
    clientId,
  });

  store.set('login_usernames', filteredUsernames);
};

export const removeUserName = (currentClientId) => {
  const usernames = store.get('login_usernames') || [];
  const filteredUsernames = usernames.filter(
    (keyPair) => keyPair.clientId !== currentClientId,
  );

  store.set('login_usernames', filteredUsernames);
};

export const getUsername = (currentClientId) => {
  const usernames = store.get('login_usernames') || [];

  const foundUsername = usernames.find(
    (username) => username.clientId === currentClientId,
  );

  if (foundUsername) {
    return foundUsername.username;
  }
  return null;
};

const createTokenForStorage = (accessToken, token) => {
  return {
    token: accessToken,
    clientId,
    expiresIn: token.expiresIn,
    expiresAt: createExpiredDate(token.expiresIn),
    refreshToken: token.refreshToken,
    scope: token.scope,
  };
};

/**
 * Token:
 * {
 *   accessToken
 *   expiresIn
 *   refreshToken
 *   scope
 * }
 */
export const saveTokenForClientId = (token, currentClientId) => {
  const accessToken = token.accessToken ? token.accessToken : token;

  let tokenWithClientIds = store.get('token') || [];
  const getCurrent = (keyPair) => keyPair.clientId === clientId;

  if (typeof tokenWithClientIds === 'string') {
    // delete old token
    store.set('token', []);
    tokenWithClientIds = [];
  }

  const hasAlreadyToken = tokenWithClientIds.find(getCurrent);

  saveClientId(clientId);

  if (!hasAlreadyToken) {
    tokenWithClientIds.push(createTokenForStorage(accessToken, token));

    store.set('token', tokenWithClientIds); // , currentDomainForCookie);
  } else {
    const updateNewTokenForCurrentClientId = tokenWithClientIds.filter(
      (keyPair) => keyPair.clientId !== currentClientId,
    );

    updateNewTokenForCurrentClientId.push(
      createTokenForStorage(accessToken, token),
    );

    store.set('token', updateNewTokenForCurrentClientId); // , currentDomainForCookie);
  }
};
