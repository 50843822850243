import styled, { css } from 'styled-components';

export const ExplainLogCategoryBox = styled.div`
  background: #ffffff;
  width: 100%;
  min-width: 100%;
`;

export const ExplainLogCategoryWrapper = styled.div`
  align-items: flex-start;
  background: #ffffff;
  border-left: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  padding: 14px 10px 0 10px;
  width: 100%;
  min-width: 100%;
`;

export const ExplainLogCategoryHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  padding-bottom: 10px;
  position: relative;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;

export const ExplainLogCategoryHeadingWrapper = styled.div`
  align-items: center;
  cursor: ${({ hasEvents }) => (hasEvents ? 'pointer' : 'default')};
  display: flex;
  justify-content: flex-start;
`;

export const ExplainLogCategoryBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid ${({ themePrimary }) => themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -25.5px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const ExplainLogCategoryDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & span {
    color: #666666;
    font-size: 14px;
  }
`;

export const ExplainLogCategoryTitle = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 2px;

  & span {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2px;
  }
`;

export const ExplainLogCategoryStatus = styled.div`
  display: flex;
  padding: 0;
  margin: 0;

  ${({ resultStatus }) => {
    switch (true) {
      case resultStatus === 'Successful':
        return css`
          color: #29a29a !important;
        `;
      case resultStatus === 'Skipped':
        return css`
          color: #5b89cf !important;
        `;
      case resultStatus === 'SuccessfulWithWarnings':
        return css`
          color: rgb(255, 152, 0) !important;
        `;
      case resultStatus === 'Failed':
        return css`
          color: rgb(239, 88, 88) !important;
        `;
      default:
        return css`
          color: #666666 !important;
        `;
    }
  }}
`;

export const ExplainLogDetailWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 100ms ease;
  width: 100%;
`;
