import React from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { PrimaryButton } from '@cluedin/form';
import NewConfigurationFormWithExtra from './NewConfigurationFormWithExtra';
import FormGenerator from '../../../../core/components/composites/form/FormGenerator';

const NewConfigurationForm = ({
  config = [],
  onEnable,
  handleSubmit,
  helperConfiguration,
  hasExtra,
  isSaving,
  isEdit,
}) => {
  const noConfigMsg = (
    <Alert style={{ margin: '32px auto' }}>
      <FormattedMessage
        id="module-integration-noConfigrationForNewConfiguration"
        defaultMessage={'There is nothing to configure for this application.'}
      />
    </Alert>
  );

  const formConfiguration =
    config && config.length > 0 ? (
      <FormGenerator fields={config} />
    ) : (
      noConfigMsg
    );

  const showButton = (config && config.length > 0) || hasExtra || !isEdit;

  const ButtonSubmit = showButton ? (
    <div style={{ display: 'flex' }}>
      <PrimaryButton
        disabled={isSaving}
        loading={isSaving}
        text={'Save Settings'}
        onClick={handleSubmit(onEnable)}
        className="__test_integration_saveIntegrationConfig"
      />
    </div>
  ) : null;

  if (hasExtra) {
    return (
      <div>
        <NewConfigurationFormWithExtra
          helperConfiguration={helperConfiguration}
        >
          {formConfiguration}
        </NewConfigurationFormWithExtra>
        {ButtonSubmit}
      </div>
    );
  }

  return (
    <div>
      {formConfiguration}
      {ButtonSubmit}
    </div>
  );
};

NewConfigurationForm.displayName = 'NewConfigurationForm';

export default reduxForm({
  form: 'ConfigurationForm',
})(NewConfigurationForm);
