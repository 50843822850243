import { connect } from 'react-redux';
import defaultViewOptions from '../../defaultViewOptions';
import {
  createPropertyFromFields,
  setupSchemaForGrid,
} from '../../../superEntityList/helper';

const mapStateToProps = (
  { entityLists: { viewOptions }, entity: { schema, isFetchingSchema } },
  { viewMode, viewName },
) => {
  let viewConfig = viewOptions.find((v) => v.name === viewName);

  if (!viewConfig) {
    viewConfig = defaultViewOptions.find((v) => v.name === viewName);
  }

  const config = viewConfig.views.find((v) => v.name === viewMode);

  if (isFetchingSchema) {
    return {
      config,
      properties: [],
      isFetching: isFetchingSchema,
    };
  }

  const getSchemaVmByPropertyKey = setupSchemaForGrid(schema || []);

  const properties = createPropertyFromFields(
    config.fields.filter((f) => !f.disabled),
    getSchemaVmByPropertyKey,
  );

  return {
    config,
    properties,
    isFetching: isFetchingSchema,
  };
};

export const withPropertyViewModel = (Comp) => {
  return connect(mapStateToProps)(Comp);
};

export default {
  withPropertyViewModel,
};
