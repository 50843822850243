import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import KPI from '../../../../DVC/components/composites/visualizations/KPI';

const TotalConfigurationsKPI = ({ configurations }) => (
  <KPI
    title={
      <FormattedMessage
        id="module-configuration-totalNumberConfiguration"
        values={{ plural: configurations.length > 1 ? 's' : '' }}
      />
    }
    value={configurations.length}
  />
);

TotalConfigurationsKPI.disaplyName = 'TotalConfigurationsKPI';

export default TotalConfigurationsKPI;
