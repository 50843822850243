"use strict";

exports.__esModule = true;
exports.getGroupedOptions = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var sortByAsc = function sortByAsc(a, b, key) {
  var _a$key, _b$key;
  if (key === void 0) {
    key = 'displayName';
  }
  var itemA = a == null ? void 0 : (_a$key = a[key]) == null ? void 0 : _a$key.toUpperCase();
  var itemB = b == null ? void 0 : (_b$key = b[key]) == null ? void 0 : _b$key.toUpperCase();
  if (itemA < itemB) return -1;
  if (itemA > itemB) return 1;
  return 0;
};
var adaptItem = function adaptItem(i) {
  return i;
};
var getGroupedOptions = function getGroupedOptions(_ref) {
  var _ref$data = _ref.data,
    data = _ref$data === void 0 ? [] : _ref$data,
    _ref$sortFunc = _ref.sortFunc,
    sortFunc = _ref$sortFunc === void 0 ? sortByAsc : _ref$sortFunc,
    _ref$sortByKey = _ref.sortByKey,
    sortByKey = _ref$sortByKey === void 0 ? 'displayName' : _ref$sortByKey,
    _ref$groupByKey = _ref.groupByKey,
    groupByKey = _ref$groupByKey === void 0 ? 'displayName' : _ref$groupByKey,
    _ref$itemAdapterFunc = _ref.itemAdapterFunc,
    itemAdapterFunc = _ref$itemAdapterFunc === void 0 ? adaptItem : _ref$itemAdapterFunc,
    _ref$itemAdapterFuncO = _ref.itemAdapterFuncOptions,
    itemAdapterFuncOptions = _ref$itemAdapterFuncO === void 0 ? {} : _ref$itemAdapterFuncO;
  return _lodash.default.chain(data).map(function (i) {
    return itemAdapterFunc(i, itemAdapterFuncOptions);
  }).sort(function (a, b) {
    return sortFunc(a, b, sortByKey);
  }).groupBy(groupByKey).map(function (value, key) {
    return {
      label: key,
      options: value
    };
  }).value();
};
exports.getGroupedOptions = getGroupedOptions;