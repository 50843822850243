import { get } from 'lodash';
import React, { Fragment } from 'react';
import EntityPropertyViewerIntegrationGroup from './EntityPropertyViewerIntegrationGroup';

const EntityPropertyViewerIntegrationGroups = ({
  integrationProperties,
  openedCategories,
  isFetching,
  invalid,
  onCategoryClick,
  hasSearch,
  searchQuery,
}) => {
  return (
    <Fragment>
      {(integrationProperties || [])
        .filter((propertyGroup = {}) => {
          const properties = get(propertyGroup, 'properties', []);
          return properties.length > 0;
        })
        .map((propertiesGroup, index) => {
          return (
            <EntityPropertyViewerIntegrationGroup
              isOpen={
                hasSearch ||
                openedCategories.find(
                  (v) =>
                    v.toLowerCase() ===
                    propertiesGroup.integration.Name.toLowerCase(),
                )
              }
              key={
                propertiesGroup && propertiesGroup.integration
                  ? propertiesGroup.integration.Name
                  : index
              }
              propertiesGroup={propertiesGroup}
              isFetching={isFetching}
              invalid={invalid}
              onCategoryClick={onCategoryClick}
              searchQuery={searchQuery}
            />
          );
        })}
    </Fragment>
  );
};

export default EntityPropertyViewerIntegrationGroups;
