import { useMemo, useState, memo } from 'react';
import { injectIntl } from '@cluedin/locale';

import { EntityTypeSelector } from '../../entityTypes/components/containers/EntityTypeSelector';
import { usePaginatedEntities } from '../../entityTypes/hooks/usePaginatedEntities';
import { getClassicValidationMessage } from '../../core/validation/messages';

export const CreateSimpleEntityTypeOrSelect = memo(
  injectIntl(
    ({
      intl,
      value,
      onChange,
      disabled,
      isLoading,
      style = {},
      isCreatable = true,
    }) => {
      const [tempVal, setTempVal] = useState();
      const { loadMore, entities, setSearchEntities, isLoadingEntities } =
        usePaginatedEntities({
          loadMoreLabel: intl.formatMessage({
            id: 'module-entityType-select-loadMore',
          }),
        });

      const entityType = useMemo(() => value?.entityType, [value]);
      const displayName = useMemo(() => value?.displayName, [value]);

      const adaptedEntityTypeSelectValue = useMemo(() => {
        return entityType && displayName
          ? {
              value: entityType ?? '',
              label: displayName ?? '',
            }
          : undefined;
      }, [entityType, displayName]);

      const newEntityValidationError =
        tempVal?.displayName &&
        getClassicValidationMessage(tempVal.displayName, 100);

      const adaptedTempSelectValue = useMemo(() => {
        return tempVal?.vocabularyName && tempVal?.keyPrefix
          ? {
              label: tempVal?.vocabularyName ?? '',
              value: tempVal?.keyPrefix,
            }
          : undefined;
      }, [tempVal]);

      return (
        <div style={style}>
          <EntityTypeSelector
            data={entities}
            disabled={disabled}
            isClearable={false}
            onChange={onChange}
            loadMore={loadMore}
            isCreatable={isCreatable}
            setSearchName={setSearchEntities}
            setTempVal={setTempVal}
            value={adaptedTempSelectValue || adaptedEntityTypeSelectValue}
            hasError={!!newEntityValidationError}
            readOnly={isLoadingEntities || isLoading}
            isLoading={isLoadingEntities || isLoading}
            placeholder={intl.formatMessage({
              id: 'module-entityType-select-entityType',
            })}
          />
          {newEntityValidationError && (
            <div style={{ marginBottom: '-16px' }}>
              {newEntityValidationError}
            </div>
          )}
        </div>
      );
    },
  ),
);
