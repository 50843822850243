import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonLink } from '@cluedin/atoms';
import Tooltip from 'rc-tooltip';
import { Checkmark } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import { ClipboardIcon } from '@cluedin/svgs';
import { FormattedMessage } from '@cluedin/locale';

import { copyToClipboard } from '../../../../../core/helpers/dom';

const ExplainLogCopyCode = ({ attributeOrigin }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const copyToClipboardAndSuccess = () => {
    setShowCopiedMessage(true);
    copyToClipboard(attributeOrigin);

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  return (
    <ButtonLink
      text={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {!showCopiedMessage && (
            <Tooltip
              placement="top"
              trigger="hover"
              overlay={
                <FormattedMessage id="module-entity-entityCodesPanelCopyOrigin" />
              }
            >
              <ClipboardIcon />
            </Tooltip>
          )}
          {showCopiedMessage && (
            <Tooltip
              placement="top"
              trigger="hover"
              overlay={
                <FormattedMessage id="module-entity-entityCodesPanelOriginCopied" />
              }
            >
              <Checkmark color={themePrimary} size={14} />
            </Tooltip>
          )}
          <div>
            <FormattedMessage id="module-entity-entityCodesPanelCopyCode" />
          </div>
        </div>
      }
      onClick={() => {
        copyToClipboardAndSuccess();
      }}
    />
  );
};

export default ExplainLogCopyCode;

ExplainLogCopyCode.propTypes = {
  attributeOrigin: PropTypes.string,
};

ExplainLogCopyCode.defaultProps = {
  attributeOrigin: null,
};
