/* eslint-disable react/require-default-props */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PageNumberButton } from '../PageNumberButton';
import { EllipsisButton } from './EllipsisButton';

const isFirstTwoOrLastTwo = (currentPage, totalPages) =>
  currentPage < 3 || currentPage + 2 > totalPages;

export const MiddleButtons = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages < 5) {
    return null;
  }

  if (isFirstTwoOrLastTwo(currentPage, totalPages)) {
    return (
      <EllipsisButton
        totalPages={totalPages}
        onPageChange={onPageChange}
        currentPage={currentPage}
      />
    );
  }

  return (
    <Fragment>
      <EllipsisButton
        show={currentPage > 4}
        totalPages={totalPages}
        onPageChange={onPageChange}
        currentPage={currentPage}
      />
      <PageNumberButton
        pageNumber={currentPage - 1}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show={currentPage > 3}
      />
      <PageNumberButton
        pageNumber={currentPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show
      />
      <PageNumberButton
        pageNumber={currentPage + 1}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show={currentPage < totalPages - 2}
      />
      <EllipsisButton
        show={currentPage < totalPages - 3}
        totalPages={totalPages}
        onPageChange={onPageChange}
        currentPage={currentPage}
      />
    </Fragment>
  );
};

MiddleButtons.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
