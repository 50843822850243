import React from 'react';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeader,
  TableHeaderColumn,
} from '@cluedin/list-pattern';
import { flexCSSString } from 'uxi/Layout';
import TextPlaceholder from '../../../../core/components/composites/Placeholder/TextPlaceholder';
import AvatarWithNamePlaceholder from '../../../../core/components/composites/Placeholder/AvatarWithNamePlaceholder';
import StatusBadgePlaceholder from '../../../../core/components/composites/Placeholder/StatusBadgePlaceholder';

const FlexExtended = styled.div`
  ${flexCSSString};
  justify-content: flex-start;
`;

/* eslint-disable max-len */
const ConfigurationListGhost = () => (
  <Table>
    <TableHeader>
      <TableRow>
        {[...new Array(5)].map((_, i) => (
          <TableHeaderColumn key={i}>
            <TextPlaceholder color={'rgb(180, 180, 180)'} />
          </TableHeaderColumn>
        ))}
      </TableRow>
    </TableHeader>
    <TableBody>
      {[...new Array(10)].map((_, i) => (
        <TableRow key={i} style={{ cursor: 'pointer' }}>
          {[...new Array(5)].map((x, j) => {
            let content = (
              <TableRowColumn key={j}>
                <TextPlaceholder />
              </TableRowColumn>
            );

            if (j === 0) {
              content = (
                <TableRowColumn key={j}>
                  <FlexExtended>
                    <AvatarWithNamePlaceholder />
                  </FlexExtended>
                </TableRowColumn>
              );
            } else if (j === 1) {
              content = (
                <TableRowColumn key={j}>
                  <FlexExtended>
                    <StatusBadgePlaceholder />
                    <TextPlaceholder />
                  </FlexExtended>
                </TableRowColumn>
              );
            }

            return content;
          })}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
export default ConfigurationListGhost;
