import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

const VocabularyKeyGroupValue = ({ groupName }) => {
  return (
    <>
      {groupName}
      {!groupName && (
        <div style={{ fontStyle: 'italic', color: '#9b9b9c' }}>
          <FormattedMessage id="module-dataCatalog-ungroupedVocabKey" />
        </div>
      )}
    </>
  );
};

export default VocabularyKeyGroupValue;
