import styled from 'styled-components';

export const ExplainLogKindBox = styled.div`
  background: #ffffff;
  max-width: 100%;
`;

export const ExplainLogKindWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  padding: 14px 4px 7px 4px;
  width: 100%;
`;

export const ExplainLogKindHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  position: relative;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;

export const ExplainLogKindHeadingWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
`;

export const ExplainLogKindDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & strong {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2px;
  }
`;

export const ExplainLogDataWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 40px;
  transition: all 100ms ease;
  width: 100%;
`;
