import styled, { css } from 'styled-components';

export const EntityPropertiesImpactedKeyListBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const EntityPropertiesImpactedKeyListWrapper = styled.div`
  align-items: center;
  border-top: 0;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 14px 0;
  transition: all 100ms ease;

  & svg {
    margin-right: 10px;
  }
`;

export const EntityPropertiesImpactedKeyListTitle = styled.span`
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-start;
`;

export const EntityPropertiesImpactedKeyListSourceWrapper = styled.div`
  height: 100%;
  transition: all 100ms ease;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;
