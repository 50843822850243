import styled from 'styled-components';

const WrapperMD = styled.div`
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    margin-top: 0;
    text-align: center;
  }
`;

export default WrapperMD;
