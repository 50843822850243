import React from 'react';
import groupBy from 'lodash/groupBy';
import { FormattedMessage } from '@cluedin/locale';
import { H5 } from 'uxi/Classic';
import { ButtonLink } from '@cluedin/atoms';
import { Delete } from 'uxi/Icons';
import { SelectedFilter } from './FilterGroup';
import ShowAdvancedFiltersButton from './ShowAdvancedFiltersButton';
import { defaultFilters } from './DefaultFilters';

const AllActiveSearchFilters = ({
  selectedFilters,
  onAllFilterClick,
  onRemoveFilter,
  onClearAllSearchFilter,
}) => {
  const groupedBy = Object.keys(groupBy(selectedFilters, 'type'));

  const onlyAdvancedActiveFilters = groupedBy.filter(
    (key) => !defaultFilters.includes(key),
  );

  const showAdvancedFilterButton = (
    <ButtonLink
      style={{ margin: '8px 0' }}
      key="button"
      icon={<Delete />}
      text={
        <FormattedMessage
          id="module-search-clearFilters"
          values={{ total: selectedFilters.length }}
        />
      }
      onClick={() => onClearAllSearchFilter()}
    />
  );

  return (
    <div style={{ padding: '0 16px' }}>
      {onlyAdvancedActiveFilters.length === 0 && groupedBy.length > 0
        ? showAdvancedFilterButton
        : null}
      {onlyAdvancedActiveFilters.length > 0
        ? [
            showAdvancedFilterButton,
            <H5 key="title" style={{ fontWeight: 'normal' }}>
              <FormattedMessage id="module-search-activeAdvacnedFilters" />
            </H5>,
            <div
              key="content"
              style={{
                borderBottom: '1px solid #cecece',
                paddingBottom: '16px',
              }}
            >
              {onlyAdvancedActiveFilters.map((typeKey, i) => (
                <div>
                  <div
                    key={`${typeKey}-${i}`}
                    style={{ fontSize: 12, padding: '2px' }}
                  >
                    {typeKey}
                  </div>
                  <div style={{ display: 'flex', maxWidth: '268px' }}>
                    {selectedFilters
                      .filter((x) => x.type === typeKey)
                      .map((filter, j) => (
                        <SelectedFilter
                          key={`${filter.displayName}-${j}`}
                          filter={filter}
                          onRemoveFilter={onRemoveFilter}
                        />
                      ))}
                  </div>
                </div>
              ))}
            </div>,
            <div
              key="allFiltersDialog"
              style={{
                borderBottom: '1px solid #cecece',
              }}
            >
              <ShowAdvancedFiltersButton
                onAllFilterClick={onAllFilterClick}
                style={{
                  margin: '8px auto',
                }}
              />
            </div>,
          ]
        : null}
    </div>
  );
};

AllActiveSearchFilters.displayName = 'AllActiveSearchFilters';

export default AllActiveSearchFilters;
