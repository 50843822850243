import { memo } from 'react';
import { injectIntl } from '@cluedin/locale';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { shouldFetchCurrentOrganization } from '../../../organization/actions';
import { useLayoutContext } from '../../../../shared/context/layoutContext';
import HeaderContainer from './HeaderContainer';
import UserMenuContainer from './UserMenuContainer';
import MainMenuContainer from './MainMenuContainer';
import { Layout, GeneralContent } from '../composites/Layout';
import PageContentContainer from './PageContentContainer';

const fullScreenRoutes = ['/admin/gdpr/gdprReport/'];

const headerHeight = '48px';

const PageWrapperContainer = memo(
  ({ modules, children, isFullScreenRoute, routerLocation = {} }) => {
    const { pathname } = routerLocation;
    const { isMenuMinimized } = useLayoutContext();

    if (isFullScreenRoute) {
      return children;
    }

    return (
      <Layout>
        <HeaderContainer
          isMinimized={isMenuMinimized}
          height={headerHeight}
          userMenu={<UserMenuContainer height={headerHeight} />}
        />

        <GeneralContent>
          <MainMenuContainer modules={modules} location={pathname} />

          <PageContentContainer>{children}</PageContentContainer>
        </GeneralContent>
      </Layout>
    );
  },
);

const PageWrapperContainerEnhanced = compose(
  lifecycle({
    componentDidMount() {
      const { fetchCurrentOrganization: fetchCurrentOrganizationRenamed } =
        this.props;
      fetchCurrentOrganizationRenamed();
    },
  }),
)(PageWrapperContainer);

const mapToStateProps = ({ org }, { routerLocation: { pathname } = {} }) => ({
  currentOrganization: org.organization,
  isFullScreenRoute: !!(
    pathname && fullScreenRoutes.find((r) => pathname.indexOf(r) > -1)
  ),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentOrganization: () => {
    dispatch(shouldFetchCurrentOrganization());
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(injectIntl(PageWrapperContainerEnhanced));
