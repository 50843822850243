import React from 'react';
import PropTypes from 'prop-types';
import { CluedInCIconLogo } from '@cluedin/svgs';
import { ExplainLogIconWrapper } from './ExplainLogIcon.styles';

const ExplainLogIcon = ({ color, fill, icon, size, stroke }) => {
  return (
    <ExplainLogIconWrapper
      color={color}
      fill={fill}
      size={size}
      stroke={stroke}
    >
      {icon}
    </ExplainLogIconWrapper>
  );
};

export default ExplainLogIcon;

ExplainLogIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.number,
  stroke: PropTypes.string,
};

ExplainLogIcon.defaultProps = {
  color: '#f2c230',
  fill: '#fff',
  icon: <CluedInCIconLogo />,
  size: 20,
  stroke: '#fff',
};
