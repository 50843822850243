import UserUnknown from './UserUnknown';

const UserName = ({ user }) => {
  if (!user || (!user.username && !user.userName)) {
    return <UserUnknown user={user} />;
  }

  return <span>{user.username || user.userName}</span>;
};

export default UserName;
