import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

const UserUnknown = ({ author }) => {
  return author ? (
    <span>{author}</span>
  ) : (
    <span style={{ fontStyle: 'italic' }}>
      <FormattedMessage id="module-user-noAuthorKnown" />
    </span>
  );
};

export default UserUnknown;
