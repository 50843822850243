import { Filter } from '../types/ruleConditionTypes';

const isContainsPropWrap = (field = '') => field?.includes('Properties[');

const adaptRuleField = (field = ''): string => {
  if (isContainsPropWrap(field)) {
    const regExp = /Properties\[([^)]+)\]/gi;

    const res = regExp.exec(field)?.[1];

    // Because who knows ;)
    if (isContainsPropWrap(res)) return adaptRuleField(res);

    return res ?? '';
  }

  return field;
};

const VOCAB_OBJECT_TYPE_ID = '011ac3b4-0b46-4f9c-a82a-8c14f9dd642b';

export const adaptRules = (rules: Filter[] = []): Filter[] =>
  rules.map((i) => {
    if (i?.rules && i?.rules?.length > 0) {
      return {
        ...i,
        rules: adaptRules(i?.rules),
      };
    }

    return {
      ...i,
      field: adaptRuleField(i?.field),
    };
  });

const getAdaptedVocabFieldValue = (field = '') => {
  if (!field) return '';

  if (isContainsPropWrap(field)) return field;

  return `Properties[${field}]`;
};

/*
 *  Due to Ben's answer we do wrap field value in Properties[{value}] only for vocab rules
 */
export const adaptRulesToSave = (rulesArr: Filter[] = []): Filter[] =>
  rulesArr
    .map((i) => {
      if (i?.type === 'rule') {
        return {
          ...i,
          rules: adaptRulesToSave(i?.rules),
        };
      }

      return {
        ...i,
        field:
          i.objectTypeId === VOCAB_OBJECT_TYPE_ID
            ? getAdaptedVocabFieldValue(i?.field)
            : i?.field,
      };
    })
    ?.flat()
    .filter(
      (rule) =>
        rule.type !== 'rule' || (rule?.rules && rule?.rules?.length > 0),
    );

export const adaptRulesSet = (rules: { conditions: Filter }[] = []) =>
  rules.map((i) => ({
    ...i,
    conditions: {
      ...i?.conditions,
      rules: adaptRulesToSave(i?.conditions?.rules),
    },
  }));
