import styled from 'styled-components';

export const DeduplicationProjectMergePanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
`;

export const DeduplicationProjectMergePanelStepperWrapper = styled.div`
  padding: 0 16px;
`;
