import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

export const ROLE_PER_USER = gql`
  query rolesPerUser($userId: ID) {
    administration {
      id
      rolesPerUser(userId: $userId) {
        id
        name
        description
        claims {
          id
          friendlyName
          name
          value
        }
      }
    }
  }
`;

export const useQueryRolePerUser = (userId) => {
  const variables = { userId };
  const fetchPolicy = 'network-only';
  const { data, loading, error, refetch } = useQuery(ROLE_PER_USER, {
    variables,
    fetchPolicy,
  });
  const roles = get(data, 'administration.rolesPerUser', []);

  return [roles, loading, error, refetch];
};
