"use strict";

exports.__esModule = true;
exports.ThInnerWrapperUI = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var ThInnerWrapperUI = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: stretch;\n  white-space: nowrap;\n  padding: 0 0 0 16px;\n  width: 100%;\n  width: auto;\n  box-sizing: border-box;\n  ", ";\n  ", ";\n  height: 100%;\n  ", ";\n"])), function (_ref) {
  var resizable = _ref.resizable;
  return resizable ? (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n          padding-right: 8px;\n        "]))) : '';
}, function (_ref2) {
  var reorderable = _ref2.reorderable,
    canDrag = _ref2.canDrag;
  return reorderable && canDrag ? (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n          cursor: ew-resize;\n        "]))) : '';
}, function (_ref3) {
  var dragId = _ref3.dragId;
  return dragId !== 'toString' ? (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n          width: calc(100% - 8px);\n        "]))) : (0, _styledComponents.css)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n          width: calc(100%);\n          max-width: calc(100%);\n          padding: 0;\n        "])));
});
exports.ThInnerWrapperUI = ThInnerWrapperUI;