import { FC, useMemo, useLayoutEffect, useRef } from 'react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useMeasure } from '../../../modules/core/hooks/useMeasure';

interface EllipsisProps {
  children: string | JSX.Element;
}

const ellipsisStr = '...';

const Ellipsis: FC<EllipsisProps> = ({ children }) => {
  const [setMeasureElement, { width: containerWidth }] = useMeasure();
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current?.clientWidth === 0) {
      console.warn(
        `<Ellipsis/> is probably working incorrectly`,
        containerRef.current,
      );
    }

    setMeasureElement(containerRef.current);
  }, [containerRef]);

  const [fullTextWidth, fullTextHeight] = useMemo(
    () => [
      textRef.current?.clientWidth || 0,
      textRef?.current?.clientHeight || 0,
    ],
    [textRef.current],
  );

  const fullText = useMemo(
    () => textRef?.current?.textContent || '',
    [textRef.current],
  );

  const shouldTrunc = containerWidth < fullTextWidth;

  const truncText = useMemo(() => {
    if (shouldTrunc) {
      const overflowWidth = fullTextWidth - containerWidth;
      const overflowPercentage = overflowWidth / (fullTextWidth / 100);
      const truncCount =
        Math.ceil(overflowPercentage * (fullText?.length / 100)) +
        ellipsisStr.length;

      return fullText
        ?.slice(0, fullText?.length - truncCount)
        .concat(ellipsisStr);
    }

    return fullText;
  }, [containerWidth]);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: `${fullTextHeight}px`,
        position: 'relative',
      }}
    >
      <TooltipHost
        content={shouldTrunc ? fullText : ''}
        styles={{
          root: { display: 'block', width: '100%', height: '100%' },
        }}
      >
        <div
          ref={textRef}
          style={{
            whiteSpace: 'nowrap',
            position: 'absolute',
          }}
        >
          {shouldTrunc ? truncText : children}
        </div>
      </TooltipHost>
    </div>
  );
};

export { Ellipsis };
