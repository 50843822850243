export default {
  container: {
    background: '#fff',
    lineHeight: '60px',
    height: '60px',
    boxSizing: 'border-box',
    display: 'flex',
    border: '1px solid #D4DAD1',
  },
  titleContainer: {
    flex: '1',
  },
  title: {
    display: 'inline-block',
    color: '#37373a',
    fontSize: '18px',
    paddingLeft: '30px',
  },
};
