import React, { Component } from 'react';
import { injectIntl } from '@cluedin/locale';
import styled from 'styled-components';
import { Close } from 'uxi/Icons';
import { buttonResetStylesCSSString } from 'uxi/Button/buttonResetStyles';
import styles from './styles';

const { accent, transition } = styles;

/* eslint-disable no-shadow */
const ClearWrapper = styled.button`
  ${buttonResetStylesCSSString};
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  padding: 1px 6px 0;
  transition: ${({ theme: { transition } }) => transition.defaultAll};
  &:focus,
  &:hover {
    border-color: #cecece;
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    * {
      fill: grey;
    }
  }
`;
/* eslint-enable no-shadow */

const InputWrapperUI = styled.div`
  font-size: 14px;
  position: relative;
  transition: ${transition.defaultAll};

  display: flex;
  border-bottom: 2px solid #e0e0e0;

  &:focus-within {
    border-bottom: 2px solid ${({ theme: { palette } }) => palette.accent.main};
    transition: ${transition.defaultAll};
    color: ${({ theme: { palette } }) => palette.accent.main};
  }
  ${({ value }) => value && `border-bottom: 2px solid ${accent};`};
`;

const InputUI = styled.input`
  cursor: pointer;
  font-size: inherit;
  border: none;
  width: 100%;
  padding: 4px 0;
  border: none;
  transition: ${transition.defaultAll};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cecece;
    transition: ${transition.defaultAll};
  }
  &:focus {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #cecece;
    }
    transition: ${transition.defaultAll};
    outline: none;
  }
`;

class AddFilterInput extends Component {
  constructor(props) {
    super(props);

    this.formRef = null;

    this.onResetInput = this.onResetInput.bind(this);
    this.storeFormRef = this.storeFormRef.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidUpdate(previousProps) {
    const { currentFilterQuery } = this.props;

    const previousValue = previousProps.currentFilterQuery || '';
    const value = currentFilterQuery || '';

    if (previousValue !== value && value === '') {
      if (this.formRef) {
        this.formRef.reset();
      }
    }
  }

  onResetInput(e) {
    e.stopPropagation();
    const { onFilterQueryChange } = this.props;

    if (this.formRef) {
      this.formRef.reset();
    }

    onFilterQueryChange({}, '');
  }

  storeFormRef(node) {
    if (node) {
      this.formRef = node;
    }
  }

  handleSubmit(e) {
    // eslint-disable-line class-methods-use-this
    e.preventDefault();
    e.stopPropagation();
  }

  handleKeyPress(e) {
    // eslint-disable-line class-methods-use-this
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const {
      currentFilterQuery,
      onFilterQueryChange,
      filterType,
      intl, // injectIntl() HOC
      onFocusIn,
    } = this.props;

    let filterTypeDisplayName = 'filter';

    if (filterType) {
      const { value } = filterType;
      if (value instanceof Array && value[0]) {
        filterTypeDisplayName = value[0].toLowerCase();
      } else {
        filterTypeDisplayName = value.toLowerCase();
      }
    }

    const typeToFilterLabel = intl.formatMessage({
      id: 'SearchContent.searchFilterFacetsNamePlaceholderFilter',
    });

    return (
      <form onSubmit={this.handleSubmit} ref={this.storeFormRef}>
        <InputWrapperUI value={currentFilterQuery}>
          <InputUI
            onFocus={onFocusIn}
            defaultValue={`${currentFilterQuery || ''}`}
            type="text"
            placeholder={intl.formatMessage({
              id: `SearchContent.searchFilterFacetsNamePlaceholder.${filterTypeDisplayName}`,
              defaultMessage: `${typeToFilterLabel} ${filterTypeDisplayName}`,
            })}
            onChange={(e) => {
              onFilterQueryChange(e, e.target.value);
            }}
            onKeyPress={this.handleKeyPress}
          />
          {currentFilterQuery && (
            <ClearWrapper onClick={this.onResetInput} title="clear input value">
              <Close size="12" color="#9b9b9b" hoverColor="grey" />
            </ClearWrapper>
          )}
        </InputWrapperUI>
      </form>
    );
  }
}

export default injectIntl(AddFilterInput);
