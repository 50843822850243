import { Link } from 'react-router-dom';

import { withExtractIdFromDataSetName } from '../../hocs/withExtractIdFromDataSetName';
import withDataSet from '../../hocs/withDataSet';

export const DataSetName = ({
  providerName,
  dataSet,
  dataSetId,
  style = {},
}) => {
  const name = dataSet ? dataSet.name : providerName;

  return (
    <div style={style}>
      <Link to={`/admin/inbound/datasourceset/dataset/detail/${dataSetId}`}>
        {name}
      </Link>
    </div>
  );
};

export const DataSetNameFromId = withDataSet(DataSetName);

export default withExtractIdFromDataSetName(withDataSet(DataSetName));
