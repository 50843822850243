import React from 'react';
import debounce from 'lodash/debounce';
import { Query } from '@apollo/client/react/components';
import { compose, withStateHandlers } from 'recompose';
import FilterGroup from '../FilterGroup';

import { legacyClient } from '../../../../../../../../data/graphql';
import { allSearchResult } from '../../../../../../data/gql';

const createFacetViewModelFromEntity = (entity, entityTypes) => ({
  key: entity.id,
  value: entity.id,
  displayName: entity.name,
  type: entityTypes.join('-'),
});

const createFacetViewModelsFromEntity = (entities = [], entityTypes = []) =>
  entities.map((e) => createFacetViewModelFromEntity(e, entityTypes));

// TODO: change to HOOK - TODO: CHANGE TO SORT BY NEWLY CREATAED
const EntityFilterGroupWithGQL = ({
  currentFilterQuery,
  entityTypes,
  ...rest
}) => (
  <Query
    client={legacyClient}
    query={allSearchResult}
    variables={{
      query: currentFilterQuery || '*',
      filters: [
        {
          aggregationName: 'entityType',
          values: entityTypes,
        },
      ],
    }}
  >
    {({ loading, data }) => {
      const facets = createFacetViewModelsFromEntity(
        data && data.search && data.search.entries ? data.search.entries : [],
        entityTypes,
      );

      const totalFiltersCount = ((data || {}).search || {}).totalResults || 0;

      return (
        <FilterGroup
          isRemoteFiltering
          filters={facets}
          totalFiltersCount={totalFiltersCount}
          isFetching={loading}
          currentFilterQuery={
            currentFilterQuery !== '*' ? currentFilterQuery : ''
          }
          {...rest}
        />
      );
    }}
  </Query>
);

const EnhancedEntityFilterContainer = compose(
  withStateHandlers(
    { currentFilterQuery: '*' },
    {
      onFilterQueryChange: () =>
        debounce(
          (e, value) => {
            return {
              currentFilterQuery: value,
            };
          },
          300,
          { maxWait: 1200, trailing: true, leading: true },
        ),
    },
  ),
)(EntityFilterGroupWithGQL);

export default EnhancedEntityFilterContainer;
