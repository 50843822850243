"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
//background-color: ${({ theme }) => theme.colors.background.card};
var _default = function _default(Backdrop) {
  return (0, _styledComponents.default)(Backdrop)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  z-index: -1;\n  position: fixed;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  -webkit-tap-highlight-color: transparent;\n  .invisible {\n    background-color: transparent;\n  }\n"])));
};
exports.default = _default;