import { FC } from 'react';
import { useTheme } from '@cluedin/theme';
import { CancelButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import { EntityNodePanelInfoFooterWrapper } from './EntityNodePanelInfoFooterWrapper';
import { EntityNodePanelInfoFooterInnerWrapper } from './EntityNodePanelInfoFooterInnerWrapper';

type EntityNodePanelInfoFooterProps = {
  onClose: () => void;
};

export const EntityNodePanelInfoFooter: FC<EntityNodePanelInfoFooterProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  return (
    <EntityNodePanelInfoFooterWrapper themeWhite={themeWhite}>
      <EntityNodePanelInfoFooterInnerWrapper>
        <CancelButton
          data-test="entityRelationsGraph-entityNodePanel-closeButton"
          onClick={onClose}
          variant="outlined"
          onConfirm={onClose}
          rounded
          style={{ marginRight: '10px' }}
        >
          <FormattedMessage id="module-entityRelationsGraphV2-closeButtonLabel" />
        </CancelButton>
      </EntityNodePanelInfoFooterInnerWrapper>
    </EntityNodePanelInfoFooterWrapper>
  );
};
