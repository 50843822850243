import styled from 'styled-components';

export const AppSearchInputElement = styled.input`
  border: none;
  outline: none;
  box-sizing: border-box;
  transition: all 200ms;
  padding: 0px 32px 0px 12px;
  width: ${({ width }) => width ?? '400px'};
  color: ${({ theme }) => theme.palette.black};

  &::placeholder {
    color: ${({ theme }) => theme.palette.neutralDark};
  }
`;
