import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';

import { legacyClient } from '../../../../../../../data/graphql';

const GET_AGGREGATIONS = gql`
  query aggregateQuery($query: String) {
    aggregate(query: $query) {
      aggregations
    }
  }
`;

export const useSearchAggregation = () => {
  const { data, loading, error } = useQuery(GET_AGGREGATIONS, {
    client: legacyClient,
    variables: {
      query: '*',
    },
    fetchPolicy: 'network-only',
  });

  const filtersConfig = get(data, 'aggregate.aggregations');

  return {
    filtersConfig,
    loadingFiltersConfig: loading,
    loadingFiltersConfigError: error,
  };
};
