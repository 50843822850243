import React from 'react';
import { PrimaryButton, CancelButton, Modal } from '@cluedin/form';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';

import SomethingOdd from '../../../error/components/composites/SomethingOdd';
import MultiSelectionOverviewViewModel from './MultiSelectionOverview/MultiSelectionOverviewViewModel';

const AccessManyToMany = ({
  onClose,
  onAccessClick,
  isSaving,
  show,
  title,
  invalid,
  isRevoke,
  SelectionList,
  onMainListEntitySelect,
  selectedEntities,
  selectionTitle,
  selectionEntityType,
  style,
  hasNotSelected,
}) => (
  <Modal
    width="90%"
    height="90%"
    isOpen={show}
    onClose={onClose}
    bodyStyles={{ padding: 0, ...style }}
    title={title}
    footer={{
      content: (
        <>
          <CancelButton
            text={<FormattedMessage id="cancel" />}
            style={{ marginRight: '8px' }}
            onClick={onClose}
          />

          {isRevoke && (
            <PrimaryButton
              loading={isSaving}
              text={
                <FormattedMessage id="module-accessManyToMany-revokeAccess" />
              }
              type="danger"
              disabled={!selectedEntities || selectedEntities.length === 0}
              onClick={onAccessClick}
            />
          )}

          {!isRevoke && (
            <PrimaryButton
              loading={isSaving}
              text={
                <FormattedMessage id="module-accessManyToMany-grantAccess" />
              }
              disabled={!selectedEntities || selectedEntities.length === 0}
              onClick={onAccessClick}
            />
          )}
        </>
      ),
    }}
  >
    {invalid && <SomethingOdd />}
    {hasNotSelected && (
      <Alert type="error">
        <FormattedMessage id="module-accessManyToMany-atleast1" />
      </Alert>
    )}
    <Alert noIcon>
      <MultiSelectionOverviewViewModel
        title={selectionTitle}
        entityType={selectionEntityType}
        viewModels={selectedEntities}
      />
    </Alert>

    <SelectionList condensed onChange={onMainListEntitySelect} />
  </Modal>
);

export default AccessManyToMany;
