import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';
import { location, clientId } from '../../../../config';
import SignUpUserFromEmailDomainForm from './SignUpUserFromEmailDomainForm';
import Spacer from '../../../core/components/composites/generic/Spacer';

const SignupUserWithEmailDomainForm = ({
  isMutating,
  error,
  teamDomain = {},
  onSignUpOrgEmail,
}) => (
  <div>
    <CluedInBigTitle>
      <FormattedMessage id="module-public-signUpTitle" />
      <strong> {clientId}</strong>
    </CluedInBigTitle>
    {error && (
      <Spacer margin="stack-m">
        <Alert type="danger" noIcon>
          <FormattedMessage id="module-public-errorMessage" />
        </Alert>
      </Spacer>
    )}
    <SignUpUserFromEmailDomainForm
      isMutating={isMutating}
      onSignUpOrgEmail={onSignUpOrgEmail}
      teamDomain={teamDomain}
    />
    <p style={{ fontSize: '12px' }}>
      <FormattedHTMLMessage
        id="module-public-alreadyAnAccount"
        values={{ link: location.goToClientIdSignIn(clientId) }}
      />
    </p>
    <p style={{ fontSize: '12px' }}>
      <FormattedHTMLMessage
        id="module-public-html-noEmailDomain"
        values={{ emailDomainName: (teamDomain || {}).EmailDomainName }}
      />
    </p>
  </div>
);

SignupUserWithEmailDomainForm.displayName = 'SignupUserWithEmailDomainForm';

export default SignupUserWithEmailDomainForm;
