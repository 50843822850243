import RetentionDashboardPage from './components/pages/RetentionDashboardPage';
import AllRetentionPage from './components/pages/AllRetentionPage';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    component: RetentionDashboardPage,
    claims: {
      'governance.dataretention': RACI_LEVELS.CONSULTANT,
    },
  },
  {
    path: '/all',
    component: AllRetentionPage,
    claims: {
      'governance.dataretention': RACI_LEVELS.CONSULTANT,
    },
  },
  {
    path: '/create/query',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"CreateQueryRetentionPage" */
        './components/pages/CreateQueryRetentionPage'
      ),
    ),
    claims: {
      'governance.dataretention': RACI_LEVELS.CONSULTANT,
    },
  },
  {
    path: '/create/entity',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"CreateEntityRetentionpage" */
        './components/pages/CreateEntityRetentionpage'
      ),
    ),
    claims: {
      'governance.dataretention': RACI_LEVELS.CONSULTANT,
    },
  },
];
