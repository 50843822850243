import React from 'react';
import Img from 'uxi/Img';
import { TextEllipsis } from 'uxi/Text';
import PersonListItemLayout from './PersonListItemLayout';
import SocialMenu from '../../../../../../entityViewModel/components/composites/social/SocialMenu';
import { socialIconSize, logosize } from '../../theme';

const PersonListItem = ({
  person: {
    name,
    logo: logoProp = {},
    entityId,
    jobTitle,
    email: emailProp,
    phoneNumber: phoneNumberProp,
    social,
  },
  showEntityQuickView,
}) => {
  const email = emailProp ? ( // eslint-disable-line no-nested-ternary
    emailProp.indexOf('@') > -1 ? (
      <a
        href={`mailto:${emailProp}`}
        title={`email ${emailProp}`}
        style={{ width: '100%' }}
      >
        <TextEllipsis>{emailProp}</TextEllipsis>
      </a>
    ) : (
      <TextEllipsis>{`@${emailProp}`}</TextEllipsis>
    )
  ) : null;

  const phoneNumber = phoneNumberProp ? (
    <a href={`tel:${phoneNumberProp}`} title={`phone ${phoneNumberProp}`}>
      <TextEllipsis>{phoneNumberProp}</TextEllipsis>
    </a>
  ) : null;

  const socialMenuContent = Object.keys(social).reduce((a, x) => x || a, 0) ? (
    <SocialMenu
      iconSize={socialIconSize}
      socialViewModel={social}
      style={{ marginTop: '8px' }}
    />
  ) : (
    <span>&nbsp;</span>
  );

  const logo = (
    <Img
      src={logoProp.large || logoProp.small || ''}
      alt={`avatar pic of ${name}`}
      width={logosize}
      style={{
        borderRadius: '50%',
      }}
    />
  );

  return (
    <PersonListItemLayout
      showEntityQuickView={showEntityQuickView}
      entityId={entityId}
      name={name}
      jobTitle={jobTitle}
      email={email}
      phoneNumber={phoneNumber}
      socialMenu={socialMenuContent}
      logo={logo}
    />
  );
};

PersonListItem.displayName = 'PersonListItem';

export default PersonListItem;
