import React from 'react';
import { connect } from 'react-redux';
import {
  resetPromoteUserInformation,
  resetDemoteUserInformation,
  resetRealTimeInviteUserInformation,
} from '../../../user/actions';
import NewUserPromoteNotification from '../../../user/components/composites/notifications/NewUserPromoteNotification';
import NewUserDemoteNotification from '../../../user/components/composites/notifications/NewUserDemoteNotification';
import NewUserInvitedNotification from '../../../user/components/composites/notifications/NewUserInvitedNotification';
import NewEntityNotification from '../composites/types/NewEntityNotification';
import MergeFinishedNotification from '../composites/types/MergeFinishedNotification';
import { resetNewEntityUpdate } from '../../../../action/entity';
import { resetRealTimeNotify } from '../../../../action/core';
import {
  resetSearchNotificationAction,
  resetFollowNotificationAction,
} from '../../../stream/actions';
import {
  crawlerNotificationResetActions,
  upgradePlanResetActions,
  newUserNotificationActions,
  failedAuthenticationNotificationActions,
  meshQueueMessageNotificationActions,
  meshMessageNotificationActions,
  mergeFinishedNotificationActions,
  newProviderNotificationActions,
  newConfigurationNotificationActions,
} from '../../actions';
import NewSearchNotification from '../composites/types/NewSearchNotification';
import NewCluedInNotification from '../composites/types/NewCluedInNotification';
import CrawlerFinishedNotification from '../composites/types/CrawlerFinishedNotification';
import NewUserNotification from '../composites/types/NewUserNotification';
import WebhookNotification from '../composites/types/WebhookNotification';
import FailedAuthNotification from '../composites/types/FailedAuthNotification';
import UpgradePlanNotification from '../composites/types/UpgradePlanNotification';
import MeshAPIQueuedMessage from '../composites/types/MeshAPIQueuedMessage';
import MeshAPIMessage from '../composites/types/MeshAPIMessage';
import NewConfigurationNotification from '../composites/types/NewConfigurationNotification';
import NewIntegrationNotification from '../composites/types/NewIntegrationNotification';

const CluedInNotification = ({
  followNotifications,
  entityNewUpdate,
  searchesNotifications,
  promoteUserNotifications,
  demoteUserNotifications,
  cluedinNotifications,
  mergeFinishedNotification,
  newInviteUserNotifications,
  newProviderNotifications,
  newConfigurationNotifications,
  crawlerFinishedNotifications,
  upgradePlanNotifications,
  newUserNotifications,
  failedAuthenticationNotifications,
  meshAPIQueuedMessageNotification,
  meshAPIMessageNotification,
  resetNewEntityNotification,
  resetNewInviteUserNotification,
  resetNewSearchNotification,
  resetPromoteUserNotification,
  resetDemoteUserNotification,
  resetCluedInNotification,
  resetNewEntityFollowNotification,
  resetNewProviderNotification,
  resetNewConfigurationNotification,
  resetCrawlerFinishedNotificationHandler,
  resetUpgradePlanHandler,
  resetNewUserInvitationHandler,
  resetFailedAuthenticationNotificationHandler,
  resetMeshQueuedMessageNotificationHandler,
  resetMeshMessageNotificationHandler,
  resetMergeFinishedMessageNotificationHandler,
  // get gdpr notif
}) => {
  const notifications = [];
  const onlySHow = (e) => e.show;

  if (newProviderNotifications) {
    newProviderNotifications
      .filter(onlySHow)
      .forEach((newProviderNotification, index) => {
        notifications.push(
          <NewIntegrationNotification
            key={`NewProviderNotification-${index}`}
            providerNotification={newProviderNotification}
            index={notifications.length}
            onCloseClick={() => {
              resetNewProviderNotification(newProviderNotification);
            }}
          />,
        );
      });
  }

  if (newConfigurationNotifications) {
    newConfigurationNotifications
      .filter(onlySHow)
      .forEach((newConfigurationNotification, index) => {
        notifications.push(
          <NewConfigurationNotification
            key={`NewConfigurationNotification-${index}`}
            providerNotification={newConfigurationNotification}
            index={notifications.length}
            onCloseClick={() => {
              resetNewConfigurationNotification(newConfigurationNotification);
            }}
          />,
        );
      });
  }

  if (mergeFinishedNotification) {
    mergeFinishedNotification
      .filter(onlySHow)
      .forEach((mergeFinishedNotif, index) => {
        notifications.push(
          <MergeFinishedNotification
            key={`NewFollowNotification-${index}`}
            mergeFinishedData={mergeFinishedNotif}
            index={notifications.length}
            onCloseClick={() => {
              resetMergeFinishedMessageNotificationHandler(mergeFinishedNotif);
            }}
          />,
        );
      });
  }

  if (followNotifications) {
    followNotifications
      .filter(onlySHow)
      .forEach((newInsightNotification, index) => {
        notifications.push(
          <NewEntityNotification
            key={`NewFollowNotification-${index}`}
            entityNewUpdate={newInsightNotification}
            index={notifications.length}
            onCloseClick={() => {
              resetNewEntityFollowNotification(newInsightNotification);
            }}
          />,
        );
      });
  }

  if (newInviteUserNotifications) {
    newInviteUserNotifications
      .filter(onlySHow)
      .forEach((newInviteUserDialogNotification, index) => {
        notifications.push(
          <NewUserInvitedNotification
            key={`NewUserInvitedNotification-${index}`}
            invitation={newInviteUserDialogNotification.invitation}
            index={notifications.length}
            onCloseClick={() => {
              resetNewInviteUserNotification(newInviteUserDialogNotification);
            }}
          />,
        );
      });
  }

  if (promoteUserNotifications) {
    promoteUserNotifications.filter(onlySHow).forEach((u, index) => {
      notifications.push(
        <NewUserPromoteNotification
          key={`NewUserPromote-${index}`}
          userPromoted={u}
          index={notifications.length}
          onCloseClick={() => {
            resetPromoteUserNotification(u);
          }}
        />,
      );
    });
  }

  if (demoteUserNotifications) {
    demoteUserNotifications
      .filter(onlySHow)
      .forEach((demoteNotification, index) => {
        notifications.push(
          <NewUserDemoteNotification
            key={`NewUserDemote-${index}`}
            userDemoted={demoteNotification}
            index={notifications.length}
            onCloseClick={() => {
              resetDemoteUserNotification(demoteNotification);
            }}
          />,
        );
      });
  }

  if (entityNewUpdate) {
    entityNewUpdate.filter(onlySHow).forEach((e, index) => {
      notifications.push(
        <WebhookNotification
          key={`NewEntity-${index}`}
          entityNewUpdate={e}
          index={notifications.length}
          onCloseClick={() => {
            resetNewEntityNotification(e);
          }}
        />,
      );
    });
  }

  if (searchesNotifications && searchesNotifications.length > 0) {
    searchesNotifications.filter(onlySHow).forEach((searchNotif, i) => {
      notifications.push(
        <NewSearchNotification
          key={`SearchNotif-${i}`}
          searchNotification={searchNotif}
          index={notifications.length}
          onCloseClick={() => {
            resetNewSearchNotification(searchNotif);
          }}
        />,
      );
    });
  }

  if (cluedinNotifications && cluedinNotifications.length > 0) {
    cluedinNotifications.filter(onlySHow).forEach((notification, i) => {
      notifications.push(
        <NewCluedInNotification
          key={`CluedInNotification-${i}`}
          notification={notification}
          index={notifications.length}
          onCloseClick={() => {
            resetCluedInNotification(notification);
          }}
        />,
      );
    });
  }

  if (crawlerFinishedNotifications && crawlerFinishedNotifications.length > 0) {
    crawlerFinishedNotifications.filter(onlySHow).forEach((notification, i) => {
      notifications.push(
        <CrawlerFinishedNotification
          key={`CrawlerFinishedNotification-${i}`}
          notification={notification}
          index={notifications.length}
          onCloseClick={() => {
            resetCrawlerFinishedNotificationHandler(notification);
          }}
        />,
      );
    });
  }

  if (upgradePlanNotifications && upgradePlanNotifications.length > 0) {
    upgradePlanNotifications.filter(onlySHow).forEach((notification, i) => {
      notifications.push(
        <UpgradePlanNotification
          key={`UpdadePlanFinishedNotification-${i}`}
          notification={notification}
          index={notifications.length}
          onCloseClick={() => {
            resetUpgradePlanHandler(notification);
          }}
        />,
      );
    });
  }

  if (newUserNotifications && newUserNotifications.length > 0) {
    newUserNotifications.filter(onlySHow).forEach((notification, i) => {
      notifications.push(
        <NewUserNotification
          key={`NewUserInformation-${i}`}
          notification={notification}
          index={notifications.length}
          onCloseClick={() => {
            resetNewUserInvitationHandler(notification);
          }}
        />,
      );
    });
  }

  if (
    failedAuthenticationNotifications &&
    failedAuthenticationNotifications.length > 0
  ) {
    failedAuthenticationNotifications
      .filter(onlySHow)
      .forEach((notification, i) => {
        notifications.push(
          <FailedAuthNotification
            key={`FailedAuth-${i}`}
            notification={notification}
            index={notifications.length}
            onCloseClick={() => {
              resetFailedAuthenticationNotificationHandler(notification);
            }}
          />,
        );
      });
  }
  // MeshAPIQueuedMessage
  if (
    meshAPIQueuedMessageNotification &&
    meshAPIQueuedMessageNotification.length > 0
  ) {
    meshAPIQueuedMessageNotification
      .filter(onlySHow)
      .forEach((notification, i) => {
        notifications.push(
          <MeshAPIQueuedMessage
            key={`MeshAPIQueuedEdit-${i}`}
            notification={notification}
            index={notifications.length}
            onCloseClick={() => {
              resetMeshQueuedMessageNotificationHandler(notification);
            }}
          />,
        );
      });
  }
  // MeshAPIMessage
  if (meshAPIMessageNotification && meshAPIMessageNotification.length > 0) {
    meshAPIMessageNotification.filter(onlySHow).forEach((notification, i) => {
      notifications.push(
        <MeshAPIMessage
          key={`MeshAPIEdit-${i}`}
          notification={notification}
          index={notifications.length}
          onCloseClick={() => {
            resetMeshMessageNotificationHandler(notification);
          }}
        />,
      );
    });
  }

  if (notifications.length === 0) {
    return <div />;
  }

  return <div style={{ zIndex: 9 }}>{notifications}</div>;
};

const mapToSTateProps = ({
  entity: { entityNewUpdate },
  notification: {
    mergeFinishedNotification,
    newProviderNotifications,
    newConfigurationNotifications,
    crawlerFinishedNotifications,
    upgradePlanNotifications,
    newUserNotifications,
    failedAuthenticationNotifications,
    meshAPIQueuedMessageNotification,
    meshAPIMessageNotification,
  },
  user: {
    newInviteUserNotifications,
    promoteUserNotifications,
    demoteUserNotifications,
  },
  stream: { searchesNotifications, followNotifications },
  core: { cluedinNotifications },
}) => ({
  entityNewUpdate,
  followNotifications,
  mergeFinishedNotification,
  searchesNotifications,
  promoteUserNotifications,
  demoteUserNotifications,
  cluedinNotifications,
  newInviteUserNotifications,
  newProviderNotifications,
  newConfigurationNotifications,
  crawlerFinishedNotifications,
  upgradePlanNotifications,
  newUserNotifications,
  failedAuthenticationNotifications,
  meshAPIQueuedMessageNotification,
  meshAPIMessageNotification,
});

const mapDispatchToProps = (dispatch) => ({
  resetNewEntityNotification(e) {
    dispatch(resetNewEntityUpdate(e.entity.id));
  },
  resetNewInviteUserNotification(invitation) {
    dispatch(resetRealTimeInviteUserInformation(invitation));
  },
  resetNewSearchNotification(searchNotification) {
    dispatch(resetSearchNotificationAction(searchNotification));
  },
  resetPromoteUserNotification(promoteUserInformation) {
    dispatch(resetPromoteUserInformation(promoteUserInformation));
  },
  resetDemoteUserNotification(demotedUserInformation) {
    dispatch(resetDemoteUserInformation(demotedUserInformation));
  },
  resetCluedInNotification(notification) {
    dispatch(resetRealTimeNotify(notification));
  },
  resetNewEntityFollowNotification(notification) {
    dispatch(resetFollowNotificationAction(notification.entity.id));
  },
  resetNewProviderNotification(notification) {
    dispatch(newConfigurationNotificationActions.reset(notification));
  },
  resetNewConfigurationNotification(notification) {
    dispatch(newProviderNotificationActions.reset(notification));
  },
  resetCrawlerFinishedNotificationHandler(notification) {
    dispatch(crawlerNotificationResetActions.reset(notification));
  },
  resetUpgradePlanHandler(notification) {
    dispatch(upgradePlanResetActions.reset(notification));
  },
  resetNewUserInvitationHandler(notification) {
    dispatch(newUserNotificationActions.reset(notification));
  },
  resetFailedAuthenticationNotificationHandler(notification) {
    dispatch(failedAuthenticationNotificationActions.reset(notification));
  },
  resetMeshQueuedMessageNotificationHandler(notification) {
    dispatch(meshQueueMessageNotificationActions.reset(notification));
  },
  resetMeshMessageNotificationHandler(notification) {
    dispatch(meshMessageNotificationActions.reset(notification));
  },
  resetMergeFinishedMessageNotificationHandler(notification) {
    dispatch(mergeFinishedNotificationActions.reset(notification));
  },
});
export default connect(
  mapToSTateProps,
  mapDispatchToProps,
)(CluedInNotification);
