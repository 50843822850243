import useViewMode from './useViewMode';

import { createColumnForGrid } from '../../../../helpers/columnConfiguration';
import useCustomizedColumns from '../../../../../core/hooks/useCustomizedColumns';
import { useVocabKeysByKeys } from '../../../../../dataCatalog/hooks/useVocabKeysByKeys';

export const useViewModeWithCustomizedColumns = (
  initialViewName,
  defaultVocabularyKeys,
  defaultEntityKeys,
  onChangeSortingByColumn,
  filters,
  hasStrongTypingFeature,
) => {
  const [
    viewMode,
    viewName,
    setViewMode,
    columnsConfiguration,
    DataGrid,
    DataGridGhost,
    restrictions,
  ] = useViewMode(initialViewName, {
    defaultVocabularyKeys,
    defaultEntityKeys,
  });

  const customAddColumns = (
    originalColumns,
    newColumns,
    updatedOriginalColumnsCb,
  ) => {
    const prevFilteredColumns = originalColumns?.filter(
      (i) => i.type === 'entity',
    );

    updatedOriginalColumnsCb([...prevFilteredColumns, ...newColumns]);
  };

  const [
    columns,
    saveCustomizedColumns,
    addColumns,
    deleteColumns,
    getColumnsForViewName,
  ] = useCustomizedColumns(viewName, columnsConfiguration, customAddColumns);

  const vKeys = (columns || [])
    .map((c) => {
      if (c.type === 'vocabulary') {
        return c.property;
      }

      return null;
    })
    .filter(Boolean);

  const { vocabularyKeys, loading, error } = useVocabKeysByKeys(vKeys);

  // This is what should be used by the grid
  const columnsForGrid = createColumnForGrid(
    columns,
    vocabularyKeys,
    onChangeSortingByColumn,
    filters,
    hasStrongTypingFeature,
  );

  const columnsWithKeys = columns.map((column) => {
    if (column.type === 'vocabulary') {
      const vocabKey = (vocabularyKeys || []).find(
        (v) => v.key === column.property,
      );

      column.vocabKey = vocabKey;
      return column;
    }

    return column;
  });

  return [
    {
      DataGrid,
      DataGridGhost,
      columnsForGrid,
    },
    {
      columns: columnsWithKeys,
      saveCustomizedColumns,
      addColumns,
      deleteColumns,
      restrictions,
      getColumnsForViewName,
    },
    {
      viewMode,
      setViewMode,
    },
  ];
};
