import React, { useState } from 'react';
import SomethingOdd from './dialogs/SomethingOdd';
import IdleManager from './IdleManager';
import NoToken from './PageNoToken';
import AppStyles from '../../../styles';
import { idleTimeout } from '../../../../config';
import PageLoader from '../composites/PageLoader';
import { BackButtonContext } from '../../../../components/PillarBackButton';
import { getNewToken } from '../../helpers/token';
import { OrganizationFeatureProvider } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';
import { MultiUploaderProvider } from '../../../uploadFile/components/container/MultiUploader/MultiUploaderProvider';

const App = (props) => {
  const {
    q,
    id,
    PageWrapper,
    children,
    token,
    location,
    params,
    onInactive,
    isLoadingEntityConfigurations,
    isFetchingAllIntegration,
    modules,
  } = props;
  let timeoutValue = -1;

  if (idleTimeout > 0) {
    timeoutValue = idleTimeout;
  }

  const pathName = location ? location.pathname : '';
  const [showBack, setShowBack] = useState({ showBack: false });

  const renderChildren = () => {
    if (!token) {
      return <NoToken />;
    }
    if (isLoadingEntityConfigurations || isFetchingAllIntegration) {
      return <PageLoader />;
    }
    return children;
  };
  try {
    return (
      <div className="root">
        {timeoutValue > 0 ? (
          <IdleManager
            timeout={timeoutValue}
            onInactive={onInactive}
            onSkip={() => {
              getNewToken().catch(() => {
                if (onInactive) {
                  onInactive();
                }
              });
            }}
          />
        ) : null}
        <AppStyles>
          <OrganizationFeatureProvider>
            <MultiUploaderProvider>
              <BackButtonContext.Provider value={[showBack, setShowBack]}>
                <PageWrapper
                  id={id}
                  q={q}
                  pathName={pathName}
                  routerLocation={location}
                  routerParams={params}
                  modules={modules}
                >
                  {renderChildren()}
                </PageWrapper>
              </BackButtonContext.Provider>
            </MultiUploaderProvider>
          </OrganizationFeatureProvider>
        </AppStyles>
      </div>
    );
  } catch (e) {
    return <SomethingOdd open />;
  }
};

App.displayName = 'App';

export default App;
