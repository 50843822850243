import { Badge } from 'uxi/Badge';

const UserRoleVocab = ({ role }) => {
  return (
    <Badge style={{ margin: '5px 5px 5px 0' }} type="info">
      {role}
    </Badge>
  );
};

const UserRoles = (
  { roles = [] }, // eslint-disable-line react/prop-types
) => (
  <div>
    {roles.map((role, i) => (
      <UserRoleVocab role={role} key={role || i} />
    ))}
  </div>
);

export default UserRoles;
