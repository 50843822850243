import React from 'react';
import { PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

const UserConsentActions = ({ onClick, isSaving }) => (
  <div>
    <PrimaryButton
      onClick={onClick}
      loading={isSaving}
      text={
        <FormattedMessage
          id="module-consent-giveConsent"
          defaultMessage="I GIVE CONSENT"
        />
      }
    />
  </div>
);

export default UserConsentActions;
