import styled from 'styled-components';

export const ExplainLogDetailBox = styled.div`
  background: #ffffff;
  width: auto;
  max-width: 1200px;
`;

export const ExplainLogDetailWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding: 12px 0 0 0;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;

export const ExplainLogDetailHeadingWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
`;

export const ExplainLogDetailDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & strong {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2px;
  }

  & span {
    color: #666666;
    font-size: 14px;
  }
`;

export const ExplainLogDataWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 100ms ease;
  width: 100%;
`;

export const ExplainLogDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
`;

export const ExplainLogDetailStatus = styled.div`
  color: ${({ themePrimary }) => themePrimary};
  padding: 0;
  margin: 0;
`;

export const ExplainLogDetailBadStatus = styled.div`
  color: rgb(255, 152, 0);
  padding: 0;
  margin: 0;
`;

export const ExplainLogDetailsPre = styled.pre`
  border: 1px solid #ececec;
  padding: 8px;
  margin-top: 8px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  width: 100%;
  word-break: keep-all;
`;
