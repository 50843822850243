import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { register } from '../../registry';
import { Externallink } from 'uxi/Icons';

const SuggestedSearchTitle = styled.span`
  color: #2f2f32;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ececec;
  padding: 0 12px;
`;

const EmptyContent = styled.div`
  padding: 48px 0;
  text-align: center;
  font-size: 14px;
`;

const GlobalMetricsDisable: FC<{
  template: {
    name?: string | ReactNode;
    displayName?: string | ReactNode;
    pageTemplateId: string;
    hasNoSuggestedSearchEvenIfEnabled: boolean;
  };
}> = ({ template }) => {
  return (
    <div
      style={{
        margin: '24px 24px 24px 24px',
        border: '1px solid #ececec',
        background: 'white',
      }}
    >
      <SuggestedSearchTitle>
        <FormattedMessage id="module-entityWidget-noQualityMetricsEnableInLayout" />
      </SuggestedSearchTitle>
      <div>
        <EmptyContent>
          <span>
            <FormattedMessage id="module-entityWidget-noQualityMetricsEnableMessageInLayout" />
          </span>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/settings/entity-page-layout/edit?id=${encodeURIComponent(
              template.pageTemplateId,
            )}`}
          >
            {template.displayName || template.name}
            <div style={{ marginLeft: '6px', display: 'inline-block' }}>
              <Externallink size={12} />
            </div>
          </Link>
        </EmptyContent>
      </div>
    </div>
  );
};

register('MetricsDisableInLayout', GlobalMetricsDisable, {
  name: 'MetricsDisableInLayout',
  displayName: 'Metrics is disabled in layout',
  description: 'Display if metrics is disabled in layout',
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
