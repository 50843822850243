import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { LayoutOutlineIcon } from '@cluedin/svgs';

import routes from './routes';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  name: 'Entity Page Layouts',
  displayName: <FormattedMessage id="module-entityPageLayout-adminName" />,
  path: '/entity-page-layout',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: LayoutOutlineIcon,
        name: <FormattedMessage id="module-entityPageLayout-adminName" />,
        link: '/admin/settings/entity-page-layout/',
        // claims: admin.users.user.view, !! TODO
      },
    ],
  },
  routes,
};
