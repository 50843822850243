import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import UniqueIdentifierConsent from './UniqueIdentifierConsent';

const UserConsentIdentifierList = ({ uniqueIdentifiers, onChange }) => {
  const requiredUniqueIdentifiers = uniqueIdentifiers.filter(
    (u) => u.isRequired,
  );
  const optionalUniqueIdentifiers = uniqueIdentifiers.filter(
    (u) => !u.isRequired,
  );
  const mainTitle = (
    <FormattedMessage
      id="module-gdpr-configureUniqueIdentifiers"
      values={{
        uniqueIdentifierLength: uniqueIdentifiers.length,
        plural: uniqueIdentifiers.length > 1 ? 's' : '',
      }}
    />
  );

  return (
    <div>
      <div style={{ border: '1px solid #ccc', padding: '15px' }}>
        {' '}
        {mainTitle}{' '}
      </div>
      {requiredUniqueIdentifiers.map((p) => (
        <UniqueIdentifierConsent uniqueIdentifier={p} onChange={onChange} />
      ))}
      {optionalUniqueIdentifiers.map((p) => (
        <UniqueIdentifierConsent uniqueIdentifier={p} onChange={onChange} />
      ))}
    </div>
  );
};

export default UserConsentIdentifierList;
