/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

function renderChildren(children, props) {
  return React.Children.map(children, (child, index) => {
    // if child is not a tab we don't need to clone it
    // since we don't need to add custom props
    let isFirst = false;
    if (child && child.type !== Tab) {
      return child;
    }
    if (index === 0) {
      isFirst = true;
    }

    const clonedProps = {
      isMainStyle: props.isMainStyle,
      activeTabClassName: props.activeTabClassName,
      disabledTabClassName: props.disabledTabClassName,
      isFirst,
    };

    return React.cloneElement(child, clonedProps);
  });
}

const TabListStyle = {
  tabList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    background: '#EEEEEE',
  },
  tabListMain: {
    borderTop: 0,
    borderBottom: '1px solid #D4DAD1',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    background: '#fff',
  },
};

class TabList extends Component {
  static displayName = 'TabList';
  static propTypes = {
    className: PropTypes.string,
    isMainStyle: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    activeTabClassName: PropTypes.string,
    disabledTabClassName: PropTypes.string,
  };

  render() {
    const {
      className,
      children,
      isMainStyle,
      activeTabClassName,
      disabledTabClassName,
      ...attributes
    } = this.props;

    let mergedStyle = TabListStyle.tabList;

    if (isMainStyle) {
      mergedStyle = TabListStyle.tabListMain;
    }
    return (
      <ul
        {...attributes}
        style={{
          ...mergedStyle,
          ...(attributes.style || {}),
        }}
        className={className}
        role="tablist"
      >
        {renderChildren(children, this.props)}
      </ul>
    );
  }
}

export default TabList;
