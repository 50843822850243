import Privacypage from './components/pages/PrivacyPage';
import FrameWrapperPage from './components/pages/FrameWrapperPage';
import ConsentPage from './components/pages/ConsentPage';
import IdentifyPage from './components/pages/IdentifyPage';

const FrameRoutes = {
  path: '/frame',
  component: FrameWrapperPage,
  routes: [
    {
      path: '/identify',
      component: IdentifyPage,
    },
    {
      path: '/consent/:consentFormID',
      component: ConsentPage,
    },
    {
      path: '/privacy',
      component: Privacypage,
    },
  ],
};

export default FrameRoutes;
