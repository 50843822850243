import React, { Component } from 'react';
import { take } from 'lodash';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { ListToolbar } from '@cluedin/list-pattern';

import { escapeRegex } from '../../../core/helpers/string';
import SchemaListSelection from './SchemaListSelection';

class SchemaListSelectionWithFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      query: '',
    };
    this.setQueryHandler = this.setQueryHandler.bind(this);
    this.onLoadMoreHandler = this.onLoadMoreHandler.bind(this);
  }

  onLoadMoreHandler(pageNumber) {
    this.setState({
      pageNumber,
    });
  }

  setQueryHandler(value) {
    this.setState({
      query: value,
      pageNumber: 0,
    });
  }

  filterSchema() {
    const { schema } = this.props;
    const { pageNumber, query } = this.state;

    const filtered = query
      ? (schema || []).filter(
          (c) =>
            c.Key.match(new RegExp(escapeRegex(query), 'i')) ||
            (c.DisplayName || '').match(new RegExp(escapeRegex(query), 'i')),
        )
      : schema;

    return take(filtered, 100 * (pageNumber + 1));
  }
  render() {
    const { schema, onSchemaSelect, unavailableProperties, intl } = this.props;
    const { pageNumber } = this.state;
    const itemsTaken = this.filterSchema();
    const showTotal = itemsTaken.length;
    const hasShow = showTotal !== schema.length;

    return (
      <>
        <div
          style={{
            padding: '16px',
            borderTop: '1px solid #ccc',
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListToolbar
            style={{ padding: 0, margin: 0 }}
            onSubmitSearch={this.setQueryHandler}
            onChangeSearch={this.setQueryHandler}
            searchName={this.state.query}
            searchInputPlaceholder={intl.formatMessage({
              id: 'module-schema-searchForVocabTerm',
            })}
          />

          <div style={{ textAlign: 'right', flex: 1 }}>
            {hasShow && (
              <span>
                <span>Showing&nbsp;</span>
                <span style={{ fontWeight: 'bold' }}>{itemsTaken.length}</span>
                <span>&nbsp;out&nbsp;of&nbsp;</span>
              </span>
            )}
            <span style={{ fontWeight: 'bold' }}>{schema.length}</span>&nbsp;
            <FormattedMessage id="module-schema-vocabTerms" />
          </div>
        </div>

        <SchemaListSelection
          unavailableProperties={unavailableProperties}
          onLoadMore={this.onLoadMoreHandler}
          schema={itemsTaken}
          data={itemsTaken || []}
          pageNumber={pageNumber}
          onSchemaSelect={onSchemaSelect}
        />
      </>
    );
  }
}
export default injectIntl(SchemaListSelectionWithFilters);
