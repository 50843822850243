import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Text from 'uxi/Text';
import UserConsentProcessorList from './UserConsentProcessorList';
import UserExplanation from './UserExplanation';
import UserConsentIdentifierList from './UserConsentIdentifierList';
import { RequiredUI } from './UniqueIdentifierConsent';

const UserConsent = ({
  consent,
  onChangeUniqueIdentifier,
  onChangeDataProcessor,
  processorsViewModel,
  uniqueIdentifiersViewModel,
}) => {
  if (!consent) {
    return <div />;
  }
  const { shortExplanation, privacyLink } = consent;

  return (
    <div style={{ padding: '16px' }}>
      <UserExplanation
        style={{ padding: '24px 0 16px' }}
        explantation={shortExplanation}
      />
      {uniqueIdentifiersViewModel && uniqueIdentifiersViewModel.length > 0 && (
        <UserConsentIdentifierList
          uniqueIdentifiers={uniqueIdentifiersViewModel}
          onChange={onChangeUniqueIdentifier}
        />
      )}
      <Text
        type="caption"
        style={{ display: 'block', textAlign: 'right', margin: '16px 0' }}
      >
        <RequiredUI />
        <FormattedMessage id="module-gdpr-requiredToUser" />
      </Text>
      {processorsViewModel && processorsViewModel.length > 0 && (
        <div style={{ marginTop: '15px' }}>
          <UserConsentProcessorList
            processors={processorsViewModel}
            onChange={onChangeDataProcessor}
          />
        </div>
      )}
      <div style={{ textAlign: 'right', padding: '15px 0 0 0' }}>
        <a href={privacyLink}>
          <FormattedMessage id="module-gdpr-learnMoreDataRights" />
        </a>
      </div>
    </div>
  );
};

UserConsent.displayName = 'UserConsent';

export default UserConsent;
