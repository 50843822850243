import React from 'react';
import { Panel, Modal } from '@cluedin/form';
import WrapperMD from './WrapperMD';

const HelpExtraContent = ({
  open,
  noExtra,
  setOpen,
  contentTypeExtra,
  data,
  modalHeight,
  modalWidth,
}) => {
  return (
    <>
      {!noExtra && contentTypeExtra === 'slidePanel' && (
        <Panel open={open} onClose={() => setOpen(false)} title={data?.title}>
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </Panel>
      )}
      {!noExtra && contentTypeExtra === 'dialog' && (
        <Modal
          isOpen={open}
          title={data?.title}
          onClose={() => setOpen(false)}
          height={modalHeight}
          width={modalWidth}
        >
          <WrapperMD dangerouslySetInnerHTML={{ __html: data?.content }} />
        </Modal>
      )}
    </>
  );
};

export default HelpExtraContent;
