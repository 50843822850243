import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_VOCABULARY_CLASSIFICATION = gql`
  query dataClassifications {
    management {
      id
      dataClassifications
    }
  }
`;

export const useQueryVocabularyKeyClassifications = (id) => {
  const { data, loading, error } = useQuery(GET_VOCABULARY_CLASSIFICATION, {
    variables: {
      id,
    },
  });

  const classifications = get(data, 'management.dataClassifications');

  return [classifications, loading, error];
};

export default {
  useQueryVocabularyKeyClassifications,
};
