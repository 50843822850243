import React from 'react';

const ErrorText = ({ children }) => {
  return (
    <span
      style={{
        padding: '0 6px',
        fontSize: '12px',
        color: '#d13f48',
      }}
    >
      {children}
    </span>
  );
};

export default ErrorText;
