import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_RELATIONS = gql`
  query getEntityRelations($entityId: ID!) {
    virtualization {
      getEntityRelations(entityId: $entityId) {
        id
        name
        icon
        route
        displayName
        attributeOrigin
        codes
        isShadowEntity
        isTemporalEntity
        type
        nodeKind
        edges {
          edgeType
          direction
          entityType
          entityId
          icon
          route
          name
          attributeOrigin
          codes
          createdDate
          isShadowEntity
          isTemporalEntity
          isGrouped
          groupedEntityIds
          entityCount
          properties
          displayName
          nodeKind
        }
      }
    }
  }
`;

export const useGetEntityRelations = (entityId) => {
  const { data, loading, error } = useQuery(GET_ENTITY_RELATIONS, {
    variables: { entityId },
  });

  const entityRelations = get(data, 'virtualization.getEntityRelations');

  return [entityRelations, loading, error];
};
