import { DataSourceDatabase, ManuallyAddedIcon } from '@cluedin/svgs';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';

import { withIntegrationFromIdForSources } from '../../../../../integration/components/hocs/withIntegrationFromId';
import {
  EntitySourceItemWrapper,
  EntitySourceItemLogo,
  EntitySourceManuallyAddedIcon,
  EntitySourceItemDisplayName,
} from './EntitySourceItem.styles';

const EntitySourceItem = ({
  AccountInformation,
  ProviderName,
  ProviderId,
  integration,
  index,
  ...props
}) => {
  const accountDisplayName =
    AccountInformation?.AccountDisplay || AccountInformation?.AccountId || '–';

  const displayName = accountDisplayName;
  const iconSrc = integration?.Icon || null;

  const tooltipId = useId();

  const isManualEntrySource =
    integration?.Name?.toLowerCase() === 'manual entry source' ||
    ProviderId === 'fdcbf2d5-996f-424e-a5dc-a24251e5120b';

  let icon = <DataSourceDatabase style={{ height: 32, width: 32 }} />;
  if (iconSrc) {
    icon = (
      <EntitySourceItemLogo
        src={iconSrc}
        alt={ProviderName}
        style={{ overflow: 'hidden' }}
      />
    );
  }
  if (isManualEntrySource) {
    icon = (
      <EntitySourceManuallyAddedIcon>
        <ManuallyAddedIcon />
      </EntitySourceManuallyAddedIcon>
    );
  }

  return (
    <TooltipHost
      content={
        <>
          {ProviderName} - {displayName}
        </>
      }
      calloutProps={{
        gapSpace: 4,
        target: `#sourceitem-${index}`,
      }}
      id={tooltipId}
    >
      <EntitySourceItemWrapper>
        <div id={`sourceitem-${index}`}>{icon}</div>
        <EntitySourceItemDisplayName>{displayName}</EntitySourceItemDisplayName>
      </EntitySourceItemWrapper>
    </TooltipHost>
  );
};

export default withIntegrationFromIdForSources(EntitySourceItem, (props) => {
  return {
    integrationId: props?.ProviderId,
  };
});
