import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import CreateUserForm from './CreateUserForm';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';
import CluedInRegularText from '../../../core/components/composites/generic/CluedInRegularText';

const HighlightedText = styled.span`
  color: rgb(21, 169, 169);
`;

const SignUpUser = ({ clientId, onSignupUser, isMutating, email }) => (
  <div>
    <CluedInBigTitle>
      <FormattedMessage id="module-public-signUpUser" />
    </CluedInBigTitle>
    <CluedInRegularText>
      <HighlightedText>{clientId}</HighlightedText>
    </CluedInRegularText>
    <CreateUserForm
      email={email}
      isMutating={isMutating}
      onSignupUser={onSignupUser}
    />
  </div>
);

export default SignUpUser;
