import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import { HighlightTextFromContext } from '@cluedin/components';

const GrayishLabel = styled.div`
  font-size: 12px;
  color: #c3c3c3;
  padding-bottom: 4px;
`;

const ValueWrapper = styled.span`
  overflow: hidden;
  overflow-wrap: break-word;
  padding-top: 16px;
`;
const EntityPropertyItem = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralQuaternaryAlt};

  a {
    color: ${({ theme }) => theme.palette.themePrimary};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ExpandedRow = ({ entityProps }) => {
  const theme = useTheme();

  if (entityProps.length === 0) {
    return <EntityPropertyItem>No Value</EntityPropertyItem>;
  }

  return entityProps?.map((property, key) => {
    return (
      <EntityPropertyItem key={key} theme={theme}>
        {property?.vocabularyKey?.vocabularyId ? (
          <Link
            target={'_blank'}
            to={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
              property?.name,
            )}`}
          >
            {property?.vocabularyKey?.name || property?.name}

            {property?.name && (
              <span style={{ marginLeft: '6px' }}>
                <Externallink size={8} />
              </span>
            )}
          </Link>
        ) : (
          <GrayishLabel>
            {property?.vocabularyKey?.name || property?.name}
          </GrayishLabel>
        )}

        <GrayishLabel>
          <HighlightTextFromContext text={property?.name} />
        </GrayishLabel>

        <ValueWrapper>
          {property?.value || (
            <span style={{ color: '#9B9B9C', fontStyle: 'italic' }}>
              No Value
            </span>
          )}
        </ValueWrapper>
      </EntityPropertyItem>
    );
  });
};
