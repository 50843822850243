"use strict";

exports.__esModule = true;
exports.getPlaceholderColor = exports.getControlShadow = exports.getControlHoverBorderColor = exports.getControlBorderColor = exports.getControlBackground = exports.getColorByState = exports.getArrowWrapperBackground = void 0;
var _theme = require("@cluedin/theme");
var lighten = _theme.colorManipulator.lighten;
var getColorByState = function getColorByState(colors, hasError, isDisabled) {
  if (isDisabled) return colors.gray;
  if (hasError) return colors.danger;
  return colors.primary;
};

/*
 *
 */
exports.getColorByState = getColorByState;
var getControlBorderColor = function getControlBorderColor(_ref) {
  var colors = _ref.colors,
    hasError = _ref.hasError,
    isDisabled = _ref.isDisabled,
    isFocused = _ref.isFocused;
  if (isDisabled) return colors.gray;
  if (hasError) return colors.danger;
  if (isFocused) return colors.primary;
  return colors.grayish;
};
exports.getControlBorderColor = getControlBorderColor;
var getControlHoverBorderColor = function getControlHoverBorderColor(colors, hasError, isDisabled) {
  return getColorByState(colors, hasError, isDisabled);
};
exports.getControlHoverBorderColor = getControlHoverBorderColor;
var getControlShadow = function getControlShadow(shadows, hasError, isDisabled) {
  if (isDisabled) return 'none';
  if (hasError) return shadows.default;
  return shadows.default;
};
exports.getControlShadow = getControlShadow;
var getArrowWrapperBackground = function getArrowWrapperBackground(_ref2) {
  var colors = _ref2.colors,
    hasError = _ref2.hasError,
    isDisabled = _ref2.isDisabled,
    noBorder = _ref2.noBorder,
    transparentMode = _ref2.transparentMode;
  if (hasError && noBorder) {
    return lighten(colors.danger, 0.5);
  }
  if (transparentMode) {
    return colors.lightest;
  }
  return getColorByState(colors, hasError, isDisabled);
};
exports.getArrowWrapperBackground = getArrowWrapperBackground;
var getPlaceholderColor = function getPlaceholderColor(colors, hasError, isDisabled, noBorder) {
  if (hasError && noBorder) {
    return colors.dark;
  }
  return isDisabled ? colors.gray : colors.grayish;
};
exports.getPlaceholderColor = getPlaceholderColor;
var getControlBackground = function getControlBackground(colors, isDisabled, noBorder, hasError) {
  if (isDisabled) {
    return colors.grayLight;
  }
  if (hasError && noBorder) {
    return lighten(colors.danger, 0.5);
  }
  return colors.lightest;
};
exports.getControlBackground = getControlBackground;