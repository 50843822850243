import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import AccessManyToMany from '../composites/AccessManyToMany';

import {
  shouldBatchUpdatePersmissions,
  shouldBatchDeletePersmissions,
  showBatchUserPermissionsAction,
  hideBatchUserPermissionsAction,
} from '../../../configuration/actions';

class WithAccessManyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toSelected: [],
      fromSelected: [],
      isRevoke: false,
    };

    this.onGrantAccessHandler = this.onGrantAccessHandler.bind(this);
    this.onRevokeAccessHandler = this.onRevokeAccessHandler.bind(this);
    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.onToSelectedHandler = this.onToSelectedHandler.bind(this);
    this.onAccessClickHandler = this.onAccessClickHandler.bind(this);
  }

  onGrantAccessHandler(values) {
    const { showDialog } = this.props;
    showDialog();
    this.setState({
      fromSelected: values,
      isRevoke: false,
    });
  }

  onRevokeAccessHandler(values) {
    const { showDialog } = this.props;
    showDialog();

    this.setState({
      fromSelected: values,
      isRevoke: true,
    });
  }

  onCloseHandler() {
    const { hideDialog } = this.props;
    hideDialog();

    this.setState({
      fromSelected: [],
    });
  }

  onAccessClickHandler() {
    const { fromSelected, toSelected, isRevoke } = this.state;
    const { onGiveAccess, onRevokeAccess, mapPayload } = this.props;

    if (!toSelected || toSelected.length === 0) {
      this.setState({
        hasNotSelected: true,
      });
      return;
    }

    this.setState({
      hasNotSelected: false,
    });

    if (isRevoke) {
      onRevokeAccess(mapPayload(fromSelected, toSelected));
    } else {
      onGiveAccess(mapPayload(fromSelected, toSelected));
    }
  }

  onToSelectedHandler(values, e, oldValues) {
    if (oldValues) {
      this.setState({
        toSelected: oldValues,
      });
    } else {
      this.setState({
        toSelected: values,
      });
    }
  }

  render() {
    const {
      dialogSize,
      show,
      isSaving,
      invalid,
      Comp,
      fromEntityTypeName,
      SelectionList,
      AccessManyToManyComponent,
      toEntityTypeName,
      MainListProps,
      canGrantAccess,
    } = this.props;
    // TODO: Remove selection for 2 list
    const { isRevoke, fromSelected, hasNotSelected } = this.state;

    return (
      <div>
        {canGrantAccess && (
          <AccessManyToManyComponent
            style={dialogSize}
            isSaving={isSaving}
            invalid={invalid}
            isRevoke={isRevoke}
            show={show}
            title={
              <FormattedMessage
                id={
                  isRevoke
                    ? 'module-core-revokeAccessMany'
                    : 'module-core-selectAccessMany'
                }
                values={{
                  typeName: toEntityTypeName,
                }}
              />
            }
            onClose={this.onCloseHandler}
            selectedIds={fromSelected}
            SelectionList={SelectionList}
            onMainListEntitySelect={this.onToSelectedHandler}
            onAccessClick={this.onAccessClickHandler}
            hasNotSelected={hasNotSelected}
            selectionTitle={
              <FormattedMessage
                id="module-core-currentSelectionTitle"
                values={{
                  numberSelected: fromSelected.length,
                  typeName: fromEntityTypeName,
                }}
                defaultMessage={
                  'You have selected {numberSelected} {typeName}(s).'
                }
              />
            }
            selectionEntityType={fromEntityTypeName}
          />
        )}
        <div style={{ minHeight: '300px' }}>
          <Comp
            grantAccess={this.onGrantAccessHandler}
            revokeAccess={this.onRevokeAccessHandler}
            {...MainListProps}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export const withAccessMany = ({ from, to }, mapPayload) => {
  const mapStateToProps = ({
    configuration: {
      isSavingPermissions,
      invalidBatchPermissions,
      showBatchUserPermissions,
    },
    user: { currentUser },
  }) => ({
    currentUser,
    isSaving: isSavingPermissions,
    invalid: invalidBatchPermissions,
    show: showBatchUserPermissions,
  });

  const MainList = from.List;
  const SelectionList = to.List;
  const convertToSelectionViewModel = from.toSelectionViewModel;
  const withEntities = from.selectionLoader;
  const fromEntityTypeName = from.entityType;
  const toEntityTypeName = to.entityType;
  const ListProps = from.listProps || {};

  const mapDispatchToProps = (dispatch) => ({
    onGiveAccess({ userIds, configurationsIds }) {
      dispatch(
        shouldBatchUpdatePersmissions({
          UserIds: [...userIds],
          ProviderDefinitionIds: [...configurationsIds],
        }),
      );
    },
    onRevokeAccess({ userIds, configurationsIds }) {
      dispatch(
        shouldBatchDeletePersmissions({
          UserIds: [...userIds],
          ProviderDefinitionIds: [...configurationsIds],
        }),
      );
    },
    showDialog() {
      dispatch(showBatchUserPermissionsAction());
    },
    hideDialog() {
      dispatch(hideBatchUserPermissionsAction());
    },
  });

  const EnchangedAccessManyToMany = withEntities(AccessManyToMany, (props) => ({
    selectedEntities: convertToSelectionViewModel(props.entities),
  }));

  const Enhanced = (props) => (
    <WithAccessManyComponent
      Comp={MainList}
      MainListProps={ListProps}
      convertToSelectionViewModel={convertToSelectionViewModel}
      SelectionList={SelectionList}
      fromEntityTypeName={fromEntityTypeName}
      toEntityTypeName={toEntityTypeName}
      AccessManyToManyComponent={EnchangedAccessManyToMany}
      mapPayload={mapPayload}
      {...props}
    />
  );

  return connect(mapStateToProps, mapDispatchToProps)(Enhanced);
};

export default {
  withAccessMany,
};
