import React from 'react';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';

const ShowAdvancedFiltersButton = ({ style, onAllFilterClick }) => (
  <ButtonLink
    style={{ margin: '8px auto', fontSize: '14px', ...style }}
    isFullWidth
    text={
      <span data-test="show-all-filters-link">
        <FormattedMessage
          defaultMessage="Show All filters"
          id="SearchContent.showAllFilters"
        />
      </span>
    }
    onClick={onAllFilterClick}
  />
);

export default ShowAdvancedFiltersButton;
