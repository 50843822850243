import { RACI_LEVELS } from '@cluedin/components';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'integration.configuredintegrations': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"AllConfigurationPage" */
        './components/pages/AllConfigurationPage'
      ),
    ),
  },
];
