"use strict";

exports.__esModule = true;
exports.useIntegrationFilter = exports.useEntityTypeFilter = exports.useCommonFilter = void 0;
var _hooks = require("../../../hooks");
var _useResetPageNo = require("./useResetPageNo");
var setIntegrationFilterValue = function setIntegrationFilterValue(currentValue, setValue, removeValue, resetPageNo) {
  return function (value) {
    if (value === currentValue) {
      return;
    }
    if (value === 'null' || !value) {
      removeValue();
    } else {
      setValue(value);
    }
    resetPageNo();
  };
};
var useCommonFilter = function useCommonFilter(filterName, history, location, pageNoLabel) {
  if (pageNoLabel === void 0) {
    pageNoLabel = 'pageNo';
  }
  var _useQueryString = (0, _hooks.useQueryString)(history, location, filterName),
    currentValue = _useQueryString[0],
    setValue = _useQueryString[1],
    removeValue = _useQueryString[2];
  var resetPageNo = (0, _useResetPageNo.useResetPageNo)(history, location, pageNoLabel);
  return [currentValue, setIntegrationFilterValue(currentValue, setValue, removeValue, resetPageNo)];
};
exports.useCommonFilter = useCommonFilter;
var useEntityTypeFilter = function useEntityTypeFilter(history, location, pageNoLabel) {
  if (pageNoLabel === void 0) {
    pageNoLabel = 'pageNo';
  }
  return useCommonFilter('entityTypeFilter', history, location, pageNoLabel = 'pageNo');
};
exports.useEntityTypeFilter = useEntityTypeFilter;
var useIntegrationFilter = function useIntegrationFilter(history, location, pageNoLabel) {
  if (pageNoLabel === void 0) {
    pageNoLabel = 'pageNo';
  }
  return useCommonFilter('integrationFilter', history, location, pageNoLabel = 'pageNo');
};
exports.useIntegrationFilter = useIntegrationFilter;