import { gql } from '@apollo/client';

export const createCleanProjectMutationString = `
  mutation createCleanProject($project: InputCleanProject, $load: Boolean) {
    preparation {
      createCleanProject(project: $project, load: $load) {
        id
        name
        sourceType
        sourceParameters
        status
        loaded
      }
    }
  }
`;

const getProjectByIdString = `
query getProjectById($id: ID!) {
  preparation {
    cleanProject(id: $id) {
      id
      name
      sourceType
      sourceParameters
      status
      loaded
      archived
      undoPointer
      operations
      reviewers
      sourceType
      isDataSetArchived
      sourceParameters
      columns {
        name
        originalName
        visible
        displayName
      }
      authors {
        id
        username
      }
      filters {
        id
        name
        filter
        enabled
        type
        value
        field
        inverse
        included
      }
    }
  }
}
`;

const getAllProjectByTypeString = `
query cleanProjects($pageSize: Int, $page: Int, $status: String, $reviewers: [String], $annotationProjectId: ID, $dataSetId: ID) {
  preparation {
    cleanProjects(pageSize: $pageSize, page: $page, status: $status, reviewers: $reviewers, annotationProjectId: $annotationProjectId, dataSetId: $dataSetId) {
      id
      createdAt
      name
      sourceType
      sourceParameters
      status
      loaded
      archived
      reviewers
      authors {
        id
        username
      }
    }
  }
}
`;

const getDataFromDataSetString = `
query queryCleanProject($id: ID!, $pageSize: Int, $page: Int, $sort: JSON) {
  preparation {
    queryCleanProject(id: $id, pageSize: $pageSize, page: $page, sort: $sort)
  }
}
`;

const projectRealTimeString = `
subscription cleanProjectStatusChanged($projectId: ID!){
  cleanProjectStatusChanged(projectId: $projectId) {
    id
    name
    status
    undoPointer
    loaded
  }
}
`;

const transformCleanProjectString = `
mutation transformDataSet($id: ID, $operation: OperationInput) {
  preparation {
    transformDataSet(id: $id, operation: $operation)
  }
}`;

const batchTransformDataSetString = `
mutation batchTransformDataSet($id: ID, $operations: [OperationInput]) {
  preparation {
    batchTransformDataSet(id: $id, operations: $operations)
  }
}`;

const previewDataSetString = `
query previewCleanProject($id: ID!, $pageSize: Int, $page: Int) {
  preparation {
    previewCleanProject(id: $id, pageSize: $pageSize, page: $page)
  }
}
`;

const configureCleanProjectMutationStr = `
mutation configureCleanProject($id: ID, $columns: [String], $maxNumber: Int) {
  preparation {
    configureCleanProject(id: $id, columns: $columns, maxNumber: $maxNumber)
  }
}
`;

const getOperationsForProjectStr = `
query cleanProjectOperations($id: ID!) {
  preparation {
    cleanProjectOperations(id: $id)
  }
}
`;
const redoCleanOperationStr = `
mutation redoCleanOperation($id: ID) {
  preparation {
    redoCleanOperation(id: $id)
  }
}
`;

const undoCleanOperationStr = `
mutation undoCleanOperation($id: ID) {
  preparation {
    undoCleanOperation(id: $id)
  }
}
`;

const getAllFiltersForDataSetStr = `
query cleanProjectFilters($id: ID!) {
  preparation {
    cleanProjectFilters(id: $id) {
      id
      name
      filter
      inverse
      enabled
      included
      type
      field
      value
    }
  }
}
`;

const aggregateDataSetStr = `
query aggregateDataSet($id: ID!, $size: Int) {
  preparation {
    aggregateDataSet(id: $id, size: $size)
  }
}
`;

const createFilterForDataSetStr = `
mutation createFilterForDataSet($id: ID, $filter: CleanFilterInput) {
  preparation {
    createFilterForDataSet(id: $id, filter: $filter) {
      id
    }
  }
}
`;
const createAggregationForDataSetStr = `
mutation createAggregationForDataSet($id: ID, $aggregation: CleanAggregationInput) {
  preparation {
    createAggregationForDataSet(id: $id, aggregation: $aggregation) {
      id
    }
  }
}
`;

const editFilterForDataSetStr = `
mutation editFilterForDataSet($id: ID, $filter: CleanFilterInput) {
  preparation {
    editFilterForDataSet(id: $id, filter: $filter)
  }
}
`;
const deleteFilterForDataSetStr = `
mutation deleteFilterForDataSet($id: ID, $filterId: ID) {
  preparation {
    deleteFilterForDataSet(id: $id, filterId: $filterId)
  }
}
`;

const getCollisionClusteringStr = `
query getCollisionClustering($id: ID!, $option: ClusterInputCollision) {
  preparation {
    collisionClusterDataSet(id: $id, option: $option)
  }
}
`;

const getMetricClusteringStr = `
query getMetricClustering($id: ID!, $option: ClusterInputMetrics) {
  preparation {
    metricClusterDataSet(id: $id, option: $option)
  }
}
`;

const commitCleanProjectStr = `
  mutation commitCleanProject($id: ID!) {
    preparation {
      commitCleanProject(id: $id)
    }
  }
`;

const getAllArchivedProjectsStr = `
  query archivedCleanProjects {
    preparation {
      archivedCleanProjects {
        id
        createdAt
        name
        sourceType
        sourceParameters
        status
        loaded
        archived
        reviewers
        authors {
          id
          username
        }
      }
    }
  }
`;

const archiveCleanProjectMutationStr = `
  mutation archiveCleanProject($id: ID!) {
    preparation {
      archiveCleanProject(id: $id)
    }
  }
`;

const unArchiveCleanProjectMutationStr = `
mutation unArchiveCleanProject($id: ID!) {
  preparation {
    unArchiveCleanProject(id: $id)
  }
}
`;

const updateColumnsCleanProjectStr = `
  mutation updateColumnsCleanProject($id: ID, $columns: [String]) {
    preparation {
      updateColumnsCleanProject(id: $id, columns: $columns)
    }
  }
`;
const getCoreVocabStr = `
query schemaVocab($entityType: String) {
  preparation {
    schema(entityType: $entityType) {
      Key
      DisplayName
    }
  }
}
`;

const setColumnsCleanProjectStr = `
mutation setColumnsCleanProject($id: ID!, $columns: [ColumnInfoInput]) {
  preparation {
    setColumnsCleanProject(id: $id, columns: $columns)
  }
}
`;

const setReviewersStr = `
  mutation setReviewers($id: ID!, $userIds: [String]) {
    preparation {
      setReviewers(id: $id, userIds:$userIds)
    }
  }
`;

const reviewProjectStr = `
mutation review($id: ID!) {
  preparation {
    review(id: $id)
  }
}
`;

const reClaimProjectStr = `
mutation reclaim($id: ID!) {
  preparation {
    reclaim(id: $id)
  }
}
`;

export const updateColumnsCleanProject = gql(updateColumnsCleanProjectStr);

export const getAllFiltersForDataSet = gql(getAllFiltersForDataSetStr);
export const aggregateDataSet = gql(aggregateDataSetStr);
export const createFilterForDataSet = gql(createFilterForDataSetStr);
export const createAggregationForDataSet = gql(createAggregationForDataSetStr);
export const editFilterForDataSet = gql(editFilterForDataSetStr);
export const deleteFilterForDataSet = gql(deleteFilterForDataSetStr);

export const redoCleanOperationMutation = gql(redoCleanOperationStr);
export const undoCleanOperationMutation = gql(undoCleanOperationStr);

export const createCleanProjectMutation = gql(createCleanProjectMutationString);

export const getAllProjectByTypeQuery = gql(getAllProjectByTypeString);

export const getProjectByIdQuery = gql(getProjectByIdString);

export const getDataFromDataSetQuery = gql(getDataFromDataSetString);

export const transformCleanProjectMutation = gql(transformCleanProjectString);

export const batchTransformDataSetMutation = gql(batchTransformDataSetString);

export const projectRealTimeSubscription = gql(projectRealTimeString);

export const previewDataSetQuery = gql(previewDataSetString);

export const configureCleanProjectMutation = gql(
  configureCleanProjectMutationStr,
);

export const getOperationsForProject = gql(getOperationsForProjectStr);

export const getCollisionClustering = gql(getCollisionClusteringStr);

export const getMetricClustering = gql(getMetricClusteringStr);

export const commitCleanProjectMutation = gql(commitCleanProjectStr);

export const archiveCleanProjectMutation = gql(archiveCleanProjectMutationStr);

export const getAllArchivedProjects = gql(getAllArchivedProjectsStr);

export const unArchiveCleanProjectMutation = gql(
  unArchiveCleanProjectMutationStr,
);

export const getCoreVocab = gql(getCoreVocabStr);

export const setColumnsCleanProjectMutation = gql(setColumnsCleanProjectStr);

export const setReviewers = gql(setReviewersStr);

export const review = gql(reviewProjectStr);

export const reClaim = gql(reClaimProjectStr);

export const addColumnToCleanProjectMutation = gql`
  mutation addColumnToCleanProject($projectId: ID, $column: InputNewColumn) {
    preparation {
      addColumnToCleanProject(projectId: $projectId, column: $column) {
        name
        displayName
      }
    }
  }
`;

export default {
  getOperationsForProject,
  createCleanProjectMutation,
  getProjectByIdQuery,
  getDataFromDataSetQuery,
  projectRealTimeSubscription,
  previewDataSetQuery,
  configureCleanProjectMutation,
  redoCleanOperationMutation,
  undoCleanOperationMutation,
  getCollisionClustering,
  getMetricClustering,
  transformCleanProjectMutation,
  batchTransformDataSetMutation,
  commitCleanProjectMutation,
  getAllArchivedProjects,
  unArchiveCleanProjectMutation,
  getCoreVocab,
  setColumnsCleanProjectMutation,
  setReviewers,
  review,
  reClaim,
  addColumnToCleanProjectMutation,
};
