import React from 'react';
import { Select } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

const options = [
  {
    key: 'default',
    value: '',
    label: <FormattedMessage id="data-source-choose-database" />,
  },
  {
    value: 'mssql',
    key: 'mssql',
    label: <FormattedMessage id="data-source-microsoft-server" />,
  },
  {
    value: 'mysql',
    key: 'mysql',
    label: <FormattedMessage id="data-source-mysql" />,
  },
  {
    value: 'postgres',
    key: 'postgres',
    label: <FormattedMessage id="data-source-postgres" />,
  },
];

const DataBaseTechnologySelect = ({ value, onChange }) => {
  const handleChange = ({ value }) => onChange(value);
  return (
    <div data-test={'ImportSQLdropDownButton'} style={{ width: '300px' }}>
      <Select
        defaultValue={options[0]}
        value={value}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
};

export default DataBaseTechnologySelect;
