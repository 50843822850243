import React from 'react';
import { connect } from 'react-redux';
import { initMerge, abortMerge } from '../../actions';

const defaultConfig = { min: 2, max: Infinity, sameEntityType: true };
export const withEntityMergeAction = (Comp, config) => {
  const Hocs = (props) => <Comp {...props} />;

  const mapToStateProps = ({ entityMerge: { isMerging } }) => ({
    isMerging,
  });

  const mapDispatchToProps = (dispatch) => ({
    startMerge() {
      dispatch(initMerge(config || defaultConfig));
    },
    abortMerge() {
      dispatch(abortMerge());
    },
  });

  return connect(mapToStateProps, mapDispatchToProps)(Hocs);
};

export default withEntityMergeAction;
