import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import Flex from 'uxi/Layout/Flex';
import { PrimaryButton, CancelButton } from '@cluedin/form';
import { ButtonLink } from '@cluedin/atoms';
import { Delete, Externallink, Merge } from 'uxi/Icons';
import {
  Panel,
  PanelHeader,
  PanelContent,
  PanelFooter,
} from '@cluedin/components';
import { connect } from 'react-redux';

import { updateTarget, abortMerge, deselectEntity } from '../../actions';
import UnstyledLink from '../../../core/components/composites/UnstyledLink';

const styles = {
  wrapper: {
    lineHeight: 1,
  },
  alertInnerBody: {
    padding: '16px 16px 96px 16px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  alertMainContent: {
    width: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    transition: 'height 1s',
  },
  selectedEntitiesWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'column',
  },
  selectedEntitiesItem: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: '8px 0',
    justifyContent: 'flex-start',
    paddingBottom: '8px',
    borderBottom: '1px solid #cecece',
  },
  button: {
    color: '#373737',
    marginLeft: 'auto',
    marginBottom: '1em',
  },
};

const errorToMsgMap = {
  MAX_SELECTION: 'entityMergeErrorMaxSelect',
  ALREADY_MERGING: 'entityMergeAlreadyMergingError', // need a specific case to know how to handle
  MUST_BE_SAME_TYPE: 'entityMergeErrorEntityType',
  FETCHING_ERROR: 'entityMergeErrorFetch',
  REMOVE_ENTITY: 'entityMergeErrorEntityIsNotSelected',
};

const getErrorMsg = (errorCode) => {
  const linkLabel = <FormattedMessage id="entityMergeSupportLinkLabel" />;
  const theLink = <a href="mailto:support@cluedin.com">{linkLabel}</a>;
  const msgId = errorToMsgMap[errorCode];
  if (msgId) {
    return (
      <FormattedMessage
        key={errorCode}
        id={msgId}
        values={{ supportLink: theLink }}
      />
    );
  }
  return (
    <FormattedMessage
      key={errorCode}
      id="entityMergeErrorGeneric"
      values={{ supportLink: theLink }}
    />
  );
};

// eslint-disable-next-line react/prefer-stateless-function
export class EntitesSelectionManager extends Component {
  static propTypes = {
    isMerging: PropTypes.bool,
    onAbortMerge: PropTypes.func,
    selectedEntities: PropTypes.array,
    mergeErrors: PropTypes.array,
    isSelectionValid: PropTypes.bool,
  };

  static defaultProps = {
    isMerging: false,
    onAbortMerge: () => {},
    selectedEntities: [],
    mergeErrors: [],
    isSelectionValid: false,
  };

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { onAbortMerge } = this.props;
    if (onAbortMerge) {
      onAbortMerge();
    }
  }

  renderEntityItem(mergeViewModel) {
    const { removeEntityFromMergeSelection, onUpdateTarget } = this.props;
    return (
      <div key={mergeViewModel.entityId} style={styles.selectedEntitiesItem}>
        <div>
          <strong>{(mergeViewModel && mergeViewModel.name) || '-'}</strong>
        </div>
        <Flex style={{ width: '100%', justifyContent: 'flex-start' }}>
          <ButtonLink
            text="Use as target"
            onClick={() => onUpdateTarget(mergeViewModel.entityId)}
          />
          <UnstyledLink
            to={`/entity/${mergeViewModel.entityId}`}
            target="_blank"
          >
            <Flex style={{ marginLeft: '8px' }}>
              <Flex style={{ marginRight: '4px' }}>
                <FormattedMessage id="module-entityMerge-viewEntity" />
              </Flex>{' '}
              <Externallink size={8} />
            </Flex>
          </UnstyledLink>
          <ButtonLink
            style={{ marginLeft: 'auto' }}
            text=""
            icon={<Delete />}
            onClick={() =>
              removeEntityFromMergeSelection(mergeViewModel.entityId)
            }
          />
        </Flex>
      </div>
    );
  }

  renderTargetEntityItem(mergeViewModel) {
    const { removeEntityFromMergeSelection } = this.props;
    return (
      <div key={mergeViewModel.entityId} style={styles.selectedEntitiesItem}>
        <div>
          <div>
            (
            <FormattedMessage id="module-entityMerge-targetEntity" />
          </div>
          <strong>{(mergeViewModel && mergeViewModel.name) || '-'}</strong>
        </div>
        <Flex style={{ width: '100%', justifyContent: 'flex-start' }}>
          <UnstyledLink
            to={`/entity/${mergeViewModel.entityId}`}
            target="_blank"
          >
            <Flex>
              <Flex style={{ marginRight: '4px' }}>
                <FormattedMessage id="module-entityMerge-viewEntity" />
              </Flex>{' '}
              <Externallink size={8} />
            </Flex>
          </UnstyledLink>

          <ButtonLink
            style={{ marginLeft: 'auto' }}
            text=""
            icon={<Delete />}
            onClick={() =>
              removeEntityFromMergeSelection(mergeViewModel.entityId)
            }
          />
        </Flex>
      </div>
    );
  }

  renderAlertBody() {
    const { selectedEntities, mergeErrors, isSelectionValid } = this.props;
    const errorMsgs = mergeErrors.map((e) => getErrorMsg(e));

    const errorContent = mergeErrors.length ? (
      <Alert type="warning" noIcon showClose>
        {errorMsgs}
      </Alert>
    ) : null;

    return (
      <div style={styles.alertInnerBody}>
        <div key="theContent" style={styles.alertMainContent}>
          {errorContent}
          <h3 style={{ color: 'inherit', minHeight: '56px' }}>
            {selectedEntities.length > 0 ? (
              <FormattedMessage id="module-entityMerge-targetEntity" />
            ) : (
              <FormattedMessage id="module-entityMerge-selectTargetEntity" />
            )}
          </h3>
          <div
            style={{ ...styles.selectedEntitiesWrapper, marginBottom: '32px' }}
          >
            {selectedEntities
              .slice(0, 1)
              .map(this.renderTargetEntityItem.bind(this))}
          </div>
          <h3 style={{ color: 'inherit', minHeight: '56px' }}>
            {isSelectionValid ? ( // eslint-disable-line no-nested-ternary
              <FormattedMessage id="module-entityMerge-entitiesToMergeInTarget" />
            ) : selectedEntities.length < 1 ? (
              ''
            ) : (
              <FormattedMessage id="module-entityMerge-selectEntitiesToMerge" />
            )}
          </h3>
          <div style={styles.selectedEntitiesWrapper}>
            {selectedEntities.slice(1).map(this.renderEntityItem.bind(this))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isMerging, isSelectionValid, onAbortMerge, selectedEntities } =
      this.props;

    if (!isMerging) {
      return null;
    }

    const selectedEntitiesIds = selectedEntities.map(
      ({ entityId }) => entityId,
    );

    const content = isMerging ? this.renderAlertBody() : null;

    return (
      <Panel onClose={onAbortMerge}>
        <PanelHeader
          title={'Merge entities'}
          confirmButtonProps={{ onClick: onAbortMerge }}
        />

        <PanelContent
          style={{ display: 'flex', flexDirection: 'column' }}
          data-test="merge-entities-side-panel"
        >
          {content}
        </PanelContent>

        <PanelFooter>
          <CancelButton onClick={onAbortMerge} style={{ marginRight: '8px' }}>
            <FormattedMessage id="cancel" />
          </CancelButton>

          <PrimaryButton
            disabled={!isSelectionValid}
            key="theButton"
            icon={<Merge />}
            text={<FormattedMessage id="module-entityMerge-reviewMerge" />}
            href={
              isSelectionValid
                ? `/entity/merge/${
                    selectedEntities[0].entityId
                  }?ids=${selectedEntitiesIds.join(',')}`
                : undefined
            }
          />
        </PanelFooter>
      </Panel>
    );
  }
}

const mapToStateProps = ({
  entityMerge: { isMerging, isSelectionValid, selectedEntities, errors },
}) => ({
  isMerging,
  isSelectionValid,
  selectedEntities,
  mergeErrors: errors,
});

const mapDispatchToProps = (dispatch) => ({
  onAbortMerge: () => {
    dispatch(abortMerge());
  },
  onUpdateTarget: (entityId) => {
    dispatch(updateTarget(entityId));
  },
  removeEntityFromMergeSelection: (entityId) => {
    dispatch(deselectEntity({ entityId }));
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(EntitesSelectionManager);
