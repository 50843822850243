import { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { getAllDataSourceSet } from '../../data';

export const withDataSourceSets = (Comp) => (props) => {
  let page = 0;
  const pageSize = 20;
  const [query, setQuery] = useState('');

  return (
    <Query
      query={getAllDataSourceSet}
      fetchPolicy="no-cache"
      variables={{
        pageSize,
        page,
        name: query,
      }}
    >
      {({ error, data = {}, networkStatus, fetchMore }) => {
        const noDataSourceSet =
          (!data || !data.inbound || !data.inbound.dataSourceSets.data) &&
          !error;

        const dataSourceSets =
          (data && data.inbound && data.inbound.dataSourceSets.data) || [];
        const isFetching =
          networkStatus === 1 || // loading
          networkStatus === 2 || // setting varialbes
          networkStatus === 4; // refetch

        const isFetchingMore = networkStatus === 3;

        return (
          <Comp
            {...props}
            noResultMessage="Currently, no data source set created"
            isFetching={isFetching}
            noDataSourceSet={noDataSourceSet}
            isFetchingMore={isFetchingMore}
            errorDataSourceSets={error}
            dataSourceSets={dataSourceSets}
            query={query}
            onQueryChange={(q) => {
              setQuery(q);
            }}
            onLoadMore={() => {
              page += 1;
              fetchMore({
                variables: {
                  pageSize,
                  page,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (
                    !fetchMoreResult.inbound ||
                    !fetchMoreResult.inbound.dataSourceSets ||
                    !fetchMoreResult.inbound.dataSourceSets.data
                  ) {
                    return prev;
                  }

                  return {
                    ...prev,
                    inbound: {
                      dataSourceSets: {
                        ...prev.inbound.dataSourceSets,
                        data: [
                          ...prev.inbound.dataSourceSets.data,
                          ...fetchMoreResult.inbound.dataSourceSets.data,
                        ],
                      },
                    },
                  };
                },
              });
            }}
          />
        );
      }}
    </Query>
  );
};

export default withDataSourceSets;
