import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Link } from 'react-router-dom';
import { Checkmark } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';

const MergeFinishedNotificationStyle = {
  for: {
    display: 'inline-block',
  },
  entityLink: {
    display: 'inline-block',
    width: '230px',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'bottom',
  },
};

/*
{
  "C": "d-4881E7D8-B,0|G,1|D,0|H,2|F,0",
  "M": [{
    "H": "clientHub",
    "M": "MergeFinished",
    "A": ["Merge Finished", "f36bf3fb-c6f3-5270-bb81-ead754ece5b4"]
  }]
}
*/

const MergeFinishedNotification = ({
  index,
  mergeFinishedData,
  onCloseClick,
}) => {
  const entityId = mergeFinishedData.M[0].A[1];

  const title = 'Entity merge succeeded!';
  const linkUrl = `/entity/${entityId}`;

  const message = (
    <div>
      <div style={MergeFinishedNotificationStyle.for}>For&nbsp;</div>
      <Link to={linkUrl}>{entityId}</Link>
    </div>
  );

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={Checkmark}
    />
  );
};

const EnhancedMergeFinishedNotification = compose(
  lifecycle({
    componentDidMount() {
      const { mergeFinishedData } = this.props;
      const entityId = mergeFinishedData.M[0].A[1];
      const title = 'Entity merge succeeded!';
      const message = `For ${entityId}`;
      const linkUrl = `/entity/${entityId}`;

      notify(title, { body: message }, linkUrl);
    },
  }),
)(MergeFinishedNotification);

export default EnhancedMergeFinishedNotification;
