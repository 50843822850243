import React from 'react';
import upperFirst from 'lodash/upperFirst';
import styled from 'styled-components';
import ColorHash from 'color-hash';

const colorHash = new ColorHash();

const findSize = (size = '', customSize) => {
  if (customSize) {
    return customSize;
  }
  if (!size) {
    return '32';
  }

  const lwSize = size.toLocaleLowerCase();

  if (lwSize === 'xs') {
    return '16';
  }

  if (lwSize === 's') {
    return '24';
  }

  if (lwSize === 'l') {
    return '48';
  }

  if (lwSize === 'xl') {
    return '64';
  }

  return '32';
};

const findFontSize = (size = '', customFontSize) => {
  if (customFontSize) {
    return customFontSize;
  }

  if (!size) {
    return '32';
  }

  const lwSize = size.toLocaleLowerCase();

  if (lwSize === 'xs') {
    return '12';
  }

  if (lwSize === 's') {
    return '16';
  }

  if (lwSize === 'l') {
    return '24';
  }

  if (lwSize === 'xl') {
    return '30';
  }

  return '20';
};

const FirstLetterComponentWrapper = styled.div`
  border-radius: 100%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: ${({ size, customSize }) => `${findSize(size, customSize)}px`};
  min-width: ${({ size, customSize }) => `${findSize(size, customSize)}px`};
  height: ${({ size, customSize }) => `${findSize(size, customSize)}px`};
  line-height: ${({ size, customSize }) => `${findSize(size, customSize)}px`};
  font-size: ${({ size, customFontSize }) =>
    `${findFontSize(size, customFontSize)}px`};
`;

const takeFirstLetter = (name = '') => upperFirst(name).charAt(0);

const FirstLetterComponent = ({
  name = '',
  size,
  style,
  customSize,
  customFontSize,
}) => (
  <FirstLetterComponentWrapper
    style={{
      ...(style || {}),
      background: colorHash.hex(takeFirstLetter(name)),
    }}
    size={size}
    customSize={customSize}
    customFontSize={customFontSize}
  >
    {takeFirstLetter(name)}
  </FirstLetterComponentWrapper>
);

export default FirstLetterComponent;
