import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { clientId } from '../../../config';

export const GET_ORGNAME_AVAILABLE = gql`
  query GetIsOrganizationAvailable($id: String!) {
    administration {
      isOrganizationAvailable(id: $id) {
        EmailDomainName
        IsEmailDomainSignupActivated
        SingleSignOn
        clientId
        isAvailable
        loginTheme
      }
    }
  }
`;

export const useOrgNameAvailable = (skip = false) => {
  const { data, loading, error } = useQuery(GET_ORGNAME_AVAILABLE, {
    skip,
    variables: { id: clientId },
  });

  const isOrgAvailable = get(data, 'administration.isOrganizationAvailable');

  return [isOrgAvailable, loading, error];
};
