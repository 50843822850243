import React from 'react';
import Flex from 'uxi/Layout/Flex';
import Drawer from 'uxi/Drawer';

const UserFeedbackContainer = ({ children }) => (
  <Drawer open={true} anchor="top" direction="bottom" inAttr>
    <div style={{ maxWidth: '700px', minWidth: '700px', margin: '0 auto' }}>
      {children}
    </div>
  </Drawer>
);

export default UserFeedbackContainer;
