import { handleActions } from 'redux-actions';
import {
  getAllMeshesActions,
  approveMeshCmdActions,
  rejectMeshCmdActions,
  getMoreMeshesActions,
} from './actions';

const initialState = {
  allMeshes: [],
  isLoadingAllMeshes: true,
  invalidAllMeshes: false,
  actuallyExecuteUpdates: {},
};

export default handleActions(
  {
    [getAllMeshesActions.request]: (state) => ({
      ...state,
      allMeshes: [],
      isLoadingAllMeshes: true,
      invalidAllMeshes: false,
    }),
    [getAllMeshesActions.receive]: (state, { payload }) => ({
      ...state,
      allMeshes: payload,
      isLoadingAllMeshes: false,
      invalidAllMeshes: false,
    }),
    [getMoreMeshesActions.request]: (state) => ({
      ...state,
      isLoadingMore: true,
      invalidLoadMore: false,
    }),
    [getMoreMeshesActions.receive]: (state, { payload }) => ({
      ...state,
      allMeshes: [...(state.allMeshes || []), ...payload],
      isLoadingMore: false,
      invalidLoadMore: false,
    }),
    [getMoreMeshesActions.invalid]: (state) => ({
      ...state,
      isLoadingMore: false,
      invalidLoadMore: true,
    }),
    [getAllMeshesActions.invalid]: (state) => ({
      ...state,
      allMeshes: [],
      isLoadingAllMeshes: false,
      invalidAllMeshes: true,
    }),
    [approveMeshCmdActions.request]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.Id]: {
            isFetching: true,
            invalid: false,
            done: false,
          },
        },
      };
    },
    [approveMeshCmdActions.invalid]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.Id]: {
            isFetching: false,
            invalid: true,
            done: false,
          },
        },
      };
    },
    [approveMeshCmdActions.receive]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.Id]: {
            isFetching: false,
            invalid: false,
            done: true,
          },
        },
      };
    },
    [rejectMeshCmdActions.request]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.meshMsgId]: {
            isFetching: true,
            invalid: false,
            done: false,
          },
        },
      };
    },
    [rejectMeshCmdActions.invalid]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.meshMsgId]: {
            isFetching: false,
            invalid: true,
            done: false,
          },
        },
      };
    },
    [rejectMeshCmdActions.receive]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.meshMsgId]: {
            isFetching: false,
            invalid: false,
            done: true,
          },
        },
      };
    },
  },
  initialState,
);
