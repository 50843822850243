import React from 'react';
import styled from 'styled-components';
import ActionLink from 'uxi/Action/ActionLink';
import { useTheme } from '@cluedin/theme';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';
import UnstyledLink from '../shared/UnstyledLink';

const getActionStyles = (themePrimary) => ({
  color: 'white',
  background: themePrimary,
});

const LinkWrapper = styled.div`
  transition: ${({ theme: { transition } }) => transition.defaultAll};
  filter: brightness(100%);
  & a:hover,
  & a:focus {
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    filter: brightness(130%);
  }
`;

const getCustomLink = (isAction, themePrimary) => (props) =>
  (
    <LinkWrapper>
      <UnstyledLink
        {...props}
        style={{
          ...(isAction ? getActionStyles(themePrimary) : {}),
          display: 'block',
        }}
      />
    </LinkWrapper>
  );

const DashboardExternalLink = ({
  isAction,
  label,
  icon,
  to,
  target,
  className,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <div className={className}>
      <ActionLink
        style={{ borderBottomWidth: 0 }}
        Link={getCustomLink(isAction, themePrimary)}
        to={to || '#'}
        target={target}
        menuDescriptor={{
          displayName: label,
          key: `${label}${to}`,
          icon,
          isPromoted: true,
        }}
      />
    </div>
  );
};

const ExternalLink = () => {
  const Icon = getAppropriateIcon('Externallink');
  return (
    <DashboardExternalLink
      isAction={true}
      label="Go to google"
      to="https://www.google.com"
      icon={<Icon />}
    />
  );
};

export default {
  name: 'ExternalLink',
  type: 'externalLink',
  required: 'dashboard',
  Component: ExternalLink,
  parameters: [
    {
      type: 'string',
      name: 'to',
    },
    {
      type: 'icon',
      name: 'icon',
    },
  ],
};
