import { apiRequestForSARBatch } from '../../helpers/request';
import { apiRequest, rawRequest, toJSON } from '../core/helpers/request';

export const getSAR = (id) => apiRequest('GET', `api/v1/gdprrequest?id=${id}`);

export const getAllSAR = (options) => {
  const page = options ? options.page : 0;
  const take = options ? options.page : 20;

  return apiRequest('GET', `api/v1/gdpr/all?page=${page}&take=${take}`);
};

const tagToArray = (tag) => {
  switch (tag) {
    case 'pending':
      return [1, 2, 3, 4, 5];
    case 'completed':
      return [6];
    case 'new':
      return [1];
    case 'all':
      return [1, 2, 3, 4, 5, 6];
    default:
      return [];
  }
};
export const getFilteredSARs = (filterOptions = {}) => {
  const page = filterOptions.page || 0;
  const take = filterOptions.take || 20;
  const state = tagToArray(filterOptions.status || []);
  const stateUrl = state.reduce(
    (ac, current) => (ac ? `${ac}&states=${current}` : `states=${current}`),
    '',
  );

  const urlWithState = `api/v1/gdprrequeststates?${
    stateUrl ? `${stateUrl}&` : ''
  }page=${page}&take=${take}`;

  return apiRequest('GET', urlWithState);
};

export const createSar = (sar) => {
  const identifiers = {
    ...sar.Identifiers,
  };

  // todo: fix this.
  delete identifiers.idForClient;

  return apiRequest('POST', 'api/v1/gdprrequest', {
    ...sar,
    Identifiers: identifiers,
    IdentifiersString: JSON.stringify(identifiers),
  });
};

export const updateSar = (sar) => apiRequest('PUT', 'api/v1/gdprrequest', sar);

export const deleteSar = (id) =>
  apiRequest('DELETE', `api/v1/gdprrequest?id=${id}`);

export const archiveSar = (id) =>
  apiRequest('POST', `api/v1/gdprarchive?id=${id}`);
/**
 *
 *
 * {
 *  NumberOfDays
 *  PaymentProvider
 *  ReportLogo
 *  ReportText
 *  ReportColor
 *  ReportTrigger
 *  AnonymizationType
 * }
 */
export const updateGDPRConfig = (gdprConfiguration) =>
  apiRequest('PUT', 'api/v1/gdpr/configuration', gdprConfiguration);

export const getGDPRConfig = () =>
  apiRequest('GET', 'api/v1/gdpr/configuration');

export const getGDPRJsonReport = (url) => rawRequest('GET', url);

export const createSARReport = (sar, reportSettings = {}) =>
  apiRequest(
    'POST',
    `api/v1/gdpr/report?sarId=${sar.Id}&id=${sar.EntityId}`,
    reportSettings,
  );

export const regenerateSARReport = (sar, reportSettings = {}) =>
  apiRequest('POST', 'api/v1/gdpr/report', {
    ...reportSettings,
    sarId: sar.Id,
    id: sar.EntityId,
  });

export const sendReport = (sar, email) =>
  apiRequest('POST', `api/v1/gdpr/sendreport?sarId=${sar.Id}&email=${email}`);

export const createSARReportExcludeGuid = (sar, excludedGuids = []) =>
  apiRequest(
    'POST',
    `api/v1/gdpr/report?sarId=${sar.Id}&id=${
      sar.EntityId
    }&excludeGuids=${excludedGuids.join(',')}`,
  );

/**
 * Queued Ones :
 */

export const anonymize = (id) =>
  apiRequest('POST', `api/v1/anon/gdpr?id=${id}`, {
    Action: 'UPDATE',
    Query: `${id}`,
    Transform: [],
    IsQueued: true,
    RemoveFromCluedIn: true,
    DisconnectAccountAndRemoveFromCluedIn: false,
  });

export const removeAllData = (id) =>
  apiRequest('POST', `/api/v1/remove/gdpr?id=${id}`);

export const minimizeUserData = (id) =>
  apiRequest('POST', `api/v1/gdpr/minimize?id=${id}&isQueued=true`);

export const subscribeForUserData = ({ guid, id: sarId }) =>
  apiRequest('POST', `api/v1/gdpr/subscribe?guid=${guid}&sarId=${sarId}`);

export const unSubscribeForUserData = ({ guid, id: sarId }) =>
  apiRequest('POST', `api/v1/gdpr/unsubscribe?guid=${guid}&sarId=${sarId}`);

export const unMinimizeUserData = (id) =>
  apiRequest('POST', `api/v1/gdpr/unminimize?id=${id}`);

export const addFromProcessing = (id) =>
  apiRequest('POST', `api/v1/gdpr/unprocessing?id=${id}`);

export const removeFromProcessing = (id) =>
  apiRequest('POST', `api/v1/gdpr/processing?id=${id}&isQueued=true`);

export const getConsentReport = () => apiRequest('GET', 'api/gdpr/consent');

export const getIdentifiersReport = () =>
  apiRequest('GET', 'api/gdpr/indentifying');

export const getEncryptedReport = () => apiRequest('GET', 'api/gdpr/encrypted');

export const getLocationReport = () => apiRequest('GET', 'api/gdpr/location');

export const getEntityTypesReport = () =>
  apiRequest(
    'GET',
    'api/gdpr/entitytypes?includeShadowEntities=false&includeExternalData=false',
  );

export const escalate = (sarId, entityIdToEscalateTo, reason) => {
  const args = `?sarId=${sarId}&identityId=${entityIdToEscalateTo}&reason=${reason}`;

  // it seems empty response means all good (200 OK)
  return apiRequest('POST', `api/gdprescalate/${args}`);
};

export const getExtendReportTransforms = () =>
  apiRequest('GET', 'api/gdpr/transforms');

export const postExtendReportData = (sarId, transformId, file) =>
  apiRequest(
    'POST',
    `api/gdpr/clue?sarId=${sarId}&transformId=${transformId}`,
    file,
  );

export const batchImportFromFile = (file) =>
  apiRequestForSARBatch('POST', 'api/gdpr/report/batch', file).then((resp) => {
    if (!resp || (resp && resp.length === 0)) {
      const err = new Error(
        'batchImportDataFailure: Empty response from the server. \n No data could be found in the file',
      );
      console.error(err);
      throw err;
    }
    return resp;
  }); // because can upload png
