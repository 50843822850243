import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';
import { TextField } from 'uxi/Input';
import { Flex } from 'uxi/Layout';
import AuthFormItem from '../composites/AuthFormItem';
import AlreadyUsedClientIdList from '../composites/lists/AlreadyUsedClientIdList';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';
import CluedInRegularText from '../../../core/components/composites/generic/CluedInRegularText';
import { url as urlConfig } from '../../../../config';
import AuthFormLabel from '../composites/AuthFormLabel';
import Spacer from '../../../core/components/composites/generic/Spacer';

const SelectClientId = ({
  isFetching,
  clientIds,
  selectId,
  invalidTeamInfo,
  clientIdProposal,
  onClientIdChange,
  isError,
}) => {
  let teamdomainInvalidErrorMessage;
  let teamdomainUnexistingDomainErrorMessage;

  if (isError) {
    teamdomainInvalidErrorMessage = (
      <FormattedMessage
        id="module-auth-invalidUrl"
        defaultMessage="You can only input letters and numbers. You cannot use spaces. There should be a minimum of 2 characters."
      />
    );
  }

  if (invalidTeamInfo) {
    teamdomainUnexistingDomainErrorMessage = (
      <FormattedMessage
        id="module-auth-unexistingTeamDomain"
        defaultMessage="Team domain does not exist"
      />
    );
  }

  return (
    <Spacer
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          selectId(clientIdProposal);
        }
      }}
      padding="stack-m"
    >
      <CluedInBigTitle>
        <div data-test="signin-team">
          <FormattedMessage
            id="module-auth-signinToYourTeam"
            defaultMessage="Sign in to your team"
          />
        </div>
      </CluedInBigTitle>
      <CluedInRegularText>
        <div data-test="signin-team-prompt-text">
          <FormattedMessage
            id="module-auth-findOrgFormExplanation"
            defaultMessage="Enter your team's CluedIn domain."
          />
        </div>
      </CluedInRegularText>
      <AuthFormItem>
        <AuthFormLabel>
          <FormattedMessage
            id="module-auth-teamDomainLabel"
            defaultMessage="Team Domain"
          />
        </AuthFormLabel>
        <Flex>
          <div
            style={{ flex: 1, paddingRight: '6px' }}
            data-test="team-name-field"
          >
            <TextField
              fullWidth
              value={clientIdProposal}
              onChange={(event) => {
                onClientIdChange(event.target.value);
              }}
              autoFocus
              className="test_auth_selectId_clientId_input"
              error={
                teamdomainUnexistingDomainErrorMessage ||
                teamdomainInvalidErrorMessage
              }
            />
          </div>
          <CluedInRegularText>
            {urlConfig.mainCookieDomain.startsWith('.')
              ? urlConfig.mainCookieDomain
              : `.${urlConfig.mainCookieDomain}`}
          </CluedInRegularText>
        </Flex>
      </AuthFormItem>
      <AuthFormItem>
        <PrimaryButton
          isFullWidth
          onClick={() => {
            selectId(clientIdProposal);
          }}
          disabled={isFetching}
          loading={isFetching}
          text={
            <span data-test="continue-button-change-team">
              <FormattedMessage
                id="module-auth-continue"
                defaultMessage="Continue"
              />
            </span>
          }
          className="test_auth_selectId_continue"
        />
      </AuthFormItem>
      <AlreadyUsedClientIdList clientIds={clientIds} onSelect={selectId} />
    </Spacer>
  );
};

export default SelectClientId;
