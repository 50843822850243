"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(MenuItem) {
  return (0, _styledComponents.default)(MenuItem)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  ", ";\n  padding: 0px 8px;\n\n  &.menuItem {\n    width: auto;\n\n    [", "] {\n      min-height: auto;\n    }\n  }\n\n  &.menuItem.dense {\n    ", ";\n    min-height: auto;\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.typography.body1;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.breakpoints.up('sm');
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.typography.body2;
  });
};
exports.default = _default;