"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(SwitchBase) {
  return (0, _styledComponents.default)(SwitchBase)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  padding: 9px;\n  .switchBaseInput {\n    cursor: inherit;\n    position: absolute;\n    opacity: 0;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    margin: 0;\n    padding: 0;\n    z-index: 1;\n  }\n},\n"])));
};
exports.default = _default;