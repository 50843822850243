import PropTypes from 'prop-types';
import Text from 'uxi/Text';
import { Loader, Toggle } from '@cluedin/form';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
} from '@cluedin/list-pattern';
import { Switch } from 'uxi/Input';
import { FormattedMessage } from '@cluedin/locale';

const AdminUserSettingsRolesStyle = {
  rowColumn: {
    width: '80px',
    textAlign: 'center',
  },
};

const AdminUserActiveSettings = ({
  onToggleActive,
  user,
  isFetching,
  currentUser,
}) => {
  const isCheckBoxActive =
    user.Account.UserName !== currentUser.client.UserName;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableRowColumn
            style={{
              padding: '16px',
            }}
          >
            <Text style={{ marginTop: '6px' }} type="heading">
              <FormattedMessage id="module-user-active" />
            </Text>
            <div style={{ marginTop: '6px' }}>
              <Text type="caption">
                {user.Account.UserName === currentUser.client.UserName ? (
                  <FormattedMessage id="module-user-noDeactivateYourself" />
                ) : (
                  <FormattedMessage id="module-user-inactiveUser" />
                )}
              </Text>
            </div>
          </TableRowColumn>
          <TableRowColumn style={AdminUserSettingsRolesStyle.rowColumn}>
            {isFetching ? (
              <div style={{ display: 'flex', paddingLeft: '15px' }}>
                <Loader />
              </div>
            ) : (
              <Toggle
                name="activeSwitch"
                disabled={!isCheckBoxActive}
                checked={user.Account.LockoutEnd === null}
                onChange={onToggleActive}
              />
            )}
          </TableRowColumn>
        </TableRow>
      </TableBody>
    </Table>
  );
};

AdminUserActiveSettings.displayName = 'AdminUserActiveSettings';

AdminUserActiveSettings.propTypes = {
  onToggleActive: PropTypes.func,
  user: PropTypes.object,
  isFetching: PropTypes.bool,
  currentUser: PropTypes.object,
};

AdminUserActiveSettings.defaultProps = {
  onToggleActive: () => {},
  user: {},
  isFetching: false,
  currentUser: {},
};

export default AdminUserActiveSettings;
