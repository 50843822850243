import React, { useEffect } from 'react';

import { useGetEntityCodeNodeInfo } from '../../../hooks/useGetEntityCodeNodeInfo';
import EntityCodePanelContainer from '../EntityCodePanelContainer/EntityCodePanelContainer';

const EntityCodePanelAPIContainer = ({
  entityId,
  nodeDataType,
  setNodeDataType,
  currentNode,
  handleExecuteAction,
}) => {
  const entityCode = encodeURIComponent(currentNode?.subLabel);

  const [
    getEntityCodeNodeInfo,
    {
      data: entityCodePanelData,
      loading: entityCodePanelLoading,
      error: entityCodePanelError,
    },
  ] = useGetEntityCodeNodeInfo();

  useEffect(() => {
    if (entityId && entityCode) {
      getEntityCodeNodeInfo(entityId, entityCode);
    }
  }, [entityId, entityCode]);

  return (
    <>
      {entityCodePanelData && (
        <EntityCodePanelContainer
          entityId={entityId}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          entityCodePanelData={entityCodePanelData}
          entityCodePanelLoading={entityCodePanelLoading}
          entityCodePanelError={entityCodePanelError}
          currentNode={currentNode}
          handleExecuteAction={handleExecuteAction}
        />
      )}
    </>
  );
};

export default EntityCodePanelAPIContainer;
