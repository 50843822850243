"use strict";

exports.__esModule = true;
exports.default = void 0;
function configureRefreshFetch(configuration) {
  var refreshToken = configuration.refreshToken,
    shouldRefreshToken = configuration.shouldRefreshToken,
    fetch = configuration.fetch,
    onRejectToken = configuration.onRejectToken;
  var refreshingTokenPromise = null;
  return function (url, options) {
    if (options === void 0) {
      options = {};
    }
    if (refreshingTokenPromise !== null) {
      return refreshingTokenPromise.then(function () {
        return fetch(url, options);
      })
      // Even if the refreshing fails, do the fetch so we reject with
      // error of that request
      .catch(function () {
        return fetch(url, options);
      });
    }
    return fetch(url, options).then(function (resp) {
      if (shouldRefreshToken(resp)) {
        if (refreshingTokenPromise === null) {
          refreshingTokenPromise = new Promise(function (resolve, reject) {
            refreshToken().then(function (resp) {
              if (!resp) {
                if (onRejectToken) {
                  onRejectToken();
                }
                refreshingTokenPromise = null;
                reject({
                  orignal: {
                    url: url
                  },
                  shouldLogout: true
                });
              } else {
                refreshingTokenPromise = null;
                resolve();
              }
              return resp;
            });
          });
        }
        return refreshingTokenPromise.then(function () {
          return fetch(url, options);
        }).catch(function (e) {
          // If refreshing fails, continue with original error
          throw e;
        });
      } else {
        return resp;
      }
    });
  };
}
var _default = configureRefreshFetch;
exports.default = _default;