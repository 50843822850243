import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { StreamLogo } from '@cluedin/svgs';

const StreamLogoWrapper = styled.div`
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  & svg * {
    fill: ${({ color }) => color} !important;
  }
`;

const StreamIcon = ({ color, size = 14 }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <StreamLogoWrapper color={color ? color : themePrimary}>
      <StreamLogo height={`${size}px`} width={`${size}px`} />
    </StreamLogoWrapper>
  );
};

export default StreamIcon;
