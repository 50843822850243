import React, { Component } from 'react';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';

import IntegrationFilters from '../filters/IntegrationFilters';
import IntegrationList from './IntegrationList';
import { filterPerType } from '../filters/IntegrationTypeFilter';
import { filterPerInstallationType } from '../filters/IntegrationInstalattionTypeFilters';
import EmptyIntegrationList from './EmptyIntegrationList';
import { escapeRegex } from '../../../../core/helpers/string';

class ConfigurationListWithFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      type: props.integrationType || '',
      installationType: '',
    };
  }

  onInstallationTypeChangeHandler(event, value) {
    this.setState({
      installationType: value,
    });
  }

  onTypeChangeHandler(event, value) {
    this.setState({
      type: value,
    });
  }

  onSearchChangeHandler(value) {
    this.setState({
      query: value,
    });
  }

  getFilteredConfigurations() {
    const { integrations } = this.props;
    const { query, type, installationType } = this.state;
    const configurationfilteredByName = integrations.filter((c) =>
      c.Name.match(new RegExp(escapeRegex(query), 'i')),
    );

    const integrationFilteredPerType = filterPerType(
      configurationfilteredByName,
      type,
    );

    return filterPerInstallationType(
      integrationFilteredPerType || [],
      installationType,
    ).sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
  }

  render() {
    const {
      integrations,
      onInviteClick,
      onAddClick,
      integrationType,
      hideFilters,
      withInviteButton,
    } = this.props;
    const { query } = this.state;

    const filteredIntegrations = this.getFilteredConfigurations();

    const noConfiguration =
      integrations && integrations.length > 0 ? (
        <Alert>
          <FormattedMessage id="module-integration-noIntegrationFound" />
        </Alert>
      ) : (
        <EmptyIntegrationList />
      );

    return (
      <>
        {hideFilters ? null : (
          <IntegrationFilters
            query={query}
            configurations={integrations}
            integrationType={integrationType}
            onTypeChange={this.onTypeChangeHandler.bind(this)}
            onSearchChange={this.onSearchChangeHandler.bind(this)}
            onInstallationChange={this.onInstallationTypeChangeHandler.bind(
              this,
            )}
          />
        )}

        {integrations &&
        integrations.length > 0 &&
        filteredIntegrations &&
        filteredIntegrations.length > 0 ? (
          <IntegrationList
            query={query}
            withInviteButton={withInviteButton}
            onInviteClick={onInviteClick}
            onAddClick={onAddClick}
            integrations={filteredIntegrations}
          />
        ) : (
          noConfiguration
        )}
      </>
    );
  }
}

export default ConfigurationListWithFilters;
