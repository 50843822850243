import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Circle } from 'uxi/Icons';
import { pulseName } from './pulseKeyframes';

const AvatarWithNamePlaceholderUI = styled.div`
  width: ${({ width }) => width};
  height: ${({ lineHeight }) => lineHeight};
  display: flex;
  align-items: center;
  opacity: 0.1;
  animation: ${pulseName} 1s linear infinite;
`;

const AvatarWithNamePlaceholder = ({
  style = {},
  width = '100px',
  color = 'rgb(47, 47, 50)',
  height = '14px',
}) => (
  <AvatarWithNamePlaceholderUI style={style} width={width} height={height}>
    <Circle style={{ marginRight: '6px' }} />
    <div
      style={{
        width: '100%',
        height: `${height}`,
        display: 'inline-block',
        background: `${color}`,
      }}
    />
  </AvatarWithNamePlaceholderUI>
);
AvatarWithNamePlaceholder.defaultProps = {
  style: {},
  width: '100px',
  height: '14px',
  color: 'rgb(47, 47, 50)',
};

AvatarWithNamePlaceholder.propTypes = {
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default AvatarWithNamePlaceholder;
