import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import { Consent } from 'uxi/Icons';
import { useOrganizationFeatureFlagOption } from '../featureFlag/hooks/useOrganizationFeatureFlag';

const { governance } = actionsConfiguration;

export default {
  pillar: 'governance',
  displayName: <FormattedMessage id="consent" />,
  path: '/consent',
  featureFlag: 'Consent',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Consent,
        name: <FormattedMessage id="consent" />,
        link: '/admin/governance/consent',
        claims: governance.consent.forms.view,
      },
    ],
  },
  routes,
};
