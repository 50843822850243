import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

const EntityTileEntityTypeTooltip = ({
  isConfigured,
  entityType,
  children,
}) => {
  const content = isConfigured ? (
    <div>{entityType}</div>
  ) : (
    <div style={{ padding: '4px 0' }}>
      Entity type <strong>{entityType}</strong> not configured.
      <br />
      <Link
        target="_blank"
        to={`/admin/management/entity-types/edit?entityType${entityType}`}
      >
        Configure entity type
      </Link>
    </div>
  );

  return (
    <Tooltip placement="top" destroyTooltipOnHide overlay={content}>
      {children}
    </Tooltip>
  );
};

export default EntityTileEntityTypeTooltip;
