import styled from 'styled-components';
import { pure } from 'recompose';

const AuthFormLabel = styled.div`
  font-size: 13px;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.5);
`;

export default pure(AuthFormLabel);
