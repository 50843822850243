import { gql, useQuery } from '@apollo/client';
import { SavedSearch } from './useSavedSearch';

export const SHARED_SAVED_SEARCHES = gql`
  query getSharedSavedSearches($viewName: String, $page: Int) {
    administration {
      getSharedSavedSearches(viewName: $viewName, page: $page) {
        total
        searches {
          filterUrl
          id
          name
          viewMode
          columnsConfig
          createdBy
          created
          shared
        }
      }
    }
  }
`;

export type GetSharedSavedSearchesResponse = {
  administration: {
    getSharedSavedSearches: {
      total: number;
      searches: SavedSearch[];
    };
  };
};

type Options = {
  page: number;
  viewName: string;
};

export const useQuerySharedSavedSearches = ({ viewName, page }: Options) => {
  const fetchPolicy = 'network-only';

  const { data, loading, refetch } = useQuery<
    GetSharedSavedSearchesResponse,
    Options
  >(SHARED_SAVED_SEARCHES, {
    fetchPolicy,
    variables: {
      page,
      viewName,
    },
  });

  const sharedSavedSearchesRes = data?.administration?.getSharedSavedSearches;

  return {
    refetchSharedSavedSearches: refetch,
    loadingSharedSavedSearches: loading,
    totalsSharedSavedSearches: sharedSavedSearchesRes?.total,
    sharedSavedSearches: sharedSavedSearchesRes?.searches || [],
  };
};
