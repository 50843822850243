import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/edit/:id',
    claims: {
      'management.glossary': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GlossaryTermDetailPage" */
        './components/pages/GlossaryTermDetailPage'
      ),
    ),
  },
  {
    path: '/',
    claims: {
      'management.glossary': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GlossaryListPage" */
        './components/pages/GlossaryListPage'
      ),
    ),
  },
];
