import React from 'react';
import { List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';

import { columns } from './columns';
import { GroupedEntitiesPanelListText } from './GroupedEntitiesPanelList.styles';

const GroupedEntitiesPanelList = ({
  totalDataCount,
  data,
  loading,
  error,
  labels = {},
  groupedEntitiesPanelFetchMore,
}) => {
  const loadMoreIsDisabled = data?.length === totalDataCount;

  if (!data) {
    return (
      <div style={{ maxWidth: '100%' }}>
        <div style={{ marginBottom: '20px' }}>
          <GroupedEntitiesPanelListText>
            {labels?.noDataListHeading}
          </GroupedEntitiesPanelListText>
          {labels?.listText}
        </div>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '100%', marginBottom: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        <GroupedEntitiesPanelListText>
          {labels?.listHeading}
        </GroupedEntitiesPanelListText>
        {labels?.listText}
      </div>

      <List
        offsetTop={1}
        hideExpanding
        noSearch
        selectable={false}
        columns={columns}
        data={data}
        loading={loading}
        error={error}
        showPagination={true}
        notFoundProps={{
          message: labels?.notFoundMessage,
          explanation: labels?.notFoundExplanation,
          noCreate: true,
        }}
        listFooterAdditionalRightContent={
          <PrimaryButton
            loading={loading}
            data-test="entityRelations-groupedEntities-loadMoreButton"
            disabled={loadMoreIsDisabled}
            color="accent"
            onClick={groupedEntitiesPanelFetchMore}
            rounded
            text={
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListLoadMoreButton" />
            }
          />
        }
      />
    </div>
  );
};

export default GroupedEntitiesPanelList;
