/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

const TabStyle = {
  liMainStyle: {
    margin: 0,
    padding: '15px 30px',
    display: 'inline-block',
    cursor: 'pointer',
    color: '#37373a',
    fontSize: '16px',
    background: '#fff',
  },
  borderFirst: {
    //borderTop: '1px solid #D4DAD1',
    //borderBottom: '1px solid #D4DAD1',
    borderRight: '1px solid #D4DAD1',
  },
  border: {
    borderRight: '1px solid #D4DAD1',
  },
  li: {
    margin: 0,
    padding: '15px 30px',
    display: 'inline-block',
    cursor: 'pointer',
  },
  liSelected: {
    background: '#fff',
  },
  liMainStyleSelected: {
    color: '#000000',
    background: '#F3F3F2',
  },
};

class Tab extends Component {
  static displayName = 'Tab';

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    isMainStyle: PropTypes.bool,
    isFirst: PropTypes.bool,
    focus: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    panelId: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  };

  static defaultProps = {
    className: '',
    isMainStyle: false,
    disabled: false,
    isFirst: false,
    focus: false,
    selected: false,
    id: null,
    panelId: null,
  };

  componentDidMount() {
    this.checkFocus();
  }

  componentDidUpdate() {
    this.checkFocus();
  }

  checkFocus() {
    if (this.props.selected && this.props.focus) {
      findDOMNode(this).focus(); // eslint-disable-line
    }
  }

  render() {
    const {
      style,
      selected,
      disabled,
      panelId,
      className,
      children,
      id,
      isMainStyle,
      isFirst,
      // just cleaning the attribute from those two props :
      activeTabClassName, // eslint-disable-line react/prop-types
      disabledTabClassName, // eslint-disable-line react/prop-types
      ...attributes
    } = this.props;

    let mergedStyle = Object.assign(
      style || {},
      TabStyle.li,
      isFirst ? TabStyle.borderFirst : TabStyle.border,
    );

    if (isMainStyle) {
      mergedStyle = Object.assign(
        style || {},
        TabStyle.liMainStyle,
        isFirst ? TabStyle.borderFirst : TabStyle.border,
      );
    }

    delete attributes.focus;

    if (selected) {
      if (!isMainStyle) {
        mergedStyle = Object.assign(
          style || {},
          mergedStyle,
          TabStyle.liSelected,
          isFirst ? TabStyle.borderFirst : TabStyle.border,
        );
      } else {
        mergedStyle = Object.assign(
          style || {},
          mergedStyle,
          TabStyle.liMainStyleSelected,
          isFirst ? TabStyle.borderFirst : TabStyle.border,
        );
      }
    }

    return (
      <li
        {...attributes}
        style={mergedStyle}
        className={className}
        role="tab"
        id={id}
        aria-selected={selected ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-controls={panelId}
        tabIndex={selected ? '0' : null}
      >
        {children}
      </li>
    );
  }
}

export default Tab;
