import styled from 'styled-components';
import ColumnSelectionListNoColumns from './ColumnSelectionListNoColumns';

const ColumnSelectionListHeader = styled.div`
  padding: 16px 4px 16px 16px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
`;

const ColumnSelectionList = ({
  header,
  children,
  numberSelected,
  maxNumberColumns,
  noDefault,
  emptyMessage,
}) => {
  return (
    <>
      <ColumnSelectionListHeader>{header}</ColumnSelectionListHeader>
      {numberSelected === 0 && (
        <ColumnSelectionListNoColumns
          emptyMessage={emptyMessage}
          noDefault={noDefault}
          maxNumberColumns={maxNumberColumns}
        />
      )}
      {children}
    </>
  );
};

export default ColumnSelectionList;
