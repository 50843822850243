import get from 'lodash/get';
import { usePaging } from './usePaging';
import { getPage } from '../helpers/pager';

export const useInMemoryPaging = (
  data,
  history,
  location,
  pageSize,
  pagingId,
) => {
  const [, { selectedPage, itemsPerPage }, setSelectedPage] = usePaging(
    history,
    location,
    pageSize,
    pagingId,
  );

  const pagedData = getPage(data, selectedPage, itemsPerPage);
  const totalItems = get(data, 'length', 0);

  return [pagedData, totalItems, itemsPerPage, selectedPage, setSelectedPage];
};
