import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';

const GET_DATASET = gql`
  query getDataSetById($id: ID!) {
    inbound {
      dataSet(id: $id) {
        id
        name
        touched
        createdAt
        hasError
        latestErrorMessage
        errorType
        elasticTotal
        expectedTotal
        lastPurgedAt
        lastPurgedBy
        isBridge
        isArchive
        hasQuarantineData
        archivedBy
        fileSize
        currentLoadedSize
        hasValidationParsingError
        failedLoadingTotal
        hasFailureInLoading
        archivedByUser {
          id
          username
        }
        lastPurgedByUser {
          id
          username
        }
        author {
          id
          username
        }
        annotationId
        annotation {
          originEntityCodeKey
          annotationProperties {
            key
          }
        }
        dataSourceId
        canBeDeleted
        configuration
        dataSource {
          id
          name
          type
          stopped
          crashedDuringUpload
          dataSourceSet {
            id
            name
          }
        }
        originalFields
        stats {
          total
          successful
          failed
          bytes
        }
        fieldMappings {
          originalField
          key
          id
          edges {
            edgeType
            dataSetId
            dataSourceId
            dataSourceGroupId
            entityType
          }
        }
      }
    }
  }
`;

export const useQueryDataSetDetail = (id) => {
  const { data, refetch, loading, error } = useQuery(GET_DATASET, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const detail = get(data, 'inbound.dataSet', {});

  return [detail, loading, error, refetch];
};
