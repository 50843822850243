import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import styled from 'styled-components';

const AuthFormItemWrapper = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const AuthFormItem = ({ style = {}, children }) => (
  <AuthFormItemWrapper style={style}>{children}</AuthFormItemWrapper>
);

AuthFormItem.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

AuthFormItem.defaultProps = {
  style: {},
};

export default pure(AuthFormItem);
