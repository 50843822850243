import React from 'react';
import RedirectContainer from '../containers/RedirectContainer';

export const onlyNotAuth = (Comp, token) => (props) =>
  (
    <RedirectContainer shouldRedirect={token} url="/" {...props}>
      <Comp {...props} />
    </RedirectContainer>
  );

export default {
  onlyNotAuth,
};
