import IntegrationWrapperPage from './components/pages/IntegrationWrapperPage';
import AllConfigurationPage from './components/pages/AllConfigurationPage';
import DashboardPage from './components/pages/DashboardPage';
import ConfigurationRoutes from '../configuration/route';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import IntegrationManagementPage from './components/pages/IntegrationManagementPage';
import NewConfigurationPage from './components/pages/NewConfigurationPage';
import EditConfigurationPage from './components/pages/EditConfigurationPage';
import OauthCallbackPage from './components/pages/OauthCallbackPage';
import { RACI_LEVELS } from '@cluedin/components';

export const links = {
  index: 'integrations',
  configuration: 'configuration/:id',
  newIntegration: 'new/:id',
  newOauth: 'oauth/:id',
  callback: 'callback/:name',
  editConfiguration: 'edit/:id/:configurationId',
};

export const getLink = (name, id, configurationId = '') => {
  const result = `/admin/${links.index}`;
  if (name === 'index') {
    return result;
  }

  return `${result}/${links[name]
    .replace(':id', id)
    .replace(':configurationId', configurationId)}`;
};

export const ManagementRoutes = [
  {
    path: '/integrations',
    component: OnlyClientIdSubDomain(IntegrationManagementPage),
    routes: [
      {
        path: '/callback/:name',
        component: OauthCallbackPage,
      },
      {
        path: '/new/:integrationId',
        component: NewConfigurationPage,
      },
      {
        path: '/oauth/:integrationId',
        component: NewConfigurationPage,
      },
      {
        path: '/edit/:integrationId/:configurationId',
        component: EditConfigurationPage,
      },
    ],
  },
];

export default [
  {
    path: '/',
    exact: true,
    component: DashboardPage,
    claims: {
      'integration.availableintegrations': RACI_LEVELS.INFORMED,
    },
  },
  {
    path: '/applications',
    component: AllConfigurationPage,
    claims: {
      'integration.availableintegrations': RACI_LEVELS.INFORMED,
    },
  },
  //...ConfigurationRoutes,
];
