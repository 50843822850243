import React, { useContext } from 'react';
import { HighlightTextContext } from '@cluedin/components';
import { Link } from 'react-router-dom';
import EntityTypeAvatar from './EntityTypeAvatar';

const EntityTypeAvatarConfigurationLink = ({
  entityConfig = {},
  onlyLabel,
  style = {},
}) => {
  const query = useContext(HighlightTextContext);

  return (
    <Link
      style={style}
      className="test_Administration_EntityTypes_Row"
      to={`/admin/management/entity-types/edit?entityType=${entityConfig.entityType}`}
      data-test={`entity-name-${entityConfig.entityType}`}
    >
      <EntityTypeAvatar
        entityConfig={entityConfig}
        entityType={entityConfig.entityType}
        query={query || entityConfig.query}
        onlyLabel={onlyLabel}
      />
    </Link>
  );
};

export default EntityTypeAvatarConfigurationLink;
