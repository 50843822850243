import { connect } from 'react-redux';
import AdminUserActiveSettings from '../composites/AdminUserActiveSettings';
import { shouldBlock, shouldUnBlock } from '../../actions';

const mapToStateProps = (
  { user: { blockUsers, unBlockUsers } },
  { user, currentUser },
) => {
  const isFetchingUnBlock = unBlockUsers[user.Account.UserName]
    ? unBlockUsers[user.Account.UserName].isFetching
    : false;
  const isFetchingBlock = blockUsers[user.Account.UserName]
    ? blockUsers[user.Account.UserName].isFetching
    : false;

  return {
    currentUser,
    isFetching: isFetchingBlock || isFetchingUnBlock,
  };
};

const mapToDispatchProps = (dispatch, { user }) => ({
  onToggleActive: () => {
    if (user.Account.LockoutEnd !== null) {
      dispatch(shouldUnBlock(user.Account.UserName));
    } else {
      dispatch(shouldBlock(user.Account.UserName));
    }
  },
});

export default connect(
  mapToStateProps,
  mapToDispatchProps,
)(AdminUserActiveSettings);
