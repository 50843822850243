/* eslint-disable no-return-assign */
export const colors = [
  '#5688b3',
  '#63cfba',
  '#345084',
  '#1d3c4f',
  '#00897a',
  '#61acbb',
  '#5688b3',
  '#1C4146',
  '#58A8A3',
  '#06412c',
];

export const colorMaker = (function colorsMaker() {
  let i = 0;

  return () => colors[i + 1 >= 10 ? (i = 0) : (i += 1)]; // eslint-disable-line no-plusplus
})();

export const orderedColorMaker = (index) => {
  const actualIndex = index.toString().match(/\d$/);
  let theColor = '#000000';
  try {
    theColor = colors[actualIndex];
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    return theColor;
  }
  return theColor; // eslint-disable-line no-plusplus
};

export default {
  colors,
  colorMaker,
  orderedColorMaker,
};
