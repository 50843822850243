import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

import ServerStatusView from '../TopMenu/ServerStatus/ServerStatusView';
import { ApiGatewayDown } from '../TopMenu/ServerStatus/ApiGatewayDown';
import { ReactComponent as ErrorIllustration } from './Seymour-illustration.svg';

const DataErrorPage = styled.div`
  min-height: calc(100hv - 48px);
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 24px;
  overflow: auto;
`;

const ServerError = styled.div`
  min-width: 780px;
  max-width: 780px;
  text-align: center;
`;

const ServerErrorTitle = styled.div`
  margin-top: 24px;
  color: black;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
`;
const ServerErrorText = styled.div`
  font-size: 16px;
  color: #9b9b9c;
`;

const CoreDataError = ({
  statuses,
  statusLoading,
  statusError,
  globalStatus,
}) => {
  const theme = useTheme();

  return (
    <>
      <DataErrorPage>
        <ServerError>
          <ErrorIllustration />
          <ServerErrorTitle>
            <FormattedMessage id="module-core-serverErrorOccured" />
          </ServerErrorTitle>
          <ServerErrorText>
            <FormattedHTMLMessage id="module-core-serverErrorOccuredText" />
          </ServerErrorText>
          {!statusError && !statusLoading && (
            <ServerStatusView
              theme={theme}
              statuses={statuses}
              statusLoading={statusLoading}
              statusError={statusError}
              globalStatus={globalStatus}
              hideAlert
              contentStyle={{
                border: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
                marginTop: '24px',
              }}
            />
          )}

          {statusError && !statusLoading && (
            <div style={{ padding: '16px 150px' }}>
              <ApiGatewayDown />
            </div>
          )}
        </ServerError>
      </DataErrorPage>
    </>
  );
};

export default CoreDataError;
