import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'uxi/Badge';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { connect } from 'react-redux';
import EntityNameWithLogo from '../../../entity/components/composites/EntityNameWithLogo';
import { Ellipsis } from '@cluedin/components';
const UserWithName = ({ entity, currentUser, query = '' }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const isYou =
    entity && currentUser.client.UserName === entity.Account.UserName;
  const isEmailedConfirmed = true || entity.EmailConfirmed;

  return (
    <Ellipsis>
      <Link
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          color: themePrimary,
          fontSize: '14px',
        }}
        to={`/admin/settings/users/settings/${entity.Account.Id}`}
      >
        <EntityNameWithLogo
          src={entity.Entity ? entity.Entity.logoUrl : ''}
          name={
            entity.Entity?.name ? entity.Entity.name : entity.Account.UserName
          }
          query={query}
        />
      </Link>
      {isYou && (
        <Badge
          type="info"
          style={{ margin: '0 0 0 10px', display: 'inline-block' }}
        >
          <FormattedMessage id="module-user-thisIsYou" />
        </Badge>
      )}
      {!isEmailedConfirmed && (
        <Badge
          type="info"
          style={{ margin: '0 0 0 10px', display: 'inline-block' }}
        >
          <FormattedMessage id="module-user-waitingEmailConfig" />
        </Badge>
      )}
    </Ellipsis>
  );
};

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps)(UserWithName);
