import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { EntityExplainLog } from './fragments';

const CREATE_EXPLAIN_LOG_PER_ENTITY_ID = gql`
  mutation createExplainLogPerEntityId($entityId: ID!) {
    virtualization {
      createExplainLog(entityId: $entityId) {
        ...EntityExplainLog
      }
    }
  }
  ${EntityExplainLog.fragments.entityExplainLog}
`;

export const useCreateExplainLogPerEntityId = (entityId) => {
  const [createExplainLog, { data, loading, error }] = useMutation(
    CREATE_EXPLAIN_LOG_PER_ENTITY_ID,
  );

  const explainLog = get(data, 'virtualization.createExplainLog');

  return [
    () => {
      createExplainLog({
        variables: {
          entityId,
        },
      });
    },
    { data: explainLog, loading, error },
  ];
};
