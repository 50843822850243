import React from 'react';
import PropTypes from 'prop-types';
import { DivPadding } from 'uxi/Base';
import { Flex } from 'uxi/Layout';
import { injectIntl } from '@cluedin/locale';
import { ListToolbar } from '@cluedin/list-pattern';

import UserInviteButtonContainer from '../../../../userModule/components/container/UserInviteButtonContainer';

const UserFilters = ({ onSearchChange, hideInvite, query, intl }) => (
  <DivPadding padding="S" style={{ display: 'flex' }}>
    <Flex
      style={{ flex: 1, flexFlow: 'row wrap', justifyContent: 'flex-start' }}
    >
      {!hideInvite && (
        <div style={{ marginRight: '16px' }}>
          <UserInviteButtonContainer />
        </div>
      )}

      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <div className="test_Administration_User_SearchForm">
          <ListToolbar
            style={{ padding: 0 }}
            onChangeSearch={onSearchChange}
            onSubmitSearch={onSearchChange}
            searchName={query || ''}
            searchInputPlaceholder={intl.formatMessage({
              id: 'module-user-searchForUser',
            })}
          />
        </div>
      </div>
    </Flex>
  </DivPadding>
);

UserFilters.displayName = 'UserFilters';

UserFilters.propTypes = {
  onSearchChange: PropTypes.func,
  query: PropTypes.string,
};

UserFilters.defaultProps = {
  onSearchChange: () => {},
  query: '',
};

export default injectIntl(UserFilters);
