"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
//transition: theme.transitions.create('box-shadow'),
var _default = function _default(Card) {
  return (0, _styledComponents.default)(Card)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background-color: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  .rounded {\n    border-radius: ", ";\n  }\n  .outlined {\n    border: 1px solid ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.background.card;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.text.primay;
  }, function (_ref3) {
    var theme = _ref3.theme,
      elevation = _ref3.elevation;
    return theme.shadows[elevation || 'none'];
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.shape.radius;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.colors.divider;
  });
};
exports.default = _default;