import { handleActions } from 'redux-actions';
import { take } from 'lodash';

import {
  notificationActions,
  markAllAsRedActions,
  markAsRedActions,
  shouldMarkAsReadNotification,
  nextNotificationPageActions,
  crawlerNotificationResetActions,
  upgradePlanResetActions,
  newUserNotificationActions,
  failedAuthenticationNotificationActions,
  meshQueueMessageNotificationActions,
  meshMessageNotificationActions,
  mergeFinishedNotificationActions,
  openNotificationCenter,
  closeNotificationCenter,
  shouldAddNotif,
  shouldClearNotification,
} from './actions';

export const initialState = {
  isFetchingNotifications: true,
  isFetchingNotificationsInvalid: false,
  notifications: [],
  notificationsPageNumber: 0,
  isFetchingNextPage: false,
  isFetchingMarkAllAsRead: false,

  isFetchingMarkAsRead: false,
  markAllAsRedInvalid: false,

  showLoadMore: false,
  crawlerFinishedNotifications: [],
  upgradePlanNotifications: [],
  newUserNotifications: [],
  failedAuthenticationNotifications: [],
  meshAPIQueuedMessageNotification: [],
  meshAPIMessageNotification: [],
  mergeFinishedNotification: [],
  newProviderNotifications: [],
  newConfigurationNotifications: [],
  newNotif: [],
};

export default handleActions(
  {
    [openNotificationCenter]: (state) => ({
      ...state,
      isOpenNotificationCenter: true,
      newNotif: [],
    }),
    [closeNotificationCenter]: (state) => ({
      ...state,
      isOpenNotificationCenter: false,
    }),
    [notificationActions.request]: (state) => ({
      ...state,
      isFetchingNotifications: true,
    }),
    [notificationActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingNotificationsInvalid: false,
      isFetchingNotifications: false,
      notifications: payload.result,
      notificationsPageNumber: state.notificationsPageNumber + 1,
      showLoadMore: payload.length === 10,
    }),
    [notificationActions.invalid]: (state) => ({
      ...state,
      isFetchingNotifications: false,
      isFetchingNotificationsInvalid: true,
    }),

    [markAsRedActions.request]: (state, { payload: Id }) => {
      const newNotificationWithMarkAsRead = state.notifications;

      newNotificationWithMarkAsRead.forEach((n) => {
        const notification = n;
        if (Id === notification.Id) {
          notification.Processed = true;
        }
      });

      return {
        ...state,
        isFetchingMarkAsRead: true,
        markAllAsRedInvalid: false,
        notifications: [...newNotificationWithMarkAsRead],
      };
    },
    [markAsRedActions.receive]: (state) => ({
      ...state,
      isFetchingMarkAsRead: false,
      markAllAsRedInvalid: false,
    }),
    [markAsRedActions.invalid]: (state, { payload }) => ({
      ...state,
      isFetchingMarkAsRead: false,
      markAllAsRedInvalid: payload || true,
    }),

    [shouldMarkAsReadNotification]: (state, { payload }) => {
      const newNotificationWithMarkAsRead = state.notifications;
      newNotificationWithMarkAsRead.forEach((n) => {
        const notification = n;
        if (payload.notificationId === notification.Id) {
          notification.Processed = true;
        }
      });

      return {
        ...state,
        isFetchingMarkAsRead: true,
        notifications: [...newNotificationWithMarkAsRead],
      };
    },

    [markAllAsRedActions.request]: (state) => ({
      ...state,
      isFetchingMarkAllAsRead: true,
    }),
    [markAllAsRedActions.receive]: (state) => {
      const newNotification = state.notifications;

      newNotification.forEach((n) => {
        const notification = n;
        notification.Processed = true;
      });

      return {
        ...state,
        isFetchingMarkAllAsRead: false,
        notifications: [...newNotification],
      };
    },
    [markAllAsRedActions.invalid]: (state) => ({
      ...state,
      isFetchingMarkAllAsRead: false,
    }),

    [nextNotificationPageActions.request]: (state) => ({
      ...state,
      isFetchingNextPage: true,
    }),
    [nextNotificationPageActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingNextPage: false,
      notificationsPageNumber: state.notificationsPageNumber + 1,
      notifications: [...state.notifications, ...payload.result],
      showLoadMore: payload.result.length === 10,
    }),
    [nextNotificationPageActions.invalid]: (state) => ({
      ...state,
      isFetchingNextPage: false,
    }),

    [crawlerNotificationResetActions.receive]: (state, { payload }) => ({
      ...state,
      crawlerFinishedNotifications: [
        ...state.crawlerFinishedNotifications,
        {
          message: payload.result.message,
          providerName: payload.result.providerName,
          show: true,
        },
      ],
    }),
    [crawlerNotificationResetActions.reset]: (state, { payload }) => {
      const resetCrawlerFinishedNotifications =
        state.crawlerFinishedNotifications.filter(
          (n) => n.providerName === payload.result.providerName,
        );

      resetCrawlerFinishedNotifications.forEach((n) => {
        const finishedNotif = n;
        finishedNotif.show = false;
      });

      return {
        ...state,
        crawlerFinishedNotifications: [...state.crawlerFinishedNotifications],
      };
    },

    [upgradePlanResetActions.receive]: (state, { payload }) => ({
      ...state,
      upgradePlanNotifications: [
        ...state.upgradePlanNotifications,
        {
          plan: payload.result.plan,
          count: payload.result.count,
          show: true,
        },
      ],
    }),
    [upgradePlanResetActions.reset]: (state, { payload }) => {
      const resetUpgradePlanNotifications =
        state.upgradePlanNotifications.filter(
          (n) => n.plan === payload.result.plan,
        );

      resetUpgradePlanNotifications.forEach((n) => {
        const nPlanNotification = n;
        nPlanNotification.show = false;
      });

      return {
        ...state,
        upgradePlanNotifications: [...state.upgradePlanNotifications],
      };
    },

    [newUserNotificationActions.receive]: (state, { payload }) => ({
      ...state,
      newUserNotifications: [
        ...state.newUserNotifications,
        {
          show: true,
          userName: payload.result.userName,
          email: payload.result.email,
          id: payload.result.id,
        },
      ],
    }),
    [newUserNotificationActions.reset]: (state, { payload }) => {
      const resetNewUserNotifications = state.newUserNotifications.filter(
        (n) => n.userName === payload.result.userName,
      );

      resetNewUserNotifications.forEach((n) => {
        const nUserNotification = n;
        nUserNotification.show = false;
      });

      return {
        ...state,
        newUserNotifications: [...state.newUserNotifications],
      };
    },

    [failedAuthenticationNotificationActions.receive]: (
      state,
      { payload },
    ) => ({
      ...state,
      failedAuthenticationNotifications: [
        ...state.failedAuthenticationNotifications,
        {
          show: true,
          accountId: payload.result.accountId,
          providerName: payload.result.providerName,
          providerId: payload.result.providerId,
          count: payload.result.count,
        },
      ],
    }),
    [failedAuthenticationNotificationActions.reset]: (state, { payload }) => {
      const resetFailedAuthNotification =
        state.failedAuthenticationNotifications.filter(
          (n) => n.providerId === payload.result.providerId,
        );

      resetFailedAuthNotification.forEach((n) => {
        const nFiledAuthNotif = n;
        nFiledAuthNotif.show = false;
      });

      return Object.assign({}, state, {
        failedAuthenticationNotifications: [
          ...state.failedAuthenticationNotifications,
        ],
      });
    },

    [meshQueueMessageNotificationActions.receive]: (state, { payload }) => ({
      ...state,
      meshAPIQueuedMessageNotification: [
        ...state.meshAPIQueuedMessageNotification,
        {
          ...payload.result,
          show: true,
        },
      ],
    }),
    [meshQueueMessageNotificationActions.reset]: (state, { payload }) => {
      /* eslint-disable max-len */
      const { notification } = payload;
      const resetMeshAPIQueuedMessageNotification =
        state.meshAPIQueuedMessageNotification.map((currentNotif) => {
          if (
            currentNotif.EntityId === notification.EntityId &&
            currentNotif.QueryStructure.PropertyDiffs.PropertyName ===
              notification.QueryStructure.PropertyDiffs.PropertyName &&
            currentNotif.QueryStructure.PropertyDiffs.OldValue ===
              notification.QueryStructure.PropertyDiffs.OldValue &&
            currentNotif.QueryStructure.PropertyDiffs.NewValue ===
              notification.QueryStructure.PropertyDiffs.NewValue
          ) {
            currentNotif.show = false; // eslint-disable-line no-param-reassign
            return currentNotif;
          }
          return currentNotif;
        });

      return {
        ...state,
        meshAPIQueuedMessageNotification: [
          ...resetMeshAPIQueuedMessageNotification,
        ],
      };
    },

    [meshMessageNotificationActions.receive]: (state, { payload }) => ({
      ...state,
      meshAPIMessageNotification: [
        ...state.meshAPIMessageNotification,
        {
          ...payload.result,
          show: true,
        },
      ],
    }),
    [meshMessageNotificationActions.reset]: (state) => ({
      ...state,
      meshAPIMessageNotification: [],
    }),

    [mergeFinishedNotificationActions.receive]: (state, { payload }) => ({
      ...state,
      mergeFinishedNotification: [
        ...state.mergeFinishedNotification,
        {
          ...payload.result,
          show: true,
        },
      ],
    }),
    [mergeFinishedNotificationActions.reset]: (state, { payload }) => {
      const { notification } = payload;
      const { mergeFinishedNotification } = state;

      const newMergeFinishedNotification = mergeFinishedNotification.map(
        (n) => {
          if (n.M[0].A[1] === notification.M[0].A[1]) {
            return {
              ...n,
              show: false,
            };
          }
          return n;
        },
      );

      return {
        ...state,
        mergeFinishedNotification: newMergeFinishedNotification,
      };
    },
    [shouldAddNotif]: (state, { payload }) => ({
      ...state,
      newNotif: take([...state.newNotif, payload], 3),
    }),
    [shouldClearNotification]: (state, { payload }) => ({
      ...state,
      newNotif: take(
        [...(state.newNotif || []).filter((n) => n.id !== payload)],
        3,
      ),
    }),
  },
  initialState,
);
