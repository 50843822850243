import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import {
  shouldFetchUnapprovedConfigurations,
  shouldFetchAllActiveConfigurations,
  shouldFetchInactiveConfigurations,
} from '../../actions';
import PageLoader from '../../../core/components/composites/PageLoader';

const removeDeleted = (c) => !c.isDeleted;

const enhanced = compose(
  lifecycle({
    componentDidMount() {
      const { fetchProviders } = this.props;
      fetchProviders();
    },
  }),
  branch((props) => props.isFetching, renderComponent(PageLoader)),
);

export const withAllConfigurations = (Comp, mapToStateProps) => {
  const EnchangedComp = enhanced(Comp);

  const mapToSelectProps = (
    {
      configuration: {
        allActiveConfigurations,
        isFetchingAllActiveConfigurations,
        isFetchingUnapprovedConfiguration,
        allUnapprovedConfigurations,
        allInactiveConfigurations,
        isFetchingAllInactiveConfigurations,
      },
    },
    { selectedIds, filter },
  ) => {
    const selected = selectedIds || [];
    const configurations = [
      ...(allActiveConfigurations.filter(removeDeleted) || []),
      ...(allInactiveConfigurations.filter(removeDeleted) || []),
      ...(allUnapprovedConfigurations.filter(removeDeleted) || []),
    ];

    let filteredConfigurations = configurations;

    if (selected && selected.length > 0) {
      filteredConfigurations = configurations.filter((configuration) => {
        return selected.find((s) => {
          return configuration.Id === s;
        });
      });
    }

    const entities = filter
      ? (filteredConfigurations || []).filter(filter)
      : filteredConfigurations || [];

    const result = {
      entities,
      configurations: entities,
      isFetching:
        isFetchingAllActiveConfigurations ||
        !allActiveConfigurations ||
        isFetchingAllInactiveConfigurations ||
        !allInactiveConfigurations ||
        !allUnapprovedConfigurations ||
        isFetchingUnapprovedConfiguration,
    };

    return mapToStateProps ? mapToStateProps(result) : result;
  };

  const mapDispatchToProps = (dispatch) => ({
    fetchProviders: () => {
      dispatch(shouldFetchAllActiveConfigurations());
      dispatch(shouldFetchInactiveConfigurations());
      dispatch(shouldFetchUnapprovedConfigurations());
    },
    onConfigurationClick: (data) => {
      dispatch(
        window.open({
          type: 'configuration',
          data,
        }),
      );
    },
  });

  return connect(mapToSelectProps, mapDispatchToProps)(EnchangedComp);
};

export default withAllConfigurations;
