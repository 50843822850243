import React from 'react';
import styled from 'styled-components';
import FilterGroupItemButton from './FilterGroupItemButton';

const iconSize = 16;

const FilterGroupItemIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const FilterGroupItemName = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
`;

const FilterGroupItem = ({ onFocusIn, filter, onClick }) => (
  <FilterGroupItemButton
    onFocusIn={onFocusIn}
    onClick={() => {
      onClick(filter);
    }}
  >
    {filter.icon && React.isValidElement(filter.icon) && (
      <FilterGroupItemIcon>
        {React.cloneElement(filter.icon, { size: `${iconSize}` })}
      </FilterGroupItemIcon>
    )}
    <FilterGroupItemName data-test={`filter-group-item-${filter.displayName}`}>
      {filter.displayName}
    </FilterGroupItemName>
  </FilterGroupItemButton>
);

FilterGroupItem.displayName = 'FilterGroupItem';

export default FilterGroupItem;
