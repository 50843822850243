import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Integration } from 'uxi/Icons';
import Toast from '../../../../core/components/composites/Toast';
import { withIntegrationConfig } from '../../../../integration/components/hocs/withIntegrationConfig';

const NewConfigurationNotificationStyle = {
  logo: {
    width: '24px',
  },
};

const noop = () => {};

const NewConfigurationNotification = ({
  index,
  providerNotification,
  integrationConfig,
  onCloseClick,
}) => {
  const userName = providerNotification.userName;
  const title = (
    <FormattedMessage id="NewConfigurationNotification.ConfigUpdatedTitle" />
  );

  const message = (
    <FormattedMessage
      id="NewConfigurationNotification.ConfigUpdatedMessage"
      values={{ userName, providerName: integrationConfig.Name }}
    />
  );

  let providerIcon;

  if (integrationConfig) {
    providerIcon = (
      <img
        style={NewConfigurationNotificationStyle.logo}
        alt={integrationConfig.Name}
        src={integrationConfig.Icon}
      />
    );
  } else {
    providerIcon = <Integration />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick || noop}
      title={title}
      message={message}
      customIcon={providerIcon}
    />
  );
};

export default withIntegrationConfig(NewConfigurationNotification, (props) => {
  return {
    integrationName: props.providerName,
  };
});
