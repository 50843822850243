import React from 'react';
import { Flex } from 'uxi/Layout';
import { compose } from 'recompose';
import { actionsConfiguration, withClaimsDisabled } from '@cluedin/components';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { Add, Code, Process } from 'uxi/Icons';
import {
  AvailableIntegrations,
  ImportFromDatabaseIcon,
  ImportFromFileIcon,
  ImportFromIngestionPointIcon,
  Tile,
  ViewAllDataSources,
  ManualDataEntryIcon,
} from '@cluedin/atoms';

import { hasFeature } from '../../../../config';
import { withImportFileWitoutDataSet } from '../../../dataSourceV2/hocs/withImportFile';
import DashboardTitle from '../../../core/components/composites/dashboard/DashboardTitle';
import { withAddIngestionPointWithoutDataSet } from '../../../dataSourceV2/hocs/withAddIngestionPoint';
import { withImportFromDatabaseWitoutDataSet } from '../../../dataSourceV2/hocs/withImportFromDatabase';
import { withManualClueCreation } from '../../../manualDataEntry/hooks/withManualClueCreation';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';

const { integrations } = actionsConfiguration;
const DashboardTile = withClaimsDisabled(Tile);

const DashboardTileActions = ({
  openImportFromFile,
  openImportFromDataBase,
  openImportFromIngestionPoint,
  openManualClueCreation,
  history,
}) => {
  const hasImportDatabase = useOrganizationFeatureFlagOption('ImportDatabase');
  const hasManualDataEntry =
    useOrganizationFeatureFlagOption('manualDataEntry');

  return (
    <>
      <DashboardTitle data-test="integrations-title">
        <FormattedMessage id="inbound" />
      </DashboardTitle>

      <Flex
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
        }}
      >
        {hasFeature('datasource') && (
          <>
            <div
              className="test_Integration_Dashboard_dataSources_add_file"
              data-test="import-from-file-tile"
            >
              <DashboardTile
                Icon={ImportFromFileIcon}
                onClick={openImportFromFile}
                actionConfiguration={integrations.dataSources.dataSet.create}
                displayName={
                  <FormattedHTMLMessage id="module-integration-importFromFile" />
                }
              />
            </div>

            {hasImportDatabase && (
              <div
                className="test_Integration_Dashboard_dataSources_add_file"
                data-test="import-from-database-tile"
              >
                <DashboardTile
                  Icon={ImportFromDatabaseIcon}
                  onClick={openImportFromDataBase}
                  actionConfiguration={integrations.dataSources.dataSet.create}
                  displayName={
                    <FormattedHTMLMessage id="module-integration-importFromDB" />
                  }
                />
              </div>
            )}

            {hasFeature('endpoint') && (
              <div
                className="test_Integration_Dashboard_dataSources_add_ingestion"
                data-test="import-from-endpoint-tile"
              >
                <DashboardTile
                  Icon={ImportFromIngestionPointIcon}
                  onClick={openImportFromIngestionPoint}
                  actionConfiguration={integrations.dataSources.dataSet.create}
                  displayName={
                    <FormattedHTMLMessage id="module-integration-importFromEndpoint" />
                  }
                />
              </div>
            )}
          </>
        )}
        {hasManualDataEntry && (
          <div className="test_Integration_Dashboard_dataSources_add_ingestion">
            <DashboardTile
              Icon={ManualDataEntryIcon}
              onClick={openManualClueCreation}
              actionConfiguration={
                integrations.manualDataEntryInput.manualDataEntryInput.create
              }
              displayName={
                <FormattedHTMLMessage id="module-manualDataEntry-displayName" />
              }
            />
          </div>
        )}
        <div
          className="test_Integration_Dashboard_ConfiguredIntegrations"
          data-test="import-from-connector-tile"
        >
          <DashboardTile
            Icon={Add}
            onClick={() => {
              history.push('/admin/inbound/integration/applications');
            }}
            actionConfiguration={
              integrations.configuredIntegrations.configuredIntegration.view
            }
            displayName={
              <FormattedMessage
                id="module-home-importDataFromConnctor"
                defaultMessage="Configured Integrations"
              />
            }
          />
        </div>

        <div
          className="test_Integration_Dashboard_ConfiguredIntegrations"
          data-test="build-integration-tile"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://documentation.cluedin.net/integration/build-integration"
          >
            <DashboardTile
              Icon={Code}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.view
              }
              displayName={
                <>
                  <FormattedHTMLMessage
                    id="module-home-buildIntegration"
                    defaultMessage="Build<br/>Integration"
                  />
                </>
              }
            />
          </a>
        </div>

        <div
          className="test_Integration_Dashboard_ConfiguredIntegrations"
          data-test="install-integration-tile"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://documentation.cluedin.net/integration/install-integrations"
          >
            <DashboardTile
              Icon={Process}
              actionConfiguration={
                integrations.configuredIntegrations.configuredIntegration.view
              }
              displayName={
                <FormattedMessage id="module-home-installIntegration" />
              }
            />
          </a>
        </div>

        <div
          className="test_Integration_Dashboard_AvailableIntegrations"
          data-test="available-integrations-tile"
        >
          <DashboardTile
            type="secondary"
            Icon={AvailableIntegrations}
            onClick={() => {
              history.push('/admin/inbound/integration/applications');
            }}
            actionConfiguration={
              integrations.availableIntegrations.availableIntegration.view
            }
            displayName={
              <FormattedMessage
                id="module-integration-ViewAllApplications"
                defaultMessage="Available Integrations"
              />
            }
          />
        </div>

        {hasFeature('datasource') && (
          <div
            className="test_Integration_Dashboard_dataSources"
            data-test="view-datasources-tile"
          >
            <DashboardTile
              type="secondary"
              Icon={ViewAllDataSources}
              onClick={() => {
                history.push('/admin/inbound/datasourceset');
              }}
              actionConfiguration={
                integrations.dataSources.dataSourceGroup.view
              }
              displayName={
                <FormattedHTMLMessage id="module-integration-viewAllDatasources" />
              }
            />
          </div>
        )}
        {hasManualDataEntry && (
          <div
            className="test_Integration_Dashboard_manualDataEntryProjects"
            data-test="view-manualDataEntryProjects-tile"
          >
            <DashboardTile
              type="secondary"
              Icon={ViewAllDataSources}
              onClick={() => {
                history.push('/admin/inbound/manual-data-entry');
              }}
              actionConfiguration={
                integrations.manualDataEntryProjectManagement
                  .manualDataEntryProject.view
              }
              displayName={
                <FormattedHTMLMessage id="module-integration-viewAllManualDataEntryProject" />
              }
            />
          </div>
        )}
      </Flex>
    </>
  );
};

export default compose(
  withImportFileWitoutDataSet,
  withImportFromDatabaseWitoutDataSet,
  withAddIngestionPointWithoutDataSet,
  withManualClueCreation,
)(DashboardTileActions);
