import { FormattedMessage } from '@cluedin/locale';
import { Select } from '@cluedin/form';

const defaultOptions = [
  {
    displayName: (
      <FormattedMessage id="module-goldenRecordList-sortRelevance" />
    ),
    value: 'relevance',
  },
  {
    displayName: <FormattedMessage id="module-goldenRecordList-sortByNew" />,
    value: 'new',
  },
  {
    displayName: <FormattedMessage id="module-goldenRecordList-sortByOld" />,
    value: 'old',
  },
];

const SearchRelevanceFilters = ({ sortType, onSortChange }) => {
  const options = defaultOptions.map(({ value, displayName }) => ({
    value: value,
    label: <span style={{ fontSize: '14px' }}>{displayName}</span>,
  }));

  return (
    <div style={{ width: '216px', maxWidth: '216px' }}>
      <Select
        defaultValue={{ value: sortType || 'relevance' }}
        value={sortType}
        options={options}
        onChange={({ value }) => {
          onSortChange(value);
        }}
      />
    </div>
  );
};

export default SearchRelevanceFilters;
