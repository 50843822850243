import React from 'react';
import { Spinner } from '@cluedin/form';
import { Checkmark } from 'uxi/Icons';
import PageLoader from '../../../core/components/composites/PageLoader';
import { useTheme } from '@cluedin/theme';

const styles = {
  loaderPadding: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
  },
  ul: {
    display: 'block',
    padding: '15px',
    listStyle: 'none',
  },
  pad: {
    padding: '7px 7px 0 7px',
  },
};

const ConfigurationLoadingStatus = ({ messages, small }) => {
  const theme = useTheme();
  const lis = (messages || []).map((m, i, a) => (
    <li style={styles.pad} key={i}>
      <div style={{ display: 'flex' }}>
        {i === a.length - 1 ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div>
            <Checkmark style={{ fill: theme?.palette?.themePrimary }} />
          </div>
        )}
        <div style={styles.pad}>{`   ${m}`}</div>
      </div>
    </li>
  ));

  const messageList = lis.length && (
    <div style={styles.loaderPadding}>
      <ul style={styles.ul}>{lis}</ul>
    </div>
  );

  return (
    <div style={{ position: 'relative', minHeight: '100px' }}>
      {messageList ||
        (small ? (
          <div style={styles.loaderPadding}>
            <Spinner />
          </div>
        ) : (
          <PageLoader />
        ))}
    </div>
  );
};

export default ConfigurationLoadingStatus;
