import { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getGroupedOptions, Select } from '@cluedin/form';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import usePrevious from '../../../core/hooks/usePrevious';
import { getClassicValidationMessage } from '../../../core/validation/messages';

import EntityTypeAvatar from './EntityTypeAvatar';

const itemAdapterFunc = (i) => {
  const group = i?.entityType?.split('/')[1] ?? '';

  const item = {
    group,
    ...i,
    value: i?.entityType,
    subLabel: i?.entityType,
    label: i?.displayName || i?.entityType,
  };

  return {
    ...item,
    rawIcon: item.icon,
    icon: (
      <EntityTypeAvatar
        entityConfig={item}
        AvatarWithNameSize={20}
        style={{ width: '18px' }}
        entityType={i?.entityType}
      />
    ),
    'data-test': `${i?.displayName}-entityTypeSelector`,
  };
};

const getEntityCodeForValue = (value) => {
  const trimSpaces = value ? value.trim() : '';
  const removeTooMuchWhiteSpace = trimSpaces.replace(/  +/g, ' ');

  const hasSlash = removeTooMuchWhiteSpace[0] === '/' ? true : false;
  const entityTypeWitouthSlash = hasSlash
    ? removeTooMuchWhiteSpace
    : `/${removeTooMuchWhiteSpace}`;

  return entityTypeWitouthSlash.replaceAll(' ', '/');
};

export const EntityTypeSelector = ({
  data,
  value,
  onChange,
  disabled,
  readOnly,
  loadMore,
  isLoading,
  setTempVal,
  placeholder,
  setSearchName,
  hasError = false,
  formatCreateLabel,
  setIsNewEntityType,
  isClearable = true,
  isCreatable = true,
  keepEntityTypeAsDisplayName,
}) => {
  const { formatMessage } = useIntl();
  const hasAccessToCreate = useUserHasAccess(
    actionsConfiguration.admin.entityTypes.entityType.create.claims,
  );

  const prevData = usePrevious(data);

  const persistData = useMemo(() => {
    if (data?.length > 0) {
      return data;
    }

    return prevData;
  }, [isLoading, data]);

  const options = useMemo(
    () =>
      getGroupedOptions({
        itemAdapterFunc,
        data: persistData,
        sortByKey: 'group',
        groupByKey: 'group',
      }),
    [persistData],
  );

  const onChangeEntity = useCallback((v) => {
    setIsNewEntityType?.(false);

    setTempVal?.(undefined);

    onChange?.({
      new: false,
      icon: v?.rawIcon ?? '',
      entityType: v?.entityType,
      displayName: v?.displayName,
    });
  }, []);

  const handleCreateNewEntity = useCallback((v) => {
    setIsNewEntityType?.(true);

    const newValueHasError = getClassicValidationMessage(
      v.indexOf('/') == 0 ? v.substring(1) : v,
      100,
    );

    if (newValueHasError) {
      return setTempVal?.({
        icon: '',
        new: true,
        displayName: keepEntityTypeAsDisplayName
          ? getEntityCodeForValue(v)
          : v.replaceAll('/', ' '),
        entityType: getEntityCodeForValue(v),
      });
    }

    setTempVal?.(undefined);

    onChange?.({
      icon: '',
      new: true,
      displayName: keepEntityTypeAsDisplayName
        ? getEntityCodeForValue(v)
        : v.replaceAll('/', ' '),
      entityType: getEntityCodeForValue(v),
    });
  }, []);

  const onInputChange = (v) => {
    setSearchName(v);
  };

  return (
    <>
      <Select
        isGrouped
        isSearchable
        value={value}
        options={options}
        hasError={hasError}
        loadMore={loadMore}
        disabled={disabled}
        readOnly={readOnly}
        isLoading={isLoading}
        isClearable={isClearable}
        onChange={onChangeEntity}
        placeholder={placeholder}
        onInputChange={onInputChange}
        onCreateOption={handleCreateNewEntity}
        formatCreateLabel={formatCreateLabel}
        isCreatable={isCreatable && hasAccessToCreate}
      />

      {!hasAccessToCreate && options?.length === 0 && (
        <div style={{ marginTop: 46, color: '#F2B600' }}>
          {formatMessage({ id: 'module-entityType-no-access-to-create' })}
        </div>
      )}
    </>
  );
};
