import { apiRequest } from '../core/helpers/request';

// admin.datamanagement
export const submitMergedEntitesDelta = (ids, targetId, delta) =>
  apiRequest(
    'POST',
    `api/entity/mergeEntities?ids=${ids.split(',')}&targetId=${targetId}`,
    delta,
  );

export default {
  submitMergedEntitesDelta,
};
