import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Panel, PanelContent, PanelFooter, CancelButton } from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';
import { Help } from 'uxi/Icons';
import { closeHelpCenter, openCenter } from '../../../../../help/actions';
import { TopNavButton } from '../TopNavButton';

import HelpCenterContainer from '../../../../../help/components/containers/HelpCenterContainer';

const ProviderStatusPanel = memo(
  injectIntl(({ isOpen, onClose, intl }) => {
    return (
      <Panel
        open={isOpen}
        overlapHeader={true}
        panelType={'medium'}
        withNativeClose={true}
        withCustomHeader={true}
        onClose={onClose}
      >
        <PanelContent style={{ padding: 0 }}>
          <HelpCenterContainer />
        </PanelContent>

        <PanelFooter>
          <CancelButton
            rounded
            onClick={onClose}
            text={intl.formatMessage({ id: 'cancel' })}
          />
        </PanelFooter>
      </Panel>
    );
  }),
);

const HelpCenterCaller = memo(
  injectIntl(({ open, theme, isOpen, onClose, openCenter }) => {
    return (
      <>
        <ProviderStatusPanel isOpen={isOpen} onClose={onClose} />
        <TopNavButton onClick={openCenter} isActive={open}>
          <Help size={20} color={theme.palette.white} />
        </TopNavButton>
      </>
    );
  }),
);

const mapToStateProps = ({ help: { isOpenHelpCenter, selected } }) => ({
  isOpen: isOpenHelpCenter,
  selected,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeHelpCenter());
  },
  openCenter: () => {
    dispatch(openCenter());
  },
});

const ReduxedHelpCenterCaller = connect(
  mapToStateProps,
  mapDispatchToProps,
)(HelpCenterCaller);

export const HelpCenter = ReduxedHelpCenterCaller;
