import React from 'react';
import PropTypes from 'prop-types';

import {
  ExplainLogDetailBox,
  ExplainLogDataWrapper,
  ExplainLogDetailWrapper,
} from './ExplainLogDetail.styles';
import ExplainLogSingleOperation from '../ExplainLogSingleOperation/ExplainLogSingleOperation';
import ExplainLogSingleEvent from '../ExplainLogSingleEvent/ExplainLogSingleEvent';

const ExplainLogDetail = ({ data, isOperation }) => {
  return (
    <ExplainLogDetailBox>
      <ExplainLogDetailWrapper>
        <ExplainLogDataWrapper>
          {/* FOR NESTED OPERATION EVENTS */}
          {isOperation &&
            data.operation.events.length > 0 &&
            (data.operation.events || []).map((item, index) => {
              const isOperation = item.kind === 'Operation';

              return (
                <ExplainLogDetail
                  key={index}
                  data={item}
                  isOperation={isOperation}
                />
              );
            })}

          {/* FOR SINGLE OPERATIONS */}
          {isOperation && data.operation.events.length <= 0 && (
            <ExplainLogSingleOperation data={data} />
          )}

          {/* FOR SINGLE EVENTS */}
          {!isOperation && <ExplainLogSingleEvent data={data} />}
        </ExplainLogDataWrapper>
      </ExplainLogDetailWrapper>
    </ExplainLogDetailBox>
  );
};

export default ExplainLogDetail;

ExplainLogDetail.propTypes = {
  data: PropTypes.object,
  isOperation: PropTypes.bool,
};

ExplainLogDetail.defaultProps = {
  data: {},
  isOperation: false,
};
