import storage from 'store';
import { createAction } from 'redux-actions';
import { showSuccess } from 'uxi-business/userMessage/actions';
import { FormattedMessage } from '@cluedin/locale';
import cogoToast from 'cogo-toast';

import {
  unauthorized,
  unauthorizedWithResponseStatus,
} from '../../action/generic';
import {
  getConfigurationById,
  getAllActiveConfigurations,
  getUserPermissionsByConfigurationId,
  updateConfigurationPermissionFromUser,
  updateConfigurationPermission,
  getUnapprovedConfiguration,
  getInactiveProviders,
  play,
  pause,
  reject,
  approve,
  reAuthProvider,
  setProductOwner,
  fetchProductOwner,
  removeProductOwner,
  reProcessConfiguration,
  deleteConfigurationPermission,
  grantConfigurationPermission,
} from './data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
} from '../../action/helpers/actionCreator';
import {
  shouldRemoveConfigurationFromPermission,
  shouldAddConfigurationFromPermission,
} from '../user/actions';
import { withCache, shouldShowAlert } from '../core/actions';

export const fetchAllActiveConfigurationsAction = createAsyncAction(
  'CONFIGURATION_ALL_CONFIGURATIONS',
);
export const shouldFetchAllActiveConfigurations = withCache(
  'configuration',
  'allActiveConfigurations',
  'isFetchingAllActiveConfigurations',
)(
  defaultAsyncCallWithActions(
    getAllActiveConfigurations,
    fetchAllActiveConfigurationsAction,
  ),
);
export const forceFetchAllActiveConfigurations = defaultAsyncCallWithActions(
  getAllActiveConfigurations,
  fetchAllActiveConfigurationsAction,
);

export const fetchConfigurationByIdAction = createAsyncAction(
  'CONFIGURATION_CONFIGURATION_BY_ID',
);
export const shouldFetchConfigurationById = defaultAsyncCallWithActions(
  getConfigurationById,
  fetchConfigurationByIdAction,
);

export const fetchUserPermissionsByConfigurationIdAction = createAsyncAction(
  'CONFIGURATION_PERSMISSION_BY_CONFIGURATION_ID',
);

export const shouldFetchUserPermissionsByConfigurationId =
  defaultAsyncCallWithActions(
    getUserPermissionsByConfigurationId,
    fetchUserPermissionsByConfigurationIdAction,
  );

export const updateConfigurationPermissionActionFromUser = createAsyncAction(
  'CONFIGURATION_UPDATE_PERMISSIONS',
);

export const shouldUpdateConfigurationPermissionFromUser =
  ({ userId, configurationId }) =>
  (dispatch) => {
    dispatch(
      updateConfigurationPermissionActionFromUser.request({
        userId,
        configurationId,
      }),
    );

    getUserPermissionsByConfigurationId(configurationId).then(
      (allUsersIdForConfiguration) => {
        const hasAccess = allUsersIdForConfiguration.userId.find(
          (userIdFromServer) => userIdFromServer === userId,
        );

        if (hasAccess) {
          return deleteConfigurationPermission({
            UserIds: [userId],
            ProviderDefinitionIds: [configurationId],
          })
            .then(() => {
              dispatch(
                shouldRemoveConfigurationFromPermission(
                  userId,
                  configurationId,
                ),
              );
              dispatch(
                updateConfigurationPermissionActionFromUser.receive({
                  userId,
                  configurationId,
                }),
              );
            })
            .catch(() => {
              dispatch(
                updateConfigurationPermissionActionFromUser.invalid({
                  userId,
                  configurationId,
                }),
              );
              dispatch(
                shouldShowAlert({
                  title: 'Error while removing permission',
                  description:
                    'Something odd happened while removing permission',
                  type: 'error',
                }),
              );
            });
        }

        return updateConfigurationPermissionFromUser(configurationId, [userId])
          .then(() => {
            dispatch(
              shouldAddConfigurationFromPermission(userId, configurationId),
            );
            dispatch(
              updateConfigurationPermissionActionFromUser.receive({
                userId,
                configurationId,
              }),
            );
          })
          .catch(() => {
            dispatch(
              updateConfigurationPermissionActionFromUser.invalid({
                userId,
                configurationId,
              }),
            );
            dispatch(
              shouldShowAlert({
                title: 'Error while adding permission',
                description: 'Something odd happened while giving a permission',
                type: 'error',
              }),
            );
          });
      },
    );
  };

export const updatePermissionForConfigurationActions = createAsyncAction(
  'UPDATE_PERMISSION_FOR_CONFIGURATION',
);

export const shouldUpdatePermissionForConfiguration =
  (userId, payload, hasAccess) => (dispatch) => {
    dispatch(
      updatePermissionForConfigurationActions.request({
        userId,
        payload,
      }),
    );

    const dataHandler = hasAccess
      ? deleteConfigurationPermission
      : grantConfigurationPermission;
    dataHandler(payload)
      .then(() => {
        dispatch(
          updatePermissionForConfigurationActions.receive({
            userId,
            payload: {
              ...payload,
              hasAccess,
            },
          }),
        );
        cogoToast.success(
          hasAccess
            ? 'Permissions have been revoked'
            : 'Permissions have been given',
          {
            position: 'bottom-right',
            hideAfter: 5,
          },
        );
      })
      .catch(() => {
        dispatch(
          updatePermissionForConfigurationActions.invalid({
            userId,
            payload: {
              ...payload,
              hasAccess,
            },
          }),
        );
        if (hasAccess) {
          dispatch(
            shouldShowAlert({
              title: 'Error while removing permission',
              description: 'Something odd happened while removing permission',
              type: 'error',
            }),
          );
        } else {
          dispatch(
            shouldShowAlert({
              title: 'Error while adding permission',
              description: 'Something odd happened while giving a permission',
              type: 'error',
            }),
          );
        }
      });
  };

export const hideBatchUserPermissionsAction = createAction(
  'BATCH_PERMISSIONS_HIDE_DIALOG',
);
export const showBatchUserPermissionsAction = createAction(
  'BATCH_PERMISSIONS_SHOW_DIALOG',
);

export const shouldBatchDeletePersmissionsAction = createAsyncAction(
  'BATCH_PERMISSIONS_DELETE_FROM_CONFIGURATION_SELECTION',
);
export const shouldBatchDeletePersmissions = defaultAsyncCallWithActions(
  deleteConfigurationPermission,
  shouldBatchDeletePersmissionsAction,
  (dispatch) => {
    dispatch(hideBatchUserPermissionsAction());
    cogoToast.success('Permissions have been revoked', {
      position: 'bottom-right',
      hideAfter: 5,
    });
  },
);

export const shouldBatchUpdatePersmissionsAction = createAsyncAction(
  'BATCH_PERMISSIONS_FROM_CONFIGURATION_SELECTION',
);
export const shouldBatchUpdatePersmissions = defaultAsyncCallWithActions(
  updateConfigurationPermission,
  shouldBatchUpdatePersmissionsAction,
  (dispatch) => {
    dispatch(hideBatchUserPermissionsAction());
    cogoToast.success('Permissions have been given', {
      position: 'bottom-right',
      hideAfter: 5,
    });
  },
);

export const fetchUnapprovedConfigurationAction = createAsyncAction(
  'ALL_UNAPPROVED_CONFIGURATIONS',
);

export const shouldFetchUnapprovedConfigurations = defaultAsyncCallWithActions(
  getUnapprovedConfiguration,
  fetchUnapprovedConfigurationAction,
);

export const fetchInactiveConfigurationsAction = createAsyncAction(
  'ALL_INACTIVE_CONFIGURATIONS',
);

export const shouldFetchInactiveConfigurations = withCache(
  'configuration',
  'allInactiveConfigurations',
  'isFetchingAllInactiveConfigurations',
)(
  defaultAsyncCallWithActions(
    getInactiveProviders,
    fetchInactiveConfigurationsAction,
  ),
);

export const playAction = createAsyncAction('PLAY_CONFIGURATION');
export const shouldPlay = noBodyResponseAsyncCallWithActions(play, playAction);

export const pauseAction = createAsyncAction('PAUSE_CONFIGURATION');
export const shouldPause = noBodyResponseAsyncCallWithActions(
  pause,
  pauseAction,
);

export const approveAction = createAsyncAction('APPROVE_CONFIGURATION');
export const shouldApprove = noBodyResponseAsyncCallWithActions(
  approve,
  approveAction,
);

export const rejectAction = createAsyncAction('REJECT_CONFIGURATION');

export const shouldReject = noBodyResponseAsyncCallWithActions(
  reject,
  rejectAction,
);

export const shouldReAuth =
  (configuration, integrationConfig) => (/* dispatch */) => {
    if (
      !integrationConfig.authMethods.oauth ||
      !integrationConfig.authMethods.oauth.oauthCallbackKey
    ) {
      throw new Error(
        `Provider definition for ${integrationConfig.name} is missing oauth url options`,
      );
    }
    storage.set(
      integrationConfig.authMethods.oauth.oauthCallbackKey,
      configuration.ProviderId,
    );

    // why this replace :1339 mess?
    // https://github.com/CluedIn-io/CluedIn.Widget/issues/141
    const cleanedReAuthEndpoint =
      configuration &&
      configuration.ReAuthEndpoint &&
      configuration.ReAuthEndpoint.replace(':1339', '');

    return reAuthProvider(cleanedReAuthEndpoint).then((url) => {
      if (window) {
        window.location.assign(url);
      }
    });
  };

export const fetchProdutOwnerActions = createAsyncAction(
  'CONFIGURATION_FETCH_PRODUCT_OWNER',
);
export const shouldFetchProductOwner = (providerDefinitionId) => (dispatch) => {
  dispatch(fetchProdutOwnerActions.request());

  return fetchProductOwner(providerDefinitionId)
    .then((resp) => {
      dispatch(fetchProdutOwnerActions.receive(resp));
    })
    .catch(
      unauthorizedWithResponseStatus(dispatch, fetchProdutOwnerActions.invalid),
    );
};

export const setProductOwnerActions = createAsyncAction(
  'CONFIGURATION_SET_PRODUCT_OWNER',
);
export const shouldSetProductOwner =
  (userId, providerDefinitionId) => (dispatch) => {
    dispatch(setProductOwnerActions.request({ userId, providerDefinitionId }));

    return setProductOwner(userId, providerDefinitionId)
      .then((resp) => {
        dispatch(setProductOwnerActions.receive(resp));
        dispatch(shouldFetchProductOwner(providerDefinitionId));
      })
      .catch(
        unauthorized(dispatch, setProductOwnerActions.invalid, false, {
          userId,
          providerDefinitionId,
        }),
      );
  };

export const removeProductOwnerActions = createAsyncAction(
  'CONFIGURATION_REMOVE_PRODUCT_OWNER',
);
export const shouldRemoveProductOwner = (productOwner) => (dispatch) => {
  dispatch(removeProductOwnerActions.request(productOwner));

  return removeProductOwner(productOwner)
    .then(() => {
      dispatch(removeProductOwnerActions.receive(productOwner));
      dispatch(shouldFetchProductOwner(productOwner.ProviderDefinitionId));
    })
    .catch(
      unauthorized(
        dispatch,
        removeProductOwnerActions.invalid,
        false,
        productOwner,
      ),
    );
};

export const shouldShowProductOwner = createAction('SHOW_PRODUCT_OWNER_DIALOG');
export const shouldHideProductOwner = createAction('HIDE_PRODUCT_OWNER_DIALOG');
export const receiveRealTimeProviderMessage = createAction(
  'CONFIGURATION_NEW_STATUS_FROM_SOCKET',
);

export const reProcessConfigurationActions = createAsyncAction(
  'CONFIGURATION_REPROCESS_FOR_CONFIGURATION',
);
export const shouldReprocess = defaultAsyncCallWithActions(
  reProcessConfiguration,
  reProcessConfigurationActions,
  (dispatch) => {
    dispatch(
      shouldShowAlert({
        title: 'Successful request',
        description:
          'Your request to re-process this configuration has been received',
        type: 'success',
      }),
    );
  },
);
