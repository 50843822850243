import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import UserRoles from '../../../../../user/components/composites/UserRoles';

export const columns = [
  {
    displayName: <FormattedMessage id="module-connector-userName" />,
    property: 'username',
  },
  {
    displayName: <FormattedMessage id="module-connector-roles" />,
    Component: UserRoles,
  },
];
