import React from 'react';
import ImagePreview from '../../../../entityWidget/components/composites/ImagePreview';

const EntityPreview = ({ entity }) => {
  let imagePreviewUrl;

  if (entity.hasPreview) {
    imagePreviewUrl = entity.previewUrl;
  }

  if (!imagePreviewUrl && entity.hasLogo) {
    imagePreviewUrl = entity.logoUrl;
  }

  return <ImagePreview previewUrl={imagePreviewUrl} alt={entity.name} />;
};

export default EntityPreview;
