import { registerEntityWidget } from '../../registry';
import EntityPropertyWidget from './EntityPropertyWidget';

registerEntityWidget('EntityPropertyWidget', {
  name: 'Entity Property Widget',
  displayName: 'Property',
  description: 'Display all available properties for an Entity.',
  view: EntityPropertyWidget,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
