"use strict";

exports.__esModule = true;
exports.default = void 0;
var _context = require("@apollo/client/link/context");
var _token = require("../token");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var authLink = (0, _context.setContext)(function (_, _ref) {
  var headers = _ref.headers;
  // get the authentication token from local storage if it exists
  var token = (0, _token.getCurrentToken)();
  // return the headers to the context so httpLink can read them
  return {
    headers: _extends({}, headers, {
      authorization: token ? "Bearer " + token : ''
    })
  };
});
var _default = authLink;
exports.default = _default;