import styled from 'styled-components';
import EntityListProperty from '../../../../../entityProperties/components/containers/EntityProperties/EntityListProperty/EntityListProperty';

const FieldDisplayNameSubtitle = styled.div`
  text-transform: lowercase;
  font-weight: 400;
  font-size: 11px;
`;

const VocabularyDisplayNameWrapper = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VocabularyDisplayNameComponent = ({ property, vocabKey }) => {
  const displayNameToUse = property;

  if (vocabKey) {
    return (
      <>
        <EntityListProperty
          hidePopover
          vocabularyKey={vocabKey}
          entityVocabularyKey={property}
        />
      </>
    );
  }

  if (displayNameToUse && displayNameToUse.indexOf('.') > -1) {
    const main = displayNameToUse.split('.').pop();
    const sub = displayNameToUse.replace(main, '');

    return (
      <VocabularyDisplayNameWrapper>
        <div>{main}</div>
        <FieldDisplayNameSubtitle>{sub}</FieldDisplayNameSubtitle>
      </VocabularyDisplayNameWrapper>
    );
  }

  return <span>{displayNameToUse || ''}</span>;
};

export default VocabularyDisplayNameComponent;
