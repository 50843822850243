import { gql } from '@apollo/client';

export default gql`
  fragment EntityExplainLog on EntityExplainLog {
    entityId
    scopes {
      identifier {
        raw {
          definitionId
          definitionName
          entityId
          scopeIdentifier
        }
        details {
          kind
          dataPartId
        }
      }
      operations {
        id
        category
        title
        duration
        events
        result {
          status
          error
          event
        }
        summaries
      }
      source {
        id
        kind
        name
      }
    }
  }
`;
