import { gql } from '@apollo/client';

export default gql`
  fragment VocabularyKey on VocabularyKey {
    displayName
    vocabularyKeyId
    vocabularyId
    name
    isVisible
    isCluedInCore
    isDynamic
    isProvider
    isObsolete
    groupName
    key
    storage
    dataClassificationCode
    dataType
    description
    dataAnnotationsIsPrimaryKey
    dataAnnotationsIsEditable
    dataAnnotationsIsNullable
    dataAnnotationsIsRequired
    dataAnnotationsMinimumLength
    dataAnnotationsMaximumLength
    providerId
    compositeVocabularyId
    compositeVocabulary {
      name
      displayName
      dataType
    }
    mapsToOtherKeyId
    glossaryTermId
    createdAt
    createdBy
    mappedKey
    isValueChangeInsignificant
    connector {
      id
      name
      about
      icon
      type
    }
    vocabulary {
      vocabularyId
      vocabularyName
      connector {
        id
        name
        about
        icon
      }
    }
    author {
      id
      username
    }
  }
`;
