"use strict";

exports.__esModule = true;
exports.useQueryString = void 0;
var _pick = _interopRequireDefault(require("lodash/pick"));
var _forOwn = _interopRequireDefault(require("lodash/forOwn"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var updateLocation = function updateLocation(_ref, history, replace, path) {
  var pathname = _ref.pathname,
    hash = _ref.hash,
    query = _ref.query,
    state = _ref.state;
  if (replace === void 0) {
    replace = false;
  }
  var search = query.toString();
  var newLocation = {
    pathname: path ? path : pathname,
    hash: hash,
    search: search ? "?" + search : '',
    state: state
  };
  if (replace) {
    history.replace(newLocation);
  } else {
    history.push(newLocation);
  }
};
var getValue = function getValue(search, key) {
  var query = new URLSearchParams(search);
  return Array.isArray(key) ? key.reduce(function (acc, currentKey) {
    var _extends2;
    return _extends({}, acc, (_extends2 = {}, _extends2[currentKey] = query.get(currentKey), _extends2));
  }, {}) : query.get(key);
};
var setValue = function setValue(history, key, path) {
  return function (value, replaceLocation) {
    var _ref2;
    if (replaceLocation === void 0) {
      replaceLocation = false;
    }
    var _history$location = history.location,
      pathname = _history$location.pathname,
      hash = _history$location.hash,
      search = _history$location.search,
      state = _history$location.state;
    var query = new URLSearchParams(search);
    var values = Array.isArray(key) ? (0, _pick.default)(value, key) : (_ref2 = {}, _ref2[key] = value, _ref2);
    (0, _forOwn.default)(values, function (v, k) {
      return query.set(k, v.toString());
    });
    updateLocation({
      pathname: pathname,
      hash: hash,
      query: query,
      state: state
    }, history, replaceLocation, path);
  };
};
var removeValue = function removeValue(history, key, path) {
  return function (replaceLocation) {
    if (replaceLocation === void 0) {
      replaceLocation = false;
    }
    var _history$location2 = history.location,
      pathname = _history$location2.pathname,
      hash = _history$location2.hash,
      search = _history$location2.search,
      state = _history$location2.state;
    var query = new URLSearchParams(search);
    var keys = Array.isArray(key) ? key : [key];
    keys.forEach(function (k) {
      return query.delete(k);
    });
    updateLocation({
      pathname: pathname,
      hash: hash,
      query: query,
      state: state
    }, history, replaceLocation, path);
  };
};
var useQueryString = function useQueryString(history, location, key, options) {
  if (options === void 0) {
    options = {};
  }
  var search = location.search;
  var _options = options,
    path = _options.path;
  var currentValue = getValue(search, key);
  return [currentValue, setValue(history, key, path), removeValue(history, key, path)];
};
exports.useQueryString = useQueryString;