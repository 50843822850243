import { useLazyQuery, gql } from '@apollo/client';
import get from 'lodash/get';

const GET_ENTITY_EDGES = gql`
  query getEntitiesByEdgeType(
    $entityId: ID!
    $edgeType: String
    $edgeDirection: String
    $cursor: String
    $isShadowEntity: Boolean
  ) {
    virtualization {
      getEntitiesByEdgeType(
        entityId: $entityId
        edgeType: $edgeType
        edgeDirection: $edgeDirection
        cursor: $cursor
        isShadowEntity: $isShadowEntity
      )
    }
  }
`;

export const useGetEntityEdges = () => {
  const [getEntitiesByEdgeType, { data, loading, error, fetchMore }] =
    useLazyQuery(GET_ENTITY_EDGES, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const incomingCursor = get(
    data,
    'virtualization.getEntitiesByEdgeType.data.entity.edges.incoming.cursor',
  );
  const outgoingCursor = get(
    data,
    'virtualization.getEntitiesByEdgeType.data.entity.edges.outgoing.cursor',
  );
  const cursorToUse = incomingCursor || outgoingCursor;

  const result = get(data, 'virtualization.getEntitiesByEdgeType');

  return [
    (entityId, edgeType, edgeDirection, isShadowEntity) =>
      getEntitiesByEdgeType({
        variables: {
          entityId,
          edgeType,
          edgeDirection,
          isShadowEntity,
        },
      }),
    {
      data: result,
      loading,
      error,
      fetchMore: (entityId, edgeType, edgeDirection, isShadowEntity) =>
        fetchMore({
          variables: {
            entityId,
            edgeType,
            edgeDirection,
            cursor: cursorToUse,
            isShadowEntity,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const fetchMoreResults = get(
              fetchMoreResult,
              'virtualization.getEntitiesByEdgeType',
            );

            if (
              fetchMoreResults?.data?.entity?.edges?.outgoing?.entries
                ?.length === 0 ||
              fetchMoreResults?.data?.entity?.edges?.incoming?.entries
                ?.length === 0
            ) {
              return prev;
            }

            const dataToAppend = fetchMoreResults?.data?.entity?.edges?.outgoing
              ? {
                  edges: {
                    ...(prev.virtualization.getEntitiesByEdgeType.data.entity
                      .edges || {}),
                    outgoing: {
                      ...(prev.virtualization.getEntitiesByEdgeType.data.entity
                        .edges.outgoing || {}),
                      entries: [
                        ...(prev.virtualization.getEntitiesByEdgeType.data
                          .entity.edges.outgoing.entries || []),
                        ...fetchMoreResults.data.entity.edges.outgoing.entries,
                      ],
                      cursor:
                        fetchMoreResults.data.entity.edges.outgoing.cursor,
                    },
                  },
                }
              : {
                  edges: {
                    ...(prev.virtualization.getEntitiesByEdgeType.data.entity
                      .edges || {}),
                    incoming: {
                      ...(prev.virtualization.getEntitiesByEdgeType.data.entity
                        .edges.incoming || {}),
                      entries: [
                        ...(prev.virtualization.getEntitiesByEdgeType.data
                          .entity.edges.incoming.entries || []),
                        ...fetchMoreResults.data.entity.edges.incoming.entries,
                      ],
                      cursor:
                        fetchMoreResults.data.entity.edges.incoming.cursor,
                    },
                  },
                };

            const result = {
              ...prev,
              virtualization: {
                ...(prev.virtualization || {}),
                getEntitiesByEdgeType: {
                  ...(prev.virtualization.getEntitiesByEdgeType || {}),
                  data: {
                    ...(prev.virtualization.getEntitiesByEdgeType.data || {}),
                    entity: {
                      ...(prev.virtualization.getEntitiesByEdgeType.data
                        .entity || {}),
                      ...dataToAppend,
                    },
                  },
                },
              },
            };

            return result;
          },
        }),
    },
  ];
};
