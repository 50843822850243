"use strict";

exports.__esModule = true;
exports.useResetPageNo = void 0;
var _hooks = require("../../../hooks");
var useResetPageNo = function useResetPageNo(history, location, pageNoLabel) {
  if (pageNoLabel === void 0) {
    pageNoLabel = 'pageNo';
  }
  var _useQueryString = (0, _hooks.useQueryString)(history, location, pageNoLabel),
    qsPageNo = _useQueryString[0],
    setPageNo = _useQueryString[1];
  var pageNo = parseInt(qsPageNo, 10);
  var resetPageNo = function resetPageNo() {
    if (pageNo && pageNo > 1) {
      setPageNo('1', true);
    }
  };
  return resetPageNo;
};
exports.useResetPageNo = useResetPageNo;