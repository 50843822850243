"use strict";

exports.__esModule = true;
exports.storeContentRectHeight = exports.showColumn = exports.setSelected = exports.setNextColumnWidth = exports.setIsResizing = exports.setIsReordering = exports.setExtraColumnWidth = exports.setDisplay = exports.setCurrColumnWidth = exports.setColumns = exports.setColumnWidth = exports.setColumOrder = exports.hideColumn = exports.default = exports.C = void 0;
var C = {
  SET_SELECTED: 'SET_SELECTED',
  SET_EXTRA_COL_WIDTH: 'SET_EXTRA_COL_WIDTH',
  SET_DISPLAY: 'SET_DISPLAY',
  SET_COLUMNS: 'SET_COLUMNS',
  SHOW_COLUMN: 'SHOW_COLUMN',
  HIDE_COLUMN: 'HIDE_COLUMN',
  SET_IS_RESIZING: 'SET_IS_RESIZING',
  SET_IS_REORDERING: 'SET_IS_REORDERING',
  SET_COLUMN_W: 'SET_COLUMN_W',
  SET_CURR_COLUMN_W: 'SET_CURR_COLUMN_W',
  SET_NEXT_COLUMN_W: 'SET_NEXT_COLUMN_W',
  SET_COLUMN_ORDER: 'SET_COLUMN_ORDER',
  STORE_C_RECT_HEIGHT: 'STORE_C_RECT_HEIGHT'
};
exports.C = C;
var setSelected = function setSelected(payload) {
  return {
    type: C.SET_SELECTED,
    payload: payload
  };
};
exports.setSelected = setSelected;
var setExtraColumnWidth = function setExtraColumnWidth(payload) {
  return {
    type: C.SET_EXTRA_COL_WIDTH,
    payload: payload
  };
};
exports.setExtraColumnWidth = setExtraColumnWidth;
var setDisplay = function setDisplay(payload) {
  return {
    type: C.SET_DISPLAY,
    payload: payload
  };
};
exports.setDisplay = setDisplay;
var storeContentRectHeight = function storeContentRectHeight(payload) {
  return {
    type: C.STORE_C_RECT_HEIGHT,
    payload: payload
  };
};
exports.storeContentRectHeight = storeContentRectHeight;
var setColumns = function setColumns(payload) {
  return {
    type: C.SET_COLUMNS,
    payload: payload
  };
};
exports.setColumns = setColumns;
var showColumn = function showColumn(payload) {
  return {
    type: C.SHOW_COLUMN,
    payload: payload
  };
};
exports.showColumn = showColumn;
var hideColumn = function hideColumn(payload) {
  return {
    type: C.HIDE_COLUMN,
    payload: payload
  };
};
exports.hideColumn = hideColumn;
var setIsResizing = function setIsResizing(payload) {
  return {
    type: C.SET_IS_RESIZING,
    payload: payload
  };
};
exports.setIsResizing = setIsResizing;
var setColumnWidth = function setColumnWidth(payload) {
  return {
    type: C.SET_COLUMN_W,
    payload: payload
  };
};
exports.setColumnWidth = setColumnWidth;
var setCurrColumnWidth = function setCurrColumnWidth(payload) {
  return {
    type: C.SET_CURR_COLUMN_W,
    payload: payload
  };
};
exports.setCurrColumnWidth = setCurrColumnWidth;
var setNextColumnWidth = function setNextColumnWidth(payload) {
  return {
    type: C.SET_NEXT_COLUMN_W,
    payload: payload
  };
};
exports.setNextColumnWidth = setNextColumnWidth;
var setIsReordering = function setIsReordering(payload) {
  return {
    type: C.SET_IS_REORDERING,
    payload: payload
  };
};
exports.setIsReordering = setIsReordering;
var setColumOrder = function setColumOrder(payload) {
  return {
    type: C.SET_COLUMN_ORDER,
    payload: payload
  };
};
exports.setColumOrder = setColumOrder;
var _default = {
  showColumn: showColumn,
  hideColumn: hideColumn,
  setIsResizing: setIsResizing,
  setColumnWidth: setColumnWidth
};
exports.default = _default;