import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import ForgotPwdFormContainer from './ForgotPwdFormContainer';
import PublicContext from '../../../auth/components/composites/PublicContext';
import ForgotImg from '../../assets/forgot-illustration.png';

const ForgotPwd = () => (
  <NotSignedInLayoutDividedInTwo
    Page={ForgotPwdFormContainer}
    Context={PublicContext}
    url={`${ForgotImg}`}
  />
);

export default ForgotPwd;
