import React from 'react';

import { AvatarWithName } from 'uxi/Img';
import { Integration } from 'uxi/Icons';

const DefaultAllIntegration = () => (
  <AvatarWithName
    imgSize={'16px'}
    icon={<Integration />}
    style={{ display: 'inline-flex' }}
  />
);

export default DefaultAllIntegration;
