import React from 'react';
import PropTypes from 'prop-types';
import { GqlErrorMessages } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { FormDecorator } from '@cluedin/form';

import EntityPropertiesInputToUse from '../EntityPropertiesInputToUse/EntityPropertiesInputToUse';

const EntityPropertiesStep2Edit = ({
  dataType,
  error,
  name,
  setEntityInputValue,
  vocabularyValue,
  vocabularyKey,
  entityInputValue,
}) => {
  return (
    <>
      <FormDecorator
        data-test={'entityProperties-editPanel-editValueInput'}
        label={
          <FormattedMessage id="module-entityProperties-editPanelSecondStepValue" />
        }
      >
        <EntityPropertiesInputToUse
          dataType={dataType}
          name={name}
          setEntityInputValue={setEntityInputValue}
          entityInputValue={entityInputValue}
          vocabularyValue={vocabularyValue}
          vocabularyKey={vocabularyKey}
        />
      </FormDecorator>

      {error && <GqlErrorMessages error={error} />}
    </>
  );
};

export default EntityPropertiesStep2Edit;

EntityPropertiesStep2Edit.propTypes = {
  dataType: PropTypes.any,
  error: PropTypes.any,
  name: PropTypes.string,
  setEntityInputValue: PropTypes.func,
  vocabularyValue: PropTypes.string,
};

EntityPropertiesStep2Edit.defaultProps = {
  dataType: null,
  error: null,
  name: null,
  setEntityInputValue: () => {},
  vocabularyValue: null,
};
