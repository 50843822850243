/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageLeft } from './PageLeft';
import { PageRight } from './PageRight';
import { PageNumberButton } from './PageNumberButton';
import { MiddleButtons } from './MiddleButtons';

const Container = styled.div`
  display: flex;
  background: #fff;
  max-height: 32px;
  justify-content: center;
`;

const normalizeSelectedPage = (selectedPage, totalPages) => {
  if (selectedPage > 0 && selectedPage <= totalPages) {
    return selectedPage;
  }

  if (selectedPage < 1) {
    return 1;
  }

  return totalPages;
};

export const Pager = ({
  totalItems = 0,
  selectedPage = 1,
  itemsPerPage = 20,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = normalizeSelectedPage(selectedPage, totalPages);

  return (
    <Container>
      <PageLeft currentPage={currentPage} onPageChange={onPageChange} />
      <PageNumberButton
        pageNumber={1}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show
      />
      <PageNumberButton
        pageNumber={2}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show={totalPages > 1}
      />
      <MiddleButtons
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <PageNumberButton
        pageNumber={totalPages - 1}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show={totalPages > 3}
      />
      <PageNumberButton
        pageNumber={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        show={totalPages > 2}
      />
      <PageRight
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </Container>
  );
};

Pager.propTypes = {
  totalItems: PropTypes.number,
  selectedPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};
