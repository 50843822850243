import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_MESSAGES_FOR_ENTITY = gql`
  query messagesForEntity($entityId: ID!) {
    virtualization {
      messagesForEntity(entityId: $entityId) {
        messages
      }
    }
  }
`;

export const useMessagesForEntity = (entityId) => {
  const { data, loading, error, refetch } = useQuery(GET_MESSAGES_FOR_ENTITY, {
    variables: { entityId },
  });

  return [data, loading, error, refetch];
};
