import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import EntityPreview from '../../entityViewModel/components/composites/entity/EntityPreview';
import EntityIcon from '../components/composites/EntityIcon';
import EntityTypeAvatar from '../../entityTypes/components/containers/EntityTypeAvatar';
import EntityPropertyLabel from './EntityPropertyLabel';

const EntityPropertyViewerDetailedHeader = ({ entity }) => {
  let imagePreviewUrl;

  if (entity.hasPreview) {
    imagePreviewUrl = entity.previewUrl;
  }

  if (!imagePreviewUrl && entity.hasLogo) {
    imagePreviewUrl = entity.logoUrl;
  }

  const preview = imagePreviewUrl ? (
    <div style={{ width: '48px', marginRight: '14px' }}>
      <EntityPreview entity={entity} />
    </div>
  ) : null;

  const icon = <EntityIcon entityType={entity.data.entityType} size="L" />;

  const previewContent = preview || icon;

  return (
    <div style={{ margin: '0 0 16px 0' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{previewContent}</div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            textAlign: 'left',
            fontWeight: 'bold',
          }}
        >
          {entity.name}
        </div>
      </div>
      <div>
        <EntityPropertyLabel>
          <FormattedMessage id="module-entity-entityType" />
        </EntityPropertyLabel>
        <EntityTypeAvatar entityType={entity.data.entityType} />
      </div>
      <div>
        <EntityPropertyLabel>
          <FormattedMessage id="module-entity-desription" />
        </EntityPropertyLabel>
        <div style={{ textAlign: 'left' }}>
          {/** TODO: add a show more and limit to some character numbers */}
          {entity.data.description}
        </div>
      </div>
    </div>
  );
};

export default EntityPropertyViewerDetailedHeader;
