import { FC, ReactElement } from 'react';
import {
  Button,
  PanelFooter,
  PanelContent,
  PrimaryButton,
} from '@cluedin/form';
import { useIntl } from 'react-intl';

import translations from '../../../dataCatalog/locales/en';
import { VocabOrPropertySelection } from '../../../dataCatalog/components/containers/VocabOrPropertySelection';
import { useCleanProjectEntityProps } from '../../hooks/useCleanProjectEntityProps';
import { Key } from '../../types';

const isTranslationForPropExists = (key: string) => {
  return key in translations;
};

type Props = {
  msgId?: string;
  vocabKeys: Key[];
  onCreate: () => void;
  saveDisabled: boolean;
  saveLoading?: boolean;
  closeBtn: ReactElement;
  goToFirstStep: () => void;
  setVocabKeys: (data: Key[]) => void;
};

const SelectVocabKeysForNewCleanProject: FC<Props> = ({
  msgId,
  onCreate,
  closeBtn,
  vocabKeys,
  setVocabKeys,
  saveDisabled,
  goToFirstStep,
  saveLoading = false,
}) => {
  const { formatMessage } = useIntl();
  const { data, loading } = useCleanProjectEntityProps();

  if (loading) {
    return null;
  }

  const entityKeys = data?.map((i) => {
    const nameKey = `module-dataCatalog-${i}`;
    const descKey = `module-dataCatalog-${i}Expl`;

    const displayName = isTranslationForPropExists(nameKey)
      ? formatMessage({ id: nameKey })
      : i?.toUpperCase?.();

    const description = isTranslationForPropExists(descKey)
      ? formatMessage({ id: descKey })
      : '';

    return {
      property: i,
      type: 'entity',
      displayName,
      description,
    };
  });

  return (
    <>
      <PanelContent style={{ padding: 0 }}>
        <VocabOrPropertySelection
          onChange={setVocabKeys}
          entityKeys={entityKeys}
          noEntityProperties={false}
          defaultColumns={vocabKeys}
        />
      </PanelContent>

      <PanelFooter>
        <Button
          rounded
          onClick={goToFirstStep}
          style={{ marginRight: '8px' }}
          text={formatMessage({ id: 'module-clean-create-back' })}
        />

        {closeBtn}

        <PrimaryButton
          rounded
          onClick={onCreate}
          loading={saveLoading}
          disabled={saveDisabled}
          style={{ marginLeft: '8px' }}
        >
          {formatMessage({ id: msgId ? msgId : 'module-clean-create-create' })}
        </PrimaryButton>
      </PanelFooter>
    </>
  );
};

export default SelectVocabKeysForNewCleanProject;
