import _ from 'lodash';

export const getIcon = (
  name: string | null | undefined,
): HTMLImageElement | null => {
  if (!name) return null;

  try {
    const iconFileSVG = require(`@cluedin/svgs/icons/entity-types/${_.kebabCase(
      name,
    )}-ico.svg`);
    const image = new Image();
    image.setAttribute('src', iconFileSVG);

    return image;
  } catch (e) {
    console.error('Error importing icon:', e);
    return null;
  }
};
