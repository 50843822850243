import { useEffect, useState, useRef, useMemo } from 'react';
// import ResizeObserver from 'resize-observer-polyfill'; // from uxi

/**
 * Because we directly depend on uxi directly, we have the resize-observer-polyfill
 * ─┬ uxi@3.0.30
 *  └── resize-observer-polyfill@1.5.1
 */
export const useResizeObserver = (reactRef, callback) => {
  useEffect(() => {
    const resizeObserver = new window.ResizeObserver((entries) => {
      callback(entries[0].contentRect);
    });

    resizeObserver.observe(reactRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [reactRef, callback]);
};

export const useResizeObserver2 = ({ ref: reactRef, onResize }) => {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const previous = useRef({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // if (
    //   typeof ref !== "object" ||
    //   ref === null ||
    //   !(ref.current instanceof Element)
    // ) {
    //   return;
    // }

    // const element = ref.current;

    const resizeObserver = new window.ResizeObserver((entries) => {
      // Since we only observe the one element, we don't need to loop over the
      // array
      if (!entries.length) {
        return;
      }

      const entry = entries[0];

      // `Math.round` is in line with how CSS resolves sub-pixel values
      const newWidth = Math.round(entry.contentRect.width);
      const newHeight = Math.round(entry.contentRect.height);
      if (
        previous.current.width !== newWidth ||
        previous.current.height !== newHeight
      ) {
        const newSize = { width: newWidth, height: newHeight };
        if (onResize) {
          onResize(newSize);
        } else {
          previous.current.width = newWidth;
          previous.current.height = newHeight;
          setSize(newSize);
        }
      }
    });

    resizeObserver.observe(reactRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [reactRef, onResize]);

  return useMemo(
    () => ({ reactRef, ...size }),
    // eslint-disable-next-line
    [reactRef, size ? size.width : null, size ? size.height : null],
  );
};

export default null;
