import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { createDataSourceSetMutation } from '../../data';

export const withCreateDataSourceSetMutation = (Comp) => (props) =>
  (
    <Mutation mutation={createDataSourceSetMutation}>
      {(createDataSourceSet, { loading, error, data = {} }) => {
        const dataSourceSetId =
          data && data.inbound && data.inbound.createDataSourceSet
            ? data.inbound.createDataSourceSet
            : '';

        return (
          <Comp
            {...props}
            isSaving={loading}
            errorCreateDataSourceSet={error}
            dataSourceSetId={dataSourceSetId}
            createDataSourceSet={({ name }) => {
              createDataSourceSet({
                variables: {
                  dataSourceSet: {
                    name,
                    author:
                      props.currentUser && props.currentUser.client
                        ? props.currentUser.client.Id
                        : '',
                  },
                },
              });
            }}
          />
        );
      }}
    </Mutation>
  );

withCreateDataSourceSetMutation.displayName = 'withCreateDataSourceSetMutation';

export default {
  withCreateDataSourceSetMutation,
};
