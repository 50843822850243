import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { GqlErrorMessages } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import {
  GhostEntityHistoryLoader,
  EntityHistoryEmptyState,
  EntityHistoryHeader,
} from '@cluedin/components';

import { useQueryEntitySources } from '../../hooks/useEntityHistorySources';
import { Header } from '../../containers/HeaderSection/Header';

import HistoryChangeSet from './HistoryChangeSet/HistoryChangeSet';

export const EntityHistoryWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const getCorrectLabel = (property) => {
  if (property === 'CluedInClean') {
    return <FormattedMessage id="module-entityTopology-sourceTextClean" />;
  } else if (
    property === 'CluedInManualEntry' ||
    property.includes('Manual Data Entry')
  ) {
    return <FormattedMessage id="module-entityTopology-sourceManuallyAdded" />;
  } else if (property === 'mergeEntities') {
    return (
      <FormattedMessage id="module-entityTopology-deduplicationPanelTitle" />
    );
  } else {
    return property;
  }
};

const defaultSourcesOption = { label: 'All data sources', value: '' };
const EntityHistory = ({
  entityId,
  useQueryEntitySummaryById,
  useQueryEntityHistoryDetails,
  labels = {},
  userAvatar,
  cleanRootUrl,
  dedupRootUrl,
  manualDataEntryRootUrl,
}) => {
  const [filters, setFilters] = useState({
    sourceName: '',
    sortedDateFilter: 'SortDate',
  });

  const {
    data: entitySources,
    loading: loadingSources,
    error: errorSources,
  } = useQueryEntitySources(entityId);

  const entitySourceOptions = !loadingSources
    ? [
        defaultSourcesOption,
        ...entitySources?.map(({ property }) => ({
          label: getCorrectLabel(property),
          value: property,
        })),
      ]
    : [defaultSourcesOption];
  const {
    data: history,
    loading,
    error,
  } = useQueryEntitySummaryById({ id: entityId, params: filters });

  const { noHistoryFound = 'No history for this entity' } = labels;

  return (
    <EntityHistoryWrapper>
      <Header
        entitySourceOptions={entitySourceOptions}
        setFilters={setFilters}
        filters={filters}
      />
      {loading && <GhostEntityHistoryLoader />}
      {error && <GqlErrorMessages error={error} />}
      {!history ||
        (history.length === 0 && (
          <EntityHistoryEmptyState noHistoryFound={noHistoryFound} />
        ))}
      {!loading && !error && history !== undefined && (
        <>
          <EntityHistoryHeader labels={labels} />
          {history.map((changeSet, index) => {
            return (
              <HistoryChangeSet
                cleanRootUrl={cleanRootUrl}
                dedupRootUrl={dedupRootUrl}
                manualDataEntryRootUrl={manualDataEntryRootUrl}
                key={index}
                isOpen={index === 0 ? true : false}
                changeSet={changeSet}
                entityId={entityId}
                currentSort={filters}
                useQueryEntityHistoryDetails={useQueryEntityHistoryDetails}
                labels={labels}
                userAvatar={userAvatar}
              />
            );
          })}
        </>
      )}
    </EntityHistoryWrapper>
  );
};

export default EntityHistory;

EntityHistory.propTypes = {
  entityId: PropTypes.string.isRequired,
  useQueryEntitySummaryById: PropTypes.func.isRequired,
  useQueryEntityHistoryDetails: PropTypes.func.isRequired,
  labels: PropTypes.object,
  userAvatar: PropTypes.node,
};
