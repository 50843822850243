import React from 'react';
import { Link } from 'react-router-dom';
import { AvatarWithName, HighlightText } from '@cluedin/atoms';

import FirstLetterComponent from '../../../core/components/composites/FirstLetterComponent';

const IntegrationNameWithLogoFromIntegration = ({
  integration = {},
  link,
  onClick,
  prefix = '',
  extra,
  query,
}) => {
  const nameContent = (
    <HighlightText text={integration.Name} highlight={query} />
  );

  const name = link ? (
    <Link
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      to={link}
    >
      {prefix}
      {nameContent}
      {extra}
    </Link>
  ) : (
    nameContent
  );

  return (
    <>
      <AvatarWithName
        key={integration?.Id}
        contain
        isSquare
        src={integration.Icon || null}
        icon={
          integration.Icon ? null : (
            <FirstLetterComponent
              customSize="24"
              customFontSize="14"
              name={integration.Name}
            />
          )
        }
        style={{ display: 'inline-flex' }}
        name={name}
      />
    </>
  );
};

export default IntegrationNameWithLogoFromIntegration;
