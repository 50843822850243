import React from 'react';
import RedirectContainer from '../containers/RedirectContainer';

export const onlyAuth = (Comp, token) => (props) =>
  (
    <RedirectContainer shouldRedirect={!token} keepRedirect url="/signin">
      <Comp {...props} />
    </RedirectContainer>
  );

export default {
  onlyAuth,
};
