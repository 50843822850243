import {
  DirectionalGraphNetworkEdge,
  UpdatedGraphNetworkEdge,
  UpdatedGraphNetworkNode,
} from '../types';

export const checkForMultipleLinks = (
  source:
    | string
    | number
    | DirectionalGraphNetworkEdge
    | UpdatedGraphNetworkNode,
  target:
    | string
    | number
    | DirectionalGraphNetworkEdge
    | UpdatedGraphNetworkNode,
  linkCollection: UpdatedGraphNetworkEdge[],
) => {
  const links = linkCollection?.filter(
    (link) =>
      (link?.source === source && link?.target === target) ||
      (link?.source === target && link?.target === source),
  );

  return links?.length > 1;
};
