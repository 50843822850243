import { connect } from 'react-redux';
import {
  createPropertyFromFields,
  setupSchemaForGrid,
} from '../../superEntityList/helper';

const mapStateToProps = (
  { entity: { schema, isFetchingSchema } },
  { properties },
) => {
  if (isFetchingSchema) {
    return {
      properties: [],
      isFetching: isFetchingSchema,
    };
  }

  const getSchemaVmByPropertyKey = setupSchemaForGrid(schema || []);

  const enhancedProperties = createPropertyFromFields(
    (properties || []).filter((f) => !f.disabled),
    getSchemaVmByPropertyKey,
  );

  return {
    properties: enhancedProperties,
    isFetching: isFetchingSchema,
  };
};

export const withPropertyViewModelOnly = (Comp) => {
  return connect(mapStateToProps)(Comp);
};

export default {
  withPropertyViewModelOnly,
};
