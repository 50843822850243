"use strict";

exports.__esModule = true;
exports.integrations = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var INTEGRATION_AVAILABLEINTEGRATIONS_INFORMED = _claimTypes.default.INTEGRATION_AVAILABLEINTEGRATIONS_INFORMED,
  INTEGRATION_CONFIGUREDINTEGRATIONS_CONSULTANT = _claimTypes.default.INTEGRATION_CONFIGUREDINTEGRATIONS_CONSULTANT,
  INTEGRATION_CONFIGUREDINTEGRATIONS_INFORMED = _claimTypes.default.INTEGRATION_CONFIGUREDINTEGRATIONS_INFORMED,
  INTEGRATION_DATASOURCEGROUPS_CONSULTANT = _claimTypes.default.INTEGRATION_DATASOURCEGROUPS_CONSULTANT,
  INTEGRATION_DATASOURCEGROUPS_INFORMED = _claimTypes.default.INTEGRATION_DATASOURCEGROUPS_INFORMED,
  INTEGRATION_ENRICHMENT_INFORMED = _claimTypes.default.INTEGRATION_ENRICHMENT_INFORMED,
  INTEGRATION_ENRICHMENT_CONSULTANT = _claimTypes.default.INTEGRATION_ENRICHMENT_CONSULTANT,
  INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_CONSULTANT = _claimTypes.default.INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_CONSULTANT,
  INTEGRATION_MANUALDATAENTRYINPUT_CONSULTANT = _claimTypes.default.INTEGRATION_MANUALDATAENTRYINPUT_CONSULTANT,
  INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_INFORMED = _claimTypes.default.INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_INFORMED;
var integrations = {
  enrichment: {
    enrichment: {
      create: {
        claims: INTEGRATION_ENRICHMENT_CONSULTANT
      },
      edit: {
        claims: INTEGRATION_ENRICHMENT_CONSULTANT
      },
      view: {
        claims: INTEGRATION_ENRICHMENT_INFORMED
      }
    }
  },
  availableIntegrations: {
    availableIntegration: {
      view: {
        claims: INTEGRATION_AVAILABLEINTEGRATIONS_INFORMED
      }
    }
  },
  manualDataEntryProjectManagement: {
    manualDataEntryProject: {
      create: {
        claims: INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_CONSULTANT
      },
      edit: {
        claims: INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_CONSULTANT
      },
      view: {
        claims: INTEGRATION_MANUALDATAENTRYPROJECTMANAGEMENT_INFORMED
      }
    }
  },
  manualDataEntryInput: {
    manualDataEntryInput: {
      create: {
        claims: INTEGRATION_MANUALDATAENTRYINPUT_CONSULTANT
      }
    }
  },
  configuredIntegrations: {
    configuredIntegration: {
      create: {
        claims: INTEGRATION_CONFIGUREDINTEGRATIONS_CONSULTANT
      },
      delete: {
        claims: INTEGRATION_CONFIGUREDINTEGRATIONS_CONSULTANT
      },
      edit: {
        claims: INTEGRATION_CONFIGUREDINTEGRATIONS_CONSULTANT
      },
      view: {
        claims: INTEGRATION_CONFIGUREDINTEGRATIONS_INFORMED
      }
    }
  },
  dataSources: {
    clues: {
      create: {
        claims: INTEGRATION_DATASOURCEGROUPS_CONSULTANT
      }
    },
    dataSet: {
      create: {
        claims: INTEGRATION_DATASOURCEGROUPS_CONSULTANT
      },
      delete: {
        claims: INTEGRATION_DATASOURCEGROUPS_CONSULTANT
      }
    },
    dataSource: {
      delete: {
        claims: INTEGRATION_DATASOURCEGROUPS_CONSULTANT
      },
      view: {
        claims: INTEGRATION_DATASOURCEGROUPS_INFORMED
      }
    },
    dataSourceGroup: {
      create: {
        claims: INTEGRATION_DATASOURCEGROUPS_CONSULTANT
      },
      view: {
        claims: INTEGRATION_DATASOURCEGROUPS_INFORMED
      }
    }
  }
};
exports.integrations = integrations;