import React from 'react';
import Alert from 'uxi/Alert';
import { FormattedHTMLMessage } from '@cluedin/locale';

const MergeDataWarning = ({ missingIDS = [] }) => (
  <Alert type="warning">
    <FormattedHTMLMessage
      id="errorMergeMissingIds"
      values={{
        missingIDS: missingIDS.join(', '),
      }}
    />
  </Alert>
);

export default MergeDataWarning;
