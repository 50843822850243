import React from 'react';
import uuid from 'uuid/v4';
import jsCookie from 'js-cookie';
import UserConsentForEndUserContainer from '../../../consent/components/containers/UserConsentForEndUserContainer';

const ConsentPage = ({
  match: {
    params: { consentFormID },
  },
  location: { search },
}) => {
  const params = new URLSearchParams(search);
  const uniqueID = params.get('uniqueID');
  const existingToken = jsCookie.get('consent_token');
  const generatedToken = `/Cookie#CluedIn:${uuid()}`;

  const generatedUuid = uniqueID || existingToken || generatedToken;

  return (
    <UserConsentForEndUserContainer
      consentFormId={consentFormID}
      generatedConsentToken={generatedUuid}
    />
  );
};

export default ConsentPage;
