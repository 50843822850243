import React from 'react';
import { Loader, TextField } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { CheckMark } from '@cluedin/atoms';

const errorStyle = { color: '#ef5858', fontSize: '12px' };

const RoleNameTextField = ({
  value,
  extraProps = {},
  onChange,
  disabled,
  hasError,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const isLoading = extraProps?.isLoading;
  const alreadyExisting = extraProps?.alreadyExisting;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div data-test="role-name-input" style={{ display: 'flex', flex: 1 }}>
          <TextField
            disabled={disabled}
            isFullWidth
            value={value}
            onChange={onChange}
          />
        </div>
        <div
          style={{ display: 'flex', paddingLeft: '12px', paddingTop: '4px' }}
        >
          {isLoading && <Loader />}
          {value && !hasError && !alreadyExisting && !isLoading && (
            <CheckMark size={16} color={themePrimary} />
          )}
        </div>
      </div>
      {alreadyExisting && (
        <div style={{ marginTop: '6px' }}>
          <span className="__test_errorMessage" style={errorStyle}>
            <FormattedMessage id="module-role-alreadyExisting" />
          </span>
        </div>
      )}
    </>
  );
};

export default RoleNameTextField;
