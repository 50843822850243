"use strict";

exports.__esModule = true;
exports.buttonResetstyles = exports.buttonResetStylesCSSString = void 0;
var _styledComponents = require("styled-components");
var _templateObject;
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var buttonResetstyles = {
  border: 'none',
  padding: 0,
  fontSize: 'inherit',
  background: 'transparent',
  borderRadius: 0
};
exports.buttonResetstyles = buttonResetstyles;
var buttonResetStylesCSSString = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  border: none;\n  padding: 0;\n  font-size: inherit;\n  background: transparent;\n  border-radius: 0;\n  cursor: pointer;\n"]))).join('\n');
exports.buttonResetStylesCSSString = buttonResetStylesCSSString;