import IntegrationIconContainer from '../../../../integration/components/containers/IntegrationIconContainer';
import { isDataSet } from '../../../../dataSourceV2/helper';
import { DataSetSourceLine } from './DataSetSourceLine';
import ProviderOriginalLink from './ProviderOriginalLink';
import { EntitySourceWrapperLine } from './EntitySourceWrapperLine';
import { EntitySourceName } from './EntitySourceName';

const EntitySourceLine = ({ integrationWithUris = {} }) => {
  if (isDataSet(integrationWithUris.name)) {
    return <DataSetSourceLine providerName={integrationWithUris.name} />;
  }

  if (!integrationWithUris.uri || integrationWithUris.uri.length === 0) {
    return (
      <EntitySourceWrapperLine>
        <IntegrationIconContainer
          iconSize="m"
          integrationName={integrationWithUris.name}
        />
        <EntitySourceName>{integrationWithUris.name}</EntitySourceName>
      </EntitySourceWrapperLine>
    );
  }

  return (
    <EntitySourceWrapperLine>
      <IntegrationIconContainer
        iconSize="m"
        integrationName={integrationWithUris.name}
      />
      <EntitySourceName>
        <ProviderOriginalLink integrationWithUris={integrationWithUris} />
      </EntitySourceName>
    </EntitySourceWrapperLine>
  );
};

export default EntitySourceLine;
