import React from 'react';
/**
 * @dependency {getAppropriateReactComponentFromSchema} core/modules/entity/entityProperty/getApp...
 */
import { getAppropriateReactComponentFromSchema } from '../entity/entityProperty/getAppropriateComponent';
/**
 * @dependency {EntityLinkFromEntityContainer} core/modules/wms/components/containers/EntityLink...
 */

import NameComponent from '../entityLists/components/composites/cells/NameComponent';
import CreatedComponent from '../entityLists/components/composites/cells/CreatedComponent';
import EntityType from '../entityLists/components/composites/cells/EntityType';
import PropertyValue from '../entityLists/components/composites/cells/PropertyValue';
import CellWidget from '../entityLists/components/composites/cells/CellWidget';
import CellForGrid from '../entityLists/components/composites/cells/CellForGrid';
import CellValueInRangeType from '../entityLists/components/composites/cells/CellValueInRangeType';
import FieldDisplayName from '../entityLists/components/composites/cells/FieldDisplayName';

const createPropertyFromField = (field, getSchemaVmByPropertyKey) => {
  const fieldDisplayName = <FieldDisplayName field={field} />;

  if (field.Component) {
    return {
      displayName: fieldDisplayName,
      property: field.field,
      Component: field.Component,
      schema: null,
    };
  }

  if (field.widget) {
    return {
      displayName: fieldDisplayName,
      property: field.field,
      Component: CellWidget(field.widget),
      schema: null,
    };
  }

  if (field.field === 'name') {
    return {
      displayName: fieldDisplayName,
      // propertyKey: field.field,
      property: field.field, // DataGridv2 API uses `property`, not `propertyKey`
      // isComputed: true,
      Component: NameComponent,
      schema: null,
    };
  }

  if (field.field === 'created') {
    return {
      displayName: fieldDisplayName,
      // propertyKey: field.field,
      property: field.field, // DataGridv2 API uses `property`, not `propertyKey`
      // isComputed: true,
      Component: CreatedComponent,
      schema: null,
    };
  }

  if (field.field === 'entityType') {
    return {
      displayName: fieldDisplayName,
      // propertyKey: field.field,
      property: field.field, // DataGridv2 API uses `property`, not `propertyKey`
      // isComputed: true,
      Component: EntityType,
      schema: null,
    };
  }

  if (field.field === 'value') {
    return {
      displayName: fieldDisplayName,
      // propertyKey: field.field,
      property: field.field, // DataGridv2 API uses `property`, not `propertyKey`
      // isComputed: true,
      Component: CellValueInRangeType,
      schema: null,
    };
  }

  const schemaForProperty = getSchemaVmByPropertyKey(field.field);
  const ShemaComponent = schemaForProperty.component;
  let Component = PropertyValue(field.field);

  if (ShemaComponent) {
    Component = CellForGrid(ShemaComponent, field.field);
  }

  return {
    displayName: fieldDisplayName,
    // propertyKey: field.field,
    property: field.field, // DataGridv2 API uses `property`, not `propertyKey`
    // isComputed: true, // DataGridv2 API deprecarted isCompouted, you always get full entity
    Component,
    schema: schemaForProperty,
  };
};

export const createPropertyFromFields = (fields, getSchemaVmByPropertyKey) =>
  fields.map((f) => {
    return createPropertyFromField(f, getSchemaVmByPropertyKey);
  });

export const setupSchemaForGrid = (schema) => {
  const findSchema = (key) =>
    schema.find((s) => s.Key.toLowerCase() === key.toLowerCase());

  return (key) => {
    const propertyInSchema = findSchema(key);
    let result;

    if (propertyInSchema) {
      result = {
        propertyInSchema,
        component: getAppropriateReactComponentFromSchema(propertyInSchema),
      };
    } else {
      result = {
        propertyInSchema: null,
        component: null,
        normalizedKeyNameForForm: null,
      };
    }
    return result;
  };
};

export default {
  createPropertyFromFields,
  setupSchemaForGrid,
};
