import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

const GET_ENTITIES_HIERARCHY = gql`
  query getEntitiesFromEdgeType($model: InputEntitiesFromEdgeType) {
    management {
      id
      entitiesFromEdgeType(model: $model) {
        hierarchyNodes {
          entityId
          displayName
          entityType
          originEntityCode
        }
      }
    }
  }
`;

export const useGetEntitiesFromEdgeType = () => {
  const [getEntities, { data, loading, error }] = useLazyQuery(
    GET_ENTITIES_HIERARCHY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const queryFromHooks = get(data, 'management.entitiesFromEdgeType');

  return [
    (model) => {
      getEntities({
        variables: { model },
      });
    },
    {
      data: queryFromHooks,
      loading,
      error,
    },
  ];
};
