import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import { excludedPropertiesFromPropertyViewer } from '../wms/helpers/entityPropertyConfig';
import { setupPropertyForAll } from '../../selectors/schema';
import { schemaCache } from './cache';
import { findSource } from '../integration/selector';
import {
  isPropertyFromDataSet,
  extractDataSetId,
  createDataSetIdForIntegrationComponent,
} from '../dataSourceV2/helper';

/* eslint-disable guard-for-in */

const propertyViewModelCache = {};
const propertyViewModelCacheWithouCluedin = {};

const validatePropertyKey = (key) =>
  key.toLowerCase().indexOf('kpi') === -1 &&
  key.toLowerCase().indexOf('-custom') === -1 &&
  excludedPropertiesFromPropertyViewer.indexOf(key) === -1;

const getPropertyGroupsFromEntity = (entity) => entity.data.propertyGroups;

export const getPropertyGroupedBySource = (entity) => {
  const groups = getPropertyGroupsFromEntity(entity);
  return groupBy(groups, (g) => g.source);
};

export const addCluedinForUndefinedSources = (groupedBySource) => {
  const result = { ...groupedBySource };
  if (groupedBySource && groupedBySource.undefined && groupedBySource.CluedIn) {
    result.CluedIn = groupedBySource.CluedIn.concat(groupedBySource.undefined);
  }
  if (
    groupedBySource &&
    groupedBySource.Knowledgegraph &&
    groupedBySource.CluedIn
  ) {
    result.CluedIn = groupedBySource.CluedIn.concat(
      groupedBySource.Knowledgegraph,
    );
  }

  return result;
};

export const cleanUpPropertiesInarray = (groupedBySource) => {
  const result = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const sourceKey in groupedBySource) {
    const groupedBySourceArray = groupedBySource[sourceKey];
    result[sourceKey] = [];

    groupedBySourceArray.forEach((group) => {
      result[sourceKey] = result[sourceKey].concat(group.keys);
    });
  }

  return result;
};

export const groupNullIntegrationToOthers = (groupedResult) => {
  const allNonEmptyIntegration = groupedResult.filter((g) => g.integration);

  const allEmpty = groupedResult.filter((g) => !g.integration);

  let allEmptyProperties = [];
  allEmpty.forEach((g) => {
    allEmptyProperties = allEmptyProperties.concat(g.properties);
  });

  if (allEmptyProperties && allEmptyProperties.length > 0) {
    const allPropertyFromDataSet = allEmptyProperties.filter((p) => {
      return isPropertyFromDataSet(p.propertyKeyName);
    });

    allPropertyFromDataSet.forEach((p) => {
      const dataSetId = extractDataSetId(p.propertyKeyName);
      const dataSet = allNonEmptyIntegration.find(
        (integrationPropertiesPair) => {
          return (
            integrationPropertiesPair.integration.name ===
            createDataSetIdForIntegrationComponent(dataSetId)
          );
        },
      );

      if (!dataSet) {
        const dsName = createDataSetIdForIntegrationComponent(dataSetId);

        allNonEmptyIntegration.push({
          integration: {
            name: dsName,
            Name: dsName,
          },
          properties: [p],
        });
      } else {
        dataSet.properties.push(p);
      }
    });

    allNonEmptyIntegration.push({
      integration: {
        name: 'Other Integrations',
        Name: 'Other Integrations',
      },
      properties: (allEmptyProperties || []).filter((p) => {
        return !isPropertyFromDataSet(p.propertyKeyName);
      }),
    });
  }

  return allNonEmptyIntegration;
};

const groupCluedInIntegration = (groupedResult) => {
  const allCluedInInfo = groupedResult.filter(
    (g) => g.integration.Name.toLowerCase() === 'cluedin',
  );

  if (
    allCluedInInfo &&
    (allCluedInInfo.length === 0 || allCluedInInfo.length === 1)
  ) {
    return groupedResult;
  }

  const cluedInProps = {
    integration: allCluedInInfo[0].integration,
    properties: [],
  };

  allCluedInInfo.forEach((cluedinCategory) => {
    cluedInProps.properties = cluedInProps.properties.concat(
      cluedinCategory.properties || [],
    );
  });

  const allNonCluedIn = groupedResult.filter(
    (g) => g.integration.Name.toLowerCase() !== 'cluedin',
  );

  return [cluedInProps, ...allNonCluedIn];
};

export const createPropertyGroupViewModel = (
  integrationName,
  propertiesForSource,
  entityProperties,
  getComponentForSchema,
  allIntegrations,
) => {
  const result = [];

  const integration = findSource(integrationName, allIntegrations);

  if (propertiesForSource && propertiesForSource.length > 0) {
    propertiesForSource.forEach((property) => {
      const propertyKeyName = `property-${property}`;
      if (validatePropertyKey(propertyKeyName)) {
        const schemaResult = getComponentForSchema(property);
        if (schemaResult && schemaResult.propertyInSchema) {
          const displayName = schemaResult.propertyInSchema.DisplayName;
          const value = entityProperties[propertyKeyName];
          const content = schemaResult.component
            ? schemaResult.component(value)
            : null;
          if (content) {
            result.push({ displayName, propertyKeyName, content });
          }
        }
      }
    });
  }

  return {
    integration,
    properties: result,
  };
};

// move that out of the Component -
// Component should play with a Array < PropertyGroupViewModel >
export const createPropertyGroupsViewModel = (
  entity,
  getComponentForSchema,
  hasCluedinInformation,
  allIntegrations,
) => {
  const hasCache = hasCluedinInformation
    ? propertyViewModelCache[entity.id]
    : propertyViewModelCacheWithouCluedin[entity.id];

  if (hasCache) {
    return hasCache;
  }

  const entityProperties = entity.data.properties;
  const groupedBySourceRaw = getPropertyGroupedBySource(entity);
  const groupedBySource = addCluedinForUndefinedSources(groupedBySourceRaw);
  const groupedBySourceWithOnlyProperties =
    cleanUpPropertiesInarray(groupedBySource);
  let result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const sourceKey in groupedBySourceWithOnlyProperties) {
    if (sourceKey !== 'undefined' && sourceKey !== 'Knowledgegraph') {
      const propertiesForSource = groupedBySourceWithOnlyProperties[sourceKey];
      const listOfPropertiesWithContent = createPropertyGroupViewModel(
        sourceKey,
        propertiesForSource,
        entityProperties,
        getComponentForSchema,
        allIntegrations,
      );

      if (
        listOfPropertiesWithContent.properties &&
        listOfPropertiesWithContent.properties.length > 0
      ) {
        result.push(listOfPropertiesWithContent);
      }
    }
  }

  if (hasCluedinInformation) {
    result = result.filter(
      (propGroup) =>
        !propGroup.integration ||
        propGroup.integration.Name.toLowerCase() !== 'cluedin',
    );
  }

  result = groupNullIntegrationToOthers(result);

  if (hasCluedinInformation) {
    propertyViewModelCache[entity.id] = result;
  } else {
    propertyViewModelCacheWithouCluedin[entity.id] = result;
  }

  return result;
};

const createProperties = (entity, getComponentForSchema, integrations) => {
  const entityProperties = entity.data.properties;

  const result = [];

  const addToResult = (property) => {
    let propertyNameForSchema = property;
    if (property.indexOf('property-') > -1) {
      propertyNameForSchema = property.split('property-')[1];
    }

    const schemaResult = getComponentForSchema(propertyNameForSchema);

    const displayName = schemaResult.propertyInSchema
      ? schemaResult.propertyInSchema.DisplayName
      : property;
    const value = entityProperties[property];
    const content = schemaResult.component
      ? schemaResult.component(value)
      : null;
    const cluedInSource = findSource('cluedin', integrations);
    let source =
      findSource(propertyNameForSchema, integrations) || cluedInSource;

    if (
      schemaResult &&
      schemaResult.propertyInSchema &&
      schemaResult.propertyInSchema.IsCore
    ) {
      source = {
        ...cluedInSource,
        Name: 'Core Vocabulary',
      };
    }

    result.push({
      displayName,
      property,
      content,
      value,
      schema:
        schemaResult && schemaResult.propertyInSchema
          ? schemaResult.propertyInSchema
          : null,
      hasSchema: !!(schemaResult && schemaResult.propertyInSchema),
      source,
    });
  };

  if (entityProperties && Object.keys(entityProperties).length > 0) {
    Object.keys(entityProperties)
      .filter((p) => p !== 'attribute-type')
      .forEach(addToResult);
  }

  return result;
};

export const createEntityProperties = (
  entity,
  schema = [],
  integrations = [],
) => {
  if (!entity || !entity.id) {
    return {
      properties: [],
    };
  }

  const getSchemaForComponent = schemaCache(entity.id, setupPropertyForAll)(
    schema,
    entity,
  );
  const properties = createProperties(
    entity,
    getSchemaForComponent,
    integrations,
  );

  return {
    properties,
  };
};

const getSelectedEntity = ({ entityModule }) =>
  entityModule ? entityModule.currentEntity : null;
const getEntityId = (state, props) => (props ? props.entityId : null);
const getIsFetchingEntity = ({ entityModule, entity }) =>
  entityModule.isFetchingEntity || entity.isFetchingSchema;
const getIsFetchingCurrentUser = ({ user }) => user.isFetchingCurrentUser;

const getIsFetchingEntityConfig = ({ wms }) =>
  wms.isLoadingEntityConfigurations;

export const getIsLoading = createSelector(
  [
    getEntityId,
    getIsFetchingEntity,
    getIsFetchingCurrentUser,
    getIsFetchingEntityConfig,
  ],
  (
    entityId,
    isFetchingEntity,
    isFetchingUser,
    isLoadingEntityConfigurations,
  ) => {
    if (entityId) {
      return (
        isFetchingEntity || isFetchingUser || isLoadingEntityConfigurations
      );
    }
    return isFetchingUser || isLoadingEntityConfigurations;
  },
);

export const getTitle = createSelector(
  [getSelectedEntity, getEntityId],
  (entity, entityId) => {
    let titleSuffix;

    if (!entityId && !entity) {
      titleSuffix = 'Application';
    }

    if (entity) {
      titleSuffix = entity.name;
    }

    if (!titleSuffix) {
      titleSuffix = 'loading...';
    }

    return `CluedIn - ${titleSuffix}`;
  },
);

const cluedinProcessorString = 'CluedIn('.toLocaleLowerCase();
const isCluedInProcessor = (name = '') =>
  name.toLocaleLowerCase().indexOf(cluedinProcessorString) > -1;

export const uniqProviderBasedOnUrisAndProviderCodes = (
  uris = [],
  providers = [],
  integrations = [],
) => {
  const result = [];

  const urisFiltered = (uris || [])
    .filter((uri) => uri.origin || uri.Origin)
    .map((uri) => ({
      ...uri,
      origin: uri.origin || uri.Origin,
    }));

  const compactedUris = urisFiltered || [];
  const compactedUrisWithoutCluedinProcessors = compactedUris.filter(
    (uri) => !isCluedInProcessor(uri.origin),
  );

  compactedUrisWithoutCluedinProcessors.forEach((uri) => {
    result.push({
      name: uri.origin,
      uri: uri.uri || uri.Uri,
      config: findSource(uri.origin, integrations, true),
    });
  });

  uniq(providers)
    .filter((providerCode) => {
      const providerConfigFromProviderCode = findSource(
        providerCode,
        integrations,
        true,
      );
      const isFound = compactedUrisWithoutCluedinProcessors.find((uri) => {
        if (uri.origin.toLowerCase() === providerCode.toLowerCase()) {
          return true;
        }

        const currentProviderFromUri = findSource(
          uri.origin,
          integrations,
          true,
        );
        if (currentProviderFromUri && providerConfigFromProviderCode) {
          return (
            currentProviderFromUri.Name === providerConfigFromProviderCode.Name
          );
        }

        return false;
      });

      return !isFound;
    })
    .forEach((providerCode) => {
      const providerConfigFromProviderCode = findSource(
        providerCode,
        integrations,
        true,
      );

      result.push({
        name: providerCode,
        config: providerConfigFromProviderCode || null,
        uri: '',
      });
    });

  return result;
};

export const createPropertyForQuickViewer = (
  entity,
  getComponentForSchema,
  hasCluedinInformation,
  allIntegrations,
) => {
  const entityProperties = entity.data.properties;
  const groupedBySourceRaw = getPropertyGroupedBySource(entity);
  const groupedBySource = addCluedinForUndefinedSources(groupedBySourceRaw);
  const groupedBySourceWithOnlyProperties =
    cleanUpPropertiesInarray(groupedBySource);
  const allPropertiesKeys = Object.keys(entityProperties || {});
  const propertiesScanned = [];

  let result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const sourceKey in groupedBySourceWithOnlyProperties) {
    if (sourceKey !== 'undefined' && sourceKey !== 'Knowledgegraph') {
      const propertiesForSource = groupedBySourceWithOnlyProperties[sourceKey];
      const listOfProperties = [];
      const integration = findSource(sourceKey, allIntegrations);

      if (propertiesForSource && propertiesForSource.length > 0) {
        // eslint-disable-next-line
        propertiesForSource.forEach((property) => {
          const propertyKeyName = `property-${property}`;
          propertiesScanned.push(propertyKeyName);
          if (validatePropertyKey(propertyKeyName)) {
            const schemaResult = getComponentForSchema(property);
            if (schemaResult && schemaResult.propertyInSchema) {
              const displayName = schemaResult.propertyInSchema.DisplayName;
              const value = entityProperties[propertyKeyName];
              const content = schemaResult.component
                ? schemaResult.component(value)
                : null;
              if (content) {
                listOfProperties.push({
                  displayName,
                  propertyKeyName,
                  content,
                });
              } else {
                listOfProperties.push({
                  displayName,
                  propertyKeyName,
                  content: value || null,
                });
              }
            } else {
              listOfProperties.push({
                displayName: propertyKeyName,
                propertyKeyName,
                content: entityProperties[propertyKeyName] || null,
              });
            }
          } else {
            listOfProperties.push({
              displayName: propertyKeyName,
              propertyKeyName,
              content: entityProperties[propertyKeyName] || null,
            });
          }
        });
      }

      if (listOfProperties && listOfProperties.length > 0) {
        result.push({
          integration,
          properties: listOfProperties,
        });
      }
    }
  }

  const allNonCategorizedProperties = [];
  allPropertiesKeys.forEach((p) => {
    if (propertiesScanned.indexOf(p) === -1 && p !== 'attribute-type') {
      allNonCategorizedProperties.push({
        displayName: p,
        propertyKeyName: p,
        content: entityProperties[p],
      });
    }
  });

  result.push({
    integration: null,
    properties: allNonCategorizedProperties,
  });

  result = groupNullIntegrationToOthers(result);
  result = groupCluedInIntegration(result);

  return result;
};

export default {
  getIsLoading,
  getTitle,
  createPropertyGroupsViewModel,
  createEntityProperties,
  uniqProviderBasedOnUrisAndProviderCodes,
  createPropertyForQuickViewer,
};
