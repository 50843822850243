import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';
import { NotFoundComponent } from '@cluedin/list-pattern';
import styled from 'styled-components';

import {
  installIntegration,
  buildIntegration,
} from '../../../../documentation/helpers';

const ButtonWrapper = styled.div`
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
`;

const EmptyIntegrationList = () => (
  <>
    <NotFoundComponent
      message={<FormattedMessage id="module-integration-noIntegrationAdded" />}
      explanation={
        <FormattedMessage id="module-integration-noIntegrationAdded-expl" />
      }
      noCreate
    />

    <ButtonWrapper>
      <PrimaryButton
        style={{ margin: '4px 0' }}
        href={installIntegration}
        target="_blank"
        rel="noopener"
        text={
          <FormattedMessage id="module-integration-installIntegrationFromMarketplace" />
        }
      />
      <span style={{ margin: '0 14px' }}>or</span>
      <PrimaryButton
        style={{ margin: '4px 0' }}
        href={buildIntegration}
        target="_blank"
        rel="noopener"
        text={<FormattedMessage id="module-integration-buildIntegration" />}
      />
    </ButtonWrapper>
  </>
);

export default EmptyIntegrationList;
