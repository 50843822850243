import React from 'react';
import { extractDataSetIdFromProviderNameInEntity, isDataSet } from '../helper';

export const withExtractIdFromDataSetName = (Comp) => (props) => {
  let dataSetId;

  if (isDataSet(props.providerName)) {
    dataSetId = extractDataSetIdFromProviderNameInEntity(props.providerName);
  }

  return <Comp id={dataSetId} {...props} />;
};

export default {
  withExtractIdFromDataSetName,
};
