import React from 'react';
import { Filter } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import { ButtonLink } from '@cluedin/atoms';

const PropertyFilterToggler = ({
  showPropertyFilterSearch = false,
  onShowPropertyFilters,
  onHidePropertyFilters,
  selectedFilters,
}) => {
  const propertyFilters = (selectedFilters || []).filter(
    (f) => f.type === 'property',
  );
  const buttonText = showPropertyFilterSearch ? (
    <FormattedMessage id="module-search-hideAdvancedVocabFilter" />
  ) : (
    <FormattedMessage id="module-search-showAdvancedVocabFilter" />
  );
  let extraText = '';
  if (propertyFilters.length === 1) {
    extraText = (
      <FormattedMessage
        id="module-search-filterCount"
        values={{ total: propertyFilters.length }}
      />
    );
  }
  if (propertyFilters.length > 1) {
    extraText = (
      <FormattedMessage
        id="module-search-filtersCount"
        values={{ total: propertyFilters.length }}
      />
    );
  }

  return (
    <ButtonLink
      style={{ marginLeft: '6px' }}
      onClick={() => {
        if (showPropertyFilterSearch) {
          onHidePropertyFilters();
        } else {
          onShowPropertyFilters();
        }
      }}
      icon={<Filter />}
      text={
        <>
          {buttonText}
          {extraText}
        </>
      }
    />
  );
};

export default PropertyFilterToggler;
