import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import cogoToast from 'cogo-toast';
import { usePrevious } from '../../core/hooks';

const INVITE_USER = gql`
  mutation inviteUser($email: String) {
    administration {
      id
      sendInvitation(email: $email) {
        id
        created
        email
        expiredDate
        updated
        userId
      }
    }
  }
`;

export const useMutationInviteUser = (refetch) => {
  const [inviteUser, { data, loading, error }] = useMutation(INVITE_USER);

  const invitation = get(data, 'administration.sendInvitation', {});

  const done = !loading && invitation && invitation.id;

  useEffect(() => {
    if (!loading && invitation && invitation.id) {
      cogoToast.success(`Invitation Sent`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
      if (refetch) {
        refetch();
      }
    }
  }, [loading, invitation, data]);

  return [
    (email) => {
      inviteUser({ variables: { email } });
    },
    {
      done,
      loading,
      error,
    },
  ];
};

const RESEND_INVITIES = gql`
  mutation inviteUser($ids: [ID]) {
    administration {
      id
      resendUserInvitation(ids: $ids)
    }
  }
`;

export const useMutationResentInvites = (refetch) => {
  const [resendInvite, { data, loading, error }] = useMutation(RESEND_INVITIES);

  const invitations = get(data, 'administration.resendUserInvitation', []);

  const done = !loading && invitations && invitations.length > 0;

  const prevError = usePrevious(error);

  useEffect(() => {
    if (!loading && invitations && invitations.length > 0) {
      cogoToast.success(`Invitations re-sent`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
      if (refetch) {
        refetch();
      }
    }
  }, [loading, invitations, data]);

  useEffect(() => {
    if (error && error !== prevError) {
      cogoToast.error(`Resend invitations failed`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  }, [error, prevError]);

  return [
    (ids) => {
      resendInvite({ variables: { ids } });
    },
    {
      done,
      loading,
      error,
    },
  ];
};

const REMOVE_INVITES = gql`
  mutation inviteUser($ids: [ID]) {
    administration {
      id
      removeUserInvitation(ids: $ids)
    }
  }
`;

export const useMutationRemoveInvites = (refetch) => {
  const [removeInvite, { data, loading, error }] = useMutation(REMOVE_INVITES);

  const invitations = get(data, 'administration.removeUserInvitation', []);

  const done = !loading && invitations && invitations.length > 0;

  const prevError = usePrevious(error);

  useEffect(() => {
    if (!loading && invitations && invitations.length > 0) {
      cogoToast.success(`Invitations removed`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
      if (refetch) {
        refetch();
      }
    }
  }, [loading, invitations, data]);

  useEffect(() => {
    if (error && error !== prevError) {
      cogoToast.error(`Remove invitations failed`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  }, [error, prevError]);

  return [
    (ids) => {
      removeInvite({ variables: { ids } });
    },
    {
      done,
      loading,
      error,
    },
  ];
};

export default {
  useMutationInviteUser,
  useMutationResentInvites,
  useMutationRemoveInvites,
};
