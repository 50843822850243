import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import defaultLanguages from '../../../../en.json';
/**
 * Getting other locale versions.
 */

//const messages = {
// en: defaultLanguages,
// de: {
//   ...localesInfoGerman.messages,
// },
//};
// Default flow
// 1. En is localized per default
// 2. we call addLocaleData('');
// 3. Check current locale => check cookie, if not, take navigator.language
// 4. If currentLocal !== "en" and "support", we fetch new JSON for the language
// 5. we call the actions

// Code
// 1. create actions to switch langauge
// 2. create reducer to listen for change of langauge
// 3. create the wrapper for react-intl
// 3. create Icon - Menu to switch Language
// 4. create Dopdown to switch Language and highlight the 'current'
const LocalizedProvider = ({ children, currentCulture = 'en' }) => {
  const [loadedCurrentCulture, setLoadedCurrentCulture] = useState('en');
  const [messages, setMessages] = useState(defaultLanguages);
  const [loadedLocales, setLoadedLocales] = useState({
    en: true,
  });

  useEffect(() => {
    if (currentCulture === 'en') {
      setMessages(defaultLanguages);
      setLoadedCurrentCulture(currentCulture);
    } else {
      fetch(`/languages/${currentCulture}.json`)
        .then((r) => r.json())
        .then((data) => {
          if (!loadedLocales[currentCulture]) {
            import(
              `@formatjs/intl-pluralrules/locale-data/${currentCulture}`
            ).then(() => {
              setLoadedLocales({
                ...loadedLocales,
                [currentCulture]: true,
              });
              setMessages(data);
              setLoadedCurrentCulture(currentCulture);
            });
          } else {
            setMessages(data);
            setLoadedCurrentCulture(currentCulture);
          }
        });
    }
  }, [currentCulture]);

  return (
    <IntlProvider
      key={loadedCurrentCulture}
      locale={loadedCurrentCulture}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = ({ locale: { currentCulture } }) => ({
  currentCulture,
});

export default connect(mapStateToProps)(LocalizedProvider);
