import styled from 'styled-components';
import { IconButton } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';

const Button = styled(IconButton)`
  border-radius: 4px;
`;

export const FilterIconButton = (props) => {
  const theme = useTheme();

  return (
    <Button
      theme={theme}
      {...props}
      style={{
        color: !props?.disabled && theme.palette.themePrimary,
        border: `1px solid ${
          !props?.disabled
            ? theme.palette.themePrimary
            : theme.palette.neutralQuaternaryAlt
        }`,
        background: 'transparent',
        ...props?.style,
      }}
    />
  );
};
