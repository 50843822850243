export const createDataToSend = ({ data, formFields = [] }) => {
  const resultData = {};
  const dataToSend = {};
  for (const [key, value] of Object.entries(data ?? {})) {
    const currentField = formFields.find((elem) => elem.id === key);

    if (
      currentField.type === 'toggle' &&
      (value === undefined || value === '')
    ) {
      resultData[key] = false;
    }
    if (value !== undefined && value !== '') {
      resultData[key] = value;
    }
  }

  for (const [key, value] of Object.entries(resultData)) {
    const vocabularyKey = formFields.find(
      (formField) => formField.id === key,
    )?.vocabularyKey;

    dataToSend[vocabularyKey.toString()] =
      typeof value === 'object' ? value.value : value;
  }
  return dataToSend;
};
