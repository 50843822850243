"use strict";

exports.__esModule = true;
exports.saveTokenForClientId = exports.getFullToken = exports.getCurrentToken = void 0;
var _store = _interopRequireDefault(require("store"));
var _config = require("../config");
var _utils = require("./utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var saveClientId = function saveClientId(currentClientId) {
  var clientIds = _store.default.get('clientIds') || [];
  if (clientIds.indexOf(currentClientId) === -1) {
    clientIds.push(currentClientId);
  }
  _store.default.set('clientIds', clientIds);
  _store.default.set('currentClientId', currentClientId);
};
var createTokenForStorage = function createTokenForStorage(accessToken, token) {
  return {
    token: accessToken,
    clientId: _config.clientId,
    expiresIn: token.expiresIn,
    expiresAt: (0, _utils.createExpiredDate)(token.expiresIn),
    refreshToken: token.refreshToken,
    scope: token.scope
  };
};
/**
 * Token:
 * {
 *   accessToken
 *   expiresIn
 *   refreshToken
 *   scope
 * }
 */
var saveTokenForClientId = function saveTokenForClientId(token, currentClientId) {
  var accessToken = token.accessToken ? token.accessToken : token;
  var tokenWithClientIds = _store.default.get('token') || [];
  var getCurrent = function getCurrent(keyPair) {
    return keyPair.clientId === _config.clientId;
  };
  if (typeof tokenWithClientIds === 'string') {
    // delete old token
    _store.default.set('token', []);
    tokenWithClientIds = [];
  }
  var hasAlreadyToken = tokenWithClientIds.find(getCurrent);
  saveClientId(_config.clientId);
  if (!hasAlreadyToken) {
    tokenWithClientIds.push(createTokenForStorage(accessToken, token));
    _store.default.set('token', tokenWithClientIds); // , currentDomainForCookie);
  } else {
    var updateNewTokenForCurrentClientId = tokenWithClientIds.filter(function (keyPair) {
      return keyPair.clientId !== currentClientId;
    });
    updateNewTokenForCurrentClientId.push(createTokenForStorage(accessToken, token));
    _store.default.set('token', updateNewTokenForCurrentClientId); // , currentDomainForCookie);
  }
};
exports.saveTokenForClientId = saveTokenForClientId;
var getFullToken = function getFullToken() {
  var tokenParsed = _store.default.get('token');
  if (!tokenParsed) {
    return null;
  }
  if (tokenParsed.length === 0) {
    return null;
  }
  if (!_config.clientId) {
    return null;
  }
  var tokenWithClientId;
  if (_config.clientId) {
    try {
      tokenWithClientId = tokenParsed.find(function (token) {
        if (!_config.clientId) {
          return false;
        }
        if (!token.clientId) {
          return false;
        }
        return token.clientId.toLowerCase() === (_config.clientId && _config.clientId.toLowerCase());
      });
    } catch (e) {
      return null;
    }
  }
  if (!tokenWithClientId) {
    return null;
  }
  return tokenWithClientId;
};
exports.getFullToken = getFullToken;
var getCurrentToken = function getCurrentToken() {
  var fullToken = getFullToken();
  return fullToken ? fullToken.token : null;
};
exports.getCurrentToken = getCurrentToken;