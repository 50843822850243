export const previewPropertiesPool = [
  'office365.mail.body',
  'property-mail.body',
  'property-cluedin.mail.body',
  'property-pipedrive.activity.Note',
  'property-hubspot.email.HtmlBody',
];

export const kpiPropertiesPool = [
  'property-organization.kpi-lastActivity',
  'property-organization.kpi-lastEntity',
  'property-organization.kpi-connectivity',
  'property-organization.kpi-communication',
];

export const websitePropertiesPool = [
  'property-organization.website',
  'property-user.website',
  'property-organization.Website',
  'property-user.website',
];

export const socialPropertiesPool = [
  'twitter-friendscount',
  'twitter-followerscount',
  'twitter-statusescount',
  'twitter-favoritescount',
  'twitter-userverified',
];

export const twitterPropertiesPool = [
  'property-user.social.twitter',
  'property-user.social.Twitter',
  'property-organization.social.Twitter',
  'property-organization.social.twitter',
  'property-organization.social+twitter',
];

export const linkedinPropertiesPool = [
  'property-user.social.linkedin',
  'property-user.social.Linkedin',
  'property-user.social.LinkedIn',
  'property-organization.social.linkedin',
  'property-organization.social.Linkedin',
  'property-organization.social.LinkedIn',
  'property-organization.social+linkedin',
];

export const facebookPropertiesPool = [
  'property-user.social.facebook',
  'property-user.social.Facebook',
  'property-organization.social.Facebook',
  'property-organization.social.facebook',
  'property-organization.social+facebook',
];

export const crunchbasePropertiesPool = [
  'property-user.social.crunchbase',
  'property-user.social.Crunchbase',
  'property-organization.social.Crunchbase',
  'property-organization.social.crunchbase',
  'property-organization.social+crunchbase',
];

export const googlePlusPropertiesPool = [
  'property-user.social.googlePlus',
  'property-user.social.GooglePlus',
  'property-organization.social.GooglePlus',
  'property-organization.social.googlePlus',
  'property-organization.social+googlePlus',
];

export const instagramPropertiesPool = [
  'property-user.social.instagram',
  'property-user.social.Instagram',
  'property-organization.social.instagram',
  'property-organization.social.instagram',
  'property-organization.social+instagram',
];

export const githubPropertiesPool = [
  'property-user.social.github',
  'property-user.social.Github',
  'property-user.webProfile.Github',
  'property-organization.social.Github',
  'property-organization.social.github',
  'property-organization.social+github',
];

export const angellistPropertiesPool = [
  'property-user.social.angellist',
  'property-user.social.Angelist',
  'property-user.webProfile.AngelList',
  'property-organization.social.Angelist',
  'property-organization.social.angellist',
  'property-organization.social+angellist',
];

export const wikipediaPropertiesPool = [
  'property-user.social.wikipedia',
  'property-user.social.Wikipedia',
  'property-organization.social.Wikipedia',
  'property-organization.social.wikipedia',
  'property-organization.social+wikipedia',
];

export const skypePropertiesPool = [
  'property-user.social.skype',
  'property-user.social.Skype',
  'property-organization.social.Skype',
  'property-organization.social.skype',
  'property-organization.social+skype',
];

export const youtubePropertiesPool = [
  'property-user.social.youtube',
  'property-user.social.Youtube',
  'property-organization.social.Youtube',
  'property-organization.social.youtube',
  'property-organization.social+youtube',
  'property-organization.social+youTube',
];

export const excludedPropertiesFromPropertyViewer = [
  ...previewPropertiesPool,
  ...kpiPropertiesPool,
  ...socialPropertiesPool,
  ...websitePropertiesPool,
  ...twitterPropertiesPool,
  ...linkedinPropertiesPool,
  ...facebookPropertiesPool,
  ...crunchbasePropertiesPool,
  ...googlePlusPropertiesPool,
  ...instagramPropertiesPool,
  ...githubPropertiesPool,
  ...angellistPropertiesPool,
  ...wikipediaPropertiesPool,
  ...skypePropertiesPool,
  ...youtubePropertiesPool,
];

export const jobTitle = ['property-user.jobTitle', 'property-person.jobTitle'];

export const email = [
  'property-user.emailAddresses',
  'property-user.email',
  'property-person.emailAddresses',
  'property-person.email',
];

export const phoneNumber = [
  'property-user.mobileNumber-E164',
  'property-user.mobileNumber-International',
  'property-user.mobileNumber-Rfc3966',
  'property-user.phoneNumber',
  'property-user.phoneNumber-E164',
  'property-user.phoneNumber-International',
  'property-user.phoneNumber-Rfc3966',
  'property-person.mobileNumber-E164',
  'property-person.mobileNumber-International',
  'property-person.mobileNumber-Rfc3966',
  'property-person.phoneNumber',
  'property-person.phoneNumber-E164',
  'property-person.phoneNumber-International',
  'property-person.phoneNumber-Rfc3966',
];

export const fileType = ['property-file.type'];

export const findOnePropertyValue = (properties, propertiesPool) => {
  const foundPropertyKey = Object.keys(properties).find(
    (k) => propertiesPool.indexOf(k) > -1,
  );
  if (foundPropertyKey) {
    return properties[foundPropertyKey];
  }
  return null;
};

export default excludedPropertiesFromPropertyViewer;
