import { FC } from 'react';
import { List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { ApolloError } from '@apollo/client';

import { columns } from './columns';
import VocabularySource from '../../../../dataCatalog/components/composites/VocabularySource';
import { EntityProperties, VocabularyKey } from '../../types';

type EntityNodePanelListProps = {
  entityProperties: EntityProperties;
  loading: boolean;
  error: ApolloError | undefined;
};

export const EntityNodePanelList: FC<EntityNodePanelListProps> = ({
  entityProperties,
  loading,
  error,
}) => {
  return (
    <div>
      <List
        offsetTop={368}
        hideExpanding
        noSearch
        loading={loading}
        error={error}
        columns={[
          ...columns,
          {
            property: 'source',
            displayName: (
              <FormattedMessage id="module-entityRelationsGraphV2-tableSourceLabel" />
            ),
            Component: ({
              vocabularyKey,
            }: {
              vocabularyKey: VocabularyKey;
            }) => (
              <VocabularySource
                {...vocabularyKey}
                connector={vocabularyKey?.vocabulary?.connector}
                fallBackName={null}
              />
            ),
          },
        ]}
        notFoundProps={{
          message: (
            <FormattedMessage id="module-entityRelationsGraphV2-tableNoPropertiesLabel" />
          ),
          explanation: (
            <FormattedMessage id="module-entityRelationsGraphV2-tableNoPropertiesExplanationLabel" />
          ),
          noCreate: true,
        }}
        data={entityProperties}
        selectable={false}
        showPagination={false}
      />
    </div>
  );
};
