"use strict";

exports.__esModule = true;
exports.protocol = exports.mainGraphQLUrl = exports.mainGraphQLSocket = exports.mainDomain = exports.legacy = exports.graphQL = exports.gqlDomain = exports.authURL = exports.appSubdomain = exports.apiURL = void 0;
var _domain = require("../url/domain");
var _envHelper = require("./envHelper");
var subDomain = (0, _domain.getSubdomain)();
var env = (0, _envHelper.getEnv)();
var envVars = env.variables;
var envVarGQL = envVars.REACT_APP_UI_GQL_URL || '';
var gqlDomain = envVarGQL.endsWith('/') ? envVarGQL : envVarGQL + "/" || '/';
exports.gqlDomain = gqlDomain;
var protocol = env.legacy ? envVars.protocol : envVars.REACT_APP_UI_PROTOCOL;
exports.protocol = protocol;
var mainDomain = env.legacy ? envVars.mainDomain : envVars.REACT_APP_UI_MAIN_DOMAIN;
exports.mainDomain = mainDomain;
var appSubdomain = env.legacy ? envVars.appSubdomain : envVars.REACT_APP_UI_APP_SUBDOMAIN;
exports.appSubdomain = appSubdomain;
var graphQL = env.legacy ? envVars.webApi + "/graphql" : gqlDomain + "api/api/graphql";
exports.graphQL = graphQL;
var mainGraphQLUrl = env.legacy ? '' : gqlDomain + "graphql";
exports.mainGraphQLUrl = mainGraphQLUrl;
var mainGraphQLSocket = env.legacy ? '' : gqlDomain !== '/' ? gqlDomain.replace('http', 'ws') + "graphql" : "ws://" + subDomain + "/graphql";
exports.mainGraphQLSocket = mainGraphQLSocket;
var authURL = env.legacy ? envVars.authApi : gqlDomain + "auth/";
exports.authURL = authURL;
var apiURL = env.legacy ? envVars.webApi : gqlDomain + "api/";
exports.apiURL = apiURL;
var legacy = env.legacy;
exports.legacy = legacy;