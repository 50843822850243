import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import { requestPasswordResetEmail } from '../data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../core/helpers/action';

export const forgotPwdActions = createAsyncAction('PUBLIC_FORGOT_PWD');

export const shouldRequestEmailReset = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(requestPasswordResetEmail, forgotPwdActions),
  {
    onErrorAction: forgotPwdActions.invalid,
  },
);
