import React from 'react';
import { generateFormHoc } from 'uxi-business';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';
import CluedInRegularText from '../../../core/components/composites/generic/CluedInRegularText';
import Spacer from '../../../core/components/composites/generic/Spacer';
import { required, email } from '../../../core/helpers/form';

const SignUp = generateFormHoc(
  'SignUpFormStep1',
  [
    {
      name: 'email',
      label: <FormattedMessage id="module-public-email" />,
      type: 'email',
      validate: [required, email],
    },
  ],
  {
    buttonLabel: (
      <span className="test_signUpForm_button">
        <FormattedMessage id="module-public-signup" />
      </span>
    ),
  },
);

const SignUpForm = ({ error, isFetching, signUp }) => (
  <div className="test_signUpForm_Wrapper">
    <Spacer padding="stack-m">
      <CluedInBigTitle>
        <FormattedMessage id="module-public-signupTitle" />
      </CluedInBigTitle>
      <CluedInRegularText>
        <FormattedMessage id="module-public-signupExplanation" />
      </CluedInRegularText>
      {error && (
        <Alert style={{ marginTop: '16px' }} type="danger" noIcon>
          <p>
            <FormattedMessage id="module-public-signup-SignupGenericError" />
          </p>
        </Alert>
      )}
    </Spacer>
    <SignUp isFetching={isFetching} onClick={signUp} />
  </div>
);

export default SignUpForm;
