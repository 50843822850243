import styled from 'styled-components';

export const EntitySourceWrapperLine = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #d2d2d2;
  margin: 0 12px;
  &:last-child {
    border-bottom: none;
  }
`;
