import { useEffect } from 'react';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { PanelHeader } from '@cluedin/form';
import get from 'lodash/get';

import { GroupedEntitiesPanelWrapper } from './GroupedEntitiesPanel.styles';
import GroupedEntitiesPanelHeader from './GroupedEntitiesPanelHeader/GroupedEntitiesPanelHeader';
import GroupedEntitiesPanelList from './GroupedEntitiesPanelList/GroupedEntitiesPanelList';
import GroupedEntitiesPanelFooter from './GroupedEntitiesPanelFooter/GroupedEntitiesPanelFooter';

const GroupedEntitiesPanel = ({
  onClose,
  groupedEntitiesPanelData,
  groupedEntitiesPanelLoading,
  groupedEntitiesPanelError,
  groupedEntitiesPanelFetchMore,
  isGroupedEntitiesPanelOpen,
  setIsGroupedEntitiesPanelOpen,
  intl,
  edgeType,
  setSelectedNode,
  setSelectedEdge,
  setIsEdgePropertiesPanelOpen,
}) => {
  const handleOnClose = () => {
    setSelectedNode({});
    setSelectedEdge(null);
    onClose();
    setIsEdgePropertiesPanelOpen(false);
    setIsGroupedEntitiesPanelOpen(false);
  };

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, []);

  const incomingEdgesData = get(
    groupedEntitiesPanelData,
    'data.entity.edges.incoming.entries',
  );
  const incomingDataCount = get(
    groupedEntitiesPanelData,
    'data.entity.edges.incoming.totalResults',
  );
  const outgoingEdgesData = get(
    groupedEntitiesPanelData,
    'data.entity.edges.outgoing.entries',
  );
  const outgoingDataCount = get(
    groupedEntitiesPanelData,
    'data.entity.edges.outgoing.totalResults',
  );

  return (
    <>
      <PanelHeader
        title={
          <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesPanelTitle" />
        }
        onClose={handleOnClose}
      />

      <GroupedEntitiesPanelWrapper>
        {incomingEdgesData && (
          <>
            <GroupedEntitiesPanelHeader
              dataCount={incomingDataCount}
              edgeType={edgeType}
              labels={{
                title: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesPanelTitle',
                }),
                edgeType: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListEdgeType',
                }),
                totalRecords: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListTotalRecords',
                }),
                totalEdges: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListTotalIncomingEdges',
                }),
                notFoundMessage: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingNotFoundMessage',
                }),
                notFoundExplanation: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingNotFoundExplanation',
                }),
                groupedRecordsLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesHeaderGroupedRecords',
                }),
              }}
            />

            <GroupedEntitiesPanelList
              totalDataCount={incomingDataCount}
              data={incomingEdgesData}
              loading={groupedEntitiesPanelLoading}
              error={groupedEntitiesPanelError}
              labels={{
                listHeading: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListIncomingEdges',
                }),
                listText: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListIncomingEdgesText',
                }),
                noDataListHeading: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListNoIncomingEdges',
                }),
                notFoundMessage: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListIncomingNotFoundMessage',
                }),
                notFoundExplanation: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListIncomingNotFoundExplanation',
                }),
              }}
              groupedEntitiesPanelFetchMore={groupedEntitiesPanelFetchMore}
            />
          </>
        )}

        {outgoingEdgesData && (
          <>
            <GroupedEntitiesPanelHeader
              dataCount={outgoingDataCount}
              edgeType={edgeType}
              labels={{
                title: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesPanelTitle',
                }),
                edgeType: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListEdgeType',
                }),
                totalRecords: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListTotalRecords',
                }),
                totalEdges: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListTotalOutgoingEdges',
                }),
                notFoundMessage: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingNotFoundMessage',
                }),
                notFoundExplanation: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingNotFoundExplanation',
                }),
                groupedRecordsLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesHeaderGroupedRecords',
                }),
              }}
            />

            <GroupedEntitiesPanelList
              totalDataCount={outgoingDataCount}
              data={outgoingEdgesData}
              loading={groupedEntitiesPanelLoading}
              error={groupedEntitiesPanelError}
              labels={{
                listHeading: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingEdges',
                }),
                listText: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingEdgesText',
                }),
                noDataListHeading: intl.formatMessage({
                  id: 'module-entityRelationsGraph-groupedEntitiesListNoOutgoingEdges',
                }),
              }}
              groupedEntitiesPanelFetchMore={groupedEntitiesPanelFetchMore}
            />
          </>
        )}

        <GroupedEntitiesPanelFooter handleOnClose={handleOnClose} />
      </GroupedEntitiesPanelWrapper>
    </>
  );
};

export default injectIntl(GroupedEntitiesPanel);
