import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  shouldFetchEntities as shouldFetchEntitiesAction,
  abortMerge,
  shouldSubmitMergedEntitiesDelta as shouldSubmitMergedEntitiesDeltaAction,
} from '../../actions';
import { getAllPropsWithPropertySchema } from '../../selectors';
import { shouldFetchSchema as shouldFetchSchemaSelector } from '../../../../action/entity';

const mapToStateProps = ({
  entityMerge: {
    errors,
    isMerging,
    isSelectionValid,
    entities,
    isSubmitting,
    isFetching,
    isDone,
  },
  entity: { isFetchingSchema, schema },
  forms: { propsDelta },
}) => ({
  errors,
  isMerging,
  isSelectionValid,
  entities,
  isSubmitting,
  isFetching,
  isFetchingSchema,
  isDone,
  conflictedProperties: getAllPropsWithPropertySchema(entities, schema),
  propsDelta,
});

const mapDispatchToProps = (dispatch) => ({
  shouldChangeBaseEntity: (newBaseId, ids) => {
    return dispatch(push(`/entity/compare/${newBaseId}?ids=${ids.join(',')}`));
  },
  shouldFetchEntities: (entitiesIds) =>
    dispatch(shouldFetchEntitiesAction(entitiesIds)),
  abortMerge: () => dispatch(abortMerge()),
  shouldFetchSchema: () => dispatch(shouldFetchSchemaSelector()),

  shouldSubmitMergedEntitiesDelta: (entitiesDelta) =>
    dispatch(shouldSubmitMergedEntitiesDeltaAction(entitiesDelta)),
});

export default connect(mapToStateProps, mapDispatchToProps);
