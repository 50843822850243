import { keyframes } from 'styled-components';

export const pulseName = keyframes`
  0% { opacity: 0.16; }
  50% { opacity: 0.32; }
  100% { opacity: 0.16; }
`;

export const pulseKeyframesConfig = {
  '0%': 0.16,
  '50%': 0.32,
  '100%': 0.16,
};

export const pulseEasing = 'cubic-bezier(.3,0,.7,1)';
export const pulseDuration = '1.4s';
export default pulseName;
