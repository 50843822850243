import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { logMessage } from '../../../core/helpers/logger';
import NotFound from '../composites/NotFound';

const EnhancedNotFound = compose(
  lifecycle({
    componentDidMount() {
      logMessage(`Error 404 for : ${window.location.href}`);
    },
  }),
)(NotFound);

const mapToStateProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  onGoToHomeScreen() {
    dispatch(push('/'));
  },
  onBack() {
    dispatch(goBack());
  },
});

export default connect(mapToStateProps, mapDispatchToProps)(EnhancedNotFound);
