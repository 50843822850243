import React from 'react';
import { DivPadding } from 'uxi/Base';
import { injectIntl } from '@cluedin/locale';
import { ListToolbar } from '@cluedin/list-pattern';

import IntegrationDropdown from '../../../../integration/components/composites/IntegrationDropdown';
import IntegrationStatusDropdown from '../../../../integration/components/composites/IntegrationStatusDropdown';

const getUniquedConfigurationList = (configurations = []) => {
  const unique = [];
  configurations.forEach((c) => {
    const foundItem = unique.find((u) => u.Name === c.Name);
    if (!foundItem) {
      unique.push(c);
    }
  });

  return unique;
};
// eslint-disable-next-line react/jsx-indent
const ConfigurationFilters = ({
  configurations,
  query,
  onSearchChange,
  onStatusChange,
  onIntegrationChange,
  intl,
}) => (
  <DivPadding
    padding="S"
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      background: '#fff',
    }}
  >
    <div style={{ flex: 1, display: 'flex', flexFlow: 'row wrap' }}>
      <div style={{ display: 'inline-block', margin: '8px' }}>
        <IntegrationDropdown
          integrations={getUniquedConfigurationList(configurations)}
          onChange={onIntegrationChange}
        />
      </div>

      <div style={{ margin: '8px', display: 'inline-block' }}>
        <IntegrationStatusDropdown onChange={onStatusChange} />
      </div>

      <div
        className="test_ConfigurationList_Search"
        style={{ marginLeft: 'auto', padding: '8px' }}
      >
        <ListToolbar
          onChangeSearch={onSearchChange}
          onSubmitSearch={onSearchChange}
          searchName={query}
          searchInputPlaceholder={intl.formatMessage({
            id: 'module-configuration-searchByIntegrationNamePlaceholder',
          })}
          style={{ padding: 0 }}
        />
      </div>
    </div>
  </DivPadding>
);

ConfigurationFilters.displayName = 'ConfigurationFilters';

export default injectIntl(ConfigurationFilters);
