import { useIntl } from 'react-intl';

const BreadCrumbText = ({ item, LinkComponent }) => {
  const { formatMessage } = useIntl();
  if (typeof item === 'string') {
    return item;
  }

  if (!item?.id && !item?.text) {
    return '';
  }

  if (item?.to) {
    return (
      <LinkComponent className="crumbText" to={item.to}>
        {formatMessage({ id: item?.id ? `${item.id}` : `${item?.text}` })}
      </LinkComponent>
    );
  }

  return formatMessage({ id: item?.id ? `${item.id}` : `${item?.text}` });
};

export default BreadCrumbText;
