import { useId } from 'react';
import styled from 'styled-components';
import { Add } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

const ButtonLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
  }
`;

type AddMoreFileButtonProps = {
  open: () => void;
  disabled: boolean;
  filesLimit: number;
};

export const AddMoreFileButton = ({
  open,
  disabled,
  filesLimit,
}: AddMoreFileButtonProps) => {
  const tooltipId = useId();
  if (!disabled) {
    return (
      <ButtonLink
        disabled={disabled}
        text={
          <ButtonLinkWrapper>
            <Add size={12} />
            <FormattedMessage id="module-fileUpload-addMoreFiles" />
          </ButtonLinkWrapper>
        }
        onClick={open}
      />
    );
  }

  return (
    <TooltipHost
      content={
        <FormattedMessage
          id="module-uploadFile-count-limit-exceeded"
          values={{ filesLimit }}
        />
      }
      id={tooltipId}
      calloutProps={{ gapSpace: 0 }}
    >
      <ButtonLink
        disabled={disabled}
        text={
          <ButtonLinkWrapper>
            <Add size={12} />
            <FormattedMessage id="module-fileUpload-addMoreFiles" />
          </ButtonLinkWrapper>
        }
        onClick={open}
      />
    </TooltipHost>
  );
};
