"use strict";

exports.__esModule = true;
exports.HistoryChangeSetItemsDataGridWrapper = exports.HistoryChangeSetItemsChanges = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var HistoryChangeSetItemsDataGridWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  margin: 10px 15px 10px 0;\n\n  & table {\n    border-bottom: 1px solid rgb(224, 224, 224);\n    border-left: 1px solid rgb(224, 224, 224);\n    border-right: 1px solid rgb(224, 224, 224);\n    font-size: 14px;\n    word-wrap: wrap;\n\n    & td {\n      word-break: break-all;\n    }\n\n    & tr:hover {\n      background: ", " !important;\n    }\n\n    & thead th {\n      background: #f9f9f9;\n      border-color: rgb(224, 224, 224) !important;\n      font-weight: 600;\n    }\n\n    /* Property */\n    & tbody td:nth-of-type(1) {\n      & > div > span > div > div > div {\n        padding: 8px 0;\n        white-space: normal;\n      }\n    }\n\n    /* Change Type */\n    & th:nth-of-type(2) {\n      width: 100px !important;\n      max-width: 100px !important;\n    }\n\n    & tbody td:nth-of-type(2) {\n      & > div > span > div {\n        align-items: center;\n        display: flex;\n        justify-content: center !important;\n        width: 100%;\n\n        & > div {\n          background: ", ";\n          border-radius: 12px;\n          color: ", ";\n          justify-content: center !important;\n          padding: 0.2rem 0.8rem;\n          width: min-content;\n          max-width: min-content;\n          min-width: min-content;\n        }\n      }\n    }\n\n    /* Old Value, New Value */\n    & tbody td:nth-of-type(3),\n    tbody td:nth-of-type(4) {\n      & > div > span > div > div > span {\n        padding: 8px 0;\n        white-space: normal;\n      }\n    }\n  }\n\n  @supports (-moz-appearance: none) {\n    & th {\n      position: static;\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.palette.themeLighterAlt;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.palette.themeLighterAlt;
}, function (_ref3) {
  var themePrimary = _ref3.themePrimary;
  return themePrimary;
});
exports.HistoryChangeSetItemsDataGridWrapper = HistoryChangeSetItemsDataGridWrapper;
var HistoryChangeSetItemsChanges = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  padding-right: 12px;\n  width: 100%;\n\n  & span {\n    display: flex;\n    justify-content: ", ";\n  }\n"])), function (_ref4) {
  var value = _ref4.value;
  return value ? {} : 'center';
});
exports.HistoryChangeSetItemsChanges = HistoryChangeSetItemsChanges;