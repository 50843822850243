import React from 'react';
import styled from 'styled-components';
import IconPlaceholder from '../IconPlaceholder';

const SocialMenuLinkItem = styled.div`
  opacity: 0.6;
  margin: 0 2px;
  :hover {
    opacity: 0.9;
  }
`;

const SocialMenuItemLoader = ({ iconSize = 16, color }) => (
  <li>
    <SocialMenuLinkItem>
      <IconPlaceholder size={iconSize} color={color} />
    </SocialMenuLinkItem>
  </li>
);

SocialMenuItemLoader.displayName = 'SocialMenuItemLoader';

export default SocialMenuItemLoader;
