import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { get } from 'lodash';
import { EntityTypes } from './fragments';

export const GET_ENTITY_TYPE_LAYOUT_CONFIGURATIONS = gql`
  query entityTypeLayoutConfigurations(
    $searchName: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    management {
      id
      entityTypeConfigurations(
        searchName: $searchName
        pageNumber: $pageNumber
        pageSize: $pageSize
      ) {
        data {
          ...EntityTypeConfiguration
        }
        total
      }
    }
  }
  ${EntityTypes.fragments.entityTypeConfiguration}
`;

export const useEntityTypeConfigurations = (initialVars = {}) => {
  const { data, loading, error } = useQuery(
    GET_ENTITY_TYPE_LAYOUT_CONFIGURATIONS,
    {
      variables: {
        ...initialVars,
      },
    },
  );

  const entityTypeLayoutConfigurations = get(
    data,
    'management.entityTypeConfigurations',
  );

  return [entityTypeLayoutConfigurations, loading, error];
};

export default {
  useEntityTypeConfigurations,
};
