import React from 'react';
import moment from 'moment';
// import Tooltip from 'rc-tooltip';
import Flex from 'uxi/Layout/Flex';
import { Trianglearrow, Trianglearrowup } from 'uxi/Icons';

import styled from 'styled-components';
import { lt } from '../../../../core/helpers/numbers';

// const G = styled.g`
//   circle {
//     fill: ${({ color, theme: { palette } }) => color || palette.primary.light};
//     stroke: white;
//     stroke-width: 1;
//   }
// `;
const ToolTip = styled.div`
  z-index: 9999;
  background: #020202;
  background: rgba(0, 0, 0, 0.85);
  color: #dfdfdf;
  padding: 2px 2px 2px 6px;
  border-radius: 3px;
  font-size: 0.3rem;
  overflow: visible;
`;

/* eslint-disable indent */
const G = styled.g`
  &:hover > circle {
    stroke-width: 0.1;
    fill: white;
    stroke: black;
  }
  & + foreignObject,
  & + foreignObject + line {
    display: none;
  }
  ${({ showDetails }) =>
    showDetails
      ? `
      &:hover + foreignObject,
      &:hover + foreignObject + line {
        z-index: 99;
        display: block;
      }
    `
      : ''};
  opacity: 1;
  visibility: visible;

  ${({ show }) => (show === false ? 'opacity: 0; visibility: collapse' : '')};
`;
/* eslint-enable indent */

const Circle = styled.circle`
  fill: ${({ color, theme: { palette } }) => color || palette.primary.light};
  stroke: white;
  stroke-width: ${({ showDetails }) => (showDetails ? 0.25 : 1)};
`;

const DotsForPath = ({
  showDetails,
  values,
  min,
  max,
  MINH,
  MAXH,
  MINW,
  MAXW,
  color,
  vectorEffect,
  r,
  dateRange,
  name,
  show,
}) => {
  return values.map(({ value: p, date }, i, list) => {
    const minDateTS = dateRange.start.format('X');
    const maxDateTS = dateRange.end.format('X');
    const totalDateDiff = maxDateTS - minDateTS;
    const d = moment(date);
    const dTS = d.format('X');
    // console.log('minDateTS', minDateTS);
    // console.log('d', d);
    // console.log('dTS', dTS);
    // console.log('maxDateTS', maxDateTS);
    // const x = lt(i, 0, values.length - 1, MINW, MAXW);
    const x = lt(dTS - minDateTS, 0, totalDateDiff, MINW, MAXW);

    const diff = i === 0 ? 0 : p - list[i - 1].value;
    const diffImprove = diff >= 0;

    const diffColor = diffImprove ? 'green' : 'red';
    const DiffIcon = diffImprove ? Trianglearrowup : Trianglearrow;
    const toolTipContent = (
      <Flex>
        <Flex style={{ justifyContent: 'flex-start', marginRight: '4px' }}>
          <div
            style={{
              width: '4px',
              height: '4px',
              background: color || 'rgb(72,99,114)',
              marginRight: '2px',
            }}
          />
          {name}
        </Flex>
        <Flex style={{ flexGrow: 9, justifyContent: 'space-between' }}>
          {`${p.toFixed(2)} %`}
          <small>({d.fromNow()})</small>
          <Flex>
            <DiffIcon size={6} color={diffColor} />
            {diffImprove ? ' + ' : ' '}
            {diff.toFixed(2)} {' % '}
          </Flex>
        </Flex>
      </Flex>
    );

    /* return (
          <foreignObject>
            <Tooltip
              placement="top"
              trigger={['hover', 'click']}
              overlay={(
                <div>
                  <div>{p} %</div>
                  <div>{d.fromNow()}</div>
                </div>
              )}
            >
              <Circle
                vectorEffect={vectorEffect || 'default'}
                r={r || (showDetails ? 1 : 2)}
                color={color}
                // cx={lt(i, 0, values.length - 1, MINW, MAXW)}
                cx={x}
                cy={MAXH - lt(p, min, max, MINH, MAXH)}
              />
            </Tooltip>
          </foreignObject>
        ); */

    // const circleRef = React.createRef();

    const y = MAXH - lt(p, min, max, MINH, MAXH);

    return (
      <React.Fragment>
        <G showDetails={showDetails} show={show}>
          <Circle
            // ref={circleRef}
            vectorEffect={vectorEffect || 'default'}
            r={r || (showDetails ? 1 : 2)}
            color={color}
            // cx={lt(i, 0, values.length - 1, MINW, MAXW)}
            cx={x}
            cy={y}
          />
        </G>
        <foreignObject
          // x={x - 70}
          // y={y - 32}
          x={0}
          y={-32}
          width={116}
          height={10}
          style={{ overflow: 'visible' }}
        >
          {/* <div xmlns="http://www.w3.org/1999/xhtml"> */}
          <ToolTip>{toolTipContent}</ToolTip>
          {/* </div> */}
        </foreignObject>
        <line
          x1={58}
          y1={-22}
          style={{ strokeWidth: 0.2, stroke: 'black' }}
          y2={y}
          x2={x}
        />
      </React.Fragment>
    );
  });
};

export default DotsForPath;
