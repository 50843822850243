import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  shouldFetchAllIntegrations,
  shouldFetchAllIntegrationsWithDataSourceAndEnricher,
} from '../../actions';

const mapToSelectProps = ({
  integration: {
    allIntegrations,
    isFetchingAllIntegrations,
    invalidAllIntegrations,
  },
}) => ({
  integrations: allIntegrations,
  isFetchingIntegrations: isFetchingAllIntegrations,
  invalidAllIntegrations,
});

const mapToSelectPropsWithAll = ({
  integration: {
    allIntegrationsWithNoFilters,
    isFetchingAllIntegrationsNoFilters,
    invalidAllIntegrationsNoFilters,
  },
}) => ({
  integrations: allIntegrationsWithNoFilters,
  isFetchingIntegrations: isFetchingAllIntegrationsNoFilters,
  invalidAllIntegrations: invalidAllIntegrationsNoFilters,
});

const mapDispatchToPropsWithAll = (dispatch) => ({
  fetchProviders: () => {
    dispatch(shouldFetchAllIntegrationsWithDataSourceAndEnricher());
  },
});

const mapDispatchToProps = (dispatch) => ({
  fetchProviders: () => {
    dispatch(shouldFetchAllIntegrations());
  },
});

export const withAllIntegrationsIncludingConnectorAndEnricher = (Comp) => {
  return connect(
    mapToSelectPropsWithAll,
    mapDispatchToPropsWithAll,
  )(({ fetchProviders, ...rest }) => {
    useEffect(() => {
      fetchProviders();
    }, [fetchProviders]);

    return <Comp {...rest} />;
  });
};

export const withIntegration = (Comp) => {
  return connect(
    mapToSelectProps,
    mapDispatchToProps,
  )(({ fetchProviders, ...rest }) => {
    useEffect(() => {
      fetchProviders();
    }, [fetchProviders]);

    return <Comp {...rest} />;
  });
};

export default withIntegration;
