import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';
import noAccessIllustration from '../../assets/no-access-illustration.png';

const DefaultMessage = ({ feature }) => (
  <>
    <FormattedMessage id="module-error-noAccessToThisPagePart1" />
    {!Array.isArray(feature) || (
      <FormattedMessage id="module-error-noAccessToThisPagePart2" />
    )}
    .
  </>
);

const AccessDeniedComposite = ({ onBackToHomeClick, feature, message }) => (
  <div style={{ margin: '0 auto', textAlign: 'center' }}>
    <div style={{ marginTop: '50px' }}>
      <img alt="Access Denied" src={`${noAccessIllustration}`} />
    </div>
    <div style={{ padding: '15px', fontSize: '20px' }}>
      {message ? message : <DefaultMessage feature={feature} />}
    </div>
    <div style={{ paddingTop: '15px' }}>
      <PrimaryButton
        click={onBackToHomeClick}
        text={<FormattedMessage id="module-error-goHome" />}
      />
    </div>
  </div>
);

export default AccessDeniedComposite;
