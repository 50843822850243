import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';

import {
  ExplainLogSummaryTableWrapper,
  ExplainLogSummaryTableHead,
  ExplainLogSummaryTableRow,
  ExplainLogSummaryTableHeading,
  ExplainLogSummaryTableBody,
  ExplainLogSummaryTableData,
  ExplainLogSummaryTableScroll,
} from './ExplainLogSummaryTable.styles';

const ExplainLogSummaryTable = ({ columnTitles, rows, tableTitle }) => {
  const theme = useTheme();

  return (
    <ExplainLogSummaryTableScroll>
      <div
        style={{
          padding: '15px 0',
        }}
      >
        <strong>
          <FormattedMessage id="module-explainLog-summaryTableTitle" />: &nbsp;
          {tableTitle}
        </strong>
      </div>
      <ExplainLogSummaryTableWrapper data-test="entity_explainLog_explainLogSummaryTable">
        <ExplainLogSummaryTableHead>
          <ExplainLogSummaryTableRow theme={theme}>
            {columnTitles &&
              (columnTitles || []).map((column, columnIndex) => (
                <ExplainLogSummaryTableHeading key={columnIndex}>
                  {column}
                </ExplainLogSummaryTableHeading>
              ))}
          </ExplainLogSummaryTableRow>
        </ExplainLogSummaryTableHead>
        <ExplainLogSummaryTableBody>
          {rows &&
            (rows || []).map((row, rowIndex) => (
              <ExplainLogSummaryTableRow key={rowIndex}>
                {(row || []).map((rowItem, rowItemIndex) => (
                  <ExplainLogSummaryTableData key={rowItemIndex}>
                    {rowItem}
                  </ExplainLogSummaryTableData>
                ))}
              </ExplainLogSummaryTableRow>
            ))}
        </ExplainLogSummaryTableBody>
      </ExplainLogSummaryTableWrapper>
    </ExplainLogSummaryTableScroll>
  );
};

export default ExplainLogSummaryTable;

ExplainLogSummaryTable.propTypes = {
  columnTitles: PropTypes.object,
  rows: PropTypes.object,
  rows: PropTypes.string,
};

ExplainLogSummaryTable.defaultProps = {
  columnTitles: {},
  rows: {},
  tableTitle: 'Properties table',
};
