import React from 'react';
import PropTypes from 'prop-types';
import ComponentWithPadding from 'uxi/Base/ComponentWithPadding';

class PageStructureContainer extends ComponentWithPadding {
  render() {
    const { children } = this.props;
    const mergedStyle = this.getStyle();

    return <div style={mergedStyle}>{children}</div>;
  }
}

PageStructureContainer.propTypes = {
  children: PropTypes.node,
};

export default PageStructureContainer;
