export const truncateString = (label: string, length?: number): string => {
  const defaultLength = 17;

  if (!length) {
    length = defaultLength;
  }

  if (label?.length > length) {
    return label.substring(0, length) + '...';
  }

  return label;
};
