import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';
import {
  DataSourceDatabase,
  DataCleaningTopologyIco,
  MergeIco,
  ManuallyAddedIcon,
} from '@cluedin/svgs';
import { ReactComponent as DeduplicationIcon } from '@cluedin/svgs/icons/deduplication.svg';

import {
  RecordPanelHeaderIconWrapper,
  RecordPanelHeaderConnectorIcon,
  RecordPanelHeaderCleanIconWrapper,
  RecordPanelHeaderDeduplicationIconWrapper,
  RecordPanelHeaderManualMergeIconWrapper,
  RecordPanelHeaderManuallyAddedIconWrapper,
} from './RecordPanelHeaderIcon.styles';

const RecordPanelHeaderIcon = ({
  provider,
  isCleanProject,
  isLegacyCleanProject,
  isDeduplicationProject,
  isManualMerge,
  isManuallyAdded,
  isRecord,
  isManuallyAddedFileEntry,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const themeWhite = theme?.palette?.white;

  let iconToShow = <DataSourceDatabase style={{ height: 48, width: 48 }} />;
  if (provider?.icon || provider?.providerIcon) {
    iconToShow = (
      <RecordPanelHeaderConnectorIcon
        dangerouslySetInnerHTML={{
          __html: provider?.icon ?? provider?.providerIcon,
        }}
      />
    );
  }
  if (isManualMerge) {
    iconToShow = (
      <RecordPanelHeaderManualMergeIconWrapper>
        <MergeIco style={{ height: 24, width: 24 }} />
      </RecordPanelHeaderManualMergeIconWrapper>
    );
  }
  if (isCleanProject || isLegacyCleanProject) {
    iconToShow = (
      <RecordPanelHeaderCleanIconWrapper>
        <DataCleaningTopologyIco style={{ height: 30, width: 30 }} />
      </RecordPanelHeaderCleanIconWrapper>
    );
  }
  if (isDeduplicationProject) {
    iconToShow = (
      <RecordPanelHeaderDeduplicationIconWrapper>
        <DeduplicationIcon style={{ height: 30, width: 30 }} />
      </RecordPanelHeaderDeduplicationIconWrapper>
    );
  }
  if (isManuallyAdded || isManuallyAddedFileEntry) {
    iconToShow = (
      <RecordPanelHeaderManuallyAddedIconWrapper>
        <ManuallyAddedIcon style={{ height: 30, width: 30 }} />
      </RecordPanelHeaderManuallyAddedIconWrapper>
    );
  }

  return (
    <RecordPanelHeaderIconWrapper
      data-test={`entityTopology-recordPanel-headerIcon-${provider?.name}`}
      themePrimary={themePrimary}
      themeWhite={themeWhite}
      isCleanProject={isCleanProject}
      isLegacyCleanProject={isLegacyCleanProject}
      isDeduplicationProject={isDeduplicationProject}
      isManualMerge={isManualMerge}
      isManuallyAdded={isManuallyAdded}
      isManuallyAddedFileEntry={isManuallyAddedFileEntry}
    >
      {iconToShow}
    </RecordPanelHeaderIconWrapper>
  );
};

export default RecordPanelHeaderIcon;

RecordPanelHeaderIcon.propTypes = {
  provider: PropTypes.object,
  isCleanProject: PropTypes.bool,
  isLegacyCleanProject: PropTypes.bool,
  isDeduplicationProject: PropTypes.bool,
  isManualMerge: PropTypes.bool,
  isManuallyAdded: PropTypes.bool,
  isManuallyAddedFileEntry: PropTypes.bool,
  isRecord: PropTypes.bool,
};
