import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/datasource/:id',
    claims: {
      'management.annotation': RACI_LEVELS.INFORMED,
      'integration.datasourcegroups': RACI_LEVELS.INFORMED,
      'integration.configuredintegrations': RACI_LEVELS.INFORMED,
      'management.annotation': RACI_LEVELS.INFORMED,
      'consume.exporttargets': RACI_LEVELS.INFORMED,
      'management.datacatalog': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"DataSourceDetailPage" */
        './components/pages/DataSourceDetailPage'
      ),
    ),
  },
  {
    path: '/dataset/detail/:id',
    claims: {
      'management.annotation': RACI_LEVELS.INFORMED,
      'integration.datasourcegroups': RACI_LEVELS.INFORMED,
      'integration.configuredintegrations': RACI_LEVELS.INFORMED,
      'management.annotation': RACI_LEVELS.INFORMED,
      'consume.exporttargets': RACI_LEVELS.INFORMED,
      'management.datacatalog': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"DataSetDetailPage" */
        './components/pages/DataSetDetailPage'
      ),
    ),
  },
  {
    path: '/',
    claims: {
      'integration.datasourcegroups': RACI_LEVELS.INFORMED,
      'integration.configuredintegrations': RACI_LEVELS.INFORMED,
      'management.annotation': RACI_LEVELS.INFORMED,
      'consume.exporttargets': RACI_LEVELS.INFORMED,
      'management.datacatalog': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"DataSourceSetListPage" */
        './components/pages/DataGroupsListPage'
      ),
    ),
  },
];
