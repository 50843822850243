import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FormattedMessage } from '@cluedin/locale';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import {
  shouldFetchUserAccount,
  fetchAllUsers as fetchAllUsersAction,
} from '../../actions';
import PageLoader from '../../../core/components/composites/PageLoader';
import {
  PageStructureContainer,
  PageStructureHeader,
} from '../../../core/components/composites/PageStructure';
import { Tab, TabList } from '../../../core/components/composites/Tabs';
import AdminUserRolesPage from './AdminUserRolesPage';
import AdminUserDynamicRolesPage from './AdminUserDynamicRolesPage';
import AdminUserIntegrationPage from './AdminUserIntegrationPage';

const AdminUserSettingsPageWrapper = (props) => {
  const {
    currentSelectedUser,
    currentUser,
    navigateToRoles,
    id,
    goBack,
    navigateToIntegration,
    location,
    match,
    navigateToDynamicRoles,
  } = props;
  const isRoleSelected =
    location.pathname === `/admin/settings/users/settings/${id}` ||
    location.pathname === `/admin/settings/users/settings/${id}/`;

  const isIntegrationSelected =
    location.pathname.indexOf(
      `/admin/settings/users/settings/${id}/integrations`,
    ) > -1;

  const isDynamicRoles =
    location.pathname.indexOf(`/admin/settings/users/settings/${id}/roles`) >
    -1;

  const headerTitle = currentSelectedUser.Entity
    ? currentSelectedUser?.Entity?.name
    : currentSelectedUser?.Account?.UserName ?? '';

  return (
    <div>
      <PageStructureHeader
        title={`Administrator Settings for ${headerTitle}`}
      />
      <TabList isMainStyle>
        <Tab onClick={goBack}>
          <span className="test_Administration_User_UserSettings_Back">
            ←&nbsp;
            <FormattedMessage id="module-user-back" />
          </span>
        </Tab>
        <Tab
          selected={isRoleSelected}
          onClick={() => {
            navigateToRoles(id);
          }}
        >
          <span className="test_Administration_User_UserSettings_Settings">
            <FormattedMessage id="module-user-settings" />
          </span>
        </Tab>
        <Tab
          selected={isDynamicRoles}
          onClick={() => {
            navigateToDynamicRoles(id);
          }}
        >
          <span className="test_Administration_User_UserSettings_Roles">
            <FormattedMessage id="module-user-roles" />
          </span>
        </Tab>
        <Tab
          selected={isIntegrationSelected}
          onClick={() => {
            navigateToIntegration(id);
          }}
        >
          <span className="test_Administration_User_UserSettings_Permissions">
            <FormattedMessage id="module-user-integrationPermissions" />
          </span>
        </Tab>
      </TabList>
      <PageStructureContainer>
        <Route
          path={`${match.url}/`}
          exact
          render={(realProps) => (
            <AdminUserRolesPage
              user={currentSelectedUser}
              currentUser={currentUser}
              {...realProps}
            />
          )}
        />
        <Route
          path={`${match.url}/roles`}
          render={(realProps) => (
            <AdminUserDynamicRolesPage
              user={currentSelectedUser}
              userId={id}
              currentUser={currentUser}
              {...realProps}
            />
          )}
        />
        <Route
          path={`${match.url}/integrations`}
          render={(realProps) => (
            <AdminUserIntegrationPage
              user={currentSelectedUser}
              currentUser={currentUser}
              {...realProps}
            />
          )}
        />
      </PageStructureContainer>
    </div>
  );
};
AdminUserSettingsPageWrapper.displayName = 'AdminUserSettingsPageWrapper';

AdminUserSettingsPageWrapper.propTypes = {
  currentSelectedUser: PropTypes.object,
  currentUser: PropTypes.object,
  navigateToRoles: PropTypes.func,
  id: PropTypes.string,
  goBack: PropTypes.func,
  navigateToIntegration: PropTypes.func,
  location: PropTypes.object,
};
AdminUserSettingsPageWrapper.defaultProps = {
  currentSelectedUser: {},
  currentUser: {},
  navigateToRoles: () => {},
  id: '',
  children: PropTypes.any,
  goBack: () => {},
  navigateToIntegration: () => {},
  location: {},
};

const EnhancedUserSettingsPageWrapper = compose(
  lifecycle({
    componentDidMount() {
      const { fetchUserAccount, fetchAllUsers, id } = this.props;
      fetchUserAccount(id);
      fetchAllUsers();
    },
  }),
  branch(
    (props) =>
      !props.currentSelectedUser ||
      props.isFetchingCurrentSelectedUser ||
      props.isFetchingAllUsers,
    renderComponent(PageLoader),
  ),
)(AdminUserSettingsPageWrapper);

const mapToStateProps = (
  {
    user: {
      currentSelectedUser,
      currentUser,
      isInvalidCurrentSelectedUser,
      isFetchingCurrentSelectedUser,
      isFetchingAllUsers,
    },
  },
  {
    match: {
      params: { id },
    },
  },
) => ({
  id,
  currentSelectedUser,
  currentUser,
  isInvalidCurrentSelectedUser,
  isFetchingCurrentSelectedUser,
  isFetchingAllUsers,
});

const mapToDispatchProps = (dispatch) => ({
  navigateToRoles: (id) => {
    dispatch(push(`/admin/settings/users/settings/${id}`));
  },
  navigateToIntegration: (id) => {
    dispatch(push(`/admin/settings/users/settings/${id}/integrations`));
  },
  navigateToDynamicRoles: (id) => {
    dispatch(push(`/admin/settings/users/settings/${id}/roles`));
  },
  goBack: () => {
    dispatch(push('/admin/settings/users/all'));
  },
  fetchUserAccount: (id) => {
    dispatch(shouldFetchUserAccount(id));
  },
  fetchAllUsers: () => {
    dispatch(fetchAllUsersAction());
  },
});

export default connect(
  mapToStateProps,
  mapToDispatchProps,
)(EnhancedUserSettingsPageWrapper);
