import React from 'react';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Widget } from 'uxi/Widget';
import { FormattedMessage } from '@cluedin/locale';
import { shouldFetchAllProviderForUser } from '../../actions';
import ConfigurationListContainer from '../../../configuration/components/containers/lists/ConfigurationListContainer';
import UserPermissionForConfigurationCell from './UserPermissionForConfigurationCell';

const AdminUserIntegrationsContainer = ({
  providerListForUser = [],
  user = {},
}) => (
  <Widget
    style={{
      background: '#fff',
    }}
    title={<FormattedMessage id="module-user-integrationAccess" />}
  >
    <div style={{ minHeight: '400px' }}>
      <ConfigurationListContainer
        disableMulti
        ActionTitle={<FormattedMessage id="module-user-permissions" />}
        Action={UserPermissionForConfigurationCell}
        extraProps={{
          user,
          providerListForUser,
        }}
      />
    </div>
  </Widget>
);

AdminUserIntegrationsContainer.displayName = 'AdminUserIntegrationsContainer';

const EnhancedAdminUserIntegrationsContainer = compose(
  lifecycle({
    componentDidMount() {
      const { fetchAllProviderForUser, user } = this.props;

      fetchAllProviderForUser(user.Account.Id);
    },
  }),
)(AdminUserIntegrationsContainer);

const mapToSelectProps = (
  { user: { isFetchingAllProvidersForUser, allProvidersByUser } },
  { user },
) => {
  const isFetchingProviderListForUser =
    isFetchingAllProvidersForUser[user.Account.Id];
  const providerListForUser = (
    allProvidersByUser[user.Account.Id] || []
  ).filter((r) => r.UserId === user.Account.Id);

  return {
    isFetching: isFetchingProviderListForUser,
    providerListForUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllProviderForUser: (id) => {
    dispatch(shouldFetchAllProviderForUser(id));
  },
});

export default connect(
  mapToSelectProps,
  mapDispatchToProps,
)(EnhancedAdminUserIntegrationsContainer);
