import { useQuery, gql } from '@apollo/client';
import { Catalog } from './fragments';
import { VocabKeyType } from '../types/VocabKeyType';

const GET_VOCABULARY_NAMES_BY_KEYS = gql`
  query getVocabularyKeysByKeys($keys: [String]) {
    management {
      getVocabularyKeysByKeys(keys: $keys) {
        total
        data {
          ...VocabularyKey
        }
      }
    }
  }
  ${Catalog.fragments.vocabularyKey}
`;

type InputKeys = string[];

type Data = {
  management: {
    getVocabularyKeysByKeys: {
      total: number;
      data: VocabKeyType[];
    };
  };
};

export const useVocabKeysByKeys = (keys: InputKeys, skip: boolean) => {
  const { data, loading, error, refetch } = useQuery<Data, { keys: InputKeys }>(
    GET_VOCABULARY_NAMES_BY_KEYS,
    {
      fetchPolicy: 'network-only',
      skip,
      variables: {
        keys,
      },
    },
  );

  const vocabularyKeys: VocabKeyType[] =
    data?.management?.getVocabularyKeysByKeys?.data ?? [];

  return { vocabularyKeys, loading, error, refetch };
};
