import React from 'react';
import styled from 'styled-components';

const PrivacyPageMain = styled.div`
  height: 100%;
  background: #fff;
  padding: 0;
  margin: 0;
  * {
    box-sizing: border-box;
  }
`;

const PrivacyPageMainContent = styled.div`
  padding: 0;
  margin: 0 auto;
  height: 100%;
`;

const FrameWrapperPage = ({ children }) => (
  <PrivacyPageMain className="cluedinFrame">
    <PrivacyPageMainContent className="cluedinFrame-content">
      {children}
    </PrivacyPageMainContent>
  </PrivacyPageMain>
);

export default FrameWrapperPage;
