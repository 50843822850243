import React, { useEffect } from 'react';

import { withGroupedEntitiesPanelWorkflow } from '../../hocs/withGroupedEntitiesPanelWorkflow';

const GroupedEntitiesPanelContainer = ({
  openGroupedEntitiesPanelWorkflow,
  groupedEntitiesPanelData,
  groupedEntitiesPanelLoading,
  groupedEntitiesPanelError,
  groupedEntitiesPanelFetchMore,
  isGroupedEntitiesPanelOpen,
  setIsGroupedEntitiesPanelOpen,
  edgeType,
  setSelectedNode,
  setSelectedEdge,
  setIsEdgePropertiesPanelOpen,
}) => {
  useEffect(() => {
    if (isGroupedEntitiesPanelOpen) {
      openGroupedEntitiesPanelWorkflow();
    }
  }, [isGroupedEntitiesPanelOpen]);

  return <span />;
};

export default withGroupedEntitiesPanelWorkflow(GroupedEntitiesPanelContainer);
