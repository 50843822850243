import React from 'react';
import moment from 'moment';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';
import { FormattedMessage } from '@cluedin/locale';
import { NotFoundComponent } from '@cluedin/list-pattern';
import { useTheme } from '@cluedin/theme';
import { Notification as NotificationIcon } from 'uxi/Icons';
import { Button, GqlErrorMessages } from '@cluedin/form';
import Notification, { NotificationList } from './notification';
import NotificationGhostLoading from './notification/NotificationGhostLoading';

const NotificationIconWithSize = () => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <div style={{ marginBottom: '24px' }}>
      <NotificationIcon color={themePrimary} size={64} />
    </div>
  );
};

const NotificationListContainer = ({
  notifications,
  loadingNotifications,
  errorsNotifications,
  showMore,
  onClose,
  loadMoreNotifications,
}) => {
  if (errorsNotifications) {
    return <GqlErrorMessages error={errorsNotifications} />;
  }

  if (loadingNotifications) {
    return (
      <>
        <NotificationGhostLoading key={0} />
        <NotificationGhostLoading key={1} />
        <NotificationGhostLoading key={2} />
        <NotificationGhostLoading key={3} />
      </>
    );
  }

  if (!notifications || notifications.length === 0) {
    return (
      <>
        <NotFoundComponent
          IconComponent={NotificationIconWithSize}
          message={
            <FormattedMessage id="module-notifications-noNotifications" />
          }
          explanation={
            <FormattedMessage id="module-notifications-noNotificationsExpl" />
          }
          noCreate
        />
      </>
    );
  }

  return (
    <NotificationList>
      {notifications.map((notification, index) => {
        const Icon = getAppropriateIcon(notification.icon || 'Help');

        return (
          <Notification
            tabIndex={index}
            onClose={onClose}
            key={notification.id}
            source={notification.source}
            title={notification.title}
            description={notification.description}
            date={
              notification.created ? moment(notification.created).fromNow() : ''
            }
            IconComponent={Icon}
            alertType={notification.alertType}
            actions={notification.actions || []}
          />
        );
      })}
      {showMore && (
        <div style={{ margin: '0 12px 12px 12px' }}>
          <Button
            tabIndex="-1"
            isFullWidth
            onClick={loadMoreNotifications}
            text={
              <FormattedMessage id="module-notifications-notifcationLoadMore" />
            }
          />
        </div>
      )}
    </NotificationList>
  );
};

export default NotificationListContainer;
