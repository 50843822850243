import { handleActions } from 'redux-actions';
import {
  shouldFetchDataBreachActions,
  shouldFetchMoreDataBreachActions,
  shouldCreateDataBreachActions,
  addConfigurationSelection,
  clearConfigurationSelection,
  clearSavinDataBreachgResult,
} from './actions';

const initialState = {
  shouldSelectConfiguration: false,

  allDataBreaches: [],
  isFetchingDataBreaches: false,
  invalidDataBreaches: false,
  isFetchingMoreDataBreaches: false,
  pageNumber: 0,

  isCreatingDataBreach: false,
  doneCreatingDataBreach: false,
  invalidCreatingDataBreach: false,
};

export default handleActions(
  {
    [addConfigurationSelection]: (state) => ({
      ...state,
      shouldSelectConfiguration: true,
    }),
    [clearConfigurationSelection]: (state) => ({
      ...state,
      shouldSelectConfiguration: false,
    }),
    [shouldFetchDataBreachActions.request]: (state) => ({
      ...state,
      isFetchingDataBreaches: true,
      invalidDataBreaches: false,
    }),
    [shouldFetchDataBreachActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingDataBreaches: false,
      allDataBreaches: payload,
      invalidDataBreaches: false,
    }),
    [shouldFetchDataBreachActions.invalid]: (state) => ({
      ...state,
      isFetchingDataBreaches: false,
      invalidDataBreaches: true,
    }),
    [shouldFetchMoreDataBreachActions.request]: (
      state,
      { payload: { page } },
    ) => ({
      ...state,
      isFetchingMoreDataBreaches: true,
      invalidDataBreaches: false,
      pageNumber: page,
    }),
    [shouldFetchMoreDataBreachActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingMoreDataBreaches: false,
      allDataBreaches: [...state.allDataBreaches, ...payload],
      invalidDataBreaches: false,
    }),
    [shouldFetchMoreDataBreachActions.invalid]: (state) => ({
      ...state,
      isFetchingMoreDataBreaches: false,
      invalidDataBreaches: true,
    }),
    [shouldCreateDataBreachActions.request]: (state) => ({
      ...state,
      isCreatingDataBreach: true,
      doneCreatingDataBreach: false,
      invalidCreatingDataBreach: false,
    }),
    [shouldCreateDataBreachActions.receive]: (state) => ({
      ...state,
      isCreatingDataBreach: false,
      doneCreatingDataBreach: true,
      invalidCreatingDataBreach: false,
    }),
    [shouldCreateDataBreachActions.invalid]: (state) => ({
      ...state,
      isCreatingDataBreach: false,
      doneCreatingDataBreach: false,
      invalidCreatingDataBreach: true,
    }),
    [clearSavinDataBreachgResult]: (state) => ({
      ...state,
      isCreatingDataBreach: false,
      doneCreatingDataBreach: false,
      invalidCreatingDataBreach: true,
    }),
  },
  initialState,
);
