import { FileUploadRequestWithProgress } from '../../../types';
import { FileUploadProgressList } from './FileUploadProgressList';

type fileUploadProgressContext = {
  fileUploadProgress: FileUploadRequestWithProgress[];
  resetFiles: () => void;
  markAsViewed: (ids: string[]) => void;
};

type FileUploadProgressProps = {
  fileUploadProgressContext: fileUploadProgressContext;
  onClose: () => void;
};

export const FileUploadProgress = ({
  fileUploadProgressContext,
  onClose,
}: FileUploadProgressProps) => {
  return (
    <>
      <FileUploadProgressList
        fileUploadProgress={fileUploadProgressContext.fileUploadProgress || []}
        onClose={onClose}
      />
    </>
  );
};
