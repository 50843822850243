import styled from 'styled-components';
import { Badge } from 'uxi/Badge';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';
import { Externallink, Pencil } from 'uxi/Icons';
import EntityIcon from '../../../../entity/components/composites/EntityIcon';
import DefaultEntityDates from '../../../components/composites/DefaultEntityDates';
import { HintBadge } from '../../../../../shared/components/HintBadge';

const DefaultEntityHeaderWrapper = styled.div`
  position: relative;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 0;
  border: 1px solid #ececec;
  margin-top: 24px;
  padding: 24px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: start;
`;

const DefaultInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EntityTitle = styled.div`
  color: #2f2f32;
  font-weight: 600;
  font-size: 20px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 16px;
  margin-right: 12px;
`;

const IconWrapper = styled.div`
  margin: 0 24px;
  display: flex;
  align-items: center;
`;

const btnMargin = {
  marginRight: '12px',
};

const DefaultEntityHeader = ({ entity }) => {
  let viewOriginLink;
  let editOriginalContent;
  const isShadowEntity = entity?.data?.isShadowEntity === 'True';

  if (entity?.data?.uri) {
    viewOriginLink = (
      <PrimaryButton
        text={<FormattedMessage id="Generic.Open" />}
        href={entity?.data?.uri}
        startIcon={<Externallink size={12} />}
        target="_blank"
        style={btnMargin}
      />
    );
  }

  if (entity?.editUrl) {
    editOriginalContent = (
      <PrimaryButton
        text={<FormattedMessage id="Generic.Edit" />}
        href={entity?.editUrl}
        target="_blank"
        startIcon={<Pencil size={12} />}
        style={btnMargin}
      />
    );
  }

  return (
    <DefaultEntityHeaderWrapper>
      <IconWrapper>
        <EntityIcon entityType={entity?.data?.entityType} size="L" />
      </IconWrapper>
      <DefaultInfoWrapper>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <EntityTitle>{entity.name}</EntityTitle>
              </div>
              <div>
                {(viewOriginLink || editOriginalContent) && (
                  <div>
                    {viewOriginLink}
                    {editOriginalContent}
                  </div>
                )}
              </div>
            </div>
            <DefaultEntityDates
              createdDate={entity?.data?.createdDate}
              modifiedDate={entity?.data?.modifiedDate}
              discoveryDate={entity?.data?.discoveryDate}
              lastChangedBy={entity?.data?.lastChangedBy}
              authors={entity?.data?.authors}
            />
          </div>
          {isShadowEntity && (
            <div style={{ marginLeft: '8px' }}>
              <HintBadge
                label={
                  <FormattedMessage id="module-entityV2-shadow-entity-label" />
                }
                hintLabel={
                  <FormattedMessage id="module-entityV2-shadow-entity-tooltip-hint" />
                }
              />
            </div>
          )}
        </div>
        {entity?.data?.tags && (
          <div style={{ marginTop: '12px' }}>
            {entity?.data?.tags.map((t) => (
              <Badge type="success" style={{ marginRight: '6px' }}>
                {t}
              </Badge>
            ))}
          </div>
        )}
        {entity.description && (
          <DescriptionWrapper>{entity.description}</DescriptionWrapper>
        )}
      </DefaultInfoWrapper>
    </DefaultEntityHeaderWrapper>
  );
};

export default DefaultEntityHeader;
