import { handleActions } from 'redux-actions';
import {
  entityNotFound,
  entityNoAccess,
  somethingOdd,
  generalAccessDenied,
  generalSessionExpired,
  generalUnknownError,
  generalEntityNotFound,
  generalQueued,
  clearError,
  clearErrors,
} from './actions';
import { widgetErrorActions } from '../core/helpers/action';

const { accessDenied, accessUnauthorized, globalError, queued } =
  widgetErrorActions;

const initalDefault = {
  accessDeniedErrorsDictionnary: {},
  accessUnauthorizedErrorsDictionnary: {},
  globalErrorsDictionnary: {},
  queuedErrorsDictionnary: {},
  entityErrors: {},
  accessDeniedGlobalMessages: [],
  sessionExpiredGlobalMessages: [],
  unknownErrorMessages: [],
  notFoundGlobalMessages: [],
  queuedGlobalMessages: [],
};

export default handleActions(
  {
    [accessDenied]: (state, action) => {
      const { accessDeniedErrorsDictionnary } = state;
      const { widgetUniqueID } = action.payload;
      accessDeniedErrorsDictionnary[widgetUniqueID] = true;
      return {
        ...state,
        accessDeniedErrorsDictionnary: { ...accessDeniedErrorsDictionnary },
      };
    },
    [accessUnauthorized]: (state, action) => {
      const { accessUnauthorizedErrorsDictionnary } = state;
      const { widgetUniqueID } = action.payload;
      accessUnauthorizedErrorsDictionnary[widgetUniqueID] = true;
      return {
        ...state,
        accessUnauthorizedErrorsDictionnary: {
          ...accessUnauthorizedErrorsDictionnary,
        },
      };
    },
    [globalError]: (state, action) => {
      const { globalErrorsDictionnary } = state;
      if ('payload' in action && 'widgetUniqueID' in action.payload) {
        const { widgetUniqueID } = action.payload;
        globalErrorsDictionnary[widgetUniqueID] = true;
      }
      return {
        ...state,
        globalErrorsDictionnary: { ...globalErrorsDictionnary },
      };
    },
    [queued]: (state, action) => {
      const { queuedErrorsDictionnary } = state;
      const { widgetUniqueID } = action.payload;
      queuedErrorsDictionnary[widgetUniqueID] = true;
      return {
        ...state,
        queuedErrorsDictionnary: { ...queuedErrorsDictionnary },
      };
    },
    [clearErrors]: (state, action) => {
      const {
        accessDeniedErrorsDictionnary,
        accessUnauthorizedErrorsDictionnary,
        globalErrorsDictionnary,
        queuedErrorsDictionnary,
      } = state;

      const { widgetUniqueID } = action.payload;
      accessDeniedErrorsDictionnary[widgetUniqueID] = false;
      accessUnauthorizedErrorsDictionnary[widgetUniqueID] = false;
      globalErrorsDictionnary[widgetUniqueID] = false;
      queuedErrorsDictionnary[widgetUniqueID] = false;

      return {
        ...state,
        accessDeniedErrorsDictionnary: { ...accessDeniedErrorsDictionnary },
        accessUnauthorizedErrorsDictionnary: {
          ...accessUnauthorizedErrorsDictionnary,
        },
        globalErrorsDictionnary: { ...globalErrorsDictionnary },
        queuedErrorsDictionnary: { ...queuedErrorsDictionnary },
      };
    },
    [entityNotFound]: (state, { payload }) => ({
      ...state,
      entityErrors: {
        ...state.entityErrors,
        [payload]: { type: 'notfound' },
      },
    }),
    [entityNoAccess]: (state, { payload }) => ({
      ...state,
      entityErrors: {
        ...state.entityErrors,
        [payload]: { type: 'noaccess' },
      },
    }),
    [somethingOdd]: (state, { payload: { entityId, text, status } }) => ({
      ...state,
      entityErrors: {
        ...state.entityErrors,
        [entityId]: { type: 'error', text, status },
      },
    }),
    [generalAccessDenied]: (state, { payload }) => ({
      ...state,
      accessDeniedGlobalMessages: [
        ...state.accessDeniedGlobalMessages,
        payload,
      ],
    }),
    [generalSessionExpired]: (state, { payload }) => ({
      ...state,
      sessionExpiredGlobalMessages: [
        ...state.sessionExpiredGlobalMessages,
        payload,
      ],
    }),
    [generalUnknownError]: (state, { payload }) => ({
      ...state,
      unknownErrorMessages: [...state.unknownErrorMessages, payload],
    }),
    [generalEntityNotFound]: (state, { payload }) => ({
      ...state,
      notFoundGlobalMessages: [...state.notFoundGlobalMessages, payload],
    }),
    [generalQueued]: (state, { payload }) => ({
      ...state,
      queuedGlobalMessages: [...state.queuedGlobalMessages, payload],
    }),
    [clearError]: (state, { payload }) => ({
      ...state,
      accessDeniedGlobalMessages: state.accessDeniedGlobalMessages.filter(
        (m) => m.id !== payload,
      ),
      sessionExpiredGlobalMessages: state.sessionExpiredGlobalMessages.filter(
        (m) => m.id !== payload,
      ),
      unknownErrorMessages: state.unknownErrorMessages.filter(
        (m) => m.id !== payload,
      ),
      notFoundGlobalMessages: state.notFoundGlobalMessages.filter(
        (m) => m.id !== payload,
      ),
      queuedGlobalMessages: state.queuedGlobalMessages.filter(
        (m) => m.id !== payload,
      ),
    }),
  },
  initalDefault,
);
