import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const RedirectContainer = ({
  shouldRedirect,
  url,
  children,
  history,
  keepRedirect,
  location,
}) => {
  useEffect(() => {
    if (shouldRedirect) {
      if (keepRedirect) {
        history.push(`${url}?redirect=${location.pathname}`);
      } else {
        history.push(url);
      }
    }
  }, [shouldRedirect]);

  if (shouldRedirect) {
    return <div />;
  }

  return <>{children}</>;
};

export default withRouter(RedirectContainer);
