import React, { Component } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/form';
import PropTypes from 'prop-types';
import { Close } from 'uxi/Icons';
import Fade from './Fade';
import ToastNotificationMessageStyle from './Toast.style';

const ToastWrapper = styled.div`
  background: #ffffff;
  position: fixed;
  width: 300px;
  min-height: 50px;
  overflow: hidden;
  top: 15px;
  right: 15px;
  zindex: 999;
  borderradius: 3px;
  box-shadow: 0 0 12px #999999;
  opacity: 0.9;
  padding: 16px 32px 16px 8px;
  color: #565656;
  display: flex;
  &:hover {
    opacity: 1;
  }
`;

class Toast extends Component {
  hide() {
    const { onCloseClick, index } = this.props;

    if (onCloseClick) {
      onCloseClick(index);
    }
  }

  render() {
    const theme = useTheme();
    const themePrimary = theme.palette.themePrimary;

    const { index, Icon, title, message, customIcon } = this.props;

    const topSize = index === 0 ? 15 : index * 85 + 60;
    const styleAssign = {
      top: `${topSize}px`,
    };

    let iconContent;
    let containerStyle = ToastNotificationMessageStyle.container;

    if (Icon) {
      iconContent = (
        <div style={ToastNotificationMessageStyle.iconWrapper}>
          <Icon
            style={{
              ...ToastNotificationMessageStyle.icon,
              color: themePrimary,
            }}
          />
        </div>
      );
    } else if (customIcon) {
      iconContent = (
        <div style={ToastNotificationMessageStyle.iconWrapper}>
          {customIcon}
        </div>
      );
    } else {
      containerStyle = Object.assign(containerStyle, {
        paddingLeft: '15px',
      });
    }

    return (
      <Fade>
        <ToastWrapper style={styleAssign}>
          <div style={ToastNotificationMessageStyle.close}>
            <div style={ToastNotificationMessageStyle.closeIcon}>
              <Close size="14" onClick={this.hide.bind(this)} />
            </div>
          </div>
          <div style={ToastNotificationMessageStyle.containerWrapper}>
            {iconContent}
            <div style={containerStyle}>
              <div style={ToastNotificationMessageStyle.title}>{title}</div>
              <div>{message}</div>
            </div>
          </div>
        </ToastWrapper>
      </Fade>
    );
  }
}

Toast.propTypes = {
  index: PropTypes.number.isRequired,
  Icon: PropTypes.any.isRequired,
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  customIcon: PropTypes.node.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default Toast;
