import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { FormattedMessage } from '@cluedin/locale';
import {
  FormDecorator,
  TextField,
  PanelContent,
  PanelFooter,
  FormSubmitAndCancel,
  PanelHeader,
  PanelClose,
  withConfirmDialog,
  ConfirmCancelDialog,
  GqlErrorMessages,
  Select,
  Toggle,
} from '@cluedin/form';

import { useCreateHierarchy } from '../../hooks/useCreateHierarchy';
import { useGetEdgeTypes } from '../../hooks/useGetEdgeTypes';
import {
  getClassicValidationMessage,
  getOptionalStringButNotRequired,
  RequiredMessage,
  NotEmptyErrorMessage,
} from '../../../core/validation/messages';
import { isEmpty } from '../../../core/validation/index';
import { useGetEntitiesFromEdgeType } from '../../hooks/useGetEntitiesFromEdgeType';
import { CreateSimpleEntityTypeOrSelect } from '../../../mapping/components';

const HierarchyCreationForm = ({ onClose, history, noCaching, entity }) => {
  const [createHierarchy, { data, loading, error }] =
    useCreateHierarchy(noCaching);
  const [
    getEdgeTypes,
    {
      data: edgeTypeOptions,
      loading: edgeTypeOptionsLoading,
      error: edgeTypeOptionsError,
    },
  ] = useGetEdgeTypes();
  const [
    getEntities,
    { data: entities, loading: loadingEntities, error: entitiesError },
  ] = useGetEntitiesFromEdgeType();
  const [isTouched, setTouched] = useState(
    entity?.name || entity?.data['entityType'] || false,
  );
  const name = entity?.name ? `${entity?.name} Hierarchy` : null;
  const [hierarchyName, setHierarchyName] = useState(name || '');
  const [entityEdgeType, setEntityEdgeType] = useState('');
  const [edgeTypeKeyword, setEdgeTypeKeyword] = useState('');
  const prevEntityEdgeType = useRef();
  const [isOutgoing, setIsOutgoing] = useState('false');
  const prevIsOutgoing = useRef();
  const [createWithTopLevelRoots, setCreateWithTopLevelRoots] = useState(false);
  const [entityCodeList, setEntityCodeList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [multiRootAction, setMultiRootAction] = useState();
  const [entityType, setEntityType] = useState(
    entity?.data['entityType'] || '',
  );
  const id = get(data, 'management.createHierarchy.id');

  const singleProjectAction = {
    value: 'single-project',
    label: <FormattedMessage id="module-hierarchy-singleProjectAction" />,
  };
  const multiProjectAction = {
    value: 'multi-project',
    label: <FormattedMessage id="module-hierarchy-multiProjectAction" />,
  };
  const multiRootActions = [singleProjectAction, multiProjectAction];

  if (multiRootAction == null) {
    setMultiRootAction(singleProjectAction);
  }

  const getRequiredandNotEmptyValidationMessage = (value) => {
    if (!value) {
      return <RequiredMessage />;
    }

    if (isEmpty(value)) {
      return <NotEmptyErrorMessage />;
    }

    return null;
  };

  const hierarchyNameError = getRequiredandNotEmptyValidationMessage(
    hierarchyName,
    250,
  );
  const entityTypeError = getOptionalStringButNotRequired(entityType, 250);
  const entityCodeNotSelectedError = (v) =>
    v?.length == 0 ? 'Please choose at least one entity' : '';
  const multiRootActionNotSelectedError = (v) =>
    multiRootAction == null ? 'Please choose an action' : '';

  useEffect(() => {
    let redirect = '/admin/management/hierarchy';
    if (id && multiRootAction?.value == singleProjectAction.value) {
      redirect += `/detail/${id}`;
    }

    if (id) {
      history.push(redirect);
      onClose();
    }

    return () => {
      setTouched(false);
    };
  }, [id, onClose, history]);

  useEffect(() => {
    if (entityEdgeType && entityEdgeType != undefined) {
      setEntityEdgeType('');
      setEntityCodeList([]);
    }
    getEdgeTypes({ entityType: entityType });
  }, [setEntityType, entityType]);

  useEffect(() => {
    if (
      entityEdgeType != '' &&
      ((entityEdgeType && entityEdgeType != prevEntityEdgeType.current) ||
        (isOutgoing && isOutgoing != prevIsOutgoing.current))
    ) {
      getEntities({
        edgeType: entityEdgeType.trim(),
        outgoing: isOutgoing === 'true',
        keyword: '',
        take: 20,
      });
      prevEntityEdgeType.current = entityEdgeType;
      prevIsOutgoing.current = isOutgoing;
      setEntityCodeList([]);
    }
  }, [setEntityEdgeType, entityEdgeType, setIsOutgoing, isOutgoing]);

  useEffect(() => {
    getEntities({
      edgeType: entityEdgeType.trim(),
      outgoing: isOutgoing === 'true',
      keyword: keyword,
      take: 20,
    });
  }, [setKeyword, keyword]);

  useEffect(() => {
    if (entityCodeList.length > 0) {
      setKeyword('');
    }
  }, [setEntityCodeList, entityCodeList]);

  const ClosePanelButton = !!hierarchyName
    ? withConfirmDialog(PanelClose, ConfirmCancelDialog)
    : PanelClose;

  return (
    <>
      <PanelHeader
        title={<FormattedMessage id="module-hierarchy-createHierarchy" />}
        onClose={onClose}
        CustomClose={
          <ClosePanelButton
            confirmTitle={
              <FormattedMessage id="data-source-confirm-lost-changes-title" />
            }
            confirmMessage={
              <FormattedMessage id="data-source-confirm-lost-changes-message" />
            }
            onConfirm={onClose}
            onClick={onClose}
          />
        }
      />

      <PanelContent>
        {error && <GqlErrorMessages error={error} />}
        <FormDecorator
          label={<FormattedMessage id="module-hierarchy-Name" />}
          helpText={<FormattedMessage id="module-hierarchy-NameHelp" />}
          isRequired
          errorText={isTouched && hierarchyNameError}
        >
          <TextField
            data-test="input-name-new-hierarchy"
            value={hierarchyName}
            onChange={(e, v) => {
              setTouched(true);
              setHierarchyName(v);
            }}
          />
        </FormDecorator>
        <FormDecorator
          label={<FormattedMessage id="module-hierarchy-EntityType" />}
          helpText={<FormattedMessage id="module-hierarchy-EntityTypeHelp" />}
          errorText={isTouched && entityTypeError}
        >
          <div className="__test_EntityType">
            <CreateSimpleEntityTypeOrSelect
              isCreatable={false}
              value={entityType || ''}
              onChange={(v) => {
                setTouched(true);
                setEntityType(v.entityType);
              }}
            />
          </div>
        </FormDecorator>
        <FormDecorator
          label={<FormattedMessage id="module-hierarchy-EntityEdgeType" />}
          helpText={
            <FormattedMessage id="module-hierarchy-EntityEdgeTypeHelp" />
          }
          errorText={isTouched && edgeTypeOptionsError?.message}
        >
          <Select
            data-test="input-entityEdgeType-new-hierarchy"
            isSearchable={true}
            value={entityEdgeType}
            isLoading={edgeTypeOptionsLoading}
            disabled={edgeTypeOptions?.edgeTypes.length == 0}
            inputValue={edgeTypeKeyword}
            options={edgeTypeOptions?.edgeTypes
              ?.filter((edgeType) => {
                return edgeType.toLowerCase().includes(edgeTypeKeyword);
              })
              .map((edgeType, i) => {
                return {
                  value: edgeType,
                  label: edgeType,
                };
              })}
            onInputChange={(v) => {
              setEdgeTypeKeyword(v);
            }}
            onChange={(v) => {
              setTouched(true);
              setEntityEdgeType(v.value);
            }}
          />
        </FormDecorator>
        {entityEdgeType && (
          <FormDecorator
            label={<FormattedMessage id="module-hierarchy-EdgeDirection" />}
            helpText={
              <FormattedMessage id="module-hierarchy-EdgeDirectionHelp" />
            }
          >
            <Select
              data-test="input-edgeDirection-new-hierarchy"
              value={isOutgoing}
              defaultValue={{ value: 'false' }}
              options={[
                {
                  value: 'true',
                  label: <FormattedMessage id="module-hierarchy-outgoing" />,
                },
                {
                  value: 'false',
                  label: <FormattedMessage id="module-hierarchy-incoming" />,
                },
              ]}
              onChange={(v) => {
                setTouched(true);
                setIsOutgoing(v.value);
              }}
            />
          </FormDecorator>
        )}
        {entityEdgeType && entities?.hierarchyNodes?.length > 0 && (
          <FormDecorator
            label={
              <FormattedMessage id="module-hierarchy-ChoseTopLevelRoots" />
            }
            helpText={
              <FormattedMessage id="module-hierarchy-ChoseTopLevelRootsHelp" />
            }
            isRequire={false}
          >
            <Toggle
              checked={createWithTopLevelRoots}
              name={'topLevelRoots'}
              onChange={() => {
                setCreateWithTopLevelRoots(!createWithTopLevelRoots);
              }}
            />
          </FormDecorator>
        )}
        {entityEdgeType && !createWithTopLevelRoots && (
          <FormDecorator
            label={<FormattedMessage id="module-hierarchy-RootEntity" />}
            helpText={<FormattedMessage id="module-hierarchy-RootEntityHelp" />}
            isRequire={false}
            errorText={
              isTouched &&
              entityEdgeType &&
              (entityCodeNotSelectedError(entityCodeList) || entitiesError)
            }
          >
            <Select
              isSearchable
              isMulti
              isLoading={loadingEntities}
              selected={entityCodeList}
              inputValue={keyword}
              options={entities?.hierarchyNodes
                .filter((rootNode) => {
                  return rootNode.displayName.toLowerCase().includes(keyword);
                })
                .map((rootNode, i) => {
                  return {
                    value: rootNode.originEntityCode,
                    label: rootNode.displayName,
                  };
                })}
              onInputChange={(v, e) => {
                if (e.action == 'input-change') {
                  setKeyword(v);
                }
              }}
              onChange={(v) => {
                setEntityCodeList(v);
              }}
            />
          </FormDecorator>
        )}
        {(entityCodeList?.length > 1 || createWithTopLevelRoots) && (
          <FormDecorator
            label={
              <FormattedMessage id="module-hierarchy-MultipleRootAction" />
            }
            helpText={
              <FormattedMessage id="module-hierarchy-MultipleRootActionHelp" />
            }
            isRequired
            errorText={
              isTouched &&
              entityEdgeType &&
              entitiesError == null &&
              multiRootActionNotSelectedError(entityCodeList)
            }
          >
            <Select
              value={multiRootAction}
              options={multiRootActions}
              onChange={(v) => {
                setMultiRootAction(v);
              }}
            />
          </FormDecorator>
        )}
      </PanelContent>

      <PanelFooter>
        <FormSubmitAndCancel
          loading={loading || loadingEntities}
          isTouchedAndNotSaved={!!hierarchyName}
          submitProps={{
            disabled: !!hierarchyNameError,
            label: (
              <span data-test="create-new-hierarchy-button">
                <FormattedMessage id="module-hierarchy-create" />
              </span>
            ),
            onClick: () => {
              if (!hierarchyName) {
                return;
              }

              var createData = {
                name: hierarchyName?.trim(),
                entityType: entityType?.trim() ?? '',
                relationships: null,
                entityEdgeType: entityEdgeType?.trim(),
                entityEdgeOutgoing: isOutgoing === 'true',
                createWithTopLevelRoots: createWithTopLevelRoots,
                rootNodeOriginEntityCodes: entityCodeList,
                createMultiProjects:
                  multiRootAction?.value == multiProjectAction.value,
              };

              createHierarchy(createData);
            },
          }}
          onCancel={() => {
            setHierarchyName('');
            setEntityType('');
            setEntityEdgeType('');
            setEntityCodeList([]);
            setIsOutgoing('false');
            onClose();
          }}
        />
      </PanelFooter>
    </>
  );
};

export default withRouter(HierarchyCreationForm);
