import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { setupRedirectToRelativeHome } from '../core/components/containers/RedirectToRelativeHome';
import { RACI_LEVELS } from '@cluedin/components';

export const getLink = {
  home: () => '/admin/governance/pii',
  dimension: (dimensionId) => `/admin/governance/pii/dimension/${dimensionId}`,
};

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'governance.personalidentifiers': RACI_LEVELS.CONSULTANT,
    },
    component: setupRedirectToRelativeHome('pii'),
  },
  {
    path: '/home',
    exact: true,
    claims: {
      'governance.personalidentifiers': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GlobalMetricsDashboard" */
        './components/pages/GlobalMetricsDashboard'
      ),
    ),
  },
  {
    path: '/dimension/:dimensionId',
    exact: true,
    claims: {
      'governance.personalidentifiers': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"MetricName" */
        './components/pages/MetricDetail'
      ),
    ),
  },
];
