import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { ButtonLink } from '@cluedin/atoms';

const ShowMoreDetails = ({ toggle, showMore }) => (
  <div style={{ marginTop: '6px' }}>
    <ButtonLink
      onClick={toggle}
      style={{ color: '#fff' }}
      text={
        showMore ? (
          <FormattedMessage id="module-error-hideDetails" />
        ) : (
          <FormattedMessage id="module-error-showMoreDetails" />
        )
      }
    />
  </div>
);

export default ShowMoreDetails;
