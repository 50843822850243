import React from 'react';
import { DataGridv2 } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import StatusBadge from './StatusBadge';
import { getLabelStatus } from './utils';

const DataSourceStatus = ({ data }) => {
  const statuses = (Object.keys(data || {}) || []).map((key) => {
    const type = getLabelStatus(key);
    const value = data[key];

    return {
      type,
      status: value === true ? 'up' : 'down',
    };
  });

  return (
    <>
      <DataGridv2
        data={statuses}
        model={[
          {
            property: 'type',
            displayName: <FormattedMessage id="module-coreStatusCheck-Type" />,
          },
          {
            property: 'status',
            Component: StatusBadge,
            displayName: (
              <FormattedMessage id="module-coreStatusCheck-Status" />
            ),
          },
        ]}
      />
    </>
  );
};

export default DataSourceStatus;
