"use strict";

exports.__esModule = true;
var _buttonStyles = require("./buttonStyles");
Object.keys(_buttonStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _buttonStyles[key]) return;
  exports[key] = _buttonStyles[key];
});
var _checkboxStyles = require("./checkboxStyles");
Object.keys(_checkboxStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _checkboxStyles[key]) return;
  exports[key] = _checkboxStyles[key];
});
var _choiceGroupStyles = require("./choiceGroupStyles");
Object.keys(_choiceGroupStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _choiceGroupStyles[key]) return;
  exports[key] = _choiceGroupStyles[key];
});
var _datePickerFieldStyles = require("./datePickerFieldStyles");
Object.keys(_datePickerFieldStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _datePickerFieldStyles[key]) return;
  exports[key] = _datePickerFieldStyles[key];
});
var _textFieldStyles = require("./textFieldStyles");
Object.keys(_textFieldStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _textFieldStyles[key]) return;
  exports[key] = _textFieldStyles[key];
});
var _toggleStyles = require("./toggleStyles");
Object.keys(_toggleStyles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _toggleStyles[key]) return;
  exports[key] = _toggleStyles[key];
});