import { FormattedMessage } from '@cluedin/locale';
import { actionsConfiguration, RACI_LEVELS } from '@cluedin/components';

import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  name: 'theme',
  displayName: <FormattedMessage id="theme" />,
  path: '/theme',
  claims: {
    'admin.tenantmanagement': RACI_LEVELS.INFORMED,
  },
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Datacleaning',
        name: <FormattedMessage id="theme" />,
        link: '/admin/settings/theme',
        claims: admin.tenantmanagement.tenant.view,
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"APITokenViewContainer" */
            './components/pages/WhiteLabelPage'
          ),
        ),
      ),
    },
  ],
};
