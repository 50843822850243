import { compose, branch, renderComponent } from 'recompose';
import EntitiesWithPaging from './EntitiesWithPaging';
import EntityWithMutation from './EntityWithMutation';
import EntityAccessDenied from './EntityAccessDenied';
import InvalidEntity from './InvalidEntity';
import EntityNotFound from './EntityNotFound';
import NoEntities from './NoEntities';
import EntityLoading from './EntityLoading';
import QueuedEntity from './QueuedEntity';
import EntitiesList from './EntitiesList';
import EntitySuccess from './EntitySuccess';

import {
  isFetching,
  isEntityNotFound,
  isAccessDenied,
  isInvalid,
  ifNoEntities,
  isDone,
} from './utils/propsChecker';

export const withAccessDenied = (options = {}) =>
  branch(
    isAccessDenied(options),
    renderComponent(
      options.AccessDenied
        ? options.AccessDenied
        : EntityAccessDenied(options.placement, options.AccessDeniedMessage),
    ),
  );

export const withSuccess = (options = {}) =>
  branch(
    isDone(options),
    renderComponent(
      options.Success
        ? options.Success
        : EntitySuccess(options.placement, options.SuccessMessage),
    ),
  );

export const withEntityNotFound = (options = {}) =>
  branch(
    isEntityNotFound(options),
    renderComponent(
      options.NotFound
        ? options.NotFound
        : EntityNotFound(options.placement, options.EntityNotFoundMessage),
    ),
  );

export const withInvalid = (options = {}) =>
  branch(
    isInvalid(options),
    renderComponent(
      options.Invalid
        ? options.Invalid
        : InvalidEntity(options.placement, options.InvalidEntityMessage),
    ),
  );

export const withIsFetching = (options = {}) =>
  branch(
    isFetching(options),
    renderComponent(
      options.Loader ? options.Loader : EntityLoading(options.placement),
    ),
  );

export const withEntity = (component, options) =>
  compose(
    withIsFetching(options),
    withInvalid(options),
    withEntityNotFound(options),
    withAccessDenied(options),
  )(component);

export const noEntities = (options = {}) =>
  branch(
    ifNoEntities(options),
    renderComponent(
      options.NoEntities
        ? options.NoEntities
        : NoEntities({
            placement: options.placement,
            message: options.noEntitiesMessage,
          }),
    ),
  );

export const withEntities = (Component, options) =>
  compose(
    withInvalid(options),
    withIsFetching(options),
  )(EntitiesList(Component, options));

const checkIfQueuedMutation = ({ queuedMutation }) => queuedMutation;

const withQueuedMutation = (options = {}) =>
  branch(
    (props) =>
      options.normalizeProps
        ? checkIfQueuedMutation(options.normalizeProps(props))
        : checkIfQueuedMutation(props),
    renderComponent(
      options.Queued
        ? options.Queued
        : QueuedEntity(options.placement, options.QueuedMessage),
    ),
  );

export const withMutation = (Component, options = {}) => {
  const CompWithQueued = compose(withQueuedMutation(options));

  return CompWithQueued(EntityWithMutation(Component, options));
};

export const withPaging = (Component, options = {}) =>
  withEntities(EntitiesWithPaging(Component, options), options);
