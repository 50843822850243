import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_TERM_BY_ID = gql`
  query getGlossaryTerm($id: ID!) {
    management {
      id
      glossaryTerm(id: $id) {
        id
        name
        categoryId
        category {
          id
          name
        }
        active
        createdBy
        modifiedBy
        isEndorsedByCurrentUser
        ownedBy
        createdAt
        modifiedAt
        shortDescription
        certificationLevel
        userRating
        rating
        version
        ruleSet
        description
        isObsolete
        endorsedBy
        relatedTags {
          id
          name
        }
      }
    }
  }
`;

export const useQueryTermById = (id) => {
  const { data, loading, error } = useQuery(GET_TERM_BY_ID, {
    variables: {
      id,
    },
  });

  const term = get(data, 'management.glossaryTerm');

  return [term, loading, error];
};

export default {
  useQueryTermById,
};
