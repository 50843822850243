import React from 'react';
import PropTypes from 'prop-types';

import { useMeasure } from '../../hooks/useMeasure';

export const CollapseElement = ({
  children,
  isVisible,
  duration = '200ms',
  style: stylesFromProps = {},
}) => {
  const [measureRef, { height: elementHeight }] = useMeasure();

  const style = {
    overflow: 'hidden',
    config: { duration },
    transition: `height ${duration} ease-out`,
    height: isVisible ? `${elementHeight}px` : `${0}px`,
    ...stylesFromProps,
  };

  return (
    <div style={style}>
      <div ref={measureRef}>{children}</div>
    </div>
  );
};

CollapseElement.propTypes = {
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
