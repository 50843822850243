import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../core/components/composites/List/List';
import DomainUser from './DomainUser';

const DomainUsers = ({ potentialUsers, inviteClick }) => {
  const domainUsersContent = potentialUsers.map((user, index) => (
    <DomainUser inviteClick={inviteClick} key={index} domainUser={user} />
  ));

  return <List>{domainUsersContent}</List>;
};

DomainUsers.propTypes = {
  inviteClick: PropTypes.func,
  potentialUsers: PropTypes.array,
};

DomainUsers.defaultProps = {
  inviteClick: null,
  potentialUsers: [],
};

export default DomainUsers;
