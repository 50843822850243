import styled from 'styled-components';

export const ManualMergePanelFooterWrapper = styled.div`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  border-top: 1px solid #ccc;
  margin-top: auto;
`;

export const ManualMergePanelFooterInnerWrapper = styled.div`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;

export const ManualMergePanelFooterSvg = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & svg {
    fill: white !important;
    height: 18px;
    margin-right: 6px;
    stroke: white !important;
    width: 18px;
    & g {
      fill: white !important;
    }
    & ellipse {
      display: none !important;
      height: 0 !important;
      opacity: 0;
      visibility: hidden;
      width: 0 !important;
    }
  }
`;
