import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { registerEntityWidget } from '../../registry';
import EntitySourceLine from './EntitySourceLine';
import { useQueryConnectors } from '../../../../connector/hooks/useQueryConnectors';
import { uniqProviderBasedOnUrisAndProviderCodes } from './helper';
import {
  WidgetTitle,
  WidgetContainer,
  WidgetHeader,
} from '../../../components/composites/widgets';

const EntitySourceAndProfileWrapper = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const EntitySourceAndProfile = ({ entity }) => {
  const [connectors, loading, error] = useQueryConnectors();
  const mergedUrisAndProviders = uniqProviderBasedOnUrisAndProviderCodes(
    entity ? entity.data.uris : [],
    entity ? entity.providers : [],
    connectors || [],
  );

  return (
    <WidgetContainer style={{ marginTop: '12px' }}>
      <WidgetHeader
        title={<FormattedMessage id="module-entityWidget-informationSource" />}
      />
      <EntitySourceAndProfileWrapper>
        {(mergedUrisAndProviders || [])
          .filter((item) => item.name !== 'CluedInImporter')
          .map((integrationWithUris, i) => (
            <EntitySourceLine
              key={`EntitySourceLine-${integrationWithUris.name}-${i}`}
              integrationWithUris={integrationWithUris}
            />
          ))}
      </EntitySourceAndProfileWrapper>
    </WidgetContainer>
  );
};

registerEntityWidget('EntitySourcesV2', {
  name: 'Information Sources',
  displayName: <FormattedMessage id="module-entityWidget-informationSource" />,
  description: 'Display the list of information source',
  view: EntitySourceAndProfile,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
