import uuid from 'uuid/v4';

import { CardPosition } from '../../utils/CardPosition';

const idExist = (id, graph) => graph?.filter((g) => g?.id === id)?.length;

export const filterTemporalNodes = (graphNetwork, rootEntityId) => {
  const nodesWithoutTemporalEntities = graphNetwork?.nodes?.filter(
    (node) =>
      node?.isTemporalEntity === false ||
      node?.isShadowEntity === false ||
      node?.id === rootEntityId,
  );

  const removedNodeIds = graphNetwork?.nodes
    .filter(
      (node) =>
        node?.isTemporalEntity &&
        node?.isShadowEntity &&
        node?.id !== rootEntityId,
    )
    .map((node) => node?.id);

  const filteredEdges = graphNetwork?.edges.filter(
    (edge) =>
      (!removedNodeIds?.includes(edge?.from) || edge?.from === rootEntityId) &&
      (!removedNodeIds?.includes(edge?.to) || edge?.to === rootEntityId),
  );

  return {
    nodes: nodesWithoutTemporalEntities,
    edges: filteredEdges,
  };
};

export const filterShadowNodes = (graphNetwork, rootEntityId) => {
  const nodesWithoutShadowEntities = graphNetwork?.nodes?.filter(
    (node) =>
      node?.isShadowEntity === false ||
      node?.isTemporalEntity ||
      node?.id === rootEntityId,
  );

  const removedNodeIds = graphNetwork?.nodes
    .filter(
      (node) =>
        node?.isShadowEntity &&
        !node?.isTemporalEntity &&
        node?.id !== rootEntityId,
    )
    .map((node) => node?.id);

  const filteredEdges = graphNetwork?.edges.filter(
    (edge) =>
      (!removedNodeIds?.includes(edge?.from) || edge?.from === rootEntityId) &&
      (!removedNodeIds?.includes(edge?.to) || edge?.to === rootEntityId),
  );

  return {
    nodes: nodesWithoutShadowEntities,
    edges: filteredEdges,
  };
};

export const generateGraphFromSummary = (
  edgeSummary,
  rootEntityId,
  themePrimary,
) => {
  let nodes = [];

  const itemsPerRadius = 10;
  const radius = 400;
  const arrowColor = '#999';

  const edgeProps = {
    width: 182,
    height: 48,
    color: '#efd88f',
    backgroundColor: '#ffffff',
    borderColor: '#d7d7d8',
    labelFontStyle: '10px',
    labelFontColor: 'black',
    subTextFontColor: '#9b9b9c',
    subTextFontStyle: '8px',
  };

  const themeEdgeProps = {
    ...edgeProps,
    borderHoverColor: themePrimary,
  };

  const inputEdges = edgeSummary?.edges?.map((item) => {
    if (!item?.isGrouped) {
      return item;
    }
    return {
      ...item,
      entityId: item?.id,
      name: 'grouped',
    };
  });

  const edgeSize = inputEdges?.length || 0;
  const levels = Math.floor(edgeSize / itemsPerRadius);

  let theta = [];

  for (let i = 0; i <= levels; i++) {
    theta[i] = [];
    for (let j = 0; j < itemsPerRadius; j++) {
      theta[i][j] = CardPosition[j].radius - i / Math.PI;
    }
  }

  const radiusPosition = (n) => {
    const level = Math.floor(n / itemsPerRadius);
    const index = n % itemsPerRadius;
    const radiusPosition = theta[level][index];
    const positionX = Math.round(radius * Math.cos(radiusPosition));
    const positionY = Math.round(radius * Math.sin(radiusPosition));

    return {
      x: positionX,
      y: positionY,
      edgeLength: radius * (level * 0.6 + 1),
    };
  };

  !idExist(edgeSummary?.id, nodes) &&
    nodes?.push({
      id: edgeSummary?.id,
      label: edgeSummary?.name,
      title: edgeSummary?.name,
      group: edgeSummary?.isGrouped ? edgeSummary?.group : 'default',
      x: 0,
      y: 0,
      borderWidth: 0,
      borderWidthSelected: 0,
      shape: 'custom',
      nodeKind: edgeSummary?.nodeKind,
      isShadowEntity: edgeSummary?.isShadowEntity,
      isTemporalEntity: edgeSummary?.isTemporalEntity,
      entityType: edgeSummary?.entityType || edgeSummary?.type,
      icon: edgeSummary?.icon,
      displayName: edgeSummary?.displayName,
      groupedEntityIds: edgeSummary?.groupedEntityIds,
      previewImage: edgeSummary?.previewImage,
    });

  let edges = [];

  inputEdges?.forEach((e, i) => {
    const coordinates = radiusPosition(i);
    !idExist(e?.entityId, nodes) &&
      e?.entityId &&
      nodes?.push({
        id: e?.entityId,
        label: e?.name,
        title: e?.name,
        x: e?.entityId !== rootEntityId ? coordinates?.x - 100 : coordinates?.x,
        y: e?.entityId !== rootEntityId ? coordinates?.y + 400 : coordinates?.y,
        shape: 'custom',
        nodeKind: e?.nodeKind,
        isShadowEntity: e?.isShadowEntity,
        isTemporalEntity: e?.isTemporalEntity,
        entityType: e?.entityType || e?.type,
        icon: e?.icon,
        displayName: e?.displayName,
        groupedEntityIds: e?.groupedEntityIds,
        previewImage: e?.previewImage,
        attributeOrigin: e?.attributeOrigin,
      });

    const direction =
      e?.direction === 'Outgoing'
        ? { from: edgeSummary?.id, to: e?.entityId }
        : { from: e?.entityId, to: edgeSummary?.id };

    const edgeExist = edges?.filter(
      (edge) => edge?.from === direction?.from && edge?.to === direction?.to,
    )?.length;

    if (!edgeExist) {
      edges?.push({
        ...direction,
        groupedEntityIds: e?.groupedEntityIds,
        arrowStrikethrough: false,
        label: e?.edgeType,
        length: coordinates?.edgeLength,
        color: {
          color: arrowColor,
          hover: themeEdgeProps?.borderHoverColor,
          inherit: 'true',
        },
        hoverWidth: 1,
        font: {
          color: arrowColor,
          size: 12,
        },
        id: uuid(),
      });
    } else {
      edges = edges?.map((edge) => {
        if (
          edge?.from === direction?.from &&
          edge?.to === direction?.to &&
          edge?.edgeType !== e?.edgeType
        ) {
          return {
            ...edge,
            label: `${edge?.label}\n\n${e?.edgeType}`,
          };
        }
        return edge;
      });
    }
  });

  let smoothOptions = {
    smooth: {
      enabled: false,
    },
  };

  if (edges?.length <= 100) {
    smoothOptions = {
      smooth: {
        enabled: true,
        type: 'discrete',
        roundness: 0.25,
      },
    };
  }

  const edgesWithStyling = edges?.map((edge) => ({
    ...edge,
    ...smoothOptions,
  }));

  const visGraph = {
    nodes,
    edges: edgesWithStyling,
  };

  return visGraph;
};
