import React from 'react';
import { Loader } from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';
import { compose } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import { ChoiceGroup, FormDecorator, TextField } from '@cluedin/form';
import { withDataSourceSets } from '../../../hocs/gql/withDataSourceSets';
import DataSourceSetSelect from './DataSourceSetSelect';

const NewOrExistingDataSet = ({
  value,
  dataSourceSets,
  dataSourceSetId,
  newDataSourceSet,
  onChangeDataSourceSetId,
  onDataSourceSetNameChange,
  onChangeNewOrExistingDataSourceSet,
  intl,
  errorDataSetName,
}) => {
  const optionsChoiceGroup = [
    {
      key: 'newDataSourceGroup',
      value: true,
      text: <FormattedMessage id="data-source-create-group" />,
    },
    {
      key: 'existingDataSourceGroup',
      value: false,
      text: <FormattedMessage id="data-source-existing-group" />,
    },
  ];

  const handleChangeChoice = (e, v) =>
    onChangeNewOrExistingDataSourceSet(v.value);

  return (
    <>
      <ChoiceGroup
        defaultSelectedKey={
          newDataSourceSet ? 'newDataSourceGroup' : 'existingDataSourceGroup'
        }
        options={optionsChoiceGroup}
        onChange={handleChangeChoice}
      />
      {newDataSourceSet === true && (
        <div style={{ marginTop: '12px' }}>
          <FormDecorator
            label={<FormattedMessage id="data-source-group-name" />}
            isRequired
            errorText={errorDataSetName}
          >
            <TextField
              value={value}
              onChange={onDataSourceSetNameChange}
              placeholder={intl.formatMessage({ id: 'data-source-enter-name' })}
              data-test={'newDataSourceGroupName'}
              hasError={!!errorDataSetName}
            />
          </FormDecorator>
        </div>
      )}
      {newDataSourceSet === false && (
        <div style={{ marginTop: '12px' }}>
          <FormDecorator
            label={<FormattedMessage id="data-source-select-group" />}
            isRequired
          >
            <DataSourceSetSelect
              value={dataSourceSetId}
              dataSourceSets={dataSourceSets}
              onChange={onChangeDataSourceSetId}
            />
          </FormDecorator>
        </div>
      )}
    </>
  );
};

const FileUploadDataSourceSetField = ({
  isFetching,
  dataSourceSets,
  dataSourceSetId,
  newDataSourceSet,
  dataSourceSetName,
  onChangeDataSourceSetId,
  onDataSourceSetNameChange,
  onChangeNewOrExistingDataSourceSet,
  intl,
  errorDataSetName,
}) => {
  if (!isFetching && dataSourceSets.length === 0) {
    onChangeNewOrExistingDataSourceSet(true);
  }

  return (
    <FormDecorator
      isRequired
      label={<FormattedMessage id="data-source-group" />}
      helpText={<FormattedMessage id="data-source-upload-file-help" />}
      tooltipHint={<FormattedMessage id="data-source-upload-file-hint" />}
      errorText={dataSourceSets.length === 0 ? errorDataSetName : null}
    >
      {isFetching && (
        <div style={{ display: 'flex' }}>
          <Loader />
        </div>
      )}
      <div className="_test_DataSourceSetName">
        {!isFetching && dataSourceSets.length === 0 && (
          <TextField
            data-test={'newDataSourceGroupName'}
            value={dataSourceSetName}
            onChange={onDataSourceSetNameChange}
            hasError={!!errorDataSetName}
          />
        )}
        {!isFetching && dataSourceSets.length > 0 && (
          <NewOrExistingDataSet
            errorDataSetName={errorDataSetName}
            dataSourceSets={dataSourceSets}
            newDataSourceSet={newDataSourceSet}
            onChangeNewOrExistingDataSourceSet={
              onChangeNewOrExistingDataSourceSet
            }
            value={dataSourceSetName}
            onDataSourceSetNameChange={onDataSourceSetNameChange}
            dataSourceSetId={dataSourceSetId}
            onChangeDataSourceSetId={onChangeDataSourceSetId}
            intl={intl}
          />
        )}
      </div>
    </FormDecorator>
  );
};

export default compose(
  injectIntl,
  withDataSourceSets,
)(FileUploadDataSourceSetField);
