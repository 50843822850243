import { Card } from './Card';

const cardStyles = {
  baseFontColor: 'black',
  hoverFontColor: '#555555',
};

const CardCode = (props) => {
  const { selected } = props;

  const codeProps = {
    backgroundColor: '#F7FCFB',
    borderColor: '#29A29A',
    circleColor: '#DDF0F0',
    circleTransparency: 1,
    color: '#29A29A',
    icon: props?.iconsToUse?.KeyIcon,
    labelFontColor: cardStyles?.baseFontColor,
    subTextFontColor: cardStyles?.baseFontColor,
  };

  return props?.iconsToUse && Card({ ...props, ...codeProps });
};

const CardDedupProject = (props) => {
  const codeProps = {
    backgroundColor: '#FBF9FE',
    borderColor: '#A65DEE',
    circleColor: '#e2cbf9',
    circleTransparency: 1,
    color: '#A65DEE',
    icon: props?.iconsToUse?.DeduplicationTopologyV2Icon,
    labelFontColor: cardStyles?.baseFontColor,
    subTextFontColor: cardStyles?.baseFontColor,
  };

  return props?.iconsToUse && Card({ ...props, ...codeProps });
};

const CardManualMerge = (props) => {
  const codeProps = {
    backgroundColor: '#FFFDF9',
    borderColor: '#FFB800',
    circleColor: '#FDF6E0',
    circleTransparency: 1,
    color: '#FFB800',
    icon: props?.iconsToUse?.MergeIco,
    labelFontColor: cardStyles?.baseFontColor,
    subTextFontColor: cardStyles?.baseFontColor,
  };

  return props?.iconsToUse && Card({ ...props, ...codeProps });
};

const CardFuzzyMatch = (props) => {
  const codeProps = {
    backgroundColor: '#FFFBF9',
    borderColor: '#F27730',
    circleColor: '#FCE4D6',
    circleTransparency: 1,
    color: '#F27730',
    icon: props?.iconsToUse?.FuzzyMatchingIcon,
    labelFontColor: cardStyles?.baseFontColor,
    subTextFontColor: cardStyles?.baseFontColor,
  };

  return props?.iconsToUse && Card({ ...props, ...codeProps });
};

const CardClean = (props) => {
  const codeProps = {
    backgroundColor: '#F8FDFE',
    borderColor: '#00B4D8',
    circleColor: '#CCF0F7',
    circleTransparency: 1,
    color: '#00B4D8',
    icon: props?.iconsToUse?.DataCleaningTopologyIco,
    labelFontColor: cardStyles?.baseFontColor,
    subTextFontColor: cardStyles?.baseFontColor,
  };

  return props?.iconsToUse && Card({ ...props, ...codeProps });
};

const CardUserInput = (props) => {
  const codeProps = {
    backgroundColor: '#fffafd',
    borderColor: '#ff99da',
    circleColor: '#ffe0f4',
    circleTransparency: 1,
    color: '#ff99da',
    icon: props?.iconsToUse?.ManuallyAddedIcon,
    labelFontColor: cardStyles?.baseFontColor,
    subTextFontColor: cardStyles?.baseFontColor,
  };

  return props?.iconsToUse && Card({ ...props, ...codeProps });
};

const CardDataPart = (props) => {
  // Disabled whilst drawing icons in the legend is problematic
  // const { providerIcon } = props;

  const dataPartProps = {
    backgroundColor: '#FAFBFE',
    backgroundCircle: false,
    borderColor: '#5B89CF',
    color: '#5B89CF',
    icon: props?.iconsToUse?.DataSourceDatabaseIcon,
    iconSize: 32,
    labelFontColor: cardStyles?.baseFontColor,
    // icon: providerIcon
    //   ? `data:image/svg+xml;utf8,${encodeURIComponent(providerIcon)}`
    //   : props?.iconsToUse?.DataSourceDatabaseIcon,
    roundedIcon: true,
    subTextFontColor: cardStyles?.baseFontColor,
    style: {
      paddingLeft: 5,
      paddingTop: 10,
      innerPadding: 0,
      textLeft: 48,
      textPaddingTop: 12,
      textSpace: 14,
    },
  };

  return props?.iconsToUse && Card({ ...props, ...dataPartProps });
};

export const CardTypes = (props) => {
  const { cardType } = props;

  switch (cardType) {
    case 'EntityCode':
      CardCode(props);
      break;
    case 'DataPart':
      CardDataPart(props);
      break;
    case 'DeduplicationProjectMerge':
      CardDedupProject(props);
      break;
    case 'ManualMerge':
      CardManualMerge(props);
      break;
    case 'FuzzyMatch':
      CardFuzzyMatch(props);
      break;
    case 'Clean':
      CardClean(props);
      break;
    case 'LegacyClean':
      CardClean(props);
      break;
    case 'UserInput':
      CardUserInput(props);
      break;
    default:
      Card(props);
      break;
  }
};
