import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';

const CREATE_RECORD_MANUAL_PROJECT = gql`
  mutation addRecordToManualDataEntryProject($projectId: ID!, $records: JSON) {
    management {
      addRecordToManualDataEntryProject(
        projectId: $projectId
        records: $records
      )
    }
  }
`;

export const useCreateRecordManualDataEntryProject = () => {
  const [create, { data, loading, error }] = useMutation(
    CREATE_RECORD_MANUAL_PROJECT,
  );

  const dataFromPayload = get(
    data,
    'management.addRecordToManualDataEntryProject',
  );

  const createRecord = ({ projectId, records }) => {
    return create({
      variables: { projectId, records },
    });
  };

  return [
    createRecord,
    {
      data: dataFromPayload,
      success: !!dataFromPayload,
      loading,
      error,
    },
  ];
};
