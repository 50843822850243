import EntityDataKPIs from '../../../../entityWidget/components/composites/EntityDataKPIs';

const EntityDataKPIList = ({ entity, template }) => {
  const isaccuracyDisabled = template && !template?.accuracy;
  const iscompletenessDisabled = template && !template?.completeness;
  const isconnectivityDisabled = template && !template?.connectivity;
  const isrelevanceDisabled = template && !template?.relevance;

  return (
    <EntityDataKPIs
      entity={entity}
      template={template}
      isaccuracyDisabled={isaccuracyDisabled}
      iscompletenessDisabled={iscompletenessDisabled}
      isconnectivityDisabled={isconnectivityDisabled}
      isrelevanceDisabled={isrelevanceDisabled}
    />
  );
};

EntityDataKPIList.displayName = 'EntityDataKPIList';

export default EntityDataKPIList;
