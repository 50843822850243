import { getCurrentToken, clientId } from '../../config';
import {
  apiRequest,
  authRequest,
  authRequestFormData,
} from '../core/helpers/request';
import { toVM } from '../entity/viewModel';

export const getAllUsers = () =>
  authRequest('GET', 'api/account/accounts').then((resp) =>
    resp.map((u) => {
      const vmU = u;
      if (vmU.Entity) {
        vmU.Entity = toVM(u.Entity, true, getCurrentToken(), true);
      }
      if (vmU.Account) {
        vmU.Account.IsLocked = vmU.Account.LockoutEnd
          ? new Date(vmU.Account.LockoutEnd) > new Date()
          : false;
      }
      return vmU;
    }),
  );

export const getPotentialUsers = () =>
  apiRequest('GET', 'api/entity/domainusers');

export const getCurrentUser = () =>
  authRequest('GET', 'api/account/user').then((user) => {
    const userWithEntity = user;
    if (userWithEntity.entity) {
      userWithEntity.entity = toVM(user.entity, getCurrentToken(), true);
    }
    return userWithEntity;
  });

export const promote = (userName) =>
  authRequest('PUT', `api/account/setadmin?username=${userName}&value=${true}`);

export const block = (userName) =>
  authRequest('PUT', `api/account/block?username=${userName}&value=${true}`);

export const unblock = (userName) =>
  authRequest('PUT', `api/account/block?username=${userName}&value=${false}`);

export const isUserExisting = (userName) =>
  apiRequest(
    'GET',
    `api/account/username?username=${userName}&clientid=${clientId}`,
  ).then((resp) => resp.body);

export const changePassword = (data) =>
  authRequestFormData('POST', 'api/account/change', data);

export const getUserAccount = (id) =>
  // TODO shouldnt this just ask api for single user rather than pulling all back?
  getAllUsers().then((users) => users.find((c) => c.Account.Id === id));

export const getAllProviderForUser = (userId) =>
  apiRequest(
    'GET',
    `api/configuration/providefinitionaccounts/user?userId=${userId}`,
  );
