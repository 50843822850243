import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Anonymize, History, Pencil } from 'uxi/Icons';
import { get } from 'lodash';
import { injectIntl } from '@cluedin/locale';
import { useFeatureFlagOption } from '@cluedin/components';

import PageLoader from '../../../../../prepare/components/composites/PageLoader';
import {
  EntityPropertiesDropdownWrapper,
  EntityPropertiesButtonWrapper,
  EntityPropertiesDropdownIconWrapper,
} from './EntityPropertiesDropdown.styles';
import VocabularySource from '../../../../../dataCatalog/components/composites/VocabularySource';
import DotsButtonDropDown from '../../../../../core/components/composites/DotsButtonDropDown';
import EntityPropertiesHistoryPanel from '../EntityPropertiesHistoryPanel/EntityPropertiesHistoryPanel';
import EntityPropertiesEditPanelV2 from '../EntityPropertiesEditPanelV2/EntityPropertiesEditPanelV2';
import vocabulary from '../../../../../dataCatalog/hooks/fragments/vocabulary';
import { useOrganizationFeatureFlagOption } from '../../../../../featureFlag/hooks/useOrganizationFeatureFlag';

const EntityPropertiesDropdown = ({
  onEntityAnonymizeProperty,
  entityId,
  entityVocabularyKey,
  intl,
  vocabularyKey,
  value,
  isAnonymized,
  filteredHistoryChanges,
  hasVocabularySource = true,
  reloadOnSave,
  refetch,
  historyRefetch,
  historyLoading,
}) => {
  const hasAnonymizedFeature = useOrganizationFeatureFlagOption('Anonymize');
  const [isHistoryPanelExpanded, setIsHistoryPanelExpanded] = useState(false);
  const [isEditPanelExpanded, setIsEditPanelExpanded] = useState(false);

  const vocabularyKeyDisplayName = get(vocabularyKey, 'displayName');

  const filteredHistoryChangesToShow =
    filteredHistoryChanges &&
    vocabularyKey &&
    (filteredHistoryChanges || []).map((historyItem) => {
      if (!vocabulary?.key) {
        return historyItem?.filter(
          (changeItem) =>
            changeItem?.change['attribute-key'] === entityVocabularyKey,
        );
      }

      return historyItem.filter(
        (changeItem) =>
          changeItem?.change['attribute-key'] === vocabularyKey?.key,
      );
    });
  let anonymizeLabel;
  if (!isAnonymized) {
    anonymizeLabel = intl.formatMessage({
      id: 'module-entityProperties-tableAnonymize',
    });
  }
  if (isAnonymized) {
    anonymizeLabel = intl.formatMessage({
      id: 'module-entityProperties-tableDeanonymize',
    });
  }

  const options = [
    {
      'data-test': 'entityPropertiesDropdown-viewHistory',
      label: intl.formatMessage({
        id: 'module-entityProperties-tableViewHistory',
      }),
      onClick: () => {
        if (!historyLoading) {
          setIsHistoryPanelExpanded(true);
        }
      },
      disabled: historyLoading,
      icon: (
        <EntityPropertiesDropdownIconWrapper>
          {historyLoading ? <PageLoader /> : <History />}
        </EntityPropertiesDropdownIconWrapper>
      ),
    },
    hasAnonymizedFeature
      ? {
          'data-test': 'entityPropertiesDropdown-anonymize',
          label: anonymizeLabel,
          onClick: () => {
            onEntityAnonymizeProperty({
              entityVocabularyKey,
              value,
              isAnonymized,
            });
          },
          icon: (
            <EntityPropertiesDropdownIconWrapper>
              <Anonymize />
            </EntityPropertiesDropdownIconWrapper>
          ),
        }
      : null,
    {
      'data-test': 'entityPropertiesDropdown-edit',
      label: intl.formatMessage({ id: 'module-entityProperties-tableEdit' }),
      enableHover: !isAnonymized,
      onClick: () => {
        if (isAnonymized) {
          return;
        }
        setIsEditPanelExpanded(true);
      },
      disabled: isAnonymized,
      icon: (
        <EntityPropertiesDropdownIconWrapper>
          <Pencil />
        </EntityPropertiesDropdownIconWrapper>
      ),
    },
  ].filter(Boolean);

  const SourceIcon = () => {
    return (
      <VocabularySource
        {...vocabularyKey}
        connector={vocabularyKey?.vocabulary?.connector}
      />
    );
  };

  return (
    <EntityPropertiesDropdownWrapper>
      <EntityPropertiesButtonWrapper>
        {hasVocabularySource && (
          <VocabularySource
            {...vocabularyKey}
            connector={vocabularyKey?.vocabulary?.connector}
          />
        )}
      </EntityPropertiesButtonWrapper>

      <div data-test="entity-entityProperties-dropdownButton">
        <DotsButtonDropDown options={options} />
      </div>

      <EntityPropertiesHistoryPanel
        entityVocabularyKey={entityVocabularyKey}
        isHistoryPanelExpanded={isHistoryPanelExpanded}
        setIsHistoryPanelExpanded={setIsHistoryPanelExpanded}
        vocabularyKeyDisplayName={vocabularyKeyDisplayName}
        vocabularyKey={vocabularyKey}
        value={value}
        filteredHistoryChangesToShow={filteredHistoryChangesToShow}
        SourceIcon={SourceIcon}
        historyLoading={historyLoading}
        entityId={entityId}
      />
      {isEditPanelExpanded && (
        <EntityPropertiesEditPanelV2
          entityId={entityId}
          isEditPanelExpanded={isEditPanelExpanded}
          setIsEditPanelExpanded={setIsEditPanelExpanded}
          value={value}
          vocabularyKey={vocabularyKey}
          refetch={refetch}
          historyRefetch={historyRefetch}
          entityVocabularyKey={entityVocabularyKey}
          reloadOnSave={reloadOnSave}
        />
      )}
    </EntityPropertiesDropdownWrapper>
  );
};

export default injectIntl(EntityPropertiesDropdown);

EntityPropertiesDropdown.propTypes = {
  entityVocabularyKey: PropTypes.string,
  entityId: PropTypes.string,
  filteredHistoryChanges: PropTypes.array,
  isAnonymized: PropTypes.bool,
  onEntityAnonymizeProperty: PropTypes.func,
  value: PropTypes.string,
  vocabularyKey: PropTypes.object,
  refetch: PropTypes.func,
};

EntityPropertiesDropdown.defaultProps = {
  entityVocabularyKey: '',
  entityId: null,
  filteredHistoryChanges: [],
  isAnonymized: null,
  onEntityAnonymizeProperty: () => {},
  value: '',
  vocabularyKey: {},
  refetch: () => {},
};
