import React from 'react';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent } from 'uxi/Panel';
import CurrentUserRequestList from '../lists/CurrentUserRequestList';

const CurrentUserRequestsDialog = ({
  show,
  onClose,
  currentUserRequests = [],
}) => (
  <Dialog show={show} onClose={onClose}>
    <Panel onClose={onClose} style={{ height: '100vh' }}>
      <PanelHeader onClose={onClose} title={'Your current request'} hasClose />
      <PanelContent style={{ padding: '30px' }}>
        <CurrentUserRequestList currentUserRequests={currentUserRequests} />
      </PanelContent>
    </Panel>
  </Dialog>
);

export default CurrentUserRequestsDialog;
