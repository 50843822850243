import { useTheme } from '@cluedin/theme';
import LightTab from '../../core/components/composites/LightTab/LightTab';
import { removeLastCharacter } from '../../core/helpers/string';

const removeIfLastPathIsSlash = (url, path) => {
  if (url.lastIndexOf('/') === url.length - 1) {
    return `${removeLastCharacter(url)}${path}`;
  }

  return `${url}${path}`;
};

const LightTabExtra = ({ path, label, selected, history, match }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <LightTab
      selected={selected}
      style={selected ? { fontWeight: 'bold', color: themePrimary } : {}}
      onClick={(e) => {
        history.push(removeIfLastPathIsSlash(match.url, path));
        e.preventDefault();
      }}
    >
      {label}
    </LightTab>
  );
};

export default LightTabExtra;
