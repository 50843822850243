import React, { useEffect } from 'react';

import { withManualMergePanelWorkflow } from '../../../hocs/withManualMergePanelWorkflow';

const ManualMergeContainer = ({
  entityId,
  nodeDataType,
  setNodeDataType,
  currentNode,
  handleExecuteAction,
  manualMergePanelData,
  manualMergePanelLoading,
  manualMergePanelError,
  openManualMergePanelWorkflow,
}) => {
  useEffect(() => {
    if (nodeDataType === 'ManualMerge') {
      openManualMergePanelWorkflow();
    }
  }, [nodeDataType]);

  return <span />;
};

export default withManualMergePanelWorkflow(ManualMergeContainer);
