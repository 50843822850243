import PropTypes from 'prop-types';
import RelationIcon from '@cluedin/svgs/icons/relation-icon.svg';

export const Card = ({
  ctx,
  x,
  y,
  width,
  height,
  icon,
  iconSize = 12,
  actionIcon,
  label,
  subText,
  altText,
  color,
  circleTransparency,
  backgroundColor,
  borderColor,
  circleColor,
  opacity,
  labelFontStyle,
  labelFontColor,
  subTextFontColor,
  subTextFontStyle,
  altTextFontColor = '#ccc',
  altTextFontStyle,
  altTextOffset = 0,
  backgroundCircle = true,
  roundedIcon = false,
  style = {
    paddingLeft: 16,
    paddingTop: 18,
    innerPadding: 8,
    textLeft: 48,
    textPaddingTop: 4,
    textSpace: 14,
  },
  selected,
  hover,
}) => {
  const coords = {
    x: x - width / 2,
    y: y - height / 2,
    w: width,
    h: height,
  };

  if (opacity) ctx.globalAlpha = opacity;

  ctx.fillStyle = backgroundColor;
  ctx.fillRect(coords.x, coords.y, coords.w, coords.h); // Node background

  ctx.strokeStyle = borderColor;
  ctx.lineWidth = hover || selected ? 3 : 1;
  ctx.strokeRect(coords.x, coords.y, coords.w, coords.h); // Node outer border

  ctx.fillStyle = color;
  ctx.fillRect(coords.x, coords.y, 3, coords.h);

  if (backgroundCircle) {
    ctx.globalAlpha = circleTransparency || 0.3;
    ctx.beginPath();
    ctx.opacity = circleTransparency || 0.3;
    ctx.arc(
      coords.x + style.paddingLeft + 12,
      coords.y + style.paddingTop + 6,
      12,
      0,
      2 * Math.PI,
    );
    ctx.arc(
      coords.x + style.paddingLeft + 12,
      coords.y + style.paddingTop + 6,
      12,
      0,
      2 * Math.PI,
    );
    ctx.fillStyle = circleColor || color;
    ctx.fill();
    ctx.globalAlpha = opacity;
  }

  if (icon) {
    const iconImage = document.createElement('img');
    iconImage.setAttribute('src', icon);

    if (roundedIcon) {
      ctx.save();
      ctx.beginPath();
      ctx.arc(
        coords.x + style.paddingLeft + 22,
        coords.y + style.paddingTop + 16,
        iconSize / 2 - 2,
        0,
        2 * Math.PI,
      );
      ctx.arc(
        coords.x + style.paddingLeft + 22,
        coords.y + style.paddingTop + 16,
        iconSize / 2 - 2,
        0,
        2 * Math.PI,
      );
      ctx.closePath();
      ctx.clip();
    }

    ctx.drawImage(
      iconImage,
      coords.x + style.paddingLeft + 6,
      coords.y + style.paddingTop,
      iconSize,
      iconSize,
    );

    if (roundedIcon) {
      ctx.restore();
    }
  } else {
    const RelationImage = document.createElement('img');
    RelationImage.setAttribute('src', RelationIcon);
    ctx.drawImage(
      RelationImage,
      coords.x + style.paddingLeft,
      coords.y + style.paddingTop - 6,
      24,
      24,
    );
    ctx.drawImage(
      RelationImage,
      coords.x + style.paddingLeft,
      coords.y + style.paddingTop - 6,
      24,
      24,
    );
  }

  if (actionIcon) {
    const actionIconSize = 12;
    const actionImage = document.createElement('img');
    actionImage.setAttribute('src', actionIcon);
    ctx.drawImage(
      actionImage,
      coords.x + coords.w - 21,
      coords.y + style.paddingTop / 2 + 3,
      actionIconSize,
      actionIconSize,
    );
    ctx.drawImage(
      actionImage,
      coords.x + coords.w - 21,
      coords.y + style.paddingTop / 2 + 3,
      actionIconSize,
      actionIconSize,
    );
  }

  ctx.font = labelFontStyle;
  ctx.fillStyle = labelFontColor;
  ctx.fillText(
    label,
    coords.x + style.textLeft,
    coords.y + style.paddingTop + (subText ? 0 : 8) + style.textPaddingTop,
  );

  ctx.font = subTextFontColor;
  ctx.fillStyle = subTextFontColor;
  subText &&
    ctx.fillText(
      `${subText}`,
      coords.x + style.textLeft,
      coords.y + style.paddingTop + style.textPaddingTop + style.textSpace,
    );

  ctx.font = subTextFontStyle;
  ctx.fillStyle = subTextFontColor;

  if (altText) {
    ctx.font = altTextFontStyle;
    ctx.fillStyle = altTextFontColor;
    altText &&
      ctx.fillText(
        `${altText}`,
        coords.x + coords.w - (altText >= 9 ? 21 : 18) - altTextOffset,
        coords.y + coords.h - style.paddingTop / 2,
      );
  }

  if (opacity) ctx.globalAlpha = 1;

  return ctx && ctx;
};

Card.propTypes = {
  ctx: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.string,
  actionIcon: PropTypes.object,
  subText: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  labelFontStyle: PropTypes.string,
  labelFontColor: PropTypes.string,
  subTextFontColor: PropTypes.string,
  subTextFontStyle: PropTypes.string,
};
