import React from 'react';
import { withRouter } from 'react-router-dom';
import { Action } from '@cluedin/components';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';
import DashboardWidget from '../../../appBuilder/dashboard/DashboardWidget';

const DashboardAction = ({ action, history }) => {
  const GivenIcon = getAppropriateIcon(action.icon || 'Help');

  return (
    <DashboardWidget key={action.name}>
      <Action
        menuDescriptor={{
          displayName: (
            <div>
              <div>View all</div>
              <div>{action.name}</div>
            </div>
          ),
          icon: <GivenIcon />,
          key: action.name,
          hasNew: false,
          onClick: () => {
            if (action.url.indexOf('https://') > -1) {
              const win = window.open(action.link, '_blank');
              win.focus();
            } else {
              // ADD MODULE NAME
              history.push(action.url);
            }
          },
          isPromoted: false,
        }}
      />
    </DashboardWidget>
  );
};

const EnhancedDashbaordAction = withRouter(DashboardAction);

export default {
  name: 'DashboardAction',
  type: 'dashboardAction',
  required: 'dashboard',
  Component: EnhancedDashbaordAction,
  parameters: [
    {
      type: 'object',
      name: 'action',
    },
  ],
};
