import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { connect } from 'react-redux';
import { PrimaryButton, CancelButton, Modal } from '@cluedin/form';
import Alert from 'uxi/Alert';

import { isUserBlockedAndCurrentUserExcluded } from '../../../../user/helper';
import UserListContainer from '../../../../user/components/containers/lists/UserListContainer';

class InviteUserToAddIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIds: [],
    };
  }

  onChangeHandler(e, selectedIndex, selectedRows) {
    this.setState({
      userIds: selectedRows,
    });
  }

  render() {
    const {
      show,
      onClose,
      onInvite,
      isInvalid,
      isInviting,
      currentUser,
      integration = {},
    } = this.props;

    const { userIds } = this.state;

    if (!show) {
      return null;
    }

    const alertContent = (
      <Alert type="danger">
        <FormattedMessage id="module-integration-error" />
      </Alert>
    );

    return (
      <Modal
        width="calc(100% - 20vw)"
        isOpen={show}
        onClose={onClose}
        title={
          <FormattedMessage
            id="module-integration-inviteUserToAdd"
            values={{
              integrationName: integration.Name,
            }}
          />
        }
        footer={{
          content: (
            <>
              <PrimaryButton
                loading={isInviting}
                style={{ marginRight: '8px' }}
                text={<FormattedMessage id="module-integration-invite" />}
                disabled={isInviting || !userIds || userIds.length === 0}
                onClick={() =>
                  onInvite({ providerId: integration.Id, userIds })
                }
              />
              <CancelButton
                onClick={onClose}
                text={<FormattedMessage id="module-integration-inviteCancel" />}
              />
            </>
          ),
        }}
      >
        {isInvalid && alertContent}

        <UserListContainer
          condensed
          onChange={this.onChangeHandler.bind(this)}
          filter={(user) =>
            isUserBlockedAndCurrentUserExcluded(user, currentUser)
          }
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps)(InviteUserToAddIntegration);
