import { apiRequest } from '../core/helpers/request';

export const fetchAllRetentionSetup = (page = 0, take = 20) =>
  apiRequest('GET', `api/v1/retention?page=${page}&take=${take}`);

export const deleteRetention = (id) =>
  apiRequest('DELETE', `api/v1/retention?id=${id}`);

export const createEntityRetention = ({ entityId, ttl }) =>
  apiRequest('PUT', `api/v1/timetolive?id=${entityId}&timeToLive=${ttl}`);

/**
  {
    "Query": "query {}",
    "Variables": "",
    "OperationName": "",
    "Retention": 41831031,
    "Name": "All Mail",
    "Description": "Mail should only be kept for 7 years"
}
*/
export const createQueryRetention = (query) =>
  apiRequest('PUT', 'api/v1/querytimetolive', query);

export const deleteMultipleRetention = (ids) => {
  const arrayOfDeletionPromises = ids.map(deleteRetention);

  return Promise.all(arrayOfDeletionPromises);
};
