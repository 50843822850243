import { Link } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
import FirstLetterComponent from '../../../core/components/composites/FirstLetterComponent';

const DataSetIcon = ({ providerName, dataSet, dataSetId, size }) => {
  const name = dataSet ? dataSet.name : providerName;

  return (
    <div style={{ display: 'inline-block' }}>
      <Tooltip overlay={<span>View in {name}</span>} placement="bottom">
        <Link
          style={{
            display: 'block',
            marginRight: '5px',
          }}
          to={`/admin/inbound/datasourceset/dataset/detail/${dataSetId}`}
        >
          <FirstLetterComponent size={size || 's'} name={name} />
        </Link>
      </Tooltip>
    </div>
  );
};

export default DataSetIcon;
