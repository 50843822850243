"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var TdInnerWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  box-sizing: border-box;\n  display: flex;\n  display: grid;\n  flex-flow: row nowrap;\n  align-items: stretch;\n  align-items: center;\n  width: 100%;\n  padding: 0 0 0 16px;\n  transition: all 0ms cubic-bezier(0.5, 1, 0.5, 1);\n"])));
TdInnerWrapper.displayName = 'TdInnerWrapper';
var _default = TdInnerWrapper;
exports.default = _default;