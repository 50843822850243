import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTheme } from '@cluedin/theme';
import { toUpper } from 'lodash';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';

const SearchLinkForEntityType = ({
  entityType,
  entityConfig = {},
  children,
}) => {
  const isOldSearchEnabled = useOrganizationFeatureFlagOption('OldSearch');

  const theme = useTheme();

  const displayName =
    entityConfig && entityConfig.displayName
      ? entityConfig.displayName
      : entityType;

  const query = new URLSearchParams('');

  query.set(
    toUpper('entityType'),
    encodeURI(JSON.stringify([entityType || displayName])),
  );

  return (
    <Link
      style={{ color: theme?.palette?.themePrimary }}
      to={
        isOldSearchEnabled
          ? `/search?q=*&filters=entityType:${entityType}|${displayName}&sort=relevance`
          : {
              pathname: '/search',
              search: query.toString(),
              state: { fromExternalPage: true },
            }
      }
    >
      {children}
    </Link>
  );
};

const mapDispatchToProps = (
  { wms: { entityConfigurations } },
  { entityType },
) => {
  const entityConfig = (entityConfigurations || []).find(
    (e) => e.entityType === entityType,
  );
  return {
    entityType,
    entityConfig,
  };
};

export default connect(mapDispatchToProps)(SearchLinkForEntityType);
