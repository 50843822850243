import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { shouldFetchValuesForSchemaPropertyKey } from '../../actions';

const EnhancedValuesForSchemaContainer = compose(
  lifecycle({
    componentDidMount() {
      const { fetchValues } = this.props;
      fetchValues();
    },
    componentDidUpdate(prevProps) {
      const { fetchValues, propertyKey } = this.props;
      if (prevProps.propertyKey !== propertyKey) {
        fetchValues();
      }
    },
  }),
);

const mapStateToProps = ({ schema: { valuesForSchema } }, { propertyKey }) => {
  const valuesForKey = (valuesForSchema || {})[propertyKey] || {};

  return {
    isFetching: valuesForKey.isFetching,
    invalid: valuesForKey.invalid,
    values: valuesForKey.values,
  };
};

const mapDispatchToProps = (dispatch, { propertyKey }) => ({
  fetchValues() {
    if (propertyKey) {
      dispatch(shouldFetchValuesForSchemaPropertyKey(propertyKey));
    }
  },
});

export const withSchemaValue = (Comp) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EnhancedValuesForSchemaContainer(Comp));
};

export default withSchemaValue;
