import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { Ellipsis } from '@cluedin/components';

import { ListValueWrapper, ListRawValue } from './ListValue.styles';

const ListValue = ({ value, vocabularyKey }) => {
  const dataType = get(vocabularyKey, 'dataType');
  const isValidDate = moment(value.toString()).isValid();

  if (dataType === 'DateTime' || dataType === 'Date') {
    return (
      <Ellipsis>
        <ListValueWrapper>
          {value && (
            <div>{value && isValidDate && moment(value).format('LLL')}</div>
          )}

          <ListRawValue>{value}</ListRawValue>
        </ListValueWrapper>
      </Ellipsis>
    );
  }

  return (
    <Ellipsis>
      <ListValueWrapper>{value}</ListValueWrapper>
    </Ellipsis>
  );
};

export default ListValue;

ListValue.propTypes = {
  value: PropTypes.string,
  vocabularyKey: PropTypes.object,
};

ListValue.defaultProps = {
  value: '',
  vocabularyKey: {},
};
