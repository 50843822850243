import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
} from '../core/helpers/action';
import { getMeshes } from './data';
import { approveMeshCmd, rejectMeshCmd } from '../entityActions/data';

export const getAllMeshesActions = createAsyncAction('ALL_MESHES');

export const shouldFetchMeshes = defaultAsyncCallWithActions(
  getMeshes,
  getAllMeshesActions,
);

export const getMoreMeshesActions = createAsyncAction('ALL_MESHES_MORE');
export const shouldFetchMoreMeshes = defaultAsyncCallWithActions(
  getMeshes,
  getMoreMeshesActions,
);

export const approveMeshCmdActions = createAsyncAction(
  'APPROVE_MESH_CMD_FROM_CENTER',
);

const onApproveMeshCmdSuccess = () => {
  // dispatch(shouldFetchMeshes());
};

export const shouldApproveMeshCmd = noBodyResponseAsyncCallWithActions(
  approveMeshCmd,
  approveMeshCmdActions,
  onApproveMeshCmdSuccess,
);
export const rejectMeshCmdActions = createAsyncAction(
  'REJECT_MESH_CMD_FROM_CENTER',
);

export const shouldRejectMeshCmd = noBodyResponseAsyncCallWithActions(
  rejectMeshCmd,
  rejectMeshCmdActions,
);
