import { Link, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { register } from '../../registry';
import {
  WidgetHeader,
  WidgetContainer,
} from '../../../components/composites/widgets';
import EntityTypeAvatar from '../../../../entityTypes/components/containers/EntityTypeAvatar';

import { useAggregationSearchForEntity } from './useAggregationSearchForEntity';
import PageLoader from '../../../../core/components/composites/PageLoader';

const RelationItem = styled.div`
  display: flex;
  padding: 6px 12px;
  align-items: center;
`;

const RelationContainer = styled.div`
  & div:first-child {
    border-top: 0;
  }
  & div {
    border-top: 1px solid #ececec;
  }
`;

const EmptyRelation = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;

const EntityRelationsV2 = withRouter(({ entity, history, template }) => {
  const { data, loading, error } = useAggregationSearchForEntity(entity.id);
  const { rootUrl } = template;
  const entityTypeFacets = get(data, 'Facets.entityType.terms', []);
  const isEmpty = entityTypeFacets?.length === 0;

  return (
    <WidgetContainer style={{ marginTop: '24px' }}>
      <WidgetHeader
        title={
          <FormattedMessage id="module-entityWidget-entityRelationsWidgetTitle" />
        }
      />
      <RelationContainer>
        {loading && (
          <div style={{ marginBottom: '12px' }}>
            <PageLoader />
          </div>
        )}
        {isEmpty && (
          <EmptyRelation>
            <FormattedMessage id="module-entityWidget-entityRelationsWidgetNoRelations" />
          </EmptyRelation>
        )}
        {entityTypeFacets.map((entityTypeCount) => {
          return (
            <RelationItem>
              <div style={{ flex: 1 }}>
                <EntityTypeAvatar entityType={entityTypeCount.term} />
              </div>
              <div>{entityTypeCount.count}</div>
            </RelationItem>
          );
        })}
        <RelationItem style={{ justifyContent: 'center' }}>
          <Link to={`${rootUrl}/relations`}>
            <FormattedMessage id="module-entityWidget-entityRelationsWidgetViewAllRelations" />
          </Link>
        </RelationItem>
      </RelationContainer>
    </WidgetContainer>
  );
});

register('EntityRelationsV2', EntityRelationsV2, {
  name: 'EntityRelationsV2',
  displayName: 'Entity Relations',
  description: 'All relations.',
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
