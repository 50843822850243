import React from 'react';
import { Flex } from 'uxi/Layout';
import { FormattedHTMLMessage } from '@cluedin/locale';

import Col from '../../../wms/components/composites/Col';
import Row from '../../../wms/components/composites/Row';
import IntegrationRepartionsListContainer from '../containers/reports/IntegrationRepartionsListContainer';
import TotalUsage from '../../../organization/components/containers/TotalUsage';
import TotalConfigurationsKPIContainer from '../../../configuration/components/containers/visualizations/TotalConfigurationsKPIContainer';
import DashboardTitle from '../../../core/components/composites/dashboard/DashboardTitle';
import DashboardTileActions from './DashboardTileActions';

const DashboardStyle = {
  kpi: {
    background: '#fff',
    height: '170px',
    position: 'relative',
    border: '1px solid #ccc',
    padding: '15px',
    textAlign: 'center',
  },
};

const Dashboard = ({
  history,
  goToDatasources,
  goToApplications,
  goToConfigurations,
  goToManualDataEntryProject,
}) => (
  <div style={{ margin: '16px 32px' }}>
    <DashboardTileActions
      history={history}
      goToDatasources={goToDatasources}
      goToApplications={goToApplications}
      goToConfigurations={goToConfigurations}
      goToManualDataEntryProject={goToManualDataEntryProject}
    />

    <DashboardTitle>
      <FormattedHTMLMessage id="module-integration-report" />
    </DashboardTitle>

    <Row style={{ margin: '30px -15px' }}>
      <Col size={3}>
        <Flex style={DashboardStyle.kpi}>
          <div className="test_Integration_Dashboard_TotalConfigurationsKpi">
            <TotalConfigurationsKPIContainer />
          </div>
        </Flex>
        <Flex
          style={{
            ...DashboardStyle.kpi,
            marginTop: '28px',
            marginBottom: '30px',
          }}
        >
          <div className="test_Integration_Dashboard_TotalUsage">
            <TotalUsage />
          </div>
        </Flex>
      </Col>
      <Col size={9}>
        <div className="test_Integration_Dashboard_IntegrationList">
          <IntegrationRepartionsListContainer />
        </div>
      </Col>
    </Row>
  </div>
);

export default Dashboard;
