import { useState } from 'react';
import {
  EntityCodePanelHeading,
  EntityCodePanelHeadingWrapper,
  OriginCodeWrapper,
} from './styled';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

import { ClipboardIcon } from '@cluedin/svgs';
import { Checkmark } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';
import Tooltip from 'rc-tooltip';
import { copyToClipboard } from '../../../../core/helpers/dom';

const OriginCode = ({ url }) => {
  return (
    <OriginCodeWrapper>
      <span>{url}</span>
    </OriginCodeWrapper>
  );
};

const EntityCodePanel = (attributeOrigin) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const copyToClipboardAndSuccess = () => {
    setShowCopiedMessage(true);
    copyToClipboard(attributeOrigin.attributeOrigin);

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  return (
    <>
      <EntityCodePanelHeadingWrapper>
        <EntityCodePanelHeading>
          <FormattedMessage id="module-entity-entityCodesPanelOriginCodeHeading" />
        </EntityCodePanelHeading>
        <ButtonLink
          text={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!showCopiedMessage && (
                <Tooltip
                  placement="top"
                  trigger="hover"
                  overlay={
                    <FormattedMessage id="module-entity-entityCodesPanelCopyOrigin" />
                  }
                >
                  <ClipboardIcon />
                </Tooltip>
              )}
              {showCopiedMessage && (
                <Tooltip
                  placement="top"
                  trigger="hover"
                  overlay={
                    <FormattedMessage id="module-entity-entityCodesPanelOriginCopied" />
                  }
                >
                  <Checkmark color={themePrimary} size={14} />
                </Tooltip>
              )}
              <div style={{ marginLeft: '6px' }}>
                <FormattedMessage id="module-entity-entityCodesPanelCopyCode" />
              </div>
            </span>
          }
          onClick={() => {
            copyToClipboardAndSuccess();
          }}
        />
      </EntityCodePanelHeadingWrapper>
      <OriginCode url={attributeOrigin.attributeOrigin} />
    </>
  );
};

export default EntityCodePanel;
