import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { shouldFetchAllRetention } from '../../actions';
import { ListPage } from '../../../core/components/Hocs/ListPage';

import RetentionListContainer from './lists/RetentionListContainer';

export default ListPage({
  title: (
    <FormattedMessage
      id="module-dataRetention-allRetention"
      defaultMessage="All Retention Policies"
    />
  ),
  moduleName: 'retention',
  entityName: 'retention',
  List: RetentionListContainer,
  fetch: shouldFetchAllRetention,
  noDataText: (
    <FormattedMessage
      id="module-dataRetention-noRetention"
      defaultMessage="No retention policities"
    />
  ),
});
