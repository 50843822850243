import React from 'react';
import EntityIconUI from '../../entityTypes/generic/EntityIcon';
import withEntityIcon from '../../../wms/components/Hocs/withEntityIcon';
import EntityIconToolTipText from '../../../../viewModels/IEntity/EntityIconTooltipText';

const EntityIcon = (props) => {
  const {
    entityIconName = 'Help',
    entityType,
    entityConfig = { icon: 'Help' },
    style,
  } = props;
  return (
    <EntityIconToolTipText
      entityType={entityType}
      isConfigured={
        entityConfig && entityConfig?.icon && entityConfig?.displayName
      }
    >
      <div style={style || {}}>
        <EntityIconUI icon={entityIconName} {...props} />
      </div>
    </EntityIconToolTipText>
  );
};

EntityIcon.defaultProps = {
  entityType: '',
  size: 'M',
  borderless: false,
};

export default withEntityIcon(EntityIcon);
