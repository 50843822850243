import React from 'react';
import { DataGridv2 } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import StatusBadge from './StatusBadge';

const ServerStatus = (props) => {
  return (
    <>
      <StatusBadge status={props.ServiceStatus} />
    </>
  );
};

const ServerStatusTable = ({ statuses = [] }) => {
  return (
    <DataGridv2
      data={statuses}
      model={[
        {
          property: 'Type',
          displayName: <FormattedMessage id="module-coreStatusCheck-Type" />,
        },
        {
          property: 'ServiceStatus',
          Component: ServerStatus,
          displayName: <FormattedMessage id="module-coreStatusCheck-Status" />,
        },
      ]}
    />
  );
};

export default ServerStatusTable;
