import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { createCleanProjectsByEntities } from '../../../../development/data';
import { Stepper } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { injectIntl } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import withSchema from '../../../../schema/components/Hocs/withSchema';
import { withIntegration } from '../../../../integration/components/hocs/withIntegration';
import { usePrevious } from '../../../../core/hooks';
import cogoToast from 'cogo-toast';
import { useSearchQueryFromHooks } from '../../../hooks/useSearchQueryFromHooks';
import CleanGqlVocabularyKeyConfiguration from './CleanGqlVocabularyKeyConfiguration';

import CleanGQlQueryConfiguration from './CleanGQlQueryConfiguration';
import PageLoader from '../../../../core/components/composites/PageLoader';

import { unicodeEncodeGqlCursor } from '../../../../core/helpers/encoding';

const createLimit = 10000;

const createCursor = ({ pageSize = 10000, page = 0 }) => {
  const cursorObj = {
    pageSize: pageSize,
    page: page,
  };

  return unicodeEncodeGqlCursor(JSON.stringify(cursorObj));
};

const defaultStepperState = { active: 1, completed: [] };

const CleanGqlQueryWizzard = ({
  searchStat,
  gqlOptions,
  intl,
  loading,
  onClose,
  history,
}) => {
  const [projectName, setProjectName] = useState('');
  const [errorCreating, setErrorCreating] = useState('');
  const [creating, setCreating] = useState('');
  const [cleanDataParts, setCleanDataParts] = useState(false);
  const [validSearchFilter, setValidSearchFilter] = useState('');
  const [stepperState, setStepperState] = useState(defaultStepperState);
  const [pageNumber, setPageNumber] = useState(1);

  const hasMinimalSearchValue = searchStat > createLimit;
  const variables = gqlOptions.variables || {};

  const entityTypeFilter = (variables.filters || []).find(
    (f) => f.aggregationName === 'entityType',
  );

  const providerFilter = (variables.filters || []).find(
    (f) => f.aggregationName === 'providers',
  );

  const entityType = ((entityTypeFilter || {}).values || [])[0];
  const providerTypes = (providerFilter || {}).values || [];
  const onlyOneEntityType =
    ((entityTypeFilter || {}).values || []).filter((c) => c).length === 1;

  const [selectedSchema, setSelectedSchema] = useState([]);

  const maxPage = Math.ceil(searchStat / 5);

  const tooBig = pageNumber > maxPage;
  const tooSmall = pageNumber < 1;

  const [getEntities, { entitiesLoading, data }] = useSearchQueryFromHooks();

  const prevData = usePrevious(data);

  useEffect(() => {
    if (data && data != prevData) {
      var entityIds = data.map((item) => item.id);

      createCleanProjectsByEntities({
        projectName,
        entityIds,
        source: 'Search Results',
        vocabularyKeys: selectedSchema,
        cleanDataParts,
        providers: providerTypes,
      })
        .then(() => {
          cogoToast.success(`Clean Project successfully created`, {
            position: 'bottom-right',
            hideAfter: 5,
          });
          history.push('/admin/preparation/clean');
          onClose();
        })
        .catch((err) => {
          setErrorCreating(true);
          setCreating(false);
          console.log('Error while creating cleaning projects:'); // eslint-disable-line no-console
          console.log(err); // eslint-disable-line no-console
        });
    }
  }, [data]);

  useEffect(() => {
    setValidSearchFilter(onlyOneEntityType);
  }, [onlyOneEntityType]);

  const handleSecondStep = useCallback(() => {
    setStepperState({
      active: 2,
      completed: [1],
    });
  }, [setStepperState]);

  const resetStep = useCallback(() => {
    setSelectedSchema([]);
    setStepperState({
      active: 1,
      completed: [],
    });
  }, [setStepperState]);

  return (
    <>
      <Stepper
        activeStep={stepperState.active}
        completedSteps={stepperState.completed}
        steps={[
          {
            label: (
              <FormattedMessage id="module-clean-createCleanProjectFromSearchPart1" />
            ),
          },
          {
            label: (
              <FormattedMessage id="module-clean-createCleanProjectFromSearchPart2" />
            ),
          },
        ]}
      />

      <>
        {errorCreating && (
          <Alert type="Danger">
            <FormattedMessage id="module-clean-cleanErrorCreatingProject" />
          </Alert>
        )}

        {loading && <PageLoader />}

        {!loading && stepperState.active === 1 && (
          <CleanGQlQueryConfiguration
            validSearchFilter={validSearchFilter}
            intl={intl}
            projectName={projectName}
            setProjectName={setProjectName}
            searchStat={searchStat}
            hasMinimalSearchValue={hasMinimalSearchValue}
            onlyOneEntityType={onlyOneEntityType}
            cleanDataParts={cleanDataParts}
            setCleanDataParts={setCleanDataParts}
            providerTypes={providerTypes}
            entityType={entityType}
            onCancel={onClose}
            pageNumber={pageNumber}
            pageNumberTooBig={tooBig}
            pageNumberTooSmall={tooSmall}
            maxPage={maxPage}
            setPageNumber={setPageNumber}
            onNext={() => {
              handleSecondStep();
            }}
          />
        )}

        {!loading &&
          stepperState.active === 2 &&
          validSearchFilter &&
          entityType && (
            <CleanGqlVocabularyKeyConfiguration
              selectedSchema={selectedSchema}
              setSelectedSchema={setSelectedSchema}
              onCancel={resetStep}
              creating={creating}
              onCreatecleanProject={() => {
                setCreating(true);
                setErrorCreating(false);

                const cursor = createCursor({
                  pageSize: createLimit,
                  page: pageNumber - 1,
                });

                const toSend = {
                  ...variables,
                  cursor,
                };

                getEntities(toSend);
              }}
              disabledCreation={
                !onlyOneEntityType ||
                !projectName ||
                (selectedSchema || []).length === 0 ||
                entitiesLoading ||
                creating
              }
            />
          )}
      </>
    </>
  );
};

export default injectIntl(
  withIntegration(withSchema(withRouter(CleanGqlQueryWizzard))),
);
