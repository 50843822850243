import { connect } from 'react-redux';
import { compose, lifecycle, renderComponent, branch } from 'recompose';
import { env } from '../../../../config';
import DefaultAlert from '../../../core/components/composites/alerts/DefaultAlert';
import PageLoader from '../../../core/components/composites/PageLoader';
import UserConsentForEndUser from '../composites/UserConsentForEndUser';
import {
  shouldUpdateUniqueIdentifier,
  shouldUpdateDataProcessor,
  shouldSaveConsentEntries,
  shouldFetchConsentFormById,
} from '../../actions';
import { shouldFetchCurrentOrganization } from '../../../organization/actions';

import {
  toConsentFormViewModel,
  fromConsentFormtoConsumableForWebsite,
} from '../../converter';
import { shouldFetchAllIntegrations } from '../../../integration/actions';

let apiToken = '';
if (env === 'production') {
  // ! this is the "machine learning" token:
  apiToken =
    'vB9-LbiuJU3i75I23nDCF73QGybpm_9COFcqZ7vIO4RflTBzSMVaHu69D5oHLjgUAl-1PuNohXnfQasgG60l67wIGPdtjy36_e6cfFVTRdyqK0vwcAbYwzKXMaDQ-kFz870eTkWCo-0dH-h2mhXG61vsX7WV90GIJBhYbmc47yI14HPwp5M_h0p4s15PdXzQSANu3bqj9GVjoURkyIhuCxBDVcdiwETt8101gGu-HGKFztPfY4NZ_YT7UrKtCBbcurnvgmcDOde9g-mG8HpK9lHf6k7rdjifd5KneKY-EhT-9_SB5CnPDjyCCp9ZQ3WHOciItutpRXgvcAMotzjsJEYYRoAPUDpc3I3rO1sjkSX6DEdP4OZaOcr6tzH-VXr3ilCb0CFnGYWpMllKdwwS_EIlttnvZ1aEc8i3vn3DDnEt57Wyd9Osc_6nFtcGFGvxATutGpWjWXhEy9_69pMwOJnhEBb-8EJCMAz_7eNpDgh3-h0LK9Sk3GM7FJwu2AvGfGUuwnsQXxIYLAnhd0mvFCP5-z929ErjE2vRz2-Ow2aZ0QbF';
} else if (env === 'test') {
  apiToken =
    'Vw27NZGvTIriM-rS_H5NetcXUcWzb5ocWEzlD5TW2LGWaEhA8ZRYj3XTAsjd5rUiIqsyYwU9s1QhhPlNGBvIIQSO4HlAPiU6tMTIT8LHOBK7hBn9CTUwqYqqA2q7Z31mhL92kxjee08G9FXcyya5aPmnkbwaSTUwVPST6lHmjjDhaMF-WQRjstVhnOxH6rb3t6AEAh_1xBNzCGTLOr6e_MnqvTbfgM17Xcex_E8_ELoqdNiTyheLjT0rOhafE2UcCZwFSu_YWm0KnSRSi4TaPNsK_YQwD1tWCsYaDsiREnYM2rlIEkAY3zc25QkxGSrrLQp2eNLpifZORrbFOD1onIBQY3_T8LsNzw6kmrdZytEj1Gy84kiRAEjN4UuFDwiaN9pR1AYNpmuL5yMN-GdvCbentVTXei-E0P6fCDbwmfHX2P058L3GKOLWppJitxmpGEJMgsHfLH3dgV26rc1KRS3RA7aZfEWfqoac-l1DL6l7zoe_7QfJd2mNFjctlzqMR6LBciIH5ppBqCkjxLdCHhogbfI3q6Zxaz9NM50P-4K6J2xEuHlJ5u-mNs7R3wG-4HMltw';
}

const UserConsentForEndUserContainerEnhanced = compose(
  lifecycle({
    componentDidMount() {
      const {
        consentFormId,
        fetchIntegration,
        shouldFetchOrganization,
        fetchConsentForm,
      } = this.props;

      fetchIntegration();
      shouldFetchOrganization();

      if (consentFormId) {
        fetchConsentForm(consentFormId);
      }
    },
  }),
  branch(({ isFetching }) => isFetching, renderComponent(PageLoader)),
  branch(
    ({ isFetchingConsentFromByIdError, invalid }) =>
      isFetchingConsentFromByIdError || invalid,
    renderComponent(DefaultAlert),
  ),
)(UserConsentForEndUser);

const mapToStateProps = (
  {
    org: { organization, isFetchingOrganization },
    integration: { allIntegrations, isFetchingAllIntegrations },
    consent: {
      currentUserConsentToken,
      isFetchingConsentFromById,
      isFetchingConsentFromByIdError,
      consentForm,
      saveWebsiteConsentEntries,
    },
  },
  { consentFormId },
) => {
  const isFetching =
    isFetchingAllIntegrations ||
    isFetchingConsentFromById ||
    isFetchingOrganization;

  const status =
    !isFetching && consentFormId && saveWebsiteConsentEntries[consentFormId]
      ? saveWebsiteConsentEntries[consentFormId]
      : {};

  return {
    currentUserConsentToken,
    isFetching,
    isSaving: status.isFetching,
    done: status.done,
    invalid: status.invalid,
    currentOrganization: organization,
    isFetchingConsentFromByIdError,
    consentForm:
      !isFetching && consentForm
        ? toConsentFormViewModel(consentForm, allIntegrations)
        : {},
  };
};

const mapDispatchToProps = (dispatch, { generatedConsentToken }) => ({
  fetchConsentForm: (consentFormId) => {
    dispatch(shouldFetchConsentFormById(consentFormId, apiToken));
  },
  shouldFetchOrganization() {
    // TODO : WHY PASSING A TOKE N???
    dispatch(shouldFetchCurrentOrganization(apiToken));
  },
  fetchIntegration() {
    dispatch(shouldFetchAllIntegrations(apiToken));
  },
  onChangeUniqueIdentifier: (consent) => {
    dispatch(shouldUpdateUniqueIdentifier(consent));
  },
  onChangeDataProcessor: (consent) => {
    dispatch(shouldUpdateDataProcessor(consent));
  },
  onSaveConsentEntries: (selectedFormId, result, consentForm) => {
    const consentForEndUser = fromConsentFormtoConsumableForWebsite(
      consentForm,
      result,
    );

    dispatch(
      shouldSaveConsentEntries({
        formId: selectedFormId,
        consentEntries: result,
        apiToken,
        generatedConsentToken,
        consentForEndUser,
      }),
    );
  },
  onClose: (selectedFormId, result = [], consentForm) => {
    // eslint-disable-line no-unused-vars
    const nullResult = [];
    const consentForEndUser = fromConsentFormtoConsumableForWebsite(
      consentForm,
      nullResult,
    );

    dispatch(
      shouldSaveConsentEntries({
        formId: selectedFormId,
        consentEntries: nullResult,
        apiToken,
        generatedConsentToken,
        consentForEndUser,
      }),
    );
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(UserConsentForEndUserContainerEnhanced);
