import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

import MainHelpSection from '../composites/sections/MainHelpSection';
import HelpTopic from '../composites/sections/HelpTopic';

const HelpContainer = ({ selected, onMainMenu, onTopicClick }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  return (
    <div
      className="test_HelpCenter_container"
      style={{
        padding: '16px 16px 64px 16px',
        marginBottom: '64px',
      }}
    >
      {selected?.topicId ? (
        <HelpTopic
          selectedTopic={selected}
          onTopicClick={onTopicClick}
          onMainMenu={onMainMenu}
          themePrimary={themePrimary}
        />
      ) : (
        <MainHelpSection
          selectedTopic={selected}
          themePrimary={themePrimary}
          onTopicClick={onTopicClick}
        />
      )}
      <div
        style={{ display: 'flex', flexFlow: 'column nowrap', padding: '8px 0' }}
      >
        <a
          key="zendesk"
          style={{ padding: '4px 0', color: themePrimary }}
          href="https://cluedin.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="module-core-submitTicket" />
        </a>
        <a
          key="privacy"
          style={{ padding: '4px 0', color: themePrimary }}
          href="https://www.cluedin.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="module-core-privacyLink"
            defaultMessage="Privacy Policy"
          />
        </a>
      </div>
    </div>
  );
};

export default HelpContainer;
