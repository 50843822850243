import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

export const notFoundMessage = {
  buttonLabel: <FormattedMessage id="module-dataCatalog-vocab-buttonLabel" />,
  message: <FormattedMessage id="module-dataCatalog-vocabNotFound-message" />,
  explanation: (
    <FormattedMessage id="module-dataCatalog-vocabNotFound-explanation" />
  ),
};
