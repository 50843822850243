export const getRandInRange = (min = 0, max = 1) =>
  Math.random() * (max - min) + min;

export const getRandIntInRange = (min = 0, max = 1) =>
  Math.round(getRandInRange(min, max));

export const getRandIntegersInRange = (min = 0, max = 1, howMany = 1) =>
  [...Array(howMany)].map(() => getRandIntInRange(min, max));

export const linearTransformation = (x, a, b, c, d) =>
  ((x - a) / (b - c)) * (d - c) + c; // eslint-disable-line

export const lt = linearTransformation;

/**
 * getScaledValueWithMetricNotation
 * @param {Number} value (not NaN)
 * @param {boolean} shouldRemoveSpaces
 * @returns {String} with appropriate metric scaling and unit
 *
 * 9999 => 9999
 * 10000 => 10 k +
 * 999999 => 999 k +
 * 1000000 => 1 M +
 * 999_999_999 => 999 M +
 * 1_000_000_000 => 1 B +
 * 1_000_000_000_000 => 1000 B +
 *
 */
export const getScaledValueWithMetricNotation = (
  value,
  shouldRemoveSpaces = false,
) => {
  let unit;
  let val = Number(value);

  if (Number.isNaN(val)) {
    return 0;
  }

  const oneThousand = 1000;
  const oneMillion = 1_000_000;
  const oneBillion = 1_000_000_000;

  if (typeof value === 'number' && !isNaN(value)) {
    if (value >= oneBillion) {
      unit = 'B';
      const scaledToBillions = value / oneBillion;
      val = scaledToBillions;
    } else if (value >= oneMillion) {
      unit = 'M';
      const scaledToMillions = value / oneMillion;
      val = scaledToMillions;
    } else if (value > oneThousand) {
      unit = 'k';
      const scaledToThousand = value / oneThousand;
      val = scaledToThousand;
    }
  }

  const floored = Math.floor(val);

  const finalVal = shouldRemoveSpaces
    ? `${floored}${unit ? `${unit}+` : ''}`.trim()
    : `${floored} ${unit ? `${unit} +` : ''}`.trim();

  return finalVal;
};

export const getScaledPercentageValue = (valueProp) => {
  const value = valueProp || 0;

  const v = parseInt(Math.round(value), 10);

  const res = v;

  return res;
};
