import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Dialog from 'uxi/Dialog';
import Panel, { PanelContent, PanelHeader } from 'uxi/Panel';
import { Flex } from 'uxi/Layout';
import { PrimaryButton } from '@cluedin/form';
import SearchStats from './SearchStats';

const panelContentStyle = {
  maxHeight: 'calc(80vh - calc( 2 * 50px ))',
  minHeight: '60vh',
  backgroundColor: '#f3f3f2',
};

const dialogStyle = {
  width: 'calc(100% - 18vw)',
  maxWidth: '1650px',
  left: '8vw',
  right: '8vw',
};

const AdvancedSearchFiltersDialog = (
  { show, children, onClose, searchStat, totalLoading }, // eslint-disable-line
) => (
  <Dialog show={show} style={dialogStyle} onClose={onClose}>
    <Panel onClose={onClose}>
      <PanelHeader
        title={
          <FormattedMessage
            id="SearchContent.advancedSearch"
            defaultMessage="Advanced search"
          />
        }
        hasClose
        style={{
          background: '#fff',
          color: 'inherit',
          borderBottom: '1px solid #cecece',
        }}
      >
        <Flex
          style={{
            marginRight: '24px',
            overflow: 'hidden',
            justifyContent: 'flex-start',
          }}
        >
          <SearchStats
            totalLoading={totalLoading}
            searchStat={searchStat}
            style={{ marginLeft: 'auto', marginRight: '16px' }}
          />
          <PrimaryButton
            text={
              <span data-test="advanced-panel-search-button">
                <FormattedMessage
                  id="SearchContent.search"
                  defaultMessage="Search"
                />
              </span>
            }
            style={{ margin: '0 8px' }}
            onClick={() => {
              onClose();
            }}
          />
        </Flex>
      </PanelHeader>
      <PanelContent style={panelContentStyle}>{children}</PanelContent>
    </Panel>
  </Dialog>
);

AdvancedSearchFiltersDialog.displayName = 'AdvancedSearchFilterDialog';

export default AdvancedSearchFiltersDialog;
