"use strict";

exports.__esModule = true;
exports.useResizeObserver2 = exports.useResizeObserver = exports.default = void 0;
var _react = require("react");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
// import ResizeObserver from 'resize-observer-polyfill'; // from uxi

/**
 * Because we directly depend on uxi directly, we have the resize-observer-polyfill
 * ─┬ uxi@3.0.30
 *  └── resize-observer-polyfill@1.5.1
 */
var useResizeObserver = function useResizeObserver(reactRef, callback) {
  (0, _react.useEffect)(function () {
    var resizeObserver = new window.ResizeObserver(function (entries) {
      callback(entries[0].contentRect);
    });
    resizeObserver.observe(reactRef.current);
    return function () {
      resizeObserver.disconnect();
    };
  }, [reactRef, callback]);
};
exports.useResizeObserver = useResizeObserver;
var useResizeObserver2 = function useResizeObserver2(_ref) {
  var reactRef = _ref.ref,
    onResize = _ref.onResize;
  var _useState = (0, _react.useState)({
      width: undefined,
      height: undefined
    }),
    size = _useState[0],
    setSize = _useState[1];
  var previous = (0, _react.useRef)({
    width: undefined,
    height: undefined
  });
  (0, _react.useEffect)(function () {
    // if (
    //   typeof ref !== "object" ||
    //   ref === null ||
    //   !(ref.current instanceof Element)
    // ) {
    //   return;
    // }

    // const element = ref.current;

    var resizeObserver = new window.ResizeObserver(function (entries) {
      // Since we only observe the one element, we don't need to loop over the
      // array
      if (!entries.length) {
        return;
      }
      var entry = entries[0];

      // `Math.round` is in line with how CSS resolves sub-pixel values
      var newWidth = Math.round(entry.contentRect.width);
      var newHeight = Math.round(entry.contentRect.height);
      if (previous.current.width !== newWidth || previous.current.height !== newHeight) {
        var newSize = {
          width: newWidth,
          height: newHeight
        };
        if (onResize) {
          onResize(newSize);
        } else {
          previous.current.width = newWidth;
          previous.current.height = newHeight;
          setSize(newSize);
        }
      }
    });
    resizeObserver.observe(reactRef.current);
    return function () {
      resizeObserver.disconnect();
    };
  }, [reactRef, onResize]);
  return (0, _react.useMemo)(function () {
    return _extends({
      reactRef: reactRef
    }, size);
  },
  // eslint-disable-next-line
  [reactRef, size ? size.width : null, size ? size.height : null]);
};
exports.useResizeObserver2 = useResizeObserver2;
var _default = null;
exports.default = _default;