import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import {
  fetchAllUsers as fetchAllUsersAction,
  shouldFetchCurrentUser as shouldFetchCurrentUserAction,
} from '../../actions';
import PageLoader from '../../../core/components/composites/PageLoader';

const giveId = (u) => ({
  Id: u.Account.Id,
  ...u,
});

export const withAllUser = (Comp, mapToStateProps) => {
  const UserListWithFiltersContainer = compose(
    lifecycle({
      componentDidMount() {
        const {
          shouldFetchCurrentUser,
          fetchAllUsers,

          // fetch all users
          isFetching,
          invalidAllUsers,

          // fetch currentUser
          currentUser,
          isFetchingCurrentUser,
        } = this.props;

        if (!isFetchingCurrentUser && !currentUser) {
          shouldFetchCurrentUser();
        }
        if (!isFetching && !invalidAllUsers) {
          fetchAllUsers();
        }
      },
    }),
    branch(
      (props) => props.isFetching || props.isFetchingCurrentUser,
      renderComponent(PageLoader),
    ),
  )(Comp);

  const mapStateToProps = (
    {
      user: {
        allUsers,
        isFetchingAllUsers,
        invalidAllUsers,
        currentUser,
        isFetchingCurrentUser,
      },
    },
    { filter, selectedIds },
  ) => {
    let filteredUsers = allUsers || [];

    if (selectedIds && selectedIds.length > 0) {
      filteredUsers = allUsers.filter((user) => {
        return selectedIds.find((id) => {
          return user.Account.Id === id;
        });
      });
    }

    const usersFiltered = filter ? filteredUsers.filter(filter) : filteredUsers;
    const entities = usersFiltered.map(giveId);
    const result = {
      entities,
      users: entities,
      isFetching: isFetchingAllUsers,
      invalidAllUsers,
      currentUser,
      isFetchingCurrentUser,
    };

    return mapToStateProps ? mapToStateProps(result) : result;
  };

  const mapDispatchToProps = (dispatch) => ({
    shouldFetchCurrentUser: () => {
      dispatch(shouldFetchCurrentUserAction());
    },
    fetchAllUsers: () => {
      dispatch(fetchAllUsersAction());
    },
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserListWithFiltersContainer);
};

export default {
  withAllUser,
};
