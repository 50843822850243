import { createAction } from 'redux-actions';
import store from 'store';
import {
  getIntegrationById,
  getAllIntegrations,
  getAllIntegrationsForSources,
  inviteUsersToAddIntegration,
  addActiveProvider,
  newOauthConfiguration,
  editConfiguration,
  enableProvider,
  addTrelloToken,
  getAllIntegrationsWithNoFilters,
} from './data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
  noBodyResponseAsyncCallWithActionsWithQueue,
  defaultAsyncCallWithActionsWithParamWithQueue,
} from '../../action/helpers/actionCreator';
import { unauthorized } from '../../action/generic';

export const fetchAllIntegrationsActions = createAsyncAction('INTEGRATION_ALL');

export const fetchAllIntegrationsActionsWithNoFiltersActions =
  createAsyncAction('INTEGRATION_ALL_NOFITERS');

export const shouldFetchAllIntegrations = defaultAsyncCallWithActions(
  getAllIntegrations,
  fetchAllIntegrationsActions,
);

export const shouldFetchAllIntegrationsForSources = defaultAsyncCallWithActions(
  getAllIntegrationsForSources,
  fetchAllIntegrationsActions,
);

export const shouldFetchAllIntegrationsWithDataSourceAndEnricher =
  defaultAsyncCallWithActions(
    getAllIntegrationsWithNoFilters,
    fetchAllIntegrationsActionsWithNoFiltersActions,
  );

export const fetchIntegrationByIdAction =
  createAsyncAction('INTEGRATION_BY_ID');

export const shouldFetchIntegrationById = defaultAsyncCallWithActions(
  getIntegrationById,
  fetchIntegrationByIdAction,
);

export const showInviteAction = createAction('SHOW_INVITATION');
export const hideInviteAction = createAction('HIDE_INVITE');

export const inviteUserToIntegrationAction = createAsyncAction(
  'INTEGRATION_INVITE_USERS',
);

export const shouldInviteUserToIntegration = noBodyResponseAsyncCallWithActions(
  inviteUsersToAddIntegration,
  inviteUserToIntegrationAction,
);

export const shouldAddActiveProviderActions = createAsyncAction(
  'INTEGRATION_SHOULD_ADD_ACTIVE_INTEGRATION',
);
export const shouldAddActiveProvider =
  defaultAsyncCallWithActionsWithParamWithQueue(
    addActiveProvider,
    shouldAddActiveProviderActions,
  );

export const shouldEditActiveProviderActions = createAsyncAction(
  'INTEGRATION_SHOULD_EDIT_ACTIVE_INTEGRATION',
);
export const shouldEditActiveProvider =
  noBodyResponseAsyncCallWithActionsWithQueue(
    editConfiguration,
    shouldEditActiveProviderActions,
  );

export const shouldEnableProviderActions = createAsyncAction(
  'INTEGRATION_SHOULD_ENABLE_ACTIVE_INTEGRATION',
);
export const shouldEnableProvider = noBodyResponseAsyncCallWithActionsWithQueue(
  enableProvider,
  shouldEnableProviderActions,
);

export const shouldEnableProviderReset = createAction(
  'INTEGRATION_SHOULD_ENABLE_ACTIVE_INTEGRATION_RESET',
);

export function shouldAddTrello({ token, integrationId, configuration }) {
  return (dispatch) =>
    addTrelloToken(token).then(() => {
      dispatch(shouldAddActiveProvider({ integrationId, configuration }));
    });
}

export const shouldAddOauthActiveProviderActions = createAsyncAction(
  'INTEGRATION_SHOULD_ADD_ACTIVE_OAUTH_INTEGRATION',
);
export const shouldAddOauthActiveProvider = (param) => (dispatch) => {
  const { name, id, url } = param;

  store.set(name, id); // we store the transaction for the OAUTH.

  dispatch(shouldAddOauthActiveProviderActions.request(param));

  return newOauthConfiguration(url)
    .then((res) => res.body)
    .then((res) => {
      window.location = res;
    })
    .catch(
      unauthorized(
        dispatch,
        shouldAddOauthActiveProviderActions.invalid,
        false,
        param,
      ),
    );
};
