import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Panel, { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import Alert from 'uxi/Alert';
import ConsentEntryForForm from './userConsent/ConsentEntryForForm';
import UserConsentActions from './UserConsentActions';

class UserConsentForEndUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
    };

    this.giveConsentHandler = this.giveConsentHandler.bind(this);
    this.giveNULLConsentHandler = this.giveNULLConsentHandler.bind(this);
  }

  onChangeHandler(consentEntries) {
    this.setState({
      result: consentEntries,
    });
  }

  giveConsentHandler() {
    const {
      onSaveConsentEntries,
      consentFormId,
      currentOrganization,
      consentForm,
      generatedConsentToken,
    } = this.props;

    const { result } = this.state;

    const consentEntriesToSave = result
      .filter((c) => c.checked)
      .map((c) => ({
        KnownIdentifier: `Cluedin#cookieValue:${generatedConsentToken}`,
        ConsentId: c.consentId,
        OrganizationId: currentOrganization.Id,
      }));

    onSaveConsentEntries(consentFormId, consentEntriesToSave, consentForm);
  }

  giveNULLConsentHandler() {
    const {
      onSaveConsentEntries,
      consentFormId,
      // currentOrganization,
      consentForm,
      // generatedConsentToken,
    } = this.props;

    // const {
    //   result,
    // } = this.state;

    const consentEntriesToSave = [];

    onSaveConsentEntries(consentFormId, consentEntriesToSave, consentForm);
  }

  render() {
    const {
      // onClose,
      consentForm,
      isSaving,
      isPreview,
      done,
    } = this.props;

    return (
      <Panel
        onClose={this.giveNULLConsentHandler}
        style={{ maxHeight: '100vh' }}
      >
        <PanelHeader
          title={consentForm ? consentForm.title : 'Consent'}
          hasClose
        />
        {isPreview && (
          <Alert>
            <FormattedMessage
              id="module-consent-previewForm"
              defaultMessage="This is a preview form - This message won\'t show when the user will give its consent."
            />
          </Alert>
        )}
        <PanelContent>
          <div style={{ margin: '14px' }}>
            {!done && (
              <ConsentEntryForForm
                consentFormViewModel={consentForm}
                onChange={(consentEntries) => {
                  this.onChangeHandler(consentEntries);
                }}
              />
            )}
            {done && (
              <Alert type="success">Your consent have been saved.</Alert>
            )}
          </div>
        </PanelContent>
        <PanelFooter hasCancel>
          <UserConsentActions
            isSaving={isSaving}
            onClick={() => {
              this.giveConsentHandler();
            }}
          />
        </PanelFooter>
      </Panel>
    );
  }
}

export default UserConsentForEndUser;
