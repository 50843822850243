import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { GqlErrorMessages } from '@cluedin/form';
import { usePrevious } from '@cluedin/components';
import { useSearch } from './hooks/useSearch';
import { useSearchAggregation } from './hooks/useSearchAggregation';
import { useSearchCount } from './hooks/useSearchCount';
import SearchResult from './SearchResult';
import SearchPageContentLayout from './layouts/SearchPageContentLayout';
import EntityPropertyViewerDetailedContainer from '../../../../entity/entityProperty/EntityPropertyViewerDetailedContainer';
import SearchHeader from './layouts/SearchHeader';
import SearchPropertyFilter from './layouts/SearchPropertyFilter';
import SearchOption from './layouts/SearchOption';
import SearchFilters from './layouts/SearchFilters';
import { shouldUpdateEntityTypePoolIdentifier } from '../../../actions';
import PageLoader from '../../../../core/components/composites/PageLoader';
import { useEntityTypeLayoutConfigurations } from '../../../../entityTypes/hooks/useEntityTypeLayoutConfigurations';

const getSearchType = (pathname) => {
  if (pathname.indexOf('/search/person') > -1) {
    return 'person';
  } else if (pathname.indexOf('/search/organization') > -1) {
    return 'organization';
  } else if (pathname.indexOf('/search/document') > -1) {
    return 'document';
  }

  return 'searchResult';
};

const Search = ({
  location,
  history,
  searchQuery,
  entityTypesConfigurations,
  dispatch,
}) => {
  const [showPropertyFilter, setShowPropertyFilter] = useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [searchType, setSearchType] = useState(
    getSearchType(location.pathname),
  );
  const prevSearchType = usePrevious(searchType);

  const [
    searchResult,
    { isFetching, isFetchingMore, variables, query, fetchMore },
    searchUrl,
    {
      selectedFilters,
      sortType,
      addFilter,
      removeFilter,
      clearAllFilters,
      hasPropertySearch,
      onSortChange,
    },
    totalSearchCount,
    totalLoading,
  ] = useSearch(searchQuery, searchType, entityTypesConfigurations);

  const [aggregationfilters, { loadingFilters, promotedFilters }] =
    useSearchAggregation(entityTypesConfigurations);

  const { q } = variables;

  useEffect(() => {
    history.push(searchUrl);
  }, [searchUrl]);

  useEffect(() => {
    if (searchType && prevSearchType && prevSearchType !== searchType) {
      if (searchType === 'searchResult') {
        history.push(`/search${searchUrl}`);
      } else {
        history.push(`/search/${searchType}${searchUrl}`);
      }
    }
  }, [searchType, searchUrl, prevSearchType]);

  const currentEntityTypePoolIdentifier =
    searchType !== 'searchResult' ? searchType : '';

  return (
    <>
      <EntityPropertyViewerDetailedContainer />
      <SearchPageContentLayout
        searchHeader={
          <SearchHeader
            isFetchingSearch={isFetching}
            sortType={sortType}
            selectedFilters={selectedFilters}
            total={totalSearchCount}
            totalLoading={totalLoading}
            onSortChange={onSortChange}
            q={q}
            showPropertyFilterSearch={showPropertyFilter}
            onShowPropertyFilters={() => {
              setShowPropertyFilter(true);
            }}
            onHidePropertyFilters={() => {
              setShowPropertyFilter(false);
            }}
          />
        }
        searchPropertyFilter={
          <SearchPropertyFilter
            selectedFilters={selectedFilters}
            hasPropertySearch={hasPropertySearch}
            onAddFilter={addFilter}
            onRemoveFilter={removeFilter}
            showPropertyFilterSearch={showPropertyFilter}
          />
        }
        searchOption={
          <SearchOption
            viewName="search"
            searchStat={totalSearchCount}
            isFetchingSearch={isFetching}
            gqlOptions={{
              variables,
              query,
            }}
            q={q}
          />
        }
        searchResults={
          <SearchResult
            searchType={searchType}
            data={searchResult}
            isFetchingMainSearch={isFetching}
            isFetchingMore={isFetchingMore}
            onLoadMore={fetchMore}
            take={27}
          />
        }
        searchFilter={
          <SearchFilters
            filters={aggregationfilters}
            loading={loadingFilters}
            promotedFilters={promotedFilters}
            total={totalSearchCount}
            totalLoading={totalLoading}
            showAdvancedSearch={showAdvancedSearch}
            onShowAdvancedSearch={() => {
              setShowAdvancedSearch(true);
            }}
            onHideAdvancedSearch={() => {
              setShowAdvancedSearch(false);
            }}
            selectedFilters={selectedFilters}
            onAddFilter={addFilter}
            onRemoveFilter={removeFilter}
            onClearAllSearchFilter={clearAllFilters}
            currentEntityTypePoolIdentifier={currentEntityTypePoolIdentifier}
            onEntityTypePoolChange={(e) => {
              setSearchType('searchResult');
              // TODO: remove this at some point
              dispatch(
                shouldUpdateEntityTypePoolIdentifier({
                  searchId: 'main',
                  entityTypePoolIdentifier: '',
                }),
              );
            }}
          />
        }
      />
    </>
  );
};

// Intermediate is to show page load on "full page load when we load entity config"
// TODO: it seems that there is some "double fetching" which changes the ref of the EntityConfigurations
const Intermediate = (props) => {
  const {
    data: entityTypesConfigurations,
    loading,
    error,
  } = useEntityTypeLayoutConfigurations();

  if (!entityTypesConfigurations || loading) {
    return <PageLoader />;
  }

  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  return (
    <Search {...props} entityTypesConfigurations={entityTypesConfigurations} />
  );
};

export default withRouter(Intermediate);
