import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import Flex from 'uxi/Layout/Flex';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import { buttonResetStylesCSSString } from 'uxi/Button/buttonResetStyles';
import { AvatarWithName } from 'uxi/Img';
import { Followentities, Keepintheloop, Notification } from 'uxi/Icons';

const views = {
  notifications: 'notifications',
  followedEntities: 'followedEntities',
  savedSearches: 'savedSearches',
};

const Tab = styled.button.attrs((/* props */) => ({
  onMouseOut: (e) => e.target.blur(),
}))`
  ${buttonResetStylesCSSString};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding: 0 6px;
  flex: 1;
  max-width: ${({ tabCount }) =>
    tabCount !== undefined ? `calc(100% / ${tabCount})` : '33.332%'};
  text-align: center;
  border-bottom: 1px solid #cecece;
  &:not(:last-child) {
    border-right: 1px solid #cecece;
  }
  background: #f2f2f2;
  opacity: 0.9;
  ${({ active }) =>
    active
      ? 'font-weight: bold; border-bottom: transparent; background: white;'
      : 'opacity: .7;'};
`;

class NotificationCenterTabsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: views.notifications,
    };
  }

  render() {
    const { hasSavedSearchFeature, hasFollowFeature } = this.props;

    const { view } = this.state;

    let tabCount = Object.keys(views).length;

    const viewToRenderPropName = views[view];

    const theView = this.props[viewToRenderPropName];

    const hasOtherThanNotification = hasFollowFeature || hasSavedSearchFeature;

    if (!hasFollowFeature) {
      tabCount = tabCount - 1;
    }
    if (!hasSavedSearchFeature) {
      tabCount = tabCount - 1;
    }

    return (
      <>
        {hasOtherThanNotification && (
          <Flex
            style={{
              height: '50px',
              background: 'white',
              position: 'sticky',
              justifyContent: 'flex-start',
              top: 0,
              zIndex: 9,
            }}
          >
            <Tab
              tabCount={tabCount}
              active={view === 'notifications'}
              onClick={() => this.setState({ view: 'notifications' })}
            >
              <div className="test_notificationCenter_notificationTab">
                <AvatarWithName
                  icon={<Notification />}
                  name={
                    <TextEllipsis text="Notifications">
                      <FormattedMessage id="module-notifications-notificationsTab" />
                    </TextEllipsis>
                  }
                  style={{ display: 'inline-flex' }}
                />
              </div>
            </Tab>
            {hasFollowFeature && (
              <Tab
                tabCount={tabCount}
                active={view === 'followedEntities'}
                onClick={() => this.setState({ view: 'followedEntities' })}
              >
                <div className="test_notificationCenter_followedEntitiesTab">
                  <AvatarWithName
                    icon={<Followentities />}
                    name={
                      <TextEllipsis text="Followed entities">
                        <FormattedMessage id="module-notifications-followingEntitiesTab" />
                      </TextEllipsis>
                    }
                  />
                </div>
              </Tab>
            )}
            {hasSavedSearchFeature && (
              <Tab
                tabCount={tabCount}
                active={view === 'savedSearches'}
                onClick={() => this.setState({ view: 'savedSearches' })}
              >
                <div className="test_notificationCenter_savedSearchesTab">
                  <AvatarWithName
                    icon={<Keepintheloop />}
                    name={
                      <TextEllipsis text="Saved searches">
                        <FormattedMessage id="module-notifications-savedSearchesTab" />
                      </TextEllipsis>
                    }
                  />
                </div>
              </Tab>
            )}
          </Flex>
        )}
        <React.Fragment>{theView}</React.Fragment>
      </>
    );
  }
}

NotificationCenterTabsWrapper.displayName = 'NotificationCenterTabsWrapper';

export default NotificationCenterTabsWrapper;
