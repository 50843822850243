import styled from 'styled-components';

export const RecordListFiltersWrapper = styled.div``;

export const RecordListFiltersTitleWitResultsInfoWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const RecordListFiltersInner = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

export const RecordListRuleFiltersInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
`;
