import { css } from 'styled-components';

export const conversation = css`
  /* .cluedIn_conversation { */
  .media-list.chat-list {
    max-height: 520px;
    overflow: auto;
    font-size: 13px;
    color: #333;
    padding-left: 0;
    list-style: none;
    margin: 0;
  }

  .media {
    margin-top: 15px;
  }

  .media-left,
  .media-right,
  .media-body {
    display: table-cell;
    vertical-align: top;
  }

  .chat-list .media {
    margin-right: 20%;
  }

  .chat-list .media-left {
    padding-right: 20px;
  }

  .media-left,
  .media-right,
  .media-body {
    position: relative;
  }

  .media,
  .media-body {
    overflow: visible;
  }

  .chat-list .media-content:not([class*='bg-']) {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }

  .chat-list .media-content {
    position: relative;
    padding: 10px 12px;
    width: auto;
    border-radius: 3px;
    display: inline-block;
  }

  .media-annotation {
    color: #999;
    font-size: 12px;
    font-weight: 400;
  }

  .chat-list .media-content:before {
    border-top: 5px solid transparent;
    border-right: 5px solid;
    border-right-color: inherit;
    border-bottom: 5px solid transparent;
  }

  .chat-list .media-content:before,
  .chat-list .reversed .media-content:before {
    content: '';
    left: -5px;
    top: 15px;
    position: absolute;
    margin-left: 0;
  }

  .media-body {
    width: 10000px;
  }

  .chat-list .reversed .media-content:not([class*='bg-']) {
    background-color: #42a5f5;
    border-color: #42a5f5;
    margin-right: 10px;
  }

  .chat-list .reversed .media-content {
    text-align: left;
    color: #fff;
  }

  .media-left img:not(.media-preview),
  .media-right img:not(.media-preview),
  .thumbnail .media img:not(.media-preview) {
    width: 40px;
    height: 40px;
    max-width: none;
  }

  .media-left .userName,
  .media-right .userName {
    display: block;
    margin-top: 10px;
    width: 50px;
    height: 20px;
    max-width: none;
    word-wrap: break-word;
  }

  .chat-list .reversed .media-body {
    text-align: right;
  }

  .chat-list .media.reversed {
    margin-right: 0;
    margin-left: 20%;
  }

  .chat-list .reversed .media-content:before {
    left: auto;
    right: -5px;
    border-right: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid;
    border-left-color: inherit;
    border-bottom: 5px solid transparent;
  }
  /* } */
`.join('');

export default conversation;
