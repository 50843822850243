"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(AutoComplete) {
  return (0, _styledComponents.default)(AutoComplete)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose([""])));
};
exports.default = _default;