import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import {
  defaultAsyncCallWithActions,
  createAsyncAction,
} from '../../core/helpers/action';
import { saveUserOrgEmail } from '../data';

export const saveUserOrgEmailActions = createAsyncAction(
  'PUBLIC_SIGNUP_USER_FROM_EMAIL',
);
export const shouldFetchSignUpOrgEmail = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(saveUserOrgEmail, saveUserOrgEmailActions),
  {
    onErrorAction: saveUserOrgEmailActions.invalid,
  },
);
