import { memo } from 'react';
import find from 'lodash/find';
import RuleItemPreview from './RuleItemPreview';
import { RuleItemGlossaryFieldPreview } from './RuleItemGlossaryFieldPreview';

const adaptEnumerablePreviewName = (str) => {
  return str?.replace(/.$/, '');
};

export const RuleItemFieldSelectors = memo(
  ({
    ruleItem,
    mappedKey,
    objectTypeId,
    ruleItemIndex,
    fieldHasError,
    onPropertyChange,
    objectTypes = [],
    isVocabularyProperty,
    hasStrongTypingFeature,
    VocabularyAutoComplete,
    PropertyItemAutoComplete,
    previewMode,
  }) => {
    const selectedObjectType = find(
      objectTypes,
      (ot) => ot.id === objectTypeId,
    );

    const handlePropertyChange = (value) => {
      const selectedItemType = value?.dataType;

      const isStrongTyped =
        value?.storage === 'Typed' && hasStrongTypingFeature;

      let type = 'string';

      // This might need to be changed as condition to transform is value.dataType === 'Typed' && hasStrongTypingFeature
      if (isStrongTyped) {
        if (selectedItemType === 'Guid') {
          type = 'guid';
        }

        if (selectedItemType === 'Time') {
          type = 'time';
        }

        if (selectedItemType === 'DateTime') {
          type = 'date';
        }

        if (selectedItemType === 'Boolean') {
          type = 'bool';
        }

        if (selectedItemType === 'Integer' || selectedItemType === 'Duration') {
          type = 'integer';
        }

        if (
          selectedItemType === 'Number' ||
          selectedItemType === 'Currency' ||
          selectedItemType === 'Money'
        ) {
          type = 'double';
        }
      }

      onPropertyChange(value?.key, ruleItemIndex, type);
    };

    return (
      <>
        {previewMode ? (
          <RuleItemPreview objectTypeId={objectTypeId} text={ruleItem?.field} />
        ) : (
          <>
            {isVocabularyProperty ? (
              <VocabularyAutoComplete
                value={ruleItem}
                hasError={fieldHasError}
                onChange={handlePropertyChange}
                mappedKeyLabel={mappedKey?.Key}
              />
            ) : (
              <PropertyItemAutoComplete
                ruleItem={ruleItem}
                hasError={fieldHasError}
                objectTypeId={objectTypeId}
                ruleItemIndex={ruleItemIndex}
                onPropertyChange={onPropertyChange}
                selectedObjectType={selectedObjectType}
              />
            )}
          </>
        )}
      </>
    );
  },
);
