import React from 'react';
import { CancelButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

import {
  GroupedEntitiesPanelFooterWrapper,
  GroupedEntitiesPanelFooterInnerWrapper,
} from './GroupedEntitiesPanelFooter.styles';

const GroupedEntitiesPanelFooter = ({ handleOnClose }) => {
  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  return (
    <GroupedEntitiesPanelFooterWrapper themeWhite={themeWhite}>
      <GroupedEntitiesPanelFooterInnerWrapper>
        <CancelButton
          data-test="entityRelationsGraph-groupedEntitiesPanel-closeButton"
          onClick={handleOnClose}
          variant="outlined"
          onConfirm={handleOnClose}
          rounded
          style={{ marginRight: '10px' }}
        >
          <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesCloseButton" />
        </CancelButton>
      </GroupedEntitiesPanelFooterInnerWrapper>
    </GroupedEntitiesPanelFooterWrapper>
  );
};

export default GroupedEntitiesPanelFooter;
