import React from 'react';
import { withWorkflow } from '@cluedin/form';

import ManualMergePanel from '../components/containers/ManualMergePanel/ManualMergePanel';

const ManualMergePanelWrapper =
  (Comp) =>
  ({ openWorkflow, ...props }) =>
    <Comp openManualMergePanelWorkflow={openWorkflow} {...props} />;

export const withManualMergePanelWorkflow = (Comp) =>
  withWorkflow(ManualMergePanelWrapper(Comp), ManualMergePanel, {
    withCustomHeader: true,
    panelType: 'custom',
    width: 1200,
  });
