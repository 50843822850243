import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { register } from '../../registry';
import { Externallink } from 'uxi/Icons';

const SuggestedSearchTitle = styled.span`
  color: #2f2f32;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ececec;
  padding: 0 12px;
`;

const EmptyContent = styled.div`
  padding: 48px 0;
  text-align: center;
  font-size: 14px;
`;

const EmptyEntitySuggestedSearchV2: FC<{
  template: {
    name?: string | ReactNode;
    displayName?: string | ReactNode;
    pageTemplateId: string;
    hasNoSuggestedSearchEvenIfEnabled: boolean;
  };
}> = ({ template }) => {
  const { hasNoSuggestedSearchEvenIfEnabled } = template;
  let content = (
    <EmptyContent>
      <span>
        <FormattedMessage id="module-entityWidget-suggestedSearchesDisabled" />
      </span>
      <Link
        target="_blank"
        rel="noreferrer noopener"
        to={`/admin/settings/entity-page-layout/edit?id=${encodeURIComponent(
          template.pageTemplateId,
        )}`}
      >
        {template.displayName || template.name}
        <div style={{ marginLeft: '6px', display: 'inline-block' }}>
          <Externallink size={12} />
        </div>
      </Link>
    </EmptyContent>
  );

  if (hasNoSuggestedSearchEvenIfEnabled) {
    content = (
      <EmptyContent>
        <FormattedMessage id="module-entityWidget-noSuggestedSearces" />
      </EmptyContent>
    );
  }
  return (
    <div
      style={{
        margin: '0 24px 24px 24px',
        border: '1px solid #ececec',
        background: 'white',
      }}
    >
      <SuggestedSearchTitle>
        <FormattedMessage id="module-entityWidget-noSuggestedSearces-Title" />
      </SuggestedSearchTitle>
      <div>{content}</div>
    </div>
  );
};

register('EmptyEntitySuggestedSearchV2', EmptyEntitySuggestedSearchV2, {
  name: 'EmptyEntitySuggestedSearchV2',
  displayName: 'Empty Suggested Search',
  description: 'Display if suggested search is disabled.',
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
