import { createAction } from 'redux-actions';
import storage from 'store';
import defaultViewOptions, { searchList } from './defaultViewOptions';

export const shouldOpenViewOptions = createAction('SHOULD_SHOW_VIEW_OPTION');

export const shouldCloseViewOptions = createAction('SHOULD_HIDE_VIEW_OPTION');

export const shouldChangeViewOptionAction = createAction(
  'SHOULD_CHANGE_VIEW_OPTION',
);

export const shouldChangeViewOption = (updatedConfig) => (dispatch) => {
  let viewOptions = storage.get('view-options');
  if (!viewOptions) {
    viewOptions = defaultViewOptions;
  }

  let foundUpdateConfig = viewOptions.find(
    (c) => c.name === updatedConfig.viewName,
  );

  if (!foundUpdateConfig) {
    foundUpdateConfig = defaultViewOptions?.find(
      (v) => v.name === updatedConfig.viewName,
    );
  }

  const viewModeOption = foundUpdateConfig?.views?.find(
    (v) => v.name === updatedConfig.viewMode,
  );

  viewModeOption.fields = updatedConfig.fields;

  storage.set('view-options', viewOptions);
  dispatch(shouldChangeViewOptionAction(viewOptions));
};

export const shouldChangeViewMode = (viewName, viewMode) => (dispatch) => {
  let viewOptions = storage.get('view-options');
  if (!viewOptions) {
    viewOptions = defaultViewOptions;
  }

  const foundUpdateConfig = viewOptions.find((c) => c.name === viewName);

  if (!foundUpdateConfig) {
    let defaultUpdateConfig = defaultViewOptions.find(
      (v) => v.name === viewName,
    );
    if (!defaultUpdateConfig) {
      defaultUpdateConfig = searchList;
    }
    defaultUpdateConfig.viewMode = viewMode;

    viewOptions.push(defaultUpdateConfig);
  } else {
    foundUpdateConfig.viewMode = viewMode;
  }

  storage.set('view-options', viewOptions);

  dispatch(shouldChangeViewOptionAction(viewOptions));
};
