import { CancelButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

import {
  EdgePropertesPanelFooterWrapper,
  EdgePropertesPanelFooterInnerWrapper,
} from './EdgePropertiesPanelFooter.styles';

const EdgePropertiesPanelFooter = ({ handleOnClose }) => {
  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  return (
    <EdgePropertesPanelFooterWrapper themeWhite={themeWhite}>
      <EdgePropertesPanelFooterInnerWrapper>
        <CancelButton
          data-test="entityRelationsGraph-edgePropertiesPanel-closeButton"
          onClick={handleOnClose}
          variant="outlined"
          onConfirm={handleOnClose}
          rounded
          style={{ marginRight: '10px' }}
        >
          <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelCloseButton" />
        </CancelButton>
      </EdgePropertesPanelFooterInnerWrapper>
    </EdgePropertesPanelFooterWrapper>
  );
};

export default EdgePropertiesPanelFooter;
