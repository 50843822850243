import get from 'lodash/get';
import { apiRequest, rawRequest } from '../core/helpers/request';

export const getConfigurationById = (id) =>
  apiRequest('GET', `api/configuration/providers?id=${id}`);

export const getAllActiveConfigurations = () => {
  return apiRequest(
    'GET',
    'api/configuration/providers?excludeTypes=DataSource,Connector,Enricher',
  ).then((p) => get(p, 'Data', []));
};

export const getUserPermissionsByConfigurationId = (id) =>
  apiRequest(
    'GET',
    `api/v1/configuration/providefinitionaccounts?providerDefinitionId=${id}`,
  ).then((resp) => ({
    configurationId: id,
    userId: (resp || [])
      .map((a) => a.Account && a.Account.UserId)
      .filter((x) => x),
  }));

export const updateConfigurationPermissionFromUser = (
  configurationId,
  userIds,
) =>
  apiRequest('POST', 'api/v1/configuration/providefinitionaccounts', {
    ProviderDefinitionIds: [configurationId],
    UserIds: userIds,
  });

export const updateConfigurationPermission = (payload) =>
  apiRequest('POST', 'api/v1/configuration/providefinitionaccounts', payload);

export const grantConfigurationPermission = (payload) =>
  apiRequest('POST', 'api/v1/configuration/providefinitionaccounts', payload);

export const deleteConfigurationPermission = (payload) =>
  apiRequest('DELETE', 'api/v1/configuration/providefinitionaccounts', payload);

export const getUnapprovedConfiguration = () =>
  apiRequest(
    'GET',
    'api/v1/configuration/unapprovedproviders?excludeTypes=DataSource,Connector,Enricher',
  ).then((unapprovedproviders) =>
    get(unapprovedproviders, 'Data', []).map((c) => ({
      ...c,
      isUnApproved: true,
    })),
  );

export const getInactiveProviders = () => {
  return apiRequest(
    'GET',
    'api/configuration/inactiveproviders?excludeTypes=DataSource,Connector,Enricher',
  ).then((p) => get(p, 'Data', []));
};

export const play = ({ providerId, accountId }) =>
  apiRequest(
    'PUT',
    `api/organization/providers?id=${providerId}&active=true&AccountId=${accountId}`,
  );

export const pause = ({ providerId, accountId }) =>
  apiRequest(
    'PUT',
    `api/organization/providers?id=${providerId}&active=false&AccountId=${accountId}`,
  );

export const reject = ({ id, providerId }) =>
  apiRequest(
    'DELETE',
    `api/v1/organization/providers/clear?id=${id}&providerId=${providerId}`,
  );

export const approve = ({ id, data }) =>
  apiRequest(
    'POST',
    `api/v2/organization/providers/enable?organizationProviderId=${id}`,
    data,
  );

export const reAuthProvider = (reAuthEndpoint) =>
  rawRequest('GET', reAuthEndpoint).then((r) => r.text());

export const setProductOwner = (userId, providerDefinitionId) =>
  apiRequest('POST', 'api/v1/configuration/providerdefinitionowners', {
    UserId: userId,
    ProviderDefinitionId: providerDefinitionId,
  });

export const removeProductOwner = (productOwner) =>
  apiRequest(
    'DELETE',
    `api/v1/configuration/providerdefinitionowners?id=${productOwner.Id}`,
  );

export const fetchProductOwner = (providerDefinitionId) =>
  apiRequest(
    'GET',
    `api/v1/configuration/providerdefinitionowners?providerDefinitionId=${providerDefinitionId}`,
  );

export const reProcessConfiguration = (id) =>
  apiRequest('POST', `api/v1/organization/admin/providers/recrawl?id=${id}`);
