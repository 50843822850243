import React from 'react';
import { Stepper } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

const NewConfigurationStepper = ({ integration, activeStep }) => (
  <Stepper
    activeStep={activeStep}
    steps={[
      {
        label: (
          <FormattedMessage
            id="module-integration-authenticateWith"
            values={{
              name: integration.Name,
            }}
            defaultMessage="Authenticate with {name}"
          />
        ),
      },
      {
        label: (
          <FormattedMessage
            id="module-integration-configure"
            values={{
              name: integration.Name,
            }}
            defaultMessage="Configure {name}"
          />
        ),
      },
    ]}
  />
);

export default NewConfigurationStepper;
