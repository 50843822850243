import { push } from 'connected-react-router';
import { get } from 'lodash';
import constants from '../constants';
import { logException, logMessage, warn } from '../modules/core/helpers/logger';
import {
  entityNoAccess,
  entityNotFound,
  somethingOdd,
  showAccessDeniedMessage,
  showUnauthorizedMessage,
  showErrorMessage,
} from '../modules/error/actions';

export const unauthorizedRequest = () => ({
  type: constants.generic.UNAUTHORIZED_REQUEST,
});

export const accessDesignRequest = () => ({
  type: constants.generic.ACCESS_DENIED,
});

export const showGlobalError = () => ({
  type: constants.generic.GLOBAL_ERROR,
});

export const subtleErrorHandling =
  (dispatch, action, redirectIfDenied, params) => (resp) => {
    // this is another kind of error, probably from React

    if (
      resp &&
      !resp.url &&
      !resp.method &&
      !resp.response &&
      !resp.status &&
      !resp.timeout
    ) {
      logException(resp);
      dispatch(showErrorMessage());
    }

    const url = resp && resp.req ? resp.req.url : '';

    if (resp.response && resp.response.status === 403 && redirectIfDenied) {
      logMessage(`Error 403 - access denies - for ${url}`);
      dispatch(showAccessDeniedMessage());
    }

    if (resp.response && resp.response.status === 401) {
      const callurl =
        resp.response && resp.response.req && resp.response.req.url;
      logMessage(`Error 401 - unauthorized - for ${callurl}`);
      dispatch(showUnauthorizedMessage());
    }

    return dispatch(action(params));
  };

export const unauthorizedWithResponseStatus =
  (dispatch, action, redirectIfDenied, params) => (resp) => {
    // this is another kind of error, probably from React
    if (
      resp &&
      !resp.url &&
      !resp.method &&
      !resp.response &&
      !resp.status &&
      !resp.timeout
    ) {
      logException(resp);
      dispatch(showGlobalError());
    }

    const url = resp && resp.req ? resp.req.url : '';

    const accessDenied = resp.response && resp.response.status === 403;

    if (accessDenied) {
      logMessage(`Error 403 - access denies - for ${url}`);
    }

    if (resp.response && resp.response.status === 401) {
      const callurl =
        resp.response && resp.response.req && resp.response.req.url;
      logMessage(`Error 401 - unauthorized - for ${callurl}`);
      dispatch(unauthorizedRequest());
    }

    return dispatch(action({ params, accessDenied }));
  };

export function unauthorized(dispatch, action, redirectIfDenied, params) {
  return (resp) => {
    // this is another kind of error, probably from React
    if (
      resp &&
      !resp.url &&
      !resp.method &&
      !resp.response &&
      !resp.status &&
      !resp.timeout
    ) {
      logException(resp);
      dispatch(showGlobalError());
    }

    const url = resp && resp.req ? resp.req.url : '';

    if (resp.response && resp.response.status === 403 && redirectIfDenied) {
      logMessage(`Error 403 - access denies - for ${url}`);
      dispatch(accessDesignRequest());
      dispatch(push('/error/denied'));
    }

    if (resp.response && resp.response.status === 401) {
      const callurl =
        resp.response && resp.response.req && resp.response.req.url;
      logMessage(`Error 401 - unauthorized - for ${callurl}`);
      dispatch(unauthorizedRequest());
    }

    return dispatch(action(params));
  };
}

export function unauthorizedWithResponse(
  dispatch,
  action,
  redirectIfDenied,
  params,
) {
  return (resp) => {
    const status = resp?.requestError?.status;
    const message = resp?.requestError?.message;
    const url = resp?.requestError?.orignal?.url;

    if (status === 403 && redirectIfDenied) {
      const callurl = url || '';
      logMessage(`Error 403 - access denies - for ${callurl}`);
      dispatch(accessDesignRequest());
      dispatch(push('/error/denied'));
    }

    if (status === 401) {
      const callurl = url || '';
      logMessage(`Error 401 - unauthorized - for ${callurl}`);
      dispatch(unauthorizedRequest());
    }

    return dispatch(action({ params, resp: message }));
  };
}

export function unauthorizedWithQueue(
  dispatch,
  actions,
  params,
  redirectIfDenied,
) {
  return (resp) => {
    // this is another kind of error, probably from React
    if (
      resp &&
      !resp.url &&
      !resp.method &&
      !resp.response &&
      !resp.status &&
      !resp.timeout
    ) {
      logException(resp);
      dispatch(showGlobalError());
    }

    const url = resp && resp.req ? resp.req.url : '';

    if (resp.response && resp.response.status === 202) {
      logMessage(`Error 202 - queuing work for - for ${url}`);
      if (actions.queued) {
        return dispatch(actions.queued(params));
      }
    }

    if (resp.response && resp.response.status === 403 && redirectIfDenied) {
      logMessage(`Error 403 - access denies - for ${url}`);
      dispatch(accessDesignRequest());
      dispatch(push('/error/denied'));
    }

    if (resp.response && resp.response.status === 401) {
      const callurl =
        resp.response && resp.response.req && resp.response.req.url;
      logMessage(`Error 401 - unauthorized - for ${callurl}`);
      dispatch(unauthorizedRequest());
    }

    return dispatch(actions.invalid(params));
  };
}

/**
 *
 * Creating the error reducers???
 */
export function unauthorizedForEntity(dispatch, action, entityId) {
  return (resp) => {
    const statusForFetch = get(resp, 'requestError.status');
    const legacyStatus = get(resp, 'response.status');
    const status = statusForFetch || legacyStatus;

    // this is another kind of error, probably from React
    if (
      resp &&
      !resp.url &&
      !resp.method &&
      !resp.response &&
      !resp.timeout &&
      !status
    ) {
      logException(resp);
    }

    if (status === 403) {
      // no access to this entity
      logMessage(`Error Entity 403 - not access to Entity - for ${entityId}`);
      return dispatch(entityNoAccess(entityId));
    }

    if (status === 404) {
      // the Entity is not found
      warn(`Error Entity 404 - not found - for ${entityId}`);
      return dispatch(entityNotFound(entityId));
    }

    if (status === 500) {
      // the Entity is not found
      const errorTextFromFetch = get(resp, 'requestError.message');
      const legacy = get(resp, 'response.text');

      const errorText = errorTextFromFetch || legacy || 'Unknown Error';
      logMessage(`Error Entity 500 - ${errorText}`);

      return dispatch(somethingOdd({ entityId, text: errorText, status }));
    }

    if (status === 401) {
      // Unauthorized = keep current behavior
      logMessage(`Error Entity 401 - unauthorized - for ${entityId}`);
      return dispatch(unauthorizedRequest());
    }

    return dispatch(action({ entityId }));
  };
}
