import styled from 'styled-components';

export const EdgePropertiesPanelEdgeWrapper = styled.div`
  align-items: center;
  border-left: 1px solid #ececec;
  border-bottom: none;
  border-right: none;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  padding: 0 14px 0 8px;
  position: relative;
  width: 100%;
`;
