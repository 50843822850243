import styled from 'styled-components';

const EntityTypeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #cfd1d2;
  border-radius: 50%;
  padding: 4px;
  background-color: #f4f4f4;
  color: #cfd1d2;
  width: 28px;
  height: 28px;
`;

export default EntityTypeIconWrapper;
