import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import { graphql } from '@apollo/client/react/hoc';
import { createFiltersViewModelsFromGql } from '../../entityViewModel/helpers/filter';
import { legacyClient } from '../../../data/graphql';

/**
 * 
 * sort: "DATE"
sortDirection: "DESCENDING"
 */
export const allFacets = gql`
  query aggregateQuery($query: String, $filters: [FilterQuery]) {
    aggregate(query: $query, filters: $filters) {
      aggregations
      totalResults
    }
  }
`;

const mapToStateProps = ({ wms: { entityConfigurations } }) => ({
  entityConfigurations,
});

export const withFacets = (Comp) => {
  return connect(mapToStateProps)(
    graphql(allFacets, {
      client: legacyClient,
      options: () => ({
        client: legacyClient,
        variables: {
          query: '*',
        },
      }),
      props: ({
        ownProps: { entityConfigurations },
        data: { loading, aggregate },
      }) => {
        const viewModels = createFiltersViewModelsFromGql(
          (aggregate || {}).aggregations || {},
          entityConfigurations,
        );
        const total = (aggregate || {}).totalResults || 0;
        const promotedFilters = ['entityType', 'providers'].reduce(
          (accu, promotedFilterType) => {
            // eslint-disable-next-line no-param-reassign
            accu[promotedFilterType] = viewModels[promotedFilterType];
            return accu;
          },
          {},
        );

        return {
          isRemoteFiltering: false,
          totalFiltersCount: total,
          isFetching: loading,
          promotedFilters,
          filters: viewModels,
          total,
        };
      },
    })(Comp),
  );
};

export const allSearchResult = gql`
  query searchQuery(
    $query: String
    $filter: String
    $filters: [FilterQuery]
    $terms: [TermQuery]
    $pageSize: Int
    $contextIds: [Guid]
    $sort: SortMethod
    $sortDirection: SortDirection
    $includeExternalData: Boolean
    $includeUnstructuredData: Boolean
    $cursor: PagingCursor
    $searchUntokenized: Boolean
  ) {
    search(
      query: $query
      filter: $filter
      filters: $filters
      terms: $terms
      pageSize: $pageSize
      contextIds: $contextIds
      sort: $sort
      sortDirection: $sortDirection
      includeExternalData: $includeExternalData
      includeUnstructuredData: $includeUnstructuredData
      cursor: $cursor
      searchUntokenized: $searchUntokenized
    ) {
      totalResults
      cursor
      entries {
        id
        name
        createdDate
        discoveryDate
        modifiedDate
        properties
        providerOrigins
        description
        uris
        previewImage {
          uri
        }
        entityType
        sortDate
      }
      aggregations
    }
  }
`;

const getSortValue = (sortType, q) => {
  if (sortType === 'new') {
    return {
      sort: 'DATE',
      sortDirection: 'DESCENDING',
    };
  }

  if (sortType === 'old') {
    return {
      sort: 'DATE',
      sortDirection: 'ASCENDING',
    };
  }
  if ((!q || q === '*') && !sortType) {
    return {
      sort: 'DATE',
      sortDirection: 'DESCENDING',
    };
  }

  return {
    sort: 'RELEVANCE',
    sortDirection: 'DESCENDING',
  };
};

export const encodeFiltersForGQLSearch = (filters = []) => {
  if (!filters || filters.length === 0) {
    return [];
  }

  const filterResult = [];
  const filtersGoupedByType = groupBy(filters, (f) => f.type);

  Object.keys(filtersGoupedByType || {}).forEach((type) => {
    const values = filtersGoupedByType[type].map((filter) => filter.value);

    if (type === 'property') {
      filtersGoupedByType[type].forEach((p) => {
        if (p.value !== '') {
          filterResult.push({
            fieldName: `properties.${p.displayName}`,
            value: p.value,
          });
        }
      });
      // .map(filter => filter.displayName);
    } else {
      filterResult.push({
        aggregationName: type,
        values,
        operator: 'OR',
      });
    }
  });

  return filterResult;
};

export const getFiltersVariables = (
  selectedFiltersParam = [],
  entityTypePoolIdentifier,
) => {
  let selectedFilters = selectedFiltersParam;

  if (!Array.isArray(selectedFilters)) {
    return [];
  }

  // graphQL uses contextIds for those edges that are entity
  selectedFilters = selectedFilters.filter(
    (filter) => filter.type !== '/Person' && filter.type !== '/Organization',
  );

  if (entityTypePoolIdentifier) {
    selectedFilters = selectedFilters.filter(
      (filter) => filter.type !== 'entityType',
    );
    if (entityTypePoolIdentifier === 'person') {
      // TODO: use the entityTypePools definition from /module/search/helper.js
      selectedFilters.push({
        type: 'entityType',
        value: '/Person',
      });
    } else if (entityTypePoolIdentifier === 'organization') {
      selectedFilters.push({
        type: 'entityType',
        value: '/Organization',
      });
    }
  }

  return encodeFiltersForGQLSearch(selectedFilters);
};

const getContextIdsVariables = (selectedFilters = []) => {
  return selectedFilters
    .filter(
      (f) =>
        f.type.toLowerCase() === '/person' ||
        f.type.toLowerCase() === '/organization',
    )
    .map((filter) => filter.value);
};

export const createSearchParameterFromProps = (props, cursor) => {
  const {
    q,
    selectedFilters,
    sortType,
    take,
    cursor: cursorFromProps,
    entityTypePoolIdentifier,
  } = props;

  const sortVariables = getSortValue(sortType, q);

  return {
    query: q,
    pageSize: take,
    cursor: cursor || cursorFromProps,
    ...sortVariables,
    filters: getFiltersVariables(selectedFilters, entityTypePoolIdentifier),
    contextIds: getContextIdsVariables(selectedFilters),
    includeExternalData: false,
    includeUnstructuredData: false,
  };
};

export default {
  withFacets,
};
