import { compose, lifecycle, renderComponent, branch } from 'recompose';
import MergeDataError from '../composites/MergeDataError';

const withFetchOnMount = compose(
  lifecycle({
    componentWillMount() {
      this.props.abortMerge();
    },
    componentDidMount() {
      const { ids, shouldFetchEntities, shouldFetchSchema } = this.props;

      shouldFetchSchema();
      shouldFetchEntities(ids.split(','));
    },
  }),
  branch(({ ids }) => !ids, renderComponent(MergeDataError)),
);

export default withFetchOnMount;
