import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { buttonResetStylesCSSString } from 'uxi/Button/buttonResetStyles';

const imageSize = 17;

const UnstyledButton = styled.button`
  ${buttonResetStylesCSSString};
  max-width: 200px;
  text-align: left;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: 0;
    right: 0;
    background: transparent;
    transition: ${({ theme: { transition } }) => transition.defaultAll};
  }
  & > * {
    z-index: 0;
  }
  &:focus,
  &:hover {
    outline: none;
    &:before {
      background: #ececec;
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
  }

  /* Add filter labe lon hover */
  & > *:last-child {
    white-space: nowrap;
    color: grey;
    font-size: 11px;
    padding-right: 4px;
    opacity: 0;
    overflow: hidden;
    max-width: 0;
    flex-grow: 0;
    flex-shrink: 999;
    transition: ${({ theme: { transition } }) => transition.defaultAll};
  }
  &:hover > *:last-child,
  &:focus > *:last-child {
    max-width: 110px;
    flex-grow: 0;
    flex-shrink: 999;
    opacity: 1;
    transition: ${({ theme: { transition } }) => transition.defaultAll};
  }
`;

export const FilterGroupItemWrapper = styled.div`
  margin: 8px 0;
  font-size: 14px;
  color: #222222;
  /**
   * TODO to fix this use of !important, need to harmonize the componenet api of the
   * icons (svg icon) and image (Img and other using it like avatar with name empty-cells.)
   * to support the same way of overwritting width/size/height
   * the idea is to have this working for img too:
   * 'React.cloneElement(filter.icon, { size: imageSize }'
   */
  & figure {
    min-width: ${imageSize}px !important;
    width: ${imageSize}px !important;
    max-width: ${imageSize}px !important;
    min-height: ${imageSize}px !important;
    height: ${imageSize}px !important;
    max-height: ${imageSize}px !important;
  }
`;

const FilterGroupItem = ({ onFocusIn, children, onClick }) => (
  <FilterGroupItemWrapper>
    <UnstyledButton
      onFocus={onFocusIn}
      onMouseOut={() => {}}
      onClick={() => {
        onClick();
      }}
    >
      {children}
      <span>
        <FormattedMessage id="module-search-clickAddFilter" />
      </span>
    </UnstyledButton>
  </FilterGroupItemWrapper>
);

FilterGroupItem.displayName = 'FilterGroupItem';

FilterGroupItem.defaultProps = {
  onClick: () => {},
};

export default FilterGroupItem;
