import { memo } from 'react';
import { injectIntl } from '@cluedin/locale';
import { CancelButton, PanelContent, PanelFooter } from '@cluedin/form';
import ServerStatusView from './ServerStatusView';
import { ApiGatewayDown } from './ApiGatewayDown';

export const ServerStatusPanel = memo(
  injectIntl(
    ({
      intl,
      statuses,
      statusLoading,
      statusError,
      globalStatus,
      values: { theme },
      onClose,
    }) => {
      return (
        <>
          <PanelContent style={!statusError && { padding: '0px' }}>
            {!statusError && (
              <ServerStatusView
                statuses={statuses}
                statusLoading={statusLoading}
                statusError={statusError}
                globalStatus={globalStatus}
                theme={theme}
              />
            )}

            {statusError && <ApiGatewayDown />}
          </PanelContent>

          <PanelFooter>
            <CancelButton
              rounded
              onClick={onClose}
              text={intl.formatMessage({ id: 'cancel' })}
            />
          </PanelFooter>
        </>
      );
    },
  ),
);
