import EntityTileView from '../composites/EntityTileView';
import { withPropertyViewModel } from '../hocs/withPropertyViewModel';
// import { withListPropertySelection } from '../hocs/withPropertySelection';
// import { tileViewFields } from '../../defaultViewOptions';

export default withPropertyViewModel(
  // withListPropertySelection(
  EntityTileView,
  //   {
  //     restriction: 2,
  //     standardFields: tileViewFields,
  //   },
  // )
);
