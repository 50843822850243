import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import RequiredField from './RequiredField';

const FormExplanationRequiredFields = ({ style = {} }) => (
  <div style={style}>
    <FormattedMessage id="module-core-requiredField" />
    (<RequiredField />)
  </div>
);

export default FormExplanationRequiredFields;
