import { useEffect, useState } from 'react';
import {
  useLazyQueryDataSource,
  DataSourcePayload,
} from '../../../../dataSourceV2/hooks/useLazyDataSource';
import { useMultiUploader } from '../MultiUploader/MultiUploaderProvider';
import { FileUploadRequest, FileUploadRequestProgress } from '../../../types';
import { FileUploadProgressLine } from '../../composite/FileUploadProgress';

type FileUploadToasterData = {
  fileUploadRequest: FileUploadRequest;
  finished: boolean;
  progressInfo: FileUploadRequestProgress;
  skipTimeOut: boolean;
};

type FileUploadToaster = {
  closeToast: () => void;
  data: FileUploadToasterData;
  error: boolean;
  errorMessage: string;
};

const onLoad = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

const FileUploadToaster = ({
  closeToast,
  data,
  error,
  errorMessage,
}: FileUploadToaster) => {
  const fileUploadRequest = data?.fileUploadRequest;
  const finished = data?.finished;
  const skipTimeOut = data?.skipTimeOut;
  const [pause, setPause] = useState<boolean>(false);
  const [dataSourceFromServer, setDataSourceFromServer] =
    useState<DataSourcePayload>();

  const multiUploadContext = useMultiUploader();
  const abortFileUpload = multiUploadContext?.abortFileUpload;
  const resumeFileUpload = multiUploadContext?.resumeFileUpload;
  const cancelFileUpload = multiUploadContext?.cancelFileUpload;

  const percentage = Math.floor(data?.progressInfo?.percentage || 0);
  const percentageFormatted = percentage > 100 ? 100 : percentage;

  const dataSourceId = fileUploadRequest?.dataSourceId || '';

  const [getDataSource, { dataSource }] = useLazyQueryDataSource(dataSourceId);

  window.addEventListener('beforeunload', onLoad);

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', onLoad);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!dataSourceFromServer) {
        getDataSource();
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (dataSource) {
      setDataSourceFromServer(dataSource);
    }
  }, [dataSource]);

  const finishedWithDataSource =
    finished || dataSource?.fileMetadata?.uploading === false;

  useEffect(() => {
    if (finishedWithDataSource && !skipTimeOut) {
      setTimeout(() => {
        closeToast();
      }, 1000);
    }
  }, [finishedWithDataSource]);

  return (
    <FileUploadProgressLine
      showClose
      fileUploadRequest={fileUploadRequest}
      closeToast={closeToast}
      error={error}
      errorMessage={errorMessage}
      finishedWithDataSource={finishedWithDataSource}
      percentage={percentageFormatted}
      pause={pause}
      abortFileUpload={() => {
        if (abortFileUpload) {
          abortFileUpload(fileUploadRequest);
          setPause(true);
        }
      }}
      resumeFileUpload={() => {
        if (resumeFileUpload) {
          resumeFileUpload(fileUploadRequest);
          setPause(false);
        }
      }}
      cancelFileUpload={() => {
        if (cancelFileUpload) {
          cancelFileUpload(fileUploadRequest);

          closeToast();
        }
      }}
      dataSourceId={fileUploadRequest?.dataSourceId}
    />
  );
};

export default FileUploadToaster;
