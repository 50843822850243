import styled from 'styled-components';

export const EntityPropertiesHistoryFullWrapper = styled.div`
  padding-top: 30px;
`;

export const EntityPropertiesTableTitle = styled.strong`
  color: #222222;
  font-size: 18px;
`;

export const EntityPropertiesHistoryFullTableScroll = styled.div`
  overflow-x: auto;
  padding: 15px 0;
  max-width: 100%;
`;

export const EntityPropertiesHistoryFullTableWrapper = styled.table`
  background: #f9f9f9;
  border: 1px solid rgb(224, 224, 224);
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const EntityPropertiesHistoryFullTableHead = styled.thead`
  background: #f9f9f9;
  text-align: left;
  width: 100%;
  min-width: 100%;
`;

export const EntityPropertiesHistoryFullTableHeading = styled.th`
  color: #595959;
  border: 1px solid rgb(224, 224, 224);
  font-weight: 600;
  padding: 12px;
  max-width: 260px;
  word-wrap: break-word;
`;

export const EntityPropertiesHistoryFullTableData = styled.td`
  background: #ffffff;
  padding: 12px;
  text-align: left;
  max-width: 260px;
  word-wrap: break-word;
`;

export const EntityPropertiesHistoryFullTableRow = styled.tr`
  border-bottom: 1px solid rgb(224, 224, 224);
  width: 100%;
  min-width: 100%;

  &:hover {
    & ${EntityPropertiesHistoryFullTableData} {
      background: #f4fafa !important;
    }
  }
`;

export const EntityPropertiesHistoryFullTableBody = styled.tbody``;
