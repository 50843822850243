import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';

const calloutProps = { gapSpace: 0 };

const getTooltipIdFromStatus = (status) => {
  if (status === 'down') {
    return <FormattedMessage id="module-core-serverDownTooltip" />;
  }

  if (status === 'up') {
    return <FormattedMessage id="module-core-serverUpTooltip" />;
  }

  if (status === 'degraded') {
    return <FormattedMessage id="module-core-serverDegradedTooltip" />;
  }

  return <FormattedMessage id="module-core-serverUnknown" />;
};

const ServerStatusBadgeFromServerBadge = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  background: ${({ status, statusError, isGlobal }) => {
    let bgColor = '#ccc';

    if (status === 'up' || (status || '').toLowerCase() === 'green') {
      if (isGlobal) {
        bgColor = '#0fdada';
      } else {
        bgColor = '#29A29A';
      }
    }

    if (status === 'degraded') {
      bgColor = '#F2B600';
    }

    if (status === 'down' || statusError) {
      bgColor = '#E66565';
    }

    return bgColor;
  }};
`;

const StatusBadge = ({ status = '', style = {}, isGlobal, statusError }) => {
  const tooltipId = useId('tooltip');

  return (
    <TooltipHost
      content={getTooltipIdFromStatus(status)}
      // This id is used on the tooltip itself, not the host
      // (so an element with this id only exists when the tooltip is shown)
      id={tooltipId}
      calloutProps={calloutProps}
      styles={{
        root: {
          display: 'inline-block',
        },
      }}
    >
      <ServerStatusBadgeFromServerBadge
        statusError={statusError}
        status={status}
        isGlobal={isGlobal}
        style={{
          ...style,
        }}
      />
    </TooltipHost>
  );
};

export default StatusBadge;
