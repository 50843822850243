import EntityMetricsContainer from '../../components/containers/EntityMetricsContainer';

const EntityDataKPIs = ({
  entity,
  isaccuracyDisabled,
  iscompletenessDisabled,
  isconnectivityDisabled,
  isrelevanceDisabled,
}) => (
  <div style={{ width: 'calc(100% - 16px)', padding: '8px' }}>
    <EntityMetricsContainer
      entity={entity}
      isaccuracyDisabled={isaccuracyDisabled}
      iscompletenessDisabled={iscompletenessDisabled}
      isconnectivityDisabled={isconnectivityDisabled}
      isrelevanceDisabled={isrelevanceDisabled}
    />
  </div>
);

EntityDataKPIs.displayName = 'EntityDataKPIs';

export default EntityDataKPIs;
