import styled, { css } from 'styled-components';

export const EdgePropertiesPanelEdgeWrapper = styled.div`
  align-items: center;
  border-left: 1px solid #ececec;
  border-bottom: none;
  border-right: none;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  padding: 0 14px 0 8px;
  position: relative;
  width: 100%;
`;

export const EdgePropertiesPanelEdgeInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 14px 14px 14px 28px;
  transition: all 100ms ease;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;

export const EdgePropertiesPanelEdgeBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme?.palette?.themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -4.75px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const EdgePropertiesPanelEdgeHeader = styled.div`
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
`;

export const EdgePropertiesPanelEdgeDetailWrapper = styled.div`
  height: 100%;
  transition: all 100ms ease;
  width: 100%;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          padding: 14px;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;

export const EdgePropertiesPanelEdgeDetailListWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: 0 10px 14px 7px;
`;
