import { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { register } from '../../registry';

const SuggestedSearchTitle = styled.span`
  color: #2f2f32;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ececec;
  padding: 0 12px;
`;

const EmptyContent = styled.div`
  padding: 48px 0;
  text-align: center;
  font-size: 14px;
`;

const GlobalMetricsDisable: FC = () => {
  return (
    <div
      style={{
        margin: '24px 24px 24px 24px',
        border: '1px solid #ececec',
        background: 'white',
      }}
    >
      <SuggestedSearchTitle>
        <FormattedMessage id="module-entityWidget-noQualityMetricsEnable" />
      </SuggestedSearchTitle>
      <div>
        <EmptyContent>
          <FormattedMessage id="module-entityWidget-noQualityMetricsEnableMessage" />
        </EmptyContent>
      </div>
    </div>
  );
};

register('GlobalMetricsDisable', GlobalMetricsDisable, {
  name: 'GlobalMetricsDisable',
  displayName: 'Metrics is disabled',
  description: 'Display if metrics is disabled in settings.',
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
