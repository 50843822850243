import { useCallback } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import styled from 'styled-components';
import Add from 'uxi/Icons/Add';
import { ButtonLink } from '@cluedin/atoms';

import RuleAndOrCondition from './RuleAndOrCondition';
import { RuleList } from './RuleList';
import RuleActions from './RuleActions';

const RuleWrapper = styled.div`
  & * {
    box-sizing: border-box;
  }
  background: #fff;
  position: relative;
  & .ruleBuilderContainer {
    background: #fff;
  }
  .childRule {
    position: relative;
    margin-left: 14px;
  }
  .ruleBuilderContainer > div {
    margin-left: 4px;
    border: 1px solid #d7d7d8;
    padding: 6px;
    background-color: #d7d7d81a;
  }
  .childRule::before,
  .childRule::after {
    content: '';
    position: absolute;
    left: -10px;
    width: 12px;
    // height: calc(50% + 4px);
    // height: 29px;
    height: 100%;
    border-color: ${({ previewMode, themePrimary }) =>
      !previewMode ? themePrimary : '#d7d7d8'};
    border-style: solid;
  }
  .childRule::before {
    top: -1px;
    // height: calc(50% + 1px);
    height: 29px;
    border-width: 0 0 3px 3px;
  }
  .childRule::after {
    //top: 50%;
    top: 0;
    border-width: 0 0 0 3px;
  }
  & .childRule:last-child::after {
    //top: 50%;
    height: 50px;
    border-width: 0 0 0 0;
  }
`;

export const RuleCondition = ({
  adaptRules,
  rule: rawRule,
  ruleItemIndex,
  errors,
  operators,
  // properties,
  addFirstMessage = <FormattedMessage id="module-rule-addFirstRule" />,
  onChange,
  onChangeRule,
  isChild,
  onRemoveRule,
  objectTypes,
  actionConfiguration,
  VocabularyAutoComplete,
  VocabularyValueAutoComplete,
  PropertyItemAutoComplete,
  ruleConditionIndex,
  hasStrongTypingFeature,
  useQueryVocabularyKeyByKey,
  scope,
  previewMode,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const rule = adaptRules
    ? {
        ...rawRule,
        rules: adaptRules(rawRule?.rules),
      }
    : rawRule;

  const hasARule = rule && rule.rules && rule.rules.length > 0;

  const updateState = useCallback(
    (state) => {
      if (onChange) {
        onChange(state);
      }

      if (onChangeRule) {
        onChangeRule(state, ruleItemIndex);
      }
    },
    [onChange, onChangeRule, ruleItemIndex],
  );
  return (
    <RuleWrapper
      className="ruleBuilderContainer childRule"
      themePrimary={themePrimary}
      previewMode={previewMode}
    >
      {!hasARule && (
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            border: '1px solid #D4DAD1',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <div data-test="rule-condition-on-add-filter">
            <ButtonLink
              icon={<Add />}
              text={addFirstMessage}
              onClick={() => {
                const newState = {
                  ...rule,
                  condition: 'AND',
                  rules: [
                    ...rule?.rules,
                    {
                      name: '',
                      operator: '',
                      value: [],
                    },
                  ],
                };
                updateState(newState);
              }}
              actionConfiguration={actionConfiguration}
            />
          </div>
        </div>
      )}
      {hasARule && (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flex: 1 }}>
              <RuleAndOrCondition
                condition={rule.condition}
                onChange={(value) => {
                  rule.condition = value;

                  updateState(rule);
                }}
                previewMode={previewMode}
              />
            </div>
            {!previewMode && (
              <div>
                <RuleActions
                  isChild={isChild}
                  rule={rule}
                  removeRule={() => {
                    if (onRemoveRule) {
                      onRemoveRule(ruleItemIndex);
                    }
                  }}
                  onAddRule={() => {
                    const newState = {
                      ...rule,
                      rules: [
                        ...rule.rules,
                        {
                          name: '',
                          operator: '',
                          value: [],
                        },
                      ],
                    };
                    updateState(newState);
                  }}
                  onAddGroup={() => {
                    const newState = {
                      ...rule,
                      rules: [
                        ...rule.rules,
                        {
                          type: 'rule',
                          condition: 'AND',
                          rules: [],
                        },
                      ],
                    };
                    updateState(newState);
                  }}
                  actionConfiguration={actionConfiguration}
                />
              </div>
            )}
          </div>
          <RuleList
            scope={scope}
            ruleConditionIndex={ruleConditionIndex}
            isChild
            errors={errors}
            rules={rule.rules}
            operators={operators}
            objectTypes={objectTypes}
            VocabularyAutoComplete={VocabularyAutoComplete}
            PropertyItemAutoComplete={PropertyItemAutoComplete}
            useQueryVocabularyKeyByKey={useQueryVocabularyKeyByKey}
            hasStrongTypingFeature={hasStrongTypingFeature}
            VocabularyValueAutoComplete={VocabularyValueAutoComplete}
            onRemoveRuleItem={(childRuleItemIndex) => {
              const updatedRule = { ...rule };
              updatedRule.rules = [
                ...(rule.rules.filter((r, index) => {
                  return childRuleItemIndex !== index;
                }) || []),
              ];

              updateState(updatedRule);
            }}
            onRemoveRule={(childRuleItemIndex) => {
              const updateRule = { ...rule };
              updateRule.rules = [
                ...(rule.rules.filter((r, index) => {
                  return childRuleItemIndex !== index;
                }) || []),
              ];

              if (onChange) {
                onChange(
                  {
                    ...updateRule,
                  },
                  ruleItemIndex,
                );
              }

              if (onChangeRule) {
                onChangeRule(
                  {
                    ...updateRule,
                  },
                  ruleItemIndex,
                );
              }
            }}
            onObjectTypeChange={(value, childRuleItemIndex) => {
              const updatedRule = { ...rule };
              updatedRule.rules = [...(updatedRule.rules || [])];

              updatedRule.rules[childRuleItemIndex] = {
                condition: 'AND',
                field: '',
                objectTypeId: value,
                operator: '',
                value: [],
              };

              updateState(updatedRule);
            }}
            onPropertyChange={(value, childRuleItemIndex, type) => {
              const updatedRule = { ...rule };
              updatedRule.rules = [...(updatedRule.rules || [])];

              updatedRule.rules[childRuleItemIndex] = {
                ...updatedRule.rules[childRuleItemIndex],
                condition: 'AND',
                field: value,
                operator: null,
                type,
                value: [],
              };

              updateState(updatedRule);

              // To force reset operator state
              // setTimeout(() => updateState(updatedRule), 0);
            }}
            onChangeOperatorType={(value, childRuleItemIndex) => {
              const updatedRule = { ...rule };
              updatedRule.rules = [...(updatedRule.rules || [])];
              const existingValue =
                updatedRule.rules[childRuleItemIndex].value || [];

              updatedRule.rules[childRuleItemIndex] = {
                ...updatedRule.rules[childRuleItemIndex],
                operator: value,
                value: [...existingValue],
              };

              updateState(updatedRule);
            }}
            onValueChange={(value, childRuleItemIndex, indexValue) => {
              const updatedRule = { ...rule };
              const updateValue =
                (updatedRule.rules[childRuleItemIndex] || {}).value || [];
              const newRef = [...updateValue];
              newRef[indexValue] = value;

              updatedRule.rules = [...(updatedRule.rules || [])];

              updatedRule.rules[childRuleItemIndex] = {
                ...updatedRule.rules[childRuleItemIndex],
                value: value ? [...newRef] : [],
              };

              updateState(updatedRule);
            }}
            onMultipleValueChange={(value, childRuleItemIndex) => {
              const updatedRule = { ...rule };

              updatedRule.rules = [...(updatedRule.rules || [])];

              updatedRule.rules[childRuleItemIndex] = {
                ...updatedRule.rules[childRuleItemIndex],
                value: [...value],
              };

              updateState(updatedRule);
            }}
            onChangeRule={(updatedRule, childRuleItemIndex) => {
              const updatedRuleState = { ...rule };
              updatedRuleState.rules = [...rule.rules];

              updatedRuleState.rules[childRuleItemIndex] = updatedRule;

              if (onChange) {
                onChange(
                  {
                    ...updatedRuleState,
                  },
                  ruleItemIndex,
                );
              }

              if (onChangeRule) {
                onChangeRule(
                  {
                    ...updatedRuleState,
                  },
                  ruleItemIndex,
                );
              }
            }}
            actionConfiguration={actionConfiguration}
            previewMode={previewMode}
          />
        </div>
      )}
    </RuleWrapper>
  );
};
