import React, { useMemo } from 'react';
import { withWorkflow } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

//import { UserMenuProfileSingedInAs } from "./UserMenuProfileSingedInAs";
import { ServerStatusPanel } from './ServerStatusPanel';

export const withServerStatus = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(() => <Comp {...props} openWorkflow={openWorkflow} />, [props]),
    (panelProps) => <ServerStatusPanel {...panelProps} {...props} />,
    {
      withCustomHeader: true,
      overlapHeader: true,
      withNativeClose: true,
      onRenderNavigationContent: (props, defaultRender) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FormattedMessage id="module-coreStatusCheck-title" />
          {defaultRender(props)}
        </div>
      ),
    },
  );

  return <EnhancedhWorkflow />;
};
