import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import Flex from 'uxi/Layout/Flex';
import TextEllipsis from 'uxi/Text/TextEllipsis';
// import { lt } from '../../../../core/helpers/numbers';
import Path from './Path';
import DotsForPath from './DotsForPath';
import colorHash from './color';

const getColor = (str = 'cluedin') => colorHash.hex(str);

const MINW = 0;
const MINH = 0;
export const MAXW = 200;
export const MAXH = 100;
const FONT_SIZE = 8;

const legendStyle = {
  color: 'grey',
  fontSize: '14px',
};

const Svg = styled.svg`
  /* background: #fafafa; */
  padding: 2px;
  margin: 8px 0;
  overflow: visible;
  width: calc(100% - 2px - 16px);
  height: calc(100% - 4px);
  color: grey;
  fill: blue;

  * {
    mix-blend-mode: fusion;
    transition: ${({ theme: { transition } }) => transition.defaultAll};
  }

  text {
    font-size: ${FONT_SIZE}px;
  }

  line {
    stroke: ${({ theme: { palette } }) => palette.primary.light};
    stroke-width: 1;
  }
`;

const TrendOverTimeChart = ({
  dateRange,
  data,
  options,
  /* semanticColor, units, */
  showDetails,
  providerNames = {},
}) => {
  const {
    valuesLabelX: valuesLabelXProp,
    values,
    additionalValues,
    labelX,
    labelY,
    // threshold,
    // min: minProp,
    // max: maxProp,
  } = data;

  const min = 0;
  const max = 100;
  const dataLength = values.length;

  // X axis labels –––––
  let xAxisLabel = null;
  if (!options || (options && options.xAxisLabel)) {
    xAxisLabel = labelX;
  }

  let xAxisLabels = showDetails ? (
    <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
      <div>{dateRange && dateRange.start && dateRange.start.fromNow()}</div>
      <div>{dateRange && dateRange.end && dateRange.end.fromNow()}</div>
    </Flex>
  ) : null;

  if (valuesLabelXProp && (!options || (options && options.xAxisLabels))) {
    xAxisLabels = valuesLabelXProp.map((label) => (
      <div
        style={{
          flex: 1,
          maxWidth: `calc((100% - (${dataLength} * 4px) - 2px) / ${dataLength})`,
          padding: '2px',
        }}
      >
        <TextEllipsis title={label}>{label}</TextEllipsis>
      </div>
    ));
  }

  // Y axis labels –––––
  let yAxisLabel = null;
  if (!options || (options && options.yAxisLabel)) {
    yAxisLabel = labelY;
  }
  let yAxisLabels = null;

  if (!options || (options && options.yAxisLabels)) {
    yAxisLabels = values
      .map((_, i) => <div>{Math.round(i * ((max - min) / dataLength))}</div>)
      .concat([<div>{Math.round(max)}</div>])
      .reverse();
  }

  // const thresholdLine = null;
  // if (threshold !== undefined) {
  //   const r = lt(threshold, 0, max, 0, MAXH);
  //   thresholdLine = (
  //     <g>
  //       <text
  //         x={2}
  //         y={(MAXH - r) - 2}
  //         style={{ fontSize: '.6em', stroke: 'transparent', fill: semanticColor }}
  //       >
  //         {Math.round(r)}{units}
  //       </text>
  //       <line
  //         x1={0}
  //         x2={MAXW}
  //         style={{ strokeWidth: 0.5, stroke: semanticColor }}
  //         y1={MAXH - r}
  //         y2={MAXH - r}
  //       />
  //     </g>
  //   );
  // }

  const wrapperStyles = {
    padding: yAxisLabel || yAxisLabels ? '14px' : 0,
    flexFlow: 'column nowrap',
    height: '100%',
    width: '100%',
    // maxHeight: 'calc(100vh - 50px - 113px)',
    // paddingTop: 0,
    // paddingRight: 0,
    // paddingBottom: (xAxisLabel || xAxisLabels) ? '8px' : 0,
    // paddingLeft: (yAxisLabel || yAxisLabels) ? '8px' : 0,
  };

  return (
    <Flex style={{ ...wrapperStyles }}>
      <Flex style={{ width: '100%', flexGrow: 99 }}>
        <div
          style={{
            width: yAxisLabel ? '22px' : 0,
            overflow: 'visible',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              minWidth: '100%',
              flexShrink: 99,
              transform: 'rotate(-90deg)',
              transformOrigin: 'center center',
              display: 'flex',
              /* align-items: center; */
              justifyContent: 'center',
              ...legendStyle,
            }}
          >
            <TextEllipsis
              title={labelY}
              style={{
                minWidth: '130px',
              }}
            >
              {yAxisLabel}
            </TextEllipsis>
          </div>
        </div>
        <Flex
          style={{
            flexFlow: 'column nowrap',
            flexGrow: 99,
            maxWidth: '100%',
            height: '100%',
          }}
        >
          <Flex
            style={{
              width: '100%',
              height: '100%',
              minHeight: '120px',
              alignItems: 'stretch',
            }}
          >
            <Flex
              style={{
                flex: 1,
                flexFlow: 'column nowrap',
                justifyContent: 'space-between',
                alignItems: 'auto',
                ...legendStyle,
              }}
            >
              {yAxisLabels}
            </Flex>
            <Flex style={{ flexGrow: 99 }}>
              {values.length > 1 ? (
                <Svg
                  viewBox={`${MINW} ${MINH} ${MAXW} ${MAXH}`}
                  style={{
                    paddingRight: yAxisLabel || yAxisLabels ? '24px' : 0,
                  }}
                >
                  {/* Lines */}
                  {values
                    .map((_, i) => (
                      <line
                        x1={0}
                        y1={MAXH - i * (MAXH / dataLength)}
                        x2={MAXW}
                        y2={MAXH - i * (MAXH / dataLength)}
                        style={{ strokeWidth: 0.5, stroke: 'lightgrey' }}
                      />
                    ))
                    .concat([
                      <line
                        x1={0}
                        y1={0}
                        x2={MAXW}
                        y2={0}
                        style={{ strokeWidth: 0.5, stroke: 'lightgrey' }}
                      />,
                    ])}

                  {/* Extra data path(s) */}
                  {
                    // eslint-disable-next-line no-shadow
                    (additionalValues || []).map(
                      ({
                        /* color, */
                        providerName: name,
                        values, // eslint-disable-line no-shadow
                        min = 0, // eslint-disable-line no-shadow
                        max = 100, // eslint-disable-line no-shadow
                      }) => {
                        const styles = {
                          opacity: showDetails ? 1 : 0.3,
                        };
                        return (
                          <React.Fragment>
                            <Path
                              show={providerNames[name] === true}
                              style={styles}
                              color={getColor(name)}
                              className={`additional-path-${name}`}
                              dateRange={dateRange}
                              curveData={[
                                values,
                                min,
                                max,
                                MINW,
                                MAXW,
                                MINH,
                                MAXH,
                              ]}
                            />
                            {showDetails ? (
                              <DotsForPath
                                show={providerNames[name] === true}
                                {...{
                                  values,
                                  min,
                                  max,
                                  MINH,
                                  MAXH,
                                  MINW,
                                  MAXW,
                                }}
                                dateRange={dateRange}
                                showDetails={showDetails}
                                color={getColor(name)}
                                name={name}
                              />
                            ) : null}
                          </React.Fragment>
                        );
                      },
                    )
                  }

                  {/*  the main path  */}
                  <Path
                    show={providerNames.Blended === true}
                    vectorEffect="default"
                    className="main-path-blended-values"
                    curveData={[data.values, min, max, MINW, MAXW, MINH, MAXH]}
                    dateRange={dateRange}
                  />
                  <DotsForPath
                    show={providerNames.Blended === true}
                    name={'Blended'}
                    r={2}
                    showDetails={showDetails}
                    dateRange={dateRange}
                    {...{ values, min, max, MINH, MAXH, MINW, MAXW }}
                  />

                  {/* threshold if any */}
                  {/* {thresholdLine} */}
                </Svg>
              ) : (
                <Flex
                  style={{
                    width: '100%',
                    height: '100%',
                    minHeight: `${MAXH}px`,
                  }}
                >
                  <FormattedMessage id="module-governance-notEnoughDataTrends" />
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex style={{ width: '100%', maxWidth: '100%' }}>
            <Flex>&nbsp;&nbsp;</Flex>
            <Flex
              style={{
                flexGrow: 99,
                justifyContent: 'space-between',
                maxWidth: 'calc(100% - 8px)', // 8px is for the double &nbsp;
                ...legendStyle,
              }}
            >
              {values.length > 1 ? xAxisLabels : null}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ width: '100%', ...legendStyle }}>
        <div>{xAxisLabel ? <span>&nbsp;</span> : null}</div>
        <div>
          <TextEllipsis>{xAxisLabel}</TextEllipsis>
        </div>
      </Flex>
    </Flex>
  );
};

TrendOverTimeChart.displayName = 'TrendOverTimeChart';

export default TrendOverTimeChart;
