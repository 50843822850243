import React from 'react';
import { Externallink } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';

import VocabType from '../../../../../dataModeling/components/composites/VocabType';
import {
  EntityListPropertyTooltipFieldLink,
  EntityListPropertyTooltipFieldTitle,
  EntityListPropertyTooltipFieldValue,
  EntityListPropertyTooltipTitle,
  EntityListPropertyTooltipWrapper,
} from './EntityListPropertyTooltip.styles';

const EntityListPropertyTooltip = ({ schema }) => {
  return (
    <EntityListPropertyTooltipWrapper>
      <div>
        <EntityListPropertyTooltipTitle>
          <FormattedMessage id="module-coreEntityList-vocabDetail" />
        </EntityListPropertyTooltipTitle>
        <div style={{ padding: '6px' }}>
          <div>
            <EntityListPropertyTooltipFieldTitle>
              <FormattedMessage id="module-coreEntityList-displayName" />
            </EntityListPropertyTooltipFieldTitle>
            <EntityListPropertyTooltipFieldValue>
              {schema.DisplayName}
            </EntityListPropertyTooltipFieldValue>
          </div>

          <div style={{ marginTop: '6px' }}>
            <EntityListPropertyTooltipFieldTitle>
              <FormattedMessage id="module-coreEntityList-vocabType" />
            </EntityListPropertyTooltipFieldTitle>
            <EntityListPropertyTooltipFieldValue>
              <VocabType value={schema.IsCoreVocab} />
            </EntityListPropertyTooltipFieldValue>
          </div>

          <div style={{ marginTop: '6px' }}>
            <EntityListPropertyTooltipFieldTitle>
              <FormattedMessage id="module-coreEntityList-type" />
            </EntityListPropertyTooltipFieldTitle>
            <EntityListPropertyTooltipFieldValue>
              {schema.DataType}
            </EntityListPropertyTooltipFieldValue>
          </div>

          <div style={{ marginTop: '6px' }}>
            <EntityListPropertyTooltipFieldTitle>
              <FormattedMessage id="module-coreEntityList-description" />
            </EntityListPropertyTooltipFieldTitle>
            <EntityListPropertyTooltipFieldValue>
              {schema.Description || '-'}
            </EntityListPropertyTooltipFieldValue>
          </div>

          <div style={{ marginTop: '6px' }}>
            <EntityListPropertyTooltipFieldTitle>
              <FormattedMessage id="module-coreEntityList-actions" />
            </EntityListPropertyTooltipFieldTitle>

            <div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
                    schema.Key,
                  )}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <EntityListPropertyTooltipFieldLink>
                    <FormattedMessage id="module-coreEntityList-viewModeDetails" />
                  </EntityListPropertyTooltipFieldLink>

                  <Externallink size={10} />
                </a>
              </div>

              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/admin/management/catalog/vocab-key/values?key=${encodeURIComponent(
                    schema.Key,
                  )}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <EntityListPropertyTooltipFieldLink>
                    <FormattedMessage id="module-coreEntityList-viewMoreValues" />
                  </EntityListPropertyTooltipFieldLink>

                  <Externallink size={10} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EntityListPropertyTooltipWrapper>
  );
};

export default EntityListPropertyTooltip;
