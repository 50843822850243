import HierarchyListContainer from '../containers/HierarchyListContainer';

const HierarchyListEntityTabPage = ({ entity, match, history, location }) => {
  return (
    <div className="__test_hierarchyListPage">
      <HierarchyListContainer
        entity={entity}
        match={match}
        history={history}
        location={location}
      />
    </div>
  );
};

export default HierarchyListEntityTabPage;
