import React, { Fragment } from 'react';
import EntityPropertyViewerPropertyList from './EntityPropertyViewerPropertyList';
import EntityPropertyViewerIntegrationGroupHeader from './EntityPropertyViewerIntegrationGroupHeader';

const EntityPropertyViewerIntegrationGroups = ({
  propertiesGroup,
  isFetching,
  invalid,
  onCategoryClick,
  isOpen,
  searchQuery,
}) => {
  return (
    <Fragment>
      <EntityPropertyViewerIntegrationGroupHeader
        title={propertiesGroup.integration.Name}
        numberProperties={propertiesGroup.properties.length}
        isOpen={isOpen}
        onClick={() => {
          onCategoryClick(propertiesGroup.integration.Name);
        }}
      />
      <div style={isOpen ? {} : { display: 'none' }}>
        <EntityPropertyViewerPropertyList
          searchQuery={searchQuery}
          propertyViewModels={propertiesGroup.properties || []}
          isFetching={isFetching}
          invalid={invalid}
          integrationName={propertiesGroup.integration.Name}
        />
      </div>
    </Fragment>
  );
};

export default EntityPropertyViewerIntegrationGroups;
