import styled from 'styled-components';

const EntityTableDataGridOverwrites = styled.div`
  table {
    display: ${({ display }) => display};
    width: 100%;
  }
`;

export default EntityTableDataGridOverwrites;
