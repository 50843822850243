"use strict";

exports.__esModule = true;
exports.RESPONSIBLE = exports.INFORMED = exports.CONSULTANT = exports.ACCOUNTABLE = void 0;
var ACCOUNTABLE = ['Accountable']; // Approve
exports.ACCOUNTABLE = ACCOUNTABLE;
var RESPONSIBLE = ['Responsible'].concat(ACCOUNTABLE); // Submit
exports.RESPONSIBLE = RESPONSIBLE;
var CONSULTANT = ['Consultant'].concat(RESPONSIBLE); // Edit
exports.CONSULTANT = CONSULTANT;
var INFORMED = ['Informed'].concat(CONSULTANT); // View
exports.INFORMED = INFORMED;