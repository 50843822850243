import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import {
  CancelButton,
  PanelContent,
  PanelFooter,
  withWorkflow,
} from '@cluedin/form';
import { Notification } from 'uxi/Icons';
import { FormattedMessage, injectIntl } from '@cluedin/locale';

import NotificationCenter from '../../../../../notifications/components/containers/NotificationCenterContainer';

import { TopNavButton } from '../TopNavButton';

const NotificationBadge = styled.span`
  position: absolute;
  left: 0%;
  font-size: 55%;
  padding: 0.4em;
  border-radius: 999px;
  line-height: 0.95em;
  color: white;
  background: ${({ theme: { palette } }) => palette.red};
  text-align: center;
  min-width: 2em;
  font-weight: bold;
`;

const NotificationsCaller = memo(
  injectIntl(({ intl, theme, notificationsOptions, openWorkflow }) => {
    const { notificationQuantity } = notificationsOptions;

    const handleOpen = () =>
      openWorkflow({
        intl,
        theme,
        notificationsOptions,
        overrideTitle: intl.formatMessage({
          id: 'CluedInUserMenu.crawlingStatus',
        }),
      });

    return (
      <TopNavButton onClick={handleOpen}>
        {notificationQuantity > 0 && (
          <NotificationBadge theme={theme}>
            {notificationQuantity}
          </NotificationBadge>
        )}

        <Notification size={20} color={theme.palette.white} />
      </TopNavButton>
    );
  }),
);

const NotificationsPanel = memo(
  ({
    values: {
      intl,
      theme,
      notificationsOptions: {
        showMore,
        notifications,
        errorsNotifications,
        loadingNotifications,
        loadMoreNotifications,
        markNotificationsAsRead,
      },
    },
    onClose,
  }) => {
    useEffect(() => {
      markNotificationsAsRead?.();
    }, []);

    return (
      <>
        <PanelContent
          style={{
            padding: 0,
            borderTop: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
          }}
        >
          <NotificationCenter
            onClose={onClose}
            showMore={showMore}
            notifications={notifications}
            errorsNotifications={errorsNotifications}
            loadingNotifications={loadingNotifications}
            loadMoreNotifications={loadMoreNotifications}
          />
        </PanelContent>

        <PanelFooter>
          <CancelButton
            rounded
            onClick={onClose}
            text={intl.formatMessage({ id: 'cancel' })}
          />
        </PanelFooter>
      </>
    );
  },
);

export const Notifications = withWorkflow(
  NotificationsCaller,
  NotificationsPanel,
  {
    withCustomHeader: true,
    overlapHeader: true,
    withNativeClose: true,
    headerText: <FormattedMessage id="Notification.NotificationCenter.Title" />,
    panelType: 'medium',
    commandStyles: {
      padding: '16px 16px 16px 0',
    },
  },
);
