import React from 'react';
import RedirectContainer from './RedirectContainer';

export const setupRedirectToRelativeHome =
  (url) =>
  (...props) => {
    return (
      <RedirectContainer
        shouldRedirect
        useReplace
        url={`${url}/home/`}
        {...props}
      />
    );
  };

const RedirectToRelativeHome = (...props) => (
  <RedirectContainer shouldRedirect useReplace url={'pii/home/'} {...props} />
);

export default RedirectToRelativeHome;
