"use strict";

exports.__esModule = true;
exports.useAccordionCompletionState = void 0;
var _react = require("react");
var useAccordionCompletionState = function useAccordionCompletionState(defaultCompleted) {
  if (defaultCompleted === void 0) {
    defaultCompleted = [];
  }
  var _useState = (0, _react.useState)(defaultCompleted),
    completed = _useState[0],
    setCompleted = _useState[1];
  var handleCompleteStep = function handleCompleteStep(id) {
    if (!completed.includes(id)) {
      setCompleted(function (prev) {
        return [].concat(prev, [id]);
      });
    }
  };
  var handleUnCompleteStep = function handleUnCompleteStep(id) {
    if (completed.includes(id)) {
      var completedList = completed.filter(function (val) {
        return val !== id;
      });
      setCompleted(completedList);
    }
  };
  return {
    completed: completed,
    handleCompleteStep: handleCompleteStep,
    handleUnCompleteStep: handleUnCompleteStep
  };
};
exports.useAccordionCompletionState = useAccordionCompletionState;