import React from 'react';
import { RACI_LEVELS } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default {
  pillar: 'management',
  name: 'catalog-legacy',
  displayName: <FormattedMessage id="catalog-legacy" />,
  hideFromMenu: true,
  path: '/catalog-legacy',
  extendPillarDashboard: {
    actions: [
      // {
      //   icon: 'Patterns',
      //   name: 'Data Catalog',
      //   link: '/admin/management/catalog',
      //   claims: management.dataCatalog.catalog.view,
      // },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"SchemaStructurePage" */
            './components/pages/SchemaStructurePage'
          ),
        ),
      ),
    },
    {
      path: '/details',
      exact: true,
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"SchemaDetailPage" */
            './components/pages/SchemaDetailPage'
          ),
        ),
      ),
    },
    {
      path: '/values',
      exact: true,
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"SchemaDetailValuesPage" */
            './components/pages/SchemaDetailValuesPage'
          ),
        ),
      ),
    },
  ],
};
