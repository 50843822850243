"use strict";

exports.__esModule = true;
exports.default = createShape;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function createShape(shape) {
  if (shape === void 0) {
    shape = {};
  }
  var mainShape = _extends({
    radius: '2px',
    borderRadius: '2px'
  }, shape);
  return mainShape;
}