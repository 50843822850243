import React from 'react';
import Badge from 'uxi/Badge';
import { FormattedMessage } from '@cluedin/locale';

const VocabType = ({ value }) => {
  return (
    <div className="test_Management_Catalog_SchemaList_vocabType">
      {value && (
        <Badge style={{ marginLeft: '4px' }} type="success">
          <FormattedMessage id="module-dataModeling-coreVocab" />
        </Badge>
      )}
      {!value && (
        <Badge style={{ marginLeft: '4px' }}>
          <FormattedMessage id="module-dataModeling-integrationVocab" />
        </Badge>
      )}
    </div>
  );
};

export default VocabType;
