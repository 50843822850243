import { Link } from 'react-router-dom';
import { useTheme } from '@cluedin/theme';
import { Flex } from 'uxi/Layout';
import { TileDetail } from 'uxi/Tile';
import { TextEllipsis } from 'uxi/Text';
import { Snippet } from '../TileSnippet';
import RelativeTextPlaceholder from './RelativeTextPlaceholder';
import IconPlaceholder from './IconPlaceholder';

const EntityTileViewItemGhostLoading = ({ searchResult = {}, noBorderTop }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <TileDetail
      title={
        <Link to={searchResult.partialURL || '/'}>
          <TextEllipsis>
            <RelativeTextPlaceholder
              color={themePrimary}
              style={{ maxWidth: '320px' }}
            />
          </TextEllipsis>
        </Link>
      }
      imageUrl={false}
      icon={<IconPlaceholder />}
      roundImage
      extra={<RelativeTextPlaceholder style={{ height: '60px' }} />}
      style={{
        background: '#fff',
        borderLeft: 'none',
        borderRight: 'none',
        padding: '32px 16px 16px 16px',
        ...(noBorderTop ? { borderTop: 'none' } : {}),
      }}
    >
      <div style={{ minWidth: '280px' }}>
        {/* 'found 3 month' ago placeholder: */}
        <Snippet>
          <RelativeTextPlaceholder
            style={{ width: '64%', maxWidth: '190px' }}
          />
        </Snippet>

        {/* ProviderIconListPlaceholder: */}
        <Flex
          style={{
            justifyContent: 'flex-start',
            height: '24px',
            margin: '8px 0px 16px',
          }}
        >
          {[...Array(6)].map(() => (
            <IconPlaceholder
              style={{ width: '24px', height: '24px', marginRight: '5px' }}
            />
          ))}
        </Flex>
      </div>
    </TileDetail>
  );
};

EntityTileViewItemGhostLoading.displayName = 'EntityTileViewItemGhostLoading';

export default EntityTileViewItemGhostLoading;
