import { gql, useMutation } from '@apollo/client';

import { SavedSearch } from './useSavedSearch';

export const SAVE_SEARCH = gql`
  mutation saveSearch($viewName: String, $search: InputSearch) {
    administration {
      saveSearch(viewName: $viewName, search: $search) {
        filterUrl
        id
        name
        viewMode
        columnsConfig
        createdBy
        created
      }
    }
  }
`;

type SaveFilterRes = {
  administration: {
    saveSearch: SavedSearch;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Options<T = {}> = {
  viewName: string;
  search: T;
};

export const useSaveSearch = <T>({ viewName }: Omit<Options, 'search'>) => {
  const [create, { loading, error }] = useMutation<SaveFilterRes, Options<T>>(
    SAVE_SEARCH,
  );

  const saveSearch = async (search: T) => {
    return create({
      variables: {
        search,
        viewName,
      },
    });
  };

  return {
    error,
    loading,
    saveSearch,
  };
};
