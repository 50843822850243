import { useQuery, gql } from '@apollo/client';

export const GET_OBJECT_TYPES = gql`
  query getRuleObjectTypes($context: RuleObjectTypeContext) {
    management {
      id
      ruleObjectTypes(context: $context) {
        id
        name
      }
    }
  }
`;

export type RuleObjectType = { id: string; name: string };

type QueryRuleObjectTypesResponse = {
  management: {
    ruleObjectTypes: RuleObjectType[];
  };
};

export type RuleObjectContext = 'CLEAN' | 'STREAM' | 'SEARCH';

export const useQueryRuleObjectTypes = (
  context: RuleObjectContext,
  skip = false,
) => {
  const { data, loading, error } = useQuery<
    QueryRuleObjectTypesResponse,
    { context: RuleObjectContext }
  >(GET_OBJECT_TYPES, {
    skip,
    variables: {
      context,
    },
    fetchPolicy: 'network-only',
  });

  const ruleObjectTypes = data?.management?.ruleObjectTypes ?? [];

  return [ruleObjectTypes, { loading, error }] as const;
};
