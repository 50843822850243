import { FormattedHTMLMessage } from '@cluedin/locale';

const ColumnSelectionListNoneSelected = ({ defaultFields = [] }) => {
  if (!defaultFields || defaultFields.length === 0) {
    return <span />;
  }

  return (
    <>
      <div>
        <FormattedHTMLMessage id="module-goldenRecordList-followingFieldsDisplayed" />
      </div>
      <ul style={{ marginTop: '6px' }}>
        {defaultFields.map((f) => {
          return <li>- {f.field}</li>;
        })}
      </ul>
    </>
  );
};

export default ColumnSelectionListNoneSelected;
