import React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
import { Button } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import AuthFormLabel from '../AuthFormLabel';

const ClientIdsWrapper = styled.div`
  color: #fff;
  text-align: left;
  font-size: 16px;
  margin-top: 30px;
  font-weight: 200;
`;

const AlreadyUsedClientIdList = ({ clientIds = [], onSelect }) => {
  if (clientIds && clientIds.length === 0) {
    return <span />;
  }

  return (
    <ClientIdsWrapper>
      <div>
        <AuthFormLabel>
          <FormattedMessage
            id="module-auth-alreadySignedIn"
            defaultMessage="List of team you have already signed in"
          />
        </AuthFormLabel>
        {clientIds.map((id) => (
          <Button
            key={id}
            style={{ marginRight: '15px' }}
            text={id}
            onClick={() => onSelect(id)}
          />
        ))}
      </div>
    </ClientIdsWrapper>
  );
};

export default pure(AlreadyUsedClientIdList);
