import { gql, useQuery } from '@apollo/client';

const GET_PROPERTIES_FOR_OBJECT_TYPES = gql`
  query getRuleProperties(
    $id: ID
    $isStringOnly: Boolean
    $excludedProperties: String
  ) {
    management {
      id
      ruleProperties(
        id: $id
        isStringOnly: $isStringOnly
        excludedProperties: $excludedProperties
      ) {
        key
        type
        friendlyName
        group
        supportsAutoComplete
      }
    }
  }
`;

export const useQueryPropertyFromObjectType = (
  objectTypeId,
  isStringOnly,
  excludedProperties,
  skip = false,
) => {
  const { data, error, loading } = useQuery(GET_PROPERTIES_FOR_OBJECT_TYPES, {
    variables: {
      id: objectTypeId,
      isStringOnly,
      excludedProperties,
    },
    fetchPolicy: 'network-only',
    skip: skip || !objectTypeId,
  });

  const properties = ((data || {} || {}).management || {}).ruleProperties;

  return [properties || [], loading, error];
};

export default {
  useQueryPropertyFromObjectType,
};
