import React from 'react';
import { generateFormHoc } from 'uxi-business';
import { FormattedMessage } from '@cluedin/locale';
import { required, emailFormForm, password } from '../../../core/helpers/form';
import Tos from './Tos';
import { asyncSignupValidation } from '../../actions/createOrgAndUser';
import { url } from '../../../../config';

const SignUp = generateFormHoc(
  'CreateAccountForm',
  [
    {
      name: 'email',
      label: <FormattedMessage id="module-public-email" />,
      helpText: <FormattedMessage id="module-public-emailHelpText" />,
      type: 'email',
      readOnly: true,
      disabled: true,
      validate: [required, emailFormForm],
    },
    {
      name: 'organizationName',
      label: <FormattedMessage id="module-public-webAddress" />,
      helpText: <FormattedMessage id="module-public-webAddressHelp" />,
      validate: [required],
      sufix: `.${url.mainDomain}`,
    },
    // {
    //   name: 'username',
    //   label: 'Username',
    //   validate: [required],
    // },
    {
      name: 'password',
      label: <FormattedMessage id="module-public-password" />,
      helpText: <FormattedMessage id="module-public-passwordHelp" />,
      type: 'password',
      validate: [required, password],
    },
    {
      name: 'tos',
      validate: [required],
      Input: Tos,
    },
  ],
  {
    asyncValidate: asyncSignupValidation,
    asyncBlurFields: ['username', 'organizationName'],
    buttonLabel: (
      <span className="test_createAccountFormBtn">
        <FormattedMessage id="module-public-signup" />
      </span>
    ),
  },
);

const CreateAccountForm = ({ email, isMutating, onSignup }) => {
  return (
    <div className="test_createAccountForm">
      <SignUp
        isFetching={isMutating}
        initialValues={{ email }}
        onClick={onSignup}
      />
    </div>
  );
};

export default CreateAccountForm;
