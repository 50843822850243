import { useState, memo } from 'react';

import { useVocabularyKeys } from '../../../hooks/useVocabularyKeys';
import VocabularyKeyFullListComposite from '../VocabularyKeyFullList/VocabularyKeyFullListComposite';

export const VocabularyKeyListSelectionDialog = memo(
  ({ onSelectedChange, selectedVocabulary }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const [dataTypeFilterState, setDataTypeFilterState] = useState('');
    const [classificationFilterState, setClassificationFilterState] =
      useState('');
    const [integrationFilterState, setIntegrationFilterState] = useState('');

    const [data, loading, error] = useVocabularyKeys({
      searchName: searchTerm,
      pageNumber: currentPage,
      pageSize: 20,
      dataType: dataTypeFilterState,
      classification: classificationFilterState,
      connectorId: integrationFilterState,
      filterTypes: integrationFilterState === 'core' ? 1 : null,
      filterHasNoSource: integrationFilterState === 'noSource' ? true : null,
    });

    return (
      <VocabularyKeyFullListComposite
        selectable
        data={data}
        error={error}
        offsetTop={234}
        loading={loading}
        searchTerm={searchTerm}
        currentPage={currentPage}
        selected={selectedVocabulary}
        setSearchTerm={setSearchTerm}
        setCurrentPage={setCurrentPage}
        onSelectedChange={onSelectedChange}
        dataTypeFilterState={dataTypeFilterState}
        setDataTypeFilterState={setDataTypeFilterState}
        integrationFilterState={integrationFilterState}
        classificationFilterState={classificationFilterState}
        setIntegrationFilterState={setIntegrationFilterState}
        setClassificationFilterState={setClassificationFilterState}
      />
    );
  },
);
