import styled from 'styled-components';

export const EntityRelationsTreeRootEdgeWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  max-width: 100%;
`;
