import { intersectionWith } from 'lodash';
import { withWorkflow } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import GoldenRecordPropertySelection from '../GoldenRecordPropertySelection';
import RecordList from './RecordList';

import { useGoldenRecordList } from '../GoldenRecordListContext';

const EnhancedEntityList = withWorkflow(
  RecordList,
  GoldenRecordPropertySelection,
  {
    title: <FormattedMessage id="module-goldenRecordList-customizeColumns" />,
    panelType: 'medium',
  },
);

const GoldenRecordList = ({
  defaultEntityType,
  sortOptions,
  filterVocabularyOptions,
  hideBaseFilter,
  defaultBatchActionsConfiguration,
  batchActions = [],
  hasSelection,
  asSearchList,
  offsetTop = 114,
}) => {
  const {
    viewOptions: {
      viewMode,
      columns,
      saveCustomizedColumns,
      addColumns,
      deleteColumns,
      restrictions: { maxNumberColumns, excludePropertyKeys },
    },
  } = useGoldenRecordList();

  const onAddColumns = (newColumns = [], type) => {
    if (type === 'vocabulary') {
      const vocabularyColumns = newColumns.map((c) => ({
        property: c,
        type: 'vocabulary',
      }));

      addColumns(vocabularyColumns);
    }
    if (type === 'entity') {
      // All column already present
      const alreadyAdded = intersectionWith(columns, newColumns, (a, b) => {
        return a.property === b;
      });

      // All newly added column
      const allNew = newColumns.filter((c) => {
        const isAlreadyAddedColum = alreadyAdded.find(
          (aC) => aC.property === c,
        );
        return !isAlreadyAddedColum;
      });

      // We remove the one that was removed
      let updatedColumns = columns
        .map((currentColumn) => {
          if (currentColumn.type === 'vocabulary') {
            return currentColumn;
          }

          if (currentColumn.property === 'name') {
            return currentColumn;
          }

          const isFoundInUpdatedColumns = newColumns.find(
            (c) => c === currentColumn.property,
          );

          if (isFoundInUpdatedColumns) {
            return currentColumn;
          }

          return null;
        })
        .filter((c) => c);

      // We add the new one
      if (allNew && allNew.length > 0) {
        const newEntitiesColumn = allNew.map((c) => ({
          property: c,
          type: 'entity',
        }));

        updatedColumns = updatedColumns.concat(newEntitiesColumn);
      }

      saveCustomizedColumns(updatedColumns);
    }
  };

  return (
    <EnhancedEntityList
      columns={columns}
      viewMode={viewMode}
      offsetTop={offsetTop}
      sortOptions={sortOptions}
      asSearchList={asSearchList}
      hasSelection={hasSelection}
      batchActions={batchActions}
      onAddColumns={onAddColumns}
      hideBaseFilter={hideBaseFilter}
      onDeleteColumns={deleteColumns}
      maxNumberColumns={maxNumberColumns}
      defaultEntityType={defaultEntityType}
      onChangeColumns={saveCustomizedColumns}
      excludePropertyKeys={excludePropertyKeys}
      filterVocabularyOptions={filterVocabularyOptions}
      defaultBatchActionsConfiguration={defaultBatchActionsConfiguration}
    />
  );
};

export default GoldenRecordList;
