import { createAction } from 'redux-actions';
import { getEntity } from '../entity/data';
import {
  getAllFollowing,
  getAllSearches,
  clearSearchCount,
  subscribeSearch,
  unSubscribeSearch,
  clearFollowingCount,
} from './data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
} from '../core/helpers/action';

export const unFollowAction = createAction('STREAM_UN_FOLLOW');
export const followAction = createAction('STREAM_FOLLOW');

export const allFollowingActions = createAsyncAction('STREAM_ALL_FOLLOWING');
export const shouldFetchAllFollowingEntities = defaultAsyncCallWithActions(
  getAllFollowing,
  allFollowingActions,
);

export const resetFollowingCountActions = createAsyncAction(
  'STREAM_RESET_FOLLOW_COUNT',
);
export const shouldResetFollowingCount = noBodyResponseAsyncCallWithActions(
  clearFollowingCount,
  resetFollowingCountActions,
);

export const allSavedSearchesActions = createAsyncAction(
  'STREAM_ALL_SAVED_SEARCHES',
);
export const shouldFetchAllSavedSearches = defaultAsyncCallWithActions(
  getAllSearches,
  allSavedSearchesActions,
);

export const resetSearchCountActions = createAsyncAction(
  'STREAM_RESET_SEARCH_COUNT',
);
export const shouldResetSearchCount = noBodyResponseAsyncCallWithActions(
  clearSearchCount,
  resetSearchCountActions,
);

export const subscribeForSearchActions = createAsyncAction(
  'STREAM_SUBSCRIBE_SEARCH',
);
export const shouldSubsribeForSearch = noBodyResponseAsyncCallWithActions(
  subscribeSearch,
  subscribeForSearchActions,
  (dispatch) => {
    dispatch(shouldFetchAllSavedSearches());
  },
);

export const unsubscribeForSearchActions = createAsyncAction(
  'STREAM_UNSUBSCRIBE_SEARCH',
);
export const shouldUnsubsribeForSearch = noBodyResponseAsyncCallWithActions(
  unSubscribeSearch,
  unsubscribeForSearchActions,
);

export const resetSearchNotificationAction = createAction(
  'STREAM_RESET_SAVED_SEARCH_NOTIFICATION',
);
export const newRealTimeSavedSearchInformationAction = createAction(
  'STREAM_ADD_SAVED_SEARCH_NOTIFICATION',
);

export const receiveRealTimeSaveSearchInformation =
  ({ query, count }) =>
  (dispatch) => {
    setTimeout(() => {
      dispatch(resetSearchNotificationAction({ query, count }));
    }, 10000);

    dispatch(newRealTimeSavedSearchInformationAction(query, count));
  };

export const removeSavedSearchFromRealTimeAction = createAction(
  'STREAM_REMOVE_SAVED_SEARCH_NOTIFICATION',
);
export const clearSaveSearchFromRealTimeAction = createAction(
  'STREAM_SAVED_SEARCH_CLEAR_COUNT',
);
export const addAllNewSaveSearchFromRealTimeAction = createAction(
  'STREAM_ADD_NEW_SAVED_SEARCH',
);

export const resetFollowNotificationAction = createAction(
  'STREAM_RESET_FOLLOW_NOTIFICATION',
);
export const newRealTimeFollowInformationAction = createAction(
  'STREAM_ADD_FOLLOW_NOTIFICATION',
);

export const receiveRealTimeFollowInformation =
  ({ entityId, count }) =>
  (dispatch) => {
    getEntity(entityId).then((entity) => {
      setTimeout(() => {
        dispatch(resetFollowNotificationAction({ entityId, count }));
      }, 10000);

      dispatch(newRealTimeFollowInformationAction({ entity, count }));
    });
  };

export const removeFollowFromRealTimeAction = createAction(
  'STREAM_REMOVE_FOLLOW_NOTIFICATION',
);
export const clearFollowFromRealTimeAction = createAction(
  'STREAM_FOLLOW_CLEAR_COUNT',
);

export const addNewFollowFromRealTimeWithEntityAction = createAction(
  'STREM_FOLLOW_ADD_FROM_REAL_TIME',
);

export const addAllNewFollowFromRealTimeAction =
  ({ entityId, count }) =>
  (dispatch) => {
    getEntity(entityId).then((entity) => {
      if (entity) {
        dispatch(
          addNewFollowFromRealTimeWithEntityAction({
            entity,
            count,
          }),
        );
      }
    });
  };
