import React from 'react';
import {
  PrimaryButton,
  FormGenerator,
  useFormGeneratorWithReactHook,
} from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

const AuthSettingsFormFields = ({
  formFields = [],
  authValues = {},
  loading,
  style = {},
  saveAuthmethod,
}) => {
  const {
    fields,
    control,
    handleSubmit,
    errors,
    //watch,
    isDirty,
    touched,
  } = useFormGeneratorWithReactHook(formFields, authValues);

  return (
    <div style={style}>
      <FormGenerator
        fields={fields}
        control={control}
        errors={errors}
        touched={touched}
      />
      <PrimaryButton
        text={<FormattedMessage id="module-configuration-saveLabel" />}
        type="primary"
        loading={loading}
        disabled={!isDirty}
        onClick={handleSubmit((data) => {
          saveAuthmethod(data);
        })}
      />
    </div>
  );
};

export default AuthSettingsFormFields;
