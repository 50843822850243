import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import CreateOrgAndLoginFormContainer from './CreateOrgAndLoginFormContainer';
import PublicContext from '../../../auth/components/composites/PublicContext';
import SignUpImg from '../../assets/sign-up-illustration.png';

const SaveUrl = ({
  match: {
    params: { id },
  },
  location: { search },
}) => {
  const params = new URLSearchParams(search);
  const email = params.get('email');

  return (
    <NotSignedInLayoutDividedInTwo
      Page={CreateOrgAndLoginFormContainer}
      id={decodeURIComponent(id)}
      Context={PublicContext}
      url={`${SignUpImg}`}
      email={email}
    />
  );
};

export default SaveUrl;
