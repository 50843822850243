import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import SigninWrapper from './SigninWrapper';
import { location } from '../../../../../config';
import SignIn from './SignIn';

const SigninForm = ({
  invalidLogin,
  loginHandler,
  loadingSignin,
  clientId,
  email,
  setEmail,
  password,
  setPassword,
  emailRequired,
  emailError,
  passwordRequired,
  passwordError,
  touchedEmail,
  setTouchedEmail,
  touchedPassword,
  setTouchedPassword,
  invalidForm,
}) => {
  let errorMessage = null;

  if (navigator && navigator.onLine === false) {
    errorMessage = (
      <Alert type="danger">
        <FormattedMessage
          id="module-auth-signinOfflineError"
          defaultMessage="It looks like you are not connected to the internet."
        />
      </Alert>
    );
  } else if (invalidLogin) {
    errorMessage = (
      <div data-test="signIn_signInForm_signInGenericError">
        <Alert noIcon type="danger">
          <FormattedMessage
            id="module-auth-signinGenericError"
            defaultMessage="Sorry, you entered an incorrect email address or password."
          />
        </Alert>
      </div>
    );
  }

  return (
    <SigninWrapper clientId={clientId}>
      {errorMessage}
      <SignIn
        style={{ marginTop: '12px' }}
        loadingSignin={loadingSignin}
        forgotUrl={location.goToForgotPassword(clientId)}
        loginHandler={loginHandler}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        emailRequired={emailRequired}
        emailError={emailError}
        passwordRequired={passwordRequired}
        passwordError={passwordError}
        touchedEmail={touchedEmail}
        setTouchedEmail={setTouchedEmail}
        touchedPassword={touchedPassword}
        setTouchedPassword={setTouchedPassword}
        invalidForm={invalidForm}
      />
    </SigninWrapper>
  );
};

export default SigninForm;
