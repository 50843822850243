import React from 'react';
import PropTypes from 'prop-types';

const TextWithoutIconStyle = {
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  maxWidth: '180px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'inline-block',
  verticalAlign: 'bottom',
  flexGrow: 9,
  flexBasis: 'auto',
  width: '100%',
};

const TextWithoutIcon = ({ children }) => (
  <span style={TextWithoutIconStyle}>{children}</span>
);

TextWithoutIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextWithoutIcon;
