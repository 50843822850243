import React from 'react';

const PersonalIdentifiers = ({ personalIdentifiers = [] }) => (
  <div
    style={{
      border: '1px solid #d4d4d4',
      color: '#222222',
      background: '#F3F3F2',
      padding: '0 15px 15px 15px',
    }}
  >
    {personalIdentifiers.map((personalIdentifier) => (
      <div style={{ paddingTop: '15px' }}>
        <div>{personalIdentifier.key}</div>
        <div>
          <strong>{personalIdentifier.value}</strong>
        </div>
      </div>
    ))}
  </div>
);

export default PersonalIdentifiers;
