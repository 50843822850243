import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { appendTitle } from '../../../actions';

const withTitle = (Comp, m) => {
  const enhancedComp = compose(
    lifecycle({
      componentWillUnmount() {
        const { appendTitleAction } = this.props;
        appendTitleAction(null);
      },
      componentDidMount() {
        const { appendTitleAction } = this.props;
        appendTitleAction(m);
      },
    }),
  )(Comp);

  const mapToStateProps = () => ({});

  const mapDispatchToProps = (dispatch) => ({
    appendTitleAction(mod) {
      dispatch(appendTitle(mod));
    },
  });

  return connect(mapToStateProps, mapDispatchToProps)(enhancedComp);
};

export default withTitle;
