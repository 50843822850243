import styled from 'styled-components';

export const EntityMainBarUl = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  strong {
    font-weight: normal;
  }
`;

export const EntityMainBarLi = styled.li`
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
  list-style: none;
  float: left;
  margin: 0;
`;

export const EntityMainBarLiContent = styled.li`
  display: flex;
  alignitems: center;
`;

export const ButtonLinkText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewOriginalLi = styled.li`
  margin: 0;
  margin-left: 10px;
  list-style: none,
  float: left;
  padding: 0;
  height: 58px;
  font-size:14px;
  padding-top:2px;
  display: flex;
  align-items: center;
`;

export const BackLi = styled.li`
  list-style: none;
  float: left;
  padding: 0;
  margin: 0;
  height: 58px;
`;

export const BackLinkAnchor = styled.a`
  padding: 0 0 0 15px;
  display: inline-block;
  height: 58px;
  color: black !important;
  margin-left: -30px;
  &:hover {
    background: #f2f2f2;
  }
`;

export const TextWrapper = styled.span`
  border-right: 1px solid #ccc;
  padding-right: 15px;
`;
