import React, { useState } from 'react';
import differenceWith from 'lodash/differenceWith';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { withWorkflow } from '@cluedin/form';
import { List } from '@cluedin/list-pattern';
import RoleCreationForm from '../forms/RoleCreationForm';
import { useQueryRoles } from '../../../hooks/useQueryRoles';
import { columns } from './columns';

const { admin } = actionsConfiguration;

export const RolesListContainer = injectIntl(
  ({
    openWorkflow,
    searchTerm,
    onSubmitSearch,
    currentPage,
    onPageChange,
    selected,
    propertyKey = 'id',
    roleIdsToOmit = [],
    intl,
    creation = [
      {
        label: <FormattedMessage id="module-role-createRole" />,
        onClick: () => {
          openWorkflow();
        },
        actionConfiguration: admin.roles.role.create,
      },
    ],
    selectable = false,
    withToolbar,
    filterToolbar,
    onSelectedChange,
  }) => {
    const [searchName, setSearchName] = useState(searchTerm);
    const [roles, totalItems, loading, error] = useQueryRoles({
      searchTerm,
      ...currentPage,
    });

    const rolesInList = differenceWith(
      roles,
      roleIdsToOmit,
      (r, id) => r.id === id,
    );
    const numberOfRoles = (roleIdsToOmit || []).length;
    const totalRoles = parseInt(totalItems, 10) - numberOfRoles;

    return (
      <List
        searchInputPlaceholder={intl.formatMessage({
          id: 'module-role-roleSearchPlaceholer',
        })}
        selectable={selectable}
        propertyKey={propertyKey}
        selected={selected}
        onSelectedChange={onSelectedChange}
        data={rolesInList}
        total={totalRoles}
        selectedPage={currentPage.selectedPage}
        itemsPerPage={currentPage.itemsPerPage}
        onPageChange={onPageChange}
        columns={columns}
        onSubmitSearch={onSubmitSearch}
        onChangeSearch={setSearchName}
        searchName={searchName}
        loading={loading}
        error={error}
        creation={creation}
        withToolbar={withToolbar}
        filterToolbar={filterToolbar}
        offsetTop={108}
        notFoundProps={{
          buttonLabel: <FormattedMessage id="module-role-createRole" />,
          message: <FormattedMessage id="module-role-noRolesFound" />,
          explanation: <FormattedMessage id="module-role-roleExplanation" />,
          onCreateClick: openWorkflow,
          actionConfiguration: admin.roles.role.create,
        }}
      />
    );
  },
);

export default withWorkflow(RolesListContainer, RoleCreationForm, {
  title: <FormattedMessage id="module-role-createRole" />,
});
