import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import {
  shouldFetchUnapprovedConfigurations,
  forceFetchAllActiveConfigurations,
  shouldFetchInactiveConfigurations,
} from '../../../actions';
import { open } from '../../../../quickview/actions';
import ConfigurationListWithFilters from '../../../components/composites/lists/ConfigurationListWithFilters';
import ConfigurationListWithFiltersLoading from '../../../components/composites/lists/ConfigurationListWithFiltersLoading';
import ConfigurationListWithFiltersErrored from '../../../components/composites/lists/ConfigurationListWithFiltersErrored';
import ConfigurationListWithFiltersEmpty from '../../../components/composites/lists/ConfigurationListWithFiltersEmpty';
import { fetchAllUsers } from '../../../../user/actions/index';

const removeDeleted = (c) => !c.isDeleted;

const EnhancedConfigurationListWithFilters = compose(
  lifecycle({
    componentDidMount() {
      const { fetchProviders, onFetchAllUsers } = this.props;
      fetchProviders();
      onFetchAllUsers();
    },
  }),
  branch(
    ({ isFetching, providerError }) => !providerError && isFetching,
    renderComponent(ConfigurationListWithFiltersLoading),
  ),
  branch(
    ({ providerError }) => providerError,
    renderComponent(ConfigurationListWithFiltersErrored),
  ),
  branch(
    ({ configurations }) => configurations && configurations.length <= 0,
    renderComponent(ConfigurationListWithFiltersEmpty),
  ),
)(ConfigurationListWithFilters);

const mapToSelectProps = ({
  configuration: {
    allActiveConfigurations,
    isFetchingAllActiveConfigurations,
    invalidAllActiveConfigurations,

    allUnapprovedConfigurations,
    isFetchingUnapprovedConfiguration,
    invalidUnapprovedConfiguration,

    allInactiveConfigurations,
    isFetchingAllInactiveConfigurations,
    invalidAllInactiveConfigurations,

    configurationMessage,
  },
  user: { allUsers },
}) => {
  const configurations = [
    ...((allActiveConfigurations || []).filter(removeDeleted) || []),
    ...((allInactiveConfigurations || []).filter(removeDeleted) || []),
    ...((allUnapprovedConfigurations || []).filter(removeDeleted) || []),
  ];

  configurations.map((c) => {
    let status;
    const configuration = c;

    if (configurationMessage && configurationMessage[c.Id]) {
      const statusAsNumber = configurationMessage[c.Id];

      if (statusAsNumber === 0) {
        status = 'Processing';
      } else if (statusAsNumber === 1) {
        status = 'Crawling';
      } else if (statusAsNumber === 2) {
        status = 'Queued';
      } else if (statusAsNumber === 3) {
        status = 'Idle';
      } else if (statusAsNumber === 4) {
        status = 'Finished';
      }
    }

    configuration.status = status;

    return configuration;
  });

  return {
    providerError:
      invalidAllActiveConfigurations ||
      invalidAllInactiveConfigurations ||
      invalidUnapprovedConfiguration,
    configurations,
    isFetching:
      isFetchingAllActiveConfigurations ||
      !allActiveConfigurations ||
      isFetchingAllInactiveConfigurations ||
      !allInactiveConfigurations ||
      !allUnapprovedConfigurations ||
      isFetchingUnapprovedConfiguration,
    allUsers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProviders: () => {
    dispatch(forceFetchAllActiveConfigurations());
    dispatch(shouldFetchInactiveConfigurations());
    dispatch(shouldFetchUnapprovedConfigurations());
  },
  onConfigurationClick: (data) => {
    dispatch(
      open({
        type: 'configuration',
        data,
      }),
    );
  },
  onFetchAllUsers: () => {
    dispatch(fetchAllUsers());
  },
});

export default connect(
  mapToSelectProps,
  mapDispatchToProps,
)(EnhancedConfigurationListWithFilters);
