import React from 'react';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';
import DashboardActionKPI from './DashboardActionKPI';
import UnstyledLink from '../../../../modules/core/components/composites/UnstyledLink';
import RenderChildren from '../../../../modules/core/components/composites/RenderChildren';
import { withGqlQuery } from '../../data/withGqlQuery';
import { getColorForType } from '../../../core/helpers/colors';

const ActionKpi = ({
  className,
  hasNew,
  icon,
  data,
  title,
  actionLabel,
  quickView,
  kpiType,
  to,
  match,
  loading,
}) => {
  const isLink = (quickView && quickView.path && quickView.widget) || to;

  const WrapperComp = isLink ? UnstyledLink : RenderChildren;
  const linkTo =
    quickView && quickView.path ? `${match.url}${quickView.path}` : to;

  const IconComp = getAppropriateIcon(icon || 'Help');
  const color = getColorForType(kpiType);

  return (
    <WrapperComp to={linkTo} style={{ color }}>
      <DashboardActionKPI
        loading={loading}
        className={className}
        kpiType={kpiType}
        hasNew={hasNew}
        value={data}
        title={title}
        icon={<IconComp />}
        actionLabel={actionLabel}
      />
    </WrapperComp>
  );
};

export default {
  name: 'ActionKpi',
  type: 'actionkpi',
  required: 'dashboard',
  Component: withGqlQuery(ActionKpi),
  parameters: [
    {
      type: 'string',
      name: 'title',
      displayName: 'Title',
      helpText: 'The main label for your KPI',
    },
    {
      type: 'string',
      name: 'to',
      displayName: 'Link',
      helpText: 'Link the user will be redirect to',
    },
    {
      type: 'icon',
      name: 'icon',
      displayName: 'Icon',
      helpText:
        'A nice icon to choose from to make the widget more understandable.',
    },
    {
      type: 'string',
      name: 'gql',
      displayName: 'Query',
      helpText: 'A Gql Query that would be executed',
    },
  ],
};
