import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { Spinner } from '@cluedin/form';
import { Flex } from 'uxi/Layout';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import {
  shouldFetchGDPRConfig,
  shouldhideEscalateDialog,
  shouldShowEscalateDialog,
  shouldFetchGDPRJsonReport,
  shouldFetchSARById,
  resetGDPRJsonReportActions,
} from '../../actions';
import GdprReportComposite from '../composites/gdprReport/GdprReportComposite';

const JsonReportFetchingErrorMessage = () => (
  <Alert type="error">
    <FormattedMessage
      id="module-gdpr-jsonReportErrorMessage"
      values={{
        link: (
          <a href="mailto:support@cluedin.com">
            <FormattedMessage id="module-gdpr-jsonReportErrorMessageLink" />
          </a>
        ),
      }}
    />
  </Alert>
);

const LoadingCompo = () => (
  <Flex>
    <Spinner />
  </Flex>
);

const GdprReportContainerEnhanced = compose(
  lifecycle({
    componentDidMount() {
      const { fetchConfig, fetchSAR, sarId, currentSAR } = this.props;
      fetchConfig();

      if (!currentSAR || !('Id' in currentSAR)) {
        fetchSAR(sarId);
      }
    },
    componentWillUnmount() {
      const { onShouldResetGDPRJsonReport } = this.props;
      // only clean if we had a JsonReportUri
      if (onShouldResetGDPRJsonReport) {
        onShouldResetGDPRJsonReport();
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        currentSARJsonReport,
        currentSAR,
        isFetchingJsonReport,
        onShouldFetchGDPRJsonReport,
        isFetchingJsonReportError,
      } = nextProps;

      if (!this.props.isFetchingJsonReportError && !isFetchingJsonReportError) {
        if (currentSAR && currentSAR.ReportJsonUri) {
          if (!isFetchingJsonReport && !currentSARJsonReport) {
            if (onShouldFetchGDPRJsonReport) {
              onShouldFetchGDPRJsonReport(nextProps.currentSAR.ReportJsonUri);
            }
          }
        }
      }
    },
  }),
  branch(
    ({ isFetchingJsonReportError }) => isFetchingJsonReportError,
    renderComponent(JsonReportFetchingErrorMessage),
  ),
  branch(
    ({ currentSAR, currentSARJsonReport }) =>
      !currentSAR && !currentSARJsonReport,
    renderComponent(LoadingCompo),
  ),
)(GdprReportComposite);

const stateToProps = (
  {
    GDPR: {
      isFetching,
      currentSAR,
      currentSARJsonReport,
      configuration,
      isFetchingConfiguration,
      escalateDialog,
      showEscalateDialog,
      isFetchingJsonReport,
      isFetchingJsonReportError,
    },
  },
  { handleSubmit: reduxFormHandleSubmit },
) => ({
  isFetching,
  currentSAR,
  currentSARJsonReport,
  configuration,
  isFetchingConfiguration,
  escalateDialog,
  showEscalateDialog,
  reduxFormHandleSubmit,
  isFetchingJsonReport,
  isFetchingJsonReportError,
});

const dispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(shouldFetchGDPRConfig()),
  fetchSAR: (sarId) => dispatch(shouldFetchSARById(sarId)),
  onCloseEscalte: () => {
    dispatch(shouldhideEscalateDialog());
  },
  onShowEscalatePopUp(sarIds) {
    dispatch(shouldShowEscalateDialog(sarIds));
  },
  onShouldFetchGDPRJsonReport(jsonReportUri) {
    dispatch(shouldFetchGDPRJsonReport(jsonReportUri));
  },
  onShouldResetGDPRJsonReport() {
    dispatch(resetGDPRJsonReportActions());
  },
});

export default connect(
  stateToProps,
  dispatchToProps,
)(GdprReportContainerEnhanced);
