export default {
  header: {
    display: 'flex',
  },
  headerBack: {},
  headerButton: {
    flex: 1,
    textAlign: 'right',
  },
  entityWrapper: {
    marginTop: '15px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  entityType: {
    marginRight: '10px',
  },
  entityName: {
    fontSize: '18px',
    lineHeight: '50px',
  },
  title: {
    marginTop: '15px',
  },
  entityTitleWrapper: {
    marginTop: '15px',
    minHeight: '50px',
    display: 'flex',
  },
  entityTitleIcon: {
    paddingTop: '5px',
    marginRight: '10px',
  },
  entityTitleName: {
    fontSize: '18px',
    lineHeight: '50px',
  },
  listWrapper: {
    marginTop: '15px',
  },
  newChangesTitle: {
    margin: 0,
    padding: 0,
  },
  newChangesTitleWrapper: {
    marginTop: '15px',
  },
  newChangesSubTitle: {
    margin: 0,
    padding: 0,
  },
};
