import React from 'react';
import styled from 'styled-components';
import SimpleGrid from 'uxi/Layout/SimpleGrid';
import FilterGroup from '../FilterGroup';
import EntityFilter from '../EntityFilter';
import { FormattedMessage } from '@cluedin/locale';
import AdvancedSearchFiltersDialog from './AdvancedSearchFiltersDialog';
import { personPool, orgPool } from '../poolConfig';
import withInMemoryFilter from '../../../../../HOCs/withInMemoryFilter';

const FilterGroupWithMemoryFilter = withInMemoryFilter(FilterGroup, '/');

const WrapperUI = styled.div`
  padding: 32px 16px;
  max-height: 100%;
  & > * > * > * {
    /* this > grid > gridItem > filterGroup */
    display: flex;
    flex-direction: column;
    height: 100%;
    & > *:last-child {
      overflow: auto;
    }
  }
`;

const FilterGroupInDialogWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  background: #fff;
  border: 1px solid #cecece;
  transition: ${({ theme: { transition } }) => transition.defaultAll};
  margin: 0 8px;
  & > * {
    box-sizing: border-box;
    min-height: 220px;
    & > *:first-child {
      display: none; /* hide filterGroup border bottom */
    }
  }

  & > * > *:nth-child(3) {
    display: none;
  }
`;

const AdvancedSearchFilters = ({
  facets = {},
  selectedFilters,
  onAddFilter,
  onRemoveFilter,
  onFilterQueryChange,
  currentFilterQuery,
  showAdvancedSearch,
  onClose,
  searchStat,
  shouldFetchSearch,
  isFetchingSearch,
  currentEntityTypePoolIdentifier,
  onEntityTypePoolChange,
  totalLoading,
}) => (
  <AdvancedSearchFiltersDialog
    show={showAdvancedSearch}
    onClose={onClose}
    searchStat={searchStat}
    totalLoading={totalLoading}
    shouldFetchSearch={shouldFetchSearch}
  >
    <WrapperUI>
      <SimpleGrid
        columnAutoSizing={'fill'}
        gap={16}
        itemWidth={'301px'} // 285 + 16
        style={{
          width: '100%',
          margin: '8px 0',
        }}
      >
        {Object.keys(facets)
          .filter((facet) => facets[facet].length > 0)
          .map((k, i) => (
            <FilterGroupInDialogWrapper key={`${k}-${i}`}>
              <FilterGroupWithMemoryFilter
                currentEntityTypePoolIdentifier={
                  currentEntityTypePoolIdentifier
                }
                onEntityTypePoolChange={onEntityTypePoolChange}
                isFetchingMainSearch={isFetchingSearch}
                title={k}
                filters={facets[k]}
                totalFiltersCount={((facets || {})[k] || []).length}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                selectedFilters={selectedFilters.filter(
                  (x) => x.type.toLowerCase() === k.toLowerCase(),
                )}
                onFilterQueryChange={onFilterQueryChange}
                currentFilterQuery={currentFilterQuery}
                filterType={{ type: 'facet', value: k }}
              />
            </FilterGroupInDialogWrapper>
          ))}
        <FilterGroupInDialogWrapper>
          <EntityFilter
            title={<FormattedMessage id="module-search-person" />}
            selectedFilters={selectedFilters.filter(
              (x) => x.type === personPool.join('-'),
            )}
            entityTypes={personPool}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            filterType={{ type: 'facet', value: 'Person' }}
          />
        </FilterGroupInDialogWrapper>
        <FilterGroupInDialogWrapper>
          <EntityFilter
            title={<FormattedMessage id="module-search-organization" />}
            selectedFilters={selectedFilters.filter(
              (x) => x.type === orgPool.join('-'),
            )}
            entityTypes={orgPool}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            filterType={{ type: 'facet', value: 'Organization' }}
          />
        </FilterGroupInDialogWrapper>
      </SimpleGrid>
    </WrapperUI>
  </AdvancedSearchFiltersDialog>
);

AdvancedSearchFilters.displayName = 'AdvancedSearchFiltersContainer';

export default AdvancedSearchFilters;
