import { useState, useMemo, useCallback } from 'react';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { TextField } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { FormDecorator } from '@cluedin/form';
import Alert from 'uxi/Icons/Alert';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';

import { getClassicValidationMessage } from '../../../../core/validation/messages';
import { usePaginatedVocabularies } from '../../../hooks/usePaginatedVocabularies';
import { VocabularySelector } from './VocabularySelector';
import { replaceAll } from '../../../../core/helpers/string';
import { validatePrefix, validateVocabularyName } from '../../../validation';

export const VocabularySelectAndCreate = injectIntl(
  ({
    intl,
    onChange,
    disabled,
    value = {},
    style = {},
    isClearable,
    isCreatable,
  }) => {
    const [tempVal, setTempVal] = useState();
    const [isNewVocabulary, setIsNewVocabulary] = useState(value.new);
    const [touchedPrefix, setTouchedPrefix] = useState(false);

    const {
      loadMore,
      vocabularies,
      totalVocabularies,
      setSearchVocabularies,
      isLoadingVocabularies,
    } = usePaginatedVocabularies({
      loadMoreLabel: intl.formatMessage({
        id: 'data-source-mapping-vocab-select-loadMore',
      }),
    });

    const vocabularyName = useMemo(() => value?.vocabularyName, [value]);
    const keyPrefix = useMemo(() => value?.keyPrefix, [value]);
    const id = useMemo(() => value?.vocabularyId, [value]);

    const adaptedVocabularySelectValue = useMemo(() => {
      return vocabularyName && keyPrefix
        ? {
            label: vocabularyName ?? '',
            value: id || keyPrefix || '',
          }
        : undefined;
    }, [vocabularyName, keyPrefix, id]);

    const onChangeKeyPrefix = useCallback((_, v) => {
      setTouchedPrefix(true);
      const keyPrefixWithoutSpace = replaceAll(v || '', ' ', '');
      const hasErrorKeyPrefix = !!validatePrefix(keyPrefixWithoutSpace, 100);

      onChange((prev) => ({
        ...prev,
        keyPrefix: keyPrefixWithoutSpace,
        hasErrorKeyPrefix,
      }));
    }, []);

    const theme = useTheme();
    const themePrimary = theme.palette.themePrimary;

    let prefixErrorText;
    let vocabularyNameErrorText;

    if (isNewVocabulary && touchedPrefix) {
      prefixErrorText = validatePrefix(keyPrefix);
    }
    if (isNewVocabulary && vocabularyName) {
      vocabularyNameErrorText = validateVocabularyName(vocabularyName);
    }

    const newVocabularyValidationError =
      tempVal?.vocabularyName &&
      getClassicValidationMessage(tempVal.vocabularyName, 100);

    const adaptedTempSelectValue = useMemo(() => {
      return tempVal?.vocabularyName && tempVal?.keyPrefix
        ? {
            label: tempVal?.vocabularyName ?? '',
            value: tempVal?.keyPrefix,
          }
        : undefined;
    }, [tempVal]);

    return (
      <div style={style}>
        <VocabularySelector
          isClearable={isClearable}
          isCreatable={isCreatable}
          total={totalVocabularies}
          setTempVal={setTempVal}
          hasError={!!newVocabularyValidationError}
          data={vocabularies}
          disabled={disabled}
          onChange={(config) => {
            if (config.new) {
              setTouchedPrefix(true);
            } else {
              setTouchedPrefix(false);
            }
            onChange(config);
          }}
          loadMore={loadMore}
          placeholder={<FormattedMessage id="module-vocabulary-vacab-label" />}
          isLoading={isLoadingVocabularies}
          value={adaptedTempSelectValue || adaptedVocabularySelectValue}
          setSearchName={setSearchVocabularies}
          setIsNewVocabulary={setIsNewVocabulary}
        />

        {newVocabularyValidationError && (
          <div style={{ marginBottom: '-16px' }}>
            {newVocabularyValidationError}
          </div>
        )}

        {!isNewVocabulary &&
          value?.vocabularyId &&
          !newVocabularyValidationError && (
            <Link
              style={{
                marginTop: '10px',
                display: 'inline-flex',
                alignItems: 'center',
                color: themePrimary,
              }}
              target="_blank"
              to={`/admin/management/catalog/detail/${value?.vocabularyId}`}
            >
              <div style={{ marginRight: '10px' }}>
                <FormattedMessage id="module-dataCatalog-view-details-on" />{' '}
                {value?.vocabularyName}
              </div>
              <Externallink size={14} />
            </Link>
          )}

        {vocabularyNameErrorText && (
          <div style={{ marginTop: '6px' }}>{vocabularyNameErrorText}</div>
        )}

        {isNewVocabulary && !newVocabularyValidationError && (
          <>
            <div
              style={{
                color: '#5B89CF',
                fontSize: '12px',
                marginTop: '6px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Alert style={{ marginRight: '6px' }} size={16} color="#5B89CF" />
              <em>
                <FormattedMessage id="module-dataCatalog-field-name" />
              </em>
              <strong style={{ margin: '0 4px' }}>{vocabularyName}</strong>
              <em>
                <FormattedMessage id="module-dataCatalog-field-name-help" />
              </em>
            </div>
            <FormDecorator
              isRequired
              errorText={prefixErrorText}
              style={{ marginTop: '12px' }}
              label={
                <FormattedMessage id="module-dataCatalog-field-keyPrefix" />
              }
              helpText={
                <FormattedMessage id="module-dataCatalog-field-keyPrefix-help" />
              }
            >
              <TextField value={keyPrefix} onChange={onChangeKeyPrefix} />
            </FormDecorator>
          </>
        )}
      </div>
    );
  },
);
