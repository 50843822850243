import get from 'lodash/get';
import constants from '../constants';
import { unauthorizedForEntity } from './generic';
import { getEntity, getSchema } from '../data/entity';

const requestEntity = () => ({
  type: constants.entity.REQUEST_ENTITY,
});

const receiveEntity = (entity, addToEntities) => ({
  type: constants.entity.RECEIVE_ENTITY,
  data: {
    entity,
    addToEntities,
  },
});

const invalidEntity = () => ({
  type: constants.entity.INVALID_ENTITY,
});

export const shouldCleanUpSelected = () => ({
  type: constants.entity.CLEAN_UP_SELECTED_ENTITY,
});

const shouldFetchEntity = (state, id) =>
  !state.entity.selectedEntity || state.entity.selectedEntity.id === id;

const invalidUpdateEntity = () => ({
  type: constants.entity.INVALID_UPDATE_ENTITY,
});

const receiveUpdateEntity = (entity) => ({
  type: constants.entity.RECEIVE_UPDATE_ENTITY,
  data: {
    entity,
  },
});

const shouldUpdateEntity = (state, id) => (dispatch) => {
  const entityAlreadyFetch =
    state && state.entity && state.entity.entities && state.entity.entities[id];

  if (entityAlreadyFetch) {
    getEntity(id)
      .then((resp) => {
        if (!resp) {
          return dispatch(invalidUpdateEntity());
        }

        return dispatch(receiveUpdateEntity(resp));
      })
      .catch(unauthorizedForEntity(dispatch, invalidUpdateEntity, id));
  }
};

const fetchEntity = (state, id, force) => (dispatch) => {
  const entityAlreadyFetch = state.entity.entities[id];

  dispatch(requestEntity(id));

  if (!force && entityAlreadyFetch) {
    return dispatch(receiveEntity(entityAlreadyFetch));
  }

  return getEntity(id)
    .then((resp) => {
      if (!resp) {
        return dispatch(invalidEntity());
      }

      return dispatch(receiveEntity(resp, true));
    })
    .catch(unauthorizedForEntity(dispatch, invalidEntity, id));
};

export function shouldFetchEntityIfNeeded(id, force) {
  return (dispatch, getState) => {
    if (shouldFetchEntity(getState(), id)) {
      dispatch(fetchEntity(getState(), id, force));
    }
    if (force) {
      dispatch(shouldUpdateEntity(id));
    }
  };
}

const requestSchemaForEntities = () => ({
  type: constants.entity.REQUEST_ENTITY_SCHEMA,
});

const receiveSchemaForEntities = (schema) => ({
  type: constants.entity.RECEIVE_ENTITY_SCHEMA,
  data: {
    schema,
  },
});

export function shouldFetchSchema() {
  return (dispatch, state) => {
    const { isFetchingSchema, schema } = get(state(), 'entity', {});
    if (!isFetchingSchema && !schema) {
      dispatch(requestSchemaForEntities());
      return getSchema().then((schema) => {
        dispatch(receiveSchemaForEntities(schema));
      });
    }

    return null;
  };
}

const requestNewEntityUpdate = () => ({
  type: constants.entity.REQUEST_NEW_ENTITY_UPDATE,
});

const receiveNewEntityUpdate = (entity, count) => ({
  type: constants.entity.RECEIVE_NEW_ENTITY_UPDATE,
  data: {
    entity,
    count,
  },
});

export const resetNewEntityUpdate = (entityId) => ({
  type: constants.entity.RESET_NEW_ENTITY_UPDATE,
  data: {
    entityId,
  },
});

export const shouldReceiveNewEntityUpdate = (entityId, count) => (dispatch) => {
  dispatch(requestNewEntityUpdate(entityId));

  return getEntity(entityId).then((resp) => {
    dispatch(shouldFetchEntityIfNeeded(entityId, true));

    setTimeout(() => {
      dispatch(resetNewEntityUpdate(entityId));
    }, 10000);

    dispatch(receiveNewEntityUpdate(resp, count));
  });
};
