import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { abortMerge } from '../../../entityMerge/actions';

// hard-code message for now
// eslint-disable-next-line no-return-assign
const beforeUnloadHandler = (e) =>
  (e.returnValue =
    'Your are currently selecting entites for a merging! Are you sure you want to leave?');

/**
 * set up custom dialog/behavior : http://bit.ly/2AiMuCg
 */
const addPreventExit = (Page) => {
  class Decorated extends Component {
    componentDidMount() {
      if (this.props.isMerging) {
        window.addEventListener('beforeunload', beforeUnloadHandler);
      }
    }

    componentWillUnmount() {
      const { onNavigationCompleted } = this.props;
      if (onNavigationCompleted) {
        onNavigationCompleted();
      }
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }

    routerWillLeave() {
      if (this.props.isMerging) {
        return 'Your are currently selecting entites for a merging! Are you sure you want to leave ?';
      }
      return true;
    }

    render() {
      const { isMerging } = this.props;

      return (
        <div>
          <Prompt
            when={isMerging}
            message={() =>
              'Your are currently selecting entites for a merging! Are you sure you want to leave ?'
            }
          />
          <Page {...this.props} />
        </div>
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onNavigationCompleted: () => dispatch(abortMerge()),
  });

  const mapStateToProps = ({ entityMerge }) => ({
    isMerging: entityMerge.isMerging,
  });

  return connect(mapStateToProps, mapDispatchToProps)(Decorated);
};

export default addPreventExit;
