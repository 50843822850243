import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';
import { User } from './fragments';

const ALL_USERS = gql`
  query getAllUsers {
    administration {
      allUsers {
        ...User
      }
    }
  }
  ${User.fragments.user}
`;

export const useQueryAllUsers = () => {
  const { data, loading, error } = useQuery(ALL_USERS);

  const users = get(data, 'administration.allUsers', []);

  return [users, loading, error];
};
