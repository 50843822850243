/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'uxi/Button/FlatButton';
import Button from 'uxi/Button';

export const PageNumberButton = ({
  pageNumber,
  currentPage,
  onPageChange,
  show = true,
}) => {
  if (!show) {
    return null;
  }

  const isCurrentPage = currentPage === pageNumber;
  const Component = isCurrentPage ? Button : FlatButton;

  return (
    <Component
      message={pageNumber}
      type={isCurrentPage ? 'primary' : null}
      style={{ marginRight: '2px' }}
      onClick={() => {
        if (!isCurrentPage) {
          onPageChange(pageNumber);
        }
      }}
    />
  );
};

PageNumberButton.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
