import { handleActions } from 'redux-actions';
import { open, close, update } from './actions';

const intialState = {
  configuration: null,
  entity: null,
  user: null,
};

export default handleActions(
  {
    '@@router/LOCATION_CHANGE': (state) => ({
      ...state,
      configuration: null,
      entity: null,
      user: null,
    }),
    [open]: (state, { payload: { type, data } }) => ({
      configuration: type === 'configuration' ? data : null,
      entity: type === 'entity' ? data : null,
      user: type === 'user' ? data : null,
    }),
    [close]: (state) => ({
      ...state,
      configuration: null,
      entity: null,
      user: null,
    }),
    [update]: (state, { payload: { type, data } }) => ({
      configuration: type === 'configuration' ? data : null,
      entity: type === 'entity' ? data : null,
      user: type === 'user' ? data : null,
    }),
  },
  intialState,
);
