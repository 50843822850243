"use strict";

exports.__esModule = true;
exports.engineRoom = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ENGINE_CONFIGURATIONGROUPS_INFORMED = _claimTypes.default.ENGINE_CONFIGURATIONGROUPS_INFORMED,
  ENGINE_JOBS_INFORMED = _claimTypes.default.ENGINE_JOBS_INFORMED,
  ENGINE_PROCESSING_INFORMED = _claimTypes.default.ENGINE_PROCESSING_INFORMED,
  ENGINE_STATISTICS_INFORMED = _claimTypes.default.ENGINE_STATISTICS_INFORMED;
var engineRoom = {
  configurationGroups: {
    group: {
      view: {
        claims: ENGINE_CONFIGURATIONGROUPS_INFORMED
      }
    }
  },
  jobs: {
    job: {
      view: {
        claims: ENGINE_JOBS_INFORMED
      }
    }
  },
  processing: {
    pipeline: {
      view: {
        claims: ENGINE_PROCESSING_INFORMED
      }
    }
  },
  statistics: {
    statistic: {
      view: {
        claims: ENGINE_STATISTICS_INFORMED
      }
    }
  }
};
exports.engineRoom = engineRoom;