import React from 'react';
import { Flex } from 'uxi/Layout';
import { compose } from 'recompose';
import { actionsConfiguration, withClaimsDisabled } from '@cluedin/components';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import {
  Tile,
  ImportFromFileIcon,
  ViewAllDataSources,
  AvailableIntegrations,
  ImportFromDatabaseIcon,
  ConfiguredIntegrations,
  ImportFromIngestionPointIcon,
  ManualDataEntryIcon,
} from '@cluedin/atoms';

import { hasFeature } from '../../../../config';
import { withImportFileWitoutDataSet } from '../../../dataSourceV2/hocs/withImportFile';
import DashboardTitle from '../../../core/components/composites/dashboard/DashboardTitle';
import { withAddIngestionPointWithoutDataSet } from '../../../dataSourceV2/hocs/withAddIngestionPoint';
import { withImportFromDatabaseWitoutDataSet } from '../../../dataSourceV2/hocs/withImportFromDatabase';
import { withManualClueCreation } from '../../../manualDataEntry/hooks/withManualClueCreation';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';

const { integrations } = actionsConfiguration;
const DashboardTile = withClaimsDisabled(Tile);

const DashboardTileActions = ({
  goToDatasources,
  goToApplications,
  goToConfigurations,
  openImportFromFile,
  openImportFromDataBase,
  openImportFromIngestionPoint,
  goToManualDataEntryProject,
  openManualClueCreation,
}) => {
  const hasImportDatabase = useOrganizationFeatureFlagOption('ImportDatabase');
  const hasManualDataEntry =
    useOrganizationFeatureFlagOption('manualDataEntry');

  return (
    <>
      <DashboardTitle>
        <FormattedMessage id="module-integration-actions" />
      </DashboardTitle>

      <Flex
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
        }}
      >
        {hasFeature('datasource') && (
          <>
            <div className="test_Integration_Dashboard_dataSources_add_file">
              <DashboardTile
                Icon={ImportFromFileIcon}
                onClick={openImportFromFile}
                actionConfiguration={integrations.dataSources.dataSet.create}
                displayName={
                  <FormattedHTMLMessage id="module-integration-importFromFile" />
                }
              />
            </div>

            {hasImportDatabase && (
              <div className="test_Integration_Dashboard_dataSources_add_file">
                <DashboardTile
                  Icon={ImportFromDatabaseIcon}
                  onClick={openImportFromDataBase}
                  actionConfiguration={integrations.dataSources.dataSet.create}
                  displayName={
                    <FormattedHTMLMessage id="module-integration-importFromDB" />
                  }
                />
              </div>
            )}

            {hasFeature('endpoint') && (
              <div className="test_Integration_Dashboard_dataSources_add_ingestion">
                <DashboardTile
                  Icon={ImportFromIngestionPointIcon}
                  onClick={openImportFromIngestionPoint}
                  actionConfiguration={integrations.dataSources.dataSet.create}
                  displayName={
                    <FormattedHTMLMessage id="module-integration-importFromEndpoint" />
                  }
                />
              </div>
            )}
          </>
        )}
        {hasManualDataEntry && (
          <div className="test_Integration_Dashboard_dataSources_add_ingestion">
            <DashboardTile
              Icon={ManualDataEntryIcon}
              onClick={openManualClueCreation}
              displayName={
                <FormattedHTMLMessage id="module-manualDataEntry-displayName" />
              }
            />
          </div>
        )}

        <div className="test_Integration_Dashboard_AvailableIntegrations">
          <DashboardTile
            type="secondary"
            Icon={AvailableIntegrations}
            onClick={goToApplications}
            actionConfiguration={
              integrations.availableIntegrations.availableIntegration.view
            }
            displayName={
              <FormattedMessage
                id="module-integration-ViewAllApplications"
                defaultMessage="Available Integrations"
              />
            }
          />
        </div>

        <div className="test_Integration_Dashboard_ConfiguredIntegrations">
          <DashboardTile
            type="secondary"
            Icon={ConfiguredIntegrations}
            onClick={goToConfigurations}
            actionConfiguration={
              integrations.configuredIntegrations.configuredIntegration.view
            }
            displayName={
              <FormattedMessage
                id="module-integration-ViewAllConfigurations"
                defaultMessage="Configured Integrations"
              />
            }
          />
        </div>

        {hasFeature('datasource') && (
          <div className="test_Integration_Dashboard_dataSources">
            <DashboardTile
              type="secondary"
              Icon={ViewAllDataSources}
              onClick={goToDatasources}
              actionConfiguration={
                integrations.dataSources.dataSourceGroup.view
              }
              displayName={
                <FormattedHTMLMessage id="module-integration-viewAllDatasources" />
              }
            />
          </div>
        )}

        {hasManualDataEntry && (
          <div className="test_Integration_Dashboard_manualDataEntryProject">
            <DashboardTile
              type="secondary"
              Icon={ViewAllDataSources}
              onClick={goToManualDataEntryProject}
              // actionConfiguration={integrations.dataSources.dataSourceGroup.view}
              displayName={
                <FormattedHTMLMessage id="module-integration-viewAllManualDataEntryProject" />
              }
            />
          </div>
        )}
      </Flex>
    </>
  );
};

export default compose(
  withImportFileWitoutDataSet,
  withImportFromDatabaseWitoutDataSet,
  withAddIngestionPointWithoutDataSet,
  withManualClueCreation,
)(DashboardTileActions);
