import {
  forwardRef,
  memo,
  FormEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
} from 'react';
import { useTheme } from '@cluedin/theme';

import { useAppSearch } from '../../context/useAppSearch';
import { AppSearchInputElement } from './AppSearchInput.styles';

type Props = {
  id: string;
  width: string;
  placeholder: string;
  handleBlurAppSearchInput: () => void;
};

export const AppSearchInput = memo(
  forwardRef<HTMLInputElement, Props>(
    ({ id, width, placeholder, handleBlurAppSearchInput }, ref) => {
      const theme = useTheme();
      const {
        value,
        handleChangeSearch,
        handleSearch,
        handleCloseSuggestions,
        handleFocus,
      } = useAppSearch();

      const handleChangeScopeInput = useCallback(
        ({
          currentTarget: { value: newValue },
        }: FormEvent<HTMLInputElement>) => {
          handleChangeSearch(newValue);
        },
        [],
      );

      const handleKeyPress = useCallback(
        ({ key }: KeyboardEvent<HTMLInputElement>) => {
          if (key === 'Enter') {
            handleCloseSuggestions();

            handleSearch();

            handleBlurAppSearchInput();
          }
        },
        [value],
      );

      const handleFocusInput = (_: FocusEvent<HTMLInputElement>) =>
        handleFocus();

      return (
        <AppSearchInputElement
          id={id}
          ref={ref}
          value={value}
          width={width}
          theme={theme}
          autoComplete="off"
          placeholder={placeholder}
          onFocus={handleFocusInput}
          onKeyPress={handleKeyPress}
          data-test="main-search-input"
          onChange={handleChangeScopeInput}
        />
      );
    },
  ),
);
