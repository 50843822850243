import { RACI_LEVELS } from '@cluedin/components';

import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'management.deduplicationprojectmanagement': RACI_LEVELS.INFORMED,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"DeduplicationPage" */
          './components/pages/DeduplicationPage'
        ),
      ),
    ),
  },
  {
    path: '/detail/:id/results/:groupId',
    claims: {
      'management.deduplicationprojectmanagement': RACI_LEVELS.INFORMED,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"DeduplicationEntityResultDetailPage" */
          './components/pages/DeduplicationEntityResultDetailPage'
        ),
      ),
    ),
  },
  {
    path: '/detail/:id',
    claims: {
      'management.deduplicationprojectmanagement': RACI_LEVELS.INFORMED,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"DeduplicationDetailPage" */
          './components/pages/DeduplicationDetailPage'
        ),
      ),
    ),
  },
];
