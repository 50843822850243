import React, { Fragment } from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
} from '@cluedin/locale';
import styled from 'styled-components';
import TextPlaceholder from '../../../../../../../core/components/composites/Placeholder/TextPlaceholder';

const Meta = styled.span`
  /* margin-left: auto; */
  font-weight: 100;
  font-size: 11px;
  color: hsla(0, 0%, 50%, 1);
`;

const FilterGroupMeta = (props) => {
  const {
    filteredCount,
    isRemoteFiltering,
    selectedCount,
    totalCount,
    isFetching,
    show,
    currentEntityTypePoolIdentifier,
    currentFilterQuery,
    style,
    intl,
  } = props;

  const availableLabel = intl.formatMessage({ id: 'module-search-available' });
  const foundLabel = intl.formatMessage({ id: 'module-search-found' });

  const total = selectedCount ? (
    <span>
      <strong>
        {isRemoteFiltering // eslint-disable-line no-nested-ternary
          ? totalCount
          : isNaN(totalCount - selectedCount)
          ? '0'
          : `${totalCount - selectedCount}`}
      </strong>{' '}
      {isRemoteFiltering ? null : (
        <Fragment>
          (+{' '}
          <span style={{ fontSize: '12.5px', fontWeight: 'bold' }}>
            {selectedCount}
          </span>{' '}
          <FormattedMessage id="module-search-active" />)
        </Fragment>
      )}
    </span>
  ) : (
    totalCount
  );

  return isFetching ? ( // eslint-disable-line no-nested-ternary
    <Meta style={style}>
      <TextPlaceholder />
    </Meta>
  ) : !show ? ( // eslint-disable-line no-nested-ternary
    <Meta style={style}>
      {currentEntityTypePoolIdentifier}{' '}
      <FormattedMessage id="module-search-only" />
    </Meta>
  ) : currentFilterQuery ? ( // eslint-disable-line no-nested-ternary
    <Meta style={style}>
      {isRemoteFiltering ? (
        <FormattedHTMLMessage
          id="module-search-ShowingOutOfFound"
          values={{
            filteredCount,
            total,
          }}
        />
      ) : (
        <FormattedHTMLMessage
          id="module-search-FoundOutOf"
          values={{
            filteredCount,
            total,
          }}
        />
      )}
    </Meta>
  ) : selectedCount ? (
    <Meta style={style}>
      <strong>
        <span style={{ fontSize: '12.5px' }}>{selectedCount}</span>&nbsp;
        <FormattedHTMLMessage id="module-search-activeFilters" />
      </strong>
    </Meta>
  ) : (
    <Meta style={style}>
      <FormattedHTMLMessage
        id="module-search-noActiveFilters"
        values={{
          totalCount,
          label: isRemoteFiltering ? foundLabel : availableLabel,
        }}
      />
    </Meta>
  );
};

export default injectIntl(FilterGroupMeta);
