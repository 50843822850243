import React from 'react';
import { Checkbox } from 'uxi/Input';
import { FormattedMessage } from '@cluedin/locale';
import { ErrorText } from 'uxi/Text';
import CILink from '../../../core/components/composites/generic/CILink';
import CluedInRegularText from '../../../core/components/composites/generic/CluedInRegularText';
import CluedInExplanationText from '../../../core/components/composites/generic/CluedInExplanationText';

const CheckBoxStyle = {
  width: '100%',
  maxWidth: '470px',
  display: 'block',
};

const tosAcceptMessage = {
  marginTop: '15px',
  width: '100%',
};

const Tos = (props) => {
  const {
    onChange,
    meta: { pristine },
    input: { value },
  } = props;
  return (
    <div>
      <Checkbox
        checked={value}
        style={CheckBoxStyle}
        onChange={(e, checkedOrNot) => {
          onChange(checkedOrNot);
        }}
        className="__test_saveUsernamePassword_tos"
        label={
          <CluedInRegularText>
            <FormattedMessage id="SaveUsernameAndPasswordTostPart1Of3" />
            <CILink
              target="_blank"
              href="https://www.cluedin.com/security"
              title="CluedIn security policy"
            >
              <FormattedMessage id="SaveUsernameAndPasswordTosSecurity" />
            </CILink>
            <FormattedMessage id="SaveUsernameAndPasswordTostPart2Of3" />
            <CILink
              target="_blank"
              href="https://www.cluedin.com/privacy"
              title="CluedIn privacy policy"
            >
              <FormattedMessage id="SaveUsernameAndPasswordTosTosPrivacy" />
            </CILink>
            <FormattedMessage id="SaveUsernameAndPasswordTostPart3Of3" />
            <CILink
              target="_blank"
              href="https://www.cluedin.com/terms"
              title="CluedIn terms of service"
            >
              <FormattedMessage id="SaveUsernameAndPasswordTosTosLabel" />
            </CILink>
            .
          </CluedInRegularText>
        }
      />
      <div>
        {!value && !pristine ? (
          <CluedInExplanationText style={tosAcceptMessage}>
            <ErrorText>
              <FormattedMessage id="module-public-agreeOnTos" />
            </ErrorText>
          </CluedInExplanationText>
        ) : null}
      </div>
    </div>
  );
};

export default Tos;
