import React from 'react';
import { Select } from '@cluedin/form';

export const PropertyTypeNewSelector = ({ value, types, onChange }) => {
  const options = types?.map((i) => ({
    value: i,
    label: i,
  }));

  const handleChange = ({ value }) => onChange?.(value);

  return (
    <Select
      value={value}
      options={options}
      hasError={!value}
      onChange={handleChange}
    />
  );
};
