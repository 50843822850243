import styled from 'styled-components';

const SearchFIltersWrapper = styled.div`
  &,
  & * {
    box-sizing: border-box;
  }
  background: #fff;
  box-shadow: 0 0px 1px 2px rgba(60, 60, 60, 0.09),
    0 1px 4px rgba(60, 60, 60, 0.18);
  padding-top: 8px;
  min-height: calc(100vh - 48px - 32px);
  height: calc(100vh - 48px - 32px);
  max-height: 999999999vh;
  display: flex;
  flex-direction: column;
`;

export default SearchFIltersWrapper;
