import { useEffect } from 'react';
import cogoToast from 'cogo-toast';
import { get } from 'lodash';
import { useMutation, gql } from '@apollo/client';

const GRANT_ACCESS = gql`
  mutation grantUsersPermission($connectorId: ID, $userIds: [ID]) {
    inbound {
      grantUsersPermission(connectorId: $connectorId, userIds: $userIds) {
        id
        users {
          id
        }
      }
    }
  }
`;

export const useGrantUsersPermission = () => {
  const [grantUsersPermission, { loading, error, data }] =
    useMutation(GRANT_ACCESS);

  const grantDone = get(data, 'inbound.grantUsersPermission');

  useEffect(() => {
    if (!loading && grantDone) {
      cogoToast.success(`User has been granted access`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  }, [loading, grantDone]);

  return [
    (connector, userIds) =>
      grantUsersPermission({
        variables: {
          connectorId: connector.id,
          userIds,
        },
      }),
    loading,
    error,
    grantDone,
  ];
};
