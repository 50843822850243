"use strict";

exports.__esModule = true;
exports.useQueryCurrentUserClaims = exports.GET_CLAIMS = void 0;
var _client = require("@apollo/client");
var _get = _interopRequireDefault(require("lodash/get"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var GET_CLAIMS = (0, _client.gql)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query {\n    administration {\n      currentUserClaims {\n        name\n        level\n      }\n    }\n  }\n"])));
exports.GET_CLAIMS = GET_CLAIMS;
var useQueryCurrentUserClaims = function useQueryCurrentUserClaims() {
  var _useQuery = (0, _client.useQuery)(GET_CLAIMS),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  var claims = (0, _get.default)(data, 'administration.currentUserClaims');
  return [claims, loading, error];
};
exports.useQueryCurrentUserClaims = useQueryCurrentUserClaims;