import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { createCleanProjectMutation } from '../../data/gql';

export const withCreateAddProject = (Comp) => (props) =>
  (
    <Mutation mutation={createCleanProjectMutation}>
      {(createCleanProject, { loading, error, data = {} }) => {
        // here with the ID => we redirect to /clean/project/:id
        const project =
          data && data.preparation && data.preparation.createCleanProject
            ? data.preparation.createCleanProject
            : {};

        return (
          <Comp
            {...props}
            loading={loading}
            errorCreating={error}
            projectId={project.id}
            createCleanProject={(cleanProject) => {
              const fieldNamesFilters = (cleanProject.filters || []).filter(
                (f) => !!f.fieldName,
              );

              const filters = cleanProject.provider
                ? [
                    {
                      aggregationName: 'entityType',
                      values: [cleanProject.entityType],
                      operator: 'OR',
                    },
                    {
                      aggregationName: 'providers',
                      values: [cleanProject.provider],
                      operator: 'OR',
                    },
                    ...(fieldNamesFilters || []),
                  ]
                : [...fieldNamesFilters];

              createCleanProject({
                variables: {
                  load: true,
                  project: {
                    name: cleanProject.name,
                    sourceType: 'gql',
                    annotationProjectId: cleanProject.annotationId,
                    sourceParameters: [
                      {
                        key: 'entityType',
                        value: cleanProject.entityType,
                      },
                      {
                        key: 'query',
                        value: cleanProject.query || '*',
                      },
                      {
                        key: 'filters',
                        value: [...filters] || [],
                      },
                    ],
                    authors: [props.currentUser.Id],
                  },
                },
              });
            }}
          />
        );
      }}
    </Mutation>
  );

withCreateAddProject.displayName = 'withCreateAddProject';

export default {
  withCreateAddProject,
};
