import { FormattedMessage } from '@cluedin/locale';
import { Toggle } from '@cluedin/form';

export const RecordListSaveSearchFormFields = [
  {
    tabindex: 1,
    name: 'name',
    isRequired: true,
    autoComplete: 'off',
    displayName: (
      <FormattedMessage id="module-goldenRecordList-nameFiltersNameLabel" />
    ),
  },
  {
    name: 'shared',
    tabindex: 2,
    Input: ({ value, onChange }) => (
      <>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            paddingBottom: '8px',
          }}
        >
          <span style={{ fontSize: 14, fontWeight: 600 }}>
            <FormattedMessage id="module-goldenRecordList-save-search-shared" />
          </span>
          <Toggle checked={value} onChange={(_, v) => onChange(v)} />
        </div>

        <span style={{ fontSize: 12, color: '#ccc' }}>
          <FormattedMessage id="module-goldenRecordList-save-search-shared-label" />
        </span>
      </>
    ),
  },
];
