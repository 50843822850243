import { injectIntl } from '@cluedin/locale';
import { Link } from 'react-router-dom';
import { useId } from '@fluentui/react-hooks';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { HighlightTextFromContext } from '@cluedin/components';
import { useTheme } from '@cluedin/theme';
import { Externallink } from 'uxi/Icons';

import WarningImage from '../../../core/components/composites/WarningImage';
import VocabularyFullKey from './VocabularyFullKey';

const VocabKeyItemLinkForList = ({
  intl,
  vocabKey,
  displayName,
  externalLink,
  ...r
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const tooltipId = useId(vocabKey);

  const isObsolete = r?.isObsolete;
  const mappedKey = r?.mappedKey;

  const getTo = (key = vocabKey) =>
    `/admin/management/catalog/vocab-key?key=${encodeURIComponent(key)}`;

  const link = externalLink ? (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to={getTo()}
      style={{ color: themePrimary }}
    >
      <span style={{ marginRight: '6px' }}>
        {displayName && <HighlightTextFromContext text={displayName} />}
      </span>
      <Externallink size={10} />
    </Link>
  ) : (
    <Link
      style={{ color: themePrimary }}
      to={getTo()}
      rel="noopener noreferrer"
    >
      {displayName && <HighlightTextFromContext text={displayName} />}
    </Link>
  );

  return (
    <div style={{ width: '100%' }}>
      {isObsolete && (
        <div style={{ height: 14 }}>
          <TooltipHost
            id={tooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={
              <>
                {intl.formatMessage({
                  id: mappedKey
                    ? 'module-dataCatalog-key-is-obsolete'
                    : 'module-dataCatalog-key-is-obsolete-plain',
                })}

                {mappedKey && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={getTo(mappedKey?.Key)}
                    style={{ whiteSpace: 'nowrap', color: themePrimary }}
                  >
                    <span style={{ marginRight: 5 }}>{mappedKey?.Key}</span>

                    <Externallink size={8} />
                  </Link>
                )}
              </>
            }
          >
            <WarningImage size={14} />
          </TooltipHost>
        </div>
      )}

      <div>
        {link}
        <VocabularyFullKey vocabKey={vocabKey} />
      </div>
    </div>
  );
};

export default injectIntl(VocabKeyItemLinkForList);
