import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Checkmark } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';

const NewCluedInNotification = ({ index, notification, onCloseClick }) => {
  const title = notification.title;
  const message = <div>{notification.message}</div>;

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={Checkmark}
    />
  );
};

const EnhancedNewCluedInNotification = compose(
  lifecycle({
    componentDidMount() {
      const { notification } = this.props;
      const message = notification.message;
      const title = notification.title;

      notify(title, { body: message });
    },
  }),
)(NewCluedInNotification);

export default EnhancedNewCluedInNotification;
