import constants from '../constants';
import { location } from '../config';

export function goToLocation(locationURL, param) {
  if (locationURL === 'clientIdSignIn') {
    window.location = location.goToClientIdSignIn(param);
  }
  return {
    type: constants.core.GOTOLOCATION,
  };
}

export function updateAuthToken(authInfo) {
  return (dispatch) =>
    dispatch({
      type: constants.core.UPDATE_TOKEN,
      data: {
        token: authInfo.access_token,
      },
    });
}

export function resetRealTimeNotify(notification) {
  return (dispatch) =>
    dispatch({
      type: constants.core.RESET_REALTIME_NOTIFICATION,
      data: {
        notification,
      },
    });
}

export function receiveRealTimeNotify(notification) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetRealTimeNotify(notification));
    }, 10000);

    return dispatch({
      type: constants.core.REQUEST_REALTIME_NOTIFICATION,
      data: {
        notification,
      },
    });
  };
}
