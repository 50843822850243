"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var Tr = _styledComponents.default.tr(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  position: relative;\n  &:not(:last-child) {\n    border-bottom: 1px solid hsla(0, 0%, 88%, 1);\n  }\n  background: ", ";\n  &:nth-child(2n) {\n    background: ", ";\n  }\n  &:nth-child(1n) {\n    ", ";\n\n    &:hover {\n      background: ", ";\n    }\n  }\n"])), function (_ref) {
  var disabled = _ref.disabled,
    palette = _ref.theme.palette;
  return !disabled ? palette.white : palette.neutralLighterAlt;
}, function (_ref2) {
  var disabled = _ref2.disabled,
    palette = _ref2.theme.palette;
  return !disabled && palette.neutralLighterAlt;
}, function (_ref3) {
  var selected = _ref3.selected,
    disabled = _ref3.disabled,
    palette = _ref3.theme.palette;
  return !disabled && (selected ? palette.themeLighterAlt : '');
}, function (_ref4) {
  var disabled = _ref4.disabled,
    palette = _ref4.theme.palette;
  return !disabled && palette.themeLighterAlt;
});
Tr.displayName = 'Tr';
var _default = Tr;
exports.default = _default;