import React from 'react';
import DataGrid from 'uxi/DataGrid';
import moment from 'moment';

const RequestType = ({ value }) => {
  if (value === 'rightToAccess') {
    return 'Right to access request';
  }
  if (value === 'rightToErasure') {
    return 'Right to access request';
  }

  return value;
};

const CreatedDate = ({ value }) => <span>{moment(value).fromNow()}</span>;

const CurrentUserRequestList = ({ currentUserRequests }) => (
  <DataGrid
    data={currentUserRequests}
    properties={[
      {
        property: 'type',
        displayName: 'Request Type',
        Component: RequestType,
      },
      {
        property: 'created',
        displayName: 'Creation Date',
        Component: CreatedDate,
      },
    ]}
  />
);

export default CurrentUserRequestList;
