import styled from 'styled-components';

export const PanelDetailImage = styled.img<{
  isImageBorderRound: boolean;
}>`
  align-items: center;
  clip-path: ${({ isImageBorderRound }) =>
    !isImageBorderRound && 'circle(50%)'};
  display: flex;
  height: 82px;
  max-height: 82px;
  min-height: 82px;
  justify-content: center;
  width: 82px;
  max-width: 82px;
  min-width: 82px;
`;
