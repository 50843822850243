import React from 'react';
import { ColWrapper } from './Col.styles';

const Col = ({ size, mobileSize, children }) => {
  let className = 'cluedIn_col';

  if (size) {
    className += ` m${size}`;
  }

  if (mobileSize) {
    className += ` s${mobileSize}`;
  }

  return <ColWrapper className={className}>{children}</ColWrapper>;
};

export default Col;
