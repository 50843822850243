import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';

const GET_PAGE_TEMPLATE_BY_ID = gql`
  query getPageTemplateById($id: ID) {
    virtualization {
      getPageTemplateById(id: $id) {
        pageTemplateId
        name
        displayName
        layoutTemplateId
        vocabularyKeysCore
        vocabularyKeysNonCore
        suggestedSearchEnabled
        metricsEnabled
        createdDate
        modifiedDate
        createdBy
        modifiedBy
        layoutTemplate {
          Id
          Name
          DisplayName
          Description
          GridConfiguration
          Icon
          OrganizationId
          Order
          CreatedDate
          ModifiedDate
          CreatedBy
          ModifiedBy
        }
        isReadOnly
        widgets
        includeSuggestedSearches
        includeMetrics
      }
    }
  }
`;

export const useGetPageTemplateById = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_PAGE_TEMPLATE_BY_ID, {
    variables: {
      id,
    },
  });

  const response = get(data, 'virtualization.getPageTemplateById');

  return [response, loading, error, refetch];
};
