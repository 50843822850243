import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import {
  pulseEasing,
  pulseDuration,
  pulseKeyframesConfig,
} from './pulseKeyFrames';

export const pulseName = keyframes`
  ${Object.keys(pulseKeyframesConfig).map(
    (key) => `${key} { opacity: ${pulseKeyframesConfig[key]};}`,
  )};
`;

const TextPlaceholderUI = styled.div`
  background: ${({ color }) => color};
  opacity: 0.32;
  animation: ${pulseName} ${pulseDuration} ${pulseEasing} infinite;
  ${({ height }) => (height ? `${height}px` : '')};
  ${({ tiny }) =>
    tiny &&
    `
    line-height: 1;
    margin: .2em 0;
  `};
  ${({ centered }) =>
    centered ? 'margin-left :auto; margin-right: auto' : ''};
`;

const RelativeTextPlaceholder = ({
  style,
  color,
  tiny = false,
  centered = false,
}) => (
  <TextPlaceholderUI
    style={style}
    color={color}
    tiny={tiny}
    centered={centered}
  >
    &nbsp;
  </TextPlaceholderUI>
);
RelativeTextPlaceholder.defaultProps = {
  style: {},
  color: 'rgb(47, 47, 50)',
  tiny: false,
};

RelativeTextPlaceholder.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  tiny: PropTypes.bool,
};

RelativeTextPlaceholder.displayName = 'RelativeTextPlaceholder';

export default RelativeTextPlaceholder;
