"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(InputBase) {
  return (0, _styledComponents.default)(InputBase)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n", "\ncolor: ", ";\nline-height: 1.1876em; // Reset (19px), match the native input line-height\nbox-sizing: border-box; // Prevent padding issue with fullWidth.\nposition: relative;\ncursor: text;\ndisplay: inline-flex;\nalign-items: center;\n&.disabled {\n  color: ", ";\n  cursor: default;\n}\n&.fullWidth {\n  width: 100%;\n}\n& input[type=search] {\n  -moz-appearance: textfield;\n  -webkit-appearance: textfield;\n}\n& input, select {\n  font: inherit;\n  letter-spacing: inherit;\n  color: currentColor;\n  // padding: ", "px 0 ", "px;\n  padding: 0; //reset padding\n  border: 0;\n  box-sizing: content-box;\n  background: none;\n  height: 1.1876em; // Reset (19px), match the native input line-height\n  margin: 0; // Reset for Safari\n  -webkit-tap-highlight-color: transparent;\n  display: block;\n  // Make the flex item shrink with Firefox\n  min-width: 0;\n  width: 100%; // Fix IE 11 width issue\n  // animation-name: component-library-auto-fill-cancel;\n  // animation-duration: 10ms;\n  &::-webkit-input-placeholder: placeholder;\n  &::-moz-placeholder: placeholder; // Firefox 19+\n  &:-ms-input-placeholder: placeholder; // IE 11\n  &::-ms-input-placeholder: placeholder; // Edge\n  &:focus: {\n    outline: 0;\n  }\n  // Reset Firefox invalid required input style\n  &:invalid: {\n    box-shadow: none;\n  },\n  &::-webkit-search-decoration: {\n    // Remove the padding when type=search.\n    -webkit-appearance: none;\n  }\n  &.disabled: {\n    opacity: 1; // Reset iOS opacity\n  }\n  &:-webkit-autofill {\n    animation-duration: 5000s;\n    animation-name: component-library-auto-fill;\n  }\n}\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.typography.body1;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.text.primary;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.text.disabled;
  }, 8 - 2, 8 - 1);
};
/**
 * // Show and hide the placeholder logic
 * 
 * 
  'label[data-shrink=false] + $formControl &': {
    '&::-webkit-input-placeholder': placeholderHidden,
    '&::-moz-placeholder': placeholderHidden, // Firefox 19+
    '&:-ms-input-placeholder': placeholderHidden, // IE 11
    '&::-ms-input-placeholder': placeholderHidden, // Edge
    '&:focus::-webkit-input-placeholder': placeholderVisible,
    '&:focus::-moz-placeholder': placeholderVisible, // Firefox 19+
    '&:focus:-ms-input-placeholder': placeholderVisible, // IE 11
    '&:focus::-ms-input-placeholder': placeholderVisible, // Edge
  },
  auto-fill
  https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
 */
exports.default = _default;