import { memo } from 'react';
import { useIntl } from 'react-intl';
import {
  FormDecorator,
  GqlErrorMessages,
  Select,
  TextField,
} from '@cluedin/form';

import EntityTypeAvatar from '../../../../../../entityTypes/components/containers/EntityTypeAvatar';
import { useGoldenRecordList } from '../../../GoldenRecordListContext';
import { useSearchAggregation } from './useSearchAggregation';
import {
  RecordListFiltersInner,
  RecordListFiltersWrapper,
} from './RecordListFilters.styles';
import IntegrationIconContainer from '../../../../../../integration/components/containers/IntegrationIconContainer';
import { useAppSearch } from '../../../../../../AppSearch';
import { AppSearchScopes } from '../../../../../../AppSearch/AppSearch.types';
import { useGetConnectorInfoByProviderDefinitionIds } from '../../../../../hooks/useGetConnectorInfoByProviderDefinitionIds';
import { url } from '../../../../../../../config';
import { RuleItemEnumerableSelector } from '../../../../../../rule/components/containers/ruleCondition/RuleItemEnumerableSelector';
import { optionLabelsWithCountFormatter } from '../../../../../../rule/utils/optionLabelsWithCountFormatter';

const propertyRuleObjectTypeId = '0a1984a6-7b75-47a9-b076-8f6b45b358ac';

const getIcon = (type, by) => {
  if (type === 'entityType') {
    return (
      <EntityTypeAvatar
        entityType={by}
        AvatarWithNameSize={14}
        style={{ width: '12px' }}
      />
    );
  }

  if (type === 'providers') {
    return <IntegrationIconContainer integrationName={by} iconSize="xs" />;
  }

  return null;
};

const getFiltersModel = (
  filtersConfig = {},
  entityTypesConfigurations = [],
  filtersToHide = [],
  connectorsInfo,
) => {
  const filtersData = {};

  Object.entries(filtersConfig).forEach((i) => {
    const filterName = i[0];
    const filterValue = i[1]?.Items;

    if (
      !filterValue ||
      filterValue?.length === 0 ||
      filtersToHide.includes(filterName)
    ) {
      return;
    }

    filtersData[filterName] = filterValue?.map((filterConfigItem) => {
      const value = filterConfigItem?.Name;

      const itemConnectorsInfo = connectorsInfo?.find((i) => i.id === value);

      const filterConfigData = entityTypesConfigurations.find(
        (filterConfigDataItem) => filterConfigDataItem.entityType === value,
      );

      const icon =
        filterName !== 'providerDefinitionIds_v2' ? (
          getIcon(filterName, value)
        ) : (
          <img
            style={{
              width: '18px',
              height: '18px',
            }}
            src={`${url.api}${itemConnectorsInfo?.icon}`}
          />
        );

      return {
        icon,
        value,
        count: filterConfigItem?.Count,
        label:
          filterName === 'providerDefinitionIds_v2'
            ? itemConnectorsInfo?.name || value
            : filterConfigData?.displayName || value,
      };
    });
  });

  return filtersData;
};

export const RecordListFilters = memo(() => {
  const intl = useIntl();

  const { handleChangeScope } = useAppSearch();
  const {
    filtersToHide,
    loadingEntityTypes,
    appliedSavedSearch,
    entityTypesConfigurations,
    listOptions: { filters, setFilters, total },
    filterOptions: {
      appliedSavedSearchName,
      changeAppliedSearchName,
      appliedSearchNameValidationError,
    },
  } = useGoldenRecordList();

  const { filtersConfig, loadingFiltersConfig, loadingFiltersConfigError } =
    useSearchAggregation();

  const providerDefinitionIds =
    filtersConfig?.providerDefinitionIds_v2?.Items?.map((i) => i?.Name);

  const {
    data: connectorsInfo,
    loading: loadingConnectorInfoByProviderDefinitionIds,
    error: loadingConnectorInfoByProviderDefinitionIdsError,
  } = useGetConnectorInfoByProviderDefinitionIds({
    ids: providerDefinitionIds,
  });

  if (
    loadingFiltersConfigError ||
    loadingConnectorInfoByProviderDefinitionIdsError
  ) {
    return <GqlErrorMessages />;
  }

  const filtersModel = getFiltersModel(
    filtersConfig,
    entityTypesConfigurations,
    [
      ...filtersToHide,
      'author_name',
      'lastchangedby_name',
      'extension',
      'providers',
      'providerDefinitionIds_v2',
    ],
    connectorsInfo,
  );

  const handleChangeFilter = (name, value) => {
    const firstValue = value?.[0]?.value;

    // Todo: needs fixing to STORE 'displayName' and render icon
    const newValue = {
      ...filters,
      [name]:
        value?.length > 0
          ? value?.map((i) => (typeof i === 'string' ? i : i?.value))
          : null,
    };

    if (name !== 'entityType') {
      return setFilters(newValue);
    }

    if (
      name === 'entityType' &&
      value.length === 1 &&
      (!filters.entityType || filters.entityType?.length === 0) &&
      (firstValue?.toLowerCase()?.replaceAll('/', '') ===
        AppSearchScopes.organization ||
        firstValue?.toLowerCase()?.replaceAll('/', '') ===
          AppSearchScopes.person)
    ) {
      handleChangeScope(firstValue?.toLowerCase()?.replace('/', ''));

      return setFilters(newValue);
    }

    handleChangeScope(AppSearchScopes.all);

    setTimeout(() => {
      setFilters(newValue);
    }, 0);
  };

  const filtersModelKeys = Object.keys(filtersModel);

  return (
    <RecordListFiltersWrapper className="RecordListFiltersWrapper_1">
      {appliedSavedSearch && (
        <FormDecorator
          errorText={appliedSearchNameValidationError}
          style={{ padding: '10px 0', width: '40%' }}
          label={intl.formatMessage({
            id: `module-goldenRecordList-nameFiltersNameLabel`,
          })}
        >
          <TextField
            value={appliedSavedSearchName}
            onChange={(_, v) => changeAppliedSearchName(v)}
          />
        </FormDecorator>
      )}

      <RecordListFiltersInner className="RecordListFiltersInner">
        {filtersModelKeys?.map((filterItem) => {
          const i = filtersModel[filterItem];

          const value = filters?.[filterItem]?.map?.((listFilter, idx) => {
            const valueModelFromFiltersModel = i.find(
              (i) => i.value === listFilter,
            );

            return {
              value: listFilter,
              label: valueModelFromFiltersModel?.label || listFilter,
              icon: valueModelFromFiltersModel?.icon,
            };
          });

          const optionsWithWidth = i.map((option) => ({
            ...option,
            width: 250,
          }));

          return (
            <FormDecorator
              style={{ minWidth: 250, maxWidth: 250, width: 250 }}
              label={intl.formatMessage({
                id: `module-goldenRecordList-list-filter-${filterItem}`,
              })}
            >
              <div data-test={`search-filter-${filterItem}`}>
                <Select
                  isMulti
                  formatOptionLabel={optionLabelsWithCountFormatter}
                  options={optionsWithWidth}
                  isClearable
                  value={value}
                  onChange={(v) => {
                    handleChangeFilter(filterItem, v);
                  }}
                  isLoading={
                    loadingEntityTypes ||
                    loadingFiltersConfig ||
                    loadingConnectorInfoByProviderDefinitionIds
                  }
                  placeholder={intl.formatMessage({
                    id: 'module-goldenRecordList-list-filters-all',
                  })}
                />
              </div>
            </FormDecorator>
          );
        })}
        <FormDecorator
          style={{ minWidth: 250, maxWidth: 250, width: 250 }}
          label={intl.formatMessage({
            id: `module-goldenRecordList-list-filter-providers`,
          })}
        >
          <div
            data-test={`search-filter-providers`}
            data-rule-item-parent={propertyRuleObjectTypeId}
          >
            <RuleItemEnumerableSelector
              isMulti
              isCreatable={false}
              property="Providers"
              objectTypeId={propertyRuleObjectTypeId}
              parentId={propertyRuleObjectTypeId}
              value={filters?.providers}
              onChange={(v) => {
                handleChangeFilter('providers', v);
              }}
              placeholder={intl.formatMessage({
                id: 'module-goldenRecordList-list-filters-all',
              })}
            />
          </div>
        </FormDecorator>
        <FormDecorator
          style={{ minWidth: 250, maxWidth: 250, width: 250 }}
          label={intl.formatMessage({
            id: `module-goldenRecordList-list-filter-providerDefinitionIds_v2`,
          })}
        >
          <div
            data-test={`search-filter-providerDefinitionIds_v2`}
            data-rule-item-parent={propertyRuleObjectTypeId}
          >
            <RuleItemEnumerableSelector
              isMulti
              isCreatable={false}
              property="ProviderDefinitionIds"
              objectTypeId={propertyRuleObjectTypeId}
              parentId={propertyRuleObjectTypeId}
              value={filters?.providerDefinitionIds_v2}
              onChange={(v) => {
                handleChangeFilter('providerDefinitionIds_v2', v);
              }}
              placeholder={intl.formatMessage({
                id: 'module-goldenRecordList-list-filters-all',
              })}
            />
          </div>
        </FormDecorator>
      </RecordListFiltersInner>

      <span style={{ fontSize: 14, fontWeight: 600 }}>
        {intl.formatMessage({
          id: 'module-goldenRecordList-found-results',
        })}
        : {total}
      </span>
    </RecordListFiltersWrapper>
  );
});
