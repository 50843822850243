"use strict";

exports.__esModule = true;
exports.default = void 0;
var _client = require("@apollo/client");
var _ws = require("@apollo/client/link/ws");
var _utilities = require("@apollo/client/utilities");
var _token = require("../token");
var _env = require("../env");
var _authLink = _interopRequireDefault(require("./authLink"));
var _errorLink = _interopRequireDefault(require("./errorLink"));
var _cleanTypeNameLink = _interopRequireDefault(require("./cleanTypeNameLink"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = function _default(_ref) {
  var on401 = _ref.on401,
    legacyGraphQl = _ref.legacyGraphQl;
  var legacyLink = new _client.HttpLink({
    uri: legacyGraphQl ? legacyGraphQl : _env.graphQL
  });
  var httpLegacyLinkWithaddons = _client.ApolloLink.from([_cleanTypeNameLink.default, (0, _errorLink.default)({
    on401: on401
  }), _authLink.default, legacyLink]);
  var legacyClient = new _client.ApolloClient({
    link: httpLegacyLinkWithaddons,
    cache: new _client.InMemoryCache(),
    headers: {
      Authorization: "Bearer " + (0, _token.getCurrentToken)()
    }
  });
  var httpLink = new _client.HttpLink({
    uri: _env.mainGraphQLUrl
  });
  var httpLinkWithCleanTypeName = _client.ApolloLink.from([_cleanTypeNameLink.default, (0, _errorLink.default)({
    on401: on401
  }), _authLink.default, httpLink]);
  var splitLink;
  if (_env.mainGraphQLSocket) {
    var wsLink = new _ws.WebSocketLink({
      uri: _env.mainGraphQLSocket,
      options: {
        reconnect: true,
        connectionParams: function connectionParams() {
          return {
            authToken: "Bearer " + (0, _token.getCurrentToken)()
          };
        }
      }
    });
    var wsLinkWihtMiddleware = _client.ApolloLink.from([_cleanTypeNameLink.default, _authLink.default, wsLink]);
    splitLink = (0, _client.split)(function (_ref2) {
      var query = _ref2.query;
      var definition = (0, _utilities.getMainDefinition)(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    }, wsLinkWihtMiddleware, httpLinkWithCleanTypeName);
  }
  var authenticatedClient = new _client.ApolloClient({
    link: splitLink || httpLinkWithCleanTypeName,
    connectToDevTools: true,
    cache: new _client.InMemoryCache()
    //{ addTypename: false }
  });

  return {
    legacyClient: legacyClient,
    authenticatedClient: authenticatedClient
  };
};
exports.default = _default;