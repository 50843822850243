import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from '@cluedin/locale';
import InsightList from '../../composites/InsightList';
import ViewEntityChanges from '../../composites/ViewEntityChanges';
import { unFollow as shouldUnFollow } from '../../../../entityActions/actions';
import { shouldResetFollowingCount } from '../../../actions';
import {
  closeNotificationCenter, // this is actually a toggle...
} from '../../../../notifications/actions';
import SearchBar from '../../composites/SearchBar';
import EntityFollowingEmptyState from '../../composites/EntityFollowingEmptyState';

const FollowingEntitiesContentStyle = {
  filterWrapper: {
    display: 'flex',
    padding: '15px',
    borderBottom: '1px solid #ccc',
  },
};

class FollowingEntitiesContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      viewType: '',
      selectedInsight: null,
    };
    this.onBackHandler = this.onBackHandler.bind(this);
    this.onRemovInsightClickHandler =
      this.onRemovInsightClickHandler.bind(this);
    this.closeAndResetEntity = this.closeAndResetEntity.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.viewChanges = this.viewChanges.bind(this);
  }

  componentWillUnmount() {
    this.setState({
      searchValue: '',
    });
  }

  onBackHandler() {
    this.setState({
      viewType: '',
      selectedInsight: '',
    });
  }

  onRemovInsightClickHandler(e) {
    const { unFollow } = this.props;
    unFollow(e.Entity.EntityId);
  }

  viewChanges(insight) {
    this.setState({
      selectedInsight: insight,
      viewType: 'detail',
    });
  }

  closeAndResetEntity(insight) {
    const { close, resetInsight } = this.props;

    if (close) {
      close();
    }

    this.setState({
      searchValue: '',
    });
    resetInsight(insight);
  }

  handleSearchChange(value) {
    this.setState({
      searchValue: value,
    });
  }

  render() {
    const { insights, intl } = this.props;
    const { searchValue, viewType, selectedInsight } = this.state;
    let content;

    if (viewType === 'detail' && selectedInsight) {
      content = (
        <div>
          <ViewEntityChanges
            onBack={this.onBackHandler}
            viewEntity={this.closeAndResetEntity}
            insight={selectedInsight}
          />
        </div>
      );
    } else if (insights && insights.length > 0) {
      let filteredInsights = insights;

      if (searchValue) {
        filteredInsights = (filteredInsights || []).filter((i) =>
          i.Entity.EntityName.match(new RegExp(searchValue, 'i')),
        );
      }

      content = (
        <div>
          <div
            className="test_NotificationCenter_FollowedEntities_Search"
            style={FollowingEntitiesContentStyle.filterWrapper}
          >
            <SearchBar
              searchValue={searchValue}
              onInputChange={this.handleSearchChange}
              placeholder={intl.formatMessage({
                id: 'module-stream-searchForEntities',
              })}
            />
          </div>
          <div>
            <InsightList
              onInsightClick={this.closeAndResetEntity}
              viewChanges={this.viewChanges}
              onRemovInsightClick={this.onRemovInsightClickHandler}
              insights={filteredInsights}
            />
          </div>
        </div>
      );
    } else {
      content = <EntityFollowingEmptyState />;
    }

    return <div style={{ textAlign: 'left' }}>{content}</div>;
  }
}

const mapToStateProps = ({ stream: { followedEntities } }) => {
  return {
    insights: followedEntities || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  unFollow(entityId) {
    dispatch(shouldUnFollow({ id: entityId }));
  },
  resetInsight(insight) {
    dispatch(shouldResetFollowingCount(insight.Entity.EntityId));
  },
  close() {
    dispatch(closeNotificationCenter());
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(injectIntl(FollowingEntitiesContent));
