import { useContext, useState, useEffect, useCallback } from 'react';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { useId } from '@fluentui/react-hooks';
import { Error } from 'uxi/Icons';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import styled, { keyframes } from 'styled-components';
import { withWorkflow } from '@cluedin/form';
import { UploadFileProgressContext } from './UploadFileProgressContext';
import { FileUploadProgress } from './FileUploadProgress';
import { UploadContext } from '../../../types';
import { UploadFileProgressCallout } from './UploadFileProgressCallout';

const getAnim = () => {
  const bollox = keyframes`
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    } 
  `;
  return bollox;
};
const IconWrapperRotation = styled.div`
  svg {
    vertical-align: middle;
    margin-left: 10px;
    animation: ${() => getAnim()} 1.5s infinite linear;
    transform-origin: center;
  }
`;

const iconClass = mergeStyles({
  fontSize: 20,
  height: 24,
  width: 24,
  color: 'white',
});

const IconWrapper = styled.div`
  svg {
    vertical-align: middle;
    margin-top: -16px;
  }
`;

const TextWrapper = styled.div`
  color: #fff;
`;

const UploadFileProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  height: 100%;
  border: none;
  min-width: 0;
  padding: 0 6px;
  border-radius: 0;
  box-shadow: none !important;
  &:hover {
    background: ${({ theme }) => theme.palette.themeDarkAlt};
  }
`;

type UploadFileProgressProps = {
  openWorkflow: () => void;
  fileUploadProgressContext: UploadContext;
};

const UploadFileProgressComposite = withWorkflow(
  ({ openWorkflow, fileUploadProgressContext }: UploadFileProgressProps) => {
    const theme = useTheme();
    const [isCalloutVisible, setCallOutVisible] = useState(false);
    const buttonId = useId('callout-button');

    const completedItems = fileUploadProgressContext?.completedItems || [];
    const errorFiles = fileUploadProgressContext?.errorFiles || [];
    const allFinished = fileUploadProgressContext?.allFinished;

    const hasError = errorFiles?.length > 0;
    const hasMultipleFiles = completedItems.length > 1;
    const hasMultipleError = errorFiles.length > 1;

    useEffect(() => {
      if (allFinished) {
        setCallOutVisible(true);
      }
    }, [allFinished]);

    if (
      !fileUploadProgressContext?.fileUploadProgress ||
      fileUploadProgressContext?.fileUploadProgress?.length === 0
    ) {
      return null;
    }

    return (
      <>
        <UploadFileProgressWrapper
          theme={theme}
          onClick={() => {
            setCallOutVisible(false);
            const idAsView = completedItems.map(
              (f) => f.fileUploadRequest.fileId,
            );
            fileUploadProgressContext?.markAsViewed?.(idAsView);
            openWorkflow();
          }}
        >
          {!hasError && allFinished && (
            <IconWrapper>
              <FontIcon
                className={iconClass}
                aria-label="Success"
                iconName="CheckMark"
              />
            </IconWrapper>
          )}
          {hasError && (
            <Error
              style={{
                background: theme.palette.red,
                borderRadius: '18px',
                marginRight: '6px',
              }}
              color="#fff"
              size={18}
            />
          )}

          {!allFinished && (
            <IconWrapperRotation>
              <FontIcon
                className={iconClass}
                aria-label="Rotate"
                iconName="Rotate"
              />
            </IconWrapperRotation>
          )}
          <TextWrapper id={buttonId}>
            {!hasError && (
              <span style={{ marginRight: '4px' }}>
                {fileUploadProgressContext?.fileUploadProgress?.length}
              </span>
            )}
            {allFinished &&
              !hasError &&
              (hasMultipleFiles ? (
                <FormattedMessage id="module-uploadFile-uploadedFiles" />
              ) : (
                <FormattedMessage id="module-uploadFile-uploadedFile" />
              ))}
            {!allFinished &&
              !hasError &&
              (hasMultipleFiles ? (
                <FormattedMessage id="module-uploadFile-uploadingFiles" />
              ) : (
                <FormattedMessage id="module-uploadFile-uploadingFile" />
              ))}
            {allFinished &&
              hasError &&
              (hasMultipleError ? (
                <FormattedMessage id="module-uploadFile-uploadingFilesError" />
              ) : (
                <FormattedMessage id="module-uploadFile-uploadingFileError" />
              ))}
          </TextWrapper>
          {isCalloutVisible && (
            <UploadFileProgressCallout
              completedItems={completedItems}
              hasMultipleFiles={hasMultipleFiles}
              completedNumberFiles={completedItems.length}
              numberOfError={errorFiles.length}
              buttonId={buttonId}
              onClose={() => {
                setCallOutVisible(false);
                const idAsView = completedItems.map(
                  (f) => f.fileUploadRequest.fileId,
                );
                fileUploadProgressContext?.markAsViewed?.(idAsView);
              }}
              openWorkflow={openWorkflow}
            />
          )}
        </UploadFileProgressWrapper>
      </>
    );
  },
  FileUploadProgress,
  {
    title: <FormattedMessage id="module-uploadFile-uploadFileTitle" />,
  },
);

export const UploadFileProgress = () => {
  const fileUploadProgressContext = useContext(UploadFileProgressContext);
  const allFinished = fileUploadProgressContext?.allFinished;

  const closeExtra = useCallback(() => {
    if (allFinished) {
      fileUploadProgressContext?.resetFiles?.();
    }
  }, [allFinished]);

  return (
    <>
      <UploadFileProgressComposite
        onCloseExtra={closeExtra}
        fileUploadProgressContext={fileUploadProgressContext}
      />
    </>
  );
};
