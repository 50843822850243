import React from 'react';
import PrivacyFeaturesContainer from '../containers/PrivacyFeaturesContainer';

const PrivacyPage = () => (
  <div>
    <PrivacyFeaturesContainer />
  </div>
);

export default PrivacyPage;
