import React from 'react';
import { PageStructureContainer } from '../../../core/components/composites/PageStructure';
import IntegrationHeader from '../composites/IntegrationHeader';

const IntegrationPageWrapper = ({ children, integration }) => {
  return (
    <div>
      <PageStructureContainer>
        <IntegrationHeader integration={integration} />
        <div
          style={{
            background: '#fff',
            height: 'calc(100vh - 148px)',
          }}
        >
          {children}
        </div>
      </PageStructureContainer>
    </div>
  );
};

export default IntegrationPageWrapper;
