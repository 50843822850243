import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';
import { FormDecorator } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';

import { useGetAcceptsUpdate } from '../../../../hooks/getAcceptsUpdate';
import { Step1RadioWrapper } from './EntityPropertiesStep1Options.styles';

const radioStyles = { alignSelf: 'flex-start', padding: 0 };

const EntityPropertiesStep1Options = ({ editMode, setEditMode, entityId }) => {
  const [{ data, loading, error }] = useGetAcceptsUpdate(entityId);
  const theme = useTheme();

  const onEditChange = useCallback((type) => {
    setEditMode(type);
  });

  return (
    <FormDecorator
      label={
        <FormattedMessage id="module-entityProperties-editPanelFirstStep" />
      }
      style={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      }}
    >
      <Step1RadioWrapper
        theme={theme}
        data-test={'entityProperties-editPanel-manualDirectEditRadio'}
      >
        <Radio
          styles={radioStyles}
          name="manualDirectEdit"
          checked={editMode === 'manual-directEdit'}
          onChange={() => onEditChange('manual-directEdit')}
          label={
            <FormattedMessage id="module-entityProperties-editPanelManualDirectEditRadioLabel" />
          }
          helpText={
            <FormattedMessage id="module-entityProperties-editPanelManualDirectEditRadioHelpText" />
          }
        />
      </Step1RadioWrapper>

      {data?.response && (
        <>
          <Step1RadioWrapper
            data-test={
              'entityProperties-editPanel-onlyGeneratedMeshMessagesRadio'
            }
          >
            <Radio
              styles={radioStyles}
              name="mesh"
              checked={editMode === 'mesh'}
              onChange={() => onEditChange('mesh')}
              label={
                <FormattedMessage id="module-entityProperties-editPanelMeshRadioLabel" />
              }
              helpText={
                <FormattedMessage id="module-entityProperties-editPanelMeshRadioHelpText" />
              }
              disabled={!data?.response}
            />
          </Step1RadioWrapper>

          <Step1RadioWrapper
            data-test={'entityProperties-editPanel-manualEditMeshRadio'}
          >
            <Radio
              styles={radioStyles}
              name="manualEdit+mesh"
              checked={editMode === 'manualEdit+mesh'}
              onChange={() => onEditChange('manualEdit+mesh')}
              label={
                <FormattedMessage id="module-entityProperties-editPanelManualEdit+MeshRadioLabel" />
              }
              helpText={
                <FormattedMessage id="module-entityProperties-editPanelManualEdit+MeshRadioHelpText" />
              }
              disabled={!data?.response}
            />
          </Step1RadioWrapper>
        </>
      )}
    </FormDecorator>
  );
};

export default EntityPropertiesStep1Options;

EntityPropertiesStep1Options.propTypes = {
  editMode: PropTypes.string,
  setEditMode: PropTypes.func,
  entityId: PropTypes.string,
};

EntityPropertiesStep1Options.defaultProps = {
  editMode: '',
  setEditMode: () => {},
  entityId: '',
};
