import styled from 'styled-components';

export const ExplainLogSingleEventItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExplainLogSingleEventPre = styled.pre`
  border: 1px solid #ececec;
  padding: 8px;
  margin: 0 !important;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  width: 100%;
  word-break: keep-all;
`;
