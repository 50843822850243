import moment from 'moment';

export const getEntityTemporalDataFromGql = (entity = {}) => {
  const { createdDate, modifiedDate, discoveryDate } = entity;

  const createdDateFormatted = createdDate
    ? moment(createdDate).fromNow()
    : null;
  const modifiedDateFormatted = modifiedDate
    ? moment(modifiedDate).fromNow()
    : null;
  const discoveryDateFormatted = discoveryDate
    ? moment(discoveryDate).fromNow()
    : null;
  // TODO add author if available:
  // last modified 3 months ago by Billy

  const modifiedDateToRender =
    modifiedDateFormatted && `Last modified ${modifiedDateFormatted}`;
  const createdDateToRender =
    createdDateFormatted && `Created ${createdDateFormatted}`;
  const discoveryDateToRender =
    discoveryDateFormatted && `Discovered ${discoveryDateFormatted}`;

  let result;

  if (modifiedDateToRender) {
    result = modifiedDateToRender;
  }

  if (result && createdDateToRender) {
    result = `${result}, ${createdDateToRender.toLowerCase()}`;
    return result;
  }

  if (result) {
    return result;
  }

  return discoveryDateToRender;
};
