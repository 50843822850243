import React from 'react';
import { Widget } from 'uxi/Widget';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';

const EntityDenied = () => (
  <Widget
    style={{
      background: '#fff',
    }}
    tilte={<FormattedMessage id="accessDeniedTitle" />}
  >
    <Alert>
      <FormattedMessage id="noAccessToPage" />
    </Alert>
  </Widget>
);

export default EntityDenied;
