export const required = (value) => (value ? undefined : 'Required');

export const requiredArray = (value) => {
  return value && Array.isArray(value) && value.length > 0
    ? undefined
    : 'Not an array, or array empty';
};

const isUrl = (str) => {
  /* eslint-disable no-useless-escape */
  const regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(str);
};

export const email = (v) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(v);
};

export const emailFormForm = (v) => {
  return !email(v) ? '' : 'Please enter a valid email';
};

export const url = (value) => {
  const result = isUrl(value) ? undefined : 'Invalid Url';
  return result;
};

export const uuid = (v) => {
  const re =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return !re.test(v);
};

export const password = (v) => {
  return v.length > 7 ? '' : 'Your password must be at least 8 characters long';
};

export default {
  required,
  url,
  email,
  uuid,
  password,
};
