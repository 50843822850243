import { handleActions } from 'redux-actions';
import storage from 'store';
import {
  shouldOpenViewOptions,
  shouldCloseViewOptions,
  shouldChangeViewOptionAction,
} from './actions';
import defaultViewOptions from './defaultViewOptions';

const viewOptions = storage.get('view-options') || defaultViewOptions;

export const initialState = {
  shouldViewOptions: false,
  viewOptions,
};

export default handleActions(
  {
    [shouldOpenViewOptions]: (state) => ({
      ...state,
      shouldViewOptions: true,
    }),
    [shouldCloseViewOptions]: (state) => ({
      ...state,
      shouldViewOptions: false,
    }),
    [shouldChangeViewOptionAction]: (state, { payload }) => ({
      ...state,
      viewOptions: [...payload],
    }),
  },
  initialState,
);
