import { makeLazy } from '../core/components/Hocs/LazyRoute';

export const getLink = {
  home: () => '/admin/settings/feature-flag/',
};

export default [
  {
    path: '/',
    exact: true,
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"FeatureFlagPage" */
        './components/pages/FeatureFlagPage'
      ),
    ),
  },
];
