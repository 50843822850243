import React from 'react';
import PropTypes from 'prop-types';

const SourceTitleStyle = {
  wrapper: {
    paddingTop: '5px',
    clear: 'both',
    marginBottom: '32px',
    textAlign: 'left',
  },
  img: {
    float: 'left',
    width: '24px',
    borderRadius: '2px',
    marginRight: '5px',
  },
  title: {
    marginBottom: '5px',
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#586672',
  },
  separator: {
    borderColor: '#ccc',
    borderTop: '0px',
  },
};

const EntityPropertySection = ({ children, title, icon }) => {
  const titleStyle = SourceTitleStyle.title;

  return (
    <div style={SourceTitleStyle.wrapper}>
      <h4 style={titleStyle}>
        {icon}
        {title}
      </h4>
      <hr style={SourceTitleStyle.separator} />
      {children}
    </div>
  );
};

EntityPropertySection.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default EntityPropertySection;
