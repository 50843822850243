"use strict";

exports.__esModule = true;
exports.InnerRow = exports.ContentWrapper = exports.Accordion = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var Accordion = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: flex;\n  border-bottom: 1px solid hsla(0, 0%, 88%, 1);\n  flex-direction: column;\n\n  & > div:first-of-type {\n    padding-right: 7px;\n    background: ", ";\n\n    &:hover {\n      background: ", ";\n    }\n  }\n\n  &:last-of-type {\n    border-bottom: none;\n  }\n"])), function (_ref) {
  var visible = _ref.visible,
    palette = _ref.theme.palette;
  return visible && palette.themeLighterAlt;
}, function (_ref2) {
  var palette = _ref2.theme.palette;
  return palette.themeLighterAlt;
});
exports.Accordion = Accordion;
var ContentWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  width: 100%;\n"])));
exports.ContentWrapper = ContentWrapper;
var InnerRow = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  display: flex;\n\n  & > div:first-of-type {\n    padding-right: 10px;\n  }\n"])));
exports.InnerRow = InnerRow;