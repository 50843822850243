import React from 'react';
import { connect } from 'react-redux';
import { DefaultSource } from '../../data';

const formatName = (str) => str.toLowerCase().replace(' ', '');

const getIntegrationConfig = (integrations, integrationName) => {
  const formattedName = formatName(integrationName);

  return integrations.find((integration) => {
    const currentIntegrationName = formatName(integration.Name);

    if (
      currentIntegrationName === formattedName ||
      formattedName.indexOf(currentIntegrationName) > -1
    ) {
      return true;
    }

    if (integration.Aliases) {
      const found = (integration.Aliases || []).find(
        (a) => formatName(a) === formattedName,
      );

      if (found) {
        return !!found;
      }
    }

    return false;
  });
};

const mapToStateProps =
  (convertProps) =>
  ({ integration: { allIntegrations } }, props) => {
    const propsToUser = convertProps ? convertProps(props) : props;

    const { integrationName } = propsToUser;

    if (integrationName.toLowerCase() === 'cluedin') {
      return {
        integrationConfig: DefaultSource,
        integration: DefaultSource,
      };
    }

    if (!allIntegrations) {
      return {
        integrationConfig: null,
      };
    }

    if (!integrationName) {
      return {
        integrationConfig: null,
      };
    }

    const integrationConfig = getIntegrationConfig(
      allIntegrations,
      integrationName,
    );

    return {
      integrationConfig: integrationConfig || { Name: integrationName },
      integration: integrationConfig || { Name: integrationName },
    };
  };

export const withIntegrationConfig = (Comp, convertProps) => {
  const EnhancedComp = connect(mapToStateProps(convertProps))(Comp);

  return (props) => <EnhancedComp {...props} />;
};

const getIntegrationConfigFromId = (allIntegrations = [], integrationId) => {
  return allIntegrations.find((i) => i.Id === integrationId);
};

const mapToStatePropsFromConfiguration = (
  { integration: { allIntegrations } },
  { configuration },
) => {
  if (!allIntegrations) {
    return {
      integrationConfig: null,
    };
  }

  if (!configuration) {
    return {
      integrationConfig: null,
    };
  }

  const integrationConfig = getIntegrationConfigFromId(
    allIntegrations,
    configuration.ProviderId,
  );

  return {
    integrationConfig: integrationConfig || null,
  };
};

export const withIntegrationConfigFromConfiguration = (Comp) => {
  const EnhancedComp = connect(mapToStatePropsFromConfiguration)(Comp);

  return (props) => <EnhancedComp {...props} />;
};

const mapToStatePropsFromEntity = (
  { integration: { allIntegrations } },
  { entity },
) => {
  if (!allIntegrations) {
    return {
      integrationConfig: null,
    };
  }

  if (!entity) {
    return {
      integrationConfig: null,
    };
  }

  const integrationConfig = getIntegrationConfig(
    allIntegrations,
    entity.name || entity.CodeName,
  );

  return {
    integrationConfig: integrationConfig || null,
  };
};

export const withIntegrationConfigFromEntity = (Comp) => {
  const EnhancedComp = connect(mapToStatePropsFromEntity)(Comp);

  return (props) => <EnhancedComp {...props} />;
};

const mapToStatePropsFromIntegrationId = (
  { integration: { allIntegrations } },
  { integrationId },
) => {
  const integrationConfig = (allIntegrations || []).find(
    (i) => i.Id === integrationId,
  );

  return {
    integrationConfig,
  };
};

export const withIntegrationWithId = (Comp) => {
  const EnhancedComp = connect(mapToStatePropsFromIntegrationId)(Comp);

  return (props) => <EnhancedComp {...props} />;
};

export default {
  withIntegrationConfig,
  withIntegrationConfigFromConfiguration,
  withIntegrationConfigFromEntity,
  withIntegrationWithId,
};
