import React from 'react';
import { DivPadding } from 'uxi/Base';
import DoughnutChart from '../../../../DVC/components/composites/DoughnutChart';

const EntityTypeDistributionComposite = ({ data }) => (
  <div style={{ width: '100%', height: '100%' }}>
    <DivPadding
      style={{
        display: 'flex',
        margin: '0 auto',
        flexFlow: 'row wrap',
        maxWidth: '600px',
      }}
      padding="s"
    >
      <DoughnutChart data={data} />
    </DivPadding>
  </div>
);

export default EntityTypeDistributionComposite;
