import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { legacyClient } from '../../../data/graphql';

export const withGqlQuery = (Comp) => (props) => {
  if (!props.gql || !props.gql.query) {
    return <Comp {...props} />;
  }

  return (
    <Query
      client={legacyClient}
      query={gql(props.gql.query)}
      variables={props.gql.variables || {}}
    >
      {({ loading, error, data = {} }) => {
        let dataWithPath;

        if (error) {
          console.error('Invalid Gql Query pass to component');
          console.error(error);
        }

        try {
          if (props.gql.valueResolver) {
            if (data) {
              const resolver = props.gql.valueResolver;
              dataWithPath = resolver(data, props);
            }
          } else if (props.gql.valuePath) {
            dataWithPath = _.get(
              data,
              props.gql.valuePath,
              props.gql.defaultValue || 0,
            );
          } else if (data) {
            dataWithPath = data;
          } else {
            dataWithPath = props.gql.defaultValue;
          }
        } catch (e) {
          dataWithPath = 'Invalid';
        }

        return (
          <Comp
            {...props}
            loading={loading}
            isFetching={loading}
            invalid={error}
            data={dataWithPath}
            error={error}
          />
        );
      }}
    </Query>
  );
};

export default {
  withGqlQuery,
};
