import { apiRequest } from '../core/helpers/request';

export const saveConsentEntries = (
  consentEntries,
  apiToken,
  generatedConsentToken,
) => {
  const processorConsentWithEncodedLookupIds = consentEntries.map(
    (consentEntry) => ({
      ...consentEntry,
      LookupId: encodeURIComponent(generatedConsentToken),
    }),
  );

  return apiRequest(
    'POST',
    'api/v1/gdprconsententry',
    processorConsentWithEncodedLookupIds,
    apiToken,
  );
};

export const saveSingleConsentEntry = (consentEntry) =>
  apiRequest('POST', 'api/v1/gdprconsententry', [consentEntry]);

export const saveManualConsentEntries = ({ consentEntries }) =>
  apiRequest('POST', 'api/v1/gdprconsententry', consentEntries);

export const saveConsent = (processorConsent) =>
  apiRequest('POST', 'api/v1/gdprconsent', processorConsent);

export const saveConsentForm = (consentAPIModel) => {
  if (!consentAPIModel) {
    return console.error('you have to pass a valid consentForm');
  }

  const { form, consents } = consentAPIModel;

  const arrayOfConsentPromises = consents
    .map((i) => ({
      ...i,
      IdentifiersString: JSON.stringify(i.Identifiers || '[]'),
    }))
    .map(saveConsent);

  return Promise.all(arrayOfConsentPromises).then((savedConsent) => {
    // do smth with the responses
    // like build the ConsentIds json string
    const savedConsentIds = savedConsent.map(({ Id }) => Id);

    const postBody = {
      ...form,
      ConsentIds: JSON.stringify(savedConsentIds), // json string
    };

    // 2. post the consent form
    return apiRequest('POST', 'api/v1/gdprconsentform', postBody);
  });
};

export const fetchConsentFormByIdForEdit = (consentFormId) =>
  apiRequest('GET', `api/v1/gdprconsentform?id=${consentFormId}`);

export const fetchConsentFormById = (consentFromId, token) =>
  apiRequest('GET', `api/v1/gdprconsentform?id=${consentFromId}`, null, token);

export const fetchAllConsentForms = (option = {}) => {
  const { page = 0, take = 20 } = option;

  return apiRequest('GET', `api/v1/gdprconsentform?page=${page}&take=${take}`);
};
export const fetchAllConsent = (options = {}) => {
  const { page = 0, take = 20 } = options;
  return apiRequest('GET', `api/v1/gdprconsent?page=${page}&take=${take}`);
};

export const fetchConsentFormByFormId = (consentFromId) =>
  apiRequest('GET', `api/v1/gdprconsentform?id=${consentFromId}`);

export const fetchConsentEntiresForConsentEntry = ({
  consentEntryId,
  page = 0,
  take = 20,
}) =>
  apiRequest(
    'GET',
    `api/v1/gdprconsententry/consent?consentId=${consentEntryId}&page=${page}&take=${take}`,
  );
