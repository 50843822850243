/* eslint-disable */
import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import SignupUserWithEmailDomainFormContainer from './SignupUserWithEmailDomainFormContainer';
import PublicContext from '../../../auth/components/composites/PublicContext';
import SignUpImg from '../../assets/sign-up-illustration.png';

const SignupUserWithEmailDomainWrapperContainer = () => (
  <NotSignedInLayoutDividedInTwo
    Page={SignupUserWithEmailDomainFormContainer}
    Context={PublicContext}
    url={`${SignUpImg}`}
  />
);

SignupUserWithEmailDomainWrapperContainer.displayName =
  'SignupUserWithEmailDomainWrapperContainer';
export default SignupUserWithEmailDomainWrapperContainer;
