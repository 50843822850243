const IntegrationListRowStyle = {
  root: {
    display: 'flex',
    padding: '15px',
    borderBottom: '1px solid rgb(222, 229, 231)',
    flexFlow: 'row wrap',
  },
  title: {
    margin: '0px 0px 5px 0px',
    padding: 0,
    fontSize: '20px',
    fontFamily: 'sans-serif',
    fontWeight: '500',
  },
  descriptionContainer: {
    flex: 1,
    display: 'flex',
    flexFlow: 'row wrap',
  },
  descriptionContainerContent: {
    flex: 1,
    padding: '0 15px',
    minWidth: '200px',
  },
  details: {
    fontSize: '14px',
    color: '#9e9ea6',
  },
  button: {
    margin: '0 8px',
  },
  dangerIcon: {
    padding: '5px',
    fill: 'red',
  },
  iconWrapper: {
    marginBottom: '8px',
  },
};
export default IntegrationListRowStyle;
