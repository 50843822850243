import React, { Fragment } from 'react';
import styled from 'styled-components';
import EntityTileSnippet from './EntityTileSnippet';
import SchemaPropertyLinkWithPopover from '../../../schema/components/containers/SchemaPropertyLinkWithPopover';

export const PropertyLabel = styled.div`
  font-size: 12px;
  max-width: 100%;
  font-style: italic;
`;

const EntityPropertyValue = ({ entity, property }) => {
  if (property.displayName === 'description') {
    return <EntityTileSnippet snippet={entity.description} />;
  }

  const ValueComp = property.Component;
  return <EntityTileSnippet snippet={<ValueComp {...entity} />} />;
};

const EntityPropertiesList = ({ entity, properties = [] }) => {
  return (
    <Fragment>
      {properties.map((p) => (
        <div>
          <PropertyLabel>
            <SchemaPropertyLinkWithPopover propertyKey={p.displayName} />
          </PropertyLabel>
          <div>
            <EntityPropertyValue entity={entity} property={p} />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

const ExtraTileExtra = ({ entity, properties, description }) => {
  const hasField = properties && properties.length > 0;

  if (!hasField) {
    return <EntityTileSnippet snippet={description} />;
  }

  return <EntityPropertiesList entity={entity} properties={properties} />;
};

export default ExtraTileExtra;
