import createPropertyVM from './createPropertyVM';

const createPropertyFromTransform = (transform, schema, configurations) => {
  if (!transform) {
    return null;
  }

  return createPropertyVM(
    transform.key,
    transform.value,
    schema,
    configurations,
  );
};

const createPropertiesFromTransforms = (
  transforms = [],
  schema,
  configurations,
) =>
  transforms.map((transform) =>
    createPropertyFromTransform(transform, schema, configurations),
  );

export const toActionMessagesVM = (
  actionMessages = [],
  schema = [],
  configurations = [],
) => {
  if (
    !Array.isArray(actionMessages) ||
    !Array.isArray(schema || !Array.isArray(configurations))
  ) {
    return [];
  }

  return (
    actionMessages &&
    (actionMessages || [])
      .filter((x) => x.isQueued !== false)
      .map((actionMessage) => {
        let transforms = null;
        let rawQuery = null;

        try {
          transforms = JSON.parse(actionMessage.TransformString);
        } catch (err) {
          transforms = null;
        }

        if (!transforms) {
          return null;
        }

        try {
          rawQuery = JSON.parse(actionMessage.RawQueries);
        } catch (err) {
          rawQuery = null;
        }

        const providerDefinition = configurations.find(
          (config) => config.Id === actionMessage.ProviderDefinitionId,
        );

        return {
          id: actionMessage.Id,
          action: actionMessage.Action,
          properties: createPropertiesFromTransforms(transforms),
          transforms,
          rawQuery: {
            source: (rawQuery || {}).Source,
            query: (rawQuery || {}).Query,
          },
          providerDefinition,
          providerDefinitionId: actionMessage.ProviderDefinitionId,
        };
      })
      .filter((x) => x)
  );
};

export default toActionMessagesVM;
