import React from 'react';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import GraphWidgetWrapper from '../../containers/reports/GraphWidgetWrapper';

const NotEnoughData = () => (
  <GraphWidgetWrapper ratio={false}>
    <Alert style={{ height: '100%' }}>
      <FormattedMessage id="module-governance-notEnoughDataForChart" />
    </Alert>
  </GraphWidgetWrapper>
);

export default NotEnoughData;
