import { Div } from '../modules/core/components/composites/routing/RouteWithSubRoutes';
import withBreadcrumb from '../modules/core/components/composites/routing/withBreadcrumb';
import withTitle from '../modules/core/components/composites/routing/withTitle';
import DefaultPillarWrapper from './DefaultPillarWrapper';
import { onlyAuth } from '../modules/auth/components/Hocs/onlyAuth';
import {
  getCurrentToken,
  getPillarConfiguration,
  getExtensionConfiguration,
} from '../config';
import ExtensionDashboard from './ExtensionDashboard';
import PageNotAvailable from '../modules/error/components/composites/PageNotAvailable';

const token = getCurrentToken();

const mergePillars = (defaultPillars, extensionPillars) => {
  const result = [...defaultPillars];

  extensionPillars.forEach((extendPillar) => {
    const foundPillar = defaultPillars.find(
      (x) => x.name === extendPillar.name,
    );
    if (foundPillar) {
      console.error(
        `You are trying to replace a default Pillar: ${extendPillar.name} - this is forbidden`,
      );
    }
    result.push(extendPillar);
  });

  return result;
};

const mergeModules = (defaultModules, extensionModules) => {
  const result = [...defaultModules];

  extensionModules.forEach((extendModule) => {
    const foundModule = defaultModules.find(
      (x) => x.name === extendModule.name,
    );
    if (foundModule) {
      console.error(
        `You are trying to replace a default Module: ${extendModule.name} - this is forbidden`,
      );
    }
    result.push(extendModule);
  });

  return result;
};

const compileExtensions = (defaultExtensions = {}, extension = {}) => {
  const { pillars = [], modules = [] } = extension;
  const { pillars: defaultPillars = [], modules: defaultModules = [] } =
    defaultExtensions;

  const mergedPillar = mergePillars(defaultPillars, pillars);
  const mergedModules = mergeModules(defaultModules, modules);

  const rootApplication = (mergedPillar || []).map((pillar) => {
    const modulesForGivenPillar = (mergedModules || [])
      .filter((mod) => {
        return mod.pillar === pillar.name;
      })
      .map((m) => {
        const moduleConfig = getPillarConfiguration(pillar.name, m.name);
        let modComp = m.Component || Div;

        if (moduleConfig && moduleConfig.disable) {
          const mainDisableReason = getExtensionConfiguration('disableReason');
          modComp = PageNotAvailable(
            moduleConfig.disableReason || mainDisableReason,
            moduleConfig.disableImage,
          );
        }

        const ModuleComp = withBreadcrumb(modComp, {
          name: m.name,
          displayName: m.displayName,
          path: m.path,
          pillar: m.pillar,
          backButton: m.backButton,
          hideModule: m.hideModule,
        });

        return {
          ...m,
          component: ModuleComp,
          routes: (m.routes || []).map((r) => {
            return {
              ...r,
              component: withTitle(r.component, r.title),
            };
          }),
        };
      });

    const dashboardExtensions = (modulesForGivenPillar || []).map((m) => {
      if (m.extendPillarDashboard) {
        return {
          featureFlag: m.featureFlag,
          actions: m.extendPillarDashboard.actions,
          section: m.extendPillarDashboard.section
            ? {
                Component: m.extendPillarDashboard.section,
                module: m,
              }
            : null,
        };
      }
      return null;
    });

    const Comp =
      pillar && pillar.component ? pillar.component : DefaultPillarWrapper;

    const EnhancedComp = onlyAuth(Comp, token);

    return {
      ...pillar,
      component: EnhancedComp,
      dashboardExtensions: dashboardExtensions || [],
      routes: [
        {
          path: '/',
          exact: true,
          component: ExtensionDashboard,
        },
        ...(modulesForGivenPillar || []),
      ],
    };
  });

  return rootApplication;
};

export default compileExtensions;
