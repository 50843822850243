import React, { useEffect, useState } from 'react';
import { getEntityTypeIcon } from '@cluedin/components';
import { useId } from '@fluentui/react-hooks';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Externallink } from 'uxi/Icons';

import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { Panel, PanelHeader, PanelContent } from '@cluedin/form';
import { ButtonLink } from '@cluedin/atoms';
import { Checkmark } from 'uxi/Icons';
import { ClipboardIcon } from '@cluedin/svgs';
import Tooltip from 'rc-tooltip';
import { get } from 'lodash';
import { copyToClipboard } from '../../../core/helpers/dom';
import {
  Divider,
  EntityCodePanelCodesList,
  EntityCodePanelHeading,
  EntityCodePanelHeadingWrapper,
  EntityCodePanelWrapper,
  OriginCodeWrapper,
} from './EntityCodePanel.styles';

const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContent: {
    margin: '10px 0',
  },
  imageBackground: {
    width: 72,
    height: 72,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: 10,
  },
  icon: {
    width: 36,
    height: 36,
    display: 'inline-block',
    fill: 'white',
  },
  title: {
    display: 'inline-block',
    width: '100%',
  },
  code: {
    display: 'inline-block',
    marginBottom: 4,
    paddingBottom: 4,
  },
  buttonLink: {
    fontWeight: 'bold',
  },
};

const OriginCode = ({ url }) => {
  return (
    <OriginCodeWrapper>
      <span>{url}</span>
    </OriginCodeWrapper>
  );
};

const getPath = (route, entity) =>
  route
    ? route.replace(
        ':id',
        entity.data && (entity.data.id || entity.data.entityId),
      )
    : `entity/${entity?.data?.id || entity?.data?.entityId}`;

const EntityCodePanel = ({ entity, onClose, includeHeader = false, show }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const tooltipId = useId();

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const codes = get(entity, 'data.codes', []);

  const Icon = includeHeader ? (
    getEntityTypeIcon(entity.data.icon || 'Help')
  ) : (
    <></>
  );

  const rawRoute = entity && entity.data.route;

  const route = rawRoute
    ? rawRoute?.includes(':id')
      ? rawRoute
      : `${rawRoute}/:id`
    : null;

  const copyToClipboardAndSuccess = () => {
    setShowCopiedMessage(true);
    copyToClipboard(
      entity?.data['attribute-origin'] || entity?.data?.attributeOrigin,
    );

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  const handleEsc = ({ key }) => {
    if (onClose && key === 'Escape' && show) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [show]);

  return (
    <Panel open={show} onClose={onClose} withCustomHeader panelType="medium">
      <PanelHeader
        style={{
          fontSize: '16px',
          padding: '0 16px',
        }}
        hasClose
        title={<FormattedMessage id="module-entity-entityCodesPanelHeader" />}
        onClose={onClose}
      />
      <PanelContent>
        {includeHeader && entity && entity.data && entity.data.name && (
          <div style={styles.headerContainer}>
            <div
              style={{
                ...styles.imageBackground,
                backgroundColor: themePrimary ?? 'rgba(37,162,153)',
              }}
            >
              <Icon style={styles.icon} />
            </div>
            {entity?.data?.icon ? (
              <ButtonLink
                style={styles.buttonLink}
                onClick={(e) => {
                  const path = getPath(route, entity);
                  window.open(`/${path}`, '_blank');
                  return false;
                }}
              >
                {entity.data.name}
              </ButtonLink>
            ) : (
              <TooltipHost
                content={
                  <div style={{ padding: '6px' }}>
                    <div>
                      <FormattedHTMLMessage
                        id="module-entityType-notConfigureEntityTypeMessage"
                        values={{
                          entityType: entity?.data?.entityType,
                        }}
                      />
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`/admin/management/entity-types/edit?entityType=${entity?.data?.entityType}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Externallink size={12} />
                        <span style={{ marginLeft: '6px' }}>
                          <FormattedMessage id="module-entityType-configureEntityType" />
                        </span>
                      </a>
                    </div>
                  </div>
                }
              >
                <ButtonLink
                  style={styles.buttonLink}
                  onClick={(e) => {
                    const path = getPath(route, entity);
                    window.open(`/${path}`, '_blank');
                    return false;
                  }}
                >
                  {entity.data.name}
                </ButtonLink>
              </TooltipHost>
            )}
            {entity.data &&
              (entity.data.type || (entity.data && entity.data.entityType))}
          </div>
        )}
        <EntityCodePanelWrapper>
          <EntityCodePanelHeadingWrapper themePrimary={themePrimary}>
            <EntityCodePanelHeading>
              <FormattedMessage id="module-entity-entityCodesPanelOriginCodeHeading" />
            </EntityCodePanelHeading>
            <ButtonLink
              text={
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {!showCopiedMessage && (
                    <Tooltip
                      placement="top"
                      trigger="hover"
                      overlay={
                        <FormattedMessage id="module-entity-entityCodesPanelCopyOrigin" />
                      }
                    >
                      <ClipboardIcon themePrimary={themePrimary} />
                    </Tooltip>
                  )}
                  {showCopiedMessage && (
                    <Tooltip
                      placement="top"
                      trigger="hover"
                      overlay={
                        <FormattedMessage id="module-entity-entityCodesPanelOriginCopied" />
                      }
                    >
                      <Checkmark color={themePrimary} size={14} />
                    </Tooltip>
                  )}
                  <div style={{ marginLeft: '6px' }}>
                    <FormattedMessage id="module-entity-entityCodesPanelCopyCode" />
                  </div>
                </span>
              }
              onClick={() => {
                copyToClipboardAndSuccess();
              }}
            />
          </EntityCodePanelHeadingWrapper>
          <OriginCode
            url={
              entity &&
              entity?.data &&
              (entity?.data['attribute-origin'] ||
                entity?.data?.attributeOrigin)
            }
          />

          <Divider />

          <EntityCodePanelHeading>
            <FormattedMessage id="module-entity-entityCodesPanelCodesHeading" />
          </EntityCodePanelHeading>
          <EntityCodePanelCodesList>
            {codes && codes.length === 0 && (
              <FormattedMessage id="module-entity-entityCodesPanelNoCodes" />
            )}

            {codes && codes.map((code) => <li key={code}>{code}</li>)}
          </EntityCodePanelCodesList>
        </EntityCodePanelWrapper>
      </PanelContent>
    </Panel>
  );
};

export default EntityCodePanel;
