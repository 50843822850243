import { isEmpty } from 'lodash';

import { getBaseValidationConfig } from './getBaseValidationConfig';

const getFieldValidationByCondition = ({
  fieldToValidate,
  fieldErrorTypes,
  validationCondition,
  validationConditionValue = 1,
}) => {
  switch (validationCondition) {
    case 'required': {
      if (isEmpty(fieldToValidate)) {
        const message = fieldErrorTypes?.filter(
          (i) => i.key === 'required',
        )?.[0]?.message;

        return message || 'Required';
      }

      break;
    }
    case 'length': {
      const message = fieldErrorTypes?.filter((i) => i.key === 'length')?.[0]
        ?.message;

      if (fieldToValidate?.length < validationConditionValue) {
        return message || 'Required';
      }

      break;
    }
    case 'multiple': {
      const message = fieldErrorTypes?.filter((i) => i.key === 'multiple')?.[0]
        ?.message;

      if (
        isEmpty(fieldToValidate) ||
        !fieldToValidate?.every((i) => !isEmpty(i))
      ) {
        return message || 'Required';
      }

      break;
    }
    default:
      return undefined;
  }
};
const getFieldValidation = ({
  fieldToValidateKey,
  fieldToValidate,
  fieldErrorTypes,
  fieldValidationConditions,
}) => {
  const fieldToValidationResult = [];

  fieldValidationConditions?.forEach((validationCondition) => {
    const validationResult = getFieldValidationByCondition({
      fieldToValidate,
      fieldErrorTypes,
      validationCondition: validationCondition?.name,
      validationConditionValue: validationCondition?.value,
    });

    if (validationResult) {
      fieldToValidationResult.push({
        key: fieldToValidateKey,
        message: validationResult,
      });
    }
  });

  return fieldToValidationResult;
};

const getValidation = ({ data = {}, validationRules, errorTypes }) => {
  const errors = [];

  validationRules?.forEach((validationRule) => {
    const fieldDependsOn = validationRule?.dependsOn;
    const fieldDependsOnValue = data?.[fieldDependsOn];

    if (fieldDependsOn && !fieldDependsOnValue) {
      return;
    }

    const fieldGetSpecialCondition =
      validationRule?.getSpecialCondition?.(fieldDependsOnValue);

    const fieldToValidateKey = validationRule?.key;

    const fieldToValidate = data?.[fieldToValidateKey];
    const fieldValidationConditions =
      fieldGetSpecialCondition || validationRule?.validationConditions;
    const fieldErrorTypes = errorTypes?.[fieldToValidateKey];

    const result = getFieldValidation({
      fieldToValidateKey,
      fieldToValidate,
      fieldErrorTypes,
      fieldValidationConditions,
    });

    errors.push(result);
  });

  return errors?.flat();
};

export const getRulesValidationErrors = ({ rules, operators, intl }) => {
  const { errorTypes, validationRules } = getBaseValidationConfig({
    operators,
    intl,
  });

  const res = [];

  const prepareErrors = ({ rules, count }) => {
    count = count || 0;

    rules?.forEach?.((ruleItem, idx) => {
      if (ruleItem.type === 'rule') {
        return prepareErrors({ rules: ruleItem?.rules, count: count + 1 });
      }

      const errors = getValidation({
        data: ruleItem,
        errorTypes,
        validationRules,
      });

      if (errors?.length === 0) {
        return;
      }

      res.push({
        key: `${count}-${idx}`,
        errors,
      });
    });
  };

  prepareErrors({ rules });

  return res;
};
