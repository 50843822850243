"use strict";

exports.__esModule = true;
exports.twoEntitiesHistoryDefault = exports.partIdDefault = exports.historyDefault = exports.historyChangeSourceDefault = exports.fourEntitiesHistoryDefault = exports.entityIdDefault = exports.defaultChangeSetItems = exports.changeSetDefault = void 0;
var defaultChangeSetItems = [{
  property: 'acceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Id',
  changeType: 'Added',
  oldValue: 'acceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Id',
  newValue: 'acceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Id',
  changedPart: 'Property'
}, {
  property: 'acceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Id',
  changeType: 'Added',
  oldValue: 'acceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Id',
  newValue: 'acceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Idacceptance.organization.Id',
  changedPart: 'Property'
}, {
  property: 'acceptance.organization.Id',
  changeType: 'Added',
  oldValue: null,
  newValue: 'd65bd568-2a93-4925-9b59-cd5465646b95',
  changedPart: 'Property'
}, {
  property: 'acceptance.organization.lastUpdated',
  changeType: 'Added',
  oldValue: null,
  newValue: '30/08/2021 08:51:01 +07:00',
  changedPart: 'Property'
}, {
  property: 'organization.address.countryCode',
  changeType: 'Added',
  oldValue: null,
  newValue: 'FR',
  changedPart: 'Property'
}, {
  property: 'organization.contact.email',
  changeType: 'Added',
  oldValue: null,
  newValue: 'dupEmailAndPhoneNumber@foobar.com',
  changedPart: 'Property'
}];
exports.defaultChangeSetItems = defaultChangeSetItems;
var entityIdDefault = '642e5b3e-9e7b-501c-b630-28b6ce33899d';
exports.entityIdDefault = entityIdDefault;
var partIdDefault = 0;
exports.partIdDefault = partIdDefault;
var changeSetDefault = {
  groupName: 'August 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
};
exports.changeSetDefault = changeSetDefault;
var historyChangeSourceDefault = {
  __typename: 'EntityHistoryChangeSources',
  providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
  sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
  changedAt: '2021-08-30T01:51:01.8569634+00:00',
  changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
  partId: 16,
  changeType: 'Discovered',
  changeCount: 14
};
exports.historyChangeSourceDefault = historyChangeSourceDefault;
var historyDefault = [{
  groupName: 'August 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T02:51:44.6474436+00:00',
    changedBy: null,
    partId: 112,
    changeType: 'Discovered',
    changeCount: 14
  }]
}];
exports.historyDefault = historyDefault;
var twoEntitiesHistoryDefault = [{
  groupName: 'August 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
}, {
  groupName: 'July 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
}];
exports.twoEntitiesHistoryDefault = twoEntitiesHistoryDefault;
var fourEntitiesHistoryDefault = [{
  groupName: 'August 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
}, {
  groupName: 'July 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
}, {
  groupName: 'June 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
}, {
  groupName: 'May 2021',
  sources: [{
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:01.8569634+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 16,
    changeType: 'Discovered',
    changeCount: 14
  }, {
    providerDefinitionId: '78497542-b5ce-4b48-82c1-a39b7d385f0f',
    sourceName: 'Acceptance - add490b8e38742de99cbf6d7b82fc78f',
    changedAt: '2021-08-30T01:51:44.6474436+00:00',
    changedBy: 'ec0afae7-04bc-420e-87a9-beb87229e992',
    partId: 69,
    changeType: 'Discovered',
    changeCount: 14
  }]
}];
exports.fourEntitiesHistoryDefault = fourEntitiesHistoryDefault;