import React from 'react';
import routes from './routes';

export default {
  pillar: 'testing',
  name: 'Entity Pending Changes',
  displayName: 'Entity Pending Changes',
  path: '/entity-pending-changes',
  routes,
};
