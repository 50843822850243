import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';

import { EntityRelationsDetailEntityTypeWrapper } from './EntityRelationsDetailEntityTypeWrapper';
import EntityTypeAvatarConfigurationLinkContainer from '../../../entityTypes/components/containers/EntityTypeAvatarConfigurationLinkContainer';
import { addTokenToImage } from '../../../entity/helper';
import { getFullToken } from '../../../core/helpers/storage';

type Value =
  | number
  | string
  | boolean
  | null
  | undefined
  | number[]
  | string[]
  | { uri: string };

type EntityRelationsDetailValueProps = {
  property: string;
  value: Value;
  edgeInformationId: Value;
  labelFromNode: string | null | undefined;
};

const isValueArrayOfStrings = (value: unknown): value is string[] => {
  return (
    Array.isArray(value) &&
    value?.every((item: unknown) => typeof item === 'string')
  );
};

export const EntityRelationsDetailValue: FC<
  EntityRelationsDetailValueProps
> = ({ property, value, edgeInformationId, labelFromNode }) => {
  if (isValueArrayOfStrings(value) && property === 'Grouped entity IDs') {
    return (
      <ol style={{ textAlign: 'left' }}>
        {value?.map((item, index) => (
          <li key={`${item}-${index}`}>{item}</li>
        ))}
      </ol>
    );
  }

  if (typeof value === 'boolean') {
    return (
      <div style={{ textTransform: 'capitalize' }}>{value.toString()}</div>
    );
  }

  if (!value || typeof value === 'undefined' || typeof value === null) {
    return <div>-</div>;
  }

  if (property === 'Entity type') {
    return (
      <EntityRelationsDetailEntityTypeWrapper>
        <EntityTypeAvatarConfigurationLinkContainer
          entityType={value}
          entity={null}
        />
      </EntityRelationsDetailEntityTypeWrapper>
    );
  }

  if (property === 'Label') {
    if (typeof value === 'string' && value?.toLowerCase() === 'grouped') {
      return (
        <div>
          <FormattedMessage id="module-entityRelationsGraphTreeview-groupedRelationsLabel" />
        </div>
      );
    }

    return (
      <Link
        target="_blank"
        rel="noreferrer noopener"
        to={`/entity/${edgeInformationId}`}
      >
        {typeof value === 'string' ? value : null}
      </Link>
    );
  }

  if (property === 'Preview image') {
    const previewImageIcon = addTokenToImage(
      (value as { uri: string })?.uri,
      getFullToken()?.token,
    );

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          padding: '4px',
        }}
      >
        <img
          src={previewImageIcon}
          alt={labelFromNode ?? `${property}`}
          style={{
            clipPath: 'circle(50%)',
            height: '40px',
            width: '40px',
          }}
        />
      </div>
    );
  }

  return <div>{typeof value === 'string' ? value : null}</div>;
};
