import React from 'react';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { shouldShowAlert } from '../../../core/actions';
import MainWidgetForPage from '../../../core/components/composites/MainWidgetForPage';
import DefaultAlert from '../../../core/components/composites/alerts/DefaultAlert';
import ConfigurationForm from './forms/ConfigurationForm';
import { shouldEditActiveProvider } from '../../actions';
import { shouldFetchConfigurationById } from '../../../configuration/actions';
import ConfigurationLoadingStatusContainer from '../../../configuration/components/containers/ConfigurationLoadingStatusContainer';
import { WithErrorAndLoadingAndQueuing } from '../../../core/components/Hocs/WithErrorAndLoading';
import { getInitialValueFromConfiguration } from '../../converter';
import PageLoader from '../../../core/components/composites/PageLoader';
import { withIntegrationFromId } from '../hocs/withIntegrationFromId';

const EditConfigurationContainer = (props) => {
  const {
    invalidSaving,
    integration,
    editConfiguration,
    configuration,
    initialValues,
    isSaving,
  } = props;

  if (!integration || (integration && !integration.Properties)) {
    const providerName = integration ? integration.Name : 'configuration';

    return (
      <Alert style>
        <FormattedMessage
          id="module-integration-noSupportEdit"
          values={{ providerName }}
        />
      </Alert>
    );
  }

  const configWithValues = integration.Properties.map((c) => {
    const cf = initialValues.helperConfiguration[c.name];

    return {
      ...c,
      value: cf,
    };
  });

  return (
    <MainWidgetForPage>
      <div style={{ padding: '15px' }}>
        {invalidSaving && <DefaultAlert />}
        <ConfigurationForm
          isSaving={isSaving}
          isEdit={true}
          integrationName={integration.Name}
          hasExtra={configuration ? configuration.SupportsConfiguration : false}
          configuration={configuration ? configuration.Configuration : {}}
          helperConfiguration={
            configuration ? configuration.helperConfiguration : {}
          }
          initialValues={initialValues}
          config={configWithValues}
          onEnable={(updateConfig) => {
            editConfiguration(configuration.AccountId, updateConfig);
          }}
        />
      </div>
    </MainWidgetForPage>
  );
};

const EnhanceEditConfigurationContainer = compose(
  lifecycle({
    componentDidMount() {
      const { fetchCurrentConfiguration } = this.props;
      fetchCurrentConfiguration();
    },
    componentWillReceiveProps(nextProp) {
      const { messageAndRedirect } = this.props;
      const { done } = this.props;

      if (!done && nextProp.done) {
        messageAndRedirect();
      }
    },
  }),
  branch(({ isFetching }) => isFetching, renderComponent(PageLoader)),
)(
  WithErrorAndLoadingAndQueuing(EditConfigurationContainer, {
    queueMessage:
      'We have received your request to edit this integration configuration, it has been queued and will be completed shortly.',
    CustomLoading: ConfigurationLoadingStatusContainer,
  }),
);

const mapStateToProps = (
  {
    configuration: {
      invalidSelectedConfiguration,
      isFetchingSelectedConfiguration,
      selectedConfiguration,
    },
    integration: { editConfiguration },
  },
  { integration, configurationId },
) => {
  const status = editConfiguration
    ? editConfiguration[configurationId] || {}
    : {};

  return {
    invalid: invalidSelectedConfiguration,
    isFetching: isFetchingSelectedConfiguration,
    configuration: selectedConfiguration,
    configurationId,
    invalidSaving: status.invalid,
    isSaving: status.isSaving,
    done: status.done,
    queued: status.queued,
    integration,
    initialValues: selectedConfiguration
      ? getInitialValueFromConfiguration(
          selectedConfiguration,
          integration ? integration.Properties : [],
        )
      : {},
  };
};

const mapDispatchToProps = (
  dispatch,
  { configurationId, integrationId, standAlone },
) => ({
  editConfiguration(accountId, editConfiguration) {
    dispatch(
      shouldEditActiveProvider({
        configurationId,
        accountId,
        configuration: editConfiguration,
      }),
    );
  },
  fetchCurrentConfiguration() {
    if (standAlone) {
      dispatch(shouldFetchConfigurationById(configurationId));
    }
  },
  messageAndRedirect() {
    dispatch(
      shouldShowAlert({
        type: 'success',
        title: 'Configuration Edited',
        description: 'Your configuration has been updated successfully.',
      }),
    );
    dispatch(push(`/admin/configuration/${integrationId}/${configurationId}/`));
  },
});

EditConfigurationContainer.displayName = 'EditConfigurationContainer';

export default withIntegrationFromId(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EnhanceEditConfigurationContainer),
);
