import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_DATA_PART_NODE_INFO = gql`
  query getDataPartNodeInfo($entityId: ID!, $dataPartId: ID!) {
    virtualization {
      getDataPartNodeInfo(entityId: $entityId, dataPartId: $dataPartId) {
        dataPartId
        name
        entityType
        createdDate
        modifiedDate
        discoveredDate
        properties
        provider
        source
      }
    }
  }
`;

export const useGetDataPartNodeInfo = () => {
  const [getDataPartNodeInfo, { data, loading, error }] = useLazyQuery(
    GET_DATA_PART_NODE_INFO,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    },
  );

  const result = get(data, 'virtualization.getDataPartNodeInfo');

  return [
    (entityId, dataPartId) =>
      getDataPartNodeInfo({ variables: { entityId, dataPartId } }),
    {
      data: result,
      loading,
      error,
    },
  ];
};
