import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'uxi/Button/FlatButton';

export const PageLeft = ({ currentPage, onPageChange }) => (
  <FlatButton
    message="<"
    style={{ marginRight: '4px' }}
    onClick={() => onPageChange(currentPage - 1)}
    disabled={currentPage === 1}
  />
);

PageLeft.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
