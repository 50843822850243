import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/:id',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GlobalMetricsDashboard" */
        './components/pages/EntityPropertiesPage'
      ),
    ),
  },
];
