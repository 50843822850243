import { memo, useCallback, useState } from 'react';
import {
  List,
  DataTreeView,
  DefaultAdvancedFilters,
  DefaultFilterToolbar,
  DataGridv2,
} from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { notFoundMessage } from './notFoundMessage';
import { getColumns } from './columns';
import VocabKeyTypeSelector from '../forms/fields/VocabKeyTypeSelector';
import ClassficationDropdownContainer from '../forms/fields/ClassificationDropdownContainer';
import AllConnectors from '../../../../integration/components/containers/AllConnectors';

const ListWrapper = styled.div`
  table th:nth-of-type(3),
  table thead td:nth-of-type(3) {
    min-width: fit-content !important;
    width: 280px !important;
  }

  /* data Type */

  table th:nth-of-type(2),
  table thead td:nth-of-type(2) {
    min-width: fit-content !important;
    width: 160px !important;
  }

  @media only screen and (max-width: 1720px) {
    /* Hide Group Name */
    table th:nth-of-type(5),
    table td:nth-of-type(5) {
      display: none;
    }
  }
  @media only screen and (max-width: 1400px) {
    /* Hide Group Name */
    table th:nth-of-type(4),
    table td:nth-of-type(4) {
      display: none;
    }
  }
  @media only screen and (max-width: 992px) {
    /* Hide Group Name */
    table th:nth-of-type(3),
    table thead td:nth-of-type(3) {
      min-width: fit-content !important;
      width: 240px !important;
    }
  }
`;

const VocabularyKeyFullListComposite = ({
  data,
  error,
  loading,
  selected,
  property,
  offsetTop,
  searchTerm,
  currentPage,
  setSearchTerm,
  setCurrentPage,
  onSelectedChange,
  selectable = false,
  dataTypeFilterState,
  integrationFilterState,
  setDataTypeFilterState,
  forceMobileView = true,
  classificationFilterState,
  setIntegrationFilterState,
  setClassificationFilterState,
}) => {
  const intl = useIntl();

  const [searchNameInput, setSearchName] = useState(searchTerm);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const keys = ((data || {}).data || [])
    .filter((v) => {
      if (v.dataType === 'Lookup' && !v.compositeVocabularyId) {
        return false;
      } else if (
        v.compositeVocabularyId &&
        v.compositeVocabulary?.dataType == 'Lookup' &&
        v.name !== 'Name'
      ) {
        return false;
      }

      return true;
    })
    .map((v) => {
      let displayName = v.displayName;
      if (
        v.compositeVocabularyId &&
        v.compositeVocabulary?.dataType == 'Lookup'
      ) {
        const resolvedValueLocalized = intl.formatMessage({
          id: 'module-dataCatalog-resolvedLookupValue',
        });
        displayName = `${v.compositeVocabulary.displayName} ${v.displayName} ${resolvedValueLocalized}`;
      }
      return { ...v, vocabKey: v.key, ...{ displayName } };
    });

  const hasFilters =
    searchTerm ||
    dataTypeFilterState ||
    integrationFilterState ||
    dataTypeFilterState;

  const reset = useCallback(() => {
    setSearchTerm('');
    setDataTypeFilterState('');
    setClassificationFilterState('');
    setSearchName('');
    setIntegrationFilterState('');
  }, []);

  const filters = [
    {
      label: (
        <FormattedMessage id="module-dataCatalog-vocabKey-field-dataType" />
      ),
      show: !forceMobileView,
      filter: (
        <VocabKeyTypeSelector
          data-test="data-type-selector"
          style={{
            width: '200px',
          }}
          defaultLabel={<FormattedMessage id="module-dataCatalog-allTypes" />}
          value={dataTypeFilterState || ''}
          onChange={(value) => {
            setDataTypeFilterState(value);
          }}
        />
      ),
    },
    {
      label: <FormattedMessage id="module-dataCatalog-classification" />,
      show: !forceMobileView,
      filter: (
        <ClassficationDropdownContainer
          data-test="data-classification-selector"
          style={{
            width: '200px',
          }}
          defaultLabel={
            <FormattedMessage id="module-dataCatalog-allClassification" />
          }
          value={classificationFilterState}
          onChange={(value) => {
            setClassificationFilterState(value);
          }}
        />
      ),
    },
    {
      label: (
        <FormattedMessage id="module-dataCatalog-vocabKey-field-integration" />
      ),
      show: !forceMobileView,
      filter: (
        <AllConnectors
          data-test="data-integration-selector"
          idValue={integrationFilterState || ''}
          value={integrationFilterState}
          onChange={setIntegrationFilterState}
        />
      ),
    },
  ];

  const showAdvancedFiltersResponsive =
    filters.filter((f) => !f.show).length > 0;

  let numberOfActiveFilters = 0;
  let numberOfActiveAdvancedFilters = 0;

  if (searchTerm) {
    numberOfActiveFilters += 1;
  }

  if (dataTypeFilterState) {
    numberOfActiveFilters += 1;
  }

  if (classificationFilterState) {
    numberOfActiveFilters += 1;
  }

  if (integrationFilterState) {
    numberOfActiveFilters += 1;
  }

  if (dataTypeFilterState && forceMobileView) {
    numberOfActiveAdvancedFilters += 1;
  }

  if (classificationFilterState && forceMobileView) {
    numberOfActiveAdvancedFilters += 1;
  }

  if (integrationFilterState && forceMobileView) {
    numberOfActiveAdvancedFilters += 1;
  }

  const resetLabel = (
    <span data-test="reset-filter-button">
      {numberOfActiveFilters === 0
        ? 'Reset all filters'
        : `Reset ${numberOfActiveFilters} filter${
            numberOfActiveFilters > 1 ? 's' : ''
          }`}
    </span>
  );

  return (
    <ListWrapper>
      <List
        DataGrid={!selectable ? DataTreeView : DataGridv2}
        hideExpanding
        loading={loading}
        searchInputPlaceholder={intl.formatMessage({
          id: 'cluedin-components-defaultSearchPlaceholder',
        })}
        error={error}
        offsetTop={offsetTop ?? 1}
        onSelectedChange={onSelectedChange}
        selectable={selectable}
        selected={selected}
        columns={getColumns(forceMobileView)}
        data={keys}
        total={data.total}
        selectedPage={currentPage}
        onPageChange={setCurrentPage}
        onChangeSearch={setSearchName}
        onSubmitSearch={setSearchTerm}
        searchName={searchNameInput}
        propertyKey={property ? property : 'vocabKey'}
        filterToolbar={
          <DefaultFilterToolbar
            resetAllFilterLabel={resetLabel}
            filters={filters}
            onResetAllFilters={() => {
              reset();
            }}
            numberOfActiveAdvancedFilters={numberOfActiveAdvancedFilters}
            onShowMoreFilter={() => {
              setShowAdvancedFilter(!showAdvancedFilter);
            }}
          />
        }
        advancedFilters={
          showAdvancedFilter &&
          showAdvancedFiltersResponsive && (
            <DefaultAdvancedFilters
              filters={filters}
              onHideFilter={() => {
                setShowAdvancedFilter(false);
              }}
              onResetFilter={() => {
                reset();
              }}
            />
          )
        }
        hasFilters={hasFilters}
        notFoundProps={{ ...notFoundMessage, noCreate: true }}
        noSearchFoundProps={{
          message: (
            <FormattedMessage id="module-dataCatalog-vocabKey-notFoundMessage" />
          ),
          explanation: (
            <FormattedMessage id="module-dataCatalog-vocabKey-notFoundExplanation" />
          ),
        }}
      />
    </ListWrapper>
  );
};

export default memo(VocabularyKeyFullListComposite);
