import { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { PanelContent, PanelHeader } from '@cluedin/form';
import { Stepper } from '@cluedin/components';
import get from 'lodash/get';

const defaultStepperState = {
  active: 1,
  completed: [],
};

import EntityCodePanelHeader from '../EntityCodePanel/EntityCodePanelHeader/EntityCodePanelHeader';
import EntityTopologySplitPanelList from '../EntityTopologySplitPanelList/EntityTopologySplitPanelList';
import EntityTopologyManualPanelPreview from '../EntityTopologyManualPanelPreview/EntityTopologyManualPanelPreview';
import ManualMergePanelFooter from '../ManualMergePanel/ManualMergePanelFooter/ManualMergePanelFooter';
import EntityTopologySplitModal from '../EntityTopologySplitModal/EntityTopologySplitModal';
import {
  DeduplicationProjectMergePanelStepperWrapper,
  DeduplicationProjectMergePanelWrapper,
} from './DeduplicationProjectMergePanel.styles';

const DeduplicationProjectMergePanel = ({
  entityId,
  onClose,
  currentNode,
  nodeDataType,
  setNodeDataType,
  handleExecuteAction,
  deduplicationProjectMergePanelData,
  deduplicationProjectMergePanelLoading,
  deduplicationProjectMergePanelError,
  intl,
}) => {
  const dataType = get(currentNode, 'dataType');

  const [isPreviewError, setIsPreviewError] = useState([]);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [newEntityCount, setNewEntityCount] = useState(null);
  const [stepperState, setStepperState] = useState(defaultStepperState);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);

  const handleBack = () => {
    setStepperState({
      active: 1,
      completed: [],
    });
  };

  const handleSplitModalOnClose = () => {
    setIsSplitModalOpen(false);
  };

  const handleOnCloseWithReset = () => {
    onClose();
    handleBack();
    setNodeDataType('');
  };

  useEffect(() => {
    return () => {
      handleOnCloseWithReset();
    };
  }, []);

  let connectingNode = '';
  let offsetTop;
  if (dataType === 'ManualMerge') {
    connectingNode = (
      <FormattedMessage id="module-entityTopology-manualMergePanelTitle" />
    );
    offsetTop = 460;
  }
  if (dataType === 'DeduplicationProjectMerge') {
    connectingNode = (
      <FormattedMessage id="module-entityTopology-deduplicationPanelTitle" />
    );
    offsetTop = 460;
  }

  const kind = get(deduplicationProjectMergePanelData, 'actions[0].kind');

  let panelStep1Text = '';
  if (kind === 'UndoDeduplicationProjectMerge') {
    panelStep1Text = intl.formatMessage({
      id: 'module-entityTopology-deduplicationMergePanelStep1',
    });
  }
  if (kind === 'UndoManualMerge') {
    panelStep1Text = intl.formatMessage({
      id: 'module-entityTopology-manualMergePanelStep1',
    });
  }

  return (
    <>
      <PanelHeader title={connectingNode} onClose={handleOnCloseWithReset} />

      <DeduplicationProjectMergePanelStepperWrapper>
        <Stepper
          activeStep={stepperState?.active}
          completedSteps={stepperState?.completed}
          onClickStep={(active) => {
            if (active === 1) {
              handleBack();
            }
          }}
          steps={[
            { label: panelStep1Text },
            {
              label: intl.formatMessage({
                id: 'module-entityTopology-manualMergePanelStep2',
              }),
            },
          ]}
        />
      </DeduplicationProjectMergePanelStepperWrapper>

      <DeduplicationProjectMergePanelWrapper>
        {deduplicationProjectMergePanelData && (
          <EntityCodePanelHeader
            data={deduplicationProjectMergePanelData}
            title={connectingNode}
            currentNode={currentNode}
          />
        )}

        {stepperState?.active === 1 &&
          deduplicationProjectMergePanelData &&
          !deduplicationProjectMergePanelError && (
            <EntityTopologySplitPanelList
              dataType={dataType}
              entityId={entityId}
              currentNode={currentNode}
              data={deduplicationProjectMergePanelData}
              error={deduplicationProjectMergePanelError}
              loading={deduplicationProjectMergePanelLoading}
              offsetTop={offsetTop}
            />
          )}

        <PanelContent style={{ padding: '16px 0' }}>
          {stepperState?.active === 2 &&
            deduplicationProjectMergePanelData &&
            !deduplicationProjectMergePanelError && (
              <EntityTopologyManualPanelPreview
                data={deduplicationProjectMergePanelData}
                entityId={entityId}
                setNewEntityCount={setNewEntityCount}
                setIsPreviewError={setIsPreviewError}
                setIsPreviewLoading={setIsPreviewLoading}
              />
            )}
        </PanelContent>

        <ManualMergePanelFooter
          handleOnCloseWithReset={handleOnCloseWithReset}
          stepperState={stepperState}
          setStepperState={setStepperState}
          data={deduplicationProjectMergePanelData}
          handleBack={handleBack}
          setIsSplitModalOpen={setIsSplitModalOpen}
          isPreviewError={isPreviewError}
          isPreviewLoading={isPreviewLoading}
        />
      </DeduplicationProjectMergePanelWrapper>

      {/* Modal */}
      <EntityTopologySplitModal
        isOpen={isSplitModalOpen}
        onClose={handleSplitModalOnClose}
        onClosePanel={handleOnCloseWithReset}
        newEntityCount={newEntityCount}
        kind={kind}
        entityId={entityId}
        data={deduplicationProjectMergePanelData}
        handleExecuteAction={handleExecuteAction}
      />
    </>
  );
};

export default injectIntl(DeduplicationProjectMergePanel);
