"use strict";

exports.__esModule = true;
exports.ThInnerWrapperUI = void 0;
var _react = _interopRequireWildcard(require("react"));
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _reactDnd = require("react-dnd");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var ThInnerWrapperUI = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: stretch;\n  white-space: nowrap;\n  padding: 0 0 0 16px;\n  width: 100%;\n  width: auto;\n  box-sizing: border-box;\n  ", ";\n  ", ";\n  height: 100%;\n  /* width: calc(100% - 8px); */\n  ", ";\n"])), function (_ref) {
  var resizable = _ref.resizable;
  return resizable ? (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n          padding-right: 8px;\n        "]))) : '';
}, function (_ref2) {
  var reorderable = _ref2.reorderable,
    canDrag = _ref2.canDrag;
  return reorderable && canDrag ? (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n          cursor: ew-resize;\n        "]))) : '';
}, function (_ref3) {
  var dragId = _ref3.dragId;
  return dragId !== 'toString' ? (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n          width: calc(100% - 8px);\n        "]))) : (0, _styledComponents.css)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n          width: calc(100%);\n          max-width: calc(100%);\n          padding: 0;\n        "])));
});

// const ThInnerWrapper = (props) => {
//   const {
//     // isResizing,
//     // onDragTableHeaderStart,
//     // onDropTableHeader,
//     // setColumOrder,
//     index,
//     // dragId,
//     // reorderable,
//     // canDrag,
//   } = props;

//   const ref = useRef(null);

//   const [, drop] = useDrop({
//     accept: 'my-foobar-type',
//     hover: debounce(
//       (item, monitor) => {
//         if (!ref.current || isResizing) {
//           return;
//         }

//         const dragIndex = item.index;
//         const itemDragId = item.dragId;
//         const hoverIndex = index;
//         const itemHoverId = dragId;

//         if (itemDragId === itemHoverId) {
//           return;
//         }

//         const { left, width } = ref.current.getBoundingClientRect();

//         const hoverMiddleX = (width) / 2;
//         const { x = 0 } = monitor.getClientOffset() || {};

//         const isDragingRight = dragIndex < hoverIndex;

//         const hoverClientX = x - left;

//         if (
//           (isDragingRight && hoverClientX > hoverMiddleX)
//         || (hoverClientX < hoverMiddleX)
//         ) {
//           setColumOrder([itemDragId, itemHoverId]);
//           // Note: we're mutating the monitor item here!
//           // Generally it's better to avoid mutations,
//           // but it's good here for the sake of performance
//           // to avoid expensive index searches.
//           // eslint-disable-next-line no-param-reassign
//           item.index = hoverIndex;
//         }
//       },
//       16, { maxWait: 32, leading: true, trailing: true }),
//   });

//   const [, drag] = useDrag({
//     item: {
//       type: 'my-foobar-type',
//       dragId,
//       index,
//       options: {
//         canDrag,
//       },
//     },
//     // eslint-disable-next-line consistent-return
//     begin: (/* monitor */) => {
//       if (!ref.current || isResizing || !canDrag) {
//         // return drop(ref);
//         return;
//       }
//       onDragTableHeaderStart(index, ref);
//     },
//     end: (/* monitor */) => {
//       onDropTableHeader();
//     },
//   });

//   // disable DnD when we resize, and to be sure call drop
//   useEffect(() => {
//     if (isResizing) {
//       drop(ref);
//       return () => {
//         if (reorderable) {
//           drag(drop(ref));
//         }
//       };
//     }
//     if (reorderable) {
//       drag(drop(ref));
//     }
//     return () => {
//       drop(ref);
//     };
//   }, [isResizing]);

//   return (
//     <ThInnerWrapperUI
//       {...props}
//       ref={isResizing || !canDrag ? null : ref}
//     />
//   );
// };

// ThInnerWrapper.displayName = 'ThInnerWrapper';

// export default ThInnerWrapper;
exports.ThInnerWrapperUI = ThInnerWrapperUI;