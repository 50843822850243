import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';
import { ButtonLink } from '@cluedin/atoms';
import { Externallink } from 'uxi/Icons';
import {
  PanelFooter,
  PanelContent,
  IconButton,
  CancelButton,
  PrimaryButton,
} from '@cluedin/form';

import { useGoldenRecordList } from '../GoldenRecordListContext';

const EntityItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralTertiaryAlt};
  padding: 8px 16px;

  &:first-of-type {
    margin-bottom: 30px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const EntityItemContent = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const EntityName = styled.h4`
  margin: 5px 0;
`;

const ExternalLinkWrapper = styled.i`
  margin: 0 0 -2px 4px;
  display: inline-block;
`;

const EntityLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.themePrimary};

  &:hover {
    color: ${({ theme }) => theme.palette.themePrimary};
  }
`;

type Props = {
  onClose: () => void;
  onUnselectFromListIds: (id: string) => void;
};

export const GoldenRecordListMergePanel: FC<Props> = ({
  onClose,
  onUnselectFromListIds,
}) => {
  const theme = useTheme();

  const {
    selectionState: { selected, setSelected },
  } = useGoldenRecordList();

  const [targetId, setTargetId] = useState(selected[0].id);

  const handleUseAsTarget = (id: string) => {
    setTargetId(() => id);
  };

  const handleDelete = (id: string, isTarget = false) => {
    onUnselectFromListIds?.(id);

    setSelected((prev) => {
      const newSelected = prev.filter((i) => i.id !== id);

      if (isTarget) {
        setTargetId(() => newSelected[0].id);
      }

      return newSelected;
    });
  };

  const targetEntity = selected.filter((i) => i.id === targetId)[0];
  const toMerge = selected.filter((i) => i.id !== targetId);

  const previewLink =
    selected.length > 1 &&
    `/entity/merge/${targetId}?ids=${selected.map((i) => i.id).join(',')}`;

  return (
    <>
      <PanelContent style={{ padding: 0 }}>
        <EntityItem key={targetEntity.id} theme={theme}>
          <h3>
            <FormattedMessage id="module-goldenRecordList-entity-as-target" />
          </h3>

          <EntityName>{targetEntity.name}</EntityName>

          <EntityItemContent>
            <EntityLink
              to={`/entity/${targetEntity.id}`}
              target="_blank"
              theme={theme}
            >
              <FormattedMessage id="module-goldenRecordList-view-entity" />

              <ExternalLinkWrapper>
                <Externallink size={10} />
              </ExternalLinkWrapper>
            </EntityLink>

            <IconButton
              onClick={() => handleDelete(targetEntity.id, true)}
              disabled={selected.length < 2}
              iconProps={{ iconName: 'Delete' }}
            />
          </EntityItemContent>
        </EntityItem>

        <div style={{ padding: '0 16px' }}>
          <h3>
            <FormattedMessage id="module-goldenRecordList-entities-to-merge" />
          </h3>

          {selected.length < 2 && (
            <FormattedMessage id="module-goldenRecordList-at-least-one-more-entity-required" />
          )}
        </div>

        {toMerge.map((i) => (
          <EntityItem key={i.id} theme={theme}>
            <EntityName>{i.name}</EntityName>

            <EntityItemContent>
              <div>
                <ButtonLink
                  text={
                    <FormattedMessage id="module-goldenRecordList-use-entity-as-target" />
                  }
                  onClick={() => handleUseAsTarget(i.id)}
                />
                <span style={{ margin: '0 4px' }}>|</span>
                <EntityLink
                  to={`/entity/${i.id}`}
                  target="_blank"
                  theme={theme}
                >
                  <FormattedMessage id="module-goldenRecordList-view-entity" />

                  <ExternalLinkWrapper>
                    <Externallink size={10} />
                  </ExternalLinkWrapper>
                </EntityLink>
              </div>

              <IconButton
                onClick={() => handleDelete(i.id)}
                iconProps={{ iconName: 'Delete' }}
              />
            </EntityItemContent>
          </EntityItem>
        ))}
      </PanelContent>

      <PanelFooter>
        <CancelButton
          rounded
          onClick={onClose}
          style={{ marginRight: '8px' }}
          text={<FormattedMessage id="module-goldenRecordList-merge-cancel" />}
        />
        <PrimaryButton
          rounded
          href={previewLink}
          disabled={selected.length < 2}
          text={<FormattedMessage id="module-goldenRecordList-merge-preview" />}
        />
      </PanelFooter>
    </>
  );
};
