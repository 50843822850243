import clsx from 'clsx';
import DropDown from 'uxi/DropDownv2';
import ButtonMenuItem from 'uxi/Menu/ButtonMenu/ButtonMenuItem';
import styled from 'styled-components';

const BreadCrumbMobileWrapper = styled.div`
  div div button:hover {
    background-color: ${({ theme }) => theme?.palette?.themeLight};
    color: ${({ theme }) => theme?.palette?.black};
  }
`;

import BreadCrumbDropdownMenuItem from './BreadCrumbDropdownMenuItem';
import BreadCrumbSeparator from './BreadCrumbSeparator';
import BreadCrumText from './BreadCrumbText';

const BreadCrumbMobile = ({
  crumbs = [],
  parentLast,
  onNavigate,
  LinkComponent,
  theme,
}) => {
  const onlyShowForParent = crumbs.length === 0 && parentLast;

  return (
    <BreadCrumbMobileWrapper
      theme={theme}
      className={clsx('breadcrumb-item-mobile', {
        'breadcrumb-item-mobile-only': onlyShowForParent,
      })}
    >
      <BreadCrumbSeparator />

      <DropDown
        style={{ margin: '0 6px' }}
        tabIndex={-1}
        anchor={'left'}
        trigger={'...'}
        box={
          <div
            style={{
              background: '#fff',
              textAlign: 'right',
              position: 'fixed',
              border: '1px solid #D4DAD1',
            }}
          >
            {crumbs.map((title, i) => {
              return (
                <ButtonMenuItem
                  key={i}
                  onClick={() => {}}
                  style={{ textAlign: 'right' }}
                >
                  <BreadCrumbDropdownMenuItem onNavigate={onNavigate}>
                    {title.isLoading ? null : (
                      <BreadCrumText
                        LinkComponent={LinkComponent}
                        item={title}
                      />
                    )}
                  </BreadCrumbDropdownMenuItem>
                </ButtonMenuItem>
              );
            })}
            {parentLast && (
              <div className="breadcrumb-item-mobile-only">
                <ButtonMenuItem
                  onClick={() => {}}
                  style={{ textAlign: 'right' }}
                >
                  <BreadCrumbDropdownMenuItem onNavigate={onNavigate}>
                    {parentLast.isLoading ? null : (
                      <BreadCrumText
                        LinkComponent={LinkComponent}
                        item={parentLast}
                      />
                    )}
                  </BreadCrumbDropdownMenuItem>
                </ButtonMenuItem>
              </div>
            )}
          </div>
        }
      />
    </BreadCrumbMobileWrapper>
  );
};

export default BreadCrumbMobile;
