import { connect } from 'react-redux';
import { shouldFetchWidgetConfiguration } from '../../actions';
import { clientId } from '../../../../config';

const mapStateToProps = ({
  wms: { entityConfigurations, isLoadingEntityConfigurations },
}) => ({
  entityConfigurations,
  isLoadingEntityConfigurations,
});

const mapDispatchToProps = (dispatch) => ({
  reloadEntityConfiguration: () => {
    dispatch(shouldFetchWidgetConfiguration(clientId));
  },
});

export const withEntityTypeConfigurationReload = connect(
  mapStateToProps,
  mapDispatchToProps,
);
