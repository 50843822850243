"use strict";

exports.__esModule = true;
exports.default = void 0;
var _client = require("@apollo/client");
var cleanTypeName = new _client.ApolloLink(function (operation, forward) {
  if (operation.variables) {
    var omitTypename = function omitTypename(key, value) {
      return key === '__typename' ? undefined : value;
    };
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map(function (data) {
    return data;
  });
});
var _default = cleanTypeName;
exports.default = _default;