import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const StyledReactToastContainer = styled(ToastContainer)<{
  progressColor: string;
}>`
  .Toastify__progress-bar-theme--light {
    background: ${({ progressColor }) => progressColor};
  }
`;

export const AppReactToastersContainer = () => {
  const {
    palette: { themePrimary },
  } = useTheme();

  return (
    <>
      <StyledReactToastContainer
        autoClose={5000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        progressColor={themePrimary}
        position={toast.POSITION.BOTTOM_RIGHT}
        enableMultiContainer
      />

      <StyledReactToastContainer
        limit={3}
        hideProgressBar
        autoClose={5000}
        enableMultiContainer
        pauseOnHover={false}
        style={{ width: 350 }}
        pauseOnFocusLoss={false}
        containerId="notifications"
        progressColor={themePrimary}
        position={toast.POSITION.BOTTOM_LEFT}
      />

      <StyledReactToastContainer
        autoClose={false}
        closeButton={false}
        closeOnClick={false}
        enableMultiContainer
        pauseOnHover={false}
        containerId="uploader"
        pauseOnFocusLoss={false}
        progressColor={themePrimary}
        position={toast.POSITION.BOTTOM_CENTER}
      />
    </>
  );
};
