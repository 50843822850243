import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_HISTORY_CHANGE_SET = gql`
  query getEntityHistoryChangeSet($id: ID!, $changeSetId: ID!) {
    virtualization {
      entityHistoryChangeSet(id: $id, changeSetId: $changeSetId) {
        property
        changeType
        oldValue
        newValue
        changedPart
      }
    }
  }
`;

export const useQueryEntityHistoryChangeSetById = (id, changeSetId) => {
  const { data, loading, error } = useQuery(GET_ENTITY_HISTORY_CHANGE_SET, {
    variables: { id, changeSetId },
  });

  const history = get(data, 'virtualization.entityHistoryChangeSet');

  return [history, loading, error];
};
