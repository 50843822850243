import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import VocabSelectionStep from './VocabSelectionStep';

const NonCoreVocabSelection = ({ vocabs, onSelectionChange }) => {
  return (
    <VocabSelectionStep
      title={<FormattedMessage id="module-annotation-nonCoreVocabTitle" />}
      vocabs={vocabs}
      onSelectionChange={onSelectionChange}
      noVocabContent={
        <div>
          <FormattedMessage id="module-annotation-nonCoreVocabSelectNoVocabPart1" />
          <br />
          <FormattedMessage id="module-annotation-nonCoreVocabSelectNoVocabPart2" />
        </div>
      }
    />
  );
};

export default NonCoreVocabSelection;
