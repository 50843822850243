import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ORG_SETTINGS = gql`
  query getConfigurationSettings($id: ID!, $includeHiddenFromUi: Boolean) {
    administration {
      id
      configurationSettings(id: $id, includeHiddenFromUi: $includeHiddenFromUi)
    }
  }
`;

const GET_ORG_SETTINGS_FOR_USER = gql`
  query organizationConfigurationSettingsForUser($settingsKeys: [String]) {
    administration {
      id
      organizationConfigurationSettingsForUser(settingsKeys: $settingsKeys)
    }
  }
`;

export const useQueryConfigurationSettings = (
  id,
  includeHiddenFromUi = false,
) => {
  const fetchPolicy = 'no-cache';

  const { data, loading, error, refetch } = useQuery(GET_ORG_SETTINGS, {
    skip: !id,
    fetchPolicy,
    variables: {
      id,
      includeHiddenFromUi,
    },
  });

  const configurationSettings = get(
    data,
    'administration.configurationSettings',
    {},
  );

  return [configurationSettings, loading, error, refetch];
};

export const useQueryConfigurationSettingsForUser = (settingsKeys = []) => {
  const fetchPolicy = 'no-cache';

  const { data, loading, error, refetch } = useQuery(
    GET_ORG_SETTINGS_FOR_USER,
    {
      fetchPolicy,
      variables: {
        settingsKeys,
      },
    },
  );

  const configurationSettings = get(
    data,
    'administration.organizationConfigurationSettingsForUser',
    {},
  );

  return [configurationSettings, loading, error, refetch];
};

export default {
  useQueryConfigurationSettings,
  useQueryConfigurationSettingsForUser,
};
