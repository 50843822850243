import React from 'react';
import { PropTypes } from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import styled from 'styled-components';
import { filterGroupClassName } from './FilterGroup';

const getContentMaxHeight = (filtersCount = 0) =>
  [
    'calc(',
    '100vh',
    '- 48px', // app header
    '- 32px', // spacing
    '- 32px', // spacing
    '- 32px', // left column filter title
    filtersCount ? '- 35px' : '', // account for clear all filter button, if any + 24 magic px
    '- 32px', // footer "Show advanced filter" btn
    // '- 24px', // form ssomewhere ini the universe
    ')',
  ].join(' ');

const WrapperUI = styled.div`
  &,
  & * {
    box-sizing: border-box;
    transition: ${({ theme: { transition } }) => transition.defaultAll};
  }
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 999;
  height: 100%;
  max-height: ${({ filtersCount }) => getContentMaxHeight(filtersCount)};
  transition: ${({ theme: { transition } }) => transition.defaultAll};
  /* any selected */
  & > * {
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    max-height: calc(100% / 4);
    min-height: 24px;
    overflow-y: hidden;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h5 > span:last-child {
      /*  title h5 > meta */
      transform: translateY(-56px);
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
    & > *:last-child {
      overflow: hidden;
      filter: grayscale(0%) opacity(0.75);
    }
    & > *:nth-child(3) {
      flex-grow: 1;
      flex-shrink: 0;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
    &:hover > *:nth-child(3) {
      max-height: 0%;
      height: 100%;
    }
    & > *:nth-child(n + 4) {
      flex-grow: 1;
      flex-shrink: 0;
      height: 100%;
      max-height: 0%;
    }
    &:hover > *:nth-child(4) {
      max-height: 100%;
    }
    &:hover > *:nth-child(5) {
      max-height: 100%;
    }
    & > *:nth-child(4) {
      flex-shrink: 99;
    }
  }

  /* not selected */
  & > *:not(:nth-child(${({ selected }) => selected})) {
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    height: 100%;
    flex-shrink: 999999;
    flex-grow: 0;
    overflow: hidden;
    & > *:not(:hover) {
      filter: grayscale(100%) opacity(0.5);
    }
    cursor: pointer;
    h5 > span:last-child {
      /*  title h5 > meta */
      transform: translateY(0px);
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
  }

  /* selected if any */
  & > *:nth-child(${({ selected }) => selected}) {
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    overflow-y: auto;
    flex-shrink: 0;
    flex-grow: 99999;
    /* max-height: 100vh; */
    max-height: 100%;
    height: calc(100% - calc(4 * 24px));
    filter: grayscale(0%) opacity(1);
    h5 > span:last-child {
      /*  title h5 > meta */
      transform: translateY(0px);
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
    & > *:last-child {
      overflow-y: auto;
      /* max-height: 100vh; */
      max-height: 100%;
      filter: grayscale(0%) opacity(1);
    }
    & > *:nth-child(3) {
      transition: ${({ theme: { transition } }) => transition.defaultAll};
      height: 100%;
      max-height: 0%;
    }
    & > *:nth-child(n + 4) {
      flex-grow: 0;
      flex-shrink: 1;
      height: 100%;
      max-height: 100%;
    }
    & > *:nth-child(4) {
      flex-shrink: 99;
    }
  }
`;

class SearchFiltersLayout extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  constructor(props) {
    super(props);

    this.ref = null;

    this.state = { focused: null };
  }

  onFocusIn = (idx, event = {}) => {
    this.setState({ focused: idx });
    const { currentTarget } = event;
    if (currentTarget) {
      const filterGroup = currentTarget.closest(`.${filterGroupClassName}`);
      const inputMaybe = filterGroup.querySelector('input[type=text]');

      if (
        filterGroup &&
        filterGroup.scrollTop &&
        inputMaybe &&
        inputMaybe.focus
      ) {
        inputMaybe.focus();
        filterGroup.scrollTop = 0;
        setTimeout(() => {
          if (
            filterGroup &&
            filterGroup.scrollTop &&
            inputMaybe &&
            inputMaybe.focus
          ) {
            inputMaybe.focus();
            filterGroup.scrollTop = 0;
          }
        }, 500);
      }
    }
  };

  handleClickOutside = () => {
    this.setState({ focused: null });

    if (this.ref) {
      const filterGroups = this.ref.querySelectorAll(
        `.${filterGroupClassName}`,
      );

      if (filterGroups && filterGroups.length) {
        [...filterGroups].forEach((fg) => {
          fg.scrollTop = 0; // eslint-disable-line no-param-reassign
        });
      }
    }
  };

  render() {
    const { focused } = this.state;
    const { filtersCount } = this.props;

    return (
      <WrapperUI
        filtersCount={filtersCount}
        ref={(node) => {
          this.ref = node;
        }}
        selected={focused !== null ? focused + 1 : null}
      >
        {React.Children.map(this.props.children, (element, idx) => {
          return React.cloneElement(element, {
            onFilterGroupFocusIn: (e) => {
              this.onFocusIn(idx, e);
            },
            onClickOutsideFilterGroup: this.handleClickOutside,
          });
        })}
      </WrapperUI>
    );
  }
}

export default enhanceWithClickOutside(SearchFiltersLayout);
