"use strict";

exports.__esModule = true;
exports.useSorting = void 0;
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _hooks = require("../../../hooks");
var _useResetPageNo = require("./useResetPageNo");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useSorting = function useSorting(history, location, pageNoLabel) {
  if (pageNoLabel === void 0) {
    pageNoLabel = 'pageNo';
  }
  var _useQueryString = (0, _hooks.useQueryString)(history, location, ['sortBy', 'sortDirection']),
    state = _useQueryString[0],
    setState = _useQueryString[1],
    removeState = _useQueryString[2];
  var resetPageNo = (0, _useResetPageNo.useResetPageNo)(history, location, pageNoLabel);
  var setSort = function setSort(key, direction) {
    var newState = {
      sortBy: key,
      sortDirection: direction
    };
    if ((0, _isEqual.default)(state, newState)) {
      removeState();
    } else {
      setState(newState);
    }
    resetPageNo();
  };
  return [state, setSort];
};
exports.useSorting = useSorting;