import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { addTokenToImage } from '../../../entity/helper';
import EntityLinkContainer from '../../../wms/components/containers/EntityLinkContainer';

import { getCurrentToken } from '../../../../config';
import ListItem from '../../../core/components/composites/List/ListItem';

const DomainUserStyle = {
  logoContainer: {
    width: '48px',
    textAlign: 'center',
    height: '48px',
    paddingLeft: '10px',
  },
  logo: {
    width: '100%',
    borderRadius: '2px',
  },
  action: {
    textAlign: 'right',
    paddingRight: '15px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

export default class DomainUser extends Component {
  static propTypes = {
    inviteClick: PropTypes.func.isRequired,
    domainUser: PropTypes.object,
  };

  static defaultProps = {
    domainUser: {},
  };

  invite() {
    const { domainUser, inviteClick } = this.props;
    const email =
      domainUser.Properties.MetadataDictionary['property-user.email'];
    const name = domainUser.Name;

    inviteClick(email, name);
  }

  render() {
    const { domainUser } = this.props;
    let imageProfile;
    let inviteButtonContent;
    const inviteMessage = <FormattedMessage id="DomainUser.AddTeamMember" />;
    const nameStyle = { paddingLeft: '15px' };

    if (
      domainUser.Properties &&
      domainUser.Properties.MetadataDictionary &&
      domainUser.Properties.MetadataDictionary['property-user.email']
    ) {
      inviteButtonContent = (
        <div style={DomainUserStyle.action}>
          <PrimaryButton
            onClick={this.invite.bind(this)}
            text={inviteMessage}
          />
        </div>
      );
    }

    if (domainUser.PreviewUrl) {
      const imageUrl = addTokenToImage(
        domainUser.PreviewUrl,
        getCurrentToken(),
      );

      imageProfile = (
        <div style={DomainUserStyle.logoContainer}>
          <EntityLinkContainer
            id={domainUser.Id}
            entityType={domainUser.EntityType}
          >
            <img
              alt={domainUser.Name}
              style={DomainUserStyle.logo}
              src={imageUrl}
            />
          </EntityLinkContainer>
        </div>
      );
    }

    const content = (
      <div style={{ flex: 1 }}>
        <EntityLinkContainer
          id={domainUser.Id}
          entityType={domainUser.EntityType}
        >
          <strong style={nameStyle}>{domainUser.Name}</strong>
        </EntityLinkContainer>
      </div>
    );

    return (
      <ListItem className="test_Administration_User_SuggestedUserItem">
        <div style={{ display: 'flex', lineHeight: '48px' }}>
          {imageProfile}
          {content}
          {inviteButtonContent}
        </div>
      </ListItem>
    );
  }
}
