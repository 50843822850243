import { useQuery, gql } from '@apollo/client';
import get from 'lodash/get';

export const GET_ALL_VOCABULARIES = gql`
  query getAllVocabularies(
    $searchName: String
    $isActive: Boolean
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: String
    $entityType: String
    $connectorId: ID
    $filterTypes: Int
    $filterHasNoSource: Boolean
  ) {
    management {
      id
      vocabularies(
        searchName: $searchName
        isActive: $isActive
        pageNumber: $pageNumber
        pageSize: $pageSize
        sortBy: $sortBy
        sortDirection: $sortDirection
        entityType: $entityType
        connectorId: $connectorId
        filterTypes: $filterTypes
        filterHasNoSource: $filterHasNoSource
      ) {
        total
        data {
          vocabularyId
          vocabularyName
          keyPrefix
          isCluedInCore
          isDynamic
          isProvider
          isActive
          grouping
          createdAt
          connector {
            id
            name
            about
            icon
          }
        }
      }
    }
  }
`;

export const useVocabularies = (initialVar = {}, withCaching) => {
  const { data, error, loading, refetch, networkStatus } = useQuery(
    GET_ALL_VOCABULARIES,
    {
      fetchPolicy: withCaching ? 'cache-first' : 'no-cache',
      variables: {
        ...initialVar,
        filterIsObsolete: 'All',
      },
    },
  );

  const pagedVocabularies = get(data, 'management.vocabularies', {
    total: 0,
    data: [],
  });

  return [
    pagedVocabularies,
    loading,
    error,
    networkStatus === 4,
    (params) => {
      refetch(
        params ?? {
          ...initialVar,
          filterIsObsolete: 'All',
        },
      );
    },
  ];
};

export default {
  useVocabularies,
};
