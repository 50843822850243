import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlatButton } from 'uxi/Button';
import { Popover } from './Popover';
import { GoToPageForm } from './GoToPageForm';

export const Button = styled(FlatButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

const Wrapper = styled.div`
  display: inline-block;
`;

export const EllipsisButton = ({
  show = true,
  onPageChange,
  totalPages,
  currentPage,
}) => {
  const [state, setState] = useState({ showGotoPage: false, currentPage });

  if (!show) {
    return null;
  }

  if (currentPage !== state.currentPage) {
    // Page has changed, close popover
    setState({ showGotoPage: false, currentPage });
  }

  const { showGotoPage } = state;
  return (
    <Wrapper>
      {showGotoPage && (
        <Popover>
          <GoToPageForm totalPages={totalPages} onPageChange={onPageChange} />
        </Popover>
      )}
      <Button
        message="..."
        onClick={() => setState({ showGotoPage: !showGotoPage, currentPage })}
      />
    </Wrapper>
  );
};

EllipsisButton.propTypes = {
  show: PropTypes.bool,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

EllipsisButton.defaultProps = {
  show: true,
};
