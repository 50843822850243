"use strict";

exports.__esModule = true;
exports.defaultLocales = exports.LocalizationContext = void 0;
var _FormattedMessage = _interopRequireDefault(require("./FormattedMessage"));
exports.FormattedMessage = _FormattedMessage.default;
var _reactIntl = require("react-intl");
exports.injectIntl = _reactIntl.injectIntl;
exports.defineMessages = _reactIntl.defineMessages;
exports.FormattedHTMLMessage = _reactIntl.FormattedHTMLMessage;
var _LocalizationContext = require("./LocalizationContext");
exports.LocalizationContext = _LocalizationContext.LocalizationContext;
exports.defaultLocales = _LocalizationContext.defaultLocales;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }