import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import CreateOrgAndLoginForm from '../composites/CreateOrgAndLoginForm';
import { shouldCreateOrgAndUser } from '../../actions/createOrgAndUser';
import { url } from '../../../../config';
import {
  withSuccess,
  withIsFetching,
} from '../../../core/components/Hocs/defaults';

const EnhancedCreateOrgAndLogin = compose(
  lifecycle({
    componentWillUpdate(nextProps) {
      const { onRedirectOnSaveUrlSuccess } = this.props;

      const { isDone, orgName } = nextProps;
      if (isDone) {
        const redirect = `https://${orgName}.${url.mainDomain}`;
        onRedirectOnSaveUrlSuccess(redirect);
      }
    },
  }),
  withIsFetching(),
  withSuccess({
    SuccessMessage: (
      <div className="test_createOrgAndUser_success">
        <FormattedMessage id="module-public-createOrgAndUser-success" />
      </div>
    ),
  }),
)(CreateOrgAndLoginForm);

export const mapToStateProps = (
  { createOrgAndUser: { error, done, isFetching } },
  { id, email },
) => ({
  id,
  error,
  done,
  isFetching: false,
  isMutating: isFetching,
  email,
});

export const mapDispatchToProps = (dispatch, { id }) => ({
  onSignup: (values) => {
    dispatch(
      shouldCreateOrgAndUser({
        ...values,
        username: values.email,
        code: id,
      }),
    );
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(EnhancedCreateOrgAndLogin);
