"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var TriggererWrapperWithEllispsisChildren = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  min-height: 34px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  /* background: white; */\n  /* border: 1px solid #cecece; */\n  border-radius: ", ";\n  ", "\n\n  * {\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    color: #222222 !important;\n  }\n"])), function (_ref) {
  var radius = _ref.theme.radius;
  return radius;
}, function (_ref2) {
  var disabled = _ref2.disabled;
  if (disabled) {
    return "\n        & > *:first-child {\n          opacity: 0.8 !important;\n          background: #fafafa;\n        }\n        & > *:last-child {\n          opacity: 0.8 !important;\n        }\n      ";
  }
  return "\n    ";
});
var _default = TriggererWrapperWithEllispsisChildren;
exports.default = _default;