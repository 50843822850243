import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import { Panel, PanelHeader, PanelContent } from '@cluedin/form';

import EntityPropertiesHistoryLatest from '../EntityPropertiesHistoryLatest/EntityPropertiesHistoryLatest';
import EntityPropertiesHistoryFull from '../EntityPropertiesHistoryFull/EntityPropertiesHistoryFull';
import { EntityPropertiesHistoryPanelWrapper } from './EntityPropertiesHistoryPanel.styles';

const EntityPropertiesHistoryPanel = ({
  setIsExpanded,
  entityVocabularyKey,
  isHistoryPanelExpanded,
  setIsHistoryPanelExpanded,
  vocabularyKeyDisplayName,
  vocabularyKey,
  value,
  filteredHistoryChangesToShow,
  SourceIcon,
  historyLoading,
}) => {
  return (
    <Panel
      open={isHistoryPanelExpanded}
      onClose={() => setIsHistoryPanelExpanded(false)}
      withCustomHeader
      panelType="medium"
    >
      <PanelHeader
        style={{
          fontSize: '16px',
          padding: '0 16px',
        }}
        hasClose
        title={
          <>
            <FormattedMessage id="module-entityProperties-panelHistoryProperty" />
            &nbsp;{vocabularyKeyDisplayName}&nbsp;
            <FormattedMessage id="module-entityProperties-panelHistoryPropertySecond" />
          </>
        }
        onClose={() => {
          setIsExpanded(false);
          setIsHistoryPanelExpanded(false);
        }}
      />
      <PanelContent>
        <EntityPropertiesHistoryPanelWrapper>
          <EntityPropertiesHistoryLatest
            entityVocabularyKey={entityVocabularyKey}
            vocabularyKey={vocabularyKey}
            value={value}
            SourceIcon={SourceIcon}
          />

          <EntityPropertiesHistoryFull
            filteredHistoryChangesToShow={filteredHistoryChangesToShow}
            historyLoading={historyLoading}
          />
        </EntityPropertiesHistoryPanelWrapper>
      </PanelContent>
    </Panel>
  );
};

export default EntityPropertiesHistoryPanel;

EntityPropertiesHistoryPanel.propTypes = {
  entityVocabularyKey: PropTypes.string,
  filteredHistoryChangesToShow: PropTypes.array,
  setIsExpanded: PropTypes.func,
  isHistoryPanelExpanded: PropTypes.bool,
  setIsHistoryPanelExpanded: PropTypes.func,
  value: PropTypes.string,
  vocabularyKeyDisplayName: PropTypes.string,
  vocabularyKey: PropTypes.object,
  SourceIcon: PropTypes.node,
};

EntityPropertiesHistoryPanel.defaultProps = {
  entityVocabularyKey: null,
  filteredHistoryChangesToShow: [],
  setIsExpanded: () => {},
  isHistoryPanelExpanded: false,
  setIsHistoryPanelExpanded: () => {},
  value: '',
  vocabularyKeyDisplayName: '',
  vocabularyKey: {},
  SourceIcon: null,
};
