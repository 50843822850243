import { handleActions } from 'redux-actions';
import {
  hasStartAsLastCharacter,
  removeLastCharacter,
} from '../core/helpers/string';

import {
  unFollowAction,
  followAction,
  allFollowingActions,
  allSavedSearchesActions,
  resetSearchCountActions,
  subscribeForSearchActions,
  unsubscribeForSearchActions,
  resetFollowingCountActions,
  resetSearchNotificationAction,
  newRealTimeSavedSearchInformationAction,
  removeSavedSearchFromRealTimeAction,
  clearSaveSearchFromRealTimeAction,
  addAllNewSaveSearchFromRealTimeAction,
  newRealTimeFollowInformationAction,
  removeFollowFromRealTimeAction,
  clearFollowFromRealTimeAction,
  addNewFollowFromRealTimeWithEntityAction,
  resetFollowNotificationAction,
} from './actions';

const formatSearchQuery = (str) =>
  hasStartAsLastCharacter(str) ? removeLastCharacter(str) : str;

const initialState = {
  followedEntities: [],
  isFetchingFollwedEntities: false,
  invalidFollowedEntities: false,
  saveSearches: [],
  isFetchingSaveSearches: false,
  invalidSavedSearches: false,
  showNotificationForFollowing: false,
  showNotificationForSearch: false,
  allSavedSearchRequest: {},
  searchesNotifications: [],
  followNotifications: [],
};

const getEntityType = (entity) => {
  if (!entity) {
    return null;
  }

  if (entity.data) {
    return entity.data.entityType;
  }

  if (entity.entity) {
    return entity.entity.entityType;
  }

  return entity.entityType;
};

const resetCountForFollowing = (followingEntities = [], payload) =>
  followingEntities.map((f) => {
    if (f.Entity.EntityId === payload) {
      return {
        ...f,
        Count: 0,
      };
    }

    return f;
  });

export default handleActions(
  {
    [followAction]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        {
          Count: 0,
          Entities: [],
          Entity: {
            Count: 0,
            EntityId: payload.id,
            EntityName: payload.name,
            EntityType: getEntityType(payload),
          },
        },
        ...state.followedEntities,
      ],
    }),
    [unFollowAction]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        ...(state.followedEntities || []).filter(
          (f) => f.Entity.EntityId !== payload.id,
        ),
      ],
    }),
    [allFollowingActions.request]: (state) => ({
      ...state,
      followedEntities: [],
      isFetchingFollwedEntities: false,
      invalidFollowedEntities: false,
    }),
    [allFollowingActions.receive]: (state, { payload }) => ({
      ...state,
      followedEntities: payload,
      isFetchingFollwedEntities: false,
      invalidFollowedEntities: false,
    }),
    [allFollowingActions.invalid]: (state) => ({
      ...state,
      followedEntities: [],
      isFetchingFollwedEntities: false,
      invalidFollowedEntities: true,
    }),
    [allSavedSearchesActions.request]: (state) => ({
      ...state,
      isFetchingSaveSearches: true,
      saveSearches: [],
      invalidSavedSearches: false,
    }),
    [allSavedSearchesActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingSaveSearches: false,
      saveSearches: payload,
      invalidSavedSearches: false,
    }),
    [allSavedSearchesActions.invalid]: (state) => ({
      ...state,
      isFetchingSaveSearches: false,
      saveSearches: [],
      invalidSavedSearches: true,
    }),
    [subscribeForSearchActions.request]: (state, { payload }) => ({
      ...state,
      allSavedSearchRequest: {
        ...state.allSavedSearchRequest,
        [formatSearchQuery(payload)]: {
          isFetching: true,
          invalid: false,
          done: false,
          type: 'subscribe',
        },
      },
    }),
    [subscribeForSearchActions.receive]: (state, { payload }) => ({
      ...state,
      saveSearches: [
        {
          Query: payload,
          Count: 0,
          Name: formatSearchQuery(payload),
        },
        ...state.saveSearches,
      ],
      allSavedSearchRequest: {
        ...state.allSavedSearchRequest,
        [formatSearchQuery(payload)]: {
          isFetching: true,
          invalid: false,
          done: false,
          type: 'subscribe',
        },
      },
      showNotificationForSearch: true,
    }),
    [subscribeForSearchActions.invalid]: (state, { payload }) => ({
      ...state,
      allSavedSearchRequest: {
        ...state.allSavedSearchRequest,
        [formatSearchQuery(payload)]: {
          isFetching: false,
          invalid: true,
          done: false,
          type: 'subscribe',
        },
      },
    }),
    [resetSearchCountActions.request]: (state) => ({
      ...state,
      showNotificationForFollowing: false,
    }),
    [resetSearchCountActions.receive]: (state) => ({
      ...state,
    }),
    [resetSearchCountActions.invalid]: (state) => ({
      ...state,
    }),
    [unsubscribeForSearchActions.request]: (state, { payload }) => ({
      ...state,
      allSavedSearchRequest: {
        ...state.allSavedSearchRequest,
        [formatSearchQuery(payload)]: {
          isFetching: true,
          invalid: false,
          done: false,
          type: 'unsubscribe',
        },
      },
    }),
    [unsubscribeForSearchActions.receive]: (state, { payload }) => ({
      ...state,
      saveSearches: [
        ...(state.saveSearches || []).filter(
          (savedSearch) =>
            savedSearch.Query.toLowerCase() !== payload.toLowerCase(),
        ),
      ],
      allSavedSearchRequest: {
        ...state.allSavedSearchRequest,
        [formatSearchQuery(payload)]: {
          isFetching: false,
          invalid: false,
          done: true,
          type: 'unsubscribe',
        },
      },
    }),
    [unsubscribeForSearchActions.invalid]: (state, { payload }) => ({
      ...state,
      allSavedSearchRequest: {
        ...state.allSavedSearchRequest,
        [formatSearchQuery(payload)]: {
          isFetching: false,
          invalid: true,
          done: false,
          type: 'unsubscribe',
        },
      },
    }),
    [resetFollowingCountActions.receive]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        ...resetCountForFollowing(state.followedEntities || [], payload),
      ],
      showNotificationForFollowing: false,
    }),
    [resetSearchNotificationAction]: (state, payload) => ({
      ...state,
      searchesNotifications: [
        ...(state.searchesNotifications || []).map((s) => {
          if (s.query.toLowerCase() === payload.query.toLowerCase()) {
            return {
              ...s,
              show: false,
            };
          }
          return s;
        }),
      ],
    }),
    [removeSavedSearchFromRealTimeAction]: (state, payload) => ({
      ...state,
      saveSearches: [
        ...(state.saveSearches || []).filter((s) => s.Query !== payload.search),
      ],
    }),
    [newRealTimeSavedSearchInformationAction]: (state, { payload }) => ({
      ...state,
      saveSearches: [
        ...(state.saveSearches || []).map((i) => {
          if (payload.query.toLowerCase() === i.Query.toLowerCase()) {
            return {
              ...i,
              Count: payload.count,
            };
          }

          return i;
        }),
      ],
      showNotificationForSearch: true,
      searchesNotifications: [
        ...state.searchesNotifications,
        {
          count: payload.count,
          query: payload.query,
          show: true,
        },
      ],
    }),
    [clearSaveSearchFromRealTimeAction]: (state, { payload }) => ({
      ...state,
      saveSearches: [
        ...(state.saveSearches || []).map((s) => {
          if (s.Query.toLowerCase() === payload.search.toLowerCase()) {
            return {
              ...s,
              Count: 0,
            };
          }

          return s;
        }),
      ],
    }),
    [addAllNewSaveSearchFromRealTimeAction]: (state, { payload }) => ({
      ...state,
      searches: [
        {
          Query: payload.q,
          Count: payload.count,
          Name: hasStartAsLastCharacter(payload.search)
            ? removeLastCharacter(payload.search)
            : payload.search,
        },
        ...state.searches,
      ],
      showNotification: true,
    }),
    [newRealTimeFollowInformationAction]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        ...(state.saveSearches || []).map((insight) => {
          if (
            payload.entity.id.toLowerCase() ===
            insight.Entity.EntityId.toLowerCase()
          ) {
            return {
              ...insight,
              Count: payload.count,
            };
          }

          return insight;
        }),
      ],
      followNotifications: [
        ...state.searchesNotifications,
        {
          show: true,
          entity: payload.entity,
          count: payload.count,
        },
      ],
    }),
    [resetFollowNotificationAction]: (state, { payload }) => ({
      followNotifications: [
        ...(state.followNotifications || []).map((s) => {
          if (s.entity.id === payload.entityId) {
            return {
              ...s,
              show: false,
            };
          }
          return s;
        }),
      ],
    }),
    [clearFollowFromRealTimeAction]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        ...(state.followedEntities || []).map((insight) => {
          if (insight.Entity.EntityId.toLowerCase() === payload.toLowerCase()) {
            return {
              ...insight,
              Count: 0,
            };
          }

          return insight;
        }),
      ],
    }),
    [removeFollowFromRealTimeAction]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        ...(state.followedEntities || []).filter(
          (s) => s.Entity.EntityId !== payload,
        ),
      ],
    }),
    [addNewFollowFromRealTimeWithEntityAction]: (state, { payload }) => ({
      ...state,
      followedEntities: [
        {
          Count: payload.count || 0,
          Entities: [],
          Entity: {
            Count: payload.count || 0,
            EntityId: payload.entity.id,
            EntityName: payload.entity.name,
            EntityType: getEntityType(payload),
          },
        },
        ...state.followedEntities,
      ],
      showNotificationForFollowing: true,
    }),
  },
  initialState,
);
