import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { PrimaryButton, Button } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';

import {
  EntityActionMessageItemsDetailsHeaderWrapper,
  EntityActionMessageItemsDetailsHeaderInfoWrapper,
  EntityActionMessageItemsDetailsHeaderInfo,
  EntityActionMessageItemDetailsHeaderBadge,
  EntityActionMessageItemDetailsHeaderDisplayName,
  EntityActionMessageItemDetailsHeaderValue,
  EntityActionMessageItemDetailsHeaderButtonWrapper,
} from './EntityActionMessageItemsDetailsHeader.styles';

const EntityActionMessageItemsDetailsHeader = ({
  actionType,
  approveLoading,
  approveMeshCommand,
  isExpanded,
  setIsExpanded,
  property,
  rejectLoading,
  rejectMeshCommand,
}) => {
  const displayName = get(property, 'displayName');
  const value = get(property, 'value');
  const actionTypeToDisplay = actionType.toLowerCase();
  const theme = useTheme();

  return (
    <EntityActionMessageItemsDetailsHeaderWrapper
      theme={theme}
      isExpanded={isExpanded}
    >
      {isExpanded ? (
        <Arrowdown
          color={theme.palette.themePrimary}
          size={16}
          onClick={() => setIsExpanded(!isExpanded)}
          data-test={'entity_pendingChanges_expandArrowDown'}
        />
      ) : (
        <Arrowright
          color={theme.palette.themePrimary}
          size={16}
          onClick={() => setIsExpanded(!isExpanded)}
          data-test={'entity_pendingChanges_expandArrowRight'}
        />
      )}

      <EntityActionMessageItemsDetailsHeaderInfoWrapper>
        <EntityActionMessageItemsDetailsHeaderInfo
          onClick={() => setIsExpanded(!isExpanded)}
          data-test={'entity_pendingChanges_headerToExpand'}
        >
          <EntityActionMessageItemDetailsHeaderDisplayName>
            {displayName}:
          </EntityActionMessageItemDetailsHeaderDisplayName>
          <EntityActionMessageItemDetailsHeaderValue>
            {value ? (
              value
            ) : (
              <em>
                <FormattedMessage id="module-pendingChanges-headerValue" />
              </em>
            )}
          </EntityActionMessageItemDetailsHeaderValue>

          <EntityActionMessageItemDetailsHeaderBadge
            theme={theme}
            actionType={actionTypeToDisplay}
          >
            {actionTypeToDisplay}
          </EntityActionMessageItemDetailsHeaderBadge>
        </EntityActionMessageItemsDetailsHeaderInfo>

        <EntityActionMessageItemDetailsHeaderButtonWrapper>
          <PrimaryButton
            data-test={'entity_pendingChanges_approveButton'}
            disabled={approveLoading || rejectLoading}
            onClick={approveMeshCommand}
            text={
              <FormattedMessage id="module-pendingChanges-headerApproveButton" />
            }
          />
          <Button
            style={{ marginLeft: '12px' }}
            data-test={'entity_pendingChanges_rejectButton'}
            disabled={rejectLoading || approveLoading}
            onClick={rejectMeshCommand}
            text={
              <FormattedMessage id="module-pendingChanges-headerRejectButton" />
            }
          />
        </EntityActionMessageItemDetailsHeaderButtonWrapper>
      </EntityActionMessageItemsDetailsHeaderInfoWrapper>
    </EntityActionMessageItemsDetailsHeaderWrapper>
  );
};

export default EntityActionMessageItemsDetailsHeader;

EntityActionMessageItemsDetailsHeader.propTypes = {
  actionType: PropTypes.string,
  approveLoading: PropTypes.bool,
  approveMeshCommand: PropTypes.func,
  isExpanded: PropTypes.bool,
  property: PropTypes.object,
  setIsExpanded: PropTypes.func,
  rejectLoading: PropTypes.bool,
  rejectMeshCommand: PropTypes.func,
};

EntityActionMessageItemsDetailsHeader.defaultProps = {
  actionType: '',
  approveLoading: false,
  approveMeshCommand: () => {},
  isExpanded: false,
  property: {},
  setIsExpanded: () => {},
  rejectLoading: false,
  rejectMeshCommand: () => {},
};
