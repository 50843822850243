import React from 'react';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import EntityPropertiesBooleanInput from '../EntityPropertiesBooleanInput/EntityPropertiesBooleanInput';
import EntityPropertiesDateInput from '../EntityPropertiesDateInput/EntityPropertiesDateInput';
import EntityPropertiesLookupInput from '../EntityPropertiesLookupInput/EntityPropertiesLookupInput';
import { OnlyExistingValuesSelect } from '../../../../../../shared/components/OnlyExistingValuesSelect';

const EntityPropertiesInputToUse = ({
  dataType,
  name,
  setEntityInputValue,
  vocabularyValue,
  vocabularyKey,
  entityInputValue,
}) => {
  if (dataType === 'Boolean') {
    return (
      <EntityPropertiesBooleanInput
        name={name}
        setEntityInputValue={setEntityInputValue}
      />
    );
  }

  if (dataType === 'DateTime' || dataType === 'Date') {
    return (
      <EntityPropertiesDateInput
        setEntityInputValue={setEntityInputValue}
        vocabularyValue={vocabularyValue}
      />
    );
  }
  if (dataType === 'Lookup') {
    return (
      <EntityPropertiesLookupInput
        setEntityInputValue={setEntityInputValue}
        vocabularyValue={vocabularyValue}
        vocabularyKey={vocabularyKey}
      />
    );
  }

  return (
    <OnlyExistingValuesSelect
      value={entityInputValue}
      defaultValue={vocabularyValue}
      onChange={setEntityInputValue}
      vocabularyKey={vocabularyKey.key}
      isCreatable={true}
    />
  );
};

export default EntityPropertiesInputToUse;

EntityPropertiesInputToUse.propTypes = {
  dataType: PropTypes.any,
  name: PropTypes.string,
  setEntityInputValue: PropTypes.func,
  vocabularyValue: PropTypes.string,
};

EntityPropertiesInputToUse.defaultProps = {
  dataType: null,
  name: null,
  setEntityInputValue: () => {},
  vocabularyValue: null,
};
