import GdprReportContainer from './components/containers/GdprReportContainer';
import GdprJsonReportPage from './components/pages/GdprJsonReportPage';
// import ProtectedFeatureHOC from '../core/components/Hocs/ProtectedFeatureHOC';

// import { checkIfAuthenticatedWithToken } from '../core/components/Hocs/Routing';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';

// TODO: move to HOC - onEnter={checkIfAuthenticatedWithToken}

const reportRoutes = {
  path: '/admin/gdpr/gdprReport/:id',
  exact: true,
  component: OnlyClientIdSubDomain(GdprJsonReportPage),
  routes: [
    {
      exact: true,
      path: '/',
      component: GdprReportContainer,
    },
  ],
};

export default reportRoutes;
