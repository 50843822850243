import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import HierarchyIcon from './components/composites/HierarchyIcon';

const { management } = actionsConfiguration;

export default {
  pillar: 'management',
  name: 'hierarchies',
  displayName: <FormattedMessage id="hierarchies" />,
  path: '/hierarchy',
  featureFlag: 'manualHierarchy',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: HierarchyIcon,
        name: <FormattedMessage id="hierarchies" />,
        link: '/admin/management/hierarchy',
        claims: management.hierarchies.view,
      },
    ],
  },
  routes,
};
