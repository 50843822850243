import { useCallback, FormEvent, memo, FC } from 'react';
import { useTheme } from '@cluedin/theme';
import {
  Dropdown,
  ICalloutProps,
  IDropdownStyles,
  IDropdownOption,
  DropdownMenuItemType,
} from '@fluentui/react';

import { useAppSearch } from '../context/useAppSearch';
import { AppSearchScopes, AppSearchScope } from '../AppSearch.types';

const calloutStyles: Partial<ICalloutProps> = {
  styles: {
    root: {
      width: '250px',
    },
  },
};

const options: {
  text: string;
  itemType?: DropdownMenuItemType.Header;
  key: AppSearchScope | 'Entity types';
}[] = [
  {
    key: 'Entity types',
    text: 'Entity Types',
    itemType: DropdownMenuItemType.Header,
  },
  { key: 'all', text: 'All' },
  { key: 'person', text: 'Person' },
  { key: 'organization', text: 'Organization' },
];

type Props = {
  scopeDropdownChangedEvent: () => void;
};

export const AppSearchScopeDropdown: FC<Props> = memo(
  ({ scopeDropdownChangedEvent }) => {
    const theme = useTheme();
    const { scope, handleChangeScope } = useAppSearch();

    const getStyles = useCallback(
      (): Partial<IDropdownStyles> => ({
        dropdown: {
          width: 120,
          ':focus': {
            '::after': {
              border: 'none',
            },
          },
        },
        title: {
          border: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          color: theme.palette.neutralDark,
          backgroundColor: theme.palette.neutralLighter,
          ':hover': {
            backgroundColor: theme.palette.neutralLight,
          },
        },
        dropdownItemHeader: { color: theme.palette.themePrimary },
      }),
      [theme],
    );

    const handleChangeScopeDropdown = useCallback(
      (_: FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
        const key =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          AppSearchScopes[option!.key as AppSearchScope] ?? AppSearchScopes.all;

        handleChangeScope(key);

        scopeDropdownChangedEvent();
      },
      [],
    );

    return (
      <Dropdown
        options={options}
        selectedKey={scope}
        styles={getStyles()}
        calloutProps={calloutStyles}
        onChange={handleChangeScopeDropdown}
      />
    );
  },
);
