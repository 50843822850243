import {
  authWithoutCreds,
  authRequest,
  authRequestFormData,
} from '../core/helpers/request';
import { clientId as currentClientId } from '../../config';

export const getTeamDomain = (clientId) =>
  authRequest(
    'GET',
    `api/account/available?clientId=${clientId || currentClientId}`,
  );

/**
 * @deprecated since forever. Use `refreshToken`
 */
export const login = ({ email, password, clientId }) =>
  authRequestFormData('POST', 'api/connect/token', {
    grant_type: 'password',
    username: email,
    password,
    client_id: clientId,
  });

export const refreshToken = (token) =>
  authWithoutCreds('POST', 'connect/token', {
    grant_type: 'refresh_token',
    refresh_token: token,
    client_id: currentClientId,
  });
