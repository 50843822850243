import React, { useState } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Arrowdown from 'uxi/Icons/Arrowdown';
import Arrowup from 'uxi/Icons/Arrowup';

const Legend = ({ canvas, position }) => {
  const [showLegend, setShowLegend] = useState(false);

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1,
        top: position?.top,
        bottom: position?.bottom,
        right: position?.right,
      }}
    >
      <a
        style={{
          display: 'inline-block',
          marginBottom: 10,
          width: position?.width || 320,
          textAlign: 'right',
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShowLegend(!showLegend);
        }}
      >
        {showLegend ? (
          <>
            <FormattedMessage id="Topology.hide" />
            &nbsp;
            <Arrowup size={12} />
          </>
        ) : (
          <>
            <FormattedMessage id="Topology.show" />
            &nbsp;
            <Arrowdown size={12} />
          </>
        )}
      </a>
      <div
        style={{
          display: showLegend ? 'block' : 'none',
          width: 300,
          height: showLegend ? position?.height || 320 : 75,
          marginLeft: position?.marginLeft || 22,
          opacity: showLegend ? 0.8 : 0,
          transition: 'all 0.5s',
        }}
      >
        <div width={300} height={300} ref={canvas}></div>
      </div>
    </div>
  );
};

export default Legend;
