import React from 'react';
import { connect } from 'react-redux';
import { generateFormHoc } from 'uxi-business';
import { Flex } from 'uxi/Layout/Flex';
import { TextField } from 'uxi/Input';
import { FormattedMessage, injectIntl } from '@cluedin/locale';

import { required } from '../../../core/helpers/form';

const EmailWithEmailDomainInput = (props) => {
  const { teamDomain, intl } = props;
  return (
    <Flex style={{ justifyContent: 'flex-start', width: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <TextField
          isFullWidth
          placeholder={intl.formatMessage({
            id: 'module-public-emailAddressPlaceholder',
          })}
          {...props}
        />
      </div>
      <Flex>@{teamDomain.EmailDomainName}</Flex>
    </Flex>
  );
};

const mapStateToProps = ({ publicModule }) => ({
  teamDomain: publicModule.result,
});

const ConnectedEmailWithEmailDomainInput = connect(mapStateToProps)(
  injectIntl(EmailWithEmailDomainInput),
);

// brilliant
const SignUpUserForm = generateFormHoc(
  'CreateUserAccountForm',
  [
    {
      name: 'email',
      label: <FormattedMessage id="module-public-email" />,
      validate: [required],
      // sufix: '@emailDomain',
      Input: ConnectedEmailWithEmailDomainInput,
    },
  ],
  {
    asyncValidate: () => Promise.resolve(),
    asyncBlurFields: ['email'],
    buttonLabel: <FormattedMessage id="module-public-signup" />,
  },
);

const SignUpUserFromEmailDomainForm = ({
  isMutating,
  onSignUpOrgEmail,
  teamDomain,
}) => {
  return (
    <div>
      <SignUpUserForm
        isFetching={isMutating}
        onClick={({ email }) => {
          onSignUpOrgEmail({
            email: `${email}@${teamDomain.EmailDomainName}`,
          });
        }}
      />
    </div>
  );
};

export default SignUpUserFromEmailDomainForm;
