import * as d3 from 'd3';

import { UpdatedGraphNetworkEdge, UpdatedGraphNetworkNode } from '../types';

export const positionNodesAndLinks = (
  simulation: d3.Simulation<UpdatedGraphNetworkNode, UpdatedGraphNetworkEdge>,
  nodeSvgGroup: d3.Selection<
    SVGGElement,
    UpdatedGraphNetworkNode,
    SVGSVGElement | null,
    unknown
  >,
  allLinkSvgElements: d3.Selection<
    SVGGElement,
    UpdatedGraphNetworkEdge,
    SVGElement,
    unknown
  >,
) => {
  simulation.on('tick', () => {
    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__body-entity')
      .attr('x', (node) => node.x - 40)
      .attr('y', (node) => node.y - 40);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__body-shadowEntity',
      )
      .attr('x', (node) => node.x - 40)
      .attr('y', (node) => node.y - 40);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__body-relatedEntityNode',
      )
      .attr('x', (node) => node.x - 40)
      .attr('y', (node) => node.y - 40);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__border-left')
      .attr('x', (node) => node.x - 42)
      .attr('y', (node) => node.y - 41);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__border-left-shadowEntity',
      )
      .attr('x', (node) => node.x - 42)
      .attr('y', (node) => node.y - 41);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__border-left-relatedEntityNode',
      )
      .attr('x', (node) => node.x - 42)
      .attr('y', (node) => node.y - 41);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__icon')
      .attr('cx', (node) => node.x - 5)
      .attr('cy', (node) => node.y);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__icon-entity')
      .attr('cx', (node) => node.x - 5)
      .attr('cy', (node) => node.y);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__icon-relatedEntity',
      )
      .attr('cx', (node) => node.x - 5)
      .attr('cy', (node) => node.y);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__icon-groupedRelations',
      )
      .attr('cx', (node) => node.x - 5)
      .attr('cy', (node) => node.y);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__icon-shadowEntity',
      )
      .attr('cx', (node) => node.x - 5)
      .attr('cy', (node) => node.y);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__icon-svg')
      .attr('x', (node) => node.x - 25)
      .attr('y', (node) => node.y - 20);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__icon-previewImage',
      )
      .attr('x', (node) => node.x - 25)
      .attr('y', (node) => node.y - 20);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__icon-entity-svg')
      .attr('x', (node) => node.x - 16)
      .attr('y', (node) => node.y - 11);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__icon-relatedEntity-svg',
      )
      .attr('x', (node) => node.x - 16)
      .attr('y', (node) => node.y - 11);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__icon-groupedRelations-svg',
      )
      .attr('x', (node) => node.x - 25)
      .attr('y', (node) => node.y - 20);

    // Expansion disabled for V1
    // nodeSvgGroup
    //   .selectAll('.node__icon-expandNodeArrow')
    //   .attr('x', (node: any) => node.x + 170)
    //   .attr('y', (node: any) => node.y - 30);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__title')
      .attr('x', (node) => node.x + 25)
      .attr('y', (node) => node.y - 8);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__title-relatedEntityNode',
      )
      .attr('x', (node) => node.x + 25)
      .attr('y', (node) => node.y - 8);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node__subtitle')
      .attr('x', (node) => node.x + 25)
      .attr('y', (node) => node.y + 10);

    nodeSvgGroup
      .selectAll<SVGGElement, UpdatedGraphNetworkNode>(
        '.node__subtitle-relatedEntityNode',
      )
      .attr('x', (node) => node.x + 25)
      .attr('y', (node) => node.y + 10);

    allLinkSvgElements
      .attr('x1', (link) => link.source.x)
      .attr('y1', (link) => link.source.y)
      .attr('x2', (link) => link.target.x)
      .attr('y2', (link) => link.target.y);

    allLinkSvgElements
      .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__body')
      .attr('d', (thisLink) => {
        const { source, target } = thisLink;
        const curve = thisLink.curve || { x: 0, y: 0 };

        const qx = source.x + curve.x;
        const qy = source.y + curve.y;

        return `M ${source.x},${source.y} Q ${qx},${qy} ${target.x},${target.y}`;
      });

    allLinkSvgElements
      .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__body-hidden')
      .attr('d', (thisLink) => {
        const { source, target } = thisLink;
        const curve = thisLink.curve || { x: 0, y: 0 };

        const qx = source.x + curve.x;
        const qy = source.y + curve.y;

        return `M ${source.x},${source.y} Q ${qx},${qy} ${target.x},${target.y}`;
      });

    allLinkSvgElements
      .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__body-hidden')
      .attr('x1', (link) => link.source.x)
      .attr('y1', (link) => link.source.y)
      .attr('x2', (link) => link.target.x)
      .attr('y2', (link) => link.target.y);

    allLinkSvgElements
      .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__label')
      .attr('x', function (thisLink) {
        const pathElement = d3
          .select(`#link-path-${thisLink?.id}`)
          .select<SVGPathElement>(null)
          .node() as SVGPathElement | null;

        if (pathElement) {
          const pathLength = pathElement?.getTotalLength();
          const midpoint = pathElement?.getPointAtLength(pathLength / 2);
          return midpoint?.x;
        }
        return null;
      })
      .attr('y', function (thisLink) {
        const pathElement = d3
          .select(`#link-path-${thisLink?.id}`)
          .select<SVGPathElement>(null)
          .node() as SVGPathElement | null;

        if (pathElement) {
          const pathLength = pathElement.getTotalLength();
          const midpoint = pathElement.getPointAtLength(pathLength / 2);
          return midpoint?.y;
        }
        return null;
      });
  });
};
