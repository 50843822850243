import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import { createOrganizationAndUser, fetchDataShards } from '../data';
import { getTeamDomain } from '../../auth/data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../core/helpers/action';
import { url } from '../../../config';

export const asyncSignupValidation = (
  signup,
  dispatch,
  formAsyncErrors,
  field,
) => {
  if (field === 'organizationName') {
    return new Promise((resolve, reject) => {
      getTeamDomain(signup.organizationName).then(({ isAvailable }) => {
        if (!isAvailable) {
          reject({
            organizationName: 'web address already taken',
          });
        } else {
          resolve({});
        }
      });
    });
  }

  return Promise.resolve();
};

export const fetchDataShardActions = createAsyncAction('FETCH_DATA_SHARDS');

export const shouldFetchDataShards = defaultAsyncCallWithActions(
  fetchDataShards,
  fetchDataShardActions,
);

export const createOrgAndUserActions = createAsyncAction(
  'CREATE_ORG_USER_ACTIONS',
);
export const shouldCreateOrgAndUser = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(
    createOrganizationAndUser,
    createOrgAndUserActions,
    (dispatch, { organizationName }) => {
      dispatch(createOrgAndUserActions.receive(organizationName));
      const redirect = `${url.protocol}${organizationName}.${url.mainDomain}`;
      window.location = redirect;
    },
  ),
  {
    onErrorAction: createOrgAndUserActions.invalid,
  },
);
