import { createAction } from 'redux-actions';
import jsCookie from 'js-cookie';

export const changeLocalize = createAction('CHANGE_LOCALE');
export const shouldFetchLocalize = (value) => (dispatch) => {
  jsCookie.set('culture', value);
  dispatch(changeLocalize(value));
};

export default {
  changeLocalize,
  shouldFetchLocalize,
};
