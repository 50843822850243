export const isEmpty = (obj) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const getDeeplyNestedProp = (p, o, notFound = null) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : notFound), o);

export const differenceFromBase = (base, obj2) => {
  const reconcialte = {};
  const baseKeys = Object.keys(base);
  const obj2Keys = Object.keys(obj2);

  baseKeys.forEach((key1) => {
    const hasKey = obj2Keys.find((key2) => key2 === key1);

    if (hasKey) {
      if (base[key1] !== obj2[key1]) {
        reconcialte[key1] = obj2[key1];
      }
    }
  });
  return reconcialte;
};

export const getValueFromPath = (path, value, notFound) => {
  const p = path.split('.');
  return getDeeplyNestedProp(p, value, notFound);
};
