import { useState, useEffect } from 'react';
import {
  shouldRefetchNewToken,
  getNewToken,
} from '../../../core/helpers/token';

const TokenRefresherContainer = () => {
  // eslint-disable-next-line
  const [errorRefreshing, setErrorRefreshing] = useState(false);

  useEffect(() => {
    setInterval(() => {
      if (shouldRefetchNewToken()) {
        getNewToken().catch(() => {
          setErrorRefreshing(true);
        });
      }
    }, 1000 * 2 * 60); //every 2 minutes * 60
  }, []);

  return null;
};

export default TokenRefresherContainer;
