import React from 'react';
import RoleDetailLink from '../../composites/RoleDetailLink';
import { FormattedMessage } from '@cluedin/locale';

export const columns = [
  {
    property: 'name',
    displayName: <FormattedMessage id="module-role-roleName" />,
    Component: RoleDetailLink,
  },
  {
    property: 'description',
    displayName: <FormattedMessage id="module-role-description" />,
  },
];
