import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
} from '@cluedin/list-pattern';
import ConfigurationStatusWithVisual from './ConfigurationStatusWithVisual';
import ConfigurationDataStatus from './ConfigurationDataStatus';

const ConfigurationOverviewStatus = ({ configuration }) => {
  const isActive = configuration.Active;
  const showDataStatus =
    isActive &&
    !configuration.FailingAuthentication &&
    !configuration.isUnApproved;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableRowColumn>
            <strong>
              <FormattedMessage id="module-configuration-status" />
            </strong>
          </TableRowColumn>
          <TableRowColumn style={{ textAlign: 'right' }}>
            <ConfigurationStatusWithVisual
              configuration={{ ...configuration }}
            />
          </TableRowColumn>
        </TableRow>
        {showDataStatus && (
          <TableRow>
            <TableRowColumn>
              <strong>
                <FormattedMessage id="module-configuration-dataStatus" />
              </strong>
            </TableRowColumn>
            <TableRowColumn
              style={{ textAlign: 'right', paddingRight: '15px' }}
            >
              <ConfigurationDataStatus configuration={configuration} />
            </TableRowColumn>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ConfigurationOverviewStatus;
