import { FormattedMessage } from '@cluedin/locale';
import { actionsConfiguration, RACI_LEVELS } from '@cluedin/components';

import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  claims: {
    'admin.tokenmanagement': RACI_LEVELS.INFORMED,
  },
  name: 'apitoken',
  displayName: <FormattedMessage id="apitoken" />,
  path: '/token',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Access',
        name: <FormattedMessage id="apitoken" />,
        link: '/admin/settings/token',
        claims: admin.tokenmanagement.token.view,
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"ApiTokenPage" */
            './components/pages/ApiTokenPage'
          ),
        ),
      ),
    },
  ],
};
