import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { withTheme, colorManipulator } from '@cluedin/theme';
import { FormattedHTMLMessage } from '@cluedin/locale';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { connect } from 'react-redux';
import { Checkbox } from 'uxi/Input';
import { selectEntity, deselectEntity } from '../../actions';
import { entityTypesPool } from '../../entityTypesPool';

const ItemWrapper = styled.div`
  box-sizing: border-box;
  flex-grow: 99;
  height: 100%;
  min-height: 100%;
  width: 100%;
`;

/* eslint-disable indent */
const EntitySelectableListItemWrapperUI = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
 
  background: white;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);

  & > * {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    box-sizing: border-box;
  }

  & > *:first-child {
    z-index: 1;
    position: absolute;
    top: 0;
    background: ${({ theme }) =>
      colorManipulator.fade(theme.palette.themePrimary, 0.8)};
    background-opacity: .5;
    ${({ isMerging }) => isMerging && 'border: 1px solid #cecece;'}; */
    ${({ noBorderTop }) => noBorderTop && 'border-top: none;'};
    display: flex;
    align-items: center;
    justify-content: center;

    svg * {
      fill: white !important;
    }
  }

  & > * + * { /* *:nth-child(2) selector but IE sucks */
    ${({ disabled }) => disabled && 'opacity: .25'};
    ${({ selected, theme }) =>
      selected &&
      `background: ${colorManipulator.fade(theme.palette.themePrimary, 0.2)};`};
    .uxi-tile-detail {
      /* overwrite bcg styles from uxi Tile */
      ${({ selected, theme }) =>
        selected &&
        `background-color: ${colorManipulator.fade(
          theme.palette.themePrimary,
          0.2,
        )}!important;`};
    }
  }

`;

const CheckboxWrapperUI = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  height: 100%;
  max-width: ${({ isMerging }) => (isMerging ? '40px' : '0px')};
  min-width: ${({ isMerging }) => (isMerging ? '40px' : '0px')};

  & *,
  & > div > div {
    height: 100%;
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`;
/* eslint-enable indent */

class EntitySelectableListItemWrapper extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { mergeViewModel, onSelectEntity, onDeselectEntity } = this.props;
    const checked = e.target.checked;

    if (checked) {
      onSelectEntity(mergeViewModel);
    } else {
      onDeselectEntity(mergeViewModel);
    }
  }

  renderSelectable() {
    const {
      currentEntityType,
      mergeViewModel,
      isSelectionComplete,
      children,
      selectedEntities,
      isMerging,
      itemWrapperStyle,
      noBorderTop,
      wrapperStyle,
      theme,
    } = this.props;

    const selected = !!selectedEntities.find(
      (e) => e.entityId === mergeViewModel.entityId,
    );

    let entityTypeIsValid;

    if (mergeViewModel.entityType === currentEntityType) {
      entityTypeIsValid = true;
    } else if (
      entityTypesPool[currentEntityType] &&
      entityTypesPool[currentEntityType].indexOf(mergeViewModel.entityType) > -1
    ) {
      entityTypeIsValid = true;
    }

    const disabled =
      isSelectionComplete || (currentEntityType && !entityTypeIsValid);

    const tooltipOverlay = isSelectionComplete ? (
      <FormattedMessage id="module-entityMerge-selectionComplete" />
    ) : (
      <FormattedHTMLMessage id="module-entityMerge-onlySameType" />
    );

    return (
      <EntitySelectableListItemWrapperUI
        selected={selected}
        disabled={disabled}
        isMerging={isMerging}
        noBorderTop={noBorderTop}
        currentEntityType={currentEntityType}
        style={wrapperStyle}
        theme={theme}
      >
        <Tooltip
          overlay={tooltipOverlay}
          placement="top"
          destroyOnHide
          {...(!disabled ? { visible: false } : {})}
        >
          <CheckboxWrapperUI
            className="test_EntitySelectable_CheckboxWrapper"
            currentEntityType={currentEntityType}
            isMerging={isMerging}
          >
            <Checkbox
              onChange={this.handleChange}
              checked={selected}
              disabled={disabled}
              id={mergeViewModel.entityId}
            />
          </CheckboxWrapperUI>
        </Tooltip>
        <ItemWrapper style={itemWrapperStyle}>{children}</ItemWrapper>
      </EntitySelectableListItemWrapperUI>
    );
  }

  render() {
    return this.renderSelectable();
  }
}

EntitySelectableListItemWrapper.propTypes = {
  children: PropTypes.node,
  mergeViewModel: PropTypes.object,
  selectedEntities: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  isMerging: PropTypes.bool,
  isSelectionComplete: PropTypes.bool,
  currentEntityType: PropTypes.string,
  onSelectEntity: PropTypes.func,
  onDeselectEntity: PropTypes.func,
  wrapperStyle: PropTypes.object,
};

EntitySelectableListItemWrapper.defaultProps = {
  children: <div />,
  mergeViewModel: {},
  selectedEntities: [],
  isMerging: false,
  isSelectionComplete: false,
  currentEntityType: null,
  wrapperStyle: {},
  onSelectEntity: () => {},
  onDeselectEntity: () => {},
};

const mapStateToProps = ({
  entityMerge: {
    isMerging,
    selectedEntities,
    isSelectionComplete,
    currentEntityType,
  },
}) => ({
  isMerging,
  selectedEntities,
  isSelectionComplete,
  currentEntityType,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectEntity: (mergeViewModel) => {
    dispatch(selectEntity(mergeViewModel));
  },
  onDeselectEntity: (mergeViewModel) => {
    dispatch(deselectEntity(mergeViewModel));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(EntitySelectableListItemWrapper));
