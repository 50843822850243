"use strict";

exports.__esModule = true;
exports.ChoiceGroupStyles = void 0;
var ChoiceGroupStyles = function ChoiceGroupStyles() {
  return {
    root: {
      '.ms-ChoiceField-field:hover': {
        ':after': {
          width: '12px',
          height: '12px',
          top: '4px',
          left: '4px'
        }
      },
      '.ms-ChoiceField-field': {
        ':before': {
          backgroundColor: '#fff',
          borderColor: '#9B9B9C'
        },
        ':after': {
          width: '12px',
          height: '12px',
          top: '4px',
          left: '4px'
        }
      }
    }
  };
};
exports.ChoiceGroupStyles = ChoiceGroupStyles;