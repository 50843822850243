import { apiRequest } from '../core/helpers/request';
import { toVM } from '../entity/viewModel';

// admin.datamanagement
export const getDuplicates = () =>
  apiRequest('GET', 'api/organization/duplicates');

// admin.datamanagement
export const getDuplicatePerEntityTypes = () =>
  apiRequest('GET', 'api/organization/v2/duplicates');

export const getDuplicatesQueries = () =>
  apiRequest('GET', 'api/v1/duplicates/queries');

export const getDuplicatesWidgets = () =>
  apiRequest('GET', 'api/v1/duplicates/potential-duplicate-entity-groupings');

export const getEntitiesFromDuplicateQuery = ({
  sourceQuery,
  resultSetKey,
  itemGroupingKey,
  cursor,
}) => {
  const cursorStr = cursor ? `&cursor=${cursor}` : '';

  return apiRequest(
    'GET',
    `api/v1/duplicate/potential-entity-duplicate-instances?sourceQuery=${sourceQuery}&resultSetKey=${resultSetKey}&itemGroupingKey=${itemGroupingKey}${cursorStr}`,
  ).then((result) => {
    const Entries = ((result || {}).Entries || []).map((e) => toVM(e, true));
    return {
      ...result,
      Entries,
    };
  });
};

export default {
  getDuplicates,
  getDuplicatePerEntityTypes,
  getDuplicatesWidgets,
  getEntitiesFromDuplicateQuery,
  getDuplicatesQueries,
};
