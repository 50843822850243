import { useIntl } from 'react-intl';

import { DataItem } from './types';

export const filterAndFormatData = (data: DataItem[]) => {
  const { formatMessage } = useIntl();

  const propertyMap: { [key: string]: string } = {
    id: formatMessage({ id: 'module-entityRelationsGraphTreeview-idLabel' }),
    attributeOrigin: formatMessage({
      id: 'module-entityRelationsGraphTreeview-originCodeLabel',
    }),
    label: formatMessage({
      id: 'module-entityRelationsGraphTreeview-labelLabel',
    }),
    isShadowEntity: formatMessage({
      id: 'module-entityRelationsGraphTreeview-isShadowEntityLabel',
    }),
    isTemporalEntity: formatMessage({
      id: 'module-entityRelationsGraphTreeview-isTemporalEntityLabel',
    }),
    entityType: formatMessage({
      id: 'module-entityRelationsGraphTreeview-entityTypeLabel',
    }),
    displayName: formatMessage({
      id: 'module-entityRelationsGraphTreeview-displayNameLabel',
    }),
    groupedEntityIds: formatMessage({
      id: 'module-entityRelationsGraphTreeview-groupedEntityIdsLabel',
    }),
    previewImage: formatMessage({
      id: 'module-entityRelationsGraphTreeview-previewImageLabel',
    }),
  };

  return data?.map((item) => {
    const property = propertyMap[item?.property] || item?.property;

    return {
      ...item,
      property: property,
    };
  });
};
