import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';

const CONNECTOR_ICON = gql`
  query connectorConfigurationIcon($id: ID) {
    inbound {
      id
      connectorConfigurationIcon(id: $id)
    }
  }
`;

export const useConnectorConfigurationIcon = (id, skip = false) => {
  const { data, loading, error, refetch } = useQuery(CONNECTOR_ICON, {
    skip,
    variables: {
      id: id,
    },
  });

  const icon = get(data, 'inbound.connectorConfigurationIcon');

  return [icon, loading, error, refetch];
};
