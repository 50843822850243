"use strict";

exports.__esModule = true;
exports.TextPlaceholder = exports.RelativeTextPlaceholder = exports.IconPlaceholder = void 0;
var _IconPlaceholder = _interopRequireDefault(require("./IconPlaceholder"));
exports.IconPlaceholder = _IconPlaceholder.default;
var _RelativeTextPlaceholder = _interopRequireDefault(require("./RelativeTextPlaceholder"));
exports.RelativeTextPlaceholder = _RelativeTextPlaceholder.default;
var _TextPlaceholder = _interopRequireDefault(require("./TextPlaceholder"));
exports.TextPlaceholder = _TextPlaceholder.default;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }