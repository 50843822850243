import { withClaimsDisabled } from '@cluedin/components';
import DashboardActionKPI from './DashboardActionKPI';
import UnstyledLink from '../UnstyledLink';
import RenderChildren from '../RenderChildren';

const ActionKpi = ({
  className,
  hasNew,
  icon,
  value,
  label,
  actionLabel,
  drawer,
  danger,
  to,
  onClick,
  disabled,
}) => {
  const isLink =
    (drawer && drawer.route && drawer.title && drawer.content) || to;

  const WrapperComp = isLink ? UnstyledLink : RenderChildren;

  const linkTo =
    drawer && drawer.route && drawer.title && drawer.content
      ? drawer.route
      : to;

  return (
    <WrapperComp
      disabled={disabled}
      to={!disabled && linkTo}
      style={{ color: danger ? '#d13f48' : '#222222' }}
    >
      <DashboardActionKPI
        onClick={!disabled && onClick}
        className={className}
        danger={danger}
        hasNew={hasNew}
        value={!disabled ? value : '-'}
        title={label}
        icon={icon}
        actionLabel={actionLabel}
      />
    </WrapperComp>
  );
};

export default withClaimsDisabled(ActionKpi);
