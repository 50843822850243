import styled from 'styled-components';

export const EntityCodePanelHeaderWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  height: auto;
  justify-content: flex-start;
  padding: 32px 0;
  width: 100%;
`;
