import styled, { css } from 'styled-components';

export const EntityTopologySplitPreviewNewEntitiesWrapper = styled.div`
  background: ${({ theme }) => (theme ? theme?.palette?.white : '#ffffff')};
  border: 1px solid #ececec;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;
`;

export const EntityTopologySplitPreviewNewEntitiesBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const EntityTopologySplitPreviewNewEntitiesInnerWrapper = styled.div`
  align-items: center;
  border-bottom: ${({ isExpanded }) => isExpanded && '1px solid #ececec'};
  background: ${({ isExpanded, theme }) =>
    isExpanded ? theme?.palette?.themeLighterAlt : '#ffffff'};
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 14px;
  transition: all 100ms ease;

  &:hover {
    background: ${({ isExpanded, theme }) =>
      isExpanded ? theme?.palette?.themeLighter : '#fafafa'};
  }

  & svg {
    margin-right: 10px;
  }
`;

export const EntityTopologySplitPreviewNewEntitiesHeader = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

export const EntityTopologySplitPreviewNewEntitiesTitle = styled.div`
  color: #000 !important;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const ExplainLogEntityTypeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg {
    height: 14px !important;
    min-height: 14px !important;
    margin: 0 0 0 6px !important;
    padding: 0 !important;
    min-width: 14px !important;
    width: 14px !important;
  }
`;

export const EntityTopologySplitPreviewNewEntitiesInformation = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const EntityTopologySplitPreviewNewEntitiesSourceWrapper = styled.div`
  height: 100%;
  transition: all 100ms ease;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;
