import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';

const CONNECTOR_BY_ID = gql`
  query connectorConfigurationById($id: ID) {
    inbound {
      id
      connectorConfiguration(id: $id) {
        id
        name
        accountDisplay
        accountId
        active
        autoSync
        codeName
        configuration
        connector {
          id
          icon
          name
          authMethods
          properties
          streamModes
        }
        createdDate
        entityId
        failingAuthentication
        guide
        helperConfiguration
        providerId
        reAuthEndpoint
        source
        sourceQuality
        stats
        status
        supportsAutomaticWebhookCreation
        supportsConfiguration
        supportsWebhooks
        userId
        userName
        users {
          id
          username
          roles
        }
        webhookManagementEndpoints
        webhooks
      }
    }
  }
`;

export const useQueryConfiguredConnectorById = (id) => {
  const { data, loading, error, refetch } = useQuery(CONNECTOR_BY_ID, {
    variables: {
      id: id,
    },
  });

  const connector = get(data, 'inbound.connectorConfiguration');

  return [connector, loading, error, refetch];
};
