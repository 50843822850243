import { memo, useMemo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormDecorator, PrimaryButton, TextField } from '@cluedin/form';

import { useGoldenRecordList } from '../../../GoldenRecordListContext';
import {
  RecordListRuleFiltersInner,
  RecordListFiltersWrapper,
} from './RecordListFilters.styles';
import RuleConditionContainer from '../../../../../../rule/components/containers/RuleConditionContainer';
import { useQueryOperators } from '../../../../../../rule/hooks/useQueryOperators';
import { getRulesValidationErrors } from '../../../../../../rule/rulesValidationUtils';

export const RecordListFiltersByRuleBuilder = memo(({ hideFilter }) => {
  const intl = useIntl();

  const { operators, loading: loadingOperators } = useQueryOperators();

  const {
    appliedSavedSearch,
    listOptions: { filters, setFilters, total },
    filterOptions: {
      appliedSavedSearchName,
      changeAppliedSearchName,
      appliedSearchNameValidationError,
    },
  } = useGoldenRecordList();

  const [internalFiltersState, setInternalFiltersState] = useState(
    filters.condition || { condition: 'AND', rules: [] },
  );

  useEffect(() => {
    setInternalFiltersState(
      filters.condition || { condition: 'AND', rules: [] },
    );
  }, [filters.condition]);

  const filtersValidationErrors = useMemo(
    () =>
      getRulesValidationErrors({
        rules: internalFiltersState.rules,
        operators,
        intl,
      }),
    [internalFiltersState.rules, operators],
  );

  const handleSearch = (v) => {
    const newValue = {
      ...filters,
      condition: internalFiltersState,
    };

    setFilters(newValue);

    hideFilter();
  };

  return (
    <RecordListFiltersWrapper className="RecordListFiltersWrapper_1">
      {appliedSavedSearch && (
        <FormDecorator
          errorText={appliedSearchNameValidationError}
          style={{ padding: '10px 0', width: '40%' }}
          label={intl.formatMessage({
            id: `module-goldenRecordList-nameFiltersNameLabel`,
          })}
        >
          <TextField
            value={appliedSavedSearchName}
            onChange={(_, v) => changeAppliedSearchName(v)}
          />
        </FormDecorator>
      )}

      <RecordListRuleFiltersInner className="RecordListRuleFiltersInner">
        <RuleConditionContainer
          scope="Search"
          context="SEARCH"
          conditions={internalFiltersState}
          onChange={setInternalFiltersState}
          operators={operators}
          loadingOperators={loadingOperators}
          rulesValidationErrors={filtersValidationErrors}
        />
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <PrimaryButton
            rounded
            onClick={handleSearch}
            disabled={
              filtersValidationErrors?.length > 0 ||
              internalFiltersState?.rules?.length === 0
            }
            text={intl.formatMessage({
              id: `module-goldenRecordList-rule-filter-search`,
            })}
          />
          <span style={{ fontSize: 14, fontWeight: 600 }}>
            {intl.formatMessage({
              id: 'module-goldenRecordList-found-results',
            })}
            : {total}
          </span>
        </div>
      </RecordListRuleFiltersInner>
    </RecordListFiltersWrapper>
  );
});
