import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_HIERARCHIES = gql`
  query getHierarchies(
    $searchName: String
    $entityCode: String
    $status: String
    $pageNumber: Int
    $sortBy: String
    $sortDirection: String
  ) {
    management {
      id
      hierarchies(
        searchName: $searchName
        entityCode: $entityCode
        status: $status
        pageNumber: $pageNumber
        sortBy: $sortBy
        sortDirection: $sortDirection
      ) {
        total
        data {
          id
          name
          entityType
          relationships
          status
          numberOfNodes
          ownedBy
          createdBy
          modifiedBy
          createdAt
          modifiedAt
          author {
            id
            username
          }
        }
      }
    }
  }
`;

export const useQueryHierarchies = (initial = {} /** initial props */) => {
  if (initial.status === null || initial.status === undefined) {
    delete initial.status;
  }
  if (initial.pageNumber === null || initial.pageNumber === undefined) {
    delete initial.pageNumber;
  }

  const { data, loading, error, refetch, fetchMore, networkStatus } = useQuery(
    GET_HIERARCHIES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        ...initial,
      },
    },
  );

  const hierarchies = ((data || {}).management || {}).hierarchies;

  return [
    hierarchies || { total: 0, hierarchies: [] },
    loading,
    error,
    networkStatus,
    (options) =>
      refetch({
        notifyOnNetworkStatusChange: true,
        variables: {
          ...options,
        },
      }),
    fetchMore,
  ];
};
