"use strict";

exports.__esModule = true;
exports.SectionDivider = exports.PickListWrapper = exports.ListItemWrapper = exports.ListItem = exports.HeaderText = exports.FlexColumn = exports.ErrorText = exports.EmptyStateWrapper = exports.DeleteIconItem = exports.AddTextFieldWrapper = exports.AddButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _atoms = require("@cluedin/atoms");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var ListItem = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  height: 35px;\n  min-height: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-left: 8px;\n\n  :hover {\n    background-color: #f4fafa;\n  }\n"])));
exports.ListItem = ListItem;
var ListItemWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  height: 150px;\n  min-height: 150px;\n  margin-top: 6px;\n  border: 1px solid #d7d7d8;\n  overflow: auto;\n"])));
exports.ListItemWrapper = ListItemWrapper;
var PickListWrapper = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 12px;\n"])));
exports.PickListWrapper = PickListWrapper;
var EmptyStateWrapper = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
exports.EmptyStateWrapper = EmptyStateWrapper;
var AddButtonWrapper = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  margin-left: 14px;\n  margin-right: 14px;\n  align-items: center;\n  cursor: pointer;\n"])));
exports.AddButtonWrapper = AddButtonWrapper;
var AddTextFieldWrapper = _styledComponents.default.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  margin-top: 4px;\n"])));
exports.AddTextFieldWrapper = AddTextFieldWrapper;
var DeleteIconItem = (0, _styledComponents.default)(_atoms.DeleteIcon)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteralLoose(["\n  width: 16;\n  margin-right: 30px;\n  cursor: pointer;\n"])));
exports.DeleteIconItem = DeleteIconItem;
var HeaderText = _styledComponents.default.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteralLoose(["\n  font-weight: 600;\n  color: black;\n"])));
exports.HeaderText = HeaderText;
var SectionDivider = _styledComponents.default.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteralLoose(["\n  margin-top: 16px;\n  margin-bottom: 8px;\n"])));
exports.SectionDivider = SectionDivider;
var FlexColumn = _styledComponents.default.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  flex-direction: column;\n"])));
exports.FlexColumn = FlexColumn;
var ErrorText = _styledComponents.default.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteralLoose(["\n  font-size: 12px;\n  color: #d13f48;\n"])));
exports.ErrorText = ErrorText;