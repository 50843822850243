import React from 'react';
import Helmet from 'react-helmet';
import { PageHeader } from 'uxi/Page';
import { PageStructureContainer } from '../PageStructure';

const DashboardWrapper = ({ children, title, browserTitle, icon }) => (
  <div>
    <Helmet title={browserTitle} />
    <PageHeader icon={icon} title={title} />
    <PageStructureContainer style={{ margin: '15px 30px' }}>
      {children}
    </PageStructureContainer>
  </div>
);

export default DashboardWrapper;
