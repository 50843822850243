import React from 'react';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import {
  shouldFetchAllFollowingEntities,
  shouldFetchAllSavedSearches,
} from '../../../stream/actions';

import { hasFeature, hasFeatureRole } from '../../selector';

import { MainMenuContainer as MainMenu } from '../composites/MainMenuContainer';

const MainMenuContainer = (props) => <MainMenu {...props} />;

MainMenuContainer.displayName = 'MainMenuContainer';

const EnhancedMenu = compose(
  lifecycle({
    componentDidMount() {
      const { shouldFetchSearchesAndFollows } = this.props;

      shouldFetchSearchesAndFollows();
    },
  }),
)(MainMenuContainer);

EnhancedMenu.displayName = 'EnhancedMenu';

const mapToStateProps = ({
  core: { features },
  stream: { followedEntities, saveSearches },
  user: { currentUser },
  organization,
}) => {
  const newFollowers = (followedEntities || []).filter(
    (follower) => follower.Count > 0,
  );
  const newSearches = (saveSearches || []).filter((s) => s.Count > 0);

  return {
    hasFollow: hasFeature(features || [], 'follow'),
    hasSavedSearch: hasFeature(features || [], 'savedSearches'),
    hasMetrics: hasFeature(features || [], 'metrics'),
    hasNewFollower: newFollowers && newFollowers.length > 0,
    hasNewSearches: newSearches && newSearches.length > 0,
    logoUrl:
      organization && organization.currentOrganization
        ? organization.currentOrganization.Logo
        : null,
  };
};

const mapDispatchToProps = (dispatch, { location }) => ({
  isActive(urls = []) {
    const currentPath = location.toLowerCase();
    let result = false;
    if (Array.isArray(urls)) {
      urls.forEach((u) => {
        if (!result) {
          result = currentPath.indexOf(u) > -1;
        }
      });
    } else {
      return currentPath.indexOf(urls) > -1;
    }

    return result;
  },
  shouldFetchSearchesAndFollows() {
    dispatch(shouldFetchAllSavedSearches());
    dispatch(shouldFetchAllFollowingEntities());
  },
});

export default connect(mapToStateProps, mapDispatchToProps)(EnhancedMenu);
