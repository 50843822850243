"use strict";

exports.__esModule = true;
exports.DataPartHeaderDetailWrapper = exports.DataPartHeaderDetailTitle = exports.DataPartHeaderDetailTimeStamps = exports.DataPartHeaderDetailTimeStamp = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var DataPartHeaderDetailWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: flex-start;\n  width: 100%;\n"])));
exports.DataPartHeaderDetailWrapper = DataPartHeaderDetailWrapper;
var DataPartHeaderDetailTitle = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  font-size: 16px;\n  font-weight: bold;\n  margin-bottom: 4px;\n"])), function (_ref) {
  var themePrimary = _ref.themePrimary;
  return themePrimary ? themePrimary : 'rgb(41, 162, 154)';
});
exports.DataPartHeaderDetailTitle = DataPartHeaderDetailTitle;
var DataPartHeaderDetailTimeStamps = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: 8px;\n"])));
exports.DataPartHeaderDetailTimeStamps = DataPartHeaderDetailTimeStamps;
var DataPartHeaderDetailTimeStamp = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  color: #9b9b9c;\n"])));
exports.DataPartHeaderDetailTimeStamp = DataPartHeaderDetailTimeStamp;