import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import UserProcessorConsent from '../processor/UserProcessorConsent';

const UserConsentProcessorList = (props) => {
  const { processors, onChange } = props;

  const title = (
    <FormattedMessage
      id="module-consent-configureDataProcessor"
      values={{
        dataProcessorsLength: processors.length,
        plural: processors.length > 1 ? 's' : '',
      }}
    />
  );

  return (
    <div>
      <div style={{ border: '1px solid #ccc', padding: '15px' }}>{title}</div>
      {processors.map((p) => (
        <UserProcessorConsent processor={p} onChange={onChange} />
      ))}
    </div>
  );
};

UserConsentProcessorList.displayName = 'UserConsentProcessorList';

export default UserConsentProcessorList;
