import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const ADD_USERS_TO_ROLE = gql`
  mutation ($userName: String!, $roles: [String]!) {
    administration {
      id
      addRolesToUser(userName: $userName, roles: $roles)
    }
  }
`;

export const useAddRolesToUser = () => {
  const [addRolesToUser, { data, loading, error }] =
    useMutation(ADD_USERS_TO_ROLE);
  const done = _.get(data, 'administration.addRolesToUser');

  return [
    (userName, roles) => addRolesToUser({ variables: { userName, roles } }),
    done,
    loading,
    error,
  ];
};
