import React from 'react';
import Alert from 'uxi/Alert';
import { ErrorText } from 'uxi/Text';
import AccessDeniedContainer from '../../../../error/components/containers/AccessDeniedContainer';

const EntityAccessDenied = (placement, message) => {
  if (placement === 'page') {
    return () => <AccessDeniedContainer message={message} />;
  }

  if (placement === 'inline') {
    return () => <ErrorText>{message || 'Access Denied'}</ErrorText>;
  }

  return () => <Alert type="danger">{message || 'Access Denied'}</Alert>;
};

export default EntityAccessDenied;
