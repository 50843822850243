import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';

const FormattedCreationDate = ({ sortDate }) => {
  return (
    <div>
      {sortDate ? (
        moment(sortDate).format('LLL')
      ) : (
        <FormattedMessage id="module-entityTopology-noDate" />
      )}
    </div>
  );
};

FormattedCreationDate.propTypes = {
  createdAt: PropTypes.string,
};
FormattedCreationDate.defaultProps = {
  createdAt: null,
};

export const columns = [
  {
    property: 'sortDate',
    displayName: 'Date',
    Component: FormattedCreationDate,
  },
];
