import React, { useState } from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from '@cluedin/locale';
import KeyIcon from '@cluedin/svgs/icons/key-icon.svg';

import GroupedEntitiesPanelAPIContainer from './GroupedEntitiesPanelAPIContainer/GroupedEntitiesPanelAPIContainer';
import EdgePropertiesPanelAPIContainer from './EdgePropertiesPanelAPIContainer/EdgePropertiesPanelAPIContainer';
import EntityCodePanel from '../composites/structure/EntityCodePanel';
import EdgeRelation from '../../../components/graphs/EdgeRelation/NewEdgeRelation/NewEdgeRelation';
import PageLoader from '../../core/components/composites/PageLoader';
import { EntityRelationsContainerWrapper } from './EntityRelationsContainer.styles';
import { useQueryEntityEdgeSummaryById } from '../hooks/useEdgeSummary';
import { useGetEntityRelations } from '../hooks/useGetEntityRelations';
import withEntityQuickView from '../../entity/components/Hocs/withEntityQuickview';
import { useOrganizationFeatureFlagOption } from '../../featureFlag/hooks/useOrganizationFeatureFlag';
import EntityRelationsGraphV3 from '../../entityRelationsGraphV2/containers/EntityRelationsGraphV3';

const KeyImage = document.createElement('img');
KeyImage.setAttribute('src', KeyIcon);

const messages = defineMessages({
  createdLabel: { id: 'module-entity-relation-created-label' },
  directionLabel: { id: 'module-entity-relation-direction-label' },
  noNameLabel: { id: 'module-entity-relation-noname-label' },
  noEntityLabel: { id: 'module-entity-relation-noentity-label' },
});

const EntityRelationsContainer = ({
  entity,
  isFetching,
  intl,
  removeMargin,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [isGroupedEntitiesPanelOpen, setIsGroupedEntitiesPanelOpen] =
    useState(false);
  const [nodesAndEdges, setNodesAndEdges] = useState({});
  const [isEdgePropertiesPanelOpen, setIsEdgePropertiesPanelOpen] =
    useState(false);

  if (!entity || isFetching) {
    return <PageLoader />;
  }

  const onNodeClick = (node) => {
    setSelectedNode(node);
    setIsPanelOpen(true);
  };

  const onEdgeClick = (edge) => {
    setSelectedEdge(edge);
    setIsEdgePropertiesPanelOpen(true);
  };

  const onGroupedNodeClick = (node, edgeType, edgeDirection) => {
    if (edgeType) {
      setSelectedNode({
        ...node,
        edgeType,
        edgeDirection,
      });
    } else {
      setSelectedNode(node, edgeDirection);
    }
    setIsGroupedEntitiesPanelOpen(true);
  };

  const handleEntityCodePanelOnClose = () => {
    setSelectedEdge(null);
    setIsEdgePropertiesPanelOpen(false);
    setIsPanelOpen(false);
  };

  const isNewEntityRelationsSummaryQuery = useOrganizationFeatureFlagOption(
    'EntityRelationsWithNewAPI',
  );
  const isNewEntityRelationsGraph = useOrganizationFeatureFlagOption(
    'newEntityRelationsGraph',
  );

  return (
    <>
      <EntityRelationsContainerWrapper
        data-test="edge-relation-container"
        id="edge-relation-container"
        removeMargin={removeMargin}
      >
        {isNewEntityRelationsGraph ? (
          <EntityRelationsGraphV3 entityId={entity?.id} entity={entity} />
        ) : (
          <EdgeRelation
            entityId={entity.id}
            keyImage={KeyImage}
            useQueryEntityEdgeSummaryById={
              isNewEntityRelationsSummaryQuery
                ? useGetEntityRelations
                : useQueryEntityEdgeSummaryById
            }
            labels={{
              createdLabel: intl.formatMessage(messages.createdLabel),
              directionLabel: intl.formatMessage(messages.directionLabel),
              noNameLabel: intl.formatMessage(messages.noNameLabel),
              noEntityLabel: intl.formatMessage(messages.noEntityLabel),
            }}
            onNodeClick={onNodeClick}
            setIsGroupedEntitiesPanelOpen={onGroupedNodeClick}
            selectedNode={selectedNode}
            setNodesAndEdges={setNodesAndEdges}
            nodesAndEdges={nodesAndEdges}
            onEdgeClick={onEdgeClick}
            setIsEdgePropertiesPanelOpen={setIsEdgePropertiesPanelOpen}
          />
        )}

        {isPanelOpen && (
          <EntityCodePanel
            show={isPanelOpen}
            includeHeader={true}
            entity={{ data: selectedNode }}
            onClose={() => handleEntityCodePanelOnClose()}
          />
        )}

        {isGroupedEntitiesPanelOpen && (
          <GroupedEntitiesPanelAPIContainer
            entityId={entity?.id}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            setSelectedEdge={setSelectedEdge}
            edgeType={selectedNode?.label}
            isGroupedEntitiesPanelOpen={isGroupedEntitiesPanelOpen}
            setIsGroupedEntitiesPanelOpen={setIsGroupedEntitiesPanelOpen}
            setIsEdgePropertiesPanelOpen={setIsEdgePropertiesPanelOpen}
          />
        )}

        {isEdgePropertiesPanelOpen && (
          <EdgePropertiesPanelAPIContainer
            entityId={entity?.id}
            selectedEdge={selectedEdge}
            setSelectedEdge={setSelectedEdge}
            isEdgePropertiesPanelOpen={isEdgePropertiesPanelOpen}
            setIsEdgePropertiesPanelOpen={setIsEdgePropertiesPanelOpen}
            nodesAndEdges={nodesAndEdges}
            onNodeClick={onNodeClick}
            onGroupedNodeClick={onGroupedNodeClick}
          />
        )}
      </EntityRelationsContainerWrapper>
    </>
  );
};

export default withEntityQuickView(
  injectIntl(connect()(EntityRelationsContainer)),
);
