import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_BY_DATA_PART_PROPERTIES = gql`
  query getEntityByDataPartProperties($properties: JSON!) {
    virtualization {
      getEntityByDataPartProperties(properties: $properties) {
        entityVocabularyKey
        value
        vocabularyKey {
          displayName
          vocabularyKeyId
          isCluedInCore
          isDynamic
          isObsolete
          isProvider
          vocabularyId
          name
          isVisible
          key
          groupName
          dataClassificationCode
          dataType
          description
          providerId
          mapsToOtherKeyId
          vocabulary {
            connector {
              about
              icon
              id
              name
            }
            vocabularyId
            vocabularyName
          }
        }
      }
    }
  }
`;

export const useGetEntityByDataPartProperties = (properties) => {
  const { data, loading, error } = useQuery(
    GET_ENTITY_BY_DATA_PART_PROPERTIES,
    {
      variables: {
        properties,
      },
    },
  );

  const result = get(data, 'virtualization.getEntityByDataPartProperties');

  return [result, loading, error];
};
