import { createAction } from 'redux-actions';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../action/helpers/actionCreator';
import { sendRequest } from './data';

export const selectFeature = createAction('FEATURE_SELECTED');
export const clearSelectFeature = createAction('FEATURE_SELECTED_CLEAR');

export const shouldShowPersonalIdentifiers = createAction(
  'SHOW_PERSONAL_IDENTIFIERS',
);

export const showConsentDialog = createAction('SHOW_CONSENT_DIALOG');

export const showCurrentUserRequest = createAction(
  'SHOW_CURRENT_USER_REQUESTS',
);

export const sendRequestActions = createAsyncAction('FEATURE_SEND_REQUEST');
export const shouldSendRequest = defaultAsyncCallWithActions(
  sendRequest,
  sendRequestActions,
);
