import { memo, useCallback, forwardRef } from 'react';
import { useTheme } from '@cluedin/theme';
import { IconButton, IButtonStyles } from '@fluentui/react';

import { useAppSearch } from '../context/useAppSearch';

export const AppSearchButton = memo(
  forwardRef<HTMLInputElement, unknown>((_, ref) => {
    const theme = useTheme();
    const { handleSearch } = useAppSearch();

    const getStyles = useCallback(
      (): Partial<IButtonStyles> => ({
        root: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          color: theme.palette.themePrimary,
          backgroundColor: theme.palette.neutralLighter,
        },
        rootHovered: {
          color: theme.palette.themePrimary,
          backgroundColor: theme.palette.neutralLight,
        },
      }),
      [theme],
    );

    return (
      <IconButton
        elementRef={ref}
        styles={getStyles()}
        onClick={handleSearch}
        iconProps={{ iconName: 'Search' }}
      />
    );
  }),
);
