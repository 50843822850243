import React from 'react';
import Helmet from 'react-helmet';
import { matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DivPadding } from 'uxi/Base';
import { Widget } from 'uxi/Widget';
import { push } from 'connected-react-router';
import Tooltip from 'rc-tooltip';
import styled from 'styled-components';
import { compose, lifecycle } from 'recompose';
import { useTheme } from '@cluedin/theme';
import { Step, Stepper, StepButton } from 'uxi/Stepper';
import { FormattedMessage } from '@cluedin/locale';
import GdprSarOverviewContainer from '../containers/GdprSarOverviewContainer';
import { PageStructureContainer } from '../../../core/components/composites/PageStructure';
import { WithErrorAndLoading } from '../../../core/components/Hocs/WithErrorAndLoading';
import Crumb from '../../../core/components/composites/routing/Crumb';
import { useBackButton } from '../../../../components/PillarBackButton';
import {
  shouldFetchSARById,
  shouldClearCurrentSar,
  SARClearSubjectsEntityAction,
} from '../../actions';

const StepButtonWrapper = styled.div`
  div[type='success'] {
    background-color: ${({ themePrimary }) => themePrimary} !important;
  }
  svg {
    color: ${({ themePrimary }) => themePrimary} !important;
  }
`;

const GdprNewSubjectAccessWraperPage = ({
  children,
  currentSARId,
  goToFindEntity,
  goToValidate,
  goToSendReport,
  goToConfigureReport,
  goToOverview,
  activeStep,
  currentSAR,
  SARSubjectsEntity,
}) => {
  useBackButton();
  const theme = useTheme();
  return (
    <div>
      <Helmet title={`CluedIn - S.A.R. - ${currentSARId}`} />

      <PageStructureContainer style={{ margin: '16px' }}>
        <Crumb
          title={[
            {
              to: `/admin/governance/gdpr/list`,
              text: 'All Subject Access Request',
            },
            { id: 'module-gdpr-SARDetail' },
          ]}
        />
        <div className="cluedIn_row">
          <div className="cluedIn_col l3 s12">
            <GdprSarOverviewContainer
              currentSAR={currentSAR}
              sarId={currentSARId}
              SARSubjectsEntity={SARSubjectsEntity}
            />
          </div>
          <div className="cluedIn_col l9 s12">
            <Widget
              style={{ background: '#fff' }}
              title={
                <FormattedMessage id="GdprNewSubjectAccessWraperPage_Title" />
              }
            >
              <div>
                <div style={{ borderBottom: '1px solid #ccc' }}>
                  <DivPadding padding="S">
                    <StepButtonWrapper
                      themePrimary={theme.palette.themePrimary}
                    >
                      <Stepper activeStep={activeStep} linear nowrap>
                        <Step
                          style={{
                            cursor:
                              currentSAR.EntityId !==
                              '00000000-0000-0000-0000-000000000000'
                                ? 'not-allowed'
                                : 'normal',
                          }}
                        >
                          <Tooltip
                            overlay={
                              <span>
                                An individual is already attached to this SAR
                              </span>
                            }
                            trigger={['hover']}
                            placement="top"
                            defaultVisible={
                              currentSAR.EntityId !==
                              '00000000-0000-0000-0000-000000000000'
                                ? false
                                : undefined
                            }
                          >
                            <StepButton
                              style={{
                                pointerEvents:
                                  currentSAR.EntityId !==
                                  '00000000-0000-0000-0000-000000000000'
                                    ? 'not-allowed'
                                    : 'normal',
                              }}
                              onClick={
                                currentSAR.EntityId !==
                                '00000000-0000-0000-0000-000000000000'
                                  ? () => {}
                                  : goToFindEntity
                              }
                            >
                              <FormattedMessage id="GdprNewSubjectAccessWraperPage_AttachIndividual" />
                            </StepButton>
                          </Tooltip>
                        </Step>
                        <Step>
                          <StepButton
                            onClick={
                              currentSAR.State >= 2
                                ? () => {
                                    goToValidate(currentSARId);
                                  }
                                : () => {}
                            }
                          >
                            <FormattedMessage id="GdprNewSubjectAccessWraperPage_ValidateReport" />
                          </StepButton>
                        </Step>
                        <Step>
                          <StepButton
                            onClick={
                              currentSAR.State >= 3
                                ? () => {
                                    goToSendReport(currentSARId);
                                  }
                                : () => {}
                            }
                          >
                            <FormattedMessage id="GdprNewSubjectAccessWraperPage_SendingReport" />
                          </StepButton>
                        </Step>
                        <Step>
                          <StepButton
                            onClick={
                              currentSAR.State >= 4
                                ? () => {
                                    goToConfigureReport(currentSARId);
                                  }
                                : () => {}
                            }
                          >
                            <FormattedMessage id="GdprNewSubjectAccessWraperPage_ConfigureData" />
                          </StepButton>
                        </Step>
                        <Step>
                          <StepButton
                            onClick={
                              currentSAR.State >= 5
                                ? () => {
                                    goToOverview(currentSARId);
                                  }
                                : () => {}
                            }
                          >
                            <FormattedMessage id="GdprNewSubjectAccessWraperPage_Overview" />
                          </StepButton>
                        </Step>
                      </Stepper>
                    </StepButtonWrapper>
                  </DivPadding>
                </div>
                <DivPadding padding="S">{children}</DivPadding>
              </div>
            </Widget>
          </div>
        </div>
      </PageStructureContainer>
    </div>
  );
};

GdprNewSubjectAccessWraperPage.displayName = 'GdprNewSubjectAccessWraperPage';

GdprNewSubjectAccessWraperPage.propTypes = {
  children: PropTypes.node,
};
GdprNewSubjectAccessWraperPage.defaultProps = {
  children: null,
  location: '',
};

const EnhancedGdprNewSubjectAccessWraperPage = compose(
  lifecycle({
    componentDidMount() {
      const { fetchSARById, currentSARId } = this.props;
      fetchSARById(currentSARId);
    },
    componentWillUnmount() {
      const { clearSar, clearSubjectsEntity, currentSARId } = this.props;
      clearSar(currentSARId);
      clearSubjectsEntity();
    },
    componentWillReceiveProps(nextProps) {
      if (this.props.currentSARId !== nextProps.currentSARId) {
        const { clearSubjectsEntity } = this.props;
        clearSubjectsEntity();
      }
    },
  }),
)(WithErrorAndLoading(GdprNewSubjectAccessWraperPage));

const mapStateToProps = (
  {
    GDPR: {
      currentSAR,
      isFetching,
      currentSARError,
      SARSubjectsEntity,
      SARSubjectsEntityLoading,
    },
  },
  { location: { pathname } },
) => {
  let activeStep = 1;
  let match;

  if (
    pathname.toLowerCase().indexOf('/admin/governance/gdpr/edit/findentity') ===
    0
  ) {
    match = matchPath(pathname, {
      path: '/admin/governance/gdpr/edit/findentity/:id',
    });
    activeStep = 1;
  } else if (
    pathname.toLowerCase().indexOf('/admin/governance/gdpr/edit/validate') === 0
  ) {
    match = matchPath(pathname, {
      path: '/admin/governance/gdpr/edit/validate/:id',
    });
    activeStep = 2;
  } else if (
    pathname.toLowerCase().indexOf('/admin/governance/gdpr/edit/sendreport') ===
    0
  ) {
    match = matchPath(pathname, {
      path: '/admin/governance/gdpr/edit/sendreport/:id',
    });
    activeStep = 3;
  } else if (
    pathname
      .toLowerCase()
      .indexOf('/admin/governance/gdpr/edit/configuredata') === 0
  ) {
    match = matchPath(pathname, {
      path: '/admin/governance/gdpr/edit/configuredata/:id',
    });
    activeStep = 4;
  } else if (
    pathname.toLowerCase().indexOf('/admin/governance/gdpr/edit/overview') === 0
  ) {
    match = matchPath(pathname, {
      path: '/admin/governance/gdpr/edit/overview/:id',
    });
    activeStep = 5;
  }

  const SarId =
    match && match.params && match.params.id ? match.params.id : null;

  return {
    isFetching:
      isFetching || SARSubjectsEntityLoading || !currentSAR || !currentSAR.Id,
    currentSARId: SarId,
    currentSAR,
    activeStep,
    SARSubjectsEntity,
    invalid: currentSARError || !SarId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToFindEntity: (SarId) => {
    dispatch(push(`/admin/governance/gdpr/edit/findEntity/${SarId}`));
  },
  goToValidate: (SarId) => {
    dispatch(push(`/admin/governance/gdpr/edit/validate/${SarId}`));
  },
  goToSendReport: (SarId) => {
    dispatch(push(`/admin/governance/gdpr/edit/sendReport/${SarId}`));
  },
  goToConfigureReport: (SarId) => {
    dispatch(push(`/admin/governance/gdpr/edit/configuredata/${SarId}`));
  },
  goToOverview: (SarId) => {
    dispatch(push(`/admin/governance/gdpr/edit/overview/${SarId}`));
  },
  clearSar: (SarId) => dispatch(shouldClearCurrentSar(SarId)),
  fetchSARById: (SarId) => {
    dispatch(shouldFetchSARById(SarId));
  },
  clearSubjectsEntity: () => dispatch(SARClearSubjectsEntityAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnhancedGdprNewSubjectAccessWraperPage);
