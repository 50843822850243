import { getCurrentToken, url as urlHelper } from '../../config';
import { removeFirstCharacter } from '../core/helpers/string';
import { apiRequest } from '../core/helpers/request';

export const DefaultSource = {
  Name: 'CluedIn',
  Icon: '/img/cluedin.png',
  Domain: 'https://www.cluedin.com',
};

export const getAllIntegrationsWithNoFilters = () => {
  return (
    apiRequest('GET', 'api/integration/connector/all')
      //.then(r => r.body)
      .then((integrations) => {
        return (integrations || [])
          .filter(
            (i) =>
              i.Type !== 'Connector' &&
              i.Type !== 'DataSource' &&
              i.Type !== 'Enrichment',
          )
          .map((c) => {
            let result;
            const iconUrl =
              c.Icon[0] === '/' ? removeFirstCharacter(c.Icon) : c.Icon;

            try {
              result = {
                ...c,
                Icon: c.Icon ? `${urlHelper.api}${iconUrl}` : '',
                Properties: c.Properties ? c.Properties : [],
                Aliases: c.Aliases ? c.Aliases : [],
                AuthMethods: c.AuthMethods ? c.AuthMethods : [],
              };
            } catch (e) {
              result = {
                ...c,
                Icon: c.Icon ? `${urlHelper.api}${iconUrl}` : '',
                Properties: [],
                Aliases: [],
                AuthMethods: [],
              };
            }

            return result;
          });
      })
  );
};

export const getAllIntegrations = () => {
  return (
    apiRequest('GET', 'api/integration/connector/all')
      //.then(r => r.body)
      .then((integrations) => {
        return (integrations || [])
          .filter(
            (i) =>
              i.Type !== 'Connector' &&
              i.Type !== 'DataSource' &&
              i.Type !== 'Enrichment',
          )
          .map((c) => {
            let result;
            const iconUrl =
              c.Icon[0] === '/' ? removeFirstCharacter(c.Icon) : c.Icon;

            try {
              result = {
                ...c,
                Icon: c.Icon ? `${urlHelper.api}${iconUrl}` : '',
                Properties: c.Properties ? c.Properties : [],
                Aliases: c.Aliases ? c.Aliases : [],
                AuthMethods: c.AuthMethods ? c.AuthMethods : [],
              };
            } catch (e) {
              result = {
                ...c,
                Icon: c.Icon ? `${urlHelper.api}${iconUrl}` : '',
                Properties: [],
                Aliases: [],
                AuthMethods: [],
              };
            }

            return result;
          });
      })
  );
};

export const getAllIntegrationsForSources = () => {
  return apiRequest('GET', 'api/integration/connector/all').then(
    (integrations) => {
      return (integrations || [])
        .filter((i) => i.Type !== 'Connector' && i.Type !== 'Enrichment')
        .map((c) => {
          let result;
          const iconUrl =
            c.Icon[0] === '/' ? removeFirstCharacter(c.Icon) : c.Icon;

          try {
            result = {
              ...c,
              Icon: c.Icon ? `${urlHelper.api}${iconUrl}` : '',
              Properties: c.Properties ? c.Properties : [],
              Aliases: c.Aliases ? c.Aliases : [],
              AuthMethods: c.AuthMethods ? c.AuthMethods : [],
            };
          } catch (e) {
            result = {
              ...c,
              Icon: c.Icon ? `${urlHelper.api}${iconUrl}` : '',
              Properties: [],
              Aliases: [],
              AuthMethods: [],
            };
          }

          return result;
        });
    },
  );
};

export const getIntegrationById = (id) =>
  getAllIntegrations().then((providers) => providers.find((p) => p.Id === id));

export const inviteUsersToAddIntegration = ({ providerId, userIds }) =>
  apiRequest(
    'POST',
    `api/v2/organization/providers/team?providerId=${providerId}&userIds=${userIds.join(
      ',',
    )}`,
    {},
  );

// === addActiveProvider ===
// return Request.CreateResponse(HttpStatusCode.Created, new {
//  organizationProvider.AccountDisplay, organizationProvider.AutoSync, organizationProvider.Source,
//  organizationProvider.SourceQuality, helperConfiguration, organizationProvider.Id, appUrl,
//  jobDataCheck.SupportsWebHooks, jobDataCheck.SupportsAutomaticWebhookCreation });
export const addActiveProvider = ({ integrationId, configuration }) =>
  apiRequest(
    'POST',
    `api/v2/organization/providers?providerId=${integrationId}`,
    configuration,
  );

export const addTrelloToken = (token) =>
  apiRequest('POST', `api/TrelloOAuth?token=${token}`, {});

// === enableProvider ===
export const enableProvider = ({ configurationId, configuration }) =>
  apiRequest(
    'POST',
    `api/v2/organization/providers/enable?organizationProviderId=${configurationId}`,
    configuration,
  );

export const editConfiguration = ({
  configurationId,
  accountId,
  configuration,
}) =>
  apiRequest(
    'PUT',
    `api/v2/organization/providers?providerId=${configurationId}&AccountId=${accountId}&accessToken=${getCurrentToken()}`,
    configuration,
  );

export const newOauthConfiguration = (url) => apiRequest('GET', url);
