import { gql } from '@apollo/client';

export const allSearchResult = gql`
  query searchQuery(
    $query: String
    $filter: String
    $rule: String
    $filters: [FilterQuery]
    $terms: [TermQuery]
    $pageSize: Int
    $contextIds: [Guid]
    $sort: SortMethod
    $sortDirection: SortDirection
    $includeExternalData: Boolean
    $includeUnstructuredData: Boolean
    $cursor: PagingCursor
    $searchUntokenized: Boolean
    $sortFields: [SortField]
  ) {
    search(
      query: $query
      filter: $filter
      rule: $rule
      filters: $filters
      terms: $terms
      pageSize: $pageSize
      contextIds: $contextIds
      sort: $sort
      sortDirection: $sortDirection
      includeExternalData: $includeExternalData
      includeUnstructuredData: $includeUnstructuredData
      cursor: $cursor
      searchUntokenized: $searchUntokenized
      sortFields: $sortFields
    ) {
      cursor
      entries {
        id
        name
        createdDate
        discoveryDate
        modifiedDate
        properties
        originEntityCode
        providerOrigins
        description
        uris
        previewImage {
          uri
        }
        entityType
        sortDate
      }
      aggregations
    }
  }
`;

export default {
  allSearchResult,
};
