import { connect } from 'react-redux';

import { TopMenu } from '../composites/TopMenu/TopMenu';

import { shouldLogout } from '../../../auth/actions';
import { clientId } from '../../../../config';

const mapStateToProps = (state) => {
  const {
    user: { currentUser },
    publicModule: { isUsingSSO } = {},
  } = state;

  return {
    isUsingSSO,
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    const { pathname } = window.location;
    dispatch(shouldLogout(clientId, pathname));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
