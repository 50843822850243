import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from '@cluedin/form';
import type { Params } from '../../hooks/useEntityHistorySummary';

type Option = {
  label: ReactNode;
  value: string;
};

type HeaderProps = {
  setFilters: React.Dispatch<React.SetStateAction<Params>>;
  filters: Params;
  entitySourceOptions: Option[];
};

const options: Option[] = [
  {
    label: <FormattedMessage id="module-entityHistoryV2-sort-date" />,
    value: 'SortDate',
  },
  {
    label: <FormattedMessage id="module-entityHistoryV2-modified-date" />,
    value: 'ModifiedDate',
  },
  {
    label: <FormattedMessage id="module-entityHistoryV2-created-date" />,
    value: 'CreatedDate',
  },
  {
    label: <FormattedMessage id="module-entityHistoryV2-discovery-date" />,
    value: 'DiscoveryDate',
  },
];

export const Header: React.FC<HeaderProps> = ({
  setFilters,
  filters,
  entitySourceOptions,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '300px' }}>
          <Select
            value={filters?.sourceName}
            options={entitySourceOptions}
            defaultValue={entitySourceOptions[0]}
            onChange={({ value }: { value: string }) => {
              setFilters((previousState: Params) => ({
                ...previousState,
                sourceName: value,
              }));
            }}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '250px', marginRight: '12px' }}>
          <Select
            value={filters?.sortedDateFilter}
            options={options}
            defaultValue={options[0]}
            onChange={({ value }: { value: string }) => {
              setFilters((previousState: Params) => ({
                ...previousState,
                sortedDateFilter: value,
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
};
