import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Integration } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';
import { withIntegrationConfig } from '../../../../integration/components/hocs/withIntegrationConfig';

const title = 'Integration is ready';
const CrawlerFinishedNotificationStyle = {
  logo: {
    width: '24px',
  },
};

const CrawlerFinishedNotification = ({
  index,
  notification,
  onCloseClick,
  integrationConfig,
}) => {
  const message = `Your integration ${notification.providerName} has finished crawling.`;
  let providerIcon;
  if (integrationConfig) {
    providerIcon = (
      <img
        style={CrawlerFinishedNotificationStyle.logo}
        alt={integrationConfig.Name}
        src={integrationConfig.Icon}
      />
    );
  } else {
    providerIcon = <Integration />;
  }

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      customIcon={providerIcon}
    />
  );
};

const EnhancedCrawlerFinishedNotification = compose(
  lifecycle({
    componentDidMount() {
      const { notification } = this.props;
      const message = `Your integration ${notification.providerName} has finished crawling.`;

      notify(title, { body: message });
    },
  }),
)(CrawlerFinishedNotification);

export default withIntegrationConfig(
  EnhancedCrawlerFinishedNotification,
  (props) => {
    return {
      integrationName:
        props && props.notification && props.notification.providerName
          ? props.notification.providerName
          : null,
    };
  },
);
