import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionsConfiguration, ButtonLink } from '@cluedin/components';
import { Idbadge } from 'uxi/Icons';
import { ThemedIcon } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { Spinner } from '@cluedin/form';
import { shouldFetchProductOwner, shouldShowProductOwner } from '../../actions';

const getProductOwnerEntityId = (allUsers, userId) =>
  allUsers.find(({ Account }) => userId === Account.Id);

const { integrations } = actionsConfiguration;
class ProductOwner extends Component {
  render() {
    const { providerOwner, allUsers, showProductOwner } = this.props;

    const setOwnerButton = (
      <div>
        <ButtonLink
          onClick={showProductOwner}
          icon={
            <ThemedIcon>
              <Idbadge size={16} />
            </ThemedIcon>
          }
          style={{ margin: '8px' }}
          text={
            <FormattedMessage
              id="module-configuration-setOwner"
              defaultMessage="Configure Product Owners"
            />
          }
          actionConfiguration={
            integrations.configuredIntegrations.configuredIntegration.edit
          }
        />
      </div>
    );

    if (providerOwner && providerOwner.length && allUsers) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexFlow: 'row wrap',
          }}
        >
          {providerOwner.map(({ UserId }, i) => {
            const entityData = getProductOwnerEntityId(allUsers, UserId);
            if (entityData) {
              if (entityData.Entity) {
                return (
                  <span style={{ margin: '2px' }}>
                    <Link to={`/person/${entityData.Entity.id}`}>
                      {entityData.Account.UserName}
                    </Link>
                    {i < providerOwner.length - 1 ? ', ' : ''}
                  </span>
                );
              }
              return (
                <span style={{ margin: '2px' }}>
                  {entityData.Account.UserName}
                  {i < providerOwner.length - 1 ? ', ' : ''}
                </span>
              );
            }
            return null;
          })}
          {setOwnerButton}
        </div>
      );
    }

    return setOwnerButton;
  }
}

const EnhancedProductOwner = compose(
  lifecycle({
    componentDidMount() {
      const { fetchProductOwner, configuration } = this.props;
      if (fetchProductOwner && configuration && 'Id' in configuration) {
        fetchProductOwner(configuration.Id);
      }
    },
    componentDidUpdate(prevProp) {
      const { fetchProductOwner, configuration } = this.props;
      if (configuration !== prevProp.configuration) {
        fetchProductOwner(configuration.Id);
      }
    },
  }),
  branch(
    ({ isFetchingProviderOwner }) => isFetchingProviderOwner,
    renderComponent(Spinner),
  ),
  branch(
    ({ isFecthingProviderOwnerAccessDenied }) =>
      isFecthingProviderOwnerAccessDenied,
    renderComponent(() => (
      <span style={{ color: 'red' }}>
        <FormattedMessage
          id="module-configuration-noAccessToProductOwners"
          defaultMessage="You do not have access."
        />
      </span>
    )),
  ),
)(ProductOwner);

const mapStateToProps = ({
  configuration: {
    isFetchingProviderOwner,
    isFetchingProviderOwnerError,
    isFecthingProviderOwnerAccessDenied,
    providerOwner,
  },
  user: { allUsers },
}) => ({
  isFetchingProviderOwner,
  isFetchingProviderOwnerError,
  isFecthingProviderOwnerAccessDenied,
  providerOwner,
  allUsers,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductOwner(providerDefinitionId) {
    dispatch(shouldFetchProductOwner(providerDefinitionId));
  },
  showProductOwner() {
    dispatch(shouldShowProductOwner());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnhancedProductOwner);
