import styled, { css } from 'styled-components';

export const EdgePropertiesPanelEdgeGroupEdgeWrapperNoData = styled.div<{
  isExpanded?: boolean;
}>`
  height: 100%;
  margin: 10px;
  transition: all 100ms ease;
  width: 100%;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          padding: 14px;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;
