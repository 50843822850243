"use strict";

exports.__esModule = true;
exports.uuid = exports.url = exports.requiredArray = exports.required = exports.password = exports.isEmpty = exports.emailFormForm = exports.email = exports.default = void 0;
var _lodash = require("lodash");
var required = function required(value) {
  return value ? undefined : 'Required';
};
exports.required = required;
var requiredArray = function requiredArray(value) {
  return value && Array.isArray(value) && value.length > 0 ? undefined : 'Not an array, or array empty';
};
exports.requiredArray = requiredArray;
var isUrl = function isUrl(str) {
  /* eslint-disable no-useless-escape */
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(str);
};
var email = function email(v) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(v);
};
exports.email = email;
var emailFormForm = function emailFormForm(v) {
  return !email(v) ? '' : 'Please enter a valid email';
};
exports.emailFormForm = emailFormForm;
var url = function url(value) {
  var result = isUrl(value) ? undefined : 'Invalid Url';
  return result;
};
exports.url = url;
var uuid = function uuid(v) {
  var re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return !re.test(v);
};
exports.uuid = uuid;
var password = function password(v) {
  return v.length > 7 ? '' : 'Your password must be at least 8 characters long';
};
exports.password = password;
var isEmpty = function isEmpty(src) {
  if ((0, _lodash.isString)(src)) {
    var hasCharacter = src == null ? void 0 : src.trim == null ? void 0 : src.trim().length;
    if (!hasCharacter) {
      return 'Field cannot be empty';
    }
  }
  return undefined;
};
exports.isEmpty = isEmpty;
var _default = {
  required: required,
  url: url,
  email: email,
  uuid: uuid,
  password: password,
  isEmpty: isEmpty
};
exports.default = _default;