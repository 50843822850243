import React, { useState, memo, useContext } from 'react';
import { Delete } from 'uxi/Icons';
import { TextField, DatePickerField as DatePicker } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import {
  LocalizationContext,
  injectIntl,
  FormattedMessage,
} from '@cluedin/locale';

const RuleItemOperatorFieldMultipleInput = injectIntl(
  ({
    intl,
    index,
    value,
    fieldRaw,
    onValueChange,
    valueHasError,
    onRemoveInput,
    isDatePicker,
    showAutocomplete,
    VocabularyValueAutoComplete,
    skipRetrievedValues,
  }) => {
    const theme = useTheme();
    const locales = useContext(LocalizationContext);
    const ruleConditionLocales = locales?.RuleCondition;

    return (
      <>
        <div>
          <FormattedMessage
            id="module-rule-valueIndex"
            values={{
              index: index + 1,
            }}
          />
        </div>
        <div style={{ margin: '6px 0', display: 'flex', alignItems: 'center' }}>
          {isDatePicker && (
            <div style={{ width: '300px' }}>
              <DatePicker
                hasError={valueHasError}
                placeholder={intl.formatMessage({
                  id: ruleConditionLocales.placeholderValueId,
                  defaultMessage: ruleConditionLocales.placeholderValueId,
                })}
                value={value ? new Date(value) : ''}
                onSelectDate={(date) => {
                  const onlyDate = new Date(
                    Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      0,
                      0,
                      0,
                    ),
                  );
                  onValueChange(onlyDate.toISOString(), index);
                }}
              />
            </div>
          )}
          {showAutocomplete && !isDatePicker && (
            <VocabularyValueAutoComplete
              vocabularyKey={fieldRaw}
              hasError={!value && valueHasError}
              value={value}
              skipRetrievedValues={skipRetrievedValues}
              rawValue
              onChange={(value) => {
                onValueChange(value, index);
              }}
            />
          )}

          {!showAutocomplete && !isDatePicker && (
            <div style={{ minWidth: '400px' }}>
              <TextField
                hasError={!value && valueHasError}
                errorMessage={!value && valueHasError}
                placeholder={intl.formatMessage({
                  id: ruleConditionLocales.placeholderValueId,
                  defaultMessage: ruleConditionLocales.placeholderValueId,
                })}
                value={value}
                onChange={(e, value) => {
                  onValueChange(value, index);
                }}
              />
            </div>
          )}

          {index !== 0 && (
            <button
              style={{
                padding: 0,
                border: 'none',
                cursor: 'pointer',
                marginLeft: '6px',
                background: 'transparent',
              }}
              onClick={onRemoveInput}
            >
              <Delete color={theme.palette.red} size="14" />
            </button>
          )}
        </div>
      </>
    );
  },
);

export const RuleItemMultipleValueControls = memo(
  ({
    isDatePicker,
    valueHasError,
    fieldRaw,
    value = [],
    ruleItemIndex,
    showAutocomplete,
    onMultipleValueChange,
    VocabularyValueAutoComplete,
  }) => {
    const theme = useTheme();

    const defaultInputs =
      value.length === 0
        ? [
            {
              type: 'text',
            },
          ]
        : value.map(() => ({
            type: 'text',
          }));

    const [inputs, setInputs] = useState(defaultInputs);

    return (
      <>
        {inputs.map((_, index) => {
          return (
            <RuleItemOperatorFieldMultipleInput
              valueHasError={valueHasError}
              key={index}
              index={index}
              fieldRaw={fieldRaw}
              value={value[index]}
              showAutocomplete={showAutocomplete}
              VocabularyValueAutoComplete={VocabularyValueAutoComplete}
              onValueChange={(valueFromInput, index) => {
                const newRef = [...value];
                newRef[index] = valueFromInput;
                onMultipleValueChange(newRef, ruleItemIndex);
              }}
              onRemoveInput={() => {
                const newInputs = (inputs || []).filter((input, inputIndex) => {
                  return index !== inputIndex;
                });
                const values = (value || []).filter((v, valueIndex) => {
                  return index !== valueIndex;
                });
                setInputs([...newInputs]);
                onMultipleValueChange([...values], ruleItemIndex);
              }}
              isDatePicker={isDatePicker}
            />
          );
        })}
        <button
          style={{
            padding: 0,
            border: 'none',
            cursor: 'pointer',
            marginLeft: '6px',
            background: 'transparent',
            color: theme.palette.themePrimary,
          }}
          onClick={() => {
            setInputs([
              ...(inputs || []),
              {
                type: 'text',
                label: `Value ${inputs.length + 1}`,
              },
            ]);

            if (onMultipleValueChange) {
              onMultipleValueChange([...(value || []), ''], ruleItemIndex);
            }
          }}
        >
          <FormattedMessage id="module-rule-addValue" />
        </button>
      </>
    );
  },
);
