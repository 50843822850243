import { useTheme } from '@cluedin/theme';
import styled, { css } from 'styled-components';
import { Ellipsis } from '@cluedin/components';

const DetailPageHeaderWrapper = styled.div`
  background: #fff;
  padding: 12px;
  display: flex;
  // flex-wrap: wrap;
  border-bottom: 1px solid #d4dad1;
`;

const DetailPageHeaderIcon = styled.div`
  display: flex;
  align-items: top;
  text-align: center;
  margin: 12px;

  ${({ iconWithWrapper, theme }) =>
    iconWithWrapper &&
    css`
      width: 30px;
      height: 30px;
      display: flex;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
      background: ${theme.palette.themePrimary};
    `}
`;

const DetailPageHeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 20px;

  .actions {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }
`;

const DetailPageHeaderContentTitleAndCaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 12px;
    padding-top: 6px;
    word-break: break-word;
    white-space: nowrap;

    .title-action {
      cursor: pointer;
    }

    .title-action:hover {
      opacity: 0.6;
    }
  }

  .caption {
    color: #ccc;
  }
`;

const DetailPageHeader = ({
  icon,
  title,
  actions,
  caption,
  titleAction = false,
  iconWithWrapper = false,
  titleAddition = <></>,
  titleActionClick = null,
}) => {
  const theme = useTheme();

  return (
    <DetailPageHeaderWrapper className="__test-detailPageHeader">
      <DetailPageHeaderIcon theme={theme} iconWithWrapper={iconWithWrapper}>
        {icon}
      </DetailPageHeaderIcon>

      <DetailPageHeaderContent className="DetailPageHeaderContent">
        <DetailPageHeaderContentTitleAndCaptionWrapper>
          <div className="title" style={{ display: 'inline-flex' }}>
            <div style={{ maxWidth: 500 }}>
              <Ellipsis>{title}</Ellipsis>
            </div>
            {titleAction && (
              <div className="title-action" onClick={titleActionClick}>
                {titleAction}
              </div>
            )}
            {titleAddition}
          </div>

          <div className="caption">{caption}</div>
        </DetailPageHeaderContentTitleAndCaptionWrapper>

        <div className="actions DetailPageHeaderContent-actions">{actions}</div>
      </DetailPageHeaderContent>
    </DetailPageHeaderWrapper>
  );
};

export default DetailPageHeader;
