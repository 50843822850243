import React, { lazy, Suspense } from 'react';
import PageLoader from '../../components/composites/PageLoader';

export const makeLazy = (ImportStatement) => {
  const PageComponent = lazy(ImportStatement);

  return (props) => (
    <Suspense fallback={<PageLoader />}>
      <PageComponent {...props} />
    </Suspense>
  );
};

export default {
  makeLazy,
};
