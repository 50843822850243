// import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
// import SearchPageV3 from './components/pages/SearchPageV3';
import SearchPageV4 from './components/pages/SearchPageV4';

const SearchRoutes = [
  {
    path: '/search/organization',
    exact: true,
    component: SearchPageV4,
  },
  {
    path: '/search/person',
    exact: true,
    component: SearchPageV4,
  },
  // {
  //   path: '/search',
  //   exact: true,
  //   component: SearchPageV3,
  // },
  {
    //path: '/searchV4',
    path: '/search',
    exact: true,
    component: SearchPageV4,
  },
];

export default SearchRoutes;
