import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

const IconWrapperRoundedWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ size }) => size * 2}px;
  background: ${({ themePrimary }) => themePrimary};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  & svg * {
    fill: ${({ color }) => color} !important;
  }
`;

const IconWrapperRounded = ({
  color = '#fff',
  Icon,
  size = 28,
  iconSize = 14,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <IconWrapperRoundedWrapper
      themePrimary={themePrimary}
      size={size}
      color={color}
    >
      <Icon height={`${iconSize}px`} width={`${iconSize}px`} />
    </IconWrapperRoundedWrapper>
  );
};

export default IconWrapperRounded;
