import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import { Datapolicy } from 'uxi/Icons';

const { governance } = actionsConfiguration;

export default {
  pillar: 'governance',
  name: 'pii',
  displayName: <FormattedMessage id="pii" />,
  path: '/pii',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Datapolicy,
        name: <FormattedMessage id="pii" />,
        link: '/admin/governance/pii',
        claims: governance.gdpr.personalidentifiers.view,
      },
    ],
  },
  routes,
};
