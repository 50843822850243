import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

const GET_HELP_MENU = gql`
  query {
    help {
      id
      root {
        id
        excerpt
        title
        content
        modules {
          id
          excerpt
          title
          content
        }
      }
    }
  }
`;

export const useQueryHelpMenu = () => {
  const fetchPolicy = 'cache-and-network';
  const { data, loading, error, refetch } = useQuery(GET_HELP_MENU, {
    fetchPolicy,
  });
  const help = get(data, 'help.root');

  return [help, loading, error, refetch];
};
