import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { Ellipsis, AlertIcon } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { AlertTriangle } from '@cluedin/svgs';
import styled from 'styled-components';

import {
  EntityListValueWrapper,
  EntityListRawValue,
} from './EntityListValue.styles';

const IconWrapper = styled.span`
  padding-right: 6px;
`;

const EntityListValue = ({ isAnonymized, value, vocabularyKey, errorText }) => {
  const dataType = get(vocabularyKey, 'dataType');
  const isValidDate = moment(value.toString()).isValid();

  if (dataType === 'DateTime' || dataType === 'Date') {
    return (
      <Ellipsis>
        <EntityListValueWrapper>
          {!isAnonymized && value ? (
            <div>{value && isValidDate && moment(value).format('LLL')}</div>
          ) : (
            <div>
              <em>
                <FormattedMessage id="module-entityProperties-tableEntityValueAnonymized" />
              </em>
            </div>
          )}
          <EntityListRawValue>{value}</EntityListRawValue>
        </EntityListValueWrapper>
      </Ellipsis>
    );
  }

  return (
    <Ellipsis>
      {errorText && (
        <IconWrapper title={errorText}>
          <AlertTriangle
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        </IconWrapper>
      )}
      <EntityListValueWrapper
        style={{
          display: 'inline-block',
        }}
      >
        {value}
      </EntityListValueWrapper>
    </Ellipsis>
  );
};

export default EntityListValue;

EntityListValue.propTypes = {
  isAnonymized: PropTypes.bool,
  value: PropTypes.string,
  vocabularyKey: PropTypes.object,
};

EntityListValue.defaultProps = {
  isAnonymized: null,
  value: '',
  vocabularyKey: {},
};
