import React from 'react';
import { Select } from '@cluedin/form';
import { FirstLetterComponent } from '@cluedin/components';
import { injectIntl } from '@cluedin/locale';
import { CoreVocabulary } from '@cluedin/svgs';
import { AvatarWithName } from 'uxi/Img';
import { useQueryConnectors } from '../../../hooks/useQueryAllConnectors';
import { urlBuilder } from '../../../../../config';
import { removeFirstCharacter } from '../../../../core/helpers/string';

const AllConnectors = ({ value, onChange, intl, ...rest }) => {
  const [allConnectors, loading] = useQueryConnectors();

  const newOptions = allConnectors.map((int) => {
    const iconUrl =
      int && int.icon && int.icon[0] === '/'
        ? removeFirstCharacter(int.icon)
        : int.icon;
    const fullIconUrl = iconUrl ? urlBuilder.api(iconUrl) : null;

    return {
      value: int.id,
      label: int.name,
      icon: (
        <AvatarWithName
          contain
          isSquare
          src={fullIconUrl}
          icon={
            int.icon ? null : (
              <FirstLetterComponent
                name={int.name}
                customSize="24"
                customFontSize="14"
              />
            )
          }
          style={{ display: 'inline-flex' }}
        />
      ),
    };
  });

  const handleOnChange = ({ value }) => {
    onChange(value);
  };

  if (loading) {
    return <span />;
  }

  return (
    <div {...rest} style={{ ...(rest?.style ?? {}), minWidth: '200px' }}>
      <Select
        value={value || ''}
        onChange={handleOnChange}
        options={[
          {
            value: '',
            label: intl.formatMessage({
              id: 'module-integration-allIntegrationsFilter',
            }),
          },
          {
            value: 'noSource',
            label: intl.formatMessage({
              id: 'module-integration-noSourceFilter',
            }),
          },
          {
            value: 'core',
            label: intl.formatMessage({ id: 'module-integration-coreFilter' }),
            icon: (
              <CoreVocabulary
                style={{
                  height: '24px',
                  maxHeight: '24px',
                  marginRight: '6px',
                  width: '24px',
                  maxWidth: '24px',
                }}
              />
            ),
          },
          ...newOptions,
        ]}
      />
    </div>
  );
};

export default injectIntl(AllConnectors);
