"use strict";

exports.__esModule = true;
exports.reducer = exports.initialState = exports.default = void 0;
var _actions = require("./actions");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); } /* eslint-disable no-case-declarations */
var initialState = {
  columns: [],
  isResizing: false,
  isResizingProp: undefined,
  isResizingNextProp: undefined,
  pageX: undefined,
  currColWidth: undefined,
  nextColWidth: undefined,
  hasBeenResizedOnce: false,
  isReordering: null,
  cRectHeight: undefined,
  baseCellWidth: 240,
  display: 'table',
  extraColWidth: null,
  selected: []
};
exports.initialState = initialState;
var reducer = function reducer(state, _ref) {
  var type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case _actions.C.SET_SELECTED:
      return _extends({}, state, {
        selected: payload
      });
    case _actions.C.SET_DISPLAY:
      return _extends({}, state, {
        display: payload
      });
    case _actions.C.SET_EXTRA_COL_WIDTH:
      return _extends({}, state, {
        extraColWidth: payload > 0 ? payload : null
      });
    case _actions.C.STORE_C_RECT_HEIGHT:
      return _extends({}, state, {
        cRectHeight: payload
      });
    case _actions.C.SET_COLUMNS:
      return _extends({}, state, {
        columns: [].concat(payload)
      });
    case _actions.C.SHOW_COLUMN:
      return _extends({}, state, {
        columns: state.columns.map(function (c) {
          return c.property === payload ? console.log(c.property, c.width) || _extends({}, c, {
            hide: false
          },
          // eslint-disable-next-line no-nested-ternary
          c.width === undefined ? state.display !== 'table' ? {
            width: state.baseCellWidth
          } : {} : {}) : c;
        })
      });
    case _actions.C.HIDE_COLUMN:
      return _extends({}, state, {
        columns: state.columns.filter(function (c) {
          return !c.hide;
        }).length > 1 ? state.columns.map(function (c) {
          return c.property === payload ? _extends({}, c, {
            hide: true
          }) : c;
        }) : state.columns
      });
    case _actions.C.SET_IS_REORDERING:
      if (state.isResizing) {
        return _extends({}, state, {
          isReordering: null
        });
      }
      return _extends({}, state, {
        isReordering: payload
      });
    case _actions.C.SET_COLUMN_ORDER:
      {
        var propA = payload[0],
          propB = payload[1];
        var _newCols = [].concat(state.columns);
        var idxA = _newCols.findIndex(function (c) {
          return c.property === propA;
        });
        var idxB = _newCols.findIndex(function (c) {
          return c.property === propB;
        });
        _newCols.splice(idxA, 1, state.columns[idxB]);
        _newCols.splice(idxB, 1, state.columns[idxA]);
        var idxBVisibleOnly = _newCols.filter(function (x) {
          return !x.hide;
        }).findIndex(function (c) {
          return c.property === propA;
        });
        return _extends({}, state, {
          columns: _newCols,
          isReordering: idxBVisibleOnly
        });
      }
    case _actions.C.SET_IS_RESIZING:
      var propIdx = state.columns.findIndex(function (c) {
        return c.property === payload.property;
      });
      var siblingsProp = propIdx > -1 ? state.columns.slice(propIdx + 1).find(function (x) {
        return !x.hide;
      }) : undefined;
      var nenwColumns = state.columns.map(function (c) {
        return c.property === payload.property ? _extends({}, c, {
          width: payload.currColWidth
        }) : c;
      }).map(function (c) {
        return c.property === (siblingsProp || {}).property ? _extends({}, c, {
          width: payload.nextColWidth ? payload.nextColWidth : c.width
        }) : c;
      });
      return _extends({}, state, {
        hasBeenResizedOnce: true,
        isResizing: payload.isResizing,
        pageX: payload.pageX,
        isResizingProp: payload.property,
        isResizingNextProp: (siblingsProp || {}).property,
        currColWidth: payload.currColWidth,
        nextColWidth: payload.nextColWidth,
        columns: nenwColumns
      });
    case _actions.C.SET_COLUMN_W:
      var newCols = state.columns.map(function (c) {
        return c.property === payload.property ? _extends({}, c, {
          width: payload.width
        }) : c;
      });

      // const hasBeenResizedOnce = (
      //   newCols.filter(x => x.width !== undefined).length
      //   === newCols.length
      // );

      return _extends({}, state, {
        // hasBeenResizedOnce,
        columns: newCols
      });
    case _actions.C.SET_CURR_COLUMN_W:
      return _extends({}, state, {
        columns: state.columns.map(function (c) {
          return c.property === state.isResizingProp ? _extends({}, c, {
            width: payload
          }) : c;
        })
      });
    case _actions.C.SET_NEXT_COLUMN_W:
      return _extends({}, state, {
        columns: state.columns.map(function (c) {
          return c.property === state.isResizingNextProp ? _extends({}, c, {
            width: payload
          }) : c;
        })
      });
    default:
      return _extends({}, state);
  }
};

/*

// eslint-disable-next-line no-shadow
const middleware = reducer => (state, { type, payload }) => {
  console.log('––––––––––––––––––––––––––––', '\n');
  console.log(`ACTION ${type} PREV STATE  :`, '\n', state);
  console.log(`ACTION ${type} WITH PAYLOAD:`, '\n', JSON.parse(JSON.stringify(payload)));
  const newState = reducer(state, { type, payload });
  console.log(`ACTION ${type} NEXT STATE  :`, '\n', newState);
  return newState;
};

export default middleware(reducer);

*/
exports.reducer = reducer;
var _default = reducer;
exports.default = _default;