import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import SigninForm from './SigninForm';
import { redirectAfterLogin, saveUsernameInStore } from '../../../actions';
import { clientId } from '../../../../../config';
import { email as isEmail } from '../../../../../helpers/validation';
import { useSignInMutation } from '../../../hooks/useSignInMutation';
import { useOrgNameAvailable } from '../../../hooks/useOrgNameAvailable';
import PageLoader from '../../../../core/components/composites/PageLoader';
import SSOWrapper from './SSOWrapper';

const validPassword = (value) => {
  if (value.length < 8) {
    return (
      <span data-test="short-password-error">
        Password must contain at least 8 characters
      </span>
    );
  }

  return null;
};

// if (teamDomain && teamDomain.SingleSignOn && teamDomain.SingleSignOn.LoginUrl) {
//   if (type === 'login') {
//     loginWithSSO(teamDomain.SingleSignOn);
//   }
//   if (type === 'changePassword' && teamDomain.SingleSignOn.ChangePasswordUrl) {
//     loginWithSSO(teamDomain.SingleSignOn);
//   }
// }

const SigninFormContainer = () => {
  const [teamDomainInfo, loadingOrgInfo] = useOrgNameAvailable();
  const loginUrl = get(teamDomainInfo, 'SingleSignOn.LoginUrl');
  const [signIn, { token, loading: loadingSignin, error: errorSignIn }] =
    useSignInMutation(clientId);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [touchedEmail, setTouchedEmail] = useState(false);
  const [touchedPassword, setTouchedPassword] = useState(false);

  const emailRequired = !!email ? (
    ''
  ) : (
    <span data-test="email-address-required">Email address is required</span>
  );
  const emailError = email ? (
    isEmail(email)
  ) : (
    <span data-test="invalid-email-address">Invalid email address</span>
  );
  const passwordRequired = !!password ? (
    ''
  ) : (
    <span data-test="password-required">Password is required</span>
  );
  const passwordError = password ? (
    validPassword(password)
  ) : (
    <span data-test="invalid-password">Invalid password</span>
  );

  const invalidForm =
    emailRequired || emailError || passwordRequired || passwordError;

  useEffect(() => {
    if (token) {
      saveUsernameInStore({
        isRememberMe: false,
        clientId: clientId,
        username: email,
      });
      redirectAfterLogin({ token, clientId });
    }
  }, [token]);

  if (loadingOrgInfo) {
    return <PageLoader />;
  }

  return (
    <div>
      <SSOWrapper
        ssologinUrl={loginUrl}
        ssoConfig={teamDomainInfo.SingleSignOn}
        cluedInLogin={
          <SigninForm
            invalidLogin={errorSignIn}
            loadingSignin={loadingSignin}
            clientId={clientId}
            email={email}
            setEmail={setEmail}
            touchedEmail={touchedEmail}
            setTouchedEmail={setTouchedEmail}
            touchedPassword={touchedPassword}
            setTouchedPassword={setTouchedPassword}
            password={password}
            setPassword={setPassword}
            emailRequired={emailRequired}
            emailError={emailError}
            passwordRequired={passwordRequired}
            passwordError={passwordError}
            invalidForm={invalidForm}
            loginHandler={() => {
              if (!invalidForm) {
                signIn({
                  email,
                  password,
                });
              }
            }}
          />
        }
      />
    </div>
  );
};

export default SigninFormContainer;
