"use strict";

exports.__esModule = true;
exports.urlBuilder = void 0;
var _env = require("../env");
var buildUrl = function buildUrl(base, relURL) {
  return relURL && relURL[0] === '/' ? "" + base + relURL.substring(1) : "" + base + (relURL || '');
};
var urlBuilder = {
  auth: function auth(relURL) {
    return buildUrl(_env.authURL, relURL);
  },
  api: function api(relURL) {
    return buildUrl(_env.apiURL, relURL);
  }
};
exports.urlBuilder = urlBuilder;