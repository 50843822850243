import React, { Fragment } from 'react';
import catalog from './catalog';

const defaultRegistry = {
  widgets: catalog,
};

export const WidgetRegistryContext = React.createContext(defaultRegistry);

const WidgetRegistry = ({ children, widgets }) => {
  const updatedRegistry = {
    ...defaultRegistry,
    widgets: [...defaultRegistry.widgets, ...(widgets || [])],
  };

  return (
    <Fragment>
      <WidgetRegistryContext.Provider value={updatedRegistry}>
        {children}
      </WidgetRegistryContext.Provider>
    </Fragment>
  );
};

export default WidgetRegistry;
