import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import AccessDeniedComposite from '../composites/AccessDeniedComposite';

const AccessDeniedContainer = ({ dispatch, feature, message }) => (
  <AccessDeniedComposite
    feature={feature}
    message={message}
    onBackToHomeClick={() => {
      dispatch(push('/'));
    }}
  />
);

export default connect()(AccessDeniedContainer);
