import { createAction } from 'redux-actions';

import {
  createAsyncAction,
  defaultAsyncCallWithActionsWithParam,
} from '../../action/helpers/actionCreator';
import { updateNewFilter, removeNewFilter } from './helper';
import { search, aggregationSearch, suggestedSearch } from './data';
import { makeAsyncActionWatcherSaga } from './saga-utils';

export const aggregationSearchActions = createAsyncAction('SEARCH_AGGREGATION');

export const shouldFetchAggregationSearch =
  defaultAsyncCallWithActionsWithParam(
    aggregationSearch,
    aggregationSearchActions,
  );

export const quickSearchResultActions = createAsyncAction(
  'GET_DUPLICATES_SEARCH_RESULT',
);

export const shouldQuickSearchResult = defaultAsyncCallWithActionsWithParam(
  search,
  quickSearchResultActions,
);

/**
 * Main Search saga with default "takeLatest" */
/*
const SEARCH_MODULE_SEARCH = 'SEARCH_MODULE_SEARCH';
export const shouldFetchMainSearchSagaActions = createAsyncAction(SEARCH_MODULE_SEARCH);
export const mainSearchActionsWatcherSaga = makeAsyncActionWatcherSaga(
  SEARCH_MODULE_SEARCH,
  search,
  true,
  (payload, resp) => ({ result: resp, param: payload }),
  err => err.message,
); */
// export const shouldFetchSearch = shouldFetchMainSearchSagaActions.request;
// export const shouldFetchSearch = () => ({ type: 'GFY' });

export const shouldStoreUpdatedMainSearchQuery = createAction(
  'SEARCH_MODULE_STORE_UPDATED_SEARCH_QUERY',
);
/**
 * shouldUpdateMainSearchQuery
 * @param {Object: consumerSearchModel} search
 * @returns void
 *
 */
export const shouldUpdateMainSearchQuery =
  ({ query }) =>
  (dispatch) => {
    dispatch(
      shouldStoreUpdatedMainSearchQuery({
        query,
      }),
    );
  };

export const shouldStoreMainSearchUpdatedFilters = createAction(
  'SEARCH_MODULE_STORE_UPDATED_FILTERS',
);
export const shouldAddSearchFilter =
  ({ filter, selectedFilters }) =>
  (dispatch) => {
    const newSearchFilters = updateNewFilter(filter, selectedFilters || []);
    dispatch(
      shouldStoreMainSearchUpdatedFilters({ filters: newSearchFilters }),
    );
  };

export const shouldClearAllSearchFilter = () => (dispatch) => {
  dispatch(shouldStoreMainSearchUpdatedFilters({ filters: [] }));
};

export const shouldRemoveSearchFilter =
  ({ filter, selectedFilters }) =>
  (dispatch) => {
    const newSearchFilters = removeNewFilter(filter, selectedFilters || []);
    dispatch(
      shouldStoreMainSearchUpdatedFilters({ filters: newSearchFilters }),
    );
  };

export const shouldStoreSortType = createAction(
  'SEARCH_MODULE_SEARCH_STORE_SORT_TYPE',
);

export const shouldShowAdvancedSearch = createAction(
  'SEARCH_MODULE_SHOW_ADVANCED_SEARCH_DIALOG',
);
export const shouldHideAdvancedSearch = createAction(
  'SEARCH_MODULE_HIDE_ADVANCED_SEARCH_DIALOG',
);

export const shouldUpdateEntityTypePoolIdentifier = createAction(
  'SEARCH_MODULE_UPDATE_ENTITY_TYPE_POOL_IDENTIFIER',
);

export const shouldUpdateQueryCurrentInputValue = createAction(
  'SEARCH_MODULE_UPDATE_QUERY_CURRENT_INPUT_VALUE',
);

export const shouldShowPropertyFilters = createAction(
  'SHOW_SEARCH_PROPERTY_FILTERS',
);
export const shouldHidePropertyFilters = createAction(
  'HIDE_SEARCH_PROPERTY_FILTERS',
);

/**
 * Search suggestions saga with default "takeLatest" */
const SEARCH_MODULE_SUGGESTIONS_SAGA = 'SEARCH_MODULE_SUGGESTIONS_SAGA';
export const shouldFetchSearchSuggestionsSagaActions = createAsyncAction(
  SEARCH_MODULE_SUGGESTIONS_SAGA,
);

export const searchSuggestionsActionsWatcherSaga = makeAsyncActionWatcherSaga(
  SEARCH_MODULE_SUGGESTIONS_SAGA,
  suggestedSearch,
  true,
  (payload, result) => ({ result }),
);

export const shouldFetchSearchSuggestions =
  shouldFetchSearchSuggestionsSagaActions.request;
