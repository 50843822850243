import styled, { css } from 'styled-components';

export const HistoryChangeSetBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const HistoryChangeSetWrapper = styled.div`
  align-items: center;
  background: ${({ isExpanded, theme }) =>
    isExpanded ? theme.palette.themeLighterAlt : '#ffffff'};
  border: 1px solid #ececec;
  border-top: 0;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 14px 30px;
  transition: all 100ms ease;

  &:hover {
    background: ${({ isExpanded, theme }) =>
      isExpanded ? theme.palette.themeLighter : '#fafafa'};
  }

  & svg {
    margin-right: 10px;
  }
`;

export const HistoryChangeSetDate = styled.span`
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
`;

export const HistoryChangeSetSourceWrapper = styled.div`
  height: 100%;
  transition: all 100ms ease;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;

export const HistoryChangeRecordCount = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
