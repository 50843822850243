import React from 'react';
import styled from 'styled-components';
import { registerEntityWidget } from '../../registry';
import { FormattedMessage } from '@cluedin/locale';
import EntitySourceLine from './EntitySourceLine';
import { uniqProviderBasedOnUrisAndProviderCodes } from '../../../../entity/selectors';

const EntitySourceAndProfileWrapper = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const EntitySourceAndProfile = ({ entity }) => {
  const mergedUrisAndProviders = uniqProviderBasedOnUrisAndProviderCodes(
    entity ? entity.data.uris : [],
    entity ? entity.providers : [],
  );

  return (
    <EntitySourceAndProfileWrapper>
      {(mergedUrisAndProviders || []).map((integrationWithUris, i) => (
        <EntitySourceLine
          key={`EntitySourceLine-${integrationWithUris.name}-${i}`}
          integrationWithUris={integrationWithUris}
        />
      ))}
    </EntitySourceAndProfileWrapper>
  );
};

EntitySourceAndProfile.displayName = 'EntitySourceAndProfile';

registerEntityWidget('EntitySourceAndProfile', {
  name: 'Information Sources',
  displayName: <FormattedMessage id="module-entityWidget-informationSource" />,
  description: 'Display the list of information source',
  view: EntitySourceAndProfile,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
