import { connect } from 'react-redux';
import get from 'lodash/get';
import { appendTitle } from '../../../actions';

export const withCrumb = (Comp) => {
  const mapDispatchToProps = {
    setTitle: appendTitle,
  };

  const mapStateToProps = (state) => ({
    currentTitle: get(state, ['coreModule', 'currentTitle']),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Comp);
};

export default withCrumb;
