import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import { Flex } from 'uxi/Layout';
import { getScaledValueWithMetricNotation } from '../../../helpers/numbers';

const DashboardKPIWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  height: calc(100% - 16px);
  min-height: calc(146px - 16px);

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px -2px rgba(0, 0, 0, 0.12);

  color: white;
  ${({ isFullHeight }) => (isFullHeight ? 'height: calc(100% - 32px)' : '')};
  background: ${({ type, theme: { palette } }) =>
    palette.semantic[type] || '#466ca2'};
`;

const CornerArrow = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  margin: 0;
  width: 0;
  height: 0;
  border-top: 14px solid transparent; /* 15 on purpose */
  border-bottom: 14px solid transparent;
  border-left: 14px solid white;
  transform: translateY(-7px) rotate(-45deg);
  transform-origin: center;
`;

const DashboardKPI = ({
  icon,
  value,
  title,
  type,
  hasNew,
  style,
  isFullHeight,
  className,
  disabled,
}) => {
  const hasNewContent =
    hasNew > 0 ? (
      <div style={{ opacity: 0.7, fontSize: 'thin' }}>
        {hasNew > 2000 ? `+ ${Math.floor(hasNew / 1000)}k` : `+ ${hasNew}`}{' '}
        since last visit
      </div>
    ) : null;

  const displayValue = Number.isInteger(value)
    ? getScaledValueWithMetricNotation(value)
    : value;

  return (
    <DashboardKPIWrapper
      className={className}
      isFullHeight={isFullHeight}
      type={type}
      style={style}
      disabled={disabled}
    >
      <CornerArrow />
      <Flex
        style={{
          width: '100%',
          padding: '8px',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Flex
          style={{
            justifyContent: 'flex-start',
            width: '100%',
            fontSize: '42px',
            fontWeight: '500',
          }}
        >
          {React.cloneElement(icon, { size: 42 })}
          <TextEllipsis style={{ paddingLeft: '16px' }}>
            {displayValue}
          </TextEllipsis>
        </Flex>
        <div style={{ flexGrow: 9, width: '100%', padding: '4px 0' }}>
          <TextEllipsis>{title}</TextEllipsis>
        </div>
        <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
          <TextEllipsis>{hasNewContent}</TextEllipsis>
        </Flex>
      </Flex>
    </DashboardKPIWrapper>
  );
};

DashboardKPI.defaultProps = {
  type: 'info',
  hasNew: 0,
  badgeType: 'error',
};

DashboardKPI.defaultProps = {
  type: PropTypes.oneOf(['error', 'warning', 'success', 'information']),
  hasNew: PropTypes.number,
};

DashboardKPI.displayName = 'DashboardKPI';

export default DashboardKPI;
