import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

import routes from './routes';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  name: 'roles',
  displayName: <FormattedMessage id="roles" />,
  path: '/roles',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'administrator',
        name: <FormattedMessage id="roles" />,
        link: '/admin/settings/roles',
        claims: admin.roles.role.view,
      },
    ],
  },
  routes,
};
