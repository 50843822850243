"use strict";

exports.__esModule = true;
exports.getEnv = void 0;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var getEnv = function getEnv() {
  var variables = window.cluedInEnv || process.env;
  if (!(0, _isNil.default)(variables) && !(0, _isEmpty.default)(variables)) {
    return {
      variables: variables,
      legacy: false
    };
  }
  try {
    var $inputVars = document.getElementById('runtimeConfiguration');
    variables = JSON.parse($inputVars.value);
  } catch (error) {
    console.error(error);
  }
  return {
    variables: variables,
    legacy: true
  };
};
exports.getEnv = getEnv;