import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from '@cluedin/form';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // eslint-disable-line consistent-return
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const PageLoader = () => {
  const [count, setCount] = useState(0);

  useInterval(() => {
    // Your custom logic here
    setCount(count < 3 ? count + 1 : 0);
  }, 256);

  const dots = [...new Array(count)].map(() => '.').join('');

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '200px',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Spinner />
      <small style={{ textAlign: 'left', width: '86px', color: 'grey' }}>
        Fetching data{dots}
      </small>
    </div>
  );
};

export default PageLoader;
