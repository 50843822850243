import { useRef, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { ButtonLink } from '@cluedin/atoms';
import { IconButton } from '@fluentui/react/lib/Button';
import { useTheme } from '@cluedin/theme';
import { Callout, mergeStyleSets, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { FormattedMessage } from '@cluedin/locale';
import usePrevious from '../../../../core/hooks/usePrevious';
import { useHistory } from 'react-router-dom';
import { FileUploadRequestWithProgress } from '../../../types';

const styles = mergeStyleSets({
  callout: {
    width: 240,
    maxWidth: '90%',
  },
});

type UploadFileProgressCalloutProps = {
  buttonId: string;
  openWorkflow: () => void;
  onClose: () => void;
  numberOfError: number;
  hasMultipleFiles: boolean;
  completedNumberFiles: number;
  completedItems: FileUploadRequestWithProgress[];
};

export const UploadFileProgressCallout = ({
  buttonId,
  onClose,
  openWorkflow,
  numberOfError,
  hasMultipleFiles,
  completedNumberFiles,
  completedItems,
}: UploadFileProgressCalloutProps) => {
  const hasError = numberOfError > 0;
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');
  const theme = useTheme();
  let count = 5;
  const timeoutHandler = useRef<NodeJS.Timeout>();
  const prevCompleted = usePrevious(completedNumberFiles);
  const history = useHistory();

  const startIntervalCounter = () => {
    timeoutHandler.current = setInterval(() => {
      if (count > 0) {
        count = count - 1;
      }
      if (count === 0) {
        onClose();
        clearIntervalCount();
      }
    }, 1000);
  };

  const clearIntervalCount = () => {
    if (timeoutHandler.current) {
      clearInterval(timeoutHandler.current);
    }
    count = 5;
  };

  useEffect(() => {
    if (prevCompleted !== completedNumberFiles) {
      clearIntervalCount();
      count = 5;
      startIntervalCounter();
    }
  }, [completedNumberFiles, prevCompleted]);

  useEffect(() => {
    return () => {
      clearIntervalCount();
    };
  }, []);

  return (
    <Callout
      className={styles.callout}
      ariaLabelledBy={labelId}
      ariaDescribedBy={descriptionId}
      role="dialog"
      gapSpace={4}
      target={`#${buttonId}`}
    >
      <div style={{ padding: '24px' }}>
        <div style={{ position: 'absolute', top: '12px', right: '12px' }}>
          <IconButton
            style={{
              width: '14px',
              height: '14px',
            }}
            data-test="pager-close-upload-callout"
            onClick={onClose}
            iconProps={{
              iconName: 'Cancel',
              style: {
                height: 14,
                fontSize: 14,
                color: theme.palette.neutralPrimary,
              },
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!hasError && (
            <Icon
              iconName="CheckMark"
              style={{
                height: 12,
                fontSize: 12,
                lineHeight: '14px',
                backgroundColor: theme.palette.themePrimary,
                color: '#fff',
                borderRadius: '14px',
                padding: '2px',
                display: 'flex',
                marginRight: '12px',
              }}
            />
          )}
          {hasError && (
            <Icon
              iconName="OpenFile"
              style={{
                height: 14,
                fontSize: 14,
                lineHeight: '14px',
                marginRight: '12px',
                color: theme.semanticColors.errorText,
              }}
            />
          )}
          <div>
            {hasError && (
              <Text
                style={{
                  color: theme.semanticColors.errorText,
                }}
                block
                id={descriptionId}
              >
                {numberOfError > 1 ? (
                  <FormattedMessage
                    id="module-uploadFile-callOutErrorFiles"
                    values={{ numberOfError }}
                  />
                ) : (
                  <FormattedMessage
                    id="module-uploadFile-callOutErrorFile"
                    values={{ numberOfError }}
                  />
                )}
              </Text>
            )}
            {!hasError && (
              <Text block id={descriptionId}>
                {hasMultipleFiles ? (
                  <FormattedMessage
                    id="module-uploadFile-filesFinished"
                    values={{ numberOfError }}
                  />
                ) : (
                  <FormattedMessage
                    id="module-uploadFile-oneFileFinished"
                    values={{ numberOfError }}
                  />
                )}
              </Text>
            )}
            <div style={{ marginTop: '6px' }}>
              {completedItems &&
                completedItems.length === 1 &&
                completedItems[0] && (
                  <ButtonLink
                    style={{ marginRight: '6px' }}
                    onClick={() => {
                      history.push(
                        `/admin/inbound/datasourceset/datasource/${completedItems[0].fileUploadRequest.dataSourceId}`,
                      );
                    }}
                  >
                    <FormattedMessage id="module-uploadFile-viewOnlyFile" />
                  </ButtonLink>
                )}
              {completedItems && completedItems.length > 1 && (
                <ButtonLink
                  onClick={() => {
                    onClose();
                    openWorkflow();
                  }}
                  text={
                    <FormattedMessage id="module-uploadFile-viewFileStatus" />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Callout>
  );
};
