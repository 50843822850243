import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';

import EntityTopologySplitNewDataPart from '../EntityTopologySplitNewDataPart/EntityTopologySplitNewDataPart';
import {
  EntityTopologySplitPreviewNewEntityWrapper,
  EntityTopologySplitPreviewNewEntityInnerWrapper,
  EntityTopologySplitPreviewNewEntityHeading,
  EntityTopologySplitPreviewNewEntityHeadingWrapper,
  EntityTopologySplitPreviewNewEntityDetailsWrapper,
  EntityTopologySplitPreviewNewEntityArrowWrapper,
  EntityTopologySplitPreviewNewEntityBorderCircle,
  EntityTopologySplitPreviewNewEntityDetails,
  EntityTopologySplitPreviewNewEntityDetailsInner,
  ExplainLogIdentifierSourceWrapper,
  EntityTopologySplitPreviewNewEntityBox,
} from './EntityTopologySplitPreviewNewEntity.styles';

const EntityTopologySplitPreviewNewEntity = ({
  index,
  data,
  entityId,
  currentNode,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <EntityTopologySplitPreviewNewEntityWrapper>
      <EntityTopologySplitPreviewNewEntityBox isExpanded={isExpanded}>
        <EntityTopologySplitPreviewNewEntityInnerWrapper>
          <EntityTopologySplitPreviewNewEntityHeading isExpanded={isExpanded}>
            <EntityTopologySplitPreviewNewEntityHeadingWrapper>
              <EntityTopologySplitPreviewNewEntityDetailsWrapper
                data-test="entity-entityTopology-splitPreviewNewEntityheader"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <EntityTopologySplitPreviewNewEntityArrowWrapper>
                  {isExpanded ? (
                    <Arrowdown color={themePrimary} size={16} />
                  ) : (
                    <Arrowright color={themePrimary} size={16} />
                  )}
                </EntityTopologySplitPreviewNewEntityArrowWrapper>

                <EntityTopologySplitPreviewNewEntityBorderCircle
                  themePrimary={themePrimary}
                />

                <EntityTopologySplitPreviewNewEntityDetails>
                  <EntityTopologySplitPreviewNewEntityDetailsInner>
                    <strong>
                      <FormattedMessage id="module-entityTopology-entity" />
                      &nbsp;{index}
                    </strong>
                    {data?.dataParts &&
                      (data?.dataParts.length === 1 ? (
                        <FormattedMessage
                          id="module-entityTopology-totalRecord"
                          values={{ total: data?.dataParts?.length }}
                        />
                      ) : (
                        <FormattedMessage
                          id="module-entityTopology-totalRecords"
                          values={{ total: data?.dataParts?.length }}
                        />
                      ))}
                  </EntityTopologySplitPreviewNewEntityDetailsInner>
                </EntityTopologySplitPreviewNewEntityDetails>
              </EntityTopologySplitPreviewNewEntityDetailsWrapper>
            </EntityTopologySplitPreviewNewEntityHeadingWrapper>
          </EntityTopologySplitPreviewNewEntityHeading>
        </EntityTopologySplitPreviewNewEntityInnerWrapper>

        <ExplainLogIdentifierSourceWrapper isExpanded={isExpanded}>
          {data &&
            data?.dataParts &&
            data?.dataParts.length > 0 &&
            (data?.dataParts || []).map((dataPart) => (
              <EntityTopologySplitNewDataPart
                key={`${dataPart?.dataPartId}-${dataPart?.name}`}
                data={dataPart}
                entityId={entityId}
                isNewEntityDataPart
                currentNode={currentNode}
              />
            ))}
        </ExplainLogIdentifierSourceWrapper>
      </EntityTopologySplitPreviewNewEntityBox>
    </EntityTopologySplitPreviewNewEntityWrapper>
  );
};

export default EntityTopologySplitPreviewNewEntity;

EntityTopologySplitPreviewNewEntity.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  entityId: PropTypes.string,
  currentNode: PropTypes.object,
  currentNode: PropTypes.object,
};

EntityTopologySplitPreviewNewEntity.defaultProps = {
  index: null,
  data: {},
  entityId: null,
  currentNode: {},
  currentNode: {},
};
