import moment from 'moment';
import { getFullToken, saveTokenForClientId } from './storage';
import { refreshToken } from '../../auth/data';
import { clientId } from '../../../config';
import { getAntiForgeryTokenFromDOM } from './dom';

//need to fix this
export const isTokenExpired = (expiresAt) => {
  return moment().isAfter(moment(expiresAt));
};

export const createExpiredDate = (expiresIn = 0) => {
  let addToCurrentDate = expiresIn;

  if (expiresIn > 60) {
    // we add a 5 minute buffer to avoid issues
    addToCurrentDate = expiresIn - 60 * 5;
  }

  return moment().add(addToCurrentDate, 'seconds').toString();
};

let getNewTokenPromise;

export const getNewToken = () => {
  if (!getNewTokenPromise) {
    const fullToken = getFullToken();

    if (!fullToken || !fullToken.refreshToken) {
      return new Promise((resolve, reject) => {
        reject('error');
      });
    }

    getNewTokenPromise = refreshToken(fullToken.refreshToken).then(
      (authInfo = {}) => {
        //if authinfo is null, it means the call failed
        if (authInfo.error) {
          getNewTokenPromise = null;
          return null;
        }

        saveTokenForClientId(
          {
            accessToken: authInfo.access_token,
            expiresIn: authInfo.expires_in,
            scope: authInfo.scope,
            refreshToken: authInfo.refresh_token,
          },
          clientId,
        );
        getNewTokenPromise = null;
        return authInfo;
      },
    );
  }

  return getNewTokenPromise;
};

export const shouldRefetchNewToken = () => {
  const fullToken = getFullToken();
  if (!fullToken) {
    return true;
  }

  return isTokenExpired(fullToken.expiresAt);
};

export const getAntiForgeryToken = () => {
  return getAntiForgeryTokenFromDOM();
};

export default {
  getAntiForgeryToken,
  createExpiredDate,
  isTokenExpired,
  shouldRefetchNewToken,
};
