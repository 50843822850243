import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'preparation.prepare': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"DataCleaningDashboard" */
        './components/containers/DataCleaningDashboard'
      ),
    ),
  },
  {
    exact: true,
    title: 'Project Detail',
    path: '/project/:id',
    claims: {
      'preparation.prepare': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"CleanProjectDetailPage" */
        './components/pages/CleanProjectDetailPage'
      ),
    ),
  },
  {
    exact: true,
    title: {
      id: 'module-prepare-allActiveProjects',
    },
    path: '/projects/all',
    claims: {
      'preparation.prepare': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"CleanProjectListPage" */
        './components/pages/CleanProjectListPage'
      ),
    ),
  },
  {
    exact: true,
    title: {
      id: 'module-prepare-archivedProjects',
    },
    path: '/projects/archived',
    claims: {
      'preparation.prepare': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"CleanProjectListArchivedPage" */
        './components/pages/CleanProjectListArchivedPage'
      ),
    ),
  },
];
