import { handleActions } from 'redux-actions';
import {
  changePrimaryColor,
  changeSecondaryColor,
  updateThemeWithOrg,
} from './actions';

export const initialState = {
  logo: '',
  primaryColor: '#15a9a9',
  secondaryColor: '#1b3c4f',
  mainDarkColor: '#1c313f',
};

export default handleActions(
  {
    [changePrimaryColor]: (state, { payload }) => ({
      ...state,
      primaryColor: payload,
    }),
    [changeSecondaryColor]: (state, { payload }) => ({
      ...state,
      secondaryColor: payload,
    }),
    [updateThemeWithOrg]: (
      state,
      { payload: { primaryColor, secondaryColor, mainDarkColor } },
    ) => ({
      ...state,
      primaryColor,
      secondaryColor,
      mainDarkColor,
    }),
  },
  initialState,
);
