import { useState } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { FormDecorator } from '@cluedin/form';

import DataSetSelect from '../../../../../../dataSourceV2/components/containers/forms/DataSetSelect';
import DataSourceSetSelect from '../../../../../../dataSourceV2/components/containers/forms/DataSourceSetSelect';
import withDataSourceSets from '../../../../../../dataSourceV2/hocs/gql/withDataSourceSets';
import DataSourceSelect from '../../../../../../dataSourceV2/components/containers/forms/DataSourceSelect';

const EnhancedDataSourceSetSelect = withDataSourceSets(DataSourceSetSelect);

const DataSetSelection = ({
  currentDataSetId,
  dataSetId,
  value,
  onChange,
  showVocabInfo,
}) => {
  const [dataSourceSetId, setDataSourceSetId] = useState();
  const [dataSourceId, setDataSourceId] = useState();

  return (
    <>
      <FormDecorator
        label={<FormattedMessage id="module-annotation-chooseDataGroupLabel" />}
      >
        <EnhancedDataSourceSetSelect
          value={dataSourceSetId}
          onChange={(d) => {
            setDataSourceSetId(d);
          }}
        />
      </FormDecorator>
      <FormDecorator
        label={
          <FormattedMessage id="module-annotation-chooseDataGroupDataSourceLabel" />
        }
      >
        {!dataSourceSetId && (
          <div>
            <FormattedMessage id="module-annotation-chooseDataGroupPleaseSelectDSG" />
          </div>
        )}
        {dataSourceSetId && (
          <DataSourceSelect
            value={dataSourceId}
            dataSourceSetId={dataSourceSetId}
            onChange={(d) => {
              setDataSourceId(d);
            }}
          />
        )}
      </FormDecorator>
      <FormDecorator
        label={
          <FormattedMessage id="module-annotation-chooseDataGroupDataSetLabel" />
        }
      >
        {!dataSourceId && (
          <div>
            <FormattedMessage id="module-annotation-chooseDataGroupPleaseSelectDS" />
          </div>
        )}
        {dataSourceId && (
          <DataSetSelect
            showVocabInfo={showVocabInfo}
            dataSourceId={dataSourceId}
            dataSetId={currentDataSetId}
            value={value}
            onChange={onChange}
          />
        )}
      </FormDecorator>
    </>
  );
};

export default DataSetSelection;
