import { NameWrapper } from './styled';

import VocabularySource from '../../../../dataCatalog/components/composites/VocabularySource';

export const getColumns = (displayName) => {
  return [
    {
      property: 'name',
      displayName,
      Component: ({
        name,
        connector,
        providerId,
        isCluedInCore,
        entityProps,
        onToggle,
        ...r
      }) => {
        return (
          <NameWrapper onClick={onToggle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <VocabularySource
                hideLabels
                connector={connector}
                providerId={providerId}
                isCluedInCore={isCluedInCore}
              />
              <strong>{name}</strong>
            </div>
          </NameWrapper>
        );
      },
    },
  ];
};
