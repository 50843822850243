import { useContext } from 'react';

import { AppContext, type UseAppReturnType } from './appContext';

export const useAppContext = (): UseAppReturnType => {
  const appContextData = useContext(AppContext);

  if (!appContextData) {
    throw new Error(
      'Make sure you are using `useAppContext` inside AppContextProvider!',
    );
  }

  return appContextData;
};
