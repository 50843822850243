import React from 'react';
import Section from './Section';

const InformationsSources = ({ jsonReport }) => {
  const { Links } = jsonReport;

  return (
    <Section jsonReport={jsonReport}>
      <h2>Information Source</h2>
      {Links &&
        Links.length > 0 &&
        Links.map(({ Uri, Origin }) => (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={Uri}
              title={`View in in ${Origin}`}
            >
              {`View in in ${Origin}`}
            </a>
          </div>
        ))}
    </Section>
  );
};

export default InformationsSources;
