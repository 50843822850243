import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pulseName } from './pulseKeyframes';

const TextPlaceholderUI = styled.div`
  width: ${({ width }) => width};
  height: ${({ lineHeight }) => lineHeight};
  display: flex;
  align-items: center;
  opacity: 0.1;
  animation: ${pulseName} 1s linear infinite;
`;

const TextPlaceholder = ({
  style = {},
  width = '100px',
  color = 'rgb(47, 47, 50)',
  height = '14px',
}) => (
  <TextPlaceholderUI style={style} width={width} height={height}>
    <div
      style={{
        width: '100%',
        height: `${height}`,
        display: 'inline-block',
        background: `${color}`,
      }}
    />
  </TextPlaceholderUI>
);
TextPlaceholder.defaultProps = {
  style: {},
  width: '100px',
  height: '14px',
  color: 'rgb(47, 47, 50)',
};

TextPlaceholder.propTypes = {
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default TextPlaceholder;
