import styled from 'styled-components';
import TileSnippet, { Snippet } from './TileSnippet';
import TileEntityPropertyValue from './TileEntityPropertyValue';
import { VocabularyValueCellComponentStandAlone } from '../table/VocabularyValueCellComponent';
import EntityListProperty from '../../../../../entityProperties/components/containers/EntityProperties/EntityListProperty/EntityListProperty';
import EntityDataCellComponent from '../table/EntityDataCellComponent';

export const PropertyLabel = styled.div`
  font-size: 12px;
  max-width: 100%;
  font-style: italic;
  padding-bottom: 4px;
  padding-top: 4px;
`;

const EntityPropertyValue = ({ entity, column }) => {
  if (column.type === 'entity') {
    const EntityComponent = column.Component;

    if (
      column.property.toLowerCase() === 'createddate' ||
      column.property.toLowerCase() === 'modifieddate' ||
      column.property.toLowerCase() === 'discoverydate'
    ) {
      const Comp = EntityDataCellComponent(column.property);

      return <Comp {...entity} />;
    }

    if (EntityComponent) {
      return (
        <Snippet>
          <EntityComponent {...entity} />
        </Snippet>
      );
    }

    return <TileSnippet snippet={entity[column.property]} />;
  }

  return (
    <Snippet>
      <VocabularyValueCellComponentStandAlone
        entity={entity}
        property={column.property}
      />
    </Snippet>
  );
};

const EntityPropertiesList = ({ entity, columns = [] }) => {
  return (
    <>
      {columns.map((column) => {
        return (
          <div style={{ marginBottom: '4px' }}>
            <PropertyLabel>
              {column.vocabKey ? (
                <>
                  <EntityListProperty
                    hidePopover
                    vocabularyKey={column.vocabKey}
                    entityVocabularyKey={column.property}
                  />
                </>
              ) : (
                <TileEntityPropertyValue column={column} />
              )}
            </PropertyLabel>
            <div>
              <EntityPropertyValue entity={entity} column={column} />
            </div>
          </div>
        );
      })}
    </>
  );
};

const TileExtra = ({ entity, columns, description }) => {
  const hasField = columns && columns.length > 0;

  if (!hasField && columns.find((i) => i.property === 'description')) {
    return <TileSnippet snippet={description} />;
  }

  return <EntityPropertiesList entity={entity} columns={columns} />;
};

export default TileExtra;
