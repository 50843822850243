import React from 'react';
import { Link } from 'react-router-dom';
import withEntityLink from '../Hocs/withEntityLink';

const EntityLinkContainer = ({ entityUrl, children, ...rest }) => {
  return (
    <Link {...rest} to={entityUrl}>
      {children}
    </Link>
  );
};

export default withEntityLink(EntityLinkContainer);
