import React from 'react';
import Dialog from 'uxi/Dialog';
import { FormattedMessage } from '@cluedin/locale';
import Panel from 'uxi/Panel';
import { Help } from 'uxi/Icons';
import styled from 'styled-components';
import { PrimaryButton, CancelButton } from '@cluedin/form';

const ConfirmDialogErrorContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 24px;
  min-width: 400px;
`;

const ConfirmDialogTitle = styled.div`
  font-weight: bold;
  font-color: black;
  font-size: 24px;
  padding-bottom: 8px;
  text-align: center;
`;

const DismissHelpDialogMessage = styled.div`
  color: #707070;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

const DismissHelpDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog show={isOpen} onClose={onClose}>
      <Panel>
        <ConfirmDialogErrorContentWrapper>
          <div style={{ marginBottom: '12px' }}>
            <Help color="#004085" size={64} />
          </div>
          <ConfirmDialogTitle>
            <FormattedMessage id="module-help-dismissDialogTitlte" />
          </ConfirmDialogTitle>
          <DismissHelpDialogMessage>
            <FormattedMessage id="module-help-dismissDialogContent" />
          </DismissHelpDialogMessage>
          <div>
            <CancelButton
              rounded
              text={<FormattedMessage id="module-help-dismissDialogCancel" />}
              onClick={onClose}
            />
            <PrimaryButton
              rounded
              style={{ marginLeft: '16px' }}
              text={<FormattedMessage id="module-help-dismissDialogDissmiss" />}
              onClick={onConfirm}
            />
          </div>
        </ConfirmDialogErrorContentWrapper>
      </Panel>
    </Dialog>
  );
};

export default DismissHelpDialog;
