"use strict";

exports.__esModule = true;
exports.default = createTheme;
var _zIndex = _interopRequireDefault(require("./zIndex"));
var _typography = _interopRequireDefault(require("./typography"));
var _spacing = _interopRequireDefault(require("./spacing"));
var _color = _interopRequireDefault(require("./color"));
var _shape = _interopRequireDefault(require("./shape"));
var _transitions = _interopRequireDefault(require("./transitions"));
var _shadows = _interopRequireDefault(require("./shadows"));
var _createBreakpoints = _interopRequireDefault(require("./createBreakpoints"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function createTheme(variables) {
  if (variables === void 0) {
    variables = {};
  }
  var colors = (0, _color.default)(variables);
  var spacing = (0, _spacing.default)(variables.spacing);
  var typography = (0, _typography.default)(variables);
  var shape = (0, _shape.default)(variables);
  var breakpoints = (0, _createBreakpoints.default)();
  return {
    colors: colors,
    spacing: spacing,
    zIndex: _zIndex.default,
    typography: typography,
    shape: shape,
    transitions: _transitions.default,
    shadows: _shadows.default,
    breakpoints: breakpoints
  };
}