import styled, { css } from 'styled-components';

export const EntityActionMessageItemsDetailsHeaderWrapper = styled.div`
  align-items: center;
  background: ${({ isExpanded, theme }) =>
    isExpanded ? theme.palette.themeLighterAlt : '#ffffff'};
  border: 1px solid #ececec;
  border-top: 0;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding: 14px 30px;
  transition: all 100ms ease;
  width: 100%;

  &:hover {
    background: ${({ isExpanded, theme }) =>
      isExpanded ? theme.palette.themeLighter : '#fafafa'};
  }

  & svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const EntityActionMessageItemsDetailsHeaderInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 14px;
  width: 100%;
  max-width: 1600px;
`;

export const EntityActionMessageItemsDetailsHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }
`;

export const EntityActionMessageItemDetailsHeaderDisplayName = styled.div`
  color: #666666;
`;

export const EntityActionMessageItemDetailsHeaderValue = styled.div`
  color: #000000;
  font-weight: 600;

  & em {
    color: #666666;
  }
`;

export const EntityActionMessageItemDetailsHeaderBadge = styled.strong`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 6px;
  text-transform: capitalize;

  ${({ actionType, theme }) => {
    switch (true) {
      case actionType === 'create':
        return css`
          color: ${theme.palette.themePrimary} !important;
        `;
      case actionType === 'merge':
        return css`
          color: ${theme.palette.themePrimary} !important;
        `;
      case actionType === 'update':
        return css`
          color: ${theme.palette.themePrimary} !important;
        `;
      case actionType === 'archive':
        return css`
          color: #5b89cf !important;
        `;
      case actionType === 'associate':
        return css`
          color: #5b89cf !important;
        `;
      case actionType === 'disassociate':
        return css`
          color: rgb(255, 152, 0) !important;
        `;
      case actionType === 'remove':
        return css`
          color: rgb(255, 152, 0) !important;
        `;
      default:
        return css`
          color: ${theme.palette.themePrimary} !important;
        `;
    }
  }}
`;

export const EntityActionMessageItemDetailsHeaderButtonWrapper = styled.div`
  margin: 0 28px 0 0;
`;
