import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { Catalog } from './fragments';

const GET_VOCABULARY_ID = gql`
  query getVocabularyKey($key: String!) {
    management {
      id
      vocabularyPerKey(key: $key) {
        ...VocabularyKey
        isVocabularyOwner
      }
    }
  }
  ${Catalog.fragments.vocabularyKey}
`;

const GET_VOCABULARY_ID_NO_OWNER = gql`
  query getVocabularyKey($key: String!) {
    management {
      id
      vocabularyPerKey(key: $key) {
        ...VocabularyKey
      }
    }
  }
  ${Catalog.fragments.vocabularyKey}
`;

export const useQueryVocabularyKeyByKey = (key, skip) => {
  const { data, loading, error, refetch } = useQuery(GET_VOCABULARY_ID, {
    fetchPolicy: 'network-only',
    skip,
    variables: {
      key,
    },
  });

  const vocab = get(data, 'management.vocabularyPerKey');

  return [vocab, loading, error, refetch];
};

export const useQueryVocabularyKeyByKeyNoOwner = (key) => {
  const { data, loading, error, refetch } = useQuery(
    GET_VOCABULARY_ID_NO_OWNER,
    {
      fetchPolicy: 'network-only',
      variables: {
        key,
      },
    },
  );

  const vocab = get(data, 'management.vocabularyPerKey');

  return [vocab, loading, error, refetch];
};

export default {
  useQueryVocabularyKeyByKey,
};
