import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const TEST_CONNECTION = gql`
  mutation testDataSourceConfiguration($dataSource: InputDataSource) {
    inbound {
      testDataSourceConfiguration(dataSource: $dataSource)
    }
  }
`;

export const useDatabaseTestConnection = () => {
  const [testConnection, { data, loading, error }] =
    useMutation(TEST_CONNECTION);

  const onlyNeededData = data
    ? ((data || {}).inbound || {}).testDataSourceConfiguration || {}
    : null;

  return [
    (configuration) => {
      testConnection({
        variables: {
          dataSource: {
            name: 'name',
            type: 'sql',
            configuration: {
              ...configuration,
            },
          },
        },
      });
    },
    {
      data: onlyNeededData,
      loading,
      error,
    },
  ];
};

export default {
  useDatabaseTestConnection,
};
