/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  memo,
  FC,
  useMemo,
  Dispatch,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  createContext,
  SetStateAction,
} from 'react';
import qs from 'query-string';
import { pick, upperFirst, isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import {
  useEntityTypeLayoutConfigurations,
  EntityTypeLayoutConfiguration,
} from '../../../../entityTypes/hooks/useEntityTypeLayoutConfigurations';
import { useEntitySearch } from '../../../hooks/useEntitySearch';
import { useViewModeWithCustomizedColumns } from '../GoldenRecordList/hooks/useViewModeWithCustomizedColumns';
import {
  type SavedSearch,
  useSavedSearch,
} from '../../../hooks/useSavedSearch';
import {
  Connector,
  useQueryConnectorsIntegrationAndEnrichment,
} from '../../../../connector/hooks/useQueryConnectorsIntegrationAndEnrichment';
import { queryParamName, searchURI, useAppSearch } from '../../../../AppSearch';
import { AppSearchScopes } from '../../../../AppSearch/AppSearch.types';
import { blankValidation } from '../../../../../helpers/validation';
import { useLazyQuerySavedSearchById } from '../../../hooks/useLazyQuerySavedSearchById';
import { useQueryCurrentOrganization } from '../../../../security/hooks/useCurrentOrganziation';
import { useQueryConfigurationSettings } from '../../../../security/hooks/useConfigurationSettings';
import { getInitialRules } from '../../../../cleanProject/containers/CreateCleanProjectPanel/CreateCleanProjectPanel';
import { useQueryOperators } from '../../../../rule/hooks/useQueryOperators';
import { useQueryRuleObjectTypes } from '../../../../rule/hooks/useQueryRuleObjectTypes';
import type { Filter } from '../../../../rule/types/ruleConditionTypes';

type SelectedItem = {
  id: string;
  name: string;
  entityType: string;
};

const useListSelectionStore = (): {
  selectionActive: boolean;
  selected: SelectedItem[];
  toggleSelectionActive: () => void;
  setSelected: Dispatch<SetStateAction<SelectedItem[]>>;
} => {
  const [selected, setSelected] = useState<SelectedItem[]>([]);
  const [selectionActive, setSelectionActive] = useState(false);

  const toggleSelectionActive = useCallback(() => {
    setSelected(() => []);
    setSelectionActive((prev) => !prev);
  }, []);

  return {
    selected,
    setSelected,
    selectionActive,
    toggleSelectionActive,
  };
};

const getColumnsConfigWithoutVocabKey = (data: any[] = []) =>
  data.map((i) => {
    const { vocabKey: _, ...rest } = i;

    return rest;
  });

export const countConditionFilters = (rule: Filter): number => {
  if (!rule.rules) {
    return 0;
  }

  let count = rule.rules.filter((i) => !i.rules).length;

  for (const subRule of rule.rules) {
    count += countConditionFilters(subRule);
  }

  return count;
};

export type ListSelectionStoreReturnType = ReturnType<
  typeof useListSelectionStore
>;

export type FilterOptions = {
  numberOfFilters: number;
  hasActiveFilters: boolean;
  onApplySearch: (savedSearch: SavedSearch) => void;
} & ReturnType<typeof useSavedSearch>;

export type GoldenRecordListContextType = {
  listOptions: any;
  viewOptions: any;
  viewName: string;
  hasSelection: boolean;
  hideListSearch: boolean;
  filtersToHide: (string | undefined)[];
  integrations: Connector[];
  loadingEntityTypes: boolean;
  advancedFiltersMode: boolean;
  onChangeFIlterMode: () => void;
  handleSetFilterMode: (v: boolean) => void;
  loadingIntegrations: boolean;
  filterOptions: FilterOptions;
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  appliedSavedSearch: SavedSearch | undefined;
  selectionState: ListSelectionStoreReturnType;
  entityTypesConfigurations: EntityTypeLayoutConfiguration[];
};

export const GoldenRecordListContext =
  createContext<GoldenRecordListContextType | null>(null);

type Props = {
  viewName?: string;
  children: ReactNode;
  defaultTerm?: string;
  defaultTags?: string[];
  searchNameKey?: string;
  hasSelection?: boolean;
  hideListSearch?: boolean;
  defaultProviders?: string[];
  defaultEntityType?: string | string[];
  defaultEntityKeys?: string[] | unknown;
  defaultProviderDefinitionIds?: string[];
  defaultVocabularyKeys: string[] | unknown;
};

export const GoldenRecordListProvider: FC<Props> = memo(
  ({
    children,
    defaultTerm,
    searchNameKey,
    defaultEntityType,
    defaultEntityKeys,
    hasSelection = false,
    defaultVocabularyKeys,
    hideListSearch = false,
    viewName = 'mainSearch',
    defaultProviderDefinitionIds,
    defaultTags,
    defaultProviders,
  }) => {
    const [org] = useQueryCurrentOrganization();
    const [orgSettingsData] = useQueryConfigurationSettings(org.Id, true);

    const { operators } = useQueryOperators();
    const [objectTypes] = useQueryRuleObjectTypes('SEARCH');

    const hasStrongTypingFeature = useMemo(
      () =>
        orgSettingsData?.['Feature.Search.StrongTyped']?.toLowerCase() ===
        'true',
      [orgSettingsData],
    );

    const { formatMessage } = useIntl();

    const { savedSearch, getSavedSearchById } = useLazyQuerySavedSearchById();

    const {
      savedSearches,
      handleEditSearch,
      handleSaveSearch,
      savedSearchesPage,
      handleDeleteSearch,
      totalSavedSearches,
      sharedSavedSearches,
      loadingSavedSearches,
      refetchSavedSearches,
      setSavedSearchesPage,
      totalsSharedSavedSearches,
    } = useSavedSearch({ viewName });

    const [appliedSavedSearch, setAppliedSavedSearch] = useState<
      SavedSearch | undefined
    >();

    const [appliedSavedSearchName, setAppliedSavedSearchName] = useState<
      string | undefined
    >();

    const clearAppliedSavedSearch = () => {
      setAppliedSavedSearch(undefined);
      setAppliedSavedSearchName(undefined);
    };

    const history = useHistory();
    const location = useLocation();

    const filterUrl = useMemo(() => location.search, [location]);

    history.listen(({ search }) => {
      // to clear appliedSavedSearch if user clicks `x` in top search input
      if (appliedSavedSearch && !qs.parse(decodeURI(search))?.appliedSearchId) {
        clearAppliedSavedSearch();
      }
    });

    const currentParsedSearch = qs.parse(decodeURI(filterUrl));

    const appliedSearchId = currentParsedSearch?.appliedSearchId;

    const loadAndSetAppliedSavedSearch = async () => {
      if (!appliedSearchId) {
        return;
      }

      const loadedSearch = await getSavedSearchById(appliedSearchId);

      const foundAppliedSearch =
        loadedSearch?.data?.administration?.getSavedSearchById;

      if (!foundAppliedSearch) {
        return;
      }

      setAppliedSavedSearchName(foundAppliedSearch?.name);
      setAppliedSavedSearch(foundAppliedSearch);
    };

    useEffect(() => {
      if (
        (appliedSearchId &&
          !appliedSavedSearch?.id &&
          savedSearches?.length > 0) ||
        sharedSavedSearches?.length > 0
      ) {
        const foundAppliedSearch =
          savedSearches.filter((i) => i.id === appliedSearchId)?.[0] ||
          sharedSavedSearches.filter((i) => i.id === appliedSearchId)?.[0];

        if (foundAppliedSearch) {
          setAppliedSavedSearchName(foundAppliedSearch?.name);
          setAppliedSavedSearch(foundAppliedSearch);
        } else if (!savedSearch && appliedSearchId) {
          // to call it only once
          loadAndSetAppliedSavedSearch();
        }
      }
    }, [
      appliedSearchId,
      appliedSavedSearch,
      sharedSavedSearches,
      savedSearches,
    ]);

    const {
      scope,
      resetSearch,
      quiteSetValue,
      handleChangeScope,
      handleChangeSearch,
      searchWasChangedAndRequested,
    } = useAppSearch();

    const [advancedFiltersMode, setAdvancedFiltersMode] = useState(() => {
      return !!currentParsedSearch?.CONDITION;
    });

    useEffect(() => {
      return () => {
        resetSearch();
        setAdvancedFiltersMode(() => false);
      };
    }, []);

    const selectionState = useListSelectionStore();

    const filtersToHide = [
      defaultTags && 'tags_name',
      defaultProviders && 'providers',
      defaultEntityType && 'entityType',
      defaultProviderDefinitionIds && 'providerDefinitionIds_v2',
    ].filter(Boolean);

    const [
      { entities = [], total = 0, cursor },
      { loading },
      {
        selectedPage,
        pageSize,
        setCurrentPage,
        setSearchName,
        searchNameInput,
        setSearchNameInput,
        filters,
        setFilters,
        hasFilters,
        nextPage,
      },
    ]: any = useEntitySearch({
      defaultEntityType,
      defaultTags,
      defaultProviders,
      defaultProviderDefinitionIds,
      defaultTerm,
      searchNameKey,
      advancedFiltersMode,
    } as any);

    const onChangeFIlterMode = useCallback(() => {
      setAdvancedFiltersMode((p) => {
        const f = filters;

        resetFilters();

        if (!p) {
          const conditions = getInitialRules(
            f?.entityType?.join(',') || '',
            operators,
            f.providerDefinitionIds_v2,
            f.tags_name,
            objectTypes,
            f.providers,
          );

          handleChangeScope(AppSearchScopes.all);

          setFilters({
            ...f,
            entityType: null,
            tags_name: null,
            providers: null,
            providerDefinitionIds_v2: null,
            condition: conditions?.rules?.length > 0 ? conditions : null,
          });
        } else {
          setFilters({
            ...f,
            entityType: null,
            tags_name: null,
            providers: null,
            condition: null,
            providerDefinitionIds_v2: null,
          });
        }

        return !p;
      });
    }, [filters]);

    const handleSetFilterMode = (v: boolean) => setAdvancedFiltersMode(v);

    const updateEntityTypeFilterBasedOnScope = () => {
      if (appliedSearchId) {
        return;
      }

      if (filters.entityType?.length > 1 || scope === AppSearchScopes.all) {
        if ((history.location.state as any)?.fromExternalPage) {
          try {
            const parsedEntityTypeFromExternalNavigation =
              typeof currentParsedSearch?.ENTITYTYPE === 'string'
                ? JSON.parse(currentParsedSearch?.ENTITYTYPE)
                : currentParsedSearch?.ENTITYTYPE;

            const parsedEntityTypeFromExternalNavigationValue =
              parsedEntityTypeFromExternalNavigation[0]
                ?.toLowerCase()
                ?.replaceAll('/', '');

            if (
              parsedEntityTypeFromExternalNavigation?.length === 1 &&
              (parsedEntityTypeFromExternalNavigationValue ===
                AppSearchScopes.organization ||
                parsedEntityTypeFromExternalNavigationValue ===
                  AppSearchScopes.person)
            ) {
              handleChangeScope(parsedEntityTypeFromExternalNavigationValue);

              return;
            }
          } catch {}
          return;
        }

        setFilters({ ...filters, entityType: null });
        history.push({ ...history.location, state: null });

        return;
      }

      const transformedScope = `/${upperFirst(scope)}`;

      setFilters({ ...filters, entityType: [transformedScope] });
      history.push({ ...history.location, state: null });
    };

    useEffect(() => {
      if (advancedFiltersMode) {
        return;
      }

      // to apply entity type filter if it was in url
      updateEntityTypeFilterBasedOnScope();
    }, [scope, advancedFiltersMode, appliedSearchId]);

    // this should take the an ARRAY of Entity types from the "entities"
    const { data: entityTypesConfigurations, loading: loadingEntityTypes } =
      useEntityTypeLayoutConfigurations();
    const { data: integrations, loading: loadingIntegrations } =
      useQueryConnectorsIntegrationAndEnrichment();

    const onChangeSortingByColumn = (sortBy: string, sortDirection: string) => {
      if (
        filters.sortBy === sortBy &&
        filters.sortDirection === sortDirection
      ) {
        return setFilters({ ...filters, sortBy: null, sortDirection: null });
      }

      setFilters({ ...filters, sortBy, sortDirection });
    };

    const [
      { DataGrid, DataGridGhost, columnsForGrid },
      {
        columns,
        saveCustomizedColumns,
        addColumns,
        getColumnsForViewName,
        deleteColumns,
        restrictions: { maxNumberColumns, excludePropertyKeys },
      },
      { viewMode, setViewMode },
    ] = useViewModeWithCustomizedColumns(
      viewName,
      defaultVocabularyKeys,
      defaultEntityKeys,
      onChangeSortingByColumn,
      filters,
      hasStrongTypingFeature,
    );

    const hiddenFilters = pick(filters || {}, [
      'entityType',
      'providers',
      'tags_name',
      'providerDefinitionIds_v2',
    ]);

    // num on filter btn
    let numberOfFilters =
      filters?.condition?.rules?.length > 0
        ? countConditionFilters(filters?.condition)
        : 0;

    if (!filters?.condition || filters?.condition?.rules?.length === 0) {
      Object.keys(hiddenFilters).forEach((k) => {
        const v = (hiddenFilters as any)[k];

        if (v) {
          numberOfFilters += 1;
        }
      });
    }

    const hasActiveFilters =
      searchWasChangedAndRequested ||
      numberOfFilters > 0 ||
      filters?.condition?.rules?.length > 0;

    const changeAppliedSearchName = (name: string) =>
      setAppliedSavedSearchName(() => name);

    const appliedSearchNameValidationError = useMemo(() => {
      if (!!!blankValidation(appliedSavedSearchName)) {
        return formatMessage({
          id: 'module-goldenRecordList-save-search-required-err',
        });
      }
    }, [appliedSavedSearchName]);

    const isSavedSearchConfigChanged = useMemo(() => {
      if (!appliedSavedSearch) {
        return false;
      }

      const appliedSavedSearchViewConfig = {
        viewMode: appliedSavedSearch?.viewMode,
        columnsConfig: {
          // to keep sorting
          tile: getColumnsConfigWithoutVocabKey(
            appliedSavedSearch?.columnsConfig?.tile,
          ),
          table: getColumnsConfigWithoutVocabKey(
            appliedSavedSearch?.columnsConfig?.table,
          ),
        },
      };

      const currentSavedSearchViewConfig = {
        viewMode: viewMode,
        columnsConfig: {
          tile: getColumnsConfigWithoutVocabKey(
            getColumnsForViewName(`${viewName}-tile`),
          ),
          table: getColumnsConfigWithoutVocabKey(
            getColumnsForViewName(`${viewName}-table`),
          ),
        },
      };

      if (
        !isEqual(appliedSavedSearchViewConfig, currentSavedSearchViewConfig)
      ) {
        return true;
      }

      const appliedParsedSearch = qs.parse(
        decodeURI(appliedSavedSearch?.filterUrl ?? ''),
      );

      const appliedSavedSearchFiltersConfig = {
        providers:
          appliedParsedSearch?.providers || appliedParsedSearch?.PROVIDERS,
        sortType:
          appliedParsedSearch?.sortType ||
          appliedParsedSearch?.sorttype ||
          appliedParsedSearch?.SORTTYPE,
        entityType:
          appliedParsedSearch?.entityType ||
          appliedParsedSearch?.entitytype ||
          appliedParsedSearch?.ENTITYTYPE,
        sources: appliedParsedSearch?.PROVIDERDEFINITIONIDS_V2,
        tags: appliedParsedSearch?.TAGS_NAME,
        [queryParamName]: appliedParsedSearch?.[queryParamName],
        condition: appliedParsedSearch?.CONDITION,
      };

      const currentSavedSearchFiltersConfig = {
        providers:
          currentParsedSearch?.providers || currentParsedSearch?.PROVIDERS,
        sortType:
          currentParsedSearch?.sortType ||
          currentParsedSearch?.sorttype ||
          currentParsedSearch?.SORTTYPE,
        entityType:
          currentParsedSearch?.entityType ||
          currentParsedSearch?.entitytype ||
          currentParsedSearch?.ENTITYTYPE,
        sources: currentParsedSearch?.PROVIDERDEFINITIONIDS_V2,
        tags: currentParsedSearch?.TAGS_NAME,
        [queryParamName]: currentParsedSearch?.[queryParamName],
        condition: currentParsedSearch?.CONDITION,
      };

      if (
        !isEqual(
          appliedSavedSearchFiltersConfig,
          currentSavedSearchFiltersConfig,
        )
      ) {
        return true;
      }

      return false;
    }, [currentParsedSearch, viewMode, scope, appliedSavedSearch]);

    const saveDisabled =
      !hasActiveFilters ||
      (appliedSavedSearch && appliedSearchNameValidationError) ||
      (appliedSavedSearch &&
        appliedSavedSearchName === appliedSavedSearch?.name &&
        !isSavedSearchConfigChanged);

    const saveAsDisabled = !hasActiveFilters;

    const setScopeAndValueFromSavedSearch = (initialFilterUrl: string) => {
      const search = qs.parse(decodeURI(initialFilterUrl));
      const searchTerm = search?.[queryParamName];

      searchTerm && quiteSetValue(searchTerm);

      const rawEntityType =
        search?.entityType || search?.entitytype || search?.ENTITYTYPE;

      if (rawEntityType) {
        try {
          const entityType =
            typeof rawEntityType === 'string'
              ? JSON.parse(rawEntityType)
              : rawEntityType;

          if (
            entityType?.length === 1 &&
            (entityType[0]?.toLowerCase()?.replaceAll('/', '') ===
              AppSearchScopes.organization ||
              entityType[0]?.toLowerCase()?.replaceAll('/', '') ===
                AppSearchScopes.person)
          ) {
            return handleChangeScope(
              entityType[0]?.toLowerCase()?.replace('/', ''),
            );
          }
        } catch {
          return handleChangeScope(AppSearchScopes.all);
        }
      }

      return handleChangeScope(AppSearchScopes.all, false);
    };

    const onApplySearch = async (savedSearch: SavedSearch) => {
      const { filterUrl, viewMode, id, columnsConfig } = savedSearch;

      if (!filterUrl) {
        return;
      }

      setAppliedSavedSearch(savedSearch);
      setAppliedSavedSearchName(savedSearch?.name);

      const search = qs.parse(filterUrl);

      handleSetFilterMode(search?.CONDITION?.length > 0);

      setScopeAndValueFromSavedSearch(filterUrl);

      history.push(
        `${searchURI}?${qs.stringify({ ...search, appliedSearchId: id })}`,
      );

      setViewMode(viewMode);

      saveCustomizedColumns(columnsConfig[viewMode]);

      // to make sure we setting opposite config to LocalStorage
      if (viewMode === 'table') {
        return saveCustomizedColumns(columnsConfig?.tile || [], {
          skipActivating: true,
          overrideViewName: `${viewName}-tile`,
        });
      }

      saveCustomizedColumns(columnsConfig?.table || [], {
        skipActivating: true,
        overrideViewName: `${viewName}-table`,
      });
    };

    const resetFilters = () => {
      clearAppliedSavedSearch();

      if (defaultEntityType) {
        history.push(
          `${location.pathname}?${qs.stringify({
            entityType: defaultEntityType,
          })}`,
        );
      } else {
        history.push(location.pathname);
      }

      handleChangeScope(AppSearchScopes.all, true);

      handleSetFilterMode(false);

      handleChangeSearch('');
      setSearchName('');
      setSearchNameInput('');
    };

    const onDeleteSearch = async (searchId: string) => {
      if (appliedSavedSearch?.id === searchId) {
        resetFilters();
      }

      return handleDeleteSearch(searchId);
    };

    const value = {
      history,
      location,
      viewName,
      integrations,
      hasSelection,
      hideListSearch,
      selectionState,
      loadingEntityTypes,
      appliedSavedSearch,
      loadingIntegrations,
      entityTypesConfigurations,
      advancedFiltersMode,
      onChangeFIlterMode,
      nextPage,
      cursor,
      handleSetFilterMode,
      filtersToHide,
      listOptions: {
        total,
        loading,
        filters,
        entities,
        pageSize,
        filterUrl,
        setFilters,
        hasFilters,
        selectedPage,
        resetFilters,
        setSearchName,
        setCurrentPage,
        searchNameInput,
        setSearchNameInput,
      },
      viewOptions: {
        DataGrid,
        DataGridGhost,
        columnsForGrid,
        columns,
        saveCustomizedColumns,
        addColumns,
        getColumnsForViewName,
        deleteColumns,
        restrictions: {
          maxNumberColumns,
          excludePropertyKeys,
        },
        viewMode,
        setViewMode,
      },
      filterOptions: {
        saveDisabled,
        onApplySearch,
        savedSearches,
        saveAsDisabled,
        numberOfFilters,
        handleEditSearch,
        handleSaveSearch,
        hasActiveFilters,
        savedSearchesPage,
        totalSavedSearches,
        sharedSavedSearches,
        loadingSavedSearches,
        refetchSavedSearches,
        setSavedSearchesPage,
        appliedSavedSearchName,
        changeAppliedSearchName,
        totalsSharedSavedSearches,
        loadAndSetAppliedSavedSearch,
        appliedSearchNameValidationError,
        handleDeleteSearch: onDeleteSearch,
      },
    };

    return (
      <GoldenRecordListContext.Provider value={value}>
        {children}
      </GoldenRecordListContext.Provider>
    );
  },
);
