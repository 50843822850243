import { useEffect } from 'react';
import { injectIntl } from '@cluedin/locale';
import { PanelHeader } from '@cluedin/form';

import EdgePropertiesPanelHeader from './EdgePropertiesPanelHeader/EdgePropertiesPanelHeader';
import EdgePropertiesPanelFooter from './EdgePropertiesPanelFooter/EdgePropertiesPanelFooter';
import {
  EdgePropertiesPanelWrapper,
  EdgePropertiesPanelEdgeGroupEntryWrapper,
  EdgePropertiesPanelEdgeLoaderWrapper,
} from './EdgePropertiesPanel.styles';
import { useQueryEntityEdgeSummaryById } from '../../hooks/useEdgeSummary';
import EdgePropertiesPanelEdgeGroupEntryContainer from './EdgePropertiesPanelEdgeGroupEntry/EdgePropertiesPanelEdgeGroupEntryContainer';
import PageLoader from '../../../core/components/composites/PageLoader';

const EdgePropertiesPanel = ({
  onClose,
  selectedEdge,
  setSelectedEdge,
  isEdgePropertiesPanelOpen,
  setIsEdgePropertiesPanelOpen,
  edgeType,
  edgeByEdgeId,
  edgeTypesArray,
  nodesAndEdges,
  entityId,
  edgeDirection,
  onNodeClick,
  onGroupedNodeClick,
  intl,
}) => {
  const [edgeSummary, loading, error] = useQueryEntityEdgeSummaryById(entityId);

  const getNodeInfo = (id) =>
    edgeSummary?.id === id
      ? edgeSummary
      : (edgeSummary?.edges || [])?.filter((e) => e?.entityId === id)[0];

  const handleOnClose = () => {
    setIsEdgePropertiesPanelOpen(false);
    setSelectedEdge(null);
    onClose();
  };

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, []);

  const edgeFrom = nodesAndEdges?.nodes?.filter(
    (node) => node?.id === edgeByEdgeId?.[0]?.from,
  )?.[0];
  const edgeTo = nodesAndEdges?.nodes?.filter(
    (node) => node?.id === edgeByEdgeId?.[0]?.to,
  )?.[0];

  const nodeFrom = getNodeInfo(edgeFrom?.id);
  const nodeTo = getNodeInfo(edgeTo?.id);

  return (
    <>
      <PanelHeader
        title={intl.formatMessage({
          id: 'module-entityRelationsGraph-edgePropertiesPanelTitle',
        })}
        onClose={handleOnClose}
      />

      <EdgePropertiesPanelWrapper>
        {loading ? (
          <EdgePropertiesPanelEdgeLoaderWrapper>
            <PageLoader />
          </EdgePropertiesPanelEdgeLoaderWrapper>
        ) : (
          <>
            <EdgePropertiesPanelHeader
              nodeFrom={nodeFrom}
              nodeTo={nodeTo}
              edgeType={edgeType}
              edgeTypesArray={edgeTypesArray}
              edgeFrom={edgeFrom}
              edgeTo={edgeTo}
              onNodeClick={onNodeClick}
              onGroupedNodeClick={onGroupedNodeClick}
              edgeDirection={edgeDirection}
              labels={{
                title: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesPanelTitle',
                }),
                edgeTypeLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesPanelEdgeType',
                }),
                totalEdgeTypes: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesPanelTotalEdgeTypes',
                }),
                edgeFromLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesPanelTotalEdgeFrom',
                }),
                edgeToLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesPanelTotalEdgeTo',
                }),
                groupedRecordsLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesHeaderGroupedRecords',
                }),
                directionLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesHeaderDirection',
                }),
                outgoingLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesHeaderOutgoing',
                }),
                incomingLabel: intl.formatMessage({
                  id: 'module-entityRelationsGraph-edgePropertiesHeaderIncoming',
                }),
              }}
            />

            <div style={{ marginBottom: '32px' }}>
              {intl.formatMessage({
                id: 'module-entityRelationsGraph-edgePropertiesAssignedProperties',
              })}
            </div>

            {edgeTypesArray?.map((edgeType, index) => (
              <EdgePropertiesPanelEdgeGroupEntryWrapper
                key={`${edgeType}-${index}`}
              >
                <EdgePropertiesPanelEdgeGroupEntryContainer
                  edgeType={edgeType}
                  entityId={entityId}
                  edgeDirection={edgeDirection}
                  edgeByEdgeId={edgeByEdgeId}
                  edges={{ edgeFrom, edgeTo }}
                />
              </EdgePropertiesPanelEdgeGroupEntryWrapper>
            ))}
            <EdgePropertiesPanelFooter handleOnClose={handleOnClose} />
          </>
        )}
      </EdgePropertiesPanelWrapper>
    </>
  );
};

export default injectIntl(EdgePropertiesPanel);
