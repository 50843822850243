import { FC, ReactElement, memo, ChangeEvent } from 'react';
import { Descendant } from 'slate';
import {
  TextField,
  PanelFooter,
  PanelContent,
  PrimaryButton,
  FormDecorator,
} from '@cluedin/form';
import { useIntl } from 'react-intl';

import { Filters, Operators } from '../../../rule/types/ruleConditionTypes';
import RuleConditionContainer from '../../../rule/components/containers/RuleConditionContainer';
import { RuleValidationErrors } from '../../../rule/rulesValidationUtils/rulesValidationTypes';

type Props = {
  name: string;
  query: string;
  filters: Filters;
  operators: Operators;
  nextDisabled: boolean;
  closeBtn: ReactElement;
  description: Descendant[];
  includeDataParts: boolean;
  loadingOperators?: boolean;
  onChangeName: (v: string) => void;
  onChangeQuery: (v: string) => void;
  setFilters: (data: Filters) => void;
  finishEditConfiguration: () => void;
  nameValidationError: string | undefined;
  filtersValidationErrors: RuleValidationErrors;
  onChangeIncludeDataParts: (v: boolean) => void;
  onChangeDescription: (Description: Descendant[]) => void;
  isFiltersRequired: boolean;
};

export const ConfigureNewCleanProject: FC<Props> = memo(
  ({
    name,
    query,
    filters,
    closeBtn,
    operators,
    setFilters,
    onChangeName,
    nextDisabled,
    onChangeQuery,
    loadingOperators,
    nameValidationError,
    finishEditConfiguration,
    filtersValidationErrors,
    isFiltersRequired,
  }) => {
    const { formatMessage } = useIntl();

    return (
      <>
        <PanelContent>
          <FormDecorator
            isRequired
            errorText={nameValidationError}
            label={formatMessage({ id: 'module-clean-create-name-label' })}
            helpText={formatMessage({
              id: 'module-clean-create-name-help-text',
            })}
          >
            <TextField
              type="text"
              isFullWidth
              value={name}
              data-test="addRule-name-input"
              className="__test_addRuleName"
              onChange={(_: ChangeEvent, v: string) => onChangeName(v)}
              placeholder={formatMessage({
                id: 'module-clean-create-name-label',
              })}
            />
          </FormDecorator>

          <FormDecorator
            label={formatMessage({ id: 'module-clean-create-query' })}
            helpText={formatMessage({
              id: 'module-clean-create-query-text',
            })}
          >
            <TextField
              type="text"
              isFullWidth
              value={query}
              data-test="addRule-query-input"
              className="__test_addRuleQuery"
              placeholder={formatMessage({
                id: 'module-clean-create-query-placeholder',
              })}
              onChange={(_: ChangeEvent, v: string) => onChangeQuery(v)}
            />
          </FormDecorator>

          {/* // Just disabling for now */}
          {/* <FormDecorator
          label={formatMessage({ id: 'module-clean-create-history-label' })}
          helpText={formatMessage({ id: 'module-clean-create-history-help-text' })}
        >
          <Checkbox
            name="cleanWithHistory"
            checked={includeDataParts}
            label={formatMessage({ id: 'module-clean-create-history-label' })}
            onChange={(_: ChangeEvent, v: boolean) => onChangeIncludeDataParts(v)}
          />
        </FormDecorator> */}

          <FormDecorator
            isRequired={isFiltersRequired}
            label={formatMessage({ id: 'module-clean-create-filters-label' })}
            helpText={formatMessage({
              id: 'module-clean-create-filters-help-text',
            })}
          >
            <RuleConditionContainer
              scope="Search"
              context="CLEAN"
              conditions={filters}
              onChange={setFilters}
              operators={operators}
              loadingOperators={loadingOperators}
              rulesValidationErrors={filtersValidationErrors}
            />
          </FormDecorator>
        </PanelContent>

        <PanelFooter>
          {closeBtn}

          <PrimaryButton
            rounded
            onClick={finishEditConfiguration}
            style={{ marginLeft: '8px' }}
            disabled={nextDisabled}
          >
            {formatMessage({ id: 'module-clean-create-next' })}
          </PrimaryButton>
        </PanelFooter>
      </>
    );
  },
);
