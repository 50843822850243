import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { get } from 'lodash';

export const MARK_ALL_AS_READ = gql`
  mutation markAllAsRead {
    notification {
      markAllAsRead
    }
  }
`;

export const useMarkAllAsRead = ({ refetchUnread }) => {
  const [markAllAsRead, { data, loading, error }] =
    useMutation(MARK_ALL_AS_READ);
  const count = get(data, 'notification.markAllAsRead', 0);

  useEffect(() => {
    if (refetchUnread) {
      refetchUnread();
    }
  }, [refetchUnread, count]);

  return [markAllAsRead, count, loading, error];
};
