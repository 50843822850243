import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/following',
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"AllFollowingPage" */
          './components/pages/AllFollowingPage'
        ),
      ),
    ),
  },
  {
    path: '/alerts',
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"AllSavedSearchesPage" */
          './components/pages/AllSavedSearchesPage'
        ),
      ),
    ),
  },
];
