import { Select } from '@cluedin/form';
import Alert from 'uxi/Alert';
import withDataSources from '../../../hocs/withDataSources';

const DataSourceSelect = ({
  dataSources = [],
  value,
  onChange,
  dataSourceId,
  style = {},
  isFetching,
  loading,
  skipExcluded = false,
}) => {
  const excludeCurrent = (dataSources || []).filter(
    (d) => skipExcluded || d.id !== dataSourceId,
  );

  const options = excludeCurrent.map((d) => ({
    value: d.id,
    key: d.id,
    label: d.name,
  }));

  return (
    <>
      {dataSources && dataSources.length === 0 && !loading && !isFetching && (
        <Alert type="warning">No data source for this data group</Alert>
      )}
      {dataSources && dataSources.length > 0 && (
        <div style={{ width: '300px', ...style }}>
          <Select
            value={value || dataSourceId}
            options={options}
            onChange={({ value }) => onChange(value)}
          />
        </div>
      )}
    </>
  );
};

export default withDataSources(DataSourceSelect);
