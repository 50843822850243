import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, CancelButton, PrimaryButton } from '@cluedin/form';
import { FormattedMessage, injectIntl } from '@cluedin/locale';

import EntityTopologySplitModalContent from '../../composites/EntityTopologySplitModalContent/EntityTopologySplitModalContent';
import { useEntitySplitExecute } from '../../../hooks/useEntitySplitExecute';
import { EntityTopologySplitModalWrapper } from './EntityTopologySplitModal.styles';

const EntityTopologySplitModal = ({
  isOpen,
  onClose,
  onClosePanel,
  newEntityCount,
  kind,
  entityId,
  data: nodeData,
  selectedDataParts,
  intl,
  handleExecuteAction,
}) => {
  const params = {
    Kind: nodeData?.actions?.[0]?.splitEntityActionRequest?.Kind,
    Code: nodeData?.actions?.[0]?.splitEntityActionRequest?.Code,
    DataPartIds: selectedDataParts,
  };

  let paramsToString;
  if (selectedDataParts && selectedDataParts?.length > 0 && nodeData) {
    paramsToString = JSON.stringify(params);
  }
  if ((!selectedDataParts || selectedDataParts?.length <= 0) && nodeData) {
    paramsToString = JSON.stringify(
      nodeData?.actions?.[0]?.splitEntityActionRequest,
    );
  }

  const [entitySplitExecute, { data, loading, error }] = useEntitySplitExecute(
    intl,
    kind,
  );
  const handleOnClick = () => {
    entitySplitExecute(entityId, paramsToString);

    setTimeout(() => {
      onClose();
      onClosePanel();
      handleExecuteAction();
    }, 200);
  };

  let dialogTitle = '';
  if (kind === 'RemoveEntityCode') {
    if (selectedDataParts?.length === 1) {
      dialogTitle = (
        <FormattedMessage id="module-entityTopology-dialogTitleRemoveEntityCode" />
      );
    }
    if (selectedDataParts?.length > 1) {
      dialogTitle = (
        <FormattedMessage id="module-entityTopology-dialogTitleRemoveEntityCodes" />
      );
    }
  }
  if (kind === 'UndoDeduplicationProjectMerge') {
    dialogTitle = (
      <FormattedMessage id="module-entityTopology-dialogTitleDeduplication" />
    );
  }
  if (kind === 'UndoManualMerge') {
    dialogTitle = (
      <FormattedMessage id="module-entityTopology-dialogTitleManual" />
    );
  }

  return (
    <Modal
      height="750px"
      isOpen={isOpen}
      onClose={onClose}
      title={dialogTitle}
      footer={{
        content: (
          <>
            <CancelButton
              text="Cancel"
              rounded
              style={{ marginRight: '10px' }}
              onClick={onClose}
            />
            <PrimaryButton text="Confirm" rounded onClick={handleOnClick} />
          </>
        ),
      }}
    >
      <EntityTopologySplitModalWrapper>
        <EntityTopologySplitModalContent
          newEntityCount={newEntityCount}
          kind={kind}
          selectedDataParts={selectedDataParts}
        />
      </EntityTopologySplitModalWrapper>
    </Modal>
  );
};

export default injectIntl(EntityTopologySplitModal);

EntityTopologySplitModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClosePanel: PropTypes.func,
  newEntityCount: PropTypes.number,
  kind: PropTypes.string,
  entityId: PropTypes.string,
  data: PropTypes.object,
  selectedDataParts: PropTypes.array,
  handleExecuteAction: PropTypes.func,
};

EntityTopologySplitModal.defaultProps = {
  isOpen: null,
  onClose: () => {},
  onClosePanel: () => {},
  newEntityCount: null,
  kind: null,
  entityId: null,
  data: {},
  selectedDataParts: [],
  handleExecuteAction: () => {},
};
