import { createSelector } from 'reselect';

const getTypedSuggestions = (currentInputValue, entityTypePoolIdentifier) => {
  let typedSuggestionsData =
    (currentInputValue && [
      {
        Name: `${currentInputValue}`,
        Highlight: '',
        entityType: 'none',
      },
      {
        Name: `${currentInputValue}`,
        Highlight: '',
        entityType: '/Person',
        badgeLabel: 'person',
      },
      {
        Name: `${currentInputValue}`,
        Highlight: '',
        entityType: '/Organization',
        badgeLabel: 'organization',
      },
    ]) ||
    [];

  if (entityTypePoolIdentifier) {
    typedSuggestionsData = typedSuggestionsData.filter((sug) => {
      if (
        sug.entityType === '/Organization' &&
        entityTypePoolIdentifier === 'organization'
      ) {
        return false;
      }
      if (
        sug.entityType === '/Person' &&
        entityTypePoolIdentifier === 'person'
      ) {
        return false;
      }
      return true;
    });
  }

  return typedSuggestionsData;
};

export const getSuggestionViewModel = (
  suggestions,
  currentInputValue,
  entityTypePoolIdentifier,
) => {
  // return (suggestions || []);
  const suggestedTypesSearches = getTypedSuggestions(
    currentInputValue,
    entityTypePoolIdentifier,
  );

  if (!currentInputValue || currentInputValue.length <= 2) {
    return suggestedTypesSearches;
  }

  return (suggestedTypesSearches || []).concat(suggestions || []);
};

const getSuggestionsStore = ({ searchModule }) =>
  searchModule ? searchModule.searchSuggestions : [];

const getSuggestion = createSelector(
  [getSuggestionsStore],
  (searchSuggestions) =>
    searchSuggestions ? searchSuggestions.suggestions : [],
);

export const getFromPath = (state, { params }) => {
  return params ? params.q : '';
};

export const currentInputValue = (state, { location }) => {
  return location && location.params ? location.params.q : '';
};

export const getCurrentValueFromStore = (state) => {
  return state && state.searchModule
    ? state.searchModule.currentInputValue
    : null;
};

export const getIsFetchingSuggestion = createSelector(
  [getSuggestionsStore],
  (searchSuggestions) =>
    searchSuggestions ? searchSuggestions.isFetching : [],
);

export const getCurrentInpuValue = createSelector(
  [getCurrentValueFromStore, getFromPath],
  (value, path) => {
    return value === null ? path : value;
  },
);

export const getEntityTypePoolIdentifier = (state) => {
  return state &&
    state.searchModule &&
    state.searchModule.search &&
    state.searchModule.search.main &&
    state.searchModule.search.main.entityTypePoolIdentifier
    ? state.searchModule.search.main.entityTypePoolIdentifier
    : '';
};

export const getSuggestionVM = createSelector(
  [getSuggestion, getCurrentInpuValue, getEntityTypePoolIdentifier],
  getSuggestionViewModel,
);

export default {
  getSuggestionVM,
  getIsFetchingSuggestion,
  getEntityTypePoolIdentifier,
  getCurrentInpuValue,
};
