import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { ForgotPasswordForm } from 'uxi-business';

import { shouldRequestEmailReset } from '../../actions/forgotPwd';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';
import { clientId } from '../../../../config';

const ForgotPwdFormContainer = ({
  isFetching,
  error,
  onShouldRequestEmailReset,
  isDone,
}) => (
  <div className="test_public_ForgotPasswordForm">
    <CluedInBigTitle>
      <div data-test="reset-password-title">
        <FormattedMessage id="module-public-forgotPwd-ForgotPwdTitleText" />
      </div>
    </CluedInBigTitle>
    {isDone && (
      <Alert noIcon type="success">
        <FormattedMessage
          id="module-public-forgotPwd-ForgotPwdSuccessMessageLine1"
          defaultMessage="If the details you have entered are correct,"
        />
        <br />
        <FormattedMessage
          id="module-public-forgotPwd-ForgotPwdSuccessMessageLine2"
          defaultMessage="you will receive an email with an invitation to reset your password."
        />
      </Alert>
    )}
    {error && (
      <Alert noIcon type="danger">
        <FormattedMessage id="module-public-forgotPwd-ForgotPwdGenericError" />
      </Alert>
    )}
    {!isDone && (
      <div data-test="reset-password-form">
        <ForgotPasswordForm
          onClick={onShouldRequestEmailReset}
          isFetching={isFetching}
        />
      </div>
    )}
  </div>
);

const mapToStateProps = ({ forgotPwd: { isFetching, done, error } }) => ({
  isFetching,
  isDone: done,
  error,
});

export const mapDispatchToProps = (dispatch) => ({
  onShouldRequestEmailReset: ({ email }) =>
    dispatch(shouldRequestEmailReset({ email, clientId })),
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(ForgotPwdFormContainer);
