import { useMutation, gql } from '@apollo/client';

const CREATE_DATA_SOURCE_SET = gql`
  mutation createDataSourceSet($dataSourceSet: InputDataSourceSet) {
    inbound {
      createDataSourceSet(dataSourceSet: $dataSourceSet)
    }
  }
`;

type Options = {
  name: string;
  author: string;
};

type CreateDataSourceSetRes = {
  inbound: {
    createDataSourceSet: string;
  };
};

export const useCreateDataSourceSet = () => {
  const [createDataSourceSet, { data, error, loading }] = useMutation<
    CreateDataSourceSetRes,
    { dataSourceSet: Options }
  >(CREATE_DATA_SOURCE_SET);

  const handleCreateDataSourceSet = ({ name, author }: Options) => {
    return createDataSourceSet({
      variables: { dataSourceSet: { name, author } },
    });
  };

  return [
    handleCreateDataSourceSet,
    {
      data: data?.inbound?.createDataSourceSet,
      error,
      loading,
    },
  ];
};
