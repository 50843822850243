"use strict";

exports.__esModule = true;
exports.management = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var MANAGEMENT_ANNOTATION_CONSULTANT = _claimTypes.default.MANAGEMENT_ANNOTATION_CONSULTANT,
  MANAGEMENT_ANNOTATION_INFORMED = _claimTypes.default.MANAGEMENT_ANNOTATION_INFORMED,
  MANAGEMENT_DATACATALOG_INFORMED = _claimTypes.default.MANAGEMENT_DATACATALOG_INFORMED,
  MANAGEMENT_DUPLICATES_INFORMED = _claimTypes.default.MANAGEMENT_DUPLICATES_INFORMED,
  MANAGEMENT_GLOSSARY_CONSULTANT = _claimTypes.default.MANAGEMENT_GLOSSARY_CONSULTANT,
  MANAGEMENT_GLOSSARY_INFORMED = _claimTypes.default.MANAGEMENT_GLOSSARY_INFORMED,
  MANAGEMENT_RULEBUILDER_CONSULTANT = _claimTypes.default.MANAGEMENT_RULEBUILDER_CONSULTANT,
  MANAGEMENT_RULEBUILDER_INFORMED = _claimTypes.default.MANAGEMENT_RULEBUILDER_INFORMED,
  MANAGEMENT_DATACATALOG_CONSULTANT = _claimTypes.default.MANAGEMENT_DATACATALOG_CONSULTANT,
  MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_INFORMED = _claimTypes.default.MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_INFORMED,
  MANAGEMENT_DEDUPLICATIONREVIEW_INFORMED = _claimTypes.default.MANAGEMENT_DEDUPLICATIONREVIEW_INFORMED,
  MANAGEMENT_DEDUPLICATIONREVIEW_CONSULTANT = _claimTypes.default.MANAGEMENT_DEDUPLICATIONREVIEW_CONSULTANT,
  MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_CONSULTANT = _claimTypes.default.MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_CONSULTANT,
  MANAGEMENT_HIERARCHIES_CONSULTANT = _claimTypes.default.MANAGEMENT_HIERARCHIES_CONSULTANT,
  MANAGEMENT_HIERARCHIES_INFORMED = _claimTypes.default.MANAGEMENT_HIERARCHIES_INFORMED;
var management = {
  annotations: {
    annotation: {
      create: {
        claims: MANAGEMENT_ANNOTATION_CONSULTANT
      },
      edit: {
        claims: MANAGEMENT_ANNOTATION_CONSULTANT
      },
      view: {
        claims: MANAGEMENT_ANNOTATION_INFORMED
      }
    }
  },
  dataCatalog: {
    catalog: {
      create: {
        claims: MANAGEMENT_DATACATALOG_CONSULTANT
      },
      view: {
        claims: MANAGEMENT_DATACATALOG_INFORMED
      },
      edit: {
        claims: MANAGEMENT_DATACATALOG_CONSULTANT
      },
      delete: {
        claims: MANAGEMENT_DATACATALOG_CONSULTANT
      }
    }
  },
  duplicates: {
    duplicate: {
      view: {
        claims: MANAGEMENT_DUPLICATES_INFORMED
      }
    }
  },
  glossary: {
    category: {
      create: {
        claims: MANAGEMENT_GLOSSARY_CONSULTANT
      },
      delete: {
        claims: MANAGEMENT_GLOSSARY_CONSULTANT
      },
      edit: {
        claims: MANAGEMENT_GLOSSARY_CONSULTANT
      },
      view: {
        claims: MANAGEMENT_GLOSSARY_INFORMED
      }
    },
    term: {
      create: {
        claims: MANAGEMENT_GLOSSARY_CONSULTANT
      },
      delete: {
        claims: MANAGEMENT_GLOSSARY_CONSULTANT
      },
      edit: {
        claims: MANAGEMENT_GLOSSARY_CONSULTANT
      }
    }
  },
  rules: {
    rule: {
      create: {
        claims: MANAGEMENT_RULEBUILDER_CONSULTANT
      },
      delete: {
        claims: MANAGEMENT_RULEBUILDER_CONSULTANT
      },
      edit: {
        claims: MANAGEMENT_RULEBUILDER_CONSULTANT
      },
      view: {
        claims: MANAGEMENT_RULEBUILDER_INFORMED
      }
    },
    ruleOrder: {
      edit: {
        claims: MANAGEMENT_RULEBUILDER_CONSULTANT
      }
    }
  },
  deduplication: {
    view: {
      claims: MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_INFORMED
    },
    delete: {
      claims: MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_CONSULTANT
    },
    edit: {
      claims: MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_CONSULTANT
    },
    create: {
      claims: MANAGEMENT_DEDUPLICATIONPROJECTMANAGEMENT_CONSULTANT
    },
    reviewer: {
      view: {
        claims: MANAGEMENT_DEDUPLICATIONREVIEW_INFORMED
      },
      delete: {
        claims: MANAGEMENT_DEDUPLICATIONREVIEW_CONSULTANT
      },
      edit: {
        claims: MANAGEMENT_DEDUPLICATIONREVIEW_CONSULTANT
      },
      create: {
        claims: MANAGEMENT_DEDUPLICATIONREVIEW_CONSULTANT
      }
    }
  },
  hierarchies: {
    create: {
      claims: MANAGEMENT_HIERARCHIES_CONSULTANT
    },
    delete: {
      claims: MANAGEMENT_HIERARCHIES_CONSULTANT
    },
    edit: {
      claims: MANAGEMENT_HIERARCHIES_CONSULTANT
    },
    view: {
      claims: MANAGEMENT_HIERARCHIES_INFORMED
    }
  }
};
exports.management = management;