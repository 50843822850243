import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import {
  currentUsage,
  current,
  save,
  uploadFile,
  updateEntityConfig,
  createEntityConfig,
} from './data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  defaultAsyncCallWithActionsWithParam,
} from '../../action/helpers/actionCreator';
import { shouldFetchWidgetConfiguration } from '../wms/actions';
import { withCache, shouldShowAlert } from '../core/actions';
import { withDefaultErrorHandlingActions } from '../error/actions';

/**
 * BEFORE WE WERE FETCHING THE ENTITY OF THE ID - I DO NOT THINK WE USE THIS ANYMORE
 * /*
export function fetchCurrentOrganization(apiToken) {
  return (dispatch, getState) => {
    if (getState().organization.currentOrganization) {
      return;
    }

    dispatch(requestCurrentOrganization());

    current(apiToken).then((org) => {
      dispatch(updateThemeWithOrg(org));
      getById(org.Id, apiToken).then((orgEntity) => {
        const orgReceived = org;

        orgReceived.entity = orgEntity;

        dispatch(receiveCurrentOrganization(orgReceived));
      });
    }).catch(unauthorized(dispatch, invalidCurrentOrganization));
  };
}
*/

export const shouldUpdateOrganization = createAction(
  'CURRENT_ORGANIZATION_UPDATE',
);

export const currentUsageAction = createAsyncAction('CURRENT_USAGE_V2');

export const shouldFetchCurrentUsage = withCache(
  'org',
  'usage',
  'isFetchingUsage',
)(
  withDefaultErrorHandlingActions(
    defaultAsyncCallWithActions(currentUsage, currentUsageAction),
  ),
);

export const currentOrganizationActions = createAsyncAction(
  'CURRENT_ORGANIZATION_V2',
);
export const shouldFetchCurrentOrganization = withCache(
  'org',
  'organization',
  'isFetchingOrganization',
)(
  withDefaultErrorHandlingActions(
    defaultAsyncCallWithActions(current, currentOrganizationActions),
  ),
);

export const saveOrganizationActions = createAsyncAction(
  'CURRENT_ORGANIZATION_SAVE',
);
export const shouldSaveOrganization = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(save, saveOrganizationActions),
);

export const uploadLogoActions = createAsyncAction('CURRENT_ORGANIZATION_SAVE');

export const shouldUploadLogo = (file, currentOrganization) => (dispatch) => {
  dispatch(uploadLogoActions.request());

  return uploadFile(file).then((url) =>
    save({
      ...currentOrganization,
      Logo: url,
    }).then(() => {
      dispatch(uploadLogoActions.receive(url));
    }),
  );
};

export const shouldResetLogo = (currentOrganization) => (dispatch) => {
  dispatch(
    shouldSaveOrganization({
      ...currentOrganization,
      Logo: '',
    }),
  );
};

export const updatePrimaryColor = createAction(
  'CURRENT_ORGANIZATION_UPDATE_PRIMARY_COLOR',
);
export const updateSecondaryColor = createAction(
  'CURRENT_ORGANIZATION_UPDATE_SECONDARY_COLOR',
);
export const updateLogoForPreview = createAction(
  'CURRENT_ORGANIZATION_UPDATE_LOGO_FOR_PREVIEW',
);
export const shouldClearLogo = createAction('CURRENT_ORGANIZATION_CLEAR_LOGO');

export const updateEntityConfigActions = createAsyncAction(
  'UPDATE_ENTITY_CONFIG',
);
export const createEntityConfigActions = createAsyncAction(
  'CREATE_ENTITY_CONFIG',
);

export const shouldUpdateEntityConfig = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActionsWithParam(
    updateEntityConfig,
    updateEntityConfigActions,
    (dispatch) => {
      dispatch(
        shouldShowAlert({
          description: 'Entity configuration successfully saved.',
          type: 'success',
        }),
      );
      dispatch(shouldFetchWidgetConfiguration());
      dispatch(push('/admin/settings/entity-types'));
    },
  ),
);

export const shouldCreateEntityConfig = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActionsWithParam(
    createEntityConfig,
    createEntityConfigActions,
    (dispatch) => {
      dispatch(
        shouldShowAlert({
          description: 'Entity configuration successfully saved.',
          type: 'success',
        }),
      );
      dispatch(shouldFetchWidgetConfiguration());
      dispatch(push('/admin/settings/entity-types'));
    },
  ),
);
