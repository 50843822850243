import { PrimaryButton, TextField, FormDecorator } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

const SignIn = ({
  style = {},
  loadingSignin,
  loginHandler,
  forgotUrl,
  email,
  setEmail,
  password,
  setPassword,
  emailRequired,
  emailError,
  passwordRequired,
  passwordError,
  touchedEmail,
  setTouchedEmail,
  touchedPassword,
  setTouchedPassword,
  invalidForm,
}) => {
  const touched = touchedEmail && touchedPassword;

  return (
    <div
      style={style}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          loginHandler(e);
        }
      }}
    >
      <FormDecorator label={<FormattedMessage id="module-auth-email" />}>
        <div data-test="signIn_signIn_emailTextField">
          <TextField
            autoFocus
            name="email"
            type="email"
            value={email}
            error={touchedEmail && (emailRequired || emailError)}
            onChange={(e, v) => {
              setTouchedEmail(true);
              setEmail(v);
            }}
          />
        </div>
      </FormDecorator>
      <FormDecorator label={<FormattedMessage id="module-auth-password" />}>
        <div data-test="signIn_signIn_passwordTextField">
          <TextField
            name="password"
            type="password"
            value={password}
            error={touchedPassword && (passwordRequired || passwordError)}
            onChange={(e, v) => {
              setTouchedPassword(true);
              setPassword(v);
            }}
          />
        </div>
      </FormDecorator>
      <div data-test="signIn_signIn_signInButton">
        <PrimaryButton
          disabled={!touched || loadingSignin || invalidForm}
          loading={loadingSignin}
          isFullWidth
          onClick={loginHandler}
          text={<FormattedMessage id="module-auth-signInAction" />}
        />
      </div>
      <div style={{ paddingTop: '16px' }}>
        <a href={forgotUrl || '/forgot'} data-test="forgot-password-link">
          <FormattedMessage id="module-auth-forgotPassword" />
        </a>
      </div>
    </div>
  );
};

export default SignIn;
