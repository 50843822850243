import React from 'react';
import { Select } from 'uxi/Input';
import { Flex } from 'uxi/Layout';
import { FormattedMessage } from '@cluedin/locale';

const SearchBoxPoolSelectIdentifier = ({ value, onEntityPoolChange }) => {
  return (
    <Select value={value} isFullWidth onChange={onEntityPoolChange}>
      <Flex value="">
        <div>
          <FormattedMessage id="module-core-searchBox-all" />
        </div>
      </Flex>
      <Flex value="person">
        <div>
          <FormattedMessage id="module-core-searchBox-person" />
        </div>
      </Flex>
      <Flex value="organization">
        <div>
          <FormattedMessage id="module-core-searchBox-org" />
        </div>
      </Flex>
    </Select>
  );
};

export default SearchBoxPoolSelectIdentifier;
