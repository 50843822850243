import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import ReactDOMServer from 'react-dom/server';

import { useTheme } from '@cluedin/theme';

import { LogInIcon } from './LogInIcon';

const serializeSVGToString = (component) => {
  const svgString = ReactDOMServer.renderToString(component);
  return encodeURIComponent(svgString);
};

const convertedIcon = (color) => {
  return serializeSVGToString(<LogInIcon color={color} />);
};

const PublicContextContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 540px;
  right: 0;
  bottom: 0;
  height: 100%;
  @media screen and (min-width: 1024px) {
    background: ${({ themePrimary }) =>
      `url('data:image/svg+xml,${convertedIcon(
        themePrimary,
      )}') no-repeat center`};
    display: block;
    background-size: cover;
  }
`;

const PublicContext = ({ url = '' }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.accent;

  return <PublicContextContainer themePrimary={themePrimary} url={url} />;
};

export default pure(PublicContext);
