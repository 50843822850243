import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GqlErrorMessages } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';

import PageLoader from '../../../../core/components/composites/PageLoader';
import EntityTopologySplitPreviewNewEntity from '../EntityTopologySplitPreviewNewEntity/EntityTopologySplitPreviewNewEntity';
import {
  EntityTopologySplitPreviewNewEntitiesWrapper,
  EntityTopologySplitPreviewNewEntitiesBox,
  EntityTopologySplitPreviewNewEntitiesInnerWrapper,
  EntityTopologySplitPreviewNewEntitiesHeader,
  EntityTopologySplitPreviewNewEntitiesInformation,
  EntityTopologySplitPreviewNewEntitiesTitle,
  EntityTopologySplitPreviewNewEntitiesSourceWrapper,
} from './EntityTopologySplitPreviewNewEntities.styles';

const EntityTopologySplitPreviewNewEntities = ({
  data,
  loading,
  error,
  title,
  entityId,
  total,
  currentNode,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [isExpanded, setIsExpanded] = useState(true);

  if (error) {
    return <GqlErrorMessages error={error} />;
  }
  if (loading) {
    return <PageLoader />;
  }

  return (
    <EntityTopologySplitPreviewNewEntitiesWrapper theme={theme}>
      <EntityTopologySplitPreviewNewEntitiesBox isExpanded={isExpanded}>
        <EntityTopologySplitPreviewNewEntitiesInnerWrapper
          isExpanded={isExpanded}
          theme={theme}
        >
          <EntityTopologySplitPreviewNewEntitiesHeader
            data-test="entity-entityTopology-splitPreviewNewEntitiesHeader"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <Arrowdown color={themePrimary} size={16} />
            ) : (
              <Arrowright color={themePrimary} size={16} />
            )}
            <EntityTopologySplitPreviewNewEntitiesInformation>
              <EntityTopologySplitPreviewNewEntitiesTitle>
                {title}
              </EntityTopologySplitPreviewNewEntitiesTitle>
              {total &&
                (total === 1 ? (
                  <FormattedMessage
                    id="module-entityTopology-totalEntity"
                    values={{ total }}
                  />
                ) : (
                  <FormattedMessage
                    id="module-entityTopology-totalEntities"
                    values={{ total }}
                  />
                ))}
            </EntityTopologySplitPreviewNewEntitiesInformation>
          </EntityTopologySplitPreviewNewEntitiesHeader>
        </EntityTopologySplitPreviewNewEntitiesInnerWrapper>

        <EntityTopologySplitPreviewNewEntitiesSourceWrapper
          isExpanded={isExpanded}
        >
          {data &&
            (data || []).map((newEntity, index) => (
              <EntityTopologySplitPreviewNewEntity
                key={`${newEntity?.dataPartId}-${newEntity?.name}`}
                data={newEntity}
                entityId={entityId}
                index={index + 1}
                currentNode={currentNode}
              />
            ))}
        </EntityTopologySplitPreviewNewEntitiesSourceWrapper>
      </EntityTopologySplitPreviewNewEntitiesBox>
    </EntityTopologySplitPreviewNewEntitiesWrapper>
  );
};

export default EntityTopologySplitPreviewNewEntities;

EntityTopologySplitPreviewNewEntities.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  title: PropTypes.string,
  entityId: PropTypes.string,
  total: PropTypes.number,
  currentNode: PropTypes.object,
  currentNode: PropTypes.object,
};

EntityTopologySplitPreviewNewEntities.defaultProps = {
  data: {},
  loading: null,
  error: null,
  title: null,
  entityId: null,
  total: null,
  currentNode: {},
  currentNode: {},
};
