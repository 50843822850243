import { useState, useMemo, memo } from 'react';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';

import { withDialogWorkflow } from '../../../../core/components/Hocs/workflows/withDialogWorkflow';
import AddVocabulary from '../../../../dataCatalog/components/containers/EntityVocabSelection/AddVocabulary';
import AddEntityProperty from '../../../../dataCatalog/components/containers/EntityVocabSelection/AddEntityProperty';
import ColumnSelectionList from './ColumnSelectionList';

export const GoldenRecordPropertySelection = ({
  viewMode,
  columns = [],
  onAddColumns,
  onDeleteColumns,
  onChangeColumns,
  maxNumberColumns,
  excludePropertyKeys,
  defaultEntityType,
  values,
}) => {
  const { formatMessage } = useIntl();

  const EnhancedColumnSelectionList = useMemo(
    () =>
      withDialogWorkflow(ColumnSelectionList, AddEntityProperty, {
        dialogName: 'entityPropertyDialog',
        title:
          viewMode === 'tile'
            ? formatMessage({
                id: 'module-goldenRecordList-titleEntityProperty-as-tile',
              })
            : formatMessage({
                id: 'module-goldenRecordList-titleEntityProperty',
              }),
        mainButtonLabel: formatMessage({
          id: 'module-goldenRecordList-saveSelectionAction',
        }),
        methodName: 'onAddEntityProperty',
        width: '90vh',
        height: '85vh',
        bodyStyles: {
          padding: 0,
        },
      }),
    [viewMode],
  );

  const EnhancedColumnSelectionListWithVocabulary = useMemo(
    () =>
      withDialogWorkflow(EnhancedColumnSelectionList, AddVocabulary, {
        dialogName: 'vocabularyPropertyDialog',
        title: 'module-goldenRecordList-vocabPropertyDialog',
        title:
          viewMode === 'tile'
            ? formatMessage({
                id: 'module-goldenRecordList-vocabPropertyDialog-as-tile',
              })
            : formatMessage({
                id: 'module-goldenRecordList-vocabPropertyDialog',
              }),
        methodName: 'onAddVocabulary',
        mainButtonLabel:
          viewMode === 'tile'
            ? formatMessage({
                id: 'module-goldenRecordList-addVocabFields',
              })
            : formatMessage({
                id: 'module-goldenRecordList-addVocabColumns',
              }),
        width: '90vh',
        height: '85vh',
        bodyStyles: {
          padding: 0,
        },
      }),
    [viewMode],
  );

  const defaultSelectedVocabulary = columns
    .filter((p) => p.type === 'vocabulary')
    .map((p) => p.property);

  const [selectedVocabulary, setSelectedVocabulary] = useState(
    defaultSelectedVocabulary,
  );

  const defaultSelectedEntityProperty = columns
    .filter((p) => p.type === 'entity' && !p.fixed)
    .map((p) => p.property);

  const [selectedEntityProperty, setSelectedProperty] = useState(
    defaultSelectedEntityProperty,
  );

  const currentNumberColumns =
    (selectedVocabulary || []).length +
    (selectedEntityProperty || []).length +
    (viewMode === 'table' ? 1 : 0);

  const onMainButtonClick = (_, { dialogName, onClose }) => {
    if (dialogName === 'vocabularyPropertyDialog') {
      const selected = selectedVocabulary;

      onAddColumns(selected, 'vocabulary');

      setTimeout(() => {
        setSelectedVocabulary(() => selected);
      }, 100);
    }

    if (dialogName === 'entityPropertyDialog') {
      const selected = selectedEntityProperty;

      onAddColumns(selectedEntityProperty, 'entity');

      setTimeout(() => {
        setSelectedProperty(() => selected);
      }, 100);
    }

    onClose();
  };

  const onDeleteColumn = (deletedColumn) => {
    onDeleteColumns([deletedColumn]);

    if (deletedColumn?.type === 'entity') {
      setSelectedProperty((prev) =>
        prev.filter((i) => i !== deletedColumn?.property),
      );
    } else {
      setSelectedVocabulary((prev) =>
        prev.filter((i) => i !== deletedColumn?.property),
      );
    }
  };

  return (
    <EnhancedColumnSelectionListWithVocabulary
      addEntityPropOffsetTop={230}
      columns={columns}
      viewMode={viewMode}
      addLabel={values?.addLabel}
      onDeleteColumn={onDeleteColumn}
      onChangeColumns={onChangeColumns}
      maxNumberColumns={maxNumberColumns}
      defaultEntityType={defaultEntityType}
      onMainButtonClick={onMainButtonClick}
      selectedVocabulary={selectedVocabulary}
      excludePropertyKeys={excludePropertyKeys}
      currentNumberColumns={currentNumberColumns}
      selectedEntityProperty={selectedEntityProperty}
      mainActionDisabled={
        currentNumberColumns > maxNumberColumns ||
        (isEqual(defaultSelectedVocabulary, selectedVocabulary) &&
          isEqual(defaultSelectedEntityProperty, selectedEntityProperty))
      }
      onEntityPropertyChange={(selected) => {
        setSelectedProperty(selected || []);
      }}
      onEntityPropertyCancel={() => {
        setSelectedProperty(() => defaultSelectedEntityProperty);
      }}
      onVocabularySelectedChange={(selected) => {
        setSelectedVocabulary(selected || []);
      }}
      onVocabularySelectedCancel={() => {
        setSelectedVocabulary(() => defaultSelectedVocabulary);
      }}
    />
  );
};

export default memo(GoldenRecordPropertySelection);
