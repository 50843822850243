import { FormattedMessage } from '@cluedin/locale';
import { getClassicValidationMessage } from '../core/validation/messages';
import { validateVocabularyKeyName } from './components/containers/forms/VocabularyKeyForm/validation';

export const validateVocabularyName = (value) => {
  return getClassicValidationMessage(value, 250);
};

export const validatePrefix = (value) => {
  return getClassicValidationMessage(value, 50);
};

export const getVocabularyKeyNameValidationMessage = (value) => {
  const isValid = validateVocabularyKeyName(value);
  const validationError = getClassicValidationMessage(value, 250);

  if (validationError) {
    return validationError;
  }

  if (!isValid) {
    return (
      <FormattedMessage id="module-dataCatalog-vocabKey-field-name-error" />
    );
  }

  return null;
};
