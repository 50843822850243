import { FC } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PanelHeader } from '@cluedin/form';

import { SelectedEdgeDetail } from './SelectedEdgeDetail';
import { EdgeRelationInfoFooter } from './EdgeRelationInfoFooter';
import { EdgeRelationInfoWrapper } from './EdgeRelationInfoWrapper';
import { EdgeRelationInfoEdgeGroupEntryWrapper } from './EdgeRelationInfoEdgeGroupEntryWrapper';
import { EntityRelationEdgeProperties } from './EntityRelationEdgeProperties';
import {
  GraphNetwork,
  SelectedConnectingDirectionalEdge,
  SelectedConnectingEdge,
  UpdatedGraphNetworkNode,
} from '../types';

type EntityRelationInfoProps = {
  entityId: string;
  selectedEdge: SelectedConnectingEdge;
  setSelectedEdge: (edge: SelectedConnectingEdge | null) => void;
  onClose: () => void;
  onNodeClick: (selectedNode: UpdatedGraphNetworkNode) => void;
  onGroupedRelationsClick: (groupedNode: UpdatedGraphNetworkNode) => void;
  graphNetworkState?: GraphNetwork;
};

export const EntityRelationInfo: FC<EntityRelationInfoProps> = ({
  entityId,
  selectedEdge,
  setSelectedEdge,
  onClose,
  onNodeClick,
  onGroupedRelationsClick,
  graphNetworkState,
  ...props
}) => {
  const edgeType = selectedEdge?.edgeType;
  const edgeTypesArray = selectedEdge?.edgeTypesArray;
  const edgeDirection = selectedEdge?.edgeDirection;
  const nodeFrom = selectedEdge?.nodeFrom;
  const nodeTo = selectedEdge?.nodeTo;
  const edgeFrom = selectedEdge?.edgeFrom;
  const edgeTo = selectedEdge?.edgeTo;

  const handleOnClose = () => {
    setSelectedEdge?.(null);
    onClose?.();
  };

  return (
    <>
      <PanelHeader
        title={
          <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelTitle" />
        }
        onClose={handleOnClose}
      />

      <EdgeRelationInfoWrapper>
        <>
          <SelectedEdgeDetail
            nodeFrom={nodeFrom}
            nodeTo={nodeTo}
            edgeType={edgeType}
            totalEdgeTypes={edgeTypesArray?.length}
            edgeFrom={edgeFrom}
            edgeTo={edgeTo}
            onNodeClick={
              onNodeClick as (node: SelectedConnectingDirectionalEdge) => void
            }
            onGroupedRelationsClick={
              onGroupedRelationsClick as unknown as (
                nodeTo: SelectedConnectingDirectionalEdge,
                edgeType: string,
                edgeDirection: string,
              ) => void
            }
            edgeDirection={edgeDirection}
          />

          <div style={{ marginBottom: '32px' }}>
            <FormattedMessage id="module-entityRelationsGraph-edgePropertiesAssignedProperties" />
          </div>

          {edgeTypesArray?.map((edgeType, index) => (
            <EdgeRelationInfoEdgeGroupEntryWrapper key={`${edgeType}-${index}`}>
              <EntityRelationEdgeProperties
                edgeType={edgeType}
                entityId={entityId}
                edgeDirection={edgeDirection}
                edgeFrom={edgeFrom}
                edgeTo={edgeTo}
                selectedEdge={selectedEdge}
              />
            </EdgeRelationInfoEdgeGroupEntryWrapper>
          ))}
          <EdgeRelationInfoFooter onClose={handleOnClose} />
        </>
      </EdgeRelationInfoWrapper>
    </>
  );
};
