import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { flexCSSString, Flex } from 'uxi/Layout';
import { Phone, Emailthread } from 'uxi/Icons';
import { textEllipsisStylesCSSString } from 'uxi/Text/TextEllipsis';
import { color, socialIconSize } from '../../theme';

const OrganizationListItemWrapperUI = styled.li`
  overflow: hidden;
  display: flex;
  align-items: center;
  list-style: none;
  height: 100%;
  padding: 4px;
  border-radius: 3px;
  color: ${color};
`;

const FlexRightColumn = styled.div`
  ${flexCSSString};
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 0px 16px;
  border-left: 1px solid #cecece;
  flex-grow: 9;
`;

const FlexLeftColumn = styled.div`
  ${flexCSSString};
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 88px;
  min-width: 88px;
  max-width: 88px;
  align-items: center;
  padding: 0 8px;
`;

const Ellipsis = styled.div`
  ${textEllipsisStylesCSSString};
  max-width: 100%;
  height: 22px;
  padding: 2px 0;
  max-width: 100%;
  width: 100%;
`;

const SocialFlexWrapper = styled.div`
  ${flexCSSString};
  width: 100%;
  height: 26px;
  margin: 8px auto;

  svg * {
    fill: ${({ theme: { palette } }) => palette.accent.main};
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    &:hover,
    &:focus {
      fill: ${({ theme: { palette } }) => palette.accent.dark};
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
  }
`;

const OrganizationListItemLayout = ({
  name,
  logo,
  socialMenu,
  location,
  industry,
  entityId,
  phoneNumber,
  email,
  showEntityQuickView,
}) => (
  <div
    style={{
      padding: '16px 8px',
      border: '1px solid #cecece',
      borderRadius: '3px',
    }}
  >
    <OrganizationListItemWrapperUI className="test_searchResult_organization">
      <FlexLeftColumn>
        {logo}
        <SocialFlexWrapper>{socialMenu}</SocialFlexWrapper>
      </FlexLeftColumn>
      <FlexRightColumn style={{ justifyContent: 'flex-start' }}>
        <Link
          to={`/organization/${entityId}`}
          style={{ maxWidth: '100%', width: '100%' }}
          onClick={(e) => {
            if (showEntityQuickView) {
              const { ctrlKey, metaKey } = e;

              if (ctrlKey || metaKey) {
                // noop, let native 'open in a new tab'
                // behaviour take place
              } else {
                e.preventDefault();
                showEntityQuickView({
                  entityId,
                  entityType: '/Organization',
                });
              }
            }
          }}
        >
          <Ellipsis>{name}</Ellipsis>
        </Link>
        <Ellipsis style={{ fontSize: '14px' }}>{industry}</Ellipsis>
        <Ellipsis style={{ fontSize: '14px' }}>{location}</Ellipsis>
        <Flex style={{ width: '100%' }}>
          <Emailthread size={socialIconSize} />
          <Ellipsis
            style={{ fontSize: '14px', marginLeft: '4px', flexGrow: 1 }}
          >
            {email}
          </Ellipsis>
        </Flex>
        <Flex style={{ width: '100%' }}>
          <Phone size={socialIconSize} />
          <Ellipsis
            style={{ fontSize: '14px', marginLeft: '4px', flexGrow: 1 }}
          >
            {phoneNumber}
          </Ellipsis>
        </Flex>
      </FlexRightColumn>
    </OrganizationListItemWrapperUI>
  </div>
);

OrganizationListItemLayout.displayName = 'OrganizationListItemLayout';

export default OrganizationListItemLayout;
