import React from 'react';
import { getEntityTypeIcon } from '@cluedin/components';
import { getEntityConfiguration } from '../../wms/helpers/widgetHelper';
import IntegrationIconContainer from '../../integration/components/containers/IntegrationIconContainer';

const createFilterViewModels = (facet = {}, key) => {
  const { terms } = facet;
  return (terms || []).map(({ term }) => ({
    value: term,
    type: key,
    displayName: term,
    icon: null,
  }));
};

const createFilterViewModelsFromProviderFromGql = (facet = {}, key) => {
  const { Items } = facet;
  return Items.map(({ Name }) => {
    return {
      value: Name,
      displayName: Name,
      type: key,
      icon: <IntegrationIconContainer integrationName={Name} iconSize="xs" />,
    };
  });
};

const createFilterViewModelsFromGql = (facet = {}, key) => {
  const { Items } = facet;
  return (Items || []).map(({ Name, Count }) => ({
    value: Name,
    type: key,
    displayName: Name,
    icon: null,
    count: Count,
  }));
};

const createFilterViewModelsFromProvider = (facet = {}, key) => {
  const { terms } = facet;
  return terms.map(({ term }) => {
    return {
      value: term,
      displayName: term,
      type: key,
      icon: <IntegrationIconContainer integrationName={term} iconSize="xs" />,
    };
  });
};

const createFilterViewModelsFromEntityType = (facet = {}, key) => {
  const { terms } = facet;
  return terms.map(({ term }) => {
    const entityConfig = getEntityConfiguration(term);

    const icon = entityConfig && entityConfig.icon ? entityConfig.icon : 'Help';
    const displayName =
      entityConfig && entityConfig.displayName
        ? entityConfig.displayName
        : term;

    const Icon = getEntityTypeIcon(icon || 'Help');

    return {
      type: key,
      value: term,
      displayName,
      icon: <Icon />,
    };
  });
};

const createFilterViewModelsFromEntityTypeGql = (
  facet = {},
  key,
  entityConfigurations = [],
) => {
  const { Items } = facet;
  return Items.map(({ Name, Count }) => {
    const entityConfig = getEntityConfiguration(Name, entityConfigurations);

    const icon = entityConfig && entityConfig.icon ? entityConfig.icon : 'Help';
    const displayName =
      entityConfig && entityConfig.displayName
        ? entityConfig.displayName
        : Name;

    const Icon = getEntityTypeIcon(icon || 'Help');

    return {
      type: key,
      value: Name,
      displayName,
      icon: <Icon />,
      count: Count,
    };
  });
};

const excludedFacets = ['people', 'companies'];

export const createFiltersViewModelsFromGql = (
  facets = {},
  entityConfigurations,
) => {
  const customFiltersList = {
    providers: {
      createVM: createFilterViewModelsFromProviderFromGql,
      param: {},
    },
    entityType: {
      createVM: createFilterViewModelsFromEntityTypeGql,
      param: entityConfigurations,
    },
  };

  return Object.keys(facets)
    .filter((k) => excludedFacets.indexOf(k.toLowerCase()) === -1)
    .reduce((accu, key) => {
      if (customFiltersList[key]) {
        // eslint-disable-next-line no-param-reassign
        accu[key] = customFiltersList[key].createVM(
          facets[key],
          key,
          customFiltersList[key].param,
        );
      } else {
        // eslint-disable-next-line no-param-reassign
        accu[key] = createFilterViewModelsFromGql(facets[key], key);
      }

      return accu;
    }, {});
};

export const createFiltersViewModels = (facets = {}) => {
  const customFiltersList = {
    providers: {
      createVM: createFilterViewModelsFromProvider,
    },
    entityType: {
      createVM: createFilterViewModelsFromEntityType,
    },
  };

  return Object.keys(facets).reduce((accu, key) => {
    if (customFiltersList[key]) {
      // eslint-disable-next-line no-param-reassign
      accu[key] = customFiltersList[key].createVM(facets[key], key);
    } else {
      accu[key] = createFilterViewModels(facets[key], key); // eslint-disable-line no-param-reassign
    }

    return accu;
  }, {});
};

export default {
  createFiltersViewModelsFromGql,
};
