import React from 'react';
import styled from 'styled-components';

const FieldDisplayNameSubtitle = styled.div`
  text-transform: lowercase;
  font-weight: 400;
  font-size: 11px;
`;

const FieldDisplayName = ({ field }) => {
  const displayNameToUse = field.displayName || field.field;

  if (!field.displayName && field.field.indexOf('.') > -1) {
    const main = displayNameToUse.split('.').pop();
    const sub = displayNameToUse.replace(main, '');

    return (
      <div
        style={{
          fontWeight: 600,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>{main}</div>
        <FieldDisplayNameSubtitle>{sub}</FieldDisplayNameSubtitle>
      </div>
    );
  }

  if (field.ComponentDisplayName) {
    const CompToRender = field.ComponentDisplayName;
    return <CompToRender field={field} />;
  }

  return displayNameToUse;
};

export default FieldDisplayName;
