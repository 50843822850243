import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from 'uxi/Widget';
import { DivPadding } from 'uxi/Base';
import Helmet from 'react-helmet';
import { FormattedMessage } from '@cluedin/locale';
import {
  PageStructureContainer,
  PageStructureHeader,
} from '../../../../core/components/composites/PageStructure';

const UserSettingsWrapperComposite = ({ children }) => (
  <div>
    <Helmet title="CluedIn - User Settings" />
    <PageStructureHeader
      title={<FormattedMessage id="module-user-userSettings" />}
    />
    <PageStructureContainer style={{ margin: '15px 30px' }}>
      <Widget
        style={{ background: '#fff' }}
        title={<FormattedMessage id="module-users-changePassword" />}
      >
        <DivPadding padding="S">{children}</DivPadding>
      </Widget>
    </PageStructureContainer>
  </div>
);

UserSettingsWrapperComposite.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserSettingsWrapperComposite;
