import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';
import { Externallink } from 'uxi/Icons';

import {
  EntityPropertiesHistoryLatestWrapper,
  EntityPropertyHistoryLatestItemWrapper,
  EntityPropertiesVocabNotFound,
  EntityPropertyHistoryLatestItemTitle,
  EntityPropertyHistoryLatestItemValue,
  EntityPropertiesHistoryLatestLabel,
} from './EntityPropertiesHistoryLatest.styles';

const EntityPropertyHistoryLatestItem = ({ itemTitle, itemValue }) => {
  return (
    <EntityPropertyHistoryLatestItemWrapper>
      <EntityPropertyHistoryLatestItemTitle>
        {itemTitle}
      </EntityPropertyHistoryLatestItemTitle>
      <EntityPropertyHistoryLatestItemValue>
        {itemValue}
      </EntityPropertyHistoryLatestItemValue>
    </EntityPropertyHistoryLatestItemWrapper>
  );
};

EntityPropertyHistoryLatestItem.propTypes = {
  itemTitle: PropTypes.node,
  itemValue: PropTypes.node,
};

EntityPropertyHistoryLatestItem.defaultProps = {
  itemTitle: null,
  itemValue: null,
};

const EntityPropertiesHistoryLatest = ({
  entityVocabularyKey,
  vocabularyKey,
  value,
  SourceIcon,
}) => {
  const displayName = get(vocabularyKey, 'displayName');
  const propertyKey = get(vocabularyKey, 'key');

  return (
    <EntityPropertiesHistoryLatestWrapper>
      <EntityPropertiesHistoryLatestLabel>
        <FormattedMessage id="module-entityProperties-panelHistoryLatestTitle" />
      </EntityPropertiesHistoryLatestLabel>

      <EntityPropertyHistoryLatestItem
        itemTitle={
          <FormattedMessage id="module-entityProperties-panelHistoryDisplayName" />
        }
        itemValue={displayName ? displayName : entityVocabularyKey}
      />
      <EntityPropertyHistoryLatestItem
        itemTitle={
          <FormattedMessage id="module-entityProperties-panelHistorySource" />
        }
        itemValue={<SourceIcon />}
      />
      <EntityPropertyHistoryLatestItem
        itemTitle={
          <FormattedMessage id="module-entityProperties-panelHistoryKey" />
        }
        itemValue={
          propertyKey ? (
            <Link
              target="_blank"
              rel="noreferrer noopener"
              to={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
                propertyKey,
              )}`}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              {propertyKey}
              <div style={{ marginLeft: '6px' }}>
                <Externallink size={12} />
              </div>
            </Link>
          ) : (
            <EntityPropertiesVocabNotFound>
              <FormattedMessage id="module-entityProperties-panelHistoryKeyNotFound" />
            </EntityPropertiesVocabNotFound>
          )
        }
      />
      <EntityPropertyHistoryLatestItem
        itemTitle={
          <FormattedMessage id="module-entityProperties-panelHistoryLatest" />
        }
        itemValue={value}
      />
    </EntityPropertiesHistoryLatestWrapper>
  );
};

export default EntityPropertiesHistoryLatest;

EntityPropertiesHistoryLatest.propTypes = {
  entityVocabularyKey: PropTypes.string,
  value: PropTypes.string,
  vocabularyKey: PropTypes.object,
  SourceIcon: PropTypes.node,
};

EntityPropertiesHistoryLatest.defaultProps = {
  entityVocabularyKey: null,
  value: '',
  vocabularyKey: {},
  SourceIcon: null,
};
