import { createContext, ReactNode } from 'react';

import { useAppSearchStore } from './useAppSearchStore';

type UseAppSearchStoreReturnType = ReturnType<typeof useAppSearchStore>;

export const AppSearchContext =
  createContext<UseAppSearchStoreReturnType | null>(null);
AppSearchContext.displayName = 'AppSearchContext';

export const AppSearchProvider = ({ children }: { children: ReactNode }) => {
  const value = useAppSearchStore();

  return (
    <AppSearchContext.Provider value={value}>
      {children}
    </AppSearchContext.Provider>
  );
};
