import { apiRequest } from '../core/helpers/request';
import { toVM } from './viewModel';
import { url } from '../../config';

const { cluedInCleanUrl } = url;
const { publicApiUrl } = url;

export const getCleaningProjects = () =>
  apiRequest('GET', 'api/v1/clean/getprojects').then((resp) => toVM(resp));

export const saveCleaningProjects = (projectId) =>
  apiRequest('POST', `api/v1/clean/saveproject?projectId=${projectId}`);

export const createCleanProjects = (payload) =>
  apiRequest('POST', 'api/v1/clean/createprojects', payload);

export const createCleanProjectsByEntities = (payload) =>
  apiRequest('POST', 'api/v1/clean/createprojects/entities', payload);

export const deleteCleaningProjects = (projectId) =>
  apiRequest('DELETE', `api/v1/clean/deleteproject?projectId=${projectId}`);

export const viewProject = (projectId) =>
  apiRequest(
    'GET',
    `api/v1/clean/get-access-token?projectId=${projectId}`,
  ).then((response) => {
    window.open(
      `${cluedInCleanUrl}project?project=${projectId}&cluedin_api_url=${encodeURIComponent(
        publicApiUrl,
      )}&cluedin_api_token=${encodeURIComponent(response.token)}`,
      '_blank',
    );
  });

export default {
  getCleaningProjects,
  saveCleaningProjects,
  createCleanProjects,
  deleteCleaningProjects,
  viewProject,
};
