import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';

import ExplainLogCategory from '../ExplainLogCategory/ExplainLogCategory';
import ExplainLogBestGuessSource from '../ExplainLogBestGuessSource/ExplainLogBestGuessSource';
import {
  ExplainLogEntryBorderCircle,
  ExplainLogEntryBox,
  ExplainLogEntryDetails,
  ExplainLogEntryDetailsWrapper,
  ExplainLogEntryHeading,
  ExplainLogEntryHeadingWrapper,
  ExplainLogEntryCategoryWrapper,
  ExplainLogEntryWrapper,
} from './ExplainLogEntry.styles';

const ExplainLogEntry = ({ data, details, isGoldenRecord, entityId }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);
  const [filteredDetails, setFilteredDetails] = useState('');
  const dataPartId = get(data, 'identifier.details.dataPartId');
  const dataSource = get(data, 'source');

  const iconDetails = () => {
    let newIcon;
    if (details && Array.isArray(details)) {
      newIcon =
        details &&
        (details || []).filter(
          (item) => Number(item.data['attribute-id']) === Number(dataPartId),
        );
    } else {
      newIcon = details;
    }
    setFilteredDetails(newIcon);
  };

  useEffect(() => {
    iconDetails();
  }, [details, dataPartId]);

  let sourceIdToUse;
  if (Array.isArray(filteredDetails)) {
    if (filteredDetails[0]?.data?.['attribute-originProviderDefinitionId']) {
      sourceIdToUse =
        filteredDetails[0]?.data?.['attribute-originProviderDefinitionId'];
    } else {
      sourceIdToUse = null;
    }
  }
  if (!Array.isArray(filteredDetails)) {
    if (filteredDetails?.['attribute-originProviderDefinitionId']) {
      sourceIdToUse = filteredDetails?.['attribute-originProviderDefinitionId'];
    } else {
      sourceIdToUse = null;
    }
  }

  return isGoldenRecord ? (
    data &&
      (data.operations || []).map((opItem, opIndex) => {
        const hasEvents = opItem && opItem.events.length > 0;

        return (
          <div style={{ padding: '0 10px' }}>
            <ExplainLogCategory
              key={opIndex}
              data={opItem}
              hasEvents={hasEvents}
            />
          </div>
        );
      })
  ) : (
    <ExplainLogEntryBox>
      <ExplainLogEntryWrapper>
        <ExplainLogEntryHeading isExpanded={isExpanded}>
          <ExplainLogEntryHeadingWrapper data-test="entity_explainLog_entryButton">
            <ExplainLogEntryDetailsWrapper>
              <div onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? (
                  <Arrowdown
                    color={themePrimary}
                    size={16}
                    style={{ marginRight: '10px' }}
                  />
                ) : (
                  <Arrowright
                    color={themePrimary}
                    size={16}
                    style={{ marginRight: '10px' }}
                  />
                )}
              </div>

              <ExplainLogEntryBorderCircle
                onClick={() => setIsExpanded(!isExpanded)}
                themePrimary={themePrimary}
              />

              <ExplainLogEntryDetails>
                <ExplainLogBestGuessSource
                  onClick={() => setIsExpanded(!isExpanded)}
                  entityId={entityId}
                  dataPartId={dataPartId}
                  isGoldenRecord={isGoldenRecord}
                  sourceId={sourceIdToUse}
                  dataSource={dataSource}
                />
              </ExplainLogEntryDetails>
            </ExplainLogEntryDetailsWrapper>
          </ExplainLogEntryHeadingWrapper>
        </ExplainLogEntryHeading>

        <ExplainLogEntryCategoryWrapper isExpanded={isExpanded}>
          {data &&
            (data.operations || []).map((opItem, opIndex) => {
              const hasEvents = opItem?.events?.length > 0;

              return (
                <ExplainLogCategory
                  key={opIndex}
                  data={opItem}
                  hasEvents={hasEvents}
                />
              );
            })}
        </ExplainLogEntryCategoryWrapper>
      </ExplainLogEntryWrapper>
    </ExplainLogEntryBox>
  );
};

export default ExplainLogEntry;

ExplainLogEntry.propTypes = {
  data: PropTypes.object,
  details: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isGoldenRecord: PropTypes.bool,
  entityId: PropTypes.string,
};

ExplainLogEntry.defaultProps = {
  data: {},
  details: null,
  isGoldenRecord: false,
  entityId: null,
};
