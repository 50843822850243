import { connect } from 'react-redux';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { shouldGetEntityTypeReport } from '../../actions';
import LoadingChart from '../composites/reports/LoadingChart';
import LoadingError from '../composites/reports/LoadingError';

const CompEnhanced = compose(
  lifecycle({
    componentDidMount() {
      const { data, isFetchingEntityTypeReport, getEntityReport } = this.props;
      if (!isFetchingEntityTypeReport && !data) {
        getEntityReport();
      }
    },
  }),
  branch(
    ({ isFetchingEntityTypeReport }) => isFetchingEntityTypeReport,
    renderComponent(LoadingChart),
  ),
  branch(
    ({ isInvalidEntityTypeReport }) => isInvalidEntityTypeReport,
    renderComponent(LoadingError),
  ),
);

const mapSelectToProps = ({
  GDPR: {
    isFetchingEntityTypeReport,
    isInvalidEntityTypeReport,
    entityTypeReport,
  },
}) => ({
  isFetchingEntityTypeReport,
  isInvalidEntityTypeReport,
  data: entityTypeReport,
});

const mapDispatchToProps = (dispatch) => ({
  getEntityReport: () => {
    dispatch(shouldGetEntityTypeReport());
  },
});

const withEntityReportData = (Comp) => {
  return connect(mapSelectToProps, mapDispatchToProps)(CompEnhanced(Comp));
};

export default withEntityReportData;
