import { apiRequest } from '../core/helpers/request';
import {
  hasStartAsLastCharacter,
  removeLastCharacter,
} from '../core/helpers/string';

export const clearFollowingCount = (id) =>
  apiRequest('PUT', `api/insight/count?id=${id}`);

export const getAllFollowing = () => apiRequest('GET', 'api/insight/all');

export const getAllSearches = () =>
  apiRequest('GET', 'api/search/subscribe').then((savedSearches) => {
    if (savedSearches && savedSearches.length > 0) {
      savedSearches.forEach((s) => {
        const search = s;
        search.Name = hasStartAsLastCharacter(s.Query)
          ? removeLastCharacter(s.Query)
          : s.Query;
      });
    }
    return savedSearches;
  });

export const clearSearchCount = (id) =>
  apiRequest('PUT', `api/v1/search/clear?id=${id}`);

export const subscribeSearch = (q) =>
  apiRequest('POST', `api/search/subscribe?q=${q}`);

export const unSubscribeSearch = (q) =>
  apiRequest('POST', `api/search/unsubscribe?q=${q}`);
