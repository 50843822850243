import { FormattedMessage } from '@cluedin/locale';
import { List, ListToolbar } from '@cluedin/list-pattern';

import Tag from '../../../../glossary/components/composites/Tag';

const TypeTag = ({ type }) => {
  return <Tag label={type} />;
};

const VocabOrPropertySelectionList = ({
  columns = [],
  onAddEntityProperty,
  onAddVocabulary,
  onDeleteColumns,
  noEntityProperties,
  onlyFunctionality,
  disabled,
  offsetTop = 201,
}) => {
  const creationButtons = noEntityProperties
    ? [
        {
          key: 'addVocabProp',
          label: <FormattedMessage id="module-dataCatalog-addVocabProp" />,
          onClick: () => {
            onAddVocabulary();
          },
        },
      ]
    : [
        {
          key: 'addEntityProperty',
          label: <FormattedMessage id="module-dataCatalog-addEntityProperty" />,
          onClick: () => {
            onAddEntityProperty();
          },
        },
        {
          key: 'addVocabProp2',
          label: <FormattedMessage id="module-dataCatalog-addVocabProp" />,
          onClick: () => {
            onAddVocabulary();
          },
        },
      ];

  if (onlyFunctionality) {
    return (
      <ListToolbar
        noSearch
        style={{ padding: 0 }}
        creation={creationButtons}
        creationDisabled={disabled}
        creationLabel={<FormattedMessage id="module-dataCatalog-addProperty" />}
      />
    );
  }

  return (
    <List
      dataGridName="new-clean-select-vocab"
      noSearch
      data={columns}
      offsetTop={offsetTop}
      withToolbar={true}
      creationDisabled={disabled}
      actions={[
        {
          label: <FormattedMessage id="module-dataCatalog-removeProps" />,
          action: (properties) => {
            onDeleteColumns(properties.map((c) => ({ property: c })));
          },
        },
      ]}
      creationLabel={<FormattedMessage id="module-dataCatalog-addProperty" />}
      creation={creationButtons}
      propertyKey="property"
      notFoundProps={{
        buttonLabel: <FormattedMessage id="module-dataCatalog-addProps" />,
        message: (
          <FormattedMessage id="module-dataCatalog-addAtLeastOneProps" />
        ),
        explanation: (
          <FormattedMessage id="module-dataCatalog-addPropsExplanation" />
        ),
        noCreate: true,
      }}
      columns={[
        {
          displayName: <FormattedMessage id="module-dataCatalog-property" />,
          property: 'property',
        },
        {
          displayName: <FormattedMessage id="module-dataCatalog-type" />,
          property: 'type',
          Component: TypeTag,
        },
      ]}
    />
  );
};

export default VocabOrPropertySelectionList;
