import { RACI_LEVELS } from '@cluedin/components';
import ConsentFormDetailPageWrapper from './components/pages/ConsentFormDetailPageWrapper';
import ConsentDetailPageWrapper from './components/pages/ConsentDetailPageWrapper';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

const ConsentRoutes = [
  {
    path: '/',
    exact: true,
    claims: {
      'governance.consent': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"ConsentDashboardPage" */
        './components/pages/ConsentDashboardPage'
      ),
    ),
  },
  {
    path: '/forms',
    component: ConsentFormDetailPageWrapper,
    claims: {
      'governance.consent': RACI_LEVELS.CONSULTANT,
    },
    routes: [
      {
        path: '/',
        exact: true,
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ConsentFormListPage" */
            './components/pages/ConsentFormListPage'
          ),
        ),
      },
      {
        path: '/create',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"WebsiteFormGeneratorPage" */
            './components/pages/WebsiteFormGeneratorPage'
          ),
        ),
      },
      {
        path: '/detail/edit/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"WebsiteFormGeneratorEditPage" */
            './components/pages/WebsiteFormGeneratorEditPage'
          ),
        ),
      },
    ],
  },
  {
    path: '/consents',
    component: ConsentDetailPageWrapper,
    claims: {
      'governance.consent': RACI_LEVELS.CONSULTANT,
    },
    routes: [
      {
        path: '/',
        exact: true,
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ConsentListPage" */
            './components/pages/ConsentListPage'
          ),
        ),
      },
      {
        path: '/create',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"SingleConsentFormPage" */
            './components/pages/SingleConsentFormPage'
          ),
        ),
      },
      {
        path: '/result/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ConsentResultPage" */
            './components/pages/ConsentResultPage'
          ),
        ),
      },
    ],
  },
];

export default ConsentRoutes;
