import React from 'react';
import Flex from 'uxi/Layout/Flex';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';

const UsersPanelContent = ({ users, extra = null }) => (
  <div style={{ padding: '16px 16px 96px 16px', width: 'calc(100% - 32px)' }}>
    {(!users || users.length === 0) && (
      <Alert>
        <FormattedMessage id="module-user-notFound" />
      </Alert>
    )}
    {users.map((user) => {
      const username = (user.Account || {}).UserName || '-';
      const email = (user.Account || {}).Email || '-';

      return (
        <Flex
          style={{
            margin: '8px 0',
            padding: '8px 0',
            width: '100%',
            justifyContent: 'flex-start',
            borderBottom: '1px solid #cecece',
          }}
        >
          <Link to={`/admin/settings/users/settings/${user.Account.Id}`}>
            <strong>{username || email}</strong>
          </Link>
        </Flex>
      );
    })}
    {extra}
  </div>
);

export default UsersPanelContent;
