import styled from 'styled-components';
import { buttonResetStylesCSSString } from 'uxi/Button/buttonResetStyles';

export const UnstyledButtonForBadge = styled.button.attrs((/* props */) => ({
  onMouseOut: function onMouseOut(e) {
    if (e && e.target && e.target.blur) {
      e.target.blur();
    }
  },
}))`
  ${buttonResetStylesCSSString};

  margin: 0 4px 4px 0;

  &,
  & * {
    transition: ${({ theme: { transition } }) => transition.defaultAll};
  }
  &:hover,
  &:focus {
    & > * {
      background-color: ${({ theme: { palette } }) =>
        palette.semantic.error} !important;
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
    * {
      fill: white;
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
  }
`;

export default UnstyledButtonForBadge;
