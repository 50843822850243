"use strict";

exports.__esModule = true;
exports.GhostHistoryChangeSetItemsDataGridWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var GhostHistoryChangeSetItemsDataGridWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  margin: 10px 15px 10px 0;\n\n  & table {\n    border-bottom: 1px solid rgb(224, 224, 224);\n    border-left: 1px solid rgb(224, 224, 224);\n    border-right: 1px solid rgb(224, 224, 224);\n    font-size: 14px;\n    word-wrap: wrap;\n\n    & td {\n      word-break: break-all;\n    }\n\n    & tr:hover {\n      background: ", " !important;\n    }\n\n    & thead th {\n      background: #f9f9f9;\n      border-color: rgb(224, 224, 224) !important;\n      font-weight: 600;\n    }\n  }\n\n  @supports (-moz-appearance: none) {\n    & th {\n      position: static;\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.palette.themeLighterAlt;
});
exports.GhostHistoryChangeSetItemsDataGridWrapper = GhostHistoryChangeSetItemsDataGridWrapper;