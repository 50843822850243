import React, { Component } from 'react';
import ReactEmoji from 'react-emoji';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';

const styles = {
  container: {
    padding: '0 1em 1em 1em',
  },
};

const extractPseudoTag = new RegExp('<.*?>', 'g');

const removeBrackets = (m) => {
  const toReturn = m;
  const values = m.Message.match(extractPseudoTag);
  let result = m.Message;
  if (values && values.length > 0) {
    values.forEach((t) => {
      const withoutBracket = t.substring(1, t.length - 1);
      if (t.indexOf('http://') > -1 || t.indexOf('https://') > -1) {
        result = result.replace(
          t,
          `<a href="${withoutBracket}" target="_blank">${withoutBracket}</a>`,
        );
      } else {
        result = result.replace(t, withoutBracket);
      }
    });
  }

  toReturn.Message = result;

  return toReturn;
};

class Conversation extends Component {
  render() {
    const { messages } = this.props;
    let messagesToRender = messages.Messages;

    let numberOfAuthor = reduce(
      messagesToRender,
      (total, m) => {
        total.push(m.Author);
        return total;
      },
      [],
    );

    numberOfAuthor = uniq(numberOfAuthor);

    if (numberOfAuthor.length === 2) {
      messagesToRender = (messagesToRender || []).map((dis) => {
        const result = dis;
        if (dis.Author === numberOfAuthor[1]) {
          result.reverse = true;
        }

        return result;
      });
    }

    messagesToRender = (messagesToRender || []).map(removeBrackets);

    return (
      <div className="cluedIn_conversation" style={styles.container}>
        <ul className="media-list chat-list">
          {messagesToRender.map((m, i) => {
            const messageClassName = m.reverse ? 'media reversed' : 'media';
            let authorOnLeft;
            let authorOnRight;

            if (m.reverse) {
              authorOnRight = (
                <div className="media-right">
                  <div className="userName">{m.Author}</div>
                </div>
              );
            } else {
              authorOnLeft = (
                <div className="media-left">
                  <div className="userName">{m.Author}</div>
                </div>
              );
            }

            return (
              <li key={i} className={messageClassName}>
                {authorOnLeft}
                <div className="media-body">
                  <div
                    className="media-content"
                    style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                  >
                    {ReactEmoji.emojify(m.Message)}
                    {/* <div dangerouslySetInnerHTML={{ __html: ReactEmoji.emojify(m.Message)}} /> */}
                  </div>
                </div>
                {authorOnRight}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Conversation;
