import React, { useState, useEffect } from 'react';
import { List } from '@cluedin/list-pattern';
import { actionsConfiguration } from '@cluedin/components';
import { SortingHeaderCell } from '../../../../shared/components/SortingHeaderCell';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { useQueryHierarchies } from '../../hooks/useQueryHierarchies';
import { useDeleteHierarchies } from '../../hooks/useDeleteHierarchies';
import HierarchyLink from '../composites/HierarchyLink';
import UserAvatar from '../../../userModule/components/composites/UserAvatar';
import ListDateComponent from '../../../core/components/composites/lists/ListDateComponent';
import StatusFilter from '../composites/StatusFilter';
import { notFoundMessage } from '../../utils/messages';
import withCreateHierarchy from '../hocs/withCreateHierarchy';
import withCloneHierarchy from '../hocs/withCloneHierarchy';
import qs from 'query-string';

const CreatedDateComponent = ListDateComponent('createdAt');
const ModifiedDateComponent = ListDateComponent('modifiedAt');
const Author = ({ author }) => <UserAvatar user={author} />;
const EntityTypeComponent = ({ entityType }) => (
  <span style={{ fontStyle: 'italic' }}>{`${
    entityType === '' ? 'Any Entity Type' : entityType
  }`}</span>
);
const StatusComponent = ({ status }) => <span>{`${status}`}</span>;
const NodeCountComponent = ({ numberOfNodes }) => (
  <span>{`${numberOfNodes != null ? numberOfNodes : 0}`}</span>
);

const { management } = actionsConfiguration;

const HierarchyListContainer = ({
  openWorkflow,
  openCloneWorkflow,
  entity,
  match,
  history,
  location,
  intl,
}) => {
  const entityCode = entity?.data['attribute-origin'];

  const queryParameterStringify = qs.parse(location.search || '') || {};

  const getPageNumber = (pageNumber) => {
    if (pageNumber) {
      const pageNumberInt = parseInt(pageNumber, 10);
      return pageNumberInt > 1 ? pageNumberInt : null;
    }

    return null;
  };

  const queryParameters = {
    ...queryParameterStringify,
    entityCode: entityCode,
    status: queryParameterStringify.status,
    pageNumber: getPageNumber(queryParameterStringify.pageNumber),
  };

  const filters = queryParameters;

  const [data, loading, errors, networkStatus, refetch] =
    useQueryHierarchies(filters);
  const [deleteHierarchies, { data: deleteData }] = useDeleteHierarchies();
  const [searchNameState, setSearchNameState] = useState(filters.searchName);

  const onSortChange = (key, value) => {
    const newFilters = {
      ...queryParameters,
    };

    if (newFilters.sortBy === key && newFilters.sortDirection === value) {
      delete newFilters.sortBy;
      delete newFilters.sortDirection;
    } else {
      newFilters.sortBy = key;
      newFilters.sortDirection = value;
    }

    const qsFilter = qs.stringify(newFilters);

    history.push(`${match.url}?${qsFilter}`);
  };

  const onFilterChange = (key, value) => {
    const newFilters = {
      ...queryParameters,
    };

    if (value === null) {
      delete newFilters[key];
    } else {
      newFilters[key] = value;
    }

    const qsFilter = qs.stringify(newFilters);

    history.push(`${match.url}?${qsFilter}`);
  };

  const actionItems = [
    {
      label: <FormattedMessage id="module-hierarchy-clone" />,
      action: (hierarchyIds) => {
        openCloneWorkflow({
          id: hierarchyIds[0],
          name: data.data.find((h) => h.id == hierarchyIds[0]).name,
        });
      },
    },
    {
      label: <FormattedMessage id="module-hierarchy-delete" />,
      action: (hierarchyIds) => deleteHierarchies(hierarchyIds),
      withConfirm: true,
      confirmTitle: (
        <FormattedMessage id="module-hierarchy-confirmDeleteTitle" />
      ),
      confirmMessage: (
        <FormattedMessage id="module-hierarchy-confirmDeleteTitleMsg" />
      ),
    },
  ];

  useEffect(() => {
    if (deleteData) {
      history.push(match.url);
    }
  }, [deleteHierarchies]);

  return (
    <>
      <List
        offsetTop={10}
        hasFilters={Object.keys(filters).length > 0}
        loading={loading}
        error={errors}
        searchName={searchNameState}
        selectedPage={queryParameters.pageNumber}
        searchInputPlaceholder={intl.formatMessage({
          id: 'module-hierarchy-searchDefaultSearchPlaceholder',
        })}
        onPageChange={(value) => {
          const newFilters = {
            ...queryParameters,
          };

          if (value === null || value === 0 || value === 1) {
            delete newFilters.pageNumber;
          } else {
            newFilters.pageNumber = value;
          }

          const qsFilter = qs.stringify(newFilters);

          history.push(`${match.url}?${qsFilter}`);
        }}
        onChangeSearch={(v) => {
          setSearchNameState(v);
        }}
        onSubmitSearch={(value) => {
          const newFilters = {
            ...queryParameters,
          };

          if (value === null) {
            delete newFilters.searchName;
          } else {
            newFilters.searchName = value;
          }

          const qsFilter = qs.stringify(newFilters);

          history.push(`${match.url}?${qsFilter}`);
        }}
        filterToolbar={
          <StatusFilter
            value={filters.status}
            onChange={(v) => {
              onFilterChange('status', v);
            }}
          />
        }
        notFoundProps={{
          ...notFoundMessage,
          onCreateClick: () => {
            openWorkflow();
          },
        }}
        creation={[
          {
            label: <FormattedMessage id="module-hierarchy-createHierarchy" />,
            onClick: () => {
              openWorkflow();
            },
          },
        ]}
        propertyKey="id"
        total={data.total || 0}
        data={(data.data || []).map((d) => ({
          ...d,
          refetch,
          filters,
          entityCode,
        }))}
        columns={[
          {
            property: 'name',
            displayName: <FormattedMessage id="module-hierarchy-name" />,
            Component: HierarchyLink,
            menu: (
              <SortingHeaderCell
                className="__test_name"
                onClick={(value) => {
                  onSortChange('name', value);
                }}
                value={filters.sortBy === 'name' ? filters.sortDirection : null}
              />
            ),
          },
          {
            displayName: <FormattedMessage id="module-hierarchy-entityType" />,
            property: 'entityType',
            width: '120px',
            maxWidth: '120px',
            Component: EntityTypeComponent,
            menu: (
              <SortingHeaderCell
                className="__test_entityType"
                onClick={(value) => {
                  onSortChange('entityType', value);
                }}
                value={
                  filters.sortBy === 'entityType' ? filters.sortDirection : null
                }
              />
            ),
          },
          {
            displayName: <FormattedMessage id="module-hierarchy-author" />,
            property: 'ownedBy',
            width: '140px',
            Component: Author,
            menu: (
              <SortingHeaderCell
                className="__test_ownedBy"
                onClick={(value) => {
                  onSortChange('ownedBy', value);
                }}
                value={
                  filters.sortBy === 'ownedBy' ? filters.sortDirection : null
                }
              />
            ),
          },
          {
            displayName: <FormattedMessage id="module-hierarchy-createdAt" />,
            property: 'createdAt',
            Component: CreatedDateComponent,
            width: '100px',
            menu: (
              <SortingHeaderCell
                className="__test_createdAt"
                onClick={(value) => {
                  onSortChange('createdAt', value);
                }}
                value={
                  filters.sortBy === 'createdAt' ? filters.sortDirection : null
                }
              />
            ),
          },
          {
            displayName: <FormattedMessage id="module-hierarchy-modifiedAt" />,
            property: 'modifiedAt',
            Component: ModifiedDateComponent,
            width: '100px',
            menu: (
              <SortingHeaderCell
                className="__test_modifiedAt"
                onClick={(value) => {
                  onSortChange('modifiedAt', value);
                }}
                value={
                  filters.sortBy === 'modifiedAt' ? filters.sortDirection : null
                }
              />
            ),
          },
          {
            displayName: (
              <FormattedMessage id="module-hierarchy-numberOfNodes" />
            ),
            property: 'numberOfNodes',
            width: '100px',
            maxWidth: '100px',
            Component: NodeCountComponent,
            menu: (
              <SortingHeaderCell
                className="__test_numberOfNodes"
                onClick={(value) => {
                  onSortChange('numberOfNodes', value);
                }}
                value={
                  filters.sortBy === 'numberOfNodes'
                    ? filters.sortDirection
                    : null
                }
              />
            ),
          },
          {
            displayName: <FormattedMessage id="module-hierarchy-status" />,
            property: 'status',
            Component: StatusComponent,
            width: '70px',
            maxWidth: '70px',
            menu: (
              <SortingHeaderCell
                className="__test_status"
                onClick={(value) => {
                  onSortChange('status', value);
                }}
                value={
                  filters.sortBy === 'status' ? filters.sortDirection : null
                }
              />
            ),
          },
        ]}
        actions={actionItems}
      />
    </>
  );
};

export default withCloneHierarchy(
  withCreateHierarchy(injectIntl(HierarchyListContainer)),
);
