import React from 'react';
import InsightListItem from './InsightListItem';

const InsightList = ({
  insights,
  onInsightClick,
  viewChanges,
  onRemovInsightClick,
}) => (
  <div>
    <ul
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      {(insights || []).map((insight, index) => (
        <InsightListItem
          key={`insight-${index}`}
          id={insight.Entity.EntityId}
          entityType={insight.Entity.EntityType}
          insight={insight}
          onInsightClick={onInsightClick}
          viewChanges={viewChanges}
          onRemovInsightClick={onRemovInsightClick}
        />
      ))}
    </ul>
  </div>
);

export default InsightList;
