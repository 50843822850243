import Widget from 'uxi/Widget';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

const widgetStyles = {
  minWidth: '80px',
  width: '100%',
  maxWidth: '200px',
  maxHeight: '180px',
  minHeight: '180px',
  background: 'white',
  margin: '0 16px 16px 0',
  boxShadow:
    'rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px',
  position: 'relative',
};

const DashboardBadge = styled.div`
  z-index: 1;
  top: 24px;
  right: 12px;
  color: #3264ae;
  font-size: 10px;
  font-weight: 400;
  padding: 4px 10px;
  position: absolute;
  border-radius: 14px;
  background: #e6edf8;
  display: inline-flex;
  box-sizing: border-box;
  text-transform: uppercase;
`;

const DashboardWidget = ({
  children,
  className,
  dataTestName,
  deprecatedLabel,
  betaLabel,
}) => {
  const theme = useTheme();

  return (
    <Widget style={widgetStyles}>
      {betaLabel && <DashboardBadge theme={theme}>{betaLabel}</DashboardBadge>}

      {deprecatedLabel && (
        <DashboardBadge theme={theme}>{deprecatedLabel}</DashboardBadge>
      )}

      <div className={className} data-test={dataTestName}>
        {children}
      </div>
    </Widget>
  );
};

export default DashboardWidget;
