import { FC, memo } from 'react';
import { Callout, DelayedRender } from '@fluentui/react';

import { useAppSearch } from '../../context/useAppSearch';
import { AppSearchSuggestions } from './AppSearchSuggestions';

type Props = {
  id: string;
  width: string;
  suggestionsLabel: string;
  handleBlurAppSearchInput: () => void;
};

export const AppSearchSuggestionsCallout: FC<Props> = memo(
  ({ id, width, suggestionsLabel, handleBlurAppSearchInput }) => {
    const { isSuggestionsCalloutOpen, handleCloseSuggestions } = useAppSearch();

    if (!isSuggestionsCalloutOpen) {
      return null;
    }

    return (
      <Callout
        role="alert"
        target={`#${id}`}
        isBeakVisible={false}
        setInitialFocus={false}
        onDismiss={handleCloseSuggestions}
        styles={{
          root: {
            width,
          },
        }}
      >
        <>
          <AppSearchSuggestions
            suggestionsLabel={suggestionsLabel}
            handleBlurAppSearchInput={handleBlurAppSearchInput}
          />
        </>
      </Callout>
    );
  },
);
