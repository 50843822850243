"use strict";

exports.__esModule = true;
exports.useExpansionState = void 0;
var _lodash = require("lodash");
var _react = require("react");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var useExpansionState = function useExpansionState(intialValues) {
  if (intialValues === void 0) {
    intialValues = {};
  }
  var _useState = (0, _react.useState)(intialValues),
    expansionValues = _useState[0],
    setExpansionValues = _useState[1];
  var toggle = (0, _react.useCallback)(function (_ref) {
    var id = _ref.id,
      upLevelId = _ref.upLevelId;
    if (!upLevelId) {
      var foundItem = (0, _lodash.get)(expansionValues, id);
      if (foundItem) {
        return setExpansionValues(function (prev) {
          var _extends2;
          var expanded = !(foundItem != null && foundItem.expanded);
          return _extends({}, prev, (_extends2 = {}, _extends2[id] = _extends({}, prev[id], {
            expanded: expanded
          }), _extends2));
        });
      }
      return setExpansionValues(function (prev) {
        var _extends3;
        return _extends({}, prev, (_extends3 = {}, _extends3[id] = {
          expanded: true,
          items: {}
        }, _extends3));
      });
    }
    if (upLevelId) {
      // should be refactored to some recursion func in some point)
      // probably right after we have more than two expansion levels inside tree list
      var _foundItem = (0, _lodash.get)(expansionValues, upLevelId + ".items." + id);
      if (_foundItem) {
        return setExpansionValues(function (prev) {
          var _extends4, _extends5;
          var expanded = !(_foundItem != null && _foundItem.expanded);
          return _extends({}, prev, (_extends5 = {}, _extends5[upLevelId] = _extends({}, prev[upLevelId], {
            items: _extends({}, prev[upLevelId].items, (_extends4 = {}, _extends4[id] = _extends({}, prev[upLevelId].items[id], {
              expanded: expanded
            }), _extends4))
          }), _extends5));
        });
      }
      return setExpansionValues(function (prev) {
        var _extends6, _extends7;
        return _extends({}, prev, (_extends7 = {}, _extends7[upLevelId] = _extends({}, prev[upLevelId], {
          items: _extends({}, prev[upLevelId].items, (_extends6 = {}, _extends6[id] = {
            expanded: true,
            items: {}
          }, _extends6))
        }), _extends7));
      });
    }
  }, [expansionValues]);
  var isExpanded = (0, _react.useCallback)(function (_ref2) {
    var id = _ref2.id,
      upLevelId = _ref2.upLevelId;
    if (!upLevelId) {
      return (0, _lodash.get)(expansionValues, id + ".expanded");
    }
    return !!(0, _lodash.get)(expansionValues, upLevelId + ".items." + id + ".expanded");
  }, [expansionValues]);
  return (0, _react.useMemo)(function () {
    return {
      toggle: toggle,
      isExpanded: isExpanded
    };
  }, [toggle, isExpanded]);
};
exports.useExpansionState = useExpansionState;