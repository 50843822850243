import { connect } from 'react-redux';
import _ from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { UserClaimsProvider } from '@cluedin/bootstrap';

import EntityPageContainerContainer from '../../../entity/components/containers/structure/EntityPageContainerContainer';
import AddPreventExit from '../../../core/components/Hocs/AddPreventExit';
import { onlyAuth } from '../../../auth/components/Hocs/onlyAuth';
import { getCurrentToken } from '../../../../config';
import NotFoundComponent from '../../../error/components/composites/NotFound';

const token = getCurrentToken();

const EnhancedAddPreventExit = onlyAuth(
  AddPreventExit(EntityPageContainerContainer),
  token,
);

const Div = ({ children }) => <div>{children}</div>;

const createEntityRoutesFromConfiguration = (entityConfigurations = []) => {
  const result = entityConfigurations
    .filter((entityConfig) => entityConfig?.path)
    .map((entityConfig) => {
      return {
        entityType: entityConfig.entityType,
        path: `/${entityConfig.path}`,
        component: EnhancedAddPreventExit,
      };
    });

  result.push({
    entityType: 'entity',
    type: 'entity',
    path: '/entity/:id',
    component: EnhancedAddPreventExit,
  });

  return result;
};

const EntityRoutesContainer = ({ entityRoutes }) => {
  return (
    <Switch>
      {(_.uniqBy(entityRoutes, (e) => e.path) || []).map((route) => {
        const Comp = route && route.component ? route.component : Div;

        return (
          <Route
            key={route.path}
            path={route.path}
            render={(props) => (
              <UserClaimsProvider>
                <Comp
                  entityType={route.entityType}
                  widgetConfiguration={route.widgetConfiguration}
                  layoutConfiguration={route.layoutConfiguration}
                  {...props}
                />
              </UserClaimsProvider>
            )}
          />
        );
      })}

      <Route component={NotFoundComponent} />
    </Switch>
  );
};

const mapStateToProps = ({ wms: { entityConfigurations } }) => ({
  entityRoutes: createEntityRoutesFromConfiguration(entityConfigurations || []),
});

export default connect(mapStateToProps)(EntityRoutesContainer);
