import { gql, useLazyQuery } from '@apollo/client';
import get from 'lodash/get';

import { legacyClient } from '../../../data/graphql';

const createLimit = 10000;
const fetchPolicy = 'network-only';

const GET_SEARCH = gql`
  query searchQueryFromHooks(
    $query: String
    $filter: String
    $filters: [FilterQuery]
    $terms: [TermQuery]
    $contextIds: [Guid]
    $sort: SortMethod
    $sortDirection: SortDirection
    $includeExternalData: Boolean
    $includeUnstructuredData: Boolean
    $cursor: PagingCursor
    $searchUntokenized: Boolean
    $pageSize: Int
  ) {
    search(
      query: $query
      filter: $filter
      filters: $filters
      terms: $terms
      pageSize: $pageSize
      contextIds: $contextIds
      sort: $sort
      sortDirection: $sortDirection
      includeExternalData: $includeExternalData
      includeUnstructuredData: $includeUnstructuredData
      cursor: $cursor
      searchUntokenized: $searchUntokenized
    ) {
      cursor
      entries {
        id
      }
    }
  }
`;

export const useSearchQueryFromHooks = () => {
  const [getEntities, { data, loading }] = useLazyQuery(GET_SEARCH, {
    client: legacyClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  });

  const queryFromHooks = get(data, 'search.entries');

  return [
    (variables) => {
      getEntities({
        variables,
      });
    },
    {
      data: queryFromHooks,
      loading,
    },
  ];
};
