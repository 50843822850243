import React from 'react';
import PropTypes from 'prop-types';
import { SplitIcon } from '@cluedin/svgs';
import { FormattedMessage } from '@cluedin/locale';

import {
  EntityTopologySplitModalContentWrapper,
  EntityTopologySplitModalIllustration,
  EntityTopologySplitModalHeaderWrapper,
  EntityTopologySplitModalHeader,
  EntityTopologySplitModalText,
  EntityTopologyResultText,
} from './EntityTopologySplitModalContent.styles';

const EntityTopologySplitModalContent = ({
  newEntityCount,
  kind,
  selectedDataParts,
}) => {
  let splitType = '';
  if (kind === 'RemoveEntityCode') {
    if (selectedDataParts?.length === 1) {
      splitType = (
        <FormattedMessage id="module-entityTopology-splitTypeRemoveEntityCode" />
      );
    }
    if (selectedDataParts?.length > 1) {
      splitType = (
        <FormattedMessage id="module-entityTopology-splitTypeRemoveEntityCodes" />
      );
    }
  }
  if (kind === 'UndoDeduplicationProjectMerge') {
    splitType = (
      <FormattedMessage id="module-entityTopology-splitTypeDeduplication" />
    );
  }
  if (kind === 'UndoManualMerge') {
    splitType = <FormattedMessage id="module-entityTopology-splitTypeManual" />;
  }

  let infoMessage = '';
  if (kind === 'RemoveEntityCode') {
    infoMessage = (
      <FormattedMessage id="module-entityTopology-splitDialogSplitInformation" />
    );
  }
  if (kind === 'UndoDeduplicationProjectMerge') {
    infoMessage = (
      <FormattedMessage id="module-entityTopology-splitDialogDeduplicationInformation" />
    );
  }
  if (kind === 'UndoManualMerge') {
    infoMessage = (
      <FormattedMessage id="module-entityTopology-splitDialogManualInformation" />
    );
  }

  let entityText =
    newEntityCount === 1 ? (
      <FormattedMessage id="module-entityTopology-splitDialogResultEntity" />
    ) : (
      <FormattedMessage id="module-entityTopology-splitDialogResultEntities" />
    );

  let resultSplitType = '';
  if (kind === 'RemoveEntityCode') {
    if (selectedDataParts?.length === 1) {
      resultSplitType = (
        <FormattedMessage id="module-entityTopology-splitDialogResultRemovingEntityCode" />
      );
    }
    if (selectedDataParts?.length > 1) {
      resultSplitType = (
        <FormattedMessage id="module-entityTopology-splitDialogResultRemovingEntityCodes" />
      );
    }
  }
  if (kind === 'UndoDeduplicationProjectMerge') {
    resultSplitType = (
      <FormattedMessage id="module-entityTopology-splitDialogResultDeduplicationProjectMerge" />
    );
  }
  if (kind === 'UndoManualMerge') {
    resultSplitType = (
      <FormattedMessage id="module-entityTopology-splitDialogResultManualMerge" />
    );
  }

  return (
    <EntityTopologySplitModalContentWrapper>
      <EntityTopologySplitModalIllustration>
        <SplitIcon size={40} />
      </EntityTopologySplitModalIllustration>

      <EntityTopologySplitModalHeaderWrapper>
        <EntityTopologySplitModalHeader>
          <FormattedMessage
            id="module-entityTopology-splitDialogHeader"
            values={{ splitType }}
          />
        </EntityTopologySplitModalHeader>
        <EntityTopologySplitModalText>
          {infoMessage}
        </EntityTopologySplitModalText>

        <EntityTopologyResultText>
          <FormattedMessage
            id="module-entityTopology-splitDialogResult"
            values={{ resultSplitType, newEntityCount, entityText }}
          />
        </EntityTopologyResultText>
      </EntityTopologySplitModalHeaderWrapper>
    </EntityTopologySplitModalContentWrapper>
  );
};

export default EntityTopologySplitModalContent;

EntityTopologySplitModalContent.propTypes = {
  newEntityCount: PropTypes.number,
  kind: PropTypes.string,
  selectedDataParts: PropTypes.array,
};

EntityTopologySplitModalContent.defaultProps = {
  newEntityCount: null,
  newEntityCount: null,
  selectedDataParts: [],
};
