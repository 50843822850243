import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

export const noStyle = { fontStyle: 'italic', color: '#ccc' };

export const noEntity = (
  <span style={noStyle}>
    <FormattedMessage id="module-entityLists-noEntity" />
  </span>
);

export const noValue = (
  <span style={noStyle}>
    <FormattedMessage id="module-entityLists-noValue" />
  </span>
);

export default {
  noStyle,
  noEntity,
  noValue,
};
