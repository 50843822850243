"use strict";

exports.__esModule = true;
exports.ListPropertyTooltipWrapper = exports.ListPropertyTooltipTitle = exports.ListPropertyTooltipFieldValue = exports.ListPropertyTooltipFieldTitle = exports.ListPropertyTooltipFieldLinkWrapper = exports.ListPropertyTooltipFieldLink = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var ListPropertyTooltipWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background: #fff;\n  border: 1px solid #ccc;\n  border-radius: 2px;\n  min-width: 250px;\n"])));
exports.ListPropertyTooltipWrapper = ListPropertyTooltipWrapper;
var ListPropertyTooltipTitle = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  background: #f2f2f2;\n  border-bottom: 1px solid #ccc;\n  color: rgb(136, 136, 136);\n  font-size: 12px;\n  font-weight: bold;\n  padding: 6px;\n"])));
exports.ListPropertyTooltipTitle = ListPropertyTooltipTitle;
var ListPropertyTooltipFieldTitle = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  color: rgb(136, 136, 136);\n  font-style: italic;\n  font-size: 12px;\n  word-break: break-word;\n"])));
exports.ListPropertyTooltipFieldTitle = ListPropertyTooltipFieldTitle;
var ListPropertyTooltipFieldValue = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  color: #000000;\n  font-size: 12px;\n  font-weight: 600;\n  margin-top: 2px;\n  word-break: break-word;\n"])));
exports.ListPropertyTooltipFieldValue = ListPropertyTooltipFieldValue;
var ListPropertyTooltipFieldLinkWrapper = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n"])));
exports.ListPropertyTooltipFieldLinkWrapper = ListPropertyTooltipFieldLinkWrapper;
var ListPropertyTooltipFieldLink = _styledComponents.default.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  font-size: 12px;\n  margin: 2px 4px 0 0;\n  word-break: break-word;\n"])));
exports.ListPropertyTooltipFieldLink = ListPropertyTooltipFieldLink;