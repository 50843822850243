import React from 'react';
import get from 'lodash/get';
import Img from 'uxi/Img';
import { Integration } from 'uxi/Icons';
import FirstLetterComponent from '../../../core/components/composites/FirstLetterComponent';

const findSize = (size = '') => {
  if (!size) {
    return '32';
  }

  const lwSize = size.toLocaleLowerCase();

  if (lwSize === 's') {
    return '24';
  }

  if (lwSize === 'l') {
    return '48';
  }

  if (lwSize === 'xl') {
    return '64';
  }

  return '32';
};

const IntegrationIcon = ({ size, integration, style = {}, roundImage }) => {
  const width = findSize(size);
  const icon = get(integration, 'Icon');
  const name = get(integration, 'Name');

  if (!icon && !name) {
    return <Integration size={width} />;
  }

  let imageStyle = {};

  if (roundImage) {
    imageStyle = { borderRadius: '50%' };
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      {icon ? (
        <Img contain async width={width} src={icon} style={imageStyle} />
      ) : (
        <FirstLetterComponent name={name} size={size} />
      )}
    </div>
  );
};

export default IntegrationIcon;
