import { FC } from 'react';
import { List } from '@cluedin/list-pattern';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { FormattedMessage } from '@cluedin/locale';
import { useIntl } from 'react-intl';
import { PrimaryButton } from '@cluedin/form';

import { columns } from './columns';
import { EntityGroupedRelationListText } from './EntityGroupedRelationListText';
import { GroupedRelationEntityDataEdge } from '../../types';

type EntityGroupedRelationListProps = {
  totalDataCount: number;
  groupedRelationData: GroupedRelationEntityDataEdge[];
  groupedRelationDataLoading: boolean;
  groupedRelationDataError: ApolloError | undefined;
  groupedRelationDataFetchMore: (
    entityId: string,
    edgeType: string,
    edgeDirection: string,
  ) => Promise<ApolloQueryResult<unknown>>;
  direction: 'incoming' | 'outgoing';
};

export const EntityGroupedRelationList: FC<EntityGroupedRelationListProps> = ({
  totalDataCount,
  groupedRelationData,
  groupedRelationDataLoading,
  groupedRelationDataError,
  groupedRelationDataFetchMore,
  direction,
}) => {
  const { formatMessage } = useIntl();
  const loadMoreIsDisabled = groupedRelationData?.length === totalDataCount;
  const isOutgoing = direction === 'outgoing';
  const isIncoming = direction === 'incoming';

  let notFoundPropsMessage;
  let notFoundPropsExplanation;
  if (isIncoming) {
    notFoundPropsMessage = formatMessage({
      id: 'module-entityRelationsGraph-groupedEntitiesListIncomingNotFoundMessage',
    });
    notFoundPropsExplanation = formatMessage({
      id: 'module-entityRelationsGraph-groupedEntitiesListIncomingNotFoundExplanation',
    });
  }
  if (isOutgoing) {
    notFoundPropsMessage = formatMessage({
      id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingNotFoundMessage',
    });
    notFoundPropsExplanation = formatMessage({
      id: 'module-entityRelationsGraph-groupedEntitiesListOutgoingNotFoundExplanation',
    });
  }

  if (!groupedRelationData) {
    return (
      <div style={{ maxWidth: '100%' }}>
        <div style={{ marginBottom: '20px' }}>
          {isIncoming && (
            <>
              <EntityGroupedRelationListText>
                <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListNoIncomingEdges" />
              </EntityGroupedRelationListText>
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListIncomingEdgesText" />
            </>
          )}
          {isOutgoing && (
            <>
              <EntityGroupedRelationListText>
                <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListNoOutgoingEdges" />
              </EntityGroupedRelationListText>
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListOutgoingEdgesText" />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '100%', marginBottom: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        {isIncoming && (
          <>
            <EntityGroupedRelationListText>
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListIncomingEdges" />
            </EntityGroupedRelationListText>
            <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListIncomingEdgesText" />
          </>
        )}
        {isOutgoing && (
          <>
            <EntityGroupedRelationListText>
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListOutgoingEdges" />
            </EntityGroupedRelationListText>
            <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListOutgoingEdgesText" />
          </>
        )}
      </div>

      <List
        offsetTop={1}
        hideExpanding
        noSearch
        selectable={false}
        columns={columns}
        data={groupedRelationData}
        loading={groupedRelationDataLoading}
        error={groupedRelationDataError}
        showPagination={true}
        notFoundProps={{
          message: notFoundPropsMessage,
          explanation: notFoundPropsExplanation,
          noCreate: true,
        }}
        listFooterAdditionalRightContent={
          <PrimaryButton
            loading={groupedRelationDataLoading}
            data-test="entityRelations-groupedEntities-loadMoreButton"
            disabled={loadMoreIsDisabled}
            color="accent"
            onClick={groupedRelationDataFetchMore}
            rounded
            text={
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListLoadMoreButton" />
            }
          />
        }
      />
    </div>
  );
};
