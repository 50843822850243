import React from 'react';

import VocabKeyItemLinkForList from '../../composites/VocabKeyItemLinkForList';
import VocabularySource from '../../composites/VocabularySource';
import VocabularyKeyClassification from '../../composites/VocabularyKeyClassification';
import VocabularyKeyGroupValue from '../../composites/VocabularyKeyGroupValue';

const VocabularySourceFromKey = ({ providerId, isCluedInCore, vocabulary }) => {
  return (
    <VocabularySource
      isCluedInCore={isCluedInCore}
      providerId={providerId}
      {...vocabulary}
      fallBackName={vocabulary?.vocabularyName}
    />
  );
};

const mobileViewColumns = [
  {
    property: 'name',
    displayName: 'Name',
    Component: VocabKeyItemLinkForList,
  },
  {
    property: 'dataType',
    displayName: 'Type',
  },
  {
    property: 'source',
    displayName: 'Source',
    Component: VocabularySourceFromKey,
  },
];

const extraViewColumns = [
  {
    property: 'dataClassificationCode',
    displayName: 'Classification',
    Component: VocabularyKeyClassification,
  },
  {
    property: 'groupName',
    displayName: 'Group',
    Component: VocabularyKeyGroupValue,
  },
];

const allFields = [...mobileViewColumns, ...extraViewColumns];
export const getColumns = (forceMobileView) => {
  if (forceMobileView) {
    return mobileViewColumns;
  }

  return allFields;
};
