import { connect } from 'react-redux';
import ConfigurationLoadingStatus from '../composites/ConfigurationLoadingStatus';

const mapToStateProps = (
  { configuration: { configurationMessage } },
  { configurationId },
) => {
  const messages =
    configurationMessage && configurationMessage[configurationId]
      ? configurationMessage[configurationId]
      : [];

  return {
    messages,
  };
};

export default connect(mapToStateProps)(ConfigurationLoadingStatus);
