import React from 'react';
import Alert from 'uxi/Alert';
import GraphWidgetWrapper from '../../containers/reports/GraphWidgetWrapper';

const LoadingError = () => (
  <GraphWidgetWrapper ratio={false}>
    <Alert style={{ height: '100%' }}>
      An Error occured while fetching the data for this chart.
    </Alert>
  </GraphWidgetWrapper>
);

export default LoadingError;
