const isDataSet = (str) => {
  return str.startsWith('ds') && str.length === 34;
};

const DefaultSource = {
  Name: 'CluedIn',
  Icon: '/img/cluedin.png',
  Domain: 'https://www.cluedin.com',
};

const formatName = (name) => name.toLowerCase().replace(' ', '');

const findSourceInConfig = (
  property = '',
  integrations = [],
  withoutCluedIn,
) => {
  const result = [];

  if (!property) {
    return null;
  }

  integrations.forEach((integration) => {
    let localFound;
    if (formatName(property) === formatName(integration.name)) {
      localFound = {
        strict: true,
        provider: integration,
      };
    }

    if (
      !localFound &&
      formatName(integration.name).indexOf(formatName(property)) > -1
    ) {
      localFound = {
        strict: false,
        provider: integration,
      };
    }
    if (
      !localFound &&
      formatName(property).indexOf(formatName(integration.name)) > -1
    ) {
      localFound = {
        strict: false,
        provider: integration,
      };
    }

    if (!localFound && integration.aliases) {
      integration.aliases.forEach((singleAlias) => {
        if (!localFound && formatName(property) === formatName(singleAlias)) {
          localFound = {
            strict: true,
            provider: integration,
          };
        }
        if (
          !localFound &&
          formatName(property).indexOf(formatName(singleAlias)) > -1
        ) {
          localFound = {
            strict: false,
            provider: integration,
          };
        }
      });
    }
    if (localFound) {
      result.push(localFound);
    }
  });

  if (result.length === 0 && !withoutCluedIn) {
    return DefaultSource;
  }
  if (result.length === 1) {
    return result[0].provider;
  }

  if (result.length > 1) {
    const hasStrict = result.find((r) => r.strict);
    if (hasStrict) {
      return hasStrict.provider;
    }

    return result[0].provider;
  }

  return withoutCluedIn ? null : DefaultSource;
};

export const findSource = (
  property = 'CluedIn',
  integrations = [],
  withoutCluedIn,
) => {
  const splittedProperties = property.split('.');
  const supposedToBeIntegrationName = splittedProperties[0];

  if (isDataSet(supposedToBeIntegrationName)) {
    return {
      Name: supposedToBeIntegrationName,
    };
  }

  const propertyToLook =
    splittedProperties.length > 1
      ? formatName(supposedToBeIntegrationName)
      : formatName(property);

  return findSourceInConfig(propertyToLook, integrations, withoutCluedIn);
};

export default {
  findSource,
};
