/* eslint-disable no-underscore-dangle */
import locationBuilder from './location';
import excludedProvider from './excludedProvider';
import imageBuilder from './image';
import providerUrl from './provider';
import { getSubdomain } from '../modules/core/helpers/url';
import { getCurrentToken as getCurrentTokenFromStorage } from '../modules/core/helpers/storage';

const __cluedIn = window.__cluedIn;

const envVars = window.cluedInEnv || process.env;

const extensions = (window.cluedInExtensionUI || {}).pillarConfiguration || {};

const envVarGQL = envVars.REACT_APP_UI_GQL_URL;
const envVarInternalGql = envVars.REACT_APP_UI_INTERNAL_GQL_URL;

const languageDefaultFromEnv = envVars.REACT_APP_UI_LANGUAGE_DEFAULT;
let languages = ['en.json'];
try {
  languages = (envVars.REACT_APP_UI_LANGUAGES || '').split(';');
} catch (e) {
  console.error('Invalid format for REACT_APP_UI_LANGUAGE_DEFAULT specified');
}
const hasDefaultLanguageInstalled =
  languages.indexOf(languageDefaultFromEnv) > -1;
if (!hasDefaultLanguageInstalled) {
  console.error('No default language found');
}
const sanitizedLanguageDefault = (languageDefaultFromEnv || '').split('.')[0];
const languageDefault = hasDefaultLanguageInstalled
  ? sanitizedLanguageDefault
  : 'en';

const runtimeConfiguration = {
  env: envVars.REACT_APP_UI_ENV,
  gqlDomain: envVarGQL.endsWith('/') ? envVarGQL : `${envVarGQL}/` || '/',
  gqlInternalDomain: envVarInternalGql
    ? envVarInternalGql.endsWith('/')
      ? envVarInternalGql
      : `${envVarInternalGql}/` || '/'
    : '',
  cookieDomainName: envVars.REACT_APP_UI_COOKIE_DOMAIN_NAME,
  protocol: envVars.REACT_APP_UI_PROTOCOL,
  app: envVars.REACT_APP_UI_APP,
  bucket: envVars.REACT_APP_UI_BUCKET,
  cdnUrl: envVars.REACT_APP_UI_CDN_URL,
  appSubdomain: envVars.REACT_APP_UI_APP_SUBDOMAIN,
  cluedInCleanUrl: envVars.REACT_APP_UI_CLUEDIN_CLEAN_URL,
  features: envVars.REACT_APP_UI_CLUEDIN_FEATURES,
  cluedinAppDomain: envVars.REACT_APP_UI_CLUEDIN_APP_DOMAIN,
  mainDomain: envVars.REACT_APP_UI_MAIN_DOMAIN,
  sizeLimit: envVars.REACT_APP_UI_UPLOAD_LIMIT || '10m',
  filesLimit: envVars.REACT_APP_UI_UPLOAD_FILES_LIMIT || 5,
  publicApiUrl: envVars.REACT_APP_UI_CLUEDIN_PUBLICAPI_URL.replace(/\/$/, ''),
};

if (
  envVars.env === 'production' &&
  (!runtimeConfiguration.app ||
    !runtimeConfiguration.bucket ||
    !runtimeConfiguration.cookieDomainName ||
    !runtimeConfiguration.env ||
    !runtimeConfiguration.mainDomain ||
    !runtimeConfiguration.protocol ||
    !runtimeConfiguration.appSubdomain)
) {
  console.error(runtimeConfiguration);
  throw new Error('invalid runtimeConfiguration');
}

const subDomain = getSubdomain();
export const clientId =
  subDomain === runtimeConfiguration.appSubdomain ? null : subDomain;

export const env = runtimeConfiguration.env;

export const sentryConfigUrl = runtimeConfiguration.sentryConfigUrl;

const expandedFeatures = Array.isArray(runtimeConfiguration.feature)
  ? runtimeConfiguration.features
  : runtimeConfiguration.features.split(';');

const cluedinConfiguration = {
  ...runtimeConfiguration,
  gql: `${runtimeConfiguration.gqlDomain}/graphql`,
  features: expandedFeatures,
  website: 'https://www.cluedin.com',
  graphQL: `${runtimeConfiguration.gqlDomain}api/api/graphql`,
  api: `${runtimeConfiguration.gqlDomain}api/`,
  auth: `${runtimeConfiguration.gqlDomain}auth/`,
  mainApp: `${runtimeConfiguration.app}/`,
  app: `${runtimeConfiguration.gqlDomain}app/`,
  publicApi: `${runtimeConfiguration.gqlDomain}public/`,
  cdnUrl: `${runtimeConfiguration.bucket}`,
  mainCookieDomain: `${runtimeConfiguration.cookieDomainName}`,
  appSubdomain: `${runtimeConfiguration.appSubdomain}`,
  cluedinAppDomain: runtimeConfiguration.cluedinAppDomain,
  marketPlaceLink:
    runtimeConfiguration.marketPlaceLink ||
    'https://marketplace.cluedin-test.online',
  mainGraphQLUrl: `${runtimeConfiguration.gqlDomain}graphql`,
  uploadUrl: `${runtimeConfiguration.gqlDomain}upload`,
  uploadInternalUrl: runtimeConfiguration.gqlInternalDomain
    ? `${runtimeConfiguration.gqlInternalDomain}upload`
    : '',
  mainGraphQLSocket:
    runtimeConfiguration.gqlDomain !== '/'
      ? `${runtimeConfiguration.gqlDomain.replace('http', 'ws')}graphql`
      : `ws://${subDomain}/graphql`,
};

export const url = cluedinConfiguration;

export const DOM = {
  typeAttribute: 'cluedInType',
  idAttribute: 'id',
};

export const image = imageBuilder;

export const marketPlaceLink =
  runtimeConfiguration.marketPlaceLink ||
  'https://marketplace.cluedin-test.online/';

export const location = locationBuilder(
  runtimeConfiguration.protocol,
  `.${url.mainDomain}/`,
  clientId,
  env,
);

export const excludedProviders = excludedProvider;

export const providers = providerUrl(url.bucket);

export const setCurrentToken = (token) => {
  __cluedIn.token = token;
};

export const getCurrentToken = getCurrentTokenFromStorage;

export const buildClientIdUrl = (orgName) =>
  `${runtimeConfiguration.protocol}${orgName}.${runtimeConfiguration.mainDomain}/`;

export const authUrl = `${runtimeConfiguration.protocol}auth.${runtimeConfiguration.mainDomain}/`;

export const currentClientId = `${runtimeConfiguration.protocol}${clientId}.${runtimeConfiguration.mainDomain}/`;

export const features = cluedinConfiguration.features || [];

export const hasFeature = (feature) => {
  return features.indexOf(feature) > -1;
};

export const getExtensionConfiguration = (pathName) => {
  return _.get(extensions, pathName);
};

export const getPillarConfiguration = (pillarName, moduleName) => {
  return getExtensionConfiguration(`${pillarName}.${moduleName}`);
};

export const idleTimeout = runtimeConfiguration.idleTimeout || -1;

export const buildUrl = (base, relURL) => {
  return relURL && relURL[0] === '/'
    ? `${base}${relURL.substring(1)}`
    : `${base}${relURL || ''}`;
};

export const urlBuilder = {
  api(relURL) {
    return buildUrl(url.api, relURL);
  },
  publicApi(relURL) {
    return buildUrl(url.publicApi, relURL);
  },
  auth(relURL) {
    return buildUrl(url.auth, relURL);
  },
  app(relURL) {
    return buildUrl(url.app, relURL);
  },
  mainApp(relURL) {
    return buildUrl(url.mainApp, relURL);
  },
  currentClientId(relUrl) {
    return buildUrl(buildClientIdUrl(clientId), relUrl);
  },
  current(relURL) {
    return buildUrl(currentClientId, relURL);
  },
  upload(relURL) {
    return buildUrl(url.uploadUrl, relURL);
  },
  uploadInternal(relURL) {
    if (url.uploadUrl) {
      return buildUrl(url.uploadInternalUrl, relURL);
    } else {
      throw new Error('Internal upload url is not configured.');
    }
  },
};

export default {
  features,
  env,
  DOM,
  image,
  getCurrentToken,
  setCurrentToken,
  clientId,
  location,
  url,
  urlBuilder,
  excludedProviders,
  providers,
  idleTimeout,
  languageDefault,
  languages,
};
