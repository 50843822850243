import { FirstLetterComponent } from '@cluedin/components';
import { withDataSet } from '../../hocs/withDataSet';
import DataSetIcon from './DataSetIcon';

const EnhancedDataSetIcon = withDataSet(DataSetIcon);

const extractDataSetIdWithoutHyphen = (dataSetIdWithoutHyphen) => {
  return dataSetIdWithoutHyphen.replace(
    /(.{8})(.{4})(.{4})(.{4})(.{12})/,
    '$1-$2-$3-$4-$5',
  );
};

const extractDataSetIdFromPivot = (providerName, pivot) => {
  const splitedName = (providerName || '').split(pivot);

  if (splitedName && splitedName.length > 1) {
    const dataSetIdWithoutHyphen = splitedName[1];
    if (dataSetIdWithoutHyphen) {
      return extractDataSetIdWithoutHyphen(dataSetIdWithoutHyphen);
    }
  }

  return '';
};

const extractDataSetId = (providerName = '') => {
  const isCluedInImporterDs = providerName.indexOf('cluedinimporterds') > -1;
  const isSimpleDSImporter = providerName.indexOf('ds') > -1;

  let dataSetId = '';

  if (isCluedInImporterDs) {
    return extractDataSetIdFromPivot(providerName, 'cluedinimporterds');
  }

  if (isSimpleDSImporter) {
    return extractDataSetIdFromPivot(providerName, 'ds');
  }

  return dataSetId;
};

const DataSetIconContainer = ({ providerName, ...rest }) => {
  if (
    !providerName ||
    providerName === '' ||
    typeof providerName !== 'string'
  ) {
    // well nothing we can do here
    // empty span would not hurt I guess
    return <span />;
  }

  const dataSetId = extractDataSetId(providerName);

  if (!dataSetId) {
    return <FirstLetterComponent {...rest} name={providerName} />;
  }

  return (
    <EnhancedDataSetIcon providerName={providerName} id={dataSetId} {...rest} />
  );
};

export default DataSetIconContainer;
