import { useState, useEffect } from 'react';
import store from 'store';

const getColumns = (viewName, defaultColumns) => {
  let columns = defaultColumns;
  const configurationForList = store.get(viewName);

  if (configurationForList && configurationForList.length > 0) {
    columns = configurationForList;
  }

  return columns;
};

export const useCustomizedColumns = (
  viewName,
  defaultColumns = [],
  customAddColumns,
) => {
  const [selectedColumns, setSelectedColumns] = useState(
    getColumns(viewName, defaultColumns),
  );

  useEffect(() => {
    setSelectedColumns(getColumns(viewName, defaultColumns));
  }, [viewName]);

  const saveCustomizedColumns = (
    columns,
    { skipActivating, overrideViewName } = {
      skipActivating: false,
      overrideViewName: null,
    },
  ) => {
    if (!skipActivating) {
      setSelectedColumns(columns);
    }

    store.set(overrideViewName || viewName, columns);
  };

  const getColumnsForViewName = (providedViewName, providedDefaultColumns) => {
    return getColumns(
      providedViewName || viewName,
      providedDefaultColumns || defaultColumns,
    );
  };

  const onAddColumns = (columns = []) => {
    if (customAddColumns) {
      return customAddColumns(selectedColumns, columns, (data) => {
        setSelectedColumns(data);
        store.set(viewName, data);
      });
    }

    const filteredDuplicates = columns.filter((c) => {
      const found = selectedColumns.find(
        (selected) => selected.property === c.property,
      );
      return !found;
    });

    const allColumns = [...selectedColumns, ...filteredDuplicates];

    setSelectedColumns(allColumns);
    store.set(viewName, allColumns);
  };

  return [
    selectedColumns,
    saveCustomizedColumns,
    onAddColumns,
    (columns = []) => {
      const filtered = selectedColumns.filter((existingColumn) => {
        const found = columns.find(
          (c) => c.property === existingColumn.property,
        );

        return !found;
      });

      setSelectedColumns(filtered);
      store.set(viewName, filtered);
    },
    getColumnsForViewName,
  ];
};

export default useCustomizedColumns;
