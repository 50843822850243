"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(RadioButton) {
  return (0, _styledComponents.default)(RadioButton)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  position: relative;\n  display: flex;\n  color: ", ";\n  &.checked .layer {\n    transform: scale(1);\n    transition: ", ";\n    circle {\n      stroke: ", ";\n      stroke-width: 3px;\n    }\n  }\n  .layer {\n    position: absolute;\n    left: 0;\n    transform: scale(0);\n  }\n"])), function (_ref) {
    var theme = _ref.theme,
      checked = _ref.checked;
    return checked ? theme.colors.pureWhite : theme.colors.action.active;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest
    });
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.accent.main;
  });
};
exports.default = _default;