const arrayToTree = (arr, parentId = null) =>
  !arr || !arr.filter
    ? []
    : arr
        .filter((n) => n.Parent === parentId)
        .map((n) => ({
          ...n,
          isChecked: true,
          childNodes: arrayToTree(arr, n.Id),
        }));

export const getInitialValueFromConfiguration = (
  configuration = {},
  properties = [],
) => {
  const valueFromConfiguration =
    configuration.helperConfiguration || configuration.Configuration;
  const intialValues = {};

  properties.forEach((p) => {
    const propertyName = p.name;
    const values = valueFromConfiguration[propertyName];
    if (p.type === 'tree') {
      intialValues[propertyName] = arrayToTree(values)[0];
    } else {
      intialValues[propertyName] = values;
    }
  });

  return {
    Id: configuration.Id,
    AccountDisplay: configuration.AccountDisplay,
    AutoSync: configuration.AutoSync,
    SourceQuality: configuration.SourceQuality,
    Source: configuration.Source,
    helperConfiguration: {
      ...intialValues,
    },
  };
};

const treeToArray = (value) => {
  let result = [];
  const childNodes = value.childNodes ? value.childNodes : [];

  result.push({
    ...value,
    childNodes: null,
  });

  childNodes.forEach((node) => {
    const toArray = treeToArray(node);
    result = [...result, ...toArray];
  });

  return result;
};

export const getValueFromFormToConfiguration = (
  configuration = {},
  properties = [],
) => {
  const valueFromConfiguration =
    configuration.helperConfiguration || configuration.Configuration;
  const intialValues = {};

  properties.forEach((p) => {
    const propertyName = p.name;
    const values = valueFromConfiguration[propertyName];
    if (p.type === 'tree') {
      intialValues[propertyName] = treeToArray(values);
    } else {
      intialValues[propertyName] = values;
    }
  });

  return {
    Id: configuration.Id,
    AccountDisplay: configuration.AccountDisplay,
    AutoSync: configuration.AutoSync,
    SourceQuality: configuration.SourceQuality,
    Source: configuration.Source,
    helperConfiguration: {
      ...intialValues,
    },
  };
};

export default {
  getInitialValueFromConfiguration,
  getValueFromFormToConfiguration,
};
