import { handleActions } from 'redux-actions';
import { saveUserOrgEmailActions } from '../actions/saveOrgEmail';

const initialState = {
  isFetching: false,
  done: false,
  error: false,
};

export default handleActions(
  {
    [saveUserOrgEmailActions.request]: (state) => ({
      ...state,
      isFetching: true,
      error: false,
      done: false,
    }),
    [saveUserOrgEmailActions.receive]: (state) => ({
      ...state,
      isFetching: false,
      done: true,
      error: false,
    }),
    [saveUserOrgEmailActions.invalid]: (state) => ({
      ...state,
      error: true,
      done: false,
      isFetching: false,
    }),
  },
  initialState,
);
