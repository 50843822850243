import styled from 'styled-components';
import styles from './styles';

const { accent, transition } = styles;

/* eslint-disable indent */
const FilterGroupWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #fff;
  padding: 0 16px;
  border: 1px solid transparent;
  transition: ${transition.defaultAll};
  border-bottom-color: #e0e0e0;

  /* showing N ourt of TOTAL */
  & h5 > *:last-child {
    opacity: 0.5;
  }

  &:focus-within {
    border: 1px solid ${accent};
    transition: ${transition.defaultAll};
    /* showing N ourt of TOTAL */
    & h5 > *:last-child {
      opacity: 1;
    }
    ${({ currentFilterQuery }) =>
      currentFilterQuery &&
      `border: 1px solid ${({ theme: { palette } }) => palette.accent.main};`};
  }
  ${({ currentFilterQuery }) =>
    currentFilterQuery && 'border: 1px solid #cecece'};
`;

export default FilterGroupWrapper;
