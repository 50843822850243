"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(ButtonGroup) {
  return (0, _styledComponents.default)(ButtonGroup)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: inline-flex;\n  &.horizontal {\n    button.grouped {\n      border-right-width: 0;\n      &.contained {\n        border-top-right-radius: 0;\n        border-bottom-right-radius: 0;\n        border-top-left-radius: 0;\n        border-bottom-left-radius: 0;\n        border-right: 1px solid ", ";\n      }\n    }\n    button.grouped:first-child {\n      border-left-width: 1px;\n      &.contained {\n        border-left: 0;\n        border-top-left-radius: ", ";\n        border-bottom-left-radius: ", ";\n      }\n    }\n    button.grouped:last-child {\n      border-right-width: 1px;\n      &.contained {\n        border-left: 0;\n        border-right: 0;\n        border-top-right-radius: ", ";\n        border-bottom-right-radius: ", ";\n      }\n    }\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.primary.light;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.shape.radius;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.shape.radius;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.shape.radius;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.shape.radius;
  });
};
exports.default = _default;