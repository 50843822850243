import React, { createContext, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@cluedin/form';
import { Help } from 'uxi/Icons';
import { needHelp } from '../../actions';

export const HelpContext = createContext([]);

export const useHelpContext = (value) => {
  const [, setContext] = useContext(HelpContext);

  useEffect(() => {
    setContext(value);

    return () => {
      setContext('');
    };
  }, []);
};

const HelpContextualButton = ({ helpContext, selectTopic }) => {
  return (
    <>
      {helpContext && helpContext.type && (
        <Button
          startIcon={<Help size={16} />}
          type="info"
          variant="outlined"
          onClick={() => {
            selectTopic(helpContext);
          }}
        >
          Help
        </Button>
      )}
    </>
  );
};

const mapToDispatch = (dispatch) => {
  return {
    selectTopic: (v) => {
      dispatch(needHelp(v));
    },
  };
};

export default connect(() => {}, mapToDispatch)(HelpContextualButton);
