import styled, { css } from 'styled-components';

export const EntityTopologySplitNewDataPartWrapper = styled.div`
  background: #ffffff;
  max-width: 100%;
`;

export const EntityTopologySplitNewDataPartInnerWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: ${({ isNewEntityDataPart }) =>
    isNewEntityDataPart ? '27px' : '21px'};
  padding: 14px 16px 0 0;
`;

export const EntityTopologySplitNewDataPartHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 14px;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    min-width: 100%;
    position: absolute;
    top: 100%;
  }
`;

export const EntityTopologySplitNewDataPartHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const EntityTopologySplitNewDataPartBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid
    ${({ isNewEntityDataPart, themePrimary }) => {
      if (isNewEntityDataPart) {
        return;
      } else {
        return themePrimary;
      }
    }};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -5.5px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const EntityTopologySplitNewDataPartDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
`;

export const EntityTopologySplitNewDataPartDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const EntityTopologySplitNewDataPartDetailsInner = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
