import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import OauthForm from './OauthForm';
import CredentialForm from './CredentialForm';
import TokenForm from './TokenForm';

import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from '../../../../core/components/composites/Tabs';

const AuthMethodForm = ({
  authMethods,
  integrationName,
  onAuthenticate,
  onOauthAuthenticate,
  isSaving,
  guide,
}) => {
  const needTabs = Object.keys(authMethods).length > 1;
  const oauth = authMethods.oauth;
  const credentials = authMethods.credentials;
  const token = authMethods.token;

  let theGuide = null;
  if (guide && guide.Instructions) {
    theGuide = (
      <div>
        <h4>
          <FormattedMessage id="module-integration-notes" />
        </h4>
        <p>{guide.Instructions}</p>
      </div>
    );
  }

  return (
    <div>
      {needTabs && (
        <Tabs
          style={{
            marginTop: '15px',
            marginBottom: '15px',
            marginLeft: '-15px',
            marginRight: '-15px',
            borderTop: '1px solid rgb(212, 218, 209)',
          }}
          isMainStyle
        >
          <TabList>
            {token && (
              <Tab key="token">
                <span className="test_Integration_AddIntegration_TokenTab">
                  <FormattedMessage id="module-integration-token" />
                </span>
              </Tab>
            )}
            {credentials && (
              <Tab key="credential">
                <span className="test_Integration_AddIntegration_CredentialTab">
                  <FormattedMessage id="module-integration-creds" />
                </span>
              </Tab>
            )}
            {oauth && (
              <Tab key="oauth">
                <span className="test_Integration_AddIntegration_OauthTab">
                  <FormattedMessage id="module-integration-oauth" />
                </span>
              </Tab>
            )}
          </TabList>
          {token && (
            <TabPanel
              style={{
                padding: '15px',
              }}
              key="token"
            >
              <TokenForm
                isSaving={isSaving}
                integrationName={integrationName}
                config={token}
                onAuthenticate={(values) => {
                  onAuthenticate({
                    type: 'token',
                    values,
                  });
                }}
              />
            </TabPanel>
          )}
          {credentials && (
            <TabPanel
              key="credential"
              style={{
                padding: '15px',
              }}
            >
              <CredentialForm
                isSaving={isSaving}
                integrationName={integrationName}
                config={credentials}
                onAuthenticate={(values) => {
                  onAuthenticate({
                    type: 'credential',
                    values,
                  });
                }}
              />
            </TabPanel>
          )}
          {oauth && (
            <TabPanel
              key="oauth"
              style={{
                padding: '15px',
              }}
            >
              <OauthForm
                isSaving={isSaving}
                integrationName={integrationName}
                config={oauth}
                onAuthenticate={onOauthAuthenticate}
              />
            </TabPanel>
          )}
        </Tabs>
      )}
      {!needTabs && oauth && (
        <OauthForm
          isSaving={isSaving}
          integrationName={integrationName}
          config={oauth}
          onAuthenticate={onOauthAuthenticate}
        />
      )}
      {!needTabs && credentials && (
        <CredentialForm
          isSaving={isSaving}
          integrationName={integrationName}
          config={credentials}
          onAuthenticate={(values) => {
            onAuthenticate({
              type: 'credential',
              values,
            });
          }}
        />
      )}
      {!needTabs && token && (
        <TokenForm
          isSaving={isSaving}
          integrationName={integrationName}
          config={token}
          onAuthenticate={(values) => {
            onAuthenticate({
              type: 'token',
              values,
            });
          }}
        />
      )}
      {theGuide}
    </div>
  );
};

export default AuthMethodForm;
