import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { AlertTriangle } from '@cluedin/svgs';

const NoValue = styled.div`
  font-style: italic;
  color: #ccc;
`;
const IconWrapper = styled.span`
  padding-right: 6px;
`;

const invalidLookupNameRegex =
  /^\[Invalid \((?<reason>Code|Missing|Duplicate)\)\] (?<code>.*)$/;
const invalidLookupValueRegex =
  /^\[Invalid \((?<reason>Value|Missing|Duplicate)\)\] (?<value>.*)$/;

const ValueCell = ({ value }) => {
  let lookupValue = value;
  let errorText;
  if (lookupValue && typeof lookupValue === 'string') {
    const nameMatch = lookupValue.match(invalidLookupNameRegex);
    if (nameMatch?.groups) {
      const code = nameMatch.groups.code;
      lookupValue = code;
      errorText = value;
      const valueMatch = code.match(invalidLookupValueRegex);
      if (valueMatch?.groups) {
        lookupValue = valueMatch.groups.value;
      }
    }
  }

  return (
    <span>
      {!lookupValue && (
        <NoValue>
          <FormattedMessage id="module-goldenRecordList-noValue" />
        </NoValue>
      )}
      {lookupValue && errorText && (
        <>
          <IconWrapper title={errorText}>
            <AlertTriangle
              style={{
                width: '16px',
                height: '16px',
              }}
            />
          </IconWrapper>
        </>
      )}
      {lookupValue && <span>{lookupValue}</span>}
    </span>
  );
};

const LookupValueCellComponent = (p) => (entity) => {
  const value = entity.properties[`property-${p}`] || entity[p];
  return <ValueCell value={value} />;
};

export default LookupValueCellComponent;
