import { compose } from 'recompose';
import { withFacets } from '../../../search/data/gql';
import { WithErrorAndLoadingInLine } from '../../../core/components/Hocs/WithErrorAndLoading';
import withEntityViewModel from '../../../organization/components/Hocs/withEntityViewModel';

const withEntityTypesData = compose(
  withFacets,
  WithErrorAndLoadingInLine,
  withEntityViewModel,
);

export default withEntityTypesData;
