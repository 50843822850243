import styled from 'styled-components';

export const EntityTopologyHeaderDetailWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

export const EntityTopologyHeaderDetailTitle = styled.div`
  color: ${({ themePrimary }) =>
    themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const EntityTopologyHeaderTimeStamps = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
`;

export const EntityTopologyHeaderTimeStamp = styled.div`
  color: #9b9b9c;
`;

export const EntityTopologyHeaderAuthor = styled.div`
  padding-top: 8px;
`;
