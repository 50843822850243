import styled, { css } from 'styled-components';

export const RecordPanelHeaderIconWrapper = styled.div`
  align-items: center;

  ${({
    isCleanProject,
    isLegacyCleanProject,
    isDeduplicationProject,
    isManualMerge,
    isManuallyAdded,
    isManuallyAddedFileEntry,
  }) => {
    switch (true) {
      case isManualMerge:
        return css`
          background: #fbf4de;
        `;
      case isCleanProject || isLegacyCleanProject:
        return css`
          background: #ccf0f7;
        `;
      case isDeduplicationProject:
        return css`
          background: #e2cbf9;
        `;
      case isManuallyAdded || isManuallyAddedFileEntry:
        return css`
          background: #ffe0f4;
        `;
      default:
        return css`
          background: ${({ themePrimary }) =>
            themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
        `;
    }
  }};

  border-radius: 80%;
  display: flex;
  height: 50px;
  min-height: 50px;
  justify-content: center;
  margin-right: 14px;
  width: 50px;
  min-width: 50px;

  & svg path {
    fill: ${({ themeWhite }) => themeWhite ?? '#fff'};
  }
`;

export const RecordPanelHeaderConnectorIcon = styled.div`
  align-items: center !important;
  display: flex !important;
  height: 100% !important;
  justify-content: center !important;
  margin: 0 !important;
  width: 100% !important;
`;

export const RecordPanelHeaderManualMergeIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg path {
    fill: #f2c230 !important;
  }
`;

export const RecordPanelHeaderCleanIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg path {
    fill: #00b4d8 !important;
  }
`;

export const RecordPanelHeaderDeduplicationIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg path {
    fill: #a65dee !important;
  }
`;

export const RecordPanelHeaderManuallyAddedIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg path {
    fill: #ff99da !important;
  }
`;
