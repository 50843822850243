import { Tag } from '@cluedin/components';
import ColumnCardItemWrapper from './ColumnCardItemWrapper';
import VocabKeyItemLink from '../../../../../dataCatalog/components/composites/VocabKeyItemLink';

const ColumnCardItem = ({ column, style, onDeleteColumn }) => {
  return (
    <ColumnCardItemWrapper
      style={style}
      column={column}
      onDeleteColumn={onDeleteColumn}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1' }}>
          {column.vocabKey ? (
            <VocabKeyItemLink vocabularyKey={column.vocabKey} isExternalLink />
          ) : (
            column.property
          )}
        </div>
        {column.type && <Tag label={column.type} />}
      </div>
    </ColumnCardItemWrapper>
  );
};

export default ColumnCardItem;
