import React from 'react';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

export default {
  pillar: 'administration',
  name: 'entityTypes',
  hideFromMenu: true,
  displayName: <FormattedMessage id="entityTypes" />,
  path: '/entity-types',
  extendPillarDashboard: {
    actions: [],
  },
  routes: [
    {
      path: '/',
      exact: true,
      claims: {
        'admin.entitytypes': RACI_LEVELS.CONSULTANT,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"EntityTypesPageRedirect" */
            './components/pages/EntityTypesPageRedirect'
          ),
        ),
      ),
    },
    {
      path: '/edit',
      claims: {
        'admin.entitytypes': RACI_LEVELS.CONSULTANT,
      },
      title: 'Edit Type',
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"EditEntityTypesPageRedirect" */
            './components/pages/EditEntityTypesPageRedirect'
          ),
        ),
      ),
    },
  ],
};
