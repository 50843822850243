import { Icon } from '@fluentui/react';
import { useTheme } from '@cluedin/theme';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const PanelEdgeHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const PanelEdgeHeading = ({ isOutgoing, edges, edge }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const { formatMessage } = useIntl();

  const isLabelGrouped = (label) => {
    return label.toLowerCase() === 'grouped';
  };

  const fromLabel = edges?.edgeFrom?.label
    ? isLabelGrouped(edges?.edgeFrom?.label)
      ? edge?.referencedEntityName
      : edges?.edgeFrom?.label
    : formatMessage({
        id: 'module-entityRelationsGraph-edgePropertiesEntryNoName',
      });

  const toLabel = edges?.edgeTo?.label
    ? isLabelGrouped(edges?.edgeTo?.label)
      ? edge?.referencedEntityName
      : edges?.edgeTo?.label
    : formatMessage({
        id: 'module-entityRelationsGraph-edgePropertiesEntryNoName',
      });

  return (
    <PanelEdgeHeadingWrapper>
      {isOutgoing ? (
        <>
          <div style={{ fontWeight: 'normal', marginRight: '8px' }}>
            {formatMessage({
              id: 'module-entityRelationsGraph-edgePropertiesFor',
            })}
            :
          </div>
          {fromLabel}
          <Icon
            iconName="ArrowOutgoing"
            style={{
              color: themePrimary,
              margin: '0 8px',
              fontSize: 14,
              marginTop: '-2px',
            }}
          />
          {toLabel}
        </>
      ) : (
        <>
          <div style={{ fontWeight: 'normal', marginRight: '8px' }}>
            {formatMessage({
              id: 'module-entityRelationsGraph-edgePropertiesFor',
            })}
            :
          </div>
          {toLabel}
          <Icon
            iconName="ArrowIncoming"
            style={{
              color: themePrimary,
              margin: '0 8px',
              fontSize: 14,
              marginTop: '-2px',
            }}
          />
          {fromLabel}
        </>
      )}
    </PanelEdgeHeadingWrapper>
  );
};

export default PanelEdgeHeading;
