import { connect } from 'react-redux';
import {
  Route,
  Redirect,
  withRouter,
  Switch,
  matchPath,
} from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';
import { RACI_LEVELS } from '@cluedin/components';
import get from 'lodash/get';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import { useGetEntityTypeByType } from '../../entity/hooks/useGetEntityTypeByType';
import LightTabList from '../../core/components/composites/LightTab/LightTabList';
import EntityRelationsContainer from '../../entityRelationsGraph/pages/EntityRelationsPage';
import HierarchyListEntityTabPage from '../../hierarchy/components/pages/HierarchyListEntityTabPage';
import EntityHistoryContainer from '../../entityHistory/pages/EntityHistoryPage';
import EntityHistory from '../../entityHistoryV2/containers/EntityHistory';
import PageLoader from '../../core/components/composites/PageLoader';
import ProtectedRoute from '../../core/components/containers/ProtectedRoute';
import EntityMainBar from '../components/composites/EntityMainBar';
import OverviewContainer from '../containers/OverviewContainer';
import EntityPropertiesPageV2 from '../../entityProperties/components/pages/EntityPropertiesPage';
import EntityTopologyPage from '../../entityTopology/components/pages/EntityTopologyPage';
import EntityPendingChangesPage from '../../entityPendingChanges/components/pages/EntityPendingChangesPage';
import ExplainLogPage from '../../entity/components/pages/EntityExplainLogPage';
import {
  EntityContainer,
  EntityHeaderWrapper,
  EntityMainBarWrapper,
} from './EntityMainPage.styles';
import LightTabExtra from './LightTabExtra';
import { useOrganizationFeatureFlagOption } from '../../featureFlag/hooks/useOrganizationFeatureFlag';

const { governance, admin } = actionsConfiguration;

const EntityMainPageWithTopology = ({
  isLoading,
  entity,
  schema,
  entityProperties,
  isFetchingEntity,
  match,
  history,
  isFetching,
  entityId,
  type,
  rootUrl,
}) => {
  const hasGovernanceAccess = useUserHasAccess(
    governance.gdpr.subjectAccessRequest.view.claims,
  );

  const hasDataManagementAccess = useUserHasAccess(
    admin.datamanagement.datamanagement.view.claims,
  );

  const [entityTypeData, entityTypeLoading, entityTypeError] =
    useGetEntityTypeByType(type);
  const layoutId = get(entityTypeData, '[0]pageTemplateId');
  const layoutName = get(entityTypeData, '[0]pageTemplateName');
  const isHierarchiesEnabled =
    useOrganizationFeatureFlagOption('manualHierarchy');

  const isHistoryWithFiltersEnabled =
    useOrganizationFeatureFlagOption('historyWithFilters');

  let tabLinksWithTopology = [
    {
      path: '/overview',
      label: <FormattedMessage id="module-entityV2-overview-tab" />,
      selected:
        matchPath(location.pathname, { path: `${match.url}/overview` }) ||
        matchPath(location.pathname, { path: `${match.url}`, exact: true }),
    },
    {
      path: '/properties',
      label: <FormattedMessage id="module-entityV2-properties-tab" />,
      selected: matchPath(location.pathname, {
        path: `${match.url}/properties`,
      }),
    },
    {
      path: '/relations',
      label: <FormattedMessage id="module-entityV2-relations-tab" />,
      selected: matchPath(location.pathname, {
        path: `${match.url}/relations`,
      }),
    },
    hasGovernanceAccess && {
      path: '/pending-changes',
      label: <FormattedMessage id="module-entityV2-pending-changes-tab" />,
      selected: matchPath(location.pathname, {
        path: `${match.url}/pending-changes`,
      }),
    },
    {
      path: '/history',
      label: <FormattedMessage id="module-entityV2-history-tab" />,
      selected: matchPath(location.pathname, { path: `${match.url}/history` }),
    },
    hasDataManagementAccess && {
      path: '/explain-log',
      label: <FormattedMessage id="module-entityV2-explain-log-tab" />,
      selected: matchPath(location.pathname, {
        path: `${match.url}/explain-log`,
      }),
    },
    {
      path: '/topology',
      label: <FormattedMessage id="module-entityV2-topology-tab" />,
      selected: matchPath(location.pathname, { path: `${match.url}/topology` }),
    },
  ].filter(Boolean);

  if (isHierarchiesEnabled) {
    tabLinksWithTopology.push({
      path: '/hierarchies',
      label: <FormattedMessage id="module-entityV2-hierarchies-tab" />,
      selected: matchPath(location.pathname, {
        path: `${match.url}/hierarchies`,
      }),
    });
  }

  const toChild = {
    isLoading,
    entity,
    schema,
    type,
    entityProperties,
    entityId,
    isFetchingEntity,
  };

  return !isLoading && entity && !isFetching ? (
    <>
      <EntityHeaderWrapper>
        <EntityMainBarWrapper>
          {entity && (
            <EntityMainBar
              entity={entity}
              entityId={entityId}
              goBack={history.goBack}
              layoutId={layoutId}
              layoutName={layoutName}
            />
          )}
        </EntityMainBarWrapper>
        <LightTabList style={{ borderBottom: '1px solid #D4DAD1' }}>
          {tabLinksWithTopology.map((u) => {
            return (
              <LightTabExtra
                key={u.path}
                path={u.path}
                label={u.label}
                history={history}
                selected={u.selected}
                match={match}
              />
            );
          })}
        </LightTabList>
      </EntityHeaderWrapper>
      <EntityContainer>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/overview`} />}
          />
          <Route
            path={`${match.url}/relations`}
            exact
            render={() => (
              <EntityRelationsContainer
                entity={entity}
                isFetching={isFetching}
              />
            )}
          />
          <ProtectedRoute
            routeClaims={{ 'management.hierarchies': RACI_LEVELS.INFORMED }}
            path={`${match.url}/hierarchies`}
            exact
            render={(props) => (
              <HierarchyListEntityTabPage entity={entity} {...props} />
            )}
          />
          <Route
            path={`${match.url}/properties`}
            exact
            render={(props) => (
              <EntityPropertiesPageV2 entityId={entityId} {...props} />
            )}
          />
          <Route
            path={`${match.url}/topology`}
            exact
            render={(props) => (
              <EntityTopologyPage entityId={entityId} {...props} />
            )}
          />
          <Route
            path={`${match.url}/pending-changes`}
            exact
            render={(props) => (
              <EntityPendingChangesPage {...toChild} {...props} />
            )}
          />
          <Route
            path={`${match.url}/explain-log`}
            exact
            render={(props) => <ExplainLogPage {...toChild} {...props} />}
          />
          <Route
            path={`${match.url}/history`}
            exact
            render={(props) =>
              isHistoryWithFiltersEnabled ? (
                <EntityHistory {...toChild} {...props} />
              ) : (
                <EntityHistoryContainer {...toChild} {...props} />
              )
            }
          />
          <Route
            path={`${match.url}/overview`}
            exact
            render={() =>
              entity && (
                <OverviewContainer
                  entity={entity}
                  isFetching={isFetching}
                  type={type}
                  rootUrl={rootUrl}
                />
              )
            }
          />
        </Switch>
      </EntityContainer>
    </>
  ) : (
    <PageLoader />
  );
};

export default withRouter(connect()(EntityMainPageWithTopology));
