import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { Button } from '@cluedin/form';
import Alert from 'uxi/Alert';
import { ExpandingArrow } from '@cluedin/atoms';
import ServerStatusTable from './ServerStatusTable';
import DataSourceStatus from './DataSourceStatus';
import MappingStatus from './MappingStatus';
import ServerStatusLabel from './ServerStatusLabel';
import MicroServiceStatusLabel from './MicroServiceStatusLabel';

import { CollapseElement } from '../../CollapseElement';

const MenuButton = styled(Button)`
  margin: 0;
  width: 100%;
  height: 38px;
  border: none;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  box-shadow: none !important;
  background: ${({ theme, isActive }) =>
    isActive && theme.palette.neutralLighterAlt};

  &:hover {
    text-decoration: none;
  }
`;

const ServiceItemWrapper = styled.span`
  width: 100%;
  font-weight: 600;
  display: inline-flex;
  justify-content: space-between;
`;

const ServerStatusView = memo(
  injectIntl(
    ({
      statuses,
      // statusLoading,
      statusError,
      globalStatus,
      theme,
      hideAlert,
      contentStyle = {},
    }) => {
      const [serverStatus, setServerStatus] = useState(false);
      const [componentStatus, setComponentStatus] = useState(false);
      const [dataSourceStatus, setDataSourceStatus] = useState(false);
      const [mappingStatus, setMappingStatus] = useState(false);
      const [prepareStatus, setPrepareStatus] = useState(false);

      const serverShards = statuses?.server?.DataShards || [];
      const componentShards = statuses?.server?.Components || [];
      const dataSource = statuses?.dataSource;
      const mapping = statuses?.mapping;
      const prepare = statuses?.prepare;

      const clusterDown =
        statusError || (globalStatus && globalStatus === 'down');
      return (
        <>
          {!hideAlert && globalStatus && globalStatus === 'degraded' && (
            <Alert type="warning">
              <FormattedMessage id="module-coreStatusCheck-globalDegraded" />
            </Alert>
          )}
          {!hideAlert && clusterDown && (
            <Alert type="error">
              <FormattedMessage id="module-coreStatusCheck-globalDown" />
            </Alert>
          )}
          <div
            style={{
              borderTop: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
              ...contentStyle,
            }}
          >
            <>
              <MenuButton
                theme={theme}
                isActive={serverStatus}
                onClick={() => setServerStatus((p) => !p)}
              >
                <ServiceItemWrapper>
                  <ServerStatusLabel
                    label={
                      <FormattedMessage id="module-coreStatusCheck-ServerStatus" />
                    }
                    statuses={componentShards}
                    clusterDown={clusterDown}
                  />
                  {!clusterDown && (
                    <ExpandingArrow
                      size={14}
                      color={theme.palette.themePrimary}
                      expanded={serverStatus}
                    />
                  )}
                </ServiceItemWrapper>
              </MenuButton>
              {!clusterDown && serverStatus && (
                <CollapseElement
                  isVisible={serverStatus}
                  style={{ overflow: 'inherit' }}
                >
                  <ServerStatusTable statuses={serverShards} />
                </CollapseElement>
              )}
            </>
            <>
              <MenuButton
                theme={theme}
                isActive={componentStatus}
                onClick={() => setComponentStatus((p) => !p)}
              >
                <ServiceItemWrapper>
                  <ServerStatusLabel
                    label={
                      <FormattedMessage id="module-coreStatusCheck-Components" />
                    }
                    statuses={componentShards}
                    clusterDown={clusterDown}
                  />
                  {!clusterDown && (
                    <ExpandingArrow
                      size={14}
                      color={theme.palette.themePrimary}
                      expanded={componentStatus}
                    />
                  )}
                </ServiceItemWrapper>
              </MenuButton>
              {!clusterDown && componentStatus && (
                <CollapseElement
                  isVisible={componentStatus}
                  style={{ overflow: 'inherit' }}
                >
                  <ServerStatusTable statuses={componentShards} />
                </CollapseElement>
              )}
            </>
            <>
              <MenuButton
                theme={theme}
                isActive={dataSourceStatus}
                onClick={() => setDataSourceStatus((p) => !p)}
              >
                <ServiceItemWrapper>
                  <MicroServiceStatusLabel
                    label={
                      <FormattedMessage id="module-coreStatusCheck-DataSourceServices" />
                    }
                    data={dataSource}
                    clusterDown={clusterDown}
                  />
                  {!clusterDown && (
                    <ExpandingArrow
                      size={14}
                      color={theme.palette.themePrimary}
                      expanded={dataSourceStatus}
                    />
                  )}
                </ServiceItemWrapper>
              </MenuButton>

              <CollapseElement
                isVisible={dataSourceStatus}
                style={{ overflow: 'inherit' }}
              >
                {!clusterDown && dataSourceStatus && (
                  <DataSourceStatus data={dataSource} />
                )}
              </CollapseElement>
            </>
            <>
              <MenuButton
                theme={theme}
                isActive={mappingStatus}
                onClick={() => setMappingStatus((p) => !p)}
              >
                <ServiceItemWrapper>
                  <MicroServiceStatusLabel
                    label={
                      <FormattedMessage id="module-coreStatusCheck-mappingServices" />
                    }
                    data={mapping}
                    clusterDown={clusterDown}
                  />
                  {!clusterDown && (
                    <ExpandingArrow
                      size={14}
                      color={theme.palette.themePrimary}
                      expanded={mappingStatus}
                    />
                  )}
                </ServiceItemWrapper>
              </MenuButton>

              <CollapseElement
                isVisible={mappingStatus}
                style={{ overflow: 'inherit' }}
              >
                {!clusterDown && mappingStatus && (
                  <MappingStatus data={mapping} />
                )}
              </CollapseElement>
            </>
            <>
              <MenuButton
                theme={theme}
                isActive={prepareStatus}
                onClick={() => setPrepareStatus((p) => !p)}
              >
                <ServiceItemWrapper>
                  <MicroServiceStatusLabel
                    label={
                      <FormattedMessage id="module-coreStatusCheck-prepareServices" />
                    }
                    data={prepare}
                    clusterDown={clusterDown}
                  />
                  {!clusterDown && (
                    <ExpandingArrow
                      size={14}
                      color={theme.palette.themePrimary}
                      expanded={prepareStatus}
                    />
                  )}
                </ServiceItemWrapper>
              </MenuButton>

              <CollapseElement
                isVisible={mappingStatus}
                style={{ overflow: 'inherit' }}
              >
                {!clusterDown && prepareStatus && (
                  <MappingStatus data={prepare} />
                )}
              </CollapseElement>
            </>
          </div>
        </>
      );
    },
  ),
);

export default ServerStatusView;
