import React from 'react';
import PropTypes from 'prop-types';
import { HighlightTextFromContext } from '@cluedin/components';
import { Externallink } from 'uxi/Icons';

import VocabularyFullKey from '../../../../../dataCatalog/components/composites/VocabularyFullKey';

const EntityPropertiesImpactedKey = ({ keyToShow, displayName }) => {
  return (
    <li style={{ paddingBottom: '8px' }}>
      {keyToShow && (
        <>
          <a
            target="__blank"
            rel="noreferrer noopener"
            href={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
              keyToShow,
            )}`}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              width: 'fit-content',
            }}
          >
            <HighlightTextFromContext text={displayName} />
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </a>

          <div>
            <VocabularyFullKey vocabKey={keyToShow} />
          </div>
        </>
      )}
    </li>
  );
};

export default EntityPropertiesImpactedKey;

EntityPropertiesImpactedKey.propTypes = {
  keyToShow: PropTypes.string,
  displayName: PropTypes.string,
};

EntityPropertiesImpactedKey.defaultProps = {
  keyToShow: '',
  displayName: '',
};
