"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
/*
& *:focus {
    background: red;
  }
*/
var _default = function _default(List) {
  return (0, _styledComponents.default)(List)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  position: relative;\n  &.padding {\n    padding-top: 8px;\n    padding-bottom: 8px;\n  }\n  &.subHeader {\n    padding-top: 8px;\n  }\n  //needed for keeyboard nav\n  & *:focus {\n    background-color: ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.action.focus;
  });
};
exports.default = _default;