"use strict";

exports.__esModule = true;
exports.headerHeight = exports.footerHeight = exports.default = void 0;
var headerHeight = '50px';
exports.headerHeight = headerHeight;
var footerHeight = '50px';
exports.footerHeight = footerHeight;
var _default = {
  headerHeight: headerHeight,
  footerHeight: footerHeight
};
exports.default = _default;