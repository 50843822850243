import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';

const GET_ENTITY_DATA_BY_ID = gql`
  query getEntityDataById($id: ID!) {
    virtualization {
      getEntityDataById(id: $id) {
        entityAccountData
        entityId
        numberOfFollowers
        score
        suggestedSearches
        entity {
          name
          entityType
          origin
          aliases
          tags
          discoveryDate
          createdDate
          modifiedDate
          entityId
          organizationId
          codes
          dataDescription
          edgesSummary
          description
          properties
          propertyGroups
          providerOrigins
          sortDate
          timeToLive
        }
      }
    }
  }
`;

const GET_ENTITY_BY_ID = gql`
  query getEntityById($id: ID!) {
    virtualization {
      entityById(id: $id) {
        entityProperties {
          entityVocabularyKey
          value
          vocabularyKey {
            displayName
            vocabularyKeyId
            isCluedInCore
            isDynamic
            isObsolete
            mappedKey
            isProvider
            vocabularyId
            name
            isVisible
            key
            dataType
            glossaryTermId
            compositeVocabularyId
            compositeVocabulary {
              name
              displayName
              dataType
            }
            groupName
            dataClassificationCode
            dataType
            description
            providerId
            mapsToOtherKeyId
            vocabulary {
              connector {
                about
                icon
                id
                name
              }
              vocabularyId
              vocabularyName
            }
          }
        }
        propertyGroups {
          keys
          name
          source
        }
      }
    }
  }
`;

export const useGetEntityById = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_ENTITY_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  return [data, loading, error, refetch];
};

export const useGetEntityDataById = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_ENTITY_DATA_BY_ID, {
    variables: { id },
  });
  const entity = get(data, 'virtualization.getEntityDataById.entity', {});

  const entityAccountData = get(
    data,
    'virtualization.getEntityDataById.entityAccountData',
    {},
  );

  const entityId = get(data, 'virtualization.getEntityDataById.entityId', {});

  const numberOfFollowers = get(
    data,
    'virtualization.getEntityDataById.numberOfFollowers',
    {},
  );

  const score = get(data, 'virtualization.getEntityDataById.score', {});

  const suggestedSearches = get(
    data,
    'virtualization.getEntityDataById.suggestedSearches',
    {},
  );

  return [
    {
      suggestedSearches,
      score,
      numberOfFollowers,
      entityId,
      entityAccountData,
      entity,
    },
    loading,
    error,
    refetch,
  ];
};

export const useEntityPropertiesById = () => {
  const [getEntityProperties, { data, loading, error, refetch }] =
    useLazyQuery(GET_ENTITY_BY_ID);
  const entityProperties = get(
    data,
    'virtualization.entityById.entityProperties',
    {},
  );
  const propertyGroups = get(
    data,
    'virtualization.entityById.propertyGroups',
    {},
  );

  return [
    (id) =>
      getEntityProperties({
        variables: {
          id,
        },
      }),
    {
      data: {
        entityProperties,
        propertyGroups,
      },
      loading,
      error,
      refetch,
    },
  ];
};
