import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import inRange from 'lodash/inRange';
import { TextField } from 'uxi/Input';
import { PrimaryButton } from '@cluedin/form';
import { Error } from 'uxi/Icons';

const textFieldStyle = {
  width: '60px',
  height: '30px',
  padding: '0 8px',
  fontSize: '14px',
  fontWeight: 'normal',
};

const Container = styled.div`
  padding: 16px;
`;

const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
  white-space: nowrap;
`;

const OfTotal = styled.div`
  font-size: 12px;
  opacity: 0.35;
  white-space: nowrap;
  padding: 0 16px 0 8px;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: #f16a6a;
  font-size: 12px;
  margin-bottom: 8px;
`;

const ErrorIcon = styled(Error)`
  margin-right: 4px;
`;

const isValid = (page, totalPages) => inRange(page, 1, totalPages + 1);

export const GoToPageForm = ({ totalPages, onPageChange }) => {
  const [page, setPage] = useState();
  const [isError, setErrorState] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    const inputEl = container.current.querySelector('input');
    inputEl.focus();
  });

  const submitPage = () => {
    if (isValid(page, totalPages)) {
      onPageChange(page);
    } else {
      setErrorState(true);
    }
  };

  return (
    <Container
      ref={container}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          submitPage();
        }
      }}
    >
      {isError && (
        <ErrorMessage>
          <ErrorIcon size={12} />
          This page number does not exist
        </ErrorMessage>
      )}
      <ControlWrapper>
        <Label for="goto-page">Go to page</Label>
        <TextField
          style={textFieldStyle}
          value={page}
          onChange={(_, value) => setPage(parseInt(value, 10))}
          type="number"
          min="1"
          max={totalPages}
          id="goto-page"
        />
        <OfTotal>of {totalPages}</OfTotal>
        <PrimaryButton text="Go" onClick={submitPage} />
      </ControlWrapper>
    </Container>
  );
};

GoToPageForm.propTypes = {
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
