import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../../../core/components/containers/ProtectedRoute';
import ErrorBoundary from '../../../../error/components/containers/ErrorBoundary';

export const Div = ({ children }) => <div>{children}</div>;
const RouteWithSubRoutes = ({ routes = [], Comp, match, ...rest }) => {
  const CompToRender = Comp || Div;

  return (
    <ErrorBoundary>
      <CompToRender match={match} {...rest}>
        {routes && routes.length > 0 && (
          <Switch>
            {routes.map((module) => {
              const RouteComponent = module.claims ? ProtectedRoute : Route;
              return (
                <RouteComponent
                  routeClaims={module.claims}
                  key={module.path}
                  path={`${match.url}${module.path}`}
                  exact={module.exact || false}
                  render={(props) => (
                    <RouteWithSubRoutes
                      match={match}
                      {...props}
                      {...rest}
                      Comp={module.component || Div}
                      routes={module.routes}
                    />
                  )}
                />
              );
            })}
          </Switch>
        )}
      </CompToRender>
    </ErrorBoundary>
  );
};

export default RouteWithSubRoutes;
