import { FormattedMessage } from '@cluedin/locale';
import { StatusBadge } from 'uxi/Badge';

const ConfigurationStatus = ({ configuration }) => {
  // if (configuration?.isUnApproved) {
  //   return (
  //     <StatusBadge
  //       type="warning"
  //       label={<FormattedMessage id="module-configuration-unapproved" />}
  //     />
  //   );
  // }
  if (configuration?.FailingAuthentication) {
    return (
      <StatusBadge
        type="error"
        label={<FormattedMessage id="module-configuration-disconected" />}
      />
    );
  }

  if (configuration?.Active || configuration?.Status?.NextExecution) {
    return (
      <StatusBadge
        type="success"
        label={<FormattedMessage id="module-configuration-active" />}
      />
    );
  }

  return (
    <StatusBadge
      type="unexisting"
      label={<FormattedMessage id="module-configuration-inactive" />}
    />
  );
};

export default ConfigurationStatus;
