import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';

import authenticatedClient from '../../../data/graphql';
import { UNREAD_NOTIFICATIONS_COUNT } from './useUnreadNotificationsCount';
import Notification from '../components/containers/notification/Notification';

const NOTIFICATION_ADDED = gql`
  subscription notificationAdded {
    notificationAdded {
      id
      icon
      source
      title
      description
      created
      alertType
      actions
      isNew
    }
  }
`;

export const ALL_NOTIFICATIONS = gql`
  query notifications($page: Int, $pageSize: Int) {
    notification {
      id
      notifications(page: $page, pageSize: $pageSize) {
        total
        data {
          id
          icon
          source
          title
          description
          created
          alertType
          actions
          isNew
        }
      }
    }
  }
`;

const NotificationJSX = ({ notification }) => {
  return (
    <Notification
      id={notification.id}
      key={notification.id}
      title={notification.title}
      source={notification.source}
      alertType={notification.alertType}
      actions={notification.actions || []}
      description={notification.description}
      IconComponent={getAppropriateIcon(notification.icon || 'Help')}
      date={notification.created ? moment(notification.created).fromNow() : ''}
      style={{
        margin: 0,
        padding: 0,
        border: 'none',
      }}
    />
  );
};

export const useNotifications = () => {
  const pageSize = 20;

  const {
    data,
    loading,
    error,
    // refetch,
    fetchMore,
    networkStatus,
    subscribeToMore,
  } = useQuery(ALL_NOTIFICATIONS, {
    variables: {
      page: 0,
      pageSize,
    },
  });

  const total = get(data, 'notification.notifications.total', 0);
  const notifications = get(data, 'notification.notifications.data', []);
  const showMore = !(total === notifications.length);

  return [
    notifications,
    loading,
    error,
    {
      total,
      networkStatus,
      fetchMore: () => {
        fetchMore({
          variables: {
            page: Math.ceil(notifications.length / pageSize),
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const notifications = get(
              fetchMoreResult,
              'notification.notifications.data',
              [],
            );

            if (notifications.length === 0) {
              return prev;
            }

            const result = {
              ...prev,
              notification: {
                ...(prev?.notification || []),
                notifications: {
                  ...(prev?.notification?.notifications || {}),
                  data: [...(prev?.notification?.notifications?.data || [])],
                },
              },
            };

            return result;
          },
        });
      },
      subscribeToMore: () => {
        subscribeToMore({
          document: NOTIFICATION_ADDED,
          updateQuery: async (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;

            const newNotification = subscriptionData.data.notificationAdded;

            const result = {
              ...prev,
              notifications: {
                ...(prev?.notification?.notifications || {}),
                data: [
                  newNotification,
                  ...(prev?.notification?.notifications?.data || []),
                ],
              },
            };

            toast(<NotificationJSX notification={newNotification} />, {
              containerId: 'notifications',
            });

            await authenticatedClient.refetchQueries({
              include: [UNREAD_NOTIFICATIONS_COUNT, ALL_NOTIFICATIONS],
            });

            return result;
          },
        });
      },
    },
    showMore,
  ];
};
