import { FormattedMessage } from '@cluedin/locale';
import { ListProperty } from '@cluedin/components';

import ListValue from '../../../../entityTopology/components/containers/RecordPanel/RecordPanelList/ListValue/ListValue';
import { EntityProperty } from '../../types';

export const columns = [
  {
    property: 'entityVocabularyKey',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraphV2-tablePropertyLabel" />
    ),
    Component: ListProperty,
  },
  {
    property: 'value',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraphV2-tableValueLabel" />
    ),
    Component: (props: EntityProperty) => <ListValue {...props} />,
  },
];
