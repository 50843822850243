import { FormattedMessage } from '@cluedin/locale';
import { List, DataTreeView } from '@cluedin/list-pattern';

import EntityTypeAvatar from '../containers/EntityTypeAvatar';
import SearchLinkForEntityType from '../containers/SearchLinkForEntityType';

const EntityTypeAvatarValue = ({ value }) => (
  <EntityTypeAvatar entityType={value} />
);

const SearchLink = (entity) => (
  <SearchLinkForEntityType entityType={entity.entityType}>
    {entity.count}
  </SearchLinkForEntityType>
);

const EntityTypesTotalList = ({ entityTypes }) => {
  return (
    <List
      noSearch
      offsetTop={180}
      hideExpanding
      selectable={false}
      withToolbar={false}
      showPagination={false}
      DataGrid={DataTreeView}
      data={entityTypes || []}
      columns={[
        {
          displayName: (
            <FormattedMessage id="module-entityType-entityTypeLabel" />
          ),
          property: 'entityType',
          Component: EntityTypeAvatarValue,
        },
        {
          isComputed: true,
          displayName: <FormattedMessage id="module-entityType-count" />,
          property: 'count',
          Component: SearchLink,
        },
      ]}
    />
  );
};

export default EntityTypesTotalList;
