import { Select } from '@cluedin/form';
import { Flex } from 'uxi/Layout';
import { Loader } from '@cluedin/form';

const DataSourceSetSelect = ({
  value,
  style = {},
  dataSourceSets = [],
  onChange,
  isFetching,
}) => {
  if (isFetching) {
    return (
      <Flex>
        <Loader />
      </Flex>
    );
  }

  const options = dataSourceSets.map((dataSourceSet, i) => ({
    value: dataSourceSet.id,
    key: i,
    label: (
      <span
        style={{ minWidth: '175px', justifyContent: 'flex-start' }}
        data-test={`select-group-${dataSourceSet.name}`}
      >
        {dataSourceSet.name}
      </span>
    ),
  }));

  const handleChange = ({ value }) => onChange(value);
  return (
    <div
      style={{ width: '300px', ...style }}
      data-test={'selectDataSourceGroup'}
    >
      <Select value={value} onChange={handleChange} options={options} />
    </div>
  );
};

export default DataSourceSetSelect;
