/* eslint-disable no-useless-escape */
export default {
  'module-dataCatalog-vocabRevokeOwnershipLabel': 'Remove ownership',
  'module-dataCatalog-vocabRevokeOwnershipTitle':
    'Are you sure you want to remove ownership from these users?',
  'module-dataCatalog-vocabRevokeOwnershipMessage':
    'This will remove the vocabulary ownership from selected users',
  'module-dataCatalog-vocabNotFoundMessage': 'Vocabulary cannot be found',
  'module-dataCatalog-vocabNotFoundExplanation':
    'Make sure there are no unwanted or incorrect search terms as part of the search',
  'module-dataCatalog-vocabKeyNotFoundMessage':
    'Vocabulary key cannot be found',
  'module-dataCatalog-vocabKeyNotFoundExplanation':
    'Make sure there are no unwanted or incorrect search terms as part of the search',
  'module-dataCatalog-addOwner': 'Add Vocabulary Owner',
  'module-dataCatalog-noVocabOwner': 'No vocabulary owners',
  'module-dataCatalog-noVocabOwnerExplanation':
    'There should always be an owner for a vocabulary. If there is no vocabulary owner defined, CluedIn will look at different levels of the system to find one - from provider level, to RACI level, and finally the list of Administrators if needed.',
  'module-dataCatalog-addOwnerTitle': 'Select users to add as an owner',
  'module-dataCatalog-field-name': 'Name',
  'module-dataCatalog-field-name-help': 'e.g, Customer, Car, Product',
  'module-dataCatalog-field-name-error-blank': "Name can't be blank",
  'module-dataCatalog-field-name-error-specialChar':
    "Name can't have special characters",
  'module-dataCatalog-field-entityType': 'Primary Entity type',
  'module-dataCatalog-field-entityType-help':
    'This value will indicate the main Entity Type associated to this vocabulary.',
  'module-dataCatalog-field-source': 'Source',
  'module-dataCatalog-field-keyPrefix': 'Key prefix',
  'module-dataCatalog-field-keyPrefix-error-blank': "Key prefix can't be blank",
  'module-dataCatalog-field-keyPrefix-error-specialChar':
    "Key prefix can't have special characters",
  'module-vocabulary-vacab-label': 'Vocabulary',
  'module-vocabulary-field-description': 'Description',
  'module-dataCatalog-field-keyPrefix-help':
    'The key prefix will be added before all vocabulary key names to create a full vocabulary key, composed of the vocabulary prefix and the name of the vocabulary (e.g, hubspot.email - where "hubspot" is the prefix, and "email" is the vocabulary name). The dot (".") will be added automatically.',
  'module-dataCatalog-field-description': 'Description',
  'module-dataCatalog-vocabKey-field-displayName': 'Display name',
  'module-dataCatalog-vocabKey-field-displayName-help':
    'User-friendly text used in the user interface to help with readability.',
  'module-dataCatalog-vocabKey-field-displayName-validationError':
    'Must start with a letter which can only then be followed by letters or numbers',
  'module-dataCatalog-vocabKey-field-name': 'Name',
  'module-dataCatalog-vocabKey-field-name-help':
    "The vocabulary 'name' will be used to create the full vocabulary 'key'.",
  'module-dataCatalog-vocabKey-field-name-error':
    'The vocabulary name must start with a letter which can only then be followed by letters or numbers',
  'module-dataCatalog-vocabKey-field-groupName': 'Group name',
  'module-dataCatalog-vocabKey-field-isVisible': 'Is Visible',
  'module-dataCatalog-vocabKey-field-dataType': 'Data type',
  'module-dataCatalog-vocabKey-field-dataClassificationCode': 'Classification',
  'module-dataCatalog-vocabKey-field-description': 'Description',
  'module-dataCatalog-vocabKey-field-lookupDataGlossaryTerm': 'Glossary Term',
  'module-dataCatalog-vocabKey-field-mapsTo': 'Maps to',
  'module-dataCatalog-vocabKey-field-mapsTo-help':
    "Choose a vocabulary key where the current vocabulary key should be mapped. Once mapped, the current vocabulary key's value will flow towards the selected vocabulary key. Once a value is defined, please visit the lineage tab to view the data flow.",
  'module-dataCatalog-vocabKey-fullVocabKeyWillBe':
    'Full vocabulary key will be',
  'module-dataCatalog-createVocab-panelTitle': 'Create Vocabulary',
  'module-dataCatalog-addVocabKeys-panelTitle': 'Add Vocabulary Key',
  'module-dataCatalog-removeVocabKeys-mapAnotherTitle':
    'Map to another vocabulary key?',
  'module-dataCatalog-removeVocabKeys-mapAnotherDesc':
    'Mapping this Vocabulary Key to a new or existing key will help maintain the history of the data you have already ingested.',
  'module-dataCatalog-removeVocabKeys-removeVocabTitle':
    'Remove vocabulary keys?',
  'module-dataCatalog-will-be-marked-as-obsolete-info':
    'For lineage purposes, if a removed vocabulary key is being used by golden records, we will mark it as obsolete and keep it alongside current vocabulary keys.',
  'module-dataCatalog-removeVocabKeys-removeVocabDesc':
    'Removing this vocabulary key will cause records that have used this key to be reprocessed. It will also affect the following elements:',
  'module-dataCatalog-vocabKey-notFoundMessage':
    'Vocabulary key cannot be found',
  'module-dataCatalog-vocabKey-notFoundExplanation':
    'Make sure there are no unwanted or incorrect search terms as part of the search',
  'module-dataCatalog-vocab-headerEditButton': 'Edit',
  'module-dataCatalog-vocab-headerRemoveButton': 'Remove',
  'module-dataCatalog-vocab-headerSaveButton': 'Save',
  'module-dataCatalog-vocab-dialogSaveWarningTitle':
    'Are you sure you want to save these changes?',
  'module-dataCatalog-vocab-dialogSaveWarningMessage':
    'Vocabulary processing will be affected.',
  'module-dataCatalog-vocab-select-loadMore': 'Load more',
  'module-dataCatalog-policy-policyField': 'Policy',
  'module-dataCatalog-policy-policyFieldHelp':
    'Basic set of policies set on the vocabulary keys.',
  'module-dataCatalog-noValueForPolicy': 'No value has been set',
  'module-dataCatalog-dataAnnotationsIsPrimaryKey': 'Is PrimaryKey',
  'module-dataCatalog-dataAnnotationsIsEditable': 'Is Editable',
  'module-dataCatalog-dataAnnotationsIsNullable': 'Is Nullable',
  'module-dataCatalog-dataAnnotationsIsRequired': 'Is Required',
  'module-dataCatalog-dataAnnotationsMinimumLengthLabel':
    'Minimum Length required',
  'module-dataCatalog-dataAnnotationsMaximumLengthLabel':
    'Maximum Length required',
  'module-dataCatalog-policy-validationField': 'Basic validation rules',
  'module-dataCatalog-policy-validationdHelp':
    'Basic requirement on the length of the value',
  'management-catalog-dashboard-viewAllVocabulary': 'View All Vocabularies',
  'management-catalog-dashboard-viewAllVocabularyKey':
    'View All Vocabulary Keys',
  'module-dataCatalog-view-details-on': 'View more details on',
  'module-dataCatalog-createVocabulary': 'Create Vocabulary',
  'module-dataCatalog-batchMapping': 'Batch Mapping',
  'module-dataCatalog-entityTypeFilter': 'Entity Type',
  'module-dataCatalog-statusFilter': 'Status',
  'module-dataCatalog-vocabKey-field-integration': 'Integrations',
  'module-dataCatalog-integrationFilter': 'Sources',
  'module-dataCatalog-noVocabKeysYet': 'No vocabulary keys yet',
  'module-dataCatalog-vocabKey-explanation':
    'Your vocabulary keys will be shown here once created. To add a vocabulary key to this vocabulary, press the button below',
  'module-dataCatalog-vocabKey-buttonLabel': 'Add Vocabulary Key',
  'module-dataCatalog-vocab-buttonLabel': 'Create Vocabulary',
  'module-dataCatalog-vocab-message': 'No vocabulary created yet',
  'module-dataCatalog-vocab-explanation':
    'Your vocabulary will be shown here once they have been created; to create your first vocabulary press the button below',
  'module-dataCatalog-column-vocabularyName': 'Name',
  'module-dataCatalog-column-grouping': 'Entity type',
  'module-dataCatalog-column-keyPrefix': 'Key Prefix',
  'module-dataCatalog-column-source': 'Source',
  'module-dataCatalog-column-isActive': 'Status',
  'module-dataCatalog-saveAndReprocess': 'Save and Reprocess',
  'module-dataCatalog-renameTo':
    'This vocabulary key will be renamed to <strong>{renameValue}</strong>. For lineage purposes, if a removed vocabulary key is being used by golden records, we will mark it as obsolete and keep it alongside current vocabulary keys.',
  'module-dataCatalog-helpInfoReprocess':
    'CluedIn will automatically run a reprocess of this vocabulary key and apply the changes that have been made.<br/>Once done, this page will redirect to the updated vocabulary key.',
  'module-dataCatalog-confirm': 'Confirm',
  'module-dataCatalog-helpInfoText':
    'Renaming a vocabulary key means that CluedIn will automatically apply changes to the following elements.',
  'module-dataCatalog-next': 'Next',
  'module-dataCatalog-renameStep': 'Rename',
  'module-dataCatalog-confirmStep': 'Confirm',
  'module-dataCatalog-newVocabKey': 'New Vocabulary Key Name',
  'module-dataCatalog-vocabNotFound-buttonLabel': 'Add Vocabulary Key',
  'module-dataCatalog-vocabNotFound-message': 'No vocabulary key created yet',
  'module-dataCatalog-vocabNotFound-explanation':
    'Your vocabulary keys will be shown here once they have been created; to create your first vocabulary key press the button below',
  'module-dataCatalog-ungroupedKey': 'Ungrouped Keys',
  'module-dataCatalogr-noGroupName':
    'There are no vocabulary keys for {groupName}.',
  'module-dataCatalog-classification': 'Classification',
  'module-dataCatalog-dataType': 'Data Type',
  'module-dataCatalog-allClassification': 'All classifications',
  'module-dataCatalog-allTypes': 'All types',
  'module-dataCatalog-resetAllFilters': 'Reset all filters',
  'module-dataCatalog-resetSingular': 'Reset 1 filter',
  'module-dataCatalog-resetPlural': 'Reset {numberOfActiveFilters} filters',
  'module-dataCatalog-noValuesForVocabKeyTitle': 'No values found',
  'module-dataCatalog-deletedToast': 'Vocabulary key deleted',
  'module-dataCatalog-noValuesForVocabKey':
    'There are no values for this vocabulary key',
  'module-dataCatalog-vocabKeyValue-total': 'Total',
  'module-dataCatalog-vocabKeyValue-value': 'Value',
  'module-dataCatalog-vocabKeyUsage-Entities': 'Entities',
  'module-dataCatalog-vocabKeyUsage-Streams': 'Streams',
  'module-dataCatalog-vocabKeyUsage-Glossary-Terms': 'Glossary Terms',
  'module-dataCatalog-vocabKeyUsage-Rules': 'Rules',
  'module-dataCataglor-actionLabel-view-stream-usage': 'View stream usage',
  'module-dataCataglor-actionLabel-view-glossary-usage': 'View glossary usage',
  'module-dataCataglor-actionLabel-view-rule-usage': 'View rule usage',
  'module-dataCatalog-viewAllTerms': 'View all terms for {displayName}',
  'module-dataCatalog-viewAllRules': 'View all rules for {displayName}',
  'module-dataCatalog-viewAllStreams': 'View all stream for {displayName}',
  'module-dataCatalog-noExtraInfo': 'No extra info',
  'module-dataCatalog-invalidLineage': 'Invalid Lineage',
  'module-dataCatalog-lineageNoSource': 'No Source for this vocabulary key',
  'module-dataCatalog-noSourceVocabKey': 'No Source Vocabulary key',
  'module-dataCatalog-lineageSourceVocabKey': 'Source Vocabulary Keys',
  'module-dataCatalog-coreVocabKeys': 'Core Vocabulary Keys',
  'module-dataCatalog-lineageSourceVocabKeyTitle': 'Source Vocabulary Keys',
  'module-dataCatalog-lineageStreamTitle': 'Streams',
  'module-dataCatalog-lineageNoVocabKeyExported': 'Vocabulary key not exported',
  'module-dataCatalog-vocabKeyRemove': 'Remove',
  'module-dataCatalog-vocabKeySave': 'Save',
  'module-dataCatalog-vocabKeySubmitForApproval': 'Submit for approval',
  'module-dataCatalog-vocabKey-saveWarningTitle': 'Save Changes?',
  'module-dataCatalog-vocabKey-saveWarningMessage':
    'Saving the changes means all data using this vocabulary key will be reprocessed.',
  'module-dataCatalog-vocabKeyEdit': 'Edit',
  'module-dataCatalog-vocabKey-removeCore':
    'You cannot remove core vocabulary key',
  'module-dataCatalog-vocabKey-removeStatic':
    'You cannot remove static vocabulary key',
  'module-dataCatalog-deleteVocabKeyTitle': 'Delete vocabulary key',
  'module-dataCatalog-selectVocabKeyTitle': 'Select a vocabulary key',
  'module-dataCatalog-vocabKeyDetail-Configuration': 'Configuration',
  'module-dataCatalog-vocabKeyDetail-Usage': 'Usage',
  'module-dataCatalog-vocabKeyDetail-AllValues': 'All Values',
  'module-dataCatalog-vocabKeyDetail-Lineage': 'Lineage',
  'module-dataCatalog-vocabKeyDetail-confirmTitle':
    'Are you sure you want to continue?',
  'module-dataCatalog-vocabKeyDetail-confirmMessage':
    'Your changes to the export target configuration will be lost',
  'module-dataCatalog-vocabKeyDetail-noVocabFound': 'No Vocaburaly Key Found',
  'module-dataCatalog-renameVocabKeyTitle': 'Rename the vocabulary key',
  'module-dataCatalog-keyAddMapping': 'Please select one vocabulary to map to.',
  'module-dataCatalog-keyAddMapping-tryingToMap':
    'You are trying to map a vocabulary to itself, please select another vocabulary key.',
  'module-dataCatalog-vocabDetailUsage-viewSream': 'View stream usage',
  'module-dataCatalog-vocabDetailUsage-viewGlossary': 'View glossary usage',
  'module-dataCatalog-vocabDetailUsage-viewRule': 'View rule usage',
  'module-dataCatalog-Entities': 'Entities',
  'module-dataCatalog-Streams': 'Streams',
  'module-dataCatalog-Glossary': 'Glossary Terms',
  'module-dataCatalog-Rules': 'Rules',
  'module-dataCatalog-vocabUser-viewAllStream':
    'View all streams for {vocabularyName}',
  'module-dataCatalog-vocabUser-viewAllTerms':
    'View all terms for {vocabularyName}',
  'module-dataCatalog-vocabUser-viewAllRules':
    'View all rules for {vocabularyName}',
  'module-dataCatalog-addUsersToolbar-saveWarningTitle':
    'Are you sure you want to add these users as owners?',
  'module-dataCatalog-addUsersToolbar-saveWarningMessage':
    'This will add the selected users as owners',
  'module-dataCatalog-addUsersToolbar-addOwner': 'Add Owners',
  'module-dataCatalog-vocabDetail-coreVOcab': 'Core Vocabulary',
  'module-dataCatalog-vocabDetail-from': 'From',
  'module-dataCatalog-vocabDetail-createdBy': 'Created by',
  'module-dataCatalog-vocabDetailLabel-configuration': 'Configuration',
  'module-dataCatalog-vocabDetailLabel-usage': 'Usage',
  'module-dataCatalog-vocabDetailLabel-owners': 'Owners',
  'module-dataCatalog-vocabDetailLabel-vocabKeys': 'Vocabulary Keys',
  'module-dataCatalog-vocabDetail-confirmTitle':
    'Are you sure you want to continue?',
  'module-dataCatalog-vocabDetail-confirmMessage':
    'Your changes to the export target configuration will be lost',
  'module-dataCatalog-cannotRmoveCoreVocab':
    'You cannot remove core vocabulary',
  'module-dataCatalog-cannotRmoveVocabWithKeys':
    'You cannot remove a vocabulary if it has keys',
  'module-dataCatalog-cannotRmoveStaticVocab':
    'You cannot remove static vocabulary',
  'module-dataCatalog-vocabKeyNotFound': 'Vocabulary Key Not found.',
  'module-dataCatalog-aboutToMapXNumberOfKeys':
    'You are about to map {dataWithResultLength} vocabulary keys.',
  'module-dataCatalog-vocabBatch-label': 'Insert Mapping configuration',
  'module-dataCatalog-vocabBatch-helpText':
    'Mapping configuration are a coma separeted value list of keyMappingFrom => keyMappingTo ',
  'module-dataCatalog-vocabBatch-next': 'Next',
  'module-dataCatalog-vocabBatch-cancel': 'Cancel',
  'module-dataCatalog-vocabBatchStep-insertMapping': 'Insert Mapping',
  'module-dataCatalog-vocabBatchStep-reviewMapping': 'Review Mapping',
  'module-dataCatalog-vocabBatchStep-ConfirmMapping': 'Confirm',
  'module-dataCatalog-vocabMappingLabel-status': 'Status',
  'module-dataCatalog-vocabMappingLabel-to': 'To',
  'module-dataCatalog-vocabMappingLabel-from': 'From',
  'module-dataCatalog-vocabMappingLabel-removeMapping': 'Remove this mapping',
  'module-dataCatalog-vocabMappingLabel-canOnlyMapOnce':
    'You can only map a vocabulary once. Duplicated with rule number {duplicatedIndexes}',
  'module-dataCatalog-vocabBatchMapping-noMoreMappingToShow':
    'No more mapping to show.',
  'module-dataCatalog-addMappingLabel': 'Add mapping',
  'module-dataCatalog-removeMappingLabel': 'Remove',
  'module-dataCatalog-removeMappingEdit': 'Edit',
  'module-dataCatalog-keyIsNotMappedToAnotherKey':
    'Key is not mapped to another vocabulary key',
  'module-dataCatalog-createVocabKeyLabel': 'Create',
  'module-dataCatalog-vocabKeyRenameLabel': 'Rename',
  'module-dataCatalog-invalidVocabKeyName': 'Invalid vocabulary key name',
  'module-dataCatalog-voabKeyNameAlreadyExists':
    'Vocabulary key name already exists, must be unique',
  'module-dataCatalog-typeNamePreview':
    'Please type a name to preview the full key name',
  'module-dataCatalog-vocabNewGroup-label': 'Group Name',
  'module-dataCatalog-vocabNewGroup-radioLabel': 'Create a new group',
  'module-dataCatalog-choseGroupName-label': 'Choose a Group Name',
  'module-dataCatalog-choseGroupName-radioLabel': 'Choose an existing group',
  'module-dataCatalog-createVocabFormLabel': 'Create',
  'module-dataCatalog-defaultClassificationLabel': 'No classification',
  'module-dataCatalog-defaultLookupDataGlossaryTerm':
    'Please select a glossary term',
  'module-dataCatalog-resolvedLookupValue': '(Resolved Value)',
  'module-dataCatalog-vocabKeySelectType': 'Please select a type',
  'module-dataCatalog-noVocabSource': 'No Source',
  'module-dataCatalog-ungroupedVocabKey': 'Ungrouped',
  'module-dataCatalog-noClassificationVocabKey': 'No classification',
  'module-dataCatalog-pseudoStreamValueNameLabel': 'Name',
  'module-dataCatalog-deleteButtonCannotRemove': 'You cannot remove',
  'module-dataCatalog-deActivateVocab':
    'You cannot de-activate the vocabulary ',
  'module-dataCatalog-deActivateVocabExplanation':
    'Once a vocabulary has been activated, we cannot de-activate this vocabulary for lineage purpose.',
  'module-dataCatalog-cannotDeactivateVocabTitle':
    'You cannot de-activate a vocabulary',
  'module-dataCatalog-actviateVocab-title':
    'Are you sure you want to activate the vocabulary?',
  'module-dataCatalog-actviateVocab': 'Activate the vocabulary',
  'module-dataCatalog-actviateVocabExpl':
    'Activated vocabulary cannot currently be de-activated. Make sure your vocabulary is setup correctly, otherwise the process to remove this vocabulary will be more complex.',
  'module-dataCatalog-confirmActivateLabel': 'Confirm',
  'module-dataCatalog-vocabKeyItemDataType': 'Data Type',
  'module-dataCatalog-classificationTitle': 'Classification',
  'module-dataCatalog-genericSaveLabel': 'Save',
  'module-dataCatalog-genericSaveTitle':
    'Are you sure you want to save ths changes?',
  'module-dataCatalog-step-mapping-mapToExisting': 'Map to existing',
  'module-dataCatalog-step-mapping-selectVocabKey': 'Select vocabulary key',
  'module-dataCatalog-step-mapping-confirm': 'Confirm',
  'module-dataCatalog-removeVocabKeys-doYouWantToMapToExistingKey':
    'Do you want to map to an existing vocabulary key?',
  'module-dataCatalog-removeVocabKeys-justDeleteVocabKey':
    'No, just delete the vocabulary key',
  'module-dataCatalog-removeVocabKeys-next': 'Next',
  'module-dataCatalog-removeVocabKeys-yesMapToExisting': 'Yes',
  'module-dataCatalog-removeVocabKeys-backToPrevious': 'Back',
  'module-dataCatalog-removeVocabKeys-confirm': 'Confirm',
  'module-dataCatalog-vocab-active-label': 'Active',
  'module-dataCatalog-vocab-inactive-label': 'Inactive',
  'module-dataCatalog-all-vocabularies': 'All Vocabularies',
  'module-dataCatalog-vocabKey-field-storage': 'Storage',
  'module-dataCatalog-vocabKey-field-storage-option-typed': 'Typed',
  'module-dataCatalog-vocabKey-field-storage-option-untyped': 'Untyped',
  'module-dataCatalog-vocabKey-field-storage-option-text': 'Text',
  'module-dataCatalog-vocabKey-field-storage-option-keyword': 'Keyword',
  'module-dataCatalog-vocabKey-field-storage-reIndex-label':
    'This field when changed triggers a re-indexing',
  'module-dataCatalog-cannotSelectMore':
    'You cannot select more properties, {maxNumberColumns} max. Please refine your selection before adding more.',
  'module-dataCatalog-propertyName': 'Entity Property Name',
  'module-dataCatalog-name': 'Name',
  'module-dataCatalog-nameExpl': 'Name of the entity',
  'module-dataCatalog-entityExpl': 'The type of the entity',
  'module-dataCatalog-entityTypeExpl': 'The type of the entity',
  'module-dataCatalog-descriptionExpl': 'The description of the entity',
  'module-dataCatalog-displayName': 'Display name',
  'module-dataCatalog-displayNameExpl': 'The display name of the entity',
  'module-dataCatalog-uri': 'Uri',
  'module-dataCatalog-uriExpl': 'The uri of the entity',
  'module-dataCatalog-createdDate': 'Date Created',
  'module-dataCatalog-createdDateExpl': 'Creation date of the entity',
  'module-dataCatalog-discoveryDate': 'Discovery Date',
  'module-dataCatalog-discoveryDateExpl':
    'Date when CluedIn discovered the entity',
  'module-dataCatalog-modifiedDate': 'Date Modified',
  'module-dataCatalog-modifiedDateExpl':
    'Last time a change applied to this entity',
  'module-dataCatalog-titleEntityProperty':
    'Select entity property to add as column',
  'module-dataCatalog-saveSelectionAction': 'Save Selection',
  'module-dataCatalog-vocabPropertyDialog':
    'Select Vocabulary to add as column',
  'module-dataCatalog-addVocabColumns': 'Add Vocabulary Columns',
  'module-dataCatalog-addVocabProp': 'Add Vocabulary Property',
  'module-dataCatalog-addEntityProperty': 'Add Entity Property',
  'module-dataCatalog-removeProps': 'Remove Property',
  'module-dataCatalog-addProperty': 'Add Property',
  'module-dataCatalog-addProps': 'Add Property',
  'module-dataCatalog-addAtLeastOneProps': 'No properties selected',
  'module-dataCatalog-addPropsExplanation':
    'Properties must be selected before a clean project can be created.',
  'module-dataCatalog-property': 'Property',
  'module-dataCatalog-type': 'Type',
  'module-dataCatalog-add': 'Save Selection',
  'module-dataCatalog-choose-property-keys': 'Choose Property Keys',
  'module-dataCatalog-choose-vocab-keys': 'Choose Vocabulary Keys',
  'module-dataCatalog-OriginEntityCode': 'Origin Entity Code',
  'module-dataCatalog-description': 'Description',
  'module-dataCatalog-entityType': 'Entity Type',
  'module-dataCatalog-vocabularyKeySelectPlaceholder': 'Select Vocabulary Key',
  'module-dataCatalog-detail-key-is-obsolete':
    'Your vocabulary key is obsolete and is mapped to ',
  'module-dataCatalog-key-is-obsolete':
    'Your vocabulary key is obsolete and is mapped to ',
  'module-dataCatalog-detail-key-is-obsolete-plain':
    'Your vocabulary key is obsolete',
  'module-dataCatalog-key-is-obsolete-plain': 'Your vocabulary key is obsolete',
  'module-dataCatalog-mapsToOtherKeyInputAlert':
    'Core vocabulary keys are at the atomic level of vocabulary keys, and therefore cannot be modified.',
  'module-dataCatalog-mapsToOtherKeyInfo':
    'You can add mapping to another key. The current key will be marked as obsolete',
  'module-dataCatalog-save-edit-key-disable-because-of-re-indexing-msg':
    'Re-indexing in progress. Please try changing `Storage` or `Data type` fields later.',
  'module-dataCatalog-vocabulary-saved': 'Vocabulary saved',
  'module-dataCatalog-delete-vocab-key-err': 'Error deleting. Please try again',
  'module-dataCatalog-cannot-edit-composite':
    'Composite Vocabulary Keys cannot be edited',
};
