import React from 'react';
import PropTypes from 'prop-types';

const LittleTextPlaceholder = ({ width = 100, style }) => (
  <div
    style={{ width: `${width}px`, height: '16px', opacity: '0.1', ...style }}
  >
    <div style={{ width: '100%', height: '11px', background: '#58666e' }} />
  </div>
);

LittleTextPlaceholder.defaultProps = {
  width: 100,
};

LittleTextPlaceholder.propTypes = {
  width: PropTypes.number,
};

export default LittleTextPlaceholder;
