import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { ButtonLink } from '@cluedin/atoms';
import { withWorkflow } from '@cluedin/form';

import TileDetail from './TileDetail';
import TileExtra from './TileExtra';

import TextEllipsis from '../../../composites/layout/TextEllipsis';
import ProvidersIconLinkList from '../../../composites/providers/ProvidersIconLinkList';
import EntityLinkFromEntity from '../../../composites/EntityLinkFromEntity';
import EntityTypeAvatar from '../../../composites/entityTypes/EntityTypeAvatar';
import TileEntityTypeIcon from './TileEntityTypeIcon';
import { getEntityUrl, getEntityConfig } from '../../../../../wms/selectors';
import { useGoldenRecordList } from '../../GoldenRecordListContext';
import { useStaticGoldenRecordList } from '../../StaticGoldenRecordListContext';

import {
  getLogoFromGql,
  getEntityTemporalDataFromGql,
  uniqProviderBasedOnUrisAndProviderCodes,
} from './utils/entityHelper';
import { GoldenRecordViewPropertiesPanel } from '../../GoldenRecordViewPropertiesPanel';

const Snippet = styled.div`
  color: #999999;
  font-size: 12px;
  max-width: 100%;
`;

const extractVocabularyKeys = (entityProperties = {}) => {
  const keys = Object.keys(entityProperties).filter(
    (k) => k !== 'attribute-type',
  );
  const vocabularyKeys = keys.map((key) => {
    return key.replace(/property-/g, '');
  });

  return vocabularyKeys;
};

const TileViewItem = ({ entity = {}, entityId, openWorkflow }) => {
  const { entityTypesConfigurations, integrations, viewOptions } =
    entity?.useStaticContext
      ? useStaticGoldenRecordList()
      : useGoldenRecordList();
  const { columns } = viewOptions;

  const isSelected = entity.id === entityId;
  const bgTileColor = isSelected ? '#f2f2f2' : '#fff';

  const numberOfProperties = extractVocabularyKeys(entity.properties)?.length;
  const logo = getLogoFromGql(entity);
  const createdDate = getEntityTemporalDataFromGql(entity);

  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    entity.uris || [],
    entity.providerOrigins || [],
    integrations,
  );
  const entityUrl = getEntityUrl(
    entity.id,
    entity.entityType,
    entityTypesConfigurations,
  );
  const entityConfig = getEntityConfig(
    entityTypesConfigurations,
    entity.entityType,
  );

  const showEntityQuickView = () => {
    openWorkflow({
      entityId: entity.id,
      entityType: entity.entityType,
      entityName: entity.name,
      entityCreatedDate: entity.createdDate,
      entityModifiedDate: entity.modifiedDate,
      entityDiscoveryDate: entity.discoveryDate,
      entityDescription: entity.description,
    });
  };

  return (
    <div className="test_entity_item">
      <TileDetail
        title={
          <EntityLinkFromEntity entityUrl={entityUrl} entity={entity}>
            <TextEllipsis>{entity.name || ['[No Name]']}</TextEllipsis>
          </EntityLinkFromEntity>
        }
        imageUrl={logo.small} //no existing
        icon={
          <TileEntityTypeIcon
            entityTypesConfigurations={entityTypesConfigurations}
            entity={entity}
            entityType={entity.entityType}
          />
        }
        roundImage
        extra={
          <TileExtra
            entity={entity}
            columns={columns}
            description={entity.description}
          />
        }
        style={{
          background: bgTileColor,
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
          padding: '32px 16px 16px 16px',
        }}
      >
        <div>
          <div style={{ padding: '6px 0' }}>
            <EntityTypeAvatar
              color="#999999"
              entityType={entity.entityType}
              entityConfig={entityConfig}
            />
          </div>
          {createdDate && <Snippet>{createdDate}</Snippet>}
          <ProvidersIconLinkList
            roundImage
            style={{ margin: '8px 0 16px' }}
            providers={providers}
          />
          <ButtonLink onClick={showEntityQuickView}>
            <span style={{ fontSize: '12px' }}>
              <FormattedHTMLMessage
                id="module-goldenRecordList-viewProps"
                values={{
                  numberOfProperties,
                }}
              />
            </span>
          </ButtonLink>
        </div>
      </TileDetail>
    </div>
  );
};

TileViewItem.displayName = 'TileViewItem';

export default withWorkflow(TileViewItem, GoldenRecordViewPropertiesPanel, {
  panelType: 'medium',
  title: <FormattedMessage id="module-goldenRecordList-previewProps" />,
});
