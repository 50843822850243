import React from 'react';
import InsightSearchLinkRouterLink from './InsightSearchLinkRouterLink';

const SearchLink = ({ search }) => (
  <div>
    <InsightSearchLinkRouterLink query={search.Query}>
      {search.Name}
    </InsightSearchLinkRouterLink>
  </div>
);

export default SearchLink;
