import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import SelectClientIdFormContainer from './SelectClientIdFormContainer';
import PublicContext from '../composites/PublicContext';
import AuthTryingToCreateTeam from '../composites/AuthTryingToCreateTeam';
import SignUpIllustration from '../../assets/signin-illustration.png';

const SelectClientId = () => (
  <NotSignedInLayoutDividedInTwo
    Page={SelectClientIdFormContainer}
    PageExtra={AuthTryingToCreateTeam}
    Context={PublicContext}
    url={`${SignUpIllustration}`}
  />
);

export default SelectClientId;
