import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInMemorySearch, DefaultFilterToolbar } from '@cluedin/components';
import { List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import _ from 'lodash';

import { useEntityAnonymizeProperty } from '../../../../hooks/useEntityAnonymizeProperty';
import { useInMemoryPaging } from '../../../../../core/hooks/useInMemoryPaging';
import EntityPropertiesSourceDropdownFilter from '../EntityPropertiesSourceDropdownFilter/EntityPropertiesSourceDropdownFilter';
import EntityPropertiesDropdownFilter from '../EntityPropertiesDropdownFilter/EntityPropertiesDropdownFilter';
import EntityPropertiesDropdown from '../EntityPropertiesDropdown/EntityPropertiesDropdown';
import EntityListProperty from '../EntityListProperty/EntityListProperty';
import EntityListValue from '../EntityListValue/EntityListValue';

const EntityPropertiesList = ({
  connectorId,
  setConnectorId,
  entityId,
  entityProperties,
  filteredEntityProperties,
  filteredHistoryChanges,
  loading,
  propertyGroups,
  history,
  location,
  groupNameFilterState,
  setGroupNameFilterState,
  refetch,
  historyRefetch,
  historyLoading,
}) => {
  const connectors =
    entityProperties &&
    (entityProperties || []).map((item) => {
      return item.vocabularyKey?.vocabulary?.connector;
    });
  const uniqueConnectors = _.uniqBy(connectors || [], 'id' !== null);

  const reset = useCallback(() => {
    setConnectorId('');
    setGroupNameFilterState('');
  });

  const [{ anonymize, deanonymize }, { anonymizeLoading, status }] =
    useEntityAnonymizeProperty();

  filteredEntityProperties = filteredEntityProperties.map((property) => {
    if (property.vocabularyKey?.dataType === 'Lookup') {
      var found = filteredEntityProperties.find(
        (prop) =>
          prop.vocabularyKey?.compositeVocabulary?.dataType === 'Lookup' &&
          prop.vocabularyKey?.name === 'Name' &&
          prop.vocabularyKey?.compositeVocabularyId ===
            property.vocabularyKey?.vocabularyKeyId,
      );
      if (found) {
        const invalidRegex = /^\[Invalid \((Code|Missing|Duplicate)\)\] (.*)$/g;
        const isInvalid = invalidRegex.test(found.value);
        const newValue = isInvalid ? property.value : found.value;
        const error = isInvalid ? { errorText: found.value } : {};
        return { ...property, ...{ value: newValue }, ...error };
      }
    }
    return property;
  });

  filteredEntityProperties = filteredEntityProperties.filter((property) => {
    if (property.vocabularyKey?.compositeVocabulary?.dataType === 'Lookup') {
      return false;
    }
    return true;
  });

  const [searchedConnectors, searchTerm, onSubmitSearch] = useInMemorySearch(
    filteredEntityProperties || [],
    ['entityVocabularyKey', 'value', 'vocabularyKey.displayName'],
    history,
    location,
    { searchName: 'propertyName' },
  );
  const [searchName, setSearchName] = useState(searchTerm);

  const hasStatus = !!Object.keys(status || {}).length;

  const entityPropertiesContainingGroupNameKeys = groupNameFilterState
    ? (searchedConnectors || []).filter((eProp) => {
        if (
          eProp?.vocabularyKey?.groupName === null &&
          groupNameFilterState === '[Ungrouped]'
        ) {
          return true;
        }
        return (
          eProp?.vocabularyKey?.groupName?.toLowerCase() ===
          groupNameFilterState.toLowerCase()
        );
      })
    : searchedConnectors || [];

  const pagedEntityPropertiesWithAnonymizedValues = hasStatus
    ? (searchedConnectors || []).map((entityProperty) => {
        const propertyStatus = status[entityProperty.entityVocabularyKey];

        if (propertyStatus) {
          return {
            ...entityProperty,
            isAnonymized: propertyStatus.isAnonymized,
            value: propertyStatus.value,
          };
        }

        return entityProperty;
      })
    : entityPropertiesContainingGroupNameKeys;

  const filters = [
    {
      label: (
        <FormattedMessage id="module-entityProperties-allPropertyGroups" />
      ),
      filter: (
        <EntityPropertiesSourceDropdownFilter
          data-test="entity-entityProperties-sourceDropdownFilter"
          groupNameFilterState={groupNameFilterState}
          setGroupNameFilterState={setGroupNameFilterState}
          propertyGroups={propertyGroups}
        />
      ),
      show: !false,
    },
    {
      label: <FormattedMessage id="module-entityProperties-allIntegrations" />,
      filter: (
        <EntityPropertiesDropdownFilter
          data-test="entity-entityProperties-integrationDropdownFilter"
          connectors={uniqueConnectors}
          connectorId={connectorId}
          setConnectorId={setConnectorId}
        />
      ),
      show: !false,
    },
  ];

  let numberOfActiveFilters = 0;

  if (connectorId) {
    numberOfActiveFilters += 1;
  }
  if (groupNameFilterState) {
    numberOfActiveFilters += 1;
  }

  const resetLabel = (
    <span data-test="entity-entityProperties-resetFilterButton">
      {numberOfActiveFilters === 0 ? (
        <FormattedMessage id="module-entityProperties-resetAllFilters" />
      ) : (
        <>
          <FormattedMessage
            id="module-entityProperties-resetActiveFiltersCount"
            values={{ numberOfActiveFilters }}
          />
          {numberOfActiveFilters > 1 ? (
            <FormattedMessage id="module-entityProperties-resetActiveFiltersPlural" />
          ) : (
            ''
          )}
        </>
      )}
    </span>
  );

  const [
    pagedEntityProperties,
    totalEntityProperties,
    itemsPerPage,
    pageNumber,
    setPageNumber,
  ] = useInMemoryPaging(
    pagedEntityPropertiesWithAnonymizedValues,
    history,
    location,
    20,
    'propertiesPaging',
  );

  const dataHasObsoleteKeys =
    pagedEntityProperties?.filter((i) => i?.vocabularyKey?.isObsolete)?.length >
    0;
  const hasFilters = connectorId || groupNameFilterState || searchName;

  return (
    <>
      <List
        searchName={searchName}
        hasFilters={hasFilters}
        filterToolbar={
          <DefaultFilterToolbar
            resetAllFilterLabel={resetLabel}
            filters={filters}
            onResetAllFilters={() => {
              reset();
            }}
          />
        }
        offsetTop={161}
        loading={loading}
        selectable={false}
        data={pagedEntityProperties}
        itemsPerPage={itemsPerPage}
        selectedPage={pageNumber}
        onPageChange={setPageNumber}
        total={totalEntityProperties}
        onChangeSearch={setSearchName}
        onSubmitSearch={onSubmitSearch}
        propertyKey="property"
        notFoundProps={{
          message: (
            <FormattedMessage id="module-entityProperties-propertiesNotFound" />
          ),
          explanation: (
            <FormattedMessage id="module-entityProperties-propertiesSearchTerms" />
          ),
          noCreate: true,
        }}
        noSearchFoundProps={{
          message: (
            <FormattedMessage id="module-entityProperties-propertiesSearchNotFound" />
          ),
          explanation: (
            <FormattedMessage id="module-entityProperties-propertiesSearchNotFoundSearchTerms" />
          ),
        }}
        columns={[
          {
            displayName: (
              <FormattedMessage id="module-entityProperties-tableProperty" />
            ),
            Component: (props) => (
              <EntityListProperty
                {...props}
                dataHasObsoleteKeys={dataHasObsoleteKeys}
              />
            ),
          },
          {
            displayName: (
              <FormattedMessage id="module-entityProperties-tableValue" />
            ),
            Component: (props) => <EntityListValue {...props} />,
          },
          {
            displayName: (
              <FormattedMessage id="module-entityProperties-tableSource" />
            ),
            property: 'connector',
            Component: (props) => (
              <EntityPropertiesDropdown
                filteredHistoryChanges={filteredHistoryChanges}
                onEntityAnonymizeProperty={({
                  entityVocabularyKey,
                  value,
                  isAnonymized,
                }) => {
                  if (isAnonymized) {
                    if (!anonymizeLoading) {
                      deanonymize(entityVocabularyKey, value);
                    }
                  } else {
                    if (!anonymizeLoading) {
                      anonymize(entityVocabularyKey, value);
                    }
                  }
                }}
                entityId={entityId}
                refetch={refetch}
                historyRefetch={historyRefetch}
                historyLoading={historyLoading}
                {...props}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default EntityPropertiesList;

EntityPropertiesList.propTypes = {
  connectorId: PropTypes.string,
  setConnectorId: PropTypes.func,
  entityId: PropTypes.string,
  entityProperties: PropTypes.array,
  filteredEntityProperties: PropTypes.array,
  filteredHistoryChanges: PropTypes.array,
  loading: PropTypes.bool,
  propertyGroups: PropTypes.array,
  groupNameFilterState: PropTypes.string,
  setGroupNameFilterState: PropTypes.func,
  refetch: PropTypes.func,
};

EntityPropertiesList.defaultProps = {
  connectorId: null,
  setConnectorId: null,
  entityId: null,
  entityProperties: [],
  filteredEntityProperties: [],
  filteredHistoryChanges: [],
  loading: null,
  propertyGroups: [],
  groupNameFilterState: null,
  setGroupNameFilterState: null,
  refetch: null,
};
