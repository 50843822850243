const findById = (id) => (conf) => conf.Id === id;

const filterById = (id) => (conf) => conf.Id !== id;

const markAsDeleteForId = (id) => (conf) => {
  const newConf = conf;
  if (conf.Id === id) {
    newConf.isDeleted = true;
    return newConf;
  }

  return newConf;
};

export const mutateAppropriateConfigurationForPlay = (state, id) => {
  const {
    selectedConfiguration,
    allActiveConfigurations,
    allInactiveConfigurations,
    allUnapprovedConfigurations,
  } = state;
  const result = {};

  if (selectedConfiguration && selectedConfiguration.Id === id) {
    selectedConfiguration.Active = true;
    result.selectedConfiguration = { ...selectedConfiguration };
  }

  const isInAllActiveConfigurations = allActiveConfigurations.find(
    findById(id),
  );
  const isInInactiveConfigurations = allInactiveConfigurations.find(
    findById(id),
  );
  const isInUnapprovedConfigurations = allUnapprovedConfigurations.find(
    findById(id),
  );

  if (isInAllActiveConfigurations) {
    isInAllActiveConfigurations.Active = true;
    result.allActiveConfigurations = [...allActiveConfigurations];
  }
  if (isInInactiveConfigurations) {
    isInInactiveConfigurations.Active = true;
    result.allInactiveConfigurations = [...allInactiveConfigurations];
  }
  if (isInUnapprovedConfigurations) {
    isInUnapprovedConfigurations.Active = true;
    result.allUnapprovedConfigurations = [...allUnapprovedConfigurations];
  }

  return result;
};

export const mutateAppropriateConfigurationForPause = (state, id) => {
  const {
    selectedConfiguration,
    allActiveConfigurations,
    allInactiveConfigurations,
    allUnapprovedConfigurations,
  } = state;
  const result = {};

  if (selectedConfiguration && selectedConfiguration.Id === id) {
    selectedConfiguration.Active = false;
    result.selectedConfiguration = { ...selectedConfiguration };
  }

  const isInAllActiveConfigurations = allActiveConfigurations.find(
    findById(id),
  );
  const isInInactiveConfigurations = allInactiveConfigurations.find(
    findById(id),
  );
  const isInUnapprovedConfigurations = allUnapprovedConfigurations.find(
    findById(id),
  );

  if (isInAllActiveConfigurations) {
    isInAllActiveConfigurations.Active = false;
    result.allActiveConfigurations = [...allActiveConfigurations];
  }
  if (isInInactiveConfigurations) {
    isInInactiveConfigurations.Active = false;
    result.allInactiveConfigurations = [...allInactiveConfigurations];
  }
  if (isInUnapprovedConfigurations) {
    isInUnapprovedConfigurations.Active = false;
    result.allUnapprovedConfigurations = [...allUnapprovedConfigurations];
  }

  return result;
};

export const mutateAppropriateConfigurationForApprove = (state, id) => {
  const {
    selectedConfiguration,
    allActiveConfigurations,
    allUnapprovedConfigurations,
  } = state;
  const result = {};

  if (selectedConfiguration && selectedConfiguration.Id === id) {
    selectedConfiguration.isUnApproved = false;
    result.selectedConfiguration = { ...selectedConfiguration };
  }

  const foundInUnapproved = allUnapprovedConfigurations.find(findById(id));

  if (foundInUnapproved) {
    foundInUnapproved.isUnApproved = false;
  }

  result.allActiveConfigurations = [
    ...allActiveConfigurations,
    foundInUnapproved,
  ];
  result.allUnapprovedConfigurations = allUnapprovedConfigurations.filter(
    filterById(id),
  );

  return result;
};

export const mutateAppropriateConfigurationForReject = (state, id) => {
  const {
    selectedConfiguration,
    allActiveConfigurations,
    allInactiveConfigurations,
    allUnapprovedConfigurations,
  } = state;
  const result = {};

  if (selectedConfiguration && selectedConfiguration.Id === id) {
    selectedConfiguration.isDeleted = true;
    result.selectedConfiguration = { ...selectedConfiguration };
  }

  result.allActiveConfigurations = allActiveConfigurations.map(
    markAsDeleteForId(id),
  );
  result.allInactiveConfigurations = allInactiveConfigurations.map(
    markAsDeleteForId(id),
  );
  result.allUnapprovedConfigurations = allUnapprovedConfigurations.map(
    markAsDeleteForId(id),
  );

  return result;
};
