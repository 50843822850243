import React, { memo } from 'react';
import styled from 'styled-components';
import { Button } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';

const Btn = styled(Button)`
  padding: 0;
  width: 48px;
  height: 100%;
  border: none;
  min-width: 0;
  border-radius: 0;
  box-shadow: none !important;
  background: ${({ theme, isActive }) =>
    isActive ? theme.palette.themeDarkAlt : 'transparent'};

  &:hover {
    background: ${({ theme }) => theme.palette.themeDarkAlt};
  }
`;

export const TopNavButton = memo(({ children, isActive, ...rest }) => {
  const theme = useTheme();

  return (
    <Btn {...rest} theme={theme} isActive={isActive}>
      {children}
    </Btn>
  );
});
