"use strict";

exports.__esModule = true;
exports.governance = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var GOVERNANCE_CONSENT_CONSULTANT = _claimTypes.default.GOVERNANCE_CONSENT_CONSULTANT,
  GOVERNANCE_CONSENT_INFORMED = _claimTypes.default.GOVERNANCE_CONSENT_INFORMED,
  GOVERNANCE_DATABREACH_CONSULTANT = _claimTypes.default.GOVERNANCE_DATABREACH_CONSULTANT,
  GOVERNANCE_DATABREACH_INFORMED = _claimTypes.default.GOVERNANCE_DATABREACH_INFORMED,
  GOVERNANCE_DATARETENTION_CONSULTANT = _claimTypes.default.GOVERNANCE_DATARETENTION_CONSULTANT,
  GOVERNANCE_DATARETENTION_INFORMED = _claimTypes.default.GOVERNANCE_DATARETENTION_INFORMED,
  GOVERNANCE_METRICS_INFORMED = _claimTypes.default.GOVERNANCE_METRICS_INFORMED,
  GOVERNANCE_SUBJECTACCESSREQUEST_CONSULTANT = _claimTypes.default.GOVERNANCE_SUBJECTACCESSREQUEST_CONSULTANT,
  GOVERNANCE_SUBJECTACCESSREQUEST_INFORMED = _claimTypes.default.GOVERNANCE_SUBJECTACCESSREQUEST_INFORMED,
  GOVERNANCE_PERSONALIDENTIFIERS_CONSULTANT = _claimTypes.default.GOVERNANCE_PERSONALIDENTIFIERS_CONSULTANT,
  GOVERNANCE_PERSONALIDENTIFIERS_INFORMED = _claimTypes.default.GOVERNANCE_PERSONALIDENTIFIERS_INFORMED;
var governance = {
  consent: {
    forms: {
      create: {
        claims: GOVERNANCE_CONSENT_CONSULTANT
      },
      view: {
        claims: GOVERNANCE_CONSENT_INFORMED
      }
    },
    configuration: {
      create: {
        claims: GOVERNANCE_CONSENT_CONSULTANT
      },
      edit: {
        claims: GOVERNANCE_CONSENT_CONSULTANT
      },
      view: {
        claims: GOVERNANCE_CONSENT_INFORMED
      }
    }
  },
  dataBreach: {
    report: {
      create: {
        claims: GOVERNANCE_DATABREACH_CONSULTANT
      },
      view: {
        claims: GOVERNANCE_DATABREACH_INFORMED
      }
    }
  },
  dataRetention: {
    policy: {
      create: {
        claims: GOVERNANCE_DATARETENTION_CONSULTANT
      },
      delete: {
        claims: GOVERNANCE_DATARETENTION_CONSULTANT
      },
      view: {
        claims: GOVERNANCE_DATARETENTION_INFORMED
      }
    }
  },
  gdpr: {
    subjectAccessRequest: {
      create: {
        claims: GOVERNANCE_SUBJECTACCESSREQUEST_CONSULTANT
      },
      delete: {
        claims: GOVERNANCE_SUBJECTACCESSREQUEST_CONSULTANT
      },
      edit: {
        claims: GOVERNANCE_SUBJECTACCESSREQUEST_CONSULTANT
      },
      view: {
        claims: GOVERNANCE_SUBJECTACCESSREQUEST_INFORMED
      }
    },
    personalidentifiers: {
      edit: {
        claims: GOVERNANCE_PERSONALIDENTIFIERS_CONSULTANT
      },
      view: {
        claims: GOVERNANCE_PERSONALIDENTIFIERS_INFORMED
      }
    }
  },
  metrics: {
    metric: {
      view: {
        claims: GOVERNANCE_METRICS_INFORMED
      }
    }
  }
};
exports.governance = governance;