import { useContext } from 'react';

import {
  GoldenRecordListContext,
  GoldenRecordListContextType,
} from './GoldenRecordListContext';

export const useGoldenRecordList = (): GoldenRecordListContextType => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const context = useContext(GoldenRecordListContext)!;

  if (context === undefined) {
    throw new Error(
      'useGoldenRecordList must be used within a GoldenRecordListContext',
    );
  }

  return context;
};
