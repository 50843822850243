import { handleActions } from 'redux-actions';
import { shouldFetchSignUpActions } from '../actions/signup';

const initialState = {
  isFetchingSignup: null,
  done: false,
  errorSignup: null,
  alreadyTaken: false,
  isFetchingInvitation: false,
  invalidInvitation: false,
  invitation: null,
};

export default handleActions(
  {
    [shouldFetchSignUpActions.request]: (state) => ({
      ...state,
      isFetchingSignup: true,
      errorSignup: false,
      alreadyTaken: false,
      done: false,
    }),
    [shouldFetchSignUpActions.receive]: (state) => ({
      ...state,
      isFetchingSignup: false,
      done: true,
      errorSignup: false,
      alreadyTaken: false,
    }),
    [shouldFetchSignUpActions.invalid]: (state) => ({
      ...state,
      isFetchingSignup: false,
      errorSignup: true,
      alreadyTaken: false,
      done: false,
    }),
  },
  initialState,
);
