import React, { memo } from 'react';
import { Select } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import RuleItemPreview from './RuleItemPreview';

export const RuleItemObjectTypeIdSelector = memo(
  ({
    ruleItem,
    ruleItemIndex,
    objectTypes = [],
    onObjectTypeChange,
    objectTypeIdHasError,
    previewMode,
    selectedObjectTypeName,
  }) => {
    const objectTypeId = ruleItem.objectTypeId;

    const options = objectTypes.map((i) => ({
      value: i.id,
      label: i.name,
      'data-test': `property-type-${i.name}`,
    }));

    const handleChangeObjectTypeId = ({ value }) => {
      onObjectTypeChange(value, ruleItemIndex);
    };

    return (
      <div style={{ marginRight: '6px', minWidth: '200px' }}>
        {previewMode ? (
          <RuleItemPreview text={selectedObjectTypeName} />
        ) : (
          <Select
            options={options}
            value={objectTypeId}
            hasError={objectTypeIdHasError}
            onChange={handleChangeObjectTypeId}
            placeholder={
              <FormattedMessage id="module-rule-selectPropertyType" />
            }
          />
        )}
      </div>
    );
  },
);
