import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import routes from './module.routes';

export default {
  pillar: 'administration',
  name: 'userInvitation',
  displayName: <FormattedMessage id="userInvitation" />,
  path: '/user-invitations',
  hideFromMenu: true,
  extendPillarDashboard: {
    actions: [],
  },
  routes,
};
