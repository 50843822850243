import styled, { keyframes } from 'styled-components';
import {
  /* pulseName, */ pulseEasing,
  pulseDuration,
  pulseKeyframesConfig,
} from '../../../../core/components/composites/Placeholder/pulseKeyframes';

export const pulseName = keyframes`
  ${Object.keys(pulseKeyframesConfig).map(
    (key) => `${key} { opacity: ${pulseKeyframesConfig[key]};}`,
  )};
`;

// export const pulseName = keyframes`
//   0% { opacity: 0.32; }
//   50% { opacity: 0.72; }
//   100% { opacity: 0.32; }
// `;

const IconPlaceholder = styled.div`
  width: 46px;
  border-radius: 46px;
  height: 46px;
  ${({ size }) =>
    size
      ? `width: ${size}px; height: ${size}px; border-radius: ${size}px;`
      : ''};
  background: #ccc;
  background: ${({ color }) => `${color || '#ccc'}`};
  animation: ${pulseName} ${pulseDuration} ${pulseEasing} infinite;
  ${({ centered }) =>
    centered ? 'margin-left :auto; margin-right: auto;' : ''};
`;

export default IconPlaceholder;
