/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { RouteComponentProps } from 'react-router-dom';
import curry from 'lodash/curry';
import { unicodeEncodeGqlCursor } from '../helpers/encoding';

const ITEMS_PER_PAGE_NAME = 'itemsPerPage';
const PAGE_NO_NAME = 'pageNo';

const getCursor = (pageInfo: PageInfo) => {
  const cursorObj = {
    pageSize: pageInfo.itemsPerPage,
    page: pageInfo.selectedPage - 1,
  };

  return unicodeEncodeGqlCursor(JSON.stringify(cursorObj));
};

const getValuesFromLocation = (
  { search }: RouteComponentProps['location'],
  itemsPerPageName: string,
  pageNoName: string,
) => {
  const query = new URLSearchParams(search);
  return {
    urlItemsPerPage: parseInt(query.get(itemsPerPageName)!, 10),
    urlSelectedPage: parseInt(query.get(pageNoName)!, 10),
  };
};

const setPageInUrl = curry(
  (
    history: RouteComponentProps['history'],
    itemsPerPageName: string,
    itemsPerPage: string,
    pageNoName: string,
    pageNo: number,
    replaceLocation = false,
  ) => {
    const { pathname, search, hash, state } = history.location;
    const query = new URLSearchParams(search);
    query.set(itemsPerPageName, itemsPerPage);
    query.set(pageNoName, String(pageNo));

    const newLocation = {
      pathname,
      hash,
      search: `?${query.toString()}`,
      state,
    };

    if (replaceLocation) {
      history.replace(newLocation);
    } else {
      history.push(newLocation);
    }
  },
);

const setItemsPerPage = curry(
  (
    history: RouteComponentProps['history'],
    itemsPerPageName: string,
    n: number,
  ) => {
    const { pathname, search, hash, state } = history.location;
    const query = new URLSearchParams(search);
    query.set(itemsPerPageName, n.toString());

    const newLocation = {
      pathname,
      hash,
      search: `?${query.toString()}`,
      state,
    };

    history.push(newLocation);
  },
);

type PageInfo = {
  selectedPage: number;
  itemsPerPage: number;
};

export const usePaging = (
  history: RouteComponentProps['history'],
  location: RouteComponentProps['location'],
  defaultItemsPerPage = 10,
  pagingId = '',
) => {
  const itemsPerPageName = pagingId
    ? `${pagingId}-${ITEMS_PER_PAGE_NAME}`
    : ITEMS_PER_PAGE_NAME;
  const pageNoName = pagingId ? `${pagingId}-${PAGE_NO_NAME}` : PAGE_NO_NAME;
  const { urlItemsPerPage, urlSelectedPage } = getValuesFromLocation(
    location,
    itemsPerPageName,
    pageNoName,
  );
  const itemsPerPage = urlItemsPerPage || defaultItemsPerPage;
  const selectedPage = urlSelectedPage || 1;
  const currentPage: PageInfo = { selectedPage, itemsPerPage };
  const cursor = getCursor(currentPage);

  const setSelectedPage: (pageNo: number, reverse?: boolean) => void =
    setPageInUrl(history, itemsPerPageName, String(itemsPerPage), pageNoName);

  const setItemsPerPageFn: (n: number) => void = setItemsPerPage(
    history,
    itemsPerPageName,
  );

  if (!urlItemsPerPage || !urlItemsPerPage) {
    setSelectedPage(selectedPage, true);
  }

  return [cursor, currentPage, setSelectedPage, setItemsPerPageFn] as const;
};
