import { memo, useMemo } from 'react';
import { Loader } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import { RuleCondition } from './ruleCondition';

import { useQueryCurrentOrganization } from '../../../security/hooks/useCurrentOrganziation';
import { useQueryConfigurationSettings } from '../../../security/hooks/useConfigurationSettings';

import { RuleItemPropertySelector } from './forms/RuleItemPropertySelector';
import { RuleVocabularyKeySelectorContainer } from './forms/RuleVocabularyKeySelectorContainer';
import { RuleVocabularyValueSelectOrCreate } from './forms/RuleVocabularyValueSelectOrCreate';

import { useQueryRuleObjectTypes } from '../../hooks/useQueryRuleObjectTypes';
import { useQueryVocabularyKeyByKey } from '../../../dataCatalog/hooks/useQueryVocabularyKeyByKey';

import { adaptRules } from '../../utils/ruleAdapters';

export const RuleConditionContainer = ({
  onChange,
  operators,
  conditions,
  context = '',
  loadingOperators,
  previewMode = false,
  scope = 'Processing',
  rulesValidationErrors,
  actionConfiguration = {},
  loadingObjectTypes: loadingObjectTypesFromProps = false,
  addFirstMessage = <FormattedMessage id="module-rule-addFirstRule" />,
}) => {
  const [objectTypes, { loading: loadingObjectTypes }] =
    useQueryRuleObjectTypes(context ? context : undefined);

  const [org] = useQueryCurrentOrganization();
  const [orgSettingsData] = useQueryConfigurationSettings(org.Id, true);

  const hasStrongTypingFeature = useMemo(
    () =>
      orgSettingsData?.['Feature.Search.StrongTyped']?.toLowerCase() === 'true',
    [orgSettingsData],
  );

  return (
    <>
      {(loadingObjectTypes ||
        loadingOperators ||
        loadingObjectTypesFromProps) && (
        <div style={{ display: 'flex', marginTop: '12px' }}>
          <Loader />
        </div>
      )}

      {!loadingObjectTypes &&
        !loadingObjectTypesFromProps &&
        !loadingOperators && (
          <RuleCondition
            scope={scope}
            rule={conditions}
            onChange={onChange}
            operators={operators}
            adaptRules={adaptRules}
            objectTypes={objectTypes}
            errors={rulesValidationErrors}
            addFirstMessage={addFirstMessage}
            actionConfiguration={actionConfiguration}
            hasStrongTypingFeature={hasStrongTypingFeature}
            PropertyItemAutoComplete={RuleItemPropertySelector}
            useQueryVocabularyKeyByKey={useQueryVocabularyKeyByKey}
            VocabularyAutoComplete={RuleVocabularyKeySelectorContainer}
            VocabularyValueAutoComplete={RuleVocabularyValueSelectOrCreate}
            previewMode={previewMode}
          />
        )}
    </>
  );
};

export default memo(RuleConditionContainer);
