"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(Collapse) {
  return (0, _styledComponents.default)(Collapse)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  height: 0;\n  overflow: hidden;\n  transition: ", " &.horizontal {\n    height: auto;\n    width: 0;\n    transition: ", ";\n  }\n  &.entered {\n    height: auto;\n    overflow: visible;\n    &.horizontal {\n      width: auto;\n    }\n  }\n  &.hidden {\n    visibility: hidden;\n  }\n  &.wrapper {\n    // Hack to get children with a negative margin to not falsify the height computation.\n    display: flex;\n    width: 100%;\n    &.horizontal {\n      height: 100%;\n      width: auto;\n    }\n  }\n  .wrapperInner {\n    width: 100%;\n    &.horizontal {\n      width: auto;\n      height: 100%;\n    }\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.transitions.create('height');
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.transitions.create('width');
  });
};
exports.default = _default;