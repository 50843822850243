import styled from 'styled-components';

export const ToasterWrapper = styled.div`
  display: flex;
  position: relative;
  padding: ${({ showClose }: { showClose: boolean }) =>
    showClose ? '6px' : 0};
`;

export const ToasterInfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

export const ToasterFileInfo = styled.div`
  font-weight: 600;
  flex: 1;
  ${({ textColor }: { textColor: string }) => {
    if (textColor) {
      return `color: ${textColor}`;
    }
    return ``;
  }}
`;

export const ToasterFileProgress = styled.div`
  text-align: left;
  flex: 1;
`;

export const ToasterActionLine = styled.div`
  margin-top: 6px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #9b9b9c;
`;

export const ToasterIconWrapper = styled.div`
  padding-left: 6px;
`;

export const FileIcon = styled.div`
  padding-left: 6px;
`;
