import React, { useEffect } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { get } from 'lodash';
import { StarIcon } from '@cluedin/svgs';
import { Database } from 'uxi/Icons';
import { GqlErrorMessages } from '@cluedin/form';
import { ExplainLogWrapper } from './ExplainLog.styles';
import PageLoader from '../../../../core/components/composites/PageLoader';
import { useCreateExplainLogPerEntityId } from '../../../hooks/useCreateExplainLogPerEntityId';
import { useQueryExplainLogEntityById } from '../../../hooks/useExplainLogEntityById';
import ExplainLogIdentifier from './ExplainLogIdentifier/ExplainLogIdentifier';

const ExplainLogContainer = ({ entityId, removeMargin }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [createExplainLog, { data, error, loading }] =
    useCreateExplainLogPerEntityId(entityId);
  const [entityData, entityLoading, entityError] =
    useQueryExplainLogEntityById(entityId);

  useEffect(() => {
    createExplainLog();
  }, []);

  const scopes = get(data, 'scopes');
  const entityType = get(entityData, 'entity.processedData.entityType');
  const attributeOrigin = get(
    entityData,
    'entity.processedData.attribute-origin',
  );
  const details = get(entityData, 'entity.details.data');

  const goldenRecords = scopes &&
    scopes.length > 0 && [
      scopes.filter(
        (gRecord) =>
          gRecord.identifier.details.kind === 'EntityMetadataProcessing',
      ),
    ];
  const dataParts = scopes &&
    scopes.length > 0 && [
      scopes.filter(
        (dPart) =>
          dPart.identifier.details.kind === 'DataPartMetadataProcessing',
      ),
    ];

  if (error) {
    return <GqlErrorMessages error={error} />;
  }
  if (loading) {
    return <PageLoader />;
  }

  return (
    <ExplainLogWrapper removeMargin={removeMargin}>
      {goldenRecords && (goldenRecords || []) && (
        <ExplainLogIdentifier
          attributeOrigin={attributeOrigin}
          color="#f2c230"
          data={goldenRecords[0]}
          entityType={entityType}
          hasSubtitle
          icon={<StarIcon />}
          isGoldenRecord
          entityId={entityId}
          labels={{
            identifierTitle: (
              <FormattedMessage id="module-explainLog-goldenRecord" />
            ),
          }}
          size={20}
        />
      )}

      {dataParts && (dataParts || []) && (
        <ExplainLogIdentifier
          color={themePrimary}
          data={dataParts[0]}
          details={details}
          icon={<Database />}
          entityId={entityId}
          labels={{
            identifierTitle: (
              <FormattedMessage id="module-explainLog-records" />
            ),
          }}
          size={20}
        />
      )}
    </ExplainLogWrapper>
  );
};

export default ExplainLogContainer;
