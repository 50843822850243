import React, { Component } from 'react';
import { Checkbox } from '@cluedin/form';

const ListStyle = {
  titleContainer: {
    display: 'flex',
    cursor: 'pointer',
    padding: '5px 0',
  },
  titleIcon: {
    width: '30px',
  },
  title: {
    flex: 1,
  },
  titleH5: {
    margin: '5px',
  },
};

export default class CheckboxList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      isChecked: props.isChecked || false,
    };
  }

  select(isChecked, item) {
    const { onChange, value, options } = this.props;

    const currentItemIndex = value.indexOf(item.original);
    value[currentItemIndex][options.stateField || 'status'] = isChecked
      ? 'ACTIVE'
      : 'INACTIVE';

    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const mainStyle = {};
    const { value, options, className } = this.props;

    let key = 'value';
    let name = 'name';

    if (options) {
      if (options.displayName) {
        name = options.displayName;
      }
      if (options.key) {
        key = options.key;
      }
    }

    const content = (value || [])
      .map((i) => ({
        name: i[name],
        value: i[key],
        description: i.Description || i.description,
        original: i,
        isChecked: i[options.stateField || 'status'] === 'ACTIVE',
      }))
      .map((i, index) => (
        <div key={index}>
          <div style={ListStyle.titleContainer}>
            <Checkbox
              defaultChecked={i.isChecked}
              onChange={(e, isChecked) => {
                this.select(isChecked, i);
              }}
              label={
                <div style={{ paddingLeft: '15px' }}>
                  {i.name}
                  <br />
                  {i.description ? i.description : ''}
                </div>
              }
            />
          </div>
        </div>
      ));

    return (
      <div className={className} style={mainStyle}>
        {content}
      </div>
    );
  }
}
