import React, { Fragment } from 'react';
import { SimpleGrid } from 'uxi/Layout';
import EntityTileViewItem from './EntityTileViewItem';
import EntityTileViewItemGhostLoading from './EntityTileViewItemGhostLoading';
import { withEntitySelectable } from '../../../entityMerge/components/Hocs/withEntitySelectable';
import EmptySearch from './EmptySearch';

const EntityTileViewItemWithSelection = withEntitySelectable(
  EntityTileViewItem,
  'searchResult',
);

const EntityTileView = ({ data = [], isFetchingMainSearch, properties }) => {
  let finalResult = null;

  if (isFetchingMainSearch) {
    finalResult = [...Array(12)].map((_, i) => (
      <EntityTileViewItemGhostLoading noBorderTop={i === 0 || undefined} />
    ));
  } else if (!isFetchingMainSearch && !data.length) {
    finalResult = <EmptySearch />;
  } else if (!isFetchingMainSearch && data.length) {
    finalResult = data.map((searchResult) => (
      <EntityTileViewItemWithSelection
        noBorderTop
        key={`searchResult-${searchResult.entityId}`}
        searchResult={searchResult}
        isFetchingMainSearch={isFetchingMainSearch}
        properties={properties}
      />
    ));
  }

  return (
    <Fragment>
      <SimpleGrid>{finalResult}</SimpleGrid>
    </Fragment>
  );
};

export default EntityTileView;
