import { useEffect } from 'react';
import { List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';

import PageLoader from '../../../../../core/components/composites/PageLoader';
import { columns } from './columns';
import VocabularySource from '../../../../../dataCatalog/components/composites/VocabularySource';
import { useGetEntityByDataPartProperties } from '../../../../hooks/useGetEntityByDataPartProperties';

const RecordPanelList = ({
  properties,
  setIsListData,
  isListData,
  offsetTop,
  isDeduplication,
}) => {
  const [entityData, entityLoading, entityError] =
    useGetEntityByDataPartProperties(properties);

  useEffect(() => {
    if (!!entityData?.length && !entityLoading) {
      setIsListData(true);
    }
  }, [entityData, entityLoading]);

  return (
    <>
      {entityLoading ? (
        <PageLoader />
      ) : entityData?.length > 0 ? (
        <List
          offsetTop={offsetTop}
          hideExpanding
          noSearch
          loading={entityLoading}
          error={entityError}
          columns={[
            ...columns,
            {
              property: 'source',
              displayName: (
                <FormattedMessage id="module-entityTopology-source" />
              ),
              Component: ({ vocabularyKey }) => (
                <VocabularySource
                  {...vocabularyKey}
                  connector={vocabularyKey?.vocabulary?.connector}
                />
              ),
            },
          ]}
          notFoundProps={{
            message: (
              <FormattedMessage id="module-entityTopology-splitPanelNotFoundMessage" />
            ),
            explanation: (
              <FormattedMessage id="module-entityTopology-splitPanelNotFoundExplanation" />
            ),
            noCreate: true,
          }}
          data={entityData}
          selectable={false}
          showPagination={false}
        />
      ) : (
        !isListData &&
        !entityLoading && (
          <Alert type="info">
            {isDeduplication ? (
              <FormattedMessage id="module-entityTopology-panelDeduplicationNoProperties" />
            ) : (
              <FormattedMessage id="module-entityTopology-panelShadowEntity" />
            )}
          </Alert>
        )
      )}
    </>
  );
};

export default RecordPanelList;
