import React from 'react';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import { Button } from '@cluedin/form';
import Alert from 'uxi/Alert';
import PersonalIdentifiers from '../PersonalIdentifiers';

const AskRightDialog = ({
  show,
  feature,
  onClose,
  personalIdentifiers,
  onRequest,
  isSendingRequest,
  invalidRequest,
  isSendingRequestDone,
}) => (
  <Dialog show={show} onClose={onClose}>
    <Panel onClose={onClose} style={{ maxHeight: '80vh' }}>
      <PanelHeader
        onClose={onClose}
        title={feature ? feature.title : ''}
        hasClose
      />
      <PanelContent style={{ padding: '30px' }}>
        {isSendingRequestDone && (
          <Alert style={{ marginBottom: '15px' }} type="success">
            Thank you for your request. We will get back to you soon. In case
            you do not hear from us after 5 days, please do not hesitate to
            contact us.
          </Alert>
        )}
        {invalidRequest && (
          <Alert style={{ marginBottom: '15px' }} type="danger">
            Something unexpected happen. Please contact-us to proceed wiht our
            request.
          </Alert>
        )}
        <div style={{ paddingBottom: '15px' }}>
          We will send the following information to our DPO to full-fill the
          request.
        </div>
        <div style={{ paddingBottom: '15px' }}>
          <strong>Personal Identifiers List</strong>
        </div>
        <PersonalIdentifiers personalIdentifiers={personalIdentifiers} />
      </PanelContent>
    </Panel>
    <PanelFooter onClose={onClose} hasCancel>
      <Button
        onClick={onRequest}
        text="Send Request"
        disabled={isSendingRequest || isSendingRequestDone}
      />
    </PanelFooter>
  </Dialog>
);

export default AskRightDialog;
