import { DataWrapper, Title } from './styled';

const SimplePropertyWrapper = ({ propertyName = '', text = '' }) => {
  return (
    <DataWrapper>
      <Title>{propertyName}</Title>
      <span>{text}</span>
    </DataWrapper>
  );
};

export default SimplePropertyWrapper;
