import { useState } from 'react';

import { injectIntl } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { Arrowright } from 'uxi/Icons';

import { useGetEntityDataById } from '../../../../entityProperties/hooks/getEntityById';
import { CollapseElement } from '../../../../core/components/composites/CollapseElement';

import {
  BaseWrapper,
  ButtonWrapper,
  ArrowWrapper,
  ListWrapper,
} from './styled';
import formatData from './formatData';

const EntityPropertyWidget = ({ intl, entity }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const [isOpened, setOpened] = useState(false);
  const [{ entity: entityProperty }] = useGetEntityDataById(entity.id);
  const propertiesToRender = formatData(entityProperty || {}, intl);

  return (
    <BaseWrapper isFirst>
      <ButtonWrapper onClick={() => setOpened((p) => !p)}>
        <ArrowWrapper isOpened={isOpened}>
          <Arrowright size={16} color={themePrimary} />
        </ArrowWrapper>
        <span style={{ fontSize: '16px', fontWeight: '600' }}>
          {intl.formatMessage({ id: 'module-entityWidget-entityProperties' })}
        </span>
      </ButtonWrapper>
      <CollapseElement isVisible={isOpened}>
        <ListWrapper>
          {Object.values(propertiesToRender).map(({ name, component }) => {
            return <div key={name}>{component}</div>;
          })}
        </ListWrapper>
      </CollapseElement>
    </BaseWrapper>
  );
};

export default injectIntl(EntityPropertyWidget);
