import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Button } from '@cluedin/form';
import VocabType from '../../../dataModeling/components/composites/VocabType';

const SchemaLineSelect = ({ schema, onSchemaSelect, disabled }) => {
  return (
    <div
      style={{
        borderTop: '1px solid #ccc',
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: '16px', marginLeft: '4px' }}>
          {schema.DisplayName}
        </div>
        <div style={{ margin: '4px 0' }}>
          <VocabType value={schema.IsCore} />
        </div>
        <div
          style={{
            color: '#999999',
            fontSize: '12px',
            marginLeft: '4px',
          }}
        >
          {schema.Key} - {schema.DataType}
        </div>
      </div>
      <div>
        <Button
          disabled={disabled}
          text={<FormattedMessage id="module-schema-select" />}
          onClick={() => {
            onSchemaSelect(schema);
          }}
        />
      </div>
    </div>
  );
};

export default SchemaLineSelect;
