"use strict";

exports.__esModule = true;
exports.useSearch = void 0;
var _omit = _interopRequireDefault(require("lodash/omit"));
var _hooks = require("../../../hooks");
var _useResetPageNo = require("./useResetPageNo");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useSearch = function useSearch(history, location, options) {
  if (options === void 0) {
    options = {};
  }
  var queryStringOptions = (0, _omit.default)(options, ['pageNoLabel', 'searchName']);
  var _options = options,
    _options$pageNoLabel = _options.pageNoLabel,
    pageNoLabel = _options$pageNoLabel === void 0 ? 'pageNo' : _options$pageNoLabel,
    _options$searchName = _options.searchName,
    searchName = _options$searchName === void 0 ? 'searchName' : _options$searchName;
  var _useQueryString = (0, _hooks.useQueryString)(history, location, searchName, queryStringOptions),
    currentValue = _useQueryString[0],
    setValue = _useQueryString[1],
    removeValue = _useQueryString[2];
  var resetPageNo = (0, _useResetPageNo.useResetPageNo)(history, location, pageNoLabel);
  var setSearch = function setSearch(value) {
    if (value === currentValue) {
      return;
    }
    if (!value) {
      removeValue();
    } else {
      setValue(value);
    }
    resetPageNo();
  };
  return [currentValue, setSearch];
};
exports.useSearch = useSearch;