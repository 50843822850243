import React from 'react';
import Img from 'uxi/Img';
import { Integration } from 'uxi/Icons';
import { FirstLetterComponent } from '@cluedin/components';
import { withIntegrationConfig } from '../hocs/withIntegrationConfig';

const findSize = (size = '') => {
  if (!size) {
    return '32';
  }

  const lwSize = size.toLocaleLowerCase();

  if (lwSize === 'xs') {
    return '16';
  }

  if (lwSize === 's') {
    return '24';
  }

  if (lwSize === 'l') {
    return '48';
  }

  if (lwSize === 'xl') {
    return '64';
  }

  return '32';
};

const IntegrationIconContainer = ({
  integrationName = '',
  iconSize,
  style = {},
  roundImage,
  integration,
}) => {
  const width = findSize(iconSize);

  if (!integrationName) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', ...style }}>
        <Integration size={width} />
      </div>
    );
  }

  const iconFromConfig =
    integration && integration.Icon ? integration.Icon : null;

  let imageStyle = {};

  if (roundImage) {
    imageStyle = { borderRadius: '50%' };
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      {iconFromConfig ? (
        <Img
          contain
          async
          width={width}
          src={iconFromConfig}
          style={imageStyle}
        />
      ) : (
        <FirstLetterComponent name={integrationName} size={iconSize} />
      )}
    </div>
  );
};

export default withIntegrationConfig(IntegrationIconContainer);
