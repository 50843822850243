import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useApproveMeshCommand } from '../../../../hooks/useApproveMeshCommand';
import { useRejectMeshCommand } from '../../../../hooks/useRejectMeshCommand';
import { EntityActionMessageItemDetailsWrapper } from './EntityActionMessageItemDetails.styles';
import EntityActionMessageItemsDetailsHeader from '../EntityActionMessageItemsDetailsHeader/EntityActionMessageItemsDetailsHeader';
import EntityActionMessageItemDetailsExpanded from '../EntityActionMessageItemDetailsExpanded/EntityActionMessageItemDetailsExpanded';

const EntityActionMessageItemDetails = ({
  actionType,
  messageForApproval,
  messageId,
  property,
  rawQuery,
  refetchEntityMessages,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [approveMeshCommand, { data, error, loading }] =
    useApproveMeshCommand(messageForApproval);
  const [
    rejectMeshCommand,
    { data: rejectData, error: rejectError, loading: rejectLoading },
  ] = useRejectMeshCommand(messageId);

  useEffect(() => {
    refetchEntityMessages();
  }, [data, rejectData]);

  return (
    <EntityActionMessageItemDetailsWrapper>
      <EntityActionMessageItemsDetailsHeader
        actionType={actionType}
        approveLoading={loading}
        approveMeshCommand={async () => {
          await approveMeshCommand(messageForApproval);
          if (data?.success) {
            await refetchEntityMessages();
          }
        }}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        property={property}
        rejectLoading={rejectLoading}
        rejectMeshCommand={async () => {
          await rejectMeshCommand(messageId);
          if (rejectData?.success) {
            await refetchEntityMessages();
          }
        }}
      />

      {isExpanded && (
        <EntityActionMessageItemDetailsExpanded
          isExpanded={isExpanded}
          rawQuery={rawQuery}
        />
      )}
    </EntityActionMessageItemDetailsWrapper>
  );
};

export default EntityActionMessageItemDetails;

EntityActionMessageItemDetails.propTypes = {
  actionType: PropTypes.string,
  messageForApproval: PropTypes.object,
  messageId: PropTypes.string,
  property: PropTypes.object,
  rawQuery: PropTypes.object,
  refetchEntityMessages: PropTypes.func,
};

EntityActionMessageItemDetails.defaultProps = {
  actionType: '',
  messageForApproval: {},
  messageId: '',
  property: {},
  rawQuery: {},
  refetchEntityMessages: () => {},
};
