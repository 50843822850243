import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';

export const rootUrl = '/admin/testing/expression/';

export default {
  pillar: 'testing',
  name: 'expression',
  displayName: (
    <span className="__testGlobalMenuAnnotation">
      <FormattedMessage id="expression" />
    </span>
  ),
  path: '/expression',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Codefile',
        name: (
          <span className="__testGlobalMenuAnnotation">
            <FormattedMessage id="expression" />
          </span>
        ),
        link: rootUrl,
      },
    ],
  },
  routes,
};
