import { ReactNode } from 'react';
import styled from 'styled-components';
import { Provider, connect } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { UxiBusinessProvider } from 'uxi-business';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter, push } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { UserMessageProvider } from 'uxi-business/userMessage';
import { UserClaimsProvider } from '@cluedin/bootstrap';
import { LocalizationContext } from '@cluedin/locale';

import { getCurrentToken } from '../config';
import cluedInComponentLocalization from './cluedInComponentLocalization';
import App from '../modules/core/components/pages/App';
import { createStore } from '../store';

import LocalizedProvider from '../modules/locale/components/container/LocalizedProvider';
import graphQLClient from '../data/graphql';
import { shouldLogAllOut } from '../modules/auth/actions';
import subdomainChooser, {
  AppOrClientPagePublicChooser,
} from '../modules/core/components/Hocs/SubdomainChooser';
import { onlyAuth } from '../modules/auth/components/Hocs/onlyAuth';
import { onlyNotAuth } from '../modules/auth/components/Hocs/onlyNotAuth';
import ScrollToTop from '../modules/core/components/composites/ScrollToTop';
import NotFoundComponent from '../modules/error/components/composites/NotFound';
import RouteWithSubRoutes, {
  Div,
} from '../modules/core/components/composites/routing/RouteWithSubRoutes';
import EntityRoutesContainer from '../modules/wms/components/containers/EntityRoutesContainer';
import Logout from '../modules/auth/components/pages/Logout';
import LogAllOut from '../modules/auth/components/pages/LogoutAll';
import ExtensionPoint from './ExtensionPoint';
import WidgetRegistry from '../modules/appBuilder/widgets/WidgetRegistry';

import { ThemeProvider } from './ThemeProvider';

import { Store } from 'redux';
import { AppSearchProvider } from '../modules/AppSearch';
import { AppContextProvider } from '../shared/context/appContext';
import { LayoutContextProvider } from '../shared/context/layoutContext';

import { AppReactToastersContainer } from './appReactToastersContainer';

const browserHistory = createBrowserHistory();

const NoShadowWrapper = styled.div`
  & * {
    box-shadow: none !important;
  }
`;

const UserBusinessProvider = ({
  children,
  onSessionExpired,
}: {
  children: ReactNode;
  onSessionExpired: () => void;
}) => (
  <UxiBusinessProvider onSessionExpired={onSessionExpired}>
    <NoShadowWrapper>
      <UserMessageProvider />
    </NoShadowWrapper>
    {children}
  </UxiBusinessProvider>
);

const EnhancedUserBusinessProvider = connect(
  () => ({}),
  (dispatch) => ({
    onSessionExpired: () => {
      dispatch(shouldLogAllOut());
      dispatch(push('/signin'));
    },
  }),
)(UserBusinessProvider);

const token = getCurrentToken();
const WrapperPage = subdomainChooser(token, App);

let store: Store;

/**
 * The reason the getStore is created is that errorLink
 * from GQL needs access to the store
 * in test purpose, the error Link is being imported
 * and errorLink import the store from setupApp
 * as setupApp was creating the store directly,
 * it was failing as it needed in the test much more preparation.
 *
 * By creating a singleton, we avoid if the SetupApp is imported to create
 * an invalid store during test.
 */
export const getStore = () => {
  if (store) {
    return store;
  }

  store = createStore(browserHistory);
  return store;
};

export type SimpleModule = {
  path: string;
  component?: ReactNode;
  skipAuth?: boolean;
  strict?: boolean;
  exact?: boolean;
  claims?: string | unknown;
};

export type Module = SimpleModule & {
  routes?: SimpleModule[];
};

export type Modules = Module[];

type DashboardExtensionsAction = {
  claims: string | unknown;
  icon: string;
  link: string;
  name: string | ReactNode | unknown;
};

type DashboardExtensionsActions = DashboardExtensionsAction[];

type DashboardExtension = {
  section: ReactNode | null;
  actions: DashboardExtensionsActions;
};

type DashboardExtensions = (DashboardExtension | null)[];

type ExtensionRoute = Module & {
  pillar?: string;
  displayName: string | ReactNode | unknown;
  extendPillarDashboard: DashboardExtensionsActions;
};

type ExtensionRoutes = ExtensionRoute[];

type Extension = {
  exact: boolean | undefined;
  actions: never[];
  path: string;
  className?: string;
  hideFromMenu: boolean;
  requiredAdmin: boolean;
  routes: ExtensionRoutes;
  Icon: ReactNode | unknown;
  component: ReactNode | unknown;
  name: string | ReactNode | unknown;
  dashboardExtensions: DashboardExtensions;
  displayName: string | ReactNode | unknown;
};

type Extensions = Extension[];

type Props = {
  modules: Modules;
  extensions: Extensions;
  widgets: unknown[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DndProviderComponent = DndProvider as any;

function SetupApp({ modules, extensions, widgets }: Props) {
  return (
    <Provider store={getStore()}>
      <LocalizedProvider>
        <LocalizationContext.Provider value={cluedInComponentLocalization}>
          <DndProviderComponent backend={Backend}>
            <ApolloProvider client={graphQLClient}>
              <LayoutContextProvider>
                <AppContextProvider>
                  <ThemeProvider>
                    <ConnectedRouter history={browserHistory}>
                      <AppReactToastersContainer />
                      <WidgetRegistry widgets={widgets}>
                        {/* Used ts-ignore just for skip check in ConnectedRouter
                      as it's outdated and has no types exports 
                  */}
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}

                        <AppSearchProvider>
                          <EnhancedUserBusinessProvider>
                            <WrapperPage token={token} modules={extensions}>
                              <ScrollToTop>
                                <Switch>
                                  <Route
                                    path="/logout"
                                    exact
                                    render={(props) => {
                                      const LogoutPage =
                                        AppOrClientPagePublicChooser(
                                          Logout,
                                          LogAllOut,
                                        );
                                      return <LogoutPage {...props} />;
                                    }}
                                  />
                                  {extensions.map((pillar) => {
                                    return (
                                      <Route
                                        key={pillar.path}
                                        path={pillar.path}
                                        exact={pillar.exact}
                                        render={(props) => (
                                          <UserClaimsProvider>
                                            <RouteWithSubRoutes
                                              {...pillar}
                                              {...props}
                                              dashboardExtensions={
                                                pillar?.dashboardExtensions
                                              }
                                              actions={pillar?.actions || []}
                                              Icon={pillar?.Icon}
                                              displayName={pillar?.displayName}
                                              Comp={pillar.component}
                                              routes={pillar.routes}
                                            />
                                          </UserClaimsProvider>
                                        )}
                                      />
                                    );
                                  })}
                                  {modules.map((route) => {
                                    const Comp =
                                      route && route.component
                                        ? route.component
                                        : Div;
                                    const EnhancedComp = route.skipAuth
                                      ? onlyNotAuth(Comp, token)
                                      : onlyAuth(Comp, token);

                                    const routes = route?.routes ?? [];

                                    return (
                                      <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact}
                                        strict={route.strict}
                                        render={(props) => (
                                          <UserClaimsProvider>
                                            <RouteWithSubRoutes
                                              {...route}
                                              {...props}
                                              routes={routes}
                                              Comp={EnhancedComp}
                                            />
                                          </UserClaimsProvider>
                                        )}
                                      />
                                    );
                                  })}

                                  <EntityRoutesContainer />

                                  <Route component={NotFoundComponent} />
                                </Switch>
                              </ScrollToTop>
                            </WrapperPage>
                          </EnhancedUserBusinessProvider>
                        </AppSearchProvider>
                      </WidgetRegistry>
                    </ConnectedRouter>
                  </ThemeProvider>
                </AppContextProvider>
              </LayoutContextProvider>
            </ApolloProvider>
          </DndProviderComponent>
        </LocalizationContext.Provider>
      </LocalizedProvider>
    </Provider>
  );
}

export default SetupApp;
