import React from 'react';
import { Flex } from 'uxi/Layout';
import Badge from 'uxi/Badge';
import { TextEllipsis } from 'uxi/Text';
import { Delete, Activity, Announcement } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import RelativeTextPlaceholder from '../../../../../../../core/components/composites/Placeholder/RelativeTextPlaceholder';
import FilterGroupItem from './FilterGroupItem';
import FilterGroupItemButton from './FilterGroupItemButton';
import { UnstyledButtonForBadge } from './UnstyledButtonForBadge';

export const filterOutSelected = (filters = [], selectedFilters) =>
  filters.filter(
    (filter) => !selectedFilters.find((f) => f.value === filter.value),
  );

const ghostLoader = ['32%', '48%', '26%', '38%', '27%'].map((width) => (
  <FilterGroupItemButton key={width}>
    <RelativeTextPlaceholder
      style={{ width: '17px', display: 'inline-block', marginRight: '4px' }}
    />
    <RelativeTextPlaceholder style={{ width, display: 'inline-block' }} />
  </FilterGroupItemButton>
));

const FilterGroupResult = (props) => {
  const {
    filters,
    filterType,
    selectedFilters,
    isFetching,
    isFetchingMainSearch,
    currentFilterQuery,
    currentEntityTypePoolIdentifier,
    onEntityTypePoolChange,
    onAddFilter,
    onFocusIn,
  } = props;

  if (
    currentEntityTypePoolIdentifier &&
    filterType.value.toLowerCase() === 'entitytype'
  ) {
    return (
      <Flex
        style={{
          padding: '8px 0 0 0',
          fontSize: 'inherit',
          textAlign: 'center',
          boxSizing: 'border-box',
        }}
        onScroll={(e) => e.stopPropagation()}
      >
        <div style={{ width: '100%' }}>
          <Flex
            style={{
              flexFlow: 'row wrap',
              flexDirection: 'column',
              width: '100%',
              margin: '0 auto',
            }}
          >
            <UnstyledButtonForBadge
              onFocus={onFocusIn}
              onClick={() => onEntityTypePoolChange('')}
              style={{
                width: '100%',
                // (maxWidth: '210px') is kind of a hack done because the contentWithExtra
                // doesn't cater well engouh for the need of the search layout
                // TODO: make a fixed size column layout forSearch (with statefull mediaqueries)
                // maxWidth: '210px',
              }}
            >
              <Badge type="info" style={{ padding: '10px 0', width: '100%' }}>
                <Flex style={{ margin: '0 6px' }}>
                  <TextEllipsis
                    style={{ flexGrow: 9, textAlign: 'left' }}
                    truncateHead
                  >
                    <FormattedMessage
                      id="module-search-currentPoolViewing"
                      values={{
                        currentEntityTypePoolIdentifier,
                      }}
                    />
                  </TextEllipsis>
                  <Delete
                    style={{ margin: '0 2px 0 4px', cursor: 'pointer' }}
                    size="14"
                    hoverColor={'white'}
                    color={'rgba(255, 255, 255, 0.6)'}
                  />
                </Flex>
              </Badge>
            </UnstyledButtonForBadge>
          </Flex>
          <div style={{ width: '100%', pointerEvents: 'none', opacity: 0.4 }}>
            {[
              {
                displayName: 'Announcement',
                type: 'entityType',
                value: '/Announcement',
                icon: <Announcement size="" />,
              },
              {
                displayName: 'Activity',
                type: 'entityType',
                value: '/Activity',
                icon: <Activity size="" />,
              },
            ].map((filter) => (
              <FilterGroupItem filter={filter} onClick={() => {}} />
            ))}
          </div>
        </div>
      </Flex>
    );
  }

  if (
    isFetching ||
    (isFetchingMainSearch &&
      !currentFilterQuery &&
      filters &&
      filters.length === 0)
  ) {
    return <div>{ghostLoader}</div>;
  }

  let availableFilters = filters;
  if (selectedFilters && selectedFilters.length) {
    availableFilters = filterOutSelected(filters, selectedFilters);
  }

  if (availableFilters.length === 0) {
    return (
      <Flex
        style={{
          padding: '24px 0',
          fontSize: 'inherit',
          boxSizing: 'border-box',
        }}
      >
        <Flex style={{ flexFlow: 'row wrap', maxWidth: '200px' }}>
          {!currentFilterQuery && (
            <span>
              <span>
                <FormattedMessage
                  defaultMessage="No matches"
                  id="SearchContent.noMatches"
                />
              </span>
              &nbsp;
            </span>
          )}
          {currentFilterQuery && (
            <span>
              <span>
                <FormattedMessage
                  defaultMessage="No match for "
                  id="SearchContent.noMatchFor"
                />
              </span>
              &nbsp;
            </span>
          )}
          {currentFilterQuery && (
            <TextEllipsis>
              <strong>{currentFilterQuery}</strong>
            </TextEllipsis>
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <div style={{ minHeight: `calc(${availableFilters.length} * 25px + 8px)` }}>
      {availableFilters.map((filter) => (
        <FilterGroupItem
          onFocusIn={onFocusIn}
          // since we might have several entityType with the same
          // disaplyName (eg. Peron and Person)
          // this key is a bit dangerous
          // BUT since user shouldn't be faced with duplicate, the issue if a BE one I tihk
          key={filter.displayName}
          filter={filter}
          onClick={onAddFilter}
        />
      ))}
    </div>
  );
};

FilterGroupResult.displayName = 'FilterGroupResult';

export default FilterGroupResult;
