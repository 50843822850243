import React from 'react';
import { useTheme } from '@cluedin/theme';
import OrganizationListItemLayout from './OrganizationListItemLayout';
import RelativeTextPlaceholder from '../../../../../../core/components/composites/Placeholder/RelativeTextPlaceholder';
import IconPlaceholder from '../../../../../../core/components/composites/Placeholder/IconPlaceholder';
import { logosize, socialIconSize } from '../../theme';

const socialMenuGhost = [
  <IconPlaceholder key="a" size={socialIconSize} style={{ margin: '0 2px' }} />,
  <IconPlaceholder key="b" size={socialIconSize} style={{ margin: '0 2px' }} />,
  <IconPlaceholder key="c" size={socialIconSize} style={{ margin: '0 2px' }} />,
  <IconPlaceholder key="d" size={socialIconSize} style={{ margin: '0 2px' }} />,
];

const OrganizationListItemLoading = () => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <OrganizationListItemLayout
      logo={<IconPlaceholder key="c" size={logosize} />}
      socialMenu={socialMenuGhost}
      name={
        <RelativeTextPlaceholder
          style={{ width: '73%' }}
          color={themePrimary}
        />
      }
      industry={<RelativeTextPlaceholder style={{ width: '97%' }} />}
      location={<RelativeTextPlaceholder style={{ width: '78%' }} />}
      email={<RelativeTextPlaceholder style={{ width: '69%' }} />}
      phoneNumber={<RelativeTextPlaceholder style={{ width: '59%' }} />}
    />
  );
};

OrganizationListItemLoading.displayName = 'OrganizationListItemLoading';

export default OrganizationListItemLoading;
