import React from 'react';
import { getScaledValueWithMetricNotation } from '../../../core/helpers/numbers';
import withEntityTypesData from '../../../administration/components/Hocs/withEntityTypesData';

const EntityTypeKPI = (props) => {
  const { entityTypeViewModels } = props;

  const entityTypesLength = getScaledValueWithMetricNotation(
    (entityTypeViewModels || []).length,
  );

  return <span>{entityTypesLength}</span>;
};

export default withEntityTypesData(EntityTypeKPI);
