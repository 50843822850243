import styled from 'styled-components';

export const EntityActionMessageItemDetailsExpandedWrapper = styled.div`
  background: #ffffff;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  width: 100%;
  max-width: 100%;

  &:last-of-type {
    border-bottom: 1px solid #ececec;
  }
`;

export const EntityActionMessageItemDetailsExpandedInnerWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 1px solid rgb(212, 218, 209);
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  padding: 14px 16px;
  width: 100%;
`;

export const EntityActionMessageItemDetailsExpandedHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  position: relative;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;

export const EntityActionMessageItemDetailsExpandedHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;

export const EntityActionMessageItemDetailsExpandedBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme?.palette?.themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -25.5px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const EntityActionMessageItemDetailsExpandedDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const EntityActionMessageItemDetailsExpandedSource = styled.div`
  color: #000000;
  font-weight: 600;
  margin: 0 0 6px 0;
  padding: 0;
`;

export const EntityActionMessageItemDetailsExpandedPre = styled.pre`
  border: 1px solid #ececec;
  padding: 8px;
  margin: 0 0 14px 0 !important;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  width: 100%;
  max-width: 1200px;
  word-break: keep-all;
  overflow-x: auto;
`;
