import React, { useState } from 'react';
import { ReactComponent as MicrosfotLogo } from './MicrosfotLogo.svg';
import { ButtonLink } from '@cluedin/atoms';
import { Button, PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { loginWithSSO } from '../../../oidbc';

const SSOWrapper = ({ ssologinUrl, cluedInLogin, ssoConfig }) => {
  const [showCluedInLogin, setShowCluedInLogin] = useState(false);

  const isMicrosoft =
    ssologinUrl && ssologinUrl.indexOf('login.microsoftonline.com') > -1;

  if (!ssologinUrl) {
    return <>{cluedInLogin}</>;
  }

  return (
    <>
      {!showCluedInLogin && (
        <div style={{ marginTop: '12px' }}>
          {isMicrosoft ? (
            <Button
              startIcon={
                <MicrosfotLogo
                  style={{ minWidth: '16px', minHeight: '16px' }}
                />
              }
              text={<FormattedMessage id="module-auth-signinUsingMs" />}
              onClick={() => {
                loginWithSSO(ssoConfig);
              }}
            />
          ) : (
            <PrimaryButton
              text={<FormattedMessage id="module-auth-signinUsingSSO" />}
              onClick={() => {
                loginWithSSO(ssoConfig);
              }}
            />
          )}
          <div style={{ marginTop: '12px' }}>
            <ButtonLink
              text={<FormattedMessage id="module-auth-signinUsingCluedIn" />}
              onClick={() => {
                setShowCluedInLogin(true);
              }}
            />
          </div>
        </div>
      )}
      {showCluedInLogin && (
        <div style={{ marginTop: '12px' }}>
          <ButtonLink
            text={
              isMicrosoft ? (
                <FormattedMessage id="module-auth-backSigninMs" />
              ) : (
                <FormattedMessage id="module-auth-backSigninSSO" />
              )
            }
            onClick={() => {
              setShowCluedInLogin(false);
            }}
          />
        </div>
      )}
      <div>{showCluedInLogin && cluedInLogin}</div>
    </>
  );
};

export default SSOWrapper;
