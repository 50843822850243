"use strict";

exports.__esModule = true;
exports.text = exports.outlined = exports.contained = void 0;
var _styledComponents = require("styled-components");
var _theme = require("@cluedin/theme");
var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var fade = _theme.colorManipulator.fade;
var contained = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  background-color: ", ";\n  &:hover,\n  &:focus {\n    background-color: ", ";\n  }\n\n  &.primary {\n    color: ", ";\n    background-color: ", ";\n\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n\n    &.disabled {\n      background-color: ", ";\n      cursor: not-allowed;\n    }\n  }\n\n  &.accent {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid ", ";\n\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n\n    &.disabled {\n      background-color: ", ";\n      border: 1px solid ", ";\n      color: ", ";\n      cursor: not-allowed;\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.pureBlack;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.button.default;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.neutral.light;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.pureWhite;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.primary.main;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.primary.light;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.neutral.dark;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.pureWhite;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.accent.main;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.accent.main;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.charts.color1;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.colors.neutral.light;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.neutral.light;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.colors.neutral.darker;
});
exports.contained = contained;
var outlined = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  &.primary {\n    color: ", ";\n    border-color: ", ";\n    &:hover,\n    &:focus {\n      border-color: ", ";\n      background-color: ", ";\n    }\n  }\n  &.accent {\n    color: ", ";\n    border-color: ", ";\n    &:hover,\n    &:focus {\n      border-color: ", ";\n      background-color: ", ";\n      &.disabled {\n        color: ", ";\n        border-color: ", ";\n        cursor: not-allowed;\n      }\n    }\n    &.disabled {\n      color: ", ";\n      border-color: ", ";\n      cursor: not-allowed;\n    }\n  }\n"])), function (_ref15) {
  var theme = _ref15.theme;
  return theme.colors.primary.main;
}, function (_ref16) {
  var theme = _ref16.theme;
  return fade(theme.colors.primary.main, 0.5);
}, function (_ref17) {
  var theme = _ref17.theme;
  return theme.colors.primary.main;
}, function (_ref18) {
  var theme = _ref18.theme;
  return fade(theme.colors.primary.main, theme.colors.action.hoverOpacity);
}, function (_ref19) {
  var theme = _ref19.theme;
  return theme.colors.accent.main;
}, function (_ref20) {
  var theme = _ref20.theme;
  return fade(theme.colors.accent.main, 0.5);
}, function (_ref21) {
  var theme = _ref21.theme;
  return theme.colors.accent.main;
}, function (_ref22) {
  var theme = _ref22.theme;
  return fade(theme.colors.accent.main, theme.colors.action.hoverOpacity);
}, function (_ref23) {
  var theme = _ref23.theme;
  return theme.colors.accent.main;
}, function (_ref24) {
  var theme = _ref24.theme;
  return theme.colors.accent.main;
}, function (_ref25) {
  var theme = _ref25.theme;
  return theme.colors.accent.main;
}, function (_ref26) {
  var theme = _ref26.theme;
  return fade(theme.colors.accent.main, 0.5);
});
exports.outlined = outlined;
var text = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  &.primary {\n    color: ", ";\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n  &.accent {\n    color: ", ";\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n"])), function (_ref27) {
  var theme = _ref27.theme;
  return theme.colors.primary.main;
}, function (_ref28) {
  var theme = _ref28.theme;
  return theme.colors.primary.dark;
}, function (_ref29) {
  var theme = _ref29.theme;
  return theme.colors.accent.main;
}, function (_ref30) {
  var theme = _ref30.theme;
  return theme.colors.accent.dark;
});
exports.text = text;