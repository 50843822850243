import React from 'react';
import { AvatarWithName, HighlightText } from '@cluedin/atoms';
import { get, replace } from 'lodash';
import EntityTypeIcon from './EntityTypeIcon';

const EntityTypeAvatar = ({
  entityConfig = {},
  query = '',
  entityType,
  color,
  style = {},
  onlyLabel,
  onlyIcon,
  AvatarWithNameSize = 24,
}) => {
  const displayName =
    get(entityConfig, 'displayName') || replace(entityType, '/', ' ');

  if (onlyLabel) {
    return (
      <HighlightText
        style={color ? { color } : {}}
        text={displayName}
        highlight={query}
      />
    );
  }

  return (
    <AvatarWithName
      style={style}
      imgSize={AvatarWithNameSize}
      icon={
        <EntityTypeIcon
          imgSize={AvatarWithNameSize}
          color={color || undefined}
          entityConfig={entityConfig}
          entityType={entityType}
        />
      }
      name={
        !onlyIcon && (
          <HighlightText
            style={color ? { color } : {}}
            text={displayName}
            highlight={query}
          />
        )
      }
    />
  );
};

export default EntityTypeAvatar;
