import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import moment from 'moment';
import { List } from '@cluedin/list-pattern';
import IntegrationNameWithLogoFromIdContainer from '../../../../integration/components/containers/IntegrationNameWithLogoFromIdContainer';
import ConfigurationScheduleStatusWithVisual from '../ConfigurationScheduleStatusWithVisual';
import PropsMapper from '../../../../core/components/Hocs/PropsMapper';
import ConfigurationStatusWithVisual from '../ConfigurationStatusWithVisual';

const IntegrationNameRow = ({
  AccountDisplay,
  AccountId,
  ProviderId,
  query,
  Id,
}) => {
  const accountDisplayName = AccountDisplay || AccountId || (
    <FormattedMessage id="module-configuration-notRelevant" />
  );

  const accountNameContent = (
    <span style={{ whiteSpace: 'normal' }}>
      &nbsp;-&nbsp;{accountDisplayName}
    </span>
  );

  return (
    <div className="test_Configuration_ConfigurationList">
      <IntegrationNameWithLogoFromIdContainer
        integrationId={ProviderId}
        extra={accountNameContent}
        // onClick={() => { entity.onConfigurationClick(entity); }}
        link={`/admin/configuration/${ProviderId}/${Id}/`}
        query={query}
      />
    </div>
  );
};

const FormattedDate = ({ CreatedDate }) =>
  CreatedDate ? moment(CreatedDate).fromNow() : '-';

const ConfigurationList = (props) => {
  const {
    configurations = [],
    Action,
    ActionTitle,
    onConfigurationClick,
    grantAccess,
    revokeAccess,
    condensed,
    onChange,
    disableMulti,
    query,
    disableActions,
  } = props;

  const configurationProperties = [
    {
      property: 'name',
      displayName: (
        <FormattedMessage id="module-configuration-applicationName" />
      ),
      isComputed: true,
      Component: IntegrationNameRow,
    },
    {
      property: 'status',
      displayName: <FormattedMessage id="module-configuration-status" />,
      isComputed: true,
      Component: PropsMapper(
        ConfigurationStatusWithVisual,
        (configuration) => ({ configuration }),
      ),
    },
    {
      property: 'schedule-status',
      displayName: (
        <FormattedMessage id="module-configuration-scheduleStatus" />
      ),
      isComputed: true,
      Component: ConfigurationScheduleStatusWithVisual,
    },
    {
      displayName: (
        <FormattedMessage id="module-configuration-configurationAuthor" />
      ),
      property: 'UserName',
    },
    {
      property: 'creation-date',
      displayName: <FormattedMessage id="module-configuration-creationDate" />,
      Component: FormattedDate,
    },
  ];

  if (ActionTitle && Action) {
    const ActionCell = (entity) => (
      <Action configuration={entity} configurationId={entity.Id} {...props} />
    );

    configurationProperties.push({
      isComputed: true,
      displayName: <FormattedMessage id="module-configuration-accessGranted" />,
      Component: ActionCell,
    });
  }

  const isMultiSelectable = !disableMulti;

  return (
    <List
      withToolbar={false}
      offsetTop={237}
      selectable={isMultiSelectable}
      multiSelectable={isMultiSelectable}
      condensed={condensed}
      propertyKey="Id"
      onSelectedChange={onChange}
      dataGridName="configurationConfigurationList"
      actions={
        disableActions
          ? []
          : [
              {
                label: (
                  <FormattedMessage
                    id="module-configuration-clearSelection"
                    defaultMessage="Clear Selection"
                  />
                ),
                clearSelection: true,
                action: () => {},
              },
              {
                label: (
                  <FormattedMessage
                    id="module-configuration-grantAccess"
                    defaultMessage="Grant access"
                  />
                ),
                clearSelection: true,
                action: grantAccess,
              },
              {
                label: (
                  <FormattedMessage
                    id="module-configuration-revokeAccess"
                    defaultMessage="Revoke access"
                  />
                ),
                clearSelection: true,
                action: revokeAccess,
              },
            ]
      }
      data={configurations.map((c) => ({
        ...c,
        query,
        onConfigurationClick,
      }))}
      columns={configurationProperties}
    />
  );
};

ConfigurationList.displayName = 'ConfigurationList';

export default ConfigurationList;
