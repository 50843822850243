import React from 'react';
import UserRolesListContainer from '../../../roles/components/containers/UserRolesListContainer';

const AdminUserDynamicRolesPage = ({ userId, user }) => {
  return (
    <>
      <UserRolesListContainer
        userName={user.Account.UserName}
        userId={userId}
      />
    </>
  );
};

export default AdminUserDynamicRolesPage;
