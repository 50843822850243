import store from 'store';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './module.routes';

const { preparation } = actionsConfiguration;

export default {
  pillar: 'preparation',
  name: 'clean',
  displayName: <FormattedMessage id="clean-legacy" />,
  path: '/clean',
  extendPillarDashboard: {
    actions: [
      {
        isDeprecated: true,
        icon: 'Datacleaning',
        name: <FormattedMessage id="clean-legacy" />,
        link: '/admin/preparation/clean',
        claims: preparation.clean.project.view,
      },
    ],
  },
  routes,
};
