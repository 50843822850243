import { FormattedMessage } from '@cluedin/locale';
import { NotFoundComponent } from '@cluedin/components';
import WarningImage from '../../../../../core/components/composites/WarningImage';

export const GlobalSecurityDisabled = () => {
  return (
    <NotFoundComponent
      noCreate
      smaller
      IllustrationComponent={WarningImage}
      message={
        <div style={{ marginTop: '24px' }}>
          <FormattedMessage id="module-connect-globalSecurityDisable" />
        </div>
      }
      explanation={
        <>
          <div>
            <FormattedMessage id="module-connect-globalSecurityDisableText1" />
          </div>
          <div style={{ marginTop: '6px' }}>
            <FormattedMessage id="module-connect-globalSecurityDisableText2" />
          </div>
        </>
      }
    />
  );
};

export default GlobalSecurityDisabled;
