import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { getAnnotationById } from '../../data';

export const withSingleAnnotation = (Comp) => (props) => {
  if (!props.hasAnnotation) {
    return <Comp {...props} annotationId={null} annotation={null} />;
  }

  const {
    data = {},
    loading,
    error,
    refetch,
    networkStatus,
  } = useQuery(getAnnotationById, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: props.annotationId || props.id,
    },
  });
  const [annotationState, setAnnotationState] = useState();

  let annotation = null;
  if (
    !loading &&
    !error &&
    data &&
    data.preparation &&
    data.preparation.annotation
  ) {
    annotation = data.preparation.annotation;
  }

  useEffect(() => {
    if (annotation) {
      setAnnotationState(annotation);
    }
  }, [annotation]);

  return (
    <Comp
      {...props}
      annotationId={props.annotationId || props.id}
      annotationLoading={networkStatus === 1 || !annotationState}
      annotationError={error}
      annotation={annotationState}
      refetch={(annotationId) => {
        refetch({
          id: annotationId || props.annotationId || props.id,
        });
      }}
      refetchAnnotation={(annotationId) => {
        refetch({
          id: annotationId || props.annotationId || props.id,
        });
      }}
    />
  );
};

export default {
  withSingleAnnotation,
};
