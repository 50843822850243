import { connect } from 'react-redux';
import {
  shouldFetchEntities as shouldFetchEntitiesAction,
  abortMerge,
  shouldSubmitMergedEntitiesDelta as shouldSubmitMergedEntitiesDeltaAction,
  selectEntity,
  deselectEntity,
  deselectAllEntities,
} from '../../actions';
import { getConflictedPropsWithPropertySchema } from '../../selectors';
import { shouldFetchSchema as shouldFetchSchemaSelector } from '../../../../action/entity';

const mapToStateProps = ({
  entityMerge: {
    errors,
    isMerging,
    isSelectionValid,
    entities,
    isSubmitting,
    isFetching,
    isDone,
    selectedEntities,
  },
  entity: { isFetchingSchema, schema },
  forms: { propsDelta },
}) => ({
  selectedEntities,
  errors,
  isMerging,
  isSelectionValid,
  entities,
  isSubmitting,
  isFetching,
  isFetchingSchema,
  isDone,
  conflictedProperties: getConflictedPropsWithPropertySchema(entities, schema),
  propsDelta,
});

const mapDispatchToProps = (dispatch) => ({
  shouldFetchEntities: (entitiesIds) =>
    dispatch(shouldFetchEntitiesAction(entitiesIds)),
  abortMerge: () => dispatch(abortMerge()),
  shouldFetchSchema: () => dispatch(shouldFetchSchemaSelector()),

  shouldSubmitMergedEntitiesDelta: (entitiesDelta) =>
    dispatch(shouldSubmitMergedEntitiesDeltaAction(entitiesDelta)),

  selectEntity: (entity) => {
    dispatch(selectEntity(entity));
  },
  deselectEntity: (entity) => {
    dispatch(deselectEntity(entity));
  },
  deselectAllEntities: () => {
    dispatch(deselectAllEntities());
  },
});

export default connect(mapToStateProps, mapDispatchToProps);
