import { useMemo, useState } from 'react';
import { TextField, FormDecorator } from '@cluedin/form';
import { IconPickerField } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Icons/Alert';
import { useTheme } from '@cluedin/theme';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';
import { useIntl } from 'react-intl';

import { getClassicValidationMessage } from '../../../core/validation/messages';
import { EntityTypeSelector } from './EntityTypeSelector';
import EntityTypeAvatar from './EntityTypeAvatar';
import EntityTypeIcon from '../composites/EntityTypeIcon';
import { usePaginatedEntities } from '../../hooks/usePaginatedEntities';

const EntityTypeSelectAndCreate = ({
  value,
  onChange,
  disabled = false,
  style = {},
  isClearable = true,
  isCreatable = true,
  showCustomAvatar = false,
  entityTypeValidationError = null,
  entityTypeIconValidationError = null,
}) => {
  const intl = useIntl();

  const [tempVal, setTempVal] = useState();

  const { loadMore, entities, setSearchEntities, isLoadingEntities } =
    usePaginatedEntities({
      loadMoreLabel: intl.formatMessage({
        id: 'module-entityType-select-loadMore',
      }),
    });

  const isNew = value?.new ? value?.new : false;
  const [isNewEntityType, setIsNewEntityType] = useState(isNew);

  const entityType = useMemo(() => value?.entityType, [value]);
  const displayName = useMemo(
    () => value?.displayName || value?.entityType,
    [value],
  );
  const icon = useMemo(() => value?.icon, [value]);

  const adaptedEntityTypeSelectValue = useMemo(() => {
    return entityType && displayName
      ? {
          value: entityType ?? '',
          label: displayName ?? '',
          icon:
            entityType && showCustomAvatar ? (
              <EntityTypeIcon
                entityType={value?.entityType}
                entityConfig={value}
              />
            ) : (
              <EntityTypeAvatar
                AvatarWithNameSize={14}
                style={{ width: '18px' }}
                entityType={entityType}
              />
            ),
        }
      : undefined;
  }, [entityType, displayName]);

  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const newEntityValidationError =
    tempVal?.displayName &&
    getClassicValidationMessage(tempVal.displayName, 100);

  const adaptedTempSelectValue = useMemo(() => {
    return tempVal?.displayName && tempVal?.entityType
      ? {
          label: tempVal?.displayName ?? '',
          value: tempVal?.entityType,
          icon:
            tempVal?.entityType && showCustomAvatar ? (
              <EntityTypeIcon
                entityType={value?.entityType}
                entityConfig={value}
              />
            ) : (
              <EntityTypeAvatar
                AvatarWithNameSize={14}
                style={{ width: '18px' }}
                entityType={tempVal.entityType}
              />
            ),
        }
      : undefined;
  }, [tempVal]);

  return (
    <div style={style}>
      <EntityTypeSelector
        setTempVal={setTempVal}
        hasError={!!newEntityValidationError}
        isClearable={isClearable}
        isCreatable={isCreatable}
        data={entities}
        disabled={disabled}
        onChange={onChange}
        loadMore={loadMore}
        isLoading={isLoadingEntities}
        setSearchName={setSearchEntities}
        value={adaptedTempSelectValue || adaptedEntityTypeSelectValue}
        setIsNewEntityType={setIsNewEntityType}
        placeholder={intl.formatMessage({
          id: 'module-entityType-select-entityType',
        })}
      />

      {newEntityValidationError && (
        <div style={{ marginBottom: '-16px' }}>{newEntityValidationError}</div>
      )}

      {!isNewEntityType && value?.entityType && !newEntityValidationError && (
        <Link
          style={{
            marginTop: '10px',
            display: 'inline-flex',
            alignItems: 'center',
            color: themePrimary,
          }}
          target="_blank"
          to={`/admin/management/entity-types/edit?entityType=${value?.entityType}`}
        >
          <div style={{ marginRight: '10px' }}>
            <FormattedMessage id="module-entityType-view-details-on" />{' '}
            {value?.displayName}
          </div>
          <Externallink size={14} />
        </Link>
      )}

      {isNewEntityType && !newEntityValidationError && (
        <>
          <div
            style={{
              color: '#5B89CF',
              fontSize: '12px',
              marginTop: '6px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Alert style={{ marginRight: '6px' }} size={16} color="#5B89CF" />
            <em>
              <FormattedMessage id="module-entityType-newEntityTypeInfoPart1" />
            </em>
            <strong style={{ margin: '0 4px' }}>{displayName}</strong>
            <em>
              <FormattedMessage id="module-entityType-newEntityTypeInfoPart2" />
            </em>
          </div>
          <FormDecorator
            isRequired
            style={{ marginTop: '12px' }}
            errorText={
              entityTypeValidationError || entityType?.charAt(0) !== '/'
                ? intl.formatMessage({
                    id: 'module-entityType-entityType-must-start-from-slash',
                  })
                : null
            }
            label={<FormattedMessage id="module-entityType-entityTypeCode" />}
            helpText={
              <FormattedMessage id="module-entityType-entityTypeCode-help" />
            }
          >
            <TextField
              hasError={
                entityTypeValidationError || entityType?.charAt(0) !== '/'
              }
              value={entityType}
              onChange={(e, v) => {
                if (onChange) {
                  onChange({
                    ...value,
                    entityType: v,
                  });
                }
              }}
            />
          </FormDecorator>
          <FormDecorator
            isRequired
            style={{ marginTop: '12px' }}
            label={<FormattedMessage id="module-entityType-entityTypeIcon" />}
            helpText={
              <FormattedMessage id="module-entityType-entityTypeIcon-help" />
            }
            errorText={entityTypeIconValidationError}
          >
            <IconPickerField
              value={icon}
              onChange={(v) => {
                if (onChange) {
                  onChange({
                    ...value,
                    icon: v,
                  });
                }
              }}
              labels={{
                title: (
                  <FormattedMessage id="module-entityTypes-iconPicker-title" />
                ),
                searchPlaceHolder: intl.formatMessage({
                  id: 'module-entityTypes-iconPicker-searchPlaceHolder',
                }),
                noIconFound: (
                  <FormattedMessage id="module-entityTypes-iconPicker-noIconFound" />
                ),
                loadMore: (
                  <FormattedMessage id="module-entityTypes-iconPicker-loadMore" />
                ),
                changeIconButton: (
                  <FormattedMessage id="module-entityTypes-iconPicker-changeIcon" />
                ),
              }}
            />
          </FormDecorator>
        </>
      )}
    </div>
  );
};

export default EntityTypeSelectAndCreate;
