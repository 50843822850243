import styled from 'styled-components';

export const Step1RadioWrapper = styled.div`
  padding: 0 0 16px 0;
  svg {
    g > g {
      stroke: ${({ theme }) => theme.palette.themePrimary};
    }
  }
`;
