import { ReactNode, CSSProperties } from 'react';

type ErrorTextProps = {
  style?: CSSProperties;
  children: ReactNode;
};

export const ErrorText = ({ children, style = {} }: ErrorTextProps) => {
  return (
    <span
      style={{
        fontSize: '12px',
        color: '#d13f48',
        ...style,
      }}
    >
      {children}
    </span>
  );
};
