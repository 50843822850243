import React, { useState } from 'react';
import VocabSelection from './VocabSelection';
import AnnotationVocabGroup from './forms/AnnotationVocabGroup';
import { FormattedMessage } from '@cluedin/locale';

const VocabSelectionStep = ({
  vocabs,
  onSelectionChange,
  noVocabContent,
  title,
}) => {
  const [selectedProperties, setProperties] = useState([]);
  let content;

  if ((vocabs || []).length === 0) {
    content = (
      <div>
        {noVocabContent}
        <FormattedMessage id="module-annotation-vocabSelectionStepNoCoreVocab1" />
        <br />
        <FormattedMessage id="module-annotation-vocabSelectionStepNoCoreVocab2" />
      </div>
    );
  } else {
    content = (
      <VocabSelection
        selectedProperties={selectedProperties}
        setProperties={setProperties}
        vocabs={vocabs}
        onSelectionChange={onSelectionChange}
      />
    );
  }

  return (
    <AnnotationVocabGroup
      title={title}
      success={selectedProperties.length > 0}
      selectVocab={
        selectedProperties.length > 0 ? selectedProperties.length : null
      }
    >
      {content}
    </AnnotationVocabGroup>
  );
};

export default VocabSelectionStep;
