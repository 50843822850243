import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { TimeFromNow } from '@cluedin/components';

const NoValueWrapper = styled.div`
  font-style: italic;
  color: #ccc;
`;

const EntityDataCellComponent =
  (prop = 'createdDate') =>
  (entity) => {
    const date = entity[prop];

    if (!date) {
      return (
        <NoValueWrapper>
          <FormattedMessage id="module-goldenRecordList-noValue" />
        </NoValueWrapper>
      );
    }

    return <TimeFromNow date={date} />;
  };

export default EntityDataCellComponent;
