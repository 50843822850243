import { createAction } from 'redux-actions';

export const createAsyncAction = (name) => {
  const result = {};

  result.request = createAction(`REQUEST_${name}`);
  result.receive = createAction(`RECEIVE_${name}`);
  result.invalid = createAction(`INVALID_${name}`);
  result.queued = createAction(`QUEUED_${name}`);

  return result;
};

export const createBatchAsyncAction = (name) => {
  const result = {};

  result.request = createAction(`REQUEST_${name}`);
  result.receive = createAction(`RECEIVE_${name}`);
  result.invalid = createAction(`INVALID_${name}`);
  result.queued = createAction(`QUEUED_${name}`);
  result.all = createAction(`BATCH_COMPLETED_${name}`);

  return result;
};

export default {
  createAsyncAction,
  createBatchAsyncAction,
};
