"use strict";

exports.__esModule = true;
exports.useInitialExpansionBySearch = void 0;
var _lodash = require("lodash");
var isMatches = function isMatches(str, query) {
  return str == null ? void 0 : str.toLowerCase().includes(query);
};
var getExpansionState = function getExpansionState(_ref) {
  var data = _ref.data,
    search = _ref.search,
    secondLevelDataKey = _ref.secondLevelDataKey;
  var result = {};
  data.forEach(function (topLevelItem, idx) {
    var _topLevelItem$id, _topLevelItem$secondL;
    var parentId = (_topLevelItem$id = topLevelItem == null ? void 0 : topLevelItem.id) != null ? _topLevelItem$id : idx;
    if (isMatches(topLevelItem == null ? void 0 : topLevelItem.name, search)) {
      result[parentId] = {
        expanded: true,
        items: {}
      };
    }
    var innerData = (_topLevelItem$secondL = topLevelItem == null ? void 0 : topLevelItem[secondLevelDataKey]) != null ? _topLevelItem$secondL : topLevelItem == null ? void 0 : topLevelItem.data;
    if (!innerData || innerData.length === 0) return;
    innerData == null ? void 0 : innerData.forEach(function (innerItem) {
      if (!isMatches(innerItem == null ? void 0 : innerItem.name, search)) return;
      var parentResItem = (0, _lodash.get)(result, parentId);
      if (!parentResItem) {
        result[parentId] = {
          expanded: true,
          items: {}
        };
        return;
      }
    });
  });
  return result;
};
var useInitialExpansionBySearch = function useInitialExpansionBySearch(_ref2) {
  var data = _ref2.data,
    search = _ref2.search,
    secondLevelDataKey = _ref2.secondLevelDataKey;
  if (!search) return {};
  return getExpansionState({
    data: data,
    search: search,
    secondLevelDataKey: secondLevelDataKey
  });
};
exports.useInitialExpansionBySearch = useInitialExpansionBySearch;