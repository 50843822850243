import { Toggle, Select } from '@cluedin/form';
import { OnlyExistingValuesSelect } from '../../../../../shared/components/OnlyExistingValuesSelect';

const AddRecordInput = ({ formField, onChange, value, ...props }) => {
  if (formField.onlyExistingValues) {
    const handleChange = (value) => {
      return onChange({ value, label: value });
    };
    return (
      <div data-test={`addRecord-input-${formField.label}`}>
        <OnlyExistingValuesSelect
          vocabularyKey={formField.vocabularyKey}
          value={value}
          onChange={({ value }) => handleChange(value)}
          isCreatable={false}
        />
      </div>
    );
  }

  if (formField.type === 'toggle') {
    return (
      <div data-test={`addRecord-input-${formField.label}`}>
        <Toggle checked={value} onChange={(_, v) => onChange(v)} />
      </div>
    );
  } else if (formField.type === 'pickList') {
    const options = formField.parameters.map((elem) => ({
      key: elem.key,
      value: elem.value,
      label: elem.value,
    }));
    const handleChange = (value) => {
      return onChange({ value, label: value });
    };

    return (
      <div data-test={`addRecord-input-${formField.label}`}>
        <Select
          value={value}
          options={options}
          isSearchable
          isCreatable
          onCreateOption={handleChange}
          onChange={({ value }) => handleChange(value)}
        />
      </div>
    );
  } else {
    const handleChange = (value) => {
      return onChange({ value, label: value });
    };
    return (
      <div data-test={`addRecord-input-${formField.label}`}>
        <OnlyExistingValuesSelect
          vocabularyKey={formField.vocabularyKey}
          value={value}
          onChange={({ value }) => handleChange(value)}
          isCreatable={true}
        />
      </div>
    );
  }
};

export default AddRecordInput;
