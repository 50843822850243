"use strict";

exports.__esModule = true;
exports.default = setRef;
function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}