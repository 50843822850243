import { gql, useQuery } from '@apollo/client';

export const SEARCH_SUGGESTIONS = gql`
  query searchSuggestions($query: String) {
    search {
      searchSuggestions(query: $query) {
        results {
          label
        }
      }
    }
  }
`;

type QueryVariables = {
  query: string;
};

type SuggestedSearchResult = {
  label: string;
};

type SuggestedSearchResults = {
  results: SuggestedSearchResult[];
};

export type SuggestedSearchResponse = {
  search: {
    searchSuggestions: SuggestedSearchResults;
  };
};

type Options = {
  value: string;
  skip: boolean;
  onCompleted: (data: SuggestedSearchResponse) => void;
};

export const useQuerySuggestedSearch = ({
  value,
  skip,
  onCompleted,
}: Options) => {
  const fetchPolicy = 'network-only';

  const { data, loading, previousData } = useQuery<
    SuggestedSearchResponse,
    QueryVariables
  >(SEARCH_SUGGESTIONS, {
    skip,
    fetchPolicy,
    variables: {
      query: value,
    },
    onCompleted: onCompleted,
  });

  const suggestedSearchResults = data?.search?.searchSuggestions?.results || [];
  const previousSuggestedSearchResults =
    previousData?.search?.searchSuggestions?.results || [];

  return {
    suggestedSearchResults,
    previousSuggestedSearchResults,
    loadingSuggestedSearchResults: loading,
  };
};
