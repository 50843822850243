import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/',
    exact: true,
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"ExpressionListPage" */
          './components/pages/ExpressionListPage'
        ),
      ),
    ),
  },
  {
    path: '/detail/:id',
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"ExpressionDetailPage" */
          './components/pages/ExpressionDetailPage'
        ),
      ),
    ),
  },
];
