import {
  createOrganizationViewModel,
  createOrganizationViewModelFromGql,
} from './organization';
import { createPersonViewModel, createPersonViewModelFromGql } from './person';
import {
  createSearchResultViewModel,
  createSearchResultViewModelFromGql,
} from './searchResult';

export const findFromKey = (properties, key) => {
  const allLikelyCorrespondingKeys = [];
  const allPropertyKeys = Object.keys(properties);
  allPropertyKeys.forEach((k) => {
    const allKeys = k.split('.');
    const lastKey = allKeys.pop();

    if (lastKey.indexOf(key) > -1) {
      allLikelyCorrespondingKeys.push(k);
    }
  });

  const hasCluedIn = allLikelyCorrespondingKeys.find(
    (k) => k.indexOf('cluedin') > -1,
  );

  if (hasCluedIn) {
    return {
      displayName: key,
      value: properties[hasCluedIn],
    };
  }

  if (allLikelyCorrespondingKeys && allLikelyCorrespondingKeys.length > 0) {
    return {
      displayName: key,
      value: properties[allLikelyCorrespondingKeys[0]],
    };
  }

  return {
    displayName: key,
    value: null,
  };
};

const createDocumentViewModel = (entity) => entity;

const createSearchResultViewModels = (entities = []) =>
  entities.map((entity) => createSearchResultViewModel(entity));

const createSearchResultViewModelsFromGql = (
  entities = [],
  entityConfigurations,
) =>
  entities.map((entity) =>
    createSearchResultViewModelFromGql(entity, entityConfigurations),
  );

const createPersonViewModelsFromGql = (entities = [], entityConfigurations) =>
  entities.map((entity) =>
    createPersonViewModelFromGql(entity, entityConfigurations),
  );

const createOrganizationViewModelsFromGql = (
  entities = [],
  entityConfigurations,
) =>
  entities.map((entity) =>
    createOrganizationViewModelFromGql(entity, entityConfigurations),
  );

const createDocumentViewModelsFromGql = (entities = [], entityConfigurations) =>
  entities.map((entity) =>
    createSearchResultViewModelFromGql(entity, entityConfigurations),
  );

export const createVmFromgGql = (
  entities = [],
  vmType,
  entityConfigurations,
) => {
  if (vmType === 'searchResult') {
    return createSearchResultViewModelsFromGql(
      entities || [],
      entityConfigurations,
    );
  } else if (vmType === 'person') {
    return createPersonViewModelsFromGql(entities || [], entityConfigurations);
  } else if (vmType === 'organization') {
    return createOrganizationViewModelsFromGql(
      entities || [],
      entityConfigurations,
    );
  } else if (vmType === 'document') {
    return createDocumentViewModelsFromGql(
      entities || [],
      entityConfigurations,
    );
  }

  return entities;
};

const createPersonViewModels = (entities = []) =>
  entities.map((entity) => createPersonViewModel(entity));

const createOrganizationViewModels = (entities = []) =>
  entities.map((entity) => createOrganizationViewModel(entity));

const createDocumentViewModels = (entities = []) =>
  entities.map((entity) => createDocumentViewModel(entity));

export const createViewModels = (entities, vmType) => {
  if (vmType === 'searchResult') {
    return createSearchResultViewModels(entities || []);
  } else if (vmType === 'person') {
    return createPersonViewModels(entities || []);
  } else if (vmType === 'organization') {
    return createOrganizationViewModels(entities || []);
  } else if (vmType === 'document') {
    return createDocumentViewModels(entities || []);
  }
  return entities;
};

const createFacetViewModelFromEntity = (entity, key, entityTypes) => ({
  key,
  value: entity.id,
  displayName: entity.name,
  type: entityTypes.join('-'),
});

export const createFacetViewModelsFromEntity = (
  entities = [],
  key,
  entityTypes = [],
) => entities.map((e) => createFacetViewModelFromEntity(e, key, entityTypes));

export const createSearchStatViewModel = (total) => total;

export const createViewModel = (entity) => {
  if (entity.data.entityType === '/Person') {
    return createPersonViewModel(entity);
  } else if (entity.data.entityType === '/Organization') {
    return createOrganizationViewModel(entity);
  } else if (entity.data.entityType === '/Document') {
    return createDocumentViewModel(entity);
  }

  return entity;
};

export default {
  createViewModels,
  createViewModel,
  createVmFromgGql,
};
