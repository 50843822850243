import {
  fetchAllRetentionSetup,
  createEntityRetention,
  createQueryRetention,
  deleteMultipleRetention,
} from './data';
import {
  createAsyncAction,
  noBodyResponseAsyncCallWithActions,
  defaultAsyncCallWithActions,
} from '../../action/helpers/actionCreator';

export const shouldFetchAllRetentionActions = createAsyncAction(
  'SHOULD_FETCH_ALL_RETENTION',
);
export const shouldFetchAllRetention = defaultAsyncCallWithActions(
  fetchAllRetentionSetup,
  shouldFetchAllRetentionActions,
);

export const shouldCreateEntityRetentionActions = createAsyncAction(
  'SHOULD_CREATE_ENTITY_RETENTION',
);
export const shouldCreateEntityRetention = noBodyResponseAsyncCallWithActions(
  createEntityRetention,
  shouldCreateEntityRetentionActions,
);

export const shouldCreateQueryRetentionActions = createAsyncAction(
  'SHOULD_CREATE_QUERY_RETENTION',
);
export const shouldCreateQueryRetention = noBodyResponseAsyncCallWithActions(
  createQueryRetention,
  shouldCreateQueryRetentionActions,
);

export const shouldDeleteRetentionPoliciesActions = createAsyncAction(
  'SHOULD_DELETE_LIST_OF_RETENTION',
);
export const shouldDeleteRetentionPolicies = noBodyResponseAsyncCallWithActions(
  deleteMultipleRetention,
  shouldDeleteRetentionPoliciesActions,
);
