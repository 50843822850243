import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import styled from 'styled-components';
import CluedInBigTitle from '../../../../core/components/composites/generic/CluedInBigTitle';
import Spacer from '../../../../core/components/composites/generic/Spacer';

const SubTitle = styled.div`
  font-size: 16px;
  text-align: left;
  font-weight: normal;
`;

const SigninWrapper = ({ clientId, children }) => (
  <div className="test_auth_signInForm">
    <CluedInBigTitle>
      <div data-test="signin-text">
        <FormattedMessage id="module-auth-sign" defaultMessage="Sign in" />
      </div>
    </CluedInBigTitle>
    <Spacer padding="stack-m">
      <SubTitle>
        <Spacer padding="stack-s" data-test="signin-prompt-text">
          <FormattedHTMLMessage
            id="module-auth-welcomeBack"
            values={{ clientId }}
            defaultMessage="Welcome back, you are signing into the <strong>{clientId}</strong> account."
          />
        </Spacer>
      </SubTitle>
    </Spacer>
    {children}
  </div>
);

export default SigninWrapper;
