import React from 'react';
import { useTheme } from '@cluedin/theme';
import { Widget } from 'uxi/Widget';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import { SimpleGrid } from 'uxi/Layout';
import Alert from 'uxi/Alert/Alert';
import ButtonMenu from 'uxi/Menu/ButtonMenu/ButtonMenu';
import ButtonMenuItem from 'uxi/Menu/ButtonMenu/ButtonMenuItem';
import { FormattedMessage } from '@cluedin/locale';
import { UnstyledButton } from 'uxi/Button';
import { Options, Close, Fullscreen } from 'uxi/Icons';
import { withGQLEntityMetrics } from '../hocs/withGQLEntityMetrics';
import { capitalizeFirstLetter } from '../../../core/helpers/string';
import TrendOverTimeKPIChart from '../../../DVC/components/composites/TrendOverTimeKPIChart';
import PageLoader from '../../../core/components/composites/PageLoader';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';
// import { getRandInRange } from '../../../core/helpers/numbers';

const EntityMetricsContainer = ({
  dateRange,
  metrics,
  metricsLoading,
  metricsError,
  isaccuracyDisabled,
  iscompletenessDisabled,
  isconnectivityDisabled,
  isrelevanceDisabled,
}) => {
  const theme = useTheme();

  const isNewCleanEnabled =
    useOrganizationFeatureFlagOption('New Clean Project');

  const metricsToUse = (metrics || []).filter((m) => {
    const name = m?.name;

    if (
      (name === 'accuracy' && isaccuracyDisabled) ||
      (name === 'completeness' && iscompletenessDisabled) ||
      (name === 'connectivity' && isconnectivityDisabled) ||
      (name === 'relevance' && isrelevanceDisabled)
    ) {
      return false;
    }

    return true;
  });

  if (metricsToUse.length === 0) {
    return;
  }

  return (
    <SimpleGrid itemWidth={320} gap={16} style={{ margin: '16px' }}>
      {metricsToUse
        // .filter(x => mainKPIs.includes(x))
        .map((metricData = {}, idx) => {
          const {
            name,
            globalDimension = null,
            globalIntegrationTypeDimensions,
          } = metricData;

          const values =
            globalDimension &&
            globalDimension.values &&
            Array.isArray(globalDimension.values)
              ? globalDimension.values
              : [];
          const latestValue =
            globalDimension && globalDimension.latestValue
              ? globalDimension.latestValue
              : undefined;
          if (metricsLoading) {
            return (
              <Widget
                headerStyles={{ maxWidth: 'calc(100% - 76px)' }}
                containerStyle={{ height: 'calc(100% - 50px)' }}
                title={
                  metricsLoading ? (
                    'loading...'
                  ) : (
                    <TextEllipsis>
                      {`${capitalizeFirstLetter(name || 'metrics')} `}
                      <FormattedMessage id="module-gdpr-overTime" />
                    </TextEllipsis>
                  )
                }
              >
                <div style={{ padding: '64px 0' }}>
                  <PageLoader />
                </div>
              </Widget>
            );
          }

          if (metricsError) {
            return (
              <Widget
                headerStyles={{ maxWidth: 'calc(100% - 76px)' }}
                containerStyle={{ height: 'calc(100% - 50px)' }}
                title={
                  <TextEllipsis>
                    {`${capitalizeFirstLetter(name || 'metrics')} `}
                    <FormattedMessage id="module-gdpr-overTime" />
                  </TextEllipsis>
                }
              >
                <div
                  style={{
                    padding: '32px 0',
                    minHeight: '200px',
                    boxSizing: 'content-box',
                  }}
                >
                  <Alert>
                    <FormattedMessage id="module-entityWidget-errorMertricsCharts" />
                  </Alert>
                </div>
              </Widget>
            );
          }

          const dataVM = {
            values: (values || []).map(({ value, date }) => ({
              date,
              value: value * 100,
            })),
            // .concat([47.8, 65.12, 87.4]) // fake data

            latestValue: ((latestValue || {}).value || 0) * 100,
            additionalValues: (
              (globalIntegrationTypeDimensions &&
                globalIntegrationTypeDimensions.length > 0 &&
                globalIntegrationTypeDimensions) ||
              []
            ).map((x) => ({
              ...x,
              values: x.values.map(({ value, date }) => ({
                date,
                value: value * 100,
              })),
              /**
               * ADD FAKE DATA POINTS
               */
              // .concat([
              //   { value: getRandInRange(30, 60), date: '' },
              //   { value: getRandInRange(50, 70), date: '' },
              //   { value: getRandInRange(70, 90), date: '' },
              // ])
            })),
          };

          return (
            <Widget
              key={idx}
              headerStyles={{ maxWidth: 'calc(100% - 76px)' }}
              containerStyle={{ height: 'calc(100% - 50px)' }}
              title={
                <TextEllipsis>
                  {`${capitalizeFirstLetter(name || 'metrics')} `}
                  <FormattedMessage id="module-gdpr-overTime" />
                </TextEllipsis>
              }
              allowFullScreen
              renderMenu={({
                escapeFullScreen,
                goFullScreen,
                isFullScreen,
              }) => {
                return (
                  <ButtonMenu
                    anchor={'right'}
                    button={
                      <UnstyledButton
                        icon={<Options />}
                        style={{ width: '50px', height: '50px' }}
                      />
                    }
                  >
                    <ButtonMenuItem
                      icon={isFullScreen ? <Close /> : <Fullscreen />}
                      onClick={isFullScreen ? escapeFullScreen : goFullScreen}
                    >
                      {isFullScreen ? (
                        <FormattedMessage id="module-entityWidget-minimize" />
                      ) : (
                        <FormattedMessage id="module-entityWidget-maximize" />
                      )}
                    </ButtonMenuItem>
                  </ButtonMenu>
                );
              }}
            >
              <TrendOverTimeKPIChart
                dateRange={dateRange}
                theme={theme}
                // singleProvider={singleProvider}
                data={dataVM}
                actionLink={
                  isNewCleanEnabled
                    ? '/admin/preparation/new-clean-project'
                    : '/admin/preparation/clean'
                }
              />
            </Widget>
          );
        })}
    </SimpleGrid>
  );
};

EntityMetricsContainer.displayName = 'EntityMetricsContainer';

export default withGQLEntityMetrics(EntityMetricsContainer);
