import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import UserConsentIdentifierList from '../lists/UserConsentIdentifierList';
import UserConsentProcessorList from '../lists/UserConsentProcessorList';
import UserExplanation from './UserExplanation';

/**
 * Loop through the UniquedIdentifierConsent
 * Loop through the Processors
 */
class ConsentEntryForForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consent: [],
    };
  }

  onChangeUniqueIdentifierHandler(value) {
    this.reconcialiate(value.uniqueIdentifier.original.Id, value.consent);
  }

  onChangeProcessorHandler(value) {
    this.reconcialiate(value.processor.original.Id, value.hasConsent);
  }

  getUniqueIdentifierConsent(hasBoth) {
    const { consentFormViewModel } = this.props;

    if (!this.hasUniqueIdentifier()) {
      return null;
    }

    const uniqueIdentifiers = consentFormViewModel.uniqueIdentifiers.map(
      (identifier) => {
        const description = (
          <FormattedMessage
            id="module-consent-sharedWith"
            values={{
              name: identifier.consent.integration.Name,
            }}
            defaultMessage="This identifier will be shared with {name}"
          />
        );

        return {
          key: identifier.identifier,
          isRequired: identifier.consent.IsRequired,
          consent: false,
          description,
          original: identifier.consent,
        };
      },
    );

    return (
      <div style={hasBoth ? { marginBottom: '15px' } : {}}>
        <UserConsentIdentifierList
          uniqueIdentifiers={uniqueIdentifiers}
          onChange={(value) => {
            this.onChangeUniqueIdentifierHandler(value);
          }}
        />
      </div>
    );
  }

  getProcessorContent() {
    const { consentFormViewModel } = this.props;

    if (!this.hasProcessor()) {
      return null;
    }

    const processorViewModels = consentFormViewModel.processors.map(
      (processor) => ({
        ...processor,
        Name: processor.integration.Name,
        config: processor.integration,
        isRequired: processor.IsRequired,
        hasConsent: false,
        original: processor,
        identifiers: processor.identifiers,
      }),
    );

    return (
      <UserConsentProcessorList
        processors={processorViewModels}
        onChange={(value) => {
          this.onChangeProcessorHandler(value);
        }}
      />
    );
  }

  reconcialiate(consentId, checked) {
    const { consent } = this.state;
    const { onChange } = this.props;

    const hasFound = consent.find((c) => c.consentId === consentId);

    if (hasFound) {
      hasFound.checked = checked;
    } else {
      consent.push({
        consentId,
        checked,
      });
    }

    this.setState({
      consent: [...consent],
    });

    onChange(consent);
  }

  hasUniqueIdentifier() {
    const { consentFormViewModel } = this.props;
    return (
      consentFormViewModel.uniqueIdentifiers &&
      consentFormViewModel.uniqueIdentifiers.length > 0
    );
  }

  hasProcessor() {
    const { consentFormViewModel } = this.props;
    return (
      consentFormViewModel.processors &&
      consentFormViewModel.processors.length > 0
    );
  }

  render() {
    const { consentFormViewModel } = this.props;
    // const hasBoth = (this.hasProcessor() && this.hasUniqueIdentifier());

    return (
      <div>
        <UserExplanation explanation={consentFormViewModel.shortExplanation} />
        {this.getProcessorContent()}
        <div style={{ textAlign: 'right', padding: '15px 0 0 0' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={consentFormViewModel.privacyLink}
          >
            <FormattedMessage id="module-consent-learnMoreAboutDataRights" />
          </a>
        </div>
      </div>
    );
  }
}

export default ConsentEntryForForm;
