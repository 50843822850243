import React from 'react';
import FormFieldGenerator from './FormFieldGenerator';
import FormWrapper from './FormWrapper';

const FormGenerator = ({ fields = [] }) => (
  <FormWrapper>
    {fields.map((field) => (
      <FormFieldGenerator field={field} />
    ))}
  </FormWrapper>
);

export default FormGenerator;
