import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListItemWrapper = styled.li`
  border-bottom: 1px solid #dee5e7;
  position: relative;
  padding: 15px 5px;
  &:hover {
    background-color: #eeeeee;
  }
`;

const ListItem = ({ style = {}, children, condensed, className }) => {
  const mergedStyle = condensed
    ? { padding: '5px 10px', ...style }
    : { ...style };

  return (
    <ListItemWrapper className={className} style={mergedStyle}>
      {children}
    </ListItemWrapper>
  );
};

ListItem.defaultProps = {
  style: {},
  condensed: false,
};

ListItem.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  condensed: PropTypes.bool,
};

ListItem.defaultProps = {
  style: {},
};

export default ListItem;
