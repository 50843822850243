import React from 'react';
import Alert from 'uxi/Alert';
import { FormattedHTMLMessage } from '@cluedin/locale';

const MergeDataError = () => (
  <div>
    <Alert type="danger">
      <FormattedHTMLMessage id="module-entityMerge-errorMsg" />
    </Alert>
  </div>
);

export default MergeDataError;
