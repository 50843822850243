import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { PrimaryButton } from '@cluedin/form';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import serverBusyUrl from '../../assets/server-busy-illustration.png';

const styles = {
  imgContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '38%',
    marginTop: '50px',
    margin: '50px auto',
  },
  imgWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  img: {
    width: '100%',
  },
};

class Queued extends Component {
  render() {
    const { dispatch } = this.props;

    return (
      <div style={{ margin: '0 auto', textAlign: 'center', maxWidth: '580px' }}>
        <div style={styles.imgContainer}>
          <figure style={styles.imgWrapper}>
            <img style={styles.img} src={`${serverBusyUrl}`} alt="Queued" />
          </figure>
        </div>
        <div style={{ padding: '15px', fontSize: '20px' }}>
          <FormattedMessage id="requestQueued" />
          <br />
          <FormattedMessage id="requestQueuedDoneShortly" />
        </div>
        <div style={{ paddingTop: '15px' }}>
          <PrimaryButton
            click={() => {
              dispatch(push('/'));
            }}
            text={<FormattedMessage id="goBackToHomeScreen" />}
          />
        </div>
      </div>
    );
  }
}

export default connect()(Queued);
