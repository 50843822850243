import get from 'lodash/get';

import EdgePropertiesPanelContainer from '../EdgePropertiesPanelContainer/EdgePropertiesPanelContainer';

const EdgePropertiesPanelAPIContainer = ({
  entityId,
  selectedEdge,
  setSelectedEdge,
  isEdgePropertiesPanelOpen,
  setIsEdgePropertiesPanelOpen,
  nodesAndEdges,
  onNodeClick,
  onGroupedNodeClick,
}) => {
  const edgeByEdgeId = nodesAndEdges?.edges?.filter(
    (edge) => edge?.id === selectedEdge?.[0],
  );
  const edgeType = get(edgeByEdgeId, '[0].label');
  const edgeTypesArray = edgeType
    ?.split('\n')
    ?.filter((string) => string !== '');

  const edgeDirection =
    edgeByEdgeId?.[0]?.to === entityId ? 'incoming' : 'outgoing';

  return (
    <EdgePropertiesPanelContainer
      selectedEdge={selectedEdge}
      setSelectedEdge={setSelectedEdge}
      isEdgePropertiesPanelOpen={isEdgePropertiesPanelOpen}
      setIsEdgePropertiesPanelOpen={setIsEdgePropertiesPanelOpen}
      edgeType={edgeType}
      edgeByEdgeId={edgeByEdgeId}
      nodesAndEdges={nodesAndEdges}
      edgeTypesArray={edgeTypesArray}
      entityId={entityId}
      edgeDirection={edgeDirection}
      onNodeClick={onNodeClick}
      onGroupedNodeClick={onGroupedNodeClick}
    />
  );
};

export default EdgePropertiesPanelAPIContainer;
