"use strict";

exports.__esModule = true;
exports.default = createTheme;
var _common = _interopRequireDefault(require("./colors/common"));
var _grey = _interopRequireDefault(require("./colors/grey"));
var _theme = require("@cluedin/theme");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var lighten = _theme.colorManipulator.lighten,
  darken = _theme.colorManipulator.darken;
function createTheme(variables) {
  var main = variables.main || _common.default.main;
  var mainAccent = variables.accent || _common.default.accent;
  var error = variables.error || _common.default.error;
  var danger = variables.danger || _common.default.danger;
  var warning = variables.warning || _common.default.warning;
  var info = variables.info || _common.default.info;
  var success = variables.success || _common.default.success;
  return {
    button: {
      default: '#e0e0e0'
    },
    text: {
      // The most important text.
      primary: 'rgba(0, 0, 0, 0.87)',
      // Secondary text.
      secondary: lighten('#000000', 0.45),
      // Disabled text have even lower visual prominence.
      disabled: 'rgba(0, 0, 0, 0.38)',
      // Text hints.
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.26)',
      // The color of an active action like an icon button.
      active: 'rgba(0, 0, 0, 0.54)',
      // The color of an hovered action.
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      // The color of a selected action.
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      //'rgba(0, 0, 0, 0.08)',
      // The color of a disabled action.
      disabledText: 'rgba(0, 0, 0, 0.56)',
      // The background color of a disabled action.
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12
      // activatedOpacity: 0.12,
    },

    pureWhite: _common.default.white,
    pureBlack: _common.default.black,
    white: '#F3F3F2',
    black: {
      lighter: '#c2c2c2',
      light: '#d4d4d4',
      main: '#9a9a9a',
      darker: '#595959',
      darkest: '#222222'
    },
    grey: _grey.default,
    common: _common.default,
    primary: {
      lightest: lighten(main, 0.61),
      lighter: lighten(main, 0.41),
      light: lighten(main, 0.21),
      main: main,
      dark: darken(main, 0.21)
    },
    accent: {
      light: lighten(mainAccent, 0.21),
      main: mainAccent,
      dark: darken(mainAccent, 0.21)
    },
    semantic: {
      error: {
        main: error
      },
      danger: {
        main: danger
      },
      warning: {
        main: warning
      },
      info: {
        main: info
      },
      success: {
        main: success
      }
    },
    neutral: {
      darkest: lighten('#000000', 0.11),
      darker: lighten('#000000', 0.21),
      dark: lighten('#000000', 0.45),
      main: lighten('#000000', 0.7),
      light: lighten('#000000', 0.85),
      lighter: lighten('#000000', 0.95)
    },
    divider: lighten('#000000', 0.7),
    charts: {
      color1: '#64cfba',
      color2: '#62acba',
      color3: '#5789b3',
      color4: '#476ca2',
      color5: '#335184',
      color6: '#1b3c4f'
    },
    background: {
      card: _common.default.white
    }
  };
}