import React from 'react';
import Card from './sortable/Card';

const SortableSchemaPropertyList = ({
  schema,
  fields,
  defaultFields,
  onMoveCard,
  onDeleteField,
  onChangeDefaultField,
}) => {
  return fields.map((f, i) => (
    <Card
      defaultFields={defaultFields}
      key={f.field}
      index={i}
      field={f.field}
      disabled={f.disabled}
      id={f.id}
      schema={schema}
      moveCard={onMoveCard}
      onChangeDefaultField={onChangeDefaultField}
      onDeleteField={() => {
        onDeleteField(f.field);
      }}
    />
  ));
};

export default SortableSchemaPropertyList;
