import React from 'react';
import routes from './routes';
import { actionsConfiguration } from '@cluedin/components';
import { Enrich } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';

const { integrations } = actionsConfiguration;

export default {
  pillar: 'preparation',
  name: 'enricher',
  displayName: <FormattedMessage id="enricher" />,
  path: '/enrich',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Enrich,
        name: <FormattedMessage id="enricher" />,
        link: '/admin/preparation/enrich',
        claims: integrations.enrichment.enrichment.view,
      },
    ],
  },
  routes,
};
