import styled from 'styled-components';

export const RecordPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
`;

export const RecordPanelFooter = styled.div`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  border-top: 1px solid #ccc;
  margin-top: auto;
`;

export const RecordPanelFooterWrapper = styled.div`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;

export const RecordPanelAlertWrapper = styled.div`
  padding: 16px;
`;
