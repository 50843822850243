import React, { Fragment } from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { CancelButton as Button } from '@cluedin/form';
import styled from 'styled-components';
import Alert from 'uxi/Alert';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeader,
  TableHeaderColumn,
} from '@cluedin/list-pattern';
import IntegrationNameWithLogoFromId from '../../../../integration/components/containers/IntegrationNameWithLogoFromIdContainer';
import { sortConfigurationByIntegration } from '../../../helpers/sort';

const ButtonWrapper = styled.div`
  padding: 12px 0px;
  text-align: center;
`;

const { integrations } = actionsConfiguration;

const ConfigurationRepartitionCondensedList = ({ configurations, style }) => {
  const configurationRepartitions =
    sortConfigurationByIntegration(configurations);

  return (
    <Fragment>
      <Table style={style} condensed>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn>
              <FormattedMessage id="module-configuration-application" />
            </TableHeaderColumn>
            <TableHeaderColumn style={{ textAlign: 'right' }}>
              <FormattedMessage id="module-configuration-nbAccount" />
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {configurationRepartitions.map((item, i) => (
            <TableRow key={i}>
              <TableRowColumn
                style={{ paddingTop: '5px', paddingBottom: '5px' }}
              >
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <IntegrationNameWithLogoFromId
                    integrationId={item.ProviderId}
                  />
                </div>
              </TableRowColumn>
              <TableRowColumn
                style={{ textAlign: 'left', padding: '4px 24px' }}
              >
                {item.configurations ? item.configurations.length : 0}
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        {(!configurationRepartitions ||
          configurationRepartitions.length === 0) && (
          <div style={{ marginTop: '30px' }}>
            <Alert>
              <FormattedMessage id="module-configuration-noConfigurationAdded" />
            </Alert>
            <ButtonWrapper>
              <Button
                href="/admin/inbound/integration/applications"
                type="primary"
                text={
                  <FormattedMessage id="module-configuration-addConfiguration" />
                }
                actionConfiguration={
                  integrations.availableIntegrations.availableIntegration.view
                }
              />
            </ButtonWrapper>
          </div>
        )}
      </div>
    </Fragment>
  );
};

ConfigurationRepartitionCondensedList.displayName =
  'ConfigurationRepartitionCondensedList';

ConfigurationRepartitionCondensedList.propTypes = {
  configurationRepartitions: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
};

ConfigurationRepartitionCondensedList.defaultProps = {
  configurationRepartitions: [],
};

export default ConfigurationRepartitionCondensedList;
