/* eslint-disable */
import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import SignupFormContainer from './SignupFormContainer';
import PublicContext from '../../../auth/components/composites/PublicContext';
import SignUpImg from '../../assets/sign-up-illustration.png';

const SignUp = () => (
  <NotSignedInLayoutDividedInTwo
    Page={SignupFormContainer}
    Context={PublicContext}
    url={`${SignUpImg}`}
  />
);

export default SignUp;
