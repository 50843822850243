import _ from 'lodash';
import { Flex } from 'uxi/Layout';
import { Action } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { useHistory } from 'react-router';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';

import DashboardTitle from '../modules/core/components/composites/dashboard/DashboardTitle';
import DashboardWidget from '../modules/core/components/composites/dashboard/DashboardWidget';
import {
  OrganizationFeatureFlag,
  useOrganizationFeatureFlagOption,
} from '../modules/featureFlag/hooks/useOrganizationFeatureFlag';

const SectionWrapper = ({ module, children }) => {
  return (
    <>
      <DashboardTitle>{module.displayName}</DashboardTitle>
      <Flex
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
        }}
      >
        {children}
      </Flex>
    </>
  );
};

const ExtensionDashboard = ({
  dashboardExtensions = [],
  name = '',
  actions = [],
}) => {
  const history = useHistory();

  const actionsFromModules = dashboardExtensions
    .filter((extension) => {
      if (extension && extension.featureFlag) {
        const featureFlagValue = useOrganizationFeatureFlagOption(
          extension.featureFlag,
        );
        return featureFlagValue;
      }
      return true;
    })
    .map((extension) => {
      return extension?.actions || [];
    });

  const mergedActions = actions.concat(actionsFromModules);

  const sections = _.compact(
    dashboardExtensions
      .filter((extension) => {
        if (extension && extension.featureFlag) {
          const featureFlagValue = useOrganizationFeatureFlagOption(
            extension.featureFlag,
          );
          return featureFlagValue;
        }
        return true;
      })
      .map((ext) => ext?.section),
  );

  const flattenActions = _.flatten(mergedActions);

  const dashboardWidget = (action) => {
    const GivenIcon = action?.IconComponent
      ? action.IconComponent
      : getAppropriateIcon(action?.icon || 'Help');
    const actionName = action?.name;

    return (
      <DashboardWidget
        betaLabel={
          action?.isBeta && (
            <FormattedMessage id="module-core-dashbioardAction-action-beta" />
          )
        }
        deprecatedLabel={
          action?.isDeprecated && (
            <FormattedMessage id="module-core-dashbioardAction-action-deprecated" />
          )
        }
        className={`test_${name}Dashboard_${actionName}`}
        key={actionName}
      >
        <Action
          menuDescriptor={{
            displayName: actionName,
            icon: <GivenIcon />,
            key: actionName,
            hasNew: false,
            onClick: () => {
              if (action?.onClick) {
                action.onClick?.();
              } else {
                if (action?.link.indexOf('https://') > -1) {
                  const win = window.open(action?.link, '_blank');
                  win.focus();
                } else {
                  history.push(action?.link);
                }
              }
            },
            isPromoted: false,
          }}
          actionConfiguration={action?.claims}
        />
      </DashboardWidget>
    );
  };

  return (
    <div style={{ padding: '15px 30px' }}>
      <DashboardTitle>
        <FormattedMessage id="module-core-dashbioardAction-action" />
      </DashboardTitle>
      <Flex
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
        }}
      >
        {flattenActions.map((action) => {
          const actionFeature = action?.featureName;

          return actionFeature ? (
            <OrganizationFeatureFlag
              MainFeature={() => <></>}
              ExperimentalFeature={dashboardWidget(action)}
              featureName={actionFeature}
            />
          ) : (
            dashboardWidget(action)
          );
        })}
      </Flex>
      {sections?.map?.((section, idx) => {
        return (
          <SectionWrapper key={idx} module={section.module}>
            <section.Component />
          </SectionWrapper>
        );
      })}
    </div>
  );
};

export default ExtensionDashboard;
