import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { pure } from 'recompose';

const AuthTryingToCreateTeamWrapper = styled.div`
  text-align: left;
  font-size: 14px;
  margin-top: 15px;
`;

const AuthTryingToCreateTeam = ({ intl }) => {
  const titleAttribute = intl.formatMessage({
    id: 'AuthTryingToCreateTeamContactOurSalesTeam',
  });

  return (
    <AuthTryingToCreateTeamWrapper>
      <FormattedMessage id="AuthTryingToCreateTeamTryingToCreateTeam" />
      <a
        style={{ paddingLeft: '6px' }}
        href="mailto:sales@cluedin.com"
        title={titleAttribute}
        className="test_auth_contactSales"
        data-test="contact-sales-team-link"
      >
        <FormattedMessage id="AuthTryingToCreateTeamContactOurSalesTeam" />
      </a>
    </AuthTryingToCreateTeamWrapper>
  );
};

export default injectIntl(pure(AuthTryingToCreateTeam));
