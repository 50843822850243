import { getData } from '../../../../goldenRecordList/components/containers/GoldenRecordViewPropertiesPanel/GoldenRecordViewPropertiesPanel';
import { DataTreeView, List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { ExpandedRow } from '../../../../goldenRecordList/components/containers/GoldenRecordViewPropertiesPanel';
import { injectIntl } from '@cluedin/locale';
import { useGetEntityById } from '../../../../entityProperties/hooks/getEntityById';
import { getColumns } from '../entityCoreVocabulary/getColumns';
import { Wrapper, Flex } from '../entityCoreVocabulary/styled';

const NonCoreVocabularyComponent = ({
  intl,
  entity,
  template: { vocabularyKeysNonCore = [] },
}) => {
  const [data, loading] = useGetEntityById(entity.id);
  const entityProperties = data?.virtualization?.entityById?.entityProperties;
  const nonCoreVocabularyKeys = getData(entityProperties)
    .filter((property) => !property.isCluedInCore)
    .map(({ entityProps, ...props }) => {
      const vocabularyElements =
        vocabularyKeysNonCore?.length === 0
          ? entityProps.filter((entity) => !entity.vocabularyKey.isCluedInCore)
          : entityProps.filter(
              (entity) =>
                vocabularyKeysNonCore.includes(entity.name) &&
                !entity.vocabularyKey.isCluedInCore,
            );

      return { entityProps: vocabularyElements, ...props };
    });

  return (
    <Wrapper isEmpty={(nonCoreVocabularyKeys || []).length === 0}>
      <Flex withoutTopBorder>
        <List
          data={nonCoreVocabularyKeys}
          loading={loading}
          offsetTop={295}
          selectable={false}
          showPagination={false}
          DataGrid={DataTreeView}
          columns={getColumns(
            intl.formatMessage({
              id: 'module-entityWidget-vocabularyTitle',
            }),
          )}
          secondLevelDataKey="entityProps"
          noSearch={true}
          notFoundProps={{
            smaller: true,
            message: (
              <FormattedMessage id="module-entityWidget-vocabularyCoreNotFoundPropertiesTitle" />
            ),
            explanation: (
              <FormattedMessage id="module-entityWidget-vocabularyCoreExplanation" />
            ),
            noCreate: true,
          }}
          RowExpandedComponent={({ entityProps }) => (
            <ExpandedRow entityProps={entityProps} />
          )}
        />
      </Flex>
    </Wrapper>
  );
};

export default injectIntl(NonCoreVocabularyComponent);
