import React from 'react';
import Widget from 'uxi/Widget';

const widgetStyles = {
  minWidth: '80px',
  width: '100%',
  maxWidth: '200px',
  maxHeight: '180px',
  minHeight: '180px',
  background: 'white',
  margin: '0 16px 16px 0',
  boxShadow:
    'rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px',
};

const DashboardWidget = ({ children, className }) => (
  <Widget style={widgetStyles}>
    <div className={className}>{children}</div>
  </Widget>
);

export default DashboardWidget;
