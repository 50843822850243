import React from 'react';
import { Loader } from '@cluedin/atoms';
import { PanelFooter } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { CancelButton, PrimaryButton } from '@cluedin/form';

import EntityVocabSelection from '../../../../dataCatalog/components/containers/EntityVocabSelection';

const CleanGqlVocabularyKeyConfiguration = ({
  selectedSchema = [],
  setSelectedSchema,
  onCancel,
  disabledCreation,
  onCreatecleanProject,
  creating,
}) => {
  return (
    <>
      <EntityVocabSelection
        noEntityProperties
        entityKeys={[]}
        defaultColumns={selectedSchema}
        onChange={(newValues) => {
          const vocab = (newValues || []).map((v) => v.property);
          setSelectedSchema(vocab);
        }}
      />

      <PanelFooter>
        <CancelButton
          data-test={'cancelCreateCleanProjectFromSearch'}
          onClick={onCancel}
        >
          <FormattedMessage id="data-source-cancel" />
        </CancelButton>
        <PrimaryButton
          data-test={'createCleanProjectFromSearch'}
          disabled={disabledCreation}
          style={{ marginLeft: '8px' }}
          onClick={onCreatecleanProject}
          startIcon={creating ? <Loader color="#fff" size={14} /> : null}
        >
          <FormattedMessage id="module-clean-cleanCreateButton" />
        </PrimaryButton>
      </PanelFooter>
    </>
  );
};

export default CleanGqlVocabularyKeyConfiguration;
