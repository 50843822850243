"use strict";

exports.__esModule = true;
exports.breakpointKeys = void 0;
exports.default = createBreakpoints;
var _excluded = ["values", "unit", "step"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
// Sorted ASC by size. That's important.
// It can't be configured as it's used statically for propTypes.
var breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl'];

// Keep in mind that @media is inclusive by the CSS specification.
exports.breakpointKeys = breakpointKeys;
function createBreakpoints(breakpoints) {
  if (breakpoints === void 0) {
    breakpoints = {};
  }
  var _breakpoints = breakpoints,
    _breakpoints$values = _breakpoints.values,
    values = _breakpoints$values === void 0 ? {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    } : _breakpoints$values,
    _breakpoints$unit = _breakpoints.unit,
    unit = _breakpoints$unit === void 0 ? 'px' : _breakpoints$unit,
    _breakpoints$step = _breakpoints.step,
    step = _breakpoints$step === void 0 ? 5 : _breakpoints$step,
    other = _objectWithoutPropertiesLoose(_breakpoints, _excluded);
  var keys = Object.keys(values);
  function up(key) {
    var value = typeof values[key] === 'number' ? values[key] : key;
    return "@media (min-width:" + value + unit + ")";
  }
  function down(key) {
    var endIndex = keys.indexOf(key) + 1;
    var upperbound = values[keys[endIndex]];
    if (endIndex === keys.length) {
      // down from the biggest breakpoint applies to all sizes
      return up(0);
    }
    var value = typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
    return "@media (max-width:" + (value - step / 100) + unit + ")";
  }
  function between(start, end) {
    var endIndex = keys.indexOf(end);
    if (endIndex === keys.length - 1) {
      return up(start);
    }
    return "@media (min-width:" + (typeof values[start] === 'number' ? values[start] : start) + unit + ") and " + ("(max-width:" + ((endIndex !== -1 && typeof values[keys[endIndex + 1]] === 'number' ? values[keys[endIndex + 1]] : end) - step / 100) + unit + ")");
  }
  function only(key) {
    return between(key, key);
  }
  function width(key) {
    return values[key];
  }
  return _extends({
    keys: keys,
    values: values,
    up: up,
    down: down,
    between: between,
    only: only,
    width: width
  }, other);
}