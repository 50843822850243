import { FormattedMessage } from '@cluedin/locale';
import { isEmpty, isAlphaNumeric, isLongerThen, isValidUrl } from '../index';

const errorStyle = { color: '#ef5858' };

export const OnlyAlphaNumbericErrorMessage = () => {
  return (
    <div style={{ paddingBottom: '16px' }}>
      <span className="__test_errorMessage" style={errorStyle}>
        <FormattedMessage id="data-source-hint-enter-data-source-name" />
      </span>
    </div>
  );
};

export const NoSpaceErrorMessage = () => {
  return (
    <div style={{ paddingBottom: '16px' }}>
      <span className="__test_errorMessage" style={errorStyle}>
        <FormattedMessage id="data-source-hint-enter-data-source-no-space" />
      </span>
    </div>
  );
};

export const NotEmptyErrorMessage = () => {
  return (
    <div style={{ paddingBottom: '16px' }}>
      <span className="__test_errorMessage" style={errorStyle}>
        <FormattedMessage id="data-source-hint-enter-data-source-empty-name" />
      </span>
    </div>
  );
};

export const NotLongErrorMessage = ({ signsCount = 255 }) => {
  return (
    <div style={{ paddingBottom: '16px' }}>
      <span
        className="__test_errorMessage"
        data-test="error-long-name"
        style={errorStyle}
      >
        <FormattedMessage
          id="data-source-hint-enter-long-name"
          values={{ signsCount }}
        />
      </span>
    </div>
  );
};

export const RequiredMessage = () => {
  return (
    <div style={{ paddingBottom: '16px' }}>
      <span
        className="__test_errorMessage"
        data-test="error-required-name"
        style={errorStyle}
      >
        <FormattedMessage id="data-source-required-message-name" />
      </span>
    </div>
  );
};

export const NotValidUrl = () => {
  return (
    <div style={{ paddingBottom: '16px' }}>
      <span
        className="__test_errorMessage"
        data-test="error-required-name"
        style={errorStyle}
      >
        <FormattedMessage id="data-source-hit-invalidUrl" />
      </span>
    </div>
  );
};

export const getClassicValidationMessage = (value, signsCount = 256) => {
  if (!value) {
    return <RequiredMessage />;
  }

  if (isEmpty(value)) {
    return <NotEmptyErrorMessage />;
  }

  if (!isAlphaNumeric(value)) {
    return <OnlyAlphaNumbericErrorMessage />;
  }

  if (isLongerThen(value, signsCount)) {
    return <NotLongErrorMessage signsCount={signsCount} />;
  }

  return null;
};

export const getOptionalStringButNotRequired = (value, signsCount = 256) => {
  if (!value) {
    return null;
  }

  if (isEmpty(value)) {
    return <NotEmptyErrorMessage />;
  }

  if (isLongerThen(value, signsCount)) {
    return <NotLongErrorMessage signsCount={signsCount} />;
  }

  return null;
};

export const getUrlValidationRequired = (value, signsCount = 500) => {
  if (!value) {
    return <RequiredMessage />;
  }

  if (isEmpty(value)) {
    return <NotEmptyErrorMessage />;
  }

  if (isLongerThen(value, signsCount)) {
    return <NotLongErrorMessage signsCount={signsCount} />;
  }

  if (!isValidUrl(value)) {
    return <NotValidUrl />;
  }

  return null;
};
