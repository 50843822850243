import UserAvatar from '../composites/UserAvatar';
import UserName from '../composites/UserName';
import {
  withAuthorFromId,
  withAuthorFromIdFromState,
} from '../../../user/components/Hocs/withAuthorFromId';

export const UserAvatarFromIdFromState = withAuthorFromIdFromState(
  UserAvatar,
  (user) => {
    return user && user.Account
      ? {
          username: user.Account.UserName,
        }
      : {};
  },
);

export const UserNameFromId = withAuthorFromId(UserName, (user) => {
  return user && user.Account
    ? {
        username: user.Account.UserName,
      }
    : {};
});

export default withAuthorFromId(UserAvatar, (user) => {
  return user && user.Account
    ? {
        username: user.Account.UserName,
      }
    : {};
});
