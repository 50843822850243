import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulseName = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 0.6; }
  100% { opacity: 0.4; }
`;

const LinkWrapper = styled.div`
  width: 150px;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 22px;
  animation: ${pulseName} 1s linear infinite;
`;

const LinkPlaceholder = ({ style = {} }) => (
  <LinkWrapper style={style}>
    <div
      style={{ width: '100%', height: '16px', background: 'rgb(21, 169, 169)' }}
    />
  </LinkWrapper>
);

export default LinkPlaceholder;
