import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRecordPanelWorkflow } from '../../../hocs/withRecordPanelWorkflow';

const RecordPanelContainer = ({
  recordPanelData,
  openRecordPanelWorkflow,
  nodeDataType,
  setNodeDataType,
}) => {
  useEffect(() => {
    if (nodeDataType === 'DataPart') {
      openRecordPanelWorkflow();
    }
  }, [nodeDataType]);

  return <span />;
};

export default withRecordPanelWorkflow(RecordPanelContainer);

RecordPanelContainer.propTypes = {
  openRecordPanelWorkflow: PropTypes.func,
  nodeDataType: PropTypes.string,
  setNodeDataType: PropTypes.func,
};

RecordPanelContainer.defaultProps = {
  openRecordPanelWorkflow: () => {},
  nodeDataType: '',
  setNodeDataType: PropTypes.func,
};
