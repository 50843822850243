/*eslint-disable */
var __cluedIn = window.__cluedIn || {};
import 'whatwg-fetch';
/*eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';
import SetupApp from './SetupApp';
import ErrorBoundary from '../modules/error/components/containers/ErrorBoundary';

import homeRoutes from '../modules/home/routes';
import EntitiesMergePage from '../modules/entityMerge/components/pages/EntitiesMergePage';
import EntitiesComparePage from '../modules/entityMerge/components/pages/EntitiesComparePage';
import ErrorPageWrapper from '../modules/error/components/composites/ErrorPageWrapper';
import { OnlyClientIdSubDomain } from '../modules/core/components/Hocs/SubdomainChooser';

import UserSettingsWrapperComposite from '../modules/user/components/composites/structure/UserSettingsWrapperComposite';

// import GDPRRoutes from '../modules/GDPR/route';
import FrameRoutes from '../modules/frame/route';
import gdprJsonReportRoutes from '../modules/GDPR/reportRoute';
import AuthRoutes from '../modules/auth/routes';
import PublicRoutes from '../modules/public/route';
// import ConsentRoute from '../modules/consent/routes';
//Debug
// import DebugRoutes from '../modules/debug/routes';

//Data Modeling
// import DataBreachRoutes from '../modules/dataBreach/routes';
// import RetentionRoutes from '../modules/retention/routes';
import SearchRoutes from '../modules/search/routes';
// import IntegrationRoutes, { ManagementRoutes } from '../modules/integration/route';
import { ManagementRoutes } from '../modules/integration/route';
import StreamRoutes from '../modules/stream/routes';

import ChangePasswordPage from '../modules/user/components/pages/ChangePassword';
import AccessDeniedContainer from '../modules/error/components/containers/AccessDeniedContainer';
import ConfigurationPageWrapper from '../modules/configuration/components/pages/ConfigurationPageWrapper';
import NotFound from '../modules/error/components/pages/NotFoundPage';
import ServiceUnavailable from '../modules/error/components/pages/ServiceUnavailable';
import EntityDenied from '../modules/error/components/pages/EntityDenied';
import Queued from '../modules/error/components/pages/Queued';
import AddPreventExit from '../modules/core/components/Hocs/AddPreventExit';

import extensionCompiler from './extensionCompiler';
import defaultPillars from './defaultPillars';
import defaultModules from './defaultModules';

import superEntityListExampleRoutes from '../modules/superEntityList/examples-routes';

const modules = [
  ...superEntityListExampleRoutes,
  ...homeRoutes,
  ...AuthRoutes,
  FrameRoutes,
  gdprJsonReportRoutes,
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '/202',
    component: Queued,
  },
  {
    path: '/403',
    component: AccessDeniedContainer,
  },
  {
    path: '/503',
    component: ServiceUnavailable,
  },
  {
    path: '/entity/merge/:targetId',
    component: OnlyClientIdSubDomain(AddPreventExit(EntitiesMergePage)),
  },
  {
    path: '/entity/compare/:targetId',
    component: OnlyClientIdSubDomain(AddPreventExit(EntitiesComparePage)),
  },
  ...PublicRoutes,
  ...StreamRoutes,
  ...SearchRoutes,
  {
    path: '/admin',
    routes: [
      ...ManagementRoutes,
      {
        path: '/configuration/:integrationId/:configurationId',
        component: OnlyClientIdSubDomain(ConfigurationPageWrapper),
      },
    ],
  },
  {
    path: '/settings',
    component: OnlyClientIdSubDomain(UserSettingsWrapperComposite),
    routes: [
      {
        path: '/user',
        component: ChangePasswordPage,
      },
    ],
  },
  {
    path: '/error',
    component: OnlyClientIdSubDomain(ErrorPageWrapper),
    routes: [
      {
        path: '/denied',
        component: EntityDenied,
      },
    ],
  },
];

const defaultProduct = {
  pillars: defaultPillars,
  modules: defaultModules,
};

const injectWithFrame = (__cluedIn.injectWithFrame = (
  DOMElement,
  extensions,
  widgets = [],
) => {
  const ext = extensionCompiler(defaultProduct, extensions);

  const root = createRoot(DOMElement);

  root.render(
    <ErrorBoundary>
      <SetupApp extensions={ext} modules={modules} widgets={widgets} />
    </ErrorBoundary>,
  );
});

export default injectWithFrame;
