import React from 'react';
import { PrimaryButton } from '@cluedin/form';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { withCountDown } from '../Hocs/withCountDown';
import ServiceUnavailable from '../../../error/components/pages/ServiceUnavailable';

const NoToken = ({ countDown, goToLogin }) => (
  <div style={{ margin: '30x' }}>
    <Alert isBanner type="danger">
      <div>
        <FormattedHTMLMessage id="module-core-html-notConnected" />
      </div>
      <FormattedMessage
        id="module-error-sessionExpiredcountDown"
        values={{ value: countDown }}
      />
      <div style={{ marginTop: '12px' }}>
        <PrimaryButton text="Log back in" onClick={goToLogin} />
      </div>
    </Alert>
    <div style={{ paddingTop: '50px' }}>
      <ServiceUnavailable
        status={401}
        message={<FormattedMessage id="module-core-html-notConnected" />}
      />
    </div>
  </div>
);

export default withCountDown(NoToken);
