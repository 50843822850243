import React from 'react';
import { TextPlaceholder, IconPlaceholder } from '@cluedin/components';
import { GhostGraphWrapper } from './GhostGraph.styles';
import { GhostCardContainer, GhostCardItem } from './GhostCard.styles';

const GhostGraph = () => {
  return (
    <GhostGraphWrapper>
      <GhostCardContainer>
        <GhostCardItem>
          <IconPlaceholder />
        </GhostCardItem>
        <GhostCardItem>
          <TextPlaceholder />
        </GhostCardItem>
      </GhostCardContainer>
    </GhostGraphWrapper>
  );
};

export default GhostGraph;
