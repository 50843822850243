import React, { memo } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

// import { useTheme } from '../../../../../components/ThemeProvider/JUST_FOR_DEV__ThemeProvider';

import { MenuFooter } from './MenuFooter';
import { MenuTopItem } from './MenuTopItem';
import { useLayoutContext } from '../../../../../shared/context/layoutContext';

const MenuElement = styled.aside`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  box-sizing: border-box;
  background-color: ${({ menuTheme }) => menuTheme?.backgroundColor};
  transition: ${({ theme: { transition } }) => transition.default};
  width: ${({ isMinimized }) => (isMinimized ? '68px' : '216px')};
  padding-top: 24px;

  nav {
    flex: 1;
  }
`;

export const Menu = memo(({ menuDescriptors }) => {
  const { menu: menuTheme } = useTheme();
  const { isMenuMinimized, toggleMenu } = useLayoutContext();

  return (
    <MenuElement
      isMinimized={isMenuMinimized}
      className="test_globalMenu"
      breakpoint="960px"
      menuTheme={menuTheme}
    >
      <nav>
        {menuDescriptors?.map((i) => (
          <MenuTopItem
            isMinimized={isMenuMinimized}
            key={i.key}
            href={i.href}
            icon={i.icon}
            subItems={i.subItems}
            isActive={i.isActive}
            displayName={i.displayName}
            menuTheme={menuTheme}
          />
        ))}
      </nav>

      <MenuFooter
        menuTheme={menuTheme}
        toggleMenu={toggleMenu}
        isMenuMinimized={isMenuMinimized}
      />
    </MenuElement>
  );
});
