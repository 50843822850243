import React from 'react';
import { Route } from 'react-router-dom';
import toPairs from 'lodash/toPairs';
import { useUserHasAccess } from '@cluedin/components';
import PageAccessDenied from '../../../error/components/composites/PageAccessDenied';

const formatClaims = (claims) =>
  toPairs(claims).map(([k, v]) => ({ claim: k, level: v[0] }));

const ProtectedRoute = ({ routeClaims, render, ...rest }) => {
  const hasAccess = useUserHasAccess(routeClaims);

  return (
    <Route
      {...rest}
      render={(props) => {
        return hasAccess ? (
          render(props)
        ) : (
          <PageAccessDenied
            onReturnToHome={() => props.history.push('/')}
            claims={formatClaims(routeClaims)}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
