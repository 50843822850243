import { createSelector } from 'reselect';

export const hasFeature = (features = [], featureName = '') =>
  features.indexOf(featureName) > -1;

export const hasFeatureRole = (feature, currentUser) => {
  if (!currentUser) {
    return false;
  }

  return 'Account' in currentUser
    ? currentUser.Account.Roles.indexOf(feature) > -1
    : 'client' in currentUser && currentUser.client.Roles.indexOf(feature) > -1;
};

export const hasAllFeatureRole = (features, currentUser) => {
  if (!currentUser || !features || (features && !features.length)) {
    return false;
  }
  if (!Array.isArray(features)) {
    console.warn('features must be array');
    return false;
  }

  const RolesList =
    'Account' in currentUser
      ? currentUser.Account.Roles
      : ('client' in currentUser && currentUser.client.Roles) || []; // .indexOf(feature) > -1;

  if ((RolesList && !RolesList.length) || !Array.isArray(RolesList)) {
    return false;
  }

  return (
    RolesList.map((f) => features.includes(f)).filter(
      (hasFeatureX) => hasFeatureX,
    ).length === features.length
  );
};

export const hasSomeFeatureRole = (features, currentUser) => {
  if (!currentUser || !features) {
    return false;
  }
  if (!Array.isArray(features)) {
    console.warn('features must be array');
    return false;
  }

  const RolesList =
    'Account' in currentUser
      ? currentUser.Account.Roles
      : ('client' in currentUser && currentUser.client.Roles) || []; // .indexOf(feature) > -1;

  if ((RolesList && !RolesList.length) || !Array.isArray(RolesList)) {
    return false;
  }

  return RolesList.some((f) => features.some((x) => x === f));
};

export const hasRoles = (feature, currentUser) =>
  Array.isArray(feature)
    ? hasSomeFeatureRole(feature, currentUser)
    : hasFeatureRole(feature, currentUser);

const getOrg = ({ org }) => org;
const getPalette = ({ palette }) => palette;

export const getThemeColors = createSelector(
  [getOrg],
  [getPalette],
  ({ primary, secondary }) => {
    return {
      primary,
      secondary,
    };
  },
);

export default {
  hasFeature,
  hasRoles,
  hasSomeFeatureRole,
  getThemeColors,
};
