import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getCurrentToken, url } from '../../config';
import authLink from './authLink';
import errorLink from './errorLink';
import cleanTypeNameLink from './cleanTypeNameLink';

const legacyLink = new HttpLink({
  uri: url.graphQL,
});

const httpLegacyLinkWithaddons = ApolloLink.from([
  cleanTypeNameLink,
  errorLink,
  authLink,
  legacyLink,
]);

export const legacyClient = new ApolloClient({
  link: httpLegacyLinkWithaddons,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${getCurrentToken()}`,
  },
});

const { mainGraphQLUrl, mainGraphQLSocket } = url;

const httpLink = new HttpLink({
  uri: mainGraphQLUrl,
});

const httpLinkWithCleanTypeName = ApolloLink.from([
  cleanTypeNameLink,
  errorLink,
  authLink,
  httpLink,
]);

let splitLink;

if (mainGraphQLSocket) {
  const wsLink = new WebSocketLink({
    uri: mainGraphQLSocket,
    options: {
      reconnect: true,
      connectionParams: () => ({
        authToken: `Bearer ${getCurrentToken()}`,
      }),
    },
  });

  const wsLinkWihtMiddleware = ApolloLink.from([
    cleanTypeNameLink,
    authLink,
    wsLink,
  ]);

  splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLinkWihtMiddleware,
    httpLinkWithCleanTypeName,
  );
}

export const authenticatedClient = new ApolloClient({
  link: splitLink || httpLinkWithCleanTypeName,
  connectToDevTools: true,
  cache: new InMemoryCache(),
  //{ addTypename: false }
});

export default authenticatedClient;
