import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotificationCenterTabsWrapper from '../composites/NotificationCenterTabsWrapper';
import { hasFeature } from '../../../../config';

import LazyFollowingEntitiesContentPanel from '../../../stream/components/containers/menu/FollowingEntitiesContentPanel';
import LazyKeepInTheLoopContentPanel from '../../../stream/components/containers/menu/KeepInTheLoopContentPanel';
import NotificationListContainer from './NotificationListContainer';

class NotificationCenterContainer extends Component {
  render() {
    const {
      hasSavedSearchFeature,
      hasFollowFeature,
      notifications,
      loadingNotifications,
      loadMoreNotifications,
      errorsNotifications,
      showMore,
      onClose,
    } = this.props;

    return (
      <NotificationCenterTabsWrapper
        hasSavedSearchFeature={hasSavedSearchFeature}
        hasFollowFeature={hasFollowFeature}
        notifications={
          <NotificationListContainer
            notifications={notifications}
            showMore={showMore}
            onClose={onClose}
            loadingNotifications={loadingNotifications}
            errorsNotifications={errorsNotifications}
            loadMoreNotifications={loadMoreNotifications}
          />
        }
        followedEntities={<LazyFollowingEntitiesContentPanel />}
        savedSearches={<LazyKeepInTheLoopContentPanel />}
      />
    );
  }
}
const mapStateToProps = () => ({
  hasSavedSearchFeature: hasFeature('savedSearches'),
  hasFollowFeature: hasFeature('follow'),
});

export default connect(mapStateToProps)(NotificationCenterContainer);
