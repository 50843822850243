import { clientId } from '../../config';
import { authRequest } from '../core/helpers/request';

export const inviteUser = ({ email, clientId }) =>
  authRequest('POST', 'api/user/invite', {
    Email: email,
    Clientid: clientId,
  });

export const isEmailExisting = (email) =>
  authRequest(
    'GET',
    `api/account/emailavailable?email=${email}&clientid=${clientId}`,
  ).then((resp) => resp.body);
