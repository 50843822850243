import { EntityTypeIcon } from '@cluedin/components';

import EntityTileEntityTypeTooltip from '../../../composites/entityTypes/EntityTileEntityTypeTooltip';
import { getEntityConfig } from '../../../../../wms/selectors';

const TileEntityTypeIcon = ({ entityType, entityTypesConfigurations }) => {
  const entityTypeConfig = getEntityConfig(
    entityTypesConfigurations,
    entityType,
  );

  return (
    <EntityTileEntityTypeTooltip
      isConfigured={!!entityTypeConfig}
      entityType={entityType}
    >
      <EntityTypeIcon entityConfig={entityTypeConfig} />
    </EntityTileEntityTypeTooltip>
  );
};

export default TileEntityTypeIcon;
