import React from 'react';
import PropTypes from 'prop-types';
import { User } from 'uxi/Icons';
import Toast from '../../../../core/components/composites/Toast';

const NewUserInvitedNotification = ({ index, invitation, onCloseClick }) => {
  const title = `${invitation.email} was invited`;
  const message = <div>by&nbsp;{invitation.userName}</div>;

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={User}
    />
  );
};

NewUserInvitedNotification.propTypes = {
  index: PropTypes.any.isRequired,
  invitation: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default NewUserInvitedNotification;
