import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { List } from '@cluedin/list-pattern';
import Tag from '../../../../glossary/components/composites/Tag';

const TypeTag = ({ type }) => {
  return <Tag label={type} />;
};

const SelectionVocabAndEntityProperty = [
  {
    displayName: <FormattedMessage id="module-dataCatalog-property" />,
    property: 'property',
  },
  {
    displayName: <FormattedMessage id="module-dataCatalog-type" />,
    property: 'type',
    Component: TypeTag,
  },
];

const EntityVocabSelectionList = ({
  columns = [],
  onAddEntityProperty,
  onAddVocabulary,
  onDeleteColumns,
  noEntityProperties,
}) => {
  const creationButtons = noEntityProperties
    ? [
        {
          key: 'addVocabProp',
          label: <FormattedMessage id="module-dataCatalog-addVocabProp" />,
          onClick: () => {
            onAddVocabulary();
          },
        },
      ]
    : [
        {
          key: 'addEntityProperty',
          label: <FormattedMessage id="module-dataCatalog-addEntityProperty" />,
          onClick: () => {
            onAddEntityProperty();
          },
        },
        {
          key: 'addVocabProp2',
          label: <FormattedMessage id="module-dataCatalog-addVocabProp" />,
          onClick: () => {
            onAddVocabulary();
          },
        },
      ];

  return (
    <List
      dataGridName="stream-select-vocab"
      offsetTop={200}
      noSearch
      data={columns}
      withToolbar={true}
      showPagination={false}
      actions={[
        {
          label: <FormattedMessage id="module-dataCatalog-removeProps" />,
          action: (properties) => {
            onDeleteColumns(properties.map((c) => ({ property: c })));
          },
        },
      ]}
      creationLabel={<FormattedMessage id="module-dataCatalog-addProperty" />}
      creation={creationButtons}
      total={columns.length}
      propertyKey="property"
      notFoundProps={{
        buttonLabel: <FormattedMessage id="module-dataCatalog-addProps" />,
        message: (
          <FormattedMessage id="module-dataCatalog-addAtLeastOneProps" />
        ),
        explanation: (
          <FormattedMessage id="module-dataCatalog-addPropsExplanation" />
        ),
        noCreate: true,
      }}
      columns={SelectionVocabAndEntityProperty}
    />
  );
};

export default EntityVocabSelectionList;
