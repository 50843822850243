import { useContext } from 'react';

import {
  StaticGoldenRecordListContext,
  StaticGoldenRecordListContextType,
} from './StaticGoldenRecordListContext';

export const useStaticGoldenRecordList =
  (): StaticGoldenRecordListContextType => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const context = useContext(StaticGoldenRecordListContext)!;

    if (context === undefined) {
      throw new Error(
        'useGoldenRecordList must be used within a StaticGoldenRecordListContext',
      );
    }

    return context;
  };
