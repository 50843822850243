import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { Widget } from 'uxi/Widget';
import DomainUsers from '../composites/DomainUsers';
import {
  fetchPotentialUsersIfNeeded,
  shouldOpenInviteUserDialog,
} from '../../actions';

class AllPotentialUsers extends Component {
  static propTypes = {
    potentialUsers: PropTypes.array,
    isFetchingPotentialUsers: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    potentialUsers: [],
    isFetchingPotentialUsers: false,
  };

  componentDidMount() {
    const { isFetchingPotentialUsers } = this.props;

    if (!isFetchingPotentialUsers) {
      this.props.dispatch(fetchPotentialUsersIfNeeded());
    }
  }

  inviteClickHandler(email, name) {
    this.props.dispatch(shouldOpenInviteUserDialog(email, name));
  }

  render() {
    const { potentialUsers, isFetchingPotentialUsers } = this.props;
    const arrayPotentialUsers = Array.isArray(potentialUsers)
      ? potentialUsers
      : [];
    const isFetching = isFetchingPotentialUsers || !potentialUsers;

    let content;

    const filteredPotentialUsers = arrayPotentialUsers.filter(
      (p) =>
        p.EntityType === '/Person' ||
        p.EntityType === '/User' ||
        p.EntityType === '/Infrastructure/Contact',
    );

    if (filteredPotentialUsers && filteredPotentialUsers.length > 0) {
      content = (
        <DomainUsers
          inviteClick={this.inviteClickHandler.bind(this)}
          potentialUsers={filteredPotentialUsers}
        />
      );
    } else {
      content = (
        <Alert>
          <FormattedMessage id="module-user-noPotentialUser" />
        </Alert>
      );
    }

    return (
      <Widget
        style={{
          background: '#fff',
        }}
        isLoading={isFetching}
        title={<FormattedMessage id="AllPotentialUsers.Title" />}
      >
        {content}
      </Widget>
    );
  }
}

const mapToStateProps = ({
  user: { potentialUsers, isFetchingPotentialUsers },
}) => ({
  potentialUsers,
  isFetchingPotentialUsers,
});

export default connect(mapToStateProps)(AllPotentialUsers);
