import React from 'react';
import { connect } from 'react-redux';
import RetentionList from '../../composites/lists/RetentionList';
import { shouldDeleteRetentionPolicies } from '../../../actions';

const RetentionListContainer = ({ data, onDelete }) => (
  <RetentionList data={data} onDelete={onDelete} />
);

const mapToStateProps = () => {};

const mapDispatchToProps = (dispatch) => ({
  onDelete(ids) {
    dispatch(shouldDeleteRetentionPolicies(ids));
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(RetentionListContainer);
