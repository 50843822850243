import { useQuery, QueryResult } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

interface EntityHistorySummary {
  groupName: string;
  sources: EntityHistorySummarySource[];
}

export type Params = {
  sourceName?: string;
  sortedDateFilter?: string;
};

interface EntityHistorySummarySource {
  providerDefinitionId: string;
  sourceName: string;
  changedAt: string;
  changedBy: string;
  modifiedDate: string;
  createdDate: string;
  discoveryDate: string;
  sortDate: string;
  partId: string;
  changeType: string;
  changeCount: number;
  sourceDetails: {
    displayName: string;
    sourceObjectId: string;
    sourceTypeId: string;
  };
}

interface EntitySummaryQueryVariables {
  id: string;
  params: Params;
}

const GET_ENTITY_HISTORY_SUMMARY = gql`
  query entityHistorySummary($id: ID!, $params: JSON) {
    virtualization {
      entityHistorySummary(id: $id, params: $params) {
        groupName
        sources {
          providerDefinitionId
          sourceName
          changedAt
          changedBy
          modifiedDate
          createdDate
          discoveryDate
          sortDate
          partId
          changeType
          changeCount
          sourceDetails {
            displayName
            sourceObjectId
            sourceTypeId
          }
        }
      }
    }
  }
`;

export const useQueryEntitySummaryById = (
  variables: EntitySummaryQueryVariables,
) => {
  const { data, loading, error, refetch } = useQuery<
    EntityHistorySummary,
    EntitySummaryQueryVariables
  >(GET_ENTITY_HISTORY_SUMMARY, {
    variables,
    fetchPolicy: 'network-only',
  });

  const entitySummary: EntityHistorySummary | undefined = get(
    data,
    'virtualization.entityHistorySummary',
  );

  return {
    data: entitySummary,
    loading,
    error,
    refetch,
  };
};
