import React from 'react';
import Img from 'uxi/Img';
import { TableRow, TableRowColumn, TableBody } from '@cluedin/list-pattern';
import Table from './TableExtended';
import Section from './Section';
import ValueWrapper from './ValueWrapper';

const ProfileInformation = ({ jsonReport }) => {
  const { JobTitle, Name, Picture, PropertyBag } = jsonReport;

  return (
    <Section jsonReport={jsonReport}>
      <h2>Profile Information</h2>
      <Img src={Picture} alt={`${Name}'s profile picture`} />
      <h4>{Name}</h4>
      <p>{JobTitle}</p>
      <br />
      <br />
      <Table>
        <TableBody>
          {PropertyBag &&
            PropertyBag.length > 0 &&
            PropertyBag.map(({ Key, Value }) => (
              <TableRow>
                <TableRowColumn width="30%">{Key}:</TableRowColumn>
                <TableRowColumn width="70%">
                  <ValueWrapper>{Value}</ValueWrapper>
                </TableRowColumn>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <hr />
    </Section>
  );
};

export default ProfileInformation;
