import { FC, memo, useCallback } from 'react';
import { useTheme } from '@cluedin/theme';
import { IconButton, IButtonStyles } from '@fluentui/react';

import { useAppSearch } from '../context/useAppSearch';

export const AppSearchCancelButton: FC = memo(() => {
  const theme = useTheme();
  const { handleCancel } = useAppSearch();

  const getStyles = useCallback((): Partial<IButtonStyles> => {
    const rootColorsStyles = {
      backgroundColor: 'transparent',
      color: theme.palette.neutralDark,
    };

    return {
      root: {
        right: '32px',
        position: 'absolute',
        ...rootColorsStyles,
      },
      rootHovered: rootColorsStyles,
      rootFocused: rootColorsStyles,
      rootPressed: rootColorsStyles,
    };
  }, [theme]);

  const styles = getStyles();

  return (
    <IconButton
      styles={styles}
      onClick={handleCancel}
      iconProps={{ iconName: 'Cancel' }}
    />
  );
});
