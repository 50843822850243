import styled from 'styled-components';
import { useId } from '@fluentui/react-hooks';
import { Checkbox } from '@cluedin/form';
import { get } from 'lodash';
import { FormattedMessage } from '@cluedin/locale';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useTheme, colorManipulator } from '@cluedin/theme';

const ItemWrapper = styled.div`
  box-sizing: border-box;
  flex-grow: 99;
  height: 100%;
  min-height: 100%;
  width: 100%;
`;

const EntitySelectableListItemWrapperUI = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  background: white;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);

  & > * {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    box-sizing: border-box;
  }

  .test_EntitySelectable_CheckboxWrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    background: ${({ theme }) =>
      colorManipulator.fade(theme.palette.themePrimary, 0.8)};
    ${({ isMerging }) => isMerging && 'border: 1px solid #cecece;'};
    ${({ noBorderTop }) => noBorderTop && 'border-top: none;'};
    display: flex;
    align-items: center;
    justify-content: center;

    svg * {
      fill: white !important;
    }
  }

  & > * + * {
    ${({ disabled }) => disabled && 'opacity: .25'};
  }
`;

const CheckboxWrapperUI = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  height: 100%;
  max-width: ${({ isMerging }) => (isMerging ? '40px' : '0px')};
  min-width: ${({ isMerging }) => (isMerging ? '40px' : '0px')};
`;

const TileViewSelectableWrapper = ({
  children,
  selected,
  isMerging,
  itemWrapperStyle,
  wrapperStyle,
  entity,
  onChange,
  restrictionValue,
  uniqueSelectionProperty,
}) => {
  const theme = useTheme();
  const tooltipHostId = useId();

  const tooltipOverlay = (
    <span>
      <FormattedMessage id="module-goldenRecordList-selectMerge-part1" />
      <br />
      <FormattedMessage id="module-goldenRecordList-selectMerge-part2" />
    </span>
  );

  const valueForRestrictionProperty = get(entity, uniqueSelectionProperty);
  const disabled =
    isMerging &&
    restrictionValue &&
    valueForRestrictionProperty !== restrictionValue;

  return (
    <EntitySelectableListItemWrapperUI
      selected={selected}
      disabled={disabled}
      isMerging={isMerging}
      style={wrapperStyle}
      theme={theme}
    >
      {disabled ? (
        <TooltipHost
          styles={{
            root: {
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              width: '40px',
              position: 'absolute',
            },
          }}
          content={tooltipOverlay}
          id={tooltipHostId}
          calloutProps={{ gapSpace: 0 }}
        >
          <CheckboxWrapperUI
            className="test_EntitySelectable_CheckboxWrapper"
            isMerging={isMerging}
          >
            <div style={{ height: '20px', width: '20px' }}>
              <Checkbox
                onChange={(e) => {
                  const checked = e.target.checked;
                  onChange(checked, entity.id, entity);
                }}
                checked={selected}
                disabled={disabled}
                id={entity.id}
              />
            </div>
          </CheckboxWrapperUI>
        </TooltipHost>
      ) : (
        <CheckboxWrapperUI
          className="test_EntitySelectable_CheckboxWrapper"
          isMerging={isMerging}
        >
          <div style={{ height: '20px', width: '20px' }}>
            <Checkbox
              onChange={(e) => {
                const checked = e.target.checked;
                onChange(checked, entity.id, entity);
              }}
              checked={selected}
              disabled={disabled}
              id={entity.id}
            />
          </div>
        </CheckboxWrapperUI>
      )}

      <ItemWrapper style={itemWrapperStyle}>{children}</ItemWrapper>
    </EntitySelectableListItemWrapperUI>
  );
};

export default TileViewSelectableWrapper;
