import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

export const CluedInRegularTextStyle = {
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#38373a',
  },
};

const CluedInRegularText = ({ children, style }) => (
  <div style={{ ...style, ...CluedInRegularTextStyle.text }}>{children}</div>
);

CluedInRegularText.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

CluedInRegularText.defaultProps = {
  style: {},
};

export default pure(CluedInRegularText);
