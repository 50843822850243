import { useState, useEffect } from 'react';
import filter from 'lodash/filter';
import { FormattedMessage } from '@cluedin/locale';
import EntityVocabSelectionList from './EntityVocabSelectionList';
import { withDialogWorkflow } from '../../../../core/components/Hocs/workflows/withDialogWorkflow';
import AddEntityProperty from './AddEntityProperty';
import AddVocabulary from './AddVocabulary';

const EnhancedColumnSelectionList = withDialogWorkflow(
  EntityVocabSelectionList,
  AddEntityProperty,
  {
    dialogName: 'entityPropertyDialog',
    title: <FormattedMessage id="module-dataCatalog-titleEntityProperty" />,
    mainButtonLabel: (
      <FormattedMessage id="module-dataCatalog-saveSelectionAction" />
    ),
    methodName: 'onAddEntityProperty',
    width: '90vh',
    height: '85vh',
    bodyStyles: {
      padding: 0,
      overflowY: 'hidden',
    },
  },
);

const EnhancedColumnSelectionListWithVocabulary = withDialogWorkflow(
  EnhancedColumnSelectionList,
  AddVocabulary,
  {
    dialogName: 'vocabularyPropertyDialog',
    title: <FormattedMessage id="module-dataCatalog-vocabPropertyDialog" />,
    methodName: 'onAddVocabulary',
    mainButtonLabel: (
      <FormattedMessage id="module-dataCatalog-addVocabColumns" />
    ),
    style: {
      maxWidth: '800px',
    },
    width: '90vh',
    height: '85vh',
    bodyStyles: {
      padding: 0,
    },
  },
);

// Panel list
export const EntityListVocabSelectionWithDialogs = ({
  columns = [], //from workflow
  onAddColumns,
  onDeleteColumns,
  onChangeColumns,
  maxNumberColumns,
  excludePropertyKeys,
  entityKeys = [],
  noEntityProperties,
}) => {
  const [selectedVocabulary, setSelectedVocabulary] = useState([]);
  const [selectedEntityProperty, setSelectedProperty] = useState([]);

  useEffect(() => {
    const entityProps = filter(columns, (p) => p.type === 'entity').map(
      (p) => p.property,
    );
    setSelectedProperty(entityProps);
  }, [columns, setSelectedProperty]);

  const currentNumberColumns =
    (selectedVocabulary || []).length + (selectedEntityProperty || []).length;

  return (
    <EnhancedColumnSelectionListWithVocabulary
      noEntityProperties={noEntityProperties}
      entityKeys={entityKeys}
      addEntityPropOffsetTop={255}
      columns={columns}
      currentNumberColumns={currentNumberColumns}
      maxNumberColumns={maxNumberColumns}
      excludePropertyKeys={excludePropertyKeys}
      onChangeColumns={onChangeColumns}
      selectedVocabulary={selectedVocabulary}
      selectedEntityProperty={selectedEntityProperty}
      onEntityPropertyChange={(selected) => {
        setSelectedProperty(selected || []);
      }}
      onVocabularySelectedChange={(selected) => {
        setSelectedVocabulary(selected || []);
      }}
      onMainButtonClick={(e, { dialogName, onClose }) => {
        if (e.keyCode && e.keyCode === 13) {
          return;
        }

        if (dialogName === 'vocabularyPropertyDialog') {
          onAddColumns(selectedVocabulary, 'vocabulary');
        }

        if (dialogName === 'entityPropertyDialog') {
          setSelectedProperty(selectedEntityProperty);
          onAddColumns(selectedEntityProperty, 'entity');
        }

        onClose();
      }}
      onDeleteColumns={onDeleteColumns}
      onDeleteColumn={(deletedColumn) => {
        onDeleteColumns([deletedColumn]);
      }}
    />
  );
};

export default EntityListVocabSelectionWithDialogs;
