import React from 'react';
import { useTheme } from '@cluedin/theme';
import { Flex } from 'uxi/Layout';
import PersonListItemLayout from './PersonListItemLayout';
import RelativeTextPlaceholder from '../../../../../../core/components/composites/Placeholder/RelativeTextPlaceholder';
import SocialMenuPlaceholder from '../../../../../../core/components/composites/Placeholder/SocialMenuPlaceholder';
import IconPlaceholder from '../../../../../../core/components/composites/Placeholder/IconPlaceholder';
import { socialIconSize, logosize } from '../../theme';

const PersonListItemLoading = () => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const social = (
    <SocialMenuPlaceholder
      style={{ marginTop: '8px' }}
      color={themePrimary}
      size={socialIconSize}
    />
  );

  const logo = (
    <Flex
      style={{
        backgroundColor: 'white',
        borderRadius: '50%',
        left: `calc(50% - ${logosize / 2}px)`,
        height: `${logosize}px`,
        width: `${logosize}px`,
        margin: '0 auto',
      }}
    >
      <IconPlaceholder size={logosize} centered color={themePrimary} />
    </Flex>
  );

  return (
    <PersonListItemLayout
      entityId={'entityId'}
      name={
        <RelativeTextPlaceholder
          style={{ width: '73%' }}
          tiny
          centered
          color={themePrimary}
        />
      }
      jobTitle={
        <RelativeTextPlaceholder style={{ width: '59%' }} tiny centered />
      }
      email={<RelativeTextPlaceholder style={{ width: '123px' }} tiny />}
      phoneNumber={<RelativeTextPlaceholder style={{ width: '93px' }} tiny />}
      socialMenu={social}
      logo={logo}
    />
  );
};

PersonListItemLoading.displayName = 'PersonListItemLoading';

export default PersonListItemLoading;
