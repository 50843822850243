import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import OutlineButton from 'uxi/Button/OutlineButton';
import { Flex } from 'uxi/Layout';
import { Loader } from '@cluedin/form';
import { getColorForType } from '../../../core/helpers/colors';
import { getScaledValueWithMetricNotation } from '../helper/number';

const DashboardKPIWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  height: calc(100% - 16px);
  min-height: calc(148px);
  box-sizing: border-box;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px -2px rgba(0, 0, 0, 0.12);

  ${({ isFullHeight }) => (isFullHeight ? 'height: calc(100% - 32px)' : '')};
  height: 100%;
  background: white;
`;

const DashboardKPI = ({
  icon,
  value,
  title,
  type,
  hasNew,
  style,
  isFullHeight,
  actionLabel,
  kpiType,
  className,
  loading,
}) => {
  const hasNewContent =
    hasNew > 0 ? (
      <div style={{ opacity: 0.7, fontSize: 'thin' }}>
        {hasNew > 2000 ? `+ ${Math.floor(hasNew / 1000)}k` : `+ ${hasNew}`}{' '}
        since last visit
      </div>
    ) : null;

  let action = null;
  if (actionLabel) {
    let outlineButtonType = 'primary';

    if (kpiType === 'danger' || kpiType === 'error') {
      outlineButtonType = 'error';
    }
    action = (
      <OutlineButton
        inert
        type={outlineButtonType}
        style={{ textTransform: 'none' }}
        text={actionLabel}
      />
    );
  }

  const displayValue = Number.isInteger(value)
    ? getScaledValueWithMetricNotation(value)
    : value;

  const color = getColorForType(kpiType);

  return (
    <DashboardKPIWrapper
      className={className}
      isFullHeight={isFullHeight}
      type={type}
      style={style}
    >
      <div
        style={{
          position: 'absolute',
          right: '16px',
          top: 0,
          padding: '8px',
          background: color,
          color: 'white',
        }}
      >
        {React.cloneElement(icon, { size: 28 })}
      </div>
      <Flex
        style={{
          width: '100%',
          padding: '8px',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', fontSize: '42px' }}>
          <TextEllipsis>
            {!loading && displayValue}
            {loading && (
              <div style={{ marginTop: '4px', display: 'flex' }}>
                <Loader />
              </div>
            )}
          </TextEllipsis>
        </div>
        <div style={{ flexGrow: 9, width: '100%', padding: '4px 0px 8px' }}>
          <TextEllipsis>{title}</TextEllipsis>
        </div>
        <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
          {action}
          <TextEllipsis style={{ paddingLeft: '6px' }}>
            {hasNewContent}
          </TextEllipsis>
        </Flex>
      </Flex>
    </DashboardKPIWrapper>
  );
};

DashboardKPI.defaultProps = {
  type: 'info',
  hasNew: 0,
  badgeType: 'error',
};

DashboardKPI.defaultProps = {
  type: PropTypes.oneOf(['error', 'warning', 'success', 'information']),
  hasNew: PropTypes.number,
};

DashboardKPI.displayName = 'DashboardKPI';

export default DashboardKPI;
