import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import { clientId, location } from '../../../config';
import { requestPasswordReset } from '../data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../core/helpers/action';

export const resetPwdResetActions = createAsyncAction('PUBLIC_PWD_RESET');

export const shouldRequestPwdReset = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(
    requestPasswordReset,
    resetPwdResetActions,
    () => {
      setTimeout(() => {
        window.location.assign(location.goToClientIdSignIn(clientId));
      }, 3000);
    },
  ),
  {
    onErrorAction: resetPwdResetActions.invalid,
  },
);
