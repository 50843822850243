"use strict";

exports.__esModule = true;
exports.default = useControlled;
var React = _interopRequireWildcard(require("react"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useControlled(_ref) {
  var controlled = _ref.controlled,
    defaultProp = _ref.default,
    name = _ref.name,
    _ref$state = _ref.state,
    state = _ref$state === void 0 ? 'value' : _ref$state;
  // isControlled is ignored in the hook dependency lists as it should never change.
  var _React$useRef = React.useRef(controlled !== undefined),
    isControlled = _React$useRef.current;
  var _React$useState = React.useState(defaultProp),
    valueState = _React$useState[0],
    setValue = _React$useState[1];
  var value = isControlled ? controlled : valueState;
  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(function () {
      if (isControlled !== (controlled !== undefined)) {
        console.error(["Library-UI: A component is changing the " + (isControlled ? '' : 'un') + "controlled " + state + " state of " + name + " to be " + (isControlled ? 'un' : '') + "controlled.", 'Elements should not switch from uncontrolled to controlled (or vice versa).', "Decide between using a controlled or uncontrolled " + name + " " + 'element for the lifetime of the component.', "The nature of the state is determined during the first render, it's considered controlled if the value is not `undefined`.", 'More info: https://fb.me/react-controlled-components'].join('\n'));
      }
    }, [state, name, controlled]);
    var _React$useRef2 = React.useRef(defaultProp),
      defaultValue = _React$useRef2.current;
    React.useEffect(function () {
      if (!isControlled && defaultValue !== defaultProp) {
        console.error(["Library-UI: A component is changing the default " + state + " state of an uncontrolled " + name + " after being initialized. " + ("To suppress this warning opt to use a controlled " + name + ".")].join('\n'));
      }
    }, [JSON.stringify(defaultProp)]);
  }
  var setValueIfUncontrolled = React.useCallback(function (newValue) {
    if (!isControlled) {
      setValue(newValue);
    }
  }, []);
  return [value, setValueIfUncontrolled];
}