import styled from 'styled-components';

export const EntityTopologySplitModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const EntityTopologySplitModalIllustration = styled.div`
  align-items: center;
  background: #f4fbfb;
  display: flex;
  height: 180px;
  justify-content: center;
  width: 100%;

  & svg {
    height: 120px;
    width: 120px;
  }
`;

export const EntityTopologySplitModalHeaderWrapper = styled.div`
  text-align: center;
  padding: 10px;
`;

export const EntityTopologySplitModalHeader = styled.div`
  font-weight: bold;
  font-size: 20px;
  padding: 20px;
`;

export const EntityTopologySplitModalText = styled.div`
  color: #9b9b9c;
`;

export const EntityTopologyResultText = styled.div`
  color: #9b9b9c;
  font-size: 14px;
  font-weight: 600;
  padding: 32px 0;
`;
