import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { withToggle } from '../../core/components/Hocs/progressiveDisclosure';
import HighlightText from '../../core/components/composites/HighlightText';
import { withEntities } from '../../core/components/Hocs/defaults';
import EntityPropertyViewerDetailPropertyValue from './EntityPropertyViewerDetailPropertyValue';

export const ViewMoreWrapper = styled.div`
  text-align: center;
  margin: 16px 0;
`;

const EntityPropertyRow = styled.div`
  padding: 16px;
  display:flex;
  border-bottom: 1px solid #dedede;
  flex-direction: column;
  &:hover {
    background: #f2f2f2;
  }
  &:hover:nth-child(even) {
    background: #f2f2f2;
  }
  &:last-child {
    border-bottom: none;s
  }
  &:nth-child(even) {
    background-color: rgb(253, 253, 253);
  }
`;

const EntityPropertyDisplayName = styled.div`
  font-size: 14px;
  color: grey;
  margin-bottom: 4px;
`;

const VocabKey = styled.div`
  color: grey;
  font-size: 12px;
  margin-bottom: 6px;
`;

const EntityPropertyList = ({
  propertyViewModels = [],
  searchQuery,
  integrationName,
}) => {
  return (
    <div>
      {searchQuery && propertyViewModels && propertyViewModels.length === 0 && (
        <div
          style={{
            padding: '16px',
            textAlign: 'center',
            borderBottom: '1px solid #cccccc',
          }}
        >
          <span>
            <FormattedMessage id="module-entity-noPropsMatching" />
            <span style={{ fontStyle: 'italic' }}>
              &nbsp;
              <mark>{searchQuery}</mark>
              &nbsp;
            </span>
            <span>
              <FormattedMessage id="module-entity-for" />
              &nbsp;{integrationName}
            </span>
          </span>
        </div>
      )}
      {propertyViewModels.map((propertyViewModel) => (
        <EntityPropertyRow>
          <EntityPropertyDisplayName>
            {propertyViewModel.displayName}
          </EntityPropertyDisplayName>
          <VocabKey>
            <HighlightText
              text={propertyViewModel.propertyKeyName}
              highlight={searchQuery}
            />
          </VocabKey>
          <EntityPropertyViewerDetailPropertyValue>
            {propertyViewModel.content}
          </EntityPropertyViewerDetailPropertyValue>
        </EntityPropertyRow>
      ))}
    </div>
  );
};

export default withEntities(withToggle(EntityPropertyList), {
  Loader: () => <div />,
  Invalid: () => null,
  normalizeProps: (props) => ({
    ...props,
    entities: props.propertyViewModels,
  }),
});
