import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';

const SocialMenuLinkItem = styled.a`
  opacity: 0.6;
  margin: 0 2px;
  :hover {
    opacity: 0.9;
  }
`;

const SocialMenuLink = ({ name, link, icon, iconSize }) => (
  <li>
    <Tooltip
      placement="bottom"
      destroyTooltipOnHide
      overlay={<div>{name}</div>}
    >
      <SocialMenuLinkItem
        key={`socialMenuLink-${name}`}
        title={name}
        target="_blank"
        href={link}
        rel="noopener noreferrer"
      >
        {getAppropriateIcon(icon || 'Help')({
          ...(iconSize ? { size: iconSize } : {}),
        })}
      </SocialMenuLinkItem>
    </Tooltip>
  </li>
);

SocialMenuLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

SocialMenuLink.displayName = 'SocialMenuLink';

export default SocialMenuLink;
