import { memo, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { groupBy } from 'lodash';
import { useIntl } from 'react-intl';
import { TimeFromNow } from '@cluedin/components';

import { useGetEntityById } from '../../../../entityProperties/hooks/getEntityById';
import EntityIcon from '../../../../entity/components/composites/EntityIcon';
import { GoldenRecordViewPropertiesPanelList } from './GoldenRecordViewPropertiesPanelList';
import { useGetEntityTypeInfo } from '../../../../entityTypes/hooks/useGetEntityTypeInfo';

const InfoWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

const InlineInfo = styled.div`
  display: flex;
  align-items: center;
`;

const DateInfo = styled.div`
  font-size: 12px;
  color: #c3c3c3;
  padding-bottom: 4px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

const Icon = styled(InlineInfo)`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  justify-content: center;
  margin-right: 16px;
  flex: 1;
  min-width: 46px;
  max-width: 46px;
  background: ${({ theme }) => theme.palette.themePrimary};

  & path {
    fill: #fff;
  }
`;

export const getVocabularyTreeData = (
  vocabKeys = [],
  entityProperties = [],
) => {
  const data = [];
  const grouped = groupBy(vocabKeys, (g) => {
    return g?.vocabularyId;
  });

  Object.keys(grouped).forEach((i, idx) => {
    // items are vocabulary keys
    const items = grouped[i];

    const entityProps = items.map((vocabKey) => {
      const item = entityProperties.find(
        (entityProperty) => entityProperty.entityVocabularyKey === vocabKey.key,
      );

      if (item) {
        return {
          ...item,
          name: item?.entityVocabularyKey,
        };
      }

      return {
        vocabularyKey: vocabKey,
        name: vocabKey.key,
        value: '',
      };
    });

    data.push({
      id: idx,
      entityProps,
      propsNumber: entityProps.length,
      providerId: items?.[0]?.providerId,
      isCluedInCore: items?.[0]?.isCluedInCore,
      connector: items?.[0]?.vocabulary?.connector,
      name: items?.[0]?.vocabulary?.vocabularyName || 'Unknown',
    });
  });

  return data;
};

export const getData = (entityProperties) => {
  const data = [];

  const grouped = groupBy(entityProperties, (g) => {
    return g?.vocabularyKey?.vocabularyId;
  });

  Object.keys(grouped).forEach((i, idx) => {
    const items = grouped[i];

    const entityProps = items?.map((i) => ({
      ...i,
      name: i?.entityVocabularyKey,
    }));

    data.push({
      id: idx,
      entityProps,
      propsNumber: entityProps.length,
      providerId: items?.[0]?.vocabularyKey?.providerId,
      isCluedInCore: items?.[0]?.vocabularyKey?.isCluedInCore,
      connector: items?.[0]?.vocabularyKey?.vocabulary?.connector,
      name: items?.[0]?.vocabularyKey?.vocabulary?.vocabularyName || 'Unknown',
    });
  });

  return data;
};

export const GoldenRecordViewPropertiesPanel = memo(
  ({
    values: {
      entityId,
      entityType,
      entityName,
      entityDescription,
      entityCreatedDate,
      entityModifiedDate,
      entityDiscoveryDate,
    },
  }) => {
    const intl = useIntl();
    const theme = useTheme();
    const [query, setQuery] = useState('');

    const [data, loading] = useGetEntityById(entityId);
    const [entityData, loadingEntityData] = useGetEntityTypeInfo(entityType);

    const entityProperties = data?.virtualization?.entityById?.entityProperties;

    const listData = getData(entityProperties);

    return (
      <>
        <InfoWrapper>
          <Icon theme={theme}>
            <EntityIcon entityType={entityType} size="M" />
          </Icon>

          <div>
            <strong style={{ color: theme.palette.themePrimary }}>
              {entityName}
            </strong>

            <div style={{ padding: '8px 0' }}>
              {entityData?.displayName || entityType}
            </div>

            {entityCreatedDate && (
              <DateInfo>
                {intl.formatMessage(
                  { id: 'module-goldenRecordList-createdDate' },
                  {
                    entityCreatedDate: <TimeFromNow date={entityCreatedDate} />,
                  },
                )}
              </DateInfo>
            )}
            {entityModifiedDate && (
              <DateInfo>
                {intl.formatMessage(
                  { id: 'module-goldenRecordList-modifiedCreated' },
                  {
                    entityModifiedDate: (
                      <TimeFromNow date={entityModifiedDate} />
                    ),
                  },
                )}
              </DateInfo>
            )}

            {entityDiscoveryDate && (
              <DateInfo>
                {intl.formatMessage(
                  { id: 'module-goldenRecordList-entityDiscoveryDate' },
                  {
                    entityDiscoveryDate: (
                      <TimeFromNow date={entityDiscoveryDate} />
                    ),
                  },
                )}
              </DateInfo>
            )}

            {entityDescription && (
              <div style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'module-entity-desription' })}:{' '}
                {entityDescription}
              </div>
            )}
          </div>
        </InfoWrapper>

        <GoldenRecordViewPropertiesPanelList
          intl={intl}
          query={query}
          data={listData}
          setQuery={setQuery}
          loading={loading || loadingEntityData}
        />
      </>
    );
  },
);
