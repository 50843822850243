import React, { Fragment } from 'react';
import Alert from 'uxi/Alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose, branch, renderComponent } from 'recompose';
import { Flex } from 'uxi/Layout';
import { PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import {
  showInviteAction,
  hideInviteAction,
  shouldInviteUserToIntegration,
} from '../../actions';
import IntegrationListWithFilters from '../composites/lists/IntegrationListWithFilters';
import IntegrationListRowPlaceholder from '../composites/lists/IntegrationListRowPlaceholder';
import IntegrationFiltersLoading from '../composites/filters/IntegrationFiltersLoading';
import InviteUserToAddIntegration from '../containers/Dialogs/InviteUserToAddIntegration';

const IntegrationListWithFilterLoading = () => (
  <>
    <IntegrationFiltersLoading />
    {[...Array(10)].map((_, i) => (
      <IntegrationListRowPlaceholder key={i} />
    ))}
  </>
);

const IntegrationListContainer = ({
  integrations,
  onInviteClick,
  onAddClick,
  integrationType,
  hideFilters,
  onClose,
  showInvite,
  selectedIntegrationForInvite,
  isInviting,
  onInvite,
  isInvalid,
  withInviteButton,
  withViewAll,
}) => (
  <Fragment>
    <InviteUserToAddIntegration
      onClose={onClose}
      show={showInvite}
      integration={selectedIntegrationForInvite}
      isInviting={isInviting}
      onInvite={onInvite}
      isInvalid={isInvalid}
    />
    <IntegrationListWithFilters
      withInviteButton={withInviteButton}
      integrations={integrations}
      onInviteClick={onInviteClick}
      onAddClick={onAddClick}
      integrationType={integrationType}
      hideFilters={hideFilters}
    />
    {withViewAll && (
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '12px',
        }}
      >
        <PrimaryButton
          href="/admin/inbound/integration/applications"
          text={
            <FormattedMessage id="module-integration-viewAllIntegrations" />
          }
        />
      </Flex>
    )}
  </Fragment>
);

IntegrationListContainer.displayName = 'IntegrationListContainer';

IntegrationListContainer.propTypes = {
  integrations: PropTypes.array,
  onInviteClick: PropTypes.func,
  onAddClick: PropTypes.func,
};

IntegrationListContainer.defaultProps = {
  integrations: [],
  onInviteClick: () => {},
  onAddClick: () => {},
};

const EnhancedIntegrationListWithFilterss = compose(
  branch(
    (props) => props.invalidAllIntegrations,
    renderComponent(() => (
      <Alert type="danger">
        <FormattedMessage id="module-integration-errorWhileGettingIntegrations" />
      </Alert>
    )),
  ),
  branch(
    (props) => props.isFetching,
    renderComponent(IntegrationListWithFilterLoading),
  ),
)(IntegrationListContainer);

const mapToSelectProps = ({
  integration: {
    allIntegrations,
    isFetchingAllIntegrations,
    invalidAllIntegrations,
    showInvite,
    selectedIntegrationForInvite,
    inviteUsersPerIntegration,
  },
}) => {
  const status = selectedIntegrationForInvite
    ? inviteUsersPerIntegration[selectedIntegrationForInvite.Id]
    : null;

  return {
    integrations: allIntegrations,
    isFetching: isFetchingAllIntegrations,
    invalidAllIntegrations,
    showInvite,
    selectedIntegrationForInvite,
    isInviting: status ? status.isFetching : false,
    isInvalid: status ? status.invalid : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose() {
    dispatch(hideInviteAction());
  },
  onInvite({ providerId, userIds }) {
    dispatch(
      shouldInviteUserToIntegration({
        providerId,
        userIds,
      }),
    );
  },
  onInviteClick: (integration) => {
    dispatch(showInviteAction(integration));
  },
  onAddClick: (integration) => {
    dispatch(push(`/admin/integrations/new/${integration.Id}`));
  },
});

export default connect(
  mapToSelectProps,
  mapDispatchToProps,
)(EnhancedIntegrationListWithFilterss);
