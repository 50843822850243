import React from 'react';
import PropTypes from 'prop-types';
import AdminUserIntegrationsContainer from '../containers/AdminUserIntegrationsContainer';
import ConfigurationQuickViewContainer from '../../../configuration/components/containers/quickViews/ConfigurationQuickViewContainer';

const AdminUserIntegrationPage = ({ user }) => (
  <div>
    <ConfigurationQuickViewContainer />
    <AdminUserIntegrationsContainer user={user} />
  </div>
);

AdminUserIntegrationPage.displayName = 'AdminUserIntegrationPage';

AdminUserIntegrationPage.propTypes = {
  user: PropTypes.object,
};
AdminUserIntegrationPage.defaultProps = {
  user: {},
};

export default AdminUserIntegrationPage;
