import { ReactNode } from 'react';
import styled from 'styled-components';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';

const FileListHeaderWrapper = styled.div`
  display: flex;
  padding: 6px 0;
`;

const FileListHeaderTitle = styled.div`
  flex: 1;
`;

type FileListHeaderProps = {
  title: string | ReactNode;
  onClear: () => void;
  color: string;
};

export const FileListHeader = ({
  title = '',
  onClear,
  color = '',
}: FileListHeaderProps) => {
  return (
    <FileListHeaderWrapper>
      <FileListHeaderTitle style={{ color }}>{title}</FileListHeaderTitle>
      <ButtonLink
        onClick={onClear}
        text={<FormattedMessage id="module-fileUpload-clearAllFiles" />}
      />
    </FileListHeaderWrapper>
  );
};
