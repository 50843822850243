import styled from 'styled-components';

export const RootEntityHeaderEntityTypeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg {
    height: 14px !important;
    min-height: 14px !important;
    margin: 0 0 0 6px !important;
    padding: 0 !important;
    min-width: 14px !important;
    width: 14px !important;
  }
`;
