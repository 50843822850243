import styled from 'styled-components';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';

import { isDataSet } from '../../dataSourceV2/helper';
import DataSetName from '../../dataSourceV2/components/composites/DataSetName';

const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  color: #222222;
  fontsize: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: #f2f2f2;
  }
`;

const EntityPropertyViewerIntegrationGroupHeader = ({
  title,
  numberProperties,
  onClick,
  isOpen,
}) => {
  const isDataSetName = isDataSet(title);

  return (
    <Header onClick={onClick}>
      <div>{isOpen ? <Arrowdown size={12} /> : <Arrowright size={12} />}</div>
      <div style={{ flex: 1 }}>
        <div style={{ fontWeight: 'bold', paddingLeft: '12px' }}>
          {!isDataSetName && title}
          {isDataSetName && <DataSetName providerName={title} />}
        </div>
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>{numberProperties}</span>
        <span>
          &nbsp;
          <FormattedMessage id="module-entity-properties" />
        </span>
      </div>
    </Header>
  );
};

export default EntityPropertyViewerIntegrationGroupHeader;
