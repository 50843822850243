import { FC } from 'react';
import { useTheme } from '@cluedin/theme';
import { CancelButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import { EntityGroupedRelationFooterWrapper } from './EntityGroupedRelationFooterWrapper';
import { EntityGroupedRelationFooterInnerWrapper } from './EntityGroupedRelationFooterInnerWrapper';

type EntityGroupedRelationFooterProps = {
  onClose: () => void;
};

export const EntityGroupedRelationFooter: FC<
  EntityGroupedRelationFooterProps
> = ({ onClose }) => {
  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  return (
    <EntityGroupedRelationFooterWrapper themeWhite={themeWhite}>
      <EntityGroupedRelationFooterInnerWrapper themeWhite={themeWhite}>
        <CancelButton
          data-test="entityRelationsGraph-groupedRelationPanel-closeButton"
          onClick={onClose}
          variant="outlined"
          onConfirm={onClose}
          rounded
          style={{ marginRight: '10px' }}
        >
          <FormattedMessage id="module-entityRelationsGraphV2-closeButtonLabel" />
        </CancelButton>
      </EntityGroupedRelationFooterInnerWrapper>
    </EntityGroupedRelationFooterWrapper>
  );
};
