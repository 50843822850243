import genericAvatar from '../modules/entityWidget/assets/generic-avatar.png';
import defaultCompany from './assets/company.png';

const defaultProfile = `${genericAvatar}`;
const defaultLogo = `${defaultCompany}`;

export default {
  defaultProfile,
  defaultLogo,
};
