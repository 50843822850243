import styled from 'styled-components';

export const EntityNodePanelInfoFooterInnerWrapper = styled.div<{
  themeWhite?: string;
}>`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;
