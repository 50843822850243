import React from 'react';
import { injectIntl } from '@cluedin/locale';
import { ListToolbar } from '@cluedin/list-pattern';

import FlexHolyAlbatross from './FlexHolyAlbatross';

const IntegrationFilters = ({ query, onSearchChange, intl }) => (
  <FlexHolyAlbatross breakpoint={'48rem'} gap={'8px'}>
    <ListToolbar
      searchName={query}
      onChangeSearch={onSearchChange}
      onSubmitSearch={onSearchChange}
      searchInputPlaceholder={intl.formatMessage({
        id: 'module-integration-searchByName',
      })}
    />
  </FlexHolyAlbatross>
);

export default injectIntl(IntegrationFilters);
