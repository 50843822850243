import React from 'react';
import styled from 'styled-components';

const NotificationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .notification {
    border-bottom-width: 0;
  }
  .notification:first-child {
    border-top-width: 1px;
  }
  .notification:last-child {
    border-bottom-width: 1px;
  }
`;

const NotificationList = ({ children }) => {
  return <NotificationListWrapper>{children}</NotificationListWrapper>;
};

export default NotificationList;
