import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './module.routes';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  name: 'user',
  displayName: <FormattedMessage id="user" />,
  path: '/users',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'User',
        name: <FormattedMessage id="user" />,
        link: '/admin/settings/users/',
        claims: admin.users.user.view,
      },
    ],
  },
  routes,
};
