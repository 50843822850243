import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { createAnnotationQuery } from '../../data';

export const withCreateAnnotation = (Comp) => (props) => {
  const currentUserId =
    props &&
    props.currentUser &&
    props.currentUser.client &&
    props.currentUser.client.Id
      ? props.currentUser.client.Id
      : '';

  return (
    <Mutation mutation={createAnnotationQuery}>
      {(createAnnotationWithProperties, { loading, error, data }) => (
        <Comp
          {...props}
          createAnnotation={(value) => {
            createAnnotationWithProperties({
              variables: {
                annotation: {
                  ...value,
                  author: currentUserId,
                },
              },
            });
          }}
          saving={loading}
          error={error}
          doneSaving={
            !loading &&
            !error &&
            data &&
            data.preparation &&
            data.preparation.createAnnotationWithProperties
          }
          annotationId={
            data &&
            data.preparation &&
            data.preparation.createAnnotationWithProperties &&
            data.preparation.createAnnotationWithProperties.id
          }
        />
      )}
    </Mutation>
  );
};

export default withCreateAnnotation;
