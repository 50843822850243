import React from 'react';
import { TextEllipsis } from 'uxi/Text';
import InsightLinkRouterLink from './InsightLinkRouterLink';

const InsightLink = ({ insight: { Entity } }) => (
  <InsightLinkRouterLink entity={Entity}>
    <TextEllipsis>
      {Entity.EntityName || Entity.Name || `${Entity.Id} (no name)`}
    </TextEllipsis>
  </InsightLinkRouterLink>
);

export default InsightLink;
