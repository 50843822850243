import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../core/helpers/action';
import { getTeamDomain } from '../../auth/data';

export const shoudFetchPublicOrgInfoActions = createAsyncAction(
  'FETCH_GET_IS_ACCOUNT_AVAILABLE',
);
export const shoudFetchPublicOrgInfo = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(getTeamDomain, shoudFetchPublicOrgInfoActions),
  {
    onErrorAction: shoudFetchPublicOrgInfoActions.invalid,
  },
);
