import React from 'react';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';
import CluedInRegularText from '../../../core/components/composites/generic/CluedInRegularText';
import CreateAccountForm from './CreateAccountForm';

const CreateOrgAndLoginForm = ({ isMutating, onSignup, error, email }) => (
  <div>
    <CluedInBigTitle>
      <FormattedMessage id="module-public-signupFinalStepTitle" />
    </CluedInBigTitle>
    <CluedInRegularText>
      <FormattedMessage id="module-public-signupFinalStepExplantation" />
    </CluedInRegularText>
    {error && (
      <div style={{ margin: '15px 0' }}>
        <Alert type="danger" noIcon>
          <p>
            <FormattedMessage id="module-public-onboardingNetworkErrorPart1" />
          </p>
          <p>
            <FormattedMessage id="module-public-onboardingNetworkErrorPart2" />
          </p>
          <p>
            <FormattedMessage id="module-public-onboardingNetworkErrorPart3" />
          </p>
        </Alert>
      </div>
    )}
    <CreateAccountForm
      isMutating={isMutating}
      onSignup={onSignup}
      email={email}
    />
  </div>
);

export default CreateOrgAndLoginForm;
