import React, { Component } from 'react';

const escapeRegExp = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const withInMemoryFilter = (Comp, preFix = '') => {
  class WithMemoryFilter extends Component {
    constructor(props) {
      super(props);
      this.state = {
        query: '',
      };
      this.onFilterQueryChangeHandler =
        this.onFilterQueryChangeHandler.bind(this);
      this.filterFromQuery = this.filterFromQuery.bind(this);
    }

    onFilterQueryChangeHandler(e, value) {
      this.setState({
        currentFilterQuery: value,
      });
    }

    filterFromQuery() {
      const { currentFilterQuery } = this.state;
      const { filters = [] } = this.props;

      if (!currentFilterQuery) {
        return filters;
      }

      const matcher = new RegExp(
        `${escapeRegExp(preFix + currentFilterQuery)}`,
        'gi',
      );
      const displayNameMatcher = new RegExp(
        `${escapeRegExp(currentFilterQuery)}`,
        'gi',
      );

      return filters.filter(
        (x) => matcher.test(x.value) || displayNameMatcher.test(x.displayName),
      );
    }
    render() {
      const { filters } = this.props;
      const { currentFilterQuery } = this.state;

      return (
        <Comp
          {...this.props}
          onFilterQueryChange={this.onFilterQueryChangeHandler}
          currentFilterQuery={currentFilterQuery}
          filters={this.filterFromQuery(filters)}
        />
      );
    }
  }

  return WithMemoryFilter;
};

export default withInMemoryFilter;
