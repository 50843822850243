import { FC } from 'react';
import { DataGridv2 } from '@cluedin/list-pattern';
import { useIntl } from 'react-intl';

import { EntityRelationsTreeRootEdgeDetailWrapper } from './EntityRelationsTreeRootEdgeDetailWrapper';
import { GraphNode } from '../../../entityRelationsGraphV2/types';
import { EntityRelationsDetailValue } from '../../composites';
import { filterAndFormatData } from '../filterAndFormatData ';
import { DataItem } from '../types';

type EntityRelationsTreeRootEdgeDetailProps = {
  edgeNode: GraphNode | undefined;
};

export const EntityRelationsTreeRootEdgeDetail: FC<
  EntityRelationsTreeRootEdgeDetailProps
> = ({ edgeNode }) => {
  const { formatMessage } = useIntl();

  const formattedEdgeNodeData: DataItem[] | undefined =
    edgeNode &&
    Object.entries(edgeNode)?.map(([property, value]) => ({ property, value }));

  const excludedEdgeNodeProperties = new Set([
    'x',
    'y',
    'title',
    'shape',
    'icon',
    'nodeKind',
    'displayName',
  ]);
  const filteredEdgeNodeData = formattedEdgeNodeData?.filter(
    (item) => !excludedEdgeNodeProperties?.has(item?.property),
  );
  const filteredEdgeNodeDataWithCorrectLabels = filterAndFormatData(
    filteredEdgeNodeData || [],
  );

  const edgeInformationId = filteredEdgeNodeDataWithCorrectLabels?.[0]?.value;

  return (
    <EntityRelationsTreeRootEdgeDetailWrapper>
      <DataGridv2
        allowInlinePropertySelection={false}
        data={filteredEdgeNodeDataWithCorrectLabels}
        model={[
          {
            property: 'property',
            displayName: formatMessage({
              id: 'module-entityRelationsGraphTreeview-propertyLabel',
            }),
          },
          {
            property: 'value',
            displayName: formatMessage({
              id: 'module-entityRelationsGraphTreeview-valueLabel',
            }),
            Component: ({
              property,
              value,
            }: {
              property: string;
              value:
                | number
                | string
                | boolean
                | null
                | undefined
                | number[]
                | string[];
            }) => (
              <EntityRelationsDetailValue
                property={property}
                value={value}
                edgeInformationId={edgeInformationId}
                labelFromNode={edgeNode?.label ?? edgeNode?.title}
              />
            ),
          },
        ]}
      />
    </EntityRelationsTreeRootEdgeDetailWrapper>
  );
};
