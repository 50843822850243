import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'uxi/Input';
import { connect } from 'react-redux';
import { Loader } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import { shouldUnBlock, shouldBlock } from '../../actions';

const UserBlockUnBlockCell = ({
  user,
  isFetching,
  toogleActive,
  currentUser,
  refetch,
}) => {
  const hasUserAccess = useUserHasAccess(
    actionsConfiguration.admin.users.user.create.claims,
  );

  const isYou =
    currentUser && currentUser.client.UserName === user.Account.UserName;

  if (isFetching) {
    return (
      <div style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }

  if (isYou) {
    return <FormattedMessage id="module-user-deActivateYoursefl" />;
  }

  const label =
    user.Account.LockoutEnd === null ? (
      <FormattedMessage id="module-user-active" />
    ) : (
      <FormattedMessage id="module-user-blocked" />
    );

  return (
    <Switch
      name={`lockoutSwitch-${user.Account.UserName}`}
      disabled={!hasUserAccess}
      label={label}
      onChange={() => {
        toogleActive();

        // TODO: FIX REFETCH: hook for enable / disable
        setTimeout(() => {
          refetch?.();
        }, 0);
      }}
      defaultChecked={user.Account.LockoutEnd === null}
    />
  );
};

UserBlockUnBlockCell.displayName = 'UserBlockUnBlockCell';

UserBlockUnBlockCell.propTypes = {
  user: PropTypes.object,
  isFetching: PropTypes.bool,
  toogleActive: PropTypes.func,
  currentUser: PropTypes.object,
};
UserBlockUnBlockCell.defaultProps = {
  user: {},
  isFetching: false,
  toogleActive: () => {},
  currentUser: {},
};

const mapToStateProps = (
  { user: { blockUsers, unBlockUsers, currentUser } },
  { user },
) => {
  const blockCurrentUser = blockUsers[user.Account.UserName];
  const unBlockCurrentuser = unBlockUsers[user.Account.UserName];

  const isBlocking = blockCurrentUser && blockCurrentUser.isFetching;
  const isUnBlocking = unBlockCurrentuser && unBlockCurrentuser.isFetching;

  return {
    currentUser,
    isFetching: isBlocking || isUnBlocking,
  };
};

const mapDispatchToProps = (dispatch, { user }) => ({
  toogleActive: () => {
    if (user.Account.LockoutEnd !== null) {
      dispatch(shouldUnBlock(user.Account.UserName));
    } else {
      dispatch(shouldBlock(user.Account.UserName));
    }
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(UserBlockUnBlockCell);
