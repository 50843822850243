import React from 'react';
import IntegrationStatusIdle from './status/Idle';
import IntegrationStatusQueued from './status/Queued';
import IntegrationStatusCrawling from './status/Crawling';
import IntegrationStatusProcessing from './status/Processing';
import StatusCheck from './status/StatusCheck';

const ConfigurationDataStatus = ({ configuration }) => {
  let state;
  try {
    state = configuration.Stats && configuration.Stats.ProviderStatus.State;
  } catch (e) {
    console.warn('Unable to read ProviderStatus.State');
  }

  switch (state) {
    case 'Idle':
      return <IntegrationStatusIdle />;
    case 'Queued':
      return <IntegrationStatusQueued />;
    case 'Crawling':
      return <IntegrationStatusCrawling />;
    case 'Processing':
      return <IntegrationStatusProcessing />;
    default:
      return <StatusCheck />;
  }
};

export default ConfigurationDataStatus;
