import React, { useState } from 'react';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';

import {
  ExplainLogKindBox,
  ExplainLogDataWrapper,
  ExplainLogKindDetails,
  ExplainLogKindHeading,
  ExplainLogKindHeadingWrapper,
  ExplainLogKindWrapper,
} from './ExplainLogKind.styles';

const ExplainLogKind = ({ children, title }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <ExplainLogKindBox>
      <ExplainLogKindWrapper>
        <ExplainLogKindHeading>
          <ExplainLogKindHeadingWrapper
            data-test="entity_explainLog_ExplainLogKindButton"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <Arrowdown color={themePrimary} size={16} />
            ) : (
              <Arrowright color={themePrimary} size={16} />
            )}

            <ExplainLogKindDetails>
              <strong>{title}</strong>
            </ExplainLogKindDetails>
          </ExplainLogKindHeadingWrapper>
        </ExplainLogKindHeading>

        <ExplainLogDataWrapper>
          {isExpanded && children && children}
        </ExplainLogDataWrapper>
      </ExplainLogKindWrapper>
    </ExplainLogKindBox>
  );
};

export default ExplainLogKind;

ExplainLogKind.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

ExplainLogKind.defaultProps = {
  children: null,
  title: 'Event Properties',
};
