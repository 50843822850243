import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import { withUsage } from '../Hocs/withUsage';
import KPI from '../../../DVC/components/composites/visualizations/KPI';

const roundNumber = (num) => Math.round(num * 100) / 100;

const TotalUsage = ({ usage }) => {
  const formatUsedData = roundNumber(usage.Used / 1000000000);

  return (
    <KPI
      value={formatUsedData}
      title={<FormattedMessage id="module-organization-dataProcessed" />}
    />
  );
};

TotalUsage.displayName = 'TotalUsage';

TotalUsage.propTypes = {
  usage: PropTypes.object,
};

TotalUsage.defaultProps = {
  usage: {},
};

export default withUsage(TotalUsage);
