import styled from 'styled-components';
import { Button } from '@cluedin/form';

export const AppSearchSuggestionItemElement = styled(Button)`
  margin: 0;
  width: 100%;
  height: 38px;
  border: none;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  box-shadow: none !important;

  span {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.neutralDark};
  }

  &:hover {
    text-decoration: none;
  }
`;
