import React from 'react';
import { Flex } from 'uxi/Layout';
import { Loader } from '@cluedin/form';
import { withSingleAnnotation } from '../../../../annotation/components/hocs/withSingleAnnotation';
import EntityTypeAvatar from '../../../../entityTypes/components/containers/EntityTypeAvatar';

const EntityTypeView = ({ annotation, annotationLoading }) => {
  if (annotationLoading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    );
  }

  if (!annotation || !annotation.entityType) {
    return (
      <span style={{ fontStyle: 'italic' }}>No entity type defined yet.</span>
    );
  }

  return <EntityTypeAvatar entityType={annotation.entityType} />;
};

const EnhancedEntityTypeViewer = withSingleAnnotation(EntityTypeView);

const EntityTypeFromAnnotaitonId = ({ annotationId }) => {
  return <EnhancedEntityTypeViewer annotationId={annotationId} hasAnnotation />;
};

export default EntityTypeFromAnnotaitonId;
