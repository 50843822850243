"use strict";

exports.__esModule = true;
exports.getNewToken = void 0;
var _storage = require("./storage");
var _config = require("../config");
var _auth = require("../fetch/auth");
var getNewTokenPromise = null;
var getNewToken = function getNewToken() {
  if (!getNewTokenPromise) {
    var fullToken = (0, _storage.getFullToken)();
    if (!fullToken || !fullToken.refreshToken) {
      return new Promise(function (resolve, reject) {
        reject('error');
      });
    }
    getNewTokenPromise = (0, _auth.refreshToken)(fullToken.refreshToken).then(function (authInfo) {
      if (authInfo === void 0) {
        authInfo = {};
      }
      //if authinfo is null, it means the call failed
      if (authInfo.error) {
        getNewTokenPromise = null;
        return null;
      }
      (0, _storage.saveTokenForClientId)({
        accessToken: authInfo.access_token,
        expiresIn: authInfo.expires_in,
        scope: authInfo.scope,
        refreshToken: authInfo.refresh_token
      }, _config.clientId);
      getNewTokenPromise = null;
      return authInfo;
    });
  }
  return getNewTokenPromise;
};
exports.getNewToken = getNewToken;