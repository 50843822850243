"use strict";

exports.__esModule = true;
exports.useAllowedActions = void 0;
var _filter = _interopRequireDefault(require("lodash/filter"));
var _hooks = require("../../../hooks");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useAllowedActions = function useAllowedActions(actions) {
  var actionsWithoutClaims = (0, _filter.default)(actions, function (a) {
    return !a.claims;
  });
  var actionsWithAccess = (0, _filter.default)(actions, function (a) {
    return (0, _hooks.useUserHasAccess)(a.claims);
  });
  return [].concat(actionsWithoutClaims, actionsWithAccess);
};
exports.useAllowedActions = useAllowedActions;