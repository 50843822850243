import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import RelativeTextPlaceholder from '../../../../../../core/components/composites/Placeholder/RelativeTextPlaceholder';

const SearchStat = ({ total = 0, totalLoading, style }) => (
  <div style={style}>
    <strong style={{ marginRight: '4px' }}>
      {!totalLoading ? (
        <span
          style={{
            minWidth: '48px',
            display: 'inline-block',
            textAlign: 'right',
          }}
        >
          {total}
        </span>
      ) : (
        <RelativeTextPlaceholder
          style={{
            width: '48px',
            display: 'inline-block',
          }}
        />
      )}
    </strong>
    <FormattedMessage
      defaultMessage="results found"
      id="SearchContent.ResultFoundMessage"
    />
  </div>
);

SearchStat.displayName = 'SearchStat';

export default SearchStat;
