import React, { Component } from 'react';
import Flex from 'uxi/Layout/Flex';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '@cluedin/list-pattern';
import { Form } from 'react-redux-form';
import { Arrowright, Help } from 'uxi/Icons';
import { PrimaryButton, Select } from '@cluedin/form';
import { ButtonLink } from '@cluedin/atoms';
import Alert from 'uxi/Alert';
import Issue from 'uxi/Icons/Issue';
import Checkmark from 'uxi/Icons/Checkmark';
import { ProgressBar } from '@cluedin/form';
import EntityComparePropertiesResolverRow from './EntityComparePropertiesResolverRow';
import { getSemanticColor } from '../../../core/helpers/colors';

export const dotRep = '•';
export const encodeRRFKey = (k) => k.replace(/\./gi, dotRep);
export const decodeRRFKey = (k) => k.replace(new RegExp(`${dotRep}`, 'g'), '.');

const styles = {
  wrapper: {
    padding: '16px',
  },
  buttonWrapper: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

class EntityComparePropertiesResolver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitConfirmed: false,
    };
  }

  /*
  handleSubmit() {
    const {
      onSubmit,
      ids,
      targetId,
      propsDelta,
    } = this.props;

    const formattedPropsDelta = Object.keys(propsDelta)
      .filter(k => k !== '$form')
      .map(key => ({
        Id: propsDelta[key.replace(`/${dotRep}/gi`, '.')].value,
        PropertyName: key,
      }));

    if (onSubmit) {
      onSubmit({
        ids,
        targetId,
        delta: formattedPropsDelta,
      });
    }

    this.setState({
      submitConfirmed: true,
    });
  }
  */

  renderNoConflictMessage() {
    const { errors, conflictedProperties, isDone } = this.props;

    let noConflictMessage = null;
    if (
      conflictedProperties &&
      conflictedProperties.length === 0 &&
      errors.length === 0 &&
      !isDone
    ) {
      noConflictMessage = (
        <Alert type="success">
          <FormattedHTMLMessage id="mergeNoConflict" />
        </Alert>
      );
    }

    return noConflictMessage;
  }

  renderErrorContent() {
    const { isSubmitting, errors, isDone } = this.props;
    const { submitConfirmed } = this.state;

    let errorContent = null;

    if (submitConfirmed) {
      if (!isSubmitting) {
        if (isDone && errors.length === 0) {
          errorContent = (
            <Alert type="success">
              <FormattedHTMLMessage id="mergeSuccess" />
            </Alert>
          );
        } else {
          errorContent = (
            <Alert type="danger">
              <FormattedHTMLMessage id="mergeError" />
            </Alert>
          );
        }
      }
    }

    return errorContent;
  }

  render() {
    const {
      propsDelta,
      conflictedProperties,
      selectedEntities,
      isSubmitting,
      isDone,
      setCurrentStep,
      ids,
    } = this.props;

    const errorContent = this.renderErrorContent();
    const noConflictMessage = this.renderNoConflictMessage();
    const resolvedPropsCount = Object.keys(propsDelta).length - 1; // -1 becase of the $forms key
    const readyToReview = conflictedProperties.length === resolvedPropsCount;

    const notReadyToReviewMsg = readyToReview ? (
      <Flex style={{ alignItems: 'stretch', flexDirection: 'column' }}>
        <Flex>
          <Checkmark
            style={{ margin: '4px' }}
            color={getSemanticColor(0)}
            size={24}
          />
          <div style={{ margin: '4px' }}>All conflicts have been solved!</div>
        </Flex>
        <div style={{ background: 'lightgrey' }}>
          <ProgressBar
            style={{ background: 'inherit' }}
            min={0}
            max={conflictedProperties.length - 1}
            value={resolvedPropsCount}
          />
        </div>
      </Flex>
    ) : (
      <Flex style={{ alignItems: 'stretch', flexDirection: 'column' }}>
        <Flex>
          <Issue
            style={{ margin: '4px' }}
            color={getSemanticColor(0.9)}
            size={24}
          />
          <div style={{ margin: '4px' }}>
            {/* eslint-disable-next-line max-len */}
            <strong>{conflictedProperties.length - resolvedPropsCount}</strong>
            &nbsp;
            <FormattedMessage id="module-entityMerge-conflicPropsRemaining" />
          </div>
        </Flex>
        <div style={{ background: 'lightgrey' }}>
          <ProgressBar
            style={{ background: 'inherit' }}
            min={0}
            max={conflictedProperties.length}
            value={resolvedPropsCount}
          />
        </div>
      </Flex>
    );

    return (
      <div style={styles.wrapper}>
        {errorContent}
        {noConflictMessage}
        <Form key="theForm" model="propsDelta">
          <div style={{ border: '1px solid #cecece', borderTop: 'none' }}>
            <Flex
              style={{
                flexFlow: 'row nowrap',
                boxSizing: 'border-box',
                padding: '0 16px',
                width: '100%',
                justifyContent: 'flex-start',
                borderBottom: '1px solid #cecece',
                borderTop: '1px solid #cecece',
                position: 'sticky',
                top: '116px',
                zIndex: 99,
                background: 'white',
              }}
            >
              <Flex style={{ padding: '8px' }}>
                <strong>{conflictedProperties.length}</strong>&nbsp;
                <FormattedMessage id="module-entityMerge-propsFound" />
              </Flex>
              <Flex style={{ padding: '8px' }}>
                <Select
                  options={[
                    {
                      value: null,
                      label: (
                        <FormattedMessage id="module-entityMerge-showAllProps" />
                      ),
                    },
                  ]}
                />
              </Flex>
              <Flex style={{ padding: '8px', flexGrow: 1 }}>
                {notReadyToReviewMsg}
              </Flex>
              <Flex style={{ padding: '8px' }}>
                <ButtonLink
                  icon={<Help />}
                  text={
                    <FormattedMessage id="module-entityMerge-howDoesItWork" />
                  }
                />
              </Flex>
              <Flex style={{ marginLeft: 'auto', padding: '8px' }}>
                <PrimaryButton
                  disabled={isDone || isSubmitting || !readyToReview}
                  loading={isSubmitting}
                  onClick={() => setCurrentStep(2)}
                  startIcon={<Arrowright size={16} />}
                  text={
                    <FormattedMessage
                      id="ReviewMerge"
                      defaultMessage={'Review changes'}
                    />
                  }
                />
              </Flex>
            </Flex>
            <div style={{ position: 'sticky', top: '168px', zIndex: 1 }}>
              <Table>
                <TableHeader>
                  <TableRow style={{ background: '#f3f3f3' }}>
                    <TableHeaderColumn>
                      <FormattedMessage id="module-entityMerge-propertyName" />
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                      {selectedEntities[0].name}
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                      {selectedEntities[1].name}
                    </TableHeaderColumn>
                  </TableRow>
                </TableHeader>
              </Table>
            </div>
            <Table>
              <TableBody>
                {conflictedProperties &&
                  conflictedProperties.map((conflictedProp, i, collection) => {
                    return (
                      <EntityComparePropertiesResolverRow
                        ids={ids}
                        key={conflictedProp.key || conflictedProp.displayName}
                        propertyKey={conflictedProp.key}
                        model={`propsDelta.${conflictedProp.displayName}`}
                        conflictedProp={conflictedProp}
                        selectedEntities={selectedEntities}
                        isLast={i === collection.length - 1}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>

          {conflictedProperties.length > 3 && (
            <div>
              {errorContent}
              {noConflictMessage}
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default EntityComparePropertiesResolver;
