import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import ReactDOM from 'react-dom';

class EntityHtmlPreview extends Component {
  componentDidMount() {
    this.renderFrameContents();
  }

  componentDidUpdate() {
    this.renderFrameContents();
  }

  componentWillUnmount() {
    const frame = this.frame;
    if (frame) {
      ReactDOM.unmountComponentAtNode(frame.contentDocument.body);
    }
  }

  renderFrameContents() {
    const { value } = this.props;

    if (!value) {
      return;
    }

    const frame = this.frame;
    if (frame) {
      const doc = frame.contentDocument;
      const self = this;

      if (doc.readyState === 'complete') {
        doc.open();
        doc.write(value);
        doc.close();
      } else {
        setTimeout(self.renderFrameContents, 0);
      }
    }
  }

  render() {
    const { value } = this.props;

    if (!value) {
      return (
        <div style={{ padding: '15px' }}>
          <FormattedMessage id="noPreviewFromEmail" />
        </div>
      );
    }

    return (
      <iframe
        title="Entity Preview"
        ref={(ref) => {
          this.frame = ref;
        }}
        style={{
          width: '100%',
          width: 'calc(100% - 30px)', // eslint-disable-line no-dupe-keys
          border: 0,
          margin: '15px',
          padding: 0,
          minHeight: '500px',
        }}
      />
    );
  }
}

export default EntityHtmlPreview;
