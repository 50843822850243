import React from 'react';
import { PrimaryButton } from '@cluedin/form';
import Alert from 'uxi/Alert/Alert';
import { FormattedMessage } from '@cluedin/locale';

import AuthSettingsFormFields from './AuthSettingsFormFields';
import { shouldReAuth } from '../../../actions';

const IntegrationConfigurationAuthSettingsForm = ({
  authMethods = {},
  authValues = {},
  style = {},
  saveAuthmethod,
  configuration,
  integrationConfig,
}) => {
  const formTokenFields = authMethods.token;
  const formCredsFields = authMethods.credentials;
  const oauthFields = authMethods.oauth;

  const hasFormFields = formTokenFields || formCredsFields;

  if (hasFormFields) {
    const formFields = formTokenFields || formCredsFields;

    return (
      <AuthSettingsFormFields
        style={{
          background: '#fff',
          padding: '16px',
        }}
        formFields={formFields}
        authValues={authValues}
        saveAuthmethod={saveAuthmethod}
      />
    );
  }

  if (oauthFields) {
    return (
      <div style={style}>
        <PrimaryButton
          text={<FormattedMessage id="module-configuration-reAuth" />}
          onClick={() => {
            shouldReAuth(configuration, integrationConfig);
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        background: '#fff',
        padding: '16px',
      }}
    >
      <Alert type="info">
        <FormattedMessage id="module-configuration-noAuthSupported" />
      </Alert>
    </div>
  );
};

export default IntegrationConfigurationAuthSettingsForm;
