import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';

const GET_ACCEPTS_UPDATE = gql`
  query getAcceptsUpdate($entityId: ID!) {
    virtualization {
      acceptsUpdate(entityId: $entityId) {
        success
        errorMessage
        response
      }
    }
  }
`;

export const useGetAcceptsUpdate = (entityId) => {
  const { data, loading, error } = useQuery(GET_ACCEPTS_UPDATE, {
    variables: { entityId },
  });

  const result = get(data, 'virtualization.acceptsUpdate');

  return [
    {
      data: result,
      loading,
      error,
    },
  ];
};
