import { isObject } from 'lodash';
import { FormattedMessage } from '@cluedin/locale';

import EntityNameCellComponent from '../components/containers/GoldenRecordList/table/EntityNameCellComponent';
import EntityTypeCellComponent from '../components/containers/GoldenRecordList/table/EntityTypeCellComponent';
import EntityDataCellComponent from '../components/containers/GoldenRecordList/table/EntityDataCellComponent';
import VocabularyValueCellComponent from '../components/containers/GoldenRecordList/table/VocabularyValueCellComponent';
import LookupValueCellComponent from '../components/containers/GoldenRecordList/table/LookupValueCellComponent';
import VocabularyDisplayNameComponent from '../components/containers/GoldenRecordList/table/VocabularyDisplayNameComponent';
import { SortingHeaderCell } from '../../../shared/components/SortingHeaderCell';

export const defaultTileConfiguration = [
  {
    property: 'description',
    displayName: <FormattedMessage id="module-coreEntityList-description" />,
    description: (
      <FormattedMessage id="module-coreEntityList-descriptionExpl" />
    ),
    type: 'entity',
  },
];

export const defaultConfiguration = [
  {
    property: 'name',
    displayName: <FormattedMessage id="module-coreEntityList-name" />,
    type: 'entity',
    description: <FormattedMessage id="module-coreEntityList-nameExpl" />,
    fixed: true,
  },
  {
    property: 'entityType',
    displayName: <FormattedMessage id="module-coreEntityList-entityType" />,
    description: <FormattedMessage id="module-coreEntityList-entityExpl" />,
    type: 'entity',
  },
  {
    property: 'description',
    displayName: <FormattedMessage id="module-coreEntityList-description" />,
    description: (
      <FormattedMessage id="module-coreEntityList-descriptionExpl" />
    ),
    type: 'entity',
  },
];

export const createEntityProperty = (entityVocab) => {
  if (isObject(entityVocab)) {
    return {
      displayName: entityVocab.displayName,
      property: entityVocab.property,
      description: entityVocab.description,
      type: 'vocabulary',
      Component: entityVocab.Component,
    };
  }

  const hasSetup = defaultConfiguration.find(
    (eP) => eP.property === entityVocab,
  );

  if (hasSetup) {
    return hasSetup;
  }

  return {
    displayName: entityVocab,
    property: entityVocab,
    description: '',
    type: 'entity',
  };
};

export const createVocabProperty = (vocabName) => {
  if (isObject(vocabName)) {
    return {
      displayName: vocabName.displayName,
      property: vocabName.property,
      description: vocabName.description,
      type: 'vocabulary',
      Component: vocabName.Component,
    };
  }

  return {
    displayName: vocabName,
    property: vocabName,
    description: '',
    type: 'vocabulary',
  };
};

export const allEntityProperties = [
  ...defaultConfiguration,
  {
    property: 'createdDate',
    type: 'entity',
    displayName: <FormattedMessage id="module-coreEntityList-createdDate" />,
    description: (
      <FormattedMessage id="module-coreEntityList-createdDateExpl" />
    ),
  },
  {
    property: 'discoveryDate',
    type: 'entity',
    displayName: <FormattedMessage id="module-coreEntityList-discoveryDate" />,
    description: (
      <FormattedMessage id="module-coreEntityList-discoveryDateExpl" />
    ),
  },
  {
    property: 'modifiedDate',
    displayName: <FormattedMessage id="module-coreEntityList-modifiedDate" />,
    type: 'entity',
    description: (
      <FormattedMessage id="module-coreEntityList-modifiedDateExpl" />
    ),
  },
];

const entityDefaultColumnConfiguration = [
  {
    property: 'name',
    displayName: <FormattedMessage id="module-coreEntityList-name" />,
    Component: EntityNameCellComponent,
  },
  {
    property: 'entityType',
    displayName: <FormattedMessage id="module-coreEntityList-entityType" />,
    Component: EntityTypeCellComponent,
  },
  {
    property: 'description',
    displayName: <FormattedMessage id="module-coreEntityList-description" />,
  },
  {
    property: 'createdDate',
    displayName: <FormattedMessage id="module-coreEntityList-createdDate" />,
    Component: EntityDataCellComponent(),
  },
  {
    property: 'discoveryDate',
    displayName: <FormattedMessage id="module-coreEntityList-discoveryDate" />,
    Component: EntityDataCellComponent('discoveryDate'),
  },
  {
    property: 'modifiedDate',
    displayName: <FormattedMessage id="module-coreEntityList-modifiedDate" />,
    Component: EntityDataCellComponent('discoveryDate'),
  },
];

const createEntityPropertyColumn = (column) => {
  if (column.Component) {
    return column;
  }

  const component = entityDefaultColumnConfiguration.find(
    (e) => e.property === column.property,
  );

  return {
    ...column,
    ...component,
  };
};

const createVocabularyColumn = (
  column,
  vocabKey,
  onChangeSortingByColumn,
  filters,
  hasStrongTypingFeature,
) => {
  if (column.Component) {
    return column;
  }

  const isLookupCompositeName =
    vocabKey?.compositeVocabulary?.dataType === 'Lookup' &&
    vocabKey.name === 'Name';

  const isVocabStrongTyped =
    vocabKey?.storage === 'Typed' && hasStrongTypingFeature;

  // showing sort when vocab is strong typed
  return {
    displayName: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
        }}
      >
        <VocabularyDisplayNameComponent
          vocabKey={vocabKey}
          property={column.property}
        />
        <div
          style={{
            right: 0,
            zIndex: 1,
            position: 'absolute',
          }}
          onClick={(e) => {
            // just a hack to not to call uxi dropdown on this area =)
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <SortingHeaderCell
            disabled={!isVocabStrongTyped}
            disabledReason={
              <FormattedMessage id="module-goldenRecordList-sorting-disabled-reason" />
            }
            onClick={(value) => {
              onChangeSortingByColumn(vocabKey?.key, value);
            }}
            value={
              filters?.sortBy === vocabKey?.key ? filters?.sortDirection : null
            }
          />
        </div>
      </div>
    ),
    property: column.property,
    Component: isLookupCompositeName
      ? LookupValueCellComponent(column.property)
      : VocabularyValueCellComponent(column.property),
  };
};

export const createColumnForGrid = (
  columns = [],
  vocabKeys = [],
  onChangeSortingByColumn,
  filters,
  hasStrongTypingFeature,
) => {
  return columns.map((column) => {
    if (column.type === 'entity') {
      return createEntityPropertyColumn(column);
    }

    if (column.type === 'vocabulary') {
      const vocabKey = vocabKeys.find((v) => {
        return v.key === column.property;
      });

      return createVocabularyColumn(
        column,
        vocabKey,
        onChangeSortingByColumn,
        filters,
        hasStrongTypingFeature,
      );
    }
  });
};

export default {
  defaultConfiguration,
  createColumnForGrid,
};
