import moment from 'moment';
import { getCurrentToken } from '../../config';

export const addTokenToImage = (url, token, small, sizeFromParam) => {
  if (url.indexOf('&size=small') > -1) {
    url = url.replace('&size=small', ''); //eslint-disable-line
  }

  let size = `&size=${sizeFromParam || 'small'}`;

  if (small) {
    size = '&size=icon';
  }

  if (url && token && url.indexOf('blob?') > -1) {
    return `${url + size}&access_token=${token}`;
  }

  return url;
};

export const getName = (entityType) => {
  const entityConfigPerEntityType = null; // getEntityConfiguration(entityType);

  return entityConfigPerEntityType
    ? entityConfigPerEntityType.displayName
    : entityType;
};

export const hasLastChangedBy = (entity) =>
  entity.data ? entity.data.lastChangedBy : entity.lastChangedBy;

export const getModifiedDatFormatted = (entity) =>
  entity.data
    ? entity.data.modifiedDateFormatted
    : entity.modifiedDateFormatted;

export const getCreatedDatFormatted = (entity) =>
  entity.data ? entity.data.createdDateFormatted : entity.createdDateFormatted;

export const getLastChangedBy = (entity) => hasLastChangedBy(entity);

export const getEntityUri = (entity) => {
  const uris = entity.data ? entity.data.uris : entity.uris;
  if (!uris) {
    return null;
  }

  return uris.filter((uri) => uri.origin);
};

export const hasCreatedBy = (entity) => {
  const authors = entity.data ? entity.data.authors : entity.authors;

  if (!authors) {
    return null;
  }

  return authors.filter((a) => a.name);
};

export const getCreatedBy = (entity) => hasCreatedBy(entity);

export const toVM = (entityFrom) => {
  const entity = Object.assign({}, entityFrom);
  const hasDescription = entity.description;
  const differentNameAndDescription = !(entity.description === entity.name);

  entity.showDescription = hasDescription && differentNameAndDescription;

  if (entity.showDescription && entity.description.length > 200) {
    entity.descriptionFormatted = `${entity.description.substring(0, 200)}...`;
  }

  const previewUrl =
    entity && entity.previewImage && entity.previewImage.uri
      ? entity.previewImage.uri
      : null;

  if (previewUrl) {
    entity.previewUrl = addTokenToImage(previewUrl, getCurrentToken());
    entity.hasPreview = true;
  }

  entity.uri = entity.uri ? entity.uri.replace(/\+/g, '%20') : '';
  entity.modifiedDateFormatted = entity.modifiedDate
    ? moment(entity.modifiedDate).fromNow()
    : null;
  entity.createdDateFormatted = entity.createdDate
    ? moment(entity.createdDate).fromNow()
    : null;
  entity.discoveryDateFormatted = entity.discoveryDate
    ? moment(entity.discoveryDate).fromNow()
    : null;

  return entity;
};

export default {
  getName,
  toVM,
  addTokenToImage,
};
