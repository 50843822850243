import { useState, useMemo, useCallback } from 'react';

import { useEntityTypeConfigurations } from './useEntityTypeConfigurations';

export const usePaginatedEntities = ({
  pageNumber = 1,
  defaultPageSize = 10,
  initialSearchName = '',
  loadMoreLabel = 'Load more',
  useQueryEntities = useEntityTypeConfigurations,
}) => {
  const [searchEntities, setSearchEntities] = useState(initialSearchName);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [entities, isLoadingEntities, errorLoadingEntities] = useQueryEntities({
    pageSize,
    pageNumber,
    searchName: searchEntities,
  });

  const onLoadMore = useCallback(
    () => setPageSize((p) => p + defaultPageSize),
    [defaultPageSize],
  );

  const totalEntities = useMemo(() => entities?.total, [entities?.total]);

  const loadMore = useMemo(() => {
    if (totalEntities <= defaultPageSize) return;

    return {
      onClick: onLoadMore,
      label: loadMoreLabel,
    };
  }, [totalEntities, defaultPageSize, onLoadMore]);

  return {
    loadMore,
    totalEntities,
    setSearchEntities,
    isLoadingEntities,
    errorLoadingEntities,
    entities: entities?.data,
  };
};
