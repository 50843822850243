import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';
import { Loader } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import { useEntityTopology } from '../../hooks/useEntityTopology';
import EntityTopologyContainer from '../containers/EntityTopologyContainer/EntityTopologyContainer';
import {
  EntityTopologyPageWrapper,
  EntityTopologyOverlay,
  EntityTopologyOverlayText,
  EntityTopologyOverlayTextBox,
  EntityTopologyOverlayTitle,
  EntityTopologyOverlayTextWrapper,
  EntityTopologyOverlayIcon,
  EntityTopologyOverlayWrapper,
} from './EntityTopologyPage.styles';

const EntityToplogyPage = ({ entityId, removeMargin }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [currentNode, setCurrentNode] = useState({});
  const [getTopology, { data, loading, error }] = useEntityTopology(entityId);
  const [filter, setFilter] = useState('');
  const [nodeDataType, setNodeDataType] = useState('');

  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    getTopology(filter);
  }, [filter]);

  const handleExecuteAction = () => {
    setShowOverlay(true);
  };

  useEffect(() => {
    if (showOverlay) {
      const overlayTimer = setTimeout(() => {
        setShowOverlay(false);
      }, 30000);

      return () => clearTimeout(overlayTimer);
    }

    getTopology(filter);
  }, [showOverlay, filter]);

  return (
    <>
      <EntityTopologyPageWrapper removeMargin={removeMargin}>
        {!loading && !error && currentNode && (
          <EntityTopologyContainer
            entityId={entityId}
            currentNode={currentNode}
            setCurrentNode={setCurrentNode}
            topologyData={data}
            topologyLoading={loading}
            topologyError={error}
            setFilter={setFilter}
            filter={filter}
            nodeDataType={nodeDataType}
            setNodeDataType={setNodeDataType}
            handleExecuteAction={handleExecuteAction}
          />
        )}
      </EntityTopologyPageWrapper>

      {showOverlay && (
        <EntityTopologyOverlay>
          <EntityTopologyOverlayWrapper>
            <EntityTopologyOverlayTextWrapper themePrimary={themePrimary}>
              <EntityTopologyOverlayText>
                <EntityTopologyOverlayIcon>
                  <Loader size={28} />
                </EntityTopologyOverlayIcon>

                <EntityTopologyOverlayTextBox>
                  <EntityTopologyOverlayTitle>
                    <FormattedMessage id="module-entityTopology-overlayProcessingActionTitle" />
                  </EntityTopologyOverlayTitle>
                  <FormattedMessage id="module-entityTopology-overlayProcessingActionMessage" />
                </EntityTopologyOverlayTextBox>
              </EntityTopologyOverlayText>
            </EntityTopologyOverlayTextWrapper>
          </EntityTopologyOverlayWrapper>
        </EntityTopologyOverlay>
      )}
    </>
  );
};

export default EntityToplogyPage;

EntityToplogyPage.propTypes = {
  entityId: PropTypes.string,
  removeMargin: PropTypes.bool,
};
