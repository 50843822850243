import React from 'react';
import { Select } from '@cluedin/form';
import { FirstLetterComponent } from '@cluedin/components';
import { injectIntl } from '@cluedin/locale';
import { CoreVocabulary } from '@cluedin/svgs';
import { AvatarWithName } from 'uxi/Img';
import PropTypes from 'prop-types';

import { EntityPropertiesDropdownFilterWrapper } from './EntityPropertiesDropdownFilter.styles';
import { urlBuilder } from '../../../../../../config';

const EntityPropertiesDropdownFilter = ({
  connectorId,
  connectors,
  intl,
  setConnectorId,
}) => {
  const newOptions = (connectors || [])
    .filter(Boolean)
    .filter((c) => c?.id)
    .map((i) => {
      return {
        value: i?.id,
        label: i?.name,
        dataTest: `entity-entityProperties-integrationDropdown-${i?.name}`,
        icon: (
          <AvatarWithName
            contain
            isSquare
            src={i?.icon ? urlBuilder.api(i?.icon) : null}
            icon={
              i?.icon ? null : (
                <FirstLetterComponent customSize="24" customFontSize="14" />
              )
            }
            style={{ display: 'inline-flex' }}
          />
        ),
      };
    });

  const handleOnChange = ({ value }) => {
    setConnectorId(value);
  };

  return (
    <EntityPropertiesDropdownFilterWrapper>
      <Select
        onChange={handleOnChange}
        options={[
          {
            value: '',
            label: intl.formatMessage({
              id: 'module-integration-allIntegrationsFilter',
            }),
            dataTest:
              'entity-entityProperties-integrationDropdown-allIntegrations',
          },
          {
            value: 'noSource',
            label: intl.formatMessage({
              id: 'module-integration-noSourceFilter',
            }),
            dataTest: 'entity-entityProperties-integrationDropdown-noSource',
          },
          {
            value: 'core',
            label: intl.formatMessage({ id: 'module-integration-coreFilter' }),
            dataTest: 'entity-entityProperties-integrationDropdown-core',
            icon: (
              <CoreVocabulary
                style={{
                  height: '24px',
                  maxHeight: '24px',
                  marginRight: '6px',
                  width: '24px',
                  maxWidth: '24px',
                }}
              />
            ),
          },
          ...newOptions,
        ]}
        value={connectorId || ''}
      />
    </EntityPropertiesDropdownFilterWrapper>
  );
};

export default injectIntl(EntityPropertiesDropdownFilter);

EntityPropertiesDropdownFilter.propTypes = {
  connectorId: PropTypes.string,
  setConnectorId: PropTypes.func,
  connectors: PropTypes.array,
};

EntityPropertiesDropdownFilter.defaultProps = {
  connectorId: null,
  setConnectorId: null,
  connectors: [],
};
