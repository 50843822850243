import React from 'react';

export const zoomControl = (scale, network) => {
  let minZoom = 0.1;
  let maxZoom = 3;
  let lastZoomPosition = network?.getViewPosition();
  if (scale <= minZoom) {
    network?.moveTo({
      scale: minZoom,
      position: lastZoomPosition,
    });
  } else if (scale >= maxZoom) {
    network?.moveTo({
      position: lastZoomPosition,
      scale: maxZoom,
    });
  } else {
    lastZoomPosition = network?.getViewPosition();
  }
};

export const useZoomControl = () => {
  return zoomControl;
};
