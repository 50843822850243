/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Arrowup, Arrowdown } from 'uxi/Icons';

const SearchMainFilterWrapperDiv = styled.div`
  background: #fff;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 0;
`;
const SearchMainFilterWrapperChildren = styled.div`
  transition: max-height 0.4s ease-out;
  max-height: 2000;
  overflow: hidden;
`;

const AllLinkWrapper = styled.div`
  font-size: 14px;
  color: rgb(47, 47, 50);
  font-weight: 600;
  background: rgb(241, 241, 240);
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(227, 227, 228);
  cursor: pointer;
  &:hover {
    background: rgba(241, 241, 240, 0.5);
  }
`;

class SearchMainFilterWrapper extends Component {
  constructor(props) {
    super(props);
    if (props.noHeader) {
      this.state = {
        isOpen: true,
      };
    } else {
      this.state = {
        isOpen: props.initialIsOpen,
      };
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { children, title, noHeader } = this.props;

    const expandIcon = this.state.isOpen ? (
      <Arrowup size={12} color="rgb(47, 47, 50)" />
    ) : (
      <Arrowdown size={12} color="rgb(47, 47, 50)" />
    );

    const AllLink = (
      <AllLinkWrapper onClick={this.toggle.bind(this)}>
        {title}
        {expandIcon}
      </AllLinkWrapper>
    );

    let wrapperStyle = {};

    if (!this.state.isOpen) {
      wrapperStyle = { maxHeight: 0 };
    }

    if (noHeader) {
      return <div>{children}</div>;
    }

    return (
      <SearchMainFilterWrapperDiv>
        {AllLink}
        <SearchMainFilterWrapperChildren style={wrapperStyle}>
          {children}
        </SearchMainFilterWrapperChildren>
      </SearchMainFilterWrapperDiv>
    );
  }
}

export default SearchMainFilterWrapper;
