import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, Form, Errors } from 'react-redux-form';
import { PrimaryButton } from '@cluedin/form';
import { connect as connectToStore } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { Flex } from 'uxi/Layout';
import { shouldChangePassword } from '../../actions';
import FormDecorator from '../../../entity/entityTypes/generic/Form/Fields/FormDecorator';

const inputStyle = {
  width: '100%',
  padding: '5px 8px',
  margin: '10px 0',
  boxSizing: 'border-box',
  fontSize: '14px',
  border: '1px solid #ccc',
};

const HrStyle = {
  borderBottom: '1px dashed #dee5e7',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  height: '2px',
  fontSize: 0,
  overflow: 'hidden',
  marginTop: '0',
  marginBottom: '15px',
};

const PasswordCustomComponent = (props) => (
  <div data-test={`userSettings_${props.name}`}>
    <input type="password" style={inputStyle} {...props} />
  </div>
);

const required = (val) => val && val.length;
const length = (val) => val && val.length > 7;
const passwordsMatch = ({ newpassword, confirmpassword }) =>
  newpassword === confirmpassword;
// eslint-disable-next-line react/prop-types
const errorComponent = (props) => (
  <div style={{ color: 'red' }}>{props.children}</div>
);

class ChangePassword extends Component {
  static propTypes = {
    children: PropTypes.any, // eslint-disable-line react/no-unused-prop-types
    dispatch: PropTypes.func,
    newpassword: PropTypes.string,
    isChangingPassword: PropTypes.bool,
    isChangingPasswordSucceed: PropTypes.bool,
    isChangingPasswordError: PropTypes.bool,
  };

  static defaultTypes = {
    children: null,
    dispatch: () => {},
    newpassword: PropTypes.string,
    isChangingPassword: false,
    isChangingPasswordSucceed: false,
    isChangingPasswordError: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      score: 0,
      suggestions: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { oldpassword, newpassword, confirmpassword } = this.props;

    if (!oldpassword && !newpassword && !confirmpassword) {
      return null;
    }

    this.props.dispatch(
      shouldChangePassword({
        oldpassword,
        newpassword,
        confirmpassword,
      }),
    );
  }

  render() {
    if (this.props.isUsingSSO) {
      return (
        <Flex>
          <Alert>
            <FormattedHTMLMessage id="module-users-ssoReset" />
          </Alert>
        </Flex>
      );
    }

    const {
      newpassword,
      isChangingPassword,
      isChangingPasswordSucceed,
      isChangingPasswordError,
    } = this.props;

    // let score;
    // let suggestions;
    const errorContent = isChangingPasswordError && (
      <div
        data-test="userSettings_changePassword_passwordChanged"
        style={{ margin: '15px 0' }}
      >
        <Alert type="danger">
          <FormattedMessage id="module-user-oppsSomethingWrong" />
        </Alert>
      </div>
    );
    const successContent = isChangingPasswordSucceed && (
      <div style={{ margin: '15px 0' }}>
        <Alert type="success">
          <FormattedMessage id="module-user-passwordChanged" />
        </Alert>
      </div>
    );

    if (newpassword) {
      // const passwordAudit = zxcvbn(newpassword);
      // score = passwordAudit.score;
      // suggestions = passwordAudit.feedback.suggestions;
    }

    return (
      <Form
        model="changePassword"
        // onSubmit={this.handleSubmit}
        validators={{ '': { passwordsMatch } }}
      >
        {errorContent}
        {successContent}
        <FormDecorator
          isRequired
          label={<FormattedMessage id="module-user-currentPasswordLabel" />}
        >
          <Control
            component={PasswordCustomComponent}
            model="changePassword.oldpassword"
            validators={{ required }}
          />
          <Errors
            model="changePassword.oldpassword"
            show="touched"
            wrapper={errorComponent}
            messages={{
              required: <FormattedMessage id="module-user-passwordRequired" />,
            }}
          />
        </FormDecorator>
        <hr style={HrStyle} />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <FormDecorator
              isRequired
              label={<FormattedMessage id="module-user-newPasswordLabel" />}
            >
              <Control
                component={PasswordCustomComponent}
                model="changePassword.newpassword"
                validators={{ required, length }}
              />
              <Errors
                model="changePassword.newpassword"
                show="touched"
                wrapper={errorComponent}
                messages={{
                  required: (
                    <FormattedMessage id="module-user-requiredNewPassword" />
                  ),
                  length: (
                    <FormattedMessage id="module-user-newPasswordInvalidLength" />
                  ),
                }}
              />
            </FormDecorator>
            <FormDecorator
              isRequired
              label={
                <FormattedMessage id="module-user-newPasswordConfirmLabel" />
              }
            >
              <Control
                component={PasswordCustomComponent}
                model="changePassword.confirmpassword"
              />
              <Errors
                model="changePassword"
                show="touched"
                wrapper={errorComponent}
                messages={{
                  passwordsMatch: (
                    <FormattedMessage id="module-user-passwordShouldBeSame" />
                  ),
                }}
              />
            </FormDecorator>
            <hr style={HrStyle} />
            <div data-test="userSettings_changePassword_changePasswordButton">
              <PrimaryButton
                loading={isChangingPassword}
                text={<FormattedMessage id="module-user-changePassword" />}
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const select = ({ changePassword, user, publicModule = {} }) => ({
  oldpassword: changePassword.oldpassword,
  newpassword: changePassword.newpassword,
  confirmpassword: changePassword.confirmpassword,
  isChangingPassword: user.isChangingPassword,
  isChangingPasswordSucceed: user.isChangingPasswordSucceed,
  isChangingPasswordError: user.isChangingPasswordError,
  isUsingSSO: publicModule.isUsingSSO,
});

export default connectToStore(select)(ChangePassword);
