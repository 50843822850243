import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import {
  ExplainLogSummaryBox,
  ExplainLogDataWrapper,
  ExplainLogSummaryWrapper,
} from './ExplainLogSummary.styles';
import ExplainLogSummaryTable from '../ExplainLogSummaryTable/ExplainLogSummaryTable';

const ExplainLogSummary = ({ data }) => {
  const columnTitles = get(data, 'prebuiltTable.columnTitles');
  const rows = get(data, 'prebuiltTable.rows');
  const tableTitle = get(data, 'prebuiltTable.title');

  return (
    <ExplainLogSummaryBox>
      <ExplainLogSummaryWrapper>
        <ExplainLogDataWrapper>
          {data && (
            <ExplainLogSummaryTable
              columnTitles={columnTitles}
              rows={rows}
              tableTitle={tableTitle}
            />
          )}
        </ExplainLogDataWrapper>
      </ExplainLogSummaryWrapper>
    </ExplainLogSummaryBox>
  );
};

export default ExplainLogSummary;

ExplainLogSummary.propTypes = {
  data: PropTypes.object,
};

ExplainLogSummary.defaultProps = {
  data: {},
};
