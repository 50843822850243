/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  FC,
  SetStateAction,
  Dispatch,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  useEntityTypeLayoutConfigurations,
  EntityTypeLayoutConfiguration,
} from '../../../../entityTypes/hooks/useEntityTypeLayoutConfigurations';
import { useViewModeWithCustomizedColumns } from '../GoldenRecordList/hooks/useViewModeWithCustomizedColumns';

type SelectedItem = {
  id: string;
  name: string;
  entityType: string;
};

const useListSelectionStore = (): {
  selectionActive: boolean;
  selected: SelectedItem[];
  toggleSelectionActive: () => void;
  setSelected: Dispatch<SetStateAction<SelectedItem[]>>;
} => {
  const [selected, setSelected] = useState<SelectedItem[]>([]);
  const [selectionActive, setSelectionActive] = useState(false);

  const toggleSelectionActive = useCallback(() => {
    setSelected(() => []);

    setSelectionActive((prev) => !prev);
  }, []);

  return {
    selected,
    setSelected,
    selectionActive,
    toggleSelectionActive,
  };
};

export type ListSelectionStoreReturnType = ReturnType<
  typeof useListSelectionStore
>;

export type StaticGoldenRecordListContextType = {
  listOptions: any;
  viewOptions: any;
  hasSelection: boolean;
  hideListSearch: boolean;
  loadingEntityTypes: boolean;
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  selectionState: ListSelectionStoreReturnType;
  entityTypesConfigurations: EntityTypeLayoutConfiguration[];
};

export const StaticGoldenRecordListContext =
  createContext<StaticGoldenRecordListContextType | null>(null);

type StaticGoldenRecordListProviderProps = {
  viewName?: string;
  children: ReactNode;
  hasSelection?: boolean;
  hideListSearch?: boolean;
  defaultEntityKeys?: string[] | unknown;
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  defaultVocabularyKeys: string[] | unknown;
  entities?: any;
  total?: number;
  loading?: boolean;
};

export const StaticGoldenRecordListProvider: FC<
  StaticGoldenRecordListProviderProps
> = ({
  history,
  children,
  location,
  viewName = 'mainSearch',
  defaultEntityKeys,
  hasSelection = false,
  defaultVocabularyKeys,
  hideListSearch = false,
  entities,
  total,
  loading = false,
}) => {
  const selectionState = useListSelectionStore();

  const { data: entityTypesConfigurations, loading: loadingEntityTypes } =
    useEntityTypeLayoutConfigurations();

  const [
    { DataGrid, DataGridGhost, columnsForGrid },
    {
      columns,
      saveCustomizedColumns,
      addColumns,
      deleteColumns,
      restrictions: { maxNumberColumns, excludePropertyKeys },
    },
    { viewMode, setViewMode },
  ] = useViewModeWithCustomizedColumns(
    viewName,
    defaultVocabularyKeys,
    defaultEntityKeys,
  );

  const value = {
    history,
    location,
    hasSelection,
    hideListSearch,
    entityTypesConfigurations,
    loadingEntityTypes,
    listOptions: {
      total,
      loading,
      entities: (entities || []).map((e: any) => ({
        ...e,
        useStaticContext: true,
      })),
    },
    viewOptions: {
      DataGrid,
      DataGridGhost,
      columnsForGrid,
      columns,
      saveCustomizedColumns,
      addColumns,
      deleteColumns,
      restrictions: {
        maxNumberColumns,
        excludePropertyKeys,
      },
      viewMode,
      setViewMode,
    },
    selectionState,
  };

  return (
    <StaticGoldenRecordListContext.Provider value={value}>
      {children}
    </StaticGoldenRecordListContext.Provider>
  );
};
