import React, { Component } from 'react';
import { getSubdomain } from '../../helpers/url';
import { location, url } from '../../../../config';
import SSoLoginWrapper from '../../../public/components/containers/SSoLoginWrapper';
import DefaultComponent from '../../../public/components/containers/DefaultComponent';

const subdomainChooser = (token, ClientPage, AppPage) => (props) => {
  const domain = getSubdomain();
  let PageForApp = AppPage;

  if (!AppPage) {
    PageForApp = DefaultComponent;
  }
  return domain === url.appSubdomain || !token ? (
    <PageForApp {...props} />
  ) : (
    <ClientPage {...props} />
  );
};

export const AppOrClientPagePublicChooser =
  (ClientPage, AppPage) => (props) => {
    const domain = getSubdomain();
    return domain === url.appSubdomain ? (
      <AppPage {...props} />
    ) : (
      <ClientPage {...props} />
    );
  };

export const OnlyAppSubDomain = (Page, props) =>
  class OnlyAppSubDomainHoc extends Component {
    componentDidMount() {
      const domain = getSubdomain();
      if (domain !== url.appSubdomain) {
        location.goToMainApp();
      }
    }

    render() {
      const domain = getSubdomain();
      if (domain !== url.appSubdomain) {
        return <div />;
      }
      return <Page {...this.props} {...props} />;
    }
  };

/* eslint-disable react/no-multi-comp */
export const OnlyClientIdSubDomain = (Page) =>
  class OnlyClientIdSubDomainHoc extends Component {
    componentDidMount() {
      const domain = getSubdomain();
      if (domain === url.appSubdomain) {
        window.location = '/signin';
      }
    }

    render() {
      const domain = getSubdomain();

      if (domain === url.appSubdomain) {
        return <div />;
      }

      return <Page {...this.props} />;
    }
  };

export const SSOLogin = (Page, type) => (props) => {
  return (
    <SSoLoginWrapper type={type}>
      <Page {...props} />
    </SSoLoginWrapper>
  );
};

export default subdomainChooser;
