/**
 *
 * Data Structure of a ConsentForm (the thing we show on the website)
 *
    const consentForm =
    {
      Consent: [
        'fad738bf-b49f-40e3-a7e5-28c51f4418e1',
        '4248b318-b5dd-4859-a861-0ff762fe8f88',
      ],
      Id: '8190ebb8-59fd-481c-a77b-4b6260cd02af',
      OrganizationId: '0823f3da-1e2b-4781-aaa9-463a6b5e61f2',
      Title: 'Do I give consent to CluedIn?',
      PrivacyUrl: 'https://cluedin.net/privacy',
      Explanation: 'We use ...',
      Consents: [
        [
          {
            AutomaticDecision: false,
            AutomaticProcessing: false,
            Consent: 'consent',
            Id: 'fad738bf-b49f-40e3-a7e5-28c51f4418e1',
            Identifiers: null,
            IsRequired: false,
            Name: 'HubSpot',
            OrganizationId: '0823f3da-1e2b-4781-aaa9-463a6b5e61f2',
            ProviderId: '7673c455-074a-499f-afd3-c77dfbb7bc1f' },
        ],
        [
          {
            AutomaticDecision: false,
            AutomaticProcessing: false,
            Consent: 'consent',
            Id: '4248b318-b5dd-4859-a861-0ff762fe8f88',
            Identifiers: null,
            IsRequired: false,
            Name: 'MixPanel',
            OrganizationId: '0823f3da-1e2b-4781-aaa9-463a6b5e61f2',
            ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
          },
        ],
      ],
    };
 */

/**
 * This method takes a consent and a list of Integrations
 * and it returns a Data Structure (view Model) for our container which will looks like:
 *
  {
    uniqueIdentifiers:
    [
      {
        identifier:'Emal',
        consent:
          {
            IsRequired: false,
            ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
            integration: { IntegrationObject },
          },
      },
      {
          identifier:'IPAddress',
          consent:
          {
            IsRequired: false,
            ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
            integration: { IntegrationObject },
          },
      }
    ],
    dataProcessors: [{
        IsRequired: false,
        ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
        integration: { IntegrationObject }
    }],
  }
 */
const fromConsentToUniqueIdentifierAndProcessor = (
  consents = [],
  integrations,
) => {
  const processors = [];

  (consents || [])
    .map((consent) => ({
      ...consent,
      integration: integrations.find(
        (integration) => integration.Id === consent.ProviderId,
      ),
    }))
    .forEach((consent) => {
      /* if (consent.Identifiers) {
          try {
            const identifiers = consent.Identifiers;
            if (identifiers && identifiers.length > 0) {
              identifiers.forEach((c) => {
                uniqueIdentifiers.push({
                  identifier: c,
                  consent: {
                    Id: consent.Id,
                    IsRequired: consent.IsRequired,
                    ProviderId: consent.ProviderId,
                    integration: consent.integration,
                  },
                });
              });
            } else {
              processors.push({
                Id: consent.Id,
                IsRequired: consent.IsRequired,
                ProviderId: consent.ProviderId,
                integration: consent.integration,
              });
            }
          } catch (e) {
            processors.push({
              Id: consent.Id,
              IsRequired: consent.IsRequired,
              ProviderId: consent.ProviderId,
              integration: consent.integration,
            });
          }
        } else {        } */
      processors.push({
        Id: consent.Id,
        IsRequired: consent.IsRequired,
        ProviderId: consent.ProviderId,
        integration: consent.integration,
        identifiers: consent.Identifiers,
        Consent: consent.Consent,
      });
    });

  return {
    processors,
  };
};

export const toConsentViewModel = (consents = [], integrations) =>
  consents.map((consent) => {
    let identifiers = [];
    if (consent.IdentifiersString === '"[]"') {
      identifiers = [];
    } else {
      try {
        identifiers = JSON.parse(consent.IdentifiersString);
      } catch (e) {
        identifiers = [];
      }
    }

    return {
      ...consent,
      Identifiers: identifiers,
      integration: integrations.find(
        (integration) => integration.Id === consent.ProviderId,
      ),
    };
  });

/**
 * This method takes a consentForm and a list of Integrations
 * and it returns a Data Structure (view Model) for our container which will looks like:
 *
  {
    title,
    shortExplanation,
    privacyLink,
    uniqueIdentifiers:
    [
      {
        identifier:'Emal',
        consent:
          {
            IsRequired: false,
            ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
            integration: { IntegrationObject },
          },
      },
      {
          identifier:'IPAddress',
          consent:
          {
            IsRequired: false,
            ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
            integration: { IntegrationObject },
          },
      }
    ],
    dataProcessors: [{
        IsRequired: false,
        ProviderId: '14efb902-36fd-4031-9c8a-e016fa226b1f',
        integration: { IntegrationObject }
    }],
  }
 */
export const toConsentFormViewModel = (consentForm, integrations = []) => {
  // In this method we will 'enhanced' the Data Structure to have the Integration included
  // Also we will create UniqueIdentifier consent from the Consents []
  // and extract the Data Processor from the Consents [] which does not have an Identifiers
  if (!consentForm.Consents || consentForm.Consents.length === 0) {
    return {
      id: consentForm.Id,
      title: consentForm.Title,
      shortExplanation: consentForm.Explanation,
      privacyLink: consentForm.PrivacyUrl,
      consentForm,
      processors: [],
    };
  }

  const consentViewModels = fromConsentToUniqueIdentifierAndProcessor(
    consentForm.Consents || [],
    integrations,
  );

  return {
    id: consentForm.Id,
    title: consentForm.Title,
    shortExplanation: consentForm.Explanation,
    privacyLink: consentForm.PrivacyUrl,
    processors: consentViewModels.processors,
  };
};

export const fromConsentFormViewModelToAPIModel = (
  viewModel,
  organizationId,
) => {
  const {
    title,
    shortExplanation,
    privacyLink,
    // uniqueIdentifiers,
    processors,
  } = viewModel;

  const APIFormModel = {
    ConsentIds: [],
    Title: title,
    Explanation: shortExplanation,
    PrivacyUrl: privacyLink,
  };

  const arrayOfConsent = (processors || []).map((processor) => ({
    Name: processor.integration.Name,
    OrganizationId: organizationId,
    ProviderId: processor.ProviderId,
    Consent: processor.Consent,
    IsRequired: false,
    AutomaticDecision: false,
    AutomaticProcessing: false,
    Identifiers: processor.identifiers,
  }));

  /*
  uniqueIdentifiers.forEach((uniqueIdentifier) => {
    const hasFound = arrayOfConsent.find(
      consent => consent.ProviderId === uniqueIdentifier.consent.ProviderId
    );

    if (hasFound) {
      hasFound.Identifiers.push(uniqueIdentifier.identifier);
    } else {
      arrayOfConsent.push({
        Name: uniqueIdentifier.consent.integration.Name,
        OrganizationId: organizationId,
        ProviderId: uniqueIdentifier.consent.integration.Id,
        Consent: 'consent',
        IsRequired: uniqueIdentifier.consent.IsRequired,
        Identifiers: [uniqueIdentifier.identifier],
        AutomaticDecision: false,
        AutomaticProcessing: false,
      });
    }
  }); */

  return {
    form: APIFormModel,
    consents: arrayOfConsent,
  };
};

export const fromConsentFormtoConsumableForWebsite = (
  consentFormViewModel,
  consentEntries,
) => {
  // In the object
  // FormId
  // For Data Processor
  // { providerId, providerName, hasConsent, consentId }
  // For unique Identifiers
  // { identifier, providerName, providerId, hasConsent, consentId}
  // const consumableUniqueIdentifiers = [];
  const consumableProcessors = [];

  consentEntries.forEach((consentEntry) => {
    const consentId = consentEntry.consentId || consentEntry.ConsentId;

    /* if (
      consentFormViewModel.uniqueIdentifiers &&
      consentFormViewModel.uniqueIdentifiers.length > 0
    ) {
      const hasFound = consentFormViewModel.uniqueIdentifiers.find(
        uIdentifier => uIdentifier.consent.Id === consentId
      );

      if (hasFound) {
        consumableUniqueIdentifiers.push({
          identifier: hasFound.identifier,
          providerId: hasFound.consent.integration.Id,
          providerName: hasFound.consent.integration.Name,
          hasConsent: true,
        });
      }
    } */

    if (
      consentFormViewModel.processors &&
      consentFormViewModel.processors.length > 0
    ) {
      const hasFound = consentFormViewModel.processors.find(
        (uIdentifier) => uIdentifier.Id === consentId,
      );

      if (hasFound) {
        consumableProcessors.push({
          providerId: hasFound.integration.Id,
          providerName: hasFound.integration.Name,
          identifiers: hasFound.identifiers,
          hasConsent: true,
        });
      }
    }
  });

  return {
    formId: consentFormViewModel.Id,
    // uniqueIdentifiers: consumableUniqueIdentifiers || [],
    processors: consumableProcessors || [],
  };
};

export default {
  toConsentFormViewModel,
  fromConsentFormViewModelToAPIModel,
  toConsentViewModel,
  fromConsentFormtoConsumableForWebsite,
};
