import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'uxi/Input';
import { connect } from 'react-redux';
import { Loader } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import { shouldUpdateConfigurationPermissionFromUser } from '../../../configuration/actions';

const UserPermissionForConfigurationCell = ({
  configuration,
  isFetching,
  providersPerUser = [],
  updateConfiguationPersmissions,
}) => {
  const hasIntegrationAccess = useUserHasAccess(
    actionsConfiguration.integrations.configuredIntegrations
      .configuredIntegration.edit.claims,
  );

  const hasAccess =
    providersPerUser.filter((p) => p.Id === configuration.Id).length > 0;

  if (isFetching) {
    return (
      <div style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }

  if (!hasIntegrationAccess) {
    return hasAccess ? (
      <FormattedMessage id="module-user-accessGranted" />
    ) : (
      <FormattedMessage id="module-user-accessDenied" />
    );
  }

  return (
    <Switch
      name={`userPermissionSwitch-${configuration.Id}`}
      onChange={updateConfiguationPersmissions}
      defaultChecked={hasAccess}
    />
  );
};

UserPermissionForConfigurationCell.displayName =
  'UserPermissionForConfigurationCell';

UserPermissionForConfigurationCell.propTypes = {
  configuration: PropTypes.object,
  isFetching: PropTypes.bool,
  updateConfiguationPersmissions: PropTypes.func.isRequired,
};

UserPermissionForConfigurationCell.defaultProps = {
  configuration: {},
  isFetching: false,
};

const mapToStateProps = (
  {
    configuration: {
      isChangingPermissionForUser,
      isChangingPermissionsForConfiguration,
    },
    user: { allProvidersByUser, isFetchingAllProvidersForUser },
  },
  { user, configuration },
) => {
  const isFetchingProviderListForUser =
    isFetchingAllProvidersForUser[user.Account.Id];
  const permissionForUser = isChangingPermissionForUser[user.Account.Id];
  const isFetchingForUser = permissionForUser && permissionForUser.isFetching;

  const permissionForConfiguration =
    isChangingPermissionsForConfiguration[configuration.Id];
  const isFetchingForConfiguration =
    permissionForConfiguration && permissionForConfiguration.isFetching;

  const providerListForUser = (
    allProvidersByUser[user.Account.Id] || []
  ).filter((r) => r.UserId === user.Account.Id);

  return {
    isFetching:
      (isFetchingForUser && isFetchingForConfiguration) ||
      isFetchingProviderListForUser,
    allProvidersByUser: allProvidersByUser[user.Account.Id] || [],
    providerListForUser,
    providersPerUser: providerListForUser,
  };
};

const mapToDispatchProps = (dispatch, { configuration, user }) => ({
  updateConfiguationPersmissions: () => {
    dispatch(
      shouldUpdateConfigurationPermissionFromUser({
        userId: user.Account.Id,
        configurationId: configuration.Id,
      }),
    );
  },
});

export default connect(
  mapToStateProps,
  mapToDispatchProps,
)(UserPermissionForConfigurationCell);
