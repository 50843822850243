import { useEffect, useMemo, useRef, useState } from 'react';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { Stepper } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { Loader } from '@cluedin/atoms';
import cogoToast from 'cogo-toast';
import {
  CancelButton,
  PrimaryButton,
  PanelContent,
  PanelFooter,
  withConfirmDialog,
  ConfirmCancelDialog,
  PanelHeader,
  PanelClose,
  GqlErrorMessages,
} from '@cluedin/form';

import FileUploadOptionForm from '../forms/FileUploadOptionForm';
import usePrevious from '../../../../core/hooks/usePrevious';
import { useCreateDataSource } from '../../../hooks/useCreateDataSource';
import EndpointCreationForm from '../forms/EndpointCreationForm';
import { useCreateDataSets } from '../../../hooks/useCreateDataSets';
import { InfoLoader } from '../../composites/InfoLoader';
import { isAlphaNumeric, isEmpty } from '../../../../core/validation';
import { useQueryAllAnnotations } from '../../../hooks/useQueryAllAnnotations';
import {
  OnlyAlphaNumbericErrorMessage,
  NotEmptyErrorMessage,
  NotLongErrorMessage,
} from '../../../../core/validation/messages';
import { withEntityTypeConfigurationReload } from '../../../../wms/components/Hocs/withEntityTypeConfigurationReload';
import { useAppContext } from '../../../../../shared/context/appContext';
import { useCreateDataSourceSet } from '../../../hooks/useCreateDataSourceSet';

const defaultStepperState = { active: 1, completed: [] };

const AddIngestionPointWithoutDataSetComponent = ({
  onClose,
  reloadEntityConfiguration,
}) => {
  const history = useHistory();

  const {
    me: {
      client: { id: userId },
    },
  } = useAppContext();

  const [dataSourceName, setDataSourceName] = useState('');
  const [newDataSourceSet, setNewDataSourceSet] = useState(true);
  const [dataSourceSetName, setDataSourceSetName] = useState('');
  const [selectedDataSourceSetId, setDataSourceSetId] = useState(null);
  const [stepperState, setStepperState] = useState(defaultStepperState);
  const [endPointName, onChangeEndpointName] = useState('');
  const [entityTypeObject, onChangeEntityType] = useState('');

  const [mappingMode, setMappingMode] = useState('new');
  const [selectedDataSet, setDataSet] = useState();

  const [allExistingAnnotations, isLoadingAllExistingAnnotations] =
    useQueryAllAnnotations();

  const needConfirm =
    newDataSourceSet || dataSourceName || selectedDataSourceSetId;
  const ButtonWithConfirm = needConfirm
    ? withConfirmDialog(CancelButton, ConfirmCancelDialog)
    : CancelButton;
  const ClosePanelButton = needConfirm
    ? withConfirmDialog(PanelClose, ConfirmCancelDialog)
    : PanelClose;

  const [
    createDataSource,
    {
      data: dataSource,
      loading: loadingCreateDataSource,
      error: errorCreateDataSource,
    },
  ] = useCreateDataSource();

  const [
    createDataSourceSet,
    { loading: isSaving, error: errorCreateDataSourceSet },
  ] = useCreateDataSourceSet();

  const author = useMemo(() => userId, [userId]);

  const currentDataSourceId = useRef(dataSource?.id);

  const dataSourceId = currentDataSourceId.current || dataSource?.id;
  const prevDataSourceId = usePrevious(dataSourceId);

  useEffect(() => {
    if (dataSource?.id && dataSource?.id !== currentDataSourceId.current) {
      currentDataSourceId.current = dataSource?.id;
    }
  }, [dataSource]);

  const onEndpointCreated = () => {
    cogoToast.success(`Endpoint added successfully`, {
      position: 'bottom-right',
      hideAfter: 5,
    });

    if (reloadEntityConfiguration) {
      reloadEntityConfiguration();
    }

    history.push(
      `/admin/inbound/datasourceset/datasource/${currentDataSourceId.current}`,
    );
  };

  const [
    createEndpoint,
    { loading: isSavingEndpoint, error: errorCreatingEndpoint },
  ] = useCreateDataSets(onEndpointCreated);

  const isEmptyNameText = isEmpty(dataSourceName) && !!dataSourceName.length;
  const isValidFormatDataSourceName = isAlphaNumeric(dataSourceName);
  const isNotLongDataSourceName = dataSourceName?.length < 256;

  const isEmptyDataSourceGroupName =
    isEmpty(dataSourceSetName) && !!dataSourceSetName.length;
  const isValidFormatDataSourceGroupName = isAlphaNumeric(dataSourceSetName);
  const isNotLongDataSourceGroupName = dataSourceSetName?.length < 256;

  const isEmptyEndPointName = isEmpty(endPointName) && !!endPointName.length;
  const isValidFOrmatEndpointName = isAlphaNumeric(endPointName);
  const isNotLongEndpointName = endPointName?.length < 256;

  const isValidEndpointName =
    !!endPointName &&
    isValidFOrmatEndpointName &&
    isNotLongEndpointName &&
    !isEmptyEndPointName;

  const isValidDataSourceName =
    !!dataSourceName &&
    isValidFormatDataSourceName &&
    isNotLongDataSourceName &&
    !isEmptyNameText;

  const isValidDataSourceGroupName =
    !!dataSourceSetName &&
    !isEmptyDataSourceGroupName &&
    isValidFormatDataSourceGroupName &&
    isNotLongDataSourceGroupName;

  const isValidDataSourceAndDataSourceGroupName =
    isValidDataSourceName && isValidDataSourceGroupName;

  const disabledIfNewGroup =
    newDataSourceSet &&
    (!isValidDataSourceAndDataSourceGroupName || !isValidDataSourceName);

  const disabledIfNotNewGroup =
    !newDataSourceSet && (!selectedDataSourceSetId || !isValidDataSourceName);

  const nextDisabled =
    loadingCreateDataSource || disabledIfNewGroup || disabledIfNotNewGroup;

  const validEntityType = mappingMode === 'new' && entityTypeObject;

  const validDataSet =
    mappingMode === 'existing' &&
    selectedDataSet &&
    selectedDataSet.id &&
    selectedDataSet.annotationId;

  let validMapping = false;
  if (mappingMode === 'new') {
    validMapping = validEntityType;
  }

  if (mappingMode === 'existing') {
    validMapping = validDataSet;
  }

  const addDisabled =
    !validMapping || !isValidEndpointName || isLoadingAllExistingAnnotations;

  const isProgressing = loadingCreateDataSource || isSavingEndpoint || isSaving;

  useEffect(() => {
    if (dataSourceId && prevDataSourceId !== dataSourceId) {
      createEndpoint({
        dataSourceId: dataSourceId,
        dataSets: [
          {
            author,
            store: true,
            name: endPointName,
            type: 'endpoint',
            configuration: {
              object: {
                endPointName,
                autoSubmit: false,
                entityType: entityTypeObject.entityType,
              },
              entityTypeConfiguration: {
                ...entityTypeObject,
              },
            },
          },
        ],
        existingAnnotationId: selectedDataSet
          ? selectedDataSet.annotationId
          : '',
        existingDataSetId: selectedDataSet ? selectedDataSet.id : '',
      });
    }
  }, [author, dataSourceId, createEndpoint, prevDataSourceId]);

  const onCreateNewEndpoint = async ({ name }) => {
    const res = await createDataSourceSet({ name, author });

    const newDataSourceSetId = res?.data?.inbound?.createDataSourceSet;

    await createDataSource({
      dataSourceSetId: newDataSourceSetId,
      dataSource: {
        author,
        type: 'endpoint',
        name: dataSourceName,
      },
    });
  };

  const handleAdd = async () => {
    if (
      (newDataSourceSet && dataSourceSetName) ||
      (!newDataSourceSet && isValidDataSourceAndDataSourceGroupName)
    ) {
      await onCreateNewEndpoint({ name: dataSourceSetName });
    }

    if (!newDataSourceSet && selectedDataSourceSetId) {
      await createDataSource({
        dataSourceSetId: selectedDataSourceSetId,
        dataSource: {
          author,
          type: 'endpoint',
          name: dataSourceName,
        },
      });
    }
  };

  const entityTypeValidationError = useMemo(() => {
    if (stepperState.active === 1 || mappingMode === 'existing') {
      return undefined;
    }

    if (!entityTypeObject?.new) {
      return undefined;
    }

    if (
      entityTypeObject?.new &&
      entityTypeObject?.entityType?.charAt(0) === '/'
    ) {
      return undefined;
    }

    return (
      <FormattedMessage id="module-entityType-entityType-must-start-from-slash" />
    );
  }, [entityTypeObject, stepperState, mappingMode]);

  const entityTypeIconValidationError = useMemo(() => {
    if (stepperState.active === 1 || mappingMode === 'existing') {
      return undefined;
    }
    if (!entityTypeObject?.new && entityTypeObject?.icon !== '') {
      return undefined;
    }

    if (entityTypeObject?.new && entityTypeObject?.icon !== '') {
      return undefined;
    }

    return (
      <FormattedMessage id="module-entityType-entityType-icon-must-be-selected" />
    );
  }, [entityTypeObject, stepperState, mappingMode]);

  return (
    <>
      <PanelHeader
        title={
          <FormattedMessage id="data-source-import-data-from-ingestion-point" />
        }
        onClose={onClose}
        CustomClose={
          <ClosePanelButton
            confirmTitle={
              <FormattedMessage id="data-source-confirm-lost-changes-title" />
            }
            confirmMessage={
              <FormattedMessage id="data-source-confirm-lost-changes-message" />
            }
            onConfirm={() => onClose()}
            onClick={onClose}
          />
        }
      />

      <Stepper
        activeStep={stepperState.active}
        completedSteps={stepperState.completed}
        steps={[
          { label: <FormattedMessage id="data-source-configure" /> },
          { label: <FormattedMessage id="data-source-data-add-endpoint" /> },
        ]}
      />

      <PanelContent>
        {(errorCreateDataSource ||
          errorCreateDataSourceSet ||
          errorCreatingEndpoint) && (
          <div style={{ paddingBottom: '24px' }}>
            <GqlErrorMessages
              error={
                errorCreateDataSource ||
                errorCreateDataSourceSet ||
                errorCreatingEndpoint
              }
            />
          </div>
        )}

        {stepperState.active === 2 && !isProgressing && (
          <EndpointCreationForm
            entityTypeValidationError={entityTypeValidationError}
            entityTypeIconValidationError={entityTypeIconValidationError}
            endPointName={endPointName}
            errorEndpointName={
              isNotLongEndpointName ? (
                (!isValidFOrmatEndpointName && (
                  <OnlyAlphaNumbericErrorMessage />
                )) ||
                (isEmptyEndPointName && <NotEmptyErrorMessage />)
              ) : (
                <NotLongErrorMessage />
              )
            }
            onChangeEndpointName={onChangeEndpointName}
            entityType={entityTypeObject}
            onChangeEntityType={onChangeEntityType}
            allExistingAnnotations={allExistingAnnotations}
            isLoadingAllExistingAnnotations={isLoadingAllExistingAnnotations}
            selectedDataSet={selectedDataSet}
            setDataSet={setDataSet}
            mappingMode={mappingMode}
            setMappingMode={setMappingMode}
          />
        )}

        {stepperState.active === 1 && (
          <FileUploadOptionForm
            dataSourceName={dataSourceName}
            newDataSourceSet={newDataSourceSet}
            dataSourceSetName={dataSourceSetName}
            dataSourceSetId={selectedDataSourceSetId}
            onChangeDataSourceSetId={setDataSourceSetId}
            onDataSourceNameChange={(_, v) => setDataSourceName(v)}
            onChangeNewOrExistingDataSourceSet={setNewDataSourceSet}
            onDataSourceSetNameChange={(_, v) => setDataSourceSetName(v)}
            errorDataSourceName={
              isNotLongDataSourceName ? (
                (!isValidFormatDataSourceName && (
                  <OnlyAlphaNumbericErrorMessage />
                )) ||
                (isEmptyNameText && <NotEmptyErrorMessage />)
              ) : (
                <NotLongErrorMessage />
              )
            }
            errorDataSetName={
              isNotLongDataSourceGroupName ? (
                (!isValidFormatDataSourceGroupName && (
                  <OnlyAlphaNumbericErrorMessage />
                )) ||
                (isEmptyDataSourceGroupName && <NotEmptyErrorMessage />)
              ) : (
                <NotLongErrorMessage />
              )
            }
          />
        )}

        {isProgressing && (
          <InfoLoader
            title="We are creating your ingestion point."
            text="Ingestion endpoint will prepare an empty data set. Wait until the creation is completed to proceed."
          />
        )}
      </PanelContent>

      <PanelFooter>
        <ButtonWithConfirm
          confirmTitle={
            <FormattedMessage id="data-source-confirm-lost-changes-title" />
          }
          confirmMessage={
            <FormattedMessage id="data-source-confirm-lost-changes-message" />
          }
          onConfirm={() => onClose()}
          data-test={'newDataSourceGroupCancelButton'}
          rounded
          onClick={onClose}
        >
          <FormattedMessage id="data-source-cancel" />
        </ButtonWithConfirm>

        {stepperState.active === 1 && (
          <PrimaryButton
            rounded
            style={{ marginLeft: '8px' }}
            data-test={'newDataSourceGroupNextButton'}
            disabled={nextDisabled}
            className="panel-main-action"
            onClick={() => {
              setStepperState({
                active: 2,
                completed: [1],
              });
            }}
          >
            <FormattedMessage id="data-source-next" />
          </PrimaryButton>
        )}

        {stepperState.active === 2 && (
          <PrimaryButton
            rounded
            style={{ marginLeft: '8px' }}
            data-test={'newDataSourceGroupAddIngestionPointButton'}
            startIcon={isProgressing ? <Loader color="#fff" size={14} /> : null}
            disabled={
              addDisabled ||
              isProgressing ||
              entityTypeValidationError ||
              entityTypeIconValidationError
            }
            onClick={handleAdd}
            className="panel-main-action"
          >
            <FormattedMessage id="data-source-add" />
          </PrimaryButton>
        )}
      </PanelFooter>
    </>
  );
};

export const AddIngestionPointWithoutDataSet = compose(
  withEntityTypeConfigurationReload,
)(AddIngestionPointWithoutDataSetComponent);
