import { CSSProperties, FC, ReactNode } from 'react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { ButtonLink } from '@cluedin/atoms';

import Badge, {
  BadgeProps,
} from '../../../modules/core/components/composites/Badge/Badge';

export type HintBadgeProps = Omit<BadgeProps, 'style'> & {
  badgeStyle?: CSSProperties;
  style?: CSSProperties;
  hintLabel?: string | ReactNode;
  hintActionLabel?: string | ReactNode;
  tooltipAdditionalContent?: ReactNode;
};

export const HintBadge: FC<HintBadgeProps> = ({
  type,
  small,
  label,
  style,
  onClick,
  hintLabel,
  badgeStyle,
  hintActionLabel,
  tooltipAdditionalContent,
}) => {
  const id = useId();

  return (
    <TooltipHost
      content={
        <>
          <div>{hintLabel}</div>
          {tooltipAdditionalContent && <div>{tooltipAdditionalContent}</div>}
          <div style={{ marginTop: '6px' }}>
            {onClick ? (
              <ButtonLink onClick={onClick}>{hintActionLabel}</ButtonLink>
            ) : (
              <div>{hintActionLabel}</div>
            )}
          </div>
        </>
      }
      id={id}
      calloutProps={{ gapSpace: 0 }}
    >
      <Badge
        type={type}
        small={small}
        label={label}
        onClick={onClick}
        hasHover={!!hintLabel}
        style={{ marginRight: '6px', ...badgeStyle, ...style }}
      />
    </TooltipHost>
  );
};
