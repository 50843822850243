import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Checkmark } from 'uxi/Icons';
import EntityLink from '../../../../entity/components/composites/EntityLink';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';
import { getEntityUrlFromStateAndEntity } from '../../../../wms/selectors';

const NewEntityNotificationStyle = {
  for: {
    display: 'inline-block',
  },
  entityLink: {
    display: 'inline-block',
    width: '230px',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'bottom',
  },
};

const NewEntityNotification = ({ index, entityNewUpdate, onCloseClick }) => {
  const title = `We have received ${entityNewUpdate.count} new information`;
  const message = (
    <div>
      <div style={NewEntityNotificationStyle.for}>For&nbsp;</div>
      <EntityLink
        style={NewEntityNotificationStyle.entityLink}
        entity={entityNewUpdate.entity}
      />
    </div>
  );

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={Checkmark}
    />
  );
};

const EnhancedNewEntityNotification = compose(
  lifecycle({
    componentDidMount() {
      const { entityNewUpdate, entityUrl } = this.props;
      const title = `We have received ${entityNewUpdate.count} new information`;
      const message = `For ${entityNewUpdate.entity.name}`;

      notify(title, { body: message }, entityUrl);
    },
  }),
)(NewEntityNotification);

const mapToStateProps = ({ wms }, entityNewUpdate) => {
  return getEntityUrlFromStateAndEntity(wms, entityNewUpdate.entity);
};

export default connect(mapToStateProps)(EnhancedNewEntityNotification);
