import React from 'react';
import styled from 'styled-components';
import { GqlErrorMessages } from '@cluedin/form';
import Text from 'uxi/Text';
import { ButtonLink } from '@cluedin/atoms';
import { useQueryDoc } from '../../../hooks/useQueryTopicDetail';
import PageLoader from '../../../../core/components/composites/PageLoader';
import WrapperMD from '../WrapperMD';
import PillarLink from './PillarLink';

const ButtonLinkElement = styled(ButtonLink)`
  &:hover {
    color: ${({ themePrimary }) => themePrimary};
  }
`;

const HelpTopic = ({ topic, onTopicClick, themePrimary, type }) => (
  <div style={{ margin: '2px 0' }}>
    <ButtonLinkElement
      onClick={() => {
        onTopicClick({
          topicId: topic.id,
          type,
        });
      }}
      text={topic.title}
      themePrimary={themePrimary}
    />
  </div>
);

const HelpTopicSectionFromTopic = styled.div`
  background: rgba(242, 242, 242, 0.7);
  border: 1px solid #ccc;
  padding: 15px;
`;

const MainHelpTopic = ({
  selectedTopic,
  themePrimary,
  onMainMenu,
  onTopicClick,
}) => {
  const [topic, loading, error] = useQueryDoc(
    selectedTopic.topicId,
    selectedTopic.type,
  );

  let related = [];
  let type;

  if (selectedTopic.type === 'pillar') {
    related = topic?.modules;
    type = 'module';
  }

  if (selectedTopic.type === 'module') {
    related = topic?.topics;
    type = 'topic';
  }

  if (selectedTopic.type === 'topic') {
    related = topic?.topicDetails;
    type = 'topicDetail';
  }

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  return (
    <>
      <div style={{ paddingBottom: '16px' }}>
        <ButtonLinkElement
          text="Home"
          onClick={onMainMenu}
          themePrimary={themePrimary}
        />
        {selectedTopic.type && selectedTopic.type === 'module' && (
          <>
            <span
              style={{
                paddingLeft: '6px',
                paddingRight: '6px',
              }}
            >
              /
            </span>
            <PillarLink
              topic={topic}
              themePrimary={themePrimary}
              onTopicClick={onTopicClick}
            />
          </>
        )}
      </div>
      <div style={{ paddingBottom: '16px' }}>
        <WrapperMD dangerouslySetInnerHTML={{ __html: topic?.content }} />
      </div>
      {related && related.length > 0 && (
        <div style={{ paddingBottom: '15px' }}>
          <Text type="Heading">Related Topics</Text>
        </div>
      )}
      {related && related.length > 0 && (
        <HelpTopicSectionFromTopic>
          {related.map((t, i) => (
            <HelpTopic
              key={i}
              onTopicClick={onTopicClick}
              topic={t}
              type={type}
            />
          ))}
        </HelpTopicSectionFromTopic>
      )}
    </>
  );
};

export default MainHelpTopic;
