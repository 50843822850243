import React from 'react';
import { TableRow, TableRowColumn, TableBody } from '@cluedin/list-pattern';
import Table from './TableExtended';
import Section from './Section';
import ValueWrapper from './ValueWrapper';

const PropertiesList = ({ jsonReport }) => {
  const { PropertyProviderBreakdown } = jsonReport;

  const groupedByProvider = {};

  (PropertyProviderBreakdown || []).forEach(($values) => {
    const indexOfFirstDot = $values[0] && $values[0].Key.indexOf('.');
    const providerName = $values[0] && $values[0].Key.slice(0, indexOfFirstDot);

    groupedByProvider[providerName] = $values;
  });

  return (
    <Section jsonReport={jsonReport}>
      <h2>Properties List</h2>
      {Object.keys(groupedByProvider).map((k, idx) => (
        <div key={idx}>
          <h4 style={{ borderBottom: '1px solid #cecece', marginTop: '24px' }}>
            {k}:
          </h4>
          <Table>
            <TableBody>
              {groupedByProvider[k].map(({ Key, Value }) => (
                <TableRow>
                  <TableRowColumn width="30%">{Key}:</TableRowColumn>
                  <TableRowColumn width="70%">
                    <ValueWrapper>{Value}</ValueWrapper>
                  </TableRowColumn>
                  ,
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
      <hr />
    </Section>
  );
};

export default PropertiesList;
