import styled from 'styled-components';

const SectionFullBleed = styled.section`
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 98px;
  }

  h2 {
    border-bottom: 1px solid #cecece;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cecece;
    margin: 1em 0;
    padding: 0;
  }

  @media print {
    min-height: 29.7cm;

    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    position: relative;
    &:not(:first-of-type):before {
      content: 'CluedIn';
    }

    &:not(:first-of-type):after {
      content: '${({ jsonReport }) =>
        jsonReport &&
        jsonReport.Name &&
        `${jsonReport.Name} Personal Data Report`}';
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

export default SectionFullBleed;
