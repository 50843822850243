import React from 'react';
import { RACI_LEVELS } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default {
  pillar: 'management',
  name: 'duplicate',
  displayName: <FormattedMessage id="module-duplicates-deduplicattion" />,
  path: '/duplicate',
  additionalActiveMatch: '/deduplication',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Duplicates',
        name: <FormattedMessage id="module-duplicates-deduplicattion" />,
        link: '/admin/management/duplicate',
        claims: {
          'management.duplicates': RACI_LEVELS.INFORMED,
          'management.deduplicationprojectmanagement': RACI_LEVELS.INFORMED,
        },
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      title: 'Dashboard',
      claims: {
        'management.duplicates': RACI_LEVELS.INFORMED,
        'management.deduplicationprojectmanagement': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"DuplicateDashboard" */
            './components/pages/DuplicateDashboard'
          ),
        ),
      ),
    },
    {
      path: '/potential-groups',
      exact: true,
      title: 'All potential duplicate queries',
      claims: {
        'management.duplicates': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"DuplicateQueriesGroups" */
            '../dataModeling/components/pages/DuplicateQueriesGroups'
          ),
        ),
      ),
    },
    {
      path: '/deduplication-projects',
      exact: true,
      title: 'All custom de-deduplication projects',
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"AllDeDuplicateProjects" */
            './components/pages/AllDeDuplicateProjects'
          ),
        ),
      ),
    },
    {
      path: '/deduplication',
      exact: true,
      title: 'dedup',
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"AllDeDuplicateProjects" */
            '../deduplication/components/pages/DeduplicationPage'
          ),
        ),
      ),
    },
    {
      path: '/deduplication-projects/detail/:id',
      title: 'De-duplication Project details',
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"DeDuplicateProjectDetailPage" */
            './components/pages/DeDuplicateProjectDetailPage'
          ),
        ),
      ),
    },
    {
      path: '/groupDetail/:id',
      title: 'Duplicate Entities',
      claims: {
        'management.duplicates': RACI_LEVELS.INFORMED,
      },
      component: makeLazy(() =>
        import(
          /* webpackChunkName:"DuplicateEntitiesListPage" */
          './components/pages/DuplicateEntitiesListPage'
        ),
      ),
    },
    {
      path: '/group/:id',
      title: 'Duplicate Details',
      claims: {
        'management.duplicates': RACI_LEVELS.INFORMED,
      },
      component: makeLazy(() =>
        import(
          /* webpackChunkName:"DuplicateGroupDetails" */
          '../dataModeling/components/pages/DuplicateGroupDetails'
        ),
      ),
    },
  ],
};
