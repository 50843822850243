import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EntitiesCompareContainer from '../containers/EntitiesCompareContainer';

class EntitiesComparePage extends Component {
  static contextTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
  };

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      match: { params: { targetId } = {} } = {},
      location: { search } = {},
    } = this.props;

    const urlParams = new URLSearchParams(search);
    const ids = urlParams.get('ids');

    return (
      <div className="__test_EntityComparePage">
        <EntitiesCompareContainer
          ids={ids}
          targetId={targetId}
          goBack={this.goBack.bind(this)}
        />
      </div>
    );
  }
}

export default EntitiesComparePage;
