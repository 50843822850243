import React, { useState } from 'react';
import styled from 'styled-components';
import { Arrowright, Arrowdown, Checkmark } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

const MappingPropertyForKeHeaderWrapper = styled.div`
  background: #f2f2f2;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;

  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  ${({ isOpened }) => (isOpened ? '' : 'border-bottom: 1px solid #ccc;')}
  &:hover {
    background: #fafafa;
  }
`;

const MappingPropertyForKeyContentrWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 16px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
`;

const AnnotationVocabGroup = ({ title, success, children, selectVocab }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [isOpened, setOpen] = useState(false);

  return (
    <div>
      <MappingPropertyForKeHeaderWrapper
        isOpened={isOpened}
        onClick={() => {
          setOpen(!isOpened);
        }}
      >
        <div style={{ paddingRight: '12px' }}>
          {isOpened ? <Arrowdown size={12} /> : <Arrowright size={12} />}
        </div>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <div>
            <strong>{title}</strong>
          </div>
          {success && (
            <Checkmark
              size={16}
              style={{ marginLeft: '6px' }}
              color={themePrimary}
            />
          )}
        </div>
        <div>
          {selectVocab && (
            <strong>
              <FormattedMessage
                id="module-annotation-totalSelectedVocab"
                values={{
                  total: selectVocab,
                }}
              />
            </strong>
          )}
        </div>
      </MappingPropertyForKeHeaderWrapper>
      <MappingPropertyForKeyContentrWrapper isOpen={isOpened}>
        {children}
      </MappingPropertyForKeyContentrWrapper>
    </div>
  );
};

export default AnnotationVocabGroup;
