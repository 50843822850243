import React, { useState } from 'react';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';
import { Checkmark } from 'uxi/Icons';
import { ClipboardIcon } from '@cluedin/svgs';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useTheme } from '@cluedin/theme';

import { copyToClipboard } from '../../../../../core/helpers/dom';
import {
  FormattedEntityCodeWrapper,
  FormattedEntityCodeInnerWrapper,
  FormattedEntityCodeActionWrapper,
} from './FormattedEntityCode.styles';

const FormattedEntityCode = ({ entityCode }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const copyToClipboardAndSuccess = () => {
    setShowCopiedMessage(true);
    copyToClipboard(entityCode);

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  return (
    <FormattedEntityCodeWrapper>
      <FormattedEntityCodeInnerWrapper>
        <ButtonLink onClick={copyToClipboardAndSuccess}>
          <TooltipHost
            id="entityRelations-copyCode"
            calloutProps={{ gapSpace: 12 }}
            content={entityCode}
          >
            <FormattedEntityCodeActionWrapper themePrimary={themePrimary}>
              {!showCopiedMessage ? (
                <ClipboardIcon
                  size={14}
                  style={{ marginRight: '4px' }}
                  color={themePrimary}
                />
              ) : (
                <Checkmark
                  size={14}
                  style={{ marginRight: '4px' }}
                  color={themePrimary}
                />
              )}
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListCopyCode" />
            </FormattedEntityCodeActionWrapper>
          </TooltipHost>
        </ButtonLink>
      </FormattedEntityCodeInnerWrapper>
    </FormattedEntityCodeWrapper>
  );
};

export default FormattedEntityCode;
