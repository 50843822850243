import React from 'react';
import { Externallink } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import VocabType from '../../../dataModeling/components/composites/VocabType';

const SchemaQuickDetailPopover = ({ schema }) => {
  return (
    <div
      style={{
        minWidth: '250px',
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: '2px',
      }}
    >
      <div>
        <div
          style={{
            background: '#f2f2f2',
            fontWeight: 'bold',
            borderBottom: '1px solid #ccc',
            padding: '6px',
          }}
        >
          <FormattedMessage id="module-schema-vocabDetail" />
        </div>
        <div
          style={{
            padding: '6px',
          }}
        >
          <div>
            <div>
              <FormattedMessage id="module-schema-vocabDisplayName" />
            </div>
            <div style={{ color: 'black', marginTop: '2px' }}>
              {schema.DisplayName}
            </div>
          </div>
          <div style={{ marginTop: '6px' }}>
            <div>
              <FormattedMessage id="module-schema-vocabType" />
            </div>
            <div style={{ marginTop: '2px', marginLeft: '-4px' }}>
              <VocabType value={schema.IsCore} />
            </div>
          </div>
          <div style={{ marginTop: '6px' }}>
            <div>
              <FormattedMessage id="module-schema-type" />
            </div>
            <div style={{ color: 'black', marginTop: '2px' }}>
              {schema.DataType}
            </div>
          </div>
          <div style={{ marginTop: '6px' }}>
            <div>
              <FormattedMessage id="module-schema-description" />
            </div>
            <div style={{ color: 'black', marginTop: '2px' }}>
              {schema.Description || '-'}
            </div>
          </div>
          <div style={{ marginTop: '6px' }}>
            <div>
              <FormattedMessage id="module-schema-actions" />
            </div>
            <div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
                    schema.Key,
                  )}`}
                >
                  <span style={{ paddingRight: '2px' }}>
                    <FormattedMessage id="module-schema-viewMoreDetails" />
                  </span>
                  <Externallink size={10} />
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/admin/management/catalog/vocab-key/values?key=${encodeURIComponent(
                    schema.Key,
                  )}`}
                >
                  <span style={{ paddingRight: '2px' }}>
                    <FormattedMessage id="module-schema-viewAllValues" />
                  </span>
                  <Externallink size={10} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchemaQuickDetailPopover;
