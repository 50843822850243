import { CSSProperties } from 'react';
import styled from 'styled-components';
import { FileRejection } from 'react-dropzone';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { FileRejectedItem } from './FileRejectedItem';
import { FileListHeader } from './FileListHeader';

const List = styled.ul`
  border: 1px solid #ccc;
`;

type FileRejectionProps = {
  unSupportedFileDataSources: FileRejection[];
  style?: CSSProperties;
  onClearFiles: () => void;
  handleRemoveFile: (index: number) => void;
};

const noBorder = {
  border: 0,
};

export const FileRejectedList = ({
  unSupportedFileDataSources = [],
  handleRemoveFile,
  style = {},
  onClearFiles,
}: FileRejectionProps) => {
  const theme = useTheme();

  return (
    <div style={style}>
      <FileListHeader
        title={<FormattedMessage id="module-fileUpload-rejectedFiles" />}
        color={theme.semanticColors.errorText}
        onClear={onClearFiles}
      />
      <List>
        {unSupportedFileDataSources?.map(
          ({ file, errors }: FileRejection, index: number) => (
            <FileRejectedItem
              file={file}
              errors={errors}
              handleRemoveFile={() => {
                handleRemoveFile(index);
              }}
              style={index === 0 ? noBorder : {}}
            />
          ),
        )}
      </List>
    </div>
  );
};
