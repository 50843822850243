import React from 'react';
import ConfigurationFiltersGhost from '../filters/ConfigurationFiltersGhost';
import ConfigurationListGhost from './ConfigurationListGhost';

const ConfigurationListWithFiltersGhost = () => (
  <div>
    <ConfigurationFiltersGhost />
    <ConfigurationListGhost />
  </div>
);

export default ConfigurationListWithFiltersGhost;
