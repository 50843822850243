import React from 'react';
import { Button } from '@cluedin/form';
import styled from 'styled-components';
import DefaultWrapper from './DefaultWrapper';

const ButtonWrapper = styled.div`
  margin: 16px;
`;

const EntitiesWithPaging =
  (Comp, options = {}) =>
  (props) => {
    const {
      items,
      isFetchingMore,
      onLoadMore,
      pageNumber = 0,
      take = 20,
    } = props;

    const { message = 'Load More', style = {} } = options;

    const showMore =
      items &&
      items.length > 0 &&
      (items.length / ((pageNumber || 0) + 1)) % take === 0;

    const Wrapper = options.Wrapper ? options.Wrapper : DefaultWrapper;

    return (
      <Wrapper>
        <Comp {...props} />
        {showMore && (
          <ButtonWrapper style={style}>
            <Button
              loading={isFetchingMore}
              isFullWidth
              onClick={() => {
                onLoadMore(pageNumber + 1);
              }}
              text={message}
            />
          </ButtonWrapper>
        )}
      </Wrapper>
    );
  };

export default EntitiesWithPaging;
