import { useRef, useState, useMemo, useReducer, useLayoutEffect } from 'react';

import { useSafeDispatch } from './useSafeDispatch';

const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

export const useMeasure = () => {
  const [element, ref] = useState(null);

  const initialStateRef = useRef(defaultState);

  const [rect, dispatch] = useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current,
  );

  const setRect = useSafeDispatch(dispatch);

  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries?.[0]?.contentRect;

          setRect({
            x,
            y,
            top,
            left,
            right,
            width,
            bottom,
            height,
          });
        }
      }),
    [],
  );

  useLayoutEffect(() => {
    if (!element) return;

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, rect];
};
