import { handleActions } from 'redux-actions';
import {
  quickSearchResultActions,
  aggregationSearchActions,
  shouldFetchSearchSuggestionsSagaActions,
  shouldShowAdvancedSearch,
  shouldHideAdvancedSearch,
  shouldUpdateEntityTypePoolIdentifier,
  shouldStoreUpdatedMainSearchQuery,
  shouldStoreMainSearchUpdatedFilters,
  shouldStoreSortType,
  shouldUpdateQueryCurrentInputValue,
  shouldShowPropertyFilters,
  shouldHidePropertyFilters,
} from './actions';

export const initialState = {
  quickSearch: {},
  aggregationResult: {},
  search: {
    main: {
      isFetching: false,
      isFetchingMore: false,
      invalid: false,
      entityTypePoolIdentifier: '',
      filters: [],
      sortType: '',
    },
  },
  searchSuggestions: {
    isFetching: false,
    invalid: false,
    suggestions: null,
  },
  showAdvancedSearch: false,
  currentInputValue: null,
  propertyFilterSearch: false,
};

export default handleActions(
  {
    [shouldShowPropertyFilters]: (state) => ({
      ...state,
      propertyFilterSearch: true,
    }),
    [shouldHidePropertyFilters]: (state) => ({
      ...state,
      propertyFilterSearch: false,
    }),
    [shouldShowAdvancedSearch]: (state) => ({
      ...state,
      showAdvancedSearch: true,
    }),
    [shouldHideAdvancedSearch]: (state) => ({
      ...state,
      showAdvancedSearch: false,
    }),
    [aggregationSearchActions.request]: (state, { payload }) => ({
      ...state,
      aggregationResult: {
        ...state.aggregationResult,
        [payload]: {
          isFetching: true,
          invalid: false,
          result: null,
        },
      },
    }),
    [aggregationSearchActions.receive]: (
      state,
      { payload: { param, result } },
    ) => ({
      ...state,
      aggregationResult: {
        ...state.aggregationResult,
        [param]: {
          isFetching: false,
          invalid: false,
          result,
        },
      },
    }),
    [aggregationSearchActions.invalid]: (state, { payload }) => ({
      ...state,
      aggregationResult: {
        ...state.aggregationResult,
        [payload]: {
          isFetching: false,
          invalid: true,
          result: null,
        },
      },
    }),
    [quickSearchResultActions.request]: (state, { payload: { query } }) => ({
      ...state,
      quickSearch: {
        [query]: {
          isFetching: true,
          invalid: false,
          result: [],
        },
      },
    }),
    [quickSearchResultActions.receive]: (
      state,
      {
        payload: {
          result,
          param: { query },
        },
      },
    ) => ({
      ...state,
      quickSearch: {
        [query]: {
          isFetching: false,
          invalid: false,
          result,
        },
      },
    }),
    [quickSearchResultActions.invalid]: (state, { payload: { query } }) => ({
      ...state,
      quickSearch: {
        [query]: {
          isFetching: false,
          invalid: true,
          result: [],
        },
      },
    }),
    [shouldFetchSearchSuggestionsSagaActions.request]: (state) => ({
      ...state,
      searchSuggestions: {
        ...state.searchSuggestions,
        isFetching: true,
        invalid: false,
        suggestions: null,
      },
    }),
    [shouldFetchSearchSuggestionsSagaActions.receive]: (
      state,
      { payload: { result } },
    ) => ({
      ...state,
      searchSuggestions: {
        ...state.searchSuggestions,
        isFetching: false,
        invalid: false,
        suggestions: result,
      },
    }),
    [shouldFetchSearchSuggestionsSagaActions.invalid]: (state) => ({
      ...state,
      searchSuggestions: {
        ...state.searchSuggestions,
        isFetching: false,
        invalid: true,
        suggestions: null,
      },
    }),
    // eslint-disable-next-line max-len
    [shouldUpdateEntityTypePoolIdentifier]: (
      state,
      { payload: { searchId = 'main', entityTypePoolIdentifier } },
    ) => ({
      ...state,
      search: {
        ...state.search,
        [searchId]: {
          ...state.search[searchId],
          entityTypePoolIdentifier: entityTypePoolIdentifier || '',
        },
      },
    }),
    [shouldStoreUpdatedMainSearchQuery]: (state, { payload: { query } }) => ({
      ...state,
      search: {
        ...state.search,
        main: {
          ...state.search.main,
          query,
        },
      },
    }),
    [shouldStoreMainSearchUpdatedFilters]: (
      state,
      { payload: { filters } },
    ) => ({
      ...state,
      search: {
        ...state.search,
        main: {
          ...state.search.main,
          filters,
        },
      },
    }),
    [shouldStoreSortType]: (state, { payload: { sortType } }) => ({
      ...state,
      search: {
        ...state.search,
        main: {
          ...state.search.main,
          sortType,
        },
      },
    }),
    [shouldUpdateQueryCurrentInputValue]: (
      state,
      { payload: { currentInputValue } },
    ) => ({
      ...state,
      currentInputValue,
    }),
  },
  initialState,
);
