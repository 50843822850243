import React from 'react';

const ImagePreviewStyle = {
  root: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
  },
};

const ImagePreview = ({ previewUrl, alt }) => (
  <div style={{ textAlign: 'center' }}>
    <img style={ImagePreviewStyle.root} src={previewUrl} alt={alt} />
  </div>
);

export default ImagePreview;
