import React, { useState, useRef } from 'react';
import moment from 'moment';
import Graph from 'react-graph-vis';
import { useTheme } from '@cluedin/theme';
import { GqlErrorMessages } from '@cluedin/form';
import SplitIcon from '@cluedin/svgs/icons/split-icon-noshadow.svg';
import SplitIconDisabled from '@cluedin/svgs/icons/split-icon-disabled.svg';
import KeyIcon from '@cluedin/svgs/icons/key-icon-topology.svg';
import DataSourceDatabaseIcon from '@cluedin/svgs/icons/Data-source-database-topology.svg';
import DeduplicationIcon from '@cluedin/svgs/icons/deduplication-topology.svg';
import DeduplicationTopologyV2Icon from '@cluedin/svgs/icons/deduplication-topology-v2.svg';
import FuzzyMatchingIcon from '@cluedin/svgs/icons/fuzzy-matching-topology-icon.svg';
import MergeIco from '@cluedin/svgs/icons/Merge-ico.svg';
import DataCleaningTopologyIco from '@cluedin/svgs/icons/Data-cleaning-topology-ico.svg';
import ManuallyAddedIcon from '@cluedin/svgs/icons/Manually-added-icon.svg';
import { isArray } from 'lodash';
import { injectIntl } from '@cluedin/locale';

import { CardTypes } from '../Cards/CardTypes';
import GraphContainer from '../GraphContainer';
import { useZoomControl } from '../hooks/useZoomControl';
import {
  resizeNetwork,
  isSupportedCardType,
  isSupportedCardTypeForLabel,
} from '../utils';
import { graphConfig } from '../config';
import { nodeTypesList } from './nodeTypesList';
import PageLoader from '../../../modules/core/components/composites/PageLoader';

const trim = (text, limit = 21) =>
  text.length > limit ? `${text.substring(0, limit)}...` : text;

const EntityTopology = ({
  nodes,
  edges,
  onNodeClick,
  onFilter,
  filter,
  loading,
  error,
  labels = {},
  intl,
}) => {
  const [graphNetworkInstance, setGraphNetworkInstance] = useState(null);
  const [previousNodeId, setPreviousNodeId] = useState(null);

  const { updateDateLabel = 'Updated' } = labels;

  const legendCanvas = useRef(null);
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const customShapePadding = 12;
  const arrowColor = '#999';
  const edgesColorWithTheme = themePrimary;
  const zoomControl = useZoomControl();

  const cardGenericProps = {
    width: 202,
    height: 52,
    color: '#efd88f',
    backgroundColor: '#ffffff',
    borderColor: '#d7d7d8',
    labelFontStyle: '10px Segoe UI, sans-serif',
    labelFontColor: 'black',
    subTextFontColor: '#9b9b9c',
    subTextFontStyle: '8px Segoe UI, sans-serif',
  };

  const themeCardProps = {
    ...cardGenericProps,
    borderHoverColor: themePrimary,
  };

  if (!nodes || nodes?.length <= 0) {
    return <PageLoader />;
  }

  let nodeTypes = nodeTypesList?.filter((n) =>
    nodes.find(
      (node) => node?.dataType === n?.name || node?.source?.kind === n?.name,
    ),
  );

  const legendHeight = 64 * nodeTypes?.length + 5;

  const iconsToUse = {
    KeyIcon,
    DataSourceDatabaseIcon,
    DeduplicationIcon,
    DeduplicationTopologyV2Icon,
    FuzzyMatchingIcon,
    MergeIco,
    DataCleaningTopologyIco,
    ManuallyAddedIcon,
  };

  const nodeCards = (filter = []) =>
    nodes?.map((n) => {
      const { id, label, subLabel, actions, dataType } = n;

      return {
        id,
        label,
        title: 'title',
        group: dataType,
        borderWidth: 0,
        borderWidthSelected: 0,
        labelHighlightBold: false,
        shape: 'custom',
        ctxRenderer: ({
          ctx,
          id,
          x,
          y,
          state: { selected, hover },
          style,
          label,
        }) => {
          return {
            drawNode() {
              CardTypes({
                ...themeCardProps,
                label:
                  isSupportedCardTypeForLabel(n?.source?.kind, intl) ??
                  (n?.label
                    ? trim(n?.label)
                    : nodeTypes?.find((n) => n?.name === dataType)?.label),
                ctx,
                x,
                y,
                opacity:
                  filter?.includes(`${id}`) || filter?.length === 0 ? 1 : 0.4,
                subText:
                  dataType?.toLowerCase() === 'datapart' &&
                  n?.source?.kind?.toLowerCase() === 'clean'
                    ? trim(n?.source?.name)
                    : dataType?.toLowerCase() === 'deduplicationprojectmerge' ||
                      dataType?.toLowerCase() === 'entitycode'
                    ? trim(n?.subLabel)
                    : n?.provider?.providerName?.toLowerCase() ===
                      'manual entry source'
                    ? n?.source?.name
                    : `${updateDateLabel} ${moment(n?.subLabel)?.fromNow()}`,
                altText: n?.dataPartId,
                cardType:
                  (n?.source && isSupportedCardType(n?.source?.kind)) ??
                  dataType,
                providerIcon: n?.provider?.icon,
                actionIcon: actions?.length
                  ? actions.find((a) => a?.availability === 'Available')
                    ? SplitIcon
                    : SplitIconDisabled
                  : null,
                selected,
                hover,
                iconsToUse,
              });
            },
            nodeDimensions: {
              width: themeCardProps.width + customShapePadding,
              height: themeCardProps.height + customShapePadding,
            },
          };
        },
      };
    });

  const legendNodes = (network) => {
    const canvas = legendCanvas?.current;
    if (!canvas?.hasChildNodes()) {
      let ctx;

      nodeTypes?.map((nodeType, index) => {
        const nodeCanvas = document.createElement('canvas');
        ctx = nodeCanvas?.getContext('2d');
        nodeCanvas.width = 300;
        nodeCanvas.height = 60;

        nodeCanvas.addEventListener('mouseenter', () => {
          document.documentElement.style.cursor = 'pointer';
          const typeName = nodeType?.name;
          const selectNodeIds = nodes
            ?.filter((n) =>
              n?.source
                ? n?.source?.kind === typeName
                : n?.dataType === typeName,
            )
            ?.map((n) => n?.id);
          network?.selectNodes(selectNodeIds, true);
        });

        nodeCanvas.addEventListener('mouseleave', () => {
          document.documentElement.style.cursor = 'default';
          network.unselectAll();
        });

        ctx = CardTypes({
          ...cardGenericProps,
          ...{
            labelFontStyle: '14px Segoe UI, sans-serif',
            width: 300,
          },
          label: nodeType?.label,
          altText: nodeType?.name === 'EntityCode' ? '' : 'Record ID',
          altTextOffset: 40,
          altTextFontStyle: '11px Segoe UI, sans-serif',
          ctx,
          x: 150,
          y: 28,
          cardType: nodeType?.name,
          iconsToUse,
        });
        canvas?.appendChild(nodeCanvas);
      });

      return ctx;
    }
  };

  const edgesConfig = edges?.map((e) => {
    const direction = { from: e.from, to: e.to };

    return {
      ...direction,
      arrowStrikethrough: false,
      label: e?.label,
      color: {
        color: arrowColor,
        hover: themeCardProps?.borderHoverColor,
        inherit: 'true',
      },
      hoverWidth: 1,
      font: {
        color: arrowColor,
        size: 12,
        align: 'vertical',
      },
    };
  });

  const options = {
    physics: {
      enabled: true,
      hierarchicalRepulsion: {
        nodeDistance: 220,
        avoidOverlap: 0.36,
        centralGravity: 0.1,
      },
      minVelocity: 0.75,
      maxVelocity: 10,
      timestep: 0.28,
      adaptiveTimestep: true,
      solver: 'hierarchicalRepulsion',
      stabilization: false,
    },
    autoResize: true,
    layout: {
      randomSeed: 1,
      improvedLayout: true,
      hierarchical: {
        enabled: false,
        blockShifting: true,
        direction: 'LR',
        nodeSpacing: 150,
        treeSpacing: 150,
        parentCentralization: true,
        levelSeparation: 200,
        sortMethod: 'directed',
        shakeTowards: 'roots',
      },
    },
    interaction: {
      hover: true,
    },
    edges: {
      color: edgesColorWithTheme,
    },
    width: `${Math.round(document.body.clientWidth * 0.92) || 600}px`,
    height: `${Math.round(document.body.clientHeight * 0.92) || 600}px`,
  };

  const getNodeInfo = (id) => nodes?.filter((e) => e.id === id)[0];

  const events = {
    click: (networkEvent) => {
      const { nodes } = networkEvent;

      const currentNodeId = nodes[0];
      const connectedNodes =
        graphNetworkInstance?.getConnectedNodes(currentNodeId) || [];
      const highlightedNodes = currentNodeId
        ? [...connectedNodes, `${currentNodeId}`]
        : [];

      const nodeInfo = getNodeInfo(currentNodeId);
      nodeInfo && setPreviousNodeId(currentNodeId);
      nodeInfo && onNodeClick(nodeInfo);

      if (isArray(nodes) && nodes?.length > 0) {
        if (previousNodeId !== currentNodeId) {
          graphNetworkInstance?.setData({
            nodes: nodeCards(highlightedNodes),
            edges: edgesConfig,
          });
        }
      }
    },
    hoverNode: (networkEvent) => {
      document.documentElement.style.cursor = 'pointer';
    },
    blurNode: (networkEvent) => {
      document.documentElement.style.cursor = 'default';
    },
  };

  return error?.length ? (
    <GqlErrorMessages error={error} />
  ) : (
    <GraphContainer
      graph={graphNetworkInstance}
      loading={loading}
      legendCanvas={legendCanvas}
      onFilter={onFilter}
      filter={filter}
      legendPosition={{
        right: 100,
        top: 20,
        height: legendHeight,
      }}
      resetSelectedNodes={() => {
        graphNetworkInstance?.setData({
          nodes: nodeCards([]),
          edges: edgesConfig,
        });
      }}
      labels={labels}
    >
      <Graph
        graph={{
          nodes: nodeCards(),
          edges: edgesConfig,
        }}
        options={options}
        events={events}
        getNetwork={(network) => {
          setGraphNetworkInstance(network);

          network.on('zoom', (zoomLevel) => {
            zoomControl(zoomLevel?.scale, network);
          });

          window.setTimeout(() => {
            resizeNetwork(network);
          }, 200);

          window.setTimeout(() => {
            network?.fit(graphConfig?.fit);
          }, 600);

          network.on('afterDrawing', (ctx) => {
            window.setTimeout(() => {
              legendNodes(network);
            }, 600);
          });
        }}
      />
    </GraphContainer>
  );
};

export default injectIntl(EntityTopology);
