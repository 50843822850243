import { createAction } from 'redux-actions';
import { createAsyncAction } from '../core/helpers/actionCreator';
import { defaultAsyncCallWithActionsAndParams } from '../core/helpers/action';

import { getShortPath, getShortPathPeople } from './data';

export const shortestPathActions = createAsyncAction(
  'ENTITY_RELATION_SHORT_PATH',
);
export const shortestPathPeopleActions = createAsyncAction(
  'ENTITY_RELATION_PEOPLE_SHORT_PATH',
);

export const cleanUpShortestPath = createAction(
  'ENTITY_RELATION_SHORTEST_PATH_CLEAN_UP',
);
export const cleanUpShortestPathPeople = createAction(
  'ENTITY_RELATION_CLEAN_UP_SHORTEST_PATH_PEOPLE',
);

export const shouldFetchShortPath = defaultAsyncCallWithActionsAndParams(
  getShortPath,
  shortestPathActions,
);

export const shouldFetchShortPathPeople = defaultAsyncCallWithActionsAndParams(
  getShortPathPeople,
  shortestPathPeopleActions,
);
