import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { WithErrorAndLoading } from '../WithErrorAndLoading';
import NotFoundResult from '../../composites/lists/NotFoundResult';
import MainWidgetForPage from '../../composites/MainWidgetForPage';

export const ListPage = ({
  title,
  moduleName,
  fetch,
  entityName,
  List,
  noDataText,
}) => {
  const ListPageContainer = ({ data = [] }) => {
    const hasNoData = !data || data.length === 0;

    return (
      <MainWidgetForPage title={title}>
        {hasNoData && <NotFoundResult text={noDataText} />}
        {!hasNoData && <List data={data} />}
      </MainWidgetForPage>
    );
  };

  const EnhancedAllRetentionContainer = compose(
    lifecycle({
      componentDidMount() {
        const { fetchAll } = this.props;
        fetchAll();
      },
    }),
  )(WithErrorAndLoading(ListPageContainer));

  const mapStateToProps = (state) => ({
    data: state[moduleName][`ALL_${entityName}`].data,
    isFetching:
      state[moduleName][`ALL_${entityName}`].isFetching ||
      !state[moduleName][`ALL_${entityName}`].data,
    invalid: state[moduleName][`ALL_${entityName}`].invalid,
  });

  const mapDispatchToProps = (dispatch) => ({
    fetchAll() {
      dispatch(fetch());
    },
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EnhancedAllRetentionContainer);
};

export default {
  ListPage,
};
