import React, { useEffect, useMemo, useState } from 'react';
import { withWorkflow } from '@cluedin/form';
import CloneHierarchyForm from '../containers/CloneHierarchyForm';

export default (Comp, options = {}) =>
  (props) => {
    const EnhancedhWorkflow = withWorkflow(
      ({ openWorkflow }) =>
        useMemo(
          () => <Comp {...props} openCloneWorkflow={openWorkflow} />,
          [props],
        ),
      (panelProps) => <CloneHierarchyForm {...props} {...panelProps} />,
      {
        withCustomHeader: true,
        ...options,
      },
    );
    return <EnhancedhWorkflow />;
  };
