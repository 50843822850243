import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_TYPE_BY_TYPE = gql`
  query getEntityTypeByType($type: String!, $page: Int) {
    management {
      getEntityTypeByType(type: $type, page: $page) {
        id
        icon
        displayName
        type
        route
        active
        layoutConfiguration
        pageTemplateId
        pageTemplateName
      }
    }
  }
`;

export const useGetEntityTypeByType = (type, page) => {
  const { data, loading, error } = useQuery(GET_ENTITY_TYPE_BY_TYPE, {
    variables: { type, page },
  });

  const result = get(data, 'management.getEntityTypeByType');

  return [result, loading, error];
};
