import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { IconButton } from '@cluedin/form';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Hamburger } from 'uxi/Icons';

const FieldDrag = styled.div`
  padding-right: 12px;
  cursor: move;
`;

const FieldWrapper = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  &:hover {
    background: #f2f2f2;
  }
`;

const ColumnCardItemWrapper = ({ children, style, onDeleteColumn, column }) => {
  return (
    <FieldWrapper style={style}>
      <FieldDrag>
        <Hamburger size={12} />
      </FieldDrag>
      <div style={{ flex: 1 }}>{children}</div>

      {!column.fixed && (
        <IconButton
          onClick={() => onDeleteColumn(column)}
          iconProps={{ iconName: 'Delete' }}
        />
      )}

      {column.fixed && (
        <TooltipHost
          content={
            <FormattedMessage id="module-coreEntityList-columnCannotRemoved" />
          }
          id={'can-not-delete-column-ColumnCardItemWrapper'}
          calloutProps={{ gapSpace: 0 }}
        >
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            style={{ opacity: '0.4' }}
          />
        </TooltipHost>
      )}
    </FieldWrapper>
  );
};

export default ColumnCardItemWrapper;
