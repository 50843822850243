import React from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import {
  CluedinConnectorIcon,
  MergeIco,
  DataCleaningIco,
  ManuallyAddedIcon,
} from '@cluedin/svgs';
import { ReactComponent as DeduplicationIcon } from '@cluedin/svgs/icons/deduplication.svg';
import { GqlErrorMessages } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';

import ExplainLogBestGuessSourceDataPartContainer from './ExplainLogBestGuessSourceDataPartContainer/ExplainLogBestGuessSourceDataPartContainer';
import {
  ExplainLogBestGuessSourceImageWrapper,
  ExplainLogBestGuessSourceDetails,
  ExplainLogBestGuessSourceIconWrapper,
} from './ExplainLogBestGuessSource.styles';
import { useExplainLogBestGuessSource } from '../../../../hooks/useExplainLogBestGuessSource';
import { urlBuilder } from '../../../../../../config';
import { removeFirstCharacter } from '../../../../../core/helpers/string';

const ExplainLogBestGuessSourceIcon = ({
  bestGuessSource,
  onClick,
  dataSource,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const themeWhite = theme?.palette?.white;

  const iconUrl =
    bestGuessSource &&
    bestGuessSource?.image &&
    bestGuessSource?.image[0] === '/'
      ? removeFirstCharacter(bestGuessSource?.image)
      : bestGuessSource?.image;

  let iconToShow = (
    <CluedinConnectorIcon
      style={{
        height: '40px',
        width: '40px',
        marginRight: '10px',
      }}
      onClick={onClick}
    />
  );
  if (bestGuessSource?.icon) {
    const Icon = getIcon(bestGuessSource?.icon);
    iconToShow = <Icon />;
  }
  if (bestGuessSource?.image) {
    iconToShow = (
      <ExplainLogBestGuessSourceImageWrapper isImage onClick={onClick}>
        <img
          alt={bestGuessSource?.displayName}
          style={{
            maxWidth: '40px',
            minWidth: '40px',
          }}
          src={urlBuilder?.api(iconUrl)}
        />
      </ExplainLogBestGuessSourceImageWrapper>
    );
  }
  if (dataSource && dataSource?.kind?.toLowerCase() === 'manualmerge') {
    iconToShow = (
      <ExplainLogBestGuessSourceIconWrapper
        dataSourceKind={dataSource?.kind?.toLowerCase()}
        themePrimary={themePrimary}
        themeWhite={themeWhite}
      >
        <MergeIco />
      </ExplainLogBestGuessSourceIconWrapper>
    );
  }
  if (
    dataSource &&
    dataSource?.kind?.toLowerCase() === 'deduplicationprojectmerge'
  ) {
    iconToShow = (
      <ExplainLogBestGuessSourceIconWrapper
        dataSourceKind={dataSource?.kind?.toLowerCase()}
        themePrimary={themePrimary}
        themeWhite={themeWhite}
      >
        <DeduplicationIcon />
      </ExplainLogBestGuessSourceIconWrapper>
    );
  }
  if (
    (dataSource && dataSource?.kind?.toLowerCase() === 'clean') ||
    (dataSource && dataSource?.kind?.toLowerCase() === 'legacyclean')
  ) {
    iconToShow = (
      <ExplainLogBestGuessSourceIconWrapper
        dataSourceKind={dataSource?.kind?.toLowerCase()}
        themePrimary={themePrimary}
        themeWhite={themeWhite}
      >
        <DataCleaningIco />
      </ExplainLogBestGuessSourceIconWrapper>
    );
  }
  if (dataSource && dataSource?.kind?.toLowerCase() === 'userinput') {
    iconToShow = (
      <ExplainLogBestGuessSourceIconWrapper
        dataSourceKind={dataSource?.kind?.toLowerCase()}
        themePrimary={themePrimary}
        themeWhite={themeWhite}
      >
        <ManuallyAddedIcon />
      </ExplainLogBestGuessSourceIconWrapper>
    );
  }

  return iconToShow;
};

const ExplainLogBestGuessSource = ({
  dataPartId,
  isGoldenRecord,
  sourceId,
  entityId,
  onClick,
  dataSource,
}) => {
  const [data, loading, error] = useExplainLogBestGuessSource(sourceId);

  if (loading) {
    return (
      <div>
        <FormattedMessage id="module-explainLog-sourceLoading" />
        ...&nbsp;
        {sourceId}
      </div>
    );
  }

  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  let displayNameToShow = <FormattedMessage id="module-explainLog-record" />;
  if (data?.displayName) {
    displayNameToShow = data?.displayName;
  }
  if (dataSource?.kind?.toLowerCase() === 'manualmerge') {
    displayNameToShow = <FormattedMessage id="module-explainLog-manualMerge" />;
  }
  if (dataSource?.kind?.toLowerCase() === 'deduplicationprojectmerge') {
    displayNameToShow = (
      <FormattedMessage id="module-explainLog-deduplicationProjectMerge" />
    );
  }
  if (
    dataSource?.kind?.toLowerCase() === 'clean' ||
    dataSource?.kind?.toLowerCase() === 'legacyclean'
  ) {
    displayNameToShow = <FormattedMessage id="module-explainLog-clean" />;
  }
  if (dataSource?.kind?.toLowerCase() === 'userinput') {
    displayNameToShow = (
      <FormattedMessage id="module-explainLog-manuallyAdded" />
    );
  }

  return (
    <ExplainLogBestGuessSourceDetails>
      {isGoldenRecord ? null : (
        <ExplainLogBestGuessSourceIcon
          bestGuessSource={data}
          onClick={onClick}
          dataSource={dataSource}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <strong onClick={onClick}>{displayNameToShow}</strong>
        {dataPartId && entityId && (
          <ExplainLogBestGuessSourceDataPartContainer
            dataPartId={dataPartId}
            entityId={entityId}
            dataSource={dataSource}
            bestGuessSourceData={data}
          />
        )}
      </div>
    </ExplainLogBestGuessSourceDetails>
  );
};

export default ExplainLogBestGuessSource;

ExplainLogBestGuessSource.propTypes = {
  dataPartId: PropTypes.string,
  isGoldenRecord: PropTypes.bool,
  sourceId: PropTypes.string,
  entityId: PropTypes.string,
  onClick: PropTypes.func,
};

ExplainLogBestGuessSource.defaultProps = {
  dataPartId: null,
  isGoldenRecord: false,
  sourceId: null,
  entityId: null,
  onClick: () => {},
};
