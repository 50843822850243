import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import EntityPropertySection from './EntityPropertySection';
import LabelValue from '../../../core/components/composites/generic/LabelValue';

const EntityChangeRaw = ({ change }) => {
  let newValueContent;
  let oldValueContent;

  if (change.NewValue) {
    newValueContent = (
      <LabelValue
        label={<FormattedMessage id="newValue" />}
        value={change.NewValue}
      />
    );
  }

  if (change.OldValue) {
    oldValueContent = (
      <LabelValue
        label={<FormattedMessage id="previousValue" />}
        value={change.OldValue}
      />
    );
  }

  if (oldValueContent || newValueContent) {
    return (
      <EntityPropertySection title={`${change.Key} (raw)`}>
        {newValueContent}
        {oldValueContent}
      </EntityPropertySection>
    );
  }

  let contentForNoValue;

  if (change.Type.toLowerCase() === 'changed') {
    contentForNoValue = <FormattedMessage id="PropertyModified" />;
  }

  if (change.Type.toLowerCase() === 'added') {
    contentForNoValue = <FormattedMessage id="PropertyWasAdded" />;
  }

  return (
    <EntityPropertySection title={`${change.Key} (raw)`}>
      {contentForNoValue}
    </EntityPropertySection>
  );
};

export default EntityChangeRaw;
