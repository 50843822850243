import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Select } from '@cluedin/form';
import PropTypes from 'prop-types';

export const filterPerInstallationType = (integrations, type) =>
  integrations.filter((integration) => {
    if (!type || type === 'all') {
      return true;
    }
    return integration.Type && integration.Type === type;
  });

const options = [
  {
    value: 'all',
    label: <FormattedMessage id="module-integration-allInstallationTypes" />,
  },
  {
    value: 'cloud',
    label: <FormattedMessage id="module-integration-sassType" />,
  },
  {
    value: 'premise',
    label: <FormattedMessage id="module-integration-premise" />,
  },
  {
    value: 'external',
    label: <FormattedMessage id="module-integration-dataEnrichment" />,
  },
];

const IntegrationInstalattionTypeFilters = ({ onChange }) => (
  <Select
    defaultValue={options[0]}
    onChange={({ value }) => onChange(value)}
    isFullWidth
    options={options}
  />
);

IntegrationInstalattionTypeFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default IntegrationInstalattionTypeFilters;
