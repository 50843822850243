import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
// import Arrowright from 'uxi/Icons/Arrowright';
import Arrowleft from 'uxi/Icons/Arrowleft';
import { Stepper, Step, StepButton } from 'uxi/Stepper';
import Flex from 'uxi/Layout/Flex';
import { Merge, Radioinput, Radioinputoutline } from 'uxi/Icons';
import Button, { ButtonWithConfirm } from 'uxi/Button';
import EntityComparePropertiesResolver, {
  decodeRRFKey,
} from '../composites/EntityComparePropertiesResolver';
import EntityReviewPropertiesResolver from '../composites/EntityReviewPropertiesResolver';
// TODO : remove deps on action that is outside of module folder
import EntityMergeWrapper from '../composites/EntityMergeWrapper';
import MergeDataWarning from '../composites/MergeDataWarning';
import EntitySmallCardContainer from '../containers/EntitySmallCardContainer';

const HoverHelper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${({ theme: { radius } }) => radius};
  background: white;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
`;

const EntitySmallCardContainerWrapperUI = styled.div`
  position: relative;
  flex-grow: 1;
  cursor: pointer;

  & > *:first-child {
    border-color: ${({ isBaseEntity, theme }) =>
      isBaseEntity ? theme.palette.accent.main : '#cecece'};
    background: ${({ isBaseEntity, theme }) =>
      isBaseEntity ? `${theme.palette.accent.main}30` : 'transparent'};
  }

  & > *:nth-child(2) {
    transform: translateY(108%);
    opacity: 0;
  }
  &:hover > *:nth-child(2) {
    transform: translateY(0%);
    opacity: 1;
  }

  & > *:last-child {
    opacity: 0.7;
    transform-origin: center center;
    transform: scale(0.85);
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

const EntitySmallCardContainerWrapper = (props) => {
  const { children, isBaseEntity, onClick } = props;

  return (
    <EntitySmallCardContainerWrapperUI
      onClick={onClick}
      isBaseEntity={isBaseEntity}
      style={{ overflowY: 'hidden' }}
    >
      {children}
      <HoverHelper>
        {isBaseEntity ? (
          <Flex
            style={{
              flexFlow: 'column',
              width: '100%',
              height: '100%',
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
            <FormattedHTMLMessage id="module-entityMerge-baseEntity" />
          </Flex>
        ) : (
          <Flex
            style={{
              width: '100%',
              height: '100%',
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
            <FormattedHTMLMessage id="module-entityMerge-useAsBase" />
          </Flex>
        )}
      </HoverHelper>
      {isBaseEntity ? <Radioinput /> : <Radioinputoutline />}
    </EntitySmallCardContainerWrapperUI>
  );
};

const EntitiesCompare = ({
  isFetching,
  isFetchingSchema,
  isSubmitting,
  entities,
  isSelectionValid,
  conflictedProperties,
  shouldSubmitMergedEntitiesDelta,
  errors,
  isDone,
  goBack,
  ids,
  targetId,
  propsDelta,
  shouldChangeBaseEntity,
}) => {
  const showContent = !isFetching && isSelectionValid && entities.length > 1;

  const formattedPropsDelta = Object.keys(propsDelta)
    .filter((k) => k !== '$form')
    .map((key) => ({
      Id: propsDelta[key].value,
      PropertyName: decodeRRFKey(key),
    }));

  const onSubmit = () =>
    shouldSubmitMergedEntitiesDelta({
      ids,
      targetId,
      delta: formattedPropsDelta,
    });

  const parsedIds = ids.split(',');
  const missingIDS = parsedIds.filter(
    (id) => !entities.find((e) => e.id === id),
  );
  let warnContent;

  if (entities.length !== parsedIds.length) {
    warnContent = <MergeDataWarning missingIDS={missingIDS} />;
  }
  const sortedEntities = [
    ...entities.filter((e) => e.id === parsedIds[0]),
    ...entities.filter((e) => e.id !== parsedIds[0]),
  ];

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <EntityMergeWrapper
      isFetching={isFetching}
      isFetchingSchema={isFetchingSchema}
      entities={entities}
      isSelectionValid={isSelectionValid}
      conflictedProperties={conflictedProperties}
      goBack={goBack}
    >
      <Flex
        style={{
          borderBottom: '1px solid #cecece',
          width: '100%',
          boxSizing: 'border-box',
          padding: '0 16px',
        }}
      >
        <Stepper
          linear
          activeStep={currentStep}
          nowrap
          connector={<span>&nbsp;&mdash;&nbsp;</span>}
        >
          <Step nowrap>
            <StepButton onClick={() => setCurrentStep(1)}>
              <FormattedMessage id="module-entityMerge-fixConflictProps" />
            </StepButton>
          </Step>
          <Step nowrap>
            <StepButton onClick={() => {}}>
              <FormattedMessage id="module-entityMerge-reviewBeforeMerge" />
            </StepButton>
          </Step>
        </Stepper>
      </Flex>
      <Flex
        style={{
          justifyContent: 'space-between',
          width: '100%',
          boxSizing: 'border-box',
          padding: '16px',
          position: 'sticky',
          top: '-2px',
          zIndex: 1,
          background: 'white',
        }}
      >
        {currentStep === 1 ? (
          <React.Fragment>
            <EntitySmallCardContainerWrapper
              isBaseEntity
              onClick={() => {
                shouldChangeBaseEntity(parsedIds[0], [
                  parsedIds[0],
                  ...parsedIds.filter((i) => i !== parsedIds[0]),
                ]);
              }}
              style={{ width: '50%' }}
            >
              <EntitySmallCardContainer entityId={targetId} />
            </EntitySmallCardContainerWrapper>
            <Flex style={{ flexShrink: 0, padding: '8px' }} direction="column">
              <Arrowleft />
              <strong>Merge into</strong>
            </Flex>
            <EntitySmallCardContainerWrapper
              style={{ width: '50%' }}
              onClick={() => {
                shouldChangeBaseEntity(parsedIds[1], [
                  parsedIds[1],
                  ...parsedIds.filter((i) => i !== parsedIds[1]),
                ]);
              }}
            >
              <EntitySmallCardContainer entityId={parsedIds[1]} />
            </EntitySmallCardContainerWrapper>
          </React.Fragment>
        ) : (
          <EntitySmallCardContainer entityId={targetId}>
            <Flex style={{ marginLeft: 'auto' }}>
              <Button style={{ margin: '8px' }} text="Cancel" />
              <ButtonWithConfirm
                style={{ margin: '8px' }}
                key="theSubmitButton"
                type="primary"
                confirmText={
                  <FormattedMessage
                    id="merge-entities-areYouSure"
                    defaultMessage="Are you sure you want to merge?"
                  />
                }
                onClick={onSubmit}
                icon={<Merge />}
                text={<FormattedMessage id="Merge" defaultMessage="Merge" />}
                disabled={isDone || isSubmitting}
              />
            </Flex>
          </EntitySmallCardContainer>
        )}
      </Flex>

      {showContent && currentStep === 1 ? (
        <React.Fragment>
          {warnContent}
          <EntityComparePropertiesResolver
            selectedEntities={sortedEntities}
            conflictedProperties={conflictedProperties}
            onSubmit={shouldSubmitMergedEntitiesDelta}
            isSubmitting={isSubmitting}
            errors={errors}
            isDone={isDone}
            ids={ids}
            targetId={targetId}
            propsDelta={propsDelta}
            setCurrentStep={setCurrentStep}
          />
        </React.Fragment>
      ) : null}
      {showContent && currentStep === 2 ? (
        <EntityReviewPropertiesResolver
          selectedEntities={entities}
          conflictedProperties={conflictedProperties}
          onSubmit={shouldSubmitMergedEntitiesDelta}
          isSubmitting={isSubmitting}
          errors={errors}
          isDone={isDone}
          ids={ids}
          targetId={targetId}
          propsDelta={propsDelta}
          setCurrentStep={setCurrentStep}
        />
      ) : null}
      {!showContent ? (
        <Alert type="danger">
          <FormattedHTMLMessage id="errorMerge" />
        </Alert>
      ) : null}
    </EntityMergeWrapper>
  );
};

EntitiesCompare.defaultProps = {
  entity: {},
};

export default EntitiesCompare;
