import styled from 'styled-components';

export const EntityRelationsTreeRootEdgeTitle = styled.div`
  align-items: flex-start;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  padding: 14px 0;
  position: relative;
  width: 100%;
`;
