import styled from 'styled-components';

export const EntitySourceItemWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 8px 12px;
`;

export const EntitySourceItemLogo = styled.img`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
`;

export const EntitySourceManuallyAddedIcon = styled.div`
  align-items: center;
  background: #ffe0f4;
  border-radius: 80%;
  display: flex;
  justify-content: center;
  max-height: 32px !important;
  max-width: 32px !important;
  overflow: hidden;

  & svg {
    height: 20px !important;
    padding: 6px 10px;
    max-height: 20px !important;
    max-width: 20px !important;
    width: 20px !important;
  }

  & svg path {
    fill: #ff99da;
  }
`;

export const EntitySourceItemDisplayName = styled.div`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 32px);
`;
