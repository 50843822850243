import React from 'react';
import styled from 'styled-components';
import { Hamburger, Delete } from 'uxi/Icons';
import FieldWrapper from './FieldWrapper';
import FieldDrag from './FieldDrag';

const DeleteWrapper = styled.div`
  cursor: pointer;
`;

const FieldItemDecorator = ({ children, style, onDeleteField }) => {
  return (
    <FieldWrapper style={style}>
      <FieldDrag>
        <Hamburger size={12} />
      </FieldDrag>
      <div style={{ flex: 1 }}>{children}</div>
      <DeleteWrapper onClick={onDeleteField}>
        <Delete size={16} />
      </DeleteWrapper>
    </FieldWrapper>
  );
};

export default FieldItemDecorator;
