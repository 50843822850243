import React from 'react';
import { FormDecorator, Select } from '@cluedin/form';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { Flex } from 'uxi/Layout';
import Alert from 'uxi/Alert';
import { Loader } from 'uxi/Indicator';

import { useQueryDataSets } from '../../../hooks/useQueryDataSets';
import EntityTypeFromAnnotaitonId from './EntityTypeFromAnnotaitonId';
import { SelectedVocabInfo } from './SelectedVocabInfo';

const DataSetSelect = ({
  intl,
  value,
  onChange,
  dataSetId,
  dataSourceId,
  showVocabInfo,
}) => {
  const [dataSets, isFetching] = useQueryDataSets(dataSourceId);

  const excludeCurrent = (dataSets.data || []).filter(
    (d) => d.id !== dataSetId,
  );
  const selectedDataSet = value
    ? (dataSets.data || []).find((d) => d.id === value)
    : null;

  if (isFetching) {
    return (
      <Flex>
        <Loader />
      </Flex>
    );
  }

  const options = excludeCurrent.map((d) => ({
    value: d.id,
    label: d.name,
  }));

  return (
    <div>
      {(!isFetching && excludeCurrent.length) === 0 && (
        <Alert type="warning">
          <div>
            {intl.formatMessage({ id: 'module-dataSource-no-other-data-set' })}
          </div>
        </Alert>
      )}
      {excludeCurrent && excludeCurrent.length > 0 && (
        <Select
          value={value}
          options={options}
          style={{ width: '300px' }}
          onChange={({ value }) => {
            const selectedDataSet = value
              ? (dataSets.data || []).find((d) => d.id === value)
              : null;

            onChange(value, selectedDataSet);
          }}
          placeholder={intl.formatMessage({
            id: 'module-dataSource-select-for-data-set',
          })}
        />
      )}
      {value && selectedDataSet && selectedDataSet.annotationId && (
        <>
          <FormDecorator
            style={{ marginTop: '12px' }}
            label={intl.formatMessage({
              id: 'module-dataSource-entity-for-selected-data-set',
            })}
          >
            <EntityTypeFromAnnotaitonId
              annotationId={selectedDataSet.annotationId}
            />
          </FormDecorator>

          {showVocabInfo && (
            <SelectedVocabInfo
              vocabulary={selectedDataSet?.annotation?.vocabulary}
            />
          )}
        </>
      )}
      {value && selectedDataSet && !selectedDataSet.annotationId && (
        <div style={{ marginTop: '12px' }}>
          <FormattedMessage id="module-dataSource-dataIsNotMapped" />
        </div>
      )}
    </div>
  );
};

export default injectIntl(DataSetSelect);
