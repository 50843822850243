import { makeLazy } from '../core/components/Hocs/LazyRoute';
// import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    // claims: {
    //   'consume.streams': RACI_LEVELS.CONSULTANT,
    // },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamDashboardPage" */
        './components/pages/SecurityFilterPage'
      ),
    ),
  },
];
