import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { unauthorized } from '../../action/generic';
import { shouldShowAlert } from '../core/actions';
import {
  updateGDPRConfig,
  getGDPRConfig,
  getSAR,
  getFilteredSARs,
  deleteSar,
  archiveSar,
  createSar,
  updateSar,
  createSARReport,
  regenerateSARReport,
  subscribeForUserData,
  unSubscribeForUserData,
  getConsentReport,
  getIdentifiersReport,
  getLocationReport,
  getEntityTypesReport,
  getEncryptedReport,
  addFromProcessing,
  unMinimizeUserData,
  sendReport,
  removeFromProcessing,
  anonymize,
  removeAllData,
  minimizeUserData,
  escalate,
  getGDPRJsonReport,
  getExtendReportTransforms,
  postExtendReportData,
  batchImportFromFile,
} from './data';
import { fetchPersonEntities, getEntity } from '../entity/data';
import {
  updateSelectedEntityForMinimize,
  updateRemoveFromProcessing,
  updateSelectedEntityForAnonymize,
} from '../entity/actions';

import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../action/helpers/actionCreator';

export const showNewSarDialog = createAction('GDPR_SHOW_NEW_SAR_DIALOG');
export const hideNewSarDialog = createAction('GDPR_HIDE_NEW_SAR_DIALOG');

export const shouldShowAddIdentifierDialog = createAction(
  'SHOW_GDPR_CONFIGURATION_IDENTIFIER_DIALOG',
);
export const shouldHideIdentifierDialog = createAction(
  'HIDE_GDPR_CONFIGURATION_IDENTIFIER_DIALOG',
);

// FETCH Sars
export const SARActions = createAsyncAction('SAR_LIST');
export const resetSARListFilters = createAction('SAR_LIST_RESET_FILTERS');
export const shouldFetchSAR = defaultAsyncCallWithActions(
  getFilteredSARs,
  SARActions,
);

export const shouldLoadMoreSARActions = createAsyncAction('SAR_LOAD_MORE');
export const shouldLoadMoreSAR = defaultAsyncCallWithActions(
  getFilteredSARs,
  shouldLoadMoreSARActions,
);
// FETCH Sars by state
export const getFilteredSARsActions = createAsyncAction('SAR_FILTERED_LIST');
export const shouldFetchFilteredSARsByState = defaultAsyncCallWithActions(
  getFilteredSARs,
  getFilteredSARsActions,
);

// FETCH single Sar
export const SARByIdActions = createAsyncAction('SAR_SINGLE');
export const SARClearSubjectsEntityAction = createAction(
  'SAR_SINGLE_ENTITY_CLEAR',
);
export const SARFetchSubjectsEntityActions =
  createAsyncAction('SAR_SINGLE_ENTITY');
export const shouldFetchSAREntityById = defaultAsyncCallWithActions(
  getEntity,
  SARFetchSubjectsEntityActions,
);
export const shouldFetchSARById = defaultAsyncCallWithActions(
  getSAR,
  SARByIdActions,
  (dispatch, res) => {
    if (
      res &&
      res.EntityId &&
      res.EntityId !== '00000000-0000-0000-0000-000000000000'
    ) {
      dispatch(shouldFetchSAREntityById(res.EntityId));
    }
  },
);

export const lookForReportUrlActions = createAsyncAction(
  'SAR_CHECK_REPORT_URL',
);
export const shouldLookForReportUrlUpdate = defaultAsyncCallWithActions(
  getSAR,
  lookForReportUrlActions,
);

// FILTER SAR
export const updateFilterAction = createAction('SAR_LIST_UPDATE_FILTER');
export const updateFilter = (filterOption) => (dispatch) => {
  dispatch(updateFilterAction(filterOption));
  // dispatch(shouldFetchSAR(filterOption));
  // const page = filterOption.page || 0;
  // const take = filterOption.take || 20;
  dispatch(shouldFetchFilteredSARsByState(filterOption));
};

// CREATE
export const SarCreateActions = createAsyncAction('SAR_CREATE');

export const shouldCreateSar = (sar, callback) => (dispatch) => {
  dispatch(SarCreateActions.request(sar));
  createSar(sar)
    .then((sarPayload) => {
      dispatch(SarCreateActions.receive(sarPayload));
      dispatch(
        shouldShowAlert({
          title: 'Initiating new SAR',
          type: 'success',
          description:
            'Creating new S.A.R. You will be redirected in a few seconds',
        }),
      );
      dispatch(hideNewSarDialog());
      if (callback) callback(sarPayload);
    })
    .catch((err) => {
      dispatch(SarCreateActions.invalid(err));
      dispatch(
        shouldShowAlert({
          title: 'Initiating new SAR failed',
          type: 'error',
          description:
            'An error occured while creating the SAR. Please try again shorlty',
        }),
      );
      unauthorized(dispatch, SarCreateActions.invalid)(err);
    });
};

export const shouldCreateSARAndNavigate = (sar) => (dispatch) => {
  dispatch(
    shouldCreateSar(sar, (sarPayload) => {
      dispatch(push(`/admin/governance/gdpr/edit/findEntity/${sarPayload.Id}`));
    }),
  );
};

// SAVE
export const SarSaveActions = createAsyncAction('SAR_SAVE');

export const shouldSaveSar = (sar, callback) => (dispatch) => {
  dispatch(SarSaveActions.request(sar));
  updateSar(sar)
    .then((sarUpdatePayload) => {
      dispatch(SarSaveActions.receive(sarUpdatePayload));
      if (callback) callback(sarUpdatePayload);
    })
    .catch(unauthorized(dispatch, SarSaveActions.invalid));
};

export const shouldSaveSarAndNavigate = (sar, targetURL) => (dispatch) => {
  dispatch(
    shouldSaveSar(sar, () => {
      dispatch(push(targetURL));
    }),
  );
};

// generate sar report
export const generateSARReportActions = createAsyncAction(
  'GDPR_GENERATE_SAR_REPORT',
);
export const shouldGenerateSARReport = defaultAsyncCallWithActions(
  createSARReport,
  generateSARReportActions,
);
export const shouldSaveSarCreateReportAndNavigate =
  (sar, targetURL) => (dispatch) => {
    dispatch(
      shouldSaveSar(sar, () => {
        dispatch(shouldGenerateSARReport(sar));
        dispatch(push(targetURL));
      }),
    );
  };

export const shouldCreateSARAndAttachCreateReportEntityAndNavigate =
  (sarInitiatorSEmail, targetEntity) => (dispatch) => {
    const sar = {
      Email: sarInitiatorSEmail,
      Identifiers: { Name: targetEntity.data.name },
      Type: 'Export',
    };

    dispatch(
      shouldCreateSar(sar, (sarPayload) => {
        const sarWithAttachedIndividual = {
          ...sarPayload,
          State: 2,
          EntityId: targetEntity.id,
        };

        dispatch(
          shouldSaveSarCreateReportAndNavigate(
            sarWithAttachedIndividual,
            `/admin/governance/gdpr/edit/validate/${sarPayload.Id}`,
          ),
        );
      }),
    );
  };

// subscrbie for user Data
export const subscribeGdprUserData = createAsyncAction(
  'GDPR_SUBSCRIBE_USER_DATA',
);
export const shouldSubscribeForUserData =
  ({ guid, id }) =>
  (dispatch) => {
    dispatch(subscribeGdprUserData.request({ guid, id }));

    subscribeForUserData({ guid, id })
      .then(() => {
        dispatch(subscribeGdprUserData.receive({ guid, id }));
      })
      .catch(unauthorized(dispatch, subscribeGdprUserData.invalid));
  };
export const unSubscribeGdprUserData = createAsyncAction(
  'GDPR_UNSUBSCRIBE_USER_DATA',
);
export const shouldUnSubscribeForUserData =
  ({ guid, id }) =>
  (dispatch) => {
    dispatch(unSubscribeGdprUserData.request({ guid, id }));

    unSubscribeForUserData({ guid, id })
      .then(() => {
        dispatch(unSubscribeGdprUserData.receive({ guid, id }));
      })
      .catch(unauthorized(dispatch, unSubscribeGdprUserData.invalid));
  };

// DELETE
// export const sarDeleteActions = createAsyncAction('SAR_DELETE');
// export const shouldDeleteSarAction = defaultAsyncCallWithActions(deleteSar, sarDeleteActions);
// MULTIPLE DELETE
export const sarDeleteActions = createAsyncAction('SAR_DELETE');
export const shouldDeleteSarAction = (sarsIds) => (dispatch) => {
  dispatch(sarDeleteActions.request(sarsIds));
  return Promise.all(sarsIds.map((sarId) => deleteSar(sarId)))
    .then((deletedSars) => {
      const idsArray = deletedSars.map((sarRes) => sarRes.Id);
      return dispatch(sarDeleteActions.receive(idsArray));
    })
    .catch((e) => {
      console.warn(e); // eslint-disable-line no-console
      unauthorized(dispatch, sarDeleteActions.invalid)(e);
    });
};

export const sarAchiveActions = createAsyncAction('SAR_ARCHIVE');
export const shouldArchiveSar = (sarsIds) => (dispatch) => {
  dispatch(sarAchiveActions.request(sarsIds));
  return Promise.all(sarsIds.map((sarId) => archiveSar(sarId)))
    .then((archivedSars) => {
      const idsArray = archivedSars.map((sarRes) => sarRes.Id);
      return dispatch(sarAchiveActions.receive(idsArray));
    })
    .catch((e) => {
      console.warn(e); // eslint-disable-line no-console
      unauthorized(dispatch, sarAchiveActions.invalid)(e);
    });
};

// GET GDPR CONFIG
export const getGDPRConfigActions = createAsyncAction('GET_GDPR_CONFIG');
export const shouldFetchGDPRConfig = defaultAsyncCallWithActions(
  getGDPRConfig,
  getGDPRConfigActions,
);

// GET GDPR json report (ReportUri)
export const getGDPRJsonReportActions = createAsyncAction(
  'GET_GDPR_JSON_REPORT',
);
export const resetGDPRJsonReportActions = createAction(
  'RESET_GDPR_JSON_REPORT',
);
export const shouldFetchGDPRJsonReport = defaultAsyncCallWithActions(
  getGDPRJsonReport,
  getGDPRJsonReportActions,
);

// UPDATE GDPR CONFIG
export const gdprConfigUpdateActions = createAsyncAction('GDPR_CONFIG_UPDATE');
export const shouldUpdateGDPRConfig = defaultAsyncCallWithActions(
  updateGDPRConfig,
  gdprConfigUpdateActions,
  (dispatch) => {
    dispatch(
      shouldShowAlert({
        title: 'Configuration update',
        type: 'success',
        description: 'The configuration has been successfully saved',
      }),
    );
  },
);

// remove from processing
export const removeFromProcessingActions = createAsyncAction(
  'GDPR_REMOVE_FROM_PROCESSING',
);
export const shouldRemoveFromProcessing = (entityId) => (dispatch) => {
  dispatch(removeFromProcessingActions.request(entityId));
  removeFromProcessing(entityId)
    .then(() => {
      dispatch(removeFromProcessingActions.receive(entityId));
      dispatch(updateRemoveFromProcessing(true));
      dispatch(
        shouldShowAlert({
          title: 'Request to remove from processing received',
          description:
            'We have received your request to remove this entity from processing.',
          type: 'success',
        }),
      );
    })
    .catch(unauthorized(dispatch, removeFromProcessingActions.invalid));
};

export const addFromProcessingActions = createAsyncAction(
  'GDPR_ADD_FROM_PROCESSING',
);
export const shouldAddFromProcessing = (entityId) => (dispatch) => {
  dispatch(addFromProcessingActions.request(entityId));
  addFromProcessing(entityId)
    .then(() => {
      dispatch(addFromProcessingActions.receive(entityId));
      dispatch(updateRemoveFromProcessing(false));
      dispatch(
        shouldShowAlert({
          title: 'Request to add back to processing received',
          description:
            'We have received your request to add this entity back to processing.',
          type: 'success',
        }),
      );
    })
    .catch(unauthorized(dispatch, addFromProcessingActions.invalid));
};

// anonymize
export const anonymizeActions = createAsyncAction('GDPR_UN_ANONYMIZE_ENTITY');
export const shouldAnonymize = (entityId) => (dispatch) => {
  dispatch(anonymizeActions.request(entityId));
  anonymize(entityId)
    .then(() => {
      dispatch(anonymizeActions.receive(entityId));
      dispatch(updateSelectedEntityForAnonymize(false));
      dispatch(
        shouldShowAlert({
          title: 'Request to anonymize from processing received',
          description:
            'We have received your request to anonymize this entity.',
          type: 'success',
        }),
      );
    })
    .catch(unauthorized(dispatch, anonymizeActions.invalid));
};

// minimize
export const minimizeEntityActions = createAsyncAction('GDPR_MINIMIZE_ENTITY');
export const shouldMinimizeEntity = (entityId) => (dispatch) => {
  dispatch(minimizeEntityActions.request(entityId));
  minimizeUserData(entityId)
    .then(() => {
      dispatch(minimizeEntityActions.receive(entityId));
      dispatch(updateSelectedEntityForMinimize(true));
      dispatch(
        shouldShowAlert({
          title: 'Request to minimize an Entity received',
          description: 'We have received your request to minimize this entity.',
          type: 'success',
        }),
      );
    })
    .catch(unauthorized(dispatch, minimizeEntityActions.invalid));
};

// un-minimize
export const unMinimizeEntityActions = createAsyncAction(
  'GDPR_UN_MINIMIZE_ENTITY',
);
export const shouldUnminimizeEntity = (entityId) => (dispatch) => {
  dispatch(unMinimizeEntityActions.request(entityId));
  unMinimizeUserData(entityId)
    .then(() => {
      dispatch(unMinimizeEntityActions.receive(entityId));
      dispatch(updateSelectedEntityForMinimize(false));
      dispatch(
        shouldShowAlert({
          title: 'Request to un-minimize an Entity received',
          description:
            'We have received your request to un-minimize this entity.',
          type: 'success',
        }),
      );
    })
    .catch(unauthorized(dispatch, unMinimizeEntityActions.invalid));
};

// get consent report
export const consentReportActions = createAsyncAction(
  'GDPR_GET_CONSENT_REPORT',
);
export const shouldGetConsentReport = defaultAsyncCallWithActions(
  getConsentReport,
  consentReportActions,
);

// this is for when approving report "as is":
export const shouldGenerateSARReportAndNavigate = (sar) => (dispatch) => {
  dispatch(generateSARReportActions.request());
  createSARReport(sar)
    .then((res) => {
      dispatch(generateSARReportActions.receive(res));
      const newSar = { ...sar, State: 3 };
      // navigate to next step:
      dispatch(
        shouldSaveSarAndNavigate(
          newSar,
          `/admin/governance/gdpr/edit/sendReport/${sar.Id}`,
        ),
      );
    })
    .catch((e) => {
      dispatch(generateSARReportActions.invalid(e));
    });
};

// REgenerate sar report
export const regenerateSARReportActions = createAsyncAction(
  'GDPR_REGENERATE_SAR_REPORT',
);
export const resetRegenerateSARReportAction = createAction(
  'GDPR_RESET_REGENERATE_SAR_REPORT',
);

// this is for requesting a regeneration of the report according to reportSettings:
export const shouldRegenerateSARReport =
  (sar, reportSettingsParam = {}) =>
  (dispatch) => {
    dispatch(regenerateSARReportActions.request());

    // make sure all props exists (endpoint con)
    const reportSettings = {
      ExcludedGuids: [],
      ExcludedTypes: [],
      ExcludedProviders: [],
      ExcludedProperties: [],
      ExcludeProvidersDefinition: [],
      ...reportSettingsParam,
    };

    regenerateSARReport(sar, reportSettings)
      .then((res) => {
        dispatch(regenerateSARReportActions.receive(res));
        dispatch(
          shouldShowAlert({
            title: 'Request Queued',
            type: 'success',
            description:
              'The regeneration request has been successfully submitted',
          }),
        );
        // const newSar = { ...sar };
        // SAVE ADN reload the page at current step:
        // dispatch(shouldSaveSarAndNavigate(newSar, `/gdpr/edit/validate/${sar.Id}`));

        // just reload the page at current step (hacky but does the job):
        dispatch(push('/admin/governance/gdpr'));
        setTimeout(() => {
          dispatch(push(`/admin/governance/gdpr/edit/validate/${sar.Id}`));
        }, 1);
      })
      .catch((e) => {
        dispatch(
          shouldShowAlert({
            title: 'Request failed',
            type: 'error',
            description: 'The report regeneration request failed',
          }),
        );
        dispatch(regenerateSARReportActions.invalid(e));
      });
  };

// receive generated sar report ws notif
export const receiveGeneratedSARReportActions = createAsyncAction(
  'GDPR_RECEIVE_GENERATED_SAR_REPORT',
);

// personIdentifies
export const identifiersReportActions = createAsyncAction(
  'GDPR_IDENTIFIERS_REPORT',
);
export const shouldGetIdentifiersReport = defaultAsyncCallWithActions(
  getIdentifiersReport,
  identifiersReportActions,
);

// Encrypted report
export const encryptedReportActions = createAsyncAction(
  'GDPR_ENCRYPTED_REPORT',
);
export const shouldGetEncryptedReport = defaultAsyncCallWithActions(
  getEncryptedReport,
  encryptedReportActions,
);

// location report
export const locationReportActions = createAsyncAction('GDPR_LOCATION_REPORT');
export const shouldGetLocationReport = defaultAsyncCallWithActions(
  getLocationReport,
  locationReportActions,
);

// entityType report
export const entityTypeReportActions = createAsyncAction(
  'GDPR_ENTITY_TYPE_REPORT',
);
export const shouldGetEntityTypeReport = defaultAsyncCallWithActions(
  getEntityTypesReport,
  entityTypeReportActions,
);

// FAKE
export const shouldFetchPersonActions = createAsyncAction(
  'SAR_FETCH_PERSON_ENTITIES',
);
export const shouldFetchPerson = defaultAsyncCallWithActions(
  fetchPersonEntities,
  shouldFetchPersonActions,
);

export const updatePersonForSar = createAction('SAR_UPDATE_PERSON_FOR_SAR');
export const clearSelectionPersonForSar = createAction(
  'SAR_CLEAR_PERSON_FOR_SAR',
);

export const requestSARReportReadyNotif = createAction(
  'REQUEST_SAR_REPORT_READY_NOTIF',
);
export const resetSARReportReadyNotif = createAction(
  'RESET_SAR_REPORT_READY_NOTIF',
);

export const shouldClearCurrentSar = createAction('SHOULD_CLEAR_CURRENT_SAR');

export const shouldReceiveRealTimeSARReportReady = (notif) => (dispatch) => {
  setTimeout(() => {
    dispatch(resetSARReportReadyNotif(notif));
  }, 10000);
  return dispatch(requestSARReportReadyNotif(notif));
};

let intervalId = null;

export const shouldStartPollingForReport = (sar) => (dispatch) => {
  if (
    (sar && sar.ReportUri === null) ||
    !sar.ReportStatus ||
    sar.ReportStatus === 'Generating' ||
    sar.ReportStatus === 'RegeneratingExcludedGuids' ||
    sar.ReportStatus === 'RegeneratingClueTransform'
  ) {
    // poll once directly, then init interval
    dispatch(shouldLookForReportUrlUpdate(sar.Id));

    clearInterval(intervalId);
    intervalId = setInterval(() => {
      dispatch(shouldLookForReportUrlUpdate(sar.Id));
    }, 12000);
  }
};

export const shouldClearLongPoolingForReport = () => {
  clearInterval(intervalId);
};

export const sendReportToEmailActions = createAsyncAction('SAR_END_REPORT');

// eslint-disable-next-line consistent-return
export const shouldSendReportAndRedirect = (sar, email, url) => (dispatch) => {
  if (!email) {
    return dispatch(shouldSaveSarAndNavigate({ ...sar, State: 4 }, url));
  }

  dispatch(sendReportToEmailActions.request(sar.id));

  sendReport(sar, email)
    .then(() => {
      // now update the SAR State from 3 to 4
      dispatch(
        shouldSaveSar({ ...sar, State: 4 }, () => {
          dispatch(sendReportToEmailActions.receive(sar.id));
          dispatch(push(url));
        }),
      );
    })
    .catch(unauthorized, sendReportToEmailActions.invalid);
};

export const shouldFetchSARByIdAndEntity = (id) => (dispatch) => {
  dispatch(SARByIdActions.request(id));

  getSAR(id)
    .then((sar) => {
      getEntity(sar.EntityId).then((entity) => {
        sar.entity = entity; // eslint-disable-line no-param-reassign
        dispatch(SARByIdActions.receive(sar));
      });
    })
    .catch(unauthorized(dispatch, SARByIdActions.invalid));
};

export const shouldBatchActionsForGdpr =
  createAsyncAction('GDPR_ACTIONS_BATCH');

export const shouldFetchActions = (sar, actions) => (dispatch) => {
  const allPromises = [];

  if (actions.removeFromProcessing) {
    allPromises.push(removeFromProcessing(sar.EntityId));
  }
  if (actions.anonymize) {
    allPromises.push(anonymize(sar.EntityId));
  }
  if (actions.removeAllData) {
    allPromises.push(removeAllData(sar.EntityId));
  }
  if (actions.minimize) {
    allPromises.push(minimizeUserData(sar.EntityId));
  }

  dispatch(shouldBatchActionsForGdpr.request(sar.Id));

  Promise.all(allPromises)
    .then(() => {
      dispatch(shouldBatchActionsForGdpr.receive(sar.Id));
      dispatch(shouldSaveSar({ ...sar, State: 5 }));
      dispatch(push(`/admin/governance/gdpr/edit/overview/${sar.Id}`));
    })
    .catch(unauthorized, shouldBatchActionsForGdpr.invalid);
};

export const shouldResetEscalateDialog = createAction(
  'GDPR_RESET_DIALOG_ESCALATE',
);
export const shouldShowEscalateDialog = createAction(
  'GDPR_SHOW_DIALOG_ESCALATE',
);
export const shouldhideEscalateDialog = createAction(
  'GDPR_HIDE_ESCALATE_DIALOG',
);

export const escalateSarIdsForUsersIdsActions = createAsyncAction(
  'GDPR_ESCALATE_SARS_TO_USERS',
);
export const shouldEscalateForUsersAndSarIds =
  (sarIds, entityIdToEscalateTo, reason) => (dispatch) => {
    dispatch(escalateSarIdsForUsersIdsActions.request());

    Promise.all(
      sarIds.map((sarId) => escalate(sarId, entityIdToEscalateTo, reason)),
    )
      .then((res) => {
        dispatch(escalateSarIdsForUsersIdsActions.receive(res));
      })
      .catch(unauthorized(dispatch, escalateSarIdsForUsersIdsActions.invalid));
  };

export const extendReportActions = createAsyncAction(
  'GDPR_EXTEND_REPORT_FETCH_TRANSFORMS',
);
export const shouldFetchAvailableTransforms = () => (dispatch) => {
  dispatch(extendReportActions.request());

  return getExtendReportTransforms()
    .then((transforms) => {
      dispatch(extendReportActions.receive(transforms));
    })
    .catch(unauthorized(dispatch, extendReportActions.invalid));
};

export const resetPostExtendReportAction = createAction(
  'GDPR_EXTEND_REPORT_RESET_STORE',
);
export const postExtendReportActionsConflicted = createAction(
  'GDPR_EXTEND_REPORT_CONFLICTED',
);
export const postExtendReportActions = createAsyncAction(
  'GDPR_EXTEND_REPORT_POST_TRANSFORMS',
);
export const shouldPostExtendReportData =
  (sar, transformId, file) => (dispatch) => {
    dispatch(postExtendReportActions.request());

    return postExtendReportData(sar.Id, transformId, file)
      .then((resp) => {
        dispatch(postExtendReportActions.receive(resp));
        dispatch(
          shouldRegenerateSARReport(sar, {
            id: sar.EntityId,
            sarId: sar.Id,
          }),
        );
      })
      .catch((err) => {
        if (err.status === 409) {
          dispatch(postExtendReportActionsConflicted(err));
        } else {
          unauthorized(
            dispatch,
            postExtendReportActions.invalid,
            false,
            err,
          )(err);
        }
      });
  };

export const batchImportFromFileActions = createAsyncAction(
  'GDPR_BATCH_IMPORT_FROM_FILE',
);
export const shouldBatchImportFromFile =
  (sar, transformId, file) => (dispatch) => {
    dispatch(batchImportFromFileActions.request(sar, transformId, file));

    return batchImportFromFile(sar, transformId, file)
      .then((res) => {
        dispatch(batchImportFromFileActions.receive(res));
      })
      .catch((err) => {
        if (err instanceof Error) {
          dispatch(batchImportFromFileActions.invalid(err));
        } else {
          unauthorized(
            dispatch,
            batchImportFromFileActions.invalid,
            false,
            file,
          );
        }
      });
  };

export const batchImportFromFileCleanUpActions = createAction(
  'GDPR_BATCH_IMPORT_FROM_FILE_CLEAN_UP',
);

export const shouldCreateAllSarActionsAllDone = createAction(
  'GDPR_BATCH_SAR_CREATION_ALL_DONE',
);
export const shouldClearAllSarBatchCreation = createAction(
  'GDPR_BATCH_SAR_CREATION_CLEAR',
);
export const shouldCreateAllSarActions = createAsyncAction(
  'GDPR_BATCH_SAR_CREATION',
);
export const shouldCreateAllSar_old =
  (selected = []) =>
  (dispatch) => {
    // eslint-disable-line
    const doneRequests = [];
    selected.forEach((sarValue) => {
      dispatch(shouldCreateAllSarActions.request(sarValue));
      // TODO: firgure out correct payload.
      createSar(sarValue)
        .then(() => {
          dispatch(shouldCreateAllSarActions.receive(sarValue));
          doneRequests.push(sarValue);
          if (doneRequests.length === selected.length) {
            dispatch(shouldCreateAllSarActionsAllDone(selected));
          }
        })
        .catch(() => {
          shouldCreateAllSarActions.invalid(sarValue);
        });
    });
  };
