import React from 'react';
import { FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import EntityMergePropertiesResolver from './EntityMergePropertiesResolver';
// TODO : remove deps on action that is outside of module folder
import EntityMergeWrapper from './EntityMergeWrapper';
import MergeDataWarning from './MergeDataWarning';

const EntitiesMerge = ({
  isFetching,
  isFetchingSchema,
  isSubmitting,
  entities,
  isSelectionValid,
  conflictedProperties,
  shouldSubmitMergedEntitiesDelta,
  errors,
  isDone,
  goBack,
  ids,
  targetId,
  propsDelta,
}) => {
  const showContent = !isFetching && isSelectionValid && entities.length > 1;
  const missingIDS = ids
    .split(',')
    .filter((id) => !entities.find((e) => e.id === id));
  let warnContent;

  if (entities.length !== ids.split(',').length) {
    warnContent = <MergeDataWarning missingIDS={missingIDS} />;
  }

  return (
    <EntityMergeWrapper
      isFetching={isFetching}
      isFetchingSchema={isFetchingSchema}
      entities={entities}
      isSelectionValid={isSelectionValid}
      conflictedProperties={conflictedProperties}
      goBack={goBack}
    >
      {showContent ? (
        <div>
          {warnContent}
          <EntityMergePropertiesResolver
            selectedEntities={entities}
            conflictedProperties={conflictedProperties}
            onSubmit={shouldSubmitMergedEntitiesDelta}
            isSubmitting={isSubmitting}
            errors={errors}
            isDone={isDone}
            ids={ids}
            targetId={targetId}
            propsDelta={propsDelta}
          />
        </div>
      ) : (
        <Alert type="danger">
          <FormattedHTMLMessage id="errorMerge" />
        </Alert>
      )}
    </EntityMergeWrapper>
  );
};

EntitiesMerge.defaultProps = {
  entity: {},
};

export default EntitiesMerge;
