import React from 'react';
import { Modal } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import QuickSchemaSearchContainer from './QuickSchemaSearchContainer';

const AdvancedPropertyChooseDialog = ({
  show,
  onClose,
  onSchemaSelect,
  schemaKeys,
  unavailableProperties,
}) => {
  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      title={<FormattedMessage id="module-schema-selectVocabTerm" />}
    >
      <QuickSchemaSearchContainer
        unavailableProperties={unavailableProperties}
        onSchemaSelect={onSchemaSelect}
        schemaKeys={schemaKeys}
      />
    </Modal>
  );
};

export default AdvancedPropertyChooseDialog;
