import { useQuery, gql } from '@apollo/client';

const ALL_CONNECTOR = gql`
  query getAllConnectors {
    inbound {
      id
      connectors {
        id
        name
        icon
        about
        authMethods
        createdDate
        domain
        guide
        isEnabled
        isNew
        properties
        supportsWebHooks
        type
        streamModes
      }
    }
  }
`;

export type Connector = {
  id: string;
  name: string;
  icon: string;
  about: string;
  authMethods: {
    token: string[] | unknown | null;
    credentials: string[] | unknown | null;
    oauth: string[] | boolean | string | unknown | null;
  };
  createdDate: string | null;
  domain: string;
  guide: {
    Details: string;
    Instructions: string;
    Value: string[];
  };
  isEnabled: boolean;
  isNew: boolean;
  properties: string[] | unknown | null;
  supportsWebHooks: boolean;
  type: string;
  streamModes: string[] | unknown | null;
};

type ConnectorsIntegrationAndEnrichmentRes = {
  inbound: {
    connectors: Connector[];
  };
};

export const useQueryConnectorsIntegrationAndEnrichment = () => {
  const { data, loading, error, refetch } =
    useQuery<ConnectorsIntegrationAndEnrichmentRes>(ALL_CONNECTOR);

  const connectors =
    data?.inbound.connectors.filter(
      (c) => c.type !== 'Connector' && c.type !== 'DataSource',
    ) || [];

  return {
    error,
    loading,
    refetch,
    data: connectors,
  };
};
