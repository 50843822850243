import constants from '../constants';
import { env } from '../config';

const initialState = {
  features: [
    'follow',
    'savedSearch',
    'users',
    'data-governance',
    'developer',
    'settings',
    'integrations',
    'customEntityPage',
  ],
  widgetConfiguration: undefined,
  token: '',
  org: '',
  connectedData: [],
  unauthorized: false,
  isFetchingConnectedData: true,
  layout: undefined,
  lastTimeVisitedValue: undefined,
  cluedinNotifications: [],
  teamDomain: {},
  showErrorGlobal: false,
  env,
};

export default function update(state = initialState, action = {}) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (action.payload.action === 'REPLACE') {
        return state;
      }
      return state;
    case constants.generic.GLOBAL_ERROR:
      return Object.assign({}, state, {
        showErrorGlobal: true,
      });
    case constants.generic.UNAUTHORIZED_REQUEST:
      return Object.assign({}, state, {
        token: undefined,
        unauthorized: true,
      });
    case constants.core.REQUEST_MOST_CONNECTED:
      return Object.assign({}, state, {
        connectedData: [],
        isFetchingConnectedData: true,
      });
    case constants.core.RECEIVE_MOST_CONNECTED:
      return Object.assign({}, state, {
        connectedData: action.data,
        isFetchingConnectedData: false,
      });
    case constants.core.UPDATE_TOKEN:
      return Object.assign({}, state, {
        token: action.data.token,
      });
    case constants.core.REQUEST_REALTIME_NOTIFICATION:
      return Object.assign({}, state, {
        cluedinNotifications: [
          ...state.cluedinNotifications,
          {
            show: true,
            message: action.data.notification.message,
            title: action.data.notification.title,
          },
        ],
      });
    case constants.core.RESET_REALTIME_NOTIFICATION: {
      const notificationToResets = state.cluedinNotifications.filter(
        (notification) => notification.title === action.data.notification.title,
      );

      notificationToResets.forEach((notification) => {
        const notificationToReset = notification;
        notificationToReset.show = false;
      });

      return Object.assign({}, state, {
        cluedinNotifications: [...state.cluedinNotifications],
      });
    }
    default:
      return state;
  }
}
