import { gql, useLazyQuery } from '@apollo/client';
import get from 'lodash/get';

const GET_EDGETYPES = gql`
  query getEdgeTypes($entityType: String) {
    management {
      id
      getEdgeTypes(entityType: $entityType) {
        edgeTypes
      }
    }
  }
`;

export const useGetEdgeTypes = () => {
  const [getEdgeTypes, { data, loading, error }] = useLazyQuery(GET_EDGETYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const queryFromHooks = get(data, 'management.getEdgeTypes');

  return [
    (model) => {
      getEdgeTypes({
        variables: { ...model },
      });
    },
    {
      data: queryFromHooks,
      loading,
      error,
    },
  ];
};
