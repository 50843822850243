import { getIcon, getLogoFromGql } from './logo';
import { hasEntityConfigSetup } from '../../wms/helpers/widgetHelper';
import { getEntityTemporalDataFromGql } from './entity';
import { uniqProviderBasedOnUrisAndProviderCodes } from '../../entity/selectors';

export const createWidgetViewModelFromGql = (
  entity = {},
  entityConfigurations = [],
) => {
  const {
    id,
    name,
    entityType,
    description,
    uris,
    providerOrigins,
    properties,
    createdDate: originalCreatedDate,
  } = entity;

  const logo = getLogoFromGql(entity);
  const icon = getIcon(entity, entityConfigurations);
  const isEntityConfigured = hasEntityConfigSetup(
    entityType,
    entityConfigurations,
  );
  const createdDate = getEntityTemporalDataFromGql(entity);
  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    uris,
    providerOrigins || [],
  );
  const numberProperties = Object.keys(properties || {}).length || 1;

  return {
    entityId: id,
    entityType,
    name,
    description: description || 'no snippet',
    logo,
    icon,
    createdDate,
    providers,
    isEntityConfigured,
    created: originalCreatedDate,
    properties,
    numberProperties: numberProperties - 1, // without attribute-type
    original: entity,
  };
};

export default {
  createWidgetViewModelFromGql,
};
