import { memo, useId } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Loader, PrimaryButton } from '@cluedin/form';
import { Icon, TooltipHost } from '@fluentui/react';

const RecordList = ({
  data,
  total,
  loading,
  pageSize,
  selected,
  offsetTop,
  noFilters,
  hasFilters,
  selectable,
  gridColumns,
  selectedPage,
  GhostLoading,
  filterToolbar,
  onSubmitSearch,
  setCurrentPage,
  showPagination,
  searchNameInput,
  advancedFilters,
  onSelectedChange,
  setSearchNameInput,
  nextPage,
  cursor,
  List,
  customPaging,
  hideListSearch,
  listToolbarAdditionalLeftContent,
  listFooterAdditionalRightContent,
}) => {
  const tooltipId = useId();
  const disabledItemsTooltipHint = (
    <span>
      <FormattedMessage id="module-goldenRecordList-selectMerge-part1" />
      <br />
      <FormattedMessage id="module-goldenRecordList-selectMerge-part2" />
    </span>
  );

  const loadMore = () => {
    nextPage(cursor);
  };

  return (
    <>
      <List
        data={data}
        selectOnlyOne
        total={total}
        loading={loading}
        selected={selected}
        offsetTop={customPaging ? offsetTop + 72 : offsetTop}
        columns={gridColumns}
        hasFilters={hasFilters}
        selectable={selectable}
        itemsPerPage={pageSize}
        GhostLoading={GhostLoading}
        selectedPage={selectedPage}
        searchName={searchNameInput}
        onPageChange={setCurrentPage}
        filterToolbar={filterToolbar}
        onSubmitSearch={onSubmitSearch}
        showPagination={showPagination}
        advancedFilters={advancedFilters}
        onSelectedChange={onSelectedChange}
        onChangeSearch={setSearchNameInput}
        noSearch={noFilters || hideListSearch}
        allowInlinePropertySelection={false}
        listToolbarAdditionalLeftContent={listToolbarAdditionalLeftContent}
        listFooterAdditionalRightContent={listFooterAdditionalRightContent}
        listToolbarFilterWrapperStyles={{
          width: '100%',
        }}
        disabledItemsTooltipHint={disabledItemsTooltipHint}
        notFoundProps={{
          message: (
            <FormattedMessage id="module-goldenRecordList-noEntitisFoundMsg" />
          ),
          explanation: (
            <FormattedMessage id="module-goldenRecordList-noEntitisFoundExpl" />
          ),
          noCreate: true,
        }}
        noSearchFoundProps={{
          message: (
            <FormattedMessage id="module-goldenRecordList-noEntitisSearchFoundMsg" />
          ),
          explanation: (
            <FormattedMessage id="module-goldenRecordList-noEntitisSearchFoundExpl" />
          ),
          noCreate: true,
        }}
      />

      {customPaging && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            background: '#fff',
            borderTop: '1px solid hsla(0,0%,88%,1)',
          }}
        >
          <div style={{ lineHeight: 1 }}>
            <b style={{ marginRight: 10 }}>
              <FormattedMessage id="module-goldenRecordList-paging-total" />{' '}
              {total}
            </b>

            <TooltipHost
              content={
                <FormattedMessage id="module-goldenRecordList-paging-more-10k" />
              }
              id={tooltipId}
              calloutProps={{ gapSpace: 0 }}
            >
              <Icon
                iconName="Warning"
                styles={{ root: { color: 'rgb(242, 182, 0)', fontSize: 18 } }}
              />
            </TooltipHost>
          </div>

          <PrimaryButton
            rounded
            loading={loading}
            onClick={() => !loading && loadMore()}
            style={{ paddingRight: 32, paddingLeft: 32, gap: 8 }}
          >
            {loading && <Loader color="#fff" />}
            <span style={{ margin: loading ? '0px 0px 2px 4px' : 0 }}>
              <FormattedMessage id="module-goldenRecordList-paging-loadMore" />
            </span>
          </PrimaryButton>
        </div>
      )}
    </>
  );
};

export default memo(RecordList);
