import { useEffect } from 'react';
import { List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { capitalize } from 'lodash';
import Alert from 'uxi/Alert/Alert';
import { allEntityProperties } from './columnConfiguration';

const EntityPropertyName = ({ property, description, displayName }) => {
  return (
    <div style={{ padding: '6px' }}>
      <div style={{ color: '#000' }}>
        {displayName ? displayName : capitalize(property)}
      </div>
      <div style={{ fontSize: '12px', color: '#ccc' }}>{description}</div>
    </div>
  );
};

const AddEntityProperty = ({
  onEntityPropertyChange,
  selectedEntityProperty,
  currentNumberColumns,
  maxNumberColumns,
  entityKeys,
  onEntityPropertyCancel,
  addEntityPropOffsetTop = 1,
}) => {
  const keys =
    entityKeys && entityKeys.length > 0 ? entityKeys : allEntityProperties;

  const data = keys.filter((p) => !p.fixed);
  const maxedOut = currentNumberColumns > maxNumberColumns;

  useEffect(() => {
    return () => {
      onEntityPropertyCancel?.();
    };
  }, []);

  return (
    <>
      {maxedOut && (
        <Alert type="warning">
          <FormattedMessage
            id="module-dataCatalog-cannotSelectMore"
            values={{
              maxNumberColumns,
            }}
          />
        </Alert>
      )}
      <List
        dataGridName="entity-property-selection"
        selectable
        multiSelectable
        creationDisabled={true}
        showPagination={false}
        data={data}
        withToolbar={false}
        offsetTop={addEntityPropOffsetTop}
        selected={selectedEntityProperty}
        onSelectedChange={onEntityPropertyChange}
        propertyKey="property"
        columns={[
          {
            displayName: (
              <FormattedMessage
                id="module-dataCatalog-propertyName"
                values={{
                  maxNumberColumns,
                }}
              />
            ),
            property: 'property',
            Component: EntityPropertyName,
          },
        ]}
      />
    </>
  );
};

export default AddEntityProperty;
