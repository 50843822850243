import { Button, withWorkflow } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { Datacleaning } from 'uxi/Icons';
import store from 'store';
import qs from 'query-string';

import CleanGqlQueryWizzard from './CleanGqlQueryWizzard';

import { hasFeature } from '../../../../../config';
import { cleanProjectRootURL } from '../../../../cleanProject/module';

const CleanGqlQueryDialog = ({ gqlOptions, openWorkflow }) => {
  if (!hasFeature('cluedinClean')) {
    return null;
  }

  const isNewCleanActivated = store.get('FeatureFlag')?.['New Clean Project'];
  const allFilters = gqlOptions?.variables?.filters;

  const entityType = allFilters
    ?.find?.((f) => f.aggregationName === 'entityType')
    ?.values?.join?.(',');
  // In case providers feature will be needed
  // const providerFilter = (allFilters?.find?.(f => f.aggregationName === 'providers'))?.values?.[0];

  const searchOptions = qs.stringify({
    entityType,
    createOpened: true,
  });

  const newCleanUrl = `${cleanProjectRootURL}?${searchOptions}`;

  return isNewCleanActivated ? (
    <Button
      target="_blank"
      href={newCleanUrl}
      startIcon={<Datacleaning size={16} />}
      text={<FormattedMessage id="module-clean-cleanButton" />}
    />
  ) : (
    <Button
      startIcon={<Datacleaning size={16} />}
      text={<FormattedMessage id="module-clean-cleanButton" />}
      onClick={() => {
        openWorkflow();
      }}
    />
  );
};
export default withWorkflow(CleanGqlQueryDialog, CleanGqlQueryWizzard, {
  title: 'Setup your clean project',
  panelType: 'large',
});
