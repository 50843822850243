import React from 'react';
import { compose /* , lifecycle */ } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import { connect } from 'react-redux';
import ResetPwdForm from '../composites/ResetPwdForm';
import {
  withInvalid,
  withSuccess,
} from '../../../core/components/Hocs/defaults';
import { shouldRequestPwdReset } from '../../actions/resetPwd';

const EnhancedResetPwdContainer = compose(
  withInvalid({
    InvalidEntityMessage: (
      <div>
        <FormattedMessage id="module-public-forgotPwd-ForgotPwdGenericError" />
      </div>
    ),
  }),
  withSuccess({
    SuccessMessage: (
      <div>
        <FormattedMessage id="module-public-resetPwd-success" />
      </div>
    ),
  }),
)(ResetPwdForm);

const mapToStateProps = ({ resetPwd: { isFetching, error, done } }) => ({
  isMutating: isFetching,
  done,
  error,
});

const mapDispatchToStateProps = (dispatch, { invitationId }) => ({
  onResetPassword(values) {
    dispatch(
      shouldRequestPwdReset({
        ...values,
        code: invitationId,
      }),
    );
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToStateProps,
)(EnhancedResetPwdContainer);
