import { useState, useEffect } from 'react';
import filter from 'lodash/filter';
import { FormattedMessage } from '@cluedin/locale';

import VocabOrPropertySelectionList from './VocabOrPropertySelectionList';
import { withDialogWorkflow } from '../../../../core/components/Hocs/workflows/withDialogWorkflow';
import AddEntityProperty from './AddEntityProperty';
import AddVocabulary from './AddVocabulary';

const EnhancedColumnSelectionList = withDialogWorkflow(
  VocabOrPropertySelectionList,
  AddEntityProperty,
  {
    dialogName: 'entityPropertyDialog',
    title: <FormattedMessage id="module-dataCatalog-choose-property-keys" />,
    mainButtonLabel: <FormattedMessage id="module-dataCatalog-add" />,
    bodyStyles: {
      padding: 0,
    },
    methodName: 'onAddEntityProperty',
    width: '90vh',
    height: '85vh',
  },
);

const EnhancedColumnSelectionListWithVocabulary = withDialogWorkflow(
  EnhancedColumnSelectionList,
  AddVocabulary,
  {
    dialogName: 'vocabularyPropertyDialog',
    title: <FormattedMessage id="module-dataCatalog-choose-vocab-keys" />,
    methodName: 'onAddVocabulary',
    mainButtonLabel: <FormattedMessage id="module-dataCatalog-add" />,
    style: {
      maxWidth: '800px',
    },
    bodyStyles: {
      padding: 0,
    },
    width: '90vh',
    height: '85vh',
  },
);

export const VocabOrPropertySelectionWithDialogs = ({
  columns = [],
  onAddColumns,
  onDeleteColumns,
  onChangeColumns,
  maxNumberColumns,
  excludePropertyKeys,
  entityKeys = [],
  disabled,
  noEntityProperties,
  onlyFunctionality,
}) => {
  const [selectedVocabulary, setSelectedVocabulary] = useState([]);
  const [selectedEntityProperty, setSelectedProperty] = useState([]);

  useEffect(() => {
    const entityProps = filter(columns, (p) => p.type === 'entity').map(
      (p) => p.property,
    );

    const vocabularyProps = filter(columns, (p) => p.type === 'vocabulary').map(
      (p) => p.property,
    );

    setSelectedProperty(entityProps);
    setSelectedVocabulary(vocabularyProps);
  }, [columns, setSelectedProperty]);

  const currentNumberColumns =
    (selectedVocabulary || []).length + (selectedEntityProperty || []).length;

  const onMainButtonClick = (e, { dialogName, onClose }) => {
    if (e.keyCode && e.keyCode === 13) {
      return;
    }

    if (dialogName === 'vocabularyPropertyDialog') {
      onAddColumns(selectedVocabulary, 'vocabulary');
    }

    if (dialogName === 'entityPropertyDialog') {
      setSelectedProperty(selectedEntityProperty);
      onAddColumns(selectedEntityProperty, 'entity');
    }

    onClose();
  };

  return (
    <EnhancedColumnSelectionListWithVocabulary
      disabled={disabled}
      onlyFunctionality={onlyFunctionality}
      noEntityProperties={noEntityProperties}
      entityKeys={entityKeys}
      columns={columns}
      currentNumberColumns={currentNumberColumns}
      maxNumberColumns={maxNumberColumns}
      excludePropertyKeys={excludePropertyKeys}
      onChangeColumns={onChangeColumns}
      selectedVocabulary={selectedVocabulary}
      selectedEntityProperty={selectedEntityProperty}
      onMainButtonClick={onMainButtonClick}
      onDeleteColumns={onDeleteColumns}
      onEntityPropertyChange={(selected) => {
        setSelectedProperty(selected || []);
      }}
      onVocabularySelectedChange={(selected) => {
        setSelectedVocabulary(selected || []);
      }}
      onDeleteColumn={(deletedColumn) => {
        onDeleteColumns([deletedColumn]);
      }}
    />
  );
};

export default VocabOrPropertySelectionWithDialogs;
