"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(FormFieldControlLabel) {
  return (0, _styledComponents.default)(FormFieldControlLabel)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: inline-flex;\n  align-items: center;\n  cursor: pointer;\n  // For correct alignment with the text.\n  vertical-align: middle;\n  -webkit-tap-highlight-color: transparent;\n  margin-left: -11px;\n  margin-right: 16px; // used for row presentation of radio/checkbox\n  &.disabled {\n    cursor: default;\n  }\n  .labelStart {\n    flex-direction: row-reverse;\n    margin-left: 16; // used for row presentation of radio/checkbox\n    margin-right: -11;\n  }\n  .labelTop {\n    flex-direction: column-reverse;\n    margin-left: 16px;\n  }\n  .labelBottom {\n    flex-direction: column;\n    margin-left: 16pxl;\n  }\n  &.disabled .label.disabled {\n    color: ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.text.disabled;
  });
};
exports.default = _default;