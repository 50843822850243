import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { Catalog } from './fragments';

export const GET_ALL_VOCABULARIES_KEYS = gql`
  query getAllVocabularyKeys(
    $searchName: String
    $pageNumber: Int
    $pageSize: Int
    $dataType: String
    $classification: String
    $connectorId: ID
    $isCluedInCore: Boolean
    $isDynamic: Boolean
    $filterIsObsolete: String
    $isProvider: Boolean
    $hasNoProvider: Boolean
    $filterTypes: Int
    $filterHasNoSource: Boolean
  ) {
    management {
      id
      vocabularyKeys(
        searchName: $searchName
        pageNumber: $pageNumber
        pageSize: $pageSize
        dataType: $dataType
        classification: $classification
        connectorId: $connectorId
        isCluedInCore: $isCluedInCore
        isDynamic: $isDynamic
        filterIsObsolete: $filterIsObsolete
        isProvider: $isProvider
        hasNoProvider: $hasNoProvider
        filterTypes: $filterTypes
        filterHasNoSource: $filterHasNoSource
      ) {
        total
        data {
          ...VocabularyKey
        }
      }
    }
  }
  ${Catalog.fragments.vocabularyKey}
`;

export const useVocabularyKeys = (
  initialVar = {},
  withCaching,
  skip = false,
) => {
  const { data, error, refetch, networkStatus, loading } = useQuery(
    GET_ALL_VOCABULARIES_KEYS,
    {
      skip,
      fetchPolicy: withCaching ? 'cache-first' : 'no-cache',
      variables: {
        ...initialVar,
        filterIsObsolete: 'All',
      },
    },
  );

  const pagedVocabularies = get(data, 'management.vocabularyKeys', {
    total: 0,
    data: [],
  });

  return [
    pagedVocabularies,
    loading,
    error,
    networkStatus === 4,
    (params) => {
      refetch(
        params ?? {
          ...initialVar,
          filterIsObsolete: 'All',
        },
      );
    },
  ];
};

const GET_VOCABULARIES_KEYS_BY_VOCAB_ID = gql`
  query getVocabularyKeysFromVocabularyId(
    $id: ID!
    $searchName: String
    $dataType: String
    $classification: String
    $filterIsObsolete: String
    $skipFilterVisibility: Boolean
  ) {
    management {
      id
      vocabularyKeysFromVocabularyId(
        id: $id
        searchName: $searchName
        dataType: $dataType
        classification: $classification
        filterIsObsolete: $filterIsObsolete
        skipFilterVisibility: $skipFilterVisibility
      ) {
        total
        data {
          ...VocabularyKey
        }
      }
    }
  }
  ${Catalog.fragments.vocabularyKey}
`;

export const useVocabularyKeysFromVocabId = (
  id,
  filters = {},
  withCaching,
  skip = false,
) => {
  const { data, error, refetch, networkStatus } = useQuery(
    GET_VOCABULARIES_KEYS_BY_VOCAB_ID,
    {
      skip,
      fetchPolicy: withCaching ? 'cache-first' : 'no-cache',
      variables: {
        id,
        ...filters,
        filterIsObsolete: 'All',
      },
    },
  );

  const pagedVocabularies = get(
    data,
    'management.vocabularyKeysFromVocabularyId',
    { total: 0, data: [] },
  );

  return [
    pagedVocabularies,
    networkStatus === 1,
    error,
    networkStatus === 4,
    (params) => {
      refetch(
        params ?? {
          ...filters,
          filterIsObsolete: 'All',
        },
      );
    },
  ];
};

export default {
  useVocabularyKeys,
  useVocabularyKeysFromVocabId,
};
