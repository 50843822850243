import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import { PageStructureContainer } from '../modules/core/components/composites/PageStructure';
import BreadCrumbContainer from './BreadCrumbContainer';
import PillarBackButton from './PillarBackButton';
import { PillarIcon } from './composites/BreadCrumb';
import { BreadCrumbsElementWrapper } from './BreadCrumbsElementWrapper';
import HelpContextual, {
  HelpContext,
} from '../modules/help/components/containers/HelpContextual';
import { OrganizationFeatureFlag } from '../modules/featureFlag/hooks/useOrganizationFeatureFlag';
import { useIntl } from 'react-intl';

const DefaultPillarWrapper = ({
  Icon,
  path,
  name,
  children,
  crumbPath,
  displayName,
  help = false,
}) => {
  const { formatMessage } = useIntl();
  const [helpContext, setHelpContext] = useState({
    topicId: name,
    type: 'pillar',
  });

  let titleExtra;

  const tryingToGetIdFromComponent = displayName?.props?.id;

  if (tryingToGetIdFromComponent) {
    const translation = formatMessage({ id: tryingToGetIdFromComponent });

    if (translation) {
      titleExtra = ` - ${translation}`;
    }
  }

  return (
    <>
      <Helmet title={`CluedIn${titleExtra}`} />
      <HelpContext.Provider value={[helpContext, setHelpContext]}>
        <BreadCrumbsElementWrapper
          icon={
            <div
              style={{
                display: 'flex',
                alignItem: 'center',
                paddingLeft: '12px',
              }}
            >
              <PillarBackButton style={{ marginRight: '12px' }} />

              <Link to={crumbPath || path}>
                <PillarIcon Icon={Icon} />
              </Link>
            </div>
          }
          title={
            <BreadCrumbContainer
              pillarPath={path}
              crumbPath={crumbPath}
              pillarName={displayName}
              pillarId={name}
            />
          }
          help={
            help ? (
              <OrganizationFeatureFlag
                MainFeature={null}
                ExperimentalFeature={
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '16px',
                      }}
                    >
                      <HelpContextual helpContext={helpContext} />
                    </div>
                  </>
                }
                featureName={'Inline Help'}
              />
            ) : null
          }
        />
        <PageStructureContainer>{children}</PageStructureContainer>
      </HelpContext.Provider>
    </>
  );
};

export default withRouter(DefaultPillarWrapper);
