"use strict";

exports.__esModule = true;
exports.default = void 0;
var _apolloLinkError = require("apollo-link-error");
// import { fromPromise } from 'apollo-link';
// import { getNewToken } from '../../modules/core/helpers/token';
// import { getStore } from '../../components/SetupApp';
// import { generalSessionExpired } from '../../modules/error/actions';
/**
 *           // await refreshToken, then call its link middleware again
           return fromPromise(
              getNewToken().then(resp => {
                if(!resp) {
                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                  const store = getStore();
                  store.dispatch(
                    generalSessionExpired({
                      params: {},
                      errorMessage: "Could not refresh during a GQL call",
                      url: '',
                      status: 401,
                    })
                  );
                  return;
                }
                return resp;
              })
            ).filter(value => Boolean(value)).flatMap(() => forward(operation));
 */
var errorLink = function errorLink(_ref) {
  var on401 = _ref.on401;
  return (0, _apolloLinkError.onError)(function (_ref2) {
    var networkError = _ref2.networkError,
      graphQLErrors = _ref2.graphQLErrors,
      operation = _ref2.operation,
      forward = _ref2.forward;
    if (networkError) {
      switch (networkError.statusCode) {
        case 401:
          console.warn('Refreshing token and trying again');
          if (on401) {
            on401({
              networkError: networkError,
              graphQLErrors: graphQLErrors,
              operation: operation,
              forward: forward
            });
          }
          break;
        default:
          {
            // nothing
          }
        // Handle all other errors here. Irrelevant here.
      }
    }

    if (graphQLErrors) {
      // Handle gql errors, irrelevant here.
    }
  });
};
var _default = errorLink;
exports.default = _default;