import { createAction } from 'redux-actions';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  defaultAsyncCallWithActionsWithParam,
} from '../../action/helpers/actionCreator';
import {
  getDuplicates,
  getDuplicatePerEntityTypes,
  getDuplicatesWidgets,
  getEntitiesFromDuplicateQuery,
  getDuplicatesQueries,
} from './data';

export const duplicatedActions = createAsyncAction('GET_DUPLICATES');

export const shouldFetchDuplicates = defaultAsyncCallWithActions(
  getDuplicates,
  duplicatedActions,
);

export const selectdDuplicate = createAction('OPEN_SELECTED_DUPLICATED');
export const clearSelectedDuplicate = createAction('CLOSE_SELECTED_DUPLICATED');

export const selectSchema = createAction('OPEN_SELECTED_SCHEMA');
export const clearSelectedSchema = createAction('CLOSE_SELECTED_SCHEMA');

export const duplicatePerEntityTypeActions = createAsyncAction(
  'GET_DUPLICATES_PER_ENTITY_TYPE',
);

export const shouldFetchDuplicatesPerEntityTypes = defaultAsyncCallWithActions(
  getDuplicatePerEntityTypes,
  duplicatePerEntityTypeActions,
);

export const duplicatesGroupActions = createAsyncAction(
  'GET_DUPLICATES_PER_GROUP',
);
export const shouldFetchAllDuplicatesGroups = defaultAsyncCallWithActions(
  getDuplicatesWidgets,
  duplicatesGroupActions,
);

export const duplicateGroupActionsQueries = createAsyncAction(
  'GET_DUPLICATES_QUERIES',
);

export const shouldFetchAllDuplicatesQueries = defaultAsyncCallWithActions(
  getDuplicatesQueries,
  duplicateGroupActionsQueries,
);

export const fetchEntityFromDuplicateQueryActions = createAsyncAction(
  'GET_ENTITIES_FROM_DUPLICATE_QUERY',
);
export const shouldFetchEntityFromQuery = defaultAsyncCallWithActionsWithParam(
  getEntitiesFromDuplicateQuery,
  fetchEntityFromDuplicateQueryActions,
);

export const fetchMoreEntityFromDuplicateQueryActions = createAsyncAction(
  'GET_MORE_ENTITIES_FROM_DUPLICATE_QUERY',
);
export const shouldFetchMoreEntityFromQuery =
  defaultAsyncCallWithActionsWithParam(
    getEntitiesFromDuplicateQuery,
    fetchMoreEntityFromDuplicateQueryActions,
  );
