import { css } from 'styled-components';

export const socials = css`
  .cluedIn_social {
    width: 100%;
  }
  .cluedIn_social ul {
    margin: 0;
    padding: 0;
  }
  .cluedIn_social ul li {
    display: inline-block;
    margin: 0 5px 0 0;
  }

  ul.cluedIn_social_overview ul {
    margin: 0;
    padding: 0;
  }
  ul.cluedIn_social_overview li[class*='cluedIn_social_overview'] {
    line-height: 40px;
    padding: 0 10px;
    border-bottom: 1px solid #d2d2d2;
    font-weight: bold;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_header {
    font-weight: bold;
    font-size: 16px;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_header.twitter {
    background-color: #3ea1ec;
    color: white;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_header.facebook {
    background-color: #344e86;
    color: white;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_header.linkedIn {
    background-color: #00669b;
    color: white;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_header i {
    margin-right: 10px;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_number_indicator {
    float: right;
    font-size: 18px;
    margin-right: 10px;
    /* FA is not anymore anyway */
    /* i.fa.fa-arrow-circle-up {
      color: #06979e;
    }
    i.fa.fa-arrow-circle-down {
      color: red;
    } */
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_number {
    float: right;
    font-size: 20px;
    color: black;
    margin-right: 10px;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_number_prev {
    display: block;
    float: right;
    width: 60px;
    margin-top: 7px;
    text-align: center;
    color: #999;
    padding-top: 0;
    font-weight: lighter;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_number_prev_number {
    display: block;
    line-height: 14px;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_number_prev_period {
    display: block;
    margin-top: 2px;
    font-size: 10px;
    line-height: 10px;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_twitter_followers {
    border-left: 10px solid #55acee;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_twitter_following {
    border-left: 10px solid #83c3f3;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_twitter_tweets {
    border-left: 10px solid #83c3f3;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_facebook_likes {
    border-left: 10px solid #3b5998;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_facebook_messages {
    border-left: 10px solid #4c70ba;
  }
  ul.cluedIn_social_overview .cluedIn_social_overview_linkedin_followers {
    border-left: 10px solid #0077b5;
  }
`.join('');

export default socials;
