import React from 'react';
import { Externallink } from 'uxi/Icons';
import Flex from 'uxi/Layout/Flex';
import FlexExtended from './FlexExtended';
import Section from './Section';
import EntityExternalLinkContainer from '../../../../wms/components/containers/EntityExternalLinkContainer';

const Entities = (props) => {
  const { jsonReport } = props;

  const { EntityTypeDetails } = jsonReport;

  const groupedByTypes = {};

  (EntityTypeDetails || []).forEach(($values) => {
    const entityType = $values[0] && $values[0].EntityType;

    groupedByTypes[entityType] = $values;
  });

  return (
    <Section jsonReport={jsonReport}>
      <h2>Entities</h2>
      {Object.keys(groupedByTypes).map((k) => (
        <div style={{ marginBottom: '32px' }}>
          <div>
            <h4>{k}</h4>
          </div>
          {groupedByTypes[k].map(
            ({ Name, Id, Author, Url: UrlMaybe, ActionDate, EntityType }) => {
              const Url =
                UrlMaybe && UrlMaybe !== 'Unknown Url' ? UrlMaybe : null;

              return (
                <FlexExtended
                  style={{
                    marginLeft: '16px',
                  }}
                >
                  <span style={{ margin: '16px 0' }}>
                    <div> {Name} </div>
                    <div>
                      {Author && `by ${Author} `}
                      {ActionDate && ` on ${ActionDate} `}
                    </div>
                    <div />
                  </span>
                  <Flex className="no-print">
                    {Url ? (
                      <small style={{ display: 'block', marginLeft: '34px' }}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={Url}
                          alt={`open entity ${Name}`}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Externallink size={12} color={'#26a29a'} />
                          &nbsp;
                          {'view original'}
                        </a>
                      </small>
                    ) : null}
                    {Id && EntityType ? (
                      <small
                        className="no-print"
                        style={{ display: 'block', marginLeft: '34px' }}
                      >
                        <EntityExternalLinkContainer
                          id={Id}
                          entityType={EntityType}
                          alt={`open entity ${Name}`}
                        >
                          <Externallink size={12} color={'#26a29a'} />
                          &nbsp;
                          {'view entity'}
                        </EntityExternalLinkContainer>
                      </small>
                    ) : null}
                  </Flex>
                  <br />
                  <br />
                </FlexExtended>
              );
            },
          )}
        </div>
      ))}
      <hr />
    </Section>
  );
};

export default Entities;
