import PropTypes from 'prop-types';
import styled from 'styled-components';

const BreadCrumbUI = styled.div`
  padding: 0 4px;
`;

const BreadCrumbItem = ({ children, ...attrs }) => {
  return <BreadCrumbUI {...attrs}>{children}</BreadCrumbUI>;
};

BreadCrumbItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    }),
  ]).isRequired,
};

export default BreadCrumbItem;
