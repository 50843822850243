import React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
import { Flex } from 'uxi/Layout';
import { buttonResetStylesCSSString } from 'uxi/Button/buttonResetStyles';
import RelativeTextPlaceholder from '../Placeholder/RelativeTextPlaceholder';

export const SearchSuggestionButton = styled.button`
  ${buttonResetStylesCSSString};
  text-align: left;
  width: 100%;
  height: 32px;
  padding: 2px 8px;
  &:focus,
  &:hover {
    background: #cecece;
  }
  em {
    font-weight: bold;
  }
`;

const SuggestionsLoader = ({ style }) => (
  <Flex style={{ justifyContent: 'flex-start', marginLeft: '8px', ...style }}>
    <SearchSuggestionButton style={{ paddingLeft: 0 }}>
      <RelativeTextPlaceholder style={{ width: '80px' }} />
    </SearchSuggestionButton>
  </Flex>
);

export default pure(SuggestionsLoader);
