import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Drawer from 'uxi/Drawer';
import Panel, { PanelHeader } from 'uxi/Panel';
import IntegrationNameWithLogoFromIdContainer from '../../../../integration/components/containers/IntegrationNameWithLogoFromIdContainer';
import ConfigurationOverviewComposite from '../ConfigurationOverviewComposite';
import { withIntegrationConfigFromConfiguration } from '../../../../integration/components/hocs/withIntegrationConfig';

const ConfigurationQuickView = ({
  open,
  onClose,
  configuration,
  play,
  pause,
  approve,
  reProcess,
  reject,
  reAuth,
  viewSetting,
  viewPermissions,
  isLoadingPlay,
  isLoadingPause,
  isLoadingApprove,
  isLoadingReject,
  currentUser,
  setProductOwner,
  fetchProductOwner,
}) => {
  const titleContent = configuration ? (
    <IntegrationNameWithLogoFromIdContainer
      integrationId={configuration.ProviderId}
    />
  ) : (
    <FormattedMessage id="module-configuration-selectConfiguration" />
  );

  const content = configuration ? (
    <ConfigurationOverviewComposite
      configuration={configuration}
      play={play}
      pause={pause}
      approve={approve}
      reject={reject}
      reAuth={reAuth}
      reProcess={reProcess}
      viewSetting={viewSetting}
      viewPermissions={viewPermissions}
      isLoadingPlay={isLoadingPlay}
      isLoadingPause={isLoadingPause}
      isLoadingApprove={isLoadingApprove}
      isLoadingReject={isLoadingReject}
      currentUser={currentUser}
      setProductOwner={setProductOwner}
      fetchProductOwner={fetchProductOwner}
    />
  ) : (
    <div>
      <FormattedMessage id="module-configuration-noConfigurationSelected" />
    </div>
  );

  return (
    <Drawer offsetTop="48px" open={open}>
      <Panel onClose={onClose}>
        <PanelHeader hasClose title={titleContent} />
        <div>{content}</div>
      </Panel>
    </Drawer>
  );
};

export default withIntegrationConfigFromConfiguration(ConfigurationQuickView);
