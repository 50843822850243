import React, { Fragment } from 'react';
import { FormattedMessage } from '@cluedin/locale';

const DefaultSelectionWhenNoneSelected = ({ defaultFields }) => {
  if (!defaultFields || defaultFields.length === 0) {
    return <span />;
  }

  return (
    <Fragment>
      <div>
        <FormattedMessage id="module-schema-byDefaultFollowingFieldsDisplayed" />
      </div>
      <ul style={{ marginTop: '6px' }}>
        {defaultFields.map((f) => {
          return <li>- {f.field}</li>;
        })}
      </ul>
    </Fragment>
  );
};

export default DefaultSelectionWhenNoneSelected;
