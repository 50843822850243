import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import {
  TableHeaderColumn,
  Table,
  TableRow,
  TableHeader,
  TableBody,
} from '@cluedin/list-pattern';
import { Form } from 'react-redux-form';
// import Alert from 'uxi/Alert';
import EntityReviewPropertiesResolverRow from './EntityReviewPropertiesResolverRow';

export const dotRep = '•';
export const encodeRRFKey = (k) => k.replace(/\./gi, dotRep);
export const decodeRRFKey = (k) => k.replace(`/${dotRep}/gi`, '.');

const styles = {
  wrapper: {
    padding: '16px',
  },
  buttonWrapper: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

// eslint-disable-next-line  react/prefer-stateless-function
class EntityReviewPropertiesResolver extends Component {
  /*
  constructor(props) {
    super(props);
    this.state = {
      submitConfirmed: false,
    };
  }

  handleSubmit() {
    const {
      onSubmit,
      ids,
      targetId,
      propsDelta,
    } = this.props;

    const formattedPropsDelta = Object.keys(propsDelta)
      .filter(k => k !== '$form')
      .map(key => ({
        Id: propsDelta[key.replace(`/${dotRep}/gi`, '.')].value,
        PropertyName: key,
      }));

    if (onSubmit) {
      onSubmit({
        ids,
        targetId,
        delta: formattedPropsDelta,
      });
    }

    this.setState({
      submitConfirmed: true,
    });
  }


  renderNoConflictMessage() {
    const { errors, conflictedProperties, isDone } = this.props;

    let noConflictMessage = null;
    if (conflictedProperties
      && conflictedProperties.length === 0
      && errors.length === 0
      && !isDone
    ) {
      noConflictMessage = (
        <Alert type="success">
          <FormattedHTMLMessage
            id="mergeNoConflict"
          />
        </Alert>
      );
    }

    return noConflictMessage;
  }

  renderErrorContent() {
    const {
      isSubmitting,
      errors,
      isDone,
    } = this.props;

    return errorContent;
  }
  */

  render() {
    const {
      conflictedProperties,
      selectedEntities,
      // isSubmitting,
      // isDone,
      // setCurrentStep,
    } = this.props;

    // const errorContent = this.renderErrorContent();
    // const noConflictMessage = this.renderNoConflictMessage();

    return (
      <div style={styles.wrapper}>
        {/* {errorContent}
        {noConflictMessage} */}
        <Form key="theForm" model="propsDelta">
          <div style={{ border: '1px solid #cecece', borderTop: 'none' }}>
            <div
              style={{
                position: 'sticky',
                top: '116px',
                zIndex: 1,
                borderTop: '1px solid #cecece',
              }}
            >
              <Table>
                <TableHeader>
                  <TableRow style={{ background: '#f3f3f3' }}>
                    <TableHeaderColumn>
                      <FormattedMessage id="module-entityMerge-propertyName" />
                    </TableHeaderColumn>
                    <TableHeaderColumn>
                      <FormattedMessage id="module-entityMerge-value" />
                    </TableHeaderColumn>
                  </TableRow>
                </TableHeader>
              </Table>
            </div>
            <Table>
              <TableBody>
                {conflictedProperties &&
                  conflictedProperties.map((conflictedProp, i, collection) => {
                    return (
                      <EntityReviewPropertiesResolverRow
                        key={conflictedProp.key || conflictedProp.displayName}
                        propertyKey={conflictedProp.key}
                        model={`propsDelta.${conflictedProp.displayName}`}
                        conflictedProp={conflictedProp}
                        selectedEntities={selectedEntities}
                        isLast={i === collection.length - 1}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>

          {/* {(conflictedProperties.length > 3) && (
            <div>
              { errorContent }
              {noConflictMessage}
            </div>
          )} */}
          {/*
          <div style={styles.buttonWrapper}>
            {isSubmitting
              ? <MiniLoader size={32} style={{ padding: '0 8px' }} key="theMiniLoading" />
              : null
            }
            <Button
              key="theSubmitButton"
              type="primary"
              confirmText={
                <FormattedMessage
                  id="merge-entities-areYouSure"
                  defaultMessage="Merging is irreversible, are you sure ?"
                />
              }
              onClick={this.handleSubmit.bind(this)}
              onClick={() => setCurrentStep(2)}
              icon={<Merge />}
              text={
                <FormattedMessage id="ReviewMerge" defaultMessage={'Review changes'} />
              }
              disabled={isDone || isSubmitting}
            />
          </div>
          */}
        </Form>
      </div>
    );
  }
}

export default EntityReviewPropertiesResolver;
