import React, { useState, useMemo } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { useDatabaseTestConnection } from './useDatabaseTestConnection';
import { useCreateDataSource } from './useCreateDataSource';
import usePrevious from '../../core/hooks/usePrevious';
import { isEmpty } from '../../core/validation';
import { NotEmptyErrorMessage } from '../../core/validation/messages';

export const validateConfiguration = (configuration) => {
  const errors = {};

  if (!configuration.dialect) {
    errors.dialect = <FormattedMessage id="data-source-choose-database" />;
  }

  if (configuration.dialect && isEmpty(configuration.dialect)) {
    errors.dialect = <NotEmptyErrorMessage />;
  }

  if (!configuration.database) {
    errors.database = <FormattedMessage id="data-source-validation-database" />;
  }

  if (configuration.database && isEmpty(configuration.database)) {
    errors.database = <NotEmptyErrorMessage />;
  }

  if (!configuration.username) {
    errors.username = <FormattedMessage id="data-source-validation-username" />;
  }

  if (configuration.username && isEmpty(configuration.username)) {
    errors.username = <NotEmptyErrorMessage />;
  }

  if (!configuration.host) {
    errors.host = <FormattedMessage id="data-source-validation-host" />;
  }

  if (configuration.host && isEmpty(configuration.host)) {
    errors.host = <NotEmptyErrorMessage />;
  }

  return errors;
};

export const useDataBaseConfiguration = ({ isSaving, dataSourceSetId }) => {
  const [shouldGoNext, setShouldGoNext] = useState();
  const [configuration, setConfiguration] = useState({});
  const [dataSourceName, setDataSourceName] = useState('');
  const [configurationTouched, setConfigurationTouched] = useState(false);

  const configurationsErrors = useMemo(
    () => validateConfiguration(configuration),
    [configuration],
  );

  const isValidConfiguration = useMemo(
    () => Object.keys(configurationsErrors || {}).length === 0,
    [configurationsErrors],
  );

  const [
    testConnection,
    {
      data: testConnectionResult,
      loading: loadingTestConnectionResult,
      error: errorConnectionResult,
    },
  ] = useDatabaseTestConnection();

  const [
    createDataSource,
    {
      data: dataSource,
      loading: loadingCreateDataSource,
      error: errorCreateDataSource,
    },
  ] = useCreateDataSource();

  const isProgressing = useMemo(
    () => loadingCreateDataSource || isSaving,
    [loadingCreateDataSource, isSaving],
  );
  const prevDataSourceSetId = usePrevious(dataSourceSetId);
  const prevDataSourceId = usePrevious(dataSource?.id);

  return useMemo(
    () => ({
      dataSource,
      shouldGoNext,
      configuration,
      isProgressing,
      testConnection,
      dataSourceName,
      setShouldGoNext,
      createDataSource,
      prevDataSourceId,
      setConfiguration,
      setDataSourceName,
      prevDataSourceSetId,
      configurationsErrors,
      testConnectionResult,
      isValidConfiguration,
      configurationTouched,
      errorConnectionResult,
      errorCreateDataSource,
      loadingCreateDataSource,
      setConfigurationTouched,
      loadingTestConnectionResult,
      dataSourceId: dataSource?.id,
    }),
    [
      dataSource,
      shouldGoNext,
      configuration,
      isProgressing,
      testConnection,
      setShouldGoNext,
      dataSourceName,
      setConfiguration,
      prevDataSourceId,
      createDataSource,
      setDataSourceName,
      prevDataSourceSetId,
      configurationsErrors,
      testConnectionResult,
      isValidConfiguration,
      configurationTouched,
      errorConnectionResult,
      errorCreateDataSource,
      loadingCreateDataSource,
      setConfigurationTouched,
      loadingTestConnectionResult,
    ],
  );
};
