import { createSelector } from 'reselect';
import { createPropertyVM } from '../entity/properties/selector';

/*
will need to show source (provider definitions)
use JSON.parse only in try catch
being defensive on th edata structure object of trnassfomr stirng nas raw queries
*/

const createPropertyFromTransform = (transform, schema, configurations) => {
  if (!transform) {
    return null;
  }

  return createPropertyVM(
    transform.key,
    transform.value,
    schema,
    configurations,
  );
};

const createPropertiesFromTransforms = (
  transforms = [],
  schema,
  configurations,
) =>
  transforms.map((transform) =>
    createPropertyFromTransform(transform, schema, configurations),
  );

const getSelectedEntity = (state) => state.entity.selectedEntity;
const getSensitiveFromState = (state) => state.entityActions;
const getEntityId = (state, props) => props.entityId;

export const getSensitive = createSelector(
  [getSelectedEntity, getSensitiveFromState, getEntityId],
  (entity, sensitive, entityId) => {
    const sensitiveData = sensitive.sensitive || {};
    const unsensitiveData = sensitive.unSensitive || {};

    const currentSensitive = sensitiveData[entityId];
    const currentUnSensitive = unsensitiveData[entityId];

    if (!currentSensitive && !currentUnSensitive) {
      if (entity && entity.data) {
        return !(entity.data.isSensitiveInformation === 'False');
      }
      return false;
    } else if (currentSensitive && !currentUnSensitive) {
      return currentSensitive.isSensitive;
    } else if (!currentSensitive && currentUnSensitive) {
      return !currentUnSensitive.isUnSensitive;
    } else if (currentSensitive && currentUnSensitive) {
      if (currentSensitive.timeStamp > currentUnSensitive.timeStamp) {
        return currentSensitive.isSensitive;
      }
      return !currentUnSensitive.isUnSensitive;
    }
    if (entity && entity.data) {
      return !(entity.data.isSensitiveInformation === 'False');
    }
    return false;
  },
);

export const getSensitiveLoading = createSelector(
  [getSensitiveFromState, getEntityId],
  (sensitive, entityId) => {
    const sensitiveData = sensitive.sensitive || {};
    const unsensitiveData = sensitive.unSensitive || {};

    const currentSensitive = sensitiveData[entityId];
    const currentUnSensitive = unsensitiveData[entityId];

    if (!currentSensitive && !currentUnSensitive) {
      return false;
    } else if (currentSensitive && !currentUnSensitive) {
      return currentSensitive.isFetching;
    } else if (!currentSensitive && currentUnSensitive) {
      return currentUnSensitive.isFetching;
    } else if (currentSensitive && currentUnSensitive) {
      if (currentSensitive.timeStamp > currentUnSensitive.timeStamp) {
        return currentSensitive.isFetching;
      }
      return currentUnSensitive.isFetching;
    }
    return false;
  },
);

export const toActionMessagesVM = (
  actionMessages = [],
  schema = [],
  configurations = [],
) => {
  if (
    !Array.isArray(actionMessages) ||
    !Array.isArray(schema) ||
    !Array.isArray(configurations)
  ) {
    return [];
  }

  return actionMessages
    .filter((x) => x.IsQueued !== false)
    .map((actionMessage) => {
      let transforms = null;
      let rawQuery = null;

      try {
        transforms = JSON.parse(actionMessage.TransformString);
      } catch (err) {
        transforms = null;
      }
      if (
        !transforms /*  || !transformString[0].value || !transformString[0].key */
      ) {
        return null;
      }

      try {
        rawQuery = JSON.parse(actionMessage.RawQueries);
      } catch (err) {
        rawQuery = null;
      }

      const providerDefinition = configurations.find(
        (config) => config.Id === actionMessage.ProviderDefinitionId,
      );

      return {
        id: actionMessage.Id,
        action: actionMessage.Action,
        properties: createPropertiesFromTransforms(
          transforms,
          schema,
          configurations,
        ),
        transforms,
        rawQuery: {
          source: (rawQuery || {}).Source,
          query: (rawQuery || {}).Query,
        },
        providerDefinition,
        providerDefinitionId: actionMessage.ProviderDefinitionId,
      };
    })
    .filter((x) => x);
};

export default {
  toActionMessagesVM,
};
