import React from 'react';

const FieldAdonStyle = {
  container: {
    position: 'relative',
    display: 'flex',
  },
  pre: {
    color: '#555555',
    textAlign: 'center',
    backgroundColor: '#eeeeee',
    height: '31px',
    lineHeight: '31px',
    padding: '0 16px',
    fontWeight: 'bold',
  },
  post: {
    color: '#555555',
    textAlign: 'center',
    backgroundColor: '#eeeeee',
    height: '31px',
    lineHeight: '31px',
    padding: '0 16px',
    flex: 1,
    fontWeight: 'bold',
  },
};

const FormFieldAddon = ({ pre, post, children }) => (
  <div style={FieldAdonStyle.container}>
    {pre && <div style={FieldAdonStyle.pre}>{pre}</div>}
    <div style={{ flex: 3 }}>{children}</div>
    {post && <div style={FieldAdonStyle.post}>.{post}</div>}
  </div>
);

export default FormFieldAddon;
