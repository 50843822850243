import { gql, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

const GET_ENTITIES_RELATIONS_QUERY = gql`
  query GetEntitiesRelationsForNewNode($entityIds: [ID]) {
    virtualization {
      getEntitiesRelations(entityIds: $entityIds) {
        id
        name
        icon
        route
        displayName
        attributeOrigin
        codes
        isShadowEntity
        isTemporalEntity
        type
        nodeKind
        edges {
          edgeType
          direction
          entityType
          entityId
          icon
          route
          name
          attributeOrigin
          codes
          createdDate
          isShadowEntity
          isTemporalEntity
          isGrouped
          groupedEntityIds
          entityCount
          properties
          displayName
          nodeKind
        }
      }
    }
  }
`;

export type EntitiesRelationResult = {
  virtualization: {
    getEntitiesRelations: {
      id: string;
      name: string;
      icon: string;
      route: string;
      displayName: string;
      attributeOrigin: string;
      codes: string[];
      isShadowEntity: boolean;
      isTemporalEntity: boolean;
      type: string;
      nodeKind: string;
      edges: {
        edgeType: string;
        direction: string;
        entityType: string;
        entityId: string;
        icon: string;
        route: string;
        name: string;
        attributeOrigin: string;
        codes: string[];
        createdDate: string;
        isShadowEntity: boolean;
        isTemporalEntity: boolean;
        isGrouped: boolean;
        groupedEntityIds: string[] | [];
        entityCount: number;
        properties: unknown;
        displayName: string;
        nodeKind: string;
      };
    };
  };
};

export type EntitiesRelationPayload = {
  entityIds: string[];
};

export const useLazyEntitiesRelations = () => {
  const [getEntitiesRelations, { data, refetch, loading, error, fetchMore }] =
    useLazyQuery<EntitiesRelationResult, EntitiesRelationPayload>(
      GET_ENTITIES_RELATIONS_QUERY,
      {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
      },
    );

  const resultData = get(data, 'virtualization.getEntitiesRelations');

  return [
    (entityIds: string[]) => {
      return getEntitiesRelations({
        variables: {
          entityIds,
        },
      });
    },
    {
      fetchMore,
      data: resultData,
      loading,
      error,
      refetch,
    },
  ] as const;
};
