import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeader,
  TableHeaderColumn,
} from '@cluedin/list-pattern';
import { useTheme } from '@cluedin/theme';
import { Link } from 'react-router-dom';
import { Badge } from 'uxi/Badge';
import { FormattedMessage } from '@cluedin/locale';

import { isUserBlocked } from '../../../helper';
import EntityNameWithLogo from '../../../../entity/components/composites/EntityNameWithLogo';

const UserRoleVocab = ({ role }) => {
  return (
    <Badge style={{ marginRight: '5px' }} type="info">
      {role}
    </Badge>
  );
};

const UserRoles = (
  { roles }, // eslint-disable-line react/prop-types
) => (
  <span>
    {roles.map((role) => (
      <UserRoleVocab role={role} />
    ))}
  </span>
);

const UserList = (props) => {
  const {
    users,
    currentUser,
    ActionTitle,
    Action,
    extraProps,
    condensed,
    onChange,
    notSelectable,
    multiSelectable: multiSelectableProp,
    actionStyle,
  } = props;

  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  let multiSelectable;
  if (multiSelectableProp !== undefined) {
    multiSelectable = multiSelectableProp;
  } else {
    multiSelectable = !condensed;
  }

  return (
    <Table
      onChange={onChange}
      condensed={condensed}
      selectable={notSelectable ? false : condensed}
      multiSelectable={multiSelectable}
    >
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>
            <FormattedMessage id="module-user-nameLabel" />
          </TableHeaderColumn>
          <TableHeaderColumn>
            <FormattedMessage id="module-user-roleLabel" />
          </TableHeaderColumn>
          {ActionTitle ? (
            <TableHeaderColumn style={actionStyle || {}}>
              {ActionTitle}
            </TableHeaderColumn>
          ) : null}
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => {
          const isYou =
            currentUser &&
            currentUser.client.UserName === user.Account.UserName;
          return (
            <TableRow
              value={user.Account.Id}
              readOnly={(user) => isUserBlocked(user)}
              key={user.Account.Id}
            >
              <TableRowColumn>
                <Link
                  className="test_Administration_User_UserRow"
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    color: themePrimary,
                    fontSize: '14px',
                  }}
                  to={`/admin/settings/users/settings/${user.Account.Id}`}
                >
                  <EntityNameWithLogo
                    src={user.Entity ? user.Entity.logoUrl : ''}
                    name={
                      user.Entity ? user.Entity.name : user.Account.UserName
                    }
                  />
                </Link>
                {isYou && (
                  <Badge
                    type="info"
                    style={{ margin: '0 0 0 10px', display: 'inline-block' }}
                  >
                    <FormattedMessage id="module-user-thisIsYou" />
                  </Badge>
                )}
              </TableRowColumn>
              <TableRowColumn>
                {user.Account && user.Account.Roles ? (
                  <UserRoles roles={user.Account.Roles} />
                ) : (
                  <span>
                    <FormattedMessage id="module-user-userRole" />
                  </span>
                )}
              </TableRowColumn>
              {Action ? (
                <TableRowColumn style={actionStyle || {}}>
                  <Action user={user} {...extraProps} />
                </TableRowColumn>
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

UserList.displayName = 'UserList';

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  currentUser: PropTypes.object,
  ActionTitle: PropTypes.any,
  Action: PropTypes.any,
  extraProps: PropTypes.object,
  condensed: PropTypes.bool,
  onChange: PropTypes.func,
  roles: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
};

UserList.defaultProps = {
  users: [],
  currentUser: {},
  ActionTitle: null,
  Action: null,
  extraProps: {},
  condensed: false,
  onChange: () => {},
  roles: [],
};

export default UserList;
