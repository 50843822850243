import React, { Component } from 'react';
import { Radio } from 'uxi/Input';
import { Flex } from 'uxi/Layout';
import { Control } from 'react-redux-form';
import styled from 'styled-components';
import Img from 'uxi/Img';
import { withIntegrationSourceFromProperty } from '../../../integration/components/hocs/withIntegrationSourceFromProperty';
import { encodeRRFKey } from './EntityComparePropertiesResolver';

const fiftyPCInHex = '80';

const Hover = styled.div`
  width: 100%;
  &:hover {
    background: #dadada;
    background: hsla(180, 77%, 37%, 0.5);
    background: ${({ theme: { palette } }) =>
      `${palette.accent.main}${fiftyPCInHex}`}
    border-radius: 3px;
  }
`;

const styles = {
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'baseline',
    borderBottom: '1px solid #cecece',
    paddingBottom: '16px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px',
  },
  header: {
    minWidth: '180px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  label: {
    marginTop: '4px',
    display: 'flex',
  },
  radioItemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  entityName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  value: {},
  radioControlsWrapper: {
    display: 'flex',
    width: '100%',
  },
  source: {
    fontWeight: 200,
    color: '#646464',
  },
};

/* eslint-disable react/prefer-stateless-function */
class EntityMergePropertiesResolverRow extends Component {
  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
    this.renderItemControl = this.renderItemControl.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderItemControl(conflictedPropValue /* , theKey */) {
    const { value, entityName, entityId, key } = conflictedPropValue;
    // const model = `propsDelta.${theKey}`;
    const model = `propsDelta.${encodeRRFKey(key)}`;

    const defaultValue = conflictedPropValue.isOnlyObviousChoice
      ? conflictedPropValue.entityId
      : undefined;

    return (
      <div style={styles.radioControlsWrapper}>
        <Control.radio
          style={{ width: '100%' }}
          defaultValue={defaultValue}
          id={`${entityId}-${key}`}
          name={key}
          model={model}
          value={entityId}
          component={(props) => (
            <Hover>
              <Radio
                style={{ width: '100%' }}
                {...props}
                wrapperStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  flexFlow: 'row wrap',
                }}
                label={
                  <Flex
                    style={{
                      width: '100%',
                      height: '100%',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div style={styles.entityName}>{`${entityName} :`}</div>
                    <div style={styles.value}>
                      {value === '' ? '–EMPTY STRING–' : value}
                    </div>
                  </Flex>
                }
              />
            </Hover>
          )}
        />
      </div>
    );
  }

  renderItem(conflictedProp) {
    const { values, key } = conflictedProp;

    return (
      <div style={styles.radioItemWrapper}>
        {values.map((x) => this.renderItemControl(x, key))}
      </div>
    );
  }

  render() {
    const { conflictedProp, selectedEntities, isLast, source } = this.props;

    let displayNameOrKey = conflictedProp.key;

    const sourceImageUrl = source && source.Icon;

    if (
      conflictedProp &&
      conflictedProp.schema &&
      conflictedProp.schema.propertyInSchema &&
      conflictedProp.schema.propertyInSchema.DisplayName
    ) {
      displayNameOrKey = conflictedProp.schema.propertyInSchema.DisplayName;
    }

    return (
      <div style={{ ...styles.wrapper, ...(isLast ? { border: 'none' } : {}) }}>
        <div style={styles.headerWrapper}>
          <h4 style={styles.header}>{displayNameOrKey}</h4>
          &nbsp;&nbsp;
          {sourceImageUrl && (
            <div
              style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Img src={sourceImageUrl} width={20} />
            </div>
          )}
          &nbsp;
          <small style={styles.source}>{source.Name}</small>
        </div>
        <div style={{ width: '100%' }}>
          {selectedEntities.length && this.renderItem(conflictedProp)}
        </div>
      </div>
    );
  }
}

EntityMergePropertiesResolverRow.defaultProps = {
  source: 'CluedIn',
};

export default withIntegrationSourceFromProperty(
  EntityMergePropertiesResolverRow,
);
