import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { FormattedMessage } from '@cluedin/locale';

import PageLoader from '../../../../../core/components/composites/PageLoader';
import {
  EntityPropertiesHistoryFullWrapper,
  EntityPropertiesTableTitle,
  EntityPropertiesHistoryFullTableWrapper,
  EntityPropertiesHistoryFullTableHead,
  EntityPropertiesHistoryFullTableRow,
  EntityPropertiesHistoryFullTableHeading,
  EntityPropertiesHistoryFullTableBody,
  EntityPropertiesHistoryFullTableData,
  EntityPropertiesHistoryFullTableScroll,
} from './EntityPropertiesHistoryFull.styles';

const EntityPropertiesHistoryFull = ({
  filteredHistoryChangesToShow,
  historyLoading,
}) => {
  const flattenedFilteredHistoryChangesToShow =
    filteredHistoryChangesToShow.flat(1);
  const reversedFilteredHistoryChangesToShow =
    flattenedFilteredHistoryChangesToShow.reverse();

  if (historyLoading) {
    return <PageLoader />;
  }

  return (
    <EntityPropertiesHistoryFullWrapper>
      <EntityPropertiesTableTitle>
        <FormattedMessage id="module-entityProperties-panelHistoryFullHistory" />
      </EntityPropertiesTableTitle>

      {historyLoading ? (
        <PageLoader />
      ) : (
        <EntityPropertiesHistoryFullTableScroll>
          <EntityPropertiesHistoryFullTableWrapper data-test="entity_entityProperties_fullHistoryTable">
            <EntityPropertiesHistoryFullTableHead>
              <EntityPropertiesHistoryFullTableRow>
                <EntityPropertiesHistoryFullTableHeading>
                  <FormattedMessage id="module-entityProperties-panelHistoryTableType" />
                </EntityPropertiesHistoryFullTableHeading>
                <EntityPropertiesHistoryFullTableHeading>
                  <FormattedMessage id="module-entityProperties-panelHistoryTableChangedPart" />
                </EntityPropertiesHistoryFullTableHeading>
                <EntityPropertiesHistoryFullTableHeading>
                  <FormattedMessage id="module-entityProperties-panelHistoryTableKey" />
                </EntityPropertiesHistoryFullTableHeading>
                <EntityPropertiesHistoryFullTableHeading>
                  <FormattedMessage id="module-entityProperties-panelHistoryTableNewValue" />
                </EntityPropertiesHistoryFullTableHeading>
                <EntityPropertiesHistoryFullTableHeading>
                  <FormattedMessage id="module-entityProperties-panelHistoryTableModifiedAt" />
                </EntityPropertiesHistoryFullTableHeading>
                <EntityPropertiesHistoryFullTableHeading>
                  <FormattedMessage id="module-entityProperties-panelHistoryTableVersionNumber" />
                </EntityPropertiesHistoryFullTableHeading>
              </EntityPropertiesHistoryFullTableRow>
            </EntityPropertiesHistoryFullTableHead>
            <EntityPropertiesHistoryFullTableBody>
              {reversedFilteredHistoryChangesToShow &&
                (reversedFilteredHistoryChangesToShow || [])?.map(
                  (historyItem) => {
                    const change = get(historyItem, 'change');
                    const versionNumber = get(historyItem, 'dataReference');
                    const date = get(historyItem, 'date');
                    const formattedDate = moment(date).format('LLL');

                    return (
                      <EntityPropertiesHistoryFullTableRow>
                        <EntityPropertiesHistoryFullTableData>
                          {change['attribute-type']}
                        </EntityPropertiesHistoryFullTableData>
                        <EntityPropertiesHistoryFullTableData>
                          {change['attribute-changedPart']}
                        </EntityPropertiesHistoryFullTableData>
                        <EntityPropertiesHistoryFullTableData>
                          {change['attribute-key']}
                        </EntityPropertiesHistoryFullTableData>
                        <EntityPropertiesHistoryFullTableData>
                          {change['attribute-newValue']}
                        </EntityPropertiesHistoryFullTableData>
                        <EntityPropertiesHistoryFullTableData>
                          {formattedDate}
                        </EntityPropertiesHistoryFullTableData>
                        <EntityPropertiesHistoryFullTableData>
                          {versionNumber}
                        </EntityPropertiesHistoryFullTableData>
                      </EntityPropertiesHistoryFullTableRow>
                    );
                  },
                )}
            </EntityPropertiesHistoryFullTableBody>
          </EntityPropertiesHistoryFullTableWrapper>
        </EntityPropertiesHistoryFullTableScroll>
      )}
    </EntityPropertiesHistoryFullWrapper>
  );
};

export default EntityPropertiesHistoryFull;

EntityPropertiesHistoryFull.propTypes = {
  filteredHistoryChangesToShow: PropTypes.array,
};

EntityPropertiesHistoryFull.defaultProps = {
  filteredHistoryChangesToShow: [],
};
