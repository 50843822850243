import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

import { urlBuilder } from '../../../../../config';
import { FileDataSource } from '../MultiUploader/MultiUploaderProvider';

const getFileTypeFormat = (file: File): string => {
  const fileName = file.name.split('.');
  const fileFormat = fileName && fileName[fileName?.length - 1];

  return fileFormat;
};

const getSampleFile = (format = '', hasBasicXLSX = false) => {
  if (hasBasicXLSX && (format === 'xls' || format === 'xlsx')) {
    return 'cluedin-sample-xls.xlsx';
  }

  if (format === 'json') {
    return 'cluedin-json-sample.json';
  }

  return 'cluedin-json-sample.json';
};

const getNotification = (format = '', hasBasicXLSX = false) => {
  if (hasBasicXLSX && (format === 'xls' || format === 'xlsx')) {
    return <FormattedMessage id="data-source-xlsWarningSample" />;
  }

  return <FormattedMessage id="data-source-jsonWarningSample" />;
};

type FileUploadFormFormatsHelperProps = {
  hasBasicXLSX: boolean;
  fileDataSource: FileDataSource;
};

export const FileUploadFormFormatsHelper = ({
  hasBasicXLSX,
  fileDataSource,
}: FileUploadFormFormatsHelperProps) => {
  const fileFormat = getFileTypeFormat(fileDataSource.file);

  if (fileFormat !== 'xls' && fileFormat !== 'xlsx' && fileFormat !== 'json') {
    return null;
  }

  const sampleFileName = getSampleFile(fileFormat, hasBasicXLSX);
  const mainNotification = getNotification(fileFormat, hasBasicXLSX);

  return (
    <div
      style={{ fontSize: '12px', color: '#ff9800' }}
      data-test={`prompt-${fileFormat === 'json' ? 'json' : 'xls'}-files`}
    >
      {mainNotification}{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href={urlBuilder.mainApp(sampleFileName)}
        download={sampleFileName}
      >
        <FormattedMessage id="data-source-downloadSample" />
      </a>{' '}
      <FormattedMessage id="data-source-toHaveMoreInfo" />
    </div>
  );
};
