import React from 'react';
import Alert from 'uxi/Alert';
import { ErrorText } from 'uxi/Text';
import SomethingOddContainer from '../../../../error/components/containers/SomethingOddContainer';

const defaultMessage = 'An error occured while retrieving the data';

const InvalidEntity = (placement, message) => {
  if (placement === 'page') {
    return ({ status }) => (
      <SomethingOddContainer text={message} status={status} />
    );
  }

  if (placement === 'inline') {
    return () => <ErrorText>{message || defaultMessage}</ErrorText>;
  }

  return () => <Alert type="danger">{message || defaultMessage}</Alert>;
};

export default InvalidEntity;
