import { useState, useEffect } from 'react';
import SimpleGrid from '../../../composites/layout/SimpleGrid';
import TileViewItem from './TileViewItem';
import TileViewSelectableWrapper from './TileViewSelectableWrapper';

const TileGrid = ({
  data = [],
  model,
  selectable,
  selected = [],
  onSelectionChange,
  restrictionValue,
  uniqueSelectionProperty,
}) => {
  //we need to work as a list selection for a table
  const [selectedEntities, setSelectedEntities] = useState([]);

  useEffect(() => {
    setSelectedEntities(selected);
  }, [selected]);

  return (
    <div style={{ width: '100%' }}>
      <SimpleGrid>
        {data.map((entity, i) => {
          const isSelected = !!selected.find((e) => e.id === entity.id);
          return (
            <TileViewSelectableWrapper
              restrictionValue={restrictionValue}
              uniqueSelectionProperty={uniqueSelectionProperty}
              isMerging={selectable}
              entity={entity}
              key={`searchResult-${entity.id}`}
              onChange={(isSelected, entityId, entity) => {
                if (isSelected) {
                  const updatedList = [...selectedEntities, entity];
                  setSelectedEntities(updatedList);
                  onSelectionChange(isSelected, entityId, updatedList);
                } else {
                  const filteredList = selectedEntities.filter(
                    (e) => e.id !== entityId,
                  );
                  setSelectedEntities(filteredList);
                  onSelectionChange(isSelected, entityId, filteredList);
                }
              }}
              selected={isSelected}
            >
              <TileViewItem entity={entity} />
            </TileViewSelectableWrapper>
          );
        })}
      </SimpleGrid>
    </div>
  );
};

export default TileGrid;
