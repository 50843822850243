import { FormatOptionLabelMeta } from 'react-select';
import { Ellipsis } from '../../../shared/components/Ellipsis';
import { getScaledValueWithMetricNotation } from '../../core/helpers/numbers';

interface RuleItemOption {
  icon: JSX.Element;
  value: string;
  label: string;
  count: number;
  width: number;
  __isNew__?: boolean;
}

const paddings = 16;
const icon = 28;
const scroll = 16;
const cancelButton = 10;
const cancelAllButton = 19;
const arrowButton = 32;
const countMargin = 8;

const overflowStyle: React.CSSProperties = {
  overflow: 'hidden',
  overflowWrap: 'break-word',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
};

const getCountSize = (count: number | undefined) => {
  if (!count) {
    return 0;
  }

  const countString = getScaledValueWithMetricNotation(count, true);
  const hasMillion = countString.includes('M');
  const hasBillion = countString.includes('B');
  const additionalSize = hasMillion ? 8 : hasBillion ? 4 : 0;

  return countString.length * 8 + additionalSize + countMargin;
};

/**
 * optionLabelsWithCountFormatter
 * Adds `count` at right side in the Select's option label.
 * Should be passed as `formatOptionLabel` prop to Select.
 *
 */
export const optionLabelsWithCountFormatter = (
  option: RuleItemOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<RuleItemOption>,
) => {
  const { label, count, width: parentWidth } = option;
  const isSelectedOption = formatOptionLabelMeta.context === 'value';

  if (option.value === 'isResults' || option.__isNew__) {
    return option.label;
  }

  return isSelectedOption ? (
    <span
      style={{
        display: 'inline-flex',
        maxWidth: `${
          parentWidth -
          paddings -
          icon -
          cancelButton -
          cancelAllButton -
          arrowButton
        }px`,
      }}
    >
      <div style={overflowStyle}>{label}</div>
    </span>
  ) : (
    <span
      style={{
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div
        style={{
          width: `${
            parentWidth - paddings - icon - scroll - getCountSize(count)
          }px`,
        }}
      >
        <Ellipsis>{label}</Ellipsis>
      </div>
      {count > 0 && (
        <div
          style={{
            marginLeft: countMargin,
            width: `${getCountSize(count)}px`,
          }}
        >
          <Ellipsis>
            <div>{getScaledValueWithMetricNotation(count, true)}</div>
          </Ellipsis>
        </div>
      )}
    </span>
  );
};
