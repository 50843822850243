import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

const GET_MODULE_DETAIL = gql`
  query ($moduleName: ID) {
    help {
      id
      module(moduleName: $moduleName) {
        id
        excerpt
        title
        content
        pillar {
          id
          excerpt
          title
        }
        topics {
          id
          excerpt
          title
        }
      }
    }
  }
`;

const GET_PILLAR = gql`
  query ($pillarName: ID) {
    help {
      id
      pillar(pillarName: $pillarName) {
        id
        excerpt
        title
        content
        modules {
          id
          excerpt
          title
        }
      }
    }
  }
`;

const GET_TOPIC = gql`
  query ($topicName: ID) {
    help {
      id
      topic(topicName: $topicName) {
        id
        excerpt
        title
        content
        topicDetails {
          id
          excerpt
          title
        }
      }
    }
  }
`;

const GET_TOPIC_DETAIL = gql`
  query ($topicDetailName: ID) {
    help {
      id
      topicDetail(topicDetailName: $topicDetailName) {
        id
        excerpt
        title
        content
        topic
        topicName
        prev
        next
      }
    }
  }
`;

export const useQueryTopic = (topicName) => {
  const variables = { topicName };
  const fetchPolicy = 'cache-and-network';
  const { data, loading, error, refetch } = useQuery(GET_TOPIC, {
    variables,
    fetchPolicy,
  });
  const help = get(data, 'help.topic');

  return [help, loading, error, refetch];
};

export const useQueryModuleDetail = (moduleName) => {
  const variables = { moduleName };
  const fetchPolicy = 'cache-and-network';
  const { data, loading, error, refetch } = useQuery(GET_MODULE_DETAIL, {
    variables,
    fetchPolicy,
  });
  const help = get(data, 'help.module');

  return [help, loading, error, refetch];
};

export const useQueryTopicDetail = (topicDetailName) => {
  const variables = { topicDetailName };
  const fetchPolicy = 'cache-and-network';
  const { data, loading, error, refetch } = useQuery(GET_TOPIC_DETAIL, {
    variables,
    fetchPolicy,
  });
  const help = get(data, 'help.topicDetail');

  return [help, loading, error, refetch];
};

export const useQueryPillar = (pillarName) => {
  const variables = { pillarName };
  const fetchPolicy = 'cache-and-network';
  const { data, loading, error, refetch } = useQuery(GET_PILLAR, {
    variables,
    fetchPolicy,
  });
  const help = get(data, 'help.pillar');

  return [help, loading, error, refetch];
};

export const useQueryDoc = (topicDetailName, type) => {
  if (type === 'topicDetail') {
    return useQueryTopicDetail(topicDetailName);
  }

  if (type === 'module') {
    return useQueryModuleDetail(topicDetailName);
  }

  if (type === 'topic') {
    return useQueryTopic(topicDetailName);
  }

  if (type === 'pillar') {
    return useQueryPillar(topicDetailName);
  }
};
