"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var Toolbar = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  position: relative;\n  background: #f3f3f2;\n  padding: 6px;\n  border: 1px solid #cecece;\n  //margin-bottom: 20px;\n  & > * {\n    display: inline-block;\n  }\n\n  & > * + * {\n    margin-left: 4px;\n  }\n"])));
var _default = Toolbar;
exports.default = _default;