import React from 'react';

const LabelValueStyle = {
  wrapper: {
    marginBottom: '32px',
    display: 'flex',
  },
  label: {
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: '#888',
    marginRight: '16px',
    flexShrink: 0,
  },
  value: {
    marginLeft: 'auto',
    flexGrow: 1,
  },
};

const LabelValue = ({ label, value }) => (
  <div style={LabelValueStyle.wrapper}>
    <div style={LabelValueStyle.label}>{label}</div>
    <div style={LabelValueStyle.value}>{value}</div>
  </div>
);

export default LabelValue;
