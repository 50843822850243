import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { get } from 'lodash';

const GET_ENTITY_RELATIONS_QUERY = gql`
  query GetEntityRelationsForNewNode($entityId: ID!) {
    virtualization {
      getEntityRelations(entityId: $entityId) {
        id
        name
        icon
        route
        displayName
        attributeOrigin
        codes
        isShadowEntity
        isTemporalEntity
        previewImage
        type
        nodeKind
        edges {
          edgeType
          direction
          entityType
          entityId
          icon
          route
          name
          attributeOrigin
          codes
          createdDate
          isShadowEntity
          isTemporalEntity
          isGrouped
          groupedEntityIds
          entityCount
          properties
          displayName
          nodeKind
          id
          previewImage
        }
      }
    }
  }
`;

export type EntityRelationResult = {
  virtualization: {
    getEntityRelations: {
      id: string;
      name: string;
      icon: string;
      route: string;
      displayName: string;
      attributeOrigin: string;
      codes: string[];
      isShadowEntity: boolean;
      isTemporalEntity: boolean;
      previewImage: {
        uri: string;
      };
      type: string;
      nodeKind: string;
      edges: {
        edgeType: string;
        direction: string;
        entityType: string;
        entityId: string;
        icon: string;
        route: string;
        name: string;
        attributeOrigin: string;
        codes: string[];
        createdDate: string;
        isShadowEntity: boolean;
        isTemporalEntity: boolean;
        isGrouped: boolean;
        groupedEntityIds: string[] | [];
        entityCount: number;
        properties: unknown;
        displayName: string;
        nodeKind: string;
        id: string;
        previewImage: {
          uri: string;
        };
      };
    };
  };
};

export type EntityRelationPayload = {
  entityId: string;
};

export const useEntityRelations = (entityId: string) => {
  const { data, refetch, loading, error } = useQuery<
    EntityRelationResult,
    EntityRelationPayload
  >(GET_ENTITY_RELATIONS_QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      entityId,
    },
  });

  const resultData = get(data, 'virtualization.getEntityRelations');

  return [resultData, loading, error, refetch] as const;
};

export const useLazyEntityRelations = () => {
  const [getEntityRelations, { data, refetch, loading, error, fetchMore }] =
    useLazyQuery<EntityRelationResult, EntityRelationPayload>(
      GET_ENTITY_RELATIONS_QUERY,
      {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
      },
    );

  const resultData = get(data, 'virtualization.getEntityRelations');

  return [
    (entityId: string) => {
      return getEntityRelations({
        variables: {
          entityId,
        },
      });
    },
    {
      fetchMore,
      data: resultData,
      loading,
      error,
      refetch,
    },
  ] as const;
};
