import React from 'react';

const KPI = ({ value, title, style, withPadding }) => (
  <div style={style}>
    <div style={{ fontSize: '42px', paddingTop: withPadding ? '15px' : '0' }}>
      {value}
    </div>
    <div style={{ fontSize: '20px' }}>{title}</div>
  </div>
);

export default KPI;
