import { handleActions } from 'redux-actions';
import {
  shouldFetchAllRetentionActions,
  shouldCreateEntityRetentionActions,
  shouldDeleteRetentionPoliciesActions,
  shouldCreateQueryRetentionActions,
} from './actions';
import { withCrud, withCreate } from '../core/components/Hocs/ListPage/reducer';

const entityName = 'retention';

const { reducer, initialState } = withCreate(
  {
    entityName: 'queryRetention',
    action: shouldCreateQueryRetentionActions,
  },
  withCrud(
    {
      entityName,
      actions: {
        create: shouldCreateEntityRetentionActions,
        fetch: shouldFetchAllRetentionActions,
      },
    },
    {
      initialState: {
        delete: {},
      },
      reducer: {
        [shouldDeleteRetentionPoliciesActions.request]: (
          state,
          { payload },
        ) => ({
          ...state,
          delete: {
            entities: payload,
            isFetching: true,
            isDone: false,
            invalid: false,
          },
        }),
        [shouldDeleteRetentionPoliciesActions.receive]: (
          state,
          { payload },
        ) => ({
          ...state,
          delete: {
            entities: payload,
            isFetching: false,
            isDone: true,
            invalid: false,
          },
          [`ALL_${entityName}`]: {
            isFetching: false,
            invalid: false,
            data:
              state[`ALL_${entityName}`] && state[`ALL_${entityName}`].data
                ? state[`ALL_${entityName}`].data.filter(
                    (entity) => payload.indexOf(entity.Id) === -1,
                  )
                : [],
          },
        }),
        [shouldDeleteRetentionPoliciesActions.invalid]: (
          state,
          { payload },
        ) => ({
          ...state,
          delete: {
            entities: payload,
            isFetching: false,
            isDone: false,
            invalid: true,
          },
        }),
      },
    },
  ),
);

export default handleActions(reducer, initialState);
