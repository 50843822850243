import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';

import {
  HistoryChangeSetBox,
  HistoryChangeSetDate,
  HistoryChangeRecordCount,
  HistoryChangeSetSourceWrapper,
  HistoryChangeSetWrapper,
} from './HistoryChangeSet.styles';
import HistoryChangeSetSource from '../HistoryChangeSetSource/HistoryChangeSetSource';

const HistoryChangeSet = ({
  changeSet = {},
  entityId,
  useQueryEntityHistoryDetails,
  labels = {},
  isOpen,
  userAvatar,
  cleanRootUrl,
  dedupRootUrl,
  manualDataEntryRootUrl,
  currentSort,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [isExpanded, setIsExpanded] = useState(isOpen);
  const changeSetCount = changeSet.sources.length;
  const { groupName, sources = [] } = changeSet;
  const { recordSingular = 'record', recordPlural = 'records' } = labels;

  return (
    <HistoryChangeSetBox>
      <HistoryChangeSetWrapper theme={theme} isExpanded={isExpanded}>
        <HistoryChangeSetDate
          data-test="entity_entityHistory_historyChangeSet_historyChangeSetButton"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <Arrowdown color={themePrimary} size={16} />
          ) : (
            <Arrowright color={themePrimary} size={16} />
          )}
          {groupName}
        </HistoryChangeSetDate>
        <HistoryChangeRecordCount>
          {changeSetCount}&nbsp;
          {sources.length < 2 ? recordSingular : recordPlural}
        </HistoryChangeRecordCount>
      </HistoryChangeSetWrapper>

      <HistoryChangeSetSourceWrapper isExpanded={isExpanded}>
        {sources.map((historyChangeSource, index) => {
          return (
            <HistoryChangeSetSource
              key={index}
              entityId={entityId}
              currentSort={currentSort}
              historyChangeSource={historyChangeSource}
              useQueryEntityHistoryDetails={useQueryEntityHistoryDetails}
              labels={labels}
              userAvatar={userAvatar}
              cleanRootUrl={cleanRootUrl}
              dedupRootUrl={dedupRootUrl}
              manualDataEntryRootUrl={manualDataEntryRootUrl}
            />
          );
        })}
      </HistoryChangeSetSourceWrapper>
    </HistoryChangeSetBox>
  );
};

export default HistoryChangeSet;

HistoryChangeSet.propTypes = {
  changeSet: PropTypes.object,
  entityId: PropTypes.string,
  isOpen: PropTypes.bool,
  useQueryEntityHistoryDetails: PropTypes.func,
  labels: PropTypes.object,
  userAvatar: PropTypes.node,
};
