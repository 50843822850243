import { groupBy } from 'lodash';

const filterIdChar = '$';

export const encodeFilter = (filters = []) => {
  if (!filters || filters.length === 0) {
    return null;
  }

  const filtersGoupedByType = groupBy(filters, (f) => f.type);
  const filterResult = [];
  Object.keys(filtersGoupedByType).forEach((key) => {
    filterResult.push(
      (filtersGoupedByType[key] || [])
        .map(
          (f) =>
            `${f.type}:${f.value}|${
              f.id ? `${f.id}${filterIdChar}` : ''
            }${encodeURIComponent(f.displayName || 'name')}`,
        )
        .join('_OR_'),
    );
  });

  const queryString = filterResult.join('|AND|');

  return `filters=${queryString}`;
};

export const encodeSearch = (
  q = '*',
  // Filters needs to recive the "view model" no the filters sent to GQL
  filters = [],
  sortType,
) => {
  let result = `?q=${q}`;

  const filter = encodeFilter(filters);
  const filterValue = filter || '';

  if (filterValue) {
    result = `${result}&${filterValue}`;
  }

  if (sortType) {
    result = `${result}&sort=${sortType}`;
  }

  return result || '';
};

export const useSearchQueryEncoder = (q, sortType, filters = []) => {
  return encodeSearch(q, sortType, filters);
};
