import styled from 'styled-components';

export const EntityTopologySplitNewDataPartIconWrapper = styled.div`
  align-items: center;
  background: ${({ themePrimary }) =>
    themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
  border-radius: 80%;
  display: flex;
  height: 35px;
  min-height: 35px;
  justify-content: center;
  margin-right: 8px;
  width: 35px;
  min-width: 35px;
`;

export const EntityTopologySplitNewDataPartConnectorIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justifycontent: center;
  width: 100%;
`;
