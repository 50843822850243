import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { PanelContent, PanelHeader } from '@cluedin/form';
import { Stepper } from '@cluedin/components';
import get from 'lodash/get';

import EntityTopologySplitModal from '../EntityTopologySplitModal/EntityTopologySplitModal';
import EntityCodePanelHeader from './EntityCodePanelHeader/EntityCodePanelHeader';
import EntityTopologySplitPanelList from '../EntityTopologySplitPanelList/EntityTopologySplitPanelList';
import EntityTopologySplitPanelPreview from '../EntityTopologySplitPanelPreview/EntityTopologySplitPanelPreview';
import EntityCodePanelFooter from './EntityCodePanelFooter/EntityCodePanelFooter';
import {
  EntityCodePanelWrapper,
  EntityCodePanelStepperWrapper,
} from './EntityCodePanel.styles';

const defaultStepperState = {
  active: 1,
  completed: [],
};

const EntityCodePanel = ({
  entityId,
  onClose,
  currentNode,
  nodeDataType,
  setNodeDataType,
  entityCodePanelData,
  entityCodePanelLoading,
  entityCodePanelError,
  handleExecuteAction,
  intl,
}) => {
  const dataType = get(currentNode, 'dataType');

  const [isPreviewError, setIsPreviewError] = useState([]);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [newEntityCount, setNewEntityCount] = useState(null);
  const [stepperState, setStepperState] = useState(defaultStepperState);
  const [selectedDataParts, setSelectedDataParts] = useState([]);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);

  const handleSplitModalOnClose = () => {
    setIsSplitModalOpen(false);
  };

  useEffect(() => {
    if (
      entityCodePanelData &&
      entityCodePanelData?.actions?.[0]?.dataParts.length === 1
    ) {
      setSelectedDataParts([
        entityCodePanelData?.actions?.[0]?.dataParts?.[0]?.dataPartId,
      ]);
    } else {
      setSelectedDataParts([]);
    }
  }, [entityCodePanelData]);

  const handleBack = () => {
    setStepperState({
      active: 1,
      completed: [],
    });
    setSelectedDataParts([]);
  };

  const handleOnCloseWithReset = () => {
    setSelectedDataParts([]);
    setNodeDataType('');
    handleBack();
    onClose();
  };

  useEffect(() => {
    return () => {
      handleOnCloseWithReset();
    };
  }, []);

  const connectingNode =
    nodeDataType === 'EntityCode' ? (
      <>
        <FormattedMessage id="module-entityTopology-splitPanelEntityCodeTitle" />
        :&nbsp;
        {currentNode?.label}
      </>
    ) : (
      `${currentNode?.dataType}: ${currentNode?.label}`
    );

  return (
    <>
      <PanelHeader
        title={
          <FormattedMessage
            id="module-entityTopology-splitPanelTitle"
            values={{ connectingNode }}
          />
        }
        onClose={handleOnCloseWithReset}
      />

      <EntityCodePanelStepperWrapper>
        <Stepper
          activeStep={stepperState.active}
          completedSteps={stepperState.completed}
          onClickStep={(active) => {
            if (active === 1) {
              handleBack();
            }
          }}
          steps={[
            {
              label: intl.formatMessage({
                id: 'module-entityTopology-splitPanelStep1',
              }),
            },
            {
              label: intl.formatMessage({
                id: 'module-entityTopology-splitPanelStep2',
              }),
            },
          ]}
        />
      </EntityCodePanelStepperWrapper>

      <EntityCodePanelWrapper>
        {entityCodePanelData && (
          <EntityCodePanelHeader
            data={entityCodePanelData}
            title={connectingNode}
            currentNode={currentNode}
          />
        )}

        {stepperState?.active === 1 &&
          entityCodePanelData &&
          !entityCodePanelError && (
            <EntityTopologySplitPanelList
              dataType={dataType}
              entityId={entityId}
              currentNode={currentNode}
              data={entityCodePanelData}
              loading={entityCodePanelLoading}
              error={entityCodePanelError}
              selectedDataParts={selectedDataParts}
              setSelectedDataParts={setSelectedDataParts}
              offsetTop={444}
            />
          )}

        <PanelContent style={{ padding: '16px 0 ' }}>
          {stepperState?.active === 2 &&
            entityCodePanelData &&
            !entityCodePanelError && (
              <EntityTopologySplitPanelPreview
                data={entityCodePanelData}
                entityId={entityId}
                selectedDataParts={selectedDataParts}
                newEntityCount={newEntityCount}
                setNewEntityCount={setNewEntityCount}
                setIsPreviewError={setIsPreviewError}
                setIsPreviewLoading={setIsPreviewLoading}
                currentNode={currentNode}
              />
            )}
        </PanelContent>

        <EntityCodePanelFooter
          handleOnCloseWithReset={handleOnCloseWithReset}
          stepperState={stepperState}
          setStepperState={setStepperState}
          entityCodePanelData={entityCodePanelData}
          handleBack={handleBack}
          setIsSplitModalOpen={setIsSplitModalOpen}
          isPreviewError={isPreviewError}
          isPreviewLoading={isPreviewLoading}
          selectedDataParts={selectedDataParts}
        />
      </EntityCodePanelWrapper>

      <EntityTopologySplitModal
        isOpen={isSplitModalOpen}
        onClose={handleSplitModalOnClose}
        onClosePanel={handleOnCloseWithReset}
        newEntityCount={newEntityCount}
        kind={entityCodePanelData && entityCodePanelData?.actions?.[0]?.kind}
        entityId={entityId}
        selectedDataParts={selectedDataParts}
        data={entityCodePanelData}
        handleExecuteAction={handleExecuteAction}
      />
    </>
  );
};

export default injectIntl(EntityCodePanel);

EntityCodePanel.propTypes = {
  entityId: PropTypes.string,
  onClose: PropTypes.func,
  currentNode: PropTypes.object,
  nodeDataType: PropTypes.string,
  setNodeDataType: PropTypes.func,
  entityCodePanelData: PropTypes.object,
  entityCodePanelLoading: PropTypes.bool,
  entityCodePanelError: PropTypes.any,
  handleExecuteAction: PropTypes.func,
};
