import React from 'react';
import { Select } from '@cluedin/form';
import { Loader } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { useQueryVocabularyKeyClassifications } from '../../../../../hooks/useQueryVocabularyKeyClassifications';

export const ClassificationDropdownContainer = ({
  onChange,
  value = '',
  defaultLabel = (
    <FormattedMessage id="module-dataCatalog-defaultClassificationLabel" />
  ),
  style = {},
  disabled,
  ...rest
}) => {
  const [classifications, loading] = useQueryVocabularyKeyClassifications();

  if (loading) {
    return (
      <div style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }
  const defaultValue = { key: 'default', value: '', label: defaultLabel };

  const options = classifications
    .map((classfication) => ({
      key: classfication,
      value: classfication,
      label: classfication,
    }))
    .sort((x = '', y = '') => {
      const a = x?.label?.replace?.('/', '');
      const b = y?.label?.replace?.('/', '');

      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    });

  return (
    <div {...rest} style={style}>
      <Select
        disabled={disabled}
        value={value || ''}
        isFullWidth
        onChange={({ value }) => onChange(value)}
        defaultValue={defaultValue}
        options={[defaultValue, ...options]}
      />
    </div>
  );
};

export default ClassificationDropdownContainer;
