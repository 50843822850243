import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import OutlineButton from 'uxi/Button/OutlineButton';
import { useTheme } from '@cluedin/theme';
import { Flex } from 'uxi/Layout';
import { PanelContent, PanelFooter, Button, CancelButton } from '@cluedin/form';

const DashboardKPIWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  height: calc(100% - 16px);
  min-height: calc(148px);
  box-sizing: border-box;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2),
    0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px -2px rgba(0, 0, 0, 0.12);

  ${({ isFullHeight }) => (isFullHeight ? 'height: calc(100% - 32px)' : '')};
  height: 100%;
  background: white;
`;

const DashboardKPI = ({
  icon,
  value,
  title,
  type,
  hasNew,
  style,
  isFullHeight,
  actionLabel,
  danger,
  className,
  onClick,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const hasNewContent =
    hasNew > 0 ? (
      <div style={{ opacity: 0.7, fontSize: 'thin' }}>
        {hasNew > 2000 ? `+ ${Math.floor(hasNew / 1000)}k` : `+ ${hasNew}`}{' '}
        since last visit
      </div>
    ) : null;

  return (
    <DashboardKPIWrapper
      className={className}
      isFullHeight={isFullHeight}
      type={type}
      style={style}
    >
      <div
        style={{
          top: 0,
          right: '16px',
          padding: '8px',
          position: 'absolute',
          color: theme.palette.white,
          background: danger ? theme.palette.red : themePrimary,
        }}
      >
        {React.cloneElement(icon, { size: 28 })}
      </div>

      <Flex
        style={{
          width: '100%',
          padding: '8px',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', fontSize: '42px' }}>
          <TextEllipsis>{value}</TextEllipsis>
        </div>
        <div style={{ flexGrow: 9, width: '100%', padding: '4px 0px 8px' }}>
          <TextEllipsis>{title}</TextEllipsis>
        </div>
        <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
          {actionLabel && (
            <CancelButton
              type={danger ? 'danger' : undefined}
              text={actionLabel}
              onClick={onClick}
            />
          )}
          <TextEllipsis style={{ paddingLeft: '6px' }}>
            {hasNewContent}
          </TextEllipsis>
        </Flex>
      </Flex>
    </DashboardKPIWrapper>
  );
};

DashboardKPI.defaultProps = {
  type: 'info',
  hasNew: 0,
  badgeType: 'error',
};

DashboardKPI.defaultProps = {
  type: PropTypes.oneOf(['error', 'warning', 'success', 'information']),
  hasNew: PropTypes.number,
};

DashboardKPI.displayName = 'DashboardKPI';

export default DashboardKPI;
