import styled, { css } from 'styled-components';

export const EntityTopologyConnectingNodeIconWrapper = styled.div`
  align-items: center;
  background: ${({ themePrimary }) =>
    themePrimary ? themePrimary : 'rgb(41, 162, 154)'};

  ${({ dataType }) => {
    switch (true) {
      case dataType === 'entitycode':
        return css`
          background: ${({ themePrimary }) =>
            themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
        `;
      case dataType === 'deduplicationprojectmerge':
        return css`
          background: #e2cbf9;
        `;
      case dataType === 'manualmerge':
        return css`
          background: #fbf4de;
        `;
      case dataType === 'datapart':
        return css`
          background: #efc44f;
        `;
      default:
        return css`
          background: ${({ themePrimary }) =>
            themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
        `;
    }
  }}

  border-radius: 80%;
  display: flex;
  height: 60px;
  min-height: 60px;
  justify-content: center;
  margin-right: 14px;
  width: 60px;
  min-width: 60px;

  & svg {
    fill: ${({ themeWhite }) => (themeWhite ? themeWhite : '#ffffff')};
    height: 40px;
    width: 40px;
  }

  & svg path {
    ${({ dataType }) => {
      switch (true) {
        case dataType === 'manualmerge':
          return css`
            fill: #f2c230;
          `;
        case dataType === 'deduplicationprojectmerge':
          return css`
            fill: #a65dee;
          `;
        default:
          return css`
            fill: ${({ themeWhite }) => themeWhite ?? '#fff'};
          `;
      }
    }}
  }
`;
