"use strict";

exports.__esModule = true;
var _exportNames = {
  FormGenerator: true,
  FormViewOnly: true,
  ViewIsVisible: true,
  WrapperSubmitButton: true,
  formFieldAdapter: true,
  onChangeToUseSecondParameter: true
};
exports.onChangeToUseSecondParameter = exports.formFieldAdapter = exports.WrapperSubmitButton = exports.ViewIsVisible = exports.FormViewOnly = exports.FormGenerator = void 0;
var _FormGenerator = _interopRequireDefault(require("./FormGenerator"));
exports.FormGenerator = _FormGenerator.default;
var _useFormGenerator = require("./useFormGenerator");
Object.keys(_useFormGenerator).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useFormGenerator[key]) return;
  exports[key] = _useFormGenerator[key];
});
var _formFieldAdapter = require("./formFieldAdapter");
var _FormViewOnly = _interopRequireDefault(require("./FormViewOnly"));
exports.FormViewOnly = _FormViewOnly.default;
var _ViewIsVisible = _interopRequireDefault(require("./ViewField/ViewIsVisible"));
exports.ViewIsVisible = _ViewIsVisible.default;
var _WrapperSubmitButton = _interopRequireDefault(require("./WrapperSubmitButton"));
exports.WrapperSubmitButton = _WrapperSubmitButton.default;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var formFieldAdapter = _formFieldAdapter.formFieldAdapter;
exports.formFieldAdapter = formFieldAdapter;
var onChangeToUseSecondParameter = function onChangeToUseSecondParameter(props) {
  return function (e) {
    props.onChange(e.currentTarget.value);
  };
};
exports.onChangeToUseSecondParameter = onChangeToUseSecondParameter;