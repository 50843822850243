import styled from 'styled-components';

export const EdgePropertiesPanelEdgeGroupEntryHeader = styled.div`
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
`;
