/* eslint-disable */
import React, { Component } from 'react';
import jsCookie from 'js-cookie';
import { sendMessage } from '../../frame';

function fetchConsentFromAPI(/* uniqueID, consentFormID */) {
  const args = `uniqueID=${'zertgzrtzrtg'}&consentFormID=${null}`;
  const root = 'https://jsonplaceholder.typicode.com/posts/1';
  return fetch(`${root}?${args}`)
    .then((response) => {
      return response;
    })
    .then((res) => res.json());
}

class IdentifyPage extends Component {
  componentDidMount() {
    const {
      location: { search },
    } = this.props;

    const params = new URLSearchParams(search);
    const uniqueID = params.get('uniqueID');
    const consentFormID = params.get('consentFormID');

    if (!consentFormID) {
      return sendMessage('error', {
        from: 'checkConsent',
        message: 'checkConsent has been called without a consentFormID param',
      });
    }

    let uniqueIDToSendToCluedinAPI = uniqueID;
    if (!uniqueID) {
      const uniqueIDFromCookie = jsCookie.get('consent_token');
      if (uniqueIDFromCookie) {
        uniqueIDToSendToCluedinAPI = uniqueIDFromCookie;
      }
    }

    // check for client cookie containg consentFormID
    const alreadyAnsweredFormID = jsCookie.get('consent_form_id');
    if (alreadyAnsweredFormID && alreadyAnsweredFormID === consentFormID) {
      return sendMessage('checkConsentFound', { id: 'not_null_id_prop' });
    }

    if (!uniqueIDToSendToCluedinAPI) {
      // This an unrecognized/first time visitor user
      return sendMessage('checkConsentFound');
    }

    fetchConsentFromAPI(uniqueID, consentFormID)
      // .then(consentResult => sendMessage('checkConsentFound', consentResult))
      .then((consentResult) => sendMessage('checkConsentFound', {}))
      .catch((err) =>
        sendMessage('error', {
          from: 'checkConsent',
          message: `Error while fetching consentResult for
          uniqueID: ${uniqueID};
          consentFormID: ${consentFormID}`,
        }),
      );
  }

  render() {
    // const { params: { id } } = this.props;
    return <div />;
  }
}

export default IdentifyPage;
