import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { Loader } from '@cluedin/form';
import PageLoader from '../composites/PageLoader';
import ErrorPage from '../../../error/components/composites/SomethingOdd';
import Queued from '../../../error/components/pages/Queued';

export const WithErrorAndLoading = (Comp, options = {}) =>
  compose(
    branch((props) => {
      if (options && options.loading) {
        return props[options.loading];
      }
      return props.isFetching;
    }, renderComponent(PageLoader)),
    branch((props) => {
      if (options && options.error) {
        return props[options.error];
      }
      return props.invalid;
    }, renderComponent(ErrorPage)),
  )(Comp);

export const WithErrorAndLoadingInLine = (Comp) =>
  compose(
    branch(
      ({ isFetching }) => isFetching,
      renderComponent(() => (
        <div style={{ display: 'flex' }}>
          <Loader />
        </div>
      )),
    ),
    branch(({ invalid }) => invalid, renderComponent(ErrorPage)),
  )(Comp);

export const WithErrorAndLoadingAndQueuing = (
  Comp,
  { queueMessage, CustomLoading },
) =>
  compose(
    branch(
      ({ isFetching }) => isFetching,
      renderComponent(CustomLoading || PageLoader),
    ),
    branch(({ invalid }) => invalid, renderComponent(ErrorPage)),
    branch(
      ({ queued }) => queued,
      renderComponent(() => <Queued message={queueMessage} />),
    ),
  )(Comp);

export default {
  WithErrorAndLoading,
  WithErrorAndLoadingAndQueuing,
};
