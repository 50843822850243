import { FormattedMessage } from '@cluedin/locale';
import { TimeFromNow } from '@cluedin/components';
import { Badge } from 'uxi/Badge';
import SimplePropertyWrapper from './SimplePropertyWrapper';
import { DataWrapper, Title, GrayishLabel } from './styled';
import EntityCodePanel from './EntittyCodePanel';
import EntityTypeAvatar from '../../../../entityTypes/components/containers/EntityTypeAvatar';

const DateComponent = ({ date, title }) => (
  <DataWrapper>
    <Title>{title}</Title>
    {date && <TimeFromNow date={date} />}
    {!date && (
      <GrayishLabel>
        <FormattedMessage id="module-entityWidget-noDate" />
      </GrayishLabel>
    )}
  </DataWrapper>
);

const BadgeWrapperRenderer = ({ data, title }) => (
  <DataWrapper>
    <Title>{title}</Title>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {data?.map((item) => (
        <Badge style={{ marginRight: '8px', marginBottom: '4px' }} type="info">
          {item}
        </Badge>
      ))}
    </div>
  </DataWrapper>
);

const formatData = (processedData, intl) => {
  const resultData = {};

  Object.keys(processedData).map((key) => {
    if (key === 'name') {
      resultData.name = {
        name: key,
        component: (
          <SimplePropertyWrapper
            propertyName={intl.formatMessage({
              id: 'module-entityWidget-name',
            })}
            text={processedData.name}
          />
        ),
      };
    }

    if (key === 'description' && !!processedData[key]) {
      resultData.description = {
        name: key,
        component: (
          <SimplePropertyWrapper
            propertyName={intl.formatMessage({
              id: 'module-entityWidget-description',
            })}
            text={processedData.description}
          />
        ),
      };
    }

    if (key === 'entityType') {
      resultData.entityType = {
        name: key,
        component: (
          <DataWrapper>
            <Title>
              {intl.formatMessage({ id: 'module-entityWidget-entityType' })}
            </Title>
            <EntityTypeAvatar entityType={processedData.entityType} />
          </DataWrapper>
        ),
      };
    }

    if (key === 'aliases' && !!processedData[key]) {
      resultData.aliases = {
        name: key,
        component: (
          <BadgeWrapperRenderer
            data={processedData?.aliases}
            title={intl.formatMessage({ id: 'module-entityWidget-aliases' })}
          />
        ),
      };
    }

    if (key === 'tags' && !!processedData[key]) {
      resultData.tags = {
        name: key,
        component: (
          <BadgeWrapperRenderer
            data={processedData?.tags}
            title={intl.formatMessage({ id: 'module-entityWidget-tags' })}
          />
        ),
      };
    }

    if (key === 'origin') {
      resultData[key] = {
        name: key,
        component: <EntityCodePanel attributeOrigin={processedData[key]} />,
      };
    }

    if (key === 'discoveryDate') {
      resultData.discoveryDate = {
        name: key,
        component: (
          <DateComponent
            date={processedData.discoveryDate}
            title={intl.formatMessage({
              id: 'module-entityWidget-discoveryDate',
            })}
          />
        ),
      };
    }

    if (key === 'createdDate') {
      resultData.createdDate = {
        name: key,
        component: (
          <DateComponent
            date={processedData.createdDate}
            title={intl.formatMessage({
              id: 'module-entityWidget-createdDate',
            })}
          />
        ),
      };
    }

    if (key === 'modifiedDate') {
      resultData.modifiedDate = {
        name: key,
        component: (
          <DateComponent
            date={processedData.modifiedDate}
            title={intl.formatMessage({
              id: 'module-entityWidget-modifiedDate',
            })}
          />
        ),
      };
    }
  });

  return resultData;
};

export default formatData;
