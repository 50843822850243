import { url, clientId, getCurrentToken } from '../../../../config';
import {
  DataSourceMetaData,
  FileUploadRequest,
  FileUploadRequestProgress,
} from '../../types';

export type UploadChunkEvents = {
  onComplete: (file: FileUploadRequest) => void;
  onError: (
    e: ProgressEvent<EventTarget>,
    file?: FileUploadRequest | File,
  ) => void;
  onProgress: (
    progressInfo: FileUploadRequestProgress,
    file: FileUploadRequest,
  ) => void;
  onAbort: (e: ProgressEvent<EventTarget>, file: FileUploadRequest) => void;
  onChunk?: (req: XMLHttpRequest) => void;
};

const ENDPOINTS = {
  UPLOAD: 'upload/resume-upload',
  UPLOAD_STATUS: 'upload/resume-upload-status',
  UPLOAD_REQUEST: 'upload/resume-upload-request',
};

const getAuthHeaders = () => {
  const bearer = getCurrentToken();
  const AuthorizationValue = bearer ? `Bearer ${bearer}` : null;

  return {
    Authorization: AuthorizationValue,
    clientId,
  };
};

export const deleteFileRequest = (fileUploadRequest: FileUploadRequest) => {
  const authHeaders = getAuthHeaders();

  return fetch(`${url.gqlDomain}${ENDPOINTS.UPLOAD}`, {
    method: 'DELETE',
    // @ts-expect-error headers are correct here
    headers: {
      'Content-Type': 'application/json',
      ...authHeaders,
    },
    body: JSON.stringify({
      dataSourceId: fileUploadRequest.dataSourceId,
    }),
  }).then((res) => res.json());
};

export const uploadFileRequest = (
  file: File,
  meta: DataSourceMetaData,
): Promise<FileUploadRequest> => {
  const authHeaders = getAuthHeaders();

  return fetch(`${url.gqlDomain}${ENDPOINTS.UPLOAD_REQUEST}`, {
    method: 'POST',
    // @ts-expect-error headers are correct here
    headers: {
      'Content-Type': 'application/json',
      ...authHeaders,
    },
    body: JSON.stringify({
      fileName: file.name,
      fileSize: file.size,
      mimeType: file.type,
      ...meta,
    }),
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        return {
          error: true,
          errorMessage: res.statusText,
          message: await res.json(),
        };
      }
    })
    .then((res) => {
      if (res.error) {
        return {
          fileName: file.name,
          file,
          startingByte: 0,
          request: null,
          fileId: '',
          dataSourceId: '',
          error: true,
          errorObject: {
            errorMessage: res?.statusText,
            message: res?.message?.message,
          },
        };
      }

      return {
        fileName: file.name,
        file,
        startingByte: 0,
        request: null,
        fileId: res.fileId,
        dataSourceId: res.dataSourceId,
      };
    });
};

export const uploadFileChunkRequest = (
  fileUploadRequest: FileUploadRequest,
  uploadChunkEvents: UploadChunkEvents,
) => {
  const { onComplete, onError, onProgress, onAbort, onChunk } =
    uploadChunkEvents;

  const formData = new FormData();
  const req = new XMLHttpRequest();

  const chunkSize = 1024000 * 100;
  let endChunk = fileUploadRequest.startingByte + chunkSize;

  if (endChunk > fileUploadRequest.file.size) {
    endChunk = fileUploadRequest.file.size;
  }

  const chunk = fileUploadRequest.file.slice(
    fileUploadRequest.startingByte,
    endChunk,
  );

  formData.append('chunk', chunk, fileUploadRequest.fileName);
  formData.append('fileId', fileUploadRequest.fileId);

  req.open('POST', `${url.gqlDomain}${ENDPOINTS.UPLOAD}`, true);
  req.setRequestHeader(
    'Content-Range',
    `bytes=${fileUploadRequest.startingByte}-${
      fileUploadRequest.startingByte + chunk.size
    }/${fileUploadRequest.file.size}`,
  );
  req.setRequestHeader('x-file-id', fileUploadRequest.fileId);
  if (fileUploadRequest.dataSourceId) {
    req.setRequestHeader('x-dataSource-id', fileUploadRequest.dataSourceId);
  }

  req.onload = (e) => {
    // it is possible for load to be called when the request status is not 200
    // this will treat 200 only as success and everything else as failure
    if (req.status === 200) {
      if (
        fileUploadRequest.file.size ===
        fileUploadRequest.startingByte + chunk.size
      ) {
        onComplete && onComplete(fileUploadRequest);
      } else {
        fileUploadRequest.startingByte =
          fileUploadRequest.startingByte + chunk.size;

        uploadFileChunkRequest(fileUploadRequest, uploadChunkEvents);
      }
    } else {
      onError && onError(e, fileUploadRequest);
    }
  };

  req.upload.onprogress = (e) => {
    const loaded = fileUploadRequest.startingByte + e.loaded;

    const progress = {
      loaded,
      total: fileUploadRequest.file.size,
      percentage: (loaded * 100) / fileUploadRequest.file.size,
    };

    onProgress && onProgress(progress, fileUploadRequest);
  };

  req.ontimeout = (e) => onError && onError(e, fileUploadRequest);

  req.onabort = (e) => onAbort && onAbort(e, fileUploadRequest);

  req.onerror = (e) => onError && onError(e, fileUploadRequest);

  if (fileUploadRequest) {
    fileUploadRequest.request = req;
  }

  onChunk?.(req);

  req.send(formData);
};

export const uploadRetryRequest = (fileReq: FileUploadRequest) => {
  const authHeaders = getAuthHeaders();

  // try to get the status just in case it failed mid upload
  return fetch(
    `${url.gqlDomain}${ENDPOINTS.UPLOAD_STATUS}?fileName=${fileReq.fileName}&fileId=${fileReq.fileId}`,
    {
      // @ts-expect-error headers are correct here
      headers: {
        ...authHeaders,
      },
    },
  ).then((res) => res.json());
};

export const uploadResumeRequest = (fileReq: FileUploadRequest) => {
  const authHeaders = getAuthHeaders();

  return fetch(
    `${url.gqlDomain}${ENDPOINTS.UPLOAD_STATUS}?fileName=${fileReq.fileName}&fileId=${fileReq.fileId}`,
    {
      // @ts-expect-error headers are correct here
      headers: {
        ...authHeaders,
      },
    },
  ).then((res) => res.json());
};

export const resumeFileUploadRequest = (
  fileRequest: FileUploadRequest,
): Promise<number> => {
  const authHeaders = getAuthHeaders();

  return fetch(
    `${url.gqlDomain}${ENDPOINTS.UPLOAD_STATUS}?fileName=${fileRequest.fileName}&fileId=${fileRequest.fileId}`,
    {
      // @ts-expect-error headers are correct here
      headers: {
        ...authHeaders,
      },
    },
  )
    .then((res) => res.json())
    .then((r) => Number(r.totalChunkUploaded));
};
