import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import moment from 'moment';
import { Externallink } from 'uxi/Icons';
import GdprTimeLeftTableCell from '../composites/GdprTableCells/GdprTimeLeftTableCell';

const ListUI = styled.ul``;
/* need to increase specificity to overwrite this GD `.root li` rules */
const ListItemUI = styled.li`
  html body & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    padding: 12px 0;
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
    &:last-of-type {
      border: none;
    }
  }
`;

const LabelUI = styled.div`
  font-weight: bold;
  margin-right: 8px;
`;

const ValueUI = styled.div`
  margin-left: auto; /* flex magic */
  text-align: right;
  max-width: 80%;
`;

const GdprSarOverview = ({ sar, configuration, SARSubjectsEntity }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  // Identifiers
  const Identifiers = 'Identifiers' in sar ? sar.Identifiers : {};
  const mappedIdentifiersKey = Object.keys(Identifiers);
  const stringifyedIdentifiers = mappedIdentifiersKey.map((identifierKey, i) =>
    i === mappedIdentifiersKey.length - 1
      ? sar.Identifiers && sar.Identifiers[identifierKey]
      : `${sar.Identifiers && sar.Identifiers[identifierKey]}, `,
  );

  const SARSubjectsEntityHasAlreadyBeenExtended =
    (SARSubjectsEntity &&
      SARSubjectsEntity.data &&
      SARSubjectsEntity.data.properties &&
      SARSubjectsEntity.data.properties[
        'property-subjectAccessRequest.transformId'
      ]) ||
    false;

  const created = moment.utc(sar.CreatedDate).fromNow();
  const modified = moment.utc(sar.ModifiedDate).fromNow();

  let reportUriValue;
  if (sar.ReportJsonUri) {
    reportUriValue = (
      <a
        target="_blank"
        href={`/admin/gdpr/gdprReport/${sar.Id}`}
        alt="report url"
        rel="noopener noreferrer"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Externallink
          color={themePrimary}
          size={14}
          style={{ marginRight: '8px' }}
        />
        <FormattedMessage id="module-gdpr-open" />
      </a>
    );
  } else {
    reportUriValue = <FormattedMessage id="module-gdpr-notGeneratedYet" />;
  }

  const hasAttachedIndividual =
    sar.EntityId && sar.EntityId !== '00000000-0000-0000-0000-000000000000';

  let attachedIndividualValue;
  if (hasAttachedIndividual) {
    const name = (SARSubjectsEntity && SARSubjectsEntity.name) || null;
    let subjectsName = "subject's";
    if (name) {
      subjectsName =
        name && name.toLowerCase().indexOf('s') === name.length - 1
          ? `${name}'`
          : `${name}'s`;
    }

    attachedIndividualValue = (
      <Link
        to={`/person/${sar.EntityId}`}
        alt={`link to ${SARSubjectsEntity} entity page`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <FormattedMessage
          id="module-gdpr-goToSubjectSPage"
          values={{
            subject: subjectsName,
          }}
        />
      </Link>
    );
  } else {
    attachedIndividualValue = (
      <FormattedMessage id="module-gdpr-subjectNotAttachedYet" />
    );
  }

  return (
    <ListUI>
      <ListItemUI style={{ padding: '2px' }}>
        <LabelUI>
          <FormattedMessage id="module-gdpr-status" />
        </LabelUI>
        <ValueUI>
          <GdprTimeLeftTableCell sar={sar} configuration={configuration} />
        </ValueUI>
      </ListItemUI>
      <ListItemUI>
        <LabelUI>
          <FormattedMessage id="module-gdpr-identifiers" />
        </LabelUI>
        <ValueUI>{stringifyedIdentifiers}</ValueUI>
      </ListItemUI>
      <ListItemUI>
        <LabelUI>
          <FormattedMessage id="module-gdpr-created" />
        </LabelUI>
        <ValueUI>{created}</ValueUI>
      </ListItemUI>
      <ListItemUI>
        <LabelUI>
          <FormattedMessage id="module-gdpr-modified" />
        </LabelUI>
        <ValueUI>{modified}</ValueUI>
      </ListItemUI>
      {sar.ReportUri && (
        <ListItemUI>
          <LabelUI>
            <FormattedMessage id="module-gdpr-reportUri" />
          </LabelUI>
          <ValueUI>{reportUriValue}</ValueUI>
        </ListItemUI>
      )}
      {
        <ListItemUI>
          <LabelUI>
            <FormattedMessage id="module-gdpr-subject" />
          </LabelUI>
          <ValueUI>{attachedIndividualValue}</ValueUI>
        </ListItemUI>
      }
      {SARSubjectsEntityHasAlreadyBeenExtended && (
        <ListItemUI>
          <LabelUI>
            <FormattedMessage id="module-gdpr-sarHasBeenExtended" />
          </LabelUI>
          <ValueUI>{'Yes'}</ValueUI>
        </ListItemUI>
      )}
    </ListUI>
  );
};

GdprSarOverview.displayName = 'GdprSarOverview';

GdprSarOverview.propTypes = {
  sar: PropTypes.object,
  configuration: PropTypes.object,
};

GdprSarOverview.defaultProps = {
  sar: {},
  configuration: {},
};

export default GdprSarOverview;
