import * as d3 from 'd3';

import { UpdatedGraphNetworkEdge, UpdatedGraphNetworkNode } from '../types';

export const highlightNodesAndLinksOnClick = (
  clickedNode: SVGGElement,
  linkCollection: UpdatedGraphNetworkEdge[],
  node: UpdatedGraphNetworkNode,
  svg: d3.Selection<SVGSVGElement | null, unknown, d3.BaseType, unknown>,
) => {
  // Find all connected links to the clicked node
  const connectedLinks = linkCollection.filter((link) => {
    return (
      link?.source?.index === node?.index || link?.target?.index === node?.index
    );
  });
  // Find all connected nodes to the clicked node
  const connectedNodes = connectedLinks
    .flatMap((link) => {
      return [link?.source, link?.target];
    })
    .filter((connectedNode) => {
      return connectedNode?.index !== node?.index;
    });

  /**
   * Remove highlights on render
   */
  // EntityNode
  svg
    .selectAll('.node__body-entity.body-highlight')
    .classed('body-highlight', false);

  svg
    .selectAll('.node__border-left.border-highlight')
    .classed('border-highlight', false);

  // RelatedEntityNode
  svg
    .selectAll('.node__body-relatedEntityNode.body-highlight-relatedEntityNode')
    .classed('body-highlight-relatedEntityNode', false);

  svg
    .selectAll(
      '.node__border-left-relatedEntityNode.border-highlight-relatedEntityNode',
    )
    .classed('border-highlight-relatedEntityNode', false);

  // ShadowEntityNode
  svg
    .selectAll('.node__body-shadowEntity.body-highlight-shadowEntity')
    .classed('body-highlight-shadowEntity', false);

  svg
    .selectAll('.node__border-left-shadowEntity.border-highlight-shadowEntity')
    .classed('border-highlight-shadowEntity', false);

  svg.selectAll('.link__body.link-highlight').classed('link-highlight', false);

  svg
    .selectAll('.link__body')
    .selectAll('.link__arrow')
    .classed('link__arrow-highlight', false);

  /**
   * Assign highlights on click
   */
  // EntityNode
  if (d3.select(clickedNode).classed('node__body-entity')) {
    d3.select(clickedNode).classed('body-highlight', true);
  }

  if (
    d3
      .select(clickedNode.parentNode as HTMLElement)
      .classed('node__body-entity')
  ) {
    d3.select(clickedNode.parentNode as HTMLElement)
      .select('.node__border-left')
      .classed('border-highlight', true);
  }
  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .filter((node) => connectedNodes.includes(node))
    .selectAll('.node__body-entity')
    .classed('body-highlight', true);

  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .filter((node) => connectedNodes.includes(node))
    .selectAll('.node__border-left')
    .classed('border-highlight', true);

  // RelatedEntityNode
  if (d3.select(clickedNode).classed('node__body-relatedEntityNode')) {
    d3.select(clickedNode).classed('body-highlight-relatedEntityNode', true);
  }

  if (
    d3
      .select(clickedNode.parentNode as HTMLElement)
      .classed('node__body-relatedEntityNode')
  ) {
    d3.select(clickedNode.parentNode as HTMLElement)
      .select('.node__border-left-relatedEntityNode')
      .classed('border-highlight-relatedEntityNode', true);
  }

  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .filter((node) => connectedNodes.includes(node))
    .selectAll('.node__body-relatedEntityNode')
    .classed('body-highlight-relatedEntityNode', true);

  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .filter((node) => connectedNodes.includes(node))
    .selectAll('.node__border-left-relatedEntityNode')
    .classed('border-highlight-relatedEntityNode', true);

  // RelatedEntityNode
  if (d3.select(clickedNode).classed('node__body-shadowEntity')) {
    d3.select(clickedNode).classed('body-highlight-shadowEntity', true);
  }

  if (
    d3
      .select(clickedNode.parentNode as HTMLElement)
      .classed('node__body-shadowEntity')
  ) {
    d3.select(clickedNode.parentNode as HTMLElement)
      .select('.node__border-left-shadowEntity')
      .classed('border-highlight-shadowEntity', true);
  }

  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .filter((node) => connectedNodes.includes(node))
    .selectAll('.node__body-shadowEntity')
    .classed('body-highlight-shadowEntity', true);

  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .filter((node) => connectedNodes.includes(node))
    .selectAll('.node__border-left-shadowEntity')
    .classed('border-highlight-shadowEntity', true);

  // Link
  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__body')
    .filter((link) => connectedLinks.includes(link))
    .classed('link-highlight', true);

  svg
    .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__body')
    .filter((link) => {
      return connectedLinks.includes(link);
    })
    .classed('link-highlight', true)
    .each(function (this: SVGGElement) {
      const link = d3.select<SVGGElement, UpdatedGraphNetworkEdge>(this);
      link
        .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link__arrow')
        .classed('link__arrow-highlight', function (thisLink) {
          return connectedLinks.includes(thisLink);
        });
    });
};
