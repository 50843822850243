import { useEffect } from 'react';

import { useGetEdgeProperties } from '../../../hooks/useGetEdgeProperties';
import { EdgePropertiesPanelEdgeGroupEntryContainerWrapper } from './EdgePropertiesPanelEdgeGroupEntryContainer.styles';
import EdgePropertiesPanelEdgeGroupEntry from './EdgePropertiesPanelEdgeGroupEntry';
import EdgePropertiesPanelEdgeGroupEntryWithoutData from './EdgePropertiesPanelEdgeGroupEntryWithoutData';

const EdgePropertiesPanelEdgeGroupEntryContainer = ({
  edgeType,
  entityId,
  edgeDirection,
  edgeByEdgeId,
  edges,
}) => {
  const [
    getEdgeProperties,
    {
      data: edgePropertiesPanelData,
      loading: edgePropertiesPanelLoading,
      error: edgePropertiesPanelError,
    },
  ] = useGetEdgeProperties();

  useEffect(() => {
    if (entityId && edgeType && edgeDirection) {
      getEdgeProperties(entityId, edgeType, edgeDirection);
    }
  }, [entityId, edgeType, edgeDirection]);

  if (edgePropertiesPanelLoading) {
    return <div />;
  }

  if (!edgePropertiesPanelData || edgePropertiesPanelData?.length === 0) {
    return (
      <EdgePropertiesPanelEdgeGroupEntryContainerWrapper>
        <EdgePropertiesPanelEdgeGroupEntryWithoutData edgeType={edgeType} />
      </EdgePropertiesPanelEdgeGroupEntryContainerWrapper>
    );
  }

  return (
    <EdgePropertiesPanelEdgeGroupEntryContainerWrapper>
      {edgePropertiesPanelData?.map((edgeGroup, index) => (
        <EdgePropertiesPanelEdgeGroupEntry
          key={index}
          edgeGroup={edgeGroup}
          edgePropertiesPanelLoading={edgePropertiesPanelLoading}
          edgeByEdgeId={edgeByEdgeId}
          edges={edges}
          edgeDirection={edgeDirection}
          edgeType={edgeType}
        />
      ))}
    </EdgePropertiesPanelEdgeGroupEntryContainerWrapper>
  );
};

export default EdgePropertiesPanelEdgeGroupEntryContainer;
