import { useState, useCallback } from 'react';
import {
  DataSourceMetaData,
  FileUploadRequest,
  FileUploadResponse,
} from '../../types';
import {
  uploadFileRequest,
  uploadFileChunkRequest,
  uploadRetryRequest,
  resumeFileUploadRequest,
  UploadChunkEvents,
  deleteFileRequest,
} from './utils';
import authenticatedClient from '../../../../data/graphql';
import { GET_GROUPS_WITH_SOURCES_AND_SETS } from '../../../dataSourceV2/hooks/useQueryDataGroupsWithSourcesAndSets';

type ExtendUploadChunkEvents = UploadChunkEvents & {
  onUpload: (fileUploadRequest: FileUploadRequest | undefined) => void;
  onCodeError: (e: unknown) => void;
  onDelete: (file: FileUploadRequest) => void;
};

export const useResumeFileUpload = (
  uploadEvents: ExtendUploadChunkEvents,
): FileUploadResponse => {
  const {
    onAbort,
    onProgress,
    onError,
    onComplete,
    onUpload,
    onCodeError,
    onDelete,
  } = uploadEvents;

  const [fileUploadRequests, setFileRequests] = useState<FileUploadRequest[]>(
    [],
  );

  const uploadFile = async (
    file: File,
    meta: DataSourceMetaData,
    onCompleted?: () => void,
  ) => {
    try {
      const newFileRequest = await uploadFileRequest(file, meta);

      if (newFileRequest?.error) {
        return newFileRequest;
      }

      setFileRequests((prev) => [
        ...prev,
        {
          ...newFileRequest,
        },
      ]);

      onUpload({
        ...newFileRequest,
      });

      const onChunk = (req: XMLHttpRequest) => {
        setFileRequests((prev) => {
          return prev.map((i) => ({
            ...i,
            request: i.fileId === newFileRequest.fileId ? req : i.request,
          }));
        });
      };

      uploadFileChunkRequest(newFileRequest, {
        onComplete: (fileUploadRequest) => {
          onComplete(fileUploadRequest);
          if (onCompleted) {
            onCompleted();
          }
        },
        onError,
        onProgress,
        onAbort,
        onChunk,
      });

      return newFileRequest;
    } catch (e) {
      onCodeError && onCodeError(e);
      return;
    }
  };

  const abortFileUpload = useCallback(
    async (fileUploadRequest: FileUploadRequest) => {
      const fileReq = fileUploadRequests.find(
        (f) => f.fileId === fileUploadRequest.fileId,
      );

      if (fileReq && fileReq.request) {
        fileReq.request.abort();

        return true;
      }

      return false;
    },
    [fileUploadRequests],
  );

  const retryFileUpload = async (fileUploadRequest: FileUploadRequest) => {
    const fileReq = fileUploadRequests.find(
      (f) => f.fileId === fileUploadRequest.fileId,
    );

    if (fileReq) {
      try {
        await uploadRetryRequest(fileReq);
        uploadFileChunkRequest(fileReq, {
          onComplete,
          onError,
          onProgress,
          onAbort,
        });
      } catch (e) {
        uploadFileChunkRequest(fileReq, {
          onComplete,
          onError,
          onProgress,
          onAbort,
        });
      }
    }
  };

  const clearFileUpload = async (fileUploadRequest: FileUploadRequest) => {
    const fileReq = fileUploadRequests.find(
      (f) => f.fileId === fileUploadRequest.fileId,
    );

    if (fileReq) {
      await abortFileUpload(fileUploadRequest);
      // fileRequests.delete(file);

      return true;
    }

    return false;
  };

  const resumeFileUpload = async (fileUploadRequest: FileUploadRequest) => {
    const fileReq = fileUploadRequests.find(
      (f) => f.fileId === fileUploadRequest.fileId,
    );
    if (fileReq) {
      try {
        const totalChunkUploaded = await resumeFileUploadRequest(fileReq);
        fileReq.startingByte = totalChunkUploaded;

        uploadFileChunkRequest(fileReq, {
          onComplete,
          onError,
          onProgress,
          onAbort,
        });
      } catch (e) {
        onCodeError && onCodeError(e);
      }
    }
  };

  const cancelFileUpload = async (fileUploadRequest: FileUploadRequest) => {
    const fileReq = fileUploadRequests.find(
      (f) => f.dataSourceId === fileUploadRequest.dataSourceId,
    );

    if (fileReq) {
      if (fileReq.request) {
        fileReq.request.abort();
      }

      deleteFileRequest(fileReq).then(async () => {
        const updatedFileRequests = fileUploadRequests.filter(
          (f) => f.dataSourceId !== fileUploadRequest.dataSourceId,
        );

        setFileRequests([...updatedFileRequests]);

        if (onDelete) {
          onDelete(fileReq);
        }

        await authenticatedClient.refetchQueries({
          include: [GET_GROUPS_WITH_SOURCES_AND_SETS],
        });
      });
    }
  };

  return {
    fileUploadRequests,
    uploadFile,
    abortFileUpload,
    retryFileUpload,
    clearFileUpload,
    resumeFileUpload,
    cancelFileUpload,
  };
};
