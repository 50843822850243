import { handleActions } from 'redux-actions';
import {
  sensitiveActions,
  unSensitiveActions,
  updateEntityActions,
  executeUpdateEntityActions,
  followActions,
  unFollowActions,
  approveMeshCmdActions,
  rejectMeshCmdActions,
} from './actions';

const initialState = {
  updates: {},
  executeUpdates: {},
  actuallyExecuteUpdates: {}, // :(
  sensitive: {},
  unSensitive: {},
  allFollowingRequest: {},
};

/**
 *
 * case constants.follow.REQUEST_FOLLOW: {
      const isDoingFollowingRequestsFollowRequest = Object.assign(
        {}, state.isDoingFollowingRequests
      );

      const allFollowingActionsRequest = state.allFollowingActions;
      allFollowingActionsRequest[action.data.id] = allFollowingActionsRequest[action.data.id] || [];
      allFollowingActionsRequest[action.data.id].push({
        type: 'follow',
      });

      return Object.assign({}, state, {
        isDoingFollowingRequest: true,
        allFollowingActions: { ...allFollowingActionsRequest },
        isDoingFollowingRequests: isDoingFollowingRequestsFollowRequest,
      });
    }
    case constants.follow.REQUEST_UNFOLLOW: {
      const isDoingUnFollowingRequestsFollowRequest = Object.assign(
        {}, state.isDoingFollowingRequests
      );
      isDoingUnFollowingRequestsFollowRequest[action.data.id] = true;

      return Object.assign({}, state, {
        isDoingFollowingRequest: true,
        isDoingFollowingRequests: isDoingUnFollowingRequestsFollowRequest,
      });
    }
    case constants.follow.RECEIVE_UNFOLLOW: {
      let newAllFollowReceived = state.allFollowers;
      const isDoingFollowingRequestsUnfollowReceived = Object.assign(
        {}, state.isDoingFollowingRequests
      );

      const allUnfollowingActionsRequest = state.allFollowingActions;
      allUnfollowingActionsRequest[action.data.entity.id] =
        allUnfollowingActionsRequest[action.data.entity.id] || [];

      allUnfollowingActionsRequest[action.data.entity.id].push({
        type: 'unfollow',
      });

      isDoingFollowingRequestsUnfollowReceived[action.data.entity.id] = false;

      newAllFollowReceived = state.allFollowers.filter(f => (
        f.Entity.EntityId !== action.data.entity.id
      ));

      return Object.assign({}, state, {
        allFollowers: newAllFollowReceived.slice(0),
        allFollowingActions: { ...allUnfollowingActionsRequest },
        isDoingFollowingRequest: false,
        isDoingFollowingRequests: isDoingFollowingRequestsUnfollowReceived,
      });
    }
    case constants.follow.RECEIVE_FOLLOW: {
      const newAllFollowers = state.allFollowers;
      const isDoingFollowingRequestsFollowReceived = Object.assign(
        {}, state.isDoingFollowingRequests
      );
      isDoingFollowingRequestsFollowReceived[action.data.entity.id] = false;

      newAllFollowers.unshift({
        Count: 0,
        Entities: [],
        Entity: {
          Count: 0,
          EntityId: action.data.entity.id,
          EntityName: action.data.entity.name,
          EntityType:
            action.data.entity.data ?
              action.data.entity.data.entityType :
              action.data.entity.entityType,
        },
      });

      return Object.assign({}, state, {
        allFollowers: newAllFollowers.slice(0),
        isDoingFollowingRequest: false,
        showNotification: true,
        isDoingFollowingRequests: isDoingFollowingRequestsFollowReceived,
      });
    }
 */
export default handleActions(
  {
    [followActions.request]: (state, { payload }) => ({
      ...state,
      allFollowingRequest: {
        ...state.allFollowingRequest,
        [payload.id]: {
          isFetching: true,
          done: false,
          invalid: false,
          type: 'follow',
        },
      },
    }),
    [followActions.receive]: (state, { payload }) => ({
      ...state,
      allFollowingRequest: {
        ...state.allFollowingRequest,
        [payload.id]: {
          isFetching: false,
          done: true,
          invalid: false,
          type: 'follow',
        },
      },
    }),
    [followActions.invalid]: (state, { payload }) => ({
      ...state,
      allFollowingRequest: {
        ...state.allFollowingRequest,
        [payload.id]: {
          isFetching: false,
          done: false,
          invalid: true,
          type: 'follow',
        },
      },
    }),
    [unFollowActions.request]: (state, { payload }) => ({
      ...state,
      allFollowingRequest: {
        ...state.allFollowingRequest,
        [payload.id]: {
          isFetching: true,
          done: false,
          invalid: false,
          type: 'unfollow',
        },
      },
    }),
    [unFollowActions.receive]: (state, { payload }) => ({
      ...state,
      allFollowingRequest: {
        ...state.allFollowingRequest,
        [payload.id]: {
          isFetching: false,
          done: true,
          invalid: false,
          type: 'unfollow',
        },
      },
    }),
    [unFollowActions.invalid]: (state, { payload }) => ({
      ...state,
      allFollowingRequest: {
        ...state.allFollowingRequest,
        [payload.id]: {
          isFetching: false,
          done: false,
          invalid: true,
          type: 'unfollow',
        },
      },
    }),
    [updateEntityActions.request]: (state, { payload }) => {
      const { updates } = state;
      const updateActions = updates;
      updateActions[payload] = { isFetching: true, invalid: false };

      return {
        ...state,
        updates: { ...updateActions },
      };
    },
    [updateEntityActions.receive]: (state, { payload }) => {
      const { updates } = state;
      const updateActions = updates;
      updateActions[payload] = {
        isFetching: false,
        isCompleted: true,
        invalid: false,
      };

      return {
        ...state,
        updates: { ...updateActions },
      };
    },
    [updateEntityActions.invalid]: (state, { payload }) => {
      const { updates } = state;
      const updateActions = updates;
      updateActions[payload] = {
        isFetching: false,
        isCompleted: false,
        invalid: true,
      };

      return {
        ...state,
        updates: { ...updateActions },
      };
    },
    [executeUpdateEntityActions.request]: (
      state,
      { payload: { entityId, key } },
    ) => ({
      ...state,
      executeUpdates: {
        ...state.executeUpdates,
        [entityId]: {
          [key]: {
            isFetching: true,
            invalid: false,
            done: false,
          },
        },
      },
    }),
    [executeUpdateEntityActions.invalid]: (
      state,
      { payload: { entityId, key } },
    ) => ({
      ...state,
      executeUpdates: {
        ...state.executeUpdates,
        [entityId]: {
          [key]: {
            isFetching: false,
            invalid: true,
            done: false,
          },
        },
      },
    }),
    [executeUpdateEntityActions.receive]: (
      state,
      { payload: { entityId, key } },
    ) => ({
      ...state,
      executeUpdates: {
        ...state.executeUpdates,
        [entityId]: {
          [key]: {
            isFetching: false,
            invalid: false,
            done: true,
          },
        },
      },
    }),
    [sensitiveActions.request]: (state, action) => {
      const { sensitive } = state;
      const { payload } = action;
      const currentSensitive = sensitive[payload] || {};
      currentSensitive.isFetching = true;
      sensitive[payload] = currentSensitive;

      return Object.assign({}, state, {
        sensitive,
      });
    },
    [sensitiveActions.receive]: (state, action) => {
      const { sensitive } = state;
      const { payload } = action;
      const currentSensitive = sensitive[payload] || {};
      currentSensitive.isFetching = false;
      currentSensitive.isSensitive = true;
      currentSensitive.timeStamp = Date.now();

      sensitive[payload] = currentSensitive;

      return Object.assign({}, state, {
        sensitive,
      });
    },
    [unSensitiveActions.request]: (state, action) => {
      const { unSensitive } = state;
      const { payload } = action;
      const currentUnSensitive = unSensitive[payload] || {};
      currentUnSensitive.isFetching = true;

      unSensitive[payload] = currentUnSensitive;

      return Object.assign({}, state, {
        unSensitive,
      });
    },
    [unSensitiveActions.receive]: (state, action) => {
      const { unSensitive } = state;
      const { payload } = action;
      const currentUnSensitive = unSensitive[payload] || {};
      currentUnSensitive.isFetching = false;
      currentUnSensitive.isUnSensitive = true;
      currentUnSensitive.timeStamp = Date.now();

      unSensitive[payload] = currentUnSensitive;

      return Object.assign({}, state, {
        unSensitive,
      });
    },
    [approveMeshCmdActions.request]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.Id]: {
            isFetching: true,
            invalid: false,
            done: false,
          },
        },
      };
    },
    [approveMeshCmdActions.invalid]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.Id]: {
            isFetching: false,
            invalid: true,
            done: false,
          },
        },
      };
    },
    [approveMeshCmdActions.receive]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.Id]: {
            isFetching: false,
            invalid: false,
            done: true,
          },
        },
      };
    },
    [rejectMeshCmdActions.request]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.meshMsgId]: {
            isFetching: true,
            invalid: false,
            done: false,
          },
        },
      };
    },
    [rejectMeshCmdActions.invalid]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.meshMsgId]: {
            isFetching: false,
            invalid: true,
            done: false,
          },
        },
      };
    },
    [rejectMeshCmdActions.receive]: (state, { payload }) => {
      return {
        ...state,
        actuallyExecuteUpdates: {
          ...state.actuallyExecuteUpdates,
          [payload.meshMsgId]: {
            isFetching: false,
            invalid: false,
            done: true,
          },
        },
      };
    },
  },
  initialState,
);
