import styled from 'styled-components';

export const EntityPropertiesPageWrapper = styled.div`
  background: #ffffff;
  height: 100%;
  margin: ${({ removeMargin }) => (removeMargin ? '-10px 0 0 0' : '0')};
  /* margin: 0; */
  padding-top: 4px;
  width: 100%;
`;
