import React from 'react';
import Alert from 'uxi/Alert';
import {
  withConfirmDialog,
  TextField,
  Checkbox,
  ConfirmCancelDialog,
  FormDecorator,
  CancelButton,
  PrimaryButton,
} from '@cluedin/form';
import { PanelContent, PanelFooter } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

const CleanGQlQueryConfiguration = ({
  validSearchFilter,
  intl,
  projectName,
  setProjectName,
  searchStat,
  hasMinimalSearchValue,
  onlyOneEntityType,
  cleanDataParts,
  setCleanDataParts,
  providerTypes = [],
  entityType,
  onCancel,
  onNext,
  pageNumber,
  setPageNumber,
  pageNumberTooBig,
  pageNumberTooSmall,
  maxPage,
}) => {
  const isValidCancel = !!projectName;
  const ButtonWithConfirm = isValidCancel
    ? withConfirmDialog(CancelButton, ConfirmCancelDialog)
    : CancelButton;

  let errorMessage;

  if (pageNumberTooBig) {
    errorMessage = 'Please provide a lower value';
  }

  if (pageNumberTooSmall) {
    errorMessage = 'Value must be at least 1';
  }

  return (
    <>
      <PanelContent>
        {hasMinimalSearchValue ||
          (!onlyOneEntityType && (
            <Alert>
              <FormattedMessage id="module-clean-cleanRefineSearch" />
            </Alert>
          ))}
        {!onlyOneEntityType && (
          <div style={{ padding: '16px' }}>
            <FormattedMessage id="module-clean-cleanSupportsOnly" />
            &nbsp;
            <span style={{ fontWeight: 'bold' }}>
              <FormattedMessage id="module-clean-cleanOneEntityType" />
            </span>
            .
          </div>
        )}
        {validSearchFilter && (
          <div>
            <FormDecorator
              isRequired
              label={<FormattedMessage id="module-clean-cleanProjectName" />}
            >
              <TextField
                placeholder={intl.formatMessage({
                  id: 'module-clean-cleanProjectNamePlaceholder',
                })}
                value={projectName}
                onChange={(e, value) => {
                  setProjectName(value);
                }}
              />
            </FormDecorator>
            <div style={{ margin: '12px 0' }}>
              <FormattedMessage id="module-clean-cleanDatasetSize" />
              &nbsp;
              <span style={{ fontWeight: 'bold' }}>{searchStat}</span>
              &nbsp;
              <FormattedMessage id="module-clean-cleanEntities" />
            </div>
          </div>
        )}
        {validSearchFilter && (
          <div style={{ margin: '12px 0' }}>
            <FormattedMessage id="module-clean-cleanSelectedEntityType" />
            &nbsp;
            <strong>{entityType}</strong>
          </div>
        )}
        {validSearchFilter && providerTypes && providerTypes.length > 0 && (
          <div style={{ margin: '12px 0' }}>
            {providerTypes.length == 1 ? (
              <>
                <FormattedMessage id="module-clean-cleanSelectedIntegrationSingular" />
                &nbsp;
              </>
            ) : (
              <>
                <FormattedMessage id="module-clean-cleanSelectedIntegrationPlural" />
                &nbsp;
              </>
            )}
            <strong>{providerTypes.join(', ')}</strong>
          </div>
        )}
        {validSearchFilter && providerTypes.length > 0 && (
          <Checkbox
            label={
              <FormattedMessage id="module-clean-cleanWithHistoryCheckbox" />
            }
            checked={cleanDataParts}
            onChange={() => setCleanDataParts(!cleanDataParts)}
          />
        )}
        {validSearchFilter && hasMinimalSearchValue && (
          <>
            <FormDecorator
              isRequired
              label={<FormattedMessage id="module-clean-pageNumber" />}
              helpText={
                <div>
                  <FormattedMessage id="module-clean-cleanHasA" />
                  &nbsp;
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="module-clean-cleanMaximumCapacity" />
                  </span>
                  .
                  <br />
                  <br />
                  <FormattedMessage id="module-clean-cleanMaximumCapacity-startsAt1" />
                  &nbsp;{maxPage}
                </div>
              }
            >
              <TextField
                value={pageNumber}
                type="number"
                errorMessage={errorMessage}
                onChange={(e, value) => {
                  setPageNumber(value);
                }}
              />
            </FormDecorator>
          </>
        )}
      </PanelContent>
      <PanelFooter>
        <ButtonWithConfirm
          confirmTitle={
            <FormattedMessage id="data-source-confirm-lost-changes-title" />
          }
          confirmMessage={
            <FormattedMessage id="data-source-confirm-lost-changes-message" />
          }
          onConfirm={onCancel}
          data-test={'cancelFirstStepCreateCleanProjectFromSearch'}
          onClick={onCancel}
        >
          <FormattedMessage id="data-source-cancel" />
        </ButtonWithConfirm>
        <PrimaryButton
          style={{ marginLeft: '8px' }}
          data-test={'nextFirstStepCreateCleanProjectFromSearch'}
          disabled={
            !validSearchFilter ||
            !projectName ||
            pageNumberTooBig ||
            pageNumberTooSmall
          }
          onClick={() => {
            if (validSearchFilter && projectName) {
              onNext();
            }
          }}
        >
          <FormattedMessage id="data-source-next" />
        </PrimaryButton>
      </PanelFooter>
    </>
  );
};

export default CleanGQlQueryConfiguration;
