import styled from 'styled-components';

export const EntityTopologyPageWrapper = styled.div`
  flex: 1;
  height: calc(100vh - 161px);
  margin: ${({ removeMargin }) => (removeMargin ? '-10px 0 0 0' : '0')};
  overflow: hidden;
  position: relative;
`;

export const EntityTopologyOverlay = styled.div`
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px) grayscale(1) opacity(1) saturate(0);
  bottom: 0;
  height: 100%;
  min-height: 100%;
  left: 0;
  width: 100%;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 112px;
  transition: all 200ms linear;
  z-index: 100;
`;

export const EntityTopologyOverlayWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const EntityTopologyOverlayTextWrapper = styled.div`
  align-items: center;
  content: '';
  background: #ffffff;
  border-left: ${({ themePrimary }) =>
    themePrimary ? `4px solid ${themePrimary}` : '4px solid #29A29A'};
  border-radius: 4px;
  box-shadow: 0px 3px 12px #c8c6c4;
  display: flex;
  height: 100px;
  max-height: fit-content;
  min-height: 100px;
  justify-content: center;
  width: 500px;
  max-width: fit-content;
  min-width: 500px;
  padding: 8px 16px;
  transition: all 200ms linear;
  z-index: 9999;
`;

export const EntityTopologyOverlayTextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const EntityTopologyOverlayTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
`;

export const EntityTopologyOverlayText = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 100%;
  justify-content: center;
  transition: all 200ms linear;
  width: 100%;
`;

export const EntityTopologyOverlayIcon = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  padding-left: 8px;
  padding-top: 8px;
  transition: all 200ms linear;
  width: auto;

  & div,
  svg {
    align-items: center;
    display: flex !important;
    height: 100%;
    justify-content: center;
  }
`;
