import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import { compose, withStateHandlers } from 'recompose';
import ShowMoreDetails from '../ShowMoreDetails';

const DefaultError = ({ hasMultiple, moreDetails, showMore, toggle }) => (
  <Alert showClose style={{ margin: '15px', fontSize: '14px' }}>
    <div style={{ fontSize: '16px', fontWeight: 'bold', paddingBottom: '6px' }}>
      {hasMultiple ? (
        <FormattedMessage id="module-error-defaultQueuedMultipleTitle" />
      ) : (
        <FormattedMessage id="module-error-defaultQueuedTitle" />
      )}
    </div>
    <div>
      {hasMultiple ? (
        <FormattedMessage id="module-error-defaultQueuedExplanationMultiple" />
      ) : (
        <FormattedMessage id="module-error-defaultQueuedExplanation" />
      )}
    </div>
    {moreDetails && <ShowMoreDetails showMore={showMore} toggle={toggle} />}
    {moreDetails && showMore ? (
      <div style={{ marginTop: '12px' }}>{moreDetails}</div>
    ) : null}
  </Alert>
);

export default compose(
  withStateHandlers(
    () => ({
      showMore: false,
    }),
    {
      toggle:
        ({ showMore }) =>
        () => ({
          showMore: !showMore,
        }),
    },
  ),
)(DefaultError);
