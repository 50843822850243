import styled from 'styled-components';

export const EdgePropertiesPanelEdgeInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 14px 14px 14px 28px;
  transition: all 100ms ease;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;
