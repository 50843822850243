"use strict";

exports.__esModule = true;
exports.getValidation = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _merge = _interopRequireDefault(require("lodash/merge"));
var _FormValidation = require("../../FormValidation");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 *
 * validate: {
        positive: value => parseInt(value, 10) > 0,
        lessThanTen: value => parseInt(value, 10) < 10
      }
 */
var getValidation = function getValidation(field) {
  var result = {
    validate: {}
  };
  if (!field) {
    return result;
  }
  if (field.isRequired) {
    result.validate.required = _FormValidation.required;
    result.validate.isEmpty = _FormValidation.isEmpty;
  }
  if (field.type === 'url') {
    result.validate.url = _FormValidation.url;
  }
  var validate = (0, _merge.default)(result.validate, (0, _get.default)(field, 'rules'));
  return {
    validate: validate
  };
};
exports.getValidation = getValidation;