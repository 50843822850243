import { getLogoFromGql, getIcon } from './logo';
import { hasEntityConfigSetup } from '../../../../../wms/helpers/widgetHelper';
import { uniqProviderBasedOnUrisAndProviderCodes } from '../../../../../entity/selectors';
import { createViewModelFromPropertyPools } from './baseViewModel';
import { getEntityTemporalDataFromGql } from './entityDates';
import { createSocialViewModel } from './social';

const personPool = [
  {
    key: 'email',
    pool: ['property-Email', 'property-person.email', 'property-user.email'],
  },
  {
    key: 'jobTitle',
    pool: [
      'property-user.jobTitle',
      'property-person.jobTitle',
      'property-user.title',
      'property-linkedin.person.headline',
      'property-Title',
    ],
  },
  {
    key: 'phoneNumber',
    pool: ['property-person.phoneNumber'],
  },
  {
    key: 'email',
    pool: ['property-EMAIL'],
  },
];

export const createPersonViewModelFromGql = (
  entity = {},
  entityConfigurations = [],
) => {
  const {
    id,
    name,
    description,
    uris,
    providerOrigins,
    properties,
    entityType,
  } = entity;

  const viewModelWithProperties = createViewModelFromPropertyPools(
    properties || {},
    personPool,
  );

  const logo = getLogoFromGql(entity);
  const icon = getIcon(entity, entityConfigurations);
  const isEntityConfigured = hasEntityConfigSetup(
    entityType,
    entityConfigurations,
  );
  const social = createSocialViewModel(entity) || {};
  const configurationSources = uris || [];
  const createdDate = getEntityTemporalDataFromGql(entity);
  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    uris,
    providerOrigins || [],
  );

  return {
    entityId: id,
    name,
    logo,
    icon,
    description: description || 'no snippet',
    ...viewModelWithProperties,
    social,
    configurationSources,
    createdDate,
    providers,
    isEntityConfigured,
  };
};
