import { createAction } from 'redux-actions';

export const changePrimaryColor = createAction(
  'THEME_CHANGE_UPDATE_THEME_COLOR_PRIMARY',
  (color) => color,
);
export const changeSecondaryColor = createAction(
  'THEME_CHANGE_UPDATE_THEME_COLOR_SECONDARY',
  (color) => color,
);
export const updateThemeWithOrg = createAction(
  'THEME_CHANGE_UPDATE_THEME_FROM_ORG',
  ({ PrimaryColor, SecondaryColor }) => ({
    primaryColor: PrimaryColor,
    secondaryColor: SecondaryColor,
  }),
);
