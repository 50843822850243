import React from 'react';
import { ReactComponent as DeduplicationIcon } from '@cluedin/svgs/icons/deduplication.svg';
import { MergeIco } from '@cluedin/svgs';
import { Access } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import PropTypes from 'prop-types';

import { EntityTopologyConnectingNodeIconWrapper } from './EntityTopologyConnectingNodeIcon.styles';

const EntityTopologyConnectingNodeIcon = ({ dataType }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const themeWhite = theme?.palette?.white;

  let iconToShow;
  if (dataType?.toLowerCase() === 'entitycode') {
    iconToShow = <Access size={30} />;
  }
  if (dataType?.toLowerCase() === 'manualmerge') {
    iconToShow = <MergeIco style={{ width: 30, height: 30 }} />;
  }
  if (dataType?.toLowerCase() === 'deduplicationprojectmerge') {
    iconToShow = <DeduplicationIcon size={30} />;
  }

  return (
    <EntityTopologyConnectingNodeIconWrapper
      themePrimary={themePrimary}
      themeWhite={themeWhite}
      dataType={dataType?.toLowerCase()}
    >
      {iconToShow}
    </EntityTopologyConnectingNodeIconWrapper>
  );
};

export default EntityTopologyConnectingNodeIcon;

EntityTopologyConnectingNodeIcon.propTypes = {
  dataType: PropTypes.string,
};

EntityTopologyConnectingNodeIcon.defaultProps = {
  dataType: '',
};
