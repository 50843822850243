import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import SignupUser from '../composites/SignupUser';
import { clientId } from '../../../../config';
import { shouldFetchSignUser } from '../../actions/createUser';
import {
  withSuccess,
  withIsFetching,
  withInvalid,
} from '../../../core/components/Hocs/defaults';

const EnhancedSignUpUserForm = compose(
  withIsFetching(),
  withInvalid({
    InvalidEntityMessage: (
      <div>
        <FormattedMessage id="module-public-signUpUser-errorInvitation" />
      </div>
    ),
  }),
  withSuccess({
    SuccessMessage: (
      <div>
        <FormattedMessage id="module-public-signUpUser-success" />
      </div>
    ),
  }),
)(SignupUser);

const mapToStateProps = (
  { createUser: { isFetching, done, invalid } },
  { email },
) => ({
  isMutating: isFetching,
  done,
  email,
  isFetching,
  invalid,
});

const mapDispatchToProps = (
  dispatch,
  { email, invitationId, isFromEmailDomain, isAdmin },
) => ({
  onSignupUser: ({ password }) => {
    dispatch(
      shouldFetchSignUser({
        username: email,
        password,
        invitationId,
        isFromEmailDomain,
        isAdmin,
        email,
        clientId,
      }),
    );
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(EnhancedSignUpUserForm);
