import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import Flex from 'uxi/Layout/Flex';
import Img from 'uxi/Img/Img';

const EntitySmallCardUI = styled.div`
  box-sizing: border-box;
  padding: 8px;
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid #cecece;
  border-radius: ${({ theme }) => theme.radius};
`;

const EntitySmallCard = (props) => {
  const { style, entity, children } = props;

  if (!entity) {
    return 'Entity Not Found';
  }
  const { name, previewUrl, data: { entityType } = {} } = entity;

  return (
    <EntitySmallCardUI style={style}>
      {previewUrl ? (
        <Img
          contain
          width={68}
          src={previewUrl}
          style={{ padding: '8px', boxSizing: 'border-box' }}
        />
      ) : (
        <span
          style={{
            width: '68px',
            height: '68px',
            backgroundColor: '#dadada',
            padding: '8px',
            boxSizing: 'border-box',
          }}
        />
      )}
      <Flex style={{ flexFlow: 'row wrap', justifyContent: 'flex-start' }}>
        <Flex
          style={{
            padding: '0 8px',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <small style={{ fontSize: '11px', color: 'grey' }}>
            <FormattedMessage id="module-entityMerge-name" />:
          </small>
          <strong style={{ fontSize: '13px' }}>{name}</strong>
        </Flex>
        <Flex
          style={{
            padding: '0 8px',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <small style={{ fontSize: '11px', color: 'grey' }}>
            <FormattedMessage id="module-entityMerge-entityType" />:
          </small>
          <strong style={{ fontSize: '13px' }}>{entityType}</strong>
        </Flex>
      </Flex>
      {children}
    </EntitySmallCardUI>
  );
};

export default EntitySmallCard;
