import { apiRequest } from '../core/helpers/request';
import { logException } from '../core/helpers/logger';
import { createShortPartViewModel } from './viewModel';

export const getShortPath = ({ sourceId, targetId }) =>
  apiRequest('GET', `api/entity/path?targetId=${targetId}`).then((json) => {
    try {
      return createShortPartViewModel(sourceId, targetId, json ? json[0] : []);
    } catch (e) {
      logException(e);
      return [];
    }
  });

export const getShortPathPeople = ({ sourceId, targetId }) =>
  apiRequest('GET', `api/v1/entity/peoplepath?targetId=${targetId}`).then(
    (json) => {
      try {
        return createShortPartViewModel(
          sourceId,
          targetId,
          json ? json[0] : [],
        );
      } catch (e) {
        logException(e);
        return [];
      }
    },
  );
