import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../core/helpers/action';
import { signUp } from '../data';

export const shouldFetchSignUpActions = createAsyncAction(
  'PUBLIC_SHOULD_FETCH_SIGNUP',
);
export const shouldFetchSignUp = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(signUp, shouldFetchSignUpActions),
  {
    onErrorAction: shouldFetchSignUpActions.invalid,
  },
);
