import ActionKpi from './ActionKpi';
import Kpi from './Kpi';
import DashboardLink from './DashboardLink';

export const widgetTypes = ['ActionKpi', 'Kpi', 'DashboardLink', 'custom'];

export const widgetDashboardTypes = {
  ActionKpi,
  Kpi,
  DashboardLink,
};
