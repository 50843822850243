import type from '../type';

export default function (value) {
  let empty = false;

  if (type(value) === 'null' || type(value) === 'undefined') {
    empty = true;
  } else if (type(value) === 'object') {
    empty = Object.keys(value).length === 0;
  } else if (type(value) === 'boolean') {
    empty = value === false;
  } else if (type(value) === 'number') {
    empty = value === 0 || value === -1;
  } else if (type(value) === 'array' || type(value) === 'string') {
    empty = value.length === 0;
  }

  return empty;
}
