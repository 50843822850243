import React, { memo } from 'react';
import { injectIntl } from '@cluedin/locale';
import { connect } from 'react-redux';

import Notifications from '../../../notifications/components/containers/CluedInNotification';
import EntitiesSelectionManager from '../../../entityMerge/components/containers/EntitiesSelectionManager';
import TokenRefresherContainer from '../../../auth/components/containers/TokenRefresherContainer';

import { shouldClearNotification } from '../../../notifications/actions';

import UserFeedbackContainer from './UserFeedbackContainer';

import CoreDataError from '../composites/CoreDataError/index';
import { ContentWithExtra } from '../composites/Layout';
import { useServerStatus } from '../composites/TopMenu/ServerStatus/useServerStatus';

const PageContentContainer = memo(
  ({
    children,
    newNotif,
    isMerging,
    invalidOrg,
    invalidUser,
    invalidConfig,
    hasSessionExpired,
    routerLocation = {},
    hasInvalidRequiredCall,
  }) => {
    const [statuses, statusLoading, statusError, globalStatus] =
      useServerStatus();

    const { pathname } = routerLocation;

    const showInnerInlineAppDrawer = isMerging;

    const showCoreError =
      (hasInvalidRequiredCall ||
        (globalStatus === 'down' && !statusLoading) ||
        statusError) &&
      !hasSessionExpired &&
      pathname !== '/logout';

    if (showCoreError) {
      return (
        <CoreDataError
          statuses={statuses}
          statusLoading={statusLoading}
          statusError={statusError}
          globalStatus={globalStatus}
          invalidConfig={invalidConfig}
          invalidUser={invalidUser}
          invalidOrg={invalidOrg}
        />
      );
    }

    return (
      <ContentWithExtra
        extraMinWidth={showInnerInlineAppDrawer ? '360px' : '0'}
        contentMinWidth={showInnerInlineAppDrawer ? '288px' : '100%'}
        extra={<EntitiesSelectionManager />}
      >
        {children}

        <Notifications />
        <UserFeedbackContainer />

        <TokenRefresherContainer />
      </ContentWithExtra>
    );
  },
);

const mapToStateProps = ({
  error: { sessionExpiredGlobalMessages },
  configuration,
  org,
  user,
  entityMerge,
}) => ({
  hasSessionExpired:
    sessionExpiredGlobalMessages && sessionExpiredGlobalMessages.length > 0,
  // Major fails: organization, user, schema, configurations
  // INVALID_CONFIGURATION_ALL_CONFIGURATIONS
  // INVALID_CURRENT_ORGANIZATION_V2
  // USER_INVALID_CURRENT_USER
  // INVALID_FETCH_GET_IS_ACCOUNT_AVAILABLE ??
  invalidConfig: configuration.invalidAllActiveConfigurations,
  invalidOrg: org.invalidOrganization,
  invalidUser: user.invalidCurrentUser,
  hasInvalidRequiredCall:
    configuration.invalidAllActiveConfigurations ||
    org.invalidOrganization ||
    user.invalidCurrentUser,
  currentOrganization: org.organization,
  isMerging: entityMerge.isMerging,
});

export default connect(mapToStateProps, null)(injectIntl(PageContentContainer));
