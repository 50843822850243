import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { ContentWithStickyExtra, AppLayout } from 'uxi/Layout';
import EntityListPropertiesSelectorContainerDecorated from '../../../../../entityLists/components/containers/EntityListPropertiesSelectorContainer';
import { useResizeObserver } from '../../../../../core/hooks/layout';

const contentHeight = [
  'calc(',
  '100vh', // viewport
  '- 48px', // AppShell header
  '- 32px', // spacing
  ')',
].join(' ');

const extraMinWidth = 280;
const contentMinWidth = 320;
const minWidthForTwoColumns = extraMinWidth + contentMinWidth + 9;

/* eslint-disable indent */
const ExtendedAppLayout = styled(AppLayout)`
  min-width: calc(285px - 16px);

  @media (min-height: 580px) {
    ${({ containerWidth }) =>
      minWidthForTwoColumns < containerWidth
        ? `max-height: ${contentHeight};`
        : ''};
  }

  & > div {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  & > div:nth-child(2) {
    z-index: 0;
  }
`;
/* eslint-enable indent */

const SearchPageContentLayoutWrapper = styled.div`
  margin: 8px;
`;

const SearchPageContentLayout = ({
  searchHeader,
  searchFilter,
  searchResults,
  searchPropertyFilter,
  searchOption,
}) => {
  const ref = useRef();
  const [containerWidth, setContainerWidth] = useState();

  useResizeObserver(ref, (contentRect) => {
    const { width } = contentRect;
    setContainerWidth(width);
  });

  return (
    <SearchPageContentLayoutWrapper ref={ref}>
      <ContentWithStickyExtra
        extra={<div style={{ margin: '8px' }}>{searchFilter}</div>}
        extraMinWidth={`${extraMinWidth}px`} // 285 + 16
        contentMinWidth={`${contentMinWidth}px`} // 285 + 16
        mainScrollingElementSelector={'.actualMainScrollingElem'}
      >
        <div
          style={{
            boxShadow:
              '0 0px 1px 2px rgba( 60, 60, 60, .09), 0 1px 4px rgba( 60, 60, 60, .18)',
            margin: '8px',
          }}
        >
          <ExtendedAppLayout containerWidth={containerWidth}>
            <div>
              <div>{searchHeader}</div>
              <div>{searchPropertyFilter}</div>
              <div>{searchOption}</div>
            </div>
            <div style={{ background: '#fff' }}>{searchResults}</div>
          </ExtendedAppLayout>
        </div>
      </ContentWithStickyExtra>

      <EntityListPropertiesSelectorContainerDecorated viewName={'search'} />
    </SearchPageContentLayoutWrapper>
  );
};

export default SearchPageContentLayout;
