import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';
import { legacyClient } from '../../../../../../data/graphql';
import { createFiltersViewModelsFromGql } from '../viewModels/searchFilter';

// TODO: HARDCODED SORT BY NEW
const GET_AGGREGATIONS = gql`
  query aggregateQuery($query: String) {
    aggregate(query: $query) {
      aggregations
    }
  }
`;

// TODO => The FILTERS view model IS WAY TOO OPINATED AND HAS LOGIC UI
const useQuerySearchAggregation = () => {
  const [skip, setSkip] = useState(false);
  const [agg, setAgg] = useState();
  const { data, loading, error } = useQuery(GET_AGGREGATIONS, {
    skip,
    client: legacyClient,
    variables: {
      query: '*',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && !!data) {
      setSkip(true);
      const aggregations = get(data, 'aggregate.aggregations');
      setAgg(aggregations);
    }
  }, [data, loading]);

  return [
    agg,
    {
      loading,
      isFetching: loading,
      error,
    },
  ];
};

export const useSearchAggregation = (entityConfigurations = []) => {
  const [data, { loading, error }] = useQuerySearchAggregation();

  const filterViewModels = createFiltersViewModelsFromGql(
    data || {},
    entityConfigurations,
  );

  const promotedFilters = ['entityType', 'providers'].reduce(
    (accu, promotedFilterType) => {
      // eslint-disable-next-line no-param-reassign
      accu[promotedFilterType] = filterViewModels[promotedFilterType];
      return accu;
    },
    {},
  );

  return [
    filterViewModels,
    {
      loading,
      isFetching: loading,
      promotedFilters,
      error,
    },
  ];
};
