"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("@cluedin/theme");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var fade = _theme.colorManipulator.fade;
var _default = function _default(ListItem) {
  return (0, _styledComponents.default)(ListItem)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n  text-decoration: none;\n  width: auto;\n  box-sizing: border-box;\n  text-align: left;\n\n  &.focusVisible {\n    background-color: ", ";\n  }\n\n  &.selected {\n    background-color: ", ";\n\n    &.focusVisible {\n      background-color: ", ";\n    }\n  }\n\n  &.disabled {\n    opacity: ", ";\n  }\n\n  &.container {\n    position: relative;\n  }\n\n  &.alignItemsFlexStart {\n    align-items: flex-start;\n  }\n\n  &.divider {\n    border-bottom: 1px solid ", ";\n    background-clip: padding-box;\n  }\n\n  ,\n  & . withHover {\n    &:hover {\n      svg,\n      span {\n        color: ", ";\n      }\n    }\n  }\n\n  &.button {\n    transition: ", ";\n\n    &:hover {\n      svg,\n      span {\n        color: ", ";\n      }\n\n      background-color: ", ";\n      @media (hover: none) {\n        background-color: transparent;\n      }\n    }\n\n    &.selected:hover {\n      background-color: ", ";\n      @media (hover: none) {\n        background-color: ", ";\n      }\n    }\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.action.focus;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return fade(theme.colors.primary.main, theme.colors.action.selectedOpacity);
  }, function (_ref3) {
    var theme = _ref3.theme;
    return fade(theme.colors.primary.main, theme.colors.action.selectedOpacity + theme.colors.action.focusOpacity);
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.action.disabledOpacity;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.colors.divider;
  }, function (_ref6) {
    var theme = _ref6.theme;
    return theme.colors.accent.main;
  }, function (_ref7) {
    var theme = _ref7.theme;
    return theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest
    });
  }, function (_ref8) {
    var theme = _ref8.theme;
    return theme.colors.accent.main;
  }, function (_ref9) {
    var theme = _ref9.theme;
    return theme.colors.action.hover;
  }, function (_ref10) {
    var theme = _ref10.theme;
    return fade(theme.colors.primary.main, theme.colors.action.selectedOpacity + theme.colors.action.hoverOpacity);
  }, function (_ref11) {
    var theme = _ref11.theme;
    return fade(theme.colors.primary.main, theme.colors.action.selectedOpacity);
  });
};
exports.default = _default;