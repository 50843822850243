import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrimaryButton, FormDecorator } from '@cluedin/form';
import { Step, Stepper, StepButton } from 'uxi/Stepper';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import Alert from 'uxi/Alert';
import { Flex } from 'uxi/Layout';
import { TextField } from 'uxi/Input';

import { isUserBlockedAndCurrentUserExcluded } from '../../../../user/helper';
import UserListContainer from '../../../../user/components/containers/lists/UserListContainer';
import {
  shouldEscalateForUsersAndSarIds,
  shouldResetEscalateDialog,
} from '../../../actions';

class GdprEscalate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      step: 0,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  onChangeHandler(e, selectedIndex, selectedRows) {
    this.setState({
      userId: selectedRows[0],
    });
  }

  handleClose() {
    const { onClose, resetEscalateState } = this.props;

    // reset redux store state
    resetEscalateState();

    // reset local compo state
    this.setState({
      userId: null,
      step: 0,
    });

    // consumer callback
    if (onClose) {
      onClose();
    }
  }

  render() {
    const {
      show,
      isEscalating,
      onEscalate,
      escalatingError,
      sarIds = [],
      currentUser,
    } = this.props;

    const { userId, step, reason } = this.state;
    if (!show) {
      return null;
    }

    const alertContent = (
      <Alert type="danger">
        <FormattedMessage id="module-gdpr-error" />
      </Alert>
    );

    return (
      <Dialog onClose={this.handleClose} show={show}>
        <Panel onClose={this.handleClose}>
          <PanelHeader title={'Select Users'} />
          <PanelContent
            style={{
              maxHeight: 'calc(80vh - calc( 2 * 50px ))',
              minHeight: '60vh',
            }}
          >
            {escalatingError && alertContent}

            <div style={{ borderBottom: '1px solid #cecece' }}>
              <Stepper linear activeStep={step + 1} nowrap>
                <Step>
                  <StepButton onClick={() => this.setState({ step: 0 })}>
                    <FormattedMessage id="module-gdpr-escalate-steps-ChoosePerson" />
                  </StepButton>
                </Step>
                <Step>
                  <StepButton onClick={() => {}}>
                    <FormattedMessage id="module-gdpr-escalate-steps-JustifyEscalation" />
                  </StepButton>
                </Step>
              </Stepper>
            </div>
            {step === 0 ? (
              <UserListContainer
                onChange={this.onChangeHandler.bind(this)}
                filter={(user) =>
                  isUserBlockedAndCurrentUserExcluded(user, currentUser)
                }
                condensed
                multiSelectable={false}
              />
            ) : (
              <Flex
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '32px',
                }}
              >
                <FormDecorator
                  label={<FormattedMessage id="module-consent-reason" />}
                  helpText={
                    <FormattedMessage id="module-consent-esclation-justification-helptext" />
                  }
                  style={{ width: '100%' }}
                >
                  <TextField
                    isFullWidth
                    style={{ width: '100%', maxWidth: '500px' }}
                    onChange={(event, value) => {
                      this.setState({ reason: value });
                    }}
                  />
                </FormDecorator>
              </Flex>
            )}
          </PanelContent>
          <PanelFooter hasCancel>
            {step === 0 ? (
              <PrimaryButton
                loading={isEscalating}
                onClick={() => {
                  this.setState({ step: 1 });
                }}
                disabled={!userId}
                text={<FormattedMessage id="module-gdpr-choosePerson" />}
              />
            ) : (
              <PrimaryButton
                disabled={isEscalating}
                loading={isEscalating}
                onClick={() => {
                  onEscalate(sarIds, userId, reason);
                }}
                text={
                  escalatingError ? (
                    <FormattedMessage id="module-gdpr-retry" />
                  ) : (
                    <FormattedMessage id="module-gdpr-proceed" />
                  )
                }
              />
            )}
          </PanelFooter>
        </Panel>
      </Dialog>
    );
  }
}

GdprEscalate.displayName = 'GdprEscalate';
GdprEscalate.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  isEscalating: PropTypes.bool,
  onEscalate: PropTypes.func,
  resetEscalateState: PropTypes.func,
  escalatingError: PropTypes.bool,
  sarIds: PropTypes.array,
};
GdprEscalate.defaultProps = {
  show: false,
  onClose: () => {},
  isEscalating: false,
  onEscalate: () => {},
  resetEscalateState: () => {},
  escalatingError: false,
  sarIds: [],
};

const mapStateToProps = ({
  GDPR: { isEscalating, escalatingError },
  user: { currentUser },
}) => ({
  isEscalating,
  escalatingError,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onEscalate: (sarIds, entityIdToEscalateTo, reason) => {
    dispatch(
      shouldEscalateForUsersAndSarIds(sarIds, entityIdToEscalateTo, reason),
    );
  },
  resetEscalateState: () => {
    dispatch(shouldResetEscalateDialog());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GdprEscalate);
