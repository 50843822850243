import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';

const GET_MANUAL_DATA_ENTRY_PROJECTS = gql`
  query getManualDataEntryProjects($pageNumber: Int, $pageSize: Int) {
    management {
      manualDataEntryProjects(pageNumber: $pageNumber, pageSize: $pageSize) {
        total
        data {
          id
          title
          description
          entityType
          createdAt
          createdBy
          modifiedAt
          modifiedBy
          entityTypeConfiguration {
            icon
            displayName
            entityType
          }
          createdByUser {
            id
            email
            username
          }
          formFields {
            id
            label
            description
            vocabularyKey
            type
            parameters
            isRequired
            onlyExistingValues
            onlyAllowedValues
            preventForbiddenValues
            isArchived
          }
        }
      }
    }
  }
`;

export const useQueryManualDataEntry = ({ page, pageSize }) => {
  const { data, refetch, loading, error } = useQuery(
    GET_MANUAL_DATA_ENTRY_PROJECTS,
    {
      variables: {
        pageNumber: +page - 1,
        pageSize: +pageSize,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const projects = get(data, 'management.manualDataEntryProjects.data');
  const total = get(data, 'management.manualDataEntryProjects.total');
  return {
    total,
    error,
    loading,
    refetch,
    data: projects,
  };
};
