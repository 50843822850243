import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Dashboard from '../composites/Dashboard';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  goToApplications: () =>
    dispatch(push('/admin/inbound/integration/applications')),
  goToConfigurations: () => dispatch(push('/admin/inbound/configuration')),
  goToDatasources: () => dispatch(push('/admin/inbound/datasourceset')),
  goToManualDataEntryProject: () =>
    dispatch(push('/admin/inbound/manual-data-entry')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
