import React from 'react';
import PropTypes from 'prop-types';
import Dashboard from '../../../core/components/composites/dashboard/Dashboard';

const HomeDashboard = (props) => {
  const { match, dashboardDescriptor } = props;

  const homeDashboardDescriptor = dashboardDescriptor;

  return (
    <Dashboard match={match} dashboardDescriptor={homeDashboardDescriptor} />
  );
};

HomeDashboard.displayName = 'HomeDashboard';

HomeDashboard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomeDashboard;
