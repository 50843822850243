import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton, Modal, CancelButton } from '@cluedin/form';

export const withDialogWorkflowWrapper =
  (Comp, options = {}) =>
  (props) => {
    return (
      <Modal
        isOpen={props.open}
        onClose={props.onClose}
        style={props.style}
        title={props.title}
        width={props.width}
        height={props.height}
        bodyStyles={props.bodyStyles}
        footer={{
          content: (
            <>
              <CancelButton
                rounded
                style={{ marginRight: '8px' }}
                onClick={props.onClose}
                text={<FormattedMessage id="cancel" />}
              />

              <PrimaryButton
                rounded
                disabled={
                  options?.mainActionDisabled ||
                  props?.mainActionDisabled ||
                  !props.onMainButtonClick
                }
                onClick={(e) => {
                  props.onMainButtonClick(e, {
                    onClose: props.onClose,
                    dialogName: options.dialogName,
                  });
                }}
                text={options.mainButtonLabel || props.mainButtonLabel}
              />
            </>
          ),
        }}
      >
        {props.open && <Comp onClose={props.onClose} {...props} />}
      </Modal>
    );
  };

export default {
  withDialogWorkflowWrapper,
};
