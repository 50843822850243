import React from 'react';
import { Widget } from 'uxi/Widget';

const MainWidgetForPage = (props) => (
  <>
    <Widget {...props}>{props.children}</Widget>
  </>
);

export default MainWidgetForPage;
