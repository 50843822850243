import React from 'react';
import { H5 } from 'uxi/Classic';
import { FormattedMessage } from '@cluedin/locale';
import RelativeTextPlaceholder from '../../../../../../../core/components/composites/Placeholder/RelativeTextPlaceholder';
import FilterGroupMeta from './FilterGroupMeta';

const FilterGroupTitle = ({
  title,
  isFetching,
  filteredCount,
  totalCount,
  show,
  isRemoteFiltering,
  selectedCount,
  currentFilterQuery,
  currentEntityTypePoolIdentifier,
}) => {
  return (
    <H5 style={{ marginTop: '6px', display: 'flex', alignItems: 'baseline' }}>
      {title ? (
        <FormattedMessage
          id={`SearchContent.searchFilterFacetsName.${title}`}
          defaultMessage={title}
        />
      ) : (
        <RelativeTextPlaceholder style={{ width: '43.5%' }} />
      )}
      <FilterGroupMeta
        style={{ marginLeft: 'auto' }}
        filteredCount={filteredCount}
        isRemoteFiltering={isRemoteFiltering}
        selectedCount={selectedCount}
        totalCount={totalCount}
        isFetching={isFetching}
        show={show}
        currentEntityTypePoolIdentifier={currentEntityTypePoolIdentifier}
        currentFilterQuery={currentFilterQuery}
      />
    </H5>
  );
};

FilterGroupTitle.displayName = 'FilterGroupTitle';

export default FilterGroupTitle;
