import {
  AppOrClientPagePublicChooser,
  OnlyAppSubDomain,
  OnlyClientIdSubDomain,
  SSOLogin,
} from '../core/components/Hocs/SubdomainChooser';
import { hasFeature } from '../../config';
import SignUpWithEmailDomain from './components/pages/SignUpWithEmailDomain';
import Signup from './components/pages/Signup';
import ForgotPwd from './components/pages/ForgotPwd';
import ResetPwd from './components/pages/ResetPwd';
import CreateOrgAndLogin from './components/pages/CreateOrgAndLogin';
import SignupUser from './components/pages/SignupUser';

const disabledSignUp = hasFeature('disable-org-signup');

const signUpPage = disabledSignUp
  ? OnlyClientIdSubDomain(SSOLogin(SignUpWithEmailDomain, 'login'))
  : AppOrClientPagePublicChooser(
      SSOLogin(SignUpWithEmailDomain, 'login'),
      Signup,
    );

export default [
  {
    path: '/signup',
    skipAuth: true,
    component: signUpPage,
  },
  {
    path: '/forgot',
    skipAuth: true,
    component: OnlyClientIdSubDomain(SSOLogin(ForgotPwd, 'changePassword')),
  },
  {
    path: '/changepassword',
    skipAuth: true,
    component: OnlyClientIdSubDomain(ResetPwd),
  },
  {
    path: '/boarding/invite/:id',
    skipAuth: true,
    component: OnlyAppSubDomain(CreateOrgAndLogin),
  },
  {
    path: '/account/invitation/:id',
    skipAuth: true,
    component: OnlyClientIdSubDomain(SignupUser),
  },
  {
    path: '/invitation/:id',
    skipAuth: true,
    component: OnlyClientIdSubDomain(SignupUser, { isDomain: true }),
  },
];
