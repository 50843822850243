import React from 'react';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import { PrimaryButton } from '@cluedin/form';
import Alert from 'uxi/Alert';
import UserConsent from '../../../../GDPR/components/composites/consents/UserConsent';

const ConsentDialog = ({
  actionTitle,
  invalidSendingConsent,
  show,
  onClose,
  consentFormConfiguration,
  isSendingConsentDone,
}) => (
  <Dialog show={show} onClose={onClose}>
    <Panel onClose={onClose} style={{ height: '100vh' }}>
      <PanelHeader
        onClose={onClose}
        title={consentFormConfiguration.title}
        hasClose
      />
      <PanelContent style={{ padding: '30px', overflowY: 'auto' }}>
        {isSendingConsentDone && (
          <Alert style={{ marginBottom: '15px' }} type="success">
            Thank you for your request. We will get back to you soon. In case
            you do not hear from us after 5 days, please do not hesitate to
            contact us.
          </Alert>
        )}
        {invalidSendingConsent && (
          <Alert style={{ marginBottom: '15px' }} type="danger">
            Something unexpected happen. Please contact-us to proceed wiht our
            request.
          </Alert>
        )}
        <UserConsent consent={consentFormConfiguration} />
      </PanelContent>
    </Panel>
    <PanelFooter onClose={onClose} hasCancel>
      <PrimaryButton
        onClick={onClose}
        text={actionTitle || 'Yes, I give my consent'}
      />
    </PanelFooter>
  </Dialog>
);

export default ConsentDialog;
