import React, { useState } from 'react';
import { Help, Arrowdown, Arrowright } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';
import DismissHelpDialog from './DismissHelpDialog';

const HelpInfoWrapper = styled.div`
  color: #004085;
  cursor: pointer;
  background-color: #cce5ff;
  border-color: #b8daff;
  padding: 12px;
  border-radius: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  a {
    color: #002752;
  }
`;

const HelpInfoTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;

const HelpInfo = ({ title, children, defaultExpanded, withDismiss }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DismissHelpDialog
        isOpen={isOpen}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setIsOpen(false);
        }}
      />
      <HelpInfoWrapper>
        <HelpInfoTitle onClick={() => setExpanded(!expanded)}>
          <div style={{ marginRight: '6px', display: 'flex' }}>
            {expanded && <Arrowdown size={16} />}
            {!expanded && <Arrowright size={16} />}
          </div>
          <div style={{ display: 'flex' }}>
            <Help size={16} />
          </div>
          <div style={{ marginLeft: '6px', flex: 1 }}>{title}</div>
        </HelpInfoTitle>
        {expanded && (
          <div style={{ padding: '12px 0 0 0' }}>
            {children}
            {withDismiss && (
              <div style={{ marginTop: '12px', textAlign: 'left' }}>
                <a
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <FormattedMessage id="module-help-dismissHelp" />
                </a>
              </div>
            )}
          </div>
        )}
      </HelpInfoWrapper>
    </>
  );
};

export default HelpInfo;
