"use strict";

exports.__esModule = true;
exports.default = void 0;
var _Button = require("@fluentui/react/lib/Button");
var _withButtonLoader = require("./withButtonLoader");
var PrimaryButtonIconLoader = (0, _withButtonLoader.withButtonLoader)(_Button.PrimaryButton, {
  replaceIcon: true
});
var _default = PrimaryButtonIconLoader;
exports.default = _default;