import styled from 'styled-components';

import VocabularySource from '../../../../dataCatalog/components/composites/VocabularySource';

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const getColumns = (intl) => {
  return [
    {
      property: 'name',
      displayName: intl.formatMessage({ id: 'module-goldenRecordList-source' }),
      Component: ({ name, connector, providerId, isCluedInCore, ...r }) => {
        return (
          <NameWrapper>
            <VocabularySource
              hideLabels
              connector={connector}
              providerId={providerId}
              isCluedInCore={isCluedInCore}
            />
            <strong>{name}</strong>
          </NameWrapper>
        );
      },
    },
    {
      property: 'module-goldenRecordList-propsNumber',
      displayName: intl.formatMessage({ id: 'No. of properties' }),
      Component: ({ propsNumber }) => (
        <span
          style={{ textAlign: 'right', width: '100%', paddingRight: '20px' }}
        >
          {propsNumber}
        </span>
      ),
    },
  ];
};
