import { SocialLinks } from 'uxi/List';
import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { useSpring, animated } from '@react-spring/web';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import pkg from '../../../../../../package.json';

const Footer = styled.div`
  display: flex;
  max-width: 100%;
  padding-bottom: 8px;
  align-items: center;
  color: ${({ menuTheme }) => menuTheme?.color};
  padding: ${({ isMinimized }) => (isMinimized ? '0 0 8px 0' : '10px 15px')};
  justify-content: ${({ isMinimized }) =>
    isMinimized ? 'center' : 'space-between'};
`;

const FooterInner = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

const CollapseIconWrapper = styled.i`
  transition: transform 250ms;
  transform: ${({ isMenuMinimized }) =>
    `rotate(${isMenuMinimized ? 180 : 0}deg)`};
`;

const { version: pkgVersion } = pkg;

export const MenuFooter = ({ menuTheme, toggleMenu, isMenuMinimized }) => {
  const footerInnerStyles = useSpring({
    width: !isMenuMinimized ? 166 : 0,
    opacity: !isMenuMinimized ? 1 : 0,
    config: { duration: 100 },
  });

  const socialsLinks = [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/company/cluedin-aps',
    },
  ];

  return (
    <Footer isMinimized={isMenuMinimized} menuTheme={menuTheme}>
      <FooterInner style={footerInnerStyles} className="Footer-FooterInner">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ padding: '4px' }}>
            <TooltipHost
              calloutProps={{ gapSpace: 0 }}
              content={
                <>
                  <small
                    className="test_MainMenu_version"
                    style={{
                      padding: '4px',
                      maxWidth: '100%',
                      opacity: 0.75,
                    }}
                  >
                    &nbsp;v {pkgVersion}&nbsp;
                  </small>
                </>
              }
            >
              <a
                className="test_MainMenu_CluedInLink"
                href="https://www.cluedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>&nbsp;©&nbsp;</span>
                <span>&nbsp;CluedIn&nbsp;</span>
                <span>&nbsp;{new Date().getFullYear()}&nbsp;</span>
              </a>
            </TooltipHost>
          </div>

          <div
            style={{ marginLeft: '4px' }}
            className="test_MainMenu_CluedIn_social_links"
          >
            <SocialLinks
              horizontal
              iconSize={16}
              iconColor={menuTheme.accent}
              socialLinks={socialsLinks}
            />
          </div>
        </div>
      </FooterInner>
      <CollapseIconWrapper isMenuMinimized={isMenuMinimized}>
        <Icon
          onClick={toggleMenu}
          iconName="DoubleChevronLeftMed"
          styles={{
            root: {
              fontSize: 16,
              cursor: 'pointer',
              ':hover': { color: menuTheme.accent },
            },
          }}
        />
      </CollapseIconWrapper>
    </Footer>
  );
};
