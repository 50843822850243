import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_DATA_SET = gql`
  mutation createDataSets(
    $dataSourceId: ID
    $dataSets: [InputDataSet]
    $existingAnnotationId: ID
    $existingDataSetId: ID
  ) {
    inbound {
      createDataSets(
        dataSourceId: $dataSourceId
        dataSets: $dataSets
        existingAnnotationId: $existingAnnotationId
        existingDataSetId: $existingDataSetId
      ) {
        id
      }
    }
  }
`;

export const useCreateDataSets = (onCompleted = (data) => data) => {
  const [createDataSets, { data, error, loading }] = useMutation(
    CREATE_DATA_SET,
    { onCompleted },
  );

  const onlyDataNeeded = ((data || {}).inbound || {}).createDataSets;

  return [
    (datasets) => {
      createDataSets({
        variables: {
          ...datasets,
        },
      });
    },
    {
      data: onlyDataNeeded,
      error,
      loading,
    },
  ];
};

export default {
  useCreateDataSets,
};
