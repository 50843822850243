import React from 'react';
import DashboardActions from './DashboardActions';

const SimpleDashboard = ({ actions = [], name, ...rest }) => (
  <div style={{ padding: '15px 30px' }} {...rest}>
    <DashboardActions actions={actions} name={name} />
  </div>
);

export default SimpleDashboard;
