import { useIntl } from 'react-intl';
import Alert from 'uxi/Alert';

export const ApiGatewayDown = () => {
  const intl = useIntl();

  return (
    <Alert type="error">
      {intl.formatMessage({ id: 'module-coreStatusCheck-ApiGatewayDown' })}
    </Alert>
  );
};
