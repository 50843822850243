import { RACI_LEVELS } from '@cluedin/components';
import UserDashboard from './components/pages/UserDashboard';
import UserAll from '../user/components/pages/AllUsers';
import AllPotentialUsers from '../user/components/pages/AllPotentialUsers';
import AdminUserSettingsPageWrapper from '../user/components/pages/AdminUserSettingsPageWrapper';

export default [
  {
    path: '/',
    exact: true,
    component: UserDashboard,
  },
  {
    path: '/all',
    exact: true,
    title: {
      id: 'module-userModule-dashboard-allUsers',
    },
    component: UserAll,
  },
  {
    path: '/suggested',
    title: {
      id: 'module-userModule-dashboard-suggestedUsers',
    },
    exact: true,
    claims: {
      'admin.users': RACI_LEVELS.INFORMED,
    },
    component: AllPotentialUsers,
  },
  {
    path: '/settings/:id',
    title: {
      id: 'module-userModule-dashboard-userDetails',
    },
    claims: {
      'admin.users': RACI_LEVELS.CONSULTANT,
    },
    component: AdminUserSettingsPageWrapper,
  },
];
