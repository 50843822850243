import { memo } from 'react';
import { injectIntl } from '@cluedin/locale';
import { Gdpr, Connectors } from 'uxi/Icons';
import compact from 'lodash/compact';

import { Menu } from '../Menu';
import { getLink as getPIILink } from '../../../../pii/routes';
import { url, features, hasFeature } from '../../../../../config';
import { getLink as getMetricsLink } from '../../../../metrics/routes';

import { useOrganizationFeatureFlags } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';

const DisplayName = ({ name, moduleName, className = '' }) => (
  <span className={`__test_${moduleName} ${className}`}>{name}</span>
);

export const MainMenuContainer = memo(
  injectIntl(({ intl, isActive, modules, hasMetrics }) => {
    const [flags, findFeatureFlag] = useOrganizationFeatureFlags();

    const hasRetention = findFeatureFlag('DataRetention');
    const hasConsent = findFeatureFlag('Consent');
    const hasBreach = findFeatureFlag('DataBreach');
    const hasManualEntry = findFeatureFlag('manualDataEntry');

    const menuDescriptors = [];

    const integrationModules = [
      {
        key: 'applications',
        displayName: (
          <span className="test_globalMenu_Integration_AllIntegrations">
            {intl.formatMessage({ id: 'inbound.available' })}
          </span>
        ),
        href: '/admin/inbound/integration/applications',
        isActive: isActive('/admin/inbound/integration/applications'),
      },
      {
        key: 'configurations',
        displayName: (
          <span className="test_globalMenu_Integration_AllConfigurations">
            {intl.formatMessage({ id: 'inbound.configured' })}
          </span>
        ),
        href: '/admin/inbound/configuration',
        isActive: isActive('/admin/inbound/configuration'),
      },
    ];

    if (hasFeature('datasource')) {
      integrationModules.push({
        key: 'datasource',
        displayName: (
          <span
            className="test_globalMenu_Integration_DataSources"
            data-test={'sidemenu_integrations_datasources'}
          >
            {intl.formatMessage({ id: 'inbound.dataSources' })}
          </span>
        ),
        href: '/admin/inbound/datasourceset',
        isActive: isActive('/admin/inbound/datasourceset'),
      });
    }

    if (hasManualEntry) {
      integrationModules.push({
        key: 'manualDataEntryProject',
        displayName: (
          <span
            className="test_globalMenu_Integration_ManualDataEntry"
            data-test={'sidemenu_integrations_manualDataEntry'}
          >
            {intl.formatMessage({ id: 'module-manualDataEntry-displayName' })}
          </span>
        ),
        href: '/admin/inbound/manual-data-entry',
        isActive: isActive('/admin/inbound/manual-data-entry'),
      });
    }

    menuDescriptors.push({
      displayName: (
        <span
          className="test_globalMenu_integration"
          data-test={'sidemenu_integrations'}
        >
          {intl.formatMessage({ id: 'inbound' })}
        </span>
      ),
      icon: <Connectors />,
      href: '/admin/inbound/integration',
      isActive: isActive([
        '/admin/inbound',
        '/admin/inbound/integration',
        '/admin/inbound/configuration',
        '/admin/inbound/datasourceset',
      ]),
      key: 'INTEGRATION',
      subItems: integrationModules,
    });

    const manipulationRoutes = [];

    if ((features || []).includes('cluedinClean') && url.cluedInCleanUrl) {
      manipulationRoutes.push({
        key: 'DataCluedInClean',
        displayName: (
          <span className="test_globalMenu_Manipulation_Cleaning test_globalMenu_Preparation_Cleaning">
            {intl.formatMessage({ id: 'clean' })}
          </span>
        ),
        href: '/admin/preparation/clean',
        isActive: isActive('/admin/preparation/clean'),
      });
    }

    let governanceChildren = [
      {
        key: 'SubjectAccess Requests',
        displayName: (
          <span className="test_globalMenu_Governance_SarList">
            {intl.formatMessage({ id: 'compliance' })}
          </span>
        ),
        href: '/admin/governance/gdpr/list',
        isActive: isActive('/admin/governance/gdpr'),
      },
      hasConsent && {
        key: 'Consent',
        displayName: (
          <span className="test_globalMenu_Governance_Consent">
            {intl.formatMessage({ id: 'consent' })}
          </span>
        ),
        href: '/admin/governance/consent',
        isActive: isActive('/admin/governance/consent'),
      },
    ].filter((v) => v);

    if (hasMetrics) {
      governanceChildren.push({
        key: 'Quality Metrics',
        displayName: (
          <span className="test_globalMenu_Governance_Metrics">
            {intl.formatMessage({ id: 'qualityMetrics' })}
          </span>
        ),
        href: getMetricsLink.home(), // w/ !trailing sh
        isActive: isActive(getMetricsLink.home()), // </ !trailing slash
      });
    }

    governanceChildren = governanceChildren.concat(
      [
        {
          key: 'PII-metrics',
          displayName: (
            <span className="test_globalMenu_Governance_MetricsPII">
              {intl.formatMessage({ id: 'sensitiveData' })}
            </span>
          ),
          href: getPIILink.home(), // w/ !trailing sh
          isActive: isActive(getPIILink.home()), // </ !trailing slash
        },
        hasBreach && {
          key: 'DataBreach',
          displayName: (
            <span className="test_globalMenu_Governance_Breach">
              {intl.formatMessage({ id: 'breach' })}
            </span>
          ),
          href: '/admin/governance/breach',
          isActive: isActive('/admin/governance/breach'),
        },
        hasRetention && {
          key: 'Retention',
          displayName: (
            <span className="test_globalMenu_Governance_Retention">
              {intl.formatMessage({ id: 'retention' })}
            </span>
          ),
          href: '/admin/governance/retention',
          isActive: isActive('/admin/governance/retention'),
        },
        // {
        //   key: 'GOVERNANCE_SETTINGS',
        //   displayName: (
        //     <span className="test_globalMenu_Governance_Settings">
        // {intl.formatMessage({ id: "GOVERNANCE_SETTINGS" })}
        //     </span>
        //   ),
        //   onClick: () => { navigate('/gdpr/settings'); },
        //   isActive: isActive('/gdpr/settings'),
        // },
      ].filter((v) => v),
    );

    menuDescriptors.push({
      displayName: (
        <span className="test_globalMenu_governance">
          {intl.formatMessage({ id: 'GDPR_Data_Governance' })}
        </span>
      ),
      icon: <Gdpr />,
      isActive: isActive([
        '/admin/governance',
        '/admin/governance/gdpr',
        '/admin/governance/consent',
        '/admin/governance/retention',
        '/admin/governance/breach',
        // '/pii/',
        getPIILink.home(),
        // '/metrics/',
        getMetricsLink.home(),
      ]),
      key: 'GDPR_Data_Governance',
      href: '/admin/governance/gdpr',
      subItems: governanceChildren,
    });

    (modules || [])
      .filter((f) => !f.hideFromMenu)
      .forEach((m) => {
        const IconForPillar = m.Icon;
        const subMenu = compact(
          (m.routes || [])
            .filter((f) => !f.hideFromMenu)
            .filter((f) => {
              if (f.featureFlag) {
                return findFeatureFlag(f.featureFlag);
              }
              return true;
            })
            .map((r) => {
              if (r.path && r.path !== '/' && !r.hide) {
                return {
                  key: r.name,
                  displayName: (
                    <DisplayName
                      moduleName={r.name}
                      className={r.className}
                      name={r.displayName}
                    />
                  ),
                  href: `${m.path}${r.path}`.toLowerCase(),
                  isActive:
                    isActive(`${m.path}${r.path}`.toLowerCase()) ||
                    isActive(r.additionalActiveMatch),
                  ...r,
                };
              }

              return null;
            }),
        );

        menuDescriptors.push({
          displayName: (
            <DisplayName
              moduleName={m.name}
              className={m.className}
              name={m.displayName}
            />
          ),
          icon: <IconForPillar />,
          key: m.name,
          subItems: subMenu || [],
          href: m.path.toLowerCase(),
          isActive: isActive(m.path.toLowerCase()),
        });
      });

    return <Menu menuDescriptors={menuDescriptors} />;
  }),
);
