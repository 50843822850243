import { gql } from '@apollo/client';

export const addAnnotationToFileMutation = gql`
  mutation addAnnotationToFile(
    $importSetId: ID
    $fileId: ID
    $annotationId: ID
  ) {
    preparation {
      addAnnotationToFile(
        importSetId: $importSetId
        fileId: $fileId
        annotationId: $annotationId
      )
    }
  }
`;

export const getAllDataSets = gql`
  query getAllDataSets($annotationId: ID) {
    inbound {
      dataSets(annotationId: $annotationId) {
        total
        data {
          id
          name
          annotationId
          author {
            username
          }
          dataSourceId
          originalFields
          updatedAt
          createdAt
          stats {
            bytes
            successful
          }
        }
      }
    }
  }
`;

export const getAnnotationById = gql`
  query getAnnotationById($id: ID) {
    preparation {
      annotation(id: $id) {
        id
        name
        entityType
        nameKey
        descriptionKey
        originEntityCodeKey
        createdDateMap
        modifiedDateMap
        cultureKey
        origin
        versionKey
        isDynamicVocab
        beforeCreatingClue
        beforeSendingClue
        useStrictEdgeCode
        useDefaultSourceCode
        entityTypeConfiguration {
          icon
          displayName
          entityType
        }
        vocabulary {
          vocabularyName
          vocabularyId
          providerId
          keyPrefix
        }
        annotationProperties {
          displayName
          key
          vocabKey
          vocabularyKeyId
          coreVocab
          useAsEntityCode
          useAsAlias
          useSourceCode
          entityCodeOrigin
          type
          vocabularyKey {
            displayName
            vocabularyKeyId
            isCluedInCore
            isDynamic
            isObsolete
            isProvider
            vocabularyId
            name
            mappedKey
            isVisible
            key
            groupName
            dataClassificationCode
            dataType
            description
            providerId
            mapsToOtherKeyId
          }
          validations {
            id
            displayName
            parameters {
              key
              value
            }
          }
          transformations {
            filters {
              parameters {
                key
                value
              }
              id
              displayName
              inverse
            }
            operations {
              parameters {
                key
                value
              }
              id
              displayName
              inverse
            }
          }
        }
      }
    }
  }
`;

export const addPropertiesToAnnotationQuery = gql`
  mutation addPropertiesToAnnotation(
    $id: ID
    $properties: [InputPropertyAnnotation]
  ) {
    preparation {
      addPropertiesToAnnotation(id: $id, properties: $properties)
    }
  }
`;

export const modifyAnnotationMutationWitVocabAndEntityType = gql`
  mutation modifyAnnotationWithVocabAndEntityType(
    $annotation: InputEntityAnnotationWithEntityTypeAndVocabulary
  ) {
    preparation {
      modifyAnnotationWithVocabAndEntityType(annotation: $annotation)
    }
  }
`;

export const modifyAnnotationMutation = gql`
  mutation modifyAnnotation($annotation: InputEntityAnnotation) {
    preparation {
      modifyAnnotation(annotation: $annotation)
    }
  }
`;

export const createAnnotationQuery = gql`
  mutation createAnnotationWithProperties(
    $annotation: InputEntityAnnotationWithProperties
  ) {
    preparation {
      createAnnotationWithProperties(annotation: $annotation)
    }
  }
`;

export const getAllAnnotation = gql`
  query annotations($pageSize: Int, $page: Int, $name: String) {
    preparation {
      annotations(pageSize: $pageSize, page: $page, name: $name) {
        total
        data {
          id
          name
          entityType
          author
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getAllEdgeTypes = gql`
  query getAllEdgeTypes {
    preparation {
      getAllEdgeTypes
    }
  }
`;

export const getAllSubmissions = gql`
  query getAllSubmission($id: ID, $dataSetId: ID) {
    preparation {
      getAnnotationSubmissions(id: $id, dataSetId: $dataSetId) {
        total
        data {
          id
          updatedAt
          createdAt
          total
          success
          failed
          dataSetId
          cleanProjectId
          dataSet {
            id
            name
            annotationId
          }
        }
      }
    }
  }
`;

export const getAllValidations = gql`
  query getAllValidations($id: ID, $dataSetId: ID) {
    preparation {
      getAnnotationValidations(id: $id, dataSetId: $dataSetId) {
        total
        data {
          updatedAt
          createdAt
          total
          success
          failed
        }
      }
    }
  }
`;

export default {
  addAnnotationToFileMutation,
  getAnnotationById,
  addPropertiesToAnnotationQuery,
  createAnnotationQuery,
  getAllDataSets,
  getAllAnnotation,
  getAllSubmissions,
  modifyAnnotationMutation,
  getAllEdgeTypes,
};
