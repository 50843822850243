import React from 'react';
import { pure } from 'recompose';
import { Badge } from 'uxi/Badge';
import { injectIntl } from '@cluedin/locale';

const SuggestionTypeBadge = ({ badgeLabel, intl }) => (
  <Badge
    color={badgeLabel ? 'white' : undefined}
    type={badgeLabel ? 'info' : 'default'}
    style={{
      ...(badgeLabel ? {} : { border: '1px solid white' }),
    }}
  >
    {badgeLabel || intl.formatMessage({ id: 'module-core-searchBox-all' })}
  </Badge>
);

export default injectIntl(pure(SuggestionTypeBadge));
