import { withRouter } from 'react-router';
import qs from 'query-string';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import { push } from 'connected-react-router';
import {
  getSuggestionVM,
  getIsFetchingSuggestion,
  getEntityTypePoolIdentifier,
} from '../../selector';
import {
  shouldUpdateEntityTypePoolIdentifier,
  shouldUpdateMainSearchQuery,
  shouldFetchSearchSuggestions,
  shouldUpdateQueryCurrentInputValue,
  shouldStoreUpdatedMainSearchQuery,
  shouldStoreMainSearchUpdatedFilters,
} from '../../actions';
import { encodeSearch, getSearchStrategy, decodeFilters } from '../../helper';

const createPathNameForSearch = (entityTypePoolIdentifier) => {
  if (entityTypePoolIdentifier) {
    return `/search/${entityTypePoolIdentifier}`;
  }

  return '/search';
};

const mapStateToProps = (state, props) => {
  const { location } = props;
  const { search, pathname } = location;

  const searchQS = qs.parse(search || '');
  const searchQuery = searchQS.q;
  let initialSelectionValue = searchQuery;

  if (!pathname.match('/search')) {
    initialSelectionValue = '';
  }

  return {
    isFetching: getIsFetchingSuggestion(state, props),
    allSuggestions: getSuggestionVM(state, props),
    entityTypePoolIdentifier: getEntityTypePoolIdentifier(state, props),
    initialSelectionValue,
  };
};

const mapDispatchToProps = (dispatch, { location, match }) => ({
  storeEntityTypePoolIdentifier(entityTypePoolIdentifier) {
    dispatch(
      shouldUpdateEntityTypePoolIdentifier({ entityTypePoolIdentifier }),
    );
  },
  storeInitialURLFilters({ filters }) {
    dispatch(shouldStoreMainSearchUpdatedFilters({ filters }));
  },
  shouldFetchSuggestions: (q) => {
    dispatch(shouldUpdateQueryCurrentInputValue({ currentInputValue: q }));

    if (q !== '' && q.length > 2) {
      dispatch(shouldFetchSearchSuggestions(q));
    }
  },
  onMainSearchQueryValidated(q) {
    dispatch(shouldStoreUpdatedMainSearchQuery({ query: q || '' }));
  },
  onEntityTypePoolChange: (entityTypePoolIdentifier) => {
    dispatch(
      shouldUpdateEntityTypePoolIdentifier({
        searchId: 'main',
        entityTypePoolIdentifier,
      }),
    );
  },
  onSearch({ q, entityTypePoolIdentifier }) {
    const { pathname = '', search: locationSearchStr } = location;
    const { q: previousQuery = '' } = match.params;
    const searchStrategy = getSearchStrategy(pathname);
    const urlSearchParams = new URLSearchParams(locationSearchStr);
    const filtersString = urlSearchParams.get('filters');

    dispatch(
      shouldUpdateEntityTypePoolIdentifier({
        searchId: 'main',
        entityTypePoolIdentifier,
      }),
    );

    dispatch(
      shouldUpdateMainSearchQuery({
        searchId: 'main',
        q: q || '*',
        previousQuery,
        entityTypes: searchStrategy.entityTypes,
        currentPathname: pathname,
      }),
    );

    dispatch(
      push({
        pathname: createPathNameForSearch(entityTypePoolIdentifier),
        search: encodeSearch(q || '*', decodeFilters(filtersString || '')),
      }),
    );
  },
});

const regExp = /search\/(person|organization)/;
const withDidMountEnhancer = lifecycle({
  componentDidMount() {
    const { location = {}, onEntityTypePoolChange } = this.props;

    const entityPoolIdentifierMatches = location.pathname.match(regExp);
    const entityPoolIdentifierBeforeMount = entityPoolIdentifierMatches
      ? entityPoolIdentifierMatches[1]
      : undefined;

    if (entityPoolIdentifierBeforeMount) {
      onEntityTypePoolChange(entityPoolIdentifierBeforeMount);
    }
  },
});

export const withSearchSideEffectManager = (Comp) =>
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withDidMountEnhancer(Comp)),
  );

export default withSearchSideEffectManager;
