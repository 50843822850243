"use strict";

exports.__esModule = true;
exports.CheckboxStyles = void 0;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var CheckboxStyles = function CheckboxStyles(_ref) {
  var disabled = _ref.disabled,
    className = _ref.className,
    checked = _ref.checked,
    theme = _ref.theme;
  var hasError = (className || '').indexOf('has-error') > -1;
  var errorInputStyle = hasError ? {
    borderColor: '#F16A6A'
  } : {};
  return {
    checkbox: _extends({
      boxShadow: '0px 1px 3px #0000001A',
      borderColor: checked && !disabled ? theme.palette.themePrimary : '#9B9B9C'
    }, errorInputStyle, {
      '.ms-Checkbox-checkmark': {
        fontWeight: 'bold'
      }
    })
  };
};
exports.CheckboxStyles = CheckboxStyles;