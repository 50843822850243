import { FC, memo } from 'react';

import { useAppSearch } from '../../context/useAppSearch';
import { AppSearchSuggestionItem } from './AppSearchSuggestionItem';
import { AppSearchSuggestionsGroupTitle } from './AppSearchSuggestions.styles';

type Props = {
  suggestionsLabel: string;
  handleBlurAppSearchInput: () => void;
};

export const AppSearchSuggestions: FC<Props> = memo(
  ({ suggestionsLabel, handleBlurAppSearchInput }) => {
    const { suggestedSearchResults, previousSuggestedSearchResults } =
      useAppSearch();

    const hasResults = suggestedSearchResults?.length > 0;

    const data = hasResults
      ? suggestedSearchResults
      : previousSuggestedSearchResults;

    return (
      <>
        <AppSearchSuggestionsGroupTitle>
          {suggestionsLabel}
        </AppSearchSuggestionsGroupTitle>

        <div tabIndex={-1}>
          {data.map((i) => (
            <AppSearchSuggestionItem
              type="search"
              label={i.label}
              handleBlurAppSearchInput={handleBlurAppSearchInput}
            />
          ))}
        </div>
      </>
    );
  },
);
