import React from 'react';
import Tooltip from 'rc-tooltip';
import styled from 'styled-components';
import { getEntityTypeIcon } from '@cluedin/components';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { Externallink } from 'uxi/Icons';

const NotConfiguredEntityTypeIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NotConfiguredEntityTypeIconOverlayWrapper = styled.div`
  padding: 6px;
`;

const NotConfiguredEntityTypeIconLinkWrapper = styled.div`
  text-align: right;
  margin-top: 6px;
`;

const NotConfiguredEntityTypeIcon = ({
  size = 18,
  color,
  entityType,
  style = {},
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const Help = getEntityTypeIcon('help');

  return (
    <NotConfiguredEntityTypeIconWrapper style={style}>
      <Tooltip
        placement="bottom"
        overlay={
          <NotConfiguredEntityTypeIconOverlayWrapper>
            <div>
              <FormattedHTMLMessage
                id="module-entityType-notConfigureEntityTypeMessage"
                values={{
                  entityType,
                }}
              />
            </div>
            <NotConfiguredEntityTypeIconLinkWrapper>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`/admin/management/entity-types/edit?entityType=${entityType}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Externallink size={12} />
                <span style={{ marginLeft: '6px' }}>
                  <FormattedMessage id="module-entityType-configureEntityType" />
                </span>
              </a>
            </NotConfiguredEntityTypeIconLinkWrapper>
          </NotConfiguredEntityTypeIconOverlayWrapper>
        }
      >
        <Help size={size} color={color ? color : themePrimary} />
      </Tooltip>
    </NotConfiguredEntityTypeIconWrapper>
  );
};

export default NotConfiguredEntityTypeIcon;
