import React, { Fragment, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import { Button } from '@cluedin/form';
import { Datacleaning } from 'uxi/Icons';

import CleanGqlQueryDialogContainer from './CleanGqlQueryDialogContainer';
import { hasFeature } from '../../../../../config';

const CleanGqlQueryDialog = ({
  gqlOptions,
  searchStat,
  currentUser,
  loading,
  projectId,
  errorCreating,
  redirectToCleanProject,
}) => {
  const [isOpen, setVisibility] = useState(false);
  return (
    <Fragment>
      {hasFeature('cluedinClean') && (
        <Button
          style={{ margin: '0 0 0 4px' }}
          startIcon={<Datacleaning size={16} />}
          text={<FormattedMessage id="module-prepare-prepareButton" />}
          onClick={() => {
            setVisibility(true);
          }}
        />
      )}
      <CleanGqlQueryDialogContainer
        redirectToCleanProject={redirectToCleanProject}
        loading={loading}
        projectId={projectId}
        errorCreating={errorCreating}
        currentUser={currentUser}
        gqlOptions={gqlOptions}
        searchStat={searchStat}
        isOpen={isOpen}
        onClose={() => {
          setVisibility(false);
        }}
      />
    </Fragment>
  );
};
const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser: currentUser && currentUser.client ? currentUser.client : null,
});
const mapDispatchToProps = (dispatch) => ({
  redirectToCleanProject(projectId) {
    dispatch(push(`/admin/preparation/prepare/project/${projectId}`));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CleanGqlQueryDialog);
