import { memo, useEffect, useState } from 'react';
import { Panel } from '@cluedin/form';
import { uniqBy } from 'lodash';
import { useIntl } from 'react-intl';
import { ListPattern, FilterButtonWithLabel } from '@cluedin/list-pattern';

import { CollapseElementAnimated } from '../../../../../core/components/composites/CollapseElementAnimated';
import { useGoldenRecordList } from '../../GoldenRecordListContext';
import { GoldenRecordListMergePanel } from '../../GoldenRecordListMergePanel';
import RecordListToolbar from './RecordListToolbar';
import RecordListFiltersToolbar from './RecordListFiltersToolbar';
import RecordList from './RecordList';

const RecordListContainer = ({
  noFilters,
  asSearchList,
  openWorkflow,
  showPagination: showPaginationOption,
  offsetTop = 114,
}) => {
  const { formatMessage } = useIntl();
  const [isMergingPanelOpen, setIsMergingPanelOpen] = useState(false);

  const {
    history,
    hasSelection,
    nextPage,
    cursor,
    hideListSearch,
    selectionState: {
      selectionActive,
      selected,
      setSelected,
      toggleSelectionActive,
    },
    listOptions: {
      total,
      loading,
      entities,
      pageSize,
      hasFilters,
      selectedPage,
      setSearchName,
      setCurrentPage,
      searchNameInput,
      setSearchNameInput,
    },
    viewOptions: {
      DataGrid = ListPattern,
      DataGridGhost,
      columnsForGrid,
      viewMode,
      setViewMode,
    },
    filterOptions: { numberOfFilters },
  } = useGoldenRecordList();

  const [listSelectedIds, setListSelectedIds] = useState([]);

  const setSelectedDataByIds = (ids = []) => {
    if (ids.length === 0) {
      return;
    }

    const foundNewSelection = entities.filter((i) => ids.includes(i.id));

    setSelected((prev) => {
      const rawSelected = [...prev, ...foundNewSelection];

      const newSelected = uniqBy(rawSelected, 'id').filter((i) =>
        ids.includes(i.id),
      );

      return newSelected;
    });
  };

  useEffect(() => {
    setSelectedDataByIds(listSelectedIds);
  }, [listSelectedIds]);

  const handleEsc = ({ key }) => {
    if (key === 'Escape') {
      setIsFiltersOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const listTop = asSearchList ? 49 : offsetTop;

  const listOffsetTop = listTop;

  const advancedFilters = (
    <CollapseElementAnimated
      duration={100}
      isVisible={isFiltersOpened}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 2,
      }}
      innerStyles={{
        boxShadow: '0px 2px 5px 1px rgba(158,158,158,.7)',
      }}
    >
      <RecordListFiltersToolbar hideFilter={() => setIsFiltersOpened(false)} />
    </CollapseElementAnimated>
  );

  const changeViewMode = (v) => {
    setViewMode(v);

    setSelected(() => []);
    setListSelectedIds(() => []);
    onSelectedChange(() => []);
  };

  const onSelectedChange = (values) => {
    if (viewMode === 'tile') {
      const preparedListSelectedIds = values?.map((i) => i.id);

      setListSelectedIds(preparedListSelectedIds);

      return setSelected(values);
    }

    setListSelectedIds(values);
  };

  const toggleSelection = () => {
    setListSelectedIds(() => []);
    setSelected(() => []);

    toggleSelectionActive();
  };

  const startMerging = () => {
    setIsFiltersOpened(() => false);

    setIsMergingPanelOpen(() => true);
  };

  const cancelMerging = () => {
    setIsMergingPanelOpen(() => false);
  };

  const preparedSelected = viewMode === 'table' ? listSelectedIds : selected;

  const onUnselectFromListIds = (id) => {
    setListSelectedIds((prev) => prev.filter((i) => i !== id));
  };

  const disabledItemsTooltipHint = (
    <span>
      {formatMessage({
        id: 'module-goldenRecordList-selectMerge-part1',
      })}
      <br />
      {formatMessage({
        id: 'module-goldenRecordList-selectMerge-part2',
      })}
    </span>
  );

  const filterToolbar = (
    <RecordListToolbar
      hasSelection={hasSelection}
      startMerging={startMerging}
      setViewMode={changeViewMode}
      onCustomizeView={openWorkflow}
      toggleSelection={toggleSelection}
      selectionActive={selectionActive}
      hasMinimumSelected={selected.length > 1}
      onPrepare={() => setPrepareEntities(true)}
      hideFilter={() => setIsFiltersOpened(false)}
    />
  );

  const listToolbarAdditionalLeftContent = !noFilters && (
    <FilterButtonWithLabel
      label="Filter"
      style={{ margin: '0 6px' }}
      numberOfFilters={numberOfFilters}
      onClick={() => setIsFiltersOpened((prev) => !prev)}
    />
  );

  const showPagination = total > 10000 ? false : showPaginationOption;

  return (
    <>
      <Panel
        onClose={cancelMerging}
        open={isMergingPanelOpen}
        title={formatMessage({
          id: 'module-goldenRecordList-preview-merge-panel-title',
        })}
      >
        <GoldenRecordListMergePanel
          onClose={cancelMerging}
          onUnselectFromListIds={
            viewMode === 'table' ? onUnselectFromListIds : undefined
          }
        />
      </Panel>

      <RecordList
        List={DataGrid}
        total={total}
        history={history}
        loading={loading}
        nextPage={nextPage}
        cursor={cursor}
        noSearch={noFilters}
        data={entities || []}
        hasFilters={hasFilters}
        itemsPerPage={pageSize}
        offsetTop={listOffsetTop}
        selectedPage={selectedPage}
        selected={preparedSelected}
        gridColumns={columnsForGrid}
        GhostLoading={DataGridGhost}
        filterToolbar={filterToolbar}
        onSubmitSearch={setSearchName}
        customPaging={!showPagination && total > 10000}
        hideListSearch={hideListSearch}
        setCurrentPage={setCurrentPage}
        showPagination={showPagination}
        advancedFilters={advancedFilters}
        searchNameInput={searchNameInput}
        onSelectedChange={onSelectedChange}
        setSearchNameInput={setSearchNameInput}
        selectable={!!(hasSelection && selectionActive)}
        listToolbarAdditionalLeftContent={listToolbarAdditionalLeftContent}
        disabledItemsTooltipHint={disabledItemsTooltipHint}
        notFoundProps={{
          message: formatMessage({
            id: 'module-goldenRecordList-noEntitisFoundMsg',
          }),
          explanation: formatMessage({
            id: 'module-goldenRecordList-noEntitisFoundExpl',
          }),
          noCreate: true,
        }}
        noSearchFoundProps={{
          message: formatMessage({
            id: 'module-goldenRecordList-noEntitisSearchFoundMsg',
          }),
          explanation: formatMessage({
            id: 'module-goldenRecordList-noEntitisSearchFoundExpl',
          }),
          noCreate: true,
        }}
      />
    </>
  );
};

export default memo(RecordListContainer);
