import React, { Fragment, useState } from 'react';
// import EntityPropertyViewerCluedInGroup from './EntityPropertyViewerCluedInGroup';
import EntityPropertyViewerIntegrationGroups from './EntityPropertyViewerIntegrationGroups';

const EntityPropertyViewerAccordion = ({
  // coreVocabProperties,
  isFetching,
  integrationProperties,
  invalid,
  hasSearch,
  searchQuery,
}) => {
  const [openedCategory, setOpenedCategory] = useState([]);
  return (
    <Fragment>
      {/*
        Keeping the code as it is how it should be
        No time for 2.5.3 to really filter down Core Vocab
        and others.
        <EntityPropertyViewerCluedInGroup
        coreVocabProperties={coreVocabProperties}
        isFetching={isFetching}
        searchQuery={searchQuery}
        invalid={invalid}
        isOpen={
          hasSearch ||
          openedCategory.find(v => v.toLowerCase() === 'Core Vocab'.toLowerCase())
        }
        onCategoryClick={(value) => {
          const found = openedCategory.find(v => v.toLowerCase() === value.toLowerCase());

          if (found) {
            setOpenedCategory(openedCategory.filter(v => v.toLowerCase() !== value.toLowerCase()));
          } else {
            setOpenedCategory([
              ...openedCategory,
              value.toLowerCase(),
            ]);
          }
        }}
      /> */}
      <EntityPropertyViewerIntegrationGroups
        searchQuery={searchQuery}
        integrationProperties={integrationProperties}
        isFetching={isFetching}
        invalid={invalid}
        hasSearch={hasSearch}
        openedCategories={openedCategory || []}
        onCategoryClick={(value) => {
          const found = openedCategory.find(
            (v) => v.toLowerCase() === value.toLowerCase(),
          );

          if (found) {
            setOpenedCategory(
              openedCategory.filter(
                (v) => v.toLowerCase() !== value.toLowerCase(),
              ),
            );
          } else {
            setOpenedCategory([...openedCategory, value.toLowerCase()]);
          }
        }}
      />
    </Fragment>
  );
};

export default EntityPropertyViewerAccordion;
