import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import cogoToast from 'cogo-toast';
import { FormattedMessage } from '@cluedin/locale';
import get from 'lodash/get';
import gql from 'graphql-tag';

const ENTITY_ANONYMIZE_PROPERTY = gql`
  mutation entityAnonymizeProperty($vocabularyKey: String!, $value: String!) {
    virtualization {
      entityAnonymizeProperty(vocabularyKey: $vocabularyKey, value: $value) {
        value
        vocabularyKey
      }
    }
  }
`;

const ENTITY_DEANONYMIZE_PROPERTY = gql`
  mutation entityDeAnonymizeProperty($vocabularyKey: String!, $value: String!) {
    virtualization {
      entityDeAnonymizeProperty(vocabularyKey: $vocabularyKey, value: $value) {
        value
        vocabularyKey
      }
    }
  }
`;

export const useEntityAnonymizeProperty = () => {
  const [anonymizedPropertyStatus, setAnonymizedPropertyStatus] = useState({});
  const [entityAnonymizeProperty, { data, loading, error }] = useMutation(
    ENTITY_ANONYMIZE_PROPERTY,
  );
  const [
    entityDeAnonymizeProperty,
    { data: deData, loading: deLoading, error: deError },
  ] = useMutation(ENTITY_DEANONYMIZE_PROPERTY);

  let deanonymizedProperty = get(
    deData,
    'virtualization.entityDeAnonymizeProperty',
  );
  let anonymizedProperty = get(data, 'virtualization.entityAnonymizeProperty');

  useEffect(() => {
    if (anonymizedProperty) {
      anonymizedPropertyStatus[anonymizedProperty.vocabularyKey] = {
        value: anonymizedProperty.value,
        isAnonymized: true,
      };
      anonymizedProperty = null;
      setAnonymizedPropertyStatus({ ...anonymizedPropertyStatus });

      cogoToast.success(
        <span data-test="entity-entityProperties-anonymizeToast">
          <FormattedMessage id="module-entityProperties-anonymizeToast" />
        </span>,
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    }
  }, [anonymizedProperty]);

  useEffect(() => {
    if (deanonymizedProperty) {
      anonymizedPropertyStatus[deanonymizedProperty.vocabularyKey] = {
        value: deanonymizedProperty.value,
        isAnonymized: false,
      };
      deanonymizedProperty = null;
      setAnonymizedPropertyStatus({ ...anonymizedPropertyStatus });

      cogoToast.success(
        <span data-test="entity-entityProperties-deanonymizeToast">
          <FormattedMessage id="module-entityProperties-deanonymizeToast" />
        </span>,
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    }
  }, [deanonymizedProperty]);

  const anonymize = (vocabularyKey, value) => {
    entityAnonymizeProperty({
      variables: {
        vocabularyKey,
        value,
      },
    });
  };

  const deanonymize = (vocabularyKey, value) => {
    entityDeAnonymizeProperty({
      variables: {
        vocabularyKey,
        value,
      },
    });
  };

  return [
    {
      anonymize,
      deanonymize,
    },
    {
      loading: loading || deLoading,
      error: error || deError,
      status: anonymizedPropertyStatus,
    },
  ];
};
