import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import store from 'store';
import { compose, lifecycle } from 'recompose';
import { shouldShowAlert } from '../../../core/actions';
import {
  shouldAddActiveProvider,
  shouldEnableProvider,
  shouldAddTrello,
} from '../../actions';
import MainWidgetForPage from '../../../core/components/composites/MainWidgetForPage';
import DefaultAlert from '../../../core/components/composites/alerts/DefaultAlert';
import ConfigurationForm from './forms/ConfigurationForm';
import {
  getInitialValueFromConfiguration,
  getValueFromFormToConfiguration,
} from '../../converter';
import { WithErrorAndLoadingAndQueuing } from '../../../core/components/Hocs/WithErrorAndLoading';

import ConfigurationLoadingStatusContainer from '../../../configuration/components/containers/ConfigurationLoadingStatusContainer';
import { withIntegrationFromOauthLocalstorageToken } from '../hocs/withIntegrationFromOauthLocalstorageToken';

const OauthCallbackContainer = (props) => {
  const {
    invalidSaving,
    integration,
    enableProvider,
    configuration,
    initialValues,
    isEnabling,
    enableStatus,
  } = props;

  return (
    <MainWidgetForPage>
      <div style={{ padding: '15px' }}>
        {(invalidSaving || enableStatus.invalid) && <DefaultAlert />}
        <ConfigurationForm
          isSaving={isEnabling}
          integrationName={integration.Name}
          hasExtra={configuration ? configuration.SupportsConfiguration : false}
          helperConfiguration={
            configuration ? configuration.helperConfiguration : {}
          }
          config={integration.Properties}
          initialValues={initialValues}
          onEnable={(updatedConfig) => {
            enableProvider(configuration.Id, updatedConfig, integration);
          }}
        />
      </div>
    </MainWidgetForPage>
  );
};

const EnhancedOauthContainer = compose(
  lifecycle({
    componentDidMount() {
      const { integrationId, addConfiguration } = this.props;

      if (!integrationId) {
        // eslint-disable-next-line no-undef
        // eslint-disable-next-line no-restricted-globals
        console.warn(
          `No id found for oauthCallbackKey ${name}. Oauth integration may fail to complete.`,
        );
      } else {
        addConfiguration(integrationId);
      }
    },
    componentWillReceiveProps(nextProps) {
      const { onDone } = this.props;

      if (nextProps.enableStatus.done) {
        onDone();
      }
    },
  }),
)(
  WithErrorAndLoadingAndQueuing(OauthCallbackContainer, {
    queueMessage:
      'We have received your request to edit this integration configuration, it has been queued and will be completed shortly.',
    CustomLoading: ConfigurationLoadingStatusContainer,
  }),
);

const mapToStateProps = (
  { integration: { addConfiguration, enableConfiguration } },
  { integrationId, integration },
) => {
  if (!integrationId) {
    console.error(`integrationIs is empty for ${integration.name}`);
  }

  const status = addConfiguration ? addConfiguration[integrationId] || {} : {};
  const activeConfig = status ? status.activeConfiguration : null;

  const enableStatus =
    enableConfiguration && activeConfig
      ? enableConfiguration[activeConfig.Id] || {}
      : {};

  return {
    integrationId,
    invalidSaving: status.invalid,
    isFetching: !activeConfig && !status.invalid,
    isEnabling: enableStatus.isSaving,
    enableStatus,
    queued: status.queued || enableStatus.queued,
    configuration: activeConfig,
    initialValues: activeConfig
      ? getInitialValueFromConfiguration(
          activeConfig,
          integration ? integration.properties : [],
        )
      : {},
    integration,
  };
};

const mapDispatchToProps = (dispatch, { name, token }) => ({
  // 1st step is to add the config directly (we do not need any more info)
  addConfiguration(integrationId) {
    const configuration = {
      helperConfiguration: {},
      AutoSync: false,
      Source: '',
      SourceQuality: 5,
      appUrl: '',
      AccountDisplay: '',
    };

    if (token) {
      dispatch(
        shouldAddTrello({
          integrationId,
          configuration,
          token,
        }),
      );
    } else {
      dispatch(
        shouldAddActiveProvider({
          integrationId,
          configuration,
        }),
      );
    }
  },
  // 2nd step is to enable to provider with an updated configuration
  enableProvider(configurationId, configuration, integration) {
    let configurationToPost = configuration;
    try {
      configurationToPost = getValueFromFormToConfiguration(
        configuration,
        integration.Properties,
      );
    } catch (e) {
      console.error(
        'We cannot convert your helper configuration back to what the server expects',
      );
    }

    dispatch(
      shouldEnableProvider({
        configurationId,
        configuration: configurationToPost,
      }),
    );
  },
  onDone() {
    store.set(name, '');
    dispatch(
      shouldShowAlert({
        type: 'success',
        title: 'Your application has been added',
        description: 'Your configuration has been added successfully.',
      }),
    );
    dispatch(push('/admin/inbound/configuration'));
  },
});

export default withIntegrationFromOauthLocalstorageToken(
  connect(mapToStateProps, mapDispatchToProps)(EnhancedOauthContainer),
);
