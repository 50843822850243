import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    visbility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const EntityRelationsGraphV3Wrapper = styled.div`
  align-items: center;
  animation: ${fadeInAnimation} 200ms ease-in-out;
  background: #ececec;
  display: flex;
  justify-content: center;
  height: calc(100vh - 165px - 52px) !important;
  max-height: calc(100vh - 165px - 52px) !important;
  overflow: hidden !important;
  width: 100%;

  & svg {
    height: calc(100vh - 165px - 52px) !important;
    max-height: calc(100vh - 165px - 52px) !important;
    overflow: hidden !important;
    width: 100%;
    max-width: 100%;

    & path {
      fill: none !important;
    }

    &:active {
      cursor: grabbing;
    }

    .node__body-entity {
      cursor: pointer;
      fill: #f3f9f9;
      stroke: #52b4ad;
      stroke-width: 2px;
    }

    .node__body-shadowEntity {
      cursor: pointer;
      fill: #fff;
      stroke: #d7d7d8;
      stroke-width: 2px;
    }

    .node__body-relatedEntityNode {
      cursor: pointer;
      fill: #fff;
      stroke: #d7d7d8;
      stroke-width: 2px;
    }

    .node__border-left {
      cursor: pointer;
      fill: #52b4ad;
    }

    .node__border-left-shadowEntity {
      cursor: pointer;
      fill: #d7d7d8;
    }

    .node__border-left-relatedEntityNode {
      cursor: pointer;
      fill: #efd88f;
    }

    marker path {
      d: path('M 0, -5 L 10, 0 L 0, 5');
      fill: none;
      stroke: #cacacc;
      stroke-width: 1.5;
    }

    .link__arrow {
    }

    .link__arrow-highlight {
      & path {
        stroke: #52b4ad !important;
      }
    }

    .node__icon {
      cursor: pointer;
      fill: #fff;
      stroke: #52b4ad;
      stroke-width: 2;
    }

    .node__icon-entity {
      cursor: pointer;
      fill: #e7f3f3;
    }

    .node__icon-shadowEntity {
      cursor: pointer;
      fill: #d7d7d8;
    }

    .node__icon-relatedEntity {
      cursor: pointer;
      fill: #fbf4de;
    }

    .node__icon-groupedRelations {
      cursor: pointer;
      fill: #fbf4de;
    }

    .node__icon-svg {
      cursor: pointer;
      height: 40px;
      width: 40px;
    }

    .node__icon-previewImage {
      cursor: pointer;
      height: 40px;
      width: 40px;
    }

    .node__icon-entity-svg {
      cursor: pointer;
      height: 22px;
      width: 22px;
    }

    .node__icon-relatedEntity-svg {
      cursor: pointer;
      height: 22px;
      width: 22px;
    }

    .node__icon-groupedRelations-svg {
      cursor: pointer;
      height: 40px;
      width: 40px;
    }

    .node__icon-expandNodeArrow {
      cursor: pointer;
      height: 18px;
      width: 18px;
    }

    .node__title {
      fill: #52b4ad;
      font-size: 14px;
      font-weight: 600;
    }

    .node__title-relatedEntityNode {
      fill: #37373a;
      font-size: 14px;
      font-weight: 600;
    }

    .node__subtitle {
      fill: #000;
      font-size: 14px;
    }

    .node__subtitle-relatedEntityNode {
      fill: #9b9b9c;
      font-size: 14px;
    }

    .link__body {
      cursor: pointer;
      fill: none;
      stroke: #cacacc;
      stroke-width: 2px;
    }

    .link__body-hidden {
      cursor: pointer;
      stroke: transparent;
      stroke-width: 60px;
    }

    .link__label {
      cursor: pointer;
      font-size: 18px;
      stroke: #000;
      stroke-width: 0.5px;
    }

    .body-highlight {
      stroke: #52b4ad !important;
      stroke-width: 4px !important;
    }

    .body-highlight-relatedEntityNode {
      stroke: #d7d7d8 !important;
      stroke-width: 4px !important;
    }

    .body-highlight-shadowEntity {
      stroke: #d7d7d8 !important;
      stroke-width: 4px !important;
    }

    .border-highlight {
      fill: #52b4ad !important;
    }

    .border-highlight-relatedEntityNode {
      fill: #efd88f !important;
    }

    .border-highlight-shadowEntity {
      fill: #d7d7d8 !important;
    }

    .link-highlight {
      stroke: #52b4ad !important;
      stroke-width: 2px !important;
    }
  }
`;
