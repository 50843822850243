import React from 'react';
import { ButtonDropdown, Option } from '@cluedin/atoms';

const DotsButtonDropDown = ({ options }) => (
  <ButtonDropdown
    startIcon={<Option />}
    color={''}
    noArrow
    variant={null}
    options={options}
  />
);

export default DotsButtonDropDown;
