import { withWorkflow } from '@cluedin/form';

import EdgePropertiesPanel from '../containers/EdgePropertiesPanel/EdgePropertiesPanel';

const EdgePropertiesPanelWrapper =
  (Comp) =>
  ({ openWorkflow, ...props }) =>
    <Comp openEdgePropertiesPanelWorkflow={openWorkflow} {...props} />;

export const withEdgePropertiesPanelWorkflow = (Comp) =>
  withWorkflow(EdgePropertiesPanelWrapper(Comp), EdgePropertiesPanel, {
    withCustomHeader: true,
    panelType: 'custom',
    width: 1300,
  });
