import React from 'react';
import { EntityTypesIcon } from '@cluedin/svgs';
import { RACI_LEVELS, actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

const { admin } = actionsConfiguration;

export default {
  pillar: 'management',
  name: 'entityTypes',
  displayName: <FormattedMessage id="entityTypes" />,
  path: '/entity-types',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: () => <EntityTypesIcon height="60px" width="60px" />,
        name: <FormattedMessage id="entityTypes" />,
        link: '/admin/management/entity-types',
        claims: admin.entityTypes.entityType.view,
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      claims: {
        'admin.entitytypes': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"EntityTypeListPage" */
            './components/pages/EntityTypeListPage'
          ),
        ),
      ),
    },
    {
      path: '/edit',
      claims: {
        'admin.entitytypes': RACI_LEVELS.CONSULTANT,
      },
      title: 'Edit Type',
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"EditEntityTypesPage" */
            './components/pages/EditEntityTypesPage'
          ),
        ),
      ),
    },
  ],
};
