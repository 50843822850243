import React from 'react';
import Tooltip from 'rc-tooltip';
import EntityProviderLinkWithIcon, { ImgWithOpacity } from './ProviderIconLink';

const numberOfPromoteIcon = 6;

const ProvidersIconLinkList = ({ providers = [], style, roundImage }) => {
  const foundInContent = providers.map((provider, i) => (
    <div style={{ marginRight: '6px' }}>
      <EntityProviderLinkWithIcon
        key={`${provider.name}-${i}`}
        providerName={provider.name}
        uri={provider.uri || ''}
        roundImage={roundImage}
      />
    </div>
  ));

  let foundInContentBase = null;
  let foundInContentExtra = null;
  let foundInContentExtraWrapper = null;

  if (foundInContent.length > numberOfPromoteIcon) {
    foundInContentBase = foundInContent.slice(0, numberOfPromoteIcon - 1);
    foundInContentExtra = foundInContent.slice(numberOfPromoteIcon - 1);
    foundInContentExtraWrapper = (
      <Tooltip overlay={<span>{foundInContentExtra}</span>} placement="top">
        <ImgWithOpacity>
          <span style={{ color: '#999999' }}>•••</span>
        </ImgWithOpacity>
      </Tooltip>
    );
  } else {
    foundInContentBase = foundInContent;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        overflow: 'hidden',
        ...style,
      }}
    >
      {foundInContentBase}
      {foundInContentExtraWrapper}
    </div>
  );
};

export default ProvidersIconLinkList;
