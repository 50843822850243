import constants from './constant';

export const initialState = {
  allUsers: [],
  isFetchingAllUsers: false,
  invalidAllUsers: false,
  inviteUsers: {},
  sendInvitations: {},
  promoteUsers: {},
  demoteUsers: {},
  blockUsers: {},
  unBlockUsers: {},
  isUserExisting: {},
  isEmailExisting: {},
  potentialUsers: [],
  isFetchingPotentialUsers: false,
  currentUser: undefined,
  isFetchingCurrentUser: false,
  inviteUserSomethingIsWrong: false,
  removeInvitations: {},
  promoteUserNotifications: [],
  demoteUserNotifications: [],
  isChangingPassword: false,
  isChangingPasswordSucceed: false,
  isChangingPasswordError: false,
  newInviteUserNotifications: [],
  currentSelectedUser: null,
  isFetchingCurrentSelectedUser: false,
  isInvalidCurrentSelectedUser: false,
  changingRole: [],
  isFetchingAllProvidersForUser: {},
  allProvidersByUser: {},
};

export const storeName = 'user';

const update = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_CURRENT_USER: {
      return {
        ...state,
        isFetchingCurrentUser: true,
      };
    }
    case constants.RECEIVE_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.data.user,
        isFetchingCurrentUser: false,
      };
    }
    case constants.INVALID_CURRENT_USER: {
      return Object.assign({}, state, {
        isFetchingCurrentUser: false,
        invalidCurrentUser: true,
      });
    }
    case constants.REQUEST_ALL_USERS: {
      return {
        ...state,
        isFetchingAllUsers: true,
      };
    }
    case constants.INVALID_ALL_USERS: {
      return {
        ...state,
        isFetchingAllUsers: false,
        invalidAllUsers: true,
      };
    }
    case constants.RECEIVE_ALL_USERS: {
      return {
        ...state,
        allUsers: [...action.data.users],
        isFetchingAllUsers: false,
      };
    }
    case constants.REQUEST_INVITE_USER: {
      const newRequestInviteUsers = state.inviteUsers;

      newRequestInviteUsers[action.data.invitation.email] = {
        email: action.data.invitation.email,
        isFetching: true,
      };

      return {
        ...state,
        inviteUsers: {
          ...newRequestInviteUsers,
        },
        inviteUserSomethingIsWrong: false,
      };
    }
    case constants.RECEIVE_INVITE_USER: {
      const newReceiveInviteUsers = state.inviteUsers;

      newReceiveInviteUsers[action.data.invitation.email] = {
        email: action.data.invitation.email,
        isFetching: false,
        emailAlreadyTakenForInviteUsers: '',
        inviteUserSomethingIsWrong: false,
      };

      return {
        ...state,
        inviteUsers: {
          ...newReceiveInviteUsers,
        },
        isOpenInviteUserDialog: false,
      };
    }
    case constants.INVALID_INVITE_USER: {
      return {
        ...state,
        inviteUserSomethingIsWrong: true,
        inviteUsers: {
          ...state.inviteUsers,
          [action.data.invitation.email]: {
            ...state.inviteUsers[action.data.invitation.email],
            isFetching: false,
            invalid: true,
          },
        },
      };
    }
    case constants.INVALID_RESEND_INVITATION: {
      const newRequestInvalidResendInvitations = state.sendInvitations;

      newRequestInvalidResendInvitations[action.data.id] = {
        email: action.data.id,
        isFetching: false,
        hasError: true,
      };

      return {
        ...state,
        sendInvitations: {
          ...newRequestInvalidResendInvitations,
        },
      };
    }
    case constants.REQUEST_RESEND_INVITATION: {
      const newRequestInvitations = state.sendInvitations;

      newRequestInvitations[action.data.id] = {
        email: action.data.id,
        isFetching: true,
        hasError: false,
      };

      return {
        ...state,
        sendInvitations: {
          ...newRequestInvitations,
        },
      };
    }
    case constants.RECEIVE_RESEND_INVITATION: {
      const newReceiveInvitations = state.sendInvitations;

      newReceiveInvitations[action.data.id].isFetching = false;

      return {
        ...state,
        sendInvitations: {
          ...newReceiveInvitations,
        },
      };
    }
    case constants.REQUEST_BLOCK: {
      const requestBlockUsers = state.blockUsers;

      requestBlockUsers[action.data.userName] = {
        userName: action.data.userName,
        isFetching: true,
      };

      return {
        ...state,
        blockUsers: {
          ...requestBlockUsers,
        },
      };
    }
    case constants.RECEIVE_BLOCK: {
      const receiveBlockUsers = state.blockUsers;
      const currentBlockUser = state.allUsers.find(
        (u) => u.Account.UserName === action.data.userName,
      );
      const currentSelectedUserAfterBlock = state.currentSelectedUser;
      currentBlockUser.Account.LockoutEnabled = true;
      currentBlockUser.Account.LockoutEnd = '9999-12-31T23:59:59.9999999+00:00';

      receiveBlockUsers[action.data.userName] = {
        userName: action.data.userName,
        isFetching: false,
      };

      if (
        currentSelectedUserAfterBlock &&
        currentSelectedUserAfterBlock.Account &&
        currentSelectedUserAfterBlock.Account.UserName === action.data.userName
      ) {
        currentSelectedUserAfterBlock.Account.LockoutEnabled = true;
        currentSelectedUserAfterBlock.Account.LockoutEnd =
          '9999-12-31T23:59:59.9999999+00:00';
      }

      return {
        ...state,
        allUsers: [...state.allUsers],
        blockUsers: {
          ...receiveBlockUsers,
        },
        currentSelectedUser: { ...currentSelectedUserAfterBlock },
      };
    }
    case constants.REQUEST_UNBLOCK: {
      const requestUnBlockUsers = state.unBlockUsers;

      requestUnBlockUsers[action.data.userName] = {
        userName: action.data.userName,
        isFetching: true,
      };

      return {
        ...state,
        unBlockUsers: {
          ...requestUnBlockUsers,
        },
      };
    }
    case constants.RECEIVE_UNBLOCK: {
      const receiveUnBlockUsers = state.unBlockUsers;
      const currentUnBlockUser = state.allUsers.find(
        (u) => u.Account.UserName === action.data.userName,
      );
      const currentSelectedUserAfterUnblock = state.currentSelectedUser;
      currentUnBlockUser.Account.LockoutEnabled = true;
      currentUnBlockUser.Account.LockoutEnd = null;

      receiveUnBlockUsers[action.data.userName] = {
        userName: action.data.userName,
        isFetching: false,
      };

      if (
        currentSelectedUserAfterUnblock &&
        currentSelectedUserAfterUnblock.Account &&
        currentSelectedUserAfterUnblock.Account.UserName ===
          action.data.userName
      ) {
        currentSelectedUserAfterUnblock.Account.LockoutEnabled = true;
        currentSelectedUserAfterUnblock.Account.LockoutEnd = null;
      }

      return {
        ...state,
        allUsers: [...state.allUsers],
        unBlockUsers: {
          ...receiveUnBlockUsers,
        },
        currentSelectedUser: { ...currentSelectedUserAfterUnblock },
      };
    }
    case constants.REQUEST_FIND_IF_USER_EXIST: {
      const requestIsUserExisting = state.isUserExisting;

      requestIsUserExisting[action.data.userName] = {
        userName: action.data.userName,
        isFetching: true,
      };

      return {
        ...state,
        isUserExisting: {
          ...requestIsUserExisting,
        },
      };
    }
    case constants.RECEIVE_FIND_IF_USER_EXIST: {
      const receiveIsUserExisting = state.isUserExisting;

      receiveIsUserExisting[action.data.userName].isExisting =
        action.data.isExisting;

      return {
        ...state,
        isUserExisting: {
          ...receiveIsUserExisting,
        },
      };
    }
    case constants.REQUEST_FIND_IF_EMAIL_EXIST: {
      const requestIsEmailExisting = state.isEmailExisting;

      requestIsEmailExisting[action.data.email] = {
        email: action.data.email,
        isFetching: true,
      };

      return {
        ...state,
        isEmailExisting: {
          ...requestIsEmailExisting,
        },
      };
    }
    case constants.RECEIVE_FIND_IF_EMAIL_EXIST: {
      const receiveIsEmailExisting = state.isEmailExisting;

      receiveIsEmailExisting[action.data.email].isExisting =
        action.data.isExisting;

      return {
        ...state,
        isEmailExisting: {
          ...receiveIsEmailExisting,
        },
      };
    }
    case constants.REQUEST_POTENTIAL_USERS: {
      return {
        ...state,
        potentialUsers: [],
        isFetchingPotentialUsers: true,
      };
    }
    case constants.INVALID_POTENTIAL_USERS: {
      return {
        ...state,
        isFetchingPotentialUsers: false,
      };
    }
    case constants.RECEIVE_POTENTIAL_USERS: {
      return {
        ...state,
        potentialUsers: action.data,
        isFetchingPotentialUsers: false,
      };
    }
    case constants.REQUEST_OPEN_INVITE_USER_DIALOG: {
      return {
        ...state,
        isOpenInviteUserDialog: true,
        inviteUserFeedValueEmail: action.data.email,
        inviteUserFeedValueName: action.data.name,
      };
    }
    case constants.REQUEST_CLOSE_INVITE_USER_DIALOG: {
      return {
        ...state,
        isOpenInviteUserDialog: false,
        inviteUsers: {},
        inviteUserSomethingIsWrong: false,
        emailAlreadyTakenForInviteUsers: '',
      };
    }
    case constants.RECEIVE_EMAIL_IS_ALREADY_TAKEN: {
      return {
        ...state,
        emailAlreadyTakenForInviteUsers: action.data.email,
      };
    }
    case constants.REQUEST_REMOVE_INVITATION: {
      const newRequestRemoveInvitations = state.removeInvitations;

      newRequestRemoveInvitations[action.data.id] = {
        email: action.data.id,
        isFetching: true,
      };

      return {
        ...state,
        removeInvitations: {
          ...newRequestRemoveInvitations,
        },
      };
    }
    case constants.INVALID_REMOVE_INVITATION: {
      const newReceiveFailedRemoveInvitations = state.removeInvitations;
      newReceiveFailedRemoveInvitations[action.data.id] = {
        email: action.data.id,
        isFetching: false,
        hasError: true,
      };

      // TODO need an error

      return {
        ...state,
        removeInvitations: {
          ...newReceiveFailedRemoveInvitations,
        },
      };
    }
    case constants.RECEIVE_REMOVE_INVITATION: {
      const newReceiveRemoveInvitations = state.removeInvitations;

      newReceiveRemoveInvitations[action.data.id].isFetching = false;

      const newAllInvitations = state.allInvitations.map((i) => {
        if (action.data.id === i.Email) {
          return {
            ...i,
            removed: true,
          };
        }
        return i;
      });

      return {
        ...state,
        removeInvitations: {
          ...newReceiveRemoveInvitations,
        },
        allInvitations: newAllInvitations,
      };
    }

    case constants.REQUEST_NEW_USER_INVITED_INFORMATION: {
      return {
        ...state,
        newInviteUserNotifications: [
          ...state.newInviteUserNotifications,
          {
            invitation: action.data.invitation,
            show: true,
          },
        ],
      };
    }
    case constants.RESET_NEW_USER_INVITED_INFORMATION: {
      const invitationNotificationToUpdate =
        state.newInviteUserNotifications.filter(
          (i) => i.invitation.email === action.data.invitation.email,
        );

      const newInvitations = invitationNotificationToUpdate.map(
        (invitationNotification) => ({
          ...invitationNotification,
          show: false,
        }),
      );

      return {
        ...state,
        newInviteUserNotifications: [...newInvitations],
      };
    }
    case constants.REQUEST_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangingPassword: true,
        isChangingPasswordSucceed: false,
        isChangingPasswordError: false,
      };
    }
    case constants.RECEIVE_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangingPassword: false,
        isChangingPasswordSucceed: true,
        isChangingPasswordError: false,
      };
    }
    case constants.INVALID_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangingPassword: false,
        isChangingPasswordSucceed: false,
        isChangingPasswordError: true,
      };
    }
    case constants.RESET_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangingPassword: false,
        isChangingPasswordSucceed: false,
        isChangingPasswordError: false,
      };
    }
    case constants.RECEIVE_REMOVE_WIDGET_TO_USER_PROFILE: {
      const newCurrentUserForWidgetToRemove = state.currentUser;

      if (!newCurrentUserForWidgetToRemove.userProfile.widgetConfiguration) {
        newCurrentUserForWidgetToRemove.userProfile.widgetConfiguration = [
          {
            entityType: action.data.widgetToRemove.entityType,
            widgets: [
              {
                place: action.data.widgetToRemove.place,
                name: action.data.widgetToRemove.name,
                isDeleted: true,
              },
            ],
          },
        ];
      } else {
        const hadConfigForWidget =
          newCurrentUserForWidgetToRemove.userProfile.widgetConfiguration.find(
            (configPerType) =>
              configPerType.entityType.toLowerCase() ===
              action.data.widgetToRemove.entityType.toLowerCase(),
          );

        if (hadConfigForWidget) {
          hadConfigForWidget.widgets = hadConfigForWidget.widgets || [];

          hadConfigForWidget.widgets = hadConfigForWidget.widgets.filter(
            (w) =>
              w.name.toLowerCase() !==
              action.data.widgetToRemove.name.toLowerCase(),
          );

          hadConfigForWidget.widgets.push({
            place: action.data.widgetToRemove.place,
            name: action.data.widgetToRemove.name,
            isDeleted: true,
          });
        } else {
          newCurrentUserForWidgetToRemove.userProfile.widgetConfiguration.push({
            entityType: action.data.widgetToRemove.entityType,
            widgets: [
              {
                place: action.data.widgetToRemove.place,
                name: action.data.widgetToRemove.name,
                isDeleted: true,
              },
            ],
          });
        }
      }

      return {
        ...state,
        currentUser: {
          ...newCurrentUserForWidgetToRemove,
        },
      };
    }
    case constants.RECEIVE_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE: {
      const newCurrentUserForWidgetToAdd = state.currentUser;

      if (!newCurrentUserForWidgetToAdd.userProfile.widgetConfiguration) {
        newCurrentUserForWidgetToAdd.userProfile.widgetConfiguration = [
          {
            entityType: action.data.widgetToAdd.entityType,
            widgets: [
              {
                place: action.data.widgetToAdd.place,
                name: action.data.widgetToAdd.name,
                parameters: action.data.widgetToAdd.values,
              },
            ],
          },
        ];
      } else {
        const hadConfigForWidget =
          newCurrentUserForWidgetToAdd.userProfile.widgetConfiguration.find(
            (configPerType) =>
              configPerType.entityType.toLowerCase() ===
              action.data.widgetToAdd.entityType.toLowerCase(),
          );

        if (hadConfigForWidget) {
          hadConfigForWidget.widgets = hadConfigForWidget.widgets || [];
          hadConfigForWidget.widgets.push({
            place: action.data.widgetToAdd.place,
            name: action.data.widgetToAdd.name,
            parameters: action.data.widgetToAdd.values,
          });
        } else {
          newCurrentUserForWidgetToAdd.userProfile.widgetConfiguration.push({
            entityType: action.data.widgetToAdd.entityType,
            widgets: [
              {
                place: action.data.widgetToAdd.place,
                name: action.data.widgetToAdd.name,
                parameters: action.data.widgetToAdd.values,
              },
            ],
          });
        }
      }

      return {
        ...state,
        currentUser: {
          ...newCurrentUserForWidgetToAdd,
        },
      };
    }
    case constants.REQUEST_USER_ACCOUNT: {
      return {
        ...state,
        currentSelectedUser: null,
        isInvalidCurrentSelectedUser: false,
        isFetchingCurrentSelectedUser: true,
      };
    }
    case constants.RECEIVE_USER_ACCOUNT: {
      return {
        ...state,
        currentSelectedUser: action.data.user,
        isInvalidCurrentSelectedUser: false,
        isFetchingCurrentSelectedUser: false,
      };
    }
    case constants.INVALID_USER_ACCOUNT: {
      return {
        ...state,
        currentSelectedUser: null,
        isInvalidCurrentSelectedUser: true,
        isFetchingCurrentSelectedUser: false,
      };
    }
    case constants.REQUEST_UPDATE_ROLE: {
      return {
        ...state,
        changingRole: [action.data.roleName, ...state.changingRole],
      };
    }
    case constants.RECEIVE_UPDATE_ROLE: {
      // update user with new roles
      // find user in allUser and update it
      // also update currentUser
      const { roleName, userName, isPromotion } = action.data;
      const { currentSelectedUser, currentUser, allUsers } = state;
      const userInAllUsers = allUsers.find(
        (u) => u.Account.UserName === userName,
      );
      if (currentSelectedUser) {
        if (isPromotion) {
          currentSelectedUser.Account.Roles.push(roleName);
        } else {
          currentSelectedUser.Account.Roles =
            currentSelectedUser.Account.Roles.filter((r) => r !== roleName);
        }
      }
      if (currentUser) {
        if (isPromotion) {
          currentUser.client.Roles.push(roleName);
        } else {
          currentUser.client.Roles = currentUser.client.Roles.filter(
            (r) => r !== roleName,
          );
        }
      }
      if (userInAllUsers) {
        const idx = allUsers.indexOf(userInAllUsers);
        if (isPromotion) {
          userInAllUsers.Account.Roles.push(roleName);
        } else {
          userInAllUsers.Account.Roles = userInAllUsers.Account.Roles.filter(
            (r) => r !== roleName,
          );
        }
        allUsers.splice(idx, 1, userInAllUsers);
      }
      return {
        ...state,
        currentUser,
        currentSelectedUser,
        allUsers,
        changingRole: [
          ...state.changingRole.filter((r) => r !== action.data.roleName),
        ],
      };
    }
    case constants.INVALID_UPDATE_ROLE: {
      return {
        ...state,
        changingRole: [
          ...state.changingRole.filter((r) => r !== action.data.roleName),
        ],
      };
    }
    case constants.REQUEST_ALL_PROVIDER_FOR_USER: {
      return {
        ...state,
        isFetchingAllProvidersForUser: {
          [action.data.userId]: true,
        },
        allProvidersByUser: {
          [action.data.userId]: [],
        },
      };
    }
    case constants.RECEIVE_ALL_PROVIDER_FOR_USER: {
      return {
        ...state,
        isFetchingAllProvidersForUser: {
          [action.data.userId]: false,
        },
        allProvidersByUser: {
          ...state.allProvidersByUser,
          [action.data.userId]: [...action.data.providerList],
        },
      };
    }
    case constants.SHOULD_ADD_CONFIGURATION_FROM_PERMISSION: {
      const currentPremissions =
        state.allProvidersByUser[action.data.userId] || [];
      return {
        ...state,
        allProvidersByUser: {
          ...state.allProvidersByUser,
          [action.data.userId]: [
            ...currentPremissions,
            {
              UserId: action.data.userId,
              Id: action.data.configurationId,
            },
          ],
        },
      };
    }
    case constants.SHOULD_REMOVE_CONFIGURATION_FROM_PERMISSION: {
      const currentPremissionsForAdd =
        state.allProvidersByUser[action.data.userId] || [];
      const newPermissions = currentPremissionsForAdd.filter(
        (p) => p.Id !== action.data.configurationId,
      );
      return {
        ...state,
        allProvidersByUser: {
          ...state.allProvidersByUser,
          [action.data.userId]: [...newPermissions],
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default update;
