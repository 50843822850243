export const graphConfig = {
  fit: {
    minZoom: 1.8,
    maxZoom: 3,
    animation: {
      duration: 200,
    },
  },
  center: {
    position: { x: 50, y: 0 },
  },
};
