/**
 * Takes an array of object and returns an array of
 * string representing the property name that are
 * defined more than once per objects.
 */
export const getConflitedProperties = (arrayOfObject = []) =>
  arrayOfObject
    .reduce((accu, objectWithProperties) => {
      Object.keys(objectWithProperties).forEach((propertyKey) => {
        const hasValue = accu.find((x) => x.propertyKey === propertyKey);

        if (hasValue) {
          hasValue.score += 1;
        } else {
          accu.push({
            propertyKey,
            score: 1,
          });
        }
      });

      return accu;
    }, [])
    .filter((x) => x.score > 1)
    .map((x) => x.propertyKey);

export const getOnlyPropertyObject = (entities = []) =>
  entities
    .map((entity) =>
      entity && entity.data && entity.data.properties
        ? entity.data.properties
        : null,
    )
    .filter((x) => x);

/**
 *
 * @param {string[]} conflictedProperties
 * @param {entities[]} entities
 * @returns {[]} returns an array object that has the property key
 * and an array of values such as { key, value, entityName, entityId } for each entity.
 */
export const getConflictedPropertyWithValuesAndSource = (
  conflictedProperties = [],
  entities = [],
) =>
  conflictedProperties.reduce((accu, conflictedPropertyKey) => {
    const propsWithValues = entities.reduce(
      (conflictedPropertiesWithValues, entity) => {
        const currentValueForEntity =
          entity.data.properties[conflictedPropertyKey];
        if (currentValueForEntity) {
          // only add value if there is a value
          const valueToAdd = {
            value: currentValueForEntity,
            entityName:
              entity.displayName || entity.name || `${entity.id} (no name)`,
            entityId: entity.id,
            key: conflictedPropertyKey,
          };

          const foundValue = conflictedPropertiesWithValues.find(
            (conflictedPropertyWithValues) =>
              conflictedPropertyWithValues.key === conflictedPropertyKey,
          );
          if (foundValue) {
            foundValue.values.push(valueToAdd);
          } else {
            conflictedPropertiesWithValues.push({
              key: conflictedPropertyKey,
              values: [valueToAdd],
            });
          }
        }

        return conflictedPropertiesWithValues;
      },
      [],
    );

    return [...accu, ...propsWithValues];
  }, []);

export default {
  getConflitedProperties,
  getOnlyPropertyObject,
  getConflictedPropertyWithValuesAndSource,
};
