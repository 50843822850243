import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

type Params = {
  value: string;
  searchURI: string;
  queryParamName: string;
  isOnSearchPage: boolean;
  searchByAllValue: string;
};

type GetSearchStringParam = Pick<Params, 'searchByAllValue' | 'value'>;
const getSearchStringParam = ({
  searchByAllValue,
  value,
}: GetSearchStringParam): string => {
  if (!value) {
    return searchByAllValue;
  }

  return value;
};

const getSearchString = ({
  value,
  searchURI,
  queryParamName,
  searchByAllValue,
}: Omit<Params, 'isOnSearchPage'>): string => {
  const mainPath = searchURI;
  const q = getSearchStringParam({ value: value ?? '*', searchByAllValue });

  const stringifiedParams = qs.stringify({ [queryParamName]: q });

  const qString = `${mainPath}?${stringifiedParams}`;

  return qString;
};

export const useAppSearchBrowserApi = ({
  value,
  searchURI,
  queryParamName,
  isOnSearchPage,
  searchByAllValue,
}: Params) => {
  const history = useHistory();

  const handleSearch = useCallback(() => {
    if (!isOnSearchPage) {
      const qString = getSearchString({
        value,
        searchURI,
        queryParamName,
        searchByAllValue,
      });

      return history.push(qString);
    }

    const currentSearch = qs.parse(history.location.search);

    const qString = qs.stringify({
      ...currentSearch,
      [queryParamName]: getSearchStringParam({
        value: value ?? '*',
        searchByAllValue,
      }),
    });

    return history.push(`${history.location.pathname}?${qString}`);
  }, [value, isOnSearchPage, history.location]);

  const handleSetSuggestedItem = useCallback(
    (itemValue: string) => {
      const qString = getSearchString({
        searchURI,
        queryParamName,
        value: itemValue,
        searchByAllValue,
      });

      history.push(qString);
    },
    [value],
  );

  const handleClearSearch = useCallback(() => {
    const qString = getSearchString({
      value: '',
      searchURI,
      queryParamName,
      searchByAllValue,
    });

    history.push(qString);
  }, [value]);

  return {
    handleSearch,
    handleClearSearch,
    handleSetSuggestedItem,
  };
};
