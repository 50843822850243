import React from 'react';
import { withWorkflow } from '@cluedin/form';

import EntityCodePanel from '../components/containers/EntityCodePanel/EntityCodePanel';

const EntityCodePanelWrapper =
  (Comp) =>
  ({ openWorkflow, ...props }) =>
    <Comp openEntityCodePanelWorkflow={openWorkflow} {...props} />;

export const withEntityCodePanelWorkflow = (Comp) =>
  withWorkflow(EntityCodePanelWrapper(Comp), EntityCodePanel, {
    withCustomHeader: true,
    panelType: 'custom',
    width: 1200,
  });
