import React from 'react';
import groupBy from 'lodash/groupBy';
import { H3 } from 'uxi/Classic';
import { FormattedMessage } from '@cluedin/locale';
import SearchFIltersWrapper from './SearchFIltersWrapper';
import EntityFilter from './EntityFilter';
import AdvancedSearchFilters from './AdvancedSearchFilters';
import { withEntityQuickview } from '../../../../../../entity/components/Hocs/withEntityQuickview';
import AllActiveSearchFilters from './AllActiveSearchFilters';
import SearchFiltersLayout from './SearchFiltersLayout';
import ShowAdvancedFiltersButton from './ShowAdvancedFiltersButton';
import withInMemoryFilter from '../../../../HOCs/withInMemoryFilter';
import FilterGroup from './FilterGroup';
import { defaultFilters } from './DefaultFilters';
import { personPool, orgPool } from './poolConfig';

const FilterGroupWithMemoryFilter = withInMemoryFilter(FilterGroup, '/');

const SearchFilters = ({
  showAdvancedSearch,
  selectedFilters,
  onRemoveFilter,
  onAddFilter,
  onShowAdvancedSearch,
  onHideAdvancedSearch,
  closeEntityQuickView,
  onClearAllSearchFilter,
  currentEntityTypePoolIdentifier,
  onEntityTypePoolChange,
  total,
  promotedFilters,
  filters,
  totalLoading,
}) => {
  const groupedBy = Object.keys(groupBy(selectedFilters || [], 'type'));

  const onlyAdvancedActiveFilters = (groupedBy || []).filter(
    (key) => !defaultFilters.includes(key),
  );

  const onAllFilterClick = () => {
    onShowAdvancedSearch();
    closeEntityQuickView();
  };

  return (
    <>
      <SearchFIltersWrapper>
        <H3
          style={{
            margin: '8px 16px 8px 16px',
            paddingBottom: '6px',
          }}
        >
          <FormattedMessage
            defaultMessage="Filter by"
            id="SearchContent.filterBy"
          />
        </H3>
        <div style={{ borderBottom: '1px solid #cecece' }} />
        <AllActiveSearchFilters
          selectedFilters={selectedFilters}
          onRemoveFilter={onRemoveFilter}
          onClearAllSearchFilter={onClearAllSearchFilter}
          onAllFilterClick={onAllFilterClick}
        />
        <SearchFiltersLayout filtersCount={selectedFilters.length}>
          {Object.keys(promotedFilters).map((k, i) => {
            const selectedFiltersLocal = selectedFilters.filter(
              (x) => x.type.toLowerCase() === k.toLowerCase(),
            );
            return (
              <FilterGroupWithMemoryFilter
                //isFetchingMainSearch={isFetchingSearch}
                key={`${k}-${i}`}
                title={k}
                filters={promotedFilters[k]}
                totalFiltersCount={((promotedFilters || {})[k] || []).length}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                selectedFilters={selectedFiltersLocal}
                filterType={{ type: 'facet', value: k }}
                currentEntityTypePoolIdentifier={
                  currentEntityTypePoolIdentifier
                }
                onEntityTypePoolChange={onEntityTypePoolChange}
              />
            );
          })}
          <EntityFilter
            key="Person"
            title={<FormattedMessage id="module-search-person" />}
            selectedFilters={selectedFilters.filter(
              (x) => x.type === personPool.join('-'),
            )}
            entityTypes={personPool}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            filterType={{ type: 'facet', value: 'Person' }}
          />
          <EntityFilter
            key="Organization"
            title={<FormattedMessage id="module-search-organization" />}
            selectedFilters={selectedFilters.filter(
              (x) => x.type === orgPool.join('-'),
            )}
            entityTypes={orgPool}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            filterType={{ type: 'facet', value: 'Organization' }}
          />
        </SearchFiltersLayout>
        {onlyAdvancedActiveFilters.length === 0 && (
          <ShowAdvancedFiltersButton onAllFilterClick={onAllFilterClick} />
        )}
      </SearchFIltersWrapper>

      <AdvancedSearchFilters
        selectedFilters={selectedFilters}
        onAddFilter={onAddFilter}
        onRemoveFilter={onRemoveFilter}
        totalLoading={totalLoading}
        // isFetchingSearch={isFetchingSearch}
        // shouldFetchSearch={shouldFetchSearch}
        searchStat={total}
        facets={filters}
        showAdvancedSearch={showAdvancedSearch}
        onClose={onHideAdvancedSearch}
        currentEntityTypePoolIdentifier={currentEntityTypePoolIdentifier}
        onEntityTypePoolChange={onEntityTypePoolChange}
      />
    </>
  );
};

SearchFilters.displayName = 'AllSearchFiltersContainer';

export default withEntityQuickview(SearchFilters);
