import { handleActions } from 'redux-actions';
import {
  changeSSOProviderSelection,
  ssoProvidersActions,
  ssoProviderSaveAccount,
  ssoProviderActions,
} from './actions';

const initialState = {
  isFetchingSSOProviders: true,
  SSOProviders: [],
  isSavingSSOProvider: false,
  showSuccessSaveSSOProvider: false,
  showErrorSSOProvider: false,
  isFetchingCurrentSSOProvider: true,
};

export default handleActions(
  {
    [ssoProvidersActions.request]: (state) => ({
      ...state,
      isFetchingSSOProviders: true,
    }),
    [ssoProvidersActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingSSOProviders: false,
      SSOProviders: [...payload],
    }),
    [changeSSOProviderSelection]: (state, { payload }) => ({
      ...state,
      SSOSelectionValue: payload,
    }),
    [ssoProviderSaveAccount.request]: (state) => ({
      ...state,
      isSavingSSOProvider: true,
      showSuccessSaveSSOProvider: false,
    }),
    [ssoProviderSaveAccount.receive]: (state) => ({
      ...state,
      isSavingSSOProvider: false,
      showSuccessSaveSSOProvider: true,
    }),
    [ssoProvidersActions.invalid]: (state) => ({
      ...state,
      showSuccessSaveSSOProvider: false,
      isSavingSSOProvider: false,
      showErrorSSOProvider: true,
    }),
    [ssoProviderActions.request]: (state) => ({
      ...state,
      isFetchingCurrentSSOProvider: true,
      invalidCurrentSSOProvider: false,
    }),
    [ssoProviderActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingCurrentSSOProvider: false,
      invalidCurrentSSOProvider: false,
      currentSSOProvider: payload,
    }),
    [ssoProviderActions.invalid]: (state) => ({
      ...state,
      isFetchingCurrentSSOProvider: false,
      invalidCurrentSSOProvider: true,
    }),
  },
  initialState,
);
