import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cogoToast from 'cogo-toast';

import { Panel, PanelHeader, PanelFooter, PanelContent } from '@cluedin/form';
import { Stepper } from '@cluedin/components';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { CancelButton, PrimaryButton, Button, PanelClose } from '@cluedin/form';

import { FieldAlert } from '../../../../../../shared/components/FieldAlert';
import PageLoader from '../../../../../core/components/composites/PageLoader';
import { useChangeEntityPropertyValue } from '../../../../hooks/useChangeEntityPropertyValue';
import EntityPropertiesStep1Options from '../EntityPropertiesStep1Options/EntityPropertiesStep1Options';
import EntityPropertiesStep1Warning from '../EntityPropertiesStep1Warning/EntityPropertiesStep1Warning';
import EntityPropertiesStep2Edit from '../EntityPropertiesStep2Edit/EntityPropertiesStep2Edit';
import { EntityPropertiesEditPanelV2Wrapper } from './EntityPropertiesEditPanelV2.styles';

const defaultStepperState = {
  active: 1,
  completed: [],
};

const EntityPropertiesEditPanelV2 = ({
  entityId,
  entityVocabularyKey,
  intl,
  isEditPanelExpanded,
  setIsEditPanelExpanded,
  setIsExpanded,
  value,
  vocabularyKey,
  refetch,
  historyRefetch,
}) => {
  const keyToDisplay = get(vocabularyKey, 'key');
  const headerKeyToDisplay = keyToDisplay ? keyToDisplay : entityVocabularyKey;

  const [changeEntityPropertyValue, { data, loading, error }] =
    useChangeEntityPropertyValue({
      entityId,
      vocabularyKey: keyToDisplay,
    });

  const [stepperState, setStepperState] = useState(defaultStepperState);
  const [editMode, setEditMode] = useState('');
  const [entityInputValue, setEntityInputValue] = useState('');

  const onClose = () => {
    setIsExpanded(false);
    setIsEditPanelExpanded(false);
  };

  const resetStepper = () => {
    setStepperState(defaultStepperState);
    setEditMode('');
  };

  const handleCancel = () => {
    resetStepper();
    onClose();
  };

  const handleBack = () => {
    setStepperState({
      active: 1,
      completed: [],
    });
  };

  const handleSave = async () => {
    try {
      if (typeof entityInputValue === 'object' && entityInputValue?.value) {
        await changeEntityPropertyValue(entityInputValue.value, editMode);
      } else {
        await changeEntityPropertyValue(entityInputValue, editMode);
      }

      resetStepper();
      setEntityInputValue('');

      onClose();

      cogoToast.success(
        intl.formatMessage({
          id: 'module-entityProperties-editToastSuccess',
        }),
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    } catch {
      cogoToast.error(
        intl.formatMessage({ id: 'module-entityProperties-editToastError' }),
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    } finally {
      setTimeout(() => {
        refetch();
        historyRefetch(entityId);
      }, 2000);
    }
  };

  const vocabularyDataType = get(vocabularyKey, 'dataType');
  const vocabularyName = get(vocabularyKey, 'name');

  return (
    <Panel
      open={isEditPanelExpanded}
      withCustomHeader={true}
      panelType="medium"
    >
      <PanelHeader
        style={{
          fontSize: '16px',
          padding: '0 16px',
        }}
        onClose={onClose}
        CustomClose={
          <PanelClose
            confirmTitle={intl.formatMessage({
              id: 'data-source-confirm-lost-changes-title',
            })}
            confirmMessage={intl.formatMessage({
              id: 'data-source-confirm-lost-changes-message',
            })}
            onConfirm={onClose}
            onClick={onClose}
          />
        }
        title={
          <FormattedMessage
            id="module-entityProperties-editPanelHeaderTitle"
            values={{ headerKeyToDisplay }}
          />
        }
      />

      <PanelContent style={{ padding: '0px' }}>
        <Stepper
          activeStep={stepperState.active}
          completedSteps={stepperState.completed}
          onClickStep={(active) => {
            if (active === 1) {
              handleBack();
            }
          }}
          steps={[
            {
              label: (
                <FormattedMessage id="module-entityProperties-editPanelFirstStep" />
              ),
            },
            {
              label: (
                <FormattedMessage id="module-entityProperties-editPanelSecondStep" />
              ),
            },
          ]}
        />

        <EntityPropertiesEditPanelV2Wrapper>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              {stepperState.active === 1 && (
                <>
                  <EntityPropertiesStep1Options
                    editMode={editMode}
                    setEditMode={setEditMode}
                    entityId={entityId}
                  />

                  <EntityPropertiesStep1Warning
                    editMode={editMode}
                    entityId={entityId}
                    keyToDisplay={keyToDisplay}
                    vocabularyKey={vocabularyKey}
                  />
                </>
              )}

              {stepperState.active === 2 && (
                <>
                  <EntityPropertiesStep2Edit
                    error={error}
                    dataType={vocabularyDataType}
                    name={vocabularyName}
                    setEntityInputValue={setEntityInputValue}
                    vocabularyValue={value}
                    keyToDisplay={keyToDisplay}
                    entityInputValue={entityInputValue}
                    vocabularyKey={vocabularyKey}
                  />
                  <FieldAlert
                    type="warning"
                    message={intl.formatMessage({
                      id: 'module-entityProperties-alert',
                    })}
                  />
                </>
              )}
            </>
          )}
        </EntityPropertiesEditPanelV2Wrapper>
      </PanelContent>

      <PanelFooter>
        {stepperState.active === 2 && (
          <Button
            color="accent"
            onClick={handleBack}
            variant="outlined"
            onConfirm={handleBack}
            rounded
            style={{
              marginRight: '10px',
            }}
          >
            <FormattedMessage id="module-entityProperties-editPanelFooterBack" />
          </Button>
        )}

        <CancelButton
          onClick={handleCancel}
          variant="outlined"
          onConfirm={handleCancel}
          rounded
          style={{
            marginRight: '10px',
          }}
        >
          <FormattedMessage id="module-entityProperties-editPanelFooterCancel" />
        </CancelButton>

        {stepperState.active === 1 && (
          <PrimaryButton
            disabled={!stepperState.completed.includes(1) && editMode === ''}
            data-test={'entityProperties-editPanel-step1NextButton'}
            color="accent"
            onClick={() => {
              setStepperState({
                active: 2,
                completed: [1],
              });
            }}
            rounded
            className="panel-main-action"
          >
            <FormattedMessage id="module-entityProperties-editPanelFooterNext" />
          </PrimaryButton>
        )}

        {stepperState.active === 2 && (
          <PrimaryButton
            data-test={'entityProperties-editPanel-step2SaveButton'}
            color="accent"
            onClick={handleSave}
            rounded
          >
            <FormattedMessage id="module-entityProperties-editPanelSecondStepSave" />
          </PrimaryButton>
        )}
      </PanelFooter>
    </Panel>
  );
};

export default injectIntl(EntityPropertiesEditPanelV2);

EntityPropertiesEditPanelV2.propTypes = {
  entityId: PropTypes.string,
  entityVocabularyKey: PropTypes.string,
  isEditPanelExpanded: PropTypes.bool,
  setIsEditPanelExpanded: PropTypes.func,
  setIsExpanded: PropTypes.func,
  value: PropTypes.string,
  vocabularyKey: PropTypes.object,
  refetch: PropTypes.func,
};

EntityPropertiesEditPanelV2.defaultProps = {
  entityId: null,
  entityVocabularyKey: PropTypes.string,
  isEditPanelExpanded: false,
  setIsEditPanelExpanded: () => {},
  setIsExpanded: () => {},
  value: '',
  vocabularyKey: {},
  refetch: () => {},
};
