import React from 'react';
import Badge from 'uxi/Badge';
import { Delete } from 'uxi/Icons';
import { Flex } from 'uxi/Layout';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import { UnstyledButtonForBadge } from '../UnstyledButtonForBadge';

const SelectedFilter = ({ onFocusIn, filter, onRemoveFilter }) => {
  return (
    <>
      {filter && filter.displayName && (
        <UnstyledButtonForBadge
          onFocus={onFocusIn}
          onClick={() => {
            onRemoveFilter(filter);
          }}
          style={{ maxWidth: '100%' }}
        >
          <Badge type="success" style={{ maxWidth: '100%' }}>
            <Flex style={{ paddingLeft: '6px' }}>
              <TextEllipsis>{filter.displayName}</TextEllipsis>
              <Delete
                style={{ margin: '0 2px 0 6px', cursor: 'pointer' }}
                size="14"
                hoverColor={'white'}
                color={'rgba(255, 255, 255, 0.6)'}
              />
            </Flex>
          </Badge>
        </UnstyledButtonForBadge>
      )}
    </>
  );
};

export default SelectedFilter;
