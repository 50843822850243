import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { ButtonLink } from '@cluedin/atoms';
import { register } from '../../registry';
import StaticGoldenRecordList from '../../../../goldenRecordList/components/containers/StaticGoldenRecordList';
import { StaticGoldenRecordListProvider } from '../../../../goldenRecordList/components/containers/StaticGoldenRecordListContext';
import { WidgetTitle } from '../../../components/composites/widgets';

import { useQuerySuggestedSearch } from './useQuerySuggestedSearch';

const EntitySuggestedSearch = withRouter(({ search, history, location }) => {
  const { data, loading, error, loadMore, loadingMore, noMoreEntities } =
    useQuerySuggestedSearch(search);

  return (
    <div
      style={{
        margin: '0 24px 24px 24px',
        border: '1px solid #ececec',
        background: 'white',
      }}
    >
      <StaticGoldenRecordListProvider
        history={history}
        location={location}
        viewName={`suggested_search_${search.Id}`}
        hasSelection={false}
        hideListSearch={true}
        entities={data}
        total={0}
        loading={loading}
      >
        <StaticGoldenRecordList
          listToolbarAdditionalLeftContent={
            <WidgetTitle>{search.DisplayName}</WidgetTitle>
          }
          listFooterAdditionalRightContent={
            <>
              {!noMoreEntities && (
                <ButtonLink
                  disabled={loadingMore}
                  text={<FormattedMessage id="module-entityWidget-loadMore" />}
                  onClick={loadMore}
                />
              )}
            </>
          }
        />
      </StaticGoldenRecordListProvider>
    </div>
  );
});

register('EntitySuggestedSearchV2', EntitySuggestedSearch, {
  name: 'EntitySuggestedSearchV2',
  displayName: 'Suggested Search',
  description: 'All related searched.',
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
