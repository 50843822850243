import { onError } from 'apollo-link-error';
import { fromPromise } from 'apollo-link';
import { getNewToken } from '../../modules/core/helpers/token';
import { getStore } from '../../components/SetupApp';
import { generalSessionExpired } from '../../modules/error/actions';

const errorLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    if (networkError) {
      switch (networkError.statusCode) {
        case 401:
          console.warn('Refreshing token and trying again');
          // await refreshToken, then call its link middleware again
          return fromPromise(
            getNewToken().then((resp) => {
              if (!resp) {
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                const store = getStore();
                store.dispatch(
                  generalSessionExpired({
                    params: {},
                    errorMessage: 'Could not refresh during a GQL call',
                    url: '',
                    status: 401,
                  }),
                );
                return true;
              }
              return resp;
            }),
          )
            .filter((value) => Boolean(value))
            .flatMap(() => forward(operation));
        default:
        // Handle all other errors here. Irrelevant here.
      }
    }
    if (graphQLErrors) {
      // Handle gql errors, irrelevant here.
    }
  },
);

export default errorLink;
