import React, { useEffect, useMemo, useRef, useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Loader } from '@cluedin/atoms';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import cogoToast from 'cogo-toast';
import {
  CancelButton,
  PrimaryButton,
  PanelContent,
  PanelFooter,
  TextField,
  withConfirmDialog,
  ConfirmCancelDialog,
  PanelHeader,
  PanelClose,
  FormDecorator,
} from '@cluedin/form';

import { withCurrentUser } from '../../../../user/components/Hocs/withCurrentUser';
import usePrevious from '../../../../core/hooks/usePrevious';
import { useCreateDataSource } from '../../../hooks/useCreateDataSource';
import EndpointCreationForm from '../forms/EndpointCreationForm';
import { useCreateDataSets } from '../../../hooks/useCreateDataSets';
import { InfoLoader } from '../../composites/InfoLoader';
import { isAlphaNumeric, isEmpty } from '../../../../core/validation';
import { useQueryAllAnnotations } from '../../../hooks/useQueryAllAnnotations';
import {
  OnlyAlphaNumbericErrorMessage,
  NotEmptyErrorMessage,
  NotLongErrorMessage,
} from '../../../../core/validation/messages';
import { withEntityTypeConfigurationReload } from '../../../../wms/components/Hocs/withEntityTypeConfigurationReload';

const AddIngestionPointWithDataSetComponent = ({
  title,
  onClose,
  history,
  isSaving,
  currentUser,
  dataSourceSetId,
  reloadEntityConfiguration,
}) => {
  const [dataSourceName, setDataSourceName] = useState('');
  const [endPointName, onChangeEndpointName] = useState('');
  const [entityTypeObject, onChangeEntityType] = useState('');
  const [touched, setTouched] = useState(false);
  const [mappingMode, setMappingMode] = useState('new');

  const [allExistingAnnotations, isLoadingAllExistingAnnotations] =
    useQueryAllAnnotations();
  const [selectedDataSet, setDataSet] = useState();

  const [
    createDataSource,
    {
      data: dataSource,
      error: errorCreateDataSource,
      loading: loadingCreateDataSource,
    },
  ] = useCreateDataSource();

  const currentDataSourceId = useRef(dataSource?.id);

  const dataSourceId = currentDataSourceId.current || dataSource?.id;
  const prevDataSourceId = usePrevious(dataSourceId);

  useEffect(() => {
    if (dataSource?.id && dataSource?.id !== currentDataSourceId.current) {
      currentDataSourceId.current = dataSource?.id;
    }
  }, [dataSource]);

  const onEndpointCreated = () => {
    cogoToast.success(`Endpoint added successfully`, {
      position: 'bottom-right',
      hideAfter: 5,
    });

    if (reloadEntityConfiguration) {
      reloadEntityConfiguration();
    }

    history.push(
      `/admin/inbound/datasourceset/datasource/${currentDataSourceId.current}`,
    );
  };

  const [
    createEndpoint,
    { loading: isSavingEndpoint, error: errorCreatingEndpoint },
  ] = useCreateDataSets(onEndpointCreated);

  const isValidName = isAlphaNumeric(dataSourceName);
  const isEmptyName = isEmpty(dataSourceName) && !!dataSourceName.length;
  const isNotLongDataSourceName = dataSourceName?.length < 256;

  const isValidFormatEndPointName = isAlphaNumeric(endPointName);
  const isEmptyEndpointName = isEmpty(endPointName) && !!endPointName.length;
  const isNotLongEndpointName = endPointName?.length < 256;

  const isValidDataSourceName =
    dataSourceName && isValidName && !isEmptyName && isNotLongDataSourceName;

  const isValidEndpointName =
    endPointName &&
    isValidFormatEndPointName &&
    !isEmptyEndpointName &&
    isNotLongEndpointName;

  const validEntityType = mappingMode === 'new' && entityTypeObject;

  const validDataSet =
    mappingMode === 'existing' &&
    selectedDataSet &&
    selectedDataSet.id &&
    selectedDataSet.annotationId;

  let validMapping = false;

  if (mappingMode === 'new') {
    validMapping = validEntityType;
  }

  if (mappingMode === 'existing') {
    validMapping = validDataSet;
  }

  const disableSubmit =
    !isValidDataSourceName || !isValidEndpointName || !validMapping;

  const isProgressing = isSavingEndpoint || isSaving || loadingCreateDataSource;
  const author = useMemo(() => currentUser?.client?.Id ?? '', [currentUser]);

  const needConfirm = endPointName || dataSourceName || entityTypeObject;

  const ButtonWithConfirm = needConfirm
    ? withConfirmDialog(CancelButton, ConfirmCancelDialog)
    : CancelButton;
  const ClosePanelButton = needConfirm
    ? withConfirmDialog(PanelClose, ConfirmCancelDialog)
    : PanelClose;

  useEffect(() => {
    if (dataSourceId && prevDataSourceId !== dataSourceId) {
      createEndpoint({
        dataSourceId: dataSourceId,
        existingAnnotationId: selectedDataSet
          ? selectedDataSet.annotationId
          : '',
        existingDataSetId: selectedDataSet ? selectedDataSet.id : '',
        dataSets: [
          {
            author,
            store: true,
            type: 'endpoint',
            name: endPointName,
            configuration: {
              object: {
                endPointName,
                autoSubmit: false,
                entityType: entityTypeObject.entityType,
              },
              entityTypeConfiguration: {
                ...entityTypeObject,
              },
            },
          },
        ],
      });
    }
  }, [
    author,
    dataSourceId,
    endPointName,
    createEndpoint,
    entityTypeObject,
    prevDataSourceId,
  ]);

  const handleAdd = () => {
    createDataSource({
      dataSourceSetId: dataSourceSetId,
      dataSource: {
        author,
        type: 'endpoint',
        name: dataSourceName,
      },
    });
  };

  const errorText = isNotLongEndpointName ? (
    (!isValidEndpointName && <OnlyAlphaNumbericErrorMessage />) ||
    (isEmptyEndpointName && <NotEmptyErrorMessage />)
  ) : (
    <NotLongErrorMessage />
  );

  const entityTypeValidationError = useMemo(() => {
    if (
      entityTypeObject?.entityType?.charAt(0) === '/' ||
      mappingMode === 'existing'
    ) {
      return undefined;
    }

    return (
      <FormattedMessage id="module-entityType-entityType-must-start-from-slash" />
    );
  }, [entityTypeObject, mappingMode]);

  const entityTypeIconValidationError = useMemo(() => {
    if (entityTypeObject?.icon !== '' || mappingMode === 'existing') {
      return undefined;
    }

    return (
      <FormattedMessage id="module-entityType-entityType-icon-must-be-selected" />
    );
  }, [entityTypeObject, mappingMode]);
  return (
    <>
      <PanelHeader
        title={title}
        onClose={onClose}
        CustomClose={
          <ClosePanelButton
            confirmTitle={
              <FormattedMessage id="data-source-confirm-lost-changes-title" />
            }
            confirmMessage={
              <FormattedMessage id="data-source-confirm-lost-changes-message" />
            }
            onConfirm={() => onClose()}
            onClick={onClose}
          />
        }
      />

      <PanelContent>
        {errorCreateDataSource && (
          <div style={{ paddingBottom: '24px' }}>
            <Alert type="danger">{errorCreateDataSource}</Alert>
          </div>
        )}
        {errorCreatingEndpoint && (
          <div style={{ paddingBottom: '24px' }}>
            <Alert type="danger">{errorCreatingEndpoint}</Alert>
          </div>
        )}
        {!isProgressing && (
          <>
            <FormDecorator
              isRequired
              label={<FormattedMessage id="data-source-name" />}
              helpText={<FormattedMessage id="data-source-add-help-text" />}
              errorText={
                isNotLongDataSourceName ? (
                  (!isValidName && <OnlyAlphaNumbericErrorMessage />) ||
                  (isEmptyName && <NotEmptyErrorMessage />)
                ) : (
                  <NotLongErrorMessage />
                )
              }
            >
              <TextField
                value={dataSourceName}
                onChange={(_, v) => setDataSourceName(v)}
              />
            </FormDecorator>
            <EndpointCreationForm
              entityTypeValidationError={entityTypeValidationError}
              entityTypeIconValidationError={entityTypeIconValidationError}
              endPointName={endPointName}
              onChangeEndpointName={(v) => {
                setTouched(true);
                onChangeEndpointName(v);
              }}
              entityType={entityTypeObject}
              onChangeEntityType={(v) => {
                setTouched(true);
                onChangeEntityType(v);
              }}
              errorEndpointName={touched && errorText}
              allExistingAnnotations={allExistingAnnotations}
              isLoadingAllExistingAnnotations={isLoadingAllExistingAnnotations}
              selectedDataSet={selectedDataSet}
              setDataSet={setDataSet}
              mappingMode={mappingMode}
              setMappingMode={setMappingMode}
            />
          </>
        )}

        {isProgressing && (
          <InfoLoader
            title={
              <FormattedMessage id="data-source-ingestion-info-loader-title" />
            }
            text={
              <FormattedMessage id="data-source-ingestion-info-loader-message" />
            }
          />
        )}
      </PanelContent>

      <PanelFooter>
        <ButtonWithConfirm
          confirmTitle={
            <FormattedMessage id="data-source-confirm-lost-changes-title" />
          }
          confirmMessage={
            <FormattedMessage id="data-source-confirm-lost-changes-message" />
          }
          onConfirm={() => onClose()}
          data-test={'newDataSourceGroupCancelButton'}
          rounded
          onClick={onClose}
        >
          <FormattedMessage id="data-source-cancel" />
        </ButtonWithConfirm>

        <PrimaryButton
          rounded
          onClick={handleAdd}
          style={{ marginLeft: '8px' }}
          className="panel-main-action"
          disabled={
            disableSubmit ||
            isProgressing ||
            entityTypeValidationError ||
            entityTypeIconValidationError
          }
          startIcon={isProgressing ? <Loader color="#fff" size={14} /> : null}
        >
          <FormattedMessage id="data-source-add" />
        </PrimaryButton>
      </PanelFooter>
    </>
  );
};

export const AddIngestionPointWithDataSet = compose(
  withCurrentUser,
  withRouter,
  withEntityTypeConfigurationReload,
)(AddIngestionPointWithDataSetComponent);
