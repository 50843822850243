import { uniq } from 'lodash';

const formatName = (name) => name.toLowerCase().replace(' ', '');

const cluedinProcessorString = 'CluedIn('.toLocaleLowerCase();

const isDataSet = (str) => {
  return str.startsWith('ds') && str.length === 34;
};

const isCluedInProcessor = (name = '') =>
  name.toLocaleLowerCase().indexOf(cluedinProcessorString) > -1;

const findSourceInConfig = (property = '', connectors = [], withoutCluedIn) => {
  const result = [];

  if (!property) {
    return null;
  }

  connectors.forEach((connector) => {
    let localFound;
    if (formatName(property) === formatName(connector.name)) {
      localFound = {
        strict: true,
        provider: connector,
      };
    }

    if (
      !localFound &&
      formatName(connector.name).indexOf(formatName(property)) > -1
    ) {
      localFound = {
        strict: false,
        provider: connector,
      };
    }
    if (
      !localFound &&
      formatName(property).indexOf(formatName(connector.name)) > -1
    ) {
      localFound = {
        strict: false,
        provider: connector,
      };
    }

    if (!localFound && connector.Aliases) {
      connector.Aliases.forEach((singleAlias) => {
        if (!localFound && formatName(property) === formatName(singleAlias)) {
          localFound = {
            strict: true,
            provider: connector,
          };
        }
        if (
          !localFound &&
          formatName(property).indexOf(formatName(singleAlias)) > -1
        ) {
          localFound = {
            strict: false,
            provider: connector,
          };
        }
      });
    }
    if (localFound) {
      result.push(localFound);
    }
  });

  if (result.length === 0 && !withoutCluedIn) {
    return DefaultSource;
  }
  if (result.length === 1) {
    return result[0].provider;
  }

  if (result.length > 1) {
    const hasStrict = result.find((r) => r.strict);
    if (hasStrict) {
      return hasStrict.provider;
    }

    return result[0].provider;
  }

  return withoutCluedIn ? null : DefaultSource;
};

const findSource = (property = 'CluedIn', connectors = [], withoutCluedIn) => {
  const splittedProperties = property.split('.');
  const supposedToBeconnectorName = splittedProperties[0];

  if (isDataSet(supposedToBeconnectorName)) {
    return {
      Name: supposedToBeconnectorName,
    };
  }

  const propertyToLook =
    splittedProperties.length > 1
      ? formatName(supposedToBeconnectorName)
      : formatName(property);

  return findSourceInConfig(propertyToLook, connectors, withoutCluedIn);
};

export const uniqProviderBasedOnUrisAndProviderCodes = (
  uris = [],
  providers = [],
  connectors = [],
) => {
  const result = [];

  const urisFiltered = (uris || [])
    .filter((uri) => uri.origin || uri.Origin)
    .map((uri) => ({
      ...uri,
      origin: uri.origin || uri.Origin,
    }));

  const compactedUris = urisFiltered || [];
  const compactedUrisWithoutCluedinProcessors = compactedUris.filter(
    (uri) => !isCluedInProcessor(uri.origin),
  );

  compactedUrisWithoutCluedinProcessors.forEach((uri) => {
    result.push({
      name: uri.origin,
      uri: uri.uri || uri.Uri,
      config: findSource(uri.origin, connectors, true),
    });
  });

  uniq(providers)
    .filter((providerCode) => {
      const providerConfigFromProviderCode = findSource(
        providerCode,
        connectors,
        true,
      );
      const isFound = compactedUrisWithoutCluedinProcessors.find((uri) => {
        if (uri.origin.toLowerCase() === providerCode.toLowerCase()) {
          return true;
        }

        const currentProviderFromUri = findSource(uri.origin, connectors, true);
        if (currentProviderFromUri && providerConfigFromProviderCode) {
          return (
            currentProviderFromUri.Name === providerConfigFromProviderCode.Name
          );
        }

        return false;
      });

      return !isFound;
    })
    .forEach((providerCode) => {
      const providerConfigFromProviderCode = findSource(
        providerCode,
        connectors,
        true,
      );

      result.push({
        name: providerCode,
        config: providerConfigFromProviderCode || null,
        uri: '',
      });
    });

  return result;
};
