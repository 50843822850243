import { FormattedMessage } from '@cluedin/locale';
import routes from './module-routes';

export const manualDataEntryRootUrl = '/admin/inbound/manual-data-entry/detail';

export default {
  pillar: 'inbound',
  name: 'Manual Data Entry',
  displayName: <FormattedMessage id="module-manualDataEntry-displayName" />,
  path: '/manual-data-entry',
  featureFlag: 'manualDataEntry',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Access',
        name: <FormattedMessage id="module-manualDataEntry-displayName" />,
        link: '/admin/inbound/manual-data-entry',
      },
    ],
  },
  routes,
};
