"use strict";

exports.__esModule = true;
exports.default = void 0;
var _refreshToken = _interopRequireDefault(require("./refreshToken"));
var _merge = _interopRequireDefault(require("lodash/merge"));
var _token = require("../token");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var fetchWithToken = function fetchWithToken(url, options) {
  if (options === void 0) {
    options = {};
  }
  var bearer = options.token || (0, _token.getCurrentToken)();
  var AuthorizationValue = bearer ? "Bearer " + bearer : null;
  var forgeryToken = (0, _token.getAntiForgeryToken)();
  var forgeryTokenHeaders = forgeryToken ? {
    RequestVerificationToken: forgeryToken
  } : {};
  var optionsWithToken = options;
  if (AuthorizationValue != null) {
    optionsWithToken = (0, _merge.default)({}, options, {
      headers: _extends({
        Authorization: "Bearer " + bearer
      }, forgeryTokenHeaders)
    });
  }
  return fetch("" + url, optionsWithToken);
};
var refreshFetch = (0, _refreshToken.default)({
  // Pass fetch function you want to wrap, it should already be adding
  // token to the request
  fetch: fetchWithToken,
  // shouldRefreshToken is called when API fetch fails and it should decide
  // whether the response error means we need to refresh token
  shouldRefreshToken: function shouldRefreshToken(resp) {
    return resp.status === 401;
  },
  // refreshToken should call the refresh token API, save the refreshed
  // token and return promise -- resolving it when everything goes fine,
  // rejecting it when refreshing fails for some reason
  refreshToken: function refreshToken() {
    return (0, _token.getNewToken)();
  }
});
var _default = refreshFetch;
exports.default = _default;