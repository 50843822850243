import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';

import EntityPropertiesImpactedKey from '../EntityPropertiesImpactedKey/EntityPropertiesImpactedKey';
import {
  EntityPropertiesImpactedKeyListBox,
  EntityPropertiesImpactedKeyListWrapper,
  EntityPropertiesImpactedKeyListTitle,
  EntityPropertiesImpactedKeyListSourceWrapper,
} from './EntityPropertiesImpactedKeyList.styles';

const EntityPropertiesImpactedKeyList = ({ data, isSupported, title }) => {
  const [isExpanded, setIsExpanded] = useState(isSupported ? true : false);

  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <EntityPropertiesImpactedKeyListBox>
      <EntityPropertiesImpactedKeyListWrapper isExpanded={isExpanded}>
        <EntityPropertiesImpactedKeyListTitle
          data-test="entityProperties-editPanel-supportingMesh"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <Arrowdown color={themePrimary} size={16} />
          ) : (
            <Arrowright color={themePrimary} size={16} />
          )}

          <div>{title}</div>
        </EntityPropertiesImpactedKeyListTitle>
      </EntityPropertiesImpactedKeyListWrapper>

      <EntityPropertiesImpactedKeyListSourceWrapper isExpanded={isExpanded}>
        {data ? (
          <ul>
            {data &&
              (data || []).map((keyItem) => (
                <EntityPropertiesImpactedKey
                  key={keyItem?.key}
                  keyToShow={keyItem?.key}
                  displayName={keyItem?.displayName}
                />
              ))}
          </ul>
        ) : (
          <span />
        )}
      </EntityPropertiesImpactedKeyListSourceWrapper>
    </EntityPropertiesImpactedKeyListBox>
  );
};

export default EntityPropertiesImpactedKeyList;

EntityPropertiesImpactedKeyList.propTypes = {
  data: PropTypes.array,
  isSupported: PropTypes.bool,
  title: PropTypes.string,
};

EntityPropertiesImpactedKeyList.defaultProps = {
  data: [],
  isSupported: null,
  title: '',
};
