import { url } from '../../../config';
import { getSubdomain } from '../../../modules/core/helpers/url';
import { useQueryMe } from '../../hooks/useQueryMe';

export const useApp = () => {
  const domain = getSubdomain();

  const { me, loadingMe } = useQueryMe(domain === url.appSubdomain);

  return {
    me,
    loadingMe,
  };
};
