import { handleActions } from 'redux-actions';
import {
  fetchIntegrationByIdAction,
  fetchAllIntegrationsActions,
  showInviteAction,
  hideInviteAction,
  inviteUserToIntegrationAction,
  shouldAddActiveProviderActions,
  shouldEditActiveProviderActions,
  shouldAddOauthActiveProviderActions,
  shouldEnableProviderActions,
  fetchAllIntegrationsActionsWithNoFiltersActions,
  shouldEnableProviderReset,
} from './actions';

const initialState = {
  isFetchingSelectedIntegration: false,
  invalidSelectedIntegration: false,
  selectedIntegration: null,
  allIntegrations: [],
  isFetchingAllIntegrations: false,
  invalidAllIntegrations: false,
  showInvite: false,
  selectedIntegrationForInvite: null,
  inviteUsersPerIntegration: {},
  addConfiguration: {},
  editConfiguration: {},
  enableConfiguration: {},
  allIntegrationsWithNoFilters: [],
  isFetchingAllIntegrationsNoFilters: false,
  invalidAllIntegrationsNoFilters: false,
};

export default handleActions(
  {
    [shouldEnableProviderActions.request]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      enableConfiguration: {
        ...state.enableConfiguration,
        [configurationId]: {
          invalid: false,
          isSaving: true,
          done: false,
          queued: false,
        },
      },
    }),
    [shouldEnableProviderActions.receive]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      enableConfiguration: {
        ...state.enableConfiguration,
        [configurationId]: {
          invalid: false,
          isSaving: false,
          done: true,
          queued: false,
        },
      },
    }),
    [shouldEnableProviderActions.queued]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      enableConfiguration: {
        ...state.enableConfiguration,
        [configurationId]: {
          invalid: false,
          isSaving: false,
          done: false,
          queued: true,
        },
      },
    }),
    [shouldEnableProviderActions.invalid]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      enableConfiguration: {
        ...state.enableConfiguration,
        [configurationId]: {
          invalid: true,
          isSaving: false,
          done: false,
          queued: false,
        },
      },
    }),
    [shouldEditActiveProviderActions.request]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      editConfiguration: {
        ...state.editConfiguration,
        [configurationId]: {
          invalid: false,
          isSaving: true,
          done: false,
          queued: false,
        },
      },
    }),
    [shouldEditActiveProviderActions.receive]: (
      state,
      { payload: { configurationId, result } },
    ) => ({
      ...state,
      editConfiguration: {
        ...state.editConfiguration,
        [configurationId]: {
          invalid: false,
          isSaving: false,
          done: true,
          queued: false,
          activeConfiguration: result,
        },
      },
    }),
    [shouldEditActiveProviderActions.invalid]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      editConfiguration: {
        ...state.editConfiguration,
        [configurationId]: {
          invalid: true,
          isSaving: false,
          done: false,
          queued: false,
        },
      },
    }),
    [shouldEditActiveProviderActions.queued]: (
      state,
      { payload: { configurationId } },
    ) => ({
      ...state,
      editConfiguration: {
        ...state.editConfiguration,
        [configurationId]: {
          invalid: false,
          isSaving: false,
          done: false,
          queued: true,
        },
      },
    }),
    // No receive as it should redirect
    [shouldAddOauthActiveProviderActions.request]: (
      state,
      { payload: { integrationId } },
    ) => ({
      ...state,
      addConfiguration: {
        ...state.addConfiguration,
        [integrationId]: {
          invalid: false,
          isSaving: true,
          done: false,
          queued: false,
        },
      },
    }),
    [shouldAddOauthActiveProviderActions.invalid]: (
      state,
      { payload: { integrationId } },
    ) => ({
      ...state,
      addConfiguration: {
        ...state.addConfiguration,
        [integrationId]: {
          invalid: true,
          isSaving: false,
          done: false,
          queued: false,
        },
      },
    }),
    [shouldAddActiveProviderActions.request]: (
      state,
      { payload: { integrationId } },
    ) => ({
      ...state,
      addConfiguration: {
        ...state.addConfiguration,
        [integrationId]: {
          invalid: false,
          isSaving: true,
          done: false,
          queued: false,
          resp: null,
        },
      },
    }),
    [shouldAddActiveProviderActions.receive]: (
      state,
      {
        payload: {
          param: { integrationId },
          result,
        },
      },
    ) => ({
      ...state,
      addConfiguration: {
        ...state.addConfiguration,
        [integrationId]: {
          invalid: false,
          isSaving: false,
          done: true,
          queued: false,
          activeConfiguration: result,
          resp: null,
        },
      },
    }),
    [shouldAddActiveProviderActions.invalid]: (
      state,
      { payload: { params, resp } },
    ) => {
      const integrationId = params?.integrationId;

      return {
        ...state,
        addConfiguration: {
          ...state.addConfiguration,
          [integrationId]: {
            invalid: true,
            isSaving: false,
            done: false,
            queued: false,
            resp,
          },
        },
      };
    },
    [shouldAddActiveProviderActions.queued]: (
      state,
      { payload: { integrationId } },
    ) => ({
      ...state,
      addConfiguration: {
        ...state.addConfiguration,
        [integrationId]: {
          invalid: false,
          isSaving: false,
          done: false,
          queued: true,
          resp: null,
        },
      },
    }),
    [shouldEnableProviderReset]: (state, { payload }) => {
      delete state.enableConfiguration[payload?.configurationId];
      delete state.addConfiguration[payload?.integrationId];

      return {
        ...state,
      };
    },
    [fetchIntegrationByIdAction.request]: (state) => ({
      ...state,
      isFetchingSelectedIntegration: true,
      invalidSelectedIntegration: false,
      selectedIntegration: null,
    }),
    [fetchIntegrationByIdAction.receive]: (state, { payload }) => ({
      ...state,
      isFetchingSelectedIntegration: false,
      invalidSelectedIntegration: false,
      selectedIntegration: payload,
    }),
    [fetchIntegrationByIdAction.invalid]: (state) => ({
      ...state,
      isFetchingSelectedIntegration: false,
      invalidSelectedIntegration: true,
      selectedIntegration: null,
    }),
    [fetchAllIntegrationsActions.request]: (state) => ({
      ...state,
      isFetchingAllIntegrations: true,
      invalidAllIntegrations: false,
    }),
    [fetchAllIntegrationsActions.receive]: (state, { payload }) => ({
      ...state,
      allIntegrations: payload,
      isFetchingAllIntegrations: false,
      invalidAllIntegrations: false,
    }),
    [fetchAllIntegrationsActions.invalid]: (state) => ({
      ...state,
      allIntegrations: null,
      isFetchingAllIntegrations: false,
      invalidAllIntegrations: true,
    }),
    [fetchAllIntegrationsActionsWithNoFiltersActions.request]: (state) => ({
      ...state,
      isFetchingAllIntegrationsNoFilters: true,
      invalidAllIntegrationsNoFilters: false,
    }),
    [fetchAllIntegrationsActionsWithNoFiltersActions.receive]: (
      state,
      { payload },
    ) => ({
      ...state,
      allIntegrationsWithNoFilters: payload,
      isFetchingAllIntegrationsNoFilters: false,
      invalidAllIntegrationsNoFilters: false,
    }),
    [fetchAllIntegrationsActionsWithNoFiltersActions.invalid]: (state) => ({
      ...state,
      allIntegrationsWithNoFilters: null,
      isFetchingAllIntegrationsNoFilters: false,
      invalidAllIntegrationsNoFilters: true,
    }),
    [showInviteAction]: (state, { payload }) => ({
      ...state,
      showInvite: true,
      selectedIntegrationForInvite: payload,
    }),
    [hideInviteAction]: (state) => ({
      ...state,
      showInvite: false,
      selectedIntegrationForInvite: null,
    }),
    [inviteUserToIntegrationAction.request]: (
      state,
      { payload: { providerId } },
    ) => ({
      ...state,
      inviteUsersPerIntegration: {
        [providerId]: {
          isFetching: true,
          invalid: false,
        },
      },
    }),
    [inviteUserToIntegrationAction.receive]: (
      state,
      { payload: { providerId } },
    ) => ({
      ...state,
      showInvite: false,
      selectedIntegrationForInvite: false,
      inviteUsersPerIntegration: {
        [providerId]: {
          isFetching: false,
          invalid: false,
        },
      },
    }),
    [inviteUserToIntegrationAction.invalid]: (
      state,
      { payload: { providerId } },
    ) => ({
      ...state,
      inviteUsersPerIntegration: {
        [providerId]: {
          isFetching: false,
          invalid: false,
        },
      },
    }),
  },
  initialState,
);
