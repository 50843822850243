import { useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { IconButton } from '@fluentui/react/lib/Button';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { ButtonLink } from '@cluedin/atoms';
import { ProgressBar, withConfirmDialog } from '@cluedin/form';
import { Ellipsis } from '@cluedin/components';

import ErrorText from '../../../../core/components/composites/form/ErrorText';
import { FileUploadRequest } from '../../../types';
import {
  ToasterWrapper,
  ToasterInfoLine,
  ToasterFileInfo,
  ToasterFileProgress,
  ToasterActionLine,
  ToasterIconWrapper,
  FileIcon,
} from '../../composite/FileUploadProgress';

const IconButtonWithConfirm = withConfirmDialog(IconButton);

type FileUploadProgressLineProps = {
  fileUploadRequest: FileUploadRequest;
  closeToast: () => void;
  error: boolean | undefined;
  errorMessage: string | undefined;
  finishedWithDataSource: boolean;
  percentage?: number;
  pause: boolean;
  abortFileUpload: () => void;
  resumeFileUpload: () => void;
  cancelFileUpload: () => void;
  dataSourceId?: string;
  showClose: boolean;
};

export const FileUploadProgressLine = ({
  fileUploadRequest,
  closeToast,
  error,
  errorMessage,
  finishedWithDataSource,
  percentage,
  pause,
  abortFileUpload,
  resumeFileUpload,
  cancelFileUpload,
  dataSourceId,
  showClose = true,
}: FileUploadProgressLineProps) => {
  const history = useHistory();
  const theme = useTheme();

  const percentageRounded = Math.floor(percentage || 0);
  const percentageFormatted = percentageRounded > 100 ? 100 : percentageRounded;

  return (
    <ToasterWrapper showClose={showClose}>
      {showClose && finishedWithDataSource && (
        <div style={{ position: 'absolute', top: '-12px', right: '-12px' }}>
          <ToasterIconWrapper>
            <IconButton
              style={{
                width: '14px',
                height: '14px',
              }}
              data-test="pager-cancel-download"
              onClick={() => {
                closeToast();
              }}
              iconProps={{
                iconName: 'Cancel',
                style: {
                  height: 14,
                  fontSize: 14,
                  color: theme.palette.neutralPrimary,
                },
              }}
            />
          </ToasterIconWrapper>
        </div>
      )}
      <div>
        <Icon
          iconName="TextDocument"
          style={{
            fontSize: 24,
          }}
        />
      </div>
      <div style={{ paddingLeft: '12px', flex: 1 }}>
        <ToasterInfoLine>
          <ToasterFileInfo
            textColor={error && theme.semanticColors.errorText}
            style={{ maxWidth: 232 }}
          >
            <Ellipsis>{fileUploadRequest?.fileName}</Ellipsis>
          </ToasterFileInfo>
          <FileIcon>
            {error && !finishedWithDataSource && (
              <Icon
                iconName="OpenFile"
                style={{
                  height: 14,
                  fontSize: 14,
                  lineHeight: '14px',
                  color: theme.semanticColors.errorText,
                }}
              />
            )}
            {!error && !finishedWithDataSource && (
              <Icon
                iconName="OpenFile"
                style={{
                  height: 14,
                  fontSize: 14,
                  lineHeight: '14px',
                  color: theme.palette.neutralPrimary,
                }}
              />
            )}
            {!error && finishedWithDataSource && (
              <Icon
                iconName="CheckMark"
                style={{
                  height: 12,
                  fontSize: 12,
                  lineHeight: '14px',
                  backgroundColor: theme.palette.themePrimary,
                  color: '#fff',
                  borderRadius: '14px',
                  padding: '2px',
                  display: 'flex',
                }}
              />
            )}
          </FileIcon>
        </ToasterInfoLine>
        {!finishedWithDataSource && !error && (
          <ToasterActionLine>
            <ToasterFileProgress>
              <span
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                }}
              >
                {percentageFormatted}%
              </span>
              <ProgressBar
                min={0}
                max={100}
                value={percentageFormatted}
                labelPosition="top"
              />
            </ToasterFileProgress>
            <ToasterIconWrapper>
              {!pause && (
                <IconButton
                  data-test="pager-pause-download"
                  onClick={abortFileUpload}
                  iconProps={{
                    iconName: 'Pause',
                    style: {
                      height: 14,
                      fontSize: 14,
                      lineHeight: '14px',
                      color: theme.palette.neutralPrimary,
                    },
                  }}
                />
              )}
              {pause && (
                <IconButton
                  data-test="pager-pause-play"
                  onClick={resumeFileUpload}
                  iconProps={{
                    iconName: 'Play',
                    style: {
                      height: 14,
                      fontSize: 14,
                      lineHeight: '14px',
                      color: theme.palette.neutralPrimary,
                    },
                  }}
                />
              )}
              <IconButtonWithConfirm
                data-test="pager-cancel-download"
                onConfirm={cancelFileUpload}
                confirmTitle={
                  <FormattedMessage id="module-fileUpload-confirmCancelTitle" />
                }
                confirmMessage={
                  <FormattedMessage id="module-fileUpload-confirmMessageTitle" />
                }
                iconProps={{
                  iconName: 'Cancel',
                  style: {
                    height: 14,
                    fontSize: 14,
                    marginBottom: 4,
                    lineHeight: '14px',
                    color: theme.palette.neutralPrimary,
                  },
                }}
              />
            </ToasterIconWrapper>
          </ToasterActionLine>
        )}
        {error && errorMessage && (
          <ToasterActionLine>
            <ErrorText>{errorMessage}</ErrorText>
          </ToasterActionLine>
        )}
        {finishedWithDataSource && !error && (
          <ToasterActionLine>
            <div style={{ flex: 1 }}>
              <FormattedMessage id="module-fileUpload-uploadCompleted" />
            </div>
            <div>
              <ButtonLink
                onClick={() => {
                  history.push(
                    `/admin/inbound/datasourceset/datasource/${dataSourceId}`,
                  );
                  closeToast();
                }}
                text={<FormattedMessage id="module-fileUpload-viewFile" />}
              />
            </div>
          </ToasterActionLine>
        )}
      </div>
    </ToasterWrapper>
  );
};
