import React, { Fragment } from 'react';
import { WidgetRegistryContext } from './WidgetRegistry';
import { withGqlQuery } from '../data/withGqlQuery';

const Widget = ({ Component, match, widgetProps, ...rest }) => {
  return (
    <Fragment>
      <Component match={match} {...widgetProps} {...rest} />
    </Fragment>
  );
};

const WidgetWithGQlIfThereIs = withGqlQuery(Widget);

const WidgetRender = ({ widget, match, ...rest }) => {
  return (
    <WidgetRegistryContext.Consumer>
      {({ widgets }) => {
        if (widget.Component) {
          const CustomComponent = widget.Component;
          return (
            <Fragment>
              <CustomComponent {...widget} {...rest} match={match} />
            </Fragment>
          );
        }

        const w = widgets.find((wi) => wi.type === widget.type);
        if (w) {
          return (
            <Fragment>
              <WidgetWithGQlIfThereIs
                {...w}
                {...rest}
                match={match}
                widgetProps={widget}
              />
            </Fragment>
          );
        }

        return <span style={{ fontStyle: 'italic' }}>Widget Not Found</span>;
      }}
    </WidgetRegistryContext.Consumer>
  );
};

export default WidgetRender;
