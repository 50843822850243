import styled from 'styled-components';

export const RootEntityHeaderIconWrapper = styled.div<{
  themePrimary: string;
}>`
  align-items: center;
  background: ${({ themePrimary }) => themePrimary ?? '#29A29A'};
  border-radius: 80%;
  display: flex;
  height: 64px;
  max-height: 64px;
  min-height: 64px;
  justify-content: center;
  width: 64px;
  max-width: 64px;
  min-width: 64px;
`;
