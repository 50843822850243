import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const CREATE_HIERARCHY = gql`
  mutation createHierarchy($hierarchy: InputCreateHierarchy!) {
    management {
      id
      createHierarchy(hierarchy: $hierarchy) {
        id
      }
    }
  }
`;

export const useCreateHierarchy = (noCaching = false) => {
  const [createHierarchy, { data, loading, error }] =
    useMutation(CREATE_HIERARCHY);

  return [
    (hierarchy) => createHierarchy({ variables: { hierarchy } }),
    {
      data,
      loading,
      error,
    },
  ];
};
