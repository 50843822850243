"use strict";

exports.__esModule = true;
exports.actionsConfiguration = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _admin = require("./admin");
var _consume = require("./consume");
var _engineRoom = require("./engineRoom");
var _governance = require("./governance");
var _integrations = require("./integrations");
var _management = require("./management");
var _preparation = require("./preparation");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var cluedInExtension = (window || {}).cluedInExtensionUI || {};
var actionsConfiguration = _lodash.default.merge({
  admin: _admin.admin,
  consume: _consume.consume,
  engineRoom: _engineRoom.engineRoom,
  governance: _governance.governance,
  integrations: _integrations.integrations,
  management: _management.management,
  preparation: _preparation.preparation
}, cluedInExtension.actionsConfiguration || {});
exports.actionsConfiguration = actionsConfiguration;