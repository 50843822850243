import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Flex, SimpleGrid } from 'uxi/Layout';
import OrganizationListItem from './OrganizationListItem';
import OrganizationListItemLoading from './OrganizationListItemLoading';
import EntitySelectableListItemWrapper from '../../../../../../entityMerge/components/containers/EntitySelectableListItemWrapper';

const OrganizationList = ({ data = [], isFetchingMainSearch }) =>
  !isFetchingMainSearch && data.length === 0 ? (
    <Flex
      style={{ margin: '96px auto', textAlign: 'center', padding: '0 16px' }}
    >
      <FormattedMessage
        id="SearchContent.NoSearchResult"
        defaultMessage="No results found for the current query"
      />
    </Flex>
  ) : (
    <SimpleGrid
      gap={16}
      itemWidth={280}
      itemHeight={172}
      style={{ margin: '16px' }}
    >
      {isFetchingMainSearch
        ? [...Array(30)].map((_, i) => <OrganizationListItemLoading key={i} />)
        : data.map((org) => (
            <EntitySelectableListItemWrapper
              key={org.entityId}
              mergeViewModel={{
                ...org,
                entityType: '/Organization',
              }}
              wrapperStyle={{ borderRadius: '3px', overflow: 'hidden' }}
            >
              <OrganizationListItem
                organization={org}
                isFetchingMainSearch={isFetchingMainSearch}
              />
            </EntitySelectableListItemWrapper>
          ))}
    </SimpleGrid>
  );

OrganizationList.displayName = 'OrganizationList';

export default OrganizationList;
