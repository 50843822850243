"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var NotificationIconWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  border-radius: 24px;\n  height: 24px;\n  width: 24px;\n  background-color: ", ";\n  display: flex;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n"])), function (_ref) {
  var alertType = _ref.alertType,
    themePrimary = _ref.themePrimary;
  if (alertType === 'danger') {
    return '#d13f48';
  }
  if (alertType === 'info') {
    return '#3e53c1';
  }
  if (alertType === 'warning') {
    return '#ff9800';
  }
  return themePrimary;
});
var _default = NotificationIconWrapper;
exports.default = _default;