import React, { useState, useEffect } from 'react';
import { Switch } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';

import PropTypes from 'prop-types';

import { EntityPropertiesBooleanInputWrapper } from './EntityPropertiesBooleanInput.styles';

const EntityPropertiesBooleanInput = ({
  name,
  setEntityInputValue,
  vocabularyValue,
}) => {
  let checkedValue;
  if (vocabularyValue === 'true') {
    checkedValue = true;
  }
  if (vocabularyValue === 'false') {
    checkedValue = false;
  }

  const [switchStatus, setSwitchStatus] = useState(checkedValue);

  useEffect(() => {
    if (switchStatus === true) {
      setEntityInputValue('true');
    }
    if (switchStatus === false) {
      setEntityInputValue('false');
    }
  }, [switchStatus, setEntityInputValue]);

  return (
    <EntityPropertiesBooleanInputWrapper>
      <Switch
        data-test="entity_entityProperties_editSwitch"
        checked={switchStatus}
        onChange={() => {
          setSwitchStatus(!switchStatus);
        }}
        name={name}
      />

      <div>
        {switchStatus ? (
          <FormattedMessage id="module-entityProperties-panelEditPropertyEnabled" />
        ) : (
          <FormattedMessage id="module-entityProperties-panelEditPropertyDisabled" />
        )}
      </div>
    </EntityPropertiesBooleanInputWrapper>
  );
};

export default EntityPropertiesBooleanInput;

EntityPropertiesBooleanInput.propTypes = {
  name: PropTypes.string,
  setEntityInputValue: PropTypes.func,
  vocabularyValue: PropTypes.string,
};

EntityPropertiesBooleanInput.defaultProps = {
  name: null,
  setEntityInputValue: () => {},
  vocabularyValue: null,
};
