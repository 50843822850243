import React from 'react';
import EntityIconToolTipText from './EntityIconTooltipText';

const EntityIconToolTip = ({ item, isConfigured }) => {
  return (
    <EntityIconToolTipText isConfigured={isConfigured} item={item}>
      {item && item.icon ? React.createElement(item.icon) : <span />}
    </EntityIconToolTipText>
  );
};

export default EntityIconToolTip;
