import styled from 'styled-components';

export const EntityTopologySplitPanelListWarningMessageWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EntityTopologySplitPanelListWarningMessageHeading = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`;
