import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';

const GET_HISTORY_BY_ID = gql`
  query getHistoryById($id: ID!) {
    virtualization {
      entityHistoryById(id: $id) {
        versionHistory
      }
    }
  }
`;

export const useGetHistoryById = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_HISTORY_BY_ID, {
    skip: !id,
    variables: { id },
  });

  const result = get(
    data,
    'virtualization.entityHistoryById.versionHistory.version',
  );

  return [
    {
      data: result,
      loading,
      error,
      refetch,
      success: !!result,
    },
  ];
};

export const useHistoryChanges = (entityId) => {
  const [historyData, historyLoading, historyError] =
    useGetHistoryById(entityId);
  const historyChanges = get(
    historyData,
    'virtualization.entityHistoryById.versionHistory.version',
  );

  const historyChangesToShowTest = (historyChanges || []).map((historyItem) => {
    const dataReference = historyItem?.version?.['attribute-dataReference'];
    const date = historyItem?.version?.['attribute-date'];
    let changes = Array.isArray(historyItem?.version?.change)
      ? historyItem?.version?.change
      : [{ change: historyItem?.version?.change }];

    const newChanges = (changes || []).map((c) => {
      return { ...c, dataReference, date };
    });

    return newChanges;
  });

  const filteredHistoryChanges = historyChangesToShowTest
    ? (historyChangesToShowTest || []).map((historyItem) => {
        const historyItems = historyItem?.filter(
          (changeItem) =>
            changeItem?.change['attribute-changedPart'] === 'Property',
        );
        const modifiedAtItems = historyItem?.filter(
          (changeItem) =>
            changeItem?.change['attribute-key'] === 'ModifiedDate',
        );

        return [...historyItems, ...modifiedAtItems];
      })
    : historyChangesToShowTest;

  return [historyChanges, filteredHistoryChanges];
};
