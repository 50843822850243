import React from 'react';
import styled from 'styled-components';

const TabUL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  ${({ isMainStyle }) => {
    return isMainStyle
      ? `
        border-top: 1px solid #D4DAD1;
        border-bottom: 1px solid #D4DAD1;
        list-style: none;
    `
      : ``;
  }}
`;

const LightTabList = ({ isMainStyle, children, ...rest }) => {
  return (
    <TabUL isMainStyle={isMainStyle} role="tablist" {...rest}>
      {children}
    </TabUL>
  );
};

export default LightTabList;
