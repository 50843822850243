"use strict";

exports.__esModule = true;
exports.HistoryChangeSetWrapper = exports.HistoryChangeSetSourceWrapper = exports.HistoryChangeSetDate = exports.HistoryChangeSetBox = exports.HistoryChangeRecordCount = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var HistoryChangeSetBox = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 100%;\n  position: relative;\n"])));
exports.HistoryChangeSetBox = HistoryChangeSetBox;
var HistoryChangeSetWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  background: ", ";\n  border: 1px solid #ececec;\n  border-top: 0;\n  display: flex;\n  height: 100%;\n  justify-content: space-between;\n  padding: 14px 30px;\n  transition: all 100ms ease;\n\n  &:hover {\n    background: ", ";\n  }\n\n  & svg {\n    margin-right: 10px;\n  }\n"])), function (_ref) {
  var isExpanded = _ref.isExpanded,
    theme = _ref.theme;
  return isExpanded ? theme.palette.themeLighterAlt : '#ffffff';
}, function (_ref2) {
  var isExpanded = _ref2.isExpanded,
    theme = _ref2.theme;
  return isExpanded ? theme.palette.themeLighter : '#fafafa';
});
exports.HistoryChangeSetWrapper = HistoryChangeSetWrapper;
var HistoryChangeSetDate = _styledComponents.default.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  color: #000000;\n  cursor: pointer;\n  display: flex;\n  font-size: 16px;\n  font-weight: 600;\n  justify-content: flex-start;\n"])));
exports.HistoryChangeSetDate = HistoryChangeSetDate;
var HistoryChangeSetSourceWrapper = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  height: 100%;\n  transition: all 100ms ease;\n\n  ", "\n"])), function (_ref3) {
  var isExpanded = _ref3.isExpanded;
  switch (false) {
    case isExpanded:
      return (0, _styledComponents.css)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n          display: none;\n          opacity: 0;\n          visibility: hidden;\n        "])));
    default:
      return;
  }
});
exports.HistoryChangeSetSourceWrapper = HistoryChangeSetSourceWrapper;
var HistoryChangeRecordCount = _styledComponents.default.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  font-size: 14px;\n  font-weight: 400;\n"])));
exports.HistoryChangeRecordCount = HistoryChangeRecordCount;