import React from 'react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

const getDataSetById = gql`
  query getDataSetById($id: ID!) {
    inbound {
      dataSet(id: $id) {
        id
        name
        annotationId
        dataSourceId
        canBeDeleted
        configuration
        dataSource {
          id
          name
          type
          dataSourceSet {
            id
            name
          }
        }
        originalFields
        stats {
          total
          successful
          failed
          bytes
        }
        fieldMappings {
          originalField
          key
          edges {
            edgeType
            dataSetId
          }
        }
      }
    }
  }
`;

export const withDataSet = (Comp) => (props) => {
  return (
    <Query
      query={getDataSetById}
      variables={{ id: props.id || props.dataSetId }}
      fetchPolicy="no-cache" // check whey we need to disable cache to get the properties update
    >
      {({ loading, error, data = {}, refetch }) => {
        let dataSet = null;
        if (
          !loading &&
          !error &&
          data &&
          data.inbound &&
          data.inbound.dataSet
        ) {
          dataSet = data.inbound.dataSet;
        }

        return (
          <Comp
            {...props}
            dataSetId={props.id}
            isFetching={loading}
            dataSetError={error}
            dataSet={dataSet}
            refetchDataset={() => {
              refetch({
                id: props.id,
              });
            }}
          />
        );
      }}
    </Query>
  );
};

export default {
  withDataSet,
};
