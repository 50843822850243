import { FC } from 'react';
import { TextPlaceholder } from '@cluedin/components';

import { useQueryConfiguredConnectorById } from '../../../modules/connector/hooks/useQueryConfiguredConnectorById';

export const ProviderNameById: FC<{ id: string }> = ({ id }) => {
  const [connector, loadingConnector, error] =
    useQueryConfiguredConnectorById(id);

  if (loadingConnector) {
    return <TextPlaceholder width={'120px'} />;
  }

  if (!connector || error) {
    return <>{id}</>;
  }

  return <>{connector?.accountDisplay || connector?.name}</>;
};
