import React from 'react';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { shoudFetchPublicOrgInfo } from '../../../public/actions/public';
import { clientId } from '../../../../config';
import OrgClientIdNotFoundComponent from './OrgClientIdNotFoundComponent';
import OrgClientIdInvalidComponent from './OrgClientIdInvalidComponent';
import PageLoader from '../../../core/components/composites/PageLoader';

const DefaultComponent = ({ children }) => (
  <div style={{ height: '100%' }}>{children}</div>
);

const DefaultComponentEnchanced = compose(
  lifecycle({
    componentDidMount() {
      const { fetchTeamDomain } = this.props;
      if (fetchTeamDomain) {
        fetchTeamDomain();
      }
    },
  }),
  branch((props) => props.isFetching === true, renderComponent(PageLoader)),
  branch(
    (props) => props.invalid === true,
    renderComponent(OrgClientIdInvalidComponent),
  ),
  branch(
    (props) => props.isAvailable === true,
    renderComponent(OrgClientIdNotFoundComponent),
  ),
)(DefaultComponent);

const mapStateToProps = (
  { publicModule: { isFetching, result, invalid } },
  { location },
) => {
  const { pathname = '' } = location;

  return {
    isFetching,
    teamDomain: result,
    invalid,
    isAvailable: pathname !== '/404' && result && result.isAvailable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch(push(url)),
  fetchTeamDomain: () => {
    if (clientId) {
      dispatch(shoudFetchPublicOrgInfo(clientId));
    }
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultComponentEnchanced),
);
