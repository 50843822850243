import { FormattedMessage } from '@cluedin/locale';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
} from '@cluedin/list-pattern';
import Alert from 'uxi/Alert';
import moment from 'moment';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import ConfigurationOverviewStatus from './ConfigurationOverviewStatus';
import ConfigurationOverviewActions from './ConfigurationOverviewActions';
import ProductOwner from '../containers/ProductOwner';

const ConfigurationOverviewComposite = ({
  configuration,
  play,
  pause,
  approve,
  reject,
  reAuth,
  reProcess,
  viewSetting,
  viewPermissions,
  isLoadingPlay,
  isLoadingPause,
  isLoadingApprove,
  isLoadingReject,
  currentUser,
  setProductOwner,
}) => {
  const hasViewConfiguredIntegrations = useUserHasAccess(
    actionsConfiguration.integrations.configuredIntegrations
      .configuredIntegration.view.claims,
  );

  const accountName =
    configuration.AccountDisplay ||
    configuration.Name ||
    configuration.AccountId;
  const createdDate = moment(configuration.CreatedDate).fromNow();
  const nextExecution =
    configuration && configuration.Status && configuration.Status.NextExecution
      ? configuration.Status.NextExecution
      : null;
  const lastCrawlFinishTime =
    configuration &&
    configuration.Configuration &&
    configuration.Configuration.LastCrawlFinishTime
      ? configuration.Configuration.LastCrawlFinishTime
      : null;
  const isDeleted = configuration.isDeleted;
  const isOwner = currentUser && currentUser.client.Id === configuration.UserId;

  if (isDeleted) {
    return (
      <div style={{ padding: '15px' }}>
        <Alert>Account has been rejected and deleted</Alert>
      </div>
    );
  }

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableRowColumn>
              <strong>
                <FormattedMessage id="module-configuration-account" />
              </strong>
            </TableRowColumn>
            <TableRowColumn
              style={{ textAlign: 'right', paddingRight: '15px' }}
            >
              {accountName}
            </TableRowColumn>
          </TableRow>
          <TableRow>
            <TableRowColumn>
              <strong>
                <FormattedMessage id="module-configuration-author" />
              </strong>
            </TableRowColumn>
            <TableRowColumn
              style={{ textAlign: 'right', paddingRight: '15px' }}
            >
              {configuration.UserName}
            </TableRowColumn>
          </TableRow>
          <TableRow>
            <TableRowColumn>
              <strong>
                <FormattedMessage id="module-configuration-creationDate" />
              </strong>
            </TableRowColumn>
            <TableRowColumn
              style={{ textAlign: 'right', paddingRight: '15px' }}
            >
              {createdDate}
            </TableRowColumn>
          </TableRow>

          {hasViewConfiguredIntegrations ? (
            <TableRow>
              <TableRowColumn>
                <strong>
                  <FormattedMessage id="module-configuration-Owner" />
                </strong>
              </TableRowColumn>

              <TableRowColumn
                style={{ textAlign: 'right', paddingRight: '15px' }}
              >
                <ProductOwner
                  configuration={configuration}
                  setProductOwner={setProductOwner}
                />
              </TableRowColumn>
            </TableRow>
          ) : null}
          {nextExecution ? (
            <TableRow>
              <TableRowColumn>
                <strong>
                  <FormattedMessage id="module-configuration-nextExecution" />
                </strong>
              </TableRowColumn>
              <TableRowColumn
                style={{ textAlign: 'right', paddingRight: '15px' }}
              >
                {moment(nextExecution).fromNow()}
              </TableRowColumn>
            </TableRow>
          ) : null}
          {lastCrawlFinishTime ? (
            <TableRow>
              <TableRowColumn>
                <strong>
                  <FormattedMessage id="module-configuration-lastExecution" />
                </strong>
              </TableRowColumn>
              <TableRowColumn
                style={{ textAlign: 'right', paddingRight: '15px' }}
              >
                {moment(lastCrawlFinishTime).fromNow()}
              </TableRowColumn>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>

      <ConfigurationOverviewStatus configuration={configuration} />

      <ConfigurationOverviewActions
        play={play}
        pause={pause}
        approve={approve}
        reject={reject}
        reAuth={reAuth}
        reProcess={reProcess}
        viewSetting={viewSetting}
        viewPermissions={viewPermissions}
        configuration={configuration}
        isLoadingPlay={isLoadingPlay}
        isLoadingPause={isLoadingPause}
        isLoadingApprove={isLoadingApprove}
        isLoadingReject={isLoadingReject}
        isOwner={isOwner}
        setProductOwner={setProductOwner}
      />
    </div>
  );
};

export default ConfigurationOverviewComposite;
