import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Link } from 'react-router-dom';
import { Search } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';

const NewSearchNotification = ({ index, searchNotification, onCloseClick }) => {
  const title = `We have found ${searchNotification.count} new information`;
  const message = (
    <div>
      For the search:&nbsp;
      <Link to={`/search/${searchNotification.query}`}>
        {searchNotification.query}
      </Link>
    </div>
  );

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={Search}
    />
  );
};

const EnhancedNewSearchNotification = compose(
  lifecycle({
    componentDidMount() {
      const { searchNotification } = this.props;
      const title = `We have found ${searchNotification.count} new information`;
      const message = `For ${searchNotification.query}`;
      const url = `/search/${searchNotification.query}`;

      notify(title, { body: message }, url);
    },
  }),
)(NewSearchNotification);

export default EnhancedNewSearchNotification;
