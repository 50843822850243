import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CluedInCIconLogo, CluedInTextLogo } from '@cluedin/svgs';
import { useTheme } from '@cluedin/theme';

import { AppSearch } from '../../../AppSearch';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';
import MainSearch from './MainSearch';

const HeaderElement = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderMain = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-height: 100%;
`;

const HeaderLogoLink = styled(Link)`
  max-height: 48px;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
  width: ${({ isMinimized }) => (isMinimized ? '68px' : '216px')};
  transition: ${({ theme: { transition } }) => transition.default};
  padding: ${({ isMinimized }) =>
    isMinimized ? '16px 0 16px 18px' : '16px 30px'};
`;

const Header = ({ height, userMenu, isMinimized }) => {
  const isOldSearchEnabled = useOrganizationFeatureFlagOption('OldSearch');

  const {
    header: { backgroundColor: background },
  } = useTheme();

  return (
    <HeaderElement style={{ background, height }}>
      <HeaderMain>
        <HeaderLogoLink to="/home/" isMinimized={isMinimized}>
          <CluedInCIconLogo
            style={{
              height: '28px',
              width: '28px',
              marginRight: '8px',
            }}
          />

          {!isMinimized && <CluedInTextLogo style={{ width: '52px' }} />}
        </HeaderLogoLink>

        {isOldSearchEnabled ? <MainSearch /> : <AppSearch />}
      </HeaderMain>

      {userMenu}
    </HeaderElement>
  );
};

Header.propTypes = {
  height: PropTypes.string.isRequired,
  userMenu: PropTypes.node.isRequired,
  isMinimized: PropTypes.bool.isRequired,
};

export default Header;
