import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'uxi/Dialog';
import { PanelHeader, PanelContent } from 'uxi/Panel';

const fullScreenStyle = {
  width: '100%',
  height: '100vh',
  border: 'none',
  position: 'fixed',
  maxWidth: '100%',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const FullScreenDialog = ({ onClose, open, url, name }) => {
  return (
    <Dialog
      className="cluedin_dialog_fullScreen"
      show={open}
      style={fullScreenStyle}
      onClose={onClose}
    >
      <PanelHeader title={name} hasClose />
      <PanelContent>
        <iframe
          title="Full screen"
          style={{
            height: 'calc(100vh - (2 * 15px))',
            width: '100vw',
          }}
          src={url}
        />
      </PanelContent>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FullScreenDialog;
