import groupBy from 'lodash/groupBy';
import PersonList from './viewModels/components/composites/person/PersonList';
import OrganizationList from './viewModels/components/composites/organization/OrganizationList';
import SearchResultList from './components/containers/SearchResultList';

export const getSearchStrategy = (pathname) => {
  if (pathname.indexOf('/search/person') > -1) {
    return {
      type: 'person',
      component: PersonList,
      entityTypes: ['/Person'], // TODO switch to pool
    };
  } else if (pathname.indexOf('/search/organization') > -1) {
    return {
      type: 'organization',
      component: OrganizationList,
      entityTypes: ['/Organization'], // TODO switch to pool
    };
  } else if (pathname.indexOf('/search/document') > -1) {
    return {
      type: 'document',
      component: SearchResultList,
      entityTypes: ['/Documents'], // TODO switch to pool
    };
  }

  return {
    type: 'searchResult',
    component: SearchResultList,
    entityTypes: null,
  };
};

export const createEntitySearchId = (entityTypes = []) =>
  `entity-${entityTypes.join('-')}`;

export const filterIdChar = '$';
export const encodeFilter = (filters = []) => {
  if (!filters || filters.length === 0) {
    return null;
  }

  const filtersGoupedByType = groupBy(filters, (f) => f.type);
  const filterResult = [];
  Object.keys(filtersGoupedByType).forEach((key) => {
    filterResult.push(
      (filtersGoupedByType[key] || [])
        .map(
          (f) =>
            `${f.type}:${f.value}|${
              f.id ? `${f.id}${filterIdChar}` : ''
            }${encodeURIComponent(f.displayName || 'name')}`,
        )
        .join('_OR_'),
    );
  });

  const queryString = filterResult.join('|AND|');

  return `filters=${queryString}`;
};

export const encodeSearch = (q = '*', filters = [], sortType) => {
  let result = `?q=${q}`;

  const filter = encodeFilter(filters);
  const filterValue = filter || '';

  if (filterValue) {
    result = `${result}&${filterValue}`;
  }

  if (sortType) {
    result = `${result}&sort=${sortType}`;
  }

  return result || '';
};

export const decodeSort = (sort) => sort;

export const decodeFilters = (filterString) => {
  if (!filterString) {
    return [];
  }

  const allFilterByType = filterString.split('|AND|');
  const filters = [];

  allFilterByType.forEach((fByType) => {
    const splitBySameType = fByType.split('_OR_');

    (splitBySameType || []).forEach((f) => {
      const entitySplit = f.split(':');

      const index = f.indexOf(':');
      const filterKey = f.substring(0, index);
      const filterValue = f.substring(index + 1);

      const [value, displayNameAndIdMaybe] = filterValue.split('|');
      let id = null;
      let displayName = displayNameAndIdMaybe;
      if (displayNameAndIdMaybe.indexOf(filterIdChar) > -1) {
        [id, displayName] = displayNameAndIdMaybe.split(filterIdChar);
      }
      filters.push({
        id,
        type: filterKey,
        value,
        displayName: decodeURIComponent(displayName),
      });
    });
  });

  return filters;
};

export const encodeFilterForSearch = (filters = []) => {
  if (!filters || filters.length === 0) {
    return null;
  }

  const filterResult = [];
  const filtersGoupedByType = groupBy(filters, (f) => f.type);

  Object.keys(filtersGoupedByType || {}).forEach((key) => {
    filterResult.push(
      (filtersGoupedByType[key] || [])
        .map((f) => `${f.type}:${encodeURIComponent(f.value)}`)
        .join(' OR '),
    );
  });

  return (filterResult || []).join(' AND ');
};

// TODO fix this, it shoudn't be here, it badly named:
export const getNewPathnameForEntityTypePool = (
  pool,
  currentPathname,
  entityPoolIdentifier,
) => {
  const existingEntityTypePoolFilter = pool.reduce((accu, x) => {
    if (accu) {
      return accu;
    }
    if (currentPathname.indexOf(x) > -1) {
      return x;
    }
    return accu;
  }, false);

  let newPathname = currentPathname;

  if (existingEntityTypePoolFilter && entityPoolIdentifier === '') {
    newPathname = currentPathname.replace(
      `${existingEntityTypePoolFilter}/`,
      '',
    );
  } else if (existingEntityTypePoolFilter) {
    newPathname = currentPathname.replace(
      existingEntityTypePoolFilter,
      entityPoolIdentifier,
    );
  } else {
    newPathname = currentPathname.replace(
      'search/',
      `search/${entityPoolIdentifier}/`,
    );
  }
  return newPathname;
};

export const entityTypePoolIdentifiers = ['', 'person', 'organization'];

export const entityTypePools = {
  person: ['/Person'],
  organization: ['/Organization'],
};

export const removeNewFilter = (filter, selectedFilters = []) =>
  selectedFilters
    // filter by id (property filter)
    .filter((f) => (f.id ? f.id !== filter.id : true))
    // filter by value/type
    .filter((f) => {
      if (f.id) {
        return true;
      } // (property filter)

      const encodedValue = Array.isArray(filter.value)
        ? filter.value.join()
        : filter.value;
      const currentEncodedValue = Array.isArray(f.value)
        ? f.value.join()
        : f.value;

      return filter.type !== f.type || encodedValue !== currentEncodedValue;
    });

export const updateNewFilter = (filter, selectedFilters = []) => {
  const result = removeNewFilter(filter, selectedFilters);
  result.push(filter);

  return result;
};

export default {
  entityTypePoolIdentifiers,
  getSearchStrategy,
  getNewPathnameForEntityTypePool,
};
