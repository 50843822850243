import styled from 'styled-components';

export const ExplainLogIconWrapper = styled.div`
  align-items: center;
  background: ${({ color }) => (color ? color : '#f2c230')};
  border-radius: 80%;
  display: flex;
  justify-content: center;
  margin-right: 12px;
  padding: 8px;

  & svg {
    fill: ${({ fill }) => (fill ? `${fill} !important` : '#fff !important')};
    height: ${({ size }) => (size ? `${size}px` : '20px')};
    width: ${({ size }) => (size ? `${size}px` : '20px')};
    stroke: ${({ stroke }) =>
      stroke ? `${stroke} !important` : '#fff !important'};
    margin: 0 !important;

    & path {
      fill: ${({ fill }) => (fill ? `${fill} !important` : '#fff !important')};
    }
  }
`;
