import { get } from 'lodash';
import { getFormattedDate } from '../../../helpers/dates';

const ListDateComponent =
  (propName = '') =>
  (props = {}) => {
    const value = get(props, propName);
    return getFormattedDate(value);
  };

export default ListDateComponent;
