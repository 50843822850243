import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import {
  PrimaryButton,
  FormGenerator,
  useFormGeneratorWithReactHook,
} from '@cluedin/form';

const TokenForm = ({
  integrationName,
  config = {},
  onAuthenticate,
  isSaving,
}) => {
  const { fields, control, handleSubmit, errors, touched, isValid } =
    useFormGeneratorWithReactHook(config);

  return (
    <div>
      <FormGenerator
        fields={fields}
        control={control}
        errors={errors}
        touched={touched}
      />
      <div className="__test_integrationNormal_add">
        <PrimaryButton
          disabled={isSaving || !isValid}
          loading={isSaving}
          text={
            <FormattedMessage
              id="module-integration-authWith"
              values={{ integrationName }}
            />
          }
          onClick={handleSubmit(onAuthenticate)}
        />
      </div>
    </div>
  );
};

export default TokenForm;
