"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(Popover) {
  return (0, _styledComponents.default)(Popover)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  & .card {\n    position: absolute;\n    overflow-y: auto;\n    overflow-x: hidden;\n    // So we see the popover when it's empty.\n    // It's most likely on issue on userland.\n    min-width: 16px;\n    min-height: 16px;\n    max-width: calc(100% - 32px);\n    max-height: calc(100% - 32px);\n    // We disable the focus ring for mouse, touch and keyboard users.\n    outline: 0;\n  }\n"])));
};
exports.default = _default;