import React, { useMemo } from 'react';
import { withWorkflow } from '@cluedin/form';

import { UserMenuProfileSingedInAs } from './UserMenuProfileSingedInAs';
import { UserMenuPanel } from './UserMenuPanel';

export const withUserMenu = (Comp) => (props) => {
  const { currentUser } = props;
  const logoUrl = useMemo(
    () => currentUser?.entity?.data?.logoUrl,
    [currentUser],
  );
  const username = useMemo(
    () => currentUser?.client?.UserName || currentUser?.entity?.name || '–',
    [currentUser],
  );

  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(() => <Comp {...props} openWorkflow={openWorkflow} />, [props]),
    (panelProps) => <UserMenuPanel {...panelProps} {...props} />,
    {
      withCustomHeader: true,
      overlapHeader: true,
      withNativeClose: true,
      onRenderNavigationContent: (props, defaultRender) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <UserMenuProfileSingedInAs
            {...props}
            username={username}
            logoUrl={logoUrl}
            key="profileSignedInAs"
          />
          {defaultRender(props)}
        </div>
      ),
    },
  );

  return <EnhancedhWorkflow />;
};
