import React from 'react';
import styled from 'styled-components';
import SocialMenuItemLoader from './SocialMenuItemLoader';

const SocialUL = styled.ul`
  display: flex;
`;

const SocialMenuPlaceholder = ({ style, iconSize = 16, count = 4, color }) => (
  <SocialUL style={style || {}}>
    {[...Array(count)].map(() => (
      <SocialMenuItemLoader iconSize={iconSize} color={color} />
    ))}
  </SocialUL>
);

SocialMenuPlaceholder.displayName = 'SocialMenuPlaceholder';

export default SocialMenuPlaceholder;
