import React from 'react';
import { Button } from '@cluedin/form';
import IntegrationListRowStyle from './IntegrationListRowStyle';
import IconPlaceholder from '../../../../core/components/composites/Placeholder/IconPlaceholder';
import TextPlaceholder from '../../../../core/components/composites/Placeholder/TextPlaceholder';
import LittleTextPlaceholder from '../../../../core/components/composites/Placeholder/LittleTextPlaceholder';

const IntegrationListRow = () => {
  const addButton = (
    <Button
      style={IntegrationListRowStyle.button}
      click={() => {}}
      text={<TextPlaceholder style={{ width: '140px' }} />}
    />
  );

  const inviteButton = (
    <Button
      style={IntegrationListRowStyle.button}
      click={() => {}}
      text={<TextPlaceholder style={{ width: '50px' }} />}
    />
  );

  return (
    <div
      style={IntegrationListRowStyle.root}
      className={`__test_integration __test_integration_${'none'}`}
    >
      <div style={IntegrationListRowStyle.iconWrapper}>
        <IconPlaceholder />
      </div>
      <div style={IntegrationListRowStyle.descriptionContainer}>
        <div style={IntegrationListRowStyle.descriptionContainerContent}>
          <h4 style={IntegrationListRowStyle.title}>
            {<TextPlaceholder style={{ width: '50%' }} />}
          </h4>
          <div style={IntegrationListRowStyle.details}>
            {<LittleTextPlaceholder style={{ width: '100%' }} />}
          </div>
          <div style={IntegrationListRowStyle.details}>
            {<LittleTextPlaceholder style={{ width: '100%' }} />}
          </div>
          <div style={IntegrationListRowStyle.details}>
            {<LittleTextPlaceholder style={{ width: '40%' }} />}
          </div>
        </div>
        <div style={IntegrationListRowStyle.action}>
          {inviteButton}
          {addButton}
        </div>
      </div>
    </div>
  );
};

export default IntegrationListRow;
