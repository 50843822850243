import { gql, useMutation } from '@apollo/client';

import { SavedSearch } from './useSavedSearch';

export const EDIT_SEARCH = gql`
  mutation editSearch($id: ID, $viewName: String, $search: InputEditSearch) {
    administration {
      editSearch(id: $id, viewName: $viewName, search: $search) {
        filterUrl
        id
        name
        viewMode
        columnsConfig
        createdBy
        created
        shared
      }
    }
  }
`;

type EditSearchRes = {
  administration: {
    editSearch: SavedSearch;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Options<T = {}> = {
  id: string;
  viewName: string;
  search: T;
};

export const useEditSearch = <T>({
  viewName,
}: Omit<Options, 'search' | 'id'>) => {
  const [edit, { loading, error }] = useMutation<EditSearchRes, Options<T>>(
    EDIT_SEARCH,
  );

  const editSearch = async (id: string, search: T) => {
    return edit({
      variables: {
        id,
        search,
        viewName,
      },
    });
  };

  return {
    error,
    loading,
    editSearch,
  };
};
