import styled from 'styled-components';

const DashboardTitle = styled.h4`
  font-size: 16px;
  margin: 16px 0;
  width: 100%;
  padding-bottom: 2px;
  color: #999999b5;
  border-bottom: 1px solid;
`;

export default DashboardTitle;
