import { FC } from 'react';
import { Panel } from '@cluedin/form';

import {
  GraphNetwork,
  SelectedConnectingEdge,
  UpdatedGraphNetworkNode,
} from '../types';
import { EntityRelationInfo } from './EntityRelationInfo';

type EdgePropertiesPanelProps = {
  entityId: string;
  selectedEdge: SelectedConnectingEdge;
  setSelectedEdge: React.Dispatch<
    React.SetStateAction<SelectedConnectingEdge | null>
  >;
  onNodeClick: (selectedNode: UpdatedGraphNetworkNode) => void;
  onGroupedRelationsClick: (groupedNode: UpdatedGraphNetworkNode) => void;
  graphNetworkState: GraphNetwork;
};

export const EntityRelationEdgePanel: FC<EdgePropertiesPanelProps> = ({
  selectedEdge,
  entityId,
  setSelectedEdge,
  onNodeClick,
  onGroupedRelationsClick,
}) => {
  return (
    <Panel open={!!setSelectedEdge} withCustomHeader={true} panelType="large">
      <EntityRelationInfo
        entityId={entityId}
        setSelectedEdge={setSelectedEdge}
        selectedEdge={selectedEdge}
        onClose={() => setSelectedEdge(null)}
        onNodeClick={onNodeClick}
        onGroupedRelationsClick={onGroupedRelationsClick}
      />
    </Panel>
  );
};
