import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import SignupUserFormContainer from './SignupUserFormContainer';
import PublicContext from '../../../auth/components/composites/PublicContext';
import SignUpImg from '../../assets/sign-up-illustration.png';

const SignupUserContainer = ({
  match: {
    params: { id },
  },
  location: { search },
  isDomain,
}) => {
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const isAdmin = params.get('isAdmin') || 'False';

  return (
    <NotSignedInLayoutDividedInTwo
      isDomain={isDomain}
      Page={SignupUserFormContainer}
      Context={PublicContext}
      url={`${SignUpImg}`}
      invitationId={decodeURIComponent(id)}
      email={email}
      isAdmin={isAdmin && isAdmin.toLowerCase() === 'True'.toLowerCase()}
    />
  );
};

export default SignupUserContainer;
