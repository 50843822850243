/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';

export type BadgeType = 'warning' | 'info' | 'success' | 'danger' | 'error';

type BadgeUIProps = {
  hasAction: boolean;
  small: boolean;
  type: BadgeType;
};

const BadgeUI = styled.div<BadgeUIProps>`
  border-radius: 12px;
  color: ${({ type }) => {
    if (type === 'success') {
      return '#1B746E';
    }
    if (type === 'warning') {
      return '#856300';
    }
    if (type === 'info') {
      return '#3264AE';
    }
    if (type === 'danger' || type === 'error') {
      return '#FCE8E8';
    }
  }};
  background-color: ${({ type }) => {
    if (type === 'success') {
      return '#DEF1F0';
    }
    if (type === 'warning') {
      return '#FFF8E0';
    }
    if (type === 'info') {
      return '#E6EDF8';
    }
    if (type === 'danger' || type === 'error') {
      return '#AF1D1D';
    }
  }};

  white-space: nowrap;
  font-weight: 400;
  display: inline-flex;
  padding: ${({ small }) => (small ? '4px 6px' : '6px 12px')};
  ${({ small }) => (small ? 'font-size: 12px' : '')}
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'default')};

  &:hover {
    opacity: ${({ hasAction }) => (hasAction ? 0.8 : 1)};
  }
`;

export type BadgeProps = {
  type?: BadgeType;
  small?: boolean;
  hasHover?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  label: ReactNode | string;
};

export const Badge: FC<BadgeProps> = ({
  label,
  onClick,
  style = {},
  small = false,
  hasHover = false,
  type = 'warning',
}) => {
  return (
    <BadgeUI
      type={type}
      style={style}
      small={small}
      onClick={onClick}
      hasAction={!!onClick || hasHover}
      data-test={(label as any)?.props?.id}
    >
      {label}
    </BadgeUI>
  );
};

export default Badge;
