"use strict";

exports.__esModule = true;
exports.getUxiAppropriateIcon = exports.getSvgAppropriateIcon = exports.getAppropriateIcon = void 0;
var SvgIcons = _interopRequireWildcard(require("@cluedin/svgs"));
var UxiIcons = _interopRequireWildcard(require("uxi/Icons"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var capitalize = function capitalize(x) {
  return x.charAt(0).toUpperCase() + x.slice(1);
};
var getUxiAppropriateIcon = function getUxiAppropriateIcon(identifier) {
  if (identifier === void 0) {
    identifier = '';
  }
  var cleanedIdentifer = capitalize(identifier.toLowerCase());
  return UxiIcons[cleanedIdentifer] ? UxiIcons[cleanedIdentifer] : null;
};
exports.getUxiAppropriateIcon = getUxiAppropriateIcon;
var getSvgAppropriateIcon = function getSvgAppropriateIcon(identifier) {
  if (identifier === void 0) {
    identifier = '';
  }
  var cleanedIdentifer = capitalize(identifier);
  return SvgIcons[cleanedIdentifer] ? SvgIcons[cleanedIdentifer] : null;
};
exports.getSvgAppropriateIcon = getSvgAppropriateIcon;
var getAppropriateIcon = function getAppropriateIcon(identifier) {
  if (identifier === void 0) {
    identifier = '';
  }
  var uxiIcons = getUxiAppropriateIcon(identifier);
  if (uxiIcons) {
    return uxiIcons;
  }
  var svgIcons = getSvgAppropriateIcon(identifier);
  if (svgIcons) {
    return svgIcons;
  }
  return UxiIcons.Circle;
};
exports.getAppropriateIcon = getAppropriateIcon;