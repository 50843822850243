import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import Consuming from 'uxi/Icons/Consuming';
import routes from './routes';

const { consume } = actionsConfiguration;

export default {
  pillar: 'consume',
  name: 'connector',
  displayName: <FormattedMessage id="connector" />,
  path: '/connector',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Consuming,
        name: <FormattedMessage id="connector" />,
        link: '/admin/consume/connector',
        claims: consume.exportTargets.exportTarget.view,
      },
    ],
  },
  routes,
};
