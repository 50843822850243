import { useQuery, gql, useLazyQuery } from '@apollo/client';
import get from 'lodash/get';

const GET_VOCABULARY_VALUES = gql`
  query getVocabulary($vocabularyKey: String!) {
    management {
      id
      vocabularyKeyValues(vocabularyKey: $vocabularyKey)
    }
  }
`;

export const useVocabularyKeyValues = (vocabularyKey, skipRetrievedValues) => {
  const { data, loading, error } = useQuery(GET_VOCABULARY_VALUES, {
    fetchPolicy: 'network-only',
    skip: skipRetrievedValues || !vocabularyKey,
    variables: {
      vocabularyKey: vocabularyKey
        ? encodeURIComponent(vocabularyKey)
        : vocabularyKey,
    },
  });

  const vocab = get(data, 'management.vocabularyKeyValues', []);

  return [vocab, loading, error];
};

export const useLazyVocabularyKeyValues = () => {
  const [fetchVocabValuesQuery, { data, loading, error }] = useLazyQuery(
    GET_VOCABULARY_VALUES,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const fetchVocabValues = async (vocabularyKey) => {
    return await fetchVocabValuesQuery({
      variables: { vocabularyKey: encodeURIComponent(vocabularyKey) },
    });
  };

  const vocabKeyValues = get(data, 'management.vocabularyKeyValues', []);

  return {
    vocabKeyValues,
    fetchVocabValues,
    loadingVocabValues: loading,
    errorLoadingVocabValues: error,
  };
};

export default {
  useVocabularyKeyValues,
};
