import { useTheme } from '@cluedin/theme';
import { HighlightTextFromContext } from '@cluedin/components';
import { Link } from 'react-router-dom';
import VocabularyFullKey from './VocabularyFullKey';
import { Externallink } from 'uxi/Icons';

const VocabKeyItemLink = ({ vocabularyKey, isExternalLink }) => {
  const theme = useTheme();
  const target = isExternalLink ? '_blank' : null;

  return (
    <>
      <Link
        data-test={`vocabularykey-link`}
        to={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
          vocabularyKey.key,
        )}`}
        target={target}
        style={{ color: theme.palette.themePrimary }}
      >
        {vocabularyKey.displayName && (
          <HighlightTextFromContext text={vocabularyKey.displayName} />
        )}
        {isExternalLink && (
          <span style={{ marginLeft: '6px' }}>
            <Externallink size={8} />
          </span>
        )}
      </Link>
      <div>
        <VocabularyFullKey vocabKey={vocabularyKey.key} />
      </div>
    </>
  );
};

export default VocabKeyItemLink;
