export const getScaledValueWithMetricNotation = (value) => {
  let unit;
  let val = Number(value);
  const oneThousand = 1000;
  const tenThousands = 10000;
  const oneMillion = 1_000_000;
  const oneBillion = 1_000_000_000;
  if (typeof value === 'number' && !isNaN(value)) {
    if (value >= oneBillion) {
      unit = 'B';
      const scaledToBillions = value / oneBillion;
      val = scaledToBillions;
    } else if (value >= oneMillion) {
      unit = 'M';
      const scaledToMillions = value / oneMillion;
      val = scaledToMillions;
    } else if (value > tenThousands) {
      unit = 'k';
      const scaledToThousand = value / oneThousand;
      val = scaledToThousand;
    }
  }

  const floored = Math.floor(val);

  const finalVal = `${floored} ${unit ? `${unit} +` : ''}`.trim();

  return finalVal;
};

export default {
  getScaledValueWithMetricNotation,
};
