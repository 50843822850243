import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';

export const SIGNIN_MUTATION = gql`
  mutation CreateToken(
    $email: String!
    $password: String!
    $clientId: String!
  ) {
    auth {
      createToken(email: $email, password: $password, clientId: $clientId) {
        accessToken
        expiresIn
        refreshToken
        scope
      }
    }
  }
`;

export const useSignInMutation = (clientId) => {
  const [signIn, { data, loading, error }] = useMutation(SIGNIN_MUTATION);

  const token = get(data, 'auth.createToken');

  return [
    (creds) => signIn({ variables: { ...creds, clientId } }),
    {
      success: !!token,
      token,
      loading,
      error,
    },
  ];
};

export default {
  useSignInMutation,
};
