import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/detail/:id',
    exact: true,
    claims: {
      'integration.enrichment': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"ConfiguredEnricherDetailPage" */
        './components/pages/ConfiguredEnricherDetailPage'
      ),
    ),
  },
  {
    path: '/',
    exact: true,
    claims: {
      'integration.enrichment': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"AllConfiguredEnricher" */
        './components/pages/AllConfiguredEnricher'
      ),
    ),
  },
];
