import React from 'react';
import { Card, RatioBox } from 'uxi/Box';
import PropsMapperContainerQueries from 'uxi/internal/PropsMapperContainerQueries';
import { Flex } from 'uxi/Layout';
import styles from './report.style';

const rules = [
  {
    minWidth: 450,
    mapper: () => ({ ratio: '16/10' }),
  },
];

const GraphWidgetWrapper = ({ children, ratio }) =>
  ratio === false ? (
    <Card style={styles.card}>
      <Flex style={{ height: '100%' }}>
        <div style={{ width: '100%' }}>{children}</div>
      </Flex>
    </Card>
  ) : (
    <Card style={styles.card}>
      <PropsMapperContainerQueries ratio="4/3" rules={rules}>
        <RatioBox>
          <Flex style={{ height: '100%' }}>
            <div style={{ width: '100%', ...styles.cardWithRatio }}>
              {children}
            </div>
          </Flex>
        </RatioBox>
      </PropsMapperContainerQueries>
    </Card>
  );

export default GraphWidgetWrapper;
