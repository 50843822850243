import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Integration } from 'uxi/Icons';
import Toast from '../../../../core/components/composites/Toast';
import { withIntegrationConfig } from '../../../../integration/components/hocs/withIntegrationConfig';

const NewProviderNotificationStyle = {
  logo: {
    width: '24px',
  },
};

const noop = () => {};

const NewIntegrationNotification = ({
  index,
  providerNotification,
  integrationConfig,
  onCloseClick,
}) => {
  const userName = providerNotification.userName;

  if (!integrationConfig) {
    return <span />;
  }

  const title = (
    <FormattedMessage id="NewProviderNotification.ProviderAddedTitle" />
  );
  const message = (
    <FormattedMessage
      id="NewProviderNotification.ProviderAddedMessage"
      values={{ userName, providerName: integrationConfig.Name }}
    />
  );

  let providerIcon;

  if (integrationConfig) {
    providerIcon = (
      <img
        style={NewProviderNotificationStyle.logo}
        alt={integrationConfig.Name}
        src={integrationConfig.Icon}
      />
    );
  } else {
    providerIcon = <Integration />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick || noop}
      title={title}
      message={message}
      customIcon={providerIcon}
    />
  );
};

export default withIntegrationConfig(NewIntegrationNotification, (props) => ({
  integrationName: props && props.providerName,
}));
