import { FormattedHTMLMessage } from '@cluedin/locale';
import ColumnSelectionListNoneSelected from './ColumnSelectionListNoneSelected';

const ColumnSelectionListNoColumns = ({
  defaultFields = [],
  maximumNumnerColumn,
  noDefault,
  emptyMessage = 'Please, add a property to configure your view.',
}) => {
  return (
    <div style={{ padding: '16px', textAlign: 'center' }}>
      <div>{emptyMessage}</div>
      {maximumNumnerColumn && (
        <div style={{ marginTop: '2px' }}>
          <FormattedHTMLMessage
            id="module-goldenRecordList-chooseUpToProps"
            values={{ maximumNumnerColumn }}
          />
        </div>
      )}
      {!noDefault && (
        <div style={{ marginTop: '16px' }}>
          <ColumnSelectionListNoneSelected defaultFields={defaultFields} />
        </div>
      )}
    </div>
  );
};

export default ColumnSelectionListNoColumns;
