import { useQuery, gql } from '@apollo/client';

const GET_ME = gql`
  query me {
    administration {
      me {
        client
        entity
      }
    }
  }
`;

type Client = {
  startDate: string;
  lastLoginDate: string;
  organizationId: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEndDateUtc: string;
  lockoutEnd: string;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  roles: string[];
  claims: string[];
  logins: string[];
  id: string;
  userName: string;
};

type Entity = {
  entity: {
    'attribute-id': string;
    'attribute-organization': string;
    processedData: {
      entityType: string;
      name: string;
      aliases: string[];
      systemTagName: string;
      codes: string[];
      'provider-origins': string[];
      createdDate: string;
      modifiedDate: string;
      discoveryDate: string;
      edgesSummary: {
        outgoing: {
          'attribute-edgeType': string;
          'attribute-count': string;
          'attribute-entityType': string;
        }[];
      };
      properties: {
        'attribute-type': string;
        'property-IsNewUser': string;
        'property-organization.name': string;
        'property-user.email': string;
      };
      propertyGroups: {
        name: string;
        source: string;
        keys: string[];
      }[];
      dataDescription: {
        dataClasses: string[];
      };
      'attribute-origin': string;
      'attribute-appVersion': string;
      sortDate: string;
      timeToLive: string;
      isShadowEntity: 'False' | 'True';
    };
  };
};

type MeRes = {
  administration: {
    me: {
      client: Client;
      entity: Entity;
    };
  };
};

export const useQueryMe = (skip = false) => {
  const { data, loading, error } = useQuery<MeRes>(GET_ME, {
    skip,
    fetchPolicy: 'network-only',
  });

  const me = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    client: data?.administration.me?.client!,
    entity: data?.administration.me?.entity,
  };

  return {
    me,
    loadingMe: loading,
    errorLoadingMe: error,
  };
};
