import React, { useState } from 'react';
import EntityListVocabSelectionWithDialogs from './EntityListVocabSelectionWithDialogs';
import { intersectionWith } from 'lodash';
/**
 * This is a sample of how you can select either Entity or Vocab
 * to setup in various situation in the application (export target for example)
 */
const EntityListVocabSelection = ({
  defaultColumns = [],
  onChange,
  entityKeys = [],
  noEntityProperties,
}) => {
  const [columns, setColumns] = useState(defaultColumns);

  return (
    <EntityListVocabSelectionWithDialogs
      noEntityProperties={noEntityProperties}
      entityKeys={entityKeys}
      columns={columns || []}
      onAddColumns={(newColumns = [], type) => {
        if (type === 'vocabulary') {
          const vocabularyColumns = newColumns.map((c) => ({
            property: c,
            type: 'vocabulary',
          }));

          const filteredDuplicates = vocabularyColumns.filter((c) => {
            const found = columns.find(
              (selected) => selected.property === c.property,
            );
            return !found;
          });

          const allColumns = [...columns, ...filteredDuplicates];

          setColumns(allColumns);
          onChange && onChange(allColumns);
        }

        if (type === 'entity') {
          // All column already present
          const alreadyAdded = intersectionWith(columns, newColumns, (a, b) => {
            return a.property === b;
          });
          // All newly added column
          const allNew = newColumns.filter((c) => {
            const isAlreadyAddedColum = alreadyAdded.find(
              (aC) => aC.property === c,
            );
            return !isAlreadyAddedColum;
          });
          // We remove the one that was removed
          let updatedColumns = columns
            .map((currentColumn) => {
              if (currentColumn.type === 'vocabulary') {
                return currentColumn;
              }
              if (currentColumn.property === 'name') {
                return currentColumn;
              }
              const isFoundInUpdatedColumns = newColumns.find(
                (c) => c === currentColumn.property,
              );
              if (isFoundInUpdatedColumns) {
                return currentColumn;
              }
              return null;
            })
            .filter((c) => c);
          // We add the new one
          if (allNew && allNew.length > 0) {
            const newEntitiesColumn = allNew.map((c) => ({
              property: c,
              type: 'entity',
            }));
            updatedColumns = updatedColumns.concat(newEntitiesColumn);
          }
          setColumns(updatedColumns);
          onChange && onChange(updatedColumns);
        }
      }}
      onChangeColumns={(columns) => {
        setColumns(columns);
        onChange && onChange(columns);
      }}
      onDeleteColumns={(columnsToDelete = []) => {
        const filtered = columns.filter((existingColumns) => {
          const found = columnsToDelete.find(
            (c) => c.property === existingColumns.property,
          );

          return !found;
        });

        setColumns(filtered);
        onChange && onChange(filtered);
      }}
    />
  );
};

export default EntityListVocabSelection;
