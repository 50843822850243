import {
  cancel,
  take,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

const makeTakeLatestFiltered =
  (filterOn) =>
  (patternOrChannel, saga, ...args) =>
    fork(function* takeLatestFilterOn() {
      const lastestTasks = {};
      while (true) {
        const action = yield take(patternOrChannel);

        const filterKey = filterOn(action);

        if (lastestTasks[filterKey] !== undefined) {
          yield cancel(lastestTasks[filterKey]); // cancel is no-op if the task has already terminated
        }

        lastestTasks[filterKey] = yield fork(saga, ...args.concat(action));
      }
    });

/**
 *
 * @param {String} ACTIONKEY 'SOME_UNIQUE_ACTION_IDENTIFIER'
 * @param {Function} fetcher () => Promise
 * @param {?Boolean|?Function} takeLatestProp (actionPayload) => someDictKey
 * @param {?Function} successPayloadMapper
 * @param {?Function} errorPayloadMapper
 *
 * @returns {Generator} watcherSaga saga watcher to init at bootstrap
 */
export const makeAsyncActionWatcherSaga = (
  ACTIONKEY,
  fetcher,
  takeLatestProp = false,
  successPayloadMapper = (payload, resp) => ({ payload, result: resp }),
  errorPayloadMapper = (err) => err,
) => {
  function* workerSaga({ payload }) {
    try {
      const resp = yield fetcher(payload);
      yield put({
        type: `RECEIVE_${ACTIONKEY}`,
        payload: successPayloadMapper(payload, resp),
      });
    } catch (e) {
      yield put({
        type: `INVALID_${ACTIONKEY}`,
        error: errorPayloadMapper(e),
      });
    }
  }

  let controlFlowHandler = takeEvery; // default

  if (takeLatestProp === true) {
    controlFlowHandler = takeLatest;
  } else if (takeLatestProp && takeLatestProp instanceof Function) {
    controlFlowHandler = makeTakeLatestFiltered(takeLatestProp);
  }

  function* watcherSaga() {
    yield controlFlowHandler(`REQUEST_${ACTIONKEY}`, workerSaga);
  }

  return watcherSaga;
};

export default {
  makeAsyncActionWatcherSaga,
};
