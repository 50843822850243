import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { LocalizationContext } from '@cluedin/locale';

const RuleAndConditionWrapper = styled.div`
  color: #fff;
  cursor: ${({ previewMode }) => (!previewMode ? 'pointer' : 'not-allowed	')};
  display: inline-flex;
  font-size: 12px;
  & > div:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > div:hover {
    background: ${({ previewMode, themeSecondary }) =>
      !previewMode && themeSecondary};
    color: ${({ previewMode }) => !previewMode && '#fff'};
  }
`;

const RuleAndConditionElement = styled.div`
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-align: center;
  flex: 1;
  padding: 4px 6px;
  border: 1px solid
    ${({ previewMode, themePrimary }) =>
      !previewMode ? themePrimary : '#d7d7d8'};
  min-width: 42px;
  ${({ selected, themePrimary, previewMode }) => {
    if (selected && previewMode) {
      return `
        background: #d7d7d8;
        color: #fff;  
        font-weight: bold;
      `;
    }
    if (!selected && previewMode) {
      return `
        background: transparent;
        color: #d7d7d8;  
      `;
    }

    if (selected && !previewMode) {
      return `
        background: ${themePrimary};
        color: #fff;  
        font-weight: bold;
      `;
    }

    return `
      background: #fff;
      color: ${themePrimary};
    `;
  }}
`;

const RuleAndOrCondition = ({ condition, onChange, previewMode }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const themeSecondary = theme.palette.themeSecondary;

  const locales = useContext(LocalizationContext);
  const ruleConditionLocales = locales?.RuleCondition;

  return (
    <RuleAndConditionWrapper
      themeSecondary={themeSecondary}
      previewMode={previewMode}
    >
      <RuleAndConditionElement
        themePrimary={themePrimary}
        onClick={() => {
          !previewMode ? onChange('AND') : () => {};
        }}
        selected={condition === 'AND'}
        previewMode={previewMode}
      >
        {ruleConditionLocales.and}
      </RuleAndConditionElement>
      <RuleAndConditionElement
        themePrimary={themePrimary}
        onClick={() => {
          !previewMode ? onChange('OR') : () => {};
        }}
        selected={condition === 'OR'}
        previewMode={previewMode}
      >
        {ruleConditionLocales.or}
      </RuleAndConditionElement>
    </RuleAndConditionWrapper>
  );
};

export default RuleAndOrCondition;
