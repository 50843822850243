import React from 'react';
import PropTypes from 'prop-types';
import { DataCleaningIco, MergeIco, ManuallyAddedIcon } from '@cluedin/svgs';
import { ReactComponent as DeduplicationIcon } from '@cluedin/svgs/icons/deduplication.svg';
import { FormattedMessage } from '@cluedin/locale';
import { Externallink } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import { Link } from 'react-router-dom';

import {
  EntityTopologySplitPanelProviderIconWrapper,
  EntityTopologySplitPanelIcon,
  EntityTopologyIconWrapper,
} from './EntityTopologySplitPanelProviderIcon.styles';

const EntityTopologySplitPanelProviderIcon = ({
  provider,
  source,
  dataType,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const themeWhite = theme?.palette?.white;

  const isRecord = provider?.providerId;
  const isDeduplicationProjectMerge =
    source?.kind?.toLowerCase() === 'deduplicationprojectmerge';
  const isManuallyAdded =
    !provider?.providerId && source?.kind?.toLowerCase() === 'userinput';
  const isManuallyAddedFileEntry =
    source?.kind?.toLowerCase() === 'userinput' &&
    provider?.providerName?.toLowerCase() === 'manual entry source';
  const isClean = source?.kind?.toLowerCase() === 'clean';
  const isLegacyClean = source?.kind?.toLowerCase() === 'legacyclean';
  const isManualMerge =
    !provider?.providerId &&
    (source?.kind?.toLowerCase() === 'manualmerge' ||
      dataType?.toLowerCase() === 'manualmerge');

  let sourceTextToShow = (source?.kind ?? provider?.name) || (
    <FormattedMessage id="module-entityTopology-noSource" />
  );
  if (isRecord) {
    sourceTextToShow = provider?.providerName ?? provider?.name;
  }
  if (isManualMerge) {
    sourceTextToShow = (
      <FormattedMessage id="module-entityTopology-sourceManualMerge" />
    );
  }
  if (isDeduplicationProjectMerge) {
    sourceTextToShow = (
      <FormattedMessage id="module-entityTopology-sourceDeduplicationProjectMerge" />
    );
  }
  if (isClean || isLegacyClean) {
    sourceTextToShow =
      source?.name ??
      (source?.kind?.toLowerCase() === 'legacyclean' ? (
        <FormattedMessage id="module-entityTopology-sourceTextClean" />
      ) : (
        source?.kind
      ));
  }
  if (isManuallyAdded) {
    sourceTextToShow = (
      <FormattedMessage id="module-entityTopology-sourceManuallyAdded" />
    );
  }
  if (isManuallyAddedFileEntry) {
    sourceTextToShow = source?.name ?? (
      <FormattedMessage id="module-entityTopology-sourceManuallyAdded" />
    );
  }

  let sourceToShow = (
    <>
      <EntityTopologySplitPanelIcon
        dangerouslySetInnerHTML={{ __html: provider?.providerIcon }}
      />
      {sourceTextToShow}
    </>
  );
  if (isManualMerge) {
    sourceToShow = (
      <>
        <EntityTopologyIconWrapper
          themePrimary={themePrimary}
          themeWhite={themeWhite}
          sourceKind={source?.kind?.toLowerCase() || dataType?.toLowerCase()}
        >
          <MergeIco style={{ height: 16, width: 16 }} />
        </EntityTopologyIconWrapper>
        {sourceTextToShow}
      </>
    );
  }
  if (isDeduplicationProjectMerge) {
    sourceToShow = (
      <>
        <EntityTopologyIconWrapper
          themePrimary={themePrimary}
          themeWhite={themeWhite}
          sourceKind={source?.kind?.toLowerCase()}
        >
          <DeduplicationIcon style={{ height: 22, width: 22 }} />
        </EntityTopologyIconWrapper>

        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={`/admin/management/deduplication/detail/${source?.id}`}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {sourceTextToShow}
          <div style={{ marginLeft: '6px' }}>
            <Externallink size={12} />
          </div>
        </Link>
      </>
    );
  }
  if (isManuallyAddedFileEntry) {
    sourceToShow = (
      <>
        <EntityTopologyIconWrapper
          themePrimary={themePrimary}
          themeWhite={themeWhite}
          sourceKind={source?.kind?.toLowerCase()}
        >
          <ManuallyAddedIcon style={{ height: 22, width: 22 }} />
        </EntityTopologyIconWrapper>

        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={`/admin/inbound/manual-data-entry/detail/${source?.id}`}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {sourceTextToShow}
          <div style={{ marginLeft: '6px' }}>
            <Externallink size={12} />
          </div>
        </Link>
      </>
    );
  }
  if (isManuallyAdded) {
    sourceToShow = (
      <>
        <EntityTopologyIconWrapper
          themePrimary={themePrimary}
          themeWhite={themeWhite}
          sourceKind={source?.kind?.toLowerCase() || dataType?.toLowerCase()}
        >
          <ManuallyAddedIcon style={{ height: 22, width: 22 }} />
        </EntityTopologyIconWrapper>
        {sourceTextToShow}
      </>
    );
  }
  if (isClean || isLegacyClean) {
    sourceToShow = (
      <>
        <EntityTopologyIconWrapper
          themePrimary={themePrimary}
          themeWhite={themeWhite}
          sourceKind={source?.kind?.toLowerCase()}
        >
          <DataCleaningIco style={{ height: 18, width: 18 }} />
        </EntityTopologyIconWrapper>

        {source?.id ? (
          <Link
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/preparation/new-clean-project/${source?.id}`}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {sourceTextToShow}
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </Link>
        ) : (
          sourceTextToShow
        )}
      </>
    );
  }

  return (
    <EntityTopologySplitPanelProviderIconWrapper
      data-test={`entityTopology-dataPartPanel-headerIcon-${provider?.providerName}`}
    >
      {sourceToShow}
    </EntityTopologySplitPanelProviderIconWrapper>
  );
};

export default EntityTopologySplitPanelProviderIcon;

EntityTopologySplitPanelProviderIcon.propTypes = {
  provider: PropTypes.object,
};

EntityTopologySplitPanelProviderIcon.defaultProps = {
  provider: null,
};
