"use strict";

exports.__esModule = true;
exports.DefaultButtonStyle = void 0;
var DefaultButtonStyle = function DefaultButtonStyle() {
  return {
    label: {
      fontWeight: 'normal'
    }
  };
};
exports.DefaultButtonStyle = DefaultButtonStyle;