import { removeDots } from '../../../selectors/schema';
import { getAppropriateComponentFromSchema } from './getAppropriateComponentFromSchema';

const cacheForAllProps = {};

export const getSchemaViewModelFromEntityPropertyKey = (
  propertyKey,
  schema = [],
) => {
  const findSchema = (key) =>
    schema.find((s) => s.Key.toLowerCase() === key.toLowerCase());

  const hasCache = cacheForAllProps[propertyKey.toLowerCase()];

  if (hasCache) {
    return hasCache;
  }

  const propertyInSchema = findSchema(propertyKey);
  let result;

  if (propertyInSchema) {
    result = {
      propertyInSchema,
      component: getAppropriateComponentFromSchema(propertyInSchema),
      normalizedKeyNameForForm: removeDots(propertyKey),
    };
  } else {
    result = {
      propertyInSchema: null,
      component: null,
      normalizedKeyNameForForm: null,
    };
  }

  cacheForAllProps[propertyKey.toLowerCase()] = result;

  return result;
};

export default getSchemaViewModelFromEntityPropertyKey;
