import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { Form } from 'react-redux-form';
import { Merge } from 'uxi/Icons';
import { ButtonWithConfirm } from 'uxi/Button';
import { Spinner } from '@cluedin/form';
import Alert from 'uxi/Alert';
import EntityMergePropertiesResolverRow from './EntityMergePropertiesResolverRow';
import { decodeRRFKey } from './EntityComparePropertiesResolver';

const styles = {
  wrapper: {
    padding: '1.5em',
  },
  buttonWrapper: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

class EntityMergePropertiesResolver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitConfirmed: false,
    };
  }

  handleSubmit() {
    const { onSubmit, ids, targetId, propsDelta } = this.props;

    const formattedPropsDelta = Object.keys(propsDelta)
      .filter((k) => k !== '$form')
      .map((key) => ({
        Id: propsDelta[key].value,
        PropertyName: decodeRRFKey(key),
      }));

    if (onSubmit) {
      onSubmit({
        ids,
        targetId,
        delta: formattedPropsDelta,
      });
    }

    this.setState({
      submitConfirmed: true,
    });
  }

  renderNoConflictMessage() {
    const { errors, conflictedProperties, isDone } = this.props;

    let noConflictMessage = null;
    if (
      conflictedProperties &&
      conflictedProperties.length === 0 &&
      errors.length === 0 &&
      !isDone
    ) {
      noConflictMessage = (
        <Alert type="success">
          <FormattedHTMLMessage id="mergeNoConflict" />
        </Alert>
      );
    }

    return noConflictMessage;
  }

  renderErrorContent() {
    const { isSubmitting, errors, isDone } = this.props;
    const { submitConfirmed } = this.state;

    let errorContent = null;

    if (submitConfirmed) {
      if (!isSubmitting) {
        if (isDone && errors.length === 0) {
          errorContent = (
            <Alert type="success">
              <FormattedHTMLMessage id="mergeSuccess" />
            </Alert>
          );
        } else {
          errorContent = (
            <Alert type="danger">
              <FormattedHTMLMessage id="mergeError" />
            </Alert>
          );
        }
      }
    }

    return errorContent;
  }

  render() {
    const { conflictedProperties, selectedEntities, isSubmitting, isDone } =
      this.props;

    const errorContent = this.renderErrorContent();
    const noConflictMessage = this.renderNoConflictMessage();

    return (
      <div style={styles.wrapper}>
        {errorContent}
        {noConflictMessage}
        <Form key="theForm" model="propsDelta">
          {conflictedProperties &&
            conflictedProperties.map((conflictedProp, i, collection) => {
              return (
                <EntityMergePropertiesResolverRow
                  key={conflictedProp.key || conflictedProp.displayName}
                  propertyKey={conflictedProp.key}
                  model={`propsDelta.${conflictedProp.displayName}`}
                  conflictedProp={conflictedProp}
                  selectedEntities={selectedEntities}
                  isLast={i === collection.length - 1}
                />
              );
            })}
          {conflictedProperties.length > 3 && (
            <div>
              {errorContent}
              {noConflictMessage}
            </div>
          )}
          <div style={styles.buttonWrapper}>
            {isSubmitting ? (
              <Spinner
                size={32}
                style={{ padding: '0 8px' }}
                key="theMiniLoading"
              />
            ) : null}
            <ButtonWithConfirm
              key="theSubmitButton"
              type="danger"
              confirmText={
                <FormattedMessage
                  id="merge-entities-areYouSure"
                  defaultMessage="Are you sure you want to merge?"
                />
              }
              onClick={this.handleSubmit.bind(this)}
              icon={<Merge />}
              text={<FormattedMessage id="CompleteMerge" />}
              disabled={isDone || isSubmitting}
            />
          </div>
        </Form>
      </div>
    );
  }
}

export default EntityMergePropertiesResolver;
