import React, { Component } from 'react';
import { url, getCurrentToken } from '../../../../config';
import PageLoader from '../../../core/components/composites/PageLoader';

function graphQLFetcher(graphQLParams) {
  return fetch(`${url.graphQL}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCurrentToken()}`,
    },
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json());
}

class GraphiQlAsyncComp extends Component {
  state = {
    AsyncComponent: PageLoader,
  };

  async componentDidMount() {
    const module = await import(/* webpackChunkName:"graphiql" */ 'graphiql');
    //eslint-disable-next-line no-unused-expressions
    import(/* webpackChunkName:"graphiqlCSS" */ 'graphiql/graphiql.css');
    const AsyncComponent = module.default;

    this.setState({ AsyncComponent });
  }

  render() {
    const { AsyncComponent } = this.state;

    return <AsyncComponent fetcher={graphQLFetcher} />;
  }
}

const GraphiQlPage = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          flex: '1',
          height: 'calc(100vh - 90px)',
          overflow: 'hidden',
          zIndex: '0',
        }}
      >
        <GraphiQlAsyncComp />
      </div>
    </div>
  );
};

export default GraphiQlPage;
