"use strict";

exports.__esModule = true;
exports.useDebouncedEffect = void 0;
var _react = require("react");
var useDebouncedEffect = function useDebouncedEffect(effect, delay, deps) {
  var callback = (0, _react.useCallback)(effect, deps);
  (0, _react.useEffect)(function () {
    var handler = setTimeout(function () {
      callback();
    }, delay);
    return function () {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
exports.useDebouncedEffect = useDebouncedEffect;