import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSearch, useCommonFilter } from '@cluedin/components';
import { GqlErrorMessages } from '@cluedin/form';

import { EntityPropertiesPageWrapper } from './EntityPropertiesPage.styles';
import { useGetEntityById } from '../../hooks/getEntityById';
import { useGetHistoryById } from '../../hooks/getHistoryById';
import EntityPropertiesList from '../containers/EntityProperties/EntityPropertiesList/EntityPropertiesList';
import PageLoader from '../../../core/components/composites/PageLoader';

const EntityPropertiesPage = ({
  entityId,
  history,
  location,
  removeMargin,
}) => {
  const [data, loading, error, refetch] = useGetEntityById(entityId);
  const entityProperties = get(
    data,
    'virtualization.entityById.entityProperties',
  );
  const propertyGroups = get(data, 'virtualization.entityById.propertyGroups');

  const [
    {
      data: historyData,
      historyLoading,
      historyError,
      refetch: historyRefetch,
    },
  ] = useGetHistoryById(entityId);

  const historyChangesToShow = (historyData || []).map((historyItem) => {
    const dataReference = historyItem?.version?.['attribute-dataReference'];
    const date = historyItem?.version?.['attribute-date'];
    let changes = Array.isArray(historyItem?.version?.change)
      ? historyItem?.version?.change
      : [{ change: historyItem?.version?.change }];

    const newChanges = (changes || []).map((c) => {
      return { ...c, dataReference, date };
    });

    return newChanges;
  });

  const [connectorId, setConnectorId] = useSearch(history, location);
  const [groupNameFilterState, setGroupNameFilterState] = useCommonFilter(
    'groupName',
    history,
    location,
  );

  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  if (historyError) {
    return <GqlErrorMessages error={historyError} />;
  }

  if (loading) {
    return <PageLoader />;
  }

  if (historyLoading) {
    return <PageLoader />;
  }

  const filteredEntityProperties = connectorId
    ? entityProperties.filter((entityProperty) => {
        if (connectorId === 'core') {
          return (
            entityProperty?.vocabularyKey?.isCluedInCore &&
            !entityProperty?.vocabularyKey?.vocabulary?.connector?.id
          );
        }
        if (connectorId === 'noSource') {
          return (
            !entityProperty?.vocabularyKey?.isCluedInCore &&
            !entityProperty?.vocabularyKey?.vocabulary?.connector?.id
          );
        }

        return (
          entityProperty?.vocabularyKey?.vocabulary?.connector?.id ===
          connectorId
        );
      })
    : entityProperties;

  const filteredHistoryChanges = historyChangesToShow
    ? (historyChangesToShow || []).map((historyItem) => {
        const historyItems = historyItem?.filter(
          (changeItem) =>
            changeItem?.change['attribute-changedPart'] === 'Property',
        );
        const modifiedAtItems = historyItem?.filter(
          (changeItem) =>
            changeItem?.change['attribute-key'] === 'ModifiedDate',
        );

        return [...historyItems, ...modifiedAtItems];
      })
    : historyChangesToShow;

  return (
    <EntityPropertiesPageWrapper removeMargin={removeMargin}>
      <EntityPropertiesList
        entityProperties={entityProperties}
        propertyGroups={propertyGroups}
        filteredEntityProperties={filteredEntityProperties}
        filteredHistoryChanges={filteredHistoryChanges}
        connectorId={connectorId}
        setConnectorId={setConnectorId}
        loading={loading}
        history={history}
        location={location}
        entityId={entityId}
        groupNameFilterState={groupNameFilterState}
        setGroupNameFilterState={setGroupNameFilterState}
        refetch={refetch}
        historyRefetch={historyRefetch}
        historyLoading={historyLoading}
      />
    </EntityPropertiesPageWrapper>
  );
};

export default EntityPropertiesPage;

EntityPropertiesPage.propTypes = {
  entityId: PropTypes.string,
  removeMargin: PropTypes.bool,
};

EntityPropertiesPage.defaultProps = {
  entityId: '',
  removeMargin: null,
};
