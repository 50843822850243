import React from 'react';
import withEntityLink from '../Hocs/withEntityLink';

const EntityLinkContainer = ({ entityUrl, alt, children }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={entityUrl}
      alt={alt}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {children}
    </a>
  );
};

export default withEntityLink(EntityLinkContainer);
