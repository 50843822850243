import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PropTypes from 'prop-types';

const EntityPropertiesDateInput = ({
  setEntityInputValue,
  vocabularyValue,
}) => {
  const [dateToUse, setDateToUse] = useState(null);
  const [startDate, setStartDate] = useState(dateToUse);

  useEffect(() => {
    setDateToUse(moment(vocabularyValue).toDate());
  }, [vocabularyValue]);

  return (
    <>
      {dateToUse && (
        <DatePicker
          data-test="entity_entityProperties_editDatePicker"
          dateFormat="MMMM d, yyyy h:mm aa"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setEntityInputValue(date);
          }}
          showYearDropdown
          showTimeSelect
        />
      )}
    </>
  );
};

export default EntityPropertiesDateInput;

EntityPropertiesDateInput.propTypes = {
  setEntityInputValue: PropTypes.func,
  vocabularyValue: PropTypes.string,
};

EntityPropertiesDateInput.defaultProps = {
  setEntityInputValue: () => {},
  vocabularyValue: null,
};
