import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { FormSubmitAndCancel } from '@cluedin/form';

const AddRoleToUserToolbar = ({
  onCancel,
  loading,
  addRolesToUser,
  touched,
}) => (
  <FormSubmitAndCancel
    loading={loading}
    isTouchedAndNotSaved={touched}
    style={{ marginLeft: '12px' }}
    submitProps={{
      label: <FormattedMessage id="module-role-addRoles" />,
      onClick: addRolesToUser,
    }}
    onCancel={onCancel}
    showSaveConfirmation={true}
    saveWarningTitle={<FormattedMessage id="module-role-roleWarningTitle" />}
    saveWarningMessage={
      <FormattedMessage id="module-role-roleWarningMessage" />
    }
  />
);

export default AddRoleToUserToolbar;
