import { GraphNetworkNode, UpdatedGraphNetworkNode } from '../types';

export const createNodeWithCorrectLabels = (
  nodes: GraphNetworkNode[],
  groupedRelationsLabel: string,
): UpdatedGraphNetworkNode[] => {
  const newNodes = nodes?.map((newNode, index) => {
    const newName =
      newNode?.label?.toLowerCase() === 'grouped'
        ? groupedRelationsLabel
        : newNode?.label;

    return {
      ...newNode,
      name: newName,
      index,
    };
  });

  return newNodes;
};
