import { useQuery, gql } from '@apollo/client';

export const GET_ENUMERABLE_VALUES = gql`
  query getEnumerableValuesByPropertyAndObjectTypeId(
    $objectTypeId: ID!
    $property: String!
  ) {
    management {
      getEnumerableValuesByPropertyAndObjectTypeId(
        objectTypeId: $objectTypeId
        property: $property
      )
    }
  }
`;

type Options = { objectTypeId: string; property: string };

type QueryResponse = {
  management: {
    getEnumerableValuesByPropertyAndObjectTypeId: {
      Value: string;
      Name: string;
    };
  };
};

export const useQueryEnumerableValuesByPropertyAndObjectTypeId = (
  options: Options,
  skip = false,
) => {
  const { data, loading, error } = useQuery<QueryResponse, Options>(
    GET_ENUMERABLE_VALUES,
    {
      skip,
      variables: options,
      fetchPolicy: 'network-only',
    },
  );

  const enumerableValues =
    data?.management?.getEnumerableValuesByPropertyAndObjectTypeId;

  return [enumerableValues, { loading, error }] as const;
};
