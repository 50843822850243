import { CancelButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { FC } from 'react';

import { EdgeRelationInfoFooterWrapper } from './EdgeRelationInfoFooterWrapper';
import { EdgeRelationInfoFooterInnerWrapper } from './EdgeRelationInfoFooterInnerWrapper';

type EdgeRelationInfoFooterProps = {
  onClose: () => void;
};

export const EdgeRelationInfoFooter: FC<EdgeRelationInfoFooterProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  return (
    <EdgeRelationInfoFooterWrapper themeWhite={themeWhite}>
      <EdgeRelationInfoFooterInnerWrapper>
        <CancelButton
          data-test="entityRelationsGraph-edgePropertiesPanel-closeButton"
          onClick={onClose}
          variant="outlined"
          onConfirm={onClose}
          rounded
          style={{ marginRight: '10px' }}
        >
          <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelCloseButton" />
        </CancelButton>
      </EdgeRelationInfoFooterInnerWrapper>
    </EdgeRelationInfoFooterWrapper>
  );
};
