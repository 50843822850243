import React, { useState } from 'react';
import { NotFoundComponent } from '@cluedin/list-pattern';
import { Add } from 'uxi/Icons';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';

import ConfigurationFilters from '../filters/ConfigurationFilters';

const { integrations } = actionsConfiguration;

const ConfigurationListWithFiltersEmpty = () => {
  const [search, setSearch] = useState('');

  return (
    <>
      <ConfigurationFilters
        query={search}
        configurations={[]}
        onSearchChange={setSearch}
        onIntegrationChange={() => {}}
        onStatusChange={() => {}}
      />
      <NotFoundComponent
        message={
          <FormattedMessage id="module-configuration-noConfigurationAdded" />
        }
        explanation={
          <FormattedMessage id="module-configuration-noConfigurationAdded-expl" />
        }
        noCreate
      />

      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <PrimaryButton
          rounded
          type="primary"
          icon={<Add size={16} />}
          href={'/admin/inbound/integration/applications'}
          actionConfiguration={
            integrations.availableIntegrations.availableIntegration.create
          }
        >
          <FormattedMessage id="module-configuration-addConfiguration" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default ConfigurationListWithFiltersEmpty;
