import { gql } from '@apollo/client';

export const createDataSourceSetMutation = gql`
  mutation createDataSourceSet($dataSourceSet: InputDataSourceSet) {
    inbound {
      createDataSourceSet(dataSourceSet: $dataSourceSet)
    }
  }
`;

export const getAllDataSourceSet = gql`
  query getAllDataSourceSet($page: Int, $pageSize: Int) {
    inbound {
      dataSourceSets(page: $page, pageSize: $pageSize) {
        total
        data {
          id
          name
          author {
            id
            username
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getDataSetSchedulerById = gql`
  query getDataSetSchedulerById($id: ID!) {
    inbound {
      dataSetScheduler(id: $id) {
        name
        author
        createdAt
        updatedAt
      }
    }
  }
`;

export const getDataSourceSetById = gql`
  query getDataSourceSetById($id: ID!) {
    inbound {
      dataSourceSet(id: $id) {
        name
        author {
          id
          username
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const getDataSources = gql`
  query getDataSources($dataSourceSetId: ID!) {
    inbound {
      dataSources(dataSourceSetId: $dataSourceSetId) {
        total
        data {
          id
          type
          name
          author {
            id
            username
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getDataSourceById = gql`
  query getDataSourceById($id: ID!) {
    inbound {
      dataSource(id: $id) {
        id
        canBeDeleted
        name
        author {
          id
          username
        }
        fileMetadata {
          fileName
          processing
          uploading
        }
        createdAt
        type
        dataSourceSet {
          id
          name
        }
      }
    }
  }
`;

export const getDataSets = gql`
  query getDataStets($dataSourceId: ID!) {
    inbound {
      dataSets(dataSourceId: $dataSourceId) {
        total
        data {
          id
          name
          author {
            id
            username
          }
          fieldMappings {
            originalField
            key
          }
          createdAt
          updatedAt
          annotationId
          stats {
            successful
            bytes
          }
        }
      }
    }
  }
`;

export const getDataSetSchedules = gql`
  query getDataSetSchedules($dataSetId: ID!) {
    inbound {
      getDataSetSchedules(dataSetId: $dataSetId) {
        id
        name
        rule
        task
        enabled
        author
      }
    }
  }
`;

/**
 *
 * edge {
 *  dataSetId: ID
 *  edgeType
 * }
 */
export const getDataSetById = gql`
  query getDataSetById($id: ID!) {
    inbound {
      dataSet(id: $id) {
        id
        name
        createdAt
        author {
          id
          username
        }
        fieldMappings {
          originalField
          key
          edges {
            edgeType
            dataSetId
            dataSourceId
            dataSourceGroupId
            entityType
          }
        }
        annotationId
        dataSourceId
        canBeDeleted
        configuration
        elasticTotal
        expectedTotal
        dataSource {
          id
          name
          type
          dataSourceSet {
            id
            name
          }
        }
        originalFields
        stats {
          total
          successful
          failed
          bytes
        }
      }
    }
  }
`;

export const getDataSetContent = gql`
  query getDataSetContent(
    $id: ID!
    $page: Int
    $pageSize: Int
    $terms: String
  ) {
    inbound {
      dataSetContent(id: $id, page: $page, pageSize: $pageSize, terms: $terms)
    }
  }
`;

export const addAnnotationToDataSet = gql`
  mutation addAnnotationToDataSet($dataSetId: ID, $annotationId: ID) {
    inbound {
      addAnnotationToDataSet(dataSetId: $dataSetId, annotationId: $annotationId)
    }
  }
`;

export const commitDataSet = gql`
  mutation commitDataSet($dataSetId: ID) {
    inbound {
      commitDataSet(dataSetId: $dataSetId)
    }
  }
`;

export const addAnnotationMappingToDataSet = gql`
  mutation addAnnotationMappingToDataSet(
    $dataSetId: ID
    $fieldMappings: [InputPropertyMapping]
  ) {
    inbound {
      addAnnotationMappingToDataSet(
        dataSetId: $dataSetId
        fieldMappings: $fieldMappings
      )
    }
  }
`;

export const getAllDataSets = gql`
  query getAllDataSets($annotationId: ID) {
    inbound {
      dataSets(annotationId: $annotationId) {
        total
        data {
          id
          name
          annotationId
          author {
            username
          }

          fieldMappings {
            originalField
            key
            edges {
              edgeType
              dataSetId
              dataSourceId
              dataSourceGroupId
              entityType
            }
          }
          dataSourceId
          originalFields
          updatedAt
          createdAt
          stats {
            bytes
            successful
          }
        }
      }
    }
  }
`;

export default {
  createDataSourceSetMutation,
  getAllDataSourceSet,
  getDataSourceSetById,
  getDataSourceById,
  getDataSources,
  getDataSets,
  addAnnotationToDataSet,
  commitDataSet,
  addAnnotationMappingToDataSet,
  getAllDataSets,
  getDataSetContent,
};
