import React from 'react';
import moment from 'moment';
import { FormattedMessage } from '@cluedin/locale';

const CreatedComponent = (entity) => {
  const date = entity.created;

  if (!date) {
    return (
      <span
        style={{
          fontStyle: 'italic',
          color: '#ccc',
        }}
      >
        <FormattedMessage id="module-entityLists-noValue" />
      </span>
    );
  }

  return <span>{moment(date).fromNow()}</span>;
};

export default CreatedComponent;
