"use strict";

exports.__esModule = true;
exports.HighlightTextFromContext = exports.HighlightTextContext = exports.HighlightText = void 0;
var _HighlightText = _interopRequireDefault(require("./HighlightText"));
exports.HighlightText = _HighlightText.default;
var _HiglightTextContext = require("./HiglightTextContext");
exports.HighlightTextContext = _HiglightTextContext.HighlightTextContext;
var _HighlightTextFromContext = require("./HighlightTextFromContext");
exports.HighlightTextFromContext = _HighlightTextFromContext.HighlightTextFromContext;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }