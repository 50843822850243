import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PopoverArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  left: calc(50% - 10px);
  bottom: 0;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  border-top: solid 17px #fff;
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.1));
  display: inline-block;
`;

const PopoverLabel = styled.div`
  position: absolute;
  bottom: 17px;
  min-width: 200px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
`;

const PopoverContainer = styled.div`
  position: relative;
`;

export const Popover = ({ children }) => {
  return (
    <PopoverContainer>
      <PopoverLabel>{children}</PopoverLabel>
      <PopoverArrow />
    </PopoverContainer>
  );
};

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
