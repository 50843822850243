import styled from 'styled-components';

export const ExplainLogSummaryBox = styled.div`
  background: #ffffff;
  width: 100%;
`;

export const ExplainLogSummaryWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding: 12px 0 0 0;
`;

export const ExplainLogDataWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 100ms ease;
  width: 100%;
  min-width: 100%;
`;
