import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Graph from 'react-graph-vis';
import { useTheme } from '@cluedin/theme';
import uuid from 'uuid/v4';
import { GqlErrorMessages } from '@cluedin/form';

import { EntityCard } from '../../Cards/EntityCard';
import { ShadowEntityCard } from '../../Cards/ShadowEntityCard';
import { EdgeCard } from '../../Cards/EdgeCard';
import GhostGraph from '../../GhostCard/GhostGraph';
import { EdgeCardGrouped } from '../../Cards/EdgeCardGrouped';
import CardPosition from '../../Cards/CardPosition';
import GraphContainer from '../../GraphContainer';
import { useZoomControl } from '../../hooks/useZoomControl';
import { resizeNetwork } from '../../utils';
import { graphConfig } from '../../config';
import { useOrganizationFeatureFlagOption } from '../../../../modules/featureFlag/hooks/useOrganizationFeatureFlag';

const nodeTypesList = [
  {
    name: 'entity',
    label: 'Entity',
  },
  // Temporarily disabled until shadow entities are properly identifiable from BE
  // {
  //   name: 'shadowentity',
  //   label: 'Shadow Entity',
  // },
  {
    name: 'edge',
    label: 'Edge',
  },
];

const truncateString = (label) => {
  if (label?.length > 17) {
    return label.substring(0, 17) + '...';
  }

  return label;
};

const NewEdgeRelation = ({
  entityId,
  keyImage,
  useQueryEntityEdgeSummaryById,
  labels = {
    createdLabel: 'Created',
    directionLabel: 'Direction',
    noNameLabel: 'No Name',
    noEntityLabel: 'No Entity',
  },
  onNodeClick,
  itemsPerRadius = 10,
  radius = 400,
  arrowColor = '#999',
  entityProps = {
    width: 182,
    height: 48,
    background: '#f3f9f9',
    iconBackground: 'rgba(42,162,154, 0.08)',
    entityTypeFontStyle: '10px Segoe UI, sans-serif',
    entityFontStyle: '12px Segoe UI, sans-serif',
    entityFontColor: 'black',
  },
  edgeProps = {
    width: 182,
    height: 48,
    color: '#efd88f',
    backgroundColor: '#ffffff',
    borderColor: '#d7d7d8',
    labelFontStyle: '10px Segoe UI, sans-serif',
    labelFontColor: 'black',
    subTextFontColor: '#9b9b9c',
    subTextFontStyle: '8px Segoe UI, sans-serif',
  },
  shadowEntityProps = {
    width: 182,
    height: 48,
    color: '#d7d7d8',
    backgroundColor: '#ffffff',
    borderColor: '#d7d7d8',
    labelFontStyle: '10px Segoe UI, sans-serif',
    labelFontColor: 'black',
    subTextFontColor: '#9b9b9c',
    subTextFontStyle: '8px Segoe UI, sans-serif',
  },
  setIsGroupedEntitiesPanelOpen,
  selectedNode,
  setNodesAndEdges,
  nodesAndEdges,
  onEdgeClick,
  setIsEdgePropertiesPanelOpen,
}) => {
  const [edgeSummary, loading, error] = useQueryEntityEdgeSummaryById(entityId);

  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const zoomControl = useZoomControl();
  const legendCanvas = useRef(null);

  const [graphNetwork, setGraphNetwork] = useState(null);

  const edgesColorWithTheme = themePrimary;
  const themeEntityProps = {
    ...entityProps,
    borderColor: themePrimary,
    entityTypeFontColor: themePrimary,
  };
  const themeEdgeProps = {
    ...edgeProps,
    borderHoverColor: themePrimary,
  };
  const themeShadowEntityProps = {
    ...shadowEntityProps,
    borderHoverColor: themePrimary,
  };

  const isEntityRelationsNewAPI = useOrganizationFeatureFlagOption(
    'EntityRelationsWithNewAPI',
  );

  const getIcon = (name) => {
    if (!name) return null;

    try {
      const iconFileSVG = require(`@cluedin/svgs/icons/entity-types/${_.kebabCase(
        name,
      )}-ico.svg`);
      const image = new Image();
      image.setAttribute('src', iconFileSVG);
      return image;
    } catch (e) {
      console.log('Error importing icon: ', e);
      return null;
    }
  };

  const idExist = (id, graph) => graph?.filter((g) => g?.id === id)?.length;

  const options = {
    physics: {
      hierarchicalRepulsion: {
        centralGravity: 0,
        nodeDistance: 150,
        avoidOverlap: 0.26,
      },
      minVelocity: 0.75,
      solver: 'hierarchicalRepulsion',
    },
    autoResize: true,
    layout: {
      hierarchical: false,
    },
    interaction: {
      hover: true,
    },
    edges: {
      color: edgesColorWithTheme,
    },
    width: `${Math.round(document.body.clientWidth * 0.92) || 600}px`,
    height: `${Math.round(document.body.clientHeight * 0.92) || 600}px`,
  };

  const generateGraphFromSummary = (data) => {
    let nodes = [];

    const inputEdges = data?.edges?.map((item) => {
      if (!item?.isGrouped) {
        return item;
      }
      return {
        ...item,
        entityId: uuid(),
        name: 'grouped',
      };
    });

    const entityTypes = data && data?.type && data?.type?.split('/');
    const entityType = data?.displayName
      ? data?.displayName
      : entityTypes?.length && entityTypes[entityTypes?.length - 1];

    const edgeSize = inputEdges?.length || 0;
    const levels = Math.floor(edgeSize / itemsPerRadius);
    const customShapePadding = 12;

    let theta = [];

    for (let i = 0; i <= levels; i++) {
      theta[i] = [];
      for (let j = 0; j < itemsPerRadius; j++) {
        theta[i][j] = CardPosition[j].radius - i / Math.PI;
      }
    }

    const radiusPosition = (n) => {
      const level = Math.floor(n / itemsPerRadius);
      const index = n % itemsPerRadius;
      const radiusPosition = theta[level][index];
      const positionX = Math.round(radius * Math.cos(radiusPosition));
      const positionY = Math.round(radius * Math.sin(radiusPosition));

      return {
        x: positionX,
        y: positionY,
        edgeLength: radius * (level * 0.6 + 1),
      };
    };
    !idExist(data?.id, nodes) &&
      nodes?.push({
        id: data?.id,
        label: data?.name,
        title: data?.name,
        group: data?.isGrouped ? data?.group : 'default',
        x: 0,
        y: 0,
        borderWidth: 0,
        isShadowEntity: data?.isShadowEntity,
        borderWidthSelected: 0,
        shape: 'custom',
        ctxRenderer: ({
          ctx,
          id,
          x,
          y,
          state: { selected, hover },
          style,
          label,
        }) => {
          return {
            drawNode() {
              EntityCard({
                ...themeEntityProps,
                label: data?.name
                  ? truncateString(data?.name)
                  : truncateString(data?.id),
                ctx,
                x,
                y,
                entityType: entityType
                  ? truncateString(entityType)
                  : truncateString(labels?.noEntityLabel),
                entityIcon: getIcon(data?.icon),
              });
            },
            nodeDimensions: {
              width: themeEntityProps?.width + customShapePadding,
              height: themeEntityProps?.height + customShapePadding,
            },
          };
        },
      });

    let edges = [];

    inputEdges?.forEach((e, i) => {
      const coordinates = radiusPosition(i);
      !idExist(e?.entityId, nodes) &&
        e?.entityId &&
        nodes?.push({
          id: e?.entityId,
          label: e?.name,
          title: e?.name,
          x:
            isEntityRelationsNewAPI && e?.entityId !== entityId
              ? coordinates?.x - 900
              : coordinates?.x,
          y:
            isEntityRelationsNewAPI && e?.entityId !== entityId
              ? coordinates?.y + 400
              : coordinates?.y,
          shape: 'custom',
          isShadowEntity: e?.isShadowEntity,
          ctxRenderer: ({
            ctx,
            id,
            x,
            y,
            state: { selected, hover },
            style,
            label,
          }) => {
            return {
              drawNode() {
                !e.isGrouped
                  ? EdgeCard({
                      ...themeEdgeProps,
                      coordinates,
                      label: e?.name
                        ? truncateString(e?.name, 20)
                        : truncateString(e?.entityId),
                      ctx,
                      x,
                      y,
                      createdDate: e?.createdDate,
                      createdLabel: truncateString(labels?.createdLabel),
                      icon: getIcon(e?.icon),
                      kind: e?.kind,
                    })
                  : EdgeCardGrouped({
                      ...themeEdgeProps,
                      coordinates,
                      entityCount: e?.entityCount,
                      label: e?.name
                        ? truncateString(e?.name, 20)
                        : truncateString(e?.entityId),
                      ctx,
                      x,
                      y,
                      createdDate: e?.createdDate,
                      createdLabel: truncateString(labels?.createdLabel),
                      icon: getIcon(e?.icon),
                      kind: e?.kind,
                    });
              },
              nodeDimensions: {
                width: themeEdgeProps?.width + customShapePadding,
                height: themeEdgeProps?.height + customShapePadding,
              },
            };
          },
        });

      const direction =
        e?.direction === 'Outgoing'
          ? { from: data?.id, to: e?.entityId }
          : { from: e?.entityId, to: data?.id };

      const edgeExist = edges?.filter(
        (edge) => edge?.from === direction?.from && edge?.to === direction?.to,
      )?.length;

      if (!edgeExist) {
        edges?.push({
          ...direction,
          groupedEntityIds: e?.groupedEntityIds,
          arrowStrikethrough: false,
          label: e?.edgeType,
          length: coordinates?.edgeLength,
          color: {
            color: arrowColor,
            hover: themeEdgeProps?.borderHoverColor,
            inherit: 'true',
          },
          isShadowEntity: e?.isShadowEntity,
          hoverWidth: 1,
          font: {
            color: arrowColor,
            size: 12,
          },
        });
      } else {
        edges = edges?.map((edge) => {
          if (
            edge?.from === direction?.from &&
            edge?.to === direction?.to &&
            edge?.edgeType !== e?.edgeType
          ) {
            return {
              ...edge,
              label: `${edge?.label}\n\n${e?.edgeType}`,
            };
          }
          return edge;
        });
      }
    });

    let smoothOptions = {
      smooth: {
        enabled: false,
      },
    };
    if (isEntityRelationsNewAPI && edges?.length <= 100) {
      smoothOptions = {
        smooth: {
          enabled: true,
          type: 'discrete',
          roundness: 0.25,
        },
      };
    }

    const edgesWithStyling = edges?.map((edge) => ({
      ...edge,
      ...smoothOptions,
    }));

    const visGraph = {
      nodes,
      edges: edgesWithStyling,
    };

    return visGraph;
  };

  useEffect(() => {
    if (!loading && !error) {
      const data = generateGraphFromSummary(edgeSummary);
      setGraphNetwork(data);
    }
  }, [loading, error, edgeSummary]);

  const getNodeInfo = (id) =>
    edgeSummary?.id === id
      ? edgeSummary
      : (edgeSummary?.edges || [])?.filter((e) => e?.entityId === id)[0];

  const getEdgeInfo = (edge) =>
    edgeSummary?.id === edge
      ? edgeSummary
      : (edgeSummary?.edges || [])?.filter((e) => e?.entityId === edge)[0];

  const events = {
    click: (networkEvent) => {
      const nodeId = networkEvent.nodes[0];
      const node = getNodeInfo(nodeId);

      const edgeId = networkEvent?.edges[0];
      const edge = getEdgeInfo(edgeId);

      if (node) {
        onNodeClick(node, networkEvent);
      }
      if (!node && nodeId) {
        const nodeById = nodesAndEdges?.edges?.filter(
          (e) => e.from === nodeId || e.to === nodeId,
        )[0];
        setIsGroupedEntitiesPanelOpen(nodeById, networkEvent);
      }
      if (!node && !nodeId && edgeId) {
        onEdgeClick(networkEvent?.edges);
      }
    },
    hoverNode: (networkEvent) => {
      document.documentElement.style.cursor = 'pointer';
    },
    blurNode: (networkEvent) => {
      document.documentElement.style.cursor = 'default';
    },
    hoverEdge: (networkEvent) => {
      document.documentElement.style.cursor = 'pointer';
    },
    blurEdge: (networkEvent) => {
      document.documentElement.style.cursor = 'default';
    },
  };

  const legendNodes = async () => {
    const canvas = legendCanvas?.current;

    if (!canvas?.hasChildNodes()) {
      let ctx;

      const cardTypes = async (type, index) => {
        switch (type?.name) {
          case 'entity':
            EntityCard({
              ...themeEntityProps,
              entityTypeFontStyle: '10px Segoe UI, sans-serif',
              width: 202,
              height: 52,
              label: type?.label,
              ctx,
              x: 190,
              y: 28,
              // entityIcon: getIcon('circle'),
            });
            break;
          case 'shadowentity':
            ShadowEntityCard({
              ...themeShadowEntityProps,
              labelFontStyle: '12px Segoe UI, sans-serif',
              width: 202,
              height: 52,
              label: type?.label,
              ctx,
              x: 190,
              y: 28,
              // icon: getIcon('circle'),
            });
            break;
          case 'edge':
            EdgeCard({
              ...themeEdgeProps,
              labelFontStyle: '12px Segoe UI, sans-serif',
              width: 202,
              height: 52,
              label: type?.label,
              ctx,
              x: 190,
              y: 28,
              // icon: getIcon('circle'),
            });
            break;
          default:
            EntityCard({
              ...themeEntityProps,
              entityTypeFontStyle: '10px Segoe UI, sans-serif',
              width: 202,
              height: 52,
              label: type?.label,
              ctx,
              x: 150,
              y: 28,
              // entityIcon: getIcon('circle'),
            });
            break;
        }
      };

      // Temporarily disabling relations' legend
      // nodeTypesList?.map((type, index) => {
      //   const canvasNode = document.createElement('canvas');
      //   ctx = canvasNode?.getContext('2d');
      //   canvasNode.width = 300;
      //   canvasNode.height = 60;
      //   ctx = cardTypes(type, index);
      //   canvas.appendChild(canvasNode);
      // });

      return ctx;
    }
  };

  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  const legendHeight = 64 * nodeTypesList?.length + 5;

  return (
    <div>
      {!graphNetwork ? (
        <GhostGraph />
      ) : (
        <GraphContainer
          graph={graphNetwork}
          loading={loading}
          // Temporarily disabling relations' legend
          // legendCanvas={legendCanvas}
          // legendPosition={{
          //   right: 100,
          //   top: 20,
          //   height: legendHeight,
          // }}
          graphType="relation"
        >
          <Graph
            graph={graphNetwork}
            options={options}
            events={events}
            getNetwork={(network) => {
              setGraphNetwork(network);
              setNodesAndEdges(graphNetwork);

              if (network) {
                network?.on('zoom', (zoomLevel) => {
                  zoomControl(zoomLevel?.scale, network);
                });

                window.setTimeout(() => {
                  network.on('afterDrawing', (ctx) => {
                    legendNodes();
                  });
                }, 600);

                window.setTimeout(() => {
                  resizeNetwork(network); // forcing redraw in order to fix a bug when rendering icons
                }, 200);

                window.setTimeout(() => {
                  network?.setData(graphNetwork);
                }, 100);

                window.setTimeout(() => {
                  network?.fit(graphConfig?.fit);
                }, 600);
              }
            }}
          />
        </GraphContainer>
      )}
    </div>
  );
};

export default NewEdgeRelation;
