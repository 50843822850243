import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Select } from '@cluedin/form';
import PropTypes from 'prop-types';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import { useSearchQuery } from '../../../../hooks/useSearchQuery';

const EntityPropertiesLookupInput = ({
  setEntityInputValue,
  vocabularyValue,
  vocabularyKey,
  defaultPageSize = 20,
}) => {
  const [lookupValue, setLookupValue] = useState({
    label: vocabularyValue,
    value: vocabularyValue,
  });
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [searchName, setSearchName] = useState();
  const [hasDuplicate, setHasDuplicate] = useState(false);
  const [results, searchExtra] = useSearchQuery({
    query: searchName ? `*${searchName}*` : '*',
    terms: [{ id: vocabularyKey.glossaryTermId }],
    sort: 'NAME',
    sortDirection: 'ASCENDING',
    pageSize,
  });

  useEffect(() => {
    let currentHasDuplicate = false;
    let previousName;
    for (let entity of results) {
      if (entity?.name === previousName) {
        currentHasDuplicate = true;
        break;
      }
      previousName = entity.name;
    }
    if (currentHasDuplicate != hasDuplicate) {
      setHasDuplicate(currentHasDuplicate);
    }
  }, [results]);

  useEffect(() => {
    setEntityInputValue(lookupValue);
  }, [lookupValue]);

  const onLoadMore = useCallback(
    () => setPageSize((p) => p + defaultPageSize),
    [defaultPageSize],
  );

  const totalLookupValues = useMemo(() => searchExtra?.total, [searchExtra]);

  const loadMore = useMemo(() => {
    if (totalLookupValues <= pageSize) return;

    return {
      onClick: onLoadMore,
    };
  }, [totalLookupValues, pageSize, onLoadMore]);

  const options = useMemo(() => {
    return results.map((entity) => ({
      key: entity.id,
      value: entity.originEntityCode,
      label: entity.name,
    }));
  }, [results]);

  return (
    <>
      {hasDuplicate && (
        <Alert type="warning">
          <FormattedMessage id="module-entityProperties-duplicateLookup" />
        </Alert>
      )}
      <Select
        value={lookupValue}
        isSearchable={true}
        total={totalLookupValues}
        onInputChange={setSearchName}
        isLoading={searchExtra.loading}
        loadMore={loadMore}
        options={options}
        onChange={(e) => {
          setLookupValue(e);
        }}
      />
    </>
  );
};

export default EntityPropertiesLookupInput;

EntityPropertiesLookupInput.propTypes = {
  setEntityInputValue: PropTypes.func,
  vocabularyValue: PropTypes.string,
};
