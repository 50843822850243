import { apiRequest } from '../core/helpers/request';
import { toVM } from './viewModel';
import { personEntityTypePoolSearchFilters } from './entityTypes/person/helper';

/* eslint-disable no-underscore-dangle */
export const getEntity = (id) =>
  apiRequest('GET', `api/entity?id=${id}`).then((resp) =>
    toVM(resp, false, resp.__token),
  );

export const fetchPersonEntities = ({
  query,
  includeUnstructuredData,
  includeExternalData,
}) => {
  const withIncludeUnstructureData = includeUnstructuredData
    ? '&includeUnstructuredData=true'
    : '';
  const withIncludeExternalData = includeExternalData
    ? '&includeExternalData=true'
    : '';
  const withExtraSearch = `${withIncludeUnstructureData}${withIncludeExternalData}`;

  return apiRequest(
    'GET',
    `api/search?q=${
      query || '*'
    }${withExtraSearch}&type=${personEntityTypePoolSearchFilters.join(' OR ')}`,
  )
    .then((searchResult) => searchResult.Hits)
    .then((hits) => hits.map((entity) => toVM(entity)));
};

export const getMessagesForEntity = (entityId) =>
  apiRequest('GET', `api/v1/gdprmessages?id=${entityId}`);

export const anonymiseProperty = ({ value }) =>
  apiRequest('POST', `api/property/anonymise?value=${encodeURI(value)}`);

export const deAnonymiseProperty = ({ value }) =>
  apiRequest('POST', `api/property/deanonymise?value=${encodeURI(value)}`);

export const getHistory = (id) =>
  apiRequest('GET', `api/entity/history?id=${id}`).then((resp = {}) => {
    /**
     * Because reasons
     * history API send an object if only one verion is available,
     * but sends an array if there are multiple version...
     */
    // console.log('resp', resp);
    const { versionHistory } = resp;

    const DatResponse = resp;

    const isArray = Array.isArray(versionHistory.version);
    // console.log('DatResponse', DatResponse);
    // console.log('DatResponse.versionHistory', DatResponse.versionHistory);
    // console.log('DatResponse.versionHistory.version', DatResponse.versionHistory.version);
    // console.log('DatResponse.version isArray', isArray);

    if (
      DatResponse &&
      DatResponse.versionHistory &&
      DatResponse.versionHistory.version
    ) {
      if (isArray === false) {
        // console.log('case 2 MOFO');
        const makingArray = {
          versionHistory: {
            version: [{ version: { ...DatResponse.versionHistory.version } }],
          },
        };
        // console.log('makingArray', makingArray);

        return makingArray;
      }
    }

    // console.log('DatResponse', DatResponse);

    return DatResponse;
  });

export const externalSearch = (entity) =>
  apiRequest(
    'POST',
    `api/v1/organization/admin/commands/externalsearch/entity?id=${entity.id}`,
  );

export const reProcess = (entity) =>
  apiRequest(
    'POST',
    `api/v1/organization/admin/commands/process/entity?id=${entity.id}`,
  );
