import { handleActions } from 'redux-actions';
import { resetPwdResetActions } from '../actions/resetPwd';

const initialState = {
  isFetching: false,
  done: false,
  error: false,
};

export default handleActions(
  {
    [resetPwdResetActions.request]: (state) => ({
      ...state,
      isFetching: true,
      done: false,
      error: false,
    }),
    [resetPwdResetActions.receive]: (state) => ({
      ...state,
      isFetching: false,
      done: true,
      error: false,
    }),
    [resetPwdResetActions.invalid]: (state) => ({
      ...state,
      isFetching: false,
      done: false,
      error: true,
    }),
  },
  initialState,
);
