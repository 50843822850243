import { ComponentType } from 'react';
import { withWorkflow } from '@cluedin/form';

import { EntityGroupedRelationInfo } from './EntityGroupedRelationInfo';
import { GroupedNode } from '../types';

type EntityGroupedRelationPanelProps = {
  entityId: string;
  groupedNode: GroupedNode;
  openGroupedRelationPanelWorkflow: () => void;
};

type ChildEntityGroupedRelationPanelProps = EntityGroupedRelationPanelProps & {
  openWorkflow: () => void;
};

const EntityGroupedRelationPanelWrapper =
  (Comp: ComponentType<EntityGroupedRelationPanelProps>) =>
  (props: ChildEntityGroupedRelationPanelProps) => {
    const { openWorkflow, ...restProps } = props;
    return (
      <Comp {...restProps} openGroupedRelationPanelWorkflow={openWorkflow} />
    );
  };

export const withEntityGroupedRelationPanelWorkflow = (
  Comp: ComponentType<EntityGroupedRelationPanelProps>,
) =>
  withWorkflow(
    EntityGroupedRelationPanelWrapper(Comp),
    EntityGroupedRelationInfo,
    {
      withCustomHeader: true,
      panelType: 'custom',
      width: 1300,
    },
  );
