"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(NativeSelect) {
  return (0, _styledComponents.default)(NativeSelect)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  &.select.overide {\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    user-select: none;\n    min-width: 16px; //doesn't collapse\n    cursor: pointer;\n    padding-right: 34px;\n  }\n  &:focus: {\n    background-color: red;\n    border-radius: 0;\n  }\n  &::-ms-expand {\n    display: none;\n  }\n  &.disabled {\n    cursor: default;\n  }\n  &[multiple] {\n    height: auto;\n  }\n  &:not([multiple]) option,\n  &:not([multiple]) optgroup {\n    background-color: blue;\n  }\n  + .selectIcon {\n    // We use a position absolute over a flexbox in order to forward the pointer events\n    // to the input and to support wrapping tags..\n    position: absolute;\n    right: 9px;\n    top: calc(50% - 8px);\n    pointer-events: none; // Don't block pointer events on the select under the icon.\n    color: ", ";\n    &.disabled {\n      color: yellow;\n    }\n  }\n  & svg.iconOpen {\n    transform: rotate(180deg);\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.primary.main;
  });
};
exports.default = _default;