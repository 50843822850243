import styled from 'styled-components';

export const RecordPanelHeaderDetailWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

export const RecordPanelHeaderDetailTitle = styled.div`
  color: ${({ themePrimary }) => themePrimary ?? 'rgb(41, 162, 154)'};
  font-size: 16px;
  font-weight: bold;
`;

export const RecordPanelHeaderDetailTimeStamps = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
`;

export const RecordPanelHeaderDetailTimeStamp = styled.div`
  color: #9b9b9c;
`;

export const RecordPanelHeaderDetailSourceWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
`;
