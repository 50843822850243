"use strict";

exports.__esModule = true;
exports.refreshToken = void 0;
var _index = require("./index");
var _config = require("../config");
var refreshToken = function refreshToken(token) {
  return (0, _index.authWithoutCreds)('POST', 'connect/token', {
    grant_type: 'refresh_token',
    refresh_token: token,
    client_id: _config.clientId
  });
};
exports.refreshToken = refreshToken;