import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import StreamIcon from './components/composites/StreamIcon';

const { consume } = actionsConfiguration;

export default {
  pillar: 'consume',
  name: 'streams',
  hideModule: true,
  displayName: <FormattedMessage id="streams" />,
  path: '/streams',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: StreamIcon,
        name: <FormattedMessage id="streams" />,
        link: '/admin/consume/streams',
        claims: consume.streams.stream.view,
      },
    ],
  },
  routes,
};
