import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const CHANGE_ENTITY_PROPERTY_VALUE = gql`
  mutation changeEntityPropertyValue(
    $entityId: ID!
    $vocabularyKey: String!
    $value: String!
    $changeType: String!
  ) {
    virtualization {
      changeEntityPropertyValue(
        entityId: $entityId
        vocabularyKey: $vocabularyKey
        value: $value
        changeType: $changeType
      )
    }
  }
`;

export const useChangeEntityPropertyValue = ({ entityId, vocabularyKey }) => {
  const [changeEntityPropertyValue, { data, loading, error }] = useMutation(
    CHANGE_ENTITY_PROPERTY_VALUE,
  );

  return [
    (value, changeType) => {
      return changeEntityPropertyValue({
        variables: { entityId, vocabularyKey, value, changeType },
      });
    },
    {
      data,
      loading,
      error,
    },
  ];
};
