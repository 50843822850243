import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Spinner } from '@cluedin/form';
import { Flex } from 'uxi/Layout';

const ConfigurationScheduleStatusWithVisual = (configuration) => {
  let state;

  if (configuration?.status) {
    state = configuration?.status;
  } else {
    state =
      configuration?.Stats &&
      configuration?.Stats.ProviderStatus &&
      configuration?.Stats.ProviderStatus.State;
  }

  switch (state) {
    case 'Idle':
      return (
        <FormattedMessage
          id="module-configuration-statusIdle"
          defaultMessage="Idle"
        />
      );

    case 'Queued':
      return (
        <FormattedMessage
          id="module-configuration-statusQueued"
          defaultMessage="Queued"
        />
      );

    case 'Crawling':
      return (
        <Flex style={{ justifyContent: 'left' }}>
          <Spinner />
          <small>
            <FormattedMessage
              id="module-configuration-statusCrawling"
              defaultMessage="Crawling"
            />
          </small>
        </Flex>
      );

    case 'Processing':
      return (
        <FormattedMessage
          id="module-configuration-statusProcessing"
          defaultMessage="Processing"
        />
      );
    case 'Finished':
      return (
        <FormattedMessage
          id="module-configuration-statusFinished"
          defaultMessage="Finished"
        />
      );

    default:
      return '–';
  }
};

export default ConfigurationScheduleStatusWithVisual;
