"use strict";

exports.__esModule = true;
exports.getTransitionProps = getTransitionProps;
exports.reflow = void 0;
var reflow = function reflow(node) {
  return node.scrollTop;
};
exports.reflow = reflow;
function getTransitionProps(props, options) {
  var timeout = props.timeout,
    _props$style = props.style,
    style = _props$style === void 0 ? {} : _props$style;
  return {
    duration: style.transitionDuration || typeof timeout === 'number' ? timeout : timeout[options.mode] || 0,
    delay: style.transitionDelay
  };
}