import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import differenceWith from 'lodash/differenceWith';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import { useSearch } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { List } from '@cluedin/list-pattern';
import { useQueryAllUsers } from '../../../../../user/hooks';
import { useGrantUsersPermission } from '../../../../hooks/useGrantUsersPermission';
import { columns } from './columns';

const AddUsers = ({
  values: connector,
  onClose,
  history,
  location,
  refetch,
}) => {
  const usersWithAccess = get(connector, 'users', []);
  const [allUsers, loading, error] = useQueryAllUsers();
  const [grantPermissions, loadingGrant, errorGrant, doneGrant] =
    useGrantUsersPermission();
  const [searchTerm, setSearchTerm] = useSearch(history, location, {
    searchName: 'allUserSearch',
  });
  const [searchName, setSearchName] = useState(searchTerm);
  const usersWithoutAccess = differenceWith(
    allUsers,
    usersWithAccess,
    (a, b) => a.id === b.id,
  );
  const data = usersWithoutAccess.filter((u) =>
    includes(toLower(u.username), toLower(searchTerm)),
  );

  useEffect(() => {
    if (!loadingGrant && doneGrant) {
      if (refetch) {
        refetch();
      }
      onClose();
    }
  }, [loadingGrant, doneGrant, refetch]);

  return (
    <List
      dataGridName="user-grant-access"
      data={data}
      columns={columns}
      searchName={searchName}
      onChangeSearch={setSearchName}
      onSubmitSearch={setSearchTerm}
      offsetTop={100}
      actions={[
        {
          label: <FormattedMessage id="module-connector-grantAccess" />,
          action: (userIds) => {
            grantPermissions(connector, userIds);
          },
        },
      ]}
      loading={loading}
      error={error || errorGrant}
      notFoundProps={{
        noCreate: true,
        message: <FormattedMessage id="module-connector-noMoreUsers" />,
        explanation: (
          <FormattedMessage id="module-connector-noMoreUsersExplanation" />
        ),
      }}
      hasFilters={!!searchTerm}
      noSearchFoundProps={{
        noCreate: true,
        message: <FormattedMessage id="module-connector-noUsersFound" />,
        explanation: (
          <FormattedMessage id="module-connector-noUsersFoundExplanation" />
        ),
      }}
    />
  );
};

export default withRouter(AddUsers);
