import React from 'react';
import Section from './Section';

const DataBreach = ({ jsonReport }) => {
  const { Breaches } = jsonReport;
  return (
    <Section jsonReport={jsonReport}>
      <h2>Reported Data Breach:</h2>
      {Breaches && Breaches.length > 0 && Breaches.map(() => null)}
    </Section>
  );
};

export default DataBreach;
