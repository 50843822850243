import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useWatch } from 'react-hook-form';
import {
  Modal,
  CancelButton,
  PrimaryButton,
  FormGenerator,
  useFormGeneratorWithReactHook,
} from '@cluedin/form';

import { useGoldenRecordList } from '../../../GoldenRecordListContext';
import { RecordListSaveSearchFormFields } from './RecordListSaveSearchFormFields';

const RecordListSaveSearchModal = ({ opened, handleClose, hideFilter }) => {
  const intl = useIntl();

  const {
    viewName,
    filterOptions: { onApplySearch, handleSaveSearch },
    viewOptions: { columns, viewMode, getColumnsForViewName },
    listOptions: { filterUrl },
  } = useGoldenRecordList();

  const { fields, control, handleSubmit, errors, touched } =
    useFormGeneratorWithReactHook(RecordListSaveSearchFormFields, {
      name: '',
      shared: false,
    });

  const name = useWatch({
    control,
    name: 'name',
  });

  const onSave = handleSubmit(async (data) => {
    const savedSearch = await handleSaveSearch({
      ...data,
      viewMode,
      filterUrl,
      columnsConfig: {
        [viewMode]: columns,
        [viewMode === 'tile' ? 'table' : 'tile']:
          viewMode === 'tile'
            ? getColumnsForViewName(`${viewName}-table`)
            : getColumnsForViewName(`${viewName}-tile`),
      },
    });

    onApplySearch(savedSearch.data.administration.saveSearch);

    hideFilter();
    handleClose();
  });

  return (
    <Modal
      onClose={handleClose}
      isOpen={opened}
      width="450px"
      height="270px"
      title={intl.formatMessage({ id: 'module-goldenRecordList-save-search' })}
      footer={{
        style: { gap: '8px' },
        content: (
          <>
            <CancelButton
              rounded
              onClick={handleClose}
              text={intl.formatMessage({
                id: 'module-goldenRecordList-cancel',
              })}
            />
            <PrimaryButton
              rounded
              onClick={onSave}
              disabled={!!errors?.name || !name}
              text={intl.formatMessage({ id: 'module-goldenRecordList-save' })}
            />
          </>
        ),
      }}
    >
      <FormGenerator
        fields={fields}
        control={control}
        errors={errors}
        touched={touched}
      />
    </Modal>
  );
};

export default memo(RecordListSaveSearchModal);
