import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import SignupUserWithEmailDomainForm from '../composites/SignupUserWithEmailDomainForm';
import { clientId } from '../../../../config';
import {
  // withIsFetching,
  withInvalid,
  withSuccess,
} from '../../../core/components/Hocs/defaults';
import { shouldFetchSignUpOrgEmail } from '../../actions/saveOrgEmail';
import { shoudFetchPublicOrgInfo } from '../../../public/actions/public';

const EnhancedSignupUserWithEmailDomainFormContainer = compose(
  lifecycle({
    componentDidMount() {
      const { fetchTeamDomain, isFetchingPublic, teamDomain } = this.props;
      if (fetchTeamDomain && !isFetchingPublic && !teamDomain) {
        fetchTeamDomain();
      }
    },
  }),
  // withIsFetching(),
  branch(
    (props) => props.isFetchingPublic,
    renderComponent(() => 'loading...'),
  ),
  withInvalid({
    InvalidEntityMessage: (
      <div>
        <FormattedMessage id="module-public-disabledOrganization" />
      </div>
    ),
  }),
  withSuccess({
    SuccessMessage: (
      <div>
        <FormattedMessage id="module-public-checkInbox" />
      </div>
    ),
  }),
)(SignupUserWithEmailDomainForm);

export const mapStateToProps = ({
  saveOrgEmail: { isFetching, done, error },
  publicModule: { result, invalid, isFetching: isFetchingPublic },
}) => ({
  isFetchingPublic,
  isMutating: isFetching || !result,
  isFetching: isFetchingPublic,
  done,
  error,
  invalid: invalid || (result ? !result.IsEmailDomainSignupActivated : false),
  teamDomain: result,
});

export const mapDispatchToProps = (dispatch) => ({
  onSignUpOrgEmail: (signUpUserByDomainEmail) =>
    dispatch(
      shouldFetchSignUpOrgEmail({
        ...signUpUserByDomainEmail,
        clientId,
      }),
    ),
  fetchTeamDomain: () => {
    if (clientId) {
      dispatch(shoudFetchPublicOrgInfo(clientId));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnhancedSignupUserWithEmailDomainFormContainer);
