import { gql, useMutation } from '@apollo/client';
import { CleanProjectDetail } from '../types';

export const CREATE_CLEAN_PROJECT = gql`
  mutation createNewCleanProject($cleanProject: InputNewCleanProject!) {
    preparation {
      id
      createNewCleanProject(cleanProject: $cleanProject) {
        id
      }
    }
  }
`;

type CreateCleanProjectRes = {
  preparation: {
    createNewCleanProject: CleanProjectDetail;
  };
};

export const useCreateCleanProject = <T>() => {
  const [create, { loading, error }] = useMutation<
    CreateCleanProjectRes,
    { cleanProject: T }
  >(CREATE_CLEAN_PROJECT);

  const createCleanProject = async (cleanProject: T) => {
    return create({
      variables: {
        cleanProject,
      },
    });
  };

  return {
    error,
    loading,
    createCleanProject,
  };
};
