import routes, { getLink } from './routes';

export default {
  pillar: 'testing',
  name: 'entity-viewers',
  displayName: 'Entity Viewers',
  path: '/entity-viewers',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'list',
        name: 'Entity Viewers',
        link: getLink.home(),
      },
    ],
  },
  routes,
};
