import { useState } from 'react';
import toast from 'cogo-toast';
import { useIntl } from 'react-intl';

import { useDeleteSavedSearch } from './useDeleteSavedSearch';
import { useSaveSearch } from './useSaveSearch';
import { useEditSearch } from './useEditSearch';
import { useQuerySavedSearches } from './useQuerySavedSearches';
import { useQuerySharedSavedSearches } from './useQuerySharedSavedSearches';

type ColumnsConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tile: any[];
};

export type SavedSearch = {
  filterUrl: string;
  id: string;
  name: string;
  created: string;
  createdBy: string;
  shared?: boolean;
  viewMode: 'tile' | 'table';
  columnsConfig: ColumnsConfig;
};

export type CreateSavedSearchPayload = {
  filterUrl: string;
  name: string;
  viewMode: string;
  columnsConfig: ColumnsConfig;
};

export type EditSavedSearchPayload = {
  name: string;
  shared: boolean;
  viewMode: string;
  filterUrl: string;
  columnsConfig: ColumnsConfig;
};

export const useSavedSearch = ({ viewName }: { viewName: string }) => {
  const { formatMessage } = useIntl();

  const [savedSearchesPage, setSavedSearchesPage] = useState(1);

  const { savedSearches, refetch, loadingSavedSearches, totalSavedSearches } =
    useQuerySavedSearches({
      viewName,
      page: savedSearchesPage - 1,
    });

  const {
    sharedSavedSearches,
    refetchSharedSavedSearches,
    loadingSharedSavedSearches,
    totalsSharedSavedSearches,
  } = useQuerySharedSavedSearches({
    viewName,
    page: savedSearchesPage - 1,
  });

  const { deleteSavedSearch } = useDeleteSavedSearch();

  const { saveSearch } = useSaveSearch<CreateSavedSearchPayload>({ viewName });

  const { editSearch } = useEditSearch<EditSavedSearchPayload>({
    viewName,
  });

  const refetchSavedSearches = async () => {
    await refetch();
    await refetchSharedSavedSearches();
  };

  const displayToast = (
    localeId: string,
    type: 'success' | 'error' = 'success',
  ) => {
    toast[type](formatMessage({ id: localeId }), {
      position: 'bottom-right',
      hideAfter: 5,
    });
  };

  const handleDeleteSearch = async (searchId: string) => {
    try {
      await deleteSavedSearch(searchId);

      refetchSavedSearches();

      displayToast(
        formatMessage({
          id: 'module-goldenRecordList-delete-search-req-success',
        }),
      );
    } catch {
      displayToast(
        formatMessage({
          id: 'module-goldenRecordList-delete-search-req-error',
        }),
        'error',
      );
    }
  };

  const handleSaveSearch = async (payload: CreateSavedSearchPayload) => {
    try {
      const savedSearchId = await saveSearch(payload);

      refetchSavedSearches();

      displayToast(
        formatMessage({
          id: 'module-goldenRecordList-save-search-req-success',
        }),
      );

      return savedSearchId;
    } catch {
      displayToast(
        formatMessage({ id: 'module-goldenRecordList-save-search-req-error' }),
        'error',
      );
    }
  };

  const handleEditSearch = async (
    id: string,
    payload: EditSavedSearchPayload,
  ) => {
    try {
      const editedSearch = await editSearch(id, payload);

      displayToast(
        formatMessage({
          id: 'module-goldenRecordList-edit-search-req-success',
        }),
      );

      return editedSearch;
    } catch {
      displayToast(
        formatMessage({ id: 'module-goldenRecordList-edit-search-req-error' }),
        'error',
      );
    }
  };

  return {
    savedSearches,
    handleEditSearch,
    handleSaveSearch,
    savedSearchesPage,
    handleDeleteSearch,
    totalSavedSearches,
    sharedSavedSearches,
    refetchSavedSearches,
    setSavedSearchesPage,
    totalsSharedSavedSearches,
    loadingSavedSearches: loadingSavedSearches || loadingSharedSavedSearches,
  };
};
