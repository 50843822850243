import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import { Gdpr } from 'uxi/Icons';

const { governance } = actionsConfiguration;

export default {
  pillar: 'governance',
  name: 'compliance',
  displayName: <FormattedMessage id="compliance" />,
  path: '/gdpr',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Gdpr,
        name: <FormattedMessage id="compliance" />,
        link: '/admin/governance/gdpr',
        claims: governance.gdpr.subjectAccessRequest.view,
      },
    ],
  },
  routes,
};
