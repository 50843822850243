import { useState } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import ImagePreview from '../../../components/composites/ImagePreview';
import FullScreenDialog from './FullScreenDialog';
import EntityHtmlPreview from '../../../components/composites/EntityHtmlPreview';
import TweetEmbed from './TweetEmbed';
import {
  previewPropertiesPool,
  findOnePropertyValue,
} from '../../../../wms/helpers/entityPropertyConfig';
import {
  WidgetContainer,
  WidgetHeader,
  WidgetFullScreen,
} from '../../../components/composites/widgets';

const frameStyle = {
  width: '100%',
  height: '400px',
  border: 'none',
};

const EntityPreviewV2 = ({ entity }) => {
  let content;
  let hasFullScreen;
  const [isDialogOpen, setDialogOpen] = useState(false);

  const onClose = () => {
    setDialogOpen(false);
  };

  const emailHtmlContent =
    entity && entity.data && entity.data.properties
      ? findOnePropertyValue(entity.data.properties, previewPropertiesPool)
      : undefined;

  if (emailHtmlContent) {
    content = <EntityHtmlPreview value={emailHtmlContent} />;
  }

  const tweetUrl =
    entity?.data?.properties['property-twitter.status.detail.url'];

  if (tweetUrl) {
    const parts = tweetUrl.split('/');

    if (parts && parts.length > 0) {
      const tweetId = parts[parts.length - 1];
      content = <TweetEmbed id={tweetId} />;
    }
  }

  const channelName =
    entity?.data?.properties['property-slack.message.channelName'];

  if (channelName) {
    content = (
      <div>
        <div style={{ padding: '15px' }}>{entity.data.description}</div>
        <div style={{ padding: '15px' }}>
          In channel <strong>#{channelName}</strong>
        </div>
      </div>
    );
  }

  if (entity.embedUrl) {
    hasFullScreen = true;
    content = (
      <div>
        <FullScreenDialog
          onClose={onClose}
          open={isDialogOpen}
          name={entity.name}
          url={entity.embedUrl}
        />
        <div style={{ height: '400px' }}>
          <iframe
            title={`Embed ${entity.name} content`}
            style={frameStyle}
            src={entity.embedUrl}
          />
        </div>
      </div>
    );
  } else if (entity.hasPreview) {
    hasFullScreen = true;
    content = (
      <div>
        <FullScreenDialog
          onClose={onClose}
          open={isDialogOpen}
          name={entity.name}
          url={entity.previewUrl}
        />
        <ImagePreview previewUrl={entity.previewUrl} alt={entity.name} />
      </div>
    );
  } else if (entity.hasLogo) {
    hasFullScreen = true;

    content = (
      <div>
        <FullScreenDialog
          onClose={onClose}
          open={isDialogOpen}
          name={entity.name}
          url={entity.logoUrl}
        />
        <ImagePreview previewUrl={entity.logoUrl} alt={entity.name} />
      </div>
    );
  } else if (!content) {
    if (entity && entity.data && entity.data.description) {
      if (/<[a-z][\s\S]*>/i.test(entity.data.description)) {
        content = <EntityHtmlPreview value={entity.data.description} />;
      } else {
        content = (
          <div style={{ padding: '16px' }}>{entity.data.description}</div>
        );
      }
    } else {
      content = (
        <div style={{ padding: '16px' }}>
          <FormattedMessage id="module-entityWidget-noPreview" />
        </div>
      );
    }
  }

  return (
    <WidgetContainer style={{ margin: '24px' }}>
      <WidgetHeader
        title={<FormattedMessage id="module-entityWidget-preview" />}
        menu={
          hasFullScreen && (
            <WidgetFullScreen
              onClick={() => {
                setDialogOpen(true);
              }}
            />
          )
        }
      />
      <>{content}</>
    </WidgetContainer>
  );
};

export default EntityPreviewV2;
