import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '@cluedin/form';
import Alert from 'uxi/Alert';
import Dialog from 'uxi/Dialog';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
} from '@cluedin/locale';
import { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import { TextField } from 'uxi/Input';
import { withCreateAddProject } from '../../hocs/withCreateAddProject';
import { withCreateAnnotation } from '../../../../annotation/components/hocs/withCreateAnnotation';
import AnnotationFromEntityType from '../../../../annotation/components/containers/AnnotationFromEntityType';
import { withSchema } from '../../../../schema/components/Hocs/withSchema';
import { createAnnotationForVocab } from '../../../../annotation/helpers';
import { withIntegration } from '../../../../integration/components/hocs/withIntegration';
import { findSource } from '../../../../integration/selector';
import { usePrevious } from '../../../../core/hooks';

const formatName = (str = '') => str.replace(' ', '').toLowerCase();

const CleanGqlQueryDialogContainer = ({
  isOpen,
  onClose,
  gqlOptions = {},
  searchStat,
  createCleanProject,
  loading,
  projectId,
  errorCreating,
  redirectToCleanProject,
  createAnnotation,
  schema,
  doneSaving,
  annotationId,
  integrations,
  saving,
  intl,
}) => {
  const [projectName, setProjectName] = useState('');

  const prevId = usePrevious(projectId);
  const prevDoneSaving = usePrevious(doneSaving);

  useEffect(() => {
    if (projectId && prevId !== projectId) {
      redirectToCleanProject(projectId);
    }
  }, [projectId, prevId, redirectToCleanProject]);

  const hasMinimalSearchValue = searchStat > 10000;
  const variables = gqlOptions.variables || {};

  const entityTypeFilter = (variables.filters || []).find(
    (f) => f.aggregationName === 'entityType',
  );

  const providerFilter = (variables.filters || []).find(
    (f) => f.aggregationName === 'providers',
  );

  const entityType = ((entityTypeFilter || {}).values || [])[0];

  const providerType = ((providerFilter || {}).values || [])[0];

  const onlyOneEntityType =
    ((entityTypeFilter || {}).values || []).filter((c) => c).length === 1;
  const moreThanOneProvider =
    ((providerFilter || {}).values || []).filter((c) => c).length > 1;

  const filteredSchema = (schema || []).filter(
    (s) => s.Grouping === entityType && s.IsCore,
  );

  const [selectedSchema, setSelectedSchema] = useState([]);

  useEffect(() => {
    if (doneSaving && annotationId && prevDoneSaving !== doneSaving) {
      createCleanProject({
        name: projectName,
        annotationId,
        entityType,
        query: variables.query,
        filters: variables.filters,
      });
    }
  }, [prevDoneSaving, annotationId, doneSaving]);

  let schemaForIntegraitonNonCore = [];

  if (providerType && entityType) {
    schemaForIntegraitonNonCore = (schema || []).filter((schemaKey) => {
      if (schemaKey.IsCore) {
        return false;
      }

      const source = findSource(schemaKey.Key, integrations);
      if (!source || !source.Name) {
        return false;
      }

      const isIntegration =
        formatName(source.Name).indexOf(formatName(providerType)) > -1;

      return isIntegration && schemaKey.Grouping === entityType;
    });
  }

  return (
    <Dialog show={isOpen} onClose={onClose}>
      <PanelHeader
        title={<FormattedMessage id="module-prepare-prepareConfig" />}
        hasClose
      />
      <PanelContent
        style={{ padding: '12px', maxHeight: '500px', overflowY: 'scroll' }}
      >
        {errorCreating && (
          <Alert type="Danger">
            <FormattedMessage id="module-prepare-errorCreatingCleanProject" />
          </Alert>
        )}
        {moreThanOneProvider && (
          <Alert>
            <FormattedMessage id="module-prepare-onlyCreateCleanProejctForOneIntegration" />
          </Alert>
        )}
        {hasMinimalSearchValue || !onlyOneEntityType ? (
          <Alert>
            <FormattedMessage id="module-prepare-refineSearchForSearch" />
          </Alert>
        ) : (
          !moreThanOneProvider && (
            <div>
              <div style={{ margin: '6px 0' }}>
                <FormattedMessage id="module-prepare-projectName" />
              </div>
              <div>
                <TextField
                  placeholder={intl.formatMessage({
                    id: 'module-projectNamePlaceholder',
                  })}
                  value={projectName}
                  onChange={(e, value) => {
                    setProjectName(value);
                  }}
                />
              </div>
              <div style={{ margin: '12px 0' }}>
                <FormattedHTMLMessage
                  id="module-prepare-dataSetSize"
                  values={{
                    searchStat,
                  }}
                />
              </div>
            </div>
          )
        )}
        {entityType && !moreThanOneProvider && (
          <div style={{ margin: '12px 0' }}>
            <FormattedHTMLMessage
              id="module-prepare-selectedEntityTypes"
              values={{
                entityType,
              }}
            />
          </div>
        )}
        {providerType && !moreThanOneProvider && (
          <div style={{ margin: '12px 0' }}>
            <FormattedHTMLMessage
              id="module-prepare-selectedProviderType"
              values={{
                providerType,
              }}
            />
          </div>
        )}
        {entityType && !moreThanOneProvider && (
          <AnnotationFromEntityType
            coreVocab={filteredSchema}
            entityType={entityType}
            schemaForIntegraitonNonCore={schemaForIntegraitonNonCore}
            onSelectionChange={(sc) => {
              setSelectedSchema(sc);
            }}
          />
        )}
        {hasMinimalSearchValue && (
          <div style={{ padding: '16px' }}>
            <FormattedHTMLMessage id="module-prepare-max10000" />
          </div>
        )}
        {!onlyOneEntityType && (
          <div style={{ padding: '16px' }}>
            <FormattedHTMLMessage id="module-prepare-supportOnly1EntityType" />
          </div>
        )}
      </PanelContent>
      <PanelFooter onClose={onClose} hasCancel>
        <PrimaryButton
          text={<FormattedMessage id="module-prepare-prepareData" />}
          loading={loading || saving}
          disabled={
            !onlyOneEntityType ||
            hasMinimalSearchValue ||
            !projectName ||
            (selectedSchema || []).length === 0 ||
            annotationId ||
            doneSaving ||
            saving
          }
          onClick={() => {
            const properTiesForAnnotation = createAnnotationForVocab(
              selectedSchema || [],
            );

            createAnnotation({
              name: projectName,
              entityType,
              annotationProperties: properTiesForAnnotation,
            });
          }}
        />
      </PanelFooter>
    </Dialog>
  );
};

export default withCreateAddProject(
  withIntegration(
    withCreateAnnotation(withSchema(injectIntl(CleanGqlQueryDialogContainer))),
  ),
);
