import styled from 'styled-components';

const NotificationIconWrapper = styled.div`
  border-radius: 24px;
  height: 24px;
  width: 24px;
  background-color: ${({ alertType, themePrimary }) => {
    if (
      alertType === 'danger' ||
      alertType === 'error' ||
      alertType === 'alert' ||
      alertType === 'critical'
    ) {
      return '#d13f48';
    }

    if (alertType === 'info') {
      return '#3e53c1';
    }

    if (alertType === 'warning') {
      return '#ff9800';
    }

    return themePrimary;
  }};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export default NotificationIconWrapper;
