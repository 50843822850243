import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import UserRoles from '../UserRoles';
import UserWithBadgeContainer from '../../containers/UserWithBadgeContainer';
import UserBlockUnBlockCell from '../../containers/UserBlockUnBlockCell';

const UserRolesCell = ({ Account }) => {
  return Account && Account.Roles ? (
    <UserRoles roles={Account.Roles} />
  ) : (
    <span>
      <FormattedMessage id="module-user-userRole" />
    </span>
  );
};

export const getColumns = (query = '', refetch) => [
  {
    displayName: <FormattedMessage id="module-user-nameLabel" />,
    property: 'property',
    Component: (props) => (
      <UserWithBadgeContainer entity={props} query={query} />
    ),
  },
  {
    displayName: <FormattedMessage id="module-user-roleLabel" />,
    name: 'role',
    isComputed: true,
    Component: UserRolesCell,
  },
  {
    displayName: <FormattedMessage id="module-user-active" />,
    name: 'is-active',
    isComputed: true,
    Component: (props) => (
      <UserBlockUnBlockCell user={props} refetch={refetch} />
    ),
  },
];
