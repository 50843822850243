import { handleActions } from 'redux-actions';
import jsCookie from 'js-cookie';

import {
  shouldShowAddIdentifierDialog,
  shouldShowHideIdentifierDialog,
  shouldFetchConsentFormByIdForEditActions,
  shouldUpdateUniqueIdentifier,
  shouldUpdateDataProcessor,
  shouldFetchAllConsentFormsActions,
  shouldLoadMoreAllConsentFormsActions,
  shouldFetchAllConsentActions,
  shouldFetchMoreAllConsentActions,
  shouldSaveConsentActions,
  shouldFetchConsentFormsByIdActions,
  addConsentEntryForConsent,
  clearAddConsentEntryForConsent,
  shouldSaveSingleConsentEntryActions,
  addConsentEntryForForm,
  clearAddConsentEntryForForm,
  shouldFetchConsentFormByFormIdActions,
  shouldSaveManualConsentEntriesForFormActions,
  shouldSaveConsentEntriesActions,
  shouldSaveConsentFormOnBackEndActions,
  shouldFetchConsentEntriesForConsentActions,
  shouldFetchMoreConsentEntriesForConsentActions,
  clearResult,
  clearConsentSearch,
  shouldSelectConsentSearch,
  shouldSaveSingleConsentFormOnBackEndActions,
  clearSingleResult,
} from './actions';

const intialState = {
  showAddIdentifier: false,
  isFetchingConsentConfigurationForEdit: false,
  consentConfigurationEdit: null,
  invalidConsentConfigurationEdit: false,
  currentUserConsent: null,
  currentUserConsentToken: jsCookie.get('consent_token') || '',

  consentForms: null,
  isFetchingAllconsentForms: false,
  fetchAllConsentFormsError: false,
  isFetchingMoreAllConsentForms: false,
  fetchAllConsentFormsfilterOptions: { page: 0 },

  consents: null,
  isFetchingAllconsents: false,
  fetchAllConsentsError: null,
  isFetchingMoreAllConsent: false,
  allConsentFilterOption: { page: 0 },

  isSavingConsent: false,
  isSavingConsentError: false,

  isFetchingConsentFromById: false,
  isFetchingConsentFromByIdError: false,
  consentForm: null,

  selectedConsent: null,

  saveSingleConsentEntry: {},

  selectedFormId: null,

  selectedConsentForm: null,
  isFetchingSelectedConsentForm: false,
  invalidSelectedConsentForm: false,

  saveManualConsentEntries: {},
  saveWebsiteConsentEntries: {},

  isSavingConsentFormResut: false,
  doneSavingConsentFormResult: false,
  invaldSavingConsentFormResult: false,

  consentEntriesForConsent: {},

  selectedConsentIdentifier: null,

  isSavingSingleConsentFormResult: false,
  doneSavingSingleConsentFormResult: false,
  invalidSavingSingleConsentFormResult: false,
};

/* eslint-disable no-plusplus */
export default handleActions(
  {
    [shouldSaveSingleConsentFormOnBackEndActions.request]: (state) => ({
      ...state,
      isSavingSingleConsentFormResult: true,
      doneSavingSingleConsentFormResult: false,
      invalidSavingSingleConsentFormResult: false,
    }),
    [shouldSaveSingleConsentFormOnBackEndActions.receive]: (state) => ({
      ...state,
      isSavingSingleConsentFormResult: false,
      doneSavingSingleConsentFormResult: true,
      invalidSavingSingleConsentFormResult: false,
    }),
    [shouldSaveSingleConsentFormOnBackEndActions.invalid]: (state) => ({
      ...state,
      isSavingSingleConsentFormResult: false,
      doneSavingSingleConsentFormResult: false,
      invalidSavingSingleConsentFormResult: true,
    }),
    [clearSingleResult]: (state) => ({
      ...state,
      isSavingSingleConsentFormResult: false,
      doneSavingSingleConsentFormResult: false,
      invalidSavingSingleConsentFormResult: false,
    }),
    [clearConsentSearch]: (state) => ({
      ...state,
      selectedConsentIdentifier: null,
    }),
    [shouldSelectConsentSearch]: (state, { payload }) => ({
      ...state,
      selectedConsentIdentifier: payload,
    }),
    [shouldFetchConsentEntriesForConsentActions.request]: (
      state,
      { payload },
    ) => ({
      ...state,
      consentEntriesForConsent: {
        [payload.consentEntryId]: {
          isFetching: true,
          consentEntries: [],
          invalid: false,
          isFetchingMore: false,
          filterOptions: {
            pageNumber: 0,
          },
        },
      },
    }),
    [shouldFetchConsentEntriesForConsentActions.receive]: (
      state,
      { payload: { result, param } },
    ) => ({
      ...state,
      consentEntriesForConsent: {
        [param.consentEntryId]: {
          isFetching: false,
          consentEntries: result,
          invalid: false,
          isFetchingMore: false,
          filterOptions: {
            pageNumber: 0,
          },
        },
      },
    }),
    [shouldFetchConsentEntriesForConsentActions.invalid]: (
      state,
      { payload },
    ) => ({
      ...state,
      consentEntriesForConsent: {
        [payload.consentEntryId]: {
          isFetching: false,
          invalid: true,
          isFetchingMore: false,
          filterOptions: {
            pageNumber: 0,
          },
        },
      },
    }),
    [shouldFetchMoreConsentEntriesForConsentActions.request]: (
      state,
      { payload },
    ) => ({
      ...state,
      consentEntriesForConsent: {
        [payload.consentEntryId]: {
          ...state.consentEntriesForConsent[payload.consentEntryId],
          invalid: false,
          isFetchingMore: true,
        },
      },
    }),
    [shouldFetchMoreConsentEntriesForConsentActions.receive]: (
      state,
      { payload: { param, result } },
    ) => ({
      ...state,
      consentEntriesForConsent: {
        [param.consentEntryId]: {
          ...state.consentEntriesForConsent[param.consentEntryId],
          consentEntries: [
            ...state.consentEntriesForConsent[param.consentEntryId]
              .consentEntries,
            ...result,
          ],
          filterOptions: {
            ...state.consentEntriesForConsent[param.consentEntryId]
              .filterOptions,
            pageNumber:
              (state.consentEntriesForConsent[param.consentEntryId]
                .filterOptions.pageNumber || 0) + 1,
          },
          invalid: false,
          isFetchingMore: true,
        },
      },
    }),
    [shouldFetchMoreConsentEntriesForConsentActions.invalid]: (
      state,
      { payload },
    ) => ({
      ...state,
      consentEntriesForConsent: {
        [payload.consentEntryId]: {
          ...state.consentEntriesForConsent[payload.consentEntryId],
          invalid: true,
          isFetchingMore: false,
        },
      },
    }),
    [clearResult]: (state) => ({
      ...state,
      isSavingConsentFormResut: false,
      doneSavingConsentFormResult: false,
      invaldSavingConsentFormResult: false,
    }),
    [shouldSaveConsentFormOnBackEndActions.request]: (state) => ({
      ...state,
      isSavingConsentFormResut: true,
      doneSavingConsentFormResult: false,
      invaldSavingConsentFormResult: false,
    }),
    [shouldSaveConsentFormOnBackEndActions.receive]: (state) => ({
      ...state,
      isSavingConsentFormResut: false,
      doneSavingConsentFormResult: true,
      invaldSavingConsentFormResult: false,
    }),
    [shouldSaveConsentFormOnBackEndActions.invalid]: (state) => ({
      ...state,
      isSavingConsentFormResut: false,
      doneSavingConsentFormResult: false,
      invaldSavingConsentFormResult: true,
    }),
    [shouldSaveConsentEntriesActions.request]: (state, { payload }) => ({
      ...state,
      saveWebsiteConsentEntries: {
        ...state.saveWebsiteConsentEntries,
        [payload.formId]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [shouldSaveConsentEntriesActions.receive]: (state, { payload }) => ({
      ...state,
      saveWebsiteConsentEntries: {
        ...state.saveWebsiteConsentEntries,
        [payload.formId]: {
          isFetching: false,
          done: true,
          invalid: false,
        },
      },
    }),
    [shouldSaveConsentEntriesActions.invalid]: (state, { payload }) => ({
      ...state,
      saveWebsiteConsentEntries: {
        ...state.saveWebsiteConsentEntries,
        [payload.formId]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [shouldSaveManualConsentEntriesForFormActions.request]: (
      state,
      { payload },
    ) => ({
      ...state,
      saveManualConsentEntries: {
        ...state.saveManualConsentEntries,
        [payload.formId]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [shouldSaveManualConsentEntriesForFormActions.receive]: (
      state,
      { payload },
    ) => ({
      ...state,
      saveManualConsentEntries: {
        ...state.saveManualConsentEntries,
        [payload.formId]: {
          isFetching: false,
          done: true,
          invalid: false,
        },
      },
    }),
    [shouldSaveManualConsentEntriesForFormActions.invalid]: (
      state,
      { payload },
    ) => ({
      ...state,
      saveManualConsentEntries: {
        ...state.saveManualConsentEntries,
        [payload.formId]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [shouldFetchConsentFormByFormIdActions.request]: (state) => ({
      ...state,
      isFetchingSelectedConsentForm: true,
      selectedConsentForm: null,
      invalidSelectedConsentForm: false,
    }),
    [shouldFetchConsentFormByFormIdActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingSelectedConsentForm: false,
      selectedConsentForm: payload,
      invalidSelectedConsentForm: false,
    }),
    [shouldFetchConsentFormByFormIdActions.invalid]: (state) => ({
      ...state,
      isFetchingSelectedConsentForm: false,
      selectedConsentForm: null,
      invalidSelectedConsentForm: true,
    }),
    [addConsentEntryForForm]: (state, { payload }) => ({
      ...state,
      selectedFormId: payload,
    }),
    [clearAddConsentEntryForForm]: (state) => ({
      ...state,
      selectedFormId: null,
    }),
    [shouldSaveSingleConsentEntryActions.request]: (state, { payload }) => ({
      ...state,
      saveSingleConsentEntry: {
        ...state.saveSingleConsentEntry,
        [payload.ConsentId]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [shouldSaveSingleConsentEntryActions.receive]: (state, { payload }) => ({
      ...state,
      saveSingleConsentEntry: {
        ...state.saveSingleConsentEntry,
        [payload.ConsentId]: {
          isFetching: false,
          done: true,
          invalid: false,
        },
      },
    }),
    [shouldSaveSingleConsentEntryActions.invalid]: (state, { payload }) => ({
      ...state,
      saveSingleConsentEntry: {
        ...state.saveSingleConsentEntry,
        [payload.ConsentId]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [shouldShowAddIdentifierDialog]: (state) => ({
      ...state,
      showAddIdentifier: true,
    }),
    [shouldShowHideIdentifierDialog]: (state) => ({
      ...state,
      showAddIdentifier: false,
    }),
    [shouldFetchConsentFormByIdForEditActions.request]: (state) => ({
      ...state,
      isFetchingConsentConfigurationForEdit: true,
      invalidConsentConfigurationEdit: false,
      consentConfigurationEdit: null,
    }),
    [shouldFetchConsentFormByIdForEditActions.receive]: (
      state,
      { payload },
    ) => ({
      ...state,
      isFetchingConsentConfigurationForEdit: false,
      invalidConsentConfigurationEdit: false,
      consentConfigurationEdit: payload,
    }),
    [shouldFetchConsentFormByIdForEditActions.invalid]: (state) => ({
      ...state,
      isFetchingConsentConfigurationForEdit: false,
      invalidConsentConfigurationEdit: true,
      consentConfigurationEdit: null,
    }),
    [shouldUpdateUniqueIdentifier]: (
      state,
      { payload: { consent, uniqueIdentifier } },
    ) => {
      const {
        currentUserConsent,
        currentUserConsentToken,
        consentConfigurationEdit,
      } = state;
      if (!currentUserConsent) {
        const currentUserProcessors = consentConfigurationEdit.processors.map(
          (p) => ({
            ...p,
            consent: false,
          }),
        );

        const currentUniqueIdentifiers =
          consentConfigurationEdit.uniqueIdentifiers.map((p) => ({
            ...p,
            consent: false,
          }));

        const selectedUniqueIdentifier = currentUniqueIdentifiers.find(
          (p) => p.key.toLowerCase() === uniqueIdentifier.key.toLowerCase(),
        );

        if (selectedUniqueIdentifier) {
          selectedUniqueIdentifier.consent = consent;
        }

        return {
          ...state,
          currentUserConsent: {
            consentId: consentConfigurationEdit.id,
            uniqueIdentifiers: currentUniqueIdentifiers,
            processors: currentUserProcessors,
            userId: currentUserConsentToken,
          },
        };
      }

      const uniquedIdentifierToChange =
        currentUserConsent.uniqueIdentifiers.find(
          (u) => u.key.toLowerCase() === uniqueIdentifier.key.toLowerCase(),
        );

      uniquedIdentifierToChange.consent = consent;

      return {
        ...state,
        currentUserConsent: {
          ...currentUserConsent,
          uniqueIdentifiers: [...currentUserConsent.uniqueIdentifiers],
        },
      };
    },
    [shouldUpdateDataProcessor]: (
      state,
      { payload: { hasConsent, processor } },
    ) => {
      let { currentUserConsent } = state;
      const { Id: consentId, ProviderId } = processor;

      if (!hasConsent && !currentUserConsent) {
        // this should never happen
        return state;
      }

      if (hasConsent) {
        if (!currentUserConsent) {
          currentUserConsent = [];
        }

        const newConsentEntry = {
          ProviderId,
          ConsentId: consentId,
          LookupId:
            'unknown at this point, will only be set uppon consent form submission',
        };

        currentUserConsent.push(newConsentEntry);

        return {
          ...state,
          currentUserConsent,
        };
      }

      // the user retracted consent for a processor:
      currentUserConsent = currentUserConsent.filter(
        (consentEntry) => consentEntry.ConsentId !== consentId,
      );

      return {
        ...state,
        currentUserConsent,
      };
    },
    [shouldFetchAllConsentFormsActions.request]: (state) => ({
      ...state,
      isFetchingAllconsentForms: true,
      fetchAllConsentFormsError: false,
    }),
    [shouldFetchAllConsentFormsActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingAllconsentForms: false,
      fetchAllConsentFormsError: false,
      consentForms: payload && payload.length && payload[0],
    }),
    [shouldFetchAllConsentFormsActions.invalid]: (state) => ({
      ...state,
      isFetchingAllconsentForms: false,
      fetchAllConsentFormsError: true,
    }),
    [shouldLoadMoreAllConsentFormsActions.request]: (state) => ({
      ...state,
      isFetchingMoreAllConsentForms: true,
      fetchAllConsentFormsError: false,
    }),
    [shouldLoadMoreAllConsentFormsActions.receive]: (state, { payload }) => {
      const newValues = payload && payload.length && payload[0];

      return {
        ...state,
        isFetchingMoreAllConsentForms: false,
        consentForms: [...state.consentForms, ...newValues],
        fetchAllConsentFormsError: false,
        fetchAllConsentFormsfilterOptions: {
          ...state.fetchAllConsentFormsfilterOptions,
          page: (state.fetchAllConsentFormsfilterOptions.page || 0) + 1,
        },
      };
    },
    [shouldLoadMoreAllConsentFormsActions.invalid]: (state) => ({
      ...state,
      isFetchingMoreAllConsentForms: false,
      fetchAllConsentFormsError: true,
    }),
    [shouldFetchAllConsentActions.request]: (state) => ({
      ...state,
      isFetchingAllconsents: true,
      fetchAllConsentsError: false,
    }),
    [shouldFetchAllConsentActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingAllconsents: false,
      fetchAllConsentsError: false,
      consents: payload,
    }),
    [shouldFetchAllConsentActions.invalid]: (state) => ({
      ...state,
      isFetchingAllconsents: false,
      fetchAllConsentsError: true,
    }),
    [shouldFetchMoreAllConsentActions.request]: (state) => ({
      ...state,
      isFetchingMoreAllconsent: true,
      fetchAllConsentsError: false,
      allConsentFilterOption: {
        ...state.allConsentFilterOption,
        isFetchingMore: true,
      },
    }),
    [shouldFetchMoreAllConsentActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingMoreAllConsent: false,
      fetchAllConsentsError: false,
      consents: [...state.consents, ...payload],
      allConsentFilterOption: {
        ...state.allConsentFilterOption,
        isFetchingMore: false,
        page: (state.allConsentFilterOption.page || 0) + 1,
      },
    }),
    [shouldFetchMoreAllConsentActions.invalid]: (state) => ({
      ...state,
      isFetchingMoreAllConsent: false,
      fetchAllConsentsError: false,
    }),
    [shouldSaveConsentActions.request]: (state) => ({
      ...state,
      isSavingConsent: true,
      isSavingConsentError: false,
    }),
    [shouldSaveConsentActions.receive]: (state) => ({
      ...state,
      isSavingConsent: false,
      isSavingConsentError: false,
    }),
    [shouldSaveConsentActions.invalid]: (state, { payload }) => ({
      ...state,
      isSavingConsent: false,
      isSavingConsentError: payload,
    }),
    [shouldFetchConsentFormsByIdActions.request]: (state) => ({
      ...state,
      isFetchingConsentFromById: true,
      isFetchingConsentFromByIdError: false,
      consentForm: null,
    }),
    [shouldFetchConsentFormsByIdActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingConsentFromById: false,
      isFetchingConsentFromByIdError: false,
      consentForm: payload,
    }),
    [shouldFetchConsentFormsByIdActions.invalid]: (state) => ({
      ...state,
      isFetchingConsentFromById: false,
      isFetchingConsentFromByIdError: true,
      consentForm: null,
    }),
    [addConsentEntryForConsent]: (state, { payload }) => ({
      ...state,
      selectedConsent: payload,
    }),
    [clearAddConsentEntryForConsent]: (state) => ({
      ...state,
      selectedConsent: null,
    }),
  },
  intialState,
);
