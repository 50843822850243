import React from 'react';
import { Button } from '@cluedin/form';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  margin: 16px;
`;

const ListWithPaging =
  (Comp, options = {}) =>
  (props) => {
    const {
      items,
      data,
      isFetchingMore,
      onLoadMore,
      pageNumber = 0,
      take = 20,
    } = props;

    const { message = 'Load More', style = {} } = options;
    const entities = data || items;

    const showMore =
      entities &&
      entities.length > 0 &&
      (entities.length / ((pageNumber || 0) + 1)) % take === 0;

    return (
      <div>
        <Comp {...props} />
        {showMore && (
          <ButtonWrapper style={style}>
            <Button
              loading={isFetchingMore}
              isFullWidth
              onClick={() => {
                if (onLoadMore) {
                  onLoadMore(pageNumber + 1);
                }
              }}
              text={message}
            />
          </ButtonWrapper>
        )}
      </div>
    );
  };

export default ListWithPaging;
