import React from 'react';
import AllRetentionContainer from '../containers/AllRetentionContainer';
import { useBackButton } from '../../../../components/PillarBackButton';
import Crumb from '../../../core/components/composites/routing/Crumb';

const AllRetentionPage = () => {
  useBackButton();

  return (
    <>
      <Crumb title={[{ id: 'module-retention-allRetentionsPolicies' }]} />
      <AllRetentionContainer />
    </>
  );
};

export default AllRetentionPage;
