import React from 'react';
import PropTypes from 'prop-types';

const CluedInRegularTextStyle = {
  text: {
    fontSize: '12px',
    color: '#6e6e71',
  },
};

const CluedInExplanationText = ({ children, style }) => (
  <div style={{ ...CluedInRegularTextStyle.text, ...style }}>{children}</div>
);

CluedInExplanationText.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

CluedInExplanationText.defaultProps = {
  style: {},
};

export default CluedInExplanationText;
