import { useContext, createContext } from 'react';
import { useQueryOrganizationFeatures } from './useQueryOrganizationFeatures';

export const OrganizationFeatureFlagContext = createContext([]);

export const OrganizationFeatureProvider = ({ children }) => {
  const [featureFlags] = useQueryOrganizationFeatures();

  return (
    <OrganizationFeatureFlagContext.Provider value={featureFlags || []}>
      {children}
    </OrganizationFeatureFlagContext.Provider>
  );
};

export const useOrganizationFeatureFlags = () => {
  const organizationFeatureFlags = useContext(OrganizationFeatureFlagContext);
  const findFeatureFlag = (featureName) => {
    const feature = organizationFeatureFlags.find(
      (f) => f.name === featureName,
    );
    return feature?.isEnabled;
  };

  return [organizationFeatureFlags, findFeatureFlag];
};

export const useOrganizationFeatureFlagOption = (featureName) => {
  const organizationFeatureFlags = useContext(OrganizationFeatureFlagContext);

  if (!organizationFeatureFlags) {
    return false;
  }

  const foundFeature = organizationFeatureFlags.find(
    (f) => f.name === featureName,
  );

  if (!foundFeature) {
    return false;
  }

  return foundFeature.isEnabled;
};

export const OrganizationFeatureFlag = ({
  MainFeature,
  ExperimentalFeature,
  featureName,
}) => {
  const organizationFeatureFlags = useContext(OrganizationFeatureFlagContext);

  if (!organizationFeatureFlags) {
    return MainFeature;
  }

  const foundFeature = organizationFeatureFlags.find(
    (f) => f.name === featureName,
  );

  if (foundFeature?.isEnabled) {
    return ExperimentalFeature;
  }

  return MainFeature;
};
