import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  background: #fff;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #d4dad1;
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding-right: 6px;
`;

const Title = styled.div`
  display: inline-block;
  color: #37373a;
  font-size: 18px;
`;

export const BreadCrumbsElementWrapper = ({ title, icon, help }) => (
  <Container>
    <TitleContainer>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Title>{title}</Title>
    </TitleContainer>
    {help}
  </Container>
);

BreadCrumbsElementWrapper.disaplyName = 'BreadCrumbsElementWrapper';

BreadCrumbsElementWrapper.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.node,
};

BreadCrumbsElementWrapper.defaultProps = {
  title: '',
  icon: null,
};
