import React from 'react';
import Flex from 'uxi/Layout/Flex';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { Externallink } from 'uxi/Icons';
import Checkbox from 'uxi/Input/Checkbox';
import { ButtonLink } from '@cluedin/atoms';
import TextEllipsis from 'uxi/Text/TextEllipsis';
import TrendOverTimeChart, {
  MAXH,
} from './TrendOverTimeChart/TrendOverTimeChart';
import { lt } from '../../../core/helpers/numbers';
import UnstyledLink from '../../../core/components/composites/UnstyledLink';
import colorHash from './TrendOverTimeChart/color';

const getColor = (str = 'cluedin') => colorHash.hex(str);

const ChekboxOverRides = styled.div`
  &,
  & label,
  & > div,
  & > div > div,
  & > div > div > div {
    width: 100%;
  }

  & > div > div > div > div {
    padding: 0 0 0 3px;
  }
`;

class TrendOverTimeKPIChart extends React.Component {
  constructor(props) {
    super(props);

    const { data } = props;
    let providerNames = {};
    if (data && data.additionalValues && data.additionalValues.length > 0) {
      providerNames = data.additionalValues
        .map(({ providerName: name }) => name)
        .reduce((accu, x) => {
          accu[x] = true; // eslint-disable-line no-param-reassign
          return accu;
        }, {});
    }

    this.state = {
      providerNames: {
        Blended: true,
        ...providerNames,
      },
    };
  }

  invertSelection = () => {
    this.setState(({ providerNames }) => ({
      providerNames: Object.keys(providerNames).reduce((acc, k) => {
        acc[k] = !providerNames[k];
        return acc;
      }, {}),
    }));
  };

  handleChange = (ev, value, name) => {
    this.setState((state) => ({
      providerNames: {
        ...state.providerNames,
        [name]: value,
      },
    }));
  };

  hideAll = () => {
    this.setState(({ providerNames }) => ({
      providerNames: Object.keys(providerNames).reduce((acc, k) => {
        acc[k] = false;
        return acc;
      }, {}),
    }));
  };
  showAll = () => {
    this.setState(({ providerNames }) => ({
      providerNames: Object.keys(providerNames).reduce((acc, k) => {
        acc[k] = true;
        return acc;
      }, {}),
    }));
  };

  render() {
    const {
      dateRange,
      data,
      data: {
        latestValue,
        // max: maxProp,
        threshold = -1,
      },
      theme: { palette },
      actionLink = '/admin/preparation/clean',
      isFullScreen: showDetails, // from Widget(allowFullScreen)
    } = this.props;

    const { providerNames } = this.state;

    const latest = latestValue;
    const units = data.units || '%';

    const semanticColor =
      latest > threshold ? palette.themePrimary : palette.red;

    // const max = maxProp !== undefined ? maxProp : Math.max(...(data.values || []));
    const max = 100;

    const latestMapped =
      latest > 0 ? Math.round(lt(latest, 0, max, 0, MAXH)) : '-';

    return (
      <Flex
        style={{
          flexFlow: 'column nowrap',
          width: '100%',
          // maxWidth: '78vw',
          maxWidth: '1000px',
          margin: '0 auto',
          // ...(showDetails ? { minHeight: 'calc(100vh - 50px)' } : {}),
          ...(showDetails ? { minHeight: '100%', maxHeight: '80vh' } : {}),
        }}
      >
        <div style={{ padding: '16px', width: 'calc(100% - 32px)' }}>
          <h1
            style={{
              color: semanticColor,
              margin: '0 0 2px',
              fontSize: '42px',
              fontWeight: 400,
            }}
          >
            <TextEllipsis title={latestMapped}>
              {latestMapped} {units}
            </TextEllipsis>
          </h1>
          <small style={{ color: 'grey' }}>
            {latest > 0 ? null : 'No data to show yet'}
          </small>
          <div>
            <UnstyledLink to={actionLink}>
              <Flex style={{ justifyContent: 'flex-start' }}>
                <TextEllipsis title="Improve this metric">
                  <Externallink size="14" />
                  <span style={{ paddingLeft: '4px' }}>
                    <FormattedMessage id="module-gdpr-improveMetrics" />
                  </span>
                </TextEllipsis>
              </Flex>
            </UnstyledLink>
          </div>
        </div>
        <Flex style={{ width: '100%', height: '100%', alignItems: 'stretch' }}>
          {showDetails && data.values.length > 1 ? (
            <Flex
              style={{
                flexDirection: 'column',
                width: '148px',
                alignItems: 'flex-start',
                height: '100%',
              }}
            >
              <ButtonLink
                style={{ marginBottom: '4px' }}
                isFullWidth
                text={
                  <TextEllipsis title="Invert Selection">
                    Invert selection
                  </TextEllipsis>
                }
                onClick={this.invertSelection}
              />
              <ButtonLink
                style={{ marginBottom: '4px' }}
                isFullWidth
                text={
                  <TextEllipsis title="Invert Selection">Hide all</TextEllipsis>
                }
                onClick={this.hideAll}
              />
              <ButtonLink
                style={{ marginBottom: '12px' }}
                isFullWidth
                text={
                  <TextEllipsis title="Invert Selection">Show all</TextEllipsis>
                }
                onClick={this.showAll}
              />

              <ChekboxOverRides>
                <Checkbox
                  name={'Blended'}
                  checked={providerNames.Blended}
                  onChange={this.handleChange}
                  label={
                    <Flex
                      style={{
                        width: 'calc(100% - 16px)',
                        margin: '8px auto',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: '#486372',
                          marginRight: '6px',
                        }}
                      />
                      <TextEllipsis title="Blended">Blended</TextEllipsis>
                    </Flex>
                  }
                />
              </ChekboxOverRides>
              {data.additionalValues && data.additionalValues.length > 0
                ? [...data.additionalValues]
                    .reverse()
                    .map(({ providerName: name }, idx) => (
                      <ChekboxOverRides key={idx}>
                        <Checkbox
                          name={name}
                          checked={providerNames[name]}
                          onChange={this.handleChange}
                          label={
                            <Flex
                              style={{
                                width: 'calc(100% - 16px)',
                                margin: '8px auto',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <div
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  marginRight: '6px',
                                  backgroundColor: getColor(name) || '#486372',
                                }}
                              />
                              <TextEllipsis title={name}>{name}</TextEllipsis>
                            </Flex>
                          }
                        />
                      </ChekboxOverRides>
                    ))
                : null}
            </Flex>
          ) : null}
          <Flex
            className="widget_debug_flexShouldBeTall"
            style={{
              height: '100%',
              width: showDetails ? 'calc(100% - 148px)' : '100%',
            }}
          >
            <TrendOverTimeChart
              providerNames={providerNames}
              dateRange={dateRange}
              showDetails={showDetails}
              units={units}
              semanticColor={semanticColor}
              data={data}
              options={{
                xAxisLabels: !!showDetails,
                yAxisLabels: false,
                xAxisLabel: false,
                yAxisLabel: false,
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

TrendOverTimeKPIChart.displayName = 'TrendOverTimeKPIChart';

export default TrendOverTimeKPIChart;
