export const withListPage = (
  { entityName, action },
  { reducer = {}, initialState = {} },
) => {
  const enhancedReducer = {
    ...reducer,
    [action.request]: (state) => ({
      ...state,
      [`ALL_${entityName}`]: {
        isFetching: true,
        invalid: false,
        data: null,
      },
    }),
    [action.receive]: (state, { payload }) => ({
      ...state,
      [`ALL_${entityName}`]: {
        isFetching: false,
        invalid: false,
        data: payload,
      },
    }),
    [action.invalid]: (state) => ({
      ...state,
      [`ALL_${entityName}`]: {
        isFetching: false,
        invalid: true,
        data: null,
      },
    }),
  };

  const enhancedInitialState = {
    ...initialState,
    [`ALL_${entityName}`]: {
      isFetching: false,
      invalid: false,
      data: null,
    },
  };

  return {
    reducer: enhancedReducer,
    initialState: enhancedInitialState,
  };
};

export const withCreate = (
  { entityName, action },
  { reducer = {}, initialState = {} },
) => {
  const enhancedReducer = {
    ...reducer,
    [action.request]: (state) => ({
      ...state,
      [`${entityName}Creation`]: {
        invalid: false,
        isSaving: true,
        done: false,
      },
    }),
    [action.receive]: (state) => ({
      ...state,
      [`${entityName}Creation`]: {
        invalid: false,
        isSaving: false,
        done: true,
      },
    }),
    [action.invalid]: (state) => ({
      ...state,
      [`${entityName}Creation`]: {
        invalid: true,
        isSaving: false,
        done: false,
      },
    }),
  };

  return {
    reducer: enhancedReducer,
    initialState: {
      ...initialState,
      [`${entityName}Creation`]: {
        invalid: false,
        isSaving: false,
        done: false,
      },
    },
  };
};

export const withCrud = (
  { entityName, actions: { create, fetch } },
  intial = {},
) => {
  const listPage = withListPage(
    {
      entityName,
      action: fetch,
    },
    intial,
  );

  return withCreate(
    {
      entityName,
      action: create,
    },
    listPage,
  );
};

export default {
  withListPage,
  withCreate,
  withCrud,
};
