import { FormDecorator, TextField, ChoiceGroup } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { Typography } from '@cluedin/atoms';
import EntityTypeSelectAndCreate from '../../../../entityTypes/components/containers/EntityTypeSelectAndCreate';
import DataSetSelection from '../../../../annotation/components/containers/forms/edge/EdgeWizzard/DataSetSelection';
import { SelectedVocabInfo } from './SelectedVocabInfo';

const EndpointCreationForm = ({
  entityType,
  endPointName,
  onChangeEntityType,
  onChangeEndpointName,
  errorEndpointName,
  allExistingAnnotations,
  isLoadingAllExistingAnnotations,
  selectedDataSet,
  setDataSet,
  mappingMode,
  entityTypeValidationError,
  setMappingMode,
  entityTypeIconValidationError,
}) => {
  const options = [
    {
      key: 'new',
      value: 'new',
      text: (
        <>
          <FormattedMessage id="datasource-new-mapping" />
          <Typography component={'div'} color={'secondary'} variant="caption">
            <FormattedMessage id="data-source-new-radio-hint" />
          </Typography>
        </>
      ),
      'data-test': 'choose-new-entity-type-radio',
    },
    {
      key: 'existing',
      value: 'existing',
      text: (
        <>
          <FormattedMessage id="datasource-existing-mapping" />
          <Typography component={'div'} color={'secondary'} variant="caption">
            <FormattedMessage id="data-source-existing-hint" />
          </Typography>
        </>
      ),
      'data-test': 'choose-existing-mapping-radio',
    },
  ];

  const entityTypeSelections = (
    <FormDecorator
      label={<FormattedMessage id="data-source-entity-type" />}
      helpText={<FormattedMessage id="data-source-entity-type-help" />}
      isRequired
    >
      <div className="__test_EntityType" data-test={'entityType'}>
        <EntityTypeSelectAndCreate
          value={entityType || ''}
          onChange={(value) => {
            onChangeEntityType(value);
          }}
          entityTypeValidationError={entityTypeValidationError}
          entityTypeIconValidationError={entityTypeIconValidationError}
        />
      </div>
    </FormDecorator>
  );

  return (
    <>
      <FormDecorator
        className="__test_endPointName"
        label={<FormattedMessage id="data-source-endpoint-name" />}
        helpText={<FormattedMessage id="data-source-endpoint-help" />}
        errorText={errorEndpointName}
        isRequired
      >
        <TextField
          data-test={'endPointName'}
          value={endPointName}
          onChange={(e, v) => onChangeEndpointName(v)}
        />
      </FormDecorator>

      {allExistingAnnotations &&
        allExistingAnnotations.length === 0 &&
        entityTypeSelections}

      {allExistingAnnotations && allExistingAnnotations.length > 0 && (
        <FormDecorator
          label={
            <FormattedMessage id="datasource-mapping-configuration-label" />
          }
          helpText={
            <FormattedMessage id="datasource-mapping-configuration-help" />
          }
        >
          <div
            className="__test_mappingConfiguration"
            data-test={'mappingConfiguration'}
          >
            <ChoiceGroup
              onChange={(_, v) => {
                setDataSet(undefined);
                setMappingMode(v.value);
              }}
              defaultSelectedKey={'new'}
              options={options}
            />
          </div>

          <div style={{ marginLeft: '24px', marginTop: '12px' }}>
            {mappingMode === 'existing' && (
              <DataSetSelection
                showVocabInfo
                value={selectedDataSet?.id}
                onChange={(id, ds) => {
                  setDataSet(ds);
                }}
              />
            )}
          </div>
        </FormDecorator>
      )}

      {allExistingAnnotations &&
        allExistingAnnotations.length > 0 &&
        mappingMode === 'new' &&
        entityTypeSelections}
    </>
  );
};

export default EndpointCreationForm;
