import { FC, useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
import { useTheme } from '@cluedin/theme';
import { FormattedHTMLMessage } from '@cluedin/locale';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import Alert from 'uxi/Alert';

import { EdgePropertiesPanelEdgeGroupEntryWrapper } from './EdgePropertiesPanelEdgeGroupEntryWrapper';
import { EdgePropertiesPanelEdgeGroupEntryInnerWrapper } from './EdgePropertiesPanelEdgeGroupEntryInnerWrapper';
import { EdgePropertiesPanelEdgeGroupEntryHeader } from './EdgePropertiesPanelEdgeGroupEntryHeader';
import { EdgePropertiesPanelEdgeGroupWrapper } from './EdgePropertiesPanelEdgeGroupWrapper';
import { EntityRelationEdgePropertiesList } from './EntityRelationEdgePropertiesList';
import PageLoader from '../../../../../core/components/composites/PageLoader';
import {
  SelectedConnectingEdge,
  EdgePropertiesData,
  SelectedConnectingDirectionalEdge,
} from '../../../types';

type EntityRelationEdgePropertiesEntryProps = {
  edgeGroup: [string, EdgePropertiesData[]];
  edgePropertiesPanelLoading: boolean;
  selectedEdge: SelectedConnectingEdge;
  edgeTo: SelectedConnectingDirectionalEdge;
  edgeFrom: SelectedConnectingDirectionalEdge;
  edgeDirection: string;
  edgeType: string;
};

export const EntityRelationEdgePropertiesEntry: FC<
  EntityRelationEdgePropertiesEntryProps
> = ({
  edgeGroup,
  edgePropertiesPanelLoading,
  selectedEdge,
  edgeTo,
  edgeFrom,
  edgeDirection,
  edgeType,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const filteredEdgeGroup = edgeGroup?.[1]?.filter(
    (edge: EdgePropertiesData | undefined) => {
      const referencedEntityId = edge?.referencedEntityId;
      const nodeFromId = selectedEdge?.nodeFrom?.id;
      const nodeToId = selectedEdge?.nodeTo?.id;
      const nodeFromGroupedIds = selectedEdge?.nodeFrom?.groupedEntityIds || [];
      const nodeToGroupedIds = selectedEdge?.nodeTo?.groupedEntityIds || [];

      return (
        referencedEntityId !== undefined &&
        (referencedEntityId === nodeFromId ||
          referencedEntityId === nodeToId ||
          nodeFromGroupedIds.includes(referencedEntityId) ||
          nodeToGroupedIds.includes(referencedEntityId))
      );
    },
  );

  return (
    <EdgePropertiesPanelEdgeGroupEntryWrapper>
      <EdgePropertiesPanelEdgeGroupEntryInnerWrapper
        isExpanded={isExpanded}
        theme={theme}
        data-test="entity-entityRelations-edgePropertiesPanelEdgeGroupEntry"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <EdgePropertiesPanelEdgeGroupEntryHeader>
          <ExpandingArrow
            expanded={isExpanded}
            data-test="entity-entityRelations-expandingArrow"
          />
          {edgeGroup?.[0]}
        </EdgePropertiesPanelEdgeGroupEntryHeader>
      </EdgePropertiesPanelEdgeGroupEntryInnerWrapper>

      <EdgePropertiesPanelEdgeGroupWrapper isExpanded={isExpanded}>
        {edgePropertiesPanelLoading && <PageLoader />}

        {filteredEdgeGroup?.length > 0 ? (
          filteredEdgeGroup?.map((edge, index) => (
            <EntityRelationEdgePropertiesList
              key={index}
              edge={edge}
              edgeTo={edgeTo}
              edgeFrom={edgeFrom}
              edgeDirection={edgeDirection}
            />
          ))
        ) : (
          <div style={{ padding: '10px 0' }}>
            <Alert type="information">
              <FormattedHTMLMessage
                id="module-entityRelationsGraph-edgePropertiesEntryNoData"
                values={{ edgeType }}
              />
            </Alert>
          </div>
        )}
      </EdgePropertiesPanelEdgeGroupWrapper>
    </EdgePropertiesPanelEdgeGroupEntryWrapper>
  );
};
