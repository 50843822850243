import { useId } from 'react';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import _ from 'lodash';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import EntityTypeAvatar from '../../../../entityTypes/components/containers/EntityTypeAvatar';
import FormattedEntityName from './FormattedEntityName/FormattedEntityName';
import FormattedEntityCode from './FormattedEntityCode/FormattedEntityCode';

export const columns = [
  {
    property: 'referencedEntityName',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEntityName" />
    ),
    Component: FormattedEntityName,
  },
  {
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgeProperties" />
    ),
    Component: (props) => {
      const edgePropertiesTooltipId = useId();
      const theme = useTheme();

      return (
        <div>
          {_.isEmpty(props?.properties) ? (
            <em
              style={{
                color: theme?.semanticColors?.inputPlaceholderText ?? '#9B9B9C',
              }}
            >
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListNoEdgeProperties" />
            </em>
          ) : (
            <TooltipHost
              id={edgePropertiesTooltipId}
              calloutProps={{ gapSpace: 0 }}
              content={
                <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgePropertiesTooltip" />
              }
            >
              {Object.keys(props?.properties)?.length}&nbsp;
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgePropertiesCount" />
            </TooltipHost>
          )}
        </div>
      );
    },
  },
  {
    property: 'referencedEntityType',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEntityType" />
    ),
    Component: (props) => (
      <EntityTypeAvatar entityType={props?.referencedEntityType} />
    ),
  },
  {
    property: 'referencedEntityCode',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEntityCode" />
    ),
    Component: (props) => (
      <FormattedEntityCode entityCode={props?.referencedEntityCode} />
    ),
    width: '150px',
  },
];
