import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import {
  FormDecorator,
  TextField,
  PanelContent,
  PanelFooter,
  FormSubmitAndCancel,
  PanelHeader,
  GqlErrorMessages,
} from '@cluedin/form';
import { useCloneHierarchy } from '../../hooks/useCloneHierarchy';
import {
  RequiredMessage,
  NotEmptyErrorMessage,
} from '../../../core/validation/messages';
import { isEmpty } from '../../../core/validation/index';

const CloneHierarchyForm = ({
  onClose,
  history,
  noCaching,
  values: { id, name },
}) => {
  const [isTouched, setTouched] = useState(false);
  const [hierarchyName, setHierarchyName] = useState(`${name} - Copy`);
  const [cloneHierarchy, { data, loading, error }] = useCloneHierarchy();

  const getRequiredandNotEmptyValidationMessage = (value) => {
    if (!value) {
      return <RequiredMessage />;
    }

    if (isEmpty(value)) {
      return <NotEmptyErrorMessage />;
    }

    return null;
  };
  const hierarchyNameError = getRequiredandNotEmptyValidationMessage(
    hierarchyName,
    250,
  );

  useEffect(() => {
    if (data?.id) {
      history.push(`/admin/management/hierarchy/detail/${data?.id}`);
      onClose();
    }

    return () => {
      setTouched(false);
    };
  }, [data?.id, onClose, history]);

  return (
    <>
      <PanelHeader
        title={<FormattedMessage id="module-hierarchy-cloneHierarchy" />}
        onClose={onClose}
      />
      <PanelContent>
        <GqlErrorMessages error={error} />
        <FormDecorator
          label={<FormattedMessage id="module-hierarchy-CloneName" />}
          helpText={<FormattedMessage id="module-hierarchy-CloneNameHelp" />}
          isRequired
          errorText={isTouched && hierarchyNameError}
        >
          <TextField
            data-test="input-name-clone-hierarchy"
            value={hierarchyName}
            onChange={(e, v) => {
              setTouched(true);
              setHierarchyName(v);
            }}
          />
        </FormDecorator>
      </PanelContent>
      <PanelFooter>
        <FormSubmitAndCancel
          loading={loading}
          isTouchedAndNotSaved={!!hierarchyName}
          submitProps={{
            disabled: !!hierarchyNameError,
            label: (
              <span data-test="create-clone-hierarchy-button">
                <FormattedMessage id="module-hierarchy-clone" />
              </span>
            ),
            onClick: () => {
              if (!hierarchyName) {
                return;
              }

              cloneHierarchy({
                id: id,
                name: hierarchyName.trim(),
              });
            },
          }}
          onCancel={() => {
            setHierarchyName('');
            onClose();
          }}
        />
      </PanelFooter>
    </>
  );
};

export default CloneHierarchyForm;
