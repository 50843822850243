import { useMemo, useState, memo } from 'react';
import { Checkbox } from '@cluedin/form';

import { usePaginatedVocabularyKeys } from '../../../../dataCatalog/hooks/usePaginatedVocabularyKeys';
import { VocabularyKeySelector } from '../../../../dataCatalog/components/containers/VocabularyKeySelector';
import { useVocabularyKeysFromVocabId } from '../../../../dataCatalog/hooks/useVocabularyKeys';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../../../core/helpers/string';

export const RuleVocabularyKeySelectorContainer = memo(
  ({
    value,
    onChange,
    disabled,
    hasError,
    mappedKeyLabel,
    vocabularyId,
    width = '350px',
    isCreatable = false,
    vocabularyKeyPrefix,
    isClearable = true,
  }) => {
    const intl = useIntl();
    const [restrictToVocabKeys, setRestrictToVocabKeys] = useState(false);
    const [searchName, setSearchName] = useState('');

    const {
      loadMore,
      vocabularyKeys,
      totalVocabularyKeys,
      setSearchVocabularyKeys,
      isLoadingVocabularyKeys,
    } = usePaginatedVocabularyKeys({
      skip: restrictToVocabKeys && vocabularyId,
    });

    const [data, isLoadingVocabKeys] = useVocabularyKeysFromVocabId(
      vocabularyId,
      { searchName },
      false,
      !restrictToVocabKeys || !vocabularyId,
    );

    const apiData = restrictToVocabKeys ? data.data : vocabularyKeys;
    const apiTotal = restrictToVocabKeys ? data.total : totalVocabularyKeys;
    const apiLoading = restrictToVocabKeys
      ? isLoadingVocabKeys
      : isLoadingVocabularyKeys;

    const adaptedVocabularySelectValue = useMemo(() => {
      if (value && typeof value === 'string' && !mappedKeyLabel) {
        return { label: value, value: value };
      }

      if (!value?.field) return undefined;

      if (mappedKeyLabel) {
        return {
          value: value?.field,
          label: (
            <span>
              {value?.field} &#10141; {mappedKeyLabel}
            </span>
          ),
        };
      }

      return { label: value?.field, value: value?.field };
    }, [value, mappedKeyLabel]);

    const handleSearch = restrictToVocabKeys
      ? setSearchName
      : setSearchVocabularyKeys;

    return (
      <div style={{ width, flex: 1 }}>
        <>
          {vocabularyId && (
            <div style={{ paddingBottom: '10px' }}>
              <Checkbox
                checked={restrictToVocabKeys}
                onChange={() => setRestrictToVocabKeys((prev) => !prev)}
                label={intl.formatMessage(
                  { id: 'module-annotation-add-prop-restrict-to-vocab' },
                  { vocab: capitalizeFirstLetter(vocabularyKeyPrefix) },
                )}
              />
            </div>
          )}

          <VocabularyKeySelector
            data={apiData}
            total={apiTotal}
            showObsoleteLabels
            onChange={onChange}
            hasError={hasError}
            disabled={disabled}
            loadMore={loadMore}
            isLoading={apiLoading}
            isClearable={isClearable}
            isCreatable={isCreatable}
            setSearchName={handleSearch}
            value={adaptedVocabularySelectValue}
          />
        </>
      </div>
    );
  },
);
