import { registerEntityWidget } from '../../registry';
import NonCoreVocabularyComponent from './NonCoreVocabularyComponent';

registerEntityWidget('NonCoreVocabularyProperties', {
  name: 'NonCoreVocabularyProperties',
  displayName: 'Non-core Vocabulary Keys',
  description: 'Widget for Non-core Vocabulary Keys',
  noHeader: true,
  view: NonCoreVocabularyComponent,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
