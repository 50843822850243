import React from 'react';
import PropTypes from 'prop-types';

const TextWithIconStyle = {
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  maxWidth: '155px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'inline-block',
  verticalAlign: 'bottom',
  flexGrow: 9,
  paddingLeft: '6px',
  flexBasis: 'auto',
  width: '100%',
};

const TextWithIcon = ({ children }) => (
  <span style={TextWithIconStyle}>{children}</span>
);

TextWithIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextWithIcon;
