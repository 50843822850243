"use strict";

exports.__esModule = true;
exports.TextFieldStyles = void 0;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var TextFieldStyles = function TextFieldStyles(_ref) {
  var disabled = _ref.disabled,
    className = _ref.className,
    theme = _ref.theme;
  var disabledStyle = disabled ? {
    border: '1px solid #D7D7D8',
    boxShadow: 'none'
  } : {};
  var bg = (className || '').indexOf('has-error') > -1 ? {
    border: "1px solid " + theme.palette.red
  } : {};
  return {
    fieldGroup: _extends({}, bg),
    root: [{
      input: _extends({
        borderRadius: '2px',
        boxShadow: '0px 1px 3px #0000001A'
      }, disabledStyle)
    }]
  };
};
exports.TextFieldStyles = TextFieldStyles;