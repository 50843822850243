import { useState } from 'react';
import styled from 'styled-components';
import { DataGridv2 } from '@cluedin/list-pattern';
import { AnimatedArrow, Noaccess } from '@cluedin/components';
import { useTheme } from '@cluedin/theme';
import { ButtonLink } from '@cluedin/atoms';
import cogoToast from 'cogo-toast';
import {
  FormattedMessage,
  injectIntl,
  FormattedHTMLMessage,
} from '@cluedin/locale';
import { Loader, GqlErrorMessages, Button, PrimaryButton } from '@cluedin/form';

import { useCreateRoleRequest } from '../../../task/hooks/useRoleTaskMutation';

const Wrapper = styled.div`
  display: flex;
  height: ${({ height }) => height};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const IconStyle = styled.div`
  margin-bottom: 24px;

  svg path,
  svg circle,
  svg ellipse,
  svg rect {
    fill: ${({ themePrimary }) => themePrimary};
  }
`;

const Title = styled.div`
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #000;
  opacity: 0.8;
  margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ShowMoreLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 160px;
  cursor: pointer;
`;

const GridStyle = styled.div`
  max-width: 600px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  margin-top: 16px;
`;

const raciLevelToTransKeyMap = {
  Accountable: 'module-role-raciAccountable',
  Responsible: 'module-role-raciResponsible',
  Consultant: 'module-role-raciConsultant',
  Informed: 'module-role-raciInformed',
};

const PageAccessDenied = ({
  onReturnToHome,
  claims,
  intl,
  height = 'calc(100vh - 108px)',
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const translatedClaims = claims.map(({ level, ...rest }) => ({
    level: <FormattedMessage id={raciLevelToTransKeyMap[level]} />,
    ...rest,
  }));

  const [showMore, setShowMore] = useState(false);

  const [requestAccess, { done, loading, error }] = useCreateRoleRequest();

  const handleRequest = async () => {
    try {
      if (claims && claims.length > 0) {
        claims.forEach(async (c) => {
          await requestAccess(c?.claim);
        });
      }

      cogoToast.success(
        intl.formatMessage({ id: 'module-request-for-role-success' }),
        { position: 'bottom-right', hideAfter: 5 },
      );
    } catch {
      cogoToast.error(
        intl.formatMessage({ id: 'module-request-for-role-error' }),
        { position: 'bottom-right', hideAfter: 5 },
      );
    }
  };

  return (
    <Wrapper height={height}>
      <IconStyle themePrimary={themePrimary}>
        <Noaccess size={140} />
      </IconStyle>

      <Title>
        <FormattedMessage id="module-error-pageAccessDeniedTitle" />
      </Title>
      <Text>
        <FormattedHTMLMessage id="module-error-pageAccessDeniedContent" />
      </Text>
      <ButtonsWrapper>
        {onReturnToHome && (
          <PrimaryButton
            text={<FormattedMessage id="module-error-returnHome" />}
            onClick={onReturnToHome}
            type="primary"
            className="__test_AccessDeniedReturnToHomeButton"
          />
        )}

        <div data-test={'request-access'}>
          <Button
            style={{ marginLeft: '12px' }}
            disabled={loading || done}
            startIcon={loading ? <Loader /> : ''}
            text={<FormattedMessage id="module-error-requestAccess" />}
            onClick={handleRequest}
            className="__test_AccessDeniedRequestAccessButton"
          />
        </div>
      </ButtonsWrapper>
      {error && (
        <div style={{ maxWidth: '800px', margin: '12px' }}>
          <GqlErrorMessages error={error} />
        </div>
      )}
      <ShowMoreLink onClick={() => setShowMore(!showMore)}>
        <ButtonLink>
          {showMore ? (
            <FormattedMessage id="module-error-hideReqAccess" />
          ) : (
            <FormattedMessage id="module-error-viewReqAccess" />
          )}
        </ButtonLink>

        <AnimatedArrow
          size={12}
          open={showMore}
          color={themePrimary}
          style={{ marginTop: !showMore && '8px' }}
        />
      </ShowMoreLink>
      <GridStyle visible={showMore}>
        <DataGridv2
          data={translatedClaims}
          model={[
            {
              displayName: <FormattedMessage id="module-error-claimRequired" />,
              property: 'claim',
            },
            {
              displayName: <FormattedMessage id="module-error-lvlRequired" />,
              property: 'level',
            },
          ]}
        />
      </GridStyle>
    </Wrapper>
  );
};

export default injectIntl(PageAccessDenied);
