import { FormattedMessage } from '@cluedin/locale';
import { registerEntityWidget } from '../../registry';
import EntityDataKPIList from '../../../../entityViewModel/components/composites/entity/EntityDataKPIList';

registerEntityWidget('EntityDataKPIs', {
  name: 'Entity data KPIs',
  displayName: (
    <span data-test="entity-data-kpis">
      <FormattedMessage id="module-entityWidget-entityDataKpis" />
    </span>
  ),
  description: 'Shows entity data quality (quality, accuracy, etc.)',
  view: EntityDataKPIList,
  // relatedProviders: [
  //   'all',
  // ],
  tags: [],
  requireEntity: true,
  // requireEntity: false,
});
