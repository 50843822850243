import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';
import { getGlobalStatus } from './utils';

export const GET_STATUS = gql`
  query {
    notification {
      serverStatus
    }
  }
`;

export const useServerStatus = () => {
  const { data, loading, error } = useQuery(GET_STATUS, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  });
  const statuses = get(data, 'notification.serverStatus');

  const globalStatus = getGlobalStatus(statuses || {});

  return [statuses, loading, error, globalStatus];
};
