import { useQuery, gql } from '@apollo/client';
import { get, pick } from 'lodash';
import { legacyClient } from '../../../data/graphql';

const GET_SEARCH_COUNT = gql`
  query searchCount(
    $query: String
    $filter: String
    $filters: [FilterQuery]
    $terms: [TermQuery]
    $contextIds: [Guid]
    $rule: String
    $includeExternalData: Boolean
    $includeUnstructuredData: Boolean
  ) {
    count(
      query: $query
      filter: $filter
      filters: $filters
      terms: $terms
      rule: $rule
      contextIds: $contextIds
      includeExternalData: $includeExternalData
      includeUnstructuredData: $includeUnstructuredData
    ) {
      count
    }
  }
`;

export const useSearchCount = (allVariables) => {
  const variables = pick(allVariables, [
    'query',
    'filter',
    'filters',
    'rule',
    'terms',
    'contextIds',
    'includeExternalData',
    'includeUnstructuredData',
    'cursor',
  ]);

  const { data, loading, error, refetch } = useQuery(GET_SEARCH_COUNT, {
    client: legacyClient,
    variables,
    fetchPolicy: 'network-only',
  });

  const total = get(data, 'count.count');

  return [
    total,
    {
      loading,
      error,
      refetch,
    },
  ];
};
