import styled from 'styled-components';

const NotificationWrapper = styled.div`
  padding: 16px;
  border: 1px solid #d7d7d8;
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background: #fff;
  margin-bottom: 16px;
  transition: opacity 0.15s ease-in-out;
  outline: none;
  opacity: ${({ showShadow }) => (showShadow ? '0.95' : '1')};
  ${({ showShadow }) => {
    return showShadow
      ? `
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    `
      : '';
  }}
`;

export default NotificationWrapper;
