import React, { memo } from 'react';
import { Select } from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';
import RuleItemPreview from './RuleItemPreview';
import { getFriendlyOperatorName } from '../../../utils/getFriendlyOperatorName';

const filterOperatorsByScope = (operators = [], scope) => {
  return operators?.filter((i) => {
    if (!i?.apply_to_scopes) {
      return true;
    }

    return i?.apply_to_scopes?.indexOf(scope) > -1;
  });
};

const filterUnsupportedOperators = (operators = [], vocabularyKey) => {
  // Only allow Equals/Not Equal for Computed Properties
  // Others are not supported for now
  const isLookupComposite =
    vocabularyKey?.compositeVocabulary?.dataType == 'Lookup';

  if (!isLookupComposite) {
    return operators;
  }

  return operators?.filter((i) => {
    return i.friendlyName === 'Equals' || i.friendlyName === 'Not Equal';
  });
};

const findOperators = (operators = [], propertyType, applyToKey) => {
  return operators?.filter(
    (operator) => operator?.[applyToKey]?.indexOf(propertyType) > -1,
  );
};

export const RuleItemOperatorSelector = memo(
  injectIntl(
    ({
      intl,
      value,
      scope,
      operators,
      propertyType,
      vocabularyKey,
      ruleItemIndex,
      operatorHasError,
      isVocabStrongTyped,
      isVocabularyProperty,
      onChangeOperatorType,
      previewMode,
    }) => {
      const applyToKey =
        isVocabularyProperty && isVocabStrongTyped
          ? 'apply_to_vocabularykey_datatypes'
          : 'apply_to';

      const filteredOperatorsByScope = filterOperatorsByScope(operators, scope);
      const supportedOperatorsByScope = filterUnsupportedOperators(
        filteredOperatorsByScope,
        vocabularyKey,
      );

      const operatorsToShow = propertyType
        ? findOperators(supportedOperatorsByScope, propertyType, applyToKey) ||
          []
        : [];

      const operatorOptions = operatorsToShow.map((i) => ({
        value: i.id,
        label: i.friendlyName,
      }));

      const handleChangeOperatorType = ({ value: newValue }) =>
        onChangeOperatorType(newValue, ruleItemIndex);

      return (
        <div style={{ marginLeft: '6px', minWidth: '170px' }}>
          {previewMode ? (
            <RuleItemPreview text={getFriendlyOperatorName(value, operators)} />
          ) : (
            <Select
              value={value}
              options={operatorOptions}
              hasError={operatorHasError}
              onChange={handleChangeOperatorType}
              placeholder={intl.formatMessage({ id: 'module-rule-chooseOps' })}
            />
          )}
        </div>
      );
    },
  ),
);
