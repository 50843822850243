import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import cogoToast from 'cogo-toast';

const SAVE_CONFIGURATION_AUTH_METHODS = gql`
  mutation saveAuthConfiguration($configurationId: ID, $settings: JSON) {
    inbound {
      id
      saveIntegrationConfigurationAuthSettings(
        configurationId: $configurationId
        settings: $settings
      ) {
        id
      }
    }
  }
`;

export const useSaveIntegrationConfigurationAuth = (configurationId) => {
  const [saveAuthMethod, { data, loading, error }] = useMutation(
    SAVE_CONFIGURATION_AUTH_METHODS,
  );
  const id = get(data, 'inbound.saveIntegrationConfigurationAuthSettings.id');

  useEffect(() => {
    if (id) {
      cogoToast.success(`Configuration saved`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  }, [id]);

  return [
    (authValues) => {
      saveAuthMethod({
        variables: {
          configurationId,
          settings: authValues,
        },
      });
    },
    {
      loading,
      error,
      success: !!id,
    },
  ];
};
