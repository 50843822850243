import styled from 'styled-components';

export const CodeExpansionWrapper = styled.div<{
  isExpanded: boolean;
}>`
  align-items: center;
  background: ${({ isExpanded }) => (isExpanded ? '#f4fbfb' : '#fff')};
  border: 1px solid #ececec;
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
  padding: 14px;
  transition: all 100ms ease;

  &:hover {
    background: ${({ isExpanded }) => (isExpanded ? '#d3f0ee' : '#fafafa')};
  }
`;
