import React from 'react';
import Flex from 'uxi/Layout/Flex';
import { FormattedMessage } from '@cluedin/locale';
import { Button } from '@cluedin/form';
import { withIntegrationConfigFromConfiguration } from '../../../integration/components/hocs/withIntegrationConfig';
import IntegrationIcon from '../../../integration/components/composites/IntegrationIcon';

const ConfigurationPanelContentItem = ({
  configuration,
  integrationConfig,
}) => {
  return (
    <Flex
      style={{
        margin: '8px 0',
        padding: '8px 0',
        width: '100%',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #cecece',
      }}
    >
      <Flex>
        <IntegrationIcon integration={integrationConfig} />
        <div style={{ paddingLeft: '4px' }}>
          <div>{configuration.Name}</div>
          <div>
            {configuration.AccountDisplay ||
              `Account Id: ${configuration.AccountId}`}
          </div>
        </div>
      </Flex>
      <Flex style={{ marginLeft: 'auto' }}>
        <Button
          href={`/admin/configuration/${configuration.ProviderId}/${configuration.Id}/`}
          text={<FormattedMessage id="module-home-view" />}
        />
      </Flex>
    </Flex>
  );
};

export default withIntegrationConfigFromConfiguration(
  ConfigurationPanelContentItem,
);
