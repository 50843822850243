import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

export const allEntityProperties = [
  {
    property: 'name',
    displayName: <FormattedMessage id="module-dataCatalog-name" />,
    type: 'entity',
    description: <FormattedMessage id="module-dataCatalog-nameExpl" />,
    fixed: true,
  },
  {
    property: 'entityType',
    displayName: <FormattedMessage id="module-dataCatalog-entityType" />,
    description: <FormattedMessage id="module-dataCatalog-entityExpl" />,
    type: 'entity',
  },
  {
    property: 'description',
    displayName: <FormattedMessage id="module-dataCatalog-description" />,
    description: <FormattedMessage id="module-dataCatalog-descriptionExpl" />,
    type: 'entity',
  },
  {
    property: 'createdDate',
    type: 'entity',
    displayName: <FormattedMessage id="module-dataCatalog-createdDate" />,
    description: <FormattedMessage id="module-dataCatalog-createdDateExpl" />,
  },
  {
    property: 'discoveryDate',
    type: 'entity',
    displayName: <FormattedMessage id="module-dataCatalog-discoveryDate" />,
    description: <FormattedMessage id="module-dataCatalog-discoveryDateExpl" />,
  },
  {
    property: 'modifiedDate',
    displayName: <FormattedMessage id="module-dataCatalog-modifiedDate" />,
    type: 'entity',
    description: <FormattedMessage id="module-dataCatalog-modifiedDateExpl" />,
  },
];
