import React from 'react';
import { compose, lifecycle } from 'recompose';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import { FormattedMessage } from '@cluedin/locale';

import { isUserBlocked } from '../../../user/helper';
import UserListContainer from '../../../user/components/containers/lists/UserListContainer';

const UserPickerDialog = ({
  children,
  onClose,
  show,
  style,
  SetProviderOwnerComponent,
}) => (
  <div style={style}>
    {children}
    <Dialog onClose={onClose} show={show} style={{ zIndex: 200 }} isFullScreen>
      <Panel
        style={{ width: '100%', background: '#fff' }}
        onClose={onClose}
        rounded
      >
        <PanelHeader
          hasClose
          title={
            <FormattedMessage
              id="module-core-selectProductOwner"
              defaultMessage="Product Owner Management"
            />
          }
        />
        <PanelContent>
          <UserListContainer
            hideInvite
            filter={(user) => isUserBlocked(user)}
            condensed
            notSelectable
            ActionTitle={
              <FormattedMessage
                id="module-core-hasProductOwner"
                defaultMessage="Status"
              />
            }
            Action={SetProviderOwnerComponent}
            actionStyle={{ width: '90px' }}
          />
        </PanelContent>
        <PanelFooter
          hasCancel
          cancelLabel={
            <FormattedMessage
              id="module-core-selectProductOwnerCloseLabel"
              defaultMessage="Close"
            />
          }
        />
      </Panel>
    </Dialog>
  </div>
);

export default compose(
  lifecycle({
    componentWillUnmount() {
      const { onClose } = this.props;
      onClose();
    },
  }),
)(UserPickerDialog);
