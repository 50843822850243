import EntityTable from '../composites/EntityTable';
import { withPropertyViewModel } from '../hocs/withPropertyViewModel';
// import { withListPropertySelection } from '../hocs/withPropertySelection';
// import { gridViewFields } from '../../defaultViewOptions';

export default withPropertyViewModel(
  EntityTable,
  //   {
  //     standardFields: gridViewFields,
  //   },
  // )
);
