import {
  createAsyncAction,
  defaultAsyncCallWithActionsWithParam,
} from '../../action/helpers/actionCreator';
import { getValuesForSchemaKey } from './data';

export const fetchValuesForSchemaPropertyKeyActions = createAsyncAction(
  'VALUES_FOR_SCHEMA_PROPERTY_KEY',
);

export const shouldFetchValuesForSchemaPropertyKey =
  defaultAsyncCallWithActionsWithParam(
    getValuesForSchemaKey,
    fetchValuesForSchemaPropertyKeyActions,
  );
