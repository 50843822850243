import { gql, useQuery } from '@apollo/client';

export const GET_CLEAN_PROJECT_ENTITY_PROPS = gql`
  query getCleanEntityProps {
    preparation {
      getCleanEntityProps
    }
  }
`;

type CleanProjectEntityPropsResponse = {
  preparation: {
    getCleanEntityProps: string[];
  };
};

const fetchPolicy = 'network-only';

export const useCleanProjectEntityProps = () => {
  const { data, loading, error, refetch } =
    useQuery<CleanProjectEntityPropsResponse>(GET_CLEAN_PROJECT_ENTITY_PROPS, {
      fetchPolicy,
    });

  const cleanEntityProps = data?.preparation?.getCleanEntityProps ?? [];

  return {
    error,
    loading,
    refetch,
    data: cleanEntityProps,
  };
};
