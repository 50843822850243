import React from 'react';
import AddFilterInput from './AddFilterInput';
import RelativeTextPlaceholder from '../../../../../../../core/components/composites/Placeholder/RelativeTextPlaceholder';
import styles from './styles';

const { accent } = styles;

const FilterGroupInput = (props) => {
  const {
    currentFilterQuery,
    filterType,
    currentEntityTypePoolIdentifier,
    isFetching,
    onFilterQueryChange,
    onFocusIn,
  } = props;

  if (
    currentEntityTypePoolIdentifier &&
    filterType.value.toLowerCase() === 'entitytype'
  ) {
    return <div />;
  }

  return (
    <div>
      {isFetching && !currentFilterQuery ? (
        <RelativeTextPlaceholder
          color={accent}
          style={{ width: '56%', height: '14px', margin: '2px 0 1px' }}
        />
      ) : (
        <AddFilterInput
          currentFilterQuery={currentFilterQuery}
          onFilterQueryChange={onFilterQueryChange}
          filterType={filterType}
          onFocusIn={onFocusIn}
        />
      )}
    </div>
  );
};

FilterGroupInput.displayName = 'FilterGroupInput';

export default FilterGroupInput;
