import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { searchSuggestionsActionsWatcherSaga } from '../../modules/search/actions';
import { env } from '../../config';

const isProduction = env === 'production';

const sagaMiddleware = createSagaMiddleware();

const getRegistry = () => {
  let registry = window.__cluedin_registry; //eslint-disable-line
  if (!registry) {
    registry = {};
    registry.reducers = {}; // registry.reducers || {};
    registry.middlewares = []; //
    window.__cluedin_registry = registry; //eslint-disable-line
  }

  return registry;
};

export const register = (storeName, reducer, middlewares) => {
  const registry = getRegistry();

  if (registry.reducers[storeName]) {
    console.error('Trying to register the same store');
    return;
  }
  if (middlewares && middlewares.length) {
    registry.middlewares.push.apply(registry.middlewares, middlewares); //eslint-disable-line
  }
  registry.reducers[storeName] = reducer;
};

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || //eslint-disable-line
  compose;

export const compileStore = (initialState, history) => {
  const registry = getRegistry();

  let allMiddleware = [
    routerMiddleware(history),
    thunkMiddleware,
    sagaMiddleware,
  ];

  /**
   * Redux logger middleware:
   * ! Always disabled in prod, no matter what
   * leave it commented there for easy dev purpose if needed
   *
   */
  if (!isProduction) {
    allMiddleware.push(createLogger());
  }

  allMiddleware = allMiddleware.concat([...registry.middlewares]);

  // HACK: bit of a hack to merge the widget reducer into the root
  const widgetReducer = { ...registry.reducers.widget };
  delete registry.reducers.widget;

  const router = connectRouter(history);

  const rootReducer = combineReducers({
    router,
    ...widgetReducer,
    ...registry.reducers,
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...allMiddleware)),
  );

  /**
   * init sagas watcher */
  sagaMiddleware.run(searchSuggestionsActionsWatcherSaga);

  return store;
};

export default {
  register,
  compileStore,
};
