import React from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import { injectIntl } from '@cluedin/locale';
import { FormDecorator, Select } from '@cluedin/form';

import config from '../../../config';
import { shouldFetchLocalize } from '../actions';

const ChangeCultureSelectContainer = injectIntl(
  ({ intl, currentCulture, changeCulture }) => {
    if (config.languages?.length < 2) {
      return null;
    }

    let candidateLanguages = [];

    config.languages.forEach((languagePath) => {
      const lgToUser = (languagePath || '').split('.');

      if (lgToUser.length === 2) {
        candidateLanguages.push({
          label: capitalize(lgToUser[0]),
          value: lgToUser[0],
        });
      }
    });

    if (candidateLanguages.length === 0) {
      return null;
    }

    const handleChange = ({ value }) => {
      changeCulture(value);
    };

    return (
      <>
        <div style={{ padding: '10px 24px' }}>
          <FormDecorator
            label={intl.formatMessage({
              id: 'module-userMenu-select-language',
            })}
          >
            <Select
              options={candidateLanguages}
              value={currentCulture}
              onChange={handleChange}
            />
          </FormDecorator>
        </div>
      </>
    );
  },
);

const mapToStateProps = ({ locale: { currentCulture } }) => ({
  currentCulture,
});

const mapDispatchToProps = (dispatch) => ({
  changeCulture(value) {
    dispatch(shouldFetchLocalize(value));
  },
});

export const ChangeCultureSelect = connect(
  mapToStateProps,
  mapDispatchToProps,
)(ChangeCultureSelectContainer);
