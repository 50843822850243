export const gridViewFields = [
  {
    index: 0,
    id: 'name',
    field: 'name',
    fixed: true,
    disabled: false,
  },
  {
    index: 1,
    id: 'entityType',
    field: 'entityType',
    disabled: false,
  },
  {
    index: 2,
    id: 'created',
    field: 'created',
    disabled: false,
  },
];

export const tileViewFields = [
  {
    index: 0,
    id: 'description',
    field: 'description',
    disabled: false,
  },
];

export const allLists = [
  {
    name: 'grid',
    standardFields: [...gridViewFields],
    fields: [...gridViewFields],
  },
  {
    name: 'tile',
    restriction: 2,
    standardFields: [...tileViewFields],
    fields: [...tileViewFields],
  },
];

export const searchList = {
  name: 'search',
  viewMode: 'tile',
  views: allLists,
};

export const standAloneListOptions = {
  viewMode: 'grid',
  views: allLists,
};

export const suggestedSearch = {
  name: 'suggestedSearch',
  viewMode: 'tile',
  views: allLists,
};

export default [searchList, suggestedSearch];
