import React from 'react';
import PropTypes from 'prop-types';
import { User } from 'uxi/Icons';
import Toast from '../../../../core/components/composites/Toast';

const NewUserPromoteNotification = ({ index, userPromoted, onCloseClick }) => {
  const title = `${userPromoted.userName} was promoted as Administrator`;
  const message = <div>by&nbsp;{userPromoted.authorUserName}</div>;

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={User}
    />
  );
};

NewUserPromoteNotification.propTypes = {
  index: PropTypes.any.isRequired,
  userPromoted: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default NewUserPromoteNotification;
