import { useQuery, ApolloError } from '@apollo/client';
import gql from 'graphql-tag';
import { values, get } from 'lodash';

import { Operators } from '../types/ruleConditionTypes';

const GET_RULE_OPERATORS = gql`
  query getRuleOperators {
    management {
      id
      ruleOperators
    }
  }
`;

type OperatorsData = {
  operators: Operators;
};

type Return = OperatorsData & {
  loading: boolean;
  error: ApolloError | undefined;
};

export const useQueryOperators = (): Return => {
  const { data, loading, error } = useQuery(GET_RULE_OPERATORS, {
    fetchPolicy: 'network-only',
  });

  const operatorsData = data?.management?.ruleOperators;

  const operators = values(operatorsData || {});

  return {
    operators,
    loading,
    error,
  };
};
