import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MenuSubItem } from './MenuSubItem';
import { CollapseElement } from '../CollapseElement';

const MenuTopItemLink = styled(Link)`
  width: 100%;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  flex-direction: ${({ isMinimized }) => (isMinimized ? 'column' : 'row')};
  font-size: ${({ isMinimized }) => (isMinimized ? '10px' : '14px')};
  padding: ${({ isMinimized }) => (isMinimized ? '16px 0' : '10px 31px')};
  color: ${({ isActive, menuTheme }) =>
    isActive ? menuTheme.accent : menuTheme.color}!important;
  font-weight: ${({ isActive }) => (isActive ? '600' : 'normal')};

  span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  border-right: ${({ isActive, menuTheme }) =>
    isActive ? `4px solid ${menuTheme.accent}` : 'none'};

  &:hover,
  &:focus {
    transition: inherit;
  }

  &:hover {
    text-decoration: none;
    color: ${({ menuTheme }) => menuTheme.accent}!important;

    svg {
      fill: ${({ menuTheme }) => menuTheme.accent};
    }
  }
`;

const MenuLinkIconWrapper = styled.i`
  margin-right: ${({ isMinimized }) => (isMinimized ? '0' : '10px')};

  svg {
    fill: ${({ isActive, menuTheme }) =>
      isActive ? menuTheme.accent : menuTheme.color};
  }
`;

const SubItemsWrapper = styled.div`
  padding-bottom: 20px;
  padding-top: 5px;
`;

const MenuTopItemElement = styled.div``;

export const MenuTopItem = memo(
  ({ href, icon, subItems, isActive, isMinimized, displayName, menuTheme }) => {
    return (
      <MenuTopItemElement>
        <MenuTopItemLink
          to={href}
          isActive={isActive}
          isMinimized={isMinimized}
          menuTheme={menuTheme}
        >
          {icon && (
            <MenuLinkIconWrapper
              isActive={isActive}
              isMinimized={isMinimized}
              menuTheme={menuTheme}
            >
              {icon}
            </MenuLinkIconWrapper>
          )}

          {!isMinimized && displayName}
        </MenuTopItemLink>

        {subItems?.length > 0 && (
          <CollapseElement isVisible={isActive && !isMinimized}>
            <SubItemsWrapper>
              {subItems.map((i, idx, arr) => {
                const featureName = i?.extendPillarDashboard?.actions?.find?.(
                  (f) => f?.featureName,
                )?.featureName;
                const featureProps = featureName
                  ? { featureName: featureName }
                  : {};

                return (
                  <MenuSubItem
                    key={i.key}
                    href={i.href}
                    icon={i.icon}
                    isActive={i.isActive}
                    subItems={i.subItems}
                    menuTheme={menuTheme}
                    displayName={i.displayName}
                    isLast={arr.length - 1 === idx}
                    {...featureProps}
                  />
                );
              })}
            </SubItemsWrapper>
          </CollapseElement>
        )}
      </MenuTopItemElement>
    );
  },
);
