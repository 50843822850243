export const getLabelStatus = (key) => {
  let type = key;

  if (key === 'sql') {
    type = 'Relational Database';
  }

  if (key === 'elasticSearch') {
    type = 'Search Database';
  }

  if (key === 'rabbitMq') {
    type = 'Bus';
  }

  return type;
};

const getTotalMicroServices = (data) => {
  return (Object.keys(data || {}) || []).length;
};

const getMicroServiceUp = (data) => {
  const allServicesName = Object.keys(data || {}) || [];

  const statuses = (allServicesName || []).map((key) => {
    const v = data[key];

    return v;
  });

  return (statuses || []).filter((v) => {
    return v === true;
  }).length;
};

const getUILabelForStatues = (numberOfGreen, total) => {
  let status;

  if (numberOfGreen === 0) {
    status = 'down';
  }

  if (numberOfGreen > 0 && numberOfGreen < total) {
    status = 'degraded';
  }

  if (numberOfGreen === total) {
    status = 'up';
  }

  return status;
};

const getServerStatusUp = (data) => {
  return (data || []).filter((v) => {
    return v.ServiceStatus === 'Green';
  }).length;
};

const getTotalServices = (statuses) => {
  const serverShards = statuses?.server?.DataShards || [];
  const componentShards = statuses?.server?.Components || [];
  const dataSource = statuses?.dataSource;
  const mapping = statuses?.mapping;

  const dataSourceNumberServices = getTotalMicroServices(dataSource);
  const mappingNumberServices = getTotalMicroServices(mapping);

  return (
    serverShards.length +
    componentShards.length +
    dataSourceNumberServices +
    mappingNumberServices
  );
};

export const getMicroServiceGlobalLabel = (data, clusterDown) => {
  if (clusterDown) {
    return 'down';
  }
  const allServicesCount = getTotalMicroServices(data);
  const numberOfGreen = getMicroServiceUp(data);
  return getUILabelForStatues(numberOfGreen, allServicesCount);
};

export const getServerServiceGlobalLabel = (data, clusterDown) => {
  if (clusterDown) {
    return 'down';
  }
  const numberOfGreen = getServerStatusUp(data);
  return getUILabelForStatues(numberOfGreen, data.length);
};

const getTotalGreen = (statuses) => {
  const serverShardsGreenNumber = getServerStatusUp(
    statuses?.server?.DataShards || [],
  );
  const serverComponentsGreenNumber = getServerStatusUp(
    statuses?.server?.Components || [],
  );
  const dsGreenNumber = getMicroServiceUp(statuses?.dataSource || {});
  const mappingGreenNumber = getMicroServiceUp(statuses?.mapping || {});

  return (
    serverShardsGreenNumber +
    serverComponentsGreenNumber +
    dsGreenNumber +
    mappingGreenNumber
  );
};

export const getGlobalStatus = (statuses) => {
  const total = getTotalServices(statuses);
  const totalGreen = getTotalGreen(statuses);

  if (totalGreen === 0) {
    return 'down';
  }
  if (totalGreen < total) {
    return 'degraded';
  }

  if (total === totalGreen) {
    return 'up';
  }

  return 'down';
};
