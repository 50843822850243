import React from 'react';
import get from 'lodash/get';
import { useIntl } from 'react-intl';

import { EntityCodePanelHeaderWrapper } from './EntityCodePanelHeader.styles';
import EntityTopologyConnectingNodeIcon from '../../../composites/EntityTopologyConnectingNodeIcon/EntityTopologyConnectingNodeIcon';
import EntityTopologyHeaderDetail from '../../../composites/EntityTopologyHeaderDetail/EntityTopologyHeaderDetail';

const EntityCodePanelHeader = ({ data, title, currentNode }) => {
  const subtitle = get(currentNode, 'subLabel');
  const dataType = get(currentNode, 'dataType');

  const { formatMessage } = useIntl();

  return (
    <EntityCodePanelHeaderWrapper>
      <EntityTopologyConnectingNodeIcon dataType={dataType} />

      <EntityTopologyHeaderDetail
        data={data}
        title={title}
        subtitle={subtitle}
        labels={{
          modified: formatMessage({
            id: 'module-entityTopology-splitPanelDetailModified',
          }),
          created: formatMessage({
            id: 'module-entityTopology-splitPanelDetailCreated',
          }),
          discovered: formatMessage({
            id: 'module-entityTopology-splitPanelDetailDiscovered',
          }),
        }}
      />
    </EntityCodePanelHeaderWrapper>
  );
};

export default EntityCodePanelHeader;
