import React from 'react';
import styled from 'styled-components';
import StatusBadge from './StatusBadge';
import { getMicroServiceGlobalLabel } from './utils';

const ServerStatusLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MicroServiceStatusLabel = ({ label, data, clusterDown }) => {
  const status = getMicroServiceGlobalLabel(data, clusterDown);

  return (
    <ServerStatusLabelWrapper>
      <div>{label}</div>
      <div>
        <StatusBadge
          status={status}
          style={{
            marginLeft: '6px',
            width: '12px',
            height: '12px',
          }}
        />
      </div>
    </ServerStatusLabelWrapper>
  );
};

export default MicroServiceStatusLabel;
