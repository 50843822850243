import React from 'react';
import FieldItemDecorator from './FieldItemDecorator';
import SchemaPropertyLinkWithPopover from '../SchemaPropertyLinkWithPopover';

const SchemaFieldItem = ({ fieldName, fieldSchema, style, onDeleteField }) => {
  return (
    <FieldItemDecorator style={style} onDeleteField={onDeleteField}>
      <div>{fieldSchema ? fieldSchema.DisplayName : fieldName}</div>
      <SchemaPropertyLinkWithPopover propertyKey={fieldName} />
    </FieldItemDecorator>
  );
};

export default SchemaFieldItem;
