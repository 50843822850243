import { RACI_LEVELS } from '@cluedin/components';

import routes from './routes';

export const rootURL = '/admin/management/deduplication';

export default {
  hide: true,
  pillar: 'management',
  name: 'Deduplication',
  displayName: 'Deduplication',
  path: '/deduplication',
  claims: {
    'management.deduplicationprojectmanagement': RACI_LEVELS.INFORMED,
  },
  routes,
};
