"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(FormFieldLabel) {
  return (0, _styledComponents.default)(FormFieldLabel)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  ", "\n  lineHeight: 1,\npadding: 0,\n&.focused {\n    color: ", ";\n  }\n  &.disabled {\n    color: ", ";\n  }\n  &.error {\n    color: ", ";\n  }\n  & .asterisk {\n    color: ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.text.secondary;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.typography.body1;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.primary.main;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.text.disabled;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.colors.semantic.error.main;
  }, function (_ref6) {
    var theme = _ref6.theme;
    return theme.colors.semantic.error.main;
  });
};
exports.default = _default;