import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Flex } from 'uxi/Layout';

const EmptySearch = (/* props */) => {
  return (
    <div>
      <Flex
        style={{ margin: '96px auto', textAlign: 'center', padding: '0 16px' }}
      >
        <FormattedMessage
          id="SearchContent.NoSearchResult"
          defaultMessage="We couldn’t find any information using the current search parameters."
        />
      </Flex>
    </div>
  );
};

export default EmptySearch;
