"use strict";

exports.__esModule = true;
exports.useFilter = void 0;
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _omit = _interopRequireDefault(require("lodash/omit"));
var _hooks = require("../../../hooks");
var _useResetPageNo = require("./useResetPageNo");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useFilter = function useFilter(history, location, options) {
  if (options === void 0) {
    options = {};
  }
  var queryStringOptions = (0, _omit.default)(options, ['pageNoLabel', 'keyNames']);
  var _options = options,
    _options$pageNoLabel = _options.pageNoLabel,
    pageNoLabel = _options$pageNoLabel === void 0 ? 'pageNo' : _options$pageNoLabel,
    keyNames = _options.keyNames;
  var _useQueryString = (0, _hooks.useQueryString)(history, location, keyNames, queryStringOptions),
    currentValues = _useQueryString[0],
    setValues = _useQueryString[1],
    removeValues = _useQueryString[2];
  var resetPageNo = (0, _useResetPageNo.useResetPageNo)(history, location, pageNoLabel);
  var setFilterValues = function setFilterValues(values) {
    if ((0, _isEqual.default)(values, currentValues)) {
      return;
    }
    if (!values) {
      removeValues();
    } else {
      setValues(values);
    }
    resetPageNo();
  };
  return [currentValues, setFilterValues];
};
exports.useFilter = useFilter;