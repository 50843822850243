import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';

import {
  EntityTopologySplitNewDataPartIconWrapper,
  EntityTopologySplitNewDataPartConnectorIcon,
} from './EntityTopologySplitNewDataPartIcon.styles';

const EntityTopologySplitNewDataPartIcon = ({ provider }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const themeWhite = theme?.palette?.white;

  return (
    <EntityTopologySplitNewDataPartIconWrapper
      data-test={`entity-entityTopology-newDataPartIcon-${provider?.name}`}
      themePrimary={themePrimary}
      themeWhite={themeWhite}
    >
      <EntityTopologySplitNewDataPartConnectorIcon
        dangerouslySetInnerHTML={{ __html: provider?.providerIcon }}
      />
    </EntityTopologySplitNewDataPartIconWrapper>
  );
};

export default EntityTopologySplitNewDataPartIcon;

EntityTopologySplitNewDataPartIcon.propTypes = {
  provider: PropTypes.object,
};

EntityTopologySplitNewDataPartIcon.defaultProps = {
  provider: {},
};
