"use strict";

exports.__esModule = true;
exports.admin = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ADMIN_ENTITYTYPES_CONSULTANT = _claimTypes.default.ADMIN_ENTITYTYPES_CONSULTANT,
  ADMIN_ENTITYTYPES_INFORMED = _claimTypes.default.ADMIN_ENTITYTYPES_INFORMED,
  ADMIN_DATAMANAGEMENT_CONSULTANT = _claimTypes.default.ADMIN_DATAMANAGEMENT_CONSULTANT,
  ADMIN_DATAMANAGEMENT_INFORMED = _claimTypes.default.ADMIN_DATAMANAGEMENT_INFORMED,
  ADMIN_ROLES_CONSULTANT = _claimTypes.default.ADMIN_ROLES_CONSULTANT,
  ADMIN_ROLES_INFORMED = _claimTypes.default.ADMIN_ROLES_INFORMED,
  ADMIN_USERS_CONSULTANT = _claimTypes.default.ADMIN_USERS_CONSULTANT,
  ADMIN_USERS_INFORMED = _claimTypes.default.ADMIN_USERS_INFORMED,
  ADMIN_TOKENMANAGEMENT_CONSULTANT = _claimTypes.default.ADMIN_TOKENMANAGEMENT_CONSULTANT,
  ADMIN_TOKENMANAGEMENT_INFORMED = _claimTypes.default.ADMIN_TOKENMANAGEMENT_INFORMED,
  ADMIN_TENANTMANAGEMENT_CONSULTANT = _claimTypes.default.ADMIN_TENANTMANAGEMENT_CONSULTANT,
  ADMIN_TENANTMANAGEMENT_INFORMED = _claimTypes.default.ADMIN_TENANTMANAGEMENT_INFORMED,
  ADMIN_PROVIDERMANAGEMENT_CONSULTANT = _claimTypes.default.ADMIN_PROVIDERMANAGEMENT_CONSULTANT,
  ADMIN_PROVIDERMANAGEMENT_INFORMED = _claimTypes.default.ADMIN_PROVIDERMANAGEMENT_INFORMED;
var admin = {
  providermanagement: {
    providermanagement: {
      create: {
        claims: ADMIN_PROVIDERMANAGEMENT_CONSULTANT
      },
      edit: {
        claims: ADMIN_PROVIDERMANAGEMENT_CONSULTANT
      },
      view: {
        claims: ADMIN_PROVIDERMANAGEMENT_INFORMED
      }
    }
  },
  datamanagement: {
    datamanagement: {
      create: {
        claims: ADMIN_DATAMANAGEMENT_CONSULTANT
      },
      edit: {
        claims: ADMIN_DATAMANAGEMENT_CONSULTANT
      },
      view: {
        claims: ADMIN_DATAMANAGEMENT_INFORMED
      }
    }
  },
  entityTypes: {
    entityType: {
      create: {
        claims: ADMIN_ENTITYTYPES_CONSULTANT
      },
      edit: {
        claims: ADMIN_ENTITYTYPES_CONSULTANT
      },
      view: {
        claims: ADMIN_ENTITYTYPES_INFORMED
      }
    }
  },
  roles: {
    role: {
      create: {
        claims: ADMIN_ROLES_CONSULTANT
      },
      edit: {
        claims: ADMIN_ROLES_CONSULTANT
      },
      view: {
        claims: ADMIN_ROLES_INFORMED
      }
    }
  },
  tokenmanagement: {
    token: {
      create: {
        claims: ADMIN_TOKENMANAGEMENT_CONSULTANT
      },
      edit: {
        claims: ADMIN_TOKENMANAGEMENT_CONSULTANT
      },
      view: {
        claims: ADMIN_TOKENMANAGEMENT_INFORMED
      }
    }
  },
  tenantmanagement: {
    tenant: {
      create: {
        claims: ADMIN_TENANTMANAGEMENT_CONSULTANT
      },
      edit: {
        claims: ADMIN_TENANTMANAGEMENT_CONSULTANT
      },
      view: {
        claims: ADMIN_TENANTMANAGEMENT_INFORMED
      }
    }
  },
  users: {
    user: {
      create: {
        claims: ADMIN_USERS_CONSULTANT
      },
      view: {
        claims: ADMIN_USERS_INFORMED
      }
    }
  }
};
exports.admin = admin;