import GdprNewSubjectAccessWraperPage from './components/pages/GdprNewSubjectAccessWraperPage';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

const GDPRRoutes = [
  {
    path: '/',
    exact: true,
    claims: {
      'governance.subjectaccessrequest': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GdprDashboardPage" */
        './components/pages/GdprDashboardPage'
      ),
    ),
  },
  {
    path: '/settings',
    claims: {
      'governance.subjectaccessrequest': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GdprSettingsManagementPage" */
        './components/pages/GdprSettingsManagementPage'
      ),
    ),
  },
  {
    path: '/import',
    claims: {
      'governance.subjectaccessrequest': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GdprSarImportPage" */
        './components/pages/GdprSarImportPage'
      ),
    ),
  },
  {
    path: '/list',
    claims: {
      'governance.subjectaccessrequest': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"GdprSarManagementPage" */
        './components/pages/GdprSarManagementPage'
      ),
    ),
  },
  {
    path: '/edit',
    claims: {
      'governance.subjectaccessrequest': RACI_LEVELS.CONSULTANT,
    },
    component: GdprNewSubjectAccessWraperPage,
    routes: [
      {
        path: '/findEntity/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"GdprNewSubjectAccessFindEntity" */
            './components/pages/GdprNewSubjectAccessFindEntity'
          ),
        ),
      },
      {
        path: '/validate/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"GdprNewSubjectAccessValidateReport" */
            './components/pages/GdprNewSubjectAccessValidateReport'
          ),
        ),
      },
      {
        path: '/validateTheReport/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"GdprReportContainer" */
            './components/containers/GdprReportContainer'
          ),
        ),
      },
      {
        path: '/sendReport/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"GdprNewSubjectAccessSendReport" */
            './components/pages/GdprNewSubjectAccessSendReport'
          ),
        ),
      },
      {
        path: '/configureData/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"GdprNewSubjectAccessConfigureData" */
            './components/pages/GdprNewSubjectAccessConfigureData'
          ),
        ),
      },
      {
        path: '/overview/:id',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"GdprNewSubjectAccessOverview" */
            './components/pages/GdprNewSubjectAccessOverview'
          ),
        ),
      },
    ],
  },
];

export default GDPRRoutes;
