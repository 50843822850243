import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { get } from 'lodash';

export const UNREAD_NOTIFICATIONS_COUNT = gql`
  query notifications {
    notification {
      id
      unreadNotificationsCount
    }
  }
`;

export const useUnreadNotificationsCount = () => {
  const { data, loading, error, refetch } = useQuery(
    UNREAD_NOTIFICATIONS_COUNT,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    },
  );

  const count = get(data, 'notification.unreadNotificationsCount', 0);

  return [count, loading, error, refetch];
};
