import { useState } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Arrowright, Arrowdown } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';

import EntitySourceItem from './EntitySourceItem/EntitySourceItem';
import {
  EntitySourcesWrapper,
  EntitySourcesArrowWrapper,
  EntitySourceTitle,
} from './EntitySources.styles';

const EntitySources = ({ entity }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);

  const { EntityAccountData } = entity;

  return (
    <EntitySourcesWrapper>
      <EntitySourcesArrowWrapper isExpanded={isExpanded}>
        <EntitySourceTitle
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <Arrowdown color={themePrimary} size={16} />
          ) : (
            <Arrowright color={themePrimary} size={16} />
          )}
          <FormattedMessage id="module-entityWidget-personProfileSources" />
        </EntitySourceTitle>

        {isExpanded &&
          (!EntityAccountData ? (
            <FormattedMessage id="module-entityWidget-personProfileSourcesNotFound" />
          ) : (
            EntityAccountData &&
            Array.isArray(EntityAccountData) && (
              <div style={{ padding: '8px 0' }}>
                {(EntityAccountData || []).map((source, index) => {
                  return (
                    <EntitySourceItem
                      key={`${
                        source?.AccountInformation?.AccountDisplay ||
                        source?.AccountInformation?.AccountId ||
                        '-'
                      }-${index}`}
                      AccountInformation={source?.AccountInformation}
                      ProviderId={source?.ProviderId}
                      ProviderName={source?.ProviderName}
                      index={index}
                      {...source}
                    />
                  );
                })}{' '}
              </div>
            )
          ))}
      </EntitySourcesArrowWrapper>
    </EntitySourcesWrapper>
  );
};

export default EntitySources;
