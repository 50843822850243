import { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';
import { List } from '@cluedin/list-pattern';
import { useSearch, actionsConfiguration } from '@cluedin/components';

import { usePaging } from '../../../../core/hooks';
import { useQueryUsers } from '../../../hooks';
import { withAddUser } from '../../Hocs/withAddUser';
import { getColumns } from './getColumns';

const { admin } = actionsConfiguration;

const filterByUserQuery = (query) => (user) => {
  const name =
    user.Entity && user.Entity.name ? user.Entity.name : user.Account.UserName;

  if (!name || !query) {
    return true;
  }

  return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
};

const AllUsersList = ({
  openAddUser,
  grantAccess,
  revokeAccess,
  location,
  history,
}) => {
  const [data, loading, error, refetch] = useQueryUsers();
  const [searchTerm, setSearchTerm] = useSearch(history, location);
  const [searchName, setSearchName] = useState(searchTerm);

  const [, currentPage, setCurrentPage] = usePaging(history, location);

  const filteredUser = data ? data.filter(filterByUserQuery(searchName)) : [];

  // Yes I know. Bad thing. But only one can help in this case
  // We need to refetch data after it could have been changed in user detail page
  // and it's the only thing I can do as it was designen since start wrongly
  useEffect(() => {
    refetch?.();
  });

  return (
    <List
      error={error}
      history={history}
      location={location}
      selectable
      showPagination={false}
      loading={loading}
      selectedPage={currentPage.selectedPage}
      itemsPerPage={currentPage.itemsPerPage}
      onPageChange={setCurrentPage}
      searchName={searchName}
      onChangeSearch={setSearchName}
      onSubmitSearch={setSearchTerm}
      data={filteredUser}
      columns={getColumns(searchName, refetch)}
      total={(data || []).length}
      offsetTop={110}
      hasFilters={!!searchName}
      actions={[
        {
          label: (
            <FormattedMessage
              id="module-user-clearSelection"
              defaultMessage="Clear selection"
            />
          ),
          clearSelection: true,
        },
        {
          label: (
            <FormattedMessage
              id="module-user-grantAccess"
              defaultMessage="Grant access"
            />
          ),
          clearSelection: true,
          action: grantAccess,
        },
        {
          label: (
            <FormattedMessage
              id="module-user-revokeAccess"
              defaultMessage="Revoke access"
            />
          ),
          clearSelection: true,
          action: revokeAccess,
        },
      ]}
      creation={[
        {
          label: <FormattedMessage id="module-user-inviteNewMember" />,
          onClick: openAddUser,
          actionConfiguration: admin.users.user.create,
        },
      ]}
      notFoundProps={{
        message: (
          <FormattedMessage id="module-userInvitation-noInvitation-message" />
        ),
        buttonLabel: <FormattedMessage id="module-user-inviteNewMember" />,
        onCreateClick: openAddUser,
        actionConfiguration: admin.users.user.create,
      }}
      noSearchFoundProps={{
        message: <FormattedMessage id="module-user-noSearchMessage" />,
        explanation: (
          <FormattedMessage id="module-user-notSearchFoundExplanation" />
        ),
      }}
      dataGridName="AllUsersList"
    />
  );
};

export default compose(withAddUser, withRouter)(AllUsersList);
