import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_CURRENT_ORG = gql`
  query getCurrentOrg {
    administration {
      id
      organization {
        Id
        ClientId
        OrganizationName
        Description
        Domain
        Logo
        Banner
        LogoFullResolution
        BannerFullResolution
        Website
        Location
        LocationGeocode
        AllowEmailDomainSignup
        PrimaryColor
        SecondaryColor
        DarkMainColor
        globalSecurityFilter
      }
    }
  }
`;

const GET_CURRENT_ORG_WITHOUT_SECURITY = gql`
  query getCurrentOrg {
    administration {
      id
      organization {
        Id
        ClientId
        OrganizationName
        Description
        Domain
        Logo
        Banner
        LogoFullResolution
        BannerFullResolution
        Website
        Location
        LocationGeocode
        AllowEmailDomainSignup
        PrimaryColor
        SecondaryColor
        DarkMainColor
      }
    }
  }
`;

export const useQueryCurrentOrganization = () => {
  const { data, loading, error, refetch } = useQuery(GET_CURRENT_ORG);

  const organization = get(data, 'administration.organization', {});

  return [organization, loading, error, refetch];
};

export const useQueryCurrentOrganizationForUser = () => {
  // remove globalSecurityFilter as you need to be an admin to view this
  const { data, loading, error, refetch } = useQuery(
    GET_CURRENT_ORG_WITHOUT_SECURITY,
  );
  const organization = get(data, 'administration.organization', {});

  return [organization, loading, error, refetch];
};

export default {
  useQueryCurrentOrganization,
  useQueryCurrentOrganizationForUser,
};
