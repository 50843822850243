"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var ResizeHandler = _styledComponents.default.div.attrs(function (props) {
  return {
    onMouseDown: function onMouseDown(e) {
      e.stopPropagation();
      e.preventDefault(); // prevents drag start
      props.onResizeStart(e, props.property);
    }
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  ", ";\n  position: absolute;\n  right: 0;\n  top: 0;\n  height: calc(100% + 32px);\n  height: 100%;\n  width: 0px;\n  width: 6px;\n  max-width: 6px;\n  background: grey;\n  opacity: 0;\n  &,\n  &:hover,\n  &:active {\n    cursor: col-resize;\n  }\n  &:hover {\n    opacity: ", ";\n    /* &:after {\n      transition: all 280ms cubic-bezier(.5,1,.5,1);\n      opacity: .7;\n    } */\n  }\n  /* transition: all 280ms cubic-bezier(.5,1,.5,1); */\n  transition: none;\n  /* &:after {\n    transition: all 280ms cubic-bezier(.5,1,.5,1);\n    content: '\u25C2\u2758\u25B8';\n    position: absolute;\n    right: 0;\n    top: calc(100% - 4px);\n    margin-right: 50%;\n    transform: translateX(50%);\n    width: auto;\n    opacity: 0;\n  } */\n"])), function (_ref) {
  var resizable = _ref.resizable;
  return resizable ? (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n          display: block;\n          visibility: visible;\n          pointer-events: all;\n        "]))) : (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n          display: none;\n          visibility: none;\n          pointer-events: none;\n        "])));
}, function (_ref2) {
  var isResizing = _ref2.isResizing,
    isBeingResized = _ref2.isBeingResized;
  return isResizing ? isBeingResized ? 0.7 : 0 : 0.2;
});
/* eslint-disable-enable no-nested-ternary */

ResizeHandler.displayName = 'ResizeHandler';
var _default = ResizeHandler;
exports.default = _default;