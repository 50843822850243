import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { ResetPasswordForm } from 'uxi-business';
import Alert from 'uxi/Alert';
import CluedInBigTitle from '../../../core/components/composites/generic/CluedInBigTitle';

const ResetPwdContainer = ({ isMutating, error, onResetPassword }) => (
  <div className="test_public_ResetPasswordForm">
    {error && (
      <div style={{ marginTop: '15px' }}>
        <Alert noIcon type="danger">
          <FormattedMessage id="module-public-forgotPwd-ForgotPwdGenericError" />
        </Alert>
      </div>
    )}
    <CluedInBigTitle>
      <FormattedMessage id="ResetPwdTitleText" />
    </CluedInBigTitle>
    <ResetPasswordForm isFetching={isMutating} onClick={onResetPassword} />
  </div>
);

export default ResetPwdContainer;
