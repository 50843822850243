import React from 'react';
import { FormattedMessage, defaultLocales } from '@cluedin/locale';

export default {
  ...defaultLocales,
  IsActiveFilter: {
    allStatuses: (
      <FormattedMessage id="cluedin-components-IsActiveFilter-allStatuses" />
    ),
    active: <FormattedMessage id="cluedin-components-IsActiveFilter-active" />,
    inactive: (
      <FormattedMessage id="cluedin-components-IsActiveFilter-inactive" />
    ),
  },
  ListFooter: {
    showing: <FormattedMessage id="cluedin-components-ListFooter-showing" />,
    outOf: <FormattedMessage id="cluedin-components-ListFooter-outOf" />,
    rows: <FormattedMessage id="cluedin-components-ListFooter-rows" />,
    columns: <FormattedMessage id="cluedin-components-ListFooter-columns" />,
  },
  Pager: {
    pageDoesNotExist: (
      <FormattedMessage id="cluedin-components-Pager-pageDoesNotExist" />
    ),
    goToPage: <FormattedMessage id="cluedin-components-Pager-goToPage" />,
    go: <FormattedMessage id="cluedin-components-Pager-go" />,
    of: <FormattedMessage id="cluedin-components-Pager-of" />,
  },
};
