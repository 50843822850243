import styled, { css } from 'styled-components';

export const EntityTopologySplitPanelProviderIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const EntityTopologySplitPanelIcon = styled.div`
  align-items: center;
  display: flex;
  min-height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-width: 32px;
  justifycontent: center;
  margin-right: 8px;
`;

export const EntityTopologyIconWrapper = styled.div`
  align-items: center;

  ${({ sourceKind }) => {
    switch (true) {
      case sourceKind === 'manualmerge':
        return css`
          background: #fbf4de;
        `;
      case sourceKind === 'deduplicationprojectmerge':
        return css`
          background: #e2cbf9;
        `;
      case sourceKind === 'userinput':
        return css`
          background: #ffe0f4;
        `;
      case sourceKind === 'clean' || sourceKind === 'legacyclean':
        return css`
          background: #ccf0f7;
        `;
      default:
        return css`
          background: ${({ themePrimary }) =>
            themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
        `;
    }
  }};

  border-radius: 80%;
  display: flex;
  height: 32px;
  min-height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  min-width: 32px;

  & svg path {
    ${({ sourceKind }) => {
      switch (true) {
        case sourceKind === 'manualmerge':
          return css`
            fill: #f2c230;
          `;
        case sourceKind === 'deduplicationprojectmerge':
          return css`
            fill: #a65dee;
          `;
        case sourceKind === 'userinput':
          return css`
            fill: '#ff99da';
          `;
        case sourceKind === 'clean' || sourceKind === 'legacyclean':
          return css`
            fill: #00b4d8;
          `;
        default:
          return css`
            fill: ${({ themeWhite }) => themeWhite ?? '#fff'};
          `;
      }
    }}
  }
`;
