import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';
import EntityLinkFromEntityContainer from '../../../wms/components/containers/EntityLinkFromEntityContainer';

const EntityLinkStyle = {
  fontSize: '16px',
};

const EntityLink = ({
  entity,
  openInNewTab,
  q,
  isNewInformation,
  style,
  linkStyle,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const name = entity.name;
  let hasQuery = false;
  let wrapperStyle = {};

  try {
    const index = name.search(new RegExp(q, 'ig'));
    hasQuery = index > -1;
  } catch (e) {
    hasQuery = 0;
  }

  const names = [name];
  let result = [];

  if (q && names && hasQuery) {
    names.forEach((n) => {
      let nameWithStrong;

      if (typeof x === 'string') {
        nameWithStrong = n.replace(
          new RegExp(q, 'ig'),
          (x) => `<strong>${x}</strong>`,
        );
      } else {
        nameWithStrong = n;
      }

      result.push(nameWithStrong);
    });
  } else {
    result = names;
  }

  const nameContent = result.map((x, index) => {
    if (typeof x === 'string') {
      return (
        // eslint-disable-next-line react/no-danger
        <span key={index} dangerouslySetInnerHTML={{ __html: x }} />
      );
    }
    return <span key={index}>{x}</span>;
  });

  let shownLinkStyle = {
    ...EntityLinkStyle,
    color: themePrimary,
  };

  if (linkStyle) {
    shownLinkStyle = linkStyle;
  }

  let linkContent = (
    <EntityLinkFromEntityContainer style={shownLinkStyle} entity={entity}>
      {nameContent}
    </EntityLinkFromEntityContainer>
  );

  if (openInNewTab) {
    linkContent = (
      <EntityLinkFromEntityContainer
        style={shownLinkStyle}
        target="_blank"
        entity={entity}
      >
        {nameContent}
      </EntityLinkFromEntityContainer>
    );
  }

  if (style) {
    wrapperStyle = Object.assign(wrapperStyle, style);
  }

  if (isNewInformation) {
    return (
      <div style={wrapperStyle}>
        {linkContent}
        <div
          style={{
            background: '#9E9E9E',
            color: 'rgb(255, 255, 255)',
            padding: '0px 5px',
            display: 'inline-block',
            marginLeft: '5px',
            borderRadius: '20px',
            fontSize: '11px',
            fontWeight: 'bold',
          }}
        >
          new
        </div>
      </div>
    );
  }
  return <span style={wrapperStyle}>{linkContent}</span>;
};

EntityLink.displayName = 'EntityLink';
EntityLink.propTypes = {
  entity: PropTypes.object,
  openInNewTab: PropTypes.bool,
  q: PropTypes.string,
  isNewInformation: PropTypes.bool,
  style: PropTypes.object,
  linkStyle: PropTypes.object,
};
EntityLink.defaultProps = {
  entity: {},
  openInNewTab: false,
  q: '*',
  isNewInformation: false,
  style: {},
  linkStyle: {},
};

export default EntityLink;
