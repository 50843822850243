import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import cogoToast from 'cogo-toast';

const REJECT_MESH_COMMAND = gql`
  mutation rejectMeshCommand($meshMessageId: ID!) {
    virtualization {
      rejectMeshCommand(meshMessageId: $meshMessageId) {
        success
        errorMessage
        response
      }
    }
  }
`;

export const useRejectMeshCommand = (meshMessageId) => {
  const [rejectMeshCommand, { data, loading, error }] = useMutation(
    REJECT_MESH_COMMAND,
    {
      onCompleted: () => {
        cogoToast.success('Change rejected successfully', {
          position: 'bottom-right',
          hideAfter: 5,
        });
      },
      onError: () => {
        cogoToast.error('Error rejecting change', {
          position: 'bottom-right',
          hideAfter: 5,
        });
      },
    },
  );

  const rejectResponse = get(data, 'virtualization.rejectMeshCommand');

  return [
    () => {
      rejectMeshCommand({
        variables: {
          meshMessageId,
        },
      });
    },
    { data: rejectResponse, loading, error },
  ];
};
