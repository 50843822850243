import React, { useContext } from 'react';
import styled from 'styled-components';
import Add from 'uxi/Icons/Add';
import Delete from 'uxi/Icons/Delete';
import { ButtonLink } from '@cluedin/atoms';
import { LocalizationContext } from '@cluedin/locale';

const RuleActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RuleActions = ({
  onAddRule,
  onAddGroup,
  rule,
  isChild,
  removeRule,
  actionConfiguration,
}) => {
  const locales = useContext(LocalizationContext);
  const ruleConditionLocales = locales?.RuleCondition;

  return (
    <RuleActionsWrapper>
      <div data-test="rule-conditions-on-add-filter">
        <ButtonLink
          onClick={() => {
            onAddRule(rule);
          }}
          style={{ marginRight: '12px' }}
          icon={<Add />}
          text={ruleConditionLocales.rule}
          actionConfiguration={actionConfiguration}
        />
      </div>
      <div data-test="rule-conditions-on-add-group">
        <ButtonLink
          onClick={() => {
            onAddGroup(rule);
          }}
          icon={<Add />}
          text={ruleConditionLocales.group}
          actionConfiguration={actionConfiguration}
        />
      </div>
      {isChild && (
        <ButtonLink
          style={{ marginLeft: '12px' }}
          icon={<Delete />}
          onClick={() => {
            removeRule(rule);
          }}
          actionConfiguration={actionConfiguration}
        />
      )}
    </RuleActionsWrapper>
  );
};

export default RuleActions;
