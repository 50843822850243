import { ButtonLink } from '@cluedin/atoms';

import {
  EdgePropertiesPanelHeaderWrapper,
  EdgePropertiesPanelHeaderTitle,
} from './EdgePropertiesPanelHeader.styles';

const EdgePropertiesPanelHeader = ({
  nodeFrom = {},
  nodeTo = {},
  edgeType = '/',
  edgeTypesArray = [],
  edgeFrom = {},
  edgeTo = {},
  onNodeClick = () => {},
  onGroupedNodeClick = () => {},
  edgeDirection,
  labels = {},
}) => {
  const {
    title = 'Edge Properties',
    edgeTypeLabel = 'Edge type',
    totalEdgeTypes = 'Total edge types',
    edgeFromLabel = 'From',
    edgeToLabel = 'To',
    groupedRecordsLabel = 'Grouped Relations',
    directionLabel = 'Direction',
    outgoingLabel = 'Outgoing',
    incomingLabel = 'Incoming',
  } = labels;

  const isGrouped = (edge) =>
    edge?.label?.toLowerCase() === 'grouped' ||
    edge?.nodeKind?.toLowerCase() === 'groupedrelations';

  return (
    <EdgePropertiesPanelHeaderWrapper>
      <EdgePropertiesPanelHeaderTitle>{title}</EdgePropertiesPanelHeaderTitle>

      <div>
        {totalEdgeTypes}:&nbsp;
        <strong>{edgeTypesArray?.length}</strong>
      </div>
      {edgeTypesArray?.length === 1 && (
        <div>
          {edgeTypeLabel}:&nbsp;
          <strong>{edgeType}</strong>
        </div>
      )}

      <div>
        {directionLabel}:&nbsp;
        <strong>
          {edgeDirection?.toLowerCase() === 'outgoing'
            ? outgoingLabel
            : incomingLabel}
        </strong>
      </div>

      <div>
        {edgeFromLabel}:&nbsp;
        <ButtonLink
          onClick={() => {
            isGrouped(edgeFrom)
              ? onGroupedNodeClick(nodeFrom, edgeType, edgeDirection)
              : onNodeClick(nodeFrom);
          }}
          text={
            <strong>
              {isGrouped(edgeFrom)
                ? groupedRecordsLabel
                : edgeFrom?.label || edgeFrom?.name}
            </strong>
          }
        />
      </div>
      <div>
        {edgeToLabel}:&nbsp;
        <ButtonLink
          onClick={() => {
            isGrouped(edgeTo)
              ? onGroupedNodeClick(nodeTo, edgeType, edgeDirection)
              : onNodeClick(nodeTo);
          }}
          text={
            <strong>
              {isGrouped(edgeTo)
                ? groupedRecordsLabel
                : edgeTo?.label || edgeTo?.name}
            </strong>
          }
        />
      </div>
    </EdgePropertiesPanelHeaderWrapper>
  );
};

export default EdgePropertiesPanelHeader;
