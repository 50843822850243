import styled from 'styled-components';

export const EntityPropertiesDropdownWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const EntityPropertiesButtonWrapper = styled.div`
  width: 100%;
`;

export const EntityPropertiesDropdownIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 100%;

  & svg {
    height: 16px !important;
    max-height: 16px !important;
    min-height: 16px !important;
    width: 16px !important;
    max-width: 16px !important;
    min-width: 16px !important;
  }

  &:hover {
    background: #def1f0;
    color: #29a29a;
  }
`;
