import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';

const DefaultAlert = () => (
  <Alert style={{ margin: '15px' }} type="error">
    <div>
      <FormattedMessage id="module-core-error" />
    </div>
    <div style={{ marginTop: '5px' }}>
      <a
        style={{ color: '#fff', textDecoration: 'underline' }}
        href="mailto:support@cluedin.com"
      >
        <FormattedMessage id="module-core-contactSupport" />
      </a>
      &nbsp;
      <FormattedMessage id="module-core-toHaveImmediateAssistance" />
    </div>
  </Alert>
);

export default DefaultAlert;
