import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { IconButton } from '@fluentui/react/lib/Button';
import { useTheme } from '@cluedin/theme';
import { Checkbox } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { Ellipsis } from '@cluedin/components';

import FileUploadDataSourceNameField from '../../../../dataSourceV2/components/containers/forms/FileUploadDataSourceNameField';
import { useOrganizationFeatureFlagOption } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';
import { formatBytes } from './utils';
import { AddOnText } from './AddOnText';
import { FileUploadFormFormatsHelper } from './FileUploadFormFormatsHelper';
import { FileDataSource } from './types';
import { ErrorFile } from '../../../types';
import { ErrorText } from '../../../../../shared/components/Error';
import { FileIcon } from './FileIcon';

type FileAcceptedItemType = {
  fileDataSource: FileDataSource;
  handleRemoveFile: (file: FileDataSource) => void;
  handleChangeHasHeaders?: () => void;
  onDataSourceNameChange: (e: Event, v: string) => void;
  errorDataSourceName: ReactNode;
  style?: CSSProperties;
  fileExitedLimit: boolean;
  duplicates: FileDataSource[];
  errorUpload?: ErrorFile;
};

const FileAccepterWrapper = styled.div`
  border-top: 1px solid #ccc;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  ${({ hasError }: { hasError: boolean }) => {
    if (hasError) {
      return `background: #fff2f2;`;
    }
  }}
`;

const FileContentWrapper = styled.div`
  flex: 1;
`;

export const FileAcceptedItem = ({
  fileDataSource,
  handleRemoveFile,
  onDataSourceNameChange,
  errorDataSourceName,
  style = {},
  fileExitedLimit,
  duplicates = [],
  errorUpload,
  handleChangeHasHeaders,
}: FileAcceptedItemType) => {
  const hasBasicXLSX = useOrganizationFeatureFlagOption('BasicXLSX');
  const theme = useTheme();
  const warningText = theme.semanticColors.warningHighlight;
  let type = 'success';

  if (
    !errorUpload &&
    ((duplicates && duplicates.length > 0) || fileExitedLimit)
  ) {
    type = 'warning';
  }

  if (errorUpload) {
    type = 'error';
  }

  return (
    <FileAccepterWrapper hasError={!!errorUpload} style={style}>
      <FileIcon type={type} />
      <FileContentWrapper>
        <div>
          <FileUploadDataSourceNameField
            dataSourceName={fileDataSource.dataSourceName}
            onDataSourceNameChange={onDataSourceNameChange}
            errorDataSourceName={errorDataSourceName}
            button={
              <IconButton
                data-test="deleteFileIcon"
                onClick={() => {
                  handleRemoveFile(fileDataSource);
                }}
                iconProps={{
                  iconName: 'Delete',
                  style: {
                    height: 14,
                    fontSize: 14,
                    marginBottom: 4,
                    lineHeight: '14px',
                    color: theme.semanticColors.errorText,
                  },
                }}
              />
            }
          />
          {handleChangeHasHeaders && (
            <Checkbox
              styles={{ root: { marginBottom: 4 } }}
              onChange={handleChangeHasHeaders}
              checked={fileDataSource.noHeaders}
              label={<FormattedMessage id="module-fileUpload-noHeaders" />}
            />
          )}
          <AddOnText style={{ marginBottom: '6px' }}>
            <div style={{ maxWidth: 460 }}>
              <Ellipsis>
                <FormattedMessage
                  id="module-fileUpload-originalFileName"
                  values={{ fileName: fileDataSource.file.name }}
                />
              </Ellipsis>
            </div>
            <div>
              <FormattedMessage
                id="module-fileUpload-originalFileSize"
                values={{ fileName: fileDataSource.file.name }}
              />
              <span style={{ marginLeft: '4px' }}>
                {fileDataSource.file.size > 0 &&
                  formatBytes(fileDataSource.file.size)}
              </span>
            </div>
          </AddOnText>
          {!errorUpload && duplicates && duplicates.length > 0 && (
            <div style={{ color: warningText, fontSize: '12px' }}>
              <span style={{ marginRight: '4px' }}>
                <FormattedMessage id="module-fileUpload-possibleDuplicates" />
              </span>
              <span>
                {duplicates.map((f, i) => {
                  return `${i === 0 ? '' : ', '}${f.file.name}`;
                })}
              </span>
            </div>
          )}
          <FileUploadFormFormatsHelper
            fileDataSource={fileDataSource}
            hasBasicXLSX={hasBasicXLSX}
          />
          {errorUpload && errorUpload?.error && errorUpload?.error.message && (
            <ErrorText>{errorUpload?.error.message}</ErrorText>
          )}
        </div>
      </FileContentWrapper>
    </FileAccepterWrapper>
  );
};
