const WarningImage = ({ fill = '#f2b600', size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      style={{ cursor: 'pointer' }}
    >
      <g
        id="Group_4110"
        data-name="Group 4110"
        transform="translate(-1158 -672)"
      >
        <circle
          id="Ellipse_129"
          data-name="Ellipse 129"
          cx="8"
          cy="8"
          r="8"
          transform="translate(1158 672)"
          fill={fill}
        />
        <g id="Group_4109" data-name="Group 4109">
          <rect
            id="Rectangle_743"
            data-name="Rectangle 743"
            width="2"
            height="7"
            transform="translate(1165 675)"
            fill="#fff"
          />
          <circle
            id="Ellipse_130"
            data-name="Ellipse 130"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1165 683)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default WarningImage;
