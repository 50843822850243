import styled from 'styled-components';

export const GroupedEntitiesPanelFooterWrapper = styled.div`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  border-top: 1px solid #ccc;
  margin-top: auto;
`;

export const GroupedEntitiesPanelFooterInnerWrapper = styled.div`
  background: ${({ themeWhite }) => themeWhite ?? '#fff'};
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;
