import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { Option } from '@cluedin/atoms';
import config from '../../../../../../config';
import { TopNavButton } from '../TopNavButton';
import { withUserMenu } from './withUserMenu';

const UserMenuCaller = memo(
  ({
    open,
    theme,
    logout,
    entityUrl,
    currentUser,
    openWorkflow,
    showSettingsLink,
  }) => {
    const logoUrl = useMemo(
      () => currentUser?.entity?.data?.logoUrl,
      [currentUser],
    );
    const username = useMemo(
      () => currentUser?.client?.UserName || currentUser?.entity?.name || '–',
      [currentUser],
    );

    const showLanguageConfig = config.languages?.length >= 2;

    const handleOpenMenu = () =>
      openWorkflow({
        theme,
        logout,
        logoUrl,
        username,
        entityUrl,
        showSettingsLink,
        showLanguageConfig,
        entity: currentUser?.entity,
      });

    return (
      <TopNavButton onClick={handleOpenMenu} isActive={open}>
        <Option size={20} color={theme.palette.white} />
      </TopNavButton>
    );
  },
);

const getEntityUrl = (id = '', entityType = '', entityConfigurations = []) => {
  const defaultEntity = `entity/${id}`;

  if (!entityType) {
    return `/${defaultEntity}`;
  }

  const entityConfig = entityConfigurations.find(
    (entityConfiguration) =>
      entityConfiguration?.entityType?.toLowerCase?.() ===
      entityType?.toLowerCase?.(),
  );

  if (entityConfig?.path) {
    const entityUrl = entityConfig?.path?.replace?.(':id', id);

    return `/${entityUrl}`;
  }

  return `/${defaultEntity}`;
};

const getEntityUrlFromStateAndEntity = (wmsState, entity) => {
  const id = entity?.id || entity?.entityId || entity?.EntityId;
  const entityType = entity?.data
    ? entity?.data?.entityType
    : entity?.entityType;

  const entityUrl = getEntityUrl(
    id,
    entityType,
    wmsState?.entityConfigurations ? wmsState.entityConfigurations : [],
  );

  return entityUrl;
};

const mapStateToProps = ({ wms, locale: { currentCulture } }, { entity }) => ({
  currentCulture,
  entityUrl: getEntityUrlFromStateAndEntity(wms, entity),
});

const UserMenuWithPanel = withUserMenu(UserMenuCaller);

export const UserMenu = connect(mapStateToProps, null)(UserMenuWithPanel);
