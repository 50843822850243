import React from 'react';
import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';
import { loginWithSSO } from '../../../auth/oidbc';

const SSOLogin = ({ children }) => {
  return <div>{children}</div>;
};

const EnchancedSSOLogin = compose(
  lifecycle({
    componentDidMount() {
      const { teamDomain, type } = this.props;

      if (
        teamDomain &&
        teamDomain.SingleSignOn &&
        teamDomain.SingleSignOn.LoginUrl
      ) {
        if (type === 'login') {
          loginWithSSO(teamDomain.SingleSignOn);
        }
        if (
          type === 'changePassword' &&
          teamDomain.SingleSignOn.ChangePasswordUrl
        ) {
          loginWithSSO(teamDomain.SingleSignOn);
        }
      }
    },
  }),
)(SSOLogin);

const mapToStateProps = ({ publicModule: { result } }) => ({
  teamDomain: result,
});

export default connect(mapToStateProps)(EnchancedSSOLogin);
