import styled from 'styled-components';
import TruncatedText from '../../../composites/TruncatedText';

export const Snippet = styled.div`
  color: #999999;
  font-size: 12px;
  max-width: 100%;
`;

const TileSnippet = ({ snippet = 'no snippet' }) => (
  <Snippet>
    <TruncatedText text={snippet || ''} max={150} withViewMoreLink />
  </Snippet>
);

export default TileSnippet;
