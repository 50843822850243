export default {
  mainBarList: {
    margin: 0,
    padding: 0,
    display: 'flex',
    height: '58px',
  },
  mainBarListItem: {
    listStyle: 'none',
    float: 'left',
    padding: 0,
    margin: 0,
    height: '58px',
  },
  mainBarListItemLink: {
    display: 'inline-block',
    height: '58px',
    color: '#666',
    ':hover': {
      background: '#f2f2f2',
    },
  },
  sensitiveIcon: {
    float: 'left',
    marginTop: '20px',
    marginRight: '10px',
    height: '18px',
    width: '18px',
  },
};
