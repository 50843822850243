import styled, { css } from 'styled-components';

export const EntityTopologySplitPreviewNewEntityWrapper = styled.div`
  background: #ffffff;
  max-width: 100%;
`;

export const EntityTopologySplitPreviewNewEntityBox = styled.div`
  border-bottom: ${({ isExpanded }) =>
    isExpanded ? '1px solid #ececec' : null};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
  position: relative;
`;

export const EntityTopologySplitPreviewNewEntityInnerWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 21px;
  padding: 14px 0 0 0;
`;

export const EntityTopologySplitPreviewNewEntityHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 14px;
  position: relative;
  width: 100%;

  &::after {
    border-bottom: ${({ isExpanded }) =>
      isExpanded ? '1px solid #ececec' : null};
    content: '';
    min-width: 100%;
    position: absolute;
    top: 100%;
  }
`;

export const EntityTopologySplitPreviewNewEntityHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const EntityTopologySplitPreviewNewEntityBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid ${({ themePrimary }) => themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -5.5px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const EntityTopologySplitPreviewNewEntityDetailsWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 16px;
  width: 100%;
`;

export const EntityTopologySplitPreviewNewEntityArrowWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;

export const EntityTopologySplitPreviewNewEntityDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const EntityTopologySplitPreviewNewEntityDetailsInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & strong {
    font-size: 14px;
  }
`;

export const ExplainLogIdentifierSourceWrapper = styled.div`
  border-left: 1px solid #ececec;
  height: 100%;
  margin-left: 21px;
  transition: all 100ms ease;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;
