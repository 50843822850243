import { css } from 'styled-components';

const numCols = 12;
const gutterWidth = 1.5;

const smallScreen = '680px';
const mediumScreen = '992px';
// const largeScreen = '1200px';

const mediumAndUp = `screen and (min-width: ${smallScreen})`;
const largeAndUp = `screen and (min-width: ${mediumScreen})`;

export const wmsGrid = css`
  .cluedIn_container .row {
    margin-left: -${gutterWidth / 2}rem;
    margin-right: -${gutterWidth / 2}rem;
  }

  .cluedIn_row {
    margin-left: auto;
    margin-right: auto;
  }
  .cluedIn_row.cluedIn_row_notCentered {
    margin-left: 0;
    margin-right: 0;
  }

  /* Clear floating children */
  .cluedIn_row:after {
    content: '';
    display: table;
    clear: both;
  }

  .cluedIn_row .cluedIn_col {
    float: left;
    box-sizing: border-box;
    padding: 0 calc(${gutterWidth}em / 2);
  }
  .cluedIn_row .cluedIn_col[class*='push-'],
  .cluedIn_row .cluedIn_col[class*='pull-'] {
    position: relative;
  }

  ${new Array(numCols).fill('', 0, numCols).map((x, j) => {
    const i = j + 1;
    const perc = `${100 / (numCols / i)}%`;
    return `
      .cluedIn_row .cluedIn_col.s${i} {
        width: ${perc};
        margin-left: auto;
        left: auto;
        right: auto;
      }`;
  })}

  ${new Array(numCols).fill('', 0, numCols).map((x, j) => {
    const i = j + 1;
    const perc = `${100 / (numCols / i)}%`;
    return `
      .cluedIn_row .cluedIn_col.offset-s${i} {
        margin-left: ${perc};
      }
      .cluedIn_row .cluedIn_col.pull-s${i} {
        right: ${perc};
      }
      .cluedIn_row .cluedIn_col.push-s${i} {
        left: ${perc};
      }`;
  })}

  @media ${mediumAndUp} {
    ${new Array(numCols).fill('', 0, numCols).map((x, j) => {
      const i = j + 1;
      const perc = `${100 / (numCols / i)}%`;
      return `
        .cluedIn_row  .cluedIn_col.m${i} {
          width: ${perc};
          margin-left: auto;
          left: auto;
          right: auto;
        }`;
    })}

    ${new Array(numCols).fill('', 0, numCols).map((x, j) => {
      const i = j + 1;
      const perc = `${100 / (numCols / i)}%`;
      return `
        .cluedIn_row  .cluedIn_col.offset-m${i} {
          margin-left: ${perc};
        }
        .cluedIn_row  .cluedIn_col.pull-m${i} {
          right: ${perc};
        }
        .cluedIn_row  .cluedIn_col.push-m${i} {
          left: ${perc};
      }`;
    })}
  }

  @media ${largeAndUp} {
    ${new Array(numCols).fill('', 0, numCols).map((x, j) => {
      const i = j + 1;
      const perc = `${100 / (numCols / i)}%`;
      return `
        .cluedIn_row .cluedIn_col.l${i} {
          width: ${perc};
          margin-left: auto;
          left: auto;
          right: auto;
        }`;
    })}

    ${new Array(numCols).fill('', 0, numCols).map((x, j) => {
      const i = j + 1;
      const perc = `${100 / (numCols / i)}%`;
      return `
        .cluedIn_row .cluedIn_col.offset-l${i} {
          margin-left: ${perc};
        }
        .cluedIn_row .cluedIn_col.pull-l${i} {
          right: ${perc};
        }
        .cluedIn_row .cluedIn_col.push-l${i} {
          left: ${perc};
        }`;
    })}
  }
`.join('');

export default wmsGrid;
