import styled from 'styled-components';

export const EntityRelationsGraphV3HeaderWrapper = styled.div`
  /* background: #fff;
  border-bottom: 1px solid #ececec;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  width: calc(100vw - 216px);
  z-index: 2;

  & svg {
    height: 16px !important;
    width: 16px !important;
  } */

  background: #fff;
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  position: sticky;
  z-index: 2;

  & svg {
    height: 16px !important;
    width: 16px !important;
  }
`;
