import { OrganizationFeatureFlag } from '../../featureFlag/hooks/useOrganizationFeatureFlag';

export const withHelpFeatureFlag = (Comp) => (props) => {
  return (
    <OrganizationFeatureFlag
      MainFeature={null}
      ExperimentalFeature={
        <>
          <Comp {...props} />
        </>
      }
      featureName={'Inline Help'}
    />
  );
};
