import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import moment from 'moment';
import { StatusBadge } from 'uxi/Badge';

const GdprTimeLeftTableCell = ({ sar: { DueDate, State }, configuration }) => {
  if (State === 6) {
    return 'Done';
  }

  const now = moment();
  const dueDate = moment(DueDate);
  const dueDate2 = moment(DueDate);

  const isAlmostLate = now.isSameOrAfter(dueDate.subtract(1, 'days'));
  const isLate = now.isSameOrAfter(dueDate);

  const remaingDaysUntilDue = Math.floor(dueDate.diff(now, 'days'));
  const elapsedDaysSinceDue = Math.floor(now.diff(dueDate2, 'days'));

  let content;
  if (configuration && 'NumberOfDays' in configuration) {
    if (isAlmostLate || isLate) {
      if (isAlmostLate && !isLate) {
        content = (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '-5px',
            }}
          >
            <StatusBadge style={{ margin: 0 }} type="warning" />
            <span style={{ fontSize: '12px' }}>
              <FormattedMessage id="module-gdpr-lastDay" />
            </span>
          </div>
        );
      } else {
        content = (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '-5px',
            }}
          >
            <StatusBadge style={{ margin: 0 }} type="danger" />
            <span style={{ fontSize: '12px' }}>
              {elapsedDaysSinceDue <= 1 ? '1' : elapsedDaysSinceDue}
              &nbsp;{elapsedDaysSinceDue > 1 ? 'days' : 'day'}
              &nbsp;
              <FormattedMessage id="module-gdpr-late" />
            </span>
          </div>
        );
      }
    } else {
      content = (
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}
        >
          <StatusBadge style={{ margin: 0 }} type="info" />
          <span style={{ fontSize: '12px' }}>
            {remaingDaysUntilDue}
            &nbsp;{remaingDaysUntilDue > 1 ? 'days' : 'day'}
            &nbsp;
            <FormattedMessage id="module-gdpr-left" />
          </span>
        </div>
      );
    }
  } else {
    content = <div> – </div>;
  }

  return <div>{content}</div>;
};
GdprTimeLeftTableCell.displayName = 'GdprTimeLeftTableCell';
GdprTimeLeftTableCell.propTypes = {
  sar: PropTypes.object,
  configuration: PropTypes.object,
};
GdprTimeLeftTableCell.defaultProps = {
  sar: {},
  configuration: {},
};

export default GdprTimeLeftTableCell;
