import { is } from '../../core/validation';

const socialProperties = {
  website: 'property-organization.website',
  linkedin: 'property-user.social.LinkedIn',
  twitter: 'property-user.social.twitter',
  podio: 'property-podio.person.Link',
  instagram: 'property-user.social.Instagram',
  github: 'property-organization.github',
  wikipedia: 'property-organization.wiki',
  crunchbase: 'property-user.social.CrunchBase',
};

export const extractSocial = (properties = []) => {
  const result = [];

  if (!properties || properties.length === 0) {
    return [];
  }

  const socialKeys = Object.keys(socialProperties);

  socialKeys.forEach((social) => {
    const propertyKey = socialProperties[social];

    if (is.a.string(propertyKey)) {
      const value = properties[propertyKey];

      if (value) {
        result.push({ key: social, value });
      }
    } else if (is.an.array(propertyKey)) {
      const valueFromArray = propertyKey.find((p) => properties[p]);

      if (valueFromArray && properties[valueFromArray]) {
        result.push({ key: social, value: properties[valueFromArray] });
      }
    }
  });

  return result;
};

export default {
  extractSocial,
};
