import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { actionsConfiguration, Action } from '@cluedin/components';
import { Flex } from 'uxi/Layout';
import { Listul, Add } from 'uxi/Icons';
import Widget from 'uxi/Widget';
import { FormattedMessage } from '@cluedin/locale';

const widgetStyles = {
  minWidth: '200px',
  maxWidth: '200px',
  background: 'white',
  margin: '0 16px 16px 0',
};

const { governance } = actionsConfiguration;

const ConsentDashboardContainer = ({ goToAll, goToCreate }) => (
  <div style={{ marginBottom: '15px', margin: '15px' }}>
    <Flex style={{ justifyContent: 'flex-start' }}>
      <Widget style={widgetStyles}>
        <Action
          menuDescriptor={{
            displayName: (
              <FormattedMessage
                id="module-dataBreach-all"
                defaultMessage="All Breach Reports"
              />
            ),
            key: 'allDataBreach',
            hasNew: false,
            icon: <Listul />,
            onClick: () => {
              goToAll();
            },
            isPromoted: false,
          }}
          actionConfiguration={governance.dataBreach.report.view}
        />
      </Widget>
      <Widget style={widgetStyles}>
        <Action
          menuDescriptor={{
            displayName: (
              <FormattedMessage
                id="module-dataBreach-newDataBreach"
                defaultMessage="Create Breach Report"
              />
            ),
            key: 'newDataBreach',
            hasNew: false,
            icon: <Add />,
            onClick: () => {
              goToCreate();
            },
            isPromoted: false,
          }}
          actionConfiguration={governance.dataBreach.report.create}
        />
      </Widget>
    </Flex>
  </div>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  goToAll: () => {
    dispatch(push('/admin/governance/breach/all'));
  },
  goToCreate: () => {
    dispatch(push('/admin/governance/breach/create'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsentDashboardContainer);
