import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const CREATE_ROLE = gql`
  mutation ($role: InputCreateRole!) {
    administration {
      id
      createRole(role: $role) {
        id
        name
        description
        userCount
        claims {
          id
          friendlyName
          name
          value
        }
      }
    }
  }
`;

export const useCreateRole = () => {
  const [createRole, { data, loading, error }] = useMutation(CREATE_ROLE);

  return [(role) => createRole({ variables: { role } }), data, loading, error];
};
