import React from 'react';
import { PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

const OauthForm = ({ integrationName, onAuthenticate, isSaving, config }) => (
  <div>
    <div style={{ padding: '15px 0' }}>
      <FormattedMessage
        id="module-integration-oauthExpl"
        values={{ integrationName }}
      />
    </div>
    <div className="__test_integrationOauth_add">
      <PrimaryButton
        disabled={isSaving}
        loading={isSaving}
        text={
          <FormattedMessage
            id="module-integration-authWith"
            values={{ integrationName }}
          />
        }
        onClick={() => {
          onAuthenticate({
            name: config.oauthCallbackKey,
            url: config.initial,
          });
        }}
      />
    </div>
  </div>
);

export default OauthForm;
