import React, { Fragment } from 'react';
import { withIntegrationConfig } from '../hocs/withIntegrationConfig';

const IntegrationNameContainer = ({ integrationName, integrationConfig }) => {
  if (!integrationConfig) {
    return <Fragment>{integrationName}</Fragment>;
  }

  return <Fragment>{integrationConfig.Name}</Fragment>;
};

export default withIntegrationConfig(IntegrationNameContainer);
