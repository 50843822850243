import { createContext } from 'react';

const getRegistry = () => {
  let registry = window.__cluedin_in || {}; //eslint-disable-line
  return registry;
};

const registry = getRegistry();

export const WmsContext = createContext(registry);

const getSuggestedSearchesWidget = (entity, suggestedSearchOptions = {}) => {
  const suggestedSearches = [];
  const { placeSuggestedSearches } = suggestedSearchOptions;

  (entity?.SuggestedSearches || []).forEach((search) => {
    const suggestedSearchWidget = {
      name: 'EntitySuggestedSearchV2',
      place: placeSuggestedSearches || 'main',
      parameters: {
        search,
      },
      hasCode: true,
    };

    suggestedSearches.push(suggestedSearchWidget);
  });
  return suggestedSearches;
};

export const emptySuggestedSearch = (suggestedSearchOptions) => ({
  name: 'EmptyEntitySuggestedSearchV2',
  place: suggestedSearchOptions?.placeSuggestedSearches || 'main',
  parameters: {},
});

export const useSuggestedSearches = (entity, suggestedSearchOptions = {}) => {
  if (suggestedSearchOptions.includeSuggestedSearches) {
    const suggestedSearches = getSuggestedSearchesWidget(
      entity,
      suggestedSearchOptions,
    );
    return suggestedSearches;
  }
  return [];
};

export default {
  useSuggestedSearches,
};
