import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';
import { getAll } from './utils/processingRuleScopes';

const typeRoutes = getAll().flatMap((ruleScope) => {
  return [
    {
      path: `/${ruleScope.urlPath}`,
      exact: true,
      claims: {
        'management.rulebuilder': RACI_LEVELS.CONSULTANT,
      },
      component: makeLazy(() =>
        import(
          /* webpackChunkName:"RuleListPage" */
          './components/pages/RuleListPage'
        ),
      ),
    },
    {
      path: `/${ruleScope.urlPath}/order`,
      exact: true,
      claims: {
        'management.rulebuilder': RACI_LEVELS.CONSULTANT,
      },
      component: makeLazy(() =>
        import(
          /* webpackChunkName:"RuleOrderPage" */
          './components/pages/RuleOrderPage'
        ),
      ),
    },
  ];
});

const routes = [
  {
    path: '/',
    exact: true,
    claims: {
      'management.rulebuilder': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"DashboardRule" */
        './components/pages/DashboardRule'
      ),
    ),
  },
  {
    path: '/detail/:id',
    claims: {
      'management.rulebuilder': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"RuleDetailPage" */
        './components/pages/RuleDetailPage'
      ),
    ),
  },
];

const allRoutes = routes.concat(typeRoutes);
export default allRoutes;
