import { sortByCount } from '../../DVC/helpers/sort';

export const sortConfigurationByIntegration = (configurations) => {
  const result = [];

  configurations.forEach((c) => {
    const hasConfiguration = result.find((inC) => inC.name === c.Name);
    if (hasConfiguration) {
      hasConfiguration.configurations.push({
        name: c.Name || c.CodeName || c.ProviderId || c.AccountDisplay,
        Id: c.ProviderId,
        configurations: [c],
      });
    } else {
      result.push({
        name: c.Name || c.CodeName || c.ProviderId || c.AccountDisplay,
        ProviderId: c.ProviderId,
        configurations: [c],
      });
    }
  });

  return result
    .map((c) => ({
      ...c,
      count: c.configurations.length,
    }))
    .sort(sortByCount);
};

export default {
  sortConfigurationByIntegration,
};
