import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Pencil } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';

const MeshAPIMessage = ({ index, onCloseClick }) => {
  const title = 'Edition request has been made';
  const message = <div>Entity edition completed</div>;

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={Pencil}
    />
  );
};

const EnhancedMeshAPIMessage = compose(
  lifecycle({
    componentDidMount() {
      const title = 'An edition request has been made';
      const message = 'Entity edition completed';

      notify(title, { body: message });
    },
  }),
)(MeshAPIMessage);

export default EnhancedMeshAPIMessage;
