import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { createViewModel } from '../../entityViewModel/helpers';
import { createPropertyForQuickViewer } from '../../entity/selectors';
import setupGetSchema from '../../../selectors/schema';
import { shouldFetchEntity } from '../actions';
// import { createCluedInPropertyViewModel } from '../viewModels/factory';
import EntityPropertyViewerDetailed from './EntityPropertyViewerDetailed';
import { withEntityQuickview } from '../components/Hocs/withEntityQuickview';

const enhance = compose(
  lifecycle({
    componentDidMount() {
      const { fetchData, entityId } = this.props;
      fetchData(entityId);
    },
    componentWillReceiveProps(nextProps) {
      const { fetchData, entityId } = this.props;
      if (nextProps.entityId !== entityId) {
        fetchData(nextProps.entityId);
      }
    },
    componentWillUnmount() {
      const { closeEntityQuickView, entityId } = this.props;
      if (entityId) {
        closeEntityQuickView();
      }
    },
  }),
);

const mapToStateProps = (
  {
    entity: { schema, isFetchingSchema },
    entityModule: { isFetchingEntity, invalidEntity, currentEntity },
    integration: { allIntegrations },
  },
  { entityId, entityType },
) => {
  const getComponentForSchema =
    schema && currentEntity ? setupGetSchema(schema, currentEntity) : () => {};

  /*
    const cluedInPropertyViewerViewModels = (schema && currentEntity)
    ? createCluedInPropertyViewModel(
      currentEntity,
      getComponentForSchema,
    )
    : [];
  */

  const propertyViewerGroupedViewModels =
    schema && currentEntity
      ? createPropertyForQuickViewer(
          currentEntity,
          getComponentForSchema,
          true,
          allIntegrations,
        )
      : [];

  let viewModel = null;

  if (currentEntity) {
    viewModel = createViewModel(currentEntity);
  }

  return {
    viewModel,
    entity: currentEntity,
    isFetchingEntity,
    isFetchingSchema,
    invalidEntity,
    cluedInPropertyViewerViewModels: [],
    propertyViewerGroupedViewModels,
    open: !!entityId,
    entityId,
    entityType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchData(entityId) {
    if (entityId) {
      dispatch(shouldFetchEntity({ id: entityId }));
    }
  },
});

export default withEntityQuickview(
  connect(
    mapToStateProps,
    mapDispatchToProps,
  )(enhance(EntityPropertyViewerDetailed)),
);
