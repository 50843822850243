import React from 'react';
import { TextEllipsis } from 'uxi/Text';
import { Flex } from 'uxi/Layout';
import SuggestionTypeBadge from './SuggestionTypeBadge';

const SearchSuggestionItem = ({ suggestion, onClick }) => {
  return (
    <Flex
      style={{ width: '100%', boxSizing: 'border-box' }}
      onClick={onClick}
      data-test={`search-${suggestion.Name}-${suggestion.badgeLabel || 'all'}`}
    >
      <Flex
        style={{
          justifyContent: 'flex-start',
          flexGrow: 9,
          flexShrink: 1,
          overflow: 'hidden',
          marginRight: '8px',
        }}
      >
        <TextEllipsis
          title={suggestion.Name}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ flexGrow: 9, flexShrink: 1 }}>{suggestion.Name}</span>
          {suggestion.Highlight && (
            <span style={{ flexGrow: 1, flexShrink: 0 }}>&nbsp;</span>
          )}
          {suggestion.Highlight ? (
            <small style={{ opacity: 0.9, flexGrow: 1, flexShrink: 2 }}>
              <TextEllipsis title={suggestion.Highlight}>
                <span
                  dangerouslySetInnerHTML={{ __html: suggestion.Highlight }}
                />
              </TextEllipsis>
            </small>
          ) : (
            <span>&nbsp;</span>
          )}
        </TextEllipsis>
      </Flex>
      <Flex style={{ flexGrow: 0, flexShrink: 0, marginLeft: 'auto' }}>
        <SuggestionTypeBadge badgeLabel={suggestion.badgeLabel} />
      </Flex>
    </Flex>
  );
};

export default SearchSuggestionItem;
