import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import IntegrationHeader from '../../../../integration/components/composites/IntegrationHeader';
import { PageStructureContainer } from '../../../../core/components/composites/PageStructure';
import { Tab, TabList } from '../../../../core/components/composites/Tabs';

const IntegrationPageWrapper = ({
  children,
  integration,
  configuration,
  navigateToOverview,
  navigateToPermission,
  navigateToSettings,
  navigateToAuthSettings,
  isPermission,
  isSettings,
  isOverview,
  isAuthSettings,
  goBack,
}) => {
  if (!integration) {
    return null;
  }

  return (
    <div>
      <PageStructureContainer>
        <IntegrationHeader
          integration={integration}
          configuration={configuration}
        />
        <TabList isMainStyle>
          <Tab onClick={goBack}>← Back</Tab>
          <Tab onClick={navigateToOverview} selected={isOverview}>
            <FormattedMessage id="module-configuration-overview" />
          </Tab>
          <Tab onClick={navigateToPermission} selected={isPermission}>
            <FormattedMessage id="module-configuration-permissions" />
          </Tab>
          <Tab onClick={navigateToSettings} selected={isSettings}>
            <FormattedMessage id="module-configuration-settings" />
          </Tab>
          <Tab onClick={navigateToAuthSettings} selected={isAuthSettings}>
            <FormattedMessage id="module-configuration-authSettings" />
          </Tab>
        </TabList>
        <div style={{ margin: '15px 30px' }}>{children}</div>
      </PageStructureContainer>
    </div>
  );
};

const mapStateToProps = (
  state,
  { configurationId, integrationId, pathname },
) => {
  const isOverview =
    pathname === `/admin/configuration/${integrationId}/${configurationId}` ||
    pathname === `/admin/configuration/${integrationId}/${configurationId}/`;

  const isPermission =
    pathname ===
      `/admin/configuration/${integrationId}/${configurationId}/permissions` ||
    pathname ===
      `/admin/configuration/${integrationId}/${configurationId}/permissions/`;

  const isSettings =
    pathname ===
      `/admin/configuration/${integrationId}/${configurationId}/settings` ||
    pathname ===
      `/admin/configuration/${integrationId}/${configurationId}/settings/`;

  const isAuthSettings =
    pathname ===
      `/admin/configuration/${integrationId}/${configurationId}/auth-settings` ||
    pathname ===
      `/admin/configuration/${integrationId}/${configurationId}/auth-settings/`;

  return {
    isPermission,
    isSettings,
    isOverview,
    isAuthSettings,
  };
};

const mapDispatchToProps = (dispatch, { configurationId, integrationId }) => ({
  goBack: () => {
    dispatch(push('/admin/inbound/configuration'));
  },
  navigateToPermission: () => {
    dispatch(
      push(
        `/admin/configuration/${integrationId}/${configurationId}/permissions`,
      ),
    );
  },
  navigateToOverview: () => {
    dispatch(push(`/admin/configuration/${integrationId}/${configurationId}/`));
  },
  navigateToSettings: () => {
    dispatch(
      push(`/admin/configuration/${integrationId}/${configurationId}/settings`),
    );
  },
  navigateToAuthSettings: () => {
    dispatch(
      push(
        `/admin/configuration/${integrationId}/${configurationId}/auth-settings`,
      ),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationPageWrapper);
