"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Tooltip = require("./Tooltip.utils");
var _theme = require("@cluedin/theme");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var fade = _theme.colorManipulator.fade;
var _default = function _default(TooltipContentWrapper) {
  return (0, _styledComponents.default)(TooltipContentWrapper)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  &.tooltip {\n    background-color: ", ";\n    border-radius: ", ";\n    color: ", ";\n    padding: 4px 8px;\n    font-size: ", ";\n    line-height: ", "em;\n    max-width: 300px;\n    word-wrap: break-word;\n    font-weight: ", ";\n  }\n  &.tooltipArrow {\n    position: relative;\n    margin: 0;\n  }\n  & .arrow {\n    overflow: hidden;\n    position: absolute;\n    width: 1em;\n    height: 0.71em; // = width / sqrt(2) = (length of the hypotenuse)\n    box-sizing: border-box;\n    color: ", ";\n    &::before {\n      content: '';\n      margin: auto;\n      display: block;\n      width: 100%;\n      height: 100%;\n      background-color: currentColor;\n      transform: rotate(45deg);\n    }\n  }\n  &.touch {\n    padding: 8px 16px;\n    font-size: ", ";\n    line-height: ", "em;\n    font-weight: ", ";\n  }\n  &.tooltipPlacementLeft {\n    transform-origin: right center;\n    margin: 0 12px;\n    [", "] {\n      margin: 0 14px;\n    }\n  }\n  &.tooltipPlacementRight {\n    transform-origin: left center;\n    margin: 0 12px;\n    [", "] {\n      margin: 0 14px;\n    }\n  }\n  &.tooltipPlacementTop {\n    transform-origin: center bottom;\n    margin: 12px 0;\n    [", "] {\n      margin: 14px 0;\n    }\n  }\n  &.tooltipPlacementBottom {\n    transform-origin: center top;\n    margin: 12px 0;\n    [", "] {\n      margin: 14px 0;\n    }\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return fade(theme.colors.grey[700], 0.9);
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.shape.borderRadius;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.common.white;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.typography.pxToRem(10);
  }, (0, _Tooltip.round)(14 / 10), function (_ref5) {
    var theme = _ref5.theme;
    return theme.typography.fontWeightMedium;
  }, function (_ref6) {
    var theme = _ref6.theme;
    return fade(theme.colors.grey[700], 0.9);
  }, function (_ref7) {
    var theme = _ref7.theme;
    return theme.typography.pxToRem(14);
  }, (0, _Tooltip.round)(16 / 14), function (_ref8) {
    var theme = _ref8.theme;
    return theme.typography.fontWeightRegular;
  }, function (_ref9) {
    var theme = _ref9.theme;
    return theme.breakpoints.up('sm');
  }, function (_ref10) {
    var theme = _ref10.theme;
    return theme.breakpoints.up('sm');
  }, function (_ref11) {
    var theme = _ref11.theme;
    return theme.breakpoints.up('sm');
  }, function (_ref12) {
    var theme = _ref12.theme;
    return theme.breakpoints.up('sm');
  });
};
exports.default = _default;