import { withDefaultErrorHandlingActions } from 'uxi-business/errorHandling';
import {
  defaultAsyncCallWithActions,
  createAsyncAction,
} from '../../core/helpers/action';
import { saveUsernameAndPasswordFromBoarding } from '../data';

export const fetchSignupUserActions = createAsyncAction(
  'PUBLIC_SIGNUP_UDATE_ACTION',
);
export const shouldFetchSignUser = withDefaultErrorHandlingActions(
  defaultAsyncCallWithActions(
    saveUsernameAndPasswordFromBoarding,
    fetchSignupUserActions,
    (dispatch) => {
      dispatch(fetchSignupUserActions.receive());
      window.location.assign('/');
    },
  ),
  {
    onErrorAction: fetchSignupUserActions.invalid,
  },
);
