import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import EntityIcon from '../../../entity/components/composites/EntityIcon';
import EntityNewChangesContainer from '../containers/menu/EntityNewChangesContainer';
import EntityListNewChanges from '../composites/EntityListNewChanges';
import ViewEntityChangesStyles from './ViewEntityChanges.style';
import EntityLinkContainer from '../../../wms/components/containers/EntityLinkContainer';

class ViewEntityChanges extends Component {
  static propTypes = {
    insight: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    viewEntity: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      viewType: '',
      selectedInsight: undefined,
    };
  }

  viewEntityHandler() {
    const { viewEntity, insight } = this.props;

    viewEntity(insight);
  }

  viewChangesHandler(insight) {
    this.setState({
      selectedInsight: insight,
      viewType: 'detail',
    });
  }

  resetViewHandler() {
    this.setState({
      selectedInsight: undefined,
      viewType: '',
    });
  }

  render() {
    const { insight, onBack } = this.props;
    const { viewType, selectedInsight } = this.state;
    let content;
    let entityChangesContent;
    let relatedEntitiesContent;
    let onBackHandler = onBack;

    if (viewType === 'detail') {
      onBackHandler = this.resetViewHandler.bind(this);

      content = (
        <div>
          <div style={ViewEntityChangesStyles.title}>
            <h3>
              <FormattedMessage id="relatedEntityWithChanges" />
            </h3>
          </div>
          <div style={ViewEntityChangesStyles.entityTitleWrapper}>
            <div style={ViewEntityChangesStyles.entityTitleIcon}>
              <EntityIcon entityType={selectedInsight.Entity.EntityType} />
            </div>
            <div style={ViewEntityChangesStyles.entityTitleName}>
              <EntityLinkContainer
                id={selectedInsight.Entity.Id}
                entityType={selectedInsight.Entity.EntityType}
              >
                {selectedInsight.Entity.EntityName}
              </EntityLinkContainer>
            </div>
          </div>
          <EntityNewChangesContainer insight={selectedInsight.Entity} />
        </div>
      );
    } else {
      if (
        insight.Changes &&
        insight.Changes.Changes &&
        insight.Changes.Changes.length > 1
      ) {
        entityChangesContent = <EntityNewChangesContainer insight={insight} />;
      } else {
        entityChangesContent = (
          <p>
            <FormattedMessage id="noChangeForCurrentEntity" />
          </p>
        );
      }

      if (insight.Entities && insight.Entities.length > 0) {
        relatedEntitiesContent = (
          <div style={ViewEntityChangesStyles.listWrapper}>
            <EntityListNewChanges
              viewChanges={this.viewChangesHandler.bind(this)}
              savedSearch={insight}
            />
          </div>
        );
      } else {
        relatedEntitiesContent = (
          <p>
            <FormattedMessage id="noRelatedEntities" />
          </p>
        );
      }

      content = (
        <div>
          <div style={ViewEntityChangesStyles.listWrapper}>
            <div>
              <h3 style={ViewEntityChangesStyles.newChangesTitle}>
                <FormattedMessage id="newChanges" />
              </h3>
            </div>
            {entityChangesContent}
          </div>
          <div style={ViewEntityChangesStyles.newChangesTitleWrapper}>
            <h3 style={ViewEntityChangesStyles.newChangesSubTitle}>
              Related Entities
            </h3>
            {relatedEntitiesContent}
          </div>
        </div>
      );
    }

    return (
      <div style={{ padding: '16px' }}>
        <div style={ViewEntityChangesStyles.header}>
          <div style={ViewEntityChangesStyles.headerBack}>
            <Button
              onClick={onBackHandler}
              text={<FormattedMessage id="backWitArrow" />}
            />
          </div>
          <div style={ViewEntityChangesStyles.headerButton}>
            <Button
              onClick={this.viewEntityHandler.bind(this)}
              text={<FormattedMessage id="viewFullEntity" />}
            />
          </div>
        </div>
        <div style={ViewEntityChangesStyles.entityWrapper}>
          <div style={ViewEntityChangesStyles.entityType}>
            <EntityIcon entityType={insight.Entity.EntityType} />
          </div>
          <div style={ViewEntityChangesStyles.entityName}>
            {insight.Entity.EntityName}
          </div>
        </div>
        {content}
      </div>
    );
  }
}

export default ViewEntityChanges;
