import styled from 'styled-components';

export const EdgePropertiesPanelEdgeGroupEntryInnerWrapper = styled.div<{
  isExpanded: boolean;
  theme?: unknown;
}>`
  align-items: center;
  background: ${({ isExpanded, theme }) =>
    isExpanded ? theme?.palette?.themeLighterAlt : '#fff'};
  border-bottom: ${({ isExpanded }) =>
    isExpanded ? '1px solid #ececec' : null};
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 14px;
  transition: all 100ms ease;
  width: 100%;

  &:hover {
    background: ${({ isExpanded, theme }) =>
      isExpanded ? theme.palette.themeLighter : '#fafafa'};
  }

  & svg {
    margin-right: 10px;
  }
`;
