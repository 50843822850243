"use strict";

exports.__esModule = true;
exports.getAntiForgeryToken = void 0;
var getAntiForgeryToken = function getAntiForgeryToken() {
  var $antiForgery = document.querySelector('input[name=__RequestVerificationToken]');
  if (!$antiForgery) {
    return null;
  }
  return $antiForgery.value;
};
exports.getAntiForgeryToken = getAntiForgeryToken;