import React, { Component } from 'react';
import { push } from 'connected-react-router';
import Tooltip from 'rc-tooltip';
import styled from 'styled-components';
import Right from 'uxi/Icons/Arrowright';
import { Delete } from 'uxi/Icons';
import { Flex } from 'uxi/Layout';
import EntityIcon from '../../../entity/components/composites/EntityIcon';
import InsightLink from './InsightLink';
import withEntityLink from '../../../wms/components/Hocs/withEntityLink';

const ListItem = styled.li`
  list-style: none;
  position: relative;
  padding: 0;
  border-bottom: 1px solid #dee5e7;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  .tooltipWrapper {
    opacity: 0;
    margin-right: 15px;
  }
  &:hover {
    background-color: #f2f2f2;
    .tooltipWrapper {
      opacity: 1;
    }
  }
`;

const InsightListStyles = {
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    paddingLeft: 0,
    flex: 1,
    textAlign: 'left',
    overflow: 'hidden',
  },
  badge: {
    color: 'rgb(255, 64, 129)',
    marginTop: 0,
    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
    width: '180px',
  },
  trashCanSVGIcon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    top: '2px',
  },
  badgeSVGIcon: {
    fill: 'rgb(255, 64, 129)',
    // float: 'right',
    marginTop: '2.5px', // optical alignment
  },
  iconWrapper: {
    paddingTop: '5px',
    marginRight: '10px',
    marginLeft: '10px',
  },
};

class InsightListItem extends Component {
  onInsightClickHandler(e) {
    const { onInsightClick, entityUrl, navigate } = this.props;

    if (onInsightClick) {
      onInsightClick(e);
    }

    navigate(entityUrl);
  }

  viewChangesHandler(i, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const { viewChanges } = this.props;
    if (viewChanges) {
      viewChanges(i);
    }
  }

  removeEntity(insight, e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const { onRemovInsightClick } = this.props;

    onRemovInsightClick(insight);
  }

  render() {
    const { insight } = this.props;

    let badgeComponent;
    if (insight.Count && insight.Count > 0) {
      const badgetContent = `View ${insight.Count} changes`;

      badgeComponent = (
        // eslint-disable-next-line
        <a
          onClick={this.viewChangesHandler.bind(this, insight)}
          style={InsightListStyles.badge}
        >
          {badgetContent}
          <Right style={InsightListStyles.badgeSVGIcon} />
        </a>
      );
    }

    return (
      <ListItem
        className="test_NotificationCenter_FollowedEntityItem"
        data-test-id={insight.Entity.EntityId}
        onClick={this.onInsightClickHandler.bind(this, insight)}
      >
        <div style={InsightListStyles.iconWrapper}>
          <EntityIcon entityType={insight.Entity.EntityType} />
        </div>
        <div style={InsightListStyles.link}>
          <InsightLink insight={insight} />
        </div>
        <Flex>
          <div className="tooltipWrapper">
            <Tooltip placement="bottom" overlay={<span>Remove Entity</span>}>
              <div style={{ cursor: 'pointer' }}>
                <Delete onClick={this.removeEntity.bind(this, insight)} />
              </div>
            </Tooltip>
          </div>
          {badgeComponent}
        </Flex>
      </ListItem>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigate(entityUrl) {
    dispatch(push(entityUrl));
  },
});

export default withEntityLink(InsightListItem, mapDispatchToProps);
