import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from 'uxi/Icons/Search';

const SearchBarStyle = {
  wrapper: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    flex: 1,
  },
  input: {
    display: 'table-cell',
    position: 'relative',
    zIndex: 2,
    float: 'left',
    width: '100%',
    marginBottom: 0,
    borderColor: '#cfdadd',
    borderRadius: '2px',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    height: '34px',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: '#555555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #cfdadd',
  },
  addon: {
    fontSize: '16px',
    paddingLeft: '40px',
    paddingRight: '15px',
    fontWeight: 'bold',
    borderColor: '#cfdadd',
    backgroundColor: '#edf1f2',
    lineHeight: 1,
    color: '#555555',
    textAlign: 'center',
    border: '1px solid #cfdadd',
    borderRadius: '2px',
    width: '1%',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'table-cell',
    borderLeft: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    cursor: 'pointer',
  },
};

class SearchBar extends Component {
  static propTypes = {
    onInputChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };
  static defaultProps = {
    placeholder: '',
  };

  handleChange(event) {
    const { onInputChange } = this.props;

    if (onInputChange) {
      onInputChange(event.target.value);
    }
  }

  render() {
    const { placeholder, searchValue } = this.props;

    return (
      <div style={SearchBarStyle.wrapper}>
        <input
          value={searchValue}
          onChange={this.handleChange.bind(this)}
          placeholder={placeholder}
          style={SearchBarStyle.input}
          type="text"
        />
        <span style={SearchBarStyle.addon}>
          <SearchIcon />
        </span>
      </div>
    );
  }
}

export default SearchBar;
