"use strict";

exports.__esModule = true;
exports.ToggleStyles = void 0;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var ToggleStyles = function ToggleStyles(_ref) {
  var disabled = _ref.disabled,
    checked = _ref.checked,
    className = _ref.className;
  var bgHasError = (className || '').indexOf('has-error') > -1 ? {
    background: '#F16A6A'
  } : {};
  var buttonCheckedStyles = checked ? {} : {
    backgroundColor: '#D7D7D8',
    borderColor: '#D7D7D8',
    '.ms-Toggle-thumb': {
      backgroundColor: '#fff',
      width: '18px',
      height: '18px'
    },
    ':hover': {
      '.ms-Toggle-thumb': {
        backgroundColor: '#fff'
      }
    }
  };
  var positionings = checked ? {
    right: '-1px'
  } : {
    left: '-1px'
  };
  var bgColor = disabled && checked ? {
    backgroundColor: '#BFE3E1'
  } : {
    backgroundColor: '#fff'
  };
  var buttonCheckedStylesDisabled = disabled ? _extends({
    border: '1px solid #D7D7D8'
  }, bgColor, {
    '.ms-Toggle-thumb': _extends({
      backgroundColor: '#fff',
      border: '1px solid #D7D7D8',
      width: '20px',
      height: '20px',
      position: 'absolute'
    }, positionings)
  }) : {};
  return {
    root: {
      button: _extends({
        padding: 0,
        '.ms-Toggle-thumb': {
          background: '#fff',
          width: '18px',
          height: '18px'
        }
      }, buttonCheckedStyles, buttonCheckedStylesDisabled, bgHasError)
    }
  };
};
exports.ToggleStyles = ToggleStyles;