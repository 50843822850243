import { connect } from 'react-redux';
import intersectionWith from 'lodash/intersectionWith';
import includes from 'lodash/includes';
import { reject } from 'lodash';

const mapDipsatchToPropsFromKey = ({ entity: { schema } }, { propertyKey }) => {
  const schemaValue = schema.find((e) => {
    return propertyKey === e.Key;
  });

  return {
    schema: schemaValue,
  };
};

export const withSchemaFromPropertyKey = (Comp) => {
  return connect(mapDipsatchToPropsFromKey)(Comp);
};

const mapStateToProps = (
  state,
  { schemaKeys, excludeSchemaKeys, entityType, vocabEntityTypes },
) => {
  const {
    entity: { schema: allSchemas },
  } = state;
  let allSchemaForEntityType = allSchemas || [];
  const schemaGroupings = vocabEntityTypes || [entityType];

  if (entityType || vocabEntityTypes) {
    allSchemaForEntityType = allSchemaForEntityType.filter((s) =>
      includes(schemaGroupings, s.Grouping),
    );
  }

  const schema = schemaKeys
    ? intersectionWith(
        allSchemaForEntityType,
        schemaKeys,
        (s, key) => s.Key === key,
      )
    : allSchemaForEntityType;

  const filterdSchema = excludeSchemaKeys
    ? reject(schema, (s) => {
        return excludeSchemaKeys.indexOf(s.Key) > -1;
      })
    : schema;
  return { schema: filterdSchema };
};

export const withSchema = (Comp) => {
  return connect(mapStateToProps)(Comp);
};

const mapDispatchToPropsFromEntity = (
  { entity: { schema } },
  { entityType },
) => {
  const allSchemaForEntityType = (schema || []).filter(
    (s) => s.Grouping === entityType && s.IsCore,
  );

  return {
    schema: allSchemaForEntityType,
  };
};

export const withSchemaFromEntity = (Comp) => {
  return connect(mapDispatchToPropsFromEntity)(Comp);
};

/**
 * Please do not export the exact same thing under BOTH
 * NAMED export
 * and DEFAULT export
 */
export default withSchema;
