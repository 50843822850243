import React, { Fragment, useState } from 'react';
import { PrimaryButton } from '@cluedin/form';
import uuid from 'uuid/v4';
import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';
import SchemaPropertyChooserDialog from '../../../../../../schema/components/containers/SchemaPropertyChooserDialog';
import QueryBuilder from '../../../queryBuilder/QueryBuilder';

const ButtonWrapper = styled.div`
  position: relative;
  margin-left: 9px;
  &::before {
    display: ${({ hasPredicates }) => (hasPredicates ? 'block' : 'none')};
    content: '';
    height: 4px;
    width: 10px;
    background: #bdbdbd;
    position: absolute;
    left: -10px;
    top: 18px;
  }
`;

const filterToPropertyVM = (filters = []) => {
  return filters
    .filter((f) => f.type === 'property')
    .map((f) => {
      return {
        schema: {
          Key: f.displayName,
        },
        value: f.value,
        id: f.id,
      };
    });
};

const SearchPropertyFilter = ({
  onAddFilter,
  onRemoveFilter,
  selectedFilters,
  showPropertyFilterSearch,
  schemaKeys,
}) => {
  const selectedPredicate = filterToPropertyVM(selectedFilters);
  const [openDialog, setDialog] = useState(false);

  return (
    <Fragment>
      {openDialog && (
        <SchemaPropertyChooserDialog
          unavailableProperties={selectedPredicate.map((p) => p.schema.Key)}
          show={openDialog}
          schemaKeys={schemaKeys}
          onSchemaSelect={(schema) => {
            onAddFilter({
              id: uuid(),
              type: 'property',
              displayName: schema.Key,
              value: '',
            });
            setDialog(false);
          }}
          onClose={() => {
            setDialog(false);
          }}
        />
      )}
      <div
        style={{
          borderBottom: '1px solid #cecece',
          boxSizing: 'border-box',
          width: '100%',
          display: showPropertyFilterSearch ? 'block' : 'none',
        }}
      >
        <div style={{ padding: '16px' }}>
          {selectedPredicate && (
            <QueryBuilder
              predicates={selectedPredicate}
              onPredicateValueChange={({ value, schema, id }) => {
                onAddFilter({
                  id,
                  type: 'property',
                  displayName: schema.Key,
                  value,
                });
              }}
              onDeletePredicate={(id) => {
                const predicate = (selectedPredicate || []).find(
                  (p) => p.id === id,
                );

                onRemoveFilter({
                  id,
                  type: 'property',
                  displayName: predicate.schema.Key,
                  value: predicate.value,
                });
              }}
            />
          )}
          <ButtonWrapper hasPredicates={selectedPredicate.length > 0}>
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              text={<FormattedMessage id="module-search-addVocabTermFilter" />}
              onClick={() => {
                setDialog(true);
              }}
            />
          </ButtonWrapper>
        </div>
      </div>
    </Fragment>
  );
};

SearchPropertyFilter.displayName = 'SearchPropertyFilter';

export default SearchPropertyFilter;
