import React from 'react';
import styled from 'styled-components';
import { Button } from '@cluedin/form';

const PrivacyFeatureWrapper = styled.div`
  margin: 0 16px 32px;
  @media (min-width: 700px) {
    margin: 0 16px 48px;
  }
`;

const PrivacyFeatureContent = styled.div`
  /* createAppLayout */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
  & > *:nth-child(2) {
    flex-grow: 99;
    /* center title+Content combo vertically in available space */
    display: flex;
    flex-direction: inherit;
    justify-content: inherit;
  }

  border-radius: 5px;
  position: relative;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
  @media (min-width: 700px) {
    min-height: 300px;
  }
  border: 1px solid #fff;
  &:hover {
    border: 1px solid #279a95;
  }
`;

const Label = styled.div`
  padding: 0 15px;
  width: 80px;
  height: 90px;
  border-bottom-right-radius: 90px;
  border-bottom-left-radius: 90px;
  margin: -1px auto 0 auto; /* compensate for synamic border */
  color: #fff;
  background: #279a95;
`;
const Content = styled.div`
  margin: 8px 16px;
`;
const ButtonWrapper = styled.div`
  margin: 8px 16px;
`;

const Title = styled.div`
  font-weight: bold;
  color: #595959;
  padding: 16px 0;
`;

const Text = styled.div`
  padding-bottom: 15px;
`;

const PrivacyFeature = ({
  title,
  text,
  style = {},
  actionText,
  icon,
  onClick,
}) => (
  <PrivacyFeatureWrapper style={style}>
    <PrivacyFeatureContent>
      <Label> {icon} </Label>
      <Content>
        <div>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </div>
      </Content>
      <ButtonWrapper>
        <Button text={actionText} onClick={onClick} />
      </ButtonWrapper>
    </PrivacyFeatureContent>
  </PrivacyFeatureWrapper>
);

export default PrivacyFeature;
