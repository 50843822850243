/* eslint-disable */
import { 
  apiRequest, 
  authRequest,
  authRequestFormData,
} from '../core/helpers/request';
import { clientId } from '../../config';

export const signUp = (email) =>
  authRequest('POST', 'api/organization/signup', {
    Email: email,
  });

export const saveUserOrgEmail = ({ email, clientId }) =>
  authRequest('POST', 'api/user/invite', {
    ClientId: clientId,
    Email: email,
  });

/**
 * For the EPIC/signup, we need to mvoe this call towards the APP services.
 */
export const requestPasswordResetEmail = ({ email }) => {
  return authRequestFormData('POST', 'api/account/forgot', {
      Email: email,
      ClientId: clientId,
    }
  );
};

export const fetchDataShards = () =>
  apiRequest('GET', 'api/account/datashards');

export const createOrganizationAndUser = (signUpInfo) =>
  authRequest('POST', 'api/organization/create', {
    ...signUpInfo,
    confirmpassword: signUpInfo.password,
    grant_type: 'password',
    allowEmailDomainSignup: false,
    emailDomain: '',
    applicationSubDomain: signUpInfo.organizationName.toLowerCase(),
    VerificationCode: signUpInfo.code,
  });

export const requestPasswordReset = ({ password, email, code }) =>
  authRequestFormData('POST', 'api/account/reset', {
    Password: password,
    ConfirmPassword: password,
    Email: email,
    code: code,
    ClientId: clientId,
  });

export const saveUsernameAndPasswordFromBoarding = ({
  username,
  password,
  invitationId,
  clientId,
  email,
}) =>
authRequest('POST', 'api/user/verify', {
    username,
    password,
    confirmpassword: password,
    applicationSubDomain: clientId,
    grant_type: 'password',
    email,
    clientid: clientId,
    VerificationCode: invitationId,
  });

export default {
  signUp,
  requestPasswordResetEmail,
  fetchDataShards,
  requestPasswordReset,
  saveUsernameAndPasswordFromBoarding,
};
