"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(ListItemText) {
  return (0, _styledComponents.default)(ListItemText)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  box-sizing: border-box;\n  line-height: 48px;\n  list-style: none;\n  color: ", ";\n  font-weight: 500;\n  font-size: 14px;\n  .colorPrimary {\n    color: ", ";\n  }\n  .colorInherit {\n    color: inherit;\n  }\n  .gutters {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n  .inset {\n    padding-left: 72px;\n  }\n  .sticky {\n    position: sticky;\n    top: 0;\n    z-index: 1;\n    background-color: inherit;\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.text.secondary;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.primary.main;
  });
};
exports.default = _default;