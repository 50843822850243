import { createContext, ReactNode, useMemo } from 'react';

import { useLayoutMenu } from './useLayoutMenu';

export type UseLayoutMenuReturnType = ReturnType<typeof useLayoutMenu>;

export const LayoutContext = createContext<UseLayoutMenuReturnType>({
  isMenuCollapsed: undefined,
  isMenuMinimized: false,
  toggleMenu: () => {},
});
LayoutContext.displayName = 'LayoutContext';

export const LayoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { toggleMenu, isMenuCollapsed, isMenuMinimized } = useLayoutMenu();

  const value = useMemo(
    () => ({
      toggleMenu,
      isMenuMinimized,
      isMenuCollapsed,
    }),
    [toggleMenu, isMenuMinimized, isMenuCollapsed],
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
