import styled from 'styled-components';

export const EntityPropertiesStep1WarningWrapper = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;

export const EntityPropertiesStep1WarningMessageWrapper = styled.div`
  padding: 16px 0;
`;
