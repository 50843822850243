import React from 'react';
import { Link } from 'react-router-dom';
import { HighlightTextFromContext } from '@cluedin/components';
import { useTheme } from '@cluedin/theme';

const RoleDetailLink = ({ name }) => {
  const theme = useTheme();

  return (
    <Link
      to={`/admin/settings/roles/detail/${name}`}
      style={{ color: theme?.palette?.themePrimary }}
    >
      <HighlightTextFromContext text={name} />
    </Link>
  );
};

export default RoleDetailLink;
