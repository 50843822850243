import { memo } from 'react';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';

import { VocabularyKeyListSelectionDialog } from '../../VocabularyKeyListSelectionDialog/VocabularyKeyListSelectionDialog';

const AddVocabulary = ({
  onVocabularySelectedChange,
  selectedVocabulary,
  currentNumberColumns,
  maxNumberColumns,
}) => {
  const maxedOut = currentNumberColumns >= maxNumberColumns;

  return (
    <>
      {maxedOut && (
        <Alert type="warning">
          <div>
            <FormattedMessage
              id="module-dataCatalog-cannotSelectMore"
              values={{
                maxNumberColumns,
              }}
            />
          </div>
        </Alert>
      )}
      <VocabularyKeyListSelectionDialog
        selectedVocabulary={selectedVocabulary}
        onSelectedChange={onVocabularySelectedChange}
      />
    </>
  );
};

export default memo(AddVocabulary);
