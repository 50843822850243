import { handleActions } from 'redux-actions';
import {
  currentUsageAction,
  currentOrganizationActions,
  saveOrganizationActions,
  updatePrimaryColor,
  updateSecondaryColor,
  uploadLogoActions,
  updateLogoForPreview,
  shouldClearLogo,
  shouldUpdateOrganization,
} from './actions';

const initialState = {
  usage: null,
  isFetchingUsage: false,
  isInvalidUsage: false,
  organization: null,
  isFetchingOrganization: false,
  invalidOrganization: false,
  isSavingOrganization: false,
  hasSavingOrganizationError: false,
  isUploadingLogo: false,
  isInvalidUploadingLogo: false,
};

export default handleActions(
  {
    [shouldUpdateOrganization]: (state, { payload }) => ({
      ...state,
      organization: {
        ...payload,
      },
    }),
    [uploadLogoActions.request]: (state) => ({
      ...state,
      isUploadingLogo: true,
      isInvalidUploadingLogo: false,
    }),
    [uploadLogoActions.receive]: (state, { payload }) => ({
      ...state,
      organization: {
        ...state.organization,
        Logo: payload,
      },
      isUploadingLogo: false,
      isInvalidUploadingLogo: false,
    }),
    [uploadLogoActions.invalid]: (state) => ({
      ...state,
      isUploadingLogo: false,
      isInvalidUploadingLogo: true,
    }),
    [updatePrimaryColor]: (state, { payload }) => ({
      ...state,
      organization: {
        ...state.organization,
        PrimaryColor: payload,
      },
    }),
    [updateSecondaryColor]: (state, { payload }) => ({
      ...state,
      organization: {
        ...state.organization,
        SecondaryColor: payload,
      },
    }),
    [updateLogoForPreview]: (state, { payload }) => ({
      ...state.organization,
      Logo: payload,
    }),
    [updateLogoForPreview]: (state, { payload }) => ({
      ...state.organization,
      Logo: payload,
    }),
    [shouldClearLogo]: (state) => ({
      ...state,
      organization: {
        ...state.organization,
        Logo: '',
      },
    }),
    [saveOrganizationActions.request]: (state) => ({
      ...state,
      isSavingOrganization: true,
      hasSavingOrganizationError: false,
    }),
    [saveOrganizationActions.receive]: (state) => ({
      ...state,
      isSavingOrganization: false,
      hasSavingOrganizationError: false,
    }),
    [saveOrganizationActions.invalid]: (state) => ({
      ...state,
      isSavingOrganization: false,
      hasSavingOrganizationError: true,
    }),
    [currentUsageAction.request]: (state) => ({
      ...state,
      isFetchingUsage: true,
      isInvalidUsage: false,
    }),
    [currentUsageAction.receive]: (state, { payload }) => ({
      ...state,
      usage: payload,
      isFetchingUsage: false,
      isInvalidUsage: false,
    }),
    [currentUsageAction.invalid]: (state) => ({
      ...state,
      isFetchingUsage: false,
      isInvalidUsage: true,
    }),
    [currentOrganizationActions.request]: (state) => ({
      ...state,
      organization: null,
      isFetchingOrganization: true,
    }),
    [currentOrganizationActions.receive]: (state, { payload }) => ({
      ...state,
      organization: payload,
      isFetchingOrganization: false,
    }),
    [currentOrganizationActions.invalid]: (state) => ({
      ...state,
      organization: null,
      invalidOrganization: true,
      isFetchingOrganization: false,
    }),
  },
  initialState,
);
