import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import { GqlErrorMessages } from '@cluedin/form';

import HelpInfoFromService from '../../../../help/components/containers/HelpInfoFromService';
import EntityTopologySplitPreviewDataParts from '../../composites/EntityTopologySplitPreviewDataParts/EntityTopologySplitPreviewDataParts';
import EntityTopologySplitPreviewNewEntities from '../../composites/EntityTopologySplitPreviewNewEntities/EntityTopologySplitPreviewNewEntities';
import { useEntitySplitPreview } from '../../../hooks/useEntitySplitPreview';

const EntityTopologyManualPanelPreview = ({
  entityId,
  data: nodeData,
  setNewEntityCount,
  setIsPreviewError,
  setIsPreviewLoading,
}) => {
  const [entitySplitPreview, { data, loading, error }] =
    useEntitySplitPreview();

  useEffect(() => {
    setIsPreviewLoading(loading);
    setIsPreviewError(error);
  }, [loading, error]);

  const paramsToString = JSON.stringify(
    nodeData?.actions?.[0]?.splitEntityActionRequest,
  );

  useEffect(() => {
    entitySplitPreview(entityId, paramsToString);
  }, [nodeData, entityId, paramsToString]);

  useEffect(() => {
    if (data && data?.newEntities) {
      setNewEntityCount(data?.newEntities?.length);
    }
  }, [data]);

  if (loading) {
    return null;
  }
  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  return (
    <>
      {data && data?.currentDataParts && data?.currentDataParts.length > 0 && (
        <>
          <HelpInfoFromService
            title="Preview Outcome"
            topicId="topologyPreviewOutcome"
            type="module"
            contentTypeExtra="dialog"
            modalWidth="1250px"
            modalHeight="80vh"
          />

          <EntityTopologySplitPreviewDataParts
            data={data?.currentDataParts}
            loading={loading}
            error={error}
            title={
              data?.currentDataParts.length === 1 ? (
                <FormattedMessage id="module-entityTopology-startingRecord" />
              ) : (
                <FormattedMessage id="module-entityTopology-startingRecords" />
              )
            }
            entityId={entityId}
            total={data?.currentDataParts.length}
          />
        </>
      )}

      {data && data?.newEntities?.[0] && data?.newEntities?.length > 0 && (
        <EntityTopologySplitPreviewNewEntities
          data={data?.newEntities}
          loading={loading}
          error={error}
          title={<FormattedMessage id="module-entityTopology-newEntities" />}
          entityId={entityId}
          total={data?.newEntities?.length}
        />
      )}
    </>
  );
};

export default EntityTopologyManualPanelPreview;

EntityTopologyManualPanelPreview.propTypes = {
  entityId: PropTypes.string,
  data: PropTypes.object,
  setNewEntityCount: PropTypes.func,
  setIsPreviewError: PropTypes.func,
  setIsPreviewLoading: PropTypes.func,
};

EntityTopologyManualPanelPreview.defaultProps = {
  entityId: null,
  data: {},
  setNewEntityCount: () => {},
  setIsPreviewError: () => {},
  setIsPreviewLoading: () => {},
};
