import React, { useMemo } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { withWorkflow } from '@cluedin/form';
import {
  AddIngestionPointWithoutDataSet,
  AddIngestionPointWithDataSet,
} from '../components/containers/AddIngestionPoint';

export const withAddIngestionPointWithoutDataSet = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => <Comp {...props} openImportFromIngestionPoint={openWorkflow} />,
        [props],
      ),
    (panelProps) => <AddIngestionPointWithoutDataSet {...panelProps} />,
    {
      withCustomHeader: true,
      panelType: 'medium',
    },
  );

  return <EnhancedhWorkflow />;
};

export const withAddIngestionPoint = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => (
          <Comp
            {...props}
            openImportFromIngestionPointWithExistingGroup={openWorkflow}
          />
        ),
        [props],
      ),
    (panelProps) => (
      <AddIngestionPointWithDataSet
        {...panelProps}
        dataSourceSetId={props.id}
      />
    ),
    {
      title: (
        <>
          <FormattedMessage id="data-source-import-data-from-ingestion-point-into" />{' '}
          {props.name} <FormattedMessage id="data-source-group-low" />
        </>
      ),
      withCustomHeader: true,
      panelType: 'medium',
    },
  );

  return <EnhancedhWorkflow />;
};
