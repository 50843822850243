import CleanModule from '../modules/clean/module';
import cleanProjectModule from '../modules/cleanProject/module';
import UserModule from '../modules/userModule/module';
import TokenModule from '../modules/apiToken/module';
import WhiteLabelModule from '../modules/whiteLabel/module';
import EntityTypesModule from '../modules/entityTypes/module';
import EntityTypesRetroModule from '../modules/entityTypes/retroLinkModule';
import GraphQlModule from '../modules/graphql/module';
import CatalogModule from '../modules/dataModeling/module';
import DuplicateModule from '../modules/duplicates/module';
import PreparationModule from '../modules/prepare/module';
import InboundModule from '../modules/dataSourceV2/module';
import MeshModule from '../modules/mesh/module';
import annotationModule from '../modules/annotation/module';
import expressionModule from '../modules/expression/module';
import ruleModule from '../modules/rule/module';
import glossaryModule from '../modules/glossary/module';
import hierarchyModule from '../modules/hierarchy/module';
import streamsModule from '../modules/streams/module';
import connectorModule from '../modules/connector/module';
import enricherModule from '../modules/enrich/module';
import piiModule from '../modules/pii/module';
import metricsModule from '../modules/metrics/module';
import FeatureFlagModule from '../modules/featureFlag/module';
import processingPipeline from '../modules/processingPipeline/module';
import statsModule from '../modules/statistics/module';
import configurationGroupModule from '../modules/configurationGroup/module';
import rolesModule from '../modules/roles/module';
import breachModule from '../modules/dataBreach/module';
import retentionModule from '../modules/retention/module';
import consentModule from '../modules/consent/module';
import gdprModule from '../modules/GDPR/module';
import integrationModule from '../modules/integration/module';
import configurationModule from '../modules/configuration/module';
import taskModule from '../modules/task/module';
import userInvitationModule from '../modules/userInvitationModule/module';
import securityModule from '../modules/security/module';
import dataCatalog from '../modules/dataCatalog/module';
import deduplicationModule from '../modules/deduplication/module';
import entityViewerModule from '../modules/entityViewers/module';
import entityProperties from '../modules/entityProperties/module';
import entityPendingChangesModule from '../modules/entityPendingChanges/module';
import entityPageLayoutModule from '../modules/entityPageLayout/module';
import manualDataEntryModule from '../modules/manualDataEntry/module';
import uploadFileModule from '../modules/uploadFile/module';

export default [
  UserModule,
  TokenModule,
  WhiteLabelModule,
  EntityTypesModule,
  GraphQlModule,
  CatalogModule,
  dataCatalog,
  DuplicateModule,
  MeshModule,
  ruleModule,
  piiModule,
  PreparationModule,
  InboundModule,
  annotationModule,
  expressionModule,
  CleanModule,
  cleanProjectModule,
  glossaryModule,
  hierarchyModule,
  streamsModule,
  connectorModule,
  enricherModule,
  metricsModule,
  processingPipeline,
  statsModule,
  configurationGroupModule,
  rolesModule,
  FeatureFlagModule,
  breachModule,
  retentionModule,
  consentModule,
  gdprModule,
  integrationModule,
  configurationModule,
  taskModule,
  userInvitationModule,
  securityModule,
  EntityTypesRetroModule,
  deduplicationModule,
  entityViewerModule,
  entityProperties,
  entityPendingChangesModule,
  entityPageLayoutModule,
  manualDataEntryModule,
  uploadFileModule,
];
