import { FormDecorator } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import VocabularyIcon from '../../../../dataCatalog/components/containers/VocabularyIcon';

export const SelectedVocabInfo = ({ vocabulary }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <FormDecorator
      label={<FormattedMessage id="data-group-selected-vocab-for-data-set" />}
    >
      <Link
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          color: themePrimary,
        }}
        target="_blank"
        to={`/admin/management/catalog/detail/${vocabulary?.vocabularyId}`}
      >
        <VocabularyIcon />

        <div style={{ margin: '0 6px' }}>{vocabulary?.vocabularyName}</div>
        <Externallink size={14} vocabulary={vocabulary} />
      </Link>
    </FormDecorator>
  );
};
