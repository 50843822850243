import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton } from '@cluedin/form';
import { connect } from 'react-redux';
import { Followentities } from 'uxi/Icons';
import {
  shouldSubsribeForSearch,
  shouldUnsubsribeForSearch,
} from '../../../actions';
import { hasFeature } from '../../../../core/selector';
import { hasSubsribedCurrentSearch } from '../../../helper';

const KeepInTheLoopButtonContainer = ({
  q,
  hasSubscribed,
  style = {},
  onUnSubscribe,
  onSubscribe,
  hasSavedSearch,
}) => {
  if (q === '*' || !hasSavedSearch) {
    return null;
  }

  const buttonAction = hasSubscribed ? onUnSubscribe : onSubscribe;

  const buttonText = hasSubscribed ? (
    <span className="test_SearchKeepInTheLoopText">
      <FormattedMessage id="SearchContent.Unsubscribe" />
    </span>
  ) : (
    <span className="test_SearchKeepInTheLoopText">
      <FormattedMessage id="SearchContent.KeepMeInTheLoop" />
    </span>
  );

  return (
    <PrimaryButton
      style={style}
      onClick={() => {
        buttonAction(q);
      }}
      text={buttonText}
      // icon={<Followentities />}
    />
  );
};

const mapToStateProps = (
  { core: { features }, stream: { saveSearches } },
  { q },
) => ({
  hasSavedSearch: hasFeature(features || [], 'savedSearches'),
  hasSubscribed: hasSubsribedCurrentSearch(q, saveSearches || []),
});

const mapDispatchToProps = (dispatch) => ({
  onSubscribe: (Query) => {
    dispatch(shouldSubsribeForSearch(Query));
  },
  onUnSubscribe: (Query) => {
    dispatch(shouldUnsubsribeForSearch(Query));
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(KeepInTheLoopButtonContainer);
