import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import {
  FormDecorator,
  FormSubmitAndCancel,
  TextField,
  PanelContent,
  PanelFooter,
  GqlErrorMessages,
} from '@cluedin/form';
import { useMutationInviteUser } from '../../../hooks/useMutationInviteUser';
import { isEmail } from '../../../../core/validation';
import { usePrevious } from '../../../../core/hooks';

const AddUserInvitationForm = ({ onClose, refetch }) => {
  const [name, setName] = useState('');
  let errorText = '';

  const [inviteUser, { done, loading, error }] = useMutationInviteUser(refetch);

  const isValid = name ? isEmail(name) : false;

  const prevDone = usePrevious(done);

  useEffect(() => {
    if (done && prevDone !== done) {
      onClose();
    }
  }, [prevDone, done]);

  if (!isValid && name) {
    errorText = 'Value must be a valid email';
  }

  return (
    <>
      <PanelContent>
        {error && <GqlErrorMessages error={error} />}
        <FormDecorator
          label={<FormattedMessage id="module-userInvitation-Email" />}
          errorText={errorText}
        >
          <TextField
            value={name}
            onChange={(e, v) => {
              setName(v);
            }}
          />
        </FormDecorator>
      </PanelContent>

      <PanelFooter>
        <FormSubmitAndCancel
          loading={loading}
          isTouchedAndNotSaved={!!name}
          submitProps={{
            disabled: !isValid,
            label: <FormattedMessage id="module-userInvitation-Save" />,
            onClick: () => {
              if (name) {
                inviteUser(name);
              }
            },
          }}
          onCancel={() => {
            onClose();
          }}
        />
      </PanelFooter>
    </>
  );
};

export default AddUserInvitationForm;
