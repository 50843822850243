import { handleActions } from 'redux-actions';
import { fetchSignupUserActions } from '../actions/createUser';

const initialState = {
  isFetching: false,
  done: false,
  invalid: false,
  isFetchingInvitation: false, // why is this there ?
  invalidFetchInvitation: true, // why is this there ?
  isFromEmailDomain: false, // why is this there ?
};

export default handleActions(
  {
    [fetchSignupUserActions.request]: (state) => ({
      ...state,
      isFetching: true,
      invalid: false,
      done: false,
    }),
    [fetchSignupUserActions.invalid]: (state) => ({
      ...state,
      invalid: true,
      isFetching: false,
      done: false,
    }),
    [fetchSignupUserActions.receive]: (state) => ({
      ...state,
      isFetching: false,
      done: true,
      invalid: false,
    }),
  },
  initialState,
);
