import { pick, groupBy } from 'lodash';
import { useState } from 'react';

const decodeFilters = (filterString) => {
  if (!filterString) {
    return [];
  }

  const allFilterByType = filterString.split('|AND|');
  const filters = [];

  allFilterByType.forEach((fByType) => {
    const splitBySameType = fByType.split('_OR_');

    (splitBySameType || []).forEach((f) => {
      const entitySplit = f.split(':');

      const index = f.indexOf(':');
      const filterKey = f.substring(0, index);
      const filterValue = f.substring(index + 1);

      const [value, displayNameAndIdMaybe] = filterValue.split('|');
      let id = null;
      let displayName = displayNameAndIdMaybe;
      if (displayNameAndIdMaybe.indexOf('$') > -1) {
        [id, displayName] = displayNameAndIdMaybe.split('$');
      }
      filters.push({
        id,
        type: filterKey,
        value,
        displayName: decodeURIComponent(displayName),
      });
    });
  });

  return filters;
};

const encodeFiltersForGQLSearch = (filters = []) => {
  if (!filters || filters.length === 0) {
    return [];
  }

  const filterResult = [];
  const filtersGoupedByType = groupBy(filters, (f) => f.type);

  Object.keys(filtersGoupedByType || {}).forEach((type) => {
    const values = filtersGoupedByType[type].map((filter) => filter.value);

    if (type === 'property') {
      filtersGoupedByType[type].forEach((p) => {
        if (p.value !== '') {
          filterResult.push({
            fieldName: `properties.${p.displayName}`,
            value: p.value,
          });
        }
      });
      // .map(filter => filter.displayName);
    } else {
      filterResult.push({
        aggregationName: type,
        values,
        operator: 'OR',
      });
    }
  });

  return filterResult;
};

const removeNewFilter = (filter, selectedFilters = []) => {
  return (
    selectedFilters
      // filter by id (property filter)
      .filter((f) => (f.id ? f.id !== filter.id : true))
      // filter by value/type
      .filter((f) => {
        if (f.id) {
          return true;
        } // (property filter)

        const encodedValue = Array.isArray(filter.value)
          ? filter.value.join()
          : filter.value;
        const currentEncodedValue = Array.isArray(f.value)
          ? f.value.join()
          : f.value;

        return filter.type !== f.type || encodedValue !== currentEncodedValue;
      })
  );
};

const updateNewFilter = (filter, selectedFilters = []) => {
  const filterCleanedUp = pick(filter, ['id', 'type', 'displayName', 'value']);

  const result = removeNewFilter(filterCleanedUp, selectedFilters);
  result.push(filterCleanedUp);

  return result;
};

const getFiltersVariables = (
  selectedFiltersParam = [],
  entityTypePoolIdentifier,
) => {
  let selectedFilters = selectedFiltersParam;

  if (!Array.isArray(selectedFilters)) {
    return [];
  }

  // graphQL uses contextIds for those edges that are entity
  selectedFilters = selectedFilters.filter(
    (filter) => filter.type !== '/Person' && filter.type !== '/Organization',
  );

  if (entityTypePoolIdentifier && entityTypePoolIdentifier !== 'searchResult') {
    selectedFilters = selectedFilters.filter(
      (filter) => filter.type !== 'entityType',
    );
    if (entityTypePoolIdentifier === 'person') {
      // TODO: use the entityTypePools definition from /module/search/helper.js
      selectedFilters.push({
        type: 'entityType',
        value: '/Person',
      });
      selectedFilters.push({
        type: 'entityType',
        value: '/Infrastructure/User',
      });
    } else if (entityTypePoolIdentifier === 'organization') {
      selectedFilters.push({
        type: 'entityType',
        value: '/Organization',
      });
    }
  }

  return encodeFiltersForGQLSearch(selectedFilters);
};

export const useSearchFilters = (searchQuery, entityTypePoolIdentifier) => {
  const decodedFiltersFromSearch = decodeFilters(searchQuery);
  const [selectedFilters, setSelectedFilter] = useState(
    decodedFiltersFromSearch,
  );

  const filtersVariables = getFiltersVariables(
    selectedFilters,
    entityTypePoolIdentifier,
  );

  return [
    selectedFilters,
    {
      filters: filtersVariables,
      addFilter: (filter) => {
        const updateFilters = updateNewFilter(filter, selectedFilters);
        setSelectedFilter(updateFilters);
      },
      removeFilter: (filter) => {
        const updateFilters = removeNewFilter(filter, selectedFilters);
        setSelectedFilter(updateFilters);
      },
      clearAllFilters: () => {
        setSelectedFilter([]);
      },
    },
  ];
};
