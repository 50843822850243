import { connect } from 'react-redux';
import { fetchTeamDomain, shouldUpdateClientIdProposal } from '../../actions';
import SelectClientId from '../composites/SelectClientId';

const mapStateToProps = ({
  auth: {
    clientIds,
    isFetchingTeamDomain,
    redirectingTeamDomain,
    teamDomainInfo,
    clientIdProposal,
    invalidClientIdProposal,
  },
}) => ({
  isFetching: isFetchingTeamDomain || redirectingTeamDomain,
  invalidTeamInfo: teamDomainInfo ? teamDomainInfo.isAvailable : false,
  clientIds: (clientIds || []).filter((x) => x),
  clientIdProposal,
  isError: invalidClientIdProposal,
});

const mapDispatchToProps = (dispatch) => ({
  onClientIdChange: (clientId) => {
    dispatch(shouldUpdateClientIdProposal(clientId));
  },
  selectId: (id) => {
    if (id) {
      dispatch(fetchTeamDomain(id));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectClientId);
