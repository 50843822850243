import { IconCircle } from './IconCircle';
import { useTheme } from '@cluedin/theme';

type FileIconProps = {
  type: string;
};

export const FileIcon = ({ type }: FileIconProps) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const warning = theme.semanticColors.warningHighlight;

  if (type === 'error') {
    return (
      <IconCircle
        backgroundColor={theme.semanticColors.errorText}
        style={{ margin: '4px 12px 0 0' }}
        iconName="Cancel"
      />
    );
  }

  if (type === 'warning') {
    return (
      <IconCircle
        backgroundColor={warning}
        style={{ margin: '4px 12px 0 0' }}
        iconName="Warning"
      />
    );
  }

  return (
    <IconCircle
      backgroundColor={themePrimary}
      style={{ margin: '4px 12px 0 0' }}
      iconName="CheckMark"
    />
  );
};
