import React from 'react';
import { Loader, Select } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import { TYPED_OR_UNTYPED_DATA_TYPES } from '../../../../../../../constants/storageTypes';
import { useQueryVocabularyKeyType } from '../../../../../hooks/useQueryVocabularyKeyType';

export const VocabKeyTypeSelector = ({
  onChange,
  defaultLabel = (
    <FormattedMessage id="module-dataCatalog-vocabKeySelectType" />
  ),
  value = '',
  style = {},
  disabled,
  extraProps,
  ...rest
}) => {
  const [types, loading] = useQueryVocabularyKeyType();

  if (loading) {
    return (
      <div style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }

  const defaultValue = {
    value: '',
    key: 'default',
    label: defaultLabel,
  };

  const options = (types || []).map((type) => ({
    key: type,
    value: type,
    label: type,
  }));

  const handleChange = ({ value }) => {
    const isTyped = TYPED_OR_UNTYPED_DATA_TYPES.includes(value);

    extraProps?.setValue?.('storage', isTyped ? undefined : 'Keyword');

    onChange(value);
  };

  return (
    <div {...rest} style={style}>
      <Select
        defaultValue={defaultValue}
        disabled={disabled}
        style={style}
        value={value}
        isFullWidth
        onChange={handleChange}
        options={[defaultValue, ...options]}
      />
    </div>
  );
};

export default VocabKeyTypeSelector;
