import moment from 'moment';

export const isUserBlocked = (user) => {
  if (
    (user && user.Account && user.Account.LockoutEnd === null) ||
    moment(user.Account.LockoutEnd).isBefore(Date.now())
  ) {
    return user;
  }

  return null;
};

export const isUserBlockedAndCurrentUserExcluded = (user, currentUser) => {
  if (
    user &&
    isUserBlocked(user) &&
    (user && user.Account && user.Account.Id) !==
      (currentUser && currentUser.client && currentUser.client.Id)
  ) {
    return user;
  }

  return null;
};
