import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_VOCABULARY_TYPES = gql`
  query vocabularyType {
    management {
      id
      vocabularyTypes
    }
  }
`;

export const useQueryVocabularyKeyType = (id) => {
  const { data, loading, error } = useQuery(GET_VOCABULARY_TYPES, {
    variables: {
      id,
    },
  });

  const types = get(data, 'management.vocabularyTypes', []);

  return [types, loading, error];
};

export default {
  useQueryVocabularyKeyType,
};
