import React from 'react';
import getAppropriateIcon from 'uxi/Icons/getAppropriateIcon';
import DashboardKpi from './DashboardKpi';
import UnstyledLink from '../shared/UnstyledLink';
import RenderChildren from '../shared/RenderChildren';
import { withGqlQuery } from '../../data/withGqlQuery';

const Kpi = ({ title, icon, loading, drawer: { route } = {}, data }) => {
  const WrapperComp = route ? UnstyledLink : RenderChildren;
  const Icon = getAppropriateIcon(icon || 'Help');
  return (
    <WrapperComp to={route}>
      <DashboardKpi
        value={data}
        loading={loading}
        title={title}
        icon={<Icon />}
      />
    </WrapperComp>
  );
};

export default {
  name: 'Kpi',
  type: 'kpi',
  required: 'dashboard',
  Component: withGqlQuery(Kpi),
  parameters: [
    {
      type: 'string',
      name: 'title',
      displayName: 'Title',
      helpText: 'The main label for your KPI',
    },
    {
      type: 'string',
      name: 'to',
      displayName: 'Link',
      helpText: 'Link the user will be redirect to',
    },
    {
      type: 'icon',
      name: 'icon',
      displayName: 'Icon',
      helpText:
        'A nice icon to choose from to make the widget more understandable.',
    },
    {
      type: 'string',
      name: 'gql',
      displayName: 'Query',
      helpText: 'A Gql Query that would be executed',
    },
  ],
};
