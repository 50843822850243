import socials from './socials';
import conversation from './conversation';
import rctooltip from './rctooltip';
import wmsGrid from './wmsGrid';
import graphiQL from './graphiQL';

export default {
  socials,
  conversation,
  rctooltip,
  wmsGrid,
  graphiQL,
};
