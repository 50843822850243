import { useQuery, NetworkStatus } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

export const GET_ROLES = gql`
  query ($searchName: String, $itemsPerPage: Int, $pageNumber: Int) {
    administration {
      id
      roles(
        searchName: $searchName
        itemsPerPage: $itemsPerPage
        pageNumber: $pageNumber
      ) {
        total
        data {
          id
          name
          description
          userCount
        }
      }
    }
  }
`;

export const useQueryRoles = (filters) => {
  const {
    searchTerm: searchName,
    itemsPerPage,
    selectedPage: pageNumber,
  } = filters;

  const variables = { searchName, itemsPerPage, pageNumber };
  const fetchPolicy = 'cache-and-network';
  const { data, loading, networkStatus, error } = useQuery(GET_ROLES, {
    variables,
    fetchPolicy,
  });
  const showLoader =
    loading && (!data || networkStatus === NetworkStatus.setVariables);
  const roles = get(data, 'administration.roles.data', []);
  const totalItems = get(data, 'administration.roles.total', 0);

  return [roles, totalItems, showLoader, error];
};
