import { gql, useLazyQuery } from '@apollo/client';
import { SavedSearch } from './useSavedSearch';

export const GET_SAVED_SEARCH_BY_ID = gql`
  query getSavedSearchById($id: ID) {
    administration {
      getSavedSearchById(id: $id) {
        filterUrl
        id
        name
        viewMode
        columnsConfig
        createdBy
        created
      }
    }
  }
`;

export type GetSavedSearchByIdRes = {
  administration: {
    getSavedSearchById: SavedSearch;
  };
};

export const useLazyQuerySavedSearchById = () => {
  const fetchPolicy = 'network-only';

  const [getSavedSearchByIdQuery, { data, loading }] = useLazyQuery<
    GetSavedSearchByIdRes,
    { id: string }
  >(GET_SAVED_SEARCH_BY_ID, {
    fetchPolicy,
  });

  const savedSearch = data?.administration?.getSavedSearchById;

  const getSavedSearchById = (id: string) => {
    return getSavedSearchByIdQuery({
      variables: {
        id,
      },
    });
  };

  return {
    savedSearch,
    getSavedSearchById,
    loadingSavedSearchById: loading,
  };
};
