import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';
import get from 'lodash/get';

import EntityTypeAvatar from '../../../../../../entityTypes/components/containers/EntityTypeAvatar';
import {
  RecordPanelHeaderDetailWrapper,
  RecordPanelHeaderDetailTitle,
  RecordPanelHeaderDetailTimeStamps,
  RecordPanelHeaderDetailTimeStamp,
  RecordPanelHeaderDetailSourceWrapper,
} from './RecordPanelHeaderDetail.styles';

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  color: ${(props) => props.color};
`;

const RecordPanelHeaderDetail = ({
  title,
  subtitle,
  detail,
  recordId,
  isCleanProject,
  isLegacyCleanProject,
  isDeduplicationProject,
  isManuallyAdded,
  isRecord,
  isManualMerge,
  isManuallyAddedFileEntry,
  source,
  provider,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const sourceId = get(source, 'id');

  let titleToShow = (
    <>
      <FormattedMessage id="module-entityTopology-splitPanelDetailRecordId" />
      :&nbsp;
      {recordId}
    </>
  );
  if (title) {
    titleToShow = title;
  }
  if (isLegacyCleanProject) {
    titleToShow = (
      <>
        <FormattedMessage id="module-entityTopology-splitPanelDetailRecordId" />
        :&nbsp;
        {recordId}
      </>
    );
  }
  if (isManualMerge) {
    titleToShow = (
      <FormattedMessage id="module-entityTopology-sourceManualMerge" />
    );
  }
  if (isManuallyAdded || isManuallyAddedFileEntry) {
    titleToShow = (
      <FormattedMessage id="module-entityTopology-sourceManuallyAdded" />
    );
  }

  return (
    <RecordPanelHeaderDetailWrapper>
      <RecordPanelHeaderDetailTitle themePrimary={themePrimary}>
        {titleToShow}
      </RecordPanelHeaderDetailTitle>

      {!isCleanProject && !isDeduplicationProject && provider?.name && (
        <RecordPanelHeaderDetailSourceWrapper>
          <FormattedMessage id="module-entityTopology-source" />
          :&nbsp;
          {provider?.providerName
            ? `${provider?.providerName} - ${provider?.name}`
            : provider?.name}
        </RecordPanelHeaderDetailSourceWrapper>
      )}

      {isCleanProject && (
        <div style={{ display: 'flex', paddingBottom: '8px' }}>
          <FormattedMessage id="module-entityTopology-cleanProject" />
          :&nbsp;
          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/preparation/new-clean-project/${sourceId}`}
            color={themePrimary}
          >
            {source && source?.name}
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </StyledLink>
        </div>
      )}

      {isDeduplicationProject && (
        <div style={{ display: 'flex', paddingBottom: '8px' }}>
          <FormattedMessage id="module-entityTopology-deduplicationProject" />
          :&nbsp;
          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/management/deduplication/detail/${sourceId}`}
            color={themePrimary}
          >
            {source?.name}
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </StyledLink>
        </div>
      )}

      {isManuallyAddedFileEntry && (
        <div style={{ display: 'flex', paddingBottom: '8px' }}>
          <FormattedMessage id="module-entityTopology-manualDataEntryProject" />
          :&nbsp;
          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/inbound/manual-data-entry/detail/${sourceId}`}
            color={themePrimary}
          >
            {source?.name}
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </StyledLink>
        </div>
      )}

      <div style={{ paddingBottom: 4 }}>
        <EntityTypeAvatar entityType={subtitle} />
      </div>

      {title && recordId && (
        <div>
          <FormattedMessage id="module-entityTopology-splitPanelDetailRecordId" />
          :&nbsp;
          {recordId}
        </div>
      )}

      {detail && (
        <RecordPanelHeaderDetailTimeStamps>
          {detail?.modifiedAt && (
            <RecordPanelHeaderDetailTimeStamp>
              <FormattedMessage id="module-entityTopology-splitPanelDetailModified" />
              :&nbsp;
              {moment(detail?.modifiedAt).fromNow()}
            </RecordPanelHeaderDetailTimeStamp>
          )}

          {detail?.createdAt && (
            <RecordPanelHeaderDetailTimeStamp>
              <FormattedMessage id="module-entityTopology-splitPanelDetailCreated" />
              :&nbsp;
              {moment(detail?.createdAt).fromNow()}
            </RecordPanelHeaderDetailTimeStamp>
          )}

          {detail?.discoveredAt && (
            <RecordPanelHeaderDetailTimeStamp>
              <FormattedMessage id="module-entityTopology-splitPanelDetailDiscovered" />
              :&nbsp;
              {moment(detail?.discoveredAt).fromNow()}
            </RecordPanelHeaderDetailTimeStamp>
          )}
        </RecordPanelHeaderDetailTimeStamps>
      )}
    </RecordPanelHeaderDetailWrapper>
  );
};

export default RecordPanelHeaderDetail;
