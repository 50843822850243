"use strict";

exports.__esModule = true;
exports.preparation = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var PREPARATION_PREPARE_CONSULTANT = _claimTypes.default.PREPARATION_PREPARE_CONSULTANT,
  PREPARATION_PREPARE_INFORMED = _claimTypes.default.PREPARATION_PREPARE_INFORMED,
  PREPARATION_MESHCENTER_INFORMED = _claimTypes.default.PREPARATION_MESHCENTER_INFORMED,
  PREPARATION_CLEAN_CONSULTANT = _claimTypes.default.PREPARATION_CLEAN_CONSULTANT,
  PREPARATION_CLEAN_INFORMED = _claimTypes.default.PREPARATION_CLEAN_INFORMED;
var preparation = {
  clean: {
    project: {
      create: {
        claims: PREPARATION_CLEAN_CONSULTANT
      },
      delete: {
        claims: PREPARATION_CLEAN_CONSULTANT
      },
      edit: {
        claims: PREPARATION_CLEAN_CONSULTANT
      },
      view: {
        claims: PREPARATION_CLEAN_INFORMED
      }
    }
  },
  prepare: {
    project: {
      create: {
        claims: PREPARATION_PREPARE_CONSULTANT
      },
      delete: {
        claims: PREPARATION_PREPARE_CONSULTANT
      },
      edit: {
        claims: PREPARATION_PREPARE_CONSULTANT
      },
      view: {
        claims: PREPARATION_PREPARE_INFORMED
      }
    }
  },
  meshcenter: {
    project: {
      view: {
        claims: PREPARATION_MESHCENTER_INFORMED
      }
    }
  }
};
exports.preparation = preparation;