import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import IntegrationIconContainer from '../../modules/integration/components/containers/IntegrationIconContainer';
import { isDataSet } from '../../modules/dataSourceV2/helper';
import DataSetIconContainer from '../../modules/dataSourceV2/components/composites/DataSetIconContainer';

const LinkWithOpacity = styled.a`
  display: block;
  margin-right: 5px;
`;

export const ImgWithOpacity = styled.div`
  display: block;
  opacity: 0.4;
  transition: ${({ theme: { transition } }) =>
    transition && transition.defaultAll};
  &:hover {
    opacity: 1;
    transition: ${({ theme: { transition } }) =>
      transition && transition.defaultAll};
  }
`;

const ProviderIconLink = ({ providerName, uri, roundImage }) => {
  if (!providerName) {
    return null;
  }

  if (providerName.indexOf('CluedIn(') === -1) {
    console.warn(`Does not have ${providerName}`);
  }

  if (isDataSet(providerName)) {
    return (
      <DataSetIconContainer
        providerName={providerName}
        customSize="24"
        customFontSize="14"
      />
    );
  }

  if (uri) {
    return (
      <div style={{ display: 'inline-block' }}>
        <Tooltip
          overlay={<span>View in {providerName}</span>}
          placement="bottom"
        >
          <LinkWithOpacity href={uri} target="__blank">
            <ImgWithOpacity>
              <IntegrationIconContainer
                iconSize="s"
                integrationName={providerName}
                roundImage={roundImage}
              />
            </ImgWithOpacity>
          </LinkWithOpacity>
        </Tooltip>
      </div>
    );
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Tooltip
        overlay={<span>Found in {providerName}</span>}
        placement="bottom"
      >
        <ImgWithOpacity>
          <IntegrationIconContainer
            iconSize="s"
            integrationName={providerName}
          />
        </ImgWithOpacity>
      </Tooltip>
    </div>
  );
};

ProviderIconLink.displayName = 'ProviderIconLink';

ProviderIconLink.propTypes = {
  providerName: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

export default ProviderIconLink;
