import { FormattedMessage } from '@cluedin/locale';
import { actionsConfiguration, RACI_LEVELS } from '@cluedin/components';

import routes, { getLink } from './routes';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  name: 'feature-flag',
  displayName: <FormattedMessage id="feature-flag" />,
  path: '/feature-flag',
  claims: {
    'admin.tenantmanagement': RACI_LEVELS.INFORMED,
  },
  extendPillarDashboard: {
    actions: [
      {
        icon: 'list',
        name: <FormattedMessage id="feature-flag" />,
        link: getLink.home(),
        claims: admin.tenantmanagement.tenant.view,
      },
    ],
  },
  routes,
};
