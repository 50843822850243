import React from 'react';
import styled from 'styled-components';
import { HighlightTextFromContext } from '@cluedin/components';

const VocabKeyItemColumnLabel = styled.div`
  font-size: 12px;
  color: #9b9b9c;
`;

const VocabularyFullKey = ({ vocabKey }) => {
  return (
    <VocabKeyItemColumnLabel>
      {vocabKey && <HighlightTextFromContext text={vocabKey} />}
    </VocabKeyItemColumnLabel>
  );
};

export default VocabularyFullKey;
