import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

const styles = {
  noDataMessage: {
    padding: '30px',
    textAlign: 'center',
  },
  loaderPadding: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
/*
const formatName = (et) => {
  const parts = et.split(/\//gm);
  parts.shift();
  const first = parts.pop();
  return first + (parts.length ? ` (${parts.join()})` : '');
};
*/
const ConfigurationStats = (/* { dataStats, backgroundColor } */) => {
  /*
  const sorted = dataStats.sort((a, b) => a.ExpectedCount - b.ExpectedCount);
  const thresItem = sorted[Math.floor(dataStats.length * 0.5)] || {};
  const thres = thresItem.ExpectedCount || 0;
  let others = 0;
  const data = dataStats.reduce((m, s) => {
    if (s.ExpectedCount < thres) others += s.ExpectedCount;
    else m.push([formatName(s.EntityType), s.ExpectedCount]);
    return m;
  }, [['Type', 'Count']]);

  data.push(['Others', others]);

  const total = data.reduce((m, v) => (
    v.length >= 2 ?
      (
        m + (typeof v[1] === 'number' ? v[1] : 0)
      ) : m
  ), 0);

  const options = {
    chartArea: { top: 5, bottom: 5, left: 10, right: 5 },
    legend: { position: 'labeled' },
    width: '100%',
    pieSliceText: 'value',
    pieHole: 0.3,
    backgroundColor: backgroundColor || '#fff',
  };
  */
  return (
    <div>
      <div style={styles.noDataMessage}>
        <h4>
          <FormattedMessage id="module-configuration-noChartData" />
        </h4>
      </div>
    </div>
  );
};

export default ConfigurationStats;
