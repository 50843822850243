import React from 'react';
import { withWorkflow } from '@cluedin/form';

import GroupedEntitiesPanel from '../containers/GroupedEntitiesPanel/GroupedEntitiesPanel';

const GroupedEntitiesPanelWrapper =
  (Comp) =>
  ({ openWorkflow, ...props }) =>
    <Comp openGroupedEntitiesPanelWorkflow={openWorkflow} {...props} />;

export const withGroupedEntitiesPanelWorkflow = (Comp) =>
  withWorkflow(GroupedEntitiesPanelWrapper(Comp), GroupedEntitiesPanel, {
    withCustomHeader: true,
    panelType: 'custom',
    width: 1300,
  });
