import { useState, useMemo, useCallback } from 'react';

import { useVocabularyKeys } from '../../dataCatalog/hooks/useVocabularyKeys';

export const usePaginatedVocabularyKeys = ({
  skip = false,
  pageNumber = 1,
  defaultPageSize = 10,
  initialSearchName = '',
  loadMoreLabel = 'Load more',
  useQueryVocabularyKeys = useVocabularyKeys,
}) => {
  const [searchVocabularyKeys, setSearchVocabularyKeys] =
    useState(initialSearchName);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [vocabularyKeys, isLoadingVocabularyKeys, errorLoadingVocabularyKeys] =
    useQueryVocabularyKeys(
      {
        pageNumber,
        pageSize: pageSize,
        searchName: searchVocabularyKeys,
      },
      false,
      skip,
    );

  const onLoadMore = useCallback(
    () => setPageSize((p) => p + defaultPageSize),
    [defaultPageSize],
  );

  const totalVocabularyKeys = useMemo(
    () => vocabularyKeys?.total,
    [vocabularyKeys?.total],
  );

  const loadMore = useMemo(() => {
    if (totalVocabularyKeys <= pageSize) return;

    return {
      onClick: onLoadMore,
      label: loadMoreLabel,
    };
  }, [totalVocabularyKeys, pageSize, onLoadMore]);

  return {
    loadMore,
    totalVocabularyKeys,
    isLoadingVocabularyKeys,
    setSearchVocabularyKeys,
    errorLoadingVocabularyKeys,
    vocabularyKeys: vocabularyKeys?.data,
    totalVocabularyKeys: vocabularyKeys?.total,
  };
};
