import styled from 'styled-components';

export const EntityListValueWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EntityListRawValue = styled.div`
  color: #9b9b9c;
`;
