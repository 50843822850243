import { createContext, ReactNode } from 'react';

import { useApp } from './useApp';

export type UseAppReturnType = ReturnType<typeof useApp>;

export const AppContext = createContext<UseAppReturnType | null>(null);
AppContext.displayName = 'AppContext';

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useApp();

  if (value.loadingMe) {
    return null;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
