import { connect } from 'react-redux';
import EntityTypeAvatar from '../composites/EntityTypeAvatar';

const mapDispatchToProps = (
  { wms: { entityConfigurations } },
  { entityType },
) => {
  const entityConfig = (entityConfigurations || []).find(
    (e) => e.entityType === entityType,
  );
  return {
    entityType,
    entityConfig,
  };
};

export default connect(mapDispatchToProps)(EntityTypeAvatar);
