import React from 'react';
import RoleDetailLink from '../../composites/RoleDetailLink';
import { FormattedMessage } from '@cluedin/locale';

const Count = ({ userCount }) => <span>{userCount}</span>;
export const columns = [
  {
    property: 'name',
    displayName: <FormattedMessage id="module-role-roleName" />,
    Component: RoleDetailLink,
  },
  {
    property: 'userCount',
    displayName: <FormattedMessage id="module-role-userAssigned" />,
    Component: Count,
  },
];
