import React from 'react';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent } from 'uxi/Panel';
import styled from 'styled-components';

const Text = styled.div`
  margin: 30px 0;
`;

const AnonymousDialog = ({ show, title, onClose, dpoEmail, dpoPhone }) => (
  <Dialog show={show} onClose={onClose}>
    <Panel onClose={onClose} style={{ maxHeight: '80vh' }}>
      <PanelHeader onClose={onClose} title={title} hasClose />
      <PanelContent style={{ padding: '30px' }}>
        <Text>
          You are visiting this website as an&nbsp;
          <strong>anonymous user</strong>.
        </Text>
        <Text>
          We have no personal identifier at our disposal to automatically
          full-fill the request.
        </Text>
        <Text>
          If you want to process online with this request, you need to sign-in
          into the service.
        </Text>
        <Text>
          If you want to request this offline, please contact our Data
          Protection Officer.
        </Text>
        <Text>
          Phone&nbsp;
          <a href={`tel:${dpoPhone && dpoPhone.replace(/\s/g, '')}`}>
            {dpoPhone}
          </a>
          <br />
          Email&nbsp;<a href={`mailto:${dpoEmail}`}>{dpoEmail}</a>
        </Text>
      </PanelContent>
    </Panel>
  </Dialog>
);

export default AnonymousDialog;
