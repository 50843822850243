import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { flexCSSString, Flex } from 'uxi/Layout';
import { TextEllipsis } from 'uxi/Text';
import { Phone, Emailthread } from 'uxi/Icons';
import { socialIconSize, logosize, color } from '../../theme';

const PersonListItemWrapperUI = styled.div`
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #cecece;
  border-radius: 3px;
  color: ${color};
  height: 100%;
`;

const PersonListItemHeader = styled.div`
  height: ${logosize}px;
  margin-bottom: ${logosize / 2 + 8}px;
  border-bottom: 1px solid #cecece;
  background: #f1f1f1;
  background: rgba(210, 210, 210, 0.5);
  & > * {
    transform: translateY(50%);
    border-radius: 50%;
  }
`;

const PersonListItemDetailWrapper = styled.div`
  margin: 16px;
`;

const PersonListItemSocialWrapper = styled.div`
  ${flexCSSString};
  svg {
    * {
      fill: ${({ theme: { palette } }) => palette.accent.main};
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
    &:hover * {
      fill: ${({ theme: { palette } }) => palette.accent.dark};
      transition: ${({ theme: { transition } }) => transition.defaultAll};
    }
  }
`;

const PersonListItemContactWrapper = styled.div`
  text-align: left;
  margin: 16px 16px 0 16px;
  display: flex;
  justify-content: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    border-top: 1px solid #cecece;
    left: 16px;
    right: 16px;
  }

  & svg * {
    fill: ${({ theme: { palette } }) => palette.accent.main};
  }
`;

const PersonListItemLayout = ({
  entityId,
  name,
  jobTitle,
  email,
  phoneNumber,
  logo,
  socialMenu,
  showEntityQuickView,
}) => (
  <PersonListItemWrapperUI className="test_searchResult_person">
    <PersonListItemHeader>{logo}</PersonListItemHeader>
    <PersonListItemDetailWrapper>
      <Link
        to={`/person/${entityId}`}
        onClick={(e) => {
          if (showEntityQuickView) {
            const { ctrlKey, metaKey } = e;

            if (ctrlKey || metaKey) {
              // noop, let native 'open in a new tab'
              // behaviour take place
            } else {
              e.preventDefault();
              showEntityQuickView({
                entityId,
                entityType: '/Person',
              });
            }
          }
        }}
      >
        <TextEllipsis>{name || 'no name'}</TextEllipsis>
      </Link>
      <TextEllipsis>{jobTitle || '–'}</TextEllipsis>
    </PersonListItemDetailWrapper>
    <PersonListItemContactWrapper>
      <div style={{ padding: '16px', maxWidth: '100%', overflow: 'hidden' }}>
        {email ? (
          <Flex style={{ justifyContent: 'flex-start' }}>
            <Emailthread size={socialIconSize} style={{ marginRight: '8px' }} />
            {email || '–'}
          </Flex>
        ) : (
          <span>&nbsp;</span>
        )}
        {phoneNumber ? (
          <Flex style={{ justifyContent: 'flex-start' }}>
            <Phone size={socialIconSize} style={{ marginRight: '8px' }} />
            <TextEllipsis>{phoneNumber || '–'}</TextEllipsis>
          </Flex>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </PersonListItemContactWrapper>
    <PersonListItemSocialWrapper>{socialMenu}</PersonListItemSocialWrapper>
  </PersonListItemWrapperUI>
);

PersonListItemLayout.displayName = 'PersonListItemLayout';

export default PersonListItemLayout;
