import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

const IntegrationHeaderStyle = {
  container: {
    display: 'flex',
    padding: '15px',
    background: '#fff',
    borderBottom: '1px solid #dee5e7',
    borderTop: '1px solid #dee5e7',
    height: '100px',
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  logoContainer: {
    width: '64px',
  },
  logo: {
    width: '100%',
  },
  descriptionContainer: {
    flex: 1,
    paddingLeft: '15px',
  },
  title: {
    margin: '0px 0px 5px 0px',
    padding: 0,
    fontSize: '24px',
    fontWeight: '500',
  },
  details: {
    marginTop: '10px',
  },
};

const ConfigurationHeaderTitle = ({ configuration }) => (
  <div style={{ fontSize: '14px' }}>
    <span>
      {configuration
        ? configuration.AccountDisplay || configuration.AccountId
        : null}
    </span>
    <FormattedMessage id="module-integration-addedBy" />
    {configuration ? configuration.UserName : ''}
  </div>
);

const IntegrationHeader = ({ integration, configuration }) => (
  <div style={IntegrationHeaderStyle.container}>
    <div style={IntegrationHeaderStyle.logoContainer}>
      <img
        style={IntegrationHeaderStyle.logo}
        alt={integration.Name}
        src={integration.Icon}
      />
    </div>
    <div style={IntegrationHeaderStyle.descriptionContainer}>
      <h3 style={IntegrationHeaderStyle.title}>{integration.Name}</h3>
      {configuration ? (
        <ConfigurationHeaderTitle configuration={configuration} />
      ) : (
        <span>{integration.Details}</span>
      )}
    </div>
  </div>
);

export default IntegrationHeader;
