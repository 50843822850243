import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import ConfigurationRepartitionCondensedListContainer from '../../../../configuration/components/containers/lists/ConfigurationRepartitionCondensedListContainer';

const IntegrationRepartionsContainer = () => (
  <div
    style={{
      background: '#fff',
      border: '1px solid #ccc',
      position: 'relative',
    }}
  >
    <div
      style={{
        padding: '15px',
        textAlign: 'center',
        background: '#fff',
        left: 0,
        right: 0,
        position: 'absolute',
        borderBottom: '1px solid #ccc',
        fontSize: '20px',
      }}
    >
      <FormattedMessage id="module-integration-appStats" />
    </div>
    <div style={{ marginTop: '75px' }}>
      <div style={{ height: '355px', overflowY: 'auto', position: 'relative' }}>
        <ConfigurationRepartitionCondensedListContainer />
      </div>
    </div>
  </div>
);

export default IntegrationRepartionsContainer;
