import { createForms } from 'react-redux-form';

import { reducer as formReducer } from 'redux-form';
import { reducer as userMessage } from 'uxi-business/userMessage';

import reducers, { initialState } from './reducer';
import { register, compileStore } from './modules/wms/storeRegistry';

const widgetReducers = {
  ...reducers,
  form: formReducer,
  userMessage,
  ...createForms({
    changePassword: {},
    propsDelta: {},
  }),
};

export const createStore = (history) => {
  register('widget', widgetReducers);

  const store = compileStore(initialState, history);

  return store;
};

export default {
  createStore,
};
