import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GqlErrorMessages } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';

import PageLoader from '../../../../core/components/composites/PageLoader';
import EntityTopologySplitNewDataPart from '../EntityTopologySplitNewDataPart/EntityTopologySplitNewDataPart';
import {
  EntityTopologySplitPreviewDataPartsWrapper,
  EntityTopologySplitPreviewDataPartsBox,
  EntityTopologySplitPreviewDataPartsInnerWrapper,
  EntityTopologySplitPreviewDataPartsHeader,
  EntityTopologySplitPreviewDataPartsInformation,
  EntityTopologySplitPreviewDataPartsTitle,
  ExplainLogIdentifierSourceWrapper,
} from './EntityTopologySplitPreviewDataParts.styles';

const EntityTopologySplitPreviewDataParts = ({
  data,
  loading,
  error,
  title,
  entityId,
  total,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);

  if (error) {
    return <GqlErrorMessages error={error} />;
  }
  if (loading) {
    return <PageLoader />;
  }

  return (
    <EntityTopologySplitPreviewDataPartsWrapper theme={theme}>
      <EntityTopologySplitPreviewDataPartsBox isExpanded={isExpanded}>
        <EntityTopologySplitPreviewDataPartsInnerWrapper
          isExpanded={isExpanded}
          theme={theme}
        >
          <EntityTopologySplitPreviewDataPartsHeader
            data-test="entity-entityTopology-splitPreviewDataPartsHeader"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <Arrowdown color={themePrimary} size={16} />
            ) : (
              <Arrowright color={themePrimary} size={16} />
            )}
            <EntityTopologySplitPreviewDataPartsInformation>
              <EntityTopologySplitPreviewDataPartsTitle>
                {title}
              </EntityTopologySplitPreviewDataPartsTitle>
              {total &&
                (total === 1 ? (
                  <FormattedMessage
                    id="module-entityTopology-totalRecord"
                    values={{ total }}
                  />
                ) : (
                  <FormattedMessage
                    id="module-entityTopology-totalRecords"
                    values={{ total }}
                  />
                ))}
            </EntityTopologySplitPreviewDataPartsInformation>
          </EntityTopologySplitPreviewDataPartsHeader>
        </EntityTopologySplitPreviewDataPartsInnerWrapper>

        <ExplainLogIdentifierSourceWrapper isExpanded={isExpanded}>
          {data &&
            (data || []).map((newPart) => (
              <EntityTopologySplitNewDataPart
                key={`${newPart?.dataPartId}-${newPart?.name}`}
                data={newPart}
                entityId={entityId}
              />
            ))}
        </ExplainLogIdentifierSourceWrapper>
      </EntityTopologySplitPreviewDataPartsBox>
    </EntityTopologySplitPreviewDataPartsWrapper>
  );
};

export default EntityTopologySplitPreviewDataParts;

EntityTopologySplitPreviewDataParts.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  title: PropTypes.object,
  entityId: PropTypes.string,
  total: PropTypes.number,
};

EntityTopologySplitPreviewDataParts.defaultProps = {
  data: [],
  loading: null,
  error: null,
  title: null,
  entityId: null,
  total: null,
};
