import React from 'react';
import Section from './Section';

const Consents = ({ jsonReport }) => {
  const { Consents: ConsentsProp } = jsonReport;

  return (
    <Section jsonReport={jsonReport}>
      <h2>Consents:</h2>
      {ConsentsProp && ConsentsProp.length > 0 && ConsentsProp.map(() => null)}
    </Section>
  );
};

export default Consents;
