import { gql } from '@apollo/client';

export const getEntityMetricsData = gql`
  query getEntityMetricsByNames($entityId: Guid!) {
    entity(id: $entityId) {
      id
      name

      metrics(category: "dataQuality") {
        id
        name
        dataType

        # Entity Level Dimension
        entityDimension {
          id
          latestValue
          values
        }

        # Entity Level, Integration Type Dimensions
        integrationTypeDimensions {
          id
          type
          providerId
          providerName
          latestValue
          values
        }
      }
    }
  }
`;

export default {
  getEntityMetricsData,
};
