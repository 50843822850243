import { useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
import Alert from 'uxi/Alert';
import { useTheme } from '@cluedin/theme';
import { FormattedHTMLMessage } from '@cluedin/locale';

import {
  EdgePropertiesPanelEdgeGroupEntryWrapper,
  EdgePropertiesPanelEdgeGroupEntryInnerWrapper,
  EdgePropertiesPanelEdgeGroupEntryHeader,
  EdgePropertiesPanelEdgeGroupEdgeWrapperNoData,
} from './EdgePropertiesPanelEdgeGroupEntry.styles';

const EdgePropertiesPanelEdgeGroupEntryWithoutData = ({ edgeType }) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <EdgePropertiesPanelEdgeGroupEntryWrapper>
      <EdgePropertiesPanelEdgeGroupEntryInnerWrapper
        isExpanded={isExpanded}
        theme={theme}
        data-test="entity-entityRelations-edgePropertiesPanelEdgeGroupEntry"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <EdgePropertiesPanelEdgeGroupEntryHeader>
          <ExpandingArrow
            expanded={isExpanded}
            data-test="entity-entityRelations-expandingArrow"
          />
          {edgeType}
        </EdgePropertiesPanelEdgeGroupEntryHeader>
      </EdgePropertiesPanelEdgeGroupEntryInnerWrapper>

      <EdgePropertiesPanelEdgeGroupEdgeWrapperNoData isExpanded={isExpanded}>
        <Alert type="information">
          <FormattedHTMLMessage
            id="module-entityRelationsGraph-edgePropertiesEntryNoData"
            values={{ edgeType }}
          />
        </Alert>
      </EdgePropertiesPanelEdgeGroupEdgeWrapperNoData>
    </EdgePropertiesPanelEdgeGroupEntryWrapper>
  );
};

export default EdgePropertiesPanelEdgeGroupEntryWithoutData;
