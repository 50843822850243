import React from 'react';
import { Button } from '@cluedin/form';
import { DivPadding } from 'uxi/Base';

const IntegrationFiltersLoading = () => (
  <DivPadding
    padding="S"
    style={{ display: 'flex', alignItems: 'flex-start', flexFlow: 'row wrap' }}
  >
    <div style={{ flex: 1, display: 'flex', flexFlow: 'row wrap' }}>
      <div style={{ margin: '8px' }}>
        <Button text={<div style={{ width: '200px' }} />} />
      </div>
      <div style={{ margin: '8px' }}>
        <Button text={<div style={{ width: '200px' }} />} />
      </div>
    </div>
    <div>
      <div style={{ margin: '8px' }}>
        <Button text={<div style={{ width: '200px' }} />} />
      </div>
    </div>
  </DivPadding>
);

export default IntegrationFiltersLoading;
