import moment from 'moment';
import { extractSocial } from './entityProperty/social';
import { findFromKeyValue } from '../wms/helpers/entityPropertyHelper';
import { addTokenToImage } from './helper';
import { getCurrentToken } from '../../config';

export const toVM = (item, small, tokenFromProp) => {
  let mainItem;
  let token = tokenFromProp ? tokenFromProp : getCurrentToken();

  // eslint-disable-next-line no-nested-ternary
  mainItem = small // eslint-disable-line prefer-const
    ? item.entity
    : item.Entity
    ? item.Entity.entity
    : item.entity.entity;

  mainItem.data = mainItem.processedData || {};
  mainItem.EntityAccountData = item.EntityAccountData;
  mainItem.Score = item.Score;
  mainItem.id = mainItem['attribute-id'];
  mainItem.name =
    mainItem.data.displayName || mainItem.data.name || mainItem.id;
  mainItem.isViewModel = true;

  delete mainItem['attribute-id'];
  delete mainItem['attribute-organization'];
  delete mainItem['attribute-culture'];
  delete mainItem.processedData;

  const hasDescription = !!mainItem.data.description;
  const differentNameAndDescription = !(
    mainItem.data.description === mainItem.name
  );

  mainItem.showDescription = differentNameAndDescription && hasDescription;

  mainItem.providers = mainItem.data['provider-origins'];

  if (mainItem.showDescription && mainItem.data.description.length > 200) {
    mainItem.descriptionFormatted = `${mainItem.data.description.substring(
      0,
      200,
    )}...`;
  }

  if (mainItem.data.previewImage) {
    if (mainItem.data.previewImage['attribute-uri']) {
      mainItem.hasLogo = true;
      mainItem.logoUrl = addTokenToImage(
        mainItem.data.previewImage['attribute-uri'],
        token,
        true,
      );
      mainItem.largeLogoUrl = addTokenToImage(
        mainItem.data.previewImage['attribute-uri'],
        token,
        false,
        'medium',
      );
    }
  }

  if (mainItem.data.previewImage) {
    if (mainItem.data.previewImage['attribute-uri']) {
      mainItem.previewUrl = addTokenToImage(
        mainItem.data.previewImage['attribute-uri'],
        token,
      );
      mainItem.hasPreview = true;
    }
  }

  if (mainItem.name && mainItem.name.length > 100) {
    mainItem.name = `${mainItem.name.substring(0, 100)}...`;
  }

  mainItem.NumberOfFollowers = item.NumberOfFollowers || 0;

  mainItem.path = undefined;
  mainItem.SuggestedSearches = item.SuggestedSearches;

  if (mainItem.data) {
    if (mainItem.data.properties) {
      mainItem.social = extractSocial(mainItem.data.properties);
      mainItem.embedUrl =
        mainItem.data.properties['property-file.embedUrl'] || '';
      mainItem.editUrl =
        mainItem.data.properties['property-file.editUrl'] || '';
    }

    mainItem.data.uri = mainItem.data.uri
      ? mainItem.data.uri.replace(/\+/g, '%20')
      : '';
    mainItem.data.lastChangedBy =
      mainItem.data.lastChangedBy ||
      findFromKeyValue(mainItem.data.properties, 'lastmodifiedby');
    mainItem.data.modifiedDateFormatted = mainItem.data.modifiedDate
      ? moment(mainItem.data.modifiedDate).fromNow()
      : undefined;

    mainItem.data.createdDateFormatted = mainItem.data.createdDate
      ? moment(mainItem.data.createdDate).fromNow()
      : undefined;

    mainItem.data.discoveryDateFormatted = mainItem.data.discoveryDate
      ? moment(mainItem.data.discoveryDate).fromNow()
      : undefined;
  }

  return mainItem;
};

export const fromServertoGQLVm = (item, tokenFromProp) => {
  let mainItem;
  let token = tokenFromProp ? tokenFromProp : getCurrentToken();

  // eslint-disable-next-line no-nested-ternary
  mainItem = item.Entity ? item.Entity.entity : item.entity.entity;

  mainItem.properties = mainItem?.processedData?.properties || {};
  mainItem.EntityAccountData = item.EntityAccountData;
  mainItem.Score = item.Score;
  mainItem.id = mainItem['attribute-id'];
  mainItem.name =
    mainItem.processedData.displayName ||
    mainItem.processedData.name ||
    mainItem.id;
  mainItem.entityType = mainItem.processedData.entityType;
  const hasDescription = !!mainItem.processedData.description;
  const differentNameAndDescription = !(
    mainItem.processedData.description === mainItem.name
  );

  mainItem.showDescription = differentNameAndDescription && hasDescription;
  mainItem.providers = mainItem.processedData['provider-origins'];

  if (
    mainItem.showDescription &&
    mainItem.processedData.description.length > 200
  ) {
    mainItem.descriptionFormatted = `${mainItem.processedData.description.substring(
      0,
      200,
    )}...`;
  }

  if (mainItem.processedData.previewImage) {
    if (mainItem.processedData.previewImage['attribute-uri']) {
      mainItem.hasLogo = true;
      mainItem.logoUrl = addTokenToImage(
        mainItem.processedData.previewImage['attribute-uri'],
        token,
        true,
      );
      mainItem.largeLogoUrl = addTokenToImage(
        mainItem.processedData.previewImage['attribute-uri'],
        token,
        false,
        'medium',
      );
    }
  }

  if (mainItem.processedData.previewImage) {
    if (mainItem.processedData.previewImage['attribute-uri']) {
      mainItem.previewUrl = addTokenToImage(
        mainItem.processedData.previewImage['attribute-uri'],
        token,
      );
      mainItem.hasPreview = true;
    }
  }

  if (mainItem.name && mainItem.name.length > 100) {
    mainItem.name = `${mainItem.name.substring(0, 100)}...`;
  }

  mainItem.NumberOfFollowers = item.NumberOfFollowers || 0;

  mainItem.path = undefined;
  mainItem.SuggestedSearches = item.SuggestedSearches;

  if (mainItem.processedData) {
    if (mainItem.properties) {
      mainItem.social = extractSocial(mainItem.processedData.properties);
      mainItem.embedUrl =
        mainItem.processedData.properties['property-file.embedUrl'] || '';
      mainItem.editUrl =
        mainItem.processedData.properties['property-file.editUrl'] || '';
    }

    mainItem.uri = mainItem.processedData.uri
      ? mainItem.processedData.uri.replace(/\+/g, '%20')
      : '';
    mainItem.lastChangedBy =
      mainItem.processedData.lastChangedBy ||
      findFromKeyValue(mainItem.processedData.properties, 'lastmodifiedby');
    mainItem.modifiedDate = mainItem.processedData.modifiedDate;
    mainItem.createdDate = mainItem.processedData.createdDate;
    mainItem.discoveryDate = mainItem.processedData.discoveryDate;
  }

  delete mainItem['attribute-id'];
  delete mainItem['attribute-organization'];
  delete mainItem['attribute-culture'];
  delete mainItem.processedData;

  return mainItem;
};

export default {
  toVM,
  fromServertoGQLVm,
};
