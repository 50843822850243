import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'uxi/Button/FlatButton';

export const PageRight = ({ currentPage, totalPages, onPageChange }) => (
  <FlatButton
    message=">"
    style={{ marginLeft: '4px' }}
    disabled={currentPage === totalPages}
    onClick={() => onPageChange(currentPage + 1)}
  />
);

PageRight.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
