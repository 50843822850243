"use strict";

exports.__esModule = true;
exports.default = void 0;
var _theme = require("@cluedin/theme");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(AutoCompletePopper) {
  return (0, _styledComponents.default)(AutoCompletePopper)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  .popper {\n    position: absolute;\n    z-index: ", ";\n  }\n  .option {\n    min-height: 48px;\n    display: flex;\n    overflow: hidden;\n    justify-content: flex-start;\n    align-items: center;\n    cursor: pointer;\n    padding-top: 6px;\n    box-sizing: border-box;\n    outline: 0;\n    -webkit-tap-highlight-color: transparent;\n    padding-bottom: 6px;\n    padding-left: 16px;\n    padding-right: 16px;\n    min-width: 300px;\n    width: 100%;\n    &[data-focus='true'] {\n      background-color: ", ";\n      @media (hover: none) {\n        background-color: transparent;\n      }\n    }\n    &[aria-disabled='true'] {\n      opacity: ", ";\n      pointer-events: none;\n    }\n    &.aero-focusVisible {\n      background-color: ", ";\n    }\n    &[aria-selected='true'] {\n      background-color: ", ";\n    }\n  }\n  .listbox {\n    list-style: none;\n    margin: 0;\n    padding: 8px 0;\n    max-height: 40vh;\n    overflow: auto;\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.zIndex.modal;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.action.hover;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.action.disabledOpacity;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.action.focus;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return _theme.colorManipulator.alpha(theme.colors.primary.main, theme.colors.action.selectedOpacity);
  });
};
/**
 * &.aero-focusVisible {
      background-color: ${({ theme }) => alpha(
        theme.colors.primary.main,
        theme.colors.action.selectedOpacity + theme.colors.action.focusOpacity,
      )};
    }

     &[aria-selected="true"][data-focus="true"] {
      background-color: ${({theme}) => alpha(
        theme.colors.primary.main,
        theme.colors.action.selectedOpacity + theme.colors.action.hoverOpacity,
      )};
      @media (hover: none) {
        background-color: ${({theme}) => theme.colors.action.selected};
      }
    }
 */
exports.default = _default;