export default {
  header: {
    display: 'flex',
    padding: '16px',
  },
  headerViewAll: {
    flex: 1,
    textAlign: 'right',
    padding: '16px',
  },
  content: {
    marginTop: '16px',
    maxWidth: '960px',
    margin: '16px auto',
    padding: '16px',
  },
  searchQueryWrapper: {
    marginTop: '15px',
    minHeight: '50px',
    display: 'flex',
    padding: '0 16px',
  },
  searchQueryContent: {
    fontSize: '18px',
  },
  titleWrapper: {
    textAlign: 'right',
    marginBottom: '5px',
    padding: '0 16px',
  },
  titleContent: {
    margin: 0,
    padding: 0,
  },
  entityWrapper: {
    marginTop: '15px',
    minHeight: '50px',
    display: 'flex',
  },
  entityType: {
    paddingTop: '5px',
    marginRight: '10px',
  },
  entityLink: {
    fontSize: '18px',
  },
};
