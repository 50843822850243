import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

const MenuItemWrapper = styled.div`
  a.crumbText * {
    color: ${({ themePrimary }) => themePrimary};
  }
`;

const BreadCrumbDropdownMenuItem = ({ children, ...attrs }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <MenuItemWrapper {...attrs} themePrimary={themePrimary}>
      {children}
    </MenuItemWrapper>
  );
};

export default BreadCrumbDropdownMenuItem;
