import React, { Component } from 'react';
import { connect } from 'react-redux';
import SavedSearchList from '../../composites/SaveSearchList';
import { injectIntl } from '@cluedin/locale';
import ViewEntitySearchChanges from '../../composites/ViewEntitySearchChanges';
import {
  shouldResetSearchCount,
  shouldUnsubsribeForSearch,
} from '../../../actions';
import {
  closeNotificationCenter, // this is actually a toggle...
} from '../../../../notifications/actions';
import KeepInTheLoopEmptyState from '../../composites/KeepInTheLoopEmptyState';
import SearchBar from '../../composites/SearchBar';

const KeepInTheLoopContentStyle = {
  filterWrapper: {
    display: 'flex',
    padding: '15px',
    borderBottom: '1px solid #ccc',
  },
};

class KeepInTheLoopContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      viewType: '',
      selectedSearch: null,
    };
    this.onRemoveSearchClickHander = this.onRemoveSearchClickHander.bind(this);
    this.viewFullSearchHandler = this.viewFullSearchHandler.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.viewChanges = this.viewChanges.bind(this);
    this.onBackHandler = this.onBackHandler.bind(this);
  }

  onRemoveSearchClickHander(e) {
    const { unSubscribeSearch } = this.props;

    unSubscribeSearch(e.Query);
  }

  onBackHandler() {
    this.setState({
      viewType: '',
      selectedSearch: '',
    });
  }

  handleSearchChange(value) {
    this.setState({
      searchValue: value,
    });
  }

  viewChanges(search) {
    this.setState({
      selectedSearch: search,
      viewType: 'detail',
    });
  }

  viewFullSearchHandler(search) {
    const { close, viewFullSearch } = this.props;

    if (close) {
      close();
    }

    viewFullSearch(search);
  }

  render() {
    const { searches, intl } = this.props;
    const { searchValue, viewType, selectedSearch } = this.state;

    let filteredSearched = searches;
    let content;

    if (viewType === 'detail' && selectedSearch) {
      content = (
        <div>
          <ViewEntitySearchChanges
            onBack={this.onBackHandler}
            search={selectedSearch}
            onViewFullSearch={this.viewFullSearchHandler}
          />
        </div>
      );
    } else {
      if (searchValue) {
        filteredSearched = (filteredSearched || {}).filter((i) =>
          i.Name.match(new RegExp(searchValue, 'i')),
        );
      }

      if (searches.length === 0) {
        content = <KeepInTheLoopEmptyState />;
      } else {
        content = (
          <div>
            <div
              className="test_NotificationCenter_SavedSearch_Search"
              style={KeepInTheLoopContentStyle.filterWrapper}
            >
              <SearchBar
                onInputChange={this.handleSearchChange}
                placeholder={intl.formatMessage({
                  id: 'module-stream-lookForSavedSearch',
                })}
              />
            </div>
            <div>
              <SavedSearchList
                onSearchClick={this.viewFullSearchHandler}
                searches={filteredSearched}
                onRemoveSearchClick={this.onRemoveSearchClickHander}
                viewChanges={this.viewChanges}
              />
            </div>
          </div>
        );
      }
    }

    return <div>{content}</div>;
  }
}

const mapToStateProps = ({
  stream: { saveSearches, isFetchingSaveSearches },
}) => ({
  searches: saveSearches || [],
  isFetchingSearches: isFetchingSaveSearches,
});

const mapDispatchToProps = (dispatch) => ({
  unSubscribeSearch(query) {
    dispatch(shouldUnsubsribeForSearch(query));
  },
  viewFullSearch(search) {
    dispatch(shouldResetSearchCount(search.Id));
  },
  close() {
    dispatch(closeNotificationCenter());
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(injectIntl(KeepInTheLoopContent));
