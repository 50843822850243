"use strict";

exports.__esModule = true;
exports.GhostHistoryChangeSetSourceWrapper = exports.GhostHistoryChangeSetSourceHeading = exports.GhostHistoryChangeSetSourceDetails = exports.GhostHistoryChangeSetSourceBox = exports.GhostHistoryChangeSetSourceBorderCircle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var GhostHistoryChangeSetSourceBox = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background: #ffffff;\n  border-left: 1px solid #ececec;\n  border-right: 1px solid #ececec;\n  max-width: 100%;\n\n  &:last-of-type {\n    border-bottom: 1px solid #ececec;\n  }\n"])));
exports.GhostHistoryChangeSetSourceBox = GhostHistoryChangeSetSourceBox;
var GhostHistoryChangeSetSourceWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  background: #ffffff;\n  border-left: 1px solid rgb(212, 218, 209);\n  display: flex;\n  flex-direction: column;\n  margin-left: 36px;\n  padding: 14px 16px;\n"])));
exports.GhostHistoryChangeSetSourceWrapper = GhostHistoryChangeSetSourceWrapper;
var GhostHistoryChangeSetSourceHeading = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  justify-content: flex-start;\n  margin-left: 10px;\n  position: relative;\n  width: 100%;\n"])));
exports.GhostHistoryChangeSetSourceHeading = GhostHistoryChangeSetSourceHeading;
var GhostHistoryChangeSetSourceBorderCircle = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  background: #ffffff;\n  border: 1px solid #9e9e9e;\n  border-radius: 80px;\n  content: '';\n  height: 6px;\n  min-height: 6px;\n  left: -25.5px;\n  position: absolute;\n  width: 6px;\n  min-width: 6px;\n"])));
exports.GhostHistoryChangeSetSourceBorderCircle = GhostHistoryChangeSetSourceBorderCircle;
var GhostHistoryChangeSetSourceDetails = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"])));
exports.GhostHistoryChangeSetSourceDetails = GhostHistoryChangeSetSourceDetails;