import { connect } from 'react-redux';

const mapDispatchProps = (
  { entity: { schema, isFetchingSchema } },
  { propertyKey },
) => ({
  schema: (schema || []).find(
    (s) => s.Key === propertyKey || propertyKey === `property-${s.Key}`,
  ),
  isFetchingSchema: isFetchingSchema || !schema,
});

export const withSchemaFromPropertyKey = (Comp) => {
  return connect(mapDispatchProps)(Comp);
};

export default withSchemaFromPropertyKey;
