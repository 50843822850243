import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import { get } from 'lodash';
import moment from 'moment';

import {
  ExplainLogCategoryBorderCircle,
  ExplainLogCategoryBox,
  ExplainLogCategoryDetails,
  ExplainLogCategoryHeading,
  ExplainLogCategoryHeadingWrapper,
  ExplainLogCategoryTitle,
  ExplainLogCategoryWrapper,
  ExplainLogDetailWrapper,
  ExplainLogCategoryStatus,
} from './ExplainLogCategory.styles';
import ExplainLogKind from '../ExplainLogKind/ExplainLogKind';
import ExplainLogDetail from '../ExplainLogDetail/ExplainLogDetail';
import ExplainLogSummary from '../ExplainLogSummary/ExplainLogSummary';

const ExplainLogCategory = ({ data, hasEvents }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);

  const resultStatus = get(data, 'result.status');

  const handleIsExpanded = () => {
    if (hasEvents) {
      setIsExpanded(!isExpanded);
    } else {
      null;
    }
  };

  return (
    <ExplainLogCategoryBox>
      <ExplainLogCategoryWrapper>
        <ExplainLogCategoryHeading isExpanded={isExpanded}>
          <ExplainLogCategoryHeadingWrapper
            data-test="entity_explainLog_detailButton"
            hasEvents={hasEvents}
            onClick={handleIsExpanded}
          >
            {hasEvents &&
              (isExpanded ? (
                <Arrowdown color={themePrimary} size={16} />
              ) : (
                <Arrowright color={themePrimary} size={16} />
              ))}

            <ExplainLogCategoryBorderCircle />

            <ExplainLogCategoryDetails>
              <ExplainLogCategoryTitle>
                {data && data?.title}
              </ExplainLogCategoryTitle>
              {data && resultStatus && (
                <div style={{ display: 'flex' }}>
                  <FormattedMessage id="module-explainLog-categoryStatus" />
                  :&nbsp;
                  <ExplainLogCategoryStatus resultStatus={resultStatus}>
                    <strong>{resultStatus}</strong>
                  </ExplainLogCategoryStatus>
                  {data?.result?.event?.message && (
                    <>
                      &nbsp;-&nbsp;
                      <div>{data.result.event.message}</div>
                    </>
                  )}
                </div>
              )}

              {data?.duration && (
                <div style={{ display: 'flex' }}>
                  <FormattedMessage id="module-explainLog-detailDuration" />
                  :&nbsp;
                  <div>
                    {moment
                      .utc(
                        moment
                          .duration(data.duration, 'seconds')
                          .asMilliseconds(),
                      )
                      .format('HH:mm:ss.SSS')}
                    s
                  </div>
                </div>
              )}
            </ExplainLogCategoryDetails>
          </ExplainLogCategoryHeadingWrapper>
        </ExplainLogCategoryHeading>

        <ExplainLogDetailWrapper>
          {isExpanded && data?.summaries?.length > 0 && (
            <ExplainLogKind
              title={<FormattedMessage id="module-explainLog-summariesTitle" />}
            >
              {data?.summaries?.map((item, index) => {
                return <ExplainLogSummary key={index} data={item} />;
              })}
            </ExplainLogKind>
          )}

          {isExpanded && data && (
            <ExplainLogKind
              title={
                <FormattedMessage id="module-explainLog-eventsAndOperations" />
              }
            >
              {isExpanded &&
                data?.events?.length > 0 &&
                (data?.events || []).map((item, index) => {
                  const isOperation = item.kind === 'Operation';

                  return (
                    <ExplainLogDetail
                      key={index}
                      data={item}
                      isOperation={isOperation}
                    />
                  );
                })}
            </ExplainLogKind>
          )}
        </ExplainLogDetailWrapper>
      </ExplainLogCategoryWrapper>
    </ExplainLogCategoryBox>
  );
};

export default ExplainLogCategory;

ExplainLogCategory.propTypes = {
  data: PropTypes.object,
  hasEvents: PropTypes.bool,
};
