const guessingValueFromEntityBasedOnKey = (key, entityProperties = {}) => {
  const allLikelyCorrespondingKeys = [];
  const allPropertyKeys = Object.keys(entityProperties);
  allPropertyKeys.forEach((k) => {
    const allKeys = k.split('.');
    const lastKey = allKeys.pop();

    if (lastKey.indexOf(key) > -1) {
      allLikelyCorrespondingKeys.push(k);
    }
  });

  const hasCluedIn = allLikelyCorrespondingKeys.find(
    (k) => k.indexOf('cluedin') > -1,
  );

  if (hasCluedIn) {
    return entityProperties[hasCluedIn];
  }

  if (allLikelyCorrespondingKeys && allLikelyCorrespondingKeys.length > 0) {
    return entityProperties[allLikelyCorrespondingKeys[0]];
  }

  return null;
};

/**
 * This method try to do his best at finding a value for a given 'common'
 * property name. It is used to build precise viewModel based on a value wanted.
 * (eg: organizationViewModel, personViewModel, documentViewModel).
 * The idea is to make the component relying a specific structure instead of the
 * generic entity which bloat the Component with uncessary entity logic.
 * @param {string}, The common name of a property (eg: revenu, jobTitle,...)
 * @param {Object}, The value of all the properties for a given entities entityProperties
 * @param {array}, The pool for a given common property
 */
const findViewModelPropertyFromPropertyPools = (
  key,
  entityProperties = {},
  propertyPool = [],
  transform,
) => {
  let result;
  const allEntityPropertiesKey = Object.keys(entityProperties);

  allEntityPropertiesKey.forEach((propertyKey) => {
    propertyPool.forEach((propertyKeyValueFromPool) => {
      if (
        !result &&
        propertyKey.toLowerCase() === propertyKeyValueFromPool.toLowerCase()
      ) {
        result = transform
          ? transform(entityProperties[propertyKey])
          : entityProperties[propertyKey];
      }
    });
  });

  if (!result) {
    result = guessingValueFromEntityBasedOnKey(key, entityProperties);
  }

  return result;
};

/**
 * This method creates a viewModel based on the viewModel proeprty pool
 * @param {Object}, The value of all the properties for a given entities entityProperties
 * @param {array}, The entire pool of properties for a viewModel
 */
export const createViewModelFromPropertyPools = (
  entityProperties = {},
  vmPropertyPool = [],
) => {
  const VM = {};

  vmPropertyPool.forEach((propConfig) => {
    VM[propConfig.key] = findViewModelPropertyFromPropertyPools(
      propConfig.key,
      entityProperties,
      propConfig.pool,
      propConfig.transform,
    );
  });

  return VM;
};

export default {
  createViewModelFromPropertyPools,
};
