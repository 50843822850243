import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import { actionsConfiguration, useSearch } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { List } from '@cluedin/list-pattern';
import { useRevokeUsersPermission } from '../../../../hooks/useRevokeUsersPermission';
import withAddUsers from './withAddUsers';
import { columns } from './columns';
import { useQueryConfigurationSettings } from '../../../../../security/hooks/useConfigurationSettings';
import { useQueryCurrentOrganization } from '../../../../../security/hooks/useCurrentOrganziation';
import GlobalSecurityDisabled from './GlobalSecurityDisabled';

const { consume } = actionsConfiguration;

const Permissions = ({
  connector,
  openWorkflow,
  history,
  location,
  notFoundProps = {},
  noSearchFoundProps = {},
  refetch,
  offsetTop = 260,
  loading,
}) => {
  const [org, loadingOrg] = useQueryCurrentOrganization();

  const [configurationSettings, loadingConfig, error] =
    useQueryConfigurationSettings(org.Id);

  const globalSecurityToggle = get(
    configurationSettings,
    'Feature.Security.AccountFilter.Enabled',
  );

  const usersWithAccess = get(connector, 'users', []);
  const [searchTerm, setSearchTerm] = useSearch(history, location, {
    searchName: 'permissionsSearch',
  });
  const [searchName, setSearchName] = useState(searchTerm);
  const [revokePermissions, loadingRevoke, errorRevoke, revokedDone] =
    useRevokeUsersPermission();
  const data = usersWithAccess.filter((u) =>
    includes(toLower(u.username), toLower(searchTerm)),
  );

  useEffect(() => {
    if (!loadingRevoke && revokedDone && refetch) {
      refetch();
    }
  }, [loadingRevoke, revokedDone, refetch]);

  if (globalSecurityToggle?.toLowerCase() === 'false') {
    return <GlobalSecurityDisabled />;
  }

  return (
    <>
      <List
        data={data}
        loading={loading || loadingOrg || loadingConfig}
        columns={columns}
        searchName={searchName}
        onChangeSearch={setSearchName}
        onSubmitSearch={setSearchTerm}
        offsetTop={offsetTop}
        dataGridName="connectors-permissions"
        creation={[
          {
            label: <FormattedMessage id="module-connector-addUsers" />,
            onClick: () => openWorkflow(connector),
            actionConfiguration: consume.exportTargets.exportTarget.edit,
          },
        ]}
        notFoundProps={{
          ...notFoundProps,
          onCreateClick: () => openWorkflow(connector),
          actionConfiguration: consume.exportTargets.exportTarget.edit,
        }}
        noSearchFoundProps={{
          ...noSearchFoundProps,
          onCreateClick: () => openWorkflow(connector),
          actionConfiguration: consume.exportTargets.exportTarget.edit,
        }}
        actions={[
          {
            label: <FormattedMessage id="module-connector-revokeAccess" />,
            action: (userIds) => revokePermissions(connector, userIds),
          },
        ]}
        error={errorRevoke}
      />
    </>
  );
};

export default withRouter(withAddUsers(Permissions));
