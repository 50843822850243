import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';

const MultiSelectionConfigurationWrapper = styled.div`
  border-bottom: 1px solid #d4d4d4;
`;

const MultiSelectionConfigurationRow = styled.div`
  padding: 4px;
  border-top: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  display: flex;
`;

const MultiSelectionConfigurationIntegration = styled.div`
  flex: 1;
  padding: 0 0 0 8px;
`;

const MultiSelectionConfigurationState = styled.div`
  text-align: right;
  padding: 0 8px 0 8px;
  font-size: 14px;
`;

class MultiSelectionOverviewViewModel extends Component {
  constructor(props) {
    super(props);
    this.showMoreHandler = this.showMoreHandler.bind(this);
    this.state = {
      showMore: null,
    };
  }

  hideHandler() {
    this.setState({
      showMore: null,
    });
  }

  showMoreHandler(value) {
    this.setState({
      showMore: value,
    });
  }

  render() {
    const { title, entityType, viewModels } = this.props;
    const { showMore } = this.state;

    return (
      <div>
        <div style={{ marginBottom: '12px' }}>{title}</div>
        <MultiSelectionConfigurationWrapper
          style={
            viewModels.length > 5
              ? { maxHeight: '300px', overflowY: 'scroll' }
              : {}
          }
        >
          {viewModels.map((viewModel) => {
            return (
              <div>
                <MultiSelectionConfigurationRow>
                  <MultiSelectionConfigurationIntegration>
                    {viewModel.title}
                  </MultiSelectionConfigurationIntegration>
                  {viewModel.children && viewModel.children.length > 0 && (
                    <MultiSelectionConfigurationState>
                      {showMore === viewModel.id && (
                        // eslint-disable-next-line
                        <a
                          onClick={() => {
                            this.hideHandler();
                          }}
                        >
                          <FormattedMessage
                            id="module-configuration-selectedConfigurationForAccesssHide"
                            defaultMessage="Hide"
                          />
                        </a>
                      )}
                      {showMore !== viewModel.id && (
                        // eslint-disable-next-line
                        <a
                          onClick={() => {
                            this.showMoreHandler(viewModel.id);
                          }}
                        >
                          {viewModel.children.length}
                          &nbsp;
                          {entityType}
                          {viewModel.children.length > 0 ? 's' : ''}
                        </a>
                      )}
                    </MultiSelectionConfigurationState>
                  )}
                </MultiSelectionConfigurationRow>
                {viewModel.children &&
                  viewModel.children.length > 0 &&
                  showMore === viewModel.id && (
                    <MultiSelectionConfigurationRow
                      style={{ flexDirection: 'column' }}
                    >
                      {viewModel.children.map((c) => {
                        return (
                          <div style={{ padding: '4px 12px', flex: 1 }}>
                            {c}
                          </div>
                        );
                      })}
                    </MultiSelectionConfigurationRow>
                  )}
              </div>
            );
          })}
        </MultiSelectionConfigurationWrapper>
      </div>
    );
  }
}

export default MultiSelectionOverviewViewModel;
