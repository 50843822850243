import React, { useEffect } from 'react';
import get from 'lodash/get';

import { useGetDeduplicationProjectMergeNodeInfo } from '../../../hooks/useGetDeduplicationProjectMergeNodeInfo';
import DeduplicationProjectMergeContainer from '../DeduplicationProjectMergeContainer/DeduplicationProjectMergeContainer';

const DeduplicationProjectMergePanelAPIContainer = ({
  entityId,
  currentNode,
  nodeDataType,
  setNodeDataType,
  handleExecuteAction,
}) => {
  const mergeDataPartId = get(currentNode, 'actions[0].mergeDataPartId');

  const [
    getDeduplicationProjectMergeNodeInfo,
    {
      data: deduplicationProjectMergePanelData,
      loading: deduplicationProjectMergePanelLoading,
      error: deduplicationProjectMergePanelError,
    },
  ] = useGetDeduplicationProjectMergeNodeInfo();

  useEffect(() => {
    if (entityId && mergeDataPartId) {
      getDeduplicationProjectMergeNodeInfo(entityId, mergeDataPartId);
    }
  }, [entityId, mergeDataPartId]);

  return (
    <>
      {deduplicationProjectMergePanelData && (
        <DeduplicationProjectMergeContainer
          entityId={entityId}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          currentNode={currentNode}
          handleExecuteAction={handleExecuteAction}
          deduplicationProjectMergePanelData={
            deduplicationProjectMergePanelData
          }
          deduplicationProjectMergePanelLoading={
            deduplicationProjectMergePanelLoading
          }
          deduplicationProjectMergePanelError={
            deduplicationProjectMergePanelError
          }
        />
      )}
    </>
  );
};

export default DeduplicationProjectMergePanelAPIContainer;
