import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import PropsMapperContainerQueries from 'uxi/internal/PropsMapperContainerQueries';
import Flex from 'uxi/Layout/Flex';
import { Spinner } from '@cluedin/form';
import Alert from 'uxi/Alert';
import FrontPage from './FrontPage';
import ProfileInformation from './ProfileInformation';
import PropertiesList from './PropertiesList';
import Entities from './Entities';
import EntitiesEditable from './EntitiesEditable';
import InformationsSources from './InformationsSources';
import Location from './Location';
import PersonnalIdentifiers from './PersonnalIdentifiers';
import DataBreach from './DataBreach';
import Retention from './Retention';
import Consents from './Consents';

const MFBSW = styled.div`
  & > div > div {
    width: 100%;
  }
`;

const WhiteIConWrapper = styled.div`
  svg rect {
    fill: white;
  }
`;

const LoadingCompo = () => (
  <Flex>
    <Spinner />
  </Flex>
);

const WhiteLoadingCompo = () => (
  <WhiteIConWrapper>
    <LoadingCompo />
  </WhiteIConWrapper>
);

const GeneratingMsgCompo = () => (
  <MFBSW>
    <Alert>
      <Flex>
        <FormattedMessage
          id="module-gdpr-generation-in-progress"
          defaultMessage="The report is being generated"
        />
        <WhiteLoadingCompo />
      </Flex>
    </Alert>
  </MFBSW>
);

const RegeneratingMsgCompo = () => (
  <MFBSW>
    <Alert>
      <Flex>
        <FormattedMessage
          id="module-gdpr-regeneration-in-progress"
          defaultMessage="The report is being regenerated"
        />
        <WhiteLoadingCompo />
      </Flex>
    </Alert>
  </MFBSW>
);

const rules = [
  {
    minWidth: 1,
    mapper: ({ containerWidth }) => ({
      isFullPage: window.innerWidth - 32 === containerWidth,
    }),
  },
];

const ReportWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  padding: 16px;
  background: #fbfbfb;
  background: white;
`;

class GdprReportComposite extends Component {
  componentDidMount() {
    const { shouldPrint } = this.props;

    if (shouldPrint && window && 'print' in window) {
      // give some time for the page to load
      setTimeout(() => {
        if (window && window.print) {
          window.print();
        }
      }, 1500);
    }
  }

  render() {
    const {
      currentSARJsonReport,
      reportSettings,
      editable,
      onExcludedGuidsChange,
      onExcludedEntityTypeChange,
    } = this.props;

    if (!currentSARJsonReport) {
      return <div>Report is being generated</div>;
    }

    const EntitiesSectionComp = editable ? EntitiesEditable : Entities;

    return (
      <ReportWrapper>
        <div>
          <PropsMapperContainerQueries
            rules={rules}
            isFullPage={false}
            debounceDelay={90}
          >
            <FrontPage jsonReport={currentSARJsonReport} editable={editable} />
          </PropsMapperContainerQueries>
          <ProfileInformation
            jsonReport={currentSARJsonReport}
            editable={editable}
          />
          <PersonnalIdentifiers
            jsonReport={currentSARJsonReport}
            editable={editable}
          />
          <PropertiesList
            jsonReport={currentSARJsonReport}
            editable={editable}
          />
          <Location jsonReport={currentSARJsonReport} editable={editable} />
          <DataBreach jsonReport={currentSARJsonReport} editable={editable} />
          <Retention jsonReport={currentSARJsonReport} editable={editable} />
          <EntitiesSectionComp
            reportSettings={reportSettings}
            jsonReport={currentSARJsonReport}
            editable={editable}
            onExcludedEntityTypeChange={onExcludedEntityTypeChange}
            onExcludedGuidsChange={onExcludedGuidsChange}
          />
          <InformationsSources
            jsonReport={currentSARJsonReport}
            editable={editable}
          />
          <Consents jsonReport={currentSARJsonReport} editable={editable} />
        </div>
      </ReportWrapper>
    );
  }
}

GdprReportComposite.defaultProps = {
  editable: true,
  currentSARJsonReport: null,
  onChange: () => {},
  reportSettings: null,
};

const GdprReportCompositeEnhanced = compose(
  branch(
    ({ reportStatus }) => reportStatus === 'Generating',
    renderComponent(GeneratingMsgCompo),
  ),
  branch(
    ({ reportStatus }) =>
      reportStatus === 'RegeneratingExcludedGuids' ||
      reportStatus === 'RegeneratingClueTransform',
    renderComponent(RegeneratingMsgCompo),
  ),
  branch(
    ({ currentSARJsonReport }) => !currentSARJsonReport,
    renderComponent(LoadingCompo),
  ),
)(GdprReportComposite);

export default GdprReportCompositeEnhanced;
