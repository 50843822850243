import { ButtonDropdown } from '@cluedin/components';
import { Checkbox } from '@cluedin/form';

import ResetNodes from './ResetNodes';
import { FiltersWrapper } from './Filters.styles';

const Filters = ({ onSelect, filter, labels = {}, resetSelectedNodes }) => {
  const {
    filtersLabel = 'Filters',
    filtersDeadEndNodes = 'Show dead end connecting nodes',
    resetNodesLabel = 'Reset Nodes',
  } = labels;

  const onChange = (event) => {
    const checked = event?.target?.checked;
    onSelect(checked);
  };

  const defaultItems = [
    {
      text: (
        <Checkbox
          checked={filter}
          onChange={onChange}
          label={filtersDeadEndNodes}
        />
      ),
    },
  ];

  return (
    <FiltersWrapper>
      <div style={{ position: 'relative' }}>
        <ButtonDropdown
          text={filtersLabel}
          menuItems={defaultItems}
          onSelectItem={(item) => {}}
        />
      </div>

      <ResetNodes text={resetNodesLabel} onClick={resetSelectedNodes} />
    </FiltersWrapper>
  );
};

export default Filters;
