import styled from 'styled-components';

export const RootEntityHeaderIcon = styled.img<{
  alt: string;
  title: string;
}>`
  align-items: center !important;
  display: flex !important;
  filter: brightness(1000%) !important;
  height: 50% !important;
  justify-content: center !important;
  margin: 0 !important;
  padding: 20px;
  width: 50% !important;
`;
