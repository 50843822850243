import { useMemo, useState } from 'react';
import { Select, Loader } from '@cluedin/form';
import { useVocabularyKeyValues } from '../../../modules/dataCatalog/hooks/useVocabularyKeyValues';

export const OnlyExistingValuesSelect = ({
  value,
  onChange,
  vocabularyKey,
  isCreatable,
  defaultValue,
}) => {
  const [{ terms }, isLoading] = useVocabularyKeyValues(vocabularyKey);
  const [query, setQuery] = useState('');
  const options = useMemo(() => {
    const result = terms?.map((elem) => ({
      key: elem.term,
      value: elem.term,
      label: elem.term,
    }));

    return result?.filter((option) => option.label.includes(query));
  }, [terms, query]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      defaultValue={{ value: defaultValue, label: defaultValue }}
      onInputChange={(val) => {
        setQuery(val);
      }}
      options={options}
      isSearchable
      isCreatable={isCreatable}
    />
  );
};
