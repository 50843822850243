import { getEntityConfiguration } from '../modules/wms/helpers/widgetHelper';

export const getName = (entityType) => {
  const entityConfigPerEntityType = getEntityConfiguration(entityType);

  return entityConfigPerEntityType
    ? entityConfigPerEntityType.displayName
    : entityType;
};

export const getNameWithArticle = (entityType) => {
  const entityConfigPerEntityType = getEntityConfiguration(entityType);

  return entityConfigPerEntityType
    ? entityConfigPerEntityType.displayNameWithArticle
    : entityType;
};

export const getIcon = (entityType) => {
  const entityConfigPerEntityType = getEntityConfiguration(entityType);

  return entityConfigPerEntityType
    ? entityConfigPerEntityType.icon
    : entityType;
};
