import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'uxi/Input';
import { Loader } from '@cluedin/form';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import { shouldSetProductOwner, shouldRemoveProductOwner } from '../../actions';

const SetProviderOwnerContainer = ({
  user,
  isFetching,
  isOwner,
  setProductOwner,
  removeProductOwner,
  configurationId,
  currentOwnerProviderEntity,
  invalid,
}) => {
  const hasEditConfiguredIntegrations = useUserHasAccess(
    actionsConfiguration.integrations.configuredIntegrations
      .configuredIntegration.edit.claims,
  );

  return (
    <div>
      {invalid && (
        <span style={{ color: 'red', fontSize: '11px' }}>
          Something went wrong.
        </span>
      )}
      {!isFetching && !invalid && (
        <Switch
          name={`productOwner-${configurationId}-${user.Account.Id}`}
          checked={isOwner}
          disabled={!hasEditConfiguredIntegrations}
          onChange={() => {
            if (isOwner) {
              removeProductOwner(currentOwnerProviderEntity);
            } else {
              setProductOwner(user.Account.Id, configurationId);
            }
          }}
        />
      )}
      {isFetching && !invalid && (
        <div style={{ display: 'flex', paddingLeft: '16px', height: '15px' }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (
  {
    quickView: { configuration },
    configuration: {
      setProductOwner,
      removeProductOwner,
      providerOwner,
      selectedConfiguration,
    },
  },
  { user },
) => {
  let configurationId = null;
  if (configuration) {
    configurationId = configuration.Id;
  } else {
    configurationId = selectedConfiguration ? selectedConfiguration.Id : null;
  }

  const userId = user.Account.Id;
  const uniqueId = `${userId}-${configurationId}`;
  const hasSetAction = setProductOwner[uniqueId] || {};
  const hasRemoveAction = removeProductOwner[uniqueId] || {};
  const providerOwnerIds =
    (providerOwner || []).map(({ UserId }) => UserId) || [];

  const isFetching = hasSetAction.isFetching || hasRemoveAction.isFetching;
  const isOwner = (providerOwnerIds || []).indexOf(user.Account.Id) > -1;
  const currentOwnerProviderEntity = (providerOwner || []).find(
    ({ UserId }) => UserId === userId,
  );
  const invalid = hasSetAction.invalid || hasRemoveAction.invalid;

  return {
    isFetching,
    isOwner,
    configurationId,
    currentOwnerProviderEntity,
    invalid,
    providerOwner,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProductOwner(userId, configurationId) {
    dispatch(shouldSetProductOwner(userId, configurationId));
  },
  removeProductOwner(currentOwnerProviderEntity) {
    dispatch(shouldRemoveProductOwner(currentOwnerProviderEntity));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetProviderOwnerContainer);
