import styled, { css } from 'styled-components';

export const ExplainLogBestGuessSourceImageWrapper = styled.div`
  align-items: center;
  background: lightblue;
  border-radius: 80%;
  display: flex;
  justify-content: center;
  margin-right: 12px;
  padding: ${({ isImage }) => (isImage ? '0' : '20px')};

  & svg {
    fill: #fff !important;
    height: 20px;
    margin: 0 !important;
    stroke: #fff;
    width: 20px;

    & path {
      fill: #fff;
    }
  }
`;

export const ExplainLogBestGuessSourceDetails = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  & strong {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2px;

    & span {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 2px;
    }
  }
`;

export const ExplainLogBestGuessSourceIconWrapper = styled.div`
  align-items: center;

  ${({ dataSourceKind }) => {
    switch (true) {
      case dataSourceKind === 'manualmerge':
        return css`
          background: #fbf4de;
        `;
      case dataSourceKind === 'deduplicationprojectmerge':
        return css`
          background: #e2cbf9;
        `;
      case dataSourceKind === 'clean' || dataSourceKind === 'legacyclean':
        return css`
          background: #ccf0f7;
        `;
      case dataSourceKind === 'userinput':
        return css`
          background: #ffe0f4;
        `;
      default:
        return css`
          background: ${({ themePrimary }) =>
            themePrimary ? themePrimary : 'rgb(41, 162, 154)'};
        `;
    }
  }}

  border-radius: 80%;
  display: flex;
  justify-content: center;
  margin-right: 12px;
  max-height: 40px !important;
  max-width: 40px !important;

  & svg {
    height: 20px !important;
    padding: 10px;
    width: 20px !important;
    max-height: 20px !important;
    max-width: 20px !important;
  }

  & svg path {
    ${({ dataSourceKind }) => {
      switch (true) {
        case dataSourceKind === 'manualmerge':
          return css`
            fill: #f2c230;
          `;
        case dataSourceKind === 'deduplicationprojectmerge':
          return css`
            fill: #a65dee;
          `;
        case dataSourceKind === 'clean' || dataSourceKind === 'legacyclean':
          return css`
            fill: #00b4d8;
          `;
        case dataSourceKind === 'userinput':
          return css`
            fill: #ff99da;
          `;
        default:
          return css`
            fill: ${({ themeWhite }) => themeWhite ?? '#fff'};
          `;
      }
    }}
  }
`;
