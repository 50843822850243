import { get } from 'lodash';
import React, { useState } from 'react';
import { withDialogWorkflowWrapper } from './withDialogWorkflowWrapper';

export const withDialogWorkflow = (Comp, Workflow, options = {}) => {
  const EnhancedWorkflow = withDialogWorkflowWrapper(Workflow, options);
  const methodName = get(options, 'methodName');
  const extraProps = {};

  return (props) => {
    const [open, setOpen] = useState(false);

    const openWorkflow = (values) => {
      if (values) {
        setOpen(values);
      } else {
        setOpen(true);
      }
    };

    if (methodName) {
      extraProps[methodName] = openWorkflow;
    } else {
      extraProps['openWorkflow'] = openWorkflow;
    }

    return (
      <>
        {!!open && (
          <EnhancedWorkflow
            open={!!open}
            values={open}
            {...props.workflowProps}
            {...options}
            {...props}
            onClose={() => {
              setOpen(false);

              props?.onCloseDialog?.();
            }}
          />
        )}
        <Comp {...extraProps} {...props} />
      </>
    );
  };
};

export default {
  withDialogWorkflow,
};
