import { useSearchFilters } from './useSearchFilters';
import { useSearchSort } from './useSearchSort';

const getContextIdsVariables = (selectedFilters = []) => {
  return selectedFilters
    .filter(
      (f) =>
        f.type.toLowerCase() === '/person' ||
        f.type.toLowerCase() === '/organization',
    )
    .map((filter) => filter.value);
};

const createVariables = (props, cursor) => {
  const {
    q,
    selectedFilters,
    sortVariables,
    take,
    contextIds,
    cursor: cursorFromProps,
  } = props;

  return {
    query: q,
    pageSize: take,
    cursor: cursor || cursorFromProps,
    filters: selectedFilters,
    contextIds,
    includeExternalData: false,
    includeUnstructuredData: false,
    ...sortVariables,
  };
};

export const useSearchQueryDecoder = (
  searchQuery = {},
  entityTypePoolIdentifier,
) => {
  const [
    selectedFilters,
    { filters, addFilter, removeFilter, clearAllFilters },
  ] = useSearchFilters(searchQuery.filters, entityTypePoolIdentifier);

  const [sortVariables, sortType, onSortChange] = useSearchSort(
    searchQuery.sort,
    searchQuery.q,
  );

  const hasPropertySearch =
    searchQuery && searchQuery.hasPropertySearch
      ? searchQuery.hasPropertySearch
      : false;

  return [
    createVariables({
      q: searchQuery.q,
      take: 27,
      selectedFilters: filters,
      contextIds: getContextIdsVariables(selectedFilters),
      sortVariables,
      hasPropertySearch,
    }),
    {
      sortType,
      selectedFilters,
      addFilter,
      removeFilter,
      clearAllFilters,
      hasPropertySearch,
      onSortChange,
    },
  ];
};
