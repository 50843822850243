import React from 'react';
import { Query } from '@apollo/client/react/components';
import { getDataSetById } from '../data';

export const withDataSet = (Comp) => (props) => {
  return (
    <Query
      query={getDataSetById}
      variables={{ id: props.id || props.dataSetId }}
      fetchPolicy="no-cache" // check whey we need to disable cache to get the properties update
    >
      {({ loading, error, data = {}, refetch }) => {
        let dataSet = null;
        if (
          !loading &&
          !error &&
          data &&
          data.inbound &&
          data.inbound.dataSet
        ) {
          dataSet = data.inbound.dataSet;
        }

        return (
          <Comp
            {...props}
            dataSetId={props.id || props.dataSetId}
            isFetching={loading}
            dataSetError={error}
            dataSet={dataSet}
            refetchDataset={() => {
              refetch({
                id: props.id,
              });
            }}
          />
        );
      }}
    </Query>
  );
};

export default withDataSet;
