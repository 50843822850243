import { createElement } from 'react';
import _ from 'lodash';
import { hashCode, id } from './layoutHelper';

const getWidgetParameters = (widget) => {
  let wParameterFromDatabase = {};
  try {
    if (_.isString(widget.parameters)) {
      wParameterFromDatabase = JSON.parse(widget.parameters);
    } else {
      wParameterFromDatabase = widget.parameters || {};
    }
  } catch (e) {
    wParameterFromDatabase = {};
  }

  return wParameterFromDatabase;
};

const getWidgetName = (widget, widgetParameters) => {
  let name;

  if (widget.descriptor) {
    name = widget.descriptor.displayName;
  }

  if (widgetParameters && widgetParameters.displayName) {
    name = widgetParameters.displayName;
  }

  return name;
};

const WidgetRenderer = ({
  entityId,
  userId,
  entity,
  layoutName,
  widget,
  template,
  isFirst,
}) => {
  let realContextId = entityId;
  let widgetParameters = getWidgetParameters(widget);

  // eslint-disable-next-line
  const widgetRegistry = window.__cluedin_in[widget.name];

  if (!widgetRegistry) {
    return (
      <div key={widget.name} id={`${widget.name}`}>
        {widget.name}
      </div>
    );
  }

  const name = getWidgetName(widget, widgetParameters);
  const descriptor = widget && widget.descriptor ? widget.descriptor : {};
  const parametersHahsCoase = widget.parameters
    ? hashCode(JSON.stringify(widget.parameters))
    : undefined;

  const widgetParamsW = {
    name,
    template,
    descriptor,
    entity,
    entityId: realContextId,
    userId,
    isFirst,
    widgetUniqueId: id(
      widget.name,
      userId,
      entityId,
      layoutName,
      parametersHahsCoase,
    ),
    ...widgetParameters,
  };

  if (widgetRegistry.hasCode) {
    return createElement(widgetRegistry.component, widgetParamsW);
  }
  return createElement(widgetRegistry.descriptor.view, widgetParamsW);
};

export default WidgetRenderer;
