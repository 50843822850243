"use strict";

exports.__esModule = true;
exports.useInMemorySearch = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _useSearch2 = require("./useSearch");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useInMemorySearch = function useInMemorySearch(data, searchFields, history, location, options) {
  var _useSearch = (0, _useSearch2.useSearch)(history, location, options),
    searchName = _useSearch[0],
    setSearchName = _useSearch[1];
  if (!searchName) {
    return [data, searchName, setSearchName];
  }
  var filteredData = _lodash.default.filter(data, function (value) {
    var allValues = [];
    (searchFields || []).forEach(function (searchField) {
      var valueForSearchField = _lodash.default.get(value, searchField);
      if (valueForSearchField) {
        allValues.push(valueForSearchField);
      }
    });
    return _lodash.default.chain(allValues).join('-&-').toLower().includes(_lodash.default.toLower(searchName)).value();
  });
  return [filteredData, searchName, setSearchName];
};
exports.useInMemorySearch = useInMemorySearch;