import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';
import { getIndexOfFirstRecord } from './utils';

const GET_ALL_ANNOTATIONS = gql`
  query annotations($pageSize: Int, $page: Int) {
    preparation {
      annotations(pageSize: $pageSize, page: $page) {
        total
        data {
          id
          name
          entityType
          author
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const useQueryAllAnnotations = (page, pageSize) => {
  const { data, refetch, loading, error } = useQuery(GET_ALL_ANNOTATIONS, {
    variables: {
      page: getIndexOfFirstRecord(page, pageSize),
      pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const allAnnotations = get(data, 'preparation.annotations.data', {});

  return [allAnnotations, loading, error, refetch];
};
