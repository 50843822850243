import { gql } from '@apollo/client';

export default gql`
  fragment EntityTypeConfiguration on EntityTypeLayoutConfiguration {
    active
    displayName
    entityType
    icon
    id
    layoutConfiguration
    pageTemplateId
    path
    route
    template
    type
    pageTemplate {
      displayName
      name
      pageTemplateId
    }
  }
`;
