import { compose, withStateHandlers } from 'recompose';

export const withToggle = compose(
  withStateHandlers(({ showFull = false }) => ({ showFull }), {
    toggle:
      ({ showFull }) =>
      () => ({
        showFull: !showFull,
      }),
  }),
);

export default withToggle;
