import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ORG = gql`
  query getCurrentOrg {
    administration {
      id
      organization {
        Id
        ClientId
        OrganizationName
        Description
        Domain
        Logo
        Banner
        LogoFullResolution
        BannerFullResolution
        Website
        Location
        LocationGeocode
        AllowEmailDomainSignup
        PrimaryColor
        Theme
      }
    }
  }
`;

export const useOrganziation = (skip = false) => {
  const { data, loading, error, refetch } = useQuery(GET_ORG, {
    skip,
    fetchPolicy: 'network-only',
  });

  return [
    get(data, 'administration.organization', {}),
    loading,
    error,
    refetch,
  ];
};
