export const sendMessage = function (type, msg) {
  // Make sure you are sending a string, and to stringify JSON
  if (window.parent) {
    window.parent.postMessage(
      JSON.stringify({ type, msg, source: 'cluedin_consent' }),
      '*',
    );
  }
};

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
}

const callbacks = {};

bindEvent(window, 'message', (e) => {
  let message;

  if (!e || !e.data || e.data.source !== 'cluedin_consent') {
    return;
  }

  try {
    message = JSON.parse(e.data);
  } catch (error) {
    console.error(error);
    message = null;
  }

  if (!message || !message.type) {
    return;
  }

  const hasCallback = callbacks[message.type.toLowerCase()];

  if (hasCallback) {
    hasCallback.forEach((callback) => {
      callback(message.msg);
    });
  }
});

export const on = (type, callback) => {
  if (callbacks[type.toLowerCase()]) {
    callbacks[type.toLowerCase()].push(callback);
  } else {
    callbacks[type.toLowerCase()] = [callback];
  }
};

export default {
  sendMessage,
  on,
};
