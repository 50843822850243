import { createViewModelFromPropertyPools } from './base';
import { getLogo, getIcon, getLogoFromGql } from './logo';
import { createSocialViewModel } from './social';
import {
  getEntityTemporalData,
  getEntityTemporalDataFromGql,
} from './entityV2';
import { uniqProviderBasedOnUrisAndProviderCodes } from '../../entity/selectors';
import { hasEntityConfigSetup } from '../../wms/helpers/widgetHelper';

const personPool = [
  {
    key: 'email',
    pool: ['property-Email', 'property-person.email', 'property-user.email'],
  },
  {
    key: 'jobTitle',
    pool: [
      'property-user.jobTitle',
      'property-person.jobTitle',
      'property-user.title',
      'property-linkedin.person.headline',
      'property-Title',
    ],
  },
  {
    key: 'phoneNumber',
    pool: ['property-person.phoneNumber'],
  },
];

export const createPersonViewModelFromGql = (
  entity = {},
  entityConfigurations = [],
) => {
  const {
    id,
    name,
    description,
    uris,
    providerOrigins,
    properties,
    entityType,
  } = entity;

  const viewModelWithProperties = createViewModelFromPropertyPools(
    properties || {},
    personPool,
  );

  const logo = getLogoFromGql(entity);
  const icon = getIcon(entity, entityConfigurations);
  const isEntityConfigured = hasEntityConfigSetup(
    entityType,
    entityConfigurations,
  );
  const social = createSocialViewModel(entity) || {};
  const configurationSources = uris || [];
  const createdDate = getEntityTemporalDataFromGql(entity);
  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    uris,
    providerOrigins || [],
  );

  return {
    entityId: id,
    name,
    logo,
    icon,
    description: description || 'no snippet',
    ...viewModelWithProperties,
    social,
    configurationSources,
    createdDate,
    providers,
    isEntityConfigured,
  };
};

export const createPersonViewModel = (entity = {}) => {
  const {
    showDescription,
    data: { description: descriptionProp, uris },
  } = entity;

  const entityProperties =
    entity && entity.data && entity.data.properties
      ? entity.data.properties
      : {};

  const viewModelWithProperties = createViewModelFromPropertyPools(
    entityProperties,
    personPool,
  );

  const logo = getLogo(entity);
  const icon = getIcon(entity);
  const social = createSocialViewModel(entity) || {};
  const description = showDescription ? descriptionProp : 'no snippet';
  const configurationSources = uris || [];
  const createdDate = getEntityTemporalData(entity);

  return {
    entityId: entity.id,
    name: entity.name,
    logo,
    icon,
    description,
    ...viewModelWithProperties,
    social,
    configurationSources,
    createdDate,
  };
};

export default {
  createPersonViewModel,
  createPersonViewModelFromGql,
};
