import { getEntityTypeIcon } from '@cluedin/components';
import { getFileTypeUrlFromEntity } from '../../entity/entityTypes/generic/helper';
import { getEntityConfiguration } from '../../wms/helpers/widgetHelper';
import { addTokenToImage } from '../../entity/helper';
import { image, getCurrentToken } from '../../../config';

const getDefaultImage = (entityType) => {
  if (entityType === '/Person') {
    return image.defaultProfile;
  }
  if (entityType === '/Organization') {
    return image.defaultLogo;
  }
  return undefined;
};

export const getIcon = (entity = {}, entitiesConfiguration) => {
  const entityType =
    entity.data && entity.data.entityType
      ? entity.data.entityType
      : entity.entityType;

  const entityConfig =
    getEntityConfiguration(entityType, entitiesConfiguration) || {};
  return getEntityTypeIcon(entityConfig.icon || 'Help');
};

// in the case there is not preview imag we should try to get the file type
// default image should depend on entityType
export const getLogo = (entity) => {
  if (
    entity &&
    entity.data &&
    entity.data.previewImage &&
    entity.data.previewImage['attribute-uri']
  ) {
    const smallLogo = addTokenToImage(
      entity.data.previewImage['attribute-uri'],
      getCurrentToken(),
      true,
    );

    const largeLogo = addTokenToImage(
      entity.data.previewImage['attribute-uri'],
      getCurrentToken(),
      false,
      'medium',
    );

    return {
      small: smallLogo,
      large: largeLogo,
    };
  }

  const typeURL = getFileTypeUrlFromEntity(entity);

  if (typeURL) {
    return {
      small: typeURL,
      large: typeURL,
    };
  }

  const defaultImage = getDefaultImage(entity.data.entityType);

  return {
    small: defaultImage,
    large: defaultImage,
  };
};

export const getLogoFromGql = (entity) => {
  if (entity && entity.previewImage && entity.previewImage.uri) {
    const logo = addTokenToImage(
      entity.previewImage.uri,
      getCurrentToken(),
      true,
    );

    return {
      small: logo,
      large: logo,
    };
  }

  const typeURL = getFileTypeUrlFromEntity(entity);
  if (typeURL) {
    return {
      small: typeURL,
      large: typeURL,
    };
  }

  const defaultImage = getDefaultImage(entity.entityType);

  return {
    small: defaultImage,
    large: defaultImage,
  };
};

export default {
  getLogo,
  getIcon,
};
