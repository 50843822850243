import styled from 'styled-components';

export const EntityRelationsGraphV3HeaderControlWrapper = styled.div<{
  theme: unknown;
}>`
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;

  button {
    border-color: ${({ theme }) => theme?.palette?.themePrimary};
    box-shadow: none !important;
    padding: 0;
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
  }

  div:first-of-type button {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-right: none;
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
  }

  div:last-of-type button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 4px !important;
    border-left: none;
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
  }
`;
