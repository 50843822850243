import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { GqlErrorMessages } from '@cluedin/form';
import PageLoader from '../../../../../core/components/composites/PageLoader';
import { useMessagesForEntity } from '../../../../hooks/useMessagesForEntity';
import EntityPendingChangesActionMessages from '../EntityPendingChangesActionMessages/EntityPendingChangesActionMessages';
import toActionMessagesVM from '../../../../utils/toActionMessagesVM';
import { EntityPendingChangesContainerWrapper } from './EntityPendingChangesContainer.styles';

const EntityPendingChangesContainer = ({ entityId, removeMargin }) => {
  const [data, loading, error, refetch] = useMessagesForEntity(entityId);

  const allMessages =
    (data || []) && get(data, 'virtualization.messagesForEntity.messages');
  const filteredMessages =
    allMessages &&
    allMessages.length &&
    allMessages.filter((m) => m.delta && m.delta.PropertyDiffs);
  const actionMessages =
    allMessages && allMessages.length && allMessages.filter((m) => !m.delta);

  const isAllMessages = allMessages && allMessages.length;
  const isFilteredMessages = filteredMessages && filteredMessages.length;

  if (isFilteredMessages !== isAllMessages) {
    console.warn('There are unsupported message types:', allMessages);
  }

  let messagesToShow;
  if (actionMessages) {
    messagesToShow = toActionMessagesVM(actionMessages);
  }
  if (!actionMessages) {
    messagesToShow = [];
  }

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <GqlErrorMessages error={error} />;
  }

  return (
    <EntityPendingChangesContainerWrapper removeMargin={removeMargin}>
      <EntityPendingChangesActionMessages
        entityId={entityId}
        messages={messagesToShow}
        refetchEntityMessages={refetch}
      />
    </EntityPendingChangesContainerWrapper>
  );
};

export default EntityPendingChangesContainer;

EntityPendingChangesContainer.propTypes = {
  entityId: PropTypes.string,
  removeMargin: PropTypes.bool,
};

EntityPendingChangesContainer.defaultProps = {
  entityId: '',
  removeMargin: null,
};
