import React, { useMemo, memo } from 'react';
import styled from 'styled-components';
import { Select } from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';

import usePrevious from '../../../../core/hooks/usePrevious';

const Container = styled.div`
  max-width: 400px;
  width: 100%;
`;

const itemAdapterFunction = (i) => ({
  ...i,
  value: i?.term,
  label: i?.term,
});

export const RuleVocabularyValueSelector = memo(
  injectIntl(
    ({
      intl,
      data,
      value,
      total,
      onChange,
      isLoading,
      hasError,
      disabled,
      style = {},
      setInputValue,
      containerStyle = {},
      isCreatable = true,
      setIsNewVocabularyValue,
      adapterFunc = itemAdapterFunction,
    }) => {
      const prevData = usePrevious(data);

      const persistData = useMemo(() => {
        if (data?.length > 0) {
          return data;
        }

        return prevData;
      }, [isLoading, data]);

      const options = useMemo(
        () => persistData?.map(adapterFunc),
        [persistData],
      );

      const handleChange = (v) => {
        setIsNewVocabularyValue?.(false);

        onChange?.(v?.value);
      };

      const handleCreateNew = (v) => {
        setIsNewVocabularyValue?.(true);

        onChange?.(v);
      };

      const formatCreateLabel = (v) => {
        return `${intl.formatMessage({
          id: 'module-rule-use-option-prefix',
        })} "${v}"`;
      };

      return (
        <Container style={containerStyle}>
          <Select
            placeholder={intl.formatMessage({
              id: 'module-rule-placeholder-vocabularyValue',
            })}
            isSearchable
            style={style}
            total={total}
            value={value}
            options={options}
            isClearable={true}
            hasError={hasError}
            disabled={disabled}
            isLoading={isLoading}
            onChange={handleChange}
            isCreatable={isCreatable}
            onInputChange={setInputValue}
            onCreateOption={handleCreateNew}
            formatCreateLabel={formatCreateLabel}
          />
        </Container>
      );
    },
  ),
);
