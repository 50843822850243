import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import cogoToast from 'cogo-toast';

const DELETE_HIERARCHIES = gql`
  mutation deleteHierarchies($hierarchyIds: [ID]) {
    management {
      deleteHierarchies(hierarchyIds: $hierarchyIds)
    }
  }
`;

export const useDeleteHierarchies = () => {
  const [hideLoading, setHideLoading] = useState();
  const [deleteHierarchies, { loading, data, error }] =
    useMutation(DELETE_HIERARCHIES);

  useEffect(() => {
    const deletedIds = get(data, 'management.deleteHierarchies', []);
    if (!loading && deletedIds.length > 0) {
      if (hideLoading) {
        hideLoading();
      }
      const hierarchyString =
        deletedIds.length > 1 ? 'hierarchies' : 'hierarchy';
      cogoToast.success(`${deletedIds.length} ${hierarchyString} deleted`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  }, [data, loading, hideLoading]);

  return [
    (ids) => {
      deleteHierarchies({ variables: { hierarchyIds: ids } });
      const hierarchyString = ids.length > 1 ? 'hierarchies' : 'hierarchy';
      const data = cogoToast.loading(
        `Deleting ${ids.length} ${hierarchyString}`,
        { position: 'bottom-right' },
      );
      setHideLoading(data.hide);
    },
    {
      data,
      loading,
      error,
    },
  ];
};
