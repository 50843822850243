import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';
import { Button, CancelButton, PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import { SplitIconNoshadow } from '@cluedin/svgs';

import {
  EntityCodePanelFooterWrapper,
  EntityCodePanelFooterInnerWrapper,
  EntityCodePanelFooterSvg,
} from './EntityCodePanelFooter.styles';

const EntityCodePanelFooter = ({
  handleOnCloseWithReset,
  stepperState,
  setStepperState,
  entityCodePanelData,
  handleBack,
  setIsSplitModalOpen,
  isPreviewError,
  isPreviewLoading,
  selectedDataParts,
}) => {
  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  let panelConfirmButtonText = '';
  if (
    entityCodePanelData &&
    entityCodePanelData?.actions?.[0]?.kind === 'RemoveEntityCode'
  ) {
    panelConfirmButtonText = (
      <FormattedMessage id="module-entityTopology-splitPanelSplitButtonRemoveEntityCode" />
    );
  }
  if (
    entityCodePanelData &&
    entityCodePanelData?.actions?.[0]?.kind === 'UndoDeduplicationProjectMerge '
  ) {
    panelConfirmButtonText = (
      <FormattedMessage id="module-entityTopology-splitPanelSplitButtonUndoDeduplicationProjectMerge" />
    );
  }
  if (
    entityCodePanelData &&
    entityCodePanelData?.actions?.[0]?.kind === 'UndoManualMerge'
  ) {
    panelConfirmButtonText = (
      <FormattedMessage id="module-entityTopology-splitPanelSplitButtonUndoManualMerge" />
    );
  }

  return (
    <EntityCodePanelFooterWrapper themeWhite={themeWhite}>
      <EntityCodePanelFooterInnerWrapper>
        <CancelButton
          data-test="entityTopology-entityCodePanel-closeButton"
          onClick={handleOnCloseWithReset}
          variant="outlined"
          onConfirm={handleOnCloseWithReset}
          rounded
          style={{ marginRight: '10px' }}
        >
          <FormattedMessage id="module-entityTopology-splitPanelCloseButton" />
        </CancelButton>

        {stepperState.active === 1 && (
          <PrimaryButton
            data-test="entityTopology-entityCodePanel-step1NextButton"
            disabled={
              (entityCodePanelData?.actions?.[0]?.kind === 'RemoveEntityCode' &&
                selectedDataParts?.length <= 0) ||
              entityCodePanelData?.actions?.[0]?.unavailableReason ||
              entityCodePanelData?.actions?.[0]?.dataParts.length < 1
            }
            color="accent"
            onClick={() => {
              setStepperState({
                active: 2,
                completed: [1],
              });
            }}
            rounded
            className="panel-main-action"
          >
            <FormattedMessage id="module-entityTopology-splitPanelNextButton" />
          </PrimaryButton>
        )}

        {stepperState?.active === 2 && (
          <>
            <Button
              data-test="entityTopology-entityCodePanel-step2BackButton"
              color="accent"
              onClick={handleBack}
              variant="outlined"
              onConfirm={handleBack}
              rounded
              style={{ marginRight: '10px' }}
            >
              <FormattedMessage id="module-entityTopology-splitPanelBackButton" />
            </Button>

            <PrimaryButton
              data-test="entityTopology-entityCodePanel-step2ConfirmButton"
              color="accent"
              onClick={() => {
                setIsSplitModalOpen(true);
              }}
              rounded
              className="panel-main-action"
              disabled={isPreviewLoading || isPreviewError}
            >
              <EntityCodePanelFooterSvg>
                {!isPreviewLoading && !isPreviewError && (
                  <SplitIconNoshadow style={{ marginTop: '2px' }} />
                )}
                <div>{panelConfirmButtonText}</div>
              </EntityCodePanelFooterSvg>
            </PrimaryButton>
          </>
        )}
      </EntityCodePanelFooterInnerWrapper>
    </EntityCodePanelFooterWrapper>
  );
};

export default EntityCodePanelFooter;

EntityCodePanelFooter.propTypes = {
  handleOnCloseWithReset: PropTypes.func,
  stepperState: PropTypes.object,
  setStepperState: PropTypes.func,
  entityCodePanelData: PropTypes.object,
  handleBack: PropTypes.func,
  setIsSplitModalOpen: PropTypes.func,
  isPreviewError: PropTypes.any,
  isPreviewLoading: PropTypes.bool,
  selectedDataParts: PropTypes.array,
};

EntityCodePanelFooter.defaultProps = {
  handleOnCloseWithReset: () => {},
  stepperState: {},
  setStepperState: () => {},
  entityCodePanelData: {},
  handleBack: () => {},
  setIsSplitModalOpen: () => {},
  isPreviewError: null,
  isPreviewLoading: false,
  selectedDataParts: [],
};
