export const makeGoogleMapUrl = (prop) => `http://maps.google.com/?q=${prop}`;

export const makeLinkedInLinkValue = (prop) => {
  if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
    return `https://linkedin.com/in/${prop}`;
  }

  return prop;
};

export const makeLinkWithDirectValue = (prop) => {
  let result = prop;
  if (result.indexOf('http://') === -1 && result.indexOf('https://') === -1) {
    result = `http://${result}`;
  }
  return result;
};

export const makeFacebookValue = (prop) => {
  if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
    return `https://facebook.com/${prop}`;
  }

  return prop;
};

export const makeTwitterLinkValue = (prop) => {
  if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
    return `https://twitter.com/${prop}`;
  }

  return prop;
};

export const makeAngelListLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://angel.co/${prop}`;
    }

    return prop;
  }

  return prop;
};

export const makeWikipediaLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://wikipedia.com/${prop}`;
    }

    return prop;
  }

  return prop;
};

export const makeGooglePlusLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://plus.google.com/${prop}`;
    }
    return prop;
  }
  return prop;
};

export const makeInstagramLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://instagram.com/${prop}`;
    }
    return prop;
  }
  return prop;
};

export const makeGithubLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://github.com/${prop}`;
    }
    return prop;
  }
  return prop;
};

export const makeCrunchbaseLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://crunchbase.com/${prop}`;
    }
    return prop;
  }
  return prop;
};

export const makeYoutubeLinkValue = (prop) => {
  if (prop) {
    if (prop.indexOf('http://') === -1 && prop.indexOf('https://') === -1) {
      return `https://youtube.com/user/${prop}`;
    }
    return prop;
  }
  return prop;
};
