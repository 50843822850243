import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { KeepInTheLoop, CloseHand, Search } from '@cluedin/svgs';

import EmptyState from './EmptyState';

const keepInTheLoopSteps = [
  {
    illustration: <CloseHand width={150} height={150} />,
    description: (
      <div>
        <FormattedMessage id="module-stream-noKeppInTheLoop-step1-line1" />
        <br />
        <FormattedMessage id="module-stream-noKeppInTheLoop-step1-line2" />
      </div>
    ),
  },
  {
    illustration: <Search width={150} height={150} />,
    description: (
      <div>
        <FormattedMessage id="module-stream-noKeppInTheLoop-step2-line1" />
        <br />
        <FormattedMessage id="module-stream-noKeppInTheLoop-step2-line2" />
      </div>
    ),
  },
  {
    illustration: <KeepInTheLoop width={150} height={150} />,
    description: (
      <div>
        <FormattedMessage id="module-stream-noKeppInTheLoop-step3-line1" />
        <br />
        <FormattedMessage id="module-stream-noKeppInTheLoop-step3-line2" />
      </div>
    ),
    isLast: true,
  },
];

const EntityFollowingState = () => (
  <EmptyState
    title={<FormattedMessage id="module-stream-noKeppInTheLoop-title" />}
    description={
      <FormattedMessage id="module-stream-noKeppInTheLoop-description" />
    }
    boardingSteps={keepInTheLoopSteps}
  />
);

export default EntityFollowingState;
