"use strict";

exports.__esModule = true;
exports.default = useForkRef;
var _react = require("react");
var _setRef = _interopRequireDefault(require("./setRef"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function useForkRef(refA, refB) {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior
   */
  return (0, _react.useMemo)(function () {
    if (refA == null && refB == null) {
      return null;
    }
    return function (refValue) {
      (0, _setRef.default)(refA, refValue);
      (0, _setRef.default)(refB, refValue);
    };
  }, [refA, refB]);
}