import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Follow, CloseHand, VisitEntity } from '@cluedin/svgs';

import EmptyState from './EmptyState';

const followSteps = [
  {
    illustration: <CloseHand width={150} height={150} />,
    description: (
      <div>
        <FormattedMessage id="module-stream-noFollow-step1-line1" />
        <br />
        <FormattedMessage id="module-stream-noFollow-step1-line2" />
      </div>
    ),
  },
  {
    illustration: <VisitEntity width={160} height={150} />,
    description: (
      <div>
        <FormattedMessage id="module-stream-noFollow-step2-line1" />
        <br />
        <FormattedMessage id="module-stream-noFollow-step2-line2" />
      </div>
    ),
  },
  {
    illustration: <Follow width={150} height={150} />,
    description: (
      <div>
        <FormattedMessage id="module-stream-noFollow-step3-line1" />
        <br />
        <FormattedMessage id="module-stream-noFollow-step3-line2" />
      </div>
    ),
  },
];

const EntityFollowingState = () => (
  <EmptyState
    title={<FormattedMessage id="module-stream-noFollowTitle" />}
    description={<FormattedMessage id="module-stream-noFollowDescription" />}
    boardingSteps={followSteps}
  />
);

export default EntityFollowingState;
