import React from 'react';
import Tooltip from 'rc-tooltip';
import { connect } from 'react-redux';
import { FormattedMessage } from '@cluedin/locale';
import { compose, lifecycle } from 'recompose';
import { Widget } from 'uxi/Widget';
import { DivPadding } from 'uxi/Base';
import { Pencil, News, Loading } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';
import { push } from 'connected-react-router';
import { actionsConfiguration, Button } from '@cluedin/components';
import GdprSarShortOverview from '../composites/GdprSarShortOverview';
import GdprEscalate from './Dialogs/GdprEscalate';

import {
  shouldFetchGDPRConfig,
  shouldhideEscalateDialog,
  shouldShowEscalateDialog,
  shouldSubscribeForUserData,
  shouldUnSubscribeForUserData,
} from '../../actions';

const { governance } = actionsConfiguration;

const GdprSarOverviewContainer = (props) => {
  const {
    currentSAR,
    configuration,
    onCloseEscalte,
    showEscalateDialog,
    onShowEscalatePopUp,
    goToRectifyEntity,
    subscribeForData,
    unSubscribeForData,
    subscribingUserData,
    SARSubjectsEntity,
  } = props;

  const thisSARSubscribingUserData = subscribingUserData[currentSAR.Id] || {
    isFetching: false,
  };

  const hasEntityAttached =
    currentSAR.EntityId !== '00000000-0000-0000-0000-000000000000';
  // GdprSarShortOverview

  let escalateButton;
  let subscribeOrUnsubscribeButton;

  if (hasEntityAttached) {
    subscribeOrUnsubscribeButton = currentSAR.Subscribed ? (
      // unsubscribe button
      <Button
        icon={thisSARSubscribingUserData.isFetching ? <Loading /> : <News />}
        isFullWidth
        style={{ marginBottom: '16px' }}
        text={<FormattedMessage id="module-gdpr-unSubscribeCollaborators" />}
        onClick={() => unSubscribeForData(currentSAR)}
        actionConfiguration={governance.gdpr.subjectAccessRequest.edit}
      />
    ) : (
      // subsribe button
      <Button
        icon={thisSARSubscribingUserData.isFetching ? <Loading /> : <News />}
        isFullWidth
        style={{ marginBottom: '16px' }}
        text={<FormattedMessage id="module-gdpr-subscribeCollaborators" />}
        onClick={() => subscribeForData(currentSAR)}
        actionConfiguration={governance.gdpr.subjectAccessRequest.edit}
      />
    );
  }
  if (currentSAR.State > 1) {
    escalateButton = (
      <Button
        isFullWidth
        text={<FormattedMessage id="module-gdpr-escalate" />}
        onClick={() => onShowEscalatePopUp([currentSAR.Id])}
        actionConfiguration={governance.gdpr.subjectAccessRequest.edit}
      />
    );
  } else {
    escalateButton = (
      <Tooltip
        placement="top"
        trigger={['hover', 'click']}
        overlay={
          <FormattedMessage id="module-gdpr-EscalateDisabledExplanation" />
        }
      >
        <div>
          <Button
            disabled
            isFullWidth
            text={<FormattedMessage id="module-gdpr-escalate" />}
            onClick={() => onShowEscalatePopUp([currentSAR.Id])}
            actionConfiguration={governance.gdpr.subjectAccessRequest.edit}
          />
        </div>
      </Tooltip>
    );
  }

  const overview = (
    <DivPadding padding="S">
      <GdprSarShortOverview
        SARSubjectsEntity={SARSubjectsEntity}
        sar={currentSAR}
        collaborators={[]}
        configuration={configuration}
      />
      <GdprEscalate
        onClose={onCloseEscalte}
        sarIds={[currentSAR.Id]}
        show={showEscalateDialog}
      />
      <hr style={{ margin: '16px 0', borderBottom: '1px solid #cecece' }} />
      {hasEntityAttached && (
        <ButtonLink
          icon={<Pencil />}
          isFullWidth
          style={{ marginBottom: '16px' }}
          text={<FormattedMessage id="module-gdpr-shortOverviewRectify" />}
          onClick={() => goToRectifyEntity(currentSAR.EntityId)}
        />
      )}
      {subscribeOrUnsubscribeButton}
      {escalateButton}
    </DivPadding>
  );

  return (
    <Widget
      style={{ background: '#fff' }}
      title={<FormattedMessage id="module-gdpr-overview" />}
    >
      {overview}
    </Widget>
  );
};

GdprSarOverviewContainer.displayName = 'GdprSarOverviewContainer';

const GdprSarOverviewContainerEnhanced = compose(
  lifecycle({
    componentDidMount() {
      const { fetchConfig } = this.props;
      fetchConfig();
    },
  }),
)(GdprSarOverviewContainer);

const stateToProps = ({
  GDPR: {
    configuration,
    isFetchingConfiguration,
    escalateDialog,
    showEscalateDialog,
    subscribingUserData,
  },
}) => ({
  configuration,
  isFetchingConfiguration,
  escalateDialog,
  showEscalateDialog,
  subscribingUserData,
});

const dispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(shouldFetchGDPRConfig()),
  onCloseEscalte: () => {
    dispatch(shouldhideEscalateDialog());
  },
  onShowEscalatePopUp(sarIds) {
    dispatch(shouldShowEscalateDialog(sarIds));
  },
  goToRectifyEntity(entityId) {
    dispatch(push(`/person/${entityId}/props`));
  },
  subscribeForData(sar) {
    dispatch(shouldSubscribeForUserData({ id: sar.Id, guid: sar.EntityId }));
  },
  unSubscribeForData(sar) {
    dispatch(shouldUnSubscribeForUserData({ id: sar.Id, guid: sar.EntityId }));
  },
});

export default connect(
  stateToProps,
  dispatchToProps,
)(GdprSarOverviewContainerEnhanced);
