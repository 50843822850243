import React from 'react';
import { hasFeature } from '../config';
import { FormattedMessage } from '@cluedin/locale';

import {
  Manipulation,
  Code,
  Administrator,
  Consuming,
  Management,
  Connectors,
  Governance,
  Preparation,
  Home,
} from 'uxi/Icons';

const defaultPillars = [
  {
    requiredAdmin: true,
    hideFromMenu: true,
    name: 'me',
    Icon: Home,
    displayName: <FormattedMessage id="mytasks" />,
    path: '/me',
  },
  {
    requiredAdmin: true,
    hideFromMenu: true,
    name: 'inbound',
    Icon: Connectors,
    className: 'test_globalMenu_integration',
    displayName: <FormattedMessage id="inbound" />,
    path: '/admin/inbound',
    crumbPath: '/admin/inbound/integration',
  },
  {
    requiredAdmin: true,
    name: 'preparation',
    Icon: Preparation,
    className: 'test_globalMenu_manipulation test_globalMenu_preparation',
    displayName: <FormattedMessage id="preparation" />,
    path: '/admin/preparation',
  },
  {
    requiredAdmin: true,
    hideFromMenu: !hasFeature('engineRoom'),
    name: 'engineRoom',
    Icon: Manipulation,
    className: 'test_globalMenu_engineRoom test_globalMenu_engineRoom',
    displayName: <FormattedMessage id="engineRoom" />,
    path: '/admin/engine-room',
  },
  {
    requiredAdmin: true,
    name: 'management',
    Icon: Management,
    className: 'test_globalMenu_management',
    displayName: <FormattedMessage id="management" />,
    path: '/admin/management',
  },
  {
    requiredAdmin: true,
    name: 'governance',
    hideFromMenu: true,
    Icon: Governance,
    className: 'test_globalMenu_governance',
    displayName: <FormattedMessage id="governance" />,
    path: '/admin/governance',
    crumbPath: '/admin/governance/gdpr',
  },
  {
    requiredAdmin: true,
    name: 'consume',
    Icon: Consuming,
    className: 'test_globalMenu_consume',
    displayName: <FormattedMessage id="consume" />,
    path: '/admin/consume',
  },
  {
    requiredAdmin: true,
    name: 'development',
    Icon: Code,
    className: 'test_globalMenu_developer',
    displayName: <FormattedMessage id="development" />,
    hideFromMenu: true,
    path: '/admin/dev',
    actions: [
      {
        icon: 'Code',
        name: 'Install Integration',
        link: 'https://documentation.cluedin.net/integration/install-integrations',
      },
      {
        icon: 'Code',
        name: 'Build Integration',
        link: 'https://documentation.cluedin.net/integration/build-integration',
      },
    ],
  },
  {
    requiredAdmin: true,
    name: 'administration',
    Icon: Administrator,
    className: 'test_globalMenu_administration',
    displayName: <FormattedMessage id="administration" />,
    path: '/admin/settings',
  },
];

if (process.env.NODE_ENV !== 'production') {
  defaultPillars.push({
    requiredAdmin: true,
    name: 'testing',
    Icon: Administrator,
    className: 'test_globalMenu_testing',
    displayName: <FormattedMessage id="Testing" />,
    path: '/admin/testing',
  });
}

export default defaultPillars;
