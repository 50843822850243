import React from 'react';
import { RangeInput } from '@cluedin/form';

const QualitySlider = (props) => (
  <RangeInput
    min={0}
    max={10}
    value={parseInt(props.value, 10) || 0}
    onChange={(e, v) => {
      props.onChange(v);
    }}
  />
);

export default QualitySlider;
