import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { Catalog } from './fragments';

const GET_VOCABULARY_KEYS_FOR_KEYS = gql`
  query vocabularyKeyPerKeys($keys: [String]) {
    management {
      vocabularyKeyPerKeys(keys: $keys) {
        ...VocabularyKey
      }
    }
  }
  ${Catalog.fragments.vocabularyKey}
`;

export const useQueryVocabularyKeysForKeys = (keys) => {
  const { data, loading, error } = useQuery(GET_VOCABULARY_KEYS_FOR_KEYS, {
    variables: {
      keys,
    },
  });

  const vokabKeys = get(data, 'management.vocabularyKeyPerKeys', []);

  return [vokabKeys, loading, error];
};

export default {
  useQueryVocabularyKeysForKeys,
};
