import moment from 'moment';
import { get, uniq } from 'lodash';
import { findSource } from './integrationHelper';

const cluedinProcessorString = 'CluedIn('.toLocaleLowerCase();

const getDefaultImage = (entityType) => {
  if (entityType === '/Person') {
    return ''; //image.defaultProfile;
  }
  if (entityType === '/Organization') {
    return ''; //image.defaultLogo;
  }
  return undefined;
};

const extensions = {
  '.doc': 'doc-ico.svg',
  '.jpg': 'jpg-ico.svg',
  '.pptx': 'pptx-ico.svg',
  '.ppt': 'ppt-ico.svg',
  '.docx': 'docx-ico.svg',
  '.xlsx': 'xlsx-ico.svg',
  '.xls': 'xls-ico.svg',
  '.pdf': 'pdf-ico.svg',
  '.csv': 'csv-ico.svg',
  '.pages': 'pages-ico.svg',
  '.numbers': 'numbers-ico.svg',
  '.key': 'keynote-ico.svg',
};

const isCluedInProcessor = (name = '') =>
  name.toLocaleLowerCase().indexOf(cluedinProcessorString) > -1;

/**
 *
 * Change to object properties and try to 'guess it'
 *
 * need to return { displayName, value}
 */
const findFromKey = (properties, key) => {
  const allLikelyCorrespondingKeys = [];
  const allPropertyKeys = Object.keys(properties);
  allPropertyKeys.forEach((k) => {
    const allKeys = k.split('.');
    const lastKey = allKeys.pop();

    if (lastKey.indexOf(key) > -1) {
      allLikelyCorrespondingKeys.push(k);
    }
  });

  const hasCluedIn = allLikelyCorrespondingKeys.find(
    (k) => k.indexOf('cluedin') > -1,
  );

  if (hasCluedIn) {
    return {
      displayName: key,
      value: properties[hasCluedIn],
    };
  }

  if (allLikelyCorrespondingKeys && allLikelyCorrespondingKeys.length > 0) {
    return {
      displayName: key,
      value: properties[allLikelyCorrespondingKeys[0]],
    };
  }

  return {
    displayName: key,
    value: null,
  };
};

export const uniqProviderBasedOnUrisAndProviderCodes = (
  uris = [],
  providers = [],
  integrations = [],
) => {
  const result = [];

  const urisFiltered = (uris || [])
    .filter((uri) => uri.origin || uri.Origin)
    .map((uri) => ({
      ...uri,
      origin: uri.origin || uri.Origin,
    }));

  const compactedUris = urisFiltered || [];
  const compactedUrisWithoutCluedinProcessors = compactedUris.filter(
    (uri) => !isCluedInProcessor(uri.origin),
  );

  compactedUrisWithoutCluedinProcessors.forEach((uri) => {
    result.push({
      name: uri.origin,
      uri: uri.uri || uri.Uri,
      config: findSource(uri.origin, integrations, true),
    });
  });

  uniq(providers)
    .filter((providerCode) => {
      const providerConfigFromProviderCode = findSource(
        providerCode,
        integrations,
        true,
      );
      const isFound = compactedUrisWithoutCluedinProcessors.find((uri) => {
        if (uri.origin.toLowerCase() === providerCode.toLowerCase()) {
          return true;
        }

        const currentProviderFromUri = findSource(
          uri.origin,
          integrations,
          true,
        );
        if (currentProviderFromUri && providerConfigFromProviderCode) {
          return (
            currentProviderFromUri.Name === providerConfigFromProviderCode.Name
          );
        }

        return false;
      });

      return !isFound;
    })
    .forEach((providerCode) => {
      const providerConfigFromProviderCode = findSource(
        providerCode,
        integrations,
        true,
      );

      result.push({
        name: providerCode,
        config: providerConfigFromProviderCode || null,
        uri: '',
      });
    });

  return result;
};

export const getEntityTemporalDataFromGql = (entity = {}) => {
  const { createdDate, modifiedDate, discoveryDate } = entity;

  const createdDateFormatted = createdDate
    ? moment(createdDate).fromNow()
    : null;
  const modifiedDateFormatted = modifiedDate
    ? moment(modifiedDate).fromNow()
    : null;
  const discoveryDateFormatted = discoveryDate
    ? moment(discoveryDate).fromNow()
    : null;

  // last modified 3 months ago by Billy

  const modifiedDateToRender =
    modifiedDateFormatted && `Last modified ${modifiedDateFormatted}`;
  const createdDateToRender =
    createdDateFormatted && `Created ${createdDateFormatted}`;
  const discoveryDateToRender =
    discoveryDateFormatted && `Discovered ${discoveryDateFormatted}`;

  let result;

  if (modifiedDateToRender) {
    result = modifiedDateToRender;
  }

  if (result && createdDateToRender) {
    result = `${result}, ${createdDateToRender.toLowerCase()}`;
    return result;
  }

  if (result) {
    return result;
  }

  return discoveryDateToRender;
};

const figureOutFileExtension = (name, extension) => {
  if (
    extension &&
    extension.original &&
    extensions[extension.original.toLowerCase()]
  ) {
    return extensions[extension.original.toLowerCase()];
  }

  if (name && name.indexOf('.') > -1) {
    const lasType = name.split('.').pop();

    return extensions[`.${lasType}`];
  }

  return undefined;
};

const getFileTypeUrlFromEntity = (entity) => {
  const properties = get(entity, 'data.properties', {});
  const type = findFromKey(properties, 'type');

  return figureOutFileExtension(entity.name, type);
};

export const getLogoFromGql = (entity) => {
  if (entity && entity.previewImage && entity.previewImage.uri) {
    // const logo = addTokenToImage(
    //   entity.previewImage.uri,
    //   getCurrentToken(),
    //   true
    // );
    // TODO
    const logo = '';

    return {
      small: logo,
      large: logo,
    };
  }

  const typeURL = getFileTypeUrlFromEntity(entity);
  if (typeURL) {
    return {
      small: typeURL,
      large: typeURL,
    };
  }

  const defaultImage = getDefaultImage(entity.entityType);

  return {
    small: defaultImage,
    large: defaultImage,
  };
};
