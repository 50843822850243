import { createContext } from 'react';
import { UploadContext } from '../../../types';

export const UploadFileProgressContext = createContext<UploadContext>({
  fileUploadProgress: [],
  resetFiles: () => {},
  markAsViewed: () => {},
  errorFiles: [],
  completedItems: [],
  allFinished: false,
});

UploadFileProgressContext.displayName = 'UploadFileProgressContext';
