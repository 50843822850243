import React from 'react';
import OauthCallbackContainer from '../containers/OauthCallbackContainer';

const OauthCallbackPage = ({
  match: {
    params: { name },
  },
  location: { search },
}) => {
  const params = new URLSearchParams(search);
  const token = params.get('token');

  if (!name) {
    console.error(
      'you are in the callback page of adding an integration and the url does not have an integration name',
    );
  }
  return (
    <div>
      <OauthCallbackContainer name={name} token={token} />
    </div>
  );
};

export default OauthCallbackPage;
