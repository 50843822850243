import { useEffect, memo } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

import { UserMenu } from './UserMenu';
import { ServerStatus } from './ServerStatus';
import { Notifications } from './Notifications';
import { useServerStatus } from './ServerStatus/useServerStatus';
import { HelpCenter } from './HelpCenter';
import { OrganizationFeatureFlag } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';
import { useNotifications } from '../../../../notifications/hooks/useNotifications';
import { useUnreadNotificationsCount } from '../../../../notifications/hooks/useUnreadNotificationsCount';
import { UploadFileProgress } from '../../../../uploadFile/components/container/UploadFileProgress';
import { useMarkAllAsRead } from '../../../../notifications/hooks/useMarkAllAsRead';

const TopMenuWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TopMenu = memo(({ logout, isUsingSSO, currentUser }) => {
  const theme = useTheme();
  const [statuses, statusLoading, statusError, globalStatus] =
    useServerStatus();

  const [
    notifications,
    loadingNotifications,
    errorsNotifications,
    { subscribeToMore, fetchMore },
    showMore,
  ] = useNotifications();

  useEffect(() => {
    subscribeToMore();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [notificationQuantity, loading, error, refetch] =
    useUnreadNotificationsCount();
  const [markNotificationsAsRead] = useMarkAllAsRead({
    refetchUnread: refetch,
  });

  const notificationsOptions = {
    showMore,
    notifications,
    errorsNotifications,
    notificationQuantity,
    loadingNotifications,
    markNotificationsAsRead,
    loadMoreNotifications: fetchMore,
  };

  return (
    <TopMenuWrapper>
      <UploadFileProgress />
      {/*
       *    == Disabling this feature due to performance issues
       *    == And seems like it's broken anyway =)
       */}
      {/* <ProviderStatus theme={theme} isCrawling={isCrawling} /> */}
      <ServerStatus
        theme={theme}
        statuses={statuses}
        statusLoading={statusLoading}
        statusError={statusError}
        globalStatus={globalStatus}
      />
      <Notifications
        theme={theme}
        notificationsOptions={notificationsOptions}
      />

      {/*
       *    == Disabling this feature.
       */}
      <OrganizationFeatureFlag
        MainFeature={null}
        ExperimentalFeature={<HelpCenter theme={theme} />}
        featureName={'Inline Help'}
      />

      <UserMenu
        theme={theme}
        logout={logout}
        currentUser={currentUser}
        entity={currentUser?.entity}
        showSettingsLink={!isUsingSSO}
      />
    </TopMenuWrapper>
  );
});
