import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_EXPLAIN_LOG_BEST_GUESS_SOURCE = gql`
  query getBestGuessSource($id: ID) {
    virtualization {
      bestGuessSource(id: $id) {
        displayName
        image
        iconName
      }
    }
  }
`;

export const useExplainLogBestGuessSource = (id) => {
  const { data, loading, error } = useQuery(GET_EXPLAIN_LOG_BEST_GUESS_SOURCE, {
    variables: { id },
  });

  const bestGuessSource = get(data, 'virtualization.bestGuessSource');

  return [bestGuessSource, loading, error];
};
