import React, { Component } from 'react';
import Alert from 'uxi/Alert';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import {
  PrimaryButton,
  CancelButton,
  TextField,
  Modal,
  FormDecorator,
} from '@cluedin/form';

import { clientId } from '../../../../../config';
import { isEmail } from '../../../../core/validation';
import {
  checkIfEmailExistAndSendInvitation,
  shouldCloseInviteUserDialog,
} from '../../../actions';

class InviteUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      error: false,
      email: '',
    };
    this.handleClose = this.handleClose.bind(this);
    this.onFormUpdateHandler = this.onFormUpdateHandler.bind(this);
    this.handleAddTeamMember = this.handleAddTeamMember.bind(this);
  }

  onFormUpdateHandler(e, value) {
    if (!value || !isEmail(value)) {
      this.setState({ error: true, email: value });
    } else {
      this.setState({ error: false, email: value });
    }
  }

  handleClose() {
    const { close } = this.props;
    this.setState({
      email: '',
      showError: false,
      error: false,
    });

    close();
  }

  handleAddTeamMember(e) {
    const { sendEmail } = this.props;

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (this.state.error) {
      this.setState({
        showError: true,
      });
    } else if (!this.state.email) {
      this.setState({
        showError: true,
      });
    } else {
      this.setState({
        showError: false,
      });

      sendEmail({
        email: this.state.email,
        clientId,
      });
    }
  }

  render() {
    const { showError } = this.state;
    const {
      inviteUsers,
      isOpenInviteUserDialog,
      emailAlreadyTakenForInviteUsers,
      inviteUserSomethingIsWrong,
      inviteUserFeedValueEmail,
      inviteUserFeedValueName,
      intl,
    } = this.props;

    let alertContent;
    let disabledButton;
    let inviteFriendsContent;

    if (showError) {
      alertContent = (
        <div style={{ marginBottom: '15px' }}>
          <Alert type="error">
            <FormattedMessage id="InviteUserDialog.EnterValidEmail" />
          </Alert>
        </div>
      );
    } else {
      if (emailAlreadyTakenForInviteUsers) {
        alertContent = (
          <div style={{ marginBottom: '15px' }}>
            <Alert type="error">
              <FormattedMessage
                id="InviteUserDialog.EmailAlreadyInvited"
                values={{ email: emailAlreadyTakenForInviteUsers }}
              />
            </Alert>
          </div>
        );
      }

      if (inviteUserSomethingIsWrong) {
        alertContent = (
          <div style={{ marginBottom: '15px' }}>
            <Alert type="error">
              <FormattedMessage id="InviteUserDialog.SomethingWrong" />
            </Alert>
          </div>
        );
      }
    }

    const isLoadingCurrentInvite = inviteUsers[this.state.email];

    if (isLoadingCurrentInvite && isLoadingCurrentInvite.isFetching) {
      disabledButton = true;
    }

    if (inviteUserFeedValueName && inviteUserFeedValueEmail) {
      inviteFriendsContent = (
        <Alert>
          <FormattedMessage
            id="InviteUserDialog.AboutToInvite"
            values={{ inviteUserFeedValueName }}
          />
        </Alert>
      );
    }

    if (inviteUserFeedValueName && !inviteUserFeedValueEmail) {
      inviteFriendsContent = (
        <Alert type="warning">
          <FormattedMessage
            id="InviteUserDialog.AboutToInviteWithoutEmail"
            values={{
              name: (
                <span style={{ textDecoration: 'underline' }}>
                  {inviteUserFeedValueName}
                </span>
              ),
            }}
          />
        </Alert>
      );
    }

    return (
      <Modal
        height={'250px'}
        onClose={this.handleClose}
        isOpen={isOpenInviteUserDialog}
        className="__test_inviteUserDialog"
        title={<FormattedMessage id="InviteUserDialog.AddTeamMember" />}
        footer={{
          content: (
            <>
              <PrimaryButton
                loading={disabledButton}
                text={<FormattedMessage id="InviteUserDialog.AddTeamMember" />}
                disabled={disabledButton}
                onClick={this.handleAddTeamMember}
              />
              <CancelButton
                style={{ marginLeft: '16px' }}
                onClick={this.handleClose}
                text={<FormattedMessage id="InviteUserDialog.Cancel" />}
              />
            </>
          ),
        }}
      >
        {alertContent}
        {inviteFriendsContent}

        <div style={{ marginTop: '15px' }}>
          <form onSubmit={this.handleAddTeamMember}>
            <FormDecorator
              label={<FormattedMessage id="InviteUserDialog.EmailAddress" />}
            >
              <TextField
                hasError={showError}
                errorMessage={showError}
                value={this.state.email}
                placeholder={intl.formatMessage({
                  id: 'module-userInvitation-userInvitationEmail',
                })}
                onChange={this.onFormUpdateHandler}
              />
            </FormDecorator>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapToStateProps = ({
  userModule: {
    inviteUsers,
    isOpenInviteUserDialog,
    emailAlreadyTakenForInviteUsers,
    inviteUserSomethingIsWrong,
    inviteUserFeedValueEmail,
    inviteUserFeedValueName,
  },
  user: { currentUser },
}) => ({
  inviteUsers,
  isOpenInviteUserDialog,
  emailAlreadyTakenForInviteUsers,
  inviteUserSomethingIsWrong,
  inviteUserFeedValueEmail,
  inviteUserFeedValueName,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  close() {
    dispatch(shouldCloseInviteUserDialog());
  },
  sendEmail(payload) {
    dispatch(checkIfEmailExistAndSendInvitation(payload));
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(injectIntl(InviteUserDialog));
