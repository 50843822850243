import styled from 'styled-components';

export const EntitySourcesWrapper = styled.div`
  border: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  width: 100%;
`;

export const EntitySourcesArrowWrapper = styled.div``;

export const EntitySourceTitle = styled.div`
  align-items: center;
  border-bottom: ${({ isExpanded }) =>
    !isExpanded ? null : '1px solid #ececec'};
  color: #2f2f32;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  height: 100%;
  padding: 12px;

  & svg {
    margin-right: 6px;
  }
`;
