import PropTypes from 'prop-types';

import AdminUserActiveSettingsContainer from '../containers/AdminUserActiveSettingsContainer';

const AdminUserRolesPage = ({ user = {}, currentUser = {} }) => (
  <div
    style={{
      background: '#fff',
      height: 'calc(100vh - 220px)',
    }}
  >
    <AdminUserActiveSettingsContainer user={user} currentUser={currentUser} />
  </div>
);

AdminUserRolesPage.displayName = 'AdminUserRolesPage';

AdminUserRolesPage.propTypes = {
  user: PropTypes.object,
  currentUser: PropTypes.object,
};

export default AdminUserRolesPage;
