import React from 'react';
import PageLoader from '../../composites/PageLoader';

// improve page and in-line loader to be more effective visually.
const EntityLoading = (placement) => {
  if (placement === 'page') {
    return () => <PageLoader />;
  }

  if (placement === 'inline') {
    return () => <PageLoader />;
  }

  return () => <PageLoader />;
};

export default EntityLoading;
