import styled from 'styled-components';

export const GroupedEntitiesPanelHeaderWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px 0;
  width: 100%;
`;

export const GroupedEntitiesPanelHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 4px;
`;
