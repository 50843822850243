import { FC, useEffect } from 'react';

import { useGetEdgeProperties } from '../../../../entityRelationsGraph/hooks/useGetEdgeProperties';
import { EntityRelationEdgePropertiesWrapper } from './EntityRelationEdgePropertiesWrapper';
import { EntityRelationEdgePropertiesWithoutData } from './EntityRelationEdgePropertiesWithoutData';
import { EntityRelationEdgePropertiesEntry } from './EntityRelationEdgePropertiesEntry';
import {
  SelectedConnectingDirectionalEdge,
  SelectedConnectingEdge,
  GetEdgeProperties,
  GetEdgePropertiesHook,
} from '../../types';

type EntityRelationEdgePropertiesProps = {
  edgeDirection: string;
  edgeFrom: SelectedConnectingDirectionalEdge;
  edgeTo: SelectedConnectingDirectionalEdge;
  edgeType: string;
  entityId: string;
  selectedEdge: SelectedConnectingEdge;
};

export const EntityRelationEdgeProperties: FC<
  EntityRelationEdgePropertiesProps
> = ({ edgeType, entityId, edgeDirection, edgeFrom, edgeTo, selectedEdge }) => {
  const [
    getEdgeProperties,
    {
      data: edgePropertiesPanelData,
      loading: edgePropertiesPanelLoading,
      error: edgePropertiesPanelError,
    },
  ] = useGetEdgeProperties() as [GetEdgePropertiesHook, GetEdgeProperties];

  useEffect(() => {
    if (entityId && edgeType && edgeDirection) {
      getEdgeProperties(entityId, edgeType, edgeDirection);
    }
  }, [entityId, edgeType, edgeDirection]);

  if (edgePropertiesPanelLoading) {
    return null;
  }

  if (!edgePropertiesPanelData || edgePropertiesPanelData?.length === 0) {
    return (
      <EntityRelationEdgePropertiesWrapper>
        <EntityRelationEdgePropertiesWithoutData edgeType={edgeType} />
      </EntityRelationEdgePropertiesWrapper>
    );
  }

  return (
    <EntityRelationEdgePropertiesWrapper>
      {edgePropertiesPanelData?.map((edgeGroup, index) => (
        <EntityRelationEdgePropertiesEntry
          key={index}
          edgeGroup={edgeGroup}
          edgePropertiesPanelLoading={edgePropertiesPanelLoading}
          selectedEdge={selectedEdge}
          edgeFrom={edgeFrom}
          edgeTo={edgeTo}
          edgeDirection={edgeDirection}
          edgeType={edgeType}
        />
      ))}
    </EntityRelationEdgePropertiesWrapper>
  );
};
