import { useEffect } from 'react';
import _ from 'lodash';

import { useGetEntityEdges } from '../../hooks/useGetEntityEdges';
import GroupedEntitiesPanelContainer from '../GroupedEntitiesPanelContainer/GroupedEntitiesPanelContainer';

const GroupedEntitiesPanelAPIContainer = ({
  entityId,
  selectedNode,
  setSelectedNode,
  setSelectedEdge,
  edgeType,
  isGroupedEntitiesPanelOpen,
  setIsGroupedEntitiesPanelOpen,
  setIsEdgePropertiesPanelOpen,
}) => {
  const [
    getEntitiesByEdgeType,
    {
      data: groupedEntitiesPanelData,
      loading: groupedEntitiesPanelLoading,
      error: groupedEntitiesPanelError,
      fetchMore: groupedEntitiesPanelFetchMore,
    },
  ] = useGetEntityEdges();

  let edgeDirection;
  if (selectedNode?.to) {
    if (selectedNode?.to === entityId) {
      edgeDirection = 'incoming';
    } else {
      edgeDirection = 'outgoing';
    }
  }
  if (selectedNode?.edgeDirection) {
    edgeDirection = selectedNode?.edgeDirection;
  }

  const edgeTypeToUse = _.isString(selectedNode?.edgeType)
    ? selectedNode?.edgeType
    : edgeType;

  const isShadowEntity = !!selectedNode?.isShadowEntity;

  useEffect(() => {
    if (entityId && edgeTypeToUse && edgeDirection) {
      getEntitiesByEdgeType(
        entityId,
        edgeTypeToUse,
        edgeDirection,
        isShadowEntity,
      );
    }
  }, [entityId, edgeTypeToUse, edgeDirection, isShadowEntity]);

  return (
    <>
      {groupedEntitiesPanelData && (
        <GroupedEntitiesPanelContainer
          groupedEntitiesPanelData={groupedEntitiesPanelData}
          groupedEntitiesPanelLoading={groupedEntitiesPanelLoading}
          groupedEntitiesPanelError={groupedEntitiesPanelError}
          isGroupedEntitiesPanelOpen={isGroupedEntitiesPanelOpen}
          setIsGroupedEntitiesPanelOpen={setIsGroupedEntitiesPanelOpen}
          groupedEntitiesPanelFetchMore={() =>
            groupedEntitiesPanelFetchMore(
              entityId,
              edgeTypeToUse,
              edgeDirection,
              isShadowEntity,
            )
          }
          edgeType={edgeTypeToUse}
          setSelectedNode={setSelectedNode}
          setSelectedEdge={setSelectedEdge}
          setIsEdgePropertiesPanelOpen={setIsEdgePropertiesPanelOpen}
        />
      )}
    </>
  );
};

export default GroupedEntitiesPanelAPIContainer;
