import { FC, useEffect } from 'react';
import { PanelHeader } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import get from 'lodash/get';

import { EntityGroupedRelationInfoWrapper } from './EntityGroupedRelationInfoWrapper';
import { EntityGroupedRelationPanelDetail } from './EntityGroupedRelationPanelDetail';
import { EntityGroupedRelationList } from './EntityGroupedRelationList';
import { useGetEntityEdges } from '../../../entityRelationsGraph/hooks/useGetEntityEdges';
import PageLoader from '../../../core/components/composites/PageLoader';
import {
  GetEntitiesByEdgeType,
  GroupedRelationHook,
  GroupedNode,
} from '../types';
import { EntityGroupedRelationFooter } from './EntityGroupedRelationFooter';

type EntityGroupedRelationInfoProps = {
  show: boolean;
  onClose: () => void;
  groupedNode: GroupedNode;
  entityId: string;
  edgeDirection: string;
};

export const EntityGroupedRelationInfo: FC<EntityGroupedRelationInfoProps> = ({
  show,
  onClose,
  groupedNode,
  entityId,
}) => {
  const [
    getEntitiesByEdgeType,
    {
      data: groupedRelationData,
      loading: groupedRelationDataLoading,
      error: groupedRelationDataError,
      fetchMore: groupedRelationDataFetchMore,
    },
  ] = useGetEntityEdges() as [GetEntitiesByEdgeType, GroupedRelationHook];

  const edgeType = groupedNode?.relatedGroupedEdge?.label;
  const edgeDirection = groupedNode?.edgeDirection;
  const isShadowEntity = !!groupedNode?.groupNode?.isShadowEntity;

  useEffect(() => {
    if (entityId && edgeType && groupedNode) {
      getEntitiesByEdgeType(entityId, edgeType, edgeDirection, isShadowEntity);
    }
  }, [entityId, edgeType, edgeDirection, isShadowEntity]);

  const incomingEdgesData = get(
    groupedRelationData,
    'data.entity.edges.incoming.entries',
  );
  const incomingDataCount =
    get(groupedRelationData, 'data.entity.edges.incoming.totalResults') ?? 0;
  const outgoingEdgesData = get(
    groupedRelationData,
    'data.entity.edges.outgoing.entries',
  );
  const outgoingDataCount =
    get(groupedRelationData, 'data.entity.edges.outgoing.totalResults') ?? 0;

  return (
    <>
      <PanelHeader
        style={{
          fontSize: '16px',
          padding: '0 16px',
        }}
        hasClose
        title={
          <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesPanelTitle" />
        }
        onClose={onClose}
      />
      <EntityGroupedRelationInfoWrapper>
        {groupedRelationDataLoading ? (
          <PageLoader />
        ) : (
          <>
            {incomingEdgesData && (
              <>
                <EntityGroupedRelationPanelDetail
                  direction="incoming"
                  edgeTypeToUse={edgeType}
                  dataCount={incomingDataCount}
                />

                <EntityGroupedRelationList
                  direction="incoming"
                  totalDataCount={incomingDataCount}
                  groupedRelationData={incomingEdgesData}
                  groupedRelationDataLoading={groupedRelationDataLoading}
                  groupedRelationDataError={groupedRelationDataError}
                  groupedRelationDataFetchMore={() =>
                    groupedRelationDataFetchMore(
                      entityId,
                      edgeType,
                      edgeDirection,
                      isShadowEntity,
                    )
                  }
                />
              </>
            )}

            {outgoingEdgesData && (
              <>
                <EntityGroupedRelationPanelDetail
                  direction="outgoing"
                  edgeTypeToUse={edgeType}
                  dataCount={outgoingDataCount}
                />

                <EntityGroupedRelationList
                  direction="outgoing"
                  totalDataCount={outgoingDataCount}
                  groupedRelationData={outgoingEdgesData}
                  groupedRelationDataLoading={groupedRelationDataLoading}
                  groupedRelationDataError={groupedRelationDataError}
                  groupedRelationDataFetchMore={() =>
                    groupedRelationDataFetchMore(
                      entityId,
                      edgeType,
                      edgeDirection,
                      isShadowEntity,
                    )
                  }
                />
              </>
            )}
          </>
        )}

        <EntityGroupedRelationFooter onClose={onClose} />
      </EntityGroupedRelationInfoWrapper>
    </>
  );
};
