import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';

// eslint-disable-next-line no-useless-escape
const escapeRegExp = (str) =>
  str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');

export const replaceAll = (str, find, replace) =>
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

export const hasStartAsLastCharacter = (str) => str.slice(-1) === '*';

export const hasFirstCharacter = (str, char) => str && str[0] === char;

export const removeLastCharacter = (str) => str.substring(0, str.length - 1);

export const removeFirstCharacter = (str) => str.substring(1);
export const removeDuplicate = (collection, keyname) => {
  const output = [];
  const keys = [];

  collection.forEach((item) => {
    const key = item[keyname];
    if (keys.indexOf(key) === -1) {
      keys.push(key);
      output.push(item);
    }
  });

  return output;
};

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatDisplayName = (name) =>
  capitalizeFirstLetter(
    name.replace(/[-_]/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
  );

export const createPropertyDisplayName = (p) => {
  const nameParts = p.split('.');
  let name = nameParts[nameParts.length - 1];

  if (name && name.indexOf(':') > -1) {
    name = name.split(':')[1];
  }

  if (!name) {
    return p;
  }

  if (name.indexOf('property-') > -1 || name.indexOf('Property-') > -1) {
    return name.replace(/property-/i, '');
  }

  return name;
};

export const cleanAndCapitalized = (str) => {
  return capitalizeFirstLetter(str.replace(/_/g, ' ').toLowerCase());
};

export const escapeRegex = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const createTestId = (str = '') => {
  const noSpace = replaceAll(str, ' ', '');
  return noSpace.toLowerCase();
};

export default {
  escapeRegex,
  cleanAndCapitalized,
  removeDuplicate,
  formatDisplayName,
  createPropertyDisplayName,
  replaceAll,
  removeFirstCharacter,
  hasFirstCharacter,
  hasStartAsLastCharacter,
  removeLastCharacter,
  template,
  createTestId,
  getStringAndType: (str) => {
    let ext = '';
    let result = '';

    if (!str) {
      return {
        str: result,
        ext,
      };
    }

    const splitWord = str.split('.');

    if (splitWord.length > 1) {
      ext = splitWord.pop();
      result = splitWord.join('.');
    } else {
      result = str;
    }

    return {
      ext,
      str: result,
    };
  },
};
