import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'management.hierarchies': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"HierarchyListPage" */
        './components/pages/HierarchyListPage'
      ),
    ),
  },
  {
    path: '/detail/:id',
    claims: {
      'management.hierarchies': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"HierarchyDetailPage" */
        './components/pages/HierarchyDetailPage'
      ),
    ),
  },
];
