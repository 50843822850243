import { createFileTypeViewModel } from '../../../entityViewModel/helpers/fileType';

const findFromKey = (properties, key) =>
  properties.find((prop) => prop && prop.key === key);

const extensions = {
  '.doc': 'https://s3.eu-central-1.amazonaws.com/cluedintest/doc.png',
  '.jpg': 'https://s3.eu-central-1.amazonaws.com/cluedintest/jpg.png',
  '.pptx': 'https://s3.eu-central-1.amazonaws.com/cluedintest/pptx.png',
  '.ppt': 'https://s3.eu-central-1.amazonaws.com/cluedintest/ppt.png',
  '.docx': 'https://s3.eu-central-1.amazonaws.com/cluedintest/docx.png',
  '.xlsx': 'https://s3.eu-central-1.amazonaws.com/cluedintest/xlsx.png',
  '.xls': 'https://s3.eu-central-1.amazonaws.com/cluedintest/xls.png',
  '.pdf': 'https://s3.eu-central-1.amazonaws.com/cluedintest/pdf.png',
};

const figureOutFileExtension = (name, extension) => {
  if (
    extension &&
    extension.original &&
    extensions[extension.original.toLowerCase()]
  ) {
    return extensions[extension.original.toLowerCase()];
  }

  if (name && name.indexOf('.') > -1) {
    const lasType = name.split('.').pop();

    return extensions[`.${lasType}`];
  }

  return null;
};

export const hasLastChangedBy = (entity) =>
  entity.data ? entity.data.lastChangedBy : entity.lastChangedBy;

export const getModifiedDatFormatted = (entity) =>
  entity.data
    ? entity.data.modifiedDateFormatted
    : entity.modifiedDateFormatted;

export const getLastChangedBy = (entity) => hasLastChangedBy(entity);

export function getFileTypeUrlFromEntity(entity = {}) {
  const properties =
    entity.data && entity.data.properties
      ? entity.data.properties
      : entity.properties;

  if (!properties) {
    return null;
  }

  if (Array.isArray(properties)) {
    const type = findFromKey(properties, 'type');
    const fileTypeUrl = figureOutFileExtension(entity.name, type);

    return fileTypeUrl;
  }

  const typeViewModel = createFileTypeViewModel(entity);
  if (typeViewModel && typeViewModel.fileType) {
    const fileTypeUrl = figureOutFileExtension(
      entity.name,
      typeViewModel.fileType,
    );

    return fileTypeUrl;
  }

  return null;
}

export default {
  getFileTypeUrlFromEntity,
};
