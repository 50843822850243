import { registerEntityWidget } from '../../registry';
import EntityOrganizationHeaderV2 from './EntityOrganizationHeaderV2';

registerEntityWidget('entityOrganizationHeaderV2', {
  name: 'entityOrganizationHeaderV2',
  displayName: 'Organization Header',
  description: 'Display a specific header for Organization',
  noHeader: true,
  view: EntityOrganizationHeaderV2,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
