import { getLogo, getIcon, getLogoFromGql } from './logo';
import { hasEntityConfigSetup } from '../../wms/helpers/widgetHelper';
import { getEntityTemporalDataFromGql, getEntityTemporalData } from './entity';
import { uniqProviderBasedOnUrisAndProviderCodes } from '../../entity/selectors';

export const createSearchResultViewModelFromGql = (
  entity = {},
  entityConfigurations = [],
) => {
  const {
    id,
    name,
    entityType,
    description,
    uris,
    providerOrigins,
    properties,
    createdDate: originalCreatedDate,
  } = entity;

  const logo = getLogoFromGql(entity);
  const icon = getIcon(entity, entityConfigurations);
  const isEntityConfigured = hasEntityConfigSetup(
    entityType,
    entityConfigurations,
  );
  const createdDate = getEntityTemporalDataFromGql(entity);
  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    uris,
    providerOrigins || [],
  );
  const numberProperties = Object.keys(properties || {}).length || 1;

  return {
    entityId: id,
    entityType,
    name,
    description: description || 'no snippet',
    logo,
    icon,
    createdDate,
    providers,
    isEntityConfigured,
    created: originalCreatedDate,
    properties,
    numberProperties: numberProperties - 1, // without attribute-type
  };
};

export const createSearchResultViewModel = (
  entity = {},
  entityConfigurations = [],
) => {
  const {
    id,
    name,
    showDescription,
    providers: providersProp,
    data: {
      createdDate: originalCreatedDate,
      description: descriptionProp,
      uris,
      properties: propertiesProp,
    },
  } = entity;

  const description = showDescription ? descriptionProp : 'no snippet';
  const entityTypeToUse = entity && entity.data ? entity.data.entityType : '';
  const numberOfKeys = Object.keys(propertiesProp || {}).length || 1;
  const logo = getLogo(entity);
  const icon = getIcon(entity, entityConfigurations);
  const isEntityConfigured = hasEntityConfigSetup(
    entityTypeToUse,
    entityConfigurations,
  );
  const createdDate = getEntityTemporalData(entity);
  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    uris,
    providersProp,
  );
  // This is attribute-type props
  const numberProperties = numberOfKeys - 1;
  return {
    entityId: id,
    entityType: entityTypeToUse,
    name,
    description,
    logo,
    icon,
    providers,
    createdDate,
    isEntityConfigured,
    numberProperties,
    created: originalCreatedDate,
    properties: propertiesProp,
  };
};

export default {
  createSearchResultViewModel,
  createSearchResultViewModelFromGql,
};
