import styled from 'styled-components';

export const RootEntityHeaderCaption = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  height: auto;
  justify-content: center;
  padding-bottom: 10px;
  width: auto;
`;
