import { registerIcons } from '@fluentui/react/lib/Styling';
import { GqlErrorMessages } from '@cluedin/form';
import { ThemeProvider as BaseThemeProvider } from '@cluedin/theme';
import {
  AddIcon,
  SaveIcon,
  EditIcon,
  MoreVerticalIcon,
  ResetIcon,
  RefreshIcon,
  MoreIcon,
  InfoIcon,
  CalendarIcon,
  UndoIcon,
  RedoIcon,
  SearchIcon,
  CancelIcon,
  UploadIcon,
  DeleteIcon,
  AcceptIcon,
  SettingsIcon,
  CheckMarkIcon,
  FullScreenIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  BackToWindowIcon,
  ChevronUpMedIcon,
  ChevronDownMedIcon,
  ChevronFold10Icon,
  ChevronUnfold10Icon,
  DependencyAddIcon,
  DocumentSearchIcon,
  DoubleChevronLeftMedIcon,
  NavigateExternalInlineIcon,
  DoubleChevronLeftMedMirroredIcon,
  PauseIcon,
  OpenFileIcon,
  WarningIcon,
  ClearIcon,
  ForwardIcon,
  BackIcon,
  RotateIcon,
  PlayIcon,
  TextDocumentIcon,
  UpIcon,
  DownIcon,
  ErrorIcon,
  ErrorBadgeIcon,
} from '@fluentui/react-icons-mdl2';

import { useOrganziation } from '../../modules/administration/hooks/useOrganziation';
import { useAppContext } from '../../shared/context/appContext';

import { AtomsThemeProvider } from './AtomsThemeProvider';
import DynamicUXIThemeProvider from './DynamicUXIThemeProvider';
import { GlobalAppStylesProviderFromTheme } from './GlobalAppStylesProviderFromTheme';
import { useOrgNameAvailable } from '../../modules/auth/hooks/useOrgNameAvailable';

// Add icons
registerIcons({
  icons: {
    Add: <AddIcon />,
    Save: <SaveIcon />,
    More: <MoreIcon />,
    Edit: <EditIcon />,
    Info: <InfoIcon />,
    Undo: <UndoIcon />,
    Redo: <RedoIcon />,
    Reset: <ResetIcon />,
    Refresh: <RefreshIcon />,
    Upload: <UploadIcon />,
    Cancel: <CancelIcon />,
    Delete: <DeleteIcon />,
    Accept: <AcceptIcon />,
    Search: <SearchIcon />,
    Settings: <SettingsIcon />,
    CheckMark: <CheckMarkIcon />,
    FullScreen: <FullScreenIcon />,
    ChevronDown: <ChevronDownIcon />,
    ChevronLeft: <ChevronLeftIcon />,
    ChevronFold: <ChevronFold10Icon />,
    ChevronUnfold: <ChevronUnfold10Icon />,
    Replace: <DependencyAddIcon />,
    BackToWindow: <BackToWindowIcon />,
    ChevronUpMed: <ChevronUpMedIcon />,
    ChevronRight: <ChevronRightIcon />,
    DocumentSearch: <DocumentSearchIcon />,
    ChevronDownMed: <ChevronDownMedIcon />,
    DoubleChevronLeftMed: <DoubleChevronLeftMedIcon />,
    NavigateExternalInline: <NavigateExternalInlineIcon />,
    DoubleChevronLeftMedMirrored: <DoubleChevronLeftMedMirroredIcon />,
    Pause: <PauseIcon />,
    OpenFile: <OpenFileIcon />,
    Warning: <WarningIcon />,
    Clear: <ClearIcon />,
    ArrowOutgoing: <ForwardIcon />,
    ArrowIncoming: <BackIcon />,
    ArrowUp: <UpIcon />,
    ArrowDown: <DownIcon />,
    Calendar: <CalendarIcon />,
    Rotate: <RotateIcon />,
    MoreVertical: <MoreVerticalIcon />,
    TextDocument: <TextDocumentIcon />,
    Error: <ErrorIcon />,
    ErrorBadge: <ErrorBadgeIcon />,
    Play: <PlayIcon />,
  },
});

export const ThemeProvider = ({ children }) => {
  const {
    me: { client },
  } = useAppContext();
  const [org, loading, error] = useOrganziation(!client);
  const [teamDomainInfo, loadingOrgInfo, err] = useOrgNameAvailable(!!client);

  if (loading || loadingOrgInfo) return null;

  const theme = org?.Theme || teamDomainInfo?.loginTheme?.theme || 'default';
  const customThemeConfig =
    org?.PrimaryColor || teamDomainInfo?.loginTheme?.primaryColor;

  return (
    <>
      {client && <GqlErrorMessages error={error || err} />}

      <BaseThemeProvider theme={theme} defaultCustomPrimary={customThemeConfig}>
        <DynamicUXIThemeProvider>
          <AtomsThemeProvider>
            <GlobalAppStylesProviderFromTheme>
              {children}
            </GlobalAppStylesProviderFromTheme>
          </AtomsThemeProvider>
        </DynamicUXIThemeProvider>
      </BaseThemeProvider>
    </>
  );
};
