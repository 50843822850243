import React from 'react';
import moment from 'moment';
import { Checkbox, DateInput } from 'uxi/Input';
import Link from './Link';

const required = (value) => (value ? undefined : 'Required');

/* eslint-disable consistent-return */
export const getAppropriateComponent = (propertyValueWithSchema) => {
  const value = (propertyValueWithSchema || {}).value || '';

  if (propertyValueWithSchema.schema.DataType === 'Guid') {
    return;
  }

  if (propertyValueWithSchema.schema.DataType === 'Email') {
    const emailLink = `mailto:${value}`;
    return <Link to={emailLink}>{value}</Link>;
  }

  if (propertyValueWithSchema.schema.DataType === 'Duration') {
    return `${value} minutes`;
  }

  if (propertyValueWithSchema.schema.DataType === 'DateTime') {
    return moment(value).format('LL');
  }

  if (propertyValueWithSchema.schema.DataType === 'Boolean') {
    return value.toLowerCase() === 'true' ? 'Yes' : 'No';
  }

  if (propertyValueWithSchema.schema.DataType === 'PhoneNumber') {
    const phoneLink = `tel:${value}`;
    return (
      <a rel="noopener noreferrer" href={phoneLink} target="_blank">
        {value}
      </a>
    );
  }

  if (propertyValueWithSchema.schema.DataType === 'Uri') {
    /**
     * can back end provide protocol ?
     */
    const finalHREF = (value || '').match(/^https?:\/\//)
      ? value
      : `https://${value}`;

    return (
      <a href={finalHREF} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    );
  }

  return value;
};

export const getAppropraiteComponentFormFromSchema = (schema) => {
  if (schema.DataType === 'Boolean') {
    return {
      Component: (props) => <Checkbox checked={props.value} {...props} />,
      validators: null,
    };
  }

  if (schema.DataType === 'DateTime' || schema.DataType === 'Date') {
    return {
      Component: (props) => {
        const { value, ...restOfProps } = props;

        return <DateInput {...restOfProps} defaultValue={props.value} />;
      },
      validators: null,
    };
  }

  return {
    Component: (props) => (
      <input
        style={Object.assign(props.style || {}, {
          width: '100%',
          boxSizing: 'border-box',
          padding: '10px 5px',
        })}
        type="text"
        {...props}
      />
    ),
    validators: [required],
  };
};

export const getAppropriateComponentFromSchema = (schema) => {
  if (schema.DataType === 'Guid') {
    return;
  }

  if (schema.DataType === 'Email') {
    return (value) => {
      const emailLink = `mailto:${value}`;
      return <a href={emailLink}>{value}</a>;
    };
  }

  if (schema.DataType === 'Duration') {
    return (value) => `${value || 0} minutes`;
  }

  if (schema.DataType === 'DateTime') {
    return (value) => moment(value || '').format('LL');
  }

  if (schema.DataType === 'Boolean') {
    return (value) => {
      if (value.toString().toLowerCase() === 'true') {
        return 'Yes';
      }
      return 'No';
    };
  }

  if (schema.DataType === 'PhoneNumber') {
    return (value) => {
      const phoneLink = `tel:${value}`;
      return (
        <a rel="noopener noreferrer" href={phoneLink} target="_blank">
          {value}
        </a>
      );
    };
  }

  if (schema.DataType === 'Uri') {
    /**
     * can back end provide protocol ?
     */
    return (value) => {
      const finalHREF = (value || '').match(/^https?:\/\//)
        ? value
        : `https://${value}`;

      return (
        <a target="_blank" rel="noopener noreferrer" href={finalHREF}>
          {value}
        </a>
      );
    };
  }

  return (value) => <span>{value}</span>;
};

export const getAppropriateReactComponentFromSchema = (schema) => {
  if (schema.DataType === 'Guid') {
    return;
  }

  if (schema.DataType === 'Email') {
    return ({ value }) => {
      const emailLink = `mailto:${value}`;
      return <a href={emailLink}>{value}</a>;
    };
  }

  if (schema.DataType === 'Duration') {
    return ({ value }) => `${value} minutes`;
  }

  if (schema.DataType === 'DateTime') {
    return ({ value }) => moment(value).format('LL');
  }

  if (schema.DataType === 'Boolean') {
    return ({ value }) => {
      if (value.toString().toLowerCase() === 'true') {
        return 'Yes';
      }
      return 'No';
    };
  }

  if (schema.DataType === 'PhoneNumber') {
    return ({ value }) => {
      const phoneLink = `tel:${value}`;
      return (
        <a rel="noopener noreferrer" href={phoneLink} target="_blank">
          {value}
        </a>
      );
    };
  }

  if (schema.DataType === 'Uri') {
    /**
     * can back end provide protocol ?
     */
    return ({ value }) => {
      const finalHREF = (value || '').match(/^https?:\/\//)
        ? value
        : `http://${value}`;

      return (
        <a target="_blank" rel="noopener noreferrer" href={finalHREF}>
          {value}
        </a>
      );
    };
  }
};

export const CustomInput =
  (Component) =>
  ({ input: { value, onChange }, meta }) => {
    const redBorder = meta.error ? { borderColor: 'red' } : {};
    return (
      <div style={{ margin: '15px 0' }}>
        {Component ? (
          <Component
            style={redBorder}
            value={value}
            defaultValue={value}
            onChange={onChange}
          />
        ) : (
          <input
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: '10px 5px',
            }}
            type="text"
            defaultValue={value}
            onChange={onChange}
          />
        )}
        {meta.error && (
          <div style={{ color: 'red', marginTop: '5px' }}>{meta.error}</div>
        )}
      </div>
    );
  };
