export const entityTypesPool = {
  '/Person': ['/Infrastructure/User', '/Infrastructure/Contact'],
  '/Infrastructure/User': ['/Person', '/Infrastructure/Contact'],
  '/Infrastructure/Contact': ['/Infrastructure/User', '/Person'],
};

export const isPersonEntity = (entityType) =>
  entityType === '/Person' ||
  entityType === '/Infrastructure/User' ||
  entityType === '/Infrastructure/Contact';

export default {
  entityTypesPool,
  isPersonEntity,
};
