"use strict";

exports.__esModule = true;
exports.UnstyledButton = exports.FlatButton = exports.ButtonLink = exports.Button = exports.AtomButtonWithLoader = exports.AtomButton = void 0;
var _Button = _interopRequireWildcard(require("uxi/Button"));
var _atoms = require("@cluedin/atoms");
var _withClaimsDisabled = _interopRequireDefault(require("../../hocs/withClaimsDisabled"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var Button = (0, _withClaimsDisabled.default)(_Button.default);
exports.Button = Button;
var FlatButton = (0, _withClaimsDisabled.default)(_Button.FlatButton);
exports.FlatButton = FlatButton;
var ButtonLink = (0, _withClaimsDisabled.default)(_atoms.ButtonLink);
exports.ButtonLink = ButtonLink;
var UnstyledButton = (0, _withClaimsDisabled.default)(_Button.UnstyledButton);
exports.UnstyledButton = UnstyledButton;
var AtomButton = (0, _withClaimsDisabled.default)(_atoms.Button);
exports.AtomButton = AtomButton;
var AtomButtonWithLoader = (0, _atoms.withButtonLoader)((0, _withClaimsDisabled.default)(_atoms.Button), {
  replaceContent: true
});
exports.AtomButtonWithLoader = AtomButtonWithLoader;