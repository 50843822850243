import React from 'react';

import styled from 'styled-components';

const GeneralContentElement = styled.section`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const GeneralContent = ({ children }) => {
  return <GeneralContentElement>{children}</GeneralContentElement>;
};
