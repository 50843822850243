import styled, { css } from 'styled-components';

export const ExplainLogSingleOperationItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExplainLogSingleOperationStatus = styled.div`
  display: flex;
  padding: 0;
  margin: 0;

  ${({ resultStatus }) => {
    switch (true) {
      case resultStatus === 'Successful':
        return css`
          color: #29a29a !important;
        `;
      case resultStatus === 'Skipped':
        return css`
          color: #5b89cf !important;
        `;
      case resultStatus === 'SuccessfulWithWarnings':
        return css`
          color: rgb(255, 152, 0) !important;
        `;
      case resultStatus === 'Failed':
        return css`
          color: rgb(239, 88, 88) !important;
        `;
      default:
        return css`
          color: #666666 !important;
        `;
    }
  }}
`;

export const ExplainLogSingleOperationWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 100ms ease;
  width: 100%;
`;
