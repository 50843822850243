import styled from 'styled-components';

export const EntityRelationsGraphV3GraphButtonWrapper = styled.div<{
  fillColor: string;
}>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  & svg {
    fill: ${({ fillColor }) => fillColor};
  }
`;
