import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'uxi/Alert';
import { GqlErrorMessages } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import get from 'lodash/get';

import PageLoader from '../../../../../core/components/composites/PageLoader';
import { useGetAcceptedVocabKeysByIdAndKey } from '../../../../hooks/getAcceptedVocabKeys';
import EntityPropertiesImpactedKeyList from './EntityPropertiesImpactedKeyList';
import {
  EntityPropertiesStep1WarningWrapper,
  EntityPropertiesStep1WarningMessageWrapper,
} from './EntityPropertiesStep1Warning.styles';

const EntityPropertiesStep1Warning = ({
  editMode,
  entityId,
  keyToDisplay,
  vocabularyKey,
}) => {
  const [voData, voLoading, voError] = useGetAcceptedVocabKeysByIdAndKey(
    entityId,
    vocabularyKey?.key,
  );

  const supportedVocabKeys = get(voData, 'supportedVocabKeys');
  const notSupportedVocabKeys = get(voData, 'notSupportedVocabKeys');
  const allVocabKeys = get(voData, 'allVocabKeys');

  const isCore = get(vocabularyKey, 'isCluedInCore');

  if (editMode === 'manual-directEdit') {
    return (
      <EntityPropertiesStep1WarningWrapper>
        {isCore ? (
          <Alert type="warning">
            <FormattedMessage id="module-manualDirectEdit-warningCore" />
          </Alert>
        ) : (
          <Alert type="warning">
            {keyToDisplay ? (
              <FormattedMessage
                id="module-manualDirectEdit-warning"
                values={{ keyToDisplay }}
              />
            ) : (
              <FormattedMessage id="module-manualDirectEdit-warningNoKey" />
            )}
          </Alert>
        )}

        <EntityPropertiesStep1WarningMessageWrapper>
          <FormattedMessage id="module-manualDirectEdit-warningInfo" />
        </EntityPropertiesStep1WarningMessageWrapper>

        {voLoading ? (
          <PageLoader />
        ) : (
          <>
            {Array.isArray(allVocabKeys) && allVocabKeys && (
              <>
                {allVocabKeys && allVocabKeys.length > 0 ? (
                  <EntityPropertiesImpactedKeyList
                    title={
                      <FormattedMessage id="module-entityProperties-updatedByAChangeIn" />
                    }
                    data={allVocabKeys}
                    isSupported
                  />
                ) : (
                  <span />
                )}
              </>
            )}
          </>
        )}

        {voError && <GqlErrorMessages error={voError} />}
      </EntityPropertiesStep1WarningWrapper>
    );
  }

  if (editMode === 'mesh') {
    return (
      <EntityPropertiesStep1WarningWrapper>
        <Alert type="warning">
          {keyToDisplay ? (
            <FormattedMessage
              id="module-mesh-warning"
              values={{ keyToDisplay }}
            />
          ) : (
            <FormattedMessage id="module-mesh-warningNoKey" />
          )}
        </Alert>

        {voLoading ? (
          <PageLoader />
        ) : (
          <>
            {Array.isArray(supportedVocabKeys) &&
              supportedVocabKeys.length > 0 && (
                <EntityPropertiesImpactedKeyList
                  title={
                    <FormattedMessage id="module-entityProperties-supportedMeshMessage" />
                  }
                  data={supportedVocabKeys}
                  isSupported
                />
              )}

            {Array.isArray(notSupportedVocabKeys) &&
              notSupportedVocabKeys.length > 0 && (
                <EntityPropertiesImpactedKeyList
                  title={
                    <FormattedMessage id="module-entityProperties-notSupportedMeshMessage" />
                  }
                  data={notSupportedVocabKeys}
                />
              )}
          </>
        )}

        {voError && <GqlErrorMessages error={voError} />}
      </EntityPropertiesStep1WarningWrapper>
    );
  }

  if (editMode === 'manualEdit+mesh') {
    return (
      <EntityPropertiesStep1WarningWrapper>
        {isCore ? (
          <Alert type="warning">
            <FormattedMessage id="module-manualEdit+mesh-warningCore" />
          </Alert>
        ) : (
          <Alert type="warning">
            {keyToDisplay ? (
              <FormattedMessage
                id="module-manualEdit+mesh-warning"
                values={{ keyToDisplay }}
              />
            ) : (
              <FormattedMessage id="module-manualEdit+mesh-warningNoKey" />
            )}
          </Alert>
        )}

        <EntityPropertiesStep1WarningMessageWrapper>
          <FormattedMessage id="module-manualDirectEdit-warningInfo" />
        </EntityPropertiesStep1WarningMessageWrapper>

        {voLoading ? (
          <PageLoader />
        ) : (
          <>
            {Array.isArray(supportedVocabKeys) &&
              supportedVocabKeys.length > 0 && (
                <EntityPropertiesImpactedKeyList
                  title={
                    <FormattedMessage id="module-entityProperties-supportedMeshMessage" />
                  }
                  data={supportedVocabKeys}
                  isSupported
                />
              )}

            {Array.isArray(notSupportedVocabKeys) &&
              notSupportedVocabKeys.length > 0 && (
                <EntityPropertiesImpactedKeyList
                  title={
                    <FormattedMessage id="module-entityProperties-notSupportedMeshMessage" />
                  }
                  data={notSupportedVocabKeys}
                />
              )}
          </>
        )}

        {voLoading && <GqlErrorMessages error={voLoading} />}
      </EntityPropertiesStep1WarningWrapper>
    );
  }

  return <div />;
};

export default EntityPropertiesStep1Warning;

EntityPropertiesStep1Warning.propTypes = {
  editMode: PropTypes.string,
  entityId: PropTypes.string,
  keyToDisplay: PropTypes.string,
};

EntityPropertiesStep1Warning.defaultProps = {
  editMode: '',
  entityId: '',
  keyToDisplay: '',
};
