import { createAction } from 'redux-actions';
import {
  unauthorized,
  unauthorizedWithQueue,
  unauthorizedForEntity,
  subtleErrorHandling,
  unauthorizedWithResponse,
} from '../generic';

export const createAsyncActionForEntities = (name) => {
  const result = {};

  result.request = createAction(`REQUEST_${name}`, (widgetUniqueId) => ({
    widgetUniqueId,
  }));
  result.receive = createAction(
    `RECEIVE_${name}`,
    (entities, widgetUniqueId) => ({ entities, widgetUniqueId }),
  );
  result.invalid = createAction(`INVALID_${name}`);

  return result;
};

export const createAsyncAction = (name) => {
  const result = {};

  result.request = createAction(`REQUEST_${name}`);
  result.receive = createAction(`RECEIVE_${name}`);
  result.invalid = createAction(`INVALID_${name}`);
  result.queued = createAction(`QUEUED_${name}`);

  return result;
};

export const defaultAsyncEntityCallWithActions =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then((res) => {
        if (!res) {
          dispatch(actions.invalid(param));
        } else {
          dispatch(actions.receive(res));
          if (onSuccess) {
            onSuccess(dispatch);
          }
        }
      })
      .catch(unauthorizedForEntity(dispatch, actions.invalid, param.id));
  };

export const defaultAsyncCallWithActions =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then((res) => {
        dispatch(actions.receive(res));
        if (onSuccess) {
          onSuccess(dispatch, res);
        }
      })
      .catch(unauthorized(dispatch, actions.invalid, false, param));
  };

export const defaultAsyncCallWithActionsWithParam =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then((res) => {
        dispatch(
          actions.receive({
            result: res,
            param,
          }),
        );

        if (onSuccess) {
          onSuccess(dispatch);
        }
      })
      .catch(unauthorized(dispatch, actions.invalid, false, param));
  };

export const defaultAsyncCallWithActionsWithParamWithQueue =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then((res) => {
        if (res && res.status === 202) {
          dispatch(actions.queued(param));
          return {
            body: { isQueued: true },
          };
        }
        return res;
      })
      .then((res) => {
        if (res.isQueued) {
          return;
        }
        dispatch(
          actions.receive({
            result: res,
            param,
          }),
        );

        if (onSuccess) {
          onSuccess(dispatch);
        }
      })
      .catch(unauthorizedWithResponse(dispatch, actions.invalid, false, param));
  };

export const noBodyResponseAsyncCallWithActionsWithQueue =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then((res) => {
        if (res && res.status === 202) {
          if (actions.queued) {
            dispatch(actions.queued(param));
            return {
              body: {
                isQueued: true,
              },
            };
          }
        }
        return res;
      })
      .then((res) => {
        if (res && !res.isQueued) {
          dispatch(actions.receive(param));

          if (onSuccess) {
            onSuccess(dispatch);
          }
        }
      })
      .catch(unauthorizedWithQueue(dispatch, actions, param));
  };

export const noBodyResponseAsyncCallWithActions =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then(() => {
        dispatch(actions.receive(param));

        if (onSuccess) {
          onSuccess(dispatch);
        }
      })
      .catch(subtleErrorHandling(dispatch, actions.invalid, false, param));
  };
