import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { push } from 'connected-react-router';
import PageLoader from '../../../core/components/composites/PageLoader';
import { shouldLogAllOut } from '../../actions';

const LogAllOut = compose(
  lifecycle({
    componentDidMount() {
      const { logout } = this.props;
      logout();
    },
  }),
)(() => <PageLoader />);

const mapToStateProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(shouldLogAllOut());
    dispatch(push('/signin'));
  },
});

export default connect(mapToStateProps, mapDispatchToProps)(LogAllOut);
