import { FC } from 'react';
import { Icon } from '@fluentui/react';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';

import { EntityRelationEdgePropertiesHeadingWrapper } from './EntityRelationEdgePropertiesHeadingWrapper';
import {
  GroupedRelationEntityDataEdge,
  SelectedConnectingDirectionalEdge,
} from '../../../../../types';

type EntityRelationEdgePropertiesHeadingProps = {
  isOutgoing: boolean;
  edgeFrom: SelectedConnectingDirectionalEdge;
  edgeTo: SelectedConnectingDirectionalEdge;
  edge: GroupedRelationEntityDataEdge;
};

const isLabelGrouped = (label: string) => {
  return label.toLowerCase() === 'grouped';
};

export const EntityRelationEdgePropertiesHeading: FC<
  EntityRelationEdgePropertiesHeadingProps
> = ({ isOutgoing, edgeFrom, edgeTo, edge }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const fromLabel = edgeFrom?.label ? (
    isLabelGrouped(edgeFrom?.label) ? (
      edge?.referencedEntityName
    ) : (
      edgeFrom?.label
    )
  ) : (
    <FormattedMessage id="module-entityRelationsGraph-edgePropertiesEntryNoName" />
  );

  const toLabel = edgeTo?.label ? (
    isLabelGrouped(edgeTo?.label) ? (
      edge?.referencedEntityName
    ) : (
      edgeTo?.label
    )
  ) : (
    <FormattedMessage id="module-entityRelationsGraph-edgePropertiesEntryNoName" />
  );

  return (
    <EntityRelationEdgePropertiesHeadingWrapper>
      {isOutgoing ? (
        <>
          <div style={{ fontWeight: 'normal', marginRight: '8px' }}>
            <FormattedMessage id="module-entityRelationsGraph-edgePropertiesFor" />
            :
          </div>
          {fromLabel}
          <Icon
            iconName="ArrowOutgoing"
            style={{
              color: themePrimary,
              margin: '0 8px',
              fontSize: 14,
              marginTop: '-2px',
            }}
          />
          {toLabel}
        </>
      ) : (
        <>
          <div style={{ fontWeight: 'normal', marginRight: '8px' }}>
            <FormattedMessage id="module-entityRelationsGraph-edgePropertiesFor" />
            :
          </div>
          {toLabel}
          <Icon
            iconName="ArrowIncoming"
            style={{
              color: themePrimary,
              margin: '0 8px',
              fontSize: 14,
              marginTop: '-2px',
            }}
          />
          {fromLabel}
        </>
      )}
    </EntityRelationEdgePropertiesHeadingWrapper>
  );
};
