import moment from 'moment';
import { getSchemaViewModelFromEntityPropertyKey } from '../../schema/selector';
import { findSource } from '../../integration/selector';

const extractIntegrationNameFromPropertyKey = (propertyKey) => {
  if (propertyKey.indexOf('property-') > -1) {
    return propertyKey.split('property-')[1];
  }

  return propertyKey;
};

export const createPropertyVM = (
  propertyKey,
  propertyValue,
  schema = [],
  integrations = [],
) => {
  const schemaVM = getSchemaViewModelFromEntityPropertyKey(propertyKey, schema);
  const integrationNameFromPropertyKey =
    extractIntegrationNameFromPropertyKey(propertyKey);
  const source = findSource(integrationNameFromPropertyKey, integrations);

  if (schemaVM && schemaVM.propertyInSchema) {
    return {
      displayName: schemaVM.propertyInSchema.DisplayName,
      property: propertyKey,
      content: schemaVM.component,
      normalizedKeyNameForForm: schemaVM.normalizedKeyNameForForm,
      value: propertyValue,
      schema: schemaVM.propertyInSchema,
      hasSchema: true,
      source,
    };
  }

  return {
    displayName: propertyKey,
    property: propertyKey,
    content: (value) => value,
    value: propertyValue,
    schema: null,
    hasSchema: false,
    source,
  };
};

const toPropertyHistoryItemVm = (version, change) => ({
  modifiedAt: version['attribute-date'],
  newValue: change['attribute-newValue'],
  changeType: change['attribute-type'],
  modifiedAtFormatted: moment(version['attribute-date']).format('LLL'),
  version: version['attribute-dataReference'],
});

export const toPropertyHistoryVm = (history, propertyVm) => {
  let versionsArray = [];
  if (
    history &&
    history.versionHistory &&
    history.versionHistory.version &&
    history.versionHistory.version.length
  ) {
    versionsArray = history.versionHistory.version;
  }

  const result = [];

  (versionsArray || []).forEach((version) => {
    if (Array.isArray(version.version.change)) {
      (version.version.change || []).forEach((versionChange) => {
        if (
          propertyVm &&
          versionChange &&
          versionChange.change &&
          propertyVm.schema &&
          versionChange.change['attribute-key'] === propertyVm.schema.Key
        ) {
          result.push(
            toPropertyHistoryItemVm(version.version, versionChange.change),
          );
        }
      });
    } else if (
      propertyVm &&
      version &&
      version.version &&
      version.version.change &&
      version.version.change.change &&
      propertyVm.schema &&
      version.version.change.change['attribute-key'] === propertyVm.schema.Key
    ) {
      result.push(
        toPropertyHistoryItemVm(version.version, version.change.change),
      );
    }
  });

  return result.sort((a, b) => moment(a.modifiedAt) < moment(b.modifiedAt));
};

export default {
  createPropertyVM,
  toPropertyHistoryVm,
};
