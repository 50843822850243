import React, { useState } from 'react';
import Text from 'uxi/Text';
import HelpSection from './HelpSection';
import { useQueryHelpMenu } from '../../../hooks/useQueryHelpMenu';
import { GqlErrorMessages } from '@cluedin/form';
import PageLoader from '../../../../core/components/composites/PageLoader';

const MainHelpSection = ({ onTopicClick, themePrimary }) => {
  const [sectionExpanded, setSectionExpanded] = useState();
  const [data, loading, error] = useQueryHelpMenu();

  return (
    <div>
      <div style={{ marginBottom: '5px' }}>
        <Text type="title">Help</Text>
      </div>
      {error && <GqlErrorMessages error={error} />}
      {loading && <PageLoader />}
      {!loading && (
        <div style={{ margin: '16px 0' }}>
          {data?.map((s, index) => (
            <HelpSection
              key={s.id}
              isSelected={sectionExpanded === s.id}
              setSectionExpanded={setSectionExpanded}
              isFirst={index === 0}
              section={s}
              themePrimary={themePrimary}
              onTopicClick={onTopicClick}
            />
          ))}
        </div>
      )}
      <div>
        Have more questions? Contact us at{' '}
        <a style={{ color: themePrimary }} href="mailto:support@cluedin.com">
          support@cluedin.com
        </a>
      </div>
    </div>
  );
};

export default MainHelpSection;
