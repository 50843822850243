"use strict";

exports.__esModule = true;
exports.text = exports.outlined = exports.contained = void 0;
var _styledComponents = require("styled-components");
var _theme = require("@cluedin/theme");
var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var fade = _theme.colorManipulator.fade,
  lighten = _theme.colorManipulator.lighten;
var contained = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  &.error {\n    color: ", ";\n    background-color: ", ";\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n  }\n  &.danger {\n    color: ", ";\n    background-color: ", ";\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n  }\n  &.success {\n    color: ", ";\n    background-color: ", ";\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n  }\n  &.warning {\n    color: ", ";\n    background-color: ", ";\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n  }\n  &.info {\n    color: ", ";\n    background-color: ", ";\n    &:hover,\n    &:focus {\n      background-color: ", ";\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.pureWhite;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.semantic.error.main;
}, function (_ref3) {
  var theme = _ref3.theme;
  return lighten(theme.colors.semantic.error.main, 0.2);
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.pureWhite;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.semantic.danger.main;
}, function (_ref6) {
  var theme = _ref6.theme;
  return lighten(theme.colors.semantic.danger.main, 0.2);
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.pureWhite;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.semantic.success.main;
}, function (_ref9) {
  var theme = _ref9.theme;
  return lighten(theme.colors.semantic.success.main, 0.2);
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.pureWhite;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.semantic.warning.main;
}, function (_ref12) {
  var theme = _ref12.theme;
  return lighten(theme.colors.semantic.warning.main, 0.2);
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.pureWhite;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.colors.semantic.info.main;
}, function (_ref15) {
  var theme = _ref15.theme;
  return lighten(theme.colors.semantic.info.main, 0.2);
});
exports.contained = contained;
var outlined = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  &.danger {\n    color: ", ";\n    border: 1px solid\n      ", ";\n    &:hover,\n    &:focus {\n      border: ", ";\n      background-color: ", ";\n    }\n  }\n  &.error {\n    color: ", ";\n    border: 1px solid\n      ", ";\n    &:hover,\n    &:focus {\n      border: ", ";\n      background-color: ", ";\n    }\n  }\n  &.success {\n    color: ", ";\n    border: 1px solid\n      ", ";\n    &:hover,\n    &:focus {\n      border: ", ";\n      background-color: ", ";\n    }\n  }\n  &.warning {\n    color: ", ";\n    border: 1px solid\n      ", ";\n    &:hover,\n    &:focus {\n      border: ", ";\n      background-color: ", ";\n    }\n  }\n  &.info {\n    color: ", ";\n    border: 1px solid\n      ", ";\n    &:hover,\n    &:focus {\n      border: ", ";\n      background-color: ", ";\n    }\n  }\n"])), function (_ref16) {
  var theme = _ref16.theme;
  return theme.colors.semantic.danger.main;
}, function (_ref17) {
  var theme = _ref17.theme;
  return fade(theme.colors.semantic.danger.main, 0.5);
}, function (_ref18) {
  var theme = _ref18.theme;
  return "1px solid " + theme.colors.semantic.danger.main;
}, function (_ref19) {
  var theme = _ref19.theme;
  return fade(theme.colors.semantic.danger.main, theme.colors.action.hoverOpacity);
}, function (_ref20) {
  var theme = _ref20.theme;
  return theme.colors.semantic.error.main;
}, function (_ref21) {
  var theme = _ref21.theme;
  return fade(theme.colors.semantic.error.main, 0.5);
}, function (_ref22) {
  var theme = _ref22.theme;
  return "1px solid " + theme.colors.semantic.error.main;
}, function (_ref23) {
  var theme = _ref23.theme;
  return fade(theme.colors.semantic.error.main, theme.colors.action.hoverOpacity);
}, function (_ref24) {
  var theme = _ref24.theme;
  return theme.colors.semantic.success.main;
}, function (_ref25) {
  var theme = _ref25.theme;
  return fade(theme.colors.semantic.success.main, 0.5);
}, function (_ref26) {
  var theme = _ref26.theme;
  return "1px solid " + theme.colors.semantic.success.main;
}, function (_ref27) {
  var theme = _ref27.theme;
  return fade(theme.colors.semantic.success.main, theme.colors.action.hoverOpacity);
}, function (_ref28) {
  var theme = _ref28.theme;
  return theme.colors.semantic.warning.main;
}, function (_ref29) {
  var theme = _ref29.theme;
  return fade(theme.colors.semantic.warning.main, 0.5);
}, function (_ref30) {
  var theme = _ref30.theme;
  return "1px solid " + theme.colors.semantic.warning.main;
}, function (_ref31) {
  var theme = _ref31.theme;
  return fade(theme.colors.semantic.warning.main, theme.colors.action.hoverOpacity);
}, function (_ref32) {
  var theme = _ref32.theme;
  return theme.colors.semantic.info.main;
}, function (_ref33) {
  var theme = _ref33.theme;
  return fade(theme.colors.semantic.info.main, 0.5);
}, function (_ref34) {
  var theme = _ref34.theme;
  return "1px solid " + theme.colors.semantic.info.main;
}, function (_ref35) {
  var theme = _ref35.theme;
  return fade(theme.colors.semantic.info.main, theme.colors.action.hoverOpacity);
});
exports.outlined = outlined;
var text = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  &.danger {\n    color: ", ";\n    &:hover {\n      color: ", ";\n    }\n  }\n  &.error {\n    color: ", ";\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n  &.success {\n    color: ", ";\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n  &.warning {\n    color: ", ";\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n  &.info {\n    color: ", ";\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n"])), function (_ref36) {
  var theme = _ref36.theme;
  return theme.colors.semantic.danger.main;
}, function (_ref37) {
  var theme = _ref37.theme;
  return theme.colors.primary.dark;
}, function (_ref38) {
  var theme = _ref38.theme;
  return theme.colors.semantic.error.main;
}, function (_ref39) {
  var theme = _ref39.theme;
  return theme.colors.primary.dark;
}, function (_ref40) {
  var theme = _ref40.theme;
  return theme.colors.semantic.success.main;
}, function (_ref41) {
  var theme = _ref41.theme;
  return theme.colors.primary.dark;
}, function (_ref42) {
  var theme = _ref42.theme;
  return theme.colors.semantic.warning.main;
}, function (_ref43) {
  var theme = _ref43.theme;
  return theme.colors.primary.dark;
}, function (_ref44) {
  var theme = _ref44.theme;
  return theme.colors.semantic.info.main;
}, function (_ref45) {
  var theme = _ref45.theme;
  return theme.colors.primary.dark;
});
exports.text = text;