import React from 'react';
import { Integration } from 'uxi/Icons';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './route';

const { integrations } = actionsConfiguration;

export default {
  pillar: 'inbound',
  name: 'configuration',
  displayName: <FormattedMessage id="module-configuration-moduleName" />,
  path: '/configuration',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: Integration,
        name: <FormattedMessage id="module-configuration-moduleName" />,
        link: '/admin/inbound/configuration',
        claims: integrations.configuredIntegrations.configuredIntegration.view,
      },
    ],
  },
  routes,
};
