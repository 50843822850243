import * as d3 from 'd3';

import { UpdatedGraphNetworkEdge, UpdatedGraphNetworkNode } from '../types';

export const enableDraggingOfNodes = (
  simulation: d3.Simulation<UpdatedGraphNetworkNode, UpdatedGraphNetworkEdge>,
) => {
  const dragStarted = (
    event: d3.D3DragEvent<
      SVGGElement,
      UpdatedGraphNetworkNode,
      SVGSVGElement | null
    >,
    node: UpdatedGraphNetworkNode,
  ) => {
    if (!event.active) {
      simulation.alphaTarget(0.1).restart();
    }

    node.fx = node.x;
    node.fy = node.y;
  };

  const drag = (
    event: d3.D3DragEvent<
      SVGGElement,
      UpdatedGraphNetworkNode,
      SVGSVGElement | null
    >,
    node: UpdatedGraphNetworkNode,
  ) => {
    simulation.alphaTarget(0.1).restart();

    node.fx = event.x;
    node.fy = event.y;
  };

  const dragEnded = (
    event: d3.D3DragEvent<
      SVGGElement,
      UpdatedGraphNetworkNode,
      SVGSVGElement | null
    >,
    node: UpdatedGraphNetworkNode,
  ) => {
    if (!event.active) {
      simulation.alphaTarget(0);
    }

    node.fx = null;
    node.fy = null;
  };

  return d3
    .drag<SVGGElement, UpdatedGraphNetworkNode, SVGSVGElement | null>()
    .on('start', dragStarted)
    .on('drag', drag)
    .on('end', dragEnded);
};
