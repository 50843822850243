"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("@cluedin/theme");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var fade = _theme.colorManipulator.fade;
/**
 * 
 * transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
 */
var _default = function _default(IconButton) {
  return (0, _styledComponents.default)(IconButton)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  text-align: center;\n  flex: 0 0 auto;\n  font-size: ", ";\n  padding: 12px;\n  border-radius: 50%;\n  overflow: visible; // Explicitly set the default value to solve a bug on IE 11.\n  color: ", ";\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n  &:hover {\n    background-color: ", ";\n    @media (hover: none) {\n      background-color: transparent;\n    }\n  }\n  &.disabled {\n    background-color: transparent;\n    color: ", ";\n  }\n  .edgeStart {\n    margin-left: -12;\n  }\n  .edgeEnd {\n    margin-right: -12;\n  }\n  span.label {\n    width: 100%;\n    display: flex;\n    align-items: inherit;\n    justify-content: inherit;\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.typography.pxToRem(24);
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.action.active;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return fade(theme.colors.action.active, theme.colors.action.hoverOpacity);
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.action.disabledText;
  });
};
exports.default = _default;