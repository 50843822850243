import React, { Component } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'uxi/Input';
import Flex from 'uxi/Layout/Flex';
import { Externallink } from 'uxi/Icons';
import FlexExtended from './FlexExtended';
import { sectionCSSString } from './Section';
import EntityExternalLinkContainer from '../../../../wms/components/containers/EntityExternalLinkContainer';

const EntitiesSection = styled.section`
  ${sectionCSSString};
  label {
    word-break: break-word;
  }
`;

class EntitiesEditable extends Component {
  handleSelectedGuidsChange = (event, checked, value) => {
    const { onExcludedGuidsChange } = this.props;

    if (onExcludedGuidsChange) {
      onExcludedGuidsChange(event, checked, value);
    }
  };

  render() {
    const {
      jsonReport,
      editable,
      reportSettings,
      onExcludedGuidsChange,
      onExcludedEntityTypeChange,
    } = this.props;

    const { EntityTypeDetails } = jsonReport;

    const groupedByTypes = {};

    (EntityTypeDetails || []).forEach(($values) => {
      const entityType = $values[0] && $values[0].EntityType;

      groupedByTypes[entityType] = $values;
    });
    return (
      <EntitiesSection jsonReport={jsonReport}>
        <h2>Entities</h2>
        {Object.keys(groupedByTypes).map((k) => (
          <div style={{ marginBottom: '32px' }}>
            <div style={{ maxWidth: '100%' }}>
              {editable && (
                <Checkbox
                  checked={!(reportSettings.ExcludedEntityType.indexOf(k) > -1)}
                  name={k}
                  onChange={onExcludedEntityTypeChange}
                  style={{
                    maxWidth: '100%',
                    marginRight: '16px',
                    opacity:
                      reportSettings.ExcludedEntityType.indexOf(k) > -1
                        ? 0.4
                        : 1,
                  }}
                  label={<h4>{k}</h4>}
                />
              )}
            </div>
            {groupedByTypes[k].map(
              ({ Name, Id, Author, Url: UrlMaybe, ActionDate, EntityType }) => {
                const Url =
                  UrlMaybe && UrlMaybe !== 'Unknown Url' ? UrlMaybe : null;

                return (
                  <FlexExtended
                    style={{
                      maxWidth: '100%',
                      marginLeft: '16px',
                      opacity:
                        reportSettings.ExcludedEntityType.indexOf(k) > -1 ||
                        reportSettings.ExcludedGuids.indexOf(Id) > -1
                          ? 0.4
                          : 1,
                    }}
                  >
                    {editable && (
                      <Checkbox
                        defaultChecked
                        checked={
                          !(
                            reportSettings.ExcludedEntityType.indexOf(k) > -1 ||
                            reportSettings.ExcludedGuids.indexOf(Id) > -1
                          )
                        }
                        style={{
                          maxWidth: 'calc(100% - 16px)',
                          marginRight: '16px',
                        }}
                        name={Id}
                        onChange={onExcludedGuidsChange}
                        label={
                          <span
                            style={{
                              margin: '16px 0',
                              opacity:
                                reportSettings.ExcludedEntityType.indexOf(k) >
                                  -1 ||
                                reportSettings.ExcludedGuids.indexOf(Id) > -1
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            <div> {Name} </div>
                            <div>
                              {Author && `by ${Author} `}
                              {ActionDate && ` on ${ActionDate} `}
                            </div>
                            <div />
                          </span>
                        }
                      />
                    )}
                    <Flex className="no-print">
                      {Url ? (
                        <small style={{ display: 'block', marginLeft: '34px' }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={Url}
                            alt={`open entity ${Name}`}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Externallink size={12} color={'#26a29a'} />
                            &nbsp;
                            {'view original'}
                          </a>
                        </small>
                      ) : null}
                      {Id && EntityType ? (
                        <small
                          className="no-print"
                          style={{ display: 'block', marginLeft: '34px' }}
                        >
                          <EntityExternalLinkContainer
                            id={Id}
                            entityType={EntityType}
                            alt={`open entity ${Name}`}
                          >
                            <Externallink size={12} color={'#26a29a'} />
                            &nbsp;
                            {'view entity'}
                          </EntityExternalLinkContainer>
                        </small>
                      ) : null}
                    </Flex>
                    <br />
                    <br />
                  </FlexExtended>
                );
              },
            )}
          </div>
        ))}
        <hr />
      </EntitiesSection>
    );
  }
}

export default EntitiesEditable;
