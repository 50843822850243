import { actionsConfiguration, RACI_LEVELS } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

const { management } = actionsConfiguration;

export default {
  pillar: 'management',
  name: 'annotation',
  displayName: (
    <span className="__testGlobalMenuAnnotation">
      <FormattedMessage id="module-management-annotation-title" />
    </span>
  ),
  featureFlag: 'AnnotationUI',
  path: '/annotation',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Spreadsheet',
        name: <FormattedMessage id="module-management-annotation-title" />,
        link: '/admin/management/annotation',
        claims: management.annotations.annotation.view,
        isDeprecated: true,
      },
    ],
  },
  routes: [
    {
      path: '/',
      claims: {
        'management.annotation': RACI_LEVELS.CONSULTANT,
      },
      exact: true,
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"AnnotationDashboard" */
            './components/pages/AnnotationDashboard'
          ),
        ),
      ),
    },
    {
      path: '/list',
      claims: {
        'management.annotation': RACI_LEVELS.CONSULTANT,
      },
      title: 'All Annotations',
      exact: true,
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"AnnotationList" */
            './components/pages/AnnotationList'
          ),
        ),
      ),
    },
    {
      path: '/detail/:id',
      claims: {
        'management.annotation': RACI_LEVELS.CONSULTANT,
      },
      title: 'Annotation Detail',
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"AnnotationDetail" */
            './components/pages/AnnotationDetail'
          ),
        ),
      ),
    },
  ],
};
