"use strict";

exports.__esModule = true;
exports.consume = void 0;
var _claimTypes = _interopRequireDefault(require("../claim-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CONSUME_EXPORTTARGETS_CONSULTANT = _claimTypes.default.CONSUME_EXPORTTARGETS_CONSULTANT,
  CONSUME_EXPORTTARGETS_INFORMED = _claimTypes.default.CONSUME_EXPORTTARGETS_INFORMED,
  CONSUME_STREAMS_CONSULTANT = _claimTypes.default.CONSUME_STREAMS_CONSULTANT,
  CONSUME_STREAMS_INFORMED = _claimTypes.default.CONSUME_STREAMS_INFORMED,
  CONSUME_GRAPHQL_INFORMED = _claimTypes.default.CONSUME_GRAPHQL_INFORMED;
var consume = {
  exportTargets: {
    exportTarget: {
      create: {
        claims: CONSUME_EXPORTTARGETS_CONSULTANT
      },
      edit: {
        claims: CONSUME_EXPORTTARGETS_CONSULTANT
      },
      view: {
        claims: CONSUME_EXPORTTARGETS_INFORMED
      }
    }
  },
  graphQL: {
    graphiQL: {
      view: {
        claims: CONSUME_GRAPHQL_INFORMED
      }
    }
  },
  streams: {
    stream: {
      create: {
        claims: CONSUME_STREAMS_CONSULTANT
      },
      delete: {
        claims: CONSUME_STREAMS_CONSULTANT
      },
      edit: {
        claims: CONSUME_STREAMS_CONSULTANT
      },
      view: {
        claims: CONSUME_STREAMS_INFORMED
      }
    }
  }
};
exports.consume = consume;