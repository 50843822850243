import User from 'uxi/Icons/User';
import { compose } from 'recompose';
import Flex from 'uxi/Layout/Flex';
import { Connectors, Integration, Governance } from 'uxi/Icons';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { Button } from '@cluedin/form';
import Home from '../composites/HomeDashboard';
import { widgetTypes } from '../../../core/components/composites/dashboard/config';
import { withAllConfigurations } from '../../../configuration/components/Hocs/withAllConfigurations';
import IntegrationListContainer from '../../../integration/components/containers/IntegrationListContainer';
import UnstyledLink from '../../../core/components/composites/UnstyledLink';
import ConfigurationPanelContent from '../composites/ConfigurationPanelContent';
import UsersPanelContent from '../composites/UsersPanelContent';
import withEntityReportData from '../../../GDPR/components/hocs/withEntityReportData';
import EntityTypeDistributionContainer from '../../../GDPR/components/containers/reports/EntityTypeDistributionContainer';
import { withAllUser } from '../../../user/components/Hocs/withAllUser';
import TotalVocabulary from '../../../dataCatalog/components/containers/kpis/TotalVocabulary';
import TotalVocabularyKey from '../../../dataCatalog/components/containers/kpis/TotalVocabularyKey';
import EntityTypeKPI from '../../../entityTypes/components/containers/EntityTypeKPI';
import EntityTypeDistributionCount from '../../../entityTypes/components/containers/EntityTypeDistributionCount';
import { withIntegration } from '../../../integration/components/hocs/withIntegration';
import { withFacets } from '../../../search/data/gql';
import { withCurrentUserFromStore } from '../../../user/components/Hocs/withCurrentUser';
import DashboardTileActions from './DashboardTileActions';

const { integrations: integrationsConfig } = actionsConfiguration;

const HomeContainer = ({
  configurations,
  integrations,
  total,
  users,
  currentUser,
  history,
}) => {
  const availableIntegrationsCount = (integrations || []).length;

  const configuredIntegrationsCount = configurations.length;
  const failingAuthIntegrations = configurations.filter(
    (x) => x.FailingAuthentication,
  );
  const failingAuthIntegrationsCount = failingAuthIntegrations.length;
  const inactiveIntegrations = configurations.filter((x) => !x.Active);
  const inactiveIntegrationsCount = inactiveIntegrations.length;
  const unapprovedIntegrations = configurations.filter((x) => x.isUnApproved);
  const unapprovedIntegrationsCount = unapprovedIntegrations.length;

  const totalEntitiesCount = total;

  const totalOrgUsersCount = users.length;

  const dashboardDescriptor = [
    {
      widgets: [
        {
          className: 'test_homeScreen_availableIntegration',
          type: widgetTypes[1],
          label: <FormattedMessage id="module-home-integrations" />,
          icon: <Integration />,
          value: availableIntegrationsCount,
          actionConfiguration:
            integrationsConfig.configuredIntegrations.configuredIntegration
              .view,
          drawer: {
            title: 'Integrations',
            route: 'integrations',
            content: <IntegrationListContainer hideFilters withViewAll />,
          },
        },
        {
          className: 'test_homeScreen_availableConnectors',
          type: widgetTypes[1],
          label: 'Connectors',
          icon: <Connectors />,
          actionConfiguration:
            integrationsConfig.configuredIntegrations.configuredIntegration
              .view,
          value: configuredIntegrationsCount,
          drawer: {
            title: <FormattedMessage id="module-home-connectors" />,
            label: <FormattedMessage id="module-home-configuredIntegrations" />,
            route: 'configured-Integrations',
            content: (
              <ConfigurationPanelContent
                list={configurations}
                extra={
                  <Flex style={{ padding: '64px 0 16px 0' }}>
                    <Button
                      href={'/admin/inbound/configuration'}
                      text={
                        <FormattedMessage id="module-home-viewAllConfiguredIntegrations" />
                      }
                    />
                  </Flex>
                }
              />
            ),
          },
        },
        // {
        //   ...(unapprovedIntegrationsCount
        //     ? {
        //         type: widgetTypes[0],
        //         label: (
        //           <FormattedMessage id="module-home-connectorRequiredApproval" />
        //         ),
        //         icon: <Connectors />,
        //         value: unapprovedIntegrationsCount,
        //         danger: !!unapprovedIntegrationsCount,
        //         actionConfiguration:
        //           integrationsConfig.configuredIntegrations
        //             .configuredIntegration.view,
        //         actionLabel: <FormattedMessage id="module-home-approve" />,
        //         drawer: {
        //           title: (
        //             <FormattedMessage id="module-home-connectorRequiredApprovalTitle" />
        //           ),
        //           route: 'connectors-requiring-approval',
        //           content: (
        //             <ConfigurationPanelContent list={unapprovedIntegrations} />
        //           ),
        //         },
        //       }
        //     : {}),
        // },
        {
          ...(failingAuthIntegrationsCount
            ? {
                type: widgetTypes[0],
                label: (
                  <FormattedMessage id="module-home-connectorLostConnectivity" />
                ),
                icon: <Connectors />,
                danger: !!failingAuthIntegrationsCount,
                value: failingAuthIntegrationsCount,
                actionConfiguration:
                  integrationsConfig.configuredIntegrations
                    .configuredIntegration.view,
                actionLabel: <FormattedMessage id="module-home-reconnect" />,
                drawer: {
                  title: (
                    <FormattedMessage id="module-home-connectorLostConnectivityTitle" />
                  ),
                  route: 'connectors-lost-connectivity',
                  content: (
                    <ConfigurationPanelContent list={failingAuthIntegrations} />
                  ),
                },
              }
            : {}),
        },
        {
          ...(failingAuthIntegrationsCount
            ? {
                type: widgetTypes[0],
                label: <FormattedMessage id="module-home-inactiveConnectors" />,
                icon: <Connectors />,
                danger: !!inactiveIntegrationsCount,
                value: inactiveIntegrationsCount,
                actionConfiguration:
                  integrationsConfig.configuredIntegrations
                    .configuredIntegration.view,
                actionLabel: <FormattedMessage id="module-home-activate" />,
                drawer: {
                  title: (
                    <FormattedMessage id="module-home-inactiveConnectors" />
                  ),
                  route: 'inactive-connectors',
                  content: (
                    <ConfigurationPanelContent list={inactiveIntegrations} />
                  ),
                },
              }
            : {}),
        },
      ],
    },
    {
      title: <FormattedMessage id="governance" />,
      widgets: [
        {
          className: 'test_homeScreen_numberEntities',
          actionConfiguration:
            actionsConfiguration.admin.entityTypes.entityType.view,
          type: widgetTypes[1],
          label: <FormattedMessage id="module-home-nbEntities" />,
          icon: <Governance />,
          value: totalEntitiesCount,
          drawer: {
            title: (
              <span data-test="entity-type-distribution-title">
                <FormattedMessage id="module-home-nbEntitiesTitle" />
              </span>
            ),
            route: 'Entity-types-distribution',
            content: <EntityTypeDistributionContainer />,
          },
        },
        {
          className: 'test_homeScreen_numberEntityTypes',
          type: widgetTypes[1],
          label: <FormattedMessage id="module-home-nbEntitiesTypes" />,
          actionConfiguration:
            actionsConfiguration.admin.entityTypes.entityType.view,
          icon: <Governance />,
          value: <EntityTypeKPI />,
          drawer: {
            title: <FormattedMessage id="module-home-nbEntitiesTypesTitle" />,
            route: 'Entity-types-count',
            content: <EntityTypeDistributionCount />,
          },
        },
      ],
    },
    {
      title: <FormattedMessage id="management" />,
      widgets: [
        {
          actionConfiguration:
            actionsConfiguration.management.dataCatalog.catalog.view,
          className: 'test_homeScreen_numberVocab',
          type: widgetTypes[0],
          label: <FormattedMessage id="module-home-numberVocab" />,
          icon: <Governance />,
          value: <TotalVocabulary />,
          actionLabel: (
            <span data-test="view-all-vocabulary-button">
              <FormattedMessage id="module-home-viewAllVocab" />
            </span>
          ),
          to: '/admin/management/catalog/all-vocabulary?itemsPerPage=20&pageNo=1',
        },
        {
          actionConfiguration:
            actionsConfiguration.management.dataCatalog.catalog.view,
          className: 'test_homeScreen_numberCoreVocab',
          type: widgetTypes[0],
          label: <FormattedMessage id="module-home-numberVocabKeys" />,
          icon: <Governance />,
          value: <TotalVocabularyKey />,
          actionLabel: (
            <span data-test="view-all-vocabulary-keys-button">
              <FormattedMessage id="module-home-viewAllVocabKeys" />
            </span>
          ),
          to: '/admin/management/catalog/all-vocabulary-keys?itemsPerPage=20&pageNo=1',
        },
      ],
    },
    {
      title: <FormattedMessage id="administration" />,
      widgets: [
        {
          actionConfiguration: actionsConfiguration.admin.users.user.view,
          className: 'test_homeScreen_numberUsers',
          type: widgetTypes[1],
          label: <FormattedMessage id="module-home-users" />,
          icon: <User />,
          value: totalOrgUsersCount || 0,
          drawer: {
            title: <FormattedMessage id="module-home-usersTitle" />,
            route: 'number-of-users',
            content: (
              <UsersPanelContent
                users={users}
                extra={
                  <Flex style={{ width: '100%', paddingTop: '64px' }}>
                    <Flex>
                      <UnstyledLink to={'/admin/settings/users/all'}>
                        <Button
                          text={
                            <FormattedMessage id="module-home-viewAllUsers" />
                          }
                        />
                      </UnstyledLink>
                    </Flex>
                  </Flex>
                }
              />
            ),
          },
        },
      ],
    },
  ];

  /**
   * Because the displaying of some widgets are optional
   * we now filter empty objects
   */
  const filteredDashboardDescriptor = dashboardDescriptor.map(
    (dashSection) => ({
      ...dashSection,
      widgets: dashSection.widgets.filter((widget) => !!widget.type),
    }),
  );

  return (
    <>
      <div
        style={{
          padding: '0',
          margin: '16px 16px 0px 16px',
        }}
      >
        <DashboardTileActions history={history} />
      </div>
      <Home dashboardDescriptor={filteredDashboardDescriptor} />
    </>
  );
};

export default compose(
  withAllConfigurations,
  withEntityReportData,
  withAllUser,
  withIntegration,
  withFacets,
  withCurrentUserFromStore,
)(HomeContainer);
