import { registerEntityWidget } from '../../registry';
import DefaultEntityHeader from './DefaultEntityHeader';

registerEntityWidget('EntityHeaderV2', {
  name: 'EntityHeaderV2',
  displayName: 'Entity Header',
  noHeader: true,
  description: 'Display a header for any kind entity',
  view: DefaultEntityHeader,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
