import styled from 'styled-components';

export const EntityPropertiesHistoryLatestWrapper = styled.div``;

export const EntityPropertiesVocabNotFound = styled.em`
  font-style: italic;
  color: #9b9b9c;
`;

export const EntityPropertiesHistoryLatestLabel = styled.strong`
  font-size: 18px;
`;

export const EntityPropertyHistoryLatestItemWrapper = styled.div`
  padding: 8px 0;
  width: 100%;
`;

export const EntityPropertyHistoryLatestItemTitle = styled.div`
  color: #888888;
  font-size: 12px;
  margin-bottom: 4px;
`;

export const EntityPropertyHistoryLatestItemValue = styled.div`
  max-height: 85%;
  word-wrap: break-word;
`;
