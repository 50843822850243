import React from 'react';
import { GqlErrorMessages } from '@cluedin/form';
import get from 'lodash/get';

import OverviewLayout from './OverviewLayout';
import { useGetEntityTypeInfo } from '../../entityTypes/hooks/useGetEntityTypeInfo';

const OverviewContainer = ({ entity, isFetching, rootUrl, type }) => {
  const [entityTypeInfoData, entityTypeInfoLoading, entityTypeInfoError] =
    useGetEntityTypeInfo(type);
  const pageTemplateId = get(entityTypeInfoData, 'pageTemplateId');

  if (entityTypeInfoError) {
    return <GqlErrorMessages error={entityTypeInfoError} />;
  }

  return (
    <>
      {!entityTypeInfoLoading && (
        <OverviewLayout
          rootUrl={rootUrl}
          entity={entity}
          isFetching={isFetching}
          type={type}
          pageTemplateId={pageTemplateId}
        />
      )}
    </>
  );
};

export default OverviewContainer;
