import { RACI_LEVELS } from '@cluedin/components';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';

const routes = [
  {
    path: '/',
    claims: {
      'integration.manualdataentryprojectmanagement': RACI_LEVELS.INFORMED,
    },
    exact: true,
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"ManualDataEntry" */
          './components/pages/ManualDataEntry'
        ),
      ),
    ),
  },
  {
    path: '/detail/:id/add-record',
    claims: {
      'integration.manualdataentryprojectmanagement': RACI_LEVELS.CONSULTANT,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"AddRecord" */
          './components/pages/AddRecord'
        ),
      ),
    ),
  },
  {
    path: '/detail/:id',
    claims: {
      'integration.manualdataentryprojectmanagement': RACI_LEVELS.INFORMED,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"ManualDataEntryDetails" */
          './components/containers/ManualDataEntryDetails'
        ),
      ),
    ),
  },
];

export default routes;
