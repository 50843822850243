import { connect } from 'react-redux';
import { findSource } from '../../selector';

const mapToStateProps = (
  { integration: { allIntegrations } },
  { propertyKey },
) => {
  const source =
    findSource(propertyKey, allIntegrations) || findSource('CluedIn');

  return {
    integrations: allIntegrations,
    source,
  };
};

export const withIntegrationSourceFromProperty = (Comp) => {
  return connect(mapToStateProps)(Comp);
};

export default withIntegrationSourceFromProperty;
