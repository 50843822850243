"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var Tr = _styledComponents.default.tr(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  &:not(:last-child) {\n    border-bottom: 1px solid hsla(0, 0%, 88%, 1);\n  }\n  background: white;\n  &:nth-child(2n) {\n    background: hsla(0, 0%, 99.25%, 1);\n  }\n  &:nth-child(1n) {\n    ", ";\n    &:hover {\n      background: ", ";\n    }\n  }\n"])), function (_ref) {
  var selected = _ref.selected;
  return selected ? (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n            background: hsla(0, 0%, 95%, 1);\n          "]))) : '';
}, function (_ref2) {
  var palette = _ref2.theme.palette;
  return "" + palette.accent.light.replace('rgb(', 'rgba(').replace(')', ', .1)');
});
Tr.displayName = 'Tr';
var _default = Tr;
exports.default = _default;