"use strict";

exports.__esModule = true;
exports.authWithoutCreds = exports.apiRequest = void 0;
var _url = require("../url");
var _api = require("./api");
exports.apiRequest = _api.apiRequest;
var jsonToFormData = function jsonToFormData(data) {
  return Object.keys(data).map(function (key) {
    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
  }).join('&');
};
var toJSON = function toJSON(response) {
  return response.text().then(function (text) {
    return text ? JSON.parse(text) : {};
  });
};
var fetchApiWithFormEncoded = function fetchApiWithFormEncoded(method, url, data) {
  var dataEncoded = data ? jsonToFormData(data) : '';
  var contentLenght = dataEncoded.length;
  return fetch("" + url, {
    method: method,
    mode: 'cors',
    headers: {
      Accept: '*/*',
      'Content-type': 'application/x-www-form-urlencoded',
      'Content-Length': contentLenght
    },
    body: dataEncoded
  }).then(toJSON);
};
var authWithoutCreds = function authWithoutCreds(method, url, data) {
  return fetchApiWithFormEncoded(method, _url.urlBuilder.auth(url), data);
};
exports.authWithoutCreds = authWithoutCreds;