import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Integration } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';
import { withIntegrationConfig } from '../../../../integration/components/hocs/withIntegrationConfig';

const title = 'Failed Authentication for Provider';

const FailedAuthNotificationStyle = {
  logo: {
    width: '24px',
  },
};

const FailedAuthNotification = ({
  index,
  notification,
  onCloseClick,
  integrationConfig,
}) => {
  const message = <div>{notification.providerName} has problem.</div>;

  let providerIcon;

  if (integrationConfig) {
    providerIcon = (
      <img
        style={FailedAuthNotificationStyle.logo}
        alt={notification.providerName}
        src={integrationConfig.Icon}
      />
    );
  } else {
    providerIcon = <Integration />;
  }

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      customIcon={providerIcon}
    />
  );
};

const EnhancedFailedAuthNotification = compose(
  lifecycle({
    componentDidMount() {
      const { notification } = this.props;
      const message = `${notification.providerName} has problem.`;

      notify(title, { body: message });
    },
  }),
)(FailedAuthNotification);

export default withIntegrationConfig((props) => {
  return {
    integrationName:
      props && props.notification && props.notification.providerName
        ? props.notification.providerName
        : null,
  };
})(EnhancedFailedAuthNotification);
