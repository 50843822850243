import { RACI_LEVELS } from '@cluedin/components';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/detail/:id',
    exact: true,
    claims: {
      'consume.exporttargets': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"ConfiguredConnectorDetailPage" */
        './components/pages/ConfiguredConnectorDetailPage'
      ),
    ),
  },
  {
    path: '/',
    claims: {
      'consume.exporttargets': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"ConfiguredConnectListPage" */
        './components/pages/ConfiguredConnectListPage'
      ),
    ),
  },
];
