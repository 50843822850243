"use strict";

exports.__esModule = true;
exports.DataPartHeaderIconWrapper = exports.DataPartHeaderConnectorIcon = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var DataPartHeaderIconWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  background: ", ";\n  border-radius: 80%;\n  display: flex;\n  height: 50px;\n  min-height: 50px;\n  justify-content: center;\n  margin-right: 14px;\n  width: 50px;\n  min-width: 50px;\n"])), function (_ref) {
  var themePrimary = _ref.themePrimary;
  return themePrimary ? themePrimary : 'rgb(41, 162, 154)';
});
exports.DataPartHeaderIconWrapper = DataPartHeaderIconWrapper;
var DataPartHeaderConnectorIcon = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  align-items: center !important;\n  display: flex !important;\n  height: 100% !important;\n  justify-content: center !important;\n  margin: 0 !important;\n  width: 100% !important;\n"])));
exports.DataPartHeaderConnectorIcon = DataPartHeaderConnectorIcon;