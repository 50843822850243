import React from 'react';
import Alert from 'uxi/Alert';

const EntitySuccess = (placement, message) => {
  return () => (
    <Alert noIcon type="success">
      {message || 'Request successfully completed.'}
    </Alert>
  );
};

export default EntitySuccess;
