import { useState, useMemo, useCallback } from 'react';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const useLayoutMenu = () => {
  const { width: windowWidth } = useWindowDimensions();
  const menuMinimizedByVw = useMemo(() => windowWidth < 960, [windowWidth]);

  // controlled
  // undefined means it will be controlled by window width `${menuMinimizedByVw}`
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean | undefined>(
    localStorage.getItem('menu-collapsed') === 'true',
  );

  const toggleMenu = useCallback(
    (state?: boolean) => {
      if (typeof state === 'boolean') {
        return setIsMenuCollapsed(state);
      }

      setIsMenuCollapsed((prev) => {
        if (typeof prev === 'undefined') {
          localStorage.setItem('menu-collapsed', String(!menuMinimizedByVw));

          return !menuMinimizedByVw;
        }

        localStorage.setItem('menu-collapsed', String(!prev));

        return !prev;
      });
    },
    [menuMinimizedByVw],
  );

  const isMenuMinimized = useMemo(() => {
    if (typeof isMenuCollapsed === 'boolean') {
      return isMenuCollapsed;
    }

    return menuMinimizedByVw;
  }, [isMenuCollapsed, menuMinimizedByVw]);

  return useMemo(
    () => ({
      toggleMenu,
      isMenuMinimized,
      isMenuCollapsed,
    }),
    [toggleMenu, isMenuMinimized],
  );
};
