import { FC } from 'react';

import { EntityRelationsTreeWrapper } from './EntityRelationsTreeWrapper';
import PageLoader from '../../core/components/composites/PageLoader';
import { EntityRelationsGraphV3LoaderWrapper } from '../../entityRelationsGraphV2/containers/EntityRelationsGraphV3LoaderWrapper';
import { GraphNetwork } from '../../entityRelationsGraphV2/types';
import { EntityRelationsTreeEdgeGroup } from './EntityRelationsTreeEdgeGroup';
import {
  getOutgoingEdgesWithSourceAndTarget,
  getIncomingEdgesWithSourceAndTarget,
} from './edgeFactory';
import { getIncomingNodes, getOutgoingNodes } from './nodeFactory';
import { RootEntityHeader } from './RootEntityHeader';
import {
  Entity,
  UpdatedGraphNetworkEdge,
} from '../../entityRelationsGraphV2/containers/types';
import { TreeEdge } from './types';

type EntityRelationsTreeProps = {
  entityId: string;
  entity: Entity;
  treeNetwork: GraphNetwork;
  loading: boolean;
  onEdgeClick: (edge: UpdatedGraphNetworkEdge | TreeEdge) => void;
};

const EntityRelationsTree: FC<EntityRelationsTreeProps> = ({
  entityId,
  entity,
  treeNetwork,
  loading,
  onEdgeClick,
}) => {
  const rootEntity = treeNetwork?.nodes?.find((node) => node?.id === entityId);

  const outgoingEdges = getOutgoingEdgesWithSourceAndTarget(
    treeNetwork,
    entityId,
  );
  const incomingEdges = getIncomingEdgesWithSourceAndTarget(
    treeNetwork,
    entityId,
  );
  const outgoingNodes = getOutgoingNodes(treeNetwork, outgoingEdges);
  const incomingNodes = getIncomingNodes(treeNetwork, incomingEdges);

  return (
    <EntityRelationsTreeWrapper>
      {loading ? (
        <EntityRelationsGraphV3LoaderWrapper>
          <PageLoader />
        </EntityRelationsGraphV3LoaderWrapper>
      ) : (
        <div style={{ borderBottom: '1px solid #ececec' }}>
          <RootEntityHeader rootEntity={rootEntity} entity={entity} />

          <div style={{ borderBottom: '1px solid #ececec' }}>
            <EntityRelationsTreeEdgeGroup
              direction="outgoing"
              edgeGroup={outgoingEdges}
              nodeGroup={outgoingNodes}
              onEdgeClick={onEdgeClick}
            />
          </div>

          <EntityRelationsTreeEdgeGroup
            direction="incoming"
            edgeGroup={incomingEdges}
            nodeGroup={incomingNodes}
            onEdgeClick={onEdgeClick}
          />
        </div>
      )}
    </EntityRelationsTreeWrapper>
  );
};

export default EntityRelationsTree;
