import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

const PaneContent = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 16px;
`;

const PaneTile = styled.div`
  font-size: 24px;
  color: #6d6d71;
  padding-top: 68px;
  padding-bottom: 12px;
`;

const PaneText = styled.div`
  font-size: 14px;
  color: #6d6d71;
  margin-top: 16px;
`;

const PaneOnBoarding = styled.div`
  max-width: 960px;
  margin: 64px auto;
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const PaneOnBoardingStep = styled.div`
  display: flex;
  text-align: content;
  flex-direction: column;
  padding: 16px;
`;

const PaneOnBoardingStepIllustration = styled.div`
  svg path,
  svg circle {
    fill: ${({ theme }) => theme?.palette?.themePrimary};
  }
`;

const PaneOnBoardingStepIllustrationLast = styled.div`
  svg path:not(:last-child),
  svg circle {
    fill: ${({ theme }) => theme?.palette?.themePrimary};
  }

  svg path:last-child {
    stroke: ${({ theme }) => theme?.palette?.themePrimary};
  }
`;

const EmptyState = ({ title, description, boardingSteps = [] }) => {
  const theme = useTheme();

  return (
    <PaneContent>
      <PaneTile>{title}</PaneTile>
      <PaneText>{description}</PaneText>
      <PaneOnBoarding>
        {boardingSteps.map((step) => (
          <PaneOnBoardingStep key={`${step.description}-${step.illustration}`}>
            {!step?.isLast ? (
              <PaneOnBoardingStepIllustration theme={theme}>
                {step.illustration}
              </PaneOnBoardingStepIllustration>
            ) : (
              <PaneOnBoardingStepIllustrationLast theme={theme}>
                {step.illustration}
              </PaneOnBoardingStepIllustrationLast>
            )}

            <PaneText>{step.description}</PaneText>
          </PaneOnBoardingStep>
        ))}
      </PaneOnBoarding>
    </PaneContent>
  );
};

export default EmptyState;
