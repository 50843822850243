/* eslint-disable */
import React, { Component } from 'react';
import { Arrowdown, Arrowright } from 'uxi/Icons';

import { Checkbox } from '@cluedin/form';

const TreeNodeStyle = {
  titleContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  titleIcon: {
    width: '20px',
  },
  title: {
    flex: 1,
  },
  titleH5: {
    margin: '5px',
  },
};

const forEachTreeElement = (tree, predicate, cb) => {
  if (tree.childNodes) {
    tree.childNodes.forEach((n) => {
      forEachTreeElement(n, predicate, cb);
    });
  }

  if (predicate(tree) && cb) {
    cb(tree);
  }
};

export default class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      node: props.value || {},
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({ node: nextProp.value });
  }

  onChange() {
    const { node } = this.state;
    const { onChange } = this.props;

    onChange(node);
  }

  toggle = () => {
    this.setState({ visible: !this.state.visible });
  };

  select(node, isChecked) {
    const { onChange } = this.props;

    node.Active = true;

    forEachTreeElement(
      node,
      () => true,
      (n) => {
        n.Active = isChecked;
      },
    );

    this.setState({
      node,
    });

    onChange(node);
  }

  render() {
    const { className } = this.props;
    const { node } = this.state;

    if (!node) {
      console.warn(
        'The node passed to the TreeCheckBoxList is undefined or empty',
      );
      return <div className="tree-empty" />;
    }

    const nodeValue = node.Active || false;

    const title = node.title || node.Name;
    const hasChildren = !!(node.childNodes && node.childNodes.length > 0);

    let childNodesContent;

    if (hasChildren) {
      childNodesContent = node.childNodes.map((n, index) => (
        <li style={{ paddingLeft: '50px' }} key={index}>
          <TreeNode
            onChange={() => {
              this.onChange();
            }}
            value={n}
          />
        </li>
      ));
    }

    return (
      <div className={className}>
        <div style={TreeNodeStyle.titleContainer}>
          {hasChildren && (
            <a
              onClick={() => {
                this.toggle();
              }}
              style={TreeNodeStyle.titleIcon}
            >
              {this.state.visible ? (
                <Arrowdown size="14px" style={{ paddingTop: '11px' }} />
              ) : (
                <Arrowright size="14px" style={{ paddingTop: '11px' }} />
              )}
            </a>
          )}
          <Checkbox
            checked={nodeValue}
            onChange={(evt, isChecked) => {
              this.select(node, isChecked);
            }}
            label={<div style={{ paddingLeft: '5px' }}>{title}</div>}
          />
        </div>
        {childNodesContent && (
          <ul style={this.state.visible ? {} : { display: 'none' }}>
            {childNodesContent}
          </ul>
        )}
      </div>
    );
  }
}
