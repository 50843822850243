import EntityTileViewItemGhostLoading from './ghost/EntityTileViewItemGhostLoading';

const TileGridGhostLoading = () => {
  const finalResult = [...Array(12)].map((_, i) => (
    <EntityTileViewItemGhostLoading noBorderTop={i === 0 || undefined} />
  ));

  return <>{finalResult}</>;
};

export default TileGridGhostLoading;
