import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import { url } from '../../../../config';

const NotYourTeamWrapper = styled.div`
  font-size: 14px;
`;

const NotYourTeam = () => {
  return (
    <NotYourTeamWrapper>
      <FormattedMessage
        id="module-auth-notYourTeam"
        defaultMessage="Not your team?"
      />
      <a
        className="test_auth_signIn_differentAccount"
        style={{ marginLeft: '6px' }}
        href={`${url.mainApp}`}
        data-test="select-different-account-link"
      >
        <FormattedMessage
          id="module-auth-goBack"
          defaultMessage="Select a different account"
        />
      </a>
      .
    </NotYourTeamWrapper>
  );
};

export default pure(NotYourTeam);
