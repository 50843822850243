import React from 'react';

import {
  GroupedEntitiesPanelHeaderWrapper,
  GroupedEntitiesPanelHeaderTitle,
} from './GroupedEntitiesPanelHeader.styles';

const GroupedEntitiesPanelHeader = ({ dataCount, edgeType, labels = {} }) => {
  const edgeTypeToUse =
    edgeType?.toLowerCase() === 'grouped'
      ? labels?.groupedRecordsLabel
      : edgeType;

  return (
    <GroupedEntitiesPanelHeaderWrapper>
      <GroupedEntitiesPanelHeaderTitle>
        {labels?.title}
      </GroupedEntitiesPanelHeaderTitle>
      <div style={{ display: 'flex' }}>
        {labels?.edgeType}:&nbsp;
        <strong>{edgeTypeToUse}</strong>
      </div>

      <div style={{ display: 'flex' }}>
        {labels?.totalEdges}:&nbsp;
        <strong>{dataCount}</strong>
      </div>
    </GroupedEntitiesPanelHeaderWrapper>
  );
};

export default GroupedEntitiesPanelHeader;
