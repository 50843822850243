import styled from 'styled-components';

export const PersonProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border: 1px solid #ececec;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 24px;
`;

export const JobTitleWrapper = styled.div`
  color: inherit;
  fontsize: 14px;
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
  padding: 0 8px 8px 8px;
`;

export const PersonProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 12px;
`;

export const ProfileName = styled.div`
  text-align: center;
  padding: 0 8px 8px 8px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  word-break: break-word;
  color: inherit;
`;

export const DateWrapper = styled.div`
  padding-bottom: 4px;
  font-size: 12px;
  color: #707070;
  display: flex;
  justify-content: center;
`;

export const DateLabel = styled.div`
  margin-right: 4px;
`;

export const PersonProfileTagWrapper = styled.div`
  text-align: center;
  padding: 0 60px 12px 60px;
`;
