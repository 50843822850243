import { memo } from 'react';
import styled from 'styled-components';
import { DataTreeView, List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { useIntl } from 'react-intl';

import { ExpandedRow } from './ExpandedRow';
import { getColumns } from './getColumns';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const hasMatch = (string = '', search = '') =>
  string.toLowerCase().includes(search);

export const GoldenRecordViewPropertiesPanelList = memo(
  ({ data, query, loading, setQuery }) => {
    const intl = useIntl();

    const total = data.reduce(
      (prev, curr) => prev + curr.entityProps.length,
      0,
    );

    const filtered = data
      .map((i) => ({
        ...i,
        entityProps: i.entityProps.filter((prop) => hasMatch(prop.name, query)),
      }))
      .filter((i) => i.entityProps.length > 0);

    const searchTotal = filtered.reduce(
      (prev, curr) => prev + curr.entityProps.length,
      0,
    );

    return (
      <Wrapper>
        <div style={{ flex: 1 }}>
          <List
            data={filtered}
            loading={loading}
            offsetTop={295}
            selectable={false}
            showPagination={false}
            DataGrid={DataTreeView}
            columns={getColumns(intl)}
            secondLevelDataKey="entityProps"
            searchName={query}
            hasFilters={!!query}
            onSubmitSearch={setQuery}
            onChangeSearch={setQuery}
            searchFormWidth="50%"
            searchInputPlaceholder={intl.formatMessage({
              id: 'module-entity-searchByProperty',
            })}
            notFoundProps={{
              message: (
                <FormattedMessage id="module-goldenRecordList-noProps-msg" />
              ),
              explanation: (
                <FormattedMessage id="module-coreEntityList-addPropsExplanation" />
              ),
              noCreate: true,
            }}
            noSearchFoundProps={{
              message: (
                <FormattedMessage id="module-goldenRecordList-noProps-msg" />
              ),
              explanation: (
                <FormattedMessage id="module-goldenRecordList-noProps-search-text" />
              ),
              noCreate: true,
            }}
            RowExpandedComponent={({ entityProps }) => (
              <ExpandedRow entityProps={entityProps} />
            )}
          />
        </div>

        {filtered?.length > 0 && (
          <div style={{ padding: '20px' }}>
            <strong>
              {intl.formatMessage({ id: 'module-entity-properties' })}:{' '}
              {searchTotal}/{total}
            </strong>
          </div>
        )}
      </Wrapper>
    );
  },
);
