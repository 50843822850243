import get from 'lodash/get';

import {
  useSuggestedSearches,
  emptySuggestedSearch,
} from './hooks/useSuggestedSearches';
import { useGetPageTemplateById } from '../../entityPageLayout/hooks/useGetPageTemplateById';
import { useQueryConfigurationSettingsForUser } from '../../security/hooks/useConfigurationSettings';

import { useGrid } from './hooks/useGrid';
import Layout from './Layout';

const metricsGlobalDisabled = () => ({
  name: 'GlobalMetricsDisable',
  place: 'main',
  parameters: {},
});

const metricsGlobalDisabledInLayout = () => ({
  name: 'MetricsDisableInLayout',
  place: 'main',
  parameters: {},
});

const OverviewLayout = ({ entity, isFetching, rootUrl, pageTemplateId }) => {
  const [
    pageTemplateData,
    pageTemplateLoading,
    pageTemplateError,
    pageTemplateRefetch,
  ] = useGetPageTemplateById(pageTemplateId);
  const layoutTemplate = get(pageTemplateData, 'layoutTemplate');

  const [globalMetricData, metricsLoading] =
    useQueryConfigurationSettingsForUser([
      'Metrics.Enabled',
      'Metrics.accuracy.Enabled',
      'Metrics.completeness.Enabled',
      'Metrics.connectivity.Enabled',
      'Metrics.relevance.Enabled',
    ]);

  const isMetricsEnable = get(globalMetricData, 'Metrics.Enabled') === 'true';
  const accuracyEnabled =
    get(globalMetricData, 'Metrics.accuracy.Enabled') === 'true';
  const completenessEnabled =
    get(globalMetricData, 'Metrics.completeness.Enabled') === 'true';
  const connectivityEnabled =
    get(globalMetricData, 'Metrics.connectivity.Enabled') === 'true';
  const relevanceEnabled =
    get(globalMetricData, 'Metrics.relevance.Enabled') === 'true';

  const [grid, gridLoading, gridError] = useGrid(layoutTemplate?.DisplayName);

  const extraWidgets = useSuggestedSearches(entity, {
    placeSuggestedSearches: pageTemplateData?.placeSuggestedSearches,
    includeSuggestedSearches: pageTemplateData?.includeSuggestedSearches,
  });

  const hasNoSuggestedSearchEvenIfEnabled =
    pageTemplateData?.includeSuggestedSearches &&
    entity?.SuggestedSearches.length === 0;

  let allWidgets = pageTemplateData?.widgets || [];

  if (
    !pageTemplateData?.includeMetrics ||
    (!isMetricsEnable && !metricsLoading)
  ) {
    allWidgets = allWidgets?.filter((w) => w.name !== 'EntityDataKPIs');
  }

  if (extraWidgets?.length > 0) {
    allWidgets = [...allWidgets, ...extraWidgets];
  }

  if (
    !pageTemplateData?.includeSuggestedSearches ||
    entity?.SuggestedSearches.length === 0
  ) {
    const suggestedSearchWidget = emptySuggestedSearch(pageTemplateData);
    allWidgets = [...allWidgets, suggestedSearchWidget];
  }

  if (!isMetricsEnable && !metricsLoading) {
    const metricsWidget = metricsGlobalDisabled(pageTemplateData);
    allWidgets = [metricsWidget, ...allWidgets];
  }

  if (!pageTemplateData?.includeMetrics && isMetricsEnable) {
    const metricsWidget = metricsGlobalDisabledInLayout(pageTemplateData);
    allWidgets = [metricsWidget, ...allWidgets];
  }

  return (
    <div>
      {!isFetching && !pageTemplateLoading && !pageTemplateError && (
        <Layout
          template={{
            ...pageTemplateData,
            widgets: allWidgets,
            hasNoSuggestedSearchEvenIfEnabled,
            accuracy: accuracyEnabled,
            completeness: completenessEnabled,
            connectivity: connectivityEnabled,
            relevance: relevanceEnabled,
            rootUrl,
          }}
          // grid={layoutTemplate?.GridConfiguration}
          grid={grid}
          isMain
          entity={entity}
        />
      )}
    </div>
  );
};

export default OverviewLayout;
