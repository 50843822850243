import React, { memo } from 'react';

import { RuleItem } from './RuleItem';
import { RuleCondition } from './RuleCondition';

export const RuleList = memo(
  ({
    scope,
    errors,
    isChild,
    operators,
    rules = [],
    objectTypes,
    onChangeRule,
    onRemoveRule,
    onValueChange,
    onRemoveRuleItem,
    onPropertyChange,
    onObjectTypeChange,
    actionConfiguration,
    onChangeOperatorType,
    onMultipleValueChange,
    VocabularyAutoComplete,
    ruleConditionIndex = 0,
    hasStrongTypingFeature,
    PropertyItemAutoComplete,
    useQueryVocabularyKeyByKey,
    VocabularyValueAutoComplete,
    previewMode,
  }) => {
    return (
      <>
        {rules.map((ruleItem, i) => {
          if (ruleItem.type === 'rule') {
            return (
              <RuleCondition
                scope={scope}
                rule={ruleItem}
                errors={errors}
                isChild={isChild}
                ruleItemIndex={i}
                operators={operators}
                key={`condition-${i}`}
                previewMode={previewMode}
                objectTypes={objectTypes}
                onChangeRule={onChangeRule}
                onRemoveRule={onRemoveRule}
                actionConfiguration={actionConfiguration}
                ruleConditionIndex={ruleConditionIndex + 1}
                hasStrongTypingFeature={hasStrongTypingFeature}
                VocabularyAutoComplete={VocabularyAutoComplete}
                PropertyItemAutoComplete={PropertyItemAutoComplete}
                useQueryVocabularyKeyByKey={useQueryVocabularyKeyByKey}
                VocabularyValueAutoComplete={VocabularyValueAutoComplete}
              />
            );
          }

          return (
            <RuleItem
              scope={scope}
              errors={errors}
              key={`item-${i}`}
              ruleItemIndex={i}
              ruleItem={ruleItem}
              operators={operators}
              objectTypes={objectTypes}
              onValueChange={onValueChange}
              onRemoveRuleItem={onRemoveRuleItem}
              onPropertyChange={onPropertyChange}
              onObjectTypeChange={onObjectTypeChange}
              actionConfiguration={actionConfiguration}
              onChangeOperatorType={onChangeOperatorType}
              onMultipleValueChange={onMultipleValueChange}
              VocabularyAutoComplete={VocabularyAutoComplete}
              hasStrongTypingFeature={hasStrongTypingFeature}
              PropertyItemAutoComplete={PropertyItemAutoComplete}
              useQueryVocabularyKeyByKey={useQueryVocabularyKeyByKey}
              VocabularyValueAutoComplete={VocabularyValueAutoComplete}
              ruleConditionIndex={
                typeof ruleConditionIndex === 'number' ? ruleConditionIndex : 0
              }
              previewMode={previewMode}
            />
          );
        })}
      </>
    );
  },
);
