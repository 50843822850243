const reportStyles = {
  title: {
    padding: '0 16px',
    textAlign: 'center',
    fontSize: '1.4em',
    margin: '4px auto',
  },
  titleBig: {
    padding: '0 16px',
    textAlign: 'center',
    fontSize: '2.6em',
    margin: '4px auto',
  },
  subTitle: {
    padding: '0 16px',
    textAlign: 'center',
    color: 'grey',
    margin: '4px auto',
  },
  card: {
    background: '#ffffff',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  cardWithRatio: { maxWidth: '560px' },
};

export default reportStyles;
