const makeRounded = (n) => Math.round(n * 100) / 100;

const TimeSpanComponent = ({ value }) => {
  const valueInt = parseInt(value, 10);

  const inHours = valueInt / 3600;

  if (inHours < 25) {
    return `${Math.round(makeRounded(inHours))} hours`;
  }

  const isDays = valueInt / 86400;

  if (isDays < 8) {
    return `${Math.round(makeRounded(isDays))} days`;
  }

  const isWeek = isDays / 7;

  if (isWeek < 53) {
    return `${Math.round(makeRounded(isWeek))} weeks`;
  }

  const year = isDays / 365;

  return `${Math.round(makeRounded(year), 10)} years`;
};

export default TimeSpanComponent;
