import React from 'react';
import styled from 'styled-components';
import { pure } from 'recompose';

const PublicRootWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  input::-ms-clear {
    display: none;
  }
`;

const NotLoggedInLayout = ({ Page, ...restOfProps }) => (
  <PublicRootWrapper>
    <Page {...restOfProps} />
  </PublicRootWrapper>
);

NotLoggedInLayout.displayName = 'NotLoggedInLayout';

export default pure(NotLoggedInLayout);
