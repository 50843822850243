import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const UnstyledLinkWrapper = styled.span`
  a,
  button,
  button:hover,
  a:hover {
    text-decoration: none;
    opacity: ${({ disabled }) => (!disabled ? 1 : 0.3)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

const UnstyledLink = (props) => (
  <UnstyledLinkWrapper
    style={props.wrapperStyle || {}}
    disabled={props?.disabled}
  >
    <Link
      {...props}
      style={{ textDecoration: 'none !important', ...(props.style || {}) }}
    />
  </UnstyledLinkWrapper>
);

export default UnstyledLink;
