import React from 'react';
import ConfigurationPanelContentItem from './ConfigurationPanelContentItem';

const ConfigurationPanelContent = ({ list, extra = null }) => (
  <div style={{ padding: '16px 16px 96px 16px', width: 'calc(100% - 32px)' }}>
    {list.map((conf) => {
      return (
        <ConfigurationPanelContentItem key={conf.Id} configuration={conf} />
      );
    })}
    {extra}
  </div>
);

export default ConfigurationPanelContent;
