import { getLogoFromGql, getIcon } from './logo';
import { hasEntityConfigSetup } from '../../../../../wms/helpers/widgetHelper';
import { uniqProviderBasedOnUrisAndProviderCodes } from '../../../../../entity/selectors';
import { createPersonViewModelFromGql } from './person';
import { createOrganizationViewModelFromGql } from './organization';
import { getEntityTemporalDataFromGql } from './entityDates';

const createSearchResultViewModelFromGql = (
  entity = {},
  entityConfigurations = [],
) => {
  const {
    id,
    name,
    entityType,
    description,
    uris,
    providerOrigins,
    properties,
    createdDate: originalCreatedDate,
  } = entity;

  const logo = getLogoFromGql(entity);
  const icon = getIcon(entity, entityConfigurations);
  const isEntityConfigured = hasEntityConfigSetup(
    entityType,
    entityConfigurations,
  );
  const createdDate = getEntityTemporalDataFromGql(entity);
  const providers = uniqProviderBasedOnUrisAndProviderCodes(
    uris,
    providerOrigins || [],
  );
  const numberProperties = Object.keys(properties || {}).length || 1;

  return {
    entityId: id,
    entityType,
    name,
    description: description || 'no snippet',
    logo,
    icon,
    createdDate,
    providers,
    isEntityConfigured,
    created: originalCreatedDate,
    properties,
    numberProperties: numberProperties - 1, // without attribute-type
  };
};

const createSearchResultViewModelsFromGql = (
  entities = [],
  entityConfigurations,
) =>
  entities.map((entity) =>
    createSearchResultViewModelFromGql(entity, entityConfigurations),
  );

const createPersonViewModelsFromGql = (entities = [], entityConfigurations) =>
  entities.map((entity) =>
    createPersonViewModelFromGql(entity, entityConfigurations),
  );

const createOrganizationViewModelsFromGql = (
  entities = [],
  entityConfigurations,
) =>
  entities.map((entity) =>
    createOrganizationViewModelFromGql(entity, entityConfigurations),
  );

const createDocumentViewModelsFromGql = (entities = [], entityConfigurations) =>
  entities.map((entity) =>
    createSearchResultViewModelFromGql(entity, entityConfigurations),
  );

export const createVmFromgGql = (
  entities = [],
  vmType,
  entityConfigurations,
) => {
  if (vmType === 'searchResult') {
    return createSearchResultViewModelsFromGql(
      entities || [],
      entityConfigurations,
    );
  } else if (vmType === 'person') {
    return createPersonViewModelsFromGql(entities || [], entityConfigurations);
  } else if (vmType === 'organization') {
    return createOrganizationViewModelsFromGql(
      entities || [],
      entityConfigurations,
    );
  } else if (vmType === 'document') {
    return createDocumentViewModelsFromGql(
      entities || [],
      entityConfigurations,
    );
  }

  // TODO: fall back here - no idea why
  return createSearchResultViewModelsFromGql(
    entities || [],
    entityConfigurations,
  );
};
