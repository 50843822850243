import { useState } from 'react';

const getSortValue = (sortType, q) => {
  if (sortType === 'new') {
    return {
      sort: 'DATE',
      sortDirection: 'DESCENDING',
    };
  }

  if (sortType === 'old') {
    return {
      sort: 'DATE',
      sortDirection: 'ASCENDING',
    };
  }

  if (sortType === 'relevance') {
    return {
      sort: 'RELEVANCE',
      sortDirection: 'DESCENDING',
    };
  }

  return {
    sort: 'RELEVANCE',
    sortDirection: 'DESCENDING',
  };
};

export const useSearchSort = (sortQueryString, q) => {
  const defaultSortType =
    (!q || q === '*') && !sortQueryString ? 'new' : 'relevance';

  const [sortType, setSortType] = useState(defaultSortType);

  const sortValue = getSortValue(sortType, q);

  return [
    sortValue,
    sortType,
    (sortType) => {
      setSortType(sortType);
    },
  ];
};
