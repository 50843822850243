import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  shouldPlay,
  shouldPause,
  shouldApprove,
  shouldReject,
  shouldReAuth,
  shouldFetchProductOwner,
  shouldHideProductOwner,
  shouldReprocess,
} from '../../../actions';
import ConfigurationQuickView from '../../composites/quickViews/ConfigurationQuickView';
import { close } from '../../../../quickview/actions';
import UserPickerDialog from '../../../../core/components/composites/UserPickerDialog';
import SetProviderOwnerContainer from '../SetProviderOwnerContainer';

const ConfigurationQuickViewWithDialog = (props) => (
  <div>
    <UserPickerDialog
      show={props.isShowingProductOwner}
      style={{ display: 'inline' }}
      onClose={props.hideProductOwner}
      SetProviderOwnerComponent={SetProviderOwnerContainer}
    />
    <ConfigurationQuickView {...props} />
  </div>
);

const mapStateToProps = ({
  user: { currentUser },
  quickView: { configuration },
  configuration: {
    playConfigurations,
    pauseConfigurations,
    approveConfigurations,
    rejectConfigurations,
    isShowingProductOwner,
  },
}) => ({
  isShowingProductOwner,
  currentUser,
  configuration,
  open: !!configuration,
  isLoadingPlay: configuration
    ? (playConfigurations[configuration.Id] || {}).isFetching
    : false,
  isLoadingPause: configuration
    ? (pauseConfigurations[configuration.Id] || {}).isFetching
    : false,
  isLoadingApprove: configuration
    ? (approveConfigurations[configuration.Id] || {}).isFetching
    : false,
  isLoadingReject: configuration
    ? (rejectConfigurations[configuration.Id] || {}).isFetching
    : false,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductOwner(providerDefinitionId) {
    dispatch(shouldFetchProductOwner(providerDefinitionId));
  },
  onClose() {
    dispatch(close());
  },
  play({ ProviderId, Id, AccountId }) {
    dispatch(
      shouldPlay({
        providerId: ProviderId,
        accountId: AccountId,
        id: Id,
      }),
    );
  },
  pause({ ProviderId, AccountId, Id }) {
    dispatch(
      shouldPause({
        providerId: ProviderId,
        accountId: AccountId,
        id: Id,
      }),
    );
  },
  approve(configuration) {
    dispatch(
      shouldApprove({
        id: configuration.Id,
        data: {
          Id: configuration.Id,
          helperConfiguration: {},
          Source: configuration.Source,
          SourceQuality: configuration.SourceQuality,
          AutoSync: configuration.AutoSync || true,
          AccountDisplay: configuration.AccountDisplay,
        },
      }),
    );
  },
  reject({ Id, ProviderId }) {
    dispatch(
      shouldReject({
        id: Id,
        providerId: ProviderId,
      }),
    );
  },
  reProcess(configId) {
    dispatch(shouldReprocess(configId));
  },
  reAuth(configuration, integrationConfig) {
    dispatch(shouldReAuth(configuration, integrationConfig));
  },
  viewSetting(configuration) {
    dispatch(
      push(
        `/admin/configuration/${configuration.ProviderId}/${configuration.Id}/settings`,
      ),
    );
  },
  viewPermissions(configuration) {
    dispatch(
      push(
        `/admin/configuration/${configuration.ProviderId}/${configuration.Id}/permissions`,
      ),
    );
  },
  hideProductOwner() {
    dispatch(shouldHideProductOwner());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigurationQuickViewWithDialog);
