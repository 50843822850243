import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import { RACI_LEVELS } from '@cluedin/components';

export default {
  pillar: 'inbound',
  name: 'datasource',
  displayName: <FormattedMessage id="module-datasources-allDataSources" />,
  title: <FormattedMessage id="module-datasources-allDataSources" />,
  path: '/datasourceset',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Upload',
        name: <FormattedMessage id="module-datasources-allDataSources" />,
        link: '/admin/inbound/datasourceset',
        claims: {
          'integration.datasourcegroups': RACI_LEVELS.INFORMED,
          'integration.configuredintegrations': RACI_LEVELS.INFORMED,
          'management.annotation': RACI_LEVELS.INFORMED,
          'consume.exporttargets': RACI_LEVELS.INFORMED,
          'management.datacatalog': RACI_LEVELS.INFORMED,
        },
      },
    ],
  },
  routes,
};
