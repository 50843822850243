import React from 'react';
import styled from 'styled-components';
import Text from 'uxi/Text';
import { Arrowdown, Arrowup } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';

const HelpSectionWrapper = styled.div`
  background: rgb(242, 242, 242);
  padding: 15px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: ${({ isFirst }) => (isFirst ? '1px' : '0')} solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
`;

const HelpButtonLink = styled(ButtonLink)`
  &:hover {
    color: ${({ themePrimary }) => themePrimary};
  }
`;

const HelpSectionContent = styled.div`
  flex: 1;
`;

const HelpTopicSection = styled.div`
  background: rgba(242, 242, 242, 0.7);
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 15px 15px 30px;
`;

const ArrowSection = styled.div`
  width: 20px;
`;

const HelpTopic = ({ topic, onTopicClick, themePrimary }) => (
  <div style={{ margin: '2px 0' }}>
    <HelpButtonLink
      text={topic.title}
      onClick={() => {
        onTopicClick({ topicId: topic.id, type: 'module' });
      }}
      themePrimary={themePrimary}
    />
  </div>
);

const HelpSection = ({
  section,
  themePrimary,
  isSelected = false,
  onTopicClick,
  isFirst,
  setSectionExpanded,
}) => (
  <div
    onClick={() => {
      setSectionExpanded(section.id);
    }}
  >
    <HelpSectionWrapper isFirst={isFirst}>
      <HelpSectionContent>
        <div>
          <Text type="Heading">
            {section.title}
            {/* <ButtonLink
              text={section.title}
              onClick={() => {
                onTopicClick({
                  topicId: section.id,
                  type: 'pillar',
                });
              }}
            /> */}
          </Text>
        </div>
        <div>
          <Text>{section.excerpt}</Text>
        </div>
      </HelpSectionContent>
      <ArrowSection>
        {!isSelected && <Arrowdown size={20} />}
        {isSelected && <Arrowup size={20} />}
      </ArrowSection>
    </HelpSectionWrapper>
    {isSelected && (
      <HelpTopicSection>
        {section.modules.map((t, i) => (
          <HelpTopic
            key={i.title}
            onTopicClick={onTopicClick}
            topic={t}
            themePrimary={themePrimary}
          />
        ))}
      </HelpTopicSection>
    )}
  </div>
);

export default HelpSection;
