import configureRefreshFetch from './refreshToken';
import merge from 'lodash/merge';
import { getCurrentToken } from './storage';
import { getAntiForgeryToken, getNewToken } from './token';

const fetchWithToken = (url, options = {}) => {
  const bearer = options.token || getCurrentToken();
  const AuthorizationValue = bearer ? `Bearer ${bearer}` : null;
  const forgeryToken = getAntiForgeryToken();

  const forgeryTokenHeaders = forgeryToken
    ? {
        RequestVerificationToken: forgeryToken,
      }
    : {};

  let optionsWithToken = options;
  if (AuthorizationValue != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${bearer}`,
        ...forgeryTokenHeaders,
      },
    });
  }

  return fetch(`${url}`, optionsWithToken);
};

const refreshFetch = configureRefreshFetch({
  // Pass fetch function you want to wrap, it should already be adding
  // token to the request
  fetch: fetchWithToken,
  // shouldRefreshToken is called when API fetch fails and it should decide
  // whether the response error means we need to refresh token
  shouldRefreshToken: (resp) => {
    return resp.status === 401;
  },
  // refreshToken should call the refresh token API, save the refreshed
  // token and return promise -- resolving it when everything goes fine,
  // rejecting it when refreshing fails for some reason
  refreshToken: () => {
    return getNewToken();
  },
});

export default refreshFetch;
