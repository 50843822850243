import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { ButtonLink } from '@cluedin/atoms';
import Close from 'uxi/Icons/Close';

import NotificationWrapper from './NotificationWrapper';
import NotificationContentWrapper from './NotificationContentWrapper';
import NotificationIconWrapper from './NotificationIconWrapper';
import NotificationTitle from './NotificationTitle';
import NotificationSource from './NotificationSource';
import NotificationDescription from './NotificationDescription';
import NotificationActionWrapper from './NotificationActionWrapper';
import NotificationDate from './NotificationDate';
import NotificationLink from './NotificationLink';

const ViewNotificationButton = styled(ButtonLink)`
  &:hover {
    color: ${({ theme }) => theme.palette.themePrimary};
  }
`;

const Notification = ({
  id,
  IconComponent,
  source,
  title,
  description,
  date,
  alertType,
  actions,
  showShadow,
  clearNotification,
  tabIndex,
  onClose,
  style,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const themePrimary = theme.palette.themePrimary;

  return (
    <NotificationWrapper
      showShadow={showShadow}
      className="notification"
      tabIndex={tabIndex}
      style={style}
    >
      {showShadow && (
        <div
          onClick={() => clearNotification(id)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <Close size={16} />
        </div>
      )}
      {IconComponent && (
        <NotificationIconWrapper
          themePrimary={themePrimary}
          alertType={alertType}
        >
          <IconComponent color="#fff" size={14} />
        </NotificationIconWrapper>
      )}
      <NotificationContentWrapper>
        <NotificationSource>{source}</NotificationSource>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationDescription>{description}</NotificationDescription>

        <NotificationActionWrapper>
          <NotificationDate>{date}</NotificationDate>
          <NotificationLink>
            {(actions || []).map((a, index) => (
              <ViewNotificationButton
                key={`action-link-${index}`}
                text={a.title}
                theme={theme}
                onClick={() => {
                  history.push(a.actionLink);
                  onClose?.();
                }}
              />
            ))}
          </NotificationLink>
        </NotificationActionWrapper>
      </NotificationContentWrapper>
    </NotificationWrapper>
  );
};

export default Notification;
