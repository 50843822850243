import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SimpleGrid } from 'uxi/Layout';
import DashboardSection from './DashboardSection';
import { widgetDashboardTypes } from './config';
import DrawerWrapper from './DrawerWrapper';

const Dashboard = ({
  style,
  match,
  dashboardDescriptor,
  isOpen: isOpenProp,
}) => {
  if (!match) {
    console.warn(
      'Dashboard needs match from router if you want to use Drawers',
    );
  }

  const subRoutesMatches = match.url.match(/\/home\/(.*)/);
  const isOpen =
    isOpenProp !== undefined // eslint-disable-line no-unused-vars
      ? isOpenProp
      : !(subRoutesMatches && subRoutesMatches[0]);

  return (
    <div style={{ padding: '16px', margin: '16px 0 32px 0', ...style }}>
      {dashboardDescriptor.map((sectionDescriptor, idx) => {
        const { widgets, title, gridProps, emptyWidgets } = sectionDescriptor;

        return (
          <DashboardSection title={title} key={idx}>
            {widgets && widgets.length > 0 && (
              <SimpleGrid
                columnAutoSizing={'fill'}
                gap={16}
                itemWidth={240}
                itemHeight={'auto'}
                style={{ width: '100%', margin: '8px 0' }}
                {...gridProps}
              >
                {widgets.map((widgetDescriptor, idx) => {
                  const Comp = widgetDashboardTypes[widgetDescriptor.type];
                  if (widgetDescriptor.type === 'custom') {
                    if (widgetDescriptor.render) {
                      return widgetDescriptor.render(widgetDescriptor);
                    }
                    return widgetDescriptor.content || null;
                  }

                  return (
                    <div
                      key={idx}
                      data-test={`widget-${
                        widgetDescriptor.drawer?.route ||
                        widgetDescriptor.label.props?.id
                      }`}
                    >
                      <Comp
                        {...widgetDescriptor}
                        match={match}
                        key={widgetDescriptor.label}
                      />
                    </div>
                  );
                })}
              </SimpleGrid>
            )}
            {widgets && widgets.length === 0 && emptyWidgets && (
              <>{emptyWidgets}</>
            )}
          </DashboardSection>
        );
      })}

      <DrawerWrapper
        match={match}
        open={isOpen}
        homeDashboardDescriptor={dashboardDescriptor}
      />
    </div>
  );
};

Dashboard.displayName = 'Dashboard';

Dashboard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Dashboard);
