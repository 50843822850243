"use strict";

exports.__esModule = true;
exports.useActiveFilter = void 0;
var _findKey = _interopRequireDefault(require("lodash/findKey"));
var _hooks = require("../../../hooks");
var _useResetPageNo = require("./useResetPageNo");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var valueMap = {
  true: true,
  false: false,
  null: null
};
var setActiveStatus = function setActiveStatus(isActive, setValue, removeValue, resetPageNo) {
  return function (value) {
    if (value === isActive) {
      return;
    }
    var newValue = (0, _findKey.default)(valueMap, function (v) {
      return v === value;
    });
    if (newValue === 'null') {
      removeValue();
    } else {
      setValue(newValue);
    }
    resetPageNo();
  };
};
var useActiveFilter = function useActiveFilter(history, location, pageNoLabel) {
  if (pageNoLabel === void 0) {
    pageNoLabel = 'pageNo';
  }
  var _useQueryString = (0, _hooks.useQueryString)(history, location, 'isActive'),
    currentValue = _useQueryString[0],
    setValue = _useQueryString[1],
    removeValue = _useQueryString[2];
  var resetPageNo = (0, _useResetPageNo.useResetPageNo)(history, location, pageNoLabel);
  var isActive = valueMap[currentValue];
  return [isActive, setActiveStatus(isActive, setValue, removeValue, resetPageNo)];
};
exports.useActiveFilter = useActiveFilter;