import { Externallink } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';

import EntityLinkFromEntityContainer from '../../../../../wms/components/containers/EntityLinkFromEntityContainer';

const FormattedEntityName = ({
  referencedEntityType,
  referencedEntityId,
  referencedEntityName,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <EntityLinkFromEntityContainer
      entity={{
        id: referencedEntityId,
        entityType: referencedEntityType,
      }}
      style={{ color: themePrimary }}
      target="_blank"
    >
      {referencedEntityName}
      <Externallink size={12} style={{ marginLeft: '6px' }} />
    </EntityLinkFromEntityContainer>
  );
};

export default FormattedEntityName;
