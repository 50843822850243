"use strict";

exports.__esModule = true;
exports.easing = exports.duration = exports.default = void 0;
var _excluded = ["duration", "easing", "delay"];
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
// Credit to material-ui: https://raw.githubusercontent.com/mui-org/material-ui/next/packages/material-ui/src/styles/transitions.js
// This will change as we do not want material UI animation

var easing = {
  // This is the most common easing curve.
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  // Objects enter the screen at full velocity from off-screen and
  // slowly decelerate to a resting point.
  easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
  // Objects leave the screen at full velocity. They do not decelerate when off-screen.
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  // The sharp curve is used by objects that may return to the screen at any time.
  sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
};

// to learn when use what timing
exports.easing = easing;
var duration = {
  shortest: 150,
  shorter: 200,
  short: 250,
  // most basic recommended timing
  standard: 300,
  // this is to be used in complex animations
  complex: 375,
  // recommended when something is entering screen
  enteringScreen: 225,
  // recommended when something is leaving screen
  leavingScreen: 195
};
exports.duration = duration;
function formatMs(milliseconds) {
  return Math.round(milliseconds) + "ms";
}

/**
 * @param {string|Array} props
 * @param {object} param
 * @param {string} param.prop
 * @param {number} param.duration
 * @param {string} param.easing
 * @param {number} param.delay
 */
var _default = {
  easing: easing,
  duration: duration,
  create: function create(props, options) {
    if (props === void 0) {
      props = ['all'];
    }
    if (options === void 0) {
      options = {};
    }
    var _options = options,
      _options$duration = _options.duration,
      durationOption = _options$duration === void 0 ? duration.standard : _options$duration,
      _options$easing = _options.easing,
      easingOption = _options$easing === void 0 ? easing.easeInOut : _options$easing,
      _options$delay = _options.delay,
      delay = _options$delay === void 0 ? 0 : _options$delay,
      other = _objectWithoutPropertiesLoose(_options, _excluded);
    if (process.env.NODE_ENV !== 'production') {
      var isString = function isString(value) {
        return typeof value === 'string';
      };
      var isNumber = function isNumber(value) {
        return !isNaN(parseFloat(value));
      };
      if (!isString(props) && !Array.isArray(props)) {
        console.error('Library-UI: Argument "props" must be a string or Array.');
      }
      if (!isNumber(durationOption) && !isString(durationOption)) {
        console.error("Library-UI: Argument \"duration\" must be a number or a string but found " + durationOption + ".");
      }
      if (!isString(easingOption)) {
        console.error('Library-UI: Argument "easing" must be a string.');
      }
      if (!isNumber(delay) && !isString(delay)) {
        console.error('Library-UI: Argument "delay" must be a number or a string.');
      }
      if (Object.keys(other).length !== 0) {
        console.error("Library-UI: Unrecognized argument(s) [" + Object.keys(other).join(',') + "].");
      }
    }
    return (Array.isArray(props) ? props : [props]).map(function (animatedProp) {
      return animatedProp + " " + (typeof durationOption === 'string' ? durationOption : formatMs(durationOption)) + " " + easingOption + " " + (typeof delay === 'string' ? delay : formatMs(delay));
    }).join(',');
  },
  getAutoHeightDuration: function getAutoHeightDuration(height) {
    if (!height) {
      return 0;
    }
    var constant = height / 36;

    // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
    return Math.round((4 + 15 * Math.pow(constant, 0.25) + constant / 5) * 10);
  }
};
exports.default = _default;