import styled from 'styled-components';
import { useQueryPropertyFromObjectType } from '../../../hooks/useQueryPropertyFromObjectType';
import { getGlossaryTermLabel } from '../../../utils/getGlossaryTermLabel';

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledLabel = styled.span`
  font-size: 14px;
  line-height: 1.5;
`;

const StyledInputPreview = styled.span`
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #d7d7d8;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 8px;
  height: 32px;
`;

type Props = {
  label?: string;
  objectTypeId?: string;
  text: string;
};

const RuleItemPreview = ({ label, text, objectTypeId }: Props) => {
  const [propertiesToChooseFrom] = useQueryPropertyFromObjectType(objectTypeId);

  const displayName = getGlossaryTermLabel(
    propertiesToChooseFrom?.find((i: { key: string }) => i.key === text),
  );

  return (
    <StyledWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledInputPreview>{displayName || text}</StyledInputPreview>
    </StyledWrapper>
  );
};

export default RuleItemPreview;
