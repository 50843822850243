import { useContext } from 'react';

import { AppSearchContext } from './AppSearchContext';
import { SearchStore } from './useAppSearchStore';

export const useAppSearch = (): SearchStore => {
  const searchData = useContext(AppSearchContext);

  if (!searchData) {
    throw new Error(
      'Make sure you are using `useAppSearch` inside AppSearchProvider!',
    );
  }

  return searchData;
};
