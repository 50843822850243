import {
  createContext,
  FC,
  ReactNode,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { toast, Id, Slide } from 'react-toastify';
import { useResumeFileUpload } from '../../../hooks/useResumeFileUpload';
import FileUploadToaster from './FileUploadToaster';
import { UploadFileProgressContext } from '../UploadFileProgress/UploadFileProgressContext';
import {
  FileUploadRequestWithProgress,
  DataSourceMetaData,
  FileUploadRequest,
  FileUploadRequestProgress,
} from '../../../types';
import { useOrganizationFeatureFlagOption } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';

type MultiUploaderProviderProps = {
  children: ReactNode;
};

export type FileDataSource = {
  file: File;
  dataSourceName: string;
  dataSourceNameError: string;
};

export type MultiUploaderContextType = {
  uploadFile: (
    file: File,
    meta: DataSourceMetaData,
    onCompleted?: () => void,
  ) => Promise<FileUploadRequest | undefined>;
  abortFileUpload: (fileUploadRequest: FileUploadRequest) => void;
  resumeFileUpload: (fileUploadRequest: FileUploadRequest) => void;
  cancelFileUpload: (fileUploadRequest: FileUploadRequest) => void;
  fileUploadRequests: FileUploadRequest[];
};

export const MultiUploaderContext =
  createContext<MultiUploaderContextType | null>(null);

export const useMultiUploader = (): MultiUploaderContextType | null => {
  return useContext(MultiUploaderContext);
};

type ToastAndFile = {
  fileId: string;
  toastId: Id;
};

function handleBeforeUpload(e: {
  preventDefault: () => void;
  returnValue: boolean;
}) {
  e.preventDefault();
  e.returnValue = true;
}

export const MultiUploaderProvider: FC<MultiUploaderProviderProps> = ({
  children,
}) => {
  const hasNewFileStatus = useOrganizationFeatureFlagOption('fileUploadStatus');
  const toastIds = useRef<ToastAndFile[]>([]);

  const [fileUploadProgress, setFileUploadProgress] =
    useState<FileUploadRequestWithProgress[]>();

  const {
    fileUploadRequests,
    uploadFile,
    abortFileUpload,
    resumeFileUpload,
    cancelFileUpload,
  } = useResumeFileUpload({
    onUpload: (fileUploadRequest: FileUploadRequest | undefined) => {
      if (!hasNewFileStatus) {
        // @ts-expect-error the props are injected by toast
        const toastId = toast(<FileUploadToaster />, {
          transition: Slide,
          containerId: 'uploader',
          data: {
            fileUploadRequest,
            progressInfo: {
              percentage: 0,
            },
          },
        });

        toastIds.current.push({
          fileId: fileUploadRequest?.fileId || '',
          toastId,
        });
      } else {
        if (fileUploadRequest) {
          setFileUploadProgress((prev) => {
            return [
              ...(prev || []),
              {
                viewed: false,
                fileName: fileUploadRequest?.fileName,
                fileUploadRequest: fileUploadRequest,
                progressInfo: {
                  percentage: 0,
                },
              },
            ];
          });
        }
      }
    },
    onDelete: (fileUploadRequest: FileUploadRequest) => {
      if (!hasNewFileStatus) {
        const currentToast = toastIds.current.find(
          (f) => f.fileId === fileUploadRequest.fileId,
        );
        if (currentToast?.toastId) {
          toast.dismiss(currentToast.toastId);
        }
      } else {
        setFileUploadProgress((prev) => {
          const newPrev = (prev || []).filter(
            (f) => f.fileUploadRequest.fileId !== fileUploadRequest.fileId,
          );
          return [...newPrev];
        });
      }
    },
    // @ts-expect-error do not get this
    onError: (e: Error, fileUploadRequest: FileUploadRequest) => {
      if (!hasNewFileStatus) {
        const currentToast = toastIds.current.find(
          (f) => f.fileId === fileUploadRequest.fileId,
        );

        if (currentToast?.toastId) {
          toast.update(currentToast.toastId, {
            isLoading: false,
            data: {
              error: true,
              errorMessage: e.message,
              fileUploadRequest: fileUploadRequest,
            },
          });
        }
      } else {
        setFileUploadProgress((prev): FileUploadRequestWithProgress[] => {
          const updatedFileRequest = (prev || []).find(
            (f) => f.fileUploadRequest.fileId === fileUploadRequest.fileId,
          );
          const filteredPrev = (prev || []).filter(
            (f) => f.fileUploadRequest.fileId !== fileUploadRequest.fileId,
          );
          if (updatedFileRequest) {
            filteredPrev.push({
              ...updatedFileRequest,
              progressInfo: {
                percentage: updatedFileRequest?.progressInfo?.percentage,
                error: true,
                errorMessage: e.message,
              },
            });
          }
          return filteredPrev;
        });
      }
    },
    onComplete: (fileUploadRequest: FileUploadRequest) => {
      if (!hasNewFileStatus) {
        const currentToast = toastIds.current.find(
          (f) => f.fileId === fileUploadRequest.fileId,
        );

        if (currentToast?.toastId) {
          toast.update(currentToast.toastId, {
            isLoading: false,
            autoClose: 5000,
            data: {
              finished: true,
              skipTimeOut: true,
              fileUploadRequest,
            },
          });
        }
      } else {
        setFileUploadProgress((prev) => {
          const updatedFileRequest = (prev || []).find(
            (f) => f.fileUploadRequest.fileId === fileUploadRequest.fileId,
          );
          const filteredPrev = (prev || []).filter(
            (f) => f.fileUploadRequest.fileId !== fileUploadRequest.fileId,
          );
          if (updatedFileRequest) {
            filteredPrev.push({
              ...updatedFileRequest,
              progressInfo: {
                percentage: 100,
              },
            });
          }
          return filteredPrev;
        });
      }
    },
    onProgress: (
      progressInfo: FileUploadRequestProgress,
      fileUploadRequest: FileUploadRequest,
    ) => {
      if (!hasNewFileStatus) {
        const currentToast = toastIds.current.find(
          (f) => f.fileId === fileUploadRequest.fileId,
        );

        if (currentToast?.toastId) {
          toast.update(currentToast.toastId, {
            isLoading: false,
            data: {
              progressInfo,
              fileUploadRequest,
            },
          });
        }
      } else {
        setFileUploadProgress((prev) => {
          const updatedFileRequest = (prev || []).find(
            (f) => f.fileUploadRequest.fileId === fileUploadRequest.fileId,
          );
          const filteredPrev = (prev || []).filter(
            (f) => f.fileUploadRequest.fileId !== fileUploadRequest.fileId,
          );
          if (updatedFileRequest) {
            filteredPrev.push({
              ...updatedFileRequest,
              progressInfo: {
                ...progressInfo,
              },
            });
          }
          return filteredPrev;
        });
      }
    },
  });

  const resetFiles = () => {
    setFileUploadProgress([]);
  };

  const markAsViewed = (fileIds: string[]) => {
    setFileUploadProgress((prev) => {
      const foundFiled = (prev || [])
        .filter((f) => {
          return (fileIds || []).indexOf(f.fileUploadRequest.fileId) > -1;
        })
        .map((f) => ({
          ...f,
          viewed: true,
        }));

      const filteredPrev = (prev || []).filter((f) => {
        return (fileIds || []).indexOf(f.fileUploadRequest.fileId) === -1;
      });

      return [...filteredPrev, ...foundFiled];
    });
  };

  const errorFiles = (fileUploadProgress || []).filter(
    (f) => f.progressInfo.error,
  );

  const completedItems = (fileUploadProgress || []).filter(
    (f) => (f?.progressInfo?.percentage || 0) >= 100,
  );

  const inProgressItems = (fileUploadProgress || []).filter(
    (f) => (f?.progressInfo?.percentage || 0) < 100,
  );

  const allFinished =
    (fileUploadProgress || []).length > 0 &&
    completedItems.length + errorFiles.length === fileUploadProgress?.length;

  useEffect(() => {
    if (inProgressItems?.length > 0) {
      // If the function passed is named and is the same reference
      // adding it multiple-times won't create a new handle
      // so having it call multiple times in the render is no issue
      window.addEventListener('beforeunload', handleBeforeUpload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUpload);
    }
  }, [inProgressItems]);

  return (
    <MultiUploaderContext.Provider
      value={{
        uploadFile,
        fileUploadRequests,
        abortFileUpload,
        resumeFileUpload,
        cancelFileUpload,
      }}
    >
      <div id="stateHolder" />
      <UploadFileProgressContext.Provider
        value={{
          fileUploadProgress,
          resetFiles,
          markAsViewed,
          errorFiles,
          completedItems,
          allFinished,
        }}
      >
        <>{children}</>
      </UploadFileProgressContext.Provider>
    </MultiUploaderContext.Provider>
  );
};
