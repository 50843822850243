export const id = (name, userId, entityId, layoutName, parametersHahsCode) => {
  const withoutParameters = `NAME:${name}-USER:${userId}-ENTITY:${entityId}-POSITION:${layoutName}`;

  if (!parametersHahsCode) {
    return withoutParameters;
  }

  return `${withoutParameters}-PARAMS:${parametersHahsCode}`;
};

export const getEntityConfiguration = (entityType, entitiesConfiguration) =>
  (entitiesConfiguration || []).find((entityConfig) => {
    if (entityType && entityConfig && entityConfig.entityType) {
      return entityConfig.entityType.toLowerCase() === entityType.toLowerCase();
    }
    return false;
  });

export const hasEntityConfigSetup = (entityType, entityConfigurations) => {
  const entityConfig = getEntityConfiguration(entityType, entityConfigurations);

  return entityConfig && entityConfig.displayName && entityConfig.icon;
};

export const getName = (entityType) => {
  const entityConfigPerEntityType = getEntityConfiguration(entityType);

  return entityConfigPerEntityType
    ? entityConfigPerEntityType.displayName
    : entityType;
};

export default {
  id,
  getEntityConfiguration,
};
