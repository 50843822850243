"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(Typography) {
  return (0, _styledComponents.default)(Typography)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  margin: 0;\n  color: ", ";\n  &.body1 {\n    ", "\n  }\n  &.body2 {\n    ", "\n  }\n  &.caption {\n    ", "\n  }\n  &.button {\n    ", "\n  }\n  &.h1 {\n    ", "\n  }\n  &.h2 {\n    ", "\n  }\n  &.h3 {\n    ", "\n  }\n  &.h4 {\n    ", "\n  }\n  &.h5 {\n    ", "\n  }\n  &.h6 {\n    ", "\n  }\n  &.subtitle1 {\n    ", "\n  }\n  &.subtitle2 {\n    ", "\n  }\n  &.overline {\n    ", "\n  }\n  &.noWrap {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n  &.gutterBottom {\n    margin-bottom: 0.35em;\n  }\n  &.paragraph {\n    margin-bottom: 16;\n  }\n  &.secondary {\n    color: ", ";\n  }\n  &.primary {\n    color: ", ";\n  }\n  &.accent {\n    color: ", ";\n  }\n  &.danger {\n    color: ", ";\n  }\n  &.error {\n    color: ", ";\n  }\n  &.success {\n    color: ", ";\n  }\n  &.warning {\n    color: ", ";\n  }\n  &.info {\n    color: ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.text.primary;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.typography.body1;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.typography.body2;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.typography.caption;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.typography.button;
  }, function (_ref6) {
    var theme = _ref6.theme;
    return theme.typography.h1;
  }, function (_ref7) {
    var theme = _ref7.theme;
    return theme.typography.h2;
  }, function (_ref8) {
    var theme = _ref8.theme;
    return theme.typography.h3;
  }, function (_ref9) {
    var theme = _ref9.theme;
    return theme.typography.h4;
  }, function (_ref10) {
    var theme = _ref10.theme;
    return theme.typography.h5;
  }, function (_ref11) {
    var theme = _ref11.theme;
    return theme.typography.h6;
  }, function (_ref12) {
    var theme = _ref12.theme;
    return theme.typography.subtitle1;
  }, function (_ref13) {
    var theme = _ref13.theme;
    return theme.typography.subtitle2;
  }, function (_ref14) {
    var theme = _ref14.theme;
    return theme.typography.overline;
  }, function (_ref15) {
    var theme = _ref15.theme;
    return theme.colors.text.secondary;
  }, function (_ref16) {
    var theme = _ref16.theme;
    return theme.colors.primary.main;
  }, function (_ref17) {
    var theme = _ref17.theme;
    return theme.colors.accent.main;
  }, function (_ref18) {
    var theme = _ref18.theme;
    return theme.colors.semantic.danger.main;
  }, function (_ref19) {
    var theme = _ref19.theme;
    return theme.colors.semantic.error.main;
  }, function (_ref20) {
    var theme = _ref20.theme;
    return theme.colors.semantic.success.main;
  }, function (_ref21) {
    var theme = _ref21.theme;
    return theme.colors.semantic.warning.main;
  }, function (_ref22) {
    var theme = _ref22.theme;
    return theme.colors.semantic.info.main;
  });
};
exports.default = _default;