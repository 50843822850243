function configureRefreshFetch(configuration) {
  const { refreshToken, shouldRefreshToken, fetch, onRejectToken } =
    configuration;

  let refreshingTokenPromise = null;

  return (url, options = {}) => {
    if (refreshingTokenPromise !== null) {
      return (
        refreshingTokenPromise
          .then(() => fetch(url, options))
          // Even if the refreshing fails, do the fetch so we reject with
          // error of that request
          .catch(() => fetch(url, options))
      );
    }

    return fetch(url, options).then((resp) => {
      if (shouldRefreshToken(resp)) {
        if (refreshingTokenPromise === null) {
          refreshingTokenPromise = new Promise((resolve, reject) => {
            refreshToken().then((resp) => {
              if (!resp) {
                if (onRejectToken) {
                  onRejectToken();
                }
                refreshingTokenPromise = null;
                reject({
                  orignal: {
                    url,
                  },
                  shouldLogout: true,
                });
              } else {
                refreshingTokenPromise = null;
                resolve();
              }
              return resp;
            });
          });
        }

        return refreshingTokenPromise
          .then(() => fetch(url, options))
          .catch((e) => {
            // If refreshing fails, continue with original error
            throw e;
          });
      } else {
        return resp;
      }
    });
  };
}

export default configureRefreshFetch;
