import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { Select } from '@cluedin/form';
import PropTypes from 'prop-types';
import { StatusBadge } from 'uxi/Badge';

const OptionWrapper = styled.div`
  & > div > div {
    & > div {
      margin: 0 5px 0 0;
    }
    margin: 0;
    padding: 0;
  }
`;

const options = [
  {
    value: 'all',
    label: <FormattedMessage id="module-integration-allStatus" />,
  },
  {
    value: 'active',
    label: (
      <div style={{ display: 'inline-flex' }}>
        <OptionWrapper>
          <StatusBadge
            type="success"
            label={<FormattedMessage id="module-integration-onlyActive" />}
          />
        </OptionWrapper>
      </div>
    ),
  },
  {
    value: 'approved',
    label: (
      <div style={{ display: 'inline-flex' }}>
        <OptionWrapper>
          <StatusBadge
            type="success"
            label={<FormattedMessage id="module-integration-onlyApproved" />}
          />
        </OptionWrapper>
      </div>
    ),
  },
  {
    value: 'attention',
    label: (
      <div style={{ display: 'inline-flex' }}>
        <OptionWrapper>
          <StatusBadge
            type="warning"
            label={
              <FormattedMessage id="module-integration-requireYourAttention" />
            }
          />
        </OptionWrapper>
      </div>
    ),
  },
];

const IntegrationStatusDropdown = ({ onChange }) => (
  <div style={{ width: '250px' }}>
    <Select
      mainScrollingElementSelector=".uxi_GAL-main-scrolling-element"
      defaultValue={options[0]}
      onChange={({ value }) => onChange(value)}
      options={options}
    />
  </div>
);

IntegrationStatusDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default IntegrationStatusDropdown;
