import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { PrimaryButton, Button } from '@cluedin/form';
import EntityListNewChanges from '../composites/EntityListNewChanges';
import EntityNewChangesContainer from '../containers/menu/EntityNewChangesContainer';
import ViewEntitySearchChangesStyles from './ViewEntitySearchChanges.style';
import EntityIcon from '../../../entity/components/composites/EntityIcon';
import EntityLinkContainer from '../../../wms/components/containers/EntityLinkContainer';

class ViewEntitySearchChanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      viewType: '',
      selectedInsight: null,
    };
  }

  onBackHandler() {
    const { viewType } = this.state;
    const { onBack } = this.props;
    if (viewType === 'detail') {
      this.setState({
        viewType: '',
        selectedInsight: '',
      });
    } else {
      onBack();
    }
  }

  viewFullSearchHandler() {
    const { onViewFullSearch, search } = this.props;

    onViewFullSearch(search);
  }

  viewChangesHandler(insight) {
    this.setState({
      selectedInsight: insight,
      viewType: 'detail',
    });
  }

  viewFullEntityHandler(insight) {
    const { onInsightClick } = this.props;

    onInsightClick(insight.Entity.EntityId);
  }

  render() {
    const { search } = this.props;
    const { selectedInsight, viewType } = this.state;

    let content;

    if (viewType === 'detail') {
      content = (
        <div>
          <div style={ViewEntitySearchChangesStyles.entityWrapper}>
            <div style={ViewEntitySearchChangesStyles.entityType}>
              <EntityIcon entityType={selectedInsight.Entity.EntityType} />
            </div>
            <div style={ViewEntitySearchChangesStyles.entityLink}>
              <EntityLinkContainer
                id={selectedInsight.Entity.EntityId}
                entityType={selectedInsight.Entity.EntityType}
              >
                {selectedInsight.Entity.EntityName}
              </EntityLinkContainer>
            </div>
          </div>
          <EntityNewChangesContainer
            viewEntity={this.viewFullEntityHandler.bind(this)}
            insight={selectedInsight}
          />
        </div>
      );
    } else {
      content = (
        <div>
          <div style={ViewEntitySearchChangesStyles.searchQueryWrapper}>
            <div style={ViewEntitySearchChangesStyles.searchQueryContent}>
              <FormattedMessage id="searchPrefix" />
              {search.Query}
            </div>
          </div>
          <div style={ViewEntitySearchChangesStyles.titleWrapper}>
            <h3 style={ViewEntitySearchChangesStyles.titleContent}>
              {search.Entities.length}
              <FormattedMessage id="newChanges" />
            </h3>
          </div>
          <EntityListNewChanges
            savedSearch={search}
            viewChanges={this.viewChangesHandler.bind(this)}
          />
        </div>
      );
    }

    return (
      <div>
        <div style={ViewEntitySearchChangesStyles.header}>
          <div>
            <Button
              onClick={this.onBackHandler.bind(this)}
              text={<FormattedMessage id="backWitArrow" />}
            />
          </div>
          <div style={ViewEntitySearchChangesStyles.headerViewAll}>
            <PrimaryButton
              onClick={this.viewFullSearchHandler.bind(this)}
              text={<FormattedMessage id="viewAll" />}
            />
          </div>
        </div>
        <div style={ViewEntitySearchChangesStyles.content}>{content}</div>
      </div>
    );
  }
}

export default ViewEntitySearchChanges;
