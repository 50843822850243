import React, { useMemo, memo, useState } from 'react';

import { RuleVocabularyValueSelector } from './RuleVocabularyValueSelector';

import { useVocabularyKeyValues } from '../../../../dataCatalog/hooks/useVocabularyKeyValues';

export const RuleVocabularyValueSelectOrCreate = memo(
  ({
    value,
    onChange,
    hasError,
    disabled,
    rawValue,
    vocabularyKey,
    containerStyle,
    skipRetrievedValues,
  }) => {
    // eslint-disable-next-line no-unused-vars
    const [isNewVocabularyValue, setIsNewVocabularyValue] = useState(false);

    const [inputValue, setInputValue] = useState('');

    const [{ terms, total }, isLoading] = useVocabularyKeyValues(
      vocabularyKey,
      skipRetrievedValues,
    );

    const adaptedValue = useMemo(() => {
      if (rawValue) {
        return value
          ? {
              label: value,
              value: value,
            }
          : undefined;
      }

      return value?.[0]
        ? {
            label: value?.[0],
            value: value?.[0],
          }
        : undefined;
    }, [value, rawValue]);

    if (isLoading) return null;

    return (
      <RuleVocabularyValueSelector
        data={skipRetrievedValues ? [] : terms}
        total={skipRetrievedValues ? 0 : total}
        hasError={hasError}
        onChange={onChange}
        value={adaptedValue}
        disabled={disabled}
        isLoading={isLoading}
        inputValue={inputValue}
        setInputValue={setInputValue}
        containerStyle={containerStyle}
        setIsNewVocabularyValue={setIsNewVocabularyValue}
      />
    );
  },
);
