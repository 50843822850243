/**
 *
 * Change to object properties and try to 'guess it'
 *
 * need to return { displayName, value}
 */
export const findFromKey = (properties, key) => {
  const allLikelyCorrespondingKeys = [];
  const allPropertyKeys = Object.keys(properties);
  allPropertyKeys.forEach((k) => {
    const allKeys = k.split('.');
    const lastKey = allKeys.pop();

    if (lastKey.indexOf(key) > -1) {
      allLikelyCorrespondingKeys.push(k);
    }
  });

  const hasCluedIn = allLikelyCorrespondingKeys.find(
    (k) => k.indexOf('cluedin') > -1,
  );

  if (hasCluedIn) {
    return {
      displayName: key,
      value: properties[hasCluedIn],
    };
  }

  if (allLikelyCorrespondingKeys && allLikelyCorrespondingKeys.length > 0) {
    return {
      displayName: key,
      value: properties[allLikelyCorrespondingKeys[0]],
    };
  }

  return {
    displayName: key,
    value: null,
  };
};

/** same here */
export const findFromKeyValue = (properties, key) => findFromKey(key).value;

export const findProp = (properties, arrayOfProps = []) => {
  let value;

  arrayOfProps.forEach((p) => {
    // why not jsut return as soon as found first ? DF
    const valueFromProp = properties[p];
    if (!value && valueFromProp) {
      value = valueFromProp;
    }
  });

  return value;
};
