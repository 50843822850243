import { apiRequest } from '../core/helpers/request';

export const fetchNotifications = (page = 0, take = 10) =>
  apiRequest('GET', `api/v1/notification/all?page=${page}&take=${take}`);

export const markAsUnread = (id) =>
  apiRequest('PUT', `api/v1/notification/markunread?id=${id}`);

export const markAsRead = (id) =>
  apiRequest('PUT', `api/v1/notification/mark?id=${id}`);

export const deleteNotification = (id) =>
  apiRequest('DELETE', `api/v1/notification?id=${id}`);

export const markAllAsRead = () =>
  apiRequest('PUT', 'api/v1/notification/markall');
