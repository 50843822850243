import React, { Component } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import ConfigurationFilters from '../filters/ConfigurationFilters';
import ConfigurationList from './ConfigurationList';
import { escapeRegex } from '../../../../core/helpers/string';

const filterByStatus = (configurations, selectedStatus) => {
  switch (selectedStatus) {
    case 'active': {
      return configurations.filter(
        (c) => c.Active && !c.FailingAuthentication && !c.isUnApproved,
      );
    }
    case 'approved': {
      return configurations.filter((c) => !c.isUnApproved);
    }
    case 'attention': {
      return configurations.filter(
        (c) => c.FailingAuthentication || c.isUnApproved || !c.Active,
      );
    }
    default: {
      return configurations;
    }
  }
};

class ConfigurationListWithFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      selectedIntegration: null,
      selectedStatus: null,
    };
  }

  onSearchChangeHandler(value) {
    this.setState({
      query: value,
    });
  }

  onIntegrationChangeHandler(value) {
    this.setState({
      selectedIntegration: value,
    });
  }

  onStatusChangeHandler(value) {
    this.setState({
      selectedStatus: value,
    });
  }

  getFilteredConfigurations() {
    const { configurations } = this.props;
    const { query, selectedIntegration, selectedStatus } = this.state;
    const configurationfilteredByName = configurations.filter(
      (c) =>
        c.Name.match(new RegExp(escapeRegex(query), 'i')) ||
        (c.AccountDisplay || '').match(new RegExp(escapeRegex(query), 'i')),
    );

    if (selectedIntegration) {
      return filterByStatus(configurationfilteredByName, selectedStatus).filter(
        (v) => v.Name.toLowerCase() === selectedIntegration.Name.toLowerCase(),
      );
    }

    return filterByStatus(configurationfilteredByName, selectedStatus).sort(
      (a, b) => {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      },
    );
  }

  render() {
    const {
      configurations,
      ActionTitle,
      Action,
      extraProps,
      onConfigurationClick,
      grantAccess,
      revokeAccess,
      condensed,
      onChange,
      disableMulti,
    } = this.props;

    const { query } = this.state;

    const filteredConfigurations = this.getFilteredConfigurations();
    const noConfigurationAfterFiltering = (
      <Alert>
        <FormattedMessage id="module-configuration-noConfigurationFound" />
      </Alert>
    );

    const nonEmptyResult =
      filteredConfigurations && filteredConfigurations.length > 0;

    return (
      <div>
        <ConfigurationFilters
          query={query}
          configurations={configurations}
          onSearchChange={this.onSearchChangeHandler.bind(this)}
          onIntegrationChange={this.onIntegrationChangeHandler.bind(this)}
          onStatusChange={this.onStatusChangeHandler.bind(this)}
        />
        {nonEmptyResult ? (
          <ConfigurationList
            query={query}
            disableMulti={disableMulti}
            disableActions={condensed}
            configurations={filteredConfigurations}
            ActionTitle={ActionTitle}
            Action={Action}
            onConfigurationClick={onConfigurationClick}
            grantAccess={grantAccess}
            revokeAccess={revokeAccess}
            condensed={condensed}
            onChange={onChange}
            {...extraProps}
          />
        ) : (
          noConfigurationAfterFiltering
        )}
      </div>
    );
  }
}

export default ConfigurationListWithFilters;
