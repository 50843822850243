import moment from 'moment';

export const getAppropriateComponentFromSchema = (schema) => {
  if (schema.DataType === 'Guid') {
    return;
  }

  if (schema.DataType === 'Email') {
    return (value) => {
      const emailLink = `mailto:${value}`;

      return <a href={emailLink}>{value}</a>;
    };
  }

  if (schema.DataType === 'Duration') {
    return (value) => `${value || 0} minutes`;
  }

  if (schema.DataType === 'DateTime') {
    return (value) => moment(value || '').format('LL');
  }

  if (schema.DataType === 'Boolean') {
    return (value) => {
      if (value.toString().toLowerCase() === 'true') {
        return 'Yes';
      }

      return 'No';
    };
  }

  if (schema.DataType === 'PhoneNumber') {
    return (value) => {
      const phoneLink = `tel:${value}`;

      return (
        <a rel="noopener noreferrer" href={phoneLink} target="_blank">
          {value}
        </a>
      );
    };
  }

  if (schema.DataType === 'Uri') {
    return (value) => {
      const finalHREF = (value || '').match(/^https?:\/\//)
        ? value
        : `https://${value}`;

      return (
        <a target="_blank" rel="noopener noreferrer" href={finalHREF}>
          {value}
        </a>
      );
    };
  }

  return (value) => <span>{value}</span>;
};

export default getAppropriateComponentFromSchema;
