import { connect } from 'react-redux';

import { selectTopic } from '../../actions';
import HelpContainer from './HelpContainer';

const mapToStateProps = ({ help: { isOpenHelpCenter, selected } }) => ({
  isOpen: isOpenHelpCenter,
  selected,
});

const mapToDispatchProps = (dispatch) => ({
  onTopicClick({ topicId, type }) {
    dispatch(
      selectTopic({
        topicId,
        type,
      }),
    );
  },
  onMainMenu() {
    dispatch(selectTopic());
  },
});

export default connect(mapToStateProps, mapToDispatchProps)(HelpContainer);
