import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';

const { engineRoom } = actionsConfiguration;

export default {
  pillar: 'engineRoom',
  name: 'statistic',
  displayName: <FormattedMessage id="statistic" />,
  path: '/stats',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'report',
        name: <FormattedMessage id="statistic" />,
        link: '/admin/engine-room/stats',
        claims: engineRoom.statistics.statistic.view,
      },
    ],
  },
  routes,
};
