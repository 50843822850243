import React from 'react';

import styled from 'styled-components';

const LayoutElement = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  overflow: hidden auto;
`;

export const Layout = ({ children }) => {
  return <LayoutElement>{children}</LayoutElement>;
};
