import React from 'react';
import FormExplanationRequiredFields from './FormExplanationRequiredFields';

const FormWrapper = ({ children, withRequiredFields = false }) => (
  <div>
    {children}
    {withRequiredFields && <FormExplanationRequiredFields />}
  </div>
);

export default FormWrapper;
