"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ButtonStyleSemantic = require("./ButtonStyleSemantic");
var _ButtonStyle = require("./ButtonStyle");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(Button) {
  return (0, _styledComponents.default)(Button)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  & + & {\n    margin-left: 16px;\n  }\n\n  > span {\n    width: 100%;\n    display: inherit;\n    align-items: inherit;\n    justify-content: inherit;\n    svg {\n      width: 16px;\n      height: 16px;\n    }\n    .icon {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      box-sizing: content-box;\n      max-width: 16px;\n      max-height: 16px;\n    }\n    .startIcon {\n      margin-right: ", "px;\n    }\n    > svg {\n      padding: 4px; // 24px - 16px / 2;\n    }\n    .endIcon {\n      margin-left: ", "px;\n    }\n  }\n  box-sizing: border-box;\n  ", ";\n  ", "\n  transition: ", ";\n  padding: 8px;\n\n  &.contained {\n    border-radius: ", ";\n    &.primary {\n      color: ", ";\n      background-color: ", ";\n      &:hover,\n      &:focus {\n        background-color: ", ";\n      }\n    }\n    ", "\n    ", "\n  &.disabled {\n      cursor: auto;\n      opacity: ", ";\n      color: ", ";\n      &:hover,\n      &:focus {\n        color: ", ";\n      }\n    }\n  }\n  &.outlined {\n    border-style: solid;\n    border-width: 1px;\n    ", "\n    ", "\n  &.disabled {\n      cursor: auto;\n      opacity: ", ";\n      color: ", ";\n      border-color: ", ";\n      &:hover,\n      &:focus {\n        color: ", ";\n        border-color: ", ";\n      }\n    }\n  }\n\n  &.text {\n    ", "\n    ", "\n    &.disabled {\n      cursor: auto;\n      opacity: ", ";\n      color: ", ";\n\n      &:hover,\n      &:focus {\n        color: ", ";\n      }\n    }\n  }\n\n  &.accent {\n    color: ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.spacing.inputSpacing(2);
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.spacing.inputSpacing(2);
  }, function (_ref3) {
    var isFullWidth = _ref3.isFullWidth;
    return isFullWidth ? 'width: 100%' : '';
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.typography.button;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.transitions.create(['background-color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short
    });
  }, function (_ref6) {
    var theme = _ref6.theme;
    return theme.shape.radius;
  }, function (_ref7) {
    var theme = _ref7.theme;
    return theme.colors.pureWhite;
  }, function (_ref8) {
    var theme = _ref8.theme;
    return "" + theme.colors.primary.main;
  }, function (_ref9) {
    var theme = _ref9.theme;
    return "" + theme.colors.primary.light;
  }, _ButtonStyle.contained, _ButtonStyleSemantic.contained, function (_ref10) {
    var theme = _ref10.theme;
    return theme.colors.action.disabledOpacity;
  }, function (_ref11) {
    var theme = _ref11.theme;
    return theme.colors.action.pureWhite;
  }, function (_ref12) {
    var theme = _ref12.theme;
    return theme.colors.action.pureWhite;
  }, _ButtonStyle.outlined, _ButtonStyleSemantic.outlined, function (_ref13) {
    var theme = _ref13.theme;
    return theme.colors.action.disabledOpacity;
  }, function (_ref14) {
    var theme = _ref14.theme;
    return theme.colors.action.disabledText;
  }, function (_ref15) {
    var theme = _ref15.theme;
    return theme.colors.action.disabledText;
  }, function (_ref16) {
    var theme = _ref16.theme;
    return theme.colors.action.disabledText;
  }, function (_ref17) {
    var theme = _ref17.theme;
    return theme.colors.action.disabledText;
  }, _ButtonStyle.text, _ButtonStyleSemantic.text, function (_ref18) {
    var theme = _ref18.theme;
    return theme.colors.action.disabledOpacity;
  }, function (_ref19) {
    var theme = _ref19.theme;
    return theme.colors.action.disabledText;
  }, function (_ref20) {
    var theme = _ref20.theme;
    return theme.colors.action.disabledText;
  }, function (_ref21) {
    var theme = _ref21.theme;
    return theme.colors.accent.main;
  });
};
/**
*  &:focus, &:hover {
  ${({ disabled, theme }) => (!disabled
    ? `box-shadow: ${theme.outlineShadow}; outline: ${theme.outline}`
    : '')
  };


  transition: ${({ theme: { transition } }) => transition.defaultAll};


  In outlined : @media (hover: none): {
      backgroundColor: transparent;
    }
*/
exports.default = _default;