import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';

import { withRecordPanelWorkflow } from '../../../hocs/withRecordPanelWorkflow';
import { EntityTopologyDataPartPanelLinkWrapper } from './EntityTopologyDataPartPanelLink.styles';

const EntityTopologyDataPartPanelLink = ({
  name,
  recordPanelData,
  openRecordPanelWorkflow,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  return (
    <>
      <EntityTopologyDataPartPanelLinkWrapper
        data-test="entityTopology-openPanelLink-id"
        onClick={openRecordPanelWorkflow}
        themePrimary={themePrimary}
      >
        {name}
      </EntityTopologyDataPartPanelLinkWrapper>
    </>
  );
};

export default withRecordPanelWorkflow(EntityTopologyDataPartPanelLink);

EntityTopologyDataPartPanelLink.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  recordPanelData: PropTypes.object,
  openRecordPanelWorkflow: PropTypes.func,
};

EntityTopologyDataPartPanelLink.defaultProps = {
  name: null,
  recordPanelData: {},
  openRecordPanelWorkflow: () => {},
};
