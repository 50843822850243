import moment from 'moment';
import { line, curveCardinal } from 'd3-shape';
import { lt } from '../../../../core/helpers/numbers';

export const mapValuesToXYCoords = (
  values,
  min,
  max,
  MINW,
  MAXW,
  MINH,
  MAXH,
  dateRange,
) => {
  return (values || []).map(({ value: v, date } /* , i */) => {
    // todo use date to get x value instead of collection length
    // TODO figure out total date range at the root comp
    // so we can do a lt here and get our x value based on min and max date
    // TODO find a way to convert date into a integer (usable by lt())
    // most likely a unix timestap from moment
    const minDateTS = dateRange.start.format('X');
    const maxDateTS = dateRange.end.format('X');
    const totalDateDiff = maxDateTS - minDateTS;
    const d = moment(date).format('X');
    // console.log('minDateTS', minDateTS);
    // console.log('d', d);
    // console.log('maxDateTS', maxDateTS);
    // const x = lt(i, 0, values.length - 1, MINW, MAXW);
    const x = lt(d - minDateTS, 0, totalDateDiff, MINW, MAXW);
    const y = MAXH - lt(v, 0, max, MINH, MAXH);
    return [x, y];
  });
};

export function getSVGPathFromData(
  dateRange,
  data,
  min,
  max,
  MINW,
  MAXW,
  MINH,
  MAXH,
) {
  const lineGenerator = line()
    .x((d) => {
      return d[0];
    })
    .y((d) => {
      return d[1];
    })
    .curve(curveCardinal);

  return lineGenerator(
    mapValuesToXYCoords(data, min, max, MINW, MAXW, MINH, MAXH, dateRange),
  );
}
