export default (cdnUrl) => ({
  slack: {
    name: 'Slack',
    icon: `${cdnUrl}slack.png`,
    oauth: true,
    domain: 'slack.com',
  },
  office365activedirectory: {
    name: 'Office365 Azure Active Directory',
    icon: `${cdnUrl}office365azureactivedirectory.png`,
    oauth: true,
    domain: 'office.com',
  },
  office365azureactivedirectory: {
    name: 'Office365 Azure Active Directory',
    icon: `${cdnUrl}office365azureactivedirectory.png`,
    oauth: true,
    domain: 'office.com',
  },
  dropbox: {
    name: 'Dropbox',
    icon: `${cdnUrl}dropbox.png`,
    oauth: true,
    domain: 'dropbox.com',
  },
  'box.net': {
    name: 'Box',
    icon: `${cdnUrl}box.png`,
    oauth: true,
    domain: 'box.com',
  },
  box: {
    name: 'Box',
    icon: `${cdnUrl}box.png`,
    oauth: true,
    domain: 'box.com',
  },
  cluedin: {
    name: 'CluedIn',
    icon: `${cdnUrl}cluedin.png`,
    domain: 'cluedin.net',
  },
  filesystem: {
    name: 'Internal File System',
    icon: `${cdnUrl}internal.png`,
    customEdit: true,
  },
  office365: {
    name: 'Office 365',
    icon: `${cdnUrl}office365.png`,
    oauth: true,
  },
  office365online: {
    name: 'Office 365',
    icon: `${cdnUrl}office365.png`,
    oauth: true,
  },
  pivotaltracker: {
    name: 'Pivotal Tracker',
    icon: `${cdnUrl}pivotaltracker.png`,
    customEdit: true,
    domain: 'pivotaltracker.com',
  },
  sharepoint: {
    name: 'SharePoint',
    icon: `${cdnUrl}sharepoint.png`,
    customEdit: true,
    domain: 'sharepoint.com',
  },
  svn: {
    name: 'Svn',
    icon: `${cdnUrl}svn.png`,
    customEdit: true,
  },
  trello: {
    name: 'Trello',
    icon: `${cdnUrl}trello.png`,
    oauth: true,
    domain: 'trello.com',
  },
  sococo: {
    name: 'Sococo',
    icon: `${cdnUrl}sococo.png`,
    domain: 'sococo.com',
  },
  gemini: {
    name: 'Gemini',
    icon: `${cdnUrl}gemini.png`,
    customEdit: true,
    domain: 'gemini.com',
  },
  twitter: {
    name: 'Twitter',
    icon: `${cdnUrl}twitter.png`,
    oauth: true,
    domain: 'twitter.com',
  },
  linkedin: {
    name: 'LinkedIn',
    icon: `${cdnUrl}linkedin.png`,
    oauth: true,
    domain: 'linkedin.com',
  },
  evernote: {
    name: 'Evernote',
    icon: `${cdnUrl}evernote.png`,
    domain: 'evernote.com',
  },
  jira: {
    name: 'Jira',
    icon: `${cdnUrl}jira.png`,
    customEdit: true,
    domain: 'jira.com',
  },
  zendesk: {
    name: 'Zendesk',
    icon: `${cdnUrl}zendesk.png`,
    customEdit: true,
    domain: 'zendesk.com',
  },
  github: {
    name: 'Github',
    icon: `${cdnUrl}github.png`,
    oauth: true,
    domain: 'github.com',
  },
  podio: {
    name: 'Podio',
    icon: `${cdnUrl}podio.png`,
    oauth: true,
    domain: 'podio.com',
  },
  nuget: {
    name: 'Nuget',
    icon: `${cdnUrl}nuget.png`,
    domain: 'nuget.org',
  },
  bitbucket: {
    name: 'Bitbucket',
    icon: `${cdnUrl}bitbucket.png`,
    customEdit: true,
    domain: 'bitbucket.org',
  },
  stackoverflow: {
    name: 'StackOverflow',
    icon: `${cdnUrl}stackoverflow.png`,
    oauth: true,
    domain: 'stackoverflow.com',
  },
  asana: {
    name: 'Asana',
    icon: `${cdnUrl}asana.png`,
    oauth: true,
    domain: 'asana.com',
  },
  googlehangout: {
    name: 'Google Hangout',
    icon: `${cdnUrl}googlehangout.png`,
    oauth: true,
    domain: 'hangouts.google.com',
  },
  onenote: {
    name: 'Microsoft One Note',
    icon: `${cdnUrl}onenote.png`,
    domain: 'onenote.com',
  },
  gmail: {
    name: 'Gmail',
    icon: `${cdnUrl}googlegmail.png`,
    domain: 'gmail.com',
  },
  googleanalytics: {
    name: 'Google Analytics',
    icon: `${cdnUrl}googleanalytics.png`,
    domain: 'analytics.google.com',
  },
  googlecalendar: {
    name: 'Google Calendar',
    icon: `${cdnUrl}googlecalendar.png`,
    domain: 'calendar.google.com',
  },
  googledrive: {
    name: 'Google Drive',
    icon: `${cdnUrl}googledrive.png`,
    domain: 'drive.google.com',
  },
  facebook: {
    name: 'Facebook',
    icon: `${cdnUrl}facebook.png`,
    domain: 'facebook.com',
  },
  salesforce: {
    name: 'Salesforce',
    icon: `${cdnUrl}salesforce.png`,
    domain: 'salesforce.com',
  },
  pipedrive: {
    name: 'PipeDrive',
    icon: `${cdnUrl}pipedrive.png`,
    domain: 'pipedrive.com',
  },
  teamwork: {
    name: 'TeamWork',
    icon: `${cdnUrl}teamwork.png`,
    domain: 'teamwork.com',
  },
  'google+': {
    name: 'Google+',
    icon: `${cdnUrl}googleplus.png`,
    domain: 'plus.google.com',
  },
  googleplus: {
    name: 'Google+',
    icon: `${cdnUrl}googleplus.png`,
    domain: 'plus.google.com',
  },
  googlecontacts: {
    name: 'Google Contact',
    icon: `${cdnUrl}googlecontact.png`,
    domain: 'contacts.google.com',
  },
  googlecontact: {
    name: 'Google Contact',
    icon: `${cdnUrl}googlecontact.png`,
    domain: 'contacts.google.com',
  },
  stripe: {
    name: 'Stripe',
    icon: `${cdnUrl}stripe.png`,
    oauth: true,
    domain: 'stripe.com',
  },
  yammer: {
    name: 'Yammer',
    icon: `${cdnUrl}yammer.png`,
    oauth: true,
    domain: 'yammer.com',
  },
  zohocrm: {
    name: 'Zoho CRM',
    icon: `${cdnUrl}zoho.png`,
    domain: 'zoho.com',
  },
  hubspot: {
    name: 'Hubspot',
    icon: `${cdnUrl}hubspot.png`,
    domain: 'hubspot.com',
  },
  intercom: {
    name: 'Intercom',
    icon: `${cdnUrl}intercom.png`,
    domain: 'intercom.com',
  },
  dinero: {
    name: 'Dinero',
    icon: `${cdnUrl}dinero.png`,
    domain: 'dinero.dk',
  },
  aircall: {
    name: 'Aircall',
    icon: `${cdnUrl}aircall.png`,
    domain: 'aircall.io',
  },
  firmafon: {
    name: 'Firmafon',
    icon: `${cdnUrl}firmafon.png`,
    domain: 'firmafon.dk',
  },
  messagebird: {
    name: 'Message Bird',
    icon: `${cdnUrl}messagebird.png`,
    domain: 'messagebird.com',
  },
  mailchimp: {
    name: 'Mailchimp',
    icon: `${cdnUrl}mailchimp.png`,
    domain: 'mailchimp.com',
  },
  textlocal: {
    name: 'TextLocal',
    icon: `${cdnUrl}textlocal.png`,
    domain: 'textlocal.com',
  },
  googleadwords: {
    name: 'Google Adwords',
    icon: `${cdnUrl}googleadwords.png`,
    domain: 'google.com/adwords/',
  },
  dynamics: {
    name: 'Dynamics',
    icon: `${cdnUrl}dynamics.png`,
    domain: 'microsoft.com',
  },
});
