import React from 'react';
import styled from 'styled-components';
import { Hamburger } from 'uxi/Icons';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from '@cluedin/locale';
import { Switch } from 'uxi/Input';
import FieldWrapper from './FieldWrapper';
import FieldDrag from './FieldDrag';

const DeleteWrapper = styled.div`
  cursor: pointer;
`;

const SwitchWrapper = styled.div``;

const FieldItemSwitcher = ({
  style,
  onChangeDefaultField,
  fieldName,
  isSelected,
  fixed,
}) => {
  const Wrapper = fixed ? Tooltip : SwitchWrapper;

  return (
    <FieldWrapper style={style}>
      <FieldDrag>
        <Hamburger size={12} />
      </FieldDrag>
      <div style={{ flex: 1 }}>
        <div>{fieldName}</div>
        <div style={{ fontSize: '12px', color: 'grey' }}>
          <FormattedMessage id="module-schema-enittyDEfaultProperty" />
        </div>
      </div>
      <Wrapper
        placement="top"
        trigger="hover"
        overlay={
          <FormattedMessage id="module-schema-cannotBeremovedFromList" />
        }
      >
        <DeleteWrapper>
          <Switch
            disable={fixed}
            disabled={fixed}
            name={fieldName}
            checked={isSelected}
            onChange={() => {
              onChangeDefaultField(fieldName);
            }}
          />
        </DeleteWrapper>
      </Wrapper>
    </FieldWrapper>
  );
};

export default FieldItemSwitcher;
