import uniq from 'lodash/uniq';
import setupSchema from '../../selectors/schema';

import {
  getConflitedProperties,
  getOnlyPropertyObject,
  getConflictedPropertyWithValuesAndSource,
} from './helper';

const withSchema = (conflictedProperties, getSchemaFromPropertyKey) =>
  conflictedProperties.map((conflictedProp) => {
    const keyWithoutProperties =
      conflictedProp.key.indexOf('property-') === 0
        ? conflictedProp.key.split('property-')[1]
        : conflictedProp.key;

    return {
      ...conflictedProp,
      schema: getSchemaFromPropertyKey(keyWithoutProperties),
    };
  });

const autoSelectObvious = (conflictedPropertypWithValues) => {
  const valuesWithIndexes = conflictedPropertypWithValues.values.reduce(
    (accu, valueWithSource, i) => {
      if (valueWithSource.value) {
        accu.push({ valueWithSource, index: i });
      }
      return accu;
    },
    [],
  );

  const hasObviousAnswer = valuesWithIndexes.length === 1;

  if (hasObviousAnswer) {
    const indexOfObviousValue = valuesWithIndexes[0].index;

    return {
      ...conflictedPropertypWithValues,
      values: {
        ...conflictedPropertypWithValues.values,
        [indexOfObviousValue]: {
          ...conflictedPropertypWithValues.values[indexOfObviousValue],
          isOnlyObviousChoice: true,
        },
      },
    };
  }

  return conflictedPropertypWithValues;
};

const onlyConflictedValues = (propsWithValueAndSources) => {
  const allOriginalValues = propsWithValueAndSources.values.map((x) => x.value);
  const onlyUniquedValues = uniq(allOriginalValues || []);

  return onlyUniquedValues.length > 1;
};

const markConflictedValues = (propsWithValueAndSources) => {
  const allOriginalValues = propsWithValueAndSources.values.map((x) => x.value);
  const onlyUniquedValues = uniq(allOriginalValues || []);

  return {
    ...propsWithValueAndSources,
    isConflictFree: onlyUniquedValues.length === 1,
  };
  // return (onlyUniquedValues.length > 1);
};

export const getAllProperties = (selectedEntities = []) => {
  const onlyProperties = getOnlyPropertyObject(selectedEntities);
  const conflictedPropertyKeys = getConflitedProperties(onlyProperties);

  const conflictedPropertiesWithValues =
    getConflictedPropertyWithValuesAndSource(
      conflictedPropertyKeys,
      selectedEntities,
    );

  return conflictedPropertiesWithValues
    .map(markConflictedValues)
    .map(autoSelectObvious);
};

export const getConflictedProperties = (selectedEntities = []) => {
  const onlyProperties = getOnlyPropertyObject(selectedEntities);
  const conflictedPropertyKeys = getConflitedProperties(onlyProperties);

  const conflictedPropertiesWithValues =
    getConflictedPropertyWithValuesAndSource(
      conflictedPropertyKeys,
      selectedEntities,
    );

  return conflictedPropertiesWithValues
    .filter(onlyConflictedValues)
    .map(autoSelectObvious);
};

/**
 *
 * out put seems to be an array of
 * [
 * {
 * key,
 * displayName,
 * entityName,
 * schema,
 * values: [
 * {
 *    index: 0,
 *    isOnlyObviousChoice: true||false
 *    valueWithSource: {
 *      value,
 *      entityName,
 *      entityId,
 *      key
 *    }
 *  }
 * ],
 * }
 * ]
 */
export const getConflictedPropsWithPropertySchema = (
  selectedEntities,
  schema,
) => {
  if (!schema || !selectedEntities) {
    return null;
  }

  const getSchemaFromPropertyKey = setupSchema(schema);
  const conflictedProperties = getConflictedProperties(selectedEntities);

  return withSchema(conflictedProperties, getSchemaFromPropertyKey);
};

export const getAllPropsWithPropertySchema = (selectedEntities, schema) => {
  if (!schema || !selectedEntities) {
    return null;
  }

  const getSchemaFromPropertyKey = setupSchema(schema);
  const conflictedProperties = getAllProperties(selectedEntities);

  return withSchema(conflictedProperties, getSchemaFromPropertyKey);
};
