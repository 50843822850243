import { connect } from 'react-redux';
import PrivacyFeatures from '../composites/PrivacyFeatures';
import {
  selectFeature as selectFeatureAction,
  clearSelectFeature as clearSelectFeatureAction,
  shouldSendRequest,
  shouldShowPersonalIdentifiers,
  showConsentDialog as showConsentDialogAction,
  showCurrentUserRequest as showCurrentUserRequestAction,
} from '../../action';

const selectAnonymousTitle = (selectedFeature, showPersonalIdentifiers) => {
  if (selectedFeature && selectedFeature.title) {
    return selectedFeature.title;
  }
  if (showPersonalIdentifiers) {
    return 'You personal identifier list';
  }

  return 'You are currently not identified';
};

const mapStateToProps = ({
  frame: {
    selectedFeature,
    isSendingRequest,
    invalidRequest,
    isSendingRequestDone,
    currentUser,
    cookiePolicyUrl,
    showPersonalIdentifiers,
    consents,
    showConsentDialog,
    currentUserRequests,
    showCurrentUserRequest,
    dpoEmail,
    dpoPhone,
  },
}) => ({
  showCurrentUserRequest,
  cookiePolicyUrl,
  selectedFeature,
  isSendingRequest,
  invalidRequest,
  isSendingRequestDone,
  currentUser,
  currentUserRequests,
  anonymousTitle: selectAnonymousTitle(
    selectedFeature,
    showPersonalIdentifiers,
  ),
  showPersonalIdentifiers:
    currentUser && currentUser.isAuthenticated && showPersonalIdentifiers,
  consents,
  showConsentDialog,
  showAnonymousDialog:
    (!!selectedFeature && (!currentUser || !currentUser.isAuthenticated)) ||
    ((!currentUser || !currentUser.isAuthenticated) && showPersonalIdentifiers),
  dpoEmail,
  dpoPhone,
});

const mapDispatchToProps = (dispatch) => ({
  selectFeature: (feature) => {
    dispatch(selectFeatureAction(feature));
  },
  clearSelectFeature: () => {
    dispatch(clearSelectFeatureAction());
  },
  sendFeature: () => {
    dispatch(shouldSendRequest());
  },
  onShowPersonalIdentifiers: () => {
    dispatch(shouldShowPersonalIdentifiers());
  },
  onShowConsentDialog: () => {
    dispatch(showConsentDialogAction());
  },
  onShowCurrentUserRequests: () => {
    dispatch(showCurrentUserRequestAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyFeatures);
