import React, { Fragment } from 'react';
import { DataGridv2 } from '@cluedin/list-pattern';
import { Pager } from '../../../core/components/composites/Pager';
import EntityTableGhostLoading from './EntityTableGhostLoading';
import withEntityMerge from '../../../entityMerge/components/Hocs/withEntityMerge';
import DataGridStylesOverwrrideNotInUxiBecauseInAHurryToRelease from '../../../core/components/composites/DataGridStylesOverwrrideNotInUxiBecauseInAHurryToRelease';
import EmptySearch from './EmptySearch';

const EntityTable = ({
  data = [],
  properties,
  // isMerging,
  selectEntity,
  deselectEntity,
  selectedEntities: selectedEntitiesFromStore,
  deselectAllEntities,
  showPagination = false,
  pagerProps,
}) => {
  if (data && data.length === 0) {
    return <EmptySearch />;
  }

  const selectedEntities = selectedEntitiesFromStore.map(
    ({ entityId }) => entityId,
  );

  const onSelectAllChange = (allSelected) => {
    if (!allSelected) {
      deselectAllEntities();
    } else {
      data.forEach((entity) => {
        const entityMergeVM = {
          entityId: entity.id || entity.entityId,
          entityType:
            entity.entityType ||
            (entity.data.entityType ? entity.data.entityType : ''),
          name: entity.displayName || entity.name,
        };
        selectEntity(entityMergeVM);
      });
    }
  };

  const onChangeHandler = (ev, indexes, ids) => {
    if (selectedEntities.length === ids.length) {
      // nTODO
      // console.log('noop 1');
    } else if (ids.length === 0) {
      deselectAllEntities();
    } else if (selectedEntities.length > ids.length) {
      const newId = ids.find((id) => selectedEntities.indexOf(id) !== -1);
      const entity = data.find((e) => e.entityId === newId);
      const entityMergeVM = {
        entityId: entity.id || entity.entityId,
        entityType:
          entity.entityType ||
          (entity.data.entityType ? entity.data.entityType : ''),
        name: entity.displayName || entity.name,
      };

      deselectEntity(entityMergeVM);
    } else if (selectedEntities.length < ids.length) {
      const newId = ids.find((id) => selectedEntities.indexOf(id) === -1);
      const entity = data.find((e) => e.entityId === newId);
      const entityMergeVM = {
        entityId: entity.id || entity.entityId,
        entityType:
          entity.entityType ||
          (entity.data.entityType ? entity.data.entityType : ''),
        name: entity.displayName || entity.name,
      };
      selectEntity(entityMergeVM);
    }
  };

  return (
    <Fragment>
      <DataGridStylesOverwrrideNotInUxiBecauseInAHurryToRelease>
        <DataGridv2
          useSmartOverflowX
          propertyKey="entityId"
          // sortable
          resizable
          // selectable={isMerging}
          data={data}
          model={properties}
          onSelectionChange={(...a) => {
            onChangeHandler(...a);
          }}
          onSelectAllChange={(...a) => {
            onSelectAllChange(...a);
          }}
          selected={selectedEntities}
        />
      </DataGridStylesOverwrrideNotInUxiBecauseInAHurryToRelease>
      {showPagination && <Pager {...pagerProps} />}
    </Fragment>
  );
};

const EntityTableWithMerge = withEntityMerge(EntityTable);

export default (props) =>
  props.isFetchingMainSearch ? (
    <EntityTableGhostLoading {...props} />
  ) : (
    <EntityTableWithMerge {...props} />
  );
