import { createAction } from 'redux-actions';
import jsCookie from 'js-cookie';
import { sendMessage } from '../frame/frame';
import {
  saveConsentEntries,
  saveConsentForm,
  fetchConsentFormById,
  fetchAllConsentForms,
  fetchAllConsent,
  saveConsent,
  fetchConsentFormByIdForEdit,
  saveSingleConsentEntry,
  fetchConsentFormByFormId,
  saveManualConsentEntries,
  fetchConsentEntiresForConsentEntry,
} from './data';

import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
  defaultAsyncCallWithActionsWithParam,
} from '../../action/helpers/actionCreator';

export const shouldShowAddIdentifierDialog = createAction(
  'GDPR_SHOW_DIALOG_ADD_IDENTIFIER',
);
export const shouldShowHideIdentifierDialog = createAction(
  'GDPR_HIDE_DIALOG_ADD_IDENTIFIERS',
);

export const addConsentEntryForConsent = createAction(
  'CONSENT_ADD_CONSENT_ENTRY_FOR_CONSENT',
);
export const clearAddConsentEntryForConsent = createAction(
  'CONSENT_CLEAR_ADD_CONSENT_ENTRY_FOR_CONSENT',
);

export const addConsentEntryForForm = createAction(
  'CONSENT_ADD_CONSENT_ENTRY_FOR_CONSENT_FORM',
);
export const clearAddConsentEntryForForm = createAction(
  'CONSENT_CLEAR_CONSENT_ENTRY_FOR_CONSENT_FORM',
);

export const clearResult = createAction('SHOULD_CLEAR_RESULT_FOR_CONSENT_FORM');

export const clearConsentSearch = createAction('SHOULD_CLEAR_CONSENT_SEARCH');
export const shouldSelectConsentSearch = createAction(
  'SHOULD_SELECT_CONSENT_SEARCH',
);

export const shouldFetchConsentEntriesForConsentActions = createAsyncAction(
  'SHOULD_FETHC_CONSENT_ENTRIES_FOR_CONSENT_ID',
);
export const shouldFetchConsentEntriesForConsent =
  defaultAsyncCallWithActionsWithParam(
    fetchConsentEntiresForConsentEntry,
    shouldFetchConsentEntriesForConsentActions,
  );

export const shouldFetchMoreConsentEntriesForConsentActions = createAsyncAction(
  'SHOULD_FETCH_CONSENT_ENTRIES_FOR_CONSENT_ID_LOAD_MORE',
);
export const shouldFetchMoreConsentEntriesForConsent =
  defaultAsyncCallWithActionsWithParam(
    fetchConsentEntiresForConsentEntry,
    shouldFetchMoreConsentEntriesForConsentActions,
  );

export const shouldSaveSingleConsentEntryActions = createAsyncAction(
  'SAVE_SINGLE_CONSENT_ENTRY',
);
export const shouldSaveSingleConsentEntry = noBodyResponseAsyncCallWithActions(
  saveSingleConsentEntry,
  shouldSaveSingleConsentEntryActions,
);

export const shouldFetchConsentFormByFormIdActions = createAsyncAction(
  'CONSENT_FETCH_CONSENT_FORM_BY_ID',
);
export const shouldFetchConsentFormByFormId = defaultAsyncCallWithActions(
  fetchConsentFormByFormId,
  shouldFetchConsentFormByFormIdActions,
);

export const shouldSaveManualConsentEntriesForFormActions = createAsyncAction(
  'CONSENT_SAVE_CONSENT_ENTRIES_FOR_MANUAL_FORM',
);
export const shouldSaveManualConsentEntriesForForm =
  noBodyResponseAsyncCallWithActions(
    saveManualConsentEntries,
    shouldSaveManualConsentEntriesForFormActions,
  );

export const shouldFetchConsentFormByIdForEditActions = createAsyncAction(
  'CONSENT_FETCH_CONSENT_FORM_BY_ID_FOR_EDIT',
);
export const shouldFetchConsentFormByIdForEdit = defaultAsyncCallWithActions(
  fetchConsentFormByIdForEdit,
  shouldFetchConsentFormByIdForEditActions,
);

export const shouldUpdateUniqueIdentifier = createAction(
  'SHOULD_UPDATE_UNIQUE_IDENFIER_FOR_CONSENT',
);
export const shouldUpdateDataProcessor = createAction(
  'SHOULD_UPDATE_DATA_PROCESSOR_FOR_CONSENT',
);

export const shouldSaveConsentEntriesActions = createAsyncAction(
  'CONSENT_SAVE_CONSENT_END_USER_ENTRIES',
);
export const shouldSaveConsentEntries = (param) => (dispatch) => {
  dispatch(shouldSaveConsentEntriesActions.request(param));

  const {
    consentEntries,
    apiToken,
    generatedConsentToken,
    consentForEndUser,
    formId,
  } = param;

  saveConsentEntries(consentEntries, apiToken, generatedConsentToken)
    .then(() => {
      jsCookie.set('consent_token', generatedConsentToken);
      jsCookie.set('consent_form_id', formId);

      dispatch(shouldSaveConsentEntriesActions.receive(param));

      sendMessage('consent', consentForEndUser);
    })
    .catch(() => {
      dispatch(shouldSaveConsentEntriesActions.invalid(param));
    });
};

/**
 * CONSENT FORMS
 */
/* create/save consentForm */
export const shouldSaveConsentFormOnBackEndActions = createAsyncAction(
  'SAVE_CONSENT_FORM_ON_BACKEND',
);

export const shouldSaveConsentFormOnBackEnd = defaultAsyncCallWithActions(
  saveConsentForm,
  shouldSaveConsentFormOnBackEndActions,
);

/* fetch/get consentForm by id */
export const shouldFetchConsentFormsByIdActions = createAsyncAction(
  'FETCH_CONSENT_FORM_BY_ID',
);

export const shouldFetchConsentFormById =
  (consentFromId, token) => (dispatch) => {
    dispatch(shouldFetchConsentFormsByIdActions.request(consentFromId));

    fetchConsentFormById(consentFromId, token)
      .then((resp) => {
        dispatch(shouldFetchConsentFormsByIdActions.receive(resp));
      })
      .catch((err) => {
        dispatch(shouldFetchConsentFormsByIdActions.invalid(err));
      });
  };

/* fetch/get consentForm(s) */
export const shouldFetchAllConsentFormsActions = createAsyncAction(
  'FETCH_ALL_CONSENT_FORMS',
);

export const shouldFetchAllConsentForms = () => (dispatch) => {
  dispatch(shouldFetchAllConsentFormsActions.request());

  fetchAllConsentForms()
    .then((resp) => {
      dispatch(shouldFetchAllConsentFormsActions.receive([resp]));
    })
    .catch((err) => {
      dispatch(shouldFetchAllConsentFormsActions.invalid(err));
    });
};

export const shouldLoadMoreAllConsentFormsActions = createAsyncAction(
  'FETCH_ALL_CONSENT_FORMS_LOAD_MORE',
);
export const shouldLoadMoreAllConsentForms = (param) => (dispatch) => {
  dispatch(shouldLoadMoreAllConsentFormsActions.request(param));

  fetchAllConsentForms(param)
    .then((resp) => {
      dispatch(shouldLoadMoreAllConsentFormsActions.receive([resp]));
    })
    .catch((err) => {
      shouldLoadMoreAllConsentFormsActions.invalid(err);
    });
};

export const shouldSaveConsentActions = createAsyncAction('SAVE_CONSENT');

export const shouldSaveConsent = (consent) => (dispatch) => {
  dispatch(shouldSaveConsentActions.request(consent));

  saveConsent(consent)
    .then((resp) => {
      dispatch(shouldSaveConsentActions.receive(resp));
    })
    .catch((err) => {
      shouldSaveConsentActions.invalid(err);
    });
};

export const shouldFetchAllConsentActions =
  createAsyncAction('FETCH_ALL_CONSENT');
export const shouldFetchAllConsent = defaultAsyncCallWithActions(
  fetchAllConsent,
  shouldFetchAllConsentActions,
);

export const shouldFetchMoreAllConsentActions = createAsyncAction(
  'FETCH_ALL_CONSENT_LOAD_MORE',
);
export const shouldFetchMoreAllConsent = defaultAsyncCallWithActions(
  fetchAllConsent,
  shouldFetchMoreAllConsentActions,
);

export const clearSingleResult = createAction('SHOULD_CLEAR_SINGLE_CONSENT');

export const shouldSaveSingleConsentFormOnBackEndActions = createAsyncAction(
  'CREATE_SINGLE_CONSENT',
);
export const shouldSaveSingleConsentFormOnBackEnd =
  noBodyResponseAsyncCallWithActions(
    saveConsent,
    shouldSaveSingleConsentFormOnBackEndActions,
  );
