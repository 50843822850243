import { showSuccess } from 'uxi-business/userMessage/actions';
// import { defaultErrorHandling } from 'uxi-business/errorHandling';
import {
  markAsSensitive,
  markAsUnSensitive,
  updateEntity,
  executeUpdateEntity,
  followEntity,
  unFollowEntity,
  approveMeshCmd,
  rejectMeshCmd,
} from './data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
} from '../core/helpers/action';
import {
  optimisticlyIncrementLocalCurrentEntityFollowCount,
  optimisticlyDecrementLocalCurrentEntityFollowCount,
  shouldFetchEntityMessage,
} from '../entity/actions';
import { followAction, unFollowAction } from '../stream/actions';
import { shouldShowAlert } from '../core/actions';

export const sensitiveActions = createAsyncAction('SENSITIVE_');
export const unSensitiveActions = createAsyncAction('UN_SENSITIVE_');

export const shouldMarkAsSensitive = defaultAsyncCallWithActions(
  markAsSensitive,
  sensitiveActions,
  (dispatch) => {
    dispatch(
      showSuccess({
        message: 'A request has been sent to mark the entity as sensitive.',
      }),
    );
  },
);

export const shouldMarkAsUnSensitive = defaultAsyncCallWithActions(
  markAsUnSensitive,
  unSensitiveActions,
  (dispatch) => {
    dispatch(
      showSuccess({
        message: 'A request has been sent to mark the entity as un-sensitive.',
      }),
    );
  },
);

export const updateEntityActions = createAsyncAction('MESH_ENTITY_UPDATE');
export const shouldUpdateEntity = noBodyResponseAsyncCallWithActions(
  updateEntity,
  updateEntityActions,
);

export const approveMeshCmdActions = createAsyncAction('APPROVE_MESH_CMD');
const onApproveMeshCmdSuccess = (dispatch, { Query }) => {
  dispatch(shouldFetchEntityMessage(Query));
};
export const shouldApproveMeshCmd = noBodyResponseAsyncCallWithActions(
  approveMeshCmd,
  approveMeshCmdActions,
  onApproveMeshCmdSuccess,
);
export const rejectMeshCmdActions = createAsyncAction('REJECT_MESH_CMD');
const onRejectMeshCmdSuccess = (dispatch, { entityId }) => {
  dispatch(shouldFetchEntityMessage(entityId));
};
export const shouldRejectMeshCmd = noBodyResponseAsyncCallWithActions(
  rejectMeshCmd,
  rejectMeshCmdActions,
  onRejectMeshCmdSuccess,
);

export const executeUpdateEntityActions = createAsyncAction(
  'REQUEST_EXECUTE_MESH_ENTITY_UPDATE',
);

export const shouldExecuteUdpateEntity = (params, onSuccess) => (dispatch) => {
  dispatch(executeUpdateEntityActions.request(params));

  return executeUpdateEntity(params)
    .then((/* resp */) => {
      if (onSuccess) {
        onSuccess();
      }
      dispatch(
        shouldShowAlert({
          description: 'You have successfully send a mesh request',
          type: 'success',
        }),
      );
      return dispatch(executeUpdateEntityActions.receive(params));
    })
    .catch((/* err */) => {
      dispatch(executeUpdateEntityActions.invalid(params));
    });
};

export const followActions = createAsyncAction('ENTITY_ACTIONS_PUBLIC_FOLLOW');
export const unFollowActions = createAsyncAction(
  'ENTITY_ACTIONS_PUBLIC_FOLLOW',
);

export const follow = noBodyResponseAsyncCallWithActions(
  followEntity,
  followActions,
  (dispatch, params) => {
    dispatch(optimisticlyIncrementLocalCurrentEntityFollowCount());
    dispatch(followAction(params));
    dispatch(
      shouldShowAlert({
        description: `You have successfully followed ${
          (params && params.name) || ''
        }`,
        type: 'success',
      }),
    );
  },
);

export const unFollow = noBodyResponseAsyncCallWithActions(
  unFollowEntity,
  unFollowActions,
  (dispatch, params) => {
    dispatch(optimisticlyDecrementLocalCurrentEntityFollowCount());
    dispatch(unFollowAction(params));
    dispatch(
      shouldShowAlert({
        description: `You have successfully un-followed ${
          (params && params.name) || ''
        }`,
        type: 'success',
      }),
    );
  },
);
