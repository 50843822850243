import { useQuery, gql } from '@apollo/client';

export const GET_ENTITY_TYPE_LAYOUT_CONFIGURATION = gql`
  query entityTypeLayoutConfigurations {
    management {
      id
      entityTypeLayoutConfigurations {
        id
        displayName
        icon
        route
        path
        entityType
        type
        template
      }
    }
  }
`;

export type EntityTypeLayoutConfiguration = {
  id: string;
  displayName: string;
  icon: string;
  route: string;
  path: string;
  entityType: string;
  type: string;
  template: string | unknown | null;
};

type EntityTypeLayoutConfigurationsRes = {
  management: {
    entityTypeLayoutConfigurations: EntityTypeLayoutConfiguration[];
  };
};

export const useEntityTypeLayoutConfigurations = () => {
  const { data, loading, error } = useQuery<EntityTypeLayoutConfigurationsRes>(
    GET_ENTITY_TYPE_LAYOUT_CONFIGURATION,
    {
      fetchPolicy: 'network-only',
    },
  );

  const entityTypeLayoutConfigurations =
    data?.management?.entityTypeLayoutConfigurations || [];

  return {
    data: entityTypeLayoutConfigurations,
    loading,
    error,
  };
};
