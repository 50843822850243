import { FC, useEffect } from 'react';

import { withEntityGroupedRelationPanelWorkflow } from './withEntityGroupedRelationPanelWorkflow';
import { GroupedNode } from '../types';

type GroupedRelationPanelProps = {
  entityId: string;
  groupedNode: GroupedNode;
  openGroupedRelationPanelWorkflow: () => void;
};

const GroupedRelationPanel: FC<GroupedRelationPanelProps> = ({
  entityId,
  groupedNode,
  openGroupedRelationPanelWorkflow,
}) => {
  useEffect(() => {
    if (groupedNode) {
      openGroupedRelationPanelWorkflow?.();
    }
  }, [groupedNode]);

  return null;
};

export const EntityGroupedRelationPanel =
  withEntityGroupedRelationPanelWorkflow(GroupedRelationPanel);
