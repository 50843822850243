import React from 'react';
import EntitySelectableListItemWrapper from '../containers/EntitySelectableListItemWrapper';

const toVm = (entityOrPseudoEntity = {}) => ({
  entityType: entityOrPseudoEntity.data
    ? entityOrPseudoEntity.data.entityType
    : entityOrPseudoEntity.entityType,
  entityId: entityOrPseudoEntity.id || entityOrPseudoEntity.entityId,
  name: entityOrPseudoEntity.name,
});

export const withEntitySelectable =
  (Comp, propsName) =>
  ({ key, noBorderTop, ...restOfprops }) => {
    const mergeViewModel = toVm(restOfprops.entity || restOfprops[propsName]);

    return (
      <EntitySelectableListItemWrapper
        key={`EntitySelectableListItemWrapper-${key}`}
        mergeViewModel={mergeViewModel}
        noBorderTop={noBorderTop}
      >
        <Comp noBorderTop={noBorderTop} {...restOfprops} />
      </EntitySelectableListItemWrapper>
    );
  };

export default withEntitySelectable;
