import styled, { css } from 'styled-components';

export const ExplainLogEntryBox = styled.div`
  background: #ffffff;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  max-width: 100%;
`;

export const ExplainLogEntryWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 36px;
  padding: 14px 0 0 0;
`;

export const ExplainLogEntryHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 14px;
  position: relative;
  width: 100%;

  &::after {
    border-bottom: ${({ isExpanded }) =>
      isExpanded ? '1px solid #ececec' : null};
    content: '';
    min-width: 100%;
    position: absolute;
    top: 100%;
  }
`;

export const ExplainLogEntryHeadingWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
`;

export const ExplainLogEntryBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid ${({ themePrimary }) => themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -5.5px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const ExplainLogEntryDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
`;

export const ExplainLogEntryDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & strong {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2px;
  }
`;

export const ExplainLogEntryCategoryWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 100ms ease;
  width: 100%;

  ${({ isExpanded }) => {
    switch (false) {
      case isExpanded:
        return css`
          display: none;
          opacity: 0;
          visibility: hidden;
        `;
      default:
        return;
    }
  }}
`;
