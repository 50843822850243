import React from 'react';
import { Link } from 'react-router-dom';
import withEntityLinkFromEntity from '../Hocs/withEntityLinkFromEntity';

const EntityLinkFromEntity = ({ entityUrl, children, ...rest }) => {
  return (
    <Link {...rest} to={entityUrl}>
      {children}
    </Link>
  );
};

export default withEntityLinkFromEntity(EntityLinkFromEntity);
