export function getSemanticColor(
  value,
  saturation = '100%',
  lightness = '50%',
  alpha = 1,
  hueOffset = 0,
) {
  // value from 0 to 1
  const hue = ((1 - value) * 120 + hueOffset).toString(10);
  return [
    'hsla(',
    hue,
    `, ${saturation}`,
    `, ${lightness}`,
    `, ${alpha})`,
  ].join('');
}

const semanticColors = {
  info: 'rgb(49, 112, 143)',
  warning: '#FF9800',
  success: '#009688',
  danger: '#EF5858',
};

export const getColorForType = (type = 'info') => {
  const color = semanticColors[type.toLowerCase()];

  if (!color) {
    return semanticColors.success;
  }

  return color;
};

export default {
  getSemanticColor,
  getColorForType,
};
