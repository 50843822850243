import React, { Fragment } from 'react';
import { isString } from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Flex } from 'uxi/Layout';
import { Action, getIcon } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import DashboardTitle from './DashboardTitle';
import DashboardWidget from './DashboardWidget';
import { createTestId } from '../../../helpers/string';
import { OrganizationFeatureFlag } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';

const DashboardActions = ({ actions = [], navigate, name }) => {
  const dashboardWidget = (action) => {
    let GivenIcon = getIcon(action.icon || 'Help');
    if (action.IconComponent) {
      GivenIcon = action.IconComponent;
    }
    const valueForTestId = action.key || action.name;
    const testId = isString(valueForTestId) ? createTestId(valueForTestId) : '';

    return (
      <DashboardWidget
        className={`test_${name}Dashboard_${action.name}`}
        key={action.name}
        dataTestName={testId}
        betaLabel={
          action?.isBeta && (
            <FormattedMessage id="module-core-dashbioardAction-action-beta" />
          )
        }
        deprecatedLabel={
          action?.isDeprecated && (
            <FormattedMessage id="module-core-dashbioardAction-action-deprecated" />
          )
        }
      >
        <Action
          type="primary"
          menuDescriptor={{
            displayName: action.name,
            icon: <GivenIcon />,
            key: action.name,
            hasNew: false,
            onClick: () => {
              if (action.link.indexOf('https://') > -1) {
                const win = window.open(action.link, '_blank');
                win.focus();
              } else {
                navigate(action.link);
              }
            },
            isPromoted: false,
          }}
          actionConfiguration={action.claims}
        />
      </DashboardWidget>
    );
  };

  return (
    <Fragment>
      <DashboardTitle>
        <FormattedMessage id="module-core-dashbioardAction-action" />
      </DashboardTitle>
      <Flex
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
        }}
      >
        {actions.map((action) => {
          const actionFeature = action?.featureName;
          return actionFeature ? (
            <OrganizationFeatureFlag
              MainFeature={() => <></>}
              ExperimentalFeature={dashboardWidget(action)}
              featureName={actionFeature}
            />
          ) : (
            dashboardWidget(action)
          );
        })}
      </Flex>
    </Fragment>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  navigate(url) {
    dispatch(push(url));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardActions);
