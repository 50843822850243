import PropTypes from 'prop-types';

export const EntityCard = ({
  ctx,
  label,
  entityType,
  x,
  y,
  entityIcon,
  keyImage,
  background,
  borderColor,
  iconBackground,
  entityTypeFontStyle,
  entityTypeFontColor,
  entityFontStyle,
  entityFontColor,
  width,
  height,
}) => {
  const coords = {
    x: x - width / 2,
    y: y - height / 2,
    w: width,
    h: height,
  };

  ctx.fillStyle = background;
  ctx.fillRect(coords.x, coords.y, coords.w, coords.h);
  ctx.strokeStyle = borderColor;
  ctx.strokeRect(coords.x, coords.y, coords.w, coords.h);

  ctx.fillStyle = iconBackground;

  const iconSize = 16;
  const arcSize = 14;
  const keySize = 12;

  ctx.beginPath();
  ctx.globalAlpha = 0.8;
  ctx.arc(x - width / 2 + 24, y, arcSize, 0, 2 * Math.PI);
  ctx.fill();
  ctx.closePath();
  ctx.globalAlpha = 1;

  if (entityIcon) {
    ctx.drawImage(entityIcon, x - width / 2 + 16, y - 8, iconSize, iconSize);
  }
  ctx.globalAlpha = 1;

  if (entityType) {
    ctx.font = entityTypeFontStyle;
    ctx.fillStyle = entityTypeFontColor;
    ctx.fillText(entityType, x - width / 2 + 48, y - 5);
  }

  if (label) {
    ctx.font = entityFontStyle;
    ctx.fillStyle = entityFontColor;
    ctx.fillText(label, x - width / 2 + 48, y + 10 + (entityType ? 0 : -5));
  }

  const keyCoords = { x: x + 70, y: y - 14 };

  ctx.beginPath();
  if (keyImage) {
    ctx.drawImage(keyImage, keyCoords.x, keyCoords.y, keySize, keySize);
  }
  ctx.closePath();

  return ctx;
};

EntityCard.propTypes = {
  ctx: PropTypes.object,
  label: PropTypes.string,
  entityIcon: PropTypes.object,
  keyImage: PropTypes.object,
  entityType: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  borderColor: PropTypes.string,
  iconBackground: PropTypes.string,
  entityTypeFontStyle: PropTypes.string,
  entityTypeFontColor: PropTypes.string,
  entityFontStyle: PropTypes.string,
  entityFontColor: PropTypes.string,
};
