import React from 'react';
import { CoreVocabulary } from '@cluedin/svgs';
import { FirstLetterComponent } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { AvatarWithName } from '@cluedin/atoms';
import { urlBuilder } from '../../../../config';
import { removeFirstCharacter } from '../../../core/helpers/string';

const VocabularySource = ({
  connector,
  fallBackName,
  isCluedInCore,
  hideLabels = false,
  CoreVocabularySize = '24px',
  AvatarWithNameIconSize = 'M',
}) => {
  if (!connector) {
    return (
      <FirstLetterComponent
        name={fallBackName}
        size={AvatarWithNameIconSize}
        style={{ marginRight: '8px' }}
      />
    );
  }

  if (isCluedInCore) {
    return (
      <AvatarWithName
        icon={
          <CoreVocabulary
            width={CoreVocabularySize}
            height={CoreVocabularySize}
          />
        }
        name={!hideLabels && 'Core'}
      />
    );
  }

  if (connector && !connector?.name) {
    // && !fallBackName) {
    if (hideLabels) return null;

    return <FormattedMessage id="module-dataCatalog-noVocabSource" />;
  }

  const iconUrl =
    connector && connector.icon && connector.icon[0] === '/'
      ? removeFirstCharacter(connector.icon)
      : connector.icon;
  const fullIconUrl = iconUrl ? urlBuilder.api(iconUrl) : null;

  return (
    <AvatarWithName
      src={fullIconUrl}
      icon={
        fullIconUrl ? (
          <FirstLetterComponent
            name={connector.name}
            customSize="24"
            customFontSize="14"
          />
        ) : null
      }
      name={!hideLabels && (connector?.name || fallBackName)}
    />
  );
};

export default VocabularySource;
