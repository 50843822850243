export const copyToClipboard = (value) => {
  // Create a "hidden" input
  const aux = document.createElement('input');

  // Assign it the value of the specified element
  aux.setAttribute('value', value);

  // Append it to the body
  document.body.appendChild(aux);

  // Highlight its content
  aux.select();

  // Copy the highlighted text
  document.execCommand('copy');

  // Remove it from the body
  document.body.removeChild(aux);

  return true;
};

export const getAntiForgeryTokenFromDOM = () => {
  const $antiForgery = document.querySelector(
    'input[name=__RequestVerificationToken]',
  );

  if (!$antiForgery) {
    return null;
  }

  return $antiForgery.value;
};

export default {
  copyToClipboard,
  getAntiForgeryTokenFromDOM,
};
