import { handleActions } from 'redux-actions';
import {
  initMerge,
  abortMerge,
  selectEntity,
  deselectEntity,
  deselectAllEntities,
  updateTarget,
  fetchMergeEntitiesActions,
  submitMergedEntitesActions,
} from './actions';
import { entityTypesPool } from './entityTypesPool';

export const errorsEnum = {
  errorMaxSelection: 'MAX_SELECTION',
  errorAlreadyMerging: 'ALREADY_MERGING',
  errorEntityType: 'MUST_BE_SAME_TYPE',
  errorRemoveEntity: 'REMOVE_ENTITY',

  errorFetching: 'FETCHING_ERROR',

  errorSubmitting: 'SUBMITTING_ERROR',
};

export const initialState = {
  isMerging: false,
  selectedEntities: [],
  config: { min: 2, max: 2, sameEntityType: false },
  errors: [],
  currentEntityType: null,
  isSelectionValid: false,

  isFetching: false,

  isSubmitting: false,
  isDone: false,
  entities: [],
};

/**
 * Waiting to get another instance of "selectable" for a purpose other than
 * merging entites (with another config) to really see how the error handling works
 * when attempting to start to make thing
 * "selectable" while a merge is already in progress
 * and other scenario
 * -df
 */
export default handleActions(
  {
    [initMerge]: (state, { payload: config }) => {
      let error = null;
      // assert config has the right shape, using flow ? -df
      let configValue = state.config;
      if (state.isMerging) {
        error = errorsEnum.errorAlreadyMerging;
      } else {
        configValue = config;
      }
      return {
        ...state,
        isMerging: true,
        config: configValue,
        isSelectionValid: false,
        errors: error ? [error] : [],
      };
    },
    [abortMerge]: (/* state */) => ({
      /* ...state, reset state */
      ...initialState,
    }),
    [selectEntity]: (state, { payload }) => {
      let errorMaxSelection = null;
      let errorEntityType = null;
      let finalErrors = [];
      let entityType = state.currentEntityType;
      let isSelectionValid = state.isSelectionValid;
      const newselectedEntities = [...state.selectedEntities];
      const mergeViewModel = {
        entityId: payload.id || payload.entityId,
        entityType:
          payload.entityType ||
          (payload.data.entityType ? payload.data.entityType : ''),
        name: payload.displayName || payload.name,
      };

      // eslint-disable-next-line no-param-reassign
      // entity = toEntityMergeModel(entity);

      if (state.selectedEntities.length === 0) {
        // selecting first entity
        entityType =
          payload.entityType ||
          (payload.data.entityType ? payload.data.entityType : '');
      }

      if (newselectedEntities.length + 1 === state.config.max) {
        if (state.config.sameEntityType) {
          const currentEntityType = state.currentEntityType;

          const isInEntityPool =
            entityTypesPool &&
            entityTypesPool[currentEntityType] &&
            entityTypesPool[currentEntityType].indexOf(
              mergeViewModel.entityType,
            ) > -1;
          if (
            mergeViewModel.entityType === currentEntityType ||
            isInEntityPool
          ) {
            newselectedEntities.push(mergeViewModel);
            isSelectionValid = true;
            finalErrors = [];
          } else {
            errorEntityType = errorsEnum.errorEntityType;
          }
        } else {
          newselectedEntities.push(mergeViewModel);
          // isSelectionValid = true;
          finalErrors = [];
        }
      } else if (newselectedEntities.length + 1 < state.config.max) {
        newselectedEntities.push(mergeViewModel);
        isSelectionValid = false;
      } else {
        errorMaxSelection = errorsEnum.errorMaxSelection;
      }

      if (newselectedEntities.length >= 2) {
        isSelectionValid = true;
      }

      if (errorEntityType) {
        finalErrors.push(errorEntityType);
      }
      if (errorMaxSelection) {
        finalErrors.push(errorMaxSelection);
      }

      return {
        ...state,
        selectedEntities: newselectedEntities,
        errors: finalErrors,
        isSelectionValid,
        currentEntityType: entityType,
      };
    },
    [deselectEntity]: (state, { payload }) => {
      const cleanedUpSelectedEntities = [
        ...(state.selectedEntities || []).filter(
          (x) => x.entityId !== payload.entityId,
        ),
      ];

      const found = state.selectedEntities.find(
        (x) => x.entityId === payload.entityId,
      );
      const newErrors = found
        ? state.errors
        : [...state.errors, 'REMOVE_ENTITY'];

      return {
        ...state,
        selectedEntities: cleanedUpSelectedEntities,
        errors: newErrors,
        isSelectionValid:
          cleanedUpSelectedEntities.length > 0 &&
          cleanedUpSelectedEntities.length >= state.config.min,
        currentEntityType:
          cleanedUpSelectedEntities.length === 0
            ? null
            : state.currentEntityType,
      };
    },
    [deselectAllEntities]: (state) => {
      return {
        ...state,
        selectedEntities: [],
        errors: [],
        isSelectionValid: false,
        currentEntityType: null,
      };
    },
    [updateTarget]: (state, { payload }) => {
      const { selectedEntities: currentSelectedEntities } = state;
      const newTargetIdx = currentSelectedEntities.findIndex(
        (x) => x.entityId === payload,
      );
      const newTarget = currentSelectedEntities.splice(newTargetIdx, 1);
      const selectedEntities = [...newTarget, ...currentSelectedEntities];
      return {
        ...state,
        selectedEntities,
      };
    },
    [fetchMergeEntitiesActions.request]: (state) => ({
      ...state,
      isFetching: true,
      error: [],
    }),
    [fetchMergeEntitiesActions.invalid]: (state, { payload: error }) => ({
      ...state,
      errors: state.errors.concat(error),
      isFetching: false,
    }),
    [fetchMergeEntitiesActions.receive]: (state, { payload: entities }) => ({
      ...state,
      isFetching: false,
      entities,
      isSelectionValid: entities.length >= state.config.min,
    }),
    [submitMergedEntitesActions.request]: (state) => ({
      ...state,
      isSubmitting: true,
    }),
    [submitMergedEntitesActions.invalid]: (state) => ({
      ...state,
      isSubmitting: false,
      errors: [errorsEnum.errorSubmitting],
    }),
    [submitMergedEntitesActions.receive]: (state) => ({
      ...state,
      isSubmitting: false,
      errors: [],
      isDone: true,
    }),
  },
  initialState,
);
