import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

const meshRoutes = [
  {
    path: '/',
    exact: true,
    claims: {
      'preparation.meshcenter': RACI_LEVELS.INFORMED,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"MeshCenterPage" */
          './components/pages/MeshCenterPage'
        ),
      ),
    ),
  },
];

export default meshRoutes;
