"use strict";

exports.__esModule = true;
exports.useExpansion = void 0;
var _react = require("react");
var _ExpansionContext = require("./ExpansionContext");
var useExpansion = function useExpansion() {
  var context = (0, _react.useContext)(_ExpansionContext.ExpansionContext);
  if (context === undefined) {
    throw new Error('useExpansion must be used within a ExpansionProvider');
  }
  return context;
};
exports.useExpansion = useExpansion;