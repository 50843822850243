import React from 'react';
import { useTheme } from '@cluedin/theme';
import { Checkmark } from 'uxi/Icons';

const SuccessMessage = ({ children }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <span className="__test_successMessage" style={{ color: themePrimary }}>
      {children}
      <Checkmark size={12} color={themePrimary} style={{ marginLeft: '8px' }} />
    </span>
  );
};

export default SuccessMessage;
