import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

const CluedInBigTitleStyle = {
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2c2d30',
  },
};

const CluedInBigTitle = ({ children }) => (
  <h1 style={CluedInBigTitleStyle.title}>{children}</h1>
);

CluedInBigTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default pure(CluedInBigTitle);
