import React from 'react';
import { Link } from 'react-router-dom';

const HierarchyLink = ({ name, id, entityCode }) => {
  return (
    <Link
      className="__test_hierarchyItem"
      style={{ wordBreak: 'break-word' }}
      to={`/admin/management/hierarchy/detail/${id}${
        entityCode ? `?entityCode=${encodeURIComponent(entityCode)}` : ''
      }`}
    >
      {name}
    </Link>
  );
};

export default HierarchyLink;
