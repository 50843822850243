import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'engine.configurationgroups': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"ConfigurationGroup" */
        './components/pages/ConfigurationGroup'
      ),
    ),
  },
  {
    path: '/details/:id',
    exact: true,
    claims: {
      'engine.configurationgroups': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"ConfigurationGroupItems" */
        './components/pages/ConfigurationGroupItems'
      ),
    ),
  },
];
