import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';

import EntityTopologySplitPanelProviderIcon from '../../composites/EntityTopologySplitPanelProviderIcon/EntityTopologySplitPanelProviderIcon';
import { useGetDataPartNodeInfo } from '../../../hooks/useGetDataPartNodeInfo';
import { columns } from './columns';
import EntityTopologyDataPartPanelLink from '../../composites/EntityTopologyDataPartPanelLink/EntityTopologyDataPartPanelLink';
import EntityTopologySplitPanelListWarningMessage from './EntityTopologySplitPanelListWarningMessage';
import { EntityTopologySplitPanelListText } from './EntityTopologySplitPanelList.styles';

const EntityTopologyDataPartLinkWrapper = ({
  currentNode,
  name,
  entityId,
  dataPartId,
  source,
  dataType,
}) => {
  const [
    getDataPartNodeInfo,
    {
      data: dataPartNodeInfoData,
      loading: dataPartNodeInfoLoading,
      error: dataPartNodeInfoError,
    },
  ] = useGetDataPartNodeInfo();

  const dataPartPanelData = {
    currentNode: currentNode || {},
    dataPartNodeInfo: dataPartNodeInfoData || {},
  };

  useEffect(() => {
    if (entityId && dataPartId) {
      getDataPartNodeInfo(entityId, dataPartId);
    }
  }, [entityId, dataPartId]);

  const isRecord = currentNode?.provider?.providerId;
  const isDeduplicationProjectMerge =
    source?.kind?.toLowerCase() === 'deduplicationprojectmerge';
  const isManuallyAdded = source?.kind?.toLowerCase() === 'userinput';
  const isManuallyAddedFileEntry =
    source?.kind?.toLowerCase() === 'userinput' &&
    currentNode?.provider?.providerName?.toLowerCase() ===
      'manual entry source';
  const isClean = source?.kind?.toLowerCase() === 'clean';
  const isLegacyClean = source?.kind?.toLowerCase() === 'legacyclean';
  const isManualMerge =
    source?.kind?.toLowerCase() === 'manualmerge' ||
    (!currentNode?.provider?.providerId &&
      dataType?.toLowerCase() === 'manualmerge');

  let dataPartLinkName = name || (
    <FormattedMessage id="module-entityTopology-noName" />
  );
  if (isRecord) {
    dataPartLinkName = name || (
      <FormattedMessage id="module-entityTopology-noName" />
    );
  }
  if (isManuallyAddedFileEntry) {
    dataPartLinkName = (
      <FormattedMessage id="module-entityTopology-sourceManuallyAdded" />
    );
  }
  if (!currentNode?.provider?.providerName) {
    if (isManualMerge) {
      dataPartLinkName = (
        <FormattedMessage id="module-entityTopology-sourceManualMerge" />
      );
    }
    if (isClean || isLegacyClean) {
      dataPartLinkName = (
        <FormattedMessage id="module-entityTopology-sourceClean" />
      );
    }
    if (isManuallyAdded) {
      dataPartLinkName = (
        <FormattedMessage id="module-entityTopology-sourceManuallyAdded" />
      );
    }
    if (isDeduplicationProjectMerge) {
      dataPartLinkName = (
        <FormattedMessage id="module-entityTopology-sourceDeduplicationProjectMerge" />
      );
    }
  }

  return (
    <EntityTopologyDataPartPanelLink
      name={dataPartLinkName}
      recordPanelData={dataPartPanelData}
    />
  );
};

const FormattedSource = ({ provider, source, dataType }) => {
  return (
    <EntityTopologySplitPanelProviderIcon
      provider={provider}
      source={source}
      dataType={dataType}
    />
  );
};

const EntityTopologySplitPanelList = ({
  dataType,
  data,
  loading,
  error,
  entityId,
  currentNode,
  selectedDataParts,
  setSelectedDataParts,
  offsetTop,
}) => {
  const dataParts = data && data?.actions[0]?.dataParts;

  useEffect(() => {
    if (setSelectedDataParts) {
      setSelectedDataParts(
        dataParts.length > 1 ? [] : [dataParts?.[0]?.dataPartId],
      );
    }
  }, [data, dataParts]);

  let selectTitle;
  if (dataType === 'ManualMerge') {
    selectTitle = (
      <FormattedMessage id="module-entityTopology-manualMergeSelectTitle" />
    );
  }
  if (dataType === 'EntityCode') {
    selectTitle = (
      <FormattedMessage id="module-entityTopology-splitPanelSelectTitle" />
    );
  }
  if (dataType === 'DeduplicationProjectMerge') {
    selectTitle = (
      <FormattedMessage id="module-entityTopology-deduplicationProjectMergeSelectTitle" />
    );
  }

  let selectText;
  if (dataType === 'ManualMerge') {
    selectText = (
      <FormattedMessage id="module-entityTopology-manualMergeSelectText" />
    );
  }
  if (dataType === 'EntityCode') {
    selectText = (
      <>
        {data?.actions[0]?.kind === 'RemoveEntityCode' && (
          <>
            <FormattedMessage id="module-entityTopology-splitPanelSelectTextIfSelectable" />
            &nbsp;
          </>
        )}
        <FormattedMessage id="module-entityTopology-splitPanelSelectText" />
      </>
    );
  }
  if (dataType === 'DeduplicationProjectMerge') {
    selectText = (
      <FormattedMessage id="module-entityTopology-deduplicationProjectMergeSelectText" />
    );
  }

  return data && data?.actions[0]?.availability === 'Available' ? (
    <>
      <div style={{ marginBottom: '20px' }}>
        <EntityTopologySplitPanelListText>
          {selectTitle}
        </EntityTopologySplitPanelListText>
        {selectText}
      </div>

      <List
        offsetTop={offsetTop}
        hideExpanding
        noSearch
        columns={[
          {
            property: 'dataPartId',
            displayName: (
              <FormattedMessage id="module-entityTopology-recordId" />
            ),
            width: '100px',
          },
          {
            property: 'name',
            displayName: <FormattedMessage id="module-entityTopology-name" />,
            Component: (props) => {
              return (
                props?.dataPartId &&
                entityId && (
                  <EntityTopologyDataPartLinkWrapper
                    name={props?.name}
                    dataPartId={props?.dataPartId}
                    entityId={entityId}
                    currentNode={props}
                    source={props?.source}
                    dataType={dataType}
                    {...props}
                  />
                )
              );
            },
          },
          {
            property: 'source',
            displayName: <FormattedMessage id="module-entityTopology-source" />,
            Component: (props) => (
              <FormattedSource dataType={dataType} {...props} />
            ),
          },
          ...columns,
        ]}
        propertyKey="dataPartId"
        data={dataParts}
        selectable={
          data?.actions[0]?.kind === 'RemoveEntityCode' && dataParts?.length > 1
        }
        selected={selectedDataParts}
        onSelectedChange={(selected) => {
          setSelectedDataParts(selected);
        }}
        showPagination={false}
        loading={loading}
        error={error}
        notFoundProps={{
          message: (
            <FormattedMessage id="module-entityTopology-entityCodePanelNotFoundMessage" />
          ),
          explanation: (
            <FormattedMessage id="module-entityTopology-entityCodePanelNotFoundExplanation" />
          ),
          noCreate: true,
        }}
      />
    </>
  ) : (
    <EntityTopologySplitPanelListWarningMessage
      unavailableReason={data?.actions[0]?.unavailableReason}
      unavailableKind={data?.kind}
      reasonMessage={data?.actions[0]?.reasonMessage}
    />
  );
};

export default EntityTopologySplitPanelList;

EntityTopologySplitPanelList.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  entityId: PropTypes.string,
  currentNode: PropTypes.object,
  selectedDataParts: PropTypes.array,
  setSelectedDataParts: PropTypes.func,
  dataType: PropTypes.string,
};
