import moment from 'moment';
import { TimeFromNow } from '@cluedin/components';

export const getPastDateRangeLimits = (dates = ['0']) => {
  let oldest = moment(new Date()); // now
  let newest = moment(new Date(0)); // 1/1/70

  (dates || []).forEach((date) => {
    const d = moment(date);
    if (d.isBefore(oldest)) {
      oldest = d;
    }

    if (d.isAfter(newest)) {
      newest = d;
    }
  });

  return [oldest, newest];
};

export const getFormattedDate = (dateStr) => {
  if (!dateStr) {
    return null;
  }

  return <TimeFromNow date={dateStr} />;
};
