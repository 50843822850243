import React from 'react';
import { get } from 'lodash';
import IntegrationIconContainer from '../../../../integration/components/containers/IntegrationIconContainer';
import CoreVocabIcon from './CoreVocabIcon';
import VocabularyIconRound from './VocabularyIconRound';

const VocabularyIcon = ({ vocabulary }) => {
  /**
   * isCluedInCore
   * isDynamic
   * isProvider
   */
  const sourceName = get(vocabulary, 'connector.name');

  if (sourceName) {
    return (
      <IntegrationIconContainer iconSize="s" integrationName={sourceName} />
    );
  }

  if (vocabulary && vocabulary.isCluedInCore && !sourceName) {
    return <CoreVocabIcon size={20} />;
  }

  return <VocabularyIconRound />;
};

export default VocabularyIcon;
