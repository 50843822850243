import React from 'react';
import { Select } from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';
import PropTypes from 'prop-types';

import { EntityPropertiesSourceDropdownFilterWrapper } from './EntityPropertiesSourceDropdownFilter.styles';

const EntityPropertiesSourceDropdownFilter = ({
  intl,
  groupNameFilterState,
  setGroupNameFilterState,
  propertyGroups,
}) => {
  const uniqueKeyToUse = 'name';
  const uniquePropertyGroups = [
    ...new Map(
      (propertyGroups || []).map((propGroup) => [
        propGroup[uniqueKeyToUse],
        propGroup,
      ]),
    ).values(),
  ];

  const newOptions = (uniquePropertyGroups || []).map((i) => {
    return {
      value: i?.name,
      label: i?.name,
      dataTest: `entity-entityProperties-sourceDropdown-${i?.name}`,
    };
  });

  const handleOnChange = ({ value }) => {
    setGroupNameFilterState(value);
  };

  return (
    <EntityPropertiesSourceDropdownFilterWrapper>
      <Select
        onChange={handleOnChange}
        options={[
          {
            value: '',
            label: intl.formatMessage({
              id: 'module-entityProperties-allPropertyGroups',
            }),
            dataTest: 'entity-entityProperties-allPropertyGroups',
          },
          ...newOptions,
        ]}
        value={groupNameFilterState || ''}
      />
    </EntityPropertiesSourceDropdownFilterWrapper>
  );
};

export default injectIntl(EntityPropertiesSourceDropdownFilter);

EntityPropertiesSourceDropdownFilter.propTypes = {
  groupNameFilterState: PropTypes.string,
  setGroupNameFilterState: PropTypes.func,
  propertyGroups: PropTypes.array,
};

EntityPropertiesSourceDropdownFilter.defaultProps = {
  groupNameFilterState: '',
  setGroupNameFilterState: () => {},
  propertyGroups: [],
};
