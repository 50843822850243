import { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import UserFilters from '../filters/UserFilters';

const filterByUserQuery = (query) => (user) => {
  const name =
    user.Entity && user.Entity.name ? user.Entity.name : user.Account.UserName;

  if (!name || !query) {
    return true;
  }

  return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
};

class UserListWithFilters extends Component {
  static propTypes = {
    users: PropTypes.array,
    currentUser: PropTypes.object,
    Action: PropTypes.any,
    ActionTitle: PropTypes.any,
    extraProps: PropTypes.object,
    condensed: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    users: [],
    currentUser: {},
    Action: null,
    ActionTitle: null,
    extraProps: {},
    condensed: false,
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      query: null,
    };
  }

  onSearchChangeHandler(value) {
    this.setState({
      query: value,
    });
  }

  render() {
    const {
      UserList,
      users,
      currentUser,
      Action,
      hideInvite,
      ActionTitle,
      extraProps,
      condensed,
      onChange,
      actionStyle,
      multiSelectable,
      notSelectable,
      grantAccess,
      revokeAccess,
    } = this.props;
    const { query } = this.state;

    const filteredUser = users ? users.filter(filterByUserQuery(query)) : [];

    const noResult =
      filteredUser && filteredUser.length <= 0 ? (
        <div className="test_Administration_User_UserNotFound">
          <Alert>
            <FormattedMessage id="module-user-notFound" />
          </Alert>
        </div>
      ) : null;

    return (
      <div>
        <UserFilters
          hideInvite={hideInvite}
          query={query}
          onSearchChange={this.onSearchChangeHandler.bind(this)}
        />
        {noResult}

        <div>
          {!noResult && (
            <UserList
              condensed={condensed}
              currentUser={currentUser}
              users={filteredUser}
              ActionTitle={ActionTitle}
              Action={Action}
              extraProps={extraProps}
              onChange={onChange}
              multiSelectable={multiSelectable}
              notSelectable={notSelectable}
              grantAccess={grantAccess}
              revokeAccess={revokeAccess}
              actionStyle={actionStyle}
            />
          )}
        </div>
      </div>
    );
  }
}

export default UserListWithFilters;
