"use strict";

exports.__esModule = true;
exports.useFormGeneratorWithReactHook = exports.useFormGenerator = void 0;
var _react = require("react");
var _reactHookForm = require("react-hook-form");
var _FormInputs = require("./FormField/FormInputs");
var _FormInputsValidation = require("./FormField/FormInputsValidation");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var mapFieldsWithInput = function mapFieldsWithInput(fields) {
  if (fields === void 0) {
    fields = [];
  }
  return fields.map(function (f) {
    var Input = f.Input || (0, _FormInputs.getInputFromField)(f.type);
    return _extends({}, f, {
      Input: Input,
      rules: (0, _FormInputsValidation.getValidation)(f)
    });
  });
};

// we create a HOOK to avoid having to re-create the input
// each and every time
var useFormGenerator = function useFormGenerator(fields) {
  if (fields === void 0) {
    fields = [];
  }
  var _useState = (0, _react.useState)([]),
    fieldsWithInput = _useState[0],
    setFieldsWithInput = _useState[1];
  (0, _react.useEffect)(function () {
    if (fields) {
      setFieldsWithInput(mapFieldsWithInput(fields));
    }
  }, [fields]);
  return fieldsWithInput;
};
exports.useFormGenerator = useFormGenerator;
var useFormGeneratorWithReactHook = function useFormGeneratorWithReactHook(fields, defaultValues, options) {
  if (fields === void 0) {
    fields = [];
  }
  if (defaultValues === void 0) {
    defaultValues = {};
  }
  if (options === void 0) {
    options = {};
  }
  var fieldsDecorated = useFormGenerator(fields);
  var _useForm = (0, _reactHookForm.useForm)(_extends({
      defaultValues: defaultValues,
      mode: 'all'
    }, options)),
    control = _useForm.control,
    handleSubmit = _useForm.handleSubmit,
    watch = _useForm.watch,
    errors = _useForm.errors,
    formState = _useForm.formState,
    setValue = _useForm.setValue,
    getValues = _useForm.getValues,
    reset = _useForm.reset,
    setError = _useForm.setError,
    clearErrors = _useForm.clearErrors;
  var touched = formState.touched,
    isDirty = formState.isDirty,
    isValid = formState.isValid;
  return {
    handleSubmit: handleSubmit,
    control: control,
    watch: watch,
    errors: errors,
    fields: fieldsDecorated,
    isDirty: isDirty,
    touched: touched,
    setValue: setValue,
    getValues: getValues,
    reset: reset,
    isValid: isValid,
    setError: setError,
    clearErrors: clearErrors
  };
};
exports.useFormGeneratorWithReactHook = useFormGeneratorWithReactHook;