import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import PropTypes from 'prop-types';
import LabelValue from '../../../core/components/composites/generic/LabelValue';
import EntityPropertySection from './EntityPropertySection';

const EntityChange = ({ change, propertyKey, displayName, component }) => {
  let newValueContent;
  let oldValueContent;

  if (change.NewValue) {
    const newContentValue = component(change.NewValue);
    newValueContent = (
      <LabelValue
        label={<FormattedMessage id="newValue" />}
        value={newContentValue}
      />
    );
  }

  if (change.OldValue) {
    const oldContentValue = component(change.OldValue);
    oldValueContent = (
      <LabelValue
        label={<FormattedMessage id="previousValue" />}
        value={oldContentValue}
      />
    );
  }

  if (oldValueContent || newValueContent) {
    return (
      <EntityPropertySection title={displayName}>
        {newValueContent}
        {oldValueContent}
      </EntityPropertySection>
    );
  }

  let contentForNoValue;

  if (change.Type.toLowerCase() === 'changed') {
    contentForNoValue = <FormattedMessage id="PropertyModified" />;
  }

  if (change.Type.toLowerCase() === 'added') {
    contentForNoValue = <FormattedMessage id="PropertyWasAdded" />;
  }

  return (
    <EntityPropertySection title={`${propertyKey} (raw)`}>
      {contentForNoValue}
    </EntityPropertySection>
  );
};

EntityChange.defaultProps = {
  propertyKey: '',
};

EntityChange.propTypes = {
  change: PropTypes.object.isRequired,
  propertyKey: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
};

export default EntityChange;
