"use strict";

exports.__esModule = true;
exports.PanelHeader = exports.PanelFooter = exports.PanelContent = exports.Panel = void 0;
var _Panel = require("./Panel");
exports.Panel = _Panel.Panel;
var _PanelContent = require("./PanelContent");
exports.PanelContent = _PanelContent.PanelContent;
var _PanelFooter = require("./PanelFooter");
exports.PanelFooter = _PanelFooter.PanelFooter;
var _PanelHeader = require("./PanelHeader");
exports.PanelHeader = _PanelHeader.PanelHeader;