import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import cogoToast from 'cogo-toast';
import { useIntl } from 'react-intl';

const CLONE_HIERARCHY = gql`
  mutation cloneHierarchy($hierarchy: InputCloneHierarchy!) {
    management {
      id
      cloneHierarchy(hierarchy: $hierarchy) {
        id
      }
    }
  }
`;

export const useCloneHierarchy = () => {
  const [mutate, { data, loading, error }] = useMutation(CLONE_HIERARCHY);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const clonedHierarchy = get(data, 'management.cloneHierarchy');

    if (!loading && clonedHierarchy && clonedHierarchy.id) {
      cogoToast.success(
        formatMessage({
          id: 'module-hierarchy-hierarchy-cloned',
        }),
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    }
  }, [loading, data]);

  const cloneHierarchy = (hierarchy) => {
    mutate({
      variables: { hierarchy },
    });
  };

  return [
    (hierarchy) => cloneHierarchy(hierarchy),
    {
      data: get(data, 'management.cloneHierarchy'),
      loading,
      error,
    },
  ];
};
