import { useTheme } from '@cluedin/theme';
import { TextEllipsis } from 'uxi/Text';

const ProviderOriginalLinkStyle = {
  link: {
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

const ProviderOriginalLink = ({ integrationWithUris }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const urisInternal = integrationWithUris.uris || [];

  let url;
  if (Array.isArray(urisInternal)) {
    url = urisInternal ? urisInternal[0] : null;
  } else {
    url = urisInternal;
  }

  let finalResult;
  if (url) {
    finalResult = (
      <a
        style={{
          ...ProviderOriginalLinkStyle.link,
          color: themePrimary,
        }}
        href={url.uri}
        rel="noopener noreferrer"
        target="_blank"
      >
        <TextEllipsis>View in {integrationWithUris.name}</TextEllipsis>
      </a>
    );
  } else if (integrationWithUris.config && integrationWithUris.config.url) {
    finalResult = (
      <a
        style={{
          ...ProviderOriginalLinkStyle.link,
          color: themePrimary,
        }}
        href={integrationWithUris.config.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <TextEllipsis>View in {integrationWithUris.name}</TextEllipsis>
      </a>
    );
  } else if (integrationWithUris.uri) {
    finalResult = (
      <a
        style={{
          ...ProviderOriginalLinkStyle.link,
          color: themePrimary,
        }}
        href={integrationWithUris.uri}
        rel="noopener noreferrer"
        target="_blank"
      >
        <TextEllipsis>View in {integrationWithUris.name}</TextEllipsis>
      </a>
    );
  }

  return <span>{finalResult}</span>;
};

export default ProviderOriginalLink;
