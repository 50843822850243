import { FC, useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import Alert from 'uxi/Alert';
import { useTheme } from '@cluedin/theme';
import { FormattedHTMLMessage } from '@cluedin/locale';

import { EdgePropertiesPanelEdgeGroupEntryWrapper } from './EdgePropertiesPanelEdgeGroupEntryWrapper';
import { EdgePropertiesPanelEdgeGroupEntryInnerWrapper } from './EdgePropertiesPanelEdgeGroupEntryInnerWrapper';
import { EdgePropertiesPanelEdgeEntryHeader } from './EdgePropertiesPanelEdgeEntryHeader';
import { EdgePropertiesPanelEdgeGroupEdgeWrapperNoData } from './EdgePropertiesPanelEdgeGroupEdgeWrapperNoData';

type EntityRelationEdgePropertiesWithoutDataProps = {
  edgeType: string;
};

export const EntityRelationEdgePropertiesWithoutData: FC<
  EntityRelationEdgePropertiesWithoutDataProps
> = ({ edgeType }) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <EdgePropertiesPanelEdgeGroupEntryWrapper>
      <EdgePropertiesPanelEdgeGroupEntryInnerWrapper
        isExpanded={isExpanded}
        theme={theme}
        data-test="entity-entityRelations-edgePropertiesPanelEdgeGroupEntry"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <EdgePropertiesPanelEdgeEntryHeader>
          <ExpandingArrow
            expanded={isExpanded}
            data-test="entity-entityRelations-expandingArrow"
          />
          {edgeType}
        </EdgePropertiesPanelEdgeEntryHeader>
      </EdgePropertiesPanelEdgeGroupEntryInnerWrapper>

      <EdgePropertiesPanelEdgeGroupEdgeWrapperNoData isExpanded={isExpanded}>
        <Alert type="information">
          <FormattedHTMLMessage
            id="module-entityRelationsGraph-edgePropertiesEntryNoData"
            values={{ edgeType }}
          />
        </Alert>
      </EdgePropertiesPanelEdgeGroupEdgeWrapperNoData>
    </EdgePropertiesPanelEdgeGroupEntryWrapper>
  );
};
