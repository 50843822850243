import React from 'react';
import { FormattedMessage } from '@cluedin/locale';

const PropertyValue = (p) => (entity) => {
  const value = entity.properties[`property-${p}`] || entity[p];
  return (
    <span>
      {value ? (
        <span>{value}</span>
      ) : (
        <span
          style={{
            fontStyle: 'italic',
            color: '#ccc',
          }}
        >
          <FormattedMessage id="module-entityLists-noValue" />
        </span>
      )}
    </span>
  );
};

export default PropertyValue;
