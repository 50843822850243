import styled from 'styled-components';

export const MainSearchBoxStyleOverload = styled.div`
  flex-grow: 999;
  input {
    width: 100%;
  }
  /* Target exclusively IE10 and above: */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    & > div {
      width: 100%;
    }
  }
`;

export const SelectInputEntityPoolStyleOverload = styled.div`
  position: relative;
  color: #000000 !important;
  background: #ffffff;
  /* small vertical separator line */
  &:after {
    content: '';
    display: block;
    border-right: 1px solid #cecece;
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: 0;
  }
  border-radius: 3px 0 0 3px;
  width: 180px;
  min-width: 180px;
  & *[data-drop-down-trigger] button * {
    fill: ${({ theme }) => theme.palette.themePrimary};
    background: #ffffff;
  }
  *[data-drop-down-content] {
    div > div:hover {
      background: ${({ theme }) => theme.palette.themeLighterAlt} !important;
      color: #000 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  *[data-drop-down-content] {
    div > div:focus {
      background: #fff;
      color: #000;
    }
  }

  /* overwrittes => */
  & *[data-drop-down-trigger] button {
    & * {
      color: #000000 !important;
    }
    border-radius: ${({ theme: { radius } }) =>
      radius
        ? `${radius} 0 0 ${radius}`
        : ''} !important; /* overwrite inline style */
    &:focus {
      box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.4),
        0 0 2px 2px rgba(255, 255, 255, 0.24) !important;
    }
  }

  /**
   * Styles to checked validity =>
   */

  &,
  &:hover,
  &:focus,
  &:hover:focus {
    border: none;
    height: 34px;
    * {
      border: none !important;
    }
  }
  /**
    * for IE (does not undertsand focus-within) */

  *[data-drop-down-trigger],
  *[data-drop-down-trigger],
  *[data-drop-down-main],
  *[data-drop-down-main] * {
    /* legacy dropdown */
    border-radius: 3px 0 0 3px !important; /* overwrite inline style */
    border-radius: ${({ theme: { radius } }) =>
      radius
        ? `${radius} 0 0 ${radius}`
        : ''} !important; /* overwrite inline style */
    border: none !important; /* drop down main border is inline style */
    font-size: 14px;
    font-weight: 100;
    div {
      height: 34px !important; /* overwrite inline style */
      /* overflow: hidden; */
      display: flex;
      align-items: center;

      & > span {
        & > * {
          width: 100%;
        }
        & > div:nth-child(2) {
          width: auto;
          height: 34px !important; /* overwrite inline style */
          top: 1px !important; /* overwrite inline style */
        }
      }
    }
  }
`;

export default {
  SelectInputEntityPoolStyleOverload,
};
