import { gql } from '@apollo/client';

export default gql`
  fragment Vocabulary on Vocabulary {
    vocabularyId
    vocabularyName
    keyPrefix
    isCluedInCore
    entityTypeConfiguration {
      icon
      entityType
      displayName
    }
    isDynamic
    isProvider
    isActive
    grouping
    createdAt
    providerId
    description
    connector {
      id
      name
      about
      icon
    }
  }
`;
