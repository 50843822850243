import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ButtonLink } from 'uxi/Button';
import { Checkmark } from 'uxi/Icons';
import { ClipboardIcon } from '@cluedin/svgs';
import { useTheme } from '@cluedin/theme';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';
import { copyToClipboard } from '../../../../../core/helpers/dom';
import {
  EntityActionMessageItemDetailsExpandedWrapper,
  EntityActionMessageItemDetailsExpandedInnerWrapper,
  EntityActionMessageItemDetailsExpandedHeading,
  EntityActionMessageItemDetailsExpandedHeadingWrapper,
  EntityActionMessageItemDetailsExpandedBorderCircle,
  EntityActionMessageItemDetailsExpandedDetails,
  EntityActionMessageItemDetailsExpandedSource,
  EntityActionMessageItemDetailsExpandedPre,
} from './EntityActionMessageItemDetailsExpanded.styles';

const SvgWrapper = styled.div`
  display: flex;
  & svg * {
    fill: ${({ themePrimary }) => themePrimary} !important;
    color: ${({ themePrimary }) => themePrimary} !important;
  }
`;

const EntityActionMessageItemDetailsExpanded = ({ isExpanded, rawQuery }) => {
  const theme = useTheme();
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const source = get(rawQuery, 'source');
  const query = get(rawQuery, 'query');

  const copyToClipboardAndSuccess = () => {
    setShowCopiedMessage(true);
    copyToClipboard(query);

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  return (
    <EntityActionMessageItemDetailsExpandedWrapper>
      <EntityActionMessageItemDetailsExpandedInnerWrapper>
        <EntityActionMessageItemDetailsExpandedHeading>
          <EntityActionMessageItemDetailsExpandedHeadingWrapper
            isExpanded={isExpanded}
          >
            <EntityActionMessageItemDetailsExpandedBorderCircle theme={theme} />

            <EntityActionMessageItemDetailsExpandedDetails>
              <EntityActionMessageItemDetailsExpandedSource>
                {source}:
              </EntityActionMessageItemDetailsExpandedSource>

              <EntityActionMessageItemDetailsExpandedPre>
                {query}
              </EntityActionMessageItemDetailsExpandedPre>

              <ButtonLink
                message={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {!showCopiedMessage && (
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        overlay={
                          <FormattedMessage id="module-pendingChanges-copySource" />
                        }
                      >
                        <SvgWrapper themePrimary={theme.palette.themePrimary}>
                          <ClipboardIcon />
                        </SvgWrapper>
                      </Tooltip>
                    )}
                    {showCopiedMessage && (
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        overlay={
                          <FormattedMessage id="module-pendingChanges-sourceCopied" />
                        }
                      >
                        <Checkmark color="#00897ae6" size={14} />
                      </Tooltip>
                    )}
                    <FormattedMessage id="module-pendingChanges-copySource" />
                  </span>
                }
                onClick={() => {
                  copyToClipboardAndSuccess();
                }}
                data-test={'entity_pendingChanges_copySourceLink'}
              />
            </EntityActionMessageItemDetailsExpandedDetails>
          </EntityActionMessageItemDetailsExpandedHeadingWrapper>
        </EntityActionMessageItemDetailsExpandedHeading>
      </EntityActionMessageItemDetailsExpandedInnerWrapper>
    </EntityActionMessageItemDetailsExpandedWrapper>
  );
};

export default EntityActionMessageItemDetailsExpanded;

EntityActionMessageItemDetailsExpanded.propTypes = {
  isExpanded: PropTypes.bool,
  rawQuery: PropTypes.object,
};

EntityActionMessageItemDetailsExpanded.defaultProps = {
  isExpanded: false,
  rawQuery: {},
};
