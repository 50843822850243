import React, { useState, useId } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Externallink, Arrowdown, Arrowright } from 'uxi/Icons';

import { useTheme } from '@cluedin/theme';
import { HistoryChangeSetItems, TimeFromNow } from '@cluedin/components';

import {
  HistoryChangeSetSourceBorderCircle,
  HistoryChangeSetSourceBox,
  HistoryChangeSetSourceDetails,
  HistoryChangeSetSourceHeading,
  HistoryChangeSetSourceHeadingWrapper,
  HistoryChangeSetSourceWrapper,
} from './HistoryChangeSetSource.styles';

const DisplayDates = ({
  modifiedDate,
  discoveryDate,
  createdDate,
  sortDate,
}) => {
  let sortedParameters = [];
  const tooltipId = useId();
  const modified = (
    <>
      <TooltipHost
        id={tooltipId}
        content={
          <FormattedMessage id="module-entityHistoryV2-modified-explanation" />
        }
      >
        <FormattedMessage id="module-entityHistoryV2-modified" />{' '}
      </TooltipHost>
      {modifiedDate === null ? (
        <span style={{ fontStyle: 'italic' }}>
          <FormattedMessage id="module-entityHistoryV2-no-date" />
        </span>
      ) : (
        <TimeFromNow date={modifiedDate} />
      )}
    </>
  );
  const discovered = (
    <>
      <TooltipHost
        id={tooltipId}
        content={
          <FormattedMessage id="module-entityHistoryV2-discovered-explanation" />
        }
      >
        <FormattedMessage id="module-entityHistoryV2-discovered" />{' '}
      </TooltipHost>
      <TimeFromNow date={discoveryDate} />
    </>
  );
  const created = (
    <>
      <TooltipHost
        id={tooltipId}
        content={
          <FormattedMessage id="module-entityHistoryV2-created-explanation" />
        }
      >
        <FormattedMessage id="module-entityHistoryV2-created" />{' '}
      </TooltipHost>
      {createdDate === null ? (
        <span style={{ fontStyle: 'italic' }}>
          <FormattedMessage id="module-entityHistoryV2-no-date" />
        </span>
      ) : (
        <TimeFromNow date={createdDate} />
      )}
    </>
  );
  const sorted = (
    <>
      <TooltipHost
        id={tooltipId}
        content={
          <FormattedMessage id="module-entityHistoryV2-sorted-explanation" />
        }
      >
        <FormattedMessage id="module-entityHistoryV2-sorted" />{' '}
      </TooltipHost>
      <TimeFromNow date={sortDate} />
    </>
  );

  sortedParameters.push(created);
  sortedParameters.push(modified);
  sortedParameters.push(discovered);
  sortedParameters.push(sorted);

  const selectedParameter = sortedParameters.shift();
  return (
    <div>
      <div>
        <span style={{ color: '#8b8b8b' }}>{created} | </span>
        <span style={{ color: '#8b8b8b' }}>{modified}</span>
      </div>
      <div>
        <span style={{ color: '#8b8b8b' }}>{discovered}</span>
      </div>
      <div>
        <span style={{ color: '#8b8b8b' }}>{sorted}</span>
      </div>
    </div>
  );
};

const HistoryChangeSetSource = ({
  entityId,
  currentSort,
  historyChangeSource = {},
  useQueryEntityHistoryDetails,
  labels = {},
  userAvatar: UserAvatarFromId,
  cleanRootUrl,
  dedupRootUrl,
  manualDataEntryRootUrl,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const { sortedDateFilter } = currentSort;
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    sourceName,
    changeType,
    changedAt,
    changedBy,
    partId,
    sourceDetails,
    modifiedDate,
    discoveryDate,
    createdDate,
    sortDate,
  } = historyChangeSource;

  const {
    by = 'by',
    cleanTitle = 'Clean',
    source = 'Source',
    sourceDeduplicationProjectTitle = 'Merge from Deduplication Project',
    sourceManualMergeTitle = 'Manual Merge',
    cluedInManualEntryTitle = 'Manually Added In CluedIn',
  } = labels;

  let title = sourceName;

  if (sourceName === 'CluedInClean') {
    title = cleanTitle;
  }
  if (
    sourceName === 'CluedInManualEntry' ||
    sourceDetails?.sourceTypeId === '01ad258e-7e1b-4654-8152-cbce5027604d'
  ) {
    title = cluedInManualEntryTitle;
  }
  if (
    sourceDetails?.sourceTypeId === '7dc6dd5b-6c14-43f4-aa3c-8e653a7363fc' &&
    sourceName === 'mergeEntities'
  ) {
    title = sourceDeduplicationProjectTitle;
  }
  if (
    sourceDetails?.sourceTypeId !== '7dc6dd5b-6c14-43f4-aa3c-8e653a7363fc' &&
    sourceName === 'mergeEntities'
  ) {
    title = sourceManualMergeTitle;
  }

  return (
    <HistoryChangeSetSourceBox>
      <HistoryChangeSetSourceWrapper>
        <HistoryChangeSetSourceHeading>
          <HistoryChangeSetSourceHeadingWrapper
            data-test="entity_entityHistory_historyChangeSetSource_historyChangeSetSourceButton"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <Arrowdown color={themePrimary} size={16} />
            ) : (
              <Arrowright color={themePrimary} size={16} />
            )}

            <HistoryChangeSetSourceBorderCircle theme={theme} />

            <HistoryChangeSetSourceDetails>
              <strong>{title}</strong>

              {sourceDetails?.sourceObjectId && (
                <div>
                  {sourceDetails.sourceTypeId ===
                    '40d690ac-cfc6-48c7-9b6c-4e036da9c6f1' && (
                    <>
                      {source}
                      {': '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`${cleanRootUrl}/${sourceDetails?.sourceObjectId}`}
                      >
                        <span
                          style={{
                            marginRight: '4px',
                            color: theme.palette.themePrimary,
                          }}
                        >
                          {sourceDetails?.displayName}
                        </span>
                        <Externallink size={10} />
                      </Link>
                    </>
                  )}

                  {sourceDetails.sourceTypeId ===
                    '7dc6dd5b-6c14-43f4-aa3c-8e653a7363fc' && (
                    <>
                      {source}
                      {': '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`${dedupRootUrl}/${sourceDetails?.sourceObjectId}`}
                      >
                        <span
                          style={{
                            marginRight: '4px',
                            color: theme.palette.themePrimary,
                          }}
                        >
                          {sourceDetails?.displayName}
                        </span>
                        <Externallink size={10} />
                      </Link>
                    </>
                  )}

                  {sourceDetails.sourceTypeId ===
                    '01ad258e-7e1b-4654-8152-cbce5027604d' && (
                    <>
                      {source}
                      {': '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`${manualDataEntryRootUrl}/${sourceDetails?.sourceObjectId}`}
                      >
                        <span
                          style={{
                            marginRight: '4px',
                            color: theme.palette.themePrimary,
                          }}
                        >
                          {sourceDetails?.displayName}
                        </span>
                        <Externallink size={10} />
                      </Link>
                    </>
                  )}
                </div>
              )}
              <DisplayDates
                sortedDateFilter={sortedDateFilter}
                modifiedDate={modifiedDate}
                discoveryDate={discoveryDate}
                createdDate={createdDate}
                sortDate={sortDate}
              />
            </HistoryChangeSetSourceDetails>
          </HistoryChangeSetSourceHeadingWrapper>
        </HistoryChangeSetSourceHeading>

        {isExpanded && (
          <HistoryChangeSetItems
            entityId={entityId}
            labels={labels}
            useQueryEntityHistoryChangeSetById={useQueryEntityHistoryDetails}
            partId={partId}
          />
        )}
      </HistoryChangeSetSourceWrapper>
    </HistoryChangeSetSourceBox>
  );
};

export default HistoryChangeSetSource;

HistoryChangeSetSource.propTypes = {
  entityId: PropTypes.string,
  historyChangeSource: PropTypes.object,
  useQueryEntityHistoryDetails: PropTypes.func,
  labels: PropTypes.object,
  userAvatar: PropTypes.node,
};
