import React from 'react';
import { TileDetail } from 'uxi/Tile';
import { TextEllipsis } from 'uxi/Text';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';
import ProvidersIconLinkList from '../../../../viewModels/IEntity/ProvidersIconLinkList';
import { Snippet } from './EntityTileSnippet';
import EntityLinkContainer from '../../../wms/components/containers/EntityLinkContainer';
import { withEntityQuickview } from '../../../entity/components/Hocs/withEntityQuickview';
import EntityIconToolTip from '../../../../viewModels/IEntity/EntityIconTooltip';
import EntityTileExtra from './EntityTileExtra';
import EntityTypeAvatar from '../../../entityTypes/components/containers/EntityTypeAvatar';

const SearchResultListItem = ({
  searchResult = {},
  showEntityQuickView,
  noBorderTop,
  entityId,
  properties,
}) => {
  const isSelected = searchResult.entityId === entityId;
  const bgTileColor = isSelected ? '#f2f2f2' : '#fff';

  return (
    <div className="test_searchResult_item">
      <TileDetail
        title={
          <EntityLinkContainer
            id={searchResult.entityId}
            entityType={searchResult.entityType}
          >
            <TextEllipsis data-test={`search-result-${searchResult.name}`}>
              {searchResult.name}
            </TextEllipsis>
          </EntityLinkContainer>
        }
        imageUrl={searchResult.logo.small}
        icon={
          <EntityIconToolTip
            isConfigured={searchResult.isEntityConfigured}
            item={searchResult}
          />
        }
        roundImage
        extra={
          <EntityTileExtra
            entity={searchResult}
            properties={properties}
            description={searchResult.description}
          />
        }
        style={{
          background: bgTileColor,
          borderLeft: 'none',
          borderRight: 'none',
          padding: '32px 16px 16px 16px',
          ...(noBorderTop ? { borderTop: 'none' } : {}),
        }}
      >
        <div>
          <div style={{ padding: '6px 0' }}>
            <EntityTypeAvatar
              color="#999999"
              entityType={searchResult.entityType}
            />
          </div>
          <Snippet>{searchResult.createdDate}</Snippet>
          <ProvidersIconLinkList
            roundImage
            style={{ margin: '8px 0 16px' }}
            providers={searchResult.providers}
          />
          <ButtonLink
            onClick={() => {
              showEntityQuickView({
                entityId: searchResult.entityId,
                entityType: searchResult.entityType,
              });
            }}
          >
            <span style={{ fontSize: '12px' }}>
              <FormattedMessage id="module-entityLists-viewALlPropsPart1" />
              <strong>&nbsp;{searchResult.providers.length}&nbsp;</strong>
              <FormattedMessage id="module-entityLists-viewALlPropsPart2" />
            </span>
          </ButtonLink>
        </div>
      </TileDetail>
    </div>
  );
};

SearchResultListItem.displayName = 'SearchResultListItem';

export default withEntityQuickview(SearchResultListItem);
