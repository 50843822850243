import styled from 'styled-components';

const EntityLabel = styled.div`
  font-size: 14px;
  color: #c3c3c3;
  flex: 1;
  padding-bottom: 12px;
  margin-top: 12px;
`;

export default EntityLabel;
