import ColumnCard from './ColumnCard';

const SortableColumnList = ({ columns = [], onMoveCard, onDeleteColumn }) => {
  return (
    <>
      {columns.map((c, i) => (
        <ColumnCard
          key={c.property}
          index={i}
          column={c}
          disabled={c.disabled}
          id={c.id}
          moveCard={onMoveCard}
          onDeleteColumn={() => {
            onDeleteColumn(c);
          }}
        />
      ))}
    </>
  );
};

export default SortableColumnList;
