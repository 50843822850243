import React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    background: '#fff',
    padding: '15px',
  },
  titleRow: { display: 'flex', borderBottom: '1px solid #eee' },
  bodyRow: { display: 'flex', marginTop: '20px' },
  title: {
    fontSize: '1rem',
    margin: '0 0 10px',
    fontWeight: 'bold',
  },
  desc: { flex: 1, padding: '0 20px 20px 0', fontSize: '0.85rem' },
};

const SettingRow = ({ title, description, children }) => {
  const descBox = description && <div style={styles.desc}>{description}</div>;
  return (
    <div style={styles.container}>
      <div style={styles.titleRow}>
        <h4 style={styles.title}>{title}</h4>
      </div>
      <div style={styles.bodyRow}>
        {descBox}
        <div style={{ flex: 2 }}>{children}</div>
      </div>
    </div>
  );
};

export default SettingRow;
