import { Link } from 'react-router-dom';

const EntityLinkFromEntity = ({ entityUrl, children, ...rest }) => {
  return (
    <Link {...rest} to={entityUrl}>
      {children}
    </Link>
  );
};

export default EntityLinkFromEntity;
