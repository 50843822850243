import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Flex } from 'uxi/Layout';
import { PrimaryButton } from '@cluedin/form';
import Alert from 'uxi/Alert';
import ConfigurationFiltersGhost from '../filters/ConfigurationFiltersGhost';

const ConfigurationListWithFiltersErrored = () => (
  <div>
    <ConfigurationFiltersGhost />
    <Alert type="error">
      <FormattedMessage id="module-configuration-configurationFetcherror" />
    </Alert>
    <br />
    <Flex>
      <PrimaryButton
        onClick={() => window && window.location.reload()}
        text={
          <FormattedMessage
            id="module-configuration-retryFetching"
            defaultMessag="Retry"
          />
        }
      />
    </Flex>
  </div>
);

export default ConfigurationListWithFiltersErrored;
