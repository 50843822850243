import React from 'react';
import { Select } from '@cluedin/form';
import PropTypes from 'prop-types';
import IntegrationNameWithLogoFromIntegration from './IntegrationNameWithLogoFromIntegration';
import DefaultAllIntegration from './DefaultAllIntegration';
import { injectIntl } from '@cluedin/locale';

const IntegrationDropdown = ({
  value = undefined,
  onChange,
  integrations = [],
  defaultOption,
  intl,
}) => {
  const defaultLabel = {
    value: null,
    label: !!defaultOption
      ? defaultOption
      : intl.formatMessage({ id: 'module-integration-allIntegrations' }),
    icon: <DefaultAllIntegration />,
  };
  const options = integrations.map((integration) => ({
    value: integration,
    key: integration.Id,
    label: integration.Name,
    icon: <IntegrationNameWithLogoFromIntegration integration={integration} />,
  }));

  return (
    <div style={{ width: '250px' }}>
      <Select
        onChange={({ value }) => onChange(value)}
        value={
          value?.Name
            ? {
                label: value?.Name,
                icon: (
                  <IntegrationNameWithLogoFromIntegration integration={value} />
                ),
              }
            : defaultLabel
        }
        options={[defaultLabel, ...options]}
      />
    </div>
  );
};
IntegrationDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  integrations: PropTypes.array,
};
IntegrationDropdown.defaultProps = {
  integrations: [],
};

export default injectIntl(IntegrationDropdown);
