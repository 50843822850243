import styled from 'styled-components';
import { flexCSSString } from 'uxi/Layout';

const FlexExtended = styled.div`
  ${flexCSSString};
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
`;

export default FlexExtended;
