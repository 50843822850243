"use strict";

exports.__esModule = true;
exports.hasAnyClaim = void 0;
var _lodash = require("lodash");
var hasAnyClaim = function hasAnyClaim(userClaims, requiredClaims) {
  if (Object.keys(requiredClaims).length === 0) {
    return false;
  }
  return (0, _lodash.map)(userClaims, function (uc) {
    var requiredClaimLevels = requiredClaims[uc.name];
    if (!requiredClaimLevels) {
      return false;
    }
    return (0, _lodash.includes)(requiredClaimLevels, uc.level);
  }).filter(function (i) {
    return !!i;
  }).length === Object.keys(requiredClaims).length;
};
exports.hasAnyClaim = hasAnyClaim;