import React, { useEffect } from 'react';

import { withDeduplicationProjectMergePanelWorkflow } from '../../../hocs/withDeduplicationProjectMergePanelWorkflow';

const DeduplicationProjectMergeContainer = ({
  entityId,
  nodeDataType,
  setNodeDataType,
  currentNode,
  handleExecuteAction,
  deduplicationProjectMergePanelData,
  deduplicationProjectMergePanelLoading,
  deduplicationProjectMergePanelError,
  openDeduplicationProjectMergePanelWorkflow,
}) => {
  useEffect(() => {
    if (nodeDataType === 'DeduplicationProjectMerge') {
      openDeduplicationProjectMergePanelWorkflow();
    }
  }, [nodeDataType]);

  return <span />;
};

export default withDeduplicationProjectMergePanelWorkflow(
  DeduplicationProjectMergeContainer,
);
