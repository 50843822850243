import React from 'react';
import { GqlErrorMessages, Spinner } from '@cluedin/form';
import { getScaledValueWithMetricNotation } from '../../../../core/helpers/numbers';
import { useVocabularyKpi } from '../../../hooks/useVocabularyKpi';

const TotalVocabulary = () => {
  const [data, loading, error] = useVocabularyKpi();

  const value = getScaledValueWithMetricNotation((data || {}).total || 0);

  return (
    <span>
      {error && <GqlErrorMessages />}
      {!loading && value}
      {loading && <Spinner size={40} />}
    </span>
  );
};

export default TotalVocabulary;
