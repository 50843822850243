import { handleActions } from 'redux-actions';
import {
  selectFeature,
  clearSelectFeature,
  sendRequestActions,
  shouldShowPersonalIdentifiers,
  showConsentDialog,
  showCurrentUserRequest,
} from './action';

const currentUserRequests = [
  {
    type: 'rightToAccess',
    created: Date.now(),
  },
  {
    type: 'rightToErasure',
    created: Date.now(),
  },
];

const consent = {
  id: 1,
  title: 'Do I give consent to CluedIn?',
  shortExplanation:
    'We use the following data processors in order to improve our website and understand what is your interests about our products.',
  uniqueIdentifiers: [
    {
      key: 'Email',
      description: 'Bla bla',
      isRequired: true,
    },
    {
      key: 'IP Address',
      description: 'Bla bla',
      isRequired: false,
    },
  ],
  processors: [
    {
      name: 'GoogleAnalytics',
      consent: false,
      isRequired: false,
    },
    {
      name: 'Hubspot',
      consent: false,
      isRequired: true,
    },
    {
      name: 'MixPanel',
      consent: false,
      isRequired: false,
    },
  ],
  privacyLink: 'https://www.cluedin.com',
};

const intialState = {
  processorsConfig: [],
  dpoEmail: 'dpo@cluedin.com',
  dpoPhone: '+42 848 787 878',
  currentUserRequests,
  consents: [consent],
  selectedFeature: null,
  isSendingRequest: false,
  invalidRequest: false,
  isSendingRequestDone: false,
  showPersonalIdentifiers: false,
  showConsentDialog: false,
  showCurrentUserRequest: false,
  cookiePolicyUrl: 'https://www.cluedin.com/privacy',
  currentUser: {
    isAuthenticated: false,
    consentReport: {
      identifiers: [
        {
          key: 'email',
          consent: true,
        },
        {
          key: 'IPAddress',
          consent: true,
        },
      ],
      processors: [
        {
          id: 'GoogleAnalytics',
          consent: true,
        },
        {
          id: 'Hubspot',
          consent: true,
        },
        {
          id: 'Cluedin',
          consent: true,
        },
      ],
    },
    personalIdentifiers: [
      {
        key: 'Email',
        value: 'eoefokeofk@eogeogekgo.com',
      },
      {
        key: 'IP Address',
        value: '154.487.487.898',
      },
    ],
  },
};

export default handleActions(
  {
    [selectFeature]: (state, { payload }) => ({
      ...state,
      selectedFeature: payload,
      showPersonalIdentifiers: false,
    }),
    [clearSelectFeature]: (state) => ({
      ...state,
      selectedFeature: null,
      isSendingRequest: false,
      invalidRequest: false,
      isSendingRequestDone: false,
      showPersonalIdentifiers: false,
      showConsentDialog: false,
      showCurrentUserRequest: false,
    }),
    [sendRequestActions.request]: (state) => ({
      ...state,
      isSendingRequest: true,
      invalidRequest: false,
      isSendingRequestDone: false,
    }),
    [sendRequestActions.receive]: (state) => ({
      ...state,
      isSendingRequest: false,
      invalidRequest: false,
      isSendingRequestDone: true,
    }),
    [sendRequestActions.invalid]: (state) => ({
      ...state,
      isSendingRequest: false,
      invalidRequest: true,
      isSendingRequestDone: false,
    }),
    [shouldShowPersonalIdentifiers]: (state) => ({
      ...state,
      showPersonalIdentifiers: true,
    }),
    [showConsentDialog]: (state) => ({
      ...state,
      showConsentDialog: true,
    }),
    [showCurrentUserRequest]: (state) => ({
      ...state,
      showCurrentUserRequest: true,
    }),
  },
  intialState,
);
