const getValidationRules = ({ operators }) => [
  {
    key: 'objectTypeId',
    validationConditions: [{ name: 'required' }],
  },
  {
    key: 'field',
    dependsOn: 'objectTypeId',
    validationConditions: [{ name: 'required' }],
  },
  {
    key: 'operator',
    dependsOn: 'field',
    validationConditions: [{ name: 'required' }],
  },
  {
    getSpecialCondition: (dependsOnValue) => {
      const foundOperator = operators.filter(
        (i) => i.id === dependsOnValue,
      )?.[0];

      if (!foundOperator?.multiple) {
        return [{ name: 'length', value: foundOperator?.nb_inputs }];
      }

      return [{ name: 'multiple' }];
    },
    dependsOn: 'operator',
    key: 'value',
    validationConditions: [{ name: 'length', value: 1 }],
  },
];

const getErrorTypes = ({ intl }) => ({
  objectTypeId: [
    {
      key: 'required',
      message: intl.formatMessage({
        id: 'module-rule-validation-property-required',
      }),
    },
  ],
  field: [
    {
      key: 'required',
      message: intl.formatMessage({
        id: 'module-rule-validation-field-required',
      }),
    },
  ],
  operator: [
    {
      key: 'required',
      message: intl.formatMessage({
        id: 'module-rule-validation-operator-required',
      }),
    },
  ],
  value: [
    {
      key: 'length',
      message: intl.formatMessage({
        id: 'module-rule-validation-value-required',
      }),
    },
    {
      key: 'multiple',
      message: intl.formatMessage({
        id: 'module-rule-validation-multiple-value-required',
      }),
    },
  ],
});

export const getBaseValidationConfig = ({ operators, intl }) => {
  return {
    errorTypes: getErrorTypes({ intl }),
    validationRules: getValidationRules({ operators }),
  };
};
