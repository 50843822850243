import styled from 'styled-components';

const FieldWrapper = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  &:hover {
    background: #f2f2f2;
  }
`;

export default FieldWrapper;
