export default {
  entityV3: {
    REQUEST_ENTITIES_V3: 'REQUEST_ENTITIES_V3',
    RECEIVE_ENTITIES_V3: 'RECEIVE_ENTITIES_V3',
    REQUEST_SUGGESTED_SEARCH_V3: 'REQUEST_SUGGESTED_SEARCH_V3',
    RECEIVE_SUGGESTED_SEARCH_V3: 'RECEIVE_SUGGESTED_SEARCH_V3',
    INVALID_SUGGESTED_SEARCH_V3: 'INVALID_SUGGESTED_SEARCH_V3',
    REQUEST_ACTIVITIES_V3: 'REQUEST_ACTIVITIES_V3',
    RECEIVE_ACTIVITIES_V3: 'RECEIVE_ACTIVITIES_V3',
    INVALID_ACTIVITIES_V3: 'INVALID_ACTIVITIES_V3',
  },
  core: {
    GOTOLOCATION: 'GOTOLOCATION',
    REMOVE_WIDGET: 'REMOVE_WIDGET',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    RECEIVE_LAST_VISITED_CHANGE: 'RECEIVE_LAST_VISITED_CHANGE',
    REQUEST_REALTIME_NOTIFICATION: 'REQUEST_REALTIME_NOTIFICATION',
    RESET_REALTIME_NOTIFICATION: 'RESET_REALTIME_NOTIFICATION',
  },
  entity: {
    CLEAN_UP_SELECTED_ENTITY: 'CLEAN_UP_SELECTED_ENTITY',
    REQUEST_ENTITY: 'REQUEST_ENTITY',
    RECEIVE_ENTITY: 'RECEIVE_ENTITY',
    INVALID_ENTITY: 'INVALID_ENTITY',
    REQUEST_ENTITY_SCHEMA: 'REQUEST_ENTITY_SCHEMA',
    RECEIVE_ENTITY_SCHEMA: 'RECEIVE_ENTITY_SCHEMA',
    REQUEST_NEW_ENTITY_UPDATE: 'REQUEST_NEW_ENTITY_UPDATE',
    RECEIVE_NEW_ENTITY_UPDATE: 'RECEIVE_NEW_ENTITY_UPDATE',
    RESET_NEW_ENTITY_UPDATE: 'RESET_NEW_ENTITY_UPDATE',
    INVALID_UPDATE_ENTITY: 'INVALID_UPDATE_ENTITY',
    RECEIVE_UPDATE_ENTITY: 'RECEIVE_UPDATE_ENTITY',
  },
  generic: {
    UNAUTHORIZED_REQUEST: 'GENERIC_UNAUTHORIZED_REQUEST',
    ACCESS_DENIED: 'GENERIC_ACCESS_DENIED',
    GLOBAL_ERROR: 'GENERIC_GLOBAL_ERROR',
  },
};
