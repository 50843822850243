import { FormattedMessage } from '@cluedin/locale';

import { registerEntityWidget } from '../../registry';
import EntitySources from './EntitySources';

registerEntityWidget('EntitySources', {
  name: 'Entity Sources',
  displayName: (
    <FormattedMessage id="module-entityWidget-personProfileSources" />
  ),
  description: "Display an Entity's sources",
  view: EntitySources,
  noHeader: true,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
