import * as d3 from 'd3';

import { RelationIcon } from './svgs';
import { enableDraggingOfNodes } from './enableDraggingOfNodes';
import { highlightNodesAndLinksOnClick } from './highlightNodesAndLinksOnClick';
import { getIcon } from './getIcon';
import { truncateString } from './truncateString';
import { checkForMultipleLinks } from './checkForMultipleLinks';
import { positionNodesAndLinks } from './positionNodesAndLinks';
import { UpdatedGraphNetworkEdge, UpdatedGraphNetworkNode } from '../types';
import { addTokenToImage } from '../../../entity/helper';
import { getFullToken } from '../../../core/helpers/storage';

type DrawD3ForceGraphProps = {
  nodes: UpdatedGraphNetworkNode[];
  edges: UpdatedGraphNetworkEdge[];
  svgDOMNode: SVGSVGElement | null;
  offsetWidth: number;
  offsetHeight: number;
  rootEntityId: string;
  labels: {
    groupedRelationsCountLabel: string;
    dateCreatedLabel: string;
    noNameLabel: string;
  };
  handlers: {
    onNodeClick: (node: UpdatedGraphNetworkNode) => void;
    onGroupedRelationsClick: (groupedNode: UpdatedGraphNetworkNode) => void;
    onClickExpandGroupRelationsNode: () => void;
    onClickExpandEntityNode: () => void;
    onEdgeClick: (edge: UpdatedGraphNetworkEdge) => void;
  };
};

export const drawD3ForceGraph = ({
  nodes,
  edges,
  svgDOMNode,
  offsetWidth,
  offsetHeight,
  rootEntityId,
  labels: { groupedRelationsCountLabel, dateCreatedLabel, noNameLabel },
  handlers: {
    onNodeClick,
    onGroupedRelationsClick,
    onClickExpandGroupRelationsNode,
    onClickExpandEntityNode,
    onEdgeClick,
  },
}: DrawD3ForceGraphProps) => {
  const svg = d3.select(svgDOMNode);

  const nodeRadius = 200;
  const linkLabelRadius = 40;

  const simulation = d3
    .forceSimulation<UpdatedGraphNetworkNode, UpdatedGraphNetworkEdge>(nodes)
    .force(
      'link',
      d3
        .forceLink<UpdatedGraphNetworkNode, UpdatedGraphNetworkEdge>(edges)
        .id((edge) => {
          return edge?.index;
        })
        .distance(200),
    )
    .force('charge', d3.forceManyBody().strength(-1000))
    .force('center', d3.forceCenter(offsetWidth * 0.5, offsetHeight * 0.5))
    .force(
      'collision',
      d3.forceCollide(() => nodeRadius + linkLabelRadius).strength(0.8),
    )
    .alphaTarget(0)
    .alphaMin(0.009)
    .velocityDecay(0.9);

  const nodeSvgGroup = svg
    .selectAll<SVGGElement, UpdatedGraphNetworkNode>('.node')
    .data(nodes)
    .enter()
    .append<SVGGElement>('g')
    .attr('class', 'node')
    .call(enableDraggingOfNodes(simulation));

  nodeSvgGroup
    .append('rect')
    .attr('class', (thisNode) => {
      const nodeKind = thisNode?.nodeKind?.toLowerCase();
      const isShadowEntity = thisNode?.isShadowEntity;

      if (nodeKind === 'entity') {
        return 'node__body-entity';
      } else if (isShadowEntity) {
        return 'node__body-shadowEntity';
      } else {
        return 'node__body-relatedEntityNode';
      }
    })
    .attr('width', 240)
    .attr('height', 80)
    .on('click', function (_, node) {
      if (node?.nodeKind === 'entity' || node?.nodeKind === 'relatedEntity') {
        onNodeClick(node);
      }
      if (node?.nodeKind === 'groupedRelations') {
        onGroupedRelationsClick(node);
      }

      const clickedNode = this as SVGGElement;
      highlightNodesAndLinksOnClick(clickedNode, edges, node, svg);
    })
    .on('mouseover', function () {
      const thisClass = d3.select(this).attr('class');

      d3.select(this)
        .style(
          'stroke',
          thisClass === 'node__body-entity' ? '#52b4ad' : '#d7d7d8',
        )
        .style('stroke-width', '4px');

      const clickedNode = d3.select(this).datum();

      svg
        .selectAll<SVGSVGElement, UpdatedGraphNetworkEdge>('.link__body')
        .style('stroke', function (thisLink) {
          const isSourceNode = thisLink?.source === clickedNode;
          const isTargetNode = thisLink?.target === clickedNode;

          if (isSourceNode || isTargetNode) {
            return '#52b4ad';
          }
          return null;
        })
        .filter(function (thisLink) {
          const isSourceNode = thisLink?.source === clickedNode;
          const isTargetNode = thisLink?.target === clickedNode;

          return isSourceNode || isTargetNode;
        })
        .selectAll('.link__arrow path')
        .style('stroke', '#52b4ad');
    })
    .on('mouseout', function () {
      const thisClass = d3.select(this).attr('class');

      d3.select(this)
        .style(
          'stroke',
          thisClass === 'node__body-entity' ? '#a8d9d6' : '#d7d7d8',
        )
        .style('stroke-width', '2px');

      const originalStrokeColor = d3.select(this).attr('data-original-stroke');

      svg.selectAll('.link__body').style('stroke', function () {
        return originalStrokeColor;
      });

      svg
        .selectAll('.link__body')
        .select('.link__arrow path')
        .style('stroke', function () {
          return originalStrokeColor;
        });

      d3.select(this).attr('data-original-stroke', null);
    });

  nodeSvgGroup
    .append('rect')
    .attr('class', (thisNode) => {
      const nodeKind = thisNode?.nodeKind?.toLowerCase();
      const isShadowEntity = thisNode?.isShadowEntity;

      if (nodeKind === 'entity') {
        return 'node__border-left';
      } else if (isShadowEntity) {
        return 'node__border-left-shadowEntity';
      } else {
        return 'node__border-left-relatedEntityNode';
      }
    })
    .attr('width', 8)
    .attr('height', 82);

  nodeSvgGroup
    .selectAll('.node__border-left')
    .on('mouseover', function () {
      const parentNode = (this as Element).parentNode as SVGGElement;
      const node = d3.select(parentNode);
      const nodeBody = node.select('.node__body-entity');

      nodeBody.attr('data-original-stroke', nodeBody.style('stroke'));
      nodeBody.attr(
        'data-original-strokeWidth',
        nodeBody.style('stroke-width'),
      );
      nodeBody.style('stroke', '#52b4ad').style('stroke-width', '4px');
    })
    .on('mouseout', function () {
      const parentNode = (this as Element).parentNode as SVGGElement;
      const node = d3.select(parentNode);
      const nodeBody = node.select('.node__body-entity');

      nodeBody.style('stroke', nodeBody.attr('data-original-stroke'));
      nodeBody.style(
        'stroke-width',
        nodeBody.attr('data-original-strokeWidth'),
      );
    });

  nodeSvgGroup
    .selectAll('.node__border-left-relatedEntityNode')
    .on('mouseover', function () {
      const parentNode = (this as Element).parentNode as SVGGElement;
      const node = d3.select(parentNode);
      const nodeBody = node.select('.node__body-relatedEntityNode');

      nodeBody.attr('data-original-stroke', nodeBody.style('stroke'));
      nodeBody.attr(
        'data-original-strokeWidth',
        nodeBody.style('stroke-width'),
      );
      nodeBody.style('stroke', '#d7d7d8').style('stroke-width', '4px');
    })
    .on('mouseout', function () {
      const parentNode = (this as Element).parentNode as SVGGElement;
      const node = d3.select(parentNode);
      const nodeBody = node.select('.node__body-relatedEntityNode');

      nodeBody.style('stroke', nodeBody.attr('data-original-stroke'));
      nodeBody.style(
        'stroke-width',
        nodeBody.attr('data-original-strokeWidth'),
      );
    });

  nodeSvgGroup
    .selectAll('.node__border-left-shadowEntity')
    .on('mouseover', function () {
      const parentNode = (this as Element).parentNode as SVGGElement;
      const node = d3.select(parentNode);
      const nodeBody = node.select('.node__body-shadowEntity');

      nodeBody.attr('data-original-stroke', nodeBody.style('stroke'));
      nodeBody.attr(
        'data-original-strokeWidth',
        nodeBody.style('stroke-width'),
      );
      nodeBody.style('stroke', '#d7d7d8').style('stroke-width', '4px');
    })
    .on('mouseout', function () {
      const parentNode = (this as Element).parentNode as SVGGElement;
      const node = d3.select(parentNode);
      const nodeBody = node.select('.node__body-shadowEntity');

      nodeBody.style('stroke', nodeBody.attr('data-original-stroke'));
      nodeBody.style(
        'stroke-width',
        nodeBody.attr('data-original-strokeWidth'),
      );
    });

  nodeSvgGroup
    .append('circle')
    .attr('class', 'node__icon')
    .attr('class', (thisNode) => {
      const nodeKind = thisNode?.nodeKind?.toLowerCase();
      const isShadowEntity = thisNode?.isShadowEntity;

      if (nodeKind === 'entity') {
        return 'node__icon-entity';
      } else if (isShadowEntity) {
        return 'node__icon-shadowEntity';
      } else if (nodeKind === 'groupedrelations') {
        return 'node__icon-groupedRelations';
      } else if (nodeKind === 'relatedentity') {
        return 'node__icon-relatedEntity';
      } else {
        return 'node__icon';
      }
    })
    .attr('r', 20)
    .attr('cx', 50)
    .attr('cy', 50);

  nodeSvgGroup
    .append('image')
    .attr('class', (thisNode) => {
      const nodeKind = thisNode?.nodeKind?.toLowerCase();
      const nodePreviewImage = thisNode?.previewImage?.uri;

      if (nodePreviewImage) {
        return 'node__icon-previewImage';
      } else if (nodeKind === 'entity') {
        return 'node__icon-entity-svg';
      } else if (nodeKind === 'relatedentity') {
        return 'node__icon-relatedEntity-svg';
      } else if (nodeKind === 'groupedrelations') {
        return 'node__icon-groupedRelations-svg';
      } else {
        return 'node__icon-svg';
      }
    })
    .attr('xlink:href', (thisNode) => {
      const nodePreviewImage = thisNode?.previewImage?.uri;
      const nodeKind = thisNode?.nodeKind?.toLowerCase();
      const icon: HTMLImageElement | null = getIcon(thisNode?.icon);

      if (nodePreviewImage) {
        return addTokenToImage(nodePreviewImage, getFullToken()?.token);
      } else {
        if (nodeKind === 'groupedrelations') {
          return RelationIcon;
        } else {
          if (icon instanceof HTMLImageElement) {
            const imgSrc = icon?.src;
            return imgSrc;
          } else {
            const imgSrcRegex = /<img src="([^"]+)">/;
            const match = (icon as unknown as HTMLElement)?.outerHTML.match(
              imgSrcRegex,
            );

            if (match && match[1]) {
              return match[1];
            } else {
              return null;
            }
          }
        }
      }
    })
    .attr('clip-path', (thisNode) => {
      const nodePreviewImage = thisNode?.previewImage?.uri;
      const nodeKind = thisNode?.nodeKind?.toLowerCase();

      if (
        nodePreviewImage ||
        (nodeKind !== 'entity' &&
          nodeKind !== 'relatedentity' &&
          nodeKind !== 'groupedrelations')
      ) {
        return 'circle(50%)';
      } else {
        return null;
      }
    })
    .attr('preserveAspectRatio', 'xMidYMid slice');

  // TODO: Amend node expansion
  // Expansion disabled for V1
  // nodeSvgGroup
  //   .append('image')
  //   .attr('class', 'node__icon-expandNodeArrow')
  //   .attr('xlink:href', function (node: any) {
  //     const nodeId = node?.id;

  //     if (nodeId === rootEntityId) {
  //       return null;
  //     }
  //     return ArrowdownIcon;
  //   })
  //   .on('click', async function (_, node: any) {
  //     const nodeKind = node?.nodeKind?.toLowerCase();
  //     const nodeId = node?.id;

  //     if (nodeKind === 'groupedrelations') {
  //       onClickExpandGroupRelationsNode(node?.groupedEntityIds);
  //     } else {
  //       onClickExpandEntityNode(nodeId);
  //     }
  //   });

  nodeSvgGroup
    .append('text')
    .attr('class', (thisNode) => {
      return thisNode?.index === 0
        ? 'node__title'
        : 'node__title-relatedEntityNode';
    })
    .attr('x', 90)
    .attr('y', 50)
    .text((node: UpdatedGraphNetworkNode) => {
      const nodeKind = node?.nodeKind?.toLowerCase();

      if (nodeKind === 'entity' || nodeKind === 'relatedentity') {
        return truncateString(node?.displayName, 20);
      } else if (nodeKind === 'groupedrelations') {
        return truncateString(
          `${node?.groupedEntityIds?.length?.toString()} ${groupedRelationsCountLabel}`,
          20,
        );
      } else {
        return truncateString(node?.name, 20);
      }
    });

  nodeSvgGroup
    .append('text')
    .attr('class', 'node__subtitle')
    .attr('x', 90)
    .attr('y', 50)
    .text((node: UpdatedGraphNetworkNode) => {
      const nodeKind = node?.nodeKind?.toLowerCase();
      const nodeIncludesDate = node?.entityType?.includes('/Date');

      if (nodeKind === 'entity') {
        return truncateString(node?.name, 20);
      } else if (nodeKind === 'groupedrelations') {
        return truncateString(node?.name, 20);
      } else if (nodeIncludesDate) {
        return ` ${dateCreatedLabel}: ${truncateString(node?.name, 20)}`;
      } else {
        return truncateString(node?.name ?? noNameLabel, 20);
      }
    });

  const linksGroup = svg.insert('g', '.node');
  const allLinkSvgElements = linksGroup
    .selectAll<SVGGElement, UpdatedGraphNetworkEdge>('.link')
    .data(edges, (d) => d?.id)
    .enter()
    .append('g')
    .attr('class', 'link');

  allLinkSvgElements
    .append('path')
    .attr('id', (thisLink) => `link-path-${thisLink?.id}`)
    .attr('cursor', 'pointer')
    .attr('class', 'link__body')
    .style('fill', 'none')
    .attr('marker-end', (thisLink) => `url(#arrowhead-${thisLink?.id})`)
    .attr('d', (thisLink: UpdatedGraphNetworkEdge) => {
      const { source, target } = thisLink;
      const curve = thisLink?.curve || { x: 0, y: 0 };

      const qx = source?.x + curve?.x;
      const qy = source?.y + curve?.y;

      return `M ${source.x},${source.y} Q ${qx},${qy} ${target.x},${target.y}`;
    })
    .style('fill', 'none')
    .on('mouseover', function () {
      d3.select(this).style('stroke', '#52b4ad').style('stroke-width', '2px');

      const parentNode = this.parentNode as Element;
      d3.select(parentNode)
        .select('.link__arrow path')
        .style('stroke', '#52b4ad');
    })
    .on('mouseout', function () {
      d3.select(this).style('stroke', '#cacacc').style('stroke-width', '2px');

      const parentNode = this.parentNode as Element;
      d3.select(parentNode)
        .select('.link__arrow path')
        .style('stroke', '#cacacc');
    })
    .on('click', (_, edge) => {
      onEdgeClick(edge);
    });

  allLinkSvgElements
    .insert('path', ':first-child')
    .attr('class', 'link__body-hidden')
    .style('stroke', '#52b4ad')
    .style('stroke-width', '60px')
    .style('stroke-opacity', 0)
    .on('mouseover', function () {
      const parentNode = this.parentNode as Element;

      d3.select(parentNode)
        .select('.link__arrow path')
        .style('stroke', '#52b4ad');

      d3.select(parentNode).select('.link__body').style('stroke', '#52b4ad');
    })
    .on('mouseout', function () {
      const parentNode = this.parentNode as Element;

      d3.select(parentNode)
        .select('.link__arrow path')
        .style('stroke', '#cacacc');

      d3.select(parentNode).select('.link__body').style('stroke', '#cacacc');
    })
    .on('click', (_, edge) => {
      onEdgeClick(edge);
    });

  allLinkSvgElements
    .append('text')
    .attr('class', 'link__label')
    .attr('text-anchor', 'middle')
    .attr('dy', '5')
    .append('textPath')
    .attr('xlink:href', (thisLink) => `#link-path-${thisLink?.id}`)
    .attr('startOffset', (thisLink) => {
      const multipleLinks = checkForMultipleLinks(
        thisLink?.source,
        thisLink?.target,
        edges,
      );

      if (multipleLinks) {
        return '35%';
      } else {
        return '50%';
      }
    })
    .text((thisLink) => {
      return thisLink?.label;
    })
    .on('mouseover', function () {
      const parentNode = this.parentNode as Element;
      const grandparentNode = parentNode.parentNode as Element;

      d3.select(grandparentNode)
        .select('.link__arrow path')
        .style('stroke', '#52b4ad');

      d3.select(grandparentNode)
        .select('.link__body')
        .style('stroke', '#52b4ad');

      d3.select(this).transition().style('font-size', '20px');
    })
    .on('mouseout', function () {
      const parentNode = this.parentNode as Element;
      const grandparentNode = parentNode.parentNode as Element;

      d3.select(grandparentNode)
        .select('.link__arrow path')
        .style('stroke', '#cacacc');

      d3.select(grandparentNode)
        .select('.link__body')
        .style('stroke', '#cacacc');

      d3.select(this).transition().style('font-size', '18px');
    })
    .on('click', (_, edge) => {
      onEdgeClick(edge);
    });

  const marker = allLinkSvgElements
    .append('defs')
    .append('marker')
    .attr('id', (thisMarker) => 'arrowhead-' + thisMarker?.id)
    .attr('class', 'link__arrow')
    .attr('viewBox', '2 -5 10 10')
    .attr('refX', 38)
    .attr('refY', 0)
    .attr('orient', 'auto')
    .attr('markerWidth', 10)
    .attr('markerHeight', 10)
    .attr('xoverflow', 'visible');

  marker.append('svg:path').attr('d', 'M 0, -5 L 10, 0 L 0, 5');

  allLinkSvgElements.select('defs').attr('class', 'link__body');

  const zoom: d3.ZoomBehavior<SVGSVGElement, unknown> = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.1, 2])
    .on('zoom', (event) => {
      linksGroup.attr('transform', event.transform);
      nodeSvgGroup.attr('transform', event.transform);
    });

  (svg as d3.Selection<SVGSVGElement, unknown, null, undefined>)
    .call(zoom as d3.ZoomBehavior<SVGSVGElement, unknown>)
    .on('dblclick.zoom', null);

  const initialScale = 0.7;
  const initialTranslate = [
    offsetWidth * 0.5 * (1 - initialScale),
    offsetHeight * 0.5 * (1 - initialScale),
  ];
  const initialTransform = d3.zoomIdentity
    .translate(initialTranslate[0], initialTranslate[1])
    .scale(initialScale);

  zoom.transform(
    svg as d3.Selection<SVGSVGElement, unknown, null, undefined>,
    initialTransform,
  );

  positionNodesAndLinks(simulation, nodeSvgGroup, allLinkSvgElements);

  return () => {
    simulation.stop();
    allLinkSvgElements.remove();
    nodeSvgGroup.remove();
    svg.on('zoom', null);
  };
};
