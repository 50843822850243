import { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { withCrumb } from './withCrumb';

const Crumb = ({ title, currentTitle, setTitle }) => {
  useEffect(() => {
    if (!isEqual(title, currentTitle)) {
      setTitle(title);
    }
  }, [title, currentTitle, setTitle]);

  return null;
};

const crumbItemsType = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    }),
    PropTypes.shape({
      isLoading: PropTypes.bool,
    }),
  ]),
);

Crumb.propTypes = {
  title: crumbItemsType,
  currentTitle: crumbItemsType,
  setTitle: PropTypes.func,
};

export default withCrumb(Crumb);
