import React from 'react';
import FieldItemSwitcher from './FieldItemSwitcher';
import SchemaFieldItem from './SchemaFieldItem';

const FieldItem = ({
  schema,
  field,
  style,
  defaultFields,
  onDeleteField,
  onChangeDefaultField,
  disabled,
  fixed,
}) => {
  const isDefaultField = (defaultFields || []).find((f) => f.field === field);

  if (isDefaultField) {
    return (
      <FieldItemSwitcher
        style={style}
        isSelected={!disabled}
        fieldName={field}
        fixed={fixed}
        onChangeDefaultField={onChangeDefaultField}
        onDeleteField={() => {
          onDeleteField(field);
        }}
      />
    );
  }

  const fieldSchema = (schema || []).find(
    (s) => `property-${s.Key}`.toLowerCase() === field.toLowerCase(),
  );

  return (
    <SchemaFieldItem
      style={style}
      fieldName={field}
      fieldSchema={fieldSchema}
      onDeleteField={() => {
        onDeleteField(field);
      }}
    />
  );
};

export default FieldItem;
