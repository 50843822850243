import moment from 'moment';

export const getFriendlyValuePreview = (value) => {
  const isValidDate =
    !value?.toLowerCase()?.includes('utf') && moment(value).isValid();

  const friendlyValuePreview = isValidDate ? moment(value).format('ll') : value;

  return friendlyValuePreview;
};

export default { getFriendlyValuePreview };
