import { connect } from 'react-redux';
import { toVM } from '../../../entity/helper';
// import { toVM } from '../../../entity/viewModel';

const mapToStateProps = ({ entityMerge: { entities = [] } }, { entityId }) => {
  return {
    entity: toVM(
      (entities || []).find((e) => {
        return e.id === entityId;
      }),
    ),
  };
};

export default connect(mapToStateProps);
