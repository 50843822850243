import { useState, useEffect } from 'react';
import { apiRequest } from '../../../../core/helpers/request';
import { fromServertoGQLVm } from '../../../../entity/viewModel';

const getSuggestedSearch = (search, pageNumber) => {
  return apiRequest(
    'GET',
    `api/suggestedsearch?queryKey=${search.SearchQuery}&tokens=${search.Tokens}&page=${pageNumber}`,
  ).then((resp) =>
    resp.map((entity) => fromServertoGQLVm(entity, resp.__token)),
  );
};

export const useQuerySuggestedSearch = (suggestedSearchConfiguration = {}) => {
  const [data, setData] = useState({
    data: null,
    error: false,
    loading: true,
    pageNumber: 0,
    loadingMore: false,
    noMoreEntities: false,
  });

  useEffect(() => {
    setData({ ...data, error: null, loading: true, pageNumber: 0 });

    getSuggestedSearch(suggestedSearchConfiguration, data.padgeNumber)
      .then(async (entities) => {
        const noMoreEntities = entities?.length < 20;
        setData({
          ...data,
          data: entities,
          loading: false,
          noMoreEntities,
        });
      })
      .catch(() => {
        setData({
          error: true,
          loading: false,
        });
      });
  }, [JSON.stringify(suggestedSearchConfiguration)]);

  const loadMore = () => {
    setData({ ...data, error: null, loadingMore: true });

    const newPageNumber = data.pageNumber + 1;
    getSuggestedSearch(suggestedSearchConfiguration, newPageNumber)
      .then(async (entities) => {
        const noMoreEntities = (entities || []).length === 0;
        setData({
          ...data,
          data: [...data.data, ...(entities || [])],
          loadingMore: false,
          pageNumber: newPageNumber,
          noMoreEntities,
        });
      })
      .catch(() => {
        setData({
          error: true,
          loading: false,
        });
      });
  };

  return {
    ...data,
    loadMore,
  };
};
