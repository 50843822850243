import FindOrg from './components/pages/FindOrg';
import Signin from './components/pages/Signin';
import SSOLoginPage from './components/pages/SSOLogin';
import {
  AppOrClientPagePublicChooser,
  OnlyClientIdSubDomain,
} from '../core/components/Hocs/SubdomainChooser';

export default [
  {
    skipAuth: true,
    path: '/signin',
    component: AppOrClientPagePublicChooser(Signin, FindOrg),
  },
  {
    skipAuth: true,
    path: '/ssocallback',
    component: OnlyClientIdSubDomain(SSOLoginPage),
  },
];
