import React from 'react';
import NotificationWrapper from './NotificationWrapper';
import NotificationContentWrapper from './NotificationContentWrapper';
import NotificationIconWrapper from './NotificationIconWrapper';
import NotificationTitle from './NotificationTitle';
import NotificationSource from './NotificationSource';
import NotificationDescription from './NotificationDescription';
import NotificationActionWrapper from './NotificationActionWrapper';
import NotificationDate from './NotificationDate';
import NotificationLink from './NotificationLink';
import TextPlaceholder from '../../../../core/components/composites/Placeholder/TextPlaceholder';
import IconPlaceholder from '../../../../core/components/composites/Placeholder/IconPlaceholder';
import LittleTextPlaceholder from '../../../../core/components/composites/Placeholder/LittleTextPlaceholder';
import LinkPlaceholder from '../../../../core/components/composites/Placeholder/LinkPlaceholder';

const NotificationGhostLoading = () => {
  return (
    <NotificationWrapper className="notification">
      <NotificationIconWrapper>
        <IconPlaceholder size={24} />
      </NotificationIconWrapper>
      <NotificationContentWrapper>
        <NotificationSource>
          <LittleTextPlaceholder width={180} />
        </NotificationSource>
        <NotificationTitle>
          <TextPlaceholder width="250px" />
        </NotificationTitle>
        <NotificationDescription>
          <LittleTextPlaceholder width={350} />
          <LittleTextPlaceholder width={350} />
        </NotificationDescription>
        <NotificationActionWrapper>
          <NotificationDate>
            <LittleTextPlaceholder width={100} />
          </NotificationDate>
          <NotificationLink>
            <LinkPlaceholder
              style={{ width: '80px', display: 'inline-flex' }}
            />
          </NotificationLink>
        </NotificationActionWrapper>
      </NotificationContentWrapper>
    </NotificationWrapper>
  );
};

export default NotificationGhostLoading;
