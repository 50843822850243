const defaultPlaceHolder = 'main';

export const hasTabInConfiguration = (widgetConfiguration) =>
  widgetConfiguration.tabs &&
  widgetConfiguration.tabs[0] &&
  widgetConfiguration.tabs[0].children;

export const filterOnlyUsedWidgets = (widgetNames = []) => {};

export const id = (name, userId, entityId, layoutName, parametersHahsCode) => {
  const withoutParameters = `NAME:${name}-USER:${userId}-ENTITY:${entityId}-POSITION:${layoutName}`;

  if (!parametersHahsCode) {
    return withoutParameters;
  }

  return `${withoutParameters}-PARAMS:${parametersHahsCode}`;
};

/* eslint-disable */
export const hashCode = (str) => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const filterWidgetsByPlaceholderName = (widgets, placeHolderName) => {
  const filterByPlaceHolderFunc = (w) => {
    if (w.place) {
      return w.place.split('.')[0] === placeHolderName;
    }

    return defaultPlaceHolder === placeHolderName;
  };

  return widgets?.filter(filterByPlaceHolderFunc);
};

export default {
  hasTabInConfiguration,
  filterWidgetsByPlaceholderName,
};
