import {
  UpdatedGraphNetworkEdge,
  GraphNetworkEdge,
  UpdatedGraphNetworkNode,
} from '../types';

export const createNewEdges = (
  edges: GraphNetworkEdge[],
  updatedNodes: UpdatedGraphNetworkNode[],
): UpdatedGraphNetworkEdge[] => {
  const newLinks = edges?.map((newEdge, index) => {
    const sourceNodes = updatedNodes.filter(
      (source) => source?.id === newEdge?.from,
    );
    const targetNodes = updatedNodes.filter(
      (target) => target?.id === newEdge?.to,
    );

    const sourceNode = sourceNodes.length > 0 ? sourceNodes[0] : null;
    const targetNode = targetNodes.length > 0 ? targetNodes[0] : null;

    const { x: targetNodeX = 0, y: targetNodeY = 0 } = targetNode || {};
    const { x: sourceNodeX = 0, y: sourceNodeY = 0 } = sourceNode || {};

    const curveX = (targetNodeX - sourceNodeX) * 0.09;
    const curveY = (targetNodeY - sourceNodeY) * 0.09;

    const direction =
      sourceNode?.id === updatedNodes?.[0]?.id ? 'Outgoing' : 'Incoming';

    return {
      ...newEdge,
      source: sourceNode,
      target: targetNode,
      direction,
      index,
      curve: {
        x: curveX,
        y: curveY,
      },
    };
  });

  return newLinks as UpdatedGraphNetworkEdge[];
};
