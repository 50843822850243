import React, { Component } from 'react';
import styled from 'styled-components';
import ArrowRight from 'uxi/Icons/Arrowright';
import Delete from 'uxi/Icons/Delete';
import Tooltip from 'rc-tooltip';
import SearchLink from './SearchLink';

const SavedSearchListStyle = {
  list: {
    margin: 0,
    padding: 0,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '50px',
    paddingLeft: '15px',
    flex: 1,
    display: 'flex',
  },
};

const SavedSearchListListItem = styled.li`
  list-style: none;
  position: relative;
  padding: 0;
  border-bottom: 1px solid #dee5e7;
  min-height: 50px;
  display: flex;
  &:hover {
    background-color: #f2f2f2;
    .tooltip-wrapper {
      display: block;
      float: right;
      margin-right: 15px;
    }
  }
  .tooltip-wrapper {
    display: none;
  }
`;

class SavedSearchList extends Component {
  onSearchClickHandler(search) {
    const { onSearchClick } = this.props;

    if (onSearchClick) {
      onSearchClick(search);
    }
  }

  viewChangesHandler(i, e) {
    e.stopPropagation();
    const { viewChanges } = this.props;
    if (viewChanges) {
      viewChanges(i);
    }
  }

  removeSearch(search, e) {
    e.stopPropagation();
    const { onRemoveSearchClick } = this.props;

    onRemoveSearchClick(search);
  }

  render() {
    const { searches } = this.props;

    const searchContent = searches.map((search, index) => {
      if (!search.Name) {
        return null;
      }

      let badgeComponent;
      if (search.Count && search.Count > 0) {
        const badgetContent = `View ${search.Count} changes`;

        badgeComponent = (
          // eslint-disable-next-line
          <a
            onClick={this.viewChangesHandler.bind(this, search)}
            style={{
              color: 'rgb(255, 64, 129)',
              float: 'right',
              marginTop: '15px',
              marginRight: '5px',
            }}
          >
            {badgetContent}
            <ArrowRight
              size={14}
              style={{
                fill: 'rgb(255, 64, 129)',
                marginTop: '5px',
                marginLeft: '5px',
                float: 'right',
              }}
            />
          </a>
        );
      }

      return (
        <SavedSearchListListItem
          className="test_NotificationCenter_SavedSearchItem"
          key={`SavedSearch-${index}`}
          onClick={this.onSearchClickHandler.bind(this, search)}
        >
          <div style={SavedSearchListStyle.link}>
            <div
              className="test_NotificationCenter_SavedSearchItem_Name"
              data-test-name={search.Name}
              style={{ flex: 1 }}
            >
              <SearchLink search={search} />
            </div>
            <div className="tooltip-wrapper">
              <Tooltip placement="bottom" overlay={<span>Remove Search</span>}>
                <Delete
                  onClick={this.removeSearch.bind(this, search)}
                  size={20}
                />
              </Tooltip>
            </div>
          </div>
          {badgeComponent}
        </SavedSearchListListItem>
      );
    });

    return (
      <div>
        <ul style={SavedSearchListStyle.list}>{searchContent}</ul>
      </div>
    );
  }
}

export default SavedSearchList;
