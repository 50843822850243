import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { shouldFetchSignUp } from '../../actions/signup';

import SignUpForm from '../composites/SignUpForm';
import { withSuccess } from '../../../core/components/Hocs/defaults';

const EnhancedResetPwd = compose(
  withSuccess({
    SuccessMessage: (
      <div className="test_signUpForm_success">
        <h3>
          <FormattedMessage id="module-public-signup-SignupMagicLinkHeading" />
        </h3>
        <p>
          <FormattedMessage id="module-public-signup-SignupMagicLinkLine1" />
          <FormattedMessage id="module-public-signup-SignupMagicLinkLine2" />
        </p>
      </div>
    ),
  }),
)(SignUpForm);

const mapToStateProps = ({
  signup: { isFetchingSignup, done, errorSignup, alreadyTaken },
}) => ({
  isFetching: isFetchingSignup,
  done,
  error: errorSignup,
  alreadyTaken,
});

const mapDispatchToProps = (dispatch) => ({
  signUp: ({ email }) => {
    dispatch(shouldFetchSignUp(email));
  },
});

export default connect(mapToStateProps, mapDispatchToProps)(EnhancedResetPwd);
