import React from 'react';
import { Select } from '@cluedin/form';

const options = [
  { value: '', label: 'Please select a value' },
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Purchasing department', label: 'Purchasing department' },
  {
    value: 'Research and development team',
    label: 'Research and development team',
  },
  { value: 'Management', label: 'Management' },
  { value: 'IT department', label: 'IT department' },
  { value: 'Customer service', label: 'Customer service' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Marketing', label: 'Marketing' },
  {
    value: 'Personnel/Staff/Human Resources department',
    label: 'Personnel/Staff/Human Resources department',
  },
  { value: 'Engineering department', label: 'Engineering department' },
  {
    value: 'Public relations department',
    label: 'Public relations department',
  },
  { value: 'Technical support team', label: 'Technical support team' },
  { value: 'Sales department', label: 'Sales department' },
  { value: 'Communications', label: 'Communications' },
  { value: 'Account management', label: 'Account management' },
  { value: 'Top Secret', label: 'Top Secret' },
];

const DataSourceField = (props) => (
  <Select
    isFullWidth
    {...props}
    options={options}
    onChange={({ value }) => props.onChange(value)}
  />
);

export default DataSourceField;
