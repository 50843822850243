import { useLazyQuery, gql } from '@apollo/client';
import get from 'lodash/get';

const GET_DEDUPLICATION_PROJECT_MERGE_NODE_INFO = gql`
  query getDeduplicationProjectMergeNodeInfo(
    $entityId: ID!
    $mergeDataPartId: ID!
  ) {
    virtualization {
      getDeduplicationProjectMergeNodeInfo(
        entityId: $entityId
        mergeDataPartId: $mergeDataPartId
      ) {
        kind
        name
        description
        actions {
          kind
          availability
          unavailableReason
          reasonMessage
          mergeDataPartId
          userId
          dataParts {
            dataPartId
            name
            sortDate
            provider {
              providerDefinitionId
              name
              providerId
              providerName
              providerIcon
            }
            source
          }
          actionDescriptor
          splitEntityActionRequest
        }
        deduplicationProject {
          deduplicationProjectId
          name
        }
        userId
      }
    }
  }
`;

export const useGetDeduplicationProjectMergeNodeInfo = () => {
  const [getDeduplicationProjectMergeNodeInfo, { data, loading, error }] =
    useLazyQuery(GET_DEDUPLICATION_PROJECT_MERGE_NODE_INFO, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const result = get(
    data,
    'virtualization.getDeduplicationProjectMergeNodeInfo',
  );

  return [
    (entityId, mergeDataPartId) =>
      getDeduplicationProjectMergeNodeInfo({
        variables: { entityId, mergeDataPartId },
      }),
    {
      data: result,
      loading,
      error,
    },
  ];
};
