import React from 'react';
import Text from 'uxi/Text';
import RequiredField from './RequiredField';

const FormDecorator = (Input, props) => (field) =>
  (
    <div>
      {props.label && (
        <Text type="heading" style={{ paddingBottom: '15px' }}>
          {props.label} {props && props.isRequired && <RequiredField />}
        </Text>
      )}
      {props.helpText && (
        <div style={{ marginBottom: '10px' }}>
          <Text type="caption">{props.helpText}</Text>
        </div>
      )}
      <div style={{ marginBottom: '15px' }}>
        <Input
          {...props}
          {...field}
          {...field.input}
          success={field.meta.touched && !field.meta.error}
          error={field.meta.touched && field.meta.error}
          label=""
        />
      </div>
    </div>
  );

export default FormDecorator;
