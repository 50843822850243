import { FC } from 'react';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';

import { SelectedEdgeDetailWrapper } from './SelectedEdgeDetailWrapper';
import { SelectedEdgeDetailTitle } from './SelectedEdgeDetailTitle';

import { SelectedConnectingDirectionalEdge } from '../../types';

type SelectedEdgeDetailProps = {
  nodeFrom: SelectedConnectingDirectionalEdge;
  nodeTo: SelectedConnectingDirectionalEdge;
  edgeType: string;
  totalEdgeTypes: number;
  edgeFrom: SelectedConnectingDirectionalEdge;
  edgeTo: SelectedConnectingDirectionalEdge;
  onNodeClick: (node: SelectedConnectingDirectionalEdge) => void;
  onGroupedRelationsClick: (
    nodeTo: SelectedConnectingDirectionalEdge,
    edgeType: string,
    edgeDirection: string,
  ) => void;
  edgeDirection: string;
};

const isGrouped = (edge: SelectedConnectingDirectionalEdge) =>
  edge?.label?.toLowerCase() === 'grouped' ||
  edge?.nodeKind?.toLowerCase() === 'groupedrelations';

export const SelectedEdgeDetail: FC<SelectedEdgeDetailProps> = ({
  nodeFrom,
  nodeTo,
  edgeType,
  totalEdgeTypes,
  edgeFrom,
  edgeTo,
  onNodeClick,
  onGroupedRelationsClick,
  edgeDirection,
}) => {
  return (
    <SelectedEdgeDetailWrapper>
      <SelectedEdgeDetailTitle>
        <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgeProperties" />
      </SelectedEdgeDetailTitle>

      <div>
        <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelTotalEdgeTypes" />
        :&nbsp;
        <strong>{totalEdgeTypes}</strong>
      </div>
      {totalEdgeTypes === 1 && (
        <div>
          <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelEdgeType" />
          :&nbsp;
          <strong>{edgeType}</strong>
        </div>
      )}

      <div>
        <FormattedMessage id="module-entityRelationsGraph-edgePropertiesHeaderDirection" />
        :&nbsp;
        <strong>
          {edgeDirection?.toLowerCase() === 'outgoing' ? (
            <FormattedMessage id="module-entityRelationsGraph-edgePropertiesHeaderOutgoing" />
          ) : (
            <FormattedMessage id="module-entityRelationsGraph-edgePropertiesHeaderIncoming" />
          )}
        </strong>
      </div>

      <div>
        <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelTotalEdgeFrom" />
        :&nbsp;
        <ButtonLink
          onClick={() => {
            isGrouped(edgeFrom)
              ? onGroupedRelationsClick(nodeFrom, edgeType, edgeDirection)
              : onNodeClick(nodeFrom);
          }}
          text={
            <strong>
              {isGrouped(edgeFrom) ? (
                <FormattedMessage id="module-entityRelationsGraph-edgePropertiesHeaderGroupedRecords" />
              ) : (
                edgeFrom?.label ?? (
                  <FormattedMessage id="module-entityRelationsGraphV2-noName" />
                )
              )}
            </strong>
          }
        />
      </div>
      <div>
        <FormattedMessage id="module-entityRelationsGraph-edgePropertiesPanelTotalEdgeTo" />
        :&nbsp;
        <ButtonLink
          onClick={() => {
            isGrouped(edgeTo)
              ? onGroupedRelationsClick(nodeTo, edgeType, edgeDirection)
              : onNodeClick(nodeTo);
          }}
          text={
            <strong>
              {isGrouped(edgeTo) ? (
                <FormattedMessage id="module-entityRelationsGraph-edgePropertiesHeaderGroupedRecords" />
              ) : (
                edgeTo?.label ?? (
                  <FormattedMessage id="module-entityRelationsGraphV2-noName" />
                )
              )}
            </strong>
          }
        />
      </div>
    </SelectedEdgeDetailWrapper>
  );
};
