import { PrimaryButton } from '@cluedin/form';

const ResetNodes = ({ onClick = () => {}, text = '' }) => {
  return (
    <PrimaryButton
      text={text}
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '40px',
        whiteSpace: 'nowrap',
        width: 'fit-content',
      }}
    />
  );
};

export default ResetNodes;
