import { defaultErrorHandling } from 'uxi-business/errorHandling';
import { createAction, createActions } from 'redux-actions';
import { logException, logMessage } from './logger';

export const createAsyncAction = (name) => {
  const result = {};

  result.request = createAction(`REQUEST_${name}`);
  result.receive = createAction(`RECEIVE_${name}`);
  result.invalid = createAction(`INVALID_${name}`);
  result.queued = createAction(`QUEUED_${name}`);

  return result;
};

export const defaultAsyncCallWithActions =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param).then((res) => {
      dispatch(actions.receive(res));
      if (onSuccess) {
        onSuccess(dispatch, param);
      }
    });
  };

export const defaultAsyncCallWithActionsAndParams =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request({ param }));

    return call(param).then((res) => {
      dispatch(actions.receive({ result: res, param }));
      if (onSuccess) {
        onSuccess(dispatch, { param });
      }
    });
  };

export const widgetErrorActions = createActions({
  ACCESS_DENIED: (widgetUniqueID) => ({ widgetUniqueID }),
  ACCESS_UNAUTHORIZED: (widgetUniqueID) => ({ widgetUniqueID }),
  GLOBAL_ERROR: (widgetUniqueID) => ({ widgetUniqueID }),
  QUEUED: (widgetUniqueID) => ({ widgetUniqueID }),
});

export const errorHandler = (dispatch, widgetUniqueID) => (resp) => {
  // this is another kind of error, probably from React
  if (resp && !resp.status) {
    logException(resp);
    dispatch(widgetErrorActions.globalError(widgetUniqueID));
  }

  const url = resp && resp.req ? resp.req.url : '';

  if (resp.response && resp.response.status === 403) {
    logMessage(`Error 403 - access denies - for ${url}`);
    dispatch(widgetErrorActions.accessDenied(widgetUniqueID));
  }

  if (resp.response && resp.response.status === 401) {
    logMessage(`Error 401 - unauthorized - for ${url}`);
    dispatch(widgetErrorActions.accessUnauthorized(widgetUniqueID));
  }

  if (resp.response && resp.response.status === 202) {
    logMessage(`Error 401 - unauthorized - for ${url}`);
    dispatch(widgetErrorActions.queued(widgetUniqueID));
  }
};

export const noBodyResponseAsyncCallWithActions =
  (call, actions, onSuccess) => (param) => (dispatch) => {
    dispatch(actions.request(param));

    return call(param)
      .then(() => {
        dispatch(actions.receive(param));

        if (onSuccess) {
          onSuccess(dispatch, param);
        }
      })
      .catch(defaultErrorHandling(dispatch, param, actions.invalid));
  };

export default {
  createAsyncAction,
  defaultAsyncCallWithActions,
  errorHandler,
  noBodyResponseAsyncCallWithActions,
};
