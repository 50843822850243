import React from 'react';
import EntityLinkFromEntityContainer from '../../../../wms/components/containers/EntityLinkFromEntityContainer';

const NameComponent = (entity) => {
  return (
    <EntityLinkFromEntityContainer entity={entity}>
      {entity.name || entity.entityId}
    </EntityLinkFromEntityContainer>
  );
};

export default NameComponent;
