import React from 'react';
import NewIntegrationContainer from '../containers/NewIntegrationContainer';

const NewConfigurationPage = ({
  match: {
    params: { integrationId },
  },
}) => <NewIntegrationContainer integrationId={integrationId} />;

export default NewConfigurationPage;
