import { DataTreeView, List } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';

import { ExpandedRow } from '../../../../goldenRecordList/components/containers/GoldenRecordViewPropertiesPanel';
import { injectIntl } from '@cluedin/locale';
import {
  getData,
  getVocabularyTreeData,
} from '../../../../goldenRecordList/components/containers/GoldenRecordViewPropertiesPanel/GoldenRecordViewPropertiesPanel';
import { getColumns } from './getColumns';

import { useGetEntityById } from '../../../../entityProperties/hooks/getEntityById';
import { Wrapper, Flex } from './styled';
import { useQueryVocabularyKeysForKeys } from '../../../../dataCatalog/hooks/useQueryVocabularyKeysForKeys';

const CoreVocabularyComponent = ({
  intl,
  entity,
  template: { vocabularyKeysCore = [] },
}) => {
  const [data, loading] = useGetEntityById(entity.id);
  const [vocabKeys] = useQueryVocabularyKeysForKeys(vocabularyKeysCore);
  const entityProperties = data?.virtualization?.entityById?.entityProperties;
  const someData = getVocabularyTreeData(vocabKeys, entityProperties);

  const coreVocabularyKeys = getData(entityProperties)
    .filter((property) => property.isCluedInCore)
    .map(({ entityProps, ...props }) => {
      const vocabularyElements =
        vocabularyKeysCore?.length === 0
          ? entityProps.filter((entity) => entity.vocabularyKey.isCluedInCore)
          : entityProps.filter(
              (entity) =>
                vocabularyKeysCore?.includes(entity.name) &&
                entity.vocabularyKey.isCluedInCore,
            );

      return { entityProps: vocabularyElements, ...props };
    });

  const dataToShow =
    vocabularyKeysCore.length > 0 ? someData : coreVocabularyKeys;

  return (
    <Wrapper isEmpty={(dataToShow || []).length === 0}>
      <Flex>
        <List
          data={dataToShow}
          loading={loading}
          offsetTop={295}
          selectable={false}
          showPagination={false}
          DataGrid={DataTreeView}
          columns={getColumns(
            intl.formatMessage({
              id: 'module-entityWidget-vocabularyCoreTitle',
            }),
          )}
          secondLevelDataKey="entityProps"
          noSearch={true}
          notFoundProps={{
            smaller: true,
            message: (
              <FormattedMessage id="module-entityWidget-vocabularyCoreNotFoundPropertiesTitle" />
            ),
            explanation: (
              <FormattedMessage id="module-entityWidget-vocabularyCoreExplanation" />
            ),
            noCreate: true,
          }}
          RowExpandedComponent={({ entityProps }) => (
            <ExpandedRow entityProps={entityProps} />
          )}
        />
      </Flex>
    </Wrapper>
  );
};
export default injectIntl(CoreVocabularyComponent);
