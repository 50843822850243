"use strict";

exports.__esModule = true;
exports.usePaging = void 0;
var _curry = _interopRequireDefault(require("lodash/curry"));
var _encoding = require("../utils/encoding");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ITEMS_PER_PAGE_NAME = 'itemsPerPage';
var PAGE_NO_NAME = 'pageNo';
var getCursor = function getCursor(pageInfo) {
  var cursorObj = {
    pageSize: pageInfo.itemsPerPage,
    page: pageInfo.selectedPage - 1
  };
  return (0, _encoding.unicodeEncodeGqlCursor)(JSON.stringify(cursorObj));
};
var getValuesFromLocation = function getValuesFromLocation(_ref, itemsPerPageName, pageNoName) {
  var search = _ref.search;
  var query = new URLSearchParams(search);
  return {
    urlItemsPerPage: parseInt(query.get(itemsPerPageName), 10),
    urlSelectedPage: parseInt(query.get(pageNoName), 10)
  };
};
var setPageInUrl = (0, _curry.default)(function (history, itemsPerPageName, itemsPerPage, pageNoName, pageNo, replaceLocation) {
  if (replaceLocation === void 0) {
    replaceLocation = false;
  }
  var _history$location = history.location,
    pathname = _history$location.pathname,
    search = _history$location.search,
    hash = _history$location.hash,
    state = _history$location.state;
  var query = new URLSearchParams(search);
  query.set(itemsPerPageName, itemsPerPage);
  query.set(pageNoName, pageNo);
  var newLocation = {
    pathname: pathname,
    hash: hash,
    search: "?" + query.toString(),
    state: state
  };
  if (replaceLocation) {
    history.replace(newLocation);
  } else {
    history.push(newLocation);
  }
});
var usePaging = function usePaging(history, location, defaultItemsPerPage, pagingId) {
  if (defaultItemsPerPage === void 0) {
    defaultItemsPerPage = 10;
  }
  if (pagingId === void 0) {
    pagingId = '';
  }
  var itemsPerPageName = pagingId ? pagingId + "-" + ITEMS_PER_PAGE_NAME : ITEMS_PER_PAGE_NAME;
  var pageNoName = pagingId ? pagingId + "-" + PAGE_NO_NAME : PAGE_NO_NAME;
  var _getValuesFromLocatio = getValuesFromLocation(location, itemsPerPageName, pageNoName),
    urlItemsPerPage = _getValuesFromLocatio.urlItemsPerPage,
    urlSelectedPage = _getValuesFromLocatio.urlSelectedPage;
  var itemsPerPage = urlItemsPerPage || defaultItemsPerPage;
  var selectedPage = urlSelectedPage || 1;
  var currentPage = {
    selectedPage: selectedPage,
    itemsPerPage: itemsPerPage
  };
  var cursor = getCursor(currentPage);
  var setSelectedPage = setPageInUrl(history, itemsPerPageName, itemsPerPage, pageNoName);
  if (!urlItemsPerPage || !urlItemsPerPage) {
    setSelectedPage(selectedPage, true);
  }
  return [cursor, currentPage, setSelectedPage];
};
exports.usePaging = usePaging;