import React from 'react';
import { Helmet } from 'react-helmet';
import GdprReportContainer from '../containers/GdprReportContainer';

const GdprJsonReportPage = (props) => {
  const {
    match: {
      params: { id },
    },
    location: { search },
  } = props;
  const params = new URLSearchParams(search);
  const print = params.get('print');
  const shouldPrint = !!print;

  return (
    <div>
      <GdprReportContainer
        sarId={id}
        editable={false}
        shouldPrint={shouldPrint}
      />

      <Helmet>
        <title>Personnal Data Report</title>
      </Helmet>
    </div>
  );
};

export default GdprJsonReportPage;
