import { withClaimsDisabled } from '@cluedin/components';
import styled from 'styled-components';
import { ActionLink } from '@cluedin/components';
import { useTheme } from '@cluedin/theme';
import UnstyledLink from '../UnstyledLink';

const getActionStyles = (themePrimary) => ({
  color: 'white',
  background: themePrimary,
});

const LinkWrapper = styled.div`
  transition: ${({ theme: { transition } }) => transition.defaultAll};
  filter: brightness(100%);
  & a:hover,
  & a:focus {
    transition: ${({ theme: { transition } }) => transition.defaultAll};
    filter: brightness(130%);
  }
`;

const getCustomLink = (isAction, themePrimary) => (props) =>
  (
    <LinkWrapper>
      <UnstyledLink
        {...props}
        style={{
          ...(isAction ? getActionStyles(themePrimary) : {}),
          display: 'block',
        }}
      />
    </LinkWrapper>
  );

const DashboardLink = ({
  isAction,
  label,
  icon,
  to,
  target,
  className,
  onClick,
  disabled,
  actionConfiguration,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <div className={className}>
      <ActionLink
        onClick={!disabled && onClick}
        style={{ borderBottomWidth: 0 }}
        actionConfiguration={actionConfiguration}
        Link={getCustomLink(isAction, themePrimary)}
        to={!disabled && (to || '#')}
        target={target}
        menuDescriptor={{
          displayName: label,
          key: `${label}${to}`,
          icon,
          isPromoted: true,
        }}
      />
    </div>
  );
};

export default withClaimsDisabled(DashboardLink);
