"use strict";

exports.__esModule = true;
exports.saveTokenForClientId = exports.getNewToken = exports.getCurrentToken = exports.getAntiForgeryToken = void 0;
var _refreshToken = require("./refreshToken");
exports.getNewToken = _refreshToken.getNewToken;
var _storage = require("./storage");
exports.getCurrentToken = _storage.getCurrentToken;
exports.saveTokenForClientId = _storage.saveTokenForClientId;
var _antiForgery = require("./antiForgery");
exports.getAntiForgeryToken = _antiForgery.getAntiForgeryToken;