import React from 'react';
import Alert from 'uxi/Alert';

const NotFoundResult = ({ text }) => (
  <div style={{ padding: '15px' }}>
    <Alert>{text || 'No data found.'}</Alert>
  </div>
);

export default NotFoundResult;
