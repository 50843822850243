import React from 'react';
import Drawer from 'uxi/Drawer';
import Panel, { PanelHeader, PanelContent } from 'uxi/Panel';
import SomethingOdd from '../../../error/components/composites/SomethingOdd';
import PageLoader from './PageLoader';

const QuickViewWithErrorAndLoading = ({
  children,
  invalid,
  isFetching,
  title,
  open,
  onClose,
  style,
}) => {
  let content;

  if (invalid) {
    content = <SomethingOdd />;
  } else if (isFetching) {
    content = <PageLoader />;
  } else {
    content = children;
  }

  const offsetTop = 48;

  return (
    <Drawer
      offsetTop={offsetTop}
      style={{
        ...style,
      }}
      open={open}
    >
      <Panel>
        {title ? (
          <PanelHeader hasClose onClose={onClose} title={title} />
        ) : (
          <div />
        )}
        <PanelContent>{content}</PanelContent>
      </Panel>
    </Drawer>
  );
};

QuickViewWithErrorAndLoading.displayName = 'QuickViewWithErrorAndLoading';

export default QuickViewWithErrorAndLoading;
