import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { HintBadge } from '../../../../../shared/components/HintBadge';
const EntityNameWithTag = ({ entity }) => {
  const isShadowEntity = entity?.data?.isShadowEntity;

  return (
    <div style={{ display: 'flex' }}>
      <span>
        <strong
          style={{
            marginLeft: '5px',
            fontWeight: 'bold',
          }}
          data-test="entityPage-name-breadcrumb"
        >
          {entity.name}
        </strong>
      </span>
      {isShadowEntity !== 'False' && (
        <div style={{ marginLeft: '12px' }}>
          <HintBadge
            badgeStyle={{
              maxHeight: '24px',
              fontSize: 14,
              alignItems: 'center',
            }}
            label={
              <FormattedMessage id="module-entityV2-shadow-entity-label" />
            }
            hintLabel={
              <FormattedMessage id="module-entityV2-shadow-entity-tooltip-hint" />
            }
          />
        </div>
      )}
    </div>
  );
};

export default EntityNameWithTag;
