"use strict";

exports.__esModule = true;
exports.DatePickerStyles = void 0;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var DatePickerStyles = function DatePickerStyles(_ref) {
  var disabled = _ref.disabled,
    theme = _ref.theme,
    className = _ref.className;
  var hasError = (className || '').indexOf('has-error') > -1;
  var errorInputStyle = hasError ? {
    borderColor: theme.palette.red
  } : {};
  var iconStyle = hasError ? {
    background: theme.palette.red
  } : {};
  var disabledIconStyle = disabled ? {
    top: 0,
    bottom: 0,
    height: '30px'
  } : {};
  return {
    icon: _extends({
      cursor: 'pointer',
      background: theme.palette.themePrimary,
      display: 'flex',
      padding: 0,
      alignItems: 'center',
      position: 'absolute',
      right: '-1px;',
      top: '-1px;',
      bottom: '-1px;',
      color: '#fff',
      height: '32px',
      width: '32px',
      justifyContent: 'center',
      borderRadius: '0 2px 2px 0',
      '&:hover': {
        background: '#64CFBA',
        color: '#fff'
      }
    }, disabledIconStyle, iconStyle),
    root: [{
      '.ms-TextField-fieldGroup': _extends({}, errorInputStyle)
    }]
  };
};
exports.DatePickerStyles = DatePickerStyles;