import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #dee5e7;
  border-radius: 2px;
`;

const List = ({ style = {}, children, noBorder }) => {
  const mergedStyle = noBorder ? { border: 0, ...style } : { ...style };

  return <ListWrapper style={mergedStyle}>{children}</ListWrapper>;
};

List.defaultProps = {
  noBorder: false,
};

List.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
};

List.defaultProps = {
  style: {},
};

export default List;
