import { handleActions } from 'redux-actions';
import {
  openWMSManager,
  closeWMSManager,
  fetchWidgetConfigurationActions,
  changeLastVisitedTimeAction,
} from './actions';
import {
  getLastDateTime,
  updateEntityConfigurationFromUpdate,
  updateEntityConfigurationFromCreate,
} from './data';

import {
  updateEntityConfigActions,
  createEntityConfigActions,
} from '../organization/actions';

const initialState = {
  isWMSOpen: false,
  isLoadingEntityConfigurations: true,
  entityConfigurations: [],
  lastTimeVisitedValue: getLastDateTime(),
  isSavingOrCreatingEntityConfiguration: false,
  invalidSavingOrCreatingEntityConfiguration: false,
};

/*    /* case constants.core.REMOVE_WIDGET: {
      const newWidgetConfiguration = state.widgetConfiguration;
      const name = action.data.name;
      const removeWidgetParameters = action.data;

      if (removeWidgetParameters.tabIndex >= 0) {
        state.widgetConfiguration.tabs[removeWidgetParameters.tabIndex].widgets.filter(w => (
          w.name !== name
        ));
      } else {
        newWidgetConfiguration.widgets = state.widgetConfiguration.widgets.filter(w => (
          w.name !== name
        ));
      }

      return Object.assign({}, state, {
        widgetConfiguration: Object.assign({}, newWidgetConfiguration),
      });
    }
*/

export default handleActions(
  {
    [changeLastVisitedTimeAction]: (state, { payload }) => ({
      ...state,
      lastTimeVisitedValue: payload,
    }),
    [openWMSManager]: (state) => ({
      ...state,
      isWMSOpen: true,
    }),
    [closeWMSManager]: (state) => ({
      ...state,
      isWMSOpen: false,
    }),
    [fetchWidgetConfigurationActions.request]: (state) => ({
      ...state,
      isLoadingEntityConfigurations: true,
    }),
    [fetchWidgetConfigurationActions.receive]: (
      state,
      {
        payload: {
          result: { entityConfigurations },
        },
      },
    ) => ({
      ...state,
      isLoadingEntityConfigurations: false,
      entityConfigurations,
    }),
    [updateEntityConfigActions.request]: (state) => ({
      ...state,
      isSavingOrCreatingEntityConfiguration: true,
      invalidSavingOrCreatingEntityConfiguration: false,
    }),
    [updateEntityConfigActions.receive]: (state, { payload: { param } }) => ({
      ...state,
      isSavingOrCreatingEntityConfiguration: false,
      invalidSavingOrCreatingEntityConfiguration: false,
      entityConfigurations: updateEntityConfigurationFromUpdate(
        state.entityConfigurations,
        param,
      ),
    }),
    [updateEntityConfigActions.invalid]: (state) => ({
      ...state,
      isSavingOrCreatingEntityConfiguration: false,
      invalidSavingOrCreatingEntityConfiguration: true,
    }),
    [createEntityConfigActions.request]: (state) => ({
      ...state,
      isSavingOrCreatingEntityConfiguration: true,
      invalidSavingOrCreatingEntityConfiguration: false,
    }),
    [createEntityConfigActions.receive]: (state, { payload: { param } }) => ({
      ...state,
      isSavingOrCreatingEntityConfiguration: false,
      invalidSavingOrCreatingEntityConfiguration: false,
      entityConfigurations: updateEntityConfigurationFromCreate(
        state.entityConfigurations,
        param,
      ),
    }),
    [createEntityConfigActions.invalid]: (state) => ({
      ...state,
      isSavingOrCreatingEntityConfiguration: false,
      invalidSavingOrCreatingEntityConfiguration: true,
    }),
  },
  initialState,
);
