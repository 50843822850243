import styled from 'styled-components';
import { useId } from 'react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import { Icon } from '@fluentui/react/lib/Icon';

type FieldAlertTooltipProps = {
  type: 'info' | 'warning' | 'error' | 'danger' | 'success';
  message: string;
  tooltipContent: string;
};

const mainColor = {
  error: '#d13f48',
  danger: '#e66565',
  warning: '#FF9800',
  info: '#3e53c1',
  success: '#009688',
};

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const FieldAlertTooltip = ({
  message,
  type,
  tooltipContent,
}: FieldAlertTooltipProps) => {
  const tooltipTopId = useId();
  let iconName = 'Info';
  if (type === 'warning') {
    iconName = 'Warning';
  }

  const textColor = mainColor[type || 'info'];

  return (
    <div>
      <TooltipHost
        content={tooltipContent}
        id={tooltipTopId}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconWrapper>
          <div>
            <Icon
              iconName={iconName}
              styles={{
                root: {
                  fontSize: 16,
                  height: 16,
                  span: { marginRight: 6 },
                  color: textColor,
                },
              }}
            />
          </div>
          {message && <div style={{ color: textColor }}>{message}</div>}
        </IconWrapper>
      </TooltipHost>
    </div>
  );
};
