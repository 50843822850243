import { FC, useMemo, useState, memo } from 'react';
import { PanelContent } from '@cluedin/form';
import { Stepper } from '@cluedin/components';
import { List, DataTreeView } from '@cluedin/list-pattern';
import { useIntl } from 'react-intl';

import { useGoldenRecordList } from '../../../GoldenRecordListContext';
import { getColumns } from './getColumns';
import { SavedSearch } from '../../../../../hooks/useSavedSearch';

type Props = {
  onClose: () => void;
  hideFilter: () => void;
};

const RecordListSavedSearchesC: FC<Props> = memo(({ onClose, hideFilter }) => {
  const { formatMessage } = useIntl();

  const {
    filterOptions: {
      savedSearches,
      onApplySearch,
      savedSearchesPage,
      handleDeleteSearch,
      totalSavedSearches,
      sharedSavedSearches,
      refetchSavedSearches,
      setSavedSearchesPage,
      loadingSavedSearches,
      totalsSharedSavedSearches,
    },
  } = useGoldenRecordList();

  const [activeStep, setActiveStep] = useState(1);

  const onDeleteSearch = async (id: string) => {
    await handleDeleteSearch(id);

    await refetchSavedSearches();

    if (savedSearchesPage > 1) {
      if (
        activeStep === 1 &&
        (savedSearches.length < 2 || savedSearches.length === 10)
      ) {
        return setSavedSearchesPage((prev) => prev - 1);
      }

      if (sharedSavedSearches.length < 2 || sharedSavedSearches.length === 10) {
        return setSavedSearchesPage((prev) => prev - 1);
      }
    }

    onClose();
  };

  const handleApplySearch = (savedSearch: SavedSearch) => {
    hideFilter();

    onApplySearch(savedSearch);

    onClose();
  };

  const listColumns = useMemo(
    () =>
      getColumns({
        onApplySearch: handleApplySearch,
        formatMessage: formatMessage,
        handleDeleteSearch: onDeleteSearch,
      }),
    [savedSearchesPage, savedSearches],
  );

  return (
    <PanelContent style={{ padding: 0 }}>
      <Stepper
        activeStep={activeStep}
        onClickStep={(step: number) => {
          setSavedSearchesPage(1);
          setActiveStep(step);
        }}
        steps={[
          {
            label: formatMessage({
              id: 'module-goldenRecordList-saved-searches-my',
            }),
          },
          {
            label: formatMessage({
              id: 'module-goldenRecordList-saved-searches-shared',
            }),
          },
        ]}
      />

      <List
        noSearch
        hideExpanding
        offsetTop={150}
        itemsPerPage={10}
        selectable={false}
        columns={listColumns}
        DataGrid={DataTreeView}
        loading={loadingSavedSearches}
        selectedPage={savedSearchesPage}
        onPageChange={setSavedSearchesPage}
        data={activeStep === 1 ? savedSearches : sharedSavedSearches}
        total={
          activeStep === 1 ? totalSavedSearches : totalsSharedSavedSearches
        }
        notFoundProps={{
          noCreate: true,
          message: formatMessage({
            id: 'module-goldenRecordList-saved-searches-empty-msg',
          }),
          explanation: formatMessage({
            id: 'module-goldenRecordList-saved-searches-empty-explanation',
          }),
        }}
      />
    </PanelContent>
  );
});

export const RecordListSavedSearches = RecordListSavedSearchesC;
