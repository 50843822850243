import React, { useEffect } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { logMessage } from '../../../core/helpers/logger';
import serverUnavailable from '../../assets/server-unavailable-illustration.png';

const FrontEndIssue = ({ status, error }) => {
  useEffect(() => {
    logMessage(`Error ${status || '403'} for : ${window.location.href}`);
  }, [status]);

  return (
    <div
      style={{
        margin: '60px auto',
        maxWidth: '800px',
        fontFamily: 'sans-serif',
        textAlign: 'center',
      }}
    >
      <div style={{ marginTop: '60px' }}>
        <img alt="Server Unavailable" src={`${serverUnavailable}`} />
      </div>
      <div style={{ padding: '16px', fontSize: '16px' }}>
        <span>
          <FormattedMessage id="module-error-severErrorTxt" />
        </span>
      </div>
      <div style={{ margin: '16px 0' }}>
        <div>
          <FormattedMessage id="module-error-toRecover" />:
        </div>
        <a style={{ color: '#31a4a3', margin: '16px 0' }} href="/">
          <FormattedMessage id="module-error-backToHome" />
        </a>
      </div>
      <div>
        <div>
          <FormattedMessage id="module-error-possibleErrorCode" />:
        </div>
        <pre style={{ color: 'red' }}>{error && error.toString()}</pre>
        <div>
          <FormattedMessage id="module-error-onPage" />:
        </div>
        <pre>{window.location.pathname}</pre>
      </div>
    </div>
  );
};

export default FrontEndIssue;
