import { useState, FC, useMemo } from 'react';
import { type Descendant } from 'slate';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { useIntl } from 'react-intl';
import { Stepper } from '@cluedin/components';
import {
  PanelHeader,
  PanelClose,
  ConfirmCancelDialog,
  withConfirmDialog,
  CancelButton,
  getEmptyOrParsed,
} from '@cluedin/form';

import { blankValidation } from '../../../../helpers/validation';
import { getRulesValidationErrors } from '../../../rule/rulesValidationUtils';
import { adaptRulesToSave } from '../../../rule/utils/ruleAdapters';
import type {
  Filters,
  Filter,
  Operators,
  Condition,
} from '../../../rule/types/ruleConditionTypes';
import { type RuleObjectType } from '../../../rule/hooks/useQueryRuleObjectTypes';
import { type RuleValidationErrors } from '../../../rule/rulesValidationUtils/rulesValidationTypes';
import { useCreateCleanProject } from '../../hooks/useCreateCleanProject';
import { cleanProjectRootURL } from '../../module';
import type { Key, MutateCleanProject } from '../../types';
import SelectVocabKeysForNewCleanProject from './SelectVocabKeysForNewCleanProject';
import { ConfigureNewCleanProject } from './ConfigureNewCleanProject';

type InputEntityType = string | undefined;

type Props = {
  operators: Operators;
  initialQuery: string;
  initialColumns: Key[];
  initialTags?: string[];
  initialProviders?: string[];
  initialCondition?: Filters;
  onCloseCreate: () => void;
  entityType: InputEntityType;
  providerDefinitionIds?: string[];
  ruleObjectTypes: RuleObjectType[];
};

type StepperState = {
  active: number;
  completed: number[];
};

const defaultStepperState = {
  active: 1,
  completed: [],
};

export const getInitialRules = (
  entityTypeString: InputEntityType,
  operators: Operators,
  providerDefinitionIds: string[] = [],
  initialTags: string[] = [],
  ruleObjectTypes: RuleObjectType[],
  providers: string[] = [],
): Filters => {
  let res: Filters = {
    rules: [],
    condition: 'AND',
  };

  if (
    (!entityTypeString || entityTypeString.length === 0) &&
    providerDefinitionIds &&
    providerDefinitionIds.length === 0 &&
    providers &&
    providers.length === 0 &&
    initialTags &&
    initialTags.length === 0
  ) {
    return res;
  }

  const equalsOperator =
    (operators.find((i) => i.friendlyName === 'Equals')?.id as string) ?? '';

  const containsOperator =
    (operators.find((i) => i.friendlyName === 'Contains')?.id as string) ?? '';

  const propObjectTypeId = ruleObjectTypes.find(
    (i) => i.name === 'Property',
  )?.id;

  const propertyRules =
    entityTypeString?.length! > 0 &&
    entityTypeString?.split?.(',')?.map?.((i) => ({
      value: [i],
      type: 'string',
      field: 'EntityType',
      operator: equalsOperator,
      condition: 'OR' as Condition,
      objectTypeId: propObjectTypeId,
    }));

  if (propertyRules && propertyRules?.length > 0) {
    if (providerDefinitionIds?.length === 0 || initialTags?.length === 0) {
      res = {
        condition: 'OR',
        rules: propertyRules,
      };
    } else {
      res = {
        condition: 'AND',
        rules: [
          {
            type: 'rule',
            condition: 'OR',
            rules: propertyRules,
          },
        ],
      };
    }
  }

  if (providerDefinitionIds && providerDefinitionIds.length > 0) {
    const providerDefinitionsRules: Filter[] = providerDefinitionIds.map(
      (i) => ({
        value: [i],
        type: 'enumerable',
        operator: containsOperator,
        condition: 'OR' as Condition,
        field: 'ProviderDefinitionIds',
        objectTypeId: propObjectTypeId,
      }),
    );

    if (res.rules.length > 0) {
      res = {
        ...res,
        rules: [
          ...res.rules,
          {
            type: 'rule',
            condition: 'OR',
            rules: providerDefinitionsRules,
          },
        ],
      };
    } else {
      res = {
        condition: 'OR',
        rules: providerDefinitionsRules,
      };
    }
  }

  if (providers && providers.length > 0) {
    const providerRules: Filter[] = providers.map((i) => ({
      value: [i],
      type: 'enumerable',
      operator: containsOperator,
      condition: 'OR' as Condition,
      field: 'Providers',
      objectTypeId: propObjectTypeId,
    }));

    if (res.rules.length > 0) {
      res = {
        ...res,
        rules: [
          ...res.rules,
          {
            type: 'rule',
            condition: 'OR',
            rules: providerRules,
          },
        ],
      };
    } else {
      res = {
        condition: 'OR',
        rules: providerRules,
      };
    }
  }

  if (initialTags && initialTags.length > 0) {
    const tagRules: Filter[] = initialTags.map((i) => ({
      value: [i],
      type: 'enumerable',
      operator: containsOperator,
      condition: 'OR' as Condition,
      field: 'Tags',
      objectTypeId: propObjectTypeId,
    }));

    if (res.rules.length > 0) {
      res = {
        ...res,
        rules: [
          ...res.rules,
          {
            type: 'rule',
            condition: 'OR',
            rules: tagRules,
          },
        ],
      };
    } else {
      res = {
        condition: 'OR',
        rules: tagRules,
      };
    }
  }

  return res;
};

export const CreateCleanProjectPanel: FC<Props> = ({
  operators,
  entityType,
  initialTags,
  initialQuery,
  onCloseCreate,
  initialColumns,
  ruleObjectTypes,
  initialCondition,
  initialProviders,
  providerDefinitionIds,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const [stepperState, setStepperState] =
    useState<StepperState>(defaultStepperState);

  const { createCleanProject } = useCreateCleanProject<MutateCleanProject>();

  // Data
  const [name, setName] = useState('');
  const [query, setQuery] = useState(initialQuery);
  const [isFiltersRequired, setIsFiltersRequired] = useState(
    query.length === 0,
  );
  const [includeDataParts, setIncludeDataParts] = useState(false);

  const [filters, setFilters] = useState<Filters>(
    () =>
      initialCondition ||
      getInitialRules(
        entityType,
        operators,
        providerDefinitionIds,
        initialTags,
        ruleObjectTypes,
        initialProviders,
      ),
  );

  const [description, setDescription] = useState<Descendant[]>(
    getEmptyOrParsed(''),
  );

  const [vocabKeys, setVocabKeys] = useState<Key[]>(initialColumns);

  // Validation
  const filtersValidationErrors = useMemo<RuleValidationErrors>(
    () => getRulesValidationErrors({ rules: filters.rules, operators, intl }),
    [filters.rules, operators],
  );

  const nameValidationError = useMemo<string | undefined>(() => {
    if (!blankValidation(name)) {
      return intl.formatMessage({ id: 'module-clean-create-name-empty-error' });
    }

    if (name.length > 250) {
      return intl.formatMessage(
        { id: 'data-source-hint-enter-long-name' },
        { signsCount: 250 },
      );
    }
  }, [name]);

  // Change handlers
  const onChangeName = (newName: string) => {
    setName(() => newName);
  };

  const onChangeQuery = (q: string) => {
    setQuery(() => q);
    setIsFiltersRequired(q.length === 0);
  };

  const onChangeIncludeDataParts = (v: boolean) => {
    setIncludeDataParts(() => v);
  };

  const onChangeDescription = (newDescription: Descendant[]) => {
    setDescription(newDescription);
  };

  const finishEditConfiguration = () => {
    setStepperState(() => ({
      active: 2,
      completed: [1],
    }));
  };

  const goToFirstStep = () => {
    setStepperState((prev) => ({
      ...prev,
      active: 1,
    }));
  };

  const onCreate = async () => {
    try {
      const rules = adaptRulesToSave(filters.rules);

      const res = await createCleanProject({
        name,
        query,
        includeDataParts,
        fields: vocabKeys,
        description: JSON.stringify(description),
        condition: {
          rules,
          condition: filters.condition,
        },
      });

      cogoToast.success(
        intl.formatMessage({ id: 'module-clean-create-success' }),
        { position: 'bottom-right', hideAfter: 5 },
      );

      setName(() => '');
      setQuery(() => '');
      setIncludeDataParts(() => false);
      setFilters(() => ({
        rules: [],
        condition: 'AND',
      }));

      onCloseCreate();

      if (res?.data?.preparation.createNewCleanProject.id) {
        history.push(
          cleanProjectRootURL +
            `/${res.data.preparation.createNewCleanProject.id}`,
        );
      }
    } catch (e) {
      onCloseCreate();

      cogoToast.error(intl.formatMessage({ id: 'module-clean-create-error' }), {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  };

  const isDataChanged = !nameValidationError || filters.rules.length > 0;

  const ClosePanelButton = isDataChanged
    ? withConfirmDialog(PanelClose, ConfirmCancelDialog)
    : PanelClose;

  const CloseButtonWithConfirm = isDataChanged
    ? withConfirmDialog(CancelButton, ConfirmCancelDialog)
    : CancelButton;

  const closeBtn = (
    <CloseButtonWithConfirm
      rounded
      onClick={onCloseCreate}
      onConfirm={onCloseCreate}
      confirmTitle={intl.formatMessage({
        id: 'module-clean-create-cancel-title',
      })}
      confirmMessage={intl.formatMessage({
        id: 'module-clean-create-cancel-message',
      })}
    >
      {intl.formatMessage({ id: 'cancel' })}
    </CloseButtonWithConfirm>
  );

  return (
    <>
      <PanelHeader
        title={intl.formatMessage({ id: 'module-clean-create-new' })}
        onClose={onCloseCreate}
        CustomClose={
          <ClosePanelButton
            confirmTitle={intl.formatMessage({
              id: 'module-clean-create-cancel-title',
            })}
            confirmMessage={intl.formatMessage({
              id: 'module-clean-create-cancel-message',
            })}
            onConfirm={onCloseCreate}
            onClick={onCloseCreate}
          />
        }
      />

      <Stepper
        activeStep={stepperState.active}
        completedSteps={stepperState.completed}
        steps={[
          {
            label: intl.formatMessage({
              id: 'module-clean-create-step-configure',
            }),
          },
          {
            label: intl.formatMessage({
              id: 'module-clean-create-step-select-vocab-keys',
            }),
          },
        ]}
      />

      {stepperState.active === 1 && (
        <ConfigureNewCleanProject
          name={name}
          query={query}
          filters={filters}
          closeBtn={closeBtn}
          operators={operators}
          setFilters={setFilters}
          loadingOperators={false}
          description={description}
          onChangeName={onChangeName}
          onChangeQuery={onChangeQuery}
          includeDataParts={includeDataParts}
          onChangeDescription={onChangeDescription}
          nameValidationError={nameValidationError}
          filtersValidationErrors={filtersValidationErrors}
          finishEditConfiguration={finishEditConfiguration}
          onChangeIncludeDataParts={onChangeIncludeDataParts}
          nextDisabled={
            !!nameValidationError ||
            filtersValidationErrors.length > 0 ||
            (query.length === 0 && filters.rules.length === 0)
          }
          isFiltersRequired={isFiltersRequired}
        />
      )}
      {stepperState.active === 2 && (
        <SelectVocabKeysForNewCleanProject
          onCreate={onCreate}
          closeBtn={closeBtn}
          vocabKeys={vocabKeys}
          setVocabKeys={setVocabKeys}
          goToFirstStep={goToFirstStep}
          saveDisabled={vocabKeys.length === 0}
        />
      )}
    </>
  );
};
