import React from 'react';
import Switch from 'uxi/Input/Switch';
import { Integration } from 'uxi/Icons';
import styled from 'styled-components';

const HorizontalAppLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  & > *:nth-child(1) {
  }
  & > *:nth-child(2) {
    flex-grow: 99;
    flex-shrink: 1;
    padding: 16px;
    text-align: left;
  }
  & > *:nth-child(3) {
    padding: 16px 0;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }

  padding: 16px;
  display: flex;
  border: 1px solid #ccc;
  border-top: none;
`;

const UserProcessorConsent = ({ processor, onChange }) => (
  <HorizontalAppLayout>
    <div style={{ minWidth: '48px' }}>
      {(processor.icon && (
        <img
          style={{ width: '48px' }}
          src={processor.icon}
          alt={`${processor.displayName}'s logo'`}
        />
      )) || <Integration />}
    </div>
    <div>
      <div style={{ fontSize: '16px', paddingBottom: '10px' }}>
        {processor.displayName || processor.name}
      </div>
      <div style={{ paddingBottom: '5px' }}>{processor.address}</div>
      <div>
        <a href={processor.dataCollected}>View Data Collected</a> |
        <a href={processor.website}>Visit Website</a> |
        <a href={processor.privacyPolicy}>View privacy policy</a>
      </div>
    </div>
    <div>
      <Switch
        name={`userProcessorConsentSwitch-${
          processor.displayName || processor.name
        }`}
        defaultChecked={processor.consent}
        onChange={({ checked }) => {
          if (onChange) {
            onChange({
              consent: checked,
              processor,
            });
          }
        }}
      />
    </div>
  </HorizontalAppLayout>
);

UserProcessorConsent.displayName = 'UserProcessorConsent';

export default UserProcessorConsent;
