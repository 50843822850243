import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent } from 'uxi/Panel';
import { History } from 'uxi/Icons';

const IconWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
`;

const Explanation = styled.div`
  font-size: 18px;
  color: rbga(0, 0, 0, 1);
  text-align: center;
  font-weight: 600;
`;

const ExplanationText = styled.div`
  text-align: center;
  margin-top: 8px;
  color: #707070;
  font-size: 14px;
`;

const DefaultSessionExpired = ({ goToLogin, countDown }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <Dialog show open modal>
      <Panel>
        <PanelHeader
          title={<FormattedMessage id="module-error-expiredTitle" />}
        />
        <PanelContent style={{ padding: '32px' }}>
          <IconWrapper>
            <History color={themePrimary} size={60} />
          </IconWrapper>
          <Explanation>
            <FormattedMessage id="module-error-sessionExpiredExplanation" />
          </Explanation>
          <ExplanationText>
            <FormattedMessage
              id="module-error-sessionExpiredcountDown"
              values={{ value: countDown }}
            />
            <FormattedMessage id="module-error-sessionExpiredBringBackToCurrent" />
            <div style={{ marginTop: '24px' }}>
              <PrimaryButton
                text={<FormattedMessage id="module-error-logBackIn" />}
                onClick={goToLogin}
              />
            </div>
          </ExplanationText>
        </PanelContent>
      </Panel>
    </Dialog>
  );
};

export default DefaultSessionExpired;
