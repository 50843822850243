import React from 'react';
import styled from 'styled-components';
import { Tag } from 'uxi/Icons';

const TagWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background: #e3e9f1;
  color: #728fb7;
  font-size: 12px;
  margin-right: 6px;
  & .label {
    margin-left: 4px;
  }
  & .iconWrapper {
    display: flex;
  }
`;

const TagComp = ({ label, Icon = Tag }) => {
  return (
    <TagWrapper>
      <div className="iconWrapper">
        <Icon noPadding color="#728fb7" size={12} />
      </div>
      <div className="label">{label}</div>
    </TagWrapper>
  );
};

export default TagComp;
