import React, { Component } from 'react';
import { push, replace } from 'connected-react-router';
import { connect } from 'react-redux';

class RedirectContainer extends Component {
  static defaultProps = {
    replace: false,
  };

  componentDidMount() {
    const { useReplace, shouldRedirect, redirect, url } = this.props;

    if (shouldRedirect) {
      redirect(url, useReplace);
    }
  }
  render() {
    const { Comp, shouldRedirect, ...rest } = this.props;

    if (shouldRedirect) {
      return <div />;
    }

    return <Comp {...rest} />;
  }
}

const mapToStateProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  redirect(url, useReplace) {
    if (useReplace) {
      return dispatch(replace(url));
    }
    return dispatch(push(url));
  },
});

export default connect(mapToStateProps, mapDispatchToProps)(RedirectContainer);
