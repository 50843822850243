import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';

const createRoleRequest_MUTATION = gql`
  mutation createRoleRequest($claim: String!) {
    workflow {
      id
      createRoleRequest(claim: $claim) {
        id
      }
    }
  }
`;

export const useCreateRoleRequest = () => {
  const [createRoleRequest, { data, loading, error }] = useMutation(
    createRoleRequest_MUTATION,
  );
  const done = get(data, 'workflow.createRoleRequest');

  return [
    (claim) => createRoleRequest({ variables: { claim } }),
    {
      done,
      loading,
      error,
    },
  ];
};

const acceptRoleRequest_MUTATION = gql`
  mutation acceptRoleRequest($id: ID!, $roleName: String!) {
    workflow {
      id
      acceptRoleRequest(id: $id, roleName: $roleName) {
        id
      }
    }
  }
`;

export const useAcceptRoleRequest = () => {
  const [acceptRoleRequest, { data, loading, error }] = useMutation(
    acceptRoleRequest_MUTATION,
  );

  return [
    (id, roleName) => acceptRoleRequest({ variables: { id, roleName } }),
    {
      data,
      loading,
      error,
    },
  ];
};

const rejectRoleRequest_MUTATION = gql`
  mutation rejectRoleRequest($id: ID!, $reason: String!) {
    workflow {
      id
      rejectRoleRequest(id: $id, reason: $reason) {
        id
      }
    }
  }
`;

export const useRejectRoleRequest = () => {
  const [rejectRoleRequest, { data, loading, error }] = useMutation(
    rejectRoleRequest_MUTATION,
  );

  return [
    (id, reason) => rejectRoleRequest({ variables: { id, reason } }),
    {
      data,
      loading,
      error,
    },
  ];
};
