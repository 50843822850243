import styled from 'styled-components';

import IntegrationIconContainer from '../../../../integration/components/containers/IntegrationIconContainer';
import { isDataSet } from '../../../../dataSourceV2/helper';
import DataSetName from '../../../../dataSourceV2/components/composites/DataSetName';
import DataSetIconContainer from '../../../../dataSourceV2/components/composites/DataSetIconContainer';

import ProviderOriginalLink from './ProviderOriginalLink';

const EntitySourceWrapperLine = styled.li`
  list-style: none;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #d2d2d2;
  margin: 0 24px;
  &:last-child {
    border-bottom: none;
  }
`;

const EntitySourceName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  -ms-flex-basis: auto;
  margin-left: 10px;
`;

const EntitySourceLine = ({ integrationWithUris = {} }) => {
  if (isDataSet(integrationWithUris.name)) {
    return (
      <EntitySourceWrapperLine>
        <DataSetIconContainer
          size="l"
          providerName={integrationWithUris.name}
        />
        <EntitySourceName>
          <DataSetName providerName={integrationWithUris.name} />
        </EntitySourceName>
      </EntitySourceWrapperLine>
    );
  }

  if (!integrationWithUris.uri || integrationWithUris.uri.length === 0) {
    return (
      <EntitySourceWrapperLine>
        <IntegrationIconContainer
          iconSize="l"
          integrationName={integrationWithUris.name}
        />
        <EntitySourceName>{integrationWithUris.name}</EntitySourceName>
      </EntitySourceWrapperLine>
    );
  }

  return (
    <EntitySourceWrapperLine>
      <IntegrationIconContainer
        iconSize="l"
        integrationName={integrationWithUris.name}
      />
      <EntitySourceName>
        <ProviderOriginalLink integrationWithUris={integrationWithUris} />
      </EntitySourceName>
    </EntitySourceWrapperLine>
  );
};

EntitySourceLine.displayName = 'EntitySourceLine';

export default EntitySourceLine;
