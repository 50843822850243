import styled from 'styled-components';
import { Badge } from 'uxi/Badge';
import { ProfileAvatar } from '@cluedin/svgs';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { registerEntityWidget } from '../../registry';
import SocialMenu from '../../../../entityViewModel/components/composites/social/SocialMenu';
import PersonProfilePicture from './PersonProfilePicture';
import { createPersonViewModel } from '../../../../entityViewModel/helpers/personV2';

import {
  PersonProfileContainer,
  PersonProfileWrapper,
  PersonProfileTagWrapper,
} from './PersonProfile';
import { HintBadge } from '../../../../../shared/components/HintBadge';

const ProfileAvatarWrapper = styled.div`
  padding: 24px 0 0;

  svg path:first-of-type {
    fill: ${({ theme }) => theme?.palette?.themePrimary};
  }
`;

const PersonProfileStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: '5px 24px',
    borderBottom: '1px solid #d8d8d8',
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row wrap',
  },
  profileName: {
    textAlign: 'center',
    padding: '0 8px 8px 8px',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '0',
    wordBreak: 'break-word',
    color: 'inherit',
  },
  jobTitle: {
    color: 'inherit',
    fontSize: '14px',
    marginBottom: '0',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '0 8px 8px 8px',
  },
  createdDate: {
    textAlign: 'center',
    paddingBottom: '12px',
    fontSize: '12px',
    color: '#707070',
    textTransform: 'capitalize',
  },
  valueWithIcon: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  iconForValueWithIcon: {
    marginRight: '6px',
  },
};

const PersonProfile = ({ entity }) => {
  const theme = useTheme(entity);
  const personViewModel = createPersonViewModel(entity);
  const tags = entity?.data?.tags || [];
  const isShadowEntity = entity?.data?.isShadowEntity;

  return (
    <PersonProfileContainer>
      <PersonProfileWrapper data-test="person-profile-picture">
        {personViewModel.logo.large ? (
          <PersonProfilePicture src={personViewModel.logo.large} />
        ) : (
          <ProfileAvatarWrapper theme={theme}>
            <ProfileAvatar width={'64px'} height={'64px'} />
          </ProfileAvatarWrapper>
        )}
      </PersonProfileWrapper>
      <div
        style={PersonProfileStyle.profileName}
        data-test="entityPage-profileWidget-name"
      >
        {personViewModel.name}
      </div>
      {personViewModel.jobTitle && (
        <div style={PersonProfileStyle.jobTitle}>
          {personViewModel.jobTitle}
        </div>
      )}
      {personViewModel.createdDate ? (
        <div style={PersonProfileStyle.createdDate}>
          {personViewModel.createdDate.map((c) => (
            <>
              {c} <br />
            </>
          ))}
        </div>
      ) : null}
      <SocialMenu
        style={{ justifyContent: 'center', marginTop: '12px' }}
        socialViewModel={personViewModel.social}
      />
      {tags && tags.length > 0 && (
        <PersonProfileTagWrapper>
          {tags.map((t, idx) => (
            <div
              key={idx}
              data-test={`success-tag-${t}`}
              style={{
                display: 'inline-flex',
              }}
            >
              <Badge
                type="success"
                style={{
                  marginRight: '6px',
                  marginBottom: '6px',
                }}
              >
                {t}
              </Badge>
            </div>
          ))}
        </PersonProfileTagWrapper>
      )}
      {isShadowEntity !== 'False' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <HintBadge
            label={
              <FormattedMessage id="module-entityV2-shadow-entity-label" />
            }
            hintLabel={
              <FormattedMessage id="module-entityV2-shadow-entity-tooltip-hint" />
            }
          />
        </div>
      )}
    </PersonProfileContainer>
  );
};

registerEntityWidget('PersonProfileV2', {
  name: 'PersonProfileV2',
  displayName: 'Person Profile',
  description: "Display a person's profile",
  view: PersonProfile,
  noHeader: true,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
