"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(FormFieldHelperText) {
  return (0, _styledComponents.default)(FormFieldHelperText)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  ", "\n  text-align: left;\n  margin-top: 3px;\n  margin: 0, &.disabled {\n    color: ", ";\n  }\n  &.error {\n    color: ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.text.secondary;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.typography.caption;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.text.disabled;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.semantic.error.main;
  });
};
exports.default = _default;