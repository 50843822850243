import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

export const GET_ALL_VOCABULARY_TOTAL = gql`
  query getAllVocabularies(
    $searchName: String
    $isActive: Boolean
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: String
    $entityType: String
    $connectorId: ID
  ) {
    management {
      id
      vocabularies(
        searchName: $searchName
        isActive: $isActive
        pageNumber: $pageNumber
        pageSize: $pageSize
        sortBy: $sortBy
        sortDirection: $sortDirection
        entityType: $entityType
        connectorId: $connectorId
      ) {
        total
      }
    }
  }
`;

export const useVocabularyKpi = (initialVar = {}) => {
  const { data, error, refetch, networkStatus } = useQuery(
    GET_ALL_VOCABULARY_TOTAL,
    {
      // fetchPolicy: withCaching ? 'cache-first' : 'no-cache',
      variables: initialVar,
    },
  );

  const pagedVocabularies = get(data, 'management.vocabularies', {
    total: 0,
    data: [],
  });

  return [
    pagedVocabularies,
    networkStatus === 1,
    error,
    networkStatus === 4,
    () => {
      refetch(initialVar);
    },
  ];
};

export default {
  useVocabularyKpi,
};
