import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { ViewOriginalLi } from '../EntityMainBar.style';

const ViewOriginal = ({ uri }) => (
  <ViewOriginalLi key="orignalLink">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={uri.replace(/\+/g, '%20')}
    >
      <FormattedMessage id="viewOriginal" />
    </a>
  </ViewOriginalLi>
);

export default ViewOriginal;
