import styled from 'styled-components';

export const EntityNodePanelDetailWrapper = styled.div<{
  themePrimary: string;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & svg path {
    fill: ${({ themePrimary }) => themePrimary};
    path: ${({ themePrimary }) => themePrimary};
  }
`;
