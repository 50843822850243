import { connect } from 'react-redux';
import store from 'store';
import { compose, lifecycle } from 'recompose';
import { shouldFetchAllIntegrations } from '../../actions';

const mapToSelectProps = ({
  integration: { allIntegrations, isFetchingAllIntegrations, name },
}) => {
  const integrationId = store.get(name);

  const integration = allIntegrations.find((i) => i.Id === integrationId);

  return {
    integrationId,
    integration,
    isFetchingIntegration: isFetchingAllIntegrations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProviders: () => {
    dispatch(shouldFetchAllIntegrations());
  },
});

export const withIntegrationFromOauthLocalstorageToken = (Comp) => {
  const EnhancedComp = compose(
    lifecycle({
      componentDidMount() {
        const { fetchProviders } = this.props;
        fetchProviders();
      },
    }),
  )(Comp);

  return connect(mapToSelectProps, mapDispatchToProps)(EnhancedComp);
};

export default withIntegrationFromOauthLocalstorageToken;
