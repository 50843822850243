const entityCache = {};
const schemaEntityCache = {};

const getFromEntityCache = (id) => entityCache[id];
const addToEntityCache = (id, result) => {
  entityCache[id] = result;
};
const removeFromCache = (id) => {
  entityCache[id] = null;
};

export const cache =
  (fn) =>
  ({ id, force }) => {
    const hasCache = getFromEntityCache(id);

    if (hasCache && force) {
      removeFromCache(id);
    }

    if (hasCache && !force) {
      return new Promise((resolve) => {
        resolve(hasCache);
      });
    }

    return fn(id, force).then((result) => {
      addToEntityCache(id, result);
      return result;
    });
  };

export const schemaCache = (id, fn) => (schema, entity, force) => {
  const hasCache = schemaEntityCache[id];

  if (hasCache && force) {
    schemaEntityCache[id] = null;
  }

  if (hasCache && !force) {
    return hasCache;
  }

  return fn(schema, entity);
};

export default {
  cache,
  schemaCache,
};
