import { FC } from 'react';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';

import { GraphNode } from '../../../entityRelationsGraphV2/types';
import DetailPageHeader from '../../../core/components/composites/detail/DetailPageHeader';
import { getIcon } from '../../../entityRelationsGraphV2/containers/D3EntityRelationsGraph/getIcon';
import { RootEntityHeaderIcon } from './RootEntityHeaderIcon';
import EntityTypeAvatarConfigurationLinkContainer from '../../../entityTypes/components/containers/EntityTypeAvatarConfigurationLinkContainer';
import { RootEntityHeaderEntityTypeWrapper } from './RootEntityHeaderEntityTypeWrapper';
import { RootEntityHeaderIconWrapper } from './RootEntityHeaderIconWrapper';
import { RootEntityHeaderCaption } from './RootEntityHeaderCaption';
import PersonProfilePicture from '../../../entityWidget/widgets/person/personProfileV2/PersonProfilePicture';
import { createPersonViewModel } from '../../../entityViewModel/helpers/personV2';
import { Entity } from '../../../entityRelationsGraphV2/containers/types';

type RootEntityHeaderProps = {
  rootEntity: GraphNode | undefined;
  entity: Entity;
};

const ProfilePictureWrapper = styled.div`
  margin: 0 !important;

  & figure {
    margin: 0 !important;
  }
`;

export const RootEntityHeader: FC<RootEntityHeaderProps> = ({
  rootEntity,
  entity,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const originCode = entity?.data?.['attribute-origin'];
  const icon: HTMLImageElement | null = getIcon(rootEntity?.icon);
  const personViewModel = createPersonViewModel(entity);

  let iconToUse;
  if (icon instanceof HTMLImageElement) {
    const imgSrc = icon?.src;
    iconToUse = (
      <RootEntityHeaderIconWrapper themePrimary={themePrimary}>
        <RootEntityHeaderIcon
          src={imgSrc}
          alt={rootEntity?.entityType || ''}
          title={rootEntity?.entityType || ''}
        />
      </RootEntityHeaderIconWrapper>
    );

    if (personViewModel?.logo?.large) {
      iconToUse = (
        <ProfilePictureWrapper>
          <PersonProfilePicture src={personViewModel?.logo?.large} />
        </ProfilePictureWrapper>
      );
    }
  } else {
    const imgSrcRegex = /<img src="([^"]+)">/;
    const match = (icon as unknown as HTMLElement)?.outerHTML.match(
      imgSrcRegex,
    );

    if (match && match[1]) {
      iconToUse = match[1];
    } else {
      iconToUse = null;
    }
  }

  return (
    <DetailPageHeader
      icon={iconToUse}
      title={rootEntity?.label ?? rootEntity?.title}
      caption={
        <RootEntityHeaderCaption>
          <div style={{ display: 'flex' }}>
            {originCode ? (
              <>
                <FormattedMessage id="module-entityRelationsGraphTreeview-rootEntityHeaderOriginCodeLabel" />
                :&nbsp;
                <div>{originCode}</div>
              </>
            ) : (
              <>
                <FormattedMessage id="module-entityRelationsGraphTreeview-rootEntityHeaderIdLabel" />
                :&nbsp;
                <div style={{ textTransform: 'capitalize' }}>
                  {rootEntity?.id}
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <FormattedMessage id="module-entityRelationsGraphTreeview-rootEntityHeaderShadowEntityLabel" />
            :&nbsp;
            <div style={{ textTransform: 'capitalize' }}>
              {rootEntity?.isShadowEntity?.toString()}
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <FormattedMessage id="module-entityRelationsGraphTreeview-rootEntityHeaderTemporalEntityLabel" />
            :&nbsp;
            <div style={{ textTransform: 'capitalize' }}>
              {rootEntity?.isTemporalEntity?.toString()}
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <FormattedMessage id="module-entityRelationsGraphTreeview-rootEntityHeaderEntityTypeLabel" />
            :&nbsp;
            <RootEntityHeaderEntityTypeWrapper>
              <EntityTypeAvatarConfigurationLinkContainer
                entityType={rootEntity?.entityType}
                entity={null}
              />
            </RootEntityHeaderEntityTypeWrapper>
          </div>
        </RootEntityHeaderCaption>
      }
      actions={null}
    />
  );
};
