import React from 'react';
import PropTypes from 'prop-types';
import { ListProperty } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

import ListValue from './ListValue/ListValue';

export const columns = [
  {
    property: 'property',
    displayName: (
      <FormattedMessage id="module-entityTopology-recordPanelProperty" />
    ),
    Component: ListProperty,
  },
  {
    property: 'value',
    displayName: (
      <FormattedMessage id="module-entityTopology-recordPanelValue" />
    ),
    Component: (props) => <ListValue {...props} />,
  },
];
