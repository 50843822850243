import { FC, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as z from 'zod';
import cogoToast from 'cogo-toast';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Select,
  PanelFooter,
  PanelContent,
  CancelButton,
  PrimaryButton,
  EmptyValue,
  FormGenerator,
  useFormGeneratorWithReactHook,
} from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';

import { useQueryManualDataEntry } from '../../../hooks/useQueryManualDataEntryProjects';
import { useCreateRecordManualDataEntryProject } from '../../../hooks/useCreateRecordManualDataEntryProject';

import { checkEmptyValues } from '../AddRecord/checkEmptyValues';
import { ErrorEmpty } from '../AddRecord/ErrorEmpty';
import AddRecordInput from '../../pages/AddRecord/AddRecordInput';
import { createDataToSend } from '../../pages/AddRecord/createDataToSend';
import { FieldAlert } from '../../../../../shared/components/FieldAlert';

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 6px;
  color: ${(props) => props.color};
`;

type ManualClueCreationProps = {
  onClose: () => void;
};

type FormField = {
  id: string;
  label: string;
  description: string;
  vocabularyKey: string;
  type: string;
  isRequired: boolean;
  onlyExistingValues: boolean;
  onlyAllowedValues: boolean;
  preventForbiddenValues: boolean;
  isArchived: boolean;
};

type ManualDataEntryProject = {
  id: string;
  title: string;
  description: string;
  createdAt: Date;
  createdBy: string;
  formFields: FormField[];
};

interface SelectValue {
  value: string;
  label: string;
}
const manualDataEntryURL = '/admin/inbound/manual-data-entry';
const ManualClueCreation: FC<ManualClueCreationProps> = ({ onClose }) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const { formatMessage } = useIntl();
  const [value, setValue] = useState<SelectValue | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const [createRecords, { loading }] = useCreateRecordManualDataEntryProject();
  const { data }: { data: ManualDataEntryProject[] | undefined } =
    useQueryManualDataEntry({
      page: 0,
      pageSize: 0,
    });
  const [errorGeneratingOrPreviewing, setErrorOnClick] = useState('');

  const manualDataEntryOptions = data
    ?.filter(
      ({ formFields = [] }) =>
        formFields.length !== 0 &&
        formFields.filter((formField) => !formField.isArchived).length !== 0,
    )
    ?.map((manualDataEntry: ManualDataEntryProject) => ({
      value: manualDataEntry.id,
      label: manualDataEntry.title,
    }));

  const defaultValues = {};
  const schema = {};

  const formFields: FormField[] | undefined =
    value?.value !== undefined
      ? data?.find(
          (manualDataEntry: ManualDataEntryProject) =>
            manualDataEntry.id === value?.value,
        )?.formFields
      : [];

  for (const [, value] of Object.entries(formFields ?? {})) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    schema[value.id] =
      value.type === 'pickList' && value.isRequired
        ? z.object({ value: z.string(), label: z.string() })
        : value.type === 'toggle'
        ? z.any()
        : value.isRequired
        ? z
            .string()
            .min(1, { message: 'Required field' })
            .or(z.object({ value: z.string(), label: z.string() }).required())
        : z.any();
  }

  const dynamicFields = useMemo(() => {
    return formFields
      ?.filter?.((formF) => !formF.isArchived)
      ?.map((formField: FormField) => {
        let richTextMediaValue;
        try {
          richTextMediaValue = JSON.parse(formField.description);
        } catch {
          richTextMediaValue = EmptyValue;
        }

        return {
          name: formField.id,
          displayName: formField.label,
          isRequired: formField.isRequired,
          richHelpText: richTextMediaValue,
          Input: ({
            onChange,
            value,
            ...props
          }: {
            onChange: () => void;
            value: SelectValue | boolean | string;
          }) => {
            return (
              <AddRecordInput
                formField={formField}
                onChange={onChange}
                value={value}
                {...props}
              />
            );
          },
        };
      });
  }, [data, value]);

  const { fields, control, errors, touched, handleSubmit } =
    useFormGeneratorWithReactHook(dynamicFields, defaultValues, {
      resolver: zodResolver(z.object(schema)),
    });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const onSumbit = handleSubmit(async (submitData) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      if (checkEmptyValues(submitData)) {
        setErrorOnClick(
          formatMessage({
            id: 'module-manualDataEntry-error-generate',
          }),
        );
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      const dataToSend = createDataToSend({ data: submitData, formFields });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      await createRecords({
        projectId: data?.find(
          (manualDataEntry: ManualDataEntryProject) =>
            manualDataEntry.id === value?.value,
        )?.id,
        records: [dataToSend],
      });

      cogoToast.success(
        <span>
          {formatMessage({
            id: 'module-manualDataEntry-recordCreated',
          })}
        </span>,
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
      onClose();
    } catch (e) {
      cogoToast.error(
        <span>
          {formatMessage({
            id: 'module-manualDataEntry-errorRecord',
          })}
        </span>,
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    }
  });

  const noOptionsAvailable =
    !manualDataEntryOptions || manualDataEntryOptions?.length === 0;
  return (
    <>
      <PanelContent>
        {errorGeneratingOrPreviewing && (
          <ErrorEmpty errorText={errorGeneratingOrPreviewing} />
        )}
        <>
          {noOptionsAvailable ? (
            <>
              <FieldAlert
                message={
                  <FormattedMessage id="module-manualDataEntry-panelWarning" />
                }
                type="warning"
              />
              <StyledLink
                rel="noreferrer noopener"
                to={manualDataEntryURL}
                color={themePrimary}
              >
                <FormattedMessage id="module-manualDataEntry-viewAllAvailableProjects" />
              </StyledLink>
            </>
          ) : (
            <>
              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                <FormattedMessage id="module-user-nameLabel" />
                <span style={{ color: 'rgb(241, 106, 106)' }}>*</span>
              </span>
              <div style={{ marginTop: '4px', marginBottom: '6px' }}>
                <span style={{ color: 'rgb(155, 155, 156)', fontSize: '14px' }}>
                  <FormattedMessage id="module-manualDataEntry-selectList" />
                </span>
              </div>
              <div style={{ marginTop: '6px' }}>
                <Select
                  value={value}
                  onChange={(option: SelectValue) => {
                    setValue(option);
                  }}
                  options={manualDataEntryOptions}
                  disabled={noOptionsAvailable}
                />
              </div>
            </>
          )}
          <div style={{ marginTop: '8px' }}>
            {value?.value && formFields && (
              <FormGenerator
                fields={fields}
                control={control}
                errors={errors}
                touched={touched}
              />
            )}
          </div>
        </>
      </PanelContent>

      <PanelFooter>
        <div style={{ textAlign: 'end' }}>
          <CancelButton
            rounded
            style={{ marginRight: '8px' }}
            onClick={onClose}
            disabled={loading}
          >
            <FormattedMessage id="data-source-cancel" />
          </CancelButton>
          <PrimaryButton
            rounded
            disabled={value === undefined || loading}
            className="panel-main-action"
            onClick={onSumbit}
          >
            <FormattedMessage id="module-manualDataEntry-add-record" />
          </PrimaryButton>
        </div>
      </PanelFooter>
    </>
  );
};

export default ManualClueCreation;
