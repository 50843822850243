/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import superAgentPromise from 'superagent-promise';
import superAgent from 'superagent';
import { getCurrentToken, urlBuilder } from '../config';

const agent = superAgentPromise(superAgent, Promise);

const defaultTimeout = {
  response: 45000, // Wait 45 seconds for the server to start sending,
  deadline: 75000, // but allow 75 sec for the file to finish loading.
};

const getDefaultHeaders = (token, extraHeaders = {}) => {
  const bearer = token || getCurrentToken();
  const AuthorizationValue = bearer ? `Bearer ${bearer}` : null;

  const defaultMainHeaders = {
    Accept: 'application/json',
  };

  return AuthorizationValue
    ? {
        ...defaultMainHeaders,
        Authorization: AuthorizationValue,
        ...extraHeaders,
      }
    : defaultMainHeaders;
};

/**
 * Super HACK PLEASE REMOVE ME!
 */
export const apiRequestForSARBatch = (method, url, data, token) => {
  return agent
    .post(urlBuilder.api(url))
    .timeout(defaultTimeout)
    .set(getDefaultHeaders(token))
    .attach('photo', data)
    .then(({ body }) => body)
    .then((resp) => {
      const apiResponse = resp;
      // if (apiResponse) {
      //   apiResponse.__token = getCurrentToken();
      // }
      // console.log('apiResponse', apiResponse);
      return apiResponse;
    });
};

export default {
  apiRequestForSARBatch,
};
