import { connect } from 'react-redux';
import { shouldOpenInviteUserDialog } from '../../actions';
import InviteUserButton from './InviteUserButton';

const mapToStateProps = ({
  user: { currentUser },
  publicModule: { isUsingSSO } = {},
}) => ({
  currentUser,
  isUsingSSO,
});

const mapToDispatchProps = (dispatch) => ({
  shouldOpenInviteUserDialog: () => {
    dispatch(shouldOpenInviteUserDialog());
  },
});

export default connect(mapToStateProps, mapToDispatchProps)(InviteUserButton);
