import React from 'react';
import Tooltip from 'rc-tooltip';
import FirstLetterComponent from '../../../core/components/composites/FirstLetterComponent';
import UserUnknown from './UserUnknown';

const UserBadge = ({ user }) => {
  return (
    <span>
      {user && (user.username || user.userName) ? (
        <div style={{ display: 'flex', cursor: 'pointer' }}>
          <Tooltip
            placement="top"
            trigger="hover"
            overlay={user.username || user.userName}
          >
            <div style={{ display: 'flex' }}>
              <FirstLetterComponent
                name={user.username || user.userName}
                size="s"
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <UserUnknown author={(user || {}).username || (user || {}).userName} />
      )}
    </span>
  );
};

export default UserBadge;
