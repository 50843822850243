import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { Flex } from 'uxi/Layout';
import { Spinner } from '@cluedin/form';
import { usePaging } from '../../core/hooks';
import { legacyClient } from '../../../data/graphql';
import { createWidgetViewModelFromGql } from '../../entityViewModel/helpers/widgetResult';

export const withGqlQueryList = (Comp) => (props) => {
  if (!props.gql || !props.gql.query) {
    return <Comp {...props} />;
  }
  let page = 0;
  const vars = props.gql.variables || {};

  const itemsPerPage = _.get(props, 'gql.pagination.itemsPerPage');
  const pagerName = _.get(props, 'gql.pagination.pagerName');
  const { history, location } = props;
  const [cursor, currentPage, setCurrentPage] = usePaging(
    history,
    location,
    itemsPerPage,
    pagerName,
  );

  return (
    <Query
      client={legacyClient}
      query={gql(props.gql.query)}
      variables={{
        ...vars,
        page,
        cursor,
      }}
    >
      {({ loading, error, fetchMore, data = {} }) => {
        let dataWithPath;

        if (loading) {
          return (
            <Flex>
              <Spinner />
            </Flex>
          );
        }

        if (error) {
          console.error('Invalid Gql Query pass to component');
          console.error(error);
        }

        if (!error && !loading && data) {
          try {
            if (props.gql.valueResolver) {
              const resolver = props.gql.valueResolver;
              dataWithPath = resolver(data, props);
            }

            if (props.gql.listPath) {
              dataWithPath = _.get(
                data,
                props.gql.listPath,
                props.gql.defaultValue || [],
              );
            }
          } catch (e) {
            dataWithPath = [];
          }

          if (props.gql.entityPath) {
            dataWithPath = dataWithPath.map((d) => {
              return _.get(d, props.gql.entityPath, {});
            });
          }
        }

        if (dataWithPath && dataWithPath.length > 0) {
          dataWithPath = dataWithPath.map((entity) => {
            return createWidgetViewModelFromGql(entity);
          });
        }

        return (
          <Comp
            {...props}
            data={dataWithPath}
            error={error}
            loadMore={() => {
              page += 1;
              fetchMore({
                variables: {
                  ...(props.gql.variables || {}),
                  cursor: data.cursor || null,
                },
              });
            }}
            pagerProps={{
              ...currentPage,
              totalItems: _.get(
                data,
                _.get(props, 'gql.pagination.totalItemsPath'),
              ),
              onPageChange: (pageNo) => setCurrentPage(pageNo),
            }}
          />
        );
      }}
    </Query>
  );
};

export default {
  withGqlQueryList,
};
