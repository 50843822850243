import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import ConfigurationOverviewPage from './ConfigurationOverviewPage';
import EditConfigurationPage from '../../../integration/components/pages/EditConfigurationPage';
import IntegrationPageWrapperContainer from '../containers/structure/IntegrationPageWrapperContainer';
import ConfigurationLoadingStatusContainer from '../containers/ConfigurationLoadingStatusContainer';
import PageLoader from '../../../core/components/composites/PageLoader';
import IntegrationConfigurationAuthSettings from '../containers/IntegrationConfigurationAuthSettings';

import { shouldFetchIntegrationById } from '../../../integration/actions';
import { shouldFetchConfigurationById } from '../../actions';
import Permissions from '../../../connector/components/containers/ConfiguredConnectorDetailContainer/Permissions';
import { useQueryConfiguredConnectorById } from '../../../connector/hooks/useQueryConfiguredConnectorById';

const ConfigurationPageWrapper = ({
  selectedIntegration,
  location,
  selectedConfiguration,
  match: {
    url,
    params: { integrationId, configurationId },
  },
}) => {
  const [connector, loadingConfig, errorConfig, refetch] =
    useQueryConfiguredConnectorById(configurationId);

  return (
    <IntegrationPageWrapperContainer
      integration={selectedIntegration}
      integrationId={integrationId}
      configurationId={configurationId}
      configuration={selectedConfiguration}
      pathname={location.pathname}
    >
      <Route
        path={`${url}/`}
        exact
        render={(props) => (
          <ConfigurationOverviewPage
            integrationId={integrationId}
            configurationId={configurationId}
            {...props}
          />
        )}
      />
      <Route
        path={`${url}/permissions`}
        render={(props) => (
          <div style={{ margin: '-15px -30px' }}>
            <Permissions
              connector={connector}
              refetch={refetch}
              offsetTop={200}
              loading={loadingConfig}
              notFoundProps={{
                buttonLabel: (
                  <FormattedMessage id="module-configuration-permissions-noUsers" />
                ),
                message: (
                  <FormattedMessage id="module-configuration-permissions-addUserTitle" />
                ),
                explanation: (
                  <FormattedMessage id="module-configuration-permissions-addUsersBtn" />
                ),
              }}
            />
          </div>
        )}
      />
      <Route
        path={`${url}/settings`}
        render={(props) => (
          <EditConfigurationPage
            integrationId={integrationId}
            configurationId={configurationId}
            {...props}
          />
        )}
      />
      <Route
        path={`${url}/auth-settings`}
        render={(props) => (
          <IntegrationConfigurationAuthSettings
            integrationId={integrationId}
            configurationId={configurationId}
            integration={selectedIntegration}
            configuration={selectedConfiguration}
            {...props}
          />
        )}
      />
    </IntegrationPageWrapperContainer>
  );
};

const EnhancedConfigurationPageWrapper = compose(
  lifecycle({
    componentDidMount() {
      const { shouldFetchData } = this.props;
      shouldFetchData();
    },
  }),
  branch(
    ({ isFetching }) => isFetching,
    renderComponent(ConfigurationLoadingStatusContainer),
  ),
  branch(
    ({ isFetchingSelectedIntegration }) => isFetchingSelectedIntegration,
    renderComponent(PageLoader),
  ),
)(ConfigurationPageWrapper);

const mapToStateProps = (
  {
    integration: { isFetchingSelectedIntegration, selectedIntegration },
    configuration: { selectedConfiguration, isFetchingSelectedConfiguration },
  },
  {
    match: {
      params: { configurationId },
    },
  },
) => ({
  selectedIntegration,
  selectedConfiguration,
  configurationId,
  isFetchingSelectedIntegration,
  isFetching: isFetchingSelectedConfiguration,
});

const mapToDispatchProps = (
  dispatch,
  {
    match: {
      params: { integrationId, configurationId },
    },
  },
) => ({
  shouldFetchData() {
    dispatch(shouldFetchIntegrationById(integrationId));
    dispatch(shouldFetchConfigurationById(configurationId));
  },
});

export default connect(
  mapToStateProps,
  mapToDispatchProps,
)(EnhancedConfigurationPageWrapper);
