import React from 'react';
import { actionsConfiguration, RACI_LEVELS } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

const { management } = actionsConfiguration;

export default {
  pillar: 'management',
  name: 'catalog',
  displayName: <FormattedMessage id="catalog" />,
  path: '/catalog',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Patterns',
        name: <FormattedMessage id="catalog" />,
        link: '/admin/management/catalog',
        claims: management.dataCatalog.catalog.view,
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"DashboardDataCatalog" */
            './components/pages/DashboardDataCatalog'
          ),
        ),
      ),
    },
    {
      path: '/all-vocabulary',
      exact: true,
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"VocabularyListPage" */
            './components/pages/VocabularyListPage'
          ),
        ),
      ),
    },
    {
      path: '/all-vocabulary-keys',
      exact: true,
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"VocabularyKeyListPage" */
            './components/pages/VocabularyKeyListPage'
          ),
        ),
      ),
    },
    {
      path: '/detail/:id',
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"VocabularyDetailPage" */
            './components/pages/VocabularyDetailPage'
          ),
        ),
      ),
    },
    {
      path: '/vocab-key',
      claims: {
        'management.datacatalog': RACI_LEVELS.INFORMED,
      },
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"VocabularyKeyDetailPage" */
            './components/pages/VocabularyKeyDetailPage'
          ),
        ),
      ),
    },
    // {
    //   path: '/batch-map',
    //   claims: {
    //     'management.datacatalog': RACI_LEVELS.INFORMED,
    //   },
    //   component: OnlyClientIdSubDomain(makeLazy(() => import(
    //     /* webpackChunkName:"VocabularyBatchMappingPage" */
    //     './components/pages/VocabularyBatchMappingPage'
    //   ))),
    // },
  ],
};
