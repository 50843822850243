export const getIndexOfFirstRecord = (page, pageSize) => {
  if (!page || !pageSize) {
    return undefined;
  }

  if (page < 2) {
    return 0;
  }

  return (page - 1) * pageSize;
};
