import React from 'react';
import { TableRow, TableRowColumn, TableBody } from '@cluedin/list-pattern';
import Table from './TableExtended';
import Section from './Section';
import ValueWrapper from './ValueWrapper';

const PersonnalIdentifiers = ({ jsonReport }) => {
  const { Identifiers } = jsonReport;

  const values =
    (Identifiers &&
      Object.keys(Identifiers).map((key) => ({
        identifier: key,
        count: Identifiers[key],
      }))) ||
    [];

  return (
    <Section jsonReport={jsonReport}>
      <h2>Personal Identifiers</h2>
      {values &&
        values.length > 0 &&
        values.map(({ identifier, count }) => (
          <div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableRowColumn width="30%">{identifier}: </TableRowColumn>
                  <TableRowColumn width="70%">
                    <ValueWrapper>{count}</ValueWrapper>
                  </TableRowColumn>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ))}
    </Section>
  );
};

export default PersonnalIdentifiers;
