import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from '@cluedin/locale';
import { Link } from 'react-router-dom';
import { HighlightTextFromContext } from '@cluedin/components';
import { Externallink } from 'uxi/Icons';
import { useId } from '@fluentui/react-hooks';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import { VocabNotFound } from './EntityListProperty.styles';
import EntityListPropertyTooltip from './EntityListPropertyTooltip';
import VocabularyFullKey from '../../../../../dataCatalog/components/composites/VocabularyFullKey';
import WarningImage from '../../../../../core/components/composites/WarningImage';

const EntityListProperty = ({
  intl,
  vocabularyKey,
  entityVocabularyKey,
  hidePopover,
  dataHasObsoleteKeys,
}) => {
  const tooltipId = useId(vocabularyKey?.key);

  const [delayHandler, setDelayHandler] = useState(null);
  const [showPopover, setPopoverVisbilitiy] = useState(false);

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setPopoverVisbilitiy(true);
      }, 500),
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    setPopoverVisbilitiy(false);
  };

  const schema = {
    DisplayName: vocabularyKey?.displayName,
    IsCoreVocab: vocabularyKey?.isCluedInCore,
    DataType: vocabularyKey?.dataType,
    Description: vocabularyKey?.description,
    Key: vocabularyKey?.key,
  };

  const getTo = (key = vocabularyKey) =>
    `/admin/management/catalog/vocab-key?key=${encodeURIComponent(key)}`;

  return (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      {vocabularyKey?.isObsolete && (
        <div style={{ height: 16 }}>
          <TooltipHost
            id={tooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={
              <>
                {intl.formatMessage({
                  id: vocabularyKey?.mappedKey
                    ? 'module-dataCatalog-key-is-obsolete'
                    : 'module-dataCatalog-key-is-obsolete-plain',
                })}

                {vocabularyKey?.mappedKey && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={getTo(vocabularyKey?.mappedKey?.Key)}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <span style={{ marginRight: 5 }}>
                      {vocabularyKey?.mappedKey?.Key}
                    </span>

                    <Externallink size={8} />
                  </Link>
                )}
              </>
            }
          >
            <WarningImage size={16} />
          </TooltipHost>
        </div>
      )}

      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          lineHeight: '16px',
          position: 'relative',
          paddingLeft:
            !vocabularyKey?.isObsolete && dataHasObsoleteKeys && '24px',
        }}
      >
        {vocabularyKey && vocabularyKey?.key ? (
          <>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              to={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
                vocabularyKey?.key,
              )}`}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              {vocabularyKey.displayName && (
                <HighlightTextFromContext text={vocabularyKey?.displayName} />
              )}
              <div style={{ marginLeft: '6px' }}>
                <Externallink size={12} />
              </div>
            </Link>

            <div>
              <VocabularyFullKey vocabKey={vocabularyKey?.key} />
            </div>
          </>
        ) : (
          <>
            {entityVocabularyKey}
            <VocabNotFound>
              {intl.formatMessage({
                id: 'module-entityProperties-listPropertyVocabKeyNotFound',
              })}
            </VocabNotFound>
          </>
        )}

        {showPopover && !hidePopover && (
          <div
            style={{
              top: '20px',
              position: 'absolute',
              zIndex: '999',
            }}
          >
            <EntityListPropertyTooltip schema={schema} />
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(EntityListProperty);

EntityListProperty.propTypes = {
  entityVocabularyKey: PropTypes.string,
  vocabularyKey: PropTypes.object,
  dataHasObsoleteKeys: PropTypes.bool,
};

EntityListProperty.defaultProps = {
  entityVocabularyKey: '',
  vocabularyKey: {},
  dataHasObsoleteKeys: false,
};
