import { fetchAllUserInvitations, resendInvite, removeInvite } from './data';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
  noBodyResponseAsyncCallWithActions,
} from '../../action/helpers/actionCreator';
import { shouldShowAlert } from '../core/actions';

export const shouldFetchAllUserInvitationsActions = createAsyncAction(
  'SHOULD_FETCH_ALL_USER_INVITATIONS',
);
export const shouldFetchAllUserInvitations = defaultAsyncCallWithActions(
  fetchAllUserInvitations,
  shouldFetchAllUserInvitationsActions,
);

export const shouldResendInviteActions = createAsyncAction(
  'SHOULD_RESEND_INVITE',
);
export const shouldResendInvite = noBodyResponseAsyncCallWithActions(
  resendInvite,
  shouldResendInviteActions,
  (dispatch) => {
    dispatch(
      shouldShowAlert({
        type: 'success',
        title: 'Invitation resent',
        description: 'Successfully resent invitation.',
      }),
    );
  },
);

export const shouldRemoveInviteActions = createAsyncAction(
  'SHOULD_REMOVE_INVITE',
);
export const shouldRemoveInvite = noBodyResponseAsyncCallWithActions(
  removeInvite,
  shouldRemoveInviteActions,
);
