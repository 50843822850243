import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentWithExtraUI = styled.section`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex: 1;
  flex-flow: ${({ isAfter }) => (isAfter ? 'row wrap' : 'row wrap')};
`;

const ContentUI = styled.div`
  flex: 1;
  min-width: ${({ contentMinWidth }) => contentMinWidth};
  max-width: 100%;
  width: auto;
  flex-grow: 1;
  flex-shrink: 0;
`;

const ExtraUI = styled.div`
  min-width: 100%;
  width: ${({ contentMinWidth }) => contentMinWidth};
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  ${({ extraMinWidth }) =>
    extraMinWidth ? `min-width: ${extraMinWidth};` : ''}
`;

export const ContentWithExtra = memo(
  ({
    extraPosition,
    extra,
    children,
    extraMinWidth,
    contentMinWidth,
    contentStyle,
    extraMaxWidth,
    style = {},
  }) => {
    const contentItems = [
      <ExtraUI
        key="extra"
        extraMinWidth={extraMinWidth}
        style={{
          flexGrow: 1,
          flexShrink: 999999999,
          position: 'relative',
          background: 'white',
          borderRight: '1px solid #cecece',
          maxHeight: 'calc(100vh - 48px)',
          width: 'auto',
          maxWidth: extraMaxWidth || '360px',
          overflow: 'hidden',
          transform: 'translate(0, 0)',
        }}
      >
        {extra}
      </ExtraUI>,

      <ContentUI
        key="content"
        contentMinWidth={contentMinWidth}
        className="actualMainScrollingElem"
        style={{
          flexGrow: 999999999,
          flexShrink: 0,
          maxHeight: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          ...style,
        }}
      >
        {children}
      </ContentUI>,
    ];

    return (
      <ContentWithExtraUI
        isAfter={extraPosition === 'after'}
        extraPosition={extraPosition}
        style={{
          width: '100%',
          maxHeight: 'calc(100vh - 48px)',
          position: 'relative',
          flexFlow: 'row nowrap',
          overflow: 'hidden',
          ...contentStyle,
        }}
      >
        {extraPosition === 'after' ? contentItems.reverse() : contentItems}
      </ContentWithExtraUI>
    );
  },
);

ContentWithExtra.displayName = 'ContentWithExtra';

ContentWithExtra.defaultProps = {
  extraPosition: 'before',
  extraMinWidth: '200px',
  contentStyle: {},
};

ContentWithExtra.propTypes = {
  extraPosition: PropTypes.oneOf(['before', 'after']),
};

export default ContentWithExtra;
