import { connect } from 'react-redux';

import UXISCThemeProvider from 'uxi/Theme/ThemeProvider';

const mapStateToProps = ({ org: { organization } = {} }) => ({
  palette: {
    primary: (organization || {}).PrimaryColor,
    secondary: (organization || {}).SecondaryColor,
  },
});

export default connect(mapStateToProps)(UXISCThemeProvider);
