import React, { useMemo } from 'react';
import { Button, Select } from '@cluedin/form';
import AutoComplete from 'uxi/Input/AutoComplete';
import Checkmark from 'uxi/Icons/Checkmark';
import Iteration from 'uxi/Icons/Iteration';
import styled from 'styled-components';
import { withSchemaValue } from '../Hocs/withSchemaValue';
import TextPlaceholder from '../../../core/components/composites/Placeholder/TextPlaceholder';

const InputsWrapper = styled.div`
  width: 350px;
`;

const ValueAutoCompleteFromSchema = ({
  isFetching,
  values,
  value: AutocompleteInputValue,
  isSynced,
  onChange,
  setInputValue,
  isNewVersion,
}) => {
  const options = useMemo(() => {
    const isDynamicField =
      AutocompleteInputValue !== '' &&
      AutocompleteInputValue !== null &&
      !values?.find((item) => item.term === AutocompleteInputValue);
    if (isDynamicField) {
      return [
        ...(values?.map((item) => ({
          label: item.term,
          value: item.term,
        })) ?? []),
        { value: AutocompleteInputValue, label: AutocompleteInputValue },
      ];
    }
    return (
      values?.map((item) => ({
        label: item.term,
        value: item.term,
      })) ?? []
    );
  }, [AutocompleteInputValue, values]);

  return isNewVersion ? (
    <InputsWrapper>
      <Select
        options={options}
        value={AutocompleteInputValue}
        onChange={(val) => {
          if (val === null) {
            setInputValue(val);
          }
          const { value = '' } = val;
          setInputValue(value);
        }}
        disabled={isFetching}
        isSearchable={true}
        isCreatable
        isClearable
      />
    </InputsWrapper>
  ) : isFetching && !AutocompleteInputValue ? (
    <TextPlaceholder height={'32px'} />
  ) : (
    <InputsWrapper>
      <AutoComplete
        filterOn="term"
        value={AutocompleteInputValue}
        onChange={({ value }) => {
          setInputValue(value);
        }}
        onSubmit={({ value /* , originalValue */ }) => {
          onChange(value);
        }}
        items={values}
      />
      <Button
        startIcon={isSynced ? <Checkmark size={16} /> : <Iteration size={16} />}
        onClick={() => {
          onChange(AutocompleteInputValue);
        }}
      />
    </InputsWrapper>
  );
};

export default withSchemaValue(ValueAutoCompleteFromSchema);
