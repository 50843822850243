import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { RuleIcon } from '@cluedin/svgs';

const RuleLogoWrapper = styled.div`
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  & svg * {
    fill: ${({ color }) => color} !important;
  }
`;

const RuleIconWrapper = ({ color, size = 14 }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <RuleLogoWrapper color={color ? color : themePrimary}>
      <RuleIcon style={{ height: `${size}px`, width: `${size}px` }} />
    </RuleLogoWrapper>
  );
};

export default RuleIconWrapper;
