import React, { useEffect } from 'react';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { isString } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { shouldShowAlert } from '../../../core/actions';
import Alert from 'uxi/Alert';
import MainWidgetForPage from '../../../core/components/composites/MainWidgetForPage';
import DefaultAlert from '../../../core/components/composites/alerts/DefaultAlert';
import AuthMethodForm from './forms/AuthMethodForm';
import NewConfigurationStepper from '../composites/forms/NewConfigurationStepper';
import ConfigurationForm from './forms/ConfigurationForm';

import {
  shouldAddActiveProvider,
  shouldAddOauthActiveProvider,
  shouldEnableProvider,
  shouldEnableProviderReset,
} from '../../actions';
import Queued from '../../../error/components/pages/Queued';
import { getInitialValueFromConfiguration } from '../../converter';
import {
  removeFirstCharacter,
  removeLastCharacter,
} from '../../../core/helpers/string';

const NewConfigurationContainer = ({
  invalidSaving,
  integration,
  onAuthenticate,
  step,
  enableProvider,
  onOauthAuthenticate,
  isSaving,
  initialValues,
  configuration,
  isEnabling,
  guide,
  resp,
}) => {
  let errorText;

  if (resp && isString(resp)) {
    errorText = resp;
    if (errorText && errorText[0] === '"') {
      errorText = removeFirstCharacter(errorText);
    }
    if (errorText && errorText[errorText.length - 1] === '"') {
      errorText = removeLastCharacter(errorText);
    }
  }

  return (
    <MainWidgetForPage>
      <div style={{ padding: '15px' }}>
        {invalidSaving && !resp && <DefaultAlert />}
        {errorText && <Alert type="danger">{errorText}</Alert>}
        <NewConfigurationStepper
          integration={integration}
          activeStep={step + 1}
        />
        {step === 0 && (
          <AuthMethodForm
            isSaving={isSaving}
            integrationName={integration.Name}
            authMethods={integration.AuthMethods}
            onAuthenticate={onAuthenticate}
            onOauthAuthenticate={onOauthAuthenticate}
            guide={guide}
          />
        )}

        {step === 1 && (
          <ConfigurationForm
            isSaving={isEnabling}
            integrationName={integration.Name}
            hasExtra={
              configuration ? configuration.SupportsConfiguration : false
            }
            helperConfiguration={
              configuration ? configuration.helperConfiguration : {}
            }
            config={integration ? integration.Properties || [] : []}
            initialValues={initialValues}
            onEnable={(updatedConfig) => {
              enableProvider(configuration.Id, updatedConfig);
            }}
          />
        )}
      </div>
    </MainWidgetForPage>
  );
};

const EnhanceNewConfigurationContainer = compose(
  lifecycle({
    componentWillReceiveProps(nextProp) {
      /* const {
        integration,
        enableProvider,
        configuration,
      } = this.props; */

      /* if (
        nextProp.step === 1 &&
        (
          !integration.Properties ||
          integration.Properties.length === 0
        ) &&
        !nextProp.isEnabling
        &&
        configuration
      ) {
        enableProvider(configuration.Id, configuration);
      } */
      if (nextProp.step === 2) {
        const id = nextProp?.configuration?.Id;
        if (id) {
          this.props.onDone(id);
        }
      }
    },
  }),
  branch(
    ({ queued }) => queued,
    renderComponent(() => (
      <Queued
        message={`We have received your request to edit this integration configuration, it has been queued
        and will be completed shortly.`}
      />
    )),
  ),
)(NewConfigurationContainer);

const getStep = (addStatus, enableStatus = {}) => {
  if (enableStatus.done) {
    return 2;
  }
  if (addStatus.done && !enableStatus.done) {
    return 1;
  }
  if (!addStatus.done && !enableStatus.done) {
    return 0;
  }
  return 0;
};

const mapStateToProps = (
  {
    integration: { addConfiguration, enableConfiguration, selectedIntegration },
  },
  { integrationId },
) => {
  const status = addConfiguration ? addConfiguration[integrationId] || {} : {};
  const activeConfig = status ? status.activeConfiguration : null;

  const enableStatus =
    enableConfiguration && activeConfig
      ? enableConfiguration[activeConfig.Id] || {}
      : {};

  return {
    invalidSaving: status.invalid,
    isSaving: status.isSaving,
    isEnabling: enableStatus.isSaving,
    done: status.done,
    queued: status.queued || enableStatus.queued,
    resp: status.resp,
    configuration: activeConfig,
    initialValues: activeConfig
      ? getInitialValueFromConfiguration(
          activeConfig,
          selectedIntegration ? selectedIntegration.Properties : [],
        )
      : {},
    integration: selectedIntegration,
    step: getStep(status, enableStatus),
    guide: (selectedIntegration && selectedIntegration.Guide) || '–',
  };
};

const mapDispatchToProps = (dispatch, { integrationId }) => ({
  // If not Oauth, 1st step is to add the provider to be able to 'enable' it.
  onAuthenticate(value) {
    const configuration = {
      AutoSync: false,
      helperConfiguration:
        (value.values && value.values.helperConfiguration) || value.values,
      Source: '',
      SourceQuality: 5,
      appUrl: '',
      AccountDisplay: '',
    };

    dispatch(
      shouldAddActiveProvider({
        integrationId,
        configuration,
      }),
    );
  },
  // Oauth 1st step does not add or enable - this will need to be done in the callback.
  onOauthAuthenticate(value) {
    dispatch(
      shouldAddOauthActiveProvider({
        ...value,
        id: integrationId,
        integrationId,
      }),
    );
  },
  // Once the integration has been added, we have an active configration that we can enable.
  enableProvider(configurationId, configuration) {
    dispatch(
      shouldEnableProvider({
        configurationId,
        configuration,
      }),
    );
  },
  onDone(id) {
    dispatch(
      shouldShowAlert({
        type: 'success',
        title: 'Your application has been added',
        description: 'Your configuration has been added successfully.',
      }),
    );

    dispatch(
      shouldEnableProviderReset({
        integrationId,
        configurationId: id,
      }),
    );
    dispatch(push('/admin/inbound/configuration'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnhanceNewConfigurationContainer);
