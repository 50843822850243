import { useEffect } from 'react';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/',
    exact: true,
    component: ({ history }) => {
      useEffect(() => {
        history.push('/me');
      }, [history]);

      return null;
    },
  },
  {
    path: '/approval-request/assigned',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"AssignedApprovalPage" */
        './components/pages/AssignedApprovalPage'
      ),
    ),
  },
  {
    path: '/approval-request/requested',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"RequestedApprovalPage" */
        './components/pages/RequestedApprovalPage'
      ),
    ),
  },
  {
    path: '/role-request/assigned',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"AssignedRoleRequestListPage" */
        './components/pages/AssignedRoleRequestListPage'
      ),
    ),
  },
  {
    path: '/role-request/requested',
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"RequestedRoleRequestListPage" */
        './components/pages/RequestedRoleRequestListPage'
      ),
    ),
  },
];
