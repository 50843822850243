import { handleActions } from 'redux-actions';
import {
  entityMessageActions,
  receivedEntityMessagesActionFromRealTime,
  fetchEntityActions,
  shouldCleanUpSelected,
  shouldFetchPersonActions,
  shouldAnonymisePropertyActions,
  shouldDeanonymisePropertyActions,
  shouldFetchHistoryActions,
  optimisticlyIncrementLocalCurrentEntityFollowCount,
  optimisticlyDecrementLocalCurrentEntityFollowCount,
  updateSelectedEntityForMinimize,
  updateRemoveFromProcessing,
  updateSelectedEntityForAnonymize,
  showQuickViewEntityAction,
  hideQuickViewEntityAction,
} from './actions';

const initialState = {
  messages: {},
  isFetchingPersons: false,
  invalidPersons: false,
  persons: [],
  isFetchingEntity: true,
  invalidEntity: false,
  currentEntity: null,
  anonymizedProperty: {},
  deAnonymizedProperty: {},
  propertyHistory: {},
  quickViewOptions: null,
};

export default handleActions(
  {
    '@@router/LOCATION_CHANGE': (state) => ({
      ...state,
      quickViewOptions: null,
    }),
    [showQuickViewEntityAction]: (state, { payload }) => ({
      ...state,
      quickViewOptions: payload,
    }),
    [hideQuickViewEntityAction]: (state) => ({
      ...state,
      quickViewOptions: null,
    }),
    [shouldAnonymisePropertyActions.request]: (
      state,
      { payload: { key } },
    ) => ({
      ...state,
      deAnonymizedProperty: {
        ...state.deAnonymizedProperty,
        [key]: null,
      },
      anonymizedProperty: {
        ...state.anonymizedProperty,
        [key]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [shouldAnonymisePropertyActions.receive]: (
      state,
      { payload: { key, value } },
    ) => ({
      ...state,
      anonymizedProperty: {
        ...state.anonymizedProperty,
        [key]: {
          isFetching: false,
          done: true,
          invalid: false,
          value,
        },
      },
    }),
    [shouldAnonymisePropertyActions.invalid]: (
      state,
      { payload: { key } },
    ) => ({
      ...state,
      anonymizedProperty: {
        ...state.anonymizedProperty,
        [key]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [shouldDeanonymisePropertyActions.request]: (
      state,
      { payload: { key } },
    ) => ({
      ...state,
      anonymizedProperty: {
        ...state.anonymizedProperty,
        [key]: null,
      },
      deAnonymizedProperty: {
        ...state.deAnonymizedProperty,
        [key]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [shouldDeanonymisePropertyActions.receive]: (
      state,
      { payload: { key, value } },
    ) => ({
      ...state,
      deAnonymizedProperty: {
        ...state.deAnonymizedProperty,
        [key]: {
          isFetching: false,
          done: true,
          invalid: false,
          value,
        },
      },
    }),
    [shouldDeanonymisePropertyActions.invalid]: (
      state,
      { payload: { key } },
    ) => ({
      ...state,
      deAnonymizedProperty: {
        ...state.deAnonymizedProperty,
        [key]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [shouldCleanUpSelected]: (state) => ({
      ...state,
      isFetchingEntity: false,
      invalidEntity: false,
      currentEntity: null,
    }),
    [fetchEntityActions.request]: (state) => ({
      ...state,
      isFetchingEntity: true,
      invalidEntity: false,
      currentEntity: null,
    }),
    [fetchEntityActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingEntity: false,
      invalidEntity: false,
      currentEntity: payload,
    }),
    [fetchEntityActions.invalid]: (state) => ({
      ...state,
      isFetchingEntity: false,
      invalidEntity: true,
      currentEntity: null,
    }),
    [shouldFetchPersonActions.request]: (state) => ({
      ...state,
      isFetchingPersons: true,
      invalidPersons: false,
      persons: [],
    }),
    [shouldFetchPersonActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingPersons: false,
      invalidPersons: false,
      persons: [...payload],
    }),
    [shouldFetchPersonActions.invalid]: (state) => ({
      ...state,
      isFetchingPersons: false,
      invalidPersons: true,
      persons: [],
    }),
    [entityMessageActions.request]: (state, { payload }) => ({
      ...state,
      messages: {
        ...state.messages,
        [payload]: {
          isFetching: true,
        },
      },
    }),
    [entityMessageActions.receive]: (
      state,
      { payload: { entityId, messages } },
    ) => ({
      ...state,
      messages: {
        ...state.messages,
        [entityId]: {
          isFetching: false,
          messages,
        },
      },
    }),
    [entityMessageActions.invalid]: (state, { payload }) => ({
      ...state,
      messages: {
        ...state.messages,
        [payload]: {
          isFetching: false,
          invalid: false,
        },
      },
    }),
    [receivedEntityMessagesActionFromRealTime]: (
      state,
      { payload: { entityId, actions, delta } },
    ) => {
      const current =
        state.messages && state.messages[entityId]
          ? state.messages[entityId]
          : {};

      return {
        ...state,
        messages: {
          ...state.messages,
          [entityId]: {
            ...current,
            isFetching: false,
            invalid: false,
            messages: [
              {
                actions,
                delta,
              },
            ],
          },
        },
      };
    },
    [shouldFetchHistoryActions.request]: (state, { payload }) => ({
      ...state,
      propertyHistory: {
        [payload]: {
          isFetching: true,
          invalid: false,
          data: {},
        },
      },
    }),
    [shouldFetchHistoryActions.receive]: (
      state,
      { payload: { result, param } },
    ) => ({
      ...state,
      propertyHistory: {
        [param]: {
          isFetching: false,
          invalid: false,
          data: result,
        },
      },
    }),
    [shouldFetchHistoryActions.invalid]: (state, { payload }) => ({
      ...state,
      propertyHistory: {
        [payload]: {
          isFetching: false,
          invalid: true,
          data: {},
        },
      },
    }),
    [optimisticlyIncrementLocalCurrentEntityFollowCount]: (state) => {
      if (!state.currentEntity) {
        return state;
      }

      return {
        ...state,
        currentEntity: {
          ...state.currentEntity,
          ...(state.currentEntity &&
          state.currentEntity.NumberOfFollowers !== undefined
            ? { NumberOfFollowers: state.currentEntity.NumberOfFollowers + 1 }
            : {}),
        },
      };
    },
    [optimisticlyDecrementLocalCurrentEntityFollowCount]: (state) => {
      if (!state.currentEntity) {
        return state;
      }

      return {
        ...state,
        currentEntity: {
          ...state.currentEntity,
          ...(state.currentEntity &&
          state.currentEntity.NumberOfFollowers !== undefined
            ? // it is possible to '(un)follow' from somewhere that is not an entitypage
              // e.g. latestDocs from homepage
              // thus being carreful about the NumberOfFollowers
              { NumberOfFollowers: state.currentEntity.NumberOfFollowers - 1 }
            : {}),
        },
      };
    },
    [updateSelectedEntityForMinimize]: (state, { payload }) => {
      const updatedSelectedEntityForMinimize = state.currentEntity || {};

      updatedSelectedEntityForMinimize.data.isMinimized = payload;

      return {
        ...state,
        currentEntity: {
          ...updatedSelectedEntityForMinimize,
        },
      };
    },
    [updateRemoveFromProcessing]: (state, { payload }) => {
      const updatedSelectedEntityForMinimize = state.currentEntity || {};

      updatedSelectedEntityForMinimize.data.isRemovedFromProcessing = payload;

      return {
        ...state,
        currentEntity: {
          ...updatedSelectedEntityForMinimize,
        },
      };
    },
    [updateSelectedEntityForAnonymize]: (state, { payload }) => {
      const updatedSelectedEntityForMinimize = state.currentEntity || {};

      updatedSelectedEntityForMinimize.data.isRemovedFromProcessing = payload;

      return {
        ...state,
        currentEntity: {
          ...updatedSelectedEntityForMinimize,
        },
      };
    },
  },
  initialState,
);
