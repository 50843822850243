import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

const EntityIconToolTipText = ({
  isConfigured,
  item,
  children,
  entityType,
}) => {
  let entityTypeToUse = entityType;

  if (!entityTypeToUse) {
    entityTypeToUse =
      item && item.data ? item.data.entityType : item.entityType;
  }

  const content = isConfigured ? (
    <div>{entityTypeToUse}</div>
  ) : (
    <div style={{ padding: '4px 0' }}>
      Entity type <strong>{entityTypeToUse}</strong> not configured.
      <br />
      <Link
        to={`/admin/management/entity-types/edit?entityType=${entityTypeToUse}`}
      >
        Configure entity type
      </Link>
    </div>
  );

  return (
    <Tooltip placement="top" destroyTooltipOnHide overlay={content}>
      {children}
    </Tooltip>
  );
};

export default EntityIconToolTipText;
