/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';
import take from 'lodash/take';
import { ButtonLink } from '@cluedin/atoms';
import { getName } from '../../../../../helpers/entity';
import SearchMainFilterItem from './SearchMainFilterItem';
import SearchMainFilterWrapper from './SearchMainFilterWrapper';

const ShowMoreLi = styled.li`
  text-align: center;
  padding: 5px 0;
  color: #545454;
  &:hover {
    color: #454545;
  }
`;

const numberToTake = 12;

const sortByNameFor = (terms) =>
  terms.sort((a, b) => {
    let aTerm = a.term;
    let bTerm = b.term;

    if (a.entityName && b.entityName) {
      aTerm = a.entityName;
      bTerm = b.entityName;
    }

    const aCount = a.count;
    const bCount = b.count;

    if (aTerm === bTerm) {
      return aCount > bCount ? -1 : 1;
    }
    return aTerm > bTerm ? 1 : -1;
  });

const sortByCountTerms = (terms) =>
  terms.sort((a, b) => {
    let aTerm = a.term;
    let bTerm = b.term;

    if (a.entityName && b.entityName) {
      aTerm = a.entityName;
      bTerm = b.entityName;
    }

    const aCount = a.count;
    const bCount = b.count;

    if (aCount === bCount) {
      return aTerm > bTerm ? 1 : -1;
    }
    return aCount > bCount ? -1 : 1;
  });

class SearchMainFilter extends Component {
  static propTypes = {
    sortByCount: PropTypes.bool.isRequired,
    entityId: PropTypes.string,
    allVisible: PropTypes.bool,
    title: PropTypes.node,
    type: PropTypes.string.isRequired,
    facets: PropTypes.object,
    selectedFilter: PropTypes.object.isRequired,
    noHeader: PropTypes.bool,
    initialIsOpen: PropTypes.bool,
  };

  static defaultProps = {
    sortByCount: false,
    facets: {},
    title: '',
    entityId: null,
    allVisible: false,
    usePartialLink: false,
    noHeader: false,
    initialIsOpen: false,
    selectedFilter: {},
  };

  constructor(props) {
    super(props);
    if (
      this.props.facets &&
      this.props.facets.terms &&
      this.props.facets.terms.length > 0
    ) {
      this.state = {
        showMore: this.props.facets.terms.length > numberToTake,
      };
    }
  }

  showMoreHandler() {
    this.setState({
      showMore: false,
    });
  }

  render() {
    const {
      facets,
      selectedFilter,
      type,
      title,
      allVisible,
      entityId,
      sortByCount,
      initialIsOpen,
      noHeader,
      entityName,
    } = this.props;

    let sortedTerms = [];

    facets.terms.forEach((t) => {
      const facetType = t;
      if (facetType === 'entityType') {
        facetType.entityName = getName(facetType.term) || facetType.term;
      }
    });

    if (sortByCount) {
      sortedTerms = sortByCountTerms(facets.terms);
    } else {
      sortedTerms = sortByNameFor(facets.terms);
    }

    let terms = sortedTerms;

    if (!allVisible) {
      terms = this.state.showMore ? take(terms, numberToTake) : terms;
    }

    const showMore =
      this.state.showMore && !allVisible ? (
        <ShowMoreLi key="showMore" className="showMore">
          <ButtonLink
            onClick={this.showMoreHandler.bind(this)}
            text={<FormattedMessage id="SearchFilter.ShowMore" />}
          />
        </ShowMoreLi>
      ) : undefined;

    return (
      <SearchMainFilterWrapper
        noHeader={noHeader}
        initialIsOpen={initialIsOpen}
        title={title}
      >
        <ul className="filterList">
          {terms.map((t, index) => {
            const isActive =
              selectedFilter &&
              selectedFilter.facet &&
              t.term.toLowerCase() === selectedFilter.facet.term.toLowerCase();

            return (
              <SearchMainFilterItem
                entityName={entityName}
                key={index}
                index={index}
                term={t}
                type={type}
                isActive={isActive}
                entityId={entityId}
              />
            );
          })}
          {showMore}
        </ul>
      </SearchMainFilterWrapper>
    );
  }
}

export default SearchMainFilter;
