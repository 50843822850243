"use strict";

exports.__esModule = true;
exports.default = formFieldState;
function formFieldState(_ref) {
  var props = _ref.props,
    states = _ref.states,
    formFieldComponent = _ref.formFieldComponent;
  return states.reduce(function (acc, state) {
    acc[state] = props[state];
    if (formFieldComponent) {
      if (typeof props[state] === 'undefined') {
        acc[state] = formFieldComponent[state];
      }
    }
    return acc;
  }, {});
}