import React from 'react';
import styled from 'styled-components';
import { Flex } from 'uxi/Layout';

const DashboardSectionWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 48px;
  }
`;

const Title = styled.h4`
  font-size: 16px;
  margin: 16px 0;
  width: 100%;
  padding-bottom: 2px;
  color: #9e9e9e;
  border-bottom: 1px solid;
  font-weight: 100;
`;

const DashboardSection = ({ title, children, style }) => {
  if (!title) {
    return children;
  }

  return (
    <DashboardSectionWrapper
      style={{
        ...style,
      }}
    >
      <Title data-test={title && `${title.props?.id}-title`}>{title}</Title>
      <Flex
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          flexFlow: 'row wrap',
        }}
      >
        {children}
      </Flex>
    </DashboardSectionWrapper>
  );
};

export default DashboardSection;
