import {
  getAppropriateComponentFromSchema,
  getAppropraiteComponentFormFromSchema,
  CustomInput,
} from '../modules/entity/entityProperty/getAppropriateComponent';

const cache = {};
const cacheForForm = {};
const cacheForAllProps = {};

export const getAllConflictedPropertyWithSchema = (
  conflictedProperties,
  getSchemaFromPropertyKey,
) =>
  conflictedProperties.map((conflictedProp) => {
    const keyWithoutProperties =
      conflictedProp.key.indexOf('property-') === 0
        ? conflictedProp.key.split('property-')[1]
        : conflictedProp.key;

    return {
      ...conflictedProp,
      schema: getSchemaFromPropertyKey(keyWithoutProperties),
    };
  });

const validateSchema = (schemaProperty) => {
  if (!schemaProperty.Visibility) {
    console.warn('!schemaProperty.Visibility', schemaProperty);
  }
  return (
    schemaProperty &&
    schemaProperty.Visibility &&
    schemaProperty.Visibility.toLowerCase() === 'Visible'.toLowerCase()
  );
};

const dynamicVocabToSchemaModel = (dynamicVocabObj) => ({
  DataType: dynamicVocabObj.dataType,
  DisplayName: dynamicVocabObj.displayName,
  /** hiddenInApplication  is only serialized when true */
  HiddenInApplication: dynamicVocabObj.hiddenInApplication || false,
  // IsObsolete: null,
  // IsObsoleteSinceVersion: null
  Key: dynamicVocabObj.key,
  /** showInApplication is only serialized when false */
  ShowInApplication: dynamicVocabObj.showInApplication || true,
  Visibility: dynamicVocabObj.visibility || 'Visible', // visibility is only serialized when != Visible
  VocabularyName: dynamicVocabObj.vocabularyName,
});

export const switchPropertyBackToOriginal = (key) =>
  key.split('____').join('.');

export const removeDots = (key) => key.split('.').join('____');

export const normalizeValuesForForm = (entity) => {
  const propertyKeys = Object.keys(entity.data.properties);
  const initialValues = {};

  propertyKeys.forEach((key) => {
    const keyWithoutProperties =
      key.indexOf('property-') === 0 ? key.split('property-')[1] : key;

    initialValues[removeDots(keyWithoutProperties)] =
      entity.data.properties[key];
  });

  return initialValues;
};

export const getAllPropertiesWithSchema = (
  entity,
  getSchemaFromPropertyKey,
) => {
  const propertyKeys = Object.keys(entity.data.properties);
  const propertiesWithSchema = propertyKeys.map((key) => {
    const keyWithoutProperties =
      key.indexOf('property-') === 0 ? key.split('property-')[1] : key;

    return {
      key,
      value: entity.data.properties[key],
      schema: getSchemaFromPropertyKey(keyWithoutProperties),
    };
  });

  return propertiesWithSchema.filter((p) => p.schema.isValid);
};

export const findPropertyFromSchema = (schema, propertyKey) => {
  const hasCache = cache[propertyKey.toLowerCase()];

  if (hasCache) {
    return hasCache;
  }

  const findSchema = (key) =>
    schema.find((s) => s.Key.toLowerCase() === key.toLowerCase());

  const propertyInSchema = findSchema(propertyKey);
  let result;

  if (propertyInSchema && validateSchema(propertyInSchema)) {
    result = {
      isValid: true,
      propertyInSchema,
      component: getAppropriateComponentFromSchema(propertyInSchema),
      formComponent: null,
      validators: null,
      normalizedKeyNameForForm: removeDots(propertyKey),
    };
  } else {
    result = {
      isValid: false,
      propertyInSchema: null,
      component: null,
      formComponent: null,
      validators: [],
      normalizedKeyNameForForm: null,
    };
  }

  cache[propertyKey.toLowerCase()] = result;

  return result;
};

export const setupGetPropertySchema = (schema, entity, forForm) => {
  if (entity && entity.data && entity.data.dynamicVocabularyKeys) {
    schema = schema.concat(
      // eslint-disable-line no-param-reassign
      entity.data.dynamicVocabularyKeys.map(dynamicVocabToSchemaModel),
    );
  }

  const findSchema = (key) =>
    schema.find((s) => {
      if (!s.Key) {
        console.warn('(!s.Key)', s);
      }
      return (s.Key && s.Key.toLowerCase()) === (key && key.toLowerCase());
    });

  return (key) => {
    const hasCache = !forForm
      ? cache[key && key.toLowerCase()]
      : cacheForForm[key && key.toLowerCase()];

    if (hasCache) {
      return hasCache;
    }

    const propertyInSchema = findSchema(key);

    let result;

    if (!forForm && propertyInSchema && validateSchema(propertyInSchema)) {
      result = {
        isValid: true,
        propertyInSchema,
        component: getAppropriateComponentFromSchema(propertyInSchema),
        formComponent: null,
        validators: null,
        normalizedKeyNameForForm: removeDots(key),
      };
    } else if (forForm && propertyInSchema && propertyInSchema.Editable) {
      const componentWithValidators =
        getAppropraiteComponentFormFromSchema(propertyInSchema);
      result = {
        isValid: true,
        propertyInSchema,
        component: getAppropriateComponentFromSchema(propertyInSchema),
        formComponent: CustomInput(componentWithValidators.Component),
        validators: componentWithValidators.validators,
        normalizedKeyNameForForm: removeDots(key),
      };
    } else {
      result = {
        isValid: false,
        propertyInSchema: null,
        component: null,
        formComponent: null,
        validators: [],
        normalizedKeyNameForForm: null,
      };
    }

    if (forForm) {
      cacheForForm[key && key.toLowerCase()] = result;
    } else {
      cache[key && key.toLowerCase()] = result;
    }

    return result;
  };
};

export const setupPropertyForAll = (schema, entity) => {
  if (entity && entity.data && entity.data.dynamicVocabularyKeys) {
    schema = schema.concat(
      // eslint-disable-line no-param-reassign
      entity.data.dynamicVocabularyKeys.map((schemaModel) => ({
        DataType: schemaModel.dataType,
        DisplayName: schemaModel.displayName,
        Key: schemaModel.key,
        VocabularyName: schemaModel.vocabularyName,
        IsDynamic: true,
      })),
    );
  }

  const findSchema = (key) =>
    schema.find((s) => s.Key.toLowerCase() === key.toLowerCase());

  return (key) => {
    const hasCache = cacheForAllProps[key.toLowerCase()];

    if (hasCache) {
      return hasCache;
    }

    const propertyInSchema = findSchema(key);
    let result;

    if (propertyInSchema) {
      result = {
        propertyInSchema,
        component: getAppropriateComponentFromSchema(propertyInSchema),
        normalizedKeyNameForForm: removeDots(key),
      };
    } else {
      result = {
        propertyInSchema: null,
        component: null,
        normalizedKeyNameForForm: null,
      };
    }

    cacheForAllProps[key.toLowerCase()] = result;

    return result;
  };
};

export default setupGetPropertySchema;
