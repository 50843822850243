import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const scrollingElement =
        window &&
        window.document.querySelector('.uxi_GAL-main-scrolling-element');

      if (scrollingElement) {
        if (scrollingElement.scrollTo) {
          scrollingElement.scrollTo(0, 0);
        } else if (scrollingElement.scrollTop) {
          scrollingElement.scrollTop = 0;
        }
      }
    }
  }

  render() {
    return <div className="scrollToTop">{this.props.children}</div>;
  }
}

export default withRouter(ScrollToTop);
