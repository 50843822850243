import styled from 'styled-components';

export const EdgePropertiesPanelEdgeBorderCircle = styled.div<{
  theme?: string;
}>`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme?.palette?.themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -4.75px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;
