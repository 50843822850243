import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Arrowleft } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import { BackLi, BackLinkAnchor, TextWrapper } from '../EntityMainBar.style';

const BackLink = ({ onClick }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <BackLi key="back">
      <BackLinkAnchor key="backLink" onClick={onClick}>
        <Arrowleft
          style={{
            verticalAlign: 'middle',
            paddingRight: '5px',
          }}
          size={12}
          color={themePrimary}
        />
        <TextWrapper>
          <FormattedMessage id="back" />
        </TextWrapper>
      </BackLinkAnchor>
    </BackLi>
  );
};

export default BackLink;
