import React from 'react';
import { Table } from '@cluedin/list-pattern';

export default (props) => (
  <Table
    {...props}
    style={{
      minWidth: '280px',
      maxWidth: '1200px',
      marginRight: 'auto',
      overflow: 'hidden',
      ...(props.style ? props.style : {}),
    }}
  />
);
