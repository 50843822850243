import PropTypes from 'prop-types';
import genericAvatar from '../../../assets/generic-avatar.png';

const style = {
  wrapper: {
    margin: '16px',
  },
  img: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
  },
};

export const PersonProfilePicture = (props) => (
  <figure style={style.wrapper}>
    <div style={{ ...style.img, backgroundImage: `url('${props.src}')` }} />
  </figure>
);

PersonProfilePicture.propTypes = {
  src: PropTypes.string,
};

PersonProfilePicture.defaultProps = {
  src: `${genericAvatar}`,
};

export default PersonProfilePicture;
