import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Button, Noaccess } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 108px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  ${({ customBackground }) => {
    return customBackground
      ? `
      background-image: url("${customBackground}");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-opacity: 0.7o;
    `
      : '';
  }}
`;

const IconStyle = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #000;
  opacity: 0.8;
  margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const PageNotAvailable = (customText, customBackground) =>
  withRouter(({ history }) => {
    return (
      <Wrapper customBackground={customBackground}>
        <IconStyle>
          <Noaccess size={140} />
        </IconStyle>
        <Title>
          <FormattedMessage id="module-error-pageLockedTitle" />
        </Title>
        <Text>
          <div>
            {customText ? (
              customText
            ) : (
              <FormattedMessage id="module-error-pageLockedText" />
            )}
          </div>
        </Text>
        <ButtonsWrapper>
          <Button
            message={<FormattedMessage id="module-error-returnHome" />}
            onClick={() => {
              history.push('/');
            }}
            type="primary"
            className="__test_NoAccessToPageReturnToHomeButton"
          />
        </ButtonsWrapper>
      </Wrapper>
    );
  });

export default PageNotAvailable;
