import { createAction } from 'redux-actions';
import {
  createAsyncAction,
  noBodyResponseAsyncCallWithActions,
  defaultAsyncCallWithActions,
} from '../../action/helpers/actionCreator';
import { fetchAllDataBreach, createDataBreach } from './data';

export const addConfigurationSelection = createAction(
  'SHOULD_SELECT_CONFIGURATION',
);
export const clearConfigurationSelection = createAction(
  'SHOULD_RESET_CONFIGURATION',
);
export const clearSavinDataBreachgResult = createAction(
  'SHOULD_RESET_SAVING_RESULT_DATA_BREACH',
);

export const shouldFetchDataBreachActions = createAsyncAction(
  'SHOULD_FETCH_DATA_BREACH',
);

export const shouldFetchDataBreach = defaultAsyncCallWithActions(
  fetchAllDataBreach,
  shouldFetchDataBreachActions,
);

export const shouldFetchMoreDataBreachActions = createAsyncAction(
  'SHOULD_FETCH_MORE_DATA_BREACH',
);
export const shouldFetchMoreDataBreach = defaultAsyncCallWithActions(
  fetchAllDataBreach,
  shouldFetchMoreDataBreachActions,
);

export const shouldCreateDataBreachActions = createAsyncAction(
  'SHOULD_CREATE_DATA_BREACH',
);

export const shouldCreateDataBreach = noBodyResponseAsyncCallWithActions(
  createDataBreach,
  shouldCreateDataBreachActions,
);
