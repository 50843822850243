import React from 'react';
import styled from 'styled-components';
import {
  Directoryopen,
  Cookie,
  Alert,
  Settings,
  Access,
  Erase,
} from 'uxi/Icons';
import PrivacyFeature from './PrivacyFeature';
import AnonymousDialog from './dialogs/AnonymousDialog';
import AskRightDialog from './dialogs/AskRightDialog';
import PersonalIdentifierDialog from './dialogs/PersonalIdentifierDialog';
import ConsentDialog from './dialogs/ConsentDialog';
import CurrentUserRequestsDialog from './dialogs/CurrentUserRequestsDialog';

const Title = styled.h1`
  margin: 0;
  padding: 0 0 30px 0;
`;
const P = styled.p`
  margin: 0 auto 32px;
  max-width: 600px;
  padding: 0;
`;

const HR = styled.div`
  height: 1px;
  width: 100%;
  background: #ccc;
  margin: 60px 0;
`;

const PrivacyFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 760px;
  padding: 48px 16px;
  margin: 0 auto;
`;

const PrivacyFeaturesContent = styled.div`
  @media (min-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 42%; /* ! */
    }
  }
`;

const PrivacyFeatures = ({
  selectedFeature,
  selectFeature,
  clearSelectFeature,
  sendFeature,
  isSendingRequest,
  invalidRequest,
  isSendingRequestDone,
  currentUser,
  cookiePolicyUrl,
  showPersonalIdentifiers,
  onShowPersonalIdentifiers,
  showAnonymousDialog,
  anonymousTitle,
  consents,
  showConsentDialog,
  onShowConsentDialog,
  currentUserRequests,
  showCurrentUserRequest,
  onShowCurrentUserRequests,
  dpoEmail,
  dpoPhone,
}) => (
  <PrivacyFeaturesWrapper>
    <CurrentUserRequestsDialog
      show={showCurrentUserRequest}
      currentUserRequests={currentUserRequests}
      onClose={clearSelectFeature}
    />
    <PersonalIdentifierDialog
      show={showPersonalIdentifiers}
      personalIdentifiers={currentUser.personalIdentifiers}
      onClose={clearSelectFeature}
    />
    <AnonymousDialog
      show={showAnonymousDialog}
      feature={selectedFeature}
      onClose={clearSelectFeature}
      title={anonymousTitle}
      dpoEmail={dpoEmail}
      dpoPhone={dpoPhone}
    />
    <AskRightDialog
      show={!!selectedFeature && currentUser && currentUser.isAuthenticated}
      feature={selectedFeature}
      onClose={clearSelectFeature}
      onRequest={sendFeature}
      isSendingRequest={isSendingRequest}
      invalidRequest={invalidRequest}
      isSendingRequestDone={isSendingRequestDone}
      personalIdentifiers={currentUser.personalIdentifiers}
    />
    <ConsentDialog
      show={showConsentDialog}
      consentFormConfiguration={consents[0]}
      onClose={clearSelectFeature}
      actionTitle="Confirm Consent"
    />
    <Title>Privacy Collection</Title>
    <P>
      We respect your privacy. As such, we have put at our disposal all the
      legal right required by the GDPR regulation in place. Click on the
      following listed items to get more information.
    </P>
    <PrivacyFeaturesContent>
      <PrivacyFeature
        icon={
          <Directoryopen size="40" color="#fff" style={{ marginTop: '24px' }} />
        }
        title="Personal Information"
        actionText="View Personal Data"
        text={`
          We use personal information you give us to contact you in response to your queries.
          We will never share your personal data with anyone else without your consent.
        `}
        onClick={onShowPersonalIdentifiers}
      />
      <PrivacyFeature
        icon={<Cookie size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="Cookie"
        actionText="View Cookie Policy"
        text={`
          This website uses cookies to provide superior performance and make the site better to visitors.
        `}
        onClick={() => {
          window.location = cookiePolicyUrl;
        }}
      />
      <PrivacyFeature
        icon={<Settings size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="Modify your consent"
        actionText="Modify consent"
        onClick={onShowConsentDialog}
        text={`
      You have the right to withdraw your consent for processing your personal data.
      `}
      />
      <PrivacyFeature
        icon={<Alert size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="My current request"
        actionText="View your requets"
        text={`
          We provide you a way to track the request about the
          consent policy, so you can track them easily.
        `}
        onClick={onShowCurrentUserRequests}
      />
    </PrivacyFeaturesContent>

    <HR />
    <Title>Your privacy rights</Title>
    <P>
      These are your rights related to your personal data. You can exercice your
      right by clicking on the buttons.
    </P>
    <PrivacyFeaturesContent>
      <PrivacyFeature
        icon={<Alert size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="Right to be informed"
        actionText="Ask for a report"
        onClick={() => {
          selectFeature({
            code: 'rightToInform',
            title: 'Right to be informed',
          });
        }}
        text={`
      You have the right to be informed about what personal data is collected from you and the purpose it will be used for.
      `}
      />
      <PrivacyFeature
        icon={<Access size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="Right to access"
        actionText="Ask for personal data"
        onClick={() => {
          selectFeature({
            code: 'rightToAccess',
            title: 'Right to access',
          });
        }}
        text={`
      You have the right to ask for a copy of all the personal identifiers we have collected from you.
      `}
      />
      <PrivacyFeature
        icon={<Settings size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="Right to correction"
        actionText="Ask for a correction"
        onClick={() => {
          selectFeature({
            code: 'rightToCorrection',
            title: 'Right to correction',
          });
        }}
        text={`
      You have the right to view and change inaccurate personal data that we have collected.
      `}
      />
      <PrivacyFeature
        icon={<Erase size="40" color="#fff" style={{ marginTop: '24px' }} />}
        title="Right to erasure"
        actionText="Ask for a erasure"
        onClick={() => {
          selectFeature({
            code: 'rightToErasure',
            title: 'Right to erasure',
          });
        }}
        text={`
      You have the right to be forgotten. You may ask to erase all the data we have collected from you at any time.
      `}
      />
    </PrivacyFeaturesContent>
  </PrivacyFeaturesWrapper>
);

export default PrivacyFeatures;
