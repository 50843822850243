import constants from '../constants';

const initialState = {
  entities: {},
  pageNumber: {},
  isFetching: {},
  fake: {},
  suggestedSearch: {},
  suggestedSearchIsFetching: {},
  suggestedSearchPageNumber: {},
  activities: {},
  activitiesPageNumber: {},
  activitiesIsFetching: {},
};

export default function update(state = initialState, action = {}) {
  switch (action.type) {
    case constants.entityV3.REQUEST_ENTITIES_V3: {
      const { widgetUniqueId } = action.data;
      const { entities, pageNumber, isFetching, fake } = state;

      const updatedEntitiesAfterRequest = Object.assign({}, entities);
      const updatedIsFetchingAfterRequest = Object.assign({}, isFetching);
      const updatedPageNumberAfterRequest = Object.assign({}, pageNumber);
      const updatedFakeAfterRequest = Object.assign({}, fake);

      if (
        !updatedPageNumberAfterRequest[widgetUniqueId] ||
        updatedPageNumberAfterRequest[widgetUniqueId] === 0
      ) {
        updatedEntitiesAfterRequest[widgetUniqueId] = [];
        updatedFakeAfterRequest[widgetUniqueId] = [];
        updatedPageNumberAfterRequest[widgetUniqueId] = 0;
      }

      updatedIsFetchingAfterRequest[widgetUniqueId] = true;

      return Object.assign({}, state, {
        entities: updatedEntitiesAfterRequest,
        isFetching: updatedIsFetchingAfterRequest,
        pageNumber: updatedPageNumberAfterRequest,
        fake: updatedFakeAfterRequest,
      });
    }
    case constants.entityV3.RECEIVE_ENTITIES_V3: {
      const widgetUniqueIdAfterReceive = action.data.widgetUniqueId;

      const updatedEntitiesAfterReceive = Object.assign({}, state.entities);
      const updatedIsFetchingAfterReceive = Object.assign({}, state.isFetching);
      const updatedPageNumberAfterReceive = Object.assign({}, state.pageNumber);
      const updatedFakeAfterReceive = Object.assign({}, state.fake);

      updatedEntitiesAfterReceive[widgetUniqueIdAfterReceive] =
        updatedEntitiesAfterReceive[widgetUniqueIdAfterReceive].concat(
          action.data.entities,
        );
      updatedIsFetchingAfterReceive[widgetUniqueIdAfterReceive] = false;
      updatedPageNumberAfterReceive[widgetUniqueIdAfterReceive] += 1;
      updatedFakeAfterReceive[widgetUniqueIdAfterReceive] = [];

      return Object.assign({}, state, {
        entities: updatedEntitiesAfterReceive,
        isFetching: updatedIsFetchingAfterReceive,
        pageNumber: updatedPageNumberAfterReceive,
        fake: updatedFakeAfterReceive,
      });
    }
    case constants.entityV3.REQUEST_SUGGESTED_SEARCH_V3: {
      const {
        suggestedSearch,
        suggestedSearchPageNumber,
        suggestedSearchIsFetching,
      } = state;
      const ssUui = action.data.widgetUniqueId;
      const updatedSuggestedSearchAfterRequest = Object.assign(
        {},
        suggestedSearch,
      );
      const updatedSuggestedSearchIsFetchingAfterRequest = Object.assign(
        {},
        suggestedSearchIsFetching,
      );
      const updatedSuggestedSearchPageNumberAfterRequest = Object.assign(
        {},
        suggestedSearchPageNumber,
      );

      if (
        !updatedSuggestedSearchPageNumberAfterRequest[ssUui] ||
        updatedSuggestedSearchPageNumberAfterRequest[ssUui] === 0
      ) {
        updatedSuggestedSearchAfterRequest[ssUui] = [];
        updatedSuggestedSearchPageNumberAfterRequest[ssUui] = 0;
      }

      updatedSuggestedSearchIsFetchingAfterRequest[ssUui] = true;

      return Object.assign({}, state, {
        suggestedSearch: updatedSuggestedSearchAfterRequest,
        suggestedSearchIsFetching: updatedSuggestedSearchIsFetchingAfterRequest,
        suggestedSearchPageNumber: updatedSuggestedSearchPageNumberAfterRequest,
      });
    }
    case constants.entityV3.RECEIVE_SUGGESTED_SEARCH_V3: {
      const ssUuiReceived = action.data.widgetUniqueId;

      const updatedSuggestedSearchAfterReceive = Object.assign(
        {},
        state.suggestedSearch,
      );
      const updatedSuggestedSearchIsFetchingAfterReceive = Object.assign(
        {},
        state.suggestedSearchIsFetching,
      );
      const updatedSuggestedSearchPageNumberAfterReceive = Object.assign(
        {},
        state.suggestedSearchPageNumber,
      );

      updatedSuggestedSearchAfterReceive[ssUuiReceived] =
        updatedSuggestedSearchAfterReceive[ssUuiReceived].concat(
          action.data.entities,
        );
      updatedSuggestedSearchIsFetchingAfterReceive[ssUuiReceived] = false;
      updatedSuggestedSearchPageNumberAfterReceive[ssUuiReceived] += 1;

      return Object.assign({}, state, {
        suggestedSearch: updatedSuggestedSearchAfterReceive,
        suggestedSearchIsFetching: updatedSuggestedSearchIsFetchingAfterReceive,
        suggestedSearchPageNumber: updatedSuggestedSearchPageNumberAfterReceive,
      });
    }
    case constants.entityV3.REQUEST_ACTIVITIES_V3: {
      const { activities, activitiesPageNumber, activitiesIsFetching } = state;
      const activityUuId = action.data.widgetUniqueId;
      const updatedActivitiesAfterRequest = Object.assign({}, activities);
      const updatedActivitiesPageNumberAfterRequest = Object.assign(
        {},
        activitiesPageNumber,
      );
      const updatedActivitiesIsFetchingAfterRequest = Object.assign(
        {},
        activitiesIsFetching,
      );

      if (
        !updatedActivitiesPageNumberAfterRequest[activityUuId] ||
        updatedActivitiesPageNumberAfterRequest[activityUuId] === 0
      ) {
        updatedActivitiesAfterRequest[activityUuId] = [];
        updatedActivitiesPageNumberAfterRequest[activityUuId] = 0;
      }

      updatedActivitiesIsFetchingAfterRequest[activityUuId] = true;

      return Object.assign({}, state, {
        activities: updatedActivitiesAfterRequest,
        activitiesPageNumber: updatedActivitiesPageNumberAfterRequest,
        activitiesIsFetching: updatedActivitiesIsFetchingAfterRequest,
      });
    }
    case constants.entityV3.RECEIVE_ACTIVITIES_V3: {
      const activityUuiReceived = action.data.widgetUniqueId;

      const updatedActivitiesAfterReceive = Object.assign({}, state.activities);
      const updatedActivitiesIsFetchingAfterReceive = Object.assign(
        {},
        state.activitiesIsFetching,
      );
      const updatedActivitiesPageNumberAfterReceive = Object.assign(
        {},
        state.activitiesPageNumber,
      );

      updatedActivitiesAfterReceive[activityUuiReceived] =
        updatedActivitiesAfterReceive[activityUuiReceived].concat(
          action.data.entities,
        );
      updatedActivitiesIsFetchingAfterReceive[activityUuiReceived] = false;
      updatedActivitiesPageNumberAfterReceive[activityUuiReceived] += 1;

      return Object.assign({}, state, {
        activities: updatedActivitiesAfterReceive,
        activitiesPageNumber: updatedActivitiesPageNumberAfterReceive,
        activitiesIsFetching: updatedActivitiesIsFetchingAfterReceive,
      });
    }
    default:
      return state;
  }
}
