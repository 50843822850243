"use strict";

exports.__esModule = true;
exports.toJSON = exports.apiRequest = void 0;
var _errorHandling = require("uxi-business/errorHandling");
var _token = require("../token");
var _url = require("../url");
var _requestRefreshToken = _interopRequireDefault(require("./requestRefreshToken"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var toJSON = function toJSON(response) {
  return response.text().then(function (text) {
    return text ? JSON.parse(text) : {};
  });
};
exports.toJSON = toJSON;
var fetchApi = function fetchApi(method, url, data, token) {
  var dataEncoded = data ? JSON.stringify(data) : '';
  var contentLenght = dataEncoded.length;
  var headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'Content-Length': contentLenght
  };
  return (0, _requestRefreshToken.default)("" + url, {
    method: method,
    headers: headers,
    body: data ? dataEncoded : null,
    redirect: 'follow',
    token: token
  }).then(_errorHandling.defaultCatch).then(toJSON);
};
var includeTokenInResp = function includeTokenInResp(resp) {
  var response = resp;
  if (response && response instanceof Object) {
    response.__token = (0, _token.getCurrentToken)();
  }
  return response;
};
var apiRequest = function apiRequest(method, url, data) {
  return fetchApi(method, _url.urlBuilder.api(url), data).then(includeTokenInResp);
};
exports.apiRequest = apiRequest;