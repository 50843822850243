"use strict";

exports.__esModule = true;
exports.default = void 0;
var RACI_LEVELS = _interopRequireWildcard(require("./raci-levels"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var claims = ['admin.entitytypes', 'admin.roles', 'admin.users', 'consume.exporttargets', 'consume.graphql', 'consume.streams', 'engine.configurationgroups', 'engine.jobs', 'engine.processing', 'engine.statistics', 'governance.consent', 'governance.databreach', 'governance.dataretention', 'governance.metrics', 'governance.personalidentifiers', 'governance.settings', 'governance.subjectaccessrequest', 'integration.availableintegrations', 'integration.configuredintegrations', 'integration.datasourcegroups', 'integration.enrichment', 'integration.manualdataentryprojectmanagement', 'integration.manualdataentryinput', 'management.annotation', 'management.datacatalog', 'management.duplicates', 'management.glossary', 'management.hierarchies', 'management.rulebuilder', 'management.deduplicationprojectmanagement', 'management.deduplicationreview', 'preparation.prepare', 'preparation.clean', 'preparation.meshcenter', 'admin.tokenmanagement', 'admin.tenantmanagement', 'admin.datamanagement', 'admin.providermanagement'];
var claimTypes = claims.reduce(function (acc, claim) {
  var _ref, _ref2, _ref3, _ref4, _extends2;
  var claimName = claim.replace('.', '_').toUpperCase();
  return _extends({}, acc, (_extends2 = {}, _extends2[claimName + "_ACCOUNTABLE"] = (_ref = {}, _ref[claim] = RACI_LEVELS.ACCOUNTABLE, _ref), _extends2[claimName + "_RESPONSIBLE"] = (_ref2 = {}, _ref2[claim] = RACI_LEVELS.RESPONSIBLE, _ref2), _extends2[claimName + "_CONSULTANT"] = (_ref3 = {}, _ref3[claim] = RACI_LEVELS.CONSULTANT, _ref3), _extends2[claimName + "_INFORMED"] = (_ref4 = {}, _ref4[claim] = RACI_LEVELS.INFORMED, _ref4), _extends2));
}, {});

/*
exports an object covering all claim combinations that looks like this:
  {
    ADMIN_ENTITYTYPES_ACCOUNTABLE: { 'admin.entitytypes': ['Accountable'] },
    ADMIN_ENTITYTYPES_RESPONSIBLE: { 'admin.entitytypes': ['Accountable', 'Responsible'] },
    ADMIN_ENTITYTYPES_CONSULTANT: { 'admin.entitytypes': ['Accountable', 'Responsible', 'Consultant'] },
    ADMIN_ENTITYTYPES_INFORMED: { 'admin.entitytypes': ['Accountable', 'Responsible', 'Consultant', Informed] },
    ADMIN_ROLES_ACCOUNTABLE: { 'admin.roles': ['Accountable'] },
    ADMIN_ROLES_RESPONSIBLE: { 'admin.roles': ['Accountable', 'Responsible'] },
    ADMIN_ROLES_CONSULTANT: { 'admin.roles': ['Accountable', 'Responsible', 'Consultant'] },
    ADMIN_ROLES_INFORMED: { 'admin.roles': ['Accountable', 'Responsible', 'Consultant', Informed] },
    ....
  }
*/
var _default = claimTypes;
exports.default = _default;