import React, { useState, useRef } from 'react';
import Idle from 'react-idle';
import Alert from 'uxi/Alert';
import { PrimaryButton } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

class ShouldSkip {
  constructor() {
    this.skipped = false;
  }

  getSkipped() {
    return this.skipped;
  }

  setSkipped(value) {
    this.skipped = value;
  }
}

const skipValueHolder = new ShouldSkip();

const countDownInterval = (onChange) => {
  let localCount = 10;

  const timeoutRef = setInterval(() => {
    localCount = localCount - 1;
    onChange(localCount);

    if (localCount === 0) {
      clearInterval(timeoutRef);
    }
  }, 1000);

  return timeoutRef;
};

const IdleManager = ({ timeout, onInactive, onSkip }) => {
  const [timeoutElapsed, setTimeoutElapsed] = useState(false);
  const [countDown, setCountDown] = useState();
  const isRunning = useRef(false);

  const handleTimeoutElapsedChange = (idle) => {
    if (!isRunning.current) {
      isRunning.current = true;
      skipValueHolder.setSkipped(false);
      if (!timeoutElapsed && idle) {
        setTimeoutElapsed(true);

        countDownInterval((newValue) => {
          setCountDown(newValue);
          if (newValue === 0) {
            isRunning.current = false;
          }
          if (!skipValueHolder.getSkipped() && newValue === 0 && onInactive) {
            onInactive({ idle: true });
          }
        });
      }
    }
  };

  const onSkipHandler = () => {
    setCountDown(10);
    setTimeoutElapsed(false);

    skipValueHolder.setSkipped(true);
    if (onSkip) {
      onSkip();
    }
  };

  return (
    <div>
      <Idle onChange={handleTimeoutElapsedChange} timeout={timeout} />
      {timeoutElapsed ? (
        <Alert isBanner type="error">
          <FormattedMessage id="module-core-inactivity-part1" />
          <br />
          <FormattedMessage
            id="module-core-inactivity-part2"
            values={{
              countDown: (countDown || '0').toString(),
            }}
          />
          <br />
          <FormattedMessage id="module-core-inactivity-part3" />
          <br />
          <PrimaryButton text="Keep me signed in" onClick={onSkipHandler} />
        </Alert>
      ) : null}
    </div>
  );
};

export default IdleManager;
