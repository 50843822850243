"use strict";

exports.__esModule = true;
exports.b64DecodeUnicode = b64DecodeUnicode;
exports.b64EncodeUnicode = b64EncodeUnicode;
exports.unicodeEncodeGqlCursor = exports.unicodeDecodeGqlCursor = exports.default = exports.createHashKey = void 0;
var createHashKey = function createHashKey(q) {
  return encodeURIComponent(q);
};
exports.createHashKey = createHashKey;
var _default = createHashKey; // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem#Solution_4_%E2%80%93_escaping_the_string_before_encoding_it
exports.default = _default;
function b64EncodeUnicode(str) {
  if (str === void 0) {
    str = '';
  }
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode("0x" + p1);
  }));
}
// b64EncodeUnicode('✓ à la mode'); // "4pyTIMOgIGxhIG1vZGU="
// b64EncodeUnicode('\n'); // "Cg=="

function b64DecodeUnicode(str) {
  if (str === void 0) {
    str = '';
  }
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}
// b64DecodeUnicode('4pyTIMOgIGxhIG1vZGU='); // "✓ à la mode"
// b64DecodeUnicode('Cg=='); // "\n"

/* \
|*|
|*|  Base64 / binary data / UTF-8 strings utilities (#1)
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
|*|
|*|  Author: madmurphy
|*|
\ */

/* Array of bytes to base64 string decoding */
/* eslint-disable */
function b64ToUint6(nChr) {
  'use strict';

  return nChr > 64 && nChr < 91 ? nChr - 65 : nChr > 96 && nChr < 123 ? nChr - 71 : nChr > 47 && nChr < 58 ? nChr + 4 : nChr === 43 ? 62 : nChr === 47 ? 63 : 0;
}
function base64DecToArr(sBase64, nBlockSize) {
  'use strict';

  var sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ''),
    nInLen = sB64Enc.length,
    nOutLen = nBlockSize ? Math.ceil((nInLen * 3 + 1 >>> 2) / nBlockSize) * nBlockSize : nInLen * 3 + 1 >>> 2,
    aBytes = new Uint8Array(nOutLen);
  for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        aBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
      }
      nUint24 = 0;
    }
  }
  return aBytes;
}

/* Base64 string to array encoding */

function uint6ToB64(nUint6) {
  'use strict';

  return nUint6 < 26 ? nUint6 + 65 : nUint6 < 52 ? nUint6 + 71 : nUint6 < 62 ? nUint6 - 4 : nUint6 === 62 ? 43 : nUint6 === 63 ? 47 : 65;
}
function base64EncArr(aBytes) {
  'use strict';

  var eqLen = (3 - aBytes.length % 3) % 3,
    sB64Enc = '';
  for (var nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3;
    /* Uncomment the following line in order to split the output in lines 76-character long: */
    /*
    if (nIdx > 0 && (nIdx * 4 / 3) % 76 === 0) { sB64Enc += "\r\n"; }
    */
    nUint24 |= aBytes[nIdx] << (16 >>> nMod3 & 24);
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCharCode(uint6ToB64(nUint24 >>> 18 & 63), uint6ToB64(nUint24 >>> 12 & 63), uint6ToB64(nUint24 >>> 6 & 63), uint6ToB64(nUint24 & 63));
      nUint24 = 0;
    }
  }
  return eqLen === 0 ? sB64Enc : sB64Enc.substring(0, sB64Enc.length - eqLen) + (eqLen === 1 ? '=' : '==');
}
/* eslint-enable */

var unicodeDecodeGqlCursor = function unicodeDecodeGqlCursor(base64UTF16) {
  if (base64UTF16 === void 0) {
    base64UTF16 = '';
  }
  return String.fromCharCode.apply(null, new Uint16Array(base64DecToArr(base64UTF16, 2).buffer));
};
exports.unicodeDecodeGqlCursor = unicodeDecodeGqlCursor;
var unicodeEncodeGqlCursor = function unicodeEncodeGqlCursor(jsonstring) {
  if (jsonstring === void 0) {
    jsonstring = '';
  }
  var aUTF16CodeUnits = new Uint16Array(jsonstring.length);
  Array.prototype.forEach.call(aUTF16CodeUnits,
  // eslint-disable-next-line no-param-reassign
  function (el, idx, arr) {
    arr[idx] = jsonstring.charCodeAt(idx);
  });
  var sUTF16Base64 = base64EncArr(new Uint8Array(aUTF16CodeUnits.buffer));
  return sUTF16Base64;
};
// String.fromCharCode.apply(null, new Uint16Array(base64DecToArr(base64UTF16, 2).buffer));
exports.unicodeEncodeGqlCursor = unicodeEncodeGqlCursor;