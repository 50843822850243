import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'uxi/Alert';
import { FormattedMessage } from 'react-intl';

import {
  EntityPendingChangesMessagesWrapper,
  EntityPendingChangesHeader,
  EntityPendingChangesHeaderText,
} from './EntityPendingChangesActionMessages.styles';
import EntityActionMessages from '../EntityActionMessages/EntityActionMessages';

const EntityPendingChangesActionMessages = ({
  entityId,
  messages,
  refetchEntityMessages,
}) => {
  if (!messages || messages.length === 0) {
    return (
      <EntityPendingChangesMessagesWrapper>
        <Alert type="info">
          <FormattedMessage id="module-pendingChanges-noActionsRequired" />
        </Alert>
      </EntityPendingChangesMessagesWrapper>
    );
  }

  return (
    <EntityPendingChangesMessagesWrapper>
      <EntityPendingChangesHeader>
        <EntityPendingChangesHeaderText>
          <FormattedMessage id="module-pendingChanges-allPendingChanges" />
        </EntityPendingChangesHeaderText>
      </EntityPendingChangesHeader>

      <EntityActionMessages
        entityId={entityId}
        messages={messages}
        refetchEntityMessages={refetchEntityMessages}
      />
    </EntityPendingChangesMessagesWrapper>
  );
};

export default EntityPendingChangesActionMessages;

EntityPendingChangesActionMessages.propTypes = {
  entityId: PropTypes.string,
  messages: PropTypes.array,
  refetchEntityMessages: PropTypes.func,
};

EntityPendingChangesActionMessages.defaultProps = {
  entityId: '',
  messages: [],
  refetchEntityMessages: () => {},
};
