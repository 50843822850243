import { gql, useMutation, ApolloError } from '@apollo/client';
import { get } from 'lodash';

const createDataSourceSetMutation = gql`
  mutation createDataSourceSet($dataSourceSet: InputDataSourceSet) {
    inbound {
      createDataSourceSet(dataSourceSet: $dataSourceSet)
    }
  }
`;

type DataSetGroup = {
  name: string;
};

type DataSetGroupMutationResult = {
  id: string;
  error: ApolloError | undefined;
  loading: boolean;
};

export const useCreateDataSetGroup = (): [
  (dataSetGroup: DataSetGroup) => void,
  DataSetGroupMutationResult,
] => {
  const [createDataSetGroup, { data, error, loading }] = useMutation(
    createDataSourceSetMutation,
  );

  const onlyDataNeeded = get(data, 'inbound.createDataSourceSet');

  return [
    (dataSetGroup: DataSetGroup) => {
      createDataSetGroup({
        variables: {
          dataSourceSet: dataSetGroup,
        },
      });
    },
    {
      id: onlyDataNeeded,
      error,
      loading,
    },
  ];
};

export default {
  useCreateDataSetGroup,
};
