import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

type EntitySource = {
  virtualization: {
    entityHistoryDetails: {
      property: string;
      changeType: string;
      oldValue: string;
      newValue: string;
      changedPart: string;
    };
  };
};

interface EntitySourcesData {
  data: EntitySource[];
}

interface EntityDetailsVariables {
  id: string;
  partId: string;
}

type EntitySourceOutput = {
  property: string;
};

const GET_ENTITY_SOURCES = gql`
  query entityHistoryDetails($id: ID!, $partId: ID!) {
    virtualization {
      entityHistoryDetails(id: $id, partId: $partId) {
        property
        changeType
        oldValue
        newValue
        changedPart
      }
    }
  }
`;

export const useQueryEntityHistoryDetails = (id: string, partId: string) => {
  const { data, loading, error } = useQuery<
    EntitySourcesData,
    EntityDetailsVariables
  >(GET_ENTITY_SOURCES, {
    variables: { id, partId },
  });

  const entitySources: EntitySourceOutput[] =
    get(data, 'virtualization.entityHistoryDetails') ?? [];

  return [entitySources, loading, error];
};
