import React from 'react';
import { pure } from 'recompose';
import AuthTryingToCreateTeam from './AuthTryingToCreateTeam';
import NotYourTeam from './NotYourTeam';

const CreateWithNotYourTeam = () => (
  <div>
    <NotYourTeam />
    <AuthTryingToCreateTeam />
  </div>
);

export default pure(CreateWithNotYourTeam);
