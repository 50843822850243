import React, { Component } from 'react';
import { FormattedHTMLMessage } from '@cluedin/locale';
import { logMessage } from '../../../core/helpers/logger';
import serverUnavailable from '../../assets/server-unavailable-illustration.png';

class ServiceUnavailable extends Component {
  componentDidMount() {
    const { status } = this.props;

    logMessage(`Error ${status || '503'} for : ${window.location.href}`);
  }
  render() {
    const { message } = this.props;

    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <div style={{ marginTop: '50px' }}>
          <img alt="Server Unavailable" src={`${serverUnavailable}`} />
        </div>
        <div style={{ padding: '15px', fontSize: '20px' }}>
          {message || <FormattedHTMLMessage id="experencingTrouble" />}
        </div>
      </div>
    );
  }
}

export default ServiceUnavailable;
