"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(Modal) {
  return (0, _styledComponents.default)(Modal)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  position: fixed;\n  z-index: ", ";\n  right: 0;\n  bottom: 0;\n  top: 0;\n  left: 0;\n  &.hidden {\n    visibility: hidden;\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.zIndex.modal;
  });
};
exports.default = _default;