import { createAction } from 'redux-actions';
import { unauthorized, subtleErrorHandling } from '../../action/generic';
import { shouldShowAlert } from '../core/actions';
import {
  createAsyncAction,
  defaultAsyncEntityCallWithActions,
  defaultAsyncCallWithActions,
  defaultAsyncCallWithActionsWithParam,
} from '../../action/helpers/actionCreator';
import {
  getMessagesForEntity,
  getEntity,
  fetchPersonEntities,
  anonymiseProperty,
  deAnonymiseProperty,
  getHistory,
  externalSearch,
  reProcess,
} from './data';
import { cache } from './cache';
import { shouldExecuteUdpateEntity } from '../../modules/entityActions/actions';

const getEntityWithCache = cache(getEntity);

export const shouldCleanUpSelected = createAction('CLEAN_UP_ENTITY_PER_ID');

export const fetchEntityActions = createAsyncAction('GET_ENTITY_PER_ID');

export const shouldFetchEntity = defaultAsyncEntityCallWithActions(
  getEntityWithCache,
  fetchEntityActions,
);

export const shouldFetchPersonActions = createAsyncAction(
  'SAR_FETCH_PERSON_ENTITIES',
);
export const shouldFetchPerson = defaultAsyncCallWithActions(
  fetchPersonEntities,
  shouldFetchPersonActions,
);

export const entityMessageActions = createAsyncAction(
  'GET_ENTITY_MESSAGE_ACTIONS',
);
export const shouldFetchEntityMessage = (entityId) => (dispatch) => {
  dispatch(entityMessageActions.request(entityId));

  getMessagesForEntity(entityId)
    .then((messages) => {
      dispatch(
        entityMessageActions.receive({
          entityId,
          messages,
        }),
      );
    })
    .catch(unauthorized(dispatch, entityMessageActions.invalid));
};

export const receivedEntityMessagesActionFromRealTime = createAction(
  'RECEIVED_ENTITY_MESSAGE_ACTIONS_FROM_REALTIME',
);

export const shouldAnonymisePropertyActions = createAsyncAction(
  'ENTITY_PROPERTY_ANONYMIZED',
);

export const shouldAnonymiseProperty = (prop) => (dispatch) => {
  dispatch(shouldAnonymisePropertyActions.request(prop));

  anonymiseProperty(prop)
    .then((newValue) => {
      dispatch(
        shouldAnonymisePropertyActions.receive({
          ...prop,
          value: newValue,
        }),
      );

      dispatch(
        shouldExecuteUdpateEntity({
          entityId: prop.entityId,
          key: prop.key,
          value: newValue,
        }),
      );
    })
    .catch(
      subtleErrorHandling(
        dispatch,
        shouldAnonymisePropertyActions.invalid,
        false,
        prop,
      ),
    );
};

export const shouldDeanonymisePropertyActions = createAsyncAction(
  'ENTITY_PROPERTY_DE_ANONYMIZED',
);
export const shouldDeanonymiseProperty = (prop) => (dispatch) => {
  dispatch(shouldDeanonymisePropertyActions.request(prop));

  deAnonymiseProperty(prop)
    .then((newValue) => {
      dispatch(
        shouldDeanonymisePropertyActions.receive({
          ...prop,
          value: newValue,
        }),
      );

      dispatch(
        shouldExecuteUdpateEntity({
          entityId: prop.entityId,
          key: prop.key,
          value: newValue,
        }),
      );
    })
    .catch(
      subtleErrorHandling(
        dispatch,
        shouldDeanonymisePropertyActions.invalid,
        false,
        prop,
      ),
    );
};

export const shouldFetchHistoryActions = createAsyncAction('ENTITY_HISTORY');
export const shouldFetchHistory = defaultAsyncCallWithActionsWithParam(
  getHistory,
  shouldFetchHistoryActions,
);

export const externalSearchActions = createAsyncAction(
  'ENTITY_EXTERNAL_SEARCH',
);
export const shouldExternalSearch = defaultAsyncCallWithActions(
  externalSearch,
  externalSearchActions,
  (dispatch) => {
    dispatch(
      shouldShowAlert({
        title: 'Successful request',
        description:
          'Your request to search externally this entity has been received',
        type: 'success',
      }),
    );
  },
);

export const reProcessActions = createAsyncAction('ENTITY_RE_PROCESS');
export const shouldReProcess = defaultAsyncCallWithActions(
  reProcess,
  reProcessActions,
  (dispatch) => {
    dispatch(
      shouldShowAlert({
        title: 'Successful request',
        description: 'Your request to re-process this entity has been received',
        type: 'success',
      }),
    );
  },
);
export const optimisticlyIncrementLocalCurrentEntityFollowCount = createAction(
  'ENTITY_INC_LOCAL_FOLLOW_COUNT',
);
export const optimisticlyDecrementLocalCurrentEntityFollowCount = createAction(
  'ENTITY_DEC_LOCAL_FOLLOW_COUNT',
);

export const updateSelectedEntityForMinimize = createAction(
  'UPDATE_ENTITY_MINIMIZE',
);
export const updateRemoveFromProcessing = createAction(
  'UPDATE_ENTITY_UN-MINIMIZE',
);
export const updateSelectedEntityForAnonymize = createAction(
  'UPDATE-SELECTED-FOR-ANONYMIZE',
);

export const showQuickViewEntityAction = createAction(
  'ENTITY_ENTITY_QUICK_VIEW',
);
export const hideQuickViewEntityAction = createAction(
  'ENTITY_HIDE_ENTITY_QUICK_VIEW',
);
