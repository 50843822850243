import React from 'react';
import { Widget } from 'uxi/Widget';
import qs from 'query-string';
import { FormattedMessage } from '@cluedin/locale';

import IntegrationListContainer from '../containers/IntegrationListContainer';

const AllConfigurationPage = ({ location }) => {
  const queryParameter = qs.parse(location.search || '');
  const type = queryParameter ? queryParameter.type : '';

  return (
    <Widget
      style={{
        borderTop: 'none',
        background: '#fff',
        height: 'calc(100vh - 108px)',
      }}
      title={<FormattedMessage id="module-integration-allIntegrationTitle" />}
    >
      <div style={{ minHeight: '400px', background: '#fff' }}>
        <IntegrationListContainer withInviteButton integrationType={type} />
      </div>
    </Widget>
  );
};

AllConfigurationPage.displayName = 'AllConfigurationPage';

export default AllConfigurationPage;
