import Helmet from 'react-helmet';
import qs from 'query-string';

import SearchV4 from '../containers/SearchV4';
import { GoldenRecordListProvider } from '../../../goldenRecordList/components/containers/GoldenRecordListContext';
import GoldenRecordList from '../../../goldenRecordList/components/containers/GoldenRecordList';
import { useOrganizationFeatureFlagOption } from '../../../featureFlag/hooks/useOrganizationFeatureFlag';

const SearchPageV4 = (props) => {
  const { location } = props;
  const { search } = location;
  const searchQS = qs.parse(search || '');
  const q = searchQS.q;
  const title = q ? `CluedIn - Search for ${q}` : 'CluedIn - Search';

  if (useOrganizationFeatureFlagOption('OldSearch')) {
    return (
      <div>
        <Helmet title={title} />
        <SearchV4 searchQuery={searchQS} />
      </div>
    );
  }

  return (
    <GoldenRecordListProvider
      hasSelection
      hideListSearch
      searchNameKey="q"
      viewName="mainSearch"
    >
      <GoldenRecordList asSearchList />
    </GoldenRecordListProvider>
  );
};

export default SearchPageV4;
