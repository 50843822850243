"use strict";

exports.__esModule = true;
exports.useAllowedActions = void 0;
var _lodash = require("lodash");
var _form = require("@cluedin/form");
var useAllowedActions = function useAllowedActions(actions) {
  var actionsWithoutClaims = (0, _lodash.filter)(actions, function (a) {
    return !a.claims;
  });
  var actionsWithAccess = (0, _lodash.filter)(actions, function (a) {
    return (0, _form.useUserHasAccess)(a.claims);
  });
  return [].concat(actionsWithoutClaims, actionsWithAccess);
};
exports.useAllowedActions = useAllowedActions;