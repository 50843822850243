import styled from 'styled-components';
import { sortBy } from 'lodash';

import { FileUploadRequestWithProgress } from '../../../types';
import { FileUploadProgressLineContainer } from './FileUploadProgressLineContainer';

const LineWrapper = styled.div`
  padding: 12px;
  background: #white;
  border-radius: 2px;
  margin: 12px;
  border: 1px solid #ececec;
`;

const ListWrapper = styled.div`
  .file-upload-progress-list {
    border-bottom: 1px solid #ececec;
  }
  .file-upload-progress-list:first-child {
    border-top: 1px solid #ececec;
  }
`;
type FileUploadProgressListProps = {
  fileUploadProgress: FileUploadRequestWithProgress[];
  onClose: () => void;
};

export const FileUploadProgressList = ({
  fileUploadProgress,
  onClose,
}: FileUploadProgressListProps) => {
  const uploading = sortBy(fileUploadProgress, (c) => c.fileName.toLowerCase());

  return (
    <ListWrapper>
      {uploading.map((fileProgress) => {
        return (
          <LineWrapper className="file-upload-progress-list">
            <FileUploadProgressLineContainer
              fileProgress={fileProgress}
              onClose={onClose}
            />
          </LineWrapper>
        );
      })}
    </ListWrapper>
  );
};
