import React from 'react';
import { FormattedHTMLMessage } from '@cluedin/locale';
import InsightList from './InsightList';

const EntityListNewChanges = ({ viewChanges, savedSearch }) => {
  let content;

  if (!savedSearch.Entities) {
    content = (
      <div>
        <FormattedHTMLMessage id="noNewChanges" />
      </div>
    );
  } else {
    const compatibleInsight = savedSearch.Entities.map((i) => {
      const pseudoEntity = { ...i };
      pseudoEntity.EntityName = i.Name;
      pseudoEntity.EntityId = i.Id;

      return {
        Count: i.Changes && i.Changes.Changes ? i.Changes.Changes.length : 0,
        Entity: i,
      };
    });

    content = (
      <div>
        <InsightList viewChanges={viewChanges} insights={compatibleInsight} />
      </div>
    );
  }

  return <div>{content}</div>;
};

export default EntityListNewChanges;
