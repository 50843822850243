import React from 'react';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent } from 'uxi/Panel';
import PersonalIdentifiers from '../PersonalIdentifiers';

const PersonalIdentifierDialog = ({ show, onClose, personalIdentifiers }) => (
  <Dialog show={show} onClose={onClose}>
    <Panel onClose={onClose} style={{ maxHeight: '80vh' }}>
      <PanelHeader
        onClose={onClose}
        title={'Your current personal identifiers'}
        hasClose
      />
      <PanelContent style={{ padding: '30px' }}>
        <div style={{ paddingBottom: '15px' }}>
          <strong>Personal Identifiers List</strong>
        </div>
        <PersonalIdentifiers personalIdentifiers={personalIdentifiers} />
      </PanelContent>
    </Panel>
  </Dialog>
);

export default PersonalIdentifierDialog;
