import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { allSearchResult } from './allSearchResult';
import { legacyClient } from '../../../data/graphql';
import { useSearchCount } from './useSearchCount';

const defaultInitialVar = {
  query: '*',
};

export const useEntitySearchQuery = (initialVars = defaultInitialVar) => {
  const {
    data = {},
    error,
    refetch,
    loading: loadingRq,
    fetchMore,
    networkStatus,
  } = useQuery(allSearchResult, {
    fetchPolicy: 'network-only',
    client: legacyClient,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...initialVars,
    },
  });

  const [total, { loading: totalLoading }] = useSearchCount(initialVars);

  const loading =
    networkStatus === 1 || // loading
    networkStatus === 3 || // loading
    networkStatus === 2 || // setting varialbes
    networkStatus === 4 || // refetch
    totalLoading ||
    loadingRq;

  const loadingMore = networkStatus === 3;

  const entities = get(data, 'search.entries', []);
  const cursor = get(data, 'search.cursor', '');

  return [
    {
      cursor,
      entities,
      total,
    },
    {
      loading,
      loadingMore,
      error,
      refetch,
      fetchMore,
    },
  ];
};

export default {
  useEntitySearchQuery,
};
