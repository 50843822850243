import { FormattedMessage } from '@cluedin/locale';
import styled from 'styled-components';

import { Badge } from 'uxi/Badge';
import City from 'uxi/Icons/City';

import SocialMenu from '../../../../entityViewModel/components/composites/social/SocialMenu';
import { createOrganizationViewModel } from '../../../../entityViewModel/helpers/organization';
import { HintBadge } from '../../../../../shared/components/HintBadge';
import EntityIcon from '../../../../entity/components/composites/EntityIcon';
import DefaultEntityDates from '../../../components/composites/DefaultEntityDates';

import OrganizationStats from './OrganizationStats';

const OrganizationHeaderContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 0;
  border: 1px solid #ececec;
  margin-top: 24px;
  padding: 24px 0;
`;

const IconWrapper = styled.div`
  margin: 0 24px;
  display: flex;
  align-items: center;
`;

const OrganizationMainInfoWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: start;
`;

const OrganizatonLogoWrapper = styled.div`
  margin: 0 24px;
  display: flex;
  align-items: center;
`;

const OrganizationLogo = styled.div`
  margin: 0 24px;
  border: 1px solid #ececec;
  background: #fff;
`;

const OrganizationMainInfo = styled.div`
  flex: 1;
`;

const OrganizationName = styled.div`
  padding-bottom: 6px;
  color: #2f2f32;
  font-weight: 600;
  font-size: 20px;
`;

const Industry = styled.div`
  font-size: 16px;
`;

const LocationWrapper = styled.div`
  padding: 6px 0;
`;

const LogoImage = styled.img`
  max-height: 80px;
  max-width: 80px;
  object-fit: contain;
`;

const EntityOrganizationHeaderV2 = ({ entity }) => {
  const organizationViewModel = createOrganizationViewModel(entity);
  const isShadowEntity = entity?.data?.isShadowEntity === 'True';

  return (
    <OrganizationHeaderContainer>
      <OrganizationMainInfoWrapper>
        <OrganizatonLogoWrapper>
          {organizationViewModel?.logo?.large ? (
            <OrganizationLogo>
              <LogoImage
                src={organizationViewModel.logo.large}
                alt={organizationViewModel.name}
              />
            </OrganizationLogo>
          ) : (
            <IconWrapper>
              <EntityIcon entityType={entity?.data?.entityType} size="L" />
            </IconWrapper>
          )}
        </OrganizatonLogoWrapper>
        <OrganizationMainInfo>
          <div style={{ display: 'flex' }}>
            <div>
              <OrganizationName>{organizationViewModel.name}</OrganizationName>
              {organizationViewModel?.industry && (
                <Industry>{organizationViewModel.industry}</Industry>
              )}
              {organizationViewModel?.location && (
                <LocationWrapper>
                  <City size={16} style={{ paddingRight: '4px' }} />{' '}
                  {organizationViewModel.location}
                </LocationWrapper>
              )}
              <DefaultEntityDates
                createdDate={entity?.data.createdDate}
                modifiedDate={entity?.data.modifiedDate}
                discoveryDate={entity?.data.discoveryDate}
                lastChangedBy={entity?.data?.lastChangedBy}
                authors={entity?.data?.authors}
              />
            </div>
            {isShadowEntity && (
              <div style={{ marginLeft: '8px' }}>
                <HintBadge
                  label={
                    <FormattedMessage id="module-entityV2-shadow-entity-label" />
                  }
                  hintLabel={
                    <FormattedMessage id="module-entityV2-shadow-entity-tooltip-hint" />
                  }
                />
              </div>
            )}
          </div>
          <div>
            {entity?.data?.tags?.map((t) => (
              <Badge key={t} type="success" style={{ marginRight: '6px' }}>
                {t}
              </Badge>
            ))}
          </div>
          <SocialMenu
            socialViewModel={organizationViewModel.social}
            style={{ marginTop: '12px' }}
          />
        </OrganizationMainInfo>
      </OrganizationMainInfoWrapper>
      <OrganizationStats
        tickersymbol={organizationViewModel.tickersymbol}
        revenue={organizationViewModel.revenue}
        nbemployee={organizationViewModel.nbemployee}
      />
    </OrganizationHeaderContainer>
  );
};

export default EntityOrganizationHeaderV2;
