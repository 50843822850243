import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';

export const GET_GROUPS_WITH_SOURCES_AND_SETS = gql`
  query getAllDataSourceSet($page: Int, $pageSize: Int, $searchName: String) {
    inbound {
      dataSourceSets(
        page: $page
        pageSize: $pageSize
        searchName: $searchName
      ) {
        total
        data {
          id
          name
          author {
            id
            username
          }
          createdAt
          updatedAt
          dataSources {
            id
            type
            name
            hasError
            latestErrorMessage
            errorType
            stopped
            crashedDuringUpload
            sql
            connectionStatus {
              connected
              errorMessage
            }
            author {
              id
              username
            }
            createdAt
            updatedAt

            dataSets {
              id
            }
          }
        }
      }
    }
  }
`;

export const useQueryDataGroupsWithSourcesAndSets = ({
  page,
  pageSize,
  searchTerm,
}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_GROUPS_WITH_SOURCES_AND_SETS,
    {
      variables: {
        pageSize,
        searchName: searchTerm ?? '',
        page: page === 0 ? 0 : page - 1,
      },
      fetchPolicy: 'network-only',
    },
  );

  const dataSourceSets = get(data, 'inbound.dataSourceSets', {});

  return [dataSourceSets, loading, error, refetch];
};
