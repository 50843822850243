import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { fetchAllUsers as fetchAllUsersAction } from '../../actions';
import PageLoader from '../../../core/components/composites/PageLoader';

export const withAuthorFromIdFromState = (Comp, selector) => {
  const mapStateToProps = (
    { user: { allUsers, isFetchingAllUsers, invalidAllUsers } },
    { author },
  ) => {
    if (!allUsers || isFetchingAllUsers) {
      return {};
    }

    const user = allUsers.find((u) => u.Account.Id === author);

    return {
      user: selector ? selector(user) : user,
      isFetching: isFetchingAllUsers,
      invalidAllUsers,
    };
  };

  return connect(mapStateToProps)(Comp);
};

export const withAuthorFromId = (Comp, selector) => {
  const UserListWithFiltersContainer = compose(
    lifecycle({
      componentDidMount() {
        const { fetchAllUsers, isFetching, invalidAllUsers } = this.props;

        if (!isFetching && !invalidAllUsers) {
          fetchAllUsers();
        }
      },
    }),
    branch(
      (props) => props.isFetching || props.isFetchingCurrentUser,
      renderComponent(PageLoader),
    ),
  )(Comp);

  const mapStateToProps = (
    { user: { allUsers, isFetchingAllUsers, invalidAllUsers } },
    { author },
  ) => {
    if (!allUsers || isFetchingAllUsers) {
      return {};
    }

    const user = allUsers.find((u) => u.Account.Id === author);

    return {
      user: selector ? selector(user) : user,
      isFetching: isFetchingAllUsers,
      invalidAllUsers,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    fetchAllUsers: () => {
      dispatch(fetchAllUsersAction());
    },
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserListWithFiltersContainer);
};

export default {
  withAuthorFromId,
};
