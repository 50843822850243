import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Loader } from '@cluedin/atoms';
import Alert from 'uxi/Alert';
import cogoToast from 'cogo-toast';
import isEmptyObj from 'lodash/isEmpty';
import { Stepper } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import {
  CancelButton,
  PrimaryButton,
  PanelContent,
  withConfirmDialog,
  ConfirmCancelDialog,
  PanelFooter,
  PanelHeader,
  PanelClose,
  FormExplanationRequiredFields,
} from '@cluedin/form';

import SqlConnectionStringForm from '../forms/SqlConnectionStringForm';
import FileUploadOptionForm from '../forms/FileUploadOptionForm';
import { withCurrentUser } from '../../../../user/components/Hocs/withCurrentUser';
import { withCreateDataSourceSetMutation } from '../../../hocs/gql/withCreateDataSourceSetMutation';
import SuccessMessage from '../../../../core/components/composites/alerts/SuccessMessage';
import ErrorMessage from '../../../../core/components/composites/alerts/ErrorMessage';
import { useDataBaseConfiguration } from '../../../hooks/useDataBaseConfiguration';
import { InfoLoader } from '../../composites/InfoLoader';
import { isAlphaNumeric, isEmpty } from '../../../../core/validation';
import {
  OnlyAlphaNumbericErrorMessage,
  NotEmptyErrorMessage,
  NotLongErrorMessage,
} from '../../../../core/validation/messages';

const defaultStepperState = { active: 1, completed: [] };

const AddDataBaseWithoutDataSource = ({
  onClose,
  history,
  isSaving,
  currentUser,
  dataSourceSetId,
  createDataSourceSet,
  isTestingConnection,
  errorCreateDataSourceSet,
}) => {
  const [newDataSourceSet, setNewDataSourceSet] = useState();
  const [dataSourceSetName, setDataSourceSetName] = useState('');
  const [selectedDataSourceSetId, setDataSourceSetId] = useState(null);
  const [stepperState, setStepperState] = useState(defaultStepperState);

  const {
    shouldGoNext,
    dataSourceId,
    configuration,
    isProgressing,
    testConnection,
    setShouldGoNext,
    dataSourceName,
    createDataSource,
    prevDataSourceId,
    setConfiguration,
    setDataSourceName,
    prevDataSourceSetId,
    configurationsErrors,
    testConnectionResult,
    isValidConfiguration,
    configurationTouched,
    errorConnectionResult,
    errorCreateDataSource,
    loadingCreateDataSource,
    setConfigurationTouched,
    loadingTestConnectionResult,
  } = useDataBaseConfiguration({
    isSaving,
    dataSourceSetId,
  });

  const handleSecondStep = useCallback(() => {
    setStepperState({
      active: 2,
      completed: [1],
    });
  }, [setStepperState]);

  const isValidNameText = isAlphaNumeric(dataSourceName);
  const isEmptyNameText = isEmpty(dataSourceName) && !!dataSourceName.length;
  const isNotLongDataSourceName = dataSourceName?.length < 256;

  const isValidDataSetName = isAlphaNumeric(dataSourceSetName);
  const isEmptyDataSetName =
    isEmpty(dataSourceSetName) && !!dataSourceSetName.length;
  const isNotLongDataSetName = dataSourceSetName?.length < 256;

  const isValidName =
    !!dataSourceSetName &&
    !!dataSourceName &&
    isValidNameText &&
    isValidDataSetName &&
    !isEmptyDataSetName &&
    !isEmptyNameText &&
    isNotLongDataSourceName &&
    isNotLongDataSetName;

  let isInvalidValidSubmission =
    loadingCreateDataSource ||
    isSaving ||
    (newDataSourceSet && (!isValidName || !isValidDataSetName)) ||
    (!newDataSourceSet && !selectedDataSourceSetId && !isValidName);

  const isValidCancel =
    !!dataSourceSetName || !!dataSourceName || !isEmptyObj(configuration);
  const ButtonWithConfirm = isValidCancel
    ? withConfirmDialog(CancelButton, ConfirmCancelDialog)
    : CancelButton;
  const ClosePanelButton = isValidCancel
    ? withConfirmDialog(PanelClose, ConfirmCancelDialog)
    : PanelClose;

  useEffect(() => {
    if (shouldGoNext && testConnectionResult?.success) {
      handleSecondStep();
    }
  }, [shouldGoNext, handleSecondStep, testConnectionResult]);

  useEffect(() => {
    if (dataSourceSetId && prevDataSourceSetId !== dataSourceSetId) {
      createDataSource({
        dataSourceSetId,
        dataSource: {
          name: dataSourceName,
          type: 'sql',
          configuration,
          author: currentUser?.client ? currentUser.client.Id : '',
        },
      });
    }
  }, [
    currentUser,
    configuration,
    dataSourceName,
    dataSourceSetId,
    createDataSource,
    prevDataSourceSetId,
  ]);

  useEffect(() => {
    if (dataSourceId && prevDataSourceId !== dataSourceId) {
      cogoToast.success(`Database added successfully`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
      history.push(`/admin/inbound/datasourceset/datasource/${dataSourceId}`);
    }
  }, [dataSourceId, prevDataSourceId, history]);

  const reset = () => {
    setStepperState(defaultStepperState);
    setDataSourceName('');
    setDataSourceSetName('');
    setShouldGoNext(false);
  };

  const handleCancel = () => {
    reset();

    onClose();
  };

  return (
    <>
      <PanelHeader
        title={<FormattedMessage id="data-source-import-data-from-database" />}
        onClose={onClose}
        CustomClose={
          <ClosePanelButton
            confirmTitle={
              <FormattedMessage id="data-source-confirm-lost-changes-title" />
            }
            confirmMessage={
              <FormattedMessage id="data-source-confirm-lost-changes-message" />
            }
            onConfirm={() => handleCancel()}
            onClick={handleCancel}
          />
        }
      />

      <Stepper
        activeStep={stepperState.active}
        completedSteps={stepperState.completed}
        steps={[
          { label: <FormattedMessage id="data-source-connection-string" /> },
          { label: <FormattedMessage id="data-source-configure" /> },
        ]}
      />

      <PanelContent>
        {errorCreateDataSource && (
          <div style={{ paddingBottom: '24px' }}>
            <Alert type="danger">{errorCreateDataSource}</Alert>
          </div>
        )}
        {errorCreateDataSourceSet && (
          <div style={{ paddingBottom: '24px' }}>
            <Alert type="danger">{errorCreateDataSourceSet}</Alert>
          </div>
        )}

        {stepperState.active === 1 && (
          <>
            <SqlConnectionStringForm
              value={configuration}
              errors={configurationTouched ? configurationsErrors : {}}
              onChangeKey={(key, value) => {
                configuration[key] = value;

                setConfiguration({ ...configuration });

                setConfigurationTouched(true);
              }}
            />
            <FormExplanationRequiredFields />
          </>
        )}

        {stepperState.active === 2 && !isProgressing && (
          <FileUploadOptionForm
            dataSourceName={dataSourceName}
            newDataSourceSet={newDataSourceSet}
            dataSourceSetName={dataSourceSetName}
            dataSourceSetId={selectedDataSourceSetId}
            onChangeDataSourceSetId={setDataSourceSetId}
            onDataSourceNameChange={(e, v) => setDataSourceName(v)}
            onChangeNewOrExistingDataSourceSet={setNewDataSourceSet}
            onDataSourceSetNameChange={(e, v) => setDataSourceSetName(v)}
            errorDataSourceName={
              isNotLongDataSourceName ? (
                (!isValidNameText && <OnlyAlphaNumbericErrorMessage />) ||
                (isEmptyNameText && <NotEmptyErrorMessage />)
              ) : (
                <NotLongErrorMessage />
              )
            }
            errorDataSetName={
              isNotLongDataSetName ? (
                (!isValidDataSetName && <OnlyAlphaNumbericErrorMessage />) ||
                (isEmptyDataSetName && <NotEmptyErrorMessage />)
              ) : (
                <NotLongErrorMessage />
              )
            }
          />
        )}

        {stepperState.active === 2 && isProgressing && (
          <InfoLoader
            title={
              <FormattedMessage id="data-source-database-info-loader-title" />
            }
            text={
              <FormattedMessage id="data-source-database-info-loader-message" />
            }
          />
        )}
      </PanelContent>

      <PanelFooter>
        {stepperState.active === 1 && (
          <div style={{ marginRight: 'auto' }}>
            {testConnectionResult && testConnectionResult.success && (
              <SuccessMessage>
                <FormattedMessage id="data-source-database-test-connection-success" />
              </SuccessMessage>
            )}

            {(errorConnectionResult ||
              (testConnectionResult && !testConnectionResult.success)) && (
              <ErrorMessage>
                <FormattedMessage id="data-source-database-test-connection-error" />
              </ErrorMessage>
            )}
          </div>
        )}

        {stepperState.active === 1 && (
          <>
            <CancelButton
              rounded
              style={{ marginRight: '8px' }}
              data-test={'importDataFromDataBaseTestConnectionButton'}
              disabled={!isValidConfiguration || loadingTestConnectionResult}
              onClick={() => testConnection(configuration)}
              startIcon={
                loadingTestConnectionResult && !shouldGoNext ? (
                  <Loader color="#fff" size={14} />
                ) : null
              }
            >
              <FormattedMessage id="data-source-database-test-connection" />
            </CancelButton>

            <ButtonWithConfirm
              confirmTitle={
                <FormattedMessage id="data-source-confirm-lost-changes-title" />
              }
              confirmMessage={
                <FormattedMessage id="data-source-confirm-lost-changes-message" />
              }
              onConfirm={handleCancel}
              data-test={'importDataFromDataBaseCancelButton'}
              rounded
              onClick={handleCancel}
            >
              <FormattedMessage id="data-source-cancel" />
            </ButtonWithConfirm>

            <PrimaryButton
              rounded
              className="panel-main-action"
              style={{ marginLeft: '8px' }}
              data-test={'importDataFromDataBaseNextButton'}
              disabled={
                isTestingConnection ||
                !isValidConfiguration ||
                loadingTestConnectionResult
              }
              startIcon={
                shouldGoNext && loadingTestConnectionResult ? (
                  <Loader color="#fff" size={14} />
                ) : null
              }
              onClick={() => {
                if (testConnectionResult?.success) {
                  return handleSecondStep();
                }

                setShouldGoNext(true);
                testConnection(configuration);
              }}
            >
              <FormattedMessage id="data-source-next" />
            </PrimaryButton>
          </>
        )}

        {stepperState.active === 2 && (
          <div style={{ textAlign: 'end' }}>
            <ButtonWithConfirm
              confirmTitle={
                <FormattedMessage id="data-source-confirm-lost-changes-title" />
              }
              confirmMessage={
                <FormattedMessage id="data-source-confirm-lost-changes-message" />
              }
              onConfirm={() => handleCancel()}
              data-test={'importDataFromDataBaseCancelButton'}
              rounded
              onClick={handleCancel}
            >
              <FormattedMessage id="data-source-cancel" />
            </ButtonWithConfirm>

            <PrimaryButton
              rounded
              style={{ marginLeft: '8px' }}
              className="panel-main-action"
              data-test={'importDataFromDataBaseAddButton'}
              disabled={isInvalidValidSubmission || isSaving}
              startIcon={isSaving ? <Loader color="#fff" size={14} /> : null}
              onClick={() => {
                if (
                  (newDataSourceSet && dataSourceSetName) ||
                  (!newDataSourceSet && isValidName)
                ) {
                  createDataSourceSet({ name: dataSourceSetName });
                }

                if (!newDataSourceSet && selectedDataSourceSetId) {
                  createDataSource({
                    dataSourceSetId: selectedDataSourceSetId,
                    dataSource: {
                      name: dataSourceName,
                      type: 'sql',
                      configuration,
                      author: currentUser?.client ? currentUser.client.Id : '',
                    },
                  });
                }
              }}
            >
              <FormattedMessage id="data-source-add" />
            </PrimaryButton>
          </div>
        )}
      </PanelFooter>
    </>
  );
};

export default compose(
  withCurrentUser,
  withCreateDataSourceSetMutation,
  withRouter,
)(AddDataBaseWithoutDataSource);
