"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(FormField) {
  return (0, _styledComponents.default)(FormField)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  display: inline-flex;\n  flex-direction: column;\n  position: relative;\n  // Reset fieldset default style.\n  min-width: 0;\n  padding: 0;\n  margin: 0;\n  border: 0;\n  vertical-align: top; // Fix alignment issue on Safari.\n  &.fullWidth {\n    width: 100%;\n  }\n  &.horizontal {\n    flex-direction: row;\n    align-items: baseline;\n    label {\n      padding-right: ", "px;\n    }\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.spacing.inputSpacing(2);
  });
};
exports.default = _default;