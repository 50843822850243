import styled from 'styled-components';

export const EntityContainer = styled.div`
  background: white;
  height: calc(100vh - 161px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

export const EntityHeaderWrapper = styled.div`
  a {
    font-weight: normal;
  }
`;

export const EntityMainBarWrapper = styled.div``;
