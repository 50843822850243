import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

import SimpleDashboard from '../../../core/components/composites/dashboard/SimpleDashboard';

const { admin } = actionsConfiguration;

const UserDashboard = () => {
  return (
    <SimpleDashboard
      name="user"
      actions={[
        {
          key: 'module-userModule-users',
          icon: 'User',
          name: <FormattedMessage id="module-userModule-users" />,
          link: '/admin/settings/users/all',
          claims: admin.users.user.view,
        },
        {
          key: 'module-userModule-invitations',
          icon: 'Contact',
          name: <FormattedMessage id="module-userModule-invitations" />,
          link: '/admin/settings/user-invitations',
          claims: admin.users.user.view,
        },
        {
          key: 'module-userModule-suggestedUsers',
          icon: 'Group',
          name: <FormattedMessage id="module-userModule-suggestedUsers" />,
          link: '/admin/settings/users/suggested',
          claims: admin.users.user.view,
        },
      ]}
    />
  );
};

export default UserDashboard;
