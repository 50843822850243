import styled from 'styled-components';

export const EdgePropertiesPanelEdgeGroupEntryWrapper = styled.div`
  align-items: center;
  border: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 14px;
  width: 100%;
`;
