import { handleActions } from 'redux-actions';
import {
  shouldOpenInviteUserDialog,
  shouldCloseInviteUserDialog,
  shouldShowEmailIsAlreadyTaken,
  checkIfEmailExistActions,
  inviteUserActions,
} from './actions';

const initialState = {
  inviteUsers: {},
  isOpenInviteUserDialog: false,
  emailAlreadyTakenForInviteUsers: false,
  inviteUserSomethingIsWrong: false,
  inviteUserFeedValueEmail: '',
  inviteUserFeedValueName: '',
  newInviteUserNotifications: [],
  isEmailExisting: {},
};

export default handleActions(
  {
    [shouldOpenInviteUserDialog]: (state, { payload }) => ({
      ...state,
      isOpenInviteUserDialog: true,
      inviteUserFeedValueEmail: payload ? payload.email : '',
      inviteUserFeedValueName: payload ? payload.name : '',
      inviteUsers: {},
      inviteUserSomethingIsWrong: false,
      emailAlreadyTakenForInviteUsers: '',
    }),
    [shouldCloseInviteUserDialog]: (state) => ({
      ...state,
      isOpenInviteUserDialog: false,
      inviteUserFeedValueEmail: '',
      inviteUserFeedValueName: '',
      inviteUsers: {},
      inviteUserSomethingIsWrong: false,
      emailAlreadyTakenForInviteUsers: '',
    }),
    [shouldShowEmailIsAlreadyTaken]: (state, { payload }) => ({
      ...state,
      emailAlreadyTakenForInviteUsers: payload,
    }),
    [checkIfEmailExistActions.request]: (state, { payload }) => {
      const requestIsEmailExisting = state.isEmailExisting;

      requestIsEmailExisting[payload.email] = {
        email: payload.email,
        isFetching: true,
      };

      return {
        ...state,
        isEmailExisting: {
          ...requestIsEmailExisting,
        },
      };
    },
    [checkIfEmailExistActions.receive]: (state, { payload }) => {
      const receiveIsEmailExisting = state.isEmailExisting;

      receiveIsEmailExisting[payload.email].isExisting = payload.isExisting;

      return {
        ...state,
        isEmailExisting: {
          ...receiveIsEmailExisting,
        },
      };
    },
    [inviteUserActions.request]: (state, { payload }) => {
      const newRequestInviteUsers = state.inviteUsers;

      newRequestInviteUsers[payload.email] = {
        email: payload.email,
        isFetching: true,
      };

      return {
        ...state,
        inviteUsers: {
          ...newRequestInviteUsers,
        },
        inviteUserSomethingIsWrong: false,
      };
    },
    [inviteUserActions.receive]: (state, { payload }) => {
      const newReceiveInviteUsers = state.inviteUsers;

      newReceiveInviteUsers[payload.email] = {
        email: payload.email,
        isFetching: false,
        emailAlreadyTakenForInviteUsers: '',
        inviteUserSomethingIsWrong: false,
      };

      return {
        ...state,
        inviteUsers: {
          ...newReceiveInviteUsers,
        },
        isOpenInviteUserDialog: false,
      };
    },
    [inviteUserActions.invalid]: (state, { payload }) => {
      return {
        ...state,
        inviteUserSomethingIsWrong: true,
        inviteUsers: {
          ...state.inviteUsers,
          [payload.email]: {
            ...state.inviteUsers[payload.email],
            isFetching: false,
            invalid: true,
          },
        },
      };
    },
  },
  initialState,
);
