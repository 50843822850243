import styled from 'styled-components';
import { IconButton } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';

const Button = styled(IconButton)`
  border-radius: 4px;
`;

export const FilterIconButtonPlain = (props) => {
  const theme = useTheme();

  return (
    <Button
      theme={theme}
      {...props}
      style={{
        color: !props?.disabled && theme.palette.themePrimary,
        padding: '10px 2px',
        background: 'transparent',
        ...props?.style,
      }}
      iconProps={{
        ...props?.iconProps,
      }}
    />
  );
};
