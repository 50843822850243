import { findProp } from './../../../../wms/helpers/entityPropertyHelper';

export const findEntityDiscussionContent = (entity) => {
  if (!entity) {
    return '';
  }

  if (entity.data && entity.data.description) {
    return entity.data.description;
  }

  const candidates = ['property-slack.discussion.Discussion'];

  const properties = entity.data && entity.data.properties;

  return findProp(properties, candidates);
};

export default {
  findEntityDiscussionContent,
};
