import store from 'store';
import { apiRequest } from '../core/helpers/request';
/**
 * Active: true
 * DisplayName: "Links"
 * Icon: "CopyLink"
 * LayoutConfiguration: null
 * Route: null
 * Type: "/Links"
 */
const makeSureValidPath = (path) => {
  let result = path;
  if (!path) {
    return 'entity/:id';
  }

  if (path[0] === '/') {
    return path.substr(1);
  }

  if (result.indexOf('/:id') === -1) {
    result = `${result}/:id`;
  }

  return result;
};

const toVm = (entityConfigList = []) => {
  return entityConfigList.map((entityConfig) => ({
    displayName: entityConfig.DisplayName,
    icon: entityConfig.Icon,
    route: entityConfig.Route
      ? entityConfig.Route.replace('/:id', '').replace('/', '')
      : 'entity',
    path: entityConfig.Route ? makeSureValidPath(entityConfig.Route) : null,
    entityType: entityConfig.Type,
    type: entityConfig.Type,
    template: entityConfig.LayoutConfiguration,
    id: entityConfig.Id,
  }));
};

export const updateEntityConfigurationFromUpdate = (
  entityConfigurations,
  entityConfig,
) => {
  if (!entityConfig) {
    return entityConfigurations;
  }
  return entityConfigurations.map((e) => {
    if (e.entityType.toLowerCase() === entityConfig.entityType.toLowerCase()) {
      return {
        ...e,
        ...entityConfig,
      };
    }
    return e;
  });
};

export const updateEntityConfigurationFromCreate = (
  entityConfigurations,
  entityConfig,
) => {
  if (!entityConfig) {
    return entityConfigurations;
  }
  return [...entityConfigurations, entityConfig];
};

const fetchWidgetInfo = () => apiRequest('GET', 'api/v1/entityinfo').then(toVm);

export const getWidgetConfiguration = (org) =>
  fetchWidgetInfo(org).then((entityConfigurations) => {
    return {
      entityConfigurations,
    };
  });

export function setLastDateTime(entityId, value) {
  const lastTimeVisited = store.get('lastTimeVisited') || {};
  lastTimeVisited[entityId] = value;

  store.set('lastTimeVisited', lastTimeVisited);
}

export function getLastDateTime() {
  return store.get('lastTimeVisited') || {};
}

export default {
  getWidgetConfiguration,
  setLastDateTime,
  getLastDateTime,
};
