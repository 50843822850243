import React, { memo } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

// import { useTheme } from './JUST_FOR_DEV__ThemeProvider';

const GlobalAppStylesProviderFromThemeElement = styled.section`
  background-color: ${({ theme }) => theme?.appBackground};

  *::selection {
    background-color: ${({ theme }) => theme?.palette?.themePrimary}!important;
  }

  // Global link styles
  a:not([class^='ms-Button']) {
    color: ${({ theme }) => theme?.palette?.themePrimary};
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }

  .ms-Button-icon,
  .ms-SearchBox-icon,
  .ms-ContextualMenu-list i {
    line-height: 0 !important;
  }
`;

export const GlobalAppStylesProviderFromTheme = memo(({ children }) => {
  const theme = useTheme();

  return (
    <GlobalAppStylesProviderFromThemeElement
      theme={theme}
      className="GlobalAppStylesProviderFromTheme"
    >
      {children}
    </GlobalAppStylesProviderFromThemeElement>
  );
});
