import React, { useState } from 'react';
import styled from 'styled-components';
import Close from 'uxi/Icons/Close';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import SearchForm from 'uxi/Input/SearchForm';
import QuickViewWithErrorAndLoading from '../../core/components/composites/QuickViewWithErrorAndLoading';
import EntityPropertyViewerDetailedHeader from './EntityPropertyViewerDetailedHeader';
import EntityPropertyViewerAccordion from './EntityPropertyViewerAccordion';
import EntityPropertyLabel from './EntityPropertyLabel';

const EntityQuickViewWrapper = styled.div`
  box-sizing: border-box;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -ms-overflow-y: scroll;
`;

const ShowingPropertiesLabel = ({ searchedPropsNumber, allPropsNumber }) => {
  return (
    <span>
      <span style={{ fontWeight: 'bold' }}>{searchedPropsNumber}</span>
      <span>
        &nbsp;
        <FormattedMessage id="module-entity-outOf" />
        &nbsp;
      </span>
      <span style={{ fontWeight: 'bold' }}>{allPropsNumber}</span>
      <span>
        &nbsp;
        <FormattedMessage id="module-entity-properties" />
      </span>
    </span>
  );
};

const filterIntegration = (categoryWithProps = [], query) => {
  if (!query) {
    return categoryWithProps;
  }
  return categoryWithProps.filter((p) => {
    return p.propertyKeyName.toLowerCase().indexOf(query.toLowerCase()) > -1;
  });
};

const filterAllIntegrationGroups = (categoryWithProps = [], query) => {
  if (!query) {
    return categoryWithProps;
  }

  return categoryWithProps.map((c) => {
    const filteredProperties = filterIntegration(c.properties, query);

    return {
      ...c,
      properties: [...filteredProperties],
    };
  });
};

const calculateTotal = (cluedInProps = [], integrationProps = []) => {
  let result = cluedInProps.length;

  integrationProps.forEach((i) => {
    result += i.properties.length;
  });

  return result;
};

const EntityPropertyViewerDetailed = ({
  entity,
  open,
  cluedInPropertyViewerViewModels,
  propertyViewerGroupedViewModels,
  isFetchingEntity,
  isFetchingSchema,
  invalidEntity,
  closeEntityQuickView,
  intl,
}) => {
  const [query, setQuery] = useState(null);

  const cluedInPropertyViewerViewModelsFiltered = filterIntegration(
    cluedInPropertyViewerViewModels,
    query,
  );
  const propertyViewerGroupedViewModelsFiltered = filterAllIntegrationGroups(
    propertyViewerGroupedViewModels,
    query,
  );

  const totalNumberShowed = calculateTotal(
    cluedInPropertyViewerViewModelsFiltered,
    propertyViewerGroupedViewModelsFiltered,
  );

  const numberKeys =
    entity && entity.data && entity.data.properties
      ? Object.keys(entity.data.properties || {}).length || 1
      : 1;

  const numberProps = numberKeys - 1;

  return (
    <QuickViewWithErrorAndLoading
      open={open}
      onClose={closeEntityQuickView}
      isFetching={isFetchingEntity || isFetchingSchema}
      invalid={invalidEntity}
    >
      {open && (
        <EntityQuickViewWrapper>
          <div
            style={{
              padding: '16px 16px 0 16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              onClick={() => closeEntityQuickView()}
              style={{
                textAlign: 'right',
                cursor: 'pointer',
                width: '16px',
                marginLeft: 'auto',
                padding: '8px',
              }}
            >
              <Close size="16" />
            </span>
            <div style={{ flex: 1, background: '#fff' }}>
              <div>
                {entity && (
                  <EntityPropertyViewerDetailedHeader entity={entity} />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              padding: '0 16px 16px 16px',
              flexDirection: 'column',
              borderBottom: '1px solid rgb(204, 204, 204)',
            }}
          >
            <EntityPropertyLabel>
              <FormattedMessage id="module-entity-properties" />
            </EntityPropertyLabel>
            {entity && entity.data && (
              <div style={{ marginBottom: '14px' }}>
                <ShowingPropertiesLabel
                  searchedPropsNumber={totalNumberShowed}
                  allPropsNumber={numberProps}
                />
              </div>
            )}
            <div>
              <SearchForm
                placeholder={intl.formatMessage({
                  id: 'module-entity-searchByProperty',
                })}
                isFullWidth
                onChange={(e, v) => {
                  setQuery(v);
                }}
              />
            </div>
          </div>
          <EntityPropertyViewerAccordion
            invalid={invalidEntity}
            isFetching={isFetchingEntity || isFetchingSchema}
            coreVocabProperties={cluedInPropertyViewerViewModelsFiltered}
            integrationProperties={propertyViewerGroupedViewModelsFiltered}
            hasSearch={!!query}
            searchQuery={query}
          />
        </EntityQuickViewWrapper>
      )}
    </QuickViewWithErrorAndLoading>
  );
};

export default injectIntl(EntityPropertyViewerDetailed);
