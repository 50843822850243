import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';

const GET_ACCEPTED_VOCAB_KEYS_BY_ID_AND_KEY = gql`
  query getAcceptedVocabKeysByIdAndKey($entityId: ID, $key: String) {
    virtualization {
      acceptedVocabKeysByIdAndKey(entityId: $entityId, key: $key) {
        supportedVocabKeys {
          key
          displayName
          hasSupport
        }
        notSupportedVocabKeys {
          key
          displayName
          hasSupport
        }
        allVocabKeys {
          key
          displayName
          hasSupport
        }
      }
    }
  }
`;

export const useGetAcceptedVocabKeysByIdAndKey = (entityId, key) => {
  const { data, loading, error } = useQuery(
    GET_ACCEPTED_VOCAB_KEYS_BY_ID_AND_KEY,
    {
      variables: {
        entityId,
        key,
      },
    },
  );

  const response = get(data, 'virtualization.acceptedVocabKeysByIdAndKey');

  return [response, loading, error];
};
