import { createAction } from 'redux-actions';
import { unauthorized } from '../../action/generic';
import { inviteUser, isEmailExisting } from './data';
import { createAsyncAction } from '../../action/helpers/actionCreator';
import { shouldShowAlert } from '../core/actions';
import { shouldFetchAllUserInvitations } from '../userInvitation/actions';

export const shouldOpenInviteUserDialog = createAction(
  'OPEN_INVITE_USER_DIALOG',
);
export const shouldCloseInviteUserDialog = createAction(
  'CLOSE_INVITE_USER_DIALOG',
);

export const shouldShowEmailIsAlreadyTaken = createAction(
  'USER_INVITE_EMAIL_ALREADY_TAKEN',
);
export const checkIfEmailExistActions = createAsyncAction('USER_INVITE_');
export const inviteUserActions = createAsyncAction('USER_INVITE_');

export const shouldInviteUser =
  ({ clientId, email }) =>
  (dispatch) => {
    dispatch(inviteUserActions.request({ email, clientId }));

    return inviteUser({ email, clientId })
      .then((/* resp */) => {
        dispatch(
          shouldShowAlert({
            title: 'Invitation has been sent',
            description: 'The person will soon receive an email.',
            type: 'success',
          }),
        );

        dispatch(inviteUserActions.receive({ email }));
        dispatch(shouldFetchAllUserInvitations());
      })
      .catch(
        unauthorized(dispatch, () => inviteUserActions.invalid({ email })),
      );
  };

export const shouldFindIfEmailExist = (email) => (dispatch) => {
  dispatch(checkIfEmailExistActions.request(email));

  return isEmailExisting(email)
    .then((result) => {
      const isExisting = result ? result.isAvailable : true;

      dispatch(
        checkIfEmailExistActions.receive({
          email,
          isExisting,
        }),
      );
    })
    .catch(unauthorized(dispatch, checkIfEmailExistActions.invalid));
};

export const checkIfEmailExistAndSendInvitation = ({ clientId, email }) => {
  return (dispatch, getState) =>
    dispatch(shouldFindIfEmailExist(email)).then(() => {
      const isExisting = getState().user.isEmailExisting.isExisting;

      if (!isExisting) {
        return dispatch(shouldInviteUser({ email, clientId }));
      }
      return dispatch(shouldShowEmailIsAlreadyTaken(email));
    });
};
