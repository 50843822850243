import { GraphNetwork } from '../../entityRelationsGraphV2/types';
import { TreeEdge } from './types';

export const getOutgoingNodes = (
  treeNetworkState: GraphNetwork,
  outgoingEdges: TreeEdge[],
) => {
  return treeNetworkState?.nodes?.filter((node) =>
    outgoingEdges?.some((edge) => edge?.to === node?.id),
  );
};

export const getIncomingNodes = (
  treeNetworkState: GraphNetwork,
  incomingEdges: TreeEdge[],
) => {
  return treeNetworkState?.nodes?.filter((node) =>
    incomingEdges?.some((edge) => edge?.from === node?.id),
  );
};
