import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import {
  Language as LanguageIcon,
  Profile,
  Settings,
  Signout,
  Task,
  Externallink,
} from 'uxi/Icons';
import { Button, CancelButton, PanelContent, PanelFooter } from '@cluedin/form';
import { ExpandingArrow } from '@cluedin/atoms';
import { DocumentationIcon } from '@cluedin/svgs';

import { CollapseElement } from '../../CollapseElement';
import { ChangeCultureSelect } from '../../../../../locale/components/ChangeCultureSelect';

const MenuButton = styled(Button)`
  margin: 0;
  width: 100%;
  height: 38px;
  border: none;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  box-shadow: none !important;
  background: ${({ theme, isActive }) =>
    isActive && theme.palette.neutralLighterAlt};

  &:hover {
    text-decoration: none;
  }
`;

const ExternallinkWrapper = styled.div`
  margin-right: 6px;
`;

const DocumentationIconWrapper = styled.div`
  svg g {
    fill: ${({ color }) => color};
  }
`;

const LanguageItemWrapper = styled.span`
  width: 100%;
  font-weight: 600;
  display: inline-flex;
  justify-content: space-between;
`;

const renderIcon = (Component, color) => (
  <Component
    size={18}
    width={'18px'}
    height={'18px'}
    color={color}
    style={{ marginRight: '10px' }}
  />
);

export const UserMenuPanel = memo(
  injectIntl(
    ({
      intl,
      values: {
        theme,
        logout,
        entityUrl,
        showSettingsLink,
        showLanguageConfig,
      },
      onClose,
    }) => {
      const [langugeSelectVisible, setLangugeSelectVisible] = useState(false);

      return (
        <>
          <PanelContent style={{ padding: '0px' }}>
            <div
              style={{
                borderTop: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
              }}
            >
              {showLanguageConfig && (
                <>
                  <MenuButton
                    theme={theme}
                    isActive={langugeSelectVisible}
                    onClick={() => setLangugeSelectVisible((p) => !p)}
                    startIcon={renderIcon(
                      LanguageIcon,
                      theme.palette.neutralPrimary,
                    )}
                  >
                    <LanguageItemWrapper>
                      {intl.formatMessage({ id: 'module-userMenu-language' })}

                      <ExpandingArrow
                        size={14}
                        color={theme.palette.themePrimary}
                        expanded={langugeSelectVisible}
                      />
                    </LanguageItemWrapper>
                  </MenuButton>

                  <CollapseElement
                    isVisible={langugeSelectVisible}
                    style={{ overflow: 'inherit' }}
                  >
                    <ChangeCultureSelect />
                  </CollapseElement>
                </>
              )}

              <MenuButton
                href="/me"
                startIcon={renderIcon(Task, theme.palette.neutralPrimary)}
                text={intl.formatMessage({ id: 'module-userMenu-myTasks' })}
              />
              <MenuButton
                href={entityUrl}
                startIcon={renderIcon(Profile, theme.palette.neutralPrimary)}
                text={intl.formatMessage({ id: 'module-userMenu-viewProfile' })}
              />
              {showSettingsLink && (
                <MenuButton
                  href="/settings/user"
                  startIcon={renderIcon(Settings, theme.palette.neutralPrimary)}
                  text={intl.formatMessage({ id: 'CluedInUserMenu.Settings' })}
                />
              )}

              <MenuButton
                href="https://documentation.cluedin.net/"
                startIcon={
                  <DocumentationIconWrapper
                    color={theme.palette.neutralPrimary}
                  >
                    {renderIcon(DocumentationIcon)}
                  </DocumentationIconWrapper>
                }
                target="_blank"
                text={<FormattedMessage id="module-userMenu-documentation" />}
              >
                <ExternallinkWrapper>
                  <Externallink size={14} />
                </ExternallinkWrapper>
              </MenuButton>

              <MenuButton
                onClick={logout}
                text={intl.formatMessage({ id: 'CluedInUserMenu.SignOut' })}
                startIcon={renderIcon(Signout, theme.palette.neutralPrimary)}
              />
            </div>
          </PanelContent>

          <PanelFooter>
            <CancelButton
              rounded
              onClick={onClose}
              text={intl.formatMessage({ id: 'cancel' })}
            />
          </PanelFooter>
        </>
      );
    },
  ),
);
