import { removeFirstCharacter } from '../core/helpers/string';

const toAnnotationType = (type) => {
  if (type === 'Text') {
    return 'String';
  }

  if (type === 'Integer') {
    return 'Number';
  }

  if (type === 'Integer') {
    return 'Number';
  }

  if (type === 'Boolean') {
    return 'Boolean';
  }

  return 'String';
};

export const createAnnotationForVocab = (allVocab = []) => {
  const properties = allVocab.map((vocab) => {
    return {
      displayName: vocab.Key.substr(vocab.Key.indexOf('.')).replace(/\./g, ' '),
      key: vocab.Key.replace(/\./g, '_'), // Elasticsearch does not like dots
      vocabKey: vocab.Key,
      coreVocab: vocab.Key,
      useAsEntityCode: false,
      type: toAnnotationType(vocab.DataType),
    };
  });
  ['name', 'description'].forEach((p) =>
    properties.push({
      displayName: p,
      key: p,
      vocabKey: p,
      useAsEntityCode: false,
      type: 'String',
    }),
  );
  return properties;
};

export const validateProperty = (property) => {
  const errors = {};

  let numberOfErrors = 0;

  if (!property.key) {
    numberOfErrors += 1;
    errors.key = 'Please enter a valid key';
  }
  if (!property.displayName) {
    numberOfErrors += 1;
    errors.displayName = 'Please enter a displayName';
  }
  if (!property.type) {
    numberOfErrors += 1;
    errors.type = 'Please select a type';
  }

  return {
    errors,
    numberOfErrors,
  };
};

export const validateProperties = (properties = []) => {
  const allErrors = [];

  properties.forEach((p, index) => {
    const errorsRestul = validateProperty(p);

    allErrors.push({
      ...errorsRestul,
      index,
    });
  });

  return allErrors;
};

export const getTypeFromSchema = (schema) => {
  if (schema.DataType.toLowerCase() === 'text') {
    return 'String';
  }

  if (schema.DataType.toLowerCase() === 'boolean') {
    return 'Boolean';
  }

  if (
    schema.DataType.toLowerCase() === 'integer' ||
    schema.DataType.toLowerCase() === 'number'
  ) {
    return 'Number';
  }

  return null;
};

export const createDefaultValuesForProperty = (key, schema) => {
  if (schema) {
    let displayName = schema.Key.substr(schema.Key.indexOf('.')).replace(
      /\./g,
      ' ',
    );

    if (displayName[0] === ' ') {
      displayName = removeFirstCharacter(displayName);
    }

    return {
      key: key.replace(/\./g, '_'), // Elasticsearch does not like dots
      vocabKey: key,
      displayName,
      coreVocab: schema.Key,
      useAsEntityCode: false,
      type: toAnnotationType(schema.DataType),
    };
  }

  return {
    key: key.replace(/\./g, '_'),
    vocabKey: key,
    displayName: key,
    coreVocab: '',
    useAsEntityCode: false,
    type: '',
  };
};

export default {
  getTypeFromSchema,
  createAnnotationForVocab,
  validateProperty,
  validateProperties,
  createDefaultValuesForProperty,
};
