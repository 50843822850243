import React from 'react';
import styled from 'styled-components';
import { Flex } from 'uxi/Layout';
import PropertyFilterToggler from './PropertyFilterToggler';
import SearchSortSelect from '../SearchSortSelect';
import SearchStats from './SearchStats';

const SearchHeaderRoot = styled.div`
  background: #fff;
`;

const SearchHeaderWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  border-bottom: 1px solid #cecece;
`;

const SearchHeader = ({
  total,
  sortType,
  onSortChange,
  selectedFilters,
  onShowPropertyFilters,
  onHidePropertyFilters,
  showPropertyFilterSearch,
  totalLoading,
}) => {
  return (
    <SearchHeaderRoot>
      <SearchHeaderWrapper>
        <PropertyFilterToggler
          selectedFilters={selectedFilters}
          onShowPropertyFilters={onShowPropertyFilters}
          onHidePropertyFilters={onHidePropertyFilters}
          showPropertyFilterSearch={showPropertyFilterSearch}
        />
        <Flex
          style={{
            marginLeft: 'auto',
            flexFlow: 'row wrap',
            justifyContent: 'flex-end',
          }}
        >
          <SearchStats
            style={{ marginRight: '6px' }}
            totalLoading={totalLoading}
            total={total}
          />
          <div style={{ maxWidth: '216px' }}>
            <SearchSortSelect value={sortType} onChange={onSortChange} />
          </div>
        </Flex>
      </SearchHeaderWrapper>
    </SearchHeaderRoot>
  );
};

export default SearchHeader;
