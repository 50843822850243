import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Select } from '@cluedin/form';
import { integrationType } from '../../../config/integrationType';

export const filterPerType = (integrations, type) =>
  integrations.filter((integration) => {
    if (!type || type === 'all') {
      return true;
    }
    return (
      integration.config &&
      integration.config.type &&
      integration.config.type.indexOf(type) > -1
    );
  });

const IntegrationTypeFilter = ({
  onChange,
  integrationType: integrationTypeDefaultValue,
}) => {
  const optionAll = {
    value: 'all',
    label: <FormattedMessage id="module-integration-allTypes" />,
  };

  const options = integrationType.map((type) => ({
    key: type,
    value: type,
    label: <span>{type}</span>,
  }));

  const handleChange = ({ value }) => onChange(value);

  return (
    <Select
      defaultValue={{ value: integrationTypeDefaultValue || 'all' }}
      value={integrationTypeDefaultValue}
      options={[optionAll, ...options]}
      onChange={handleChange}
    />
  );
};

export default IntegrationTypeFilter;
