import { useLazyQuery, gql } from '@apollo/client';
import get from 'lodash/get';

const GET_EDGE_PROPERTIES = gql`
  query getEdgeProperties(
    $entityId: ID!
    $edgeType: String
    $edgeDirection: String
  ) {
    virtualization {
      getEdgeProperties(
        entityId: $entityId
        edgeType: $edgeType
        edgeDirection: $edgeDirection
      )
    }
  }
`;

export const useGetEdgeProperties = () => {
  const [getEdgeProperties, { data, loading, error }] = useLazyQuery(
    GET_EDGE_PROPERTIES,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    },
  );

  const result = get(data, 'virtualization.getEdgeProperties');

  return [
    (entityId, edgeType, edgeDirection) =>
      getEdgeProperties({
        variables: {
          entityId,
          edgeType,
          edgeDirection,
        },
      }),
    {
      data: result,
      loading,
      error,
    },
  ];
};
