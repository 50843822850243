import { useState, useMemo, useCallback } from 'react';

import { useVocabularies } from '../../dataCatalog/hooks/useVocabularies';

export const usePaginatedVocabularies = ({
  pageNumber = 1,
  defaultPageSize = 10,
  initialSearchName = '',
  loadMoreLabel = 'Load more',
  useQueryVocabularies = useVocabularies,
}) => {
  const [searchVocabularies, setSearchVocabularies] =
    useState(initialSearchName);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [vocabularies, isLoadingVocabularies, errorLoadingVocabularies] =
    useQueryVocabularies({
      pageNumber,
      pageSize: pageSize,
      searchName: searchVocabularies,
    });

  const onLoadMore = useCallback(
    () => setPageSize((p) => p + defaultPageSize),
    [defaultPageSize],
  );

  const totalVocabularies = useMemo(
    () => vocabularies?.total,
    [vocabularies?.total],
  );

  const loadMore = useMemo(() => {
    if (totalVocabularies <= pageSize) return;

    return {
      onClick: onLoadMore,
      label: loadMoreLabel,
    };
  }, [totalVocabularies, pageSize, onLoadMore]);

  return {
    loadMore,
    totalVocabularies,
    isLoadingVocabularies,
    setSearchVocabularies,
    errorLoadingVocabularies,
    vocabularies: vocabularies?.data,
  };
};
