import React, { useMemo } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { withWorkflow } from '@cluedin/form';

import AddDataBaseWithDataSet from '../components/containers/AddDataBase/AddDataBaseWithDataSet';
import AddDataBaseWithoutDataSet from '../components/containers/AddDataBase/AddDataBaseWithoutDataSet';

export const withImportFromDatabase = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => (
          <Comp
            {...props}
            openImportFromDataBaseWithExistingGroup={openWorkflow}
          />
        ),
        [props],
      ),
    (panelProps) => (
      <AddDataBaseWithDataSet {...panelProps} dataSourceSetId={props.id} />
    ),
    {
      title: (
        <>
          <FormattedMessage id="data-source-import-data-from-database-into" />{' '}
          {props.name} <FormattedMessage id="data-source-group-low" />
        </>
      ),
      panelType: 'medium',
      withCustomHeader: true,
    },
  );

  return <EnhancedhWorkflow />;
};

export const withImportFromDatabaseWitoutDataSet = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => <Comp {...props} openImportFromDataBase={openWorkflow} />,
        [props],
      ),
    (panelProps) => <AddDataBaseWithoutDataSet {...panelProps} />,
    {
      title: <FormattedMessage id="data-source-import-data-from-database" />,
      panelType: 'medium',
      withCustomHeader: true,
    },
  );

  return <EnhancedhWorkflow />;
};
