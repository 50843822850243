import React from 'react';
import { TextField, FormDecorator, Checkbox } from '@cluedin/form';
import { Field } from 'redux-form';
import CheckboxList from './CheckboxList';
import TreeCheckBoxList from './TreeCheckBoxList';
import FormFieldAddon from './FormFieldAddon';
import { required, url } from '../../../helpers/form';
import { get } from 'lodash';

const FormDecoratorFn = (Input, options) => (props) => {
  const value = get(props, 'input.value', '');
  return (
    <>
      <Input
        {...options}
        {...props}
        {...props.input}
        value={value}
        success={props.meta.touched && !props.meta.error}
        error={props.meta.touched && props.meta.error}
      />
    </>
  );
};

const UrlFormDecorator = (Input) => (field) => {
  const value = get(field, 'input.value', '');

  return (
    <FormFieldAddon pre={field.protocol}>
      <Input
        {...field}
        {...field.input}
        value={value}
        success={field.meta.touched && !field.meta.error}
        error={field.meta.touched && field.meta.error}
      />
    </FormFieldAddon>
  );
};

const SubDomainFormDecorator = (Input) => (field) => {
  const value = get(field, 'input.value', '');

  return (
    <FormFieldAddon pre={field.protocol} post={field.domain}>
      <Input
        {...field}
        {...field.input}
        value={value}
        success={field.meta.touched && !field.meta.error}
        error={field.meta.touched && field.meta.error}
      />
    </FormFieldAddon>
  );
};

const ReduxFormInput = FormDecoratorFn(TextField);
const ReduxFormPassword = FormDecoratorFn(TextField, { type: 'password' });
const ReduxCheckboxList = FormDecoratorFn(CheckboxList);
const ReduxTreeCheckBoxList = FormDecoratorFn(TreeCheckBoxList);

const ReduxFormUrl = UrlFormDecorator(TextField, { type: 'url' });
const ReduxFormSubdomain = SubDomainFormDecorator(TextField, {});
const ReduxCheckbox = FormDecoratorFn(Checkbox);

const selectNormalize = (field) => {
  if (field.type === 'url') {
    return (value) => (value ? `${field.protocol}${value}` : '');
  }

  return null;
};

const selectFormat = (field) => {
  if (field.type === 'url') {
    return (value) => (value ? value.replace(field.protocol, '') : '');
  }

  return null;
};
const SelectInput = (field) => {
  if (field.type === 'input') {
    return ReduxFormInput;
  }

  if (field.type === 'list') {
    return ReduxCheckboxList;
  }

  if (field.type === 'checkbox') {
    return ReduxCheckbox;
  }

  if (field.type === 'tree') {
    return ReduxTreeCheckBoxList;
  }

  if (field.type === 'password') {
    return ReduxFormPassword;
  }

  if (field.type === 'url') {
    return ReduxFormUrl;
  }

  if (field.type === 'subdomain') {
    return ReduxFormSubdomain;
  }

  return ReduxFormInput;
};

const getValidation = (field) => {
  const result = [];
  if (!field) {
    return result;
  }

  if (field.isRequired) {
    result.push(required);
  }

  if (field.type === 'url') {
    result.push(url);
  }

  return result;
};

const FormFieldGenerator = ({ field }) => (
  <FormDecorator
    isRequired={field.isRequired}
    label={field.displayName}
    helpText={field.help}
    errorText={field.error}
  >
    <Field
      {...field}
      name={`helperConfiguration.${field.name}`}
      component={SelectInput(field)}
      options={field.options}
      validate={getValidation(field)}
      format={selectFormat(field)}
      normalize={selectNormalize(field)}
    />
  </FormDecorator>
);

FormFieldGenerator.displayName = 'FormFieldGenerator';

export default FormFieldGenerator;
