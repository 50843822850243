import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@cluedin/theme';

const InsightSearchLinkRouterLinkStyle = {
  link: {
    textDecoration: 'none',
    fontSize: '14px',
    display: 'flex',
  },
};

const InsightSearchLinkRouterLink = ({ query, children }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const linkStyle = InsightSearchLinkRouterLinkStyle.link;

  return (
    <div>
      <Link
        style={{
          ...linkStyle,
          color: themePrimary,
        }}
        to={`/search/?q=${query}`}
      >
        {children}
      </Link>
    </div>
  );
};

export default InsightSearchLinkRouterLink;
