import { CSSProperties } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { IconButton } from '@fluentui/react/lib/Button';
import { FileError } from 'react-dropzone';
import { formatBytes } from './utils';
import { AddOnText } from './AddOnText';
import { FileIcon } from './FileIcon';

const FileRejectedWrapper = styled.div`
  border-top: 1px solid #ccc;
  padding: 12px;
  display: flex;
  align-items: flex-start;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type FileRejectedItemProps = {
  file: File;
  errors: FileError[];
  handleRemoveFile: () => void;
  style?: CSSProperties;
};

const FileRejectedContent = styled.div`
  flex: 1;
`;

export const FileRejectedItem = ({
  file,
  errors,
  handleRemoveFile,
  style = {},
}: FileRejectedItemProps) => {
  const theme = useTheme();

  return (
    <FileRejectedWrapper style={style}>
      <FileIcon type="error" />
      <FileRejectedContent>
        <div>{file.name}</div>
        <AddOnText>Size: {file.size > 0 && formatBytes(file.size)}</AddOnText>
        <div>
          {(errors || []).map((e: FileError) => (
            <div style={{ color: theme.semanticColors.errorText }} key={e.code}>
              {e.message}
            </div>
          ))}
        </div>
      </FileRejectedContent>
      <ActionButtonWrapper>
        <IconButton
          data-test={'deleteFileIcon'}
          onClick={handleRemoveFile}
          iconProps={{
            iconName: 'Delete',
            style: {
              height: 14,
              fontSize: 14,
              marginBottom: 4,
              lineHeight: '14px',
              color: theme.semanticColors.errorText,
            },
          }}
        />
      </ActionButtonWrapper>
    </FileRejectedWrapper>
  );
};
