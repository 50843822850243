import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { FlexHolyAlbatross } from 'uxi/Layout';
import { getScaledValueWithMetricNotation } from '../../../../core/helpers/numbers';

const EntityStats = styled.div`
  background: #fff;
  border-top: 1px solid #ececec;
  margin-bottom: -24px;
  margin-top: 24px;
`;

const EntityStatLi = styled.li`
  margin: 0;
  padding: 8px 16px;
  display: inline-block;
  text-align: left;
  border-left: 1px solid #ececec;
  white-space: nowrap;
`;

const StatTitle = styled.div`
  font-size: 12px;
`;

const StatNumber = styled.div`
  font-size: 18px;
`;

const OrganizationStats = ({ tickersymbol, revenue, nbemployee }) => {
  let tickerHtml;
  let revenueHtml;
  let nbEmployeeHtml;
  let statsHtml;

  if (nbemployee) {
    nbEmployeeHtml = (
      <EntityStatLi>
        <StatTitle>
          <FormattedMessage id="module-entityWidget-organizatoinHeaderEmployees" />
        </StatTitle>
        <StatNumber>{nbemployee}</StatNumber>
      </EntityStatLi>
    );
  }

  if (tickersymbol) {
    tickerHtml = (
      <EntityStatLi>
        <StatTitle>
          <FormattedMessage id="module-entityWidget-organizatoinHeaderSymbol" />
        </StatTitle>
        <StatNumber>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`http://finance.yahoo.com/q?s=${tickersymbol}`}
          >
            {tickersymbol}
          </a>
        </StatNumber>
      </EntityStatLi>
    );
  }

  if (revenue) {
    try {
      const [
        match, // eslint-disable-line no-unused-vars
        revenueParsed,
        currency,
      ] = revenue.toString().match(/(\d*)(\w*)/);

      const scaledRevenue = getScaledValueWithMetricNotation(
        parseInt(revenueParsed, 10),
      );

      revenueHtml = (
        <EntityStatLi>
          <StatTitle>
            <FormattedMessage id="module-entityWidget-organizatoinHeaderRevenue" />
          </StatTitle>
          <StatNumber>
            {currency} {scaledRevenue} /{' '}
            <FormattedMessage id="OrganizationHeader.Year" />
          </StatNumber>
        </EntityStatLi>
      );
    } catch (e) {
      revenueHtml = (
        <EntityStatLi>
          <StatTitle>
            <FormattedMessage id="module-entityWidget-organizatoinHeaderRevenue" />
          </StatTitle>
          <StatNumber>{revenue}</StatNumber>
        </EntityStatLi>
      );
    }
  }

  if (nbEmployeeHtml || revenueHtml) {
    statsHtml = (
      <EntityStats>
        <FlexHolyAlbatross
          as={'ul'}
          style={{
            padding: 0,
            margin: 0,
            width: '100%',
          }}
        >
          {tickerHtml}
          {nbEmployeeHtml}
          {revenueHtml}
        </FlexHolyAlbatross>
      </EntityStats>
    );
  }

  return statsHtml;
};

export default OrganizationStats;
