import { setContext } from '@apollo/client/link/context';
import { getCurrentToken } from '../../modules/core/helpers/storage';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCurrentToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default authLink;
