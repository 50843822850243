import React from 'react';
import { Select } from '@cluedin/form';
import { injectIntl } from '@cluedin/locale';

const SearchSortSelect = injectIntl(({ intl, value, onChange }) => {
  const options = [
    {
      label: intl.formatMessage({ id: 'module-search-sortByRelevance' }),
      value: 'relevance',
    },
    {
      label: intl.formatMessage({ id: 'module-search-sortByNew' }),
      value: 'new',
    },
    {
      label: intl.formatMessage({ id: 'module-search-sortByOld' }),
      value: 'old',
    },
  ];

  return (
    <div style={{ width: '180px' }}>
      <Select
        value={value}
        onChange={(item) => onChange(item.value)}
        options={options}
      />
    </div>
  );
});

export default SearchSortSelect;
