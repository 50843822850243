import styled from 'styled-components';
import { TimeFromNow } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

const DateWrapper = styled.div`
  padding-bottom: 4px;
  font-size: 12px;
  color: #707070;
  display: flex;
`;

const DateLabel = styled.div`
  margin-right: 4px;
`;

const DefaultEntityDates = ({
  createdDate,
  modifiedDate,
  discoveryDate,
  lastChangedBy,
  authors = [],
}) => {
  return (
    <>
      {createdDate && (
        <DateWrapper>
          <DateLabel>
            <FormattedMessage id="module-entityWidget-createdDateLabel" />
          </DateLabel>
          <TimeFromNow date={createdDate} />
        </DateWrapper>
      )}
      {authors?.length > 0 && (
        <DateWrapper>
          <DateLabel>
            <FormattedMessage id="module-entityWidget-createdBy" />
          </DateLabel>
          <div>
            {authors.map((author) => {
              return <span style={{ marginRight: '6px' }}>{author.name}</span>;
            })}
          </div>
        </DateWrapper>
      )}
      {modifiedDate && (
        <DateWrapper>
          <DateLabel>
            <FormattedMessage id="module-entityWidget-modifiedDateLabel" />
          </DateLabel>
          <TimeFromNow date={modifiedDate} />
        </DateWrapper>
      )}
      {lastChangedBy?.name && (
        <DateWrapper>
          <DateLabel>
            <FormattedMessage id="module-entityWidget-modifiedBy" />
          </DateLabel>
          <div>{lastChangedBy?.name}</div>
        </DateWrapper>
      )}
      {discoveryDate && (
        <DateWrapper>
          <DateLabel>
            <FormattedMessage id="module-entityWidget-discoveryDateLabel" />
          </DateLabel>
          <TimeFromNow date={discoveryDate} />
        </DateWrapper>
      )}
    </>
  );
};

export default DefaultEntityDates;
