import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_EDGE_SUMMARY = gql`
  query getEdgeSummary($id: ID!) {
    virtualization {
      entityEdgeSummary(id: $id) {
        id
        type
        name
        icon
        route
        displayName
        attributeOrigin
        codes
        edges {
          edgeType
          direction
          entityType
          entityId
          icon
          route
          name
          attributeOrigin
          codes
          createdDate
          isGrouped
          entityCount
          kind
        }
      }
    }
  }
`;

export const useQueryEntityEdgeSummaryById = (id) => {
  const { data, loading, error } = useQuery(GET_EDGE_SUMMARY, {
    variables: { id },
  });

  const edgeSummary = get(data, 'virtualization.entityEdgeSummary');

  return [edgeSummary, loading, error];
};
