/* eslint no-underscore-dangle: ["error", { "allow": ["__token"] }] */
import { toVM } from '../modules/entity/viewModel';
import { apiRequest } from '../modules/core/helpers/request';

//only use by a websocket update entity
//needs to update signalr
export const getEntity = (id) =>
  apiRequest('GET', `api/entity?id=${id}`).then((resp) =>
    toVM(resp.body, false, resp.__token),
  );

export const getSchema = () => apiRequest('GET', 'api/v1/entity/schema');
