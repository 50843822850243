import { compose, lifecycle, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { clientId } from '../../../../config';
import { shouldLogout } from '../../../auth/actions';

const withCountDownState = compose(
  withStateHandlers(
    () => ({
      countDown: 10,
    }),
    {
      deCrementCountDown:
        ({ countDown }) =>
        () => ({
          countDown: countDown > 0 ? countDown - 1 : 0,
        }),
    },
  ),
  lifecycle({
    componentDidMount() {
      const { deCrementCountDown } = this.props;
      setInterval(() => {
        deCrementCountDown();
      }, 1000);
    },
    componentDidUpdate() {
      const { countDown, goToLogin } = this.props;
      if (countDown === 1) {
        goToLogin();
      }
    },
  }),
);

const mapDispatchToProps = (dispatch) => ({
  goToLogin() {
    const { pathname } = window.location;
    dispatch(shouldLogout(clientId, pathname));
  },
});

export const withCountDown = (Comp) => {
  return withRouter(
    connect(() => ({}), mapDispatchToProps)(withCountDownState(Comp)),
  );
};

export default withCountDown;
