import React, { useMemo } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { withWorkflow } from '@cluedin/form';

import ManualClueCreation from '../components/pages/ManualClueCreation/ManualClueCreation';

export const withManualClueCreation = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => <Comp {...props} openManualClueCreation={openWorkflow} />,
        [props],
      ),
    (panelProps) => <ManualClueCreation {...panelProps} />,
    {
      title: <FormattedMessage id="module-manualDataEntry-displayName" />,
      panelType: 'medium',
    },
  );

  return <EnhancedhWorkflow />;
};
