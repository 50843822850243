export default {
  iconWrapper: {
    width: '40px',
    textAlign: 'center',
  },
  icon: {
    marginTop: '12px',
  },
  container: {
    flex: 1,
    wordBreak: 'break-all',
  },
  containerWrapper: {
    position: 'relative',
    display: 'flex',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  close: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
};
