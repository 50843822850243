import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import SigninForm from './SigninForm';
import PublicContext from '../composites/PublicContext';
import CreateWithNotYourTeam from '../composites/CreateWithNotYourTeam';
import SignUpIllustration from '../../assets/signin-illustration.png';

const SignIn = () => (
  <NotSignedInLayoutDividedInTwo
    Page={SigninForm}
    PageExtra={CreateWithNotYourTeam}
    Context={PublicContext}
    url={`${SignUpIllustration}`}
  />
);

export default SignIn;
