import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';

const NoValue = styled.div`
  font-style: italic;
  color: #ccc;
`;

const ValueCell = ({ value }) => {
  return (
    <span>
      {value ? (
        <span>{value}</span>
      ) : (
        <NoValue>
          <FormattedMessage id="module-goldenRecordList-noValue" />
        </NoValue>
      )}
    </span>
  );
};

export const VocabularyValueCellComponentStandAlone = ({
  entity,
  property,
}) => {
  const value = entity.properties[`property-${property}`] || entity[property];

  return <ValueCell value={value} />;
};

const VocabularyValueCellComponent = (p) => (entity) => {
  const value = entity.properties[`property-${p}`] || entity[p];
  return <ValueCell value={value} />;
};

export default VocabularyValueCellComponent;
