import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import Dialog from 'uxi/Dialog';
import Panel, { PanelHeader, PanelContent, PanelFooter } from 'uxi/Panel';
import { AtomButton } from '@cluedin/components';

const SomethingOdd = ({ open }) => (
  <Dialog
    show={open}
    onClose={() => {
      window.location.reload();
    }}
  >
    <Panel>
      <PanelHeader
        hasClose
        style={{ background: '#d13f48' }}
        title={<FormattedMessage id="module-core-defaultErrorTitle" />}
        onClose={() => {
          window.location.reload();
        }}
      />
      <PanelContent
        style={{ padding: '15px', maxHeight: 'calc(80vh - calc( 2 * 50px ))' }}
      >
        <div>
          <FormattedHTMLMessage id="module-core-defaultError" />
        </div>
      </PanelContent>
      <PanelFooter>
        <AtomButton
          onClick={() => {
            window.location.reload();
          }}
          color="error"
        >
          <FormattedMessage id="module-core-tryPageReload" />
        </AtomButton>
      </PanelFooter>
    </Panel>
  </Dialog>
);

export default SomethingOdd;
