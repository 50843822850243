import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

const USERS = gql`
  query getUsers {
    administration {
      users {
        id
        roles
        logoUrl
        accountId
        entityId
        lockoutEnabled
        Account
        Entity
      }
    }
  }
`;

export const useQueryUsers = () => {
  const { data, loading, error, refetch } = useQuery(USERS);

  const users = get(data, 'administration.users', []);

  return [users, loading, error, refetch];
};
