import { FC, useEffect } from 'react';
import { PanelHeader } from '@cluedin/form';
import { get } from 'lodash';
import { FormattedMessage } from '@cluedin/locale';

import { EntityNodePanelInfoWrapper } from './EntityNodePanelInfoWrapper';
import { EntityNodePanelInfoFooter } from './EntityNodePanelInfoFooter';
import { useGetEntityById } from '../../../entityProperties/hooks/getEntityById';
import { SelectedEntityNode } from '../types';
import { EntityNodePanelDetail } from './EntityNodePanelDetail';
import { EntityNodePanelList } from './EntityNodePanelList';
import { EntityNodePanelCodes } from './EntityNodePanelCodes';

type EntityNodePanelInfoProps = {
  selectedNode: SelectedEntityNode;
  onClose: () => void;
};

export const EntityNodePanelInfo: FC<EntityNodePanelInfoProps> = ({
  selectedNode,
  onClose,
}) => {
  const entityId = selectedNode?.entityId ?? selectedNode?.id;
  const [data, loading, error] = useGetEntityById(entityId);

  const entityProperties = get(
    data,
    'virtualization.entityById.entityProperties',
  );

  const handleOnClose = () => {
    onClose?.();
  };

  return (
    <>
      <PanelHeader
        title={
          <FormattedMessage id="module-entityRelationsGraphV2-entityInformationLabel" />
        }
        onClose={handleOnClose}
      />

      <EntityNodePanelInfoWrapper>
        <EntityNodePanelDetail selectedNode={selectedNode} />

        <EntityNodePanelCodes selectedNode={selectedNode} />

        <EntityNodePanelList
          entityProperties={entityProperties}
          loading={loading}
          error={error}
        />

        <EntityNodePanelInfoFooter onClose={handleOnClose} />
      </EntityNodePanelInfoWrapper>
    </>
  );
};
