import { useState, useEffect } from 'react';
import { apiRequest } from '../../../../core/helpers/request';
// import { fromServertoGQLVm } from '../../../../entity/viewModel';

const getEntityRelations = (id) => {
  return apiRequest('GET', `api/v1/entitysearch?id=${id}`);
  // .then(resp => (
  //   resp.map(entity => (fromServertoGQLVm(entity, resp.__token)))
  // ));
};

export const useAggregationSearchForEntity = (id) => {
  const [data, setData] = useState({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    setData({ ...data, error: null, loading: true });

    getEntityRelations(id)
      .then(async (entities) => {
        setData({
          ...data,
          data: entities,
          loading: false,
        });
      })
      .catch(() => {
        setData({
          error: true,
          loading: false,
        });
      });
  }, []);

  return {
    ...data,
  };
};
