import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Widget } from 'uxi/Widget';
import { actionsConfiguration, useUserHasAccess } from '@cluedin/components';

import ConfigurationListContainer from '../../../configuration/components/containers/lists/ConfigurationListContainer';
import { withAccessMany } from '../../../core/components/Hocs/withAccessMany';
import { withAllUser } from '../Hocs/withAllUser';
import UserBlockUnBlockCell from '../../components/containers/UserBlockUnBlockCell';
import AllUsersList from '../composites/lists/AllUsersList';

const converUserToSelectionViewModel = (users = []) => {
  return users.map((u) => ({
    id: u.Account.Id,
    title: u.Entity ? u.Entity.name : u.Account.UserName,
    children: [],
  }));
};

const ConfigurationListWithAccessMany = withAccessMany(
  {
    from: {
      entityType: 'user',
      List: AllUsersList,
      toSelectionViewModel: converUserToSelectionViewModel,
      selectionLoader: withAllUser,
      listProps: {
        ActionTitle: <FormattedMessage id="module-user-active" />,
        Action: UserBlockUnBlockCell,
      },
    },
    to: {
      entityType: 'configuration',
      List: ConfigurationListContainer,
    },
  },
  (fromSelected, toSelected) => ({
    userIds: fromSelected,
    configurationsIds: toSelected,
  }),
);

const AllUsers = () => {
  const canGrantAccess = useUserHasAccess(
    actionsConfiguration.integrations.configuredIntegrations
      .configuredIntegration.edit.claims,
  );

  return (
    <Widget>
      <ConfigurationListWithAccessMany canGrantAccess={canGrantAccess} />
    </Widget>
  );
};

export default AllUsers;
