import { handleActions } from 'redux-actions';
import {
  duplicatedActions,
  selectdDuplicate,
  clearSelectedDuplicate,
  selectSchema,
  clearSelectedSchema,
  duplicatePerEntityTypeActions,
  duplicatesGroupActions,
  fetchEntityFromDuplicateQueryActions,
  fetchMoreEntityFromDuplicateQueryActions,
  duplicateGroupActionsQueries,
} from './actions';

const initialState = {
  isFetchingDuplicates: false,
  duplicates: null,
  invalidDuplicates: false,
  selectedDuplicate: null,
  selectedSchema: null,
  duplicatesPerEntity: [],
  isFetchingDuplicatePerEntity: false,
  invalidDuplicatePerEntity: false,
  duplicatesPerGroups: [],
  isFetchingDuplicatesPerGroups: false,
  invalidDuplicatesPerGroups: false,
  entitiesPerDuplicateQuery: {},
};

export default handleActions(
  {
    [duplicateGroupActionsQueries.request]: (state) => ({
      ...state,
      isFetchingDuplicatesPerGroups: true,
      invalidDuplicatesPerGroups: false,
    }),
    [duplicateGroupActionsQueries.invalid]: (state) => ({
      ...state,
      isFetchingDuplicatesPerGroups: false,
      invalidDuplicatesPerGroups: true,
    }),
    [duplicateGroupActionsQueries.receive]: (state, { payload }) => ({
      ...state,
      isFetchingDuplicatesPerGroups: false,
      invalidDuplicatesPerGroups: false,
      duplicatesPerGroups: payload,
    }),
    [selectSchema]: (state, { payload }) => ({
      ...state,
      selectedSchema: payload,
    }),
    [clearSelectedSchema]: (state) => ({
      ...state,
      selectedSchema: null,
    }),
    [duplicatedActions.request]: (state) => ({
      ...state,
      isFetchingDuplicates: true,
      invalidDuplicates: false,
    }),
    [duplicatedActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingDuplicates: false,
      duplicates: payload,
      invalidDuplicates: false,
    }),
    [duplicatedActions.invalid]: (state) => ({
      ...state,
      isFetchingDuplicates: false,
      duplicates: null,
      invalidDuplicates: true,
    }),
    [selectdDuplicate]: (state, { payload }) => ({
      ...state,
      selectedDuplicate: payload,
    }),
    [clearSelectedDuplicate]: (state) => ({
      ...state,
      selectedDuplicate: null,
    }),
    [duplicatePerEntityTypeActions.request]: (state) => ({
      ...state,
      duplicatesPerEntity: [],
      isFetchingDuplicatePerEntity: true,
      invalidDuplicatePerEntity: false,
    }),
    [duplicatePerEntityTypeActions.receive]: (state, { payload }) => ({
      ...state,
      duplicatesPerEntity: payload,
      isFetchingDuplicatePerEntity: false,
      invalidDuplicatePerEntity: false,
    }),
    [duplicatePerEntityTypeActions.invalid]: (state) => ({
      ...state,
      duplicatesPerEntity: [],
      isFetchingDuplicatePerEntity: false,
      invalidDuplicatePerEntity: true,
    }),
    [duplicatesGroupActions.request]: (state) => ({
      ...state,
      isFetchingDuplicatesPerGroups: true,
      invalidDuplicatesPerGroups: false,
    }),
    [duplicatesGroupActions.receive]: (state, { payload }) => ({
      ...state,
      duplicatesPerGroups: payload,
      isFetchingDuplicatesPerGroups: false,
      invalidDuplicatesPerGroups: false,
    }),
    [duplicatesGroupActions.invalid]: (state) => ({
      ...state,
      isFetchingDuplicatesPerGroups: false,
      invalidDuplicatesPerGroups: true,
    }),
    [fetchEntityFromDuplicateQueryActions.request]: (state, { payload }) => ({
      ...state,
      entitiesPerDuplicateQuery: {
        ...state.entitiesPerDuplicateQuery,
        [`${payload.sourceQuery}-${payload.resultSetKey}-${payload.itemGroupingKey}`]:
          {
            isFetching: true,
            invalid: false,
            result: {},
          },
      },
    }),
    [fetchEntityFromDuplicateQueryActions.receive]: (
      state,
      { payload: { result, param } },
    ) => ({
      ...state,
      entitiesPerDuplicateQuery: {
        ...state.entitiesPerDuplicateQuery,
        [`${param.sourceQuery}-${param.resultSetKey}-${param.itemGroupingKey}`]:
          {
            isFetching: false,
            invalid: false,
            result,
          },
      },
    }),
    [fetchEntityFromDuplicateQueryActions.invalid]: (state, { payload }) => ({
      ...state,
      entitiesPerDuplicateQuery: {
        ...state.entitiesPerDuplicateQuery,
        [`${payload.sourceQuery}-${payload.resultSetKey}-${payload.itemGroupingKey}`]:
          {
            isFetching: false,
            invalid: true,
            result: {},
          },
      },
    }),
    [fetchMoreEntityFromDuplicateQueryActions.request]: (
      state,
      { payload },
    ) => ({
      ...state,
      [`${payload.sourceQuery}-${payload.resultSetKey}-${payload.itemGroupingKey}`]:
        {
          isFetchingMore: true,
        },
    }),
    [fetchMoreEntityFromDuplicateQueryActions.receive]: (
      state,
      { payload: { result, param } },
    ) => {
      const currentState =
        state.entitiesPerDuplicateQuery[
          `${param.sourceQuery}-${param.resultSetKey}-${param.itemGroupingKey}`
        ];
      const currentResult = (currentState || {}).result;

      return {
        ...state,
        entitiesPerDuplicateQuery: {
          ...state.entitiesPerDuplicateQuery,
          [`${param.sourceQuery}-${param.resultSetKey}-${param.itemGroupingKey}`]:
            {
              isFetchingMore: false,
              result: {
                ...currentResult,
                NextCursor: (result || {}).NextCursor,
                Entries: [
                  ...(currentResult.Entries || []),
                  ...(result.Entries || []),
                ],
              },
            },
        },
      };
    },
  },
  initialState,
);
