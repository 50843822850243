import React from 'react';
import styled from 'styled-components';
import { CluedInApplicationLogo } from '@cluedin/svgs';

import reportBg from './reportBg.base64';
import SectionFullBleed from './SectionFullBleed';

const FrontPageUI = styled.div`
  margin: -16px;
  background: url(data:image/jpeg;base64,${reportBg}) no-repeat;
  background-size: cover;

  color: white;
  padding: 16px;
  transition: ${({ theme: { transition } }) => transition.defaultAll};
  ${({ isFullPage }) => (isFullPage ? 'height: 92vh' : 'height: 400px')};
  display: flex;
  align-items: center;

  @media print {
    min-height: auto;
    min-height: 25cm;
    background: none;
    color: black;
    & svg {
      fill: darkgray;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

const TitleUI = styled.div`
  html body & h1 {
    color: white;
  }
  html body & h1 {
    @media print {
      color: black;
    }
  }
`;

const FrontPage = ({ jsonReport, isFullPage }) => {
  const { Name, ReportCreationDate } = jsonReport;

  return (
    <SectionFullBleed>
      <FrontPageUI isFullPage={isFullPage}>
        <TitleUI>
          <CluedInApplicationLogo
            style={{
              height: '110px',
              width: '110px',
            }}
          />
          <h1>{`${Name} Personal Data Report`}</h1>
          <p>{ReportCreationDate}</p>
        </TitleUI>
        <LogoWrapper>
          <CluedInApplicationLogo
            style={{
              height: '110px',
              marginBottom: '-40px',
              width: '110px',
            }}
          />
        </LogoWrapper>
      </FrontPageUI>
    </SectionFullBleed>
  );
};

export default FrontPage;
