import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Loader } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import { Select } from '@cluedin/form';

import { useQueryPropertyFromObjectType } from '../../../hooks/useQueryPropertyFromObjectType';
import { getGlossaryTermLabel } from '../../../utils/getGlossaryTermLabel';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 6px;
  & svg,
  svg * {
    fill: ${({ themePrimary }) => themePrimary};
  }
`;

export const RuleItemPropertySelector = ({
  ruleItem,
  hasError,
  objectTypeId,
  ruleItemIndex,
  onPropertyChange,
  selectedObjectType,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [propertiesToChooseFrom, loadingProperties] =
    useQueryPropertyFromObjectType(objectTypeId);

  const options = [
    {
      value: '',
      label: `All ${get(selectedObjectType, 'name', '')}`,
    },
    ...propertiesToChooseFrom.map((i) => ({
      value: i.key,
      label: getGlossaryTermLabel(i),
    })),
  ];

  const handleChangeProp = ({ value }) => {
    const prop = propertiesToChooseFrom.find((p) => p.key === value);

    onPropertyChange(value, ruleItemIndex, prop?.type);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      {loadingProperties && (
        <LoaderWrapper themePrimary={themePrimary}>
          <Loader color={themePrimary} />
        </LoaderWrapper>
      )}
      {!loadingProperties && (
        <div style={{ width: '350px', flex: 1 }}>
          <Select
            isSearchable
            options={options}
            hasError={hasError}
            value={ruleItem.field}
            onChange={handleChangeProp}
          />
        </div>
      )}
    </div>
  );
};
