import React from 'react';
import { TableRow, TableRowColumn, TableBody } from '@cluedin/list-pattern';
import Table from './TableExtended';
import Section from './Section';
import ValueWrapper from './ValueWrapper';

const Retention = ({ jsonReport }) => {
  const { Retention: RetentionProp } = jsonReport;

  return (
    <Section jsonReport={jsonReport}>
      <h2>Retention:</h2>
      <Table>
        <TableBody>
          {RetentionProp &&
            RetentionProp.length > 0 &&
            RetentionProp.map(({ Name, Description }) => (
              <TableRow>
                <TableRowColumn width="30%">{Name}: </TableRowColumn>
                <TableRowColumn width="70%">
                  <ValueWrapper>{Description}</ValueWrapper>{' '}
                </TableRowColumn>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Section>
  );
};

export default Retention;
