import React from 'react';
import { Select } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

const StatusFilter = ({ value = null, onChange }) => {
  const options = [
    {
      value: null,
      label: <FormattedMessage id="module-hierarchy-allstatus" />,
    },
    { value: 'Draft', label: <FormattedMessage id="module-hierarchy-draft" /> },
    {
      value: 'Published',
      label: <FormattedMessage id="module-hierarchy-published" />,
    },
    {
      value: 'Modified',
      label: <FormattedMessage id="module-hierarchy-modified" />,
    },
  ];

  const selectedValue = options.find((item) => item.value === value);
  const handleChange = (item) => onChange(item.value);

  return (
    <div style={{ width: '120px' }}>
      <Select
        defaultValue={options[0]}
        value={selectedValue}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
};

export default StatusFilter;
