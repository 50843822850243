import { handleActions } from 'redux-actions';
import {
  fetchConfigurationByIdAction,
  fetchAllActiveConfigurationsAction,
  fetchUserPermissionsByConfigurationIdAction,
  updateConfigurationPermissionActionFromUser,
  updatePermissionForConfigurationActions,
  fetchUnapprovedConfigurationAction,
  fetchInactiveConfigurationsAction,
  playAction,
  pauseAction,
  rejectAction,
  approveAction,
  setProductOwnerActions,
  fetchProdutOwnerActions,
  receiveRealTimeProviderMessage,
  removeProductOwnerActions,
  shouldShowProductOwner,
  shouldHideProductOwner,
  shouldBatchUpdatePersmissionsAction,
  hideBatchUserPermissionsAction,
  showBatchUserPermissionsAction,
} from './actions';
import {
  mutateAppropriateConfigurationForPlay,
  mutateAppropriateConfigurationForPause,
  mutateAppropriateConfigurationForReject,
  mutateAppropriateConfigurationForApprove,
} from './helpers/mutation';

const initialState = {
  selectedConfiguration: null,
  isFetchingSelectedConfiguration: false,
  invalidSelectedConfiguration: false,
  allActiveConfigurations: [],
  isFetchingAllActiveConfigurations: false,
  invalidAllActiveConfigurations: false,
  allInactiveConfigurations: [],
  isFetchingAllInactiveConfigurations: false,
  invalidAllInactiveConfigurations: false,
  allUnapprovedConfigurations: [],
  isFetchingUnapprovedConfiguration: false,
  invalidUnapprovedConfiguration: false,
  permissionsByConfigurations: {},
  isFetchingPermissionsByConfigurations: {},
  invalidPersmissionsByConfigurations: {},
  isChangingPermissionForUser: {},
  isChangingPermissionsForConfiguration: {},
  isChangingPermissionsForConfigurationFromIntegration: {},
  invalidChangingPermissionsForConfigurationFromIntegration: {},
  playConfigurations: {},
  pauseConfigurations: {},
  approveConfigurations: {},
  rejectConfigurations: {},

  setProductOwner: {},
  removeProductOwner: {},

  providerOwner: null,
  isFetchingProviderOwner: false,
  isFetchingProviderOwnerError: false,
  isFecthingProviderOwnerAccessDenied: false,

  isSavingPermissions: false,
  invalidBatchPermissions: false,
  showBatchUserPermissions: false,
  configurationMessage: {},
};

export default handleActions(
  {
    [hideBatchUserPermissionsAction]: (state) => ({
      ...state,
      showBatchUserPermissions: false,
    }),
    [showBatchUserPermissionsAction]: (state) => ({
      ...state,
      showBatchUserPermissions: true,
    }),
    [shouldBatchUpdatePersmissionsAction.request]: (state) => ({
      ...state,
      isSavingPermissions: true,
      invalidBatchPermissions: false,
    }),
    [shouldBatchUpdatePersmissionsAction.receive]: (state) => ({
      ...state,
      isSavingPermissions: false,
      invalidBatchPermissions: false,
    }),
    [shouldBatchUpdatePersmissionsAction.invalid]: (state) => ({
      ...state,
      isSavingPermissions: false,
      invalidBatchPermissions: true,
    }),
    [receiveRealTimeProviderMessage]: (
      state,
      { payload: { providerDefinitionId, message } },
    ) => ({
      ...state,
      configurationMessage: {
        [providerDefinitionId]: [
          ...(state.configurationMessage[providerDefinitionId] || []),
          message,
        ],
      },
    }),
    [fetchConfigurationByIdAction.request]: (state) => ({
      ...state,
      invalidSelectedConfiguration: false,
      isFetchingSelectedConfiguration: true,
      selectedConfiguration: null,
    }),
    [fetchConfigurationByIdAction.receive]: (state, { payload }) => ({
      ...state,
      invalidSelectedConfiguration: false,
      isFetchingSelectedConfiguration: false,
      selectedConfiguration: payload,
    }),
    [fetchConfigurationByIdAction.invalid]: (state) => ({
      ...state,
      invalidSelectedConfiguration: true,
      isFetchingSelectedConfiguration: false,
      selectedConfiguration: null,
    }),
    [fetchAllActiveConfigurationsAction.request]: (state) => ({
      ...state,
      isFetchingAllActiveConfigurations: true,
      invalidAllActiveConfigurations: false,
    }),
    [fetchAllActiveConfigurationsAction.receive]: (state, { payload }) => ({
      ...state,
      allActiveConfigurations: payload,
      isFetchingAllActiveConfigurations: false,
      invalidAllActiveConfigurations: false,
    }),
    [fetchAllActiveConfigurationsAction.invalid]: (state) => ({
      ...state,
      allActiveConfigurations: null,
      isFetchingAllActiveConfigurations: false,
      invalidAllActiveConfigurations: true,
    }),
    [fetchUserPermissionsByConfigurationIdAction.request]: (
      state,
      { payload },
    ) => ({
      ...state,
      permissionsByConfigurations: {
        ...state.permissionsByConfigurations,
        [payload]: null,
      },
      isFetchingPermissionsByConfigurations: {
        ...state.isFetchingPermissionsByConfigurations,
        [payload]: true,
      },
      invalidPersmissionsByConfigurations: {
        ...state.invalidPersmissionsByConfigurations,
        [payload]: false,
      },
    }),
    [fetchUserPermissionsByConfigurationIdAction.receive]: (
      state,
      { payload },
    ) => ({
      ...state,
      permissionsByConfigurations: {
        ...state.permissionsByConfigurations,
        [payload.configurationId]: payload.userId,
      },
      isFetchingPermissionsByConfigurations: {
        ...state.isFetchingPermissionsByConfigurations,
        [payload.configurationId]: false,
      },
      invalidPersmissionsByConfigurations: {
        ...state.invalidPersmissionsByConfigurations,
        [payload.configurationId]: false,
      },
    }),
    [fetchUserPermissionsByConfigurationIdAction.invalid]: (
      state,
      { payload },
    ) => ({
      ...state,
      permissionsByConfigurations: {
        ...state.permissionsByConfigurations,
        [payload]: null,
      },
      isFetchingPermissionsByConfigurations: {
        ...state.isFetchingPermissionsByConfigurations,
        [payload]: false,
      },
      invalidPersmissionsByConfigurations: {
        ...state.invalidPersmissionsByConfigurations,
        [payload]: true,
      },
    }),
    [updateConfigurationPermissionActionFromUser.request]: (
      state,
      { payload: { userId, configurationId } },
    ) => ({
      ...state,
      isChangingPermissionForUser: {
        [userId]: {
          isFetching: true,
          invalid: false,
        },
      },
      isChangingPermissionsForConfiguration: {
        [configurationId]: {
          isFetching: true,
          invalid: false,
        },
      },
    }),
    [updateConfigurationPermissionActionFromUser.receive]: (
      state,
      { payload: { userId, configurationId } },
    ) => ({
      ...state,
      isChangingPermissionForUser: {
        [userId]: {
          isFetching: false,
          invalid: false,
        },
      },
      isChangingPermissionsForConfiguration: {
        [configurationId]: {
          isFetching: false,
          invalid: false,
        },
      },
    }),
    [updateConfigurationPermissionActionFromUser.invalid]: (
      state,
      { payload: { userId, configurationId } },
    ) => ({
      ...state,
      isChangingPermissionForUser: {
        [userId]: {
          isFetching: false,
          invalid: true,
        },
      },
      isChangingPermissionsForConfiguration: {
        [configurationId]: {
          isFetching: false,
          invalid: true,
        },
      },
    }),
    [updatePermissionForConfigurationActions.request]: (
      state,
      { payload: { userId } },
    ) => ({
      ...state,
      isChangingPermissionsForConfigurationFromIntegration: {
        [userId]: true,
      },
      invalidChangingPermissionsForConfigurationFromIntegration: {
        [userId]: false,
      },
    }),
    [updatePermissionForConfigurationActions.receive]: (
      state,
      { payload: { userId, payload } },
    ) => {
      const currentPermissionsArrayForConfig = [
        ...(((state || {}).permissionsByConfigurations || {})[
          payload.ProviderDefinitionId
        ] || []),
      ];
      const newPermissionsArrayForConfig = payload.hasAccess
        ? currentPermissionsArrayForConfig.filter(
            (x) => payload.UserIds.indexOf(x) === -1,
          )
        : [...currentPermissionsArrayForConfig, ...payload.UserIds];
      return {
        ...state,
        isChangingPermissionsForConfigurationFromIntegration: {
          [userId]: false,
        },
        invalidChangingPermissionsForConfigurationFromIntegration: {
          [userId]: false,
        },
        permissionsByConfigurations: {
          ...state.permissionsByConfigurations,
          [payload.ProviderDefinitionIds[0]]: newPermissionsArrayForConfig,
        },
      };
    },
    [updatePermissionForConfigurationActions.invalid]: (
      state,
      { payload: { userId } },
    ) => ({
      ...state,
      isChangingPermissionsForConfigurationFromIntegration: {
        [userId]: false,
      },
      invalidChangingPermissionsForConfigurationFromIntegration: {
        [userId]: true,
      },
    }),
    [fetchUnapprovedConfigurationAction.request]: (state) => ({
      ...state,
      isFetchingUnapprovedConfiguration: true,
      invalidUnapprovedConfiguration: false,
    }),
    [fetchUnapprovedConfigurationAction.invalid]: (state) => ({
      ...state,
      isFetchingUnapprovedConfiguration: false,
      invalidUnapprovedConfiguration: true,
    }),
    [fetchUnapprovedConfigurationAction.receive]: (state, { payload }) => ({
      ...state,
      allUnapprovedConfigurations: payload || [],
      isFetchingUnapprovedConfiguration: false,
      invalidUnapprovedConfiguration: false,
    }),
    [fetchInactiveConfigurationsAction.request]: (state) => ({
      ...state,
      isFetchingAllInactiveConfigurations: true,
      invalidAllInactiveConfigurations: false,
    }),
    [fetchInactiveConfigurationsAction.receive]: (state, { payload }) => ({
      ...state,
      allInactiveConfigurations: payload,
      isFetchingAllInactiveConfigurations: false,
      invalidAllInactiveConfigurations: false,
    }),
    [fetchInactiveConfigurationsAction.invalid]: (state) => ({
      ...state,
      isFetchingAllInactiveConfigurations: false,
      invalidAllInactiveConfigurations: true,
    }),
    [playAction.request]: (state, { payload: { id } }) => ({
      ...state,
      playConfigurations: {
        ...state.playConfigurations,
        [id]: {
          isFetching: true,
          isInvalid: false,
        },
      },
    }),
    [playAction.receive]: (state, { payload: { id } }) => ({
      ...state,
      ...mutateAppropriateConfigurationForPlay(state, id),
      playConfigurations: {
        ...state.playConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: true,
        },
      },
    }),
    [playAction.invalid]: (state, { payload: { id } }) => ({
      ...state,
      playConfigurations: {
        ...state.playConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: true,
        },
      },
    }),
    [pauseAction.request]: (state, { payload: { id } }) => ({
      ...state,
      pauseConfigurations: {
        ...state.pauseConfigurations,
        [id]: {
          isFetching: true,
          isInvalid: false,
        },
      },
    }),
    [pauseAction.receive]: (state, { payload: { id } }) => ({
      ...state,
      ...mutateAppropriateConfigurationForPause(state, id),
      pauseConfigurations: {
        ...state.pauseConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: false,
        },
      },
    }),
    [pauseAction.invalid]: (state, { payload: { id } }) => ({
      ...state,
      pauseConfigurations: {
        ...state.pauseConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: true,
        },
      },
    }),
    [rejectAction.request]: (state, { payload: { id } }) => ({
      ...state,
      rejectConfigurations: {
        ...state.rejectConfigurations,
        [id]: {
          isFetching: true,
          isInvalid: false,
        },
      },
    }),
    [rejectAction.receive]: (state, { payload: { id } }) => ({
      ...state,
      ...mutateAppropriateConfigurationForReject(state, id),
      rejectConfigurations: {
        ...state.rejectConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: false,
        },
      },
    }),
    [rejectAction.invalid]: (state, { payload: { id } }) => ({
      ...state,
      rejectConfigurations: {
        ...state.rejectConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: true,
        },
      },
    }),
    [approveAction.request]: (state, { payload: { id } }) => ({
      ...state,
      approveConfigurations: {
        ...state.approveConfigurations,
        [id]: {
          isFetching: true,
          isInvalid: false,
        },
      },
    }),
    [approveAction.receive]: (state, { payload: { id } }) => ({
      ...state,
      ...mutateAppropriateConfigurationForApprove(state, id),
      approveConfigurations: {
        ...state.approveConfigurations,
        [id]: {
          isFetching: false,
          isInvalid: false,
        },
      },
    }),
    [approveAction.invalid]: (state, { payload: { id } }) => ({
      ...state,
      approveConfigurations: {
        ...state.approveConfigurations,
        [id]: {
          isFetching: true,
          isInvalid: false,
        },
      },
    }),
    [removeProductOwnerActions.request]: (
      state,
      { payload: { UserId, ProviderDefinitionId } },
    ) => ({
      ...state,
      removeProductOwner: {
        [`${UserId}-${ProviderDefinitionId}`]: {
          isFetching: true,
          invalid: false,
          done: false,
        },
      },
    }),
    [removeProductOwnerActions.receive]: (
      state,
      { payload: { UserId, ProviderDefinitionId } },
    ) => ({
      ...state,
      removeProductOwner: {
        [`${UserId}-${ProviderDefinitionId}`]: {
          isFetching: false,
          invalid: false,
          done: true,
        },
      },
    }),
    [removeProductOwnerActions.invalid]: (
      state,
      { payload: { UserId, ProviderDefinitionId } },
    ) => ({
      ...state,
      removeProductOwner: {
        [`${UserId}-${ProviderDefinitionId}`]: {
          isFetching: false,
          invalid: true,
          done: false,
        },
      },
    }),
    [setProductOwnerActions.request]: (
      state,
      { payload: { userId, providerDefinitionId } },
    ) => ({
      ...state,
      setProductOwner: {
        [`${userId}-${providerDefinitionId}`]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [setProductOwnerActions.receive]: (
      state,
      { payload: { UserId, ProviderDefinitionId } },
    ) => ({
      ...state,
      setProductOwner: {
        [`${UserId}-${ProviderDefinitionId}`]: {
          isFetching: false,
          done: true,
          invalid: false,
        },
      },
    }),
    [setProductOwnerActions.invalid]: (
      state,
      { payload: { userId, providerDefinitionId } },
    ) => ({
      ...state,
      setProductOwner: {
        [`${userId}-${providerDefinitionId}`]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [fetchProdutOwnerActions.request]: (state) => ({
      ...state,
      isFetchingProviderOwner: true,
      isFetchingProviderOwnerError: false,
      isFecthingProviderOwnerAccessDenied: false,
    }),
    [fetchProdutOwnerActions.receive]: (state, { payload }) => ({
      ...state,
      providerOwner: [...payload],
      isFetchingProviderOwner: false,
      isFetchingProviderOwnerError: false,
      isFecthingProviderOwnerAccessDenied: false,
    }),
    [fetchProdutOwnerActions.invalid]: (
      state,
      { payload: { accessDenied } },
    ) => ({
      ...state,
      isFetchingProviderOwner: false,
      isFetchingProviderOwnerError: true,
      providerOwner: null,
      isFecthingProviderOwnerAccessDenied: accessDenied,
    }),
    [shouldShowProductOwner]: (state) => ({
      ...state,
      isShowingProductOwner: true,
    }),
    [shouldHideProductOwner]: (state) => ({
      ...state,
      isShowingProductOwner: false,
    }),
  },
  initialState,
);
