import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

export const GET_ORG_FEAETURES = gql`
  query getOrganizationFeatures {
    administration {
      id
      organizationFeatures {
        id
        name
        displayName
        description
        status
        isEnabled
        isTrial
      }
    }
  }
`;

export const useQueryOrganizationFeatures = () => {
  const fetchPolicy = 'no-cache';
  const { data, loading, error, refetch } = useQuery(GET_ORG_FEAETURES, {
    fetchPolicy,
  });
  const features = get(data, 'administration.organizationFeatures', []);

  return [features, loading, error, refetch];
};
