export const required = (value) => (value ? undefined : 'Required');

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (
    <span data-test="invalid-email-address">Invalid email address</span>
  ) : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

export const blankValidation = (value = '') => {
  return value?.trim?.().length;
};

export const specialCharValidation = (value) => {
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return new RegExp(specialChars).test(value);
};

export const onlyNumbersAndLetters = (value) => {
  return !value.trim().replace(' ', '').match('^[A-Za-z0-9]+$');
};

export const onlyNumbersLettersAndSpaces = (value) => {
  if (value?.includes('*')) {
    return true;
  }

  return !value.trim().match('^[a-zA-Z0-9 ()-_]*$');
};
