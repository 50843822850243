import { useMemo, useEffect, useState, useCallback } from 'react';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { FormDecorator, TextField, Checkbox } from '@cluedin/form';
import Alert from 'uxi/Icons/Alert';

import { capitalizeFirstLetter } from '../../core/helpers/string';
import { useVocabularyKeysFromVocabId } from '../../dataCatalog/hooks/useVocabularyKeys';
import { VocabularyKeySelector } from '../../dataCatalog/components/containers/VocabularyKeySelector';
import { usePaginatedVocabularyKeys } from '../../dataCatalog/hooks/usePaginatedVocabularyKeys';

export const CreateSimpleVocabularyKeyOrSelect = injectIntl(
  ({
    intl,
    onChange,
    disabled,
    isLoading,
    value = {},
    vocabularyId,
    validationError,
    handleResetConfig,
    vocabularyKeyPrefix,
    handleChangeVocabDisplayName,
    defaultKeyForRestrictedVocab,
    isCreatable = true,
    restrictToVocabKeys: restrictToVocabKeysDefault = true,
  }) => {
    const [restrictToVocabKeys, setRestrictToVocabKeys] = useState(
      restrictToVocabKeysDefault,
    );

    const [touched, setTouched] = useState(false);
    const [searchName, setSearchName] = useState('');
    const [isNewVocabularyKey, setIsNewVocabularyKey] = useState(value.new);

    useEffect(() => {
      setIsNewVocabularyKey(value?.new);
    }, [value]);

    const [data, isLoadingVocabKeys] = useVocabularyKeysFromVocabId(
      vocabularyId,
      { searchName },
      false,
      !restrictToVocabKeys,
    );

    const {
      loadMore,
      vocabularyKeys,
      totalVocabularyKeys,
      setSearchVocabularyKeys,
      isLoadingVocabularyKeys,
    } = usePaginatedVocabularyKeys({ skip: restrictToVocabKeys });

    const apiData = restrictToVocabKeys ? data.data : vocabularyKeys;
    const apiTotal = restrictToVocabKeys ? data.total : totalVocabularyKeys;
    const apiLoading = restrictToVocabKeys
      ? isLoadingVocabKeys
      : isLoadingVocabularyKeys;
    const handleSearch = restrictToVocabKeys
      ? setSearchName
      : setSearchVocabularyKeys;

    const displayName = useMemo(() => value?.displayName, [value]);
    const name = useMemo(() => value?.name, [value]);
    const vocabularyKeyId = useMemo(() => value?.vocabularyKeyId, [value]);

    const adaptedVocabularySelectValue = useMemo(() => {
      return name
        ? {
            label: name ?? '',
            value: vocabularyKeyId || name || '',
          }
        : undefined;
    }, [name, vocabularyKeyId]);

    const onChangeDisplayName = useCallback((_, v) => {
      handleChangeVocabDisplayName(v);
    }, []);

    const onChangeVocabularyKey = (value) => {
      setTouched(true);

      onChange(value);
    };

    const handleChangeVocabKeysType = () => {
      setTouched(true);

      handleResetConfig?.();

      setRestrictToVocabKeys((prev) => {
        if (prev) {
          onChange(undefined);
        }

        return !prev;
      });
    };

    return (
      <>
        <FormDecorator
          isRequired
          errorText={touched && validationError}
          label={intl.formatMessage({
            id: 'module-annotation-annotationPropertyFormVocabKeyLabel',
          })}
          helpText={intl.formatMessage({
            id: 'module-annotation-annotationPropertyFormVocabKeyHelp',
          })}
        >
          <div style={{ paddingBottom: '10px' }}>
            <Checkbox
              checked={restrictToVocabKeys}
              onChange={handleChangeVocabKeysType}
              label={intl.formatMessage(
                { id: 'module-annotation-add-prop-restrict-to-vocab' },
                { vocab: capitalizeFirstLetter(vocabularyKeyPrefix) },
              )}
            />
          </div>

          <VocabularyKeySelector
            data={apiData}
            total={apiTotal}
            showObsoleteLabels
            disabled={disabled}
            isCreatable={restrictToVocabKeys && isCreatable ? true : false}
            isClearable={false}
            setSearchName={handleSearch}
            onChange={onChangeVocabularyKey}
            isLoading={apiLoading || isLoading}
            value={adaptedVocabularySelectValue}
            hasError={touched && !!validationError}
            readOnly={isLoadingVocabKeys || isLoading}
            loadMore={!restrictToVocabKeys && loadMore}
            setIsNewVocabularyKey={setIsNewVocabularyKey}
          />

          {isNewVocabularyKey && !validationError && (
            <div
              style={{
                color: '#5B89CF',
                fontSize: '12px',
                marginTop: '4px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Alert style={{ marginRight: '6px' }} size={16} color="#5B89CF" />
              <em>
                <FormattedMessage id="module-dataCatalog-vocabKey-fullVocabKeyWillBe" />
              </em>
              <strong style={{ margin: '0 4px' }}>
                {vocabularyKeyPrefix?.toLowerCase()}.{name}
              </strong>
            </div>
          )}
        </FormDecorator>

        {isNewVocabularyKey && !validationError && (
          <FormDecorator
            isRequired
            style={{ marginTop: '12px' }}
            label={'Display name'}
            helpText={
              'User-friendly text used in the user interface to help with readability.'
            }
          >
            <TextField
              value={displayName}
              onChange={onChangeDisplayName}
              hasError={!displayName}
            />
          </FormDecorator>
        )}
      </>
    );
  },
);
