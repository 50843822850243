import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { TextField, Toggle } from '@cluedin/form';
import { Field } from 'redux-form';

import SettingRow from './SettingRow';
import FormDecorator from '../../../../core/components/composites/form/FormDecorator';
import ConfigurationStats from '../../../../configuration/components/composites/ConfigurationStats';

import DataSourceField from './DataSourceField';
import QualitySlider from './QualitySlider';

const AccountDisplay = FormDecorator(TextField, {
  label: (
    <FormattedMessage
      id="module-integration-configurationDisplayName"
      defaultMessage="Display Name"
    />
  ),
  isRequired: true,
  helpText: ' ',
  placeholder: 'e.g. Service Name',
});

const ReduxDataSourceField = FormDecorator(DataSourceField, {});
const ReduxQualitySlider = FormDecorator(QualitySlider, {});
const AdaptedSwitch = (props) => (
  <Toggle
    {...props}
    onChange={(_, checked) => {
      props.onChange(checked);
    }}
  />
);

const ReduxSwitch = FormDecorator(AdaptedSwitch, {});

const NewConfigurationForm = ({ helperConfiguration, children }) => {
  const EntityTypeStatistics =
    helperConfiguration &&
    helperConfiguration.expectedStatistics &&
    helperConfiguration.expectedStatistics.EntityTypeStatistics;

  const entityStatsPanel = EntityTypeStatistics &&
    EntityTypeStatistics.length > 0 && (
      <SettingRow
        title={
          <FormattedMessage
            id="module-integration-expectedData"
            defaultMessage="Expected Data"
          />
        }
        description={
          <FormattedMessage
            id="module-integration-expectedDataDescription"
            defaultMessage="During the crawl of the provider we expect the following data."
          />
        }
      >
        <ConfigurationStats dataStats={EntityTypeStatistics} />
      </SettingRow>
    );

  return (
    <div>
      <SettingRow
        title={
          <FormattedMessage
            id="module-integration-accountDisplay"
            defaultMessage="Choose a name"
          />
        }
        description={
          <FormattedMessage
            id="module-integration-accountDisplayDescription"
            defaultMessage="Enter a title for this integration."
          />
        }
      >
        <Field name="AccountDisplay" component={AccountDisplay} />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage
            id="module-integration-applicationConfiguration"
            defaultMessage="Application Configuration"
          />
        }
        description={
          <FormattedMessage
            id="module-integration-applicationConfigurationDescription"
            defaultMessage="Application Configuration"
          />
        }
      >
        {children}
      </SettingRow>

      <SettingRow
        title={
          <FormattedMessage
            id="module-integration-autoSync"
            defaultMessage="Automatically follow new items"
          />
        }
        description={
          <FormattedMessage
            id="module-integration-autoSyncDescription"
            defaultMessage="Should any new items added to the provider be automatically synched?"
          />
        }
      >
        <Field name="AutoSync" component={ReduxSwitch} />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage
            id="module-integration-source"
            defaultMessage="Source Information"
          />
        }
        description={
          <FormattedMessage
            id="module-integration-sourceDescription"
            defaultMessage={`
              Enter a description of this source, and use the slider to define the quality of the data. 
              The "Quality of data" value is a weighting value used by the CluedIn engine to determine, 
              when comparing values from different providers, which value should win over the other.
            `}
          />
        }
      >
        <div>
          <Field name="Source" component={ReduxDataSourceField} />
        </div>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <div style={{ flex: 1, padding: '15px 15px 15px 0' }}>
            <FormattedMessage
              id="module-integration-lowerQuality"
              defaultMessage="Lower Quality"
            />
          </div>
          <div style={{ flex: 8 }}>
            <Field name="SourceQuality" component={ReduxQualitySlider} />
          </div>
          <div style={{ flex: 1, padding: '15px 0 15px 15px' }}>
            <FormattedMessage
              id="module-integration-higherQuality"
              defaultMessage="Higher Quality"
            />
          </div>
        </div>
      </SettingRow>
      {entityStatsPanel}
    </div>
  );
};

export default NewConfigurationForm;
