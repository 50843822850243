import { handleActions } from 'redux-actions';
import {
  showAlert,
  hideAlert,
  batchAsyncActions,
  appendModule,
  appendTitle,
} from './actions';

const initialState = {
  alerts: [],

  // Batch actions:
  batchActionsStatuses: {},
};

const updateStore = (store = {}, payload, statusFromReducer) => {
  const newRequest = (store.requests || []).filter((x) => x.id !== payload.id);

  return {
    allDone: false,
    requests: [
      ...(newRequest || []),
      {
        ...payload,
        status: statusFromReducer,
      },
    ],
  };
};

const makeBatchOperationDone = (store = {}) => ({
  ...store,
  allDone: true,
});

export default handleActions(
  {
    [appendTitle]: (state, { payload }) => {
      const newTitle = typeof payload === 'string' ? [payload] : payload;
      return {
        ...state,
        currentTitle: newTitle,
      };
    },
    [appendModule]: (state, { payload }) => ({
      ...state,
      currentModule: payload,
    }),
    [showAlert]: (state, { payload }) => ({
      ...state,
      alerts: [...state.alerts, payload],
    }),
    [hideAlert]: (state, { payload }) => ({
      ...state,
      alerts: [...state.alerts.filter((a) => a.id !== payload.id)],
    }),
    [batchAsyncActions.request]: (state, { payload }) => ({
      ...state,
      batchActionsStatuses: {
        ...state.batchActionsStatuses,
        [payload.entityName || 'default']: updateStore(
          state.batchActionsStatuses[payload.entityName || 'default'],
          payload,
          {
            isFetching: true,
            invalid: false,
            done: false,
          },
        ),
      },
    }),
    [batchAsyncActions.receive]: (state, { payload }) => ({
      ...state,
      batchActionsStatuses: {
        ...state.batchActionsStatuses,
        [payload.entityName || 'default']: updateStore(
          state.batchActionsStatuses[payload.entityName || 'default'],
          payload,
          {
            isFetching: false,
            invalid: false,
            done: true,
          },
        ),
      },
    }),
    [batchAsyncActions.invalid]: (state, { payload }) => ({
      ...state,
      batchActionsStatuses: {
        ...state.batchActionsStatuses,
        [payload.entityName || 'default']: updateStore(
          state.batchActionsStatuses[payload.entityName || 'default'],
          payload,
          {
            isFetching: false,
            invalid: true,
            done: false,
          },
        ),
      },
    }),
    [batchAsyncActions.all]: (state, { payload }) => ({
      ...state,
      batchActionsStatuses: {
        ...state.batchActionsStatuses,
        [payload.entityName || 'default']: makeBatchOperationDone(
          state.batchActionsStatuses[payload.entityName || 'default'],
        ),
      },
    }),
  },
  initialState,
);
