import { apiRequest } from '../core/helpers/request';
import { toVM } from '../entity/viewModel';
import { encodeFilterForSearch } from './helper';

const getSortValue = (sortDirection) => {
  if (sortDirection === 'new') {
    return 'desc';
  }

  if (sortDirection === 'old') {
    return 'asc';
  }

  return null;
};

export const search = ({
  query,
  entityTypes = [],
  filters = [],
  withUntokenized,
  sortType,
  pageNumber,
}) => {
  const typeFilter =
    entityTypes && entityTypes.length > 0
      ? `&type=${encodeURI(
          entityTypes.map((e) => `entityType:${e}`).join(' OR '),
        )}` || ''
      : '';

  const filteryString = encodeFilterForSearch(filters || []);
  const filterQuery = filteryString ? `&type=${filteryString}` : '';
  const untokenized = withUntokenized ? '&searchUntokenized=true' : '';

  const sortDirection = getSortValue(sortType);
  const sortTypeQuery = sortDirection
    ? `&dateSort=true&direction=${sortDirection}`
    : '';
  const pageNumberQuery = pageNumber ? `&page=${pageNumber}` : '';

  const url = `api/search?q=${query}${typeFilter}${filterQuery}${sortTypeQuery}${pageNumberQuery}${untokenized}`;

  return apiRequest('GET', url).then((result) => {
    if (result.Hits) {
      result.Hits = result.Hits.map((entity) =>
        toVM(entity, false, result.__token),
      );
    }

    return result;
  });
};

const aggregationSearchResult = {};

export const aggregationSearch = (id) => {
  const url = `api/v1/entitysearch?id=${id}`;

  if (aggregationSearchResult[url]) {
    return new Promise((resolve) => {
      resolve(aggregationSearchResult[url]);
    });
  }

  return apiRequest('GET', url).then((result) => {
    if (result.Hits) {
      result.Hits = result.Hits.map((entity) =>
        toVM(entity, false, result.__token),
      );
    }

    aggregationSearchResult[url] = result;
    return result;
  });
};

const searchSuggested = {};
export const suggestedSearch = (q) => {
  const suggestedUrl = `api/search/suggest?q=${q}`;

  if (searchSuggested[suggestedUrl]) {
    return new Promise((resolve) => {
      resolve(searchSuggested[suggestedUrl]);
    });
  }

  return new Promise((resolve) => {
    apiRequest('GET', suggestedUrl).then((result) => {
      searchSuggested[suggestedUrl] = result;
      resolve(result);
    });
  });
};

export default search;
