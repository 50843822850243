import { FC } from 'react';
import styled from 'styled-components';
import { TextPlaceholder } from '@cluedin/components';

import { useConnectorConfigurationIcon } from '../../../modules/connector/hooks/useConnectorConfigurationIcon';
import { useQueryConfiguredConnectorById } from '../../../modules/connector/hooks/useQueryConfiguredConnectorById';

const IconWrapper = styled.div`
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ProviderIconById: FC<{ id: string }> = ({ id }) => {
  const [connector, loadingConnector, errorLoadingConnector] =
    useQueryConfiguredConnectorById(id);
  const [icon, loadingIcon, errorLoadingIcon] = useConnectorConfigurationIcon(
    connector?.providerId,
    !connector?.providerId,
  );

  if (loadingConnector || loadingIcon) {
    return <TextPlaceholder width={'16px'} />;
  }

  if (!icon || errorLoadingIcon || errorLoadingConnector) {
    return null;
  }

  return <IconWrapper dangerouslySetInnerHTML={{ __html: icon }} />;
};
