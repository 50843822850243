import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';

import {
  EntityTopologySplitPanelListWarningMessageWrapper,
  EntityTopologySplitPanelListWarningMessageHeading,
} from './EntityTopologySplitPanelListWarningMessage.styles';

const EntityTopologySplitPanelListWarningMessage = ({
  unavailableReason,
  unavailableKind,
  reasonMessage,
}) => {
  let kind = unavailableKind;
  if (unavailableKind === 'EntityCode') {
    kind = (
      <FormattedMessage id="module-entityTopology-splitPanelSplitListUnavailableKindEntityCode" />
    );
  }

  let warningHeading = '';
  if (unavailableReason === 'Other') {
    warningHeading =
      'module-entityTopology-splitPanelSplitListUnavailableHeading';
  }
  if (unavailableReason === 'CannotRemoveOriginEntityCode') {
    warningHeading =
      'module-entityTopology-splitPanelSplitListUnavailableHeadingEntityCode';
  }

  let reasonMessageToUse = reasonMessage;
  if (!reasonMessage && unavailableReason === 'CannotRemoveOriginEntityCode') {
    reasonMessageToUse = (
      <FormattedMessage id="module-entityTopology-splitPanelSplitListUnavailableMessageEntityCode" />
    );
  }

  return (
    <Alert type="warning">
      <EntityTopologySplitPanelListWarningMessageWrapper>
        <EntityTopologySplitPanelListWarningMessageHeading>
          <FormattedMessage id={warningHeading} values={{ kind: kind }} />
        </EntityTopologySplitPanelListWarningMessageHeading>

        {reasonMessageToUse}
      </EntityTopologySplitPanelListWarningMessageWrapper>
    </Alert>
  );
};

export default EntityTopologySplitPanelListWarningMessage;

EntityTopologySplitPanelListWarningMessage.propTypes = {
  unavailableReason: PropTypes.string,
  unavailableKind: PropTypes.string,
  reasonMessage: PropTypes.string,
};

EntityTopologySplitPanelListWarningMessage.defaultProps = {
  unavailableReason: '',
  unavailableKind: '',
  reasonMessage: '',
};
