import { GraphNetwork } from '../../entityRelationsGraphV2/types';
import { TreeEdge } from './types';

export const getOutgoingEdgesWithSourceAndTarget = (
  treeNetworkState: GraphNetwork,
  entityId: string,
): TreeEdge[] => {
  return (
    treeNetworkState?.edges
      ?.filter((edge) => edge?.from === entityId)
      ?.map((edge) => {
        const sourceNode =
          treeNetworkState?.nodes?.find((node) => node?.id === edge?.from) ||
          null;
        const targetNode =
          treeNetworkState?.nodes?.find((node) => node?.id === edge?.to) ||
          null;

        return {
          ...edge,
          direction: 'Outgoing',
          source: sourceNode,
          target: targetNode,
        };
      }) || []
  );
};

export const getIncomingEdgesWithSourceAndTarget = (
  treeNetworkState: GraphNetwork,
  entityId: string,
): TreeEdge[] => {
  return (
    treeNetworkState?.edges
      ?.filter((edge) => edge?.to === entityId)
      ?.map((edge) => {
        const sourceNode =
          treeNetworkState?.nodes?.find((node) => node?.id === edge?.from) ||
          null;
        const targetNode =
          treeNetworkState?.nodes?.find((node) => node?.id === edge?.to) ||
          null;

        return {
          ...edge,
          direction: 'Incoming',
          source: sourceNode,
          target: targetNode,
        };
      }) || []
  );
};
