const ruleScopes: Array<ProcessingRuleScope> = [
  {
    key: 'dataPart',
    type: 'DataPart',
    urlPath: 'data-part',
    icon: 'DataPartRule',
  },
  {
    key: 'survivorship',
    type: 'Survivorship',
    urlPath: 'survivorship',
    icon: 'SurvivorshipRule',
  },
  {
    key: 'entity',
    type: 'Entity',
    urlPath: 'entity',
    icon: 'GoldenRecordRule',
  },
];

export interface ProcessingRuleScope {
  key: string;
  type: string;
  urlPath: string;
  icon: string;
}

export function getByType(type: string) {
  const scope = ruleScopes.find((scope) => scope.type == type);
  if (!scope) {
    throw new Error(`Unable to get rule type info using type '${type}'.`);
  }
  return scope;
}

export function getByUrlPath(urlPath: string) {
  const scope = ruleScopes.find((scope) => scope.urlPath == urlPath);
  if (!scope) {
    throw new Error(`Unable to get rule type info using urlPath '${urlPath}'.`);
  }
  return scope;
}

export function createCrumb(ruleScope: ProcessingRuleScope) {
  return {
    to: `/admin/management/rule/${ruleScope.urlPath}`,
    id: `module-rule-${ruleScope.key}`,
  };
}

export function getAll() {
  return ruleScopes;
}
