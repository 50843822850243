import isEmpty from 'lodash/isEmpty';

export const checkEmptyValues = (updatedData) => {
  for (let key in updatedData) {
    const value = updatedData[key];

    if (typeof value === 'object' || typeof value === 'boolean') {
      return false;
    }

    if (!isEmpty(value?.trim())) {
      return false;
    }
  }
  return true;
};
