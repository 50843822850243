"use strict";

exports.__esModule = true;
exports.pulseName = exports.default = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _pulseKeyFrames = require("./pulseKeyFrames");
var _templateObject, _templateObject2;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var pulseName = (0, _styledComponents.keyframes)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  ", ";\n"])), Object.keys(_pulseKeyFrames.pulseKeyframesConfig).map(function (key) {
  return key + " { opacity: " + _pulseKeyFrames.pulseKeyframesConfig[key] + ";}";
}));

// export const pulseName = keyframes`
//   0% { opacity: 0.32; }
//   50% { opacity: 0.72; }
//   100% { opacity: 0.32; }
// `;
exports.pulseName = pulseName;
var IconPlaceholder = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  width: 46px;\n  border-radius: 46px;\n  height: 46px;\n  ", ";\n  background: #ccc;\n  background: ", ";\n  animation: ", " ", " ", " infinite;\n  ", ";\n"])), function (_ref) {
  var size = _ref.size;
  return size ? "width: " + size + "px; height: " + size + "px; border-radius: " + size + "px;" : '';
}, function (_ref2) {
  var color = _ref2.color;
  return "" + (color || '#ccc');
}, pulseName, _pulseKeyFrames.pulseDuration, _pulseKeyFrames.pulseEasing, function (_ref3) {
  var centered = _ref3.centered;
  return centered ? 'margin-left :auto; margin-right: auto;' : '';
});
var _default = IconPlaceholder;
exports.default = _default;