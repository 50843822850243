import {
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';

import type { Filters } from '../../rule/types/ruleConditionTypes';
import { AppSearchScope, AppSearchScopes } from '../AppSearch.types';
import {
  SuggestedSearchResponse,
  useQuerySuggestedSearch,
} from '../hooks/useQuerySuggestedSearch';
import { useAppSearchBrowserApi } from './useAppSearchBrowserApi';

import {
  searchURI,
  defaultScope,
  defaultValue,
  queryParamName,
  searchByAllValue,
} from './appSearchConstants';

type UseAppSearchBrowserApiReturnType = ReturnType<
  typeof useAppSearchBrowserApi
>;
type QuerySuggestedSearchReturnType = ReturnType<
  typeof useQuerySuggestedSearch
>;

export type ValuesForClean = {
  query?: string;
  tags?: string[];
  condition?: Filters;
  entityType?: string[];
  providers?: string[];
  providerDefinitionIds?: string[];
  columns: { property: string; type: string }[];
};

export type SearchStore = {
  value: string;
  scope: AppSearchScope;
  valueChanged: boolean;
  scopeChanged: boolean;
  isOnSearchPage: boolean;
  handleFocus: () => void;
  resetSearch: () => void;
  handleCancel: () => void;
  searchWasRequested: boolean;
  isSuggestionsCalloutOpen: boolean;
  handleOpenSuggestions: () => void;
  handleCloseSuggestions: () => void;
  searchWasChangedAndRequested: boolean;
  quiteSetValue: (value: string) => void;
  valuesForClean: ValuesForClean | undefined;
  handleChangeSearch: (value: string) => void;
  handleClickSuggestedItem: (value: string) => void;
  setValuesForClean: Dispatch<SetStateAction<ValuesForClean | undefined>>;
  handleChangeScope: (value: AppSearchScope, doRedirect?: boolean) => void;
} & Omit<
  UseAppSearchBrowserApiReturnType,
  'handleClearSearch' | 'handleSetSuggestedItem'
> &
  QuerySuggestedSearchReturnType;

const getScopeFromPathName = (searchStr: string): AppSearchScope => {
  const search = qs.parse(decodeURI(searchStr));

  try {
    const rawEntityType = JSON.parse(
      search?.entityType || search?.entitytype || search?.ENTITYTYPE,
    );
    const entityType =
      typeof rawEntityType === 'string'
        ? JSON.parse(rawEntityType)
        : rawEntityType;

    if (entityType?.length !== 1) {
      return AppSearchScopes.all;
    }

    if (
      entityType[0]?.toLowerCase()?.replaceAll('/', '') ===
      AppSearchScopes.organization
    ) {
      return AppSearchScopes.organization;
    } else if (
      entityType[0]?.toLowerCase()?.replaceAll('/', '') ===
      AppSearchScopes.person
    ) {
      return AppSearchScopes.person;
    }

    return AppSearchScopes.all;
  } catch {
    return AppSearchScopes.all;
  }
};

const getValueFromPathName = (search: string): string => {
  const parsedValue = qs.parse(search);

  return parsedValue?.[queryParamName] ?? defaultValue;
};

export const useAppSearchStore = () => {
  const location = useLocation();
  const history = useHistory();

  const scopeFromPathName = getScopeFromPathName(location.search);
  const valueFromPathName = getValueFromPathName(location.search);

  // State
  const [valuesForClean, setValuesForClean] = useState<ValuesForClean>();

  const [scope, setScope] = useState<AppSearchScope>(scopeFromPathName);
  const [value, setValue] = useState(valueFromPathName);
  const [isSuggestionsCalloutOpen, setIsSuggestionsCalloutOpen] =
    useState(false);
  // Internal
  const [valueInputTouched, setValueInputTouched] = useState(false);

  const isOnSearchPage = useMemo(() => {
    return location.pathname.includes(searchURI);
  }, [location.pathname]);

  const { handleSearch, handleClearSearch, handleSetSuggestedItem } =
    useAppSearchBrowserApi({
      value,
      searchURI,
      isOnSearchPage,
      queryParamName,
      searchByAllValue,
    });

  const skipSuggestedSearch = useMemo(() => {
    return value?.length < 3;
  }, [value]);

  // User actions handlers
  const handleChangeScope = useCallback(
    (newScope: AppSearchScope, doRedirect?: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((history.location.state as any)?.fromExternalPage && doRedirect) {
        history.push({ ...location, state: null });
      }

      setScope(() => newScope);
    },
    [history],
  );

  const reset = useCallback(() => {
    setScope(() => AppSearchScopes.all);
    setValue(() => '');
  }, []);

  const handleOpenSuggestions = useCallback(() => {
    setIsSuggestionsCalloutOpen(() => true);
  }, []);

  const handleCloseSuggestions = useCallback(() => {
    setIsSuggestionsCalloutOpen(() => false);
  }, []);

  const handleChangeSearch = useCallback(
    (newValue: string) => {
      setValueInputTouched(() => true);

      setValue((prevValue) => {
        if (prevValue?.length > 2 && newValue?.length < 3) {
          handleCloseSuggestions();
        }

        return newValue;
      });
    },
    [isSuggestionsCalloutOpen],
  );

  const quiteSetValue = useCallback((newValue: string) => {
    setValue(() => newValue);

    handleCloseSuggestions();
    setValueInputTouched(() => false);
  }, []);

  const handleCancel = useCallback(() => {
    setValue(() => defaultValue);
    setScope(() => defaultScope);
    setValueInputTouched(() => false);

    if (isOnSearchPage) {
      handleClearSearch();
    }
  }, [isOnSearchPage]);

  const handleClickSuggestedItem = useCallback((itemValue: string) => {
    setValue(() => itemValue);

    setValueInputTouched(() => false);

    handleSetSuggestedItem(itemValue);

    handleCloseSuggestions();
  }, []);

  const handleFocus = useCallback(() => {
    if (value?.length > 2) {
      setValueInputTouched(() => true);

      handleOpenSuggestions();
    }
  }, [value]);

  // Operational methods
  const onCompletedLoadingSuggestedSearchResults = useCallback(
    (data: SuggestedSearchResponse) => {
      if (data?.search?.searchSuggestions?.results?.length > 0) {
        return handleOpenSuggestions();
      }

      if (isSuggestionsCalloutOpen) {
        handleCloseSuggestions();
      }
    },
    [isSuggestionsCalloutOpen],
  );

  // Api queries
  const {
    suggestedSearchResults,
    loadingSuggestedSearchResults,
    previousSuggestedSearchResults,
  } = useQuerySuggestedSearch({
    value,
    skip: skipSuggestedSearch || !valueInputTouched,
    onCompleted: onCompletedLoadingSuggestedSearchResults,
  });

  const valueChanged = useMemo(() => {
    return (value || defaultValue || searchByAllValue) !== searchByAllValue;
  }, [value]);

  const scopeChanged = useMemo(() => {
    return (scope || defaultScope) !== defaultScope;
  }, [scope]);

  const searchWasRequested = useMemo(() => {
    return (value || defaultValue || searchByAllValue) === valueFromPathName;
  }, [value, valueFromPathName]);

  const searchWasChangedAndRequested = useMemo(() => {
    return (scopeChanged || valueChanged) && searchWasRequested;
  }, [scopeChanged, valueChanged, value, searchWasRequested]);

  return {
    value,
    scope,
    handleFocus,
    handleCancel,
    handleSearch,
    valueChanged,
    scopeChanged,
    quiteSetValue,
    isOnSearchPage,
    valuesForClean,
    setValuesForClean,
    handleChangeScope,
    resetSearch: reset,
    handleChangeSearch,
    searchWasRequested, // shows that user changed either scope either search value and clicked search icon
    handleOpenSuggestions,
    handleCloseSuggestions,
    suggestedSearchResults,
    isSuggestionsCalloutOpen,
    handleClickSuggestedItem,
    searchWasChangedAndRequested, // shows that user changed either scope either search value !FOR NONE DEFAULT! and clicked search icon
    loadingSuggestedSearchResults,
    previousSuggestedSearchResults,
  };
};
