import { FormattedMessage } from 'react-intl';

import { cleanProjectRootURL } from '../../cleanProject/module';
import * as dedupModule from '../../deduplication/module';
import { manualDataEntryRootUrl } from '../../manualDataEntry/module';

import { EntityHistory as EntityHistoryContainer } from '../reuse/EntityHistory';
import { useQueryEntitySummaryById } from '../hooks/useEntityHistorySummary';
import { useQueryEntityHistoryDetails } from '../hooks/useEntityHistoryDetails';

interface EntityHistoryProps {
  entityId: string;
}

const EntityHistory: React.FC<EntityHistoryProps> = (props) => {
  return (
    <div style={{ padding: '16px' }}>
      <EntityHistoryContainer
        entityId={props.entityId}
        useQueryEntitySummaryById={useQueryEntitySummaryById}
        useQueryEntityHistoryDetails={useQueryEntityHistoryDetails}
        labels={{
          recordSingular: <FormattedMessage id="module-entity-record" />,
          recordPlural: <FormattedMessage id="module-entity-records" />,
          by: <FormattedMessage id="module-entity-versionHistoryByUser" />,
          propertyHeading: (
            <FormattedMessage id="module-entity-propertyHeading" />
          ),
          changeTypeHeading: (
            <FormattedMessage id="module-entity-changeTypeHeading" />
          ),
          oldValueHeading: (
            <FormattedMessage id="module-entity-oldValueHeading" />
          ),
          newValueHeading: (
            <FormattedMessage id="module-entity-newValueHeading" />
          ),
          allVersions: (
            <FormattedMessage id="module-entity-allVersionsHistory" />
          ),
          noHistoryFound: <FormattedMessage id="module-entity-noHistory" />,
          source: <FormattedMessage id="module-entity-source-label" />,
          cleanTitle: (
            <FormattedMessage id="module-entity-source-clean-title" />
          ),
          sourceDeduplicationProjectTitle: (
            <FormattedMessage id="module-entity-source-dedupProject-title" />
          ),
          sourceManualMergeTitle: (
            <FormattedMessage id="module-entity-source-manualMerge-title" />
          ),
          cluedInManualEntryTitle: (
            <FormattedMessage id="module-entity-source-manualEntry-title" />
          ),
        }}
        cleanRootUrl={cleanProjectRootURL}
        dedupRootUrl={`${dedupModule.rootURL}/detail`}
        manualDataEntryRootUrl={manualDataEntryRootUrl}
      />
    </div>
  );
};

export default EntityHistory;
