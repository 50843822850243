/* eslint-disable */
import React from 'react';
import NotSignedInLayoutDividedInTwo from '../../../auth/components/composites/NotSignedInLayoutDividedInTwo';
import ResetPwdFormContainer from './ResetPwdFormContainer';
import PublicContext from '../../../auth/components/composites/PublicContext';
import ForgotImg from '../../assets/forgot-illustration.png';

const resetPwd = ({ location: { search } }) => {
  const params = new URLSearchParams(search);
  const code = params.get('code') || '';
  return (
    <NotSignedInLayoutDividedInTwo
      invitationId={code}
      Page={ResetPwdFormContainer}
      Context={PublicContext}
      url={`${ForgotImg}`}
    />
  );
};

export default resetPwd;
