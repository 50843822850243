import { gql, useQuery } from '@apollo/client';

import { SavedSearch } from './useSavedSearch';

export const GET_CONNECTOR_INFO = gql`
  query getConnectorInfoByProviderDefinitionIds($ids: [String]) {
    inbound {
      getConnectorInfoByProviderDefinitionIds(ids: $ids) {
        id
        name
        providerId
        icon
      }
    }
  }
`;

type ConnectorInfoByProviderDefinitionIds = {
  inbound: {
    getConnectorInfoByProviderDefinitionIds: {
      id: string;
      name: string;
      providerId: string;
      icon: string;
    }[];
  };
};

export const useGetConnectorInfoByProviderDefinitionIds = ({
  ids,
}: {
  ids: string[];
}) => {
  const { data, loading, error } = useQuery<
    ConnectorInfoByProviderDefinitionIds,
    {
      ids: string[];
    }
  >(GET_CONNECTOR_INFO, {
    fetchPolicy: 'network-only',
    skip: !ids || ids.length === 0,
    variables: {
      ids,
    },
  });

  return {
    data: data?.inbound.getConnectorInfoByProviderDefinitionIds,
    error,
    loading,
  };
};
