import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import {
  shouldFetchAllIntegrations,
  shouldFetchAllIntegrationsForSources,
} from '../../actions';

const mapToSelectProps =
  (mappingProps) =>
  ({ integration: { allIntegrations, isFetchingAllIntegrations } }, props) => {
    const { integrationId } = mappingProps ? mappingProps(props) : props;

    const integration = (allIntegrations || []).find(
      (i) => i.Id === integrationId,
    );

    return {
      integration,
      isFetchingIntegration: isFetchingAllIntegrations,
    };
  };

const mapDispatchToProps = (dispatch) => ({
  fetchProviders: () => {
    dispatch(shouldFetchAllIntegrations());
  },
  fetchProvidersForSources: () => {
    dispatch(shouldFetchAllIntegrationsForSources());
  },
});

export const withIntegrationFromId = (Comp, mappingProps) => {
  const EnhancedComp = compose(
    lifecycle({
      componentDidMount() {
        const { fetchProviders } = this.props;
        fetchProviders();
      },
    }),
  )(Comp);

  return connect(
    mapToSelectProps(mappingProps),
    mapDispatchToProps,
  )(EnhancedComp);
};

export const withIntegrationFromIdForSources = (Comp, mappingProps) => {
  const EnhancedComp = compose(
    lifecycle({
      componentDidMount() {
        const { fetchProvidersForSources } = this.props;
        fetchProvidersForSources();
      },
    }),
  )(Comp);

  return connect(
    mapToSelectProps(mappingProps),
    mapDispatchToProps,
  )(EnhancedComp);
};

export default withIntegrationFromId;
