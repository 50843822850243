import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { FormattedMessage } from '@cluedin/locale';
import { TableRow, TableRowColumn } from '@cluedin/list-pattern';
import { withIntegrationSourceFromProperty } from '../../../integration/components/hocs/withIntegrationSourceFromProperty';
import withEntityMerge from '../Hocs/withEntityMerge';
import { encodeRRFKey } from './EntityComparePropertiesResolver';

const ValueWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: calc(100% - (24px + 6px));
  white-space: initial;
  ${({ noWhiteSpace }) => (noWhiteSpace ? 'word-break: break-all' : '')};
`;
/* eslint-disable indent */

/* eslint-enable indent */

const styles = {
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'baseline',
    borderBottom: '1px solid #cecece',
    paddingBottom: '16px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px',
  },
  header: {
    minWidth: '180px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  label: {
    marginTop: '4px',
    display: 'flex',
  },
  radioItemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  entityName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  radioControlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  source: {
    fontWeight: 200,
    color: '#646464',
  },
};

/* eslint-disable react/prefer-stateless-function */
class EntityReviewPropertiesResolverRow extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.renderItem = this.renderItem.bind(this);
  //   // this.renderItemControl = this.renderItemControl.bind(this);
  // }
  /*
  // eslint-next-disable-line class-methods-use-this
  renderItemControl(conflictedPropValue, theKey, conflictedProp) {
    const { value } = conflictedPropValue;

    const displayValue = value === null // eslint-disable-line no-nested-ternary
      ? '–NO VALUE SELECTED–'
      : value === '' ? '–EMPTY STRING–' : value
    ;
    const hasWhiteSpace = (displayValue && displayValue.match(/\s/gi)) || false;
    return (
      <TableRowColumn>
        <div style={styles.radioControlsWrapper}>
          <ValueWrapper noWhiteSpace={!hasWhiteSpace}>
            {displayValue}
          </ValueWrapper>
        </div>
      </TableRowColumn>
    );
  }

  renderItem(conflictedProp) {
    const { propsDelta } = this.props;
    const { values, key } = conflictedProp;

    values
      .map((value) => {
        const decodeKey = encodeRRFKey(key);
        const selectedValueHopefully = propsDelta[decodeKey] || {};
        if (value.entityId === selectedValueHopefully.value) {
          return {
            ...value,
          };
        }
        return {
          ...value,
          value: null,
        };

    return this.renderItemControl()


    return (
      // <div style={styles.radioItemWrapper}>
      values
        .map((value) => {
          const decodeKey = encodeRRFKey(key);
          const selectedValueHopefully = propsDelta[decodeKey] || {};
          if (value.entityId === selectedValueHopefully.value) {
            return {
              ...value,
            };
          }
          return {
            ...value,
            value: null,
          };
        })
        // .filter((value) => {
        //   const decodeKey = encodeRRFKey(key);
        //   const selectedValueHopefully = propsDelta[decodeKey] || {};
        //   if (value.entityId === selectedValueHopefully.value) {
        //     return true;
        //   }
        //   return false;
        // })
        .map(x => x.value === null
          ? this.renderItemControl(x, key, conflictedProp)
          : null
        )
    // </div>
    );
  }
*/
  render() {
    const {
      conflictedProp,
      // selectedEntities,
      // isLast,
      // source,
      propsDelta,
    } = this.props;

    const dataFromFormForKey = propsDelta[encodeRRFKey(conflictedProp.key)];

    const { value: selectedEntityId = 'no entityId' } =
      dataFromFormForKey || {};

    const conflictedPropCleaned = {
      ...conflictedProp,
      values: conflictedProp.values.reduce((ac, val) => {
        if (!dataFromFormForKey || val.entityId !== selectedEntityId) {
          ac = ac[0] ? ac : [null]; // eslint-disable-line no-param-reassign
        } else if (val.entityId === selectedEntityId) {
          ac = [val]; // eslint-disable-line no-param-reassign
        }

        return ac;
      }, []),
    };

    const theKey = conflictedProp.key;
    let theKeyDisplayName = theKey;

    if (
      conflictedProp &&
      conflictedProp.schema &&
      conflictedProp.schema.propertyInSchema &&
      conflictedProp.schema.propertyInSchema.DisplayName
    ) {
      theKeyDisplayName = conflictedProp.schema.propertyInSchema.DisplayName;
    }

    return (
      <TableRow>
        <TableRowColumn>
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>{theKeyDisplayName}</strong>
            <small style={{ color: 'grey' }}>{theKey}</small>
          </span>
        </TableRowColumn>
        {/* {selectedEntities.length && this.renderItem(conflictedProp)} */}
        {conflictedPropCleaned.values.map((value) => {
          const displayValue =
            value === null ? (
              <FormattedMessage id="module-entityMerge-noValue" />
            ) : (
              value.value
            );

          const hasWhiteSpace = (displayValue || '').match(/\s/gi) || false;
          return (
            <TableRowColumn>
              <div style={styles.radioControlsWrapper}>
                <ValueWrapper noWhiteSpace={!hasWhiteSpace}>
                  {displayValue}
                </ValueWrapper>
              </div>
            </TableRowColumn>
          );
        })}
      </TableRow>
    );
    /*
    return (
      <div style={{ ...styles.wrapper, ...(isLast ? { border: 'none' } : {}) }}>
        <div style={styles.headerWrapper}>
          <h4 style={styles.header}>
            {displayNameOrKey}
          </h4>
            &nbsp;&nbsp;
          {sourceImageUrl && (
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Img src={sourceImageUrl} width={20} />
            </div>
          )}
            &nbsp;
          <small style={styles.source}>{source.Name}</small>
        </div>
        <div style={{ width: '100%' }}>
          {selectedEntities.length && this.renderItem(conflictedProp) }
        </div>
      </div>
    );
    */
  }
}

EntityReviewPropertiesResolverRow.defaultProps = {
  source: 'CluedIn',
};

// export default withIntegrationSourceFromProperty(
//   EntityReviewPropertiesResolverRow
// );

export default compose(
  withIntegrationSourceFromProperty,
  withEntityMerge,
)(EntityReviewPropertiesResolverRow);
