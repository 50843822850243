import React from 'react';
import styled from 'styled-components';
import { JSON } from 'window-or-global';
import SelectedFilter from './SelectedFilter';

const SelectedFiltersWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 264px;
  width: 100%;
`;

const SelectedFilters = ({ onFocusIn, filters = [], onRemoveFilter }) => (
  <SelectedFiltersWrapper>
    {filters.map((f) => (
      <>
        <SelectedFilter
          filter={f}
          onRemoveFilter={onRemoveFilter}
          onFocusIn={onFocusIn}
        />
      </>
    ))}
  </SelectedFiltersWrapper>
);

export default SelectedFilters;
