import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';

const ENTITY_SPLIT_PREVIEW = gql`
  mutation entitySplitPreview($id: ID!, $params: String!) {
    virtualization {
      entitySplitPreview(id: $id, params: $params) {
        currentDataParts {
          dataPartId
          name
          provider {
            providerDefinitionId
            name
            providerId
            providerName
            providerIcon
          }
          sortDate
        }
        newEntities {
          dataParts {
            dataPartId
            name
            provider {
              providerDefinitionId
              name
              providerId
              providerName
              providerIcon
            }
            sortDate
          }
        }
        kind
      }
    }
  }
`;

export const useEntitySplitPreview = () => {
  const [entitySplitPreview, { data, loading, error }] =
    useMutation(ENTITY_SPLIT_PREVIEW);

  const splitPreview = get(data, 'virtualization.entitySplitPreview');

  return [
    (id, params) => {
      entitySplitPreview({
        variables: {
          id,
          params,
        },
      });
    },
    {
      data: splitPreview,
      loading,
      error,
    },
  ];
};
