import { withClaimsDisabled } from '@cluedin/components';
import DashboardKPI from './DashboardKPI';
import UnstyledLink from '../UnstyledLink';
import RenderChildren from '../RenderChildren';

const Kpi = ({
  className,
  hasNew,
  icon,
  value,
  label,
  drawer: { route } = {},
  disabled,
}) => {
  const WrapperComp = route ? UnstyledLink : RenderChildren;
  return (
    <WrapperComp to={!disabled && route} disabled={disabled}>
      <DashboardKPI
        className={className}
        hasNew={hasNew}
        value={!disabled ? value : '-'}
        disabled={disabled}
        title={label}
        icon={icon}
      />
    </WrapperComp>
  );
};

export default withClaimsDisabled(Kpi);
