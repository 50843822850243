"use strict";

exports.__esModule = true;
exports.EntityHistoryVersions = exports.EntityHistoryHeaderWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var EntityHistoryHeaderWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  background: #ffffff;\n  display: flex;\n  border-bottom: 1px solid #ececec;\n  border-left: 1px solid #ececec;\n  border-right: 1px solid #ececec;\n  border-top: 1px solid #ececec;\n  justify-content: space-between;\n  padding: 14px 30px;\n"])));
exports.EntityHistoryHeaderWrapper = EntityHistoryHeaderWrapper;
var EntityHistoryVersions = _styledComponents.default.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  color: rgb(55, 55, 58);\n  display: flex;\n  font-size: 16px;\n  font-weight: 600;\n  justify-content: center;\n  height: 100%;\n"])));
exports.EntityHistoryVersions = EntityHistoryVersions;