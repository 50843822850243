import { apiRequest } from '../core/helpers/request';

export const currentUsage = () => apiRequest('GET', 'api/organization/usage');

export const current = () => apiRequest('GET', 'api/organization');

export const getById = (id, apiToken) =>
  apiRequest(
    'GET',
    `api/entity/code?id=/Organization%23CluedIn:${id}`,
    null,
    apiToken,
  );

export const save = (organization) =>
  apiRequest('PUT', 'api/organization/', organization);

export const uploadFile = (file) =>
  apiRequest('POST', 'api/organization/upload', file);

const toEntityConfigModel = (entityConfig) => ({
  DisplayName: entityConfig.displayName,
  Id: entityConfig.id,
  Icon: entityConfig.icon,
  Route: `${entityConfig.route}/:id`,
  Type: entityConfig.entityType,
  LayoutConfiguration: entityConfig.template,
  Active: true,
});

export const updateEntityConfig = (entityConfig) =>
  apiRequest('PUT', 'api/v1/entityinfo', toEntityConfigModel(entityConfig));

export const createEntityConfig = (entityConfig) =>
  apiRequest('POST', 'api/v1/entityinfo', toEntityConfigModel(entityConfig));
