import React from 'react';
import { compose, lifecycle } from 'recompose';
import Alert from 'uxi/Alert';
import { connect } from 'react-redux';
import Flex from 'uxi/Layout/Flex';
import { FormattedHTMLMessage } from '@cluedin/locale';
import { url } from '../../../../config';

const { mainApp } = url;

const OrgClientIdNotFoundComponent = () => {
  return (
    <Flex style={{ minHeight: '200px' }}>
      <Alert>
        <FormattedHTMLMessage id="module-public-OrgClientIdNotFoundComponent" />
      </Alert>
    </Flex>
  );
};

const OrgClientIdNotFoundComponentEnchanced = compose(
  lifecycle({
    componentDidMount() {
      const { redirect } = this.props;
      setTimeout(redirect, 5000);
    },
  }),
)(OrgClientIdNotFoundComponent);

const mapStateToProps = () => ({});
const mapDispatchToProps = (/* dispatch */) => ({
  redirect() {
    window.location.assign(mainApp);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgClientIdNotFoundComponentEnchanced);
