import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose, branch, renderComponent } from 'recompose';
import {
  shouldPlay,
  shouldPause,
  shouldApprove,
  shouldReject,
  shouldReAuth,
  shouldHideProductOwner,
  shouldReprocess,
} from '../../actions';
import PageLoader from '../../../core/components/composites/PageLoader';
import ConfigurationOverviewComposite from '../composites/ConfigurationOverviewComposite';
import UserPickerDialog from '../../../core/components/composites/UserPickerDialog';
import SetProviderOwnerContainer from './SetProviderOwnerContainer';
import { withIntegrationWithId } from '../../../integration/components/hocs/withIntegrationConfig';

const ConfigurationOverviewCompositeWithDialog = (props) => (
  <div>
    <UserPickerDialog
      show={props.isShowingProductOwner}
      style={{ display: 'inline' }}
      onClose={props.hideProductOwner}
      SetProviderOwnerComponent={SetProviderOwnerContainer}
    />
    <ConfigurationOverviewComposite {...props} />
  </div>
);

const EnhancedConfigurationOverviewContainer = compose(
  branch((props) => !props.configuration, renderComponent(PageLoader)),
)(ConfigurationOverviewCompositeWithDialog);

const mapStateToProps = ({
  configuration: {
    playConfigurations,
    pauseConfigurations,
    approveConfigurations,
    rejectConfigurations,
    selectedConfiguration,
    isShowingProductOwner,
  },
}) => ({
  isShowingProductOwner,
  configuration: selectedConfiguration,
  isLoadingPlay: selectedConfiguration
    ? (playConfigurations[selectedConfiguration.Id] || {}).isFetching
    : false,
  isLoadingPause: selectedConfiguration
    ? (pauseConfigurations[selectedConfiguration.Id] || {}).isFetching
    : false,
  isLoadingApprove: selectedConfiguration
    ? (approveConfigurations[selectedConfiguration.Id] || {}).isFetching
    : false,
  isLoadingReject: selectedConfiguration
    ? (rejectConfigurations[selectedConfiguration.Id] || {}).isFetching
    : false,
});

const mapDispatchToProps = (dispatch) => ({
  hideProductOwner() {
    dispatch(shouldHideProductOwner());
  },
  play({ ProviderId, Id, AccountId }) {
    dispatch(
      shouldPlay({
        providerId: ProviderId,
        accountId: AccountId,
        id: Id,
      }),
    );
  },
  pause({ ProviderId, AccountId, Id }) {
    dispatch(
      shouldPause({
        providerId: ProviderId,
        accountId: AccountId,
        id: Id,
      }),
    );
  },
  reProcess(configId) {
    dispatch(shouldReprocess(configId));
  },
  approve(configuration) {
    dispatch(
      shouldApprove({
        id: configuration.Id,
        data: {
          Id: configuration.Id,
          helperConfiguration: {},
          Source: configuration.Source,
          SourceQuality: configuration.SourceQuality,
          AutoSync: configuration.AutoSync || true,
          AccountDisplay: configuration.AccountDisplay,
        },
      }),
    );
  },
  reject({ Id, ProviderId }) {
    dispatch(
      shouldReject({
        id: Id,
        providerId: ProviderId,
      }),
    );
  },
  reAuth(configuration, integrationConfig) {
    dispatch(shouldReAuth(configuration, integrationConfig));
  },
  viewSetting(configuration) {
    dispatch(
      push(
        `/admin/configuration/${configuration.ProviderId}/${configuration.Id}/settings`,
      ),
    );
  },
  viewPermissions(configuration) {
    dispatch(
      push(
        `/admin/configuration/${configuration.ProviderId}/${configuration.Id}/permissions`,
      ),
    );
  },
});

export default withIntegrationWithId(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EnhancedConfigurationOverviewContainer),
);
