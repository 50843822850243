import React from 'react';
import DefaultWrapper from './DefaultWrapper';

const EntitiesList = (Component, options) => (props) => {
  const Wrapper = options.Wrapper ? options.Wrapper : DefaultWrapper;

  return (
    <Wrapper>
      <Component {...props} />
    </Wrapper>
  );
};

export default EntitiesList;
