import { AvatarWithName } from '@cluedin/atoms';
import { get } from 'lodash';
import { getEntityTypeIcon } from '@cluedin/components';
import { HighlightText } from '@cluedin/components';

// TODO: entityConfig MUST come from the props not REDUX
const EntityTypeAvatar = ({
  entityConfig = {},
  query = '',
  entityType,
  color,
  style = {},
  onlyLabel,
}) => {
  const icon = get(entityConfig, 'icon');
  const displayName = get(entityConfig, 'displayName');

  const Icon = getEntityTypeIcon(icon || 'Help'); //yes need a fallback as value is NULL

  if (onlyLabel) {
    return (
      <HighlightText
        style={color ? { color } : {}}
        text={displayName}
        highlight={query}
      />
    );
  }

  return (
    <AvatarWithName
      style={style}
      imgSize={18}
      icon={<Icon color={color || undefined} />}
      name={
        <HighlightText
          style={color ? { color } : {}}
          text={displayName || entityType}
          highlight={query}
        />
      }
    />
  );
};

export default EntityTypeAvatar;
