import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_PAGE_GRID_LAYOUTS = gql`
  query getEntityPageLayouts {
    virtualization {
      getEntityPageLayouts {
        id
        name
        children
      }
    }
  }
`;

export const useGrid = (gridName) => {
  const { data, loading, error } = useQuery(GET_ENTITY_PAGE_GRID_LAYOUTS);

  const layouts = get(data, 'virtualization.getEntityPageLayouts');

  const grid =
    layouts?.find((l) => l.name === gridName) ||
    layouts?.find((l) => l.name === 'Two Column');

  return [grid, loading, error];
};

export const useGrids = () => {
  const { data, loading, error } = useQuery(GET_ENTITY_PAGE_GRID_LAYOUTS);

  const grids = get(data, 'virtualization.getEntityPageLayouts');

  return [grids, loading, error];
};
