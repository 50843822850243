import React from 'react';
import { AvatarWithName } from '@cluedin/atoms';
import UserBadge from './UserBadge';
import UserUnknown from './UserUnknown';

const UserAvatar = ({ user }) => {
  if (!user || (!user.username && !user.userName)) {
    return <UserUnknown user={user} />;
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      <AvatarWithName
        icon={<UserBadge user={user} />}
        name={user.username || user.userName}
      />
    </div>
  );
};

export default UserAvatar;
