import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';

export default {
  pillar: 'me',
  name: 'tasks',
  displayName: <FormattedMessage id="tasks" />,
  path: '/tasks',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Task',
        name: (
          <div style={{ display: 'block' }}>
            <div>
              <FormattedMessage id="module-tasks-review" />
            </div>
            <div>
              <FormattedMessage id="module-tasks-changeRequests" />
            </div>
          </div>
        ),
        link: '/me/tasks/approval-request/assigned',
      },
      {
        icon: 'Task',
        name: (
          <div style={{ display: 'block' }}>
            <div>
              <FormattedMessage id="module-tasks-change" />
            </div>
            <div>
              <FormattedMessage id="module-tasks-requests" />
            </div>
          </div>
        ),
        link: '/me/tasks/approval-request/requested',
      },
      {
        icon: 'Administrator',
        name: (
          <div style={{ display: 'block' }}>
            <div>
              <FormattedMessage id="module-tasks-review" />
            </div>
            <div>
              <FormattedMessage id="module-tasks-roleRequests" />
            </div>
          </div>
        ),
        link: '/me/tasks/role-request/assigned',
      },
      {
        icon: 'Administrator',
        name: (
          <div style={{ display: 'block' }}>
            <div>
              <FormattedMessage id="module-tasks-role" />
            </div>
            <div>
              <FormattedMessage id="module-tasks-requests" />
            </div>
          </div>
        ),
        link: '/me/tasks/role-request/requested',
      },
    ],
  },
  routes,
};
