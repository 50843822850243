"use strict";

exports.__esModule = true;
exports.useThemeConfiguration = void 0;
var _react = require("react");
var _ThemeConfigurationContext = require("./ThemeConfigurationContext");
var useThemeConfiguration = function useThemeConfiguration() {
  var context = (0, _react.useContext)(_ThemeConfigurationContext.ThemeConfigurationContext);
  if (context === undefined) {
    throw new Error('useThemeConfiguration must be used within a ThemeConfigurationContext');
  }
  return context;
};
exports.useThemeConfiguration = useThemeConfiguration;