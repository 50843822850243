import React from 'react';
import EntityListContainer from '../../../entityLists/components/containers/EntityListContainer';

const SearchResultList = ({ data = [], isFetchingMainSearch }) => {
  return (
    <EntityListContainer
      viewName="search"
      data={data}
      isFetchingMainSearch={isFetchingMainSearch}
    />
  );
};

export default SearchResultList;
