import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import update from 'immutability-helper';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import { Add } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';
import SortableSchemaPropertyList from './SortableSchemaPropertyList';
import SchemaPropertyChooserDialog from './SchemaPropertyChooserDialog';
import DefaultSelectionWhenNoneSelected from './DefaultSelectionWhenNoneSelected';

const DiscreteText = styled.span`
  opacity: ${({ maxedOut }) => (maxedOut === true ? 1 : 0.5)};
`;

export const SchemaFieldsPicker = ({
  schema,
  fields = [],
  onChange,
  options,
}) => {
  const [isOpen, setDialog] = useState(false);
  const [selectedFields, setFields] = useState(fields, []);
  const { restriction } = options || {};
  const { standardFields } = options || {};
  const defaultFields = standardFields || [];
  // const hasCorrectNumber = (selectedFields.length === restriction);
  const maxedOut =
    restriction !== undefined && selectedFields.length >= restriction;

  useEffect(() => {
    setFields(fields);
  }, [fields]);

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = selectedFields[dragIndex];
    const updatedField = update(selectedFields, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });

    setFields(updatedField);
    onChange(updatedField);
  };

  return (
    <Fragment>
      <div
        style={{
          padding: '16px',
          borderBottom: '1px solid #ccc',
          display: 'flex',
        }}
      >
        <div style={{ flex: 1 }}>
          {(selectedFields || []).length} selected{' '}
          {restriction !== undefined ? (
            <DiscreteText maxedOut={maxedOut}>
              on {restriction} max
            </DiscreteText>
          ) : (
            ''
          )}
        </div>
        <ButtonLink
          // buttonlink doesn't support disabled :( )
          // disabled={restriction !== undefined && (selectedFields.length >= restriction)}
          style={{
            ...(maxedOut
              ? {
                  opacity: 0.5,
                  filter: 'grayscale(100%)',
                  pointerEvents: 'none',
                }
              : {}),
          }}
          icon={<Add />}
          text="Add Property"
          onClick={() => {
            setDialog(true);
          }}
        />
      </div>
      {selectedFields.length === 0 && (
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <div>
            <FormattedMessage id="module-schema-pleaseAddPropsToConfigureView" />
          </div>
          {restriction && (
            <div style={{ marginTop: '2px' }}>
              <FormattedHTMLMessage
                id="module-schema-chooseUpProps"
                values={{ restriction }}
              />
            </div>
          )}
          <div style={{ marginTop: '16px' }}>
            <DefaultSelectionWhenNoneSelected defaultFields={defaultFields} />
          </div>
        </div>
      )}
      <SortableSchemaPropertyList
        schema={schema}
        fields={selectedFields}
        defaultFields={defaultFields}
        onMoveCard={moveCard}
        onChangeDefaultField={(fieldName) => {
          const foundDefaultField = selectedFields.find(
            (f) => f.field === fieldName,
          );

          foundDefaultField.disabled = !foundDefaultField.disabled;

          const updateFields = [...selectedFields];

          setFields(updateFields);
          onChange(updateFields);
        }}
        onDeleteField={(field) => {
          const filtered = selectedFields.filter((f) => f.field !== field);

          const updatedFields = [...filtered];

          setFields(updatedFields);
          onChange(updatedFields);
        }}
      />

      <SchemaPropertyChooserDialog
        show={isOpen}
        onSchemaSelect={(selectedSchema) => {
          const updateFields = [
            ...selectedFields,
            {
              index: fields.length,
              id: selectedSchema.Key,
              field: selectedSchema.Key,
            },
          ];
          setFields(updateFields);
          onChange(updateFields);

          setDialog(false);
        }}
        unavailableProperties={selectedFields.map(({ field }) => field)}
        onClose={() => {
          setDialog(false);
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ entity: { schema } }) => {
  return {
    schema,
  };
};

export default connect(mapStateToProps)(SchemaFieldsPicker);
