import styled from 'styled-components';

export const EntityRelationsContainerWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${({ removeMargin }) => (removeMargin ? '-10px 0 0 0' : '0')};
  position: relative;
`;
