import React, { memo } from 'react';
import { Server } from 'uxi/Icons';
import { TopNavButton } from '../TopNavButton';
import { withServerStatus } from './withServerStatus';
import StatusBadge from './StatusBadge';

const ServerStatusTopIcon = memo(
  ({ open, theme, openWorkflow, statusError, globalStatus, statusLoading }) => {
    const handleOpenMenu = () =>
      openWorkflow({
        theme,
      });

    return (
      <TopNavButton onClick={handleOpenMenu} isActive={open}>
        {!statusLoading && (
          <StatusBadge
            status={globalStatus}
            statusError={statusError}
            isGlobal
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              width: '12px',
              height: '12px',
            }}
          />
        )}
        <Server size={20} color={theme.palette.white} />
      </TopNavButton>
    );
  },
);

const ServerStatusWithPanel = withServerStatus(ServerStatusTopIcon);

export const ServerStatus = ServerStatusWithPanel;
