import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

const PillarIconWrapper = styled.div`
  cursor: pointer;

  &:hover {
    svg {
      fill: ${({ theme: { palette } }) => palette.themeSecondary};
    }
  }

  svg {
    fill: ${({ theme: { palette } }) => palette.themePrimary};
  }
`;

export const PillarIcon = ({ Icon }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.themePrimary;

  return (
    <PillarIconWrapper theme={theme}>
      <Icon color={primaryColor} />
    </PillarIconWrapper>
  );
};
