import React from 'react';

import { noEntity, noValue } from './CellStyles';

const ForGrid = (Comp, p) => (entity) => {
  if (!entity) {
    return noEntity;
  }

  const { properties } = entity;

  if (!properties) {
    return noValue;
  }

  const value = properties[`property-${p}`] || entity[p];

  if (value === null || value === undefined) {
    return noValue;
  }

  return <Comp value={value} />;
};

export default ForGrid;
