import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default {
  pillar: 'testing',
  name: 'upload',
  displayName: 'Upload',
  path: '/upload',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Datacleaning',
        name: 'Upload',
        link: '/admin/testing/upload',
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      component: OnlyClientIdSubDomain(
        makeLazy(() =>
          import(
            /* webpackChunkName:"UploadPage" */
            './components/pages/UploadPage'
          ),
        ),
      ),
    },
  ],
};
