import { RACI_LEVELS } from '@cluedin/components';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import { features, url } from '../../config';

const routes = [];

if ((features || []).includes('cluedinClean') && url.cluedInCleanUrl) {
  routes.push({
    path: '/',
    roles: ['OrganizationAdmin', 'Admin', 'DataGovernance'],
    claims: {
      'preparation.clean': RACI_LEVELS.CONSULTANT,
    },
    exact: true,
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"CleaningProjectPage" */
          '../development/components/pages/CleaningProjectPage'
        ),
      ),
    ),
  });
  routes.push({
    path: '/cleaning/:id',
    exact: true,
    roles: ['OrganizationAdmin', 'Admin', 'DataGovernance'],
    claims: {
      'preparation.clean': RACI_LEVELS.CONSULTANT,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"CleaningSingleProjectPage" */
          '../development/components/pages/CleaningSingleProjectPage'
        ),
      ),
    ),
  });
}

export default routes;
