import { FC, useState } from 'react';
import { ButtonLink, ExpandingArrow } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';

import { GraphNode } from '../../../entityRelationsGraphV2/types';
import { TreeEdge } from '../types';
import { EntityRelationsTreeRootEdgeWrapper } from './EntityRelationsTreeRootEdgeWrapper';
import { EntityRelationsTreeRootEdgeBorderCircle } from './EntityRelationsTreeRootEdgeBorderCircle';
import { EntityRelationsTreeRootEdgeTitle } from './EntityRelationsTreeRootEdgeTitle';
import { EntityRelationsTreeRootEdgeDetail } from '../EntityRelationsTreeRootEdgeDetail';
import { UpdatedGraphNetworkEdge } from '../../../entityRelationsGraphV2/containers/types';

type EntityRelationsTreeRootEdgeProps = {
  edge: TreeEdge;
  edgeNode: GraphNode | undefined;
  onEdgeClick: (edge: UpdatedGraphNetworkEdge | TreeEdge) => void;
  label: string;
};

export const EntityRelationsTreeRootEdge: FC<
  EntityRelationsTreeRootEdgeProps
> = ({ edge, edgeNode, onEdgeClick, label }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const edgeWithSplitLabel = {
    ...edge,
    label: label,
  };

  return (
    <>
      <EntityRelationsTreeRootEdgeWrapper>
        <EntityRelationsTreeRootEdgeTitle>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <EntityRelationsTreeRootEdgeBorderCircle />
            <div style={{ marginLeft: '14px' }}>
              <ExpandingArrow
                data-test="entityRelations-rootEdge-expandingArrow"
                expanded={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </div>
            <div style={{ display: 'flex' }}>{label}</div>
          </div>

          <div style={{ marginLeft: '35px', marginTop: '2px' }}>
            {edgeNode?.entityType ? (
              <ButtonLink
                onClick={() => onEdgeClick(edgeWithSplitLabel)}
                text={
                  edgeNode?.label ?? (
                    <FormattedMessage id="module-entityRelationsGraphV2-noName" />
                  )
                }
              />
            ) : edgeNode?.label === 'grouped' ? (
              <FormattedMessage id="module-entityRelationsGraphTreeview-groupedRelationsLabel" />
            ) : (
              edgeNode?.label
            )}
          </div>
        </EntityRelationsTreeRootEdgeTitle>

        {isExpanded && (
          <EntityRelationsTreeRootEdgeDetail edgeNode={edgeNode} />
        )}
      </EntityRelationsTreeRootEdgeWrapper>
    </>
  );
};
