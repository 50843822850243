import { FormattedMessage } from '@cluedin/locale';
import { actionsConfiguration, RACI_LEVELS } from '@cluedin/components';

import routes from './routes';

const { admin } = actionsConfiguration;

export default {
  pillar: 'administration',
  name: 'Settings',
  displayName: <FormattedMessage id="module-settings-name" />,
  path: '/settings',
  claims: {
    'admin.tenantmanagement': RACI_LEVELS.INFORMED,
  },
  extendPillarDashboard: {
    actions: [
      {
        icon: 'settings',
        name: <FormattedMessage id="module-settings-name" />,
        link: '/admin/settings/settings',
        claims: admin.tenantmanagement.tenant.view,
      },
    ],
  },
  routes,
};
