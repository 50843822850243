import React from 'react';
import PropTypes from 'prop-types';
import { User } from 'uxi/Icons';
import Toast from '../../../../core/components/composites/Toast';

const NewUserDemoteNotification = ({ index, userDemoted, onCloseClick }) => {
  const title = `${userDemoted.userName} was demoted as a regular user.`;
  const message = <div>by&nbsp;{userDemoted.authorUserName}</div>;

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={User}
    />
  );
};

NewUserDemoteNotification.propTypes = {
  index: PropTypes.any.isRequired,
  userDemoted: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default NewUserDemoteNotification;
