import React from 'react';
import styled, { css } from 'styled-components';
import modules from './modules';

const { socials, conversation, rctooltip, wmsGrid, graphiQL } = modules;

const AppWrapper = styled.div`
  input::-ms-clear {
    display: none;
  }
  img {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -moz-crisp-edges;
  }
`;

const globalStyles = css`
  ${wmsGrid};
  ${socials};
  ${conversation};
  /* we most likely already have the rctooltip styles from uxi anyay :*/
  ${rctooltip};
  ${graphiQL}; /* Check CluedIn-io/CluedIn.Widget/issues/148 */
`.join('');

export const AppStyles = (props) => (
  <AppWrapper>
    <style dangerouslySetInnerHTML={{ __html: globalStyles }} />
    {props.children}
  </AppWrapper>
);

AppStyles.displayName = 'AppStyles';

AppStyles.defaultProps = {
  children: null,
};

export default AppStyles;
