import { createViewModelFromPropertyPools } from './base';

const typePools = [
  {
    key: 'fileType',
    pool: [
      'property-type',
      'property-fileType',
      'property-document.type',
      'property-document.fileType',
    ],
  },
];

export const createFileTypeViewModel = (entity) => {
  return createViewModelFromPropertyPools(entity, typePools);
};

export default {
  createFileTypeViewModel,
};
