import React from 'react';
import { ThemeProvider as AtomsThemeProviderElement } from '@cluedin/atoms';
import { useTheme } from '@cluedin/theme';

// import { useTheme } from './JUST_FOR_DEV__ThemeProvider';

export const AtomsThemeProvider = ({ children }) => {
  const theme = useTheme();

  return (
    <AtomsThemeProviderElement
      variables={{
        accent: theme.palette.themePrimary,
      }}
    >
      {children}
    </AtomsThemeProviderElement>
  );
};
