import { type ReactNode, type CSSProperties } from 'react';
import {
  type IIconStyleProps,
  type IIconStyles,
  Icon,
} from '@fluentui/react/lib/Icon';
import { type IStyleFunctionOrObject } from '@fluentui/react';

type FieldAlertProps = {
  type?: 'info' | 'warning' | 'error' | 'danger' | 'success';
  message: string | ReactNode;
  style?: CSSProperties;
  iconStyles?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
};

const mainColor = {
  error: '#d13f48',
  danger: '#e66565',
  warning: '#856300',
  info: '#3e53c1',
  success: '#009688',
};

const mainBackgroundColor = {
  info: '#3e53c10f',
  warning: '#FFF8E0',
  success: 'white',
  error: 'white',
  danger: 'white',
};

export const FieldAlert = ({
  message,
  type = 'warning',
  style = {},
  iconStyles,
}: FieldAlertProps) => {
  let iconName = 'Info';
  if (type === 'warning') {
    iconName = 'Warning';
  }

  const textColor = mainColor[type || 'info'];
  const mainBg = mainBackgroundColor[type || 'info'];

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: mainBg,
        borderRadius: '10px',
        padding: '12px',
        lineHeight: 1,
        alignItems: 'center',
        ...style,
      }}
    >
      <Icon
        iconName={iconName}
        styles={{
          root: {
            fontSize: 16,
            height: 16,
            lineHeight: 0,
            span: { marginRight: 12 },
            color: textColor,
          },
          ...iconStyles,
        }}
      />
      <div style={{ color: textColor, fontSize: '12px', margin: 0 }}>
        {message}
      </div>
    </div>
  );
};
