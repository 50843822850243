import React, { Component } from 'react';
import { Radio } from 'uxi/Input';
import { Control } from 'react-redux-form';
import styled from 'styled-components';
import { compose } from 'recompose';
import { TableRow, TableRowColumn } from '@cluedin/list-pattern';
import { withIntegrationSourceFromProperty } from '../../../integration/components/hocs/withIntegrationSourceFromProperty';
import { getSemanticColor } from '../../../core/helpers/colors';
import withEntityMerge from '../Hocs/withEntityMerge';
import { encodeRRFKey } from './EntityComparePropertiesResolver';

// const fiftyPCInHex = '80';
const twentyPCInHex = '33';

const ValueWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: calc(100% - (24px + 6px));
  white-space: initial;
  ${({ noWhiteSpace }) => (noWhiteSpace ? 'word-break: break-all' : '')};
`;
/* eslint-disable indent */
const Hover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  label {
    padding: 0
    color: ${({ isConflicted }) =>
      isConflicted ? getSemanticColor(0.9, '70%', undefined, 0.9) : 'inherit'}
  }
  svg {
    --padding-y: 12px;
    padding: var(--padding-y, 12px) 8px;
    height: calc(100% - calc(2 * var(--padding-y, 12px))) !important;
    background-color: ${({ isConflicted }) =>
      getSemanticColor(isConflicted ? 0.9 : 0.1, '70%', undefined, 0.2)};
    color: grey;
    fill: grey;
  }

  label,
  *[data-ripple-wrapper],
  *[data-ripple-main]
                        { /* overwritte bad RAdio style casscade */
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &:hover {
    background: #dadada;
    background: hsla(180, 77%, 37%, 0.5);
    background: ${({ theme: { palette } }) =>
      `${palette.accent.main}${twentyPCInHex}`}
    border-radius: 3px;
  }
`;
/* eslint-enable indent */

const styles = {
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'baseline',
    borderBottom: '1px solid #cecece',
    paddingBottom: '16px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px',
  },
  header: {
    minWidth: '180px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  label: {
    marginTop: '4px',
    display: 'flex',
  },
  radioItemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  entityName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  radioControlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  source: {
    fontWeight: 200,
    color: '#646464',
  },
};

/* eslint-disable react/prefer-stateless-function */
class EntityComparePropertiesResolverRow extends Component {
  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
    this.renderItemControl = this.renderItemControl.bind(this);
  }

  renderItemControl(conflictedPropValue, theKey, conflictedProp) {
    const { propsDelta } = this.props;
    const { value /* , entityName */, entityId, key } = conflictedPropValue;
    const model = `propsDelta.${encodeRRFKey(key)}`;
    const defaultValue = conflictedPropValue.isOnlyObviousChoice
      ? conflictedPropValue.entityId
      : undefined;
    const currentlySelectedValueForKey = propsDelta[encodeRRFKey(key)]
      ? propsDelta[encodeRRFKey(key)].value
      : undefined;

    const { isConflictFree } = conflictedProp;

    const isTheSelectedOne = currentlySelectedValueForKey === entityId;

    const displayValue = value === '' ? '–EMPTY STRING–' : value;
    const hasWhiteSpace = displayValue.match(/\s/gi);

    return (
      <TableRowColumn>
        <div
          style={{
            ...styles.radioControlsWrapper,
            // eslint-disable-next-line no-nested-ternary
            ...(currentlySelectedValueForKey && isTheSelectedOne
              ? {}
              : currentlySelectedValueForKey
              ? { filter: 'opacity(.5) grayscale(100%)' }
              : {}),
          }}
        >
          <Control.radio
            style={{
              ...styles.radioControlsWrapper,
              // eslint-disable-next-line no-nested-ternary
            }}
            defaultValue={defaultValue}
            id={`${entityId}-${key}`}
            name={encodeRRFKey(key)}
            model={model}
            value={entityId}
            component={(props) => (
              <Hover
                isConflicted={!isConflictFree}
                isConflictFree={isConflictFree}
              >
                <Radio
                  style={{ ...styles.radioControlsWrapper }}
                  {...props}
                  wrapperStyle={{
                    ...styles.radioControlsWrapper,
                  }}
                  label={
                    <ValueWrapper noWhiteSpace={!hasWhiteSpace}>
                      {displayValue}
                    </ValueWrapper>
                  }
                />
              </Hover>
            )}
          />
        </div>
      </TableRowColumn>
    );
  }

  renderItem(conflictedProp) {
    const { ids } = this.props;
    const { values, key } = conflictedProp;
    const parsedIds = ids.split(',');
    const sortedValues = values.sort((a, b) => {
      if (a.entityId === parsedIds[0]) {
        return -1;
      }
      if (b.entityId === parsedIds[0]) {
        return +1;
      }
      return 0;
    });
    return sortedValues.map((x) =>
      this.renderItemControl(x, key, conflictedProp),
    );
  }

  render() {
    const {
      conflictedProp,
      selectedEntities,
      // isLast,
      // source,
    } = this.props;

    // let displayNameOrKey = conflictedProp.key;
    const theKey = conflictedProp.key;
    let theKeyDisplayName = theKey;

    if (
      conflictedProp &&
      conflictedProp.schema &&
      conflictedProp.schema.propertyInSchema &&
      conflictedProp.schema.propertyInSchema.DisplayName
    ) {
      theKeyDisplayName = conflictedProp.schema.propertyInSchema.DisplayName;
    }

    return (
      <TableRow>
        <TableRowColumn>
          <span
            style={{
              whiteSpace: 'normal',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <strong>{theKeyDisplayName}</strong>
            <small style={{ color: 'grey' }}>{theKey}</small>
          </span>
        </TableRowColumn>
        {selectedEntities.length && this.renderItem(conflictedProp)}
      </TableRow>
    );
    /*
    return (
      <div style={{ ...styles.wrapper, ...(isLast ? { border: 'none' } : {}) }}>
        <div style={styles.headerWrapper}>
          <h4 style={styles.header}>
            {displayNameOrKey}
          </h4>
            &nbsp;&nbsp;
          {sourceImageUrl && (
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Img src={sourceImageUrl} width={20} />
            </div>
          )}
            &nbsp;
          <small style={styles.source}>{source.Name}</small>
        </div>
        <div style={{ width: '100%' }}>
          {selectedEntities.length && this.renderItem(conflictedProp) }
        </div>
      </div>
    );
    */
  }
}

EntityComparePropertiesResolverRow.defaultProps = {
  source: 'CluedIn',
};

// export default withIntegrationSourceFromProperty(
//   EntityComparePropertiesResolverRow
// );

export default compose(
  withIntegrationSourceFromProperty,
  withEntityMerge,
)(EntityComparePropertiesResolverRow);
