import { FC, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { createNodeWithCorrectLabels } from './nodeFactory';
import { createNewEdges } from './edgeFactory';
import { drawD3ForceGraph } from './drawD3ForceGraph';
import {
  GraphNetworkNode,
  GraphNetworkEdge,
  UpdatedGraphNetworkNode,
  UpdatedGraphNetworkEdge,
} from '../types';

type D3EntityRelationsGraphProps = {
  nodes: GraphNetworkNode[];
  edges: GraphNetworkEdge[];
  rootEntityId: string;
  onNodeClick: (node: UpdatedGraphNetworkNode) => void;
  onGroupedRelationsClick: (groupedNode: UpdatedGraphNetworkNode) => void;
  onClickExpandGroupRelationsNode: () => void;
  onClickExpandEntityNode: () => void;
  onEdgeClick: (edge: UpdatedGraphNetworkEdge) => void;
};

export const D3EntityRelationsGraph: FC<D3EntityRelationsGraphProps> = ({
  nodes,
  edges,
  rootEntityId,
  onNodeClick,
  onGroupedRelationsClick,
  onClickExpandGroupRelationsNode,
  onClickExpandEntityNode,
  onEdgeClick,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const { formatMessage } = useIntl();

  const groupedRelationsLabel = formatMessage({
    id: 'module-entityRelationsGraphV2-groupedRelations',
  });
  const updatedNodes = createNodeWithCorrectLabels(
    nodes,
    groupedRelationsLabel,
  ) as UpdatedGraphNetworkNode[];
  const updatedEdges = createNewEdges(
    edges,
    updatedNodes,
  ) as UpdatedGraphNetworkEdge[];

  useEffect(() => {
    const svgParent = svgRef?.current?.parentNode as HTMLElement | null;

    const resetGraph = drawD3ForceGraph({
      nodes: updatedNodes,
      edges: updatedEdges,
      svgDOMNode: svgRef?.current,
      offsetWidth: svgParent?.offsetWidth ?? 1250,
      offsetHeight: svgParent?.offsetHeight ?? 500,
      rootEntityId,
      labels: {
        groupedRelationsCountLabel: formatMessage({
          id: 'module-entityRelationsGraphV2-relationsCount',
        }),
        dateCreatedLabel: formatMessage({
          id: 'module-entityRelationsGraphV2-dateCreated',
        }),
        noNameLabel: formatMessage({
          id: 'module-entityRelationsGraphV2-noName',
        }),
      },
      handlers: {
        onNodeClick,
        onGroupedRelationsClick,
        onClickExpandGroupRelationsNode,
        onClickExpandEntityNode,
        onEdgeClick,
      },
    });

    return () => {
      resetGraph();
    };
  }, [nodes, edges]);

  return <svg ref={svgRef} />;
};
