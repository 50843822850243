import React from 'react';
import { Thumbdown } from 'uxi/Icons';

const ErrorMessage = ({ children }) => {
  return (
    <span className="__test_errorMessage" style={{ color: '#ef5858' }}>
      {children}
      <Thumbdown size={12} color="#ef5858" style={{ marginLeft: '8px' }} />
    </span>
  );
};

export default ErrorMessage;
