import React from 'react';
import { escapeRegex } from '../../../core/helpers/string';

const HighlightText = ({ text = '', highlight, style = {} }) => {
  // Split on higlight term and include term into parts, ignore case
  if (!highlight) {
    return <span style={style}>{text}</span>;
  }
  const parts = text.split(new RegExp(`(${escapeRegex(highlight)})`, 'gi'));
  return (
    <span style={style}>
      {parts.map((part, i) => {
        if (highlight && part.toLowerCase() === highlight.toLowerCase()) {
          return <mark key={i}>{part}</mark>;
        }
        return <span>{part}</span>;
      })}
    </span>
  );
};

export default HighlightText;
