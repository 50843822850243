import EntityLinkFromEntity from '../../../composites/EntityLinkFromEntity';
import { getEntityUrl } from '../../../../../wms/selectors';
import { useGoldenRecordList } from '../../GoldenRecordListContext';
import { useStaticGoldenRecordList } from '../../StaticGoldenRecordListContext';

const NameComponent = (entity) => {
  const { entityTypesConfigurations } = entity?.useStaticContext
    ? useStaticGoldenRecordList()
    : useGoldenRecordList();

  const entityUrl = getEntityUrl(
    entity.id,
    entity.entityType,
    entityTypesConfigurations,
  );

  return (
    <EntityLinkFromEntity
      entityUrl={entityUrl}
      data-test={`golden-record-list-result-item-${entity.name || entity.id}`}
    >
      {entity.name || entity.id}
    </EntityLinkFromEntity>
  );
};

export default NameComponent;
