import { authRequest } from '../core/helpers/request';

export const fetchAllUserInvitations = () =>
  authRequest('GET', 'api/user/invites');

export const resendInvite = (id) => authRequest('PUT', `api/user/invite/${id}`);

export const removeInvite = (id) =>
  authRequest('DELETE', `api/user/invite/${id}`);

export default {
  fetchAllUserInvitations,
  resendInvite,
};
