import React from 'react';
import { useTheme } from '@cluedin/theme';
import EntityLinkContainer from '../../../wms/components/containers/EntityLinkContainer';

const InsightLinkRouterLinkStyle = {
  link: {
    textDecoration: 'none',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

const InsightLinkRouterLink = ({ entity, children }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const id = entity.EntityId || entity.Id;
  const linkStyle = InsightLinkRouterLinkStyle.link;

  return (
    <EntityLinkContainer
      style={{
        ...linkStyle,
        color: themePrimary,
      }}
      id={id}
      entityType={entity.EntityType}
    >
      {children}
    </EntityLinkContainer>
  );
};

export default InsightLinkRouterLink;
