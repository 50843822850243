import { apiRequest } from '../core/helpers/request';

const takeMesh = 20;

export const getMeshes = ({ providerId, pageNumber = 0 }) => {
  const skip = pageNumber * takeMesh;
  // &providerDefinitionId=91d9395f-7512-4345-a48c-5c19861ff90e
  const providerQuery = providerId ? `&providerDefinitionId=${providerId}` : '';

  return apiRequest(
    'GET',
    `api/meshcenter?take=${takeMesh}&skip=${skip}&ascending=false${providerQuery}`,
  );
};

export default {
  getMeshes,
};
