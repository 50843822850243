import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { appendModule } from '../../../actions';

const withBreadcrumb = (Comp, m) => {
  const enhancedComp = compose(
    lifecycle({
      componentWillUnmount() {
        const { appendCrumb } = this.props;
        appendCrumb(null);
      },
      componentDidMount() {
        const { appendCrumb } = this.props;
        appendCrumb(m);
      },
    }),
  )(Comp);

  const mapToStateProps = () => ({});

  const mapDispatchToProps = (dispatch) => ({
    appendCrumb(mod) {
      dispatch(appendModule(mod));
    },
  });

  return connect(mapToStateProps, mapDispatchToProps)(enhancedComp);
};

export default withBreadcrumb;
