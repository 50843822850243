/* eslint-disable */
import type from './ises/type';
import emptyModule from './ises/empty';
import nullOrUndefined from './ises/nullorundefined';

export const is = {
  a: {},
  an: {},
  not: {
    a: {},
    an: {},
  },
};

const ises = {
  arguments: ['arguments', type('arguments')],
  array: ['array', type('array')],
  boolean: ['boolean', type('boolean')],
  date: ['date', type('date')],
  function: ['function', 'func', 'fn', type('function')],
  null: ['null', type('null')],
  number: ['number', 'integer', 'int', type('number')],
  object: ['object', type('object')],
  regexp: ['regexp', type('regexp')],
  string: ['string', type('string')],
  undefined: ['undefined', type('undefined')],
  empty: ['empty', emptyModule],
  nullorundefined: ['nullOrUndefined', nullOrUndefined],
};

Object.keys(ises).forEach((key) => {
  let methods = ises[key].slice(0, ises[key].length - 1),
    fn = ises[key][ises[key].length - 1];

  methods.forEach((methodKey) => {
    is[methodKey] = is.a[methodKey] = is.an[methodKey] = fn;
    is.not[methodKey] =
      is.not.a[methodKey] =
      is.not.an[methodKey] =
        function () {
          return !fn.apply(this, arguments);
        };
  });
});

export const hasWhiteSpace = (s) => s.indexOf(' ') >= 0;

export const isEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isUrl = (str) => {
  const regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(str);
};

export const isValidFileName = (fileName) => {
  const ext = fileName.split('.').pop();
  return (
    ext.toLowerCase() === 'png' ||
    ext.toLowerCase() === 'jpg' ||
    ext.toLowerCase() === 'jpeg' ||
    ext.toLowerCase() === 'gif'
  );
};

export const isValidOrgName = (orgName) => {
  if (hasWhiteSpace(orgName)) {
    return false;
  }

  if (orgName.length < 3) {
    return false;
  }

  if (orgName.length > 25) {
    return false;
  }

  return true;
};

export const isValidEmailDomain = (domain) => {
  const emailRegex =
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

  return emailRegex.exec(domain);
};

export const isValidUrl = (url) => {
  const urlregex =
    /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;

  return urlregex.test(url);
};

export const validateClientId = (url) =>
  !(
    url === '' ||
    url.match(/[^A-Za-z0-9]/) ||
    url.length < 2 ||
    url.length > 30
  );

export const validatePassword = (pwd) =>
  !(pwd === '' || pwd.length < 8 || pwd.length > 25);

export const validateUserName = (username) =>
  !(
    username === '' ||
    username.match(/[^A-Za-z0-9]/) ||
    username.length < 2 ||
    username.length > 25
  );

export const isAlphaNumeric = (str) => {
  const patternName = /^[a-zA-Z 0-9 \- \( \) \_ \.]*$/;
  return patternName.test(str);
};

export const isNumeric = (str) => {
  const patternName = /^[0-9]*$/;
  return patternName.test(str);
};

export const isEmpty = (src) => {
  const patternName = /^[\s]*$/;
  return !!src?.match(patternName);
};

export const isLongerThen = (src, length = 256) => {
  return src?.length > length;
};

export const getNumeric = (str) => str.replace(/\D/g, '');

export default {
  hasWhiteSpace,
  is,
  isValidUrl,
  isEmail,
  isUrl,
  isValidFileName,
  isValidOrgName,
  isValidEmailDomain,
  validateClientId,
  validateUserName,
  validatePassword,
  isAlphaNumeric,
  isNumeric,
  getNumeric,
  isEmpty,
};
