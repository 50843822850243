import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import cogoToast from 'cogo-toast';

const MARK_ENTITY_AS_SENSITIVE = gql`
  mutation markAsSensitive($entityId: ID!) {
    virtualization {
      markAsSensitive(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const MARK_ENTITY_AS_UNSENSITIVE = gql`
  mutation markAsUnSensitive($entityId: ID!) {
    virtualization {
      markAsUnSensitive(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const MINIMYZE_USER_DATA = gql`
  mutation minimizeUserData($entityId: ID!) {
    virtualization {
      minimizeUserData(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const ANONYMIZE = gql`
  mutation anonymize($entityId: ID!) {
    virtualization {
      anonymize(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const ADD_FROM_PROCESSING = gql`
  mutation addFromProcessing($entityId: ID!) {
    virtualization {
      addFromProcessing(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const REMOVE_FROM_PROCESSING = gql`
  mutation removeFromProcessing($entityId: ID!) {
    virtualization {
      removeFromProcessing(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const EXTERNAL_SEARCH = gql`
  mutation externalSearch($entityId: ID!) {
    virtualization {
      externalSearch(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const REPROCESS = gql`
  mutation reProcess($entityId: ID!) {
    virtualization {
      reProcess(entityId: $entityId) {
        message
        success
      }
    }
  }
`;

const notificationHandler = (data, error, successMessage, errorMessage) => {
  if (data && data.success && !error) {
    cogoToast.success(successMessage, { position: 'top-center', hideAfter: 5 });
  }
  if ((data && !data.success) || error) {
    cogoToast.error(error && error.message ? error.message : errorMessage, {
      position: 'top-center',
      hideAfter: 5,
    });
  }
};

export const useMarkEntityAsSensitive = (entityId) => {
  const [markEntityAsSensitive, { data, loading, error }] = useMutation(
    MARK_ENTITY_AS_SENSITIVE,
  );

  notificationHandler(
    data?.virtualization?.markAsSensitive,
    error,
    `A request has been sent to mark the entity as sensitive.`,
    `There was an error to mark the entity as un-sensitive`,
  );

  return [
    () => {
      markEntityAsSensitive({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useMarkEntityAsUnSensitive = (entityId) => {
  const [markEntityAsUnSensitive, { data, loading, error }] = useMutation(
    MARK_ENTITY_AS_UNSENSITIVE,
  );

  notificationHandler(
    data?.virtualization?.markAsUnSensitive,
    error,
    `A request has been sent to mark the entity as un-sensitive.`,
    `There was an error to mark the entity as un-sensitive`,
  );

  return [
    () => {
      markEntityAsUnSensitive({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useMinimizeUserData = (entityId) => {
  const [minimizeUserData, { data, loading, error }] =
    useMutation(MINIMYZE_USER_DATA);

  notificationHandler(
    data?.virtualization?.minimizeUserData,
    error,
    `A request has been sent to minimze this entity.`,
    `There was an error to minimize this entity`,
  );

  return [
    () => {
      minimizeUserData({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useAnonymize = (entityId) => {
  const [anonymize, { data, loading, error }] = useMutation(ANONYMIZE);

  if (data && !error)
    cogoToast.success(`A request has been sent to anomynize this entity.`, {
      position: 'top-center',
      hideAfter: 5,
    });
  if (!data && error)
    cogoToast.error(
      error.message
        ? error.message
        : `There was an error to anomynize this entity`,
      { position: 'top-center', hideAfter: 5 },
    );

  notificationHandler(
    data?.virtualization?.anonymize,
    error,
    `A request has been sent to anomynize this entity.`,
    `There was an error to anomynize this entity`,
  );

  return [
    () => {
      anonymize({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useAddFromProcessing = (entityId) => {
  const [addFromProcessing, { data, loading, error }] =
    useMutation(ADD_FROM_PROCESSING);

  notificationHandler(
    data?.virtualization?.addFromProcessing,
    error,
    `A request has been sent to process this entity.`,
    `There was an error to process this entity`,
  );

  return [
    () => {
      addFromProcessing({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useRemoveFromProcessing = (entityId) => {
  const [removeFromProcessing, { data, loading, error }] = useMutation(
    REMOVE_FROM_PROCESSING,
  );

  notificationHandler(
    data?.virtualization?.removeFromProcessing,
    error,
    `A request has been sent to remove from processing.`,
    `There was an error to remove this entity from processing`,
  );

  return [
    () => {
      removeFromProcessing({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useExternalSearch = (entityId) => {
  const [externalSearch, { data, loading, error }] =
    useMutation(EXTERNAL_SEARCH);

  notificationHandler(
    data?.virtualization?.externalSearch,
    error,
    `A request to search externally has been made.`,
    `There was an error to search externally this entity`,
  );

  return [
    () => {
      externalSearch({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};

export const useReprocess = (entityId) => {
  const [reProcess, { data, loading, error }] = useMutation(REPROCESS);

  notificationHandler(
    data?.virtualization?.reProcess,
    error,
    `A request to re-process this entity has been made.`,
    `There was an error to re-process this entity`,
  );

  return [
    () => {
      reProcess({
        variables: {
          entityId,
        },
      });
    },
    { data, loading, error },
  ];
};
