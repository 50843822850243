import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { ButtonLink } from '@cluedin/atoms';
import { FormattedMessage } from '@cluedin/locale';
import FilterGroupTitle from './FilterGroupTitle';
import FilterGroupInput from './FilterGroupInput';
import FilterGroupResult, { filterOutSelected } from './FilterGroupResult';
import FilterGroupWrapper from './FilterGroupWrapper';
import SelectedFilters from './SelectedFilters';
import FilterEditIndicator from './FilterEditIndicator';

export const className = 'cld-MainSearch-FilterGroup';

class FilterGroup extends Component {
  static defaultProps = {
    onFilterQueryChange: () => {},
    filters: [],
  };

  constructor(props) {
    super(props);

    this.onInputValueChange = this.onInputValueChange.bind(this);
    this.handleAddFilter = this.handleAddFilter.bind(this);
  }

  componentDidMount() {
    const { onFilterGroupFocusIn, filterType } = this.props;
    const isEntityType = filterType.value.toLowerCase() === 'entitytype';

    // quick hack to focus first item and unfold first filterGroup
    if (isEntityType && onFilterGroupFocusIn) {
      onFilterGroupFocusIn();
    }
  }

  onInputValueChange(e, value) {
    const { onFilterQueryChange } = this.props;

    onFilterQueryChange(e, value);
  }

  handleAddFilter(filter) {
    const { onAddFilter, onFilterQueryChange } = this.props;
    onFilterQueryChange({}, '');
    onAddFilter(filter);
  }

  handleClickOutside() {
    const { onClickOutsideFilterGroup } = this.props;

    if (onClickOutsideFilterGroup) {
      onClickOutsideFilterGroup();
    }
  }

  render() {
    const {
      currentFilterQuery,
      title,
      filters,
      isRemoteFiltering,
      filterType,
      selectedFilters,
      isFetching,
      isFetchingMainSearch,
      currentEntityTypePoolIdentifier,
      onEntityTypePoolChange,
      onRemoveFilter,
      onFilterGroupFocusIn,
      totalFiltersCount,
    } = this.props;

    const totalSelectedFiltersCount = selectedFilters.length;

    const filterOutSelectedCount = filterOutSelected(
      filters,
      selectedFilters,
    ).length;

    const isEntityType = filterType.value.toLowerCase() === 'entitytype';

    const metaIsHidden = !!(currentEntityTypePoolIdentifier && isEntityType);

    const filterMetaProps = {
      filteredCount: filterOutSelectedCount,
      isRemoteFiltering,
      selectedCount: totalSelectedFiltersCount,
      totalCount: totalFiltersCount,
      isFetching,
      show: !metaIsHidden,
      currentEntityTypePoolIdentifier,
      currentFilterQuery,
    };

    return (
      <FilterGroupWrapper
        className={className}
        currentFilterQuery={currentFilterQuery}
        onClick={onFilterGroupFocusIn}
        // onFocusIn={(e) => {
        //   console.log('event', e);
        //   return onFilterGroupFocusIn(e);
        // }}
      >
        <FilterGroupTitle {...filterMetaProps} title={title} />
        <SelectedFilters
          onFocusIn={onFilterGroupFocusIn}
          filters={selectedFilters}
          onRemoveFilter={onRemoveFilter}
        />
        <FilterEditIndicator
          {...filterMetaProps}
          style={{
            width: '100%',
            color: 'lightgrey',
            fontSize: '12px',
            cursor: 'pointer',
            alignItems: 'flex-start',
            paddingTop: '16px',
          }}
        >
          <ButtonLink
            text={
              <FormattedMessage
                id="module-search-editFilter"
                values={{ title }}
              />
            }
          />
        </FilterEditIndicator>
        <FilterGroupInput
          onFocusIn={onFilterGroupFocusIn}
          onFilterQueryChange={this.onInputValueChange}
          isFetching={isFetching}
          onRemoveFilter={onRemoveFilter}
          selectedFilters={selectedFilters}
          currentFilterQuery={currentFilterQuery}
          filterType={filterType}
          currentEntityTypePoolIdentifier={currentEntityTypePoolIdentifier}
        />
        <FilterGroupResult
          onFocusIn={onFilterGroupFocusIn}
          onAddFilter={this.handleAddFilter}
          filters={filters}
          filterType={filterType}
          selectedFilters={selectedFilters}
          isFetching={isFetching}
          isFetchingMainSearch={isFetchingMainSearch}
          currentFilterQuery={currentFilterQuery}
          currentEntityTypePoolIdentifier={currentEntityTypePoolIdentifier}
          onEntityTypePoolChange={onEntityTypePoolChange}
        />
      </FilterGroupWrapper>
    );
  }
}

export default enhanceWithClickOutside(FilterGroup);
