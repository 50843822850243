"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  .list-content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    .list-content-list {\n      flex-direction: column;\n      overscrol-x: hide;\n      height: ", ";\n      background: #fff;\n      display: flex;\n      overflow-y: auto;\n      flex: ", ";\n    }\n  }\n  height: ", ";\n"])), function (_ref) {
  var offsetTop = _ref.offsetTop,
    hasActionEnalbed = _ref.hasActionEnalbed;
  //58 px for the list header + 65px for the footer + offsetTop
  var withoutOffstep = 65 + 58;
  var withAction = hasActionEnalbed ? 43 : 0;
  var listH = (offsetTop || 0) + withoutOffstep + withAction;
  return offsetTop ? "calc(100vh - " + listH + "px)" : "auto";
}, function (_ref2) {
  var offsetTop = _ref2.offsetTop;
  return offsetTop ? 'auto' : '';
}, function (_ref3) {
  var offsetTop = _ref3.offsetTop;
  return offsetTop ? "calc(100vh - " + offsetTop + "px)" : 'auto';
});
exports.default = _default;