import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    visbility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const EntityRelationsTreeWrapper = styled.div`
  animation: ${fadeInAnimation} 200ms ease-in-out;
  background: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
