"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = function _default(element, className) {
  do {
    if (element.classList && element.classList.contains(className)) {
      return true;
    }
    element = element.parentNode;
  } while (element);
  return false;
};
exports.default = _default;