import React, { useEffect } from 'react';
import get from 'lodash/get';

import { useGetDataPartNodeInfo } from '../../../hooks/useGetDataPartNodeInfo';
import RecordPanelContainer from '../RecordPanelContainer/RecordPanelContainer';

const RecordPanelAPIContainer = ({
  entityId,
  currentNode,
  nodeDataType,
  setNodeDataType,
}) => {
  const dataPartId = get(currentNode, 'dataPartId');

  const [
    getDataPartNodeInfo,
    {
      data: recordNodeData,
      loading: recordNodeLoading,
      error: recordNodeError,
    },
  ] = useGetDataPartNodeInfo();

  useEffect(() => {
    if (entityId && dataPartId) {
      getDataPartNodeInfo(entityId, dataPartId);
    }
  }, [entityId, dataPartId]);

  const recordPanelData = {
    currentNode: currentNode || {},
    dataPartNodeInfo: recordNodeData || {},
  };

  return (
    <>
      {recordPanelData && (
        <RecordPanelContainer
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          recordPanelData={recordPanelData}
        />
      )}
    </>
  );
};

export default RecordPanelAPIContainer;
