import React, { createContext, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { Arrowleft } from 'uxi/Icons';
import styled from 'styled-components';

export const BackButtonContext = createContext([]);

export const useBackButton = () => {
  const [, setContext] = useContext(BackButtonContext);

  useEffect(() => {
    setContext({
      showBack: true,
    });

    return () => {
      setContext({
        showBack: false,
      });
    };
  }, []);
};

const PillarBackButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding-right: 12px;
  border-right: 1px solid #d4dad1;
  color: ${({ neutralPrimary }) => neutralPrimary};
  font-size: 18px;

  &:hover {
    color: ${({ themePrimary }) => themePrimary};
  }
`;

const PillarBackButton = ({ style, history }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const neutralPrimary = theme.palette.neutralPrimary;

  const [backButtonContext] = useContext(BackButtonContext);

  return (
    <>
      {backButtonContext.showBack && (
        <PillarBackButtonWrapper
          neutralPrimary={neutralPrimary}
          themePrimary={themePrimary}
          onClick={() => {
            history.goBack();
          }}
          style={style}
        >
          <div style={{ paddingTop: '2px' }}>
            <Arrowleft size={12} />
          </div>
          <span style={{ marginLeft: '6px' }}>
            <FormattedMessage id="back" />
          </span>
        </PillarBackButtonWrapper>
      )}
    </>
  );
};

export default withRouter(PillarBackButton);
