import { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Select } from '@cluedin/form';
import { ProviderIconById } from '../../../../../shared/components/ProviderIconById';
import { useQueryEnumerableValuesByPropertyAndObjectTypeId } from '../../../hooks/useQueryEnumerableValuesByPropertyAndObjectTypeId';
import IntegrationIconContainer from '../../../../integration/components/containers/IntegrationIconContainer';
import { optionLabelsWithCountFormatter } from '../../../utils/optionLabelsWithCountFormatter';
import { url } from '../../../../../config';

const getParentWidth = (parentId) =>
  document.querySelector(`[data-rule-item-parent="${parentId}"]`)?.clientWidth;

const getProviderDefinitionIdsSelectorOptions = (items, parentId) => {
  const parentWidth = getParentWidth(parentId);

  return items.map((i) => {
    const fullPathIcon = `${url.api}${i?.Icon}`;
    return {
      icon: i?.Icon ? (
        <img src={fullPathIcon} width={16} height={16} />
      ) : (
        <ProviderIconById id={i.Value} />
      ),
      value: i.Value,
      label: i.Name,
      count: i.Count,
      width: parentWidth ? parentWidth : undefined,
    };
  });
};

const getProviderSelectorOptions = (items, parentId) => {
  const parentWidth = getParentWidth(parentId);
  return items.map((i) => ({
    icon: <IntegrationIconContainer integrationName={i.Value} iconSize="xs" />,
    value: i.Value,
    label: i.Name,
    count: i.Count,
    width: parentWidth ? parentWidth : undefined,
  }));
};

const getSimpleOptions = (items, parentId) => {
  const parentWidth = getParentWidth(parentId);
  return items.map((i) => ({
    value: i.Value || i.value || '',
    label: i.Name || i.name || '',
    width: parentWidth ? parentWidth : undefined,
  }));
};

const optionsAdaptersMap = {
  ProviderDefinitionIds: getProviderDefinitionIdsSelectorOptions,
  Tags: getSimpleOptions,
  TagsName: getSimpleOptions,
  LastChangedByName: getSimpleOptions,
  LastChangedBy: getSimpleOptions,
  AuthorNames: getSimpleOptions,
  Aliases: getSimpleOptions,
  Providers: getProviderSelectorOptions,
};

export const ValuesKeysForRuleItemEnumerableSelector = Object.keys(
  optionsAdaptersMap,
).map((i) => i?.toLowerCase());

const getOptionsByProperty = (property, items, parentId) => {
  return optionsAdaptersMap?.[property]?.(items, parentId);
};

export const RuleItemEnumerableSelector = memo(
  ({
    value,
    hasError,
    onChange,
    property,
    objectTypeId,
    placeholder,
    isMulti = false,
    isCreatable = true,
    parentId,
  }) => {
    const intl = useIntl();

    const [searchName, setSearchName] = useState('');

    const [items, { loading }] =
      useQueryEnumerableValuesByPropertyAndObjectTypeId({
        property,
        objectTypeId,
      });

    const options =
      !loading && items
        ? getOptionsByProperty(
            property,
            items?.filter?.((i) => i?.Name?.includes?.(searchName)),
            parentId,
          )
        : [];

    const handleChange = (v) => {
      onChange(isMulti ? v : v?.value);
    };

    const formatCreateLabel = (v) => {
      return `${intl.formatMessage({
        id: 'module-rule-use-option-prefix',
      })} "${v}"`;
    };

    const adaptedValue = useMemo(() => {
      if (!value) {
        return undefined;
      }

      if (property === 'ProviderDefinitionIds' && isMulti) {
        return !loading && items
          ? getProviderDefinitionIdsSelectorOptions(
              items?.filter((i) => value.includes(i.Value)),
              parentId,
            )
          : [];
      }

      if (property === 'Providers' && isMulti) {
        return !loading && items
          ? getProviderSelectorOptions(
              items?.filter((i) => value.includes(i.Value)),
              parentId,
            )
          : [];
      }

      if (items?.find((i) => i.Value === value)) {
        return value;
      }

      return {
        label: value,
        value,
      };
    }, [items, value]);

    return (
      <Select
        isCreatable={isCreatable}
        isClearable
        isSearchable
        isMulti={isMulti}
        placeholder={placeholder}
        value={adaptedValue}
        isLoading={loading}
        options={options}
        hasError={hasError}
        total={items?.length}
        onChange={handleChange}
        onInputChange={setSearchName}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={optionLabelsWithCountFormatter}
      />
    );
  },
);
