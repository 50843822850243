/*eslint-disable */
var __cluedIn = window.__cluedIn || {};
import 'whatwg-fetch';
/*eslint-disable */
import core from './reducers/core';
import entity from './reducers/entity';
import entities from './reducers/entities';

import notification from './modules/notifications/reducer';

import wms from './modules/wms/reducer';
import GDPR from './modules/GDPR/reducer';
import user from './modules/user/reducer';
import integration from './modules/integration/reducer';
import configuration from './modules/configuration/reducer';
import quickView from './modules/quickview/reducer';
import org from './modules/organization/reducer';
import entityModule from './modules/entity/reducer';
import entityMerge from './modules/entityMerge/reducer';
import entityActions from './modules/entityActions/reducer';
import entityRelation from './modules/entityRelation/reducer';
import { initialState as entityMergeInitialState } from './modules/entityMerge/reducer';
import frame from './modules/frame/reducer';
import consent from './modules/consent/reducer';
import error from './modules/error/reducer';
import help from './modules/help/reducer';
import locale from './modules/locale/reducer';
import dataModeling from './modules/dataModeling/reducer';
import searchModule, {
  initialState as searchInitialState,
} from './modules/search/reducer';
import dataBreach from './modules/dataBreach/reducer';
import coreModule from './modules/core/reducer';
import retention from './modules/retention/reducer';
import auth from './modules/auth/reducer';
import sso from './modules/sso/reducer';
import signup from './modules/public/reducers/signup';
import { getSubdomain } from './modules/core/helpers/url';
import forgotPwd from './modules/public/reducers/forgotPwd';
import createOrgAndUser from './modules/public/reducers/createOrgAndUser';
import saveOrgEmail from './modules/public/reducers/saveOrgEmail';
import resetPwd from './modules/public/reducers/resetPwd';
import createUser from './modules/public/reducers/createUser';
import whiteLabel, {
  initialState as whiteLabelInitialState,
} from './modules/whiteLabel/reducer';
import stream from './modules/stream/reducer';
import publicModule from './modules/public/reducers/public';
import userInvitation from './modules/userInvitation/reducer';
import userModule from './modules/userModule/reducer';
import mesh from './modules/mesh/reducer';
import schema from './modules/schema/reducer';
import entityLists, {
  initialState as entityListsInitialState,
} from './modules/entityLists/reducer';
import { initialState as publicModuleInitialState } from './modules/public/reducers/public';

import { env, features, getCurrentToken, clientId } from './config';

import { getUsername, getClientIds } from './modules/core/helpers/storage';

const subdomain = getSubdomain();

export const initialState = {
  publicModule: publicModuleInitialState,
  core: {
    features: features || [
      'follow',
      'savedSearches',
      'users',
      'data-governance',
      'developer',
      'settings',
      'integrations',
      'users',
      'customEntityPage',
    ],
    token: getCurrentToken(),
    widgetConfiguration: null,
    allWidgets: __cluedIn,
    org: clientId,
    connectedData: [],
    unauthorized: false,
    isFetchingConnectedData: true,
    cluedinNotifications: [],
    env,
  },
  changePassword: {},
  auth: {
    isFetchingLogin: false,
    authInfo: null,
    clientId: subdomain === 'app' ? null : subdomain,
    isFetchingTeamDomain: null,
    teamDomainInfo:
      subdomain === 'app' ? null : { clientId: subdomain, isAvailable: true },
    invalidLogin: false,
    clientIds: getClientIds() || [],
    done: false,
    userName: getUsername(subdomain),
    redirecting: false,
    redirectingTeamDomain: false,
  },
  entityMerge: entityMergeInitialState,
  whiteLabel: { ...whiteLabelInitialState },
  searchModule: {
    ...searchInitialState,
  },
  entityLists: {
    ...entityListsInitialState,
  },
};

export default {
  publicModule,
  core,
  entity,
  wms,
  auth,
  error,
  notification,
  signup,
  createOrgAndUser,
  forgotPwd,
  resetPwd,
  entities,
  org,
  entityActions,
  entityRelation,
  sso,
  createUser,
  saveOrgEmail,
  whiteLabel,
  GDPR,
  user,
  integration,
  configuration,
  quickView,
  entityModule,
  entityMerge,
  frame,
  consent,
  help,
  locale,
  dataModeling,
  searchModule,
  dataBreach,
  coreModule,
  retention,
  stream,
  userInvitation,
  userModule,
  mesh,
  schema,
  entityLists,
};
