import { gql, useQuery } from '@apollo/client';
import { SavedSearch } from './useSavedSearch';

export const SAVED_SEARCHES = gql`
  query getSavedSearches($viewName: String, $page: Int) {
    administration {
      getSavedSearches(viewName: $viewName, page: $page) {
        total
        searches {
          filterUrl
          id
          name
          viewMode
          columnsConfig
          createdBy
          created
          shared
        }
      }
    }
  }
`;

export type GetSavedSearchesResponse = {
  administration: {
    getSavedSearches: {
      total: number;
      searches: SavedSearch[];
    };
  };
};

type Options = {
  page: number;
  viewName: string;
};

export const useQuerySavedSearches = ({ viewName, page }: Options) => {
  const fetchPolicy = 'network-only';

  const { data, loading, refetch } = useQuery<
    GetSavedSearchesResponse,
    Options
  >(SAVED_SEARCHES, {
    fetchPolicy,
    variables: {
      page,
      viewName,
    },
  });

  const savedSearchesRes = data?.administration?.getSavedSearches;

  return {
    refetch,
    loadingSavedSearches: loading,
    totalSavedSearches: savedSearchesRes?.total,
    savedSearches: savedSearchesRes?.searches || [],
  };
};
