import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Flex, SimpleGrid } from 'uxi/Layout';
import PersonListItem from './PersonListItem';
import PersonListItemLoading from './PersonListItemLoading';
import EntitySelectableListItemWrapper from '../../../../../../entityMerge/components/containers/EntitySelectableListItemWrapper';

const personListItemWidth = 245;
const personListItemHeight = 310;

const PersonList = ({ data = [], isFetchingMainSearch }) =>
  !isFetchingMainSearch && data.length === 0 ? (
    <Flex
      style={{ margin: '96px auto', textAlign: 'center', padding: '0 16px' }}
    >
      <FormattedMessage
        id="SearchContent.NoSearchResult"
        defaultMessage="No results found for the current query"
      />
    </Flex>
  ) : (
    <SimpleGrid
      itemWidth={personListItemWidth}
      itemHeight={personListItemHeight}
      gap={16}
      style={{ margin: '16px' }}
    >
      {isFetchingMainSearch
        ? [...Array(30)].map(() => <PersonListItemLoading />)
        : data.map((person) => (
            <EntitySelectableListItemWrapper
              mergeViewModel={{
                ...person,
                entityType: '/Person',
              }}
              wrapperStyle={{ borderRadius: '3px', overflow: 'hidden' }}
            >
              <PersonListItem person={person} />
            </EntitySelectableListItemWrapper>
          ))}
    </SimpleGrid>
  );

export default PersonList;
