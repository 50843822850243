import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import {
  shouldFetchEntity,
  shouldCleanUpSelected,
  shouldFetchHistory,
} from '../../../actions';
import {
  getIsLoading,
  getTitle,
  createEntityProperties,
} from '../../../selectors';
import NotFoundContainer from '../../../../error/components/containers/NotFoundContainer';
import AccessDeniedContainer from '../../../../error/components/containers/AccessDeniedContainer';
import SomethingOddContainer from '../../../../error/components/containers/SomethingOddContainer';
import { getEntityUrlFromState } from '../../../../wms/selectors';

import EntityMainPageWithTopology from '../../../../entityV2/pages/EntityMainPageWithTopology';

class EntityPageContainer extends Component {
  static propTypes = {
    entity: PropTypes.object,
  };

  componentWillMount() {
    const { entityId, type, dispatchAllActionRequiredByPage } = this.props;

    const { cleanUp } = this.props;

    cleanUp();
    dispatchAllActionRequiredByPage(entityId, type);
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line consistent-return
    const { dispatchAllActionRequiredByPage } = this.props;

    if (
      nextProps.entityId !== this.props.entityId ||
      nextProps.paramToListen !== this.props.paramToListen
    ) {
      const { cleanUp } = this.props;

      cleanUp();
      dispatchAllActionRequiredByPage(nextProps.entityId, nextProps.type);
    }
  }

  render() {
    const {
      widgetConfiguration,
      layoutConfiguration,
      entityId,
      entity,
      isLoading,
      isNotFound,
      isNoAccess,
      isSomethingOdd,
      errorText,
      status,
      match,
    } = this.props;

    if (isNotFound) {
      return (
        <NotFoundContainer
          explanation={
            <div>
              <div style={{ paddingBottom: '16px' }}>
                <FormattedHTMLMessage id="module-entity-notFoundReasons" />
              </div>
              <ul style={{ fontSize: '14px' }}>
                <li>
                  <FormattedHTMLMessage id="module-entity-notFoundReasonDelete" />
                </li>
                <li>
                  <FormattedHTMLMessage id="module-entity-notFoundReasonMerge" />
                </li>
                <li>
                  <FormattedHTMLMessage id="module-entity-notFoundSensitive" />
                </li>
              </ul>
            </div>
          }
          message={<FormattedMessage id="EntityPageContainerOops" />}
        />
      );
    }

    if (isNoAccess) {
      return (
        <AccessDeniedContainer
          feature={<FormattedMessage id="featureEntityPage" />}
        />
      );
    }

    if (isSomethingOdd) {
      return <SomethingOddContainer status={status} text={errorText} />;
    }

    return (
      <div className="cluedIn_container">
        {entity && entityId && entity?.data?.entityType && (
          <EntityMainPageWithTopology
            entity={entity}
            entityId={entityId}
            type={entity?.data?.entityType}
            isFetching={isLoading}
            widgetConfiguration={widgetConfiguration}
            layoutConfiguration={layoutConfiguration}
            rootUrl={match.url}
          />
        )}
      </div>
    );
  }
}

const mapToStateProps = (state, ownProps) => {
  const {
    core,
    entity: { schema },
    entityModule: { isFetchingEntity, currentEntity },
    error: { entityErrors },
    integration: { allIntegrations },
    wms,
  } = state;

  const {
    match,
    location,
    entityType,
    widgetConfiguration,
    layoutConfiguration,
  } = ownProps;
  const entityId = match.params.id;

  const path = location ? location.pathname : 'homescreen';

  const error = entityErrors[entityId];
  const errorStatus = error ? error.type : '';
  const errorText = error ? error.text : '';
  const status = error ? error.status : null;

  const isProps = path.indexOf(`${entityId}/props`) > -1;
  const isReview = path.indexOf(`${entityId}/review`) > -1;
  const isRelations = path.indexOf(`${entityId}/relations`) > -1;
  const isHistory = path.indexOf(`${entityId}/history`) > -1;
  const isExplainLog = path.indexOf(`${entityId}/explain-log`) > -1;
  const isPendingChanges = path.indexOf(`${entityId}/pending-changes`) > -1;
  const isEntityProperties = path.indexOf(`${entityId}/properties`) > -1;
  const isTopology = path.indexOf(`${entityId}/topology`) > -1;

  const { entityUrl } = getEntityUrlFromState(wms, entityId, entityType);

  return {
    isLayout:
      !isProps &&
      !isReview &&
      !isHistory &&
      !isRelations &&
      !isExplainLog &&
      !isPendingChanges &&
      !isEntityProperties &&
      !isTopology,
    isProps,
    isReview,
    isRelations,
    isHistory,
    isExplainLog,
    isPendingChanges,
    isEntityProperties,
    isTopology,
    entityId,
    widgetConfiguration,
    layoutConfiguration,
    type: entityType,
    isLoading: getIsLoading(state, ownProps),
    entity: currentEntity,
    isFetchingEntity,
    schema,
    org: core.org,
    title: getTitle(state, ownProps),
    isNotFound: errorStatus === 'notfound',
    isNoAccess: errorStatus === 'noaccess',
    isSomethingOdd: errorStatus === 'error',
    errorText,
    status,
    entityUrl,
    entityProperties:
      currentEntity && schema
        ? createEntityProperties(currentEntity, schema, allIntegrations)
        : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  shouldCleanUpSelected() {
    dispatch(shouldCleanUpSelected());
  },
  dispatchAllActionRequiredByPage(entityId) {
    // eslint-disable-line no-shadow
    if (entityId) {
      dispatch(shouldFetchEntity({ id: entityId, force: true }));
      dispatch(shouldFetchHistory(entityId));
    }
  },
  cleanUp() {
    dispatch(shouldCleanUpSelected());
  },
  navigate(url) {
    dispatch(push(url));
  },
});

EntityPageContainer.defaultProps = {
  entity: {},
};

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(EntityPageContainer);
