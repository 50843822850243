import React from 'react';
import DataGrid from 'uxi/DataGrid';
import { FormattedMessage } from '@cluedin/locale';
import { Delete } from 'uxi/Icons';
import TimeSpanComponent from './TimeSpanComponent';
import { Ellipisis } from '@cluedin/components';
import { List } from '@cluedin/list-pattern';

const RetentionList = ({ data, onDelete }) => (
  <div>
    <DataGrid
      data={data}
      selectable
      multiSelectable
      propertyKey="Id"
      batchActions={[
        {
          icon: <Delete />,
          label: (
            <FormattedMessage
              id="module-dataRetention-delete"
              defaultMessage="Delete"
            />
          ),
          clearSelection: true,
          onClick: (e, value) => {
            onDelete(value);
          },
        },
      ]}
      properties={[
        {
          property: 'Name',
          displayName: (
            <FormattedMessage
              id="module-dataRetention-name"
              defaultMessage="Name"
            />
          ),
          Component: Ellipisis,
        },
        {
          property: 'Description',
          displayName: (
            <FormattedMessage
              id="module-dataRetention-description"
              defaultMessage="Description"
            />
          ),
        },
        {
          property: 'Timespan',
          displayName: (
            <FormattedMessage
              id="module-dataRetention-timeToLive"
              defaultMessage="Time to live"
            />
          ),
          Component: TimeSpanComponent,
        },
        {
          property: 'Query',
          displayName: (
            <FormattedMessage
              id="module-dataRetention-query"
              defaultMessage="Query"
            />
          ),
        },
      ]}
    />
  </div>
);

export default RetentionList;
