import React, { useEffect } from 'react';

import { withEntityCodePanelWorkflow } from '../../../hocs/withEntityCodePanelWorkflow';

const EntityCodePanelContainer = ({
  entityId,
  openEntityCodePanelWorkflow,
  nodeDataType,
  setNodeDataType,
  entityCodePanelData,
  entityCodePanelLoading,
  entityCodePanelError,
  currentNode,
}) => {
  useEffect(() => {
    if (nodeDataType === 'EntityCode') {
      openEntityCodePanelWorkflow();
    }
  }, [nodeDataType]);

  return <span />;
};

export default withEntityCodePanelWorkflow(EntityCodePanelContainer);
