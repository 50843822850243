import styled from 'styled-components';

export const HistoryChangeSetSourceBox = styled.div`
  background: #ffffff;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  max-width: 100%;

  &:last-of-type {
    border-bottom: 1px solid #ececec;
  }
`;

export const HistoryChangeSetSourceWrapper = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 1px solid rgb(212, 218, 209);
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  padding: 14px 16px;
`;

export const HistoryChangeSetSourceHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  position: relative;
  width: 100%;

  & svg {
    margin-right: 10px;
  }
`;

export const HistoryChangeSetSourceHeadingWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
`;

export const HistoryChangeSetSourceBorderCircle = styled.div`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme?.palette?.themePrimary};
  border-radius: 80px;
  content: '';
  height: 6px;
  min-height: 6px;
  left: -25.5px;
  position: absolute;
  width: 6px;
  min-width: 6px;
`;

export const HistoryChangeSetSourceDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & strong,
  & strong span {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 2px;
  }

  & span {
    color: #666666;
    font-size: 14px;
  }
`;
