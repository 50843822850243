import BreachDashboardPage from './components/pages/BreachDashboardPage';
import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
// import DataBreachWrapperPage from './components/pages/DataBreachWrapperPage';
// import ProtectedFeatureHOC from '../core/components/Hocs/ProtectedFeatureHOC';
import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'governance.databreach': RACI_LEVELS.CONSULTANT,
    },
    component: OnlyClientIdSubDomain(BreachDashboardPage),
  },
  {
    path: '/all',
    claims: {
      'governance.databreach': RACI_LEVELS.CONSULTANT,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"AllDataBreachPage" */
          './components/pages/AllDataBreachPage'
        ),
      ),
    ),
  },
  {
    path: '/create',
    claims: {
      'governance.databreach': RACI_LEVELS.CONSULTANT,
    },
    component: OnlyClientIdSubDomain(
      makeLazy(() =>
        import(
          /* webpackChunkName:"CreateDataBreachPage" */
          './components/pages/CreateDataBreachPage'
        ),
      ),
    ),
  },
];
