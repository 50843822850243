import React, { useState } from 'react';
import { Checkbox, TextField } from '@cluedin/form';
import { FormattedMessage, injectIntl } from '@cluedin/locale';

import { escapeRegex } from '../../../core/helpers/string';
import HighlightText from '../../../core/components/composites/HighlightText';

const CoreVocabSelection = ({
  vocabs,
  onSelectionChange,
  selectedProperties,
  setProperties,
  intl,
}) => {
  const allChecked = selectedProperties.length === (vocabs || []).length;
  const [query, setQuery] = useState();

  const filteredVocab = query
    ? (vocabs || []).filter(
        (c) =>
          c.Key.match(new RegExp(escapeRegex(query), 'i')) ||
          (c.DisplayName || '').match(new RegExp(escapeRegex(query), 'i')),
      )
    : vocabs;

  return (
    <>
      <div style={{ paddingBottom: '12px' }}>
        <Checkbox
          name="all"
          label={
            <FormattedMessage
              id="module-annotation-selectAllVocab"
              values={{
                total: vocabs.length,
              }}
            />
          }
          checked={allChecked}
          onChange={() => {
            if (allChecked) {
              setProperties([]);
              onSelectionChange([]);
            } else {
              const allValues = vocabs.map((s) => s.Key);

              setProperties(allValues);
              onSelectionChange(vocabs);
            }
          }}
        />
      </div>

      <>
        <div
          style={{
            padding: '12px 6px',
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
          }}
        >
          <TextField
            placeholder={intl.formatMessage({
              id: 'module-annotation-searchForVocab',
            })}
            value={query}
            onChange={(e, v) => {
              setQuery(v);
            }}
          />
        </div>
        <div style={{ padding: '12px 6px' }}>
          {filteredVocab.length !== vocabs.length && (
            <div>
              Showing <strong>{filteredVocab.length}</strong> out of &nbsp;
              <strong>{vocabs.length}</strong>
            </div>
          )}
        </div>
      </>

      <div
        style={{
          maxHeight: '200px',
          overflowY: 'scroll',
          border: '1px solid #ccc',
          background: '#f2f2f2',
        }}
      >
        {(filteredVocab || []).map((s, idx) => {
          const isSelected = selectedProperties.indexOf(s.Key) > -1;

          return (
            <div key={idx} style={{ padding: '4px' }}>
              <Checkbox
                name={s.Key}
                label={<HighlightText text={s.Key} highlight={query} />}
                checked={isSelected}
                onChange={() => {
                  if (isSelected) {
                    const filteredProps = selectedProperties.filter(
                      (prop) => prop !== s.Key,
                    );
                    const allSchema = filteredProps.map((p) =>
                      vocabs.find((sc) => sc.Key === p),
                    );

                    setProperties([...filteredProps]);
                    onSelectionChange(allSchema);
                  } else {
                    const updatedProps = [...selectedProperties, s.Key];
                    const allSchema = updatedProps.map((p) =>
                      vocabs.find((sc) => sc.Key === p),
                    );
                    setProperties(updatedProps);
                    onSelectionChange(allSchema);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default injectIntl(CoreVocabSelection);
