import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'admin.roles': RACI_LEVELS.INFORMED,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"RolesListPage" */
        './components/pages/RolesListPage'
      ),
    ),
  },
  {
    path: '/detail/:name',
    exact: false,
    claims: {
      'admin.roles': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"RoleDetailPage" */
        './components/pages/RoleDetailPage'
      ),
    ),
  },
];
