import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import EntityActionMessageItemDetails from '../EntityActionMessageItemDetails/EntityActionMessageItemDetails';
import { EntityActionMessageItemWrapper } from './EntityActionMessageItem.styles';

const EntityActionMessageItem = ({
  entityId,
  message,
  refetchEntityMessages,
}) => {
  const messageForApproval = {
    ...message,
    entityId,
  };

  const messageId = get(message, 'id');

  const actionType = get(message, 'action');
  const rawQuery = get(message, 'rawQuery');

  return (
    <EntityActionMessageItemWrapper>
      {message?.properties &&
        (message?.properties || []).map((property, index) => (
          <EntityActionMessageItemDetails
            key={index}
            actionType={actionType}
            messageForApproval={messageForApproval}
            messageId={messageId}
            property={property}
            rawQuery={rawQuery}
            refetchEntityMessages={refetchEntityMessages}
          />
        ))}
    </EntityActionMessageItemWrapper>
  );
};

export default EntityActionMessageItem;

EntityActionMessageItem.propTypes = {
  entityId: PropTypes.string,
  message: PropTypes.object,
  refetch: PropTypes.func,
};

EntityActionMessageItem.defaultProps = {
  entityId: '',
  message: {},
  refetch: () => {},
};
