import { registerEntityWidget } from '../../registry';
import EntityPreviewV2 from './EntityPreviewV2';

registerEntityWidget('EntityPreviewV2', {
  name: 'Preview',
  displayName: 'Preview',
  description: 'Display a preview of your external document.',
  view: EntityPreviewV2,
  menuItems: [
    {
      id: 'ShowFullScreen',
      icon: 'fullscreen',
      title: 'Show Full Screen',
    },
  ],
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
