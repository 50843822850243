import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import get from 'lodash/get';

import { useGetDataPartNodeInfo } from '../../../hooks/useGetDataPartNodeInfo';
import EntityTopologyDataPartPanelLink from '../EntityTopologyDataPartPanelLink/EntityTopologyDataPartPanelLink';
import EntityTopologySplitNewDataPartIcon from '../EntityTopologySplitNewDataPartIcon/EntityTopologySplitNewDataPartIcon';
import {
  EntityTopologySplitNewDataPartWrapper,
  EntityTopologySplitNewDataPartInnerWrapper,
  EntityTopologySplitNewDataPartHeading,
  EntityTopologySplitNewDataPartHeadingWrapper,
  EntityTopologySplitNewDataPartDetailsWrapper,
  EntityTopologySplitNewDataPartBorderCircle,
  EntityTopologySplitNewDataPartDetails,
  EntityTopologySplitNewDataPartDetailsInner,
} from './EntityTopologySplitNewDataPart.styles';

const EntityTopologySplitNewDataPart = ({
  data,
  entityId,
  isNewEntityDataPart,
  currentNode,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const dataPartId = get(data, 'dataPartId');

  const [
    getDataPartNodeInfo,
    {
      data: dataPartNodeInfoData,
      loading: dataPartNodeInfoLoading,
      error: dataPartNodeInfoError,
    },
  ] = useGetDataPartNodeInfo();

  const dataPartPanelData = {
    currentNode: currentNode || {},
    dataPartNodeInfo: dataPartNodeInfoData || {},
  };

  useEffect(() => {
    if (entityId && dataPartId) {
      getDataPartNodeInfo(entityId, dataPartId);
    }
  }, [entityId, dataPartId]);

  return (
    <EntityTopologySplitNewDataPartWrapper>
      <EntityTopologySplitNewDataPartInnerWrapper
        isNewEntityDataPart={isNewEntityDataPart}
      >
        <EntityTopologySplitNewDataPartHeading>
          <EntityTopologySplitNewDataPartHeadingWrapper data-test="entity-entityTopology-newDataPartButton">
            <EntityTopologySplitNewDataPartDetailsWrapper>
              <EntityTopologySplitNewDataPartBorderCircle
                isNewEntityDataPart={isNewEntityDataPart}
                themePrimary={themePrimary}
              />

              <EntityTopologySplitNewDataPartDetails>
                <EntityTopologySplitNewDataPartIcon provider={data?.provider} />
                <EntityTopologySplitNewDataPartDetailsInner>
                  {data?.name && data?.dataPartId && entityId ? (
                    <EntityTopologyDataPartPanelLink
                      name={<strong>{data?.name}</strong>}
                      recordPanelData={dataPartPanelData}
                    />
                  ) : (
                    <em style={{ color: '#9b9b9c' }}>
                      <FormattedMessage id="module-entityTopology-noRecordDetails" />
                    </em>
                  )}
                  <div style={{ display: 'flex' }}>
                    <FormattedMessage id="module-entityTopology-recordId" />
                    :&nbsp;
                    {data?.dataPartId}
                  </div>
                </EntityTopologySplitNewDataPartDetailsInner>
              </EntityTopologySplitNewDataPartDetails>
            </EntityTopologySplitNewDataPartDetailsWrapper>
          </EntityTopologySplitNewDataPartHeadingWrapper>
        </EntityTopologySplitNewDataPartHeading>
      </EntityTopologySplitNewDataPartInnerWrapper>
    </EntityTopologySplitNewDataPartWrapper>
  );
};

export default EntityTopologySplitNewDataPart;

EntityTopologySplitNewDataPart.propTypes = {
  data: PropTypes.object,
  entityId: PropTypes.string,
  isNewEntityDataPart: PropTypes.bool,
  currentNode: PropTypes.object,
  currentNode: PropTypes.object,
};

EntityTopologySplitNewDataPart.defaultProps = {
  data: {},
  entityId: null,
  isNewEntityDataPart: null,
  currentNode: {},
  currentNode: {},
};
