import { createAction } from 'redux-actions';
import { createAsyncAction } from '../core/helpers/action';
import { fetchNotifications, markAllAsRead, markAsRead } from './data';
import { defaultAsyncCallWithActionsWithParam } from '../../action/helpers/actionCreator';

export const openNotificationCenter = createAction('OPEN_NOTIFICATION_CENTER');
export const closeNotificationCenter = createAction(
  'CLOSE_NOTIFICATION_CENTER',
);

export const notificationActions = createAsyncAction('NOTIFICATIONS_FETCHING');
export const shouldFetchNotifications = defaultAsyncCallWithActionsWithParam(
  fetchNotifications,
  notificationActions,
);

export const markAsRedActions = createAsyncAction('NOTIFICATIONS_MARK_AS_RED');
export const shouldMarkAsRead = defaultAsyncCallWithActionsWithParam(
  markAsRead,
  markAsRedActions,
);
export const shouldMarkAsReadNotification = createAction(
  'RECEIVE_MARK_AS_READ_FROM_SOCKET',
);

export const markAllAsRedActions = createAsyncAction(
  'NOTIFICATIONS_MARK_ALL_AS_RED',
);
export const shouldMarkAllAsRead = defaultAsyncCallWithActionsWithParam(
  markAllAsRead,
  markAllAsRedActions,
);

export const nextNotificationPageActions = createAsyncAction(
  'NOTIFICATIONS_NEXT_PAGE',
);
export const shouldFetchNotificationsNextPage =
  defaultAsyncCallWithActionsWithParam(
    fetchNotifications,
    nextNotificationPageActions,
  );

const createAutoResetActions = (name = 'youShouldNameThis') => ({
  receive: createAction(`${name.toUpperCase()}_RECEIVE`),
  reset: createAction(`${name.toUpperCase()}_RESET`),
});
const createDefaultResetActionWithParam =
  (resetActions, resetDelay = 10000) =>
  (param) =>
  (dispatch) => {
    setTimeout(() => {
      dispatch(resetActions.reset(param));
    }, resetDelay);
    dispatch(resetActions.receive(param));
  };

export const crawlerNotificationResetActions = createAutoResetActions(
  'CRAWLER_NOTIFICATION',
);
export const shouldReceiveCrawlerFinishedNotification =
  createDefaultResetActionWithParam(crawlerNotificationResetActions);

export const upgradePlanResetActions = createAutoResetActions(
  'UPGRADE_PLAN_NOTIFICATION',
);
export const shouldReceiveUpgradePlanResetActions =
  createDefaultResetActionWithParam(upgradePlanResetActions);

export const newUserNotificationActions = createAutoResetActions(
  'NEW_USER_NOTIFICATION',
);
export const shouldReceiveNewUserNotification =
  createDefaultResetActionWithParam(newUserNotificationActions);

export const failedAuthenticationNotificationActions = createAutoResetActions(
  'FAILED_AUTHRENTICATION_NOTIFICATION',
);
export const shouldReceiveFailedAuthenticationNotification =
  createDefaultResetActionWithParam(failedAuthenticationNotificationActions);

export const meshQueueMessageNotificationActions = createAutoResetActions(
  'MESH_QUEUED_MSG_NOTIFICATION',
);
export const shouldReceiveMeshQueueMessageNotification =
  createDefaultResetActionWithParam(meshQueueMessageNotificationActions);

export const meshMessageNotificationActions = createAutoResetActions(
  'MESH_MSG_NOTIFICATION',
);
export const shouldReceiveMeshMessageNotification =
  createDefaultResetActionWithParam(meshMessageNotificationActions);

export const mergeFinishedNotificationActions = createAutoResetActions(
  'MERGE_FINISHED_NOTIFICATION',
);
export const shouldReceiveMergeFinishedNotification =
  createDefaultResetActionWithParam(mergeFinishedNotificationActions);

export const newProviderNotificationActions = createAutoResetActions(
  'NEW_PROVIDER_NOTIFICATION',
);
export const shouldReceiveNewProviderNotification =
  createDefaultResetActionWithParam(newProviderNotificationActions);

export const newConfigurationNotificationActions = createAutoResetActions(
  'NEW_CONFIGURATION_NOTIFICATION',
);
export const shouldReceiveNewConfigurationNotification =
  createDefaultResetActionWithParam(newConfigurationNotificationActions);

export const shouldAddNotif = createAction('SHOULD_ADD_NEW_NOTIF');
export const shouldClearNotification = createAction(
  'SHOULD_CLEAR_NOTIFICATION',
);
