import constants from '../constants';

const initialState = {
  entities: {},
  selectedEntity: undefined,
  isFetchingEntity: true,
  entityByEmail: {},
  isFetchingEntityByEmail: true,
  schema: undefined,
  isFetchingSchema: false,
  entityNewUpdate: [],
  fetchingEntityError: false,
};

export default function update(state = initialState, action = {}) {
  switch (action.type) {
    case constants.entity.CLEAN_UP_SELECTED_ENTITY:
      return Object.assign({}, state, {
        selectedEntity: null,
      });
    case constants.entity.REQUEST_ENTITY_SCHEMA:
      return Object.assign({}, state, {
        isFetchingSchema: true,
      });
    case constants.entity.RECEIVE_ENTITY_SCHEMA:
      return Object.assign({}, state, {
        schema: action.data.schema,
        isFetchingSchema: false,
      });
    case constants.entity.REQUEST_ENTITY:
      return Object.assign({}, state, {
        entities: state.entities,
        selectedEntity: undefined,
        isFetchingEntity: true,
      });
    case constants.entity.INVALID_ENTITY:
      return Object.assign({}, state, {
        isFetchingEntity: false,
        fetchingEntityError: true,
      });
    case constants.entity.RECEIVE_UPDATE_ENTITY: {
      const entities = state.entities;

      if (action.data.addToEntities) {
        entities[action.data.entity.id] = action.data.entity;
      }

      return Object.assign({}, state, {
        entities: Object.assign({}, entities),
      });
    }
    case constants.entity.RECEIVE_ENTITY: {
      const entities = state.entities;

      if (action.data.addToEntities) {
        entities[action.data.entity.id] = action.data.entity;
      }

      return Object.assign({}, state, {
        entities: Object.assign({}, entities),
        selectedEntity: action.data.entity,
        isFetchingEntity: false,
      });
    }
    case constants.entity.RECEIVE_NEW_ENTITY_UPDATE:
      return Object.assign({}, state, {
        entityNewUpdate: [
          ...state.entityNewUpdate,
          {
            show: true,
            entity: action.data.entity,
            count: action.data.count,
          },
        ],
      });
    case constants.entity.RESET_NEW_ENTITY_UPDATE: {
      const entityUpdates = state.entityNewUpdate.filter(
        (e) => e.entity.id === action.data.entityId,
      );

      entityUpdates.forEach((e) => {
        const entityUpdate = e;
        entityUpdate.show = false;
      });

      return Object.assign({}, state, {
        entityNewUpdate: [...state.entityNewUpdate],
      });
    }
    default:
      return state;
  }
}
