import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_HISTORY_CHANGE_SETS = gql`
  query getEntityHistoryChangeSets($id: ID!) {
    virtualization {
      entityHistoryChangeSets(id: $id) {
        groupName
        sources {
          providerDefinitionId
          sourceName
          changedAt
          changedBy
          partId
          changeType
          changeCount
          sourceDetails {
            displayName
            sourceObjectId
            sourceTypeId
          }
        }
      }
    }
  }
`;

export const useQueryEntityHistoryChangeSetsById = (id) => {
  const { data, loading, error } = useQuery(GET_ENTITY_HISTORY_CHANGE_SETS, {
    variables: { id },
  });

  const history = get(data, 'virtualization.entityHistoryChangeSets');

  return [history, loading, error];
};
