import { FormattedMessage } from 'react-intl';
import { urlBuilder } from '../../../../../config';

type FileUploadFormat = {
  fileFormat: string;
  hasBasicXLSX: boolean;
};

const getSampleFile = (format: string, hasBasicXLSX: boolean) => {
  if (hasBasicXLSX && (format === 'xls' || format === 'xlsx')) {
    return 'cluedin-sample-xls.xlsx';
  }

  if (format === 'json') {
    return 'cluedin-json-sample.json';
  }

  return 'cluedin-json-sample.json';
};

export const getSupportedFileFormat = (hasBasicXLSX: boolean) => {
  const supportedFileFormats = ['.csv, text/csv, application/json'];

  if (hasBasicXLSX) {
    supportedFileFormats.push(
      ', application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
  }

  return supportedFileFormats;
};

export const getFormatsToShowSampleFile = (hasBasicXLSX: boolean) => {
  const formatsToShowExampleFile = ['application/json'];

  if (hasBasicXLSX) {
    formatsToShowExampleFile.push('application/vnd.ms-excel');
    formatsToShowExampleFile.push(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
  }

  return formatsToShowExampleFile;
};

export const getSupportedFormat = (hasBasicXLSX: boolean) => {
  const supportedFormats = ['CSV', 'JSON'];

  if (hasBasicXLSX) {
    supportedFormats.push('XLS');
    supportedFormats.push('XLSX');
  }

  return supportedFormats;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const getNotification = (format: string, hasBasicXLSX: boolean) => {
  if (hasBasicXLSX && (format === 'xls' || format === 'xlsx')) {
    return <FormattedMessage id="data-source-xlsWarningSample" />;
  }

  return <FormattedMessage id="data-source-jsonWarningSample" />;
};

export const FileUploadFormFormatsHelper = (options: FileUploadFormat) => {
  const { fileFormat, hasBasicXLSX } = options;

  if (fileFormat !== 'xls' && fileFormat !== 'xlsx' && fileFormat !== 'json') {
    return null;
  }

  const sampleFileName = getSampleFile(fileFormat, hasBasicXLSX);
  const mainNotification = getNotification(fileFormat, hasBasicXLSX);

  return (
    <div
      style={{ padding: '6px 0', color: '#ff9800' }}
      data-test={`prompt-${fileFormat === 'json' ? 'json' : 'xls'}-files`}
    >
      {mainNotification}{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href={urlBuilder.mainApp(sampleFileName)}
        download={sampleFileName}
      >
        <FormattedMessage id="data-source-downloadSample" />
      </a>{' '}
      <FormattedMessage id="data-source-toHaveMoreInfo" />
    </div>
  );
};
