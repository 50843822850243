import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import RecordPanelHeaderIcon from './RecordPanelHeaderIcon/RecordPanelHeaderIcon';
import RecordPanelHeaderDetail from './RecordPanelHeaderDetail/RecordPanelHeaderDetail';
import { RecordPanelHeaderWrapper } from './RecordPanelHeader.styles';

const RecordPanelHeader = ({ data }) => {
  const provider = get(data, 'dataPartNodeInfo.provider');
  const title = get(data, 'dataPartNodeInfo.name');
  const detail = {
    modifiedAt: get(data, 'dataPartNodeInfo.modifiedDate'),
    createdAt: get(data, 'dataPartNodeInfo.createdDate'),
    discoveredAt: get(data, 'dataPartNodeInfo.discoveredDate'),
  };
  const recordId = get(data, 'dataPartNodeInfo.dataPartId');
  const subtitle = get(data, 'dataPartNodeInfo.entityType');
  const source = get(data, 'dataPartNodeInfo.source');
  const providerWithIcon = get(data, 'currentNode.provider');

  const isRecord = !!provider?.providerId;
  const isCleanProject = source?.kind?.toLowerCase() === 'clean';
  const isLegacyCleanProject = source?.kind?.toLowerCase() === 'legacyclean';
  const isDeduplicationProject =
    source?.kind?.toLowerCase() === 'deduplicationprojectmerge';
  const isManualMerge = source?.kind?.toLowerCase() === 'manualmerge';
  const isManuallyAdded = source?.kind?.toLowerCase() === 'userinput';
  const isManuallyAddedFileEntry =
    source?.kind?.toLowerCase() === 'userinput' &&
    provider?.providerName?.toLowerCase() === 'manual entry source';

  return (
    <RecordPanelHeaderWrapper>
      <RecordPanelHeaderIcon
        isManualMerge={isManualMerge}
        provider={providerWithIcon}
        isCleanProject={isCleanProject}
        isLegacyCleanProject={isLegacyCleanProject}
        isDeduplicationProject={isDeduplicationProject}
        isManuallyAdded={isManuallyAdded}
        isRecord={isRecord}
        isManuallyAddedFileEntry={isManuallyAddedFileEntry}
      />

      <RecordPanelHeaderDetail
        title={title}
        subtitle={subtitle}
        detail={detail}
        recordId={recordId}
        isCleanProject={isCleanProject}
        isLegacyCleanProject={isLegacyCleanProject}
        isDeduplicationProject={isDeduplicationProject}
        isManuallyAdded={isManuallyAdded}
        isRecord={isRecord}
        isManualMerge={isManualMerge}
        isManuallyAddedFileEntry={isManuallyAddedFileEntry}
        source={source}
        provider={provider}
      />
    </RecordPanelHeaderWrapper>
  );
};

export default RecordPanelHeader;

RecordPanelHeader.propTypes = {
  data: PropTypes.object,
};

RecordPanelHeader.defaultProps = {
  data: {},
};
