import React from 'react';
import { useTheme } from '@cluedin/theme';
import PropTypes from 'prop-types';

const CILink = ({ children, href, style, title }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const mergedStyle = Object.assign(style || {}, {
    color: themePrimary,
    textDecoration: 'none',
    cursor: 'pointer',
    position: 'relative', // hack for Material UI Label
  });

  return (
    <a style={mergedStyle} href={href} target="__blank" title={title}>
      {children}
    </a>
  );
};

CILink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CILink;
