"use strict";

exports.__esModule = true;
exports.useModal = void 0;
var _reactHooks = require("@fluentui/react-hooks");
var useModal = function useModal(_temp) {
  var _ref = _temp === void 0 ? {
      defaultOpen: false
    } : _temp,
    defaultOpen = _ref.defaultOpen;
  var _useBoolean = (0, _reactHooks.useBoolean)(defaultOpen),
    isModalOpen = _useBoolean[0],
    _useBoolean$ = _useBoolean[1],
    showModal = _useBoolean$.setTrue,
    hideModal = _useBoolean$.setFalse;
  return [isModalOpen, {
    showModal: showModal,
    hideModal: hideModal
  }];
};
exports.useModal = useModal;