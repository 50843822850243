import { useQuery, gql } from '@apollo/client';
import { get, pick } from 'lodash';
import { legacyClient } from '../../../../../../data/graphql';

// TODO: HARDCODED SORT BY NEW
const GET_SEARCH_COUNT = gql`
  query searchCount(
    $query: String
    $filter: String
    $filters: [FilterQuery]
    $terms: [TermQuery]
    $contextIds: [Guid]
    $includeExternalData: Boolean
    $includeUnstructuredData: Boolean
  ) {
    count(
      query: $query
      filter: $filter
      filters: $filters
      terms: $terms
      contextIds: $contextIds
      includeExternalData: $includeExternalData
      includeUnstructuredData: $includeUnstructuredData
    ) {
      count
    }
  }
`;

export const useSearchCount = (variables) => {
  const { data, loading, error, refetch } = useQuery(GET_SEARCH_COUNT, {
    client: legacyClient,
    variables: {
      ...pick(variables, [
        'query',
        'filter',
        'filters',
        'terms',
        'contextIds',
        'includeExternalData',
        'includeUnstructuredData',
        'cursor',
      ]),
    },
    fetchPolicy: 'network-only',
  });

  const total = get(data, 'count.count');

  return [
    total,
    {
      loading,
      error,
      refetch: () => {
        refetch(variables);
      },
    },
  ];
};
