import styled from 'styled-components';
import { getSVGPathFromData } from './utils';

const Path = styled.path.attrs(({ vectorEffect, curveData, d, dateRange }) => ({
  vectorEffect: vectorEffect || 'non-scaling-stroke',
  d: d || getSVGPathFromData(dateRange, ...curveData),
}))`
  fill: transparent;
  stroke: ${({ theme: { palette }, color }) => color || palette.primary.light};
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'collapse')};
`;

export default Path;
