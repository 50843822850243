import React from 'react';
import WidgetRenderer from './WidgetRenderer';
import Col from './Col';
import Row from './Row';

import {
  hasTabInConfiguration,
  filterWidgetsByPlaceholderName,
} from './layoutHelper';
import LayoutWithTabs from './LayoutWithTabs';

const defaultPlaceHolder = 'main';
const defaultGridSize = 12;

const Layout = ({ template, entity, userId, isMain, grid, entityId }) => {
  const hasTab = hasTabInConfiguration(template);

  let letTabContent;
  let tabContentPlaceholder;

  if (hasTab) {
    tabContentPlaceholder = template.tabs.place || 'main';

    letTabContent = (
      <LayoutWithTabs
        entityId={entityId}
        entity={entity}
        userId={userId}
        layoutName={template.name}
      />
    );
  }

  const layoutContent = grid?.children.map((row, index) => {
    if (row.type === 'row') {
      if (
        hasTab &&
        isMain &&
        grid.children &&
        grid.children.length === 1 &&
        grid.children[0].columns &&
        grid.children[0].columns.length === 1
      ) {
        return (
          <div key={`${template.name}-mainCol-${index}`}>
            {row.columns.map((col, i) => {
              const widgetsForColumn = filterWidgetsByPlaceholderName(
                template.widgets,
                col.name,
              );

              return (
                <div key={`${template.name}-mainRow-${i}`}>
                  {letTabContent && col.name === tabContentPlaceholder
                    ? letTabContent
                    : null}
                  <div className="grid">
                    {(widgetsForColumn || []).map((widget, index) => (
                      <WidgetRenderer
                        isFirst={index === 0}
                        key={`${widget.name}-${index}`}
                        entity={entity}
                        userId={userId}
                        entityId={entityId}
                        widget={widget}
                        layoutName={template.name}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      return (
        <Row key={index}>
          {row.columns.map((col, i) => {
            const widgetsForColumn = filterWidgetsByPlaceholderName(
              template.widgets,
              col.name,
            );

            return (
              <Col key={i} size={col.size} mobileSize={col.mobileSize}>
                {letTabContent && col.name === tabContentPlaceholder
                  ? letTabContent
                  : null}
                {(widgetsForColumn || []).map((widget, index) => (
                  <WidgetRenderer
                    key={`${widget.name}-${index}`}
                    isFirst={index === 0}
                    entity={entity}
                    userId={userId}
                    entityId={entityId}
                    widget={widget}
                    layoutName={template.name}
                    template={template}
                  />
                ))}
              </Col>
            );
          })}
        </Row>
      );
    }
    const gridSize = row.size || defaultGridSize;
    const gridPlaceHolderName = row.name || defaultPlaceHolder;

    return (
      <Row key={index}>
        {filterWidgetsByPlaceholderName(
          template.widgets,
          gridPlaceHolderName,
        ).map((col, i) => (
          <Col key={i} size={col.size || gridSize} mobileSize={col.mobileSize}>
            <WidgetRenderer
              isFirst={i === 0}
              key={`${col.name}-${index}`}
              entity={entity}
              userId={userId}
              entityId={entityId}
              widget={col}
              layoutName={template.name}
              template={template}
            />
          </Col>
        ))}
      </Row>
    );
  });

  return <>{layoutContent}</>;
};

export default Layout;
