import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Application } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';

const CrawlerFinishedNotification = ({ index, notification, onCloseClick }) => {
  const title = 'Your account has been upgraded';
  const message = `Plan chosen: ${notification.plan}`;
  const icon = <Application />;

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      customIcon={icon}
    />
  );
};

const EnhancedCrawlerFinishedNotification = compose(
  lifecycle({
    componentDidMount() {
      const { notification } = this.props;
      const title = 'Your account has been upgraded';
      const message = `Plan chosen: ${notification.plan}`;

      notify(title, { body: message });
    },
  }),
)(CrawlerFinishedNotification);

export default EnhancedCrawlerFinishedNotification;
