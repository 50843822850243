import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';

const GET_ENTITY_TYPE_INFO_BY_TYPE = gql`
  query getEntityTypeInfo($type: String!) {
    management {
      getEntityTypeInfo(type: $type) {
        id
        icon
        displayName
        type
        route
        path
        active
        layoutConfiguration
        pageTemplateId
        externalFeatures {
          id
          displayName
          longDescription
        }
      }
    }
  }
`;

export const useGetEntityTypeInfo = (type) => {
  const { data, loading, error, refetch } = useQuery(
    GET_ENTITY_TYPE_INFO_BY_TYPE,
    {
      variables: {
        type,
      },
    },
  );

  const response = get(data, 'management.getEntityTypeInfo');

  return [response, loading, error, refetch];
};
