import styled from 'styled-components';

export const DropZoneArea = styled.div`
  padding: 16px;
  margin: 16px 0;
  cursor: pointer;
  border-radius: 3px;
  background: ${({
    theme: {
      palette: { neutralLighterAlt },
    },
  }) => neutralLighterAlt};
  flex-direction: column;
  border: 1px dashed
    ${({
      theme: {
        palette: { themePrimary },
      },
    }) => themePrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  &:focus {
    outline: none;
  }
`;

export const DropZoneTitle = styled.p`
  color: ${({ themePrimary }: { themePrimary: string }) => themePrimary};
  font-size: 18px;
  margin: 12px 0 0;
`;

export const DropZoneTextSmall = styled.p`
  font-size: 10x;
  color: #bbb;
  margin: 12px 0;
`;

export const DropZoneLimitText = styled.p`
  font-size: 10x;
  color: #bbb;
  margin: 12px 0;

  span {
    text-transform: uppercase;
  }
`;

export const FileList = styled.div``;
