import React from 'react';
import PersonList from '../../../../viewModels/components/composites/person/PersonList';
import OrganizationList from '../../../../viewModels/components/composites/organization/OrganizationList';
import SearchResultList from './SearchResultList';
import ListWithPaging from '../../../../../core/components/Hocs/ListWithPaging';

const SearchResult = ({ data = [], searchType, isFetchingMainSearch }) => {
  return (
    <>
      {searchType === 'person' && (
        <PersonList data={data} isFetchingMainSearch={isFetchingMainSearch} />
      )}
      {searchType === 'organization' && (
        <OrganizationList
          data={data}
          isFetchingMainSearch={isFetchingMainSearch}
        />
      )}
      {searchType !== 'person' && searchType !== 'organization' && (
        <SearchResultList
          data={data}
          isFetchingMainSearch={isFetchingMainSearch}
        />
      )}
    </>
  );
};

export default ListWithPaging(SearchResult);
