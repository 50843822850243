"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(ListItemIcon) {
  return (0, _styledComponents.default)(ListItemIcon)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  flex-shrink: 0;\n  padding-right: 8px;\n  display: inline-flex;\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.action.active;
  });
};
exports.default = _default;