import React, { memo, useMemo } from 'react';
import { Delete } from 'uxi/Icons';
import { useTheme } from '@cluedin/theme';
import find from 'lodash/find';

import { RuleItemFieldSelectors } from './RuleItemFieldSelectors';
import { RuleItemValueSelectors } from './RuleItemValueSelectors';
import { RuleItemOperatorSelector } from './RuleItemOperatorSelector';
import { RuleItemObjectTypeIdSelector } from './RuleItemObjectTypeIdSelector';

const isFieldHasErrors = (errors, name) =>
  errors?.filter((i) => i.key === name)?.length > 0;

export const RuleItem = memo(
  ({
    scope,
    errors,
    ruleItem,
    objectTypes,
    onValueChange,
    ruleItemIndex,
    operators = {},
    onPropertyChange,
    onRemoveRuleItem,
    onObjectTypeChange,
    ruleConditionIndex,
    actionConfiguration,
    onChangeOperatorType,
    onMultipleValueChange,
    hasStrongTypingFeature,
    VocabularyAutoComplete,
    PropertyItemAutoComplete,
    useQueryVocabularyKeyByKey,
    VocabularyValueAutoComplete,
    previewMode,
  }) => {
    const theme = useTheme();
    const propertyType = ruleItem?.type;

    const selectedObjectTypeName = find(
      objectTypes,
      (ot) => ot.id === ruleItem?.objectTypeId,
    )?.name;

    const isVocabularyProperty = selectedObjectTypeName === 'Vocabulary';

    const [vocabKeyData] = useQueryVocabularyKeyByKey(
      ruleItem?.field,
      !isVocabularyProperty || !ruleItem?.field,
    );

    const mappedKey = vocabKeyData?.mappedKey;
    const isMappedKeyStrongTyped =
      mappedKey?.Storage === 'Typed' && hasStrongTypingFeature;

    const isVocabStrongTyped =
      vocabKeyData?.storage === 'Typed' && hasStrongTypingFeature;

    const vocabKeyDataType = useMemo(() => {
      if (!isVocabularyProperty) return propertyType || 'string';

      if (isMappedKeyStrongTyped) {
        return mappedKey?.DataType || 'string';
      }

      return isVocabStrongTyped ? vocabKeyData?.dataType : 'string';
    }, [isVocabularyProperty, vocabKeyData, ruleItem, mappedKey]);

    const itemLevelKey = `${ruleConditionIndex}-${ruleItemIndex}`;

    const itemErrors = errors?.filter((i) => i.key === itemLevelKey)?.[0]
      ?.errors;

    const objectTypeIdHasError = isFieldHasErrors(itemErrors, 'objectTypeId');
    const fieldHasError = isFieldHasErrors(itemErrors, 'field');
    const operatorHasError = isFieldHasErrors(itemErrors, 'operator');
    const valueHasError = isFieldHasErrors(itemErrors, 'value');

    return (
      <div
        className="childRule"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          padding: '12px 0 5px 5px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RuleItemObjectTypeIdSelector
              ruleItem={ruleItem}
              objectTypes={objectTypes}
              ruleItemIndex={ruleItemIndex}
              onObjectTypeChange={onObjectTypeChange}
              objectTypeIdHasError={objectTypeIdHasError}
              previewMode={previewMode}
              selectedObjectTypeName={selectedObjectTypeName}
            />

            {ruleItem?.objectTypeId && selectedObjectTypeName && (
              <RuleItemFieldSelectors
                ruleItem={ruleItem}
                mappedKey={mappedKey}
                objectTypes={objectTypes}
                ruleItemIndex={ruleItemIndex}
                fieldHasError={fieldHasError}
                onPropertyChange={onPropertyChange}
                objectTypeId={ruleItem?.objectTypeId}
                isVocabularyProperty={isVocabularyProperty}
                VocabularyAutoComplete={VocabularyAutoComplete}
                hasStrongTypingFeature={hasStrongTypingFeature}
                PropertyItemAutoComplete={PropertyItemAutoComplete}
                previewMode={previewMode}
              />
            )}

            {ruleItem?.field && vocabKeyDataType && (
              <RuleItemOperatorSelector
                scope={scope}
                ruleItem={ruleItem}
                operators={operators}
                value={ruleItem?.operator}
                ruleItemIndex={ruleItemIndex}
                propertyType={vocabKeyDataType}
                vocabularyKey={vocabKeyData}
                operatorHasError={operatorHasError}
                isVocabStrongTyped={isVocabStrongTyped}
                isVocabularyProperty={isVocabularyProperty}
                onChangeOperatorType={onChangeOperatorType}
                previewMode={previewMode}
              />
            )}
          </div>

          {ruleItem.operator && vocabKeyDataType && (
            <div style={{ marginTop: '12px' }}>
              <RuleItemValueSelectors
                ruleItem={ruleItem}
                operators={operators}
                ruleItemIndex={ruleItemIndex}
                onValueChange={onValueChange}
                valueHasError={valueHasError}
                propertyType={vocabKeyDataType}
                selectedOperator={ruleItem.operator}
                onMultipleValueChange={onMultipleValueChange}
                VocabularyValueAutoComplete={VocabularyValueAutoComplete}
                previewMode={previewMode}
              />
            </div>
          )}

          {itemErrors?.length > 0 && (
            <div
              style={{
                marginTop: ruleItem.operator ? '12px' : 0,
                color: theme.palette.red,
              }}
            >
              {itemErrors.map((err, idx) => (
                <div key={idx}>{err.message}</div>
              ))}
            </div>
          )}
        </div>
        {!previewMode && (
          <div style={{ marginTop: '6px' }}>
            <button
              style={{
                padding: 0,
                border: 'none',
                cursor: 'pointer',
                marginLeft: '6px',
                background: 'transparent',
              }}
              onClick={() => onRemoveRuleItem(ruleItemIndex)}
            >
              <Delete color={theme.palette.red} size="14" />
            </button>
          </div>
        )}
      </div>
    );
  },
);
