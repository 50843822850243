import { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from '@cluedin/locale';
import { CancelButton, PanelHeader } from '@cluedin/form';
import { useTheme } from '@cluedin/theme';
import Alert from 'uxi/Alert';
import get from 'lodash/get';

import RecordPanelHeader from './RecordPanelHeader/RecordPanelHeader';
import RecordPanelList from './RecordPanelList/RecordPanelList';
import {
  RecordPanelFooter,
  RecordPanelFooterWrapper,
  RecordPanelWrapper,
  RecordPanelAlertWrapper,
} from './RecordPanel.styles';

const RecordPanel = ({ recordPanelData, onClose, setNodeDataType, intl }) => {
  const [isListData, setIsListData] = useState(false);

  const theme = useTheme();
  const themeWhite = theme?.palette?.white;

  const title = get(recordPanelData, 'dataPartNodeInfo.name');
  const properties = get(recordPanelData, 'dataPartNodeInfo.properties');
  const recordId = get(recordPanelData, 'dataPartNodeInfo.dataPartId');
  const source = get(recordPanelData, 'dataPartNodeInfo.source');

  let offsetTop;
  if (source?.kind?.toLowerCase() === 'clean') {
    offsetTop = 391;
  }
  if (source?.kind?.toLowerCase() === 'userinput') {
    offsetTop = 414;
  }
  if (!source) {
    offsetTop = 368;
  }

  const handleOnClose = () => {
    setNodeDataType?.('');
    onClose();
  };

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, []);

  const titleToUse = recordPanelData ? (
    source ? (
      source?.kind?.toLowerCase() === 'clean' ||
      source?.kind?.toLowerCase() === 'legacyclean' ? (
        intl.formatMessage({ id: 'module-entityTopology-sourceClean' })
      ) : source?.kind?.toLowerCase() === 'manualmerge' ? (
        intl.formatMessage({ id: 'module-entityTopology-sourceManualMerge' })
      ) : source?.kind?.toLowerCase() === 'userinput' ? (
        intl.formatMessage({ id: 'module-entityTopology-sourceManuallyAdded' })
      ) : (
        source?.kind?.toLowerCase() === 'deduplicationprojectmerge' &&
        intl.formatMessage({
          id: 'module-entityTopology-sourceDeduplicationProjectMerge',
        })
      )
    ) : title ? (
      `${title}`
    ) : (
      <>
        <FormattedMessage id="module-entityTopology-splitPanelDetailRecordId" />
        :&nbsp;
        {recordId}
      </>
    )
  ) : (
    intl.formatMessage({ id: 'module-entityTopology-noRecordFound' })
  );

  return (
    <>
      <PanelHeader title={titleToUse} onClose={handleOnClose} />

      <RecordPanelWrapper>
        {recordPanelData ? (
          <RecordPanelHeader data={recordPanelData} />
        ) : (
          <RecordPanelAlertWrapper>
            <Alert type="warning">
              <FormattedMessage id="module-entityTopology-noRecordFound" />
            </Alert>
          </RecordPanelAlertWrapper>
        )}

        {properties && (
          <RecordPanelList
            properties={properties}
            setIsListData={setIsListData}
            isListData={isListData}
            offsetTop={offsetTop}
            isDeduplication={
              source?.kind?.toLowerCase() === 'deduplicationprojectmerge'
            }
          />
        )}

        <RecordPanelFooter themeWhite={themeWhite}>
          <RecordPanelFooterWrapper>
            <CancelButton
              data-test="entityTopology-recordPanel-closeButton"
              rounded
              onClick={handleOnClose}
              style={{ marginTop: '12px' }}
            >
              {intl.formatMessage({
                id: 'module-entityTopology-splitPanelCloseButton',
              })}
            </CancelButton>
          </RecordPanelFooterWrapper>
        </RecordPanelFooter>
      </RecordPanelWrapper>
    </>
  );
};

export default injectIntl(RecordPanel);
