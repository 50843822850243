import { useEffect } from 'react';
import Alert from 'uxi/Alert';
import { FormattedMessage } from '@cluedin/locale';
import VocabularyKeyListSelectionDialog from '../../VocabularyKeyListSelectionDialog';

const AddVocabulary = ({
  onVocabularySelectedChange,
  selectedVocabulary,
  currentNumberColumns,
  maxNumberColumns,
  onVocabularySelectedCancel,
}) => {
  const maxedOut = currentNumberColumns > maxNumberColumns;

  useEffect(() => {
    return () => {
      onVocabularySelectedCancel?.();
    };
  }, []);

  return (
    <>
      {maxedOut && (
        <Alert type="warning">
          <FormattedMessage
            id="module-dataCatalog-cannotSelectMore"
            values={{
              maxNumberColumns,
            }}
          />
        </Alert>
      )}
      <VocabularyKeyListSelectionDialog
        selectedVocabulary={selectedVocabulary}
        onSelectedChange={onVocabularySelectedChange}
      />
    </>
  );
};

export default AddVocabulary;
