import { useRef, memo, useCallback, FC } from 'react';

import { useAppSearch } from './context/useAppSearch';

import { AppSearchScopes } from './AppSearch.types';
import { AppSearchInput } from './components/AppSearchInput';
import { AppSearchButton } from './components/AppSearchButton';
import { AppSearchCancelButton } from './components/AppSearchCancelButton';
import { AppSearchScopeDropdown } from './components/AppSearchScopeDropdown';
import { AppSearchSuggestionsCallout } from './components/AppSearchSuggestions';

import { AppSearchElement } from './AppSearch.styles';

type Props = {
  searchInputWidth?: string;
  searchInputPlaceHolder?: string;
};

const COMMON_APP_SEARCH_INPUT_AND_CALLOUT_ID =
  'COMMON_APP_SEARCH_INPUT_AND_CALLOUT_ID';

export const AppSearch: FC<Props> = memo(
  ({ searchInputWidth = '400px', searchInputPlaceHolder = 'Search' }) => {
    const { value, scope } = useAppSearch();

    const inputRef = useRef<HTMLInputElement>(null);
    const searchButtonRef = useRef<HTMLInputElement>(null);

    const handleChangeScopeDropdown = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    const handleBlurAppSearchInput = useCallback(() => {
      inputRef.current?.blur();
      searchButtonRef.current?.focus();
    }, []);

    return (
      <AppSearchElement>
        <AppSearchScopeDropdown
          scopeDropdownChangedEvent={handleChangeScopeDropdown}
        />

        <AppSearchInput
          ref={inputRef}
          width={searchInputWidth}
          placeholder={searchInputPlaceHolder}
          id={COMMON_APP_SEARCH_INPUT_AND_CALLOUT_ID}
          handleBlurAppSearchInput={handleBlurAppSearchInput}
        />

        <AppSearchSuggestionsCallout
          width={searchInputWidth}
          suggestionsLabel="Suggested searches"
          id={COMMON_APP_SEARCH_INPUT_AND_CALLOUT_ID}
          handleBlurAppSearchInput={handleBlurAppSearchInput}
        />

        {(value?.length > 0 || scope !== AppSearchScopes.all) && (
          <AppSearchCancelButton />
        )}

        <AppSearchButton ref={searchButtonRef} />
      </AppSearchElement>
    );
  },
);
