const notify = (title, options, url) => {
  let mergedOptions = options;
  if (options) {
    mergedOptions = Object.assign({}, options, {
      icon: 'https://s3-eu-west-1.amazonaws.com/staticcluedin/Cluedin-icon.png',
    });
  }

  const notifClick = url
    ? (e) => {
        window.location = url;
        const parent = window.parent;
        if (parent.focus) {
          parent.focus();
        }
        if (window.focus) {
          window.focus();
        }
        e.target.close();
      }
    : (e) => {
        const parent = window.parent;
        if (parent.focus) {
          parent.focus();
        }
        if (window.focus) {
          window.focus();
        }
        e.target.close();
      };

  if ('Notification' in window) {
    if (window.Notification.permission === 'granted') {
      /* eslint no-new:0 */
      const notif = new Notification(title, mergedOptions);
      notif.onclick = notifClick;
    } else if (window.Notification.permission !== 'denied') {
      window.Notification.requestPermission((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          /* eslint no-new:0 */
          const notif = new window.Notification(title, mergedOptions);
          notif.onclik = notifClick;
        }
      });
    }
  }
};

export const isDesktopNotificationEnabled = () =>
  window.Notification && window.Notification.permission === 'granted';

export default notify;
