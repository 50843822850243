import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_EXPLAIN_LOG_ENTITY_DETAILS = gql`
  query getExplainLogEntityById($id: ID!) {
    virtualization {
      explainLogEntityDetails(id: $id) {
        entity
      }
    }
  }
`;

export const useQueryExplainLogEntityById = (id) => {
  const { data, loading, error } = useQuery(GET_EXPLAIN_LOG_ENTITY_DETAILS, {
    variables: { id },
  });

  const entity = get(data, 'virtualization.explainLogEntityDetails');

  return [entity, loading, error];
};
