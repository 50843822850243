import { handleActions } from 'redux-actions';
import jsCookie from 'js-cookie';
import config from '../../config';
import { changeLocalize } from './actions';

const curentCulture = jsCookie.get('culture');

const initialState = {
  currentCulture: curentCulture || config.languageDefault || 'en',
};

export default handleActions(
  {
    [changeLocalize]: (state, { payload }) => ({
      ...state,
      currentCulture: payload,
    }),
  },
  initialState,
);
