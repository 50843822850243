import { Loader } from '@cluedin/form';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useTheme } from '@cluedin/theme';

import BreadCrumbMobile from './BreadCrumbMobile';
import BreadCrumbWrapper from './BreadCrumbWrapper';
import BreadCrumbSeparator from './BreadCrumbSeparator';
import BreadCrumbItem from './BreadCrumbItem';
import BreadCrumbText from './BreadCrumbText';

const BreadCrumbs = ({
  pillarName,
  currentModule,
  currentTitle,
  pillarPath,
  pillarId,
  crumbPath,
  LinkComponent,
  onNavigate,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const crumbs = Array.isArray(currentTitle)
    ? currentTitle
    : _.compact([currentTitle]);
  const hideModule = currentModule?.hideModule;

  const crumbsWithModule =
    currentModule && !hideModule
      ? [
          {
            to: `${pillarPath}${currentModule.path}`,
            text: formatMessage({
              id: currentModule.name || currentModule.displayName,
            }),
          },
          ...crumbs,
        ]
      : [...crumbs];

  const lastItem =
    crumbsWithModule.length > 0 ? _.last(crumbsWithModule) : null;

  const parentLast = _.find(crumbsWithModule || [], (c, i) => {
    return i === crumbsWithModule.length - 2;
  });

  const allButLastAndParent = _.dropRight(crumbsWithModule, 2);

  return (
    <BreadCrumbWrapper data-e2e="breadcrumb-wrapper" theme={theme}>
      <BreadCrumbItem
        className="breadcrumb-item-pillar"
        data-e2e="breadcrumb-item-pillar"
      >
        <LinkComponent to={crumbPath || pillarPath}>
          {formatMessage({
            id: pillarId || pillarName,
          })}
        </LinkComponent>
      </BreadCrumbItem>

      {((allButLastAndParent && allButLastAndParent.length > 0) ||
        parentLast) && (
        <BreadCrumbMobile
          theme={theme}
          onNavigate={onNavigate}
          crumbs={allButLastAndParent}
          parentLast={parentLast}
          LinkComponent={LinkComponent}
        />
      )}

      {parentLast && (
        <div className="breadcrumb-item-part">
          <BreadCrumbSeparator />
          <BreadCrumbItem
            key={`crumb-parent`}
            data-e2e={`breadcrumb-parent-last`}
          >
            {parentLast.isLoading ? (
              <Loader />
            ) : (
              <BreadCrumbText LinkComponent={LinkComponent} item={parentLast} />
            )}
          </BreadCrumbItem>
        </div>
      )}

      {lastItem && (
        <div className="breadcrumb-item-part-last">
          <BreadCrumbSeparator />
          <BreadCrumbItem key={`crumb-last`} data-e2e={`breadcrumb-item-last`}>
            {lastItem.isLoading ? (
              <Loader />
            ) : (
              <BreadCrumbText LinkComponent={LinkComponent} item={lastItem} />
            )}
          </BreadCrumbItem>
        </div>
      )}
    </BreadCrumbWrapper>
  );
};

export default BreadCrumbs;
