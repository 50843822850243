import styled from 'styled-components';

export const EntityCodePanelWrapper = styled.div`
  line-height: 2.6;
  margin: 8px 16px;
  max-width: 100%;
`;

export const EntityCodePanelHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & svg path {
    fill: ${({ themePrimary }) => themePrimary && themePrimary};
    path: ${({ themePrimary }) => themePrimary && themePrimary};
  }
`;

export const EntityCodePanelHeading = styled.h4`
  font-weight: bold;
  margin: 0;
`;

export const OriginCodeWrapper = styled.div`
  background-color: rgba(41, 162, 154, 0.05);
  display: inline-block;
  flex-wrap: wrap;
  line-height: 1.6;
  margin-bottom: 16px;
  overflow-wrap: break-word;
  padding: 8px;
  width: 100%;
  max-width: calc(100% - 16px);
  word-wrap: break-word;
`;

export const Divider = styled.div`
  background-color: #d7d7d8;
  content: '';
  height: 1px;
  margin: 12px 0;
`;

export const EntityCodePanelCodesList = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
`;
