import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';

const ALL_CONNECTOR = gql`
  query getAllConnectors {
    inbound {
      id
      connectors {
        id
        name
        icon
        about
        authMethods
        createdDate
        domain
        guide
        icon
        isEnabled
        isNew
        properties
        supportsWebHooks
        type
        streamModes
      }
    }
  }
`;

export const useQueryConnectors = () => {
  const { data, loading, error, refetch } = useQuery(ALL_CONNECTOR);

  const connectors = get(data, 'inbound.connectors', []).filter(
    (c) => c.type === 'Connector',
  );

  return [connectors, loading, error, refetch];
};

export const useQueryConnectorsIngestions = () => {
  const { data, loading, error, refetch } = useQuery(ALL_CONNECTOR);

  const connectors = get(data, 'inbound.connectors', []).filter(
    (c) => c.type !== 'Connector',
  );

  return [connectors, loading, error, refetch];
};
