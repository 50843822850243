import { useSpring, animated } from '@react-spring/web';
import PropTypes from 'prop-types';

import { useMeasure } from '../../hooks/useMeasure';

export const CollapseElementAnimated = ({
  children,
  isVisible,
  innerStyles = {},
  duration = 200,
  style: stylesFromProps = {},
}) => {
  const [measureRef, { height: elementHeight }] = useMeasure();

  const animationStyles = useSpring({
    scY: isVisible ? -1 : 1,
    opacity: isVisible ? 1 : 0,
    visibility: isVisible ? 'visible' : 'hidden',
    config: { duration },
    maxHeight: isVisible ? `${elementHeight}px` : '0px',
  });

  return (
    <animated.div
      style={{
        ...animationStyles,
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1,
        background: 'white',
        ...stylesFromProps,
      }}
    >
      <div
        ref={measureRef}
        style={{
          boxShadow: '0px 2px 5px 1px rgba(158,158,158,.7)',
          ...innerStyles,
        }}
      >
        {children}
      </div>
    </animated.div>
  );
};

CollapseElementAnimated.propTypes = {
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
