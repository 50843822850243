import { useMemo, useState } from 'react';
import { injectIntl } from '@cluedin/locale';

import { getClassicValidationMessage } from '../../core/validation/messages';
import { usePaginatedVocabularies } from '../../dataCatalog/hooks/usePaginatedVocabularies';
import { VocabularySelector } from '../../dataCatalog/components/containers/VocabularySelectAndCreate';

export const CreateSimpleVocabularyOrSelect = injectIntl(
  ({ intl, onChange, disabled, isLoading, value = {} }) => {
    const [tempVal, setTempVal] = useState();

    const {
      loadMore,
      vocabularies,
      totalVocabularies,
      setSearchVocabularies,
      isLoadingVocabularies,
    } = usePaginatedVocabularies({
      loadMoreLabel: intl.formatMessage({
        id: 'data-source-mapping-vocab-select-loadMore',
      }),
    });

    const vocabularyName = useMemo(() => value?.vocabularyName, [value]);
    const keyPrefix = useMemo(() => value?.keyPrefix, [value]);
    const id = useMemo(() => value?.vocabularyId, [value]);

    const adaptedVocabularySelectValue = useMemo(() => {
      return vocabularyName && keyPrefix
        ? {
            label: vocabularyName ?? '',
            value: id || keyPrefix || '',
          }
        : undefined;
    }, [vocabularyName, keyPrefix, id]);

    const newVocabularyValidationError =
      tempVal?.vocabularyName &&
      getClassicValidationMessage(tempVal.vocabularyName, 100);

    const adaptedTempSelectValue = useMemo(() => {
      return tempVal?.vocabularyName && tempVal?.keyPrefix
        ? {
            label: tempVal?.vocabularyName ?? '',
            value: tempVal?.keyPrefix,
          }
        : undefined;
    }, [tempVal]);

    return (
      <>
        <VocabularySelector
          isCreatable={false}
          data={vocabularies}
          disabled={disabled}
          onChange={onChange}
          isClearable={false}
          loadMore={loadMore}
          setTempVal={setTempVal}
          total={totalVocabularies}
          hasError={!!newVocabularyValidationError}
          value={adaptedTempSelectValue || adaptedVocabularySelectValue}
          setSearchName={setSearchVocabularies}
          readOnly={isLoadingVocabularies || isLoading}
          isLoading={isLoadingVocabularies || isLoading}
        />
        {newVocabularyValidationError && (
          <div style={{ marginBottom: '-16px' }}>
            {newVocabularyValidationError}
          </div>
        )}
      </>
    );
  },
);
