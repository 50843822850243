import { memo, useId, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Toggle } from '@cluedin/form';

import { FilterIconButton } from '../../../composites/FilterIconButton';
import { useGoldenRecordList } from '../../GoldenRecordListContext';
import { RecordListFilters } from './RecordListFilters';
import RecordListRelevanceFilter from './RecordListRelevanceFilter';
import { RecordListFiltersTitleWitResultsInfoWrapper } from './RecordListFilters/RecordListFilters.styles';
import { RecordListFiltersByRuleBuilder } from './RecordListFilters/RecordListFiltersByRuleBuilder';

const RecordListFiltersTopToolbar = styled.div`
  background: #fff;
  display: flex;
  width: '100%';
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  padding-bottom: 25px;
`;

const RecordListFiltersToolbarLeft = styled.div`
  background-color: #fff;
  gap: 15px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const RecordListFiltersToolbarRight = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const RecordListFiltersToolbar = ({ hideFilter }) => {
  const intl = useIntl();
  const hideFiltersTooltipId = useId();

  const {
    advancedFiltersMode,
    onChangeFIlterMode,
    handleSetFilterMode,
    listOptions: { filters, setFilters },
  } = useGoldenRecordList();

  useEffect(() => {
    return () => {
      handleSetFilterMode(false);
    };
  }, []);

  const onSortTypeChange = (value) => {
    const updatedFilters = {
      ...filters,
      sortBy: null,
      sortDirection: null,
      sortType: value,
    };

    setFilters(updatedFilters);
  };

  return (
    <div style={{ padding: 16, minHeight: 185, minWidth: 400 }}>
      <RecordListFiltersTopToolbar className="RecordListFiltersTopToolbar">
        <RecordListFiltersToolbarLeft>
          <RecordListFiltersTitleWitResultsInfoWrapper>
            <h3 style={{ margin: 0 }}>
              {intl.formatMessage({ id: 'module-goldenRecordList-filters' })}
            </h3>
          </RecordListFiltersTitleWitResultsInfoWrapper>

          <Toggle
            inlineLabel
            checked={advancedFiltersMode}
            onChange={onChangeFIlterMode}
            label={intl.formatMessage({
              id: 'module-goldenRecordList-filter-advanced-mode',
            })}
            styles={{
              label: { fontWeight: 400 },
              root: { marginBottom: 0 },
            }}
          />
        </RecordListFiltersToolbarLeft>

        <RecordListFiltersToolbarRight>
          <RecordListRelevanceFilter
            sortType={filters?.sortType}
            onSortChange={onSortTypeChange}
          />

          <TooltipHost
            id={hideFiltersTooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={intl.formatMessage({
              id: 'module-goldenRecordList-hide-filter',
            })}
          >
            <FilterIconButton
              onClick={hideFilter}
              iconProps={{ iconName: 'ChevronUpMed' }}
            />
          </TooltipHost>
        </RecordListFiltersToolbarRight>
      </RecordListFiltersTopToolbar>

      <div>
        {!advancedFiltersMode ? (
          <RecordListFilters />
        ) : (
          <RecordListFiltersByRuleBuilder hideFilter={hideFilter} />
        )}
      </div>
    </div>
  );
};

export default memo(RecordListFiltersToolbar);
