import { FC, useId } from 'react';
import qs from 'query-string';
import { ButtonLink } from '@cluedin/atoms';
import { Ellipsis } from '@cluedin/components';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { IconButton, withConfirmDialog } from '@cluedin/form';
import { IntlFormatters } from 'react-intl';

import { SavedSearch } from '../../../../../hooks/useSavedSearch';
import { UserNameFromId } from '../../../../../../userModule/components/container/UserAvatarFromId';
import { countConditionFilters } from '../../../GoldenRecordListContext/GoldenRecordListContext';

const IconButtonWithConfirm = withConfirmDialog(IconButton);

const SavedSearchFilterColumn: FC<
  SavedSearch & {
    handleDeleteSearch: (id: string) => void;
    formatMessage: IntlFormatters['formatMessage'];
  }
> = ({ id, formatMessage, handleDeleteSearch, filterUrl }) => {
  const disabledReasonTooltipId = useId();

  // for now anybody can delete shared saved search
  // And and none shared searches are visible only for creator so he can remove it
  const isDeleteAllowed = true;

  const savedSearchFilters = qs.parse(decodeURI(filterUrl));

  const providers =
    savedSearchFilters?.providers || savedSearchFilters?.PROVIDERS;
  const tags = savedSearchFilters?.TAGS_NAME;
  const sources = savedSearchFilters?.PROVIDERDEFINITIONIDS_V2;
  const condition =
    (savedSearchFilters?.condition || savedSearchFilters?.CONDITION) &&
    JSON.parse(
      savedSearchFilters?.condition || savedSearchFilters?.CONDITION || '',
    );

  const entityType =
    savedSearchFilters?.entityType ||
    savedSearchFilters?.entitytype ||
    savedSearchFilters.ENTITYTYPE;

  let numberOfFilters =
    condition?.rules?.length > 0 ? countConditionFilters(condition) : 0;

  const hiddenFilters = {
    entityType,
    sources,
    tags,
    providers,
  };

  if (!condition || condition?.rules?.length === 0) {
    Object.keys(hiddenFilters).forEach((k) => {
      // @ts-expect-error as string
      const v = hiddenFilters[k];

      if (v) {
        numberOfFilters += 1;
      }
    });
  }

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <span>{numberOfFilters}</span>

      {!isDeleteAllowed ? (
        <TooltipHost
          id={disabledReasonTooltipId}
          calloutProps={{ gapSpace: 0 }}
          content={formatMessage({
            id: 'module-goldenRecordList-saved-search-action-disabled-reason',
          })}
        >
          <IconButtonWithConfirm
            disabled
            style={{ marginRight: '6px' }}
            iconProps={{ iconName: 'Delete' }}
          />
        </TooltipHost>
      ) : (
        <IconButtonWithConfirm
          style={{ marginRight: '6px' }}
          iconProps={{ iconName: 'Delete' }}
          onConfirm={() => handleDeleteSearch(id)}
          confirmTitle={formatMessage({
            id: 'module-goldenRecordList-delete-saved-search-confirmTitle',
          })}
          confirmMessage={formatMessage({
            id: 'module-goldenRecordList-delete-saved-search-confirmMessage',
          })}
        />
      )}
    </div>
  );
};

type Props = {
  onApplySearch: (savedSearch: SavedSearch) => void;
  handleDeleteSearch: (searchId: string) => Promise<void>;
  formatMessage: IntlFormatters['formatMessage'];
};

export const getColumns = ({
  formatMessage,
  handleDeleteSearch,
  onApplySearch,
}: Props) => [
  {
    displayName: formatMessage({
      id: 'module-goldenRecordList-saved-searches-list-name',
    }),
    Component: (props: SavedSearch) => {
      return (
        <div style={{ overflow: 'hidden' }}>
          <Ellipsis>
            <TooltipHost
              id={props.name}
              calloutProps={{ gapSpace: 0 }}
              content={formatMessage({
                id: 'module-goldenRecordList-apply-search',
              })}
            >
              <ButtonLink
                text={props.name}
                onClick={() =>
                  onApplySearch({
                    filterUrl: props?.filterUrl,
                    id: props?.id,
                    name: props?.name,
                    viewMode: props?.viewMode,
                    columnsConfig: props?.columnsConfig,
                    createdBy: props?.createdBy,
                    created: props?.created,
                  })
                }
              />
            </TooltipHost>
          </Ellipsis>
        </div>
      );
    },
  },
  {
    displayName: formatMessage({
      id: 'module-goldenRecordList-saved-searches-list-author',
    }),
    Component: ({ createdBy }: SavedSearch) => (
      <UserNameFromId author={createdBy} />
    ),
  },
  {
    displayName: formatMessage({
      id: 'module-goldenRecordList-saved-searches-list-filters',
    }),
    Component: (props: SavedSearch) => (
      <SavedSearchFilterColumn
        {...props}
        formatMessage={formatMessage}
        handleDeleteSearch={handleDeleteSearch}
      />
    ),
  },
];
