import React from 'react';
import PropTypes from 'prop-types';
import PageStructureHeaderStyle from './PageStructureHeader.style';

const PageStructureHeader = ({ title, children }) => (
  <div style={PageStructureHeaderStyle.container}>
    <div style={PageStructureHeaderStyle.titleContainer}>
      <div style={PageStructureHeaderStyle.title}>{title}</div>
    </div>
    <div>{children}</div>
  </div>
);

PageStructureHeader.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default PageStructureHeader;
