import React from 'react';
import styled from 'styled-components';
import { AvatarWithName } from 'uxi/Img';
import { withIntegrationFromId } from '../../../../integration/components/hocs/withIntegrationFromId';

const EntitySourceAccountLine = styled.li`
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const EntitySourceAccountLogo = styled.img`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
`;

const EntitySourceAccountItem = ({
  AccountInformation,
  ProviderName = '',
  integration,
}) => {
  const accountDisplayName =
    AccountInformation?.AccountDisplay || AccountInformation?.AccountId || '–';

  const displayName = accountDisplayName;
  const iconSrc = integration?.Icon || null;

  const icon = (
    <EntitySourceAccountLogo
      src={iconSrc}
      alt={ProviderName}
      style={{ overflow: 'hidden' }}
    />
  );

  return (
    <EntitySourceAccountLine>
      <AvatarWithName
        isSquare
        icon={icon}
        name={displayName}
        style={{ width: '100%' }}
        nameStyle={{
          width: 'calc(100% - 32px)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      />
    </EntitySourceAccountLine>
  );
};

export default withIntegrationFromId(EntitySourceAccountItem, (props) => {
  return {
    integrationId: props.ProviderId,
  };
});
