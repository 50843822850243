import styled from 'styled-components';

export const FormattedEntityCodeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FormattedEntityCodeInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const FormattedEntityCodeActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & svg path {
    fill: ${({ themePrimary }) => themePrimary && themePrimary};
    path: ${({ themePrimary }) => themePrimary && themePrimary};
  }
`;
