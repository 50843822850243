import { handleActions } from 'redux-actions';
import {
  createOrgAndUserActions,
  fetchDataShardActions,
} from '../actions/createOrgAndUser';

const initialState = {
  error: false,
  done: false,
  isFetching: false,
  dataShards: null,
  isFetchingDataShards: false,
  invalidDataShards: false,
};

export default handleActions(
  {
    [createOrgAndUserActions.request]: (state) => ({
      ...state,
      error: false,
      done: false,
      isFetching: true,
    }),
    [createOrgAndUserActions.invalid]: (state) => ({
      ...state,
      error: true,
      done: false,
      isFetching: false,
    }),
    [createOrgAndUserActions.receive]: (state) => ({
      ...state,
      error: false,
      done: true,
      isFetching: false,
    }),
    [fetchDataShardActions.request]: (state) => ({
      ...state,
      dataShards: null,
      isFetchingDataShards: true,
      invalidDataShards: false,
    }),
    [fetchDataShardActions.receive]: (state, { payload }) => ({
      ...state,
      dataShards: payload,
      isFetchingDataShards: false,
      invalidDataShards: false,
    }),
    [fetchDataShardActions.invalid]: (state) => ({
      ...state,
      dataShards: null,
      isFetchingDataShards: false,
      invalidDataShards: true,
    }),
  },
  initialState,
);
