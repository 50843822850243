import { createAction } from 'redux-actions';
import {
  createAsyncAction,
  defaultAsyncCallWithActions,
} from '../../action/helpers/actionCreator';
import { shoudFetchPublicOrgInfo } from '../public/actions/public';
import { clientId } from '../../config';

import {
  getSSOProviders,
  getAutoFillOffice365,
  getAutoFillGoogle,
  saveSSOConfiguration,
  getCurrentSSOProvider,
} from './data';

export const changeSSOProviderSelection = createAction(
  'SSO_PROVIDERS_SELECTION_CHANGE',
);
export const ssoProvidersActions = createAsyncAction('SSO_PROVIDERS');
export const ssoProviderAutoFillOffice365 = createAsyncAction(
  'SSO_PROVIDERS_AUTOFILL_OFFICE365',
);
export const ssoProviderAutoFillGoogle = createAsyncAction(
  'SSO_PROVIDERS_AUTOFILL_GOOGLE',
);
export const ssoProviderSaveAccount = createAsyncAction(
  'SSO_PROVIDERS_SAVE_ACCOUNT',
);
export const ssoProviderActions = createAsyncAction('GET_CURRENT_SSO_PROVIDER');

export const shouldFetchSSOProviders = defaultAsyncCallWithActions(
  getSSOProviders,
  ssoProvidersActions,
);

export const shouldFetchAutoFillOffice365 = defaultAsyncCallWithActions(
  getAutoFillOffice365,
  ssoProviderAutoFillOffice365,
);
export const shouldFetchAutoFillGoogle = defaultAsyncCallWithActions(
  getAutoFillGoogle,
  ssoProviderAutoFillGoogle,
);
export const shouldSaveSSOProviderConfiguration = defaultAsyncCallWithActions(
  saveSSOConfiguration,
  ssoProviderSaveAccount,
  (dispatch) => {
    dispatch(shoudFetchPublicOrgInfo(clientId));
  },
);

export const shouldFetchSSOValue = defaultAsyncCallWithActions(
  getCurrentSSOProvider,
  ssoProviderActions,
);

export default {
  shouldFetchSSOProviders,
  shouldFetchSSOValue,
  ssoProvidersActions,
  changeSSOProviderSelection,
  shouldFetchAutoFillOffice365,
  shouldFetchAutoFillGoogle,
  shouldSaveSSOProviderConfiguration,
};
