import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { orderedColorMaker } from '../../helpers/colors';

const defaultStyles = {
  width: '100%',
  height: '100%',
};

const DoughnutChart = ({ data, style }) => {
  'r';

  const options = data.options || {};

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Doughnut
        data={{
          ...data,
          datasets: data.datasets.map((ds) => ({
            backgroundColor: ds.data.map((x, i) => orderedColorMaker(i)),
            ...ds,
          })),
        }}
        options={{
          ...options,
          legend: {
            position: 'bottom',
            ...(options.legend ? options.legend : {}),
          },
        }}
        style={{ ...defaultStyles, ...style }}
      />
    </div>
  );
};

DoughnutChart.defaultProps = {
  data: {
    datasets: [
      {
        data: [],
      },
    ],
    options: {},
  },
};

export default DoughnutChart;
