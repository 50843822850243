import React from 'react';

import FileUploadDataSourceSetField from './FileUploadDataSourceSetField';
import FileUploadDataSourceNameField from './FileUploadDataSourceNameField';

const FileUploadOptionForm = ({
  dataSourceName,
  dataSourceSetId,
  newDataSourceSet,
  dataSourceSetName,
  onDataSourceNameChange,
  onChangeDataSourceSetId,
  onDataSourceSetNameChange,
  onChangeNewOrExistingDataSourceSet,
  errorDataSourceName,
  errorDataSetName,
  defaultDataSourceName,
}) => (
  <>
    <FileUploadDataSourceNameField
      defaultDataSourceName={defaultDataSourceName}
      dataSourceName={dataSourceName}
      onDataSourceNameChange={onDataSourceNameChange}
      errorDataSourceName={errorDataSourceName}
    />
    <FileUploadDataSourceSetField
      errorDataSetName={errorDataSetName}
      dataSourceSetId={dataSourceSetId}
      newDataSourceSet={newDataSourceSet}
      dataSourceSetName={dataSourceSetName}
      onChangeDataSourceSetId={onChangeDataSourceSetId}
      onDataSourceSetNameChange={onDataSourceSetNameChange}
      onChangeNewOrExistingDataSourceSet={onChangeNewOrExistingDataSourceSet}
    />
  </>
);

export default FileUploadOptionForm;
