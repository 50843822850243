"use strict";

exports.__esModule = true;
exports.default = void 0;
var common = {
  black: '#000',
  white: '#fff',
  main: 'rgba(28, 49, 63, 1)',
  accent: 'rgba(41, 162, 154, 1)',
  error: '#d13f48',
  danger: '#e66565',
  warning: '#ff9800',
  info: '#3e53c1',
  success: '#009688'
};
var _default = common;
exports.default = _default;