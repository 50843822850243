import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from '@cluedin/locale';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';

import EntityTopologyDataPartPanelLink from '../../../../../../entityTopology/components/composites/EntityTopologyDataPartPanelLink/EntityTopologyDataPartPanelLink';
import { useEntityTopology } from '../../../../../../entityTopology/hooks/useEntityTopology';
import { useGetDataPartNodeInfo } from '../../../../../../entityTopology/hooks/useGetDataPartNodeInfo';

const ExplainLogBestGuessSourceDataPartContainer = ({
  dataPartId,
  entityId,
  dataSource,
  bestGuessSourceData,
}) => {
  const [getTopology, { data, loading, error }] = useEntityTopology(entityId);
  const dataWithMatchingDataPartId = (data?.nodes || [])?.filter(
    (i) => i.dataPartId == dataPartId,
  );
  const currentNode =
    dataWithMatchingDataPartId && dataWithMatchingDataPartId[0];

  const [
    getDataPartNodeInfo,
    {
      data: dataPartNodeInfoData,
      loading: dataPartNodeInfoLoading,
      error: dataPartNodeInfoError,
    },
  ] = useGetDataPartNodeInfo();

  const dataPartPanelData = {
    currentNode: currentNode || {},
    dataPartNodeInfo: dataPartNodeInfoData || {},
  };

  useEffect(() => {
    if (entityId && dataPartId) {
      getDataPartNodeInfo(entityId, dataPartId);
    }
  }, [entityId, dataPartId]);

  useEffect(() => {
    getTopology('');
  }, []);

  let dataSourceToShow;
  if (
    dataSource?.kind?.toLowerCase() === 'deduplicationprojectmerge' &&
    dataSource?.id
  ) {
    dataSourceToShow = (
      <>
        <FormattedMessage id="module-explainLog-sourceLabel" />
        :&nbsp;
        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={`/admin/management/deduplication/detail/${dataSource?.id}`}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {dataSource?.name}
          <div style={{ marginLeft: '6px' }}>
            <Externallink size={12} />
          </div>
        </Link>
      </>
    );
  }
  if (dataSource?.kind?.toLowerCase() === 'clean' && dataSource?.id) {
    dataSourceToShow = (
      <>
        <FormattedMessage id="module-explainLog-sourceLabel" />
        :&nbsp;
        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={`/admin/preparation/new-clean-project/${dataSource?.id}`}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {dataSource?.name}
          <div style={{ marginLeft: '6px' }}>
            <Externallink size={12} />
          </div>
        </Link>
      </>
    );
  }
  if (dataSource?.kind?.toLowerCase() === 'userinput' && dataSource?.id) {
    dataSourceToShow = (
      <>
        <FormattedMessage id="module-explainLog-sourceLabel" />
        :&nbsp;
        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={`/admin/inbound/manual-data-entry/detail/${dataSource?.id}`}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {dataSource?.name}
          <div style={{ marginLeft: '6px' }}>
            <Externallink size={12} />
          </div>
        </Link>
      </>
    );
  }
  if (dataSource?.kind?.toLowerCase() === 'legacyclean') {
    dataSourceToShow = null;
  }

  return (
    dataPartId &&
    dataWithMatchingDataPartId &&
    entityId && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <FormattedMessage id="module-explainLog-recordId" />
          :&nbsp;
          <EntityTopologyDataPartPanelLink
            name={dataPartId}
            recordPanelData={dataPartPanelData}
          />
        </div>

        {dataSource && (
          <div style={{ display: 'flex' }}>{dataSourceToShow}</div>
        )}
      </div>
    )
  );
};

export default injectIntl(ExplainLogBestGuessSourceDataPartContainer);

ExplainLogBestGuessSourceDataPartContainer.propTypes = {
  dataPartId: PropTypes.number,
  entityId: PropTypes.string,
};
