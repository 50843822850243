import React from 'react';
import {
  TabList,
  Tabs,
  TabPanel,
} from '../../../core/components/composites/Tabs';
import LayoutTabMenu from './LayoutTabMenu';
import Layout from './Layout';
import { filterWidgetsByPlaceholderName } from './layoutHelper';
import { useGrid } from '../hooks/useGrid';

const getTabConfig = (tab, widgets = []) => {
  const widgetsForLayout = filterWidgetsByPlaceholderName(
    widgets,
    tab.name,
  ).map((w) => {
    const placeNames = w.place.split('.');

    placeNames.shift();

    return {
      name: w.name,
      place: placeNames.join('.'),
      parameters: w.parameters,
    };
  });

  return {
    includeSuggestedSearches: tab.includeSuggestedSearches,
    layout: {
      name: tab.layout ? tab.layout.name : '',
    },
    widgetsForLayout,
  };
};

const LayoutWithTabs = ({
  grid,
  entityId,
  entity,
  userId,
  layoutName,
  widgets,
}) => (
  <Tabs isMainStyle>
    <TabList>
      <LayoutTabMenu tabs={grid.tabs[0].children} />
    </TabList>
    {(grid.tabs[0].children || []).map((tab) => {
      const tabConfiguration = getTabConfig(tab, widgets);
      const layoutNameWithTab = `${layoutName}-${tab.name || tab.displayName}`;
      // here need to find grids
      const gridForTab = useGrid(tabConfiguration.layout.name);

      return (
        <TabPanel key={tab.name}>
          <div className="cluedIn_container">
            <Layout
              entityId={entityId}
              entity={entity}
              userId={userId}
              layoutName={layoutNameWithTab}
              grid={gridForTab}
            />
          </div>
        </TabPanel>
      );
    })}
  </Tabs>
);

export default LayoutWithTabs;
