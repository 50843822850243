import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { shouldFetchCurrentUsage } from '../../actions';
import PageLoader from '../../../core/components/composites/PageLoader';
import NotEnoughData from '../../../GDPR/components/composites/reports/NotEnoughData';

const enhance = compose(
  lifecycle({
    componentDidMount() {
      const { fetchUsage } = this.props;
      fetchUsage();
    },
  }),
);

const withLoading = compose(
  branch(({ isFetching }) => isFetching, renderComponent(PageLoader)),
  branch(
    ({ isFetching, usage }) => !isFetching && !usage,
    renderComponent(NotEnoughData),
  ),
);

export const withUsage = (Comp, noLoading) => {
  let EnhancedComp;
  if (!noLoading) {
    EnhancedComp = withLoading(Comp);
  }
  EnhancedComp = enhance(EnhancedComp);

  const mapToSelectProps = ({ org: { usage, isFetchingUsage } }) => ({
    isFetching: isFetchingUsage,
    usage,
  });

  const mapDispatchToProps = (dispatch) => ({
    fetchUsage: () => {
      dispatch(shouldFetchCurrentUsage());
    },
  });

  return connect(mapToSelectProps, mapDispatchToProps)(EnhancedComp);
};

export default withUsage;
