import { removeLastCharacter } from './string';

/* eslint-disable */
export const getSubdomain = () => {
  const parts = location.hostname.split('.');
  return parts.shift();
};

export const getParameterByName = (name, url) => {
  const currentUrl = !url ? window.location.href : url;
  const replacedName = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${replacedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(currentUrl);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const createPathWithoutDoubleSlash = (url, path) => {
  if (url.lastIndexOf('/') === url.length - 1) {
    return `${removeLastCharacter(url)}/${path}`;
  }
  return `${url}/${path}`;
};

export default {
  getSubdomain,
  getParameterByName,
  createPathWithoutDoubleSlash,
};
