import { FC, useId } from 'react';
import { PrimaryButton, Toggle } from '@cluedin/form';
import { ResetIcon } from '@cluedin/svgs';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { FormattedMessage } from '@cluedin/locale';
import { useIntl } from 'react-intl';
import { Icon } from '@fluentui/react';
import { useTheme } from '@cluedin/theme';

type GraphHeaderFilterToolbarProps = {
  hideFilters: () => void;
  isShadowNodesHidden: boolean;
  onShadowNodesChange: (value: boolean) => void;
  isTemporalNodesHidden: boolean;
  onTemporalNodesChange: (value: boolean) => void;
  onReset: () => void;
  isFiltersOpened: boolean;
};

export const GraphHeaderFilterToolbar: FC<GraphHeaderFilterToolbarProps> = ({
  hideFilters,
  isShadowNodesHidden,
  onShadowNodesChange,
  isTemporalNodesHidden,
  onTemporalNodesChange,
  onReset,
  isFiltersOpened,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const { formatMessage } = useIntl();

  const hideShadowNodesLabelTooltipId = useId();
  const hideTemporalNodesLabelTooltipId = useId();

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '16px 16px 8px 16px',
        }}
      >
        <Icon
          onClick={hideFilters}
          iconName="ChevronUpMed"
          style={{
            color: themePrimary,
            cursor: 'pointer',
            fontSize: 18,
            marginRight: '8px',
          }}
        />

        <h3>
          <FormattedMessage id="module-entityRelationsGraphV2-filtersLabel" />
        </h3>
      </div>

      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: '0 16px 32px 16px',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            width: 'auto',
          }}
        >
          <div style={{ marginRight: '16px' }}>
            <Toggle
              label={
                <TooltipHost
                  calloutProps={{ gapSpace: 0 }}
                  id={hideShadowNodesLabelTooltipId}
                  content={formatMessage({
                    id: 'module-entityRelationsGraphV2-hideShadowEntitiesTooltip',
                  })}
                >
                  {formatMessage({
                    id: 'module-entityRelationsGraphV2-hideShadowEntitiesLabel',
                  })}
                </TooltipHost>
              }
              name={'isShadowNodesHidden'}
              checked={isShadowNodesHidden}
              onText={formatMessage({
                id: 'module-entityRelationsGraphV2-hideShadowEntitiesEnabled',
              })}
              offText={formatMessage({
                id: 'module-entityRelationsGraphV2-hideShadowEntitiesDisabled',
              })}
              onChange={() => {
                onShadowNodesChange(!isShadowNodesHidden);
              }}
              data-test="entityRelationsGraphV2-hideShadowEntitiesButton"
            />
          </div>
          <div>
            <Toggle
              label={
                <TooltipHost
                  calloutProps={{ gapSpace: 0 }}
                  id={hideTemporalNodesLabelTooltipId}
                  content={formatMessage({
                    id: 'module-entityRelationsGraphV2-hideTemporalEntitiesTooltip',
                  })}
                >
                  {formatMessage({
                    id: 'module-entityRelationsGraphV2-hideTemporalEntitiesLabel',
                  })}
                </TooltipHost>
              }
              name={'isTemporalNodesHidden'}
              checked={isTemporalNodesHidden}
              onText={formatMessage({
                id: 'module-entityRelationsGraphV2-hideTemporalEntitiesEnabled',
              })}
              offText={formatMessage({
                id: 'module-entityRelationsGraphV2-hideTemporalEntitiesDisabled',
              })}
              onChange={() => {
                onTemporalNodesChange(!isTemporalNodesHidden);
              }}
              data-test="entityRelationsGraphV2-hideTemporalEntitiesButton"
            />
          </div>
        </div>

        <PrimaryButton
          rounded
          data-test="entityRelationsGraphV2-resetButton"
          text={formatMessage({
            id: 'module-entityRelationsGraphV2-resetButton',
          })}
          onClick={onReset}
          startIcon={<ResetIcon size={14} />}
        />
      </div>
    </>
  );
};
