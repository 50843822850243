import ceil from 'lodash/ceil';
import divide from 'lodash/divide';
import get from 'lodash/get';
import slice from 'lodash/slice';

const sanitizePageNumber = (list, pageNumber, itemsPerPage) => {
  if (pageNumber < 1) {
    return 1;
  }
  const listCount = get(list, 'length', 0);
  const pageCount = ceil(divide(listCount, itemsPerPage));
  if (pageNumber > pageCount) {
    return pageCount;
  }

  return pageNumber;
};

export const getPage = (list, pageNumber, itemsPerPage) => {
  const page = sanitizePageNumber(list, pageNumber, itemsPerPage);
  const startindex = (page - 1) * itemsPerPage;
  const endIndex = startindex + itemsPerPage;
  return slice(list, startindex, endIndex);
};
