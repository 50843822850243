import { gql, useLazyQuery } from '@apollo/client';

type DataSourceFileMetaData = {
  fileName: string;
  processing: boolean;
  uploading: boolean;
  uploadedPercentage: number;
};

type DataSource = {
  id: string;
  name: string;
  fileMetadata?: DataSourceFileMetaData;
};

const GET_DATA_SOURCE_ID = gql`
  query getDataSourceById($id: ID!) {
    inbound {
      dataSource(id: $id) {
        id
        canBeDeleted
        name
        hasError
        latestErrorMessage
        errorType
        author {
          id
          username
        }

        fileMetadata {
          fileName
          processing
          uploading
          uploadedPercentage
        }
        createdAt
        type

        dataSourceSet {
          id
          name
        }

        sql

        connectionStatus {
          connected
          errorMessage
        }

        dataSets {
          id
          name
          isBridge
          isArchive
          archivedBy
          archivedByUser {
            id
            username
          }
          configuration
          annotationId
          elasticTotal
          expectedTotal
          hasValidationParsingError
          hasError
          fieldMappings {
            originalField
            key
            edges {
              edgeType
              dataSetId
              dataSourceId
              dataSourceGroupId
              entityType
            }
          }
          annotation {
            id
            originEntityCodeKey
            annotationProperties {
              key
            }
          }
          stats {
            total
            successful
            failed
          }
          author {
            id
            username
          }
          createdAt
          updatedAt

          dataSource {
            id
            type
          }
        }

        connectorConfigurationId
        connectorConfiguration {
          id
          name
          accountDisplay
          accountId
          active
          autoSync
          codeName
          configuration
          connector {
            id
            icon
            name
            authMethods
            properties
            streamModes
          }
          createdDate
          entityId
          failingAuthentication
          guide
          helperConfiguration
          providerId
          reAuthEndpoint
          source
          sourceQuality
          stats
          status
          supportsAutomaticWebhookCreation
          supportsConfiguration
          supportsWebhooks
          userId
          userName
          users {
            id
            username
            roles
          }
          webhookManagementEndpoints
          webhooks
        }
      }
    }
  }
`;

export type DataSourceResult = {
  inbound: {
    dataSource: DataSource; // It might be also passed as generic
  };
};

export type DataSourcePayload = {
  id: string;
};

export const useLazyQueryDataSource = (
  dataSourceId: string,
  pollInterval = undefined,
) => {
  const [getDataSource, { data, refetch, loading, error }] = useLazyQuery<
    DataSourceResult,
    DataSourcePayload
  >(GET_DATA_SOURCE_ID, {
    variables: {
      id: dataSourceId,
    },
    fetchPolicy: 'no-cache',
    pollInterval,
  });

  const dataSource = data?.inbound?.dataSource;

  return [
    () => getDataSource(),
    {
      dataSource,
      loading,
      error,
      refetch,
    },
  ] as const;
};
