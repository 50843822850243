import React from 'react';
import { connect } from 'react-redux';
import Slide from 'uxi/internal/Slide';
import Alert from 'uxi/Alert';
import { Flex } from 'uxi/Layout';
import { Close } from 'uxi/Icons';
import styled from 'styled-components';
import { hideAlert } from '../../actions';

const IconWrapper = styled.div`
  cursor: pointer; !important
  zIndex: 9999;
`;

const UserFeedbackContainer = ({ alerts = [], onClose }) => (
  <Slide
    anchor="top"
    direction="bottom"
    in={alerts && alerts.length > 0}
    inAttr={alerts && alerts.length > 0}
    open={alerts && alerts.length > 0}
  >
    <div style={{ zIndex: 9998 }}>
      {alerts.map((alert) => (
        <Flex
          style={{
            maxWidth: '480px',
            margin: '8px auto',
            pointerEvents: 'all',
          }}
        >
          <Alert type={alert.type || 'info'} title={alert.title}>
            <Flex>
              <div style={{ minWidth: '380px' }}>{alert.description}</div>
              <IconWrapper>
                <Close
                  size="12"
                  onClick={() => {
                    onClose(alert);
                  }}
                />
              </IconWrapper>
            </Flex>
          </Alert>
        </Flex>
      ))}
    </div>
  </Slide>
);

const mapStateToProps = ({ coreModule: { alerts } }) => ({
  alerts,
});

const mapDispatchToProps = (dispatch) => ({
  onClose(alert) {
    dispatch(hideAlert(alert));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserFeedbackContainer);
