import getSchemaViewModelFromEntityPropertyKey from './getSchemaViewModelFromEntityPropertyKey';
import { findSource } from '../../integration/selector';

const extractIntegrationNameFromPropertyKey = (propertyKey) => {
  if (propertyKey.indexOf('property-') > -1) {
    return propertyKey.split('property-')[1];
  }

  return propertyKey;
};

export const createPropertyVM = (
  propertyKey,
  propertyValue,
  schema = [],
  integrations = [],
) => {
  const schemaVM = getSchemaViewModelFromEntityPropertyKey(propertyKey, schema);
  const integrationNameFromPropertyKey =
    extractIntegrationNameFromPropertyKey(propertyKey);
  const source = findSource(integrationNameFromPropertyKey, integrations);

  if (schemaVM && schemaVM.propertyInSchema) {
    return {
      displayName: schemaVM.propertyInSchema.DisplayName,
      property: propertyKey,
      content: schemaVM.component,
      normalizedKeyNameForForm: schemaVM.normalizedKeyNameForForm,
      value: propertyValue,
      schema: schemaVM.propertyInSchema,
      hasSchema: true,
      source,
    };
  }

  return {
    displayName: propertyKey,
    property: propertyKey,
    content: (value) => value,
    value: propertyValue,
    schema: null,
    hasSchema: false,
    source,
  };
};

export default createPropertyVM;
