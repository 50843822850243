import { useState } from 'react';
import { Loader } from '@cluedin/atoms';
import HelpInfo from '../composites/HelpInfo';
import { FormattedMessage } from '@cluedin/locale';
import { useQueryDoc } from '../../hooks/useQueryTopicDetail';
import WrapperMD from '../composites/WrapperMD';
import HelpExtraContent from '../composites/HelpExtraContent';
import { withHelpFeatureFlag } from '../../hocs/withHelpFeatureFlag';

const HelpInfoFromService = ({
  title,
  topicId,
  contentTypeExtra = 'slidePanel',
  type = 'topic',
  noExtra,
  modalHeight,
  modalWidth,
}) => {
  const [data, loading] = useQueryDoc(topicId, type);
  const [open, setOpen] = useState(false);

  return (
    <>
      <HelpInfo title={title}>
        {loading && <Loader />}
        {!loading && (
          <WrapperMD dangerouslySetInnerHTML={{ __html: data?.excerpt }} />
        )}
        {!noExtra && (
          <div style={{ marginTop: '4px' }}>
            <a
              onClick={() => {
                setOpen(true);
              }}
            >
              <FormattedMessage id="module-help-tooltipShowMore" />
            </a>
          </div>
        )}
      </HelpInfo>
      <HelpExtraContent
        noExtra={noExtra}
        contentTypeExtra={contentTypeExtra}
        open={open}
        setOpen={setOpen}
        data={data}
        modalHeight={modalHeight}
        modalWidth={modalWidth}
      />
    </>
  );
};

export default withHelpFeatureFlag(HelpInfoFromService);
