import React from 'react';
import SchemaListSelectionWithFilters from '../composites/SchemaListSelectionWithFilters';
import { withSchema } from '../Hocs/withSchema';

const QuickSchemaSearchContainer = ({
  schema,
  onSchemaSelect,
  unavailableProperties,
}) => {
  return (
    <SchemaListSelectionWithFilters
      onSchemaSelect={onSchemaSelect}
      schema={schema}
      unavailableProperties={unavailableProperties}
    />
  );
};

export default withSchema(QuickSchemaSearchContainer);
