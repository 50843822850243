import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import { Integration } from 'uxi/Icons';
import DashboardWrapper from '../../../core/components/composites/dashboard/DashboardWrapper';

const IntegrationWrapperPage = ({ children }) => (
  <DashboardWrapper
    browserTitle="CluedIn - Integrations"
    icon={<Integration color="#2fb3b3" />}
    title={
      <FormattedMessage
        id="module-integration-integrationTitle"
        defaultMessage="Integrations"
      />
    }
  >
    {children}
  </DashboardWrapper>
);

IntegrationWrapperPage.displayName = 'IntegrationWrapperPage';

IntegrationWrapperPage.propTypes = {
  children: PropTypes.any,
};

IntegrationWrapperPage.defaultProps = {
  children: null,
  pathname: '',
};

export default IntegrationWrapperPage;
