import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import { useOrganizationFeatureFlagOption } from '../featureFlag/hooks/useOrganizationFeatureFlag';

const { governance } = actionsConfiguration;

export default {
  pillar: 'governance',
  name: 'retention',
  displayName: <FormattedMessage id="retention" />,
  path: '/retention',
  featureFlag: 'DataRetention',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Dataretention',
        name: <FormattedMessage id="retention" />,
        link: '/admin/governance/retention',
        claims: governance.dataRetention.policy.view,
      },
    ],
  },
  routes,
};
