"use strict";

exports.__esModule = true;
exports.GhostEntityHistoryLoaderWrapper = exports.GhostEntityHistoryLoaderVersions = exports.GhostEntityHistoryLoaderHeaderItem = exports.GhostEntityHistoryLoaderHeader = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var GhostEntityHistoryLoaderWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background: #ffffff;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-top: -10px;\n  position: relative;\n"])));
exports.GhostEntityHistoryLoaderWrapper = GhostEntityHistoryLoaderWrapper;
var GhostEntityHistoryLoaderHeader = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  display: flex;\n  border: 1px solid #ececec;\n  border-bottom: none;\n  justify-content: space-between;\n  padding: 14px 30px;\n"])));
exports.GhostEntityHistoryLoaderHeader = GhostEntityHistoryLoaderHeader;
var GhostEntityHistoryLoaderHeaderItem = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n"])));
exports.GhostEntityHistoryLoaderHeaderItem = GhostEntityHistoryLoaderHeaderItem;
var GhostEntityHistoryLoaderVersions = _styledComponents.default.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  color: rgb(55, 55, 58);\n  display: flex;\n  font-size: 16px;\n  font-weight: 600;\n  height: 100%;\n"])));
exports.GhostEntityHistoryLoaderVersions = GhostEntityHistoryLoaderVersions;