import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';

import { RelationIcon } from '../../D3EntityRelationsGraph/svgs';

import { SelectedEntityNode } from '../../types';
import { EntityNodePanelDetailWrapper } from './EntityNodePanelDetailWrapper';
import { getIcon } from '../../D3EntityRelationsGraph/getIcon';
import { addTokenToImage } from '../../../../entity/helper';
import { getFullToken } from '../../../../core/helpers/storage';
import EntityTypeAvatarConfigurationLinkContainer from '../../../../entityTypes/components/containers/EntityTypeAvatarConfigurationLinkContainer';
import { PanelDetailImage } from './PanelDetailImage';
import NotConfiguredEntityTypeIcon from '../../../../entityTypes/components/composites/NotConfiguredEntityTypeIcon';

type EntityNodePanelDetailProps = {
  selectedNode: SelectedEntityNode;
};

const isImageSourceFromCluedIn = (imageSource: string) => {
  return imageSource?.includes('/static/media/');
};

const hostStyles = { root: { display: 'inline-block' } };

export const EntityNodePanelDetail: FC<EntityNodePanelDetailProps> = ({
  selectedNode,
}) => {
  const tooltipId = useId();
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;
  const nodePreviewImage = selectedNode?.previewImage?.uri;
  const icon: HTMLImageElement | null = getIcon(selectedNode?.icon);
  const nodeKind = selectedNode?.nodeKind?.toLowerCase();
  const nodeName = selectedNode?.name ?? (
    <FormattedMessage id="module-entityRelationsGraphV2-noName" />
  );
  const nodeDisplayName = selectedNode?.displayName;
  const nodeEntityType = selectedNode?.entityType ?? selectedNode?.type;
  const entityId = selectedNode?.entityId ?? selectedNode?.id;

  let nodeIcon;
  if (nodePreviewImage) {
    nodeIcon = addTokenToImage(nodePreviewImage, getFullToken()?.token);
  } else {
    if (nodeKind === 'groupedrelations') {
      nodeIcon = RelationIcon;
    } else {
      if (icon instanceof HTMLImageElement) {
        const imgSrc = icon?.src;
        nodeIcon = imgSrc;
      } else {
        const imgSrcRegex = /<img src="([^"]+)">/;
        const match = (icon as unknown as HTMLElement)?.outerHTML.match(
          imgSrcRegex,
        );

        if (match && match[1]) {
          nodeIcon = match[1];
        } else {
          nodeIcon = null;
        }
      }
    }
  }

  const alignToCenter = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  };

  const isImageBorderRound = isImageSourceFromCluedIn(nodeIcon);
  return (
    <EntityNodePanelDetailWrapper themePrimary={themePrimary}>
      <div
        style={{
          ...alignToCenter,
          width: '100%',
        }}
      >
        <div
          style={{
            ...alignToCenter,
            flexDirection: 'column',
            padding: '40px 20px 20px 20px',
            width: '100%',
          }}
        >
          {nodeIcon === null ? (
            <NotConfiguredEntityTypeIcon
              entityType={selectedNode?.entityType}
              size={48}
              color="inherit"
            />
          ) : (
            <PanelDetailImage
              src={nodeIcon}
              alt={nodeDisplayName}
              isImageBorderRound={isImageBorderRound}
            />
          )}

          <div
            style={{
              ...alignToCenter,
              flexDirection: 'column',
              padding: '10px 0',
            }}
          >
            <Link
              target="_blank"
              rel="noreferrer noopener"
              to={`/entity/${entityId}`}
              style={{ fontWeight: 700 }}
            >
              {nodeName}
            </Link>
            {!nodeDisplayName || nodeIcon === null ? (
              <TooltipHost
                content={
                  <div style={{ padding: '6px' }}>
                    <div>
                      <FormattedHTMLMessage
                        id="module-entityType-notConfigureEntityTypeMessage"
                        values={{
                          entityType: selectedNode?.entityType,
                        }}
                      />
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`/admin/management/entity-types/edit?entityType=${selectedNode?.entityType}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Externallink size={12} />
                        <span style={{ marginLeft: '6px' }}>
                          <FormattedMessage id="module-entityType-configureEntityType" />
                        </span>
                      </a>
                    </div>
                  </div>
                }
                id={tooltipId}
                calloutProps={{ gapSpace: 0 }}
                styles={hostStyles}
              >
                <div
                  style={{
                    ...alignToCenter,
                    flexDirection: 'column',
                    padding: '10px 0',
                  }}
                >
                  <EntityTypeAvatarConfigurationLinkContainer
                    entityType={nodeEntityType}
                    entity={null}
                  />
                </div>
              </TooltipHost>
            ) : (
              <div
                style={{
                  ...alignToCenter,
                  flexDirection: 'column',
                  padding: '10px 0',
                }}
              >
                <EntityTypeAvatarConfigurationLinkContainer
                  entityType={nodeEntityType}
                  entity={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </EntityNodePanelDetailWrapper>
  );
};
