import { useCallback, useMemo, useEffect, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import {
  PrimaryButton,
  FormDecorator as FormDecorateComponent,
  FormExplanationRequiredFields,
  withConfirmDialog,
  CancelButton,
  PanelFooter,
  PanelContent,
} from '@cluedin/form';
import { ImportFromFileIcon } from '@cluedin/atoms';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import * as fileDataSourceSizeParser from 'filesize-parser';
import { url } from '../../../../../config';
import FileUploadDataSourceSetField from '../../../../dataSourceV2/components/containers/forms/FileUploadDataSourceSetField';
import { useCreateDataSetGroup } from '../../../../dataSourceV2/hooks/useCreateDataSetGroup';
import { getClassicValidationMessage } from '../../../../core/validation/messages';
import { useOrganizationFeatureFlagOption } from '../../../../featureFlag/hooks/useOrganizationFeatureFlag';
import { useMultiUploader } from '../MultiUploader/MultiUploaderProvider';
import { FileAcceptedList } from './FileAcceptedList';
import {
  DropZoneArea,
  DropZoneTitle,
  DropZoneTextSmall,
  DropZoneLimitText,
} from './FileUploadComponents';
import { some } from 'lodash';
import { FileDataSource } from './types';
import { FileRejectedList } from './FileRejectedList';
import { getSupportedFormat, getSupportedFileFormat } from './utils';
import { AddMoreFileButton } from './AddMoreFileButton';
import { ErrorFile } from '../../../types';

let sizeInBytes: number;
let sizeLimit: string;
let filesLimit: number;

try {
  if (url.sizeLimit) {
    sizeLimit = url.sizeLimit;
    filesLimit = Number(url.filesLimit);
    sizeInBytes = fileDataSourceSizeParser(sizeLimit.toLowerCase());
  }
} catch (e) {
  console.error(e);
}

export const FileUploader = ({
  onClose,
  defaultDataSetGroupId,
  onUploadCompleted,
}: {
  onClose: () => void;
  defaultDataSetGroupId: string;
  onUploadCompleted: () => void;
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;
  const hasBasicXLSX = useOrganizationFeatureFlagOption('BasicXLSX');
  const [createDataSourceGroup, { id: newDataSourceGroupId, error }] =
    useCreateDataSetGroup();

  const [fileDataSources, setFileDataSources] = useState<FileDataSource[]>([]);
  const [unSupportedFileDataSources, setUnSupportedFileDataSources] = useState<
    FileRejection[]
  >([]);
  const [errorFileUpload, setErrorFileUpload] = useState<ErrorFile[]>([]);

  const uploaderContext = useMultiUploader();

  const [dataSourceSetName, setDataSourceSetName] = useState('');
  const [newDataSourceSet, setNewDataSourceSet] = useState(
    defaultDataSetGroupId ? false : true,
  );
  const [selectedDataSourceSetId, setDataSourceSetId] = useState(
    defaultDataSetGroupId ? defaultDataSetGroupId : '',
  );
  const errorDataSourceSetMessage = getClassicValidationMessage(
    dataSourceSetName,
    255,
  );

  const hasInvalidMessage = some(
    fileDataSources,
    (f) => f.dataSourceNameErrorMessage,
  );

  const isValidGroup = newDataSourceSet
    ? dataSourceSetName && !errorDataSourceSetMessage
    : selectedDataSourceSetId;

  const validUpload = useMemo(() => {
    if (!isValidGroup || hasInvalidMessage) {
      return false;
    }

    if (filesLimit > 0 && fileDataSources.length > filesLimit) {
      return false;
    }

    return fileDataSources.length > 0;
  }, [fileDataSources, isValidGroup, hasInvalidMessage]);

  const onDrop = useCallback((f: File[]) => {
    const fileDataSources = f.map((f) => {
      return {
        file: f,
        noHeaders: false,
        dataSourceName: f.name,
        dataSourceNameError: '',
      };
    });

    setFileDataSources((prev = []) => [...prev, ...fileDataSources]);
  }, []);

  const handleChangeHasHeaders = useCallback(
    (idx: number) => {
      setFileDataSources((prev) =>
        prev.map((i, fileIdx) =>
          fileIdx !== idx ? i : { ...i, noHeaders: !i.noHeaders },
        ),
      );
    },
    [fileDataSources],
  );

  const { open, fileRejections, getRootProps, getInputProps, inputRef } =
    useDropzone({
      accept: getSupportedFileFormat(hasBasicXLSX).join(),
      onDrop,
      noClick: true,
      multiple: true,
      maxSize: sizeInBytes,
    });

  // !! Workaround as useDropzone doesn't provide api to clear fileRejections array
  useEffect(() => {
    if (fileRejections) {
      setUnSupportedFileDataSources(fileRejections);
    }
  }, [fileRejections]);

  const handleRemoveFile = useCallback(
    (index: number) => {
      const filteredFileDataSources = fileDataSources.filter(
        (f, i) => i !== index,
      );

      setFileDataSources([...filteredFileDataSources]);
    },
    [inputRef, fileDataSources],
  );

  const supportedFormats = getSupportedFormat(hasBasicXLSX);

  const uploadFile = useCallback(
    (selectedDataSourceSetId: string) => {
      const allPromise = fileDataSources.map(async (f) => {
        return uploaderContext?.uploadFile(
          f.file,
          {
            noHeaders: f.noHeaders,
            dataSourceName: f.dataSourceName,
            dataSourceGroupId: selectedDataSourceSetId,
            dataSourceGroupName: dataSourceSetName,
          },
          () => {
            setTimeout(() => {
              onUploadCompleted?.();
            }, 2000);
          },
        );
      });

      Promise.all(allPromise).then((allResult) => {
        // @ts-expect-error Filter Boolean not supported
        const allErrors: ErrorFile[] = (allResult || [])
          .map((result) => {
            if (result?.error) {
              return {
                file: result?.file,
                error: result?.errorObject,
              };
            }
            return;
          })
          .filter(Boolean);

        if (allErrors?.length > 0) {
          setErrorFileUpload([...allErrors]);
        } else {
          setFileDataSources([]);
          onClose();
        }
      });
    },
    [fileDataSources, validUpload, dataSourceSetName, selectedDataSourceSetId],
  );

  useEffect(() => {
    if (newDataSourceGroupId) {
      uploadFile(newDataSourceGroupId);
    }
  }, [newDataSourceGroupId]);

  const onUploadFileDataSources = useCallback(async () => {
    if (validUpload) {
      if (newDataSourceSet) {
        createDataSourceGroup({
          name: dataSourceSetName,
        });
      } else {
        uploadFile(selectedDataSourceSetId);
      }
    }
  }, [dataSourceSetName, validUpload, selectedDataSourceSetId]);

  const isValidCancel = !!dataSourceSetName;
  const ButtonWithConfirm = isValidCancel
    ? withConfirmDialog(CancelButton)
    : CancelButton;

  const reset = () => {
    setFileDataSources([]);
    setDataSourceSetName('');
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <>
      <PanelContent>
        <FormDecorateComponent
          label={<FormattedMessage id="module-uploadFile-addFiles" />}
          helpText={
            <>
              <FormattedMessage
                id="module-uploadFile-add-file-information"
                values={{
                  supportedFormats: supportedFormats.join(', '),
                }}
              />
              {filesLimit > 0 && (
                <b>
                  <br />
                  <FormattedMessage
                    id="module-uploadFile-count-limit"
                    values={{ filesLimit }}
                  />
                </b>
              )}
            </>
          }
          isRequired
        >
          {fileDataSources.length > 0 && (
            <>
              <FileAcceptedList
                filesLimit={filesLimit > 0 ? filesLimit : undefined}
                fileDataSources={fileDataSources}
                setFileDataSources={setFileDataSources}
                handleRemoveFile={handleRemoveFile}
                onClearFiles={() => setFileDataSources([])}
                errorFileUpload={errorFileUpload}
                handleChangeHasHeaders={handleChangeHasHeaders}
              />
              <div
                style={{ textAlign: 'left', margin: '12px 0' }}
                {...getRootProps()}
                data-test={'dropZoneFileUploadAddFile'}
              >
                <input
                  className="__test_file_uploadInput"
                  {...getInputProps()}
                />
                <AddMoreFileButton
                  open={open}
                  filesLimit={filesLimit}
                  disabled={
                    filesLimit > 0 && fileDataSources.length >= filesLimit
                  }
                />
              </div>
            </>
          )}
          {unSupportedFileDataSources?.length > 0 && (
            <>
              <FileRejectedList
                unSupportedFileDataSources={unSupportedFileDataSources}
                style={{ marginBottom: '24px' }}
                onClearFiles={() => setUnSupportedFileDataSources([])}
                handleRemoveFile={(index: number) => {
                  const unSupportedFileDataSourcesUpdate =
                    unSupportedFileDataSources.filter((f, i) => i !== index);

                  setUnSupportedFileDataSources([
                    ...unSupportedFileDataSourcesUpdate,
                  ]);
                }}
              />
            </>
          )}
          {fileDataSources.length > 0 && !defaultDataSetGroupId && (
            <FileUploadDataSourceSetField
              value={dataSourceSetName}
              dataSourceSetId={selectedDataSourceSetId}
              newDataSourceSet={newDataSourceSet}
              onChangeDataSourceSetId={setDataSourceSetId}
              onDataSourceSetNameChange={(e: Event, v: string) =>
                setDataSourceSetName(v)
              }
              onChangeNewOrExistingDataSourceSet={setNewDataSourceSet}
              errorDataSetName={errorDataSourceSetMessage}
            />
          )}
          {fileDataSources.length === 0 &&
            unSupportedFileDataSources?.length === 0 && (
              <DropZoneArea
                {...getRootProps()}
                theme={theme}
                data-test={'dropZoneFileUpload'}
              >
                <input
                  className="__test_file_uploadInput"
                  {...getInputProps()}
                />

                <ImportFromFileIcon size={30} color={themePrimary} />

                <DropZoneTitle themePrimary={themePrimary}>
                  <FormattedMessage id="module-uploadFile-zone-title" />
                </DropZoneTitle>

                <DropZoneTextSmall>
                  <FormattedMessage
                    id="data-source-upload-file-zone-hint"
                    values={{
                      supportedFormats: supportedFormats.join(', '),
                    }}
                  />
                </DropZoneTextSmall>

                <PrimaryButton data-test={'fileUploadButton'} onClick={open}>
                  <FormattedMessage id="module-uploadFile-zone-button" />
                </PrimaryButton>

                {sizeLimit && (
                  <DropZoneLimitText>
                    <FormattedMessage id="data-source-upload-file-maximum-size" />{' '}
                    <span>{sizeLimit}</span>
                  </DropZoneLimitText>
                )}
              </DropZoneArea>
            )}
        </FormDecorateComponent>
        <FormExplanationRequiredFields />
      </PanelContent>
      <PanelFooter align="space-between">
        <span style={{ color: theme.palette.red }}>
          {filesLimit > 0 && fileDataSources.length > filesLimit && (
            <FormattedMessage
              id="module-uploadFile-count-limit-exceeded"
              values={{ filesLimit }}
            />
          )}
        </span>
        <div>
          <ButtonWithConfirm
            rounded
            confirmTitle={
              <FormattedMessage id="module-uploadFile-confirm-lost-changes-title" />
            }
            confirmMessage={
              <FormattedMessage id="module-uploadFile-confirm-lost-changes-message" />
            }
            onConfirm={handleCancel}
            style={{ marginRight: '8px' }}
            onClick={handleCancel}
            data-test={'newDataSourceGroupCancelButton'}
          >
            <FormattedMessage id="module-uploadFile-cancel" />
          </ButtonWithConfirm>

          <PrimaryButton
            rounded
            className="panel-main-action"
            data-test={'newDataSourceGroupUploadButton'}
            disabled={!validUpload}
            onClick={onUploadFileDataSources}
          >
            <FormattedMessage id={'module-uploadFile-upload'} />
          </PrimaryButton>
        </div>
      </PanelFooter>
    </>
  );
};
