import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';

const { preparation } = actionsConfiguration;

export default {
  pillar: 'preparation',
  name: 'prepare',
  displayName: <FormattedMessage id="prepare" />,
  path: '/prepare',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Datacleaning',
        name: <FormattedMessage id="prepare" />,
        link: '/admin/preparation/prepare',
        claims: preparation.prepare.project.view,
      },
    ],
  },
  routes,
};
