import { FC, memo } from 'react';
import { useTheme } from '@cluedin/theme';

import { useAppSearch } from '../../context/useAppSearch';
import { AppSearchSuggestionItemElement } from './AppSearchSuggestionItem.styles';

type Props = {
  label: string;
  type: 'search';
  handleBlurAppSearchInput: () => void;
};

export const AppSearchSuggestionItem: FC<Props> = memo(
  ({ label, handleBlurAppSearchInput }) => {
    const theme = useTheme();

    const { handleClickSuggestedItem } = useAppSearch();

    const handleClick = () => {
      handleClickSuggestedItem(label);

      handleBlurAppSearchInput();
    };

    return (
      <AppSearchSuggestionItemElement
        text={label}
        theme={theme}
        onClick={handleClick}
        data-test={`search-${label}`}
      />
    );
  },
);
