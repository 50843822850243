import React, { useMemo } from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { withWorkflow } from '@cluedin/form';
import { FileUploader } from '../../uploadFile/components/container/FileUploader';

export const withImportFile = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => (
          <Comp {...props} openImportFromFileWithExistingGroup={openWorkflow} />
        ),
        [props],
      ),
    (panelProps) => (
      <FileUploader
        {...panelProps}
        history={props.history}
        defaultDataSetGroupId={props.id}
        onUploadCompleted={props.refetch}
      />
    ),
    {
      title: (
        <>
          <FormattedMessage id="data-source-import-data-from-file-into" />{' '}
          {props.name} <FormattedMessage id="data-source-group-low" />
        </>
      ),
      panelType: 'medium',
    },
  );

  return <EnhancedhWorkflow />;
};

export const withImportFileWitoutDataSet = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(
        () => <Comp {...props} openImportFromFile={openWorkflow} />,
        [props],
      ),
    (panelProps) => (
      <FileUploader
        {...panelProps}
        history={props.history}
        onUploadCompleted={props.refetch}
      />
    ),
    {
      title: <FormattedMessage id="data-source-import-data-from-file" />,
      panelType: 'medium',
    },
  );

  return <EnhancedhWorkflow />;
};
