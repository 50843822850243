import { handleActions } from 'redux-actions';
import { fetchValuesForSchemaPropertyKeyActions } from './actions';

const initialState = {
  valuesForSchema: {},
};

export default handleActions(
  {
    [fetchValuesForSchemaPropertyKeyActions.request]: (state, { payload }) => ({
      ...state,
      valuesForSchema: {
        ...state.valuesForSchema,
        [payload]: {
          values: [],
          isFetching: true,
          invalid: false,
        },
      },
    }),
    [fetchValuesForSchemaPropertyKeyActions.receive]: (
      state,
      { payload: { param, result } },
    ) => ({
      ...state,
      valuesForSchema: {
        ...state.valuesForSchema,
        [param]: {
          values: result,
          isFetching: false,
          invalid: false,
        },
      },
    }),
    [fetchValuesForSchemaPropertyKeyActions.invalid]: (state, { payload }) => ({
      ...state,
      valuesForSchema: {
        ...state.valuesForSchema,
        [payload]: {
          values: [],
          isFetching: false,
          invalid: true,
        },
      },
    }),
  },
  initialState,
);
