import React from 'react';
import styled from 'styled-components';
import SocialMenuItem from './SocialMenuItem';
import {
  makeAngelListLinkValue,
  makeCrunchbaseLinkValue,
  makeFacebookValue,
  makeGithubLinkValue,
  makeInstagramLinkValue,
  makeLinkedInLinkValue,
  makeLinkWithDirectValue,
  makeTwitterLinkValue,
  makeWikipediaLinkValue,
  makeGooglePlusLinkValue,
  makeYoutubeLinkValue,
} from './internal/linkBuilder';

const SocialUL = styled.ul`
  display: flex;
`;

const SocialMenu = ({ socialViewModel, style, iconSize }) => {
  let websiteHtml = null;
  let twitterHtml = null;
  let facebookHtml = null;
  let linkedInHtml = null;
  let googlePlusHtml = null;
  let instagramHtml = null;
  let githubHtml = null;
  let crunchbaseHtml = null;
  let angelListHtml = null;
  let wikipediaHtml = null;
  let skypeHtml = null;
  let youtubeHtml = null;

  if (socialViewModel.skype) {
    skypeHtml = (
      <SocialMenuItem
        name="Skype"
        link={socialViewModel.skype}
        icon="skype"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.angellist) {
    const angelListLink = makeAngelListLinkValue(socialViewModel.angellist);
    angelListHtml = (
      <SocialMenuItem
        name="Angel List"
        link={angelListLink}
        icon="angellist"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.wikipedia) {
    const wikipediaLink = makeWikipediaLinkValue(socialViewModel.wikipedia);
    wikipediaHtml = (
      <SocialMenuItem
        name="Wikipedia"
        link={wikipediaLink}
        icon="wikipedia"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.googlePlus) {
    const googlePlusLink = makeGooglePlusLinkValue(socialViewModel.googlePlus);
    googlePlusHtml = (
      <SocialMenuItem
        name="Google+"
        link={googlePlusLink}
        icon="googleplus"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.instagram) {
    const instagramLink = makeInstagramLinkValue(socialViewModel.instagram);
    instagramHtml = (
      <SocialMenuItem
        name="Instagram"
        link={instagramLink}
        icon="instagram"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.github) {
    const githubLink = makeGithubLinkValue(socialViewModel.github);
    githubHtml = (
      <SocialMenuItem
        name="Github"
        link={githubLink}
        icon="github"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.crunchbase) {
    const crunchbaseLink = makeCrunchbaseLinkValue(socialViewModel.crunchbase);
    crunchbaseHtml = (
      <SocialMenuItem
        name="Crunchbase"
        link={crunchbaseLink}
        icon="crunchbase"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.website) {
    const websiteLink = makeLinkWithDirectValue(socialViewModel.website);
    websiteHtml = (
      <SocialMenuItem
        name="Website"
        link={websiteLink}
        icon="Website"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.twitter) {
    const twitterLink = makeTwitterLinkValue(socialViewModel.twitter);
    twitterHtml = (
      <SocialMenuItem
        name="Twitter"
        link={twitterLink}
        icon="twitter"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.linkedIn) {
    const linkedInLink = makeLinkedInLinkValue(socialViewModel.linkedIn);
    linkedInHtml = (
      <SocialMenuItem
        name="LinkedIn"
        link={linkedInLink}
        icon="linkedin"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.facebook) {
    const facebookLink = makeFacebookValue(socialViewModel.facebook);
    facebookHtml = (
      <SocialMenuItem
        name="Facebook"
        link={facebookLink}
        icon="facebook"
        iconSize={iconSize}
      />
    );
  }

  if (socialViewModel.youtube) {
    const youtubeLink = makeYoutubeLinkValue(socialViewModel.youtube);
    youtubeHtml = (
      <SocialMenuItem
        name="Youtube"
        link={youtubeLink}
        icon="youtube"
        iconSize={iconSize}
      />
    );
  }

  return (
    <SocialUL style={style || {}}>
      {websiteHtml}
      {twitterHtml}
      {facebookHtml}
      {linkedInHtml}
      {angelListHtml}
      {wikipediaHtml}
      {googlePlusHtml}
      {instagramHtml}
      {githubHtml}
      {crunchbaseHtml}
      {skypeHtml}
      {youtubeHtml}
    </SocialUL>
  );
};

SocialMenu.displayName = 'SocialMenu';

export default SocialMenu;
