import { memo, useMemo } from 'react';
import { Select, getGroupedOptions } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';

import usePrevious from '../../../../core/hooks/usePrevious';
import FirstLetterComponent from '../../../../core/components/composites/FirstLetterComponent';

const itemAdapterFunction = (i, { showObsoleteLabels }) => {
  const subLabel =
    !i?.isObsolete || !showObsoleteLabels || !i?.mappedKey?.Key ? (
      i?.key
    ) : (
      <span>
        {i?.key} &#10141; {i?.mappedKey?.Key}
      </span>
    );

  return {
    ...i,
    subLabel,
    value: i?.key,
    label: i?.displayName,
    group: i?.groupName ?? '',
    icon: <FirstLetterComponent name={i?.displayName} size="xs" />,
  };
};

export const VocabularyKeySelector = memo(
  ({
    data,
    total,
    value,
    loadMore,
    hasError,
    disabled,
    onChange,
    readOnly,
    isLoading,
    setSearchName,
    isCreatable = true,
    isClearable = true,
    setIsNewVocabularyKey,
    showObsoleteLabels = false,
    itemAdapterFunc = itemAdapterFunction,
    vocabDataTest,
  }) => {
    const prevData = usePrevious(data);

    const persistData = useMemo(() => {
      if (data?.length > 0) {
        return data;
      }

      return prevData;
    }, [isLoading, data]);

    const options = useMemo(
      () =>
        getGroupedOptions({
          itemAdapterFunc,
          data: persistData,
          sortByKey: 'group',
          groupByKey: 'group',
          itemAdapterFuncOptions: {
            showObsoleteLabels,
          },
        }),
      [persistData],
    );

    const handleChange = (v) => {
      setIsNewVocabularyKey?.(false);

      onChange?.({
        new: false,
        ...v,
      });
    };

    const handleCreateNew = (name) => {
      setIsNewVocabularyKey?.(true);

      onChange?.({
        name,
        new: true,
      });
    };

    return (
      <div data-test={vocabDataTest}>
        <Select
          placeholder={
            <FormattedMessage id="module-dataCatalog-vocabularyKeySelectPlaceholder" />
          }
          isGrouped
          isSearchable
          total={total}
          value={value}
          options={options}
          hasError={hasError}
          disabled={disabled}
          loadMore={loadMore}
          readOnly={readOnly}
          isLoading={isLoading}
          onChange={handleChange}
          isCreatable={isCreatable}
          isClearable={isClearable}
          onInputChange={setSearchName}
          onCreateOption={handleCreateNew}
        />
      </div>
    );
  },
);
