import React, { Fragment } from 'react';
import ListWithPaging from '../../../core/components/Hocs/ListWithPaging';
import SchemaLineSelect from './SchemaLineSelect';

const SchemaListSelection = ({
  data,
  onSchemaSelect,
  unavailableProperties,
}) => (
  <Fragment>
    {(data || []).map((schemarow) => (
      <SchemaLineSelect
        disabled={
          !!(unavailableProperties || []).find((pKey) => schemarow.Key === pKey)
        }
        schema={schemarow}
        onSchemaSelect={onSchemaSelect}
      />
    ))}
  </Fragment>
);

export default ListWithPaging(SchemaListSelection);
