import { handleActions } from 'redux-actions';
import {
  shouldFetchAllUserInvitationsActions,
  shouldResendInviteActions,
  shouldRemoveInviteActions,
} from './actions';

const initialState = {
  allUserInvitations: [],
  isFetchingUserInvitations: false,
  invalidUserInvitations: false,
  resendInvite: {},
  removeInvite: {},
};

export default handleActions(
  {
    [shouldFetchAllUserInvitationsActions.request]: (state) => ({
      ...state,
      isFetchingUserInvitations: true,
      allUserInvitations: [],
      invalidUserInvitations: false,
    }),
    [shouldFetchAllUserInvitationsActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingUserInvitations: false,
      allUserInvitations: payload,
      invalidUserInvitations: false,
    }),
    [shouldFetchAllUserInvitationsActions.invalid]: (state) => ({
      ...state,
      isFetchingUserInvitations: false,
      allUserInvitations: [],
      invalidUserInvitations: true,
    }),
    [shouldResendInviteActions.request]: (state, { payload }) => ({
      ...state,
      resendInvite: {
        ...state.resendInvite,
        [payload]: {
          isFetching: true,
          invalid: false,
          done: false,
        },
      },
    }),
    [shouldResendInviteActions.request]: (state, { payload }) => ({
      ...state,
      resendInvite: {
        ...state.resendInvite,
        [payload]: {
          isFetching: false,
          invalid: true,
          done: false,
        },
      },
    }),
    [shouldResendInviteActions.receive]: (state, { payload }) => ({
      ...state,
      resendInvite: {
        ...state.resendInvite,
        [payload]: {
          isFetching: false,
          invalid: false,
          done: true,
        },
      },
    }),
    [shouldRemoveInviteActions.request]: (state, { payload }) => ({
      ...state,
      removeInvite: {
        ...state.resendInvite,
        [payload]: {
          isFetching: true,
          invalid: false,
          done: false,
        },
      },
    }),
    [shouldRemoveInviteActions.receive]: (state, { payload }) => ({
      ...state,
      allUserInvitations: (state.allUserInvitations || []).filter(
        (invite) => invite.id !== payload,
      ),
      removeInvite: {
        ...state.resendInvite,
        [payload]: {
          isFetching: false,
          invalid: true,
          done: false,
        },
      },
    }),
    [shouldRemoveInviteActions.invalid]: (state, { payload }) => ({
      ...state,
      removeInvite: {
        ...state.resendInvite,
        [payload]: {
          isFetching: false,
          invalid: false,
          done: true,
        },
      },
    }),
  },
  initialState,
);
