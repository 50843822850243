import React from 'react';
import { Flex } from 'uxi/Layout';
import { buttonResetStylesCSSString } from 'uxi/Button/buttonResetStyles';
import { Search } from 'uxi/Icons';
import AutoComplete from 'uxi/Input/BETAAutoComplete';
import styled from 'styled-components';
import { injectIntl } from '@cluedin/locale';
import {
  MainSearchBoxStyleOverload,
  SelectInputEntityPoolStyleOverload,
} from './MainSearchInputStyle';
import SearchBoxPoolSelectIdentifier from './SearchBoxPoolSelectIdentifier';
import SearchSuggestionItem from './SearchSuggestionItem';
import SuggestionsLoader from './SuggestionsLoader';
import SuggestionTypeBadge from './SuggestionTypeBadge';
import { useTheme } from '@cluedin/theme';

const backgroundColor = '#fff';

const SearchBarUI = styled.div`
  background-color: ${backgroundColor};
  margin-left: 4px;
  border-radius: 3px;
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  max-width: 700px;
`;

const SubmitInputUI = styled.button.attrs((/* props */) => ({
  type: 'submit',
  tabIndex: -1,
}))`
  ${buttonResetStylesCSSString};
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 0 3px 3px 0;

  & {
    background: transparent;
    &:hover,
    &:focus {
      background: #cecece;
    }
  }

  flex-shrink: 1;
  flex-grow: 1;
`;

const InputUI = styled.input`
  ${buttonResetStylesCSSString};
  color: #000000;
  font-weight: 100;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  padding: 8px;
  height: 34px;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 9;
  border-radius: 3px 0 0 3px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
  }

  text-overflow: ellipsis;
  /* overflow: hidden; */
  white-space: nowrap;
  ${({ entityTypePoolIdentifier }) =>
    entityTypePoolIdentifier === '' // eslint-disable-line no-nested-ternary
      ? 'padding-right: 68px;'
      : entityTypePoolIdentifier === 'organization'
      ? 'padding-right: 128px;'
      : 'padding-right: 98px;'};
`;

const InputWithBadge = ({ entityTypePoolIdentifier, ...rest }) => (
  <Flex style={{ position: 'relative', width: '100%' }}>
    <InputUI entityTypePoolIdentifier={entityTypePoolIdentifier} {...rest} />
    <Flex style={{ position: 'absolute', right: '40px', top: 0, bottom: 0 }}>
      <SuggestionTypeBadge
        style={{ border: '1px solid white' }}
        badgeLabel={entityTypePoolIdentifier}
      />
    </Flex>
  </Flex>
);

const MainSearch = ({
  entityTypePoolIdentifier = '',
  onEntityPoolChange,
  suggestions = [],
  onSuggestionChange,
  onClick,
  isFetchingSuggestion,
  initialSelectionValue,
  value,
  intl,
}) => {
  const theme = useTheme();
  return (
    <SearchBarUI>
      <SelectInputEntityPoolStyleOverload
        className="test_globalHeader_entityTypeSelectWrapper"
        theme={theme}
      >
        <SearchBoxPoolSelectIdentifier
          value={entityTypePoolIdentifier}
          onEntityPoolChange={onEntityPoolChange}
        />
      </SelectInputEntityPoolStyleOverload>
      <MainSearchBoxStyleOverload>
        <AutoComplete
          data-test={'main-search-input'}
          className="test_globalHeader_search"
          inertMain
          isFullWidth
          onChange={onSuggestionChange}
          entityTypePoolIdentifier={entityTypePoolIdentifier}
          Input={InputWithBadge}
          placeholder={intl.formatMessage({ id: 'SearchContent.search' })}
          defaultValue={initialSelectionValue}
          value={value}
          onSubmit={(e, val) => {
            const uriEncodedValue = encodeURIComponent(val);
            onClick(uriEncodedValue);
          }}
        >
          {(suggestions || []).map((suggestion, i) => (
            <SearchSuggestionItem
              key={`${suggestion.Name}-${suggestion.entityType || 'none'}-${i}`}
              onClick={() => {
                onClick(suggestion);
              }}
              value={suggestion}
              suggestion={suggestion}
            />
          ))}
          {isFetchingSuggestion && <SuggestionsLoader />}
        </AutoComplete>
        <SubmitInputUI onClick={() => onClick()}>
          <Search color={theme.palette.themePrimary} size="18" />
        </SubmitInputUI>
      </MainSearchBoxStyleOverload>
    </SearchBarUI>
  );
};

export default injectIntl(MainSearch);
