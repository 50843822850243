/* eslint-disable */
import styled from 'styled-components';

const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${({ theme: { palette } }) => palette.themePrimary}}
  }

  .breadcrumb-item-module {
    display: flex;
  }
  .breadcrumb-item-mobile-only {
    display: none;
  }
  .breadcrumb-item-part {
    display: flex;
  }
  .breadcrumb-item-part-last {
    display: flex;
  }
  .breadcrumb-item-mobile {
    display: flex;
    align-items: center;
  }
  .breadcrumb-item-mobile-only {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .breadcrumb-item-pillar {
      display: none;
    }
    .breadcrumb-item-module {
      display: none;
    }
    .breadcrumb-item-part {
      display: none;
    }
    .breadcrumb-item-mobile {
      display: flex;
      align-items: center;
    }
    .breadcrumb-item-mobile-only {
      display: flex;
    }
  }
`;

export default BreadCrumbWrapper;
