import { useSearchQueryDecoder } from './useSearchQueryDecoder';
import { useSearchQueryEncoder } from './useSearchQueryEncoder';
import { useSearchQuery } from './useSearchQuery';
import { useSearchCount } from './useSearchCount';

export const useSearch = (searchQuery = {}, type, entityConfigurations) => {
  const [variables, filtersAPI] = useSearchQueryDecoder(searchQuery, type);

  const searchUrl = useSearchQueryEncoder(
    variables.query,
    filtersAPI.selectedFilters,
    filtersAPI.sortType,
  );

  const [result, searchExtra] = useSearchQuery(
    variables,
    type,
    entityConfigurations,
  );

  const [totalSearchCount, { loading: totalLoading }] =
    useSearchCount(variables);

  return [
    result,
    searchExtra,
    searchUrl,
    filtersAPI,
    totalSearchCount,
    totalLoading,
  ];
};
