import { makeLazy } from '../core/components/Hocs/LazyRoute';
import RenderChildren from '../core/components/composites/RenderChildren';

export default [
  {
    path: '/super-entity-list',
    component: RenderChildren,
    routes: [
      {
        path: '/simple',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ExampleSuperEntityListDefault" */
            './examples/ExampleSuperEntityListDefault'
          ),
        ),
      },
      {
        path: '/activity',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ExampleSuperEntityListActivity" */
            './examples/ExampleSuperEntityListActivity'
          ),
        ),
      },
      {
        path: '/person',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ExampleSuperEntityListPerson" */
            './examples/ExampleSuperEntityListPerson'
          ),
        ),
      },
      {
        path: '/org-website',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ExampleSuperEntityListHubspotOrg" */
            './examples/ExampleSuperEntityListHubspotOrg'
          ),
        ),
      },
      {
        path: '/tim',
        component: makeLazy(() =>
          import(
            /* webpackChunkName:"ExampleSuperEntityListTim" */
            './examples/ExampleSuperEntityListTim'
          ),
        ),
      },
    ],
  },
];
