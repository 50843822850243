import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, PanelContent, PanelFooter } from '@cluedin/form';
import { Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import CancelButton from '@cluedin/form/dist/src/Button/CancelButton';

class DrawerWrapper extends Component {
  constructor(props) {
    super(props);
    // this is so we get animation on initial pageWithDrawer load
    this.state = { didMount: false };
  }

  componentDidMount() {
    this.setState({ didMount: true });
  }

  render() {
    const { match, goTo, open, homeDashboardDescriptor } = this.props;

    const { didMount } = this.state;

    const isOpen = didMount ? open : false;

    const relativeHomePath = match.path.replace(/\/.[^/]*$/, '');

    const onClose = () => goTo(`${relativeHomePath}/home/`);

    return (
      <>
        {homeDashboardDescriptor.map(({ widgets = [] }) =>
          widgets.map((widgetDescriptor) => {
            const { drawer } = widgetDescriptor;

            if (drawer) {
              const { route, title, content } = drawer;

              return (
                <Route
                  key={route}
                  path={`${match.url}/${route}`}
                  render={() => (
                    <Panel
                      open={isOpen}
                      onClose={onClose}
                      title={title}
                      panelType="medium"
                    >
                      <PanelContent style={{ padding: 0 }}>
                        {content}
                      </PanelContent>
                      <PanelFooter>
                        <CancelButton onClick={onClose}>Cancel</CancelButton>
                      </PanelFooter>
                    </Panel>
                  )}
                />
              );
            }

            return null;
          }),
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  goTo: (target) => dispatch(push(target)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerWrapper);
