import React from 'react';
import Switch from 'uxi/Input/Switch';
import styled from 'styled-components';

export const RequiredUI = styled.span`
  &:after {
    color: red;
    content: '*';
  }
`;

const UniqueIdentifierConsentUI = styled.div`
  padding: 15px;
  border: 1px solid #ccc;
  border-top: none;
  display: flex;
  flex-flow: row wrap;
  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
  & > *:nth-child(1) {
    flex-grow: 99;

    display: flex;
    flex-direction: column;
    text-align: left;
  }
  & > *:nth-child(2) {
    display: flex;
    align-items: center;
  }
`;

const UniqueIdentifierConsent = ({ uniqueIdentifier, isSingle, onChange }) => (
  <UniqueIdentifierConsentUI
    style={isSingle ? { borderTop: '1px solid #ccc' } : {}}
  >
    <div>
      <div style={{ fontSize: '16px', paddingBottom: '10px' }}>
        {uniqueIdentifier.key}{' '}
        {uniqueIdentifier.isRequired ? <RequiredUI /> : null}
      </div>
      <div style={{ paddingBottom: '5px' }}>{uniqueIdentifier.description}</div>
    </div>
    <div>
      <Switch
        name={`uniqueIdentifierConsentSwitch-${uniqueIdentifier.key}`}
        defaultChecked={uniqueIdentifier.consent}
        onChange={({ checked }) => {
          if (onChange) {
            onChange({
              consent: checked,
              uniqueIdentifier,
            });
          }
        }}
      />
    </div>
  </UniqueIdentifierConsentUI>
);

export default UniqueIdentifierConsent;
