import styled from 'styled-components';

export const GhostCardContainer = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top: 1px solid #ececec;
  justify-content: space-between;
  padding: 14px 30px;
  width: 182px;
  height: 48px;
`;

export const GhostCardItem = styled.span`
  align-items: center;
  color: rgb(55, 55, 58);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  height: 100%;
`;
