"use strict";

exports.__esModule = true;
exports.createExpiredDate = void 0;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var createExpiredDate = function createExpiredDate(expiresIn) {
  if (expiresIn === void 0) {
    expiresIn = 0;
  }
  var addToCurrentDate = expiresIn;
  if (expiresIn > 60) {
    // we add a 1 minute buffer
    addToCurrentDate = expiresIn - 60;
  }
  return (0, _moment.default)().add(addToCurrentDate, 'seconds').toString();
};
exports.createExpiredDate = createExpiredDate;