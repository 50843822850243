import { env } from '../../../config';

export const logException = (ex) => {
  if (env === 'production' || env === 'premise') {
    /* eslint no-console:0 */
    if (window.console && console.error) console.error(ex);
  }
};

export const logMessage = (message) => {
  if (env === 'production' || env === 'premise') {
    /* eslint no-console:0 */
    if (window.console && console.error) console.error(message);
  }
};

export const warn = (message) => {
  if (env === 'production' || env === 'premise') {
    /* eslint no-console:0 */
    if (window.console && console.error) console.error(message);
  }
};
