"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var _default = function _default(ListItemText) {
  return (0, _styledComponents.default)(ListItemText)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  flex: 1 1 auto;\n  min-width: 0;\n  margin-top: 4px;\n  margin-bottom: 4px;\n  .multiline: {\n    margin-top: 6px;\n    margin-bottom: 6px;\n  }\n  .inset {\n    padding-left: 56px;\n  }\n"])));
};
exports.default = _default;