"use strict";

exports.__esModule = true;
exports.ListValueWrapper = exports.ListRawValue = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var ListValueWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"])));
exports.ListValueWrapper = ListValueWrapper;
var ListRawValue = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  color: #9b9b9c;\n"])));
exports.ListRawValue = ListRawValue;