import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSearch } from '@cluedin/components';
import { GqlErrorMessages } from '@cluedin/form';

import { RolesListContainer } from '../RolesListContainer/RolesListContainer';
import { usePaging } from '../../../../core/hooks';
import AddRoleToUserToolbar from './AddRoleToUserToolbar';
import { useAddRolesToUser } from '../../../hooks/useAddRolesToUser';
import usePrevious from '../../../../core/hooks/usePrevious';

const AssignRoleToUser = ({ history, location, values = {}, onClose }) => {
  const [searchTerm, setSearchTerm] = useSearch(history, location);
  const [, currentPage, setCurrentPage] = usePaging(history, location, 20);
  const [rolesSelections, setRolesSections] = useState([]);
  const [addRolesToUser, done, loading, error] = useAddRolesToUser();

  const prevDone = usePrevious(done);

  useEffect(() => {
    if (done && prevDone !== done) {
      values.refetchUserRoles();
      onClose();
    }
  }, [done, prevDone, values, onClose]);

  return (
    <>
      {error && <GqlErrorMessages error={error} />}
      <RolesListContainer
        creation={[]}
        searchTerm={searchTerm}
        onSubmitSearch={setSearchTerm}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        selectable
        withToolbar
        propertyKey="name"
        filterToolbar={
          <AddRoleToUserToolbar
            onCancel={onClose}
            loading={loading}
            addRolesToUser={() => {
              addRolesToUser(values.userName, rolesSelections);
            }}
            touched={rolesSelections.length > 0}
          />
        }
        roleIdsToOmit={(values.roles || []).map((r) => r.id)}
        selected={rolesSelections}
        onSelectedChange={(selected) => {
          setRolesSections(selected);
        }}
      />
    </>
  );
};

export default withRouter(AssignRoleToUser);
