import { memo, useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  TextField,
  DatePickerField as DatePicker,
  Select,
} from '@cluedin/form';
import { LocalizationContext } from '@cluedin/locale';

import EntityTypeSelector from '../../../../entityTypes/components/containers/MultipleEntityTypeSelector/EntityTypeSelector';
import { RuleItemMultipleValueControls } from './RuleItemMultipleValueControls';
import RuleItemPreview from './RuleItemPreview';
import { getFriendlyValuePreview } from '../../../utils/getFriendlyValuePreview';
import { ProviderNameById } from '../../../../../shared/components/ProviderNameById';
import {
  RuleItemEnumerableSelector,
  ValuesKeysForRuleItemEnumerableSelector,
} from './RuleItemEnumerableSelector';

const findInputs = (operator, propertyType) => {
  const nbInputs = operator?.nb_inputs;
  const labels = operator?.inputLabels || [];
  const inputs = [];

  for (var i = 0; i < nbInputs; i++) {
    inputs.push({ type: propertyType, label: labels[i] });
  }

  return inputs;
};

export const RuleItemValueSelectors = memo(
  ({
    selectedOperator,
    operators = [],
    ruleItem,
    ruleItemIndex,
    valueHasError,
    onValueChange,
    onMultipleValueChange,
    propertyType,
    VocabularyValueAutoComplete,
    previewMode,
  }) => {
    const intl = useIntl();

    const locales = useContext(LocalizationContext);
    const ruleConditionLocales = locales?.RuleCondition;

    const operator = operators.find((o) => o?.id === selectedOperator);
    const inputsToGenerated = !operator?.multiple
      ? findInputs(operator, propertyType) || []
      : null;

    const objectTypeId = ruleItem?.objectTypeId;
    const hasVocabValueLookUp =
      objectTypeId === '011ac3b4-0b46-4f9c-a82a-8c14f9dd642b';

    const showAutocomplete =
      hasVocabValueLookUp &&
      propertyType?.toLowerCase() !== 'datetime' &&
      propertyType?.toLowerCase() !== 'boolean';

    if (previewMode) {
      return (ruleItem?.value || []).map((val) => {
        if (ruleItem?.field !== 'ProviderDefinitionIds') {
          return (
            <div style={{ width: '400px' }}>
              <RuleItemPreview
                label="Value"
                text={
                  ruleItem?.field?.toLowerCase()?.includes('date')
                    ? getFriendlyValuePreview(val)
                    : val
                }
              />
            </div>
          );
        }

        return (
          <RuleItemPreview label="Value" text={<ProviderNameById id={val} />} />
        );
      });
    }

    if (operator?.multiple && !previewMode) {
      return (
        <RuleItemMultipleValueControls
          valueHasError={valueHasError}
          value={ruleItem?.value}
          fieldRaw={ruleItem?.field}
          showAutocomplete={showAutocomplete}
          VocabularyValueAutoComplete={VocabularyValueAutoComplete}
          onMultipleValueChange={(v) =>
            onMultipleValueChange?.(v, ruleItemIndex)
          }
          skipRetrievedValues={
            ruleItem?.operator === '4e4782d7-8c17-47c1-9797-37434fce0c77'
          }
        />
      );
    }

    return inputsToGenerated.map((inputField, index) => {
      let content;

      if (propertyType?.toLowerCase() === 'boolean' && !previewMode) {
        content = (
          <div style={{ width: '200px' }}>
            <Select
              hasError={valueHasError}
              value={ruleItem?.value?.[index] || ''}
              onChange={({ value }) =>
                onValueChange(value, ruleItemIndex, index)
              }
              options={[
                {
                  label: ruleConditionLocales.chooseValue,
                  value: null,
                  'data-test': `rule-item-value-${ruleConditionLocales.chooseValue}`,
                },
                {
                  label: ruleConditionLocales.True,
                  value: true,
                  'data-test': `rule-item-value-${ruleConditionLocales.True}`,
                },
                {
                  label: ruleConditionLocales.False,
                  value: false,
                  'data-test': `rule-item-value-${ruleConditionLocales.False}`,
                },
              ]}
            />
          </div>
        );
      }

      if (propertyType?.toLowerCase() === 'datetime' && !previewMode) {
        const dateValue = ruleItem?.value?.[index]
          ? new Date(ruleItem?.value?.[index])
          : '';

        content = (
          <div
            style={{ width: '300px' }}
            data-test={`rule-item-value-${ruleItem?.value?.[index] || ''}`}
          >
            <DatePicker
              hasError={valueHasError}
              placeholder={intl.formatMessage({
                id: ruleConditionLocales.placeholderValueId,
                defaultMessage: ruleConditionLocales.placeholderValueId,
              })}
              value={dateValue}
              onSelectDate={(date) => {
                const onlyDate = new Date(
                  Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    0,
                    0,
                    0,
                  ),
                );
                onValueChange(onlyDate.toISOString(), ruleItemIndex, index);
              }}
            />
          </div>
        );
      }

      if (
        !content &&
        !previewMode &&
        ValuesKeysForRuleItemEnumerableSelector.includes(
          ruleItem?.field?.toLowerCase(),
        )
      ) {
        content = (
          <div
            style={{ width: '300px' }}
            data-rule-item-parent={objectTypeId}
            data-test={`rule-item-value-${ruleItem?.value?.[index] || ''}`}
          >
            <RuleItemEnumerableSelector
              property={ruleItem?.field}
              objectTypeId={objectTypeId}
              parentId={objectTypeId}
              value={ruleItem?.value?.[index] || ''}
              hasError={!ruleItem?.value?.[index] && valueHasError}
              onChange={(value) => {
                onValueChange(value, ruleItemIndex, index);
              }}
            />
          </div>
        );
      }

      if (
        !content &&
        !previewMode &&
        !ValuesKeysForRuleItemEnumerableSelector.includes(
          ruleItem?.field?.toLowerCase(),
        )
      ) {
        content = showAutocomplete ? (
          <div data-test={`rule-item-value-${ruleItem?.value?.[index] || ''}`}>
            <VocabularyValueAutoComplete
              skipRetrievedValues={
                ruleItem?.operator === '4e4782d7-8c17-47c1-9797-37434fce0c77'
              }
              hasError={valueHasError}
              rawValue
              onChange={(value) => {
                onValueChange(value, ruleItemIndex, index);
              }}
              value={ruleItem?.value[index]}
              vocabularyKey={ruleItem?.field}
            />
          </div>
        ) : (
          <div style={{ width: '300px' }}>
            {ruleItem?.field.toLowerCase() === 'entitytype' ? (
              <div
                data-test={`rule-item-value-${ruleItem?.value?.[index] || ''}`}
              >
                <EntityTypeSelector
                  formatCreateLabel={(v) => (
                    <>
                      {intl.formatMessage({
                        id: 'module-rule-entityTypeSelector-use',
                      })}
                      :&nbsp;{v}
                    </>
                  )}
                  keepEntityTypeAsDisplayName
                  isCreatable
                  value={{ entityType: ruleItem?.value?.[index] || '' }}
                  onChange={({ entityType }) => {
                    onValueChange(entityType, ruleItemIndex, index);
                  }}
                />
              </div>
            ) : (
              <TextField
                hasError={!ruleItem?.value?.[index] && valueHasError}
                errorMessage={!ruleItem?.value?.[index] && valueHasError}
                type={
                  propertyType?.toLowerCase() === 'number' ? 'number' : null
                }
                placeholder={intl.formatMessage({
                  id: ruleConditionLocales.placeholderValueId,
                  defaultMessage: ruleConditionLocales.placeholderValueId,
                })}
                value={ruleItem?.value?.[index] || ''}
                onChange={(e, value) => {
                  onValueChange(value, ruleItemIndex, index);
                }}
                data-test={`rule-item-value-${ruleItem?.value?.[index] || ''}`}
              />
            )}
          </div>
        );
      }

      return (
        <div key={`operator-${index}`}>
          {inputField && inputField.label && (
            <div data-test={`rule-item-value-${inputField.label}`}>
              {inputField.label}
            </div>
          )}
          {content}
        </div>
      );
    });
  },
);
