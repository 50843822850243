import { compose, lifecycle } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { shouldFetchCurrentUser as shouldFetchCurrentUserAction } from '../../../user/actions';
import { shouldFetchSchema as shouldFetchSchemaAction } from '../../../../action/entity';
import App from '../composites/App';
import PageWrapper from '../containers/PageWrapperContainer';
import { shouldLogout } from '../../../auth/actions';
import { getSubdomain } from '../../helpers/url';
import withDefaultError from '../../../error/components/hocs/withDefaultError';
import { shoudFetchPublicOrgInfo } from '../../../public/actions/public';
import { shouldFetchWidgetConfiguration } from '../../../wms/actions';
import { clientId } from '../../../../config';
import { shouldFetchAllIntegrations } from '../../../integration/actions';

const AppEnchanced = compose(
  lifecycle({
    componentDidMount() {
      const { shouldFetchUserAndSchema } = this.props;
      shouldFetchUserAndSchema();
    },
  }),
)(withDefaultError(App));

const mapStateToProps = (
  {
    core: { showErrorGlobal },
    user: { currentUser },
    wms: { isLoadingEntityConfigurations },
    integration: { isFetchingAllIntegration },
  },
  { match: { params }, location },
) => ({
  showErrorGlobal,
  id: params.id,
  q: params.q,
  params,
  PageWrapper,
  currentUser,
  location,
  isLoadingEntityConfigurations,
  isFetchingAllIntegration,
});

const mapDispatchToProps = (dispatch) => ({
  shouldFetchUserAndSchema: () => {
    dispatch(shouldFetchCurrentUserAction());
    //dispatch(shouldFetchSchemaAction());
    dispatch(shoudFetchPublicOrgInfo(clientId));
    dispatch(shouldFetchWidgetConfiguration(clientId));
    dispatch(shouldFetchAllIntegrations());
  },
  onInactive: ({ idle }) => {
    if (idle) {
      dispatch(shouldLogout(getSubdomain()));
      const { pathname } = window.location;
      window.location = `/logout${pathname ? `?redirect=${pathname}` : ''}`;
    }
  },
});

const ConnectedEnchancedApp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppEnchanced),
);

const AppExceptLogout = ({ location, children, ...r }) => {
  if (location.pathname === '/logout') {
    return children;
  }

  return (
    <ConnectedEnchancedApp {...r} location={location} children={children} />
  );
};

export default withRouter(AppExceptLogout);
