import styled from 'styled-components';

export const Wrapper = styled.div`
  height: auto !important;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  .list-content-list > div {
    ${({ isEmpty }) => {
      if (isEmpty) {
        return `
          border-bottom: 1px solid #ECECEC;
          border-left: 1px solid #ECECEC;
          border-right: 1px solid #ECECEC;
        `;
      }

      return ``;
    }}
  }
`;

export const Flex = styled.div`
  flex: 1;
  div:first-child {
    height: auto !important;
  }
  div:last-child {
    height: auto !important;
  }

  th {
    font-size: 16px;
    background-color: white !important;
    border: 1px solid #ececec;
  }

  strong {
    font-weight: 600;
  }

  td {
    border: 1px solid #ececec;
  }

  th:last-child {
    ${({ withoutTopBorder }) => withoutTopBorder && 'border-top: none'};
  }

  tr:last-child {
    border: 1px solid #ececec;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  strong {
    white-space: nowrap;
    overflow: hidden;
  }
`;
