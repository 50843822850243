import { FC, CSSProperties, memo, useId } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { Icon } from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

const SortingHeaderCellWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: ${({ disabled }) => {
    return disabled ? 'not-allowed' : 'pointer';
  }};
`;
const ArrowWrapper = styled.div<{ up?: boolean }>`
  flex: 1;
  display: flex;
  align-items: ${({ up }) => {
    return up ? 'flex-end' : 'flex-start';
  }};

  i {
    height: 12px;
  }
`;

type Props = {
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  disabledReason?: string;
  value: 'asc' | 'desc';
  onClick: (v: 'asc' | 'desc') => void;
};

export const SortingHeaderCell: FC<Props> = memo(
  ({ onClick, value, className, disabled = false, style, disabledReason }) => {
    const disabledReasonTooltipId = useId();
    const theme = useTheme();

    const arrowUpColor = value === 'asc' ? theme.palette.themePrimary : null;
    const arrowDownColor = value === 'desc' ? theme.palette.themePrimary : null;

    if (disabled && disabledReason) {
      return (
        <TooltipHost
          id={disabledReasonTooltipId}
          calloutProps={{ gapSpace: 0 }}
          content={disabledReason}
        >
          <SortingHeaderCellWrapper
            className={className}
            style={style}
            disabled={disabled}
          >
            <ArrowWrapper
              className="asc"
              up
              onClick={() => !disabled && onClick('asc')}
            >
              <Icon
                style={{}}
                iconName="ChevronUpMed"
                styles={{
                  root: {
                    fontSize: 12,
                    color: !disabled
                      ? arrowUpColor
                      : theme.palette.neutralTertiaryAlt,
                  },
                }}
              />
            </ArrowWrapper>

            <ArrowWrapper
              className="desc"
              onClick={() => !disabled && onClick('desc')}
            >
              <Icon
                style={{}}
                iconName="ChevronDown"
                styles={{
                  root: {
                    fontSize: 12,
                    color: !disabled
                      ? arrowDownColor
                      : theme.palette.neutralTertiaryAlt,
                  },
                }}
              />
            </ArrowWrapper>
          </SortingHeaderCellWrapper>
        </TooltipHost>
      );
    }

    return (
      <SortingHeaderCellWrapper
        className={className}
        style={style}
        disabled={disabled}
      >
        <ArrowWrapper
          className="asc"
          up
          onClick={() => !disabled && onClick('asc')}
        >
          <Icon
            style={{}}
            iconName="ChevronUpMed"
            styles={{
              root: {
                fontSize: 12,
                color: !disabled
                  ? arrowUpColor
                  : theme.palette.neutralTertiaryAlt,
              },
            }}
          />
        </ArrowWrapper>

        <ArrowWrapper
          className="desc"
          onClick={() => !disabled && onClick('desc')}
        >
          <Icon
            style={{}}
            iconName="ChevronDown"
            styles={{
              root: {
                fontSize: 12,
                color: !disabled
                  ? arrowDownColor
                  : theme.palette.neutralTertiaryAlt,
              },
            }}
          />
        </ArrowWrapper>
      </SortingHeaderCellWrapper>
    );
  },
);
