import React, { useState } from 'react';

import GhostGraph from './GhostCard/GhostGraph';
import ZoomControl from './controls/Zoom';
import Filters from './controls/Filters';
import Legend from './controls/Legend';
import { useZoomControl } from './hooks/useZoomControl';
import { graphConfig } from './config';
import {
  GraphContainerWrapper,
  GraphContainerInnerWrapper,
  GraphContainerLoadingWrapper,
} from './GraphContainer.styles';

const GraphContainer = ({
  children,
  graph,
  loading,
  legendCanvas,
  legendPosition,
  onFilter,
  filter,
  resetSelectedNodes,
  labels = {},
}) => {
  const zoomControl = useZoomControl();
  return (
    <GraphContainerWrapper>
      <GraphContainerInnerWrapper>
        <div>
          <ZoomControl
            onIncrease={(e) => {
              e.preventDefault();
              const currentScale = graph?.getScale();
              const currentPosition = graph?.getViewPosition();
              const increaseScale = currentScale + 0.2;
              currentScale &&
                graph?.moveTo({
                  position: currentPosition,
                  scale: increaseScale,
                });
              zoomControl(increaseScale, graph);
            }}
            onDecrease={(e) => {
              e.preventDefault();
              const currentScale = graph?.getScale();
              const currentPosition = graph?.getViewPosition();
              const decreaseScale = currentScale - 0.2;
              currentScale &&
                graph?.moveTo({
                  position: currentPosition,
                  scale: decreaseScale,
                });
              zoomControl(decreaseScale, graph);
            }}
            onCenter={(e) => {
              e.preventDefault();
              graph?.moveTo({
                position: graphConfig?.center?.position,
                animation: {
                  duration: graphConfig?.fit?.animation.duration,
                },
              });
            }}
            onFit={(e) => {
              e.preventDefault();
              graph?.fit(graphConfig?.fit);
            }}
          />
        </div>

        {onFilter && (
          <div>
            <Filters
              onSelect={(isActive) => {
                onFilter(isActive);
                graph?.fit(graphConfig?.fit);
              }}
              filter={filter}
              labels={labels}
              resetSelectedNodes={resetSelectedNodes}
            />
          </div>
        )}
      </GraphContainerInnerWrapper>

      {loading ? (
        <GraphContainerLoadingWrapper>
          <GhostGraph />
        </GraphContainerLoadingWrapper>
      ) : (
        <div style={{ flex: 1, overflow: 'hidden' }}>
          {children}
          {legendCanvas && (
            <Legend canvas={legendCanvas} position={legendPosition} />
          )}
        </div>
      )}
    </GraphContainerWrapper>
  );
};

export default GraphContainer;
