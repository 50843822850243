const RadiusPositions = [
  {
    radius: 0,
  },
  {
    radius: (-11 * Math.PI) / 6,
  },
  {
    radius: (-5 * Math.PI) / 3,
  },
  {
    radius: (-4 * Math.PI) / 3,
  },
  {
    radius: (-7 * Math.PI) / 6,
  },
  {
    radius: -Math.PI,
  },
  {
    radius: (-5 * Math.PI) / 6,
  },
  {
    radius: (-2 * Math.PI) / 3,
  },
  {
    radius: -Math.PI / 3,
  },
  {
    radius: -Math.PI / 6,
  },
];

export default RadiusPositions;
