import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { withWorkflow } from '@cluedin/form';
import AddUsers from './AddUsers';

export default (Comp, options = {}) =>
  withWorkflow(Comp, AddUsers, {
    title: <FormattedMessage id="module-connector-grantAccessTitle" />,
    panelType: 'medium',
    ...options,
  });
