export const toVM = (projectsData) =>
  projectsData.map((projectData) => {
    const {
      name,
      id,
      rowCount,
      created,
      modified,
      importOptionMetadata = [],
      // ...rest,
    } = projectData;

    const fileSource =
      (importOptionMetadata &&
        importOptionMetadata[0] &&
        importOptionMetadata[0].fileSource) ||
      null;

    return {
      name,
      id,
      rowCount,
      created,
      modified,
      fileSource,
      // ...rest,
    };
  });

export default null;
