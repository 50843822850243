import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import cogoToast from 'cogo-toast';
import gql from 'graphql-tag';

const ENTITY_SPLIT_EXECUTE = gql`
  mutation entitySplitExecute($id: ID!, $params: String!) {
    virtualization {
      entitySplitExecute(id: $id, params: $params) {
        jobId
      }
    }
  }
`;

export const useEntitySplitExecute = (intl, kind) => {
  const [entitySplitExecute, { data, loading, error }] =
    useMutation(ENTITY_SPLIT_EXECUTE);

  const splitPreview = get(data, 'virtualization.entitySplitExecute');

  let successMessage = '';
  if (kind === 'RemoveEntityCode') {
    successMessage = intl.formatMessage({
      id: 'module-entityTopology-splitDialogExecuteSuccess',
    });
  }
  if (kind === 'UndoManualMerge') {
    successMessage = intl.formatMessage({
      id: 'module-entityTopology-manualDialogExecuteSuccess',
    });
  }
  if (kind === 'UndoDeduplicationProjectMerge') {
    successMessage = intl.formatMessage({
      id: 'module-entityTopology-deduplicationDialogExecuteSuccess',
    });
  }

  let errorMessage = '';
  if (kind === 'RemoveEntityCode') {
    errorMessage = intl.formatMessage({
      id: 'module-entityTopology-splitDialogExecuteError',
    });
  }
  if (kind === 'UndoManualMerge') {
    errorMessage = intl.formatMessage({
      id: 'module-entityTopology-manualDialogExecuteError',
    });
  }
  if (kind === 'UndoDeduplicationProjectMerge') {
    errorMessage = intl.formatMessage({
      id: 'module-entityTopology-deduplicationDialogExecuteError',
    });
  }

  useEffect(() => {
    if (!loading && data && !error) {
      cogoToast.success(
        <span data-test="entity-entityTopology-entitySplitExecuteSuccess">
          {successMessage}
        </span>,
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    }
  }, [loading, data]);

  useEffect(() => {
    if (!loading && error) {
      cogoToast.error(
        <span data-test="entity-entityTopology-entitySplitExecuteError">
          {errorMessage}
        </span>,
        {
          position: 'bottom-right',
          hideAfter: 5,
        },
      );
    }
  }, [loading, error]);

  return [
    (id, params) => {
      entitySplitExecute({
        variables: {
          id,
          params,
        },
      });
    },
    {
      data: splitPreview,
      loading,
      error,
    },
  ];
};
