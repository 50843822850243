"use strict";

exports.__esModule = true;
exports.searchForIcons = exports.getIcon = exports.getEntityTypeIcon = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var Icons = _interopRequireWildcard(require("@cluedin/svgs"));
var EntityTypesIcons = _interopRequireWildcard(require("@cluedin/svgs/dist/components/entity-types"));
var MdIcons = _interopRequireWildcard(require("react-icons/md"));
var UxiIcons = _interopRequireWildcard(require("uxi/Icons"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var capitalize = function capitalize(x) {
  return x.charAt(0).toUpperCase() + x.slice(1);
};
var mappedKeys = Object.keys(UxiIcons);
var mdIconsKeys = Object.keys(MdIcons);
var allKeys = [].concat(mappedKeys, mdIconsKeys);
var searchForIcons = function searchForIcons(searchQuery) {
  if (searchQuery === void 0) {
    searchQuery = '';
  }
  var allSearchQueries = searchQuery ? allKeys.filter(function (i) {
    return i.search(new RegExp(searchQuery, 'i')) > -1;
  }) : allKeys;
  return allSearchQueries.filter(function (i) {
    return i !== 'Notifivationcenter';
  });
};
exports.searchForIcons = searchForIcons;
var getEntityTypeIcon = function getEntityTypeIcon(identifier) {
  var _identifier;
  if (identifier === void 0) {
    identifier = '';
  }
  var cleanedIdentifer = capitalize((_identifier = identifier) == null ? void 0 : _identifier.toLowerCase());
  var entityTypesIcons = UxiIcons[cleanedIdentifer];
  if (entityTypesIcons) {
    return entityTypesIcons;
  }
  entityTypesIcons = MdIcons[identifier];
  if (entityTypesIcons) {
    return entityTypesIcons;
  }
  return null;
};
exports.getEntityTypeIcon = getEntityTypeIcon;
var getIcon = function getIcon(identifier) {
  if (identifier === void 0) {
    identifier = '';
  }
  var cleanedIdentifer = capitalize(identifier);
  var entityTypesIcons = Icons[cleanedIdentifer];
  if (entityTypesIcons) {
    return entityTypesIcons;
  }
  entityTypesIcons = EntityTypesIcons[cleanedIdentifer + "Ico"];
  if (entityTypesIcons) {
    return entityTypesIcons;
  }
  entityTypesIcons = MdIcons[identifier];
  if (entityTypesIcons) {
    return entityTypesIcons;
  }
  return EntityTypesIcons['CircleIco'];
};
exports.getIcon = getIcon;