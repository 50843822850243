import { memo, useEffect, useState } from 'react';
import { ListPattern } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { Panel } from '@cluedin/form';
import { uniqBy } from 'lodash';

import { useStaticGoldenRecordList } from '../StaticGoldenRecordListContext';
import { GoldenRecordListMergePanel } from '../GoldenRecordListMergePanel';
import RecordListToolbar from './RecordListToolbar';
import RecordList from '../GoldenRecordList/RecordList/RecordList';

const ActionExplanation = ({ selected, minSelection }) => {
  return (
    <span>
      <FormattedMessage
        id="module-goldenRecordList-actionExpl"
        values={{
          minSelection,
        }}
      />
    </span>
  );
};

const defaultBatchActions = [
  {
    requiredSelection: true,
    id: 'merge',
    label: <FormattedMessage id="module-goldenRecordList-startMergeSelect" />,
    inProgressLabel: (
      <FormattedMessage id="module-goldenRecordList-cancelInProgress" />
    ),
    confirmLabel: <FormattedMessage id="module-goldenRecordList-reviewMerge" />,
    cancelLabel: <FormattedMessage id="module-goldenRecordList-cancel" />,
    minSelection: 2,
    //maxSelection: null,
    ActionExplanation: ActionExplanation,
    onConfirm: (ids = [], { history }) => {
      if (ids.length > 1) {
        const [firstId] = ids;

        history.push(`/entity/merge/${firstId}?ids=${ids.join(',')}`);
      }
    },
  },
];

const StaticRecordList = ({
  noFilters,
  openWorkflow,
  setSearchName,
  showPagination,
  searchNameInput,
  setSearchNameInput,
  listToolbarAdditionalLeftContent,
  listFooterAdditionalRightContent,
}) => {
  const [isMergingPanelOpen, setIsMergingPanelOpen] = useState(false);

  const {
    history,
    hasSelection,
    hideListSearch,
    selectionState: {
      selectionActive,
      selected,
      setSelected,
      toggleSelectionActive,
    },
    listOptions: { total, loading, entities },
    viewOptions: {
      DataGrid = ListPattern,
      DataGridGhost,
      columnsForGrid,
      viewMode,
      setViewMode,
    },
  } = useStaticGoldenRecordList();
  const [listSelectedIds, setListSelectedIds] = useState([]);

  const setSelectedDataByIds = (ids = []) => {
    if (ids.length === 0) {
      return;
    }

    const foundNewSelection = entities.filter((i) => ids.includes(i.id));

    setSelected((prev) => {
      const rawSelected = [...prev, ...foundNewSelection];

      const newSelected = uniqBy(rawSelected, 'id').filter((i) =>
        ids.includes(i.id),
      );

      return newSelected;
    });
  };

  useEffect(() => {
    setSelectedDataByIds(listSelectedIds);
  }, [listSelectedIds]);

  const changeViewMode = (v) => {
    setViewMode(v);

    setSelected(() => []);
    setListSelectedIds(() => []);
    onSelectedChange(() => []);
  };

  const onSelectedChange = (values) => {
    if (viewMode === 'tile') {
      const preparedListSelectedIds = values?.map((i) => i.id);

      setListSelectedIds(preparedListSelectedIds);

      return setSelected(values);
    }

    setListSelectedIds(values);
  };

  const toggleSelection = () => {
    setListSelectedIds(() => []);
    setSelected(() => []);

    toggleSelectionActive();
  };

  const startMerging = () => {
    setIsMergingPanelOpen(() => true);
  };

  const cancelMerging = () => {
    setIsMergingPanelOpen(() => false);
  };

  const preparedSelected = viewMode === 'table' ? listSelectedIds : selected;

  const onUnselectFromListIds = (id) => {
    setListSelectedIds((prev) => prev.filter((i) => i !== id));
  };

  const disabledItemsTooltipHint = (
    <span>
      <FormattedMessage id="module-goldenRecordList-selectMerge-part1" />
      <br />
      <FormattedMessage id="module-goldenRecordList-selectMerge-part2" />
    </span>
  );

  return (
    <>
      <Panel onClose={cancelMerging} open={isMergingPanelOpen}>
        <GoldenRecordListMergePanel
          onClose={cancelMerging}
          onUnselectFromListIds={viewMode === 'table' && onUnselectFromListIds}
        />
      </Panel>

      <RecordList
        List={DataGrid}
        listToolbarAdditionalLeftContent={listToolbarAdditionalLeftContent}
        listFooterAdditionalRightContent={listFooterAdditionalRightContent}
        total={total}
        history={history}
        loading={loading}
        noSearch={noFilters}
        data={entities || []}
        // itemsPerPage={pageSize}
        gridColumns={columnsForGrid}
        GhostLoading={DataGridGhost}
        selected={preparedSelected}
        // selectedPage={selectedPage}
        onSubmitSearch={setSearchName}
        hideListSearch={hideListSearch}
        // setCurrentPage={setCurrentPage}
        showPagination={showPagination}
        searchNameInput={searchNameInput}
        onSelectedChange={onSelectedChange}
        setSearchNameInput={setSearchNameInput}
        selectable={!!(hasSelection && selectionActive)}
        disabledItemsTooltipHint={disabledItemsTooltipHint}
        notFoundProps={{
          message: (
            <FormattedMessage id="module-goldenRecordList-noEntitisFoundMsg" />
          ),
          explanation: (
            <FormattedMessage id="module-goldenRecordList-noEntitisFoundExpl" />
          ),
          noCreate: true,
        }}
        noSearchFoundProps={{
          message: (
            <FormattedMessage id="module-goldenRecordList-noEntitisSearchFoundMsg" />
          ),
          explanation: (
            <FormattedMessage id="module-goldenRecordList-noEntitisSearchFoundExpl" />
          ),
          noCreate: true,
        }}
        filterToolbar={
          <RecordListToolbar
            viewMode={viewMode}
            noFilters={true}
            setViewMode={changeViewMode}
            onCustomizeView={openWorkflow}
            onPrepare={() => setPrepareEntities(true)}
            hasMinimumSelected={selected.length > 1}
            hasSelection={hasSelection}
            toggleSelection={toggleSelection}
            selectionActive={selectionActive}
            startMerging={startMerging}
          />
        }
      />
    </>
  );
};

export default memo(StaticRecordList);
