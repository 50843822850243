import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pulseName } from './pulseKeyframes';

const StatusBadgePlaceholderUI = styled.div`
  width: 18px; /* 14 + 4; */
  height: 14px;
  display: flex;
  align-items: center;
  opacity: 0.1;
  margin: 0 4px 0 0;
`;

const InnerContainer = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  background: rgb(80, 80, 80);
  animation: ${pulseName} 1s linear infinite;
`;

const StatusBadgePlaceholder = ({ style, innerStyle }) => (
  <StatusBadgePlaceholderUI style={style}>
    <InnerContainer style={innerStyle} />
  </StatusBadgePlaceholderUI>
);

StatusBadgePlaceholder.defaultProps = {
  style: {},
  innerStyle: {},
};

StatusBadgePlaceholder.propTypes = {
  style: PropTypes.object,
  innerStyle: PropTypes.object,
};

export default StatusBadgePlaceholder;
