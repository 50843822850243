import { FC, useState, useId } from 'react';
import { ButtonLink, ExpandingArrow } from '@cluedin/atoms';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Checkmark } from 'uxi/Icons';
import { ClipboardIcon } from '@cluedin/svgs';
import { useTheme } from '@cluedin/theme';
import { get } from 'lodash';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import Alert from 'uxi/Alert';

import { copyToClipboard } from '../../../../core/helpers/dom';
import { SelectedEntityNode } from '../../types';
import { CopyCodeWrapper } from './CopyCodeWrapper';
import { EntityNodePanelCodesWrapper } from './EntityNodePanelCodesWrapper';
import { OriginCodeWrapper } from './OriginCodeWrapper';
import { HorizontalLine } from './HorizontalLine';
import { PanelCodeList } from './PanelCodeList';
import { CodeExpansionWrapper } from './CodeExpansionWrapper';
import { CodeExpansionInnerWrapper } from './CodeExpansionInnerWrapper';

type EntityNodePanelCodesProps = {
  selectedNode: SelectedEntityNode;
};

export const EntityNodePanelCodes: FC<EntityNodePanelCodesProps> = ({
  selectedNode,
}) => {
  const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const copyOriginCodeId = useId();
  const originCodeCopiedId = useId();

  const entityCodes = get(selectedNode, 'codes', []);

  const copyToClipboardAndSuccess = () => {
    setShowCopiedMessage(true);
    copyToClipboard(selectedNode?.attributeOrigin);

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  return (
    <div
      style={{
        marginBottom: '20px',
      }}
    >
      <CodeExpansionWrapper isExpanded={isExpanded}>
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ExpandingArrow
            data-test="entityRelations-nodePanelCodes-expandingArrow"
            expanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <FormattedMessage id="module-entityRelationsGraphV2-entityCodesLabel" />
        </div>
      </CodeExpansionWrapper>

      {isExpanded && (
        <CodeExpansionInnerWrapper isExpanded={isExpanded}>
          <EntityNodePanelCodesWrapper>
            <div
              style={{
                fontWeight: 'bold',
                margin: 0,
              }}
            >
              <FormattedMessage id="module-entityRelationsGraphV2-originCodeLabel" />
            </div>

            <ButtonLink
              text={
                <CopyCodeWrapper>
                  {!showCopiedMessage ? (
                    <TooltipHost
                      content={
                        <FormattedMessage id="module-entityRelationsGraphV2-copyOriginCodeLabel" />
                      }
                      id={copyOriginCodeId}
                      calloutProps={{ gapSpace: 0 }}
                    >
                      <ClipboardIcon />
                    </TooltipHost>
                  ) : (
                    <TooltipHost
                      content={
                        <FormattedMessage id="module-entityRelationsGraphV2-originCodeCopiedLabel" />
                      }
                      id={originCodeCopiedId}
                      calloutProps={{ gapSpace: 0 }}
                    >
                      <Checkmark color={themePrimary} size={14} />
                    </TooltipHost>
                  )}
                  <div style={{ marginLeft: '6px' }}>
                    <FormattedMessage id="module-entityRelationsGraphV2-copyCodeLabel" />
                  </div>
                </CopyCodeWrapper>
              }
              onClick={() => copyToClipboardAndSuccess()}
            />
          </EntityNodePanelCodesWrapper>

          <OriginCodeWrapper>
            <span>{selectedNode?.attributeOrigin}</span>
          </OriginCodeWrapper>

          <HorizontalLine />

          <div
            style={{
              fontWeight: 'bold',
              margin: 0,
            }}
          >
            <FormattedHTMLMessage
              id="module-entityRelationsGraphV2-codesWithCountLabel"
              values={{ entityCodeCount: entityCodes?.length }}
            />
          </div>

          {!entityCodes ||
            (entityCodes?.length < 1 && (
              <div style={{ margin: '10px 20px 0 0', width: '100%' }}>
                <Alert type="information">
                  <FormattedMessage id="module-entityRelationsGraphV2-noCodesLabel" />
                </Alert>
              </div>
            ))}

          <PanelCodeList>
            {Array.isArray(entityCodes) &&
              entityCodes?.map((entityCode) => (
                <li key={entityCode}>{entityCode}</li>
              ))}
          </PanelCodeList>
        </CodeExpansionInnerWrapper>
      )}
    </div>
  );
};
