import React from 'react';
import PropTypes from 'prop-types';
import { registerEntityWidget } from '../../registry';
import Conversation from './Conversation';
import { findEntityDiscussionContent } from './discussionHelper';

const EntityDiscussion = ({ entity }) => {
  let isDiscussion = false;
  let descriptionMessages;

  const actualDiscussion = findEntityDiscussionContent(entity);

  if (actualDiscussion) {
    try {
      descriptionMessages = JSON.parse(actualDiscussion);
      isDiscussion = true;
    } catch (e) {
      // eslint-disable-line no-empty
    }
  }

  if (isDiscussion) {
    return <Conversation messages={descriptionMessages} />;
  }
  return <div>{entity.data.description}</div>;
};

EntityDiscussion.propTypes = {
  entity: PropTypes.object.isRequired,
};

EntityDiscussion.defaultProps = {
  entity: {},
};

registerEntityWidget('EntityDiscussion', {
  name: 'EntityDiscussion',
  displayName: 'Discussion Content',
  description: 'Display the details of a discussion',
  view: EntityDiscussion,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
