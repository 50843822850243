import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import GraphiQlPage from '../development/components/pages/GraphiQlPage';
import { RACI_LEVELS } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

const { consume } = actionsConfiguration;

export default {
  pillar: 'consume',
  name: 'GraphQL',
  displayName: <FormattedMessage id="GraphQL" />,
  path: '/graphql',
  extendPillarDashboard: {
    actions: [
      {
        icon: 'Database',
        name: <FormattedMessage id="GraphQL" />,
        link: '/admin/consume/graphql',
        claims: consume.graphQL.graphiQL.view,
      },
    ],
  },
  routes: [
    {
      path: '/',
      exact: true,
      component: GraphiQlPage,
      claims: {
        'consume.graphql': RACI_LEVELS.CONSULTANT,
      },
    },
  ],
};
