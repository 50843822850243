"use strict";

exports.__esModule = true;
exports.default = useEventCallback;
var _react = require("react");
var useEnhancedEffect = typeof window !== 'undefined' ? _react.useLayoutEffect : _react.useEffect;

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * @param {function} fn
 */
function useEventCallback(fn) {
  var ref = (0, _react.useRef)(fn);
  useEnhancedEffect(function () {
    ref.current = fn;
  });
  return (0, _react.useCallback)(function () {
    return (0, ref.current).apply(void 0, arguments);
  }, []);
}