import React from 'react';
import { TableRow, TableRowColumn, TableBody } from '@cluedin/list-pattern';
import Table from './TableExtended';
import Section from './Section';
import ValueWrapper from './ValueWrapper';

const Location = ({ jsonReport }) => {
  const { DataLocation } = jsonReport;

  return (
    <Section jsonReport={jsonReport}>
      <h2>Data Location</h2>
      {DataLocation &&
        DataLocation.length > 0 &&
        DataLocation.map(($values) => {
          return (
            $values &&
            $values.map(({ provider }) => {
              const { Name, ...rest } = provider;

              return (
                <div>
                  <h4>{Name}</h4>
                  <Table>
                    <TableBody>
                      {Object.keys(rest).map((k) => (
                        <TableRow>
                          <TableRowColumn width="30%">{k}: </TableRowColumn>
                          <TableRowColumn width="70%">
                            <ValueWrapper>{rest[k]}</ValueWrapper>
                          </TableRowColumn>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              );
            })
          );
        })}
    </Section>
  );
};

export default Location;
