import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { EntityTopology } from '../../../../../components/graphs/EntityTopology';
import EntityCodePanelAPIContainer from './EntityCodePanelAPIContainer';
import RecordPanelAPIContainer from './RecordPanelAPIContainer';
import ManualMergeAPIContainer from './ManualMergeAPIContainer';
import DeduplicationProjectMergePanelAPIContainer from './DeduplicationProjectMergePanelAPIContainer';

const EntityTopologyContainer = ({
  entityId,
  currentNode,
  setCurrentNode,
  topologyData,
  topologyLoading,
  topologyError,
  setFilter,
  filter,
  nodeDataType,
  setNodeDataType,
  handleExecuteAction,
  intl,
}) => {
  const handleNodeOnClick = (dataType) => {
    setNodeDataType(dataType);
  };

  return (
    <>
      {currentNode && nodeDataType === 'EntityCode' && (
        <EntityCodePanelAPIContainer
          entityId={entityId}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          currentNode={currentNode}
          handleExecuteAction={handleExecuteAction}
        />
      )}

      {currentNode && nodeDataType === 'DataPart' && (
        <RecordPanelAPIContainer
          entityId={entityId}
          currentNode={currentNode}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
        />
      )}

      {currentNode && nodeDataType === 'ManualMerge' && (
        <ManualMergeAPIContainer
          entityId={entityId}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          currentNode={currentNode}
          handleExecuteAction={handleExecuteAction}
        />
      )}

      {currentNode && nodeDataType === 'DeduplicationProjectMerge' && (
        <DeduplicationProjectMergePanelAPIContainer
          entityId={entityId}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          currentNode={currentNode}
          handleExecuteAction={handleExecuteAction}
        />
      )}

      {topologyData && !topologyLoading && (
        <EntityTopology
          nodes={topologyData?.nodes || []}
          edges={topologyData?.edges || []}
          onNodeClick={(n) => {
            setCurrentNode(n);
            handleNodeOnClick(n?.dataType);
          }}
          onFilter={(active) => {
            active ? setFilter('none') : setFilter('');
          }}
          filter={filter}
          loading={topologyLoading}
          error={topologyError}
          labels={{
            updateDateLabel: intl.formatMessage({
              id: 'module-entityTopology-lastUpdateLabel',
            }),
            filtersLabel: intl.formatMessage({
              id: 'module-entityTopology-filterFiltersLabel',
            }),
            filtersDeadEndNodes: intl.formatMessage({
              id: 'module-entityTopology-filterDeadEndNodes',
            }),
            resetNodesLabel: intl.formatMessage({
              id: 'module-entityTopology-filterResetNodesLabel',
            }),
          }}
        />
      )}
    </>
  );
};

export default injectIntl(EntityTopologyContainer);

EntityTopologyContainer.propTypes = {
  currentNode: PropTypes.object,
  entityId: PropTypes.string,
  handleExecuteAction: PropTypes.func,
  nodeDataType: PropTypes.string,
  setCurrentNode: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.any,
  setNodeDataType: PropTypes.func,
  topologyData: PropTypes.array,
  topologyError: PropTypes.any,
  topologyLoading: PropTypes.bool,
};

EntityTopologyContainer.defaultProps = {
  currentNode: {},
  entityId: null,
  handleExecuteAction: () => {},
  nodeDataType: '',
  setCurrentNode: () => {},
  setFilter: () => {},
  filter: null,
  setNodeDataType: () => {},
  topologyData: [],
  topologyError: null,
  topologyLoading: false,
};
