import React from 'react';
import { connect } from 'react-redux';

const createVmForEntityType = ({ value, count }, entityConfigurations = []) => {
  const entityConfigForCode =
    entityConfigurations.find(
      (e) => e.entityType.toLowerCase() === value.toLowerCase(),
    ) || {};
  return {
    entityType: value,
    displayName: entityConfigForCode.displayName || value,
    icon: entityConfigForCode.icon,
    path: entityConfigForCode.path || 'entity',
    template: entityConfigForCode.template || 'Default',
    hasConfig: !!(
      entityConfigForCode &&
      entityConfigForCode.icon &&
      entityConfigForCode.displayName
    ),
    count: count || 0,
  };
};

const createVmForEntityTypes = (entityTypes = [], entityConfigurations = []) =>
  entityTypes.map((e) => createVmForEntityType(e, entityConfigurations));

const mapStateToProps = ({ wms: { entityConfigurations } }) => {
  return {
    entityConfigurations: entityConfigurations || [],
  };
};

const withEntityViewModel = (Comp) => {
  return connect(mapStateToProps)((props) => {
    const entityTypes =
      props && props.promotedFilters && props.promotedFilters.entityType
        ? props.promotedFilters.entityType
        : [];

    return (
      <Comp
        {...props}
        entityTypeViewModels={createVmForEntityTypes(
          entityTypes,
          props.entityConfigurations,
        )}
      />
    );
  });
};

export default withEntityViewModel;
