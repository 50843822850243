import { apiRequest, publicApiRequest } from '../core/helpers/request';

export const markAsSensitive = (entityId) =>
  apiRequest('POST', `api/entity/sensitive?id=${entityId}`).then(
    () => entityId,
  );

export const markAsUnSensitive = (entityId) =>
  apiRequest('POST', `api/entity/unsensitive?id=${entityId}`).then(
    () => entityId,
  );

/**
 *
 * {
 *   "Action": "UPDATE",
 *   "Query": "5ef78752-57db-554d-8c7a-91d7a389faea",
 *   "Transform": [{
 *     "key": "cluedIn.company.annualRevenue",
 *     "value": 14039292
 *   }],
 *   "IsQueued" :  true
 * }
 */
export const updateEntity = ({ entityId, propertyValues }) => {
  const payload = {
    Action: 'UPDATE',
    Query: entityId,
    Transform: propertyValues,
    IsQueued: true,
  };

  return publicApiRequest('POST', 'api/v1/mesh', payload);
};

export const approveMeshCmd = (meshMsg) => {
  return publicApiRequest('POST', 'api/v1/mesh', {
    ProviderDefinitionId: meshMsg.ProviderDefinitionId,
    Action: meshMsg.Action,
    Query: meshMsg.Query,
    Id: meshMsg.Id,
    Transform: JSON.parse(meshMsg.TransformString).map(({ key, value }) => ({
      key,
      value,
    })),
    IsQueued: false,
    RemoveFromCluedIn: meshMsg.RemoveFromCluedIn,
    DisconnectAccountAndRemoveFromCluedIn:
      meshMsg.DisconnectAccountAndRemoveFromCluedIn,
  });
};

export const rejectMeshCmd = ({ meshMsgId }) =>
  apiRequest('POST', `api/mesh/reject?id=${meshMsgId}`);

export const executeUpdateEntity = ({ entityId, key, value }) => {
  const payload = {
    Action: 'UPDATE',
    Query: entityId,
    Transform: [
      {
        key,
        value,
      },
    ],
    IsQueued: true,
  };

  return publicApiRequest('POST', 'api/v1/mesh', payload);
};

export function followEntity(entity) {
  return apiRequest('POST', `api/insight?id=${entity.id}`);
}

export function unFollowEntity(entity) {
  return apiRequest('DELETE', `api/insight?id=${entity.id}`);
}
