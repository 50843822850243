export default {
  REQUEST_ALL_USERS: 'USER_REQUEST_ALL_USERS',
  RECEIVE_ALL_USERS: 'USER_RECEIVE_ALL_USERS',
  INVALID_ALL_USERS: 'USER_INVALID_ALL_USERS',
  REQUEST_INVITE_USER: 'USER_REQUEST_INVITE_USER',
  RECEIVE_INVITE_USER: 'USER_RECEIVE_INVITE_USER',
  INVALID_INVITE_USER: 'USER_INVALID_INVITE_USER',
  REQUEST_RESEND_INVITATION: 'USER_REQUEST_RESEND_INVITATION',
  RECEIVE_RESEND_INVITATION: 'USER_RECEIVE_RESEND_INVITATION',
  INVALID_RESEND_INVITATION: 'USER_INVALID_RESEND_INVITATION',
  REQUEST_PROMOTE_USER: 'USER_REQUEST_PROMOTE_USER',
  RECEIVE_PROMOTE_USER: 'USER_RECEIVE_PROMOTE_USER',
  INVALID_PROMOTE_USER: 'USER_INVALID_PROMOTE_USER',
  REQUEST_DEMOTE_USER: 'USER_REQUEST_DEMOTE_USER',
  RECEIVE_DEMOTE_USER: 'USER_RECEIVE_DEMOTE_USER',
  INVALID_DEMOTE_USER: 'USER_INVALID_DEMOTE_USER',
  REQUEST_BLOCK: 'USER_REQUEST_BLOCK',
  RECEIVE_BLOCK: 'USER_RECEIVE_BLOCK',
  INVALID_BLOCK: 'USER_INVALID_BLOCK',
  RECEIVE_UNBLOCK: 'USER_RECEIVE_UNBLOCK',
  REQUEST_UNBLOCK: 'USER_REQUEST_UNBLOCK',
  INVALID_UNBLOCK: 'USER_INVALID_UNBLOCK',
  REQUEST_FIND_IF_USER_EXIST: 'USER_REQUEST_FIND_IF_USER_EXIST',
  RECEIVE_FIND_IF_USER_EXIST: 'USER_RECEIVE_FIND_IF_USER_EXIST',
  INVALID_FIND_IF_USER_EXIST: 'USER_INVALID_FIND_IF_USER_EXIST',
  REQUEST_FIND_IF_EMAIL_EXIST: 'USER_REQUEST_FIND_IF_EMAIL_EXIST',
  RECEIVE_FIND_IF_EMAIL_EXIST: 'USER_RECEIVE_FIND_IF_EMAIL_EXIST',
  INVALID_FIND_IF_EMAIL_EXIST: 'USER_INVALID_FIND_IF_EMAIL_EXIST',
  REQUEST_POTENTIAL_USERS: 'USER_REQUEST_POTENTIAL_USERS',
  RECEIVE_POTENTIAL_USERS: 'USER_RECEIVE_POTENTIAL_USERS',
  INVALID_POTENTIAL_USERS: 'USER_INVALID_POTENTIAL_USERS',
  REQUEST_CURRENT_USER: 'USER_REQUEST_CURRENT_USER',
  RECEIVE_CURRENT_USER: 'USER_RECEIVE_CURRENT_USER',
  INVALID_CURRENT_USER: 'USER_INVALID_CURRENT_USER',
  REQUEST_OPEN_INVITE_USER_DIALOG: 'USER_REQUEST_OPEN_INVITE_USER_DIALOG',
  REQUEST_CLOSE_INVITE_USER_DIALOG: 'USER_REQUEST_CLOSE_INVITE_USER_DIALOG',
  RECEIVE_EMAIL_IS_ALREADY_TAKEN: 'USER_RECEIVE_EMAIL_IS_ALREADY_TAKEN',
  REQUEST_REMOVE_INVITATION: 'USER_REQUEST_REMOVE_INVITATION',
  RECEIVE_REMOVE_INVITATION: 'USER_RECEIVE_REMOVE_INVITATION',
  INVALID_REMOVE_INVITATION: 'USER_INVALID_REMOVE_INVITATION',
  RECEIVE_PROMOTE_INFORMATION: 'RECEIVE_PROMOTE_INFORMATION',
  RESET_PROMOTE_INFORMATION: 'RESET_PROMOTE_INFORMATION',
  RECEIVE_DEMOTE_INFORMATION: 'RECEIVE_DEMOTE_INFORMATION',
  RESET_DEMOTE_INFORMATION: 'RESET_DEMOTE_INFORMATION',
  REQUEST_CHANGE_PASSWORD: 'REQUEST_CHANGE_PASSWORD',
  RECEIVE_CHANGE_PASSWORD: 'RECEIVE_CHANGE_PASSWORD',
  INVALID_CHANGE_PASSWORD: 'INVALID_CHANGE_PASSWORD',
  RESET_CHANGE_PASSWORD: 'RESET_CHANGE_PASSWORD',
  REQUEST_NEW_USER_INVITED_INFORMATION: 'REQUEST_NEW_USER_INVITED_INFORMATION',
  RESET_NEW_USER_INVITED_INFORMATION: 'RESET_NEW_USER_INVITED_INFORMATION',
  RECEIVE_USERS: 'RECEIVE_USERS',
  REQUEST_USERS: 'REQUEST_USERS',
  INVALID_USERS: 'INVALID_USERS',
  REQUEST_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE:
    'REQUEST_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE',
  RECEIVE_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE:
    'RECEIVE_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE',
  INVALID_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE:
    'INVALID_ADD_WIDGET_CONFIGURATION_TO_USER_PROFILE',
  REQUEST_REMOVE_WIDGET_TO_USER_PROFILE:
    'REQUEST_REMOVE_WIDGET_TO_USER_PROFILE',
  INVALID_REMOVE_WIDGET_TO_USER_PROFILE:
    'INVALID_REMOVE_WIDGET_TO_USER_PROFILE',
  RECEIVE_REMOVE_WIDGET_TO_USER_PROFILE:
    'RECEIVE_REMOVE_WIDGET_TO_USER_PROFILE',
  REQUEST_USER_ACCOUNT: 'REQUEST_USER_ACCOUNT',
  RECEIVE_USER_ACCOUNT: 'RECEIVE_USER_ACCOUNT',
  INVALID_USER_ACCOUNT: 'INVALID_USER_ACCOUNT',
  REQUEST_UPDATE_ROLE: 'REQUEST_UPDATE_ROLE',
  RECEIVE_UPDATE_ROLE: 'RECEIVE_UPDATE_ROLE',
  INVALID_UPDATE_ROLE: 'INVALID_UPDATE_ROLE',
  REQUEST_ALL_PROVIDER_FOR_USER: 'REQUEST_ALL_PROVIDER_FOR_USER',
  RECEIVE_ALL_PROVIDER_FOR_USER: 'RECEIVE_ALL_PROVIDER_FOR_USER',
  INVALID_ALL_PROVIDER_FOR_USER: 'INVALID_ALL_PROVIDER_FOR_USER',
  SHOULD_REMOVE_CONFIGURATION_FROM_PERMISSION:
    'SHOULD_REMOVE_CONFIGURATION_FROM_PERMISSION',
  SHOULD_ADD_CONFIGURATION_FROM_PERMISSION:
    'SHOULD_ADD_CONFIGURATION_FROM_PERMISSION',
};
