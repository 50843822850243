import styled from 'styled-components';

export const EntityRelationsGraphV3LoaderWrapper = styled.div`
  align-items: center;
  background: #ececec;
  display: flex;
  justify-content: center;
  height: calc(100vh - 165px) !important;
  max-height: calc(100vh - 165px) !important;
  overflow: hidden !important;
  width: 100%;

  & svg {
    height: 200px;
    max-height: 200px;
    overflow: hidden !important;
    width: 100%;
    max-width: 100%;
  }
`;
