import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import cogoToast from 'cogo-toast';

const APPROVE_MESH_COMMAND = gql`
  mutation approveMeshCommand($meshMessage: JSON!) {
    virtualization {
      approveMeshCommand(meshMessage: $meshMessage) {
        success
        errorMessage
        response
      }
    }
  }
`;

export const useApproveMeshCommand = (meshMessage) => {
  const [approveMeshCommand, { data, loading, error }] = useMutation(
    APPROVE_MESH_COMMAND,
    {
      onCompleted: () => {
        cogoToast.success('Change approved successfully', {
          position: 'bottom-right',
          hideAfter: 5,
        });
      },
      onError: () => {
        cogoToast.error('Error approving change', {
          position: 'bottom-right',
          hideAfter: 5,
        });
      },
    },
  );

  const approvalResponse = get(data, 'virtualization.approveMeshCommand');

  return [
    () => {
      approveMeshCommand({
        variables: {
          meshMessage,
        },
      });
    },
    { data: approvalResponse, loading, error },
  ];
};
