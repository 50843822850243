import React from 'react';
import styled from 'styled-components';
import FilterGroupMeta from './FilterGroupMeta';

const FilterEditIndicatorUI = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: lightgrey;
  font-size: 12px;
  cursor: pointer;
  padding-top: 16px;
`;

const FilterEditIndicator = ({
  children,
  filteredCount,
  isRemoteFiltering,
  selectedCount,
  totalCount,
  isFetching,
  show,
  currentEntityTypePoolIdentifier,
  currentFilterQuery,
}) => {
  return (
    <FilterEditIndicatorUI>
      {children}
      <FilterGroupMeta
        filteredCount={filteredCount}
        isRemoteFiltering={isRemoteFiltering}
        selectedCount={selectedCount}
        totalCount={totalCount}
        isFetching={isFetching}
        show={show}
        currentEntityTypePoolIdentifier={currentEntityTypePoolIdentifier}
        currentFilterQuery={currentFilterQuery}
      />
    </FilterEditIndicatorUI>
  );
};

export default FilterEditIndicator;
