import React, { useEffect } from 'react';
import {
  PlusCircleThin,
  MinusCircleThin,
  TargetIcon,
  FitScreenIcon,
} from '@cluedin/svgs';
import { useTheme } from '@cluedin/theme';
import { FullScreenIcon } from '@fluentui/react-icons-mdl2';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99999,
  },
  item: {
    boxShadow: '0px 1px 3px #0000001A',
    backgroundColor: 'white',
    marginBottom: 10,
    padding: 10,
  },
};

const ZoomControl = ({
  onIncrease,
  onDecrease,
  onCenter,
  onFit,
  onFullScreen,
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.themePrimary;

  useEffect(() => {}, []);

  return (
    <div style={styles.wrapper}>
      <a href="#" onClick={onIncrease} style={styles.item} title="Zoom In">
        <PlusCircleThin fill={primaryColor} width={24} height={24} />
      </a>
      <a href="#" onClick={onCenter} style={styles.item}>
        <TargetIcon fill={primaryColor} width={24} height={24} />
      </a>
      {onFit && (
        <a href="#" onClick={onFit} style={styles.item}>
          <FitScreenIcon fill={primaryColor} width={24} height={24} />
        </a>
      )}
      <a href="#" onClick={onDecrease} style={styles.item} title="Zoom Out">
        <MinusCircleThin fill={primaryColor} width={24} height={24} />
      </a>
      {onFullScreen && (
        <a
          href="#"
          onClick={onFullScreen}
          style={styles.item}
          title="Full Screen"
        >
          <FullScreenIcon
            fill={primaryColor}
            style={{ width: 24, height: 24, display: 'block' }}
          />
        </a>
      )}
    </div>
  );
};

export default ZoomControl;
