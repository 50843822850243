import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Widget from 'uxi/Widget';

const SomethingOdd = ({ text, status }) => (
  <div style={{ margin: '30px', background: '#fff' }}>
    <div style={{ background: '#e91e1e36' }}>
      <Widget
        title={<FormattedMessage id="module-error-somethingOddHappened" />}
      >
        <div style={{ padding: '30px' }}>
          <div style={{ marginBottom: '30px', color: 'black' }}>
            <strong>
              <FormattedMessage id="module-error-ErrorMessage" />
            </strong>
          </div>
          <div>
            {status && (
              <span>
                <FormattedMessage id="module-error-ErrorHttp" /> {status}
                <br />
                <br />
              </span>
            )}
            {text || <FormattedMessage id="module-error-unkownErrorOccured" />}
          </div>
          <div style={{ marginTop: '30px' }}>
            <strong style={{ marginRight: '5px' }}>
              <FormattedMessage id="module-error-source" />
            </strong>
            {window.location.href}
          </div>
        </div>
      </Widget>
    </div>
  </div>
);

export default SomethingOdd;
