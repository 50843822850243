/*eslint-disable */
const registry = (window.__cluedin_in = window.__cluedin_in || {});
/*eslint-disable */

export const register = (name, classObject, descriptor) => {
  registry[name] = {
    hasCode: true,
    component: classObject,
    descriptor,
  };
};

export const registerCodeLess = (name, descriptor) => {
  registry[name] = {
    hasCode: false,
    descriptor,
  };
};

export const registerEntityWidget = (name, descriptor) => {
  if (!descriptor.type) {
    descriptor.type = 'Entity';
  }

  registry[name] = {
    hasCode: false,
    descriptor,
  };
};
