import { useQuery, useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';

export const GET_ROLE = gql`
  query getRoleDetail($roleName: String) {
    administration {
      id
      role(roleName: $roleName) {
        id
        name
        description
        userCount
        users {
          id
          username
          roles
        }
        claims {
          id
          friendlyName
          name
          value
        }
      }
    }
  }
`;

export const useQueryLazyRole = () => {
  const [getByName, { data, loading, error }] = useLazyQuery(GET_ROLE);

  const role = get(data, 'administration.role');

  return [
    (roleName) => {
      getByName({
        fetchPolicy: 'no-cache',
        variables: {
          roleName,
        },
      });
    },
    {
      data: role,
      loading,
      error,
    },
  ];
};

export const useQueryRole = (roleName) => {
  const variables = { roleName };
  const fetchPolicy = 'network-only';
  const { data, loading, error, refetch } = useQuery(GET_ROLE, {
    variables,
    fetchPolicy,
  });
  const role = get(data, 'administration.role');

  return [role, loading, error, refetch];
};
