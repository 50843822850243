import { useMutation, gql } from '@apollo/client';

const DELETE_SEARCH = gql`
  mutation deleteSearch($searchId: String) {
    administration {
      deleteSearch(searchId: $searchId)
    }
  }
`;

export const useDeleteSavedSearch = () => {
  const [deleteSearch, { loading }] = useMutation(DELETE_SEARCH);

  const deleteSavedSearch = async (searchId: string) => {
    return await deleteSearch({
      variables: {
        searchId,
      },
    });
  };

  return {
    loading,
    deleteSavedSearch,
  };
};
