import React from 'react';
import PageLoader from '../../../../core/components/composites/PageLoader';
import GraphWidgetWrapper from '../../containers/reports/GraphWidgetWrapper';

const PageLoaderExtended = () => (
  <GraphWidgetWrapper ratio={false}>
    <PageLoader />
  </GraphWidgetWrapper>
);

export default PageLoaderExtended;
