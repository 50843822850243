"use strict";

exports.__esModule = true;
exports.useUserHasAccess = void 0;
var _react = require("react");
var _bootstrap = require("@cluedin/bootstrap");
var _hasAnyClaim = require("./hasAnyClaim");
var useUserHasAccess = function useUserHasAccess(requiredClaims) {
  if (requiredClaims === void 0) {
    requiredClaims = {};
  }
  var userClaims = (0, _react.useContext)(_bootstrap.UserClaimsContext);
  return (0, _hasAnyClaim.hasAnyClaim)(userClaims, requiredClaims);
};
exports.useUserHasAccess = useUserHasAccess;