import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import GlossaryIcon from './components/composites/icons/GlossaryIcon';

const { management } = actionsConfiguration;

export default {
  pillar: 'management',
  name: 'glossary',
  displayName: <FormattedMessage id="glossary" />,
  path: '/glossary',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: GlossaryIcon,
        name: <FormattedMessage id="glossary" />,
        link: '/admin/management/glossary',
        claims: management.glossary.category.view,
      },
    ],
  },
  routes,
};
