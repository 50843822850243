const setSizeAndOptions = (network, graphWidth, graphHeight) => {
  network.setSize(
    `${Math.round(graphWidth)}px`,
    `${Math.round(graphHeight)}px`,
  );
  network.setOptions({
    width: `${Math.round(graphWidth)}px`,
    height: `${Math.round(graphHeight)}px`,
  });
};

export const resizeNetwork = (network) => {
  const windowFactor = 0.92;
  const graphWidth = `${
    Math.round(document?.body?.clientWidth * windowFactor) || 600
  }px`;
  const graphHeight = `${
    Math.round(document?.body?.clientHeight * windowFactor) || 600
  }px`;
  if (parseInt(graphWidth) && parseInt(graphHeight)) {
    window.addEventListener('resize', () => {
      const newGraphWidth = `${
        Math.round(document?.body?.clientWidth * windowFactor) || 600
      }px`;
      const newGraphHeight = `${
        Math.round(document?.body?.clientHeight * windowFactor) || 600
      }px`;
    });
  }
  setSizeAndOptions(network, graphWidth, graphHeight);
};

export const isSupportedCardType = (cardType) => {
  switch (cardType) {
    case 'EntityCode':
      return cardType;
    case 'DataPart':
      return cardType;
    case 'DeduplicationProjectMerge':
      return cardType;
    case 'ManualMerge':
      return cardType;
    case 'FuzzyMatch':
      return cardType;
    case 'Clean':
      return cardType;
    case 'LegacyClean':
      return cardType;
    case 'UserInput':
      return cardType;
    default:
      return;
  }
};

export const isSupportedCardTypeForLabel = (cardType, intl) => {
  switch (cardType) {
    case 'EntityCode':
      return cardType;
    case 'DataPart':
      return cardType;
    case 'DeduplicationProjectMerge':
      return cardType;
    case 'ManualMerge':
      return cardType;
    case 'FuzzyMatch':
      return cardType;
    case 'Clean':
      return cardType;
    case 'LegacyClean':
      return intl.formatMessage({
        id: 'module-entityTopology-sourceTextClean',
      });
    case 'UserInput':
      return intl.formatMessage({
        id: 'module-entityTopology-sourceManuallyAdded',
      });
    default:
      return;
  }
};
