import { useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
import { useTheme } from '@cluedin/theme';
import { DataGridv2 } from '@cluedin/list-pattern';
import { useIntl } from 'react-intl';

import {
  EdgePropertiesPanelEdgeWrapper,
  EdgePropertiesPanelEdgeInnerWrapper,
  EdgePropertiesPanelEdgeHeader,
  EdgePropertiesPanelEdgeBorderCircle,
  EdgePropertiesPanelEdgeDetailWrapper,
  EdgePropertiesPanelEdgeDetailListWrapper,
} from './EdgePropertiesPanelEdge.styles';
import PanelEdgeHeading from './PanelEdgeHeading/PanelEdgeHeading';

const EdgePropertiesPanelEdge = ({ edge, edges, edgeDirection }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [isExpanded, setIsExpanded] = useState(false);

  const edgeListData = Object.keys(edge?.properties)
    ?.map((key) => [
      {
        property: String(key),
        value: edge?.properties[key],
      },
    ])
    ?.flat(1);

  const isOutgoing = edgeDirection === 'outgoing';

  return (
    <div style={{ padding: '0 8px 0 0' }}>
      <EdgePropertiesPanelEdgeWrapper>
        <EdgePropertiesPanelEdgeInnerWrapper
          data-test="entity-entityRelations-edgePropertiesPanelEdge"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <EdgePropertiesPanelEdgeHeader>
            <ExpandingArrow
              expanded={isExpanded}
              data-test="entity-entityRelations-expandingArrow"
            />
            <EdgePropertiesPanelEdgeBorderCircle theme={theme} />
            <PanelEdgeHeading
              isOutgoing={isOutgoing}
              edges={edges}
              edge={edge}
            />
          </EdgePropertiesPanelEdgeHeader>
        </EdgePropertiesPanelEdgeInnerWrapper>

        <EdgePropertiesPanelEdgeDetailWrapper isExpanded={isExpanded}>
          <EdgePropertiesPanelEdgeDetailListWrapper>
            <DataGridv2
              allowInlinePropertySelection={false}
              data={edgeListData || []}
              model={[
                {
                  property: 'property',
                  displayName: formatMessage({
                    id: 'module-entityRelationsGraph-edgePropertiesEntryProperty',
                  }),
                },
                {
                  property: 'value',
                  displayName: formatMessage({
                    id: 'module-entityRelationsGraph-edgePropertiesEntryValue',
                  }),
                },
              ]}
            />
          </EdgePropertiesPanelEdgeDetailListWrapper>
        </EdgePropertiesPanelEdgeDetailWrapper>
      </EdgePropertiesPanelEdgeWrapper>
    </div>
  );
};

export default EdgePropertiesPanelEdge;
