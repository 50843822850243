import styled from 'styled-components';

const DataGridStylesOverwrrideNotInUxiBecauseInAHurryToRelease = styled.div`
  table {
    margin-bottom: 2px;
  }
  th {
    height: 48px;
  }
  td {
    height: 48px;
  }
`;

export default DataGridStylesOverwrrideNotInUxiBecauseInAHurryToRelease;
