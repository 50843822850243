import React, { useEffect } from 'react';
import { signInAndRedirect } from '../../oidbc';
import { connect } from 'react-redux';
import { shoudFetchPublicOrgInfo } from '../../../public/actions/public';
import { clientId } from '../../../../config';
import { compose, lifecycle, branch, renderComponent } from 'recompose';
import { withRouter } from 'react-router';
import OrgClientIdNotFoundComponent from '../../../public/components/containers/OrgClientIdNotFoundComponent';
import OrgClientIdInvalidComponent from '../../../public/components/containers/OrgClientIdInvalidComponent';
import PageLoader from '../../../core/components/composites/PageLoader';
import { get } from 'lodash';

let redirect = false;
const SSOLoginContainer = ({ teamDomain }) => {
  const ssoSignOn = get(teamDomain, 'SingleSignOn');

  useEffect(() => {
    if (ssoSignOn && !redirect) {
      redirect = true;
      signInAndRedirect(ssoSignOn);
    }
  }, [ssoSignOn]);

  return <span />;
};

const EnhancedSSOLoginContainer = compose(
  lifecycle({
    componentDidMount() {
      const { fetchTeamDomain } = this.props;
      if (fetchTeamDomain) {
        fetchTeamDomain();
      }
    },
  }),
  branch(
    (props) => props.isFetching === true || !props.teamDomain,
    renderComponent(PageLoader),
  ),
  branch(
    (props) => props.invalid === true,
    renderComponent(OrgClientIdInvalidComponent),
  ),
  branch(
    (props) => props.isAvailable === true,
    renderComponent(OrgClientIdNotFoundComponent),
  ),
)(SSOLoginContainer);

const mapStateToProps = (
  { publicModule: { isFetching, result, invalid } },
  { location },
) => {
  const { pathname = '' } = location;
  return {
    isFetching,
    teamDomain: result,
    invalid,
    isAvailable: pathname !== '/404' && result && result.isAvailable,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchTeamDomain: () => {
    if (clientId) {
      dispatch(shoudFetchPublicOrgInfo(clientId));
    }
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnhancedSSOLoginContainer),
);
