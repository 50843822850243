import { useId } from 'react';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import _ from 'lodash';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import EntityTypeAvatar from '../../../../entityTypes/components/containers/EntityTypeAvatar';
import FormattedEntityName from '../../../../entityRelationsGraph/containers/GroupedEntitiesPanel/GroupedEntitiesPanelList/FormattedEntityName/FormattedEntityName';
import FormattedEntityCode from '../../../../entityRelationsGraph/containers/GroupedEntitiesPanel/GroupedEntitiesPanelList/FormattedEntityCode/FormattedEntityCode';

export const columns = [
  {
    property: 'referencedEntityName',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEntityName" />
    ),
    Component: FormattedEntityName,
  },
  {
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgeProperties" />
    ),
    Component: (props: undefined | { properties: Record<string, unknown> }) => {
      const edgePropertiesTooltipId = useId();
      const theme = useTheme();
      const properties = props?.properties ?? {};

      return (
        <div>
          {_.isEmpty(properties) ? (
            <em
              style={{
                color: theme?.semanticColors?.inputPlaceholderText ?? '#9B9B9C',
              }}
            >
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListNoEdgeProperties" />
            </em>
          ) : (
            <TooltipHost
              id={edgePropertiesTooltipId}
              calloutProps={{ gapSpace: 0 }}
              content={
                <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgePropertiesTooltip" />
              }
            >
              {Object.keys(properties)?.length}&nbsp;
              <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgePropertiesCount" />
            </TooltipHost>
          )}
        </div>
      );
    },
  },
  {
    property: 'referencedEntityType',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEntityType" />
    ),
    Component: ({ referencedEntityType }: { referencedEntityType: string }) => (
      <EntityTypeAvatar
        entityType={referencedEntityType}
        color={null}
        onlyLabel={null}
        onlyIcon={null}
      />
    ),
  },
  {
    property: 'referencedEntityCode',
    displayName: (
      <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEntityCode" />
    ),
    Component: ({ referencedEntityCode }: { referencedEntityCode: string }) => (
      <FormattedEntityCode entityCode={referencedEntityCode} />
    ),
    width: '150px',
  },
];
