import React from 'react';
import DataGrid from 'uxi/DataGrid';
import EntityTableDataGridOverwrites from './EntityTableDataGridOverwrites';
import TextPlaceholder from '../../../core/components/composites/Placeholder/TextPlaceholder';
import { getRandIntInRange } from '../../../core/helpers/numbers';

const loadingData = [...new Array(8)].map(() => ({}));
const loadingProperties = [...new Array(8)].map((_, i) => ({
  displayName: (
    <TextPlaceholder
      width={`${getRandIntInRange(...(i === 0 ? [142, 190] : [90, 138]))}px`}
    />
  ),
  Component: TextPlaceholder,
}));

const GhostLoading = () => (
  <EntityTableDataGridOverwrites display={'block'}>
    <DataGrid data={loadingData} properties={loadingProperties} />
  </EntityTableDataGridOverwrites>
);

export default GhostLoading;
