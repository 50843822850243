import React, { useEffect } from 'react';
import get from 'lodash/get';

import { useGetManualMergeNodeInfo } from '../../../hooks/useGetManualMergeNodeInfo';
import ManualMergeContainer from '../ManualMergeContainer/ManualMergeContainer';

const ManualMergeAPIContainer = ({
  entityId,
  currentNode,
  nodeDataType,
  setNodeDataType,
  handleExecuteAction,
}) => {
  const mergeDataPartId = get(currentNode, 'actions[0].mergeDataPartId');

  const [
    getManualMergeNodeInfo,
    {
      data: manualMergePanelData,
      loading: manualMergePanelLoading,
      error: manualMergePanelError,
    },
  ] = useGetManualMergeNodeInfo();

  useEffect(() => {
    if (entityId && mergeDataPartId) {
      getManualMergeNodeInfo(entityId, mergeDataPartId);
    }
  }, [entityId, mergeDataPartId]);

  return (
    <>
      {manualMergePanelData && (
        <ManualMergeContainer
          entityId={entityId}
          nodeDataType={nodeDataType}
          setNodeDataType={setNodeDataType}
          currentNode={currentNode}
          handleExecuteAction={handleExecuteAction}
          manualMergePanelData={manualMergePanelData}
          manualMergePanelLoading={manualMergePanelLoading}
          manualMergePanelError={manualMergePanelError}
        />
      )}
    </>
  );
};

export default ManualMergeAPIContainer;
