import { FC, useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import Alert from 'uxi/Alert';

import { GraphNode } from '../../../entityRelationsGraphV2/types';
import { EntityRelationsTreeEdgeGroupWrapper } from './EntityRelationsTreeEdgeGroupWrapper';
import { EntityRelationsTreeEdgeGroupTitle } from './EntityRelationsTreeEdgeGroupTitle';
import { EntityRelationsTreeRootEdge } from '../EntityRelationsTreeRootEdge';
import { TreeEdge } from '../types';
import { UpdatedGraphNetworkEdge } from '../../../entityRelationsGraphV2/containers/types';

type EntityRelationsTreeEdgeGroupProps = {
  direction: 'outgoing' | 'incoming';
  edgeGroup: TreeEdge[];
  nodeGroup: GraphNode[];
  onEdgeClick: (edge: UpdatedGraphNetworkEdge | TreeEdge) => void;
};

export const EntityRelationsTreeEdgeGroup: FC<
  EntityRelationsTreeEdgeGroupProps
> = ({ direction, edgeGroup, nodeGroup, onEdgeClick }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const isEdgeOutgoing = direction === 'outgoing';
  const isEdgeIncoming = direction === 'incoming';

  return (
    <>
      <EntityRelationsTreeEdgeGroupWrapper isExpanded={isExpanded}>
        <EntityRelationsTreeEdgeGroupTitle
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ExpandingArrow
            data-test="entityRelations-rootEdgeGroup-expandingArrow"
            expanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          {isEdgeOutgoing && (
            <FormattedHTMLMessage
              id="module-entityRelationsGraphTreeview-outgoingEdgesLabel"
              values={{ outgoingEdgesCount: edgeGroup?.length }}
            />
          )}
          {isEdgeIncoming && (
            <FormattedHTMLMessage
              id="module-entityRelationsGraphTreeview-incomingEdgesLabel"
              values={{ incomingEdgesCount: edgeGroup?.length }}
            />
          )}
        </EntityRelationsTreeEdgeGroupTitle>
      </EntityRelationsTreeEdgeGroupWrapper>

      {isExpanded &&
        Array.isArray(edgeGroup) &&
        edgeGroup?.map((edge, index) => {
          let edgeNode: GraphNode | undefined;
          if (isEdgeOutgoing) {
            edgeNode = nodeGroup?.find((node) => node?.id === edge?.to);
          } else if (isEdgeIncoming) {
            edgeNode = nodeGroup?.find((node) => node?.id === edge?.from);
          }

          const edgeTypesArray = edge?.label
            ?.split('\n')
            ?.filter((s: string) => s !== '');

          if (edgeTypesArray?.length > 1) {
            return (
              <>
                {edgeTypesArray?.map((splitEdge, index) => (
                  <EntityRelationsTreeRootEdge
                    key={`${splitEdge}-${index}`}
                    edge={edge}
                    edgeNode={edgeNode}
                    onEdgeClick={onEdgeClick}
                    label={splitEdge}
                  />
                ))}
              </>
            );
          }

          return (
            <EntityRelationsTreeRootEdge
              key={`${edge?.id}-${index}`}
              edge={edge}
              edgeNode={edgeNode}
              onEdgeClick={onEdgeClick}
              label={edge?.label}
            />
          );
        })}

      {isExpanded && (
        <>
          {!edgeGroup ||
            (Array.isArray(edgeGroup) && edgeGroup?.length < 1 && (
              <div
                style={{
                  margin: '10px',
                }}
              >
                {direction === 'outgoing' && (
                  <Alert type="information">
                    <FormattedMessage id="module-entityRelationsGraphTreeview-outgoingEdgesAlert" />
                  </Alert>
                )}
                {direction === 'incoming' && (
                  <Alert type="information">
                    <FormattedMessage id="module-entityRelationsGraphTreeview-incomingEdgesAlert" />
                  </Alert>
                )}
              </div>
            ))}
        </>
      )}
    </>
  );
};
