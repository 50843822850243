import styled from 'styled-components';

export const EntityListPropertyTooltipWrapper = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  min-width: 250px;
`;

export const EntityListPropertyTooltipTitle = styled.div`
  background: #f2f2f2;
  border-bottom: 1px solid #ccc;
  color: rgb(136, 136, 136);
  font-size: 12px;
  font-weight: bold;
  padding: 6px;
`;

export const EntityListPropertyTooltipFieldTitle = styled.div`
  color: rgb(136, 136, 136);
  font-style: italic;
  font-size: 12px;
  word-break: break-word;
`;

export const EntityListPropertyTooltipFieldValue = styled.div`
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  margin-top: 2px;
  word-break: break-word;
`;

export const EntityListPropertyTooltipFieldLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const EntityListPropertyTooltipFieldLink = styled.div`
  display: flex;
  font-size: 12px;
  margin: 2px 4px 0 0;
  word-break: break-word;
`;
