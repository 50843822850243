import React from 'react';
import { useTheme } from '@cluedin/theme';
import Text from 'uxi/Text';
import { Spinner } from '@cluedin/form';

export const InfoLoader = ({ title, text }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <div
      style={{
        textAlign: 'center',
        border: `1px solid ${themePrimary}`,
        padding: '32px',
      }}
    >
      <div>
        <Text type="heading">{title}</Text>
      </div>
      <div>
        <Text type="caption">{text}</Text>
      </div>
      <div style={{ width: '80px', margin: '0 auto' }}>
        <Spinner size={80} />
      </div>
    </div>
  );
};
