import { FC, useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
import { useTheme } from '@cluedin/theme';
import { DataGridv2 } from '@cluedin/list-pattern';
import { useIntl } from 'react-intl';

import { EdgePropertiesPanelEdgeWrapper } from './EdgePropertiesPanelEdgeWrapper';
import { EdgePropertiesPanelEdgeInnerWrapper } from './EdgePropertiesPanelEdgeInnerWrapper';
import { EdgePropertiesPanelEdgeHeader } from './EdgePropertiesPanelEdgeHeader';
import { EdgePropertiesPanelEdgeBorderCircle } from './EdgePropertiesPanelEdgeBorderCircle';
import { EdgePropertiesPanelEdgeDetailWrapper } from './EdgePropertiesPanelEdgeDetailWrapper';
import { EdgePropertiesPanelEdgeDetailListWrapper } from './EdgePropertiesPanelEdgeDetailListWrapper';
import { EntityRelationEdgePropertiesHeading } from './EntityRelationEdgePropertiesHeading';
import {
  EdgePropertiesData,
  SelectedConnectingDirectionalEdge,
} from '../../../../types';

type EntityRelationEdgePropertiesListProps = {
  edge: EdgePropertiesData;
  edgeTo: SelectedConnectingDirectionalEdge;
  edgeFrom: SelectedConnectingDirectionalEdge;
  edgeDirection: string;
};

export const EntityRelationEdgePropertiesList: FC<
  EntityRelationEdgePropertiesListProps
> = ({ edge, edgeTo, edgeFrom, edgeDirection }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const edgeListData = Object.keys(edge?.properties)
    ?.map((key) => [
      {
        property: String(key),
        value: edge?.properties[key],
      },
    ])
    ?.flat(1);

  const isOutgoing = edgeDirection === 'outgoing';

  return (
    <div style={{ padding: '0 8px 0 0' }}>
      <EdgePropertiesPanelEdgeWrapper>
        <EdgePropertiesPanelEdgeInnerWrapper
          data-test="entity-entityRelations-edgePropertiesPanelEdge"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <EdgePropertiesPanelEdgeHeader>
            <ExpandingArrow
              expanded={isExpanded}
              data-test="entity-entityRelations-expandingArrow"
            />
            <EdgePropertiesPanelEdgeBorderCircle theme={theme} />
            <EntityRelationEdgePropertiesHeading
              isOutgoing={isOutgoing}
              edgeFrom={edgeFrom}
              edgeTo={edgeTo}
              edge={edge}
            />
          </EdgePropertiesPanelEdgeHeader>
        </EdgePropertiesPanelEdgeInnerWrapper>

        <EdgePropertiesPanelEdgeDetailWrapper isExpanded={isExpanded}>
          <EdgePropertiesPanelEdgeDetailListWrapper>
            <DataGridv2
              allowInlinePropertySelection={false}
              data={edgeListData || []}
              model={[
                {
                  property: 'property',
                  displayName: formatMessage({
                    id: 'module-entityRelationsGraph-edgePropertiesEntryProperty',
                  }),
                },
                {
                  property: 'value',
                  displayName: formatMessage({
                    id: 'module-entityRelationsGraph-edgePropertiesEntryValue',
                  }),
                },
              ]}
            />
          </EdgePropertiesPanelEdgeDetailListWrapper>
        </EdgePropertiesPanelEdgeDetailWrapper>
      </EdgePropertiesPanelEdgeWrapper>
    </div>
  );
};
