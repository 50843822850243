import { useLazyQuery, gql } from '@apollo/client';
import get from 'lodash/get';

const GET_ENTITY_CODE_NODE_INFO = gql`
  query getEntityCodeNodeInfo($entityId: ID!, $entityCode: String!) {
    virtualization {
      getEntityCodeNodeInfo(entityId: $entityId, entityCode: $entityCode) {
        actions {
          availability
          dataParts {
            dataPartId
            name
            provider {
              name
              providerDefinitionId
              providerId
              providerName
              providerIcon
            }
            sortDate
            source
          }
          entityCode
          kind
          reasonMessage
          unavailableReason
          splitEntityActionRequest
        }
        description
        entityCode
        kind
        name
      }
    }
  }
`;

export const useGetEntityCodeNodeInfo = () => {
  const [getEntityCodeNodeInfo, { data, loading, error }] = useLazyQuery(
    GET_ENTITY_CODE_NODE_INFO,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    },
  );

  const result = get(data, 'virtualization.getEntityCodeNodeInfo');

  return [
    (entityId, entityCode) =>
      getEntityCodeNodeInfo({ variables: { entityId, entityCode } }),
    {
      data: result,
      loading,
      error,
    },
  ];
};
