import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

export const GET_ALL_VOCABULARIES_KEYS = gql`
  query getAllVocabularyKeys(
    $searchName: String
    $pageNumber: Int
    $pageSize: Int
    $dataType: String
    $classification: String
    $connectorId: ID
  ) {
    management {
      id
      vocabularyKeys(
        searchName: $searchName
        pageNumber: $pageNumber
        pageSize: $pageSize
        dataType: $dataType
        classification: $classification
        connectorId: $connectorId
      ) {
        total
      }
    }
  }
`;

export const useVocabularyKeyKpi = (initialVar = {}, withCaching) => {
  const { data, error, refetch, networkStatus } = useQuery(
    GET_ALL_VOCABULARIES_KEYS,
    {
      // fetchPolicy: withCaching ? 'cache-first' : 'no-cache',
      variables: initialVar,
    },
  );

  const pagedVocabularies = get(data, 'management.vocabularyKeys', {
    total: 0,
    data: [],
  });

  return [
    pagedVocabularies,
    networkStatus === 1,
    error,
    networkStatus === 4,
    () => {
      refetch(initialVar);
    },
  ];
};

export default {
  useVocabularyKeyKpi,
};
