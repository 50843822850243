import { useId } from 'react';
import { DataSourceFile } from '@cluedin/svgs';
import { useTheme } from '@cluedin/theme';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import IntegrationIconContainer from './IntegrationIconContainer';
import DataSetIconContainer from './DataSetIconContainer';
import { isDataSet } from '../../../../dataSourceV2/helper';

const LinkWithOpacity = styled.a`
  display: block;
  margin-right: 5px;
`;

export const ImgWithOpacity = styled.div`
  display: block;
  opacity: 0.4;
  transition: ${({ theme: { transition } }) =>
    transition && transition.defaultAll};
  &:hover {
    opacity: 1;
    transition: ${({ theme: { transition } }) =>
      transition && transition.defaultAll};
  }
`;

const ThemedIcon = styled.div`
  circle {
    fill: ${({ bg }) => bg};
  }
`;

const ProviderIconLink = ({ providerName, provider, uri, roundImage }) => {
  const theme = useTheme();
  const dataSourceFileIconTooltipId = useId();
  const linkWithOpacityTooltipId = useId();
  const imgWithOpacityTooltipId = useId();

  if (!providerName) {
    return null;
  }

  // Causes huge loss of performance
  // if (providerName.indexOf('CluedIn(') === -1) {
  //    console.warn(`Does not have ${providerName}`);
  // }

  if (providerName === 'File Data Source') {
    return (
      <TooltipHost
        calloutProps={{ gapSpace: 0 }}
        id={dataSourceFileIconTooltipId}
        content={<span>Found in {providerName}</span>}
      >
        <ThemedIcon bg={theme.palette.themePrimary}>
          <DataSourceFile
            style={{ width: '24px', height: '24px' }}
            size="32"
            color="#fff"
            providerName={providerName}
          />
        </ThemedIcon>
      </TooltipHost>
    );
  }

  if (isDataSet(providerName)) {
    return <DataSetIconContainer providerName={providerName} />;
  }

  if (uri) {
    return (
      <div style={{ display: 'inline-block' }}>
        <TooltipHost
          id={linkWithOpacityTooltipId}
          calloutProps={{ gapSpace: 0 }}
          content={<span>View in {providerName}</span>}
        >
          <LinkWithOpacity href={uri} target="__blank">
            <ImgWithOpacity>
              <IntegrationIconContainer
                iconSize="s"
                integrationName={providerName}
                roundImage={roundImage}
                integration={provider}
              />
            </ImgWithOpacity>
          </LinkWithOpacity>
        </TooltipHost>
      </div>
    );
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <TooltipHost
        id={imgWithOpacityTooltipId}
        calloutProps={{ gapSpace: 0 }}
        content={<span>Found in {providerName}</span>}
      >
        <ImgWithOpacity>
          <IntegrationIconContainer
            iconSize="s"
            integrationName={providerName}
            integration={provider}
          />
        </ImgWithOpacity>
      </TooltipHost>
    </div>
  );
};

ProviderIconLink.displayName = 'ProviderIconLink';

ProviderIconLink.propTypes = {
  providerName: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};

export default ProviderIconLink;
