import { useId } from 'react';

import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { FormattedMessage } from '@cluedin/locale';

import { EntitySourceName } from '../EntitySourceName';
import { EntitySourceWrapperLine } from '../EntitySourceWrapperLine';
import { DataSetName } from '../../../../../dataSourceV2/components/composites/DataSetName';
import { useQueryDataSetDetail } from '../../../../../dataSourceV2/hooks/useQueryDataSetDetail';
import IntegrationIconContainer from '../../../../../integration/components/containers/IntegrationIconContainer';

const extractGuid = (text) => {
  const pattern =
    /\b[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\b/;
  const match = text.match(pattern);
  return match ? match[0] : null;
};

export const DataSetSourceLine = ({ providerName }) => {
  const tooltipId = useId();
  const dataSetId = extractGuid(providerName);
  const [detail, loading, error] = useQueryDataSetDetail(dataSetId);

  if (loading) {
    return <EntitySourceWrapperLine>{dataSetId}</EntitySourceWrapperLine>;
  }

  if (error) {
    return (
      <TooltipHost
        id={tooltipId}
        calloutProps={{ gapSpace: 0 }}
        content={<FormattedMessage id="module-entityWidget-sourceNotFound" />}
      >
        <EntitySourceWrapperLine style={{ color: '#E66565' }}>
          {dataSetId}
        </EntitySourceWrapperLine>
      </TooltipHost>
    );
  }

  return (
    <EntitySourceWrapperLine>
      <IntegrationIconContainer iconSize="m" integrationName={detail?.name} />
      <EntitySourceName>
        <DataSetName dataSet={detail} dataSetId={detail?.id} />
      </EntitySourceName>
    </EntitySourceWrapperLine>
  );
};
