import React from 'react';
import EditConfigurationContainer from '../containers/EditConfigurationContainer';

const EditConfigurationPage = ({ integrationId, configurationId, route }) => (
  <EditConfigurationContainer
    integrationId={integrationId}
    configurationId={configurationId}
    standAlone={route && route.standAlone ? route.standAlone : false}
  />
);

export default EditConfigurationPage;
