import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import gql from 'graphql-tag';

const GET_ENTITY_TOPOLOGY = gql`
  query getEntityTopology($id: ID!, $filter: String) {
    virtualization {
      getEntityTopology(id: $id, filter: $filter) {
        id
        nodes {
          id
          label
          subLabel
          dataType
          dataPartId
          provider {
            id
            name
            icon
            providerName
          }
          source
          actions {
            kind
            availability
            unavailableReason
            reasonMessage
            deduplicationProjectId
            mergeDataPartId
          }
        }
        edges {
          from
          to
          label
        }
      }
    }
  }
`;

export const useEntityTopology = (id) => {
  const [getTopology, { data, loading, error }] =
    useLazyQuery(GET_ENTITY_TOPOLOGY);

  const result = get(data, 'virtualization.getEntityTopology');

  return [
    (filter) =>
      getTopology({
        variables: {
          id: id,
          filter: filter,
        },
      }),
    {
      data: result,
      loading,
      error,
    },
  ];
};

export default {
  useEntityTopology,
};
