import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const UnstyledLinkWrapper = styled.span`
  a,
  a:hover {
    text-decoration: none;
  }
`;

const UnstyledLink = (props) => (
  <UnstyledLinkWrapper style={props.wrapperStyle || {}}>
    <Link
      {...props}
      style={{ textDecoration: 'none !important', ...(props.style || {}) }}
    />
  </UnstyledLinkWrapper>
);

export default UnstyledLink;
