import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { showError } from 'uxi-business/userMessage/actions';
import { getEntity } from '../entity/data';
// import { errorHandler } from '../core/actions';
import { createAsyncAction } from '../core/helpers/action';
import { submitMergedEntitesDelta } from './data';
import { shouldShowAlert } from '../core/actions';

export const initMerge = createAction('INIT_MERGE', (config) => config);
export const abortMerge = createAction('ABORT_MERGE');
export const selectEntity = createAction(
  'SELECT_ENTITY',
  (mergeViewModel) => mergeViewModel,
);
export const deselectEntity = createAction(
  'DESELECT_ENTITY',
  (mergeViewModel) => mergeViewModel,
);
export const deselectAllEntities = createAction(
  'DESELECT_ALL_ENTITIES',
  (mergeViewModel) => mergeViewModel,
);
export const updateTarget = createAction('UPDATE_MARGE_TARGET_ENTITY');

export const fetchMergeEntitiesActions = createAsyncAction(
  'MERGE_FETCH_ENTITIES',
);

export const shouldFetchEntities = (entitiesIds) => async (dispatch) => {
  dispatch(fetchMergeEntitiesActions.request());

  const resolved = [];
  for (let i = 0; i < entitiesIds.length; i++) {
    // eslint-disable-line no-plusplus
    try {
      const res = await getEntity(entitiesIds[i]); // eslint-disable-line no-await-in-loop
      resolved.push(res);
    } catch (e) {
      console.warn('failed to fetch:', entitiesIds[i]);
    }
  }
  dispatch(fetchMergeEntitiesActions.receive(resolved));
};

export const submitMergedEntitesActions = createAsyncAction(
  'SUBMIT_MERGED_ENTITIES',
);
export const shouldSubmitMergedEntitiesDelta =
  (propsDeltaData) => (dispatch) => {
    dispatch(submitMergedEntitesActions.request());
    const { ids, targetId, delta } = propsDeltaData;
    submitMergedEntitesDelta(ids, targetId, delta)
      .then((resp) => {
        dispatch(submitMergedEntitesActions.receive(resp));
        dispatch(
          shouldShowAlert(
            {
              description:
                'The merge request has been successfully received and is queued. It may take a couple minutes for the changes to becomes available.',
              type: 'success',
            },
            9000,
          ),
        );
        setTimeout(() => {
          dispatch(push(`/search/?q=${targetId}`));
        }, 1200);
      })
      .catch((err) => {
        dispatch(submitMergedEntitesActions.invalid(err));
        dispatch(showError({ message: 'We failed to commit your changes' }));
      });
  };
