import React from 'react';
import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import routes from './routes';
import RuleIcon from './components/composites/RuleIcon';

const { management } = actionsConfiguration;

export default {
  pillar: 'management',
  name: 'rule',
  displayName: <FormattedMessage id="module-rule-moduleName" />,
  path: '/rule',
  extendPillarDashboard: {
    actions: [
      {
        IconComponent: RuleIcon,
        name: <FormattedMessage id="module-rule-moduleName" />,
        link: '/admin/management/rule',
        claims: management.rules.rule.view,
      },
    ],
  },
  routes,
};
