import { handleActions } from 'redux-actions';
import {
  shortestPathActions,
  shortestPathPeopleActions,
  cleanUpShortestPath,
  cleanUpShortestPathPeople,
} from './actions';

const initialState = {
  shortestPath: {},
  shortestPathIsFetching: {},
  shortestPathPeople: {},
  shortestPathPeopleIsFetching: {},
};

export default handleActions(
  {
    [shortestPathActions.request]: (
      state,
      {
        payload: {
          param: { widgetUniqueId },
        },
      },
    ) => ({
      ...state,
      shortestPath: {
        ...state.shortestPath,
        [widgetUniqueId]: [],
      },
      shortestPathIsFetching: {
        ...state.shortestPathIsFetching,
        [widgetUniqueId]: true,
      },
    }),
    [shortestPathActions.receive]: (
      state,
      {
        payload: {
          param: { widgetUniqueId },
          result,
        },
      },
    ) => ({
      ...state,
      shortestPath: {
        ...state.shortestPath,
        [widgetUniqueId]: result,
      },
      shortestPathIsFetching: {
        ...state.shortestPathIsFetching,
        [widgetUniqueId]: false,
      },
    }),
    [cleanUpShortestPath]: (
      state,
      {
        payload: {
          param: { widgetUniqueId },
        },
      },
    ) => ({
      ...state,
      shortestPath: {
        ...state.shortestPath,
        [widgetUniqueId]: [],
      },
      shortestPathIsFetching: {
        ...state.shortestPathIsFetching,
        [widgetUniqueId]: false,
      },
    }),
    [shortestPathPeopleActions.request]: (
      state,
      {
        payload: {
          param: { widgetUniqueId },
        },
      },
    ) => ({
      ...state,
      shortestPathPeople: {
        ...state.shortestPathPeople,
        [widgetUniqueId]: [],
      },
      shortestPathPeopleIsFetching: {
        ...state.shortestPathPeopleIsFetching,
        [widgetUniqueId]: true,
      },
    }),
    [shortestPathPeopleActions.receive]: (
      state,
      {
        payload: {
          param: { widgetUniqueId },
          result,
        },
      },
    ) => ({
      ...state,
      shortestPathPeople: {
        ...state.shortestPathPeople,
        [widgetUniqueId]: result,
      },
      shortestPathPeopleIsFetching: {
        ...state.shortestPathPeopleIsFetching,
        [widgetUniqueId]: false,
      },
    }),
    [cleanUpShortestPathPeople]: (
      state,
      {
        payload: {
          param: { widgetUniqueId },
        },
      },
    ) => ({
      ...state,
      shortestPathPeople: {
        ...state.shortestPathPeople,
        [widgetUniqueId]: [],
      },
      shortestPathPeopleIsFetching: {
        ...state.shortestPathPeopleIsFetching,
        [widgetUniqueId]: false,
      },
    }),
  },
  initialState,
);
