import moment from 'moment';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Externallink } from 'uxi/Icons';
import get from 'lodash/get';

import UserAvatarFromId from '../../../../userModule/components/container/UserAvatarFromId';
import {
  EntityTopologyHeaderDetailWrapper,
  EntityTopologyHeaderDetailTitle,
  EntityTopologyHeaderTimeStamps,
  EntityTopologyHeaderTimeStamp,
  EntityTopologyHeaderAuthor,
} from './EntityTopologyHeaderDetail.styles';

const EntityTopologyHeaderDetail = ({
  data,
  title,
  subtitle,
  detail,
  labels,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const { formatMessage } = useIntl();

  const {
    modified = 'Modified',
    created = 'Created',
    discovered = 'Discovered',
  } = labels;

  const userId = get(data, 'userId');
  const headerKind = get(data, 'kind');
  const isDeduplicationProject =
    headerKind && headerKind?.toLowerCase() === 'deduplicationprojectmerge';
  const deduplicationProjectId = get(
    data,
    'deduplicationProject.deduplicationProjectId',
  );

  const isValidDate = (date) => {
    return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
  };
  const isSubtitleDate = isValidDate(subtitle);

  return (
    <EntityTopologyHeaderDetailWrapper>
      <EntityTopologyHeaderDetailTitle themePrimary={themePrimary}>
        {title}
      </EntityTopologyHeaderDetailTitle>

      {isDeduplicationProject ? (
        <div style={{ display: 'flex' }}>
          <FormattedMessage id="module-entityTopology-deduplicationProject" />
          :&nbsp;
          <Link
            target="_blank"
            rel="noreferrer noopener"
            to={`/admin/management/deduplication/detail/${deduplicationProjectId}`}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {subtitle && subtitle}
            <div style={{ marginLeft: '6px' }}>
              <Externallink size={12} />
            </div>
          </Link>
        </div>
      ) : (
        <div>
          {isSubtitleDate ? (
            <>
              {formatMessage({ id: 'module-entityTopology-manualMergeData' })}
              :&nbsp;{moment(subtitle).format('DD/MM/YYYY')}
            </>
          ) : (
            subtitle
          )}
        </div>
      )}

      {detail && (
        <EntityTopologyHeaderTimeStamps>
          <EntityTopologyHeaderTimeStamp>
            {modified}:&nbsp;{moment(detail?.modifiedAt).fromNow()}
          </EntityTopologyHeaderTimeStamp>

          <EntityTopologyHeaderTimeStamp>
            {created}:&nbsp;{moment(detail?.createdAt).fromNow()}
          </EntityTopologyHeaderTimeStamp>

          <EntityTopologyHeaderTimeStamp>
            {discovered}:&nbsp;{moment(detail?.discoveredAt).fromNow()}
          </EntityTopologyHeaderTimeStamp>
        </EntityTopologyHeaderTimeStamps>
      )}

      {data && userId && (
        <EntityTopologyHeaderAuthor>
          <FormattedMessage id="module-entityTopology-splitPanelMergedBy" />
          :&nbsp;
          <UserAvatarFromId author={userId} />
        </EntityTopologyHeaderAuthor>
      )}
    </EntityTopologyHeaderDetailWrapper>
  );
};

export default EntityTopologyHeaderDetail;
