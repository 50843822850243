import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { FormattedMessage } from '@cluedin/locale';
// import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    // claims: {
    //   'consume.streams': RACI_LEVELS.CONSULTANT,
    // },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamDashboardPage" */
        './components/pages/EntityPageLayoutPage'
      ),
    ),
  },
  {
    path: '/edit',
    title: <FormattedMessage id="module-entityPageLayout-editLayout" />,
    // claims: {
    //   'consume.streams': RACI_LEVELS.CONSULTANT,
    // },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamDashboardPage" */
        './components/pages/EntityPageEditLayoutPage'
      ),
    ),
  },
];
