import { handleActions } from 'redux-actions';
import {
  fetchTeamDomainActions,
  signInActions,
  updateClientIdProposalAction,
  invalidClientIdProposalAction,
  validateClientIdProposalAction,
} from './actions';

const initialState = {
  clientId: null,
  isFetchingTeamDomain: null,
  teamDomainInfo: null,
  invalidLogin: false,
  redirectingTeamDomain: false,
  authInfo: null,
  isFetchingLogin: false,
  redirecting: false,
  errorTeamDomain: false,
  clientIdProposal: null,
  invalidClientIdProposal: false,
};

/* eslint-disable no-unused-vars */
export default handleActions(
  {
    [invalidClientIdProposalAction]: (state, { payload }) => ({
      ...state,
      invalidClientIdProposal: true,
    }),
    [validateClientIdProposalAction]: (state, { payload }) => ({
      ...state,
      invalidClientIdProposal: false,
    }),
    [updateClientIdProposalAction]: (state, { payload }) => ({
      ...state,
      clientIdProposal: payload,
    }),
    [signInActions.request]: (state) => ({
      ...state,
      isFetchingLogin: true,
      invalidLogin: false,
      redirecting: false,
    }),
    [signInActions.receive]: (state, { payload: { authInfo } }) => ({
      ...state,
      isFetchingLogin: false,
      authInfo,
      invalidLogin: false,
      redirecting: true,
    }),
    [signInActions.invalid]: (state) => ({
      ...state,
      isFetchingLogin: false,
      invalidLogin: true,
      redirecting: false,
    }),
    [fetchTeamDomainActions.request]: (state) => ({
      ...state,
      isFetchingTeamDomain: true,
      redirectingTeamDomain: false,
      errorTeamDomain: false,
    }),
    [fetchTeamDomainActions.receive]: (
      state,
      { payload: { clientId, teamDomainResult } },
    ) => ({
      ...state,
      isFetchingTeamDomain: false,
      teamDomainInfo: teamDomainResult,
      clientId,
      redirectingTeamDomain: teamDomainResult && !teamDomainResult.isAvailable,
      errorTeamDomain: false,
    }),
    [fetchTeamDomainActions.invalid]: (state) => ({
      ...state,
      isFetchingTeamDomain: false,
      redirectingTeamDomain: false,
      errorTeamDomain: true,
    }),
  },
  initialState,
);
