"use strict";

exports.__esModule = true;
exports.default = createTheme;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
// Base 8px spacing

function createTheme(variables) {
  if (variables === void 0) {
    variables = {};
  }
  var mainSpacingVariables = _extends({
    baseElementSpacing: 3,
    baseSpacing: 8
  }, variables);
  return {
    baseElementSpacing: mainSpacingVariables.baseElementSpacing,
    baseSpacing: mainSpacingVariables.baseSpacing,
    spacing: function spacing(number) {
      return mainSpacingVariables.baseSpacing * number;
    },
    inputSpacing: function inputSpacing(number) {
      return mainSpacingVariables.baseElementSpacing * number;
    }
  };
}