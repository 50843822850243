import { handleActions } from 'redux-actions';

import { selectTopic, needHelp, closeHelpCenter, openCenter } from './actions';

const initialState = {
  isOpenHelpCenter: false,
  selected: null,
};

export default handleActions(
  {
    [openCenter]: (state, { payload }) => ({
      ...state,
      selected: payload,
      isOpenHelpCenter: true,
    }),
    [closeHelpCenter]: (state, { payload }) => ({
      ...state,
      selected: payload,
      isOpenHelpCenter: false,
    }),
    [selectTopic]: (state, { payload }) => ({
      ...state,
      selected: payload,
    }),
    [needHelp]: (state, { payload }) => ({
      ...state,
      selected: payload,
      isOpenHelpCenter: true,
    }),
  },
  initialState,
);
