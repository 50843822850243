import { apiRequest } from '../core/helpers/request';

export const getValuesForSchemaKey = (propertyKey) =>
  apiRequest(
    'GET',
    `api/v1/search/terms?propertyName=${encodeURIComponent(propertyKey)}`,
  ).then((res) => {
    if (res && res.terms) {
      return res.terms;
    }
    return [];
  });

export default {
  getValuesForSchemaKey,
};
