import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { CoreVocabulary } from '@cluedin/svgs';

const StreamLogoWrapper = styled.div`
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background: ${({ themePrimary }) => themePrimary};
  width: ${({ size }) => {
    return `${size}px;`;
  }};
  height: ${({ size }) => {
    return `${size}px;`;
  }};

  border-radius: ${({ size }) => {
    return `${size}px;`;
  }};
  & svg * {
    fill: ${({ color }) => color} !important;
  }
`;

const VocabIcon = ({ color = '#fff', size = 14 }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <StreamLogoWrapper size={size} themePrimary={themePrimary} color={color}>
      <CoreVocabulary height={`${size}px`} width={`${size}px`} />
    </StreamLogoWrapper>
  );
};

export default VocabIcon;
