/* eslint-disable */
import React from 'react';
import { useTheme } from '@cluedin/theme';
import styled from 'styled-components';

const LI = styled.li`
  margin: 0;
  padding: 16px 32px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  font-weight: ${(props) =>
    (props?.selected?.isExact || props?.selected) && 'bold'};
  background: ${(props) =>
    (props?.selected?.isExact || props?.selected) && '#fff'};
  color: ${(props) =>
    (props?.selected?.isExact || props?.selected) && props?.themePrimary};
`;

const LightTab = ({ selected, disabled, panelId, children, ...rest }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <LI
      selected={selected}
      aria-selected={selected ? 'true' : 'false'}
      aria-disabled={disabled ? 'true' : 'false'}
      tabIndex={selected ? '0' : null}
      themePrimary={themePrimary}
      {...rest}
    >
      {children}
      {selected && (
        <div
          style={{
            position: 'absolute',
            bottom: '-1px',
            left: '16px',
            right: '16px',
            height: '2px',
            background: themePrimary,
          }}
        />
      )}
    </LI>
  );
};

export default LightTab;
