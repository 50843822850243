import { handleActions } from 'redux-actions';
import jsCookie from 'js-cookie';
import { isEmpty } from '../core/helpers/object';
import {
  SARActions,
  SARByIdActions,
  getFilteredSARsActions,
  resetSARListFilters,
  SarSaveActions,
  updateFilterAction,
  shouldFetchPersonActions,
  updatePersonForSar,
  clearSelectionPersonForSar,
  shouldLoadMoreSARActions,
  // new actions - all ASYNC
  SarCreateActions,
  sarDeleteActions,
  sarAchiveActions,
  getGDPRConfigActions,
  gdprConfigUpdateActions,
  removeFromProcessingActions,
  minimizeEntityActions,
  subscribeGdprUserData,
  unSubscribeGdprUserData,
  consentReportActions,
  identifiersReportActions,
  encryptedReportActions,
  locationReportActions,
  entityTypeReportActions,
  addFromProcessingActions,
  unMinimizeEntityActions,
  anonymizeActions,

  // async
  requestSARReportReadyNotif,
  resetSARReportReadyNotif,
  shouldBatchActionsForGdpr,
  shouldResetEscalateDialog,
  shouldShowEscalateDialog,
  shouldhideEscalateDialog,
  escalateSarIdsForUsersIdsActions,
  shouldShowAddIdentifierDialog,
  shouldHideIdentifierDialog,
  getGDPRJsonReportActions,
  resetGDPRJsonReportActions,
  showNewSarDialog,
  hideNewSarDialog,
  lookForReportUrlActions,
  shouldClearCurrentSar,
  generateSARReportActions,
  regenerateSARReportActions,
  resetRegenerateSARReportAction,
  extendReportActions,
  postExtendReportActions,
  postExtendReportActionsConflicted,
  resetPostExtendReportAction,
  SARFetchSubjectsEntityActions,
  SARClearSubjectsEntityAction,
  shouldCreateAllSarActions,
  shouldCreateAllSarActionsAllDone,
  shouldClearAllSarBatchCreation,
  batchImportFromFileActions,
  batchImportFromFileCleanUpActions,
} from './actions';

const initialState = {
  SARList: null,
  SARListError: false,
  isFetching: false,
  isFetchingMore: false,

  SARSubjectsEntity: null,
  SARSubjectsEntityInvalid: false,
  SARSubjectsEntityLoading: false,

  filterOptions: {
    status: 'pending',
  },

  isCreatingSar: false,
  invalidSarCreation: false,

  isSaveFetching: false,
  invalidSave: false,

  isDeletingSar: {},
  isInvalidDeleteSar: {},

  isRemovingFromProcessing: {},
  invalidRemovingFromProcessing: {},

  isMinimizingEntity: {},
  invalidMinizedEntity: {},

  isAnonymizingEntity: {},
  invalidAnonymizingEntity: {},

  subscribingUserData: {},

  isFetchingConsentReport: false,
  isInvalidConsentReport: false,
  consentReport: null,

  isFetchingUniqueIdentifiersReport: false,
  isInvalidUniqueIdentifiersReport: false,
  identifiersReport: null,

  isFetchingEncryptedReport: false,
  isInvalidEncryptedReport: false,
  encryptedReport: null,

  isFetchingLocationReport: false,
  isInvalidLocationReport: false,
  locationReport: null,

  isFetchingEntityTypeReport: false,
  isInvalidEntityTypeReport: false,
  entityTypeReport: null,

  currentSAR: {},
  currentSARError: false,
  selectedPersonEntityForSar: null,

  isFetchingConfiguration: false,
  configuration: {},
  configurationFetchError: false,
  configurationUpdateError: false,
  isFetchingGDPRconfig: false,
  gdprConfigReady: false,

  notifs: {},

  GDPRActions: {},

  showEscalateDialog: false,
  escalateDialog: [],
  isEscalating: false,
  escalatingError: null,

  showAddIdentifier: false,

  isFetchingConsentConfigurationForEdit: false,
  consentConfigurationEdit: null,
  invalidConsentConfigurationEdit: false,

  currentUserConsent: null,
  currentUserConsentToken: jsCookie.get('consent_token') || '',

  isFetchingJsonReport: false,
  isFetchingJsonReportError: false,
  currentSARJsonReport: null,

  showNewSarDialog: false,

  isFetchingRegenerateReport: false,
  regenerateReportError: false,
  regenerateReportSuccess: false,

  isFetchingGenerateReport: false,
  generateReportError: false,
  generateReportSuccess: false,

  availableTransforms: null,
  availableTransformsLoading: false,
  availableTransformsInvalid: false,

  postExtendReport: null,
  postExtendReportLoading: false,
  postExtendReportInvalid: false,
  postExtendReportConflicted: false,
  postExtendReportSuccess: false,

  importedSAR: null,
  importedSARInvalid: false,
  importedSARFetching: false,
  importedSARSuccess: false,

  batchSarCreationStatus: {},
  batchSarCreationStatusDone: false,
};

/* eslint-disable no-plusplus */
export default handleActions(
  {
    [shouldClearCurrentSar]: (state) => ({
      ...state,
      currentSAR: {},
      currentSARError: false,
      selectedPersonEntityForSar: null,
    }),
    [shouldShowAddIdentifierDialog]: (state) => ({
      ...state,
      showAddIdentifier: true,
    }),
    [shouldHideIdentifierDialog]: (state) => ({
      ...state,
      showAddIdentifier: false,
    }),
    [SARActions.request]: (state) => ({
      ...state,
      isFetching: true,
      SARListError: false,
    }),
    [SARActions.receive]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      SARListError: false,
      SARList: [...payload],
    }),
    [SARActions.invalid]: (state) => ({
      ...state,
      isFetching: false,
      SARListError: true,
    }),
    [SARFetchSubjectsEntityActions.request]: (state) => ({
      ...state,
      SARSubjectsEntity: null,
      SARSubjectsEntityInvalid: false,
      SARSubjectsEntityLoading: true,
    }),
    [SARFetchSubjectsEntityActions.invalid]: (state, { payload }) => ({
      ...state,
      SARSubjectsEntity: null,
      SARSubjectsEntityInvalid: payload || true,
      SARSubjectsEntityLoading: false,
    }),
    [SARFetchSubjectsEntityActions.receive]: (
      state,
      { payload: subjectsEntity },
    ) => ({
      ...state,
      SARSubjectsEntity: subjectsEntity,
      SARSubjectsEntityInvalid: false,
      SARSubjectsEntityLoading: false,
    }),
    [SARClearSubjectsEntityAction]: (state) => ({
      ...state,
      SARSubjectsEntity: null,
      SARSubjectsEntityInvalid: false,
      SARSubjectsEntityLoading: false,
    }),
    [shouldLoadMoreSARActions.request]: (state) => ({
      ...state,
      isFetchingMore: true,
      SARListError: false,
    }),
    [shouldLoadMoreSARActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingMore: false,
      SARList: [...(state.SARList || []), ...payload],
      SARListError: false,
      filterOptions: {
        ...state.filterOptions,
        page: (state.filterOptions.page || 0) + 1,
      },
    }),
    [shouldLoadMoreSARActions.invalid]: (state) => ({
      ...state,
      isFetchingMore: false,
      SARListError: true,
    }),
    [resetSARListFilters]: (state) => ({
      ...state,
      filterOptions: {
        status: 'pending',
      },
    }),
    [SARByIdActions.request]: (state) => ({
      ...state,
      isFetching: true,
      currentSARError: false,
    }),
    [SARByIdActions.receive]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      currentSARError: false,
      currentSAR: { ...payload },
    }),
    [SARByIdActions.invalid]: (state) => ({
      ...state,
      isFetching: false,
      currentSARError: true,
      currentSAR: {},
    }),
    [lookForReportUrlActions.request]: (state) => ({
      ...state,
      isLookingForReport: true,
    }),
    [lookForReportUrlActions.receive]: (state, { payload }) => ({
      ...state,
      isLookingForReport: false,
      currentSAR: payload.ReportUri
        ? {
            ...payload,
          }
        : state.currentSAR,
    }),
    [lookForReportUrlActions.invalid]: (state) => ({
      ...state,
      isLookingForReport: false,
      currentSARError: false,
    }),
    [updateFilterAction]: (state, { payload }) => ({
      ...state,
      filterOptions: payload,
    }),
    [shouldFetchPersonActions.request]: (state) => ({
      ...state,
      isFetchingPersonEntitiesForSAR: true,
      invalidPersonEntitiesForSar: false,
      personEntitiesForSar: [],
    }),
    [shouldFetchPersonActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingPersonEntitiesForSAR: false,
      invalidPersonEntitiesForSar: false,
      personEntitiesForSar: [...payload],
    }),
    [shouldFetchPersonActions.invalid]: (state) => ({
      ...state,
      isFetchingPersonEntitiesForSAR: false,
      invalidPersonEntitiesForSar: true,
      personEntitiesForSar: [],
    }),
    [updatePersonForSar]: (state, { payload }) => ({
      ...state,
      selectedPersonEntityForSar: payload,
      currentSAR: {
        ...state.currentSAR,
        EntityId: payload.id,
      },
    }),
    [clearSelectionPersonForSar]: (state) => ({
      ...state,
      selectedPersonEntityForSar: null,
      currentSAR: {
        ...state.currentSAR,
        EntityId: '00000000-0000-0000-0000-000000000000',
      },
    }),
    [SarCreateActions.request]: (state, { payload }) => ({
      ...state,
      isCreatingSar: true,
      invalidSarCreation: false,
      currentSAR: {
        form: payload,
      },
    }),
    [SarCreateActions.receive]: (state, { payload }) => ({
      ...state,
      currentSAR: { ...payload },
      isCreatingSar: false,
      invalidSarCreation: false,
    }),
    [SarCreateActions.invalid]: (state) => ({
      ...state,
      isCreatingSar: false,
      invalidSarCreation: true,
    }),
    [SarSaveActions.request]: (state) => ({
      ...state,
      isSaveFetching: true,
      invalidSave: false,
      // currentSAR: payload,
    }),
    [SarSaveActions.receive]: (state, { payload }) => ({
      ...state,
      isSaveFetching: false,
      invalidSave: false,
      currentSAR: payload,
    }),
    [SarSaveActions.invalid]: (state) => ({
      ...state,
      isSaveFetching: false,
      invalidSave: true,
    }),
    [sarAchiveActions.request]: (state, { payload: idsArray }) => {
      const newIsDeletingSar = {};
      const newIsInvalidDeleteSar = {};
      if (idsArray) {
        for (let i = 0; i < idsArray.length; i++) {
          newIsDeletingSar[idsArray[i]] = true;
        }
      }

      return {
        ...state,
        isDeletingSar: newIsDeletingSar,
        isInvalidDeleteSar: newIsInvalidDeleteSar,
      };
    },
    [sarAchiveActions.receive]: (state, { payload: idsArray }) => {
      const newIsDeletingSar = {};
      if (idsArray) {
        for (let i = 0; i < idsArray.length; i++) {
          newIsDeletingSar[idsArray[i]] = false;
        }
      }
      const newIsInvalidDeleteSar = {};

      return {
        ...state,
        SARList: (state.SARList || []).filter(
          ({ Id }) => !(idsArray && idsArray.indexOf(Id) > -1),
        ),
        // isDeletingSar: Object.keys(isDeletingSar).map(k => idsArray.indexOf(k) > -1)
        isDeletingSar: newIsDeletingSar,
        isInvalidDeleteSar: newIsInvalidDeleteSar,
        // TODO update list fo local SAR
      };
    },
    [sarAchiveActions.invalid]: (state, { payload: idsArray }) => {
      const newIsInvalidDeleteSar = {};
      const newIsDeletingSar = {};
      if (idsArray) {
        for (let i = 0; i < idsArray.length; i++) {
          newIsInvalidDeleteSar[idsArray[i]] = true;
        }
      }
      return {
        ...state,
        isDeletingSar: newIsDeletingSar,
        isInvalidDeleteSar: newIsInvalidDeleteSar,
      };
    },
    [sarDeleteActions.request]: (state, { payload: idsArray }) => {
      const newIsDeletingSar = {};
      const newIsInvalidDeleteSar = {};
      if (idsArray) {
        for (let i = 0; i < idsArray.length; i++) {
          newIsDeletingSar[idsArray[i]] = true;
        }
      }

      return {
        ...state,
        isDeletingSar: newIsDeletingSar,
        isInvalidDeleteSar: newIsInvalidDeleteSar,
      };
    },
    [sarDeleteActions.receive]: (state, { payload: idsArray }) => {
      const newIsDeletingSar = {};
      if (idsArray) {
        for (let i = 0; i < idsArray.length; i++) {
          newIsDeletingSar[idsArray[i]] = false;
        }
      }
      const newIsInvalidDeleteSar = {};

      return {
        ...state,
        SARList: (state.SARList || []).map((sar) => {
          if (idsArray && idsArray.indexOf(sar.Id) > -1) {
            sar.isDeleted = true; // eslint-disable-line no-param-reassign
          }
          return sar;
        }),
        isDeletingSar: newIsDeletingSar,
        isInvalidDeleteSar: newIsInvalidDeleteSar,
      };
    },
    [sarDeleteActions.invalid]: (state, { payload: idsArray }) => {
      const newIsInvalidDeleteSar = {};
      const newIsDeletingSar = {};
      if (idsArray) {
        for (let i = 0; i < idsArray.length; i++) {
          newIsInvalidDeleteSar[idsArray[i]] = true;
        }
      }
      return {
        ...state,
        isDeletingSar: newIsDeletingSar,
        isInvalidDeleteSar: newIsInvalidDeleteSar,
      };
    },
    [getGDPRConfigActions.request]: (state) => ({
      ...state,
      isFetchingGDPRconfig: true,
    }),
    [getGDPRConfigActions.receive]: (state, { payload }) => ({
      ...state,
      configuration: payload,
      isFetchingGDPRconfig: false,
      gdprConfigReady:
        'Identifiers' in payload && !isEmpty(payload.Identifiers),
    }),
    [getGDPRConfigActions.invalid]: (state) => ({
      ...state,
      isFetchingGDPRconfig: false,
      configurationFetchError: true,
    }),
    [gdprConfigUpdateActions.request]: (state) => ({
      ...state,
      isFetchingConfiguration: true,
      configurationUpdateError: false,
    }),
    [gdprConfigUpdateActions.receive]: (state, { payload }) => ({
      ...state,
      configuration: payload,
      configurationUpdateError: false,
      gdprConfigReady: true,
      isFetchingConfiguration: false,
    }),
    [gdprConfigUpdateActions.invalid]: (state) => ({
      ...state,
      configurationUpdateError: true,
      isFetchingConfiguration: false,
    }),
    [anonymizeActions.request]: (state, { payload }) => ({
      ...state,
      isAnonymizingEntity: {
        ...state.isAnonymizingEntity,
        [payload]: true,
      },
      invalidAnonymizingEntity: {
        ...state.invalidAnonymizingEntity,
        [payload]: false,
      },
    }),
    [anonymizeActions.receive]: (state, { payload }) => ({
      ...state,
      isAnonymizingEntity: {
        ...state.isRemovingFromProcessing,
        [payload]: false,
      },
      invalidAnonymizingEntity: {
        ...state.invalidRemovingFromProcessing,
        [payload]: false,
      },
    }),
    [anonymizeActions.invalid]: (state, { payload }) => ({
      ...state,
      isAnonymizingEntity: {
        ...state.isRemovingFromProcessing,
        [payload]: false,
      },
      invalidAnonymizingEntity: {
        ...state.invalidRemovingFromProcessing,
        [payload]: true,
      },
    }),
    [removeFromProcessingActions.request]: (state, { payload }) => ({
      ...state,
      isRemovingFromProcessing: {
        ...state.isRemovingFromProcessing,
        [payload]: true,
      },
      invalidRemovingFromProcessing: {
        ...state.invalidRemovingFromProcessing,
        [payload]: false,
      },
    }),
    [removeFromProcessingActions.receive]: (state, { payload }) => ({
      ...state,
      isRemovingFromProcessing: {
        ...state.isRemovingFromProcessing,
        [payload]: false,
      },
      invalidRemovingFromProcessing: {
        ...state.invalidRemovingFromProcessing,
        [payload]: false,
      },
    }),
    [removeFromProcessingActions.invalid]: (state, { payload }) => ({
      ...state,
      isRemovingFromProcessing: {
        ...state.isRemovingFromProcessing,
        [payload]: false,
      },
      invalidRemovingFromProcessing: {
        ...state.invalidRemovingFromProcessing,
        [payload]: true,
      },
    }),
    [addFromProcessingActions.request]: (state, { payload }) => ({
      ...state,
      isRemovingFromProcessing: {
        ...state.isRemovingFromProcessing,
        [payload]: true,
      },
      invalidRemovingFromProcessing: {
        ...state.invalidRemovingFromProcessing,
        [payload]: false,
      },
    }),
    [addFromProcessingActions.receive]: (state, { payload }) => ({
      ...state,
      isRemovingFromProcessing: {
        ...state.isRemovingFromProcessing,
        [payload]: false,
      },
      invalidRemovingFromProcessing: {
        ...state.invalidRemovingFromProcessing,
        [payload]: false,
      },
    }),
    [addFromProcessingActions.invalid]: (state, { payload }) => ({
      ...state,
      isRemovingFromProcessing: {
        ...state.isRemovingFromProcessing,
        [payload]: false,
      },
      invalidRemovingFromProcessing: {
        ...state.invalidRemovingFromProcessing,
        [payload]: true,
      },
    }),
    [unMinimizeEntityActions.request]: (state, { payload }) => ({
      ...state,
      isMinimizingEntity: {
        ...state.isMinimizingEntity,
        [payload]: true,
      },
      invalidMinizedEntity: {
        ...state.invalidMinizedEntity,
        [payload]: false,
      },
    }),
    [unMinimizeEntityActions.receive]: (state, { payload }) => ({
      ...state,
      isMinimizingEntity: {
        ...state.isMinimizingEntity,
        [payload]: false,
      },
      invalidMinizedEntity: {
        ...state.invalidMinizedEntity,
        [payload]: false,
      },
    }),
    [unMinimizeEntityActions.invalid]: (state, { payload }) => ({
      ...state,
      isMinimizingEntity: {
        ...state.isMinimizingEntity,
        [payload]: false,
      },
      invalidMinizedEntity: {
        ...state.invalidMinizedEntity,
        [payload]: true,
      },
    }),
    [minimizeEntityActions.request]: (state, { payload }) => ({
      ...state,
      isMinimizingEntity: {
        ...state.isMinimizingEntity,
        [payload]: true,
      },
      invalidMinizedEntity: {
        ...state.invalidMinizedEntity,
        [payload]: false,
      },
    }),
    [minimizeEntityActions.receive]: (state, { payload }) => ({
      ...state,
      isMinimizingEntity: {
        ...state.isMinimizingEntity,
        [payload]: false,
      },
      invalidMinizedEntity: {
        ...state.invalidMinizedEntity,
        [payload]: false,
      },
    }),
    [minimizeEntityActions.invalid]: (state, { payload }) => ({
      ...state,
      isMinimizingEntity: {
        ...state.isMinimizingEntity,
        [payload]: false,
      },
      invalidMinizedEntity: {
        ...state.invalidMinizedEntity,
        [payload]: true,
      },
    }),
    [unSubscribeGdprUserData.request]: (state, { payload }) => ({
      ...state,
      subscribingUserData: {
        ...state.subscribingUserData,
        ...(payload && payload.id
          ? {
              [payload.id]: {
                isFetching: true,
                done: false,
                invalid: false,
              },
            }
          : {}),
      },
    }),
    [unSubscribeGdprUserData.receive]: (state, { payload }) => ({
      ...state,
      subscribingUserData: {
        ...state.subscribingUserData,
        ...(payload && payload.id
          ? {
              [payload.id]: {
                isFetching: false,
                done: true,
                invalid: false,
              },
            }
          : {}),
      },
      currentSAR: {
        ...state.currentSAR,
        Subscribed: false,
      },
    }),
    [unSubscribeGdprUserData.invalid]: (state, { payload }) => ({
      ...state,
      subscribingUserData: {
        ...state.subscribingUserData,
        ...(payload && payload.id
          ? {
              [payload.id]: {
                isFetching: false,
                done: false,
                invalid: true,
              },
            }
          : {}),
      },
    }),
    [subscribeGdprUserData.request]: (state, { payload }) => ({
      ...state,
      subscribingUserData: {
        ...state.subscribingUserData,
        ...(payload && payload.id
          ? {
              [payload.id]: {
                isFetching: true,
                done: false,
                invalid: false,
              },
            }
          : {}),
      },
    }),
    [subscribeGdprUserData.receive]: (state, { payload }) => ({
      ...state,
      subscribingUserData: {
        ...state.subscribingUserData,
        ...(payload && payload.id
          ? {
              [payload.id]: {
                isFetching: false,
                done: true,
                invalid: false,
              },
            }
          : {}),
      },
      currentSAR: {
        ...state.currentSAR,
        Subscribed: true,
      },
    }),
    [subscribeGdprUserData.invalid]: (state, { payload }) => ({
      ...state,
      subscribingUserData: {
        ...state.subscribingUserData,
        ...(payload && payload.id
          ? {
              [payload.id]: {
                isFetching: false,
                done: false,
                invalid: true,
              },
            }
          : {}),
      },
    }),
    [consentReportActions.request]: (state) => ({
      ...state,
      isFetchingConsentReport: true,
      isInvalidConsentReport: false,
      consentReport: null,
    }),
    [consentReportActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingConsentReport: false,
      isInvalidConsentReport: false,
      consentReport: payload,
    }),
    [consentReportActions.invalid]: (state) => ({
      ...state,
      isFetchingConsentReport: false,
      isInvalidConsentReport: true,
      consentReport: null,
    }),
    [identifiersReportActions.request]: (state) => ({
      ...state,
      isFetchingUniqueIdentifiersReport: true,
      isInvalidUniqueIdentifiersReport: false,
      identifiersReport: null,
    }),
    [identifiersReportActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingUniqueIdentifiersReport: false,
      isInvalidUniqueIdentifiersReport: false,
      identifiersReport: payload,
    }),
    [identifiersReportActions.invalid]: (state) => ({
      ...state,
      isFetchingUniqueIdentifiersReport: false,
      isInvalidUniqueIdentifiersReport: true,
      identifiersReport: null,
    }),
    [encryptedReportActions.request]: (state) => ({
      ...state,
      isFetchingEncryptedReport: true,
      isInvalidEncryptedReport: false,
      encryptedReport: null,
    }),
    [encryptedReportActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingEncryptedReport: false,
      isInvalidEncryptedReport: false,
      encryptedReport: payload,
    }),
    [encryptedReportActions.invalid]: (state) => ({
      ...state,
      isFetchingEncryptedReport: false,
      isInvalidEncryptedReport: true,
      encryptedReport: null,
    }),
    [locationReportActions.request]: (state) => ({
      ...state,
      isFetchingLocationReport: true,
      isInvalidLocationReport: false,
      locationReport: null,
    }),
    [locationReportActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingLocationReport: false,
      isInvalidLocationReport: false,
      locationReport: payload,
    }),
    [locationReportActions.invalid]: (state) => ({
      ...state,
      isFetchingLocationReport: false,
      isInvalidLocationReport: true,
      locationReport: null,
    }),
    [entityTypeReportActions.request]: (state) => ({
      ...state,
      isFetchingEntityTypeReport: true,
      isInvalidEntityTypeReport: false,
      entityTypeReport: null,
    }),
    [entityTypeReportActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingEntityTypeReport: false,
      isInvalidEntityTypeReport: false,
      entityTypeReport: payload,
    }),
    [entityTypeReportActions.invalid]: (state) => ({
      ...state,
      isFetchingEntityTypeReport: false,
      isInvalidEntityTypeReport: true,
      entityTypeReport: null,
    }),
    [requestSARReportReadyNotif]: (state, { payload: notif }) => ({
      ...state,
      notifs: { ...state.notifs, [notif.id]: notif },
    }),
    [resetSARReportReadyNotif]: (state, { payload: notif }) => {
      const newNotifs = {
        ...state.notifs,
      };
      delete newNotifs[notif.id];
      return {
        ...state,
        notifs: newNotifs,
      };
    },
    [getFilteredSARsActions.request]: (state) => ({
      ...state,
      isFetching: true,
      SARListError: false,
    }),
    [getFilteredSARsActions.invalid]: (state) => ({
      ...state,
      isFetching: false,
      SARListError: true,
    }),
    [getFilteredSARsActions.receive]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      SARListError: false,
      SARList: [...payload],
    }),
    [shouldBatchActionsForGdpr.request]: (state, { payload: id }) => ({
      ...state,
      GDPRActions: {
        ...state.GDPRActions,
        [id]: {
          isFetching: true,
          done: false,
          invalid: false,
        },
      },
    }),
    [shouldBatchActionsForGdpr.receive]: (state, { payload: id }) => ({
      ...state,
      GDPRActions: {
        ...state.GDPRActions,
        [id]: {
          isFetching: false,
          done: true,
          invalid: false,
        },
      },
    }),
    [shouldBatchActionsForGdpr.invalid]: (state, { payload: id }) => ({
      ...state,
      GDPRActions: {
        ...state.GDPRActions,
        [id]: {
          isFetching: false,
          done: false,
          invalid: true,
        },
      },
    }),
    [shouldResetEscalateDialog]: (state) => ({
      ...state,
      escalateDialog: [],
      isEscalating: false,
      escalatingError: null,
    }),
    [shouldShowEscalateDialog]: (state, { payload }) => ({
      ...state,
      showEscalateDialog: true,
      escalateDialog: payload,
    }),
    [shouldhideEscalateDialog]: (state) => ({
      ...state,
      showEscalateDialog: false,
      escalateDialog: [],
    }),
    [escalateSarIdsForUsersIdsActions.request]: (state) => ({
      ...state,
      isEscalating: true,
    }),
    [escalateSarIdsForUsersIdsActions.receive]: (state) => ({
      ...state,
      showEscalateDialog: false,
      escalateDialog: [],
      isEscalating: false,
    }),
    [escalateSarIdsForUsersIdsActions.invalid]: (state) => ({
      ...state,
      escalatingError: true,
      isEscalating: false,
    }),

    [getGDPRJsonReportActions.request]: (state) => ({
      ...state,
      isFetchingJsonReport: true,
      isFetchingJsonReportError: false,
      currentSARJsonReport: null,
    }),
    [getGDPRJsonReportActions.invalid]: (state) => ({
      ...state,
      isFetchingJsonReport: false,
      isFetchingJsonReportError: true,
      currentSARJsonReport: null,
    }),
    [getGDPRJsonReportActions.receive]: (state, { payload }) => ({
      ...state,
      isFetchingJsonReport: false,
      isFetchingJsonReportError: false,
      currentSARJsonReport: payload,
    }),
    [resetGDPRJsonReportActions]: (state) => ({
      ...state,
      isFetchingJsonReport: false,
      isFetchingJsonReportError: false,
      currentSARJsonReport: null,
    }),
    [showNewSarDialog]: (state) => ({
      ...state,
      showNewSarDialog: true,
    }),
    [hideNewSarDialog]: (state) => ({
      ...state,
      showNewSarDialog: false,
    }),
    [generateSARReportActions.request]: (state) => ({
      ...state,
      isFetchingGenerateReport: true,
      generateReportError: false,
      generateReportSuccess: false,
    }),
    [generateSARReportActions.invalid]: (state) => ({
      ...state,
      isFetchingGenerateReport: false,
      generateReportError: true,
      generateReportSuccess: false,
    }),
    [generateSARReportActions.receive]: (state) => ({
      ...state,
      isFetchingGenerateReport: false,
      generateReportError: false,
      generateReportSuccess: true,
    }),
    [regenerateSARReportActions.request]: (state) => ({
      ...state,
      isFetchingRegenerateReport: true,
      regenerateReportError: false,
      regenerateReportSuccess: false,
    }),
    [regenerateSARReportActions.invalid]: (state) => ({
      ...state,
      isFetchingRegenerateReport: false,
      regenerateReportError: true,
      regenerateReportSuccess: false,
    }),
    [regenerateSARReportActions.receive]: (state) => ({
      ...state,
      isFetchingRegenerateReport: false,
      regenerateReportError: false,
      regenerateReportSuccess: true,
    }),
    [resetRegenerateSARReportAction]: (state) => ({
      ...state,
      isFetchingRegenerateReport: false,
      regenerateReportError: false,
      regenerateReportSuccess: false,
    }),
    [extendReportActions.request]: (state) => ({
      ...state,
      availableTransformsInvalid: false,
      availableTransformsLoading: true,
      availableTransforms: null,
    }),
    [extendReportActions.invalid]: (state, { payload }) => ({
      ...state,
      availableTransformsInvalid: payload || true,
      availableTransformsLoading: false,
      availableTransforms: payload,
    }),
    [extendReportActions.receive]: (state, { payload }) => ({
      ...state,
      availableTransformsInvalid: false,
      availableTransformsLoading: false,
      availableTransforms: payload,
    }),
    [postExtendReportActions.request]: (state) => ({
      ...state,
      postExtendReportSuccess: false,
      postExtendReportInvalid: false,
      postExtendReportConflicted: false,
      postExtendReportLoading: true,
      postExtendReport: null,
    }),
    [postExtendReportActions.invalid]: (state, { payload }) => ({
      ...state,
      postExtendReportSuccess: false,
      postExtendReportInvalid: payload,
      postExtendReportConflicted: false,
      postExtendReportLoading: false,
      postExtendReport: payload,
    }),
    [postExtendReportActionsConflicted]: (state, { payload }) => ({
      ...state,
      postExtendReportSuccess: false,
      postExtendReportInvalid: false,
      postExtendReportConflicted: true,
      postExtendReportLoading: false,
      postExtendReport: payload,
    }),
    [postExtendReportActions.receive]: (state, { payload }) => ({
      ...state,
      postExtendReportSuccess: true,
      postExtendReportInvalid: false,
      postExtendReportConflicted: false,
      postExtendReportLoading: false,
      postExtendReport: payload,
    }),
    [resetPostExtendReportAction]: (state) => ({
      ...state,
      postExtendReport: null,
      postExtendReportLoading: false,
      postExtendReportConflicted: false,
      postExtendReportInvalid: false,
      postExtendReportSuccess: false,
    }),
    [shouldCreateAllSarActions.request]: (state, { payload }) => ({
      ...state,
      batchSarCreationStatus: {
        [payload.clientId]: {
          isFetching: true,
          invalid: false,
          done: false,
        },
      },
      batchSarCreationStatusDone: true,
    }),
    [shouldCreateAllSarActions.receive]: (state, { payload }) => ({
      ...state,
      batchSarCreationStatus: {
        [payload.clientId]: {
          isFetching: false,
          invalid: false,
          done: true,
        },
      },
      batchSarCreationStatusDone: true,
    }),
    [shouldCreateAllSarActions.invalid]: (state, { payload }) => ({
      ...state,
      batchSarCreationStatus: {
        [payload.clientId]: {
          isFetching: false,
          invalid: false,
          done: true,
        },
      },
      batchSarCreationStatusDone: true,
    }),
    [shouldCreateAllSarActionsAllDone]: (state) => ({
      ...state,
      batchSarCreationStatus: {},
      batchSarCreationStatusDone: true,
    }),
    [shouldClearAllSarBatchCreation]: (state) => ({
      ...state,
      batchSarCreationStatus: {},
      batchSarCreationStatusDone: false,
    }),
    [batchImportFromFileActions.request]: (state) => ({
      ...state,
      importedSAR: null,
      importedSARInvalid: false,
      importedSARFetching: true,
      importedSARSuccess: false,
    }),
    [batchImportFromFileActions.receive]: (state, { payload }) => ({
      ...state,
      importedSAR: payload,
      importedSARInvalid: false,
      importedSARFetching: false,
      importedSARSuccess: true,
    }),
    [batchImportFromFileActions.invalid]: (state, { payload }) => ({
      ...state,
      importedSAR: null,
      importedSARInvalid: payload || true,
      importedSARFetching: false,
      importedSARSuccess: false,
    }),
    [batchImportFromFileCleanUpActions]: (state) => ({
      ...state,
      importedSAR: null,
      importedSARInvalid: false,
      importedSARFetching: false,
      importedSARSuccess: false,
    }),
  },
  initialState,
);
