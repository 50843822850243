import { useEffect } from 'react';
import cogoToast from 'cogo-toast';
import { get } from 'lodash';
import { useMutation, gql } from '@apollo/client';

const REVOKE_ACCESS = gql`
  mutation revokeUsersPermission($connectorId: ID, $userIds: [ID]) {
    inbound {
      revokeUsersPermission(connectorId: $connectorId, userIds: $userIds) {
        id
        users {
          id
        }
      }
    }
  }
`;

export const useRevokeUsersPermission = () => {
  const [revokeUsersPermission, { loading, error, data }] =
    useMutation(REVOKE_ACCESS);

  const revokedDone = get(data, 'inbound.revokeUsersPermission');

  useEffect(() => {
    if (!loading && revokedDone) {
      cogoToast.success(`User has been revoked`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
    }
  }, [loading, revokedDone]);

  return [
    (connector, userIds) =>
      revokeUsersPermission({
        variables: {
          connectorId: connector.id,
          userIds,
        },
      }),
    loading,
    error,
    revokedDone,
  ];
};
