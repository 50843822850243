import React from 'react';
import { GqlErrorMessages, Spinner } from '@cluedin/form';
import { getScaledValueWithMetricNotation } from '../../../../core/helpers/numbers';
import { useVocabularyKeyKpi } from '../../../hooks/useVocabularyKeyKpi';

const TotalVocabularyKey = () => {
  const [data, loading, error] = useVocabularyKeyKpi();

  const value = getScaledValueWithMetricNotation((data || {}).total || 0);

  return (
    <span>
      {error && <GqlErrorMessages />}
      {!loading && value}
      {loading && <Spinner size={40} />}
    </span>
  );
};

export default TotalVocabularyKey;
