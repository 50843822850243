import { FC, useState, useEffect } from 'react';
import { useTheme } from '@cluedin/theme';

import { useEntityRelations } from './hooks';
import { EntityRelationsGraphV3Wrapper } from './EntityRelationsGraphV3Wrapper';
import { EntityRelationsGraphV3LoaderWrapper } from './EntityRelationsGraphV3LoaderWrapper';
import { D3EntityRelationsGraph } from './D3EntityRelationsGraph';
import EntityRelationsTree from '../../entityRelationsTree/containers/EntityRelationsTree';
import {
  generateGraphFromSummary,
  filterTemporalNodes,
  filterShadowNodes,
} from './utils/generateGraphFromSummary';
import { EntityNodePanel } from './EntityNodePanel';
import { EntityRelationEdgePanel } from './EntityRelationEdgePanel';
import { EntityGroupedRelationPanel } from './EntityGroupedRelationPanel';
import PageLoader from '../../core/components/composites/PageLoader';
import { EntityRelationsGraphV3Header } from './EntityRelationsGraphV3Header';
import { EntityRelationsGraphV3TreeScrollWrapper } from './EntityRelationsGraphV3TreeScrollWrapper';
import {
  GraphNetwork,
  GroupedNode,
  SelectedNodeEdge,
  SelectedConnectingEdge,
  UpdatedGraphNetworkNode,
  UpdatedGraphNetworkEdge,
  Entity,
  SelectedEntityNode,
} from './types';
import { TreeEdge } from '../../entityRelationsTree/containers/types';

interface EntityRelationsGraphV3Props {
  entityId: string;
  entity: Entity;
}

const EntityRelationsGraphV3: FC<EntityRelationsGraphV3Props> = ({
  entity,
  entityId,
}) => {
  const theme = useTheme();
  const themePrimary = theme?.palette?.themePrimary;

  const [edgeSummary, loading, error] = useEntityRelations(entityId);

  const [groupedNode, setGroupedNode] = useState<GroupedNode | null>(null);
  const [selectedNode, setSelectedNode] = useState<SelectedEntityNode | null>(
    null,
  );
  const [selectedEdgesFromEdgeSummary, setSelectedEdgesFromEdgeSummary] =
    useState<SelectedConnectingEdge | null>(null);
  const [isShadowNodesHidden, setIsShadowNodesHidden] = useState<boolean>(true);
  const [isTemporalNodesHidden, setIsTemporalNodesHidden] =
    useState<boolean>(true);
  const [graphNetworkState, setGraphNetworkState] = useState<GraphNetwork>({
    edges: [],
    nodes: [],
  });
  const [isTreeviewExpanded, setIsTreeviewExpanded] = useState<boolean>(false);

  let initialGraphNetwork: GraphNetwork = {
    nodes: [],
    edges: [],
  };

  useEffect(() => {
    if (!loading && !error) {
      initialGraphNetwork = generateGraphFromSummary(
        edgeSummary,
        entityId,
        themePrimary,
      );

      if (isShadowNodesHidden) {
        initialGraphNetwork = filterShadowNodes(initialGraphNetwork, entityId);
      }

      if (isTemporalNodesHidden) {
        initialGraphNetwork = filterTemporalNodes(
          initialGraphNetwork,
          entityId,
        );
      }

      setGraphNetworkState(initialGraphNetwork);
    }
  }, [
    loading,
    error,
    edgeSummary,
    entityId,
    themePrimary,
    isShadowNodesHidden,
    isTemporalNodesHidden,
  ]);

  const handleEdgeClick = (edge: UpdatedGraphNetworkEdge | TreeEdge) => {
    const edgeType = edge?.label;
    const edgeTypesArray = edgeType
      ?.split('\n')
      ?.filter((s: string) => s !== '');
    const edgeDirection = edge?.to === entityId ? 'incoming' : 'outgoing';
    const edgeFromId = edge?.from;
    const edgeToId = edge?.to;

    const nodeFrom = graphNetworkState?.nodes?.filter(
      (node) => node?.id === edgeFromId,
    )?.[0];
    const nodeTo = graphNetworkState?.nodes?.filter(
      (node) => node?.id === edgeToId,
    )?.[0];

    const edgeFrom = graphNetworkState?.nodes?.filter(
      (nodeFromSummary) => nodeFromSummary?.id === edgeFromId,
    )?.[0];
    const edgeTo = graphNetworkState?.nodes?.filter(
      (nodeFromSummary) => nodeFromSummary?.id === edgeToId,
    )?.[0];

    setSelectedEdgesFromEdgeSummary({
      edgeType,
      edgeTypesArray,
      edgeDirection,
      nodeFrom,
      nodeTo,
      edgeFrom,
      edgeTo,
    });
  };

  const handleGroupedRelationsClick = (groupNode: UpdatedGraphNetworkNode) => {
    const nodeId = groupNode?.id;
    const relatedGroupedEdge = graphNetworkState?.edges?.filter(
      (graphNetworkEdge) =>
        graphNetworkEdge?.from === nodeId || graphNetworkEdge?.to === nodeId,
    )?.[0];
    let edgeDirection;
    if (relatedGroupedEdge?.to === entityId) {
      edgeDirection = 'incoming';
    } else {
      edgeDirection = 'outgoing';
    }

    setGroupedNode({ groupNode, relatedGroupedEdge, edgeDirection });
  };

  const handleNodeClick = (node: UpdatedGraphNetworkNode) => {
    const isNodeRootNode = edgeSummary?.id === node?.id;
    const selectedNode = isNodeRootNode
      ? edgeSummary
      : (Array.isArray(edgeSummary?.edges) ? edgeSummary?.edges : [])?.filter(
          (edge: SelectedNodeEdge) => edge?.entityId === node?.id,
        )[0];

    setSelectedNode(selectedNode ?? null);
  };

  return (
    <>
      <EntityRelationsGraphV3Header
        isTreeviewExpanded={isTreeviewExpanded}
        onTreeviewChange={setIsTreeviewExpanded}
        isShadowNodesHidden={isShadowNodesHidden}
        onShadowNodesChange={setIsShadowNodesHidden}
        onTemporalNodesChange={setIsTemporalNodesHidden}
        isTemporalNodesHidden={isTemporalNodesHidden}
        onReset={() => {
          setIsShadowNodesHidden(true);
          setIsTemporalNodesHidden(true);
        }}
      />

      <EntityRelationsGraphV3TreeScrollWrapper>
        {!isTreeviewExpanded && (
          <div style={{ flex: '1', position: 'relative' }}>
            {graphNetworkState?.nodes?.length === 0 ? (
              <EntityRelationsGraphV3LoaderWrapper>
                <PageLoader />
              </EntityRelationsGraphV3LoaderWrapper>
            ) : (
              <EntityRelationsGraphV3Wrapper>
                <D3EntityRelationsGraph
                  nodes={graphNetworkState?.nodes}
                  edges={graphNetworkState?.edges}
                  rootEntityId={entityId}
                  onNodeClick={handleNodeClick}
                  onGroupedRelationsClick={handleGroupedRelationsClick}
                  onClickExpandGroupRelationsNode={() => {}}
                  onClickExpandEntityNode={() => {}}
                  onEdgeClick={handleEdgeClick}
                />
              </EntityRelationsGraphV3Wrapper>
            )}
          </div>
        )}

        {isTreeviewExpanded && (
          <EntityRelationsTree
            entityId={entityId}
            entity={entity}
            treeNetwork={graphNetworkState}
            loading={loading}
            onEdgeClick={handleEdgeClick}
          />
        )}

        {selectedEdgesFromEdgeSummary && (
          <EntityRelationEdgePanel
            entityId={entityId}
            selectedEdge={selectedEdgesFromEdgeSummary}
            setSelectedEdge={setSelectedEdgesFromEdgeSummary}
            onNodeClick={handleNodeClick}
            onGroupedRelationsClick={handleGroupedRelationsClick}
            graphNetworkState={graphNetworkState}
          />
        )}

        {selectedNode && (
          <EntityNodePanel
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}

        {groupedNode && (
          <EntityGroupedRelationPanel
            show={!!groupedNode}
            onClose={() => setGroupedNode(null)}
            groupedNode={groupedNode}
            entityId={entityId}
          />
        )}
      </EntityRelationsGraphV3TreeScrollWrapper>
    </>
  );
};

export default EntityRelationsGraphV3;
