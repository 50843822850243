"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var TrExpension = _styledComponents.default.tr(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  &:not(:last-child) {\n    border-bottom: 1px solid hsla(0, 0%, 88%, 1);\n  }\n  background: white;\n"])));
TrExpension.displayName = 'Tr';
var _default = TrExpension;
exports.default = _default;