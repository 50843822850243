import { useContext } from 'react';

import { LayoutContext } from './layoutContext';

export const useLayoutContext = () => {
  const contextData = useContext(LayoutContext);

  if (!contextData) {
    throw new Error(
      'Make sure you are using `useLayoutContext` inside LayoutContextProvider!',
    );
  }

  return contextData;
};
