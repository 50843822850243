import { memo, useMemo, useState, useId, useCallback } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '@cluedin/locale';
import { Deduplication } from '@cluedin/svgs';
import qs from 'query-string';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Tabularview, Tileview } from 'uxi/Icons';
import {
  PrimaryButton,
  Button,
  IconButton,
  withWorkflow,
  ContextualMenu,
} from '@cluedin/form';
import { Icon } from '@fluentui/react/lib/Icon';

import { cleanProjectRootURL } from '../../../../../cleanProject/module';
import { useAppContext } from '../../../../../../shared/context/appContext';
import { FilterIconButton } from '../../../composites/FilterIconButton';
import { useGoldenRecordList } from '../../GoldenRecordListContext';
import { FilterIconButtonPlain } from '../../../composites/FilterIconButtonPlain';
import { RecordListSaveSearchModal } from './RecordListSaveSearch';
import { RecordListSavedSearches } from './RecordListSavedSearches';
import { useAppSearch } from '../../../../../AppSearch';
import { useOrganizationFeatureFlagOption } from '../../../../../featureFlag/hooks/useOrganizationFeatureFlag';

const GoldenRecordListFilterToolbarWrapper = styled.div`
  display: flex;
  padding-left: 8px;
  align-items: center;
  justify-content: space-between;
  justify-content: ${({ alignEnd }) => alignEnd && 'flex-end'};
`;

const ThemedSvgIcon = styled.i`
  line-height: 1;
  margin-bottom: -1px;

  svg path {
    fill: ${({ fillColor }) => fillColor};
  }
`;

const GoldenRecordListSidesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContextualMenuIcon = styled.span`
  display: inline-flex;
  max-height: 36px;
  align-items: center;
  overflow: hidden;
  margin-right: 3px;
`;

const AppliedSavedSearchBadge = styled.div`
  gap: 6px;
  padding: 3px 10px;
  margin-right: 8px;
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.themePrimary};
  background: ${({ theme }) => theme.palette.themeLighter};
`;

const ViewControlWrapper = styled.div`
  display: inline-flex;
  width: 86px;

  button {
    min-width: 32px;
    padding: 0;
    border-color: ${({ theme }) => theme.palette.themePrimary};
    box-shadow: none !important;

    i {
      margin: 0;
    }
  }

  div:first-of-type button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none;
  }

  div:last-of-type button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none;
  }
`;

const RecordListToolbar = ({
  hideFilter,
  setViewMode,
  hasSelection,
  startMerging,
  openWorkflow,
  onCustomizeView,
  toggleSelection,
  selectionActive,
  hasMinimumSelected,
}) => {
  const history = useHistory();

  const isNewCleanEnabled =
    useOrganizationFeatureFlagOption('New Clean Project');

  const {
    me: {
      client: { id: userId },
    },
  } = useAppContext();

  const { formatMessage } = useIntl();
  const [saveOpened, setSaveOpened] = useState(false);

  const theme = useTheme();
  const tileModeTooltipId = useId();
  const listModeTooltipId = useId();
  const cancelSectionTooltipId = useId();
  const previewMergeTooltipId = useId();
  const resetFiltersTooltipId = useId();
  const saveAsFiltersTooltipId = useId();
  const saveFiltersTooltipId = useId();
  const appliedSearchTooltipId = useId();

  const {
    viewName,
    listOptions: { filters, resetFilters, filterUrl },
    appliedSavedSearch,
    filterOptions: {
      saveDisabled,
      saveAsDisabled,
      hasActiveFilters,
      handleEditSearch,
      appliedSavedSearchName,
      loadAndSetAppliedSavedSearch,
    },
    viewOptions: { columns, viewMode, getColumnsForViewName },
  } = useGoldenRecordList();

  const { value, setValuesForClean } = useAppSearch();

  const cleanFromSearchUrlOptions = useMemo(() => {
    return qs.stringify({ createOpened: true });
  }, []);

  const newCleanUrl = useMemo(
    () => `${cleanProjectRootURL}?${cleanFromSearchUrlOptions}`,
    [cleanFromSearchUrlOptions],
  );

  const goToCleanV2 = useCallback(() => {
    const columnsToClean = columns?.map?.((i) => ({
      type: i?.type,
      property: i?.property,
    }));

    if (
      viewMode === 'tile' &&
      !columnsToClean.find((i) => i?.property === 'name')
    ) {
      columnsToClean.unshift({ property: 'name', type: 'entity' });
    }

    setValuesForClean(() => ({
      query: value,
      columns: columnsToClean,
      tags: filters?.tags_name,
      condition: filters?.condition,
      providers: filters?.providers,
      entityType: filters.entityType,
      providerDefinitionIds: filters.providerDefinitionIds_v2,
    }));

    history.push(newCleanUrl);
  }, [filters, viewMode, newCleanUrl]);

  const handleResetFilters = useCallback(() => {
    hideFilter();
    resetFilters();
  }, []);

  const isEditAllowed =
    appliedSavedSearch?.shared || userId === appliedSavedSearch?.createdBy;

  const TileBtn = !viewMode || viewMode === 'tile' ? PrimaryButton : Button;
  const TableBtn = viewMode === 'table' ? PrimaryButton : Button;

  const handleChangeViewMode = (mode) => {
    if (viewMode === mode) {
      return;
    }

    setViewMode(mode);
  };

  const handleSave = () => setSaveOpened(() => true);
  const handleCloseSave = () => setSaveOpened(() => false);

  const openSavedSearchesPanel = () => {
    openWorkflow();
  };

  const onEditSearch = async () => {
    const searchData = {
      name: appliedSavedSearchName,
      shared: appliedSavedSearch?.shared || false,
      viewMode,
      filterUrl,
      columnsConfig: {
        [viewMode]: columns,
        [viewMode === 'tile' ? 'table' : 'tile']:
          viewMode === 'tile'
            ? getColumnsForViewName(`${viewName}-table`)
            : getColumnsForViewName(`${viewName}-tile`),
      },
    };

    await handleEditSearch(appliedSavedSearch.id, searchData);

    loadAndSetAppliedSavedSearch();

    hideFilter();
  };

  return (
    <>
      <RecordListSaveSearchModal
        opened={saveOpened}
        hideFilter={hideFilter}
        handleClose={handleCloseSave}
      />

      <GoldenRecordListFilterToolbarWrapper
        className="GoldenRecordListFilterToolbar"
        alignEnd={!selectionActive && !appliedSavedSearch}
      >
        {(selectionActive || appliedSavedSearch) && (
          <GoldenRecordListSidesWrapper>
            {appliedSavedSearch?.name && (
              <AppliedSavedSearchBadge theme={theme}>
                {appliedSavedSearch.name?.length > 15 ? (
                  <TooltipHost
                    id={appliedSearchTooltipId}
                    calloutProps={{ gapSpace: 0 }}
                    content={appliedSavedSearch.name}
                  >
                    {appliedSavedSearch.name.slice(0, 15)}...
                  </TooltipHost>
                ) : (
                  appliedSavedSearch.name
                )}

                <Icon
                  onClick={handleResetFilters}
                  iconName="Cancel"
                  styles={{
                    root: {
                      fontSize: 12,
                      cursor: 'pointer',
                      color: theme.palette.themePrimary,
                    },
                  }}
                />
              </AppliedSavedSearchBadge>
            )}

            {selectionActive && (
              <>
                <TooltipHost
                  id={cancelSectionTooltipId}
                  calloutProps={{ gapSpace: 0 }}
                  content={formatMessage({
                    id: 'module-goldenRecordList-cancel-selection',
                  })}
                >
                  <IconButton
                    onClick={toggleSelection}
                    style={{ marginRight: '6px' }}
                    iconProps={{ iconName: 'Cancel' }}
                  />
                </TooltipHost>

                <TooltipHost
                  id={previewMergeTooltipId}
                  calloutProps={{ gapSpace: 0 }}
                  content={formatMessage({
                    id: hasMinimumSelected
                      ? 'module-goldenRecordList-merge-preview'
                      : 'module-goldenRecordList-merge-preview-disabled',
                  })}
                >
                  <IconButton
                    onClick={startMerging}
                    disabled={!hasMinimumSelected}
                  >
                    <ThemedSvgIcon fillColor={theme.palette.themePrimary}>
                      <Deduplication width={18} height={18} size={18} />
                    </ThemedSvgIcon>

                    <span style={{ marginLeft: '6px' }}>
                      {formatMessage({
                        id: 'module-goldenRecordList-start-merging',
                      })}
                    </span>
                  </IconButton>
                </TooltipHost>
              </>
            )}
          </GoldenRecordListSidesWrapper>
        )}

        <GoldenRecordListSidesWrapper
          selectionActive={selectionActive}
          style={{ gap: '8px' }}
        >
          <FilterIconButtonPlain
            onClick={() =>
              onCustomizeView({
                overrideTitle: formatMessage({
                  id:
                    viewMode === 'table'
                      ? 'module-goldenRecordList-customizeColumns'
                      : 'module-goldenRecordList-customizeFields',
                }),
                addLabel: formatMessage({
                  id:
                    viewMode === 'table'
                      ? 'module-goldenRecordList-addColumns'
                      : 'module-goldenRecordList-addFields',
                }),
              })
            }
            iconProps={{ iconName: 'Settings' }}
          >
            {formatMessage({
              id:
                viewMode === 'table'
                  ? 'module-goldenRecordList-customizeColumns'
                  : 'module-goldenRecordList-customizeFields',
            })}
          </FilterIconButtonPlain>

          {appliedSavedSearch && (
            <TooltipHost
              id={saveAsFiltersTooltipId}
              calloutProps={{ gapSpace: 0 }}
              content={formatMessage({
                id: 'module-goldenRecordList-save-as-exp',
              })}
            >
              <FilterIconButtonPlain
                onClick={handleSave}
                disabled={saveAsDisabled}
                iconProps={{ iconName: 'Save' }}
              >
                {formatMessage({ id: 'module-goldenRecordList-save-as' })}
              </FilterIconButtonPlain>
            </TooltipHost>
          )}

          <TooltipHost
            id={resetFiltersTooltipId}
            calloutProps={{ gapSpace: 0 }}
            content={formatMessage({
              id: 'module-goldenRecordList-reset-filter',
            })}
          >
            <FilterIconButtonPlain
              onClick={handleResetFilters}
              disabled={!hasActiveFilters}
              iconProps={{ iconName: 'Reset' }}
            >
              {formatMessage({ id: 'module-goldenRecordList-reset' })}
            </FilterIconButtonPlain>
          </TooltipHost>

          {viewName === 'mainSearch' && (
            <TooltipHost
              id={saveFiltersTooltipId}
              calloutProps={{ gapSpace: 0 }}
              content={formatMessage({
                id:
                  appliedSavedSearch && !isEditAllowed
                    ? 'module-goldenRecordList-saved-search-edit-disabled-reason'
                    : !saveDisabled && appliedSavedSearch
                    ? 'module-goldenRecordList-save-edited-search'
                    : 'module-goldenRecordList-save-filter',
              })}
            >
              <FilterIconButton
                disabled={
                  saveDisabled || (appliedSavedSearch && !isEditAllowed)
                }
                style={{ marginRight: '4px' }}
                iconProps={{ iconName: 'Save' }}
                onClick={appliedSavedSearch ? onEditSearch : handleSave}
              />
            </TooltipHost>
          )}

          <ViewControlWrapper theme={theme}>
            <TooltipHost
              id={tileModeTooltipId}
              calloutProps={{ gapSpace: 0 }}
              content={formatMessage({
                id: 'module-goldenRecordList-tile-view-mode',
              })}
            >
              <TileBtn
                disabled={selectionActive}
                startIcon={<Tileview size={14} />}
                style={{ borderRadius: '4px', padding: '0 14px' }}
                onClick={() => {
                  handleChangeViewMode('tile');
                }}
              />
            </TooltipHost>

            <TooltipHost
              id={listModeTooltipId}
              calloutProps={{ gapSpace: 0 }}
              content={formatMessage({
                id: 'module-goldenRecordList-list-view-mode',
              })}
            >
              <TableBtn
                disabled={selectionActive}
                startIcon={<Tabularview size={14} />}
                style={{ borderRadius: '4px', padding: '0 14px' }}
                onClick={() => {
                  handleChangeViewMode('table');
                }}
              />
            </TooltipHost>
          </ViewControlWrapper>

          {(hasSelection || viewName === 'mainSearch') && (
            <ContextualMenu
              styles={{ padding: '20px' }}
              buttonType="IconButton"
              onlyCustomIcon
              items={[
                hasSelection && {
                  disabled: selectionActive,
                  iconProps: {},
                  onRenderIcon: () => (
                    <ContextualMenuIcon>
                      <ThemedSvgIcon fillColor={theme.palette.themePrimary}>
                        <Deduplication width={18} height={18} size={18} />
                      </ThemedSvgIcon>
                    </ContextualMenuIcon>
                  ),
                  key: 'toggleSelection',
                  onClick: () => {
                    hideFilter();
                    toggleSelection();
                  },
                  text: formatMessage({
                    id: 'module-goldenRecordList-select-for-merge',
                  }),
                },
                isNewCleanEnabled &&
                  viewName === 'mainSearch' && {
                    onRenderIcon: () => (
                      <ContextualMenuIcon>
                        <Icon iconName="Search" style={{ lineHeight: 1 }} />
                      </ContextualMenuIcon>
                    ),
                    key: 'clean',
                    onClick: goToCleanV2,
                    text: formatMessage({
                      id: 'module-goldenRecordList-clean',
                    }),
                  },
                viewName === 'mainSearch' && {
                  disabled: selectionActive,
                  onRenderIcon: () => (
                    <ContextualMenuIcon>
                      <Icon iconName="Save" style={{ lineHeight: 1 }} />
                    </ContextualMenuIcon>
                  ),
                  key: 'toggleSelection',
                  onClick: openSavedSearchesPanel,
                  text: formatMessage({
                    id: 'module-goldenRecordList-saved-searches',
                  }),
                },
              ].filter(Boolean)}
              IconButtonContent={
                <Icon
                  styles={{
                    root: { lineHeight: 0, fontSize: 18 },
                  }}
                  iconName={'MoreVertical'}
                />
              }
            />
          )}
        </GoldenRecordListSidesWrapper>
      </GoldenRecordListFilterToolbarWrapper>
    </>
  );
};

export default memo(
  withWorkflow(RecordListToolbar, RecordListSavedSearches, {
    title: <FormattedMessage id="module-goldenRecordList-saved-searches" />,
    panelType: 'medium',
  }),
);
