import { connect } from 'react-redux';
import { getEntityConfigFromState } from '../../selectors';

const mapToStateProps = ({ wms }, { entityType, entity }) => {
  let entityTypeToUse = entityType;

  if (!entityTypeToUse) {
    entityTypeToUse =
      entity && entity.data ? entity.data.entityType : entity.entityType;
  }

  return getEntityConfigFromState(wms, entityTypeToUse);
};

export default (Comp, mapDispatchToProps) =>
  connect(mapToStateProps, mapDispatchToProps)(Comp);
