import { useState, useEffect } from 'react';
import { intersectionWith } from 'lodash';

import VocabOrPropertySelectionWithDialogs from './VocabOrPropertySelectionWithDialogs';

const VocabOrPropertySelection = ({
  onChange,
  entityKeys = [],
  disabled = false,
  noEntityProperties,
  defaultColumns = [],
  onlyFunctionality = false,
}) => {
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setColumns(defaultColumns);
  }, [defaultColumns]);

  const onAddColumns = (newColumns = [], type) => {
    if (type === 'vocabulary') {
      const vocabularyColumns = newColumns.map((c) => ({
        property: c,
        type: 'vocabulary',
      }));

      const prevFilteredColumns = columns.filter((i) => i.type === 'entity');

      const allColumns = [...prevFilteredColumns, ...vocabularyColumns];

      setColumns(allColumns);

      onChange?.(allColumns);
    }

    if (type === 'entity') {
      const entityColumns = newColumns.map((c) => ({
        property: c,
        type: 'entity',
      }));

      const prevFilteredColumns = columns.filter(
        (i) => i.type === 'vocabulary',
      );

      const allColumns = [...prevFilteredColumns, ...entityColumns];

      setColumns(allColumns);

      onChange?.(allColumns);
    }

    // if (type === 'entity') {
    //   // All column already present
    //   const alreadyAdded = intersectionWith(columns, newColumns, (a, b) => {
    //     return a.property === b;
    //   });
    //   // All newly added column
    //   const allNew = newColumns.filter((c) => {
    //     const isAlreadyAddedColum = alreadyAdded.find(
    //       (aC) => aC.property === c,
    //     );
    //     return !isAlreadyAddedColum;
    //   });
    //   // We remove the one that was removed
    //   let updatedColumns = columns
    //     .map((currentColumn) => {
    //       if (currentColumn.type === 'vocabulary') {
    //         return currentColumn;
    //       }
    //       if (currentColumn.property === 'name') {
    //         return currentColumn;
    //       }
    //       const isFoundInUpdatedColumns = newColumns.find(
    //         (c) => c === currentColumn.property,
    //       );
    //       if (isFoundInUpdatedColumns) {
    //         return currentColumn;
    //       }
    //       return null;
    //     })
    //     .filter((c) => c);
    //   // We add the new one
    //   if (allNew && allNew.length > 0) {
    //     const newEntitiesColumn = allNew.map((c) => ({
    //       property: c,
    //       type: 'entity',
    //     }));
    //     updatedColumns = updatedColumns.concat(newEntitiesColumn);
    //   }
    //   setColumns(updatedColumns);
    //   onChange?.(updatedColumns);
    // }
  };

  return (
    <VocabOrPropertySelectionWithDialogs
      disabled={disabled}
      entityKeys={entityKeys}
      columns={columns || []}
      onAddColumns={onAddColumns}
      onlyFunctionality={onlyFunctionality}
      noEntityProperties={noEntityProperties}
      onChangeColumns={(columns) => {
        setColumns(columns);
        onChange?.(columns);
      }}
      onDeleteColumns={(columnsToDelete = []) => {
        const filtered = columns.filter((existingColumns) => {
          const found = columnsToDelete.find(
            (c) => c.property === existingColumns.property,
          );

          return !found;
        });

        setColumns(filtered);
        onChange?.(filtered);
      }}
    />
  );
};

export default VocabOrPropertySelection;
