import { createAction } from 'redux-actions';
import { createAsyncAction } from '../core/helpers/actionCreator';
import { defaultAsyncCallWithActionsWithParam } from '../../action/helpers/actionCreator';
import {
  getWidgetConfiguration,
  setLastDateTime,
  getLastDateTime,
} from './data';

export const openWMSManager = createAction('WMS_OPEN_WMS_MANAGER');
export const closeWMSManager = createAction('WMS_CLOSE_WMS_MANAGER');

export const fetchWidgetConfigurationActions = createAsyncAction(
  'FETCH_WIDGET_CONFIGURATION',
);

export const shouldFetchWidgetConfiguration =
  defaultAsyncCallWithActionsWithParam(
    getWidgetConfiguration,
    fetchWidgetConfigurationActions,
  );

export const changeLastVisitedTimeAction = createAction(
  'WMS_CHANGE_LAST_TIMVE_VISITED',
);

export function shouldChangeLastVisitedTimeAction({ entityId, date }) {
  return (dispatch) => {
    setLastDateTime(entityId, date);

    return dispatch(changeLastVisitedTimeAction(getLastDateTime()));
  };
}
