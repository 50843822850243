import styled from 'styled-components';

export const EntityCodePanelWrapper = styled.div`
  line-height: 2.6;
  margin: 8px 16px;
  max-width: 100%;
`;

export const EntityCodePanelHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const EntityCodePanelHeading = styled.h4`
  font-weight: bold;
  margin: 0;
`;

export const GrayishLabel = styled.div`
  font-size: 14px;
  color: #c3c3c3;
`;

export const OriginCodeWrapper = styled.div`
  background-color: rgba(41, 162, 154, 0.05);
  display: inline-block;
  flex-wrap: wrap;
  line-height: 1.6;
  overflow-wrap: break-word;
  padding: 8px;
  width: 100%;
  margin-bottom: 12px;
  max-width: calc(100% - 16px);
  word-wrap: break-word;
`;

export const Divider = styled.div`
  background-color: #d7d7d8;
  content: '';
  height: 1px;
  margin: 12px 0;
`;

export const EntityCodePanelCodesList = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
`;

export const BaseWrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 20px;
  border: 1px solid #ececec;
  ${({ isFirst }) => {
    if (isFirst) {
      return `margin-top: 24px`;
    }
    return ``;
  }}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  cursor: pointer;
`;

export const ArrowWrapper = styled.div`
  margin-right: 6px;
  transition: transform 400ms;
  padding-top: ${({ isOpened }) => (isOpened ? '4px' : '5px')};
  transform: ${({ isOpened }) => `rotate(${isOpened ? 90 : 0}deg)`};
`;

export const DataWrapper = styled.div`
  margin: 0px 0 8px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  text-color: black;
  font-weight: 800;
  padding-bottom: 4px;
`;

export const ListWrapper = styled.div`
  div:last-child {
    padding-bottom: 4px;
  }
`;
