import React from 'react';
import IntegrationListRow from './IntegrationListRow';

const IntegrationList = ({
  integrations = [],
  onInviteClick,
  onAddClick,
  withInviteButton,
  query,
}) => (
  <>
    {integrations.map((integration) => (
      <IntegrationListRow
        query={query}
        withInviteButton={withInviteButton}
        key={integration.Id}
        onAddClick={onAddClick}
        onInviteClick={onInviteClick}
        integration={integration}
      />
    ))}
  </>
);

export default IntegrationList;
