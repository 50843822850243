import { makeLazy } from '../core/components/Hocs/LazyRoute';
import { RACI_LEVELS } from '@cluedin/components';

export default [
  {
    path: '/',
    exact: true,
    claims: {
      'consume.streams': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamListPage" */
        './components/pages/StreamListPage'
      ),
    ),
  },
  {
    path: '/list',
    exact: true,
    claims: {
      'consume.streams': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamListPage" */
        './components/pages/StreamListPage'
      ),
    ),
  },
  {
    path: '/detail/:id',
    claims: {
      'consume.streams': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamDetailPage" */
        './components/pages/StreamDetailPage'
      ),
    ),
  },
  {
    path: '/detail/:id',
    claims: {
      'consume.streams': RACI_LEVELS.CONSULTANT,
    },
    component: makeLazy(() =>
      import(
        /* webpackChunkName:"StreamDetailPage" */
        './components/pages/StreamDetailPage'
      ),
    ),
  },
];
