import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Query } from '@apollo/client/react/components';
import { getEntityMetricsData } from '../../data/gql';
import { legacyClient } from '../../../../data/graphql';

export const withGQLEntityMetrics =
  (Comp) =>
  (props = {}) =>
    (
      <Query
        fetchPolicy="no-cache"
        client={legacyClient}
        query={getEntityMetricsData}
        variables={{ entityId: (props.entity || {}).id }}
      >
        {({ loading, error, data = {} }) => {
          // console.log('data', data);
          let metrics = null;
          let oldest = moment(new Date()); // now
          let newest = moment(new Date(0)); // 1/1/70

          // let integrationTypeDimensions;
          if (!loading && !error && data && data.entity.metrics) {
            metrics = data.entity.metrics.map((metric) => {
              return {
                ...metric,
                globalDimension: metric.entityDimension || [],
                globalIntegrationTypeDimensions:
                  metric.integrationTypeDimensions || [],
              };
            });

            const hasValues = (metrics && metrics.length ? metrics : []).reduce(
              (acc, x = {}) => {
                return (
                  acc +
                  (x.values || []).length +
                  (x.integrationTypeDimensions || []).length
                );
              },
              0,
            );

            // console.log('hasValues', hasValues);
            const nonEmptyData = hasValues > 0;

            // console.log('nonEmptyData', nonEmptyData);

            if (nonEmptyData) {
              (metrics && metrics.length ? metrics : []).forEach((metric) => {
                // fakeMetrics.forEach((metric) => {
                const {
                  globalDimension: { values = [] } = {},
                  globalIntegrationTypeDimensions,
                } = metric;

                // values is a reserved word in JS
                const valueToIterate = _.isArray(values) ? values : [];
                (valueToIterate || []).forEach(({ date }) => {
                  const d = moment(date);
                  if (d.isBefore(oldest)) {
                    // console.log('values isBefore');
                    oldest = d;
                  }

                  if (d.isAfter(newest)) {
                    // console.log('values isAfter');
                    newest = d;
                  }
                });

                // eslint-disable-next-line no-shadow
                (globalIntegrationTypeDimensions || []).forEach(
                  ({ values }) => {
                    const valueToIterateGlobal = _.isArray(values)
                      ? values
                      : [];

                    (valueToIterateGlobal || []).forEach(({ date }) => {
                      const d = moment(date);
                      if (d.isBefore(oldest)) {
                        // console.log('integrationValues isBefore');
                        oldest = d;
                      }

                      if (d.isAfter(newest)) {
                        // console.log('integrationValues isAfter');
                        newest = d;
                      }
                    });
                  },
                );
              });
            }

            // metrics = [
            //   ...data.metrics,
            //   // ...fakeMetrics,
            // ];
          }

          // console.log('metrics', metrics);

          return (
            <Comp
              // data={data}
              {...props}
              metrics={metrics}
              metricsLoading={loading}
              metricsError={error}
              dateRange={{
                start: oldest,
                end: newest,
              }}
            />
          );
        }}
      </Query>
    );

withGQLEntityMetrics.displayName = 'withGQLEntityMetrics';

export default withGQLEntityMetrics;
