import React from 'react';
import styled from 'styled-components';
import { getName } from '../../../../../helpers/entity';
import TextWithoutIcon from './TextWithoutIcon';
import TextWithIcon from './TextWithIcon';
import IntegrationIconContainer from '../../../../integration/components/containers/IntegrationIconContainer';
import EntityIcon from '../../../../entity/components/composites/EntityIcon';
import { encodeFilter } from '../../../../search/helper';
import IntegrationNameContainer from '../../../../integration/components/containers/IntegrationNameContainer';

const makeFilteredSearchUrl = (entityIds = [], entityTypes = []) => {
  const entityTypeFilter = entityTypes.map((entityType) => ({
    type: 'entityType',
    value: entityType,
    displayName: entityType.replace('/', ''),
  }));

  const contextIdsFilter = entityIds.map(({ id, name }) => ({
    type: '/Person',
    value: id,
    displayName: name || `${id.slice(0, 8)}...`,
  }));

  const filtersString = encodeFilter([
    ...entityTypeFilter,
    ...contextIdsFilter,
  ]);

  return `/search/?q=*&${filtersString}`;
};

// const getFullAggregationLink = location.getFullAggregationLink;
// const getPartialAggregationLink = location.getPartialAggregationLink;

const SearchMainFilterItemLi = styled.li`
  list-style: none;
  border-bottom: 1px solid #e1e8ed;
  color: #545454;
  background-color: #fff;
  &.inactive {
    opacity: 0.4;
  }
  .searchMainFilterItemLink {
    padding: 5px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
    .remove {
      display: none;
    }
    .count {
      display: inline-block;
    }
  }
`;

const BadgeWrapper = styled.div`
  margin-left: auto;
`;

const SearchMainFilterItem = ({
  term,
  index,
  isActive,
  type,
  entityId,
  entityName,
  onSelectFilter,
}) => {
  let iconHtml;
  let entityTypeName = getName(term.term) || term.term;

  const linkKey = `${index}Link`;
  const isActiveClass = isActive ? 'active' : '';
  const isInactiveClass = term.count === 0 ? 'inactive' : '';

  if (type === 'entityType') {
    iconHtml = (
      <EntityIcon
        key="1"
        size="S"
        borderless
        style={{ fill: isActive ? 'white' : 'currentColor' }}
        entityType={term.term}
      />
    );
  }

  if (type === 'providers') {
    iconHtml = (
      <IntegrationIconContainer iconSize="s" integrationName={term.term} />
    );
    entityTypeName = <IntegrationNameContainer integrationName={term.term} />;
  }

  const fullAggregationLink = makeFilteredSearchUrl(
    [{ id: entityId, name: entityName }],
    [term.term],
  );

  let textContent;

  if (iconHtml) {
    textContent = <TextWithIcon>{entityTypeName}</TextWithIcon>;
  } else {
    textContent = <TextWithoutIcon>{entityTypeName}</TextWithoutIcon>;
  }

  const linkHtml = (
    <div
      // href={fullAggregationLink}
      key={linkKey}
      target="_blank"
      className="searchMainFilterItemLink"
      rel="noopener noreferrer"
      onClick={onSelectFilter}
    >
      {iconHtml}
      {textContent}
      <BadgeWrapper>
        <span>{term.count}</span>
      </BadgeWrapper>
    </div>
  );

  return (
    <SearchMainFilterItemLi className={`${isActiveClass} ${isInactiveClass}`}>
      {linkHtml}
    </SearchMainFilterItemLi>
  );
};

export default SearchMainFilterItem;
