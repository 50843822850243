import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { registerEntityWidget } from '../../registry';
import EntitySourceAccountItem from './EntitySourceAccountItem';

const EntitySourceAccountWrapper = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const SourceAccount = ({ entity }) => {
  const { EntityAccountData } = entity;

  if (!EntityAccountData) {
    return 'No source account has been found for this entity';
  }

  return (
    <EntitySourceAccountWrapper>
      {(EntityAccountData || []).map(
        ({ AccountInformation, ProviderId, ProviderName }, index) => {
          return (
            <EntitySourceAccountItem
              key={`${
                AccountInformation.AccountDisplay ||
                AccountInformation.AccountId ||
                '–'
              }-${index}`}
              AccountInformation={AccountInformation}
              ProviderId={ProviderId}
              ProviderName={ProviderName}
            />
          );
        },
      )}
    </EntitySourceAccountWrapper>
  );
};

registerEntityWidget('SourceAccount', {
  name: 'SourceAccount',
  displayName: <FormattedMessage id="module-entityWidget-accountSource" />,
  description: 'Display a list of accounts from where the data originate',
  view: SourceAccount,
  noHeader: false,
  relatedProviders: ['all'],
  tags: [],
  requireEntity: true,
});
