import React from 'react';
import { injectIntl } from '@cluedin/locale';
import { FormattedMessage } from '@cluedin/locale';
import { FormDecorator, TextField, Toggle } from '@cluedin/form/';
import DataBaseTechnologySelect from './database/DataBaseTechnologySelect';
import { getNumeric } from '../../../../core/validation';

const SqlConnectionStringForm = ({
  onChangeKey,
  value,
  errors = {},
  style = {},
  intl,
  dataBaseNameKey = 'databaseName',
}) => {
  return (
    <div style={style}>
      <FormDecorator
        label={<FormattedMessage id="data-source-sql-technology" />}
        helpText={<FormattedMessage id="data-source-sql-technology-help" />}
        errorText={errors.dialect}
        isRequired
        data-test={'FormDecorator'}
      >
        <DataBaseTechnologySelect
          value={value.dialect}
          onChange={(value) => {
            onChangeKey('dialect', value);
          }}
        />
      </FormDecorator>
      <FormDecorator
        label={<FormattedMessage id="data-source-host" />}
        helpText={<FormattedMessage id="data-source-host-help" />}
        errorText={errors.host}
        isRequired
      >
        <TextField
          data-test={'importDataHostName'}
          value={value.host}
          onChange={(e, v) => {
            onChangeKey('host', v);
          }}
          placeholder={intl.formatMessage({
            id: 'data-source-host-placeholder',
          })}
        />
      </FormDecorator>
      <FormDecorator
        label={<FormattedMessage id="data-source-database-name" />}
        helpText={<FormattedMessage id="data-source-database-name-help" />}
        errorText={errors.database}
        isRequired
      >
        <TextField
          data-test={'importDataBaseName'}
          value={value[dataBaseNameKey]}
          onChange={(e, v) => {
            onChangeKey('database', v);
          }}
          placeholder={intl.formatMessage({ id: 'data-source-database-name' })}
        />
      </FormDecorator>
      <FormDecorator
        label={<FormattedMessage id="data-source-username" />}
        errorText={errors.username}
        isRequired
      >
        <TextField
          data-test={'importDataUserName'}
          value={value.username}
          onChange={(e, v) => {
            onChangeKey('username', v);
          }}
          placeholder={intl.formatMessage({ id: 'data-source-username' })}
        />
      </FormDecorator>
      <FormDecorator label={<FormattedMessage id="data-source-password" />}>
        <TextField
          data-test={'importDataPassword'}
          value={value.password}
          type="password"
          onChange={(e, v) => {
            onChangeKey('password', v);
          }}
          placeholder={intl.formatMessage({ id: 'data-source-password' })}
        />
      </FormDecorator>
      <FormDecorator
        label={<FormattedMessage id="data-source-database-port-number" />}
        helpText={<FormattedMessage id="data-source-database-port-help" />}
      >
        <TextField
          data-test={'importDataPortNumber'}
          value={value.port || ''}
          type="port"
          onChange={(e, v) => {
            onChangeKey('port', getNumeric(v));
          }}
          placeholder={intl.formatMessage({
            id: 'data-source-database-port-number',
          })}
        />
      </FormDecorator>
      {value.dialect === 'mssql' && (
        <FormDecorator
          label={<FormattedMessage id="data-source-database-encryption" />}
          helpText={
            <FormattedMessage id="data-source-database-encryption-help" />
          }
        >
          <Toggle
            key="db-encryption"
            name="db-encryption"
            value={value.port || false}
            onChange={(e, v) => {
              onChangeKey('encrypt', !value.encrypt);
            }}
          />
        </FormDecorator>
      )}
    </div>
  );
};

export default injectIntl(SqlConnectionStringForm);
