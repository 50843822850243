import React from 'react';
import { connect } from 'react-redux';
import EntityChange from '../../composites/EntityChange';
import EntityChangeRaw from '../../composites/EntityChangeRaw';
import setupGetSchema from '../../../../../selectors/schema';

const EntityNewChangesContainer = ({ getComponentForSchema, insight }) => {
  let content;
  const changes = insight.Entity ? insight.Entity.Changes : insight.Changes;

  if (!changes || !changes.Changes) {
    content = (
      <div>
        Unfortunately, we have no information about the changes of this entity.
      </div>
    );
  } else {
    content = (
      <div>
        {changes.Changes.map((change, index) => {
          const schemaResult = getComponentForSchema(change.Key);

          if (schemaResult && schemaResult.propertyInSchema) {
            const indexKey = schemaResult.propertyInSchema.Key;
            const displayName = schemaResult.propertyInSchema.DisplayName;

            if (schemaResult.component) {
              return (
                <EntityChange
                  key={indexKey}
                  propertyKey={indexKey}
                  displayName={displayName}
                  component={schemaResult.component}
                  change={change}
                />
              );
            }
            return <EntityChangeRaw key={indexKey} change={change} />;
          }
          return <EntityChangeRaw key={index} change={change} />;
        })}
      </div>
    );
  }

  return <div>{content}</div>;
};

const mapStateToProps = ({ entity: { isFetchingSchema, schema } }) => ({
  isFetchingSchema,
  getComponentForSchema: schema ? setupGetSchema(schema) : () => {},
});

export default connect(mapStateToProps)(EntityNewChangesContainer);
