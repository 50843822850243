"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
// ${({theme}) => theme.
var _default = function _default(Tooltip) {
  return (0, _styledComponents.default)(Tooltip)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  .popper {\n    z-index: ", ";\n    pointer-events: none; // disable jss-rtl plugin\n  }\n  .popperInteractive {\n    pointer-events: auto;\n  }\n  &[x-placement='bottom'] .arrow {\n    top: 0;\n    left: 0;\n    margin-top: -0.71em;\n    margin-left: 4px;\n    margin-right: 4px;\n    &::before {\n      transform-origin: 0 100%;\n    }\n  }\n  &[x-placement='top'] .arrow {\n    bottom: 0;\n    left: 0;\n    margin-bottom: -0.71em;\n    margin-left: 4px;\n    margin-right: 4px;\n    &::before {\n      transform-origin: 100% 0;\n    }\n  }\n  &[x-placement='right'] .arrow {\n    left: 0;\n    margin-left: -0.71em;\n    height: 1em;\n    width: 0.71em;\n    margin-top: 4px;\n    margin-bottom: 4px;\n    &::before {\n      transform-origin: 100% 100%;\n    }\n  }\n  &[x-placement='left'] .arrow {\n    right: 0;\n    margin-right: -0.71em;\n    height: 1em;\n    width: 0.71em;\n    margin-top: 4px;\n    margin-bottom: 4px;\n    &::before {\n      transform-origin: 0 0;\n    }\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.zIndex.tooltip;
  });
};
exports.default = _default;