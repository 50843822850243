"use strict";

exports.__esModule = true;
exports.themeOverride = exports.getStyles = void 0;
var _colorUtils = require("./colorUtils");
var _excluded = ["isFocused", "isSelected", "theme"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var getStyles = function getStyles(options) {
  var noBorder = options == null ? void 0 : options.noBorder;
  return {
    option: function option(provided, _ref) {
      var _state$data, _state$data2, _state$data3, _state$data4;
      var isFocused = _ref.isFocused,
        isSelected = _ref.isSelected,
        colors = _ref.theme.colors,
        state = _objectWithoutPropertiesLoose(_ref, _excluded);
      return _extends({}, provided, {
        fontSize: 14,
        padding: !(state != null && state.isResults) && !(state != null && state.isLoadingOption) ? '8px' : '8px 8px 6px',
        cursor: 'pointer',
        color: colors.dark,
        background: (_state$data = state.data) != null && _state$data.isResults || state != null && state.isLoadingOption ? colors.lightest : isFocused || isSelected ? colors.light : colors.lightest,
        ':active': {
          background: (_state$data2 = state.data) != null && _state$data2.isResults || state != null && state.isLoadingOption ? colors.lightest : state.isSelected ? colors.light : colors.lightest
        },
        ':hover': {
          background: (((_state$data3 = state.data) == null ? void 0 : _state$data3.isResults) || (state == null ? void 0 : state.isLoadingOption)) && colors.lightest
        },
        ':focus': {
          background: (((_state$data4 = state.data) == null ? void 0 : _state$data4.isResults) || (state == null ? void 0 : state.isLoadingOption)) && colors.lightest
        }
      }, options == null ? void 0 : options.option);
    },
    control: function control(provided, _ref2) {
      var _getValue, _getValue$;
      var getValue = _ref2.getValue,
        isFocused = _ref2.isFocused,
        isDisabled = _ref2.isDisabled,
        _ref2$theme = _ref2.theme,
        colors = _ref2$theme.colors,
        shadows = _ref2$theme.shadows,
        _ref2$selectProps = _ref2.selectProps,
        hasError = _ref2$selectProps.hasError,
        readOnly = _ref2$selectProps.readOnly;
      var isValueSelected = (_getValue = getValue()) == null ? void 0 : (_getValue$ = _getValue[0]) == null ? void 0 : _getValue$.value;
      return _extends({}, provided, {
        fontSize: 14,
        cursor: 'pointer',
        borderRight: 'none',
        padding: '0 0 0 8px',
        borderWidth: !noBorder ? 1 : 0,
        borderRadius: !noBorder ? 2 : 0,
        pointerEvents: readOnly && 'none',
        overflow: 'hidden',
        color: isDisabled ? colors.grayish : colors.dark,
        boxShadow: (0, _colorUtils.getControlShadow)(shadows, hasError, isDisabled),
        backgroundColor: (0, _colorUtils.getControlBackground)(colors, isDisabled, noBorder, hasError),
        borderColor: (0, _colorUtils.getControlBorderColor)({
          colors: colors,
          hasError: hasError,
          isDisabled: isDisabled,
          isValueSelected: isValueSelected,
          isFocused: isFocused
        }),
        ':hover': {
          borderColor: (0, _colorUtils.getControlHoverBorderColor)(colors, hasError, isDisabled),
          '.select-IndicatorsContainer': {
            borderColor: (0, _colorUtils.getControlHoverBorderColor)(colors, hasError, isDisabled)
          }
        },
        input: {
          position: 'absolute',
          opacity: '1!important'
        }
      }, options == null ? void 0 : options.control);
    },
    menu: function menu(provided) {
      return _extends({}, provided, {
        marginTop: 0
      }, options == null ? void 0 : options.menu);
    },
    valueContainer: function valueContainer(provided) {
      return _extends({}, provided, {
        overflow: 'hidden',
        maxWidth: !options.hideArrow ? 'calc(100% - 40)' : 'calc(100% - 8)',
        div: {
          maxWidth: '100%',
          marginRight: (options == null ? void 0 : options.isMulti) && 4
        },
        marginRight: !options.hideArrow ? 40 : 8,
        '> div': !(options != null && options.isMulti) && {
          overflow: 'visible',
          maxWidth: '100%',
          width: '100%'
        }
      }, options == null ? void 0 : options.valueContainer);
    },
    placeholder: function placeholder(provided, _ref3) {
      var colors = _ref3.theme.colors,
        isDisabled = _ref3.isDisabled,
        hasError = _ref3.selectProps.hasError;
      return _extends({}, provided, {
        color: (0, _colorUtils.getPlaceholderColor)(colors, hasError, isDisabled, noBorder)
      }, options == null ? void 0 : options.placeholder);
    },
    input: function input(provided) {
      return _extends({}, provided, {
        maxWidth: '100%',
        position: 'relative',
        input: {
          maxWidth: '100%'
        },
        opacity: '1!important'
      }, options == null ? void 0 : options.input);
    }
  };
};
exports.getStyles = getStyles;
var themeOverride = function themeOverride(selectTheme, theme) {
  var colors = theme == null ? void 0 : theme.palette;
  var semanticColors = theme == null ? void 0 : theme.semanticColors;
  return _extends({}, selectTheme, {
    colors: {
      danger: colors.red,
      dark: colors.black,
      lightest: colors.white,
      primary: colors.themePrimary,
      light: colors.themeLighterAlt,
      grayish: semanticColors.inputBorder,
      grayLight: semanticColors.disabledBackground
    },
    shadows: {
      default: '0px 1px 3px #64CFBA1A'
    },
    spacing: _extends({}, selectTheme.spacing, {
      controlHeight: 32,
      baseUnit: 0
    })
  });
};
exports.themeOverride = themeOverride;