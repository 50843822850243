import React from 'react';
import { Spinner } from '@cluedin/form';
import { Flex } from 'uxi/Layout';

const PageLoader = ({ wrapperStyle }) => (
  <Flex style={{ minHeight: '200px', ...wrapperStyle }}>
    <Spinner />
  </Flex>
);

export default PageLoader;
