import { removeFirstCharacter } from '../modules/core/helpers/string';

const checkoutFollowingEntityFeature = 'https://www.cluedin.com/products';
const checkoutKeepInTheLoopFeature = 'https://www.cluedin.com/product';

export default (protocol, mainDomain, clientId) => {
  const goToApp = (url) => protocol + clientId + mainDomain + url;

  return {
    checkoutFollowingEntityFeature,
    checkoutKeepInTheLoopFeature,
    getFullAggregationLink(entityId, type) {
      return goToApp(`search/*?aggregation=${entityId}&type=${type}`);
    },
    getPartialAggregationLink(entityId, type) {
      return `/search/*?aggregation=${entityId}&type=${type}`;
    },
    redirectToUrl(url) {
      window.location = url;
    },
    goToClientIdSignIn(clientIdFromParams) {
      return `${protocol}${clientIdFromParams}${mainDomain}signin`;
    },
    goToForgotPassword(clientIdFromParams) {
      return `${protocol}${clientIdFromParams}${mainDomain}forgot`;
    },
    goToSignUp(clientIdFromParams) {
      return `${protocol}${clientIdFromParams}${mainDomain}signup`;
    },
    gotToAdminUserRoot() {
      return goToApp('admin/inbound/integration/applications');
    },
    goToConnectedProvider() {
      return goToApp('admin/inbound/configuration');
    },
    goToChrome(id) {
      return `${protocol}${id}${mainDomain}/chrome`;
    },
    goToRoot(id, specificPage) {
      let redirectUrl;

      if (specificPage) {
        redirectUrl = removeFirstCharacter(specificPage);
        return `${protocol}${id}${mainDomain}${redirectUrl}`;
      }

      return `${protocol}${id}${mainDomain}`;
    },
    gotToAppProvider(params) {
      return goToApp(`admin/integrations/new/${params[0]}/${params[1]}`);
    },
    goToSignInViaLogout() {
      return goToApp('logout');
    },
    goToSearch(searchTerm) {
      return goToApp(`search/${searchTerm}`);
    },
    goToApp,
    goToMainApp() {
      return `${protocol}app${mainDomain}`;
    },
    goToInviteUser(param) {
      let url = 'admin/integrations?open=true';

      if (param && param.email) {
        url += `&email=${param.email}`;
      }

      if (param && param.name) {
        url += `&name=${param.name}`;
      }

      return goToApp(url);
    },
    goToAppProvider(type) {
      let url = 'admin/inbound/integration/applications';
      if (type) {
        url += `?type=${type}`;
      }

      return goToApp(url);
    },
  };
};
