import { merge, toUpper, curry } from 'lodash';

const getValuesFromLocation = ({ search }, defaultfiltersQuery = []) => {
  const query = new URLSearchParams(search);
  const result = {};

  defaultfiltersQuery.forEach((f) => {
    const value = query.get(f.queryString);
    if (value) {
      result[f.variableName] = JSON.parse(decodeURI(value));
    }
  });

  return result;
};

const setFiltersInUrl = curry((history, filters) => {
  const { pathname, search, hash, state } = history.location;

  const query = new URLSearchParams(search);

  Object.keys(filters || {}).forEach((key) => {
    if (filters[key]) {
      query.set(toUpper(key), encodeURI(JSON.stringify(filters[key])));
    } else {
      query.delete(toUpper(key));
    }
  });

  const newLocation = {
    pathname,
    hash,
    search: `?${query.toString()}`,
    state,
  };

  if (search !== newLocation.search) {
    history.push(newLocation);
  }
});

const createQueryStringForFilter = (filters = {}) => {
  return Object.keys(filters || {}).map((f) => {
    return {
      queryString: toUpper(f),
      variableName: f,
    };
  });
};

export const useEntityFilters = (history, location, defaultFilters = {}) => {
  const queryStringForEachFilters = createQueryStringForFilter(defaultFilters);

  const filters = getValuesFromLocation(location, queryStringForEachFilters);

  const filtersWithDefault = merge(defaultFilters, filters);

  const setFilters = setFiltersInUrl(history);

  return [filtersWithDefault, setFilters];
};
