import styled from 'styled-components';

export const EdgePropertiesPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
`;

export const EdgePropertiesPanelEdgeGroupEntryWrapper = styled.div`
  margin-bottom: 10px;
  margin-right: 28px;
`;

export const EdgePropertiesPanelEdgeLoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;
