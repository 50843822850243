import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import Flex from 'uxi/Layout/Flex';
import { HighlightText } from '@cluedin/atoms';
import { actionsConfiguration } from '@cluedin/components';
import { PrimaryButton, Button } from '@cluedin/form';
import Tooltip from 'rc-tooltip';

import IntegrationListRowStyle from './IntegrationListRowStyle';
import IntegrationIcon from '../IntegrationIcon';

const { integrations } = actionsConfiguration;

const IntegrationListRow = ({
  integration,
  onInviteClick,
  onAddClick,
  withInviteButton,
  query,
}) => {
  const hasAuthConfig = !!(
    integration &&
    integration.AuthMethods &&
    Object.keys(integration.AuthMethods).length > 0
  );

  const addButton = hasAuthConfig ? (
    <div
      className="test_Integration_AddConfiguration"
      style={{ marginLeft: '8px' }}
    >
      <PrimaryButton
        isFullWidth
        style={IntegrationListRowStyle.button}
        onClick={() => onAddClick && onAddClick(integration)}
        text={<FormattedMessage id="module-integration-addConfiguration" />}
        actionConfiguration={
          integrations.configuredIntegrations.configuredIntegration.create
        }
      />
    </div>
  ) : (
    <Tooltip
      placement="top"
      trigger="hover"
      overlay={
        <div style={{ width: '150px', padding: '16px' }}>
          <FormattedMessage
            id="module-integration-addingManualIntegrationRequired"
            defaultMessage="Currently required manual setup. Contact our support if you want an automated configuration."
          />
        </div>
      }
    >
      <div
        className="test_Integration_AddConfiguration"
        style={{ display: 'inline-block' }}
      >
        <PrimaryButton
          style={IntegrationListRowStyle.button}
          disabled={!hasAuthConfig}
          onClick={() => onAddClick && onAddClick(integration)}
          text={<FormattedMessage id="module-integration-addConfiguration" />}
          actionConfiguration={
            integrations.configuredIntegrations.configuredIntegration.create
          }
        />
      </div>
    </Tooltip>
  );

  const inviteButton = hasAuthConfig ? (
    withInviteButton && (
      <div className="test_Integration_Invite">
        <Button
          isFullWidth
          style={IntegrationListRowStyle.button}
          onClick={() => onInviteClick && onInviteClick(integration)}
          text={<FormattedMessage id="module-integration-invite" />}
          actionConfiguration={
            integrations.configuredIntegrations.configuredIntegration.create
          }
        />
      </div>
    )
  ) : (
    <Tooltip
      placement="top"
      trigger="hover"
      overlay={
        <div style={{ width: '150px', padding: '16px' }}>
          <FormattedMessage
            id="module-integration-addingManualIntegrationRequired"
            defaultMessage="Currently required manual setup. Contact our support if you want an automated configuration."
          />
        </div>
      }
    >
      <div
        className="test_Integration_Invite"
        style={{ display: 'inline-block' }}
      >
        <Button
          isFullWidth
          style={IntegrationListRowStyle.button}
          disabled={!hasAuthConfig}
          onClick={() => onInviteClick?.(integration)}
          text={<FormattedMessage id="module-integration-invite" />}
          actionConfiguration={
            integrations.configuredIntegrations.configuredIntegration.create
          }
        />
      </div>
    </Tooltip>
  );
  const integrationName = query ? (
    <HighlightText text={integration.Name} highlight={query} />
  ) : (
    <span>{integration.Name}</span>
  );

  return (
    <div
      style={IntegrationListRowStyle.root}
      className={`__test_integration __test_integration_${integration.Name.toLowerCase().replace(
        /\s|\./g,
        '',
      )}`}
    >
      <div style={IntegrationListRowStyle.iconWrapper}>
        <IntegrationIcon size="l" integration={integration} />
      </div>
      <div style={IntegrationListRowStyle.descriptionContainer}>
        <div style={IntegrationListRowStyle.descriptionContainerContent}>
          <h4 style={IntegrationListRowStyle.title}>{integrationName}</h4>
          <div style={IntegrationListRowStyle.details}>{integration.About}</div>
        </div>
        <Flex style={{ padding: '8px 0' }}>
          {inviteButton}
          {addButton}
        </Flex>
      </div>
    </div>
  );
};

export default IntegrationListRow;
