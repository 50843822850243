import React, { useMemo } from 'react';
import { withWorkflow } from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import AddUserInvitationForm from '../../../userInvitationModule/components/containers/AddUserInvitationForm';

export const withAddUser = (Comp) => (props) => {
  const EnhancedhWorkflow = withWorkflow(
    ({ openWorkflow }) =>
      useMemo(() => <Comp {...props} openAddUser={openWorkflow} />, [props]),
    (panelProps) => <AddUserInvitationForm {...panelProps} />,
    {
      title: <FormattedMessage id="InviteUserDialog.AddTeamMember" />,
      panelType: 'medium',
    },
  );

  return <EnhancedhWorkflow />;
};
