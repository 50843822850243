import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '@cluedin/locale';
import { Flex } from 'uxi/Layout';
import { Merge, Tabularview, Tileview } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';
import { PrimaryButton, Button } from '@cluedin/form';
import { InputGroup } from 'uxi/List';

import { withEntityMergeAction } from '../../../../../../entityMerge/components/Hocs/withEntityMergeAction';
import KeepInTheLoopButtonContainer from '../../../../../../stream/components/containers/buttons/KeepInTheLoopButtonContainer';
import { withViewMode } from '../../../../../../entityLists/components/hocs/withViewMode';
import { default as PrepareGqlQueryDialog } from '../../../../../../prepare/components/containers/dialogs/CleanGqlQueryDialog';
import CleanGqlQueryDialog from '../../../../../../clean/components/containers/dialogs/CleanGqlQueryDialog';

const SearchHeaderWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  background: #fff;
`;
const SearchOption = ({
  q,
  startMerge,
  abortMerge,
  isMerging,
  changeViewMode,
  viewOptions,
  viewMode,
  gqlOptions = {},
  searchStat,
}) => {
  const TileBtn = !viewMode || viewMode === 'tile' ? PrimaryButton : Button;
  const TableBtn = viewMode === 'grid' ? PrimaryButton : Button;

  return (
    <SearchHeaderWrapper>
      <Flex>
        <InputGroup>
          <TileBtn
            startIcon={<Tileview size={14} />}
            text={<FormattedMessage id="module-search-tile" />}
            onClick={() => {
              changeViewMode('tile');
            }}
          />

          <TableBtn
            startIcon={<Tabularview size={14} />}
            text={<FormattedMessage id="module-search-tabular" />}
            disabled={isMerging}
            onClick={() => {
              changeViewMode('grid');
            }}
            type={viewMode === 'grid' ? 'primary' : null}
          />
        </InputGroup>
        <ButtonLink
          onClick={() => {
            viewOptions();
          }}
          style={{ marginLeft: '14px' }}
          text={
            viewMode === 'grid' ? (
              <FormattedMessage id="module-search-customizeTabularView" />
            ) : (
              <FormattedMessage id="module-search-customizeTileView" />
            )
          }
        />
      </Flex>
      <Flex
        style={{
          marginLeft: 'auto',
          flexFlow: 'row wrap',
          justifyContent: 'flex-end',
        }}
      >
        <CleanGqlQueryDialog gqlOptions={gqlOptions} searchStat={searchStat} />
        <PrepareGqlQueryDialog
          gqlOptions={gqlOptions}
          searchStat={searchStat}
        />
        <Button
          type={isMerging ? 'danger' : undefined}
          style={{ margin: '4px' }}
          startIcon={<Merge size={16} />}
          text={
            isMerging ? (
              <FormattedMessage
                defaultMessage="Cancel Selection"
                id="search-module-Merging"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Select for merge"
                id="search-module-startMerge"
              />
            )
          }
          onClick={isMerging ? abortMerge : startMerge}
        />
        <div className="test_SearchKeepInTheLoopBtn">
          <KeepInTheLoopButtonContainer style={{ margin: '4px' }} q={q} />
        </div>
      </Flex>
    </SearchHeaderWrapper>
  );
};

export default withEntityMergeAction(withViewMode(SearchOption));
