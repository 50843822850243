import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import { Widget } from 'uxi/Widget';
import { Arrowleft } from 'uxi/Icons';
import { ButtonLink } from '@cluedin/atoms';
import { DivPadding } from 'uxi/Base';

import EntityMainBarStyle from '../../../entity/components/composites/structure/EntityMainBar.style';

import { PageStructureHeader } from '../../../core/components/composites/PageStructure';

const iconStyle = {
  verticalAlign: 'middle',
  paddingRight: '5px',
  width: '14px',
};

const MessageContainer = ({ children }) => (
  <span style={{ paddingLeft: '8px', borderLeft: '1px solid #ccc' }}>
    {children}
  </span>
);

const EntityMergeWrapper = ({
  isFetching,
  isFetchingSchema,
  entities,
  isSelectionValid,
  conflictedProperties,
  goBack,
  children,
}) => {
  const backIcon = (
    <li
      key="back"
      style={{
        ...EntityMainBarStyle.mainBarListItem,
        marginRight: '8px',
        marginLeft: '-16px',
      }}
    >
      <ButtonLink
        key="backLink"
        onClick={goBack}
        style={{
          ...EntityMainBarStyle.mainBarListItemLink,
          marginLeft: '-30px',
          padding: '0 15px',
        }}
        text={
          <div>
            <Arrowleft style={iconStyle} />
            <FormattedMessage id="back" />
          </div>
        }
      />
    </li>
  );

  // eslint-disable-next-line no-nested-ternary
  const widgetHeader =
    isSelectionValid && !isFetchingSchema ? (
      conflictedProperties && conflictedProperties.length === 0 ? (
        <FormattedMessage
          id="readyToMerge"
          values={{
            number: conflictedProperties && conflictedProperties.length,
          }}
        />
      ) : (
        <FormattedMessage
          id="fixedConflictedProps"
          values={{
            number: conflictedProperties && conflictedProperties.length,
          }}
        />
      )
    ) : (
      <FormattedMessage
        id="autoMerging"
        values={{ number: conflictedProperties && conflictedProperties.length }}
      />
    );

  const pageTitle = isSelectionValid ? (
    <MessageContainer>
      {`Merging: ${entities[1].name} into ${entities[0].name}`}
    </MessageContainer>
  ) : (
    <MessageContainer>{'Merging: Loading...'}</MessageContainer>
  );

  return (
    <div className="cluedIn_container">
      <PageStructureHeader
        title={
          <ul style={EntityMainBarStyle.mainBarList}>
            {backIcon}
            {pageTitle}
          </ul>
        }
      />
      <DivPadding margin="S">
        <Widget
          style={{ background: '#fff' }}
          isLoading={isFetching || isFetchingSchema}
          title={widgetHeader}
        >
          {children}
        </Widget>
      </DivPadding>
    </div>
  );
};

export default EntityMergeWrapper;
