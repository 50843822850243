import { actionsConfiguration } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';
import { List, DataTreeView } from '@cluedin/list-pattern';
import { withWorkflow } from '@cluedin/form';

import { useQueryRolePerUser } from '../../../hooks/useQueryRolePerUser';
import { columns } from './columns';
import AssignRoleToUser from '../AssignRoleToUser';

const { admin } = actionsConfiguration;

const UserRolesListContainer = ({ userId, openWorkflow, userName }) => {
  const [roles, loading, error, refetch] = useQueryRolePerUser(userId);

  return (
    <List
      hideExpanding
      DataGrid={DataTreeView}
      selectable={false}
      data={roles}
      total={(roles || []).length}
      columns={columns}
      noSearch
      showPagination={false}
      loading={loading}
      error={error}
      creation={[
        {
          label: <FormattedMessage id="module-role-addRoleToUser" />,
          onClick: () => {
            openWorkflow({
              roles,
              refetchUserRoles: refetch,
              userName,
            });
          },
          actionConfiguration: admin.roles.role.create,
        },
      ]}
      offsetTop={220}
      notFoundProps={{
        buttonLabel: <FormattedMessage id="module-role-addRoleToUser" />,
        message: <FormattedMessage id="module-role-noRolesFound" />,
        explanation: (
          <FormattedMessage id="module-role-addRoleToUserExplanation" />
        ),
        onCreateClick: () =>
          openWorkflow({
            roles,
            refetchUserRoles: refetch,
            userName,
          }),
        actionConfiguration: admin.roles.role.create,
      }}
    />
  );
};

export default withWorkflow(UserRolesListContainer, AssignRoleToUser, {
  title: <FormattedMessage id="module-role-addRoleToUser" />,
  panelType: 'medium',
});
