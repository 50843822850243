"use strict";

exports.__esModule = true;
exports.getSubdomain = void 0;
var getSubdomain = function getSubdomain() {
  if (!window || !window.location || !window.location.hostname) {
    return 'foobar';
  }
  var parts = window.location.hostname.split('.');
  return parts.shift();
};
exports.getSubdomain = getSubdomain;