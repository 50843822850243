import { findProp } from '../../wms/helpers/entityPropertyHelper';
import {
  websitePropertiesPool,
  twitterPropertiesPool,
  linkedinPropertiesPool,
  facebookPropertiesPool,
  crunchbasePropertiesPool,
  googlePlusPropertiesPool,
  instagramPropertiesPool,
  githubPropertiesPool,
  angellistPropertiesPool,
  wikipediaPropertiesPool,
  skypePropertiesPool,
  youtubePropertiesPool,
} from '../../wms/helpers/entityPropertyConfig';

export const createSocialViewModel = (entity) => {
  const properties =
    entity.data && entity.data.properties
      ? entity.data.properties || {}
      : entity.properties || {};

  const websiteProp = findProp(properties, websitePropertiesPool);
  const twitterProp = findProp(properties, twitterPropertiesPool);
  const linkedInProp = findProp(properties, linkedinPropertiesPool);
  const facebookProp = findProp(properties, facebookPropertiesPool);
  const crunchbaseProp = findProp(properties, crunchbasePropertiesPool);
  const googlePlusProp = findProp(properties, googlePlusPropertiesPool);
  const instagramProp = findProp(properties, instagramPropertiesPool);
  const githubProp = findProp(properties, githubPropertiesPool);
  const angellistProp = findProp(properties, angellistPropertiesPool);
  const wikipediaProp = findProp(properties, wikipediaPropertiesPool);
  const skypeProp = findProp(properties, skypePropertiesPool);
  const youtubeProp = findProp(properties, youtubePropertiesPool);

  return {
    website: websiteProp,
    twitter: twitterProp,
    linkedIn: linkedInProp,
    facebook: facebookProp,
    crunchbase: crunchbaseProp,
    googlePlus: googlePlusProp,
    instagram: instagramProp,
    github: githubProp,
    angellist: angellistProp,
    wikipedia: wikipediaProp,
    skype: skypeProp,
    youtube: youtubeProp,
  };
};

export default {
  createSocialViewModel,
};
