export const nodeTypesList = [
  {
    name: 'DataPart',
    label: 'Record',
  },
  {
    name: 'EntityCode',
    label: 'Entity Code',
  },
  {
    name: 'DeduplicationProjectMerge',
    label: 'Merge From Deduplication Project',
  },
  {
    name: 'ManualMerge',
    label: 'Manual Merge',
  },
  {
    name: 'FuzzyMatch',
    label: 'Fuzzy Match',
  },
  {
    name: 'UserInput',
    label: 'Manually Added In CluedIn',
  },
  {
    name: 'Clean',
    label: 'Clean',
  },
  {
    name: 'LegacyClean',
    label: 'Clean (Legacy)',
  },
];
