"use strict";

exports.__esModule = true;
exports.default = debounce;
// Corresponds to 10 frames at 60 Hz.
// A few bytes payload overhead when lodash/debounce is ~3 kB and debounce ~300 B.
function debounce(func, wait) {
  if (wait === void 0) {
    wait = 166;
  }
  var timeout;
  function debounced() {
    var _this = this;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var later = function later() {
      func.apply(_this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }
  debounced.clear = function () {
    clearTimeout(timeout);
  };
  return debounced;
}