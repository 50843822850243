import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader } from '@cluedin/atoms';
import {
  CancelButton,
  PrimaryButton,
  PanelContent,
  PanelFooter,
} from '@cluedin/form';
import { FormattedMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';
import cogoToast from 'cogo-toast';
import EndpointCreationForm from '../forms/EndpointCreationForm';
import { useCreateDataSets } from '../../../hooks/useCreateDataSets';
import { withCurrentUser } from '../../../../user/components/Hocs/withCurrentUser';
import { getClassicValidationMessage } from '../../../../core/validation/messages';
import { useQueryAllAnnotations } from '../../../hooks/useQueryAllAnnotations';
import { withEntityTypeConfigurationReload } from '../../../../wms/components/Hocs/withEntityTypeConfigurationReload';

const EndpointCreationComponent = ({
  onClose,
  history,
  currentUser,
  dataSourceId,
  refetchDataSets,
  reloadEntityConfiguration,
}) => {
  const [allExistingAnnotations, isLoadingAllExistingAnnotations] =
    useQueryAllAnnotations();

  const [endPointName, onChangeEndpointName] = useState('');
  const [entityTypeObject, onChangeEntityType] = useState('');
  const [touched, setTouched] = useState(false);
  const [selectedDataSet, setDataSet] = useState();
  const [mappingMode, setMappingMode] = useState('new');

  const validEntityType = mappingMode === 'new' && entityTypeObject;

  const validDataSet =
    mappingMode === 'existing' &&
    selectedDataSet &&
    selectedDataSet.id &&
    selectedDataSet.annotationId;

  let validMapping = false;
  if (mappingMode === 'new') {
    validMapping = validEntityType;
  }

  if (mappingMode === 'existing') {
    validMapping = validDataSet;
  }

  const [
    createDataSets,
    { data: createDataSetsData, loading: isSaving, error: createDataSetsError },
  ] = useCreateDataSets();

  const errorEndPointName =
    touched && getClassicValidationMessage(endPointName, 255);

  const disabledSubmit = !validMapping || errorEndPointName || isSaving;

  useEffect(() => {
    if (createDataSetsData && createDataSetsData.length > 0) {
      cogoToast.success(`Endpoint added successfully`, {
        position: 'bottom-right',
        hideAfter: 5,
      });

      if (reloadEntityConfiguration) {
        reloadEntityConfiguration();
      }

      if (!refetchDataSets) {
        return history.push(
          `/admin/inbound/datasourceset/datasource/${dataSourceId}`,
        );
      }

      refetchDataSets?.();
      onClose();
    }
  }, [createDataSetsData, refetchDataSets, onClose]);

  const entityTypeValidationError = useMemo(() => {
    if (entityTypeObject?.entityType?.charAt(0) === '/') {
      return undefined;
    }

    return (
      <FormattedMessage id="module-entityType-entityType-must-start-from-slash" />
    );
  }, [entityTypeObject]);

  return (
    <>
      <PanelContent>
        {createDataSetsError && (
          <Alert type="error">{JSON.stringify(createDataSetsError)}</Alert>
        )}
        <EndpointCreationForm
          entityTypeValidationError={entityTypeValidationError}
          endPointName={endPointName}
          errorEndpointName={errorEndPointName}
          onChangeEndpointName={(v) => {
            setTouched(true);
            onChangeEndpointName(v);
          }}
          entityType={entityTypeObject}
          onChangeEntityType={(v) => {
            setTouched(true);
            onChangeEntityType(v);
          }}
          allExistingAnnotations={allExistingAnnotations}
          isLoadingAllExistingAnnotations={isLoadingAllExistingAnnotations}
          selectedDataSet={selectedDataSet}
          setDataSet={setDataSet}
          mappingMode={mappingMode}
          setMappingMode={setMappingMode}
        />
      </PanelContent>

      <PanelFooter>
        <CancelButton rounded onClick={onClose}>
          <FormattedMessage id="data-source-cancel" />
        </CancelButton>

        <PrimaryButton
          rounded
          style={{ marginLeft: '8px' }}
          disabled={disabledSubmit}
          startIcon={isSaving ? <Loader color="#fff" size={14} /> : null}
          onClick={() => {
            const dataSet = {
              name: endPointName,
              store: true,
              type: 'endpoint',
              author: currentUser?.client?.Id ?? '',
              configuration: {
                entityTypeConfiguration: {
                  ...entityTypeObject,
                },
                object: {
                  endPointName,
                  entityType: entityTypeObject.entityType,
                  autoSubmit: false,
                },
              },
            };

            createDataSets({
              dataSourceId: dataSourceId,
              dataSets: [dataSet],
              existingAnnotationId: selectedDataSet
                ? selectedDataSet.annotationId
                : '',
              existingDataSetId: selectedDataSet ? selectedDataSet.id : '',
            });
          }}
        >
          <FormattedMessage id="data-source-add" />
        </PrimaryButton>
      </PanelFooter>
    </>
  );
};

export const EndpointCreation = withCurrentUser(
  withRouter(withEntityTypeConfigurationReload(EndpointCreationComponent)),
);
