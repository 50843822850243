import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from '@cluedin/locale';
import {
  GqlErrorMessages,
  FormGenerator,
  FormSubmitAndCancel,
  useFormGeneratorWithReactHook,
  PanelContent,
  PanelFooter,
} from '@cluedin/form';

import { useCreateRole } from '../../../hooks/useCreateRole';
import { useQueryLazyRole } from '../../../hooks/useQueryRole';

import {
  blankValidation,
  specialCharValidation,
} from '../../../../../helpers/validation';
import { NotLongErrorMessage } from '../../../../core/validation/messages';
import RoleNameTextFields from './RoleNameTextFields';
import { useDebouncedEffect } from '../../../../core/hooks';

const formFields = [
  {
    displayName: <FormattedMessage id="module-role-name" />,
    help: <FormattedMessage id="module-role-nameHelp" />,
    name: 'name',
    isRequired: true,
    Input: RoleNameTextFields,
    rules: {
      rule: (value) => {
        if (!blankValidation(value)) {
          return (
            <FormattedMessage id="module-dataCatalog-field-name-error-blank" />
          );
        }

        if (specialCharValidation(value)) {
          return (
            <FormattedMessage id="module-dataCatalog-field-name-error-specialChar" />
          );
        }

        if (value?.length > 256) {
          return <NotLongErrorMessage signsCount={256} />;
        }
      },
    },
  },
  {
    displayName: <FormattedMessage id="module-role-description" />,
    help: <FormattedMessage id="module-role-descriptionHelp" />,
    name: 'description',
    isRequired: false,
    type: 'input',
  },
];

const RoleCreationForm = ({ onClose, history }) => {
  const [createRole, data, loading, error] = useCreateRole();
  const [
    getRoleByName,
    { data: alreadyExistRole, loading: roleByNameLoading },
  ] = useQueryLazyRole();

  const name = get(data, 'administration.createRole.name');

  useEffect(() => {
    if (name) {
      history.push(`/admin/settings/roles/detail/${name}`);
      onClose();
    }
  }, [name, onClose, history]);

  const { fields, control, handleSubmit, errors, touched, isDirty, watch } =
    useFormGeneratorWithReactHook(formFields);

  const roleName = watch('name');

  useDebouncedEffect(
    () => {
      if (roleName && !errors.name) {
        getRoleByName(roleName);
      }
    },
    200,
    [roleName, errors],
  );

  const handleCreate = (role) => {
    createRole({
      name: role?.name?.trim(),
      description: role?.description?.trim(),
    });
  };

  return (
    <>
      <PanelContent>
        {error && <GqlErrorMessages error={error} />}
        <FormGenerator
          fields={fields}
          control={control}
          errors={errors}
          touched={touched}
          extraProps={{
            isLoading: roleByNameLoading,
            alreadyExisting: !!alreadyExistRole,
          }}
        />
      </PanelContent>

      <PanelFooter>
        <FormSubmitAndCancel
          loading={loading}
          isTouchedAndNotSaved={isDirty}
          submitProps={{
            label: <FormattedMessage id="module-role-create" />,
            onClick: handleSubmit(handleCreate),
            disabled: !isEmpty(errors) || !!alreadyExistRole,
          }}
          onCancel={onClose}
        />
      </PanelFooter>
    </>
  );
};

export default withRouter(RoleCreationForm);
