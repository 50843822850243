import React from 'react';
import EntityTypeAvatar from '../../../../entityTypes/components/containers/EntityTypeAvatar';

const EntityType = (entity) => {
  const entityType = entity.entityType || entity.grouping;

  return (
    <>
      <EntityTypeAvatar entityType={entityType} />
    </>
  );
};

export default EntityType;
