import React, { Component } from 'react';
import PropTypes from 'prop-types';

const callbacks = [];

const addScript = (src, callback) => {
  if (callbacks.length === 0) {
    callbacks.push(callback);
    const s = document.createElement('script');
    s.setAttribute('src', src);
    s.onload = () => callbacks.forEach((cb) => cb());
    document.body.appendChild(s);
  } else {
    callbacks.push(callback);
  }
};

class TweetEmbed extends Component {
  componentDidMount() {
    const renderTweet = () => {
      window.twttr.ready().then(({ widgets }) => {
        const { options, onTweetLoadSuccess, onTweetLoadError } = this.props;
        widgets
          .createTweetEmbed(this.props.id, this.currentDiv, options)
          .then(onTweetLoadSuccess)
          .catch(onTweetLoadError);
      });
    };

    if (!window.twttr) {
      const isLocal = window.location.protocol.indexOf('file') >= 0;
      const protocol = isLocal ? this.props.protocol : '';

      addScript(`${protocol}//platform.twitter.com/widgets.js`, renderTweet);
    } else {
      renderTweet();
    }
  }

  render() {
    return (
      <div
        style={{
          width: '500px',
          margin: '0 auto',
          padding: '30px',
        }}
        className={this.props.className}
        ref={(c) => {
          this.currentDiv = c;
        }}
      />
    );
  }
}

TweetEmbed.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
  onTweetLoadSuccess: PropTypes.func,
  onTweetLoadError: PropTypes.func,
  protocol: PropTypes.string,
};
TweetEmbed.defaultProps = {
  protocol: 'https:',
  options: {},
  className: null,
  onTweetLoadSuccess: () => {},
  onTweetLoadError: () => {},
};

export default TweetEmbed;
