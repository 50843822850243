import styled from 'styled-components';

export const EntityPendingChangesMessagesWrapper = styled.div`
  background: #fff;
`;

export const EntityPendingChangesHeader = styled.div`
  align-items: center;
  background: #ffffff;
  display: flex;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  justify-content: space-between;
  padding: 14px 30px;
`;

export const EntityPendingChangesHeaderText = styled.div`
  align-items: center;
  color: rgb(55, 55, 58);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  height: 100%;
`;
