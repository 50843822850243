import { DataGridv2 } from '@cluedin/list-pattern';
import { FormattedMessage } from '@cluedin/locale';
import { capitalize } from 'lodash';
import Alert from 'uxi/Alert/Alert';
import { allEntityProperties } from './columnConfiguration';

const EntityPropertyName = ({ property, description, displayName }) => {
  return (
    <div style={{ padding: '6px' }}>
      <div style={{ color: '#000' }}>
        {displayName ? displayName : capitalize(property)}
      </div>
      <div style={{ fontSize: '12px', color: '#ccc' }}>{description}</div>
    </div>
  );
};

const AddEntityProperty = ({
  onEntityPropertyChange,
  selectedEntityProperty,
  currentNumberColumns,
  maxNumberColumns,
  entityKeys,
}) => {
  const keys =
    entityKeys && entityKeys.length > 0 ? entityKeys : allEntityProperties;

  const data = keys.filter((p) => !p.fixed);
  const maxedOut = currentNumberColumns > maxNumberColumns;

  return (
    <>
      {maxedOut && (
        <Alert type="warning">
          <div>
            <FormattedMessage
              id="module-dataCatalog-cannotSelectMore"
              values={{
                maxNumberColumns,
              }}
            />
          </div>
        </Alert>
      )}
      <DataGridv2
        dataGridName="entity-property-list"
        selectable
        multiSelectable
        data={data}
        selected={selectedEntityProperty}
        onSelectionChange={(isSelected, entityId, selected) => {
          if (!maxedOut || !isSelected) {
            onEntityPropertyChange(selected);
          }
        }}
        onInvertSelection={() => {
          onEntityPropertyChange([]);
        }}
        onSelectAllChange={(selectAllChecked) => {
          if (!selectAllChecked) {
            return onEntityPropertyChange([]);
          }

          const selected = data.map((d) => d.property);

          onEntityPropertyChange(selected);
        }}
        propertyKey="property"
        model={[
          {
            displayName: (
              <FormattedMessage
                id="module-dataCatalog-propertyName"
                values={{
                  maxNumberColumns,
                }}
              />
            ),
            property: 'property',
            Component: EntityPropertyName,
          },
        ]}
      />
    </>
  );
};

export default AddEntityProperty;
