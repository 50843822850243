import React from 'react';
import PropTypes from 'prop-types';
import { EntityHistory } from '@cluedin/components';
import { FormattedMessage } from '@cluedin/locale';

import UserAvatarFromId from '../../../userModule/components/container/UserAvatarFromId';
import { cleanProjectRootURL } from '../../../cleanProject/module';
import { manualDataEntryRootUrl } from '../../../manualDataEntry/module';
import * as dedupModule from '../../../deduplication/module';
import { useQueryEntityHistoryChangeSetsById } from '../../hooks/useEntityHistoryChangeSets';
import { useQueryEntityHistoryChangeSetById } from '../../hooks/useEntityHistoryChangeSet';
import { EntityHistoryWrapper } from './EntityHistory.styles';

const EntityHistoryContainer = ({ entityId, removeMargin }) => {
  return (
    <EntityHistoryWrapper removeMargin={removeMargin}>
      <EntityHistory
        entityId={entityId}
        useQueryEntityHistoryChangeSetsById={
          useQueryEntityHistoryChangeSetsById
        }
        useQueryEntityHistoryChangeSetById={useQueryEntityHistoryChangeSetById}
        labels={{
          recordSingular: <FormattedMessage id="module-entity-record" />,
          recordPlural: <FormattedMessage id="module-entity-records" />,
          by: <FormattedMessage id="module-entity-versionHistoryByUser" />,
          propertyHeading: (
            <FormattedMessage id="module-entity-propertyHeading" />
          ),
          changeTypeHeading: (
            <FormattedMessage id="module-entity-changeTypeHeading" />
          ),
          oldValueHeading: (
            <FormattedMessage id="module-entity-oldValueHeading" />
          ),
          newValueHeading: (
            <FormattedMessage id="module-entity-newValueHeading" />
          ),
          allVersions: (
            <FormattedMessage id="module-entity-allVersionsHistory" />
          ),
          noHistoryFound: <FormattedMessage id="module-entity-noHistory" />,
          source: <FormattedMessage id="module-entity-source-label" />,
          cleanTitle: (
            <FormattedMessage id="module-entity-source-clean-title" />
          ),
          sourceDeduplicationProjectTitle: (
            <FormattedMessage id="module-entity-source-dedupProject-title" />
          ),
          sourceManualMergeTitle: (
            <FormattedMessage id="module-entity-source-manualMerge-title" />
          ),
          cluedInManualEntryTitle: (
            <FormattedMessage id="module-entity-source-manualEntry-title" />
          ),
        }}
        cleanRootUrl={cleanProjectRootURL}
        dedupRootUrl={`${dedupModule.rootURL}/detail`}
        manualDataEntryRootUrl={manualDataEntryRootUrl}
        userAvatar={UserAvatarFromId}
      />
    </EntityHistoryWrapper>
  );
};

export default EntityHistoryContainer;

EntityHistoryContainer.propTypes = {
  entityId: PropTypes.string,
  removeMargin: PropTypes.bool,
};

EntityHistoryContainer.defaultProps = {
  entityId: null,
  removeMargin: null,
};
