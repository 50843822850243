import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';

const LogoWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${({ noPadding }) => (noPadding ? '0' : '4px')};
  & svg * {
    fill: ${({ color }) => color} !important;
  }
`;

const LocalIcon = ({ Icon, noPadding, color, size = 14 }) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <LogoWrapper noPadding={noPadding} color={color ? color : themePrimary}>
      <Icon style={{ height: `${size}px`, width: `${size}px` }} />
    </LogoWrapper>
  );
};

export default LocalIcon;
