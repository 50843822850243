import { makeLazy } from '../core/components/Hocs/LazyRoute';

export default [
  {
    path: '/',
    exact: true,
    component: makeLazy(
      () =>
        import(
          /* webpackChunkName:"CleanProjectList" */
          './pages/CleanList'
        ),
    ),
  },
  {
    path: '/:id',
    component: makeLazy(
      () =>
        import(
          /* webpackChunkName:"CleanProjectDetail" */
          './pages/CleanDetail'
        ),
    ),
  },
];
