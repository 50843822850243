"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("@cluedin/theme");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var fade = _theme.colorManipulator.fade;
var _default = function _default(Checkbox) {
  return (0, _styledComponents.default)(Checkbox)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  color: ", ";\n  &.checked {\n    &.primary {\n      color: ", ";\n      &:hover {\n        background-color ", " ;\n        @media (hover: none) {\n          background-color: transparent;\n        }\n      }\n    }\n    &.accent {\n      color: ", ";\n      &:hover {\n        background-color ", " ;\n        @media (hover: none) {\n          background-color: transparent;\n        }\n      }\n    }\n  }\n  &.disabled {\n    cursor: default;\n    color: ", "\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.colors.text.secondary;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.primary.main;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return fade(theme.colors.primary.main, theme.colors.action.hoverOpacity);
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.accent.main;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return fade(theme.colors.accent.main, theme.colors.action.hoverOpacity);
  }, function (_ref6) {
    var theme = _ref6.theme;
    return theme.colors.action.disabled;
  });
};
exports.default = _default;