import React from 'react';
import { Widget } from 'uxi/Widget';
import ConfigurationOverviewContainer from '../containers/ConfigurationOverviewContainer';

const ConfigurationOverviewPage = ({ integrationId, configurationId }) => (
  <Widget style={{ background: '#fff', overflow: 'hidden' }}>
    <ConfigurationOverviewContainer
      configurationId={integrationId}
      integrationId={configurationId}
    />
  </Widget>
);

export default ConfigurationOverviewPage;
