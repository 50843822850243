import { connect } from 'react-redux';
import {
  showQuickViewEntityAction,
  hideQuickViewEntityAction,
} from '../../actions';

export const withEntityQuickview = (Comp) => {
  const mapStateToProps = ({ entityModule: { quickViewOptions } }) => ({
    entityId: quickViewOptions ? quickViewOptions.entityId : '',
    entityType: quickViewOptions ? quickViewOptions.entityType : '',
  });

  const mapDispatchToProps = (dispatch) => ({
    showEntityQuickView({ entityId, entityType }) {
      dispatch(showQuickViewEntityAction({ entityId, entityType }));
    },
    closeEntityQuickView() {
      dispatch(hideQuickViewEntityAction());
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Comp);
};

export default withEntityQuickview;
