import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { actionsConfiguration, Action } from '@cluedin/components';
import { Flex } from 'uxi/Layout';
import { Listul, Add } from 'uxi/Icons';
import Widget from 'uxi/Widget';
import { FormattedMessage } from '@cluedin/locale';

const widgetStyles = {
  minWidth: '200px',
  maxWidth: '200px',
  background: 'white',
  margin: '0 16px 16px 0',
};

const { governance } = actionsConfiguration;

const RetentionDashboardContainer = ({
  goToAll,
  goToCreateQueryRetention,
  goToCreateEntityRetention,
}) => (
  <div style={{ marginBottom: '15px', margin: '15px' }}>
    <Flex style={{ justifyContent: 'flex-start' }}>
      <Widget style={widgetStyles}>
        <Action
          menuDescriptor={{
            displayName: (
              <FormattedMessage
                id="module-dataRetention-allRetention"
                defaultMessage="All Retention Policies"
              />
            ),
            key: 'allDataBreach',
            hasNew: false,
            icon: <Listul />,
            onClick: () => {
              goToAll();
            },
            isPromoted: false,
          }}
          actionConfiguration={governance.dataRetention.policy.view}
        />
      </Widget>
      <Widget style={widgetStyles}>
        <Action
          menuDescriptor={{
            displayName: (
              <FormattedMessage
                id="module-dataRetention-createQueryRetention"
                defaultMessage="Create Retention Query"
              />
            ),
            key: 'newQueryRetention',
            hasNew: false,
            icon: <Add />,
            onClick: () => {
              goToCreateQueryRetention();
            },
            isPromoted: false,
          }}
          actionConfiguration={governance.dataRetention.policy.create}
        />
      </Widget>
      <Widget style={widgetStyles}>
        <Action
          menuDescriptor={{
            displayName: (
              <FormattedMessage
                id="module-dataRetention-createEntityRetention"
                defaultMessage="New Entity Retention"
              />
            ),
            key: 'newEntityRetention',
            hasNew: false,
            icon: <Add />,
            onClick: () => {
              goToCreateEntityRetention();
            },
            isPromoted: false,
          }}
          actionConfiguration={governance.dataRetention.policy.create}
        />
      </Widget>
    </Flex>
  </div>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  goToAll: () => {
    dispatch(push('/admin/governance/retention/all'));
  },
  goToCreateQueryRetention: () => {
    dispatch(push('/admin/governance/retention/create/query'));
  },
  goToCreateEntityRetention: () => {
    dispatch(push('/admin/governance/retention/create/entity'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetentionDashboardContainer);
