"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var TriggererWrapper = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  /* min-width: 180px; */\n  width: 100%;\n  min-height: 34px;\n  height: 34px;\n  display: block;\n  border-radius: ", ";\n  overflow: hidden;\n  position: relative;\n  /* background: white; */\n"])), function (_ref) {
  var radius = _ref.theme.radius;
  return radius;
});
var _default = TriggererWrapper;
exports.default = _default;