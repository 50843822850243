export const accent = '#15a9a9';

export default {
  accent,
  transition: {
    default: '450ms cubic-bezier(0.23, 1, 0.32, 1) ',
    defaultAll: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) ',
  },
  boxShadow:
    '0 0px 1px 2px rgba( 60, 60, 60, .09), 0 1px 4px rgba( 60, 60, 60, .18)',
};
