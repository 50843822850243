"use strict";

exports.__esModule = true;
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
//background-color: ${({ theme }) => theme.colors.background.card};
var _default = function _default(Drawer) {
  return (0, _styledComponents.default)(Drawer)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  .docked {\n    flex: 0 0 auto;\n  }\n  .paper {\n    overflow-y: auto;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    flex: 1 0 auto;\n    z-index: ", ";\n    -webkit-overflow-scrolling: touch; // Add iOS momentum scrolling.\n    // temporary style\n    position: fixed;\n    top: 0;\n    // We disable the focus ring for mouse, touch and keyboard users.\n    // At some point, it would be better to keep it for keyboard users.\n    // :focus-ring CSS pseudo-class will help.\n    outline: 0;\n  }\n  .paperAnchorLeft {\n    left: 0;\n    right: auto;\n  }\n  .paperAnchorRight {\n    left: auto;\n    right: 0;\n  }\n  .paperAnchorTop {\n    top: 0;\n    left: 0;\n    bottom: auto;\n    right: 0;\n    height: auto;\n    max-height: 100%;\n  }\n  ,\n  .paperAnchorBottom {\n    top: auto;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    height: auto;\n    max-height: 100%;\n  }\n  ,\n  .paperAnchorDockedLeft {\n    border-right: 1px solid ", ";\n  }\n  .paperAnchorDockedTop {\n    border-bottom: 1px solid ", ";\n  }\n  .paperAnchorDockedRight {\n    border-left: 1px solid ", ";\n  }\n  .paperAnchorDockedBottom {\n    border-top: 1px solid ", ";\n  }\n"])), function (_ref) {
    var theme = _ref.theme;
    return theme.zIndex.drawer;
  }, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.divider;
  }, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.divider;
  }, function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.divider;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.colors.divider;
  });
};
exports.default = _default;