import { useMemo } from 'react';
import { injectIntl } from '@cluedin/locale';

import { EntityTypeSelector } from '../EntityTypeSelector';
import { usePaginatedEntities } from '../../../hooks/usePaginatedEntities';

const EntityTypeSelectorC = ({
  intl,
  value,
  onChange,
  disabled,
  isClearable = true,
  isCreatable = false,
  disableLoadMore = false,
  formatCreateLabel,
  keepEntityTypeAsDisplayName,
}) => {
  const { loadMore, entities, setSearchEntities, isLoadingEntities } =
    usePaginatedEntities({
      defaultPageSize: 1000000,
      loadMoreLabel: intl.formatMessage({
        id: 'module-entityType-select-loadMore',
      }),
    });

  const entityType = useMemo(() => {
    if (value?.entityType) {
      return value?.entityType;
    }

    if (typeof value === 'string') {
      return value;
    }
  }, [value]);

  const displayName = useMemo(() => {
    if (value?.displayName) {
      return value?.displayName;
    }
    if (value?.entityType) {
      return value?.entityType;
    }

    if (typeof value === 'string') {
      return value;
    }
  }, [value]);

  const adaptedEntityTypeSelectValue = useMemo(() => {
    return entityType && displayName
      ? {
          value: entityType ?? '',
          label: keepEntityTypeAsDisplayName
            ? entityType || ''
            : displayName?.replaceAll?.('/', ' ') ?? '',
        }
      : undefined;
  }, [entityType, displayName]);

  return (
    <EntityTypeSelector
      formatCreateLabel={formatCreateLabel}
      keepEntityTypeAsDisplayName={keepEntityTypeAsDisplayName}
      data={entities}
      disabled={disabled}
      onChange={onChange}
      showDefault={false}
      isClearable={isClearable}
      isCreatable={isCreatable}
      isLoading={isLoadingEntities}
      setSearchName={setSearchEntities}
      value={adaptedEntityTypeSelectValue}
      loadMore={!disableLoadMore && loadMore}
      placeholder={intl.formatMessage({
        id: 'module-entityType-select-entityType',
      })}
    />
  );
};

export default injectIntl(EntityTypeSelectorC);
