const personEntityTypePool = [
  '/Person',
  '/Infrastructure/Contact',
  '/Infrastructure/User',
  '/Sales/Lead',
];

export const isPersonType = (entityType) =>
  personEntityTypePool.indexOf(entityType) > -1;

export const personEntityTypePoolSearchFilters = personEntityTypePool.map(
  (entityType) => `entityType:${entityType}`,
);
