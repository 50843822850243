import React from 'react';
import { AvatarWithName } from 'uxi/Img';
import { FirstLetterComponent } from '@cluedin/components';
import HighlightText from '../../../core/components/composites/HighlightText';

const EntityNameWithLogo = ({ name, src, query = '' }) => {
  let icon = null;

  if (!src) {
    icon = (
      <FirstLetterComponent name={name} customSize="22" customFontSize="18" />
    );
  }
  const showedName = !query ? (
    name
  ) : (
    <HighlightText text={name} highlight={query} />
  );

  return (
    <AvatarWithName
      contain
      src={src}
      icon={icon}
      name={showedName}
      style={{ display: 'inline-flex' }}
    />
  );
};

EntityNameWithLogo.displayName = 'EntityNameWithLogo';

export default EntityNameWithLogo;
