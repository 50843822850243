import React from 'react';
import { get } from 'lodash';
import { getEntityTypeIcon } from '@cluedin/components';
import NotConfiguredEntityTypeIcon from './NotConfiguredEntityTypeIcon';
import EntityTypeIconWrapper from './EntityTypeIcon.styles';

const EntityTypeIcon = ({
  entityConfig = {},
  entityType,
  size,
  imgSize = 24,
  ...rest
}) => {
  const icon = get(entityConfig, 'icon');
  const entityTypeToUse = get(entityConfig, 'entityType', entityType);

  let Icon;

  if (!icon) {
    Icon = NotConfiguredEntityTypeIcon;
  } else {
    const IconFromEntityType = getEntityTypeIcon(icon); //yes need a fallback as value is NULL
    if (IconFromEntityType) {
      Icon = IconFromEntityType;
    } else {
      Icon = NotConfiguredEntityTypeIcon;
    }
  }

  return size === 'S' ? (
    <EntityTypeIconWrapper>
      <Icon
        size={imgSize}
        entityConfig={entityConfig}
        entityType={entityTypeToUse}
        {...rest}
      />
    </EntityTypeIconWrapper>
  ) : (
    <Icon
      size={imgSize}
      entityConfig={entityConfig}
      entityType={entityTypeToUse}
      {...rest}
    />
  );
};

export default EntityTypeIcon;
