export const getEntityUrl = (
  id,
  entityType = '',
  entityConfigurations = [],
) => {
  const defaultEntity = `entity/${id}`;

  if (!entityType) {
    return `/${defaultEntity}`;
  }
  const entityConfig = entityConfigurations.find(
    (entityConfiguration) =>
      entityConfiguration.entityType.toLowerCase() === entityType.toLowerCase(),
  );

  if (entityConfig && entityConfig.path) {
    const entityUrl = entityConfig.path.replace(':id', id);

    return `/${entityUrl}`;
  }

  return `/${defaultEntity}`;
};

export const getEntityConfig = (entityConfigurations, entityType) => {
  const entityConfig = entityConfigurations.find(
    (entityConfiguration) =>
      entityConfiguration.entityType.toLowerCase() === entityType.toLowerCase(),
  );

  return entityConfig || {};
};

export const getEntityUrlFromState = (wmsState, id, entityType) => ({
  entityUrl: getEntityUrl(
    id,
    entityType,
    wmsState && wmsState.entityConfigurations
      ? wmsState.entityConfigurations
      : [],
  ),
});

export const getEntityIconFromState = (wmsState, entityType) => {
  const entityConfig = getEntityConfig(
    wmsState && wmsState.entityConfigurations
      ? wmsState.entityConfigurations
      : [],
    entityType,
  );

  return {
    entityConfig,
    entityIconName: entityConfig ? entityConfig?.icon : 'Help',
  };
};

export const getEntityUrlFromStateAndEntity = (wmsState, entity) => {
  const id = entity.id || entity.entityId || entity.EntityId;
  const entityType = entity.data ? entity.data.entityType : entity.entityType;

  const entityUrl = getEntityUrl(
    id,
    entityType,
    wmsState && wmsState.entityConfigurations
      ? wmsState.entityConfigurations
      : [],
  );

  return {
    entityUrl,
  };
};

export const getEntityConfigFromState = (wmsState, entityType) => {
  const entityConfigurations =
    wmsState && wmsState.entityConfigurations
      ? wmsState.entityConfigurations
      : [];

  const entityConfig = entityConfigurations.find(
    (entityConfiguration) =>
      entityConfiguration.entityType.toLowerCase() === entityType.toLowerCase(),
  );

  return {
    entityConfig,
    hasEntityConfig: !!entityConfig,
  };
};

export default {
  getEntityUrlFromState,
  getEntityUrlFromStateAndEntity,
  getEntityConfigFromState,
};
