import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import MainSearch from './MainSearch';
import withSearchSideEffectRedirect from '../../../../search/components/HOCs/withSearchSideEffectRedirect';

class MainSearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInputValue: props.initialSelectionValue,
    };

    this.handleSuggestionChange = debounce(
      this.handleSuggestionChange,
      200,
    ).bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!this.state.markedForForceValueUpdate) {
      if (
        this.props.initialSelectionValue !== prevProps.initialSelectionValue
      ) {
        this.setState({
          currentInputValue: this.props.initialSelectionValue,
          markedForForceValueUpdate: true,
        });
        // } else if (this.props.mainSearchQuery !== prevProps.mainSearchQuery) {
        //   this.setState({
        //     currentInputValue: this.props.mainSearchQuery,
        //     markedForForceValueUpdate: true,
        //   });
      }
    } else {
      this.setState({
        markedForForceValueUpdate: false,
      });
    }
  }

  handleSuggestionChange(e, val) {
    const { shouldFetchSuggestions, onEntityTypePoolChange } = this.props;
    let value = val;

    if (val.Name) {
      value = val.Name;
      onEntityTypePoolChange(val.badgeLabel || '');
    }

    this.setState({
      currentInputValue: value,
    });

    shouldFetchSuggestions(value);
  }

  render() {
    const {
      allSuggestions,
      onEntityTypePoolChange,
      onSearch,
      entityTypePoolIdentifier,
      isFetching,
      initialSelectionValue,
    } = this.props;

    const { currentInputValue, markedForForceValueUpdate } = this.state;

    return (
      <MainSearch
        isFetchingSuggestion={isFetching}
        onClick={(suggestion) => {
          const value =
            suggestion && suggestion.Name ? suggestion.Name : suggestion;
          const badgeLabel =
            suggestion && suggestion.Name && suggestion.badgeLabel
              ? suggestion.badgeLabel
              : '';

          onSearch({
            q: value || this.state.currentInputValue,
            entityTypePoolIdentifier: badgeLabel || entityTypePoolIdentifier,
          });
        }}
        value={markedForForceValueUpdate ? currentInputValue : undefined}
        entityTypePoolIdentifier={entityTypePoolIdentifier}
        onEntityPoolChange={(e, val) => onEntityTypePoolChange(val)}
        onSuggestionChange={this.handleSuggestionChange}
        suggestions={allSuggestions}
        initialSelectionValue={initialSelectionValue}
      />
    );
  }
}

// export default MainSearchContainer;
export default withSearchSideEffectRedirect(MainSearchContainer);
