import { FC } from 'react';
import { FormattedMessage } from '@cluedin/locale';

import { EntityGroupedRelationPanelDetailWrapper } from './EntityGroupedRelationPanelDetailWrapper';
import { EntityGroupedRelationPanelDetailTitle } from './EntityGroupedRelationPanelDetailTitle';

type EntityGroupedRelationPanelDetailProps = {
  edgeTypeToUse: string;
  dataCount: number;
  direction: 'incoming' | 'outgoing';
};

export const EntityGroupedRelationPanelDetail: FC<
  EntityGroupedRelationPanelDetailProps
> = ({ edgeTypeToUse, dataCount, direction }) => {
  const isIncoming = direction === 'incoming';
  const isOutgoing = direction === 'outgoing';

  let totalEdgesLabel;
  if (isIncoming) {
    totalEdgesLabel =
      'module-entityRelationsGraph-groupedEntitiesListTotalIncomingEdges';
  }
  if (isOutgoing) {
    totalEdgesLabel =
      'module-entityRelationsGraph-groupedEntitiesListTotalOutgoingEdges';
  }

  return (
    <EntityGroupedRelationPanelDetailWrapper>
      <EntityGroupedRelationPanelDetailTitle>
        <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesPanelTitle" />
      </EntityGroupedRelationPanelDetailTitle>
      <div style={{ display: 'flex' }}>
        <FormattedMessage id="module-entityRelationsGraph-groupedEntitiesListEdgeType" />
        :&nbsp;
        <strong>{edgeTypeToUse}</strong>
      </div>

      <div style={{ display: 'flex' }}>
        <FormattedMessage id={totalEdgesLabel} />
        :&nbsp;
        <strong>{dataCount}</strong>
      </div>
    </EntityGroupedRelationPanelDetailWrapper>
  );
};
