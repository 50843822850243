import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from '@cluedin/locale';
import CoreVocabSelection from './CoreVocabSelection';
import NonCoreVocabSelection from './NonCoreVocabSelection';

const AnnotationFromEntityType = ({
  coreVocab,
  onSelectionChange,
  schemaForIntegraitonNonCore,
}) => {
  return (
    <div>
      <div style={{ padding: '12px 0' }}>
        <FormattedMessage id="module-annotation-sleectAtLeastOneVocab" />
      </div>
      {coreVocab && coreVocab.length > 0 ? (
        <CoreVocabSelection
          coreVocab={coreVocab}
          onSelectionChange={onSelectionChange}
        />
      ) : (
        <div>
          <FormattedHTMLMessage id="module-annotation-noCoreVocabForEntityType" />
        </div>
      )}
      {schemaForIntegraitonNonCore && schemaForIntegraitonNonCore.length > 0 ? (
        <div style={{ marginTop: '12px' }}>
          <NonCoreVocabSelection
            vocabs={schemaForIntegraitonNonCore}
            onSelectionChange={onSelectionChange}
          />
        </div>
      ) : (
        <div style={{ padding: '12px' }}>
          <FormattedHTMLMessage id="module-annotation-cleanNonCoreVocab" />
        </div>
      )}
    </div>
  );
};

export default AnnotationFromEntityType;
