"use strict";

exports.__esModule = true;
exports.default = void 0;
var _ThemeProvider = _interopRequireDefault(require("uxi/Theme/ThemeProvider"));
var _reactRedux = require("react-redux");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var mapStateToProps = function mapStateToProps(_ref) {
  var _ref$org = _ref.org,
    _ref$org2 = _ref$org === void 0 ? {} : _ref$org,
    organization = _ref$org2.organization;
  return {
    palette: {
      primary: (organization || {}).PrimaryColor,
      secondary: (organization || {}).SecondaryColor
    }
  };
};
var _default = (0, _reactRedux.connect)(mapStateToProps)(_ThemeProvider.default);
exports.default = _default;