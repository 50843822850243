import React from 'react';
import NotLoggedInLayout from '../../../public/components/composites/NotLoggedInLayout';

const AuthWrapper = (Page, wrapperProps) => (props) =>
  (
    <div className="root">
      <NotLoggedInLayout {...props} {...wrapperProps} Page={Page} />
    </div>
  );

export default AuthWrapper;
