import React from 'react';
import Img from 'uxi/Img';
import { capitalizeFirstLetter } from '../../../../../../core/helpers/string';
import SocialMenu from '../../../../../../entityViewModel/components/composites/social/SocialMenu';
import OrganizationListItemLayout from './OrganizationListItemLayout';
import { logosize, socialIconSize } from '../../theme';

const socialKeysToShow = ['website', 'facebook', 'twitter', 'linkedin'];

const filterSocials = (socialViewModel) =>
  Object.keys(socialViewModel)
    .filter((k) => socialKeysToShow.includes(k))
    .reduce((accu, k) => {
      accu[k] = socialViewModel[k]; // eslint-disable-line no-param-reassign
      return accu;
    }, {});

const OrganizationListItem = ({ organization, showEntityQuickView }) => {
  const {
    logo: logoProp = {},
    industry: industryProp,
    location = '',
    social = {},
    entityId,
    name,
    phoneNumber: phoneNumberProp,
    email: emailProp,
  } = organization;

  const finalIndustry = industryProp || '';

  const industry = capitalizeFirstLetter(finalIndustry.toLowerCase()) || ' ';

  const filteredSocials = filterSocials(social);

  const socialMenu = (
    <SocialMenu
      socialViewModel={filteredSocials}
      iconSize={socialIconSize}
      style={{ marginTop: '4px' }}
    />
  );

  const logo = (
    <Img
      width={logosize}
      src={logoProp.large}
      style={{ borderRadius: '50%' }}
    />
  );

  // either make mailto: link, or just display @emailDomain, or '—'
  const email = emailProp ? ( // eslint-disable-line no-nested-ternary
    emailProp.indexOf('@') > -1 ? (
      <a href={`mailto:${emailProp}`} title={`email ${emailProp}`}>
        {emailProp}
      </a>
    ) : (
      `@${emailProp}`
    )
  ) : (
    '–'
  );

  const phoneNumber = phoneNumberProp ? (
    <a href={`tel:${phoneNumberProp}`} title={`phone ${phoneNumberProp}`}>
      {phoneNumberProp}
    </a>
  ) : (
    '–'
  );

  return (
    <OrganizationListItemLayout
      showEntityQuickView={showEntityQuickView}
      name={name}
      logo={logo}
      phoneNumber={phoneNumber}
      email={email}
      socialMenu={socialMenu}
      location={location}
      industry={industry}
      entityId={entityId}
    />
  );
};

OrganizationListItem.displayName = 'OrganizationListItem';

export default OrganizationListItem;
