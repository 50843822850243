import PropTypes from 'prop-types';

import RelationIcon from '@cluedin/svgs/icons/relation-icon.svg';
import ArrowInIcon from '@cluedin/svgs/icons/arrow-in-icon.svg';
import ArrowOutIcon from '@cluedin/svgs/icons/arrow-out-icon.svg';

export const EdgeCard = ({
  ctx,
  x,
  y,
  width,
  height,
  icon,
  keyImage,
  label,
  createdLabel,
  createdDate,
  directionLabel,
  color,
  direction,
  backgroundColor,
  borderColor,
  labelFontStyle,
  labelFontColor,
  subTextFontColor,
  subTextFontStyle,
  kind,
}) => {
  const coords = {
    x: x - width / 2,
    y: y - height / 2,
    x: x - width / 2,
    y: y - height / 2,
    w: width,
    h: height,
  };
  const style = {
    paddingLeft: 16,
    paddingTop: 18,
    innerPadding: 8,
    textLeft: 48,
    textPaddingTop: 4,
    textSpace: 14,
  };

  const isShadowEntity = kind?.toLowerCase() === 'shadowentity';

  ctx.fillStyle = isShadowEntity ? '#ffffff' : backgroundColor;
  ctx.fillRect(coords.x, coords.y, coords.w, coords.h);
  ctx.strokeStyle = isShadowEntity ? '#d7d7d8' : borderColor;
  ctx.strokeRect(coords.x, coords.y, coords.w, coords.h);
  ctx.fillStyle = isShadowEntity ? '#d7d7d8' : color;
  ctx.fillRect(coords.x, coords.y, 3, coords.h);

  const keySize = 12;

  ctx.globalAlpha = 0.3;
  ctx.beginPath();
  ctx.opacity = 0.3;
  ctx.arc(
    coords.x + style.paddingLeft + 8,
    coords.y + style.paddingTop + 6,
    14,
    0,
    2 * Math.PI,
  );
  ctx.fill();
  ctx.globalAlpha = 1;

  if (keyImage) {
    ctx.drawImage(
      keyImage,
      coords.x + coords.w - 26,
      coords.y + style.paddingTop / 2 + 3,
      keySize,
      keySize,
    );
  }

  if (icon) {
    const iconSize = 14;
    ctx.drawImage(
      icon,
      coords.x + style.paddingLeft + 1,
      coords.y + style.paddingTop - 1,
      iconSize,
      iconSize,
    );
  } else {
    const RelationImage = document.createElement('img');
    RelationImage.setAttribute('src', RelationIcon);
    ctx.drawImage(
      RelationImage,
      coords.x + style.paddingLeft,
      coords.y + style.paddingTop - 6,
      24,
      24,
    );
    ctx.drawImage(
      RelationImage,
      coords.x + style.paddingLeft,
      coords.y + style.paddingTop - 6,
      24,
      24,
    );
  }

  if (label) {
    ctx.font = labelFontStyle;
    ctx.fillStyle = labelFontColor;
    ctx.fillText(
      label,
      coords.x + style.textLeft,
      coords.y +
        style.paddingTop +
        style.textPaddingTop +
        (createdLabel ? 0 : 7),
    );
  }

  if (createdLabel) {
    ctx.font = subTextFontColor;
    ctx.fillStyle = subTextFontColor;
    createdDate &&
      ctx.fillText(
        `${createdLabel}: ${createdDate.substring(0, 10)}`,
        coords.x + style.textLeft,
        coords.y + style.paddingTop + style.textPaddingTop + style.textSpace,
      );
  }

  if (direction) {
    ctx.font = subTextFontStyle;
    ctx.fillStyle = subTextFontColor;
    ctx.fillText(
      `${directionLabel}:`,
      coords.x + style.textLeft,
      coords.y + style.paddingTop + style.textPaddingTop + style.textSpace * 2,
    );
  }

  ctx.font = subTextFontStyle;
  ctx.fillStyle = subTextFontColor;
  if (direction === 'Incoming') {
    const ArrowOutImage = document.createElement('img');
    ArrowOutImage.setAttribute('src', ArrowOutIcon);
    ctx.drawImage(
      ArrowOutImage,
      coords.x + style.textLeft * 1.8,
      coords.y + style.paddingTop + style.textPaddingTop + style.textSpace + 8,
      8,
      8,
    );
    ctx.fillText(
      'Incoming',
      coords.x + style.textLeft * 2,
      coords.y + style.paddingTop + style.textPaddingTop + style.textSpace * 2,
    );
  }
  if (direction === 'Outgoing') {
    const ArrowInImage = document.createElement('img');
    ArrowInImage.setAttribute('src', ArrowInIcon);
    ctx.drawImage(
      ArrowInImage,
      coords.x + style.textLeft * 1.8,
      coords.y + style.paddingTop + style.textPaddingTop + style.textSpace + 8,
      8,
      8,
    );
    ctx.fillText(
      'Outgoing',
      coords.x + style.textLeft * 2,
      coords.y + style.paddingTop + style.textPaddingTop + style.textSpace * 2,
    );
  }

  return ctx;
};

EdgeCard.propTypes = {
  ctx: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.string,
  keyImage: PropTypes.object,
  createdLabel: PropTypes.string,
  createdDate: PropTypes.string,
  directionLabel: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  labelFontStyle: PropTypes.string,
  labelFontColor: PropTypes.string,
  subTextFontColor: PropTypes.string,
  subTextFontStyle: PropTypes.string,
  isShadowEntity: PropTypes.bool,
};
