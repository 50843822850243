import React from 'react';
import { createAction } from 'redux-actions';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { createBatchAsyncAction } from './helpers/actionCreator';

export const withCache =
  (reducerName, propToCheck, isFetching) =>
  (actionFunc) =>
  (...args) =>
  (dispatch, getState) => {
    // eslint-disable-line consistent-return
    const state = getState();
    const reducer = state ? state[reducerName] : null;
    const value = reducer ? reducer[propToCheck] : null;
    const isFetchingValue = reducer ? reducer[isFetching] : null;

    if (
      !isFetchingValue &&
      value &&
      Array.isArray(value) &&
      value.length === 0
    ) {
      return dispatch(actionFunc(...args));
    }
    if (!value && !isFetchingValue) {
      return dispatch(actionFunc(...args));
    }
  };

/**
 * Notifications
 */
export const showAlert = createAction('CORE_SHOW_ALERT');
export const hideAlert = createAction('CORE_HIDE_ALERT');
export const shouldShowAlert =
  (alert, delay = 5000) =>
  (dispatch) => {
    const alertWithId = {
      ...alert,
      id: uuid(),
    };
    dispatch(showAlert(alertWithId));
    setTimeout(() => {
      dispatch(hideAlert(alertWithId));
    }, delay);
  };

/**
 * Batch Actions
 */
const toStatusViewModel = (data, entityName) => ({
  id: uuid(),
  data,
  status: {},
  errorMsg: null,
  entityName,
});

const dataItemsToStatusViewModel = (dataItems = [], entityName) =>
  dataItems.map((x) => toStatusViewModel(x, entityName));

export const batchAsyncActions = createBatchAsyncAction('BATCH_ASYNC_ACTIONS');

export const createAsyncBatchActions =
  (dataFetcherPromise) =>
  (dataItems = []) =>
  (dispatch) => {
    const doneRequest = [];
    dataItems.forEach((dataItemVM) => {
      dispatch(batchAsyncActions.request(dataItemVM));
      dataFetcherPromise(dataItemVM.data)
        .then(() => {
          doneRequest.push(dataItems);

          dispatch(batchAsyncActions.receive(dataItemVM));

          if (doneRequest.length === dataItems.length) {
            dispatch(batchAsyncActions.all({}));
          }
        })
        .catch((e) => {
          const payload = {
            ...dataItemVM,
            errorMsg: e,
          };
          dispatch(batchAsyncActions.invalid(payload));
        });
    });
  };

export const withBatchActionStatus = (Comp, dataFetcherPromise, entityName) => {
  const HOC = (props) => <Comp {...props} />;

  const shouldBatchAction = createAsyncBatchActions(dataFetcherPromise);

  const mapStateToProps = ({ coreModule: { batchActionsStatuses } }) => ({
    statuses: (batchActionsStatuses[entityName || 'default'] || {}).requests,
    allDone: (batchActionsStatuses[entityName || 'default'] || {}).allDone,
  });

  const mapDispatchToProps = (dispatch) => ({
    batch: (dataItems) => {
      const dataItemVM = dataItemsToStatusViewModel(dataItems, entityName);
      dispatch(shouldBatchAction(dataItemVM));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

const findStatusFromBatchAction = (
  batchActionsStatuses = {},
  predicate,
  ownProps,
) => {
  const currentBatchVM = (
    (batchActionsStatuses.default || {}).requests || []
  ).find((x) => predicate(x.data, ownProps));

  if (currentBatchVM) {
    return {
      status: currentBatchVM.status,
      errorMsg: currentBatchVM.errorMsg,
    };
  }

  return {
    status: null,
    errorMsg: null,
  };
};

export const withStatus = (Comp, predicate) => {
  const HOC = (props) => <Comp {...props} />;

  const mapStateToProps = (
    { coreModule: { batchActionsStatuses } },
    ownProps,
  ) => findStatusFromBatchAction(batchActionsStatuses, predicate, ownProps);

  return connect(mapStateToProps)(HOC);
};

export const appendModule = createAction('APPEND_MODULE');
export const appendTitle = createAction('APPEND_TITLE');

export default {
  withCache,
};
