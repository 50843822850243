import EntityTypeAvatar from '../../../composites/entityTypes/EntityTypeAvatar';
import { getEntityConfig } from '../../../../../wms/selectors';
import { useGoldenRecordList } from '../../GoldenRecordListContext';
import { useStaticGoldenRecordList } from '../../StaticGoldenRecordListContext';

const EntityTypeCellComponent = (entity) => {
  const { entityTypesConfigurations } = entity?.useStaticContext
    ? useStaticGoldenRecordList()
    : useGoldenRecordList();

  const entityType = getEntityConfig(
    entityTypesConfigurations || [],
    entity.entityType,
  );

  return (
    <EntityTypeAvatar
      entityConfig={entityType}
      entityType={entity.entityType}
    />
  );
};

export default EntityTypeCellComponent;
