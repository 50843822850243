import { removeLastCharacter } from '../core/helpers/string';

export const hasSubsribedCurrentSearch = (q, searches) => {
  if (!q) {
    return false;
  }

  return (searches || []).find((s) => {
    let match = s.Query;
    if (match.length > 1 && match.indexOf('*') > -1) {
      match = removeLastCharacter(match);
    }
    return match.toLowerCase() === q.toLowerCase();
  });
};

export default {
  hasSubsribedCurrentSearch,
};
