import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const getBeforeUnloadHandler = (message) => (e) => {
  e.preventDefault();
  e.returnValue = message;
  return message;
};

const getMessageFn = (message) => (location, action) => {
  return message;
};

export const useLeavePrompt = (when, message) => {
  useEffect(() => {
    const beforeUnloadHandler = getBeforeUnloadHandler(message);
    if (when) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [when, message]);

  return () => <Prompt when={when} message={getMessageFn(message)} />;
};
