import { useEffect, useState } from 'react';
import {
  useLazyQueryDataSource,
  DataSourcePayload,
} from '../../../../dataSourceV2/hooks/useLazyDataSource';
import { useMultiUploader } from '../MultiUploader/MultiUploaderProvider';
import { FileUploadProgressLine } from '../../composite/FileUploadProgress';
import { FileUploadRequestWithProgress } from '../../../types';

type FileUploadProgressLineContainerProps = {
  fileProgress: FileUploadRequestWithProgress;
  onClose: () => void;
};

export const FileUploadProgressLineContainer = ({
  fileProgress,
  onClose,
}: FileUploadProgressLineContainerProps) => {
  const fileUploadRequest = fileProgress?.fileUploadRequest;

  const [pause, setPause] = useState<boolean>(false);
  const [dataSourceFromServer, setDataSourceFromServer] =
    useState<DataSourcePayload>();

  const multiUploadContext = useMultiUploader();
  const abortFileUpload = multiUploadContext?.abortFileUpload;
  const resumeFileUpload = multiUploadContext?.resumeFileUpload;
  const cancelFileUpload = multiUploadContext?.cancelFileUpload;
  const dataSourceId = fileUploadRequest?.dataSourceId || '';
  const finished = (fileProgress?.progressInfo?.percentage || 0) >= 100;

  const [getDataSource, { dataSource }] = useLazyQueryDataSource(dataSourceId);

  useEffect(() => {
    setTimeout(() => {
      if (!dataSourceFromServer) {
        getDataSource();
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (dataSource) {
      setDataSourceFromServer(dataSource);
    }
  }, [dataSource]);

  const finishedWithDataSource =
    finished || dataSource?.fileMetadata?.uploading === false;

  return (
    <FileUploadProgressLine
      showClose={false}
      fileUploadRequest={fileProgress.fileUploadRequest}
      closeToast={onClose}
      error={fileProgress.progressInfo.error}
      errorMessage={fileProgress.progressInfo.errorMessage}
      finishedWithDataSource={finishedWithDataSource}
      percentage={fileProgress?.progressInfo?.percentage}
      pause={pause}
      dataSourceId={dataSourceId}
      abortFileUpload={() => {
        if (abortFileUpload) {
          abortFileUpload(fileUploadRequest);

          setPause(true);
        }
      }}
      resumeFileUpload={() => {
        if (resumeFileUpload) {
          resumeFileUpload(fileUploadRequest);
          setPause(false);
        }
      }}
      cancelFileUpload={() => {
        if (cancelFileUpload) {
          cancelFileUpload(fileUploadRequest);

          onClose();
        }
      }}
    />
  );
};
