import React, { useState } from 'react';
import { Externallink } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import { withSchemaFromPropertyKey } from '../Hocs/withSchemaFromPropertyKey';
import SchemaQuickDetailPopover from './SchemaQuickDetailPopover';

const SchemaPropertyLinkWithPopover = ({
  schema,
  propertyKey,
  notFoundText,
}) => {
  const [delayHandler, setDelayHandler] = useState(null);
  const [showPopover, setPopoverVisbilitiy] = useState(false);

  if (
    propertyKey === 'name' ||
    propertyKey === 'description' ||
    propertyKey === 'attribute-type'
  ) {
    return <FormattedMessage id="module-schema-enittyDEfaultProperty" />;
  }
  if (!schema) {
    return <span>{notFoundText || propertyKey}</span>;
  }

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setPopoverVisbilitiy(true);
      }, 500),
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    setPopoverVisbilitiy(false);
  };

  return (
    <div
      style={{
        position: 'relative',
        fontSize: '12px',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a
        rel="noopener noreferrer"
        href={`/admin/management/catalog/vocab-key?key=${encodeURIComponent(
          schema.Key,
        )}`}
        target="_blank"
      >
        <span style={{ paddingRight: '4px' }}>{schema.Key}</span>
        <Externallink size={12} />
      </a>
      {showPopover && (
        <div
          style={{
            top: '24px',
            position: 'absolute',
            zIndex: '9',
          }}
        >
          <SchemaQuickDetailPopover schema={schema} />
        </div>
      )}
    </div>
  );
};

export default withSchemaFromPropertyKey(SchemaPropertyLinkWithPopover);
