"use strict";

exports.__esModule = true;
exports.pulseName = exports.pulseKeyframesConfig = exports.pulseEasing = exports.pulseDuration = exports.default = void 0;
var _styledComponents = require("styled-components");
var _templateObject;
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var pulseName = (0, _styledComponents.keyframes)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  0% { opacity: 0.16; }\n  50% { opacity: 0.32; }\n  100% { opacity: 0.16; }\n"])));
exports.pulseName = pulseName;
var pulseKeyframesConfig = {
  '0%': 0.16,
  '50%': 0.32,
  '100%': 0.16
};
exports.pulseKeyframesConfig = pulseKeyframesConfig;
var pulseEasing = 'cubic-bezier(.3,0,.7,1)';
exports.pulseEasing = pulseEasing;
var pulseDuration = '1.4s';
exports.pulseDuration = pulseDuration;
var _default = pulseName;
exports.default = _default;