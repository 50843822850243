import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Pencil } from 'uxi/Icons';
import notify, {
  isDesktopNotificationEnabled,
} from '../../../../core/helpers/desktopNotification';
import Toast from '../../../../core/components/composites/Toast';

const MeshAPIQueuedMessage = ({ index, onCloseClick }) => {
  const title = 'Edition request has been queued';
  const message = <div>Edition request has been queued</div>;

  if (isDesktopNotificationEnabled()) {
    return <span />;
  }

  return (
    <Toast
      index={index}
      onCloseClick={onCloseClick}
      title={title}
      message={message}
      Icon={Pencil}
    />
  );
};

const EnhancedMeshAPIQueuedMessage = compose(
  lifecycle({
    componentDidMount() {
      const title = 'Edition request has been queued';
      const message = 'Edition request has been queued.';

      notify(title, { body: message });
    },
  }),
)(MeshAPIQueuedMessage);

export default EnhancedMeshAPIQueuedMessage;
