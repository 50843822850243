import { FC, useId, useState } from 'react';
import { PrimaryButton, Button } from '@cluedin/form';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';
import { FilterButtonWithLabel } from '@cluedin/list-pattern';

import { EntityRelationsGraphV3HeaderWrapper } from './EntityRelationsGraphV3HeaderWrapper';
import { EntityRelationsGraphV3GraphButtonWrapper } from './EntityRelationsGraphV3GraphButtonWrapper';
import { EntityRelationsGraphV3HeaderControlWrapper } from './EntityRelationsGraphV3HeaderControlWrapper';
import { ReactComponent as GraphViewIcon } from './svgs/graphView-ico.svg';
import { ReactComponent as TreeViewIcon } from './svgs/treeView-ico.svg';
import { CollapseElementAnimated } from '../../../core/components/composites/CollapseElementAnimated';
import { GraphHeaderFilterToolbar } from './GraphHeaderFilterToolbar';

type EntityRelationsGraphV3HeaderProps = {
  isTreeviewExpanded: boolean;
  onTreeviewChange: (value: boolean) => void;
  isShadowNodesHidden: boolean;
  onShadowNodesChange: (value: boolean) => void;
  isTemporalNodesHidden: boolean;
  onTemporalNodesChange: (value: boolean) => void;
  onReset: () => void;
};

export const EntityRelationsGraphV3Header: FC<
  EntityRelationsGraphV3HeaderProps
> = ({
  isTreeviewExpanded,
  onTreeviewChange,
  isShadowNodesHidden,
  onShadowNodesChange,
  isTemporalNodesHidden,
  onTemporalNodesChange,
  onReset,
}) => {
  const [isFiltersOpened, setIsFiltersOpened] = useState<boolean>(false);

  const graphViewTooltipId = useId();
  const treeViewTooltipId = useId();

  const theme = useTheme();
  const neutralPrimary = theme?.palette?.neutralPrimary;
  const themePrimary = theme?.palette?.themePrimary;

  const GraphViewButton = isTreeviewExpanded ? PrimaryButton : Button;
  const TreeViewButton = isTreeviewExpanded ? Button : PrimaryButton;

  const graphViewIconFillColor = isTreeviewExpanded ? '#fff' : themePrimary;
  const treeViewIconFillColor = isTreeviewExpanded ? themePrimary : '#fff';

  return (
    <EntityRelationsGraphV3HeaderWrapper>
      <EntityRelationsGraphV3HeaderControlWrapper theme={theme}>
        <TooltipHost
          id={graphViewTooltipId}
          calloutProps={{ gapSpace: 0 }}
          content={
            <FormattedMessage id="module-entityRelationsGraphV2-graphViewTooltipLabel" />
          }
        >
          <TreeViewButton
            startIcon={
              <EntityRelationsGraphV3GraphButtonWrapper
                fillColor={treeViewIconFillColor}
              >
                <GraphViewIcon />
              </EntityRelationsGraphV3GraphButtonWrapper>
            }
            onClick={() => onTreeviewChange(!isTreeviewExpanded)}
          />
        </TooltipHost>

        <TooltipHost
          id={treeViewTooltipId}
          calloutProps={{ gapSpace: 0 }}
          content={
            <FormattedMessage id="module-entityRelationsGraphV2-treeViewTooltipLabel" />
          }
        >
          <GraphViewButton
            startIcon={
              <EntityRelationsGraphV3GraphButtonWrapper
                fillColor={graphViewIconFillColor}
              >
                <TreeViewIcon />
              </EntityRelationsGraphV3GraphButtonWrapper>
            }
            onClick={() => onTreeviewChange(!isTreeviewExpanded)}
          />
        </TooltipHost>
      </EntityRelationsGraphV3HeaderControlWrapper>

      <FilterButtonWithLabel
        label={
          <FormattedMessage id="module-entityRelationsGraphV2-filterLabel" />
        }
        style={{ marginLeft: '15px' }}
        onClick={() => setIsFiltersOpened(!isFiltersOpened)}
      />

      <CollapseElementAnimated
        duration={100}
        isVisible={isFiltersOpened}
        style={{
          left: 0,
          position: 'absolute',
          right: 0,
          top: 52,
          zIndex: 2,
          maxWidth: 'calc(100vw - 216px)',
        }}
        innerStyles={{
          boxShadow: '0px 2px 5px 1px rgba(158,158,158,.7)',
        }}
      >
        <GraphHeaderFilterToolbar
          hideFilters={() => setIsFiltersOpened(false)}
          isShadowNodesHidden={isShadowNodesHidden}
          onShadowNodesChange={onShadowNodesChange}
          isTemporalNodesHidden={isTemporalNodesHidden}
          onTemporalNodesChange={onTemporalNodesChange}
          onReset={onReset}
          isFiltersOpened={isFiltersOpened}
        />
      </CollapseElementAnimated>
    </EntityRelationsGraphV3HeaderWrapper>
  );
};
