import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@cluedin/locale';
import moment from 'moment';
import { get } from 'lodash';

import {
  ExplainLogSingleOperationItem,
  ExplainLogSingleOperationStatus,
  ExplainLogSingleOperationWrapper,
} from './ExplainLogSingleOperation.styles';
import ExplainLogKind from '../ExplainLogKind/ExplainLogKind';
import ExplainLogSummary from '../ExplainLogSummary/ExplainLogSummary';

const ExplainLogSingleOperation = ({ data }) => {
  const resultStatus = get(data, 'operation.result.status');
  const summaries = get(data, 'operation.summaries');

  return (
    <>
      <ExplainLogSingleOperationItem>
        <strong style={{ paddingBottom: '4px' }}>
          <FormattedMessage id="module-explainLog-detailOperation" />
          :&nbsp;
          {data.operation.title}
        </strong>
        {data && resultStatus && (
          <div style={{ display: 'flex' }}>
            <div style={{ color: '#666666' }}>
              <FormattedMessage id="module-explainLog-categoryStatus" />
              :&nbsp;
            </div>
            <ExplainLogSingleOperationStatus resultStatus={resultStatus}>
              <strong>{resultStatus}</strong>
            </ExplainLogSingleOperationStatus>
            {data.operation.result.event &&
              data.operation.result.event.message && (
                <>
                  &nbsp;-&nbsp;
                  <div>{data.operation.result.event.message}</div>
                </>
              )}
          </div>
        )}
        {data.operation.duration && (
          <div style={{ display: 'flex' }}>
            <div style={{ color: '#666666' }}>
              <FormattedMessage id="module-explainLog-detailDuration" />
              :&nbsp;
            </div>
            {moment
              .utc(
                moment
                  .duration(data.operation.duration, 'seconds')
                  .asMilliseconds(),
              )
              .format('HH:mm:ss.SSS')}
            s
          </div>
        )}
      </ExplainLogSingleOperationItem>

      <ExplainLogSingleOperationWrapper>
        {data && summaries && summaries.length > 0 && (
          <ExplainLogKind
            title={<FormattedMessage id="module-explainLog-summariesTitle" />}
          >
            {data &&
              summaries &&
              (summaries || []).map((item, index) => {
                return <ExplainLogSummary key={index} data={item} />;
              })}
          </ExplainLogKind>
        )}
      </ExplainLogSingleOperationWrapper>
    </>
  );
};

export default ExplainLogSingleOperation;

ExplainLogSingleOperation.propTypes = {
  data: PropTypes.object,
};

ExplainLogSingleOperation.defaultProps = {
  data: {},
};
