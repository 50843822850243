import { useQuery, NetworkStatus } from '@apollo/client';
import { gql } from '@apollo/client';
import get from 'lodash/get';
import { legacyClient } from '../../../../../../data/graphql';
import { createVmFromgGql } from '../viewModels/searchResult';
import { createSearchParameterFromProps } from '../../../../data/gql';

const GET_SEARCH = gql`
  query searchQueryFromHooks(
    $query: String
    $filter: String
    $filters: [FilterQuery]
    $terms: [TermQuery]
    $pageSize: Int
    $contextIds: [Guid]
    $sort: SortMethod
    $sortDirection: SortDirection
    $includeExternalData: Boolean
    $includeUnstructuredData: Boolean
    $cursor: PagingCursor
    $searchUntokenized: Boolean
  ) {
    search(
      query: $query
      filter: $filter
      filters: $filters
      terms: $terms
      pageSize: $pageSize
      contextIds: $contextIds
      sort: $sort
      sortDirection: $sortDirection
      includeExternalData: $includeExternalData
      includeUnstructuredData: $includeUnstructuredData
      cursor: $cursor
      searchUntokenized: $searchUntokenized
    ) {
      cursor
      entries {
        id
        name
        createdDate
        discoveryDate
        modifiedDate
        properties
        providerOrigins
        description
        uris
        previewImage {
          uri
        }
        entityType
        sortDate
      }
    }
  }
`;

export const useSearchQuery = (variables = {}, type, entityConfigurations) => {
  const fetchPolicy = 'network-only';
  const { data, loading, networkStatus, error, fetchMore } = useQuery(
    GET_SEARCH,
    {
      client: legacyClient,
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy,
    },
  );

  const showLoader =
    loading && (!data || networkStatus === NetworkStatus.setVariables);
  const searchResult = get(data, 'search.entries', []);
  const cursor = get(data, 'search.cursor');
  const total = get(data, 'search.totalResults');

  const searchResultViewModel = createVmFromgGql(
    searchResult,
    type,
    entityConfigurations || [],
  );

  const isFetching =
    networkStatus === 1 || // loading
    networkStatus === 2 || // setting varialbes
    networkStatus === 4; // refetch

  const isFetchingMore = networkStatus === 3;

  return [
    searchResultViewModel,
    {
      loading: showLoader,
      isFetching,
      isFetchingMore,
      error,
      total,
      variables,
      query: GET_SEARCH,
      fetchMore: (pageNumber) => {
        return fetchMore({
          variables: {
            ...variables,
            pageNumber,
            cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult.search && !fetchMoreResult.search.entries) {
              return prev;
            }
            return {
              ...prev,
              search: {
                ...prev.search,
                entries: [
                  ...prev.search.entries,
                  ...fetchMoreResult.search.entries,
                ],
                cursor: fetchMoreResult.search.cursor,
              },
            };
          },
        });
      },
    },
  ];
};
