import { withRouter } from 'react-router';
import EntityTable from '../../../entityLists/components/composites/EntityTable';
import { withPropertyViewModelOnly } from '../../hocs/withPropertyViewModelOnly';
import { withGqlQueryList } from '../../data/withGqlQueryList';

const EnhancedTable = withPropertyViewModelOnly(EntityTable);

export const EntityList = withRouter(withGqlQueryList(EnhancedTable));

export default {
  name: 'Entity List',
  type: 'entityList',
  Component: EntityList,
  parameters: [],
};
