import React from 'react';
import { DivPadding } from 'uxi/Base';
import { Button } from '@cluedin/form';

const ConfigurationFilters = () => (
  <DivPadding
    padding="S"
    style={{ display: 'flex', alignItems: 'flex-start', background: '#fff' }}
  >
    <div style={{ flex: 1, display: 'flex', flexFlow: 'row wrap' }}>
      <div style={{ display: 'inline-block', margin: '8px' }}>
        <Button style={{ width: '250px' }} />
      </div>
      <div style={{ margin: '8px', display: 'inline-block' }}>
        <Button style={{ width: '250px' }} />
      </div>
      <div style={{ marginLeft: 'auto', padding: '8px' }}>
        <Button style={{ width: '223px' }} />
      </div>
    </div>
  </DivPadding>
);

export default ConfigurationFilters;
