/* eslint-disable */
const relationType = {
  fromStartToEnd: 'fromStartToEnd',
  fromEndToStart: 'fromEndToStart',
};

export const createShortPartViewModel = (startId, endPathId, shortPath) => {
  const viewModel = [];
  if (!startId || !endPathId) {
    return [];
  }

  if (!shortPath || !shortPath.Nodes || shortPath.Nodes.length === 0) {
    return viewModel;
  }

  const start = shortPath.Nodes.find(
    (n) => n.Data.entity['attribute-id'] === startId,
  );

  viewModel.push({
    content: start.Data.entity,
    type: 'entity',
  });

  let firstRelation = shortPath.Relationships.find(
    (n) => n.StartNodeReference.Id === start.Reference.Id,
  );

  let way = relationType.fromStartToEnd;

  if (!firstRelation) {
    firstRelation = shortPath.Relationships.find(
      (n) => n.EndNodeReference.Id === start.Reference.Id,
    );
    way = relationType.fromEndToStart;
  }

  firstRelation.processed = true;

  viewModel.push({
    content: firstRelation.TypeKey,
    type: 'relation',
    sens: way === relationType.fromStartToEnd ? '' : 'invert',
  });

  let currentNode = firstRelation;

  while (currentNode) {
    let secondEntity;

    if (way === relationType.fromStartToEnd) {
      secondEntity = shortPath.Nodes.find(
        (n) => n.Reference.Id === currentNode.EndNodeReference.Id,
      );
    }

    if (way === relationType.fromEndToStart) {
      secondEntity = shortPath.Nodes.find(
        (n) => n.Reference.Id === currentNode.StartNodeReference.Id,
      );
    }

    /* if (!secondEntity) {
      console.log('----problem in shortPath happens-----');
    } */

    viewModel.push({
      content: secondEntity.Data.entity,
      type: 'entity',
      referenceId: secondEntity.Reference.Id,
    });

    if (secondEntity.Data.entity['attribute-id'] === endPathId) {
      currentNode = null;
    } else {
      let nextRelation;

      if (way === relationType.fromStartToEnd) {
        nextRelation = shortPath.Relationships.find(
          (n) =>
            n.StartNodeReference.Id === secondEntity.Reference.Id &&
            !n.processed,
        );
      } else {
        nextRelation = shortPath.Relationships.find(
          (n) =>
            n.EndNodeReference.Id === secondEntity.Reference.Id && !n.processed,
        );
      }

      if (nextRelation) {
        nextRelation.processed = true;

        viewModel.push({
          content: nextRelation.TypeKey,
          type: 'relation',
          sens: way === relationType.fromStartToEnd ? '' : 'invert',
        });

        currentNode = nextRelation;
      } else if (way === relationType.fromStartToEnd) {
        const nextRelationEnd = shortPath.Relationships.find(
          (n) =>
            n.EndNodeReference.Id === secondEntity.Reference.Id && !n.processed,
        );

        if (nextRelationEnd) {
          way = relationType.fromEndToStart;

          nextRelationEnd.processed = true;

          viewModel.push({
            content: nextRelationEnd.TypeKey,
            type: 'relation',
            sens: 'invert',
          });
          currentNode = nextRelationEnd;
        } else {
          currentNode = null;
        }
      } else {
        const nextRelationStart = shortPath.Relationships.find(
          (n) =>
            n.StartNodeReference.Id === secondEntity.Reference.Id &&
            !n.processed,
        );

        if (nextRelationStart) {
          way = relationType.fromStartToEnd;

          nextRelationStart.processed = true;

          viewModel.push({
            content: nextRelationStart.TypeKey,
            type: 'relation',
          });
          currentNode = nextRelationStart;
        } else {
          currentNode = null;
        }
      }
    }
  }

  return viewModel;
};

export default {
  createShortPartViewModel,
};
