import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Arrowdown, Arrowright } from 'uxi/Icons';
import { StarIcon } from '@cluedin/svgs';
import { FormattedMessage } from '@cluedin/locale';
import { useTheme } from '@cluedin/theme';

import EntityTypeAvatarConfigurationLinkContainer from '../../../../../entityTypes/components/containers/EntityTypeAvatarConfigurationLinkContainer';
import ExplainLogEntry from '../ExplainLogEntry/ExplainLogEntry';
import ExplainLogIcon from '../ExplainLogIcon/ExplainLogIcon';
import ExplainLogCopyCode from '../ExplainLogCopyCode/ExplainLogCopyCode';
import {
  ExplainLogIdentifierBox,
  ExplainLogIdentifierCodeBackground,
  ExplainLogIdentifierCopyCodeWrapper,
  ExplainLogIdentifierCodeWrapper,
  ExplainLogIdentifierHeader,
  ExplainLogIdentifierInformation,
  ExplainLogIdentifierSourceWrapper,
  ExplainLogIdentifierTitle,
  ExplainLogEntityTypeWrapper,
  ExplainLogIdentifierWrapper,
} from './ExplainLogIdentifier.styles';

const ExplainLogIdentifier = ({
  attributeOrigin,
  color,
  data,
  details,
  entityType,
  hasSubtitle,
  icon,
  isGoldenRecord,
  labels = {},
  size,
  entityId,
}) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  const [isExpanded, setIsExpanded] = useState(false);
  const {
    identifierTitle = (
      <FormattedMessage id="module-explainLog-identifierTitle" />
    ),
  } = labels;

  return (
    <ExplainLogIdentifierBox isExpanded={isExpanded}>
      <ExplainLogIdentifierWrapper isExpanded={isExpanded} theme={theme}>
        <ExplainLogIdentifierHeader
          data-test="entity_explainLog_identifier_title"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <Arrowdown color={themePrimary} size={16} />
          ) : (
            <Arrowright color={themePrimary} size={16} />
          )}

          <ExplainLogIcon color={color} icon={icon} size={size} />

          <ExplainLogIdentifierInformation>
            <ExplainLogIdentifierTitle>
              {identifierTitle}
            </ExplainLogIdentifierTitle>
            {hasSubtitle && (
              <>
                <ExplainLogEntityTypeWrapper>
                  <FormattedMessage id="module-explainLog-entityType" />:
                  <EntityTypeAvatarConfigurationLinkContainer
                    entityType={entityType}
                  />
                </ExplainLogEntityTypeWrapper>

                <ExplainLogIdentifierCodeWrapper>
                  <FormattedMessage id="module-explainLog-originCode" />
                  :&nbsp;
                  <ExplainLogIdentifierCodeBackground>
                    {attributeOrigin}
                  </ExplainLogIdentifierCodeBackground>
                  <ExplainLogIdentifierCopyCodeWrapper>
                    <ExplainLogCopyCode attributeOrigin={attributeOrigin} />
                  </ExplainLogIdentifierCopyCodeWrapper>
                </ExplainLogIdentifierCodeWrapper>
              </>
            )}
          </ExplainLogIdentifierInformation>
        </ExplainLogIdentifierHeader>
      </ExplainLogIdentifierWrapper>

      <ExplainLogIdentifierSourceWrapper isExpanded={isExpanded}>
        {data &&
          (data || [])
            .sort((a, b) =>
              a.identifier.details.dataPartId > b.identifier.details.dataPartId
                ? 1
                : -1,
            )
            .map((item, index) => {
              const dataHasLength = item.operations.length > 0;

              return (
                dataHasLength && (
                  <ExplainLogEntry
                    data={item}
                    details={details}
                    key={index}
                    isGoldenRecord={isGoldenRecord}
                    entityId={entityId}
                  />
                )
              );
            })}
      </ExplainLogIdentifierSourceWrapper>
    </ExplainLogIdentifierBox>
  );
};

export default ExplainLogIdentifier;

ExplainLogIdentifier.propTypes = {
  attributeOrigin: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.array,
  details: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  entityType: PropTypes.string,
  hasSubtitle: PropTypes.bool,
  icon: PropTypes.node,
  isGoldenRecord: PropTypes.bool,
  labels: PropTypes.object,
  size: PropTypes.number,
  entityId: PropTypes.string,
};

ExplainLogIdentifier.defaultProps = {
  attributeOrigin: null,
  color: null,
  data: [],
  details: null,
  entityType: null,
  hasSubtitle: false,
  icon: <StarIcon />,
  isGoldenRecord: false,
  labels: {},
  size: null,
  entityId: null,
};
