import React from 'react';
import { withWorkflow } from '@cluedin/form';

import RecordPanel from '../components/containers/RecordPanel/RecordPanel';

const RecordPanelWrapper =
  (Comp) =>
  ({ openWorkflow, ...props }) =>
    <Comp openRecordPanelWorkflow={openWorkflow} {...props} />;

export const withRecordPanelWorkflow = (Comp) =>
  withWorkflow(RecordPanelWrapper(Comp), RecordPanel, {
    withCustomHeader: true,
    panelType: 'custom',
    width: 1200,
  });
