import { Icon } from '@fluentui/react/lib/Icon';
import styled from 'styled-components';

const IconContentWrapper = styled.div`
  background: ${({ backgroundColor = '' }: { backgroundColor: string }) =>
    backgroundColor};
  border-radius: 50%;
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const IconCircle = ({
  iconName = '',
  style = {},
  backgroundColor = '',
}) => {
  return (
    <IconContentWrapper style={style} backgroundColor={backgroundColor}>
      <Icon
        style={{
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
        }}
        iconName={iconName}
      />
    </IconContentWrapper>
  );
};
