import { CSSProperties } from 'react';
import styled from 'styled-components';
import { useTheme } from '@cluedin/theme';
import { FormattedMessage } from '@cluedin/locale';
import { FileDataSource } from './types';
import { FileAcceptedItem } from './FileAcceptedItem';
import { FileListHeader } from './FileListHeader';
import { getClassicValidationMessage } from '../../../../core/validation/messages';
import { ErrorFile } from '../../../types';

type AcceptedListProps = {
  fileDataSources: FileDataSource[];
  handleRemoveFile: (index: number, file: FileDataSource) => void;
  setFileDataSources: (files: FileDataSource[]) => void;
  style?: CSSProperties;
  filesLimit?: number;
  onClearFiles: () => void;
  errorFileUpload: ErrorFile[];
  handleChangeHasHeaders: (idx: number) => void;
};

const FileAcceptedListWrapper = styled.div`
  border: 1px solid #ccc;
`;

const noBorder = {
  border: 0,
};

export const FileAcceptedList = ({
  fileDataSources = [],
  handleRemoveFile,
  setFileDataSources,
  style = {},
  filesLimit,
  onClearFiles,
  errorFileUpload,
  handleChangeHasHeaders,
}: AcceptedListProps) => {
  const theme = useTheme();
  const themePrimary = theme.palette.themePrimary;

  return (
    <>
      <FileListHeader
        title={<FormattedMessage id="module-uploadFile-acceptedFiles" />}
        color={themePrimary}
        onClear={onClearFiles}
      />

      <FileAcceptedListWrapper style={style}>
        {fileDataSources.map((fileDataSource: FileDataSource, index) => {
          const duplicates = fileDataSources.filter((f, fIndex) => {
            return (
              fIndex !== index &&
              f.file.name === fileDataSource.file.name &&
              f.file.size === fileDataSource.file.size &&
              f.file.lastModified === fileDataSource.file.lastModified
            );
          });

          const errorUpload = (errorFileUpload || []).find(
            (error) => error.file === fileDataSource.file,
          );

          return (
            <FileAcceptedItem
              errorUpload={errorUpload}
              duplicates={duplicates}
              style={index === 0 ? noBorder : {}}
              fileExitedLimit={filesLimit ? index + 1 > filesLimit : false}
              fileDataSource={fileDataSource}
              handleRemoveFile={() => {
                handleRemoveFile(index, fileDataSource);
              }}
              handleChangeHasHeaders={
                fileDataSource.file.type === 'text/csv'
                  ? () => handleChangeHasHeaders(index)
                  : undefined
              }
              onDataSourceNameChange={(e: Event, v: string) => {
                if (fileDataSource) {
                  fileDataSource.dataSourceName = v;
                  const errorMessage = getClassicValidationMessage(v);
                  if (errorMessage) {
                    fileDataSource.dataSourceNameErrorMessage = errorMessage;
                  } else {
                    fileDataSource.dataSourceNameErrorMessage = null;
                  }
                }

                setFileDataSources([...fileDataSources]);
              }}
              errorDataSourceName={fileDataSource.dataSourceNameErrorMessage}
            />
          );
        })}
      </FileAcceptedListWrapper>
    </>
  );
};
