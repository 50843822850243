import styled from 'styled-components';

export const GraphContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 161px);
  max-height: calc(100vh - 161px);
  position: absolute;
`;

export const GraphContainerInnerWrapper = styled.div`
  align-items: flex-start;
  background: transparent;
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: absolute;
  top: 0;
  z-index: 2;
`;

export const GraphContainerLoadingWrapper = styled.div`
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: calc(100vw - 100px);
`;
