import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const ConsentFormDetailPageWrapper = ({ children }) => <>{children}</>;

const mapToStateProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  goToEdit: (id) => {
    dispatch(push(`/admin/governance/consent/detail/edit/${id}`));
  },
  goToAllConsentResult: (id) => {
    dispatch(push(`/admin/governance/consent/detail/result/${id}`));
  },
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(ConsentFormDetailPageWrapper);
