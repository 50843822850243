import styled from 'styled-components';

export const OriginCodeWrapper = styled.div`
  background-color: rgba(41, 162, 154, 0.05);
  display: inline-block;
  flex-wrap: wrap;
  line-height: 1.6;
  margin-bottom: 16px;
  overflow-wrap: break-word;
  padding: 8px;
  width: 100%;
  max-width: calc(100% - 16px);
  word-wrap: break-word;
`;
