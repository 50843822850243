import { SelectedEntityNode } from '../types';
import { Panel } from '@cluedin/form';
import { EntityNodePanelInfo } from './EntityNodePanelInfo';

type EntityPanelProps = {
  selectedNode: SelectedEntityNode;
  setSelectedNode: (node: SelectedEntityNode | null) => void;
};

export const EntityNodePanel = ({
  selectedNode,
  setSelectedNode,
}: EntityPanelProps) => {
  return (
    <Panel open={!!selectedNode} withCustomHeader={true} panelType="large">
      <EntityNodePanelInfo
        selectedNode={selectedNode}
        onClose={() => setSelectedNode(null)}
      />
    </Panel>
  );
};
