"use strict";

exports.__esModule = true;
exports.HistoryChangeSetSourceWrapper = exports.HistoryChangeSetSourceHeadingWrapper = exports.HistoryChangeSetSourceHeading = exports.HistoryChangeSetSourceDetails = exports.HistoryChangeSetSourceBox = exports.HistoryChangeSetSourceBorderCircle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }
var HistoryChangeSetSourceBox = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  background: #ffffff;\n  border-left: 1px solid #ececec;\n  border-right: 1px solid #ececec;\n  max-width: 100%;\n\n  &:last-of-type {\n    border-bottom: 1px solid #ececec;\n  }\n"])));
exports.HistoryChangeSetSourceBox = HistoryChangeSetSourceBox;
var HistoryChangeSetSourceWrapper = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  background: #ffffff;\n  border-left: 1px solid rgb(212, 218, 209);\n  display: flex;\n  flex-direction: column;\n  margin-left: 36px;\n  padding: 14px 16px;\n"])));
exports.HistoryChangeSetSourceWrapper = HistoryChangeSetSourceWrapper;
var HistoryChangeSetSourceHeading = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  display: flex;\n  justify-content: flex-start;\n  margin-left: 10px;\n  position: relative;\n  width: 100%;\n\n  & svg {\n    margin-right: 10px;\n  }\n"])));
exports.HistoryChangeSetSourceHeading = HistoryChangeSetSourceHeading;
var HistoryChangeSetSourceHeadingWrapper = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  justify-content: flex-start;\n"])));
exports.HistoryChangeSetSourceHeadingWrapper = HistoryChangeSetSourceHeadingWrapper;
var HistoryChangeSetSourceBorderCircle = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  background: #ffffff;\n  border: 1px solid ", ";\n  border-radius: 80px;\n  content: '';\n  height: 6px;\n  min-height: 6px;\n  left: -25.5px;\n  position: absolute;\n  width: 6px;\n  min-width: 6px;\n"])), function (_ref) {
  var _theme$palette;
  var theme = _ref.theme;
  return theme == null ? void 0 : (_theme$palette = theme.palette) == null ? void 0 : _theme$palette.themePrimary;
});
exports.HistoryChangeSetSourceBorderCircle = HistoryChangeSetSourceBorderCircle;
var HistoryChangeSetSourceDetails = _styledComponents.default.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  align-items: flex-start;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  & strong,\n  & strong span {\n    color: #000000;\n    font-size: 16px;\n    font-weight: 600;\n    padding-bottom: 2px;\n  }\n\n  & span {\n    color: #666666;\n    font-size: 14px;\n  }\n"])));
exports.HistoryChangeSetSourceDetails = HistoryChangeSetSourceDetails;