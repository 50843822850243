import styled from 'styled-components';

export const ExplainLogSummaryTableScroll = styled.div`
  overflow-x: auto;
  max-width: 95%;
`;

export const ExplainLogSummaryTableWrapper = styled.table`
  background: #f9f9f9;
  border: 1px solid rgb(224, 224, 224);
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const ExplainLogSummaryTableHead = styled.thead`
  background: #f9f9f9;
  text-align: left;
  width: 100%;
  min-width: 100%;
`;

export const ExplainLogSummaryTableHeading = styled.th`
  color: #595959;
  border: 1px solid rgb(224, 224, 224);
  font-weight: 600;
  padding: 12px;
  max-width: 260px;
  word-wrap: break-word;
`;

export const ExplainLogSummaryTableData = styled.td`
  background: #ffffff;
  padding: 12px;
  text-align: left;
  max-width: 260px;
  word-wrap: break-word;
`;

export const ExplainLogSummaryTableRow = styled.tr`
  border-bottom: 1px solid rgb(224, 224, 224);
  width: 100%;
  min-width: 100%;

  &:hover {
    & ${ExplainLogSummaryTableData} {
      background: ${({ theme }) => theme.palette.themeLighterAlt} !important;
    }
  }
`;

export const ExplainLogSummaryTableBody = styled.tbody``;
