import { memo, useMemo } from 'react';
import { Select } from '@cluedin/form';

import { getClassicValidationMessage } from '../../../../core/validation/messages';
import usePrevious from '../../../../core/hooks/usePrevious';
import VocabularySource from '../../composites/VocabularySource';
import { validateVocabularyName, validatePrefix } from '../../../validation';

const createPrefix = (v) => {
  const trimSpaces = v ? v.trim() : '';
  const removeTooMuchWhiteSpace = trimSpaces.replace(/  +/g, ' ');

  return removeTooMuchWhiteSpace.toLowerCase().replace(/ /g, '.');
};

const itemAdapterFunc = (i) => {
  return {
    ...i,
    iconMargin: 0,
    value: i?.vocabularyId,
    label: i?.vocabularyName,
    group: i?.groups?.[0]?.name ?? '',
    icon: (
      <VocabularySource
        hideLabels
        connector={i?.connector}
        CoreVocabularySize="18px"
        AvatarWithNameIconSize="xs"
        isCluedInCore={i?.isCluedInCore}
        fallBackName={i?.vocabularyName}
      />
    ),
  };
};

export const VocabularySelector = memo(
  ({
    data,
    total,
    value,
    loadMore,
    disabled,
    onChange,
    readOnly,
    isLoading,
    setTempVal,
    placeholder,
    setSearchName,
    hasError = false,
    isClearable = true,
    isCreatable = true,
    setIsNewVocabulary,
  }) => {
    const prevData = usePrevious(data);

    const persistData = useMemo(() => {
      if (data?.length > 0) {
        return data;
      }

      return prevData;
    }, [isLoading, data]);

    const options = useMemo(
      () => persistData?.map(itemAdapterFunc),
      [isLoading, persistData],
    );

    const handleChange = (v) => {
      setIsNewVocabulary?.(false);

      setTempVal?.(undefined);

      onChange?.({
        new: false,
        vocabularyName: v?.vocabularyName,
        keyPrefix: v?.keyPrefix,
        vocabularyId: v?.vocabularyId,
      });
    };

    const handleCreateNew = (v) => {
      setIsNewVocabulary?.(true);

      const keyPrefix = createPrefix(v.toLowerCase());
      const hasVocabularyNameError = !!validateVocabularyName(v);
      const hasErrorKeyPrefix = !!validatePrefix(keyPrefix);

      const newValueHasError = getClassicValidationMessage(v, 100);

      if (newValueHasError) {
        return setTempVal?.({
          new: true,
          keyPrefix,
          vocabularyName: v,
          hasVocabularyNameError,
          hasErrorKeyPrefix,
        });
      }

      setTempVal?.(undefined);

      onChange?.({
        new: true,
        keyPrefix,
        vocabularyName: v,
        hasVocabularyNameError,
        hasErrorKeyPrefix,
      });
    };

    return (
      <Select
        isCreatable={isCreatable}
        isSearchable
        hasError={hasError}
        total={total}
        value={value}
        options={options}
        disabled={disabled}
        loadMore={loadMore}
        readOnly={readOnly}
        isLoading={isLoading}
        onChange={handleChange}
        isClearable={isClearable}
        placeholder={placeholder}
        onInputChange={setSearchName}
        onCreateOption={handleCreateNew}
      />
    );
  },
);
