import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Loader } from '@cluedin/atoms';
import Alert from 'uxi/Alert';
import cogoToast from 'cogo-toast';
import { FormattedMessage } from '@cluedin/locale';
import {
  CancelButton,
  PrimaryButton,
  PanelContent,
  PanelFooter,
  TextField,
  withConfirmDialog,
  ConfirmCancelDialog,
  PanelHeader,
  PanelClose,
  FormDecorator,
  FormExplanationRequiredFields,
} from '@cluedin/form';
import isEmptyObj from 'lodash/isEmpty';

import SqlConnectionStringForm from '../forms/SqlConnectionStringForm';
import { withCurrentUser } from '../../../../user/components/Hocs/withCurrentUser';
import SuccessMessage from '../../../../core/components/composites/alerts/SuccessMessage';
import ErrorMessage from '../../../../core/components/composites/alerts/ErrorMessage';
import { useDataBaseConfiguration } from '../../../hooks/useDataBaseConfiguration';
import { InfoLoader } from '../../composites/InfoLoader';
import { isAlphaNumeric, isEmpty } from '../../../../core/validation';
import {
  OnlyAlphaNumbericErrorMessage,
  NotEmptyErrorMessage,
  NotLongErrorMessage,
} from '../../../../core/validation/messages';

const AddDataBaseWithoutDataSource = ({
  title,
  onClose,
  history,
  isSaving,
  currentUser,
  dataSourceSetId,
  errorCreateDataSourceSet,
}) => {
  const {
    shouldGoNext,
    dataSourceId,
    configuration,
    isProgressing,
    testConnection,
    setShouldGoNext,
    dataSourceName,
    createDataSource,
    prevDataSourceId,
    setConfiguration,
    setDataSourceName,
    configurationsErrors,
    testConnectionResult,
    isValidConfiguration,
    configurationTouched,
    errorConnectionResult,
    errorCreateDataSource,
    loadingCreateDataSource,
    setConfigurationTouched,
    loadingTestConnectionResult,
  } = useDataBaseConfiguration({
    isSaving,
    dataSourceSetId,
  });

  const isValidName = isAlphaNumeric(dataSourceName);
  const isEmptyName = isEmpty(dataSourceName) && !!dataSourceName.length;
  const isNotLongDataSourceName = dataSourceName?.length < 256;

  const isInvalidValidSubmission =
    loadingCreateDataSource ||
    isSaving ||
    !dataSourceName ||
    !isValidName ||
    isEmptyName ||
    !isNotLongDataSourceName;

  const isValidCancel = !!dataSourceName || !isEmptyObj(configuration);
  const ButtonWithConfirm = isValidCancel
    ? withConfirmDialog(CancelButton, ConfirmCancelDialog)
    : CancelButton;
  const ClosePanelButton = isValidCancel
    ? withConfirmDialog(PanelClose, ConfirmCancelDialog)
    : PanelClose;

  useEffect(() => {
    if (shouldGoNext && testConnectionResult?.success) {
      createDataSource({
        dataSourceSetId,
        dataSource: {
          name: dataSourceName,
          type: 'sql',
          configuration,
          author:
            currentUser && currentUser.client ? currentUser.client.Id : '',
        },
      });
    }
  }, [
    testConnectionResult,
    shouldGoNext,
    createDataSource,
    configuration,
    dataSourceName,
    dataSourceSetId,
    currentUser,
  ]);

  useEffect(() => {
    if (dataSourceId && prevDataSourceId !== dataSourceId) {
      cogoToast.success(`Database added successfully`, {
        position: 'bottom-right',
        hideAfter: 5,
      });
      history.push(`/admin/inbound/datasourceset/datasource/${dataSourceId}`);
    }
  }, [dataSourceId, prevDataSourceId, history]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <PanelHeader
        title={title}
        onClose={onClose}
        CustomClose={
          <ClosePanelButton
            confirmTitle={
              <FormattedMessage id="data-source-confirm-lost-changes-title" />
            }
            confirmMessage={
              <FormattedMessage id="data-source-confirm-lost-changes-message" />
            }
            onConfirm={handleCancel}
            onClick={handleCancel}
          />
        }
      />
      <PanelContent>
        {errorCreateDataSource && (
          <Alert type="danger">
            {JSON.stringify(errorCreateDataSource || {})}
          </Alert>
        )}
        {errorCreateDataSourceSet && (
          <Alert type="danger">{errorCreateDataSourceSet}</Alert>
        )}

        {!isProgressing && (
          <>
            <FormDecorator
              label={<FormattedMessage id="data-source-name-data-source" />}
              helpText={<FormattedMessage id="data-source-add-help-text" />}
              errorText={
                isNotLongDataSourceName ? (
                  (!isValidName && <OnlyAlphaNumbericErrorMessage />) ||
                  (isEmptyName && <NotEmptyErrorMessage />)
                ) : (
                  <NotLongErrorMessage />
                )
              }
              isRequired
            >
              <TextField
                value={dataSourceName}
                onChange={(e, v) => {
                  setDataSourceName(v);
                }}
              />
            </FormDecorator>

            <SqlConnectionStringForm
              value={configuration}
              errors={configurationTouched ? configurationsErrors : {}}
              onChangeKey={(key, value) => {
                configuration[key] = value;

                setConfiguration({ ...configuration });

                setConfigurationTouched(true);
              }}
            />
            <FormExplanationRequiredFields />
          </>
        )}

        {isProgressing && (
          <InfoLoader
            title={
              <FormattedMessage id="data-source-database-info-loader-title" />
            }
            text={
              <FormattedMessage id="data-source-database-info-loader-message" />
            }
          />
        )}
      </PanelContent>

      <PanelFooter>
        <div style={{ marginRight: 'auto' }}>
          {testConnectionResult && testConnectionResult.success && (
            <SuccessMessage>
              <FormattedMessage id="data-source-database-test-connection-success" />
            </SuccessMessage>
          )}

          {(errorConnectionResult ||
            (testConnectionResult && !testConnectionResult.success)) && (
            <ErrorMessage>
              <FormattedMessage id="data-source-database-test-connection-error" />
            </ErrorMessage>
          )}
        </div>

        <CancelButton
          rounded
          style={{ marginRight: '8px' }}
          disabled={!isValidConfiguration || loadingTestConnectionResult}
          onClick={() => testConnection(configuration)}
          startIcon={
            loadingTestConnectionResult ? (
              <Loader color="#fff" size={14} />
            ) : null
          }
        >
          <FormattedMessage id="data-source-database-test-connection" />
        </CancelButton>

        <ButtonWithConfirm
          confirmTitle={
            <FormattedMessage id="data-source-confirm-lost-changes-title" />
          }
          confirmMessage={
            <FormattedMessage id="data-source-confirm-lost-changes-message" />
          }
          onConfirm={handleCancel}
          rounded
          onClick={handleCancel}
        >
          <FormattedMessage id="data-source-cancel" />
        </ButtonWithConfirm>

        <PrimaryButton
          rounded
          style={{ marginLeft: '8px' }}
          className="panel-main-action"
          startIcon={
            loadingCreateDataSource ||
            loadingTestConnectionResult ||
            isProgressing ? (
              <Loader color="#fff" size={14} />
            ) : null
          }
          disabled={
            isInvalidValidSubmission ||
            !isValidConfiguration ||
            loadingTestConnectionResult
          }
          onClick={() => {
            if (testConnectionResult?.success) {
              createDataSource({
                dataSourceSetId,
                dataSource: {
                  name: dataSourceName,
                  type: 'sql',
                  configuration,
                  author: currentUser?.client ? currentUser.client.Id : '',
                },
              });
            } else {
              setShouldGoNext(true);
              testConnection(configuration);
            }
          }}
        >
          <FormattedMessage id="data-source-add" />
        </PrimaryButton>
      </PanelFooter>
    </>
  );
};

export default compose(
  withCurrentUser,
  withRouter,
)(AddDataBaseWithoutDataSource);
