import { useState } from 'react';
import { ExpandingArrow } from '@cluedin/atoms';
import { useTheme } from '@cluedin/theme';
import { FormattedHTMLMessage } from '@cluedin/locale';
import Alert from 'uxi/Alert';

import {
  EdgePropertiesPanelEdgeGroupEntryWrapper,
  EdgePropertiesPanelEdgeGroupEntryInnerWrapper,
  EdgePropertiesPanelEdgeGroupEntryHeader,
  EdgePropertiesPanelEdgeGroupEdgeWrapper,
} from './EdgePropertiesPanelEdgeGroupEntry.styles';
import EdgePropertiesPanelEdge from './EdgePropertiesPanelEdge/EdgePropertiesPanelEdge';
import PageLoader from '../../../../core/components/composites/PageLoader';

const EdgePropertiesPanelEdgeGroupEntry = ({
  edgeGroup,
  edgePropertiesPanelLoading,
  edgeByEdgeId,
  edges,
  edgeDirection,
  edgeType,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  const filteredEdgeGroup = edgeGroup?.[1]?.filter(
    (edge) =>
      edge?.referencedEntityId === edgeByEdgeId?.[0]?.from ||
      edge?.referencedEntityId === edgeByEdgeId?.[0]?.to ||
      edgeByEdgeId?.[0]?.groupedEntityIds?.includes(edge?.referencedEntityId),
  );

  return (
    <EdgePropertiesPanelEdgeGroupEntryWrapper>
      <EdgePropertiesPanelEdgeGroupEntryInnerWrapper
        isExpanded={isExpanded}
        theme={theme}
        data-test="entity-entityRelations-edgePropertiesPanelEdgeGroupEntry"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <EdgePropertiesPanelEdgeGroupEntryHeader>
          <ExpandingArrow
            expanded={isExpanded}
            data-test="entity-entityRelations-expandingArrow"
          />
          {edgeGroup?.[0]}
        </EdgePropertiesPanelEdgeGroupEntryHeader>
      </EdgePropertiesPanelEdgeGroupEntryInnerWrapper>

      <EdgePropertiesPanelEdgeGroupEdgeWrapper isExpanded={isExpanded}>
        {edgePropertiesPanelLoading && <PageLoader />}

        {filteredEdgeGroup?.length > 0 ? (
          filteredEdgeGroup?.map((edge, index) => (
            <EdgePropertiesPanelEdge
              key={index}
              edge={edge}
              edges={edges}
              edgeDirection={edgeDirection}
            />
          ))
        ) : (
          <div style={{ padding: '10px 0' }}>
            <Alert type="information">
              <FormattedHTMLMessage
                id="module-entityRelationsGraph-edgePropertiesEntryNoData"
                values={{ edgeType }}
              />
            </Alert>
          </div>
        )}
      </EdgePropertiesPanelEdgeGroupEdgeWrapper>
    </EdgePropertiesPanelEdgeGroupEntryWrapper>
  );
};

export default EdgePropertiesPanelEdgeGroupEntry;
