import { OnlyClientIdSubDomain } from '../core/components/Hocs/SubdomainChooser';
import HomePage from '../home/components/pages/HomePage';
import AddPreventExit from '../core/components/Hocs/AddPreventExit';
import { setupRedirectToRelativeHome } from '../core/components/containers/RedirectToRelativeHome';

export default [
  {
    path: '/',
    exact: true,
    component: OnlyClientIdSubDomain(
      AddPreventExit(setupRedirectToRelativeHome('')),
    ),
  },
  {
    path: '/home',
    component: OnlyClientIdSubDomain(AddPreventExit(HomePage)),
  },
];
