import React from 'react';
import { FormattedMessage } from '@cluedin/locale';
import EntityTypeDistributionComposite from '../../composites/reports/EntityTypeDistributionComposite';
import styles from './report.style';
import GraphWidgetWrapper from './GraphWidgetWrapper';
import { sortByCount } from '../../../../DVC/helpers/sort';
import { getEntityConfiguration } from '../../../../wms/helpers/widgetHelper';
import withEntityReportData from '../../hocs/withEntityReportData';

const EntityTypeDistributionContainer = (props) => {
  const data = props.data && Array.isArray(props.data) ? props.data : [];

  const toPieChartData = (entityTypes = []) => {
    const cleaned = entityTypes
      .sort(sortByCount)
      .slice(0, 10)
      .map((entityType) => {
        let displayName = entityType.term;

        const entityConfig = getEntityConfiguration(entityType.term);

        if (entityConfig && entityConfig.displayName) {
          displayName = entityConfig.displayName;
        }

        return {
          name: displayName,
          value: entityType.count,
        };
      });

    return {
      labels: cleaned.map(({ name, value }) => `${name} (${value})`),
      datasets: [
        {
          label: cleaned.map(({ name }) => name),
          data: cleaned.map(({ value }) => value),
        },
      ],
      options: {
        legend: {
          position: 'left',
        },
      },
    };
  };

  const pieData = toPieChartData(data || []);

  return (
    <GraphWidgetWrapper ratio={false}>
      <h3 style={styles.title}>
        <FormattedMessage id="module-gdpr-entityTypeDistribution" />
      </h3>
      <EntityTypeDistributionComposite {...props} data={pieData} />
    </GraphWidgetWrapper>
  );
};

export default withEntityReportData(EntityTypeDistributionContainer);
