import { actionsConfiguration } from '@cluedin/components';
import { PrimaryButton } from '@cluedin/form';
import { Add } from 'uxi/Icons';
import { FormattedMessage } from '@cluedin/locale';
import InviteUserDialog from '../container/dialogs/InviteUserDialog';

const { admin } = actionsConfiguration;

const InviteUserButton = ({ shouldOpenInviteUserDialog }) => {
  return (
    <>
      <InviteUserDialog />
      <PrimaryButton
        startIcon={<Add size={14} />}
        onClick={shouldOpenInviteUserDialog}
        actionConfiguration={admin.users.user.create}
        text={<FormattedMessage id="module-user-inviteNewMember" />}
      />
    </>
  );
};

export default InviteUserButton;
